import React, {useState} from 'react';
import AreaChartWrapper from '../../../../components/Charts/AreaChartWrapper';
import {AreaChartData} from '../../../../components/Charts/mockChartData';
import {colors} from '../../../../theme/colors';
import ChartContainer from '../../../../components/Charts/ChartContainer';
import {TIME_FILTER} from '../../../../utils/constants';
import Grid from '@material-ui/core/Grid';
import Statcard from '../../../../components/Statcard';
import cpe_orange from '../../../../assets/icons/cpe_orange.png';
import cpe_down from '../../../../assets/icons/cpe_down.png';
import cpu from '../../../../assets/icons/cpu.png';
import ram from '../../../../assets/icons/ram.png';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import {makeStyles} from '@material-ui/core/styles';
import {useQuery, useLazyQuery} from '@apollo/react-hooks';
import {
    GET_CONTROLLERS,
    GET_DASHBOARD_STATS,
    GET_MAP_DATA,
    GET_UCPE_STATS,
    GET_ALL_PLACES, GET_ALL_VNF, GET_UCPE_EVENT_DATA, GET_ALL_VNF_TEMPLATES
} from '../../queries';
import Button from '@material-ui/core/Button';
import Map from './map';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../../theme/commonStyles';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {dateFormatterLLL} from '../../../../utils';
import ControllerStatusPanel from '../ControllerStatusPanel';
import UcpeEvents from '../UcpeEvents';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));
const SDNDashboardPanel = ({t, subscriptionData, data, ticketData, type}) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const organization_id = subscriptionData.organizationId;
    const [selectedDevice, setSelectedDevice] = useState('');
    const [ucpeStats, setUcpeStats] = useState([]);
    const [startDate, setStartDateState] = useState(null);
    const [endDate, setEndDateState] = useState(null);
    const [toggleState, setToggleState] = useState(false);
    const [eventsAndAlertsModelState, setEventsAndAlertsModelState] = useState({
        time: 'Today',
        placeId: '',
        modelType: ''
    });
    const [firstLoad, setFirstLoad] = useState(true);
    const [getUcpeStats] = useLazyQuery(GET_UCPE_STATS, {
        onError: error => {
            //console.log('Error-=-=-=-=-=69', error);
        },
        onCompleted: data => {
            setUcpeStats(
                data.getUcpeStats.map(stats => {
                    return {
                        name: moment.utc(parseInt(stats.datetime)).local().format("DD/MM/YYYY HH:MM"),
                        val: stats.memory,
                        secVal: stats.vcpus
                    };
                })
            );
        }
    });
    const {
        loading: controllersLoading,
        data: controllerList,
        error: controllerError
    } = useQuery(GET_CONTROLLERS, {
        variables: {
            organization_id
        }
    });
    const {loading: statsLoading, data: stats, error: statsError} = useQuery(GET_DASHBOARD_STATS, {
        variables: {
            organization_id
        }
    });
    const {
        loading: eventDataLoading,
        data: eventData,
        error: eventDataError,
        refetch: reloadEventData
    } = useQuery(GET_UCPE_EVENT_DATA, {
        variables: {
            organization_id,
            place: eventsAndAlertsModelState.placeId
        }
    });
    const {
        loading: mapsDataLoading,
        data: mapsData,
        error: mapsDataError
    } = useQuery(GET_MAP_DATA, {
        variables: {
            organization_id,
            search: ''
        }
    });
    const {
        loading: loadingPlaces,
        data: placesList,
        error: placesError
    } = useQuery(GET_ALL_PLACES, {
        variables: {
            organization_id
        }
    });
    React.useEffect(() => {
        let filterBy = {
            variables: {
                device_id: selectedDevice,
                start_date: moment(startDate).format('DD/MM/YYYY HH:MM:SS'),
                end_date: moment(endDate).format('DD/MM/YYYY HH:MM:SS')
            }
        };
        if (selectedDevice && startDate && endDate) getUcpeStats(filterBy);
    }, [selectedDevice, startDate, endDate, getUcpeStats]);
    React.useEffect(() => {
        reloadEventData();
    }, [eventsAndAlertsModelState.placeId]);
    let statsData =
        !statsLoading && !statsError
            ? {
                totalCpes:
                stats.getDashboardStats.total_devices,
                downCpes: stats.getDashboardStats.noOfDownDevices,
                avgCpu:
                stats.getDashboardStats.avgVcpus,
                avgRam:
                stats.getDashboardStats.avgMemory
            }
            : {
                totalCpes: 'loading...',
                downCpes: 'loading...',
                avgCpu: 'loading...',
                avgRam: 'loading...'
            };
    const ucpeFilter = [];
    let dashboardMapsData =
        !mapsDataLoading && !mapsDataError && mapsData.getUcpes.length > 0
            ? mapsData.getUcpes
                .filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
                .map(ucpe => {
                    ucpeFilter.push({
                        id: ucpe.device_id,
                        name: ucpe.device_name
                    });
                    return {
                        position: {
                            lat: parseFloat(ucpe.gps_latitude),
                            lng: parseFloat(ucpe.gps_longitude)
                        },
                        title: ucpe.device_name
                    };
                })
            : [];
    let placeFilList =
        !loadingPlaces && !placesError && placesList.getAllPlaces.length > 0
            ? placesList.getAllPlaces.map(place => {
                return {
                    id: place.place_name,
                    name: place.place_name
                };
            })
            : [];
    let placeFilterData = [];
    if (!loadingPlaces && !placesError && placesList.getAllPlaces.length) {
        for (let i = 0; i < placesList.getAllPlaces.length; i++) {
            let item = placesList.getAllPlaces[i];
            placeFilterData.push({
                position: {lat: parseFloat(item.gps_latitude), lng: parseFloat(item.gps_longitude)},
                title: item.place_name,
                infoData: {place_name: item.place_name}
            })
        }
    }
    const statList = [
        {
            label: t('noOfCPEs.label'),
            value: statsData.totalCpes,
            icon: cpe_orange
        },
        {
            label: t('CPEsDown.label'),
            value: statsData.downCpes,
            icon: cpe_down
        },
        {
            label: t('avgRamUsage.label'),
            value:
                statsData.avgRam !== 'loading...'
                    ? statsData.avgRam
                    ? parseFloat(statsData.avgRam).toFixed(2) + '%'
                    : 0
                    : statsData.avgRam,
            icon: ram
        },
        {
            label: t('avgCpuUsage.label'),
            value:
                statsData.avgCpu !== 'loading...'
                    ? statsData.avgCpu
                    ? parseFloat(statsData.avgCpu).toFixed(2) + '%'
                    : 0
                    : statsData.avgCpu,
            icon: cpu
        }
    ];
    if (ucpeFilter.length > 0 && firstLoad) {
        setFirstLoad(false);
        setSelectedDevice(ucpeFilter[0]['id']);
        let now = new Date();
        let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
        defaultDate = new Date(defaultDate);
        setStartDateState(defaultDate);
        setEndDateState(new Date());
    }
    const ucpeFilterList = [
        {
            label: 'uCPE Name',
            handleChange: evt => setSelectedDevice(evt.target.value),
            val: selectedDevice,
            options: ucpeFilter.length > 0 ? ucpeFilter : [],
            styleOverrides: {marginRight: 15}
        },
        {
            label: t('startDate.label'),
            handleChange: newDate => {
                setStartDateState(newDate);
            },
            val: startDate,
            name: 'startDate',
            styleOverrides: {
                ...marginGenerator("mr-15")
            },
            type: 'input'
        },
        {
            label: t('endDate.label'),
            handleChange: newDate => {
                setEndDateState(newDate);
            },
            val: endDate,
            name: 'endDate',
            styleOverrides: {
                ...marginGenerator("mr-15")
            },
            type: 'input'
        }
    ];
    const controllerColumns = [
        {
            label: t('controller.label') + ' ' + t('name.label'),
            key: 'controllerName'
        },
        {
            label: t('role.label'),
            key: 'controllerRole'
        },
        {
            label: t('ipAddress.label'),
            key: 'ipAddress'
        },
        {
            label: t('createdOn.label'),
            key: 'createdOn'
        },
        {
            label: t('status.label'),
            key: 'status'
        },
        {
            label: t('launchOption.label'),
            key: 'button'
        }
    ];
    const eventAlertModelFilter = [
            {
                label: 'Period',
                handleChange: evt => {
                    setEventsAndAlertsModelState({
                        ...eventsAndAlertsModelState,
                        time: evt.target.value
                    });
                },
                val: eventsAndAlertsModelState.time,
                options: TIME_FILTER
            },
            {
                label: t('places.label'),
                handleChange: evt => {
                    setEventsAndAlertsModelState({
                        ...eventsAndAlertsModelState,
                        placeId: evt.target.value
                    });
                },
                val: eventsAndAlertsModelState.placeId,
                options: placeFilList
            }
        ]
    ;
    return (
        <div style={{...marginGenerator('mb-56'), overflow: 'hidden'}}>
            <Grid container item style={marginGenerator('mt-24')}>
                <Grid item xs container justify={'flex-end'}>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        onClick={() => setToggleState(!toggleState)}>
                        {!toggleState ? t('hideMap.label') : t('showMap.label')}
                    </Button>
                </Grid>
            </Grid>
            {!toggleState &&
            (!loadingPlaces && !placesError && placesList.getAllPlaces.length ? (
                <Grid
                    container
                    className={classes.mapContainer}
                    style={marginGenerator('mt-32')}>
                    <Map
                        id={'ucpe-map'}
                        markers={placeFilterData.length > 0 ? placeFilterData : []}
                    />
                </Grid>
            ) : null)}
            <Grid
                container
                justify={'space-between'}
                style={marginGenerator('mt-56')}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                    />
                ))}
            </Grid>
            <Typography
                variant={'h4'}
                align={'justify'}
                style={marginGenerator('mt-24')}>
                {t('controllers.label')}
            </Typography>
            <Grid container style={marginGenerator('mt-24')}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {controllerColumns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!controllersLoading && !controllerError ? (
                                controllerList.getControllers.length > 0 ? (
                                    controllerList.getControllers.map((controller, rowIndex) => {
                                        if (controller.controller_status !== 'deleted') {
                                            return (
                                                <TableRow key={rowIndex}>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            {controller.controller_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            {controller.role}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            {controller.controller_ip}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            {dateFormatterLLL(controller.created_at)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            <ControllerStatusPanel
                                                                status={controller.controller_status}
                                                                t={t}
                                                            />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'}>
                                                            <Grid item xs container justify={'flex'}>
                                                                <Button
                                                                    variant={'outlined'}
                                                                    size={'small'}
                                                                    color={'primary'}
                                                                    onClick={() =>
                                                                        controller.controller_ip !== ''
                                                                            ? window.open(
                                                                            `ssh://${controller.controller_ip}`,
                                                                            '_blank'
                                                                            )
                                                                            : null
                                                                    }>
                                                                    SSH
                                                                </Button>
                                                                <Button
                                                                    variant={'outlined'}
                                                                    size={'small'}
                                                                    color={'primary'}
                                                                    onClick={() =>
                                                                        controller.controller_ip !== ''
                                                                            ? window.open(
                                                                            `https://${controller.controller_ip}`,
                                                                            '_blank'
                                                                            )
                                                                            : null
                                                                    }
                                                                    ml={1}
                                                                    style={{marginLeft: '10px'}}>
                                                                    HTTPS
                                                                </Button>
                                                            </Grid>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Typography
                variant={'h4'}
                align={'justify'}
                style={marginGenerator('mt-24')}>
                {t('uCPE.label')}
            </Typography>
            <ChartContainer
                title={t('ram&cpuUtilization.label')}
                filterList={ucpeFilterList}>
                <AreaChartWrapper
                    name={t('ramUtilization.label')}
                    /*data={ucpeStats.length > 0 ? ucpeStats : AreaChartData}*/
                    data={ucpeStats.length > 0 ? ucpeStats : []}
                    chartColor={colors.chartColors.graphTopaz}
                    dataKeyChart={'val'}
                    dataKeyXAxis={'name'}
                    stacked={true}
                    secondChartDataKey={'secVal'}
                    secondChartColor={colors.chartColors.graphDustyOrange}
                    secondChartName={t('cpuUtilization.label')}
                />
            </ChartContainer>
            <ChartContainer
                title={t('eventsAndAlertsModel.label')}
                filterList={eventAlertModelFilter}>
                <UcpeEvents t={t} data={ucpeFilter} eventsAndAlertsModelState={eventsAndAlertsModelState}
                            eventData={eventData}/>
            </ChartContainer>
        </div>
    )
        ;
};
export default SDNDashboardPanel;
