import React, { useState, useEffect, useContext } from 'react'
import ShayonaAlertsListings from './ShayonaAlertsListings';
import { makeStyles } from '@material-ui/core/styles';
import ChartContainer from '../../../../../components/Charts/ChartContainer';
import { convertLocalToUTCDate } from '../../../../../utils';
import { ShayonaCementsContext } from '../../../../../contexts/ShayonaCementsProvider';


const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 250,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
  
}));

const initAlertFilter = {time: 'Today', startDate: null, endDate: null, status: ''};

const ShayonaAlerts = ({
  t
}) => {
  const classes = useStyles();
  const {
    organizationId,
    shayonaApisFilterMethod,
    tripsStatusOption
  } = useContext(ShayonaCementsContext);
  const [shyonaAlertState, setShyonaAlertState] = useState({...initAlertFilter});


  const handleClearFilter = () => {
    setShyonaAlertState({...initAlertFilter});
    shayonaApisFilterMethod('alert',{startTime: null, endTime: null, status:'', truckNumber: ''});
  };

  const alertStatusOption = [
    {id: 'excess_bags', name:'Excess bags'},
    {id: 'less_bags', name:'Less bags'},
    {id: 'excess_weight',name:'Excess weight'},
    {id: 'wrong_weight',name:'Wrong weight'},
    {id: 'wrong_alert', name:'Wrong alert'}
  ];

const shayonaAlertFilter =  [
{
    label: t("startDate.label"),
    handleChange:(newDate) => {
        setShyonaAlertState({...shyonaAlertState, startDate: newDate});
    },
    val: shyonaAlertState.startDate,
    type: "input",
    styleOverrides: { marginRight: 24 },
    className: classes.dateInput
},
{
    label: t("endDate.label"),
    handleChange:(newDate) => {
        setShyonaAlertState({...shyonaAlertState, endDate: newDate})
    },
    val: shyonaAlertState.endDate,
    type: "input",
    styleOverrides: { marginRight: 24 },
    className: classes.dateInput,
},
{
  label: t('criteria.label'),
  handleChange: evt => {
      setShyonaAlertState({...shyonaAlertState, status: evt.target.value});
  },
  val: shyonaAlertState.status,
  options: alertStatusOption,
  styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
}
];

useEffect(() => {
let startTime = null;
let endTime = null;
if(shyonaAlertState.startDate && shyonaAlertState.endDate){
    startTime = new Date(convertLocalToUTCDate(shyonaAlertState.startDate));
    startTime = Math.floor(startTime.getTime() / 1000); 
    endTime = new Date(convertLocalToUTCDate(shyonaAlertState.endDate));
    endTime = Math.floor(endTime.getTime() / 1000);
}
if(startTime || endTime || shyonaAlertState.status){
shayonaApisFilterMethod('alert',{startTime, endTime, status:shyonaAlertState.status, truckNumber: ''});
}
}, [shyonaAlertState]);

  return (
    <>
      <ShayonaAlertsListings
        t={t}
        filterList={shayonaAlertFilter}
        handleClearFilter={handleClearFilter}
      />
    </>
  )
}

export default ShayonaAlerts