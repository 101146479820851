import React from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../components/hook-form';
import { renderText } from '../../displayComponent';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createClient } from '../../../../services/clientService';
import { useHistory } from 'react-router-dom';
import Message from '../../../../components/Message';

const phoneCodes = [
  { name: 'South Africa', value: '+27' },
  { name: 'Nigeria', value: '+234' },
  { name: 'Botswana', value: '+267' }
];

const CreateClientForm = () => {
  const initialValues = {
    clientName: '',
    headquarters: '',
    businessEmail: '',
    techEmail: '',
    phoneCode: '',
    telephone: ''
  };

  const phoneRegExp = /^([+]\d{2})?\d{10}$/;

  const validationSchema = yup.object().shape({
    clientName: yup.string().required('This field is required'),
    headquarters: yup.string().required('This field is required'),
    businessEmail: yup
      .string()
      .email('Not a proper email')
      .required('This field is required'),
    techEmail: yup
      .string()
      .email('Not a proper email')
      .required('This field is required'),
    phoneCode: yup.string().required('Select country code'),
    telephone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const history = useHistory();

  const { handleSubmit } = methods;

  const onSubmit = async data => {
    const payload = {
      client_name: data?.clientName,
      head_quarters: data?.headquarters,
      bussiness_contact_email: data?.businessEmail,
      technical_contact_email: data?.techEmail,
      telephone_contact: data?.telephone
    };
    const res = await createClient(payload);
    if (res?.status === 200) {
      history.push('/fabricService/Client');
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else if (res?.message) {
        Message.error(res?.message);
      } else {
        Message.error('Something went wrong');
      }
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>{renderText({ label: 'New Client' })}</Box>
            </Grid>
          </Paper>
        </Grid>

        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Client Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="clientName"
                        label="Client Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Headquarters
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="headquarters"
                        label="Headquarters"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Business Contact Email
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        size="small"
                        name="businessEmail"
                        label=" Business Contact Email"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Technical Contact Email
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        size="small"
                        name="techEmail"
                        label="Technical Contact Email"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justify="center"
                    style={{ marginTop: '5px' }}>
                    <Grid item sm={3}>
                      Telephone
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item container justify="space-between" sm={5}>
                      <Grid item sm={4}>
                        <RHFSelect
                          name="phoneCode"
                          variant="outlined"
                          size="small">
                          <option value="">Code</option>
                          {phoneCodes &&
                            phoneCodes?.map(item => (
                              <option key={item?.value} value={item.value}>
                                {`${item?.value} (${item.name})`}
                              </option>
                            ))}
                        </RHFSelect>
                      </Grid>
                      <Grid item sm={8}>
                        <RHFTextField
                          variant="outlined"
                          size="small"
                          name="telephone"
                          label="Telephone"
                          autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => history.push('/fabricService/Client')}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateClientForm;
