import {gql} from 'apollo-boost';

export const GET_ORG_BILLING_UTILIZATION_ITEMS = gql`
    query BillingUtilizationItems(
        $organizationId: String
        $startDate: Float
        $endDate: Float
    ) {
        getOrgBillingUtilizationItems(
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
        ) {
            data
        }
    }
`;
export const GET_LAST_SIX_MONTH_ORG_INVOICE_REPORT = gql`
    query OrgBillingInvoice($organizationId: String) {
        getLastSixMonthOrgInvoiceReport(organizationId: $organizationId) {
            totalPayableAmount
            invoiceDate
        }
    }
`;
export const GET_ORG_INVOICE_REPORT = gql`
    query OrgBillingInvoice($organizationId: String, $invoiceDate: String) {
        getOrgInvoiceReport(
            organizationId: $organizationId
            invoiceDate: $invoiceDate
        ) {
            _id
            totalPayableAmount
            totalCost
            totalDiscount
            organizationName
            taxAmount
            invoiceDate
            invoiceNumber
            currency
            status
            utilizations
        }
    }
`;

/*
  Get user info
 */
  export const GET_ORGANIZATION_USER_DETAILS = gql`
  query getOrgnazationUserDetails($organizationId: String) {
    getOrgnazationUserDetails(organizationId: $organizationId) {
      id
      firstName
      lastName
      email
      phone
      organization {
        id
        name
        email
        phone
        address
        region
        country
      }
    }
  }
`;

export const GET_INVOICE_SAGEX3_LIST = gql`
    query invoiceSageX3($organizationName: String, $links: String) {
        invoiceSageX3(organizationName: $organizationName, links: $links) {
            teamListData
        }
    }
`;