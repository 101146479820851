import React, {useState} from 'react';

import OcrConfigurationDocumentsReport from './OcrConfigurationDocumentsReport/';
import CustomTabs from '../../../../../../components/CustomTabs';

const OcrConfigurationDocuments = ({
    t,
    ocrPlaceData,
    ocrProjectData,
    loadingOcrPlace,
    loadingOcrProject,
    ocrFetchLoadingForDocuments,
    ocrDocumentsListing,
    handleFilterdataForOptions,
    handleSubmitOrFilterForDocumentConfigTab,
}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const tabList = [
        {
            label: t('documentsReport.label'),
            children:(
                    <OcrConfigurationDocumentsReport
                        t={t}
                        ocrPlaceData={ocrPlaceData}
                        ocrProjectData={ocrProjectData}
                        loadingOcrPlace={loadingOcrPlace}
                        loadingOcrProject={loadingOcrProject}
                        ocrFetchLoadingForDocuments={ocrFetchLoadingForDocuments}
                        ocrDocumentsListing={ocrDocumentsListing}
                        handleFilterdataForOptions={handleFilterdataForOptions}
                        handleSubmitOrFilterForDocumentConfigTab={handleSubmitOrFilterForDocumentConfigTab}
                    />
              )
        }
    ];

  return (
    <>
       <CustomTabs 
          activeIndex={activeIndex} 
          tabs={tabList}
       />
    </>
  )
}

export default OcrConfigurationDocuments