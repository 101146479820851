import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { GET_TOP_EVENTS, GET_NOTIFICATION_REPORT, GET_CAMERA_LIST, GET_PLACE_LIST, GET_TOP_EVENTS_BY_FILTER, GET_PROCESS_WORKFLOW_STEPS } from '../../queries';
import { ACKNOWLADGE_NOTIFICATION, UNACKNOWLADGE_NOTIFICATION } from '../../mutations';
import OrganizationEventsPanel from "../OrganizationEventsPanel";
import CustomTabsForEdgeAIReportPanel from './CustomTabsForEdgeAIReportPanel';
import PlaceEventsPanel from "../PlaceEventsPanel";
import CameraEventsPanel from "../CameraEventsPanel";
import NotificationEventsPanel from "../NotificationEventsPanel";
import EventsPanel from "../EventsPanel";
import { marginGenerator } from '../../../../theme/utils';
import Message from '../../../../components/Message';
import EventTagsModal from '../EventTagsModal';
import EdgeAIFacesPanel from '../EdgeAIFacesPanel';
import { FILTER_MODEL_TYPES, FILTER_TAGS_BY_MODEL, CLIENT_FILTER_OPTIONS, EDGE_AI_ORGS } from '../../../../utils/constants';
import { Box, Typography } from '@material-ui/core';
const unCannyFilters = ["LineCrossing", "Behavioural", "MultiplePerson", "HeatMap"];

export function getLocalToUtcTimeStemp(date) {
    calcTime(date);
    date = new Date(date);
    let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()) / 1000;
    return now_utc;
}

function calcTime(date, offset) {
    // create Date object for current location
    let d = new Date(date);
    // get UTC time in msec
    let utc = d.getTime();
    let nd = new Date(utc + (3600000 * offset));
    return nd;
}

const EdgeAIReportPanel = ({
    t,
    data,
    handleSetImageModalSrc,
    tagsFilter,
    aiTypes,
    mlTemplateList,
    activeNotificationTab,
    handleChangeActiveNotiTab,
    subActiveIndex,
    selectedPath
}) => {

    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [notificationCameraFilterState, setNotificationCameraFilterState] = useState('');
    const [notificationPlaceFilterState, setNotificationPlaceFilterState] = useState('');
    const [notificationModelFilterState, setNotificationModelFilterState] = useState([]);
    const [notificationMlTemplateFilterState, setNotificationMlTemplateFilterState] = useState('');
    const [notificationDateTimeFilterState, setNotificationDateTimeFilterState] = useState(null);
    const [notificationEndDateFilterState, setNotificationEndDateFilterState] = useState(null);
    const [notificationFilterLoading, setNotificationFilterLoading] = useState(false);
    const [notificationFilterData, setNotificationFilterData] = useState({});
    const [notificationFilter, setNotificationFilter] = useState(false);
    const [selectedNotificationRow, setSelectNotificationRow] = useState({});
    const [eventTagModalState, setEventTagModalState] = useState(false);
    const [activeEventTags, setActiveEventTags] = useState(null);
    const [notificationPage, setNotificationPage] = useState(1);
    const [showTagsFilter, setShowTagsFilter] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [tagFilterState, setTagFilterState] = useState(null);
    const [clearTagFilter, setClearTagFilter] = useState(false);
    const [aiModelTypesFilters, setAiModelTypesFilters] = useState(FILTER_MODEL_TYPES);
    const [topEventsFilterLoading, setTopEventsFilterLoading] = useState(false);
    const [topEventsFilterData, setTopEventsFilterData] = useState({});
    const [topEventsFilter, setTopEventsFilter] = useState(false);
    const [topEventsCameraFilterState, setTopEventsCameraFilterState] = useState('');
    const [topEventsPlaceFilterState, setTopEventsPlaceFilterState] = useState('');
    const [topEventsModelFilterState, setTopEventsModelFilterState] = useState('');
    const [topEventsDateTimeFilterState, setTopEventsDateTimeFilterState] = useState(null);
    const [topEventsEndDateFilterState, setTopEventsEndDateFilterState] = useState(null);
    const [topEventsPage, setTopEventsPage] = useState(1);
    const [apiTimer, setApiTimer] = useState(0);
    const [notificationProcessFilterState, setNotificationProcessFilterState] = useState('');
    const [notificationClientFilterState, setNotificationClientFilterState] = useState('');
    const [notiApiTimer, setNotiApiTimer] = useState(0);
    const [notiPlaceCameraFilterList, setNotiPlaceCameraFilterList] = useState({ places: [], cameras: [] });
    const [eventsPlaceCameraFilterList, setEventsPlaceCameraFilterList] = useState({ places: [], cameras: [] });
    const [searchActiveTab, setSearchActiveTab] = useState("");
    const [confidenceFilter, setConfidenceFilter] = useState('');

    const processFilterOptions = [
        { id: 'unprocessed', name: 'Unprocessed' },
        // {id: 'processed', name:'Processed'},
        { id: 'agree', name: 'Agree' },
        { id: 'disagree', name: 'Disagree' }
    ];

    const confidenceFilterOptions = [
        { id: '0.2-0.4', name: '20-40 %' },
        { id: '0.4-0.6', name: '40-60 %' },
        { id: '0.6-0.8', name: '60-80 %' }
    ];

    useEffect(() => {
        if (activeNotificationTab) {
            setActiveIndex(3);
        }
    }, [activeNotificationTab]);

    const resetActiveIndex = () => {
        setActiveIndex(0);
        handleChangeActiveNotiTab(false);
    }

    const organization = data.organizationId;

    useEffect(() => {
        getAllCameraList({
            variables: {
                organization,
                limit: 20,
                search: "",
                page: 1
            }
        });

        getAllPlaceList({
            variables: {
                organization,
                limit: 20,
                search: "",
                page: 1
            }
        });
    }, []);

    const [getAllCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: res => {
            if (res.getAllCameraList) {
                if (searchActiveTab === "notification") {
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, cameras: res.getAllCameraList.cameras ? res.getAllCameraList.cameras : [] });
                } else if (searchActiveTab === "events") {
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, cameras: res.getAllCameraList.cameras ? res.getAllCameraList.cameras : [] });
                } else {
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, cameras: res.getAllCameraList.cameras ? res.getAllCameraList.cameras : [] });
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, cameras: res.getAllCameraList.cameras ? res.getAllCameraList.cameras : [] });
                }
            }
        }
    });

    const [getAllPlaceList] = useLazyQuery(GET_PLACE_LIST, {
        "fetchPolicy": "no-cache",
        onCompleted: res => {
            if (res.getAllPlaceList) {
                if (searchActiveTab === "notification") {
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, places: res.getAllPlaceList.places ? res.getAllPlaceList.places : [] });
                } else if (searchActiveTab === "events") {
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, places: res.getAllPlaceList.places ? res.getAllPlaceList.places : [] });
                } else {
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, places: res.getAllPlaceList.places ? res.getAllPlaceList.places : [] });
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, places: res.getAllPlaceList.places ? res.getAllPlaceList.places : [] });
                }
            }
        }
    });

    const {
        data: processStepsList,
        loading: processDataLoading
    } = useQuery(GET_PROCESS_WORKFLOW_STEPS, {
        variables: {
            subscriptionId: data.id
        }
    }
    );

    useEffect(() => {
        if (aiTypes && aiTypes.length) {
            let modelFiltersOpt = aiTypes.map(itm => {
                let tmpObj = {};
                if (itm.type === "multi_object") {
                    tmpObj.id = "multi-object";
                } else {
                    tmpObj.id = itm.type;
                }
                tmpObj.name = itm.name;
                return tmpObj;
            });
            if (modelFiltersOpt.length) {
                setAiModelTypesFilters(modelFiltersOpt);
            }
        }
    }, [aiTypes]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setApiTimer(apiTimer + 1);
            ReCallAPIEvery30Second();
        }, 30000);
        return () => {
            clearTimeout(timer);
        };
    }, [apiTimer]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setNotiApiTimer(notiApiTimer + 1);
            ReCallNotificationApi();
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [notiApiTimer]);

    const ReCallAPIEvery30Second = () => {
        handleChangeTopEventsPage(null, topEventsPage);
        reloadTopEvents();
    }

    const ReCallNotificationApi = () => {
        let filterBy = {
            variables: {
                organization: data.organizationId,
            }
        }
        const cameraId = (notificationCameraFilterState && notificationCameraFilterState.camera_id) ? notificationCameraFilterState.camera_id : '';
        const placeId = (notificationPlaceFilterState && notificationPlaceFilterState.place_id) ? notificationPlaceFilterState.place_id : '';
        const modelType = notificationModelFilterState;
        let dateFilter = (notificationDateTimeFilterState) ? getLocalToUtcTimeStemp(notificationDateTimeFilterState) : '';
        let endDateFilter = (notificationEndDateFilterState) ? getLocalToUtcTimeStemp(notificationEndDateFilterState) : '';
        const tag = tagFilterState;
        let mlTemplate = notificationMlTemplateFilterState ? notificationMlTemplateFilterState : '';
        let confidence = confidenceFilter ? confidenceFilter : '0.0-1.0';
        let client = notificationClientFilterState ? notificationClientFilterState : '';
        if (cameraId) {
            filterBy.variables.cameraId = cameraId.toString();
        }
        if (placeId) {
            filterBy.variables.placeId = placeId;
        }
        if (modelType) {
            filterBy.variables.modelType = modelType.join();
        }
        if (dateFilter) {
            filterBy.variables.dateTime = dateFilter;
        }
        if (endDateFilter) {
            filterBy.variables.endTime = endDateFilter;
        }
        if (notificationProcessFilterState) {
            filterBy.variables.status = notificationProcessFilterState;
        }
        // if(tag){
        //     filterBy.variables.tag = tag;
        // }
        if (tag) {
            if (Object.keys(tag)[0] === 'whitelisted' || Object.keys(tag)[0] === 'blacklisted' || Object.keys(tag)[0] === 'unknown') {
                const objectForTagFilter = {};
                objectForTagFilter[Object.keys(tag)[0]] = `${Object.values(tag)[0]}:${Object.keys(tag)[0]}`;
                filterBy.variables.tag = objectForTagFilter;
            } else {
                filterBy.variables.tag = tag;
            }
        }
        if (confidence) {
            const confidenceValues = confidence.split("-");
            filterBy.variables.start_confidence = parseFloat(confidenceValues[0]);
            filterBy.variables.end_confidence = parseFloat(confidenceValues[1]);
        }
        if (client) {
            filterBy.variables.client = client;
        }
        filterBy.variables.page = notificationPage;
        return getNotificationListByFilter(filterBy);
    }

    const handleSelectNotificationRow = (evnt, id) => {
        selectedNotificationRow[id] = evnt.target.checked;
        setSelectNotificationRow(selectedNotificationRow);
    }

    const {
        loading: notificationLoading,
        data: notificationData,
        error: notificationError,
        refetch: reloadNotificationReport
    } = useQuery(GET_NOTIFICATION_REPORT, {
        fetchPolicy: "no-cache",
        variables: {
            page: 1,
            limit: 10,
            start_confidence: 0.0,
            end_confidence: 1.0,
            organization
        }
    });

    const [getTopEventListByFilter] = useLazyQuery(GET_TOP_EVENTS_BY_FILTER, {
        fetchPolicy: "no-cache",
        onError: error => {
            setTopEventsFilterLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            setTopEventsFilterLoading(false);
            setTopEventsFilter(true);
            setTopEventsFilterData(data.getTopEventsByFilter);
        }
    });

    const [getNotificationListByFilter] = useLazyQuery(GET_NOTIFICATION_REPORT, {
        fetchPolicy: "no-cache",
        onError: error => {
            setNotificationFilterLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            setNotificationFilterLoading(false);
            setNotificationFilter(true);
            setNotificationFilterData(data.getNotificationsReportList);
        }
    });

    const [acknowladgeMotification] = useMutation(ACKNOWLADGE_NOTIFICATION, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            reloadNotificationReport();
            Message.success('Notification acknowledged successfully');
        }
    });

    const [unAcknowladgeMotification] = useMutation(UNACKNOWLADGE_NOTIFICATION, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            reloadNotificationReport();
            Message.success('Notification unAcknowledged successfully');
        }
    });

    const handleAcknowledgeNotification = (type, id, action) => {
        const ids = [];
        if (type === 'multiple') {
            for (let key in selectedNotificationRow) {
                if (selectedNotificationRow[key])
                    ids.push(parseInt(key));
            }
        } else {
            ids.push(id);
        }
        if (ids.length) {
            if (action && action === 'unacknowledge') {
                return unAcknowladgeMotification({
                    variables: {
                        id: id,
                        isAcknowledged: false
                    }
                });
            } else {
                return acknowladgeMotification({
                    variables: {
                        ids: ids
                    }
                });
            }
        }
        Message.error('Please select notification!');
    }

    const handleNotificationFilterChange = (value, type) => {
        setNotificationFilterLoading(true);
        let filterBy = {
            variables: {
                organization: data.organizationId,
                limit: 10,
            }
        }
        let cameraId = (notificationCameraFilterState && notificationCameraFilterState.camera_id) ? notificationCameraFilterState.camera_id : '';
        let placeId = (notificationPlaceFilterState && notificationPlaceFilterState.place_id) ? notificationPlaceFilterState.place_id : '';
        let modelType = notificationModelFilterState;
        let dateFilter = (notificationDateTimeFilterState) ? getLocalToUtcTimeStemp(notificationDateTimeFilterState) : '';
        let endDateFilter = (notificationEndDateFilterState) ? getLocalToUtcTimeStemp(notificationEndDateFilterState) : '';
        let tag = tagFilterState;
        let list = tagList;
        let status = notificationProcessFilterState ? notificationProcessFilterState : null;
        let mlTemplate = notificationMlTemplateFilterState ? notificationMlTemplateFilterState : '';
        let confidence = confidenceFilter ? confidenceFilter : '0.0-1.0';
        let client = notificationClientFilterState ? notificationClientFilterState : '';
        if (type === 'camera') {
            cameraId = (value && value.camera_id) ? value.camera_id : ''
        } else if (type === 'place') {
            placeId = (value && value.place_id) ? value.place_id : '';
        } else if (type === 'model') {
            setClearTagFilter(!clearTagFilter);
            setTagFilterState(null);
            let tagFiltersList = (tagsFilter) ? tagsFilter : FILTER_TAGS_BY_MODEL;
            if (value && !unCannyFilters.includes(value) && tagFiltersList[value]) {
                setShowTagsFilter(true);
                setTagList(tagFiltersList[value]);
            } else {
                setShowTagsFilter(false);
                setTagList([]);
            }
            modelType = (value) ? value : '';
            tag = null;
        } else if (type === 'date') {
            dateFilter = (value) ? value : '';
        } else if (type === 'end_date') {
            endDateFilter = (value) ? value : '';
        } else if (type === 'tag') {
            tag = (value) ? value : '';
        } else if (type === 'list') {
            list = (value) ? value : '';
        } else if (type === 'status') {
            status = (value) ? value : '';
        } else if (type === 'confidence') {
            confidence = (value) ? value : '';
        } else if (type === 'client') {
            client = (value) ? value : '';
        }
        if (cameraId) {
            filterBy.variables.cameraId = cameraId.toString();
        }
        if (placeId) {
            filterBy.variables.placeId = placeId;
        }
        if (modelType) {
            filterBy.variables.modelType = modelType.join();
        }
        if (dateFilter) {
            filterBy.variables.dateTime = dateFilter;
        }
        if (endDateFilter) {
            filterBy.variables.endTime = endDateFilter;
        }
        if (client) {
            filterBy.variables.client = client;
        }
        if (tag) {
            if (Object.keys(tag)[0] === 'whitelisted' || Object.keys(tag)[0] === 'blacklisted' || Object.keys(tag)[0] === 'unknown') {
                const objectForTagFilter = {};
                objectForTagFilter[Object.keys(tag)[0]] = `${Object.values(tag)[0]}:${Object.keys(tag)[0]}`;
                filterBy.variables.tag = objectForTagFilter;
            } else {
                filterBy.variables.tag = tag;
            }
        }
        if (status) {
            filterBy.variables.status = status;
        }
        if (confidence) {
            const confidenceValues = confidence.split("-");
            filterBy.variables.start_confidence = parseFloat(confidenceValues[0]);
            filterBy.variables.end_confidence = parseFloat(confidenceValues[1]);
        }
        setNotificationPage(1);
        filterBy.variables.page = 1;
        setNotiApiTimer(notiApiTimer + 1);
        return getNotificationListByFilter(filterBy);
    }

    const handleChangeNotificationPage = (event, value) => {
        setNotificationFilterLoading(true);
        setNotificationPage(value);
        let filterBy = {
            variables: {
                organization: data.organizationId,
                limit: 10,
            }
        }
        const cameraId = (notificationCameraFilterState && notificationCameraFilterState.camera_id) ? notificationCameraFilterState.camera_id : '';
        const placeId = (notificationPlaceFilterState && notificationPlaceFilterState.place_id) ? notificationPlaceFilterState.place_id : '';
        const modelType = notificationModelFilterState;

        let dateFilter = (notificationDateTimeFilterState) ? getLocalToUtcTimeStemp(notificationDateTimeFilterState) : '';
        let endDateFilter = (notificationEndDateFilterState) ? getLocalToUtcTimeStemp(notificationEndDateFilterState) : '';
        const tag = tagFilterState;
        let mlTemplate = notificationMlTemplateFilterState ? notificationMlTemplateFilterState : '';
        let status = notificationProcessFilterState ? notificationProcessFilterState : null;
        let confidence = confidenceFilter ? confidenceFilter : '0.0-1.0';
        let client = notificationClientFilterState ? notificationClientFilterState : '';

        if (cameraId) {
            filterBy.variables.cameraId = cameraId.toString();
        }
        if (placeId) {
            filterBy.variables.placeId = placeId;
        }
        if (modelType) {
            filterBy.variables.modelType = modelType.join();
        }
        if (dateFilter) {
            filterBy.variables.dateTime = dateFilter;
        }
        if (endDateFilter) {
            filterBy.variables.endTime = endDateFilter;
        }

        if (tag) {
            if (Object.keys(tag)[0] === 'whitelisted' || Object.keys(tag)[0] === 'blacklisted' || Object.keys(tag)[0] === 'unknown') {
                const objectForTagFilter = {};
                objectForTagFilter[Object.keys(tag)[0]] = `${Object.values(tag)[0]}:${Object.keys(tag)[0]}`;
                filterBy.variables.tag = objectForTagFilter;
            } else {
                filterBy.variables.tag = tag;
            }
        }
        if (mlTemplate) {
            console.log('will implement once api done', mlTemplate)
        }
        if (status) {
            filterBy.variables.status = status;
        }
        if (confidence) {
            const confidenceValues = confidence.split("-");
            filterBy.variables.start_confidence = parseFloat(confidenceValues[0]);
            filterBy.variables.end_confidence = parseFloat(confidenceValues[1]);
        }
        if (client) {
            filterBy.variables.client = client;
        }
        filterBy.variables.page = value;
        setNotiApiTimer(notiApiTimer + 1);
        return getNotificationListByFilter(filterBy);
    }

    const handleAutoCamPlaceSearch = (type, value) => {
        if (type === "camera") {
            return getAllCameraList({
                variables: {
                    organization,
                    search: value,
                    limit: 20,
                    page: 1
                }
            });
        } else if (type === "place") {
            return getAllPlaceList({
                variables: {
                    organization,
                    search: value,
                    limit: 20,
                    page: 1
                }
            });
        }
    };

    const notiReportFilterList = [
        {
            label: t('cameraFilter.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    setSearchActiveTab("notification");
                    handleAutoCamPlaceSearch("camera", "");
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, cameras: [] });
                }
                setNotificationCameraFilterState(newValue);
                handleNotificationFilterChange(newValue, 'camera');
            },
            handleClose: (evt) => {
                setSearchActiveTab("notification");
                handleAutoCamPlaceSearch("camera", "");
                setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, cameras: [] });
            },
            val: notificationCameraFilterState,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            options: notiPlaceCameraFilterList.cameras,
            type: "autocomplete",
            idKey: "camera_id",
            onInputChange: val => {
                handleAutoCamPlaceSearch("camera", val);
                setSearchActiveTab("notification");
                setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, cameras: [] });
            }
        },
        {
            label: t('placesFilter.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    setSearchActiveTab("notification");
                    handleAutoCamPlaceSearch("place", "");
                    setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, places: [] });
                }
                setNotificationPlaceFilterState(newValue);
                handleNotificationFilterChange(newValue, 'place');
            },
            handleClose: (evt) => {
                setSearchActiveTab("notification");
                handleAutoCamPlaceSearch("place", "");
                setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, places: [] });
            },
            val: notificationPlaceFilterState,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            type: "autocomplete",
            idKey: "place_id",
            options: notiPlaceCameraFilterList.places,
            onInputChange: val => {
                handleAutoCamPlaceSearch("place", val);
                setSearchActiveTab("notification");
                setNotiPlaceCameraFilterList({ ...notiPlaceCameraFilterList, places: [] });
            }
        },
        {
            label: t('startDate.label'),
            handleChange: newDate => {
                let dateTime = (newDate) ? getLocalToUtcTimeStemp(newDate) : '';
                setNotificationDateTimeFilterState(newDate);
                handleNotificationFilterChange(dateTime, 'date');
            },
            val: notificationDateTimeFilterState,
            styleOverrides: { ...marginGenerator("mr-12") },
            name: 'date_time_filter',
            type: 'input'
        },
        {
            label: t('endDate.label'),
            handleChange: newDate => {
                let dateTime = (newDate) ? getLocalToUtcTimeStemp(newDate) : '';
                setNotificationEndDateFilterState(newDate);
                handleNotificationFilterChange(dateTime, 'end_date');
            },
            val: notificationEndDateFilterState,
            name: 'end_date_filter',
            styleOverrides: { ...marginGenerator("mr-12") },
            type: 'input'
        },
        {
            label: t('modelFilter.label'),
            handleChange: evt => {
                setNotificationModelFilterState(evt.target.value);
                handleNotificationFilterChange(evt.target.value, 'model');
            },
            val: notificationModelFilterState,
            styleOverrides: { minWidth: 220, maxWidth: 220 },
            options: aiModelTypesFilters
        },
        // {
        //     label: t('mlTempplate.label'),
        //     handleChange: evt => {
        //         setNotificationMlTemplateFilterState(evt.target.value);
        //         handleNotificationFilterChange(evt.target.value, 'mlTemplate');
        //     },
        //     val: notificationMlTemplateFilterState,
        //     styleOverrides: { minWidth: 220, ...marginGenerator("mt-15")},
        //     options: mlTemplateList
        // }
        {
            label: t('process.label'),
            handleChange: evt => {
                setNotificationProcessFilterState(evt.target.value);
                handleNotificationFilterChange(evt.target.value, 'status');
            },
            val: notificationProcessFilterState,
            styleOverrides: { minWidth: 220, ...marginGenerator("mt-15") },
            options: processFilterOptions
        },
        {
            label: 'Confidence level',
            handleChange: evt => {
                setConfidenceFilter(evt.target.value);
                handleNotificationFilterChange(evt.target.value, 'confidence');
            },
            val: confidenceFilter,
            styleOverrides: { minWidth: 220, ...marginGenerator("mt-15") },
            options: confidenceFilterOptions
        }
    ];

    if (data.organizationId === EDGE_AI_ORGS.CTRACK) {
        notiReportFilterList.push({
            label: 'Filter By Client',
            handleChange: evt => {
                const client = evt.target.value;
                setNotificationClientFilterState(client);
                handleNotificationFilterChange(client, 'client');
            },
            val: notificationClientFilterState,
            styleOverrides: { minWidth: 220, marginTop: 15 },
            options: CLIENT_FILTER_OPTIONS
        });
    }

    const handleTopEventsFilterChange = (value, type) => {
        setTopEventsFilterLoading(true);
        let filterBy = {
            variables: {
                organization: data.organizationId,
            }
        }
        let cameraId = (topEventsCameraFilterState && topEventsCameraFilterState.camera_id) ? topEventsCameraFilterState.camera_id : '';
        let placeId = (topEventsPlaceFilterState && topEventsPlaceFilterState.place_id) ? topEventsPlaceFilterState.place_id : '';
        let modelType = topEventsModelFilterState;
        let dateFilter = (topEventsDateTimeFilterState) ? getLocalToUtcTimeStemp(topEventsDateTimeFilterState) : '';
        let endDateFilter = (topEventsEndDateFilterState) ? getLocalToUtcTimeStemp(topEventsEndDateFilterState) : '';
        let tag = tagFilterState;
        let list = tagList;
        if (type === 'camera') {
            cameraId = (value && value.camera_id) ? value.camera_id : ''
        } else if (type === 'place') {
            placeId = (value && value.place_id) ? value.place_id : '';
        } else if (type === 'model') {
            setClearTagFilter(!clearTagFilter);
            setTagFilterState(null);
            let tagFiltersList = (tagsFilter) ? tagsFilter : FILTER_TAGS_BY_MODEL;
            if (value && !unCannyFilters.includes(value) && tagFiltersList[value]) {
                setShowTagsFilter(true);
                setTagList(tagFiltersList[value]);
            } else {
                setShowTagsFilter(false);
                setTagList([]);
            }
            modelType = (value) ? value : '';
            tag = null;
        } else if (type === 'date') {
            dateFilter = (value) ? value : '';
        } else if (type === 'end_date') {
            endDateFilter = (value) ? value : '';
        } else if (type === 'tag') {
            tag = (value) ? value : '';
        } else if (type === 'list') {
            list = (value) ? value : '';
        }
        if (cameraId) {
            filterBy.variables.cameraId = cameraId.toString();
        }
        if (placeId) {
            filterBy.variables.placeId = placeId;
        }
        if (modelType) {
            filterBy.variables.modelType = modelType;
        }
        if (dateFilter) {
            filterBy.variables.dateTime = dateFilter;
        }
        if (endDateFilter) {
            filterBy.variables.endTime = endDateFilter;
        }
        if (tag) {
            if (Object.keys(tag)[0] === 'whitelisted' || Object.keys(tag)[0] === 'blacklisted' || Object.keys(tag)[0] === 'unknown') {
                const objectForTagFilter = {};
                objectForTagFilter[Object.keys(tag)[0]] = `${Object.values(tag)[0]}:${Object.keys(tag)[0]}`;
                filterBy.variables.tag = objectForTagFilter;
            } else {
                filterBy.variables.tag = tag;
            }
        }
        setTopEventsPage(1);
        filterBy.variables.page = 1;
        return getTopEventListByFilter(filterBy);
    }

    const handleChangeTopEventsPage = (event, value) => {
        let filterBy = {
            variables: {
                organization: data.organizationId,
            }
        }
        const cameraId = (topEventsCameraFilterState && topEventsCameraFilterState.camera_id) ? topEventsCameraFilterState.camera_id : '';
        const placeId = (topEventsPlaceFilterState && topEventsPlaceFilterState.place_id) ? topEventsPlaceFilterState.place_id : '';
        const modelType = topEventsModelFilterState;

        let dateFilter = (topEventsDateTimeFilterState) ? getLocalToUtcTimeStemp(topEventsDateTimeFilterState) : '';
        let endDateFilter = (topEventsEndDateFilterState) ? getLocalToUtcTimeStemp(topEventsEndDateFilterState) : '';
        const tag = tagFilterState;
        if (cameraId) {
            filterBy.variables.cameraId = cameraId.toString();
        }
        if (placeId) {
            filterBy.variables.placeId = placeId;
        }
        if (modelType) {
            filterBy.variables.modelType = modelType;
        }
        if (dateFilter) {
            filterBy.variables.dateTime = dateFilter;
        }
        if (endDateFilter) {
            filterBy.variables.endTime = endDateFilter;
        }
        if (tag) {
            filterBy.variables.tag = tag;
        }
        filterBy.variables.page = value;
        if (event === null) {
            setApiTimer(apiTimer + 1);
        } else {
            setTopEventsPage(value);
            setTopEventsFilterLoading(true);
        }
        return getTopEventListByFilter(filterBy);
    }

    const topEventsFilterList = [
        {
            label: t('cameraFilter.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    handleAutoCamPlaceSearch("camera", newValue);
                    setSearchActiveTab("events");
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, cameras: [] });
                }
                setTopEventsCameraFilterState(newValue);
                handleTopEventsFilterChange(newValue, 'camera');
            },
            val: topEventsCameraFilterState,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            type: "autocomplete",
            idKey: "camera_id",
            options: eventsPlaceCameraFilterList.cameras,
            onInputChange: val => {
                handleAutoCamPlaceSearch("camera", val);
                setSearchActiveTab("events");
                setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, cameras: [] });
            }
        },
        {
            label: t('placesFilter.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    setSearchActiveTab("events");
                    handleAutoCamPlaceSearch("place", "");
                    setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, places: [] });
                }
                setTopEventsPlaceFilterState(newValue);
                handleTopEventsFilterChange(newValue, 'place');
            },
            val: topEventsPlaceFilterState,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            type: "autocomplete",
            idKey: "place_id",
            options: eventsPlaceCameraFilterList.places,
            onInputChange: val => {
                handleAutoCamPlaceSearch("place", val);
                setSearchActiveTab("events");
                setEventsPlaceCameraFilterList({ ...eventsPlaceCameraFilterList, places: [] });
            }
        },
        {
            label: t('startDate.label'),
            handleChange: newDate => {
                let dateTime = (newDate) ? getLocalToUtcTimeStemp(newDate) : '';
                setTopEventsDateTimeFilterState(newDate);
                handleTopEventsFilterChange(dateTime, 'date');
                setApiTimer(apiTimer + 1);
            },
            val: topEventsDateTimeFilterState,
            styleOverrides: { ...marginGenerator("mr-12") },
            name: 'date_time_filter',
            type: 'input'
        },
        {
            label: t('endDate.label'),
            handleChange: newDate => {
                let dateTime = (newDate) ? getLocalToUtcTimeStemp(newDate) : '';
                setTopEventsEndDateFilterState(newDate);
                handleTopEventsFilterChange(dateTime, 'end_date');
                setApiTimer(apiTimer + 1);
            },
            val: topEventsEndDateFilterState,
            name: 'end_date_filter',
            styleOverrides: { ...marginGenerator("mr-12") },
            type: 'input'
        },
        {
            label: t('modelFilter.label'),
            handleChange: evt => {
                setTopEventsModelFilterState(evt.target.value);
                handleTopEventsFilterChange(evt.target.value, 'model');
                setApiTimer(apiTimer + 1);
            },
            val: topEventsModelFilterState,
            styleOverrides: { minWidth: 220, maxWidth: 220 },
            options: aiModelTypesFilters
        }
    ];

    const {
        loading: topEventsLoading,
        data: topEventsData,
        error: topEventsError,
        refetch: reloadTopEvents
    } = useQuery(GET_TOP_EVENTS, {
        fetchPolicy: "no-cache",
        variables: {
            organization: organization
        }
    });

    useEffect(() => {
        if (location.state) setActiveIndex(location.state.activeIndex);
    }, [location]);

    useEffect(() => {
        setTopEventsFilterLoading(true);
        getTopEventListByFilter({
            variables: {
                organization: organization,
                placeId: '',
                cameraId: '',
                modelType: '',
                dateTime: null,
                endTime: null,
                page: 1,
                limit: 10
            }
        });
    }, []);

    const handleShowEventTags = (tags) => {
        setActiveEventTags(tags);
        setEventTagModalState(true);
    }

    const handleSearchByTag = (tag) => {
        if (tag) {
            setTagFilterState(tag);
            handleNotificationFilterChange(tag, 'tag');
        }
        return false;
    }

    const handleSearchByList = (list) => {
        if (list) {
            setTagList(list);
            handleNotificationFilterChange(list, 'list');
        }
        return false;
    }

    const currentTabValue = tabValue => {
        setActiveIndex(tabValue);
    };

    const tabList = [
        {
            label: t('organization.label'),
            children: (
                <>
                    <OrganizationEventsPanel
                        t={t}
                        organization={data.organizationId}
                        top10Events={!topEventsLoading && !topEventsError ? topEventsData.getTopEvents : []}
                        handleImageModalClick={handleSetImageModalSrc}
                        handleShowEventTags={handleShowEventTags}
                        aiModelTypesFilters={aiModelTypesFilters}
                    />
                </>
            )
        },
        {
            label: t('places.label'),
            children: (
                <>
                    <PlaceEventsPanel
                        t={t}
                        organization={data.organizationId}
                        top10Events={!topEventsLoading && !topEventsError ? topEventsData.getTopEvents : []}
                        last10DaysEvents={[]}
                        handleImageModalClick={handleSetImageModalSrc}
                        handleShowEventTags={handleShowEventTags}
                        aiModelTypesFilters={aiModelTypesFilters}
                    />
                </>
            )
        },
        {
            label: t('cameras.label'),
            children: (
                <>
                    <CameraEventsPanel
                        t={t}
                        organization={data.organizationId}
                        top10Events={!topEventsLoading && !topEventsError ? topEventsData.getTopEvents : []}
                        handleImageModalClick={handleSetImageModalSrc}
                        handleShowEventTags={handleShowEventTags}
                        aiModelTypesFilters={aiModelTypesFilters}
                    />
                </>
            )
        },
        {
            label: t('notification.label'),
            children: (
                <>
                    <NotificationEventsPanel
                        t={t}
                        organization={data.organizationId}
                        list={!notificationLoading && !notificationError ? notificationFilter === false ? notificationData.getNotificationsReportList : notificationFilterData : {}}
                        filterList={notiReportFilterList}
                        loading={notificationLoading}
                        error={notificationError}
                        filterLoading={notificationFilterLoading}
                        handleImageModalClick={handleSetImageModalSrc}
                        selectedNotificationRow={selectedNotificationRow}
                        setSelectNotificationRow={setSelectNotificationRow}
                        handleSelectNotificationRow={handleSelectNotificationRow}
                        handleAcknowledgeNotification={handleAcknowledgeNotification}
                        handleShowEventTags={handleShowEventTags}
                        page={notificationPage}
                        handlePageChange={handleChangeNotificationPage}
                        showTagsFilter={showTagsFilter}
                        tagList={tagList}
                        handleSearchByTag={handleSearchByTag}
                        clearTagFilter={clearTagFilter}
                        aiModelTypesFilters={aiModelTypesFilters}
                        handleSearchByList={handleSearchByList}
                        modelType={notificationModelFilterState ? notificationModelFilterState : ""}
                        reloadNotificationReportFunc={reloadNotificationReport}
                        statusFilter={notificationProcessFilterState}
                        processStepsList={processStepsList && processStepsList.getProcessWorkFlowSteps ? processStepsList.getProcessWorkFlowSteps : []}
                    />
                </>
            )
        },
        {
            label: t('events.label'),
            children: (
                <>
                    <EventsPanel
                        t={t}
                        list={topEventsFilterData || {}}
                        filterList={topEventsFilterList}
                        loading={topEventsLoading}
                        error={topEventsError}
                        filterLoading={topEventsFilterLoading}
                        handleImageModalClick={handleSetImageModalSrc}
                        handleShowEventTags={handleShowEventTags}
                        page={topEventsPage}
                        handlePageChange={handleChangeTopEventsPage}
                        showTagsFilter={showTagsFilter}
                        tagList={tagList}
                        handleSearchByTag={handleSearchByTag}
                        handleSearchByList={handleSearchByList}
                    />
                </>
            )
        },
        {
            label: t('faces.label'),
            children: (
                <EdgeAIFacesPanel
                    t={t}
                    organization={organization}
                    handleShowEventTags={handleShowEventTags}
                    handleImageModalClick={handleSetImageModalSrc}
                />
            )
        }
    ];

    return (
        <div>
            {/* <CustomTabsForEdgeAIReportPanel
                resetActiveIndex={resetActiveIndex}
                activeIndex={activeIndex}
                currentTabValue={currentTabValue}
                tabs={tabList}
            /> */}
            {selectedPath === "Reporting" && <> {tabList[subActiveIndex].children}</>}
            <EventTagsModal
                open={eventTagModalState}
                handleClose={() => {
                    setEventTagModalState(false);
                    setActiveEventTags({});
                }}
                tags={activeEventTags} />
        </div>
    );
};

EdgeAIReportPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default EdgeAIReportPanel;
