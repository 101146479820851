import React from 'react';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import GenericTable from '../../../../components/GenericTable';
import { dateFormatterLLL } from '../../../../utils';
import { IconButton } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import Statcard from '../../../../components/Statcard';
function EdgeAICustomConfig({ 
    t,
    list,
    loading,
    allowConfigure,
    handleSearch,
    queryState,
    handlePageChange,
    handleDeleteTransection,
    handleCreateButtonClick,
    handleFilterSubmit
}) {

    const [transectionList, setTransectionList] = React.useState([]);
    
    let columns = [
        {
            label:t('customerName.label'),
            key:"customerName"
        },
        {
            label:t('amount.label'),
            key:"amount"
        },
        {
            label:t('description.label'),
            key:"description"
        },
        {
            label:t('createdBy.label'),
            key:"createdByName"
        },
        {
            label:t('createdDate.label'),
            key:"createdAt"
        }
    ];
    
    if(allowConfigure){
        columns.push({
            label: t('delete.label'),
            key:"button"
        });
    }

    React.useEffect(() => {
        if(list && list.getTransections){
            const transData = list.getTransections.data.map(itm => {
                let tpmObj = {
                    ...itm,
                    createdAt: dateFormatterLLL(itm.createdAt)
                }
                if(allowConfigure){
                    tpmObj.button = <IconButton onClick={evt => handleDeleteTransection(itm)} color="secondary" ><DeleteForever /> </IconButton>
                }
                return tpmObj;
            });
            setTransectionList(transData);
        }
    }, [list]);

    return (
        <div>
            {allowConfigure && 
                <Grid container justify={'space-between'}>
                    <Grid item xs={1}>
                        <SearchBox handleSearch={val => handleSearch(val, "search")} />
                    </Grid>
                    <Grid item>
                        <DateTimeSelector 
                            inputStyle={{ padding: 10, minWidth: 225 }} 
                            label={t("startDate.label")} 
                            dateFormat={"yyyy-MM-dd"} 
                            value={queryState.startDate} 
                            handleChange={val => handleSearch(val, "startDate")} 
                            disableFuture={true} 
                        />
                        <DateTimeSelector 
                            inputStyle={{ padding: 10, minWidth: 225 }} 
                            label={t("endDate.label")} 
                            dateFormat={"yyyy-MM-dd"} 
                            value={queryState.endDate} 
                            handleChange={val => handleSearch(val, "endDate")} 
                            styleOverrides={{marginLeft: 15}}  
                            disableFuture={true} 
                        />
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            style={{marginLeft: 15}}
                            disabled={loading}
                            onClick={handleFilterSubmit}>
                            {t('submit.label')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() =>
                            handleCreateButtonClick('transection')
                        }>
                            {t('createTrans.label')}
                        </Button>
                    </Grid>
                </Grid>      
            }
            <Grid container style={{marginBottom: 20, marginTop: 24}} >
                <Statcard
                    statLabel={"Total Amount"}
                    statValue={(list && list.getTransections && list.getTransections.totalAmount) ? list.getTransections.totalAmount : 0 }
                />
                <GenericTable 
                    columns={columns} 
                    list={transectionList} 
                    loading={loading} 
                />
                { !loading &&
                    <>
                        <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                            <Grid item xs={2}>
                                <span>Total {Math.ceil(get(list, 'getTransections.count', null)/20)} page,  {get(list, 'getTransections.count', null)} Row</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Pagination 
                                    color="secondary"
                                    count={(list && list.getTransections) ? Math.ceil(list.getTransections.count/20) : 1} 
                                    page={queryState.page} 
                                    onChange={(evt, value) => {
                                        handlePageChange("transection", value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        </div>
    );
}

EdgeAICustomConfig.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default EdgeAICustomConfig;
