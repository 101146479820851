import React, {useState, useEffect, useContext, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import { useReactToPrint } from 'react-to-print';
import {useMutation} from '@apollo/react-hooks';
import { get } from "lodash-es";
import {useFormik} from "formik";
import * as Yup from "yup";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Input } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import OutlinedSelect from '../../../../../.././components/OutlinedSelect';
import { paddingGenerator } from '../../../../../../theme/utils';
import Message from '../../../../../../components/Message';
import {
    PRINT_EXIT_OF_SHAYONA_TRIP_TRUCK,
    CAPTURE_GROSS_WEIGHT_OF_SHAYONA_TRIP_TRUCK
  } from '../../../../Shayona/mutations';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';
import PrintSlip from '../../components/PrintSlip';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%",
    }
  });
  
  const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        type="submit"
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Print'}
        </Button>
    );
  }

  function ButtonComponentOne(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        type="submit"
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Capture gross weight'}
        </Button>
    );
  }

const initStep = {brand_id: "", quantity: ""};

const ExitPrintSlipOfTruck = ({
    t,
    open,
    onClose,
    data
}) => {

    const classes = useStyles();
    const [isCaptureWeightBtnPress, setIsCaptureWeightBtnPress] = useState(false);
    const [brandStepsState, setBrandStepsState] = useState([{...initStep}]);

    const {organizationId,
        reloadShayonaApisOrPagination,
        shayonaBrandsData
             } = useContext(ShayonaCementsContext);
  
    const formik = useFormik({
      initialValues: {
        grossWeight: ''
      },
      enableReinitialize:true,
      onSubmit: (values) => {
        if(!isCaptureWeightBtnPress){
         handleSubmtiOfTruckDetails('gross',values);
        } else {
         handleSubmtiOfTruckDetails('exit',values);
        }
      }, 
    });

   const {handleSubmit, handleBlur, handleChange, touched, errors, values, resetForm, setFieldValue } = formik;


    const [captureGrossWeight, { loading: captureGrossWeightLoading }] = useMutation(CAPTURE_GROSS_WEIGHT_OF_SHAYONA_TRIP_TRUCK, {
        onError: error => {
            console.log('err', error);
        },
        onCompleted: data => {
             setIsCaptureWeightBtnPress(true);
             setFieldValue('grossWeight', data && data.captureGrossWeightTripTruck.gross_weight);
        }
    });

    const [exitPrintOfTrip, { loading: exitPrintLoading }] = useMutation(PRINT_EXIT_OF_SHAYONA_TRIP_TRUCK, {
        onError: error => {
            console.log('err', error);

        },
        onCompleted: data => {
            handlePrint();
            Message.success(data && data.printExitSlipTripTruck.message);
            onClose();
            reloadShayonaApisOrPagination('trip','');
        }
      });

      const handleSubmtiOfTruckDetails = (type,values) => {
        if(type === 'exit'){
          const isBrandHaveValue = brandStepsState.filter(el => el.brand_id && el.quantity !== '');
          exitPrintOfTrip({
           variables:{
               organization: organizationId,
               tripId: data && data.trip_id,
               grossWeight: values && values.grossWeight,
               brands:isBrandHaveValue.length >0 ? brandStepsState : []
           }
         })
        } else if(type === 'gross'){
         captureGrossWeight({
             variables:{
               organization: organizationId,
               tripId: data && data.trip_id
           }
         })
        }
      };

      const componentRef = useRef();
      const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

      const handleRemoveMultiData = (idx) => {
        let updatedState = [...brandStepsState];
        updatedState.splice(idx, 1);
        setBrandStepsState(updatedState);
      };

      const handleInputChange = (e, indx) => {
        const { name, value } = e.target;
        
        const updatedState = [...brandStepsState];
        updatedState[indx][name] = value;
        setBrandStepsState(updatedState);
    }
     
      useEffect(() => {
       if(!open){
         resetForm();
         setIsCaptureWeightBtnPress(false);
         setBrandStepsState([{...initStep}]);
       }
      }, [open, resetForm]);
      
  return (
    <> 
    <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Exit slip process
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
                <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={6}>
                                    {
                                      isCaptureWeightBtnPress ? (
                                        <TextField
                                        value={values.grossWeight}
                                        label="Captured gross weight" 
                                        name="grossWeight"
                                        style={{ width: '100%'}}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.grossWeight && errors.grossWeight}
                                        error={touched.grossWeight && Boolean(errors.grossWeight)}
                                        disabled
                                      />
                                      ) : (
                                        <ButtonComponentOne
                                          t={t}
                                          loading={captureGrossWeightLoading}
                                       />
                                      )
                                    }
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant={'outlined'}
                                      color="primary"
                                      onClick={() => setBrandStepsState(oldArray => [...oldArray, {...initStep}])}
                                  >
                                      Add Brand
                                  </Button>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  {
                                    brandStepsState.map((step, idx)=>{
                                      return(
                                        <React.Fragment key={`step_${idx}`}>
                                            <Grid item xs={1}>
                                                <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                    Brand {idx+1}
                                                </Typography>
                                            </Grid>
                                        <Grid item xs={5}>
                                        <OutlinedSelect
                                          val={step.brand_id}
                                          label="Select Brand"
                                          handleChange={evt => handleInputChange(evt, idx)}
                                          selectStyle={{...paddingGenerator('p-8')}}
                                          styleOverrides={{ width: '100%', marginRight: 0, height: 50 }}
                                          options={get(shayonaBrandsData, 'getBrandsList.data', []) ?? []}
                                          name="brand_id"
                                        />
                                        </Grid>
                                        <Grid item xs={3}>
                                           <Input
                                                value={step.quantity}
                                                type={'text'}
                                                placeholder="Quantity"
                                                style={{width: '100%'}}
                                                color={'secondary'}
                                                name="quantity"
                                                onChange={evt => handleInputChange(evt, idx)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                          {
                                              idx!== 0 && (
                                                  <IconButton 
                                                      onClick={evt => handleRemoveMultiData(idx)} 
                                                      aria-label="delete"
                                                      style={{ float: 'right'}}
                                                  >
                                                      <DeleteIcon color="secondary"/>
                                                  </IconButton>
                                              )
                                          }
                                       </Grid>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                  </Grid>
                                <DialogActions>
                                  <Grid container justify={'space-between'}>
                                      <Button 
                                          variant={'outlined'}
                                          color="primary"
                                          onClick={onClose}
                                      >
                                          {t('cancel.label')}
                                      </Button>
                                      <ButtonComponent
                                       t={t}
                                       loading={exitPrintLoading}
                                      />
                                  </Grid>          
                              </DialogActions>
                            </form>
        </DialogContent>
    </Dialog>
    <div style={{ display: "none" }}>
        <PrintSlip
            t={t}
            type="exit"
            ref={componentRef}
            truckDetails={data}
            formDetails={values}
        />
    </div>
    </>
  )
}

export default ExitPrintSlipOfTruck