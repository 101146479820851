import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody'; 
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import SesiroKycForm from './SesiroKycForm';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../../components/CanvasSignaturePadModal';
import { GET_USER_INFO } from '../../../../../../TicketingSystem/queries';
import Message from '../../../../../../../components/Message';
import { GET_SESIRO_KYC } from '../../../queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CREATE_SESIRO_KYC, DELETE_SESIRO_KYC } from '../../../mutation';
import SesiroKycPanelItem from './SesiroKycPanelItem';

const useStyles = makeStyles(theme => ({
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  },
  dateInput: {
      width: 180,
      minWidth: 180,
      padding: 10
  },
}));

const SesiroKycPanel = ({
    t,
    data,
    allowConfigure,
    subscriptionUserData,
}) => {

    const classes = useStyles();

    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedSesiroKycItem, setSelectedSesiroKycItem] = useState(null);
    const [briefModalOpen, setBriefModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [sesiroKycModalOpen, setSesiroKycModalOpen] = useState(false);
    const [signatureState, setSignatureState] = useState({InsuredSignature: ""});

    const subscriptionId = data.id;
    // const {
    //     data: userdata,
    //     loading: userDataLoading,
    //     error: userDataError
    // } = useQuery(GET_USER_INFO);

    const [getAllSesiroKycs, {
        loading,
        data: sesiroKycList
        }] = useLazyQuery(GET_SESIRO_KYC, {
            fetchPolicy: "no-cache"
        });
    
        useEffect(() => {
            return getAllSesiroKycs({variables: {...filterStateData, subscriptionId}});
        }, []);
        
        useEffect(() => {
            getAllSesiroKycs({variables: {...filterStateData, subscriptionId}});
        }, [filterStateData, subscriptionId]);

        const [createSesiroKyc] = useMutation(CREATE_SESIRO_KYC, {
            onError: error => {
                Message.error(error);
            },
            onCompleted: () => {
                Message.success('Sesiro Kyc created/updated successfully');
                setCRModalState({clearData: true, btnLoading: false});
                return getAllSesiroKycs({variables: {...filterStateData, subscriptionId}});
            }
        });
    
        const [deleteSesiroKyc, { loading: deleteSesiroKycLoading }] = useMutation(DELETE_SESIRO_KYC, {
            onError: error => {
                Message.error(error);
            },
            onCompleted: () => {
                Message.success('Sesiro Kyc deleted successfully');
                setSesiroKycModalOpen(false);
                return getAllSesiroKycs({variables: {...filterStateData, subscriptionId}});
            }
        });

        const handleNewSesiroKyc = () => {
            setSesiroKycModalOpen(true);
        }

        const handleMenuItemClick = (type, rowItem) => {
            setCRModalState({clearData: false, btnLoading: false});
            setSelectedSesiroKycItem(rowItem);
            if(type === "sign"){
                setSignaturePadModalOpen(true);
                setSignatureType(type);
            }else if(type === "edit"){
                setSesiroKycModalOpen(true)
            }else if(type === 'delete'){
                if (
                    window.confirm('Are you sure you want to close this Sesiro Kyc ?') ===
                    false
                ) {
                    return false;
                }
                setSelectedSesiroKycItem(null);
                return deleteSesiroKyc({
                    variables: {
                        id : rowItem._id
                    }
                })
            }
        }

        const handleCreateChangeRequestSubmit = (formData) => {
            formData.subscriptionId = subscriptionId;
            return createSesiroKyc({ variables: {
                ...formData
            }});
        }

    const columns = [
        {
            label: 'First Names',
            key: "firstNames"
        },
        {
            label: 'Surname',
            key: "surname"
        },
        {
            label: 'Title',
            key: "title"
        },
        {
            label: "Gender",
            key: "gender"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];


    const handleOpenSignPanModal = (type) => {
    setSignaturePadModalOpen(true);
    setSignatureType(type);
    }

    const filterList = [
        {
          label: t('search.label'),
          handleChange: evt => {
            console.log('evt.target.value: ', evt.target.value);
            setFilterStateData({ ...filterStateData, search: evt.target.value });
          },
          val: filterStateData.search,
          type: 'text',
          styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 }
        },
        {
          label: t('startDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, startDate: newDate });
          },
          val: filterStateData.startDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        },
        {
          label: t('endDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, endDate: newDate });
          },
          val: filterStateData.endDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        }
      ];

    return (
    <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewSesiroKyc}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                { !loading ? sesiroKycList && sesiroKycList.getAllSesiroKycs && sesiroKycList.getAllSesiroKycs.data.length > 0 ?
                            sesiroKycList.getAllSesiroKycs.data.map((rowitem, rowIndex) => (
                        <SesiroKycPanelItem
                            key={rowIndex}
                            t={t}
                            data={rowitem}
                            handleMenuItemClick={handleMenuItemClick}
                            allowConfigure={allowConfigure}
                        />
                    )) :
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                        </TableCell>
                    </TableRow>: null
                }
                {loading && 
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <div style={{textAlign: "center", width: "100%"}}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        </TableCell>
                    </TableRow>
                }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <SesiroKycForm
        t={t}
        open={sesiroKycModalOpen}
        handleClose={() => setSesiroKycModalOpen(false)}
        handleOpenSignPanModal={handleOpenSignPanModal}
        handleCreateChangeRequestSubmit={handleCreateChangeRequestSubmit}
        modalState={crModalState}
        // userdata={userdata && userdata.userInfo}
        data={selectedSesiroKycItem}
        signatures={signatureState}
        setSignatureState={setSignatureState}
    />
    <CanvasSignaturePadModal 
            open={signaturePadModalOpen} 
            handleClose={() => setSignaturePadModalOpen(false)} 
            handleSaveSignature={(image) => {
                console.log("image: ", image);
                setSignaturePadModalOpen(false);
            }}
        />
    </div>
)}

export default SesiroKycPanel