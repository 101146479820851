import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import Message from '../../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateMaintenanceModal({
    t,
    open,
    handleCreateMaintenanceSubmit,
    users,
    selectePlaceList,
    handleClose,
    modalState
}) {

    const sensorInitFields = { startDate: null, endDate: null, placeId: "", person: null, comments: "" };

    const [maintenanceFormState, setMaintenainceFormState] = useState([{ ...sensorInitFields }]);
    const classes = useStyles();

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setMaintenainceFormState([{ ...sensorInitFields }]);
            handleClose();
        }
    }, [modalState]);

    const handleMaintenainceChange = (value, name) => {
        setMaintenainceFormState({ ...maintenanceFormState, [name]: value });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="sensor-template-title">
                {t('create.label')} {t('maintenance.label')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: "100%" }}
                            label={"Start Date"}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={maintenanceFormState.startDate ? maintenanceFormState.startDate : null}
                            handleChange={(newValue) => handleMaintenainceChange(newValue, 'startDate')}
                            styleOverrides={{ width: "100%" }}
                            disablePast={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: "100%" }}
                            label={"End Date"}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={maintenanceFormState.endDate ? maintenanceFormState.endDate : null}
                            handleChange={(newValue) => handleMaintenainceChange(newValue, 'endDate')}
                            styleOverrides={{ width: "100%" }}
                            disablePast={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={6}>
                        <Autocomplete
                            id={`place-select`}
                            options={selectePlaceList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                handleMaintenainceChange(newValue ? newValue._id : null, 'placeId')
                            }}
                            style={{ border: '1px solid #ebebeb' }}
                            renderInput={(params) => <TextField {...params} label={t('place.label')} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id={`person-select`}
                            options={users}
                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                            onChange={(event, newValue) => {
                                handleMaintenainceChange(newValue, 'person')
                            }}
                            style={{ border: '1px solid #ebebeb' }}
                            renderInput={(params) => <TextField {...params} label={'Responsible person'} variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Comments"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            multiline={true}
                            rows={3}
                            value={maintenanceFormState.comments}
                            onChange={evt => handleMaintenainceChange(evt.target.value, 'comments')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setMaintenainceFormState({ ...sensorInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!maintenanceFormState.startDate || !maintenanceFormState.endDate || !maintenanceFormState.placeId || !maintenanceFormState.person) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateMaintenanceSubmit(maintenanceFormState);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateMaintenanceModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateMaintenanceSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateMaintenanceModal);