import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR, PROVIDERS, DISKSIZES, RAM, CPUCORE } from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../theme/colors';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const initFormState = {name: '', provider: '', workerDiskSize: '', workerGpuCount: '1', ram: '', cpu: '', workerCount: 1, isProvisioned: false, workerSize: '', instance_ip: ''};

function CreateNodeModal({
    t,
    open,
    handleCreateNodeSubmit,
    handleClose,
    modalState
}) {
    const [nodeFormState, setNodeFormState] = useState({...initFormState});
    const classes = useStyles();

    const handleInputChange = (value, field) => {
        setNodeFormState({...nodeFormState, [field]: value});
    }

    React.useEffect(() => {
        if(modalState.clearData && open){
          setNodeFormState({...initFormState});
          handleClose();
        }
    }, [modalState]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="node-modal"
            aria-describedby="node-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="node-modal-title">{t('createNew.label')} {t('node.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={nodeFormState.name}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('name.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'name')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={nodeFormState.provider}
                                label={`${t('provider.label')}`}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                handleChange={ evt => {
                                    handleInputChange(evt.target.value, 'provider');  
                                }}
                                options={PROVIDERS}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={nodeFormState.ram}
                                label={`${t('ram.label')}`}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                handleChange={ evt => {
                                    handleInputChange(evt.target.value, 'ram');  
                                }}
                                options={[...RAM]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={nodeFormState.cpu}
                                label={`${t('cpu.label')}`}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                handleChange={ evt => {
                                    handleInputChange(evt.target.value, 'cpu');  
                                }}
                                options={[...CPUCORE]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={nodeFormState.workerDiskSize}
                                label={`${t('diskSize.label')}`}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                handleChange={ evt => {
                                    handleInputChange(evt.target.value, 'workerDiskSize');  
                                }}
                                options={[...DISKSIZES]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={nodeFormState.workerGpuCount}
                                label={`${t('gpuCount.label')}`}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                handleChange={ evt => {
                                    handleInputChange(evt.target.value, 'workerGpuCount');  
                                }}
                                options={[1]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>
                            <Typography variant={'subtitle2'}>{`${t('provisioned.label')}*`}</Typography>
                            {[{name: 'Yes', id: true}, {name: 'No', id: false}].map((item, index) => (
                                <Radio
                                    checked={item.id === nodeFormState.isProvisioned}
                                    key={index}
                                    classes={{ root: classes.iconButton }}
                                    style={{
                                    ...marginGenerator('mr-16'),
                                    ...(item.id === nodeFormState.isProvisioned
                                        ? { backgroundColor: colors.secondary.main }
                                        : '')
                                    }}
                                    onChange={() => handleInputChange(item.id, 'isProvisioned')}
                                    value={item.id}
                                    name="type-select"
                                    checkedIcon={
                                        <div>
                                            <Typography
                                            variant={'subtitle2'}
                                            style={{ color: colors.common.white }}>
                                            {item.name}
                                            </Typography>
                                        </div>
                                    }
                                    icon={
                                        <div>
                                            <Typography variant={'subtitle2'}>{item.name}</Typography>
                                        </div>
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {nodeFormState.isProvisioned && <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={nodeFormState.instance_ip}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('instanceIpLbl.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'instance_ip')}
                            />
                        </Grid>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!nodeFormState.name || !nodeFormState.provider || !nodeFormState.ram  || !nodeFormState.cpu || !nodeFormState.workerDiskSize || !nodeFormState.workerGpuCount){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        let formData = nodeFormState;
                        if(!nodeFormState.isProvisioned){
                            formData.instance_ip = '';
                        }
                        formData.workerSize = `${nodeFormState.cpu}-${nodeFormState.ram}`
                        handleCreateNodeSubmit({...formData});
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateNodeModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreatePlaceSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateNodeModal);
