import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment';

const FcrsLogsPopup = ({
  openFcrLogPopup,
  setOpenFcrLogPopup,
  loading,
  classes,
  logs
}) => {
  const [filteredLogs, setFilteredLogs] = useState([]);

  useEffect(() => {
    const filteredLogs = logs.filter(item => item.severity !== 'Provisioning');
    setFilteredLogs(filteredLogs);
  }, [logs]);
  return (
    <>
      <Popup
        title="Logs"
        openPopup={openFcrLogPopup}
        setOpenPopup={setOpenFcrLogPopup}
        width="md">
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item container justify="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ color: 'white' }}>
                      <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                        <TableCell
                          style={{ color: 'white', paddingLeft: '10px' }}>
                          Timestamp
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="left">
                          User
                        </TableCell>
                        <TableCell
                          style={{ color: 'white', width: '150px' }}
                          align="left">
                          Message
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="left">
                          Severity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {filteredLogs?.length === 0 ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No data
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {filteredLogs?.map(item => (
                          <TableRow key={item._id}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ paddingLeft: '10px' }}>
                              {moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss a'
                              )}
                            </TableCell>
                            <TableCell align="left">{item.user.name}</TableCell>
                            <TableCell align="left">{item.message}</TableCell>
                            <TableCell align="left">{item.severity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={() => setOpenFcrLogPopup(false)}>
            Close
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default FcrsLogsPopup;
