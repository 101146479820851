import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import {useLazyQuery} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../utils';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
//import DownloadIcon from '@material-ui/icons/Download';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OcrDocViewer from '../OcrDocViewer';
import {
    GET_OCR_PARSE_IMG,
} from '../../../Ocr/queries';
import Message from '../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
        variant={'outlined'}
        onClick={onClick} 
        disabled={loading}
        >
            {loading && <CircularProgress size={14} />}
             {!loading && <ArrowDownwardIcon />}
        </Button>
    );
  }


function OcrListingItem({
    key,
    t,
    data,
    organizationId
}) {
  const commonClasses = commonStyles();

     const [isParseDocViwerEnable, setIsParseDocViwerEnable] = useState(false);
     const [doctextORImg, setDoctextORImg] = useState("");
     const [ocrParseImgLoading, setOcrParseImgLoading] = useState(false);
     const [ocrParseImgs, setOcrParseImgs] = useState('');
     const [selecteIdForDownload, setSelecteIdForDownload] = useState('');
     const [isDownloadStarted, setIsDownloadStarted] = useState(false);

     const handleCloseOFParseDocViwer = () => setIsParseDocViwerEnable(false);

     const [getOcrParseImg] = useLazyQuery(GET_OCR_PARSE_IMG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            console.log('data', data);
            const alldata = get(data, 'getOcrParseImageData.data', '')
            setOcrParseImgs(alldata);
            if(isDownloadStarted){
             hadnleDowloader(alldata, 'download');
            }
            setOcrParseImgLoading(false);
            setSelecteIdForDownload('');
        },
        onError: error => {
            Message.error(error);
            setOcrParseImgLoading(false);
            setSelecteIdForDownload('');
        }
    });


    const hadnleDowloader = (data, type) => {
        if(type === 'download'){
         setIsDownloadStarted(false);
         FileSaver.saveAs(data.url,data.file_name);
        }
    };

    const {img_url} = data;
    return (
        <>
        <TableRow key={data.id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                    {img_url ? img_url.substring(60) : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.created_by_name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.heading}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            {
                data.status ? (
                    <>
                <Grid className={commonClasses.column} style={{ justifyContent: 'space-evenly' ,border: '1px #5a1f2a solid'}}>
                <Button 
                 variant="outlined" 
                 onClick={() => {
                    setIsParseDocViwerEnable(true);
                    setDoctextORImg(data.ocr_text);
                    setOcrParseImgLoading(true);
                    getOcrParseImg({
                        variables:{
                            organization: organizationId,
                            id:data.id
                        }
                    });
                }}>
                  View
                </Button>
                    <ButtonComponent 
                        t={t}
                        loading={selecteIdForDownload === data.id ? ocrParseImgLoading: false}
                        onClick={() =>{
                            setOcrParseImgLoading(true);
                            setSelecteIdForDownload(data.id);
                            setIsDownloadStarted(true);
                            getOcrParseImg({
                                variables:{
                                    organization: organizationId,
                                    id:data.id
                                }
                            });
                        }}
                    />  
                </Grid>
                </>
                ):
                (
                  <Typography variant={'subtitle1'}>Pending</Typography>  
                )
            }
            
        </TableCell>
    </TableRow>
    <OcrDocViewer
        t={t}
        open={isParseDocViwerEnable} 
        onClose={handleCloseOFParseDocViwer}
        docData={doctextORImg}  
        type="parseDoc"
        loading={ocrParseImgLoading}
        imgUrl={ocrParseImgs}
        imgName={img_url}
    />
    </>
    )
}

export default OcrListingItem
