import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomTabs from '../../../../components/CustomTabs';
import DashboardPanel from './components/DashboardPanel';
import LivenessPanel from './components/LivenessPanel';

const LivenessCheck = ({
    t, 
    data, 
    activeIndex
}) => {

    let tabList = [];
    if(data){
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <>
                        <DashboardPanel
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                        />
                    </>
                )
            },
            {
                label: t('liveness.label'),
                children: (
                    <>
                        <LivenessPanel
                            t={t}
                            allowConfigure={true}
                            data={data.getSubscriptionById}
                        />
                    </>
                )
            }
        ];
    }
    
    return (
        <>
            <CustomTabs 
                activeIndex={activeIndex} 
                tabs={tabList}
            />            
        </>
    );
};
export default withTranslation()(React.memo(LivenessCheck));
