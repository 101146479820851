import React from 'react';
import { marginGenerator } from '../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import ActiveItem from '../components/ActiveItem';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_STATUS } from '../../../utils/constants';
import PendingItem from '../components/PendingItem';

const SubscriptionPanel = ({
  t,
  subscriptionList,
  type,
  handleSubscriptionItemClick,
  handleMenuItemClick,
  label,
  handleSubscriptionActiveClick,
  permisson
}) => {
  const activeMenuItems = [{ label: t('addSites.label') }];

  return (
    <div>
      <Typography
        variant={'subtitle2'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-32')
        }}>
        {t('showingAll.label')} {subscriptionList.length} {label}
      </Typography>
      {subscriptionList.map((item, index) => {
        return type === SUBSCRIPTION_STATUS.ACTIVE ? (
          <ActiveItem
            key={index}
            data={item}
            menuItems={activeMenuItems}
            handleSubscriptionItemClick={handleSubscriptionItemClick}
            handleMenuItemClick={handleMenuItemClick}
          />
        ) : (
          <PendingItem
            key={index}
            data={item}
            handleSubscriptionItemClick={handleSubscriptionItemClick}
            handleSubscriptionActiveClick={handleSubscriptionActiveClick}
            permisson={permisson}
          />
        );
      })}
    </div>
  );
};

SubscriptionPanel.propTypes = {
  subscriptionList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleSubscriptionItemClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default SubscriptionPanel;
