import React, {useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody'; 
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import GlassPanelItem from './GlassPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../../components/CanvasSignaturePadModal';
import GlassPanelForm from './GlassPanelForm';
import Message from '../../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_GLASS_PANEL } from '../../../queries';
import { CREATE_GLASS_PANEL, DELETE_GLASS_PANEL } from '../../../mutation';
import { GET_USER_INFO } from '../../../../../../TicketingSystem/queries';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const GlassPanel = ({
    t,
    data,
    allowConfigure,
    menuItems,
    users,
    }) => {
    const classes = useStyles();
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedGlassPanelItem, setSelectedGlassPanelItem] = useState(null);
    const [glassPanelModalOpen, setGlassPanelModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({InsuredSignature: ""});

    const subscriptionId = data.id;
    const {
        data: userdata,
        loading: userDataLoading,
        error: userDataError
    } = useQuery(GET_USER_INFO);

    const [getAllGlassPanels, {
    loading,
    data: glassPanelList
    }] = useLazyQuery(GET_GLASS_PANEL, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllGlassPanels({variables: {...filterStateData, subscriptionId}});
    }, []);
    
    useEffect(() => {
        getAllGlassPanels({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [createGlassPanel] = useMutation(CREATE_GLASS_PANEL, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Glass Panel created/updated successfully');
            setCRModalState({clearData: true, btnLoading: false});
            return getAllGlassPanels({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteGlassPanel, { loading: deleteGlassPanelLoading }] = useMutation(DELETE_GLASS_PANEL, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Glass Panel deleted successfully');
            setGlassPanelModalOpen(false);
            return getAllGlassPanels({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createGlassPanel({ variables: {
            ...formData
        }});
    }

    const columns = [
        {
            label: 'Policy No',
            key: "policyNo"
        },
        {
            label: 'VAT Registration Number',
            key: "VATRegNo"
        },
        {
            label: 'INSURED Name',
            key: "insured_name"
        },
        {
            label: 'INSURED Occupation',
            key: "insured_occupation"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];


    const handleNewGlassPanel = () => {
        setGlassPanelModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }
    const filterList = [
        {
          label: t('search.label'),
          handleChange: evt => {
            console.log('evt.target.value: ', evt.target.value);
            setFilterStateData({ ...filterStateData, search: evt.target.value });
          },
          val: filterStateData.search,
          type: 'text',
          styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 }
        },
        {
          label: t('startDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, startDate: newDate });
          },
          val: filterStateData.startDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        },
        {
          label: t('endDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, endDate: newDate });
          },
          val: filterStateData.endDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        }
      ];

    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedGlassPanelItem(rowItem);
        if(type === "sign"){
            setSelectedGlassPanelItem(rowItem);
            setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setGlassPanelModalOpen(true)
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this Glass Panel ?') ===
                false
            ) {
                return false;
            }
            return deleteGlassPanel({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    return (
    <div style={{ marginTop: 45 }}>
        <Grid container style={{ ...marginGenerator("mb-20") }}>
            <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChnage={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewGlassPanel}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
        </Grid>
        <Grid container>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { !loading ? glassPanelList && glassPanelList.getAllGlassPanels && glassPanelList.getAllGlassPanels.data.length > 0 ?
                            glassPanelList.getAllGlassPanels.data.map((rowitem, rowIndex) => (
                                <GlassPanelItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    menuItems={menuItems}
                                    handleMenuItemClick={handleMenuItemClick}
                                    allowConfigure={allowConfigure}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow> : null
                        }
                        {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <GlassPanelForm
            t={t}
            open={glassPanelModalOpen}
            handleClose={() => setGlassPanelModalOpen(false)}
            modalState={crModalState}
            userdata={userdata && userdata.userInfo}
            data={selectedGlassPanelItem}
            signatures={signatureState}
            setSignatureState={setSignatureState}
            handleOpenSignPanModal={handleOpenSignPanModal}
            handleCreateRequestSubmit={handleCreateRequestSubmit}
        />
        <CanvasSignaturePadModal 
            open={signaturePadModalOpen} 
            handleClose={() => setSignaturePadModalOpen(false)} 
            handleSaveSignature={(image) => {
                console.log("image: ", image);
                setSignaturePadModalOpen(false);
            }}
            signatureType={signatureType}
        />
    </div>
)}

export default GlassPanel