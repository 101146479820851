import React, { useEffect, useState } from 'react';
import { colors } from '../../../../theme/colors';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchBox from '../../../../components/SearchBox';
import CameraAlt from '@material-ui/icons/CameraAlt';
import { Icon } from '@material-ui/core';
import image_placeholder from '../../../../assets/icons/image_placeholder.png';
import {
  dateFormatterLLL,
  convertUTCDateToLocalDate,
  getEventImageUrl
} from '../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  container: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    position: 'relative'
  },
  camerasContainer: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    position: 'relative',
    overflow: 'hidden'
  },
  headerContainer: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  cameraTile: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    cursor: 'pointer'
  },
  eventsTile: {
    padding: theme.spacing(2, 3, 2, 0)
  },
  listContainer: {
    maxHeight: '300px',
    overflowY: 'scroll',
    marginBottom: 42
  },
  loadingDiv: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    background: '#f9f9f9c9',
    zIndex: 99
  },
  loader: {
    top: '50%',
    left: '50%',
    position: 'absolute'
  },
  eventsContainer: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    position: 'relative'
  },
  paging: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    padding: '5px'
  }
}));

const CameraEvents = ({
  t,
  data,
  handleImageModalClick,
  filterLoader,
  handleListCameraEvents,
  handleEventsPageChange,
  eventsData,
  totalEventCount,
  selectedCamera,
  camEventsLoading,
  eventPage,
  eventsCamPage,
  handleEventsCameraPageChange,
  handleKeyPressSearch
}) => {
  const classes = useStyles();
  // const [selectedCamera, setSelectedCamera] = useState({});
  const [updatedCameraList, setCameraList] = useState([]);
  const [cameraSearched, setCamerSearched] = useState(false);
  // const [updatedEventsList, setEventsList] = useState([]);
  // const [eventsSearched, setEventsSearched] = useState(false);

  const handleCameraSearch = query => {
    // if (query.length) {
    //   const updatedList = data.filter(item =>
    //     item.name.toLowerCase().includes(query)
    //   );
    //   setCameraList(updatedList);
    //   setCamerSearched(true);
    // } else {
    //   setCameraList([]);
    //   setCamerSearched(false);
    // }
  };

  const handleEventsSearch = query => {
    // if (selectedCamera && query.length) {
    //   const updatedList = selectedCamera.events.filter(item =>
    //     item.description.toLowerCase().includes(query)
    //   );
    //   setEventsList(updatedList);
    //   setEventsSearched(true);
    // } else {
    //   setEventsList([]);
    //   setEventsSearched(false);
    // }
  };
  // const cameraData = cameraSearched ? updatedCameraList : data;

  return (
    <Grid container className={classes.container}>
      {filterLoader && (
        <div className={classes.loadingDiv}>
          <CircularProgress className={classes.loader} color="secondary" />
        </div>
      )}
      <Grid item xs={6} className={classes.camerasContainer}>
        <Grid
          className={classes.headerContainer}
          container
          alignItems={'center'}>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mr-32')}>
            {t('cameras.label')}
          </Typography>
          <SearchBox
            onEnterSearch={handleKeyPressSearch}
            handleSearch={handleCameraSearch}
          />
        </Grid>
        <Grid container className={classes.listContainer}>
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <Grid
                  container
                  item
                  key={index}
                  justify={'space-between'}
                  alignItems={'center'}
                  style={
                    selectedCamera && item.camera_id === selectedCamera
                      ? { backgroundColor: colors.grey[200] }
                      : {}
                  }
                  onClick={() => {
                    handleListCameraEvents(item);
                  }}
                  className={classes.cameraTile}>
                  <Grid
                    container
                    item
                    justify={'flex-start'}
                    xs={7}
                    alignItems={'center'}>
                    <Icon
                      component={() => (
                        <CameraAlt
                          color={
                            selectedCamera && item.camera_id === selectedCamera
                              ? 'secondary'
                              : 'inherit'
                          }
                        />
                      )}
                      alt="camera"
                    />
                    <Typography
                      variant={'subtitle2'}
                      color={'textPrimary'}
                      style={marginGenerator('ml-16')}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid container item justify={'flex-end'} xs={4}>
                    <Typography
                      variant={'subtitle2'}
                      color={'textPrimary'}
                      style={marginGenerator('mr-32')}>
                      {item.count ? item.count : 0} {t('events.label')}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid
              container
              alignItems={'center'}
              className={classes.cameraTile}>
              <Grid style={marginGenerator('ml-16')}>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  Camera not found!
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {data && data.length ? (
          <div className={classes.paging}>
            <Pagination
              count={10}
              page={eventsCamPage}
              onChange={handleEventsCameraPageChange}
              color="primary"
            />
          </div>
        ) : null}
      </Grid>
      <Grid item xs={6} className={classes.eventsContainer}>
        <Grid
          className={classes.headerContainer}
          style={paddingGenerator('pl-40')}
          container
          alignItems={'center'}>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mr-32')}>
            {t('events.label')}
          </Typography>
          <SearchBox handleSearch={handleEventsSearch} />
        </Grid>
        <Grid
          container
          className={classes.listContainer}
          style={paddingGenerator('pl-40')}>
          {camEventsLoading ? (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                overflow: 'hidden',
                padding: '10px'
              }}>
              <CircularProgress
                color="secondary"
                style={{ marginTop: '20px' }}
              />
            </div>
          ) : eventsData && eventsData.length > 0 ? (
            <React.Fragment>
              {eventsData.map((item, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    item
                    alignItems={'center'}
                    className={classes.eventsTile}>
                    <Grid item>
                      <img
                        onClick={evt => {
                          handleImageModalClick(evt);
                        }}
                        src={
                          item.image
                            ? getEventImageUrl(item.image)
                            : image_placeholder
                        }
                        alt={'event'}
                        style={{ height: '56px', cursor: 'pointer' }}
                      />
                    </Grid>
                    <Grid item style={marginGenerator('ml-16')}>
                      <Typography variant={'subtitle2'} color={'textPrimary'}>
                        {item.name}
                      </Typography>
                      {item.event_time && item.event_time.slice(-1) !== 'Z' ? (
                        <Typography variant={'subtitle2'}>
                          {dateFormatterLLL(item.event_time + 'Z')}
                        </Typography>
                      ) : (
                        dateFormatterLLL(
                          convertUTCDateToLocalDate(item.event_time)
                        )
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </React.Fragment>
          ) : (
            <Grid
              container
              alignItems={'center'}
              className={classes.eventsTile}>
              <Grid style={marginGenerator('ml-16')}>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  Events not found!
                </Typography>
              </Grid>
            </Grid>
          )}
          {eventsData && eventsData.length ? (
            <div className={classes.paging}>
              <Pagination
                count={totalEventCount ? Math.ceil(totalEventCount / 10) : 0}
                page={eventPage}
                onChange={handleEventsPageChange}
                color="primary"
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CameraEvents;
