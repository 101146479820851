import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL} from '../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ControllerStatusPanel from "../ControllerStatusPanel";

const UcpeItems = ({
                       t,
                       key,
                       ucpe,
                       handleMenuItemClick,
                       menuItems
                   }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={key}>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.controller_ip}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.device_ip}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.device_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.location}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.vcpus}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.memory}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {dateFormatterLLL(ucpe.created_at)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {ucpe.created_by}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <ControllerStatusPanel
                        status={(ucpe.device_status === 'success') ? 'active' : ucpe.device_status}
                        t={t}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}>
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && (
                    <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        // menuItems={
                        //   ucpe.device_status === 'success'
                        //     ? [
                        //         { label: t('configure.label') },
                        //         ...menuItems
                        //       ]
                        //     : [{ label: t('delete.label') }]
                        // }
                        menuItems={[
                            {label: t('configure.label')},
                            {label: t('wap.label')},
                            ...menuItems
                        ]}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, ucpe.device_id, 'ucpe');
                            setAnchorEl(null);
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
UcpeItems.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(UcpeItems);
