import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateQualityAssuranceTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    modalContentLoading,
    selectedProjectTypeAndUid,
    showPreValueOfForm
}) {
    const classes = useStyles();
    const [confirmInstallationStandard, setConfirmInstallationStandard] = useState(false);
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [assignToBillingToBill, setAssignToBillingToBill] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setConfirmInstallationStandard(false);
            setNextTeamMemberEmail('');
            setAssignToBillingToBill('');
            setOnHold('');
            setCategory('');
        }
    }, [modalState, open, modalContentLoading]);
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('qualityAssuarance.label')}</DialogTitle>
            {(showPreValueOfForm === true) ? <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.case_number ? preValuesOfForm.project_info.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle> : <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>}
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                {/* <Typography variant="subtitle2" color="textPrimary">{t('assignToBillingToBill.label')}</Typography> */}
                                <OutlinedSelect
                                    val={assignToBillingToBill}
                                    label={`${t('assignToBillingToBill.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setAssignToBillingToBill(evt.target.value)
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={allUserList && allUserList.length ? allUserList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2">{t('confirmInstallationStandard.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={confirmInstallationStandard}
                                    type={'text'}
                                    placeholder={t('confirmInstallationStandard.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setConfirmInstallationStandard(evt.target.value)}
                                />
                            </Grid> */}
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Typography variant="subtitle2" color="textPrimary" >
                                    <Checkbox
                                        color="secondary"
                                        value={confirmInstallationStandard}
                                        onChange={(event) => {
                                            setConfirmInstallationStandard(event.target.checked)
                                        }}
                                    />{t('confirmInstallationStandard.label')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label') + ' ' + t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label') + ' ' + t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!assignToBillingToBill || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmitClick({
                                project_uid: (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid,
                                job_order_form_url: (showPreValueOfForm === true) ? preValuesOfForm.job_order_form_url : '',
                                feetport_form_url: (showPreValueOfForm === true) ? preValuesOfForm.feetport_form_url : '',
                                confirm_installation_standar: confirmInstallationStandard,
                                assign_to_billing_to_bill: assignToBillingToBill,
                                next_team_member_email: nextTeamMemberEmail,
                                category: category,
                                on_hold: onHold,
                            });

                        
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateQualityAssuranceTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateQualityAssuranceTeamModal);
