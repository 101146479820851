import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { lowerCase } from 'lodash-es';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

  
function ImageModal(
    {
        t,
        open,
        handleClose,
        imageSrc
    }
) {
  
  const classes = useStyles();
  const [isVideo, setIsVideo] = React.useState(false)

  React.useEffect(() => {
    console.log("imageSrc: ", imageSrc);
    if(imageSrc){
      if(imageSrc.includes("mp4")){
        setIsVideo(true);
      }
    }
  }, [imageSrc]);
  
  return (
    <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
             {isVideo ? 
              <iframe frameborder="0"
                height="100%"
                width="100%"
                id="background-video"
                src={imageSrc}
                allow="autoplay; fullscreen">
              </iframe>
            :
              <img src={imageSrc} style={{width:'100%', height:'auto'}} />
            }
        </DialogContent>
      </Dialog>
    </div>
  );
}

ImageModal.propTypes = {
    t: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default withTranslation()(ImageModal);
