import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const getPremiumLinks = async (page = '', limit = '', word = '') => {
  try {
    const response = await axios.get(
      `premiumLink?page=${page}&limit=${limit}&search=${word}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getPremiumLinkById = async id => {
  try {
    const response = await axios.get(`premiumLink/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const addPremiumLink = async payload => {
  try {
    const response = await axios.post(`premiumLink`, payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const updatePremiumLinkById = async (id, payload) => {
  try {
    const response = await axios.put(`premiumLink/${id}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deletePremiumLinkById = async id => {
  try {
    const response = await axios.delete(`premiumLink/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

export {
  getPremiumLinks,
  getPremiumLinkById,
  addPremiumLink,
  updatePremiumLinkById,
  deletePremiumLinkById
};
