import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { renderText } from '../displayComponent';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../components/hook-form';
import { getAllClientData } from '../../../services/clientService';
import IPut from 'iput';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const Step2Fcr = ({ getRouterId, handlePrev, setState, handleNext }) => {
  const [clients, setClients] = useState([]);

  const initialValues = {
    fcrName: '',
    clientName: '',
    ASN: '',
    BGP: '',
    MinimumTerm: ''
  };

  const validationSchema = yup.object().shape({
    fcrName: yup.string().required('This field is required'),
    clientName: yup.string().required('This field is required'),
    ASN: yup
      .number()
      .typeError('Enter a value between 1 & 65535')
      .min(1, 'Enter a value between 1 & 65535')
      .max(65535, 'Enter a value between 1 & 65535')
      .required('This field is required'),

    BGP: yup.string().required('This field is required'),
    MinimumTerm: yup.string().required('This field is required')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const { handleSubmit, watch } = methods;

  const handleChange = val => {
    getRouterId(val);
  };
  const GetClientList = async () => {
    const res = await getAllClientData();
    if (res?.status === 200) setClients(res?.data?.result?.data);
  };

  const onSubmit = async data => {
    setState({ data });
    await handleNext();
  };

  useEffect(() => {
    GetClientList();
  }, []);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Paper style={{ padding: '0 10px' }}>
              <Box>{renderText({ label: 'Configuring Fcr' })}</Box>
              <Grid
                container
                spacing={2}
                style={{ padding: '10px', width: '500px' }}>
                <Grid item container justify="center">
                  <Grid item sm={3}>
                    FCR Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="fcrName"
                      label="FCR Name"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Client Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFSelect
                      name="clientName"
                      variant="outlined"
                      size="small">
                      <option value="">Select</option>
                      {clients &&
                        clients?.map(clientData => (
                          <option
                            key={clientData._id}
                            value={clientData?.client_name}>
                            {clientData?.client_name}
                          </option>
                        ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Minimum Term
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={7}
                    spacing={1}
                    justify="space-between"
                    fullWidth
                    alignItems="center">
                    <Grid item sm={12}>
                      <RHFSelect
                        name="MinimumTerm"
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: '1 month', value: '1' },
                          { key: '12 months', value: '12' },
                          { key: '24 months', value: '24' },
                          { key: '36 months', value: '36' }
                        ]?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item sm={2}>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    ASN
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      size="small"
                      name="ASN"
                      label="ASN"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Router-id
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item container spacing={1} sm={7}>
                    <IPut name="router_id" onChange={handleChange} />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    BGP State
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFSelect name="BGP" size="small" variant="outlined">
                      <option value="">Select</option>
                      {[{ key: 'Enabled', value: 'Enabled' }]?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container spacing={2} justify="space-between">
            <Grid item>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default Step2Fcr;
