import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import {GET_AWS_REGIONS} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
const billingAccounts = [
    {
        id: 'B1000008',
        name: 'B1000008'
    }
];
const billingCycles = [
    {
        id: '1 Month',
        name: '1 Month'
    }, {
        id: '1 Year',
        name: '1 Year'
    }, {
        id: '2 Year',
        name: '2 Year'
    }, {
        id: '3 Year',
        name: '3 Year'
    }, {
        id: '5 Year',
        name: '5 Year'
    }
];
const currencies = [
    {
        id: 'BWP',
        name: 'BWP'
    }
];
    const distances = [
    {
        id: 'less than 250',
        name: 'less than 250'

    }, {
        id: 'greater than 250',
        name: 'greater than 250'
    }
];
const protectionTypes = [
    {
        id: 'Protected',
        name: 'Protected'
    }, {
        id: 'Unprotected',
        name: 'Unprotected'
    }
];
const bandwidths = [{id: '2', name: '2'},
    {id: '3', name: '3'},
    {id: '4', name: '4'},
    {id: '5', name: '5'},
    {id: '6', name: '6'},
    {id: '7', name: '7'},
    {id: '8', name: '8'},
    {id: '9', name: '9'},
    {id: '10', name: '10'},
    {id: '11', name: '11'},
    {id: '12', name: '12'},
    {id: '13', name: '13'},
    {id: '14', name: '14'},
    {id: '15', name: '15'},
    {id: '16', name: '16'},
    {id: '17', name: '17'},
    {id: '18', name: '18'},
    {id: '19', name: '19'},
    {id: '20', name: '20'},
    {id: '21', name: '21'},
    {id: '22', name: '22'},
    {id: '23', name: '23'},
    {id: '24', name: '24'},
    {id: '25', name: '25'},
    {id: '26', name: '26'},
    {id: '27', name: '27'},
    {id: '28', name: '28'},
    {id: '29', name: '29'},
    {
        id: '30',
        name: '30'
    }, {id: '31', name: '31'}, {id: '32', name: '32'}, {id: '33', name: '33'}, {
        id: '34',
        name: '34'
    }, {id: '35', name: '35'}, {id: '36', name: '36'}, {id: '37', name: '37'}, {
        id: '38',
        name: '38'
    }, {id: '39', name: '39'}, {id: '40', name: '40'}, {id: '41', name: '41'}, {
        id: '42',
        name: '42'
    }, {id: '43', name: '43'}, {id: '44', name: '44'}, {id: '45', name: '45'}, {
        id: '46',
        name: '46'
    }, {id: '47', name: '47'}, {id: '48', name: '48'}, {id: '49', name: '49'}, {
        id: '50',
        name: '50'
    }, {id: '51', name: '51'}, {id: '52', name: '52'}, {id: '53', name: '53'}, {
        id: '54',
        name: '54'
    }, {id: '55', name: '55'}, {id: '56', name: '56'}, {id: '57', name: '57'}, {
        id: '58',
        name: '58'
    }, {id: '59', name: '59'}, {id: '60', name: '60'}, {id: '61', name: '61'}, {
        id: '62',
        name: '62'
    }, {id: '63', name: '63'}, {id: '64', name: '64'}, {id: '65', name: '65'}, {
        id: '66',
        name: '66'
    }, {id: '67', name: '67'}, {id: '68', name: '68'}, {id: '69', name: '69'}, {
        id: '70',
        name: '70'
    }, {id: '71', name: '71'}, {id: '72', name: '72'}, {id: '73', name: '73'}, {
        id: '74',
        name: '74'
    }, {id: '75', name: '75'}, {id: '76', name: '76'}, {id: '77', name: '77'}, {
        id: '78',
        name: '78'
    }, {id: '79', name: '79'}, {id: '80', name: '80'}, {id: '81', name: '81'}, {
        id: '82',
        name: '82'
    }, {id: '83', name: '83'}, {id: '84', name: '84'}, {id: '85', name: '85'}, {
        id: '86',
        name: '86'
    }, {id: '87', name: '87'}, {id: '88', name: '88'}, {id: '89', name: '89'}, {
        id: '90',
        name: '90'
    }, {id: '91', name: '91'}, {id: '92', name: '92'}, {id: '93', name: '93'}, {
        id: '94',
        name: '94'
    }, {id: '95', name: '95'}, {id: '96', name: '96'}, {id: '97', name: '97'}, {
        id: '98',
        name: '98'
    }, {id: '99', name: '99'}, {id: '100', name: '100'}, {id: '108', name: '108'}, {
        id: '110',
        name: '110'
    }, {id: '115', name: '115'}, {id: '120', name: '120'}, {id: '126', name: '126'}, {
        id: '135',
        name: '135'
    }, {id: '144', name: '144'}, {id: '150', name: '150'}, {id: '155', name: '155'}, {
        id: '165',
        name: '165'
    }, {id: '175', name: '175'}, {id: '200', name: '200'}, {id: '210', name: '210'}, {
        id: '220',
        name: '220'
    }, {id: '250', name: '250'}, {id: '252', name: '252'}, {id: '260', name: '260'}, {
        id: '300',
        name: '300'
    }, {id: '310', name: '310'}, {id: '350', name: '350'}, {id: '400', name: '400'}, {
        id: '410',
        name: '410'
    }, {id: '465', name: '465'}, {id: '500', name: '500'}, {id: '540', name: '540'}, {
        id: '600',
        name: '600'
    }, {id: '622', name: '622'}, {id: '700', name: '700'}, {id: '775', name: '775'}, {
        id: '800',
        name: '800'
    }, {id: '900', name: '900'}, {id: '930', name: '930'}, {id: '1000', name: '1000'}, {
        id: '1085',
        name: '1085'
    }, {id: '1240', name: '1240'}, {id: '1395', name: '1395'}, {id: '1550', name: '1550'}, {
        id: '1705',
        name: '1705'
    }, {id: '1860', name: '1860'}, {id: '2015', name: '2015'}, {id: '2325', name: '2325'}, {
        id: '2480',
        name: '2480'
    }, {id: '2488', name: '2488'}, {id: '2500', name: '2500'}, {id: '3000', name: '3000'}, {
        id: '3072',
        name: '3072'
    }, {id: '4000', name: '4000'}, {id: '4650', name: '4650'}, {id: '9300', name: '9300'}, {
        id: '9952',
        name: '9952'
    }, {id: '10240', name: '10240'}];

function CreateOpportunityModal({
                                    t,
                                    open,
                                    handleCreateOpportunity,
                                    handleClose,
                                    data,
                                    modalState
                                }) {
    const countries = ['Botswana'];
    const districts = [
        'Kweneng', 'Kgatleng', 'Chobe', 'Boteti', 'Ngwaketse',
        'North-West District', 'North West', 'Borolong', 'North-East District',
        'South East', 'Central Tutume', 'Southern District', 'Central District',
        'South-East District', 'Ghanzi', 'Kgalagadi South', 'Tswapong',
        'Kgalagadi', 'Okavango District', 'Kweneng East', 'Ngamiland', 'Gantsi'
    ];
    const cities = {
        'Kweneng': [
            'Lentsweletau', 'Mogoditshane', 'Khudumelapye', 'Molepolole',
            'Metsimotlhaba', 'Kumakwane', 'Kopong', 'Mmopane', 'Thamaga',
            'Letlhakeng', 'Gabane', 'Mmankgodi', 'Sojwe',
            'Gaborone - RLU6 - Mogoditshane', 'Motokwe', 'Takatokwane',
            'Thebephatshwa', 'Mapharangwane', 'Lephephe', 'Moshaweng', 'Kubung',
            'Kaudwane'
        ],
        'Kgatleng': [
            'Mochudi', 'Malolwane', 'Bokaa', 'Oodi', 'Mochudi Main', 'Pilane',
            'Artesia', 'Mmamashia', 'Mabalane', 'Rasesa', 'Dikgonnye'
        ],
        'Chobe': ['Site 127', 'Lesoma', 'Mabele', 'Kavimba'],
        'Boteti': ['Toromoja', 'Malatswai', 'Kedia'],
        'Ngwaketse': ['Sesung'],
        'North-West District': [
            'Shorobe', 'Gumare', 'Seronga', 'Shakawe', 'Toteng', 'Etsha',
            'Sehithwa', 'Nokaneng', 'Maun', 'Kasane', 'Kazungula', 'Makalamabedi',
            'Motopi', 'Ngoma', 'Pandamatenga', 'Etsha 6', 'Mohembo', 'Sepopa',
            'Tsau', 'Beetsha', 'Chanoga', 'Hatsalatladi', 'Ngarange', 'Samuchima',
            'Shakwe', 'Etsha 13', 'Ikoga', 'Nxamasere', 'Samochima', 'Kauzwho',
            'Xakau', 'Sekondomboro', 'Mogotlho', 'Gunotsoga', 'Gudigwa', 'Sankoyo',
            'Mababe', 'Savuti', 'Kachikau', 'Kwende', 'Etsha 6'
        ],
        'North West': ['Rustenburg', 'Shakwe', 'Komana', 'Lesoma', 'Chobe'],
        'Borolong': ['Gathwane', 'Magotlhwane', 'Mabule'],
        'North-East District': [
            'Tati Siding', 'Francistown', 'Masunga', 'Dibete',
            'Francistown - Nyangagwe Hill', 'Tshesebe', 'Zwenshambe', 'Tsamaya',
            'Zwenzhambe', 'Ramokgwebana', 'Gambule', 'Mulambakwena',
            'Letsholathebe', 'Mosojane', 'Siviya', 'Nlapkhane', 'Salajwe',
            'Sekakangwe', 'Vukwi'
        ],
        'South East': ['Modipane'],
        'Central Tutume': ['Matobo'],
        'Southern District': [
            'Mmathethe', 'Ranaka', 'Jwaneng', 'Manyana', 'Lotlhakane',
            'Molapowabojang', 'Kanye', 'Moshupa', 'Morwamosu', 'Pitsane ',
            'Sekoma', 'Digawana', 'Goodhope', 'Khakhea', 'Mabutsane', 'Keng',
            'Moshaneng', 'Ramatlabama', 'Ntlhantlhe', 'Gasita', 'Pitseng',
            'Selokolela', 'Lorolwane', 'Kanye'
        ],
        'Central District': [
            'Rakops', 'Serowe', 'Lerala', 'Mmadinare', 'Tobane', 'Mahalapye',
            'Bobonong', 'Tonota', 'Shashe-Mooke', 'Sebina', 'Mookane', 'Orapa',
            'Tumasera/Seleka', 'Lecheng', 'Shoshong', 'Tsetsebjwe', 'Mathangwane',
            'Chadibe', 'Thabala', 'Palapye', 'Nata', 'Mopipi', 'Dukwi', 'Borolong',
            'Maitengwe', 'Mandunyane', 'Tutume', 'Gweta', 'Selibe Phikwe',
            'Letlhakane', 'Maunatlala', 'Sefophe', 'Moiyabana', 'Marobela',
            'Nkange', 'Ramokgonami', 'Letlhakane', 'Khumaga', 'Sebina ',
            'Serowe High', 'Machaneng', 'Maokatumo', 'Mokobeng', 'Molalatau',
            'Mopipi', 'Moreomaoto', 'Palapye RLU 1', 'Rep 2', 'Tsetsebjwe Hill',
            'Xhumo', 'GooTau', 'Kachikau', 'Mathambgwane', 'Mogapi', 'Ncamasera',
            'Seleka', 'Taupye', 'Mmashoro', 'Serule', 'Cordon Fence', 'Dikalate',
            'Majwaneng', 'Malaka', 'Martins Drift', 'Matlhakola', 'Mhalapitsa',
            'Mogorosi', 'Moremi', 'Mosolotshane', 'Moeng', 'Motshegaletau',
            'Ngwapa', 'Paje', 'Radisele', 'Ratholo', 'Sefhare', 'Seolwane',
            'Sherwood', 'Ngwasha Cordeone Fence', 'Tamasane', 'Sowa', 'Goshwe',
            'Gobojango', 'Kudumatse', 'Mathathane', 'Matsiloje', 'Marapong',
            'Mosu', 'Nswazi', 'Shashe', 'Tuli Block', 'Makaleng', 'Xhumaga',
            'Kedia', 'Maape', 'Malatswai', 'Matsitama', 'Moeng', 'Toromoja',
            'Sepako', 'Borolong', 'Ramokgoname', 'Mmashoro', 'Ratholo', 'Rakops',
            'Nata'
        ],
        'South-East District': [
            'Otse', 'Lobatse', 'Ramotswa', 'Gaborone', 'Tlokweng', 'RLU10 Airport',
            'Lobatse RLU 2', 'Taung', 'Mogonye', 'Mokolodi'
        ],
        'Ghanzi': [
            'Ghanzi', 'Charleshill', 'Dkar', 'Kuke', 'Tsootsha', 'Xhanagas',
            'Lone Tree', 'Site FS', 'Mamuno', 'Xanagas'
        ],
        'Kgalagadi South': ['Maleshe'],
        'Tswapong': ['Makwate'],
        'Kgalagadi': [
            'Hukuntsi', 'Tshabong', 'Kang', 'Khisa', 'Kokotsha', 'Makopong',
            'Omaweneno', 'Werda', 'Bokspits', 'Draaihoek', 'Gachibana',
            'Kolonkwane', 'Middlepits', 'Kokong', 'Phuduhudu', 'Lone Tree',
            'Khonkhwa', 'Phepheng', 'Khakhea', 'Maralaleng', 'McCarthys Trust',
            'Deekbos', 'Bogogobo', 'Khuis', 'Gakhibana', 'Rapplespan', 'Vaalhoek',
            'Struizendam', 'Hereford/Bray', 'Lehututu', 'Two Rivers', 'Draihoek',
            'Lokgwabe', 'Mokhomba', 'Pitseng', 'Hunhukwe', 'Khawa', 'Inalegolo',
            'Tsabong'
        ],
        'Okavango District': ['Kauxwi'],
        'Kweneng East': ['Kubung', 'Hatsalatladi'],
        'Ngamiland': ['Xakao', 'Eretsha', 'Komana', 'Phuduhudu'],
        'Gantsi': [
            'Karakubis', 'Chobokwane', 'East Hanahai', 'Groote Laagte',
            'Moreomaoto', 'New Xade', 'West Hanahai', 'Kole', 'New Xanagas',
            'D`Kar', 'Ncojane', 'Gantsi BTC'
        ]
    }
    const [sitesACityData, setSitesACities] = useState([]);
    const [sitesBCityData, setSitesBCities] = useState([]);
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    const {
        loading: loadingRegions,
        data: regionsList,
        error: regionsError
    } = useQuery(GET_AWS_REGIONS);
    const handleSitesBCityData = (id) => {
        setSitesBCities(cities[id]);
    }
    const handleSitesACityData = (id) => {
        setSitesACities(cities[id]);
    }
    /*    const opportunityTypes =
            !loadingRegions && !regionsError
                ? regionsList.getAwsRegions.map(region => {
                    return {id: region, name: region};
                })
                : [];*/
    const opportunityTypes = [{id: 'Provider', name: 'Provider'}];
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        subject: '',
                        opportunityType: '',
                        billingAccount: '',
                        bandwidth: '',
                        currency: '',
                        review: '',
                        productName: '',
                        billingCycle: '',
                        distance: '',
                        protectionType: '',
                        SiteAAddress: '',
                        SiteACompanyName: '',
                        SiteACountry: '',
                        SiteADistrict: '',
                        SiteACity: '',
                        SiteAContactName: '',
                        SiteAPlot: '',
                        SiteALocation: '',
                        SiteACordinates: '',
                        SiteBAddress: '',
                        SiteBCompanyName: '',
                        SiteBCountry: '',
                        SiteBDistrict: '',
                        SiteBCity: '',
                        SiteBContactName: '',
                        SiteBPlot: '',
                        SiteBLocation: '',
                        SiteBCordinates: ''
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        handleCreateOpportunity(values)
                        resetForm()
                        //alert(JSON.stringify(values));
                    }}
                    validationSchema={Yup.object().shape({
                        subject: Yup.string()
                            .min(2, 'Subject must be 2 character!')
                            .max(255, 'Subject must be 255 character!')
                            .required('Please Enter Subject'),
                        opportunityType: Yup.string()
                            .required('Please Select Opportunity Type'),
                        billingAccount: Yup.string()
                            .required('Please Select Billing Account'),
                        bandwidth: Yup.string()
                            .required('Please Select bandwidth'),
                        currency: Yup.string()
                            .required('Please Select currency'),
                        productName: Yup.string()
                            .min(2, 'Product Name must be 2 character!')
                            .max(20, 'Product Name must be 20 character!')
                            .required('Please Enter Product Name'),
                        billingCycle: Yup.string()
                            .required('Please Select Billing Cycle'),
                        distance: Yup.string()
                            .required('Please Select Distance'),
                        protectionType: Yup.string()
                            .required('Please Select Protection Type'),
                        SiteAAddress: Yup.string()
                            .min(2, 'SiteA Address must be 2 character!')
                            .max(50, 'SiteA Address must be 50 character!')
                            .required('Please Enter SiteA Address'),
                        SiteACompanyName: Yup.string()
                            .min(2, 'SiteA Company Name must be 2 character!')
                            .max(50, 'SiteA Company Name must be 50 character!')
                            .required('Please Enter SiteA Company Name'),
                        SiteACountry: Yup.string()
                            .required('Please Select SiteA Country'),
                        SiteADistrict: Yup.string()
                            .required('Please Select SiteA District'),
                        SiteACity: Yup.string()
                            .required('Please Select SiteA City'),
                        SiteAContactName: Yup.string()
                            .min(2, 'SiteA Contact Name must be 2 character!')
                            .max(50, 'SiteA Contact Name must be 50 character!')
                            .required('Please Enter SiteA Contact Name'),
                        SiteAPlot: Yup.string()
                            .min(2, 'SiteA Plot must be 2 character!')
                            .max(20, 'SiteA Plot must be 20 character!')
                            .required('Please Enter SiteA Plot'),
                        SiteALocation: Yup.string()
                            .min(2, 'SiteA Location must be 2 character!')
                            .max(100, 'SiteA Location must be 100 character!')
                            .required('Please Enter SiteA Location'),
                        SiteBAddress: Yup.string()
                            .min(2, 'SiteB Address must be 2 character!')
                            .max(50, 'SiteB Address must be 50 character!')
                            .required('Please Enter SiteB Address'),
                        SiteBCompanyName: Yup.string()
                            .min(2, 'SiteB Company Name must be 2 character!')
                            .max(50, 'SiteB Company Name must be 50 character!')
                            .required('Please Enter SiteB Company Name'),
                        SiteBCountry: Yup.string()
                            .required('Please Select SiteB Country'),
                        SiteBDistrict: Yup.string()
                            .required('Please Select SiteB District'),
                        SiteBCity: Yup.string()
                            .required('Please Select SiteB City'),
                        SiteBContactName: Yup.string()
                            .min(2, 'SiteB Contact Name must be 2 character!')
                            .max(50, 'SiteB Contact Name must be 50 character!')
                            .required('Please Select SiteB Contact Name'),
                        SiteBPlot: Yup.string()
                            .min(2, 'SiteB Plot must be 2 character!')
                            .max(20, 'SiteB Plot must be 20 character!')
                            .required('Please Select SiteB Plot'),
                        SiteBLocation: Yup.string()
                            .min(2, 'SiteB Location must be 2 character!')
                            .max(100, 'SiteB Location must be 100 character!')
                            .required('Please Select SiteB Location'),
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.subject}
                                                    name={'subject'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.subject && touched.subject) && errors.subject}
                                                    label={`${t('subject.label')}*`}
                                                    placeholder={`${t('subjectPlaceHolder.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.opportunityType}
                                                    label={`${t('opportunityType.label')} *`}
                                                    name={'opportunityType'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={opportunityTypes}
                                                />
                                                {(errors.opportunityType && touched.opportunityType) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.opportunityType}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.billingAccount}
                                                    label={`${t('billingAccount.label')} *`}
                                                    name={'billingAccount'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={billingAccounts ? billingAccounts : []}
                                                />
                                                {(errors.billingAccount && touched.billingAccount) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.billingAccount}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.currency}
                                                    label={`${t('currency.label')} *`}
                                                    name={'currency'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={currencies ? currencies : []}
                                                />
                                                {(errors.currency && touched.currency) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.currency}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label={`${t('remark.label')}`}
                                                        style={{width: '100%'}}
                                                        color={'secondary'}
                                                        placeholder={`${t('reviewPlaceholder.label')}`}
                                                        value={values.review}
                                                        name={'review'}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={(errors.review && touched.review) && errors.review}
                                                        multiline
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    label={'Product Name'}
                                                    placeholder={`${t('productName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    value={values.productName}
                                                    name={'productName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.productName && touched.productName) && errors.productName}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.billingCycle}
                                                    label={`${t('billingCycle.label')} *`}
                                                    name={'billingCycle'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={billingCycles ? billingCycles : []}
                                                />
                                                {(errors.billingCycle && touched.billingCycle) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.billingCycle}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12}
                                                    style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('productAttributes.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <OutlinedSelect
                                                    val={values.distance}
                                                    label={`${t('distance.label')} *`}
                                                    name={'distance'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={distances ? distances : []}
                                                />
                                                {(errors.distance && touched.distance) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.distance}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <OutlinedSelect
                                                    val={values.protectionType}
                                                    label={`${t('protectionType.label')} *`}
                                                    name={'protectionType'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={protectionTypes ? protectionTypes : []}
                                                />
                                                {(errors.protectionType && touched.protectionType) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.protectionType}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <OutlinedSelect
                                                    val={values.bandwidth}
                                                    label={`${t('bandwidth.label')} *`}
                                                    name={'bandwidth'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={bandwidths ? bandwidths : []}
                                                />
                                            {(errors.bandwidth && touched.bandwidth) &&
                                            <FormHelperText style={{marginTop: '20px'}}>{errors.bandwidth}</FormHelperText>
                                            }
                                            </Grid>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12}
                                                    style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('SiteA.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SiteAAddress}
                                                    label={`${t('SiteAAddress.label')}*`}
                                                    placeholder={`${t('SiteAAddress.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteAAddress'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteAAddress && touched.SiteAAddress) && errors.SiteAAddress}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.SiteACountry}
                                                    label={`${t('SiteACountry.label')} *`}
                                                    name={'SiteACountry'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={countries.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteACountry && touched.SiteACountry) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteACountry}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.SiteADistrict}
                                                    label={`${t('SiteADistrict.label')} *`}
                                                    name={'SiteADistrict'}
                                                    handleChange={evt => {
                                                        handleSitesACityData(evt.target.value)
                                                        handleChange(evt)
                                                    }}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={districts.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteADistrict && touched.SiteADistrict) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteADistrict}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.SiteACity}
                                                    label={`${t('SiteACity.label')} *`}
                                                    name={'SiteACity'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={sitesACityData.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteACity && touched.SiteACity) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteACity}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteACompanyName}
                                                    label={`${t('SiteACompanyName.label')}*`}
                                                    placeholder={`${t('SiteACompanyName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteACompanyName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteACompanyName && touched.SiteACompanyName) && errors.SiteACompanyName}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteAContactName}
                                                    label={`${t('SiteAContactName.label')}*`}
                                                    placeholder={`${t('SiteAContactName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteAContactName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteAContactName && touched.SiteAContactName) && errors.SiteAContactName}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteAPlot}
                                                    label={`${t('SiteAPlot.label')}*`}
                                                    placeholder={`${t('SiteAPlot.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteAPlot'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteAPlot && touched.SiteAPlot) && errors.SiteAPlot}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteALocation}
                                                    label={`${t('SiteALocation.label')}*`}
                                                    placeholder={`${t('SiteALocation.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteALocation'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteALocation && touched.SiteALocation) && errors.SiteALocation}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteACordinates}
                                                    label={`${t('SiteACordinates.label')}`}
                                                    placeholder={`${t('SiteACordinates.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteACordinates'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteACordinates && touched.SiteACordinates) && errors.SiteACordinates}
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12}
                                                    style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('SiteB.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SiteBAddress}
                                                    label={`${t('SiteBAddress.label')}*`}
                                                    placeholder={`${t('SiteBAddress.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBAddress'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBAddress && touched.SiteBAddress) && errors.SiteBAddress}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SiteBCompanyName}
                                                    label={`${t('SiteBCompanyName.label')}*`}
                                                    placeholder={`${t('SiteBCompanyName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBCompanyName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBCompanyName && touched.SiteBCompanyName) && errors.SiteBCompanyName}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.SiteBCountry}
                                                    label={`${t('SiteBCountry.label')} *`}
                                                    name={'SiteBCountry'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={countries.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteBCountry && touched.SiteBCountry) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteBCountry}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.SiteBDistrict}
                                                    label={`${t('SiteBDistrict.label')} *`}
                                                    handleChange={evt => {
                                                        handleSitesBCityData(evt.target.value)
                                                        handleChange(evt);
                                                    }}
                                                    name={'SiteBDistrict'}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={districts.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteBDistrict && touched.SiteBDistrict) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteBDistrict}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.SiteBCity}
                                                    label={`${t('SiteBCity.label')} *`}
                                                    name={'SiteBCity'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={sitesBCityData.map(item => {
                                                        return {
                                                            id: item,
                                                            name: item
                                                        };
                                                    })}
                                                />
                                                {(errors.SiteBCity && touched.SiteBCity) &&
                                                <FormHelperText style={{marginTop: '20px'}}>{errors.SiteBCity}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteBContactName}
                                                    label={`${t('SiteBContactName.label')}*`}
                                                    placeholder={`${t('SiteBContactName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBContactName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBContactName && touched.SiteBContactName) && errors.SiteBContactName}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteBPlot}
                                                    label={`${t('SiteBPlot.label')}*`}
                                                    placeholder={`${t('SiteBPlot.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBPlot'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBPlot && touched.SiteBPlot) && errors.SiteBPlot}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteBLocation}
                                                    label={`${t('SiteBLocation.label')}*`}
                                                    placeholder={`${t('SiteBLocation.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBLocation'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBLocation && touched.SiteBLocation) && errors.SiteBLocation}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <TextField
                                                    value={values.SiteBCordinates}
                                                    label={`${t('SiteBCordinates.label')}`}
                                                    placeholder={`${t('SiteBCordinates.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'SiteBCordinates'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteBCordinates && touched.SiteBCordinates) && errors.SiteBCordinates}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'space-between'}>
                                        <Button
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

CreateOpportunityModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateOpportunityModal);
