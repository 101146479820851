import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
//import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
  }));
  
  function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

const urInitFields = {
    id: "",
    date: null,
    systemFacility: "",
    dateAndTimeReported: null,
    reportedBy:"",
    serverty: "",
    sectionTwo: "",
    sectionThree: "",
    sectionFour: "",
    sectionFive: "",
    nameOne: "",
    designationOne: "",
    dateOne:null,
    nameTwo:"",
    designationTwo: "",
    dateTwo:null,
    nameThree: "",
    designationThree: "",
    dateThree:null,
    infromantionMangementName: "",
    infromantionMangementDate: null,
    performedAuthSign:"",
    performedDate: null,
};

const IncidentIsuueForm = ({
    t,
    open,
    handleClose,
    handleOpenSignPanModal,
    data,
    handleCreateRequestSubmit,
    users
}) => {
  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
    if (!open) {
        setURFormState({ ...urInitFields });
        handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        setURFormState({...data,id});
    }else{
        // setShowUserSelect(true);
        // setShowLearnDevelopment(true);
        // setShowHOD(true);
        // setShowHRManager(true);
        // setShowGeneralManager(true);
    }
}, [data]);

const handleChange = (value, name, key) => {
        setURFormState({ ...urFormState, [name]: value });
}

  return (
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose(); 
    }}>
    <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
        <HrHeader />
    </DialogTitle>
    <DialogContent>
    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Incident Report</Typography>
                    </Grid>
        </Grid>
    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >SECTION 1 – General Details</Typography>
                    </Grid>
        </Grid>
         <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.date}
                            handleChange={newDate => handleChange(newDate, "date")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"System/Facility"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.systemFacility}
                            onChange={(evt) => handleChange(evt.target.value, "systemFacility")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date & Time Reported'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateAndTimeReported}
                            handleChange={newDate => handleChange(newDate, "dateAndTimeReported")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Reported By"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.reportedBy}
                            onChange={(evt) => handleChange(evt.target.value, "reportedBy")}
                        />
                    </Grid>
                    <Grid item xs={7}>
                    <Typography variant="subtitle2" style={{ paddingTop: 10, display: "inline-block" }} color="textPrimary">Severity</Typography>
                        <RadioGroup
                            row
                            aria-label="change_classification"
                            name="serverty"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `serverty`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={urFormState.serverty === 'Low' ? true : false}
                                value="Low"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                label="Low"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={urFormState.serverty === 'Medium' ? true : false}
                                value="Medium"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                label="Medium"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={urFormState.serverty === 'High' ? true : false}
                                value="High"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                label="High"
                            />
                        </RadioGroup>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >SECTION 2 – Incident Description</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Incident Description"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            value={urFormState.sectionTwo}
                            onChange={(evt) => handleChange(evt.target.value, "sectionTwo")}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >SECTION 3 – Initial Actions</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Initial Actions"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            value={urFormState.sectionThree}
                            onChange={(evt) => handleChange(evt.target.value, "sectionThree")}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >SECTION 4 – Resolution</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Resolution"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            value={urFormState.sectionFour}
                            onChange={(evt) => handleChange(evt.target.value, "sectionFour")}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >SECTION 5 – Recommendations</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Recommendations"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            value={urFormState.sectionFive}
                            onChange={(evt) => handleChange(evt.target.value, "sectionFive")}
                        />
                    </Grid> 
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }} >
                    <Grid item xs={3}>
                        {/* <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.nameOne}
                            onChange={(evt) => handleChange(evt.target.value, "nameOne")}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.nameOne}
                            handleChange={(evt) => handleChange(evt.target.value, "nameOne")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Designation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.designationOne}
                            onChange={(evt) => handleChange(evt.target.value, "designationOne")}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("signOne")}
                        >
                          Sign <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>  
                    <DateTimeSelector
                            inputStyle={{ minWidth: 180 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateOne}
                            handleChange={newDate => handleChange(newDate, "dateOne")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }} >
                    <Grid item xs={3}>
                        {/* <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.nameTwo}
                            onChange={(evt) => handleChange(evt.target.value, "nameTwo")}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.nameTwo}
                            handleChange={(evt) => handleChange(evt.target.value, "nameTwo")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Designation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.designationTwo}
                            onChange={(evt) => handleChange(evt.target.value, "designationTwo")}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("signTwo")}
                        >
                          Sign <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>  
                    <DateTimeSelector
                            inputStyle={{ minWidth: 180 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateTwo}
                            handleChange={newDate => handleChange(newDate, "dateTwo")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }} >
                    <Grid item xs={3}>
                        {/* <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.nameThree}
                            onChange={(evt) => handleChange(evt.target.value, "nameThree")}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.nameThree}
                            handleChange={(evt) => handleChange(evt.target.value, "nameThree")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Designation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.designationThree}
                            onChange={(evt) => handleChange(evt.target.value, "designationThree")}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("signThree")}
                        >
                          Sign <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>  
                    <DateTimeSelector
                            inputStyle={{ minWidth: 180 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateThree}
                            handleChange={newDate => handleChange(newDate, "dateThree")}
                        />
                    </Grid>
          </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >INFORMATION MANAGEMENT OFFICIAL</Typography>
                    </Grid>
                    <Grid item xs={4}>
                    {/* <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.infromantionMangementName}
                            onChange={(evt) => handleChange(evt.target.value, "infromantionMangementName")}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.infromantionMangementName}
                            handleChange={(evt) => handleChange(evt.target.value, "infromantionMangementName")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("infromantionMangementSign")}
                        >
                          Signature <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>  
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.infromantionMangementDate}
                            handleChange={newDate => handleChange(newDate, "infromantionMangementDate")}
                        />
                    </Grid>
                </Grid>
    </DialogContent>
    <DialogActions>
        <Grid container justify={'space-between'}>
            <Button
                variant={'outlined'}
                onClick={() => {
                    handleClose();
                }}
            >
                {t('cancel.label')}
            </Button>
            <ButtonComponent
                t={t}
                onClick={() =>handleCreateRequestSubmit(urFormState)}
                loading={false}
            />
        </Grid>
    </DialogActions>
</Dialog>
  )
}

export default IncidentIsuueForm