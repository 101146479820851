import React, { useEffect, useState } from 'react';
import {marginGenerator} from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import ActiveItem from '../ActiveItem';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_STATUS, USER_ROLES } from '../../../../utils/constants';
import PendingItem from '../PendingItem';

const SubscriptionPanel = ({
    t,
    subscriptionList,
    type,
    handleSubscriptionItemClick,
    handleMenuItemClick,
    label,
    handleSubscriptionActiveClick,
    handleOpenDiaConfigurationForm,
    permisson,
    deleteSubscription
}) => {
    const activeMenuItems = [
        {label: t('viewDetails.label')},
        {label: t('viewConfiguration.label')},
        {label: t('openServiceTickets.label')},
        {label: t('raiseNewTicket.label')},
        {label: t('edit.label')}
        // ,{
        //   label: t('terminateSubscription.label'),
        //   style: { color: colors.error.main }
        // }
    ];
    const role = localStorage.getItem('role');
    if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.EP_ADMIN)) {
       activeMenuItems.push({label: t('assignToUser.label')});
    }
    const [count, setCount] = useState(0);
    
    React.useEffect(() => {
        if(subscriptionList && subscriptionList.length){
            let filterDataCount = subscriptionList.filter(item => {
                if(item.status === type && item.isDelete === false){
                    return item;
                }
            });
            setCount(filterDataCount.length);
        }
    }, [subscriptionList]);


    return (
        <div>
          
            <Typography
                variant={'subtitle2'}
                style={{
                    ...marginGenerator('mt-32'),
                    ...marginGenerator('mb-32')
                }}>
                {t('showingAll.label')} {count} {label}
            </Typography>
            {subscriptionList.map((item, index) => {
                    return (
                        type === SUBSCRIPTION_STATUS.ACTIVE ? (
                            <ActiveItem
                                key={index}
                                data={item}
                                menuItems={activeMenuItems}
                                handleSubscriptionItemClick={handleSubscriptionItemClick}
                                handleMenuItemClick={handleMenuItemClick}
                            />
                        ) : (
                            <PendingItem
                                key={index}
                                data={item}
                                handleSubscriptionItemClick={handleSubscriptionItemClick}
                                handleSubscriptionActiveClick={handleSubscriptionActiveClick}
                                handleOpenDiaConfigurationForm={handleOpenDiaConfigurationForm}
                                permisson={permisson}
                                deleteSubscription={deleteSubscription}
                                handleMenuItemClick={handleMenuItemClick}
                            />
                        ));
                }
            )}
        </div>
    );
};
SubscriptionPanel.propTypes = {
    subscriptionList: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    handleSubscriptionItemClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};
export default SubscriptionPanel;
