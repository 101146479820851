import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import CheckSuccess from "../../../../assets/icons/check_success.png";
import PendingItem from "../../../../assets/icons/pending_actions.png";
import {colors} from "../../../../theme/colors";
import {dateFormatterLLL} from "../../../../utils";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function ProjectManagementTrackStatusDianModal({
                                                   t,
                                                   open,
                                                   handleClose,
                                                   loading,
                                                   list
                                               }) {
    const classes = useStyles();
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    /*React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);*/
    const columns = [
        {
            label: t('updatedBy.label'),
            key: 'updatedBy'
        }, {
            label: t('createdAt.label'),
            key: 'createdAt'
        }, {
            label: t('createdAt.label'),
            key: 'createdAt'
        }
    ];
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                Project Management Status Tracking
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will project management status tracking information it to Nigeria.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {list && list.length && (
                        <Grid
                            item
                            xs={5}
                            className={classes.orderTrackingContianer}
                            style={{
                                ...paddingGenerator('pt-40'),
                                ...paddingGenerator('pl-56'),
                                ...paddingGenerator('pb-24')
                            }}>
                            <VerticalTimeline animate={false} layout={'1-column'}>
                                {list.map((item, index) => (
                                    <VerticalTimelineElement
                                        key={index}
                                        icon={
                                            <img
                                                src={item.created_at ? CheckSuccess : PendingItem}
                                                alt={'success'}
                                                style={{width: 20, height: 20}}
                                            />
                                        }>
                                        <Grid
                                            container
                                            item
                                            direction={'column'}
                                            style={marginGenerator('ml-16')}>
                                            <Typography
                                                variant={'subtitle1'}
                                                style={{
                                                    color: item.created_at
                                                        ? colors.success.main
                                                        : colors.grey[400]
                                                }}>
                                                {item.status_changed_to}
                                            </Typography>
                                            {item.created_at ? (
                                                <Typography
                                                    variant={'subtitle2'}
                                                    style={{fontWeight: 'normal'}}>
                                                    {t('updatedOn.label')}{' '}
                                                    {dateFormatterLLL(item.created_at)}
                                                </Typography>
                                            ) : null}
                                            {item.updated_by ? (
                                                <Typography
                                                    variant={'subtitle2'}
                                                    style={{fontWeight: 'normal'}}>
                                                    {t('updatedBy.label')}{' '}
                                                    {item.updated_by}
                                                </Typography>
                                            ) : null}
                                        </Grid>
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        </Grid>
                    )}
                    {/*<TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item, index) => (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading ? (
                                    list.length > 0 ? (
                                        list.map((script, rowIndex) => (
                                            <ProjectManagementTrackItems
                                                key={rowIndex}
                                                t={t}
                                                script={script}
                                            />
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>
                                                    Record not found!
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Loading...</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>*/}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'center'}>
                    <Button
                        style={{marginRight: '5px'}}
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('close.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
ProjectManagementTrackStatusDianModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ProjectManagementTrackStatusDianModal);
