import React, { useState } from 'react';
import {
  NewSubscriptionPaths,
  Paths,
} from '../../routes/routePaths';
import { get, differenceBy } from "lodash-es";
import { fade, makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/Layout';
import Message from '../../components/Message';
import Typography from '@material-ui/core/Typography';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_SUBSCRIPTION_DETAILS } from '../Subscriptions/queries';
import ServiceItem from './components/ServiceItem';
import Grid from '@material-ui/core/Grid';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';
import Button from '@material-ui/core/Button';
import PlacesMap from './components/PlacesMap';
import Divider from '@material-ui/core/Divider';
import { PLACE_ORDER } from './mutations';
import { getParam } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscriptions.label', link: Paths.Subscriptions },
  { label: 'newSubscriptions.label', link: Paths.NewSubscription },
  { label: 'reviewConfig.label', link: NewSubscriptionPaths.ReviewSites }
];

const useStyles = makeStyles(theme => ({
  reviewItemContainer: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  indexContainer: {
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white
  },
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2)
  },
  footerBar: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(3),
    maxWidth: '680px'
  },
  footerContainer: {
    bottom: 0,
    left: 0
  },
  btn: {
    color: '#5a1f2a', 
    border: '1px solid #5a1f2a', 
    padding: theme.spacing(1.5, 2), 
    background: '#fff'
  }
}));

function ReviewSites({ t }) {
  const id = getParam();

  const { data: subscriptionDetails, error, loading } = useQuery(
    GET_SUBSCRIPTION_DETAILS,
    {
      variables: { id },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const { getSubscriptionById } = data;
        const siteIds =
          getSubscriptionById.sites &&
          getSubscriptionById.sites.map(item => item.id);
          console.log("siteIds: ", siteIds);
        setState({ ...state, siteIds });
      }
    }
  );

  const history = useHistory();

  const [state, setState] = useState({});
  const [placeOrderBtnLoading, setPlaceOrderBtnLoading] = useState(false);

  const [placeOrder] = useMutation(PLACE_ORDER, {
    onCompleted: data => {
      setPlaceOrderBtnLoading(false);
      Message.success('Order placed successfully.');
      history.push(Paths.Subscriptions);
    },
    onError: err => {
      console.log('ERR', err);
      setPlaceOrderBtnLoading(false);
    }
  });

  const classes = useStyles();

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '680px' }}>
      <Header title={t('reviewConfig.label')} divider={true} />
      {!loading && !error ? (
        <Grid
          style={{ ...marginGenerator('mt-32'), ...marginGenerator('mb-100') }}>
          <ServiceItem
            t={t}
            data={
              subscriptionDetails.getSubscriptionById &&
              subscriptionDetails.getSubscriptionById.service
            }
            containerStyle={{
              backgroundColor: fade(colors.grey[200], 0.5),
              ...paddingGenerator('pt-32'),
              ...paddingGenerator('pb-32'),
              ...paddingGenerator('pl-40'),
              ...paddingGenerator('pr-40')
            }}
            avatarContainerStyle={marginGenerator('mr-32')}
            index={1}
            variant={'mini'}
          />

          <>
            {subscriptionDetails.getSubscriptionById &&
            subscriptionDetails.getSubscriptionById.sites &&
            subscriptionDetails.getSubscriptionById.sites.length
              ? subscriptionDetails.getSubscriptionById.sites.map(
                  (item, index) => (
                    <ReviewSiteItem
                      t={t}
                      data={item}
                      index={index + 1}
                      key={index + 1}
                    />
                  )
                )
              : null}
          </>
        </Grid>
      ) : <div style={{width: "100%", textAlign: "center", paddingTop: 40}}><CircularProgress size={35} color="primary" /></div> }
      <Grid container className={classes.footerContainer} justify={'center'}>
        <Divider variant={'fullWidth'} absolute style={{ bottom: 'auto' }} />
        <Grid
          container
          item
          className={classes.footerBar}
          justify={'space-between'}>
          <Button className={classes.btn} variant={'outlined'} onClick={() => history.goBack()}>
            {t('editConfig.label')}
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={error || loading || placeOrderBtnLoading}
            onClick={() => {
              if(placeOrderBtnLoading){
                return null;
              }
             const sitesArray = get(subscriptionDetails.getSubscriptionById, 'sites', []);
              const updatedDataArr = sitesArray.filter((item, index) => {
                const dataSource =  sitesArray.map(x => x.name);
                const filteredArr = dataSource.indexOf(item.name) === index;
                return filteredArr
              });
              const duplicatevalue = differenceBy(sitesArray, updatedDataArr);
              if(duplicatevalue.length> 0) return  Message.error("Please update the Site name, It should be unique");
              setPlaceOrderBtnLoading(true);
               return placeOrder({
                 variables: {
                   subscriptionId: id,
                   siteIds: state.siteIds
                 }
               });
            }
            }>
            {placeOrderBtnLoading ? <CircularProgress size={20} color="primary" /> : t('confirmAndPlaceOrder.label')}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}

const ReviewSiteItem = ({ t, data, index }) => {
  const classes = useStyles();
  const [toggleState, setToggleState] = useState(false);
  const configuration = data.configuration
    ? JSON.parse(data.configuration)
    : [];

  return (
    <Grid
      className={classes.reviewItemContainer}
      style={paddingGenerator('pb-32')}>
      <Grid
        container
        justify={'space-between'}
        style={{
          ...marginGenerator('mt-40')
        }}
        className={classes.itemContainer}>
        <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
          <Grid
            item
            style={marginGenerator('mr-16')}
            className={classes.indexContainer}>
            <Typography variant={'body2'}>{index}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'subtitle1'} color={'textPrimary'}>
              {data.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs
          container
          justify={'flex-end'}
          style={marginGenerator('mt-8')}>
          <Button
            variant={'outlined'}
            size={'small'}
            color={'secondary'}
            onClick={() => setToggleState(!toggleState)}>
            {toggleState ? t('hideMap.label') : t('showMap.label')}
          </Button>
        </Grid>
      </Grid>
      {toggleState && (
        <Grid
          container
          style={marginGenerator('mt-24')}
          className={classes.mapContainer}>
          <PlacesMap
            id={index}
            search={false}
            position={{
              lat: parseFloat(data['latitude']),
              lng: parseFloat(data['longitude'])
            }}
          />
        </Grid>
      )}

      {configuration &&
        configuration.map((item, index) => (
          <Grid
            container
            item
            justify={'space-between'}
            key={index}
            style={{ ...marginGenerator('mt-24') }}>
            <Typography variant={'subtitle1'} style={{ fontWeight: 'normal' }}>
              {item.key &&
                `${item.key.charAt(0).toUpperCase()}${item.key.slice(1)}`}
            </Typography>
            <Typography variant={'subtitle1'} color={'textPrimary'}>
              {item.value}
            </Typography>
          </Grid>
        ))}

      <Grid
        container
        item
        justify={'space-between'}
        style={{ ...marginGenerator('mt-24') }}>
        <Typography variant={'subtitle1'} style={{ fontWeight: 'normal' }}>
          Address
        </Typography>
        <Typography variant={'subtitle1'} color={'textPrimary'}>
          {data.address}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ReviewSites);
