import React, { useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_DETAILS } from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {
  Paths,
  ServiceTicketPaths,
  SubscriptionPaths
} from '../../routes/routePaths';
import CustomTabs from '../../components/CustomTabs';
import { useState } from 'react';
import { getUrl } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { SUBSCRIPTION_STATUS } from '../../utils/constants';
import MenuWrapper from '../../components/MenuWrapper';
import SubscriptionPanel from './components/SubscriptionPanel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { USER_ROLES, SERVICE_TYPES } from '../../utils/constants';
import OrganisationBilling from "../BillingPanel";
import EditSubscriptionModal from './components/EditSubscriptionModal';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import { UPDATE_SUBSCRIPTION_AND_SITES, ASSIGN_SUBSCRIPTION_TO_USERS } from './mutations';
import Message from '../../components/Message';
import { GET_INVITE_MEMBERS } from '../Internal/Organisations/queries';
import AssignSubscriptionToUserModal from '../Internal/Organisations/components/AssignSubscriptionToUserModal';
import { Typography } from '@material-ui/core';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscriptions.label', link: Paths.Subscriptions }
];

const getInputs = (type, t) => {
  let inputArr = [];
  console.log("type: ", type);
  if (type === SERVICE_TYPES.MPLS) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        placeholder: '',
        endAdorment: 'MBPS'
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.DIA) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        placeholder: ''
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      },
      {
        label: t('noOfPublicIPs.label'),
        type: 'number',
        stateVar: `noOfPublicIPs`,
        placeholder: ''
      },
      {
        label: t('blockedUrls.label'),
        type: 'string',
        stateVar: `blockedUrls`,
        placeholder: ''
      },
      {
        label: t('portForwarding.label'),
        type: 'string',
        stateVar: `portForwarding`,
        placeholder: ''
      },
      {
        label: t('ddosPrevention.label'),
        type: 'string',
        stateVar: `ddosPrevention`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.EDGE_AI) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('numberOfCam.label'),
        type: 'number',
        stateVar: `numberOfCamera`,
        placeholder: 'numberOfCamPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fibre', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      }
    ];
  } else if (type === SERVICE_TYPES.SDN) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        //endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fiber', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      },
      {
        label: t('serviceType.label'),
        type: 'selectDropdown',
        stateVar: `serviceType`,
        options: ['SD-WAN', 'Security', 'BYOVNF'],
        placeholder: 'serviceTypePh.label'
      }
    ];
  }
  return [
    {
      label: t('siteName.label'),
      type: 'string',
      stateVar: `siteName`,
      placeholder: 'siteNamePh.label'
    },
    ...inputArr
  ];
};

const SubscriptionLists = ({ t }) => {
  
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [subscribeBtnShow, setSubscribeBtnShow] = useState(false);
  const [editSubscriptionModal, setEditSubscriptionModal] = useState({ open: false, btnLoading: false, clearData: false, data: [] });
  const [editSubscriptionState, setEditSubscriptionState] = useState({});
  const [locationState, setLocationState] = useState({});
  const [inputArr, setInputArr] = useState([]);
  const history = useHistory();
  const [editSubsLoading, setEditSubsLoading] = useState(false);
  const [assignSubsToUserModalOpen, setAssignSubsToUserModalOpen] = useState(false);
  const [assignSubscription, setAssignSubscription] = useState(null);

  const ownerId = localStorage.getItem("orgId")
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (location.state) setActiveIndex(location.state.activeIndex);
  
    if (
      role.includes(USER_ROLES.EP_FINANCE) ||
      role.includes(USER_ROLES.EP_IT) ||
      role.includes(USER_ROLES.IP_USER)
    ) {
      setSubscribeBtnShow(false);
    } else {
      setSubscribeBtnShow(true);
    }
  }, [location]);

  /*
     List Queries
    */
  const {
    loading: activeLoading,
    error: activeError,
    data: activeSubscriptions,
    refetch: reloadActiveSubscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      status: SUBSCRIPTION_STATUS.ACTIVE
    }
  });

  const {
    loading: pendingLoading,
    error: pendingError,
    data: pendingSubscriptions,
    refetch: reloadPendingSubscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      status: SUBSCRIPTION_STATUS.PENDING
    }
  });

  const { data: teamList, loading: teamLoading } = useQuery(GET_INVITE_MEMBERS, {
    fetchPolicy: "no-cache",
    variables: {
        orgId: ownerId,
        roleType: USER_ROLES.IP_USER
    }
  });

  const [assignSubscriptionToUsers, {loading: assignLoading}] = useMutation(ASSIGN_SUBSCRIPTION_TO_USERS, {
    onError: (e) => {
      Message.error(e);
    },
    onCompleted: (res) => {
      Message.success("User assigned successfully!");
      if(res.assignSubscriptionToUsers){
        setAssignSubsToUserModalOpen(false);
        setAssignSubscription(null);
        reloadActiveSubscriptionList();
      }
    }
  });

  const handleSubscriptionItemClick = id => {
    const path = getUrl(SubscriptionPaths.PendingSubscriptionDetails, id);
    history.push(path);
    return null;
  };

  const handleActiveSubscriptionItemClick = (id, activeIndex) => {
    const path = getUrl(SubscriptionPaths.ActiveSubscriptionDetails, id);
    history.push({
      pathname: path,
      ...(activeIndex ? { state: { activeIndex } } : {})
    });
    return null;
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
    } else if (item.label === t('viewDetails.label')) {
      handleActiveSubscriptionItemClick(id);
    } else if (item.label === t('viewConfiguration.label')) {
      handleActiveSubscriptionItemClick(id, 1);
    } else if (item.label === t('openServiceTickets.label')) {
      handleActiveSubscriptionItemClick(id, 2);
    } else if (item.label === t('raiseNewTicket.label')) {
      history.push(ServiceTicketPaths.NewServiceTicket);
    } else if (item.label === t('edit.label')) {
      handleEditActiveSubscriptionClick(id);
    } else if (item.label === t('assignToUser.label')) {
      setAssignSubsToUserModalOpen(true);
      setAssignSubscription(id);
    }
    return null;
  };

  const handleEditActiveSubscriptionClick = (id) => {
    if (id) {
      setEditSubsLoading(true);
      setEditSubscriptionModal({ open: true, btnLoading: false, clearData: false });
      return getSubscriptionByIdForEdit({
        variables: {
          id: id
        }
      });
    }
  };

  const handleSubmitEditSubscription = async (data) => {
    setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: true, clearData: false });
    let sites = Object.keys(editSubscriptionState).map((key) => editSubscriptionState[key]);
    await updateSubscriptionAndSite({
      variables: {
        id: data.id,
        name: data.name,
        sites: sites
      }
    })
  };

  const [getSubscriptionByIdForEdit] = useLazyQuery(GET_SUBSCRIPTION_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setEditSubsLoading(false);
      if (data.getSubscriptionById) {
        setInputArr(getInputs((data.getSubscriptionById.service && data.getSubscriptionById.service.serviceType) ? data.getSubscriptionById.service.serviceType.name : "", t));
        const { getSubscriptionById } = data;
        setEditSubscriptionModal({...editSubscriptionModal, data: getSubscriptionById});
        const { sites } = getSubscriptionById;
        let updatedSites = {};
        sites && sites.length && sites.forEach((item, index) => {
            const configuration =
              item.configuration && JSON.parse(item.configuration);
            let confObj = {};
            configuration.forEach((item, index) => {
              confObj = { ...confObj, [item.key]: item.value };
            });
            updatedSites = {
              ...updatedSites,
              [`site${index + 1}`]: {
                ...editSubscriptionState[`site${index}`],
                id: item.id,
                siteName: item.name,
                address: item.address,
                latitude: item.latitude,
                longitude: item.longitude,
                zipcode: item.zipcode,
                landmark: item.landmark,
                ...confObj
              }
            };
        });
        setEditSubscriptionState({ ...editSubscriptionState, ...updatedSites });
      }
    }
  });

  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  
  const [updateSubscriptionAndSite] = useMutation(UPDATE_SUBSCRIPTION_AND_SITES, {
    onError: error => {
      setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
      console.log('Error', error);
    },
    onCompleted: async data => {
      if(data.updateSubscriptionAndSites && data.updateSubscriptionAndSites.id != null){
        Message.success("Subscription updateted successfully");
        if(editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.ACTIVE){
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
          reloadActiveSubscriptionList();
        } else if(editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.PENDING){
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
          reloadPendingSubscriptionList();
        }
      } else {
        setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
      }
    }
  });

  const handleInputChange = (name, value, site) => {
    if (name === 'country') {
      getRegionList({ variables: { countryId: value } });
    }
    setEditSubscriptionState({ ...editSubscriptionState, [site]: { ...editSubscriptionState[site], [name]: value } });
  };

  const handleSetPosition = (pos, site) => {
    setLocationState({
      ...locationState,
      [site]: {
        latitude: pos.latitude,
        longitude: pos.longitude
      }
    });
  };

  const editMenuItems = [
    // { label: t('openSiteConfiguration.label') },
    { label: t('removeSite.label') }
  ];

  const handleEditMenuItemClick = (item, site) => {};

  const handleHeaderMenuClick = item => {
    if (item.label === t('raiseNewTicket.label')) {
      history.push(ServiceTicketPaths.NewServiceTicket);
    } else if (item.label === t('browseServices.label')) {
      handleNewSubscriptionClick('?browse');
    }
    return null;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const headerMenuItems = [
    { label: t('browseServices.label') },
    { label: t('raiseNewTicket.label') }
  ];

  const handleNewSubscriptionClick = searchParam => {
    history.push({
      pathname: Paths.NewSubscription,
      ...(searchParam === '?browse' ? { search: searchParam } : {})
    });
  };

  const handleSubmitAssignUser = (assignee) => {
    console.log("assignee: ", assignee);
    if(assignee && assignSubscription){
      return assignSubscriptionToUsers({variables: {
        id: assignSubscription.id,
        userIds: assignee 
      }});
    }
  }

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('subscriptions.label')}
        subText={`${
          activeSubscriptions && activeSubscriptions.getAllSubscriptions
            ? activeSubscriptions.getAllSubscriptions.length
            : 0
        } ${t('activeSubscriptions.label')} • ${
          pendingSubscriptions && pendingSubscriptions.getAllSubscriptions
            ? pendingSubscriptions.getAllSubscriptions.length
            : 0
        } ${t('pendingActivation.label')}`}
        primaryButtonText={subscribeBtnShow ? t('newSubscription.label') : null}
        handlePrimaryButtonClick={handleNewSubscriptionClick}
        handleMenuClick={evt => setAnchorEl(evt.currentTarget)}
      />
      {anchorEl && (
        <MenuWrapper
          anchorEl={anchorEl}
          handleMenuItemClick={handleHeaderMenuClick}
          handleClose={() => {
            setAnchorEl(null);
          }}
          menuItems={headerMenuItems}
        />
      )}
      <CustomTabs
        activeIndex={activeIndex}
        tabs={[
          {
            label: t('active.label'),
            children: (
              <>
               {activeLoading &&  <Typography
                variant={'subtitle2'}>...Loading, please wait!</Typography>}
                {!activeLoading && !activeError && activeSubscriptions && (
                  <SubscriptionPanel
                    subscriptionList={activeSubscriptions.getAllSubscriptions}
                    type={SUBSCRIPTION_STATUS.ACTIVE}
                    t={t}
                    label={t('activeSubscriptions.label')}
                    handleMenuItemClick={handleMenuItemClick}
                    handleSubscriptionItemClick={
                      handleActiveSubscriptionItemClick
                    }
                  />
                )}
              </>
            )
          },
          {
            label: t('pendingActivation.label'),
            children: (
              <>
                {!pendingLoading && !pendingError && pendingSubscriptions && (
                  <SubscriptionPanel
                    subscriptionList={pendingSubscriptions.getAllSubscriptions}
                    type={SUBSCRIPTION_STATUS.PENDING}
                    t={t}
                    label={t('pendingActivation.label')}
                    handleMenuItemClick={handleMenuItemClick}
                    handleSubscriptionItemClick={handleSubscriptionItemClick}
                  />
                )}
              </>
            )
          },
          {
            label: t('billing.label'),
            children: (
                <>
                    {!activeLoading && !activeError && activeSubscriptions && (
                        <OrganisationBilling
                            subscriptionList={activeSubscriptions.getAllSubscriptions ? activeSubscriptions.getAllSubscriptions : []}
                            organization={activeSubscriptions.getAllSubscriptions && activeSubscriptions.getAllSubscriptions.length > 0 ? activeSubscriptions.getAllSubscriptions[0].ownerId : ""}
                            t={t}
                        />
                    )}
                </>
            )
          }
        ]}
      />
      {(activeLoading || pendingLoading) && (
        <LinearProgress
          color="primary"
          style={{
            top: '98px',
            left: '0px',
            position: 'absolute',
            width: '100%'
          }}
        />
      )}
      <AssignSubscriptionToUserModal
        t={t}
        handleClose={() => {
            setAssignSubsToUserModalOpen(false);
            setAssignSubscription(null);
        }}
        subscriptionData={assignSubscription}
        list={(teamList && teamList.inviteMembersAnOrganization) ? teamList.inviteMembersAnOrganization : []}
        open={assignSubsToUserModalOpen}
        handleSubmitAssignUser={handleSubmitAssignUser}
        teamLoading={teamLoading}
        assignLoading={assignLoading}
      />
      <EditSubscriptionModal
        t={t}
        open={editSubscriptionModal.open}
        handleSubmitClick={handleSubmitEditSubscription}
        modalState={editSubscriptionModal}
        handleClose={() => {
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
        }}
        subscriptionData={editSubscriptionModal.data}
        state={editSubscriptionState}
        inputArr={inputArr}
        handleMenuItemClick={handleEditMenuItemClick}
        menuItems={editMenuItems}
        handleSetPosition={handleSetPosition}
        handleInputChange={handleInputChange}
        countryList={countryList && countryList.getCountries}
        regionList={regionList && regionList.getRegionsByCountryId}
        loading={editSubsLoading}
      />
    </Layout>
  );
};
export default withTranslation()(SubscriptionLists);
