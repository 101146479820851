import React, {useState} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import ImageModal from '../../../../components/ImageModal';
import { dateFormatterLLL } from '../../../../utils';

const HeatmapPanelItem = ({
    key,
    t,
    row
}) => {

    const [imageModal, setImageModalState] = useState(false);
    const [imageModalSrc, setImageModalSrcState] = useState('');

    const handleImageModalClick = event => {
        setImageModalSrcState(event.target.src);
        setImageModalState(true);
    };

    return (
        <>    
            <TableRow key={key}>
                <TableCell>{row.camera}</TableCell>
                <TableCell>{row.created_at ? dateFormatterLLL(row.created_at) : '-'}</TableCell>
                <TableCell>{row.start_time ? dateFormatterLLL(row.start_time) : null} - {row.end_time ? dateFormatterLLL(row.end_time) : null}</TableCell>
                <TableCell>{row.object_type}</TableCell>
                <TableCell>
                    {row.image?
                    <Avatar 
                        style={{cursor:'pointer'}} 
                        alt="" 
                        src={row.image} 
                        onClick={(evt) => {
                        handleImageModalClick(evt);
                        }} />
                        : 'Pending'}
                </TableCell>
            </TableRow>
            <ImageModal
                t={t}
                open={imageModal}
                imageSrc={imageModalSrc}
                handleClose={() => setImageModalState(false)}
            />
        </>    
    )
}

export default HeatmapPanelItem
