import React, {useState, useEffect, useContext, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import { useReactToPrint } from 'react-to-print';
import {useMutation} from '@apollo/react-hooks';
import { get, toNumber } from "lodash-es";
import {useFormik} from "formik";
import * as Yup from "yup";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Message from '../../../../../../components/Message';
import {
    PRINT_SHAYONA_TRIP_PACKAGE_SLIP
  } from '../../../../Shayona/mutations';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';
import PrintSlip from '../../components/PrintSlip';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%",
    }
  });
  
  const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        type="submit"
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Print'}
        </Button>
    );
  }

const TripPrintSlipModal = ({
    t,
    open,
    onClose,
    data
}) => {

    const classes = useStyles();
    const {organizationId,
        reloadShayonaApisOrPagination
             } = useContext(ShayonaCementsContext);

    const validationSchema = Yup.object({
        bagsAllocated: Yup
                  .string('Please fill allocated Bags number')
                  .required('Please fill allocated Bags number'),
    });
  
    const formik = useFormik({
      initialValues: {
        bagsAllocated: ''
      },
      validationSchema: validationSchema,
      enableReinitialize:true,
      onSubmit: (values) => {
      handleSubmtiOfTruckDetails(values);
      }, 
    });
  
   const {handleSubmit, handleBlur, handleChange, touched, errors, values, resetForm } = formik;

   const [tripPrintSlip, { loading: tripPrintSlipLoading }] = useMutation(PRINT_SHAYONA_TRIP_PACKAGE_SLIP, {
    onError: error => {
        console.log('err', error);

    },
    onCompleted: data => {
        handlePrint();
        Message.success(data && data.printTripPackageSlip.message);
        onClose();
        reloadShayonaApisOrPagination('trip','');
    }
  });

   const handleSubmtiOfTruckDetails = (values) => {
    tripPrintSlip({
        variables:{
            organization: organizationId,
            tripId: data && data.trip_id,
            bagsAllocated:  toNumber(values && values.bagsAllocated)
        }
    })
   };

   const componentRef = useRef();
   const handlePrint = useReactToPrint({
     content: () => componentRef.current,
   });
  
   useEffect(() => {
    if(!open){
      resetForm();
    }
   }, [open]);

  return (
    <>
    <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
         Print slip process
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
                <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={12}>
                                      <TextField
                                        value={values.bagsAllocated}
                                        label="Allocated Bags" 
                                        name="bagsAllocated"
                                        style={{ width: '100%'}}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.bagsAllocated && errors.bagsAllocated}
                                        error={touched.bagsAllocated && Boolean(errors.bagsAllocated)}
                                      />
                                  </Grid>
                                </Grid>
                                <DialogActions>
                                  <Grid container justify={'space-between'}>
                                      <Button 
                                          variant={'outlined'}
                                          color="primary"
                                          onClick={onClose}
                                      >
                                          {t('cancel.label')}
                                      </Button>
                                      <ButtonComponent
                                       t={t}
                                       loading={tripPrintSlipLoading}
                                      />
                                  </Grid>          
                              </DialogActions>
                            </form>
        </DialogContent>
    </Dialog>
    <div style={{ display: "none" }}>
        <PrintSlip
            t={t}
            type="entry"
            ref={componentRef}
            truckDetails={data}
            formDetails={values}
        />
    </div>
    </>
  )
}

export default TripPrintSlipModal