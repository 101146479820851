import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {NewSubscriptionPaths, Paths} from '../../routes/routePaths';
import {useHistory} from 'react-router-dom';
import {marginGenerator} from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import ConfigureSiteItem from './components/ConfigureSiteItem';
import {SERVICE_TYPES, SUBSCRIPTION_STATUS} from '../../utils/constants';
import {useLocation} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {ADD_SITE_TO_SUBSCRIPTION, UPDATE_SITE_DETAILS} from './mutations';
import {GET_COUNTRY_LIST, GET_REGION_LIST} from '../commonQueries';
import {GET_SUBSCRIPTION_DETAILS} from '../Subscriptions/queries';
import {getUrl} from '../../utils';
import Message from '../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';

const breadcrumbList = [
    {label: 'home.label', link: Paths.Dashboard},
    {label: 'subscriptions.label', link: Paths.Subscriptions},
    {label: 'newSubscriptions.label', link: Paths.NewSubscription},
    {label: 'configureSites.label', link: NewSubscriptionPaths.ConfigureSites}
];

const getInputs = (type, t) => {
    let inputArr = [];
    if (type === SERVICE_TYPES.MPLS) {
        inputArr = [
            {
                label: t('bandwidth.label'),
                type: 'number',
                stateVar: `bandwidth`,
                placeholder: '',
                endAdorment: 'MBPS'
            },
            {
                label: t('mtu.label'),
                type: 'number',
                stateVar: `mtu`,
                placeholder: ''
            },
            {
                label: t('period.label'),
                type: 'number',
                stateVar: `period`,
                placeholder: ''
            },
            {
                label: t('linkType.label'),
                type: 'string',
                stateVar: `linkType`,
                placeholder: ''
            }
        ];
    } else if (type === SERVICE_TYPES.DIA) {
        inputArr = [
            {
                label: t('subject.label'),
                type: 'string',
                stateVar: `subject`,
                endAdorment: '',
                placeholder: t('subjectPlaceHolder.label')
            },
            {
                label: t('opportunityType.label'),
                type: 'selectDropdown',
                stateVar: `opportunityType`,
                endAdorment: '',
                options: ['Provider'],
                placeholder: ''
            },
            {
                label: t('billingAccount.label'),
                type: 'selectDropdown',
                stateVar: `billingAccount`,
                endAdorment: '',
                options: ['B1000008'],
                placeholder: ''
            },
            {
                label: t('currency.label'),
                type: 'selectDropdown',
                stateVar: `currency`,
                endAdorment: '',
                options: ['BWP'],
                placeholder: ''
            },
            {
                label: t('distance.label'),
                type: 'selectDropdown',
                stateVar: `distance`,
                endAdorment: '',
                options: ['less than 250', 'less than 250' ],
                placeholder: ''
            },
            {
                label: t('protectionType.label'),
                type: 'selectDropdown',
                stateVar: `protectionType`,
                endAdorment: '',
                options: ['Protected', 'Unprotected'],
                placeholder: ''
            },
            {
                label: t('bandwidth.label'),
                type: 'selectDropdown',
                stateVar: `bandwidth`,
                endAdorment: '',
                options: [
                    '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15',
                    '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
                    '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
                    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51',
                    '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63',
                    '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75',
                    '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87',
                    '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99',
                    '100', '108', '110', '115', '120', '126', '135', '144', '150', '155',
                    '165', '175', '200', '210', '220', '250', '252', '260', '300', '310',
                    '350', '400', '410', '465', '500', '540', '600', '622', '700', '775',
                    '800', '900', '930', '1000', '1085', '1240', '1395', '1550', '1705',
                    '1860', '2015', '2325', '2480', '2488', '2500', '3000', '3072', '4000',
                    '4650', '9300', '9952', '10240'
                ],
                placeholder: ''
            },
            {
                label: t('SiteAAddress.label'),
                type: 'string',
                stateVar: `SiteAAddress`,
                placeholder: ''
            },
            {
                label: t('remark.label'),
                type: 'string',
                stateVar: `remark`,
                placeholder: t('reviewPlaceholder.label')
            },
            {
                label: t('productName.label'),
                type: 'string',
                stateVar: `productName`,
                placeholder: ''
            },
            {
                label: t('billingCycle.label'),
                type: 'selectDropdown',
                stateVar: `billingCycle`,
                endAdorment: '',
                options: ['1 Month', '1 Year', '2 Year', '3 Year', '5 Year'],
                placeholder: ''
            },
            {
                label: t('SiteACountry.label'),
                type: 'selectDropdown',
                stateVar: `SiteACountry`,
                endAdorment: '',
                options: ['Botswana'],
                placeholder: ''
            },
            {
                label: t('SiteADistrict.label'),
                type: 'selectDropdown',
                stateVar: `SiteADistrict`,
                endAdorment: '',
                options: [
                    'Kweneng', 'Kgatleng', 'Chobe', 'Boteti', 'Ngwaketse',
                    'North-West District', 'North West', 'Borolong', 'North-East District',
                    'South East', 'Central Tutume', 'Southern District', 'Central District',
                    'South-East District', 'Ghanzi', 'Kgalagadi South', 'Tswapong',
                    'Kgalagadi', 'Okavango District', 'Kweneng East', 'Ngamiland', 'Gantsi'
                ],
                placeholder: ''
            },
            {
                label: t('SiteACity.label'),
                type: 'selectDropdown',
                stateVar: `SiteACity`,
                endAdorment: '',
                options: [],
                placeholder: ''
            },
            {
                label: t('SiteACompanyName.label'),
                type: 'string',
                stateVar: `SiteACompanyName`,
                placeholder: ''
            },
            {
                label: t('SiteAContactName.label'),
                type: 'string',
                stateVar: `SiteAContactName`,
                placeholder: ''
            },
            {
                label: t('SiteAPlot.label'),
                type: 'string',
                stateVar: `SiteAPlot`,
                placeholder: ''
            },
            {
                label: t('SiteALocation.label'),
                type: 'string',
                stateVar: `SiteALocation`,
                placeholder: ''
            },
            {
                label: t('SiteACordinates.label'),
                type: 'string',
                stateVar: `SiteACordinates`,
                placeholder: ''
            },
            {
                label: t('SiteBAddress.label'),
                type: 'string',
                stateVar: `SiteBAddress`,
                placeholder: ''
            },
            {
                label: t('SiteBCompanyName.label'),
                type: 'string',
                stateVar: `SiteBCompanyName`,
                placeholder: ''
            },
            {
                label: t('SiteBCountry.label'),
                type: 'selectDropdown',
                stateVar: `SiteBCountry`,
                endAdorment: '',
                options: ['Botswana'],
                placeholder: ''
            },
            {
                label: t('SiteBDistrict.label'),
                type: 'selectDropdown',
                stateVar: `SiteBDistrict`,
                endAdorment: '',
                options: [
                    'Kweneng', 'Kgatleng', 'Chobe', 'Boteti', 'Ngwaketse',
                    'North-West District', 'North West', 'Borolong', 'North-East District',
                    'South East', 'Central Tutume', 'Southern District', 'Central District',
                    'South-East District', 'Ghanzi', 'Kgalagadi South', 'Tswapong',
                    'Kgalagadi', 'Okavango District', 'Kweneng East', 'Ngamiland', 'Gantsi'
                ],
                placeholder: ''
            },
            {
                label: t('SiteBCity.label'),
                type: 'selectDropdown',
                stateVar: `SiteBCity`,
                endAdorment: '',
                options: [],
                placeholder: ''
            },
            {
                label: t('SiteBContactName.label'),
                type: 'string',
                stateVar: `SiteBContactName`,
                placeholder: ''
            },
            {
                label: t('SiteBPlot.label'),
                type: 'string',
                stateVar: `SiteBPlot`,
                placeholder: ''
            },
            {
                label: t('SiteBLocation.label'),
                type: 'string',
                stateVar: `SiteBLocation`,
                placeholder: ''
            },
            {
                label: t('SiteBCordinates.label'),
                type: 'string',
                stateVar: `SiteBCordinates`,
                placeholder: ''
            }
        ];
    } else if (type === SERVICE_TYPES.DIAN) {
        inputArr = [
            {
                label: t('customerName.label'),
                type: 'string',
                stateVar: `customer_name`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('address.label'),
                type: 'string',
                stateVar: `address`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('CipPc.label'),
                type: 'string',
                stateVar: `product_code`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('phone.label'),
                type: 'string',
                stateVar: `phone`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('email.label'),
                type: 'string',
                stateVar: `email`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('latitude.label'),
                type: 'number',
                stateVar: `latitude`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('longitude.label'),
                type: 'number',
                stateVar: `longitude`,
                endAdorment: '',
                placeholder: ''
            },
            {
                label: t('altitude.label'),
                type: 'number',
                stateVar: `altitude`,
                placeholder: ''
            }
        ];
    } else if (type === SERVICE_TYPES.EDGE_AI || type === SERVICE_TYPES.SC) {
        inputArr = [
            {
                label: t('availableBandwidth.label'),
                type: 'selectDropdown',
                stateVar: `bandwidth`,
                endAdorment: 'MBPS',
                options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
                placeholder: 'availableBandwidthPh.label'
            },
            {
                label: t('numberOfCam.label'),
                type: 'number',
                stateVar: `numberOfCamera`,
                placeholder: 'numberOfCamPh.label'
            },
            {
                label: t('period.label'),
                type: 'selectDropdown',
                stateVar: `period`,
                options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
                placeholder: 'periodPh.label'
            },
            {
                label: t('linkType.label'),
                type: 'selectDropdown',
                stateVar: `linkType`,
                options: ['Fibre', 'Wireless', 'VSAT'],
                placeholder: 'linkTypePh.label'
            }
        ];
    } else if (type === SERVICE_TYPES.SDN) {
        inputArr = [
            {
                label: t('availableBandwidth.label'),
                type: 'selectDropdown',
                stateVar: `bandwidth`,
                //endAdorment: 'MBPS',
                options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
                placeholder: 'availableBandwidthPh.label'
            },
            {
                label: t('period.label'),
                type: 'selectDropdown',
                stateVar: `period`,
                options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
                placeholder: 'periodPh.label'
            },
            {
                label: t('linkType.label'),
                type: 'selectDropdown',
                stateVar: `linkType`,
                options: ['Fiber', 'Wireless', 'VSAT'],
                placeholder: 'linkTypePh.label'
            },
            {
                label: t('serviceType.label'),
                type: 'selectDropdown',
                stateVar: `serviceType`,
                options: ['SD-WAN', 'Security', 'BYOVNF'],
                placeholder: 'serviceTypePh.label'
            }
        ];
    }
    return [
        {
            label: t('siteName.label'),
            type: 'string',
            stateVar: `siteName`,
            placeholder: 'siteNamePh.label'
        },
        ...inputArr
    ];
};

const ConfigureSites = ({t}) => {
    const location = useLocation();
    const [state, setState] = useState({});
    const [locationState, setLocationState] = useState({});
    const [inputArr, setInputArr] = useState([]);
    const [serviceType, setServiceType] = useState("");
    const [currentSite, setCurrentSite] = useState(null);
    const [sitesACityData, setSitesACities] = useState([]);
    const [sitesBCityData, setSitesBCities] = useState([]);
    const [siteLoadingBtnState, setSiteLoadingBtnState] = useState({});
    const [loadingSubs, setLoadingSubs] = useState(false);

    const cities = {
        'Kweneng': [
            'Lentsweletau', 'Mogoditshane', 'Khudumelapye', 'Molepolole',
            'Metsimotlhaba', 'Kumakwane', 'Kopong', 'Mmopane', 'Thamaga',
            'Letlhakeng', 'Gabane', 'Mmankgodi', 'Sojwe',
            'Gaborone - RLU6 - Mogoditshane', 'Motokwe', 'Takatokwane',
            'Thebephatshwa', 'Mapharangwane', 'Lephephe', 'Moshaweng', 'Kubung',
            'Kaudwane'
        ],
        'Kgatleng': [
            'Mochudi', 'Malolwane', 'Bokaa', 'Oodi', 'Mochudi Main', 'Pilane',
            'Artesia', 'Mmamashia', 'Mabalane', 'Rasesa', 'Dikgonnye'
        ],
        'Chobe': ['Site 127', 'Lesoma', 'Mabele', 'Kavimba'],
        'Boteti': ['Toromoja', 'Malatswai', 'Kedia'],
        'Ngwaketse': ['Sesung'],
        'North-West District': [
            'Shorobe', 'Gumare', 'Seronga', 'Shakawe', 'Toteng', 'Etsha',
            'Sehithwa', 'Nokaneng', 'Maun', 'Kasane', 'Kazungula', 'Makalamabedi',
            'Motopi', 'Ngoma', 'Pandamatenga', 'Etsha 6', 'Mohembo', 'Sepopa',
            'Tsau', 'Beetsha', 'Chanoga', 'Hatsalatladi', 'Ngarange', 'Samuchima',
            'Shakwe', 'Etsha 13', 'Ikoga', 'Nxamasere', 'Samochima', 'Kauzwho',
            'Xakau', 'Sekondomboro', 'Mogotlho', 'Gunotsoga', 'Gudigwa', 'Sankoyo',
            'Mababe', 'Savuti', 'Kachikau', 'Kwende', 'Etsha 6'
        ],
        'North West': ['Rustenburg', 'Shakwe', 'Komana', 'Lesoma', 'Chobe'],
        'Borolong': ['Gathwane', 'Magotlhwane', 'Mabule'],
        'North-East District': [
            'Tati Siding', 'Francistown', 'Masunga', 'Dibete',
            'Francistown - Nyangagwe Hill', 'Tshesebe', 'Zwenshambe', 'Tsamaya',
            'Zwenzhambe', 'Ramokgwebana', 'Gambule', 'Mulambakwena',
            'Letsholathebe', 'Mosojane', 'Siviya', 'Nlapkhane', 'Salajwe',
            'Sekakangwe', 'Vukwi'
        ],
        'South East': ['Modipane'],
        'Central Tutume': ['Matobo'],
        'Southern District': [
            'Mmathethe', 'Ranaka', 'Jwaneng', 'Manyana', 'Lotlhakane',
            'Molapowabojang', 'Kanye', 'Moshupa', 'Morwamosu', 'Pitsane ',
            'Sekoma', 'Digawana', 'Goodhope', 'Khakhea', 'Mabutsane', 'Keng',
            'Moshaneng', 'Ramatlabama', 'Ntlhantlhe', 'Gasita', 'Pitseng',
            'Selokolela', 'Lorolwane', 'Kanye'
        ],
        'Central District': [
            'Rakops', 'Serowe', 'Lerala', 'Mmadinare', 'Tobane', 'Mahalapye',
            'Bobonong', 'Tonota', 'Shashe-Mooke', 'Sebina', 'Mookane', 'Orapa',
            'Tumasera/Seleka', 'Lecheng', 'Shoshong', 'Tsetsebjwe', 'Mathangwane',
            'Chadibe', 'Thabala', 'Palapye', 'Nata', 'Mopipi', 'Dukwi', 'Borolong',
            'Maitengwe', 'Mandunyane', 'Tutume', 'Gweta', 'Selibe Phikwe',
            'Letlhakane', 'Maunatlala', 'Sefophe', 'Moiyabana', 'Marobela',
            'Nkange', 'Ramokgonami', 'Letlhakane', 'Khumaga', 'Sebina ',
            'Serowe High', 'Machaneng', 'Maokatumo', 'Mokobeng', 'Molalatau',
            'Mopipi', 'Moreomaoto', 'Palapye RLU 1', 'Rep 2', 'Tsetsebjwe Hill',
            'Xhumo', 'GooTau', 'Kachikau', 'Mathambgwane', 'Mogapi', 'Ncamasera',
            'Seleka', 'Taupye', 'Mmashoro', 'Serule', 'Cordon Fence', 'Dikalate',
            'Majwaneng', 'Malaka', 'Martins Drift', 'Matlhakola', 'Mhalapitsa',
            'Mogorosi', 'Moremi', 'Mosolotshane', 'Moeng', 'Motshegaletau',
            'Ngwapa', 'Paje', 'Radisele', 'Ratholo', 'Sefhare', 'Seolwane',
            'Sherwood', 'Ngwasha Cordeone Fence', 'Tamasane', 'Sowa', 'Goshwe',
            'Gobojango', 'Kudumatse', 'Mathathane', 'Matsiloje', 'Marapong',
            'Mosu', 'Nswazi', 'Shashe', 'Tuli Block', 'Makaleng', 'Xhumaga',
            'Kedia', 'Maape', 'Malatswai', 'Matsitama', 'Moeng', 'Toromoja',
            'Sepako', 'Borolong', 'Ramokgoname', 'Mmashoro', 'Ratholo', 'Rakops',
            'Nata'
        ],
        'South-East District': [
            'Otse', 'Lobatse', 'Ramotswa', 'Gaborone', 'Tlokweng', 'RLU10 Airport',
            'Lobatse RLU 2', 'Taung', 'Mogonye', 'Mokolodi'
        ],
        'Ghanzi': [
            'Ghanzi', 'Charleshill', 'Dkar', 'Kuke', 'Tsootsha', 'Xhanagas',
            'Lone Tree', 'Site FS', 'Mamuno', 'Xanagas'
        ],
        'Kgalagadi South': ['Maleshe'],
        'Tswapong': ['Makwate'],
        'Kgalagadi': [
            'Hukuntsi', 'Tshabong', 'Kang', 'Khisa', 'Kokotsha', 'Makopong',
            'Omaweneno', 'Werda', 'Bokspits', 'Draaihoek', 'Gachibana',
            'Kolonkwane', 'Middlepits', 'Kokong', 'Phuduhudu', 'Lone Tree',
            'Khonkhwa', 'Phepheng', 'Khakhea', 'Maralaleng', 'McCarthys Trust',
            'Deekbos', 'Bogogobo', 'Khuis', 'Gakhibana', 'Rapplespan', 'Vaalhoek',
            'Struizendam', 'Hereford/Bray', 'Lehututu', 'Two Rivers', 'Draihoek',
            'Lokgwabe', 'Mokhomba', 'Pitseng', 'Hunhukwe', 'Khawa', 'Inalegolo',
            'Tsabong'
        ],
        'Okavango District': ['Kauxwi'],
        'Kweneng East': ['Kubung', 'Hatsalatladi'],
        'Ngamiland': ['Xakao', 'Eretsha', 'Komana', 'Phuduhudu'],
        'Gantsi': [
            'Karakubis', 'Chobokwane', 'East Hanahai', 'Groote Laagte',
            'Moreomaoto', 'New Xade', 'West Hanahai', 'Kole', 'New Xanagas',
            'D`Kar', 'Ncojane', 'Gantsi BTC'
        ]
    }
    const [getSubscriptionDetails] = useLazyQuery(GET_SUBSCRIPTION_DETAILS, {
        fetchPolicy: 'no-cache',
        onError: er => {
            setLoadingSubs(false);
            handleGoBack();
        },
        onCompleted: data => {
            const {getSubscriptionById} = data;
            const {sites} = getSubscriptionById;
            let updatedSites = {};
            let updatedLocation = {};
            sites &&
            sites.length &&
            sites.forEach((item, index) => {
                if (item.status === SUBSCRIPTION_STATUS.NEW) {
                    const configuration =
                        item.configuration && JSON.parse(item.configuration);
                    let confObj = {};
                    configuration.forEach((item, index) => {
                        confObj = {...confObj, [item.key]: item.value};
                    });
                    updatedLocation = {...updatedLocation, [`site${index + 1}`]: {latitude: item.latitude,
                    longitude: item.longitude}};
                    updatedSites = {
                        ...updatedSites,
                        [`site${index + 1}`]: {
                            ...state[`site${index}`],
                            id: item.id,
                            siteName: item.name,
                            address: item.address,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            zipcode: item.zipcode,
                            landmark: item.landmark,
                            ...confObj
                        }
                    };
                } else {
                    return null;
                }
            });
            setLocationState({...locationState, ...updatedLocation});
            setState({...state, ...updatedSites});
            setLoadingSubs(false);
        }
    });

    useEffect(() => {
        const splitArr = location.search.split('?');
        const id = splitArr[1].split('=')[1];
        const sites = splitArr[2].split('=')[1];
        const type = splitArr[3].split('=')[1].replace(/%20/g, ' ');
        setServiceType(type);
        setState({...state, id, sites: parseInt(sites), type});
        setInputArr(getInputs(type, t));
        setLoadingSubs(true);
        return getSubscriptionDetails({
            variables: {id}
        });
    }, [location]);

    const [getRegionList, {data: regionList}] = useLazyQuery(GET_REGION_LIST);
    const {data: countryList} = useQuery(GET_COUNTRY_LIST);
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    const [addSiteToSubscription] = useMutation(ADD_SITE_TO_SUBSCRIPTION, {
        onError: error => {
            console.log('Error', error);
            setSiteLoadingBtnState({...siteLoadingBtnState, [currentSite]: false});
        },
        onCompleted: async data => {
            const {addNewSiteToSubscription} = data;
            setState({
                ...state,
                [currentSite]: {
                    ...state[currentSite],
                    id: addNewSiteToSubscription.id
                }
            });
            setSiteLoadingBtnState({...siteLoadingBtnState, [currentSite]: false});
        }
    });

    const [updateSiteDetails] = useMutation(UPDATE_SITE_DETAILS, {
        onError: error => {
            console.log('Error', error);
            setSiteLoadingBtnState({...siteLoadingBtnState, [currentSite]: false});
        },
        onCompleted: res => {
            setSiteLoadingBtnState({...siteLoadingBtnState, [currentSite]: false});
        }
    });

    const menuItems = [
        // { label: t('openSiteConfiguration.label') },
        {label: t('removeSite.label')}
    ];

    const handleInputChange = (name, value, site) => {
        if (name === 'SiteADistrict') {
            setSitesACities(cities[value]);
        }
        if (name === 'SiteBDistrict') {
            setSitesBCities(cities[value]);
        }
        if (name === 'country') {
            getRegionList({variables: {countryId: value}});
        }
        setState({...state, [site]: {...state[site], [name]: value}});
    };

    const handleResetConfigClick = site => {
        setState({...state, [site]: {}});
    };

    const handleMenuItemClick = (item, site) => {
        if (item.label === menuItems[0].label) {
            const tempState = state;
            delete tempState[site];
            setState({...tempState, sites: state.sites - 1});
        }
    };

    const handleSetPosition = (pos, site) => {
        setLocationState({
            ...locationState,
            [site]: {
                latitude: pos.latitude,
                longitude: pos.longitude
            }
        });
    };

    const handleSaveClick = async site => {
        setCurrentSite(site);
        const siteItem = state[site];
        if(!state[site] || !locationState[site]){
            Message.error("Please fill all site details!");
            return null;
        }
        setSiteLoadingBtnState({...siteLoadingBtnState, [site]: true});
        const locationItem = locationState[site];
        const configuration = inputArr
            .map((item, index) =>
                item.stateVar !== 'siteName'
                    ? {key: item.stateVar, value: siteItem[item.stateVar]}
                    : null
            )
            .filter((item, index) => item !== null);
        if (siteItem.id) {
            await updateSiteDetails({
                variables: {
                    id: siteItem.id,
                    name: siteItem.siteName,
                    configuration: JSON.stringify(configuration),
                    latitude: locationItem.latitude,
                    longitude: locationItem.longitude,
                    address: siteItem.address,
                    serviceType: serviceType,
                    regionId: siteItem.region,
                    countryId: siteItem.country,
                    zipcode: siteItem.zipcode
                }
            });
        } else {
            await addSiteToSubscription({
                variables: {
                    name: siteItem.siteName,
                    subscriptionId: state.id,
                    configuration: JSON.stringify(configuration),
                    latitude: locationItem.latitude,
                    longitude: locationItem.longitude,
                    address: siteItem.address,
                    serviceType: serviceType,
                    regionId: siteItem.region,
                    countryId: siteItem.country,
                    zipcode: siteItem.zipcode
                }
            });
        }
        setState({...state, [site]: {...state[site], saved: true}});
        return true;
    };

    const checkValid = () => {
        return Object.keys(state)
            .filter(
                (item, index) =>
                    item.includes('site') && typeof state[item] !== 'number'
            )
            .map((item, index) => !(state[item].id || state[item].saved))
            .includes(true);
    };

    return (
        <Layout breadcrumbList={breadcrumbList}>
            {loadingSubs ? 
                <div style={{width: "100%", textAlign: "center", paddingTop: 40}}><CircularProgress size={35} color="primary" /></div>
            :
            <>
                <Header
                    title={t('configureSitesDesc.label')}
                    subText={t('configureSitesSubtext.label')}
                    primaryButtonText={t('cancel.label')}
                    secondaryButtonText={t('goBack.label')}
                    handlePrimaryButtonClick={() => history.go(-2)}
                    handleSecondaryButtonClick={handleGoBack}
                    divider={true}
                />
                <Grid style={{...marginGenerator('mt-32')}}>
                    {[...Array(state.sites)].length &&
                    [...Array(state.sites)].map((item, index) =>
                        state[`site${index + 1}`] &&
                        state[`site${index + 1}`].removed === true ? null : (
                            <ConfigureSiteItem
                                t={t}
                                key={index}
                                data={inputArr}
                                currentState={state}
                                handleMenuItemClick={handleMenuItemClick}
                                index={index + 1}
                                menuItems={menuItems}
                                handleSetPosition={handleSetPosition}
                                handleResetConfigClick={handleResetConfigClick}
                                handleInputChange={handleInputChange}
                                handleSaveClick={handleSaveClick}
                                sitesACityData={sitesACityData}
                                sitesBCityData={sitesBCityData}
                                countryList={countryList && countryList.getCountries}
                                regionList={regionList && regionList.getRegionsByCountryId}
                                loadingBtn={siteLoadingBtnState && siteLoadingBtnState[`site${index + 1}`] ? siteLoadingBtnState[`site${index + 1}`] : false}
                            />
                        )
                    )}
                </Grid>
                <Grid
                    container
                    justify={'space-between'}
                    style={{...marginGenerator('mt-24'), ...marginGenerator('mb-24')}}>
                    {serviceType !== SERVICE_TYPES.DIA && <Button
                        variant={'outlined'}
                        onClick={() =>
                            setState({
                                ...state,
                                sites: state.sites + 1,
                                [`site${state.sites + 1}`]: {}
                            })
                        }>
                        {t('addSite.label')}
                    </Button>}

                    <Button
                        onClick={() => {
                            const path = getUrl(NewSubscriptionPaths.ReviewSites, state.id);
                            history.push(path);
                        }}
                        disabled={checkValid()}
                        color="primary"
                        variant={'contained'}>
                        {t('proceed.label')}
                    </Button>
                </Grid>
            </>
            }
        </Layout>
    );
};
export default withTranslation()(ConfigureSites);
