import React, { useState } from 'react';
import { marginGenerator } from '../../../../theme/utils';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Typography from '@material-ui/core/Typography';
import TicketItem from '../TicketItem';
import PropTypes from 'prop-types';
import SearchBox from '../../../../components/SearchBox';
import DateFilter from '../DateTimeFilter';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './styles.css';

const TicketPanel = ({
  t,
  ticketList,
  type,
  filterList,
  loading,
  handleTicketItemClick,
  handleMenuItemClick,
  selectedCloseTicketsId,
  handleSearch,
  exportTicketId,
  handleChangeAgent,
  agentData,
  onEnterSearch,
  uniqueId,
  handleDateFilter,
  handleSelectAllTickets,
  handleSelectAllExportTickets,
  handleCloseMultipleTickets,
  handleExportMultipleTickets,
  search = true
}) => {
  const [agent, setAgent] = useState('');
  const handleChangeAgentData = (event, newValue) => {
    handleChangeAgent(newValue);
  };

  const handleChangeAllTickets = (data, event) => {
    handleSelectAllTickets(data, event)
  }

  const handleChangeAllExportTickets = (data, event) => {
    handleSelectAllExportTickets(data, event)
  }
  return (
    <div>
      <div className="dateFilterItemList">
        {typeof uniqueId == 'undefined' && search && (
          <SearchBox handleSearch={onEnterSearch} onEnterSearch={onEnterSearch} />
        )}

        {filterList &&
          filterList.length > 0 ?
          filterList.map((filter, index) => (
            <OutlinedSelect
              key={index}
              val={filter.val}
              label={filter.label}
              handleChange={filter.handleChange}
              IconComponent={filter.iconComponent}
              options={filter.options}
              styleOverrides={filter.styleOverrides || null}
              disabled={filter.disabled || false}
            />
          )) : null}
        {type === 'openTickets' && typeof uniqueId == 'undefined' && (
          <div className="filterAgent">
            <Autocomplete
              id="combo-box-demo"
              val={agent}
              options={agentData}
              getOptionLabel={option =>
                option ? `${option.name} ${option.surname}` : ''
              }
              onChange={(event, newValue) =>
                handleChangeAgentData(event, newValue)
              }
              style={{ width: 200 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('assignedTo.label')}
                  variant="outlined"
                />
              )}
            />
          </div>
        )}
      </div>
      {/* <DateFilter handleDateFilter={handleDateFilter} /> */}

      <Typography
        variant={'subtitle2'}
        style={{
          ...(filterList.length
            ? marginGenerator('mt-32')
            : marginGenerator('mt-0')),
          ...marginGenerator('mb-32')
        }}>
        {t('showing.label')} {ticketList && ticketList.length}{' '}
        {t(`${type}.label`).toLowerCase()}
      </Typography>
      {!loading && type && (type === 'openTickets' || type === 'newTickets') && typeof uniqueId == 'undefined' && ticketList && ticketList.length > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={ticketList && ticketList.length === selectedCloseTicketsId.length}
              onChange={event => handleChangeAllTickets(ticketList, event)}
              color="primary"
            />
          }
          label="Select All Tickets"
          style={marginGenerator('ml-0')}
        />
      )}
      {!loading && type && type === 'openTickets' && uniqueId == 'exportTicket' && ticketList && ticketList.length > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={ticketList && ticketList.length === exportTicketId.length}
              onChange={event => handleChangeAllExportTickets(ticketList, event)}
              color="primary"
            />
          }
          label="Select All Tickets"
          style={marginGenerator('ml-0')}
        />
      )}

      {loading ? (
        <LinearProgress color="secondary" />
      ) : ticketList && ticketList.length > 0 && ticketList.map((item, index) => (
        <TicketItem
          key={index}
          data={item}
          type={type}
          uniqueId={uniqueId ? uniqueId : ''}
          selectedCloseTicketsId={selectedCloseTicketsId}
          handleCloseMultipleTickets={handleCloseMultipleTickets}
          handleTicketItemClick={handleTicketItemClick}
          exportTicketId={exportTicketId ? exportTicketId : ''}
          handleExportMultipleTickets={
            handleExportMultipleTickets ? handleExportMultipleTickets : ''
          }
          handleMenuItemClick={handleMenuItemClick}
        />
      ))
      }
    </div>
  );
};

TicketPanel.propTypes = {
  ticketList: PropTypes.array,
  filterList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired,
  search: PropTypes.bool
};

export default TicketPanel;
