import React, { useEffect, useState, memo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_COUNTRY_CODE_LIST } from '../commonQueries';
import OcrProvider from '../../contexts/OcrProvider';
import ShayonaCementsProvider from '../../contexts/ShayonaCementsProvider';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {
    Paths,
    ServiceTicketPaths,
    SubscriptionPaths
} from '../../routes/routePaths';
import {
    getParam,
    getUrl,
    dateFormatterLLLHHMMSS
} from '../../utils';
import {
    SERVICE_TYPES,
    STATUS_TYPES,
    SUBSCRIPTION_STATUS,
    TICKET_PRIORITY
} from '../../utils/constants';
import { GET_SERVICE_TICKETS } from '../ServiceTickets/queries';
import { useHistory, useLocation } from 'react-router';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Flag from '@material-ui/icons/Flag';
import Message from '../../components/Message';
//EDGE AI Service Modules
import {
    GET_SUBSCRIPTION_DETAILS,
    GET_NOTIFICATION_REPORT,
    GET_CAMERA_LIST,
    GET_DEMO_VIDEOS
} from './queries';
import LinearProgress from '@material-ui/core/LinearProgress';
import useSound from 'use-sound';
import OcrPanel from './components/OcrPanel';
import ShayonaCementsProduct from './components/ShayonaCementsProduct';
import notifySound from '../../assets/notify-sound.mp3';
import { useToasts } from 'react-toast-notifications'
import Notifier from "react-desktop-notification";
import EdgeAIService from './Services/EdgeAIService';
import ManagedNFVService from './Services/ManagedNFVService';
import DIABotswanaService from './Services/DIABotswanaService';
import CloudSolutions from './Services/CloudSolutions';
import RFPManagementService from './Services/RFPManagementService';
import IOTSensors from './Services/IOTSensors';
import MorupuleCoalService from './Services/MorupuleCoalService';
import ValioService from './Services/ValioService';
import LivenessCheck from './Services/LivenessCheck';
import { calculateCameraUpTime } from './components/EdgeAIDashBoardPanel';
import IntelligentSaleTracker from './Services/IntelligentSaleTracker';
import SideNavMenu from './components/SideNavMenu';
import DocAIService from './Services/DocAIService';

const ActiveServiceTab = memo((props) => {
    const { service } = { ...props };
    if (service === SERVICE_TYPES.EDGE_AI) {
        return (
            <EdgeAIService
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                handleChangeActiveNotiTab={props.handleChangeActiveNotiTab}
                activeNotificationTab={props.activeNotificationTab}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
                filterListRecentTickets={props.filterListRecentTickets}
                handleChangeActiveIndex={props.handleChangeActiveIndex}
                getTabList={props.getTabList}
                subActiveIndex={props.subActiveIndex}
                selectedPath={props.selectedPath}
                demoVideosLoading={props.demoVideosLoading}
                demoVideos={props.demoVideos}
            />
        );
    } else if (service === SERVICE_TYPES.SDN) {
        return (
            <ManagedNFVService
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                handleChangeActiveNotiTab={props.handleChangeActiveNotiTab}
                activeNotificationTab={props.activeNotificationTab}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
                filterListRecentTickets={props.filterListRecentTickets}
            />
        );
    } else if (service === SERVICE_TYPES.DIA) {
        return (
            <DIABotswanaService
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                handleChangeActiveNotiTab={props.handleChangeActiveNotiTab}
                activeNotificationTab={props.activeNotificationTab}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                filterListRecentTickets={props.filterListRecentTickets}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
            />
        );
    } else if (service === SERVICE_TYPES.OCR) {
        return (
            <OcrProvider data={props.data}>
                <OcrPanel
                    t={props.t}
                    data={props.data}
                    sideBarActiveIndex={props.activeIndex}
                    getTabList={props.getTabList}
                />
            </OcrProvider>
        )
    } else if (service === SERVICE_TYPES.SC) {
        return (
            <ShayonaCementsProvider reloadSubscription={props.reloadSubscription} countryCodes={props.countryCodes} data={props.data} t={props.t}>
                <ShayonaCementsProduct
                    t={props.t}
                    data={props.data}
                    activeIndex={props.activeIndex}
                />
            </ShayonaCementsProvider>
        )
    } else if (service === SERVICE_TYPES.RFP_MANAGEMENT_SYSTEM) {
        return (
            <RFPManagementService
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
            />
        );
    } else if (service === SERVICE_TYPES.IOT_SENSORS) {
        return (
            <IOTSensors
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                filterListRecentTickets={props.filterListRecentTickets}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
            />
        );
    } else if (service === SERVICE_TYPES.MORUPULE_COAL_MINE) {
        return (
            <MorupuleCoalService
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                filterListRecentTickets={props.filterListRecentTickets}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
            />
        );
    } else if (service === SERVICE_TYPES.VALIO_SERVICE) {
        return (
            <OcrProvider data={props.data}>
                <ValioService
                    t={props.t}
                    data={props.data}
                    activeIndex={props.activeIndex}
                    reloadSubscription={props.reloadSubscription}
                    countryCodes={props.countryCodes}
                    serviceTickets={props.serviceTickets}
                    handleSearchRecentTickets={props.handleSearchRecentTickets}
                    handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                    handleTicketItemClick={props.handleTicketItemClick}
                    filterListRecentTickets={props.filterListRecentTickets}
                    recentTicketsSearched={props.recentTicketsSearched}
                    updatedRecentTickets={props.updatedRecentTickets}
                />
            </OcrProvider>
        );
    } else if (service === SERVICE_TYPES.LIVENESS_CHECK) {
        return (
            <LivenessCheck
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
            />
        );
    } else if (service === SERVICE_TYPES.INTELLIGENT_SALE_TRACKER) {
        return (
            <OcrProvider data={props.data}>
                <IntelligentSaleTracker
                    t={props.t}
                    data={props.data}
                    activeIndex={props.activeIndex}
                    reloadSubscription={props.reloadSubscription}
                    countryCodes={props.countryCodes}
                    serviceTickets={props.serviceTickets}
                    handleSearchRecentTickets={props.handleSearchRecentTickets}
                    handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                    handleTicketItemClick={props.handleTicketItemClick}
                    filterListRecentTickets={props.filterListRecentTickets}
                    recentTicketsSearched={props.recentTicketsSearched}
                    updatedRecentTickets={props.updatedRecentTickets}
                />
            </OcrProvider>
        );
    } else if (service === SERVICE_TYPES.DOC_AI_2_0) {
        return (
            <OcrProvider data={props.data}>
                <DocAIService
                    t={props.t}
                    data={props.data}
                    activeIndex={props.activeIndex}
                    getTabList={props.getTabList}
                    handleChangeActiveNotiTab={props.handleChangeActiveNotiTab}
                    subActiveIndex={props.subActiveIndex}
                    selectedPath={props.selectedPath}
                />
            </OcrProvider>
        );
    } else {
        return (
            <CloudSolutions
                t={props.t}
                data={props.data}
                activeIndex={props.activeIndex}
                reloadSubscription={props.reloadSubscription}
                handleChangeActiveNotiTab={props.handleChangeActiveNotiTab}
                activeNotificationTab={props.activeNotificationTab}
                countryCodes={props.countryCodes}
                serviceTickets={props.serviceTickets}
                handleSearchRecentTickets={props.handleSearchRecentTickets}
                handleTicketMenuItemClick={props.handleTicketMenuItemClick}
                handleTicketItemClick={props.handleTicketItemClick}
                filterListRecentTickets={props.filterListRecentTickets}
                recentTicketsSearched={props.recentTicketsSearched}
                updatedRecentTickets={props.updatedRecentTickets}
            />
        );
    }
});

const ActiveSubscriptionDetails = ({ t }) => {

    const id = getParam();
    const history = useHistory();
    const location = useLocation();
    const [play] = useSound(notifySound);
    const { addToast } = useToasts();
    const [activeIndex, setActiveIndex] = useState(0);
    const [subActiveIndex, setSubActiveIndex] = useState(0);
    useEffect(() => {
        if (location.state) setActiveIndex(location.state.activeIndex);
    }, [location]);
    const [breadcrumbList, setBreadcrumbList] = useState([
        { label: 'home.label', link: Paths.Dashboard },
        { label: 'subscriptions.label', link: Paths.Subscriptions },
        {
            label: 'activeSubscriptionDetails.label',
            link: SubscriptionPaths.ActiveSubscriptionDetails
        }
    ]);
    const [recentTicketsSearched, setRecentTicketsSearched] = useState(false);
    const [status, setStatus] = useState('');
    const [priority, setPriority] = useState('');
    const [updatedRecentTickets, setUpdatedRecentTickets] = useState([]);
    const [imageModal, setImageModalState] = useState(false);
    const [imageModalSrc, setImageModalSrcState] = useState('');
    const [headerNotificationList, setHeaderNotificationList] = useState(null);
    const [activeNotificationTab, setActiveNotificationTab] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [apiTimer, setApiTimer] = useState(0);
    const [cameraUpTime, setCameraUpTime] = useState(0);
    const [serviceNavigations, setServiceNavigations] = useState([]);
    const [selectedPath, setSelectedPath] = useState(null);
    const [activeSubpath, setActiveSubpath] = useState(null);

    const hostName = window.location.hostname;
    // const hostName ="trans.edgedock.inq.co.bw"

    const { data: countryCodes, loading: ccLoading } = useQuery(GET_COUNTRY_CODE_LIST);

    const [getAllCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getAllCameraList && data.getAllCameraList.cameras && data.getAllCameraList.cameras.length) {
                let camVideoTime = calculateCameraUpTime(data.getAllCameraList.cameras, true);
                setCameraUpTime(camVideoTime);
            }
        }
    });

    /* Queries */
    const { loading, data, error, refetch: reloadSubscription } = useQuery(
        GET_SUBSCRIPTION_DETAILS,
        {
            variables: {
                id
            },
            onCompleted: res => {
                if (res.getSubscriptionById) {
                    if (
                        res.getSubscriptionById.service &&
                        res.getSubscriptionById.service.serviceType &&
                        res.getSubscriptionById.service.serviceType.name ===
                        SERVICE_TYPES.EDGE_AI
                    ) {
                        getAllCameraList({
                            variables: {
                                organization: data.getSubscriptionById.organizationId,
                                search: "",
                                page: 1,
                                limit: 20000
                            }
                        });
                        //GET Header Notifications
                        return getNotificationsReportList({ variables: { organization: data.getSubscriptionById.organizationId, page: 1, limit: 10 } });
                    }
                }
            }
        }
    );

    const {
        loading: serviceTicketLoading,
        data: serviceTickets,
        error: serviceTicketError
    } = useQuery(GET_SERVICE_TICKETS, {
        variables: {
            subscriptionId: id
        }
    });

    const activeSites = (!loading && !error && data.getSubscriptionById &&
        data.getSubscriptionById.sites && data.getSubscriptionById.sites.length && data.getSubscriptionById.sites.filter(item => item.status === SUBSCRIPTION_STATUS.ACTIVE)) || [];
    const filterListRecentTickets = [
        {
            label: 'Status',
            iconComponent: CheckCircle,
            handleChange: evt => {
                const statusVal = evt.target.value;
                getRecentTicketFilteredList({
                    variables: {
                        status: statusVal,
                        subscriptionId: id,
                        ...(priority ? { priority } : {})
                    }
                });
                setStatus(statusVal);
            },
            val: status,
            options: [STATUS_TYPES.OPEN, STATUS_TYPES.CLOSED]
        },
        {
            label: 'Priority',
            iconComponent: Flag,
            handleChange: evt => {
                const priorityVal = evt.target.value;
                getRecentTicketFilteredList({
                    variables: {
                        priority: priorityVal,
                        subscriptionId: id,
                        ...(status ? { status } : {})
                    }
                });
                setPriority(priorityVal);
            },
            val: priority,
            options: [
                TICKET_PRIORITY.HIGH,
                TICKET_PRIORITY.MEDIUM,
                TICKET_PRIORITY.LOW
            ]
        }
    ];

    const [getRecentTicketFilteredList] = useLazyQuery(GET_SERVICE_TICKETS, {
        onCompleted: data => {
            setRecentTicketsSearched(true);
            setUpdatedRecentTickets(data.servicetickets);
        }
    });

    const [getNotificationsReportList] = useLazyQuery(GET_NOTIFICATION_REPORT, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setFirstLoad(true);
            if (data.getNotificationsReportList) {
                if (firstLoad && headerNotificationList && data.getNotificationsReportList.count > headerNotificationList.count) {
                    if (data.getNotificationsReportList.notifications && data.getNotificationsReportList.notifications.length > 0) {
                        let newNoti = data.getNotificationsReportList.notifications[0];
                        play();
                        const evntNotifi = <div style={{ cursor: "pointer" }} onClick={handleClickToastNotification}>
                            <b>You have received a new event</b>
                            <br />
                            <span>
                                Model - {newNoti.model_type ? newNoti.model_type : "-"}
                                <br />
                                Camera - {newNoti.camera ? newNoti.camera.name : "-"}
                                <br />
                                Place - {newNoti.camera && newNoti.camera.place ? newNoti.camera.place.name : "-"}
                                <br />
                                Time - {dateFormatterLLLHHMMSS(newNoti.created_at)}
                            </span>
                        </div>;
                        addToast(evntNotifi);
                        Notifier.focus("You have received a new event",
                            `Model - ${newNoti.model_type ? newNoti.model_type : "-"}, Camera - ${newNoti.camera ? newNoti.camera.name : "-"}, Place - ${newNoti.camera && newNoti.camera.place ? newNoti.camera.place.name : "-"}, Time - ${dateFormatterLLLHHMMSS(newNoti.created_at)}`, window.location.href);
                    }
                }
                setHeaderNotificationList(data.getNotificationsReportList);
            }
        }
    }
    );

    const handleTicketItemClick = id => {
        const path = getUrl(ServiceTicketPaths.Details, id);
        history.push(path);
    };

    const handleTicketMenuItemClick = (item, id) => {
        if (item.label === t('closeTicket.label')) {
            // close ticket request
        } else if (item.label === t('viewDetails.label')) {
            handleTicketItemClick(id);
        }
    };

    const handleSearchRecentTickets = query => {
        if (query !== '') {
            const updatedList =
                serviceTickets.servicetickets &&
                serviceTickets.servicetickets
                    .slice(0, 10)
                    .filter(
                        (item, index) =>
                            item.title.toLowerCase().includes(query.toLowerCase()) ||
                            item.ticketId.toLowerCase().includes(query.toLowerCase())
                    );
            setRecentTicketsSearched(true);
            setUpdatedRecentTickets(updatedList);
        } else {
            setRecentTicketsSearched(false);
            setUpdatedRecentTickets([]);
        }
    };

    const handleSetImageModalSrc = event => {
        setImageModalSrcState(event.target.src);
        setImageModalState(true);
    };

    const handleChangeActiveNotiTab = (st) => {
        setActiveNotificationTab(st);
        if (!st) {
            setActiveIndex(0);
        }
    };

    const handleChangeActiveIndex = (tab) => {
        setActiveIndex(tab);
    }

    let serviceTypeName;
    let HeaderSubText = '';
    if (!loading && !error) {
        if (data.getSubscriptionById && data.getSubscriptionById.isDelete) {
            Message.error('Subscription termineted!');
            history.goBack();
        }
        serviceTypeName = data.getSubscriptionById.service.serviceType
            ? data.getSubscriptionById.service.serviceType.name
            : '';
        if (serviceTypeName === SERVICE_TYPES.EDGE_AI) {
            HeaderSubText = `${data.getSubscriptionById.noOfActiveSites ? data.getSubscriptionById.noOfActiveSites : activeSites.length} ${t('activeSites.label')} • ${data
                .getSubscriptionById.noOfCPEs || 0} ${t('cameras.label')} • ${cameraUpTime}% ${t('uptime.label')}`;
        } else if (serviceTypeName === SERVICE_TYPES.SDN) {
            HeaderSubText = `${activeSites.length} ${t('activeSites.label')} • ${data
                .getSubscriptionById.noOfCPEs || 0} ${t('cpes.label')}`;
        } else {
            HeaderSubText = `${activeSites.length} ${t('activeSites.label')}`;
        }
    }

    const handleClickToastNotification = () => {
        setActiveIndex(4);
        handleChangeActiveNotiTab(true);
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setApiTimer(apiTimer + 1);
            ReCallAPIEvery30Second();
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [apiTimer]);

    const ReCallAPIEvery30Second = () => {
        //GET Header Notifications
        if (serviceTypeName === SERVICE_TYPES.EDGE_AI && !loading && !error && data.getSubscriptionById && data.getSubscriptionById.organizationId) {
            return getNotificationsReportList({ variables: { organization: data.getSubscriptionById.organizationId, page: 1, limit: 10 } });
        }
    }

    const getTabList = (tabList) => {
        const navs = tabList.map((item, index) => ({
            label: item.label,
            path: index,
            icon: item.icon,
            subPaths: item.subPaths
        }));
        const activePath = localStorage.getItem('activePath') ? localStorage.getItem('activePath') : null;
        const activeSubIndex = localStorage.getItem('activeSubIndex') ? localStorage.getItem('activeSubIndex') : null;
        const selectedPath = navs.find(obj => obj.label === activePath);
        if (!activeSubIndex && selectedPath) {
            setSelectedPath(selectedPath?.label);
            setActiveIndex(selectedPath?.path);
            setServiceNavigations(navs);
        } else if (activeSubIndex) {
            setSubActiveIndex(+activeSubIndex);
            setSelectedPath(selectedPath?.label);
            setActiveIndex(selectedPath?.path);
            setActiveSubpath(+activeSubIndex + selectedPath?.label);
            setServiceNavigations(navs);
        }
        else {
            setSelectedPath(navs[0]?.label);
            setActiveSubpath(0 + navs[0]?.label);
            setActiveIndex(navs[0]?.path);
            setServiceNavigations(navs);
        }
    };

    useEffect(() => {
        return () => {
            localStorage.removeItem('activePath');
            localStorage.removeItem('activeSubIndex');
        }
    }, [])

    const { loading: demoVideosLoading, data: demoVideos, error: demoVideosError } = useQuery(GET_DEMO_VIDEOS, {
        fetchPolicy: 'no-cache',
    });


    return (
        <Layout breadcrumbList={breadcrumbList} headerNotifications={headerNotificationList} handleNotificationClick={handleClickToastNotification} handleSetImageModalSrc={handleSetImageModalSrc} serviceName={
            data && data?.getSubscriptionById?.service
                ? data?.getSubscriptionById?.service?.name
                : ''
        }>
            {!loading && !error && !demoVideosLoading ? (
                data.getSubscriptionById && (data.getSubscriptionById.service.name === "Edge AI Service" || data.getSubscriptionById.service.name === "DocAI 2.0" || data.getSubscriptionById.service.name === "Intelligent Documents") ?
                    <SideNavMenu
                        title={
                            data.getSubscriptionById && data.getSubscriptionById.service
                                ? data.getSubscriptionById.service.name
                                : ''
                        }
                        navs={serviceNavigations}
                        handleChangeActiveNotiTab={handleChangeActiveNotiTab}
                        setActiveIndex={setActiveIndex}
                        setSubActiveIndex={setSubActiveIndex}
                        selectedPath={selectedPath}
                        setSelectedPath={setSelectedPath}
                        activeSubpath={activeSubpath}
                        setActiveSubpath={setActiveSubpath}
                    >
                        <>
                            <Header
                                title={
                                    data.getSubscriptionById && data.getSubscriptionById.service
                                        ? data.getSubscriptionById.name
                                            ? data.getSubscriptionById.service.name +
                                            ' - ' +
                                            data.getSubscriptionById.name
                                            : data.getSubscriptionById.service.name
                                        : '-'
                                }
                                subText={HeaderSubText}
                                primaryButtonText={t('raiseRequest.label')}
                                handlePrimaryButtonClick={() =>
                                    history.push(ServiceTicketPaths.NewServiceTicket)
                                }
                            />
                            <ActiveServiceTab
                                t={t}
                                data={data}
                                activeIndex={activeIndex}
                                reloadSubscription={reloadSubscription}
                                handleChangeActiveNotiTab={handleChangeActiveNotiTab}
                                activeNotificationTab={activeNotificationTab}
                                countryCodes={!ccLoading ? countryCodes : null}
                                serviceTickets={!serviceTicketError && !serviceTicketLoading ? serviceTickets : null}
                                handleSearchRecentTickets={handleSearchRecentTickets}
                                handleTicketMenuItemClick={handleTicketMenuItemClick}
                                handleTicketItemClick={handleTicketItemClick}
                                filterListRecentTickets={filterListRecentTickets}
                                recentTicketsSearched={recentTicketsSearched}
                                updatedRecentTickets={updatedRecentTickets}
                                service={serviceTypeName}
                                handleChangeActiveIndex={handleChangeActiveIndex}
                                getTabList={getTabList}
                                subActiveIndex={subActiveIndex}
                                selectedPath={selectedPath}
                                demoVideosLoading={demoVideosLoading}
                                demoVideos={demoVideos}
                            />
                        </>
                    </SideNavMenu> :
                    <>

                        {!hostName.includes('trans.edgedock.inq.co.bw') && <Header
                            title={
                                data.getSubscriptionById && data.getSubscriptionById.service
                                    ? data.getSubscriptionById.name
                                        ? data.getSubscriptionById.service.name +
                                        ' - ' +
                                        data.getSubscriptionById.name
                                        : data.getSubscriptionById.service.name
                                    : '-'
                            }
                            subText={HeaderSubText}
                            primaryButtonText={t('raiseRequest.label')}
                            handlePrimaryButtonClick={() =>
                                history.push(ServiceTicketPaths.NewServiceTicket)
                            }
                        />}

                        <ActiveServiceTab
                            t={t}
                            data={data}
                            activeIndex={activeIndex}
                            reloadSubscription={reloadSubscription}
                            handleChangeActiveNotiTab={handleChangeActiveNotiTab}
                            activeNotificationTab={activeNotificationTab}
                            countryCodes={!ccLoading ? countryCodes : null}
                            serviceTickets={!serviceTicketError && !serviceTicketLoading ? serviceTickets : null}
                            handleSearchRecentTickets={handleSearchRecentTickets}
                            handleTicketMenuItemClick={handleTicketMenuItemClick}
                            handleTicketItemClick={handleTicketItemClick}
                            filterListRecentTickets={filterListRecentTickets}
                            recentTicketsSearched={recentTicketsSearched}
                            updatedRecentTickets={updatedRecentTickets}
                            service={serviceTypeName}
                            handleChangeActiveIndex={handleChangeActiveIndex}
                            getTabList={getTabList}
                        />
                    </>
            ) : (
                loading && !error && demoVideosLoading && (
                    <LinearProgress
                        color="primary"
                        style={{
                            top: 153,
                            left: '0px',
                            position: 'absolute',
                            width: '100%',
                            zIndex: 9999
                        }}
                    />
                )
            )}



        </Layout>
    );
};
export default withTranslation()(React.memo(ActiveSubscriptionDetails));