// import React, { useEffect, useState } from 'react';
// import {
//   Avatar,
//   Card,
//   CardContent,
//   Divider,
//   CardActions,
//   Button,
//   TextField,
//   Grid
// } from '@material-ui/core';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import { useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
// import Maps from '../maps';
// import axios from 'axios';
// import moment from 'moment';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import { FABRIC_URL } from '../../../envConstants';

// const useStyles = makeStyles({
//   root: {
//     width: 275,
//     padding: '10px',
//     margin: '10px',
//     borderRadius: '15px',
//     boxShadow:
//       '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
//   },
//   cardGrid1: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   cardGrid2: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   cardGrid3: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   sub: {
//     width: '95%',
//     height: 300,
//     borderRadius: '15px',
//     marginLeft: '30px',
//     boxShadow:
//       '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
//   },
//   table: {
//     minWidth: 650
//   },
//   bullet: {
//     display: 'inline-block',
//     margin: '0 2px',
//     transform: 'scale(0.8)'
//   },
//   title: {
//     fontSize: 14
//   },
//   pos: {
//     marginBottom: 12
//   },
//   cont: {
//     background: 'linear-gradient(#5a1f2a, white)',
//     height: '100px',
//     width: '100px',
//     marginDown: '50px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   }
// });

// const logTypes = [
//   { name: 'Port', value: 'port' },
//   { name: 'Fcr', value: 'fcr' },
//   { name: 'Standard Unprotected VXC', value: 'standard-unprotected-vxc' },
//   { name: 'Premium Unprotected VXC', value: 'premium-unprotected-vxc' },
//   { name: 'Standard Protected VXC', value: 'standard-protected-vxc' }
// ];

// export default function FabricDashboard(props) {
//   const [items, setItems] = useState([]);
//   const [ports, setPorts] = useState([]);
//   const [fcr, setFcr] = useState([]);
//   const [vxc, setVxc] = useState([]);
//   const [logType, setLogType] = useState('port');

//   const classes = useStyles();

//   let history = useHistory();

//   const handleLogs = async event => {
//     const logType = event.target.value;
//     setLogType(logType);
//   };

//   useEffect(() => {
//     const portData = async () => {
//       const token = localStorage.getItem('syncomToken');
//       const res = await axios.get(
//         `${FABRIC_URL}serviceLog?log_type=${logType}&sort=-createdAt`,
//         {
//           headers: {
//             accessToken: `${token}`
//           }
//         },
//         {}
//       );

//       const portDatas = await res.data.result;
//       setItems(portDatas);
//     };
//     portData();
//   }, [logType]);

//   useEffect(() => {
//     const portData = async () => {
//       const token = localStorage.getItem('syncomToken');
//       const res = await axios.get(
//         `${FABRIC_URL}port/availability/by_user`,
//         {
//           headers: {
//             accessToken: `${token}`
//           }
//         },
//         {}
//       );
//       const portData = await res.data.result;
//       setPorts(portData);
//     };
//     portData();
//   }, []);

//   useEffect(() => {
//     const fcrData = async () => {
//       const token = localStorage.getItem('syncomToken');
//       const res = await axios.get(
//         `${FABRIC_URL}fcr/availability/by_user`,
//         {
//           headers: {
//             accessToken: `${token}`
//           }
//         },
//         {}
//       );
//       const fcrData = await res.data.result;
//       setFcr(fcrData);
//     };
//     fcrData();
//   }, []);

//   useEffect(() => {
//     const vxcData = async () => {
//       const token = localStorage.getItem('syncomToken');
//       const res = await axios.get(
//         `${FABRIC_URL}connection/availability/by_user`,
//         {
//           headers: {
//             accessToken: `${token}`
//           }
//         },
//         {}
//       );
//       const vxcData = await res.data.result;
//       setVxc(vxcData);
//     };
//     vxcData();
//   }, []);

//   const handleConnection = async () => {
//     localStorage.setItem('createConnection', 'yes');
//     history.push('fabricService/CreateConnection');
//   };

//   return (
//     <div style={{ width: '100%' }}>
//       <hr />
//       <Grid
//         container
//         xs={12}
//         style={{
//           width: '100%'
//         }}>
//         <Grid xs={12} sm={4} item container className={classes.cardGrid1}>
//           <Card className={classes.root}>
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 padding: '10px'
//               }}>
//               <div className={classes.cont}>
//                 <CardContent>
//                   <div>
//                     <Avatar
//                       style={{
//                         height: '50px',
//                         width: '50px',
//                         backgroundColor: 'white'
//                       }}>
//                       <svg
//                         version="1.1"
//                         id="Capa_1"
//                         xmlns="http://www.w3.org/2000/svg"
//                         x="0px"
//                         y="0px"
//                         viewBox="0 0 490 490">
//                         <g>
//                           <g>
//                             <path
//                               d="M0,0v490h490V0H0z M430.1,332.9h-87.5v50.9h-33.1v50.9H180.4v-50.6h-33.1v-51.3H59.9v-278h46.7v66.5h38.5V54.8h40.8v66.5
// 			                         h38.5V54.8h40.8v66.5h38.5V54.8h40.8v66.5H383V54.8h46.7v278.1L430.1,332.9L430.1,332.9z"
//                             />
//                           </g>
//                         </g>
//                       </svg>
//                     </Avatar>
//                   </div>
//                 </CardContent>
//               </div>
//               <div
//                 style={{
//                   marginRight: '30px'
//                 }}>
//                 <h5
//                   style={{
//                     marginLeft: '20px'
//                   }}>
//                   Port
//                 </h5>

//                 <ArrowUpwardIcon
//                   style={{
//                     color: 'green'
//                   }}
//                 />
//                 {ports.activePort}
//                 <ArrowDownwardIcon
//                   style={{
//                     color: 'red'
//                   }}
//                 />
//                 {ports.inActivePort}
//               </div>
//             </div>

//             <Divider />
//             <CardActions>
//               {localStorage
//                 .getItem('role')
//                 .split(',')[0]
//                 .includes('fabric_readonly') === false ? (
//                 <Button
//                   size="small"
//                   onClick={() => history.push('/fabricService/CreatePort')}>
//                   Create Port
//                 </Button>
//               ) : (
//                 <Button
//                   disabled
//                   size="small"
//                   onClick={() => history.push('fabricService/Services')}>
//                   Create Port
//                 </Button>
//               )}
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid xs={12} sm={4} item container className={classes.cardGrid2}>
//           <Card className={classes.root}>
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 padding: '10px'
//               }}>
//               <div className={classes.cont}>
//                 <CardContent>
//                   <div>
//                     <Avatar
//                       style={{
//                         height: '50px',
//                         width: '50px',
//                         backgroundColor: 'white'
//                       }}>
//                       <svg
//                         width="48px"
//                         height="48px"
//                         viewBox="0 0 48 48"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg">
//                         <rect
//                           width="48"
//                           height="48"
//                           fill="white"
//                           fill-opacity="0.01"
//                         />
//                         <path
//                           d="M36 8L13 8C10 8 4 10 4 16C4 22 10 24 13 24H35C38 24 44 26 44 32C44 38 38 40 35 40H12"
//                           stroke="black"
//                           stroke-width="4"
//                           stroke-linecap="round"
//                           stroke-linejoin="round"
//                         />
//                         <path
//                           d="M40 12C42.2091 12 44 10.2091 44 8C44 5.79086 42.2091 4 40 4C37.7909 4 36 5.79086 36 8C36 10.2091 37.7909 12 40 12Z"
//                           fill="#2F88FF"
//                           stroke="black"
//                           stroke-width="4"
//                           stroke-linejoin="round"
//                         />
//                         <path
//                           d="M8 44C10.2091 44 12 42.2091 12 40C12 37.7909 10.2091 36 8 36C5.79086 36 4 37.7909 4 40C4 42.2091 5.79086 44 8 44Z"
//                           fill="#2F88FF"
//                           stroke="black"
//                           stroke-width="4"
//                           stroke-linejoin="round"
//                         />
//                       </svg>
//                     </Avatar>
//                   </div>
//                 </CardContent>
//               </div>
//               <div
//                 style={{
//                   marginRight: '30px'
//                 }}>
//                 <h5
//                   style={{
//                     marginLeft: '20px'
//                   }}>
//                   FXC
//                 </h5>
//                 <ArrowUpwardIcon
//                   style={{
//                     color: 'green'
//                   }}
//                 />
//                 {vxc.activeCount}
//                 <ArrowDownwardIcon
//                   style={{
//                     color: 'red'
//                   }}
//                 />
//                 {vxc.inActiveCount}
//               </div>
//             </div>

//             <Divider />
//             <CardActions>
//               {localStorage
//                 .getItem('role')
//                 .split(',')[0]
//                 .includes('fabric_readonly') === false ? (
//                 <Button size="small" onClick={() => handleConnection()}>
//                   Create FXC
//                 </Button>
//               ) : (
//                 <Button
//                   disabled
//                   size="small"
//                   onClick={() =>
//                     history.push('fabricService/CreateConnection')
//                   }>
//                   Create FXC
//                 </Button>
//               )}
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid xs={12} sm={4} item container className={classes.cardGrid3}>
//           <Card className={classes.root}>
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 padding: '10px'
//               }}>
//               <div className={classes.cont}>
//                 <CardContent>
//                   <div>
//                     <Avatar
//                       style={{
//                         height: '50px',
//                         width: '50px',
//                         backgroundColor: 'white'
//                       }}>
//                       <svg
//                         version="1.1"
//                         id="Capa_1"
//                         xmlns="http://www.w3.org/2000/svg"
//                         x="0px"
//                         y="0px"
//                         width="43.422px"
//                         height="43.422px"
//                         viewBox="0 0 43.422 43.422">
//                         <g>
//                           <path
//                             d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093
// 		                         c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002
// 		                         c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591
// 		                         c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024
// 		                         c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128
// 		                         v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2
// 		                         V21.749h2.148v10.897h12.881v2L40.686,32.102z"
//                           />
//                         </g>
//                       </svg>
//                     </Avatar>
//                   </div>
//                 </CardContent>
//               </div>
//               <div
//                 style={{
//                   marginRight: '30px'
//                 }}>
//                 <h5
//                   style={{
//                     marginLeft: '20px'
//                   }}>
//                   FCR
//                 </h5>

//                 <ArrowUpwardIcon
//                   style={{
//                     color: 'green'
//                   }}
//                 />
//                 {fcr.activeFcr}
//                 <ArrowDownwardIcon
//                   style={{
//                     color: 'red'
//                   }}
//                 />
//                 {fcr.inActiveFcr}
//               </div>
//             </div>

//             <Divider />
//             <CardActions>
//               {localStorage
//                 .getItem('role')
//                 .split(',')[0]
//                 .includes('fabric_readonly') === false ? (
//                 <Button
//                   size="small"
//                   onClick={() => history.push('/fabricService/CreateFcr')}>
//                   Create FCR
//                 </Button>
//               ) : (
//                 <Button
//                   disabled
//                   size="small"
//                   onClick={() => history.push('/FXC/addConnections')}>
//                   Create FCR
//                 </Button>
//               )}
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>
//       <hr
//         style={{
//           marginTop: '20px',
//           marginBottom: '10px'
//         }}
//       />
//       <div>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             padding: '0 3em'
//           }}>
//           <div>
//             <p
//               style={{
//                 color: '#5a1f2a',
//                 fontWeight: '500',
//                 fontFamily: 'Roboto'
//               }}>
//               Recent Activity
//             </p>
//           </div>
//           <div>
//             <TextField
//               select
//               value={logType}
//               onChange={handleLogs}
//               SelectProps={{
//                 native: true
//               }}
//               variant="outlined"
//               size="small">
//               {logTypes.map(logType => (
//                 <option key={logType.value} value={logType.value}>
//                   {logType.name}
//                 </option>
//               ))}
//             </TextField>
//           </div>
//         </div>

//         <Card className={classes.sub}>
//           <div
//             style={{
//               marginLeft: '10px',
//               height: '300px'
//             }}>
//             <TableContainer
//               component={Paper}
//               style={{
//                 height: '300px'
//               }}>
//               <Table
//                 className={classes.table}
//                 aria-label="simple table"
//                 stickyHeader>
//                 <TableHead
//                   style={{
//                     backgroundColor: '#5a1f2a'
//                   }}>
//                   <TableRow
//                     style={{
//                       backgroundColor: '#5a1f2a'
//                     }}>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a',
//                         paddingLeft: '10px'
//                       }}>
//                       User Name
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a'
//                       }}>
//                       Port Name
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a'
//                       }}
//                       align="left">
//                       Action Type
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a'
//                       }}
//                       align="left">
//                       Log Type
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a'
//                       }}
//                       align="left">
//                       Date
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         color: 'white',
//                         backgroundColor: '#5a1f2a'
//                       }}
//                       align="left">
//                       Time(UTC)
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {items?.map(item => (
//                     <TableRow key={item.index} value={item._id}>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {item.user.name}
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {item.name}
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {item.action}
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {item.log_type}
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {moment(item.createdAt).format('DD/MM/YYYY')}
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         scope="row"
//                         style={{
//                           width: '160px'
//                         }}>
//                         {moment(item.createdAt).format('h:mm:ss a')}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>
//         </Card>
//       </div>
//       <hr
//         style={{
//           marginTop: '20px',
//           marginBottom: '10px'
//         }}
//       />
//       <Maps />
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  CardActions,
  Button,
  TextField,
  Grid,
  Box
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Maps from '../maps';
import axios from 'axios';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FABRIC_URL } from '../../../envConstants';

const useStyles = makeStyles({
  root: {
    width: 275,
    padding: '10px',
    margin: '10px',
    borderRadius: '15px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  cardGrid1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardGrid2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardGrid3: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sub: {
    width: '95%',
    height: 300,
    borderRadius: '15px',
    marginLeft: '30px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  table: {
    minWidth: 650
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cont: {
    background: 'linear-gradient(#5a1f2a, white)',
    height: '100px',
    width: '100px',
    marginDown: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const logTypes = [
  { name: 'Port', value: 'port' },
  { name: 'Fcr', value: 'fcr' },
  { name: 'Standard Unprotected VXC', value: 'standard-unprotected-vxc' },
  { name: 'Premium Unprotected VXC', value: 'premium-unprotected-vxc' },
  { name: 'Standard Protected VXC', value: 'standard-protected-vxc' }
];

export default function FabricDashboard(props) {
  const [items, setItems] = useState([]);
  const [ports, setPorts] = useState([]);
  const [fcr, setFcr] = useState([]);
  const [vxc, setVxc] = useState([]);
  const [logType, setLogType] = useState('port');

  const classes = useStyles();

  let history = useHistory();

  const handleLogs = async event => {
    const logType = event.target.value;
    setLogType(logType);
  };

  useEffect(() => {
    const portData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(
        `${FABRIC_URL}serviceLog?log_type=${logType}&sort=-createdAt`,
        {
          headers: {
            accessToken: `${token}`
          }
        },
        {}
      );

      const portDatas = await res.data.result;
      setItems(portDatas);
    };
    portData();
  }, [logType]);

  useEffect(() => {
    const portData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(
        `${FABRIC_URL}port/availability/by_user`,
        {
          headers: {
            accessToken: `${token}`
          }
        },
        {}
      );
      const portData = await res.data.result;
      setPorts(portData);
    };
    portData();
  }, []);

  useEffect(() => {
    const fcrData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(
        `${FABRIC_URL}fcr/availability/by_user`,
        {
          headers: {
            accessToken: `${token}`
          }
        },
        {}
      );
      const fcrData = await res.data.result;
      setFcr(fcrData);
    };
    fcrData();
  }, []);

  useEffect(() => {
    const vxcData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(
        `${FABRIC_URL}connection/availability/by_user`,
        {
          headers: {
            accessToken: `${token}`
          }
        },
        {}
      );
      const vxcData = await res.data.result;
      setVxc(vxcData);
    };
    vxcData();
  }, []);

  const handleConnection = async () => {
    localStorage.setItem('createConnection', 'yes');
    history.push('fabricService/CreateConnection');
  };

  return (
    <>

      <Grid container direction="column" spacing={2}>
        <hr />
        <Grid item>
          <Grid
            container
            justify="center"
            xs={12}
            style={{
              width: '100%'
            }}>
            <Grid xs={12} sm={4} item container className={classes.cardGrid1}>
              <Card className={classes.root}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px'
                  }}>
                  <div className={classes.cont}>
                    <CardContent>
                      <div>
                        <Avatar
                          style={{
                            height: '50px',
                            width: '50px',
                            backgroundColor: 'white'
                          }}>
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 490 490">
                            <g>
                              <g>
                                <path
                                  d="M0,0v490h490V0H0z M430.1,332.9h-87.5v50.9h-33.1v50.9H180.4v-50.6h-33.1v-51.3H59.9v-278h46.7v66.5h38.5V54.8h40.8v66.5
			                         h38.5V54.8h40.8v66.5h38.5V54.8h40.8v66.5H383V54.8h46.7v278.1L430.1,332.9L430.1,332.9z"
                                />
                              </g>
                            </g>
                          </svg>
                        </Avatar>
                      </div>
                    </CardContent>
                  </div>
                  <div
                    style={{
                      marginRight: '30px'
                    }}>
                    <h5
                      style={{
                        marginLeft: '20px'
                      }}>
                      Port
                    </h5>

                    <ArrowUpwardIcon
                      style={{
                        color: 'green'
                      }}
                    />
                    {ports.activePort}
                    <ArrowDownwardIcon
                      style={{
                        color: 'red'
                      }}
                    />
                    {ports.inActivePort}
                  </div>
                </div>

                <Divider />
                <CardActions>
                  {localStorage
                    .getItem('role')
                    .split(',')[0]
                    .includes('fabric_readonly') === false ? (
                    <Button
                      size="small"
                      onClick={() => history.push('/fabricService/CreatePort')}>
                      Create Port
                    </Button>
                  ) : (
                    <Button
                      disabled
                      size="small"
                      onClick={() => history.push('fabricService/Services')}>
                      Create Port
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
            <Grid xs={12} sm={4} item container className={classes.cardGrid2}>
              <Card className={classes.root}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px'
                  }}>
                  <div className={classes.cont}>
                    <CardContent>
                      <div>
                        <Avatar
                          style={{
                            height: '50px',
                            width: '50px',
                            backgroundColor: 'white'
                          }}>
                          <svg
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect
                              width="48"
                              height="48"
                              fill="white"
                              fill-opacity="0.01"
                            />
                            <path
                              d="M36 8L13 8C10 8 4 10 4 16C4 22 10 24 13 24H35C38 24 44 26 44 32C44 38 38 40 35 40H12"
                              stroke="black"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M40 12C42.2091 12 44 10.2091 44 8C44 5.79086 42.2091 4 40 4C37.7909 4 36 5.79086 36 8C36 10.2091 37.7909 12 40 12Z"
                              fill="#2F88FF"
                              stroke="black"
                              stroke-width="4"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 44C10.2091 44 12 42.2091 12 40C12 37.7909 10.2091 36 8 36C5.79086 36 4 37.7909 4 40C4 42.2091 5.79086 44 8 44Z"
                              fill="#2F88FF"
                              stroke="black"
                              stroke-width="4"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Avatar>
                      </div>
                    </CardContent>
                  </div>
                  <div
                    style={{
                      marginRight: '30px'
                    }}>
                    <h5
                      style={{
                        marginLeft: '20px'
                      }}>
                      FXC
                    </h5>
                    <ArrowUpwardIcon
                      style={{
                        color: 'green'
                      }}
                    />
                    {vxc.activeCount}
                    <ArrowDownwardIcon
                      style={{
                        color: 'red'
                      }}
                    />
                    {vxc.inActiveCount}
                  </div>
                </div>

                <Divider />
                <CardActions>
                  {localStorage
                    .getItem('role')
                    .split(',')[0]
                    .includes('fabric_readonly') === false ? (
                    <Button size="small" onClick={() => handleConnection()}>
                      Create FXC
                    </Button>
                  ) : (
                    <Button
                      disabled
                      size="small"
                      onClick={() =>
                        history.push('fabricService/CreateConnection')
                      }>
                      Create FXC
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
            <Grid xs={12} sm={4} item container className={classes.cardGrid3}>
              <Card className={classes.root}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px'
                  }}>
                  <div className={classes.cont}>
                    <CardContent>
                      <div>
                        <Avatar
                          style={{
                            height: '50px',
                            width: '50px',
                            backgroundColor: 'white'
                          }}>
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="43.422px"
                            height="43.422px"
                            viewBox="0 0 43.422 43.422">
                            <g>
                              <path
                                d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093
		                         c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002
		                         c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591
		                         c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024
		                         c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128
		                         v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2
		                         V21.749h2.148v10.897h12.881v2L40.686,32.102z"
                              />
                            </g>
                          </svg>
                        </Avatar>
                      </div>
                    </CardContent>
                  </div>
                  <div
                    style={{
                      marginRight: '30px'
                    }}>
                    <h5
                      style={{
                        marginLeft: '20px'
                      }}>
                      FCR
                    </h5>

                    <ArrowUpwardIcon
                      style={{
                        color: 'green'
                      }}
                    />
                    {fcr.activeFcr}
                    <ArrowDownwardIcon
                      style={{
                        color: 'red'
                      }}
                    />
                    {fcr.inActiveFcr}
                  </div>
                </div>

                <Divider />
                <CardActions>
                  {localStorage
                    .getItem('role')
                    .split(',')[0]
                    .includes('fabric_readonly') === false ? (
                    <Button
                      size="small"
                      onClick={() => history.push('/fabricService/CreateFcr')}>
                      Create FCR
                    </Button>
                  ) : (
                    <Button
                      disabled
                      size="small"
                      onClick={() => history.push('/FXC/addConnections')}>
                      Create FCR
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {' '}
          <hr
            style={{
              marginTop: '20px',
              marginBottom: '10px'
            }}
          />
        </Grid>
        <Grid item justify="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 3em'
            }}>
            <div>
              <p
                style={{
                  color: '#5a1f2a',
                  fontWeight: '500',
                  fontFamily: 'Roboto'
                }}>
                Recent Activity
              </p>
            </div>
            <div>
              <TextField
                select
                value={logType}
                onChange={handleLogs}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                size="small">
                {logTypes.map(logType => (
                  <option key={logType.value} value={logType.value}>
                    {logType.name}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <Card className={classes.sub}>
            <div
              style={{
                marginLeft: '10px',
                height: '300px'
              }}>
              <TableContainer
                component={Paper}
                style={{
                  height: '300px'
                }}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader>
                  <TableHead
                    style={{
                      backgroundColor: '#5a1f2a'
                    }}>
                    <TableRow
                      style={{
                        backgroundColor: '#5a1f2a'
                      }}>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a',
                          paddingLeft: '10px'
                        }}>
                        User Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}>
                        Port Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="left">
                        Action Type
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="left">
                        Log Type
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="left">
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="left">
                        Time(UTC)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items?.map(item => (
                      <TableRow key={item.index} value={item._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {item.user.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {item.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {item.action}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {item.log_type}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {moment(item.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            width: '160px'
                          }}>
                          {moment(item.createdAt).format('h:mm:ss a')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
        </Grid>
        <hr
          style={{
            marginTop: '20px',
            marginBottom: '10px'
          }}
        />
        <Grid item container justify="center">
          {/* <Box> */}
            <Maps />
          {/* </Box> */}
        </Grid>
      </Grid>

    </>
  );
}
