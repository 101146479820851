import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import React from 'react';
import Popup from '../../Popup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
});

const DeletePortConfirmPopup = ({
  openPopup,
  setOpenPopup,
  handleDelete,
  selectedPortData
}) => {
  const classes = useStyles();

  return (
    <>
      <Popup
        title="Remove Configured Services"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Are you sure you want to remove <b> {selectedPortData?.name} </b>
            service from your configured services?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={() => setOpenPopup(false)}>
            No,Keep Configured Service
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={e => handleDelete(selectedPortData?._id, e)}>
            Yes,Remove Configuration
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default DeletePortConfirmPopup;
