import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import Grid from '@material-ui/core/Grid';
import Statcard from '../../../../../../components/Statcard';
import BarChartWrapper from '../../../../../../components/Charts/BarChartWrapper';
import cpu from '../../../../../../assets/icons/cpu.png';
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { GET_LIVENESS_VIDEOS } from '../../queries';

const getLast7Days = (X) => {
    var dates = [];
    for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString());
    }
    return dates;
}
const chartData = getLast7Days(-20).map(itm => {
    const count = parseInt(Math.random() * (30-1) + 1);
    return {date: itm, count}  
});

const DashboardPanel = ({
  t,
  subscriptionData
}) => {
    const [statCardData, setStatCardData] = useState({totalVideos: 0, trueVideos: 0, falseVideos: 1});

    const statList = [
        {
            label: "Number of Video",
            value: statCardData.totalVideos,
            icon: cpu,
            type: "total_video"
        },
        {
            label: "Number of True",
            value: statCardData.trueVideos,
            icon: cpu,
            type: "true_video"
        },
        {
            label: "Number of False",
            value: statCardData.falseVideos,
            icon: cpu,
            type: "false_video"
        }
    ];

    const [getLivenessVideos, {error}] = useLazyQuery(GET_LIVENESS_VIDEOS, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            if(res.getLivenessVideos){
                let trueVideos = 0; 
                let falseVideos = 0;
                res.getLivenessVideos.videos && res.getLivenessVideos.videos.map(itm => {
                    if(itm.is_live){
                        trueVideos = trueVideos+1;
                    }else{
                        falseVideos = falseVideos+1;
                    }
                });
                setStatCardData({totalVideos: res.getLivenessVideos.count ? res.getLivenessVideos.count : 0, trueVideos, falseVideos});
            }
        }
    });

    React.useEffect(() => {
        return getLivenessVideos({variables: {
            organization: subscriptionData.organizationId, 
            page: 1, 
            limit: 10000
        }});
    }, []);

    return (
        <>
            <Grid
            container
            justify={'space-between'}
            style={{marginTop: 45}}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                    />
                ))}
            </Grid>
            <ChartContainer
            customMargin={{marginBottom: 32, marginTop: 24}}
            title={subscriptionData.service ? subscriptionData.service.name + " Chart" : "Chart"} >
                <BarChartWrapper
                    data={chartData}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                />
            </ChartContainer>
        </>
    );
};

DashboardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default DashboardPanel;