import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { useEffect } from 'react';
import { USER_ROLES } from "../../../../../utils/constants";
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../utils';

const ProjectManagementItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName,
    handleClickViewAllDetailsAndAttachment,
    handleProjectAttachmentClick,
    handleSelectProjectStatus,
    handleSelectProjectCategory,
    handleEditTeamClick
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    const LINES_TO_SHOW = 2;
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.customer_name} / {data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.circuit_id}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {/* <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} styleOverrides={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            "-webkit-line-clamp": 2,
                            WebkitLineClamp: '2',
                            "-webkit-box-orient": "vertical"
                            // maxHeight: '3.6em',
                            // display: '-webkit-box',
                            // height: '2.6em',
                            // wordWrap: 'break-word',
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            // lineHeight: '1.3em',
                            // "-webkit-line-clamp": 2,
                            // "-webkit-box-orient":'vertical',
                        }}>
                            {data.ping_result}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell> */}
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.project_management}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <OutlinedSelect
                            label={`${t('project.label') + ' ' + t('status.label')}`}
                            handleChange={evt => {
                                handleSelectProjectStatus(data.project, evt.target.value);
                            }}
                            styleOverrides={{ width: '100%', marginBottom: 10 }}
                            options={onHoldList ? onHoldList : []}
                        />
                        <OutlinedSelect
                            label={`${t('osla.label') + ' ' + t('category.label')}`}
                            handleChange={evt => {
                                handleSelectProjectCategory(data.project, evt.target.value);
                            }}
                            styleOverrides={{ width: '100%', marginBottom: 10 }}
                            options={categoryList ? categoryList : []}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%', marginBottom: '10px' }}
                            onClick={() => {
                                handleClickViewAllDetailsAndAttachment(data);
                            }}>{t('ViewCompleteDetails.label')}</Button>
                        <Button variant={'outlined'}
                            disabled={data.previous_docs && data.previous_docs.length == 0 ? true : false}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleProjectAttachmentClick(data.previous_docs);
                            }}>{t('attachments.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    {(role.includes(USER_ROLES.PMO_ADMIN)) ?
                        <React.Fragment>
                            <OutlinedSelect
                                label={`${t('Select Action')}`}
                                val={data.is_available ? 'Sent' : ''}
                                disabled={false}
                                handleChange={evt => {
                                    handleMenuItemClick(evt.target.value, data);
                                }}
                                styleOverrides={{ width: '100%', marginBottom: '10px' }}
                                options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                            />
                            <Button variant={'outlined'}
                                disabled={false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleEditTeamClick(data);
                                }}>{t('edit.label')}</Button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <OutlinedSelect
                                label={`${t('Select Action')}`}
                                val={data.is_available ? 'Sent' : ''}
                                disabled={data.is_available || (data.project_management != CurrentUserName) ? true : false}
                                handleChange={evt => {
                                    handleMenuItemClick(evt.target.value, data);
                                }}
                                styleOverrides={{ width: '100%', marginBottom: '10px' }}
                                options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                            />
                            <Button variant={'outlined'}
                                disabled={false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleEditTeamClick(data);
                                }}>{t('edit.label')}</Button>
                        </React.Fragment>
                    }
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
ProjectManagementItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(ProjectManagementItem);