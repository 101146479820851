import React from 'react';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%",
    }
  });
  
  const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const ShowAlertFeedBack = ({
    t,
    open,
    onClose,
    data
}) => {
    const classes = useStyles();
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            FeedBack comment's
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                <Grid item xs={12}>
                <Typography variant="body1">User comments: {data?.wrong_alert_comment}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                <Grid item xs={12}>
                <Typography variant="body1">Approver comments: {data?.approval_comment}</Typography>
                </Grid>
            </Grid>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Grid>          
            </DialogActions>
        </DialogContent>
    </Dialog>
  )
}

export default ShowAlertFeedBack