import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useLazyQuery} from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ACTIVITY_LOGS_BY_CAMERA } from "../../queries";
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import { dateFormatterLLL } from '../../../../utils';
import AttchedCamera from '../../components/AttchedCamera';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { CircularProgress } from '@material-ui/core';
import CameraStatusPanel from '../CameraStatusPanel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { CAMERA_STATUS } from '../../../../utils/constants';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    },
    longTextStyle: {
        wordWrap: 'break-word',
        maxWidth: 1000,
    }
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


  const useStyles = makeStyles({
    root: { fontSize: '16px'},
    pre: { padding: '10px 30px', margin: '0' }
  });

  
const CameraPanelItem = ({
  t,
  data,
  handleMenuItemClick,
  menuItems,
  checkboxCameraRow,
  onHandleSelectCameraRow,
  allowConfigure,
  handleAddRoi,
  roiButtonLoading
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isActiviyLogEnable, setIsActiviyLogEnable] = useState(false);
    const [isCameraLoading, setIsCameraLoading] = useState(false);
    const [analogCameraData, setAnalogCameraData] = useState(null);
    const [isAttachedModalsEnable, setIsAttachedModalsEnable] = useState(false);

    const [getlogsByCameraID] = useLazyQuery(GET_ACTIVITY_LOGS_BY_CAMERA, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setAnalogCameraData(data && data.getAllActivityLogsByCamera);
            setIsCameraLoading(false);
        },
        onError: error => {
            console.log("error :", error);
            setIsCameraLoading(false);
        }
    });
  
    const handleClose = () => {
        setIsActiviyLogEnable(false);
    };

    const attachModalhandleClose = () => {
        setIsAttachedModalsEnable(false);
    };

  return (
      <>
    <TableRow key={data.camera_id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                {allowConfigure &&
                <Grid>
                    <Checkbox
                        disabled={data.is_deleted ? true : data.provider === 'uncanny' && (data.status === "provisioning" || data.status === "pending")}
                        checked={checkboxCameraRow[data.camera_id]} 
                        value={data.camera_id}
                        onChange={(event) => {
                            onHandleSelectCameraRow(event, data.camera_id);
                        }}
                    />
                </Grid>}
                <Grid>
                    <Icon
                        component={() => (
                            <CameraAltRoundedIcon
                                color={'secondary'}
                                style={{marginTop: '2px', marginRight: '6px'}}
                            />
                        )}
                        alt={'icon'}
                    />
                </Grid>
                <Grid>
                    <Typography variant={'subtitle1'} style={{ cursor : 'pointer'}} onClick={()=> {
                        if(!data.is_deleted && data.status !== CAMERA_STATUS.PANDING && data.status !== CAMERA_STATUS.PROV){
                            setIsActiviyLogEnable(true);
                            setIsCameraLoading(true);
                            return getlogsByCameraID({
                                variables: {
                                    cameraId: data.camera_id.toString()
                                }
                            });
                        }
                        return null;
                    }}>
                        {data.name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}> { data.place ? data.place.name : '-'}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.created_by_name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography 
                    variant={'subtitle1'}
                    style={{ cursor:  data.attachedModel && data.attachedModel !== 0 ? 'pointer' :'auto' }} 
                    onClick={()=> {
                        if(data.attachedModel && data.attachedModel !== 0){
                            setIsAttachedModalsEnable(true)
                        }
                       }}
                    >
                        {(data.attachedModel) ? data.attachedModel : 0}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    {data.is_deleted ? <div>
                        <Chip
                            size="small"
                            label={t("deleted.label")}
                            color="secondary"
                        />
                </div> : <CameraStatusPanel t={t} status={data.status}/>}
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            {!data.is_deleted && data.status !== CAMERA_STATUS.PANDING && data.status !== CAMERA_STATUS.PROV ?
                <Button onClick={evt => handleAddRoi(data)} color="primary" >{t("add.label")}</Button>
            : null}
        </TableCell>
        {allowConfigure && 
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        disabled={data.is_deleted}
                        onClick={evt => {
                            if(data.is_deleted) return null;
                            if(data.provider === 'uncanny' && (data.status === "provisioning" || data.status === "pending")){
                                setAnchorEl(null);
                                return null;
                            }
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    disabled={data.is_deleted ? data.is_deleted : false}
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={menuItems}
                    handleMenuItemClick={elem => {
                        if(data.is_deleted){
                            return null
                        }
                        if(data.provider === 'uncanny' && (data.status === "provisioning" || data.status === "pending")){
                            return null;
                        }
                        handleMenuItemClick(elem, data, 'camera');
                        setAnchorEl(null);
                    }}
                />}
            </TableCell>
        }
    </TableRow>
    <AttchedCamera
    t={t}
    rederDataType='modal'
    data={data}
    title={t('attachedModels.label')}
    isAttachedCameraEnable={isAttachedModalsEnable}
    handleClose={attachModalhandleClose}
    />
         <Dialog 
          fullWidth={true}
          maxWidth={'md'}
          open={isActiviyLogEnable}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t('activityLogs.label')}
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
                {
                    isCameraLoading ? 
                    <div style={{textAlign: "center"}}>
                        <CircularProgress size={25} />
                    </div>
                    : (
                        <>  
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>{t('cameraName.label')}</TableCell>
                            <TableCell>{t('action.label')}</TableCell>
                            <TableCell>{t('description.label')}</TableCell>
                            <TableCell>{t('createdAt.label')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {analogCameraData && analogCameraData.length > 0 && analogCameraData.map((row, indx) => (
                             <TableRow key={indx}>
                                 <TableCell>{row.cameraName ? row.cameraName : '-'}</TableCell>
                                 <TableCell>{row.actionType}</TableCell>
                                 <TableCell>{row.description}</TableCell>
                                 <TableCell>{dateFormatterLLL(row.createdAt)}</TableCell>
                             </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                        </>
                    )
                }
            </DialogContent>
          </Dialog>
          </>
  );
};

CameraPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired,
    onHandleSelectCameraRow: PropTypes.func,
    checkboxCameraRow: PropTypes.array,
    allowConfigure: PropTypes.bool
};

export default withTranslation()(CameraPanelItem);