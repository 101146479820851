// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_NOC_TEAM_CAMEROON_LIST } from "../../../../queries";
import { USER_ROLES } from "../../../../../../../utils/constants";
import Header from "../../../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FieldOperationsTeamItem from "../FieldOperationsTeamItem";
import { GET_USER_INFO } from '../../../../../../Profile/queries';
import CreateFieldOperationsTeamModal from "../CreateFieldOperationsTeamModal";
import { ADD_FIELD_OPERATIONS_TEAM_CAMEROON_SECTION, UPLOAD_FILES, CREATE_STORE_REQUEST_FORM } from "../../../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../../../commonQueries';
import ProjectAttachmentModal from "../../../ProjectAttachmentModal";
import CompleteDetailModal from "../../../CompleteDetailModal";
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CreateStoreRequestFormModal from "../CreateStoreRequestFormModal";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const FieldOperationsTeam = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [FieldOperationTeam, setFieldOperationTeam] = useState(false);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [allUserList, setAllUserList] = useState('');
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [StoreRequestFormUrl, setStoreRequestFormUrl] = useState('');
    const [StoreRequestFormModalState, setStoreRequestFormModalState] = useState({ open: false, btnLoading: false, clearData: false });

    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
        // }
        getAllUserList({
            variables: {
                role: USER_ROLES.NOC_TEAM
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&project_owner=is_admin"
                }
            })
            // } else if ((role.includes(USER_ROLES.INSTALL_TEAM))) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "im_team=" + CurrentUserName + "&page=1"
            //         }
            //     })
            // }
        }
    }, [refreshTab, CurrentUserName]);
    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            // console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            setAllUserList(parseData);
            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const [addFieldOperationTeam] = useMutation(ADD_FIELD_OPERATIONS_TEAM_CAMEROON_SECTION, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addFieldOperationTeam : ", data);
            if (JSON.parse(data.addFieldOperationsTeamCameroonSection.status) === true) {
                Message.success('New field operations team section created successfully');
                setModalState({ btnLoading: false, clearData: true });
                setFieldOperationTeam(false);
                setLoading(true);
                handleRefreshTab(true);
                getAllList({
                    variables: {
                        pageNumberOrUid: "page=1&project_owner=is_admin"
                    }
                })
                // let parseData = JSON.parse(data.addFieldOperationsTeamCameroonSection.teamData);
                // if (selectedProjectType === 'Regular_project') {
                //     handleChangeControlTeam();
                // }
            } else {
                let message = JSON.parse(data.addFieldOperationsTeamCameroonSection.message);
                Message.error(message);
            }
        }
    });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            // console.log('Error', error);
        },
        onCompleted: data => {
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // let full_name = data.userInfo.id;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            // if (role.includes(USER_ROLES.INSTALL_TEAM) && !checkRoleResult) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&im_team=" + full_name
            //         }
            //     })
            // }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "project_owner=is_admin&page=" + value + checkSearchFilterData
            }
        })
        // } else if ((role.includes(USER_ROLES.INSTALL_TEAM))) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "im_team=" + CurrentUserName + "&page=" + value
        //         }
        //     })
        // }
    };
    const [getAllList] = useLazyQuery(GET_NOC_TEAM_CAMEROON_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("service delivery data : ", data);
            if (data && data.getNOCTeamCameroonList) {
                let parseResult = JSON.parse(data.getNOCTeamCameroonList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error service:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('changeControlForm.label'),
            key: "site_name"
        },
        {
            label: t('fieldOperationTeam.label'),
            key: "install_team"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        // console.log("data in hancdle : ", data);
        let projectDetail = [
            { label: t('caseId.label'), value: (data.project_info && data.project_info.case_number ? data.project_info.case_number : 'N/A') },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: ((data.project_info && data.project_info.customer_name ? data.project_info.customer_name : 'N/A') + ' / ' + (data.project_info && data.project_info.organization_name ? data.project_info.organization_name : 'N/A')) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('changeControlForm.label'), value: data.upload_change_control_doc, button: true },
            { label: t('fieldOperationTeam.label'), value: data.field_operation_team, button: false },
        ]
        setPreValuesOfForm(data);
        setCompleteDetails({ ...completeDetails, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
        setFieldOperationTeam(true);
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: true });
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({...modalState, btnLoading: true });
        if (StoreRequestFormModalState.open === true) {
            setStoreRequestFormModalState({ ...StoreRequestFormModalState, btnLoading: true });
        }
        //  console.log("file in handle : ",file);
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleViewCompleteDetailClick = () => {
        setCompleteDetails({ ...completeDetails, open: true });
    }
    const handleClickViewAllDetailsAndAttachment = (data) => {
        let projectDetail = [
            { label: t('caseId.label'), value: (data.project_info && data.project_info.case_number ? data.project_info.case_number : 'N/A') },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: ((data.project_info && data.project_info.customer_name ? data.project_info.customer_name : 'N/A') + ' / ' + (data.project_info && data.project_info.organization_name ? data.project_info.organization_name : 'N/A')) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('changeControlForm.label'), value: data.upload_change_control_doc, button: true },
            { label: t('fieldOperationTeam.label'), value: data.field_operation_team, button: false },
        ]
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
    };
    const handleSubmitFieldOperationTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        addFieldOperationTeam({
            variables: {
                project_uid: formData.project_uid,
                store_request_form_url: formData.store_request_form_url,
                upload_installation_report: formData.upload_installation_report,
                upload_physical_signed_store_req_form: formData.upload_physical_signed_store_req_form,
                csoc_team: formData.csoc_team,
                next_team_member_email: formData.next_team_member_email,
                last_team_member_name: CurrentUserName,
            }
        })
    }
    const handleSaveStoreRequestForm = (formData) => {
        setStoreRequestFormModalState({ ...StoreRequestFormModalState, btnLoading: true });
        CreateStoreRequestForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [CreateStoreRequestForm] = useMutation(CREATE_STORE_REQUEST_FORM, {
        onError: error => {
            setStoreRequestFormModalState({ ...StoreRequestFormModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data StoreRequest : ", data);
            setStoreRequestFormModalState({ ...StoreRequestFormModalState, btnLoading: false });
            if (data && data.createStoreRequestForm) {
                if (JSON.parse(data.createStoreRequestForm.status) === true) {
                    let parseData = JSON.parse(data.createStoreRequestForm.teamData);
                    setStoreRequestFormUrl(parseData.link);
                    Message.success('Store request form created successfully');
                    setStoreRequestFormModalState({ ...StoreRequestFormModalState, open: false, clearData: true });
                } else {
                    let message = JSON.parse(data.createStoreRequestForm.message);
                    Message.error(message);
                }
            }
        }
    });
    return (
        <>
            <Header title={t('fieldOperationTeam.label')} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <FieldOperationsTeamItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName}
                                            handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleClickViewAllDetailsAndAttachment={handleClickViewAllDetailsAndAttachment} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateFieldOperationsTeamModal
                    handleUploadFiles={handleUploadFiles}
                    open={FieldOperationTeam}
                    handleSubmitClick={handleSubmitFieldOperationTeam}
                    t={t}
                    preValuesOfForm={preValuesOfForm}
                    CurrentUserName={CurrentUserName}
                    StoreRequestFormUrl={StoreRequestFormUrl}
                    handleViewCompleteDetailClick={handleViewCompleteDetailClick}
                    handleClose={() => {
                        setFieldOperationTeam(false);
                    }}
                    modalState={modalState}
                    handleOpenStoreRequestForm={() => {
                        setStoreRequestFormModalState({ ...StoreRequestFormModalState, open: true, clearData: true });
                    }}
                    allUserList={allUserList}
                    handleProjectAttachmentClick={handleProjectAttachmentClick} />
                <ProjectAttachmentModal
                    open={ProjectAttachment.open}
                    t={t}
                    handleClose={() => {
                        setProjectAttachment({ open: false, data: [] });
                    }}
                    attachmentList={ProjectAttachment.data}
                />
                <CompleteDetailModal
                    open={completeDetails.open}
                    t={t}
                    handleClose={() => {
                        setCompleteDetails({ ...completeDetails, open: false });
                    }}
                    data={completeDetails.data}
                    loading={completeDetails.loading}
                    previous_comment={completeDetails.previous_comment}
                    documents={completeDetails.documents}
                    projectDetail={completeDetails.projectDetail}
                />
                 <CreateStoreRequestFormModal
                    t={t}
                    open={StoreRequestFormModalState.open}
                    handleSubmitClick={handleSaveStoreRequestForm}
                    handleClose={() => {
                        setStoreRequestFormModalState({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={StoreRequestFormModalState}
                    preValuesOfForm={preValuesOfForm}
                    handleUploadFiles={handleUploadFiles}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(FieldOperationsTeam);
