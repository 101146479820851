import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useLazyQuery} from '@apollo/react-hooks';
import {withTranslation} from 'react-i18next';
import {GET_VNF_MARKET_PLACE, GET_VNF_MARKET_PLACES_BY_ID} from './queries';
import {ADD_VNF_MARKET_PLACE, DELETE_VNF_MARKETPLACE, UPDATE_VNF_MARKET_PLACE} from './mutations';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import {InternalPaths} from '../../../routes/routePaths';
import Grid from '@material-ui/core/Grid';
import Message from '../../../components/Message';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import CreateVnfMarketPlaceModal from "./components/CreateVnfMarketPlaceModal";
import {USER_ROLES} from "../../../utils/constants";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import MenuWrapper from "../../../components/MenuWrapper";
import commonStyles from "../../../theme/commonStyles";
import CustomTabs from "../../../components/CustomTabs";
import CategoryList from "./components/Categorylist";
import {GET_ALL_DEVICE_INTERFACES} from "../../Subscriptions/queries";
import MarketPlaceItem from "./components/MarketPlaceItem";

const breadcrumbList = [
    {label: 'home.label', link: InternalPaths.Dashboard},
    {label: 'vnfMarketplace.label', link: InternalPaths.vnfMarketPlace}
];

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    listView: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listItem: {},
    image: {
        width: "225px",
        marginRight: "15px",
        marginTop: "15px"
    },
    title: {
        fontSize: "20px",
        lineHeight: "26px",
        fontWeight: 600,
        color: "#008bcc",
        marginBottom: "10px"
    },
    inline: {
        display: 'inline',
        lineHeight: "26px",
        marginBottom: "5px"
    },
    description: {
        display: "flex",
        alignItems: "center",
        lineHeight: "20px",
        marginBottom: "5px"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        maxWidth: "100%",
    },
    autoComplete: {
        maxWidth: "100%",
        borderRadius: '8px',
        margin: "20px 10px 10px",
        border: '1px solid #d1d1d1',
        '& label ': {
            transform: 'translate(14px, 20px) scale(1)'
        },
        '& .popupIndicator': {
            marginTop: "-12px"
        }
    },
}));
const VnfMarketPlace = ({t}) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const history = useHistory();
    const role = localStorage.getItem('role');
    const [vnfMarketPlaceModalState, setMarketPlaceModalState] = useState(false);
    const [isEdit, setIsEditState] = useState(false);
    const [isMarketPlaceID, setMarketPlaceIDState] = useState('');
    const [modalState, setModalState] = useState({btnLoading: false, clearData: false});
    const [updateData, setUpdateData] = useState(null);
    const [value, setValue] = React.useState(2);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuCustomItems = [{label: t('delete.label')}, {label: t('edit.label')}];
    const [options, setOptions] = React.useState([]);
    const loading1 = open && options.length === 0;
    const handleCreateButtonClick = searchParam => {
        setMarketPlaceModalState(true);
    };
    const {data, loading, error, refetch: reloadVnfMarketPlace} = useQuery(GET_VNF_MARKET_PLACE);
    React.useEffect(() => {
        let active = true;
        if (!loading1) {
            return undefined;
        }
        (async () => {
            const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
            await sleep(1e3); // For demo purposes.
            const countries = await response.json();
            if (active) {
                setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
            }
        })();
        return () => {
            active = false;
        };
    }, [loading1]);
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    const [addVnfMarketPlaceData] = useMutation(ADD_VNF_MARKET_PLACE, {
        onError: error => {
            console.log('Error', error);
            setModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            setModalState({btnLoading: false, clearData: true});
            Message.success(`Vnf MarketPlace created successfully`);
            reloadVnfMarketPlace();
        }
    });
    const [editVnfMarketPlaceData] = useMutation(UPDATE_VNF_MARKET_PLACE, {
        onError: error => {
            console.log('Error', error);
            setModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            setModalState({btnLoading: false, clearData: true});
            setMarketPlaceIDState('')
            setMarketPlaceModalState(false)
            Message.success(`Vnf MarketPlace updated successfully`);
            reloadVnfMarketPlace();
        }
    });
    const handleClose = () => {
        setMarketPlaceModalState(false);
    }
    const handleSubmitClick = (formData) => {
        setModalState({btnLoading: true, clearData: false});
        return addVnfMarketPlaceData({variables: formData});
    }
    const handleEditVnfMarketPlace = (formData) => {
        setModalState({btnLoading: true, clearData: false});
        formData.isMarketPlaceID = isMarketPlaceID;
        return editVnfMarketPlaceData({variables: formData});
    }

    const [deleteMarketPlace] = useMutation(DELETE_VNF_MARKETPLACE, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Vnf Marketplace deleted successfully');
            reloadVnfMarketPlace();
        }
    });
    const [getMarketPlaceDetails] = useLazyQuery(GET_VNF_MARKET_PLACES_BY_ID, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setUpdateData(data)
        }
    });
    const handleMenuItemClick = (elem, id, type) => {
        if (elem.label === t('edit.label')) {
            setMarketPlaceIDState(id)
            getMarketPlaceDetails({
                variables: {
                    vnfMarketPlaceId: id
                }
            })
            setMarketPlaceModalState(true);
            setIsEditState(true);
        } else if (elem.label === t('delete.label')) {
            if (
                window.confirm('Are you sure you want to delete this vnf marketplace ?') ===
                false
            ) {
                return false;
            }
            return deleteMarketPlace({
                variables: {
                    market_place_id: id
                }
            });
        }
    }
  
    return (
        <Layout breadcrumbList={breadcrumbList}>                  
            <CustomTabs
                tabs={[
                    {
                        label: t('allCategory.label'),
                        children: (
                            <CategoryList t={t}/>
                        )
                    },
                    {
                        label: t('allVnfMarketplace.label'),
                        children: (
                            <>
                                {(role.includes(USER_ROLES.IP_VNFADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) &&
                                <Header
                                    title={t('vnfMarketplace.label')}
                                    subText={`${t('vnfMarketplace.label')}`}
                                    primaryButtonText={t('createNew.label')}
                                    handlePrimaryButtonClick={handleCreateButtonClick}
                                />}
                                <Grid container>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            {/*<Autocomplete
                                                id="asynchronous-demo"
                                                open={open}
                                                className={classes.autoComplete}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                getOptionLabel={(option) => option.name}
                                                options={options}
                                                loading={loading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Search for any image via VNF marketplace"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit"
                                                                                                 size={20}/> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />*/}
                                            <List className={classes.listView}>
                                                {!loading && !error ?
                                                    data.getVnfMarketPlaces.length ?
                                                        data.getVnfMarketPlaces.map((item, index) => (
                                                            <>
                                                                <MarketPlaceItem
                                                                    key={index}
                                                                    item={item}
                                                                    t={t}
                                                                    classes={classes}
                                                                    menuItems={menuCustomItems}
                                                                    handleMenuItemClick={handleMenuItemClick}
                                                                />
                                                            </>
                                                        )) :
                                                        <Typography variant={'subtitle2'}
                                                                    style={{textAlign: 'center', fontSize: '25px'}}>Record
                                                            not found!</Typography> : null}
                                                {loading &&
                                                <Typography variant={'subtitle2'}>Loading...</Typography>
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                ]}
            />
            <CreateVnfMarketPlaceModal VnfMarketPlace={updateData} open={vnfMarketPlaceModalState} isEdit={isEdit}
                                       handleCreateVnfMarketPlace={handleSubmitClick}
                                       handleEditVnfMarketPlace={handleEditVnfMarketPlace} t={t}
                                       handleClose={handleClose} modalState={modalState}/>
      </Layout>
    );
};
export default withTranslation()(VnfMarketPlace);
