import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Statcard from '../../../../../../components/Statcard';
import SalesTrackerDashBoardMapAndDetails from './SalesTrackerDashBoardMapAndDetails';
import SalesTrackerDashboardBarChart from './SalesTrackerDashboardBarChart';

const SalesTrackerDashboard = ({
  t,
  loadingSalesDashboardMap,
  distributorsData,
  salesDashboardData,
  locationsForSalesDashBoard,
  handleFilterForSalesDashboardMap,
  getSalesDashBoardMapDetails
}) => {
  return (
    <>
      <Grid container style={{ marginTop: 35, marginBottom: 20 }}>
        <Grid item xs="12">
          <SalesTrackerDashBoardMapAndDetails
            t={t}
            loadingSalesDashboardMap={loadingSalesDashboardMap}
            distributorsData={distributorsData}
            locationsForSalesDashBoard={locationsForSalesDashBoard}
            handleFilterForSalesDashboardMap={handleFilterForSalesDashboardMap}
            getSalesDashBoardMapDetails={getSalesDashBoardMapDetails}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 30, marginBottom: '20px' }}>
        {salesDashboardData && (
          <Grid item xs="3">
            <Statcard
              statLabel={`${t('numberOf.label')} Distributors`}
              statValue={salesDashboardData?.distributors_count}
            />
          </Grid>
        )}
        {salesDashboardData && (
          <Grid item xs="3">
            <Statcard
              statLabel={`${t('numberOf.label')} Users`}
              statValue={salesDashboardData?.users_count}
            />
          </Grid>
        )}
        {salesDashboardData && (
          <Grid item xs="3">
            <Statcard
              statLabel={`${t('totalProductsSoldToday.label')}`}
              statValue={salesDashboardData?.total_products_sold_today}
            />
          </Grid>
        )}
        {salesDashboardData && (
          <Grid item xs="3">
            <Statcard
              statLabel={`${t('topSellingProductName.label')}`}
              statValue={salesDashboardData?.top_selling_product}
            />
          </Grid>
        )}
      </Grid>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xs="12">
          <Typography variant={'h4'} gutterBottom>
            Distributors sales record
          </Typography>
          <SalesTrackerDashboardBarChart distributorsData={distributorsData} />
        </Grid>
      </Grid>
    </>
  );
};

export default SalesTrackerDashboard;
