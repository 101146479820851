import React from 'react';
import { Input } from '@material-ui/core';

const EidtKeyValuePair = ({
  setOcrJsonValue,
  key,
  value,
  action,
  ocrJsonValue
}) => {
  const handlerForkeyPairs = (key, value) => {
    setOcrJsonValue(() => {
      const ocrValue = ocrJsonValue;
      ocrValue[key] = value;
      return ocrValue;
    });
  };
  return (
    <>
      {/* <span>{key ? `${key}:` : ''}</span> */}
      {action === 'edit' ? (
        <Input
          type="text"
          value={value}
          color={'secondary'}
          onBlur={e => handlerForkeyPairs(key, e.target.value)}
        />
      ) : (
        <span>{value !== '' ? value : '-'}</span>
      )}
    </>
  );
};

export default EidtKeyValuePair;
