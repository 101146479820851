import React from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';

import SearchBox from '../../../../../../components/SearchBox';
import {marginGenerator} from '../../../../../../theme/utils';
import ParseRulesItems from './ParseRulesItems';

const ParseRulesListings = ({
    t,
     ocrRuleFetchingloading,
     ocrRuleData,
     createRuleHandle,
     handleSearch,
    handleSubmitOfDeleteOcrRule,
    ocrRuleDeletetingStarted,
    selecteIdForDelete,
    handleSearchOfOcrRule,
    open,
    onClose,
    ocrRuleSubmitLoading,
    editRuleHandle,
    handleEditRuleFormClose,
    handleSubmitEditOfOcrRule,
    page,
    handlePageChange,
    dataForUpdateRuleModal,
    organizationId
}) => {

    let columns = [
        {
            label: 'Rule Name',
            key: "name"
        },
        {
            label: t('createdDate.label'),
            key: "date"
        },
        {
            label: t('createdBy.label'),
            key: "by"
        },
        {
            label: t('description.label'),
            key: "description"
        },
        {
            label: t('action.label'),
            key: "action"
        }
    ];

    const handleSearchChange = (val) => handleSearchOfOcrRule(val);

    return (
        <>  
            <Grid container style={{...marginGenerator("mb-20")}}>
                <Grid item xs="2">
                    <SearchBox type="wide" placeholderText="Search RuleName" handleSearch={handleSearchChange} />
                </Grid>
                <Grid item xs="8">
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={createRuleHandle}
                            style={{...marginGenerator('ml-10'), float:"right"}}
                        >
                            {t('createARule.label')}
                        </Button>
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ocrRuleFetchingloading ?
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'} style={{textAlign: "center"}}><CircularProgress size={25}/></Typography>
                                    </TableCell>
                                </TableRow>
                            :
                                <>
                                    {ocrRuleData && ocrRuleData.data.length > 0 ? ocrRuleData.data.map((rowitem, rowIndex) => (
                                        <ParseRulesItems
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            handleSubmitOfDeleteOcrRule={handleSubmitOfDeleteOcrRule}
                                            ocrRuleDeletetingStarted={ocrRuleDeletetingStarted}
                                            selecteIdForDelete={selecteIdForDelete}
                                            open={open}
                                            onClose={handleEditRuleFormClose}
                                            handleSubmitEditOfOcrRule={handleSubmitEditOfOcrRule}
                                            ocrRuleSubmitLoading={ocrRuleSubmitLoading}
                                            editRuleHandle={editRuleHandle}
                                            allocrRuleData={ocrRuleData.data}
                                            dataForUpdateRuleModal={dataForUpdateRuleModal}
                                            organizationId={organizationId}
                                        />
                                    )) :
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                    { !ocrRuleFetchingloading &&
                    <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                    <Grid container item xs={2}>
                    <span>Total {Math.ceil(get(ocrRuleData, 'count', null)/20)} page,  {get(ocrRuleData, 'count', null)} Row</span>
                    </Grid>
                    <Grid container item xs={2}>
                        <Pagination 
                            color="secondary"
                            count={ocrRuleData.count ? Math.ceil(ocrRuleData.count/20) : 0}
                            page={page} 
                            onChange={(evt, value) => {
                                handlePageChange("ocr_rule", value);
                            }}
                        />
                    </Grid>
                  </Grid>
                  </>
                    }
                </TableContainer>
            </Grid>   
        </>
    )
}

export default ParseRulesListings
