import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateConnectivityInfoModal({
                                         t,
                                         open,
                                         handleSaveConnectivityInfo,
                                         handleClose,
                                         data,
                                         modalState
                                     }) {
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} Connectivity Info
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will add connectivity information it to Bofinet.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        vLanId: "",
                        port: "",
                        ipAddress: ""
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        console.log('values', JSON.stringify(values))
                        handleSaveConnectivityInfo(values)
                        resetForm()
                        //alert(JSON.stringify(values));
                    }}
                    validationSchema={Yup.object().shape({
                        vLanId: Yup.string()
                            .max(100, 'vLanId must be 100 character!')
                            .required('Please Enter vLanId'),
                        port: Yup.string()
                            .required('Please enter port Number'),
                        ipAddress: Yup.string()
                            .required('Please enter Ip Address')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.vLanId}
                                                    name={'vLanId'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.vLanId && touched.vLanId) && errors.vLanId}
                                                    placeholder={`${t('vLanId.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.port}
                                                    name={'port'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.port && touched.port) && errors.port}
                                                    placeholder={`${t('port.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.ipAddress}
                                                    name={'ipAddress'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.ipAddress && touched.ipAddress) && errors.ipAddress}
                                                    placeholder={`${t('ipAddress.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
CreateConnectivityInfoModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateConnectivityInfoModal);
