import React, {useState, useEffect} from 'react';
import CustomTabs from '../../../../components/CustomTabs';
import PropTypes from 'prop-types';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import {GET_DEVICE_REPORT, GET_UCPE_LIST} from '../../queries';
import UCPEReportsPanel from '../UCPEReportsPanel';
import {paddingGenerator} from '../../../../theme/utils';
import moment from "moment";

const SDNReportPanel = ({t, data}) => {
    const ucpes = [];
    const organization_id = data.organizationId
    const [firstLoad, setFirstLoad] = useState(true);
    const {loading: loadingUcpes, data: ucpeData, error: ucpeError} = useQuery(GET_UCPE_LIST, {
        variables: {
            organization_id,
            search: ''
        }
    });
    const [activeIndex, setActiveIndex] = useState(0);
    const [
        reportDeviceFilterState,
        setReportDeviceFilterState
    ] = useState('');
    const [startDate, setStartDateState] = useState('');
    const [endDate, setEndDateState] = useState('');
    const [ReportFilteredData, setReportFilterData] = useState([]);
    const [deviceFilterLoading, setDeviceFilterLoading] = useState(
        false
    );
    const [getReportListByFilter] = useLazyQuery(GET_DEVICE_REPORT, {
        onError: error => {
            setDeviceFilterLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            setDeviceFilterLoading(false);
            if (data.getDeviceReportList && data.getDeviceReportList.deviceReport)
                setReportFilterData(data.getDeviceReportList && data.getDeviceReportList.deviceReport ? JSON.parse(data.getDeviceReportList && data.getDeviceReportList.deviceReport) : []);
        }
    });
    const handleReportFilterChange = (value, type) => {
        setDeviceFilterLoading(true);
        let filterBy = {
            variables: {
                organization: data.organizationId
            }
        };
        let deviceId = reportDeviceFilterState ? reportDeviceFilterState : '';
        let startD = startDate ? startDate : '';
        let endD = endDate ? endDate : '';
        if (type === 'ucpe') {
            deviceId = value ? value : '';
        }
        if (type === 'startDate') {
            startD = value ? value : '';
        }
        if (type === 'endDate') {
            endD = value ? value : '';
        }
        if (deviceId) {
            filterBy.variables.device_id = deviceId;
        }
        if (startD) {
            filterBy.variables.start_date = moment(startD).format('DD/MM/YYYY HH:MM:SS');
        }
        if (endD) {
            filterBy.variables.end_date = moment(endD).format('DD/MM/YYYY HH:MM:SS');
        }
        getReportListByFilter(filterBy);
    };
    if (!loadingUcpes && !ucpeError && ucpeData.getUcpes && ucpeData.getUcpes.length) {
        ucpeData.getUcpes.filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
            .map(ucpe => {
                ucpes.push({
                    id: ucpe.device_id,
                    name: ucpe.device_name
                });
            });
    }
    const deviceReportFilterList = [
        {
            label: t('uCPE.label'),
            handleChange: evt => {
                setReportDeviceFilterState(evt.target.value);
                handleReportFilterChange(evt.target.value, 'ucpe');
            },
            val: reportDeviceFilterState,
            styleOverrides: {minWidth: '172px'},
            options: ucpes.length > 0 ? ucpes : []
        },
        {
            label: t('startDate.label'),
            handleChange: evt => {
                setStartDateState(evt.target.value);
                let dateTime = evt.target.value
                    ? new Date(evt.target.value).getTime() / 1000
                    : '';
                handleReportFilterChange(dateTime, 'startDate');
            },
            val: startDate,
            name: 'startDate',
            styleOverrides: {
                ...paddingGenerator('pt-10'),
                ...paddingGenerator('pb-7')
            },
            type: 'input'
        },
        {
            label: t('endDate.label'),
            handleChange: evt => {
                setEndDateState(evt.target.value);
                let dateTime = evt.target.value
                    ? new Date(evt.target.value).getTime() / 1000
                    : '';
                handleReportFilterChange(dateTime, 'endDate');
            },
            val: endDate,
            name: 'endDate',
            styleOverrides: {
                ...paddingGenerator('pt-10'),
                ...paddingGenerator('pb-7')
            },
            type: 'input'
        }
    ];
    if (ucpes.length > 0 && firstLoad) {
        setFirstLoad(false);
        setReportDeviceFilterState(ucpes[0]['id']);
        let now = new Date();
        let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
        defaultDate = new Date(defaultDate);
        setStartDateState(defaultDate.toISOString().substring(0, 16));
        setEndDateState(new Date().toISOString().substring(0, 16));
    }
    React.useEffect(() => {
        setDeviceFilterLoading(true);
        let filterBy = {
            variables: {
                organization: data.organizationId,
                device_id: reportDeviceFilterState,
                start_date: moment(startDate).format('DD/MM/YYYY HH:MM:SS'),
                end_date: moment(endDate).format('DD/MM/YYYY HH:MM:SS')
            }
        };
        if (reportDeviceFilterState && startDate && endDate) getReportListByFilter(filterBy);
    }, [reportDeviceFilterState, startDate, endDate, getReportListByFilter]);
    return (
        <div>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={[
                    {
                        label: t('uCPE.label'),
                        children: (
                            <>
                                <UCPEReportsPanel
                                    t={t}
                                    list={ReportFilteredData}
                                    filterList={deviceReportFilterList}
                                    loading={''}
                                    error={''}
                                    filterLoading={deviceFilterLoading}
                                />
                            </>
                        )
                    }
                ]}
            />
        </div>
    );
};
SDNReportPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};
export default SDNReportPanel;
