import React, {useState, useCallback, useRef, useEffect} from 'react';
//import * as pdfjsLib from 'pdfjs-dist/build/pdf'
//import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { get } from "lodash-es";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Tesseract from "tesseract.js";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            {/* <CloseIcon /> */}
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabled, disabledValue } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading || disabledValue}
      >
          {loading && <CircularProgress size={14} />}
          {!loading && t('submit.label')}
      </Button>
  );
}

var PDFJS = window.pdfjsLib;

const OcrDocUploadForm = ({
    t,
    open,
    onClose,
    ocrSubmitLoading,
    handleSubmitOfOcrParse,
    handleFilterdataForOptions,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    loadingOcrFolder,
    isDataExists=null,
}) => {

    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [files, setFiles] = useState(null);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%',height: 20, width: 30, aspect: null });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [image, setImage] = React.useState("");
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [ocrImg, setOcrImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ocrtext, setOcrtext] = useState("");
    const [isOcrTextScreeanEnable, setIsOcrTextScreeanEnable] = useState(false);
    const [isParseHide, setIsParseHide] = useState(false);
    const [locationValue, setLocationValue] = useState('');
    const [projectValue, setProjectValue] = useState('');
    const [folderalue, setFolderalue] = useState('');
    const [cropImgValueForApi, setCropImgValueForApi] = useState('');

    const { TesseractWorker } = Tesseract;
    const worker = new TesseractWorker();


      function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

      const onSelectFile = async (e) => {
        try {
          const isFileExist = e.target.files;
          if (isFileExist && isFileExist.length > 0) {
            setFiles(isFileExist[0]);
            if(isFileExist[0].type === 'application/pdf'){
              const uri = URL.createObjectURL(e.target.files[0]);
              var pdf = await PDFJS.getDocument({ url: uri });
              var page = await pdf.getPage(currentPage);
    
              var viewport = page.getViewport(currentPage);
          
              var render_context = {
                canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
                viewport: viewport
              };
              setWidth(viewport.width);
              setHeight(viewport.height);
              await page.render(render_context);
    
              var canvas = document.getElementById("pdf-canvas");
              var img = canvas.toDataURL("image/png");
              const base64Data = img;
              const base64 = await fetch(base64Data);
          
              const base64Response = await fetch(base64Data);
              const blob = await base64Response.blob();
              const reader = new FileReader();
              reader.addEventListener("load", () => setUpImg(reader.result));
              reader.readAsDataURL(blob);
              setImage(img);
            }else if (isFileExist[0].type === 'image/jpeg' || isFileExist[0].type === 'image/png'){
              const reader = new FileReader();
              reader.addEventListener('load', () => setUpImg(reader.result));
              reader.readAsDataURL(isFileExist[0]);
            } else if(isFileExist[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                      || isFileExist[0].type === 'text/csv'
                      || isFileExist[0].type === 'application/vnd.ms-excel'){
                    setIsOcrTextScreeanEnable(true);   
            }
          } 
        } catch (error) {
          console.log('error', error);
        }
      };
    
      const onLoad = useCallback((img) => {
        imgRef.current = img;
      }, []);
    
      useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
          return;
        }
        const corpValus = {};
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        
        corpValus.x = crop.x;
        corpValus.y = crop.y;
        corpValus.width = crop.width;
        corpValus.height = crop.height;
        corpValus.scale_x = scaleX;
        corpValus.scale_y = scaleY;
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
        setCropImgValueForApi(corpValus);
        cropImage(
          canvas,
          'cropImg'
        ).then(res =>{
          setOcrImg(res[1]);
        })
         .catch(err => console.log('err', err))
        
      }, [completedCrop]);

      const cropImage = (canvas,file) => {
        return Promise.all([
          new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (blob) {
                blob.name = file;
                blob.lastModified = file.lastModified;
                resolve(blob);
              } else {
                reject(new Error("Canvas is empty"));
              }
            }, file.type); //"image/jpeg");
          }),
          canvas.toDataURL("image/jpeg")
        ]);
      };

      const handleSubmitOcr = () => {
        setIsOcrTextScreeanEnable(true);
        // worker
        //   .recognize(ocrImg, "enm", {
        //     tessedit_create_hocr: 0,
        //     tessedit_create_tsv: 0
        //   })
        //   .progress(function (p) {
        //     console.log("progress", p);
        //     setLoading(true);
        //   })
        //   .then((result) => {
        //     console.log("result is: ", result);
        //     setLoading(false);
        //     setOcrtext(result.text);
        //     worker.terminate();
        //   })
        //   .catch((err) => console.log("err", err));
        handleSubmitOfOcrParse(files,ocrtext, ocrImg,{
          rule: {
            x: cropImgValueForApi.x,
            y: cropImgValueForApi.y,
            height: cropImgValueForApi.height,
            width: cropImgValueForApi.width,
            scale_x:cropImgValueForApi.scale_x,
            scale_y:cropImgValueForApi.scale_y,
          }},locationValue || isDataExists?.parents[[isDataExists.parents.length - 1]],
         projectValue ||  isDataExists?.parents[[isDataExists.parents.length - 2]],
         folderalue || get(isDataExists, 'item.id', null));
      };

      const handlBackToUploadScreen = () => (
        setIsOcrTextScreeanEnable(false)
      );

      const contentOfOcrScreen = () => {
        let content = '';
        const styling = {
          marginLeft: 350
        }
         if(ocrSubmitLoading){
         content = <p style={styling}>pls wait we are saving your text</p>
        } 
        // this code req in future
        // if(loading){
        //   content = <p style={styling}>pls wait the parse text is loading </p>
        // } else if(ocrSubmitLoading){
        //   content = <p style={styling}>pls wait we are saving your text</p>
        // } 
        // else{
        //   content = <pre style={styling}>{ocrtext}</pre>
        // }
        return content;
      };

      useEffect(() => {
        setOcrImg(null);
        setUpImg();
        setUpImg(null);
        setIsOcrTextScreeanEnable(false);
        setFiles(null);
        setIsParseHide(false);  
        setLocationValue('');
        setProjectValue('');
        setFolderalue('');
        handleFilterdataForOptions(null, 'null');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [!open]);
      
    return (
        <>
        <Dialog 
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                 <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                          Upload OCR Doc
                          <div>
                          <OutlinedSelect
                              val={isDataExists?.parents?.length ? isDataExists?.parents[isDataExists.parents.length - 1] : projectValue}
                              // val={get(isDataExists, 'parents.[1]', null) ? get(isDataExists, 'parents.[1]', null):locationValue}
                              label="Select Loaction"
                              handleChange={evt => {
                                  handleFilterdataForOptions(evt.target.value, 'project');
                                  setLocationValue(evt.target.value);
                              }}
                              selectStyle={{...paddingGenerator('p-8')}}
                              styleOverrides={{ width: '100%'}}
                              options={(!loadingOcrPlace && ocrPlaceData) ? ocrPlaceData.data : []}
                              disabled={get(isDataExists, 'parents.[1]', null)}
                          />
                          </div>
                          <div>
                          <OutlinedSelect
                              // val={get(isDataExists, 'parents.[0]', null) ? get(isDataExists, 'parents.[0]', null):projectValue}
                              val={isDataExists?.parents?.length ? isDataExists?.parents[isDataExists.parents.length - 2] : projectValue}
                              label="Select Project"
                              handleChange={evt => {
                                  handleFilterdataForOptions({place: locationValue,project:evt.target.value}, 'folder');
                                  setProjectValue(evt.target.value);
                              }}
                              selectStyle={{...paddingGenerator('p-8')}}
                              styleOverrides={{ width: '100%' }}
                              options={(!loadingOcrProject && ocrProjectData) ? ocrProjectData.data : []}
                              disabled={get(isDataExists, 'parents.[0]', null)}
                          />
                          </div>
                          <div>
                          <OutlinedSelect
                              val={get(isDataExists, 'item.id', null) ? get(isDataExists, 'item.id', null):folderalue}
                              label="Select Folder"
                              handleChange={evt => {
                                setFolderalue(evt.target.value);
                              }}
                              selectStyle={{...paddingGenerator('p-8')}}
                              styleOverrides={{ width: '100%' }}
                              options={(!loadingOcrFolder && ocrFolderData) ? ocrFolderData.data : []}
                              disabled={get(isDataExists, 'item.id', null)}
                          />
                          </div>
                    <Tooltip title={<p style={{ fontSize: 16 }}>If you hide parse then full image gonna parse not selected area</p>}>
                    <Button 
                      variant={'outlined'}
                      color="primary"
                      onClick={() => setIsParseHide(!isParseHide)}
                      >
                       {!isParseHide? "Hide": 'show'} parse   
                    </Button>
                    </Tooltip>
                 </div>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
                 {
                   isOcrTextScreeanEnable ? 
                   <>
                    <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={handlBackToUploadScreen}
                    >
                      {t('back.label')}
                    </Button>
                   {contentOfOcrScreen()}
                   </>
                   
                   :(
                     <>   
                          <input 
                          accept="application/pdf,image/x-png,image/jpeg"
                          onChange={onSelectFile} 
                          type="file"
                          />
                            {
                            !isParseHide ? (
                              <>
                                  <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                              />
                            <div id="pdf-main-container" 
                            style={{width: 400,
                                    margin: '20px auto'
                                    }}>
                              <div id="pdf-contents">
                              <div id="image-convas-row" style={{
                                display: 'flex',
                                margin: '20px 0',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                              }}>
                              <canvas ref={previewCanvasRef} id="pdf-canvas" width={width} height={height} />

                              </div>
                              </div>
                            </div>
                            </>
                            ): (
                              <img  src={upImg} style={{ height: 620, width: 330 }}/>
                            )
                          } 
                     </>
                   )
                 }
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={onClose}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                      t={t}
                      loading={loading||ocrSubmitLoading}
                      disabledValue={!ocrImg}
                      onClick={() => {
                       //isOcrTextScreeanEnable ? handleSubmitOfOcrParse(files,ocrtext, ocrImg) : handleSubmitOcr()
                        handleSubmitOcr();
                      }}
                    />
                </Grid>          
            </DialogActions>
        </Dialog>
        </> 
    )
}

export default OcrDocUploadForm
