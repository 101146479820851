import React, {useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Message from '../../../components/Message';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../utils/constants';
import { checkValidEmail } from '../../../../utils';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
});

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function InviteMemberModal({
    t,
    open,
    data,
    handleInviteSubmitClick,
    handleClose,
    modalState
}) {

    const initFilels = {userName:'', email:'', role: ''};
    const [inviteMemberState, setInviteMemberState] = useState([{ ...initFilels }])
    const roleList = ['eadmin', 'finance', 'user', 'it'];
    
    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, memberdata, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {!memberdata && 
                <Button
                className={classes.closeButton}
                variant={'outlined'}
                color={'primary'}
                onClick={() => {
                    setInviteMemberState(oldArray => [...oldArray, {...initFilels}]);
                }}>
                    {t('addNew.label')}
                </Button>
            }
            </MuiDialogTitle>
        );
    });
    
    React.useEffect(() => {
        if(modalState.clearData && open){
            setInviteMemberState([{ ...initFilels }]);
            handleClose();
        }
    }, [modalState]);

    useEffect(() => {
        if(data){
            let name = data.firstName;
            if(data.lastName){
                name += ' '+data.lastName; 
            }
            let role = '';
            if(data.roles){
                role = data.roles.split(', ')[0];
            }
            setInviteMemberState([{id: data.id, userName: name, email: data.email, role: role}]);
        }else{
            setInviteMemberState([{...initFilels}]);
        }
    }, [data]);

    const handleInputChange = (value, idx, column) => {
		const updatedState = [...inviteMemberState];
		updatedState[idx][column] = value;
		setInviteMemberState(updatedState);
    }
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="invite-template-modal"
            aria-describedby="invite-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="customized-dialog-title" memberdata={data} onClose={handleClose}>{data ? t('edit.label') : t('inviteNew.label')} {t('member.label')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                {inviteMemberState.map((val, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            {!data && 
                                <Grid key={`heading-${idx}`} item xs={12} style={{...paddingGenerator('pl-5'), ...paddingGenerator('pl-12')}}>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {t('member.label')} {(idx+1)}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={4}>
                                <Input
                                    value={inviteMemberState[idx].userName}
                                    type={'text'}
                                    placeholder={`${t('name.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => handleInputChange(evt.target.value, idx, 'userName')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Input
                                    disabled={data ? true : false}
                                    value={inviteMemberState[idx].email}
                                    type={'text'}
                                    placeholder={`${t('email.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => handleInputChange(evt.target.value, idx, 'email')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <OutlinedSelect
                                    val={inviteMemberState[idx].role}
                                    label={`${t('role.label')} *`}
                                    handleChange={evt => {
                                        handleInputChange(evt.target.value, idx, 'role');
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                    options={roleList}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })
                }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        let validData = true;
                        let validEmail = true;
                        for(let i = 0; i < inviteMemberState.length; i++){
                            if(!inviteMemberState[i].userName || !inviteMemberState[i].email || !inviteMemberState[i].role){
                                validData = false;
                                break;
                            }
                            if(!checkValidEmail(inviteMemberState[i].email)){
                                validEmail = false;
                                break;
                            }
                        }
                        if(!validData){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if(!validEmail){
                            Message.error(INVALID_EMAIL);
                            return null;
                        }
                        handleInviteSubmitClick(inviteMemberState);
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

InviteMemberModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleInviteSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(InviteMemberModal);
