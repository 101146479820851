import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  paper: { maxWidth: "800px" },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px'
  }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateCountryModal({
    t,
    open,
    data,
    handleSubmitClick,
    handleClose,
    modalState
}) {
    const iniEmails = {sales: '', config:'', finance:'', issues: ''};
    const classes = useStyles();
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [defaultLang, setDefaultLang] = useState('');
    const [emails, setEmails] = useState(iniEmails);
    const [regions, setRegions] = useState('');
    const [status, setStatus] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');

    React.useEffect(() => {
        if(modalState.clearData && open){
            setName('');
            setCode('');
            setDefaultLang('');
            setEmails(iniEmails);
            setAddressLine1("");
            setAddressLine2("");
            handleClose();
        }
    }, [modalState]);


    React.useEffect(() => {
        if(data){
            setName(data.name);
            setCode(data.code);
            setStatus(data.status);
            setDefaultLang(data.defaultLang);
            setEmails(data.emails ? JSON.parse(data.emails) : iniEmails);
            setAddressLine1(data.addressLine1 ? data.addressLine1 : "");
            setAddressLine2(data.addressLine2 ? data.addressLine2 : "");
		}
    }, [data]);
    
    const changeEmailsValue = (indx, value) => {
        setEmails(prevState => ({
            ...prevState,
            [indx]: value
        }));
    }
    
    return (
        <Dialog
            classes={{ paper: classes.paper}}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="country-title">{data ? t('edit.label') : t('createNew.label')} {t('country.label')}</DialogTitle>
            <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <Input
                            value={name}
                            type={'text'}
                            placeholder={t('name.label')}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => setName(evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <Input
                            value={code}
                            type={'text'}
                            placeholder={t('code.label')}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => setCode(evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <Input
                            value={defaultLang}
                            type={'text'}
                            placeholder={t('defaultLang.label')}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => setDefaultLang(evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <OutlinedSelect
                            val={status}
                            label={`${t('status.label')} *`}
                            handleChange={evt => {
                                setStatus(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{ width: '100%', marginRight: 0 }}
                            options={['Active', 'Inactive']}
                        />
                    </Grid>
                </Grid>
                {!data &&
                <Grid item xs={12}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <TextField
                            id="standard-multiline-flexible"
                            placeholder={t('regions.label')}
                            multiline
                            rowsMax={12}
                            rows={2}
                            value={regions}
                            onChange={evt => setRegions(evt.target.value)}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            variant="standard"
                        />
                    </Grid>
                </Grid>}
                <Grid item xs={6}>
                    <Input
                        value={emails.sales}
                        type={'text'}
                        placeholder={`${t('sales.label')} ${t('email.label')}`}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => {
                            changeEmailsValue('sales', evt.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        value={emails.config}
                        type={'text'}
                        placeholder={`${t('config.label')} ${t('email.label')}`}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => changeEmailsValue('config', evt.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        value={emails.finance}
                        type={'text'}
                        placeholder={`${t('finance.label')} ${t('email.label')}`}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => changeEmailsValue('finance', evt.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        value={emails.issues}
                        type={'text'}
                        placeholder={`${t('issues.label')} ${t('email.label')}`}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => changeEmailsValue('issues', evt.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        fullWidth
                        id="outlined-full-width"
                        placeholder={t("addressLine1.label")}
                        margin="normal"
                        value={addressLine1}
                        onChange={evt => setAddressLine1(evt.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        fullWidth
                        id="outlined-full-width"
                        placeholder={t("addressLine2.label")}
                        margin="normal"
                        value={addressLine2}
                        onChange={evt => setAddressLine2(evt.target.value)}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!name || !code || !defaultLang || !emails.config || !emails.sales || !emails.finance || !emails.issues){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleSubmitClick({name: name, code: code, defaultLang: defaultLang, regions: regions, emails: JSON.stringify(emails), id: (data && data.id) ? data.id : null, addressLine1, addressLine2 });
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateCountryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCountryModal);
