import React, { useState, useEffect, useCallback } from 'react';
import './styles.css';
import { Paths, TicketingSystem } from '../../routes/routePaths';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getParam } from '../../utils';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  REQUIRED_ERROR,
  SUBSCRIPTION_STATUS,
  NATURE_OF_CAUSE,
  SEVERITY_OPTIONS,
  TICKET_DATA,
  LINK_TYPE,
  COUNTRY_LIST,
  CUSTOMER_TYPE
} from '../../utils/constants';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from '@material-ui/core/Divider';
import {
  GET_TICKET_CATEGORIES,
  GET_ESCALATION_LIST,
  GET_INCIDENT_TYPE_LIST,
  GET_INCIDENT_TYPE_GROUP_LIST,
  GET_VENDOR_LIST,
  GET_ALL_SUBSCRIPTION_LIST,
  GET_USER_LIST,
  GET_USER_LIST_BY_BASESTATION,
  GET_USER_LIST_BY_LOCATION,
  GET_SERVICE_TICKET_DETAILS_BY_ID,
  GET_USER_INFO_BY_ID,
  GET_SALES_SPECILIST,
  GET_ALL_INTERNAL_USER_LIST,
  GET_ALL_PHCNG_LIST,
  GET_ALL_SOLWEZING_LIST,
  GET_ALL_KITWENG_LIST,
  GET_ALL_LIVINGSTONENG_LIST,
  GET_ALL_NDOLANG_LIST,
  GET_ALL_LUSAKANG_LIST,
  GET_ALL_ABUJANG_LIST,
  GET_ALL_LAGOSNG_LIST,
  GET_BASE_STATION_LIST,
  GET_LOCATION_LIST,
  GET_ASSIGNEE_LIST,
  GET_CUSTOMERS_FROM_MONGO
} from './queries';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Image from '../../assets/icons/image.png';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import commonStyles from '../../theme/commonStyles';
import { CREATE_SERVICE_TICKET } from './mutations';
import Message from '../../components/Message';
import { UPLOAD_FILE } from '../commonMutations';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MultiSelect from './components/MultiSelect'

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'serviceTickets.label', link: Paths.ServiceTickets },
  { label: 'newServiceTicket.label', link: TicketingSystem.NewServiceTicket }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  reviewItemContainer: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  table: {
    minWidth: 600,
  },
  scrolling: {
    marginTop: '40px',
    height: '200px',
    overflowY: 'scroll',
    border: '1px solid #ddd'
  },
  detailsContainer: {
    border: `1px solid ${theme.palette.grey[200]}`
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  }
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}
let ticketCategory = [
  {
    id: '1',
    value: 'Normal Ticket',
    key: 'normalTicket'
  },
  {
    id: '2',
    value: 'Parent Ticket',
    key: 'parentTicket'
  }
];

function NewServiceTicket({ t }) {
  const id = getParam();
  const country = localStorage.getItem('country');
  const history = useHistory();
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [selectedOption, setSelectedOption] = useState({});
  const [title, setTitle] = useState('');
  const [emailCC, setEmailCC] = useState('');
  const [allCCEmails, setAllCCEmails] = useState([]);
  const [comment, setComment] = useState('');
  const [comment2, setComment2] = useState('');
  const [comment3, setComment3] = useState('');
  const [comment4, setComment4] = useState('');
  const [comment5, setComment5] = useState('');
  const [search, setSearch] = useState('');
  const [details, setDetails] = useState('');
  const [ticketCategoryData, setTicketCategoryData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [fetchedCustomerList, setFetchedCustomerList] = useState([]);
  const [preFilledCustomer, setPreFilledCustomer] = useState([]);
  const [preFilledSites, setPreFilledSites] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedSitesData, setSelectedSitesData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [region, setRegion] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileLoading, setUploadedFileLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [incidentTypeGroup, setIncidentTypeGroup] = useState([]);
  const [incidentType, setIncidentType] = useState([]);
  const [natureOfCase, setNatureOfCase] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [linkType, setLinkType] = useState([]);
  const [escalationLevel, setEscalationLevel] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [additionalAgent1, setAdditionalAgent1] = useState([]);
  const [additionalAgent2, setAdditionalAgent2] = useState([]);
  const [phcNGData, setPhcNGData] = useState([]);
  const [abujaNGData, setAbujaNGData] = useState([]);
  const [lagosNGData, setLagosNGData] = useState([]);

  const [lusakaZMData, setLusakaZMData] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [ndolaZMData, setNdolaZMData] = useState([]);
  const [kitweZMData, setKitweZMData] = useState([]);
  const [livingStoneZMData, setLivingStoneZMData] = useState([]);
  const [solweziZMData, setSolweziZMData] = useState([]);
  const [salesSpecilistData, setSalesSpecilistData] = useState([]);
  const [baseStationData, setBaseStationData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationIDList, setLocationIDList] = useState([]);

  const [incidentCause, setIncidentCause] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [assigneeData, setAssigneeData] = React.useState();
  const [vodacomProblem, setVodacomProblem] = useState(false);
  const [notNewCustomer, setNotNewCustomer] = useState(true);
  const [GAT013INQCORELINK, setGAT013INQCORELINK] = useState(false);
  const [GAT007ACCESSFIBERLINK, setGAT007ACCESSFIBERLINK] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);
  const [showBaseStation, setShowBaseStation] = useState(true);
  const [normalTicket, setNormalTicket] = useState(true);
  const [preFilled, setPrefilled] = useState(false);
  const [disableCheck, setDisableCheck] = useState(false);
  const [disableBaseCustomer, setDisableBaseCustomer] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [vendorStartDate, setVendorStartDate] = useState(null);

  const [getAssigneeData, { data: assignee }] = useLazyQuery(GET_ASSIGNEE_LIST);
  const [getUserListFromMongo, { loading: mongoUserLoading, data: userList }] = useLazyQuery(GET_CUSTOMERS_FROM_MONGO);
  const [getUserListData, { data: getAllUserList }] = useLazyQuery(GET_ALL_INTERNAL_USER_LIST);
  const { data: getPhcNGList } = useQuery(GET_ALL_PHCNG_LIST);
  const { data: getAbujaNGList } = useQuery(GET_ALL_ABUJANG_LIST);
  const { data: getLagosNGList } = useQuery(GET_ALL_LAGOSNG_LIST);
  const { data: getSalesSpecilist } = useQuery(GET_SALES_SPECILIST);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
  const { data: escalationgroup } = useQuery(GET_ESCALATION_LIST);
  const [getIncidentType, { data: incidenttype }] = useLazyQuery(
    GET_INCIDENT_TYPE_LIST
  );
  const [getIncidentTypeGroup, { data: incidenttypegroup }] = useLazyQuery(
    GET_INCIDENT_TYPE_GROUP_LIST
  );
  const { data: vendor } = useQuery(GET_VENDOR_LIST);
  const { data: categoryData } = useQuery(GET_TICKET_CATEGORIES);
  const { data: getLocationList } = useQuery(GET_LOCATION_LIST);
  const { data: getBaseStationList } = useQuery(GET_BASE_STATION_LIST);

  const [getLusakaNGListData, { data: getLusakaNGList }] = useLazyQuery(
    GET_ALL_LUSAKANG_LIST
  );
  const [getNdolaNGListData, { data: getNdolaNGList }] = useLazyQuery(
    GET_ALL_NDOLANG_LIST
  );
  const [
    getLivingstoneNGListData,
    { data: getLivingstoneNGList }
  ] = useLazyQuery(GET_ALL_LIVINGSTONENG_LIST);
  const [getKitweNGListData, { data: getKitweNGList }] = useLazyQuery(
    GET_ALL_KITWENG_LIST
  );
  const [getSolweziNGListData, { data: getSolweziNGList }] = useLazyQuery(
    GET_ALL_SOLWEZING_LIST
  );

  const [
    getPendingSubscriptions,
    { data: pendingSubscriptions }
  ] = useLazyQuery(GET_ALL_SUBSCRIPTION_LIST);

  const [getActiveSubscriptions, { data: activeSubscriptions }] = useLazyQuery(
    GET_ALL_SUBSCRIPTION_LIST
  );

  const [getUsersListByBaseStation] = useLazyQuery(
    GET_USER_LIST_BY_BASESTATION,
    {
      fetchPolicy: 'no-cache',
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: res => {
        let customerArrayData =
          res &&
          res.getUsersInfoByBaseStationCode &&
          res.getUsersInfoByBaseStationCode.length &&
          res.getUsersInfoByBaseStationCode.map(data => {
            return data;
          });
        setFetchedCustomerList(customerArrayData);
        if (res && res.getUsersInfoByBaseStationCode.length == 0) {
          Message.error('No customer found for this base station');
        }
        res && res.getUsersInfoByBaseStationCode.length > 0 && setDisableBaseCustomer(false);
        res && res.getUsersInfoByBaseStationCode.length > 0 && setBtnDisable(false);
      }
    }
  );

  const [getUsersListByLocation] = useLazyQuery(
    GET_USER_LIST_BY_LOCATION,
    {
      fetchPolicy: 'no-cache',
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: res => {
        let customerArrayData =
          res &&
          res.getUsersInfoByLocation &&
          res.getUsersInfoByLocation.length &&
          res.getUsersInfoByLocation.map(data => {
            return data;
          });
        setFetchedCustomerList(customerArrayData);
        if (res && res.getUsersInfoByLocation.length == 0) {
          Message.error('No customer found for this location');
        }
        res && res.getUsersInfoByLocation.length > 0 && setDisableBaseCustomer(false);
        res && res.getUsersInfoByLocation.length > 0 && setBtnDisable(false);
      }
    }
  );

  const [getTicketData] = useLazyQuery(GET_SERVICE_TICKET_DETAILS_BY_ID, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      if (
        res.getServiceTicketById &&
        res.getServiceTicketById.subscription &&
        res.getServiceTicketById.sites
      ) {
        let selectedSite = res.getServiceTicketById.sites.map(subs => {
          return { id: subs.id, name: subs.name, status: '' };
        });
        setSelectedSites(selectedSite);
        setPreFilledSites([res.getServiceTicketById.sites[0].id]);
        setSelectedService({
          id: res.getServiceTicketById.subscription.id,
          name: res.getServiceTicketById.subscription.name
            ? res.getServiceTicketById.subscription.name
            : res.getServiceTicketById.subscription.service.name
        });

        getUserDataById({
          variables: {
            id: res.getServiceTicketById.customer
          }
        });
      }
    }
  });
  const [getUserDataById] = useLazyQuery(GET_USER_INFO_BY_ID, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      if (res.getUserInfoById) {
        setSelectedCustomer([
          {
            id: res.getUserInfoById.id,
            firstName: res.getUserInfoById.firstName,
            lastName: res.getUserInfoById.lastName,
            email: res.getUserInfoById.email,
            attributes: res.getUserInfoById.attributes
              ? res.getUserInfoById.attributes
              : ''
          }
        ]);
        setPreFilledCustomer([res.getUserInfoById.id]);
        let countryId = JSON.parse(res.getUserInfoById.attributes).countryId[0];
        let countryNewData =
          countryList &&
          countryList.getCountries.length &&
          countryList.getCountries.filter(cou => {
            if (cou.id === countryId) {
              return cou;
            }
          });

        let countryDetail = Object.assign(
          {},
          countryNewData && countryNewData.length && countryNewData[0]
        );
        setCountryData(countryDetail);
        getRegionList({ variables: { countryId: countryDetail.id } });
      }
    }
  });
  const [createServiceTicket] = useMutation(CREATE_SERVICE_TICKET, {
    onError: error => {
      setUploadedFileLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket created successfully.');
      setUploadedFileLoading(false);
      history.push(Paths.TicketSystem);
    }
  });

  useEffect(() => {
    getUserListData({
      variables: {
        ticketType: TICKET_DATA.SERVICETICKET
      }
    })
    getAssigneeData({
      variables: {
        ticketType: TICKET_DATA.SERVICETICKET
      }
    })
    getUserListFromMongo()
    getIncidentTypeGroup({
      variables: {
        parent: 'FALSE',
        ticketType: TICKET_DATA.SERVICETICKET
      }
    });
    if (id) {
      setDisabled(true);
      setPrefilled(true);
      getTicketData({
        variables: {
          id: id
        }
      });
    }
    if (country !== 'null' && country === COUNTRY_LIST.ZAMBIA) {
      getLusakaNGListData();
      getNdolaNGListData();
      getLivingstoneNGListData();
      getKitweNGListData();
      getSolweziNGListData();
    }
  }, []);

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      return createServiceTicket({
        variables: {
          title: title,
          category: selectedOption.id,
          subscription: normalTicket ? selectedService.id : '',
          country: countryData.id,
          region: region.id,
          ticketType: 'csocTicket',
          description: details,
          sites: preFilled
            ? preFilledSites
            : normalTicket
              ? selectedSitesData
              : [],
          incidentTypeGroup: incidentTypeGroup.name,
          selectedCustomer: selectedCustomer,
          linkType: linkType.name,
          natureOfCase: natureOfCase.name,
          severity: severity.name,
          incidentType: incidentType.name,
          customers: preFilled ? preFilledCustomer : selectedCustomerData,
          escalationLevel: escalationLevel.name,
          projectCategory: projectCategory.name,
          vendor: vendorData.name,
          allCCEmails: allCCEmails,
          assignee: assigneeData,
          incidentTime: startDate,
          vodacomProblem: vodacomProblem,
          baseStation: baseStationData.id,
          circuitId: selectedSites && selectedSites[0] && selectedSites[0].circuitId,
          platform: selectedSites && selectedSites[0] && selectedSites[0].platform,
          location: locationIDList && locationIDList.length > 0 ? locationIDList : [],
          additionalAgent1: additionalAgent1.id,
          additionalAgent2: additionalAgent2.id,
          salesSpecilistData: salesSpecilistData.id,
          incidentEndTime: endDate,
          lusakaZMData: lusakaZMData.id,
          ndolaZMData: ndolaZMData.id,
          kitweZMData: kitweZMData.id,
          livingStoneZMData: livingStoneZMData.id,
          solweziZMData: solweziZMData.id,
          lagosNGData: lagosNGData.id,
          phcNGData: phcNGData.id,
          abujaNGData: abujaNGData.id,
          vendorStartDate: vendorStartDate,
          reportComment: comment,
          reportComment2: comment2,
          reportComment3: comment3,
          reportComment4: comment4,
          reportComment5: comment5,
          attachments: [data.uploadDocument.url]
        }
      });
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleInternalButton = () => {
    history.push(TicketingSystem.InternalTicket);
  }

  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const serviceOptions =
    pendingSubscriptions &&
    activeSubscriptions &&
    pendingSubscriptions.getAllSubscriptionsListing &&
    activeSubscriptions.getAllSubscriptionsListing &&
    [
      ...pendingSubscriptions.getAllSubscriptionsListing,
      ...activeSubscriptions.getAllSubscriptionsListing
    ].map((item, index) => {
      return {
        id: item.id,
        name: item.name != null ? item.name : item.service.name
      };
    });

  const siteOptions =
    selectedService &&
    selectedService.id &&
    pendingSubscriptions &&
    activeSubscriptions &&
    pendingSubscriptions.getAllSubscriptionsListing &&
    activeSubscriptions.getAllSubscriptionsListing &&
    [
      ...pendingSubscriptions.getAllSubscriptionsListing,
      ...activeSubscriptions.getAllSubscriptionsListing
    ]
      .filter(item => item.id === selectedService.id)
      .map(item => item.sites)
      .flat(1);
  const handleSubscriptionChange = (event, newValue) => {
    if (newValue == null) {
      setSelectedService([]);
      setSelectedSites([]);
    } else {
      setSelectedService(newValue);
      setSelectedSites([]);
    }
  };
  const handleSitesValue = (event, newValue) => {
    if (newValue.length === 0) {
      setSelectedSites([]);
      setSelectedSitesData([]);
    } else {
      let siteArray =
        newValue &&
        newValue.length &&
        newValue.map(data => {
          return data;
        });
      setSelectedSites(siteArray);
      let siteArrayData =
        newValue &&
        newValue.length &&
        newValue.map(data => {
          return data.id;
        });
      setSelectedSitesData(siteArrayData);
    }
  };
  const handleChangeCustomer = (event, newValue) => {
    if (newValue.length === 0) {
      setSelectedCustomer([]);
      setSelectedCustomerData([]);
      setSelectedService([]);
      setSelectedSites([]);
    } else {
      if (
        newValue[newValue.length - 1].attributes &&
        newValue[newValue.length - 1].attributes.Status &&
        newValue[newValue.length - 1].attributes.Status[0].toLowerCase() !=
        'active'
      ) {
        Message.error('User Account Is Not Active.');
      } else {
        let customerArray =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data;
          });
        setSelectedCustomer(customerArray);

        let customerArrayData =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data.id;
          });
        setSelectedCustomerData(customerArrayData);
        newValue &&
          newValue.length &&
          getPendingSubscriptions({
            variables: {
              status: SUBSCRIPTION_STATUS.PENDING,
              id: customerArrayData
            }
          });
        newValue &&
          newValue.length &&
          getActiveSubscriptions({
            variables: {
              status: SUBSCRIPTION_STATUS.ACTIVE,
              id: customerArrayData
            }
          });
      }
    }
  };
  const handleLocationValue = (event, newValue) => {
    if (newValue.length === 0) {
      setLocationData([]);
      setLocationIDList([]);
      setSelectedCustomerData([]);
      setSelectedCustomer([]);
      setDisableBaseCustomer(true);
      setFetchedCustomerList([]);
      setBtnDisable(true);
    } else {
      setSelectedCustomerData([]);
      setSelectedCustomer([]);
      setDisableBaseCustomer(true);
      setFetchedCustomerList([]);
      setBtnDisable(true);
      let locationArray =
        newValue &&
        newValue.length &&
        newValue.map(data => {
          return data;
        });
      setLocationData(locationArray);
      let allLocationId =
        newValue &&
        newValue.length &&
        newValue.map(data => {
          return data.id;
        });
      setLocationIDList(allLocationId);
      let ulCTCLocationList =
        newValue &&
        newValue.length &&
        newValue.map(data => {
          return data.ulCTCLocation;
        });
      ulCTCLocationList &&
        ulCTCLocationList.length > 0 &&
        getUsersListByLocation({
          variables: {
            location: ulCTCLocationList
          }
        });
    }
  };

  const handleChange = () => {
    setVodacomProblem(!vodacomProblem);
  };

  const handleChangeCustomerTag = () => {
    setNotNewCustomer(!notNewCustomer);
  };

  const handleChangeGAT013INQCORELINK = (event) => {
    if (event.target.checked == true) {
      setGAT013INQCORELINK(true);
      setNotNewCustomer(false);
    } else {
      setGAT013INQCORELINK(false);
      setNotNewCustomer(true);
    }
  };

  const handleChangeGAT007ACCESSFIBERLINK = (event) => {
    if (event.target.checked == true) {
      setGAT007ACCESSFIBERLINK(true);
      setNotNewCustomer(false);
    } else {
      setGAT007ACCESSFIBERLINK(false);
      setNotNewCustomer(true);
    }
  };

  const getOptionLabel = (option) => `${option && option.location}`;
  const getOptionDisabled = (option) => option && option.location === "";
  const handleToggleOption = (selectedOptions) => {
    setSelectedCustomer(selectedOptions);
    let customerArrayData =
      selectedOptions &&
      selectedOptions.length &&
      selectedOptions.map(data => {
        return data.id;
      });
    setSelectedCustomerData(customerArrayData);
  };
  const handleClearOptions = () => {
    setSelectedCustomer([])
    setSelectedCustomerData([])
  }
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedCustomer(fetchedCustomerList && fetchedCustomerList);
      let customerArrayData =
        fetchedCustomerList &&
        fetchedCustomerList.length &&
        fetchedCustomerList.map(data => {
          return data.id;
        });
      setSelectedCustomerData(customerArrayData);
    } else {
      handleClearOptions();
    }
  };

  const handleIncidentTypeGroup = (event, newValue) => {
    if (newValue === null) {
      setIncidentTypeGroup([]);
      setIncidentType([]);
    } else {
      setIncidentTypeGroup(newValue);

      newValue &&
        newValue.name &&
        getIncidentType({
          variables: {
            incidentTypeGroup: newValue.name,
            ticketType: TICKET_DATA.SERVICETICKET
          }
        });
    }
  };
  const handleTicketCategory = (event, newValue) => {
    newValue && setTicketCategoryData(newValue);
    if (newValue && newValue.key === 'parentTicket') {
      setSelectedCustomerData([]);
      setSelectedCustomer([]);
      setSelectedService([]);
      setSelectedSites([]);
      setAllCCEmails([])
      setEmailCC('')
      setIncidentTypeGroup([]);
      setIncidentType([]);
      setShowAdditional(true);
      setNormalTicket(false);
      setVodacomProblem(true);
      setDisableCheck(true)
      getIncidentTypeGroup({
        variables: {
          parent: 'TRUE',
          ticketType: TICKET_DATA.SERVICETICKET
        }
      });
    } else {
      setBtnDisable(false);
      setShowAdditional(false);
      setNormalTicket(true);
      setVodacomProblem(false);
      setDisableCheck(false)
      setSelectedCustomerData([]);
      setIncidentTypeGroup([]);
      setIncidentType([]);
      setSelectedCustomer([]);
      setSelectedService([]);
      setSelectedSites([]);
      getIncidentTypeGroup({
        variables: {
          parent: 'FALSE',
          ticketType: TICKET_DATA.SERVICETICKET
        }
      });
    }
  };

  const handleCustomerType = (event, newValue) => {
    if (newValue && newValue.value === 'Location') {
      setBaseStationData([])
      setSelectedCustomerData([]);
      setSelectedCustomer([]);
      setShowBaseStation(false)
      setFetchedCustomerList([]);
      setBtnDisable(true);
    } else {
      setLocationData([])
      setSelectedCustomerData([]);
      setSelectedCustomer([]);
      setShowBaseStation(true)
      setFetchedCustomerList([]);
      setBtnDisable(true);
    }
  }

  const handleEmailCC = (e) => {
    setEmailCC(e.target.value)
  }

  const emailHandlerKeyPress = (e) => {
    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
    if (e.key === 'Enter') {
      if (e.target.value === '') {
        Message.error('Please Enter Email Ids seprated by commas')
        return false
      }
      var emailIds = e.target.value.split(",")
      var allEmails = []
      emailIds.map(email => {
        if (!pattern.test(email)) {
          Message.error(`${email} is not valid`)
          setValidEmail(false)
          return false
        } else {
          setValidEmail(true)
          if (!allEmails.includes(email)) allEmails.push(email)
        }
      })
      setAllCCEmails(allEmails)
      Message.success("Email CC added successfully")
    }
  }


  const debounce = (func) => {
    let timer;
    return function (...args) {
      if (args && args.length) {
        args[0].persist()
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          func.apply(context, args);
        }, 500);
      }
    };
  };

  const handleChangeSearchCustomer = (value) => {
    if (value && value.target.value) {
      setSearch(value.target.value)
      getUserListFromMongo({
        variables: {
          search: value.target.value
        }
      })
    }
  };

  const handleInputChange = useCallback(debounce(handleChangeSearchCustomer), []);

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '800px' }}>
      <Header
        title={
          showAdditional
            ? t('parentServiceTicket.label')
            : t('normalServiceTicket.label')
        }
        divider={true}
        subText={t('createTicketSUbText.label')}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
        secondaryButtonText={t('internalTicket.label')}
        handleSecondaryButtonClick={handleInternalButton}
      />

      <Grid style={{ ...marginGenerator('mt-32') }}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('ticketCategory.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo7"
          value={ticketCategoryData}
          options={ticketCategory ? ticketCategory : []}
          getOptionLabel={option => option.value}
          onChange={(event, newValue) => handleTicketCategory(event, newValue)}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('whatIsItRelatedTo.label')} *
        </Typography>
        {categoryData &&
          categoryData.categories.length &&
          categoryData.categories.slice(0, 6).map((item, index) => (
            <Radio
              checked={item.id === selectedOption.id}
              key={index}
              classes={{ root: classes.iconButton }}
              style={{
                ...marginGenerator('mr-16'),
                ...(item.id === selectedOption.id
                  ? { backgroundColor: colors.secondary.main }
                  : '')
              }}
              onChange={() => setSelectedOption(item)}
              value={item.id}
              name="type-select"
              checkedIcon={
                <div>
                  <Typography
                    variant={'subtitle2'}
                    style={{ color: colors.common.white }}>
                    {item.name}
                  </Typography>
                </div>
              }
              icon={
                <div>
                  <Typography variant={'subtitle2'}>{item.name}</Typography>
                </div>
              }
            />
          ))}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentCause.label')}
        </Typography>
        <Input
          value={title}
          type={'text'}
          placeholder={`${t('incidentCause.label')} *`}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setTitle(evt.target.value)}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('enterAdditionalDetails.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={details}
              id="details-textarea"
              placeholder={t('enterIssueDetails.label')}
              multiline
              onChange={evt => setDetails(evt.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid container item justify={'flex-end'}>
            {uploadedFile && (
              <Chip
                avatar={<img src={Image} alt={'file'} />}
                label={
                  uploadedFile.name.length > 12
                    ? `${uploadedFile.name.slice(0, 12)}...`
                    : uploadedFile.name
                }
                onDelete={() => {
                  setUploadedFile('');
                }}
                deleteIcon={<Close />}
                variant="outlined"
              />
            )}
            <IconButton
              size={'medium'}
              disableRipple={true}
              disableFocusRipple={true}
              color={'secondary'}
              onClick={handleFileUploadClick}
              style={marginGenerator('ml-16')}>
              <AttachFile className={commonClasses.iconRegular} />
            </IconButton>
            <input
              type="file"
              id="attachment"
              ref={inputFile}
              onChange={event => {
                event.stopPropagation();
                event.preventDefault();
                setUploadedFile(event.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectCountry.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={countryData}
          disabled={Boolean(disabled)}
          options={countryList ? countryList.getCountries : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            newValue &&
              newValue.id &&
              getRegionList({ variables: { countryId: newValue.id } });
            setRegion('');
            setCountryData(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRegion.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={region}
          options={regionList ? regionList.getRegionsByCountryId : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setRegion(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        {normalTicket ? (
          <>
            <div className="newCustomer">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCustomerTag}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={country !== 'null' && country === COUNTRY_LIST.CAMEROON ? t('oldContract.label') : t('newCustomer.label')}
              />


              {country !== 'null' && country == COUNTRY_LIST.NIGERIA ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChangeGAT013INQCORELINK(event)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="GAT013 - Inq Core Link"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChangeGAT007ACCESSFIBERLINK}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="GAT007 - Access Fiber Links"
                  />
                </>
              ) : null
              }
            </div>

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('selectCustomer.label')} *
            </Typography>
            <Autocomplete
              multiple
              id="tags-standard"
              value={selectedCustomer}
              disabled={Boolean(disabled)}
              options={userList && userList.getCustomerDataFromMongo ? userList.getCustomerDataFromMongo : []}
              getOptionLabel={option =>
                ` ${option.organisations && option.organisations[0]
                  ? option.organisations[0].name
                  : ''
                }
                ${option.location ? option.location : ""} ${option.basestation ? option.basestation : ""} ${option.vendor ? option.vendor : ""} ${option.configured_DN_CIR ? option.configured_DN_CIR : ""
                } ${option.configured_UP_CIR ? option.configured_UP_CIR : ""} ${option.platform ? option.platform : ""} ${option.status ? option.status : ""
                }`
              }
              onChange={(event, newValue) =>
                handleChangeCustomer(event, newValue)
              }
              onInputChange={evt => handleInputChange(evt)}
              loading={mongoUserLoading}
              autoHighlight
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={params => (
                <TextField
                  style={{
                    width: '100%',
                    border: '1px solid #ebebeb !important'
                  }}
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {mongoUserLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />

            {notNewCustomer ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('selectRelevantService.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo2"
                  value={selectedService}
                  disabled={
                    !Boolean(selectedCustomer.length) || Boolean(disabled)
                  }
                  options={
                    serviceOptions && serviceOptions.length
                      ? serviceOptions
                      : []
                  }
                  getOptionLabel={option => (option ? option.name : '')}
                  onChange={(event, newValue) =>
                    handleSubscriptionChange(event, newValue)
                  }
                  getOptionSelected={(option, value) =>
                    option && option.id === value.id
                  }
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />



                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('selectRelevantSites.label')}
                </Typography>

                <Autocomplete
                  multiple
                  id="tags-standard"
                  value={selectedSites}
                  disabled={
                    (!Boolean(selectedService.length) &&
                      !Boolean(selectedCustomer.length)) ||
                    Boolean(disabled)
                  }
                  options={siteOptions && siteOptions.length ? siteOptions : []}
                  getOptionLabel={option =>
                    `${option.circuitId ? option.circuitId : ''} ${option.platform ? option.platform : ''
                    } ${option.name ? option.name : ''}`
                  }
                  onChange={(event, newValue) => {
                    handleSitesValue(event, newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      style={{
                        width: '100%',
                        border: '1px solid #ebebeb !important'
                      }}
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              </>
            ) : null}
          </>
        ) : null}

        {normalTicket && country !== 'null' && country === COUNTRY_LIST.ZAMBIA ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('linkType.label')} *
            </Typography>
            <Autocomplete
              id="combo-box-demo3"
              value={linkType}
              options={LINK_TYPE ? LINK_TYPE : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                setLinkType(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('natureOfCase.label')} *
            </Typography>
            <Autocomplete
              id="combo-box-demo3"
              value={natureOfCase}
              options={NATURE_OF_CAUSE ? NATURE_OF_CAUSE : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                setNatureOfCase(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}

        {showAdditional && country !== 'null' && country === COUNTRY_LIST.ZAMBIA ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('severity.label')} *
            </Typography>
            <Autocomplete
              id="combo-box-demo3"
              value={severity}
              options={SEVERITY_OPTIONS ? SEVERITY_OPTIONS : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                setSeverity(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentTypeGroup.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo3"
          value={incidentTypeGroup}
          options={incidenttypegroup ? incidenttypegroup.incidenttypegroup : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            handleIncidentTypeGroup(event, newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentType.label')} *
        </Typography>

        <Autocomplete
          id="combo-box-demo4"
          value={incidentType}
          disabled={!Boolean(incidentTypeGroup.id)}
          options={incidenttype ? incidenttype.incidenttype : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setIncidentType(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('escalationLevel.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo5"
          value={escalationLevel}
          options={escalationgroup ? escalationgroup.escalationgroup : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setEscalationLevel(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('vendor.label')}
        </Typography>
        <Autocomplete
          id="combo-box-demo6"
          value={vendorData}
          options={vendor ? vendor.vendor : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setVendorData(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('selectAssignee.label')}*
        </Typography>

        <Autocomplete
          id="combo-box-demo"
          val={assigneeData}
          options={assignee && assignee ? assignee.assignee : []}
          getOptionLabel={option => `${option.name} ${option.surname} `}
          onChange={(event, newValue) => {
            setAssigneeData(newValue.email.toLowerCase());
          }}
          style={{ width: '100%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '94%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentTime.label')}*
        </Typography>
        <div className="datePicker">
          <DatePicker
            timeIntervals={30}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => setStartDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy HH:mm"
            showTimeInput
          />
        </div>
        {normalTicket ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('vendorStartDate.label')}
            </Typography>
            <div className="datePicker">
              <DatePicker
                timeIntervals={30}
                selected={vendorStartDate}
                onChange={date => setVendorStartDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy HH:mm"
                showTimeInput
                startDate={vendorStartDate}
              />
            </div>
          </>
        ) : null}

        {normalTicket && country !== 'null' && country === COUNTRY_LIST.BOTSWANA ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('addCC.label')}
            </Typography>
            <Input
              value={emailCC}
              type={'text'}
              placeholder={`${t('addCCDesc.label')}`}
              style={{ width: '100%' }}
              color={'secondary'}
              onChange={evt => handleEmailCC(evt)}
              onKeyPress={e => emailHandlerKeyPress(e)}
            />
          </>
        ) : null}

        <div className="additionalField">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={vodacomProblem}
                disabled={disableCheck}
                name="checkedB"
                color="primary"
              />
            }
            label="INQ Problem"
          />
        </div>

        {showAdditional ? (
          <>
            <h2 className="header">Default</h2>
            <Divider />

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('parentTicketCustomer.label')}
            </Typography>
            <Autocomplete
              id="combo-box-demo7"
              options={CUSTOMER_TYPE ? CUSTOMER_TYPE : []}
              getOptionLabel={option => option.value}
              onChange={(event, newValue) => handleCustomerType(event, newValue)}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />

            {showBaseStation ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('selectBaseStation.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={baseStationData}
                  options={
                    getBaseStationList && getBaseStationList ? getBaseStationList.getBaseStationList : []
                  }
                  getOptionLabel={option =>
                    option && option.ulCTCBaseStationCode
                      ? option.ulCTCBaseStationCode
                      : ''
                  }
                  onChange={(event, newValue) => {
                    setBaseStationData(newValue);
                    setSelectedCustomerData([]);
                    setSelectedCustomer([]);
                    setDisableBaseCustomer(true);
                    setFetchedCustomerList([]);
                    setBtnDisable(true);
                    newValue &&
                      newValue.id &&
                      getUsersListByBaseStation({
                        variables: {
                          baseStationCode: newValue.ulCTCBaseStationCode
                        }
                      });
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('selectLocation.label')}
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  value={locationData}
                  options={getLocationList && getLocationList ? getLocationList.getLocationList : []}
                  getOptionLabel={option =>
                    option && `${option.ulCTCLocation ? option.ulCTCLocation : ''}`
                  }
                  onChange={(event, newValue) => {
                    handleLocationValue(event, newValue)
                  }}
                  getOptionSelected={(option, value) => option && option.id === value.id}
                  renderInput={params => (
                    <TextField
                      style={{
                        width: '100%',
                        border: '1px solid #ebebeb !important'
                      }}
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              </>
            )}

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('selectCustomer.label')}*
            </Typography>
            <MultiSelect
              items={fetchedCustomerList && fetchedCustomerList}
              getOptionLabel={getOptionLabel}
              disabled={disableBaseCustomer || fetchedCustomerList.length == 0}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedCustomer}
              label="Select complex values"
              placeholder="Placeholder for textbox"
              selectAllLabel="Select all"
              onToggleOption={handleToggleOption}
              onClearOptions={handleClearOptions}
              onSelectAll={handleSelectAll}
            />

            {selectedCustomer && selectedCustomer.length ? (
              <div className={classes.scrolling}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                      {selectedCustomer.map(customer => {
                        return (
                          <TableRow key={customer.attributes && customer.attributes.CircuitID
                            ? customer.attributes.CircuitID[0]
                            : customer.email}>
                            <TableCell component="th" scope="row">
                              {customer.attributes && customer.attributes.CircuitID
                                ? customer.attributes.CircuitID[0] : ""}
                            </TableCell>
                            <TableCell align="left">{customer.location ? customer.location : customer.firstName + " " + customer.lastName}</TableCell>
                            <TableCell align="left">{customer.email}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : ""}

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('endDate.label')}
            </Typography>
            <div className="datePicker">
              <DatePicker
                timeIntervals={30}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={date => setEndDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy HH:mm"
                showTimeInput
              />
            </div>
            {country !== 'null' && country !== COUNTRY_LIST.NIGERIA && country !== COUNTRY_LIST.ZAMBIA ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('lagosFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={lagosNGData}
                  options={getLagosNGList ? getLagosNGList.getLagosNGList : []}
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setLagosNGData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('phcFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={phcNGData}
                  options={getPhcNGList ? getPhcNGList.getPhcNGList : []}
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setPhcNGData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('abujaFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={abujaNGData}
                  options={getAbujaNGList ? getAbujaNGList.getAbujaNGList : []}
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setAbujaNGData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </>
            ) : null}

            {country !== 'null' && country === COUNTRY_LIST.ZAMBIA ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('lusakaFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={lusakaZMData}
                  options={
                    getLusakaNGList ? getLusakaNGList.getLusakaNGList : []
                  }
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setLusakaZMData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('ndolaFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={ndolaZMData}
                  options={getNdolaNGList ? getNdolaNGList.getNdolaNGList : []}
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setNdolaZMData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('kitweFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={kitweZMData}
                  options={getKitweNGList ? getKitweNGList.getKitweNGList : []}
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setKitweZMData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('livingstoneFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={livingStoneZMData}
                  options={
                    getLivingstoneNGList
                      ? getLivingstoneNGList.getLivingstoneNGList
                      : []
                  }
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setLivingStoneZMData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('solweziFieldOps.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={solweziZMData}
                  options={
                    getSolweziNGList ? getSolweziNGList.getSolweziNGList : []
                  }
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setSolweziZMData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </>
            ) : null}

            {country !== 'null' && country !== COUNTRY_LIST.NIGERIA ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('additionalEscalation1.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={additionalAgent1}
                  options={getAllUserList ? getAllUserList.getAllUserList : []}
                  getOptionLabel={option =>
                    option && option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : ''
                  }
                  onChange={(event, newValue) => {
                    setAdditionalAgent1(newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('additionalEscalation2.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={additionalAgent2}
                  options={getAllUserList ? getAllUserList.getAllUserList : []}
                  getOptionLabel={option =>
                    option && option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : ''
                  }
                  onChange={(event, newValue) => {
                    setAdditionalAgent2(newValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('productSalesspecilist.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={salesSpecilistData}
                  options={
                    getSalesSpecilist ? getSalesSpecilist.getSalesSpecilist : []
                  }
                  getOptionLabel={option => option.memberName}
                  onChange={(event, newValue) => {
                    setSalesSpecilistData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('projectCategory.label')}
                </Typography>
                <Autocomplete
                  id="combo-box-demo6"
                  value={projectCategory}
                  options={
                    escalationgroup
                      ? escalationgroup.escalationgroup.slice(2, 5)
                      : []
                  }
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    setProjectCategory(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </>
            ) : null}
            <h2 className="header">Reporting</h2>
            <Divider />
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('reportComment.label')}
            </Typography>
            <Input
              value={comment}
              type={'text'}
              placeholder={`${t('reportComment.label')} `}
              style={{ width: '100%' }}
              color={'secondary'}
              onChange={evt => setComment(evt.target.value)}
            />

            {country !== 'null' && country !== COUNTRY_LIST.NIGERIA ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('reportComment2.label')}
                </Typography>
                <Input
                  value={comment2}
                  type={'text'}
                  placeholder={`${t('reportComment2.label')} `}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  onChange={evt => setComment2(evt.target.value)}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('reportComment3.label')}
                </Typography>
                <Input
                  value={comment3}
                  type={'text'}
                  placeholder={`${t('reportComment3.label')} `}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  onChange={evt => setComment3(evt.target.value)}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('reportComment4.label')}
                </Typography>
                <Input
                  value={comment4}
                  type={'text'}
                  placeholder={`${t('reportComment4.label')} `}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  onChange={evt => setComment4(evt.target.value)}
                />

                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('reportComment5.label')}
                </Typography>
                <Input
                  value={comment5}
                  type={'text'}
                  placeholder={`${t('reportComment5.label')} `}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  onChange={evt => setComment5(evt.target.value)}
                />
              </>
            ) : null}
          </>
        ) : null}
      </Grid>

      <Grid
        container
        style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
        justify={'flex-end'}>
        <Button
          variant={'contained'}
          disabled={btnDisable ? btnDisable : uploadedFileLoading}
          color={'primary'}
          onClick={() => {
            if (!title ||
              !selectedOption.id ||
              !countryData.id ||
              !region.id ||
              !selectedCustomerData.length ||
              !incidentTypeGroup.name ||
              !incidentType.name ||
              !assigneeData ||
              !escalationLevel.name ||
              !startDate) {
              Message.error(REQUIRED_ERROR);
              return null;
            }
            if (normalTicket) {
              if (notNewCustomer && (!selectedService.id || !selectedSitesData.length)) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
            } else {
              if (showBaseStation) {
                if (!baseStationData.id) {
                  Message.error(REQUIRED_ERROR);
                  return null;
                }
              } else {
                if (locationIDList.length == 0) {
                  Message.error(REQUIRED_ERROR);
                  return null;
                }
              }
            }

            setUploadedFileLoading(true);
            uploadedFile
              ? prepareUpload()
              : createServiceTicket({
                variables: {
                  title: title,
                  category: selectedOption.id,
                  subscription:
                    normalTicket && notNewCustomer ? selectedService.id : '',
                  country: countryData.id,
                  region: region.id,
                  ticketType: 'csocTicket',
                  description: details,
                  sites: preFilled
                    ? preFilledSites
                    : normalTicket && notNewCustomer
                      ? selectedSitesData
                      : [],
                  selectedCustomer: selectedCustomer,
                  incidentTypeGroup: incidentTypeGroup.name,
                  linkType: linkType.name,
                  natureOfCase: natureOfCase.name,
                  severity: severity.name,
                  incidentType: incidentType.name,
                  customers: preFilled
                    ? preFilledCustomer
                    : selectedCustomerData,
                  escalationLevel: escalationLevel.name,
                  projectCategory: projectCategory.name,
                  vendor: vendorData.name,
                  allCCEmails: allCCEmails,
                  assignee: assigneeData,
                  incidentTime: startDate,
                  vodacomProblem: vodacomProblem,
                  baseStation: baseStationData.id,
                  circuitId: selectedSites && selectedSites[0] && selectedSites[0].circuitId,
                  platform: selectedSites && selectedSites[0] && selectedSites[0].platform,
                  location: locationIDList && locationIDList.length > 0 ? locationIDList : [],
                  additionalAgent1: additionalAgent1.id,
                  additionalAgent2: additionalAgent2.id,
                  salesSpecilistData: salesSpecilistData.id,
                  incidentEndTime: endDate,
                  lusakaZMData: lusakaZMData.id,
                  ndolaZMData: ndolaZMData.id,
                  kitweZMData: kitweZMData.id,
                  livingStoneZMData: livingStoneZMData.id,
                  solweziZMData: solweziZMData.id,
                  lagosNGData: lagosNGData.id,
                  phcNGData: phcNGData.id,
                  abujaNGData: abujaNGData.id,
                  vendorStartDate: vendorStartDate,
                  reportComment: comment,
                  reportComment2: comment2,
                  reportComment3: comment3,
                  reportComment4: comment4,
                  reportComment5: comment5
                }
              });
          }}>
          {uploadedFileLoading ? (
            <CircularProgress size={14} />
          ) : (
            'Create Ticket'
          )}
        </Button>
      </Grid>
    </Layout>
  );
}

export default withTranslation()(NewServiceTicket);
