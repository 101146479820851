import React from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
  
function CustomMuiDataTable({ t, columns, data, loading, title = null }) {
    
    const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            border: "1px solid lightgray"
          },
          paper: {
            border: "1px solid lightgray",
            boxShadow: "none"
          }
        },
        MUIDataTableToolbar: {
          root: {
            borderBottom: "1px solid lightgray"
          }
        }
      },
    });

    const options = {
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive: 'standard',
        rowsPerPage: 15,
        download: false,
        print: false,
        viewColumns: false,
        selectableRowsHeader: false,
    };

    return (
        <Grid>
            {!loading ? 
            <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={title}
                data={data}
                t
                columns={columns}
                options={options}
            />
            </MuiThemeProvider>
            : <div style={{marginTop: 10}}><CircularProgress color="secondary" size={30} /></div>}
        </Grid>
    );
}

export default CustomMuiDataTable;