// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { ADD_PROJECT_MANAGEMENT_TEAM, UPDATE_PMO_PROJECT, UPLOAD_FILES, UPDATE_NIGERIA_QUALITY_ASSURANCE_TEAM } from "../../mutations";
import { GET_QUALITY_ASSURANCE_LIST, GET_CUSTOMER_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProjectManagementItem from "../ProjectManagementItem";
import { GET_ALL_USER_BY_ROLES } from '../../../../commonQueries';
import { GET_USER_INFO } from '../../../../Profile/queries';
import CreateQualityAssuranceTeamModal from "../CreateQualityAssuranceTeamModal";
import CreateProjectManagementTeamModal from "../CreateProjectManagementTeamModal";
import ProjectAttachmentModal from "../ProjectAttachmentModal";
import CompleteDetailModal from "../CompleteDetailModal";
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const ProjectManagement = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const countryId = localStorage.getItem('countryId');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [projectManagementTeam, setProjectManagementTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [preValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [customerListLoading, setCustomerListLoading] = useState(false);
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [UpdateTeam, setUpdateTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [CurrentTeamUserList, setCurrentTeamUserList] = useState({ open: false, btnLoading: false, clearData: false });
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&project_owner=is_admin"
                }
            })
        }
        getAllUserList({
            variables: {
                role: USER_ROLES.EP_FINANCE
            }
        })
        getAllCurrentUserTeamList({
            variables: {
                role: USER_ROLES.PROJECT_MANAGER
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
                getAllList({
                    variables: {
                        pageNumberOrUid: "project_owner=is_admin&page=1"
                    }
                })
            }
            // else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "project_management=" + CurrentUserName + "&page=1"
            //         }
            //     })
            // }
        }
    }, [refreshTab]);
    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        fetchPolicy: 'no-cache',
        onError: error => {
            console.log('Error get finance users list in PM team', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            let filterByCountryId = [];
            filterByCountryId = parseData.filter((x) => {
                if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                    return x.attributes.countryId[0] == countryId;
                }
            })
            setAllUserList(filterByCountryId);
        }
    });
    const [getAllCurrentUserTeamList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get chnage control users list in IM team', error);
        },
        onCompleted: data => {
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            let filterByCountryId = [];
            filterByCountryId = parseData.filter((x) => {
                if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                    return x.attributes.countryId[0] == countryId;
                }
            })
            setCurrentTeamUserList(filterByCountryId);
            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in PM team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            // if (role.includes(USER_ROLES.PROJECT_MANAGER) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&project_management=" + full_name
            //         }
            //     })
            // }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "project_owner=is_admin&page=" + value + checkSearchFilterData
                }
            })
        }
        // else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "project_management=" + CurrentUserName + "&page=" + value
        //         }
        //     })
        // }
    };
    const [getAllList] = useLazyQuery(GET_QUALITY_ASSURANCE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getQualityAssuranceTeamList) {
                let parseResult = JSON.parse(data.getQualityAssuranceTeamList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error quality list in PM team:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('circuitId.label'),
            key: "circuit_id"
        },
        // {
        //     label: t('pingResult.label'),
        //     key: "ping_result"
        // },
        {
            label: t('projectManagementTeam.label'),
            key: "project_management"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('project.label') + ' ' + t('status.label'),
            key: "status"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        // console.log("data project management : ", data);
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: (data.customer_name + ' / ' + data.organization_name) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('changeControlForm.label'), value: data.change_control_form, button: true },
            { label: t('installationMannual.label'), value: data.attach_manual, button: true },
            { label: t('configChangeForm.label'), value: data.config_change_form, button: true },
            { label: t('circuitId.label'), value: data.circuit_id, button: false },
            { label: t('equipmentsToBeDeployed.label'), value: data.equipments_to_be_deployed, button: false },
            { label: t('projectManagementTeam.label'), value: data.project_management, button: false },
            { label: t('baseStation.label'), value: data.basestation, button: false },
            { label: t('billing.label') + ' ' + t('date.label'), value: data.billing_date, button: false },
            { label: t('configuredDownCiruit.label'), value: data.configured_down_circuit, button: false },
            { label: t('configuredUpCircuit.label'), value: data.configured_up_circuit, button: false },
            { label: t('location.label'), value: data.location, button: false },
            { label: t('node.label'), value: data.node, button: false },
            { label: t('pingResult.label'), value: data.ping_result, button: false },
            { label: t('productCLass.label'), value: data.product_class, button: false },
            { label: t('bucketLevel.label'), value: data.bucket_level ? data.bucket_level : '', button: false },
            { label: t('vendors.label'), value: data.vendor ? data.vendor : '', button: false },
            { label: t('node.label') + ' ' + t('code.label'), value: data.node_code ? data.node_code : '', button: false },
            { label: t('product.label') + ' ' + t('description.label'), value: data.product_description ? data.product_description : '', button: false },
            { label: t('media.label') + ' ' + t('description.label'), value: data.media_description ? data.media_description : '', button: false },
            { label: t('service.label') + ' ' + t('description.label'), value: data.service_description ? data.service_description : '', button: false },
            { label: t('platform.label'), value: data.platform ? data.platform : '', button: false },
            { label: t('region.label'), value: data.region ? data.region : '', button: false },
            { label: t('city.label'), value: data.city ? data.city : '', button: false },
            { label: t('billing.label'), value: data.billing ? data.billing : '', button: false },
        ]
        setPreValuesOfForm(data);
        setCompleteDetails({ ...completeDetails, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
        handleProjectManagementTeam();
    }
    const [addProjectManagementTeam] = useMutation(ADD_PROJECT_MANAGEMENT_TEAM, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false, clearData: false });
            console.log('Error in add PM team', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: false });
            // console.log("data addProjectManagementTeam : ", data);
            if (JSON.parse(data.addProjectManagementTeamSection.status) === true) {
                Message.success('New project management team section created successfully');
                setModalState({ btnLoading: false, clearData: false });
                setLoading(true);
                handleRefreshTab(true);
                // let parseData = JSON.parse(data.addProjectManagementTeamSection.teamData);
                handleCloseProjectManagementTeam();
                // handleFinanceTeam();
            } else {
                let message = JSON.parse(data.addProjectManagementTeamSection.message);
                Message.error(message);
            }
        }
    });
    const handleSubmitProjectManagementTeam = (formData) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addProjectManagementTeam({
                variables: {
                    change_control_form: formData.changeControlForm,
                    circuit_id: formData.circuitId,
                    attach_manual: formData.attachMannual,
                    config_change_form: formData.configChangeForm,
                    ping_result: formData.pingResult,
                    send_email: formData.sendEmail,
                    finance_detail: formData.finance_detail,
                    job_completion_certificate: formData.jobCompletionCertificate,
                    fault_information_management_matrix: formData.faultInformationManagementMatrix,
                    letter_service_details: formData.letterServiceDetails,
                    billing_start_date: formData.billingStartDate,
                    comment: formData.comment,
                    project_uid: formData.project_uid,
                    finance: formData.finance,
                    previous_comment: formData.previous_comment,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                    auto_generated_project_id: formData.auto_generated_project_id,
                    finance_email: formData.finance_email,
                    bit_type: formData.bit_type,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    last_team_member_email: userData.userInfo.email,
                    end_date: formData.end_date,
                    occurrence: formData.occurrence,
                    billend_indicator: formData.billend_indicator,
                    charge_period: formData.charge_period,
                    prorated: formData.prorated,
                    network_code: formData.network_code,
                    supplier_base_cost: formData.supplier_base_cost,
                    charge_start_date: formData.charge_start_date,
                    charge_end_date: formData.charge_end_date,
                }
            })
        }
    }
    const [updatePmoProjectCategoryAndOnHold] = useMutation(UPDATE_PMO_PROJECT, {
        onError: error => {
            console.log('Error updatePmoProject', error);
        },
        onCompleted: data => {
            // console.log("data category on hold", data);
            if (data && data.updatePmoProject && data.updatePmoProject.status) {
                Message.success('Project updated successfully');
                setLoading(true);
                setError(true);
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
                    getAllList({
                        variables: {
                            pageNumberOrUid: "page=1&project_owner=is_admin"
                        }
                    })
                }
            }
        }
    });
    const handleProjectManagementTeam = () => {
        setProjectManagementTeam(true);
    }
    const handleCloseProjectManagementTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setProjectManagementTeam(false);
    }
    const [getCustomerList] = useLazyQuery(GET_CUSTOMER_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setCustomerListLoading(false);
            setError(false);
            if (data && data.getCustomerList) {
                let parseResult = JSON.parse(data.getCustomerList.teamListData);
                setCustomerList(parseResult);
            } else {
                setList([]);
            }
        },
        onError: error => {
            console.log("error in Customer List PM Team:", error)
            setCustomerList([])
            setCustomerListLoading(false);
            setError(false);
        }
    });
    const handleChangeCustomerList = (data) => {
        setCustomerListLoading(true);
        getCustomerList({
            variables: {
                search: "search=" + data
            }
        })
    }
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        if (UpdateTeam.open === true) {
            setUpdateTeam({ ...UpdateTeam, btnLoading: true, clearData: false });
        }
        //console.log("file in handle : ",file);
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false, clearData: false });
            if (UpdateTeam.open === true) {
                setUpdateTeam({ ...UpdateTeam, btnLoading: false, clearData: false });
            }
            console.log('Error upload files in PM team', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: false });
            if (UpdateTeam.open === true) {
                setUpdateTeam({ ...UpdateTeam, btnLoading: false, clearData: false });
            }
            //console.log('Response of uopload files', res);
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleViewCompleteDetailClick = () => {
        setCompleteDetails({ ...completeDetails, open: true });
    }
    const handleClickViewAllDetailsAndAttachment = (data) => {
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: (data.customer_name + ' / ' + data.organization_name) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('changeControlForm.label'), value: data.change_control_form, button: true },
            { label: t('installationMannual.label'), value: data.attach_manual, button: true },
            { label: t('configChangeForm.label'), value: data.config_change_form, button: true },
            { label: t('circuitId.label'), value: data.circuit_id, button: false },
            { label: t('equipmentsToBeDeployed.label'), value: data.equipments_to_be_deployed, button: false },
            { label: t('projectManagementTeam.label'), value: data.project_management, button: false },
            { label: t('baseStation.label'), value: data.basestation, button: false },
            { label: t('billing.label') + ' ' + t('date.label'), value: data.billing_date, button: false },
            { label: t('configuredDownCiruit.label'), value: data.configured_down_circuit, button: false },
            { label: t('configuredUpCircuit.label'), value: data.configured_up_circuit, button: false },
            { label: t('location.label'), value: data.location, button: false },
            { label: t('node.label'), value: data.node, button: false },
            { label: t('pingResult.label'), value: data.ping_result, button: false },
            { label: t('productCLass.label'), value: data.product_class, button: false },
            { label: t('bucketLevel.label'), value: data.bucket_level ? data.bucket_level : '', button: false },
            { label: t('vendors.label'), value: data.vendor ? data.vendor : '', button: false },
            { label: t('node.label') + ' ' + t('code.label'), value: data.node_code ? data.node_code : '', button: false },
            { label: t('product.label') + ' ' + t('description.label'), value: data.product_description ? data.product_description : '', button: false },
            { label: t('media.label') + ' ' + t('description.label'), value: data.media_description ? data.media_description : '', button: false },
            { label: t('service.label') + ' ' + t('description.label'), value: data.service_description ? data.service_description : '', button: false },
            { label: t('platform.label'), value: data.platform ? data.platform : '', button: false },
            { label: t('region.label'), value: data.region ? data.region : '', button: false },
            { label: t('city.label'), value: data.city ? data.city : '', button: false },
            { label: t('billing.label'), value: data.billing ? data.billing : '', button: false },
        ]
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        }
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&project_owner=is_admin"
                }
            })
        }
    };
    const handleSelectProjectStatus = (project_uid, data) => {
        if (project_uid != "" && data != "") {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: project_uid,
                    status: data,
                }
            });
        }
    }

    const handleSelectProjectCategory = (project_uid, data) => {
        if (project_uid != "" && data != "") {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: project_uid,
                    category: data,
                }
            });
        }
    }
    const handleEditTeamClick = (data) => {
        setPreValuesOfForm(data);
        setUpdateTeam({ open: true, btnLoading: false, clearData: true });
    }
    const handleSubmitUpdateTeam = (formData) => {
        setUpdateTeam({ ...modalState, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        formData.project_uid = formData.formUid;
        updateQualityControlTeam({
            variables: {
                change_control_form: formData.changeControlForm,
                circuit_id: formData.circuitId,
                attach_manual: formData.attachMannual,
                config_change_form: formData.configChangeForm,
                ping_result: formData.pingResult,
                quality_assurance: formData.qualityAssuarance,
                project_management: formData.projectManagement,
                attach: formData.attachDocument,
                project_uid: formData.project_uid,
                comment: formData.comment,
                previous_comment: formData.previous_comment,
                equipments_to_be_deployed: formData.equipments_to_be_deployed,
                auto_generated_project_id: formData.auto_generated_project_id,
                documents: formData.documents,
                basestation: formData.basestation,
                location: formData.location,
                configured_up_circuit: formData.configured_up_circuit,
                configured_down_circuit: formData.configured_down_circuit,
                product_class: formData.product_class,
                node: formData.node,
                status: formData.status,
                billing_date: formData.billing_date,
                bucket_level: formData.bucket_level,
                vendor: formData.vendor,
                node_code: formData.node_code,
                product_description: formData.product_description,
                media_description: formData.media_description,
                platform: formData.platform,
                service_description: formData.service_description,
                region: formData.region,
                city: formData.city,
                billing: formData.billing,
                next_team_member_email: formData.next_team_member_email,
                last_team_member_name: CurrentUserName,
                last_team_member_email: userData.userInfo.email,
            }
        })
    }
    const [updateQualityControlTeam] = useMutation(UPDATE_NIGERIA_QUALITY_ASSURANCE_TEAM, {
        onError: error => {
            setUpdateTeam({ ...UpdateTeam, btnLoading: false });
            console.log('Error in update IM form', error);
            setError(true);
        },
        onCompleted: data => {
            setUpdateTeam({ ...UpdateTeam, btnLoading: false });
            // console.log("data updateQualityControlTeam : ", data);
            if (data && data.updateNigeriaQualityAssuranceTeam) {
                if (JSON.parse(data.updateNigeriaQualityAssuranceTeam.status) === true) {
                    handleRefreshTab(true);
                    Message.success('Quality assurance team section updated successfully');
                    // let parseData = JSON.parse(data.updateNigeriaQualityAssuranceTeam.teamData);
                    setUpdateTeam({ open: false, btnLoading: false, clearData: true });
                    // handleCsocTeam();
                } else {
                    let message = JSON.parse(data.updateNigeriaQualityAssuranceTeam.message);
                    Message.error(message);
                }
            }
        }
    });
    return (
        <>
            <Header title={t('projectManagementTeamSection.label')} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <ProjectManagementItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName} handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleEditTeamClick={handleEditTeamClick} handleSelectProjectCategory={handleSelectProjectCategory} handleSelectProjectStatus={handleSelectProjectStatus} handleClickViewAllDetailsAndAttachment={handleClickViewAllDetailsAndAttachment} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateProjectManagementTeamModal
                    t={t}
                    open={projectManagementTeam}
                    handleSubmitClick={handleSubmitProjectManagementTeam}
                    CurrentUserName={CurrentUserName}
                    handleClose={handleCloseProjectManagementTeam}
                    modalState={modalState}
                    preValuesOfForm={preValuesOfForm}
                    selectedProjectTypeAndUid={{}}
                    showPreValueOfForm={true}
                    handleUploadFiles={handleUploadFiles}
                    allUserList={allUserList}
                    handleViewCompleteDetailClick={handleViewCompleteDetailClick}
                    handleProjectAttachmentClick={handleProjectAttachmentClick}
                    isActionUpdate={false}
                    customerList={customerList}
                    customerListLoading={customerListLoading}
                    handleChangeCustomerList={handleChangeCustomerList} />
                <CreateQualityAssuranceTeamModal
                    t={t}
                    open={UpdateTeam.open}
                    handleSubmitClick={handleSubmitUpdateTeam}
                    handleUploadFiles={handleUploadFiles}
                    preValuesOfForm={preValuesOfForm}
                    CurrentUserName={CurrentUserName}
                    handleViewCompleteDetailClick={handleViewCompleteDetailClick}
                    handleProjectAttachmentClick={handleProjectAttachmentClick}
                    handleClose={() => {
                        setUpdateTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    isActionUpdate={true}
                    modalState={UpdateTeam}
                    allUserList={CurrentTeamUserList} />
                <ProjectAttachmentModal
                    open={ProjectAttachment.open}
                    t={t}
                    handleClose={() => {
                        setProjectAttachment({ open: false, data: [] });
                    }}
                    attachmentList={ProjectAttachment.data}
                />
                <CompleteDetailModal
                    open={completeDetails.open}
                    t={t}
                    handleClose={() => {
                        setCompleteDetails({ ...completeDetails, open: false });
                    }}
                    data={completeDetails.data}
                    loading={completeDetails.loading}
                    previous_comment={completeDetails.previous_comment}
                    documents={completeDetails.documents}
                    projectDetail={completeDetails.projectDetail}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(ProjectManagement);
