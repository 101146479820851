import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import Message from '../../components/Message';
import { ADD_PRODUCT_TO_TICKET } from './mutations';




function ProductList({ticketData}) {

    console.log(ticketData, "ticketData")
const [AddProductToTicket] = useMutation(ADD_PRODUCT_TO_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
   
      Message.success('Product Successfully Added- to Ticket.');
    }
  });

  const [selectedProduct, setSelectedProduct] = useState('');


  useEffect(()=>{


   if(selectedProduct){
    AddProductToTicket({
        variables: {
          ticketId: ticketData.id,
          product:selectedProduct
        }
      });
   }

  },[selectedProduct])
  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const productOptions = [
    'Software Development',
    'DocAi',
    'Edge Ai',
    'Fabric',
    'Vision Ai',
    'Connectivity',
    'Sales',
    'Elastic',
    'SD-WAN',
    'Edge Ai IOT',
    'SDN / NFV for Cloud Edge',
    'Digital Solutions',
    'Automation',
    'Declarative Ai'
  ];

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="product-select">Choose Ticket's Product</InputLabel>
      <Select
        label="Select Product"
        value={selectedProduct}
        onChange={handleProductChange}
        labelId="product-select-label"
        id="product-select"
      >
        {productOptions.map((product, index) => (
          <MenuItem key={index} value={product}>
            <Typography>{product}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ProductList;
