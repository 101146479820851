import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import "./CanvasSignaturePad.css";
import { Button, Grid } from '@material-ui/core';

const CanvasSignaturePad = ({
  handleSaveSignature,
  open,
  handleClose
}) => {
  
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();

  const save = () => {
    handleSaveSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogContent style={{overflowY: "visible"}}>
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{
            className: "signatureCanvas"
          }}
        />
        <Grid container style={{marginTop: 20, textAlign: "right"}}>
          <Button style={{marginRight: 15}} variant="contained" color="secondary" onClick={() => { 
            clear();
            handleClose();
          }}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={clear} style={{marginRight: 15}}>Clear</Button>
          <Button variant="contained" color="primary" onClick={save}>Save</Button> 
        </Grid>
      </DialogContent>

    </Dialog>
  )
}

export default CanvasSignaturePad