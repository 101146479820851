import React from 'react';
import OcrProcessListing from './OcrProcessListing';

const OcrProcessesPage = ({
    t
}) => {
  return (
    <>
      <OcrProcessListing
        t={t}
      />
    </>
  )
}

export default OcrProcessesPage