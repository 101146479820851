import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { marginGenerator } from '../../../../theme/utils';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TicketStatus from '../TicketStatus';
import TicketPriority from '../TicketPriority';
import { GET_ORGANISATION_NAME } from '../../../TicketingSystem/queries';
import PropTypes from 'prop-types';
import Avatar from '../../../../components/Avatar';
import AdjustIcon from '@material-ui/icons/Adjust';
import AttachFile from '@material-ui/icons/AttachFile';
import commonStyles from '../../../../theme/commonStyles';
import { fade, Icon } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
  fright: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageAlign: {
    width: '200px',
    height: 'auto'
  }
}));

const TicketDetailsPanel = ({ ticketData, t }) => {
  const [organisationName, setOrganisationName] = React.useState();
  const classes = useStyles();
  React.useEffect(() => {
    if (ticketData.ownerId) {
      getOrgName({
        variables: {
          ownerId: ticketData.ownerId
        }
      });
    }
  }, []);

  const [getOrgName] = useLazyQuery(GET_ORGANISATION_NAME, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.getOrgName) {
        setOrganisationName(data.getOrgName.name);
      }
    }
  });
  return (
    <Grid container justify={'space-between'}>
      <Grid container item xs={8}>
        <Grid item style={marginGenerator('mr-32')}>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mb-16')}>
            {t('status.label')}
          </Typography>
          <TicketStatus t={t} status={ticketData.status} />
        </Grid>
        <Grid item>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mb-16')}>
            {t('priority.label')}
          </Typography>
          <TicketPriority t={t} priority={ticketData.priority} />
        </Grid>
        <Grid
          container
          item
          direction={'column'}
          style={marginGenerator('mt-32')}>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mb-16')}>
            {t('description.label')}
          </Typography>
          <Typography variant={'body2'}>{ticketData.description}</Typography>
        </Grid>
        <Grid
          container
          item
          direction={'column'}
          style={marginGenerator('mt-32')}>
          <Typography variant={'subtitle2'} color={'textPrimary'}>
            {t('attachments.label')}
          </Typography>
          <Grid style={marginGenerator('mt-16')}>
            {ticketData.attachments &&
              ticketData.attachments.map((item, index) => (
                <Grid
                  key={index}
                  container
                  item
                  alignItems={'center'}
                  direction={'row'}
                  style={marginGenerator('mb-8')}>
                  <AttachFile
                    className={classes.iconRegular}
                    style={marginGenerator('mr-16')}
                  />
                  <Link
                    variant={'body2'}
                    color={'textPrimary'}
                    href={item}
                    target={'_blank'}>
                    <img
                      className={classes.imageAlign}
                      src={item}
                      href={item}
                      target="_blank"
                    />
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={3} direction={'column'}>
        {ticketData.ownerId ? (
          <Grid item style={marginGenerator('mb-32')}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('ownerId.label')}
            </Typography>
            <Grid
              container
              item
              direction={'row'}
              alignItems={'center'}
              justify={'flex-start'}>
              <div
                style={{
                  backgroundColor: fade(colors.secondary.main, 0.1),
                  height: '24px',
                  width: '24px',
                  ...marginGenerator('mr-8'),
                  justifyContent: 'center'
                }}
                className={classes.column}>
                <Icon
                  component={() => (
                    <AdjustIcon
                      className={classes.iconRegular}
                      color={'secondary'}
                    />
                  )}
                  alt="location"
                />
              </div>
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {organisationName}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {ticketData.subscription &&
        ticketData.subscription.service &&
        ticketData.subscription.service.name ? (
          <Grid item style={marginGenerator('mb-32')}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('subscription.label')}
            </Typography>
            <Grid
              container
              item
              direction={'row'}
              alignItems={'center'}
              justify={'flex-start'}>
              <Avatar
                name={ticketData.subscription.service.name}
                variant={'medium'}
                avatarStyles={{
                  borderRadius: 0,
                  background: fade(colors.info.main, 0.1)
                }}
                identifierStyle={{
                  color: colors.info.main,
                  fontSize: '14px',
                  fontWeight: 500
                }}
              />
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {ticketData.subscription.service.name}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {ticketData.sites && ticketData.sites.length ? (
          <Grid item style={marginGenerator('mb-32')}>
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {t('site.label')}
            </Typography>
            {ticketData.sites.map((item, index) => (
              <Grid
                container
                key={index}
                style={marginGenerator('mt-16')}
                item
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}>
                <div
                  style={{
                    backgroundColor: fade(colors.secondary.main, 0.1),
                    height: '24px',
                    width: '24px',
                    ...marginGenerator('mr-8'),
                    justifyContent: 'center'
                  }}
                  className={classes.column}>
                  <Icon
                    component={() => (
                      <LocationOn
                        className={classes.iconRegular}
                        color={'secondary'}
                      />
                    )}
                    alt="location"
                  />
                </div>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : null}
        <Grid item>
          <Typography
            variant={'subtitle2'}
            color={'textPrimary'}
            style={marginGenerator('mb-16')}>
            {t('createdBy.label')}
          </Typography>
          <Grid
            container
            item
            direction={'row'}
            alignItems={'center'}
            justify={'flex-start'}>
            <Avatar name={ticketData.createdByName} variant={'medium'} />
            <Typography variant={'subtitle2'} color={'textPrimary'}>
              {ticketData.createdByName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TicketDetailsPanel.propTypes = {
  ticketData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default TicketDetailsPanel;
