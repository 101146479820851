import React, { useState } from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { NOTIFICTION_PROCESS } from '../../mutations';
import { GET_NOTIFICTION_PROCESS_STEPS } from '../../queries';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TableHead, Fab } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import commonStyles from '../../../../theme/commonStyles';
import PlacesWithMap from '../PlacesWithMap';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Icon from '@material-ui/core/Icon';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import PriorityPanel from '../PriorityPanel';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import StepsForProcessAgreeAction from './StepsForProcessAgreeAction';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import { dateFormatterLLLHHMMSS, dateFormatterWithTimeZoneLLLHHMMSS,convertLocalToUTCDate, renderEdgeAITags } from '../../../../utils';
import Message from '../../../../components/Message';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import TextField from '@material-ui/core/TextField';
import { AI_MODEL_LIST_TYPES } from "../../../../utils/constants";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EventsVideoPlayBackModal from './EventsVideoPlayBackModal';
import VideocamIcon from '@material-ui/icons/Videocam';
import Autocomplete from '../../../../components/Autocomplete';
import ProceedStepsList from './ProceedStepsList';
import videoIcon from '../../../../assets/icons/video-icon.png';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: { marginRight: '6px', marginLeft: '6px' },
    chartTitle: { marginTop: '10px' },
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function NotificationEventsPanel({
    t,
    organization,
    list,
    filterList,
    loading,
    filterLoading,
    handleImageModalClick,
    handleSelectNotificationRow,
    selectedNotificationRow,
    setSelectNotificationRow,
    handleAcknowledgeNotification,
    handleShowEventTags,
    page,
    handlePageChange,
    showTagsFilter,
    tagList,
    handleSearchByTag,
    clearTagFilter,
    modelType,
    reloadNotificationReportFunc,
    statusFilter,
    processStepsList
}) {

    const classes = useStyles();
    const columns = [
        {
            label: t('places.label'),
            key: "place"
        },
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('eventDateTime.label'),
            key: "eventDateTime"
        },
        {
            label: t('priority.label'),
            key: "priority"
        },
        {
            label: t('tags.label'),
            key: "tags"
        },
        {
            label: t('proofSnap.label'),
            key: "imageSnap"
        },
        {
            label: t('video.label'),
            key: "video"
        },
        {
            label: t('process.label'),
            key: "acknowledge"
        }
    ];

    const commonClasses = commonStyles();
    const [searchTag, setSearchTag] = useState("");
    const [tagFilterField, setTagFilterField] = useState(null);
    const [tagInputValue, setTagInputValue] = useState("");
    const [isMapEnable, setIsMapEnable] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [locationState, setLocationState] = useState({ latitude: '', longitude: '' });
    const [isProcessDialogEnable, setIsProcessDialogEnable] = useState(false);
    const [openVideoPlayBackModal, setOpenVideoPlayBackModal] = useState(false);
    const [processActionName, setProcessActionName] = useState(t('process.label'));
    const [processComments, setProcessComments] = useState("");
    const [rowIdForProcess, setRowIdForProcess] = useState(null);
    const [rowComments, setRowComments] = useState(null);
    const [isStepperEnable, setIsStepperEnable] = useState(false);
    const [processLoading, setProcessLoading] = useState(false);
    const [processStepLoading, setProcessStepLoading] = useState(false);
    const [goToPage, setGoToPage] = useState(null);
    const [proceedTime, setProceedTime] = useState(null);
    const [selectedProcessItem, setSelectedProcessItem] = useState(null);
    const [timeZoneData, setTimeZoneData] = useState('');


    const [processAction] = useMutation(NOTIFICTION_PROCESS, {
        onError: error => {
            setProcessLoading(false);
            setProcessStepLoading(false);
        },
        onCompleted: data => {
            Message.success('Process action complete successfully.');
            setSelectedProcessItem(null);
            reloadNotificationReportFunc();
            stepperhandleClose();
            processhandleClose();
            setProcessLoading(false);
            setProcessStepLoading(false);
        }
    });

    const handleChangeSearchTag = (evt) => {
        let value = evt.target.value;
        setSearchTag(value);
        if (tagList && tagList.length) {
            let selectedTag = tagList.filter(item => {
                if (item.id === value) {
                    return item;
                }
            });
            if (selectedTag.length) {
                setTagFilterField(selectedTag[0]);
            }
        }
        if (modelType === "license_plate_recognition" && (value === 'blacklisted' || value === 'whitelisted' || value === 'unknown')) {
            setTagFilterField({ field: { type: 'text', label: "plateNo.label" }, id: value });
        }
    }

    const handleSetPositionBase = pos => {
        setLocationState({ latitude: pos.latitude, longitude: pos.longitude });
    };

    const handleClose = () => {
        setIsMapEnable(false);
    }

    const processhandleClose = () => {
        setIsProcessDialogEnable(false);
    };

    const stepperhandleClose = () => {
        setIsStepperEnable(false);
        setSelectedProcessItem(null);
    };

    const processhandleSubmit = actionValue => {
        setProcessLoading(true);
        if (actionValue === 'disagree') {
            return processAction({
                variables: {
                    orgId: organization,
                    id: rowIdForProcess,
                    comments: processComments,
                    status: actionValue,
                    isAcknowledged: true,
                    cameraId: (selectedProcessItem && selectedProcessItem.camera) ? selectedProcessItem.camera.camera_id.toString() : "",
                    proceedTime: convertLocalToUTCDate(new Date())
                }
            });
        } else {
            if (processStepsList.length > 0) {
                setProceedTime(new Date());
                processhandleClose();
                setIsStepperEnable(true);
            } else {
                return processAction({
                    variables: {
                        orgId: organization,
                        id: rowIdForProcess,
                        comments: processComments,
                        status: "agree",
                        isAcknowledged: true,
                        cameraId: (selectedProcessItem && selectedProcessItem.camera) ? selectedProcessItem.camera.camera_id.toString() : "",
                        proceedTime: convertLocalToUTCDate(new Date())
                    }
                });
            }
        }
    };

    const handleSubmitProcessFlow = (stepsFormData) => {
        setProcessStepLoading(true);
        if (stepsFormData && stepsFormData.length) {
            return processAction({
                variables: {
                    orgId: organization,
                    id: rowIdForProcess,
                    comments: processComments,
                    status: "agree",
                    isAcknowledged: true,
                    processSteps: stepsFormData,
                    cameraId: (selectedProcessItem && selectedProcessItem.camera) ? selectedProcessItem.camera.camera_id.toString() : "",
                    proceedTime: convertLocalToUTCDate(proceedTime)
                }
            });
        }
        return null;
    }

    const [getNotificationProceedSteps, { loading: proceedStepsLoading, data: proceedStepsData }] = useLazyQuery(GET_NOTIFICTION_PROCESS_STEPS, {
        fetchPolicy: "no-cache"
    });

    React.useEffect(() => {
        setSearchTag("");
        setTagFilterField(null);
        setTagInputValue("");
    }, [clearTagFilter]);

    React.useEffect(() => {
        const timeDetails = localStorage.getItem('timeZoneDetails');

        if(timeDetails){
         setTimeZoneData(timeDetails)
        }
     }, []);
     
    return (
        <div>
            {filterList &&
                filterList.map((filter, index) => {
                    if (filter.type && filter.type == 'input') {
                        return (
                            <DateTimeSelector key={index} inputStyle={{ padding: 10, minWidth: 225 }} label={filter.label} dateFormat={"yyyy-MM-dd HH:mm"} value={filter.val} handleChange={filter.handleChange} styleOverrides={filter.styleOverrides} className={filter.className ? filter.className : null} disableFuture={true} />
                        )
                    } else if (filter.type && filter.type === "autocomplete") {
                        return (
                            <Autocomplete
                                key={index}
                                styleOverrides={filter.styleOverrides || null}
                                handleChange={filter.handleChange}
                                options={filter.options}
                                label={filter.label}
                                size="small"
                                onInputChange={filter.onInputChange}
                                handleClose={filter.handleClose ? filter.handleClose : undefined}
                            />
                        );
                    } else {
                        return (
                            <OutlinedSelect
                                key={index}
                                val={filter.val}
                                label={filter.label}
                                handleChange={filter.handleChange}
                                IconComponent={filter.iconComponent || null}
                                options={filter.options}
                                styleOverrides={filter.styleOverrides || null}
                                disabled={filter.disabled || false}
                                multiple={filter.label === t('modelFilter.label') ? true : false}
                            />
                        )
                    }
                })
            }
            <>
                {showTagsFilter && tagList.length > 0 &&
                    <OutlinedSelect
                        val={searchTag}
                        label={t("searchByTags.label")}
                        handleChange={handleChangeSearchTag}
                        styleOverrides={{ minWidth: '172px', marginTop: 20 }}
                        options={(modelType === "license_plate_recognition") ? [...tagList, ...AI_MODEL_LIST_TYPES] : [...tagList]}
                    />
                }
                {searchTag && tagFilterField &&
                    <React.Fragment>
                        {(tagFilterField.field.type !== 'select') ?
                            <Input
                                value={tagInputValue}
                                type={tagFilterField.field.type}
                                placeholder={t(tagFilterField.field.label)}
                                style={{ padding: 9, marginTop: 20, minWidth: '172px', maxWidth: '172px' }}
                                color={'primary'}
                                onChange={evt => setTagInputValue(evt.target.value)}
                            />
                            :
                            <OutlinedSelect
                                val={tagInputValue}
                                label={t(tagFilterField.field.label)}
                                handleChange={evt => setTagInputValue(evt.target.value)}
                                styleOverrides={{ minWidth: '172px', marginTop: 20 }}
                                options={tagFilterField.field.options ? tagFilterField.field.options : []}
                            />
                        }
                        <Button
                            style={{ marginTop: tagFilterField.field.type === 'select' ? 20 : 0, ...paddingGenerator('pt-11'), ...paddingGenerator('pb-9'), marginRight: 15, marginLeft: 15 }}
                            variant={"contained"}
                            color={'primary'}
                            onClick={evt => handleSearchByTag({ [searchTag]: tagInputValue })}
                        >
                            {t('search.label')}
                        </Button>
                    </React.Fragment>
                }
                <Button
                    style={{ ...paddingGenerator('pt-10'), ...paddingGenerator('pb-8'), ...marginGenerator('mt-20'), float: "right" }}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => {
                        handleAcknowledgeNotification('multiple');
                        setSelectNotificationRow({});
                    }}>
                    {t('acknowledge.label')}
                </Button>
            </>
            <Grid container style={{ ...marginGenerator('mt-24') }}>
                <TableContainer>
                    <Table>
                        <TableHead> 
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))} 
                                {['agree', 'disagree'].includes(statusFilter) && <TableCell key={"processedBy"}>
                                    <Typography variant={'subtitle2'}>{t("processedBy.label")}</Typography>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !filterLoading ?
                                list.notifications && list.notifications.length > 0 ?
                                    list.notifications.map((rowitem, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell key={rowIndex}>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <Checkbox
                                                            disabled={rowitem.is_acknowledged}
                                                            checked={selectedNotificationRow[rowitem.id]}
                                                            value={rowitem.id}
                                                            onChange={(event) => {
                                                                handleSelectNotificationRow(event, rowitem.id);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        <Icon
                                                            component={() => (
                                                                <LocationOnIcon
                                                                    color={'secondary'}
                                                                    style={{ marginTop: '2px', marginRight: '6px' }}
                                                                />
                                                            )}
                                                            alt={'icon'}
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        <Typography
                                                            variant={'subtitle1'}
                                                            onClick={() => {
                                                                let placeCord = null;
                                                                if (rowitem.place && rowitem.place.lat && rowitem.place.lng) {
                                                                    placeCord = { latitude: rowitem.place.lat, longitude: rowitem.place.lng }
                                                                } else if (rowitem.camera && rowitem.camera.place && rowitem.camera.place.lat && rowitem.camera.place.lng) {
                                                                    placeCord = { latitude: rowitem.camera.place.lat, longitude: rowitem.camera.place.lng }
                                                                }
                                                                if (placeCord) {
                                                                    setLocationState(placeCord);
                                                                    setIsMapEnable(true);
                                                                }
                                                                return null;
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {rowitem.place ? rowitem.place.name : (rowitem.camera && rowitem.camera.place) ? rowitem.camera.place.name : "-"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <Typography variant={'subtitle1'}> {rowitem.camera.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell> 
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <Typography variant={'subtitle1'}>
                                                            {/* {dateFormatterLLLHHMMSS(rowitem.created_at)} */}
                                                            {dateFormatterWithTimeZoneLLLHHMMSS(rowitem.created_at.slice(0, -1), timeZoneData)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            {/* <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'}>
                                                        {rowitem.model_type}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell> */}
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <PriorityPanel priority={rowitem.priority == 1 ? 'P1' : rowitem.priority == 2 ? 'P2' : 'P3'} t={t} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                if (Object.keys(rowitem.tags).toString() === 'type') {
                                                                    Message.error('No Details Availble')
                                                                } else {
                                                                    handleShowEventTags(rowitem.tags);
                                                                }
                                                            }}
                                                            style={{width: 120, overflow: "hidden"}}
                                                        >
                                                            {renderEdgeAITags(rowitem.tags)}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <img
                                                        onClick={(evt) => {
                                                            handleImageModalClick(rowitem.image_url);
                                                        }}
                                                        src={rowitem.image_url ? rowitem.image_url.includes("mp4") ? videoIcon : rowitem.image_url : ""}
                                                        alt={'event'}
                                                        style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: "50%" }}
                                                    />
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Button color="primary" onClick={() => {
                                                        setSelectedRow(rowitem);
                                                        setOpenVideoPlayBackModal(true);
                                                    }}>
                                                        <Icon
                                                            component={() => (
                                                                <VideocamIcon
                                                                    color={'secondary'}
                                                                    style={{ marginTop: '2px', marginRight: '6px' }}
                                                                />
                                                            )}
                                                            alt={'icon'}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={commonClasses.column}>
                                                    <Grid>
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'primary'}
                                                            onClick={() => {
                                                                setProcessLoading(false);
                                                                setProcessComments(rowitem.comments);
                                                                if (['agree', 'disagree'].includes(rowitem.status)) {
                                                                    setRowComments(true);
                                                                } else {
                                                                    setRowComments(false);
                                                                }
                                                                console.log("rowitem: ", rowitem);
                                                                setRowIdForProcess(rowitem.id);
                                                                setIsProcessDialogEnable(true);
                                                                setSelectedProcessItem(rowitem);
                                                                if (rowitem.status === "agree") {
                                                                    return getNotificationProceedSteps({
                                                                        variables: {
                                                                            id: rowitem.id,
                                                                            orgId: organization
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {rowitem.status ? rowitem.status : processActionName}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            {['agree', 'disagree'].includes(statusFilter) && <TableCell key={"processedBy"}>
                                                <Typography variant={'subtitle2'}>{rowitem.proceed_by_name ? rowitem.proceed_by_name : '-'}</Typography>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                    )
                                    :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record Not Found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                                        <CircularProgress color="secondary" />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    {!loading &&
                        <>
                            <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Grid container item xs={2}>
                                    <span>Total {Math.ceil(get(list, 'count', null) / 10)} page,  {get(list, 'count', null)} Row</span>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Pagination
                                        color="secondary"
                                        count={Math.ceil(list.count / 10)}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                </Grid>
                                <Grid container item xs={1}>
                                    <span>Go to page </span>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Input
                                        value={goToPage}
                                        type={'text'}
                                        style={{ width: '10%' }}
                                        color={'secondary'}
                                        onChange={evt => setGoToPage(evt.target.value)}
                                    />
                                    <Button
                                        onClick={() => handlePageChange('goto', parseInt(goToPage))}
                                        variant={'outlined'}
                                        color="primary"
                                        style={{ marginLeft: 10 }}
                                    >
                                        Go
                                    </Button>
                                </Grid>
                            </Grid>
                        </>}
                </TableContainer>
            </Grid>
            <PlacesWithMap handleSetPositionBase={handleSetPositionBase} isMapEnable={isMapEnable} handleClose={handleClose} locationState={locationState} />
            {selectedRow &&
                <EventsVideoPlayBackModal
                    open={openVideoPlayBackModal}
                    handleClose={evt => {
                        setOpenVideoPlayBackModal(false);
                        setSelectedRow(null);
                    }}
                    data={selectedRow} />
            }
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isProcessDialogEnable}
                onClose={processhandleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" onClose={processhandleClose}>
                    {t('processAction.label')}
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContain}>
                    <TextField
                        value={processComments}
                        disabled={rowComments ? true : false}
                        onChange={evt => setProcessComments(evt.target.value)}
                        id="standard-multiline-flexible"
                        rows={2}
                        placeholder={t('comments.label')}
                        style={{ width: '100%' }}
                        variant="standard"
                        multiline
                        name="comments"
                    />
                    {rowComments &&
                        <Grid container style={{ marginTop: 15 }}>
                            <ProceedStepsList t={t} steps={proceedStepsData && proceedStepsData.getNotificationProceedSteps} loading={proceedStepsLoading} />
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    {rowComments
                        ?
                        <Button
                            onClick={processhandleClose}
                            color="primary"
                            autoFocus
                        >
                            Exit
                        </Button>
                        :
                        <>
                            <Button
                                onClick={() => processhandleSubmit('disagree')}
                                color="secondary"
                                variant="outlined"
                                disabled={processLoading}
                            >
                                {!processLoading ? t('disagree.label') : <CircularProgress size={20} color="secondary" />}
                            </Button>
                            <Button
                                onClick={() => processhandleSubmit('agree')}
                                color="primary"
                                autoFocus
                                variant="outlined"
                                disabled={processLoading}
                            >
                                {!processLoading ? t('agree.label') : <CircularProgress size={20} color="secondary" />}
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            {isStepperEnable &&
                <StepsForProcessAgreeAction
                    t={t}
                    open={isStepperEnable}
                    onClose={stepperhandleClose}
                    stepData={processStepsList}
                    btnLoading={processStepLoading}
                    handleSubmit={handleSubmitProcessFlow}
                />
            }
        </div>
    );
}

NotificationEventsPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    filterList: PropTypes.array.isRequired
};
export default NotificationEventsPanel;