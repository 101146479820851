import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateRadioEngineeringTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    selectedProjectTypeAndUid,
    CurrentUserName
}) {
    const classes = useStyles();
    const [projectFiles, setProjectFiles] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const [installTeam, setInstallTeam] = useState('');
    const [comment, setComment] = useState('');
    const nextTeamList = [
        {
            id: 'Team_1',
            name: "Team 1"
        },
        {
            id: 'Team_2',
            name: "Team 2"
        },
        {
            id: 'Team_3',
            name: "Team 3"
        }
    ];
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState, CurrentUserName]);

    const convertToBase64ProjectFiles = (img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            let splitPdf = reader.result.split(',');
            setProjectFiles(splitPdf[1]);
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }
    const convertToBase64Recommendation = (img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            let splitPdf = reader.result.split(',');
            setRecommendation(splitPdf[1]);
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('RadioTeamEngineeringSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('projectFile.label')} *</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                type={'file'}
                                placeholder={t('clickHereToViewFiles.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => convertToBase64ProjectFiles(evt.target.files[0])}
                            />
                            {/* <Button variant={'outlined'}
                                color={'primary'}
                                onClick={() => {                                    
                                }}>{t('clickHereToViewFiles.label')}</Button> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('recommendation.label')} *</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                type={'file'}
                                placeholder={t('recommendation.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => convertToBase64Recommendation(evt.target.files[0])}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Typography variant="subtitle2">{t('installTeam.label')}</Typography> */}
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <OutlinedSelect
                                label={`${t('installTeam.label')} *`}
                                handleChange={evt => {
                                    setInstallTeam(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={AllInstallTeamUserList ? AllInstallTeamUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!projectFiles || !recommendation || !installTeam) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let previous_comment = [];
                            if (comment != '') {
                                previous_comment = [{ name: CurrentUserName, comment: comment }]
                            }
                            handleSubmitClick({
                                projectFiles: projectFiles,
                                recommendation: recommendation,
                                installTeam: installTeam,
                                comment: comment,
                                formName: 'radioEngineeringForm',
                                project_uid: selectedProjectTypeAndUid.uid,
                                previous_comment: JSON.stringify(previous_comment)
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateRadioEngineeringTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateRadioEngineeringTeamModal);
