import React, { useState } from 'react';
import { CreateAccountPaths } from '../../routes/routePaths';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { marginGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Message from '../../components/Message';
import { REQUIRED_ERROR } from '../../utils/constants';
import { CREATE_ORGANIZATION } from './mutations';
import SecondaryLayout from './components/SecondaryLayout';
import { colors } from '../../theme/colors';
import OutlinedSelect from '../../components/OutlinedSelect';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';

function CreateOrganization({ t }) {
  const history = useHistory();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');

  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if(data.createOrganization && data.createOrganization.id === "false"){
        Message.error(data.createOrganization.name);
        return null;
      }else{
        Message.success('Organization created successfully.');
        history.push({ pathname: CreateAccountPaths.CreateUser, search: name });
      }
    }
  });

  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);

  return (
    <SecondaryLayout
      contentStyle={{ maxWidth: '468px', ...marginGenerator('mt-162') }}>
      <Header
        title={t('createAccount.label')}
        titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
        subText={t('createAccountStep1.label')}
        subtitleVariant={'h2'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0 }}
      />
      <Header
        title={t('organizationDetails.label')}
        containerStyle={marginGenerator('mt-16')}
        titleStyleOverride={{ fontSize: '2rem', padding: 0 }}
        subText={t('organizationDetailsSub.label')}
        subtitleVariant={'h2'}
        subtitleStyleOverride={{
          color: colors.grey[400],
          padding: 0,
          fontWeight: 'normal'
        }}
      />
      <Grid style={{ ...marginGenerator('mt-24') }}>
        <Input
          value={name}
          type={'text'}
          placeholder={`${t('orgName.label')} *`}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setName(evt.target.value)}
        />
        <Input
          value={address}
          type={'text'}
          placeholder={`${t('address.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-24') }}
          color={'secondary'}
          onChange={evt => setAddress(evt.target.value)}
        />
        <Grid
          container
          justify={'space-between'}
          item
          style={marginGenerator('mt-24')}>
          <OutlinedSelect
            val={country}
            label={`${t('country.label')} *`}
            handleChange={evt => {
              getRegionList({ variables: { countryId: evt.target.value } });
              setRegion('');
              setCountry(evt.target.value);
            }}
            styleOverrides={{ width: '220px', marginRight: 0 }}
            options={countryList ? countryList.getCountries : []}
          />
          <OutlinedSelect
            val={region}
            disabled={!country}
            label={`${t('region.label')} *`}
            handleChange={evt => {
              setRegion(evt.target.value);
            }}
            styleOverrides={{ width: '220px', marginRight: 0 }}
            options={regionList ? regionList.getRegionsByCountryId : []}
          />
        </Grid>
        <Grid
          container
          justify={'space-between'}
          item
          style={marginGenerator('mt-24')}>
          <Input
            value={zipcode}
            style={{ width: '100%' }}
            type={'text'}
            placeholder={`${t('zipCode.label')} *`}
            color={'secondary'}
            onChange={evt => setZipcode(evt.target.value)}
          />
        </Grid>
        <Button
          variant={'contained'}
          size={'large'}
          style={{ ...marginGenerator('mt-24') }}
          disabled={false}
          color={'primary'}
          onClick={() => {
            if (!name || !address || !region || !country || !zipcode) {
              Message.error(REQUIRED_ERROR);
              return null;
            }
            return createOrganization({
              variables: {
                name: name,
                address: address,
                region: region,
                country: country,
                zipcode: zipcode
              }
            });
          }}>
          {t('saveAndContinue.label')}
        </Button>
      </Grid>
    </SecondaryLayout>
  );
}

export default withTranslation()(CreateOrganization);
