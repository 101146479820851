import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import ServiceProviderTable from './ServiceProviderTable';

const ServiceProviderDashboard = () => {
  const { loggedInUserRoles } = useAuth();

  const history = useHistory();

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <br />
        <Grid item style={{ marginLeft: '10px' }}>
          {loggedInUserRoles?.includes('fabric_readonly') === false &&
          loggedInUserRoles[0] === 'superadmin' ? (
            <Button
              variant="outlined"
              onClick={() =>
                history.push('/fabricService/serviceproviders/add')
              }>
              Create Service Provider
            </Button>
          ) : (
            ''
          )}
        </Grid>
        <Grid item>
          <ServiceProviderTable />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceProviderDashboard;
