import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL} from '../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ControllerStatusPanel from "../ControllerStatusPanel";
import Button from "@material-ui/core/Button";

const Vnfitems = ({
                      t,
                      key,
                      vnf,
                      handleMenuItemClick,
                      menuItems
                  }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={key}>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnf.vnf_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnf.device.device_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnf.vnf_template.vnf_template_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {dateFormatterLLL(vnf.created_at)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnf.created_by}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <ControllerStatusPanel
                        status={vnf.vnf_status}
                        t={t}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        onClick={() =>
                            vnf.vnf_web !== ''
                                ? window.open(
                                `${vnf.vnf_web}`,
                                '_blank'
                                )
                                : null
                        }>
                        Web
                    </Button>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        onClick={() =>
                            vnf.vnf_ssh !== ''
                                ? window.open(`ssh://${vnf.vnf_ssh_ip}:${vnf.vnf_ssh_port}`, '_blank')
                                : null
                        }
                        ml={1}>
                        SSH
                    </Button>
                </Typography>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}>
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && (
                    <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={[
                            {label: t('controls.label')},
                            ...menuItems
                        ]}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, vnf, 'vnf');
                            setAnchorEl(null);
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
Vnfitems.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(Vnfitems);
