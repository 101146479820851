import * as React from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { GET_ALL_SERVICE_TICKETS, GET_ALL_SERVICE_TICKETS_LIMIT, GET_ASSIGNEE_LIST } from './queries';
import Typography from '@material-ui/core/Typography';
import { paddingGenerator, marginGenerator } from '../../theme/utils';
import Layout from '../../components/Layout';
import Button from '@material-ui/core/Button';
import Header from '../../components/Header';
import { Paths, TicketingSystem } from '../../routes/routePaths';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomTabs from '../../components/CustomTabs';
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Flag from '@material-ui/icons/Flag';
import DNS from '@material-ui/icons/Dns';
import { getUrl, convertLocalToUTCDate } from '../../utils';
import { ExportCSV } from './ExportCsv';
import { useHistory } from 'react-router-dom';
import TicketPanel from './components/TicketPanel';
import {
  STATUS_TYPES,
  TICKET_PRIORITY,
  TICKET_ASSIGNED,
  IS_PARENT,
  TICKET_TYPE,
  USER_ROLES,
  SORT_BY,
  COUNTRY_LIST
} from '../../utils/constants';
import { GET_SUBSCRIPTION_LIST } from '../Subscriptions/queries';
import {
  CLOSE_SERVICE_TICKET,
  CLOSE_MULTIPLE_SERVICE_TICKET
} from '../ServiceTickets/mutations';
import Message from '../../components/Message';
import { GET_COUNTRY_LIST } from '../commonQueries';
import { MERGE_CUSTOMER_TICKETS } from '../commonMutations'
import { CheckCircleOutlineRounded } from '@material-ui/icons';
import CustamizeExportData from './CustamizeExportData';
import CloseTicketModal from './components/CloseTicketModal';
import Pagination from '@material-ui/lab/Pagination';
import { id } from 'date-fns/locale';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'serviceTickets.label', link: Paths.ServiceTickets }
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  styles: {
    padding: '15px 20px 15px 20px',
    border: '2px solid #1b1818',
    borderRadius: '10px',
    marginRight: '100px',
    marginLeft: '25px'
  },
  exportDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftSide: {
    display: 'flex'
  }
}));

export const getSubscriptionOptions = list => {
  return list.map((item, index) => {
    return { id: item.id, name: item.service.name };
  });
};

const TicketLists = ({ t, navigation }) => {
  const classes = useStyles();
  let role = localStorage.getItem('role');
  let currentCountry = localStorage.getItem('country')
  let currentUrl = window.location.href;
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(1);
  const [pageClosed, setPageClosed] = useState(1);
  const [pageNew, setPageNew] = useState(1);
  const [openStatus, setOpenStatus] = useState('');
  const [country, setCountry] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [priority, setPriority] = useState('');
  const [assignee, setAssignee] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [openAssignee, setOpenAssignee] = useState('');
  const [newAssignee, setNewAssignee] = useState('');
  const [openCountry, setOpenCountry] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [closedCountry, setClosedCountry] = useState('');
  const [openTicketType, setOpenTicketType] = useState('');
  const [newTicketType, setNewTicketType] = useState('');
  const [openTicketSortBy, setOpenTicketSortBy] = useState('');
  const [newTicketSortBy, setNewTicketSortBy] = useState('');
  const [openPriority, setOpenPriority] = useState('');
  const [newPriority, setNewPriority] = useState('');
  const [closedPriority, setClosedPriority] = useState('');
  const [closedTicketType, setClosedTicketType] = useState('');
  const [subscription, setSubscription] = useState('');
  const [openSubscription, setOpenSubscription] = useState('');
  const [closedSubscription, setClosedSubscription] = useState('');
  const [exportTicketdata, setExportTicketdata] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openFilterLoading, setOpenFilterLoading] = useState(false);
  const [newFilterLoading, setNewFilterLoading] = useState(false);
  const [closedFilterLoading, setClosedFilterLoading] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const [showCloseTicketModal, setShowCloseTicketModal] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [updatedRecentTickets, setUpdatedRecentTickets] = useState([]);
  const [updatedNewTickets, setUpdatedNewTickets] = useState([]);
  const [updatedNewTicketsCount, setUpdatedNewTicketsCount] = useState([]);
  const [updatedOpenTickets, setUpdatedOpenTickets] = useState([]);
  const [updatedOpenTicketsCount, setUpdatedOpenTicketsCount] = useState([]);
  const [updatedClosedTicketsCount, setUpdatedClosedTicketsCount] = useState([]);
  const [updatedClosedTickets, setUpdatedClosedTickets] = useState([]);
  const [recentTicketsSearched, setRecentTicketsSearched] = useState(false);
  const [openTicketsSearched, setOpenTicketsSearched] = useState(false);
  const [newTicketsSearched, setNewTicketsSearched] = useState(false);
  const [closedTicketsSearched, setClosedTicketsSearched] = useState(false);

  const [closedDateFilterTickets, setClosedDateFilterTickets] = useState([]);
  const [openDateFilterTickets, setOpenDateFilterTickets] = useState([]);
  const [recentDateFilterTickets, setRecentDateFilterTickets] = useState([]);
  const [recentDateFilter, setRecentDateFilter] = useState(false);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [closedDateFilter, setClosedDateFilter] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [openTicketExportModal, setOpenTicketExportModal] = React.useState(
    false
  );
  const [show, setShow] = useState(false);
  const [closeTickets, setCloseTickets] = useState([]);
  const [exportTicketId, setExportTicketId] = useState([]);
  const [exportTickets, setExportTickets] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const history = useHistory();

  useEffect(() => {
    reloadAllServiceTicket();
    reloadOpenTicket();
    reloadCloseTicket();
    if (currentCountry && currentCountry == 'Malawi') {
      reloadNewTicket()
    }
  }, []);

  /*
     List Queries
    */

  const {
    loading: assigneeLoading,
    error: assigneeError,
    data: assigneeData
  } = useQuery(GET_ASSIGNEE_LIST);

  const { data: countryList } = useQuery(GET_COUNTRY_LIST);

  const [getAllTicketExport] = useLazyQuery(GET_ALL_SERVICE_TICKETS, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setExportTicketdata(data);
      setShowExportBtn(true);
      setExportLoading(false);
    }
  });

  const handleExportClick = () => {
    setExportLoading(true);
    getAllTicketExport({
      variables: {
        startDate: startDate,
        endDate: endDate,
        isParent: IS_PARENT.TRUE
      }
    });
  };

  const {
    loading: allServiceTicketLoading,
    error: allServiceTicketError,
    data: allServicetickets,
    refetch: reloadAllServiceTicket
  } = useQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    variables: { isParent: IS_PARENT.TRUE, max: 10 }
  });

  const {
    loading: newLoading,
    error: newError,
    data: newServiceTickets,
    refetch: reloadNewTicket
  } = useQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    variables: { status: STATUS_TYPES.NEW, isParent: IS_PARENT.TRUE, type: 'new', page: pageNew }
  });

  const {
    loading: openLoading,
    error: openError,
    data: openServiceTickets,
    refetch: reloadOpenTicket
  } = useQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    variables: { status: STATUS_TYPES.OPEN, isParent: IS_PARENT.TRUE, type: 'open', page: page }
  });

  const {
    loading: closedLoading,
    error: closedError,
    data: closedServiceTickets,
    refetch: reloadCloseTicket
  } = useQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    variables: { status: STATUS_TYPES.CLOSED, isParent: IS_PARENT.TRUE, page: pageClosed }
  });

  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
    data: subscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST);

  /*
    Filter queries
   */

  const [getRecentTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    onCompleted: data => {
      setRecentTicketsSearched(true);
      setUpdatedRecentTickets(data.allServiceticketsWithLimit.data);
    }
  });

  const [getNewTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    onCompleted: data => {
      setNewTicketsSearched(true);
      setNewFilterLoading(false)
      setUpdatedNewTickets(data.allServiceticketsWithLimit.data);
      setUpdatedNewTicketsCount(data.allServiceticketsWithLimit.total)
    }
  });

  const [getOpenTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    onCompleted: data => {
      setOpenTicketsSearched(true);
      setOpenFilterLoading(false)
      setUpdatedOpenTickets(data.allServiceticketsWithLimit.data);
      setUpdatedOpenTicketsCount(data.allServiceticketsWithLimit.total)
    }
  });

  const [getClosedTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    onCompleted: data => {
      setClosedTicketsSearched(true);
      setClosedFilterLoading(false)
      setUpdatedClosedTickets(data.allServiceticketsWithLimit.data);
      setUpdatedClosedTicketsCount(data.allServiceticketsWithLimit.total)
    }
  });

  /*
    Filter Queries
   */

  let filterListRecentTickets = [
    {
      label: 'Country',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const countryName = evt.target.value;
        const countryId =
          countryList &&
          countryList.getCountries.filter(function (item) {
            if (item.name == countryName) {
              return item.id;
            }
          });
        const country = countryId && countryId[0] && countryId[0].id;

        getRecentTicketFilteredList({
          variables: {
            country: country,
            max: 10,
            ...(status ? { status } : {}),
            ...(priority ? { priority } : {}),
            ...(assignee ? { assignee } : {}),
            ...(ticketType ? { ticketType } : {})
          }
        });

        setCountry(countryName);
      },
      val: country,
      options: [
        COUNTRY_LIST.IVORY_COAST,
        COUNTRY_LIST.ZAMBIA,
        COUNTRY_LIST.BOTSWANA,
        COUNTRY_LIST.NIGERIA,
        COUNTRY_LIST.MALAWI
      ]
    },
    {
      label: 'Status',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const statusVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            status: statusVal,
            max: 10,
            ...(priority ? { priority } : {}),
            ...(assignee ? { assignee } : {}),
            ...(ticketType ? { ticketType } : {}),
            ...(country ? { country: country } : {})
          }
        });

        setStatus(statusVal);
      },
      val: status,
      options: [STATUS_TYPES.OPEN, STATUS_TYPES.INPROGRESS, STATUS_TYPES.HOLD, STATUS_TYPES.CLOSED]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            priority: priorityVal,
            max: 10,
            ...(status ? { status } : {}),
            ...(assignee ? { assignee } : {}),
            ...(ticketType ? { ticketType } : {}),
            ...(country ? { country: country } : {})
          }
        });

        setPriority(priorityVal);
      },
      val: priority,
      options: [
        TICKET_PRIORITY.CRITICAL,
        TICKET_PRIORITY.URGENT,
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Assign',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const assignedVal = evt.target.value;
        getRecentTicketFilteredList({
          variables: {
            assignee: assignedVal,
            max: 10,
            ...(status ? { status } : {}),
            ...(priority ? { priority } : {}),
            ...(ticketType ? { ticketType } : {}),
            ...(country ? { country: country } : {})
          }
        });

        setAssignee(assignedVal);
      },
      val: assignee,
      options: [TICKET_ASSIGNED.ASSIGNED, TICKET_ASSIGNED.UNASSIGNED]
    },
    {
      label: 'Ticket Type',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        let ticketType;
        const assignedVal = evt.target.value;
        if (assignedVal === 'Email Tickets') {
          ticketType = 'mailTicket';
        } else if (assignedVal === 'Customer Tickets') {
          ticketType = 'serviceTicket';
        } else if (assignedVal === 'Call Ticket') {
          ticketType = 'csocTicket';
        } else if (assignedVal === 'Internal Ticket') {
          ticketType = 'internalTicket';
        }
        getRecentTicketFilteredList({
          variables: {
            ticketType: ticketType,
            max: 10,
            ...(status ? { status } : {}),
            ...(priority ? { priority } : {}),
            ...(assignee ? { assignee } : {}),
            ...(country ? { country: country } : {})
          }
        });

        setTicketType(assignedVal);
      },
      val: ticketType,
      options: [
        TICKET_TYPE.MAILTICKET,
        TICKET_TYPE.CUSTOMERTICKET,
        TICKET_TYPE.CSOCMANAGERTICKET,
        TICKET_TYPE.INTERNALTICKET
      ]
    }
    // {
    //   label: 'Subscription',
    //   iconComponent: DNS,
    //   handleChange: evt => {
    //     const subscriptionVal = evt.target.value;

    //     getRecentTicketFilteredList({
    //       variables: {
    //         subscriptionId: subscriptionVal,
    //         ...(priority ? { priority } : {}),
    //         ...(assignee ? { assignee } : {}),
    //         ...(status ? { status } : {}),
    //         ...(ticketType ? { ticketType } : {}),
    //         ...(country ? { country: country } : {})
    //       }
    //     });

    //     setSubscription(subscriptionVal);
    //   },
    //   val: subscription,
    //   options:
    //     !subscriptionsError && !subscriptionsLoading
    //       ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
    //       : [],
    //   styleOverrides: { minWidth: '172px' }
    // }
  ];

  let filterListNew = [
    {
      label: 'Country',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const countryName = evt.target.value;
        const countryId =
          countryList &&
          countryList.getCountries.filter(function (item) {
            if (item.name == countryName) {
              return item.id;
            }
          });
        const country = countryId && countryId[0] && countryId[0].id;

        getNewTicketFilteredList({
          variables: {
            country: country,
            page: pageNew,
            ...({ status: STATUS_TYPES.NEW }),
            ...(newPriority ? { priority: newPriority } : {}),
            ...(newTicketType ? { ticketType: newTicketType } : {}),
            ...(newTicketSortBy ? { sortBy: newTicketSortBy } : {}),
            ...(newAssignee ? { assignee: newAssignee } : {})
          }
        });
        setNewFilterLoading(true)
        setNewCountry(countryName);
      },
      val: newCountry,
      options: [
        COUNTRY_LIST.IVORY_COAST,
        COUNTRY_LIST.ZAMBIA,
        COUNTRY_LIST.BOTSWANA,
        COUNTRY_LIST.NIGERIA,
        COUNTRY_LIST.MALAWI
      ]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getNewTicketFilteredList({
          variables: {
            priority: priorityVal,
            page: pageNew,
            ...({ status: STATUS_TYPES.NEW }),
            ...(newAssignee ? { assignee: newAssignee } : {}),
            ...(newTicketType ? { ticketType: newTicketType } : {}),
            ...(newTicketSortBy ? { sortBy: newTicketSortBy } : {}),
            ...(newCountry ? { country: newCountry } : {})
          }
        });
        setNewFilterLoading(true)
        setNewPriority(priorityVal);
      },

      val: newPriority,
      options: [
        TICKET_PRIORITY.CRITICAL,
        TICKET_PRIORITY.URGENT,
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Assign',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const assignedVal = evt.target.value;
        getNewTicketFilteredList({
          variables: {
            assignee: assignedVal,
            page: pageNew,
            ...({ status: STATUS_TYPES.NEW }),
            ...(newPriority ? { priority: newPriority } : {}),
            ...(newTicketType ? { ticketType: newTicketType } : {}),
            ...(newTicketSortBy ? { sortBy: newTicketSortBy } : {}),
            ...(newCountry ? { country: newCountry } : {})
          }
        });
        setNewFilterLoading(true)
        setNewAssignee(assignedVal);
      },
      val: newAssignee,
      options: [TICKET_ASSIGNED.ASSIGNED, TICKET_ASSIGNED.UNASSIGNED]
    },
    {
      label: 'Ticket Type',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        let ticketType;
        const assignedVal = evt.target.value;
        if (assignedVal === 'Email Tickets') {
          ticketType = 'mailTicket';
        } else if (assignedVal === 'Customer Tickets') {
          ticketType = 'serviceTicket';
        } else if (assignedVal === 'Call Ticket') {
          ticketType = 'csocTicket';
        } else if (assignedVal === 'Internal Ticket') {
          ticketType = 'internalTicket';
        }
        getNewTicketFilteredList({
          variables: {
            ticketType: ticketType,
            page: pageNew,
            ...({ status: STATUS_TYPES.NEW }),
            ...(newPriority ? { priority: newPriority } : {}),
            ...(newAssignee ? { assignee: newAssignee } : {}),
            ...(newTicketSortBy ? { sortBy: newTicketSortBy } : {}),
            ...(newCountry ? { country: newCountry } : {})
          }
        });
        setNewFilterLoading(true)
        setNewTicketType(assignedVal);
      },
      val: newTicketType,
      options: [
        TICKET_TYPE.MAILTICKET,
        TICKET_TYPE.CUSTOMERTICKET,
        TICKET_TYPE.CSOCMANAGERTICKET,
        TICKET_TYPE.INTERNALTICKET
      ]
    },
    {
      label: 'Ticket Sort By',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const ticketSortBy = evt.target.value;
        getNewTicketFilteredList({
          variables: {
            sortBy: ticketSortBy,
            page: pageNew,
            ...({ status: STATUS_TYPES.NEW }),
            ...(newPriority ? { priority: newPriority } : {}),
            ...(newAssignee ? { assignee: newAssignee } : {}),
            ...(newCountry ? { country: newCountry } : {}),
            ...(newTicketType ? { ticketType: newTicketType } : {})
          }
        });
        setNewFilterLoading(true)
        setNewTicketSortBy(ticketSortBy);
      },
      val: newTicketSortBy,
      options: [
        SORT_BY.LATESTFIRST,
        SORT_BY.OLDESTFIRST,
        SORT_BY.LASTMODIFIED,
        SORT_BY.FIRSTMODIFIED
      ]
    }
  ]

  let filterListOpen = [
    {
      label: 'Country',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const countryName = evt.target.value;
        const countryId =
          countryList &&
          countryList.getCountries.filter(function (item) {
            if (item.name == countryName) {
              return item.id;
            }
          });
        const country = countryId && countryId[0] && countryId[0].id;

        getOpenTicketFilteredList({
          variables: {
            country: country,
            page: page,
            ...(openStatus ? { status: openStatus } : { status: STATUS_TYPES.OPEN }),
            ...(openPriority ? { priority: openPriority } : {}),
            ...(openTicketType ? { ticketType: openTicketType } : {}),
            ...(openTicketSortBy ? { sortBy: openTicketSortBy } : {}),
            ...(openAssignee ? { assignee: openAssignee } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenCountry(countryName);
      },
      val: openCountry,
      options: [
        COUNTRY_LIST.IVORY_COAST,
        COUNTRY_LIST.ZAMBIA,
        COUNTRY_LIST.BOTSWANA,
        COUNTRY_LIST.NIGERIA,
        COUNTRY_LIST.MALAWI
      ]
    },
    {
      label: 'Status',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const statusVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            status: statusVal,
            page: page,
            ...(openAssignee ? { assignee: openAssignee } : {}),
            ...(openTicketType ? { ticketType: openTicketType } : {}),
            ...(openTicketSortBy ? { sortBy: openTicketSortBy } : {}),
            ...(openCountry ? { country: openCountry } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenStatus(statusVal);
      },
      val: openStatus,
      options: [STATUS_TYPES.OPEN, STATUS_TYPES.INPROGRESS, STATUS_TYPES.HOLD]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            priority: priorityVal,
            page: page,
            ...(openStatus ? { status: openStatus } : { status: STATUS_TYPES.OPEN }),
            ...(openAssignee ? { assignee: openAssignee } : {}),
            ...(openTicketType ? { ticketType: openTicketType } : {}),
            ...(openTicketSortBy ? { sortBy: openTicketSortBy } : {}),
            ...(openCountry ? { country: openCountry } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenPriority(priorityVal);
      },

      val: openPriority,
      options: [
        TICKET_PRIORITY.CRITICAL,
        TICKET_PRIORITY.URGENT,
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Assign',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const assignedVal = evt.target.value;
        getOpenTicketFilteredList({
          variables: {
            assignee: assignedVal,
            page: page,
            ...(openStatus ? { status: openStatus } : { status: STATUS_TYPES.OPEN }),
            ...(openPriority ? { priority: openPriority } : {}),
            ...(openTicketType ? { ticketType: openTicketType } : {}),
            ...(openTicketSortBy ? { sortBy: openTicketSortBy } : {}),
            ...(openCountry ? { country: openCountry } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenAssignee(assignedVal);
      },
      val: openAssignee,
      options: [TICKET_ASSIGNED.ASSIGNED, TICKET_ASSIGNED.UNASSIGNED]
    },
    {
      label: 'Ticket Type',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        let ticketType;
        const assignedVal = evt.target.value;
        if (assignedVal === 'Email Tickets') {
          ticketType = 'mailTicket';
        } else if (assignedVal === 'Customer Tickets') {
          ticketType = 'serviceTicket';
        } else if (assignedVal === 'Call Ticket') {
          ticketType = 'csocTicket';
        } else if (assignedVal === 'Internal Ticket') {
          ticketType = 'internalTicket';
        }
        getOpenTicketFilteredList({
          variables: {
            ticketType: ticketType,
            page: page,
            ...(openStatus ? { status: openStatus } : { status: STATUS_TYPES.OPEN }),
            ...(openPriority ? { priority: openPriority } : {}),
            ...(openAssignee ? { assignee: openAssignee } : {}),
            ...(openTicketSortBy ? { sortBy: openTicketSortBy } : {}),
            ...(openCountry ? { country: openCountry } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenTicketType(assignedVal);
      },
      val: openTicketType,
      options: [
        TICKET_TYPE.MAILTICKET,
        TICKET_TYPE.CUSTOMERTICKET,
        TICKET_TYPE.CSOCMANAGERTICKET,
        TICKET_TYPE.INTERNALTICKET
      ]
    },
    {
      label: 'Ticket Sort By',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const ticketSortBy = evt.target.value;
        getOpenTicketFilteredList({
          variables: {
            sortBy: ticketSortBy,
            page: page,
            ...(openStatus ? { status: openStatus } : { status: STATUS_TYPES.OPEN }),
            ...(openPriority ? { priority: openPriority } : {}),
            ...(openAssignee ? { assignee: openAssignee } : {}),
            ...(openCountry ? { country: openCountry } : {}),
            ...(openTicketType ? { ticketType: openTicketType } : {})
          }
        });
        setOpenFilterLoading(true)
        setOpenTicketSortBy(ticketSortBy);
      },
      val: openTicketSortBy,
      options: [
        SORT_BY.LATESTFIRST,
        SORT_BY.OLDESTFIRST,
        SORT_BY.LASTMODIFIED,
        SORT_BY.FIRSTMODIFIED
      ]
    }
    // {
    //   label: 'Subscription',
    //   iconComponent: DNS,
    //   handleChange: evt => {
    //     const subscriptionVal = evt.target.value;

    //     getOpenTicketFilteredList({
    //       variables: {
    //         subscriptionId: subscriptionVal,
    //         ...(openPriority ? { priority: openPriority } : {}),
    //         ...(openAssignee ? { assignee: openAssignee } : {}),
    //         ...(openTicketType ? { ticketType: openTicketType } : {}),
    //         ...(openCountry ? { country: openCountry } : {}),
    //         status: STATUS_TYPES.OPEN
    //       }
    //     });

    //     setOpenSubscription(subscriptionVal);
    //   },
    //   val: openSubscription,
    //   options:
    //     !subscriptionsError && !subscriptionsLoading
    //       ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
    //       : [],
    //   styleOverrides: { minWidth: '172px' }
    // }
  ];
  let filterClosed = [
    {
      label: 'Country',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const countryName = evt.target.value;
        const countryId =
          countryList &&
          countryList.getCountries.filter(function (item) {
            if (item.name == countryName) {
              return item.id;
            }
          });
        const country = countryId && countryId[0] && countryId[0].id;

        getClosedTicketFilteredList({
          variables: {
            country: country,
            page: pageClosed,
            status: STATUS_TYPES.CLOSED,
            ...(closedPriority ? { priority: closedPriority } : {}),
            ...(closedTicketType ? { ticketType: closedTicketType } : {})
          }
        });
        setClosedFilterLoading(true)
        setClosedCountry(countryName);
      },
      val: closedCountry,
      options: [
        COUNTRY_LIST.IVORY_COAST,
        COUNTRY_LIST.ZAMBIA,
        COUNTRY_LIST.BOTSWANA,
        COUNTRY_LIST.NIGERIA,
        COUNTRY_LIST.MALAWI
      ]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getClosedTicketFilteredList({
          variables: {
            priority: priorityVal,
            page: pageClosed,
            status: STATUS_TYPES.CLOSED,
            ...(closedTicketType ? { ticketType: closedTicketType } : {}),
            ...(closedCountry ? { country: closedCountry } : {})
          }
        });
        setClosedFilterLoading(true)
        setClosedPriority(priorityVal);
      },
      val: closedPriority,
      options: [
        TICKET_PRIORITY.CRITICAL,
        TICKET_PRIORITY.URGENT,
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Ticket Type',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        let ticketType;
        const assignedVal = evt.target.value;
        if (assignedVal === 'Email Tickets') {
          ticketType = 'mailTicket';
        } else if (assignedVal === 'Customer Tickets') {
          ticketType = 'serviceTicket';
        } else if (assignedVal === 'Call Ticket') {
          ticketType = 'csocTicket';
        } else if (assignedVal === 'Internal Ticket') {
          ticketType = 'internalTicket';
        }
        getClosedTicketFilteredList({
          variables: {
            ticketType: ticketType,
            page: pageClosed,
            status: STATUS_TYPES.CLOSED,
            ...(closedPriority ? { priority: closedPriority } : {}),
            ...(closedCountry ? { country: closedCountry } : {})
          }
        });
        setClosedFilterLoading(true)
        setClosedTicketType(assignedVal);
      },
      val: closedTicketType,
      options: [
        TICKET_TYPE.MAILTICKET,
        TICKET_TYPE.CUSTOMERTICKET,
        TICKET_TYPE.CSOCMANAGERTICKET,
        TICKET_TYPE.INTERNALTICKET
      ]
    }
    // {
    //   label: 'Subscription',
    //   iconComponent: DNS,
    //   handleChange: evt => {
    //     const subscriptionVal = evt.target.value;

    //     getClosedTicketFilteredList({
    //       variables: {
    //         subscriptionId: subscriptionVal,
    //         ...(closedPriority ? { priority: closedPriority } : {}),
    //         status: STATUS_TYPES.CLOSED
    //       }
    //     });

    //     setClosedSubscription(subscriptionVal);
    //   },
    //   val: closedSubscription,
    //   options:
    //     !subscriptionsError && !subscriptionsLoading
    //       ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
    //       : [],
    //   styleOverrides: { minWidth: '172px' }
    // }
  ];

  if (!role.includes(USER_ROLES.IP_SUPERADMIN)) {
    filterListRecentTickets.splice(0, 1);
    filterListNew.splice(0, 1);
    filterListOpen.splice(0, 1);
    filterClosed.splice(0, 1);
  }

  const [closeServiceTicket] = useMutation(CLOSE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      reloadOpenTicket();
      if (currentCountry && currentCountry == 'Malawi') {
        reloadNewTicket()
      }
      reloadAllServiceTicket();
      reloadCloseTicket();
      Message.success('Ticket closed successfully.');
    }
  });

  const [closeMultipleServiceTicket] = useMutation(
    CLOSE_MULTIPLE_SERVICE_TICKET,
    {
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: () => {
        setCloseTickets([])
        setButtonLoading(false);
        setShowCloseTicketModal(false);
        reloadOpenTicket();
        if (currentCountry && currentCountry == 'Malawi') {
          reloadNewTicket()
        }
        reloadAllServiceTicket();
        reloadCloseTicket();
        Message.success('Tickets closed successfully.');
      }
    }
  );

  const [mergeCustomerTickets] = useMutation(
    MERGE_CUSTOMER_TICKETS,
    {
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: () => {
        setCloseTickets([])
        reloadOpenTicket();
        if (currentCountry && currentCountry == 'Malawi') {
          reloadNewTicket()
        }
        reloadAllServiceTicket();
        reloadCloseTicket();
        Message.success('Tickets merged successfully.');
      }
    }
  );

  const handleTicketItemClick = id => {
    const path = getUrl(TicketingSystem.Details, id);
    history.push(path);
  };

  const handleMenuItemClick = (item, ticketId, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
      return closeServiceTicket({
        variables: { id }
      });
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(id);
    }
  };

  const handleSearchRecentTickets = query => {
    if (query !== '') {
      setRecentDateFilterTickets([]);
      setRecentDateFilter(false);
      const updatedList =
        allServicetickets.allServiceticketsWithLimit && allServicetickets.allServiceticketsWithLimit.data &&
        allServicetickets.allServiceticketsWithLimit.data.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item._id.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.firstName && item.userInfo.firstName.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.lastName && item.userInfo.lastName.toLowerCase().includes(query.toLowerCase())
        );
      setRecentTicketsSearched(true);
      setUpdatedRecentTickets(updatedList.slice(0, 10));
    } else {
      setRecentTicketsSearched(false);
      setUpdatedRecentTickets([]);
    }
  };

  const onEnterSearchNew = (e, query) => {
    if (query !== '') {
      if (e.key === 'Enter') {
        setSearchQuery(query)
        getNewTicketFilteredList({
          variables: {
            status: STATUS_TYPES.NEW,
            search: query,
            page: pageNew
          }
        })
        setNewFilterLoading(true)
      }
    } else {
      setSearchQuery('')
      setNewTicketsSearched(false);
    }
  }

  const onEnterSearch = (e, query) => {
    if (query !== '') {
      if (e.key === 'Enter') {
        setSearchQuery(query)
        getOpenTicketFilteredList({
          variables: {
            status: STATUS_TYPES.OPEN,
            search: query,
            page: page
          }
        })
        setOpenFilterLoading(true)
      }
    } else {
      setSearchQuery('')
      setOpenTicketsSearched(false);
    }
  }

  const onEnterSearchClosed = (e, query) => {
    if (query !== '') {
      if (e.key === 'Enter') {
        setSearchQuery(query)
        getClosedTicketFilteredList({
          variables: {
            status: STATUS_TYPES.CLOSED,
            search: query,
            page: page
          }
        })
        setClosedFilterLoading(true)
      }
    } else {
      setSearchQuery('')
      setClosedTicketsSearched(false);
    }
  }

  const handleSearchNewTickets = query => {
    if (query !== '') {
      setOpenDateFilterTickets([]);
      setOpenDateFilter(false);

      const updatedList =
        newServiceTickets.allServiceticketsWithLimit && newServiceTickets.allServiceticketsWithLimit.data &&
        newServiceTickets.allServiceticketsWithLimit.data.filter(
          (item, index) =>
            item.userInfo && item.userInfo.firstName && item.userInfo.firstName.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.lastName && item.userInfo.lastName.toLowerCase().includes(query.toLowerCase()) ||
            item.sites && item.sites[0] && item.sites[0].name.toLowerCase().includes(query.toLowerCase()) ||
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item._id.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase())
        );
      setUpdatedNewTickets(updatedList);
      setUpdatedNewTicketsCount(updatedList.length)
      setNewTicketsSearched(true);
    } else {
      setNewTicketsSearched(false);
      setUpdatedNewTickets([]);
    }
  };

  const handleSearchOpenTickets = query => {
    if (query !== '') {
      setOpenDateFilterTickets([]);
      setOpenDateFilter(false);

      const updatedList =
        openServiceTickets.allServiceticketsWithLimit && openServiceTickets.allServiceticketsWithLimit.data &&
        openServiceTickets.allServiceticketsWithLimit.data.filter(
          (item, index) =>
            item.userInfo && item.userInfo.firstName && item.userInfo.firstName.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.lastName && item.userInfo.lastName.toLowerCase().includes(query.toLowerCase()) ||
            item.sites && item.sites[0] && item.sites[0].name.toLowerCase().includes(query.toLowerCase()) ||
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item._id.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase())
        );
      setUpdatedOpenTickets(updatedList);
      setUpdatedOpenTicketsCount(updatedList.length)
      setOpenTicketsSearched(true);
    } else {
      setOpenTicketsSearched(false);
      setUpdatedOpenTickets([]);
    }
  };

  const handleSearchClosedTickets = query => {
    if (query !== '') {
      setClosedDateFilterTickets([]);
      setClosedDateFilter(false);
      const updatedList =
        closedServiceTickets.allServiceticketsWithLimit && closedServiceTickets.allServiceticketsWithLimit &&
        closedServiceTickets.allServiceticketsWithLimit.data.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item._id.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.firstName && item.userInfo.firstName.toLowerCase().includes(query.toLowerCase()) ||
            item.userInfo && item.userInfo.lastName && item.userInfo.lastName.toLowerCase().includes(query.toLowerCase())
        );
      setClosedTicketsSearched(true);
      setUpdatedClosedTickets(updatedList);
      setUpdatedClosedTicketsCount(updatedList.length)
    } else {
      setClosedTicketsSearched(false);
      setUpdatedClosedTickets([]);
    }
  };

  const handleRecentDateFilter = date => {
    var time = convertLocalToUTCDate(date);
    if (date !== '') {
      const updatedDateFilterList =
        allServicetickets.allServiceticketsWithLimit && allServicetickets.allServiceticketsWithLimit.data &&
        allServicetickets.allServiceticketsWithLimit.data.filter(
          (item, index) => item.createdAt >= time
        );
      setRecentDateFilterTickets(updatedDateFilterList);
      setRecentDateFilter(true);
    } else {
      setRecentDateFilterTickets([]);
      setRecentDateFilter(false);
    }
  };

  const handleOpenDateFilter = date => {
    var time = convertLocalToUTCDate(date);
    if (date !== '') {
      const updatedDateFilterList =
        openServiceTickets.allServiceticketsWithLimit && openServiceTickets.allServiceticketsWithLimit.data &&
        openServiceTickets.allServiceticketsWithLimit.filter(
          (item, index) => item.createdAt >= time
        );
      setOpenDateFilterTickets(updatedDateFilterList);
      setOpenDateFilter(true);
    } else {
      setOpenDateFilterTickets([]);
      setOpenDateFilter(false);
    }
  };

  const handleChangeAgent = agent => {
    if (agent != null) {
      const assignee = agent.email.toLowerCase();
      getOpenTicketFilteredList({
        variables: {
          assignee: assignee,
          status: STATUS_TYPES.OPEN
        }
      });
    }
  };

  const handleClosedDateFilter = date => {
    var time = convertLocalToUTCDate(date);
    if (date !== '') {
      const updatedDateFilterList =
        closedServiceTickets.allServiceticketsWithLimit && closedServiceTickets.allServiceticketsWithLimit.data &&
        closedServiceTickets.allServiceticketsWithLimit.data.filter(
          (item, index) => item.createdAt >= time
        );
      setClosedDateFilterTickets(updatedDateFilterList);
      setClosedDateFilter(true);
    } else {
      setClosedDateFilterTickets([]);
      setClosedDateFilter(false);
    }
  };

  const handleSelectAllTickets = (data, event) => {
    if (data.length != closeTickets.length) {
      setCloseTickets([])
    }
    data && data.length > 0 && data.map(item => {
      handleCloseMultipleTickets(item._id, event);
    })
  }

  const handleSelectAllExportTickets = (data, event) => {
    if (data.length != exportTicketId.length) {
      setExportTicketId([])
    }
    data && data.length > 0 && data.map(item => {
      handleExportMultipleTickets(item.id, event);
    })
  }

  const handleCloseMultipleTickets = (id, event) => {
    setCloseTickets(prev => {
      let newState = [...prev];
      var checkDeleteVal = newState.indexOf(id);
      if (checkDeleteVal !== -1) {
        newState.splice(checkDeleteVal, 1);
      } else {
        if (!newState.includes(id)) {
          newState.push(id);
        }
      }
      if (newState.length) {
        setShow(true);
      } else {
        setShow(false);
      }
      return newState;
    });
  };

  const handleExportMultipleTickets = (id, event) => {
    setExportTicketId(prev => {
      let newState = [...prev];
      var checkDeleteVal = newState.indexOf(id);
      if (checkDeleteVal !== -1) {
        newState.splice(checkDeleteVal, 1);
      } else {
        if (!newState.includes(id)) {
          newState.push(id);
        }
      }
      // if (newState.length) {
      //   setShow(true);
      // } else {
      //   setShow(false);
      // }
      return newState;
    });
  };

  const handleSubmitExportTickets = () => {
    if (exportTicketId && exportTicketId.length === 0) {
      Message.error('Please select data to export');
    } else {
      let exportData =
        exportTicketdata &&
        exportTicketdata.allServicetickets &&
        exportTicketdata.allServicetickets.filter(data => {
          if (exportTicketId.includes(data.id)) {
            return data;
          }
        });
      setOpenTicketExportModal(false);
      setExportTickets(exportData);
    }
  };

  const handleRaiseNewTicketClick = () => {
    history.push(TicketingSystem.NewServiceTicket);
  };

  const handleMultipleTcketsClose = () => {
    setShowCloseTicketModal(true);
  };

  const handleSubmitCloseTicket = data => {
    setButtonLoading(true);
    return closeMultipleServiceTicket({
      variables: {
        id: closeTickets,
        currentUrl: currentUrl,
        ticketCloseTime: data.endDate,
        closeDescription: data.description,
        extraNote: data.extraNote
      }
    });
  };

  const fileName = 'ExportAllTicketDetail';

  const viewers =
    exportTicketdata &&
    exportTicketdata.allServicetickets &&
    exportTicketdata.allServicetickets;

  const handleExportAllTickets = (data) => {
    setOpenTicketExportModal(false);
    setExportTickets(data);
  };


  const setStartDateHandler = (date) => {
    // Validate that the date difference is less than or equal to 7 days
    const maxEndDate = new Date(date);
    maxEndDate.setDate(date.getDate() + 7);

    if (endDate > maxEndDate) {
      // If the current end date exceeds the allowed range, reset it
      setEndDate(new Date(date));
    }

    setStartDate(date);
  };

  const setEndDateHandler = (date) => {
    // Validate that the date difference is less than or equal to 7 days
    const minStartDate = new Date(date);
    minStartDate.setDate(date.getDate() - 7);

    if (startDate < minStartDate) {
      // If the current start date is before the allowed range, reset it
      setStartDate(new Date(date));
    }

    setEndDate(date);
  };

  const emptyData = () => {
    Message.error('No Record Found For This Date Range');
    return (
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={handleExportClick}>
        {exportLoading ? (
          <CircularProgress color="secondary" size={20} />
        ) : (
          t('exportTickets.label')
        )}
      </Button>
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handlePageChangeNew = (event, value) => {
    setPageNew(value)
  }

  const handlePageChangeClosed = (event, value) => {
    setPageClosed(value)
  }

  const handleMergeTickets = () => {
    if (
      window.confirm('Are you sure you want to merge these tickets? By clicking ok you agree to keep oldest ticket and remove the new ones.') ===
      false
    ) {
      return false;
    }

    return mergeCustomerTickets({
      variables: {
        ticketId: closeTickets
      }
    })
  }

  const activeTicketIndex = localStorage.getItem('activeTicketTab')

  useEffect(() => {
    setActiveIndex(activeTicketIndex)
  }, [activeTicketIndex])

  const activeIndexFun = (index) => {
    localStorage.setItem("activeTicketTab", index)
  }

  const tabChangeFun = () => {
    setCloseTickets([])
  }

  const customTabs = [
    {
      label: t('recentTickets.label'),
      children: (
        <TicketPanel
          ticketList={
            recentDateFilter
              ? recentDateFilterTickets
              : recentTicketsSearched
                ? updatedRecentTickets
                : allServicetickets && allServicetickets.allServiceticketsWithLimit && allServicetickets.allServiceticketsWithLimit.data
          }
          type={'recentTickets'}
          t={t}
          loading={allServiceTicketLoading ? true : false}
          handleMenuItemClick={handleMenuItemClick}
          filterList={filterListRecentTickets}
          handleTicketItemClick={handleTicketItemClick}
          handleSearch={handleSearchRecentTickets}
          handleDateFilter={handleRecentDateFilter}
        />
      )
    },
    {
      label: t('newTickets.label'),
      children: (
        <>
          <TicketPanel
            ticketList={
              openDateFilter
                ? openDateFilterTickets
                : newTicketsSearched
                  ? updatedNewTickets
                  : newServiceTickets && newServiceTickets.allServiceticketsWithLimit && newServiceTickets.allServiceticketsWithLimit.data
            }
            type={'newTickets'}
            t={t}
            handleMenuItemClick={handleMenuItemClick}
            loading={(newFilterLoading || newLoading) ? true : false}
            selectedCloseTicketsId={closeTickets}
            filterList={filterListNew}
            handleTicketItemClick={handleTicketItemClick}
            handleSearch={handleSearchNewTickets}
            onEnterSearch={onEnterSearchNew}
            agentData={
              !assigneeLoading &&
              !assigneeError &&
              assigneeData.assignee
            }
            handleChangeAgent={handleChangeAgent}
            handleCloseMultipleTickets={handleCloseMultipleTickets}
            handleSelectAllTickets={handleSelectAllTickets}
            handleDateFilter={handleOpenDateFilter}
          />
          <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
            count={newTicketsSearched ? Math.ceil(updatedNewTicketsCount / 30) : newServiceTickets
              && newServiceTickets.allServiceticketsWithLimit && newServiceTickets.allServiceticketsWithLimit.total ?
              Math.ceil(newServiceTickets.allServiceticketsWithLimit.total / 30) : 0} page={pageNew} onChange={handlePageChangeNew} />
        </>
      )
    },
    {
      label: t('openTickets.label'),
      children: (
        <>
          <TicketPanel
            ticketList={
              openDateFilter
                ? openDateFilterTickets
                : openTicketsSearched
                  ? updatedOpenTickets
                  : openServiceTickets && openServiceTickets.allServiceticketsWithLimit && openServiceTickets.allServiceticketsWithLimit.data
            }
            type={'openTickets'}
            t={t}
            handleMenuItemClick={handleMenuItemClick}
            loading={(openFilterLoading || openLoading) ? true : false}
            selectedCloseTicketsId={closeTickets}
            filterList={filterListOpen}
            handleTicketItemClick={handleTicketItemClick}
            handleSearch={handleSearchOpenTickets}
            onEnterSearch={onEnterSearch}
            agentData={
              !assigneeLoading &&
              !assigneeError &&
              assigneeData.assignee
            }
            handleChangeAgent={handleChangeAgent}
            handleCloseMultipleTickets={handleCloseMultipleTickets}
            handleSelectAllTickets={handleSelectAllTickets}
            handleDateFilter={handleOpenDateFilter}
          />
          <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
            count={openTicketsSearched ? Math.ceil(updatedOpenTicketsCount / 30) : openServiceTickets
              && openServiceTickets.allServiceticketsWithLimit && openServiceTickets.allServiceticketsWithLimit.total ?
              Math.ceil(openServiceTickets.allServiceticketsWithLimit.total / 30) : 0} page={page} onChange={handlePageChange} />
        </>
      )
    },
    {
      label: t('closedTickets.label'),
      children: (
        <>
          <TicketPanel
            ticketList={
              closedDateFilter
                ? closedDateFilterTickets
                : closedTicketsSearched
                  ? updatedClosedTickets
                  : closedServiceTickets && closedServiceTickets.allServiceticketsWithLimit && closedServiceTickets.allServiceticketsWithLimit.data
            }
            type={'closedTickets'}
            t={t}
            handleMenuItemClick={handleMenuItemClick}
            filterList={filterClosed}
            loading={(closedFilterLoading || closedLoading) ? true : false}
            onEnterSearch={onEnterSearchClosed}
            handleTicketItemClick={handleTicketItemClick}
            handleSearch={handleSearchClosedTickets}
            handleDateFilter={handleClosedDateFilter}
          />
          <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
            count={closedTicketsSearched ? Math.ceil(updatedClosedTicketsCount / 30) : closedServiceTickets
              && closedServiceTickets.allServiceticketsWithLimit && closedServiceTickets.allServiceticketsWithLimit.total
              ? Math.ceil(closedServiceTickets.allServiceticketsWithLimit.total / 30) : 0} page={pageClosed} onChange={handlePageChangeClosed} />
        </>
      )
    }
  ]

  if (currentCountry && currentCountry == 'Malawi') {
    // Edited to Omit Item 1 (Recent Tickets)
    customTabs.shift();
    
   
  }else {customTabs.splice(1,1)}

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('serviceTickets.label')}
        subText={`${openServiceTickets && openServiceTickets.allServiceticketsWithLimit && openServiceTickets.allServiceticketsWithLimit.data
          ? openServiceTickets.allServiceticketsWithLimit.total
          : 0
          } ${t('openTickets.label')}`}
        primaryButtonText={!role.includes(USER_ROLES.SD_READONLY) && t('raiseNewTicket.label')}
        handlePrimaryButtonClick={!role.includes(USER_ROLES.SD_READONLY) && handleRaiseNewTicketClick}
        secondaryButtonText={!role.includes(USER_ROLES.SD_READONLY) && show ? t('closeMultiple.label') : null}
        handleSecondaryButtonClick={!role.includes(USER_ROLES.SD_READONLY) && handleMultipleTcketsClose}
      />

      <div className={classes.root}>
        {allServiceTicketLoading && <CircularProgress color="secondary" />}
      </div>
      {!allServiceTicketLoading && !allServiceTicketError && allServicetickets && (
        <div className={classes.exportDiv}>
             <div className={classes.leftSide}>
      <Typography
        variant="subtitle2"
        color="textPrimary"
        style={{
          ...paddingGenerator('pt-12'),
          ...marginGenerator('mr-70'),
        }}>
        <small style={{width:'30px'}}>Date range should be a difference of 1 week (7 days) - </small>
        {t('selectInterval.label')}
     
      </Typography>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDateHandler(date)}
        className={classes.styles}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDateHandler(date)}
        selectsEnd
        className={classes.styles}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
          <div className={classes.rightSide}>
            <>
              {(show && closeTickets && closeTickets.length > 1) ? (
                <div style={{ float: 'right' }}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleMergeTickets}>
                    {exportLoading ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : (
                      t('mergeTickets.label')
                    )}
                  </Button>
                </div>
              ) : showExportBtn ? (
                <>
                  {exportTickets && exportTickets.length ? (
                    <ExportCSV csvData={exportTickets} fileName={fileName} />
                  ) : (
                    <>
                      {exportTicketdata &&
                        exportTicketdata.allServicetickets &&
                        exportTicketdata.allServicetickets.length ? (
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          onClick={evt => setOpenTicketExportModal(true)}>
                          {exportLoading ? (
                            <CircularProgress color="secondary" size={20} />
                          ) : (
                            t('exportCustamize.label')
                          )}
                        </Button>
                      ) : (
                        emptyData()
                      )}
                    </>
                  )}
                </>
              ) : (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleExportClick}>
                  {exportLoading ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    t('exportTickets.label')
                  )}
                </Button>
              )}
            </>
          </div>
        </div>
      )}

      <CustomTabs
        tabs={customTabs}
        activeIndex={currentCountry=='Malawi' ? parseInt(0): parseInt(activeIndex)}
        activeIndexFun={activeIndexFun}
        tabChangeFun={tabChangeFun}
      />
      <CustamizeExportData
        open={openTicketExportModal}
        handleClose={evt => setOpenTicketExportModal(false)}
        data={
          exportTicketdata && exportTicketdata.allServicetickets
            ? exportTicketdata.allServicetickets
            : []
        }
        uniqueId={'exportTicket'}
        startDate={startDate}
        assigneeList={!assigneeLoading && !assigneeError && assigneeData}
        endDate={endDate}
        exportTicketId={exportTicketId}
        handleSelectAllExportTickets={handleSelectAllExportTickets}
        handleExportMultipleTickets={handleExportMultipleTickets}
        handleSubmitExportTickets={handleSubmitExportTickets}
        handleExportAllTickets={handleExportAllTickets}
      />
      <CloseTicketModal
        open={showCloseTicketModal}
        handleClose={evt => setShowCloseTicketModal(false)}
        handleSubmitCloseTicket={handleSubmitCloseTicket}
        clearModal={clearModal}
        buttonLoading={buttonLoading}
        type="closeMultiple"
      />
    </Layout>
  );
};
export default withTranslation()(TicketLists);
