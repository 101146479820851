import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../../../components/OutlinedSelect';

function ButtonComponent(props) {
    const { t, onClick } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick}>
            {t('submit.label')}
        </Button>
    );
}
function CreateQuoteModal({
    t,
    open,
    handleClose,
    handleCreateBidSubmit
}) {
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Upload New Quote</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...marginGenerator('mt-8')
                    }}>
                    <Grid>
                        <OutlinedSelect
                            options={[{name: "Test Bid 1", id: "1"}, {name: "Test Bid 2", id: "2"}]}
                            label={"Select Bid"}
                            val={""}
                            styleOverrides={{width: "100%"}}
                            handleChange={evt => {

                            }}
                        />
                    </Grid>
                    <Grid style={{marginTop: 15}}>
                        <Input
                            value={""}
                            type={'text'}
                            placeholder={"Vendor Name"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => {

                            }}
                        />
                    </Grid>
                    <Grid style={{marginTop: 15}}>
                        <Input
                            value={""}
                            type={'file'}
                            placeholder={"Upload Doc"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => {
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            return handleCreateBidSubmit({});
                        }}
                     />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateQuoteModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateQuoteModal);