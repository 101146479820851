import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import ActingFormPanelItem from './ActingFormPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import ActingFormModal from './ActingFormModal';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import {
    GET_ALL_ACTINGS
} from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_ACTING, DELETE_ACTING } from '../../mutation';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function ActingFormPanel({
    t,
    data,
    allowConfigure,
    menuItems,
    users,
    departments
}) {
    const classes = useStyles();
    
    const [filterStateData, setFilterStateData] = useState({...initFilterState});
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedUserItem, setSelectedUserItem] = useState(null);
    const [userRequestModalOpen, setUserRequestModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({
    originatorSign: "", mbaManagerSign: "", 
    HODSign: "", finantialAccountantSign: "" });

    const subscriptionId = data.id;

    const {
        data: userdata,
        loading: userDataLoading,
        error: userDataError
      } = useQuery(GET_USER_INFO);
    
    const [getAllUserRequests, {
        loading,
        data: userActingList
    }] = useLazyQuery(GET_ALL_ACTINGS, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllUserRequests({variables: {...filterStateData, subscriptionId}});
    }, []);

    useEffect(() => {
        getAllUserRequests({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [createActing] = useMutation(CREATE_ACTING, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Acting request created successfully');
            setCRModalState({clearData: true, btnLoading: false});
            return getAllUserRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteActing, { loading: deleteActingLoading }] = useMutation(DELETE_ACTING, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Acting request deleted successfully');
            return getAllUserRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createActing({ variables: {
            ...signatureState,
            ...formData
        }});
    }

    const columns = [
        {
            label: 'User Name',
            key: "userName"
        },
        {
            label: 'Expiry Date',
            key: "expiryDate"
        },
        {
            label: 'Employee Type',
            key: "employeeType"
        },
        // {
        //     label: "Department",
        //     key: "department"
        // },
        // {
        //     label: 'Section',
        //     key: "section"
        // },
        {
            label: 'Designation',
            key: "designation"
        },
        {
            label: 'Recommendation Status',
            key: "recommendationStatus"
        },
        {
            label: 'Authorisation Status',
            key: "authorisationStatus"
        },
        {
            label: 'Account Type',
            key: "accountType"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const handleNewUserRequest = () => {
        setUserRequestModalOpen(true);
    }
    
    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
    ];


    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedUserItem(rowItem);
        if(type === "sign"){
            setSelectedUserItem(rowItem);
           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setUserRequestModalOpen(true)
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteActing({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    onChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewUserRequest}
                        >
                            {"Acting Form"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { !loading ? userActingList && userActingList.getAllActings && userActingList.getAllActings.data.length > 0 ?
                                userActingList.getAllActings.data.map((rowitem, rowIndex) => (
                                    <ActingFormPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        menuItems={menuItems}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow> : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ActingFormModal
                open={userRequestModalOpen}
                handleClose={evt => setUserRequestModalOpen(false)}
                modalState={crModalState}
                users={users}
                userdata={userdata && userdata.userInfo}
                data={selectedUserItem}
                departments={departments}
                signatures={signatureState}
                handleOpenSignPanModal={handleOpenSignPanModal}
                handleCreateRequestSubmit={handleCreateRequestSubmit}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    );
}

ActingFormPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default ActingFormPanel;
