import {gql} from 'apollo-boost';
/*
  Update pmo project owner
 */
export const UPDATE_PMO_PROJECT = gql`
    mutation UpdatePmoProject($uid: String, $project_owner: String, $status: String, $is_rejected: Boolean, $rejected_description: String, $billing_country: String, $category: String, $on_hold: Boolean){
        updatePmoProject(uid: $uid, project_owner: $project_owner, status: $status, is_rejected: $is_rejected, rejected_description: $rejected_description, billing_country: $billing_country, category: $category, on_hold: $on_hold) {
            status
        }
    }
`;
/*
  Update pmo project type
 */
export const UPDATE_PMO_PROJECT_TYPE = gql`
    mutation UpdatePmoProjectType($uid: String, $project_type: String){
        updatePmoProjectType(uid: $uid, project_type: $project_type) {
            status
        }
    }
`;
/* Close pmo special project */
  export const CLOSE_PMO_SPECIAL_PROJECT = gql`
  mutation ClosePmoSpecialProject($uid: String){
    closePmoSpecialProject(uid: $uid) {
          status
      }
  }
`;

// add install team
// export const ADD_INSTALL_TEAM = gql`
//     mutation AddInstallTeamSection($formData: JSON){
//         addInstallTeamSection(formData: $formData) {
//             teamData
//             status
//             message
//         }
//     }
// `;
export const ADD_INSTALL_TEAM = gql`
    mutation AddInstallTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $platform: String, $change_control_questionaire_form: String, $circuit_id: String, $im_team: String, $attach_manual: String, $previous_comment: String, $comment: String, $team_name: String, $finance_email: String, $next_team_member_email: String){
        addInstallTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, platform: $platform, circuit_id: $circuit_id, change_control_questionaire_form: $change_control_questionaire_form, im_team: $im_team, attach_manual: $attach_manual, previous_comment: $previous_comment, comment: $comment, team_name: $team_name, finance_email: $finance_email, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// addInstallTeamSection(project_uid: String, auto_generated_project_id: String, equipments_to_be_deployed: String, circuit_id: String, im_team: String, attach_manual: Upload, previous_comment: String, comment: String): TeamData
// add install team lead
export const ADD_INSTALL_TEAM_LEAD = gql`
    mutation AddInstallTeamLeadSection($project_uid: String, $last_team_member_name: String, $equipments_to_be_deployed: String, $documents: JSON, $project_type: String, $change_control_questionaire_form: String, $im_team: String, $previous_comment: String, $comment: String, $recommendation: String, $cost_control_form: String, $project_files: String, $site_array: JSON){
        addInstallTeamLeadSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, equipments_to_be_deployed: $equipments_to_be_deployed, documents: $documents, project_type: $project_type, change_control_questionaire_form: $change_control_questionaire_form, im_team: $im_team, previous_comment: $previous_comment, comment: $comment, recommendation: $recommendation, cost_control_form: $cost_control_form, project_files: $project_files, site_array: $site_array) {
            teamData
            status
            message
        }
    }
`;
// add install team
// export const ADD_INSTALL_TEAM = gql`
//     mutation AddInstallTeamSection($project_uid: String, $equipments_to_be_deployed: String, $change_control_questionaire_form: String, $im_team: String, $previous_comment: String, $comment: String, $recommendation: String, $cost_control_form: String, $project_files: String, $site_array: JSON){
//         addInstallTeamSection(project_uid: $project_uid, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_questionaire_form: $change_control_questionaire_form, im_team: $im_team, previous_comment: $previous_comment, comment: $comment, recommendation: $recommendation, cost_control_form: $cost_control_form, project_files: $project_files, site_array: $site_array) {
//             teamData
//             status
//             message
//         }
//     }
// `;
// add im team
export const ADD_IM_TEAM = gql`
    mutation AddIMTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $change_control_questionaire_form: String, $circuit_id: String, $change_control_team: String, $attach_manual: String, $documents: JSON, $paste_any_other_info: String, $previous_comment: String, $comment: String, $next_team_member_email: String){
        addIMTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_questionaire_form: $change_control_questionaire_form, circuit_id: $circuit_id, change_control_team: $change_control_team, attach_manual: $attach_manual, documents: $documents, paste_any_other_info: $paste_any_other_info, previous_comment: $previous_comment, comment: $comment, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add change control team
export const ADD_CHNAGE_CONTROL_TEAM = gql`
    mutation AddChangeControlTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $change_control_form: String, $circuit_id: String, $generate_config_change_form: String, $attach_manual: String, $integration_engineer: String, $previous_comment: String, $comment: String, $next_team_member_email: String){
        addChangeControlTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_form: $change_control_form, circuit_id: $circuit_id, generate_config_change_form: $generate_config_change_form, attach_manual: $attach_manual, integration_engineer: $integration_engineer, previous_comment: $previous_comment, comment: $comment, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add integration engineer team
export const ADD_INTEGRATION_ENGINEER_TEAM = gql`
    mutation AddIntegrationEngineerTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $change_control_form: String, $circuit_id: String, $attach_manual: String, $config_change_form: String, $ping_result: String, $documents:JSON, $quality_assurance: String, $previous_comment: String, $comment: String, $next_team_member_email: String){
        addIntegrationEngineerTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_form: $change_control_form, circuit_id: $circuit_id, attach_manual: $attach_manual, config_change_form: $config_change_form, ping_result: $ping_result, documents: $documents, quality_assurance: $quality_assurance, previous_comment: $previous_comment, comment: $comment, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add quality assurance team
export const ADD_QUALITY_ASSURANCE_TEAM = gql`
    mutation AddQualityAssuranceTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $change_control_form: String, $circuit_id: String, $attach_manual: String, $config_change_form: String, $ping_result: String, $documents:JSON, $quality_assurance: String, $attach: String, $previous_comment: String, $comment: String, $project_management: String, $basestation: String, $location: String, $configured_up_circuit: String, $configured_down_circuit: String, $product_class: String, $node: String, $status: String, $billing_date: String, $bucket_level: String, $vendor: String, $node_code: String, $product_description: String, $media_description: String, $platform: String, $service_description: String, $region: String, $city: String, $billing: String, $next_team_member_email: String){
        addQualityAssuranceTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_form: $change_control_form, circuit_id: $circuit_id, attach_manual: $attach_manual, config_change_form: $config_change_form, ping_result: $ping_result, documents: $documents, quality_assurance: $quality_assurance, attach: $attach, previous_comment: $previous_comment, comment: $comment, project_management: $project_management, basestation: $basestation, location: $location, configured_up_circuit: $configured_up_circuit, configured_down_circuit: $configured_down_circuit , product_class: $product_class, node: $node, status: $status, billing_date: $billing_date, bucket_level: $bucket_level, vendor: $vendor, node_code: $node_code, product_description: $product_description, media_description: $media_description, platform: $platform, service_description: $service_description, region: $region, city: $city, billing: $billing, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add project management team
export const ADD_PROJECT_MANAGEMENT_TEAM = gql`
    mutation AddProjectManagementTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $job_completion_certificate: String, $fault_information_management_matrix: String, $letter_service_details: String, $billing_start_date: String, $change_control_form: String, $circuit_id: String, $attach_manual: String, $config_change_form: String, $ping_result: String, $send_email: String, $finance_detail: JSON, $finance: String, $finance_email: String, $previous_comment: String, $comment: String, $bit_type: String, $next_team_member_email: String){
        addProjectManagementTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, job_completion_certificate: $job_completion_certificate, fault_information_management_matrix: $fault_information_management_matrix, letter_service_details: $letter_service_details, billing_start_date: $billing_start_date, change_control_form: $change_control_form, circuit_id: $circuit_id, attach_manual: $attach_manual, config_change_form: $config_change_form, finance_email: $finance_email, ping_result: $ping_result, send_email: $send_email, finance_detail: $finance_detail, finance: $finance, previous_comment: $previous_comment, comment: $comment, bit_type: $bit_type, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add finance team
export const ADD_FINANCE_TEAM = gql`
    mutation AddFinanceTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $job_completion_certificate: String, $fault_information_management_matrix: String, $letter_service_details: String, $billing_start_date: String, $change_control_form: String, $circuit_id: String, $attach_manual: String, $config_change_form: String, $ping_result: String, $finance_detail: JSON, $documents: JSON, $send_email: String, $csoc: String, $previous_comment: String, $comment: String, $next_team_member_email: String){
        addFinanceTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, job_completion_certificate: $job_completion_certificate, fault_information_management_matrix: $fault_information_management_matrix, letter_service_details: $letter_service_details, billing_start_date: $billing_start_date, change_control_form: $change_control_form, circuit_id: $circuit_id, attach_manual: $attach_manual, config_change_form: $config_change_form, ping_result: $ping_result, finance_detail: $finance_detail, documents: $documents, send_email: $send_email, csoc: $csoc, previous_comment: $previous_comment, comment: $comment, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add CSOC team
export const ADD_CSOC_TEAM = gql`
    mutation AddCSOCTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $change_control_form: String, $circuit_id: String, $generate_manual: String, $config_change_form: String, $ping_result: String, $fault_management: String, $pm_forms: String, $previous_comment: String, $comment: String, $next_team_member_email: String){
        addCSOCTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, change_control_form: $change_control_form, circuit_id: $circuit_id, generate_manual: $generate_manual, config_change_form: $config_change_form, ping_result: $ping_result, fault_management: $fault_management, pm_forms: $pm_forms, previous_comment: $previous_comment, comment: $comment, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// add CSOC team
export const ADD_RADIO_ENGINEERING_TEAM = gql`
    mutation AddRadioEngineeringTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $project_files: String, $recommendation: String, $install_team: String, $previous_comment: String, $comment: String){
        addRadioEngineeringTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, project_files: $project_files, recommendation: $recommendation, install_team: $install_team, previous_comment: $previous_comment, comment: $comment) {
            teamData
            status
            message
        }
    }
`;
// add CSOC team
export const ADD_OUTSIDE_PROJECT_MANAGEMENT_TEAM = gql`
    mutation AddOutsideProjectManagementTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $equipments_to_be_deployed: String, $project_files: String, $cost_control_form: String, $install_team: String, $purchase_order_form: String, $select_vendor: String, $email_vendor: String, $next_team_member_email: String){
        addOutsideProjectManagementTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, equipments_to_be_deployed: $equipments_to_be_deployed, project_files: $project_files, cost_control_form: $cost_control_form, install_team: $install_team, purchase_order_form: $purchase_order_form, select_vendor: $select_vendor, email_vendor: $email_vendor, next_team_member_email: $next_team_member_email) {
            teamData
            status
            message
        }
    }
`;
// create change control request form
export const CREATE_CHANGE_CONTROL_REQUEST_FORM = gql`
    mutation CreateCostControlRequest($formData: String){
        createCostControlRequest(formData: $formData) {
            teamData
            status
            message
        }
    }
`;

// create feetport form
export const CREATE_FEETPORT_FORM = gql`
    mutation CreateFeetportForm($formData: String){
        createFeetportForm(formData: $formData) {
            teamData
            status
            message
        }
    }
`;
// create JobOrder form
export const CREATE_JOB_ORDER_FORM = gql`
    mutation CreateJobOrderForm($formData: String){
        createJobOrderForm(formData: $formData) {
            teamData
            status
            message
        }
    }
`;
// create Project charter form
export const CREATE_PROJECT_CHARTER_FORM = gql`
    mutation CreateProjectCharterForm($formData: String){
        createProjectCharterForm(formData: $formData) {
            teamData
            status
            message
        }
    }
`;
// create store request form
export const CREATE_STORE_REQUEST_FORM = gql`
    mutation CreateStoreRequestForm($formData: String){
        createStoreRequestForm(formData: $formData) {
            teamData
            status
            message
        }
    }
`;
// create Site acceptance form for IvoryCoast team section
export const CREATE_SITE_ACCEPTANCE_FORM = gql`
  mutation CreateSiteAcceptanceForm($formData: String){
    createSiteAcceptanceForm(formData: $formData) {
        teamData
        status
        message
    }
  }
`;
export const UPLOAD_FILES = gql`
  mutation AllUploadFile($file: Upload!, $project_uid: String, $team_name: String){
    allUploadFile(file: $file, project_uid: $project_uid, team_name: $team_name) {
        url
        filename
        mimetype
        encoding
    }
  }
`;
// add activation team
export const ADD_ACTIVATION_TEAM = gql`
    mutation AddActivationTeamSection($project_uid: String, $last_team_member_name: String, $customer_name: String, $location: String, $assigned_Ip: String, $vlan: String, $service_Id: String, $allocated_bandwidth: String, $sideA_address: String, $sideB_address: String, $base_station: String, $domain_name: String, $domain_owner: String, $contact_person_details: String, $domain_service_attached: String, $email_package: String, $address_service: String, $VMIP: String, $storage_capacity: String, $capicity_allocated: String, $VM_specs: String, $number_of_machines: String){
        addActivationTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, customer_name: $customer_name, location: $location, assigned_Ip: $assigned_Ip, vlan: $vlan, service_Id: $service_Id, allocated_bandwidth: $allocated_bandwidth, sideA_address: $sideA_address, sideB_address: $sideB_address, base_station: $base_station, domain_name: $domain_name, domain_owner: $domain_owner, contact_person_details: $contact_person_details, domain_service_attached: $domain_service_attached, capicity_allocated: $capicity_allocated, email_package: $email_package, address_service: $address_service, VMIP: $VMIP, storage_capacity: $storage_capacity, VM_specs: $VM_specs, number_of_machines: $number_of_machines) {
            teamData
            status
            message
        }
    }
`;
// create service delivery form
export const ADD_SERVICE_DELIVERY_TEAM = gql`
  mutation AddServiceDeliveryTeamSection($feetport_form_url: String, $project_uid: String, $last_team_member_name: String, $stock_control_team: String, $next_team_member_email: String){
    addServiceDeliveryTeamSection(feetport_form_url: $feetport_form_url, project_uid: $project_uid, last_team_member_name: $last_team_member_name, stock_control_team: $stock_control_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create stock control form
export const ADD_STOCK_CONTROL_TEAM = gql`
  mutation addStockControlTeamSection($job_order_form_url: String, $feetport_form_url: String, $project_uid: String, $last_team_member_name: String, $field_engineer: String, $next_team_member_email: String){
    addStockControlTeamSection(job_order_form_url: $job_order_form_url, feetport_form_url: $feetport_form_url, project_uid: $project_uid, last_team_member_name: $last_team_member_name, field_engineer: $field_engineer, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create install team for Malawi form
export const ADD_INSTALL_TEAM_MALAWI = gql`
  mutation AddInstallTeamMalawieamSection($job_order_form_url: String, $feetport_form_url: String, $project_uid: String, $last_team_member_name: String, $quality_assurance_team: String, $next_team_member_email: String, $upload_pics: JSON, $signal: String){
    addInstallTeamMalawieamSection(job_order_form_url: $job_order_form_url, feetport_form_url: $feetport_form_url, project_uid: $project_uid, last_team_member_name: $last_team_member_name, quality_assurance_team: $quality_assurance_team, next_team_member_email: $next_team_member_email, upload_pics: $upload_pics, signal: $signal) {
        teamData
        status
        message
    }
  }
`;
// create quality assurance for Malawi form
export const ADD_QUALITY_ASSURANCE_TEAM_MALAWI = gql`
  mutation AddQualityAssuranceTeamMalawiSection($job_order_form_url: String, $feetport_form_url: String, $project_uid: String, $last_team_member_name: String, $assign_to_billing_to_bill: String, $next_team_member_email: String, $confirm_installation_standar: Boolean){
    addQualityAssuranceTeamMalawiSection(job_order_form_url: $job_order_form_url, feetport_form_url: $feetport_form_url, project_uid: $project_uid, last_team_member_name: $last_team_member_name, assign_to_billing_to_bill: $assign_to_billing_to_bill, next_team_member_email: $next_team_member_email, confirm_installation_standar: $confirm_installation_standar) {
        teamData
        status
        message
    }
  }
`;
// create quality assurance for Malawi form
export const ADD_INTERNAL_PROJECTS = gql`
  mutation addInternalProject($project_name: String, $project_description: String, $billing_country: String, $opportunity: JSON, $attachments: JSON, $is_internal_project: Boolean, $customer_name: String){
    addInternalProject(project_name: $project_name, project_description: $project_description, billing_country: $billing_country, opportunity: $opportunity, attachments: $attachments, is_internal_project: $is_internal_project, customer_name: $customer_name) {
        teamData
        status
        message
    }
  }
`;
// create project for Zambia team section
export const ADD_PROJECT_ZAMBIA_SECTION = gql`
  mutation AddProjectZambiaTeamSection($project_uid: String, $last_team_member_name: String, $site_array: JSON, $account_name: String, $account_number: String, $equipment_type: String, $sla: String, $attach_quotation: String, $bdu_team: String, $next_team_member_email: String){
    addProjectZambiaTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, site_array: $site_array, account_name: $account_name, account_number: $account_number, equipment_type: $equipment_type, sla: $sla, attach_quotation: $attach_quotation, bdu_team: $bdu_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create project for Zambia team section
export const ADD_BDU_TEAM_SECTION = gql`
  mutation AddBduTeamSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $last_mile_type: String, $router_type: String, $additional_requirements: String, $attach_installtion_form: String, $install_team_person_name: String, $next_team_member_email: String){
    addBduTeamSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, last_mile_type: $last_mile_type, router_type: $router_type, additional_requirements: $additional_requirements, attach_installtion_form: $attach_installtion_form, install_team_person_name: $install_team_person_name, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create project for Zambia team section
export const ADD_INSTALL_TEAM_ZAMBIA_SECTION = gql`
  mutation AddInstallTeamZambiaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $provisioning_of_account: String, $installation_requirements: String, $installation_complete_date: String, $material_user: String, $sign_of_installation_form: String, $project_manage_ment_team_name: String, $next_team_member_email: String, $bdu_team: String, $bdu_team_email: String){
    addInstallTeamZambiaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, provisioning_of_account: $provisioning_of_account, installation_requirements: $installation_requirements, installation_complete_date: $installation_complete_date, material_user: $material_user, sign_of_installation_form: $sign_of_installation_form, project_manage_ment_team_name: $project_manage_ment_team_name, next_team_member_email: $next_team_member_email, bdu_team: $bdu_team, bdu_team_email: $bdu_team_email) {
        teamData
        status
        message
    }
  }
`;
// create PM for Zambia team section
export const ADD_PM_TEAM_ZAMBIA_SECTION = gql`
  mutation AddPMTeamZambiaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $soak_period: String, $soak_completion_date: String, $project_completion_certificate: String, $activattion_form: String, $quotation: String, $discount: String, $billing_team_name: String, $next_team_member_email: String){
    addPMTeamZambiaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, soak_period: $soak_period, soak_completion_date: $soak_completion_date, project_completion_certificate: $project_completion_certificate, activattion_form: $activattion_form, quotation: $quotation, discount: $discount, billing_team_name: $billing_team_name, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create billing for Zambia team section
export const ADD_BILLING_TEAM_ZAMBIA_SECTION = gql`
  mutation AddBillingTeamZambiaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $update_billing_system: String, $sends_back_to_projects_for_closure: String, $csoc_team_name: String, $next_team_member_email: String){
    addBillingTeamZambiaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, update_billing_system: $update_billing_system, sends_back_to_projects_for_closure: $sends_back_to_projects_for_closure, csoc_team_name: $csoc_team_name, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create csoc for Zambia team section
export const ADD_CSOC_TEAM_ZAMBIA_SECTION = gql`
  mutation AddCSOCTeamZambiaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $project_completion_form: String){
    addCSOCTeamZambiaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, project_completion_form: $project_completion_form) {
        teamData
        status
        message
    }
  }
`;
// create ACTIVATION for Botwana team section
export const ADD_ACTIVATION_TEAM_BOTSWANA_SECTION = gql`
  mutation AddActivationTeamBotswanaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $client_name: String, $service_id: String, $vlan: String, $material_user: String, $base_station: String, $domain_name: String, $next_team_member_email: String, $backup_capacity: String, $contact_person_details: String, $vm_specs: String, $vm_ip: String){
    addActivationTeamBotswanaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, client_name: $client_name, service_id: $service_id, vlan: $vlan, material_user: $material_user, base_station: $base_station, domain_name: $domain_name, next_team_member_email: $next_team_member_email, backup_capacity: $backup_capacity, contact_person_details: $contact_person_details, vm_specs: $vm_specs, vm_ip: $vm_ip) {
        teamData
        status
        message
    }
  }
`;
// create DEVELOPER for Botwana team section
export const ADD_DEVELOPER_TEAM_BOTSWANA_SECTION = gql`
  mutation AddDeveloperTeamBotswanaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $service_test_result: String, $job_card: String, $site_service_picture: String, $equipment_deployed: String, $pm_team: String, $next_team_member_email: String){
    addDeveloperTeamBotswanaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, service_test_result: $service_test_result, job_card: $job_card, site_service_picture: $site_service_picture, equipment_deployed: $equipment_deployed, pm_team: $pm_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create client service team for Botwana team section
export const ADD_CLIENT_SERVICE_TEAM_BOTSWANA_SECTION = gql`
  mutation AddClientServiceTeamBotswanaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $sale_experience: String, $technical_experience: String, $finance_experience: String, $service_satisfaction: String, $any_other_business: String, $project_manage_ment_team_name: String, $next_team_member_email: String, $finance_team: String){
    addClientServiceTeamBotswanaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, sale_experience: $sale_experience, technical_experience: $technical_experience, finance_experience: $finance_experience, service_satisfaction: $service_satisfaction, any_other_business: $any_other_business, project_manage_ment_team_name: $project_manage_ment_team_name, next_team_member_email: $next_team_member_email, finance_team: $finance_team) {
        teamData
        status
        message
    }
  }
`;
// create pm for Botwana team section
export const ADD_PM_TEAM_BOTSWANA_SECTION = gql`
  mutation AddPMTeamBotswanaSection($project_uid: String, $last_team_member_name: String, $auto_generated_project_id: String, $client_service_team: String, $next_team_member_email: String){
    addPMTeamBotswanaSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, auto_generated_project_id: $auto_generated_project_id, client_service_team: $client_service_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create SERVICE_DELIVERY for Cameroon team section
export const ADD_SERVICE_DELIVERY_TEAM_CAMEROON_SECTION = gql`
  mutation AddServiceDeliveryTeamCameroonSection($project_uid: String, $last_team_member_name: String, $project_charter_form_url: String, $upload_project_plan: String, $noc_team: String $next_team_member_email: String){
    addServiceDeliveryTeamCameroonSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, project_charter_form_url: $project_charter_form_url, upload_project_plan: $upload_project_plan, noc_team: $noc_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create NOC for Cameroon team section
export const ADD_NOC_TEAM_CAMEROON_SECTION = gql`
  mutation AddNOCTeamCameroonSection($project_uid: String, $last_team_member_name: String, $upload_change_control_doc: String, $field_operation_team: String, $next_team_member_email: String){
    addNOCTeamCameroonSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, upload_change_control_doc: $upload_change_control_doc, field_operation_team: $field_operation_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create FIELD_OPERATIONS for Cameroon team section
export const ADD_FIELD_OPERATIONS_TEAM_CAMEROON_SECTION = gql`
  mutation AddFieldOperationsTeamCameroonSection($project_uid: String, $last_team_member_name: String, $store_request_form_url: String, $upload_installation_report: String, $csoc_team: String, $next_team_member_email: String){
    addFieldOperationsTeamCameroonSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, store_request_form_url: $store_request_form_url, upload_installation_report: $upload_installation_report, csoc_team: $csoc_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create CSOC for Cameroon team section
export const ADD_CSOC_TEAM_CAMEROON_SECTION = gql`
  mutation AddCSOCTeamCameroonSection($project_uid: String, $last_team_member_name: String, $upload_qa_test_result: String, $finance_team: String, $next_team_member_email: String){
    addCSOCTeamCameroonSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, upload_qa_test_result: $upload_qa_test_result, finance_team: $finance_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create Finance for Cameroon team section
export const ADD_FINANCE_TEAM_CAMEROON_SECTION = gql`
  mutation AddFinanceTeamCameroonSection($project_uid: String, $last_team_member_name: String, $upload_signed_site_doc: String, $next_team_member_email: String){
    addFinanceTeamCameroonSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, upload_signed_site_doc: $upload_signed_site_doc, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create SALES TEAM for IvoryCoast team section
export const ADD_SALES_TEAM_IVORY_COAST_SECTION = gql`
  mutation AddSalesTeamIvoryCoastSection($project_uid: String, $last_team_member_name: String, $salesforce: String, $client_name: String, $physical_address: String, $contact_person: String, $gps_cordinate: String, $email: String, $delivery_due_date: String, $tax_payer_number: String, $rccm: String, $it_team: String, $next_team_member_email: String){
    addSalesTeamIvoryCoastSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, salesforce: $salesforce, client_name: $client_name, physical_address: $physical_address, contact_person: $contact_person, gps_cordinate: $gps_cordinate, email: $email, delivery_due_date: $delivery_due_date, tax_payer_number: $tax_payer_number, rccm: $rccm, it_team: $it_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create IT for IvoryCoast team section
export const ADD_IT_TEAM_IVORY_COAST_SECTION = gql`
  mutation AddItTeamIvoryCoastSection($project_uid: String, $last_team_member_name: String, $field_engineer: String, $products_or_solution: String, $job_order_item: String, $submit_for_approval: String, $finance_team: String, $next_team_member_email: String){
    addItTeamIvoryCoastSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, field_engineer: $field_engineer, products_or_solution: $products_or_solution, job_order_item: $job_order_item, submit_for_approval: $submit_for_approval, finance_team: $finance_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create FINACE for IvoryCoast team section
export const ADD_FINANCE_TEAM_IVORY_COAST_SECTION = gql`
  mutation AddFinanceTeamIvoryCoastSection($project_uid: String, $last_team_member_name: String, $cost_and_margin: String, $vetting_of_client: String, $delivery_team: String, $next_team_member_email: String){
    addFinanceTeamIvoryCoastSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, cost_and_margin: $cost_and_margin, vetting_of_client: $vetting_of_client, delivery_team: $delivery_team, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create DELIVERY for IvoryCoast team section
export const ADD_DELIVERY_TEAM_IVORY_COAST_SECTION = gql`
  mutation AddDeliveryTeamIvoryCoastSection($project_uid: String, $last_team_member_name: String, $site_acceptance: String){
    addDeliveryTeamIvoryCoastSection(project_uid: $project_uid, last_team_member_name: $last_team_member_name, site_acceptance: $site_acceptance) {
        teamData
        status
        message
    }
  }
`;
// create camtel section
export const ADD_CAMTEL_SECTION = gql`
  mutation AddCamtelFormSection($nin: String, $surname: String, $middle_name: String, $first_name: String, $mothers_maider_name: String, $means_of_identification: String, $phone_number: String, $alternate_phone_number: String, $email_address: String, $nationality: String, $date_of_birth: String, $address: String, $profession: String, $height: String, $gender: String, $profile_photo: String, $signature: String){
    addCamtelFormSection(nin: $nin, surname: $surname, middle_name: $middle_name, first_name: $first_name, mothers_maider_name: $mothers_maider_name, means_of_identification: $means_of_identification, phone_number: $phone_number, alternate_phone_number: $alternate_phone_number, email_address: $email_address, nationality: $nationality, date_of_birth: $date_of_birth, address: $address, profession: $profession, height: $height, gender: $gender, profile_photo: $profile_photo, signature: $signature) {
        teamData
        status
        message
    }
  }
`;
export const GET_EXCEL_FILE_URL_BI_DASHBOARD_ANALYTICS_TABLE = gql`
  mutation GetExcelFileURLBidashboardAnalyticsTable($formData: String){
    getExcelFileURLBidashboardAnalyticsTable(formData: $formData) {
        teamData
        status
        message
    }
  }
`;