import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
  });
  

const useStyles = makeStyles({
    root: { fontSize: '16px'},
    pre: { padding: '10px 30px', margin: '0' }
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const VideoPlayer = ({
    t,
    videoUrl,
    open,
    handleCloseVideo
}) => {
    const classes = useStyles();
    return (
        <Dialog 
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleCloseVideo}
            aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseVideo} />
          <DialogContent dividers className={classes.dialogContain}>
            <video style={{ width: 690, height: 440, marginLeft: 80}} controls>
             <source src={videoUrl} type="video/mp4" />
            </video>
          </DialogContent>
        </Dialog>
    )
}

export default VideoPlayer
