import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { checkInternalPlatform } from '../../../../utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(16),
    maxWidth: '1100px',
    marginBottom: theme.spacing(12)
  }
}));

function SecondaryLayout({ children, contentStyle, scope, hostLogoUrl }) {
  const classes = useStyles();
  const otherPlatform = checkInternalPlatform();
  
  return (
    <>
      <Navbar scope={scope} hostLogoUrl={hostLogoUrl} />
      <Container
        className={classes.content}
        style={contentStyle}
        disableGutters={true}>
        {children}
      </Container>
      {!otherPlatform && <Footer />}
    </>
  );
}

SecondaryLayout.propTypes = {
  children: PropTypes.node.isRequired,
  contentStyle: PropTypes.object,
  scope: PropTypes.string
};

export default SecondaryLayout;
