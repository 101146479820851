import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';
import ActingForm2 from '../../../../../../../assets/icons/actingForm2.png';
import ActingForm from '../../../../../../../assets/icons/actingForm.png';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '210px',
        borderWidth:0.3,
        borderRadius:1,
        borderColor:'black'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    jobTitle: "",
    userFullName: "",
    prNumber: "",
    jobGrade: "",
    appointedTo: "",
    fromDate: null,
    toDate: null,
    noOfDays: "",
    relivedEmployeeName: "",
    relivedGrade: "",
    relivedJobTitle: "",
    reasonForActing: "",
    originator: "",
    hrManagerText: "",
    mbaManager: "",
    HOD: "",
    finantialAccountant: "",
    originatorSign: "",
    mbaManagerSign: "",
    HODSign: "",
    finantialAccountantSign: "",
    actualForpayment: "",
    occupationActed: "",
    jobGradeActing: "",
    salaryScale: "",
    occActingEmp: "",
    jobGradeActingEmp: "",
    basicPay: "",
    difference : "",
    generalComment: "",
    clDate: null
};

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    return imgURL;
}

function ActingFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [departmentSections, setDepartmentSections] = useState([]);
    const [showOriginator, setShowOriginator] = useState(false);
    const [showMbaManager, setShowMbaManager] = useState(false);
    const [showHOD, setShowHOD] = useState(false);
    const [showFinantialAccountant, setShowFinantialAccountant] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setShowOriginator((data.createdById === userdata.id) ? true : false);
            setShowMbaManager((data.createdById === userdata.id) ? true : false);
            setShowHOD((data.createdById === userdata.id) ? true : false);
            setShowFinantialAccountant((data.createdById === userdata.id) ? true : false);
            setURFormState({...data, id });
        }else{
            setShowOriginator(true);
            setShowMbaManager(true)
            setShowHOD(true)
            setShowFinantialAccountant(true)
        }
    }, [data]);

    const handleChange = (value, name) => {
        if (name === "allBenefits") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.allBenefits.push(value.target.value);
                } else {
                    let index = newState.allBenefits.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.allBenefits.splice(index, 1);
                    }
                }
                return newState;
            });
        }else if(name === 'allowances'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.allowances.push(value.target.value);
                } else {
                    let index = newState.allowances.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.allowances.splice(index, 1);
                    }
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                    <Grid container spacing={4} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >ACTING APPOINTMENT FORM</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Name</h5>
                        </Grid>
                        <Grid item xs={5}>
                          <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.userFullName}
                                onChange={(evt) => handleChange(evt.target.value, "userFullName")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Job Title</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.jobTitle}
                                onChange={(evt) => handleChange(evt.target.value, "jobTitle")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>PR Number</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input style={{ fontSize: 15, fontWeight: 200 }}
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.prNumber}
                                onChange={(evt) => handleChange(evt.target.value, "prNumber")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Job Grade</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.jobGrade}
                                onChange={(evt) => handleChange(evt.target.value, "jobGrade")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Appointed To</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.appointedTo}
                                onChange={(evt) => handleChange(evt.target.value, "appointedTo")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>From (Date)</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 320, marginTop: 20 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.fromDate}
                                handleChange={newDate => handleChange(newDate, "fromDate")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>To (Date)</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 320, marginTop: 20 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.toDate}
                                handleChange={newDate => handleChange(newDate, "toDate")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}> No. of Days</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.noOfDays}
                                onChange={(evt) => handleChange(evt.target.value, "noOfDays")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Employee being Relieved</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.relivedEmployeeName}
                                onChange={(evt) => handleChange(evt.target.value, "relivedEmployeeName")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Relieved Job Grade / Band</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.relivedGrade}
                                onChange={(evt) => handleChange(evt.target.value, "relivedGrade")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 400 }}>Relieved Job Title</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 20 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.relivedJobTitle}
                                onChange={(evt) => handleChange(evt.target.value, "relivedJobTitle")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontSize: 15, fontWeight: 400 }}>Reason for acting</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="textarea"
                                variant="standard"
                                fullWidth={true}
                                multiline
                                rows={5}
                                onChange={(evt) => handleChange(evt.target.value, "reasonForActing")}
                                value={urFormState.reasonForActing}
                            />
                        </Grid>
                    </Grid>

                <Paper className={classes.paper}>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showOriginator || data.originator === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>Originator</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>Originator</Typography>
                            </Grid>}
                        {showOriginator &&
                            <Grid item xs={5}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="Originator"
                                    disabled={(data && data.originatorSign) ? true : false}
                                    val={urFormState.originator}
                                    handleChange={(evt) => handleChange(evt.target.value, "originator")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.originator === userdata.id) ? (
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("originatorSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        {((signatures && signatures.originatorSign) || (data && data.originatorSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.originatorSign) ? data.originatorSign : signatures.originatorSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showMbaManager || data.mbaManager === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>MBA Manager/Section Head</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>MBA Manager/Section Head</Typography>
                            </Grid>}
                        {showMbaManager &&
                            <Grid item xs={5}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="MBA Manager"
                                    disabled={(data && data.mbaManagerSign) ? true : false}
                                    val={urFormState.mbaManager}
                                    handleChange={(evt) => handleChange(evt.target.value, "mbaManager")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.mbaManager === userdata.id) ? (
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("mbaManagerSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        {((signatures && signatures.mbaManagerSign) || (data && data.mbaManagerSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.mbaManagerSign) ? data.mbaManagerSign : signatures.mbaManagerSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showHOD || data.HOD === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>Head of Department</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>Head of Department</Typography>
                            </Grid>}
                        {showHOD &&
                            <Grid item xs={5}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="Head of Department"
                                    disabled={(data && data.HODSign) ? true : false}
                                    val={urFormState.HOD}
                                    handleChange={(evt) => handleChange(evt.target.value, "HOD")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.HOD === userdata.id) ? (
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("HODSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        {((signatures && signatures.HODSign) || (data && data.HODSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.HODSign) ? data.HODSign : signatures.HODSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ fontWeight: 500 }}>Human Resources Manager/Advisor</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            style={{ width: '100%', marginTop: 50, marginBottom: 20}}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.hrManagerText}
                            onChange={(evt) => handleChange(evt.target.value, "hrManagerText")}
                        />
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >DETERMINATION - ACTING APPOINTMENT (CU AND ABOVE)</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Actual for Payment</Typography>
                            <Input
                                type={'text'}
                                placeholder={"Actual for Payment"}
                                style={{ width: '100%', marginTop: 50}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.actualForpayment}
                                onChange={(evt) => handleChange(evt.target.value, "actualForpayment")}
                            />
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}} >
                            <img src={ActingForm2} alt="actingForm2" />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={4} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >DETERMINATION - ACTING APPOINTMENT (CL AND BELOW)</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Occupation being acted for</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.occupationActed}
                                onChange={(evt) => handleChange(evt.target.value, "occupationActed")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Job Grade</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.jobGradeActing}
                                onChange={(evt) => handleChange(evt.target.value, "jobGradeActing")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Salary (Scale Minimum) (P)</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.salaryScale}
                                onChange={(evt) => handleChange(evt.target.value, "salaryScale")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Occupation of acting employee</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.occActingEmp}
                                onChange={(evt) => handleChange(evt.target.value, "occActingEmp")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Job Grade</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.jobGradeActingEmp}
                                onChange={(evt) => handleChange(evt.target.value, "jobGradeActingEmp")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Basic Pay (P)</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.basicPay}
                                onChange={(evt) => handleChange(evt.target.value, "basicPay")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4" style={{ fontSize: 15, fontWeight: 200 }}>Difference (P)</Typography>
                            <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 40 }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.difference}
                                onChange={(evt) => handleChange(evt.target.value, "difference")}
                            />
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}} >
                            <img src={ActingForm} alt="actingForm" />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >APPROVAL</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="textarea"
                                placeholder="Comments..."
                                variant="standard"
                                fullWidth={true}
                                multiline
                                rows={5}
                                onChange={(evt) => handleChange(evt.target.value, "generalComment")}
                                value={urFormState.generalComment}
                            />
                        </Grid>
                        <Grid container spacing={4} style={{ marginBottom: 20 }} >
                            {(data && (showFinantialAccountant || data.finantialAccountant === userdata.id)) ?
                                <Grid item xs={12}>
                                    <Typography variant="h4" >Financial Accountant</Typography>
                                </Grid> : <Grid item xs={12}>
                                    <Typography variant="h4" >Financial Accountant</Typography>
                                </Grid>}
                            {showFinantialAccountant &&
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        label="Financial Accountant"
                                        disabled={(data && data.finantialAccountantSign) ? true : false}
                                        val={urFormState.finantialAccountant}
                                        handleChange={(evt) => handleChange(evt.target.value, "finantialAccountant")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                            }
                            {(data && data.finantialAccountant === userdata.id) ? (
                                <Grid xs={1}>
                                    <IconButton
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("finantialAccountantSign")}>
                                        <img src={SignIcon} width="26" height={26} />
                                    </IconButton>
                                </Grid>
                            ) : null}
                            {((signatures && signatures.finantialAccountantSign) || (data && data.finantialAccountantSign)) &&
                                <Grid xs={1}>
                                    <img src={convertBase64ToUrl((data && data.finantialAccountantSign) ? data.finantialAccountantSign : signatures.finantialAccountantSign)} width={60} height={60} />
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h4" style={{ fontSize: 17, fontWeight: 600}}>Signature</Typography>
                            <IconButton style={{ marginLeft: 20, marginBottom: 50 }}
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("finantialAccountantSign")}
                                >
                                Sign here  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                          <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontSize: 17, fontWeight: 600 }}>Date</Typography>
                              <DateTimeSelector
                                    inputStyle={{ minWidth: '100%'}}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.clDate}
                                    handleChange={newDate => handleChange(newDate, "clDate")}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!urFormState.userFullName){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateRequestSubmit(urFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ActingFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ActingFormModal);
