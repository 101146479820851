import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { useEffect } from 'react';
import { USER_ROLES } from "../../../../../utils/constants";
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../utils';

const MyRequestItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserUid,
    handleClickViewAllDetails,
    handleProjectAttachmentClick
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    useEffect(() => { }, [CurrentUserUid])
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.name)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.department}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.date}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.transaction_type}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.line_manager}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_track ? data.project_track : '')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleClickViewAllDetails(data);
                            }}>{t('ViewCompleteDetails.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    {(role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) ?
                        <Button
                            variant={'outlined'}
                            disabled={false}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleMenuItemClick(data);
                            }}>{t('assignee.label')}</Button>
                        // <OutlinedSelect
                        //     label={`${t('Select Action')}`}
                        //     val={data.is_available ? 'Sent' : ''}
                        //     disabled={false}
                        //     handleChange={evt => {
                        //         handleMenuItemClick(evt.target.value, data);
                        //     }}
                        //     styleOverrides={{ width: '50%', marginRight: 2 }}
                        //     options={data.is_available ? sentMenuItems : (menuItems  ? menuItems : [])}
                        // />
                        :
                        <Button
                            variant={'outlined'}
                            disabled={data.line_manager_uid != CurrentUserUid ? true : false}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleMenuItemClick(data);
                            }}>{t('assignee.label')}</Button>
                        // <OutlinedSelect
                        //     label={`${t('Select Action')}`}
                        //     val={data.is_available ? 'Sent' : ''}
                        //     handleChange={evt => {
                        //         handleMenuItemClick(evt.target.value, data);
                        //     }}
                        //     styleOverrides={{ width: '50%', marginRight: 2 }}
                        //     options={data.is_available ? sentMenuItems : (menuItems  ? menuItems : [])}
                        // />
                    }
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
MyRequestItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(MyRequestItem);