import { gql } from 'apollo-boost';

/*
  Upload file
 */
export const UPLOAD_FILE = gql`
  mutation($file: Upload) {
    uploadDocument(file: $file) {
      url
      filename
    }
  }
`;

export const IMPORT_DATA_TO_DB = gql`
  mutation($data: [String]) {
    importDataToDB(data: $data) {
      data
    }
  }
`;

export const IMPORT_ORG_USER_ROLE_TO_DB = gql`
  mutation($data: [String]) {
    importOrgDataToDB(data: $data) {
      data
    }
  }
`;

export const IMPORT_CHILD_CUSTOMER_TO_DB = gql`
  mutation($data: [String]) {
    importChildCustomerToDB(data: $data) {
      data
    }
  }
`;

export const UPLOAD_BULK_USERS = gql`
  mutation uploadBulkUsers($data: [String]) {
    uploadBulkUsers(data: $data) {
      data
    }
  }
`;

export const ASSIGN_ORG_TO_BULK_USERS = gql`
  mutation assignOrgToBulkUsers($data: [String]) {
    assignOrgToBulkUsers(data: $data) {
      data
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String) {
    sendResetPasswordLink(email: $email) {
      success
      msg
    }
  }
`;

export const SEND_DIRECT_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String) {
    sendDirectResetPasswordLink(email: $email) {
      success
      msg
    }
  }
`;

export const CREATE_USER_GROUP_FROM_CSV = gql`
    mutation CreateCSVUser(
        $users: String
    ) {
        createUserFromCsv(
            users: $users 
        ) {
           id
        }
    }
`;

export const IMPORT_BIRTHDAY_TO_DB = gql`
  mutation($data: [String]) {
    importBirthdayDB(data: $data) {
      data
    }
  }
`;

export const ADD_CYBER_SECURITY = gql`
  mutation CyberSecurityForm(
    $fullName: String
    $address: String
    $jobTitle: String
    $email: String
    $phone: String
    $incidentTime: Date
    $incidentType: String
    $incidentDetected: String
    $notified: String
    $notifiedDesc: String
    $containment: String
    $containmentDesc: String
    $impactedService: String
    $impactedServiceDesc: String
    $attackVendor: String
    $attackVendorDesc: String
    $infoImpact: String
    $infoImpactDesc: String
    $otherImpact: String
    $otherImpactDesc: String
  ){
    addCyberSecurityForm(
      fullName: $fullName
      address: $address
      jobTitle: $jobTitle
      email: $email
      phone: $phone
      incidentTime: $incidentTime
      incidentType: $incidentType
      incidentDetected: $incidentDetected
      notified: $notified
      notifiedDesc: $notifiedDesc
      containment: $containment
      containmentDesc: $containmentDesc
      impactedService: $impactedService
      impactedServiceDesc: $impactedServiceDesc
      attackVendor: $attackVendor
      attackVendorDesc: $attackVendorDesc
      infoImpact: $infoImpact
      infoImpactDesc: $infoImpactDesc
      otherImpact: $otherImpact
      otherImpactDesc: $otherImpactDesc
    ){
      id
    }
  }
`;

export const MERGE_CUSTOMER_TICKETS = gql`
  mutation MergeCustomerTickets ($ticketId : [String]){
    mergeCustomerTickets(ticketId: $ticketId){
      id
    }
  }
`;