import React, { useState } from 'react';
import { Paths, ServiceTicketPaths } from '../../routes/routePaths';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getParam } from '../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_SUBSCRIPTION_LIST } from '../Subscriptions/queries';
import { REQUIRED_ERROR, SUBSCRIPTION_STATUS } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';
import Grid from '@material-ui/core/Grid';
import { GET_TICKET_CATEGORIES } from './queries';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Image from '../../assets/icons/image.png';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import commonStyles from '../../theme/commonStyles';
import OutlinedSelect from '../../components/OutlinedSelect';
import { CREATE_SERVICE_TICKET } from './mutations';
import Message from '../../components/Message';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import { UPLOAD_FILE } from '../commonMutations';
import CircularProgress from '@material-ui/core/CircularProgress';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'serviceTickets.label', link: Paths.ServiceTickets },
  { label: 'newServiceTicket.label', link: ServiceTicketPaths.NewServiceTicket }
];

const useStyles = makeStyles(theme => ({
  reviewItemContainer: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  detailsContainer: {
    border: `1px solid ${theme.palette.grey[200]}`
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  }
}));

function NewServiceTicket({ t }) {
  // const id = getParam();

  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState({});
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [country, setCountry] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileLoading, setUploadedFileLoading] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [selectedSites, setSelectedSites] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const { data: countryList } = useQuery(GET_COUNTRY_LIST);

  const { data: categoryData } = useQuery(GET_TICKET_CATEGORIES);

  const { data: pendingSubscriptions } = useQuery(GET_SUBSCRIPTION_LIST, {
    variables: {
      status: SUBSCRIPTION_STATUS.PENDING
    }
  });

  const { data: activeSubscriptions } = useQuery(GET_SUBSCRIPTION_LIST, {
    variables: {
      status: SUBSCRIPTION_STATUS.ACTIVE
    }
  });

  const [createServiceTicket] = useMutation(CREATE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket created successfully.');
      setUploadedFileLoading(false);
      history.push(Paths.ServiceTickets);
    }
  });

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      return createServiceTicket({
        variables: {
          title: title,
          description: details,
          country: country,
          category: selectedOption.id,
          subscription: selectedService,
          sites: selectedSites,
          attachments: [data.uploadDocument.url]
        }
      });
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const serviceOptions =
    pendingSubscriptions &&
    activeSubscriptions &&
    pendingSubscriptions.getAllSubscriptions &&
    activeSubscriptions.getAllSubscriptions &&
    [
      ...pendingSubscriptions.getAllSubscriptions,
      ...activeSubscriptions.getAllSubscriptions
    ].map((item, index) => {
      return { id: item.id, name: item.name ? item.name : item.service.name };
    });

  const siteOptions =
    selectedService &&
    [
      ...pendingSubscriptions.getAllSubscriptions,
      ...activeSubscriptions.getAllSubscriptions
    ]
      .filter(item => item.id === selectedService)
      .map(item => item.sites)
      .flat(1);

  const classes = useStyles();
  const commonClasses = commonStyles();

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '680px' }}>
      <Header
        title={t('newServiceTicket.label')}
        divider={true}
        subText={`${
          activeSubscriptions && activeSubscriptions.getAllSubscriptions
            ? activeSubscriptions.getAllSubscriptions.length
            : 0
        } ${t('activeSubscriptions.label')} • ${
          pendingSubscriptions && pendingSubscriptions.getAllSubscriptions
            ? pendingSubscriptions.getAllSubscriptions.length
            : 0
        } ${t('pendingActivation.label')}`}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
      />

      <Grid style={{ ...marginGenerator('mt-32') }}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRelevantService.label')} *
        </Typography>
        <OutlinedSelect
          val={selectedService}
          handleChange={evt => {
            setSelectedService(evt.target.value);
            setSelectedSites([]);
          }}
          options={
            serviceOptions && serviceOptions.length ? serviceOptions : []
          }
          styleOverrides={{
            width: '100%'
          }}
          selectStyle={true}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRelevantSites.label')}
        </Typography>
        <OutlinedSelect
          val={selectedSites}
          handleChange={evt => setSelectedSites(evt.target.value)}
          disabled={!Boolean(selectedService)}
          options={siteOptions && siteOptions.length ? siteOptions : []}
          multiple={true}
          styleOverrides={{
            width: '100%'
          }}
          selectStyle={true}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('whatIsItRelatedTo.label')} *
        </Typography>
        {categoryData &&
          categoryData.categories.length &&
          categoryData.categories.map((item, index) => (
            <Radio
              checked={item.id === selectedOption.id}
              key={index}
              classes={{ root: classes.iconButton }}
              style={{
                ...marginGenerator('mr-16'),
                ...(item.id === selectedOption.id
                  ? { backgroundColor: colors.secondary.main }
                  : '')
              }}
              onChange={() => setSelectedOption(item)}
              value={item.id}
              name="type-select"
              checkedIcon={
                <div>
                  <Typography
                    variant={'subtitle2'}
                    style={{ color: colors.common.white }}>
                    {item.name}
                  </Typography>
                </div>
              }
              icon={
                <div>
                  <Typography variant={'subtitle2'}>{item.name}</Typography>
                </div>
              }
            />
          ))}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('enterTitle.label')}
        </Typography>
        <Input
          value={title}
          type={'text'}
          placeholder={`${t('enterTitle.label')} *`}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setTitle(evt.target.value)}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('enterAdditionalDetails.label')}
        </Typography>
        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={details}
              id="details-textarea"
              placeholder={t('enterIssueDetails.label')}
              multiline
              onChange={evt => setDetails(evt.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid container item justify={'flex-end'}>
            {uploadedFile && (
              <Chip
                avatar={<img src={Image} alt={'file'} />}
                label={
                  uploadedFile.name.length > 12
                    ? `${uploadedFile.name.slice(0, 12)}...`
                    : uploadedFile.name
                }
                onDelete={() => {
                  setUploadedFile('');
                }}
                deleteIcon={<Close />}
                variant="outlined"
              />
            )}
            <IconButton
              size={'medium'}
              disableRipple={true}
              disableFocusRipple={true}
              color={'secondary'}
              onClick={handleFileUploadClick}
              style={marginGenerator('ml-16')}>
              <AttachFile className={commonClasses.iconRegular} />
            </IconButton>
            <input
              type="file"
              id="attachment"
              ref={inputFile}
              onChange={event => {
                event.stopPropagation();
                event.preventDefault();
                setUploadedFile(event.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectCountry.label')}*
        </Typography>
        <OutlinedSelect
          val={country}
          handleChange={evt => {
            setCountry(evt.target.value);
          }}
          styleOverrides={{ width: '100%' }}
          options={countryList ? countryList.getCountries : []}
        />
      </Grid>
      <Grid
        container
        style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
        justify={'flex-end'}>
        <Button
          variant={'contained'}
          disabled={uploadedFileLoading}
          color={'primary'}
          onClick={() => {
            setUploadedFileLoading(true);
            if (!title || !selectedOption.id || !selectedService || !country) {
              Message.error(REQUIRED_ERROR);
              return null;
            }

            uploadedFile
              ? prepareUpload()
              : createServiceTicket({
                  variables: {
                    title: title,
                    description: details,
                    country: country,
                    category: selectedOption.id,
                    subscription: selectedService,
                    sites: selectedSites
                  }
                });
          }}>
          {uploadedFileLoading ? <CircularProgress size={14} /> : 'Send'}
        </Button>
      </Grid>
    </Layout>
  );
}

export default withTranslation()(NewServiceTicket);
