import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import { FieldArray, Formik, Field, getIn } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Message from "../../../../components/Message";
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../utils/constants';
import { dateFormatterYYYYMMDD, checkValidEmail } from "../../../../utils/index";

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateProjectInitiateModal({
    t,
    open,
    handleSaveProjectInitiate,
    handleClose,
    data,
    modalState,
    headingName,
    subheading,
}) {
    let configuration;
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    if (data && data.getSubscriptionById.sites && data.getSubscriptionById.sites.length) {
        // configuration = data.getSubscriptionById.sites[0].configuration && JSON.parse(data.getSubscriptionById.sites[0].configuration);
        // console.log("configuration: ", configuration);
        // configuration = {
        //     customer_name: configuration.filter(item => item.key === 'customer_name' || 'customerName')[0].value,
        //     address: configuration.filter(item => item.key === 'address')[0].value,
        //     product_code: configuration.filter(item => item.key === 'product_code' || 'CipPc')[0].value,
        //     phone: configuration.filter(item => item.key === 'phone')[0].value,
        //     email: configuration.filter(item => item.key === 'email')[0].value,/*
        //     latitude: configuration.filter(item => item.key === 'latitude')[0].value,
        //     longitude: configuration.filter(item => item.key === 'longitude')[0].value,
        //     altitude: configuration.filter(item => item.key === 'altitude')[0].value,*/
        // }
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} {headingName}
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>{subheading ? 'This form will project initiate information it to Nigeria.' : ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        category: "",
                        customer: configuration && configuration.customer_name,
                        project_manager: "",
                        project_sponsor: "",
                        project_code: configuration && configuration.product_code,
                        contract_date: "",
                        target_completion: "",
                        site_name: "",
                        site_address: configuration && configuration.address,
                        customer_contact: configuration && configuration.phone,
                        contact_tel: "",
                        contact_email: configuration && configuration.email,
                        circuit_number: "",
                        change_reason: "",
                        change_impact: "",
                        change_duration: "",
                        fall_back_plan: "",
                        idirect_modem_sno: "",
                        buc_model: "",
                        lnb_model: "",
                        antenna_size: "",
                        idirect_cir_up: "",
                        idirect_cir_down: "",
                        idirect_type: "",
                        corporate_internet_cir_up: "",
                        corporate_internet_cir_down: "",
                        existing_layer_3_device: "",
                        corporate_internet_specify: "",
                        corporate_internet_termination_device: "",
                        corporate_internet_number_of_public_ip: "",
                        network_ip: "",
                        default_gw: "",
                        subnet_mask: "",
                        bwa_service: "",
                        bwa_cir_up: "",
                        bwa_termination_device: "",
                        bwa_number_of_public_ips: "",
                        bwa_service_level: "",
                        project_name: ""
                    }}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        // if (!values.project_name || !values.category || !values.customer || !values.project_manager ||
                        //     !values.project_sponsor || !values.project_code || !values.site_address || !values.contract_date ||
                        //     !values.target_completion || !values.customer_contact || !values.site_name || !values.contact_email ||
                        //     !values.circuit_number || !values.change_reason || !values.change_impact || !values.corporate_internet_cir_down ||
                        //     !values.change_duration || !values.fall_back_plan || !values.idirect_modem_sno || !values.corporate_internet_cir_up ||
                        //     !values.idirect_type || !values.corporate_internet_termination_device || !values.network_ip ||
                        //     !values.bwa_service || !values.default_gw || !values.bwa_termination_device || !values.bwa_number_of_public_ips ||
                        //     !values.bwa_service_level) {
                        //     Message.error(REQUIRED_ERROR);
                        // } else if (values.contact_email != '' && !checkValidEmail(values.contact_email)) {
                        //     Message.error(INVALID_EMAIL);
                        //     return null;
                        // } else {
                        // if (values.category === "") {
                        //     values.category = null
                        // }
                        // if (values.bwa_number_of_public_ips === "") {
                        //     values.bwa_number_of_public_ips = null
                        // }
                        // if (values.bwa_service_level === "") {
                        //     values.bwa_service_level = null
                        // }
                        if (values.contract_date != "") {
                            let convertContactDate = dateFormatterYYYYMMDD(values.contract_date);
                            values.contract_date = convertContactDate;
                        } else {
                            values.contract_date = null;
                        }
                        if (values.target_completion != "") {
                            let convertTargetCompletion = dateFormatterYYYYMMDD(values.target_completion);
                            values.target_completion = convertTargetCompletion;
                        } else {
                            values.target_completion = null;
                        }
                        setSubmitting(true);
                        handleSaveProjectInitiate(values)
                        // }
                        // if (modalState.clearData) {
                        //     resetForm()
                        // }
                    }}
                    validationSchema={Yup.object().shape({
                        // category: Yup.string()
                        //     .required('Please select category'),
                        contact_email: Yup.string()
                            .email('Please enter valid contact email'),
                        network_ip: Yup.string()
                            // .required('Please enter network IP')
                            .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter valid network ip'),
                        default_gw: Yup.string()
                            // .required('Please enter default GW IP')
                            .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter valid default GW'),
                        subnet_mask: Yup.string()
                            .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter valid subnet mask')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{ ...paddingGenerator('pb-30') }}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.project_name}
                                                    name={'project_name'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.project_name && touched.project_name) && errors.project_name}
                                                    placeholder={`${t('project_name.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <OutlinedSelect
                                                    val={values.category}
                                                    label={`${t('category.label')} *`}
                                                    name={'category'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={[
                                                        { id: 'New Installation', name: 'New Installation' },
                                                        { id: 'Upgrade', name: 'Upgrade' },
                                                        { id: 'Downgrade', name: 'Downgrade' },
                                                        { id: 'Decommisioning', name: 'Decommisioning' }
                                                    ]}
                                                />
                                                {(errors.category && touched.category) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.category}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.customer}
                                                    name={'customer'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.customer && touched.customer) && errors.customer}
                                                    placeholder={`${t('customer.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.project_manager}
                                                    name={'project_manager'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.project_manager && touched.project_manager) && errors.project_manager}
                                                    placeholder={`${t('project_manager.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.project_sponsor}
                                                    name={'project_sponsor'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.project_sponsor && touched.project_sponsor) && errors.project_sponsor}
                                                    placeholder={`${t('project_sponsor.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.project_code}
                                                    name={'project_code'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.project_code && touched.project_code) && errors.project_code}
                                                    placeholder={`${t('project_code.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.site_address}
                                                    name={'site_address'}
                                                    onChange={handleChange}
                                                    multiline
                                                    rows={4}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.site_address && touched.site_address) && errors.site_address}
                                                    placeholder={`${t('site_address.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant={'subtitle2'}>{t('contract_date.label')} *</Typography>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id={`${t('contract_date.label')} *`}
                                                        // label={`${t('contract_date.label')} *`}
                                                        format="MM/dd/yyyy"
                                                        value={props.values.contract_date}
                                                        onChange={value => props.setFieldValue("contract_date", value)}
                                                        style={{ width: '100%', marginRight: 0 }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant={'subtitle2'}>{t('target_completion.label')} *</Typography>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id={`${t('target_completion.label')} *`}
                                                        // label={`${t('target_completion.label')} *`}
                                                        format="MM/dd/yyyy"
                                                        value={props.values.target_completion}
                                                        onChange={value => props.setFieldValue("target_completion", value)}
                                                        style={{ width: '100%', marginRight: 0 }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.customer_contact}
                                                    name={'customer_contact'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.customer_contact && touched.customer_contact) && errors.customer_contact}
                                                    placeholder={`${t('customer_contact.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.site_name}
                                                    name={'site_name'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.site_name && touched.site_name) && errors.site_name}
                                                    placeholder={`${t('site_name.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.contact_tel}
                                                    name={'contact_tel'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.contact_tel && touched.contact_tel) && errors.contact_tel}
                                                    placeholder={`${t('contact_tel.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.contact_email}
                                                    name={'contact_email'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.contact_email && touched.contact_email) && errors.contact_email}
                                                    placeholder={`${t('contact_email.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.circuit_number}
                                                    name={'circuit_number'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.circuit_number && touched.circuit_number) && errors.circuit_number}
                                                    placeholder={`${t('circuit_number.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.change_reason}
                                                    name={'change_reason'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    multiline
                                                    rows={4}
                                                    helperText={(errors.change_reason && touched.change_reason) && errors.change_reason}
                                                    placeholder={`${t('change_reason.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.change_impact}
                                                    name={'change_impact'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.change_impact && touched.change_impact) && errors.change_impact}
                                                    placeholder={`${t('change_impact.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.change_duration}
                                                    name={'change_duration'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.change_duration && touched.change_duration) && errors.change_duration}
                                                    placeholder={`${t('change_duration.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.fall_back_plan}
                                                    name={'fall_back_plan'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.fall_back_plan && touched.fall_back_plan) && errors.fall_back_plan}
                                                    placeholder={`${t('fall_back_plan.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    iDirect
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Corporate Internet
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.idirect_modem_sno}
                                                    name={'idirect_modem_sno'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.idirect_modem_sno && touched.idirect_modem_sno) && errors.idirect_modem_sno}
                                                    placeholder={`${t('modem_sno.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.corporate_internet_cir_up}
                                                    name={'corporate_internet_cir_up'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.corporate_internet_cir_up && touched.corporate_internet_cir_up) && errors.corporate_internet_cir_up}
                                                    placeholder={`${t('cir_up.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.corporate_internet_cir_down}
                                                    name={'corporate_internet_cir_down'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.corporate_internet_cir_down && touched.corporate_internet_cir_down) && errors.corporate_internet_cir_down}
                                                    placeholder={`${t('cir_down.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <OutlinedSelect
                                                    val={values.idirect_type}
                                                    label={`${t('type.label')} *`}
                                                    name={'idirect_type'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={[{ id: 'DVB-S2', name: 'DVB-S2' }, { id: 'non-DVB', name: 'non-DVB' }]}
                                                />
                                                {(errors.idirect_type && touched.idirect_type) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.idirect_type}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.existing_layer_3_device}
                                                    name={'existing_layer_3_device'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.existing_layer_3_device && touched.existing_layer_3_device) && errors.existing_layer_3_device}
                                                    placeholder={`${t('existing_layer_3_device.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.buc_model}
                                                    name={'buc_model'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.buc_model && touched.buc_model) && errors.buc_model}
                                                    placeholder={`${t('buc_model.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.corporate_internet_specify}
                                                    name={'corporate_internet_specify'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.corporate_internet_specify && touched.corporate_internet_specify) && errors.corporate_internet_specify}
                                                    placeholder={`${t('corporate_internet_specify.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.lnb_model}
                                                    name={'lnb_model'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.lnb_model && touched.lnb_model) && errors.lnb_model}
                                                    placeholder={`${t('lnb_model.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <OutlinedSelect
                                                    val={values.corporate_internet_termination_device}
                                                    label={`${t('termination_device.label')} *`}
                                                    name={'corporate_internet_termination_device'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={['Server', 'Router', 'Other'].map(item => {
                                                        return { id: item, name: item }
                                                    })}
                                                />
                                                {(errors.corporate_internet_termination_device && touched.corporate_internet_termination_device) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.corporate_internet_termination_device}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.antenna_size}
                                                    name={'antenna_size'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.antenna_size && touched.antenna_size) && errors.antenna_size}
                                                    placeholder={`${t('antenna_size.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.corporate_internet_number_of_public_ip}
                                                    name={'corporate_internet_number_of_public_ip'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.corporate_internet_number_of_public_ip && touched.corporate_internet_number_of_public_ip) && errors.corporate_internet_number_of_public_ip}
                                                    placeholder={`${t('corporate_internet_number_of_public_ip.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.idirect_cir_up}
                                                    name={'idirect_cir_up'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.idirect_cir_up && touched.idirect_cir_up) && errors.idirect_cir_up}
                                                    placeholder={`${t('cir_up.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.idirect_cir_down}
                                                    name={'idirect_cir_down'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.idirect_cir_down && touched.idirect_cir_down) && errors.idirect_cir_down}
                                                    placeholder={`${t('cir_down.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Customer LAN Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    BWA
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.network_ip}
                                                    name={'network_ip'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.network_ip && touched.network_ip) && errors.network_ip}
                                                    placeholder={`${t('customer_lan_info_network_ip.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <OutlinedSelect
                                                    val={values.bwa_service}
                                                    label={`${t('service.label')} *`}
                                                    name={'bwa_service'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={['Airlink', 'Metrolink'].map(item => {
                                                        return { id: item, name: item }
                                                    })}
                                                />
                                                {(errors.bwa_service && touched.bwa_service) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.bwa_service}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.default_gw}
                                                    name={'default_gw'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.default_gw && touched.default_gw) && errors.default_gw}
                                                    placeholder={`${t('default_gw.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.bwa_cir_up}
                                                    name={'bwa_cir_up'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.bwa_cir_up && touched.bwa_cir_up) && errors.bwa_cir_up}
                                                    placeholder={`${t('cir_up.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.bwa_cir_down}
                                                    name={'bwa_cir_down'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.bwa_cir_down && touched.bwa_cir_down) && errors.bwa_cir_down}
                                                    placeholder={`${t('cir_down.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.subnet_mask}
                                                    name={'subnet_mask'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.subnet_mask && touched.subnet_mask) && errors.subnet_mask}
                                                    placeholder={`${t('subnet_mask.label')} `}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <OutlinedSelect
                                                    val={values.bwa_termination_device}
                                                    label={`${t('termination_device.label')} *`}
                                                    name={'bwa_termination_device'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={['Yes', 'No'].map(item => {
                                                        return { id: item, name: item }
                                                    })}
                                                />
                                                {(errors.bwa_termination_device && touched.bwa_termination_device) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.bwa_termination_device}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={values.bwa_number_of_public_ips}
                                                    name={'bwa_number_of_public_ips'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.bwa_number_of_public_ips && touched.bwa_number_of_public_ips) && errors.bwa_number_of_public_ips}
                                                    placeholder={`${t('corporate_internet_number_of_public_ip.label')} *`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <OutlinedSelect
                                                    val={values.bwa_service_level}
                                                    label={`${t('bwa_service_level.label')} *`}
                                                    name={'bwa_service_level'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                                    options={[1, 2, 3, 4, 5].map(item => {
                                                        return { id: item, name: item }
                                                    })}
                                                />
                                                {(errors.bwa_service_level && touched.bwa_service_level) &&
                                                    <FormHelperText
                                                        style={{ marginTop: '20px' }}>{errors.bwa_service_level}</FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{ marginRight: '5px' }}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading} />
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
CreateProjectInitiateModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSaveProjectInitiate: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateProjectInitiateModal);
