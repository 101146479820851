import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    // blackBorderTitle:{
    //     margin: '8px 0px',
    // },
    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    licenceNumber: "",
    placeToVisit: "",
    username: "",
    userDepartment: "",
    userSection: "",
    dateReq: null,
    dateRequired: null,
    timeRequired: null,
    dateSupAuth: null,
    dateHODAppr: null,
    returnDate: null,
    purpose:'',
    immediateSupervisor:'',
    dateSupAuthSupervisor: null,
    authorisedByApprovedByHod:'',
    dateHodAppr: null,
    vechileAvaility:'',
    dateReqRecived:null,
    registerNumberDate:null,
    transportDate:null,
    approvedByEngineerManger:'',
    approvedByEngineerMangerDate:null,
    approvelOfVechile: '',
    approvedByHod: '',
    approvedByHodDate: null,
    approvedByGm: '',
    approvedByGmDate: null,
    insertType : [],
    sign: "",
    HOD: "",
    complaint: "",
    positionExist : "",
    strength : "",
    variance :"",
    approverComment: "",
    approverSign: "",
    validation: "",
    validationSign: "",
    learnDevelopmentComment: "",
    HRManagerCasual: "",
    HRManagerCasualSign: "",
    HRManagerCasualComment: "",
    HRManager: "",
    HRManagerSign: "",
    HRManagerComment: "",
    GeneralManager: "",
    GeneralManagerSign: "",
};


const tableItems = [
    {
        key: 'long_term',
        value: 'Long Term',
        label: "Permanent / Fixed Term (long term)",
        description: "Permanent & FTC 12 months and above"
    },
    {
        key: 'short_term',
        value: 'Short term',
        label: "Fixed Term (short term)",
        description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
    },
    {
        key: 'casual',
        label: "Casual",
        value: "Casual",
        description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
    }
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function TransportFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [isFormElementShown, setIsFormElementShown] = useState(true);
    const [userDeclaration, setUserDeclaration] = useState(true);
    const [departmentSections, setDepartmentSections] = useState([]);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [showHRManagerCasual, setShowHRManagerCasual] = useState(false);
    const [showHRManager, setShowHRManager] = useState(false);
    const [showGeneralManager, setShowGeneralManager] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const userDepartment = data.userDepartment && data.userDepartment._id
            const userSection = data.userSection && data.userSection
            const id = data._id;
            setShowUserSelect((data.createdById === userdata.id) ? true : false);
            setShowValidation((data.createdById === userdata.id) ? true : false);
            setShowHRManagerCasual((data.createdById === userdata.id) ? true : false);
            setShowHRManager((data.createdById === userdata.id) ? true : false);
            setShowGeneralManager((data.createdById === userdata.id) ? true : false);
            setURFormState({...data, userDepartment, userSection, id });
            if(data.userSection){
                setDepartmentSections([data.userSection]);
            }
        }else{
            setShowUserSelect(true);
            setShowValidation(true);
            setShowHRManagerCasual(true);
            setShowHRManager(true);
            setShowGeneralManager(true);
        }
    }, [data]);
    
    const handleChange = (value, name) => {
        if (name === "insertType") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.insertType.push(value.target.value);
                } else {
                    let index = newState.insertType.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.insertType.splice(index, 1);
                    }
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                    <Grid item xs={12} className={classes.blackBorder}>
                        <Typography variant="h4" style={{ textAlign: "center"}} >Transport Request</Typography>
                    </Grid>

                    <Grid container style={{ marginBottom: 20 }} xs={12}>
                        <Grid item xs={3} className={classes.blackBorder} >
                            <Typography variant="h4">Requisition by User</Typography>
                            <h4>
                                (vehicle requests should be done 3 days in advance)
                            </h4>
                        </Grid>
                        <Grid container xs={9}>
                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Department</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <OutlinedSelect
                                        options={(departments && departments.length) ? departments : []}
                                        // label="Department"
                                        selectStyle={{ ...paddingGenerator('p-4') }}
                                        val={urFormState.userDepartment}
                                        handleChange={(evt) => {
                                            const item = departments.find(itm => evt.target.value === itm.id);
                                            setDepartmentSections(item ? item.sections : []);
                                            handleChange(evt.target.value, "userDepartment");
                                        }}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <h5>Section</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <OutlinedSelect
                                        options={(departmentSections && departmentSections.length) ? departmentSections : []}
                                        // label="Section"
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                        val={urFormState.userSection}
                                        selectStyle={{ ...paddingGenerator('p-4') }}
                                        handleChange={(evt) => {
                                            evt.target.value && evt.target.value._id &&
                                            handleChange(evt.target.value, "userSection");
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Date Requested</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: '100%' }}
                                        // label={'Date Requested'}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.dateReq}
                                        handleChange={newDate => handleChange(newDate, "dateReq")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>User's Name</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <Input
                                        type={'text'}
                                        // placeholder={"Username"}
                                        style={{ width: '100%' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={urFormState.username}
                                        onChange={(evt) => handleChange(evt.target.value, "username")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Date Required</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: '100%' }}
                                        // label={'Date Required'}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.dateRequired}
                                        handleChange={newDate => handleChange(newDate, "dateRequired")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Company License Number</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <Input
                                        type={'text'}
                                        placeholder={"Company License Number"}
                                        style={{ width: '100%' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={urFormState.licenceNumber}
                                        onChange={(evt) => handleChange(evt.target.value, "licenceNumber")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Time Required</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <Input
                                        type={'text'}
                                        style={{ minWidth: '100%' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={urFormState.timeRequired}
                                        onChange={(evt) => handleChange(evt.target.value, "timeRequired")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Place to visit</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <Input
                                        type={'text'}
                                        // placeholder={"Place to visit"}
                                        style={{ width: '100%' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={urFormState.placeToVisit}
                                        onChange={(evt) => handleChange(evt.target.value, "placeToVisit")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Return Date</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: '100%' }}
                                        // label={'Return Date'}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.returnDate}
                                        handleChange={newDate => handleChange(newDate, "returnDate")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Purpose</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        type="textarea"
                                        // placeholder="Purpose"
                                        variant="filled"
                                        size="small"
                                        multiline
                                        rows={5}
                                        onChange={(evt) => handleChange(evt.target.value, "purpose")}
                                        value={urFormState.purpose}
                                        style={{ minWidth: "100%", width: "100%" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5} />
                                <Grid item xs={7}>
                                    <IconButton 
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("userSign")}
                                        style={{margin: '10px'}}
                                        >
                                        User Signature <img src={SignIcon} width="26" height={26} alt=""/>
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Authorised By: immediate supervisor</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        // label="Authorised By: immediate supervisor"
                                        disabled={(data && data.HRManagerSign) ? true : false}
                                        val={urFormState.immediateSupervisor}
                                        handleChange={(evt) => handleChange(evt.target.value, "immediateSupervisor")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                        selectStyle={{ ...paddingGenerator('p-4') }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Date Sup Auth</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: '100%' }}
                                        // label={'Date Sup Auth'}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.dateSupAuthSupervisor}
                                        handleChange={newDate => handleChange(newDate, "dateSupAuthSupervisor")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={7} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <h5>Authorised By: Approved by Hod</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        // label="Authorised By: Approved by Hod"
                                        val={urFormState.authorisedByApprovedByHod}
                                        handleChange={(evt) => handleChange(evt.target.value, "authorisedByApprovedByHod")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                        selectStyle={{ ...paddingGenerator('p-4') }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={5} className={classes.blackBorder}>
                                <Grid item xs={5}>
                                    <h5>Date Hod appr</h5>
                                </Grid>
                                <Grid item xs={7}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: '100%' }}
                                        // label={'Date Hod appr'}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.dateHodAppr}
                                        handleChange={newDate => handleChange(newDate, "dateHodAppr")}
                                    />
                                </Grid>
                            </Grid>
                        
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid container xs={12}>
                    <Grid item xs={3} className={classes.blackBorder} >
                        <Typography variant="h4">Aprover Of Vechile Allocation</Typography>
                    </Grid>
                    <Grid container xs={9}>
                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4}>
                                <h5>Vechile availble</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <RadioGroup
                                    row
                                    aria-label="vechileAvaility"
                                    value={urFormState.vechileAvaility}
                                    name="vechileAvaility"
                                    onChange={(evt) => {
                                        handleChange(evt.target.value, "vechileAvaility");
                                    }}
                                >
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="Yes"
                                        labelPlacement="YES"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="No"
                                        labelPlacement="NO"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="No"
                                    />
                                </RadioGroup>   
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={5}>
                                <h5>Date Request Recived</h5>
                            </Grid>
                            <Grid item xs={7}>
                                <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date Request Recived'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.dateReqRecived}
                                    handleChange={newDate => handleChange(newDate, "dateReqRecived")}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4} >
                                <h5>Registration Number of Vehicle Allocated</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <Input
                                    type={'text'}
                                    style={{ width: '100%' }}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={urFormState.RegNo}
                                    onChange={(evt) => handleChange(evt.target.value, "RegNo")}
                                />
                                
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={3}>
                                <h5>Date</h5>
                            </Grid>
                            <Grid item xs={9}>
                                <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.transportDate}
                                    handleChange={newDate => handleChange(newDate, "transportDate")}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4} >
                                <h5>Transport Supervisor Signature</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <IconButton 
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("transportSupervisorSign")}
                                    style={{ display: 'flex', justifyContent: 'left', fontSize: '16px', marginTop: '25px'}}
                                    >
                                    Transport Supervisor Signature <img src={SignIcon} width="26" height={26} alt=""/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={3}>
                                <h5>Date</h5>
                            </Grid>
                            <Grid item xs={9}>
                            <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.transportSupervisorDate}
                                    handleChange={newDate => handleChange(newDate, "transportSupervisorDate")}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4}>
                                <h5>Approved by Engineering Manger</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    // label="Approved by Engineering Manger"
                                    disabled={(data && data.HRManagerSign) ? true : false}
                                    val={urFormState.approvedByEngineerManger}
                                    handleChange={(evt) => handleChange(evt.target.value, "approvedByEngineerManger")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={3}>
                                <h5>Date</h5>
                            </Grid>
                            <Grid item xs={9}>
                                <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.approvedByEngineerMangerDate}
                                    handleChange={newDate => handleChange(newDate, "approvedByEngineerMangerDate")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container xs={12}>
                    <Grid item xs={3} className={classes.blackBorder} >
                        <Typography variant="h4">HOD APPROVAL</Typography>
                        <h5>(use of own behicle allowed only if a Mine vehicle is not available and evidanced by sign off by Transport Supervisor above)</h5>
                    </Grid>
                    <Grid container xs={9}>
                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4}>
                                <h5>Appoval to use own vechile</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <RadioGroup
                                    row
                                    aria-label="approvelOfVechile"
                                    value={urFormState.approvelOfVechile}
                                    name="approvelOfVechile"
                                    onChange={(evt) => {
                                        handleChange(evt.target.value, "approvelOfVechile");
                                    }}
                                >
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="Yes"
                                        labelPlacement="YES"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="No"
                                        labelPlacement="NO"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="N/A"
                                        labelPlacement="N/A"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="N/A"
                                    />
                                </RadioGroup> 
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder} />

                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4} >
                                <h5>Approved by HOD</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    // label="Approved by HOD"
                                    //disabled={(data && data.HRManagerSign) ? true : false}
                                    val={urFormState.approvedByHod}
                                    handleChange={(evt) => handleChange(evt.target.value, "approvedByHod")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={3}>
                                <h5>Date</h5>
                            </Grid>
                            <Grid item xs={9}>
                                <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.approvedByHodDate}
                                    handleChange={newDate => handleChange(newDate, "approvedByHodDate")}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={7} className={classes.blackBorder}>
                            <Grid item xs={4} >
                                <h5>Approved by GM</h5>
                            </Grid>
                            <Grid item xs={8}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    // label="Approved by GM"
                                    val={urFormState.approvedByGm}
                                    handleChange={(evt) => handleChange(evt.target.value, "approvedByGm")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={5} className={classes.blackBorder}>
                            <Grid item xs={3}>
                                <h5>Date</h5>
                            </Grid>
                            <Grid item xs={9}>
                                <DateTimeSelector
                                    inputStyle={{ minWidth: '100%' }}
                                    // label={'Date'}
                                    dateFormat={"yyyy-MM-dd"}
                                    value={urFormState.approvedByGmDate}
                                    handleChange={newDate => handleChange(newDate, "approvedByGmDate")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!urFormState.userDepartment){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateRequestSubmit(urFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

TransportFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(TransportFormModal);