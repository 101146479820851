// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_INSTALL_LIST, GET_ACTIVATION_TEAM_BOTSWANA_LIST, GET_SERVICE_DELIVERY_TEAM_LIST, GET_SALES_TEAM_IVORY_COAST_LIST, GET_PROJECT_ZAMBIA_TEAM_LIST, GET_SERVICE_DELIVERY_TEAM_CAMEROON_LIST } from "../../queries";
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EquipmentsItem from "../EquipmentsItem";
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Message from "../../../../../components/Message";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const Equipments = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const countryName = localStorage.getItem('country');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [CountryFormData, setCountryFormData] = useState({ mutationName: null, apiName: null });

    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        if (COUNTRY_LIST.NIGERIA === countryName) {
            setCountryFormData({ mutationName: GET_INSTALL_LIST, apiName: 'getInstallTeamList' })
        } else if (COUNTRY_LIST.BOTSWANA === countryName) {
            setCountryFormData({ mutationName: GET_ACTIVATION_TEAM_BOTSWANA_LIST, apiName: 'getActivationTeamBotswanaList' })
        } else if (COUNTRY_LIST.MALAWI === countryName) {
            setCountryFormData({ mutationName: GET_SERVICE_DELIVERY_TEAM_LIST, apiName: 'getServiceDeliveryTeamList' })
        } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
            setCountryFormData({ mutationName: GET_SALES_TEAM_IVORY_COAST_LIST, apiName: 'getSalesTeamIvoryCoastList' })
        } else if (COUNTRY_LIST.ZAMBIA === countryName) {
            setCountryFormData({ mutationName: GET_PROJECT_ZAMBIA_TEAM_LIST, apiName: 'getProjectZambiaTeamList' })
        } else if (COUNTRY_LIST.CAMEROON === countryName) {
            setCountryFormData({ mutationName: GET_SERVICE_DELIVERY_TEAM_CAMEROON_LIST, apiName: 'getServicedeliveryTeamCameroonList' })
        }
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            getAllList({
                variables: {
                    pageNumberOrUid: "project_owner=is_admin&page=1"
                }
            })
        }
    }, [refreshTab]);
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        getAllList({
            variables: {
                pageNumberOrUid: "project_owner=is_admin&page=" + value + checkSearchFilterData
            }
        })
    };
    const [getAllList] = useLazyQuery(CountryFormData.mutationName, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("install data Equipment: ", data);
            if (COUNTRY_LIST.NIGERIA === countryName) {
                if (data && data.getInstallTeamList) {
                    let parseResult = JSON.parse(data.getInstallTeamList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            } else if (COUNTRY_LIST.BOTSWANA === countryName) {
                if (data && data.getActivationTeamBotswanaList) {
                    let parseResult = JSON.parse(data.getActivationTeamBotswanaList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            } else if (COUNTRY_LIST.MALAWI === countryName) {
                if (data && data.getServiceDeliveryTeamList) {
                    let parseResult = JSON.parse(data.getServiceDeliveryTeamList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
                if (data && data.getSalesTeamIvoryCoastList) {
                    let parseResult = JSON.parse(data.getSalesTeamIvoryCoastList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            } else if (COUNTRY_LIST.ZAMBIA === countryName) {
                if (data && data.getProjectZambiaTeamList) {
                    let parseResult = JSON.parse(data.getProjectZambiaTeamList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            } else if (COUNTRY_LIST.CAMEROON === countryName) {
                if (data && data.getServicedeliveryTeamCameroonList) {
                    let parseResult = JSON.parse(data.getServicedeliveryTeamCameroonList.teamListData);
                    setList(parseResult);
                } else {
                    setList([])
                }
            }
        },
        onError: error => {
            console.log("error get install list in equipments:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('equipments.label') + ' ' + t('details.label'),
            key: "equipments"
        },
        {
            label: t('createdDate.label'),
            key: "created_at"
        },
    ];
    const handleMenuItemClick = (elem, data) => { }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
    };
    return (
        <>
            <Header title={t('equipments.label')} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <EquipmentsItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(Equipments);
