import React, { useEffect, useState } from 'react';
import { FaBars, FaAngleDown } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Container, Tooltip, Typography, makeStyles } from '@material-ui/core';
import ExpandedSideNavComponents from './components/ExpandedSideNavComponents';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    padding: '0.5rem 1rem'
  },
  top_section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "14px",
    padding: "12px",
    minHeight: "2.5rem"
  },
  expandedMenu: {
    cursor: "pointer",
    border: '1px solid white',
    borderRadius: '0.5rem',
    padding: '0.4rem'
  },
  collapsedMenu: {
    cursor: "pointer"
  },
  title: {
    lineHeight: 0,
    color: "#FFFFFF",
    fontSize: "1.1rem"
  },
  sidebar: {
    background: "#5a1f2a",
    color: "#ffffff",
    height: "100%",
    overflow: "auto"
  }
}));

const SideNavMenu = ({ title, navs, handleChangeActiveNotiTab, setActiveIndex, setSubActiveIndex, selectedPath, setSelectedPath, activeSubpath, setActiveSubpath, children }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDiv, setActiveDiv] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2
      }
    },
    show: {
      width: '140px',
      padding: '5px 15px',
      transition: {
        duration: 0.2
      }
    }
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5
      }
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5
      }
    }
  };

  const handleNavChange = (event, newValue) => {
    if (typeof handleChangeActiveNotiTab !== "undefined") {
      handleChangeActiveNotiTab(false, newValue);
      setActiveIndex(newValue);
    }
  };

  const handleSelect = (route) => {
    setActiveDiv(route?.label);
    setSelectedPath(null);
    setActiveSubpath(null);
    localStorage.setItem('activePath', route.label);
  };

  useEffect(() => {
    const activePath = localStorage.getItem('activePath') ? localStorage.getItem('activePath') : null;
    setActiveDiv(activePath);
  }, []);


  return (
    <>
      <div className={classes.root}>
        <div>
          <motion.div
            animate={{
              minWidth: isOpen ? '170px' : '45px',
              transition: {
                duration: 0.8,
                type: 'spring',
                damping: 10
              }
            }}
            className={classes.sidebar}
          >
            <Box
              className={classes.top_section}
            >
              <AnimatePresence>
                {
                  isOpen && (
                    <motion.h1
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      <Typography variant='h6' className={classes.title}>
                        {/* {showTitle && title} */}
                      </Typography>
                    </motion.h1>
                  )
                }
              </AnimatePresence>

              <Box display="flex" className={!isOpen ? classes.collapsedMenu : classes.expandedMenu}>
                {isOpen ? <FaAngleDown style={{ transform: 'rotate(90deg)' }} onClick={toggle} /> : <FaBars onClick={toggle} />}
              </Box>
            </Box>
            <section className="routes">
              {navs?.map((route, index) => {
                if (route.subPaths) {
                  return (
                    <ExpandedSideNavComponents
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      setSubActiveIndex={setSubActiveIndex}
                      selectedPath={selectedPath}
                      setSelectedPath={setSelectedPath}
                      setActiveIndex={setActiveIndex}
                      activeSubpath={activeSubpath}
                      setActiveSubpath={setActiveSubpath}
                      setActiveDiv={setActiveDiv}
                    />
                  );
                }
                return (
                  <Tooltip title={route.label} placement="right-start">
                    <div
                      onClick={(e) => {
                        handleNavChange(e, route.path);
                        handleSelect(route);
                      }}
                      key={index}
                      className={`link ${activeDiv === route.label ? 'active' : ''}`}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="icon">{route?.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text">
                            {route.label}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </Tooltip>
                );
              })}
            </section>
          </motion.div>
        </div >
        <Container
          className={classes.content}
          disableGutters={window.innerWidth > 1024}>
          {children}
        </Container>
      </div >
    </>
  );
};

export default SideNavMenu;
