import React from 'react';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@material-ui/core';

const EditPortPopup = ({
  openEditPortPopup,
  setOpenEditPortPopup,
  portName,
  portChange,
  portSpeed,
  setPortSpeed,
  minTerm,
  setMinTerm,
  handleSubmit,
  selectedPortData,
  classes
}) => {
  return (
    <>
      <Popup
        title="Edit Configured Services"
        openPopup={openEditPortPopup}
        setOpenPopup={setOpenEditPortPopup}>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={5}>
                  Port Name
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={portName}
                    onChange={portChange}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={5}>
                  Port Speed
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={portSpeed}
                    disabled
                    onChange={e => setPortSpeed(e.target.value)}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={5}>
                  Minimum Term
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item container sm={6} alignItems="center">
                  <Grid item sm={12}>
                    <TextField
                      select
                      value={minTerm}
                      fullWidth
                      onChange={e => setMinTerm(e.target.value)}
                      SelectProps={{
                        native: true
                      }}
                      variant="outlined"
                      size="small">
                      <option value="">Select</option>
                      {[
                        { key: '1 month', value: '1' },
                        { key: '12 months', value: '12' },
                        { key: '24 months', value: '24' },
                        { key: '36 months', value: '36' }
                      ]?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={2} style={{ marginLeft: '5px' }}>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={5}>
                  Market Place
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={'No'}
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>{' '}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => setOpenEditPortPopup(false)}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={e => handleSubmit(selectedPortData._id, e)}>
            Save Changes
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default EditPortPopup;
