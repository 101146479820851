import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@material-ui/core';
import Popup from '../../Popup';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import { Tooltip as ReChartsTooltip } from 'recharts';
import moment from 'moment';

const IpsecChartPopup = ({
  chartData,
  openChartsPopup,
  setOpenChartsPopup,
  loading,
  dateFilter,
  matricType,
  setDateFilter,
  setMatricType,
  classes
}) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload?.length) {
      return (
        <div
          style={{
            border: '1px solid red',
            borderRadius: '8%',
            backgroundColor: '#661723',
            color: '#fff',
            fontSize: '13px',
            padding: '3px'
          }}>
          {payload &&
            payload.map((item, i) => (
              <p className="label" key={i}>{`${item.name} : ${item.value}`}</p>
            ))}
        </div>
      );
    }

    return null;
  };
  return (
    <Popup
      openPopup={openChartsPopup}
      setOpenPopup={setOpenChartsPopup}
      title="Service Graph-Traffic"
      width="md">
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item container>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '20px'
              }}>
              <span style={{ fontSize: 'small' }}>Metric Type</span>
              <div>
                <TextField
                  select
                  value={matricType}
                  onChange={e => setMatricType(e.target.value)}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined"
                  size="small">
                  <option value="">Select</option>
                  {[
                    { key: 'Mbps', value: 'Mbps' },
                    { key: 'Packets', value: 'packets' },
                    { key: 'Errors', value: 'errors' }
                  ].map(item => (
                    <option key={item.key} value={item.value}>
                      {item.key}
                    </option>
                  ))}
                </TextField>
              </div>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px'
              }}>
              <span style={{ fontSize: 'small' }}>Date Range</span>
              <div>
                <TextField
                  select
                  value={dateFilter}
                  onChange={e => setDateFilter(parseInt(e.target.value))}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined"
                  size="small">
                  <option value="">Select</option>
                  {[
                    { key: 'Last 15 Minutes', value: 15 },
                    { key: 'Last 1 Hour', value: 60 }
                  ].map(item => (
                    <option key={item.key} value={item.value}>
                      {item.key}
                    </option>
                  ))}
                </TextField>
              </div>
            </Box>
          </Grid>

          {loading && (
            <Grid item container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
          {!loading && chartData?.length !== 0 && (
            <Grid item>
              <LineChart
                width={800}
                height={350}
                data={chartData}
                margin={{
                  top: 15,
                  right: 20,
                  left: 10,
                  bottom: 50
                }}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis
                  dataKey="_source.@timestamp"
                  tickFormatter={unixTime =>
                    dateFilter === 15 || dateFilter === 60
                      ? moment(unixTime).format('hh:mm')
                      : moment(unixTime).format('DD/MM')
                  }
                  angle={-45}
                  interval={
                    dateFilter === 15 ? 0 : dateFilter === 60 ? 3 : null
                  }
                  tickCount={
                    dateFilter === 15 ? 15 : dateFilter === 60 ? 20 : null
                  }
                  dy={20}
                  type={chartData?.length > 0 ? 'category' : 'number'}
                  domain={[0, 'dataMax + 5']}>
                  <Label
                    stroke="#8884d8"
                    value="Date/Time"
                    position="insideBottom"
                    offset={-15}
                    dy={20}
                  />
                </XAxis>
                <Line
                  type="monotone"
                  dataKey="_source.interfaces.ifInOctets"
                  stroke="#ea4436"
                  name="ifInOctets"
                  activeDot={{ r: 8 }}
                />

                <Line
                  type="monotone"
                  dataKey="_score"
                  name="_score"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <YAxis
                  type="number"
                  domain={['auto', 'auto']}
                  interval={0}
                  allowDataOverflow={true}
                  label={{
                    stroke: '#ea4436',
                    value: matricType,
                    angle: -90,
                    position: 'insideLeft'
                  }}
                />
                <ReChartsTooltip content={<CustomTooltip />} />
              </LineChart>
            </Grid>
          )}
          {!loading && chartData?.length === 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <p>No Data</p>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenChartsPopup(false);
          }}>
          Close
        </Button>
      </DialogActions>
    </Popup>
  );
};

export default IpsecChartPopup;
