import React, { useState, useRef, useEffect } from 'react';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../components/OutlinedSelect' ;
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

function LineCrosingImage({ t }) {
    
    const canvasRef = useRef(null);

    //Load canvas for Line crossing area counting
    const [image, setImage] = useState("");
    const [style, setStyle] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [camResolution, setCamResolution] = useState('');
    const [startCords, setStartCords] = useState({x:0, y:0});
    const [lineCords, setLineCords] = useState({x:0, y:0});
    const [imageResolution, setImageResolution] = useState({width: "", height: ""});
    const [finalCords, setFinalCords] = useState({startX:0, startY:0, endX: 0, endY:0 });
    
    useEffect(() => {
        if(!image){
            return;
        }

        if(canvasRef.current){

            const context = canvasRef.current.getContext("2d");
            let startPosition = {x: 0, y: 0};
            let lineCoordinates = {x: 0, y: 0};
            let isDrawStart = false;
            const getClientOffset = (event) => {
                const {pageX, pageY} = event.touches ? event.touches[0] : event;
                const x = pageX - canvasRef.current.offsetLeft;
                const y = pageY - canvasRef.current.offsetTop;
                return {
                    x,
                    y
                } 
            }
            
            const drawLine = () => {
                context.beginPath();
                context.moveTo(startPosition.x, startPosition.y);
                context.lineTo(lineCoordinates.x, lineCoordinates.y);
                context.strokeStyle = 'red';
                context.lineWidth = 3;
                context.stroke();
            }
            
            const mouseDownListener = (event) => {
                startPosition = getClientOffset(event);
                isDrawStart = true;
            }
            
            const mouseMoveListener = (event) => {
                if(!isDrawStart) return;            
                lineCoordinates = getClientOffset(event);
                clearCanvas();
                drawLine();
            }
            
            const mouseupListener = (event) => {
                isDrawStart = false;
                setStartCords(startPosition);
                setLineCords(lineCoordinates);
            }
            
            const clearCanvas = () => {
                context.clearRect(0,0, canvasRef.current.width, canvasRef.current.height);
            }
            
            canvasRef.current.addEventListener('mousedown', mouseDownListener);
            canvasRef.current.addEventListener('mousemove', mouseMoveListener);
            canvasRef.current.addEventListener('mouseup', mouseupListener);
            
            canvasRef.current.addEventListener('touchstart', mouseDownListener);
            canvasRef.current.addEventListener('touchmove', mouseMoveListener);
            canvasRef.current.addEventListener('touchend', mouseupListener);
            context.save();
        }
        
    }, [image]);

    useEffect(() => {
        calculateResolution();
    }, [lineCords]);

    const roundOF2 = (num) => {
        return Math.round(num * 100) / 100;
    }

    const calculateResolution = () => {
        if(!camResolution){
            return null;
        }
        let camRsl = camResolution.split("*");
        if(!camRsl.length){
            return null;
        }
        let startX = roundOF2(parseInt(startCords.x)/parseInt(imageResolution.width));
        let startY = roundOF2(parseInt(startCords.y)/parseInt(imageResolution.height));
        let endX = roundOF2(parseInt(lineCords.x)/parseInt(imageResolution.width));
        let endY = roundOF2(parseInt(lineCords.y)/parseInt(imageResolution.height));
        startX = roundOF2(startX*parseInt(camRsl[0]));
        startY = roundOF2(startY*parseInt(camRsl[1]));
        endX = roundOF2(endX*parseInt(camRsl[0]));
        endY = roundOF2(endY*parseInt(camRsl[1]));
        setFinalCords({startX, startY, endX, endY });
    }
    
    const handleOnSelectImage = (evt) => {
        if(evt.target.files && evt.target.files.length){
            setSelectedImg(evt.target.value);
            const file = evt.target.files[0];
            let img = new Image();
            img.src = URL.createObjectURL(file);
            var reader = new FileReader(); 
            reader.onload = function (e) {
                img.onload = function(){
                    console.log('this.width', this.width);
                    setImageResolution({width: this.width, height: this.height});
                };
            };
            reader.readAsDataURL(file);
            setStyle({backgroundImage: `url(${URL.createObjectURL(file)})`, border:"1px solid", backgroundRepeat: 'no-repeat', backgroundPosition: 'center'});
            setImage(evt.target.value);
        }else{
            setImage("");
            setStyle(null);
            setSelectedImg(null);
        }
    }

    const handleSaveCordinates = () => {
        localStorage.removeItem("lineCrossing");
        localStorage.setItem('lineCrossing', JSON.stringify(finalCords));
        window.close();
    }

    const cameraRSOptions = ["640*480", "1024*780", "1920*1280"];
    return (
        <React.Fragment>
            {style && imageResolution &&
                <canvas ref={canvasRef} width={imageResolution.width} height={imageResolution.height} style={style} />
            }
            <Grid style={{ maxWidth:"300px", ...marginGenerator('mt-24'), ...marginGenerator('ml-24') }}>
                <Grid style={marginGenerator('mt-24')}>
                    <OutlinedSelect
                        val={camResolution}
                        label={`${t('resolution.label')} *`}
                        selectStyle={{...paddingGenerator('p-8')}}
                        styleOverrides={{ width: '100%', marginRight: 0 }}
                        handleChange={evt => setCamResolution(evt.target.value)}
                        options={cameraRSOptions}
                    />
                </Grid>
                <Grid>
                    <Input
                        value={selectedImg}
                        disabled={!camResolution}
                        type={'file'}
                        placeholder={`${t('image.label')}*`}
                        style={{ width: '100%', marginTop: "35px" }}
                        color={'secondary'}
                        inputProps={{accept:"image/*"}}
                        onChange={handleOnSelectImage}
                    />
                </Grid>
                {finalCords.startX > 0 &&
                    <Grid style={{...marginGenerator('mt-20')}}>
                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                            Line crossing Start - <br/> X axis : {finalCords.startX}, Y axis: {finalCords.startY}
                        </Typography>
                        <Typography style={{...marginGenerator('mt-15')}} variant={'subtitle1'} color={'textPrimary'}>
                            Line crossing End - <br/> X axis: {finalCords.endX}, Y axis: {finalCords.endY}
                        </Typography>
                    </Grid>
                }
                <Grid style={{...marginGenerator('mt-20'), ...marginGenerator('mb-40')}}>
                    <Button
                        color="secondary"
                        variant={'contained'}
                        disabled={!finalCords.startX}
                        onClick={() => {
                            handleSaveCordinates();
                        }}
                        style={{width:"100%"}}
                    >
                        {t('save.label')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default withTranslation()(LineCrosingImage);
