import React from 'react';
import ReactEcharts from 'echarts-for-react';

const AnalyticsDashboardLineChart = ({ dataKeyXAxis, stackKeyNames, visualMap, data}) => {
    return (
        <div>
            <ReactEcharts
                option={{
                    // Make gradient line here
                    height: "80%",
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    legend: {
                      data: stackKeyNames
                    },
                    visualMap: visualMap,
                    tooltip: {
                      trigger: 'axis'
                    },
                    xAxis: [
                      {
                        data: dataKeyXAxis
                      }
                    ],
                    yAxis: {
                      type: 'value'
                    },
                    series: data
                }}
            />
        </div>
    );
};

export default AnalyticsDashboardLineChart;
