import React, { useState, useEffect } from 'react';
import './styles.css';
import { Paths, TicketingSystem } from '../../routes/routePaths';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getParam } from '../../utils';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  REQUIRED_ERROR,
  TICKET_DATA,
  CONTACT_TYPE,
  SEVERITY_OPTIONS_INTERNAL,
  COUNTRY_LIST
} from '../../utils/constants';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from '@material-ui/core/Divider';
import {
  GET_TICKET_CATEGORIES,
  GET_ESCALATION_LIST,
  GET_INCIDENT_TYPE_LIST,
  GET_INCIDENT_TYPE_GROUP_LIST,
  GET_BASE_STATION_LIST,
  GET_ALL_INTERNAL_USER_LIST,
  GET_ASSIGNEE_LIST
} from './queries';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Image from '../../assets/icons/image.png';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import commonStyles from '../../theme/commonStyles';
import { CREATE_SERVICE_TICKET } from './mutations';
import Message from '../../components/Message';
import { UPLOAD_FILE } from '../commonMutations';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'newServiceTicket.label', link: TicketingSystem.NewServiceTicket },
  { label: 'internalTicket.label', link: TicketingSystem.InternalTicket }
];

let ticketCategory = [
  {
    id: '1',
    value: 'Corporate Ticket',
    key: 'corporateTicket'
  },
  {
    id: '2',
    value: 'Infra Ticket',
    key: 'infraTicket'
  }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  reviewItemContainer: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  table: {
    minWidth: 600,
  },
  scrolling: {
    marginTop: '40px',
    height: '200px',
    overflowY: 'scroll',
    border: '1px solid #ddd'
  },
  detailsContainer: {
    border: `1px solid ${theme.palette.grey[200]}`
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  }
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}


function NewServiceTicket({ t }) {
  const country = localStorage.getItem('country');
  const history = useHistory();
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [selectedOption, setSelectedOption] = useState({});
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState([]);
  const [comment, setComment] = useState('');
  const [comment2, setComment2] = useState('');
  const [contactType, setContactType] = useState({});
  const [details, setDetails] = useState('');
  const [ticketCategoryData, setTicketCategoryData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [region, setRegion] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileLoading, setUploadedFileLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [showInfraTicket, setShowInfraTicket] = useState(false);
  const [incidentTypeGroup, setIncidentTypeGroup] = useState([]);
  const [incidentType, setIncidentType] = useState([]);
  const [escalationLevel, setEscalationLevel] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [baseStationData, setBaseStationData] = useState([]);

  const [incidentCause, setIncidentCause] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [assigneeData, setAssigneeData] = React.useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [getAssigneeData, { data: assignee }] = useLazyQuery(GET_ASSIGNEE_LIST);
  const [getUserListData, { loading: userLoading, data: getAllUserList }] = useLazyQuery(GET_ALL_INTERNAL_USER_LIST);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
  const { data: escalationgroup } = useQuery(GET_ESCALATION_LIST);
  const { data: getBaseStationList } = useQuery(GET_BASE_STATION_LIST);
  const [getIncidentType, { data: incidenttype }] = useLazyQuery(
    GET_INCIDENT_TYPE_LIST
  );
  const [getIncidentTypeGroup, { data: incidenttypegroup }] = useLazyQuery(
    GET_INCIDENT_TYPE_GROUP_LIST
  );

  const [getCategoryData] = useLazyQuery(
    GET_TICKET_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: res => {
        res &&
          res.categories &&
          res.categories.length &&
          res.categories.slice(6, 7).map(data => {
            setSelectedOption(data);
          });
      }
    }
  );

  const [createServiceTicket] = useMutation(CREATE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket created successfully.');
      setUploadedFileLoading(false);
      history.push(Paths.TicketSystem);
    }
  });

  useEffect(() => {
    getUserListData({
      variables: {
        ticketType: TICKET_DATA.INTERNALTICKET
      }
    })
    getAssigneeData({
      variables: {
        ticketType: TICKET_DATA.INTERNALTICKET
      }
    })
    getIncidentTypeGroup({
      variables: {
        ticketType: TICKET_DATA.INTERNALTICKET
      }
    });
    getCategoryData()
  }, []);

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      return createServiceTicket({
        variables: {
          title: title,
          category: selectedOption.id,
          contactType: showInfraTicket ? "" : contactType.name,
          country: countryData.id,
          region: region.id,
          ticketType: 'internalTicket',
          description: details,
          incidentTypeGroup: incidentTypeGroup.name,
          incidentType: incidentType.name,
          baseStation: showInfraTicket ? baseStationData.id : '',
          severity: showInfraTicket ? severity.name : '',
          customers: selectedCustomerData,
          escalationLevel: escalationLevel.name,
          projectCategory: projectCategory.name,
          incidentCause: incidentCause,
          assignee: assigneeData,
          incidentTime: startDate,
          incidentEndTime: endDate,
          reportComment: comment,
          reportComment2: comment2,
          attachments: [data.uploadDocument.url]
        }
      });
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue.length === 0) {
      setSelectedCustomer([]);
      setSelectedCustomerData([]);
    } else {
      if (
        newValue[newValue.length - 1].attributes &&
        newValue[newValue.length - 1].attributes.Status &&
        newValue[newValue.length - 1].attributes.Status[0].toLowerCase() !=
        'active'
      ) {
        Message.error('User Account Is Not Active.');
      } else {
        let customerArray =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data;
          });
        setSelectedCustomer(customerArray);

        let customerArrayData =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data.id;
          });
        setSelectedCustomerData(customerArrayData);
      }
    }
  };

  const handleIncidentTypeGroup = (event, newValue) => {
    if (newValue === null) {
      setIncidentTypeGroup([]);
      setIncidentType([]);
    } else {
      setIncidentTypeGroup(newValue);

      newValue &&
        newValue.name &&
        getIncidentType({
          variables: {
            incidentTypeGroup: newValue.name,
            ticketType: TICKET_DATA.INTERNALTICKET
          }
        });
    }
  };

  const handleTicketCategory = (event, newValue) => {
    newValue && setTicketCategoryData(newValue);
    if (newValue && newValue.key === 'infraTicket') {
      setIncidentTypeGroup([])
      setIncidentType([])
      setShowInfraTicket(true);
    } else {
      setShowInfraTicket(false);
    }
  };

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '800px' }}>
      <Header
        title={t('internalServiceTicket.label')}
        divider={true}
        subText={t('createInternalTicketSUbText.label')}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
      />
      <Grid style={{ ...marginGenerator('mt-32') }}>
        {country !== 'null' && country === COUNTRY_LIST.ZAMBIA ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('ticketCategory.label')}*
            </Typography>
            <Autocomplete
              id="combo-box-demo7"
              value={ticketCategoryData}
              options={ticketCategory ? ticketCategory : []}
              getOptionLabel={option => option.value}
              onChange={(event, newValue) => handleTicketCategory(event, newValue)}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentCause.label')}
        </Typography>
        <Input
          value={title}
          type={'text'}
          placeholder={`${t('incidentCause.label')} *`}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setTitle(evt.target.value)}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('enterAdditionalDetails.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>

          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={details}
              id="details-textarea"
              placeholder={t('enterIssueDetails.label')}
              multiline
              onChange={evt => setDetails(evt.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid container item justify={'flex-end'}>
            {uploadedFile && (
              <Chip
                avatar={<img src={Image} alt={'file'} />}
                label={
                  uploadedFile.name.length > 12
                    ? `${uploadedFile.name.slice(0, 12)}...`
                    : uploadedFile.name
                }
                onDelete={() => {
                  setUploadedFile('');
                }}
                deleteIcon={<Close />}
                variant="outlined"
              />
            )}
            <IconButton
              size={'medium'}
              disableRipple={true}
              disableFocusRipple={true}
              color={'secondary'}
              onClick={handleFileUploadClick}
              style={marginGenerator('ml-16')}>
              <AttachFile className={commonClasses.iconRegular} />
            </IconButton>
            <input
              type="file"
              id="attachment"
              ref={inputFile}
              onChange={event => {
                event.stopPropagation();
                event.preventDefault();
                setUploadedFile(event.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>

        {country !== 'null' && country === COUNTRY_LIST.ZAMBIA && !showInfraTicket ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('contactType.label')} *
            </Typography>
            <Autocomplete
              id="combo-box-demo3"
              value={contactType}
              options={CONTACT_TYPE ? CONTACT_TYPE : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                setContactType(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectCountry.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={countryData}
          disabled={Boolean(disabled)}
          options={countryList ? countryList.getCountries : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            newValue &&
              newValue.id &&
              getRegionList({ variables: { countryId: newValue.id } });
            setRegion('');
            setCountryData(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRegion.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={region}
          options={regionList ? regionList.getRegionsByCountryId : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setRegion(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />
        {showInfraTicket ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('selectBaseStation.label')}
            </Typography>
            <Autocomplete
              id="combo-box-demo6"
              value={baseStationData}
              options={
                getBaseStationList ? getBaseStationList.getBaseStationList : []
              }
              getOptionLabel={option =>
                option && option.ulCTCBaseStationCode
                  ? option.ulCTCBaseStationCode
                  : ''
              }
              onChange={(event, newValue) => {
                setBaseStationData(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('selectUser.label')} *
        </Typography>
        <Autocomplete
          multiple
          id="tags-standard"
          value={selectedCustomer}
          disabled={Boolean(disabled)}
          options={getAllUserList ? getAllUserList.getAllUserList : []}
          getOptionLabel={option =>
            option && option.firstName
              ? `${option.firstName} ${option.lastName ? option.lastName : ""} ${option.emailAddress} 
              ${option.department ? option.department : ""}`
              : ''
          }
          onChange={(event, newValue) =>
            handleChangeCustomer(event, newValue)
          }
          loading={userLoading}
          autoHighlight
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              style={{
                width: '100%',
                border: '1px solid #ebebeb !important'
              }}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {userLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentTypeGroup.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo3"
          value={incidentTypeGroup}
          options={incidenttypegroup ? showInfraTicket ? incidenttypegroup.incidenttypegroup
            .slice(6, incidenttypegroup.incidenttypegroup.length) : incidenttypegroup.incidenttypegroup : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            handleIncidentTypeGroup(event, newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentType.label')} *
        </Typography>

        <Autocomplete
          id="combo-box-demo4"
          value={incidentType}
          disabled={!Boolean(incidentTypeGroup.id)}
          options={incidenttype ? incidenttype.incidenttype : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setIncidentType(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('escalationLevel.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo5"
          value={escalationLevel}
          options={escalationgroup ? escalationgroup.escalationgroup : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setEscalationLevel(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        {showInfraTicket ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...marginGenerator('mb-8'),
                ...marginGenerator('mt-40')
              }}>
              {t('severity.label')} *
            </Typography>
            <Autocomplete
              id="combo-box-demo3"
              value={severity}
              options={SEVERITY_OPTIONS_INTERNAL ? SEVERITY_OPTIONS_INTERNAL : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                setSeverity(newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('selectAssignee.label')}*
        </Typography>

        <Autocomplete
          id="combo-box-demo"
          val={assigneeData}
          options={assignee ? assignee.assignee : []}
          getOptionLabel={option => `${option.name} ${option.surname} `}
          onChange={(event, newValue) => {
            setAssigneeData(newValue.email.toLowerCase());
          }}
          style={{ width: '100%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '94%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentTime.label')}*
        </Typography>
        <div className="datePicker">
          <DatePicker
            timeIntervals={30}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => setStartDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy HH:mm"
            showTimeInput
          />
        </div>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('endDate.label')}*
        </Typography>
        <div className="datePicker">
          <DatePicker
            timeIntervals={30}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onChange={date => setEndDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy HH:mm"
            showTimeInput
          />
        </div>

        <h2 className="header">Reporting</h2>
        <Divider />
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('reportComment.label')}
        </Typography>
        <Input
          value={comment}
          type={'text'}
          placeholder={`${t('reportComment.label')} `}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setComment(evt.target.value)}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            ...marginGenerator('mb-8'),
            ...marginGenerator('mt-40')
          }}>
          {t('reportComment2.label')}
        </Typography>
        <Input
          value={comment2}
          type={'text'}
          placeholder={`${t('reportComment2.label')} `}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setComment2(evt.target.value)}
        />
      </Grid>

      <Grid
        container
        style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
        justify={'flex-end'}>
        <Button
          variant={'contained'}
          disabled={btnDisable ? btnDisable : uploadedFileLoading}
          color={'primary'}
          onClick={() => {
            if (
              !title ||
              !selectedOption.id ||
              !countryData.id ||
              !region.id ||
              !selectedCustomer.length ||
              !incidentTypeGroup.name ||
              !incidentType.name ||
              !escalationLevel.name ||
              !assigneeData ||
              !startDate
            ) {
              Message.error(REQUIRED_ERROR);
              return null;
            } else {
              setUploadedFileLoading(true);
              uploadedFile
                ? prepareUpload()
                : createServiceTicket({
                  variables: {
                    title: title,
                    category: selectedOption.id,
                    contactType: showInfraTicket ? "" : contactType.name,
                    country: countryData.id,
                    region: region.id,
                    ticketType: 'internalTicket',
                    description: details,
                    incidentTypeGroup: incidentTypeGroup.name,
                    baseStation: showInfraTicket ? baseStationData.id : '',
                    severity: showInfraTicket ? severity.name : '',
                    incidentType: incidentType.name,
                    customers: selectedCustomerData,
                    escalationLevel: escalationLevel.name,
                    projectCategory: projectCategory.name,
                    assignee: assigneeData,
                    incidentTime: startDate,
                    incidentEndTime: endDate,
                    reportComment: comment,
                    reportComment2: comment2,
                  }
                });
            }
          }}>
          {uploadedFileLoading ? (
            <CircularProgress size={14} />
          ) : (
            'Create Ticket'
          )}
        </Button>
      </Grid>
    </Layout>
  );
}

export default withTranslation()(NewServiceTicket);
