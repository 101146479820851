import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../theme/commonStyles';
import { dateFormatterLLL } from '../../../utils';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PersonOutlineIcon from '@material-ui/icons/Person';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
// import CloseIcon from '@material-ui/icons/Close';
// import { useState } from 'react';
// import MenuWrapper from '../../../../components/MenuWrapper';
// import Button from '@material-ui/core/Button';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    root: { fontSize: '16px'},
    pre: { padding: '10px 30px', margin: '0' }
});

const UserItem = ({
  t,
  data,
  handleSetAnchorEl,
  key
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();

    return (
        <TableRow key={key}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Icon
                            component={() => (
                                <PersonOutlineIcon
                                    color={'secondary'}
                                    style={{marginTop: '2px', marginRight: '6px'}}
                                />
                            )}
                            alt={'icon'}
                        />
                    </Grid>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.firstName + " " + data.lastName}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}> 
                            {data.email}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.phone ? data.phone : "-"}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.org ? data.org : "-"}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Typography variant={'subtitle1'}>
                        {data.role}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            handleSetAnchorEl(evt.currentTarget, data)
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

UserItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(UserItem);