import React, { useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_COUNTRIES } from './queries';
import {ADD_REGIONS_TO_COUNTRY, ADD_UPDATE_COUNTRY} from './mutations';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { InternalPaths } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import CountryItem from './components/CountryItem'
import CreateCountryModal from './components/CreateCountryModal';
import Message from '../../../components/Message';
import CreateRegionModal from './components/RegionModal';
import RegionViewModal from './components/RegionViewModal';
import { GET_REGION_LIST } from '../../commonQueries';

const breadcrumbList = [
  { label: 'home.label', link: InternalPaths.Dashboard },
  { label: 'countries.label', link: InternalPaths.countries }
];

const Countries = ({ t }) => {

    const [countryModalState, setCountryModalState] = useState(false);
    const [modalState, setModalState] = useState({btnLoading: false, clearData: false});
    const [updateCountry, setUpdateCountry] = useState(null);
    const [selectedCountryData, setSelectedCountryData] = useState(null);
    const [viewRegionModal, setViewRegionModal] = useState(false);
    const [regionModalState, setRegionModalState] = useState({btnLoading: false, clearData: false, open: false});
    const [regionList, setRegionList] = useState([]);
    const menuItems = [
        {label: t('edit.label')},
        {label: t('addRegion.label')},
        {label: t('regions.label')}
    ];
    
    const handleAddNewSubAdminClick = searchParam => {
        setCountryModalState(true);
    };
    const {data, loading, error, refetch: reloadCountries} = useQuery(GET_COUNTRIES, {
        fetchPolicy: "no-cache"
    });
    const [getCountryRegions, {loading: regionLoading}] = useLazyQuery(GET_REGION_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setRegionList(res.getRegionsByCountryId ? res.getRegionsByCountryId : []);
        }
    });
    const columns = [
        {
            label:t('name.label'),
            key:"name"
        },
        {
            label:t('countryCode.label'),
            key:"countryCode"
        },
        {
            label:t('emails.label'),
            key:"emails"
        },
        {
            label:t('defaultLang.label'),
            key:"defaultLang"
        },
        {
            label:t('createdDate.label'),
            key:"createdDate"
        },
        {
            label:t('edit.label'),
            key:"button"
        }
    ];

    const [addUpdateCountry] = useMutation(ADD_UPDATE_COUNTRY, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            setModalState({btnLoading: false, clearData: true});
            setUpdateCountry(null);
            Message.success(`Country ${updateCountry ? 'updated' : 'created'} successfully`);
            reloadCountries();
        }
    });
    
    const [addRegionsToCountry] = useMutation(ADD_REGIONS_TO_COUNTRY, {
        onError: error => {
            console.log('Error', error);
            setRegionModalState({...regionModalState, btnLoading: false});
        },
        onCompleted: data => {
            setRegionModalState({btnLoading: false, clearData: true, open: false});
            setSelectedCountryData(null);
            Message.success(`Regions added successfully`);
        }
    });
    
    const handleMenuItemClick = (elem, data) => {
        if(elem.label === t('addRegion.label')){
            setSelectedCountryData(data);
            setRegionModalState({...regionModalState, open: true, clearData: false});
        }else if(elem.label === t('regions.label')){
            setViewRegionModal(true);
            setSelectedCountryData(data);
            return getCountryRegions({variables: {
                countryId: data.id
            }});
        }else{
            setModalState({btnLoading: false, clearData: false});
            setUpdateCountry(data);
            setCountryModalState(true);
        }
    }

    const handleClose = () => {
        setCountryModalState(false);
    }
    
    const handleSubmitClick = (formData) => {
        setModalState({btnLoading:true, clearData: false});
        return addUpdateCountry({variables: formData});
    }

    const handleAddRegionSubmit = (regionsData) => {
        if(selectedCountryData && regionsData){
            setRegionModalState({...regionModalState, btnLoading: true});
            return addRegionsToCountry({
                variables: {regions: regionsData, countryId: selectedCountryData.id} 
            });
        }
    }
    
    return (
        <Layout breadcrumbList={breadcrumbList}>
            <Header
                title={t('countries.label')}
                subText={`${t('countrySubTxt.label')}`}
                primaryButtonText={t('addCountryBtn.label')}
                handlePrimaryButtonClick={handleAddNewSubAdminClick}
            />
            <Grid container style={{marginBottom: 24}} >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                            data.getCountries.length ? 
                            data.getCountries.map((item, index) => (
                                <CountryItem data={item} key={index} handleMenuItemClick={handleMenuItemClick} menuItems={menuItems} />
                            )) : 
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <CreateCountryModal 
                open={countryModalState} 
                handleSubmitClick={handleSubmitClick} 
                data={updateCountry} 
                t={t} 
                handleClose={handleClose} 
                modalState={modalState} 
            />
            {selectedCountryData && <>
                <CreateRegionModal 
                    open={regionModalState.open} 
                    handleSubmitClick={handleAddRegionSubmit} 
                    data={selectedCountryData} 
                    t={t} 
                    handleClose={(evt) => {
                        setRegionModalState({open: false, clearData: true, btnLoading: false});
                        setSelectedCountryData(null);
                    }}
                    modalState={regionModalState} 
                />
                <RegionViewModal 
                    t={t}
                    open={viewRegionModal}
                    data={selectedCountryData}
                    handleClose={evt => {
                        setViewRegionModal(false);
                        setSelectedCountryData(null);
                    }}
                    regions={regionList}
                    loading={regionLoading}
                />
            </>}

        </Layout>
    );
};
export default withTranslation()(Countries);
