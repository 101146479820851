
import React from 'react';
import '../FabricService.css';
import FabricDashboard from './FabricDashboard';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '3em'
  }
}));

export default function FabricServices() {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.container}>
        <Grid item>
          <FabricDashboard />
        </Grid>
      </Grid>
    </>
  );
}
