import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  description: {
   fontSize:10,
   color: '#000000'
  },
  total: {
   fontSize:10
  },
container: {
  marginTop: 0,
  marginBottom: 0,
  paddingBottom: 50,
  flexDirection: 'row',
  '@media max-width: 400': {
    flexDirection: 'column'
  }
},
leftContainer: {
  flexDirection: 'column',
  width: 300,
  paddingTop: 30,
  paddingBottom: 20,
  '@media max-width: 400': {
    width: '100%',
    paddingRight: 0
  },
  '@media orientation: landscape': {
    width: 300
  }
},
centerContainer: {
  flexDirection: 'column',
  width: 120,
  paddingTop: 30,
  paddingBottom: 20,
  paddingLeft: 0,
  paddingBottom: 0,
  color: '#9696A4',
  '@media max-width: 400': {
    paddingTop: 10,
    paddingLeft: 0
  }
},
rightContainer: {
  flexDirection: 'column',
  width:230,
  paddingTop: 30,
  paddingBottom: 20,
  paddingLeft: 0,
  paddingBottom: 0,
  '@media max-width: 400': {
    paddingTop: 10,
    paddingLeft: 0
  }
}
});

const InvoiceTableFooter = ({ data }) => {
  let totalLinkOutageArr = data && data.length && data[0].id != '' && data.map(item =>{
    return item.timeToRestore
  })
  let outageNotInclude = data && data.length && data[0].id != '' && data.filter(item => {
    return item.vodacomProblem == false
  })
  let outageNotIncludeArr = outageNotInclude && outageNotInclude.length && outageNotInclude.map(item =>{
    return item.timeToRestore
  })
  let finalData = data && data.length && data[0].id != '' && data.filter(item => {
    return item.vodacomProblem == true
  })
  let restoreData = finalData && finalData.length && finalData.map(item =>{
    return item.timeToRestore
  })

  let timeToRestore = finalData && finalData.length ? (
  restoreData && restoreData.length && restoreData.reduce((acc, curr) => {
    let accVal = acc.split(":")
    let currVal = curr.split(":")
    let days = parseInt(accVal[0]) + parseInt(currVal[0]);
    let hours = parseInt(accVal[1]) + parseInt(currVal[1]);
    let minutes = parseInt(accVal[2]) + parseInt(currVal[2]);
    let second = parseInt(accVal[3]) + parseInt(currVal[3]);
    return days + ":" + hours + ":" + minutes + ":" + second;
  }, "0:0:0:0")
  ) :  "0:0:0:0"

  let calculateLinkOutage = (arr) => {
   return arr.reduce((acc, curr) => {
        let accVal = acc.split(":")
        let currVal = curr.split(":")
        let days = parseInt(accVal[0]) + parseInt(currVal[0]);
        let hours = parseInt(accVal[1]) + parseInt(currVal[1]);
        let minutes = parseInt(accVal[2]) + parseInt(currVal[2]);
        let second = parseInt(accVal[3]) + parseInt(currVal[3]);
        return days + ":" + hours + ":" + minutes + ":" + second;
      }, "0:0:0:0")
  }

  let totalOutage = totalLinkOutageArr && totalLinkOutageArr.length ? calculateLinkOutage(totalLinkOutageArr) : '0:0:0:0'
  let outageNotIncluded = outageNotIncludeArr && outageNotIncludeArr.length ? calculateLinkOutage(outageNotIncludeArr) : '0:0:0:0'
  let outageIncluded = restoreData && restoreData.length ? calculateLinkOutage(restoreData) : '0:0:0:0'

  const finalrestoreTime = (timeToRestore, time) => {
    let timeInSeconds = time ==='yes' ? (convertH2M(timeToRestore) * 60) / data.length : (convertH2M(timeToRestore) * 60)
    seconds = Number(timeInSeconds);
    var days = Math.floor(seconds / (3600*24));
    var hours = Math.floor(seconds % (3600*24) / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);
    var seconds = Math.floor(seconds % 60);

    return days + ':' + hours + ':' + minutes + ':' + seconds;
  }

  const targetMeetFun = input => {
    let number = input.toString().split(':');
    if (number[0] > 0) {
      return 'No';
    } else {
      if(number[1] >= 4){
        return "No"
      }else{
        return 'Yes';
      }
    }
  };

  function convertH2M(timeInHour){
    var timeParts = timeInHour.split(":");
    let timeInMinutes = (Number(timeParts[0]) * 24 * 60) + (Number(timeParts[1]) * 60) + (Number(timeParts[2]));
    return timeInMinutes
  }

  const calculateActualPercentage = (restoreTime) => {
    let minutesToRestore = convertH2M(restoreTime)
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let daysInMonth = new Date(year, month, 0).getDate();
    let minutesInMonth = daysInMonth * 24* 60
    let actualPercentage = ((minutesInMonth - minutesToRestore) / minutesInMonth) * 100
    return actualPercentage.toFixed(2)
  }
  
  return (
    <React.Fragment>
      <>
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          <Text style={styles.description}>Total link Outage in this month : {`${totalOutage}`} </Text>
          <Text style={styles.description}>Outage include in Availability calculation : {`${outageIncluded}`} </Text>
          <Text style={styles.description}>Outage Not include in Availability calculation : {`${outageNotIncluded}`} </Text>
        </View>
        <View style={styles.centerContainer}>
          <Text style={styles.description}>Target Time (hr): </Text>
          <Text style={styles.description}>Actual Time (d:h:m:s): </Text>
          <Text style={styles.description}>Target: </Text> 
          <Text style={styles.description}>Actual: </Text>
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.row}>
            <Text style={styles.total}>{'4:00'}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.total}>{finalData.length > 0 ? finalrestoreTime(timeToRestore, 'yes') : timeToRestore}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.total}>99%</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.total}>{finalData.length > 0 ? calculateActualPercentage(finalrestoreTime(timeToRestore, 'no')) : calculateActualPercentage(timeToRestore)} {"%"}</Text>
          </View>
        </View>
      </View>
        
      </>
    </React.Fragment>
  );
};

export default InvoiceTableFooter;
