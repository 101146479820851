import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { dateFormatterLLL } from '../../utils';
import { marginGenerator } from '../../theme/utils';
import { USER_ROLES } from '../../utils/constants';
import commonStyles from '../../theme/commonStyles';
import AttachFile from '@material-ui/icons/AttachFile';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fright: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageAlign: {
    width: '100px',
    height: 'auto'
  }
}));

function ActivityList({ list }) {
  const classes = useStyles();
  const role = localStorage.getItem('role');
  const commonClasses = commonStyles();
  return (
    <div style={{ paddingBottom: '90px' }}>
      {role.includes(USER_ROLES.CSOC_MANAGER) || role.includes(USER_ROLES.CSOC) ||
        role.includes(USER_ROLES.SD_READONLY) || role.includes(USER_ROLES.THIRD_PARTY_VENDOR) ? (
        <>
          {list.map((activityItem, activityIndex) => (
            <Grid
              container
              style={marginGenerator('mb-24')}
              justify={'flex-start'}
              key={`activity-${activityIndex}`}>
              <Grid item xs={5} sm={3}>
                <Typography
                  variant={'subtitle2'}
                  className={commonClasses.normalFontWeight}>
                  {dateFormatterLLL(activityItem.createdAt)}
                  <br />
                  {activityItem.createdByName}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant={'body1'} color={'textPrimary'}>
                  {activityItem.logMessage}
                </Typography>
                {activityItem.attachments &&
                  activityItem.attachments.map((item, index) =>
                    item ? (
                      <Grid
                        key={index}
                        container
                        alignItems={'center'}
                        style={marginGenerator('mt-8')}>
                        <AttachFile
                          color={'action'}
                          className={commonClasses.iconRegular}
                          style={{ ...marginGenerator('mr-8') }}
                        />
                        <Link
                          variant={'body2'}
                          color={'textSecondary'}
                          href={item}
                          target={'_blank'}>
                          <img
                            className={classes.imageAlign}
                            src={item}
                            href={item}
                            target="_blank"
                          />
                        </Link>
                      </Grid>
                    ) : null
                  )}
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
      {/*</Fragment>*/}
      {/*  );*/}
      {/*})}*/}
    </div>
  );
}

ActivityList.propTypes = {};

export default ActivityList;
