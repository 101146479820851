import React,{ useState, useContext } from 'react';
import { get } from "lodash-es";
import { useMutation} from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import OcrProjectItem from './OcrProjectItem';
import CreateProject from './CreateProject';
import OcrConfigurationEditProject from './OcrConfigurationEditProject';
import {marginGenerator} from '../../../../../../theme/utils';
import {
    EDIT_PROJECT
} from '../../../../Ocr/mutations';
import Message from '../../../../../../components/Message';
import {OcrContext} from '../../../../../../contexts/OcrProvider';

const useStyles = makeStyles(theme => ({
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  }
}));

const OcrConfigurationProject = ({
  t,
  //ocrProjectData,
  //loading,
  page,
  handlePageChange,
  isProjectEnable,
  handleCreateProjectEnableFormClose,
  createProjectEnable,
  ocrPlaceData,
  placeLoading,
  handleSubmitOfOcrProject,
  ocrProjectSubmit,
  handleSubmitOfDeleteOcrTreeItems,
  selecteIdForDelete,
  ocrProjectDeletetingStarted
}) => {
  
  const classes = useStyles();
  const [isEditProjectEnable, setIsEditProjectEnable] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const { 
    organizationId,
    ocrProjectData,
    ocrProjectDataLoading,
    refetchProjectData
  } = useContext(OcrContext);
  
  let columns = [
    {
        label: 'Project' + ' ' + t('name.label'),
        key: "name"
    },
    {
      label: 'Description',
      key: "description"
    },
    {
        label: t('places.label'),
        key: "placeName"
    },
    {
        label: t('createdBy.label'),
        key: "createdByName"
    },
    {
        label: t('createdDate.label'),
        key: "createdDate"
    },
    {
        label: t('action.label'),
        key: "action"
    }
];

const [editProejct, { loading:editProjectLoading }] = useMutation(EDIT_PROJECT, {
    onError: error => {
        console.log('err', error);
    },
    onCompleted: data => {
        Message.success(data && data.editProject.message);
        refetchProjectData();
        handleCloseOfEditProjectModal();
    }
});

const handleEditSubmitOfProject = (data) => {
    editProejct({
        variables:{
          organization: organizationId,
          id: data.id,
          name: data.projectName
        }
    })
};

const enableEditProjectModal = (data) => {
    setIsEditProjectEnable(true);
    setSelectedData(data);
};

const handleCloseOfEditProjectModal = () => {
    setIsEditProjectEnable(false);
    setSelectedData('');   
};

  return(
    <div>
      <Grid container style={{...marginGenerator("mb-20")}}>
        <Grid item xs="10"/>
        <Grid item xs="2">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={createProjectEnable}
                >
                {t('createProject.label')}
            </Button>
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!ocrProjectDataLoading ?
                      ocrProjectData.data && ocrProjectData.data.length > 0 ?
                      ocrProjectData.data.map((rowitem, rowIndex) => (
                                <OcrProjectItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    ocrProjectDeletetingStarted={ocrProjectDeletetingStarted}
                                    selecteIdForDelete={selecteIdForDelete}
                                    handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                                    enableEditProjectModal={enableEditProjectModal}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {ocrProjectDataLoading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            { !ocrProjectDataLoading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(ocrProjectData, 'count', null)/20)} page,  {get(ocrProjectData, 'count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={ocrProjectData && ocrProjectData.count ? Math.ceil(ocrProjectData.count/20) : 0} 
                                page={page} 
                                onChange={(evt, value) => {
                                    handlePageChange("ocr_project", value);
                                }} 
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </TableContainer>
    </Grid>
    <CreateProject 
      t={t}
      open={isProjectEnable}
      onClose={handleCreateProjectEnableFormClose}
      loading={placeLoading}
      ocrPlaceData={ocrPlaceData}
      handleSubmitOfOcrProject={handleSubmitOfOcrProject}
      ocrProjectSubmit={ocrProjectSubmit}        
    />
    <OcrConfigurationEditProject
      t={t}
      open={isEditProjectEnable}
      onClose={handleCloseOfEditProjectModal}
      data={selectedData}
      editProjectLoading={editProjectLoading}
      handleEditSubmitOfProject={handleEditSubmitOfProject}   
    />
</div>
  );
};

export default OcrConfigurationProject;
