import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

const DemosVideoPopup = ({ t, onOpen, onClose, children }) => {
    const classes = useStyles();
    return (
        <>
            <Dialog
                classes={{ paper: classes.paper }}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="country-modal"
                aria-describedby="country-modal"
                open={onOpen}
                disableBackdropClick={true}

                onClose={onClose}>
                <DialogTitle>
                    <Typography variant="subtitle1">Video</Typography>
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" onClick={() => onClose(onClose)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withTranslation()(React.memo(DemosVideoPopup));