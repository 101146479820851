import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { dateFormatterLLL } from '../../../../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { CAMERA_STATUS } from '../../../../../../../utils/constants';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { paddingGenerator } from '../../../../../../../theme/utils';

const useStyles = makeStyles(theme => ({
    statusContainer: {
      height: '15px',
      borderRadius: '16px',
      alignItems: 'center',
      display: 'flex'
    },
    provStatus: {
      background: theme.palette.warning.main
    },
    activeStatus: {
      background: theme.palette.success.main
    },
    failedStatus: {
      background: theme.palette.error.main
    },
    textStyle: {
      color: theme.palette.common.white,
      fontSize: '14px'
    }
}));

const VideoStatus = ({ status }) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    status = status.toLowerCase();
    let statusClasses, label;
    if(status === CAMERA_STATUS.PANDING){
      statusClasses = classes.provStatus;
      label = "Processing";
    }else if(status === "true"){
      statusClasses = classes.activeStatus;
      label = "True";
    }else if(status === "false"){
      statusClasses = classes.failedStatus;
      label = "False";
    }else{
      statusClasses = classes.provStatus;
      label = "Processing";
    }
    return (
        <div
        className={clsx(
            classes.statusContainer,
            statusClasses
        )}
        style={{
            ...paddingGenerator('pt-8'),
            ...paddingGenerator('pb-6'),
            ...paddingGenerator('pl-12'),
            ...paddingGenerator('pr-12')
        }}>
          <Typography
              className={clsx(commonClasses.buttonTextSmall, classes.textStyle)}
              variant={'body1'}>
              {label}
          </Typography>
        </div>
    );
};

const LivenessPanelItem = ({
    t,
    data,
    handleViewVideo
}) => {

    const commonClasses = commonStyles();
    const status = (data.status && data.status.toLowerCase() === "pending") ? "pending" : (data.is_live) ? "True" : "False";

    return (
        <>
            <TableRow key={data._id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography
                            variant={'subtitle1'}
                            style={{ cursor: 'pointer' }}
                        >
                            {data.person}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {dateFormatterLLL(data.uploaded_on)}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {data.status}
                        </Typography>
                    </Grid>
                </TableCell>
                {/* <TableCell>
                    <Grid className={commonClasses.column}>
                    <Button
                        color="primary"
                        style={{width: 120, overflow: "hidden"}}
                    >
                        Show Result
                    </Button>
                    </Grid>
                </TableCell> */}
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <VideoStatus status={status} />
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <IconButton onClick={() => handleViewVideo(data, "video")}>
                            <VisibilityIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleViewVideo(data, "image")} style={{ marginLeft: 8 }}>
                            <CameraAltIcon color="primary" />
                        </IconButton>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

LivenessPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(LivenessPanelItem);