import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {withTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import {getUrl} from "../../../../utils";
import {InternalPaths, NewSubscriptionPaths, Paths} from "../../../../routes/routePaths";
import {useHistory} from "react-router";
import {useQuery} from "@apollo/react-hooks";
import {GET_CATEGORIES, GET_VNF_MARKET_PLACE} from "../../../Internal/VnfMarketPlace/queries";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import VNFMarketplaceDetailPanel from "../VNFMarketplaceDetailPanel";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    listView: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listItem: {cursor: "pointer"},
    image: {
        width: "225px",
        marginRight: "15px",
        marginTop: "15px"
    },
    title: {
        fontSize: "20px",
        lineHeight: "26px",
        fontWeight: 600,
        color: "#008bcc",
        marginBottom: "10px"
    },
    inline: {
        display: 'inline',
        lineHeight: "26px",
        marginBottom: "5px"
    },
    description: {
        display: "flex",
        alignItems: "center",
        lineHeight: "20px",
        marginBottom: "5px"
    },
    nested: {
        paddingLeft: theme.spacing(1),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        maxWidth: "100%",
    },
    autoComplete: {
        maxWidth: "100%",
        borderRadius: '8px',
        margin: "20px 10px 10px",
        border: '1px solid #d1d1d1',
        '& label ': {
            transform: 'translate(14px, 20px) scale(1)'
        },
        '& .popupIndicator': {
            marginTop: "-12px"
        }
    },
}));
const { useCallback, useState } = React
const NavLink = ({ _id, name, isActive, onClick, classes }) => (
    <ListItem button selected={!!isActive}
              onClick={useCallback(() => onClick(_id), [_id])}
              className={classes.nested}>
        <ListItemText primary={name}/>
    </ListItem>
)
function VNFMarketplacePanel({t, subscriptionData, openModal, handleClose}) {
    const classes = useStyles();
    const history = useHistory();
    const role = localStorage.getItem('role');
    const theme = useTheme();
    const [openMenu, setOpenMenu] = React.useState(true);
    const [openMarketPlaceDetailModal, setOpenMarketPlaceDetailModal] = React.useState(false);
    const [vnfMarketPlaceId, setVnfMarketPlaceId] = React.useState('');
    const [categoryData, setCategoryDataState] = React.useState([]);
    const [value, setValue] = React.useState(2);
    const [active, setActive] = useState(null);
    const {data, loadingData, error, refetch: reloadVnfMarketPlace} = useQuery(GET_VNF_MARKET_PLACE, {
        variables: {
            categoryId: active
        }
    });
    const {loading: loadingCatData, data: categoryData1} = useQuery(GET_CATEGORIES, {
            onCompleted: data => {
                setCategoryDataState(data.getCategories)
                console.log('categoryDta',  data)
            }
        }
    );
    const handleClick = () => {
        setOpenMenu(!openMenu);
    };
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    function handleNavigate(path) {
        history.push(path);
    }
    const handelVnfMarketPlaceDetails = (id) => {
        setOpenMarketPlaceDetailModal(true)
        setVnfMarketPlaceId(id)
    }
    const handleCloseMarketPlaceModal = (prams) => {
        if (prams === 'success') {
            handleClose()
        }
        setOpenMarketPlaceDetailModal(false)
    }
    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
            await sleep(1e3); // For demo purposes.
            const countries = await response.json();
            if (active) {
                setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    const handleCreateAdminClick = () => {
        const path = getUrl(InternalPaths.createNewUser);
        history.push({pathname: path});
    }
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                aria-labelledby="marketplace-modal"
                aria-describedby="marketplace-modal"
                open={openModal}
                style={{alignItems: 'baseline'}}
                onClose={() => {
                    handleClose();
                }}>
                <DialogTitle id="ml-template-title">
                    <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        xs={12}
                        style={{fontSize: "25px"}}
                        align={'center'}>
                        {t('vnfMarketplace.label')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Categories
                                    </ListSubheader>
                                }
                                className={classes.root}
                            >
                                <ListItem button onClick={handleClick}>
                                    <ListItemText primary="All Categories"/>
                                    {openMenu ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={openMenu} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {!loadingCatData?
                                            categoryData && categoryData.length ?
                                                categoryData.map((item, index) => (
                                                    <NavLink
                                                        key={index}
                                                        {...item}
                                                        onClick={setActive}
                                                        classes={classes}
                                                        isActive={active === item._id} />
                                                )) :
                                                <Typography variant={'subtitle2'} style={{textAlign: 'center'}}>Record not found!</Typography> : null}
                                        {loadingCatData &&
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                        }
                                    </List>
                                </Collapse>
                            </List>
                        </Grid>
                        <Grid item xs={10}>
                            {/*<Autocomplete
                                id="asynchronous-demo"
                                open={open}
                                className={classes.autoComplete}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={options}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search for any image via VNF marketplace"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />*/}
                            <List className={classes.listView}>
                                {!loadingData && !error ?
                                    data && data.getVnfMarketPlaces.length ?
                                        data.getVnfMarketPlaces.map((item, index) => (
                                            <>
                                                <ListItem alignItems="flex-start" key={index} className={classes.listItem}
                                                          onClick={() => {
                                                              handelVnfMarketPlaceDetails(item._id)
                                                              /*const path = getUrl(Paths.VnfMarketplaceDetails, item._id);
                                                              history.push(path);*/
                                                          }}>
                                                    <div className={classes.image}>
                                                        <img alt="Cindy Baker" className={classes.cover} src={item.imageLogo}/>
                                                    </div>
                                                    <ListItemText
                                                        primary={<React.Fragment>
                                                            <Typography
                                                                component="h4"
                                                                variant="body2"
                                                                className={classes.title}
                                                                color="textPrimary"
                                                            >
                                                                {item.imageName}
                                                            </Typography>
                                                        </React.Fragment>}
                                                        secondary={
                                                            <React.Fragment>
                                                                {/*<Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {item.imageType}
                                                                </Typography>
                                                                {' — ' + item.createdAt}
                                                                <Typography
                                                                    variant="body2"
                                                                    className={classes.description}
                                                                    color="textPrimary"
                                                                >
                                                                    <Rating name="read-only" value={value} readOnly/> {' — 2 AWS review'}
                                                                </Typography>*/}
                                                                <Typography paragraph className={classes.description}>
                                                                    {item.description}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider variant="inset" component="li"/>
                                            </>
                                        )) :
                                        <Typography variant={'subtitle2'} style={{textAlign: 'center', fontSize: '25px'}}>Record not found!</Typography> : null}
                                {loadingData &&
                                <Typography variant={'subtitle2'}>Loading...</Typography>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {openMarketPlaceDetailModal &&
            <VNFMarketplaceDetailPanel t={t} data={subscriptionData} open={openMarketPlaceDetailModal} id={vnfMarketPlaceId} handleClose={handleCloseMarketPlaceModal}/>
            }
        </>
    );
}
VNFMarketplacePanel.propTypes = {
    t: PropTypes.func.isRequired
};
export default withTranslation()(VNFMarketplacePanel);
