import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {
    GET_ALL_CONTROLLERS,
    GET_ALL_DEVICE_INTERFACES,
    GET_ALL_NETWORK_INTERFACES,
    GET_ALL_VNF_NETWORK_INTERFACES,
    GET_TEMPLATE_LIST,
    GET_UCPE_LIST
} from '../../queries';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import PlacesMap from "../../../NewSubscription/components/PlacesMap";
import Message from "../../../../components/Message";
import {REQUIRED_ERROR} from "../../../../utils/constants";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    input: {
        minWidth: '224px'
    }
}));
function EditVNFModal({t, data, open, handleEditVnf, handleClose, modalState}) {
    const organization_id = data.organizationId
    const [VNFIPAddress, setVNFIPAddress] = useState('');
    const [VNFPort, setVNFPort] = useState('');
    const [vnfURLType, setVNFURLType] = useState('');
    const [vnfURL, setVNFURL] = useState('');
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setVNFIPAddress('');
            setVNFPort('');
            setVNFURL('');
            handleClose();
        }
    }, [modalState]);
    const classes = useStyles();
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ip-address-template-modal"
            aria-describedby="ip-address-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="place-template-title">{t('edit.label')} {t('vnf.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                          style={{
                              ...paddingGenerator('pb-30')
                          }}>
                        <Input
                            value={VNFIPAddress}
                            type={'text'}
                            placeholder={`${t('sshIP.label')}*`}
                            style={{width: '100%'}}
                            color={'secondary'}
                            onChange={evt => setVNFIPAddress(evt.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}
                          style={{
                              ...paddingGenerator('pb-30')
                          }}>
                        <Input
                            value={VNFPort}
                            type={'text'}
                            placeholder={`${t('sshPort.label')}*`}
                            style={{width: '100%'}}
                            color={'secondary'}
                            onChange={evt => setVNFPort(evt.target.value)}
                        />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            style={{...marginGenerator('mt-8'), ...marginGenerator('mb-24')}}
                            xs={3}>
                            <OutlinedSelect
                                val={vnfURLType}
                                label={`${t('type.label')} *`}
                                onChange={evt => setVNFURLType(evt.target.value)}
                                styleOverrides={{width: '100%', marginRight: 0}}
                                options={[
                                    {id: 'http://', name: 'http://'},
                                    {id: 'https://', name: 'https://'}
                                ]}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9}>
                            <Input
                                type={'text'}
                                placeholder={`${t('url.label')}*`}
                                style={{width: '100%'}}
                                className={classes.input}
                                color={'secondary'}
                                value={vnfURL}
                                onChange={evt => setVNFURL(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleEditVnf({vnf_ssh_ip: VNFIPAddress, vnf_web: vnfURLType + vnfURL, vnf_ssh_port: VNFPort});
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
EditVNFModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(EditVNFModal);
