import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../theme/commonStyles';
import { paddingGenerator } from '../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    width: theme.spacing(30),
    height: theme.spacing(10),
    border: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(1)
  }
}));

function Statcard({
  statLabel,
  statValue,
  icon,
  type,
  handleClick,
  customStyles
}) {
  const commonClasses = commonStyles();
  const classes = useStyles();
  return (
    <Grid item>
      <Grid
        container
        item
        justify={'space-between'}
        alignItems={'center'}
        className={classes.cardContainer}
        onClick={handleClick}
        style={{
          ...paddingGenerator('pt-16'),
          ...paddingGenerator('pb-16'),
          ...paddingGenerator('pr-16'),
          ...paddingGenerator('pl-24'),
          cursor: type === 'cam_down' ? 'pointer' : 'default',
          ...customStyles
        }}>
        <Grid item>
          <Typography variant={'h4'} style={{ paddingTop: 0 }}>
            {statValue}
          </Typography>
          <Typography variant={'subtitle2'}>{statLabel}</Typography>
        </Grid>
        {icon && (
          <Grid item>
            <img
              src={icon}
              className={commonClasses.iconExtraLarge}
              alt={'statIcon'}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

Statcard.propTypes = {
  statLabel: PropTypes.string.isRequired,
  statValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  icon: PropTypes.string.isRequired
};

export default Statcard;
