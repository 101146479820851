import React, { useState, createContext, useCallback, useEffect } from 'react';
import { get } from "lodash-es";
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import {
  GET_OCR_PROCESS_STEPS_LIST,
  GET_OCR_USERS_LIST,
  GET_OCR_FOLDER_LIST,
  GET_OCR_PROJECT_LIST,
  GET_OCR_GROUPS_OF_FOLDERS
} from '../containers/Subscriptions/Ocr/queries';
import Message from '../components/Message';

export const OcrContext = createContext();

function OcrProvider({ children, data }) {
  
  const { organizationId } = data.getSubscriptionById;
  const [ocrProcessStepsData, setOcrProcessStepsDate] = useState('');
  const [ocrGroupsOfFolders, setOcrGroupsOfFolders] = useState('');

  const { data: ocrUsersData, loading: ocrUsersListLoading  } = useQuery(GET_OCR_USERS_LIST, { 
    variables: {
        organization: organizationId,
        groupId: ''
    } 
  });

  const { data: ocrFolderData, loading: loadingOcrFolder, refetch: refetchFolder  } = useQuery(GET_OCR_FOLDER_LIST, { 
    variables: {
      organization: organizationId,
      project: null,
      place: null,
      search: null,
      page: 1,
      limit: 1000
    } 
  });

  const { data: ocrProjectData, loading: ocrProjectDataLoading, refetch: refetchProjectData  } = useQuery(GET_OCR_PROJECT_LIST, { 
    variables: {
        organization: organizationId,
        limit: 1000,
        page: 1
    } 
});

  const [getOcrProcessStepsList, {loading: ocrProcessStepsLoading}] = useLazyQuery(GET_OCR_PROCESS_STEPS_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const ocrUsersList =  get(data, 'getOcrProcessStepsList', '');
      if(ocrUsersList){
        setOcrProcessStepsDate(ocrUsersList);
      }
    },
    onError: error => {
        Message.error(error);
    }
  });

  const [getOcrGroupsOfFolderList, {loading: getOcrGroupsOfFolderListLoading}] = useLazyQuery(GET_OCR_GROUPS_OF_FOLDERS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const ocrUsersList =  get(data, 'getOcrGroupsOfFolders', '');
      if(ocrUsersList){
        setOcrGroupsOfFolders(ocrUsersList);
      }
    },
    onError: error => {
        Message.error(error);
    }
  });

  const handleRefetchedOrFilter = (type, data) => {
    if(type === 'stepsProcess'){
      getOcrProcessStepsList({variables: {organization: organizationId}});
    } else if(type === 'groupsOfFolder'){
      getOcrGroupsOfFolderList({variables: {folder_id: data}})
    }
  };

  useEffect(() => {
    if(organizationId){
      getOcrProcessStepsList({variables: {organization: organizationId}});
    }
  }, [organizationId]);

    return (
      <OcrContext.Provider
        value={{
          organizationId,
          ocrProcessStepsData,
          ocrProcessStepsLoading,
          handleRefetchedOrFilter,
          ocrUsersListLoading,
          allOcrUsersData:!ocrUsersListLoading && ocrUsersData? get(ocrUsersData, 'getOcrUsersList.data', []) : [],
          loadingOcrFolder,
          ocrFolderData: get(ocrFolderData, 'getOcrFolderList', []),
          refetchFolder,
          ocrProjectData: get(ocrProjectData, 'getOcrProjectList', []),
          ocrProjectDataLoading,
          refetchProjectData,
          ocrGroupsOfFolders,
          getOcrGroupsOfFolderListLoading
        }}
      >
        {children}
      </OcrContext.Provider>
    );
  }
  
  export default OcrProvider;