import React, { useState } from 'react';
import ChartContainer from '../.././../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../.././../../../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GenericTable from '../../../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { dateFormatterLLL } from '../../../../../../utils';
import CreateBidModal from './CreateBidModal';
import CompareIcon from '@material-ui/icons/Compare';
import CompareDocModal from '../Quotes/RefDocModal';
import SelectedCompareModal from '../Quotes/SelectedCompareModal';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 215,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }  
}));

const bidData = [
    {
        name: "Test Bid 1", 
        uploaded_on: dateFormatterLLL(new Date()), 
        status: "Pending", 
        due_date: dateFormatterLLL(new Date()),
        vendores_allowed: ""
    },
    {
        name: "Test Bid 2", 
        uploaded_on: dateFormatterLLL(new Date()), 
        status: "Open", 
        due_date: dateFormatterLLL(new Date()), 
        vendores_allowed: ""
    }
];

const Bids = ({ t }) => {
    const classes = useStyles();
    const [bidList, setBidList] = useState([]);
    const [openCreateBidModal, setOpenCreateBidModal] = useState(false);
    const [compareModelOpen, setCompareModelOpen] = useState(false);
    const [selectedCompareModelOpen, setSelectedCompareModelOpen] = useState(false);
    
    const handleCompareClick = () => {
        setCompareModelOpen(true);
    }

    const eventAlertTimelineFilter = [
        {
            label: "Search By Name",
            handleChange: (evt) => {},
            val: null,
            type: "text",
            styleOverrides: { marginRight: 24, marginTop: 10 },
            className: classes.dateInput
        },
        {
            label: t("startDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('status.label'),
            handleChange: (evt, newValue) => {
            },
            val: "",
            options: ["Pending", "Open", "Completed"],
            styleOverrides: { width: 180, marginTop: 10 }
        }
    ];
    const columns = [
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Uploaded On",
            key: "uploaded_on"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Due Date",
            key: "due_date"
        },
        {
            label: "Compare",
            key: "compare"
        },
        {
            label: "Action",
            key: "action"
        }
    ];

    React.useEffect(() => {
        setBidList(bidData.map(itm => {
            itm.action = <>
                <IconButton style={{marginRight: 10}} aria-label="print">
                    <PrintIcon color="primary" />
                </IconButton>
                <IconButton style={{marginRight: 10}} aria-label="edit">
                    <EditIcon color="primary" />
                </IconButton>
                <IconButton aria-label="delete">
                    <DeleteIcon color="secondary" />
                </IconButton>
            </>;
            itm.compare = <>
            <IconButton onClick={handleCompareClick} aria-label="delete">
                <CompareIcon color="primary" />
            </IconButton>
            </>;
            return itm;
        }));
    }, []);

    const handleCreateBidSubmit = () => {   
    }


    return (
        <div style={{width: "100%", display: "inline-block"}}>
            <ChartContainer
                customMargin={{marginTop: 0}}
                filterList={eventAlertTimelineFilter}
                filterSubmit={evt => {
                    console.log("filter");
                }}
                createNewButton={evt => {
                    console.log("create New");
                    setOpenCreateBidModal(true);
                }}
                createLabel="Create New Bid">
            </ChartContainer>
            <Grid
            container
            style={marginGenerator('mt-25')}>
                <GenericTable list={bidList} columns={columns} />
            </Grid>
            <CreateBidModal 
                open={openCreateBidModal}
                handleClose={evt => {
                    setOpenCreateBidModal(false);
                }}
                handleCreateBidSubmit={handleCreateBidSubmit}
            />
            <CompareDocModal 
                open={compareModelOpen}
                handleClose={evt => {
                    setCompareModelOpen(false);
                }}
                handleOpenCompareModal={evt => {
                  setSelectedCompareModelOpen(true);
                  setCompareModelOpen(false);
                }}
            />
            <SelectedCompareModal 
                open={selectedCompareModelOpen}
                handleClose={evt => {
                    setSelectedCompareModelOpen(false);
                }}
            />
        </div>
    );
};

Bids.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default Bids;
