import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import commonStyles from '../../../../../theme/commonStyles';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function NigeriaFormsUpdateDetailModal({
    t,
    open,
    handleClose,
    handleUpdateRelatedFormClick,
    data,
    selectedProjectTypeAndUid,
    modalState
}) {
    const classes = useStyles();
    React.useEffect(() => { }, [open, data]);
    const commonClasses = commonStyles();
    const columns = [
        {
            label: t('name.label'),
            key: "name"
        },
        {
            label: t('details.label'),
            key: "details"
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('ViewCompleteDetails.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item, index) => (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data ?
                                    <React.Fragment>
                                        {(data.install_team_lead_section && data.install_team_lead_section.length) &&
                                            <React.Fragment>
                                                {data.install_team_lead_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('installTeamSection.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(1, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.install_team_section && data.install_team_section.length) &&
                                            <React.Fragment>
                                                {data.install_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('installTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(2, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.im_team_section && data.im_team_section.length) &&
                                            <React.Fragment>
                                                {data.im_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('imTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(3, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.change_control_team_section && data.change_control_team_section.length) &&
                                            <React.Fragment>
                                                {data.change_control_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('changeControlTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(4, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.integration_engineer_team_section && data.integration_engineer_team_section.length) &&
                                            <React.Fragment>
                                                {data.integration_engineer_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('integrationEngineer.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(5, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.quality_assurance_section && data.quality_assurance_section.length) &&
                                            <React.Fragment>
                                                {data.quality_assurance_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('qualityAssuarance.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(6, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.pm_section && data.pm_section.length) &&
                                            <React.Fragment>
                                                {data.pm_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('projectManagementTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(7, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.finance_section && data.finance_section.length) &&
                                            <React.Fragment>
                                                {data.finance_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('financeTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(8, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.csoc_team_section && data.csoc_team_section.length) &&
                                            <React.Fragment>
                                                {data.csoc_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('csocTeam.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(9, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                          {(data.outside_project_integration && data.outside_project_integration.length) &&
                                            <React.Fragment>
                                                {data.outside_project_integration.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('projectManagementTeamSection.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(10, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                        {(data.radio_engineering_team_section && data.radio_engineering_team_section.length) &&
                                            <React.Fragment>
                                                {data.radio_engineering_team_section.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                        {t('RadioTeamEngineeringSection.label')} {index+1}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Button variant={'outlined'}
                                                                        // disabled={(item.value == '' || item.value == null) ? true : false}
                                                                        color={'primary'}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            handleUpdateRelatedFormClick(11, item)
                                                                        }}>{t('update.label')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        }
                                    </React.Fragment> :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

NigeriaFormsUpdateDetailModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(NigeriaFormsUpdateDetailModal);
