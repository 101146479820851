import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import VideoPanelItem from './VideoPanelItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import RoiModal from '../EdgeAIRoiModal';
import CreateVideoTemplateModal from './CreateVideoTemplateModal';
import UpdateVideoModal from './UpdateVideoModal';
import { GET_VIDEOS_LIST } from '../../queries';
import { CREATE_VIDEO } from '../../mutations';
import { convertLocalToUTCDate } from '../../../../utils';
import Message from '../../../../components/Message';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function EdgeAIVideoPanel({
    t,
    subscriptionData,
    allowConfigure,
    placeList,
    mlModelList,
    notificationTemplates
}) {

    const menuItems = [
        {label: t('edit.label')},
        {label: t('delete.label')}
    ];

    const classes = useStyles();
    const [roiModalState, setRoiModalState] = React.useState(false);
    const [roiImgSrc, setRoiImgSrc] = React.useState("");
    const [createVideoTemplateModal, setCreateVideoModalState] = useState(
        false
    );
    const [videoModalState, setVideoModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [videoList, setVideoList] = useState([]);
    const [videoCount, setVideoCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [placeId, setPlaceId] = useState("");
    const [templateId, setTemplateId] = useState(null);
    const [searchKey, setSearchKey] = useState("");
    const [updateVideoData, setUpdateVideoData] = useState(null);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [selectedVideosRow, setSelectedVideosRow] = useState({});
    const [flierStatusValue, setFlierSluetatusValue] = useState('');
    const [goToPage, setGoToPage] = useState(null);

    const filterByStatusoption = [
        {id: 'detected', name: 'Detected'},
        {id: 'queued', name: 'Queued'},
        {id: 'processed', name: 'Processed'}
    ];

    let columns = [
        {
            label: t('video.label') + ' ' + t('name.label'),
            key: "name"
        }, {
            label: t('places.label'),
            key: "place"
        },
        {
            label: t('onboardBy.label'),
            key: "onboardBy"
        },
        {
            label: t('onboardDate.label'),
            key: "onboardDate"
        },
        {
            label: t('attachedModels.label'),
            key: "attachedModels"
        },
        {
            label: t('videoStatus.label'),
            key: "videoStatus"
        }
        // {
        //     label: t('roi.label'),
        //     key: "roi"
        // }
    ];
    if (allowConfigure) {
        columns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
    }

    const filterList = [{
        label: 'Filter By Place',
        handleChange: evt => {
            setPlaceId(evt.target.value);
            setLoading(true);
            setPage(1);
            const place_id = (evt.target.value) ? evt.target.value.place_id : '';
            return getAllVideosList({variables: {placeId: place_id, organization: subscriptionData.organizationId, templateId: templateId, page: 1, search: searchKey, status: flierStatusValue}});
        },
        val: placeId,
        styleOverrides: { minWidth: '172px' },
        options: placeList
    },
    {
        label: 'Filter By Model',
        handleChange: evt => {
            setLoading(true);
            setTemplateId(evt.target.value ? evt.target.value : null);
            setPage(1);
            return getAllVideosList({variables: {placeId: placeId ? placeId.place_id : "", organization: subscriptionData.organizationId, templateId: evt.target.value, page: 1, search: searchKey, status: flierStatusValue}});
        },
        val: templateId,
        styleOverrides: { minWidth: '172px' },
        options: mlModelList
    },
    {
        label: 'Filter By Status',
        handleChange: evt => {
            setLoading(true);
            setFlierSluetatusValue(evt.target.value);
            setPage(1);
            return getAllVideosList({variables: {placeId: placeId ? placeId.place_id : "", organization: subscriptionData.organizationId, templateId: templateId, page: 1, search: searchKey, status: evt.target.value}});
        },
        val: flierStatusValue,
        styleOverrides: { minWidth: '172px' },
        options: filterByStatusoption
    }];
    
    const [getAllVideosList] = useLazyQuery(GET_VIDEOS_LIST, {
        fetchPolicy: "no-cache",
        onError: error => {
            setLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            setLoading(false);
            if(data.getAllVideosList){
                let videos = data.getAllVideosList.videos ? data.getAllVideosList.videos.filter(itm => {
                    itm.attachedModel = itm.config && typeof itm.config === 'object' ? Object.keys(itm.config).length  : 0;
                    let placeData = placeList.filter(plc => (plc.place_id == itm.place_id));
                    if(placeData.length){
                        itm.place = placeData[0];
                    }
                    return itm;
                }) : [];
                setVideoList(videos);
                setVideoCount(data.getAllVideosList.count ? data.getAllVideosList.count : 0);
            }
        }
    });

    const [createVideo] = useMutation(CREATE_VIDEO, {
        onError: error => {
            setVideoModalState({btnLoading: false, clearData: true});
            Message.error(error);
            setCreateVideoModalState(false);
        },
        onCompleted: data => {
            if (data.addVideo && data.addVideo.success) {
                setVideoModalState({btnLoading: false, clearData: true});
                Message.success('Video configuration created successfully.');
                return getAllVideosList({variables: {placeId: "", organization: subscriptionData.organizationId, templateId: null, page: 1, status: flierStatusValue}});
            } else {
                setVideoModalState({btnLoading: false, clearData: false});
                Message.error(data.addVideo && data.addVideo.message ? data.addVideo.message : "Something went wrong!");
            }
            setCreateVideoModalState(false);
            
        }
    });

    useEffect(() => {
        setLoading(true);
        return getAllVideosList({variables: {placeId: "", organization: subscriptionData.organizationId, templateId: null, page: 1, status: ''}});
    }, []);

    const handleSelectVideoRow = (evnt, index) => {
        setSelectedVideosRow({...selectedVideosRow, [index]: evnt.target.checked});
    }

    const handleDeleteMultipleVideo = () => {
        const videoIds = [];
        for(let key in selectedVideosRow){
          if(selectedVideosRow[key])
            videoIds.push(key);
        }
        if(videoIds.length){
          if(window.confirm('Are you sure? You want to delete selected videos!') === false){
            return false;
          }
        //   return deleteMultipleVideos({
        //     variables: {
        //       ids: videoIds
        //     }
        //   });
        }else{
          return false;
        }
    };

    const handleCreateButtonClick = () => {
        setVideoModalState({ btnLoading: false, clearData: false });
        setUpdateVideoData(null);
        setOpenUpdateModal(false);
        setCreateVideoModalState(true);
    }

    const handleSubmitLoadingVideo = (loadState) => {
        setVideoModalState(loadState);
    }

    const handleCreateVideoSubmitClick = formData => {
        console.log("formData: ", formData);
        if(!formData){
            return null;
        }
        let tempObj = {};
        let video = formData.video;
        tempObj.organization = subscriptionData.organizationId;
        tempObj.name = video.name;
        tempObj.description = video.description;
        tempObj.place = video.place ? video.place.place_id : "";
        tempObj.url = video.url ? video.url : "";
        tempObj.fps = video.fps;
        tempObj.configTemplate = [];
        if (formData.aiModels.length > 0 && formData.aiModels[0].ai_template_id) {
            for (let j = 0; j < formData.aiModels.length; j++) {
                let tmpAiModal = {};
                let aiMdl = formData.aiModels[j];
                tmpAiModal.startTime = aiMdl.from_time ? parseInt(aiMdl.from_time)*60 : 0;
                tmpAiModal.endTime = aiMdl.to_time ? parseInt(aiMdl.to_time)*60 : 0;
                tmpAiModal.startDate = aiMdl.start_date ? convertLocalToUTCDate(aiMdl.start_date) : "";
                tmpAiModal.templateId = aiMdl.ai_template_id;
                tmpAiModal.notificationTemplateId = [];
                if (
                    formData.notifications.length > 0 &&
                    formData.notifications[0].notification_template_id
                ) {
                    for (let k = 0; k < formData.notifications.length; k++) {
                        if (
                            formData.notifications[k].ai_template_id ==
                            aiMdl.ai_template_id
                        ) {
                            tmpAiModal.notificationTemplateId.push(
                                formData.notifications[k].notification_template_id
                            );
                        }
                    }
                }
                tempObj.configTemplate.push(tmpAiModal);
            }
        }
        return createVideo({variables: {videoData: tempObj}});
    }

    const handleSearchChange = (val) => {
        setLoading(true);
        setSearchKey(val);
        return getAllVideosList({variables: {placeId: placeId ? placeId.place_id : "", organization: subscriptionData.organizationId, templateId: templateId, page: 1, search: val, status: flierStatusValue}});
    }

    const handleAddRoi = (cam) => {
        let imgurl = "https://inq-ai-notify.s3.af-south-1.amazonaws.com/multi-object/1601911924354324/1623750513.1383665.jpg";
        setRoiImgSrc(imgurl);
        setRoiModalState(true);
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
        setLoading(true);
        return getAllVideosList({variables: {placeId: placeId ? placeId.place_id : "", organization: subscriptionData.organizationId, templateId: templateId, page: newPage, search: searchKey, status: flierStatusValue}});
    }

    const handleMenuItemClick = (elem, data, type) => {
        if(!data){
          return false;
        }
        if (elem.label === t('edit.label')) {
            
        } else if (elem.label === t('delete.label')) {
        
        }
    }
    
    return (
        <div>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                {filterList && filterList.map((filter, index) => (
                    <Grid item xs="2">
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent || null}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    </Grid>
                ))}
                <Grid item xs="3">
                    <SearchBox handleSearch={handleSearchChange} />
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleCreateButtonClick}>
                            {t('createNow.label')}
                        </Button>
                        {/* <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={handleDeleteMultipleVideo}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                        >
                            {t('delete.label')}
                        </Button> */}
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'} style={{textAlign: "center"}}><CircularProgress size={25}/></Typography>
                                    </TableCell>
                                </TableRow>
                            :
                                <>
                                    {videoList.length > 0 ? videoList.map((rowitem, rowIndex) => (
                                        <VideoPanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                            handleSelectVideoRow={handleSelectVideoRow}
                                            checkedVideosRow={selectedVideosRow}
                                            allowConfigure={allowConfigure}
                                            handleAddRoi={handleAddRoi}
                                        />
                                    )) :
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                    {!loading &&
                        <>
                            <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                                <Grid item xs={2}>
                                    <span>Total {Math.ceil(videoCount/20)} page,  {videoCount} Row</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Pagination 
                                        color="secondary"
                                        count={videoCount ? Math.ceil(videoCount / 20) : 0} 
                                        page={page} 
                                        onChange={(evt, value) => {
                                            handlePageChange(value);
                                        }} 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <span>Go to page </span>
                                </Grid>    
                                <Grid item xs={1}>
                                    <Input
                                        value={goToPage}
                                        type={'text'}
                                        style={{ minWidth: 100 }}
                                        color={'secondary'}
                                        onChange={evt => {
                                            let maxRecord = parseInt(Math.ceil(videoCount/20));
                                            if(parseInt(evt.target.value) > maxRecord){
                                                return null;
                                            }
                                            setGoToPage(evt.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        onClick={()=> handlePageChange(parseInt(goToPage))}
                                        variant={'outlined'}
                                        color="primary"
                                        disabled={!goToPage}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Go
                                    </Button>      
                                </Grid>
                            </Grid>
                        </>
                    }
                </TableContainer>
            </Grid>
            {roiModalState && <RoiModal imageSrc={roiImgSrc} open={roiModalState} handleClose={evt => {
                setRoiModalState(false);
            }}/>}
            <CreateVideoTemplateModal
                t={t}
                open={createVideoTemplateModal}
                handleCreateVideoSubmitClick={handleCreateVideoSubmitClick}
                handleSubmitLoadingVideo={handleSubmitLoadingVideo}
                subscriptionData={subscriptionData}
                selectePlaceList={placeList}
                selectMlTemplateList={mlModelList}
                selectNotiTemplateList={notificationTemplates}
                handleClose={() => {
                    setCreateVideoModalState(false);
                    setVideoModalState({ clearData: false, btnLoading: false });
                }}
                modalState={videoModalState}
                videoFpsOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            />

            {updateVideoData && 
                <UpdateVideoModal
                    t={t}
                    open={openUpdateModal}
                    data={updateVideoData}
                    handleCreateVideoSubmitClick={handleCreateVideoSubmitClick}
                    handleSubmitLoadingVideo={handleSubmitLoadingVideo}
                    subscriptionData={subscriptionData}
                    selectePlaceList={placeList}
                    selectMlTemplateList={mlModelList}
                    selectNotiTemplateList={notificationTemplates}
                    handleClose={() => {
                        setOpenUpdateModal(false);
                        setVideoModalState({ clearData: false, btnLoading: false });
                    }}
                    modalState={videoModalState}
                    videoFpsOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                />
            }
        </div>
    );
}

EdgeAIVideoPanel.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};
export default EdgeAIVideoPanel;
