import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProcessWorkFlow from './components/ProcessWorkFlow';
import SmtpSetting from './components/SmtpSetting'
import CustomTabs from '../../../../components/CustomTabs';
import { GET_EDGE_AI_ORG_DETAIL, GET_PROCESS_WORKFLOW_STEPS } from '../../queries';
import { USER_ROLES } from '../../../../utils/constants';
import { CREATE_PROCESS_WORKFLOW_STEPS, DELETE_PROCESS_WORKFLOW_STEPS, CREATE_SMTP_DATA } from '../../mutations';
import Message from '../../../../components/Message';
import CreateProcessStepsModal from './components/CreateProcessStepsModal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    buttonDiv: {
        textAlign: "end",
        marginBottom: "10px"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            // style={{ position: "absolute", right: 0, top: 0, cursor: "pointer", zIndex: 999 }}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('terminateSubscription.label')}
        </Button>
    );
}

const EdgeAISettingsPanel = ({ t,
    data,
    handleDeleteSubscription,
    loading,
    allowConfigure,
    subActiveIndex,
    selectedPath
}) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const org_type = localStorage.getItem("org_type");
    const [smtpLoading, setSmtpLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [openProcessStepModal, setOpenProcessStepModal] = useState(false);
    const [processModalState, setProcessModalState] = useState({ loading: false, clearData: false });
    const [editProcessData, setEditProcessData] = useState(null);

    const {
        data: processStepsList,
        loading: processDataLoading,
        refetch: reloadProcessData
    } = useQuery(GET_PROCESS_WORKFLOW_STEPS, {
        variables: {
            subscriptionId: data.id
        },
        onCompleted: (res) => {
            console.log("res: ", res);
        }
    }
    );

    const {
        data: orgDetails,
        loading: orgDataLoading,
        refetch: reloadOrgDetails
    } = useQuery(GET_EDGE_AI_ORG_DETAIL, {
        fetchPolicy: "no-cache",
        variables: {
            id: data.organizationId
        }
    }
    );

    const [addProcessWorkFlow] = useMutation(CREATE_PROCESS_WORKFLOW_STEPS, {
        onError: error => {
            setProcessModalState({ clearData: false, loading: false });
            Message.error("Something went wrong!");
        },
        onCompleted: data => {
            Message.success('Process workflow steps created successfully.');
            setOpenProcessStepModal(false);
            setProcessModalState({ clearData: true, loading: false });
            setEditProcessData(null);
            reloadProcessData();
        }
    });

    const [addSmtpData] = useMutation(CREATE_SMTP_DATA, {
        onError: error => {
            setSmtpLoading(false);
            Message.error(error);
        },
        onCompleted: data => {
            setSmtpLoading(false);
            reloadOrgDetails();
            Message.success('SMTP Data created successfully.');
        }
    });

    const [deleteProcessWorkFlow] = useMutation(DELETE_PROCESS_WORKFLOW_STEPS, {
        onError: error => {
            Message.error("Something went wrong!");
        },
        onCompleted: data => {
            Message.success('Process workflow step deleted successfully.');
            reloadProcessData();
        }
    });

    const handleCreateProcessStepSubmit = (stepData) => {
        if (!stepData) {
            return null;
        }
        stepData = stepData.map(itm => {
            itm.subscription = data.id;
            return itm;
        });
        setProcessModalState({ loading: true, clearData: false });
        return addProcessWorkFlow({
            variables: {
                processSteps: stepData,
                id: editProcessData ? editProcessData.id : ""
            }
        });
    }

    const handleDeleteItem = (row) => {
        if (row && window.confirm("Are you sure? you want to delete this step.")) {
            return deleteProcessWorkFlow({
                variables: {
                    id: row.id
                }
            });
        }
        return null;
    }

    const handleProcessItemClick = (row, type) => {
        if (type === "delete") {
            handleDeleteItem(row);
        } else {
            setEditProcessData(row);
            setOpenProcessStepModal(true);
        }
    }

    const handleUpdateSmtpSubmit = (formData) => {
        setSmtpLoading(true)
        return addSmtpData({
            variables: {
                orgId: data.organizationId,
                mailServer: formData.mailServer,
                from: formData.from,
                userName: formData.userName,
                password: formData.password,
                port: formData.port
            }
        })
    }

    const configTabs = [
        {
            label: t('processWorkFlow.label'),
            children: (
                <>
                    <ProcessWorkFlow
                        handleCreateProcessStep={evt => {
                            setOpenProcessStepModal(true);
                            setEditProcessData(null);
                        }}
                        t={t}
                        list={(!processDataLoading && processStepsList && processStepsList.getProcessWorkFlowSteps) ? processStepsList.getProcessWorkFlowSteps : []}
                        loading={processDataLoading}
                        handleItemClick={handleProcessItemClick}
                    />
                </>
            )
        }
    ];

    if ((role.includes(USER_ROLES.EP_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) &&
        org_type && org_type !== null && org_type === 'partner'
    ) {
        configTabs.push({
            label: t('smtpSetting.label'),
            children: (
                <>
                    <SmtpSetting
                        t={t}
                        handleSubmit={handleUpdateSmtpSubmit}
                        loading={smtpLoading}
                        orgDetails={orgDetails ? orgDetails.getEdgeAIOrganizationDetails : null}
                        dataLoading={orgDataLoading}
                    />
                </>
            )
        });
    }

    React.useEffect(() => {
        if (orgDetails) {
            localStorage.setItem('timeZoneDetails', orgDetails?.getEdgeAIOrganizationDetails?.timezone)
        }
    }, [orgDetails]);

    return (
        <div style={{ position: "relative" }}>
            {allowConfigure &&
                <div className={classes.buttonDiv}>
                    <ButtonComponent
                        t={t}
                        onClick={handleDeleteSubscription}
                        loading={loading}
                    />
                </div>
            }
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            /> */}
            {selectedPath === "Settings" && <> {configTabs[subActiveIndex].children}</>}
            <CreateProcessStepsModal
                open={openProcessStepModal}
                handleClose={evt => {
                    setProcessModalState({ clearData: true, loading: false });
                    setOpenProcessStepModal(false);
                    setEditProcessData(null);
                }}
                modalState={processModalState}
                processData={editProcessData}
                handleCreateProcessStepSubmit={handleCreateProcessStepSubmit}
            />
        </div>
    );
};

EdgeAISettingsPanel.propTypes = {
    t: PropTypes.func.isRequired,
    handleDeleteSubscription: PropTypes.func.isRequired
};
export default EdgeAISettingsPanel;
