import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PaymentRequisitionPanelItem from './PaymentRequisitionPanelItem';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentRequisitionFormModal from './PaymentRequisitionFormModal';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import { GET_PAYMENT_REQUISITION } from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_PAYMENT_REQUISITION, DELETE_PAYMENT_REQUISITION } from '../../mutation';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function PaymentRequisitionForm({
    t,
    allowConfigure,
    data,
    menuItems,
    users,
    departments
}) {
    const classes = useStyles();
    const subscriptionId = data.id;

    const [filterStateData, setFilterStateData] = useState({...initFilterState});
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedPaymentRequisitionItem, setSelectedPaymentRequisitionItem] = useState(null);
    const [PaymentRequisitionFormModalOpen, setPaymentRequisitionFormModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({signature: "", signature2: "", paymentSignature: ""});


    const [getAllPaymentRequisitions, {
          loading,
          data: paymentRequisitionList
      }] = useLazyQuery(GET_PAYMENT_REQUISITION, {
          fetchPolicy: "no-cache"
      });

      useEffect(() => {
          return getAllPaymentRequisitions({variables: {...filterStateData, subscriptionId}});
      }, []);

      useEffect(() => {
          if(filterStateData.startDate && filterStateData.endDate){
          getAllPaymentRequisitions({variables: {...filterStateData, subscriptionId}});
      } else{
          getAllPaymentRequisitions({variables: {...filterStateData, subscriptionId}});
        }
          }, [filterStateData, subscriptionId]);

      const [createPaymentRequisition, { loading: createRequestLoading }] = useMutation(CREATE_PAYMENT_REQUISITION, {
          onError: error => {
              Message.error(error);
          },
          onCompleted: () => {
              Message.success('Payment request created successfully');
              setPaymentRequisitionFormModalOpen(false);
              setCRModalState({clearData: true, btnLoading: false});
              setSelectedPaymentRequisitionItem(null);
              setSignatureType("");
              return getAllPaymentRequisitions({variables: {...filterStateData, subscriptionId}});
          }
      });

      const [deletePaymentRequisition, { loading: deletePaymentRequisitionLoading }] = useMutation(DELETE_PAYMENT_REQUISITION, {
          onError: error => {
              Message.error(error);
          },
          onCompleted: () => {
              Message.success('Payment request deleted successfully');
              return getAllPaymentRequisitions({variables: {...filterStateData, subscriptionId}});
          }
      });

      const handleCreatePaymentRequestSubmit = (formData) => {
          formData.subscriptionId = subscriptionId;
          return createPaymentRequisition({ variables: formData });
      }

    const columns = [

        {
            label: 'Payee Name',
            key: "payeeName"
        },
        {
            label: "Payment Purpose",
            key: "paymentPurpose"
        },
        {
            label: 'Amount',
            key: "amount"
        },
        // {
        //     label: 'Date Requested',
        //     key: "requisitionerDate"
        // },
        // ,
        // {
        //     label: 'Date Authorised',
        //     key: "authoriserDate"
        // },
        {
            label: 'Cost Code',
            key: "costCode"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const handleNewPaymentRequest = () => {
        setPaymentRequisitionFormModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            value: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        }
    ];


    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedPaymentRequisitionItem(rowItem);
        if(type === "sign"){
            setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setPaymentRequisitionFormModalOpen(true)
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this request?') ===
                false
            ) {
                return false;
            }
            return deletePaymentRequisition({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    return (
        <div style={{ marginTop: 45 }}>
            {console.log("PaymentRequisitionFormModalOpen === ", PaymentRequisitionFormModalOpen)}
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewPaymentRequest}
                        >
                            {"Add Request"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                        <TableCell key={index} style={item.style ? item.style : null} >
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                      { console.log("paymentRequisitionList == ", paymentRequisitionList) }
                      {!loading ? paymentRequisitionList && paymentRequisitionList.getAllPaymentRequisitions && paymentRequisitionList.getAllPaymentRequisitions.data && paymentRequisitionList.getAllPaymentRequisitions.data.length > 0 ?
                              paymentRequisitionList.getAllPaymentRequisitions.data.map((rowitem, rowIndex) => (
                          <PaymentRequisitionPanelItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    menuItems={menuItems}
                                    handleMenuItemClick={handleMenuItemClick}
                                    allowConfigure={allowConfigure}
                                  />
                              )) :
                              <TableRow>
                                  <TableCell colSpan={columns.length}>
                                      <Typography variant={'subtitle2'}>Record not found!</Typography>
                                  </TableCell>
                              </TableRow>
                              : null
                          }
                          {loading &&
                              <TableRow>
                                  <TableCell colSpan={columns.length}>
                                      <div style={{textAlign: "center", width: "100%"}}>
                                          <CircularProgress size={20} color="primary" />
                                      </div>
                                  </TableCell>
                              </TableRow>
                          }
                </TableBody>
                </Table>
            </TableContainer>
          </Grid>

            <PaymentRequisitionFormModal
                open={PaymentRequisitionFormModalOpen}
                handleClose={() => {
                    setPaymentRequisitionFormModalOpen(false);
                    setSelectedPaymentRequisitionItem(null);
                }}
                modalState={{...crModalState, btnLoading: createRequestLoading}}
                users={users}
                data={selectedPaymentRequisitionItem}
                signatures={signatureState}
                handleOpenSignPanModal={handleOpenSignPanModal}
                handleCreatePaymentRequestSubmit={handleCreatePaymentRequestSubmit}
            />

            <CanvasSignaturePadModal
                open={signaturePadModalOpen}
                handleClose={() => setSignaturePadModalOpen(false)}
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    );
  }

PaymentRequisitionForm.propTypes = {
    t: PropTypes.func.isRequired,
    //list: PropTypes.object,
    //menuItems: PropTypes.array.isRequired,
    //handleMenuItemClick: PropTypes.func.isRequired
};
export default PaymentRequisitionForm;
