import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { Image } from '@react-pdf/renderer';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
  blackBorder: {
      border: '1px solid black',
      outline: '1px solid',
      padding: '0px !important',
  },
  blackBorderTitle:{
      margin: '18px 9px',
  },
  paper: {
      padding: '15px',
      marginBottom: '50px'
  },
  icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  },
  root: {
      flexGrow: 1,
  },

  input: {
      minWidth: '224'
  },
  iconButton: {
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(1.25, 3),
      borderRadius: '24px',
      marginTop: theme.spacing(2),
      border: "1px solid #b8b8b8"
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = {
  id: '',
  userFullName: '',
  userDepartment: '',
  userDesignation: '',
  userSection: '',
  userCellular: '',
  employeeNumber: '',
  accountType: '',
  employeeType: '',
  expiryDate: null,
  requiredAccess: [],
  recommendation: '',
  recommendationSign: '',
  authorisationSign: '',
  authorisation: '',
  recomendDate: null,
  authDate: null,
  userDeclareDate: null,
  iamUser: '',
  iamUserSign: '',
  iamUserDate: null,
  userIdCreated: ''
};

const requiredAccess = [
  'Syspro',
  'Email',
  'Resource Link Core',
  'On-Key',
  'Internet',
  'Mobile Email',
  'IsoMetrix',
  'RTO',
  'Time & Attendance'
];

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, 'pot', { type: contentType });
}
function convertBase64ToUrl(imgStr) {
  imgStr = imgStr.replace('data:image/png;base64,', '');
  let enc = window.atob(imgStr);
  let image = new File([enc], `${new Date().getTime()}.jpg`, {
    type: 'image/jpeg'
  });
  let file = b64toBlob(imgStr, 'image/jpeg');
  let imgURL = URL.createObjectURL(file);
  // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
  return imgURL;
}
function UserRequestModal({
  t,
  open,
  data,
  users,
  departments,
  userdata,
  handleClose,
  modalState,
  handleCreateRequestSubmit,
  handleOpenSignPanModal,
  signatures
}) {
  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const [isFormElementShown, setIsFormElementShown] = useState(true);
  const [userDeclaration, setUserDeclaration] = useState(false);
  const [departmentSections, setDepartmentSections] = useState([]);
  const [showUserSelect, setShowUserSelect] = useState(false);

  React.useEffect(() => {
    if (modalState.clearData && open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [modalState]);

  React.useEffect(() => {
    if (data && data.id) {
      const userDepartment = data.userDepartment && data.userDepartment.id;
      const userSection = data.userSection && data.userSection.id;
      setShowUserSelect(data.createdById === userdata.id ? true : false);
      setURFormState({ ...data, userDepartment, userSection });
    } else {
      setShowUserSelect(true);
    }
  }, [data]);

  const handleChange = (value, name) => {
    if (name === 'requiredAccess') {
      setURFormState(prevState => {
        const newState = { ...prevState };
        if (value.target.checked) {
          newState.requiredAccess.push(value.target.value);
        } else {
          let index = newState.requiredAccess.indexOf(value.target.value);
          if (index !== -1) {
            newState.requiredAccess.splice(index, 1);
          }
        }
        return newState;
      });
    } else {
      setURFormState({ ...urFormState, [name]: value });
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        <HrHeader />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">REQUEST TO ADD/AMEND USER ID</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">USER DETAILS</Typography>
          </Grid>
          <Grid item xs={2}>
               <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>
                  Full Name:</h5>
            </Grid>
            <Grid item xs={4}>
              <Input
                type={'text'}
                style={{ width: '100%', marginTop: 5}}
                className={classes.input}
                color={'secondary'}
                value={urFormState.userFullName}
                onChange={(evt) => handleChange(evt.target.value, "userFullName")}
              />
          </Grid>
    
           <Grid item xs={2}>
                  <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>
                       Designation:</h5>
           </Grid>
           <Grid item xs={4}>
               <Input
                   type={'text'}
                   style={{ width: '100%', marginTop: 5}}
                   className={classes.input}
                    color={'secondary'}
                    value={urFormState.userDesignation}
                    onChange={(evt) => handleChange(evt.target.value, "userDesignation")}
               />
            </Grid>

            <Grid item xs={2}>
                  <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600, marginLeft: -10 }}>
                     Department:</h5>
            </Grid>
            <Grid item xs={4}>
                <OutlinedSelect
                  options={departments && departments.length ? departments : []}
                  label="Department"
                  val={urFormState.userDepartment}
                  handleChange={evt => {
                    const item = departments.find(
                      itm => evt.target.value === itm.id
                    );
                    setDepartmentSections(item ? item.sections : []);
                    handleChange(evt.target.value, 'userDepartment');
                  }}
                  styleOverrides={{ minWidth: '100%', marginRight: 2, marginTop: 20  }}
                />
            </Grid>
            
            <Grid item xs={2}>
                  <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>
                     Section:</h5>
            </Grid>
            <Grid item xs={4}>
                <OutlinedSelect
                  options={
                    departmentSections && departmentSections.length
                      ? departmentSections
                      : []
                  }
                  label="Section"
                  styleOverrides={{ minWidth: '100%', marginTop: 20 }}
                  val={urFormState.userSection}
                  handleChange={evt => {
                    handleChange(evt.target.value, 'userSection');
                  }}
                />
          </Grid>

          <Grid item xs={2}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>Employee Number:</h5>
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 2}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.userEmployeeNumber}
                                onChange={(evt) => handleChange(evt.target.value, "userEmployeeNumber")}
                            />
                        </Grid>
      
          <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>Cellular:</h5>
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                                type={'text'}
                                style={{ width: '100%', marginTop: 2}}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.userCellular}
                                onChange={(evt) => handleChange(evt.target.value, "userCellular")}
                            />
                        </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">ACCOUNT TYPE:</Typography>
          </Grid>

          <Grid item xs={3}>
            <RadioGroup
              row
              aria-label="account_type"
              name="account_type"
              value={urFormState.accountType}
              onChange={evt => {
                handleChange(evt.target.value, 'accountType');
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Create"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                labelPlacement="start"
                label="Create"
              />

              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Amend"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                label="Amend"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              row
              aria-label="employee_type"
              value={urFormState.employeeType}
              name="employee_type"
              onChange={evt => {
                handleChange(evt.target.value, 'employeeType');
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Permanent"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                label="Permanent Employee"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Temporary"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                label="Temporary Employee"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={1}>
                <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>Expiry Date:</h5>
          </Grid>
          <Grid item xs={3}>
            <DateTimeSelector
              inputStyle={{ minWidth: 280, marginTop:10 }}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.expiryDate}
              handleChange={newDate => handleChange(newDate, 'expiryDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">REQUIRED ACCESS:</Typography>
          </Grid>
        <Grid item xs={12}>
            <FormGroup row>
              {requiredAccess.map(itm => (
                <Grid item xs={3} key={itm}>
                  <FormControlLabel
                    style={{ ...marginGenerator('mb-8') }}
                    value={itm}
                    checked={
                      urFormState.requiredAccess
                        ? urFormState.requiredAccess.includes(itm)
                        : false
                    }
                    control={
                      <Checkbox
                        style={{ border: '0px', ...marginGenerator('mr-8') }}
                      />
                    }
                    labelPlacement="start"
                    onChange={evt => {
                      handleChange(evt, 'requiredAccess');
                    }}
                    label={itm}
                  />
          </Grid>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <FormControlLabel
            disabled={
              data &&
              (data.recommendation === userdata.id ||
                data.authorisation === userdata.id)
                ? true
                : false
            }
            control={
              <Checkbox
                onChange={() => setIsFormElementShown(!isFormElementShown)}
                checked={isFormElementShown}
                value={isFormElementShown}
              />
            }
            label="Show element"
          />
        </Grid>
        {isFormElementShown && (
          <>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              {data &&
              (showUserSelect || data.recommendation === userdata.id) ? (
                <Grid item xs={12}>
                  <Typography variant="h4">RECOMMENDATION:</Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h4">RECOMMENDATION:</Typography>
                </Grid>
              )}
              {showUserSelect && (
                <Grid item xs={3}>
                    <Grid item xs={1}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600}}>User:</h5>
                    </Grid>
                      <OutlinedSelect
                    options={users && users.length ? users : []}
                    label="User"
                    disabled={data && data.recommendationSign ? true : false}
                    val={urFormState.recommendation}
                    handleChange={evt =>
                      handleChange(evt.target.value, 'recommendation')
                    }
                    styleOverrides={{ minWidth: '100%', marginRight: 2, }}
                  />
             </Grid>

              )}
              {data && data.recommendation === userdata.id ? (
               
               <Grid xs={1} style={{marginTop:'100%'}}>
                  <IconButton 
                    variant={'outlined'}
                    onClick={() =>
                      handleOpenSignPanModal('recommendationSign')
                    }>
                    <img src={SignIcon} width="26" height={26} />
                  </IconButton>
                </Grid>
              ) : null}
              
              <Grid item xs={4}>
                <Grid item xs={3}>
                  <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>Date:</h5>
                </Grid>
                <DateTimeSelector
                  inputStyle={{ minWidth: 220,}}
                  dateFormat={'yyyy-MM-dd'}
                  value={urFormState.expiryDate}
                  handleChange={newDate =>
                    handleChange(newDate, 'recomendDate')
                  }
                />
              </Grid>
              <Grid item xs={4}>
                        <Button style={{marginTop: 62}}
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("userDeclareSign")}>
                            Signature <img style={{marginLeft: 5}} src={SignIcon} width="26" height={26}/> 
                        </Button>
                    </Grid>

              {((signatures && signatures.recommendationSign) ||
                (data && data.recommendationSign)) && (
                <Grid xs={1}>
                  <img
                    src={convertBase64ToUrl(
                      data && data.recommendationSign
                        ? data.recommendationSign
                        : signatures.recommendationSign
                    )}
                    width={60}
                    height={60}
                  />
                </Grid>
              )}
            </Grid>
            
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              {data &&
              (showUserSelect || data.authorisation === userdata.id) ? (
                <Grid item xs={12}>
                  <Typography variant="h4">AUTHORISATION:</Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h4">AUTHORISATION:</Typography>
                </Grid>
              )}
              {showUserSelect && (
                <Grid item xs={3}>
                   <Grid item xs={1}>
                <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>User:</h5>
          </Grid>
                  <OutlinedSelect
                    options={users && users.length ? users : []}
                    label="User"
                    disabled={data && data.authorisationSign ? true : false}
                    val={urFormState.authorisation}
                    handleChange={evt =>
                      handleChange(evt.target.value, 'authorisation')
                    }
                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                  />
                </Grid>
                
              )}
              {data && data.authorisation === userdata.id ? (
                <Grid xs={1}>
                  <IconButton
                    variant={'outlined'}
                    onClick={() => handleOpenSignPanModal('authorisationSign')}>
                    <img src={SignIcon} width="26" height={26} />
                  </IconButton>
                </Grid>
              ) : null}
             
              <Grid item xs={4}>
              <Grid item xs={2}>
                <h5 className={classes.blackBorderTitle} style={{ fontSize: 14, fontWeight: 600 }}>Date:</h5>
          </Grid>
                <DateTimeSelector
                  inputStyle={{ minWidth: 220 }}
                  dateFormat={'yyyy-MM-dd'}
                  value={urFormState.expiryDate}
                  handleChange={newDate => handleChange(newDate, 'authDate')}
                />
              </Grid>
              <Grid item xs={2}>
                        <Button style = {{marginTop:62}}
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("userDeclareSign")}>
                            Signature <img style={{marginLeft: 7}} src={SignIcon} width="26" height={26}/> 
                        </Button>
                    </Grid>
              
              {((signatures && signatures.authorisationSign) ||
                (data && data.authorisationSign)) && (
                <Grid xs={1}>
                  <img
                    src={convertBase64ToUrl(
                      data && data.authorisationSign
                        ? data.authorisationSign
                        : signatures.authorisationSign
                    )}
                    width={60}
                    height={60}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">USER DECLARATION:</Typography>
          </Grid>
          <Grid xs={12}>
            <Checkbox
              value={true}
              checked={userDeclaration}
              style={{ padding: 0 }}
              onChange={evt => setUserDeclaration(evt.target.checked)}
            />
            <Typography style={{ display: 'inline' }} variant="body1">
              I agree to make use of IT systems in accordance with the Morupule
              Coal Mine (MCM) IT User Policy. To review your obligation on this
              policy please refer to this document. MCM IT User policy on the
              File Server or ask any IT personnel to assist. To ensure that the
              system is not unlawful, unauthorized or improper purpose and
              comply with the policy, MCM may monitor, record, inspect and/or
              disclose any use of the system and/or files contained upon it as
              necessary. Any such use or non-compliance could result in
              disciplinary action and/or civil criminal penalties being taken
              against you.
            </Typography>
          </Grid>
        
          <Grid className={classes.blackBorder} container xs={6} style={{ marginTop: 20}}>
            <Grid item xs={6} style={{ textAlign: 'right'}}>
             <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("userDeclareSign")}
                >Signature<img src={SignIcon} width="26" height={26} alt=""/>
              </IconButton>
             </Grid>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6} style={{ marginTop: 20}}>
            <DateTimeSelector 
              inputStyle={{ minWidth: 90, }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.expiryDate}
              handleChange={newDate => handleChange(newDate, 'userDeclareDate')}
              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
          </Grid>
        </Grid>

        <FormGroup row>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid className={classes.blackBorderTitle} item xs={12}>
            <Typography variant="h4">
              INFORMATION MANAGEMENT OFFICIAL
            </Typography>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <OutlinedSelect
              options={users && users.length ? users : []}
              label="User"
              disabled={data && data.recommendationSign ? true : false}
              val={urFormState.recommendation}
              handleChange={evt => handleChange(evt.target.value, 'iamUser')}
              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
          </Grid>

          <Grid className={classes.blackBorder} container xs={6}>
            <Grid item xs={6} style={{ textAlign: 'right'}}>
             <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("iamUserSign")}
                >Signature<img src={SignIcon} width="26" height={26} alt=""/>
              </IconButton>
             </Grid>
          </Grid>
        
          <Grid className={classes.blackBorder} item xs={6}> 
            <DateTimeSelector 
              inputStyle={{ minWidth: 90, border:'0px', outline: 'none' }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.expiryDate}
              handleChange={newDate => handleChange(newDate, 'iamUserDate')}
              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
          </Grid>
          
          <Grid className={classes.blackBorder} item xs={6}>
            <Input 
              type={'text'}
              placeholder={'User ID Created'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.userFullName}
              onChange={evt => handleChange(evt.target.value, 'userIdCreated')}
            />
          </Grid>
        </Grid>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">MCM IT User Policy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * User accounts that have not been accessed for 30 days will be
              classified as inactive and will be disabled.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * Inactive accounts will be reviewed monthly or bi-monthly and
              formally identified as required by the IM Section.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * User accounts which have been disabled will be permanently
              deleted after 90 days if no notification is given to IM section
              that the account is still required.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * For any computer account for which a user has been issued a User
              ID, the user must not allow another person to share or use this
              user ID. The user will be required to create a password to protect
              access to the account.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * The password must comply with the password standard as defined
              in the Logical Access Control Policy (COM/IM/PO/004).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ marginLeft: 20 }}>
              * A password should not be easy to guess.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: 20 }}>
            <Typography variant="subtitle2">
              * Users are responsible for keeping their password secret.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: 20 }}>
            <Typography variant="subtitle2">
              * Users must not disclose their passwords either on purpose or
              accidentally to any other person for any reason.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: 20 }}>
            <Typography variant="subtitle2">
              * Users must never disclose their password over the telephone or
              by email.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * Company devices are issued for business purpose and remain the
              property of MCM.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              * When the device is allocated, the user assumes responsibility
              for the physical security of the equipment and the information
              contained therein.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              setURFormState({ ...urInitFields });
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              if (
                !urFormState.userFullName ||
                !urFormState.userDepartment ||
                !urFormState.userDesignation ||
                !urFormState.userDepartment ||
                !urFormState.employeeType
              ) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              if (!userDeclaration) {
                Message.error('Please Accept User Declaration!');
                return null;
              }
              return handleCreateRequestSubmit(urFormState);
            }}
            loading={modalState.btnLoading}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

UserRequestModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreateRequestSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(UserRequestModal);
