import React, { useState, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash-es';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import commonStyles from '../../../../theme/commonStyles';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import SearchBox from '../../../../components/SearchBox';

import { GET_CAMERA_LIST } from '../../queries';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContain: {
    width: '100%'
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px' },
  pre: { padding: '10px 30px', margin: '0' }
});

const AssignCameraForMLTemplate = ({
  t,
  data,
  isAttachedCameraEnable,
  handleClose,
  title,
  hadleUpdateCameraInMlTemp,
  handleCameraSearch,
  cameraList
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [checkCameras, setCheckCameras] = useState({});
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [masterStartTime, setMasterStartTime] = useState(null);
  const [masterEndTime, setMasterEndTime] = useState(null);
  const [isMasterTimeOptionEnable, setIsMasterTimeOptionEnable] = useState(
    true
  );
  const [filteredCameraList, setFilteredCameraList] = useState([]);

  const {
    loading: cameraLoading,
    data: allcameraList,
    error: cameraError
  } = useQuery(GET_CAMERA_LIST, {
    variables: {
      organization: data?.organization?.toString(),
      page: 1,
      limit: 1000
    }
  });

  useEffect(() => {
    setFilteredCameraList(allcameraList);
  }, []);

  useEffect(() => {
    setFilteredCameraList(cameraList);
  }, [cameraList]);

  const handleSubmitForBulkUpload = mlId => {
    const allPyaload = [];
    for (let key in checkCameras) {
      if (checkCameras[key])
        allPyaload.push({
          camera_id: key,
          startTime: checkCameras[key].startTime
            ? moment.utc(checkCameras[key].startTime).format('HH:mm')
            : null,
          endTime: checkCameras[key].endTime
            ? moment.utc(checkCameras[key].endTime).format('HH:mm')
            : null
        });
    }
    hadleUpdateCameraInMlTemp(mlId, allPyaload, handleClose, setCheckCameras);
  };

  const handleChangeCameraRow = (evt, id) => {
    if (evt.target.checked) {
      setCheckCameras({
        ...checkCameras,
        [id]: { startTime: null, endTime: null }
      });
    } else {
      setCheckCameras(prevData => {
        const newData = { ...prevData };
        delete newData[id];
        return newData;
      });
    }
  };

  const handleChangeCameraForAll = (evt, allcameras) => {
    let cameraCheckboxState = {};
    if (evt.target.checked) {
      setIsSelectAllChecked(true);
      setIsMasterTimeOptionEnable(false);
      allcameras.length > 0 &&
        allcameras.map(el => {
          if (['active', 'failed', 'pending'].includes(el.status)) {
            cameraCheckboxState[el.camera_id] = {
              startTime: null,
              endTime: null
            };
          }
        });
      setCheckCameras({ ...cameraCheckboxState });
    } else {
      setIsMasterTimeOptionEnable(true);
      setIsSelectAllChecked(false);
      setCheckCameras({});
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={isAttachedCameraEnable}
        onClose={() => {
          handleClose();
          setCheckCameras({});
        }}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
            setCheckCameras({});
          }}>
          {title}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={commonClasses.column}>
              <Grid>
                <Checkbox
                  checked={isSelectAllChecked}
                  value={true}
                  onChange={event =>
                    handleChangeCameraForAll(
                      event,
                      get(allcameraList, 'getAllCameraList.cameras', [])
                    )
                  }
                />
              </Grid>
              <Grid>
                <Icon
                  component={() => (
                    <CameraAltRoundedIcon
                      color={'secondary'}
                      style={{ marginTop: '2px', marginRight: '6px' }}
                    />
                  )}
                  alt={'icon'}
                />
              </Grid>
              <Grid>
                <Typography variant={'subtitle1'}>
                  Select All camera's
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  label="Start Time"
                  disabled={isMasterTimeOptionEnable}
                  value={masterStartTime}
                  onChange={newDate => {
                    setMasterStartTime(newDate);
                    let cameraCheckboxStartTimeState = { ...checkCameras };
                    get(allcameraList, 'getAllCameraList.cameras', []).length >
                      0 &&
                      get(allcameraList, 'getAllCameraList.cameras', []).map(
                        el => {
                          if (
                            ['active', 'failed', 'pending'].includes(el.status)
                          ) {
                            cameraCheckboxStartTimeState[el.camera_id] = {
                              ...cameraCheckboxStartTimeState[el.camera_id],
                              startTime: newDate
                            };
                            if (
                              cameraCheckboxStartTimeState[el.camera_id]
                                .endTime === undefined
                            ) {
                              cameraCheckboxStartTimeState = {
                                ...cameraCheckboxStartTimeState,
                                [el.camera_id]: {
                                  ...cameraCheckboxStartTimeState[el.camera_id],
                                  endTime: null
                                }
                              };
                            }
                          }
                        }
                      );
                    setCheckCameras({ ...cameraCheckboxStartTimeState });
                  }}
                  renderInput={params => <TextField {...params} />}
                />
                <TimePicker
                  style={{ marginLeft: 6 }}
                  label="End Time"
                  disabled={isMasterTimeOptionEnable}
                  value={masterEndTime}
                  onChange={newDate => {
                    setMasterEndTime(newDate);
                    let cameraCheckboxEndTimeState = { ...checkCameras };
                    get(allcameraList, 'getAllCameraList.cameras', []).length >
                      0 &&
                      get(allcameraList, 'getAllCameraList.cameras', []).map(
                        el => {
                          if (
                            ['active', 'failed', 'pending'].includes(el.status)
                          ) {
                            cameraCheckboxEndTimeState[el.camera_id] = {
                              ...cameraCheckboxEndTimeState[el.camera_id],
                              endTime: newDate
                            };
                            if (
                              cameraCheckboxEndTimeState[el.camera_id]
                                .startTime === undefined
                            ) {
                              cameraCheckboxEndTimeState = {
                                ...cameraCheckboxEndTimeState,
                                [el.camera_id]: {
                                  ...cameraCheckboxEndTimeState[el.camera_id],
                                  startTime: null
                                }
                              };
                            }
                          }
                        }
                      );
                    setCheckCameras({ ...cameraCheckboxEndTimeState });
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className={commonClasses.column}>
              <SearchBox handleSearch={handleCameraSearch} />
            </Grid>
          </Grid>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('cameraName.label')}</TableCell>
                  <TableCell>Time option</TableCell>
                  <TableCell>{t('camt4Id.label')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!cameraLoading && !cameraError ? (
                  get(filteredCameraList, 'getAllCameraList.cameras', [])
                    .length > 0 ? (
                    get(filteredCameraList, 'getAllCameraList.cameras', []).map(
                      (row, index) =>
                        ['active', 'failed', 'pending'].includes(row.status) &&
                        !row.is_deleted && (
                          <TableRow key={index}>
                            <TableCell>
                              <Grid className={commonClasses.column}>
                                <Grid>
                                  <Checkbox
                                    checked={
                                      checkCameras &&
                                      checkCameras[row.camera_id]
                                        ? checkCameras[row.camera_id]
                                        : false
                                    }
                                    value={row.camera_id}
                                    onChange={event =>
                                      handleChangeCameraRow(
                                        event,
                                        row.camera_id
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid>
                                  <Icon
                                    component={() => (
                                      <CameraAltRoundedIcon
                                        color={'secondary'}
                                        style={{
                                          marginTop: '2px',
                                          marginRight: '6px'
                                        }}
                                      />
                                    )}
                                    alt={'icon'}
                                  />
                                </Grid>
                                <Grid>
                                  <Typography variant={'subtitle1'}>
                                    {row.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <TimePicker
                                    label="Start Time"
                                    disabled={
                                      checkCameras &&
                                      checkCameras[row.camera_id]
                                        ? false
                                        : true
                                    }
                                    value={
                                      checkCameras &&
                                      checkCameras[row.camera_id]
                                        ? checkCameras[row.camera_id].startTime
                                        : null
                                    }
                                    onChange={newDate => {
                                      setCheckCameras({
                                        ...checkCameras,
                                        [row.camera_id]: {
                                          ...checkCameras[row.camera_id],
                                          startTime: newDate
                                        }
                                      });
                                    }}
                                    renderInput={params => (
                                      <TextField {...params} />
                                    )}
                                  />
                                  <TimePicker
                                    style={{ marginLeft: 6 }}
                                    label="End Time"
                                    disabled={
                                      checkCameras &&
                                      checkCameras[row.camera_id]
                                        ? false
                                        : true
                                    }
                                    value={
                                      checkCameras &&
                                      checkCameras[row.camera_id]
                                        ? checkCameras[row.camera_id].endTime
                                        : null
                                    }
                                    onChange={newDate => {
                                      setCheckCameras({
                                        ...checkCameras,
                                        [row.camera_id]: {
                                          ...checkCameras[row.camera_id],
                                          endTime: newDate
                                        }
                                      });
                                    }}
                                    renderInput={params => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </TableCell>
                            <TableCell>{row.t4_cam_id}</TableCell>
                          </TableRow>
                        )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant={'subtitle2'}>
                          Record not found!
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      {cameraLoading && !cameraError && (
                        <LinearProgress color="primary" />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container justify={'space-between'}>
            <Button
              variant={'outlined'}
              onClick={() => {
                handleClose();
                setCheckCameras({});
              }}>
              {t('cancel.label')}
            </Button>
            <Button
              color="secondary"
              variant={'contained'}
              onClick={() => handleSubmitForBulkUpload(data.id)}>
              {t('submit.label')}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignCameraForMLTemplate;
