import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '12px 4px',
        textAlign: 'right',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    sessionHeading:{
        marginLeft: '6px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: 'fit-content'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    //title: "",
    // firstName: "",
    // surName: "",
    // middleName: "",
    // dateOfBirth: null,
    // gender: "",
    // maritalStatus: "",
    // noOfChild: "",
    // dateEngaged: null,
    // designation: "",
    // grade: "",
    // pay: "",
    // userDepartment: "",
    // userSection: "",
    // costCode: "",
    // employeeType: "",
    // leaveEntitlement: "",
    // allBenefits: [],
    // allowances: [],
    // dateEngagedOnRL: null,
    // generalComment: "",
    // financialAccountant: "",
    // HRAdvisor:"",
    // HROfficer: "",
    // employeeIsAgree: "agree",

    EmpName: "",
    EmpPayrollNo: "",
    EmpJobTitle: "",
    EmpDepartment: "",
    EmpSection: "",
    EmpDateOfLastShift: null,
    storesComments: "",
    storesSignature: "",
    storesDate: null,
    securityDriverPermit: "",
    securityDriverTag: "",
    securityAccessCard: "",
    securityComments: "",
    securitySignature: "",
    securityDate: null,
    accountsInstitutionOne: "",
    accountsInstitutionTwo: "",
    accountsInstitutionThree:"",
    accountsInstitutionFour: "",
    adminTravelAmount: "",
    adminSTEAmount: "",
    adminStaffPurchasesAmount: "",
    adminCashAdvanceAmount: "",
    adminOthersAmount: "",
    mineAccountantSignature: "",
    mineAccountantDate: null,
    isRecreationClubAccount: "",
    recreationClubComments: "",
    recreationClubAmountP: "",
    clubSecretarySignature: "",
    clubSecretaryDate: null,
    tuckShopOutstandingAmountP: "",
    tuckShopSupervisorSignature: "",
    tuckShopSupervisorDate: null,
    itLaptop: "",
    itLaptopDate: null,
    itLaptopBag: "",
    itLaptopBagDate: null,
    itSecureCable: "",
    itSecureCableDate: null,
    itLearningMedia: "",
    itComments: "",
    itSupervisorSignature: "",
    itSupervisorDate: null,
    saActiveDirectAccount: null,
    saSyspro: "",
    saRTO: "",
    saResourceLink: "",
    saIsometrix: "",
    saRLSelfService: "",
    saOnKey: "",
    systemAdministrationSupervisorSignature: "",
    systemAdministrationSupervisorDate: null,
    hrShiftWorked: "",
    hrOvertimeWorked: "",
    taSupervisorDate: null,
    sectionHeadComments: "",
    SupervisorSignature: "",
    SupervisorDate: null,
    adminCellPhone: "",
    adminCellPhoneBillAmount: "",
    adminTelePhoneBillAmount: "",
    gmSecretarySignature: "",
    gmSecretaryDate: null,
    clinicExitMedicalExam: "",
    clinicComments: "",
    mineDoctorSignature: "",
    mineDoctorDate: null,
    HousingInspection: "",
    housingComments: "",
    housingRepairCost: "",
    HROfficalSignature: "",
    utilitiesOfficialSignature: "",
    utilitiesOfficialDate: null,
    controlAutomationAccount: "",
    controlTelephonePin: "",
    controlVoiceRadioRecovered: "",
    controlComments: "",
    ciOfficicalSignature: "",
    ciOfficicalDate: null,
    recruitmentExitInterview: "",
    recruitmentExitInterviewComments: "",
    recruitmentPensionWithdrawal: "",
    recruitmentPensionWithdrawalComments: "",
    recruitmentTerminationAdvice: "",
    recruitmentRecord: "",
    recruitmentPermitSurrendered: "",
    recruitmentComments: "",
    humanResourcesOfficialSignature: "",
    humanResourcesOfficialDate: null,
    employeeName: "",
    employeeIsAgree: "",
    employeeComments: "",
    employeeSignature: "",
    employeeDate: null,
    HRManagerComments: "",
    HRManagerSignature: "",
    HRManagerCommentsDate: null,
};

const allowances = [
    "Housing",
    "Electricity",
    "Telephone",
    "Inducement",
    "Other"
]

const allBenefits = [
    "Gratuity",
    "Severance",
    "Pension"
];

const genderList = [
    "Male",
    "Female"
];

const EmployeeList = [
    "Temporary Employee",
    "Permanent Employee"
];

const MaritalStatus = [
    "Married",
    "UnMarried",
    "Divorced"
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    return imgURL;
}

function ExitClearanceModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [departmentSections, setDepartmentSections] = useState([]);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;

            const EmpDepartment = data.EmpDepartment && data.EmpDepartment._id
            const EmpSection = data.EmpSection && data.EmpSection._id

            setURFormState({...data, id, EmpDepartment, EmpSection });
            if(data.EmpSection){
                setDepartmentSections([data.EmpSection]);
            }
        }
    }, [data]);

    const handleChange = (value, name) => {
        if (name === "allBenefits") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.allBenefits.push(value.target.value);
                } else {
                    let index = newState.allBenefits.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.allBenefits.splice(index, 1);
                    }
                }
                return newState;
            });
        }else if(name === 'isRecreationClubAccount' || name === 'itLaptop' || name === 'itLaptopBag' || name=== 'itSecureCable'
            || name === 'saSyspro' || name === 'saRTO' || name === 'saResourceLink' || name === 'saIsometrix' || name === 'saRLSelfService'||
            name === 'saOnKey' || name === 'clinicExitMedicalExam' || name === 'HousingInspection' || name === 'controlAutomationAccount' ||
            name === 'controlTelephonePin' || name === 'controlVoiceRadioRecovered' || name === 'recruitmentExitInterview'
            || name === 'recruitmentPensionWithdrawal' || name === 'recruitmentTerminationAdvice' || name === 'recruitmentRecord'
            || name === 'recruitmentPermitSurrendered'){
            if (value.target.checked) {
                setURFormState({ ...urFormState, [name]: value.target.value });
            }else {
                setURFormState({ ...urFormState, [name]: "" });
            } 
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ textAlign: "center"}} >EMPLOYEE EXIT CLEARANCE FORM</Typography>
                        <hr />
                    </Grid>
                    <Grid className={classes.greyBackgroundBorder} item xs={12}>
                        <p style={{margin: '0px'}}>
                            This form is to be used by an employee prior to leaving the employ of the company for whatever reason. The employee must
                            visit all under mentioned sections for comments and signatures. Final payments will not be made until the below has been fully
                            completed.
                        </p>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading}>EMPLOYEE</Typography>
                        </Grid>

                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Name</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.EmpName}
                                onChange={(evt) => handleChange(evt.target.value, "EmpName")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Payroll No</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.EmpPayrollNo}
                                onChange={(evt) => handleChange(evt.target.value, "EmpPayrollNo")}
                            />
                        </Grid>
                        
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Job Title</h5>
                        </Grid>
                        <Grid item xs={5}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.EmpJobTitle}
                                onChange={(evt) => handleChange(evt.target.value, "EmpJobTitle")}
                            />
                        </Grid>
                        
                    
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Department</h5>
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.EmpDepartment}
                                onChange={(evt) => handleChange(evt.target.value, "EmpDepartment")}
                            /> */}
                             <OutlinedSelect
                                    options={(departments && departments.length) ? departments : []}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    val={urFormState.EmpDepartment}
                                    handleChange={(evt) => {
                                        const item = departments.find(itm => evt.target.value === itm.id);
                                        setDepartmentSections(item ? item.sections : []);
                                        handleChange(evt.target.value, "EmpDepartment");
                                    }}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Section</h5>
                        </Grid>
                        <Grid item xs={5}>
                            {/* <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.EmpSection}
                                onChange={(evt) => handleChange(evt.target.value, "EmpSection")}
                            /> */}
                            <OutlinedSelect
                                    options={(departmentSections && departmentSections.length) ? departmentSections : []}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    val={urFormState.EmpSection}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    handleChange={(evt) => {
                                        handleChange(evt.target.value, "EmpSection");
                                    }}
                                />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date of last Shift</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.EmpDateOfLastShift}
                                handleChange={newDate => handleChange(newDate, "EmpDateOfLastShift")}
                            />
                        </Grid>
                        
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >STORES</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <h5 style={{ marginLeft: '5px' }}>Outstanding Stores Issues/Goods Purchased.</h5>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.storesComments}
                                onChange={(evt) => handleChange(evt.target.value, "storesComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Materials Controller</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("storesSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.storesDate}
                                handleChange={newDate => handleChange(newDate, "storesDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >SECURITY</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Drivers Authorisation Permit </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'} 
                                value={urFormState.securityDriverPermit}
                                onChange={(evt) => handleChange(evt.target.value, "securityDriverPermit")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>DriversTag</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.securityDriverTag}
                                onChange={(evt) => handleChange(evt.target.value, "securityDriverTag")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>AccessCard</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.securityAccessCard}
                                onChange={(evt) => handleChange(evt.target.value, "securityAccessCard")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.securityComments}
                                onChange={(evt) => handleChange(evt.target.value, "securityComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Senior Security Officer</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("securitySignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.securityDate}
                                handleChange={newDate => handleChange(newDate, "securityDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >ACCOUNTS</Typography>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <h5 style={{ marginLeft: '5px' }}>Outstanding Loans:</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Institution 1: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.accountsInstitutionOne}
                                onChange={(evt) => handleChange(evt.target.value, "accountsInstitutionOne")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Institution 2: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.accountsInstitutionTwo}
                                onChange={(evt) => handleChange(evt.target.value, "accountsInstitutionTwo")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Institution 3: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.accountsInstitutionThree}
                                onChange={(evt) => handleChange(evt.target.value, "accountsInstitutionThree")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Institution 4: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.accountsInstitutionFour}
                                onChange={(evt) => handleChange(evt.target.value, "accountsInstitutionFour")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Travel Advance: Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminTravelAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminTravelAmount")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>S.T.E: Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminSTEAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminSTEAmount")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Staff Purchases: Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminStaffPurchasesAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminStaffPurchasesAmount")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Cash Advance: Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminCashAdvanceAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminCashAdvanceAmount")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Others: Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminOthersAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminOthersAmount")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Mine Accountant</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("mineAccountantSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.mineAccountantDate}
                                handleChange={newDate => handleChange(newDate, "mineAccountantDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >RECREATION CLUB</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="isRecreationClubAccount"
                                checked={
                                    urFormState.isRecreationClubAccount === 'isRecreationClubAccount'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "isRecreationClubAccount");
                                }}
                                label={'Recreation Club Account Refund:'}
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.recreationClubComments}
                                onChange={(evt) => handleChange(evt.target.value, "recreationClubComments")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.recreationClubAmountP}
                                onChange={(evt) => handleChange(evt.target.value, "recreationClubAmountP")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Club Secretary</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("clubSecretarySignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.clubSecretaryDate}
                                handleChange={newDate => handleChange(newDate, "clubSecretaryDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >TUCK SHOP</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.tuckShopOutstandingAmountP}
                                onChange={(evt) => handleChange(evt.target.value, "tuckShopOutstandingAmountP")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Tuck Shop Supervisor</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("tuckShopSupervisorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.tuckShopSupervisorDate}
                                handleChange={newDate => handleChange(newDate, "tuckShopSupervisorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >INFORMATION TECHNOLOGY OFFICE</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="itLaptop"
                                checked={
                                    urFormState.itLaptop === 'itLaptop'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "itLaptop");
                                }}
                                label={'Laptop:'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.itLaptopDate}
                                handleChange={newDate => handleChange(newDate, "itLaptopDate")}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="itLaptopBag"
                                checked={
                                    urFormState.itLaptopBag === 'itLaptopBag'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "itLaptopBag");
                                }}
                                label={'Laptop Bag:'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.itLaptopBagDate}
                                handleChange={newDate => handleChange(newDate, "itLaptopBagDate")}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="itSecureCable"
                                checked={
                                    urFormState.itSecureCable === 'itSecureCable'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "itSecureCable");
                                }}
                                label={'Secure Cable:'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.itSecureCableDate}
                                handleChange={newDate => handleChange(newDate, "itSecureCableDate")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Learning Media (Books, CD & DVDs): </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.itLearningMedia}
                                onChange={(evt) => handleChange(evt.target.value, "itLearningMedia")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.itComments}
                                onChange={(evt) => handleChange(evt.target.value, "itComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Supervisor/Section Head</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("itSupervisorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.itSupervisorDate}
                                handleChange={newDate => handleChange(newDate, "itSupervisorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >SYSTEMS ADMINISTRATION</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <h5 className={classes.blackBorderTitle}>Active Directory Account: </h5>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.saActiveDirectAccount}
                                handleChange={newDate => handleChange(newDate, "saActiveDirectAccount")}
                                label={'Expiry'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saSyspro"
                                checked={
                                    urFormState.saSyspro === 'saSyspro'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saSyspro");
                                }}
                                label={'Syspro : '}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saRTO"
                                checked={
                                    urFormState.saRTO === 'saRTO'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saRTO");
                                }}
                                label={'RTO: '}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saResourceLink"
                                checked={
                                    urFormState.saResourceLink === 'saResourceLink'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saResourceLink");
                                }}
                                label={'Resource Link (Core):'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saIsometrix"
                                checked={
                                    urFormState.saIsometrix === 'saIsometrix'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saIsometrix");
                                }}
                                label={'Isometrix:'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saRLSelfService"
                                checked={
                                    urFormState.saRLSelfService === 'saRLSelfService'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saRLSelfService");
                                }}
                                label={'RL Self Service: '}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="saOnKey"
                                checked={
                                    urFormState.saOnKey === 'saOnKey'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "saOnKey");
                                }}
                                label={'On Key:'}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Supervisor/Section Head</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("systemAdministrationSupervisorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.systemAdministrationSupervisorDate}
                                handleChange={newDate => handleChange(newDate, "systemAdministrationSupervisorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >HR SYSTEMS ADMINISTRATION</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Shift worked (current month) : </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.hrShiftWorked}
                                onChange={(evt) => handleChange(evt.target.value, "hrShiftWorked")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Overtime worked (current month): </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.hrOvertimeWorked}
                                onChange={(evt) => handleChange(evt.target.value, "hrOvertimeWorked")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Time and Attendance Administrator</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("taSupervisorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.taSupervisorDate}
                                handleChange={newDate => handleChange(newDate, "taSupervisorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >SUPERVISOR/SECTION HEAD</Typography>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <h5 style={{ marginLeft: '5px' }}>Handed Over Company Property /PPE/Uniform/Locker Keys.</h5>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.sectionHeadComments}
                                onChange={(evt) => handleChange(evt.target.value, "sectionHeadComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Supervisor/Section Head</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("SupervisorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.SupervisorDate}
                                handleChange={newDate => handleChange(newDate, "SupervisorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >ADMINISTRATION</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Cell Phone Handset: </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminCellPhone}
                                onChange={(evt) => handleChange(evt.target.value, "adminCellPhone")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Cell Phone Bill Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminCellPhoneBillAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminCellPhoneBillAmount")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Telephone Outstanding Amount: P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.adminTelePhoneBillAmount}
                                onChange={(evt) => handleChange(evt.target.value, "adminTelePhoneBillAmount")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >GMâ€™s Secretary / Receptionist</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("gmSecretarySignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.gmSecretaryDate}
                                handleChange={newDate => handleChange(newDate, "gmSecretaryDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >CLINIC</Typography>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="clinicExitMedicalExam"
                                checked={
                                    urFormState.clinicExitMedicalExam === 'clinicExitMedicalExam'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "clinicExitMedicalExam");
                                }}
                                label={'Exit Medical Examination:'}
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.clinicComments}
                                onChange={(evt) => handleChange(evt.target.value, "clinicComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Mine Doctor</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("mineDoctorSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.mineDoctorDate}
                                handleChange={newDate => handleChange(newDate, "mineDoctorDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >HOUSING</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="HousingInspection"
                                checked={
                                    urFormState.HousingInspection === 'HousingInspection'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "HousingInspection");
                                }}
                                label={'House Inspection Carried Out:'}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.housingComments}
                                onChange={(evt) => handleChange(evt.target.value, "housingComments")}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Repair Costs to the Mine House:  P </h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.housingRepairCost}
                                onChange={(evt) => handleChange(evt.target.value, "housingRepairCost")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Human Resources Official</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("HROfficalSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Utilities Official</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("utilitiesOfficialSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.utilitiesOfficialDate}
                                handleChange={newDate => handleChange(newDate, "utilitiesOfficialDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >CONTROL AND INSTRUMENTATION</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="controlAutomationAccount"
                                checked={
                                    urFormState.controlAutomationAccount === 'controlAutomationAccount'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "controlAutomationAccount");
                                }}
                                label={'Automation Account Removed:'}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="controlTelephonePin"
                                checked={
                                    urFormState.controlTelephonePin === 'controlTelephonePin'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "controlTelephonePin");
                                }}
                                label={'Telephone Extension/Pin Recovered:'}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="controlVoiceRadioRecovered"
                                checked={
                                    urFormState.controlVoiceRadioRecovered === 'controlVoiceRadioRecovered'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "controlVoiceRadioRecovered");
                                }}
                                label={'Voice Radio Recovered:'}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.controlComments}
                                onChange={(evt) => handleChange(evt.target.value, "controlComments")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >C & I Official</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("ciOfficicalSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.ciOfficicalDate}
                                handleChange={newDate => handleChange(newDate, "ciOfficicalDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >RECRUITMENT SECTION</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="recruitmentExitInterview"
                                checked={
                                    urFormState.recruitmentExitInterview === 'recruitmentExitInterview'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "recruitmentExitInterview");
                                }}
                                label={'Exit Interview Conducted:'}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.recruitmentExitInterviewComments}
                                onChange={(evt) => handleChange(evt.target.value, "recruitmentExitInterviewComments")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="recruitmentPensionWithdrawal"
                                checked={
                                    urFormState.recruitmentPensionWithdrawal === 'recruitmentPensionWithdrawal'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "recruitmentPensionWithdrawal");
                                }}
                                label={'Pension Withdrawal Application completed:'}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.recruitmentPensionWithdrawalComments}
                                onChange={(evt) => handleChange(evt.target.value, "recruitmentPensionWithdrawalComments")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="recruitmentTerminationAdvice"
                                checked={
                                    urFormState.recruitmentTerminationAdvice === 'recruitmentTerminationAdvice'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "recruitmentTerminationAdvice");
                                }}
                                label={'Termination Advice processed:'}
                            />
                        </Grid>

                                
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="recruitmentRecord"
                                checked={
                                    urFormState.recruitmentRecord === 'recruitmentRecord'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "recruitmentRecord");
                                }}
                                label={'Record of Service Prepared: '}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="recruitmentPermitSurrendered"
                                checked={
                                    urFormState.recruitmentPermitSurrendered === 'recruitmentPermitSurrendered'
                                      ? true
                                      : false
                                }
                                control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    handleChange(evt, "recruitmentPermitSurrendered");
                                }}
                                label={'Permits surrendered including dependants:'}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.recruitmentComments}
                                onChange={(evt) => handleChange(evt.target.value, "recruitmentComments")}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <h5 style={{ marginLeft: '5px' }}>The above named employee has satisfied the company clearance procedure.</h5>
                        </Grid>
                        
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >Human Resources Official</Typography>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("humanResourcesOfficialSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.humanResourcesOfficialDate}
                                handleChange={newDate => handleChange(newDate, "humanResourcesOfficialDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >EMPLOYEE ACCEPTANCE / NON ACCEPTANCE</Typography>
                        </Grid>

                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>I </h5>
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.employeeName}
                                onChange={(evt) => handleChange(evt.target.value, "employeeName")}
                            />
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="employeeIsAgree"
                                value={urFormState.employeeIsAgree}
                                onChange={(evt) => handleChange(evt.target.value, "employeeIsAgree")}
                                style={{ minWidth: 'fit-content', padding: '7px' }}>
                                <MenuItem value="">
                                    "select"
                                </MenuItem>
                                <MenuItem value="agree">
                                    "agree"
                                </MenuItem>
                                <MenuItem value="do not agree">
                                    "do not agree"
                                </MenuItem>
                            </Select>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <h5> with the contents of the completed clearance form</h5>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.employeeComments}
                                onChange={(evt) => handleChange(evt.target.value, "employeeComments")}
                            />
                        </Grid>
                        
                        <Grid item xs={4} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("employeeSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.employeeDate}
                                handleChange={newDate => handleChange(newDate, "employeeDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.blackBorder} container  xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h4"className={classes.sessionHeading} >HUMAN RESOURCES MANAGER</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <h5 style={{ marginLeft: '5px' }}> The above named employee has satisfied the company clearance procedure.</h5>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.HRManagerComments}
                                onChange={(evt) => handleChange(evt.target.value, "HRManagerComments")}
                            />
                        </Grid>
                        
                        <Grid item xs={4} style={{ textAlign: 'center'}}>
                            <IconButton 
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("HRManagerSignature")}
                                >
                                Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: '100%' }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.HRManagerCommentsDate}
                                handleChange={newDate => handleChange(newDate, "HRManagerCommentsDate")}
                            />
                        </Grid>
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => handleCreateRequestSubmit(urFormState)}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ExitClearanceModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ExitClearanceModal);