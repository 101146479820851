import React from 'react';
import { Redirect } from 'react-router-dom';
import Message from './../components/Message';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { USER_ROLES } from '../utils/constants';

class PrivateRoute extends React.Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true
  };

  componentDidMount() {
    try {
      let token = localStorage.getItem('syncomToken');
      let orgName = localStorage.getItem('orgName');

      const { realm_access } = jwt_decode(token);
      const roleVerification = this.props.internal
        ? realm_access &&
          (realm_access.roles.includes(USER_ROLES.IP_SUPERADMIN) ||
            realm_access.roles.includes(USER_ROLES.IP_COUNTRYADMIN))
        : true;
        //login isseu
      if (token && roleVerification) {
        this.setState({ isAuthenticated: true });
      } else {
        throw new Error('');
      }
    } catch (e) {
      Message.error('Not authenticated to access this page.');
    }
    this.setState({ isAuthenticating: false });
  }

  render() {
    const { component: Component, ...rest } = this.props;

    if (this.state.isAuthenticating) {
      return null;
    }

    return this.state.isAuthenticated ? (
      <Component {...rest} />
    ) : (
      <Redirect to="/" />
    );
  }
}

export default PrivateRoute;
