import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
// import { USER_ROLES } from "../../../../../utils/constants";
import { useEffect } from 'react';
import { dateFormatterLLL } from '../../../../../utils';

const ProjectTrackItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName
}) => {
    const commonClasses = commonStyles();
    // const role = localStorage.getItem('role');
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow key={data.uid}>
                <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.customer_name} / {data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                        {data.project_owner ? data.project_owner : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.project_type ? data.project_type : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.tracking_status ? data.tracking_status : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};
ProjectTrackItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(ProjectTrackItem);