import {
  Button,
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Popup from '../../Popup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AddBgp } from '../../../../services/fcrService';
import Message from '../../../../components/Message';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const validationSchema = yup.object().shape({
  remoteAs: yup
    .number()
    .typeError('Enter a value between 65001 & 65100')
    .min(65001, 'Enter a value between 65001 & 65100')
    .max(65100, 'Enter a value between 65001 & 65100')
    .required('This field is required')
});

const AddBgpPopup = ({
  BgpAddView,
  setBgpAddView,
  connectionId,
  connCircleBEndIp,
  classes
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialValues = { neighbourIp: '', remoteAs: '' };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset, formState: { isValid } } = methods;

  const onSubmit = async ({ remoteAs }) => {
    const payload = {
      conn_id: connectionId,
      neighbor_ip: nieghbourIp,
      neighbor_remote: remoteAs.toString()
    };
    const res = await AddBgp(payload);
    if (res?.status === 200) {
      Message.success('BGP Added Succesfully');
      reset();
      setBgpAddView(false);
    } else {
      Message.error(res?.message);
      reset();
    }
  };

  useEffect(() => {
    setNieghbourIp(connCircleBEndIp);
  }, [connCircleBEndIp]);

  return (
    <>
      <Popup
        title="Add BGP"
        openPopup={BgpAddView}
        setOpenPopup={setBgpAddView}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered>
                    <Tab label="Standard" {...a11yProps(0)} />
                    <Tab label="Advanced" {...a11yProps(1)} disabled />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <Grid item container alignItems="center">
                      <Grid
                        item
                        style={{ color: 'grey', fontWeight: '500' }}
                        sm={4}>
                        Neighbor IP
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={7}>
                        <TextField
                          value={nieghbourIp}
                          size="small"
                          variant="outlined"
                          autoComplete="off"
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      style={{ marginTop: '10px' }}>
                      <Grid
                        item
                        style={{ color: 'grey', fontWeight: '500' }}
                        sm={4}>
                        Remote-AS
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={7}>
                        <RHFTextField
                          variant="outlined"
                          name="remoteAs"
                          label="Remote-AS"
                          autoComplete="off"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={1}
                    style={{ height: '175px' }}></TabPanel>
                </>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setBgpAddView(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
              Submit
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default AddBgpPopup;
