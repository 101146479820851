import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_START_END_DATE_PROJECT_ANALYSIS, GET_START_END_DATE_PAGINATED_PROJECT_ANALYSIS, GET_EXCEL_FILE_URL_PAGINATED_PROJECT_ANALYSIS } from "../../queries";
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import CustomTable from "./components/Table";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnalyticsItem from "./components/AnalyticsItem";
import BiDashboardItem from "./components/BiDashboardItem";
import ProjectInProgressTableItem from "./components/ProjectInProgressTableItem";
import QuantityOfProjectTableItem from "./components/QuantityOfProjectTableItem";
import AgeingProjectsInProgressTableItem from "./components/AgeingProjectsInProgressTableItem";
import AnalysisOfOutOfSLACausesTableItem from "./components/AnalysisOfOutOfSLACausesTableItem";
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import { colors } from '../../../../../theme/colors';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PieChartWrapper from '../../../../../components/Charts/PieChartWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import LineChartWrapper from '../../../../../components/Charts/LineChartWrapper';
import BarHorizontalChartWrapper from '../../../../../components/Charts/BarHorizontalChartWrapper';
import ChartContainer from '../../../../../components/Charts/ChartContainer';
import ProjectDetailListModal from './components/ProjectDetailListModal';
import Message from "../../../../../components/Message";
import { GET_EXCEL_FILE_URL_BI_DASHBOARD_ANALYTICS_TABLE } from "../../mutations";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));
export const IncidentStatusPOC = ({ t, data }) => {
    return (
        <div>
            <PieChartWrapper data={data} />
        </div>
    );
};
function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            style={{float: 'right'}}
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('downloadProjectReport.label')}
        </Button>
    );
}
const Analytics = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [ProjectDetailList, setProjectDetailList] = useState({ open: false, loading: false, error: '', data: [], listName: '', excelSheetUrl: '' });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [PMOWeeklyReport, setPMOWeeklyReport] = useState({ loading: false, data: [], total: 50 });
    const [ProjectInProgressClosedAndHold, setProjectInProgressClosedAndHold] = useState({ loading: false, data: [], error: false });
    const [QuantityOfProjectTable, setQuantityOfProjectTable] = useState({ loading: false, data: [], error: false });
    const [AgeingProjectsInProgress, setAgeingProjectsInProgress] = useState({ loading: false, data: [], error: false });
    const [AnalysisOfOutOfSLACausesTable, setAnalysisOfOutOfSLACausesTable] = useState({ loading: false, data: [], error: false });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const countryName = localStorage.getItem('country');
    let currencyCode = '';
    let currencySymbol = '';
    let count = 1;
    if (COUNTRY_LIST.NIGERIA === countryName) {
        currencyCode = 'NGN';
        currencySymbol = '₦';
    } else if (COUNTRY_LIST.BOTSWANA === countryName) {
        currencyCode = 'BWP';
        currencySymbol = 'P';
    } else if (COUNTRY_LIST.MALAWI === countryName) {
        currencyCode = 'MWK';
        currencySymbol = 'MK';
    } else if (COUNTRY_LIST.ZAMBIA === countryName) {
        currencyCode = 'ZMW';
        currencySymbol = 'ZK';
    } else if (COUNTRY_LIST.CAMEROON === countryName) {
        currencyCode = 'XAF';
        currencySymbol = 'FCFA';
    } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
        currencyCode = 'XOF';
        currencySymbol = 'CFA';
    }
    let billing_country = 'all';
    // let billing_country = localStorage.getItem('country');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    const PMOWeeklyReportColumn = [
        { name: t('WIPReportSummary.label') },
        { name: t('nrc.label') + ' (' + currencyCode + ')' },
        { name: t('mrc.label') + ' (' + currencyCode + ')' }
    ];
    const PMOWeeklyReportData = [
        { summary: 'Completed', nrc: 'N/A', mrc: 'N/A' },
        { summary: '1 - 30 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: '31 - 60 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: '61 - 90 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: 'Total', nrc: 'N/A', mrc: 'N/A' },
    ];
    const projectInProgressData = [
        { name: t('mrc.label') + ' (' + currencySymbol + ')', value: 44 },
        { name: t('nrc.label') + ' (' + currencySymbol + ')', value: 56 },
        { name: t('sitesCount.label'), value: 0 },
    ];
    const NrcNNgnData = [
        { name: t('nrc.label') + ' (' + currencySymbol + ')' + ', ' + currencyCode + ' 1', value: 80 },
        { name: t('nrc.label') + ' (' + currencySymbol + ')' + ', ' + currencyCode + ' 2', value: 10 },
        { name: t('nrc.label') + ' (' + currencySymbol + ')' + ', ' + currencyCode + ' 3', value: 10 },
    ];
    const countsOfProjectsProgressData = [
        { name: '0 - 30', value: 22.2 },
        { name: '31 - 60', value: 22.2 },
        { name: '61 - 90', value: 11.1 },
        { name: '91 - 120', value: 0 },
        { name: '121 - above', value: 44.5 },
    ];
    const projectInProgressClosedAndHoldColumn = [
        { label: t('column.label') },
        { label: t('projectInProgress.label') },
        { label: t('projectsClosed.label') },
        { label: t('projectOnHold.label') },
        { label: t('totalProjectInPmoPortfolio.label') + ' (' + currencyCode + ')' },
    ];
    const ProjectInProgressClosedAndHoldtData = [
        { column: 'NRC' + ' (' + currencySymbol + ')', project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
        { column: 'MRC' + ' (' + currencySymbol + ')', project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
        { column: 'SITES COUNT', project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
    ];
    const QuantityOfProjectTableColumn = [
        { label: t('column.label') },
        { label: t('quantityOfProjects.label') },
        { label: t('nrc.label') + ' (' + currencySymbol + ')' },
        { label: t('mrc.label') + ' (' + currencySymbol + ')' },
    ];
    const QuantityOfProjectTabletData = [
        { column: 'Projections', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
        { column: 'Current Revenue', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
        { column: 'Expected Revenue', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
    ];
    const AgeingProjectsInProgressTableColumn = [
        { label: t('ageing.label') },
        { label: t('count.label') },
        { label: t('nrc.label') + ' (' + currencySymbol + ')' },
        { label: t('mrc.label') + ' (' + currencySymbol + ')' },
        { label: t('totalRevenueN.label') + ' (' + currencySymbol + ')' },
    ];
    const AgeingProjectsInProgressTabletData = [
        { ageing: '0 - 30', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '31 - 60', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '61 - 90', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '91 - above', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: 'Total', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
    ];
    const AnalysisOfOutOfSLACausesTableColumn = [
        { label: t('column.label') },
        { label: t('category.label') },
        { label: t('count.label') },
        { label: t('nrc.label') },
        { label: t('mrc.label') },
    ];
    const AnalysisOfOutOfSLACausesTabletData = [
        { column: '1', category: 'Customer install delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '2', category: 'Customer integration delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '3', category: 'Customer acceptance delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '4', category: 'Inq Internal delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '5', category: '3rd party delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '6', category: 'Equipment order and delivery', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '7', category: 'Solution redesigning delays', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
    ];
    const FY21ProjectRevenueData = [
        {
            name: 'MRC',
            type: 'line',
            stack: 'Total',
            data: [7.06, 3.42, 13.10, 11.96, 11.85, 14.17, 6.56, 15.50, 2.65]
        },
        {
            name: 'NRC',
            type: 'line',
            stack: 'Total',
            data: [9.09, 4.98, 10.63, 11.73, 6.70, 9.56, 8.25, 16.54, 3.75]
        }
    ]
    const FY21ProjectRevenueDataKeyXAxis = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]
    const projectInProgressBarChartData = [
        {
            name: 'SITE COUNTS',
            type: 'bar',
            data: [127, 36, 12, 175]
        },
        {
            name: 'MRC' + '(' + currencySymbol + ')',
            type: 'bar',
            data: [22564852, 2658654, 4582635, 29568945]
        },
        {
            name: 'NRC' + '(' + currencySymbol + ')',
            type: 'bar',
            data: [30564852, 3658654, 4982635, 39568945]
        }
    ]
    const projectInProgressBarChartYAxisData = ['Projects in Progress', 'Projects Closed', 'Projects On Hold', 'Total Projects in PMO Portfolio(' + currencyCode + ')'];
    useEffect(() => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        // if (COUNTRY_LIST.IVORY_COAST === countryName) {
        //     billing_country = `cote d'ivoire`;
        // }
        if (count === 1) {
            let startDateBefore30days = moment(convertEndDate).subtract(30, 'day').format("YYYY-MM-DD");
            // let formatStartDate = moment(startDateBefore30days).format("MM/DD/YYYY");
            setStartDate(new Date(startDateBefore30days));
            getAllList({
                variables: {
                    startEnddate: "start_date=" + startDateBefore30days + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
            count++;
        }
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            let startDateBefore30days = moment(convertEndDate).subtract(30, 'day').format("YYYY-MM-DD");
            // let formatStartDate = moment(startDateBefore30days).format("MM/DD/YYYY");
            setStartDate(new Date(startDateBefore30days));
            getAllList({
                variables: {
                    startEnddate: "start_date=" + startDateBefore30days + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
            count++;
        }
    }, [CurrentUserName]);
    const handlePageChangeProjectList = (event, value) => {
        setProjectDetailList({ ...ProjectDetailList, loading: true, error: '', data: [] })
        setPage(value);
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        // if (COUNTRY_LIST.IVORY_COAST === countryName) {
        //     billing_country = `cote d'ivoire`;
        // }
        getAllPaginatedProjectList({
            variables: {
                startEnddate: "start_date=" + startDate + "&end_date=" + endDate + '&page=' + value + "&billing_country=" + billing_country + "&data_for=" + ProjectDetailList.listName
            }
        })
    };
    const [getAllList] = useLazyQuery(GET_START_END_DATE_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            if (data && data.getStartEndDateAllCountryProjectAnalytics) {
                let parseResult = JSON.parse(data.getStartEndDateAllCountryProjectAnalytics.teamListData);
                if (parseResult && parseResult.analytics && parseResult.analytics.project_sales) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_sales).forEach(obj => {
                        for (let property in obj) {
                            if (property == "sales_team_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "sales_team_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "sales_team_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_sales['total'] = {
                        "total_count": 0,
                        "sales_team_nrc": sumNrc,
                        "sales_team_mrc": sumMrc,
                        "sales_team_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_installed) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_installed).forEach(obj => {
                        for (let property in obj) {
                            if (property == "install_team_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "install_team_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "install_team_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_installed['total'] = {
                        "total_count": 0,
                        "install_team_nrc": sumNrc,
                        "install_team_mrc": sumMrc,
                        "install_team_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_billed) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_billed).forEach(obj => {
                        for (let property in obj) {
                            if (property == "finance_team_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "finance_team_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "finance_team_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_billed['total'] = {
                        "total_count": 0,
                        "finance_team_nrc": sumNrc,
                        "finance_team_mrc": sumMrc,
                        "finance_team_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_in_wip) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_in_wip).forEach(obj => {
                        for (let property in obj) {
                            if (property == "pending_for_billing_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "pending_for_billing_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "pending_for_billing_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_in_wip['total'] = {
                        "total_count": 0,
                        "pending_for_billing_nrc": sumNrc,
                        "pending_for_billing_mrc": sumMrc,
                        "pending_for_billing_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_installed_but_not_billed) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_installed_but_not_billed).forEach(obj => {
                        for (let property in obj) {
                            if (property == "sales_team_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "sales_team_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "sales_team_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_installed_but_not_billed['total'] = {
                        "total_count": 0,
                        "sales_team_nrc": sumNrc,
                        "sales_team_mrc": sumMrc,
                        "sales_team_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_lost) {
                    let sumArc = 0;
                    let sumMrc = 0;
                    let sumNrc = 0;
                    Object.values(parseResult.analytics.project_lost).forEach(obj => {
                        for (let property in obj) {
                            if (property == "project_lost_arc"){
                                sumArc += obj[property];
                            }
                            if (property == "project_lost_mrc"){
                                sumMrc += obj[property];
                            }
                            if (property == "project_lost_nrc"){
                                sumNrc += obj[property];
                            }
                        }
                    })
                    parseResult.analytics.project_lost['total'] = {
                        "total_count": 0,
                        "project_lost_nrc": sumNrc,
                        "project_lost_mrc": sumMrc,
                        "project_lost_arc": sumArc,
                    }
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_average_install_team) {
                    let sum = Object.values(parseResult.analytics.project_average_install_team).reduce((a, b) => a + b, 0)
                    parseResult.analytics.project_average_install_team['total'] = sum;
                }
                if (parseResult && parseResult.analytics && parseResult.analytics.project_average_bill_team) {
                    let sum = Object.values(parseResult.analytics.project_average_bill_team).reduce((a, b) => a + b, 0)
                    parseResult.analytics.project_average_bill_team['total'] = sum;
                }
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get start end date data Analytics :", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const [getAllPaginatedProjectList] = useLazyQuery(GET_START_END_DATE_PAGINATED_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            console.log("data bidashboard : ", data);
            if (data && data.getStartEndDatePaginatedProjectAnalysis) {
                let parseResult = JSON.parse(data.getStartEndDatePaginatedProjectAnalysis.teamListData);
                console.log("parse data anylectis : ", parseResult);
                setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: parseResult })
            } else {
                setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
            }
        },
        onError: error => {
            console.log("error get start end date data Analytics :", error)
            setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
        }
    });
    const [getExcelFileUrlPaginatedProjectList] = useLazyQuery(GET_EXCEL_FILE_URL_PAGINATED_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setDownloadFileLoading(false);
            if (data && data.getExcelFileURLPaginatedProjectAnalysis) {
                let parseResult = JSON.parse(data.getExcelFileURLPaginatedProjectAnalysis.teamListData);
                // console.log("parse data anylectis excel url: ", parseResult);
                if (parseResult && parseResult.url) {
                    Message.success('Projects excel file downloaded successfully');
                    window.location.href = parseResult.url;
                }
                // setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: parseResult })
            } else {
                // setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
            }
        },
        onError: error => {
            setDownloadFileLoading(false);
            console.log("error get start end date data Analytics :", error)
            setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
        }
    });
    const biDashboardColumns = [
        {
            label: t('WIPReportSummary.label'),
            key: "report_summary"
        },
        {
            label: t('nrc.label'),
            key: "report_summary"
        },
        {
            label: t('mrc.label'),
            key: "report_summary"
        },
        {
            label: t('launched.label'),
            key: "report_summary"
        },
        {
            label: t('newlyLaunched.label'),
            key: "report_summary"
        },
        {
            label: t('closed.label'),
            key: "report_summary"
        },
        {
            label: t('newlyClosed.label'),
            key: "report_summary"
        },
    ];
    const analyticsColumns = [
        {
            label: t('parameters.label'),
            key: "report_summary"
        },
        {
            label: t('nigeria.label'),
            key: "report_summary"
        },
        {
            label: t('malawi.label'),
            key: "report_summary"
        },
        {
            label: t('botswana.label'),
            key: "report_summary"
        },
        {
            label: t('cameroon.label'),
            key: "report_summary"
        },
        {
            label: t('zambia.label'),
            key: "report_summary"
        },
        {
            label: t('ivoryCoast.label'),
            key: "report_summary"
        },
        {
            label: t('groupCumulative.label'),
            key: "groupCumulative"
        }
    ];
    const handleMenuItemClick = (elem, data) => { }
    const handleDateSubmitClick = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        let date1 = new Date(convertStartDate).getTime();
        let date2 = new Date(convertEndDate).getTime();
        if (date2 >= date1) {
            setLoading(true);
            setLoading(true);
            setError(true);
            // if (COUNTRY_LIST.IVORY_COAST === countryName) {
            //     billing_country = `cote d'ivoire`;
            // }
            getAllList({
                variables: {
                    startEnddate: "start_date=" + convertStartDate + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
        } else {
            Message.error('Start date is greater than end date. Please select proper start date and end date');
        }
    };
    const handleSubmitProjectDetailList = (list, listName) => {
        setPage(1);
        console.log("list handleSubmitProjectDetailList : ", list);
        console.log("list handleSubmitProjectDetailList : ", listName);
        if (list && list.length != 0) {
            if (list.results && list.results.length != 0) {
                setProjectDetailList({ open: true, loading: false, error: '', data: list, listName: listName, excelSheetUrl: '' })
            }
        }
    }
    const handleClickDownloadExcelSheet = () => {
        // if (COUNTRY_LIST.IVORY_COAST === countryName) {
        //     billing_country = `cote d'ivoire`;
        // }
        setDownloadFileLoading(true);
        getExcelFileUrlPaginatedProjectList({
            variables: {
                startEnddate: "start_date=" + startDate + "&end_date=" + endDate + "&billing_country=" + billing_country + "&data_for=" + ProjectDetailList.listName
            }
        })
    }
    const downloadExcelTableAnalytics = () => {
        if(list.analytics && Object.keys(list.analytics).length > 0){
            setDownloadFileLoading(true);
            getExcelFileBiDashboardAnalyticsTable({
                variables: {
                    formData: JSON.stringify(list.analytics)
                }
            })
        }
    }
    const [getExcelFileBiDashboardAnalyticsTable] = useMutation(GET_EXCEL_FILE_URL_BI_DASHBOARD_ANALYTICS_TABLE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setDownloadFileLoading(false);
            if (data && data.getExcelFileURLBidashboardAnalyticsTable) {
                console.log("data excel : ",data)
                let parseResult = JSON.parse(data.getExcelFileURLBidashboardAnalyticsTable.teamData);
                console.log("parse data anylectis excel url: ", parseResult);
                if (parseResult && parseResult.url) {
                    Message.success('Project report downloaded successfully');
                    window.location.href = parseResult.url;
                }
            }
        },
        onError: error => {
            setDownloadFileLoading(false);
            console.log("error get start end date data Analytics :", error)
        }
    });
    return (
        <>
            <Header title={t('biDashboard.label')} />
            <Grid container>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="secondary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>Project Report ({moment(startDate).format("DD MMM YYYY") + ' - ' + moment(endDate).format("DD MMM YYYY")})
                    </Typography>
                    <BiDashboardItem
                        t={t}
                        data={list}
                        column={biDashboardColumns}
                        loading={loading}
                        startDate={startDate}
                        endDate={endDate}
                        error={error}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...paddingGenerator('pb-20'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>Project Report ({moment(startDate).format("DD MMM YYYY") + ' - ' + moment(endDate).format("DD MMM YYYY")})
                    <ButtonComponent
                        t={t}
                        onClick={downloadExcelTableAnalytics}                        
                        loading={downloadFileLoading} />
                    </Typography>
                    <AnalyticsItem
                        t={t}
                        data={list}
                        column={analyticsColumns}
                        loading={loading}
                        startDate={startDate}
                        endDate={endDate}
                        error={error}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
                <ProjectDetailListModal
                    t={t}
                    open={ProjectDetailList.open}
                    handleClose={() => {
                        setProjectDetailList({ open: false, loading: false, error: '', data: [], listName: '', excelSheetUrl: '' });
                        setPage(1);
                    }}
                    data={ProjectDetailList.data}
                    page={page}
                    loading={ProjectDetailList.loading}
                    error={ProjectDetailList.error}
                    listName={ProjectDetailList.listName}
                    excelSheetUrl={ProjectDetailList.excelSheetUrl}
                    handlePageChangeProjectList={handlePageChangeProjectList}
                    handleClickDownloadExcelSheet={handleClickDownloadExcelSheet}
                    downloadFileLoading={downloadFileLoading}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(Analytics);