import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Message from '../../../../components/Message';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { REQUIRED_ERROR } from '../../../../utils/constants';

function ChooseOrgModal({
    t,
    open,
    handleClose,
    data,
    handleSubmitSelectOrg,
    ...other
}) {
    
    const [selectedOrg, setSelectedOrg] = useState('');
    const radioGroupRef = React.useRef(null);    
    
    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="verify-email-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}
            disableBackdropClick
            disableEscapeKeyDown
            {...other}
            >
            <DialogTitle id="confirmation-dialog-title">{t('chooseOrg.label')}</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                ref={radioGroupRef}
                aria-label="ringtone"
                name="ringtone"
                value={selectedOrg}
                onChange={(evt) => {
                    setSelectedOrg(evt.target.value)
                }}
                >
                {data.map((item) => (
                    <FormControlLabel style={{...marginGenerator('mb-8')}} value={item.id} key={item.id} control={<Radio style={{border:'0px', ...marginGenerator('mr-8')}} />} label={item.name} />
                ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <Button 
                    variant="contained"
                    color={'primary'}
                    disabled={!selectedOrg}
                    onClick={(evt) => {
                        if(!selectedOrg){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleSubmitSelectOrg(selectedOrg);
                    }}
                    >
                        {t('submit.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ChooseOrgModal.propTypes = {

  handleClose: PropTypes.func.isRequired,
  handleVerifyEmailClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleResendOtp: PropTypes.func
};

export default withTranslation()(ChooseOrgModal);
