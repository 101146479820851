import React, { useState } from 'react';
import { Button, Avatar, Grid, Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from 'react-router-dom';
import SelectSourceOfConnection from '../SelectSourceOfConnection/SelectSourceOfConnection';
import Container from '@material-ui/core/Container';
import { useAppContext } from '../../../../../contexts/AppContext';

const Step1 = ({ fcrChanged, handleNext }) => {
  let history = useHistory();
  const [isActive, setIsActive] = useState('');

  const { dispatch } = useAppContext();

  const handleDataCenter = (e, index, destinationType) => {
    e.preventDefault();
    setIsActive(index);
    dispatch({
      type: 'SET_DESTINATION_TYPE',
      payload: destinationType
    });
  };

  const data = [
    {
      icon: (
        <svg
          style={{ height: '20px', width: '20px' }}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 490 490">
          <g>
            <path
              d="M0,0v490h490V0H0z M430.1,332.9h-87.5v50.9h-33.1v50.9H180.4v-50.6h-33.1v-51.3H59.9v-278h46.7v66.5h38.5V54.8h40.8v66.5
      h38.5V54.8h40.8v66.5h38.5V54.8h40.8v66.5H383V54.8h46.7v278.1L430.1,332.9L430.1,332.9z"
              fill="white"
            />
          </g>
        </svg>
      ),
      name: 'Port',
      destinationType: 'port'
    },
    {
      icon: (
        <svg
          style={{ height: '20px', width: '20px' }}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="43.422px"
          height="43.422px"
          viewBox="0 0 43.422 43.422">
          <g>
            <path
              d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093
       c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002
       c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591
       c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024
       c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128
       v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2
       V21.749h2.148v10.897h12.881v2L40.686,32.102z"
              fill="white"
            />
          </g>
        </svg>
      ),
      name: 'FCR',
      destinationType: 'fcr'
    },
    {
      icon: (
        <svg
          version="1.1"
          style={{ height: '20px', width: '20px' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          enable-background="new 0 0 512 512">
          <g>
            <g>
              <path
                fill="white"
                d="m257,461.2c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4z"
              />
              <path
                fill="white"
                d="m257,299.9c-43.7,0-83.3,17.7-111.9,46.3-8.1,8.1-8.1,21.1 0,29.2 8.1,8 21,7.9 29.1-0.2 21.3-21.2 50.6-34.3 82.9-34.3 32.3,0 61.6,13.1 82.9,34.3 8.1,8 21,8.2 29.1,0.2 8.1-8 8-21.1 0-29.2-28.8-28.6-68.4-46.3-112.1-46.3z"
              />
              <g>
                <path
                  fill="white"
                  d="m257,374.3c-23.1,0-44.1,9.3-59.3,24.4-8.1,8-8,21.1 0.1,29.1 8,8 20.9,7.9 28.9,0 7.8-7.7 18.5-12.4 30.3-12.4 11.8,0 22.5,4.8 30.3,12.4 8,7.9 20.9,8 28.9,0 8.1-8 8.1-21.1 0.1-29.1-15.2-15.1-36.2-24.4-59.3-24.4z"
                />
                <path
                  fill="white"
                  d="m441.3,164.1c-4.4-51.8-47.8-92.4-100.7-92.4-15.1,0-29.5,3.3-42.4,9.3-26-41.1-71.7-68.5-123.8-69-81.6-0.8-149.6,66.5-149.6,148.1-1.06581e-14,12.7 1.6,25.1 4.6,36.9-11,15.1-17.4,33.7-17.4,53.8 0,50 39.8,90.7 89.4,92.2h12.2c11.3,0 20.5-9.2 20.5-20.5v-0.1c0-11.3-9.2-20.5-20.5-20.5h-12.2c-27.4-1.5-48.9-24.8-48.3-52.3 0.2-10.4 3.6-20.4 9.7-28.8 7.1-9.8 9.4-22.3 6.4-34.1-2.2-8.6-3.3-17.6-3.3-26.6 0-59.1 48-107.1 107.1-107.1 36.9,0 70.8,18.7 90.6,49.9 11,17.4 33.2,23.9 51.9,15.3 7.9-3.7 16.4-5.5 25.2-5.5 44.3,0 58.6,40 59.8,54.9 1.4,15.9 11.8,29.6 26.9,35.1 20.2,7.3 33.7,26.7 33.7,48.2 0,28.2-23,51.2-51.2,51.2h-10.7c-11.3,0-20.5,9.2-20.5,20.5 0,11.3 9.2,20.5 20.5,20.5h9.8c51,0 93.4-41.8 93.1-92.8-0.3-39.7-25.6-73.4-60.8-86.2z"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
      name: 'Cloud',
      destinationType: 'cloud'
    }
  ];

  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}>
          <Grid item container>
            <SelectSourceOfConnection fcrChanged={fcrChanged} />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="body1"> Select Destination</Typography>
            </Grid>
            <Grid item container spacing={2}>
              {data?.map((item, index) => (
                <Grid item>
                  <div
                    style={{
                      height: '150px',
                      width: '150px',
                      borderStyle: 'solid',
                      cursor: 'pointer',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: index === isActive ? '#5a1f2a' : '',
                      marginRight: '10px'
                    }}
                    onClick={e =>
                      handleDataCenter(e, index, item.destinationType)
                    }
                    key={item.index}
                    className="item">
                    <Avatar
                      style={{
                        height: '50px',
                        width: '50px',
                        backgroundColor: '#5a1f2a'
                      }}>
                      {item.icon}
                    </Avatar>
                    <p
                      className="sss"
                      style={{ color: index === isActive ? 'white' : '' }}>
                      {item.name}
                    </p>
                  </div>
                </Grid>
              ))}
              <Grid item>
                <div
                  style={{
                    height: '150px',
                    width: '150px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    borderWidth: '1px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  className="test">
                  <Avatar
                    style={{
                      height: '50px',
                      width: '50px',
                      backgroundColor: '#5a1f2a'
                    }}>
                    <ShoppingCartIcon />
                  </Avatar>
                  <p className="sss">Market Place</p>
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    height: '150px',
                    width: '150px',
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    borderWidth: '1px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  className="test">
                  <Avatar
                    style={{
                      height: '50px',
                      width: '50px',
                      backgroundColor: '#5a1f2a'
                    }}>
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="48px"
                      height="48px"
                      viewBox="0 0 367.578 367.578">
                      <path
                        fill="white"
                        d="M281.541,97.751c0-53.9-43.851-97.751-97.751-97.751S86.038,43.851,86.038,97.751c0,44.799,30.294,82.652,71.472,94.159
	v144.668c0,4.026,1.977,9.1,4.701,12.065l14.514,15.798c1.832,1.993,4.406,3.136,7.065,3.136s5.233-1.143,7.065-3.136l14.514-15.798
	c2.724-2.965,4.701-8.039,4.701-12.065v-7.387l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.909c0-2.744-1.471-5.262-4.036-6.907
	l-14.591-9.363v-0.207l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.909c0-2.744-1.471-5.262-4.036-6.907l-14.591-9.363v-0.207
	l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.908c0-2.745-1.471-5.263-4.036-6.909l-14.591-9.363V191.91
	C251.246,180.403,281.541,142.551,281.541,97.751z M183.789,104.948c-20.985,0-37.996-17.012-37.996-37.996
	s17.012-37.996,37.996-37.996s37.996,17.012,37.996,37.996S204.774,104.948,183.789,104.948z"
                      />
                    </svg>
                  </Avatar>
                  <p className="sss">Enter Service Key</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="space-around" alignItems="center">
            <Grid item>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleNext}
                variant="contained"
                size="medium"
                color="primary"
                type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Step1;
