import { gql } from 'apollo-boost';

/*
  Internal dashboard stats
 */
export const INTERNAL_STATS = gql`
  query Dashboad {
    internalDashboardStats {
      totalOrgs
      orgsSubscribed
      totalSubscriptions
      totalUsers
    }
  }
`;

/*
  All service tickets
 */
export const ALL_SERVICE_TICKETS = gql`
  query Dashboard {
    dashboardRecentTickets {
      id
      ticketId
      title
      createdAt
      createdByName
      status
      priority
      updatedAt
      updatedByName
    }
  }
`;
