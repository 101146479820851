export const AreaChartData = [
  {
    name: '8:00 AM',
    val: 100,
    secVal: 100,
    thirdVal: 100
  },
  {
    name: '9:00 AM',
    val: 22,
    secVal: 32,
    thirdVal: 22
  },
  {
    name: '10:00 AM',
    val: 27,
    secVal: 28,
    thirdVal: 22
  },
  {
    name: '11:00 PM',
    val: 15,
    secVal: 37,
    thirdVal: 22
  },
  {
    name: '12:00 PM',
    val: 88,
    secVal: 62,
    thirdVal: 22
  },
  {
    name: '1:00 PM',
    val: 71,
    secVal: 75,
    thirdVal: 22
  },
  {
    name: '2:00 PM',
    val: 44,
    secVal: 62,
    thirdVal: 22
  },
  {
    name: '3:00 PM',
    val: 52,
    secVal: 78,
    thirdVal: 22
  },
  {
    name: '4:00 PM',
    val: 70,
    secVal: 50,
    thirdVal: 22
  },
  {
    name: '5:00 PM',
    val: 63,
    secVal: 76,
    thirdVal: 22
  }
];

export const mockTableData = [
  {
    acl: 'Odyssey',
    source: '245.55.0.211',
    destination: '138.243.74.195',
    protocol: 'IP',
    lastUpdated: '4/5/2020'
  },
  {
    acl: 'S60',
    source: '235.120.0.192',
    destination: '131.112.60.50',
    protocol: 'UDP',
    lastUpdated: '3/1/2020'
  },
  {
    acl: 'Ram Van 3500',
    source: '91.215.108.191',
    destination: '69.180.35.126',
    protocol: 'FTP',
    lastUpdated: '7/4/2019'
  },
  {
    acl: 'Tempo',
    source: '43.32.171.42',
    destination: '87.246.27.169',
    protocol: 'TCP',
    lastUpdated: '12/17/2019'
  },
  {
    acl: 'Liberty',
    source: '81.109.156.15',
    destination: '80.149.142.63',
    protocol: 'TCP',
    lastUpdated: '7/1/2019'
  }
];

export const mockCameraEventsData = [
  {
    id: 1,
    name: 'Camera 01',
    events: [
      {
        description: 'Intruder detected 1',
        time: '12:03:32'
      },
      {
        description: 'Intruder detected 2',
        time: '12:03:32'
      },
      {
        description: 'Intruder detected 3',
        time: '12:03:32'
      }
    ]
  },
  {
    id: 2,
    name: 'Camera 02',
    events: [
      {
        description: 'Intruder detected 5',
        time: '12:03:32'
      },
      {
        description: 'Intruder detected 6',
        time: '12:03:32'
      },
      {
        description: 'Intruder detected 7',
        time: '12:03:32'
      }
    ]
  }
];

export const mockMlData = [
  {
    id:1,
    name: 'Test Template 1',
    createdBy: '1234',
    createdByName:'Admin',
    createdDate: '4/5/2020',
    noOfCam: '1',
    priority: 'High'
  },
  {
    id:2,
    name: 'Test Template 2',
    createdBy: '1234',
    createdByName:'Admin',
    createdDate: '4/5/2020',
    noOfCam: '3',
    priority: 'Medium'
  },
  {
    id:3,
    name: 'Test Template 3',
    createdBy: '1234',
    createdByName:'Admin',
    createdDate: '4/5/2020',
    noOfCam: '4',
    priority: 'Low'
  },
  {
    id:4,
    name: 'Test Template 4',
    createdBy: '1234',
    createdByName:'Admin',
    createdDate: '4/5/2020',
    noOfCam: '2',
    priority: 'High'
  }
]

export const mockPlaceData = [
  {
      id: 1,
      name: 'Test Place 1',
      createdBy: '1234',
      createdByName: 'Admin',
      createdDate: '4/5/2020',
      noOfCam: '1',
      coordinates: '22.719568, 75.857727'
  },
  {
      id: 2,
      name: 'Test Template 2',
      createdBy: '1234',
      createdByName: 'Admin',
      createdDate: '4/5/2020',
      noOfCam: '3',
      coordinates: '22.719568, 75.857727'
  }
]

export const mockCameraData = [
  {
      id: 1,
      name: 'Test Camera 1',
      place: 'Test Place 1',
      onboardBy: '1234',
      onboardDate: '4/5/2020',
      attachedModel: '4',
      cameraStatus: 'Active'
  },
  {
      id: 2,
      name: 'Test Camera 2',
      place: 'Test Place 2',
      onboardBy: '12345',
      onboardDate: '4/6/2020',
      attachedModel: '3',
      cameraStatus: 'Active'
  },
  {
    id: 3,
    name: 'Test Camera 3',
    place: 'Test Place 2',
    onboardBy: '12345',
    onboardDate: '4/6/2020',
    attachedModel: '2',
    cameraStatus: 'Provisioning'
}
]

export const mockOrgEventData = [
    {
        id: 1,
        place: 'Test Place 1',
        camera: 'Test Cemera 1',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test',
    }, {
        id: 2,
        place: 'Test Place 2',
        camera: 'Test Cemera 2',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test 2',
    }
]
export const mockNotificationEventData = [
    {
        id: 1,
        place: 'Test Place 1',
        camera: 'Test Cemera 1',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test',
        acknowledge: 'Test 1',
    }, {
        id: 2,
        place: 'Test Place 2',
        camera: 'Test Cemera 2',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test 2',
        acknowledge: 'Test 2',
    }
]
export const mockPlaceEventData = [
    {
        id: 1,
        place: 'Test Place 1',
        camera: 'Test Cemera 1',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test',
    }, {
        id: 2,
        place: 'Test Place 2',
        camera: 'Test Cemera 2',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test 2',
    }
]
export const mockCameraEventData = [
    {
        id: 1,
        place: 'Test Place 1',
        camera: 'Test Cemera 1',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test',
    }, {
        id: 2,
        place: 'Test Place 2',
        camera: 'Test Cemera 2',
        eventDateTime: '4/5/2020 00:00:00',
        detectedModel: 'Admin',
        priority: 'High',
        imageSnap: 'Test 2',
    }
]

export const mockEdgeServerData = [
  {
    id: 1,
    server_name: 'Edge interface server',
    cpu: '53.20%',
    gpu: '52.20%',
    ram: '20.00%',
    firmware:'v 1.21',
    status:'Healthy',
    lastUpdated:'05 Minutes ago'
  },
  {
    id: 1,
    server_name: 'Edge interface 2',
    cpu: '53.20%',
    gpu: '52.20%',
    ram: '20.00%',
    firmware:'v 1.02',
    status:'Healthy',
    lastUpdated:'10 Minutes ago'
  },
  {
    id: 1,
    server_name: 'Edge interface 3',
    cpu: '51.20%',
    gpu: '46.20%',
    ram: '21.00%',
    firmware:'v 1.23',
    status:'Healthy',
    lastUpdated:'02 Minutes ago'
  }
]

export const mockInferenceModelData = [
  {
    id: 1,
    model_name: 'ANRP',
    source: '53.20%',
    placeholder: '52.20%',
    firmware:'v 1.21',
    status:'Active',
    lastUpdated:'05 Minutes ago'
  },
  {
    id: 1,
    model_name: 'Intruder Alert System',
    source: '53.20%',
    placeholder: '52.20%',
    firmware:'v 1.21',
    status:'Active',
    lastUpdated:'05 Minutes ago'
  },
  {
    id: 1,
    model_name: 'Traffic Pattern',
    source: '53.20%',
    placeholder: '52.20%',
    firmware:'v 1.21',
    status:'Active',
    lastUpdated:'05 Minutes ago'
  }
]

export const mockSystemLogData = [
  {
    date:'31 Jan 2020',
    data:[
      {
        id: 1,
        log_time: '12:23:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        id: 1,
        log_time: '12:00:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        id: 1,
        log_time: '01:04:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      }
    ]
  },
  {
    date:'01 Fab 2020',
    data:[
      {
        id: 1,
        log_time: '05:20:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        id: 1,
        log_time: '10:00:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        id: 1,
        log_time: '11:04:00',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      }
    ]
  },
]

export const mockMapData = [
  {
    latitude:22.719568,
    longitude:75.857727,
    name:'Indore'
  },
  {
    latitude:23.0225,
    longitude:72.5714,
    name:'Ahmedabad'
  },
  {
    latitude:19.0760,
    longitude:72.8777,
    name:'Mumbai'
  },
  {
    latitude:12.9716,
    longitude:77.5946,
    name:'Bangalore'
  }
]
