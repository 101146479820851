import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {FieldArray, Formik, Field, getIn} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateStoreFormModal({
                                  t,
                                  open,
                                  handleSaveStoreForm,
                                  handleClose,
                                  partsLoading,
                                  partsError,
                                  partsData,
                                  data,
                                  modalState
                              }) {
    let configuration;
    let allParts = [];
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    if (data && data.getSubscriptionById.sites && data.getSubscriptionById.sites.length) {
        configuration = (data.getSubscriptionById.sites[0].configuration && JSON.parse(data.getSubscriptionById.sites[0].configuration));
        configuration = {
            customer_name: configuration.filter(item => item.key === 'customer_name' || 'customerName')[0].value,
            product_code: configuration.filter(item => item.key === 'product_code' || 'CipPc')[0].value
        }
    }
    if (!partsLoading && !partsError) {
        if (partsData && partsData.getAllParts && partsData.getAllParts.length) {
            allParts = partsData.getAllParts.map(item => {
                return {
                    part_no: item.part_no,
                    description: item.part_description,
                    quantity: item.quantity
                }
            });
        }
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} Store Request From
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will store form information it to Nigeria.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        name: "",
                        customer: configuration && configuration.customer_name,
                        department: "",
                        location: "",
                        project_code: configuration && configuration.product_code,
                        circuit_id: "",
                        request_type: "",
                        others_to_be_notified: "",
                        line_manager: "",
                        parts: allParts && allParts.length ? allParts : [{
                            part_no: '',
                            description: '',
                            quantity: ''
                        }]
                    }}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        handleSaveStoreForm(values)
                        if (modalState.clearData) {
                            resetForm()
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        customer: Yup.string()
                            .required('Please Enter Customer Name'),
                        name: Yup.string()
                            .max(13, 'Phone must be 13 character!')
                            .required('Please Enter Name'),
                        department: Yup.string()
                            .required('Please Select department'),
                        location: Yup.string()
                            .required('Please Enter Location'),
                        project_code: Yup.string()
                            .required('Please Enter Project Code'),
                        circuit_id: Yup.string()
                            .required('Please Enter Circuit Id'),
                        request_type: Yup.string()
                            .required('Please Select Request Type'),
                        others_to_be_notified: Yup.string()
                            .required('Please enter Others Notified'),
                        line_manager: Yup.string()
                            .required('Please enter line manager'),
                        parts: Yup.array()
                            .of(
                                Yup.object().shape({
                                    part_no: Yup.string().required('Required'),
                                    description: Yup.string().required('Required'),
                                    quantity: Yup.string().required('Required')
                                })
                            )
                            .required('Must have parts') // these constraints are shown if and only if inner constraints are satisfied
                            .min(1, 'Minimum of 1 parts')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Spare/Store Request From
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.customer}
                                                    name={'customer'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.customer && touched.customer) && errors.customer}
                                                    placeholder={`${t('customer.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.name}
                                                    name={'name'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.name && touched.name) && errors.name}
                                                    placeholder={`${t('name.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <OutlinedSelect
                                                    val={values.department}
                                                    label={`${t('department.label')} *`}
                                                    name={'department'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={[{id: 'PMO', name: 'PMO'}]}
                                                />
                                                {(errors.department && touched.department) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.department}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.request_type}
                                                    name={'request_type'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.request_type && touched.request_type) && errors.request_type}
                                                    placeholder={`${t('request_type.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.location}
                                                    name={'location'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.location && touched.location) && errors.location}
                                                    placeholder={`${t('location.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.project_code}
                                                    name={'project_code'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.project_code && touched.project_code) && errors.project_code}
                                                    placeholder={`${t('project_code.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.circuit_id}
                                                    name={'circuit_id'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.circuit_id && touched.circuit_id) && errors.circuit_id}
                                                    placeholder={`${t('circuitId.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.others_to_be_notified}
                                                    name={'others_to_be_notified'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.others_to_be_notified && touched.others_to_be_notified) && errors.others_to_be_notified}
                                                    placeholder={`${t('others_to_be_notified.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.line_manager}
                                                    name={'line_manager'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.line_manager && touched.line_manager) && errors.line_manager}
                                                    placeholder={`${t('line_manager.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Parts
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FieldArray
                                                    name="parts"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.parts.map((parts, index) => (
                                                                <Grid key={index} container spacing={3}>
                                                                    {/** both these conventions do the same */}
                                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                        {(index !== 0) ? <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                            -
                                                                        </Button> : <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push({
                                                                                part_no: '',
                                                                                description: '',
                                                                                quantity: ''
                                                                            })}>
                                                                            +
                                                                        </Button>}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={parts.part_no}
                                                                            name={`parts[${index}].part_no`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `parts[${index}].part_no`)
                                                                                && getIn(touched, `parts[${index}].part_no`))
                                                                            && getIn(errors, `parts[${index}].part_no`)}
                                                                            placeholder={`${t('part_no.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={parts.description}
                                                                            name={`parts.${index}.description`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `parts[${index}].description`)
                                                                                && getIn(touched, `parts[${index}].description`))
                                                                            && getIn(errors, `parts[${index}].description`)}
                                                                            placeholder={`${t('description.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={parts.quantity}
                                                                            name={`parts.${index}.quantity`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `parts[${index}].quantity`)
                                                                                && getIn(touched, `parts[${index}].quantity`))
                                                                            && getIn(errors, `parts[${index}].quantity`)}
                                                                            placeholder={`${t('quantity.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
CreateStoreFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSaveStoreForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateStoreFormModal);
