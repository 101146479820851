import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ConfigurationPanel = ({
  t,
  data,
  handleUpdateAction,
  handleCreateButtonClick,
  reloadData,
  countryCodes
}) => {

    //let configTabs = [];
    return (
        <div>
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            /> */}
            <Typography variant="h6">{data ? `${data.service.name} Configuration`  : "Configuration"}</Typography>
        </div>
    );
};

ConfigurationPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default ConfigurationPanel;
