import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Unauthorized from '../../containers/FabricServices/Views/Unauthorized';

const ProtectedRoute = ({ children, roles, ...rest }) => {
  const [authorised, setAuthorised] = useState(true);
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const loggedInUserRoles = user?.realm_access?.roles;

    const roleExists = loggedInUserRoles.every(availRole =>
      roles.includes(availRole)
    );

    if (roleExists) {
      setAuthorised(true);
    } else {
      setAuthorised(false);
    }
  }, [roles, user]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated && authorised ? children : <Unauthorized />;
      }}
    />
  );
};

export default ProtectedRoute;
