import React from 'react';

import AccessControlApplication from './AccessControlApplication';
import KeysControlRegister from './KeysControlRegister';
import PersonSearchRegister from './PersonSearchRegister';
import RemoteControlRegister from './RemoteControlRegister';
import CustomTabs from '../../../../../../components/CustomTabs';

const SecurityPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex
}) => {
    let tabList = [];
    tabList.push(
        {
        label: "Access Control Application",
        children: (
            <>
                <AccessControlApplication
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Keys Control Register",
        children: (
            <>
                <KeysControlRegister
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Person Search Register",
        children: (
            <>
                <PersonSearchRegister
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
        {
        label: "Remote Control Register",
        children: (
            <>
                <RemoteControlRegister
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    );
  return (
    <CustomTabs 
    activeIndex={activeIndex} 
    tabs={tabList}
   />
  )
}

export default SecurityPanel