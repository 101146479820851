import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import AttchedCamera from '../AttchedCamera';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DNS from '@material-ui/icons/Dns';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PriorityPanel from '../PriorityPanel';

const MLTemplateItem = ({
  t,
  data,
  handleMenuItemClick,
  allowConfigure,
  assignCamerahandleOpen,
  handleAttechedCameraList
}) => {
    
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const assigncameraMenuItems = [
        { label: t('edit.label') },
        { label: t('delete.label') },
        { label: t('assignToCamera.label') }
    ];
  
    return (
        <TableRow key={data.id} >
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Icon
                        component={() => (
                            <DNS
                            color={'secondary'}
                            style={{ marginTop: '2px', marginRight: '6px' }}
                            />
                        )}
                        alt={'icon'}
                        />
                    </Grid>
                    <Grid>
                        <Typography variant={'subtitle1'} > 
                            {data.name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} > {data.created_by_name}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} >
                            {dateFormatterLLL(data.created_at)}                   
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography 
                        variant={'subtitle1'} 
                        style={{ cursor: data.noOfCam === 0 ? 'auto' :'pointer' }} 
                        onClick={()=> handleAttechedCameraList(data)}
                        disable={data.noOfCam === 0}
                        >
                            {data.noOfCam ? data.noOfCam : 0}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <PriorityPanel priority={data.priority} t={t}/>
                    </Grid>
                </Grid>
            </TableCell>
            {allowConfigure && 
                <TableCell>
                    <Grid >
                        <IconButton
                            disabled={!allowConfigure}
                            size={'small'}
                            disableRipple={true}
                            disableFocusRipple={true}
                            color={'secondary'}
                            onClick={evt => {
                                setAnchorEl(evt.currentTarget);
                                evt.stopPropagation();
                            }}
                        >
                            <MoreHoriz className={commonClasses.iconRegular} />
                        </IconButton>
                    </Grid>
                    {anchorEl && <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={assigncameraMenuItems}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, data, 'ml_template');
                            setAnchorEl(null);
                            if(elem.label === t('assignToCamera.label')){
                                assignCamerahandleOpen(data);
                            }
                        }}
                    />}
                </TableCell>
            }
        </TableRow>
    );
};

MLTemplateItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(MLTemplateItem);
