import React from 'react';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { marginGenerator } from '../../../../theme/utils';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  cardRt: {
    backgroundColor: '#E5E5E5',
  },
  cardArea: {
    padding: '10px'
  },
  icons: {
    fontSize: '50px',
    color: '#FF4952'
  },
  desc: {
    marginTop: '15px',
    fontSize: '17px',
    color: 'black'
  },
  cardContainer: {
    width: theme.spacing(23),
    height: theme.spacing(26),
    marginBottom: theme.spacing(1),
    backgroundColor: '#E5E5E5', 
    borderRadius: 10,
    cursor: 'pointer'
  },
  textContainer: {
    maxWidth: theme.spacing(20)
  }
}));

const ActionCardLayout = ({ icon, label, link }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const history = useHistory();
  return (
    <Grid item>
      <Grid
        className={classes.cardContainer}
        container
        item
        direction={'column'}
        justify={'center'}
        alignItems={'center'}
        onClick={() =>
          link ? history.push({ pathname: link, search: '?dashboard' }) : null
        }>
        <Grid item>
            <CardActionArea className={classes.cardArea}>
                <CardContent>
                    <img
                        src={icon}
                        className={commonClasses.iconExtraLarge}
                        alt={'statIcon'}
                    />
                    <Typography className={classes.desc}>
                        {label}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionCardLayout;
