import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateAccountPaths } from '../../routes/routePaths';
import CreateOrganization from './CreateOrganization';
import CreateUser from './CreateUser';

class CreateAccount extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={CreateAccountPaths.CreateOrg}
          component={CreateOrganization}
        />
        <Route
          exact={true}
          path={CreateAccountPaths.CreateUser}
          component={CreateUser}
        />
      </Switch>
    );
  }
}
export default CreateAccount;
