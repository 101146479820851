import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  HeatmapLayer,
  useLoadScript,
  Marker,
  InfoWindow,
  Autocomplete
} from '@react-google-maps/api';
import { CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  searchInput: {
    background: '#fff',
    top: 8,
    left: 12,
    minWidth: 270
  }
}));

const containerStyle = {
  width: '100%',
  height: '500px'
};

const options = {
  dissipating: true,
  maxIntensity: 10,
  radius: 30,
  opacity: 0.8,
  gradient: [
    'rgba(58, 58, 58, 0)',
    'rgba(58, 58, 58, 0.1)',
    'rgba(58, 58, 58, 0.2)',
    'rgba(58, 58, 58, 0.2)',
    'rgba(58, 58, 58, 0.4)',
    'rgba(58, 58, 58, 0.4)',
    'rgba(58, 58, 58, 0.6)',
    'rgba(58, 58, 58, 0.6)',
    'rgba(58, 58, 58, 0.8)',
    'rgba(58, 58, 58, 0.8)',
    'rgba(58, 58, 58, 1)'
  ]
};

const libraries = ['places', 'visualization'];

function SalesHeatMap({ locationsForSalesDashBoard }) {
  const classes = useStyles();

  const [hoveredData, setHoveredData] = useState(null);
  const [activeDataPoint, setActiveDataPoint] = useState(null);
  const [autocompleteRef, setAutocompleteRef] = useState(null);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  
  
  const [center, setCenter] = useState({
    lat: 13.1339,
    lng: 27.8493
  });

  useEffect(() => {

    const convertedData = locationsForSalesDashBoard.map((item, index) => {
      const key = `location_${index + 1}`;
      const data = item[key];
      return {
        geo_location: data.geo_location,
        top_selling_products: data.top_selling_products,
      };
    });
    setMarkerData(convertedData)
    // Convert the data to the format expected by HeatmapLayer
    const heatmapData = convertedData?.map(location => ({
      location: new window.google.maps.LatLng(
        parseFloat(location.geo_location?.lat),
        parseFloat(location.geo_location?.long)
      ),
      weight: location.top_selling_products?.reduce((sum, product) => sum + product['Sales Count'], 0),
    }));

    setHeatmapData(heatmapData);
  }, [locationsForSalesDashBoard]);



  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD1d45qiv804kgEGCiHHwyQFLXzIxaoroE',
    libraries: libraries
  });

  const handleMouseOver = (event, data, i) => {
    setHoveredData(data);
    setActiveDataPoint(i);
  };

  const handleMouseOut = () => {
    setHoveredData(null);
    setActiveDataPoint(null);
  };

  const onLoadAutoComplete = autocomplete => {
    setAutocompleteRef(autocomplete);
  };

  const onSearchPlaceChanged = () => {
    if (autocompleteRef !== null) {
      const selectedPlace = autocompleteRef.getPlace();
      if (
        selectedPlace &&
        selectedPlace.geometry &&
        selectedPlace.geometry.location
      ) {
        let lat = selectedPlace.geometry.location?.lat();
        let lng = selectedPlace.geometry.location?.lng();
        setClickedLatLng({ lat, lng });
        setCenter({ lat, lng });
      } else {
        setClickedLatLng(null);
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };



  return (
    <>
      {isLoaded ? (
        <GoogleMap
          onClick={e => setClickedLatLng(e.latLng.toJSON())}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={3}>
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={onSearchPlaceChanged}>
            <TextField
              className={classes.searchInput}
              id="standard-basic"
              placeholder="Search"
              variant="standard"
            />
          </Autocomplete>
          <HeatmapLayer data={heatmapData} options={options} />


            

          {markerData.map((location, index) => (
        <Marker
          key={index}
          position={{
            lat: parseFloat(location.geo_location?.lat),
            lng: parseFloat(location.geo_location?.long),
          }}
          onClick={() => {
            setSelectedLocation(location);
          }}
        />
      ))}

      {selectedLocation && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedLocation.geo_location?.lat),
            lng: parseFloat(selectedLocation.geo_location?.long),
          }}
          onCloseClick={() => {
            setSelectedLocation(null);
          }}
        >
          <div>
            <h2>Top Selling Products</h2>
            <ul>
              {selectedLocation.top_selling_products.map((product, index) => (
                <li key={index}>
                  {product['Product Name']} - Sales Count: {product['Sales Count']}
                </li>
              ))}
            </ul>
          </div>
        </InfoWindow>
      )}
        </GoogleMap>
      ) : (
        <p>
          <CircularProgress />
        </p>
      )}
    </>
  );
}

export default SalesHeatMap;
