import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import ClientTable from './ClientTable';
import { useAuth } from '../../../../contexts/AuthContext';

const CreateClientDashboard = () => {
  let history = useHistory();
  const { loggedInUserRoles } = useAuth();

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <br />
        <Grid item style={{ marginLeft: '10px' }}>
          <Button
            variant="outlined"
            disabled={
              loggedInUserRoles?.includes('fabric_readonly') === false
                ? false
                : true
            }
            onClick={() => history.push('/fabricService/createClient')}>
            Create Client
          </Button>
        </Grid>
        <Grid item>
          <ClientTable />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateClientDashboard;
