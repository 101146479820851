import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import PaymentHeader from '../../PaymentHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '0.7px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },

    input: {
        minWidth: 'auto'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    date: null,
    payee: "",
    voucherNo: "",
    chequeNo: "",
    details1: "",
    details2: "",
    amount1: "", 
    amount2:"", 
    amount3:"",
    amount4: "",
    amount5: "", 
    amount6: "",
    amount7: "",
    amount8: "",
    amount9: "",
    amount10: "",
    amount11: "",
    amount12: "",
    amount13: "",
    amount14: "",
    amount15: "",
    amount16: "",
    amount17: "",
    amount18: "",
    amount19: "",
    amount20: "",
    total1:"", 
    total2:"",
    total3: '',
    total4: '',
    code1:'',
    code2:'',
    code3:'',
    code4:'',
    code5:'',
    code6:'',
    code7:'',
    code8:'',
    description1: '',
    description2: '',
    description3: '',
    description4: '',
    description5: '',
    description6: '',
    description7: '',
    description8: '',
    ReceivedBy:"",
    ReceiverSign:"",
    ReceiverDate:null,
    PreparedBy:"",
    PreparedSign:"",
    PreparedDate:null,
    CheckedBy:"",
    CheckedSign:"",
    CheckedDate:null,
    AuthorizedBy:"",
    AuthorizedSign:"",
    AuthorizedDate:null,
    ApprovedBy:"",
    ApprovedSign:"",
    ApprovedDate:null    
};


// const tableItems = [
//     {
//         key: 'long_term',
//         value: 'Long Term',
//         label: "Permanent / Fixed Term (long term)",
//         description: "Permanent & FTC 12 months and above"
//     },
//     {
//         key: 'short_term',
//         value: 'Short term',
//         label: "Fixed Term (short term)",
//         description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
//     },
//     {
//         key: 'casual',
//         label: "Casual",
//         value: "Casual",
//         description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
//     }
// ]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}

function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function PaymentVoucherFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreatePaymentVoucherSubmit,
    handleOpenSignPanModal,
    paymentData,
    signatures
})  {
    const classes = useStyles();
    const [PaymentVoucherFormState, setPaymentVoucherFormState] = useState({ ...urInitFields });
    const [showHOD, setShowHOD] = useState(false);
    const [showMBAManager, setShowMBAManager] = useState(false);
    const [showSectionHead, setShowSectionHead] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setPaymentVoucherFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

   /**  const handleChange = (value, name) => {
        if(name === 'reasonForChange'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
            if (value.target.checked) {
                    newState.reasonForChange.push(value.target.value);
            }else{
                let index = newState.reasonForChange.indexOf(value.target.value);
                if (index !== -1) {
                    newState.reasonForChange.splice(index, 1);
                    }
                }
                return newState;
            });
        }  else {
            setURFormState({ ...urFormState, [name]: value });
        }
    } **/
    React.useEffect(() => {
        if (data && data.id) {
            const id = data.id;
            setPaymentVoucherFormState({...data, id});
        }
    }, [data]);


    const handleChange = (value, field) => {
        setPaymentVoucherFormState({ ...PaymentVoucherFormState, [field]: value });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
            <PaymentHeader />
            </DialogTitle>
            <DialogContent>
            <FormGroup row>

            <Grid container spacing={2} style={{ marginBottom: 20}} >
                <Grid item xs={12}>
                <h4 className={classes.blackBorderTitle} style={{textAlign: 'center',fontSize:'25px',borderBottom: '2.5px solid black' }}>PAYMENT VOUCHER</h4>                       
                </Grid>

                <Grid className={classes.blackBorder} item xs={6}>
                    <DateTimeSelector
                    inputStyle={{ minWidth: 250}}
                    label="Date"
                    dateFormat={"yyyy-MM-dd"}
                    value={PaymentVoucherFormState.date}
                    handleChange={newDate => handleChange(newDate, "date")}
                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder="Voucher Number"
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.voucherNo}
                    onChange={(evt) => handleChange(evt.target.value, "voucherNo")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder="Payee"
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.payee}
                    onChange={(evt) => handleChange(evt.target.value, "payee")}
                />
                </Grid>

                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder="Cheque Number"
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.chequeNo}
                    onChange={(evt) => handleChange(evt.target.value, "chequeNo")}
                />   
                </Grid>
            </Grid>
            </FormGroup>

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <FormGroup row>
                <Grid className={classes.blackBorder} item xs={8}>
                    <h5 className={classes.blackBorderTitle}>DETAILS OF EXPENDITURE</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>AMOUNT</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.details1}
                    onChange={(evt) => handleChange(evt.target.value, "details1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount1}
                    onChange={(evt) => handleChange(evt.target.value, "amount1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount2}
                    onChange={(evt) => handleChange(evt.target.value, "amount2")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.details2}
                    onChange={(evt) => handleChange(evt.target.value, "details2")}
                />   
                </Grid>
            
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount3}
                    onChange={(evt) => handleChange(evt.target.value, "amount3")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount4}
                    onChange={(evt) => handleChange(evt.target.value, "amount4")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={8}>
                    <h5 className={classes.blackBorderTitle}>TOTAL</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.total1}
                    onChange={(evt) => handleChange(evt.target.value, "total1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.total2}
                    onChange={(evt) => handleChange(evt.target.value, "total2")}
                />   
                </Grid>
            </FormGroup>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                <FormGroup row>  
                    <Grid className={classes.blackBorder} item xs={2}>
                        <h5 className={classes.blackBorderTitle}>GL CODE</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <h5 className={classes.blackBorderTitle}>ACCOUNT DESCRIPTION</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={4}>
                        <h5 className={classes.blackBorderTitle}>AMOUNT</h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code1}
                    onChange={(evt) => handleChange(evt.target.value, "code1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description1}
                    onChange={(evt) => handleChange(evt.target.value, "description1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount5}
                    onChange={(evt) => handleChange(evt.target.value, "amount5")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount6}
                    onChange={(evt) => handleChange(evt.target.value, "amount6")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code2}
                    onChange={(evt) => handleChange(evt.target.value, "code2")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description2}
                    onChange={(evt) => handleChange(evt.target.value, "description2")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount7}
                    onChange={(evt) => handleChange(evt.target.value, "amount7")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount8}
                    onChange={(evt) => handleChange(evt.target.value, "amount8")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code3}
                    onChange={(evt) => handleChange(evt.target.value, "code3")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description3}
                    onChange={(evt) => handleChange(evt.target.value, "description3")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount9}
                    onChange={(evt) => handleChange(evt.target.value, "amount9")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount10}
                    onChange={(evt) => handleChange(evt.target.value, "amount10")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code4}
                    onChange={(evt) => handleChange(evt.target.value, "code4")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description4}
                    onChange={(evt) => handleChange(evt.target.value, "description4")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount11}
                    onChange={(evt) => handleChange(evt.target.value, "amount11")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount12}
                    onChange={(evt) => handleChange(evt.target.value, "amount12")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code5}
                    onChange={(evt) => handleChange(evt.target.value, "code5")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description5}
                    onChange={(evt) => handleChange(evt.target.value, "description5")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount13}
                    onChange={(evt) => handleChange(evt.target.value, "amount13")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount14}
                    onChange={(evt) => handleChange(evt.target.value, "amount14")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code6}
                    onChange={(evt) => handleChange(evt.target.value, "code6")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description6}
                    onChange={(evt) => handleChange(evt.target.value, "description6")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount15}
                    onChange={(evt) => handleChange(evt.target.value, "amount15")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount16}
                    onChange={(evt) => handleChange(evt.target.value, "amount16")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code7}
                    onChange={(evt) => handleChange(evt.target.value, "code7")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description7}
                    onChange={(evt) => handleChange(evt.target.value, "description7")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount17}
                    onChange={(evt) => handleChange(evt.target.value, "amount17")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount18}
                    onChange={(evt) => handleChange(evt.target.value, "amount18")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.code8}
                    onChange={(evt) => handleChange(evt.target.value, "code8")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={6}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.description8}
                    onChange={(evt) => handleChange(evt.target.value, "description8")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount19}
                    onChange={(evt) => handleChange(evt.target.value, "amount19")}
                    
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.amount20}
                    onChange={(evt) => handleChange(evt.target.value, "amount20")}
                    
                />   
                </Grid>

                    <Grid className={classes.blackBorder} item xs={8}>
                    <h5 className={classes.blackBorderTitle}>TOTAL</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.total3}
                    onChange={(evt) => handleChange(evt.target.value, "total3")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={PaymentVoucherFormState.total4}
                    onChange={(evt) => handleChange(evt.target.value, "total4")}
                />   
                </Grid>
            </FormGroup>
            </Grid>             

            <FormGroup row> 
            <Grid container spacing={2} style={{ marginBottom: 20 }} >  
                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>SIGNATURES</h5>            
                </Grid>
            <Grid className={classes.blackBorder} item xs={12}>
            <FormGroup row>
            <Grid className={classes.blackBorder} item xs={4}>
            <Input
                type={'text'}
                placeholder={"Received By"}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={PaymentVoucherFormState.ReceivedBy}
                onChange={(evt) => handleChange(evt.target.value, "ReceivedBy")}
            //value={sensorFormState.deviceNo}
            />
            </Grid>

            <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
            <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("ReceiverSign")}
            >Signature <img src={SignIcon} width="26" height={26} alt=""/>
            </IconButton>
            </Grid>


            <Grid className={classes.blackBorder} item xs={4}>
                <DateTimeSelector
                    inputStyle={{ minWidth: 250 }}
                    label="Date"
                    dateFormat={"yyyy-MM-dd"}
                    value={PaymentVoucherFormState.ReceiverDate}
                    handleChange={newDate => handleChange(newDate, "ReceiverDate")}
                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                />
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <Input
                type={'text'}
                placeholder={"Prepared By"}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={PaymentVoucherFormState.PreparedBy}
                onChange={(evt) => handleChange(evt.target.value, "PreparedBy")}
            //value={sensorFormState.deviceNo}
            />
            </Grid> 

            <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
            <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("PreparedSign")}
            >Signature <img src={SignIcon} width="26" height={26} alt=""/>
            </IconButton>
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
                <DateTimeSelector
                    inputStyle={{ minWidth: 250 }}
                    label= "Date"
                    dateFormat={"yyyy-MM-dd"}
                    value={PaymentVoucherFormState.PreparedDate}
                    handleChange={newDate => handleChange(newDate, "PreparedDate")}
                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                />
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <Input
                type={'text'}
                placeholder={"Checked By"}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={PaymentVoucherFormState.CheckedBy}
                onChange={(evt) => handleChange(evt.target.value, "CheckedBy")}
            //value={sensorFormState.deviceNo}
            />
            </Grid>

            <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
            <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("CheckedSign")}
            >Signature <img src={SignIcon} width="26" height={26} alt=""/>
            </IconButton>
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
                inputStyle={{ minWidth: 250 }}
                label="Date"
                dateFormat={"yyyy-MM-dd"}
                value={PaymentVoucherFormState.CheckedDate}
                handleChange={newDate => handleChange(newDate, "CheckedDate")}
                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <Input
                type={'text'}
                placeholder={"Authorized By"}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={PaymentVoucherFormState.AuthorizedBy}
                onChange={(evt) => handleChange(evt.target.value, "AuthorizedBy")}
            //value={sensorFormState.deviceNo}
            />
            </Grid>

            <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
            <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("AuthorizedSign")}
            >Signature <img src={SignIcon} width="26" height={26} alt=""/>
            </IconButton>
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
                inputStyle={{ minWidth: 250 }}
                label="Date"
                dateFormat={"yyyy-MM-dd"}
                value={PaymentVoucherFormState.AuthorizedDate}
                handleChange={newDate => handleChange(newDate, "AuthorizedDate")}
                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <Input
                type={'text'}
                placeholder={"Approved By"}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={PaymentVoucherFormState.ApprovedBy}
                onChange={(evt) => handleChange(evt.target.value, "ApprovedBy")}
            //value={sensorFormState.deviceNo}
            />
            </Grid>

            <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
            <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("ApprovedSign")}
            >Signature <img src={SignIcon} width="26" height={26} alt=""/>
            </IconButton>
            </Grid>

            <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
                inputStyle={{ minWidth: 250 }}
                label="Date"
                dateFormat={"yyyy-MM-dd"}
                value={PaymentVoucherFormState.ApprovedDate}
                handleChange={newDate => handleChange(newDate, "ApprovedDate")}
                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
            />
            </Grid>

    </FormGroup>
    </Grid>               

    </Grid>
    </FormGroup>
</DialogContent>

<DialogActions>
<Grid container justify={'space-between'}>
<Button
    variant={'outlined'}
    onClick={() => {
    setPaymentVoucherFormState({ ...urInitFields });
    handleClose();
}}
>
{t('cancel.label')}
</Button>
<ButtonComponent
t={t}
onClick={() => {
    if(!PaymentVoucherFormState.date){
        Message.error(REQUIRED_ERROR);
        return null;
    }
    handleCreatePaymentVoucherSubmit(PaymentVoucherFormState);
}}
loading={modalState.btnLoading}
/>
</Grid>
</DialogActions>
</Dialog>
);
}

PaymentVoucherFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreatePaymentVoucherSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(PaymentVoucherFormModal);