import React from 'react';
import ReactEcharts from "echarts-for-react";

const LineChartWrapper = ({
  data,
  dataKeyXAxis,
  stackKeyNames,
  title
}) => {

    return (
        <div>
          <ReactEcharts
            option={{
              height: "80%",
              title: {
                text: title ? title : ''
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '20%',
                containLabel: true
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross'
                },
                extraCssText: 'width: 170px'
              },
              legend: {
                type:"scroll",
                data: stackKeyNames
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: dataKeyXAxis
              },
              dataZoom: [{
                type: 'inside',
                start: 0,
                end: 10
              }, {
                start: 0,
                end: 10,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '60%',
                handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                }
              }],
              yAxis: {
                type: 'value'
              },
              series: data
            }} />
        </div>
    );
};

export default LineChartWrapper;