import React from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import commonStyles from '../../../../../theme/commonStyles';
import { colors } from '../../../../../theme/colors';
import DNS from '@material-ui/icons/Dns';
import Icon from '@material-ui/core/Icon';

function CustomTable({ t, columns, list, handleItemClick }) {
  const commonClasses = commonStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index}>
                <Typography variant={'subtitle2'}>{item.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((rowItem, rowIndex) => (
            <TableRow
              style={{ cursor: 'pointer' }}
              key={rowIndex}
              onClick={() =>
                handleItemClick(rowItem.id, rowItem.name, rowItem.email)
              }>
              {columns.map((colItem, colIndex) => (
                <TableCell key={colIndex}>
                  {Array.isArray(colItem.key) ? (
                    !colItem.join ? (
                      <Grid className={commonClasses.column}>
                        {colItem.icon ? (
                          <Grid
                            item
                            style={{
                              border: `1px solid ${colors.grey[200]}`,
                              height: 52,
                              width: 52,
                              borderRadius: '50%',
                              padding: '1px',
                              marginRight: 16
                            }}>
                            <img
                              src={colItem.icon}
                              alt={'icon'}
                              className={commonClasses.iconExtraLarge}
                            />
                          </Grid>
                        ) : null}
                        <Grid item>
                          {colItem.key.map((item, index) => (
                            <Typography
                              key={index}
                              variant={index === 0 ? 'subtitle1' : 'subtitle2'}
                              color={index === 0 ? 'textPrimary' : 'initial'}>
                              {rowItem[item] || '-'}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography>
                        {rowItem[colItem.key[0]]} {rowItem[colItem.key[1]]}
                      </Typography>
                    )
                  ) : colItem.key === 'subscriptions' ||
                    colItem.key === 'deletedSubscriptions' ||
                    colItem.key === 'pendingSubscriptions' ? (
                    rowItem[colItem.key] && rowItem[colItem.key].length ? (
                      <Grid className={commonClasses.column}>
                        <Grid item>
                          <Icon
                            component={() => (
                              <DNS
                                color={'secondary'}
                                style={{ marginTop: '2px', marginRight: '6px' }}
                              />
                            )}
                            alt={'icon'}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'subtitle1'}
                            color={colItem.color}>
                            {rowItem[colItem.key][0].name
                              ? rowItem[colItem.key][0].name
                              : rowItem[colItem.key][0].service.name}{' '}
                            {rowItem[colItem.key].length - 1
                              ? `${t('and.label')} ${rowItem[colItem.key]
                                  .length - 1} ${t('others.label')}`
                              : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant={'subtitle1'} color={'textPrimary'}>
                        -
                      </Typography>
                    )
                  ) : (
                    <Grid className={commonClasses.column}>
                      {colItem.icon ? (
                        <Grid item>
                          <Icon component={() => colItem.icon} alt={'icon'} />
                        </Grid>
                      ) : null}
                      <Grid item>
                        <Typography
                          variant={'subtitle1'}
                          color={colItem.color || 'textPrimary'}>
                          {rowItem[colItem.key] || '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomTable;
