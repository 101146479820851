// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_CASH_ADVANCE_REQUEST_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MyRequestItem from "../MyRequestItem";
import { GET_USER_INFO } from '../../../../Profile/queries';
import CreateCashAdvanceRequestTeamModal from "../CreateCashAdvanceRequestTeamModal";
import CreateLineManagerTeamModal from "../CreateLineManagerTeamModal";
import { ADD_CASH_ADVANCE_REQUEST, UPLOAD_FILES, ADD_LINE_MANAGER_TEAM } from "../../mutations";
import { GET_ALL_USER_BY_ROLES, GET_ALL_USER_BY_ONLY_COUNTRY_ID_LIST } from '../../../../commonQueries';
import CompleteDetailModal from "../CompleteDetailModal";
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));

const MyRequest = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [CashAdvanceRequestTeam, setCashAdvanceRequestTeam] = useState(false);
    const [LineManagerTeam, setLineManagerTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [PreValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    const { loading: customerLoading, data: users } = useQuery(GET_ALL_USER_BY_ONLY_COUNTRY_ID_LIST, {
        fetchPolicy: 'no-cache',
        onError: error => {
            // console.log('Error get all users info', error);
        },
        onCompleted: data => {
            // console.log("users name : ", data);
        }
    });
    useEffect(() => {
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&project_manager=is_admin"
                }
            })
        }
        getAllUserList({
            variables: {
                role: USER_ROLES.INTEGRATION_TEAM
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
                getAllList({
                    variables: {
                        pageNumberOrUid: "project_manager=is_admin&page=1"
                    }
                })
            } else {
                getAllList({
                    variables: {
                        pageNumberOrUid: "page=1&current_user_uid=" + currentUserData.userInfo.id
                    }
                })
            }
        }
    }, [refreshTab, users, customerLoading]);

    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get integration users list in change control team', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            setAllUserList(parseData);

            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const { data: currentUserData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in chnage control team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            let full_ID = data.userInfo.id;
            // console.log("currentUserData name : ", data);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            if (!checkRoleResult) {
                getAllList({
                    variables: {
                        pageNumberOrUid: "page=1&current_user_uid=" + full_ID
                    }
                })
            }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "project_manager=is_admin&page=" + value + checkSearchFilterData
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "current_user_uid=" + currentUserData.userInfo.id + "&page=" + value
                }
            })
        }
    };
    
    const [getAllList] = useLazyQuery(GET_CASH_ADVANCE_REQUEST_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("getCashAdvanceRequest data : ", data);
            if (data && data.getCashAdvanceRequest) {
                let parseResult = JSON.parse(data.getCashAdvanceRequest.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get line manager list:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const columns = [
        {
            label: t('name.label'),
            key: "name"
        },
        {
            label: t('department.label'),
            key: "department"
        },
        {
            label: t('date.label'),
            key: "date"
        },
        {
            label: t('transactionType.label'),
            key: "transactionType"
        },
        {
            label: t('lineManager.label'),
            key: "lineManager"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('track.label') +' '+ t('status.label'),
            key: "ProjectTrack"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (data) => {
        // console.log("data change : ", data);
        setPreValuesOfForm(data);
        setLineManagerTeam({ open: true, btnLoading: false, clearData: true });
    }
    const [addCashAdvanceRequestTeam] = useMutation(ADD_CASH_ADVANCE_REQUEST, {
        onError: error => {
            setModalState({btnLoading: false, clearData: false });
            console.log('Error add change control form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addCashAdvanceRequest : ", data);
            setModalState({btnLoading: false, clearData: false });
            if (data && data.addCashAdvanceRequest) {
                if (JSON.parse(data.addCashAdvanceRequest.status) === true) {
                    Message.success('New cash advance request created successfully');
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addCashAdvanceRequest.teamData);
                    setModalState({ btnLoading: false, clearData: true });
                    setCashAdvanceRequestTeam(false);
                } else {
                    let message = JSON.parse(data.addCashAdvanceRequest.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSubmitCashAdvanceRequestTeamForm = (formData) => {
        setModalState({btnLoading: true, clearData: false });
        handleRefreshTab(false);
        addCashAdvanceRequestTeam({
            variables: {
                name: CurrentUserName,
                department: formData.department,
                date: formData.date,
                transaction_type: formData.transaction_type,
                total: formData.total,
                cash_advance: formData.cash_advance,
                cash_returned: formData.cash_returned,
                name_of_staff: formData.name_of_staff,
                bank_name: formData.bank_name,
                account_number: formData.account_number,
                users_comment: formData.users_comment,
                attach_receipt: formData.attach_receipt,
                work_flow_transaction: formData.work_flow_transaction,
                line_manager: formData.line_manager,
                line_manager_uid: formData.line_manager_uid,
                next_team_member_email: formData.next_team_member_email,
                current_user_uid: currentUserData.userInfo.id,
                current_user_email: currentUserData.userInfo.email,
            }
        })
    }
    const handleSubmitLineManagerTeamForm = (formData) => {
        setLineManagerTeam({ ...LineManagerTeam, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        addLineManagerRequestTeamSection({
            variables: {
                work_flow_management: PreValuesOfForm.uid,
                approval: formData.approval,
                comment: formData.comment,
                ehod_team: formData.ehod_team,
                ehod_uid: formData.ehod_uid,
                next_team_member_email: formData.next_team_member_email,
            }
        })
    }
    const [addLineManagerRequestTeamSection] = useMutation(ADD_LINE_MANAGER_TEAM, {
        onError: error => {
            setLineManagerTeam({ ...LineManagerTeam, btnLoading: false, clearData: false });
            console.log('Error add change control form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addLineManagerRequestTeam : ", data);
            setLineManagerTeam({ ...LineManagerTeam, btnLoading: false, clearData: false });
            if (data && data.addLineManagerRequestTeam) {
                if (JSON.parse(data.addLineManagerRequestTeam.status) === true) {
                    Message.success('New line manager request created successfully');
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addLineManagerRequestTeam.teamData);
                    setLineManagerTeam({ open: false, btnLoading: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addLineManagerRequestTeam.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({ btnLoading: true, clearData: false });
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({btnLoading: false, clearData: false });
            console.log('Error upload files in change control team', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: false });
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });

    const handleClickViewAllDetails = (data) => {
        let detailArray = [
            { label: t('name.label'), value: data.name, button: false },
            { label: t('department.label'), value: data.department, button: false },
            { label: t('date.label'), value: data.date, button: false },
            { label: t('transactionType.label'), value: data.transaction_type, button: false },
            { label: t('total.label'), value: data.total, button: false },
            { label: t('cashAdvance.label'), value: data.cash_advance, button: false },
            { label: t('cashReturnrDue.label'), value: data.cash_returned, button: false },
            { label: t('nameOfStaff.label'), value: data.name_of_staff, button: false },
            { label: t('bankName.label'), value: data.bank_name, button: false },
            { label: t('accountNumber.label'), value: data.account_number, button: false },
            { label: t('usersComment.label'), value: data.users_comment, button: false },
            { label: t('attachReceipt.label'), value: data.attach_receipt, button: true },
            { label: t('lineManager.label'), value: data.line_manager, button: false },
        ]
        let transaction = data.work_flow_transaction;
        for (let index = 0; index < transaction.length; index++) {
            detailArray.push(
                { label: t('description.label') + ' ' + index, value: transaction[index].description, button: false },
                { label: t('amount.label') + ' ' + index, value: transaction[index].amount, button: false })
        }
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: [] });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "project_manager=is_admin&page=1&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&current_user_uid=" + currentUserData.userInfo.id + "&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        }
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "project_manager=is_admin&page=1"
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&current_user_uid=" + currentUserData.userInfo.id
                }
            })
        }
        // getInvoice({
        //     variables: {
        //         pageNumberOrUid: "page=1&project_manager=is_admin"
        //     }
        // })
    };
    return (
        <>
            <Header title={t('myRequest.label')} />
            <Grid container>
                <Grid item xs={9}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid style={{ margin: '5px', float: 'right' }}>
                        <Button
                            color="secondary"
                            variant={'contained'}
                            onClick={() => {
                                setModalState({ btnLoading: false, clearData: true });
                                setCashAdvanceRequestTeam(true);
                            }}
                        >{t('add.label')}</Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <MyRequestItem
                                            data={item}
                                            key={index}
                                            handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems}
                                            CurrentUserUid={currentUserData && currentUserData.userInfo.id ? currentUserData.userInfo.id : ''}
                                            handleClickViewAllDetails={handleClickViewAllDetails} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                        `` </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateCashAdvanceRequestTeamModal
                    t={t}
                    open={CashAdvanceRequestTeam}
                    handleSubmitClick={handleSubmitCashAdvanceRequestTeamForm}
                    CurrentUserName={CurrentUserName}
                    handleUploadFiles={handleUploadFiles}
                    handleClose={() => {
                        setModalState({ btnLoading: false, clearData: true });
                        setCashAdvanceRequestTeam(false);
                    }}
                    modalState={modalState}
                    allUserList={users}
                    customerLoading={customerLoading} />
                <CompleteDetailModal
                    open={completeDetails.open}
                    t={t}
                    handleClose={() => {
                        setCompleteDetails({ ...completeDetails, open: false });
                    }}
                    data={completeDetails.data}
                    loading={completeDetails.loading}
                    previous_comment={completeDetails.previous_comment}
                    documents={completeDetails.documents}
                    projectDetail={completeDetails.projectDetail}
                />
                <CreateLineManagerTeamModal
                    t={t}
                    open={LineManagerTeam.open}
                    handleSubmitClick={handleSubmitLineManagerTeamForm}
                    handleClose={() => {
                        setLineManagerTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={LineManagerTeam}
                    allUserList={users}
                    customerLoading={customerLoading} />
            </Grid>
        </>
    );
};
export default withTranslation()(MyRequest);
