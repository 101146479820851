import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { marginGenerator } from '../../../../../../theme/utils';
import { dateFormatterLLL, getParam } from '../../../../../../utils';
import { TICKET_DATA } from '../../../../../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TicketStatus from '../CyberSecurityStatus';
import TicketPriority from '../../../../components/TicketPriority';
import PropTypes from 'prop-types';
import Avatar from '../../../../../../components/Avatar';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import UpdateCybersecurityAssignee from '../UpdateCybersecurityAssignee';
import { GET_ASSIGNEE_LIST } from '../../../../queries';

const useStyles = makeStyles(theme => ({
    para: {

    },
}));

const CyberSecurityDetailPanel = ({ requestData, t, reloadTickets }) => {
    const Id = getParam();
    const classes = useStyles();

    const [getAssigneeData, { data: assignee }] = useLazyQuery(GET_ASSIGNEE_LIST);

    React.useEffect(() => {
        getAssigneeData({
            variables: {
                ticketType: TICKET_DATA.SERVICETICKET
            }
        })
    }, [])

    return (
        <>
            <Grid container justify={'space-between'}>
                <Grid container item xs={9}>
                    <Grid item style={marginGenerator('mr-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('status.label')}
                        </Typography>
                        <TicketStatus t={t} status={requestData.status} />
                    </Grid>
                    {requestData.priority != null ? (
                        <Grid item style={{ marginLeft: '32px' }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('priority.label')}
                            </Typography>
                            <TicketPriority t={t} priority={requestData.priority} />
                        </Grid>
                    ) : null}
                    <Grid item style={{ marginLeft: '32px' }}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('incidentType.label')}
                        </Typography>
                        {requestData.incidentType}
                    </Grid>
                    <Grid item style={{ marginLeft: '32px' }}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('incidentTime.label')}
                        </Typography>
                        {requestData.incidentTime && requestData.incidentTime ?
                            dateFormatterLLL(requestData.incidentTime) : null}
                    </Grid>
                    <Grid item style={{ marginLeft: '32px' }}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('phone.label')}
                        </Typography>
                        {requestData.phone && requestData.phone ?
                            requestData.phone : null}
                    </Grid>

                    <Grid container item style={{ marginTop: 32 }}>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {requestData.assignee ? t('cyberAssign.label') : null}
                                <UpdateCybersecurityAssignee
                                    reloadTickets={reloadTickets}
                                    assigneeList={assignee?.assignee}
                                    requestId={requestData.requestId}
                                    id={Id}
                                />
                            </Typography>
                            {requestData.assignee ? requestData.assignee : null}
                        </Grid>
                    </Grid>

                    <Grid container item style={{ marginTop: 32 }}>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('incidentDetected.label')}
                            </Typography>
                            {requestData.incidentDetected}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('otherNotified.label')}
                            </Typography>
                            {requestData.notified === "No" ? 'No one notified' : requestData.notifiedDesc}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('containment.label')}
                            </Typography>
                            {requestData.containment === "No" ? 'No containment measures made' : requestData.containmentDesc}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('impactedService.label')}
                            </Typography>
                            {requestData.impactedService === "No" ? 'No service impacted' : requestData.impactedServiceDesc}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('attackVendor.label')}
                            </Typography>
                            {requestData.attackVendor === "No" ? 'Do not know how the attack was made' : requestData.attackVendorDesc}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('infoImpact.label')}
                            </Typography>
                            {requestData.infoImpact === "No" ? 'No information breached' : requestData.infoImpactDesc}
                        </Grid>
                        <Grid item xs={12} style={{ ...marginGenerator('mr-16'), ...marginGenerator('mt-32') }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('otherInfo.label')}
                            </Typography>
                            {requestData.otherImpact === "No" ? 'Nothing' : requestData.otherImpactDesc}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={3} direction={'column'}>
                    {requestData && requestData.createdByName && <Grid item>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('createdBy.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={requestData.createdByName} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {requestData.createdByName}
                            </Typography>
                        </Grid>
                    </Grid>}
                    {requestData && requestData.updatedAt && <Grid item style={marginGenerator('mt-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('ticketupdatedOn.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={dateFormatterLLL(requestData.updatedAt)} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {dateFormatterLLL(requestData.updatedAt)}
                            </Typography>
                        </Grid>
                    </Grid>}
                    {requestData && requestData.updatedByName && <Grid item style={marginGenerator('mt-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('updatedBy.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={requestData.updatedByName} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {requestData.updatedByName}
                            </Typography>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </>
    );
};

CyberSecurityDetailPanel.propTypes = {
    requestData: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default CyberSecurityDetailPanel;
