import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import { TicketingSystem } from '../../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import Header from '../../../components/Header';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { convertUTCDateToLocalDate, dateFormatterLLL } from '../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  INCIDENT_BY_REGION,
  INCIDENT_STATUS_POC_STATS,
  INCIDENT_BY_SUBJECT,
  TOP_10_INCIDENT_TYPE,
  TOP_10_INCIDENT_BY_MONTH,
  INCIDENT_BY_FISCAL_YEAR,
  TOP5_INCIDENT_BY_FISCAL_YEAR,
  TOP5_INCIDENT_GROUP_BY_FISCAL_YEAR,
  GET_ALL_SERVICES_BY_COUNTRY,
  NEW_CUSTOMER_AVAILABILITY_REPORT,
  GET_ALL_ENGINEERS_STATISTIC
} from './queries';
import { COUNTRY_LIST } from '../../../utils/constants';
import { GET_USER_LIST, GET_ALL_SUBSCRIPTION_LIST } from '../queries';
import Button from '@material-ui/core/Button';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { colors } from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PieChartWrapper from '../../../components/Charts/PieChartWrapper';
import BarChartWrapper from '../../../components/Charts/BarChartWrapper';
import PieChartWrapperScroll from '../../../components/Charts/CsocPieChartWrapper';
import BarMultiChartWrapper from '../../../components/Charts/BarMultiChartWrapper';
import BarMultiChartWrapperTwo from '../../../components/Charts/BarMultiChartWrapperTwo';
import CustomTable from '../components/Table';
import ReportTable from '../components/ReportTable';
import PaginationTable from '../components/PaginationTable';
import FaultTable from '../components/FaultTable';
import FinencialYearTable from '../components/FinencialYearTable';
import EngineerReportTable from '../components/EngineerReportTable';
import { SUBSCRIPTION_STATUS, TICKET_DATA } from '../../../utils/constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedSelect from '../../../components/OutlinedSelect';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Dashboard },
  { label: 'analytics.label', link: TicketingSystem.Analytics }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftSide: {
    display: 'flex'
  },
  exportDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  styles: {
    padding: '15px 20px 15px 20px',
    border: '2px solid #1b1818',
    borderRadius: '10px',
    marginRight: '100px',
    marginLeft: '25px'
  },
}));

export const IncidentStatusPOC = ({ t, data }) => {
  return (
    <div>
      <PieChartWrapper data={data} />
    </div>
  );
};

let ticketCategory = [
  TICKET_DATA.SERVICETICKET,
  TICKET_DATA.INTERNALTICKET
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{children}</Typography>
          </Grid>
        </Grid>
      </Box>
    )}
  </div>
  
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Analytics = ({ t }) => {
  const classes = useStyles();
  const country = localStorage.getItem('country');
  const [incidentStatusPoc, setIncidentStatusPoc] = useState([]);
  const [incidentBySubject, setIncidentBySubject] = useState([]);
  const [incidentByRegion, setIncidentByRegion] = useState([]);
  const [availabilityReportData, setAvailabilityReportData] = useState([]);
  const [availabilityReportOrg, setAvailabilityReportOrg] = useState([]);
  const [availabilityReportMonthYear, setAvailabilityReportMonthYear] = useState([]);
  const [top10Incident, settop10Incident] = useState([]);
  const [incidentFiscalYear, setIncidentFiscalYear] = useState([]);
  const [top5IncidentFiscalYear, setTop5IncidentFiscalYear] = useState([]);
  const [top5CategoryFiscalYear, setTop5CategoryFiscalYear] = useState([]);
  const [top10IncidentByMonth, settop10IncidentByMonth] = useState([]);
  const [countryServiceList, setCountryServiceList] = useState([]);
  const [allEngineerStatisticData, setAllEngineerStatisticData] = useState([])
  const [showIncidentStatusPoc, setShowIncidentStatusPoc] = useState(true);
  const [showIncidentBySubject, setShowIncidentBySubject] = useState(true);
  const [showIncidentByRegion, setShowIncidentByRegion] = useState(true);
  const [showAvailabilityReport, setShowAvailabilityReport] = useState(true);
  const [showTop10Incident, setShowTop10Incident] = useState(true);
  const [showIncidentFiscalYear, setShowIncidentFiscalYear] = useState(true);
  const [showTop5IncidentFiscal, setShowTop5IncidentFiscal] = useState(true);
  const [showTop5CategoryFiscal, setShowTop5CategoryFiscal] = useState(true);
  const [showIncidentByMonth, setShowIncidentByMonth] = useState(true);
  const [showCountryServiceList, setShowCountryServiceList] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [engineerStartDate, setengineerStartDate] = useState(new Date());
  const [engineerEndDate, setengineerEndDate] = useState(new Date());
  const [monthYear, setMonthYear] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = React.useState(1);
  const [regionPage, setRegionPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [reportCustomer, setReportCustomer] = useState({});
  const [selectedIncident, setSelectedIncident] = useState('');
  const [value, setValue] = React.useState('');
  const [totalCountRegion, setTotalCountRegion] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [engineerBtnLoading, setEngineerBtnLoading] = React.useState(false);
  const [engineerDataLoading, setEngineerDataLoading] = React.useState(false);
  const [monthlyReport, setMonthlyReport] = React.useState(true);
  const [ticketType, setTicketType] = useState('');
  const [tableData,setTableData] = useState()
  const [
    availabilityReportLoading,
    setAvailabilityReportLoading
  ] = React.useState(false);
  const [incidentLoading, setIncidentLoading] = React.useState(true);
  const [incidentRegionLoading, setIncidentRegionLoading] = React.useState(
    true
  );
  const [countryServiceLoading, setCountryServiceLoading] = useState([]);
  const [top10IncidentLoading, setTop10IncidentLoading] = React.useState(true);
  const [incidentFiscalLoading, setIncidentFiscalLoading] = React.useState(
    true
  );

  const [fetchOrg, setfetchOrg] = useState([])
  const [
    top5IncidentFiscalYearLoading,
    setTop5IncidentFiscalYearLoading
  ] = React.useState(true);
  const [
    top5CategoryFiscalYearLoading,
    setTop5CategoryFiscalYearLoading
  ] = React.useState(true);
  const [
    top10IncidentMonthLoading,
    setTop10IncidentMonthLoading
  ] = React.useState(true);
  const [searchValue, setSearchValue] = useState();
  const [orgoptions, setorgOptions] = useState([]);
  const [CustomersData, setCustomersData] = useState([]);
  const [platform, setPlatform] = useState();
  const [circuitId, setCircuitId] = useState();
  const [siteName, setSiteName] = useState();

  const [getOrgName, { loading: customerLoading, data: users}] = useLazyQuery(
    GET_USER_LIST
  );
  const DEBOUNCE_DELAY = 1000; // Adjust the debounce delay as needed (in milliseconds)
  const debounce = (func, delay) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleSearch = (value) => {
    setSearchValue(value) 
    // Simulate an API call (remove this in your actual implementation)
   
  };

    const delayedHandleSearch = debounce(handleSearch, DEBOUNCE_DELAY);

   
  // useEffect(() => {
  //   getTop5CategoryByFiscalYear();
  //   getTop5IncidentByFiscalYear();
  //   getIncidentByFiscalYear();
  //   getIncidentStatus();
  //   getIncidentStatusBySubject({
  //     variables: {
  //       page: page
  //     }
  //   });
  //   getIncidentByRegion({
  //     variables: {
  //       page: regionPage
  //     }
  //   });
  //   getTop10Incidents();
  //   getTop10IncidentsByMonth();
  //   getServiceListByCountry();
  // }, []);


  useEffect(() => {
    if (searchValue) {
      getOrgName({
        variables: {
          orgName: searchValue
        }
      });
    } else {
      setorgOptions([]);
    }
  }, [searchValue]);


  useEffect(() => {
    if (users) {

     
      // Process the data returned from the API and update the options state
      const processedOptions = users.usersByCountryId?.map((user) => ({
        organisations: [
          {
            name: user.organisations[0].name,
            id: user.organisations[0].id,
            platform: user.platform,
            customer:user.id,
            circuitId:user.CircuitId,
            customerName:user.customerName
          }
        ]
      }));
      setorgOptions(processedOptions);
      setCustomersData(users.usersByCountryId)
    
    }
  }, [users]);

  // const { loading: customerLoading, data: users } = useQuery(GET_USER_LIST);


  // const UsersListByOrganisation = users && users.usersByCountryId && users.usersByCountryId.length &&
  //   users.usersByCountryId.filter(item => {
  //     return item.organisations && item.organisations.length
  //   })
   
 

 


 
    



    

 
    // console.log(UsersListByOrganisation,"UsersListByOrganisation")

  const [getIncidentByRegion] = useLazyQuery(INCIDENT_BY_REGION, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.incidentByRegion.data && data.incidentByRegion.data.length) {
        setIncidentByRegion(data.incidentByRegion.data);
        setTotalCountRegion(data.incidentByRegion.total);
      }
      setIncidentRegionLoading(false);
    }
  });

  const [getAvailabilityReport] = useLazyQuery(NEW_CUSTOMER_AVAILABILITY_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
     
      if(!data.newcustomerAvailabilityReport?.data || data.newcustomerAvailabilityReport?.data?.length===0){
        setAvailabilityReportLoading(false);
        setAvailabilityReportData();
      setTableData() 
      return;
      }
      // setAvailabilityReportData((data.newcustomerAvailabilityReport?.data)?.slice(0,10));
      setAvailabilityReportData(data.newcustomerAvailabilityReport?.data);
      setTableData(data.newcustomerAvailabilityReport?.data)
     setAvailabilityReportMonthYear(data.newcustomerAvailabilityReport?.monthYear);
     setPlatform(data.newcustomerAvailabilityReport?.platform)
     setCircuitId(data.newcustomerAvailabilityReport?.circuitId)
     setSiteName(data.newcustomerAvailabilityReport?.siteName)
      setAvailabilityReportOrg(data.newcustomerAvailabilityReport?.organisationName);
      setAvailabilityReportLoading(false);
    }
  });
  

  const [getIncidentStatus] = useLazyQuery(INCIDENT_STATUS_POC_STATS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.incidentStatusPOC) {
        setIncidentStatusPoc(data.incidentStatusPOC);
      }
      setIncidentLoading(false);
    }
  });

  const [getIncidentStatusBySubject] = useLazyQuery(INCIDENT_BY_SUBJECT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.incidentBySubject.data && data.incidentBySubject.data.length) {
        setIncidentBySubject(data.incidentBySubject.data);
        setTotalCount(data.incidentBySubject.total);
      }
      setLoading(false);
    }
  });

  const [getTop10Incidents] = useLazyQuery(TOP_10_INCIDENT_TYPE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.top1oIncidentType && data.top1oIncidentType.length) {
        settop10Incident(data.top1oIncidentType);
      }
      setTop10IncidentLoading(false);
    }
  });

  const [getServiceListByCountry] = useLazyQuery(GET_ALL_SERVICES_BY_COUNTRY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.servicesByCountry?.data && data?.servicesByCountry?.data?.length) {
        setCountryServiceList(data.servicesByCountry.data);
      }
      setCountryServiceLoading(false);
    }
  });

  const [getTop10IncidentsByMonth] = useLazyQuery(TOP_10_INCIDENT_BY_MONTH, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.top1oIncidentByMonth && data.top1oIncidentByMonth.length) {
        settop10IncidentByMonth(data.top1oIncidentByMonth);
      }
      setTop10IncidentMonthLoading(false);
    }
  });

  const [getTop5IncidentByFiscalYear] = useLazyQuery(
    TOP5_INCIDENT_BY_FISCAL_YEAR,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data.top5FaultTypeFiscalYear && data.top5FaultTypeFiscalYear.data) {
          setTop5IncidentFiscalYear(
            JSON.parse(data.top5FaultTypeFiscalYear.data)
          );
        }
        setTop5IncidentFiscalYearLoading(false);
      }
    }
  );

  const [getTop5CategoryByFiscalYear] = useLazyQuery(
    TOP5_INCIDENT_GROUP_BY_FISCAL_YEAR,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (
          data.top5CategoryTypeFiscalYear &&
          data.top5CategoryTypeFiscalYear.data
        ) {
          setTop5CategoryFiscalYear(
            JSON.parse(data.top5CategoryTypeFiscalYear.data)
          );
        }
        setTop5CategoryFiscalYearLoading(false);
      }
    }
  );

  const [getIncidentByFiscalYear] = useLazyQuery(INCIDENT_BY_FISCAL_YEAR, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data.totalIncidentByFiscalYear &&
        data.totalIncidentByFiscalYear.length
      ) {
        setIncidentFiscalYear(data.totalIncidentByFiscalYear);
      }
      setIncidentFiscalLoading(false);
    }
  });

  const [getAllEngineerStatistics] = useLazyQuery(GET_ALL_ENGINEERS_STATISTIC, {
    fetchPolicy: 'no-cache',
    onError: e => {
      console.log("Error", e)
    },
    onCompleted: res => {
      if (res && res.getAllEngineerStatistics && res.getAllEngineerStatistics.length) {
        setEngineerBtnLoading(false)
        setEngineerDataLoading(false)
        setAllEngineerStatisticData(res.getAllEngineerStatistics)
      }
    }
  })

  let top10IncidentByMonthData =
    top10IncidentByMonth &&
    top10IncidentByMonth.length &&
    top10IncidentByMonth.map(data => {
      return { name: data.name, value: data.TotalIncident };
    });

  const handlePageChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getIncidentStatusBySubject({
      variables: {
        page: value
      }
    });
  };

  const handleRegionPageChange = (event, value) => {
    setRegionPage(value);
    setIncidentRegionLoading(true);
    getIncidentByRegion({
      variables: {
        page: value
      }
    });
  };

  const incidentStatusPOCTotal =
    incidentStatusPoc &&
    incidentStatusPoc.IncidentCompleted +
    incidentStatusPoc.IncidentInProgress +
    incidentStatusPoc.IncidentNotStarted;

  const incidentStatusPOCData = [
    { name: 'Incident Completed', value: incidentStatusPoc.IncidentCompleted },
    {
      name: 'Incident In Progress',
      value: incidentStatusPoc.IncidentInProgress
    },
    {
      name: 'Incident Not Started',
      value: incidentStatusPoc.IncidentNotStarted
    }
  ];

  const incidentStatusPOCColumn = [
    { name: 'Incident Status POC' },
    { name: 'Quantity' }
  ];
  const incidentBySubjectColumn = [
    { name: 'Fault Type' },
    { name: 'Not Started' },
    { name: 'In Progress' },
    { name: 'Completed' },
    { name: 'Total' }
  ];

  const availabilityReportColumn = [
    { name: 'Out of service/FT call' },
    { name: 'On service/FT cleared' },
    { name: 'Time To Restore' },
    { name: 'Target meet?' },
    { name: 'Fault / FT #' },
    { name: 'Fault Type' },
    { name: 'Included In' },
    { name: 'Comment' }
  ];

  const top10IncidentColumn = [{ name: 'Fault Type' }, { name: 'Quantity' }];
  const top5IncidentFiscalYearColumn = [
    { name: 'Fault Type' },
    { name: 'Apr', key: 'April' },
    { name: 'May', key: 'May' },
    { name: 'June', key: 'June' },
    { name: 'July', key: 'July' },
    { name: 'Aug', key: 'August' },
    { name: 'Sept', key: 'September' },
    { name: 'Oct', key: 'October' },
    { name: 'Nov', key: 'November' },
    { name: 'Dec', key: 'December' },
    { name: 'Jan', key: 'January' },
    { name: 'Feb', key: 'February' },
    { name: 'Mar', key: 'March' }
  ];
  const top5CategoryFiscalYearColumn = [
    { name: 'Fault Group' },
    { name: 'Apr', key: 'April' },
    { name: 'May', key: 'May' },
    { name: 'June', key: 'June' },
    { name: 'July', key: 'July' },
    { name: 'Aug', key: 'August' },
    { name: 'Sept', key: 'September' },
    { name: 'Oct', key: 'October' },
    { name: 'Nov', key: 'November' },
    { name: 'Dec', key: 'December' },
    { name: 'Jan', key: 'January' },
    { name: 'Feb', key: 'February' },
    { name: 'Mar', key: 'March' }
  ];

  const engineerTableColumn = [
    { name: "Sr. No." },
    { name: "Engineer Name" },
    { name: "Open Tickets" },
    { name: "Closed Tickets" },
    { name: "Total Tickets" }
  ]

  const servicesByCountryColumn = [
    { name: 'Country Name' },
    { name: 'Total Service' }
  ];

  let incidentFiscalBarData = { source: [] };

  let months = [
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March'
  ];

  let parentObjKeys = Object.keys(top5IncidentFiscalYear);
  incidentFiscalBarData.source.push(['month'].concat(parentObjKeys));

  months.forEach(function (month) {
    let arr = [month];
    parentObjKeys.forEach(function (key) {
      arr.push(top5IncidentFiscalYear[key][month]);
    });
    incidentFiscalBarData.source.push(arr);
  });

  let categoryFiscalBarData = { source: [] };
  let parentCatObjKeys = Object.keys(top5CategoryFiscalYear);
  categoryFiscalBarData.source.push(['months'].concat(parentCatObjKeys));
  months.forEach(function (month) {
    let arr = [month];
    parentCatObjKeys.forEach(function (key) {
      arr.push(top5CategoryFiscalYear[key][month]);
    });
    categoryFiscalBarData.source.push(arr);
  });


  let AllEngineerStatisticBarData = { source: [] };
  let allEngineers = allEngineerStatisticData && allEngineerStatisticData.map(item => item.engineer)
  AllEngineerStatisticBarData.source.push(['engineer', 'Open Ticket', 'Closed Ticket']);
  allEngineers.forEach(engineer => {
    let data = []
    allEngineerStatisticData.forEach(item => {
      if (item.engineer == engineer) {
        data.push(item.engineer, item.openTicket, item.closedTicket)
      }
    })
    AllEngineerStatisticBarData.source.push(data)
  })



  const allIncidents = [
    { name: t('incidentPoc.label') },
    { name: t('incidentBySubject.label') },
    { name: t('incidentByRegion.label') },
    { name: t('topFaults.label') },
    { name: t('topFaultsByMonth.label') },
    { name: t('incidentFiscalYear.label') },
    { name: t('top5Fault.label') },
    { name: t('top5Category.label') },
    { name: t('circuitByRegion.label') },
    { name: t('availabilityReport.label') }
  ];

  const handleFilterStats = () => {
    if (selectedIncident === '') {




      setShowCountryServiceList(true);
      setCountryServiceLoading(true);
      setShowIncidentStatusPoc(true);
      setShowIncidentBySubject(true);
      setShowIncidentByRegion(true);
      setShowTop10Incident(true);
      setShowIncidentFiscalYear(true);
      setShowTop5IncidentFiscal(true);
      setShowTop5CategoryFiscal(true);
      setShowIncidentByMonth(true);
      setShowCountryServiceList(true);
      setShowAvailabilityReport(true);
      getIncidentStatus({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      // getIncidentStatusBySubject({
      //   variables: {
      //     page: page,
      //     customer:
      //       selectedCustomer && selectedCustomer.id && selectedCustomer.id,
      //     startDate: startDate,
      //     endDate: endDate
      //   }
      // });
      getIncidentByRegion({
        variables: {
          page: regionPage,
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getTop10Incidents({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getTop10IncidentsByMonth({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getIncidentByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getTop5IncidentByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getTop5CategoryByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      getServiceListByCountry({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
    
    
    
    }
    if (selectedIncident === 'Incidents Status POC') {
      setIncidentLoading(true);
      getIncidentStatus({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowAvailabilityReport(false);
      setShowIncidentStatusPoc(true);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }

    if (selectedIncident === 'Incidents By Subject') {
      setLoading(true);
      getIncidentStatusBySubject({
        variables: {
          page: page,
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowAvailabilityReport(false);
      setShowIncidentBySubject(true);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Incident By Region') {
      setIncidentRegionLoading(true);
      getIncidentByRegion({
        variables: {
          page: regionPage,
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(true);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowAvailabilityReport(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Top 10 Faults By Type') {
      setTop10IncidentLoading(true);
      getTop10Incidents({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowAvailabilityReport(false);
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(true);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Top 10 Faults of Current Month') {
      setTop10IncidentMonthLoading(true);
      getTop10IncidentsByMonth({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowAvailabilityReport(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowIncidentByMonth(true);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Finential Year Incident Stats') {
      setIncidentFiscalLoading(true);
      getIncidentByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowAvailabilityReport(false);
      setShowIncidentFiscalYear(true);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Financial Year Top 5 Fault') {
      setTop5IncidentFiscalYearLoading(true);
      getTop5IncidentByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowAvailabilityReport(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(true);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Financial Year Top 5 Fault Category') {
      setTop5CategoryFiscalYearLoading(true);
      getTop5CategoryByFiscalYear({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });

      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowAvailabilityReport(false);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowTop5CategoryFiscal(true);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
    if (selectedIncident === 'Circuit By Region') {
      setCountryServiceLoading(true);
      getServiceListByCountry({
        variables: {
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowAvailabilityReport(false);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(true);
    }
    if (selectedIncident === 'Availability Report By Customer') {
      setCountryServiceLoading(true);
      setShowIncidentStatusPoc(false);
      setShowIncidentBySubject(false);
      setShowIncidentByRegion(false);
      setShowTop10Incident(false);
      setShowIncidentFiscalYear(false);
      setShowTop5IncidentFiscal(false);
      setShowAvailabilityReport(true);
      setShowTop5CategoryFiscal(false);
      setShowIncidentByMonth(false);
      setShowCountryServiceList(false);
    }
  };

  const handleFilterReset = () => {
    setSelectedCustomer({});
    setSelectedIncident('');
    setStartDate(new Date());
    setEndDate(new Date());
    getTop5CategoryByFiscalYear();
    getTop5IncidentByFiscalYear();
    getIncidentByFiscalYear();
    getIncidentStatus();
    getIncidentStatusBySubject({
      variables: {
        page: page
      }
    });
    getIncidentByRegion({
      variables: {
        page: regionPage
      }
    });
    getTop10Incidents();
    getTop10IncidentsByMonth();
    getServiceListByCountry();
    setShowCountryServiceList(true);
    setCountryServiceLoading(true);
    setShowIncidentStatusPoc(true);
    setShowIncidentBySubject(true);
    setShowIncidentByRegion(true);
    setShowTop10Incident(true);
    setShowIncidentFiscalYear(true);
    setShowTop5IncidentFiscal(true);
    setShowTop5CategoryFiscal(true);
    setShowIncidentByMonth(true);
    setShowCountryServiceList(true);
    setShowAvailabilityReport(true);
  };

  const handleRadioChange = event => {
    if (event.target.value === 'month') {
      setValue(event.target.value);
      setMonthlyReport(true)
      setAvailabilityReportData([])
    }
    if (event.target.value === 'week') {
      setValue(event.target.value);
      setMonthlyReport(false)
      setAvailabilityReportData([])
    }
  };
  const handleChangeReportCustomer = (event, newValue) => {
    // console.log("newValue:", newValue);
  
    if (newValue === null) {
      setReportCustomer({});
    } else {

      setAvailabilityReportData([]);
      setReportCustomer(newValue);
      setAvailabilityReportLoading(true);
  
   
  
      getAvailabilityReport({
        variables: {
          customer: newValue.organisations[0].id,
          platform: newValue.organisations[0].platform ? newValue.organisations[0].platform :"",
          organisations: newValue.organisations[0].id,
          org: newValue.organisations[0].name,
          monthYear: monthYear,
          circuitId:newValue.organisations[0].circuitId ? newValue.organisations[0].circuitId : "",
          reportType: monthlyReport ? 'monthly' : 'weekly',
          customerName : newValue.organisations[0].customerName ? newValue.organisations[0].customerName :"",
        
        }
      });
    }
  };
  
  // const handleChangeReportCustomer = (event, newValue) => {
  //   console.log(newValue,"variables")
  //   if (newValue === null) {
  //     setReportCustomer({});
  //   } else {
  //     setAvailabilityReportData([])
  //     setReportCustomer(newValue);
  //     setAvailabilityReportLoading(true);
  //     getAvailabilityReport({
  //       variables: {
  //         customer: newValue.id,
  //         platform: newValue.platform,
  //         organisations: newValue.organisations[0].id,
  //         org: newValue.organisations[0].name,
  //         monthYear: monthYear,
  //         circuitId:newValue.circuitId ? newValue.circuitId :"",
  //         reportType: monthlyReport ? 'monthly' : 'weekly'
  //       }
  //     });
   
  //   }
  // };

  const handleMonthChange = date => {
    setMonthYear(date);
    if (reportCustomer.id) {
      setAvailabilityReportData([])
      setAvailabilityReportLoading(true);
      getAvailabilityReport({
        variables: {
          platform:reportCustomer.organisations[0].platform,
          circuitId:reportCustomer.organisations[0].circuitId ? reportCustomer.organisations[0].circuitId :"",
          customer: reportCustomer.organisations[0].id,
          organisations: reportCustomer.organisations[0].id,
          org: reportCustomer.organisations[0].name,
          monthYear: date,
          reportType: monthlyReport ? 'monthly' : 'weekly',
          customerName : reportCustomer.organisations[0].customerName ? reportCustomer.organisations[0].customerName :"",
     
        }
      });
    }
  };

  const handleChange = (event) => {
    setTicketType(event.target.value)
  }
  const handleCustomerStatics = () => {
    setEngineerBtnLoading(true)
    setEngineerDataLoading(true)
    getAllEngineerStatistics({
      variables: {
        engineerStartDate,
        engineerEndDate,
        ticketType: ticketType != "" ? ticketType : TICKET_DATA.SERVICETICKET
      }
    })
  }




  const [tabvalue, settabValue] = useState(0);

  const tabhandleChange = (event, newValue) => {
    settabValue(newValue);
  };




  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const loadIncidentBySubject = ()=>{
    setLoading(true);
    getIncidentStatusBySubject({
      variables: {
        page: page,
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });
  }


  const LoadIncidentByRegion = ()=>{
    setIncidentRegionLoading(true);
      getIncidentByRegion({
        variables: {
          page: regionPage,
          customer:
            selectedCustomer && selectedCustomer.id && selectedCustomer.id,
          startDate: startDate,
          endDate: endDate
        }
      });
  }


  const LoadTopTenIncident = ()=>{
    
    setTop10IncidentLoading(true);
    getTop10Incidents({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });
  }


  const LoadIncidentByMonth = ()=>{

    setTop10IncidentMonthLoading(true);
    getTop10IncidentsByMonth({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });
  }


  const LoadShowIncidentFiscalYear = ()=>{
    setIncidentFiscalLoading(true);
    getIncidentByFiscalYear({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });

  }

  


  
  const LoadTopFiveFiscalYear = ()=>{

    setTop5IncidentFiscalYearLoading(true);
    getTop5IncidentByFiscalYear({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });

   


 

  }


  const LoadTopFiveCategoryFiscal = ()=>{
    setTop5CategoryFiscalYearLoading(true);
    getTop5CategoryByFiscalYear({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });

  }


  const ShowCountryServoceList = ()=>{
    setCountryServiceLoading(true);
    getServiceListByCountry({
      variables: {
        customer:
          selectedCustomer && selectedCustomer.id && selectedCustomer.id,
        startDate: startDate,
        endDate: endDate
      }
    });

  }
  
  

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('analytics.label')}
        subText={`${t('statsLastUpdated.label')} ${dateFormatterLLL(
          Date.now()
        )}`}
      />
      <br />

  
<Box display="flex" height={224}>


 
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabvalue}
        onChange={tabhandleChange}
        aria-label="Vertical tabs example"
        style={{ borderRight: '1px solid #ccc' }}
      >
        <Tab label="Availability Report" {...a11yProps(0)}/>
        <Tab label="Show Incidents By Subject" {...a11yProps(1)} onClick={loadIncidentBySubject} />
        <Tab label="Incidents By Region" {...a11yProps(2)} onClick={LoadIncidentByRegion} />
        <Tab label="Show Top Ten Incident Faults" {...a11yProps(3)} onClick={LoadTopTenIncident}/>
        <Tab label="Show Incidents By Months" {...a11yProps(4)} onClick={LoadIncidentByMonth} />
        <Tab label="Show Incident Fiscal Year" {...a11yProps(5)} onClick={LoadShowIncidentFiscalYear} />
        <Tab label="Show Top 5 Incident Fiscal" {...a11yProps(6)} onClick={LoadTopFiveFiscalYear}  />
        <Tab label="Show Top Five Category Fiscal" {...a11yProps(7)} onClick={LoadTopFiveCategoryFiscal}  />
        <Tab label="Show Country Service List" {...a11yProps(8)} onClick={ShowCountryServoceList} />
        {/* <Tab label="Item Nine" {...a11yProps(9)} /> */}
     

        { (country == COUNTRY_LIST?.CAMEROON || country == COUNTRY_LIST?.MALAWI) &&    <Tab label="Engineers Report" {...a11yProps(10)} />}
      </Tabs>
     
      <TabPanel value={tabvalue} index={0} style={{ maxWidth: '900px', margin: '0 auto' }} >
      <>
         <Grid container style={{ maxHeight: '500px', overflowY: 'auto', padding: '20px',...marginGenerator('mb-8'), }}>
              <Grid item xs={12}>
      {showAvailabilityReport && (
      <>
          <div >
            <Typography
              variant={'h4'}
              style={{
                // ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('availabilityReport.label')}
            </Typography>
            <Grid container >
              <Grid item xs={12} sm={6}>
                {monthlyReport ? (
                  <>
                    <Typography variant={'h6'}>{t('selectMonth.label')}</Typography>
                    <DatePicker
                      selected={monthYear}
                      onChange={date => handleMonthChange(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      showTwoColumnMonthYearPicker
                    />
                  </>
                ) : (
                  <>
                    <Typography variant={'h6'}>{t('selectWeekDate.label')}</Typography>
                    <DatePicker
                      selected={monthYear}
                      onChange={(date) => handleMonthChange(date)}
                    />
                  </>
                )
                }
              </Grid>
              <Grid item xs={3}>

              {/* <Autocomplete
   id="country-select-demo"
  
   options={UsersListByOrganisation && UsersListByOrganisation?.length ? UsersListByOrganisation : []}
  sx={{ width: 300 }}
 
  getOptionLabel={option =>
    option
      ? `${option.organisations[0]
        ? option.organisations[0].name
        : ''
      }`
      : ''
  }
  renderInput={(params) => <TextField
   {...params} 
   onChange={(event) =>
    handleOrgSearch(event)
  }
   label="choose organisation" />}
/> */}

{/* OrgAuto */}
<Autocomplete
      disablePortal
      id="country-select-demo"
      options={orgoptions}
      getOptionLabel={(option) => option.organisations[0]?.name || ''}
      autoHighlight
      onChange={(event,newValue)=>{handleChangeReportCustomer(event,newValue)}}
      style={{ width: '94%' }}
      renderInput={(params) => (
        <TextField
          style={{ width: '100%', border: '1px solid #ebebeb !important' }}
          {...params}
          variant="standard"
          label="Choose Organisation"
          value={searchValue}
          onChange={(event) => delayedHandleSearch(event.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {customerLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
              </Grid>
            </Grid>
            <Grid container justify={'space-between'}>
              <Grid container item xs={6} direction="column">
                <RadioGroup
                  row
                  aria-label="quiz"
                  name="quiz"
                  value={value}
                  onChange={handleRadioChange}>
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    labelPlacement="start"
                    label="Monthly Report"
                  />
                  <FormControlLabel
                    value="week"
                    labelPlacement="start"
                    control={<Radio />}
                    label="Weekly Report"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <ReportTable
                  data={availabilityReportData}
                  column={availabilityReportColumn}
                  loading={availabilityReportLoading}
                  monthandyear={availabilityReportMonthYear}
                  orgName={availabilityReportOrg}
                  mytableData={tableData}
                  platform={platform}
                  circuitId={circuitId}
                  siteName={siteName}
                />
              </Grid>
            </Grid>
          </div>
          <br />

       </>
      )}

              </Grid>
          </Grid>
        </>
      </TabPanel>


      <TabPanel value={tabvalue} index={1} style={{ maxWidth: '900px', margin: '0 auto' }} >
      {showIncidentBySubject && (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('incidentBySubject.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={loadIncidentBySubject}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PaginationTable
                  data={incidentBySubject}
                  column={incidentBySubjectColumn}
                  handlePageChange={handlePageChange}
                  page={page}
                  loading={loading}
                  totalCount={totalCount}
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}

      </TabPanel>
      <TabPanel value={tabvalue} index={2} style={{ maxWidth: '900px', margin: '0 auto' }}  >
      {showIncidentByRegion && (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('incidentByRegion.label')}
            </Typography>
            <Grid container spacing={3}>
  <Grid item xs={3}>
    <div className="dateRange">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  </Grid>

  <Grid item xs={6}>
    <Button
      style={{ margin: '0px 20px' }}
      variant="contained"
      onClick={LoadIncidentByRegion}
      color="primary"
      disableElevation
    >
      Submit
    </Button>
  </Grid>
</Grid>


            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PaginationTable
                  data={incidentByRegion}
                  column={incidentBySubjectColumn}
                  loading={incidentRegionLoading}
                  page={regionPage}
                  handlePageChange={handleRegionPageChange}
                  totalCount={totalCountRegion}
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}
      </TabPanel>
      <TabPanel value={tabvalue} index={3} style={{ maxWidth: '900px', margin: '0 auto' }} >
      {showTop10Incident && (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('topFaults.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={LoadTopTenIncident}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>


            <Grid container spacing={1} style={{marginBottom:130}}>
              <Grid item xs={7}>
                <FaultTable
                  data={top10Incident}
                  column={top10IncidentColumn}
                  loading={top10IncidentLoading}
                />
              </Grid>
              <Grid item xs={5}>
                <BarChartWrapper
                  data={
                    top10Incident && top10Incident.length ? top10Incident : []
                  }
                  dataKeyChart={'TotalIncident'}
                  dataKeyXAxis={'name'}
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}
      </TabPanel>
      <TabPanel value={tabvalue} index={4} style={{ maxWidth: '900px', margin: '0 auto' }} >
      {showIncidentByMonth && (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('topFaultsByMonth.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={LoadIncidentByMonth}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>


            <Grid container style={{marginBottom:130}}>
              <Grid item xs={7}>
                <FaultTable
                  data={top10IncidentByMonth}
                  column={top10IncidentColumn}
                  loading={top10IncidentMonthLoading}
                />
              </Grid>
              <Grid item xs={5}>
                <PieChartWrapperScroll
                  data={
                    top10IncidentByMonthData && top10IncidentByMonthData.length
                      ? top10IncidentByMonthData
                      : []
                  }
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}
      </TabPanel>
      <TabPanel value={tabvalue} index={5} style={{ maxWidth: '900px', margin: '0 auto' }} >
      
      {showIncidentFiscalYear && (
        <>
          <div className={classes.root} style={{marginBottom:130}}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('incidentFiscalYear.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={LoadShowIncidentFiscalYear}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>
        <Grid container spacing={1}>
  <Grid item xs={12} sm={6} md={8} lg={10}>
    <BarChartWrapper
      data={
        incidentFiscalYear && incidentFiscalYear.length
          ? incidentFiscalYear
          : []
      }
      dataKeyChart={'TotalIncident'}
      dataKeyXAxis={'month'}
      loading={incidentFiscalLoading}
    />
  </Grid>
</Grid>

          </div>
          <br />
        </>
      )}
      </TabPanel>
      <TabPanel value={tabvalue} index={6} style={{ maxWidth: '900px', margin: '0 auto' }} >
      {showTop5IncidentFiscal && (
        <>
          <div className={classes.root} style={{marginBottom:130}}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('top5Fault.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={LoadTopFiveFiscalYear}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>
            <Grid container style={{marginBottom:130}} >
          
              <Grid item xs={12}>
                <FinencialYearTable
                  data={top5IncidentFiscalYear}
                  column={top5IncidentFiscalYearColumn}
                  loading={top5IncidentFiscalYearLoading}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <BarMultiChartWrapper
                  data={
                    incidentFiscalBarData &&
                      incidentFiscalBarData.source &&
                      incidentFiscalBarData.source.length
                      ? incidentFiscalBarData
                      : []
                  }
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}
      </TabPanel>
      <TabPanel value={tabvalue} index={7} style={{marginBottom:130, maxWidth: '900px', margin: '0 auto'}}>
      {showTop5CategoryFiscal && (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('top5Category.label')}
            </Typography>

            <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={LoadTopFiveCategoryFiscal}
              color="primary"
              disableElevation>
              Submit
            </Button>
           
          </Grid>
        </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FinencialYearTable
                  data={top5CategoryFiscalYear}
                  column={top5CategoryFiscalYearColumn}
                  loading={top5CategoryFiscalYearLoading}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <BarMultiChartWrapper
                  data={
                    categoryFiscalBarData &&
                      categoryFiscalBarData.source &&
                      categoryFiscalBarData.source.length
                      ? categoryFiscalBarData
                      : []
                  }
                />
              </Grid>
            </Grid>
          </div>
          <br />
        </>
      )}
      </TabPanel>

      <TabPanel value={tabvalue} index={8} style={{ maxWidth: '900px', margin: '0 auto' }}>
      {showCountryServiceList && (
        <>
        <Grid container >
          <Grid item xs={3}>
            <div className="dateRange">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
        
        
          <Grid item xs={6}>
            <Button
              style={{ margin: '0px 20px' }}
              variant="contained"
              onClick={ShowCountryServoceList}
              color="primary"
              disableElevation>
              Submit
            </Button>
      
          </Grid>
        </Grid>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('circuitByRegion.label')}
            </Typography>
            <Grid container spacing={5} style={{ marginBottom: '130px', padding: '16px' }}>
  <Grid item xs={12} sm={8}>
    <CustomTable
      data={countryServiceList}
      column={servicesByCountryColumn}
      loading={countryServiceLoading}
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <IncidentStatusPOC t={t} data={countryServiceList} />
  </Grid>
</Grid>

          </div>
        </>
      )}
      </TabPanel>


     

            {/* Engineers Report */}
      <TabPanel value={tabvalue} index={10}>
      {country !== 'null' && (country == COUNTRY_LIST.CAMEROON || country == COUNTRY_LIST.MALAWI) ? (
        <>
          <div className={classes.root}>
            <Typography
              variant={'h4'}
              style={{
                ...marginGenerator('mt-32'),
                ...marginGenerator('mb-8'),
                borderBottom: `1px solid ${colors.grey[200]}`
              }}>
              {t('engineerReport.label')}
            </Typography>
            <Grid container spacing={5} style={{ paddingTop: '15px', marginBottom: '15px' }}>
              <Grid item xs={3}>
                <DatePicker
                  selected={engineerStartDate}
                  onChange={date => setengineerStartDate(date)}
                  className={classes.styles}
                  selectsStart
                  startDate={engineerStartDate}
                  endDate={engineerEndDate}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  selected={engineerEndDate}
                  onChange={date => setengineerEndDate(date)}
                  selectsEnd
                  className={classes.styles}
                  startDate={engineerStartDate}
                  endDate={engineerEndDate}
                  minDate={engineerStartDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid>
                  <OutlinedSelect
                    label={"Select Ticket Type"}
                    handleChange={handleChange}
                    val={ticketType}
                    selectStyle={{ ...paddingGenerator('p-8') }}
                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                    options={ticketCategory ? ticketCategory : []}
                    id="standard-select"
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{ margin: '0px 20px' }}
                  variant="contained"
                  onClick={handleCustomerStatics}
                  color="primary"
                  disableElevation>
                  {engineerBtnLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : t('submit.label')}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {allEngineerStatisticData && allEngineerStatisticData.length && !engineerBtnLoading ? (
                <>
                  <Grid item xs={12}>
                    <BarMultiChartWrapperTwo
                      data={
                        AllEngineerStatisticBarData &&
                          AllEngineerStatisticBarData.source &&
                          AllEngineerStatisticBarData.source.length
                          ? AllEngineerStatisticBarData
                          : []
                      }
                    />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <EngineerReportTable
                      data={allEngineerStatisticData}
                      column={engineerTableColumn}
                      loading={engineerDataLoading}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        </>
      ) : null}
      </TabPanel>
    </Box>

    

     
      <br />
      
    </Layout>
  );
};
export default withTranslation()(Analytics);
