import React, {useState} from 'react';
import {colors} from '../../../../theme/colors';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchBox from '../../../../components/SearchBox';
import {dateFormatterLLL, convertUTCDateToLocalDate} from '../../../../utils';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {getStartEndTimeForReport} from '../../../../utils';
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_DASHBOARD_EVENT_DATA} from "../../queries";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    camerasContainer: {
        borderRight: `1px solid ${theme.palette.grey[200]}`
    },
    headerContainer: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    cameraTile: {
        padding: theme.spacing(2, 3),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        cursor: 'pointer'
    },
    eventsTile: {
        padding: theme.spacing(2, 3, 2, 0)
    },
    listContainer: {
        maxHeight: '300px',
        overflowY: 'scroll',
    }
}));
const UcpeEvents = ({t, data, eventsAndAlertsModelState, eventData}) => {
    const classes = useStyles();
    const [selectedDevice, setSelectedDevice] = useState({});
    const [updatedCameraList, setCameraList] = useState([]);
    const [cameraSearched, setCamerSearched] = useState(false);
    const [updatedEventsList, setEventsList] = useState([]);
    const [eventsDashboardList, setEventsDashboardList] = useState([]);
    const [eventsSearched, setEventsSearched] = useState(false);
    const [isLoader, setLoader] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    React.useEffect(() => {
        setSelectedDevice({})
    }, [eventsAndAlertsModelState]);
    const handleCameraSearch = query => {
        if (query.length) {
            const updatedList = data.filter(item =>
                item.name.toLowerCase().includes(query)
            );
            setCameraList(updatedList);
            setCamerSearched(true);
        } else {
            setCameraList([]);
            setCamerSearched(false);
        }
    };
    const [getEventDashboardList] = useLazyQuery(GET_DASHBOARD_EVENT_DATA, {
        fetchPolicy: "no-cache",
        onError: error => {
            setLoader(false);
        },
        onCompleted: data => {
            if (data && data.getEventDashboard.length) {
                setLoader(false);
                setEventsDashboardList(data.getEventDashboard);
            } else {
                setEventsDashboardList([]);
                setLoader(false);
            }
        }
    });
    const handelClick = item => {
        setLoader(true);
        setSelectedDevice(item)
        let todayStartEndDate = getStartEndTimeForReport(eventsAndAlertsModelState.time);
        let formatStartDate = new Date((todayStartEndDate.startTime * 1000));
        let formatEndDate = new Date((todayStartEndDate.endTime * 1000));
        getEventDashboardList({
            variables: {
                deviceID: (item.deviceName) ? item.deviceName : selectedDevice.deviceName,
                start_date: moment(formatStartDate).format('YYYY-MM-DD'),
                end_date: moment(formatEndDate).format('YYYY-MM-DD')
            }
        })
    }
    const handleEventsSearch = query => {
        if (query === '') {
            let todayStartEndDate = getStartEndTimeForReport(eventsAndAlertsModelState.time);
            let formatStartDate = new Date((todayStartEndDate.startTime * 1000));
            let formatEndDate = new Date((todayStartEndDate.endTime * 1000));
            getEventDashboardList({
                variables: {
                    deviceID: selectedDevice.deviceName,
                    start_date: moment(formatStartDate).format('YYYY-MM-DD'),
                    end_date: moment(formatEndDate).format('YYYY-MM-DD')
                }
            })
            return;
        }
        if (selectedDevice && query.length) {
            const updatedList = eventsDashboardList.filter(item =>
                item.name.toLowerCase().includes(query)
            );
            setEventsDashboardList(updatedList);
            //  setLoader(true);
        } else {
            setEventsDashboardList([]);
            setLoader(false);
        }
    };
    let eventsData = selectedDevice
        ? eventsSearched
            ? updatedEventsList
            : selectedDevice.events
        : [];
    // if(eventsData && eventsData.length){
    //   eventsData = eventsData.map((item, index) => {
    //     return item;
    //   });
    // }
    const cameraData = cameraSearched ? updatedCameraList : data;
    if (data && data.length > 0 && firstLoad) {
        setFirstLoad(false);
        setSelectedDevice(data[0]);
    }
    if (eventData && eventData.getUcpeEvent.length > 0 && firstLoad) {
        setFirstLoad(false);
    }
    const eventColumns = [
        {
            label: 'Name',
            key: 'name'
        },
        {
            label: 'Type',
            key: 'type'
        },
        {
            label: 'Source type',
            key: 'sourceType'
        },
        {
            label: 'Time',
            key: 'time'
        }
    ];
    console.log('start date', getStartEndTimeForReport(eventsAndAlertsModelState.time));
    return (
        <Grid container className={classes.container}>
            <Grid item xs={5} className={classes.camerasContainer}>
                <Grid
                    className={classes.headerContainer}
                    container
                    alignItems={'center'}>
                    <Typography
                        variant={'subtitle2'}
                        color={'textPrimary'}
                        style={marginGenerator('mr-32')}>
                        {t('uCPE.label')}
                    </Typography>
                    <SearchBox handleSearch={handleCameraSearch}/>
                </Grid>
                <Grid container className={classes.listContainer}>
                    {!firstLoad ? (
                        eventData && eventData.getUcpeEvent.map((item, index) => {
                            return (
                                <Grid
                                    container
                                    item
                                    key={index}
                                    justify={'space-between'}
                                    alignItems={'center'}
                                    style={
                                        selectedDevice && item.deviceName === selectedDevice.deviceName
                                            ? {
                                                backgroundColor: 'rgb(90 31 42)',
                                                padding: '5px' - <label for=""/>
                                            }
                                            : {}
                                    }
                                    onClick={() => handelClick(item)}
                                    className={classes.deviceName}>
                                    <Grid
                                        container
                                        item
                                        justify={'flex-start'}
                                        xs={7}
                                        alignItems={'center'}>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'textPrimary'}
                                            style={
                                                selectedDevice && item.deviceName === selectedDevice.deviceName
                                                    ? {
                                                        color: colors.common.white,
                                                        padding: '5px'
                                                    }
                                                    : {...marginGenerator('ml-16'), ...paddingGenerator('p-5')}
                                            }>
                                            {item.deviceName}
                                        </Typography>
                                    </Grid>
                                    <Grid container item justify={'flex-end'} xs={4}>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'textPrimary'}
                                            style={
                                                selectedDevice && item.deviceName === selectedDevice.deviceName
                                                    ? {
                                                        color: colors.common.white,
                                                        padding: '5px'
                                                    }
                                                    : {...marginGenerator('ml-16'), ...paddingGenerator('p-5')}}>
                                            {item.event_log_count ? item.event_log_count : 0} {t('events.label')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })) : (
                        <Grid container item justify={'flex-end'} xs={4}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mr-32')}>
                                Loading...
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs className={classes.eventsContainer}>
                <Grid
                    className={classes.headerContainer}
                    style={paddingGenerator('pl-40')}
                    container
                    alignItems={'center'}>
                    <Typography
                        variant={'subtitle2'}
                        color={'textPrimary'}
                        style={marginGenerator('mr-32')}>
                        {t('events.label')}
                    </Typography>
                    <SearchBox handleSearch={handleEventsSearch}/>
                </Grid>
                <Grid
                    container
                    className={classes.listContainer}
                    style={paddingGenerator('pl-40')}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {eventColumns.map((item, index) => (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>
                                                {item.label}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoader ? (
                                    eventsDashboardList.length > 0 ? (
                                        eventsDashboardList.map((item, rowIndex) => (
                                            <TableRow>
                                                <TableCell>
                                                    {/*<Typography variant={'subtitle2'}>
                                                    {item.deviceName}
                                                </Typography>*/}
                                                    <Typography variant={'subtitle2'}>
                                                        {item.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {item.type}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {item.sourceType}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {dateFormatterLLL(
                                                            convertUTCDateToLocalDate(new Date(item.timestamp))
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={eventColumns.length}>
                                                <Typography variant={'subtitle2'}>
                                                    Events not found!!
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                    <TableRow>
                                        <TableCell colSpan={eventColumns.length}>
                                            <Typography variant={'subtitle2'}>Loading...</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default UcpeEvents;
