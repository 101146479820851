import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, withStyles } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../../../components/hook-form';
import IPut from 'iput';
import { isIP } from 'is-ip';
import { useAppContext } from '../../../../../../contexts/AppContext';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    height: '43px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const FcrToFcr = props => {
  const { handlePrev, handleNext, setState, getSubnet, client } = props;
  const [subnetIsValid, setSubnetIsValid] = useState(true);
  const [nextStepAllowed, setNextStepAllowed] = useState(false);

  const { state } = useAppContext();

  const initialValues = {
    connectionName: '',
    rateLimit: '',
    MinimumTerm: 'standard',
    clientName: ''
  };
  const validationSchema = yup.object().shape({
    connectionName: yup.string().required('This field is required'),
    clientName: yup.string().required('This field is required'),
    rateLimit: yup
      .number()
      .typeError('Enter a value between 0 & 1000')
      .min(0, 'Enter a value between 0 & 1000')
      .max(1000, 'Enter a value between 0 & 1000')
      .required('This field is required'),
    MinimumTerm: yup.string().required('This field is required')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const { handleSubmit, setValue } = methods;

  const handleChange = val => {
    getSubnet(val);
    if (isIP(val)) {
      setSubnetIsValid(true);
      setNextStepAllowed(true);
    } else {
      setSubnetIsValid(false);
      setNextStepAllowed(false);
    }
  };
  const onSubmit = data => {
    setState({ data });
    handleNext();
  };

  useEffect(() => {
    setValue('clientName', state?.clientDetails?.clientName);
  }, [client, setValue]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '10px', width: '500px' }}>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Connection Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="connectionName"
                label="Connection Name"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Client Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="clientName"
                size="small"
                autoComplete="off"
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Rate Limit
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="rateLimit"
                label="Rate Limit (Mbps)"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Link Type
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFSelect name="MinimumTerm" variant="outlined" size="small">
                <option value="">Select</option>
                {[
                  {
                    key: 'Standard Unprotected',
                    value: 'standard-unprotected'
                  },
                  {
                    key: 'Premium Unprotected',
                    value: 'premium-unprotected'
                  },
                  { key: 'Standard Protected', value: 'standard-protected' }
                ].map(item => (
                  <option key={item.key} value={item?.value}>
                    {item?.key}
                  </option>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Subnet
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={1}
              sm={8}>
              <Grid item sm={7}>
                <IPut onChange={handleChange} />
              </Grid>
              <Grid item sm={5}>
                /30
              </Grid>
            </Grid>
            {!subnetIsValid && (
              <span sx={{ color: 'red' }}>Enter a valid IP Address</span>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box p={2}>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Box>
            <Box p={2}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={nextStepAllowed ? false : true}
                type="submit">
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default withStyles(styles)(FcrToFcr);
