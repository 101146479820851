import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import {Card, CardMedia} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

function ButtonComponent(props) {
    const {t, onClick, loading, ariaLabel, modalStateType} = props;
    return (
        <Button
            onClick={onClick} disabled={loading}>
            {ariaLabel === 'Play' && <Typography component="span" variant="span" color="textSecondary">Start</Typography>}
            {ariaLabel === 'Stop' && <Typography component="span" variant="span" color="textSecondary">Stop</Typography>}
            {ariaLabel === 'Pause' && <Typography component="span" variant="span" color="textSecondary">Pause</Typography>}
            {ariaLabel === 'Resume' && <Typography component="span" variant="span" color="textSecondary">Resume</Typography>}
            <IconButton aria-label={ariaLabel}>
                {ariaLabel === 'Play' ? loading && modalStateType === 'start' ? <CircularProgress size={14}/> : <PlayCircleOutlineIcon/> : ""}
                {ariaLabel === 'Stop' ? loading && modalStateType === 'stop' ? <CircularProgress size={14}/> : <StopIcon/> : ""}
                {ariaLabel === 'Pause' ? loading && modalStateType === 'pause' ? <CircularProgress size={14}/> : <PauseCircleOutlineIcon/> : ""}
                {ariaLabel === 'Resume' ? loading && modalStateType === 'resume' ? <CircularProgress size={14}/> : <SkipNextIcon/> : ""}
            </IconButton>
        </Button>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '100%',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));
function ControlsVNFModal({t, data, open, handleControlsVnf, vnfId, vnfName, handleClose, modalState}) {
    const organization_id = data.organizationId;
    const classes = useStyles();
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="vnf-modal"
            aria-describedby="vnf-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogContent>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h5" color="textSecondary">
                                {t('vnfs.label') + ' ' + t('controls.label') + ':- ' + vnfName}
                            </Typography>
                        </CardContent>
                        <div className={classes.controls}>
                            <ButtonComponent
                                t={t}
                                onClick={() => {
                                    handleControlsVnf({vnfId: vnfId, type: 'start'});
                                }}
                                ariaLabel={'Play'}
                                loading={modalState.btnLoading}
                                modalStateType={modalState.type}
                            />
                            <ButtonComponent
                                t={t}
                                onClick={() => {
                                    handleControlsVnf({vnfId: vnfId, type: 'stop'});
                                }}
                                ariaLabel={'Stop'}
                                loading={modalState.btnLoading}
                                modalStateType={modalState.type}
                            />
                            <ButtonComponent
                                t={t}
                                onClick={() => {
                                    handleControlsVnf({vnfId: vnfId, type: 'pause'});
                                }}
                                ariaLabel={'Pause'}
                                loading={modalState.btnLoading}
                                modalStateType={modalState.type}
                            />
                            <ButtonComponent
                                t={t}
                                onClick={() => {
                                    handleControlsVnf({vnfId: vnfId, type: 'resume'});
                                }}
                                ariaLabel={'Resume'}
                                loading={modalState.btnLoading}
                                modalStateType={modalState.type}
                            />
                        </div>
                    </div>
                    {/*   <CardMedia
                        className={classes.cover}
                        image={VnfCoverImage}
                    />*/}
                </Card>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'} style={{margin: 'auto', width: '100px'}}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('close.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
ControlsVNFModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ControlsVNFModal);
