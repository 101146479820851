import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Checkbox, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
//import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  blackBorder: {
    border: '0.5px solid black',
    outline: '0.5px solid',
    padding: '0px'
  },
  blueBorder: {
    border: '5px solid ',
    borderRight: '5px solid #06373e',
    borderTop: '5px solid #2e8a88',
    borderBottom: '5px solid #2e8a88',
    borderLeft: '5px solid #47cfc7',
    width: '350px',
    padding: '50px',
    borderRadius: '12px'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = {
  id: '',
  title: '',
  firstIssueDate: null,
  procedureNo: '',
  revisionNo: '',
  reviewPeriod: '',
  nextReviewDate: null,
  preparedBy: '',
  distributionList: '',
  registerSection: [
    {
      key: 0,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 1,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 2,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 3,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 4,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 5,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 6,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 7,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 8,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    },
    {
      key: 9,
      name: '',
      comapnyName: '',
      contactNumber: '',
      keyOffice: '',
      dateCollected: null,
      timeCollected: null,
      dateReturned: null,
      timeReturned: null,
      signature: ''
    }
  ]
};

const KeysControlRegisterForm = ({
  t,
  open,
  data,
  handleClose,
  modalState,
  handleCreateRequestSubmit,
  handleOpenSignPanModal
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
    if (!open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
      const id = data._id;
      setURFormState({ ...data, id });
    }
  }, [data]);

  const handleChange = (value, name, key, sectionName) => {
    if (sectionName === 'registerSection') {
      setURFormState(prevState => {
        const newState = { ...prevState };
        const row = newState[sectionName];
        row[key][name] = value;
        return newState;
      });
    } else {
      setURFormState({ ...urFormState, [name]: value });
    }
  };
  const columns = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Comapny Name',
      key: 'comapnyName'
    },
    {
      label: 'Contact Number',
      key: 'contactNumber'
    },
    {
      label: 'key Office',
      key: 'keyOffice'
    },
    {
      label: 'Date Collected',
      key: 'dateCollected'
    },
    {
      label: 'Time Collected',
      key: 'timeCollected'
    },
    {
      label: 'Date Returned',
      key: 'dateReturned'
    },
    {
      label: 'Time Returned',
      key: 'timeReturned'
    },
    {
      label: 'Signature',
      key: 'signature'
    }
  ];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        {/* <HrHeader /> */}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">MORUPULE COAL MINE LIMITED</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'TITLE'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.title}
              onChange={evt => handleChange(evt.target.value, 'title')}
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimeSelector
              label={'FIRST ISSUE DATE'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.firstIssueDate}
              handleChange={newDate => handleChange(newDate, 'firstIssueDate')}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'PROCEDURE NO'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.procedureNo}
              onChange={evt => handleChange(evt.target.value, 'procedureNo')}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'REVISION NO'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.revisionNo}
              onChange={evt => handleChange(evt.target.value, 'revisionNo')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'REVIEW PERIOD'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.reviewPeriod}
              onChange={evt => handleChange(evt.target.value, 'reviewPeriod')}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'NEXT REVIEW DATE'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.nextReviewDate}
              onChange={evt => handleChange(evt.target.value, 'nextReviewDate')}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'PREPARED BY'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.preparedBy}
              onChange={evt => handleChange(evt.target.value, 'preparedBy')}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type={'text'}
              placeholder={'DISTRIBUTION LIST'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.distributionList}
              onChange={evt =>
                handleChange(evt.target.value, 'distributionList')
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((item, index) => (
                    <TableCell key={index}>
                      <Typography variant={'subtitle2'}>
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {urFormState?.registerSection.length > 0 ? (
                  urFormState?.registerSection.map((rowitem, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>
                        <Input
                          type={'text'}
                          style={{ width: '50%' }}
                          className={classes.input}
                          color={'secondary'}
                          value={rowitem.name}
                          onChange={evt => {
                            handleChange(evt.target.value, 'name', rowIndex, `registerSection`);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Input
                            type={'text'}
                            style={{ width: '50%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={rowitem.comapnyName}
                            onChange={evt => {
                              handleChange(evt.target.value, 'comapnyName', rowIndex, `registerSection`,);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Input
                            type={'text'}
                            style={{ width: '50%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={rowitem.contactNumber}
                            onChange={evt => {
                              handleChange(evt.target.value, 'contactNumber',rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Input
                            type={'text'}
                            style={{ width: '50%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={rowitem.keyOffice}
                            onChange={evt => {
                              handleChange(evt.target.value, 'keyOffice', rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                           <DateTimeSelector
                            dateFormat={'yyyy-MM-dd'}
                            value={rowitem.dateCollected}
                            handleChange={newDate => {
                              handleChange(newDate, 'dateCollected' ,rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <DateTimeSelector
                            dateFormat={'HH:mm'}
                            value={rowitem.timeCollected}
                            handleChange={newDate => {
                              handleChange(newDate, 'timeCollected', rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <DateTimeSelector
                            dateFormat={'yyyy-MM-dd'}
                            value={rowitem.dateReturned}
                            handleChange={newDate => {
                              handleChange(newDate, 'dateReturned', rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <DateTimeSelector
                            dateFormat={'HH:mm'}
                            value={rowitem.timeReturned}
                            handleChange={newDate => {
                              handleChange(newDate, 'timeReturned', rowIndex, `registerSection`);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid style={{ display: 'flex', alignItems: 'right' }}>
                          <IconButton
                            variant={'outlined'}
                            onClick={() => {
                            handleOpenSignPanModal(`signature${rowIndex}`)
                            }}>
                            Sign
                            <img src={SignIcon} width="20" height={20} alt="" />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => handleCreateRequestSubmit(urFormState)}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default KeysControlRegisterForm;
