import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '80%',
        textAlign: 'right',
        paddingRight: 8,
    },
    total: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    }
  });


const InvoiceTableFooter = ({total, currency}) => {
    return(
        <React.Fragment>
            <View style={styles.row}>
                <Text style={styles.description}>Subtotal: </Text>
                <Text style={styles.total}><Text style={styles.currencyFont}>{currency}</Text>{ total ? Number.parseFloat(total.totalCost).toFixed(2) : 0}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Discount: </Text>
                <Text style={styles.total}>{currency}{ total ? Number.parseFloat(total.totalDiscount).toFixed(2) : 0 }</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Taxes: </Text>
                <Text style={styles.total}>{currency}{ total ? Number.parseFloat(total.taxes).toFixed(2) : 0 }</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Total: </Text>
                <Text style={styles.total}>{currency}{ total ? Number.parseFloat(total.totalPayableAmt).toFixed(2) : 0 }</Text>
            </View>
        </React.Fragment>
    )
};
  
  export default InvoiceTableFooter