import { gql } from 'apollo-boost';

/*
  Get organisations list
*/
export const ORGANISATIONS_LIST = gql`
  query Organizations {
    organizations {
      id
      name
      email
      phone
      country
      zipcode
      logo
      type
      subscriptions {
        id
        name
        isDelete
        status
        service {
          id
          name
        }
      }
      noOfUsers
    }
  }
`;

/*
  Get organisations list for Select
*/
export const ORGANISATIONS_LIST_FOR_SELECT = gql`
  query SelectOrganization {
    getOrganizationsList {
      id
      name
    }
  }
`;

/*
  Search organisations
*/
export const SEARCH_ORGANISATION = gql`
  query Organizations($searchText: String!) {
    searchOrganizations(searchText: $searchText) {
      id
      name
      email
      phone
      country
      zipcode
      subscriptions {
        id
        name
        service {
          id
          name
        }
      }
      noOfUsers
    }
  }
`;

/*
  Get members
 */
export const GET_INVITE_MEMBERS = gql`
  query Organizations($orgId: String!, $roleType: String) {
    inviteMembersAnOrganization(orgId: $orgId, roleType: $roleType) {
      id
      firstName
      lastName
      email
      phone
      roles
    }
  }
`;

export const GET_MEMBERS = gql`
  query Organizations($orgId: String!) {
    usersOfAnOrganization(orgId: $orgId) {
      id
      firstName
      lastName
      email
      phone
      roles
      countryId
    }
  }
`;

export const GET_USERS_LIST = gql`
  query UserAccounts {
    userAccounts {
      id
      firstName
      lastName
      email
      roles
      phone
    }
  }
`;

export const GET_ASSIGN_USERS = gql`
  query UserAccounts($role: String) {
    getAssignUsers(role: $role) {
      id
      firstName
      lastName
      email
      roles
    }
  }
`;