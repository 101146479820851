import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import {useState} from 'react';
import {fade} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MenuWrapper from '../../../../components/MenuWrapper';
import Input from '@material-ui/core/Input';
import PlacesMap from '../PlacesMap';
import {colors} from '../../../../theme/colors';
import CheckSuccess from '../../../../assets/icons/check_success.png';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    itemContainer: {
        flexGrow: 1,
        backgroundColor: fade(theme.palette.grey[200], 0.5),
        border: `1px solid ${theme.palette.grey[200]}`
    },
    toggleContainer: {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderTop: 'none',
        flexGrow: 1,
        padding: theme.spacing(4, 10)
    },
    indexContainer: {
        width: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const InputContainer = ({
                            t,
                            handleInputChange,
                            label,
                            placeholder,
                            name,
                            type,
                            site,
                            endAdorment,
                            value,
                            options
                        }) => {
    return (
        <Grid
            container
            item
            justify={'space-between'}
            alignItems={'center'}
            style={{
                ...paddingGenerator('pt-16'),
                ...paddingGenerator('pb-16')
            }}>
            <Grid>
                <Typography variant="h4">{t(label)}</Typography>
                <Typography variant="subtitle2">{t(placeholder)}</Typography>
            </Grid>
            {type === 'selectDropdown' ? (
                <Grid>
                    <OutlinedSelect
                        val={value}
                        label={t(label)}
                        handleChange={evt =>
                            handleInputChange(name, evt.target.value, site)
                        }
                        selectStyle={{...paddingGenerator('p-8')}}
                        styleOverrides={{minWidth: '280px', margin: '0px'}}
                        options={options}
                    />
                </Grid>
            ) : (
                <Grid>
                    <Input
                        endAdornment={
                            endAdorment ? <Typography>{endAdorment}</Typography> : <></>
                        }
                        value={value}
                        type={type}
                        color={'secondary'}
                        onChange={evt => handleInputChange(name, evt.target.value, site)}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const ConfigureSiteItem = ({
    t,
    data,
    type,
    index,
    menuItems,
    handleInputChange,
    handleResetConfigClick,
    currentState,
    handleMenuItemClick,
    handleSaveClick,
    handleSetPosition,
    regionList,
    sitesACityData,
    sitesBCityData,
    countryList,
    loadingBtn
}) => {
    const [toggleState, setToggleState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();
    const commonClasses = commonStyles();

    const handleSetPositionBase = (pos, place, id) => {
        // if(place.formatted_address){
        //   handleInputChange(
        //     'address',
        //     place.formatted_address,
        //     `site${index}`
        //   );
        // }
        handleSetPosition(pos, `site${index}`);
    };

    const handleMenuItemClickBase = item => {
        handleMenuItemClick(item, `site${index}`);
        setAnchorEl(null);
    };

    return (
        <>
            <Grid
                container
                justify={'space-between'}
                style={{
                    ...paddingGenerator('pt-16'),
                    ...paddingGenerator('pb-16'),
                    ...paddingGenerator('pl-32'),
                    ...paddingGenerator('pr-32'),
                    ...(toggleState ? {backgroundColor: colors.common.white} : null)
                }}
                className={classes.itemContainer}>
                <Grid item xs style={{display: 'flex', alignItems: 'center'}}>
                    <Grid
                        item
                        style={marginGenerator('mr-16')}
                        className={classes.indexContainer}>
                        {currentState[`site${index}`] &&
                        (currentState[`site${index}`].saved ||
                            currentState[`site${index}`].id) ? (
                            <img
                                alt={'success'}
                                src={CheckSuccess}
                                className={commonClasses.iconLarge}
                            />
                        ) : (
                            <Typography variant={'body2'}>{index}</Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                            {t('site.label')} {index}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs
                    container
                    justify={'flex-end'}
                    style={marginGenerator('mt-8')}>
                    {!toggleState && (
                        <Button
                            variant={'text'}
                            color={'secondary'}
                            onClick={() => setToggleState(!toggleState)}>
                            {currentState[`site${index}`] &&
                            (currentState[`site${index}`].saved ||
                                currentState[`site${index}`].id)
                                ? t('editConfiguration.label')
                                : t('configure.label')}
                        </Button>
                    )}
                    {!(
                        currentState[`site${index}`] &&
                        (currentState[`site${index}`].saved ||
                            currentState[`site${index}`].id)
                    ) ? (
                        <IconButton
                            size={'small'}
                            disableRipple={true}
                            disableFocusRipple={true}
                            color={'secondary'}
                            style={{
                                ...marginGenerator('ml-32'),
                                backgroundColor: 'transparent'
                            }}
                            onClick={evt => {
                                setAnchorEl(evt.currentTarget);
                                evt.stopPropagation();
                            }}>
                            <MoreHoriz className={commonClasses.iconRegular}/>
                        </IconButton>
                    ) : null}
                </Grid>
                {anchorEl && (
                    <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={menuItems}
                        handleMenuItemClick={handleMenuItemClickBase}
                    />
                )}
            </Grid>

            {toggleState && (
                <Grid container className={classes.toggleContainer}>
                    {data &&
                    data.map((item, inputIndex) => {
                        let option;
                        switch (item.stateVar) {
                            case 'SiteBCity':
                                option = sitesBCityData;
                                break;
                            case 'SiteACity':
                                option = sitesACityData;
                                break;
                            default:
                                option = item.options;
                        }
                        return <InputContainer
                            t={t}
                            site={`site${index}`}
                            key={inputIndex}
                            type={item.type}
                            handleInputChange={handleInputChange}
                            endAdorment={item.endAdorment}
                            name={item.stateVar}
                            label={item.label}
                            placeholder={item.placeholder}
                            value={
                                currentState[`site${index}`] &&
                                currentState[`site${index}`][item.stateVar]
                                    ? currentState[`site${index}`][item.stateVar]
                                    : ''
                            }
                            options={option ? option : null}
                        />
                    })}

                    <Grid
                        container
                        style={marginGenerator('mt-48')}
                        className={classes.mapContainer}>
                        <PlacesMap
                            id={index}
                            handleSetPosition={handleSetPositionBase}
                            position={
                                currentState[`site${index}`] &&
                                currentState[`site${index}`]['latitude'] &&
                                currentState[`site${index}`]['longitude']
                                    ? {
                                        lat: parseFloat(currentState[`site${index}`]['latitude']),
                                        lng: parseFloat(currentState[`site${index}`]['longitude'])
                                    }
                                    : null
                            }
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        justify={'space-between'}
                        style={{
                            ...marginGenerator('mt-48'),
                            ...paddingGenerator('pt-16'),
                            ...paddingGenerator('pb-16')
                        }}>
                        <Grid>
                            <Typography variant="h4">{t('address.label')}</Typography>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <Input
                                    value={
                                        currentState[`site${index}`] &&
                                        currentState[`site${index}`]['address']
                                            ? currentState[`site${index}`]['address']
                                            : ''
                                    }
                                    placeholder={t('address.label')}
                                    type={'text'}
                                    color={'secondary'}
                                    onChange={evt =>
                                        handleInputChange(
                                            'address',
                                            evt.target.value,
                                            `site${index}`
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={
                                        currentState[`site${index}`] &&
                                        currentState[`site${index}`]['country']
                                            ? currentState[`site${index}`]['country']
                                            : ''
                                    }
                                    label={t('country.label')}
                                    handleChange={evt =>
                                        handleInputChange(
                                            'country',
                                            evt.target.value,
                                            `site${index}`
                                        )
                                    }
                                    options={countryList}
                                />
                                <OutlinedSelect
                                    val={
                                        currentState[`site${index}`] &&
                                        currentState[`site${index}`]['region']
                                            ? currentState[`site${index}`]['region']
                                            : ''
                                    }
                                    label={t('region.label')}
                                    handleChange={evt =>
                                        handleInputChange(
                                            'region',
                                            evt.target.value,
                                            `site${index}`
                                        )
                                    }
                                    options={regionList}
                                />
                            </Grid>
                            <Grid item style={marginGenerator('mt-24')}>
                                <Input
                                    style={marginGenerator('mr-16')}
                                    value={
                                        currentState[`site${index}`] &&
                                        currentState[`site${index}`]['landmark']
                                            ? currentState[`site${index}`]['landmark']
                                            : ''
                                    }
                                    type={'text'}
                                    color={'secondary'}
                                    placeholder={t('landmark.label')}
                                    onChange={evt =>
                                        handleInputChange(
                                            'landmark',
                                            evt.target.value,
                                            `site${index}`
                                        )
                                    }
                                />

                                <Input
                                    value={
                                        currentState[`site${index}`] &&
                                        currentState[`site${index}`]['zipcode']
                                            ? currentState[`site${index}`]['zipcode']
                                            : ''
                                    }
                                    placeholder={t('zipCode.label')}
                                    type={'number'}
                                    color={'secondary'}
                                    onChange={evt =>
                                        handleInputChange(
                                            'zipcode',
                                            evt.target.value,
                                            `site${index}`
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justify={'space-between'}
                        style={marginGenerator('mt-48')}>
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                handleResetConfigClick(`site${index}`);
                            }}>
                            {t('resetConfig.label')}
                        </Button>
                        {loadingBtn ? <CircularProgress size={20} color="primary" /> :
                        <Button
                            onClick={async () => {
                                let res = await handleSaveClick(`site${index}`);
                                if(res){
                                    setToggleState(false);
                                }
                                return null;
                            }}
                            color="primary"
                            variant={'contained'}>
                            {t('save&Continue.label')}
                        </Button>
                        }
                    </Grid>
                </Grid>
            )}
        </>
    );
};

ConfigureSiteItem.propTypes = {};

export default withTranslation()(ConfigureSiteItem);
