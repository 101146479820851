import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper
} from '@material-ui/core';
import { renderText } from '../../displayComponent';
import { FormProvider, RHFSelect } from '../../../../components/hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  getAllSourcePods,
  getDestinationPods,
  getNetworkQuery
} from '../../../../services/insightService';
import InsightPreview from '../InsightPreview';
import { marginGenerator } from '../../../../theme/utils';

const useStyles = makeStyles(theme => ({
  pageHeight: { height: '100vh' },
  cardContainer: {
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  heading: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px'
  }
}));

const InsightForm = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allSourcePods, setAllSourcePods] = useState([]);
  const [destPods, setDestPods] = useState([]);
  const [queryData, setQueryData] = useState('');

  const initialValues = {
    source: '',
    routingProtocol: '',
    routingProfile: '',
    destination: '',
    queryType: '',
    packetSize: ''
  };

  const validationSchema = yup.object().shape({
    source: yup.string().required('Required'),
    routingProtocol: yup.string().required('Required'),
    routingProfile: yup.string().required('Required'),
    destination: yup.string().required('Required'),
    queryType: yup.string().required('Required'),
    packetSize: yup.string().required('Required')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid }
  } = methods;

  const sourceId = watch('source');
  const watchRoutingProtocol = watch('routingProtocol');
  const watchDestination = watch('destination');

  const onSubmit = async data => {
    const payload = {
      source: data.source,
      routing_protocol: data.routingProtocol,
      routing_profile: data.routingProfile,
      destination: data.destination,
      query_type: data.queryType,
      packet_size: data.packetSize
    };
    setLoading(true);
    const res = await getNetworkQuery(payload);
    if (res?.data?.status === 200 || res?.data?.statusCode === 200) {
      setQueryData(res?.data?.message);

      setLoading(false);
    } else {
      setQueryData(res?.message || res?.data?.message);
      setLoading(false);
    }
  };

  const GetAllPortsFcrs = async () => {
    const res = await getAllSourcePods();
    setAllSourcePods(res?.data?.result?.data);
  };

  const GetDestinationPortsFcrs = async sourceId => {
    const res = await getDestinationPods(sourceId);
    setDestPods(res?.data?.result);
  };

  useEffect(() => {
    if (sourceId) {
      GetDestinationPortsFcrs(sourceId);
    }
  }, [sourceId]);

  useEffect(() => {
    GetAllPortsFcrs();
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={5}
        className={classes.pageHeight}>
        <Grid item justify="center">
          <Paper Component={Box} className={classes.heading}>
            <Grid item xs={12}>
              <Box p={1}>{renderText({ label: 'Insight' })}</Box>
            </Grid>
          </Paper>
        </Grid>
        <Container maxWidth="lg">
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              item
              container
              direction="column"
              spacing={2}
              className={classes.cardContainer}>
              <Grid item container justify="space-around">
                <Grid item sm={3}>
                  <label>Source</label>
                  <RHFSelect variant="outlined" name="source" size="small">
                    <option value="">Select</option>
                    {allSourcePods &&
                      allSourcePods?.map(option => (
                        <option key={option?.name} value={option?._id}>
                          {`${option?.zone}, ${option?.dc_id?.city}, ${option?.dc_id?.country}`}
                        </option>
                      ))}
                  </RHFSelect>
                </Grid>
                <Grid item sm={3}>
                  <label>Routing Protocol</label>
                  <RHFSelect
                    variant="outlined"
                    name="routingProtocol"
                    size="small"
                    disabled={!sourceId}>
                    <option value="">Select</option>
                    {[{ key: 'IPv4', value: 'ipv4' }]?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item sm={3}>
                  <label>Routing Profile</label>
                  <RHFSelect
                    variant="outlined"
                    name="routingProfile"
                    size="small"
                    disabled={!watchRoutingProtocol}>
                    <option value="">Select</option>
                    {[{ key: 'Standard', value: 'standard' }]?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
              </Grid>
              <Grid item container justify="space-around">
                <Grid item sm={3}>
                  <label>Destination</label>
                  <RHFSelect
                    variant="outlined"
                    name="destination"
                    size="small"
                    disabled={!watchRoutingProtocol}>
                    <option value="">Select</option>
                    {destPods &&
                      destPods?.map(option => (
                        <option key={option?.name} value={option?._id}>
                          {`${option?.zone}, ${option?.dc_id?.city}, ${option?.dc_id?.country}`}
                        </option>
                      ))}
                  </RHFSelect>
                </Grid>
                <Grid item sm={3}>
                  <label>Query Type</label>
                  <RHFSelect
                    variant="outlined"
                    name="queryType"
                    size="small"
                    disabled={!watchDestination}>
                    <option value="">Select</option>
                    {[
                      { key: 'Ping', value: 'ping' },
                      { key: 'Traceoute', value: 'transioute' }
                    ]?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item sm={3}>
                  <label>Packet Size</label>
                  <RHFSelect
                    variant="outlined"
                    name="packetSize"
                    size="small"
                    disabled={!watchDestination}>
                    <option value="">Select</option>
                    {[
                      { key: '100 Bytes', value: '100 Bytes' },
                      { key: '300 Bytes', value: '300 Bytes' },
                      { key: '1000 Bytes', value: '1000 Bytes' },
                      { key: '1300 Bytes', value: '1300 Bytes' }
                    ]?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
              </Grid>
              <Grid item container justify="center">
                <Button
                  variant="outlined"
                  type="submit"
                  size="large"
                  disabled={!isValid}
                  style={{ ...marginGenerator('mt-10') }}
                  disableElevation>
                  {!loading ? (
                    'Network Query'
                  ) : (
                    <CircularProgress color="secondary" size={20} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Container>
        {queryData && (
          <Grid item>
            <InsightPreview queryData={queryData} setQueryData={setQueryData} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InsightForm;
