import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { withTranslation } from 'react-i18next';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  },
  container: {
    padding: '10px'
  },
  msg: { fontSize: '15px', marginLeft: '25px', paddingBottom: '50px' }
}));

const CustomerTable = ({ data, column, t }) => {
  const classes = useStyles();
  return (
    <>
      {data != null ? (
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {column &&
                  column.map(column => (
                    <TableCell key={uuidv4()}>{column.name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && (
                <StyledTableRow key={uuidv4()}>
                  <TableCell>
                    {data.attributes.CircuitID
                      ? data.attributes.CircuitID[0]
                      : ''}
                  </TableCell>
                  <TableCell>{`${data.firstName} ${data.lastName}`}</TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{data.location}</TableCell>
                  <TableCell>{data.vendor}</TableCell>
                  <TableCell>{data.basestation}</TableCell>
                  <TableCell>{data.configured_DN_CIR}</TableCell>
                  <TableCell>{data.configured_UP_CIR}</TableCell>
                  <TableCell>{data.platform}</TableCell>
                  <TableCell>
                    {data.organisations.length
                      ? data.organisations[0].name
                      : ''}
                  </TableCell>
                  <TableCell>{data.role && data.role}</TableCell>
                  <TableCell>{data.status}</TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.msg}>{t('allCustomerDetails.label')}</div>
      )}
    </>
  );
};
export default withTranslation()(CustomerTable);
