import React, {useState, useEffect } from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@material-ui/core";


import {marginGenerator} from '../../../../../../theme/utils';
import commonStyles from '../../../../../../theme/commonStyles';
import SearchBox from '../../../../../../components/SearchBox';
import OcrConfigurationGroupsAndUsersItem from './OcrConfigurationGroupsAndUsersItem';
import MenuWrapper from '../../../../../../components/MenuWrapper';
import Message from '../../../../../../components/Message';
import CreateNewUserFromOcr from '../../components/CreateNewUserFromOcr';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
  }));
  

const OcrConfigurationGroupsAndUsers = ({
    t,
    assignProjectEnable,
    createGroupOrUserEnable,
    subscriptionUserListLoading,
    subscriptionUserData,
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIdFromUserList, setSelectedIdFromUserList] = useState('');
    const [isCreateNewUserEnable, setIsCreateNewUserEnable] = useState(false);

    const assignMenuItems = [
        { label: t('assignToProject.label') },
        { label: t('addToGroup.label') }
      ];
    // let columns = [
    //   {
    //       label:t('name.label'),
    //       key: "name"
    //   },
    //   {
    //     label:'Email address',
    //     key: "email"
    //   },
    //   {
    //       label: t('createdDate.label'),
    //       key: "createdDate"
    //   },
    //   {
    //       label: t('action.label'),
    //       key: "action"
    //   }
    // ];

    const columns = [
        {
          name: "id",
          label: t('id.label'),
          options: {
            filter: false,
            sort: false,
            display: false
          }
        },
        {
            name: "name",
            label: t('name.label'),
            options: {
              filter: false
            }
          },
        {
          name: "email",
          label: 'Email address',
          options: {
            filter: false
          }
        },
        {
          name: "id",
          label: t('action.label'),
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value,tableMeta) => {
              return (
                  <>
                <Grid>
                <IconButton
                    size={'small'}
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'secondary'}
                    onClick={evt => {
                        setAnchorEl(evt.currentTarget);
                    }}
                >
                    <MoreHoriz className={commonClasses.iconRegular} />
                </IconButton>
            </Grid>
            <MenuWrapper
                id={'ticket-menu'}
                anchorEl={anchorEl}
                handleClose={evt => {
                    evt.stopPropagation();
                    setAnchorEl(null);
                    setSelectedIdFromUserList('');
                }}
                menuItems={[...assignMenuItems]}
                handleMenuItemClick={elem => {
                  let selectedFiels = [];
                  if(selectedIdFromUserList && subscriptionUserData.length> 0){
                    selectedFiels = subscriptionUserData.filter(el => el.id === selectedIdFromUserList);
                  }
                  console.log('selectedFiels', selectedFiels);
                    setAnchorEl(null);
                    if(elem.label === t('assignToProject.label')){
                      assignProjectEnable(t('assignToProject.label'),
                        {
                         first_name: selectedFiels[0].firstName,
                         last_name: selectedFiels[0].lastName,
                         email: selectedFiels[0].email,
                         user_id: selectedFiels[0].id,
                         phone_no:null
                       }
                      );
                    } else if(elem.label === t('addToGroup.label')){
                      assignProjectEnable(t('addToGroup.label'),
                      [{
                        first_name: selectedFiels[0].firstName,
                        last_name: selectedFiels[0].lastName,
                        email: selectedFiels[0].email,
                        user_id: selectedFiels[0].id,
                        phone_no:null
                      }]);
                    }
                }}
            />
            </>
              )
            }
          }
        }
      ];

      const options = {
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive: 'standard',
        rowsPerPage: 10,
        download: false,
        print: false,
        viewColumns: false,
        selectableRowsHeader: false,
        onRowClick: (rowData, rowMeta) => {
          setSelectedIdFromUserList(rowData[0]);
        }
      };

      const handleCloseOfCreateUser = () =>  setIsCreateNewUserEnable(false)

  return (
    <>
    <Grid container style={{...marginGenerator("mb-20")}}>
        <Grid item xs="3">
          <Typography variant={'h2'}>Users</Typography>
          <Typography variant={'subtitle2'}>All users created by you</Typography>
        </Grid>
        <Grid item xs="6"/>
        <Grid item xs="1">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={()=>createGroupOrUserEnable(t('createGroup.label'))}
                >
                {t('groups.label')}
            </Button>
        </Grid>
        <Grid item xs="2">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={()=> setIsCreateNewUserEnable(true)}
                >
                {t('addAdmins.label')}
            </Button>
        </Grid>
    </Grid>
    <Grid container>
    <TableContainer>
    <MUIDataTable
      title={
        <Typography variant="h6">
          {subscriptionUserListLoading && (
            <CircularProgress
              size={24}
              style={{ marginLeft: 15, position: "relative", top: 4 }}
            />
          )}
        </Typography>
      }
      data={subscriptionUserData}
      t
      columns={columns}
      options={options}
    />
    </TableContainer>
        {/* <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!subscriptionUserListLoading ?
                      subscriptionUserData && subscriptionUserData.length > 0 ?
                      subscriptionUserData.map((rowitem, rowIndex) => (
                                <OcrConfigurationGroupsAndUsersItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    assignProjectEnable={assignProjectEnable}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {subscriptionUserListLoading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer> */}
        <CreateNewUserFromOcr
          t={t}
          open={isCreateNewUserEnable}
          onClose={handleCloseOfCreateUser}
        />
    </Grid>
    </>
  )
}

export default OcrConfigurationGroupsAndUsers