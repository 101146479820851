import React, {useState, useEffect} from 'react';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import OutlinedSelect from '../../../../../.././../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

const CreateSubFolder = ({
    t,
    open,
    onClose,
    handleSubmitOfOcrSubFolder,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    ocrSubFolderSubmit,
    placeLoading,
    projectLoading,
    folderLoading,
    handleFilterdataForOptions,
    isProjectDataExists=null
}) => {


    const classes = useStyles();
    const [folderName, setFolderName] = useState('');
    const [folderDescription, setFolderDescription] = useState('');
    const [locationValue, setLocationValue] = useState('');
    const [projectValue, setProjectValue] = useState('');
    const [folderValue, setFolderValue] = useState('');

  useEffect(() => {
    setFolderName('');
    setFolderDescription('');
    setLocationValue('');
    setProjectValue('');
    setFolderValue('');
    handleFilterdataForOptions(null, 'null');
  }, [!open]);

  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
             Create SubFolder
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
        <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
            <Grid item xs={2}>
                <OutlinedSelect
                    val={get(isProjectDataExists, 'parents.[0]', null) ? get(isProjectDataExists, 'parents.[0]', null):locationValue}
                    label="Select Loaction"
                    handleChange={evt => {
                      handleFilterdataForOptions(evt.target.value, 'project');
                        setLocationValue(evt.target.value);
                    }}
                    selectStyle={{...paddingGenerator('p-8')}}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={(!placeLoading && ocrPlaceData) ? ocrPlaceData.data : []}
                    disabled={get(isProjectDataExists, 'parents.[0]', null)}
                />
            </Grid>
            <Grid item xs={2}>
                <OutlinedSelect
                    val={get(isProjectDataExists, 'item.id', null) ? get(isProjectDataExists, 'item.id', null):projectValue}
                    label="Select Project"
                    handleChange={evt => {
                        setProjectValue(evt.target.value);
                    }}
                    selectStyle={{...paddingGenerator('p-8')}}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={(!projectLoading && ocrProjectData) ? ocrProjectData.data : []}
                    disabled={get(isProjectDataExists, 'item.id', null)}
                />
            </Grid>
            <Grid item xs={2}>
                <OutlinedSelect
                    val={get(isProjectDataExists, 'parents.[0]', null) ? get(isProjectDataExists, 'item.id', null):folderValue}
                    label="Select Folder"
                    handleChange={evt => {
                      setFolderValue(evt.target.value);
                    }}
                    selectStyle={{...paddingGenerator('p-8')}}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={(!folderLoading && ocrFolderData) ? ocrFolderData.data : []}
                    disabled={get(isProjectDataExists, 'item.id', null)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                  value={folderName}
                  id="outlined-search" 
                  label="SubFolder Name" 
                  type="search"
                  style={{ width: '100%'}}
                  onChange={(e) => setFolderName(e.target.value)}
                />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <TextField
                value={folderDescription}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                style={{ width: '100%'}}
                onChange={(e) => setFolderDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={onClose}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent 
                  t={t}
                  loading={ocrSubFolderSubmit}
                  onClick={()=> 
                    handleSubmitOfOcrSubFolder({
                                 folderName,
                                 folderDescription,
                                 locationValue: get(isProjectDataExists, 'parents.[0]', null)|| locationValue,
                                 projectValue:get(isProjectDataExists, 'item.id', null)||projectValue,
                                 folderValue: get(isProjectDataExists, 'item.id', null)||folderValue,
                                })
                  }
                />
            </Grid>          
        </DialogActions>
    </Dialog>
  );
};

export default CreateSubFolder;
