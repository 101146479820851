import { gql } from 'apollo-boost';

export const GET_USER_PRODUCTS = gql`
    query getUserProducts($page: Int, $search: String) {
        getUserProducts(page:$page, search : $search) {
            count
            previous
            next
            results
        }
    }
`;
