import React, { useState, useEffect, useContext } from 'react'
import {useMutation} from '@apollo/react-hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'

import {OcrContext} from '../../../../../../contexts/OcrProvider';
import {
  UPDATE_STATUS_OF_PROCESS
} from '../../../../Ocr/mutations';
import Message from '../../../../../../components/Message';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const OcrProcessApproveOrRejectModal = ({
    t,
    open,
    onClose,
    type,
    data
}) => {
    const classes = useStyles();
    const [approvalOrRejectComments, setApprovalOrRejectComments] = useState('');
    const { 
      organizationId,
      handleRefetchedOrFilter
    } = useContext(OcrContext);

    const [updateStatusOfProcessSteps, { loading: updateStatusOfProcessStepsLoading }] = useMutation(UPDATE_STATUS_OF_PROCESS, {
      onError: error => {
          console.log('err', error);
      },
      onCompleted: data => {
          Message.success(data && data.updateStatusProcessSteps.message);
          handleRefetchedOrFilter('stepsProcess', '');
          onClose();
      }
  });

    useEffect(() => {
      if(!open){
        setApprovalOrRejectComments('');
      }
    }, [open])
    
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
    disableBackdropClick={true} 
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose} />
        <DialogContent dividers className={classes.dialogContain}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
               <TextField
                  id="standard-multiline-flexible"
                  rows={2}
                  placeholder={type? 'Approved comments': 'Reject comments'}
                  style={{ width: '100%', marginTop: 12 }}
                  onChange={(e)=> setApprovalOrRejectComments(e.target.value)}
                  value={approvalOrRejectComments}
                  variant="standard"
                  multiline
                  name="comments"
                />
            </Grid>
          </Grid> 
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={onClose}
                style={{marginRight: 20}}
                color="secondary"
                variant="outlined"
            >
                {t('cancel.label')}
            </Button>
            <ButtonComponent 
                t={t} 
                onClick={() =>{
                  if(!approvalOrRejectComments) return Message.error('Please fill comments !!');
                    updateStatusOfProcessSteps({
                      variables:{
                        organization: organizationId,
                        processId: data && data._id,
                        status: type,
                        approvalOrRejectComments: approvalOrRejectComments,
                        fileId: data && data.fileId,
                        approved_user_id: data && data.approvalUserId
                      }
                    })
                  }
                }
                loading={updateStatusOfProcessStepsLoading}
            />      
        </DialogActions>
    </Dialog>
  )
}

export default OcrProcessApproveOrRejectModal