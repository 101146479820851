import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import 'fabric-webpack'
import Message from '../../components/Message';

const fabric = window.fabric
class VolumeLineCrossImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorInitialized: false,
      drawedLine: [],
      selectedImg: '',
      image: null,
      imageResolution: {},
      imageBase64Url: ""
    }
    // Fabric.js state
    this.lines = {}
    this.mouseDown = false;
  }

  roundOF2 = (num) => {
    return Math.round(num * 100) / 100;
  }

  initListeners() {

    this.editorCanvas.on('mouse:down', (o) => {
      this.mouseDown  = true;
      let pointer     = this.editorCanvas.getPointer(o.e);
      let points      = [ pointer.x, pointer.y, pointer.x, pointer.y ];
      // Potential cause of bug if 'recording' isn't
      // defined when we reach here
      this.lines['recording'] = new fabric.Line(points, {
        strokeWidth: 3,
        fill: 'red',
        stroke: 'red',
        originX: 'center',
        originY: 'center'
      });
      this.editorCanvas.add(this.lines['recording']);
    });
    
    this.editorCanvas.on('mouse:move', (o) => {
      if (!this.mouseDown) return;
      let pointer = this.editorCanvas.getPointer(o.e);
      this.lines['recording'].set({ x2: pointer.x, y2: pointer.y });
      // TODO STORE LINE DATA POINTS
      this.editorCanvas.renderAll();
    });
    
    this.editorCanvas.on('mouse:up', (o) => {
      if (!this.mouseDown) return;
      let { x1, y1, x2, y2 } = this.lines['recording'];
      let startX = this.roundOF2(parseInt(x1)/parseInt(this.state.imageResolution.width));
      let startY = this.roundOF2(parseInt(y1)/parseInt(this.state.imageResolution.height));
      let endX = this.roundOF2(parseInt(x2)/parseInt(this.state.imageResolution.width));
      let endY = this.roundOF2(parseInt(y2)/parseInt(this.state.imageResolution.height));
      this.setState({ drawedLine: [...this.state.drawedLine, {relativeCords: { startX, startY, endX, endY }, element: this.lines['recording'], name: ""}] });
      this.mouseDown = false;
    });
  }

  componentDidMount() {
    if(this.elCanvas) {
      this.editorCanvas = new fabric.Canvas(this.elCanvas, { selection: false, width: 0.1, height: 0.1 });
      this.initListeners();      
      if(localStorage.getItem("editLineCrossData")){
        let lineData = JSON.parse(localStorage.getItem("editLineCrossData"));
        if(lineData.image){
          console.log("line data", lineData);
          let imgData = lineData.image;
          let img = new Image();
          img.src = imgData;
          img.onload = (e) => {
            this.mainElem.style = {display:'block'};
            this.elCanvas.width = img.width;
            this.elCanvas.height = img.height;
            this.setState({imageResolution: {width: img.width, height: img.height}, imageBase64Url: lineData.image});
            this.editorCanvas.setDimensions({width: img.width, height: img.height});
            this.editorCanvas.setBackgroundImage(lineData.image, this.editorCanvas.renderAll.bind(this.editorCanvas), {
              backgroundImageOpacity: 0.5,
              backgroundImageStretch: false
            });
            if(lineData.lines && lineData.lines.length > 0){
              let existLines = [];
              for(let i = 0; i < lineData.lines.length; i++){
                let line = new fabric.Line(lineData.lines[i].pointers, {...lineData.lines[i].coordinates});              
                this.editorCanvas.add(line);
                existLines.push({ relativeCords: lineData.lines[i].relativeCords, element: line, name: lineData.lines[i].name });
              }
              this.setState({ drawedLine: existLines });
              localStorage.removeItem("editLineCrossData");
            }
          };
        }
      }
    }
  }

  handleOnSelectImage = (evt) => {
    if(evt.target.files && evt.target.files.length){
      if(this.state.drawedLine.length){
        this.editorCanvas.remove(this.state.drawedLine[0].element);
      }
      this.setState({selectedImg: evt.target.value});
      const file = evt.target.files[0];
      let img = new Image();
      img.src = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.mainElem.style = {display:'block'};
        this.elCanvas.width = img.width;
        this.elCanvas.height = img.height;
        this.setState({imageResolution: {width: img.width, height: img.height}});
        this.editorCanvas.setDimensions({width: img.width, height: img.height});
        this.editorCanvas.setBackgroundImage(reader.result, this.editorCanvas.renderAll.bind(this.editorCanvas), {
          backgroundImageOpacity: 0.5,
          backgroundImageStretch: false
        });
        if(localStorage.getItem("editLineCrossData")){
          let lineData = JSON.parse(localStorage.getItem("editLineCrossData"));
          if(lineData.lines && lineData.lines.length > 0){
            let existLines = [];
            for(let i = 0; i < lineData.lines.length; i++){
              let line = new fabric.Line(lineData.lines[i].pointers, {...lineData.lines[i].coordinates});              
              this.editorCanvas.add(line);
              existLines.push({ relativeCords: lineData.lines[i].relativeCords, element: line, name: lineData.lines[i].name });
            }
            this.setState({ drawedLine: existLines });
          }
          localStorage.removeItem("editLineCrossData");
        }
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({imageBase64Url: reader.result});
      };
      this.setState({image: evt.target.value});
    }
  }
  handleDeleteLine = (index) => {
    if(this.state.drawedLine[index]){
      this.editorCanvas.remove(this.state.drawedLine[index].element);
      const drawedLine = this.state.drawedLine;
      drawedLine.splice(index, 1);
      this.setState({ drawedLine });
    }
  }
  
  handleSaveCordinates = () => {
    let [checkValidate, checkUnique] = Array(2).fill(true);
    let drawedLines = this.state.drawedLine.map(item => {
      item.name = item.name.replace(/\s+/g, "_");
      if(item.element){
        item.pointers = [item.element.x1, item.element.y1, item.element.x2, item.element.y2];
        item.coordinates = {
          stroke: "red",
          strokeWidth: 3,
          top: item.element.top,
          width: item.element.width,
          originX: 'center',
          originY: 'center'
        }
      }
      delete item.element;
      if(!item.name){
        checkValidate = false;
      }
      return item;
    });
    if(!checkValidate){
      Message.error("Fields marked as * are required.");
      return null;
    }
    const uniqueValues = new Set(drawedLines.map(v => v.name));
    if (uniqueValues.size < drawedLines.length) {
      Message.error("Please enter a unique name for every line!");
      return null;
    }
    localStorage.setItem('linedata', JSON.stringify({linedata: drawedLines, line_img_url: this.state.imageBase64Url}));
    window.close();
  }

  handleChangeName = (value, index) => {
    let drawedLine = this.state.drawedLine;
    if(drawedLine[index]){
      drawedLine[index].name = value;
    }
    this.setState({ drawedLine });
  }

  render () {
    return (
      <>
        <div
          ref={el => this.mainElem = el}
          className="counting-areas-editor"
          style={{display:'none'}}
        >
          <canvas
            ref={(el) => this.elCanvas = el}
            width={0}
            height={0}
            className="editor-canvas" />
          <style jsx={'true'}>{`
            .editor-canvas{
              border:1px solid;
            }
            .counting-areas-editor{
              margin: 3%;
            }
            .counting-areas-editor, .editor-canvas  {
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: '2'};
            }

            {/* @media (min-aspect-ratio: 16/9) {
              :global(.canvas-container),.editor-canvas {
                width: 100% !important;
                height: auto !important;
              }
            }
            @media (max-aspect-ratio: 16/9) {
              :global(.canvas-container),.editor-canvas {
                width: auto !important;
                height: 100% !important;
              }
            } */}
          `}</style>
        </div>
        <div style={{marginBottom: 50}}>
          <Grid style={{marginBottom:20, marginLeft: '3%', width: '10%'}}>
            <Input
              value={this.state.selectedImg}
              type={'file'}
              placeholder={`Image *`}
              style={{ width: '100%', marginTop: "35px" }}
              color={'secondary'}
              inputProps={{accept:"image/*"}}
              onChange={this.handleOnSelectImage}
            />
          </Grid>
          {this.state.drawedLine.length > 0 && this.state.drawedLine.map((item, index) => {
            return (
              <Paper key={index} style={{width:"80%", marginLeft: '3%', marginBottom: '20px', padding: 16, backgroundColor: '#f9fafb', border: '1px solid #b6b6b6'}}>
                <Grid container spacing={3}>
                  <Grid item xs={3} style={{paddingTop:22}}>
                    <Input
                      value={item.name}
                      type={'text'}
                      variant="outlined"
                      placeholder={`Line ${index+1}*`}
                      color={'secondary'}
                      onChange={ evt => {
                        this.handleChangeName(evt.target.value, index);  
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{paddingTop:22}}>
                    <Typography variant="subtitle1" color={"primary"} style={{marginBottom: '6px'}}>
                      <b>Start X : </b> {item.relativeCords.startX}
                      <b>&nbsp;, Start Y : </b> {item.relativeCords.startY}
                    </Typography>
                    <Typography variant="subtitle1" color={"primary"}>
                      <b>End X :</b> {item.relativeCords.endX}
                      <b>&nbsp;, End Y :</b> {item.relativeCords.endY}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{paddingTop:22, textAlign: "center"}}>
                    <Fab onClick={evt => {this.handleDeleteLine(index)}} size="small" color="secondary" aria-label="add">
                      <DeleteIcon/>
                    </Fab>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
          <Grid style={{marginBottom:20, marginLeft: '3%', width: '10%'}}>
            <Button
              color="primary"
              variant={'contained'}
              disabled={!this.state.drawedLine.length>0}
              onClick={() => {
                this.handleSaveCordinates();
              }}
              style={{width: "279px"}}
            >
              Save
            </Button>
          </Grid>
        </div>
      </>
    )
  }
}

export default withTranslation()(VolumeLineCrossImage);