import { gql } from 'apollo-boost';
/*
  Get Notification Template Details By Id
 */
  export const GET_NOTIFICATION_TEMPLATE_LIST = gql`
  query NotificationTemplate($organization: String, $search: String, $page: Int, $limit: Int) {
      getAllNotificationTemplateList(organization: $organization, search: $search, page: $page, limit: $limit) {
          count
          templates
      }
  }
`;
/*
Get All Places List
*/
export const GET_IOT_PLACE_LIST = gql`
  query Place($subscriptionId: String, $search: String, $page: Int, $limit: Int) {
        getIOTPlaces(subscriptionId: $subscriptionId, search: $search, page: $page, limit: $limit) {
            count
            places
        }
  }
`;

/*
  Get Sensors List
 */
  export const GET_SENSOR_LIST = gql`
  query Sensor($search: String, $page: Int, $limit: Int, $status: String) {
      getAllSensorList(
          search: $search
          page: $page
          limit: $limit
          status: $status
      ) {
          count
          sensors
      }
  }
`;

/*
  Get Sensors List
 */
  export const GET_MAINTENANCE_LIST = gql`
  query IOTMaintenance($search: String, $page: Int, $limit: Int, $status: String) {
    getAllMaintenance(
        search: $search
        page: $page
        limit: $limit
        status: $status
      ) {
          count
          maintenance
      }
  }
`;