import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from 'react-router-dom';
import { TicketingSystem } from '../../../../routes/routePaths';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import { getUrl } from '../../../../utils';

const UserItem = ({ t, data }) => {
  const history = useHistory();
  const commonClasses = commonStyles();
  const handleItemClick = data => {
    const path = getUrl(TicketingSystem.NewServiceTicket);
    history.push(path);
  };
  return (
    <>
          <TableRow key={uuidv4()}>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Icon
                    component={() => (
                      <PersonOutlineIcon
                        color={'secondary'}
                        style={{ marginTop: '2px', marginRight: '6px' }}
                      />
                    )}
                    alt={'icon'}
                  />
                </Grid>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.firstName ? data.firstName : ""} 
                  {data.lastName ? data.lastName :""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>
                    {data.CircuitId ? data.CircuitId : ""}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>
                    {data.BaseStation ? data.BaseStation : ""}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>
                    {data.organizationName ? data.organizationName : ""}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.platform ? data.platform : ""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.vendor ? data.vendor : ""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.configured_UP_CIR ? data.configured_UP_CIR : ""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.configured_DN_CIR ? data.configured_DN_CIR : ""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Typography variant={'subtitle1'}>{data.customerName ? data.customerName : ""}</Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid className={commonClasses.column}>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleItemClick(data)}>
                    {t('createTicket.label')}
                  </Button>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
    </>
  );
};

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func
};

export default withTranslation()(UserItem);
