import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Location from '@material-ui/icons/LocationOn';
import PropTypes from 'prop-types';
import { colors } from '../../../../theme/colors';
import Button from '@material-ui/core/Button';
import ProgressBar from '../../../../components/ProgressBar';
import {
    SERVICE_TYPES,
    SUBSCRIPTION_STATUS
} from '../../../../utils/constants';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    itemContainer: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        flexGrow: 1
    },
    updateIndicator: {
        height: '6px',
        width: '6px',
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        background: theme.palette.success.main,
        borderRadius: '50%'
    }
}));
const PendingItem = ({
    t,
    data,
    handleOpenDiaConfigurationForm,
    handleSubscriptionItemClick,
    handleSubscriptionActiveClick,
    permisson,
    deleteSubscription,
    handleMenuItemClick
}) => {

    const [isDeleteSubscriptionEnable, setIsDeleteSubscriptionEnable] = useState(false);
    const [selectItemForDelete, setSelectItemForDelete] = useState(null);
    const commonClasses = commonStyles();
    const classes = useStyles();

    const handleClose = () => {
        setIsDeleteSubscriptionEnable(false);
    };
    return (
        <>
            <Grid
                container
                style={{ ...paddingGenerator('pt-8'), ...paddingGenerator('pb-24') }}
                className={classes.itemContainer}>
                <Grid item xs style={marginGenerator('mr-8')}>
                    <Typography variant={'h4'}>
                        {data.name
                            ? `${data.name} - ${data.service.name}`
                            : data.service.name}
                    </Typography>
                    {/* <Typography variant={'subtitle2'}>
                    {t('id.label').toUpperCase()} {data.ticketId} • {t('ticketRaisedOn.label')}{' '}
                    {dateFormatterLLL(data.createdAt)}
                    </Typography> */}
                    <Grid
                        container
                        item
                        style={{ ...marginGenerator('mt-16'), color: colors.error.main }}
                        alignItems={'center'}>
                        <Location
                            className={commonClasses.iconRegular}
                            style={marginGenerator('mr-10')}
                        />
                        {data.sites && data.sites.length && (
                            <Typography
                                variant={'subtitle2'}
                                style={{ color: colors.error.main }}>
                                {data.sites[0].name} {t('and.label')} {data.sites.length - 1}{' '}
                                {t('otherSites.label')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={permisson ? 6 : 4}
                    container
                    justify={'space-between'}
                    alignItems={'flex-start'}
                    style={marginGenerator('mt-8')}>
                    <ProgressBar
                        label={t('sitesActive.label')}
                        totalVal={data.sites ? data.sites.length : 0}
                        progressItem={
                            data.sites.filter(
                                item => item.status === SUBSCRIPTION_STATUS.ACTIVE
                            ).length
                        }
                    />
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => handleSubscriptionItemClick(data.id)}>
                        Track Progress
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => handleMenuItemClick({ label: t('edit.label') }, data.id)}>
                        Edit
                    </Button>
                    {permisson && (data.service.name === SERVICE_TYPES.DIA || data.service.name === SERVICE_TYPES.DIAN) ? (
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => handleOpenDiaConfigurationForm(data)}>
                            {t('activate.label')}
                        </Button>
                    ) : (
                        permisson && (
                            <>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => handleSubscriptionActiveClick(data)}>
                                    {t('activate.label')}
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => {
                                        setSelectItemForDelete(data);
                                        setIsDeleteSubscriptionEnable(true);
                                    }}
                                >
                                    {t('delete.label')}
                                </Button>
                            </>
                        )
                    )}
                </Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isDeleteSubscriptionEnable}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t('subscriptionDelete.label', { name: `${get(selectItemForDelete, 'name', null)} ${get(selectItemForDelete, 'service.name', null)}` })}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('cancel.label')}
                    </Button>
                    <Button onClick={() => {
                        deleteSubscription({
                            variables: {
                                id: get(selectItemForDelete, 'id', null)
                            }
                        })
                        handleClose();
                    }} color="primary" autoFocus>
                        {t('delete.label')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
PendingItem.propTypes = {
    data: PropTypes.object.isRequired,
    handleSubscriptionItemClick: PropTypes.func.isRequired
};
export default withTranslation()(PendingItem);
