import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TicketActive from '../../../../assets/icons/ticket_active.png';
import TicketInactive from '../../../../assets/icons/ticket_inactive.png';
import commonStyles from '../../../../theme/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { STATUS_TYPES, TICKET_PRIORITY } from '../../../../utils/constants';
import { dateFormatterLLL } from '../../../../utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import TicketStatus from '../TicketStatus';
import Avatar from '../../../../components/Avatar';
import FlagWarning from './../../../../assets/icons/flag_warning.png';
import FlagError from './../../../../assets/icons/flag_error.png';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';

const useStyles = makeStyles(theme => ({
  ticketContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    flexGrow: 1,
    cursor: 'pointer'
  },
  closedTitle: {
    color: theme.palette.grey[300]
  },
  updateIndicator: {
    height: '6px',
    width: '6px',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    background: theme.palette.success.main,
    borderRadius: '50%'
  }
}));

const TicketItem = ({
  t,
  data,
  handleTicketItemClick,
  handleMenuItemClick
}) => {
  const commonClasses = commonStyles();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let menus = [{ label: t('viewDetails.label') }];
  if (data.status === STATUS_TYPES.OPEN) {
    menus.push({ label: t('closeTicket.label') });
  }
  return (
    <Grid
      container
      style={{ ...paddingGenerator('pt-8'), ...paddingGenerator('pb-24') }}
      className={classes.ticketContainer}
      // onClick={() => handleTicketItemClick(data.id)}
      onClick={() => handleTicketItemClick(data, data.id)}>
      <Grid item>
        <img
          src={
            data.status === STATUS_TYPES.CLOSED ? TicketInactive : TicketActive
          }
          alt={'ticket'}
          style={marginGenerator('mt-8')}
          className={commonClasses.iconMedium}
        />
      </Grid>

      <Grid item style={marginGenerator('ml-24')}>
        <Typography
          variant={'h4'}
          className={
            data.status === STATUS_TYPES.CLOSED ? classes.closedTitle : ''
          }>
          {data.title}
          {moment(data.updatedAt).isSame(moment(), 'day') && (
            <sup>
              <div className={classes.updateIndicator} />
            </sup>
          )}
        </Typography>
        <Typography variant={'subtitle2'}>
          {t('id.label').toUpperCase()} {data.ticketId} •{' '}
          {t('ticketRaisedOn.label')} {dateFormatterLLL(data.createdAt)}
        </Typography>
        <Grid
          container
          item
          className={classes.playerContainer}
          style={marginGenerator('mt-16')}
          alignItems={'center'}>
          <Avatar name={data.createdByName} />
          <Typography variant={'subtitle2'}>
            {t('createdBy.label')} {data.createdByName}
          </Typography>
        </Grid>
        {data.status === STATUS_TYPES.CLOSED && (
          <Typography style={marginGenerator('mt-16')} variant={'subtitle2'}>
            {t('closedOn.label')} {dateFormatterLLL(data.updatedAt)}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs
        container
        justify={'flex-end'}
        alignItems={'flex-start'}
        style={marginGenerator('mt-8')}>
        <TicketStatus status={data.status} t={t} />
        {data.priority === TICKET_PRIORITY.MEDIUM ||
        data.priority === TICKET_PRIORITY.HIGH ? (
          <img
            src={
              data.priority === TICKET_PRIORITY.HIGH
                ? FlagError
                : data.priority === TICKET_PRIORITY.MEDIUM
                ? FlagWarning
                : ''
            }
            style={marginGenerator('ml-16')}
            className={commonClasses.iconMedium}
            alt={data.priority}
          />
        ) : (
          <div style={marginGenerator('ml-16')} />
        )}
        <IconButton
          size={'small'}
          disableRipple={true}
          disableFocusRipple={true}
          color={'secondary'}
          style={marginGenerator('ml-32')}
          onClick={evt => {
            setAnchorEl(evt.currentTarget);
            evt.stopPropagation();
          }}
          // className={classes.secondaryButtonStyle}
        >
          <MoreHoriz className={commonClasses.iconRegular} />
        </IconButton>
      </Grid>
      {anchorEl && (
        <MenuWrapper
          id={'ticket-menu'}
          anchorEl={anchorEl}
          handleClose={evt => {
            evt.stopPropagation();
            setAnchorEl(null);
          }}
          menuItems={menus}
          handleMenuItemClick={item => {
            handleMenuItemClick(item, data.id);
            setAnchorEl(null);
          }}
        />
      )}
    </Grid>
  );
};

TicketItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(TicketItem);
