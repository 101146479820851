import { Grid, Input } from '@material-ui/core';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import GenericTable from '../../../../../components/GenericTable';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../utils';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import DateTimeSelector from '../../../../../components/DateTimeSelector';

const OcrActivityLogs = ({
  t,
  list,
  filterList,
  loading,
  handleSubmitFilter,
  handlePageChange,
  page,
  handleExportClick,
  isExportFile
}) => {

  const [logsData, setLogsData] = React.useState([]); 
  let columns = [
    {
      label: t('action.label'),
      key: 'actionType'
    },
    {
      label: t('createdDate.label'),
      key: 'createdAt'
    },
    {
      label: t('createdBy.label'),
      key: 'createdByName'
    },
    {
      label: t('ip.label'),
      key: 'ipAddress'
    },
    {
      label: t('description.label'),
      key: 'description'
    },
  ];

  React.useEffect(() => {
    setLogsData((list && list.data) ? list.data.map(itm => {
      return {
        ...itm,
        createdAt: dateFormatterLLL(itm.createdAt),
        ipAddress: itm.ipAddress.client_ip
      }
    }) : []);
  }, [list]);

  return (
    <>
      <Grid container style={{ marginBottom: 24 }}>
        {filterList.length > 0 &&
          filterList.map((filter, index) => {
            if(filter.type === "text"){
              return (
                  <Input
                    key={index}
                    value={filter.val}
                    placeholder={filter.label}
                    onChange={filter.handleChange}
                    style={filter.style ? filter.style : null}
                  />
              );
            }else if(filter.type === "select"){
              return (<OutlinedSelect
                key={index}
                val={filter.val}
                label={filter.label}
                handleChange={filter.handleChange}
                options={filter.options}
                styleOverrides={filter.style || null}
              />);
            }else if(filter.type === "date"){
              return (
                <DateTimeSelector 
                  key={index} 
                  inputStyle={{ padding: 10, minWidth: 190 }} 
                  label={filter.label} 
                  dateFormat={"yyyy-MM-dd"} 
                  value={filter.val} 
                  handleChange={filter.handleChange} 
                  styleOverrides={filter.style || null} 
                  className={filter.className ? filter.className : null} 
                  disableFuture={true} 
                />
              )
            }
          })}
          <Button 
            onClick={handleSubmitFilter} 
            variant="outlined" 
            color="primary"
            style={{marginRight: 15}}
            disabled={loading}
            size="small" >
            {t("submit.label")}
          </Button>
          <Button 
            onClick={handleExportClick} 
            variant="outlined" 
            color="secondary"
            disabled={((logsData.length > 0) ? false : true || isExportFile)}
            size="small" >
            {t("export.label")}
          </Button>
      </Grid>
      <Grid container >
        <GenericTable
          loading={loading}
          columns={columns}
          list={logsData}
        />
        {!loading &&
          <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Grid item xs={4}>
              <span>Total {(list && list.count) ? Math.ceil(list.count / 20) : 0} page,  {list.count} Row</span>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                color="secondary"
                count={(list && list.count) ? Math.ceil(list.count / 20) : 0}
                page={page}
                onChange={(evt, value) => {
                  handlePageChange(value);
                }}
              />
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
};
export default withTranslation()(OcrActivityLogs);
