import React from 'react'

const OcrBillingPage = () => {
    return (
        <div>
           Billing 
        </div>
    )
}

export default OcrBillingPage
