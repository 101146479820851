import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import {
    UPDATE_PMO_PROJECT, UPDATE_PMO_PROJECT_TYPE, ADD_INSTALL_TEAM_LEAD,
    ADD_IM_TEAM, ADD_CHNAGE_CONTROL_TEAM, ADD_INTEGRATION_ENGINEER_TEAM, ADD_QUALITY_ASSURANCE_TEAM,
    ADD_PROJECT_MANAGEMENT_TEAM, ADD_FINANCE_TEAM, ADD_CSOC_TEAM, ADD_RADIO_ENGINEERING_TEAM,
    ADD_OUTSIDE_PROJECT_MANAGEMENT_TEAM, CREATE_CHANGE_CONTROL_REQUEST_FORM, UPLOAD_FILES, ADD_ACTIVATION_TEAM_BOTSWANA_SECTION, CREATE_FEETPORT_FORM,
    ADD_SERVICE_DELIVERY_TEAM, ADD_SERVICE_DELIVERY_TEAM_CAMEROON_SECTION, ADD_INTERNAL_PROJECTS, ADD_PROJECT_ZAMBIA_SECTION, CREATE_PROJECT_CHARTER_FORM, ADD_SALES_TEAM_IVORY_COAST_SECTION,
    ADD_BILLING_TEAM_MALAWI, ADD_QUALITY_ASSURANCE_TEAM_MALAWI
} from "../../mutations";

import SearchIcon from '@material-ui/icons/Search';
import { GET_PMO_PROJECTS, GET_PROJECTS_USER, GET_CUSTOMER_LIST, GET_PROJECT_RELETED_ALL_FORMS } from "../../queries";
import ProjectsItem from "../ProjectsItem";
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreateInstallTeamLeadModal from "../CreateInstallTeamLeadModal";
import CreateRadioEngineeringTeamModal from "../CreateRadioEngineeringTeamModal";
import CreateOutsideProjectManagementTeamModal from "../CreateOutsideProjectManagementTeamModal";
import Button from '@material-ui/core/Button';
import { GET_USER_INFO } from '../../../../Profile/queries';
import { GET_ALL_USER_BY_ROLES, GET_COUNTRY_LIST } from '../../../../commonQueries';
import CreateProjectInitiateModal from "../../../../Subscriptions/components/CreateProjectInitiateModal";
import Input from '@material-ui/core/Input';

import ProjectAttachmentModal from "../ProjectAttachmentModal";
import CreateActivationTeamModal from "../Botswana/Components/CreateActivationTeamModal";
import RejectProjectDescriptionModal from '../RejectProjectDescriptionModal';
import CreateServiceDeliveryTeamModal from '../Malawai/Components/CreateServiceDeliveryTeamModal';
import CreateFeetportTeamModal from '../Malawai/Components/CreateFeetportTeamModal';
import CreateMalawiQualityAssuranceTeamModal from "../Malawai/Components/CreateQualityAssuranceTeamModal";
// import CreateMalawiQualityAssuranceTeamModal from "../Malawai/Components/CreateBillingTeamModal";
import CreateNigeriaProjectManagementTeamModal from "../CreateProjectManagementTeamModal";
import CreateProjectZambiaTeamModal from '../Zambia/Components/CreateProjectTeamModal';
import CreateInternalProjectsModal from '../CreateInternalProjectsModal';
import CreateServiceDeliveryFormModal from '../Cameroon/Components/CreateServiceDeliveryFormModal';
import CreateProjectCharterTeamModal from '../Cameroon/Components/CreateProjectCharterFormModal';
import CreateSalesTeamModal from '../IvoryCoast/Components/CreateSalesTeamModal';
import NigeriaFormsUpdateDetailModal from '../NigeriaFormsUpdateDetailModal';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    floatRight: {
        float: 'right'
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const Projets = ({ t, refreshTab, handleRefreshTab }) => {
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const countryId = localStorage.getItem('countryId');
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [projectsUser, setProjectUser] = useState([]);
    const [installTeamSection, setInstallTeamSection] = useState(false);
    const [radioEngineeringTeam, setRadioEngineeringTeam] = useState(false);
    const [outsideProjectManagementTeam, setOutsideProjectManagementTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [selectedProjectTypeAndUid, setSelectedProjectTypeAndUid] = useState({ project_type: '', uid: '', case_number: '', opportunity_uid: '', customer_name: '', organization_name: '', auto_generated_project_id: '', project_owner: '', project_name: '', total_mrc: null, total_nrc: null });
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [AllInstallTeamUserList, setAllInstallTeamUserList] = useState([]);
    const [AllStockControlTeamUserList, setAllStockControlTeamUserList] = useState([]);
    const [AllBduTeamUserList, setAllBduTeamUserList] = useState([]);
    const [AllNocTeamUserList, setAllNocTeamUserList] = useState([]);
    const [AllItTeamUserList, setAllItTeamUserList] = useState([]);
    const [AllMalawiBillingUserList, setAllMalawiBillingUserList] = useState([]);
    const [AllNigeriaPMUserList, setAllNigeriaPMUserList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [changeControlRequestQuestionFormModalState, setChangeControlRequestQuestionFormModalState] = useState(false);
    const [ChangeControlRequestQuestionModalState, setChangeControlRequestQuestionModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [ChangeControlRequestFormUrl, setChangeControlRequestFormUrl] = useState('');
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [ShowBackButton, setShowBackButton] = useState(false);
    const [disableCloseProject, setDisableCloseProject] = useState(true);
    const [IsEmptySiteArray, setIsEmptySiteArray] = useState(false);
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [activationTeamSection, setActivationTeamSection] = useState(false);
    const [modalContentLoading, setModalContentLoading] = useState(false);
    const [rejectProjectDescription, setRejectProjectDescription] = useState({ open: false, btnLoading: false, clearData: false });
    const [serviceDeliveryTeam, setServiceDeliveryTeam] = useState(false);
    const [feetPortFormModalState, setFeetPortFormModalState] = useState({ open: false, btnLoading: false, clearData: false });
    const [feetportFormUrl, setFeetportFormUrl] = useState('');
    const [ProjectCharterFormModalState, setProjectCharterFormModalState] = useState({ open: false, btnLoading: false, clearData: false });
    const [ProjectCharterFormUrl, setProjectCharterFormUrl] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [internalProjectModalState, setInternalProjectModalState] = useState({ open: false, btnLoading: false, clearData: false });
    const [projectZambiaTeam, setProjectZambiaTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [SalesTeam, setSalesTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [serviceDeliveryCameroonTeam, setServiceDeliveryCameroonTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [MalawiQualityAssuranceTeam, setMalawiQualityAssuranceTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [NigeriaProjectManagementTeam, setNigeriaProjectManagementTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [customerList, setCustomerList] = useState([]);
    const [customerListLoading, setCustomerListLoading] = useState(false);
    const [ProjectRelatedForms, setProjectRelatedForms] = useState({ open: false, data: [] });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in Project list', error);
        },
        onCompleted: data => {
            // console.log("data : ", data);
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            setCurrentUserName(full_name);
            if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=" + full_name + "&billing_country=" + countryName
                    }
                })
            }
        }
    });
    const { data: countryList } = useQuery(GET_COUNTRY_LIST);
    // }

    const [addQualityAssuranceTeamMalawi] = useMutation(ADD_QUALITY_ASSURANCE_TEAM_MALAWI, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addQualityAssuranceTeamMalawi : ", data);
            if (JSON.parse(data.addQualityAssuranceTeamMalawiSection.status) === true) {
                handleRefreshTab(true);
                Message.success('New quality assurance team section created successfully');
                setModalState({ btnLoading: false, clearData: true });
                
                setMalawiQualityAssuranceTeam(false);
                setLoading(true);
              
                // let parseData = JSON.parse(data.addQualityAssuranceTeamMalawiSection.teamData);
                // if (selectedProjectType === 'Regular_project') {
                //     handleChangeControlTeam();
                // }
            } else {
                let message = JSON.parse(data.addQualityAssuranceTeamMalawiSection.message);
                Message.error(message);
            }
        }
    });


    const menuItems = [
        { label: t('Initiated') },
        { label: t('NACR sent for Signoff') },
        { label: t('NACR Not Required') },
        { label: t('NACR Approved') },
        { label: t('NACR Declined') },
        { label: t('Generate OPEX COST and New Service') },
        { label: t('Control Doc Sent for Approval') },
        { label: t('Generate Purchase Request') },
        { label: t('Request for Site Survey') },
        { label: t('Requested for Detailed Engineering Doc') },
        { label: t('Completed') },
    ];
    let projectsType = [];
    if (COUNTRY_LIST.NIGERIA === countryName) {
        projectsType.push({
            id: 'Regular project',
            name: 'Regular Project'
        },
            {
                id: 'High Capacity',
                name: 'High Capacity'
            },
            {
                id: 'Coverage Outside Inq',
                name: 'Coverage Outside Inq'
            },
            {
                id: 'Close Project',
                name: 'Close Project'
            })
    } else if (COUNTRY_LIST.BOTSWANA === countryName) {
        projectsType.push({
            id: 'Dedicated Internet Access',
            name: 'Dedicated Internet Access'
        },
            {
                id: 'Broadband',
                name: 'Broadband'
            },
            {
                id: 'Virtual Machine Service',
                name: 'Virtual Machine Service'
            },
            {
                id: 'Cloud Backup Services',
                name: 'Cloud Backup Services'
            },
            {
                id: 'Domain Services',
                name: 'Domain Services'
            },
            {
                id: 'Access Points',
                name: 'Access Points'
            },
            {
                id: 'FTTH',
                name: 'FTTH'
            },
            {
                id: 'Software Development',
                name: 'Software Development'
            },
            {
                id: 'Metro (MPLS) Services',
                name: 'Metro (MPLS) Services'
            },
            {
                id: 'Firewall Services',
                name: 'Firewall Services'
            },
            {
                id: 'Doc AI',
                name: 'Doc AI'
            },
            {
                id: 'Video AI',
                name: 'Video AI'
            },
            {
                id: 'IP Addresses',
                name: 'IP Addresses'
            },
            {
                id: 'Rack Hosting',
                name: 'Rack Hosting'
            },
            {
                id: 'Elastic',
                name: 'Elastic'
            },
            {
                id: 'Web Services',
                name: 'Web Services'
            },
            {
                id: 'Mimecast',
                name: 'Mimecast'
            },
            {
                id: 'Contract Renewal',
                name: 'Contract Renewal'
            },
        )
    } else if (COUNTRY_LIST.MALAWI === countryName || COUNTRY_LIST.ZAMBIA === countryName || COUNTRY_LIST.CAMEROON === countryName || COUNTRY_LIST.IVORY_COAST === countryName) {
        projectsType.push({
            id: 'Client Installation',
            name: 'Client Installation'
        },
            {
                id: 'Network Installation',
                name: 'Network Installation'
            },
            {
                id: 'Client Maintenance',
                name: 'Client Maintenance'
            },
            {
                id: 'Network Maintenance',
                name: 'Network Maintenance'
            },
            {
                id: 'Client Survey',
                name: 'Client Survey'
            },
            {
                id: 'Network Survey',
                name: 'Network Survey'
            },
            {
                id: 'Client Service Change - Upgrade',
                name: 'Client Service Change - Upgrade'
            },
            {
                id: 'Client Service Change - Downgrade',
                name: 'Client Service Change - Downgrade'
            })
    }
    if (COUNTRY_LIST.MALAWI === countryName) {
        projectsType.push({
            id: 'Contract Renewal',
            name: 'Contract Renewal'
        }, {
            id: 'License Renewal',
            name: 'License Renewal'
        }, {
            id: 'M365',
            name: 'M365'
        })
    }
    if (COUNTRY_LIST.CAMEROON === countryName) {
        projectsType.push({
            id: 'Internal Network',
            name: 'Internal Network'
        }, {
            id: 'Internal Infrastructure',
            name: 'Internal Infrastructure'
        })
    }
    if (COUNTRY_LIST.ZAMBIA === countryName){
        projectsType.push({
            id: 'Domains',
            name: 'Domains'
        })
    }
    useEffect(() => {
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&billing_country=" + countryName
                }
            })
        }
        getAllInstallTeamUserList({
            variables: {
                role: USER_ROLES.INSTALL_TEAM_LEAD
            }
        })
        getAllStockControleamUserList({
            variables: {
                role: USER_ROLES.STOCK_CONTROL_TEAM
            }
        })
        getSolutionTeamZambiaUserList({
            variables: {
                role: USER_ROLES.PROJECT_MANAGER
            }
        })
        getAllNocTeamUserList({
            variables: {
                role: USER_ROLES.NOC_TEAM
            }
        })
        getAllItTeamUserList({
            variables: {
                role: USER_ROLES.IT_TEAM
            }
        })
        getAllMalawiBillingUserList({
            variables: {
                role: USER_ROLES.BILLING_TEAM
            }
        })
        getAllNigeriaPMUserList({
            variables: {
                role: USER_ROLES.EP_FINANCE
            }
        })
        getProjectsUser({
            variables: {
                billingCountry: "billing_country=" + countryName
            }
        })
    }, []);
    const [getProjectsUser] = useLazyQuery(GET_PROJECTS_USER, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getProjectsUser) {
                let parseResult = JSON.parse(data.getProjectsUser.projectsUserData);
                parseResult = parseResult.map(item => {
                    return {
                        id: item.name,
                        name: item.name,
                    }
                })
                setProjectUser(parseResult);
            }
        },
    });
    // const [getProjectsUser] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
    //     onError: error => {
    //         // console.log('Error', error);
    //     },
    //     onCompleted: data => {
    //         let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
    //         setProjectUser(parseData);
    //     }
    // });
    const [updatePmoProjectType] = useMutation(UPDATE_PMO_PROJECT_TYPE, {
        onError: error => {
            console.log('Error', error);
            handleCloseInstallTeamModal();
            handleCloseOutsideProjectManagementTeam();
            setActivationTeamSection(false);
            //handleCloseRadioEngineeringTeam
        },
        onCompleted: data => {
            setModalContentLoading(false);
            Message.success('Project type updated successfully');
            setLoading(true);
            setError(true);
            var addProjectType = "";
            if (ShowBackButton === true) {
                addProjectType = "&project_type=Close Project"
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) {
                    setDisableCloseProject(false);
                }
            }
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=is_admin" + addProjectType + "&billing_country=" + countryName
                    }
                })
            } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + addProjectType + "&billing_country=" + countryName
                    }
                })
            }
        }
    });
    const [updatePmoProject] = useMutation(UPDATE_PMO_PROJECT, {
        onError: error => {
            console.log('Error updatePmoProject', error);
        },
        onCompleted: data => {
            if (rejectProjectDescription.open === true) {
                Message.success('Project rejected successfully');
                setRejectProjectDescription({ open: false, btnLoading: false, clearData: true });
            } else {
                Message.success('Project updated successfully');
            }
            setLoading(true);
            setError(true);
            var addProjectType = "";
            if (ShowBackButton === true) {
                addProjectType = "&project_type=Close Project";
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) {
                    setDisableCloseProject(false);
                }
            }
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=is_admin" + addProjectType + "&billing_country=" + countryName
                    }
                })
            } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + addProjectType + "&billing_country=" + countryName
                    }
                })
            }
        }
    });
    const [updatePmoProjectCategoryAndOnHold] = useMutation(UPDATE_PMO_PROJECT, {
        onError: error => {
            console.log('Error updatePmoProject', error);
        },
        onCompleted: data => {
            // console.log("data category on hold", data);
        }
    });
    const [getAllInstallTeamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role install: ", parseData);
                setAllInstallTeamUserList(parseData);
            }
        }
    });
    const [getAllStockControleamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role install: ", parseData);
                setAllStockControlTeamUserList(parseData);
            }
        }
    });
    const [getSolutionTeamZambiaUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role change: ", parseData);
                let filterByCountryId = [];
                filterByCountryId = parseData.filter((x) => {
                    if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                        return x.attributes.countryId[0] == countryId;
                    }
                })
                setAllBduTeamUserList(filterByCountryId);
            }
        }
    });
    const [getAllNocTeamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role change: ", parseData);
                setAllNocTeamUserList(parseData);
            }
        }
    });
    const [getAllItTeamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role change: ", parseData);
                setAllItTeamUserList(parseData);
            }
        }
    });
    const [getAllMalawiBillingUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            // console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data role change bdu: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            let filterByCountryId = [];
            filterByCountryId = parseData.filter((x) => {
                if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                    return x.attributes.countryId[0] == countryId;
                }
            })
            setAllMalawiBillingUserList(filterByCountryId);
        }
    });
    const [getAllNigeriaPMUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            // console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data role change bdu: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            let filterByCountryId = [];
            filterByCountryId = parseData.filter((x) => {
                if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                    return x.attributes.countryId[0] == countryId;
                }
            })
            setAllNigeriaPMUserList(filterByCountryId);
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        var addProjectType = "";
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        if (ShowBackButton === true) {
            addProjectType = "&project_type=Close Project"
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) {
                setDisableCloseProject(false);
            }
        }
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=" + value + "&project_owner=is_admin" + addProjectType + checkSearchFilterData + "&billing_country=" + countryName
                }
            })
        } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=" + value + "&project_owner=" + CurrentUserName + addProjectType + checkSearchFilterData + "&billing_country=" + countryName
                }
            })
        }
    };
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate + "&billing_country=" + countryName
                }
            })
        } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + "&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate + "&billing_country=" + countryName
                }
            })
        }
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&billing_country=" + countryName
                }
            })
        } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + "&billing_country=" + countryName
                }
            })
        }
    };
    const handleClosedProjectsList = (value) => {
        setShowBackButton(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) {
            setDisableCloseProject(false);
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&project_type=Close Project&billing_country=" + countryName
                }
            })
        } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_type=Close Project&project_owner=" + CurrentUserName + "&billing_country=" + countryName
                }
            })
        }
    };
    const handleOpenProjectsList = (value) => {
        setShowBackButton(false);
        setDisableCloseProject(true);
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&billing_country=" + countryName
                }
            })
        } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + "&billing_country=" + countryName
                }
            })
        }
    };
    let columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customerName"
        },
        {
            label: t('status.label'),
            key: "project_status"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('modified.label'),
            key: "modified"
        },
        {
            label: t('owner.label'),
            key: "project_owner"
        },
        {
            label: t('attachments.label'),
            key: "attachments"
        },
        {
            label: t('reject.label') + ' ' + t('project.label'),
            key: "reject"
        },
    ];
    // if (COUNTRY_LIST.NIGERIA === countryName){
    //     columns.push({
    //         label: t('viewDetails.label'),
    //         key: "viewDetails"
    //     });
    // }
    if (COUNTRY_LIST.MALAWI === countryName || COUNTRY_LIST.NIGERIA === countryName) {
        columns.push({
            label: t('proceedToBilling.label'),
            key: "button"
        });
    }
    if ((role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN))) {
        columns.push({
            label: t('action.label'),
            key: "button"
        });
    }
    const [getAllProjectList] = useLazyQuery(GET_PMO_PROJECTS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            if (data && data.getPmoProjectsList) {
                let parseResult = JSON.parse(data.getPmoProjectsList.projectData);
                //console.log("parseResult :", parseResult)
                setList(parseResult);
            }
        },
        onError: error => {
            console.log("pmo error :", error);
            setList([]);
            setLoading(false);
            setError(false);
        }
    });
    const [addInstallTeam] = useMutation(ADD_INSTALL_TEAM_LEAD, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addInstallTeam : ", data);
            if (data && data.addInstallTeamLeadSection) {
                if (JSON.parse(data.addInstallTeamLeadSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addInstallTeamLeadSection.teamData);
                    Message.success('New install team lead section created successfully');
                    setPreValuesOfForm({});
                    handleCloseInstallTeamModal();
                    // if (selectedProjectTypeAndUid.project_type === 'Regular project') {
                    //     handleImTeam();
                    // } else if (selectedProjectTypeAndUid.project_type === 'High Capacity' || selectedProjectTypeAndUid.project_type === 'Coverage Outside Inq') {
                    //     handleChangeControlTeam();
                    // }
                } else {
                    let message = JSON.parse(data.addInstallTeamLeadSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addActivationTeam] = useMutation(ADD_ACTIVATION_TEAM_BOTSWANA_SECTION, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addActivationTeam : ", data);
            if (data && data.addActivationTeamBotswanaSection) {
                if (JSON.parse(data.addActivationTeamBotswanaSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addActivationTeamBotswanaSection.teamData);
                    Message.success('New activation team section created successfully');
                    handleCloseInstallTeamModal();
                    setModalState({ btnLoading: false, clearData: true });
                    setActivationTeamSection(false);
                } else {
                    let message = JSON.parse(data.addActivationTeamBotswanaSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addServiceDeliveryCameroonTeam] = useMutation(ADD_SERVICE_DELIVERY_TEAM_CAMEROON_SECTION, {
        onError: error => {
            setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: false });
            if (data && data.addServiceDeliveryTeamCameroonSection) {
                if (JSON.parse(data.addServiceDeliveryTeamCameroonSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addServiceDeliveryTeamCameroonSection.teamData);
                    Message.success('New service delivery team section created successfully');
                    setServiceDeliveryCameroonTeam({ open: false, btnLoading: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addServiceDeliveryTeamCameroonSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addServiceDeliveryTeam] = useMutation(ADD_SERVICE_DELIVERY_TEAM, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            if (data && data.addServiceDeliveryTeamSection) {
                if (JSON.parse(data.addServiceDeliveryTeamSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addServiceDeliveryTeamSection.teamData);
                    Message.success('New service delivery team section created successfully');
                    setModalState({ btnLoading: false, clearData: true });
                    setServiceDeliveryTeam(false);
                } else {
                    let message = JSON.parse(data.addServiceDeliveryTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        if (feetPortFormModalState.open === true) {
            setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: true, clearData: false });
        } else if (internalProjectModalState.open === true) {
            setInternalProjectModalState({ ...internalProjectModalState, btnLoading: true, clearData: false });
        } else if (projectZambiaTeam.open === true) {
            setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: true, clearData: false, clearData: false });
        } else if (serviceDeliveryCameroonTeam.open === true) {
            setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: true, clearData: false });
        } else if (NigeriaProjectManagementTeam.opem === true) {
            setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: true, clearData: false });
        } else if (MalawiQualityAssuranceTeam.open === true) {
            setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: true, clearData: false });
        }
        //console.log("file in handle : ",file);
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false, clearData: false });
            if (feetPortFormModalState.open === true) {
                setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: false, clearData: false });
            } else if (internalProjectModalState.open === true) {
                setInternalProjectModalState({ ...internalProjectModalState, btnLoading: false, clearData: false });
            } else if (projectZambiaTeam.open === true) {
                setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: false, clearData: false });
            } else if (serviceDeliveryCameroonTeam.open === true) {
                setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: false, clearData: false });
            } else if (NigeriaProjectManagementTeam.opem === true) {
                setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: false, clearData: false });
            } else if (MalawiQualityAssuranceTeam.open === true) {
                setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: false, clearData: false });
            }
            console.log('Error', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ ...modalState, btnLoading: false, clearData: false });
            if (feetPortFormModalState.open === true) {
                setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: false, clearData: false });
            } else if (internalProjectModalState.open === true) {
                setInternalProjectModalState({ ...internalProjectModalState, btnLoading: false, clearData: false });
            } else if (projectZambiaTeam.open === true) {
                setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: false, clearData: false });
            } else if (serviceDeliveryCameroonTeam.open === true) {
                setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: false, clearData: false });
            } else if (NigeriaProjectManagementTeam.opem === true) {
                setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: false, clearData: false });
            } else if (MalawiQualityAssuranceTeam.open === true) {
                setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: false, clearData: false });
            }
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const [addRadioEngineeringTeam] = useMutation(ADD_RADIO_ENGINEERING_TEAM, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addRadioEngineeringTeam : ", data);
            if (data && data.addRadioEngineeringTeamSection) {
                if (JSON.parse(data.addRadioEngineeringTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New radio engineering team section created successfully');
                    let parseData = JSON.parse(data.addRadioEngineeringTeamSection.teamData);
                    setPreValuesOfForm(parseData);
                    handleCloseRadioEngineeringTeam();
                    if (selectedProjectTypeAndUid.project_type === 'High Capacity') {
                        setChangeControlRequestFormUrl('');
                        handleCreateInstallTeam();
                    }
                } else {
                    let message = JSON.parse(data.addRadioEngineeringTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addOutsideProjectManagementTeam] = useMutation(ADD_OUTSIDE_PROJECT_MANAGEMENT_TEAM, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addOutsideProjectManagementTeam : ", data);
            if (data && data.addOutsideProjectManagementTeamSection) {
                if (JSON.parse(data.addOutsideProjectManagementTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New outside project management team section created successfully');
                    let parseData = JSON.parse(data.addOutsideProjectManagementTeamSection.teamData);
                    setPreValuesOfForm(parseData);
                    handleCloseOutsideProjectManagementTeam();
                    setChangeControlRequestFormUrl('');
                    handleCreateInstallTeam();
                } else {
                    let message = JSON.parse(data.addOutsideProjectManagementTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleMenuItemClick = (elem, uid) => {
        if (
            window.confirm('Are you sure you want to make this person become an owner ?') ===
            false
        ) {
            return false;
        }
        // console.log("elem : ", elem);
        // console.log("uid : ", uid);
        return updatePmoProject({
            variables: {
                uid: uid,
                project_owner: elem,
                status: 'Assigned to PM'
            }
        });
    }
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleProjectTypeClick = (elem, data) => {
        setSelectedProjectTypeAndUid({
            project_type: elem,
            uid: data.uid,
            case_number: data.case_number,
            opportunity_uid: data.opportunity_uid,
            customer_name: data.customer_name,
            organization_name: data.organization_name,
            project_owner: data.project_owner,
            total_mrc: data.total_mrc,
            total_nrc: data.total_nrc,
            project_name: data.project_name,
            auto_generated_project_id: (data.auto_generated_project_id ? data.auto_generated_project_id : '')
        });
        if (
            window.confirm('Are you sure you want to update project type ?') ===
            false
        ) {
            return false;
        }
        setModalContentLoading(true);
        if (elem === 'Regular project' || elem === 'High Capacity') {
            setChangeControlRequestFormUrl('');
            handleCreateInstallTeam();
        }
        // else if (elem === 'High Capacity') {
        //     handleRadioEngineeringTeam();
        // } 
        else if (elem === 'Coverage Outside Inq') {
            handleOutsideProjectManagementTeam();
        } else if (COUNTRY_LIST.BOTSWANA === countryName) {
            setActivationTeamSection(true);
        } else if (COUNTRY_LIST.MALAWI === countryName) {
            setServiceDeliveryTeam(true);
        } else if (COUNTRY_LIST.ZAMBIA === countryName) {
            setProjectZambiaTeam({ open: true, btnLoading: false, clearData: true });
        } else if (COUNTRY_LIST.CAMEROON === countryName) {
            setServiceDeliveryCameroonTeam({ open: true, btnLoading: false, clearData: true });
        } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
            setSalesTeam({ open: true, btnLoading: false, clearData: true });
        }
        return updatePmoProjectType({
            variables: {
                uid: data.uid,
                project_type: elem
            }
        });
    }
    const handleSubmitInstallTeam = (formData) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addInstallTeam({
                variables: {
                    project_files: formData.projectFiles,
                    project_uid: formData.project_uid,
                    change_control_questionaire_form: formData.changeControlForm,
                    // affix_circuit_id: formData.affixCircuitId,
                    im_team: formData.attachIMPersonnel,
                    comment: formData.comment,
                    recommendation: formData.recommendation,
                    cost_control_form: formData.costControlForm,
                    previous_comment: formData.previous_comment,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                    site_array: formData.site_array,
                    documents: formData.attachAnyOtherDoc,
                    project_type: formData.project_type,
                    last_team_member_name: CurrentUserName,
                    last_team_member_email: userData.userInfo.email,
                }
            })
        }
    }
    const handleSubmitActivationTeam = (formData) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addActivationTeam({
                variables: {
                    project_uid: formData.project_uid,
                    client_name: formData.client_name,
                    location: formData.location,
                    assigned_Ip: formData.assigned_Ip,
                    allocated_bandwidth: formData.allocated_bandwidth,
                    base_station: formData.base_station,
                    domain_name: formData.domain_name,
                    contact_person_details: formData.contact_person_details,
                    email_package: formData.email_package,
                    vm_ip: formData.vm_ip,
                    vm_specs: formData.vm_specs,
                    backup_capacity: formData.backup_capacity,
                    number_of_machines: formData.number_of_machines,
                    next_team_member_email: '',
                    last_team_member_name: CurrentUserName,
                    // sideA_address: formData.sideA_address,
                    // sideB_address: formData.sideB_address,
                    // domain_owner: formData.domain_owner,
                    // domain_service_attached: formData.domain_service_attached,
                    // address_service: formData.address_service,
                    // capicity_allocated: formData.capicity_allocated,
                    vlan: formData.vlan,
                    service_id: formData.service_Id,
                    order_type: formData.order_type,
                    order_description: formData.order_description,
                    port_number: formData.port_number,
                    capacity: formData.capacity,
                    service_type: formData.service_type,
                    backup_type: formData.backup_type,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                }
            })
        }
    }
    const handleSubmitServiceDeliveryTeam = (formData) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addServiceDeliveryTeam({
                variables: {
                    project_uid: formData.project_uid,
                    feetport_form_url: formData.feetport_form_url,
                    stock_control_team: formData.stock_control_team,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                }
            })
        }
    }
    const handleSubmitServiceDeliveryCameroonTeam = (formData) => {
        setServiceDeliveryCameroonTeam({ ...serviceDeliveryCameroonTeam, btnLoading: true, clearData: false });
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addServiceDeliveryCameroonTeam({
                variables: {
                    project_uid: formData.project_uid,
                    project_charter_form_url: formData.project_charter_form_url,
                    upload_project_plan: formData.upload_project_plan,
                    noc_team: formData.noc_team,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                }
            })
        }
    }
    const handleSubmitRadioEngineeringTeam = (formData) => {
        // console.log("radio : ", formData);
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        addRadioEngineeringTeam({
            variables: {
                project_files: formData.projectFiles,
                recommendation: formData.recommendation,
                install_team: formData.installTeam,
                project_uid: formData.project_uid,
                comment: formData.comment,
                previous_comment: formData.previous_comment,
                equipments_to_be_deployed: "",
                auto_generated_project_id: "",
                last_team_member_name: CurrentUserName,
                last_team_member_email: userData.userInfo.email,
            }
        })
    }
    const handleSubmitOutsideProjectManagementTeam = (formData) => {
        setModalState({ ...modalState, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addOutsideProjectManagementTeam({
                variables: {
                    project_files: formData.projectFiles,
                    cost_control_form: formData.costControlForm,
                    install_team: formData.installTeam,
                    purchase_order_form: formData.purchaseOrderForm,
                    select_vendor: formData.vendor,
                    project_uid: formData.project_uid,
                    email_vendor: formData.vendorEmail,
                    auto_generated_project_id: "",
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    last_team_member_email: userData.userInfo.email,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                }
            })
        }
    }
    // open model
    const handleCreateInstallTeam = () => {
        setInstallTeamSection(true);
    }
    const handleOutsideProjectManagementTeam = () => {
        setOutsideProjectManagementTeam(true);
    }
    // close model
    const handleCloseInstallTeamModal = () => {
        setIsEmptySiteArray(true);
        setModalState({ btnLoading: false, clearData: true });
        setInstallTeamSection(false);
    }
    const handleCloseRadioEngineeringTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setRadioEngineeringTeam(false);
    }
    const handleCloseOutsideProjectManagementTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setOutsideProjectManagementTeam(false);
    }
    /**
     * Create Project Initiate Form Submit
     */
    const handleSaveProjectInitiate = (formData) => {
        setChangeControlRequestQuestionModalState({ ...ChangeControlRequestQuestionModalState, btnLoading: true, clearData: false });
        formData.project_name = selectedProjectTypeAndUid.uid;
        formData.opportunity = selectedProjectTypeAndUid.opportunity_uid;
        formData.opportunityId = selectedProjectTypeAndUid.opportunity_uid;
        SaveChangeControlRequestQuestionForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [SaveChangeControlRequestQuestionForm] = useMutation(CREATE_CHANGE_CONTROL_REQUEST_FORM, {
        onError: error => {
            setChangeControlRequestQuestionModalState({ ...ChangeControlRequestQuestionModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data : ", data);
            setChangeControlRequestQuestionModalState({ ...ChangeControlRequestQuestionModalState, btnLoading: false });
            if (data && data.createCostControlRequest) {
                if (JSON.parse(data.createCostControlRequest.status) === true) {
                    let parseData = JSON.parse(data.createCostControlRequest.teamData);
                    setChangeControlRequestFormUrl(parseData.pdf_link);
                    Message.success('Change control request questionarie form created successfully');
                    setChangeControlRequestQuestionFormModalState(false);
                    setInstallTeamSection(true);
                } else {
                    let message = JSON.parse(data.createCostControlRequest.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSaveFeetportForm = (formData) => {
        setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: true, clearData: false });
        CreateFeetportForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [CreateFeetportForm] = useMutation(CREATE_FEETPORT_FORM, {
        onError: error => {
            setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            setFeetPortFormModalState({ ...feetPortFormModalState, btnLoading: false });
            if (data && data.createFeetportForm) {
                if (JSON.parse(data.createFeetportForm.status) === true) {
                    let parseData = JSON.parse(data.createFeetportForm.teamData);
                    setFeetportFormUrl(parseData.link);
                    Message.success('Service delivery feetport form created successfully');
                    setFeetPortFormModalState({ ...feetPortFormModalState, open: false, clearData: true });
                    // setInstallTeamSection(true);
                } else {
                    let message = JSON.parse(data.createFeetportForm.message);
                    Message.error(message);
                }
            }
        }
    });

    const handleSaveProjectCharterForm = (formData) => {
        setProjectCharterFormModalState({ ...ProjectCharterFormModalState, btnLoading: true, clearData: false });
        CreateProjectCharterForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [CreateProjectCharterForm] = useMutation(CREATE_PROJECT_CHARTER_FORM, {
        onError: error => {
            setProjectCharterFormModalState({ ...ProjectCharterFormModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            setProjectCharterFormModalState({ ...ProjectCharterFormModalState, btnLoading: false });
            if (data && data.createProjectCharterForm) {
                if (JSON.parse(data.createProjectCharterForm.status) === true) {
                    let parseData = JSON.parse(data.createProjectCharterForm.teamData);
                    setProjectCharterFormUrl(parseData.link);
                    Message.success('Service delivery ProjectCharter form created successfully');
                    setProjectCharterFormModalState({ ...ProjectCharterFormModalState, open: false, clearData: true });
                    // setInstallTeamSection(true);
                } else {
                    let message = JSON.parse(data.createProjectCharterForm.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleOpenChangeControlRequestQuestionForm = () => {
        setChangeControlRequestQuestionFormModalState(true);
    }
    const handleRejectProjectClick = async (data) => {
        setSelectedProjectTypeAndUid({
            project_type: data.project_type,
            uid: data.uid,
            case_number: data.case_number,
            opportunity_uid: data.opportunity_uid,
            customer_name: data.customer_name,
            organization_name: data.organization_name,
            project_owner: data.project_owner,
            project_name: data.project_name,
            total_mrc: data.total_mrc,
            total_nrc: data.total_nrc,
            auto_generated_project_id: (data.auto_generated_project_id ? data.auto_generated_project_id : '')
        });
        setRejectProjectDescription({ ...rejectProjectDescription, open: true, btnLoading: false });
    }
    const handleSubmitRejectProjectDescription = async (data) => {
        setRejectProjectDescription({ ...rejectProjectDescription, open: true, btnLoading: true, clearData: false });
        setLoading(true);
        setError(true);
        await updatePmoProject({
            variables: {
                uid: data.uid,
                is_rejected: true,
                rejected_description: data.description,
                billing_country: countryName
            }
        });
        return handleRefreshTab(true);
    }
    const handleSaveInternalProjectForm = (formData) => {
        setInternalProjectModalState({ ...internalProjectModalState, btnLoading: true, clearData: false });
        addInternalProjectPMO({
            variables: {
                project_name: formData.project_name,
                project_description: formData.project_description,
                billing_country: formData.billing_country,
                opportunity: {
                    opportunity_name: formData.opportunity_name,
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email
                },
                attachments: formData.attachments,
                is_internal_project: true,
                customer_name: "Inq Internal Projects"
            }
        })
    }
    const [addInternalProjectPMO] = useMutation(ADD_INTERNAL_PROJECTS, {
        onError: error => {
            setInternalProjectModalState({ ...internalProjectModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            setInternalProjectModalState({ ...internalProjectModalState, btnLoading: false });
            if (data && data.addInternalProject) {
                if (JSON.parse(data.addInternalProject.status) === true) {
                    Message.success('New internal project created successfully');
                    setInternalProjectModalState({ ...internalProjectModalState, open: false, clearData: true });
                    setLoading(true);
                    setError(true);
                    if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY)) {
                        getAllProjectList({
                            variables: {
                                pageNumberAndOwner: "page=1&project_owner=is_admin&billing_country=" + countryName
                            }
                        })
                    } else if ((role.includes(USER_ROLES.PROJECT_MANAGER)) && !(role.includes(USER_ROLES.PMO_ADMIN))) {
                        getAllProjectList({
                            variables: {
                                pageNumberAndOwner: "page=1&project_owner=" + CurrentUserName + "&billing_country=" + countryName
                            }
                        })
                    }
                } else {
                    let message = JSON.parse(data.addInternalProject.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSaveProjectZambiaTeam = (formData) => {
        setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // console.log("formdata : ", formData);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addProjectZambiaTeam({
                variables: {
                    project_uid: formData.project_uid,
                    account_name: formData.account_name,
                    account_number: formData.account_number,
                    equipment_type: formData.equipment_type,
                    solution_team: formData.solution_team,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                }
            })
        }
    }
    const [addProjectZambiaTeam] = useMutation(ADD_PROJECT_ZAMBIA_SECTION, {
        onError: error => {
            setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: false, clearData: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data zambia : ", data);
            setProjectZambiaTeam({ ...projectZambiaTeam, btnLoading: false, clearData: false });
            if (data && data.addProjectZambiaTeamSection) {
                // console.log("data.addProjectZambiaTeamSection : ", data.addProjectZambiaTeamSection)
                if (JSON.parse(data.addProjectZambiaTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New project created successfully');
                    setProjectZambiaTeam({ ...projectZambiaTeam, open: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addProjectZambiaTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSaveSalesTeam = (formData) => {
        setSalesTeam({ ...SalesTeam, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // console.log("formdata : ", formData);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addSalesTeam({
                variables: {
                    project_uid: formData.project_uid,
                    salesforce: formData.salesforce,
                    client_name: formData.client_name,
                    physical_address: formData.physical_address,
                    contact_person: formData.contact_person,
                    gps_cordinate: formData.gps_cordinate,
                    email: formData.email,
                    delivery_due_date: formData.delivery_due_date,
                    tax_payer_number: formData.tax_payer_number,
                    rccm: formData.rccm,
                    it_team: formData.it_team,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                }
            })
        }
    }
    const [addSalesTeam] = useMutation(ADD_SALES_TEAM_IVORY_COAST_SECTION, {
        onError: error => {
            setSalesTeam({ ...SalesTeam, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data ivory : ", data);
            setSalesTeam({ ...SalesTeam, btnLoading: false });
            if (data && data.addSalesTeamIvoryCoastSection) {
                // console.log("data.addSalesTeamIvoryCoastSection : ", data.addSalesTeamIvoryCoastSection)
                if (JSON.parse(data.addSalesTeamIvoryCoastSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New sales team created successfully');
                    setSalesTeam({ ...SalesTeam, open: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addSalesTeamIvoryCoastSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleOpenProceedToBilling = (data) => {
        setSelectedProjectTypeAndUid({
            project_type: data.project_type,
            uid: data.uid,
            case_number: data.case_number,
            opportunity_uid: data.opportunity_uid,
            customer_name: data.customer_name,
            organization_name: data.organization_name,
            project_owner: data.project_owner,
            project_name: data.project_name,
            total_mrc: data.total_mrc,
            total_nrc: data.total_nrc,
            auto_generated_project_id: (data.auto_generated_project_id ? data.auto_generated_project_id : '')
        });
        if (COUNTRY_LIST.MALAWI === countryName) {
            setMalawiQualityAssuranceTeam({ open: true, btnLoading: false, clearData: true });
            // CreateMalawiQualityAssuranceTeamModal({ open: true, btnLoading: false, clearData: true });
        } else if (COUNTRY_LIST.NIGERIA === countryName) {
            setNigeriaProjectManagementTeam({ open: true, btnLoading: false, clearData: true });
        }
    }
    const handleSaveMalawiQualityAssuranceTeam = (formData) => {
      
        setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // console.log("formdata malawi : ", formData);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addQualityAssuranceTeamMalawi({
                variables: {
                    project_uid: formData.project_uid,
                    job_order_form_url: formData.job_order_form_url,
                    feetport_form_url: formData.feetport_form_url,
                    confirm_installation_standar: formData.confirm_installation_standar,
                    assign_to_billing_to_bill: formData.assign_to_billing_to_bill,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                }
            })
        }
    }
    const [addMalawiQualityAssuranceTeam] = useMutation(ADD_BILLING_TEAM_MALAWI, {
        onError: error => {
            setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: false, clearData: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data malawi : ", data);
            setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, btnLoading: false, clearData: false });
            if (data && data.addBillingTeamMalawiSection) {
                // console.log("data.addBillingTeamMalawiSection : ", data.addBillingTeamMalawiSection)
                if (JSON.parse(data.addBillingTeamMalawiSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New biling team section created successfully');
                    setMalawiQualityAssuranceTeam({ ...MalawiQualityAssuranceTeam, open: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addBillingTeamMalawiSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSaveNigeriaProjectManagementTeam = (formData) => {
        setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: true, clearData: false });
        handleRefreshTab(false);
        // console.log("formdata : ", formData);
        // update project category and on_hold
        if (formData.category != '' && formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                    status: formData.on_hold
                }
            });
        } else if (formData.category != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    category: formData.category,
                }
            });
        } else if (formData.on_hold != '') {
            updatePmoProjectCategoryAndOnHold({
                variables: {
                    uid: formData.project_uid,
                    status: formData.on_hold
                }
            });
        }
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addNigeriaProjectManagementTeam({
                variables: {
                    change_control_form: formData.changeControlForm,
                    circuit_id: formData.circuitId,
                    attach_manual: formData.attachMannual,
                    config_change_form: formData.configChangeForm,
                    ping_result: formData.pingResult,
                    send_email: formData.sendEmail,
                    finance_detail: formData.finance_detail,
                    job_completion_certificate: formData.jobCompletionCertificate,
                    fault_information_management_matrix: formData.faultInformationManagementMatrix,
                    letter_service_details: formData.letterServiceDetails,
                    billing_start_date: formData.billingStartDate,
                    comment: formData.comment,
                    project_uid: formData.project_uid,
                    finance: formData.finance,
                    previous_comment: formData.previous_comment,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                    auto_generated_project_id: formData.auto_generated_project_id,
                    finance_email: formData.finance_email,
                    bit_type: formData.bit_type,
                    next_team_member_email: formData.next_team_member_email,
                    last_team_member_name: CurrentUserName,
                    last_team_member_email: userData.userInfo.email,
                    end_date: formData.end_date,
                    occurrence: formData.occurrence,
                    billend_indicator: formData.billend_indicator,
                    charge_period: formData.charge_period,
                    prorated: formData.prorated,
                    network_code: formData.network_code,
                    supplier_base_cost: formData.supplier_base_cost,
                    charge_start_date: formData.charge_start_date,
                    charge_end_date: formData.charge_end_date,
                }
            })
        }
    }
    const [addNigeriaProjectManagementTeam] = useMutation(ADD_PROJECT_MANAGEMENT_TEAM, {
        onError: error => {
            setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: false, clearData: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data zambia : ", data);
            setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, btnLoading: false, clearData: false });
            if (data && data.addProjectManagementTeamSection) {
                // console.log("data.addProjectManagementTeamSection : ", data.addProjectManagementTeamSection)
                if (JSON.parse(data.addProjectManagementTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New biling team section created successfully');
                    setNigeriaProjectManagementTeam({ ...NigeriaProjectManagementTeam, open: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addProjectManagementTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [getCustomerList] = useLazyQuery(GET_CUSTOMER_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setCustomerListLoading(false);
            setError(false);
            if (data && data.getCustomerList) {
                let parseResult = JSON.parse(data.getCustomerList.teamListData);
                setCustomerList(parseResult);
            } else {
                setList([]);
            }
        },
        onError: error => {
            console.log("error in Customer List PM Team:", error)
            setCustomerList([])
            setCustomerListLoading(false);
            setError(false);
        }
    });
    const handleChangeCustomerList = (data) => {
        setCustomerListLoading(true);
        getCustomerList({
            variables: {
                search: "search=" + data
            }
        })
    }
    const handleProjectRowClick = (data) => {
        setSelectedProjectTypeAndUid({
            project_type: data.project_type,
            uid: data.uid,
            case_number: data.case_number,
            opportunity_uid: data.opportunity_uid,
            customer_name: data.customer_name,
            organization_name: data.organization_name,
            project_owner: data.project_owner,
            project_name: data.project_name,
            total_mrc: data.total_mrc,
            total_nrc: data.total_nrc,
            auto_generated_project_id: (data.auto_generated_project_id ? data.auto_generated_project_id : '')
        });
        getProjectrelatedAllForms({
            variables: {
                uid: data.uid
            }
        })
    }
    const [getProjectrelatedAllForms] = useLazyQuery(GET_PROJECT_RELETED_ALL_FORMS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            // setCustomerListLoading(false);
            setError(false);
            if (data && data.getProjectRelatedAllForms) {
                let parseResult = JSON.parse(data.getProjectRelatedAllForms.projectData);
                // console.log("get reletd form data : ",parseResult);
                setProjectRelatedForms({ open: true, data: parseResult });
            } else {
                setProjectRelatedForms({ open: false, data: [] });
            }
        },
        onError: error => {
            console.log("error in get :", error)
            setProjectRelatedForms({ open: false, data: [] })
            // setCustomerListLoading(false);
            setError(false);
        }
    });
    const handleUpdateRelatedFormClick = (FormNumber, data) => {
        if (FormNumber === 1) {
            // Open update form for install team lead

        }
    }
    return (
        <>
            {/*  {(role.includes(USER_ROLES.EP_IT) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) &&
            <Header
                title={t('myIncidents.label')}
                subText={`${t('myIncidents.label')}`}
                // primaryButtonText={t('createNew.label')}
                //handlePrimaryButtonClick={handleCreateButtonClick}
            />}*/}
            <Grid container>
                <Grid item xs={7}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid style={{ margin: '0px 0px 15px 0px' }}>
                        {/* <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ marginRight: '15px', float: 'right' }}
                            onClick={() => {
                                setInternalProjectModalState({ open: true, btnLoading: false, clearData: true });
                            }}
                        >{t('addInternalProjects.label')}
                        </Button> */}
                        {ShowBackButton &&
                            <Button
                                variant={'outlined'}
                                visibility="hidden"
                                color={'primary'}
                                style={{ marginRight: '15px', float: 'right' }}
                                onClick={handleOpenProjectsList}
                            >{t('back.label')}
                            </Button>
                        }
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ marginRight: '15px', float: 'right' }}
                            onClick={handleClosedProjectsList}
                        >{t('closeProjects.label')}
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <ProjectsItem data={item} key={index} handleMenuItemClick={handleMenuItemClick} handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleRejectProjectClick={handleRejectProjectClick} handleOpenProceedToBilling={handleOpenProceedToBilling}
                                            disableCloseProject={disableCloseProject} menuItems={menuItems} projectsUser={projectsUser} projectsType={projectsType}
                                            handleProjectTypeClick={handleProjectTypeClick} handleProjectRowClick={handleProjectRowClick} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateInstallTeamLeadModal
                    modalContentLoading={modalContentLoading}
                    open={installTeamSection}
                    handleSubmitClick={handleSubmitInstallTeam}
                    t={t}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    handleClose={handleCloseInstallTeamModal}
                    isActionUpdate={false}
                    modalState={modalState}
                    AllInstallTeamUserList={AllInstallTeamUserList}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    IsEmptySiteArray={IsEmptySiteArray}
                    handleUploadFiles={handleUploadFiles}
                    ChangeControlRequestFormUrl={ChangeControlRequestFormUrl}
                    handleOpenChangeControlRequestQuestionForm={handleOpenChangeControlRequestQuestionForm}
                />
                <CreateRadioEngineeringTeamModal modalContentLoading={modalContentLoading} open={radioEngineeringTeam} handleSubmitClick={handleSubmitRadioEngineeringTeam} t={t} CurrentUserName={CurrentUserName}
                    handleClose={handleCloseRadioEngineeringTeam} modalState={modalState} AllInstallTeamUserList={AllInstallTeamUserList} selectedProjectTypeAndUid={selectedProjectTypeAndUid} />
                <CreateOutsideProjectManagementTeamModal modalContentLoading={modalContentLoading} open={outsideProjectManagementTeam} handleSubmitClick={handleSubmitOutsideProjectManagementTeam} t={t}
                    handleClose={handleCloseOutsideProjectManagementTeam} handleUploadFiles={handleUploadFiles} modalState={modalState} AllInstallTeamUserList={AllInstallTeamUserList} selectedProjectTypeAndUid={selectedProjectTypeAndUid} />
                <CreateActivationTeamModal
                    t={t}
                    modalContentLoading={modalContentLoading}
                    open={activationTeamSection}
                    handleSubmitClick={handleSubmitActivationTeam}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    handleClose={() => {
                        setModalState({ btnLoading: false, clearData: true });
                        setActivationTeamSection(false);
                    }}
                    modalState={modalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                />
                <CreateServiceDeliveryTeamModal
                    t={t}
                    modalContentLoading={modalContentLoading}
                    open={serviceDeliveryTeam}
                    handleSubmitClick={handleSubmitServiceDeliveryTeam}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    AllStockControlTeamUserList={AllStockControlTeamUserList}
                    feetportFormUrl={feetportFormUrl}
                    handleOpenFeetportForm={() => {
                        setFeetPortFormModalState({ ...feetPortFormModalState, open: true, clearData: true });
                    }}
                    handleClose={() => {
                        setModalState({ btnLoading: false, clearData: true });
                        setServiceDeliveryTeam(false);
                    }}
                    modalState={modalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleUploadFiles={handleUploadFiles}
                />
                {/* change control request questionaire */}
                <CreateProjectInitiateModal
                    t={t}
                    open={changeControlRequestQuestionFormModalState}
                    handleSaveProjectInitiate={handleSaveProjectInitiate}
                    headingName={t('changeControlQuestionaireForm.label')}
                    subheading={false}
                    // data={data}
                    handleClose={() => {
                        setChangeControlRequestQuestionModalState({ btnLoading: false, clearData: true });
                        setChangeControlRequestQuestionFormModalState(false);
                    }}
                    modalState={ChangeControlRequestQuestionModalState}
                />
                <ProjectAttachmentModal
                    open={ProjectAttachment.open}
                    t={t}
                    handleClose={() => {
                        setProjectAttachment({ open: false, data: [] });
                    }}
                    attachmentList={ProjectAttachment.data}
                />
                <RejectProjectDescriptionModal
                    open={rejectProjectDescription.open}
                    t={t}
                    modalState={rejectProjectDescription}
                    handleClose={() => {
                        setRejectProjectDescription({ open: false, btnLoading: false, clearData: true });
                    }}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleSubmitClick={handleSubmitRejectProjectDescription}
                />
                <CreateFeetportTeamModal
                    t={t}
                    open={feetPortFormModalState.open}
                    handleSubmitClick={handleSaveFeetportForm}
                    handleClose={() => {
                        setFeetPortFormModalState({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={feetPortFormModalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleUploadFiles={handleUploadFiles}
                />
                <CreateInternalProjectsModal
                    t={t}
                    open={internalProjectModalState.open}
                    handleSubmitClick={handleSaveInternalProjectForm}
                    handleClose={() => {
                        setInternalProjectModalState({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={internalProjectModalState}
                    handleUploadFiles={handleUploadFiles}
                    countryList={countryList}
                    userData={userData}
                />
                <CreateProjectZambiaTeamModal
                    t={t}
                    open={projectZambiaTeam.open}
                    handleSubmitClick={handleSaveProjectZambiaTeam}
                    handleClose={() => {
                        setProjectZambiaTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={projectZambiaTeam}
                    handleUploadFiles={handleUploadFiles}
                    modalContentLoading={modalContentLoading}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    allUserList={AllBduTeamUserList}
                />
                <CreateServiceDeliveryFormModal
                    t={t}
                    open={serviceDeliveryCameroonTeam.open}
                    allUserList={AllNocTeamUserList}
                    modalContentLoading={modalContentLoading}
                    handleSubmitClick={handleSubmitServiceDeliveryCameroonTeam}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    ProjectCharterFormUrl={ProjectCharterFormUrl}
                    handleUploadFiles={handleUploadFiles}
                    handleOpenProjectCharterForm={() => {
                        setProjectCharterFormModalState({ ...ProjectCharterFormModalState, open: true, clearData: true });
                    }}
                    handleClose={() => {
                        setServiceDeliveryCameroonTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={serviceDeliveryCameroonTeam}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                />
                <CreateProjectCharterTeamModal
                    t={t}
                    open={ProjectCharterFormModalState.open}
                    handleSubmitClick={handleSaveProjectCharterForm}
                    handleClose={() => {
                        setProjectCharterFormModalState({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={ProjectCharterFormModalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleUploadFiles={handleUploadFiles}
                />
                <CreateSalesTeamModal
                    t={t}
                    open={SalesTeam.open}
                    handleSubmitClick={handleSaveSalesTeam}
                    handleClose={() => {
                        setSalesTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={SalesTeam}
                    handleUploadFiles={handleUploadFiles}
                    modalContentLoading={modalContentLoading}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    allUserList={AllItTeamUserList}
                />
                <CreateMalawiQualityAssuranceTeamModal
                    handleUploadFiles={handleUploadFiles}
                    open={MalawiQualityAssuranceTeam.open}
                    handleSubmitClick={handleSaveMalawiQualityAssuranceTeam}
                    t={t}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    preValuesOfForm={{}}
                    showPreValueOfForm={false}
                    handleClose={() => {
                        setMalawiQualityAssuranceTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={MalawiQualityAssuranceTeam}
                    allUserList={AllMalawiBillingUserList}
                />
                <CreateNigeriaProjectManagementTeamModal
                    t={t}
                    open={NigeriaProjectManagementTeam.open}
                    handleUploadFiles={handleUploadFiles}
                    handleSubmitClick={handleSaveNigeriaProjectManagementTeam}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    preValuesOfForm={{}}
                    showPreValueOfForm={false}
                    handleClose={() => {
                        setNigeriaProjectManagementTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={NigeriaProjectManagementTeam}
                    allUserList={AllNigeriaPMUserList}
                    isActionUpdate={false}
                    customerList={customerList}
                    customerListLoading={customerListLoading}
                    handleChangeCustomerList={handleChangeCustomerList}
                    handleViewCompleteDetailClick={''}
                    handleProjectAttachmentClick={''}
                />
                <NigeriaFormsUpdateDetailModal
                    t={t}
                    open={ProjectRelatedForms.open}
                    data={ProjectRelatedForms.data}
                    handleUpdateRelatedFormClick={handleUpdateRelatedFormClick}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleClose={() => {
                        setProjectRelatedForms({ open: false, data: [] });
                    }}
                    modalState={ProjectRelatedForms}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(Projets);
