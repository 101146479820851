import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import Grid from '@material-ui/core/Grid';
import Statcard from '../../../../../../components/Statcard';
import BarChartWrapper from '../../../../../../components/Charts/BarChartWrapper';
import { useLazyQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

const getLast7Days = (X) => {
    var dates = [];
    for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString());
    }
    return dates;
}
const chartData = getLast7Days(-20).map(itm => {
    const count = parseInt(Math.random() * (30 - 1) + 1);
    return { date: itm, count }
});
const initMapFilter = {time: 'Today', startDate: null, endDate: null, placeId: '', modelType: ''};
const useStyles = makeStyles(theme => ({
    mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2),
      position: "relative",
      overflow: "hidden"
    },
    dateInput: {
      width: 250,
      minWidth: 200,
      padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    }
  }));

const DashboardPanel = ({
    t,
    subscriptionData
}) => {
    
    const classes = useStyles();
    const [mapPlaceList, setMapPlaceList] = useState([]);
    const [eventsAndAlertsTimelineState, setEventsAndAlertsTimelineState] = useState({...initMapFilter});
    const [statCardData, setStatCardData] = useState({ totalDepartment: 6, totalSection: 24, totalMeeting: 18, totalDoc: 450 });

    const statList = [
        {
            label: "No of Departments",
            value: statCardData.totalDepartment,
            type: "no_of_departments"
        },
        {
            label: "No of Sections",
            value: statCardData.totalSection,
            type: "no_of_sections"
        },
        {
            label: "Number of Meetings",
            value: statCardData.totalMeeting,
            type: "no_of_meetings"
        },
        {
            label: "Number of Docs",
            value: statCardData.totalDoc,
            type: "no_of_docs"
        }
    ];

    const closeInfoWindow = () => {

    }

    const eventAlertTimelineFilter = [
        {
            label: t("startDate.label"),
            handleChange:(newDate) => {
            },
            val: eventsAndAlertsTimelineState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange:(newDate) => {
            },
            val: eventsAndAlertsTimelineState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                setEventsAndAlertsTimelineState({...eventsAndAlertsTimelineState, placeId: newValue});
            },
            val: eventsAndAlertsTimelineState.placeId,
            options: [],
            loading: false,
            type: "autocomplete",
            onInputChange: (val) => {
                
            },
            handleClose: (evt) => {
            },
            styleOverrides: { marginRight: 0, display: "inline-block", width: 220, marginTop: 10 },
        }
    ];
    

    return (
        <>
            {/* <Grid container spacing={2}> */}
                <ChartContainer
                title={null}
                filterList={eventAlertTimelineFilter}
                clearFilter={null}>
                </ChartContainer>
            {/* </Grid> */}
            <Grid
                container
                justify={'space-between'}
                style={{ marginTop: 20 }}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                    />
                ))}
            </Grid>
            <ChartContainer
                customMargin={{ marginBottom: 32, marginTop: 24 }}
                title={subscriptionData?.service ? subscriptionData.service.name + " Chart" : "Chart"} >
                <BarChartWrapper
                    data={chartData}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                />
            </ChartContainer>
        </>
    );
};

DashboardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default DashboardPanel;