import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ALL_SERVICE_TICKETS_LIMIT } from '../queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { TicketingSystem } from '../../../routes/routePaths';
import Grid from '@material-ui/core/Grid';
import { getParam } from '../../../utils';
import { IS_PARENT } from '../../../utils/constants';
import Pagination from '@material-ui/lab/Pagination';
import TicketPanel from '../components/TicketPanel';
import LinearProgress from '@material-ui/core/LinearProgress';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Customers },
  { label: 'customersDetail.label', link: TicketingSystem.CustomersDetails }
];

const UsersList = ({ t }) => {
  const id = getParam();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [customerTicketList, setCustomerTicketList] = useState(1);
  const [customerTicketCount, setCustomerTicketCount] = useState(1);
  /*
        List Queries
    */
  const [getCustomerDetailList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
        setLoading(false)
        setCustomerTicketList(res.allServiceticketsWithLimit.data)
        setCustomerTicketCount(res.allServiceticketsWithLimit.total)
    }
  });
  
  React.useEffect(() => {
    if(id){
        getCustomerDetailList({
        variables: {
            isParent: IS_PARENT.TRUE,
            page: page,
            customer : id
        }
      })
    }
  },[])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleTicketItemClick = () => {}
  const handleMenuItemClick = () => {}
 
  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('customersTicketList.label')}
        subText={`${t('customersTicketSubTxt.label')}`}
      />
     <TicketPanel
        ticketList={customerTicketList}
        type={'customerTicket'}
        t={t}
        loading = {loading}
        uniqueId = ''
        filterList={[]}
        handleTicketItemClick={handleTicketItemClick}
        handleMenuItemClick ={handleMenuItemClick}
     />
      <Pagination color="secondary" size="large" style={{float:"right", padding:"20px 0"}} 
            count={customerTicketCount ? Math.ceil(customerTicketCount / 30 )  : 0} page={page} onChange={handlePageChange} 
        />
    </Layout>
  );
};
export default withTranslation()(UsersList);
