import { gql } from 'apollo-boost';

/*
  Add Update Country
 */
export const ADD_UPDATE_COUNTRY = gql`
  mutation AddUpdateCountry($name: String, $code: String, $emails: String, $regions: String, $defaultLang: String, $id: String, $addressLine1: String, $addressLine2: String){
    addUpdateCountry(name: $name, defaultLang: $defaultLang, code: $code, emails: $emails, regions: $regions, id: $id, addressLine1: $addressLine1, addressLine2: $addressLine2) {
      id
    }
  }
`;
/*
  Add Update Country
 */
  export const ADD_REGIONS_TO_COUNTRY = gql`
  mutation AddRegions($regions: [JSON], $countryId: String){
    addRegionsToCountry(regions: $regions, countryId: $countryId) {
      id
    }
  }
`;
