import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import commonStyles from '../../../../theme/commonStyles';
import BusinessIcon from '@material-ui/icons/Business';
import Icon from '@material-ui/core/Icon';
import {useState} from 'react';
import {marginGenerator} from '../../../../theme/utils';
import PriorityPanel from '../PriorityPanel';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ChartContainer from "../../../../components/Charts/ChartContainer";
import {TIME_FILTER} from "../../../../utils/constants";
import BarChartWrapper from "../../../../components/Charts/BarChartWrapper";
import {useLazyQuery} from '@apollo/react-hooks';
import { dateFormatterLLL, convertUTCDateToLocalDate, getStartEndTimeForReport, getEventImageUrl, renderEdgeAITags, dateFormatterWithTimeZoneLLLHHMMSS } from '../../../../utils';
import { GET_LAST_10_DAYS_EVENTS, GET_MODEL_EVENTS, GET_PLACE_EVENTS } from '../../queries';
import Button from '@material-ui/core/Button';
import videoIcon from '../../../../assets/icons/video-icon.png';

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px'},
    chartTitle: {marginTop: '10px'}
}));
function OrganizationEventsPanel({
        t,
        organization,
        top10Events,
        handleImageModalClick,
        handleShowEventTags,
        aiModelTypesFilters
    }) {
    const classes = useStyles();
    const [modelState, setModelState] = useState('');
    const [modelDateState, setModelDateState] = useState('Today');
    const [placeDateState, setPlaceDateState] = useState('Today');
    const [last10DaysEvents, setLast10DaysEvents] = useState([]);
    const [modelEvents, setModelEvents] = useState([]);
    const [placeEvents, setPlaceEvents] = useState([]);
    const [timeZoneData, setTimeZoneData] = useState('');
    
    const [getLast10DaysEvents] = useLazyQuery(GET_LAST_10_DAYS_EVENTS, {
        fetchPolicy: 'no-cache',
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            let eventsData = [];
            if (data.getEventsByDateGraph.length) {
                eventsData = data.getEventsByDateGraph.map((item) => {
                    let date = new Date(item.date);
                    item.date = date.toISOString().slice(0, 10);
                    return item;
                });
            }
            setLast10DaysEvents(eventsData);
        }
    });
    
    const [getModelEvents] = useLazyQuery(GET_MODEL_EVENTS, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            setModelEvents(data.getEventsByModelGraph);
        }
    });

    const [getPlaceEvents] = useLazyQuery(GET_PLACE_EVENTS, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            setPlaceEvents(data.getEventsByPlaceGraph);
        }
    });

    React.useEffect(() => {
        let filterBy = {
            variables: {
                organization: organization,
                startTime: getStartEndTimeForReport('Last_10_Days').startTime,
                endTime: getStartEndTimeForReport('Last_10_Days').endTime
            }
        }
        if (modelState) {
            filterBy.variables.modelType = modelState;
        }
        getLast10DaysEvents(filterBy);
        
    }, [modelState]);
    
    React.useEffect(() => {
        let filterBy = {
            variables: {
                organization: organization,
                startTime: getStartEndTimeForReport(modelDateState).startTime,
                endTime: getStartEndTimeForReport(modelDateState).endTime
            }
        }
        getModelEvents(filterBy);
    }, [modelDateState]);
    
    React.useEffect(() => {
        let filterBy = {
            variables: {
                organization: organization,
                startTime: getStartEndTimeForReport(placeDateState).startTime,
                endTime: getStartEndTimeForReport(placeDateState).endTime
            }
        }
        getPlaceEvents(filterBy);
    }, [placeDateState]);

    React.useEffect(() => {
        const timeDetails = localStorage.getItem('timeZoneDetails');

        if(timeDetails){
         setTimeZoneData(timeDetails)
        }
     }, []);
    
    const modelFilter = [{
        label: t('filterByModel.label'),
        handleChange: evt => {
            setModelState(evt.target.value);
        },
        val: modelState,
        options: aiModelTypesFilters,
        styleOverrides: {'minWidth': '300px'}
    }
    ];
    const modelDateFilter = [{
        label: t('filterByDate.label'),
        handleChange: evt => {
            setModelDateState(evt.target.value);
        },
        val: modelDateState,
        options: TIME_FILTER,
        styleOverrides: {'minWidth': '300px'}
    }];
    const placeDateFilter = [{
        label: t('filterByDate.label'),
        handleChange: evt => setPlaceDateState(evt.target.value),
        val: placeDateState,
        options: TIME_FILTER,
        styleOverrides: {'minWidth': '300px'}
    }];
    const columns = [
        {
            label: t('places.label'),
            key: "place"
        },
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('eventDateTime.label'),
            key: "eventDateTime"
        },
        {
            label: t('detectedModel.label'),
            key: "detectedModel"
        },
        {
            label: t('priority.label'),
            key: "priority"
        },
        {
            label: t('tags.label'),
            key: "tags"
        },
        {
            label: t('imageSnap.label'),
            key: "imageSnap"
        }
    ];
    const commonClasses = commonStyles();
    return (
        <div style={{overflow: 'hidden'}}>
            <Grid style={{...marginGenerator('mt-16'), ...marginGenerator('mb-15')}}>
                <Typography variant="h4"><strong>{t('topEvent.label')}</strong></Typography>
            </Grid>
            <Grid container>
                <TableContainer style={{maxHeight: '342px'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {top10Events.map((rowitem, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell key={rowIndex}>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                                <Icon
                                                    component={() => (
                                                        <BusinessIcon
                                                            color={'secondary'}
                                                            style={{marginTop: '2px', marginRight: '6px'}}
                                                        />
                                                    )}
                                                    alt={'icon'}
                                                />
                                            </Grid>
                                            <Grid>
                                                <Typography variant={'subtitle1'}>
                                                    {rowitem.place_name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                                <Typography variant={'subtitle1'}> {rowitem.camera_name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                                {/* <Typography variant={'subtitle1'}>
                                                {rowitem.event_time && rowitem.event_time.slice(-1) !== "Z" ? <Typography variant={'subtitle2'}>{dateFormatterLLL(rowitem.event_time + "Z")}</Typography> : dateFormatterLLL(convertUTCDateToLocalDate(rowitem.event_time)) }
                                                </Typography> */}
                                                <Typography variant={'subtitle1'}>
                                                {dateFormatterWithTimeZoneLLLHHMMSS((rowitem.event_time && rowitem.event_time.includes("Z")) ? rowitem.event_time : rowitem.event_time, timeZoneData)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                                <Typography variant={'subtitle1'}>
                                                    {rowitem.tags && rowitem.tags.event_type ? rowitem.tags.event_type : rowitem.type ? rowitem.type : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                                <PriorityPanel priority={(rowitem.priority === 1) ? 'P1' : rowitem.priority === 2 ? 'P2' : 'P3'} t={t}/>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Button 
                                            style={{width: 120, overflow: "hidden"}}
                                            color="primary" 
                                            onClick={() => handleShowEventTags(rowitem.tags)}>
                                                {renderEdgeAITags(rowitem.tags)}
                                            </Button>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid className={classes.root}>
                                                {/* <Avatar style={{cursor: 'pointer'}} alt="" src={getEventImageUrl(rowitem.image)} onClick={(evt) => {
                                                    handleImageModalClick(getEventImageUrl(rowitem.image));
                                                }}/> */}
                                                <img
                                                    onClick={(evt) => {
                                                        handleImageModalClick(getEventImageUrl(rowitem.image));
                                                    }}
                                                    src={getEventImageUrl(rowitem.image) ? getEventImageUrl(rowitem.image).includes("mp4") ? videoIcon : getEventImageUrl(rowitem.image) : ""}
                                                    alt={'event'}
                                                    style={{ height: 50, width: 50, cursor:'pointer', borderRadius: "50%" }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} style={{...marginGenerator('mt-24'), ...marginGenerator('mb-15')}}>
                    <ChartContainer
                        title={t('lastTenDays.label')}
                        children={null}
                        filterList={modelFilter}>
                        <BarChartWrapper
                            data={last10DaysEvents}
                            dataKeyChart={'count'}
                            dataKeyXAxis={'date'}
                        />
                    </ChartContainer>
                </Grid>
                <Grid item xs={12} style={{...marginGenerator('mt-24'), ...marginGenerator('mb-15')}}>
                    <ChartContainer
                        title={t('models.label')}
                        children={null}
                        filterList={modelDateFilter}>
                        <BarChartWrapper
                            data={modelEvents}
                            dataKeyChart={'count'}
                            dataKeyXAxis={'model'}
                        />
                    </ChartContainer>
                </Grid>
                <Grid item xs={12} style={{...marginGenerator('mt-24'), ...marginGenerator('mb-30')}}>
                    <ChartContainer
                        title={t('places.label')}
                        children={null}
                        filterList={placeDateFilter}>
                        <BarChartWrapper
                            data={placeEvents}
                            dataKeyChart={'count'}
                            dataKeyXAxis={'name'}
                        />
                    </ChartContainer>
                </Grid>
            </Grid>
        </div>
    );
}
OrganizationEventsPanel.propTypes = {
    t: PropTypes.func.isRequired,
    organization: PropTypes.string,
    top10Events: PropTypes.array
};
export default OrganizationEventsPanel;
