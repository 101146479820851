import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InqLogo from '../../../../assets/icons/INQ_Logo_home.png';
// import InqLogo from '../../../../assets/';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import MenuWrapper from '../../../../components/MenuWrapper';
import i18n from './../../../../i18n';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { colors } from '../../../../theme/colors';
import Button from '@material-ui/core/Button';
import { paddingGenerator } from '../../../../theme/utils';
import { Paths } from '../../../../routes/routePaths';
import { checkInternalPlatform } from '../../../../utils/constants';

const hostName = window.location.hostname;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  logo: {
    height: '65px',
  },
  edgedockLogo: {
    height: '5rem'
  },
  primaryOptions: {
    marginRight: theme.spacing(2)
  },
  secondaryMenu: {
    display: 'flex',
    position: 'absolute',
    right: 80
  },
  selector: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    borderRight: `1px solid white`,
    padding: theme.spacing(1, 3)
  },
  active: {
    color: '#fff'
  },
  border: {
    border: `1px solid primary`,
    color: 'white'
  },
  dynamicLogo: {
    height: 60,
    padding: "6px 10px",
    borderRadius: 10,
    backgroundColor: "#ffff"
  },
}));


function Navbar({ history, t, scope, hostLogoUrl = null }) {

  const [dnsLogo, setDnsLogo] = useState(null);
  const otherPlatform = checkInternalPlatform();


  useEffect(() => {
    if (hostLogoUrl) {
      setDnsLogo(hostLogoUrl);
    }
  }, [hostLogoUrl]);

  const menuItems = [
    { label: t('english.label'), value: 'en' },
    { label: t('french.label'), value: 'fr' }
  ];
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar
          variant="dense"
          style={{
            height: '96px',
            justifyContent: 'center',
            backgroundColor: colors.common.main,
            ...paddingGenerator('pr-80'),
            ...paddingGenerator('pl-80')
          }}>
          <img className={(dnsLogo) ? classes.dynamicLogo : hostName === "trans.edgedock.inq.co.bw" ? classes.edgedockLogo : classes.logo} src={(dnsLogo) ? dnsLogo : hostName === "trans.edgedock.inq.co.bw" ? "https://edgeai-org-logo.s3.af-south-1.amazonaws.com/trans_cash_and_carry.jpeg" : otherPlatform ? null : InqLogo} alt="logo" />
          <div className={classes.secondaryMenu}>
            <Typography
              variant={'subtitle1'}
              color={'white'}
              onClick={evt => setAnchorEl(evt.currentTarget)}
              className={clsx(classes.primaryOptions, classes.selector)}>
              {i18n.language === 'en' ? t('english.label') : t('french.label')}
              {/* <ArrowDropDownIcon /> */}
            </Typography>

            {scope === 'Login' ? (
              <Button
                onClick={() => {
                  window.open('https://inq.my.salesforce.com/', '_blank');
                }}
                color="primary"
                className={classes.border}
                variant={'text'}>
                {t('support.label')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  history.push('/');
                }}
                className={classes.border}
                color="primary"
                variant={'text'}>
                {t('login.label')}
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {anchorEl && (
        <MenuWrapper
          id={'ticket-menu'}
          anchorEl={anchorEl}
          handleClose={evt => {
            evt.stopPropagation();
            setAnchorEl(null);
          }}
          menuItems={menuItems}
          handleMenuItemClick={item => {
            setAnchorEl(null);
            return i18n.changeLanguage(item.value);
          }}
        />
      )}
    </div>
  );
}

export default withTranslation()(withRouter(Navbar));
