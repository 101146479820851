import React, { useState, useEffect } from 'react';
import ChartContainer from '../.././../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../.././../../../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GenericTable from '../../../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { dateFormatterLLL } from '../../../../../../utils';
import CreateQuoteModal from './CreateQuoteModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { GET_ALL_QUOTES, GET_ALL_BIDS } from '../../queries';
import { CREATE_QUOTE, UPLOAD_QUOTE_DOCS } from '../../mutation';
import Message from '../../../../../../components/Message';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import QuoteDocsModal from './QuoteDocsModal';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 215,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
}));

const Quotes = ({ t, reloadBids }) => {
    const classes = useStyles();
    const [bidList, setBidList] = useState([]);
    const [quoteList, setQuoteList] = useState([]);
    const [openCreateQuoteModal, setOpenCreateQuoteModal] = useState(false);
    const [openRefDocModal, setOpenRefDocModal] = useState(false);
    const [quoteFilterState, setQuoteFilterState] = useState({search: "", status: "", page: 1, limit: 20, startDate: null, endDate: null});
    const [quoteModalState, setQuoteModalState] = useState({btnLoading: false, clearData: false});
    const [quoteDocsUpload, setQuoteDocsUpload] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [selectedQuote, setSelectedQuote] = useState(null);
    
    const [getAllBids] = useLazyQuery(GET_ALL_BIDS, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            const bidNewList = []
            if(res.getBids && res.getBids.length){
                res.getBids.map(itm => {
                    if(itm.name){
                        bidNewList.push({id: itm.id, name: itm.name});
                    }
                });
            } 
            setBidList(bidNewList);
            if(firstLoad){
                setFirstLoad(false);
                return getAllQuotes({variables: {
                    ...quoteFilterState
                }});
            }
        }
    });
    
    const handleOpenPdfUrl = (url) => {
        console.log("url");
        window.open(url, {target: "_blank"});
    }

    const handleMenuItemClick = (quote, action) => {
        setSelectedQuote(null);
        if(action === "edit"){

        }else if(action === "delete"){

        }else{
            handleOpenPdfUrl(quote.quote_file_url);
        }
        return null;
    }

    const [getAllQuotes, { loading }] = useLazyQuery(GET_ALL_QUOTES, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setQuoteList((res.getQuotes && res.getQuotes.length) ? res.getQuotes.map(itm => {
                if(itm.docs && itm.docs.length){
                    const quoteTypeDoc = itm.docs.filter((doc) => (doc.document_type === "quote"));
                    itm.quote_file_url = (quoteTypeDoc.length) ? quoteTypeDoc[0].url : null;
                }
                itm.action = <>
                    <IconButton onClick={() => handleMenuItemClick(itm, 'edit')} style={{marginRight: 10}} aria-label="edit">
                        <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => handleMenuItemClick(itm, 'delete')} aria-label="delete">
                        <DeleteIcon color="secondary" />
                    </IconButton>
                    <IconButton disabled={(itm.quote_file_url) ? false : true} onClick={() => handleMenuItemClick(itm, 'view')} aria-label="delete">
                        <VisibilityIcon color="primary" />
                    </IconButton>
                </>;
                itm.quote_reference = <>
                    <IconButton onClick={() => handleOpenRefDoc(itm)} aria-label="delete">
                        <PictureAsPdfIcon color="primary" />
                    </IconButton>
                </>;
                const quoteBid = bidList.filter(bid => itm.bid === bid.id);
                if(quoteBid && quoteBid.length){
                    itm.bid_name = quoteBid[0].name;
                }
                itm.created_at = dateFormatterLLL(itm.created_at);
                return itm;
            }) : []);
        }
    });

    const [uploadQuoteDocs] = useMutation(UPLOAD_QUOTE_DOCS, {
        onError: (e) => {
            Message.error(e);
            setQuoteModalState({btnLoading: false, clearData: false});
        },
        onCompleted: (res) => {
            setQuoteDocsUpload([]);
            setQuoteModalState({btnLoading: false, clearData: true});
            return getAllQuotes({variables: {
                ...quoteFilterState
            }});
        }
    });

    const [createQuote] = useMutation(CREATE_QUOTE, {
        onError: (e) => {
            Message.error(e);
            setQuoteModalState({btnLoading: false, clearData: false});
        },
        onCompleted: (res) => {
            if(res.addQuote && quoteDocsUpload.length){
                console.log("quoteDocsUpload: ", quoteDocsUpload);
                quoteDocsUpload.forEach((itm) => {
                    if(itm.file){
                        uploadQuoteDocs({variables: {
                            file: itm.file,
                            type: itm.type,
                            name: res.addQuote.name,
                            quoteId: res.addQuote.id
                        }});
                    }
                });
            }
        }
    });

    useEffect(() => {
        getAllBids({variables: {search: "", limit: 20, page: 1}});
    }, []);

    useEffect(() => {
        getAllBids({variables: {search: "", limit: 20, page: 1}});
    }, [reloadBids]);

    const quoteFilterList = [
        {
            label: "Search By Name",
            handleChange: (evt) => setQuoteFilterState({...quoteFilterState, search: evt.target.value}),
            val: quoteFilterState.search,
            type: "text",
            styleOverrides: { marginRight: 24, marginTop: 10 },
            className: classes.dateInput
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => setQuoteFilterState({...quoteFilterState, startDate: newDate}),
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => setQuoteFilterState({...quoteFilterState, endDate: newDate}),
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('status.label'),
            handleChange: (evt) => setQuoteFilterState({...quoteFilterState, status: evt.target.value}),
            val: quoteFilterState.status,
            options: ["Open", "Closed", "Offered"],
            styleOverrides: { width: 180, marginTop: 10 }
        }
    ];
    const columns = [
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Bid Name",
            key: "bid_name"
        },
        {
            label: "Uploaded On",
            key: "created_at"
        },
        {
            label: "Vendor Name",
            key: "vendor_name"
        },
        {
            label: "Quote Refrence",
            key: "quote_reference"
        },
        {
            label: "Action",
            key: "action"
        }
    ];

    const handleOpenRefDoc = (quote) => {
        setOpenRefDocModal(true);
        setSelectedQuote(quote);
    }

    const handleCreateQuoteSubmit = (formData, quoteDocs) => {
        setQuoteModalState({btnLoading: true, clearData: false});
        setQuoteDocsUpload(quoteDocs);
        return createQuote({variables: {...formData}});
    }

    return (
        <div style={{width: "100%", display: "inline-block"}}>
            <ChartContainer
                customMargin={{marginTop: 0}}
                filterList={quoteFilterList}
                filterSubmit={(evt) => {
                    return getAllQuotes({variables: {
                        ...quoteFilterState
                    }});
                }}
                createNewButton={(evt) => {
                    setQuoteDocsUpload([]);
                    setOpenCreateQuoteModal(true);
                }}
                createLabel="Upload a Quote">
            </ChartContainer>
            <Grid
            container
            style={marginGenerator('mt-25')}>
                <GenericTable loading={loading} list={quoteList} columns={columns} />
            </Grid>
            <CreateQuoteModal 
                open={openCreateQuoteModal}
                handleClose={evt => {
                    setOpenCreateQuoteModal(false);
                }}
                selectBidList={bidList}
                modalState={quoteModalState}
                handleCreateQuoteSubmit={handleCreateQuoteSubmit}
            />
            <QuoteDocsModal 
                open={openRefDocModal}
                data={selectedQuote}
                loading={false}
                handleClose={evt => {
                    setOpenRefDocModal(false);
                    setSelectedQuote(null);
                }}
            />
        </div>
    );
};

Quotes.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default Quotes;
