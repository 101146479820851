import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import KeysControlRegisterItem from './KeysControlRegisterItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { marginGenerator } from '../../../../../../../theme/utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../../components/CanvasSignaturePadModal';
import KeysControlRegisterForm from './KeysControlRegisterForm';
import Message from '../../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_KEYS_CONTROL_REGISTER } from '../../../queries';
import {
  CREATE_KEYS_CONTROL_REGISTER,
  DELETE_KEYS_CONTROL_REGISTER
} from '../../../mutation';

const useStyles = makeStyles(theme => ({
  paging: {
    float: 'right',
    marginBottom: 30,
    marginTop: 20
  },
  dateInput: {
    width: 180,
    minWidth: 180,
    padding: 10
  }
}));

const KeysControlRegister = ({
  t,
  data,
  allowConfigure,
  menuItems,
  users,
  listing = []
}) => {
  const classes = useStyles();
  const [filterStateData, setFilterStateData] = useState({
    startDate: null,
    endDate: null,
    section: '',
    search: ''
  });
  const [crModalState, setCRModalState] = useState({
    btnLoading: false,
    clearDate: false
  });
  const [selectedGlassPanelItem, setSelectedGlassPanelItem] = useState(null);
  const [glassPanelModalOpen, setGlassPanelModalOpen] = useState(false);
  const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
  const [signatureType, setSignatureType] = useState('');
  const [signatureState, setSignatureState] = useState([
    { signature0: '' },
    { signature1: '' },
    { signature2: '' },
    { signature3: '' },
    { signature4: '' },
    { signature5: '' },
    { signature6: '' },
    { signature7: '' },
    { signature8: '' },
    { signature9: '' }
  ]);

  const subscriptionId = data.id;

  const [
    getAllKeysControlRegister,
    { loading, data: keysControlList }
  ] = useLazyQuery(GET_KEYS_CONTROL_REGISTER, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    return getAllKeysControlRegister({
      variables: { ...filterStateData, subscriptionId }
    });
  }, []);

  useEffect(() => {
    getAllKeysControlRegister({
      variables: { ...filterStateData, subscriptionId }
    });
  }, [filterStateData, subscriptionId]);

  const [createKeysControlRegister] = useMutation(
    CREATE_KEYS_CONTROL_REGISTER,
    {
      onError: error => {
        Message.error(error);
      },
      onCompleted: () => {
        Message.success('Key Control created successfully');
        setGlassPanelModalOpen(false);
        return getAllKeysControlRegister({
          variables: { ...filterStateData, subscriptionId }
        });
      }
    }
  );

  const [
    deleteKeysControlRegister,
    { loading: deleteKeysControlRegisterLoading }
  ] = useMutation(DELETE_KEYS_CONTROL_REGISTER, {
    onError: error => {
      Message.error(error);
    },
    onCompleted: () => {
      Message.success('Key Control deleted successfully');
      setGlassPanelModalOpen(false);
      return getAllKeysControlRegister({
        variables: { ...filterStateData, subscriptionId }
      });
    }
  });

  const handleCreateRequestSubmit = formData => {
    formData.subscriptionId = subscriptionId;
    formData.registerSection = formData.registerSection.map((el, i) => {
      el.signature = signatureState[i][`signature${i}`];
      return el;
    });
    return createKeysControlRegister({
      variables: {
        ...formData
      }
    });
  };
  const columns = [
    {
      label: 'Title',
      key: 'title'
    },
    {
        label: 'Date',
        key: 'date'
    },
    {
      label: 'Procedure Number',
      key: 'procedureNumber'
    },
    {
      label: 'Revision Number',
      key: 'revisionNumber'
    },
    {
      label: 'Review Period',
      key: 'reviewPeriod'
    },
    {
      label: 'Actions',
      key: 'actions'
    }
  ];

  const handleNewGlassPanel = () => {
    setGlassPanelModalOpen(true);
  };

  const handleOpenSignPanModal = type => {
    setSignaturePadModalOpen(true);
    setSignatureType(type);
  };

  const filterList = [
    {
      label: t('search.label'),
      handleChange: evt => {
        console.log('evt.target.value: ', evt.target.value);
        setFilterStateData({ ...filterStateData, search: evt.target.value });
      },
      val: filterStateData.search,
      type: 'text',
      styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 }
    },
    {
      label: t('startDate.label'),
      handleChange: newDate => {
        setFilterStateData({ ...filterStateData, startDate: newDate });
      },
      val: filterStateData.startDate,
      type: 'input',
      styleOverrides: { marginRight: 20 },
      className: classes.dateInput
    },
    {
      label: t('endDate.label'),
      handleChange: newDate => {
        setFilterStateData({ ...filterStateData, endDate: newDate });
      },
      val: filterStateData.endDate,
      type: 'input',
      styleOverrides: { marginRight: 20 },
      className: classes.dateInput
    }
  ];

  const handleMenuItemClick = (type, rowItem) => {
    setCRModalState({ clearData: false, btnLoading: false });
    setSelectedGlassPanelItem(rowItem);
    if (type === 'sign') {
      setSelectedGlassPanelItem(rowItem);
      setSignaturePadModalOpen(true);
      setSignatureType(type);
    } else if (type === 'edit') {
      setGlassPanelModalOpen(true);
    } else if (type === 'delete') {
      if (
        window.confirm('Are you sure you want to close this Row ?') ===
        false
      ) {
        return false;
      }
      return deleteKeysControlRegister({
        variables: {
          id: rowItem._id
        }
      });
    }
  };
  console.log('keysControlList', keysControlList);
  return (
    <div style={{ marginTop: 45 }}>
      <Grid container style={{ ...marginGenerator('mb-20') }}>
        <Grid item xs="10">
          {filterList &&
            filterList.map((filter, index) => {
              if (filter.type && filter.type === 'input') {
                return (
                  <DateTimeSelector
                    key={index}
                    label={filter.label ? filter.label : ''}
                    dateFormat={'yyyy-MM-dd HH:mm'}
                    value={filter.val}
                    handleChange={filter.handleChange}
                    styleOverrides={filter.styleOverrides}
                    inputStyle={{ minWidth: 220, padding: 10 }}
                    disableFuture={true}
                  />
                );
              } else if (filter.type && filter.type === 'text') {
                return (
                  <Input
                    key={index}
                    placeholder={filter.label ? filter.label : ''}
                    value={filter.val}
                    onChange={filter.handleChange}
                    style={filter.styleOverrides}
                  />
                );
              } else {
                return (
                  <OutlinedSelect
                    key={index}
                    val={filter.val}
                    label={filter.label}
                    handleChange={filter.handleChange}
                    IconComponent={filter.iconComponent}
                    options={filter.options}
                    styleOverrides={filter.styleOverrides || null}
                    disabled={filter.disabled || false}
                  />
                );
              }
            })}
          <Button
            variant="contained"
            color={'primary'}
            style={{ padding: 10 }}
            onClick={() => {}}>
            Filter
          </Button>
        </Grid>
        <Grid item xs="2">
          {allowConfigure && (
            <>
              <Button
                variant={'outlined'}
                color={'primary'}
                style={{ ...marginGenerator('ml-10'), float: 'right' }}
                onClick={handleNewGlassPanel}>
                {'Open Form'}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            { !loading ? keysControlList && keysControlList.getAllKeysControlRegisterRequests && keysControlList.getAllKeysControlRegisterRequests.data.length > 0 ?
                                keysControlList.getAllKeysControlRegisterRequests.data.map((rowitem, rowIndex) => (
                                    <KeysControlRegisterItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow> : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                    }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <KeysControlRegisterForm
        t={t}
        open={glassPanelModalOpen}
        handleClose={() => setGlassPanelModalOpen(false)}
        modalState={crModalState}
        data={selectedGlassPanelItem}
        signatures={signatureState}
        setSignatureState={setSignatureState}
        handleOpenSignPanModal={handleOpenSignPanModal}
        handleCreateRequestSubmit={handleCreateRequestSubmit}
      />
      <CanvasSignaturePadModal
        open={signaturePadModalOpen}
        handleClose={() => setSignaturePadModalOpen(false)}
        handleSaveSignature={image => {
          setSignaturePadModalOpen(false);
          setSignatureState(prevState => {
            const newState = { ...prevState };
            const index = signatureType.slice(9, 10);
            newState[index][signatureType] = image;
            return newState;
          });
        }}
        signatureType={signatureType}
      />
    </div>
  );
};

export default KeysControlRegister;
