import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import sampleCsvfile from "../../../../../assets/uploadBulkUsersDemo.csv";
import CSVReader from 'react-csv-reader';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import commonStyles from '../../../../../theme/commonStyles';
import { checkValidEmail } from '../../../../../utils';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
};
function CreateUploadBulkUsersModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
}) {
    const classes = useStyles();
    const [uploadPrevUserList, setUploadPrevUserList] = useState([]);
    const [finalCsvData, setFinalCsvData] = useState('');
    const [loading, setLoading] = useState(true);
    const commonClasses = commonStyles();
    const columns = [
        {
            label: t('firstName.label'),
            key: "firstName"
        },
        {
            label: t('lastName.label'),
            key: "lastName"
        },
        {
            label: t('emailAddress.label'),
            key: "emailAddress"
        },
        {
            label: t('phone.label'),
            key: "phone"
        },
        {
            label: t('role.label'),
            key: "role"
        },
        {
            label: t('password.label'),
            key: "password"
        },
        {
            label: t('country.label'),
            key: "country"
        },
    ];

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setUploadPrevUserList([]);
        }
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    
    const handleUploadUserCsvFile = (csvData, fileInfo) => {
        setLoading(true);
        if (csvData && csvData.length) {
            let getCsvData = [];
            csvData.map(plate => {
                if (plate.emailaddress && checkValidEmail(plate.emailaddress)) {
                    plate.countryname = plate.countryname ? plate.countryname : 'Zambia';
                    getCsvData.push({
                        firstname: plate.firstname ? plate.firstname : '',
                        lastname: plate.lastname ? plate.lastname : '',
                        emailaddress: plate.emailaddress,
                        phone: plate.phone ? plate.phone : '',
                        role: plate.role ? plate.role : 'user',
                        password: plate.password ? plate.password : '',
                        countryname: plate.countryname,
                    });
                }
            });
            setUploadPrevUserList(getCsvData);
            setLoading(false);
            let finalData = JSON.stringify(csvData);
            setFinalCsvData(finalData);
        }else{
            Message.error("Users data not found in uploaded CSV file!");
            return null;

        }
    };

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('uploadBulkUsers.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('uploadBulkUsers.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <CSVReader
                                inputStyle={{ width: '100%', color: 'secondary', border: 'black 5px' }}
                                cssClass="ml-csv-input"
                                accept=".csv"
                                cssInputClass="ml-csv-input"
                                onFileLoaded={handleUploadUserCsvFile}
                                parserOptions={papaparseOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('downloadSampleFile.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button style={{ width: '100%' }} variant={'outlined'} color="primary" href={sampleCsvfile} download>{t('downloadSampleFile.label')}</Button>
                        </Grid>
                    </Grid>
                    {!loading && uploadPrevUserList && uploadPrevUserList.length ?
                        <>
                            <Grid style={{ ...marginGenerator('mt-15') }}>
                                <Typography variant="h6" color="primary" >Users preview</Typography>
                            </Grid>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((item, index) => (
                                                <TableCell key={index}>
                                                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <React.Fragment>
                                            {uploadPrevUserList.map((item, index) => (
                                                <TableRow key={item.uid}>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.firstname ? item.firstname : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.lastname ? item.lastname : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.emailaddress ? item.emailaddress : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.phone ? item.phone : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.role ? item.role : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.password ? item.password : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.countryname ? item.countryname : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!finalCsvData) {
                                Message.error("Please choose CSV file.");
                                return null;
                            }
                            return handleSubmitClick(finalCsvData);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateUploadBulkUsersModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateUploadBulkUsersModal);
