import * as React from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ALL_SERVICE_SUBSCRIPTION_TICKETS } from '../queries';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { Paths, TicketingSystem } from '../../../routes/routePaths';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTabs from '../../../components/CustomTabs';
import { useState, useEffect } from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Flag from '@material-ui/icons/Flag';
import DNS from '@material-ui/icons/Dns';
import { getUrl, getParam } from '../../../utils';
import { useHistory } from 'react-router-dom';
import TicketPanel from '../components/TicketPanel';
import {
  STATUS_TYPES,
  TICKET_PRIORITY,
  TICKET_ASSIGNED,
  IS_PARENT
} from '../../../utils/constants';
import { GET_SUBSCRIPTION_LIST } from '../../Subscriptions/queries';
import {
  CLOSE_SERVICE_TICKET,
  CLOSE_MULTIPLE_SERVICE_TICKET
} from '../../ServiceTickets/mutations';
import Message from '../../../components/Message';
import { CheckCircleOutlineRounded } from '@material-ui/icons';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscription.label' }
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // height: '100vh'
  }
}));

export const getSubscriptionOptions = list => {
  return list.map((item, index) => {
    return { id: item.id, name: item.service.name };
  });
};

const TicketLists = ({ t, navigation }) => {
  const classes = useStyles();
  const serviceId = getParam();
  const [status, setStatus] = useState('');
  const [priority, setPriority] = useState('');
  const [assignee, setAssignee] = useState('');
  const [openAssignee, setOpenAssignee] = useState('');
  const [openPriority, setOpenPriority] = useState('');
  const [closedPriority, setClosedPriority] = useState('');
  const [subscription, setSubscription] = useState('');
  const [openSubscription, setOpenSubscription] = useState('');
  const [closedSubscription, setClosedSubscription] = useState('');

  const [updatedRecentTickets, setUpdatedRecentTickets] = useState([]);
  const [updatedOpenTickets, setUpdatedOpenTickets] = useState([]);
  const [updatedClosedTickets, setUpdatedClosedTickets] = useState([]);

  const [recentTicketsSearched, setRecentTicketsSearched] = useState(false);
  const [openTicketsSearched, setOpenTicketsSearched] = useState(false);
  const [closedTicketsSearched, setClosedTicketsSearched] = useState(false);
  const [show, setShow] = useState(false);
  const [closeTickets, setCloseTickets] = useState({ id: [] });

  const history = useHistory();

  useEffect(() => {
    reloadAllServiceTicket();
    reloadOpenTicket();
    reloadCloseTicket();
  }, []);

  const {
    loading: allServiceTicketLoading,
    error: allServiceTicketError,
    data: allServiceSubscriptionTickets,
    refetch: reloadAllServiceTicket
  } = useQuery(GET_ALL_SERVICE_SUBSCRIPTION_TICKETS, {
    variables: { isParent: IS_PARENT.TRUE, serviceId: serviceId }
  });

  console.log('allServiceSubscriptionTickets', allServiceSubscriptionTickets);

  const {
    loading: openLoading,
    error: openError,
    data: openServiceTickets,
    refetch: reloadOpenTicket
  } = useQuery(GET_ALL_SERVICE_SUBSCRIPTION_TICKETS, {
    variables: {
      status: STATUS_TYPES.OPEN,
      isParent: IS_PARENT.TRUE,
      serviceId: serviceId
    }
  });
  console.log('openServiceTickets', openServiceTickets);
  const {
    loading: closedLoading,
    error: closedError,
    data: closedServiceTickets,
    refetch: reloadCloseTicket
  } = useQuery(GET_ALL_SERVICE_SUBSCRIPTION_TICKETS, {
    variables: {
      status: STATUS_TYPES.CLOSED,
      isParent: IS_PARENT.TRUE,
      serviceId: serviceId
    }
  });

  console.log('closedServiceTickets', closedServiceTickets);

  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
    data: subscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST);

  /*
    Filter queries
   */
  const [getRecentTicketFilteredList] = useLazyQuery(
    GET_ALL_SERVICE_SUBSCRIPTION_TICKETS,
    {
      onCompleted: data => {
        setRecentTicketsSearched(true);
        setUpdatedRecentTickets(
          data.allServiceSubscriptionTickets.slice(0, 10)
        );
      }
    }
  );

  const [getOpenTicketFilteredList] = useLazyQuery(
    GET_ALL_SERVICE_SUBSCRIPTION_TICKETS,
    {
      onCompleted: data => {
        setOpenTicketsSearched(true);
        setUpdatedOpenTickets(data.allServiceSubscriptionTickets);
      }
    }
  );

  const [getClosedTicketFilteredList] = useLazyQuery(
    GET_ALL_SERVICE_SUBSCRIPTION_TICKETS,
    {
      onCompleted: data => {
        setClosedTicketsSearched(true);
        setUpdatedClosedTickets(data.allServiceSubscriptionTickets);
      }
    }
  );

  // /*
  //   Filter Queries
  //  */

  const filterListRecentTickets = [
    {
      label: 'Status',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const statusVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            status: statusVal,
            ...(priority ? { priority } : {}),
            ...(assignee ? { assignee } : {})
          }
        });

        setStatus(statusVal);
      },
      val: status,
      options: [STATUS_TYPES.OPEN, STATUS_TYPES.CLOSED]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            priority: priorityVal,
            ...(status ? { status } : {}),
            ...(assignee ? { assignee } : {})
          }
        });

        setPriority(priorityVal);
      },
      val: priority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Assign',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const assignedVal = evt.target.value;
        getRecentTicketFilteredList({
          variables: {
            assignee: assignedVal,
            ...(status ? { status } : {}),
            ...(priority ? { priority } : {})
          }
        });

        setAssignee(assignedVal);
      },
      val: assignee,
      options: [TICKET_ASSIGNED.ASSIGNED, TICKET_ASSIGNED.UNASSIGNED]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(priority ? { priority } : {}),
            ...(assignee ? { assignee } : {}),
            ...(status ? { status } : {})
          }
        });

        setSubscription(subscriptionVal);
      },
      val: subscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const filterListOpen = [
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            priority: priorityVal,
            status: STATUS_TYPES.OPEN,
            ...(openAssignee ? { assignee: openAssignee } : {})
          }
        });

        setOpenPriority(priorityVal);
      },

      val: openPriority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Assign',
      iconComponent: CheckCircleOutlineRounded,
      handleChange: evt => {
        const assignedVal = evt.target.value;
        getOpenTicketFilteredList({
          variables: {
            assignee: assignedVal,
            status: STATUS_TYPES.OPEN,
            ...(openPriority ? { priority: openPriority } : {})
          }
        });

        setOpenAssignee(assignedVal);
      },
      val: openAssignee,
      options: [TICKET_ASSIGNED.ASSIGNED, TICKET_ASSIGNED.UNASSIGNED]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(openPriority ? { priority: openPriority } : {}),
            ...(openAssignee ? { assignee: openAssignee } : {}),
            status: STATUS_TYPES.OPEN
          }
        });

        setOpenSubscription(subscriptionVal);
      },
      val: openSubscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const filterClosed = [
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getClosedTicketFilteredList({
          variables: {
            priority: priorityVal,
            status: STATUS_TYPES.CLOSED
          }
        });

        setClosedPriority(priorityVal);
      },
      val: closedPriority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getClosedTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(closedPriority ? { priority: closedPriority } : {}),
            status: STATUS_TYPES.CLOSED
          }
        });

        setClosedSubscription(subscriptionVal);
      },
      val: closedSubscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const [closeServiceTicket] = useMutation(CLOSE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      Message.success('Ticket closed successfully.');

      reloadAllServiceTicket();
      reloadOpenTicket();
      reloadCloseTicket();
    }
  });

  const [closeMultipleServiceTicket] = useMutation(
    CLOSE_MULTIPLE_SERVICE_TICKET,
    {
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: () => {
        Message.success('Tickets closed successfully.');

        reloadAllServiceTicket();
        reloadOpenTicket();
        reloadCloseTicket();
      }
    }
  );

  const handleTicketItemClick = id => {
    const path = getUrl(TicketingSystem.Details, id);
    history.push(path);
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
      return closeServiceTicket({
        variables: { id }
      });
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(id);
    }
  };

  const handleSearchRecentTickets = query => {
    if (query !== '') {
      const updatedList =
        allServiceSubscriptionTickets.allServiceSubscriptionTickets &&
        allServiceSubscriptionTickets.allServiceSubscriptionTickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase())
        );
      setRecentTicketsSearched(true);
      setUpdatedRecentTickets(updatedList.slice(0, 10));
    } else {
      setRecentTicketsSearched(false);
      setUpdatedRecentTickets([]);
    }
  };

  const handleSearchOpenTickets = query => {
    if (query !== '') {
      const updatedList =
        openServiceTickets.allServiceSubscriptionTickets &&
        openServiceTickets.allServiceSubscriptionTickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase())
        );
      setUpdatedOpenTickets(updatedList);
      setOpenTicketsSearched(true);
    } else {
      setOpenTicketsSearched(false);
      setUpdatedOpenTickets([]);
    }
  };

  const handleSearchClosedTickets = query => {
    if (query !== '') {
      const updatedList =
        closedServiceTickets.allServiceSubscriptionTickets &&
        closedServiceTickets.allServiceSubscriptionTickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase())
        );
      setClosedTicketsSearched(true);
      setUpdatedClosedTickets(updatedList);
    } else {
      setClosedTicketsSearched(false);
      setUpdatedClosedTickets([]);
    }
  };

  const handleRaiseNewTicketClick = () => {
    history.push(TicketingSystem.NewServiceTicket);
  };

  const handleMultipleTcketsClose = () => {
    return closeMultipleServiceTicket({
      variables: { id: closeTickets.id }
    });
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('serviceTickets.label')}
        subText={`${
          openServiceTickets && openServiceTickets.allServiceSubscriptionTickets
            ? openServiceTickets.allServiceSubscriptionTickets.length
            : 0
        } ${t('openTickets.label')} • ${
          updatedRecentTickets ? updatedRecentTickets.length : 0
        } ${t('updatedTickets.label')}`}
        primaryButtonText={t('raiseNewTicket.label')}
        handlePrimaryButtonClick={handleRaiseNewTicketClick}
        secondaryButtonText={show ? t('closeMultiple.label') : null}
        handleSecondaryButtonClick={handleMultipleTcketsClose}
      />

      <div className={classes.root}>
        {allServiceTicketLoading && <CircularProgress color="secondary" />}
      </div>

      <CustomTabs
        tabs={[
          {
            label: t('recentTickets.label'),
            children: (
              <>
                {!allServiceTicketLoading &&
                  !allServiceTicketError &&
                  allServiceSubscriptionTickets && (
                    <TicketPanel
                      ticketList={
                        recentTicketsSearched
                          ? updatedRecentTickets
                          : allServiceSubscriptionTickets.allServiceSubscriptionTickets.slice(
                              0,
                              10
                            )
                      }
                      type={'subscriptionrecenttickets'}
                      t={t}
                      handleMenuItemClick={handleMenuItemClick}
                      filterList={filterListRecentTickets}
                      handleTicketItemClick={handleTicketItemClick}
                      handleSearch={handleSearchRecentTickets}
                    />
                  )}
              </>
            )
          },
          {
            label: t('openTickets.label'),
            children: (
              <>
                {!openLoading && !openError && openServiceTickets && (
                  <TicketPanel
                    ticketList={
                      openTicketsSearched
                        ? updatedOpenTickets
                        : openServiceTickets.allServiceSubscriptionTickets
                    }
                    type={'subscriptionopentickets'}
                    t={t}
                    handleMenuItemClick={handleMenuItemClick}
                    filterList={filterListOpen}
                    handleTicketItemClick={handleTicketItemClick}
                    handleSearch={handleSearchOpenTickets}
                  />
                )}
              </>
            )
          },
          {
            label: t('closedTickets.label'),
            children: (
              <>
                {!closedLoading && !closedError && closedServiceTickets && (
                  <TicketPanel
                    ticketList={
                      closedTicketsSearched
                        ? updatedClosedTickets
                        : closedServiceTickets.allServiceSubscriptionTickets
                    }
                    type={'subscriptionclosedtickets'}
                    t={t}
                    handleMenuItemClick={handleMenuItemClick}
                    filterList={filterClosed}
                    handleTicketItemClick={handleTicketItemClick}
                    handleSearch={handleSearchClosedTickets}
                  />
                )}
              </>
            )
          }
        ]}
      />
    </Layout>
  );
};
export default withTranslation()(TicketLists);
