import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { USER_ROLES } from '../../utils/constants';
import GoogleLogin from 'react-google-login';
import Button from '@material-ui/core/Button';
import SecondaryLayout from './components/SecondaryLayout';
import { colors } from '../../theme/colors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { LOGIN, GET_ORGANISATION_DNS_LOGO } from '../commonQueries';
import {
  EMAIL_OTP_VERIFICATION,
  RESEND_OTP,
  CREATE_GSUIT_USER,
  UPDATE_GSUIT_USER_ACCOUNT,
  CREATE_ORGANIZATION
} from './mutations';
import { Paths } from '../../routes/routePaths';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Message from '../../components/Message';
import { REQUIRED_ERROR, EMAIL_NOT_VERIFIED } from '../../utils/constants';
import jwt_decode from 'jwt-decode';
import VerifyEmailModal from './components/verifyEmailModal';
import ChooseOrgModal from './components/ChooseOrgModal';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import CreateOrgModal from './components/CreateOrgModal';
import Typography from '@material-ui/core/Typography';
import { isOtherPlatform } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: '80%',
    maxHeight: 435
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
}));

const currentDns = window.location.hostname;
const hostName = window.location.hostname;

// Define a condition to check if the hostName is "trans.edgedock.inq.co.bw"
const isHidden = hostName === 'trans.edgedock.inq.co.bw';

function Login({ t }) {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [socialEmail, setSocialEmail] = useState('');
  const [socialPassword, setSocialPassword] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [disableGoogle, setDisableGoogle] = useState(false);
  const [verifyEmailModalState, setVerifyEmailModalState] = useState(false);
  const [createdEmailState, setCreatedEmailState] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [chooseOrgModalState, setChooseOrgModalState] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [createGsuitAcOrgState, setCreateGsuitAcOrgState] = useState(false);
  const [clearCreateOrgModel, setClearCreateOrgModel] = useState({
    loading: false,
    clear: false
  });
  const loginBtn = useRef(null);
  const [gSuitUserData, setGsuitUserData] = useState(null);
  const [loading, setLoading] = useState();
  const [hostLogoUrl, setHostLogoUrl] = useState(null);
  const otherPlatform = isOtherPlatform();

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmitLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [email, password]);

  useEffect(() => {
    return getOrgDnsLogo({
      variables: {
        dnsUrl: currentDns
      }
    });
  }, []);

  const [getOrgDnsLogo] = useLazyQuery(GET_ORGANISATION_DNS_LOGO, {
    onError: error => {
      localStorage.removeItem('login_logo');
    },
    onCompleted: data => {
      if (
        data &&
        data.getOrganisationDnsLogo &&
        data.getOrganisationDnsLogo.imageUrl
      ) {
        setHostLogoUrl(data.getOrganisationDnsLogo.imageUrl);
        localStorage.setItem(
          'login_logo',
          data.getOrganisationDnsLogo.imageUrl
        );
      } else {
        localStorage.removeItem('login_logo');
      }
    }
  });

  const [userlogin] = useLazyQuery(LOGIN, {
    onError: error => {
      setDisableGoogle(false);
      setLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data.userLogin && data.userLogin.success) {
        const { accessToken, refreshToken, organisations } = data.userLogin;
        const { realm_access } = jwt_decode(accessToken);
        let roles = realm_access.roles;
        if (
          roles.includes(USER_ROLES.CSOC_MANAGER) ||
          roles.includes(USER_ROLES.CSOC) ||
          roles.includes(USER_ROLES.SD_READONLY) ||
          roles.includes(USER_ROLES.THIRD_PARTY_VENDOR)
        ) {
          let index = roles.indexOf(USER_ROLES.EP_ADMIN);
          if (index > -1) {
            roles.splice(index, 1);
          }
        }
        let emailDomain = email.split('@');
        setDomain(emailDomain[1]);
        localStorage.setItem('email', email);
        localStorage.setItem('domain', emailDomain[1]);
        localStorage.setItem('syncomToken', accessToken);
        localStorage.setItem('syncomRefreshToken', refreshToken);
        localStorage.setItem('role', roles);
        localStorage.setItem('orgList', JSON.stringify(organisations));
        let userOrgs = organisations ? JSON.parse(organisations) : [];
        if (
          roles.includes(USER_ROLES.IP_SUPERADMIN) ||
          roles.includes(USER_ROLES.IP_COUNTRYADMIN)
        ) {
          localStorage.setItem('orgId', userOrgs[0].id);
          window.location.replace(Paths.LoginInProgress);
          return null;
        }
        setLoading(false);
        if (userOrgs.length > 1) {
          setOrgList(userOrgs);
          setChooseOrgModalState(true);
        } else if (userOrgs.length && userOrgs[0]) {
          localStorage.setItem('orgId', userOrgs[0].id);
          window.location.replace(Paths.LoginInProgress);
        } else {
          Message.error('Organization not found!');
        }
      } else {
        setLoading(false);
        if (data.userLogin.msg === 'email_not_verified') {
          Message.error(EMAIL_NOT_VERIFIED);
          setTimeout(() => {
            setCreatedEmailState(email);
            setVerifyEmailModalState(true);
          }, 2000);
        }
      }
    }
  });

  const [createGsuitUser] = useMutation(CREATE_GSUIT_USER, {
    onError: error => {
      setDisableGoogle(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      if (
        data &&
        data.createGsuitUser &&
        data.createGsuitUser.email &&
        data.createGsuitUser.googleId
      ) {
        if (
          data.createGsuitUser.organisation === 'inq.inc' &&
          !data.createGsuitUser.status
        ) {
          setSocialEmail(data.createGsuitUser.email);
          setSocialPassword(data.createGsuitUser.googleId);
          setGsuitUserData(null);
          return updateGsuitUser({
            variables: {
              email: data.createGsuitUser.email,
              roles: USER_ROLES.IP_USER,
              organizationName: 'INQ'
            }
          });
        } else if (data.createGsuitUser.status === 'logged_in') {
          const {
            accessToken,
            refreshToken,
            organisations
          } = data.createGsuitUser;
          const { realm_access } = jwt_decode(accessToken);
          let roles = realm_access.roles;
          if (
            roles.includes(USER_ROLES.CSOC_MANAGER) ||
            roles.includes(USER_ROLES.CSOC) ||
            roles.includes(USER_ROLES.SD_READONLY) ||
            roles.includes(USER_ROLES.THIRD_PARTY_VENDOR)
          ) {
            let index = roles.indexOf(USER_ROLES.EP_ADMIN);
            if (index > -1) {
              roles.splice(index, 1);
            }
          }
          let emailDomain = data.createGsuitUser.email.split('@');
          setDomain(emailDomain[1]);
          localStorage.setItem('email', email);
          localStorage.setItem('domain', emailDomain[1]);
          localStorage.setItem('syncomToken', accessToken);
          localStorage.setItem('syncomRefreshToken', refreshToken);
          localStorage.setItem('role', roles);
          let userOrgs = organisations ? JSON.parse(organisations) : [];
          if (
            roles.includes(USER_ROLES.IP_SUPERADMIN) ||
            roles.includes(USER_ROLES.IP_COUNTRYADMIN)
          ) {
            localStorage.setItem('orgId', userOrgs[0].id);
            window.location.replace(Paths.LoginInProgress);
            return null;
          }
          if (userOrgs.length > 1) {
            setOrgList(userOrgs);
            setChooseOrgModalState(true);
          } else if (userOrgs.length && userOrgs[0]) {
            localStorage.setItem('orgId', userOrgs[0].id);
            window.location.replace(Paths.LoginInProgress);
          } else {
            Message.error('Organization not found!');
            return null;
          }
        } else {
          setGsuitUserData(data.createGsuitUser);
          setCreateGsuitAcOrgState(true);
        }
      } else if (
        data &&
        data.createGsuitUser.status &&
        data.createGsuitUser.status === 'already_added'
      ) {
        setGsuitUserData(null);
        setDisableGoogle(false);
        Message.error(
          'You have registered as normal account. Please login using your email id and password'
        );
        return null;
      } else {
        setGsuitUserData(null);
        setDisableGoogle(false);
        return null;
      }
    }
  });

  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    onError: error => {
      console.log('Error', error);
      setClearCreateOrgModel({ loading: false, clear: false });
    },
    onCompleted: data => {
      if (data.createOrganization && data.createOrganization.name) {
        Message.success('Organization created successfully.');
        setClearCreateOrgModel({ loading: false, clear: true });
        setCreateGsuitAcOrgState(false);
        setSocialEmail(gSuitUserData.email);
        setSocialPassword(gSuitUserData.googleId);
        return updateGsuitUser({
          variables: {
            email: gSuitUserData.email,
            roles: USER_ROLES.IP_NUSER,
            organizationName: data.createOrganization.name
          }
        });
      }
    }
  });

  const [updateGsuitUser] = useMutation(UPDATE_GSUIT_USER_ACCOUNT, {
    onError: error => {
      setDisableGoogle(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setGsuitUserData(null);
      return userlogin({
        variables: {
          username: socialEmail,
          password: socialPassword
        }
      });
    }
  });

  const [emailOTPVerification] = useMutation(EMAIL_OTP_VERIFICATION, {
    onError: error => {
      console.log('Error', error);
      setVerificationLoading(false);
      Message.success('Invalid OTP!');
    },
    onCompleted: data => {
      setVerificationLoading(false);
      if (data.emailOTPVerification && data.emailOTPVerification.id) {
        Message.success('Email verified successfully.');
        setTimeout(() => {
          setCreatedEmailState(null);
          setVerifyEmailModalState(false);
          const {
            accessToken,
            refreshToken,
            organisations
          } = data.emailOTPVerification;
          const { realm_access } = jwt_decode(accessToken);
          localStorage.setItem('syncomToken', accessToken);
          localStorage.setItem('syncomRefreshToken', refreshToken);
          localStorage.setItem('role', realm_access.roles);
          let userOrgs = organisations ? JSON.parse(organisations) : [];
          if (userOrgs.length > 1) {
            setOrgList(userOrgs);
            setChooseOrgModalState(true);
          } else if (userOrgs.length && userOrgs[0]) {
            localStorage.setItem('orgId', userOrgs[0].id);
            window.location.replace(Paths.LoginInProgress);
          } else {
            Message.error('Organization not found!');
          }
        }, 1500);
      } else {
        Message.success('Invalid OTP!');
      }
    }
  });

  const [resendOTP] = useMutation(RESEND_OTP, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data.resendOTP && data.resendOTP.email) {
        Message.success(
          'OTP sent successfully on your registerd email address'
        );
      }
    }
  });

  const handleVerifyEmailClick = data => {
    setVerificationLoading(true);
    setTimeout(() => {
      return emailOTPVerification({
        variables: {
          email: createdEmailState,
          otp: data.otp,
          login: JSON.stringify({ username: email, password: password })
        }
      });
    }, 1500);
  };

  const handleResendOtp = () => {
    if (createdEmailState) {
      return resendOTP({
        variables: { email: createdEmailState, action: 'email_verify' }
      });
    }
  };

  const handleSubmitSelectOrg = orgId => {
    if (orgId) {
      localStorage.setItem('orgId', orgId);
      window.location.replace(Paths.LoginInProgress);
    }
  };

  const responseGoogle = response => {
    var index = response.profileObj.email.indexOf('@');
    var email_domain = response.profileObj.email.slice(
      index + 1,
      response.profileObj.email.length
    );
    var domain_name = email_domain.split('.')[0];
    if (domain_name === 'gmail') {
      setDisableGoogle(false);
      Message.error('Please try with your organisation email address');
    } else {
      setDisableGoogle(true);
      createGsuitUser({
        variables: {
            firstName: response.profileObj.givenName,
            lastName: response.profileObj.familyName,
            email: response.profileObj.email,
            googleId: response.profileObj.googleId,
            accessToken: response.accessToken,
            organisation: email_domain
        }
      });
    }
  };

  const responseGoogleFailure = response => {
    setDisableGoogle(false);
    Message.error(response.reason);
  };

  const handleSubmitLogin = () => {
    if (!email || !password) {
      Message.error(REQUIRED_ERROR);
      return null;
    }
    setLoading(true);
    return userlogin({
      variables: {
        username: email,
        password: password
      }
    });
  };

  return (
    <SecondaryLayout
      scope={'Login'}
      hostLogoUrl={hostLogoUrl}
      contentStyle={{
        maxWidth: '328px',
        ...marginGenerator('mt-130'),
        ...marginGenerator('mb-150')
      }}>
      <Header
        title={
          otherPlatform ? "Hello..!, let's log in" : t('BonjourLoginText.label')
        }
        titleStyleOverride={{
          color: colors.grey[400],
          fontSize: '2.2rem',
          padding: 0
        }}
        subText={''}
        titleVariant="body1"
        subtitleVariant={'h2'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0 }}
      />
      <Grid container style={{ ...marginGenerator('mt-24') }}>
        {disableGoogle && <CircularProgress color="secondary" />}
        <Typography variant="h2">{t('clientLog.label')}</Typography>
        <Input
          value={email}
          type={'text'}
          placeholder={`${t('email.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-24') }}
          color={'secondary'}
          onChange={evt => setEmail(evt.target.value)}
        />
        <Input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder={`${t('password.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Button
                ref={loginBtn}
                variant={'contained'}
                size={'large'}
                style={{ ...marginGenerator('mt-24'), borderRadius: '28px' }}
                disabled={disableGoogle || loading}
                color={'secondary'}
                onClick={handleSubmitLogin}>
                {loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  t('login.label')
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            spacing={1}
            style={{ alignItems: 'center', justifyContent: 'end' }}>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  history.push(Paths.CreateAccount);
                }}
                style={{ textDecoration: 'underline', marginRight: 52 }}>
                {t('register.label')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  window.location.replace(Paths.ForgotPassword);
                }}
                style={{ textDecoration: 'underline' }}>
                {t('forgotPassword.label')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
   { !isHidden && (
      <Grid
        item
        xs
        container
        direction="column"
        spacing={0}
        justify="flex-start"
        style={{ ...marginGenerator('mt-10'), paddingTop: '20px' }}
      >
        <Grid item>
          <Typography variant="h2" component="div">
            {t('staff.label')}
          </Typography>
        </Grid>
        <Grid item>
          <GoogleLogin
            clientId="855557901383-nf8r2gel8d91089it4bjs77db4o95hn5.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogleFailure}
            // hostedDomain="inq.inc"
            cookiePolicy={'single_host_origin'}
            disabled={disableGoogle}
            isSignedIn={false}
          />
        </Grid>
      </Grid>
    )}

      {verifyEmailModalState && (
        <VerifyEmailModal
          t={t}
          open={verifyEmailModalState}
          handleClose={() => setVerifyEmailModalState(false)}
          handleVerifyEmailClick={handleVerifyEmailClick}
          handleResendOtp={handleResendOtp}
          loading={verificationLoading}
        />
      )}
      {chooseOrgModalState && (
        <ChooseOrgModal
          t={t}
          classes={{
            paper: classes.paper
          }}
          open={chooseOrgModalState}
          handleClose={() => setChooseOrgModalState(false)}
          handleVerifyEmailClick={handleVerifyEmailClick}
          data={orgList}
          handleSubmitSelectOrg={handleSubmitSelectOrg}
        />
      )}
      {createGsuitAcOrgState && (
        <CreateOrgModal
          modelState={clearCreateOrgModel}
          open={createGsuitAcOrgState}
          handleClose={() => {
            setCreateGsuitAcOrgState(false);
          }}
          handleSubmitCreateOrg={data => {
            setClearCreateOrgModel({ loading: true, clear: false });
            return createOrganization(data);
          }}
        />
      )}
    </SecondaryLayout>
  );
}

export default withTranslation()(Login);
