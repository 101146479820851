import React, {useState, useEffect} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './InvoiceTableFooter';
import InvoiceSubscriptionHeader from './InvoiceSubscriptionHeader';
import InvoiceSubscriptionRow from './InvoiceSubscriptionRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  }
});

const InvoiceItemsTable = ({invoice, t}) => {

  const [subscriptionState, setSubscriptionState] = useState([]);
  const [addonsState, setAddonsState] = useState([]);
  
  useEffect(() => {
    if(invoice.subscriptionState && invoice.subscriptionState.length){
      let subscriptions = invoice.subscriptionState.map(item => {
        return {
          description: item.name,
          amount: item.amount,
          discount: item.freeTierDiscount,
          total: item.finalPrice
        };
      });
      setSubscriptionState(subscriptions);
    }
  }, [invoice.subscriptionState]);

  useEffect(() => {
    if(invoice.addonsState && invoice.addonsState.length){
      let addons = invoice.addonsState.map(item => {
        return {
          description: item.moduleName,
          qty: item.number,
          rate: item.costPerMonth,
          discount: item.freeTierDiscount
        };
      });
      setAddonsState(addons);
    }
  }, [invoice.addonsState]);

  return (
    <View style={styles.tableContainer}>
      <InvoiceSubscriptionHeader row={{title: t("subscriptions.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceSubscriptionRow currency={invoice.currency ? invoice.currency.symbol : "$"} items={subscriptionState} />
      
      <InvoiceTableHeader row={{title: t("addOns.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol : "$"} items={addonsState} />

      <InvoiceTableFooter currency={invoice.currency ? invoice.currency.symbol: "$"} total={invoice.totalState ? invoice.totalState : null} />
    </View>
  )
}


export default InvoiceItemsTable