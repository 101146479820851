import "date-fns";
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { IsNumeric } from '../../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedSelect from '../../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    root: {
        width: 600,
        marginBottom: 10
    },
    loader: {
        width: "100%",
        textAlign:"center"
    },
    yearMonthFilter: {
        padding: 10
    }
}));

const initAddOns = { moduleName: "", costPerMonth: 0, freeTierDiscount: 0 };
const initCamState = { perCamCost: 0, freeTierDiscount: 0 };
const aiTypes = [
    {
        id: 'multi_object',
        name: "Multi Objects",
        provider: 'edgeai'
    },
    {
        id: 'line_cross',
        name: 'Line Cross Object',
        provider: 'edgeai'
    },
    {
        id: 'facemask',
        name: 'Facemask',
        provider: 'edgeai'
    },
    {
        id: 'social_distancing',
        name: 'Social Distancing',
        provider: 'edgeai'
    },
    {
        id: 'action_detection',
        name: 'Action Detection',
        provider: 'edgeai'
    },
    {
        id: 'face_recognition',
        name: 'Face Recognition',
        provider: 'edgeai'
    },
    {
        id: 'license_plate_recognition',
        name: 'License plate recognition',
        provider: 'edgeai'
    },
    {
        id: 'safety_helmet_detection',
        name: 'Safety helmet detection',
        provider: 'edgeai'
    },
    {
        id: 'camera_status',
        name: 'Camera status',
        provider: 'edgeai'
    },
    {
        id: 'custom',
        name: 'Custom',
        provider: 'edgeai'
    },
    {
        id: 'heatmap',
        name: 'Heatmap',
        provider: 'edgeai'
    }
];
const notificationTypes = [
    {
        id:'email',
        name:"Email" 
    },
    {
        id:'sms',
        name:"SMS" 
    },
    {
        id:'whatsapp',
        name:"WhatsApp" 
    },
    {
        id:'phone',
        name:"Phone" 
    } 
];

const EdgeAIBilling = ({ t, loadingData, billingData, currency, handleSubmitBilling, currencies, handleChangeCurrency, modalState, open, handleClose }) => {
    
    const classes = useStyles();
    const [addonsState, setAddonsState] = useState([initAddOns]);
    const [cameraState, setCameraState] = useState(initCamState);
    const [notificationState, setNotificationState] = useState([]);
    const [modelsEnabledState, setModelsEnabledState] = useState([]);
    const [taxes, setTaxes] = useState(0);

    React.useEffect(() => {
        if(modalState.clearData && open){
            setAddonsState([initAddOns]);
            setCameraState({...initCamState});
            setNotificationState([]);
            setModelsEnabledState([]);
            setTaxes(0);
        }
    }, [modalState]);
    
    useEffect(() => {
        let modelsArr = [];
        let notiArray = [];
        for(let itm of aiTypes){
            modelsArr.push({name: itm.id, type: itm.name, costPerModel: 0, freeTierDiscount: 0});
        }
        for(let itm of notificationTypes){
            notiArray.push({name: itm.id, type: itm.name, perNotiCost: 0, freeTierDiscount: 0});
        }
        setModelsEnabledState(modelsArr);
        setNotificationState(notiArray);
    }, []);

    const handleCreateAddOns = () => {
        setAddonsState([...addonsState, initAddOns]);
    }

    const handleChangeAddons = (evt, idx) => {
        const { name, value } = evt.target;
        if(name !== "moduleName"){
            if(!value){
                setAddonsState(
                    addonsState.map((item, i) => 
                        i === idx 
                        ? {...item, [name] : 0 } 
                        : item
                ));
                return null;
            }
            if(!IsNumeric(value)){
                return null;
            }
        }
        setAddonsState(
            addonsState.map((item, i) => 
                i === idx 
                ? {...item, [name] : name !== "moduleName" ? parseFloat(value) : value } 
                : item
        ));
    }

    const handleChangeCameraState = (evt) => {
        const { name, value } = evt.target;
        if(!value){
            setCameraState({...cameraState, [name]: 0});
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        setCameraState({...cameraState, [name]: parseFloat(value)});
    }

    const handleChangeNotification = (evt, idx) => {
        const { name, value } = evt.target;
        if(!value){
            setNotificationState(
                notificationState.map((item, i) => 
                    i === idx 
                    ? {...item, [name] : 0 } 
                    : item
            ));
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        setNotificationState(
            notificationState.map((item, i) => 
                i === idx 
                ? {...item, [name] : parseFloat(value) } 
                : item
        ));
    }

    const handleChangeModelEnabled = (evt, idx) => {
        const { name, value } = evt.target;
        if(!value){
            setModelsEnabledState(
                modelsEnabledState.map((item, i) => 
                    i === idx 
                    ? {...item, [name] : 0 } 
                    : item
            ));
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        setModelsEnabledState(
            modelsEnabledState.map((item, i) => 
                i === idx 
                ? {...item, [name] : parseFloat(value) } 
                : item
        ));
    }

    return (
        <div className={classes.root}>
            {loadingData ? 
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            :
                <>
                    <Grid container style={{ marginTop: 20 }}>
                        {/*************  Camera module **************** */}
                        <Grid item xs={2}>
                            <Typography style={{ marginTop: 10 }} variant="subtitle1">{t("cameras.label")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                InputLabelProps={{style: {fontSize: 12}}}
                                size="small"
                                id="filled-basic"
                                variant="filled"
                                label={t("perCameraCost.label")}
                                style={{ width: 140 }}
                                name="perCamCost"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                    ),
                                }}
                                value={cameraState.perCamCost}
                                onChange={handleChangeCameraState}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                InputLabelProps={{style: {fontSize: 12}}}
                                size="small"
                                id="filled-basic"
                                variant="filled"
                                label={t("freeTierDiscount.label")}
                                name="freeTierDiscount"
                                value={cameraState.freeTierDiscount}
                                onChange={handleChangeCameraState}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                    ),
                                }}
                                style={{ width: 140 }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <OutlinedSelect
                                val={currency}
                                label={`${t('currency.label')} *`}
                                handleChange={handleChangeCurrency}
                                styleOverrides={{ width: '100%', marginRight: 0 }}
                                options={currencies}
                            />
                        </Grid>
                    </Grid>
                    {/*************  Models Enabled module **************** */}
                    {modelsEnabledState.length > 0 &&
                        <React.Fragment>
                            <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("modelsEnabled.label")}</Typography>
                            {modelsEnabledState.map((item, index) => {
                                return (
                                    <Grid container key={`model_${index}`} style={{marginTop: 25}}>
                                        <Grid item xs={4}><Typography style={{marginTop: 10}} variant="subtitle1">{item.type}</Typography></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                value={item.costPerModel}
                                                label={t("costPerModel.label")}
                                                id="filled-basic"
                                                variant="filled"
                                                name="costPerModel"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                onChange={evt => handleChangeModelEnabled(evt, index)}
                                                style={{ width: 180 }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                id="filled-basic"
                                                variant="filled"
                                                value={item.freeTierDiscount}
                                                label={t("freeTierDiscount.label")}
                                                name="freeTierDiscount"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                onChange={evt => handleChangeModelEnabled(evt, index)}
                                                style={{ width: 180 }}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </React.Fragment>
                    }

                    {/*************  Notification module **************** */}
                    {notificationState.length > 0 &&
                        <React.Fragment>
                            <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("notifications.label")}</Typography>
                            {notificationState.map((item, key) => (
                                <Grid container style={{marginTop: 25}} key={`noti_${key}`}>
                                    <Grid item xs={4}>
                                        <Typography style={{marginTop: 10}} variant="subtitle1">{item.type}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            size="small"
                                            id="filled-basic"
                                            value={item.perNotiCost}
                                            label={t("costPerNotif.label")}
                                            variant="filled"
                                            name="perNotiCost"
                                            style={{ width: 180 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                ),
                                            }}
                                            onChange={evt => handleChangeNotification(evt, key)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            size="small"
                                            id="filled-basic"
                                            label={t("freeTierDiscount.label")}
                                            variant="filled"
                                            value={item.freeTierDiscount}
                                            name="freeTierDiscount"
                                            onChange={evt => handleChangeNotification(evt, key)}
                                            style={{ width: 180 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </React.Fragment>
                    }

                    {/*************  Addons module **************** */}
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("addOns.label")}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button style={{ marginTop: 22, marginRight: 17, float: "right", width: 145 }} size="medium" variant="contained" color="primary" onClick={handleCreateAddOns}>{t("createAddons.label")}</Button>
                        </Grid>
                    </Grid>
                    {addonsState.map((itm, index) => {
                        return (
                            <>
                                <Grid container key={index} style={{marginTop: 25}}>
                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            size="small"
                                            value={itm.moduleName}
                                            onChange={evt => { handleChangeAddons(evt, index) }}
                                            id="filled-basic"
                                            label={t("moduleName.label")}
                                            name="moduleName"
                                            variant="filled"
                                            style={{ width: 180 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            size="small"
                                            value={itm.costPerMonth}
                                            id="filled-basic"
                                            name="costPerMonth"
                                            onChange={evt => { handleChangeAddons(evt, index) }}
                                            label={t("costPerMonth.label")}
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                ),
                                            }}
                                            style={{ width: 180 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            size="small"
                                            value={itm.freeTierDiscount}
                                            id="filled-basic"
                                            name="freeTierDiscount"
                                            label={t("freeTierDiscount.label")}
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                                ),
                                            }}
                                            onChange={evt => { handleChangeAddons(evt, index) }}
                                            style={{ width: 180 }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                    <Grid container style={{marginTop: 25}}>
                        <Grid item xs={4}>
                            <Typography style={{marginTop: 10}} variant="subtitle1">{t("taxes.label")}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                InputLabelProps={{style: {fontSize: 12}}}
                                size="small"
                                value={taxes}
                                onChange={evt => setTaxes(evt.target.value)}
                                id="filled-basic"
                                name="taxes"
                                label={t("amount.label")}
                                variant="filled"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{currency ? currency.symbol : "$"}</InputAdornment>
                                    ),
                                }}
                                style={{ width: 180 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Button disabled={modalState.btnLoading} style={{float: "right"}} size="medium" variant="contained" color="primary" onClick={evt => {
                                if(modalState.btnLoading){
                                    return null;
                                }
                                handleSubmitBilling({ cameraState, modelsEnabledState, addonsState, notificationState, taxes });
                            }}>{modalState.btnLoading ? <CircularProgress color="secondary" size={12} /> : t("submit.label") }</Button>
                            <Button style={{float: "right", marginRight: 15}} size="medium" variant="contained" color="default" onClick={handleClose}>{t("cancel.label")}</Button>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    );
};
export default EdgeAIBilling;