import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { marginGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';

function ProgressBar({
  totalVal,
  chartWidth = 152,
  chartHeight = 6,
  progressItem,
  label,
  style
}) {
  const calcWidth = itemVal => (itemVal * chartWidth) / totalVal;

  return (
    <Grid style={style}>
      <Grid style={marginGenerator('mb-16')}>
        <Typography variant={'subtitle2'} color={'textPrimary'}>
          {progressItem} / {totalVal} {label}
        </Typography>
      </Grid>

      <Grid
        container
        style={{
          backgroundColor: colors.secondary.lightest,
          borderRadius: 3,
          width: chartWidth,
          height: chartHeight
        }}>
        <Grid
          item
          style={{
            width: calcWidth(progressItem),
            backgroundColor: colors.secondary.main,
            height: chartHeight,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            borderTopRightRadius: progressItem === totalVal ? 3 : 0,
            borderBottomRightRadius: progressItem === totalVal ? 3 : 0,
          }}
        />
      </Grid>
    </Grid>
  );
}

ProgressBar.propTypes = {
  progressItem: PropTypes.number.isRequired,
  totalVal: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number
};

export default ProgressBar;
