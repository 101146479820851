import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Popup from '../../Popup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { generateHexCode, updateIpSec } from '../../../../services/fcrService';
import { useToasts } from 'react-toast-notifications';
import IPut from 'iput';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const validationSchema = yup.object().shape({
  clientSiteName: yup.string().required('Required'),
  sharedSecret: yup.string().required('This field is required'),
  localSubnet: yup
    .string()
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Enter a valid IP Address'
    ),
  remoteSubnet: yup
    .string()
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Enter a valid IP Address'
    )
});

const EditIpSecPopup = ({ ipSecData, editIpSec, setEditIpSec, classes }) => {
  const { addToast } = useToasts();

  const [ipAddressLocalGateway, setIpAddressLocalGateway] = useState('');
  const [ipAddressClientSite, setIpAddressClientSite] = useState('');
  const [localSubnet, setLocalSubnet] = useState('');
  const [remoteSubnet, setRemoteSubnet] = useState('');

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      clientSiteName: data?.clientSiteName,
      ipAddressOfLocalGateway: ipAddressLocalGateway,
      ipAddressOfClientSite: ipAddressClientSite,
      sharedSecert: data?.sharedSecret,
      localSubnet: localSubnet,
      remoteSubnet: remoteSubnet
    };
    const res = await updateIpSec(ipSecData?._id, payload);
    if (res?.data?.statusCode === 200) {
      addToast('Updated Successfully', { appearance: 'success' });
      reset();
      setEditIpSec(false);
    }
    if (res?.response?.status === 422 || res?.response?.status === 500) {
      addToast(res?.response?.data?.message, { appearance: 'error' });
      reset();
      setEditIpSec(false);
    }
  };

  const RegenerateHexKey = async () => {
    const res = await generateHexCode();
    setValue('sharedSecret', res?.data?.result);
  };

  useEffect(() => {
    if (ipSecData && Object.keys(ipSecData)?.length) {
      setValue('clientSiteName', ipSecData?.clientSiteName);
      setValue('sharedSecret', ipSecData?.sharedSecert);
    }
    setIpAddressLocalGateway(ipSecData?.ipAddressOfLocalGateway);
    setIpAddressClientSite(ipSecData?.ipAddressOfClientSite);
    setLocalSubnet(ipSecData?.localSubnet.split('/')[0]);
    setRemoteSubnet(ipSecData?.remoteSubnet.split('/')[0]);
  }, [ipSecData, setValue, generateHexCode]);

  return (
    <>
      <Popup
        title="Edit Ipsec"
        openPopup={editIpSec}
        setOpenPopup={setEditIpSec}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container direction="column" spacing={3}>
              <Grid item container spacing={2}>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Client Site Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="clientSiteName"
                      label="Client Site Name"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Local Gateway IP
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <TextField
                      value={ipAddressLocalGateway}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Client Site IP
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <TextField
                      value={ipAddressClientSite}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Shared Secret
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={7}
                    spacing={1}
                    justify="center"
                    alignItems="center">
                    <Grid item sm={8}>
                      <RHFTextField
                        name="sharedSecret"
                        label="Existing Hex Key"
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                        disabled
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Button
                        className={classes.button}
                        onClick={RegenerateHexKey}
                        variant="contained">
                        Regenerate
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Local Subnet
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7} justify="center" alignItems="center">
                    <IPut
                      defaultValue={localSubnet}
                      onChange={value => setLocalSubnet(value)}
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Remote Subnet
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7} justify="center" alignItems="center">
                    <IPut
                      defaultValue={remoteSubnet}
                      onChange={value => setRemoteSubnet(value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setEditIpSec(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default withStyles(styles)(EditIpSecPopup);
