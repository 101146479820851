import { createMuiTheme } from '@material-ui/core/styles';
import { colors, hostColors } from './colors';
import { typography } from './typography';
import { getAppTitle } from '../utils';

const defaultTheme = createMuiTheme();
const hostName = window.location.hostname;
window.document.title = getAppTitle(hostName);
export const muiTheme = createMuiTheme({
  palette: hostColors[hostName] ? { ...hostColors[hostName] } : { ...colors },
  typography: {
    ...typography
  },
  shape: {
    borderRadius: 1
  },

  // Component style overrides

  overrides: {
    MuiAppBar: {
      root: {
        borderBottom: `1px solid ${colors.grey[200]}`
      },
      positionSticky: {
        top: 97
      }
    },
    MuiToolbar: {
      dense: {
        height: '56px',
        paddingLeft: '0px',
        paddingRight: defaultTheme.spacing(2)
      }
    },
    MuiTab: {
      root: {
        padding: 0,
        height: '65px',
        marginRight: defaultTheme.spacing(6),
        '@media (min-width: 600px)': {
          minWidth: '40px'
        },
        textTransform: 'none'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.875rem',
        lineHeight: 1.14,
        padding: defaultTheme.spacing(1.5, 2)
      },
      outlined: {
        border: `1px solid ${colors.grey[200]}`,
        color: colors.common.black,
        padding: defaultTheme.spacing(1.5, 2)
      },
      containedSizeLarge: {
        padding: defaultTheme.spacing(2.5, 3),
        width: '100%'
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        height: '40px',
        width: defaultTheme.spacing(5)
      },
      colorPrimary: {
        background: colors.primary.main,
        color: defaultTheme.palette.common.white,

        '&:hover': {
          backgroundColor: colors.common.black,
          color: colors.common.white
        }
      },
      colorSecondary: {
        backgroundColor: colors.common.white,
        color: colors.grey[400]
      },
      sizeSmall: {
        height: '24px',
        width: '24px',
        color: colors.grey[400],
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiSelect: {
      root: {
        padding: defaultTheme.spacing(1, 2),
        fontSize: '0.875rem',
        lineHeight: '1.125rem'
      },
      select: {
        '&:focus': {
          backgroundColor: colors.common.white
        }
      },
      outlined: {
        border: `1px solid ${colors.grey[200]}`
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: `translate(14px, 9px) scale(1)`
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0
      }
    },
    MuiInputBase: {
      multiline: {
        padding: 0
      },
      inputMultiline: {
        textAlign: 'justify',
        fontSize: '0.875rem',
        lineHeight: '1.125rem'
      }
    },
    MuiChip: {
      root: {
        padding: defaultTheme.spacing(1)
      },
      label: {
        fontSize: '0.875rem',
        fontWeight: 500,
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2)
      },
      deleteIcon: {
        color: colors.common.black,
        height: '16px',
        width: '16px'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiMenu: {
      paper: {
        '@media (min-width: 1024px)': {
          minWidth: '240px'
        }
      }
    },
    MuiMenuItem: {
      root: {
        padding: defaultTheme.spacing(2, 4),
        paddingTop: defaultTheme.spacing(2),
        paddingBottom: defaultTheme.spacing(2)
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.grey[200]
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'transparent'
      }
    },
    MuiDialogContent: {
      root: {
        padding: defaultTheme.spacing(4, 8),
        paddingTop: defaultTheme.spacing(4)
      }
    },
    MuiDialogActions: {
      root: {
        borderTop: `1px solid ${colors.grey[200]}`,
        padding: defaultTheme.spacing(4, 6)
      }
    },
    MuiRadio: {
      root: {
        border: `1px solid ${colors.grey[200]}`,
        padding: 0
      }
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.grey[200]}`,
        padding: defaultTheme.spacing(2, 3),
        minWidth: '280px',
        fontSize: '0.875rem',
        lineHeight: '1.125rem'
      },
      input: {
        padding: 0
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: 0
      }
    }
  }
});

export default muiTheme;
