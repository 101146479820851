import React, { useState,useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Modal from '@material-ui/core/Modal';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { MdCloudUpload, MdChat, MdRecordVoiceOver, MdSettings,MdOutlineEditCalendar, MdInsertDriveFile, MdGroup, MdPeople, MdHelp } from 'react-icons/md';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    section: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding:0,
        backgroundImage: `url('/edge.jpg')`, // Set the background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      overlay: {
        background: 'rgba(255, 255, 255, 0.6)', // Adjust the opacity here (0.6 for 60%)
      },
  colorCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
  appBar: {
    backgroundColor: '#5a1f2a',
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helpButton: {
    color: '#fff',
  },
  settingsButton: {
    color: '#fff',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  dashboard: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    height: '220px', // Increased height
  },
  count: {
    fontSize: 16, // Slightly increased count font size
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 32, // Slightly increased icon font size
  },
  box1: {
    backgroundColor: '#00bcd4',
    color: '#fff',
    borderRadius: '10px',
  },
  box2: {
    backgroundColor: '#cddc39',
    borderRadius: '0',
  },
  box3: {
    backgroundColor: '#ff9800',
    color: '#fff',
  },
  box4: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  box5: {
    backgroundColor: '#673ab7',
    color: '#fff',
  },
  box6: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  box7: {
    backgroundColor: '#4caf50',
    borderRadius: '0',
  },
  // Additional color themes
  box8: {
    backgroundColor: '#f06292',
    color: '#fff',
  },
  box9: {
    backgroundColor: '#00e5ff',
    color: '#fff',
  },
  box10: {
    backgroundColor: '#6d4c41',
    color: '#fff',
  },
  box11: {
    backgroundColor: '#f57f17',
    color: '#fff',
  },
  box12: {
    backgroundColor: '#3949ab',
    color: '#fff',
  },
  box13: {
    backgroundColor: '#ff8f00',
    color: '#fff',
  },
  box14: {
    backgroundColor: '#1976d2',
    color: '#fff',
  },
  box15: {
    backgroundColor: '#f9a825',
    color: '#fff',
  },
  box16: {
    backgroundColor: '#e64a19',
    color: '#fff',
  },
  box17: {
    backgroundColor: '#00c853',
    color: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    borderRadius: '4px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
  },
  drawer: {
    width: '200px',
  },
}));

const DashboardBox = ({ classes, icon, title, count, background, onClick }) => {
  return (
    <Paper className={`${classes.dashboard} ${background}`} onClick={onClick}>
      {icon}
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" className={classes.count}>
        {count}
      </Typography>
    </Paper>
  );
};



const themes = {
    theme1: {
      background: '#F5F5F5',
    },
    theme2: {
      background: '#81C784',
    },
    theme3: {
      background: '#64B5F6',
    },
    theme4: {
      background: '#FFD54F',
    },
    theme5: {
      background: '#7e57c2',
    },
    theme6: {
      background: '#ffb74d',
    },
    theme7: {
      background: '#4dd0e1',
    },
    theme8: {
      background: '#ff8a80',
    },
    theme9: {
      background: '#E57373',
    },
    theme10: {
      background: '#ffab40',
    },
    theme11: {
      background: '#3E2723',
    },
    theme12: {
      background: '#BF360C',
    },
    theme13: {
      background: '#2E7D32',
    },
    theme14: {
      background: '#3949AB',
    },
    theme15: {
      background: '#FF5722',
    },
    theme16: {
      background: '#006064',
    },
    theme17: {
      background: '#FF6F00',
    },
    theme18: {
      background: '#4527A0',
    },
    theme19: {
      background: '#1B5E20',
    },
    theme20: {
      background: '#C2185B',
    },
    theme21: {
      background: '#1976D2',
    },
    theme22: {
      background: '#FBC02D',
    },
    theme23: {
      background: '#4A148C',
    },
    theme24: {
      background: '#795548',
    },
    theme25: {
      background: '#00ACC1',
    },
    theme26: {
      background: '#EF6C00',
    },
    theme27: {
      background: '#D84315',
    },
    theme28: {
      background: '#00838F',
    },
    theme29: {
      background: '#6A1B9A',
    },
    theme30: {
      background: '#FFA000',
    },
  };
  const ThemeBackground = ({ selectedTheme, children }) => {
    const classes = useStyles();
  
    return (
      <div className={classes.section} style={{ backgroundImage: `url('/edge.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {children}
      </div>
    );
  };

const DocAIHome = () => {
const localStorageKey = 'selectedTheme'; // Local storage key for the selected theme
  const classes = useStyles();
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [isThemeDrawerOpen, setThemeDrawerOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('theme1');

  useEffect(() => {
    // Load the selected theme from local storage on component mount
    const storedTheme = localStorage.getItem(localStorageKey);
    if (storedTheme && themes[storedTheme]) {
      setSelectedTheme(storedTheme);
    }
  }, []);

  const handleHelpClick = () => {
    setHelpModalOpen(true);
  };

  const handleModalClose = () => {
    setHelpModalOpen(false);
  };

  const handleThemeChange = (themeName) => {
    setSelectedTheme(themeName);
     // Store the selected theme in local storage
     localStorage.setItem(localStorageKey, themeName);
    setThemeDrawerOpen(false);
  };

  return (
    <ThemeBackground selectedTheme={selectedTheme}>
    <ThemeProvider theme={createMuiTheme(themes[selectedTheme])}>
    <div className={classes.section} style={{ background: `rgba(${parseInt(themes[selectedTheme].background.slice(1, 3), 16)}, ${parseInt(themes[selectedTheme].background.slice(3, 5), 16)}, ${parseInt(themes[selectedTheme].background.slice(5, 7), 16)}, 0.5)` }}>

    <AppBar
  position="fixed"
  className={classes.appBar}
  style={{ backgroundColor: themes[selectedTheme].background }}
>
  <Toolbar className={classes.toolbar}>
    <Typography variant="h6" style={{ color: '#2d0f15' }}>
      Welcome to DocAI
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        className={classes.settingsButton}
        onClick={() => setThemeDrawerOpen(true)}
      >
        <MdSettings fontSize="24" style={{ color: '#2d0f15' }} />
      </Button>
      <Button className={classes.helpButton} onClick={handleHelpClick}>
        <MdHelp fontSize="24" style={{ color: '#2d0f15' }} />
      </Button>
    </div>
  </Toolbar>
</AppBar>



        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardBox
              classes={classes}
              icon={<MdCloudUpload className={classes.icon} />}
              title="CRM"
              // count={250}
              background={classes.box1}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardBox
              classes={classes}
              icon={<MdChat className={classes.icon} />}
              title="Create Collaboration"
              // count={100}
              background={classes.box2}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardBox
              classes={classes}
              icon={<MdRecordVoiceOver className={classes.icon} />}
              title="Text To Speech"
              // count={56}
              background={classes.box3}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardBox
              classes={classes}
              icon={<MdOutlineEditCalendar className={classes.icon} />}
              title="Task Management"
              // count={0}
              background={classes.box4}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardBox
              classes={classes}
              icon={<MdInsertDriveFile className={classes.icon} />}
              title="OCR files processed"
              count={23}
              background={classes.box5}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardBox
              classes={classes}
              icon={<MdGroup className={classes.icon} />}
              title="All Projects"
              count={0}
              background={classes.box6}
              onClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardBox
              classes={classes}
              icon={<MdPeople className={classes.icon} />}
              title="Total Users"
              count={0}
              background={classes.box7}
              onClick={() => {}}
            />
          </Grid>
        </Grid>
        <Modal
          open={isHelpModalOpen}
          onClose={handleModalClose}
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <Typography variant="h6">Help Information</Typography>
            <Typography>
              This is the help information for the dashboard items.
            </Typography>
          </div>
        </Modal>
        <Drawer
  anchor="right"
  open={isThemeDrawerOpen}
  onClose={() => setThemeDrawerOpen(false)}
  classes={{ paper: classes.drawer }}
>
  <div style={{ padding: '16px' }}>
    <Typography variant="h6" gutterBottom>
      Select Your Theme Color
    </Typography>
  </div>
  <Grid container spacing={2} style={{ padding: '16px' }}>
    {Object.keys(themes).map((themeName) => (
      <Grid item xs={4} key={themeName}>
        <Paper
          className={`${classes.colorCircle} ${classes.colorCirclePadding}`}
          style={{ background: themes[themeName].background }}
          onClick={() => handleThemeChange(themeName)}
        >
          {/* Add color name or any additional text here */}
        </Paper>
      </Grid>
    ))}
  </Grid>
  <Button
    variant="contained"
    color="primary"
    fullWidth
    onClick={() => handleThemeChange('theme1')}
    style={{ backgroundColor: '#2d0f15', color: '#fff' }}
  >
    Reset Theme to Default
  </Button>
</Drawer>



      </div>
    </ThemeProvider>
    </ThemeBackground>
  );
};

export default DocAIHome;
