// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { ADD_UPDATE_CATEGORY, DELETE_VNF_MARKETPLACE, DELETE_VNF_MARKETPLACE_CATEGORY } from "../../mutations";
import { GET_BASESTATION_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BaseStationItem from "../HistoricalCircuitIdsItem/BaseStationItem";
import { GET_USER_INFO } from '../../../../Profile/queries';
import CreateImTeamModal from "../CreateImTeamModal";
import { ADD_IM_TEAM } from "../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../commonQueries';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const BaseSations = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [imTeam, setImTeam] = useState(false);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [allUserList, setAllUserList] = useState('');
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchCount, setsearchCount] = useState('');
    const [selectedField, setSelectedField] = useState('');
    const [variableSearchValue, setvariableSearchValue] = useState('');
    const [presignedurl, setPresignedUrl] = useState();



    // const handleChange = (event) => {
    //   onFieldChange(event.target.value);
    // };

    const fields = [
        'circuit_id',
        'organization_name',
        'platform',
        'product_class',
        'media_description',
        'status',
        'configured_up',
        'configured_down',
        'comment',
        'date_integration',

    ];
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        getAllList({
            variables: {
                pageOrsearch: "page=1"
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            getAllList({
                variables: {
                    pageOrsearch: "page=1"
                }
            })
        }
    }, [refreshTab, CurrentUserName]);
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText + "&" + SearchProjectsText + "=" + variableSearchValue

        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageOrsearch: "page=" + value + checkSearchFilterData
            }
        })
    };
    const [getDownload, { loading: loadingDownload }] = useLazyQuery(GET_BASESTATION_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setLoading(false);
            setError(false);
          if (data && data?.getBasestationList) {
            try {
              let getdownloadCsv = JSON.parse(data.getBasestationList.teamListData);
              setTimeout(()=>{
                getdownloadCsv?.presigned_url ? setPresignedUrl(getdownloadCsv?.presigned_url) : setPresignedUrl('');
              },1000)
           } catch (error) {
              console.error('Error downloading CSV:', error);
            }
          } else {
            // setList([])
          }
        },
        onError: (error) => {
          console.log("error downloading CSV:", error);
          // setLoading(false);hh
          // setError(false);
        },
      });

    const [getAllList] = useLazyQuery(GET_BASESTATION_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("install data : ", data);
            if (data && data?.getBasestationList) {
                let parseResult = JSON.parse(data.getBasestationList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
                setsearchCount(parseResult?.count)
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get historical list:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const columns = [
        {
            label: 'Client Name',
            key: "Client_Name"
        },
        {
            label: 'IP Address',
            key: "ip_address"
        },
        {
            label: 'Up Down Since',
            key: "updown_since"
        },
        {
            label: 'Link Status',
            key: "link_status"
        },

        {
            label: 'Packet Loss',
            key: "packet_loss"
        },

        {
            label: "Base Station Type",
            key: "basestation_type"
        },
        {
            label: "Shelter Provision",
            key: "shelter_provision"
        },

        {
            label: 'State',
            key: "state"
        },

        {
            label: 'LGA',
            key: "lga"
        },
        {
            label: 'Latitude',
            key: "latitude"
        },
        {
            label:'Longitude' ,
            key: "longitude"
        },

        {
            label: 'Down Link MBPS',
            key: "downlink_mbps"
        },
        {
            label:'Up Link MBPS',
            key: "uplink_mbps"
        },
        // {
        //     label: t('configuredDownCiruit.label'),
        //     key: "configured_down_ciruit"
        // },
        // {
        //     label: t('configuredUpCircuit.label'),
        //     key: "configured_up_circuit"
        // },

        // {
        //     label: t('billing.label') + ' ' + t('date.label'),
        //     key: "billing_date"
        // },
        // {
        //     label: t('node.label'),
        //     key: "node"
        // },


    ];
    const handleMenuItemClick = (elem, data) => {

    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageOrsearch: `page=1&search=${SearchProjectsText}&start=${convertStartDate}&end=${convertEndDate}&${selectedField}=${variableSearchValue}`,
            }

        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setSelectedField('');
        setvariableSearchValue('');
        setsearchCount('');
        setLoading(true);
        setPresignedUrl();
        setError(true);
        getAllList({
            variables: {
                pageOrsearch: "page=1"
            }
        })
    };

    const downloadCsv = async()=>{

        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
     
        getDownload({
            variables: {
                pageOrsearch: `search=${SearchProjectsText}&start=${convertStartDate}&end=${convertEndDate}&download=csv`,
            }

        })


    }

    const downloadCsvFile= async(url, fileName) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName || 'downloadedFile'; // You can specify a default filename if needed
        anchor.style.display = 'none';
      
        document.body.appendChild(anchor);
      
        anchor.click();
      
        document.body.removeChild(anchor);
      };
    return (
        <>
            <Header title={t('basestations.label')} />
            <Grid container>
            <Grid item xs={12}>
            <Grid
      container
      justify="space-between"
      alignItems="center"
      style={{ margin: '0px 0px 15px 0px', display: 'flex', zIndex: '100' }}
    >
      <Grid item>
        <Input
          value={SearchProjectsText}
          type={'text'}
          placeholder='Search all fields'
          style={{ width: '80%', flex: 1 }}
          color={'secondary'}
          onChange={(evt) => setSearchProjectsText(evt.target.value)}
        />
        <Button
          color="secondary"
          variant={'contained'}
          className={classes.button}
          onClick={handleSearchProjectsList}
        >
          <SearchIcon />
        </Button>
      </Grid>

      <Grid item>
        <Button
          color="secondary"
          variant={'contained'}
          onClick={handleResetSearchProjectsList}
        >
          Reset
        </Button>
      </Grid>

      {searchCount && (
        <Grid item>
          <Typography variant={'subtitle2'}>Count - {searchCount} </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={6} lg={4}>
        {!presignedurl && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={downloadCsv}
            disabled={loadingDownload}
          >
            Download CSV
          </Button>
        )}

        {presignedurl && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              downloadCsvFile(presignedurl);
            }}
            disabled={loadingDownload}
          >
            Export CSV
          </Button>
        )}
      </Grid>
    </Grid>
    </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <BaseStationItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.count ? list.count / 10 : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(BaseSations);
