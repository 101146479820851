import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL, convertUTCDateToLocalDate} from '../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PriorityPanel from "../PriorityPanel";

const SDNNotificationPanelItem = ({
                                      t,
                                      data,
                                      handleMenuItemClick,
                                      menuItems
                                  }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={data.id}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Icon
                            component={() => (
                                <NotificationsNoneRoundedIcon
                                    color={'secondary'}
                                    style={{marginTop: '2px', marginRight: '6px'}}
                                />
                            )}
                            alt={'icon'}
                        />
                    </Grid>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.notification_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.notification_type}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}> {data.created_by}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {dateFormatterLLL(data.created_at)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            <PriorityPanel priority={(data.notification_priority == 1) ? 'P1' : data.notification_priority == 2 ? 'P2' : 'P3'} t={t}/>
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={menuItems}
                    handleMenuItemClick={elem => {
                        handleMenuItemClick(elem, data.notification_id, 'notification');
                        setAnchorEl(null);
                    }}
                />}
            </TableCell>
        </TableRow>
    );
};
SDNNotificationPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(SDNNotificationPanelItem);
