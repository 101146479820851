import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import CreateRule from '../CreateRule';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography'; 
import { dateFormatterLLL } from '../../../../../../utils';
import { ACTION_TYPES } from '../../../../../../utils/constants';
import Message from '../../../../../../components/Message';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    DOWNLOAD_OCR_RULE_CSV,
} from '../../../../Ocr/queries';
import {
    DELETE_OCR_RULE_CSV
} from '../../../../Ocr/mutations';
import { IconButton } from '@material-ui/core';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
            color={"secondary"}
            variant={'contained'}
            onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading &&  t('delete.label') }
        </Button>
    );
  }

  function DownloadButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <IconButton 
        onClick={onClick}
        style={{marginRight: 12}}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <ArrowDownwardIcon />}
        </IconButton>
    );
  }

const ParseRulesItems = ({
    key,
    t,
    data,
    handleSubmitOfDeleteOcrRule,
    ocrRuleDeletetingStarted,
    selecteIdForDelete,
    open,
    onClose,
    handleSubmitEditOfOcrRule,
    ocrRuleSubmitLoading,
    editRuleHandle,
    allocrRuleData,
    dataForUpdateRuleModal,
    organizationId
}) => {
  const commonClasses = commonStyles();

  const [selecteIdForDownload, setSelecteIdForDownload] = useState('');
  const [ocrRuleCsvLoading, setOcrRuleCsvLoading] = useState(false);

  const [deleteOCRRuleCsv] = useMutation(DELETE_OCR_RULE_CSV, {
    onError: error => {
        console.log('err', error);

    },
    onCompleted: data => {
        console.log('data', data);
    }
  });

  const [downloadOcrRuleCsv] = useLazyQuery(DOWNLOAD_OCR_RULE_CSV, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
        const alldata = get(data, 'downloadOcrRulecsv', '');
        hadnleDowloader(alldata, selecteIdForDownload);
        Message.success(data && data.downloadOcrRulecsv.message);
        setOcrRuleCsvLoading(false);
        setSelecteIdForDownload('');
    },
    onError: error => {
        Message.error(error);
        setOcrRuleCsvLoading(false);
        setSelecteIdForDownload('');
    }
});

const hadnleDowloader = async (data, id) => {
   await FileSaver.saveAs(data.presigned_url,`${id}.csv`);
   setTimeout(() => {
    deleteOCRRuleCsv({
        variables:{
         ruleId: id
        }
    });
   }, 60000);
};

    return (
        <>
        <TableRow key={data.id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                    {data.rule_name ? data.rule_name : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.created_by_name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.description}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Button 
                    variant="outlined"
                    style={{marginRight: 12}} 
                    onClick={()=>editRuleHandle(allocrRuleData,data.id)}
                    >
                  Edit
                </Button>
                <DownloadButtonComponent 
                    t={t}
                    loading={selecteIdForDownload === data.id ? ocrRuleCsvLoading: false}
                    onClick={() =>{
                        setOcrRuleCsvLoading(true);
                        setSelecteIdForDownload(data.id)
                        downloadOcrRuleCsv({
                            variables:{
                                ruleId:data.id
                            }
                        });
                    }}
                />
                <ButtonComponent 
                    t={t}
                    loading={selecteIdForDelete === data.id ? ocrRuleDeletetingStarted: false}
                    onClick={() =>{
                    handleSubmitOfDeleteOcrRule(data.id);
                    }}
                />
            </Grid>
        </TableCell>
    </TableRow>
    <CreateRule 
        t={t}
        open={open}
        onClose={onClose}
        ocrData={''}
        handleSubmitEditOfOcrRule={handleSubmitEditOfOcrRule}
        ocrRuleSubmitLoading={ocrRuleSubmitLoading}
        ocrImageDataForCreateRule={[]}
        actionType={ACTION_TYPES.EDIT}
        ruleData={dataForUpdateRuleModal}       
    />
    </>
    )
}

export default ParseRulesItems
