import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import BriefingPanelItem from './BriefingPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Message from '../../../../../../components/Message';
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import AddBriefiengModal from './AddBriefiengModal';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import BackupControlForm from '../BackupControlPanel/BackupControlForm';
//import MeetingSignatureModal from '../../../../components/MeetingRequestPanel/MeetingSignatureModal';
import {
    GET_BRIEF_LIST,
    GET_SECTION_LIST
} from '../../queries';
import { ADD_BRIEFING, DELETE_BRIEF } from '../../mutation';


const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const BriefingPanel = ({
    t,
    allowConfigure,
    subscriptionUserData,
    data
}) => {

    const classes = useStyles();
    const subscriptionId = data.id;

    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null,section: "", search: "" });
    const [selectedBriefItem, setSelectedBriefItem] = useState(null);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [briefModalOpen, setBriefModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");

    const [getAllBriefs, {
        loading,
        data: briefingRequestList
    }] = useLazyQuery(GET_BRIEF_LIST, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllBriefs({variables: {...filterStateData, subscriptionId}});
    }, []);

    useEffect(() => {
        getAllBriefs({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [addBriefing, { loading: createBriefLoading }] = useMutation(ADD_BRIEFING, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Brief created successfully');
            // setMeetingRequestModalOpen(false);
            setBriefModalOpen(false);
            setCRModalState({clearData: true, btnLoading: false});
            setSelectedBriefItem(null);
            return getAllBriefs({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteBriefing, { loading: deleteMeetingLoading }] = useMutation(DELETE_BRIEF, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Brief deleted successfully');
            return getAllBriefs({variables: {...filterStateData, subscriptionId}});
        }
    });

    const {
        loading: sectionLoading,
        data: sectionList,
        error: sectionError,
        refetch: reloadSections
    } = useQuery(GET_SECTION_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            subscriptionId: subscriptionId
        }
    });

    const columns = [
        {
            label: 'Brief Title',
            key: "briefTitle"
        },
        {
            label: 'Issue Date',
            key: "date"
        },
        {
            label: 'Section',
            key: "section"
        },
        {
            label: "Audience",
            key: "audience"
        },
        {
            label: 'Organizer',
            key: "organizer"
        },
        {
            label: 'Brief Notes',
            key: "briefNotes"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];


    const handleNewMeetingRequest = () => {
        setBriefModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    } 

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Section',
            handleChange: evt => {
                setFilterStateData({...filterStateData, section: evt.target.value, section: evt.target.value});
            },
            val: filterStateData.section,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: sectionList && sectionList.getAllSections ? sectionList.getAllSections.data :[]
        }
    ];



    const handleCreateMeetingSubmit = (formData) => {
        formData.attendees = formData.attendees.map(itm => itm.id);
        formData.subscriptionId = subscriptionId;
        return addBriefing({ variables: formData });
    }

    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedBriefItem(rowItem);
        if(type === "sign"){
            setSelectedBriefItem(rowItem);
           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setBriefModalOpen(true)
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteBriefing({
                variables: {
                    id : rowItem.id
                }
            })
        }
    }

    const handleOpenSignaturePad = (assigneeId) => {
        setSignaturePadModalOpen(true);
    }

  return (
            <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    onChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewMeetingRequest}
                        >
                            {"Add Brief"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading ? briefingRequestList && briefingRequestList.getAllBriefs && briefingRequestList.getAllBriefs.length > 0 ?
                                briefingRequestList.getAllBriefs.map((rowitem, rowIndex) => (
                                    <BriefingPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        users={subscriptionUserData}
                                        // handleAddMeetingNotes={handleAddMeetingNotes}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                                : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
             <AddBriefiengModal
                t={t}
                open={briefModalOpen}
                modalState={{...crModalState, btnLoading: createBriefLoading}}
                handleClose={() => setBriefModalOpen(false)}
                handleCreateMeetingSubmit={handleCreateMeetingSubmit}
                subscriptionUserData={subscriptionUserData}
                data={selectedBriefItem}
                subscriptionId={subscriptionId}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    console.log("image: ", image);
                    setSignaturePadModalOpen(false);
                }}
            />
            {/* <MeetingSignatureModal
                open={meetingSignatureModalOpen}
                handleClose={() => {
                    setMeetingSignatureModalOpen(false);
                    setSignatureType("");
                    setSelectedBriefItem(null);
                }}
                meetingItem={selectedBriefItem}
                handleSinatureClick={(assigneeId) => handleOpenSignaturePad(assigneeId)}
            />
            {selectedBriefItem && signatureType &&
                <CanvasSignaturePadModal 
                    open={signaturePadModalOpen}
                    handleClose={() => {
                        setSignaturePadModalOpen(false);
                        setSignatureType("");
                    }}
                />
            } */}
        </div>
  )
}

export default BriefingPanel