import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import VideosPanel from './components/Panels/VideosPanel';
import CustomTabs from '../../../../components/CustomTabs';


const EdgeAIDemosPanel = ({ t, selectedPath, subActiveIndex, videosData }) => {
    const [tabList, setTabList] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const location = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);

        if (location.state) setActiveIndex(location.state.activeIndex);

        const tabList = [];
        for (const category in videosData) {
            if (videosData.hasOwnProperty(category)) {
                tabList.push({
                    label: category,
                    children: (
                        <>
                            <VideosPanel t={t} videoData={videosData[category]} />
                        </>
                    ),
                });
            }
        };
        setTabList(tabList);

    }, [location, location.pathname]);

    return (
        <>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={tabList}
                subActiveIndex={subActiveIndex}
            />
        </>
    )
}

export default withTranslation()(React.memo(EdgeAIDemosPanel));