import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Message from '../../../components/Message';
import { GET_COUNTRY_LIST } from '../../commonQueries';
import {
  REQUIRED_ERROR,
  INVALID_PHONE
} from '../../../utils/constants';
import OutlinedSelect from '../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GET_ALL_ROLES } from '../../commonQueries';

// const roleList = [
//   'countryadmin',
//   'eadmin',
//   'servicemanager',
//   'finance',
//   'user',
//   'it',
//   'csoc_manager',
//   'csoc',
//   'third_party_vendor'
// ];


function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('updateUser.label')}
    </Button>
  );
}

function EditUsers({
  open,
  handleClose,
  handleSubmit,
  buttonLoading,
  clearModal,
  data,
  organisations,
  orgLoading,
  t
}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [countryId, setCountryId] = useState('');
  const [orgId, setOrgId] = useState([]);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  
  useEffect(() => {
    if (clearModal) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setRole("");
      setCountryId("");
      setOrgId([]);
    }
  }, [clearModal]);

  useEffect(() => {
    console.log("data.organisations: ", data.organisations);
    if (data) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setPhone(data.phone);
      setRole(data.role);
      setCountryId(data.countryId);
      setOrgId(data.organisations ? data.organisations : []);
    }
  }, [data]);
  const {
    loading: roleLoading,
    error: roleError,
    data: roleList
  } = useQuery(GET_ALL_ROLES);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Users</DialogTitle>
        <DialogContent>
          <Grid container style={{ ...marginGenerator('mt-24') }}>
            <Grid container item justify={'space-between'}>
              <Input
                value={firstName}
                type={'text'}
                placeholder={`${t('firstName.label')} *`}
                style={{ minWidth: '220px' }}
                color={'secondary'}
                onChange={evt => setFirstName(evt.target.value)}
              />
              <Input
                value={lastName}
                type={'text'}
                placeholder={`${t('lastName.label')} *`}
                style={{ minWidth: '220px' }}
                color={'secondary'}
                onChange={evt => setLastName(evt.target.value)}
              />
            </Grid>
            <Autocomplete
              id="asynchronous-demo"
              style={{ width: '100%', marginTop: 24, border: '1px solid #ebebeb' }}
              name="orgId"
              value={orgId}
              multiple={true}
              onChange={(event, newValue) => {
                setOrgId(newValue);
              }}
              getOptionSelected={(option, value) => option.id === value}
              getOptionLabel={option => option.name}
              options={organisations}
              loading={orgLoading}
              renderInput={params => (
                <TextField
                  {...params}
                  autoFocus={false}
                  label={t('organization.label')}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {orgLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
            <Grid
              container
              item
              justify={'space-between'}
              style={{ ...marginGenerator('mt-24') }}>
              <OutlinedSelect
                val={countryId}
                label={`${t('country.label')} *`}
                handleChange={evt => {
                  setCountryId(evt.target.value);
                }}
                selectStyle={{ ...paddingGenerator('p-8') }}
                styleOverrides={{ width: '225px', ...marginGenerator('mr-0') }}
                options={countryList ? countryList.getCountries : []}
              />
              <OutlinedSelect
                val={role}
                label={`${t('role.label')} *`}
                handleChange={evt => {
                  setRole(evt.target.value);
                }}
                selectStyle={{ ...paddingGenerator('p-8') }}
                styleOverrides={{ width: '222px', ...marginGenerator('mr-0') }}
                options={!roleLoading && !roleError && roleList.getAllRoles ? JSON.parse(roleList.getAllRoles.RoleData) : []} 
                // options={roleList}
              />
            </Grid>
            <Input
              value={phone}
              type={'text'}
              placeholder={`${t('phone.label')} *`}
              style={{ width: '100%', ...marginGenerator('mt-40') }}
              color={'secondary'}
              autoComplete={'off'}
              onChange={evt => setPhone(evt.target.value)}
            />
            <Input
              value={email}
              type={'text'}
              placeholder={`${t('email.label')} *`}
              style={{ width: '100%', ...marginGenerator('mt-24') }}
              color={'secondary'}
              disabled={true}
              onChange={null}
            />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              if (
                !firstName ||
                !lastName ||
                !email ||
                !phone ||
                !role
              ) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              const regex = /^\d+$/;
              if (!regex.test(phone) || phone.length > 10 || phone.length < 7) {
                Message.error(INVALID_PHONE);
                return null;
              }
              if(!data) return null;
              let formData = {
                id: data.id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                role: role,
                phone: phone,
                countryId: countryId
              };
              if(orgId && orgId.length > 0){
                formData.organisations = orgId.map(org => org.id);
              }
              handleSubmit(formData);
            }}
            loading={buttonLoading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(EditUsers);
