import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import AdvanceRequisitionHeader from '../../AdvanceRequisitionHeader';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '0.7px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },

    input: {
        minWidth: 'auto'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    from: "",
    prn: "",
    costCentre: "",
    tel: "",
    cheque: "",
    cheque1: "",
    amount: "",
    amount1: "",
    tripPurpose:"",
    date:null,
    time:"",
    date1:null,
    time1:"",
    hotel: "",
    hotelCost: "", 
    hotelForeign: "",
    allowance: "",
    allowanceCost: "",
    allowanceRand:"",
    allowanceForeign: "",
    allowance1: "",
    dailyCost: "",
    dailyRand:"",
    dailyForeign: "",
    mealallowance: "",
    mealCost: "",
    mealRand:"",
    mealForeign: "",
    residence: "",
    residenceCost: "",
    residenceRand:"",
    residenceForeign: "",
    car: "",
    carCost: "",
    carRand:"",
    carForeign:"", 
    publicTransport:"",
    publicCost: "",
    publicRand:"",
    publicForeign: "",
    other:"",
    otherCost:'',
    otherRand:"",
    otherForeign:"",
    other1:"",
    otherCost1:'',
    otherRand1:"",
    otherForeign1:"",
    total:"",
    total1:"",
    total2:"",
    applicant:"",
    ApplicantSign:"",
    date2: null,
    sectionHead:"",
    SectionSignature: "",
    date3: null,
    headofDepartment:"",
    DepartmentSignature: "",
    date4: null,  
};


// const tableItems = [
//     {
//         key: 'long_term',
//         value: 'Long Term',
//         label: "Permanent / Fixed Term (long term)",
//         description: "Permanent & FTC 12 months and above"
//     },
//     {
//         key: 'short_term',
//         value: 'Short term',
//         label: "Fixed Term (short term)",
//         description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
//     },
//     {
//         key: 'casual',
//         label: "Casual",
//         value: "Casual",
//         description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
//     }
// ]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}

function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function AdvanceRequisitionFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateAdvanceRequisitionSubmit,
    handleOpenSignPanModal,
    signatures
})  {
    const classes = useStyles();
    const [AdvanceRequisitionFormState, setAdvanceRequisitionFormState] = useState({ ...urInitFields });
    const [showHOD, setShowHOD] = useState(false);
    const [showMBAManager, setShowMBAManager] = useState(false);
    const [showSectionHead, setShowSectionHead] = useState(false);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setAdvanceRequisitionFormState({...data, id});
        }
    }, [data]);


    const handleChange = (value, field) => {
        setAdvanceRequisitionFormState({ ...AdvanceRequisitionFormState, [field]: value });
    }

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setAdvanceRequisitionFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
            <AdvanceRequisitionHeader/>
            </DialogTitle>
            <DialogContent>
        <FormGroup row>

    <Grid container spacing={2} style={{ marginBottom: 20}} >
                <Grid item xs={12}>
                <h4 className={classes.blackBorderTitle} style={{textAlign: 'center',fontSize:'25px',borderBottom: '2.5px solid black' }}>ADVANCE REQUISITION FORM</h4>                       
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>TO</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={8}>
                    <h5 className={classes.blackBorderTitle}>ACCOUNTS</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>FROM(NAME)</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.from}
                    onChange={(evt) => handleChange(evt.target.value, "from")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>PRN. NO</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.prn}
                    onChange={(evt) => handleChange(evt.target.value, "prn")}
                />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>COST CENTRE</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.costCentre}
                    onChange={(evt) => handleChange(evt.target.value, "costCentre")}
                />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>TEL. NO</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={8}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.tel}
                    onChange={(evt) => handleChange(evt.target.value, "tel")}
                />   
                </Grid>
                
            </Grid>
            </FormGroup>

            <FormGroup row>
            <Grid container spacing={2} style={{ marginBottom: 20}} >

                    <Grid className={classes.blackBorder} item xs={4}>
                        <h5 className={classes.blackBorderTitle}></h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                        <h5 className={classes.blackBorderTitle}>CURRENCY REQUIRED</h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                        <h5 className={classes.blackBorderTitle}>AMOUNT</h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>2.1 Cheque/Cash</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.cheque}
                    onChange={(evt) => handleChange(evt.target.value, "cheque")}
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.amount}
                    onChange={(evt) => handleChange(evt.target.value, "amount")}
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>Cheque/Cash</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.cheque1}
                    onChange={(evt) => handleChange(evt.target.value, "cheque1")}
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.amount1}
                    onChange={(evt) => handleChange(evt.target.value, "amount1")}
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                        <h5 className={classes.blackBorderTitle}>2.2 Purpose Of Trip</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={12}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={AdvanceRequisitionFormState.tripPurpose}
                        onChange={(evt) => handleChange(evt.target.value, "tripPurpose")}
                        />
                    </Grid>


                    <Grid className={classes.blackBorder} item xs={12}>
                        <h5 className={classes.blackBorderTitle}>Duration Of Trip</h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label="Date From"
                            dateFormat={"yyyy-MM-dd"}
                            value={AdvanceRequisitionFormState.date}
                            handleChange={newDate => handleChange(newDate, "date")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        placeholder="Time From"
                        color={'secondary'}
                        value={AdvanceRequisitionFormState.time}
                        onChange={(evt) => handleChange(evt.target.value, "time")}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label="Date To"
                            dateFormat={"yyyy-MM-dd"}
                            value={AdvanceRequisitionFormState.date1}
                            handleChange={newDate => handleChange(newDate, "date1")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        placeholder="Time To"
                        color={'secondary'}
                        value={AdvanceRequisitionFormState.time1}
                        onChange={(evt) => handleChange(evt.target.value, "time1")}
                        />
                    </Grid>  
                    
        </Grid>
        </FormGroup>


            <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <FormGroup row>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}></h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                    <h5 className={classes.blackBorderTitle}>NO OF MEALS/NIGHTS</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                    <h5 className={classes.blackBorderTitle}>PULA</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                    <h5 className={classes.blackBorderTitle}>RAND</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                    <h5 className={classes.blackBorderTitle}>OTHER CURRENCY</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={5}>
                    <h5 className={classes.blackBorderTitle}>3.1 Hotel</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.hotel}
                    onChange={(evt) => handleChange(evt.target.value, "hotel")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.hotelCost}
                    onChange={(evt) => handleChange(evt.target.value, "hotelCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.hotelCost}
                    onChange={(evt) => handleChange(evt.target.value, "hotelRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.hotelForeign}
                    onChange={(evt) => handleChange(evt.target.value, "hotelForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.2 Daily Allowance(Bed Only)</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle} style={{ textAlign: 'center'}}>@P550</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.allowance}
                    onChange={(evt) => handleChange(evt.target.value, "allowance")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.allowanceCost}
                    onChange={(evt) => handleChange(evt.target.value, "allowanceCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.allowanceRand}
                    onChange={(evt) => handleChange(evt.target.value, "allowanceRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.allowanceForeign}
                    onChange={(evt) => handleChange(evt.target.value, "allowanceForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.3 Daily Allowance(Bed & Breakfast)</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle} style={{ textAlign: 'center'}}>@400</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.allowance1}
                    onChange={(evt) => handleChange(evt.target.value, "allowance1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.dailyCost}
                    onChange={(evt) => handleChange(evt.target.value, "dailyCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.dailyRand}
                    onChange={(evt) => handleChange(evt.target.value, "dailyRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.dailyForeign}
                    onChange={(evt) => handleChange(evt.target.value, "dailyForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.4 Meal Allowance(Lunch Only)</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle} style={{ textAlign: 'center'}}>@P200</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.mealallowance}
                    onChange={(evt) => handleChange(evt.target.value, "mealallowance")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.mealCost}
                    onChange={(evt) => handleChange(evt.target.value, "mealCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.mealRand}
                    onChange={(evt) => handleChange(evt.target.value, "mealRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.mealForeign}
                    onChange={(evt) => handleChange(evt.target.value, "mealForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.5 Private Residence</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle} style={{ textAlign: 'center'}}>@P1300</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.residence}
                    onChange={(evt) => handleChange(evt.target.value, "residence")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.residenceCost}
                    onChange={(evt) => handleChange(evt.target.value, "residenceCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.residenceRand}
                    onChange={(evt) => handleChange(evt.target.value, "residenceRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.residenceForeign}
                    onChange={(evt) => handleChange(evt.target.value, "residenceForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.6 Use Of Private Vehicle</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle} style={{ textAlign: 'center'}}>@P4/km</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.car}
                    onChange={(evt) => handleChange(evt.target.value, "car")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.carCost}
                    onChange={(evt) => handleChange(evt.target.value, "carCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.carRand}
                    onChange={(evt) => handleChange(evt.target.value, "carRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.carForeign}
                    onChange={(evt) => handleChange(evt.target.value, "carForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={5}>
                    <h5 className={classes.blackBorderTitle}>3.7 Public Transport(Receipts Required)</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.publicTransport}
                    onChange={(evt) => handleChange(evt.target.value, "publicTransport")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.publicCost}
                    onChange={(evt) => handleChange(evt.target.value, "publicCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.publicRand}
                    onChange={(evt) => handleChange(evt.target.value, "publicRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.publicForeign}
                    onChange={(evt) => handleChange(evt.target.value, "publicForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}>3.8 Other</h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle}></h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.other}
                    onChange={(evt) => handleChange(evt.target.value, "other")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherRand}
                    onChange={(evt) => handleChange(evt.target.value, "otherRand")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherCost}
                    onChange={(evt) => handleChange(evt.target.value, "otherCost")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherForeign}
                    onChange={(evt) => handleChange(evt.target.value, "otherForeign")}
                    
                />   
                </Grid>

                <Grid className={classes.blackBorder} item xs={4}>
                    <h5 className={classes.blackBorderTitle}></h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                    <h5 className={classes.blackBorderTitle}></h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={1}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.other1}
                    onChange={(evt) => handleChange(evt.target.value, "other1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherRand1}
                    onChange={(evt) => handleChange(evt.target.value, "otherRand1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherCost1}
                    onChange={(evt) => handleChange(evt.target.value, "otherCost1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.otherForeign1}
                    onChange={(evt) => handleChange(evt.target.value, "otherForeign1")}
                    
                />   
                </Grid>


                <Grid className={classes.blackBorder} item xs={6}>
                    <h5 className={classes.blackBorderTitle}>TOTAL ADVANCE REQUIRED</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.total}
                    onChange={(evt) => handleChange(evt.target.value, "total")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.total1}
                    onChange={(evt) => handleChange(evt.target.value, "total1")}
                />   
                </Grid>
                <Grid className={classes.blackBorder} item xs={2}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.total2}
                    onChange={(evt) => handleChange(evt.target.value, "total2")}
                />   
                </Grid>
            </FormGroup>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                <FormGroup row>

                    <Grid className={classes.blackBorder} item xs={4}> 
                    <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder= "Applicant Name"
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.applicant}
                    onChange={(evt) => handleChange(evt.target.value, "applicant")}
                />   
                          
                        </Grid>

                    <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
                    <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("ApplicantSign")}
                    >Signature <img src={SignIcon} width="26" height={26} alt=""/>
                    </IconButton>
                    </Grid>


                    <Grid className={classes.blackBorder} item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label="Date"
                            dateFormat={"yyyy-MM-dd"}
                            value={AdvanceRequisitionFormState.date2}
                            handleChange={newDate => handleChange(newDate, "date2")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                            
                            <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder= "Section Head Name"
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.sectionHead}
                    onChange={(evt) => handleChange(evt.target.value, "sectionHead")}
                />              
                        </Grid>

                    <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
                    <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("SectionSignature")}
                    >Signature<img src={SignIcon} width="26" height={26} alt=""/>
                    </IconButton>
                    </Grid>


                    <Grid className={classes.blackBorder} item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label="Date"
                            dateFormat={"yyyy-MM-dd"}
                            value={AdvanceRequisitionFormState.date3}
                            handleChange={newDate => handleChange(newDate, "date3")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}> 
                            <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    placeholder= "Head Of Department"
                    className={classes.input}
                    color={'secondary'}
                    value={AdvanceRequisitionFormState.headofDepartment}
                    onChange={(evt) => handleChange(evt.target.value, "headofDepartment")}
                />             
                        </Grid>

                    <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
                    <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("DepartmentSignature")}
                    >Signature <img src={SignIcon} width="26" height={26} alt=""/>
                    </IconButton>
                    </Grid>


                    <Grid className={classes.blackBorder} item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label="Date"
                            dateFormat={"yyyy-MM-dd"}
                            value={AdvanceRequisitionFormState.date4}
                            handleChange={newDate => handleChange(newDate, "date4")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>      
            </FormGroup>
            </Grid>  

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <FormGroup row>
                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>NOTES - Refer to Policy & Procedure: Travelling Expense on Company Business and 2021 Rates Manual </h5>
                </Grid>
                
                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>1. HOTEL ACCOMODATION </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>The following may be claimed against receipted invoice only <br></br>
                    1.1 Accomodation charge & taxes <br></br>
                    1.2 Laundry for stays of 3nights or more away from home <br></br>
                    1.3 Business telephone calls, facsimiles, and one reasonable call home a day </p>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>2. DAILY ALLOWANCE </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>An employee on company business may claim an allowance of P550(if only accomodation is provided)
                    or P400 (if accomodation and breakfast are provided) in respect of each period containing a consecutive lunch/dinner and a night away from home
                    (in any sequence). Note that if meals are provided the above allowances may not be claimed. <br></br>
                    Where the period of absence from home is more than 10days refer to the policy <br></br></p>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>3. MEAL ALLOWANCE </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>Where a business trip does not involve an overnight stay or the person does not qualify for the full
                    daily allowance as above, a meal allowance of P200 may be claimed for lunch. No receipts are necessary.</p>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>4. PRIVATE RESIDENCE </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>An employee who chooses to reside with family or friends while traveling on authorised company business will 
                    receive a daily allowance of P1300 in lieu of all other allowances.</p>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>5. USE OF PRIVATE VEHICLE  </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>Where an employee has been authorised to use his own vehicle, P4 may be claimed against every
                    kilometre travelled on the business trip.</p>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>6. PUBLIC TRANSPORT </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>An advance equivalent to the anticipated cost of public transport may be requested.</p>
                </Grid>
  
                <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>7. PARKING FEES </h5>
                </Grid>

                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>Fees for the parking of a vehicle at a hotel or parking garage only in Gaborone, Johannesburg, or 
                    other major urban centres may be claimed.</p>
                </Grid>
            </FormGroup>
            </Grid>
            
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <FormGroup row>
                <Grid className={classes.blackBorder} item xs={12}>
                    <p className={classes.blackBorderTitle}>NB - All Expense Forms must be submitted within 14days of return from a business 
                    trip,failing which the total amount advanced will be deducted from the employee's salary without further advice. All refunds
                    to such deductions will be done through payroll. Refer all expense related queries to Kealeboga Gobotswang on extension 1231. </p>
                </Grid>            
            </FormGroup>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20, color: 'lightblue'}} >
            <FormGroup row>
                <Grid>
                    <h5>INTERNAL</h5>
                </Grid>            
            </FormGroup>
            </Grid>             

</DialogContent>
<DialogActions>
<Grid container justify={'space-between'}>
<Button
    variant={'outlined'}
    onClick={() => {
    setAdvanceRequisitionFormState({ ...urInitFields });
    handleClose();
}}
>
{t('cancel.label')}
</Button>
<ButtonComponent
t={t}
onClick={() => {
    if(!AdvanceRequisitionFormState.from){
        Message.error(REQUIRED_ERROR);
        return null;
    }
    handleCreateAdvanceRequisitionSubmit(AdvanceRequisitionFormState);
}}
loading={modalState.btnLoading}
/>
</Grid>
</DialogActions>
</Dialog>
);
}

AdvanceRequisitionFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateAdvanceRequisitionSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(AdvanceRequisitionFormModal);