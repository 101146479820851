import React, { Component } from 'react';
import { Typography, TextField, Button, MenuItem } from '@material-ui/core';

export const renderText = ({ label, color, align, variant, component }) => (
  <Typography
    color={color ? color : 'primary'}
    align={align ? align : 'center'}
    variant={variant ? variant : 'h6'}>
    {label}
  </Typography>
);

export const renderInputText = ({
  label,
  color,
  state,
  name,
  handleOnChange,
  type
}) => {
  const { data, errors } = state;
  return (
    <TextField
      label={label}
      color={color ? color : 'primary'}
      variant="outlined"
      fullWidth={true}
      size="small"
      name={name}
      autoFocus
      value={data[name]}
      error={errors[name] ? true : false}
      helperText={errors[name]}
      onChange={handleOnChange}
      type={type}
      autoComplete="off"
    />
  );
};

export const renderButton = ({
  label,
  variant,
  color,
  handleOnClick,
  className
}) => (
  <Button
    variant={variant ? variant : 'outlined'}
    color={color ? color : 'primary'}
    size="medium"
    onClick={handleOnClick}
    // style={{ borderRadius: '10px' }}
    className={className}>
    {' '}
    {label}
  </Button>
);

export const renderSelectText = ({
  label,
  color,
  state,
  name,
  handleOnChange
}) => {
  const { data, errors } = state;
  return (
    <TextField
      id="outlined-basic"
      label={label}
      color={color ? color : 'primary'}
      variant="outlined"
      fullWidth={true}
      size="small"
      name={name}
      value={data[name]}
      defaultValue={data[name]}
      error={errors[name] ? true : false}
      helperText={errors[name]}
      onChange={handleOnChange}>
      {data.map()}
    </TextField>
  );
};

export const renderSelect = ({
  label,
  color,
  state,
  name,
  handleOnChange,
  options,
  defaultValue,
  style
}) => {
  const { data, errors } = state;
  return (
    <TextField
      select
      label={label}
      color={color ? color : 'primary'}
      variant="outlined"
      fullWidth={true}
      size="small"
      name={name}
      value={data[name]}
      style={style}
      defaultValue={defaultValue}
      error={errors[name] ? true : false}
      helperText={errors[name]}
      onChange={handleOnChange}>
      {options.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.key}
        </MenuItem>
      ))}
    </TextField>
  );
};
