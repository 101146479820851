import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
import SesiroHeader from '../../../SesiroHeader';

    const useStyles = makeStyles(theme => ({
        blackBorder: {
            border: '1px solid black',
            outline: '1px solid',
            padding: '0px !important',
        },
        blackBorderTitle:{
            margin: '18px 9px',
        },
        paper: {
            padding: '15px',
            marginBottom: '50px'
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        root: {
            flexGrow: 1,
        },
        input: {
            minWidth: '224px'
        },
        iconButton: {
            width: 'auto',
            height: 'auto',
            padding: theme.spacing(1.25, 3),
            borderRadius: '24px',
            marginTop: theme.spacing(2),
            border: "1px solid #b8b8b8"
        },

        blueBorder: {
            border: "5px solid ",
            borderRight: "5px solid #06373e",
            borderTop: "5px solid #2e8a88",
            borderBottom: "5px solid #2e8a88",
            borderLeft: "5px solid #47cfc7",
            width: '350px',
            padding: '50px',
            borderRadius: '12px'
          },
    }));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = { 
    id:"",
    policyNumber: "",
    vatRagistrationNo: "",
    insured: "",
    insuredfullName:"",
    insuredOccupation:"",
    insuredIdNo:"",
    insuredAddress:"",
    insuredDayTel:"",
    vechileDetails:"",
    vechileMake:"",
    tare:"",
    grossVechileMass:"",
    kilometresCompleted:"",
    registrationNo:"",
    value:"",
    modelAndYear:"",
    dateOfPurchase:null,
    whoseName:"",
    pricePaid:"",
    repairName:"",
    damageAddress:"",
    damageTelNo:"",
    damageToOwnVechile:"",
    estimateForRepairs:"",
    yourDamagedVehicle:"",
    driverFullName:"",
    driverOccupation:"",
    driverIdNo:"",
    driverAddress:"",
    driverDayTelNo:"",
    licenceNumber:"",
    validityPeriod:"",
    place:"",
    class:"",
    fullLearner:"",
    stateFullyThePurpose:"",
    drivingWithPermission:"",
    isEmploy:"",
    ownerOfAnotherVehicle:"",
    insurerAndPolicyNumber:"",
    convictionsForMotoringOffences:"",
    licenceEndorsed:"",
    physicalDefects:"",
    detailsOfPreviousAccidents:"",
    passengersFullName:"",
    passengersAddress:"",
    injury:"",
    purposeWereTheyCarried:"",
    areTheyEmployees:"",
    otherVechileRegistrationNumber:"",
    otherVechileMake:"",
    nameAndAddress:"",
    otherVechileDetailsOfDamage:"",
    nameAndAddressOfOwner:"",
    propertyOtherDetailsOfDamage:"",
    nameOfInjured:"",
    relationshipToAccident:"",
    detailsOfInjuries:"",
    nameOfHospital:"",
    witnessOneFullName:"",
    witnessOneAddress:"",
    witnessOneTelephoneNo:"",
    witnessTwoFullName:"",
    witnessTwoAddress:"",
    witnessTwoTelephoneNo:"",
    acidentDateSubmitted:null,
    details:"",
    beforeAccidentSpeed:"",
    beforeAccidentWeatherConndition:"",
    beforeAccidentVisibility:"",
    beforeAccidentVehicleLightsWereOn:"",
    beforeAccidentStreetLighting:"",
    momentOfImpactSpeed:"",
    momentOfImpactWeatherConndition:"",
    momentOfImpactVisibility:"",
    momentOfImpactVehicleLightsWereOn:"",
    momentOfImpactStreetLighting:"",
    wasAnyWarningGiven:"",
    police:"",
    policeFullName:"",
    policeRefNo:"",
    policeDateSubmitted:null,
    stationReported:"",
    driverTestedForAlcohol:"",
    descriptionOfAccident:"",
    sketchOfAccident:"",
    licenceSignature:"",
    licenceCapacity:"",
    declartionDateSubmitted:null,
    declartionCapacity: "",
    secondDeclartionDateSubmitted: null
};

const MotorAccidentForm = ({
  t,
  open,
  data,
  handleClose,
  modalState,
  handleCreateMotorAccidentSubmit,
  handleOpenSignPanModal
}) => {
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const classes = useStyles();

  React.useEffect(() => {
    if (!open) {
        setURFormState({ ...urInitFields });
        handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        setURFormState({...data, id });
    }else{
        // setShowUserSelect(true);
        // setShowLearnDevelopment(true);
        // setShowHOD(true);
        // setShowHRManager(true);
        // setShowGeneralManager(true);
    }
}, [data]);

  const handleChange = (value, name, key) => {
    setURFormState({ ...urFormState, [name]: value });
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle id="sensor-template-title">
        {/* <HrHeader /> */}
        <SesiroHeader />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              MOTOR ACCIDENT CLAIM FORM
            </h4>
          </Grid>
          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'Policy Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.policyNumber}
              onChange={(evt) => handleChange(evt.target.value, "policyNumber")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'VAT Registration Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.vatRagistrationNo}
              onChange={(evt) => handleChange(evt.target.value, "vatRagistrationNo")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              INSURED
            </h4>
          </Grid>
          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'Full Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.insuredfullName}
              onChange={(evt) => handleChange(evt.target.value, "insuredfullName")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Occupation'} 
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.insuredOccupation}
              onChange={(evt) => handleChange(evt.target.value, "insuredOccupation")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'ID No'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.insuredIdNo}
              onChange={(evt) => handleChange(evt.target.value, "insuredIdNo")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Address'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.insuredAddress}
              onChange={(evt) => handleChange(evt.target.value, "insuredAddress")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'(Day) Tel. No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.insuredDayTel}
              onChange={(evt) => handleChange(evt.target.value, "insuredDayTel")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}> 
              VEHICLE
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="If Vehicle Is Subject To Hire Purchase, Credit or Leasing Agreement, State Name And Address Of Finance Company"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.vechileDetails}
              onChange={(evt) => handleChange(evt.target.value, "vechileDetails")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Make'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.vechileMake}
              onChange={(evt) => handleChange(evt.target.value, "vechileMake")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Tare'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.tare}
              onChange={(evt) => handleChange(evt.target.value, "tare")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Gross Vehicle Mass'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.grossVechileMass}
              onChange={(evt) => handleChange(evt.target.value, "grossVechileMass")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Kilometres Completed'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.kilometresCompleted}
              onChange={(evt) => handleChange(evt.target.value, "kilometresCompleted")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Registration Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.registrationNo}
              onChange={(evt) => handleChange(evt.target.value, "registrationNo")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Value'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.value}
              onChange={(evt) => handleChange(evt.target.value, "value")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Model and Year'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.modelAndYear}
              onChange={(evt) => handleChange(evt.target.value, "modelAndYear")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Date of Purchase'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.dateOfPurchase}
              handleChange={newDate => handleChange(newDate, "dateOfPurchase")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type="textarea"
              placeholder="In Whose Name Is The Vehicle Registered?"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.whoseName}
              onChange={(evt) => handleChange(evt.target.value, "whoseName")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Price Paid'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.pricePaid}
              onChange={(evt) => handleChange(evt.target.value, "pricePaid")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              DAMAGE
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <TextField
              type="textarea"
              placeholder="Repair Name"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.repairName}
              onChange={(evt) => handleChange(evt.target.value, "repairName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <TextField
              type={'textarea'}
              placeholder={'Address'}
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.damageAddress}
              onChange={(evt) => handleChange(evt.target.value, "damageAddress")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Telephone No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.damageTelNo}
              onChange={(evt) => handleChange(evt.target.value, "damageTelNo")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type={'textarea'}
              placeholder={'Damage To Own Vehicle'}
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.damageToOwnVechile}
              onChange={(evt) => handleChange(evt.target.value, "damageToOwnVechile")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type={'textarea'}
              placeholder={'Estimate For Repairs Or Attach Quotation'}
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.estimateForRepairs}
              onChange={(evt) => handleChange(evt.target.value, "estimateForRepairs")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type={'textarea'}
              placeholder={'Where Can Your Damaged Vehicle Be Inspected?'}
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.yourDamagedVehicle}
              onChange={(evt) => handleChange(evt.target.value, "yourDamagedVehicle")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              DRIVER
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'Full Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.driverFullName}
              onChange={(evt) => handleChange(evt.target.value, "driverFullName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Occupation'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.driverOccupation}
              onChange={(evt) => handleChange(evt.target.value, "driverOccupation")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'ID No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.driverIdNo}
              onChange={(evt) => handleChange(evt.target.value, "driverIdNo")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type={'textarea'}
              placeholder={'Address'}
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.driverAddress}
              onChange={(evt) => handleChange(evt.target.value, "driverAddress")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'(Day) Tel. No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.driverDayTelNo}
              onChange={(evt) => handleChange(evt.target.value, "driverDayTelNo")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              Driving Licence - Attach Copy
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Licence Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.licenceNumber}
              onChange={(evt) => handleChange(evt.target.value, "licenceNumber")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Validity Period'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.validityPeriod}
              onChange={(evt) => handleChange(evt.target.value, "validityPeriod")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Place'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.place}
              onChange={(evt) => handleChange(evt.target.value, "place")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Class'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.class}
              onChange={(evt) => handleChange(evt.target.value, "class")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Full Learner'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.fullLearner}
              onChange={(evt) => handleChange(evt.target.value, "fullLearner")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="State Fully The Purpose For Which The Vehicle Was Being Used"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.stateFullyThePurpose}
              onChange={(evt) => handleChange(evt.target.value, "stateFullyThePurpose")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Was He/She Driving With Your Permission?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `drivingWithPermission`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.drivingWithPermission === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.drivingWithPermission === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Was He/She In Your Employ?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `isEmploy`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.isEmploy === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.isEmploy === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Is He/She The Owner Of Another Vehicle?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `ownerOfAnotherVehicle`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.ownerOfAnotherVehicle === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.ownerOfAnotherVehicle === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type="textarea"
              placeholder="If Yes, Give Name Of Insurer And Policy Number"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.insurerAndPolicyNumber}
              onChange={(evt) => handleChange(evt.target.value, "insurerAndPolicyNumber")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="Details Of Any Convictions For Motoring Offences"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.convictionsForMotoringOffences}
              onChange={(evt) => handleChange(evt.target.value, "convictionsForMotoringOffences")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Has The Licence Ever Been Endorsed?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `licenceEndorsed`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.licenceEndorsed === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.licenceEndorsed === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Does He/She Have Any Physical Defects?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `physicalDefects`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.physicalDefects === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.physicalDefects === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="Details Of Previous Accidents"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.detailsOfPreviousAccidents}
              onChange={(evt) => handleChange(evt.target.value, "detailsOfPreviousAccidents")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              PASSENGERS (Insured Vehicle)
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              Passengers In Insured Vehicle
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Full Name(s)'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.passengersFullName}
              onChange={(evt) => handleChange(evt.target.value, "passengersFullName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Address'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.passengersAddress}
              onChange={(evt) => handleChange(evt.target.value, "passengersAddress")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Injury'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.injury}
              onChange={(evt) => handleChange(evt.target.value, "injury")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="For What Purpose Were They Carried?"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.purposeWereTheyCarried}
              onChange={(evt) => handleChange(evt.target.value, "purposeWereTheyCarried")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>Are They Employees?</h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `areTheyEmployees`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.areTheyEmployees === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.areTheyEmployees === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              OTHER PARTY
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>OTHER VEHICLES</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Registration Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.otherVechileRegistrationNumber}
              onChange={(evt) => handleChange(evt.target.value, "otherVechileRegistrationNumber")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Make'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.otherVechileMake}
              onChange={(evt) => handleChange(evt.target.value, "otherVechileMake")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Name & Address Of Owner & Driver'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.nameAndAddress}
              onChange={(evt) => handleChange(evt.target.value, "nameAndAddress")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Details Of Damage'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.otherVechileDetailsOfDamage}
              onChange={(evt) => handleChange(evt.target.value, "otherVechileDetailsOfDamage")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              PROPERTY OTHER THAN VEHICLES
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Name And Address Of Owner'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.nameAndAddressOfOwner}
              onChange={(evt) => handleChange(evt.target.value, "nameAndAddressOfOwner")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Details Of Damage'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.propertyOtherDetailsOfDamage}
              onChange={(evt) => handleChange(evt.target.value, "propertyOtherDetailsOfDamage")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              PERSONAL INJURIES(OTHER THAN IN INSURED VEHICLE)
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Name Of Injured'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.nameOfInjured}
              onChange={(evt) => handleChange(evt.target.value, "nameOfInjured")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={
                'Relationship To Accident e.g. Driver, Passenger, Etc.'
              }
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.relationshipToAccident}
              onChange={(evt) => handleChange(evt.target.value, "relationshipToAccident")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Details Of Injuries'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.detailsOfInjuries}
              onChange={(evt) => handleChange(evt.target.value, "detailsOfInjuries")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Name Of Hospital (If Applicable)'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.nameOfHospital}
              onChange={(evt) => handleChange(evt.target.value, "nameOfHospital")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              WITNESS
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>WITNESS 1</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Full Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessOneFullName}
              onChange={(evt) => handleChange(evt.target.value, "witnessOneFullName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Address'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessOneAddress}
              onChange={(evt) => handleChange(evt.target.value, "witnessOneAddress")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Telephone No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessOneTelephoneNo}
              onChange={(evt) => handleChange(evt.target.value, "witnessOneTelephoneNo")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>WITNESS 2</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Full Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessTwoFullName}
              onChange={(evt) => handleChange(evt.target.value, "witnessTwoFullName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Address'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessTwoAddress}
              onChange={(evt) => handleChange(evt.target.value, "witnessTwoAddress")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Telephone No.'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              multiline
              rows={2}
              rowsMax={2}
              value={urFormState.witnessTwoTelephoneNo}
              onChange={(evt) => handleChange(evt.target.value, "witnessTwoTelephoneNo")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              ACCIDENT
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <h5 className={classes.blackBorderTitle}>Date And Time</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={8}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Select Date & Time'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.acidentDateSubmitted}
              handleChange={newDate => handleChange(newDate, "acidentDateSubmitted")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <h5 className={classes.blackBorderTitle}>
              Place Where Accident Occurred
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={8}>
            <TextField
              type="textarea"
              placeholder="Details"
              variant="standard"
              fullWidth={true}
              value={urFormState.details}
              onChange={(evt) => handleChange(evt.target.value, "details")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>BEFORE ACCIDENT</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'Speed KM/H'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.beforeAccidentSpeed}
              onChange={(evt) => handleChange(evt.target.value, "beforeAccidentSpeed")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Weather Conndition'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.beforeAccidentWeatherConndition}
              onChange={(evt) => handleChange(evt.target.value, "beforeAccidentWeatherConndition")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Visibility'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.beforeAccidentVisibility}
              onChange={(evt) => handleChange(evt.target.value, "beforeAccidentVisibility")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Which Vehicle Lights Were On?'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.beforeAccidentVehicleLightsWereOn}
              onChange={(evt) => handleChange(evt.target.value, "beforeAccidentVehicleLightsWereOn")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Street Lighting?'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.beforeAccidentStreetLighting}
              onChange={(evt) => handleChange(evt.target.value, "beforeAccidentStreetLighting")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>MOMENT OF IMPACT</h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Input
              type={'text'}
              placeholder={'Speed KM/H'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.momentOfImpactSpeed}
              onChange={(evt) => handleChange(evt.target.value, "momentOfImpactSpeed")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Weather Conndition'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.momentOfImpactWeatherConndition}
              onChange={(evt) => handleChange(evt.target.value, "momentOfImpactWeatherConndition")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Visibility'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.momentOfImpactVisibility}
              onChange={(evt) => handleChange(evt.target.value, "momentOfImpactVisibility")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Which Vehicle Lights Were On?'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.momentOfImpactVehicleLightsWereOn}
              onChange={(evt) => handleChange(evt.target.value, "momentOfImpactVehicleLightsWereOn")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Street Lighting?'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.momentOfImpactStreetLighting}
              onChange={(evt) => handleChange(evt.target.value, "momentOfImpactStreetLighting")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Was Any Warning Given By You, e.g. Hooting, Indicator, Etc.?
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `wasAnyWarningGiven`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.wasAnyWarningGiven === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.wasAnyWarningGiven === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              POLICE
            </h4>
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type="textarea"
              placeholder="Police/Traffic Officer Who Recorded Details Of Accident »"
              variant="standard"
              fullWidth={true}
              value={urFormState.police}
              onChange={(evt) => handleChange(evt.target.value, "police")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <TextField
              type="textarea"
              placeholder="Full Name"
              variant="standard"
              fullWidth={true}
              value={urFormState.policeFullName}
              onChange={(evt) => handleChange(evt.target.value, "policeFullName")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Police Ref. No. / Police Report'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.policeRefNo}
              onChange={(evt) => handleChange(evt.target.value, "policeRefNo")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Date And Time'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.policeDateSubmitted}
              handleChange={newDate => handleChange(newDate, "policeDateSubmitted")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Station Reported'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.stationReported}
              onChange={(evt) => handleChange(evt.target.value, "stationReported")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              DRIVER
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <Grid item xs={12}>
              <h5 className={classes.blackBorderTitle}>
                Was The Driver Tested For Alcohol Or Drugs
              </h5>
            </Grid>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={(evt) => {
                handleChange(evt.target.value, `driverTestedForAlcohol`);
              }}
              >
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.driverTestedForAlcohol === 'No' ? true : false}
                labelPlacement="start"
                label="No"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.driverTestedForAlcohol === 'Yes' ? true : false}
                label="Yes"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="Description Of Accident"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.descriptionOfAccident}
              onChange={(evt) => handleChange(evt.target.value, "descriptionOfAccident")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <TextField
              type="textarea"
              placeholder="Sketch Of Accident"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={2}
              value={urFormState.sketchOfAccident}
              onChange={(evt) => handleChange(evt.target.value, "sketchOfAccident")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              LICENCE INSPECTED
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              I Have Inspected The Driver’s Licence And It Is Free Of
              Endorsements/Endorsed As Shown.
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              Please Attach Copies Of Driver’s Licence And Page 1 Of Driver’s
              Identity Document.
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Signature'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.licenceSignature}
              onChange={(evt) => handleChange(evt.target.value, "licenceSignature")}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={6}>
            <Input
              type={'text'}
              placeholder={'Capacity'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.licenceCapacity}
              onChange={(evt) => handleChange(evt.target.value, "licenceCapacity")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={12}
            style={{ backgroundColor: '#6cccf560' }}>
            <h4
              className={classes.blackBorderTitle}
              style={{
                fontSize: '18px',
                fontWeight: '900',
                fontFamily: 'sans-serif'
              }}>
              DECLARATION
            </h4>
          </Grid>

          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              We Hereby Declare The Aforegoing Particulars To be True And
              Complete In Every Aspect
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.declartionDateSubmitted}
              handleChange={newDate => handleChange(newDate, "declartionDateSubmitted")}
            />
          </Grid>

          <Grid
            className={classes.blackBorder}
            item
            xs={8}
            style={{ textAlign: 'center' }}>
            <IconButton
              variant={'outlined'}
              onClick={() => handleOpenSignPanModal('driverSignature')}>
              Signature Of Driver{' '}
              <img src={SignIcon} width="26" height={26} alt="" />
            </IconButton>
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <Input
              type={'text'}
              placeholder={'Capacity'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.declartionCapacity}
              onChange={(evt) => handleChange(evt.target.value, "declartionCapacity")}
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: '100%' }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.secondDeclartionDateSubmitted}
              handleChange={newDate => handleChange(newDate, "secondDeclartionDateSubmitted")}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ textAlign: 'center' }}>
            <IconButton
              variant={'outlined'}
              onClick={() => handleOpenSignPanModal('insuredSignature')}>
              Insured's Signature{' '}
              <img src={SignIcon} width="26" height={26} alt="" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid className={classes.blackBorder} item xs={12}>
            <h5 className={classes.blackBorderTitle}>
              N.B. IT IS IMPORTANT THAT YOU NOTIFY THE INSURERS IMMEDIATELY YOU
              BECOME AWARE OF ANY IMPENDING PROSECUTION,INQUEST OR DEMAND
            </h5>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent 
          t={t}                         
          onClick={() => {
            handleCreateMotorAccidentSubmit(urFormState);
                        }} loading={false} />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default MotorAccidentForm;
