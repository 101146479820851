import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateBduTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
}) {
    const classes = useStyles();
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerAccountNumber, setCustomerAccountNumber] = useState('');
    const [TypeOfEquipment, setTypeOfEquipment] = useState('');
    const [Sla, setSla] = useState('');
    const [Quatation, setQuatation] = useState('');
    const [DiscountForm, setDiscountForm] = useState('');
    const [ProofOfConceptDocument, setProofOfConceptDocument] = useState('');
    const [ProvisioningTeam, setProvisioningTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setCustomerName('');
            setCustomerAccountNumber('');
            setTypeOfEquipment('');
            setSla('');
            setDiscountForm('');
            setQuatation('');
            setProofOfConceptDocument('');
            setProvisioningTeam('');
            setNextTeamMemberEmail('');
        }
    }, [modalState, open]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('bduTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : ((preValuesOfForm.project_info && preValuesOfForm.project_info.case_number) ? preValuesOfForm.project_info.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('customerName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.account_name ? preValuesOfForm.account_name : ''}
                                type={'text'} disabled
                                placeholder={t('customerName.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                // onChange={evt => setCustomerName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('customer.label') + ' ' + t('accountNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.account_number ? preValuesOfForm.account_number : ''}
                                type={'text'} disabled
                                placeholder={t('customer.label') + ' ' + t('accountNumber.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                // onChange={evt => setCustomerAccountNumber(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('typeOfEquipmentToBeInstalled.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipment_type ? preValuesOfForm.equipment_type : ''}
                                type={'text'} disabled
                                placeholder={t('typeOfEquipmentToBeInstalled.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                // onChange={evt => setTypeOfEquipment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('quotation.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!(preValuesOfForm.solution_info && preValuesOfForm.solution_info.quotation) ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if ((preValuesOfForm.solution_info && preValuesOfForm.solution_info.quotation) != undefined) {
                                        window.open(preValuesOfForm.solution_info.quotation);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('plan.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={Sla}
                                type={'text'}
                                placeholder={t('plan.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSla(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attach.label')} {t('discountForm.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setDiscountForm(file);
                                    } else {
                                        setDiscountForm('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attach.label')} {t('proofOfConceptDocument.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setProofOfConceptDocument(file);
                                    } else {
                                        setProofOfConceptDocument('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("provisioningTeam.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setProvisioningTeam(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!Sla || !ProvisioningTeam || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // create URL for quatation file
                            let getDiscountForm = "";
                            if (DiscountForm != "" && DiscountForm != null) {
                                let getDiscountFormUrl = await handleUploadFiles(DiscountForm, preValuesOfForm.project, 'bdu_team');
                                if (getDiscountFormUrl.data && getDiscountFormUrl.data.allUploadFile && getDiscountFormUrl.data.allUploadFile.url) {
                                    getDiscountForm = getDiscountFormUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            
                            let getProofOfConceptDocument = "";
                            if (ProofOfConceptDocument != "" && ProofOfConceptDocument != null) {
                                let getProofOfConceptDocumentUrl = await handleUploadFiles(ProofOfConceptDocument, preValuesOfForm.project, 'bdu_team');
                                if (getProofOfConceptDocumentUrl.data && getProofOfConceptDocumentUrl.data.allUploadFile && getProofOfConceptDocumentUrl.data.allUploadFile.url) {
                                    getProofOfConceptDocument = getProofOfConceptDocumentUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                account_name: preValuesOfForm.account_name ? preValuesOfForm.account_name : '',
                                account_number: preValuesOfForm.account_number ? preValuesOfForm.account_number : '',
                                sla: Sla,
                                equipment_type: preValuesOfForm.equipment_type ? preValuesOfForm.equipment_type : '',
                                proof_of_concept: getProofOfConceptDocument,
                                discount_form: getDiscountForm,
                                quotation: "No file",
                                provisioning_team: ProvisioningTeam,
                                next_team_member_email: nextTeamMemberEmail,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateBduTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateBduTeamModal);