import React, { useState } from 'react';
import { marginGenerator } from '../../../../../theme/utils';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TableContainer,
    Typography,
    Input,
    Button,
    CircularProgress,
} from '@material-ui/core';
import commonStyles from '../../../../../theme/commonStyles';
import OutlinedSelect from "../../../../../components/OutlinedSelect";
import DateTimeSelector from '../../../../../components/DateTimeSelector';
import Autocomplete from '../../../../../components/Autocomplete';
import videoIcon from '../../../../../assets/icons/video-icon.png';
import { dateFormatterLLLHHMMSS } from '../../../../../utils';
import Message from '../../../../../components/Message';
import { Pagination } from '@material-ui/lab';
import { get } from 'lodash-es';

function renderFRName(tags){
    if (!tags) return "-";
    let tagName = "-";
    if (Object.keys(tags).toString() === 'faces') {
        tagName = Object.keys(tags.faces).toString();
    } else {
        tagName = Object.keys(tags).toString();
    }
    return tagName;
}

const RegisterFaces = ({
    t,
    filterList,
    columns,
    list,
    handleImageModalClick,
    handlePageChange,
    page,
    loading
}) => {

    const commonClasses = commonStyles();
    
    return (
        <div style={{overflow: "hidden"}}>
            <Grid container spacing={1} >
                {filterList &&
                    filterList.map((filter, index) => {
                        if (filter.type && filter.type == 'input') {
                            return (
                                <Grid item xs={2}>
                                    <DateTimeSelector key={index} inputStyle={{ padding: 10, minWidth: 190 }} label={filter.label} dateFormat={"yyyy-MM-dd HH:mm"} value={filter.val} handleChange={filter.handleChange} styleOverrides={filter.styleOverrides} className={filter.className ? filter.className : null} disableFuture={true} />
                                </Grid>
                            )
                        } else if (filter.type && filter.type === "autocomplete") {
                            return (
                                <Grid item xs={3}>
                                    <Autocomplete
                                        key={index}
                                        styleOverrides={filter.styleOverrides || null}
                                        handleChange={filter.handleChange}
                                        options={filter.options}
                                        label={filter.label}
                                        size="small"
                                        onInputChange={filter.onInputChange}
                                    />
                                </Grid>
                            );
                        } else if (filter.type && filter.type === "text") {
                            return (
                                <Grid item xs={2}>
                                    <Input
                                        value={filter.searchByFace}
                                        type={'text'}
                                        placeholder={t('search.label')}
                                        style={{ minWidth: 190, padding: 10 }}
                                        color={'secondary'}
                                        onChange={filter.handleChange}
                                    />
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        key={index}
                                        val={filter.val}
                                        label={filter.label}
                                        handleChange={filter.handleChange}
                                        IconComponent={filter.iconComponent || null}
                                        options={filter.options}
                                        styleOverrides={filter.styleOverrides || null}
                                        disabled={filter.disabled || false}
                                    />
                                </Grid>
                            )
                        }
                    })
                }
            </Grid>
            <Grid container style={{ ...marginGenerator('mt-24') }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!loading && list ?
                            (list.notifications && list.notifications.length > 0) ?
                                list.notifications.map((rowitem, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <img
                                                    onClick={(evt) => {
                                                        handleImageModalClick(rowitem.image_url);
                                                    }}
                                                    src={rowitem.image_url ? rowitem.image_url.includes("mp4") ? videoIcon : rowitem.image_url : ""}
                                                    alt={'event'}
                                                    style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: "50%" }}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    {renderFRName(rowitem.tags)}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'}> {rowitem.camera.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'}>
                                                        {dateFormatterLLLHHMMSS(rowitem.created_at)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))
                            :
                                <TableRow>
                                    <TableCell colSpan={columns ? columns.length : 0}>
                                        <Typography variant={'subtitle2'}>Record Not Found!</Typography>
                                    </TableCell>
                                </TableRow>
                            :
                            <TableRow>
                                <TableCell colSpan={columns ? columns.length : 0} style={{ textAlign: "center" }}>
                                    <CircularProgress color="secondary" />
                                </TableCell>
                            </TableRow>
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading &&
                <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Grid container item xs={4}>
                        <span>Total {list ? Math.ceil(get(list, 'count', null) / 20) : 0} page,  {get(list, 'count', null)} Row</span>
                    </Grid>
                    <Grid container item xs={6}>
                        <Pagination
                            color="secondary"
                            count={list ? Math.ceil(list.count / 20) : 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Grid>
                </Grid>}
            </Grid>
        </div>
    )
}

export default RegisterFaces