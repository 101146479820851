import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { InternalPaths } from '../../../routes/routePaths';
import CustomTabs from "../../../components/CustomTabs";
import CashAdvanceRequestTeam from './components/CashAdvanceRequestTeam';
import EhodTeam from './components/EhodTeam';
import MdTeam from './components/MdTeam';
import MyRequest from './components/MyRequest';
import PettyCashierTeam from './components/PettyCashierTeam';
import ProcurementTeam from './components/ProcurementTeam';
import HumanCapitalTeam from './components/HumanCapitalTeam';
import TreasuryManagementTeam from './components/TreasuryManagementTeam';
import FinalPettyCashierTeam from './components/FinalPettyCashierTeam';
import { USER_ROLES, COUNTRY_LIST } from "../../../utils/constants";
import Typography from "@material-ui/core/Typography";

const breadcrumbList = [
    { label: 'home.label', link: InternalPaths.Dashboard },
    { label: 'workflowManagement.label', link: InternalPaths.workflowManagement }
];
const WorkflowManagement = ({ t }) => {
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const [refreshTab, setRefreshTab] = useState(false);
    const handleRefreshTab = (event) => {
        setRefreshTab(event);
    }
    let getAllNigeriaPmoRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN, USER_ROLES.PROJECT_MANAGER, USER_ROLES.INSTALL_TEAM_LEAD, USER_ROLES.INSTALL_TEAM,
    USER_ROLES.CHANGE_CONTROL_TEAM, USER_ROLES.INTEGRATION_TEAM, USER_ROLES.QUALITY_CONTROL_TEAM, USER_ROLES.EP_FINANCE,
    USER_ROLES.CSOC_MANAGER, USER_ROLES.IM_TEAM, USER_ROLES.RADIO_ENGINEER_TEAM, USER_ROLES.INSTALL_TEAM_ADMIN, USER_ROLES.IM_TEAM_ADMIN, USER_ROLES.CHANGE_CONTROL_TEAM_ADMIN,
    USER_ROLES.INTEGRATION_TEAM_ADMIN, USER_ROLES.QUALITY_CONTROL_TEAM_ADMIN, USER_ROLES.FINANCE_TEAM_ADMIN, USER_ROLES.CSOC_TEAM_ADMIN, USER_ROLES.PMO_READ_ONLY];
    let checkNigeriaRoleResult = getAllNigeriaPmoRole.some(i => role.includes(i));
    let getAllPmoAdminRole = [USER_ROLES.PROJECT_MANAGER, USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN, USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_READ_ONLY];
    let checkPmoAdminRoleResult = getAllPmoAdminRole.some(i => role.includes(i));
    let tabs = [];
    // if (checkPmoAdminRoleResult) {
    //     tabs.push({
    //         label: t('analytics.label'),
    //         children: (
    //             <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
    //         )
    //     })
    // }
    // {
    //     label: t("myRequest.label"),
    //     children: (
    //         <MyRequest t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
    //     )
    // },
    if (COUNTRY_LIST.NIGERIA === countryName) {
        tabs.push({
            label: t("lineManager.label"),
            children: (
                <CashAdvanceRequestTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: t("ehod.label"),
            children: (
                <EhodTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.MD_TEAM)) {
            tabs.push({
                label: t("md.label"),
                children: (
                    <MdTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PETTY_CASHIER_TEAM)) {
            tabs.push({
                label: t("pettyCashier.label"),
                children: (
                    <PettyCashierTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PROCUREMENT_TEAM)) {
            tabs.push({
                label: t("procurement.label"),
                children: (
                    <ProcurementTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.HUMAN_CAPITAL_TEAM)) {
            tabs.push({
                label: t("humanCapital.label"),
                children: (
                    <HumanCapitalTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.TREASURY_MANAGER_TEAM)) {
            tabs.push({
                label: t("treasuryManager.label"),
                children: (
                    <TreasuryManagementTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PETTY_CASHIER_TEAM)) {
            tabs.push({
                label: t("final.label") + ' ' + t("pettyCashier.label"),
                children: (
                    <FinalPettyCashierTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
    }
    return (
        <Layout breadcrumbList={breadcrumbList}>
            {(tabs.length === 0) &&
                <Typography variant={'subtitle2'}>{t('unathorisedUserPage.label')}</Typography>
            }
            <CustomTabs
                tabs={tabs}
            />
        </Layout>
    );
};
export default withTranslation()(WorkflowManagement);
