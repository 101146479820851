import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateInstallTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    allBduTeamUserList
}) {
    const classes = useStyles();
    const [provisioningOfAccount, setProvisioningOfAccount] = useState('');
    const [installationRequirements, setInstallationRequirements] = useState('');
    const [installationCompleteDate, setInstallationCompleteDate] = useState('');
    const [materialUsed, setMaterialUsed] = useState('');
    const [signOfInstallationForm, setSignOfInstallationForm] = useState('');
    const [pmTeam, setPMTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [BduTeam, setBduTeam] = useState('');
    const [BduTeamEmail, setBduTeamEmail] = useState('');
    React.useEffect(() => {
        if (modalState.clearData === true) {
            setProvisioningOfAccount('');
            setInstallationRequirements('');
            setInstallationCompleteDate('');
            setMaterialUsed('');
            setSignOfInstallationForm('');
            setPMTeam('');
            setNextTeamMemberEmail('');
            setBduTeam('');
            setBduTeamEmail('');
        }
    }, [modalState, open]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('installTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : ((preValuesOfForm.project_info && preValuesOfForm.project_info.case_number) ? preValuesOfForm.project_info.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('provisioningOfAccount.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={provisioningOfAccount}
                                type={'text'}
                                placeholder={t('provisioningOfAccount.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProvisioningOfAccount(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('installationRequirements.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={installationRequirements}
                                type={'text'}
                                placeholder={t('installationRequirements.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setInstallationRequirements(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('installationCompleteDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={installationCompleteDate}
                                    onChange={date => setInstallationCompleteDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Please select a date"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('materialUsed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={materialUsed}
                                type={'text'}
                                placeholder={t('materialUsed.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setMaterialUsed(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('signOfInstalltionForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setSignOfInstallationForm(file);
                                    } else {
                                        setSignOfInstallationForm('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("projectManagementTeam.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setPMTeam(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("bduTeam.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setBduTeam(evt.target.value);
                                        for (let i = 0; i < allBduTeamUserList.length; i++) {
                                            if (allBduTeamUserList[i].name == evt.target.value) {
                                                setBduTeamEmail(allBduTeamUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allBduTeamUserList ? allBduTeamUserList : []}
                            />
                        </Grid>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            // let convertContactDate = "";
                            if (!provisioningOfAccount || !installationRequirements || !installationCompleteDate || !materialUsed || !signOfInstallationForm || !pmTeam || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // convertContactDate = dateFormatterYYYYMMDD(installationCompleteDate);
                            // if (convertContactDate == "Invalid date") {
                            //     Message.error(convertContactDate);
                            // }
                            // create URL for quatation file
                            let getSignOfInstallationMannual = "";
                            if (signOfInstallationForm != "" && signOfInstallationForm != null) {
                                let getSignOfInstallationMannualUrl = await handleUploadFiles(signOfInstallationForm, preValuesOfForm.project, 'install_team_zambia');
                                if (getSignOfInstallationMannualUrl.data && getSignOfInstallationMannualUrl.data.allUploadFile && getSignOfInstallationMannualUrl.data.allUploadFile.url) {
                                    getSignOfInstallationMannual = getSignOfInstallationMannualUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                provisioning_of_account: provisioningOfAccount,
                                installation_requirements: installationRequirements,
                                installation_complete_date: moment(installationCompleteDate).format("YYYY-MM-DD"),
                                material_user: materialUsed,
                                sign_of_installation_form: getSignOfInstallationMannual,
                                project_manage_ment_team_name: pmTeam,
                                next_team_member_email: nextTeamMemberEmail,
                                bdu_team: BduTeam,
                                bdu_team_email: BduTeamEmail,
                                auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateInstallTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateInstallTeamModal);