import React, { useState, createContext, useCallback } from 'react';

export const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const [profile, setProfile] = useState('');
  const detailData = [{ name: "vishal" }, { name: "vishu" }]

  const setProfileData = useCallback(userData => {
    setProfile(userData);
  }, [setProfile]);

  const resetProfile = () => setProfile('');

  return (
    <ProfileContext.Provider
      value={{
        profile,
        detailData,
        setProfileData,
        resetProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;