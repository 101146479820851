import React from 'react';
import { Typography, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    width: theme.spacing(130),
    minHeight: theme.spacing(25),
    border: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const InsightPreview = ({ queryData, setQueryData }) => {
  const classes = useStyles();

  const handleAbort = () => {
    setQueryData('');
  };

  return (
    <>
      <Container className={classes.container} maxWidth="lg">
        <Grid
          className={classes.cardContainer}
          container
          direction="column"
          spacing={2}>
          <Grid item>
            <Typography gutterBottom>
              <Link onClick={handleAbort}> Abort-Remove</Link>
            </Typography>
            <Typography gutterBottom>{queryData}</Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InsightPreview;
