import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    mainTable: {
        marginBottom: 20,
        borderBottomColor: '#9696A4',
        borderBottomWidth: 1,
        fontSize: 8
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 8,
        fontStyle: 'bold'
    },
    description: {
        width: '23%',
        marginLeft: '5px'
    },
    head1: {
        width: '5%',
        textAlign: "center"
    },
    head2: {
        width: '8%',
        textAlign: "center"
    },
});

const InvoiceTableRow = ({ item }) => {
    return (<View style={styles.mainTable}>
        <View style={styles.row}>
            <Text style={styles.head1}>1</Text>
            <Text style={styles.head2}>{item.A1}</Text>
            <Text style={styles.description}>{item.XVBASITE}</Text>
            <Text style={styles.description}>{item.ITMDES}</Text>
            <Text style={styles.head2}>{item.STRDAT}</Text>
            <Text style={styles.head2}>{item.ENDDAT}</Text>
            <Text style={styles.head2}>{item.QTY}</Text>
            <Text style={styles.head2}>{(item.NETPRI).toFixed(2)}</Text>
            <Text style={styles.head2}>{(item.QTY * item.NETPRI).toFixed(2)}</Text>
        </View>
    </View>);
};

export default InvoiceTableRow