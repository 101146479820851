import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { marginGenerator } from '../../../../../theme/utils';
import { dateFormatterLLL, getParam } from '../../../../../utils';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TicketStatus from '../TicketStatus';
import TicketPriority from '../../../components/TicketPriority';
import PropTypes from 'prop-types';
import Avatar from '../../../../../components/Avatar';
import Accordians from '../../../../../components/Accordion';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    fright: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    imageAlign: {
        width: '180px',
        height: 'auto',
        border: '2px dashed #661723'
    },
    list: {
        height: 150,
        border: '1px solid #5a1f2a',
        overflowX: 'hidden',
    },
    emailAddress: {
        fontSize: 14
    }
}));

const RequestDetailsPanel = ({ requestLastData, requestData, t, reloadTickets }) => {
    const classes = useStyles();
    const history = useHistory();
    const Id = getParam();

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new File(byteArrays, "pot", { type: contentType });
    }
    function convertBase64ToUrl(imgStr) {
        let enc = window.atob(imgStr);
        let image = new File([enc], `${new Date().getTime()}.jpg`, {
            type: "image/jpeg"
        });
        let file = b64toBlob(imgStr, "image/jpeg");
        let imgURL = URL.createObjectURL(file);
        return imgURL;
    }

    return (
        <>
            <Grid container justify={'space-between'}>
                <Grid container item xs={9}>
                    <Grid item style={marginGenerator('mr-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('status.label')}
                        </Typography>
                        <TicketStatus t={t} status={requestData.status} />
                    </Grid>
                    {requestLastData.changeMatrixImpact != null ? (
                        <Grid item style={{ marginLeft: '32px' }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('priority.label')}
                            </Typography>
                            <TicketPriority t={t} priority={requestLastData.changeMatrixImpact} />
                        </Grid>
                    ) : null}
                    {requestLastData.changeUrgency != null ? (
                        <Grid item style={{ marginLeft: '32px' }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('urgency.label')}
                            </Typography>
                            <TicketPriority t={t} priority={requestLastData.changeUrgency} />
                        </Grid>
                    ) : null}
                    <Grid item style={{ marginLeft: '32px' }}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('inCharge.label')}
                        </Typography>
                        {requestLastData.inCharge.toUpperCase()}
                    </Grid>
                    <Grid item style={{ marginLeft: '32px' }}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('ticketRaisedOn.label')}
                        </Typography>
                        {requestLastData.createdAt && requestLastData.createdAt ?
                            dateFormatterLLL(requestData.createdAt) : null}
                    </Grid>

                    <Grid container item style={{ marginTop: 32 }}>
                        <Grid item style={marginGenerator('mr-16')}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {requestLastData.changeType ? t('changeType.label') : null}
                            </Typography>
                            {requestLastData.changeType}
                        </Grid>
                        <Grid item style={{ marginLeft: '32px' }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('implimentationStartDate.label')}
                            </Typography>
                            {requestLastData.implimentStartDate && requestLastData.implimentStartDate ?
                                dateFormatterLLL(requestData.implimentStartDate) : null}
                        </Grid>
                        <Grid item style={{ marginLeft: '32px' }}>
                            <Typography
                                variant={'subtitle2'}
                                color={'textPrimary'}
                                style={marginGenerator('mb-16')}>
                                {t('implimentationEndDate.label')}
                            </Typography>
                            {requestLastData.implimentEndDate && requestLastData.implimentEndDate ?
                                dateFormatterLLL(requestData.implimentEndDate) : null}
                        </Grid>
                    </Grid>

                    {requestLastData.lineManager !== null ||
                        requestLastData.reserveAgent !== null ||
                        requestLastData.reserveAgent2 !== null ? (
                        <Grid container item style={{ marginTop: 32 }}>
                            <Grid item style={{ marginRight: 10 }}>
                                <Typography
                                    variant={'subtitle2'}
                                    color={'textPrimary'}
                                    style={marginGenerator('mb-16')}>
                                    {requestLastData.reserveAgent ? t('reserveChangeAgent.label') : null}
                                </Typography>
                                <span className={classes.emailAddress}>{requestLastData.reserveAgent ? `${requestLastData.reserveAgent.firstName} ${requestLastData.reserveAgent.lastName} (${requestLastData.reserveAgent.emailAddress})` : null}</span>
                            </Grid>
                            <Grid item style={{ marginRight: 10 }}>
                                <Typography
                                    variant={'subtitle2'}
                                    color={'textPrimary'}
                                    style={marginGenerator('mb-16')}>
                                    {requestLastData.reserveAgent2 ? t('reserveChangeAgent2.label') : null}
                                </Typography>
                                <span className={classes.emailAddress}>{requestLastData.reserveAgent2 ? `${requestLastData.reserveAgent2.firstName} ${requestLastData.reserveAgent2.lastName} (${requestLastData.reserveAgent2.emailAddress})` : null}</span>
                            </Grid>
                            <Grid item style={{ marginRight: 10 }}>
                                <Typography
                                    variant={'subtitle2'}
                                    color={'textPrimary'}
                                    style={marginGenerator('mb-16')}>
                                    {requestLastData.lineManager ? t('lineManager.label') : null}
                                </Typography>
                                <span className={classes.emailAddress}>{requestLastData.lineManager ? `${requestLastData.lineManager.firstName} ${requestLastData.lineManager.lastName} (${requestLastData.lineManager.emailAddress})` : null}</span>
                            </Grid>
                        </Grid>
                    ) : null}

                    {requestLastData.eHOD !== null ? (
                        <Grid container item style={{ marginTop: 32 }}>
                            <Grid item style={{ marginRight: 10 }}>
                                <Typography
                                    variant={'subtitle2'}
                                    color={'textPrimary'}
                                    style={marginGenerator('mb-16')}>
                                    {requestLastData.eHOD ? t('ehod.label') : null}
                                </Typography>
                                <span className={classes.emailAddress}>{requestLastData.eHOD ? `${requestLastData.eHOD.firstName} ${requestLastData.eHOD.lastName} (${requestLastData.eHOD.emailAddress})` : null}</span>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container item xs={3} direction={'column'}>
                    <Grid item>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('createdBy.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={requestData.createdByName} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {requestData.createdByName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={marginGenerator('mt-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('ticketupdatedOn.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={dateFormatterLLL(requestData.updatedAt)} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {dateFormatterLLL(requestData.updatedAt)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={marginGenerator('mt-16')}>
                        <Typography
                            variant={'subtitle2'}
                            color={'textPrimary'}
                            style={marginGenerator('mb-16')}>
                            {t('updatedBy.label')}
                        </Typography>
                        <Grid
                            container
                            item
                            direction={'row'}
                            alignItems={'center'}
                            justify={'flex-start'}>
                            <Avatar name={requestData.updatedByName} variant={'medium'} />
                            <Typography variant={'subtitle2'} color={'textPrimary'}>
                                {requestData.updatedByName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={marginGenerator('mb-32')}>
                    <Typography variant={'subtitle2'} color={'textPrimary'}>
                        {requestLastData.attachments.length ? t('attachments.label') : null}
                    </Typography>
                </Grid>
                {requestLastData.attachments &&
                    requestLastData.attachments.map((item, index) => (
                        <Grid item xs={2} style={marginGenerator('mb-8')}>
                            <Link
                                variant={'body2'}
                                color={'textPrimary'}
                                href={requestLastData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                                target={'_blank'}>
                                <img
                                    className={classes.imageAlign}
                                    src={requestLastData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                                    href={requestLastData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                                    target="_blank"
                                />
                            </Link>
                        </Grid>
                    ))}
            </Grid>

            <Divider
                variant={'fullWidth'}
                absolute
                style={{ bottom: 'auto', marginTop: 18 }}
            />
            <Grid
                container
                justify={'space-between'}
                style={{ paddingBottom: '50px', paddingTop: '20px' }}>
                <Grid item xs={12} style={marginGenerator('mb-32')}>
                    <Accordians
                        description={requestLastData.description}
                        panel={0}
                        createdBy={requestLastData.createdByName}
                        createdAt={dateFormatterLLL(requestLastData.createdAt)}
                    />
                    {
                        requestLastData.communication && requestLastData.communication.length > 0 &&
                        requestLastData.communication.map((data, index) => (
                            <Accordians
                                description={data.description}
                                panel={index + 1}
                                ticketType={requestLastData.ticketType}
                                createdBy={data.createdByName}
                                type={data.type}
                                attachments={data.attachments}
                                createdAt={dateFormatterLLL(data.createdAt)}
                            />
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

RequestDetailsPanel.propTypes = {
    requestLastData: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default RequestDetailsPanel;
