import { gql } from 'apollo-boost';

/*
  Get all services
*/
export const GET_ALL_SERVICES_LIST_BY_TYPES = gql`
  query ServiceListByType($serviceTypeId: String) {
    getServiceListByType(serviceTypeId: $serviceTypeId) {
      id
      name
      description
      avgUptime
      avgCost
      avgInstallationTime
      serviceType {
        id
        name
      }
    }
  }
`;

/*
  Get all service types
*/
export const GET_ALL_SERVICES_TYPES = gql`
  query {
    getAllServiceTypes {
      id
      name
      countryId
      services
    }
  }
`;

export const GET_BILLING_DATA_BY_SERVICE = gql`
  query ServiceBilling($serviceId: String) {
    getBillingDataByService(serviceId: $serviceId) {
      id
      type
      notificationType
      service {
        currency
        taxes
      }
      modelType
      moduleName
      perItemCost
      discount
      createdAt
    }
  }
`;