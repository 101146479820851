import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import QuoteImg_1 from '../../../../../../assets/compare-quotes/quote-1.jpg';
import QuoteImg_2 from '../../../../../../assets/compare-quotes/quote-2.jpg';
import QuoteImg_3 from '../../../../../../assets/compare-quotes/quote-3.jpg';
import QuoteImg_4 from '../../../../../../assets/compare-quotes/quote-4.jpg';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { IconButton, Typography } from '@material-ui/core';

function RefDocModal({
    t,
    open,
    handleClose,
    handleOpenCompareModal
}) {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Compare Quotes</DialogTitle>
            <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <img src={QuoteImg_1} style={{width: 200, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                    </div>
                    <div style={{width: "100%", paddingLeft: 32}}>
                        <Typography variant="h6">Quote 1</Typography>
                        <Typography variant="subtitle1">Value: Test Quote 1</Typography>
                        <Typography variant="subtitle1">Year: 2022</Typography>
                        <Button style={{marginTop: 12}} variant="outlined" color="primary" >Add To Compare</Button>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <img src={QuoteImg_2} style={{width: 200, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                    </div>
                    <div style={{width: "100%", paddingLeft: 32}}>
                        <Typography variant="h6">Quote 2</Typography>
                        <Typography variant="subtitle1">Value: Test Quote 2</Typography>
                        <Typography variant="subtitle1">Year: 2022</Typography>
                        <Button style={{marginTop: 12}} variant="outlined" color="primary" >Add To Compare</Button>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <img src={QuoteImg_3} style={{width: 200, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                    </div>
                    <div style={{width: "100%", paddingLeft: 32}}>
                        <Typography variant="h6">Quote 3</Typography>
                        <Typography variant="subtitle1">Value: Test Quote 3</Typography>
                        <Typography variant="subtitle1">Year: 2022</Typography>
                        <Button style={{marginTop: 12}} variant="outlined" color="primary" >Add To Compare</Button>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <img src={QuoteImg_4} style={{width: 200, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                    </div>
                    <div style={{width: "100%", paddingLeft: 32}}>
                        <Typography variant="h6">Quote 4</Typography>
                        <Typography variant="subtitle1">Value: Test Quote 4</Typography>
                        <Typography variant="subtitle1">Year: 2022</Typography>
                        <Button style={{marginTop: 12}} variant="outlined" color="primary" >Add To Compare</Button>
                    </div>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenCompareModal}
                    >
                        Compare Selected Quotes
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

RefDocModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(RefDocModal);