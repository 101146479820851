import React, {useState} from 'react';
import {TIME_FILTER} from '../../../../utils/constants';
import Grid from '@material-ui/core/Grid';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import {makeStyles} from '@material-ui/core/styles';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {GET_CONNECTIVITY_INFO, GET_ELASTIC_CLOUD_DATA, GET_OPPORTUNITY_BY_ID} from '../../queries';
import Button from '@material-ui/core/Button';
import Map from './map';
import commonStyles from '../../../../theme/commonStyles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import ChartContainer from '../../../../components/Charts/ChartContainer';
import AreaEChartWrapper from '../../../../components/Charts/AreaEChartWrapper';

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));
const DIADashboardPanel = ({t, subscriptionData, data, ticketData, type}) => {
    const classes = useStyles();
    const organization_id = subscriptionData.opportunityId;
    const [selectedDevice, setSelectedDevice] = useState('');
    const [elasticCloudState, setElasticCloudState] = useState([]);
    const [elasticCloudDateState, setElasticCloudDateState] = useState([]);
    const [startDate, setStartDateState] = useState('');
    const [endDate, setEndDateState] = useState('');
    const [toggleState, setToggleState] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const ucpeFilter = [];
    if (ucpeFilter.length > 0 && firstLoad) {
        setFirstLoad(false);
        setSelectedDevice(ucpeFilter[0]['id']);
        let now = new Date();
        let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
        defaultDate = new Date(defaultDate);
        setStartDateState(defaultDate.toISOString().substring(0, 16));
        setEndDateState(new Date().toISOString().substring(0, 16));
    }
    const elasticFilterList = [
        {
            label: t('startDate.label'),
            handleChange: evt => {
                console.log(evt.target.value);
                setStartDateState(evt.target.value);
            },
            val: startDate,
            name: 'startDate',
            styleOverrides: {
                ...paddingGenerator('pt-10'),
                ...paddingGenerator('pb-7')
            },
            type: 'input'
        },
        {
            label: t('endDate.label'),
            handleChange: evt => {
                setEndDateState(evt.target.value);
            },
            val: endDate,
            name: 'endDate',
            styleOverrides: {
                ...paddingGenerator('pt-10'),
                ...paddingGenerator('pb-7')
            },
            type: 'input'
        }
    ];
    const connectivityColumns = [
        {
            label: t('srNo.label'),
            key: 'Sl.No'
        },
        {
            label: t('ipAddress.label'),
            key: 'ipAddress'
        },
        {
            label: t('vLanId.label'),
            key: 'vLanId'
        },
        {
            label: t('speed.label'),
            key: 'speed'
        }
    ];
    const {
        loading: connectivityLoading,
        data: connectivityInfoList,
        error: connectivityError
    } = useQuery(GET_CONNECTIVITY_INFO, {
        variables: {
            opportunityId: organization_id
        }
    });
    const {
        loading: loadingPpportunity,
        data: opportunityDetails,
        error: opportunityError
    } = useQuery(GET_OPPORTUNITY_BY_ID, {
        variables: {
            opportunityId: organization_id
        }
    });
    const getDatesBetweenDates = (start, end) => {
        let dates = [];
        const theDate = new Date(start);
        end = new Date(end);
        while (theDate < end) {
            dates.push({name: new Date(theDate).toISOString().split('T')[0], val: 0});
            theDate.setDate(theDate.getDate() + 1);
        }
        return dates;
    };
    const bytesToSize = (bytes) => {
        if (bytes === 0) return 0;
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2);
    }
    const [getElasticCloudState] = useLazyQuery(GET_ELASTIC_CLOUD_DATA, {
        fetchPolicy: 'no-cache',
        onError: error => {
            console.log('Error-=-=-=-=-=69', error);
        },
        onCompleted: elasticFlowData => {
            if (elasticFlowData && elasticFlowData.getElasticCloudFlowData && elasticFlowData.getElasticCloudFlowData.data) {
                let responseData = JSON.parse(elasticFlowData.getElasticCloudFlowData.data);
                console.log(responseData, 'responseData')
                if (responseData && responseData.hits && responseData.hits.hits && responseData.hits.hits.length) {
                    let IpAddress = connectivityInfoList && connectivityInfoList.getConnectivityInfo.length && connectivityInfoList.getConnectivityInfo[0].ip_address;
                    const start = new Date(startDate).toISOString();
                    const end = new Date(endDate).toISOString();
                    let dates = getDatesBetweenDates(start, end), dtr = [];
                    let incomingData = [], outGoingData = [];
                    responseData.hits.hits.forEach(hit => {
                        let splitDate = hit._source['@timestamp'].split('T');
                        if (hit._source.client.domain === IpAddress) {
                            incomingData.push({
                                name: splitDate[0] + ' ' + splitDate[1].substring(0, 5),
                                val: (hit._source.network.bytes / 1000000)
                            });
                        }
                        if (hit._source.server.domain === IpAddress) {
                            outGoingData.push({
                                name: splitDate[0] + ' ' + splitDate[1].substring(0, 5),
                                val: (hit._source.network.bytes / 1000000)
                            });
                        }
                        dtr.push(splitDate[0]);
                    });
                    const filtereddates = dates.filter((item) => !dtr.includes(item.name));
                    outGoingData = [...outGoingData, ...filtereddates];
                    incomingData = [...incomingData, ...filtereddates];
                    outGoingData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    incomingData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    if (outGoingData.length) {
                        setElasticCloudDateState(outGoingData.map(item => item.name));
                    } else {
                        setElasticCloudDateState(incomingData.map(item => item.name));
                    }
                    let AreaChartData = [
                        {
                            name: 'Incoming',
                            type: 'line',
                            stack: 'In',
                            areaStyle: {normal: {}},
                            data: incomingData.map(item => item.val)
                        },
                        {
                            name: 'Outgoing',
                            type: 'line',
                            stack: 'Out',
                            areaStyle: {normal: {}},
                            data: outGoingData.map(item => item.val)
                        }
                    ];
                    setElasticCloudState(AreaChartData);
                } else {
                    setElasticCloudState([]);
                }
            }
        }
    });
    const opportunityData = opportunityDetails
        && opportunityDetails.getOpportunityById
        && opportunityDetails.getOpportunityById.opportunitiesDetails
        && JSON.parse(opportunityDetails
            && opportunityDetails.getOpportunityById
            && opportunityDetails.getOpportunityById.opportunitiesDetails);
    const coordinatesOpportunity = opportunityData && opportunityData.siteB_cordinates && opportunityData.siteB_cordinates.split(',');
    React.useEffect(() => {
        let now = new Date();
        let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
        defaultDate = new Date(defaultDate);
        setStartDateState(defaultDate.toISOString().substring(0, 16));
        setEndDateState(new Date().toISOString().substring(0, 16));
    }, []);
    React.useEffect(() => {
        let IpAddress = connectivityInfoList && connectivityInfoList.getConnectivityInfo.length && connectivityInfoList.getConnectivityInfo[0].ip_address;
        let VlanId =
            connectivityInfoList &&
            connectivityInfoList.getConnectivityInfo.length &&
            connectivityInfoList.getConnectivityInfo[0].vlan;
        if (startDate && endDate) getElasticCloudState({
            variables: {
                requestPayload: JSON.stringify({
                    'query': {
                        'bool': {
                            'filter': [
                                {
                                    'range': {
                                        '@timestamp': {
                                            'gte': moment(startDate).format('YYYY-MM-DD'),
                                            'lte': moment(endDate).format('YYYY-MM-DD')
                                        }
                                    }
                                }
                            ],
                            'should': [
                                {'term': {'client.domain': IpAddress}},
                                {'term': {'server.domain': IpAddress}},
                                {'term': {'flow.vlan': VlanId}}
                            ]
                        }
                    }
                })
            }
        });
    }, [startDate, endDate, getElasticCloudState, connectivityInfoList]);
    return (
        <div style={{...marginGenerator('mb-56'), overflow: 'hidden'}}>
            <Grid container item style={marginGenerator('mt-24')}>
                <Grid item xs container justify={'flex-end'}>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        onClick={() => setToggleState(!toggleState)}>
                        {!toggleState ? t('hideMap.label') : t('showMap.label')}
                    </Button>
                </Grid>
            </Grid>
            {!toggleState &&
            (!loadingPpportunity && !opportunityError && coordinatesOpportunity ? (
                <Grid
                    container
                    className={classes.mapContainer}
                    style={marginGenerator('mt-32')}>
                    <Map
                        id={'dia-map'}
                        markers={[{
                            position: {
                                lat: parseFloat(coordinatesOpportunity[0]),
                                lng: parseFloat(coordinatesOpportunity[1])
                            }, title: opportunityData.subject, infoData: {place_name: opportunityData.subject}
                        }]}
                    />
                </Grid>
            ) : null)}
            <Typography
                variant={'h4'}
                align={'justify'}
                style={marginGenerator('mt-24')}>
                {t('connectivityInfo.label')}
            </Typography>
            <Grid container style={marginGenerator('mt-24')}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {connectivityColumns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!connectivityLoading && !connectivityError ? (
                                connectivityInfoList.getConnectivityInfo.length > 0 ? (
                                    connectivityInfoList.getConnectivityInfo.map((connectivity, rowIndex) => {
                                        return (
                                            <TableRow key={rowIndex}>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {rowIndex + 1}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {connectivity.ip_address}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {connectivity.vlan}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant={'subtitle2'}>
                                                        {connectivity.speed + ' Mbps'}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container item style={marginGenerator('mt-24')}>
                    <Grid item xs container justify={'flex-end'}>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            color={'primary'}>
                            {t('SubmitRequest.label')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/*<ChartContainer
                title={t('elasticUtilization.label')}
                filterList={elasticFilterList}>
                <AreaEChartWrapper data={elasticCloudState}
                                   dataKeyXAxis={elasticCloudDateState}
                                   stackKeyNames={[t('incoming.label'), t('outgoing.label')]}/>
        //         <AreaElasticChartWrapper
        //   name={t('mb.label')}
        //   data={elasticCloudState.length > 0 ? elasticCloudState : []}
        //   chartColor={colors.chartColors.graphTopaz}
        //   dataKeyChart={'val'}
        //   dataKeyXAxis={'name'}
        //   stacked={true}
        //   secondChartDataKey={'secVal'}
        //   secondChartColor={colors.chartColors.graphDustyOrange}
        //   secondChartName={'Destination'}
        // />
            </ChartContainer>*/}
        </div>
    );
};
export default DIADashboardPanel;
