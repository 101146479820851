import {gql} from 'apollo-boost';
 
export const GET_OCR_LIST = gql`
    query OcrList($organization: String, $place: String, $project: String, $start_date: Int, $end_date: Int, $search: String, $page: Int, $limit: Int) {
        getOcrListdata(organization: $organization, place: $place, project: $project, start_date: $start_date, end_date: $end_date, search: $search,  page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_RULE_LIST = gql`
    query OcrRuleList($organization: String, $search: String, $page: Int $limit: Int) {
        getOcrRuleListdata(organization: $organization, search: $search, page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_DASHBOARD_DATA = gql`
    query OcrDashboardData($organization: String) {
        getOcrDashBoarddata(organization: $organization) {
            success
            message
            data
        }
    }
`;

export const GET_OCR_IMAGE_FOR_RULE = gql`
    query OcrRuleImageList($organization: String, $search: String) {
        getOcrImageListForRule(organization: $organization, search: $search) {
            success
            message
            data
        }
    }
`;

export const GET_OCR_PARSE_IMG = gql`
    query OcrParseImageData($organization: String, $id: String, $isDownload: String) {
        getOcrParseImageData(organization: $organization, id: $id, isDownload: $isDownload) {
            success
            message
            data
        }
    }
`;

export const DOWNLOAD_OCR_RULE_CSV = gql`
    query DownloadOcrRulecsv($ruleId: String) {
        downloadOcrRulecsv(ruleId: $ruleId) {
            success
            message
            presigned_url
        }
    }
`;

export const GET_OCR_PLACE_LIST = gql`
    query OcrPlaceList($organization: String, $search: String, $page: Int $limit: Int) {
        getOcrPlaceList(organization: $organization, search: $search, page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_PROJECT_LIST = gql`
    query OcrProjectList($organization: String, $place: String, $search: String, $page: Int $limit: Int) {
        getOcrProjectList(organization: $organization, place: $place, search: $search, page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_FOLDER_LIST = gql`
    query OcrFolderList($organization: String, $place: String, $project: String, $search: String, $page: Int $limit: Int) {
        getOcrFolderList(organization: $organization, place: $place, project: $project, search: $search, page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_DOC_TREE_DATA = gql`
    query OcrDocTree($organization: String, $userData: JSON, $is_superUser: Boolean) {
        getOcrDocTreeData(organization: $organization, userData: $userData, is_superUser: $is_superUser) {
            id
            name
            projects
        }
    }
`;

export const GET_OCR_DASHBOARD_MAP_DEATILS = gql`
    query GetOcrDashboardMapDetaills($organization: String, $place: String, $project: String, $folder: String, $start_date: Int, $end_date: Int) {
        getOcrDashboardMapDetaills(organization: $organization, place: $place, project: $project, folder: $folder, start_date: $start_date, end_date: $end_date) {
            success
            message
            data
        }
    }
`;

export const GET_SUBSCRIPTION_USERS = gql`
    query GetSubuscriptionUsers($groupId: String, ) {
        getSubuscriptionUsers(groupId: $groupId) {
            data
        }
    }
`;

export const GET_OCR_TREEE_VIEW_SEARCH = gql`
    query GetOcrTreeViewSearch($organization: String, $search: String) {
        getOcrTreeViewSearch(organization: $organization, search: $search) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_GROUP_LIST = gql`
    query GetOcrGroupList($organization: String) {
        getOcrGroupList(organization: $organization) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_GROUP_DATA_BY_ID = gql`
    query GetOcrGroupDataById($id: String) {
        getOcrGroupDataById(id: $id) {
            success
            message
            data
        }
    }
`;

export const GET_USERS_HAVE_FOLDER_ACCESS_OCR_LIST = gql`
    query GetUsersHaveFolderAccessOcrList($organization: String, $folder_id: String) {
        getUsersHaveFolderAccessOcrList(organization: $organization, folder_id: $folder_id) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_USERS_LIST = gql`
    query GetOcrUsersList($organization: String, $group_id: String) {
        getOcrUsersList(organization: $organization, group_id: $group_id) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_ACTIVITY_LOGS = gql`
    query OcrActivityLogs($organization: String, $search: String, $startDate: Date, $endDate: Date, $project: String, $place: String, $page: Int) {
        getOcrActivityLogs(organization: $organization, search: $search, startDate: $startDate, endDate: $endDate, project: $project, place: $place, page: $page) {
            count
            data
        }
    }
`;

export const GET_OCR_PROCESS_LIST = gql`
    query GetOcrProcessList($organization: String) {
        getOcrProcessList(organization: $organization) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_PROCESS_STEPS_LIST = gql`
    query GetOcrProcessStepsList($organization: String) {
        getOcrProcessStepsList(organization: $organization) {
            success
            message
            count
            data
        }
    }
`;

export const GET_OCR_GROUPS_OF_FOLDERS = gql`
    query GetOcrGroupsOfFolders($folder_id: String) {
        getOcrGroupsOfFolders(folder_id: $folder_id) {
            success
            message
            count
            data
        }
    }
`;

