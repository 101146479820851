import React, { useState, useEffect } from 'react';
import { TicketingSystem } from '../../../../routes/routePaths';
import Layout from '../../../../components/Layout';
import Header from '../../../../components/Header';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import Typography from '@material-ui/core/Typography';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import Radio from '@material-ui/core/Radio';
import { marginGenerator } from '../../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import { ADD_CYBER_SECURITY } from '../../../commonMutations';
import Message from '../../../../components/Message';
import { GET_USER_INFO } from '../../queries'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { RadioGroup } from '@material-ui/core';

const breadcrumbList = [
    { label: 'changeManagement.label', link: TicketingSystem.CM },
    { label: 'cyberSecurity.label', link: TicketingSystem.CyberSecurityForm }
];

const useStyles = makeStyles(theme => ({

}));

const initFormState = {
    fullName: "", address: "", jobTitle: "", email: "", phone: "", incidentTime: new Date(),
    incidentType: "", incidentDetected: "", notified: "No", notifiedDesc: "", containment: "No", containmentDesc: "",
    impactedService: "No", impactedServiceDesc: "", attackVendor: "No", attackVendorDesc: "", infoImpact: "No", infoImpactDesc: "",
    otherImpact: "No", otherImpactDesc: "",
};


function CyberSecurityForm({ t }) {
    const country = localStorage.getItem('country');
    const history = useHistory();
    const classes = useStyles();
    const commonClasses = commonStyles();

    const [formState, setFormState] = React.useState({ ...initFormState });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserData()
    }, []);

    const { data, refetch: getUserData } = useQuery(GET_USER_INFO);

    useEffect(() => {
        if (data && data.userInfo && data.userInfo.email) {
            let userData = data.userInfo
            setFormState({
                ...formState,
                fullName: userData.firstName + " " + userData.lastName, email: userData.email,
            });
        }
    }, [])

    const [createCyberSecurityForm] = useMutation(ADD_CYBER_SECURITY, {
        onError: error => {
            setLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Cyber Security Form Created Successfully.');
            setLoading(false);
            history.push(TicketingSystem.CyberSecurity);
        }
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleCancelClick = () => {
        history.goBack();
    };

    return (
        <Layout
            breadcrumbList={breadcrumbList}
            contentStyle={{ maxWidth: '990px' }}>
            <Header
                title={t('cyberSecurityLabel.label')}
                divider={true}
                subText={t('cyberSecurityDesc.label')}
                primaryButtonText={t('cancel.label')}
                handlePrimaryButtonClick={handleCancelClick}
            />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('contactPerson.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('ContactName.label')}
                    </Typography>
                    <Input
                        value={formState.fullName}
                        type={'text'}
                        disabled={formState.fullName !== "" ? true : false}
                        name="fullName"
                        style={{ width: '100%' }}
                        placeholder={"Enter Full Name"}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('address.label')}
                    </Typography>
                    <Input
                        value={formState.address}
                        type={'text'}
                        name="address"
                        style={{ width: '100%' }}
                        placeholder={"Enter Address Here"}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('title.label')}
                    </Typography>
                    <Input
                        value={formState.jobTitle}
                        type={'text'}
                        name="jobTitle"
                        style={{ width: '100%' }}
                        placeholder={"Enter Job Title"}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('phone.label')}
                    </Typography>
                    <Input
                        value={formState.phone}
                        type={'text'}
                        name="phone"
                        style={{ width: '100%' }}
                        placeholder={"Enter Phone Number"}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('email.label')}
                    </Typography>
                    <Input
                        value={formState.email}
                        type={'text'}
                        disabled={formState.email !== "" ? true : false}
                        name="email"
                        style={{ width: '100%' }}
                        placeholder={"Enter Email ID"}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('theIncident.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('incidentTime.label')}
                    </Typography>
                    <DateTimeSelector
                        label={"Select Expiry Date"}
                        dateFormat={"yyyy-MM-dd HH:mm"}
                        value={formState.incidentTime}
                        handleChange={date => setFormState({ ...formState, incidentTime: date })}
                        styleOverrides={{ width: '100%' }}
                        inputStyle={{ minWidth: 220, padding: '16px 24px' }}
                        disableFuture={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('incident.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="incidentType"
                        row
                        value={formState.incidentType}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Malware" control={<Radio />} label="Malware" />
                        <FormControlLabel value="Data Breach" control={<Radio />} label="Data Breach" />
                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('incidentDetected.label')}
                    </Typography>
                    <Input
                        value={formState.incidentDetected}
                        multiline
                        rows={3}
                        type={'text'}
                        style={{ width: '100%' }}
                        placeholder={"Please Describe How was the incident detected?"}
                        color={'secondary'}
                        name="incidentDetected"
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('notification.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('otherNotified.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="notified"
                        row
                        value={formState.notified}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.notified && formState.notified === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.notifiedDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="notifiedDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('containmentHead.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('containment.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="containment"
                        row
                        value={formState.containment}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.containment && formState.containment === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.containmentDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="containmentDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('impactedServices.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('impactedService.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="impactedService"
                        row
                        value={formState.impactedService}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.impactedService && formState.impactedService === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.impactedServiceDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="impactedServiceDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('attackVendorHead.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('attackVendor.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="attackVendor"
                        row
                        value={formState.attackVendor}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.attackVendor && formState.attackVendor === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.attackVendorDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="attackVendorDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('infoImpactHead.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('infoImpact.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="infoImpact"
                        row
                        value={formState.infoImpact}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.infoImpact && formState.infoImpact === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.infoImpactDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="infoImpactDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: "center" }}>{t('other.label')}</h2>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ ...marginGenerator('mb-8') }}>
                        {t('otherInfo.label')}
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="otherImpact"
                        row
                        value={formState.otherImpact}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {formState.otherImpact && formState.otherImpact === 'Yes' &&
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ ...marginGenerator('mb-8') }}>
                            {t('description.label')}
                        </Typography>
                        <Input
                            value={formState.otherImpactDesc}
                            multiline
                            rows={3}
                            type={'text'}
                            style={{ width: '100%' }}
                            placeholder={"Please Describe"}
                            color={'secondary'}
                            name="otherImpactDesc"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>

            <Grid
                container
                style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
                justify={'flex-end'}>
                <Button
                    variant={'contained'}
                    disabled={loading}
                    color={'primary'}
                    onClick={() => {
                        if (
                            !formState.fullName ||
                            !formState.address ||
                            !formState.jobTitle ||
                            !formState.phone ||
                            !formState.email ||
                            !formState.incidentTime ||
                            !formState.incidentType ||
                            !formState.incidentDetected
                        ) {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.notified == 'Yes' && formState.notifiedDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.containment == 'Yes' && formState.containmentDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.impactedService == 'Yes' && formState.impactedServiceDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.attackVendor == 'Yes' && formState.attackVendorDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.infoImpact == 'Yes' && formState.infoImpactDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        if (formState.otherImpact == 'Yes' && formState.otherImpactDesc == "") {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }

                        setLoading(true);
                        createCyberSecurityForm({
                            variables: {
                                fullName: formState.fullName,
                                address: formState.address,
                                jobTitle: formState.jobTitle,
                                phone: formState.phone,
                                email: formState.email,
                                incidentTime: formState.incidentTime,
                                incidentType: formState.incidentType,
                                incidentDetected: formState.incidentDetected,
                                notified: formState.notified,
                                notifiedDesc: formState.notifiedDesc,
                                containment: formState.containment,
                                containmentDesc: formState.containmentDesc,
                                impactedService: formState.impactedService,
                                impactedServiceDesc: formState.impactedServiceDesc,
                                attackVendor: formState.attackVendor,
                                attackVendorDesc: formState.attackVendorDesc,
                                infoImpact: formState.infoImpact,
                                infoImpactDesc: formState.infoImpactDesc,
                                otherImpact: formState.otherImpact,
                                otherImpactDesc: formState.otherImpactDesc
                            }
                        });
                    }}>
                    {loading ? (
                        <CircularProgress size={14} />
                    ) : (
                        'Create Ticket'
                    )}
                </Button>
            </Grid>
        </Layout>
    );
}

export default withTranslation()(CyberSecurityForm);
