import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

// ----------------------------------------------------------------------

RHFIpAddressTextField.propTypes = {
  name: PropTypes.string
};

export default function RHFIpAddressTextField({ name, ...other }) {
  const { control, errors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ReactInputMask
          mask="999.999.999.999"
          value={value}
          onChange={onChange}
          {...other}>
          {inputProps => (
            <TextField
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              type="text"
              fullWidth
              {...inputProps}
            />
          )}
        </ReactInputMask>
      )}
    />
  );
}
