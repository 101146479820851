import React, { useState, useRef, useEffect } from 'react';
import SignaturePad from "react-signature-canvas";
import "./SignatureCanvasPad.css";

const SignatureCanvasPad = ({
    t,
    value,
    handleClick,
    name
}) => {
  const [imageURL, setImageURL] = useState(null);

  const sigCanvas = useRef({});

  const cancle = () =>{
    sigCanvas.current.clear();
    handleClick(name,"");
  }
   
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    handleClick(name, sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  }

  useEffect(() => {
    if(sigCanvas.current){
     handleClick(name, sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  }, [sigCanvas])
  
  return (
    <>
    <SignaturePad
      ref={sigCanvas}
      canvasProps={{
        className: "signatureCanvas"
      }}
    />
      <button onClick={save}>Save</button>
      <button onClick={cancle}>Cancle</button>
    </>
  )
}

export default SignatureCanvasPad