import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths, SubscriptionPaths } from '../../routes/routePaths';
import SubscriptionLists from './SubscriptionLists';
import PendingSubscriptionDetails from './PendingSubscriptionDetails';
import ActiveSubscriptionDetails from './ActiveSubscriptionDetails';
import EdgeAIManageNode from './EdgeAIManageNode';

class Subscriptions extends React.PureComponent {
  render() {
    return (
      <>
     
      <Switch>
        <Route
          exact={true}
          path={SubscriptionPaths.PendingSubscriptionDetails}
          component={PendingSubscriptionDetails}
        />
        <Route
          exact={true}
          path={SubscriptionPaths.ActiveSubscriptionDetails}
          component={ActiveSubscriptionDetails}
        />
        <Route
          exact={true}
          path={Paths.Subscriptions}
          component={SubscriptionLists}
        />
        <Route
          exact={true}
          path={SubscriptionPaths.manageEdgeAINode}
          component={EdgeAIManageNode}
        />
      </Switch>
      <Route
          exact={true}
          path={SubscriptionPaths.approverForAlert}
          component={ActiveSubscriptionDetails}
        />
      </>
    );
  }
}
export default Subscriptions;
