import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { USER_ROLES } from '../../../../utils/constants';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';

const CustomAnalytics = ({ 
    t,
    data,
    handleCreateURLClick
}) => {
    let role = localStorage.getItem("role");
    return (
        <>
            {role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) ?
                <Grid style={{ display: 'flex', alignItems: 'right' }} style={{...marginGenerator('mb-20'), float: 'right'}} >
                    <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() =>
                        handleCreateURLClick('analytics')
                    }>
                        { data.analyticsUrl ? t('edit.label') : t('add.label')} {t('url.label')}
                    </Button>
                </Grid>
            : null }
            <Grid>
                {data.analyticsUrl ? 
                    <iframe width="100%" height={window.innerHeight} src={data.analyticsUrl} frameBorder="0" style={{border:0,}} allowFullScreen={true}></iframe>
                    :  <Typography variant={'subtitle2'}>{t('dataNotFound.label')}</Typography>
                }
            </Grid>
        </>
    );
};

CustomAnalytics.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default CustomAnalytics;