import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { marginGenerator } from '../../../../../../theme/utils';
import MorupuleLogo from '../../../../../../assets/icons/mcmlogo.png';

const useStyles = makeStyles(theme => ({
    left: {
        float: 'left',
    },
    right:{
        float: 'right',
        border: '0.7px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
    blackBorder: {
        border: '1px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
}));

function ExpenseHeader({ t }) {

    const classes = useStyles();

    return (
            <Grid container style={{ padding: '0 20px' }}>
                <Grid item xs={12} >
                    <div className={classes.main}>
                            <div className={classes.left}>
                                <img style={{width: '190px', height: 'auto',paddingLeft:'80px'}} src={MorupuleLogo} alt="Morupule" />
                            </div>
                            
                            <Grid container spacing={12} item xs={12} style={{paddingBottom:'40px',paddingLeft:'90px',paddingRight:'90px'}}>
                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>RECORD NO: FINA/ACC/PR/002 & 004/FORM/03</p>                       
                                        </Grid>
                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>PAGE 1 OF 1</p>                       
                                        </Grid>
                                
                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>EFFECTIVE DATE: DECEMEBER 2009</p>                   
                                        </Grid>
                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>FIRST ISSUE DATE: OCTOBER 1999</p>                       
                                        </Grid>

                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>REVISED DATE: APRIL 2021</p>                       
                                        </Grid>
                                        <Grid className={classes.right} item xs={6}>
                                        <p style={{color: 'black', fontSize:'10px',marginLeft: '5px'}}>REVISION NO: 7</p>                       
                                        </Grid>
                            </Grid>    
                    </div>
                </Grid>
            </Grid>
    );
}

export default withTranslation()(ExpenseHeader);
