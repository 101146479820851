import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Message from '../../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import SesiroHeader1 from '../../../SesiroHeader1';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    blackBorder: {
        border: '0.5px solid black',
        outline: '0.5px solid',
        padding: '0px'
    },
    blueBorder: {
        border: "5px solid ",
        borderRight: "5px solid #06373e",
        borderTop: "5px solid #2e8a88",
        borderBottom: "5px solid #2e8a88",
        borderLeft: "5px solid #47cfc7",
        width: '350px',
        padding: '50px',
        borderRadius: '12px'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px',
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    subscriptionId: "",
    policyNo: "",
    VATRegNo: "",
    insured_name: "",
    insured_occupation: "",
    insured_address: "",
    insured_tellNo: "",
    occurrence_dateTime: "",
    occurrence_causeBreakage: "",
    occurrence_address: "",
    occurrence_nameAddressBreakagePerson: "",
    occurrence_nameAddressWitnesses: "",
    premises_address: "",
    premises_isOccupied: "",
    premises_isOccupiedWhom: "",
    premises_occupiedPurpose: "",
    vehicle_make: "",
    vehicle_regNo: "",
    vehicle_model: "",
    vehicle_year: "",
    vehicle_windscreen: "",
    vehicle_driverName: "",
    vehicle_licenseNo: "",
    vehicle_placeIssued: "",
    vehicle_dateIssued: "",
    brokenGlassDetail_description: "",
    brokenGlassDetail_size: "",
    brokenGlassDetail_crackedORShattered: "",
    brokenGlassDetail_signWriting: "",
    value_totalValue: "",
    value_lastDate: "",
    otherInsurance_isCovering: "",
    otherInsurance_insurerName: "",
    declaration_capacity: "",
    declaration_lastValue: "",
};

const GlassPanelForm = ({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal
}) => {
    const classes = useStyles();
    const [GPFormState, setGPFormState] = useState({ ...urInitFields });

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setGPFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setGPFormState({...data, id});
        }
    }, [data]);

    const handleChange = (value, name) => {
        // if (name === "allBenefits") {
        //     setURFormState((prevState) => {
        //         const newState = { ...prevState };
        //         if (value.target.checked) {
        //             newState.allBenefits.push(value.target.value);
        //         } else {
        //             let index = newState.allBenefits.indexOf(value.target.value);
        //             if (index !== -1) {
        //                 newState.allBenefits.splice(index, 1);
        //             }
        //         }
        //         return newState;
        //     });
        // }else if(name === 'allowances'){
        //     setURFormState((prevState) => {
        //         const newState = { ...prevState };
        //         if (value.target.checked) {
        //             newState.allowances.push(value.target.value);
        //         } else {
        //             let index = newState.allowances.indexOf(value.target.value);
        //             if (index !== -1) {
        //                 newState.allowances.splice(index, 1);
        //             }
        //         }
        //         return newState;
        //     });
        // } else if(name === 'recClub' || name === 'PMAregistraion' || name === 'gym' || name === 'pensionFormsRegistraion') {
        //     setURFormState((prevState) => {
        //         const newState = { ...prevState };
        //         if (value.target.checked) {
        //             debugger;
        //             newState[name] = value.target.value;
        //         } else {
        //             newState[name] = '';
        //         }
        //         return newState;
        //     });
        // } else {
        // }
        setGPFormState({ ...GPFormState, [name]: value });
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="sensor-template-title">
                <SesiroHeader1/>
            </DialogTitle>
            <DialogContent>

            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '900', fontFamily: 'sans-serif'}}>Policy Number (*)</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.policyNo}
                        onChange={(evt) => handleChange(evt.target.value, "policyNo")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>VAT Registration Number (*)</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.VATRegNo}
                        onChange={(evt) => handleChange(evt.target.value, "VATRegNo")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>INSURED</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Name (*)</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.insured_name}
                        onChange={(evt) => handleChange(evt.target.value, "insured_name")}
                    />
                </Grid>

                    <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                        <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Occupation (*)</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.insured_occupation}
                        onChange={(evt) => handleChange(evt.target.value, "insured_occupation")}
                    />
                    </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Address</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.insured_address}
                        onChange={(evt) => handleChange(evt.target.value, "insured_address")}
                    />
                    </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>(Day) Tel. no.</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.insured_tellNo}
                        onChange={(evt) => handleChange(evt.target.value, "insured_tellNo")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>OCCURRENCE</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Date & Time of breakage</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <DateTimeSelector
                        inputStyle={{ minWidth: 400 }}
                        dateFormat={"yyyy-MM-dd"}
                        value={GPFormState.occurrence_dateTime}
                        handleChange={newDate => handleChange(newDate, "occurrence_dateTime")}
                    />
                </Grid>

                    <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                        <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Cause of breakage</h5>
                    </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.occurrence_causeBreakage}
                        onChange={(evt) => handleChange(evt.target.value, "occurrence_causeBreakage")}
                    />
                    </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'sans-serif'}}>Address</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.occurrence_address}
                        onChange={(evt) => handleChange(evt.target.value, "occurrence_address")}
                    />
                    </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Name and address of person responsible for breakage</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <TextField
                        type="textarea"
                        variant="standard"
                        fullWidth={true}
                        multiline
                        rows={2}
                        rowsMax={2}
                        value={GPFormState.occurrence_nameAddressBreakagePerson}
                        onChange={(evt) => handleChange(evt.target.value, "occurrence_nameAddressBreakagePerson")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Name and address of witnesses</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <TextField
                        type="textarea"
                        variant="standard"
                        fullWidth={true}
                        multiline
                        rows={2}
                        rowsMax={2}
                        value={GPFormState.occurrence_nameAddressWitnesses}
                        onChange={(evt) => handleChange(evt.target.value, "occurrence_nameAddressWitnesses")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>PREMISES</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Address of premises where breakage occurred</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <TextField
                        type="textarea"
                        variant="standard"
                        fullWidth={true}
                        multiline
                        rows={2}
                        rowsMax={2}
                        value={GPFormState.premises_address}
                        onChange={(evt) => handleChange(evt.target.value, "premises_address")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Were premises occupied?</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <RadioGroup
                        row
                        aria-label="change_classification"
                        name="change_classification"
                        value={GPFormState.premises_isOccupied}
                        onChange={(evt) => handleChange(evt.target.value, "premises_isOccupied")}
                    >
                        <FormControlLabel
                            style={{ ...marginGenerator('mb-8') }}
                            value="No"
                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                            labelPlacement="start"
                            label="No"
                        />
                        <FormControlLabel
                            style={{ ...marginGenerator('mb-8') }}
                            value="Yes"
                            labelPlacement="start"
                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                            label="Yes"
                        />
                    </RadioGroup>
                    <hr style={{ width: "101.5%"}}></hr>
                    <Input
                        type={'text'}
                        placeholder={"if Yes, By whom?"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.premises_isOccupiedWhom}
                        onChange={(evt) => handleChange(evt.target.value, "premises_isOccupiedWhom")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Purpose for which occupied</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.premises_occupiedPurpose}
                        onChange={(evt) => handleChange(evt.target.value, "premises_occupiedPurpose")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>VEHICLE</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Vehicle make</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.vehicle_make}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_make")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Registration no.</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={GPFormState.vehicle_regNo}
                    onChange={(evt) => handleChange(evt.target.value, "vehicle_regNo")}
                />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Model</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                <Input
                    type={'text'}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={GPFormState.vehicle_model}
                    onChange={(evt) => handleChange(evt.target.value, "vehicle_model")}
                />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Year</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.vehicle_year}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_year")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Windscreen tinted or clear and shatterproof or amour plate?</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <TextField
                        type="textarea"
                        variant="standard"
                        fullWidth={true}
                        multiline
                        rows={2}
                        rowsMax={2}
                        value={GPFormState.vehicle_windscreen}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_windscreen")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Driver's name</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.vehicle_driverName}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_driverName")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>License no.</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.vehicle_licenseNo}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_licenseNo")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Place Issued</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.vehicle_placeIssued}
                        onChange={(evt) => handleChange(evt.target.value, "vehicle_placeIssued")}
                    />
                </Grid>
                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Date Issued</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <DateTimeSelector
                        inputStyle={{ minWidth: 460 }}
                        dateFormat={"yyyy-MM-dd"}
                        value={GPFormState.vehicle_dateIssued}
                        handleChange={newDate => handleChange(newDate, "vehicle_dateIssued")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>DETAILS OF BROKEN GLASS</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Full description of broken glass</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.brokenGlassDetail_description}
                        onChange={(evt) => handleChange(evt.target.value, "brokenGlassDetail_description")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Size and thickness in millimetres</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.brokenGlassDetail_size}
                        onChange={(evt) => handleChange(evt.target.value, "brokenGlassDetail_size")}
                    />
                </Grid>
                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                        <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Cracked or shattered?</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.brokenGlassDetail_crackedORShattered}
                        onChange={(evt) => handleChange(evt.target.value, "brokenGlassDetail_crackedORShattered")}
                    />
                </Grid>
                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Any signwriting or broken glass?</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.brokenGlassDetail_signWriting}
                        onChange={(evt) => handleChange(evt.target.value, "brokenGlassDetail_signWriting")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>VALUE</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Total value of all insured glass BWP:</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <Input
                        type={'text'}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.value_totalValue}
                        onChange={(evt) => handleChange(evt.target.value, "value_totalValue")}
                    />
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>When last valued?</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={7}>
                    <DateTimeSelector
                        inputStyle={{ minWidth: 460 }}
                        dateFormat={"yyyy-MM-dd"}
                        value={GPFormState.value_lastDate}
                        handleChange={newDate => handleChange(newDate, "value_lastDate")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom:30 }} >
                <Grid item xs={12}>
                <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>OTHER INSURANCE</Typography>
                </Grid>

                <Grid className={classes.blackBorder} item xs={4} style={{ backgroundColor: "#6cccf560"}}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px',fontWeight: '500', fontFamily: 'sans-serif'}}>Is there any other insurance covering the broken glass?</h5>
                </Grid>
                <Grid item xs={7} className={classes.blackBorder}>
                    <RadioGroup
                        row
                        aria-label="change_classification"
                        name="change_classification"
                        value={GPFormState.otherInsurance_isCovering}
                        onChange={(evt) => handleChange(evt.target.value, "otherInsurance_isCovering")}
                    >
                        <FormControlLabel
                            style={{ ...marginGenerator('mb-8') }}
                            value="No"
                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                            labelPlacement="start"
                            label="No"
                        />
                        <FormControlLabel
                            style={{ ...marginGenerator('mb-8') }}
                            value="Yes"
                            labelPlacement="start"
                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                            label="Yes"
                        />
                    </RadioGroup>
                    <hr style={{ width: "101.5%"}}></hr>

                    <Input
                        type={'text'}
                        placeholder={"If so, give name of insurer"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.otherInsurance_insurerName}
                        onChange={(evt) => handleChange(evt.target.value, "otherInsurance_insurerName")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontFamily: 'sans-serif'}}>DECLARATION</Typography>
                    <p style={{fontSize:'14px', fontfamily:'sans-serif'}}>I/We solemnly declare that the above particulars are true and complete in every respect. <br/></p>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <IconButton
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("InsuredSignature")}
                        >
                        Insured’s Signature <img src={SignIcon} width="26" height={26} alt=""/>
                    </IconButton>
                </Grid>
                <Grid item xs={3}>
                    <Input
                        type={'text'}
                        placeholder={"Capacity"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={GPFormState.declaration_capacity}
                        onChange={(evt) => handleChange(evt.target.value, "declaration_capacity")}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                    <DateTimeSelector
                        inputStyle={{ minWidth: 260 }}
                        label={'When last valued?'}
                        dateFormat={"yyyy-MM-dd"}
                        value={GPFormState.declaration_lastValue}
                        handleChange={newDate => handleChange(newDate, "declaration_lastValue")}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() => {
                        if( !GPFormState.policyNo || 
                            !GPFormState.VATRegNo || 
                            !GPFormState.insured_name ||
                            !GPFormState.insured_occupation ){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateRequestSubmit(GPFormState);
                    }}
                    loading={modalState.btnLoading}
                />
            </Grid>
        </DialogActions>
    </Dialog>
    )
}

GlassPanelForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default GlassPanelForm
