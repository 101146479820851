import "date-fns";
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BarChartWrapper from '../../../components/Charts/BarChartWrapper';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TableHead } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { convertPriceFormat } from "../../../utils";
import DownloadPDFButton from '../BillingUtilization/DownloadPDFButton';
import DownloadNewPDFButton from '../BillingUtilization/DownloadNewPDFButton';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 40
    },
    loader: {
        width: "100%",
        textAlign: "center"
    },
    yearMonthFilter: {
        padding: 10
    }
}));

function getInvoiceDueDate(date) {
    if (!date) return null;
    let yearAndMonth = date.split("/");
    if (yearAndMonth.length > 1) {
        let lastDate = new Date(yearAndMonth[1], parseInt(yearAndMonth[0]) > 9 ? yearAndMonth[0] : '0' + yearAndMonth[0], 0);
        let color = (new Date() < lastDate) ? "green" : "red";
        let dueDate = lastDate.toLocaleString().split(", ").length > 0 ? lastDate.toLocaleString().split(", ")[0] : lastDate.toLocaleString();
        return <Typography variant="subtitle1" style={{ color, fontWeight: "bold" }}>{dueDate}</Typography>
    } else {
        return null;
    }
}

const Invoice = ({ t, loadingData, invoiceChartData, invoiceReportList, handleChangeDateFilter, startDate, userInfo, invoiceCompanyDetails, invoiceSageX3List, loadingInvoiceSageX3Data, handleSageInvoiceTableNextClick }) => {

    const classes = useStyles();
    const [invoiceDataList, setInvoiceDataList] = useState([]);
    let columns = [
        {
            label: t('sNo.label'),
            key: "sNo"
        },
        {
            label: t('date.label'),
            key: "invoiceDate"
        },
        {
            label: t('invoiceNumber.label'),
            key: "invoiceNumber"
        },
        {
            label: t('download.label'),
            key: "download"
        },
        {
            label: t('invoiceAmount.label'),
            key: "invoiceAmount"
        },
        {
            label: t('dueDate.label'),
            key: "invoiceAmount"
        },
        {
            label: `${t('paidInvoice.label')} ${t("yesNo.label")}`,
            key: "status"
        },
    ];

    useEffect(() => {
        let invoiceList = [];
        if (!loadingInvoiceSageX3Data && invoiceReportList.length > 0) {
            for (let invoiceData of invoiceReportList) {
                let addonsState = [];
                let subscriptionState = [];
                let invoiceBillData = { totalPayableAmount: invoiceData.totalPayableAmount, invoiceNumber: invoiceData.invoiceNumber, invoiceDate: invoiceData.invoiceDate };
                let totalState = { taxes: invoiceData.taxAmount, totalCost: invoiceData.totalCost, totalDiscount: invoiceData.totalDiscount, totalPayableAmt: invoiceData.totalPayableAmount ? invoiceData.totalPayableAmount : 0 };
                if (invoiceData.invoiceDate) {
                    let yearAndMonth = invoiceData.invoiceDate.split("/");
                    if (yearAndMonth.length > 1) {
                        let lastDate = new Date(yearAndMonth[1], parseInt(yearAndMonth[0]) > 9 ? yearAndMonth[0] : '0' + yearAndMonth[0], 0);
                        let firstDay = new Date(lastDate.getFullYear(), lastDate.getMonth(), 1);
                        let lastDay = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);
                        invoiceBillData = { ...invoiceBillData, startDate: firstDay, endDate: lastDay }
                    }
                }
                if (invoiceData.utilizations && invoiceData.utilizations.length > 0) {
                    invoiceData.utilizations.map(item => {
                        if (item.type === "subscription") {
                            subscriptionState.push({ name: item.moduleName, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        } else if (item.type === "addons") {
                            addonsState.push({ moduleName: item.moduleName, number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        }
                    });
                }
                invoiceList.push({ ...invoiceData, downloadBtn: <DownloadPDFButton invoiceReport={true} t={t} invoiceId={invoiceData._id} invoiceData={{ ...invoiceBillData, subscriptionState, addonsState, totalState, currency: invoiceData.currency }} userInfo={userInfo ? userInfo : null} invoiceCompanyDetails={invoiceCompanyDetails} /> });
            }
        }
        setInvoiceDataList(invoiceList);
    }, [invoiceReportList, invoiceSageX3List, loadingInvoiceSageX3Data]);

    return (
        <div className={classes.root}>
            <Grid container style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary" style={{ marginBottom: 15 }}>{t("invoiceReportHead.label")}</Typography>
                    <BarChartWrapper
                        data={invoiceChartData && invoiceChartData.length ? invoiceChartData : []}
                        dataKeyChart={'totalPayableAmount'}
                        dataKeyXAxis={'invoiceDate'}
                    />
                </Grid>
            </Grid>
            {/* <Grid container > */}
            <Grid style={{ display: 'flex', alignItems: 'right', marginBottom: 20, float: 'right' }} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        variant="inline"
                        openTo="month"
                        views={["month", "year"]}
                        value={startDate}
                        maxDate={new Date()}
                        placeholder={t("filterMothYear.label")}
                        onChange={evt => {
                            setInvoiceDataList([]);
                            handleChangeDateFilter(evt);
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {/* </Grid> */}
            <Grid container style={{ marginBottom: 24 }} >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loadingInvoiceSageX3Data ?
                                invoiceSageX3List && invoiceSageX3List.$resources && invoiceSageX3List.$resources.length ? invoiceSageX3List.$resources.map((item, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {key + 1}
                                            </TableCell>
                                            <TableCell>
                                                {item.ACCDAT}
                                            </TableCell>
                                            <TableCell>
                                                {item.NUM}
                                            </TableCell>
                                            {/* <TableCell>
                                            {item.currency ? item.currency.symbol : "$"}{convertPriceFormat(item.totalPayableAmount.toFixed(2))}
                                        </TableCell> */}
                                            <TableCell>
                                                {item.$uuid ?
                                                    <DownloadNewPDFButton invoiceReport={true} t={t} invoiceId={item.$uuid} userInfo={userInfo ? userInfo : null} invoiceCompanyDetails={item} />
                                                    : <Button disabled={true} variant="outlined" size="small" color="secondary">{t("download.label")}</Button>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.AMTATI}
                                            </TableCell>
                                            <TableCell>
                                                {item.DUDDAT}
                                            </TableCell>
                                            <TableCell>
                                                {item.PAYCUR == (item.QTY * item.NETPRI * 1.075).toFixed(2) ? 'Yes' : 'No'}
                                                {/* {item.status === "Paid" ? "Yes" : "No"} */}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            {loadingInvoiceSageX3Data &&
                                <TableRow>
                                    <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                                        <CircularProgress color="primary" />
                                    </TableCell>
                                </TableRow>
                            }
                            {!loadingInvoiceSageX3Data ?
                                invoiceSageX3List && invoiceSageX3List.$links && invoiceSageX3List.$links.$first && invoiceSageX3List.$links.$first.$url ? <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: '5px', marginTop: '15px' }}
                                    onClick={() => {
                                        handleSageInvoiceTableNextClick(invoiceSageX3List.$links.$first.$url);
                                    }}
                                >{t("first.label")}
                                </Button>
                                    : '' : ''}
                            {!loadingInvoiceSageX3Data ?
                                invoiceSageX3List && invoiceSageX3List.$links && invoiceSageX3List.$links.$previous && invoiceSageX3List.$links.$previous.$url ? <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: '5px', marginTop: '15px' }}
                                    onClick={() => {
                                        handleSageInvoiceTableNextClick(invoiceSageX3List.$links.$previous.$url);
                                    }}
                                >{t("previous.label")}
                                </Button>
                                    : '' : ''}
                            {!loadingInvoiceSageX3Data ?
                                invoiceSageX3List && invoiceSageX3List.$links && invoiceSageX3List.$links.$next && invoiceSageX3List.$links.$next.$url ? <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: '5px', marginTop: '15px' }}
                                    onClick={() => {
                                        handleSageInvoiceTableNextClick(invoiceSageX3List.$links.$next.$url);
                                    }}
                                >{t("next.label")}
                                </Button>
                                    : '' : ''}
                            {!loadingInvoiceSageX3Data ?
                                invoiceSageX3List && invoiceSageX3List.$links && invoiceSageX3List.$links.$last && invoiceSageX3List.$links.$last.$url ? <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: '5px', marginTop: '15px' }}
                                    onClick={() => {
                                        handleSageInvoiceTableNextClick(invoiceSageX3List.$links.$last.$url);
                                    }}
                                >{t("last.label")}
                                </Button>
                                    : '' : ''}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
};
export default Invoice;