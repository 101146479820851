/**
 * All route paths in this file
 */
const Paths = {
  Dashboard: '/dashboard',
  Subscriptions: '/subscriptions',
  ServiceTickets: '/serviceTickets',
  Support: '/support',
  NewSubscription: '/newSubscription',
  CreateAccount: '/createAccount',
  Profile: '/profile',
  Settings: '/settings',
  LoginInProgress: '/inProgress',
  ForgotPassword: '/forgotPassword',
  ResetPassword: '/resetPassword',
  ChangePassword: '/changePassword',
  ActivityLogs: '/activityLogs',
  LineCrosingImage: '/lineCrosingImage',
  MultiLineCrosingImage: '/multiLineCrosingImage',
  VnfMarketplace: '/vnfmarketplace',
  VnfMarketplaceDetails: '/vnfmarket/details/:id',
  TicketSystem: '/ticketing-system',
  GetRoiAxis: '/getRoiAxis',
  GetMultiRoiAxis: '/getMultipleRoiAxis',
  DirectionalLineCross: '/directionalLineCross',
  VolumeLineCrossImage: '/volumeLineCrossImage',
  OtherServices: '/otherServices',
  FabricServices: '/fabricService',
  GetPolygons: '/getPolygons',
  CM: '/change-management',
};

const ServiceManager = {
  Dashboard: '/sm/dashboard',
  Users: '/sm/users',
  Customers: '/sm/customers',
  Add: '/sm/ad',
  Subscription: `/sm/subscriptions/:id`,
  ConfigureSites: '/sm/configure',
  Vendors: '/sm/vendors',
  IncidentType: '/sm/incidenttype',
  IncidentTypeGroup: '/sm/incidentgroup',
  AdditionalAgent: '/sm/addtionalAgent',
  NGList: '/sm/nglist'
};
const TicketingSystem = {
  Details: `${Paths.TicketSystem}/details/:id`,
  Dashboard: '/ticket-dashboard',
  TicketPortal: '/ticketing-system',
  NewServiceTicket: `${Paths.TicketSystem}/newTicket/`,
  InternalTicket: `${Paths.TicketSystem}/internalTicket/`,
  NewServiceTicketId: `${Paths.TicketSystem}/newTicket/:id`,
  Update: `${Paths.TicketSystem}/update/:id`,
  Subscription: `/subscription/:id`,
  SubscriptionSite: `/subscription-site/:id`,
  KnowladgeBase: `/knowladgebase`,
  KnowladgeBaseDetail: `/knowladgebase/details/:id`,
  Rating: `${Paths.TicketSystem}/rating`,
  Customers: '/customers',
  CustomersDetails: '/customers/detail/:id',
  Upload: '/upload',
  Analytics: `/analytics`,
  History: '/circuit-history',
  CM: '/change-management',
  CMDetails: `${Paths.CM}/details/:id`,
  ChangeRequest: '/change-management/newRequest',
  MttrAnalytics: '/mttrAnalytics',
  CyberSecurityForm: '/cyberSecurity/incidentForm',
  CyberSecurity: '/cyberSecurity',
  CSDetails: '/cyberSecurity/details/:id'
};
const ServiceTicketPaths = {
  Details: `${Paths.ServiceTickets}/details/:id`,
  NewServiceTicket: `${Paths.ServiceTickets}/newTicket/`
};
const SubscriptionPaths = {
  PendingSubscriptionDetails: `${Paths.Subscriptions}/pending/details/:id`,
  ActiveSubscriptionDetails: `${Paths.Subscriptions}/active/details/:id`,
  manageEdgeAINode: `${Paths.Subscriptions}/nodeManagement/:id/:nodeId`,
  approverForAlert: `${Paths.Subscriptions}/active/details/:id?alertId=:id`
};


const NewSubscriptionPaths = {
  ConfigureSites: `${Paths.NewSubscription}/configure`,
  ReviewSites: `${Paths.NewSubscription}/ReviewSites/:id`
};

const CreateAccountPaths = {
  CreateOrg: `${Paths.CreateAccount}/organization`,
  CreateUser: `${Paths.CreateAccount}/user`
};
const InternalPaths = {
  Dashboard: '/i/dashboard',
  organisations: '/i/organisations',
  users: '/i/users',
  customers: '/i/customers',
  createNewUser: '/i/createNewUser',
  countries: '/i/countries',
  vnfMarketPlace: '/i/vnfMarketPlace',
  servicesAndProducts: '/i/servicesAndProducts',
  pmoDashboard: '/i/pmoDashboard',
  biDashboard: '/i/biDashboard',
  workflowManagement: '/i/workflowManagement',
};
const OrganisationPaths = {
  details: `${InternalPaths.organisations}/details/:id`
};
const InternalUser = {
  Dashboard: '/cp/dashboard',
  Events: '/cp/events'
}

const fabricServicePaths = {

  Ports: `${Paths.FabricServices}/Services`,
  Connections: `${Paths.FabricServices}/Connections`,
  FCR: `${Paths.FabricServices}/FCR`,
  CreatePort: `${Paths.FabricServices}/CreatePort,`,
  CreateConnection: `${Paths.FabricServices}/CreateConnection`,
  CreateFcr: `${Paths.FabricServices}/CreateFcr`
}
export {
  Paths,
  ServiceTicketPaths,
  SubscriptionPaths,
  NewSubscriptionPaths,
  CreateAccountPaths,
  InternalPaths,
  TicketingSystem,
  OrganisationPaths,
  ServiceManager,
  InternalUser,
  fabricServicePaths
};
