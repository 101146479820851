import { gql } from 'apollo-boost';

export const CREATE_BID = gql`
    mutation Bid($file: Upload!, $name: String, $closingDate: Date) {
        addBid(name: $name, file: $file, closingDate: $closingDate) {
            id
        }
    }
`;

export const CREATE_QUOTE = gql`
    mutation Quote($bidId: String, $vendorName: String, $name: String) {
        addQuote(bidId: $bidId, vendorName: $vendorName, name: $name) {
            id
            name
        }
    }
`;

export const UPLOAD_QUOTE_DOCS = gql`
    mutation QuoteDocs($file: Upload!, $type: String, $quoteId: String, $name: String) {
        uploadQuoteDocs(file: $file, type: $type, quoteId: $quoteId, name: $name) {
            url
        }
    }   
`;