import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Popup from '../../Popup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { createIpsec, generateHexCode } from '../../../../services/fcrService';
import IPut from 'iput';
import Message from '../../../../components/Message';

const validationSchema = yup.object().shape({
  clientSiteName: yup.string().required('Required'),
  ipAddressLocalGateway: yup
    .string()
    .required('Required')
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'Enter a valid IP Address'
    ),
  rateLimit: yup
    .number()
    .typeError('Enter a value between 0 & 1000')
    .min(0, 'Enter a value between 0 & 1000')
    .max(1000, 'Enter a value between 0 & 1000')
    .required('This field is required')
});

const AddIpSecPopup = ({ addIpSec, setAddIpSec, fcrId, fcrMyIp, classes }) => {
  const [hexCode, setHexCode] = useState('');
  const [ipAddressClientSite, setIpAddressClientSite] = useState('...');
  const [localSubnet, setLocalSubnet] = useState('...');
  const [remoteSubnet, setRemoteSubnet] = useState('...');

  const initialValues = {
    rateLimit: '',
    clientSiteName: '',
    ipAddressLocalGateway: '',
    ipAddressClientSite: '',
    localSubnet: '',
    remoteSubnet: '',
    sharedSecert: ''
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      clientSiteName: data.clientSiteName,
      fcr_id: fcrId,
      ipAddressOfLocalGateway: data.ipAddressLocalGateway,
      ipAddressOfClientSite: ipAddressClientSite,
      localSubnet: localSubnet + '/32',
      remoteSubnet: remoteSubnet + '/32',
      sharedSecert: hexCode,
      speed: `${data.rateLimit} Mbps`
    };
    const res = await createIpsec(payload);
    if (res?.data?.statusCode === 200) {
      Message.success('Added Succesfully');
      reset();
      setAddIpSec(false);
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else {
        Message.error('Something went wrong');
      }
      reset();
      setAddIpSec(false);
    }
  };
  const GenerateHexCode = async () => {
    const res = await generateHexCode();
    setHexCode(res?.data?.result);
    setValue('sharedSecert', res?.data?.result);
    setValue('ipAddressLocalGateway', fcrMyIp);
  };

  useEffect(() => {
    GenerateHexCode();
  }, []);

  return (
    <>
      <Popup title="Add Ipsec" openPopup={addIpSec} setOpenPopup={setAddIpSec}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container direction="column" spacing={3}>
              <Grid item container direction="column" spacing={2}>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Client Site Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="clientSiteName"
                      label="Client Site Name"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Rate Limit
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="rateLimit"
                      label="Rate Limit (Mbps)"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Local Gateway IP
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="ipAddressLocalGateway"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Client Site IP
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <IPut
                      defaultValue={ipAddressClientSite}
                      onChange={val => {
                        setIpAddressClientSite(val);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Shared Secret
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7} justify="center" alignItems="center">
                    <RHFTextField
                      name="sharedSecert"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Local Subnet
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item container sm={7} alignItems="center">
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item>
                        <IPut
                          defaultValue={localSubnet}
                          onChange={val => {
                            setLocalSubnet(val);
                          }}
                        />
                      </Grid>
                      <Grid item>/32</Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={4}>
                    Remote Subnet
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item container sm={7} alignItems="center">
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item>
                        <IPut
                          defaultValue={remoteSubnet}
                          onChange={val => {
                            setRemoteSubnet(val);
                          }}
                        />
                      </Grid>
                      <Grid item>/32</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setAddIpSec(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default AddIpSecPopup;
