import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
// import { USER_ROLES } from "../../../../../utils/constants";
import { useEffect } from 'react';
import { dateFormatterLLL } from '../../../../../utils';

const BaseSationItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName
}) => {
    const commonClasses = commonStyles();
    // const role = localStorage.getItem('role');
    useEffect(() => { }, [CurrentUserName])
    return (
        
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.client_name ? data.client_name : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                        {data.ip_address ? data.ip_address : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.updown_since ? data.updown_since : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
           
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.link_status ? data.link_status : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.packet_loss ? data.packet_loss : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.basestation_type ? data.basestation_type : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.shelter_provision ? data.shelter_provision : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.state ? data.state : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.lga ? data.lga : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.latitude ? data.latitude : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.longitude ? data.longitude : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.downlink_mbps ? data.downlink_mbps : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.uplink_mbps ? data.uplink_mbps : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {/* <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.product_class ? data.product_class : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.billing_date ? data.billing_date : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.node ? data.node : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.connected_did ? data.connected_did : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>

            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.active_did ? data.active_did : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell> */}
            {/* <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell> */}
            {/* } */}
        </TableRow>
    );
};
BaseSationItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(BaseSationItem);