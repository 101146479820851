import { gql } from 'apollo-boost';

/*
  Get Country List
 */
export const GET_COUNTRY_LIST = gql`
  query Country {
    getCountries {
      id
      name
    }
  }
`;

/*
  Get Region List
 */
export const GET_REGION_LIST = gql`
  query Region($countryId: String!) {
    getRegionsByCountryId(countryId: $countryId) {
      id
      name
      code
    }
  }
`;

/*
  Get Country Codes
 */
export const GET_COUNTRY_CODE_LIST = gql`
  query CountryCode {
    getCoutriesCode {
      id
      name
      flag
      dial_code
    }
  }
`;

/*
  User Login
 */
export const LOGIN = gql`
  query Login($username: String!, $password: String!) {
    userLogin(username: $username, password: $password) {
      accessToken
      refreshToken
      expiresIn
      success
      msg
      organisations
    }
  }
`;

export const CHECK_GSUIT_USER = gql`
  query CheckGsuitUser($email: String, $googleId: String) {
    checkGsuitUserExist(email: $email, googleId: $googleId) {
      googleId
    }
  }
`;

/*
  User Logout
 */
export const LOGOUT = gql`
  query Logout($refreshToken: String!) {
    userLogout(refreshToken: $refreshToken)
  }
`;

/*
  User Organization Info
 */
export const USER_ORG_INFO = gql`
  query UserOrgInfo($orgId: String, $action: String) {
    userOrganizationInfo(orgId: $orgId, action: $action) {
      id
      name
      countryId
      country
      regionId
      region
      dns
      logo
      type
      userCountryId
      userCountryName
      subscriptionId
    }
  }
`;
/*
  get all roles
 */
export const GET_ALL_ROLES = gql`
  query GetAllRoles{
    getAllRoles{
      RoleData
    }
  }
`;
/*
  get all user by roles
 */
export const GET_ALL_USER_BY_ROLES = gql`
  query getAllUserByRoles($role: String){
    getAllUserByRoles(role: $role){
      RoleData
    }
  }
`;

export const GET_ORGANISATION_DNS_LOGO = gql`
  query OrganisationDnsLogo($dnsUrl: String){
    getOrganisationDnsLogo(dnsUrl : $dnsUrl){
      dnsUrl
      imageUrl
    }
  }
`;

export const GET_BIRTHDAY_LIST = gql`
  query BirthdayList{
    getBirthdayList{
      month
      day
      firstName
      lastName
    }
  }
`;

export const GET_EVENTS_LIST = gql`
  query AllEvents{
    getAllEvents{
      id
      name
      date
      expiryDate
    }
  }
`;

export const GET_ALL_USER_BY_ONLY_COUNTRY_ID_LIST = gql`
  query getAllUserByOnlyCountyID{
    getAllUserByOnlyCountyID {
    id
    firstName
    lastName
    email
    attributes
    organisations
    basestation
    role
    location
    vendor
    configured_DN_CIR
    configured_UP_CIR
    platform
    blocked
    active
    status
  }
}
`;