import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";

const DateTimeSelector = ({
    styleOverrides,
    handleChange,
    value,
    className,
    disableFuture,
    disablePast,
    minDate,
    dateFormat,
    label,
    inputStyle,
    disabled,
    inputValue
}) => {

    let Picker = null;
    if (dateFormat === "yyyy-MM-dd") {
        Picker = (<DatePicker
            clearable
            ampm={true}
            value={value}
            style={styleOverrides || null}
            onChange={handleChange}
            format={dateFormat}
            disableFuture={disableFuture ? disableFuture : false}
            disablePast={disablePast ? disablePast : false}
            className={className}
            minDate={minDate ? minDate : false}
            placeholder={label}
            InputProps={{
                style: inputStyle ? inputStyle : null,
                ...inputValue && { value: inputValue }
            }}
            disabled={disabled ? disabled : false}
        />
        );
    } else if (dateFormat === "HH:mm") {
        Picker = (<TimePicker
            clearable
            ampm={true}
            value={value}
            style={styleOverrides || null}
            onChange={handleChange}
            format={dateFormat}
            disableFuture={disableFuture ? disableFuture : false}
            disablePast={disablePast ? disablePast : false}
            className={className}
            minDate={minDate ? minDate : false}
            placeholder={label}
            InputProps={{
                style: inputStyle ? inputStyle : null,
                ...inputValue && { value: inputValue }
            }}
            disabled={disabled ? disabled : false}
        />);

    } else {
        Picker = (<DateTimePicker
            clearable
            ampm={true}
            value={value}
            style={styleOverrides || null}
            onChange={handleChange}
            format={dateFormat}
            disableFuture={disableFuture ? disableFuture : false}
            disablePast={disablePast ? disablePast : false}
            className={className}
            minDate={minDate ? minDate : false}
            placeholder={label}
            InputProps={{
                style: inputStyle ? inputStyle : null
            }}
            disabled={disabled ? disabled : false}
        />);
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {Picker}
        </MuiPickersUtilsProvider>
    );
};

DateTimeSelector.propTypes = {
    handleChange: PropTypes.func.isRequired,
    val: PropTypes.object.isRequired,
};

export default DateTimeSelector;