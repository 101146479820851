import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import RegionTable from './RegionTable';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const RegionDashboard = () => {
  const history = useHistory();
  const { loggedInUserRoles } = useAuth();

  useEffect(() => {
    if (loggedInUserRoles[0] !== 'superadmin') {
      history.push('/fabricService');
    }
  }, [loggedInUserRoles, history]);

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <br />
        <Grid item style={{ marginLeft: '10px' }}>
          {loggedInUserRoles?.includes('fabric_readonly') === false &&
          loggedInUserRoles[0] === 'superadmin' ? (
            <Button
              variant="outlined"
              onClick={() => history.push('/fabricService/create-region')}>
              Create Region
            </Button>
          ) : (
            ''
          )}
        </Grid>
        <Grid item>
          <RegionTable />
        </Grid>
      </Grid>
    </>
  );
};

export default RegionDashboard;
