import React from 'react';
import commonStyles from '../../../../theme/commonStyles';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Input from '@material-ui/core/Input';
import {useState} from 'react';
import AreaChartWrapper from '../../../../components/Charts/AreaChartWrapper';
import {AreaChartData} from '../../../../components/Charts/mockChartData';
import {colors} from '../../../../theme/colors';
import ChartContainer from '../../../../components/Charts/ChartContainer';
import moment from "moment";
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {marginGenerator} from "../../../../theme/utils";

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'}
}));
function UCPEReportsPanel({
                              t,
                              list,
                              filterList,
                              loading,
                              filterLoading
                          }) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const colorChartTheme = (index) => {
        switch (index) {
            case 0:
                return colors.chartColors.graphTopaz;
            case 1:
                return colors.chartColors.graphCarolinaBlue;
            case 2:
                return colors.chartColors.graphDustyOrange;
            default:
                return colors.chartColors.graphTopaz;
        }
    }
    return (
        <div>
            {filterList &&
            filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <Input
                            key={index}
                            value={filter.val}
                            type={'datetime-local'}
                            placeholder={filter.label}
                            style={filter.styleOverrides || null}
                            className={classes.input}
                            color={'secondary'}
                            onChange={filter.handleChange}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent || null}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            {!filterLoading ? list && list.length > 0 && list.map((chart, index) => {
                return <ChartContainer key={index} title={t(chart.title)}>
                    <AreaChartWrapper
                        name={t(chart.title)}
                        data={chart && chart.data.length && chart.data.map(stats => {
                            return {
                                name: moment.utc(parseInt(stats.datetime)).local().format("DD/MM/YYYY HH:MM"),
                                val: stats.val
                            };
                        })}
                        chartColor={colorChartTheme(index)}
                        dataKeyChart={'val'}
                        dataKeyXAxis={'name'}
                    />
                </ChartContainer>
            }) : <Grid container justify={'space-between'}
                       style={marginGenerator('mt-24')}>
                <Typography variant={'subtitle2'}>Loading...<CircularProgress size={14}/></Typography>
            </Grid>}
            {/*<ChartContainer title={t('cpuUtilization.label')}>
                <AreaChartWrapper
                    name={t('cpuUtilization.label')}
                    data={AreaChartData}
                    chartColor={colors.chartColors.graphTopaz}
                    dataKeyChart={'val'}
                    dataKeyXAxis={'name'}
                />
            </ChartContainer>
            <ChartContainer title={t('ramUtilization.label')}>
                <AreaChartWrapper
                    name={t('ramUtilization.label')}
                    data={AreaChartData}
                    chartColor={colors.chartColors.graphCarolinaBlue}
                    dataKeyChart={'val'}
                    dataKeyXAxis={'name'}
                />
            </ChartContainer>
            <ChartContainer title={t('diskUtilization.label')}>
                <AreaChartWrapper
                    name={t('diskUtilization.label')}
                    data={AreaChartData}
                    chartColor={colors.chartColors.graphDustyOrange}
                    dataKeyChart={'val'}
                    dataKeyXAxis={'name'}
                />
            </ChartContainer>*/}
        </div>
    );
}
UCPEReportsPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    filterList: PropTypes.array.isRequired
};
export default UCPEReportsPanel;
