import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { marginGenerator } from '../../../../../../theme/utils';
import MorupuleLogo from '../../../../../../assets/icons/botashlogo.png';

const useStyles = makeStyles(theme => ({
    left: {
        float: 'left',
    },
    right: {
        float: 'right',
        border: '0.7px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
    blackBorder: {
        border: '1px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
}));

function BotashHeader({ t }) {

    const classes = useStyles();

    return (
        <Grid container style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <div className={classes.main}>
                    <div className={classes.left}>

                    </div>

                    <Grid container spacing={12} item xs={12} style={{ paddingBottom: '40px', paddingLeft: '90px', paddingRight: '90px' }}>
                        <Grid className={classes.right} item xs={4}>
                            <img style={{ width: '130px', height: 'auto', marginLeft: '15px' }} src={MorupuleLogo} alt="Morupule" />
                        </Grid>
                        <Grid className={classes.right} item xs={4}>
                            <p style={{ color: 'black', fontSize: '10px', marginLeft: '5px' }}>QUALITY FORMS</p>
                        </Grid>

                        <Grid className={classes.right} item xs={4}>
                            <p style={{ color: 'black', fontSize: '10px', marginLeft: '5px' }}>Page 1 of 2</p>
                        </Grid>
                        <Grid className={classes.right} item xs={4}>
                            <p style={{ color: 'black', fontSize: '10px', marginLeft: '5px' }}>Doc No. 5100/RECD/0002</p>
                        </Grid>

                        <Grid className={classes.right} item xs={4}>
                            <p style={{ color: 'black', fontSize: '10px', marginLeft: '5px' }}>Re No: 01</p>
                        </Grid>
                        <Grid className={classes.right} item xs={4}>
                            <p style={{ color: 'black', fontSize: '10px', marginLeft: '5px' }}>DATE 01.12.2013</p>
                        </Grid>
                        <Grid className={classes.right} item xs={12}>
                            <p style={{ color: 'black', fontSize: '18px', textAlign: 'center' }}>TITLE: ENGAGEMENT CHECKLIST</p>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(BotashHeader);
