import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NotificationPanelItem from '../NotificationPanelItem';
import commonStyles from '../../../../theme/commonStyles';
import {makeStyles} from '@material-ui/core/styles';
import {dateFormatterLLL} from '../../../../utils';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MenuWrapper from '../../../../components/MenuWrapper';
import {useState} from 'react';
import ControllerStatusPanel from '../ControllerStatusPanel';
import {Image} from "@material-ui/icons";
import ImagesItems from "./ImagesItems";
import VNFTemplateItems from "../VNFTemplatePanel/VNFTemplateItems";
import OutlinedSelect from "../../../../components/OutlinedSelect";

function ImagesPanel({
                         t,
                         menuItems,
                         list,
                         handleCreateButtonClick,
                         handleMenuItemClick,
                         handleImageFilter,
                         loading,
                         error
                     }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuCustomItems = [{label: t('delete.label')}];
    const columns = [
        {
            label: t('imageName.label'),
            key: 'imageName'
        },
        {
            label: t('logoImage.label'),
            key: 'imageLogo'
        },
        {
            label: t('imageSize.label'),
            key: 'imageSize'
        },
        {
            label: t('version.label'),
            key: 'version'
        }/*,
        {
            label: t('vnfTemplateReferred.label'),
            key: 'vnfTemplateReferred'
        }*/,
        {
            label: t('status.label'),
            key: 'status'
        },
        {
            label: t('createdDate.label'),
            key: 'createdDate'
        },
        {
            label: t('createdBy.label'),
            key: 'createdBy'
        },
        {
            label:/* t('edit.label') + '/' + */t('delete.label'),
            key: 'button'
        }
    ];
    const filterImages = [
        {
            id: 'all',
            name: 'All Images'
        }, {
            id: 'success',
            name: 'Success Images'
        },
        {
            id: 'other',
            name: 'Failed Images'
        }
    ];
    const useStyles = makeStyles(theme => ({
        cardContainer: {
            width: theme.spacing(30),
            height: theme.spacing(20),
            border: `1px solid ${theme.palette.grey[500]}`,
            marginBottom: theme.spacing(1),
            background: '#fcfcfc',
            borderRadius: '2px',
            cursor: 'pointer'
        },
        textContainer: {
            maxWidth: theme.spacing(20)
        }
    }));
    if (!loading && !error) {
        list = list.getVmImages && list.getVmImages.length ? list.getVmImages : [];
    }
    const classes = useStyles();
    const commonClasses = commonStyles();
    return (
        <div>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), float: 'right', marginLeft: "5px"}}>
                <OutlinedSelect
                    label={`${t('filterByStatus.label')}`}
                    handleChange={evt => {
                        handleImageFilter(evt.target.value);
                    }}
                    selectStyle={{...paddingGenerator('p-8')}}
                    options={
                        filterImages.map(image => {
                            return image;
                        })
                    }
                />
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('images_modal')}>
                    {t('createNew.label')}
                </Button>
                {/*<a href={'/vnfmarketplace'} target="_blank" rel="noopener noreferrer">*/}
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('vnf_maketplace_list_modal')}
                    style={{float: 'right', marginLeft: "5px"}}>
                    {t('vnfMarketplace.label')}
                </Button>
                {/* </a>*/}
            </Grid>
            {/*<Grid container spacing={2} style={marginGenerator('mt-16')}>
        <Grid item>
          <Grid
            className={classes.cardContainer}
            container
            item
            direction={'column'}
            justify={'center'}
            alignItems={'center'}>
             <Grid item>
          <img
            className={commonClasses.iconExtraLarge}
            alt={'Image 1'}
          />
        </Grid>
            <Grid
              item
              style={marginGenerator('mt-16')}
              className={classes.textContainer}>
              <Typography
                align={'center'}
                variant={'body1'}
                style={{ fontWeight: 500 }}>
                {'Image 1'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className={classes.cardContainer}
            container
            item
            direction={'column'}
            justify={'center'}
            alignItems={'center'}>
             <Grid item>
          <img
            className={commonClasses.iconExtraLarge}
            alt={'Image2'}
          />
        </Grid>
            <Grid
              item
              style={marginGenerator('mt-16')}
              className={classes.textContainer}>
              <Typography
                align={'center'}
                variant={'body1'}
                style={{ fontWeight: 500 }}>
                {'Image 2'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className={classes.cardContainer}
            container
            item
            direction={'column'}
            justify={'center'}
            alignItems={'center'}>
             <Grid item>
          <img
            className={commonClasses.iconExtraLarge}
            alt={'Image3'}
          />
        </Grid>
            <Grid
              item
              style={marginGenerator('mt-16')}
              className={classes.textContainer}>
              <Typography
                align={'center'}
                variant={'body1'}
                style={{ fontWeight: 500 }}>
                {'Image 3'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>*/}
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ? (
                                list.length > 0 ? (
                                    list.map((vmImage, rowIndex) => (
                                        <ImagesItems
                                            key={rowIndex}
                                            t={t}
                                            vmImage={vmImage}
                                            menuItems={menuCustomItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
ImagesPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default ImagesPanel;
