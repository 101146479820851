import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../utils';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DNS from '@material-ui/icons/Dns';
import Icon from '@material-ui/core/Icon';

const ActivityLogItem = ({
  t,
  data,
}) => {
  const commonClasses = commonStyles();
  return (
    <TableRow key={data.id} >
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Icon
                    component={() => (
                        <DNS
                        color={'secondary'}
                        style={{ marginTop: '2px', marginRight: '6px' }}
                        />
                    )}
                    alt={'icon'}
                    />
                </Grid>
                <Grid>
                    <Typography variant={'subtitle1'} > 
                        {data.actionType}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > {data.description}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > {data.ipAddress && data.ipAddress.client_ip ? data.ipAddress.client_ip : ""}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > {data.createdByName}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} >
                        {dateFormatterLLL(data.createdAt)}                        
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
    </TableRow>
  );
};

ActivityLogItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withTranslation()(ActivityLogItem);
