import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TicketActive from '../../../../../assets/icons/ticket_active.png';
import commonStyles from '../../../../../theme/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import { dateFormatterLLL } from '../../../../../utils';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  ticketContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    flexGrow: 1,
    cursor: 'pointer'
  },
  closedTitle: {
    color: theme.palette.grey[300]
  },
  updateIndicator: {
    height: '6px',
    width: '6px',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    background: theme.palette.success.main,
    borderRadius: '50%'
  },
  TopBar: {
    display: 'flex'
  }
}));

const TicketItem = ({ t, type, data, handleTicketItemClick }) => {
  const commonClasses = commonStyles();
  const classes = useStyles();

  return (
    <div className={classes.TopBar}>
      <Grid
        container
        style={{ ...paddingGenerator('pt-8'), ...paddingGenerator('pb-24') }}
        className={classes.ticketContainer}
        onClick={() => handleTicketItemClick(data.id)}>
        <Grid item>
          <img
            src={TicketActive}
            alt={'ticket'}
            style={marginGenerator('mt-8')}
            className={commonClasses.iconMedium}
          />
        </Grid>
        <Grid item style={marginGenerator('ml-24')}>
          <Typography variant={'h4'}>
            {data.title}
            {moment(data.updatedAt).isSame(moment(), 'day') && (
              <sup>
                <div className={classes.updateIndicator} />
              </sup>
            )}
          </Typography>
          <Typography variant={'subtitle2'}>
            {t('id.label').toUpperCase()} {data.ticketId} •{' '}
            {t('ticketRaisedOn.label')}{' '}
            {/* {dateFormatterLLL(
              convertUTCDateToLocalDate(new Date(data.createdAt))
            )} */}
            {dateFormatterLLL(data.createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

TicketItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired
};

export default withTranslation()(TicketItem);
