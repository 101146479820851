import React, { useState, useEffect } from 'react';
import CustomTabs from '../../../../../../components/CustomTabs';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
    GET_IOT_PLACE_LIST,
    GET_SENSOR_LIST,
    GET_MAINTENANCE_LIST
} from '../../queries';
import {
    CREATE_IOT_PLACE,
    UPDATE_IOT_SENSOR,
    CREATE_IOT_MAINTENANCE
} from '../../mutation';

import { GET_USERS_LIST } from '../../../../../Internal/Organisations/queries';
// import NotificationPanel from '../ConfigurationPanel/component/NotificationPanel';
import PlacePanel from "../ConfigurationPanel/component/PlacePanel";
import SensorPanel from "../ConfigurationPanel/component/SensorPanel";
import MaintenancePanel from "../ConfigurationPanel/component/MaintenancePanel";
import { USER_ROLES } from '../../../../../../utils/constants';
import CreatePlaceTemplateModal from '../../../../components/CreatePlaceTemplateModal';
import Message from '../../../../../../components/Message';
import IOTSensorModal from './component/IOTSensorModal';
import CreateMaintenanceModal from './component/CreateMaintenanceModal';

const ConfigurationPanel = ({
    t,
    data
}) => {

    const menuItems = [
        { label: t('edit.label') },
        // { label: t('delete.label') }
    ];
    const subscriptionId = data.id;
    const role = localStorage.getItem('role');
    const [activeIndex, setActiveIndex] = useState(0);
    const [configureActions, setConfigureActions] = useState(false);
    const [sensorFilterState, setSensorFilterState] = useState({ search: "", status: "", page: 1 });
    const [placeFilterState, setPlaceFilterState] = useState({ search: "", page: 1 });
    const [maintenanceFilterState, setMaintenanceFilterState] = useState({ search: "", page: 1 });
    const [openPlaceModal, setOpenPlaceModal] = useState(false);
    const [editPlaceData, setEditPlaceData] = useState(null);
    const [placeModalState, setPlaceModalState] = useState({ btnLoading: false, clearData: false });
    const [sensorModalState, setSensorModalState] = useState({ btnLoading: false, clearData: false });
    const [openSensorModal, setOpenSensorModal] = useState(false);
    const [editSensorData, setEditSensorData] = useState(null);
    const [selectPlaceList, setSelectPlaceList] = useState([]);
    const [maintenanceType, setMaintenanceType] = useState({});
    const [openCreateMaintenanceModal, setOpenCreateMaintenanceModal] = useState(false);
    const [maintenanceModalState, setMaintenanceModalState] = useState({ btnLoading: false, clearData: false });


    useEffect(() => {
        if (role.includes(USER_ROLES.EP_FINANCE) || role.includes(USER_ROLES.IP_USER)) {
            setConfigureActions(false);
        } else {
            setConfigureActions(true);
        }
        getIOTSensors({variables: {
            search: "",
            page: 1,
            limit: 20
        }});
        getAllMaintenance({variables: {
            search: "",
            page: 1,
            limit: 20
        }});
        return getIOTPlaces({variables: {
            search: "",
            page: 1,
            subscriptionId,
            limit: 20
        }});
    }, []);

    //   const {
    //     loading: notiTemplateLoading,
    //     data: notiTemplateList,
    //     error: notiTemplateError,
    //     refetch: reloadNotiTemplateData
    //   } = useQuery(GET_NOTIFICATION_TEMPLATE_LIST, {
    //     fetchPolicy: "no-cache",
    //     variables: {
    //       organization,
    //       limit: 20,
    //       search: filterState.notification,
    //       page: paginationState.notification
    //     }
    //   });

    const [getIOTPlaces, {
        loading: placeLoading,
        data: placeList,
        error: placeError,
        refetch: reloadPlaceData
    }] = useLazyQuery(GET_IOT_PLACE_LIST, {
        fetchPolicy: "no-cache"
    });

    const {data: usersList, loading: userLoading } = useQuery(GET_USERS_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            console.log("Res: ", res);
        }
    });

    const [getAllIOTPlaces] = useLazyQuery(GET_IOT_PLACE_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            console.log("res: ", res.getIOTPlaces);
            setSelectPlaceList(res.getIOTPlaces && res.getIOTPlaces.places ? res.getIOTPlaces.places : []);
        }
    });

    const [getIOTSensors, {
        loading: sensorLoading,
        data: sensorList,
        error: sensorError,
        refetch: reloadSensorData
    }] = useLazyQuery(GET_SENSOR_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            search: sensorFilterState.search,
            page: sensorFilterState.page,
            limit: 20
        },
        onCompleted: (res) => {
            if(res && res.getAllSensorList && res.getAllSensorList.sensors){
                let sensorManitainence = {};
                res.getAllSensorList.sensors.map(itm => {
                    sensorManitainence[itm._id] = itm.maintenanceType ? itm.maintenanceType : "Active";
                });
                setMaintenanceType(sensorManitainence);  
            }
        }
    });

    const [getAllMaintenance, {
        loading: maintenanceLoading,
        data: maintenanceList,
        error: maintenanceError,
        refetch: reloadMaintenanceData
    }] = useLazyQuery(GET_MAINTENANCE_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            console.log("Res: ", res);
        }
    });

    const [createPlace] = useMutation(CREATE_IOT_PLACE, {
        onError: error => {
            Message.success(error);
            setPlaceModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            if (data.addIOTPlace) {
                setPlaceModalState({ btnLoading: false, clearData: true });
                Message.success('Place created successfully');
                return reloadPlaceData({
                    variables: {
                        subscriptionId,
                        search: "",
                        page: 1,
                        limit: 20
                    }
                });
            }
        }
    });

    const [createMaintenance] = useMutation(CREATE_IOT_MAINTENANCE, {
        onError: error => {
            Message.success(error);
            setMaintenanceModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            if (data.addMaintenance) {
                setMaintenanceModalState({ btnLoading: false, clearData: true });
                Message.success('Maintenance created successfully');
                return reloadMaintenanceData({
                    variables: {
                        search: "",
                        page: 1,
                        limit: 20
                    }
                });
            }
        }
    });

    const [updateIOTSensor] = useMutation(UPDATE_IOT_SENSOR, {
        onError: error => {
            Message.success(error);
            setSensorModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            if (data) {
                setSensorModalState({ btnLoading: false, clearData: true });
                Message.success('Sensor updated successfully');
                return reloadSensorData({
                    variables: {
                        search: "",
                        page: 1,
                        limit: 20
                    }
                });
            }
        }
    });

    const handleCreatePlace = () => {
        setOpenPlaceModal(true);
        setEditPlaceData(null);
    }

    const handleCreatePlaceSubmit = (formData) => {
        if (formData.name && formData.location) {
            const placeData = {
                name: formData.name,
                lat: formData.location.latitude,
                lng: formData.location.longitude,
                type: 'general',
                subscriptionId
            };
            setPlaceModalState({ btnLoading: true, clearData: false });
            if (formData.place_id) {
                placeData.id = formData.place_id;
                return createPlace({
                    variables: placeData
                });
            } else {
                return createPlace({
                    variables: placeData
                });
            }
        } else {
            return false;
        }
    }

    const handleMenuItemClick = (elem, itemData, type) => {
        if (!itemData) {
            return false;
        }
        if (elem.label === t('edit.label')) {
            if (type === 'notification') {
            } else if (type === 'place') {
                setEditPlaceData({...itemData, place_id: itemData._id});
                setOpenPlaceModal(true);
            } else if (type === 'sensor') {
                setEditSensorData({...itemData});
                setOpenSensorModal(true);
                getAllIOTPlaces({variables: {
                    page: 1,
                    limit: 10000,
                    subscriptionId
                }});
            }
        } else if (elem.label === t('delete.label')) {
            if (type === 'notification') {
                if (window.confirm('Are you sure? You want to delete this template!') === false) {
                    return false;
                }
                // return deleteNotificationTemplate({
                //     variables: {
                //         id: id
                //     }
                // });
            } else if (type === 'place') {
                if (window.confirm('Are you sure? You want to delete this place!') === false) {
                    return false;
                }
            }

        };
    }

    const handleUpdateSensorSubmitClick = (formData) => {
        if(formData && formData.id){
            const sensorData = {
                deviceName: formData.deviceName,
                placeId: formData.placeId ? formData.placeId._id : "",
                id: formData.id
            };
            setSensorModalState({ btnLoading: true, clearData: false });
            return updateIOTSensor({variables: sensorData});
        }
        return false;
    }

    const handleCreateMaintenanceSubmit = (formData) => {
        if(formData){
            console.log("formData: ", formData);
            setMaintenanceModalState({ btnLoading: true, clearData: false });
            return createMaintenance({variables: formData});
        }
        return false;
    }

    const handlePageChange = (type, value) => {
        if(type === "sensor"){
            setSensorFilterState({...sensorFilterState, page: value});
            return getIOTSensors({variables: {
                page: value,
                limit: 20,
                search: sensorFilterState.search
            }});
        }else if(type === "place"){
            setPlaceFilterState({...placeFilterState, page: value});
            return getIOTPlaces({variables: {
                page: value,
                limit: 20,
                subscriptionId,
                search: placeFilterState.search
            }});
        }else if(type === "maintenance"){
            setMaintenanceFilterState({...maintenanceFilterState, page: value});
            return getAllMaintenance({variables: {
                page: value,
                limit: 20,
                search: maintenanceFilterState.search
            }});
        }
    }

    const handleSearch = (type, value) => {
        if(type === "sensor"){
            setSensorFilterState({...sensorFilterState, page: 1, search: value ? value : ""});
            return getIOTSensors({variables: {
                page: 1,
                limit: 20,
                search: value
            }});
        }else if(type === "place"){
            setPlaceFilterState({...placeFilterState, page: 1, search: value ? value : ""});
            return getIOTPlaces({variables: {
                page: 1,
                limit: 20,
                subscriptionId,
                search: value
            }});
        }
    }

    const handleChangeMaintenance = (value, rowItem) => {
        setMaintenanceType({...maintenanceType, [rowItem._id]: value});
        const sensorData = {
            maintenanceType: value,
            id: rowItem._id
        };
        return updateIOTSensor({variables: sensorData});
    }

    const handleChangeMaintenanceAction = (value, rowItem) => {
        const maintenanceData = {
            action: value,
            id: rowItem._id
        };
        return createMaintenance({variables: maintenanceData});
    }

    const configTabs = [
        {
            label: t('places.label'),
            children: (
                <>
                    <PlacePanel
                        t={t}
                        menuItems={menuItems}
                        list={placeList}
                        loading={placeLoading}
                        error={placeError}
                        allowConfigure={configureActions}
                        handleCreatePlace={handleCreatePlace}
                        handleMenuItemClick={handleMenuItemClick}
                        handlePageChange={handlePageChange}
                        placeFilterState={placeFilterState}
                        handleSearch={handleSearch}
                    />
                </>
            )
        },
        {
            label: t('sensors.label'),
            children: (
                <>
                    <SensorPanel
                        t={t}
                        menuItems={menuItems}
                        list={sensorList}
                        loading={sensorLoading}
                        error={sensorError}
                        handleMenuItemClick={handleMenuItemClick}
                        allowConfigure={configureActions}
                        handlePageChange={handlePageChange}
                        sensorFilterState={sensorFilterState}
                        handleSearch={handleSearch}
                        handleChangeMaintenance={handleChangeMaintenance}
                        maintenanceType={maintenanceType}
                    />
                </>
            )
        },
        {
            label: t('maintenance.label'),
            children: (
                <>
                    <MaintenancePanel
                        t={t}
                        menuItems={menuItems}
                        list={maintenanceList}
                        loading={maintenanceLoading}
                        error={maintenanceError}
                        allowConfigure={configureActions}
                        handleCreateNew={evt => {
                            getAllIOTPlaces({variables: {
                                page: 1,
                                limit: 10000,
                                subscriptionId
                            }});
                            setOpenCreateMaintenanceModal(true);
                        }}
                        handleMenuItemClick={handleMenuItemClick}
                        handlePageChange={handlePageChange}
                        maintenanceFilterState={maintenanceFilterState}
                        handleSearch={handleSearch}
                        handleChangeMaintenance={handleChangeMaintenanceAction}
                    />
                </>
            )
        }
    ];

    return (
        <div>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            />
            <CreatePlaceTemplateModal
                open={openPlaceModal}
                data={editPlaceData}
                handleClose={evt => setOpenPlaceModal(false)}
                handleCreatePlaceSubmitClick={handleCreatePlaceSubmit}
                modalState={placeModalState}
            />
            <IOTSensorModal
                open={openSensorModal}
                data={editSensorData}
                handleClose={evt => setOpenSensorModal(false)}
                handleUpdateSensorSubmitClick={handleUpdateSensorSubmitClick}
                modalState={sensorModalState}
                selectePlaceList={selectPlaceList}
            />
            <CreateMaintenanceModal
                open={openCreateMaintenanceModal}
                handleClose={evt => setOpenCreateMaintenanceModal(false)}
                handleCreateMaintenanceSubmit={handleCreateMaintenanceSubmit}
                modalState={maintenanceModalState}
                users={(!userLoading && usersList && usersList.userAccounts) ? usersList.userAccounts : []}
                selectePlaceList={selectPlaceList}
            />
        </div>
    );
};

ConfigurationPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default ConfigurationPanel;
