import React, {useState} from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../utils';
//import Message from "../../../../../../../components/Message";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button 
      color="primary"
      onClick={onClick}
      >
          {loading && <CircularProgress size={19} />}
          {!loading && <DeleteIcon color="secondary"/> }
      </Button>
  );
}

const ShayonaAuditItem = ({
    key,
    t,
    data,
    // deleteTruckLoading,
    // selecteIdForDelete,
    // handleSubmitOfDeleteTruck,
    // creteNewTruck
}) => {
  const commonClasses = commonStyles();
  return (
    <TableRow key={data.id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? data.action : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? dateFormatterLLL(data.created_on) : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    {/* <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {`${data.empty_weight_range_start} - ${data.empty_weight_range_end}`}
                </Typography>
            </Grid>
        </Grid>
    </TableCell> */}
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data? data.username: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    {/* <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {`${data.gross_weight_range_start} - ${data.gross_weight_range_end}`}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data ?data.allowed_diff_weight: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell> */}
    {/* <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
            <Button 
            color="primary"
            onClick={()=>  creteNewTruck('Edit Truck', data)}
            variant="outlined"
            >
             Edit
            </Button>
            <ButtonComponent 
                t={t}
                loading={selecteIdForDelete === data.category_id ? deleteTruckLoading: false}
                onClick={() =>handleSubmitOfDeleteTruck(data.category_id)}
            />
            </Grid>
        </Grid>
    </TableCell> */}
</TableRow> 
  )
}

export default ShayonaAuditItem