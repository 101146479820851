import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import {withTranslation} from 'react-i18next';
import {
    GET_CONNECTIVITY_INFO
} from './../../queries';
import {
    GenerateRandomString,
} from '../../../../utils';
import {
    SUBSCRIPTION_STATUS,
    USER_ROLES,
    FIELD_REQUIRED_ERROR,
} from '../../../../utils/constants';
import {
    CREATE_OPPORTUNITY,
    SAVE_ELASTIC_USER
} from './../../mutations';
import {useHistory} from 'react-router';
import CustomTabs from '../../../../components/CustomTabs';
import TicketPanel from '../../../ServiceTickets/components/TicketPanel';
import Message from '../../../../components/Message';
//SDN-NFV Service Modules
import DIADashboardPanel from './../../components/DIADashboardPanel';
import CreateOpportunityModal from './../../components/CreateOpportunityModal';
import BillingDiaPanel from './../../components/BillingDiaPanel';
import MonitoringPanel from './../../components/MonitoringPanel';

const DIABotswanaService = ({
    t, 
    data, 
    activeIndex,
    serviceTickets,
    filterListRecentTickets,
    handleTicketItemClick,
    handleTicketMenuItemClick,
    handleSearchRecentTickets,
    recentTicketsSearched,
    updatedRecentTickets
}) => {
    
    const history = useHistory();
    const role = localStorage.getItem('role');
    const [isReloadState, setIsReloadState] = useState(false);
    const [reloadDataState, setReloadDataState] = useState('');
    const [createDIAOpportunityModal, setDIAOpportunityModalState] = useState(false);
    const [connectivityInfoList, setConnectivityInfoList] = useState(null);
    const [opportunityLoadModalState, setOpportunityLoadModalState] = useState({
        btnLoading: false, 
        clearData: false  
    });
    /**
     * DIA SERVICE QUERY/MUTATION
     */
    const [createOpportunity] = useMutation(CREATE_OPPORTUNITY, {
        onError: error => {
            setOpportunityLoadModalState({btnLoading: false, clearData: false});
            // Message.error(error);
        },
        onCompleted: data => {
            if (data.createOpportunity.status) {
                Message.success('Opportunity created successfully');
                setOpportunityLoadModalState({btnLoading: false, clearData: true});
                setReloadDataState('opportunity');
            } else {
                Message.error('Opportunity is not create');
                setOpportunityLoadModalState({btnLoading: false, clearData: false});
            }
        }
    });
    
    useEffect(() => {
        console.log("data.getSubscriptionById ======== : ", data.getSubscriptionById);
        getConnectivityInfoList({
            variables: {
                opportunityId: data.getSubscriptionById.opportunityId
            }
        });
    }, []);
    
    const activeSites = (data.getSubscriptionById &&
        data.getSubscriptionById.sites && data.getSubscriptionById.sites.length && data.getSubscriptionById.sites.filter(item => item.status === SUBSCRIPTION_STATUS.ACTIVE)) || [];
    
    const [getConnectivityInfoList] = useLazyQuery(GET_CONNECTIVITY_INFO, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            setConnectivityInfoList(res);
        }
    });
    const [addUpdateElasticUser] = useMutation(SAVE_ELASTIC_USER, {
        onError: error => {
            setIsReloadState(true);
        },
        onCompleted: data => {
            setIsReloadState(true);
        }
    });
    useEffect(() => {
        let IpAddress =
            connectivityInfoList &&
            connectivityInfoList.getConnectivityInfo.length &&
            connectivityInfoList.getConnectivityInfo[0].ip_address;
        let VlanId =
            connectivityInfoList &&
            connectivityInfoList.getConnectivityInfo.length &&
            connectivityInfoList.getConnectivityInfo[0].vlan;
        if (IpAddress) {
            addUpdateElasticUser({
                variables: {
                    username:
                        data && data.getSubscriptionById && data.getSubscriptionById.name,
                    password: GenerateRandomString(8),
                    IpAddress: IpAddress,
                    vlanid: VlanId,
                    opportunityId:
                        data &&
                        data.getSubscriptionById &&
                        data.getSubscriptionById.opportunityId
                }
            });
        }
    }, [connectivityInfoList]);
    const handleCreateOpportunity = formData => {
        setOpportunityLoadModalState({btnLoading: true, clearData: false});
        if (!formData.subject) {
            setOpportunityLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('subject.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        const requestPayload = {
            opportunity_details: {
                subject: formData.subject,
                type: formData.opportunityType,
                billing_account: formData.billingAccount,
                currency: formData.currency,
                remarks: formData.review
            },
            product_charges: {
                product_name: formData.productName,
                charges: {
                    charge_type: {
                        bill_cycle: formData.billingCycle,
                        attributes: {
                            distance: formData.distance,
                            protection_type: formData.protectionType,
                            bandwidth: formData.bandwidth
                        }
                    }
                }
            },
            site_info: {
                billing_account: formData.billingAccount,
                siteA: {
                    address_label: formData.SiteAAddress,
                    company_name: formData.SiteACompanyName,
                    country: formData.SiteACountry,
                    district: formData.SiteADistrict,
                    city: formData.SiteACity,
                    contact_name: formData.SiteAContactName,
                    plot: formData.SiteAPlot,
                    location: formData.SiteALocation,
                    coordinates: formData.SiteACordinates
                },
                siteB: {
                    address_label: formData.SiteBAddress,
                    company_name: formData.SiteBCompanyName,
                    country: formData.SiteBCountry,
                    district: formData.SiteBDistrict,
                    city: formData.SiteBCity,
                    contact_name: formData.SiteBContactName,
                    plot: formData.SiteBPlot,
                    location: formData.SiteBLocation,
                    coordinates: formData.SiteBCordinates
                }
            }
        };
        return createOpportunity({
            variables: {opportunityData: JSON.stringify(requestPayload)}
        });
    };
    
    let tabList = [];
    if (data) {
        if (data.getSubscriptionById && data.getSubscriptionById.isDelete) {
            Message.error('Subscription termineted!');
            history.goBack();
        }
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <DIADashboardPanel
                        t={t}
                        subscriptionData={data.getSubscriptionById}
                        data={activeSites}
                        ticketData={
                            (serviceTickets &&
                                serviceTickets.servicetickets.slice(0, 10)) ||
                            []
                        }
                        type={data.getSubscriptionById.service.serviceType.name}
                    />
                )
            },
            {
                label: t('serviceTickets.label'),
                children: (
                    <>
                        {serviceTickets && (
                            <TicketPanel
                                ticketList={
                                    recentTicketsSearched
                                        ? updatedRecentTickets
                                        : serviceTickets.servicetickets.slice(0, 10)
                                }
                                type={'recentTickets'}
                                t={t}
                                handleMenuItemClick={handleTicketMenuItemClick}
                                filterList={filterListRecentTickets}
                                handleTicketItemClick={handleTicketItemClick}
                                handleSearch={handleSearchRecentTickets}
                            />
                        )}
                    </>
                )
            }
        ];
        if (
            !role.includes(USER_ROLES.CSOC_MANAGER) &&
            !role.includes(USER_ROLES.CSOC) &&
            !role.includes(USER_ROLES.SD_READONLY) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
        ) {
            tabList.push({
                label: t('monitoring.label'),
                children: (
                    <>
                        <MonitoringPanel
                            t={t}
                            isReload={isReloadState}
                            data={data.getSubscriptionById}
                        />
                    </>
                )
            });
        }
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.CSOC_MANAGER) &&
            !role.includes(USER_ROLES.CSOC) &&
            !role.includes(USER_ROLES.SD_READONLY) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('billing.label'),
                children: (
                    <>
                        <BillingDiaPanel t={t} data={data.getSubscriptionById}/>
                    </>
                )
            });
        }
    }

    return (
        <>
            <CustomTabs 
                activeIndex={activeIndex} 
                tabs={tabList}
            />
            {/****************** DIA MODALS ******************** */}
            <React.Fragment>
                <CreateOpportunityModal
                    t={t}
                    open={createDIAOpportunityModal}
                    handleCreateOpportunity={handleCreateOpportunity}
                    handleClose={() => {
                        setOpportunityLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setDIAOpportunityModalState(false);
                    }}
                    modalState={opportunityLoadModalState}
                />
            </React.Fragment>
        </>
    );
};
export default withTranslation()(React.memo(DIABotswanaService));