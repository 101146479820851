import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const UserItem = ({ t, data, handleMenuItemClick, menuItems }) => {
  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <TableRow key={data.id}>
      <TableCell>
        <Grid className={commonClasses.column}>
          <Grid>
            <Icon
              component={() => (
                <PersonOutlineIcon
                  color={'secondary'}
                  style={{ marginTop: '2px', marginRight: '6px' }}
                />
              )}
              alt={'icon'}
            />
          </Grid>
          <Grid>
            <Typography variant={'subtitle1'}>
              {data.firstName} {data.lastName}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid className={commonClasses.column}>
          <Grid>
            <Typography variant={'subtitle1'}> {data.email}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid className={commonClasses.column}>
          <Grid>
            <Typography variant={'subtitle1'}> {data.phone}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid className={commonClasses.column}>
          <Grid>
            <Typography variant={'subtitle1'}> {data.role}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid className={commonClasses.column}>
          <Grid>
            <Typography variant={'subtitle1'}>
              {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid>
          <IconButton
            size={'small'}
            disableRipple={true}
            disableFocusRipple={true}
            color={'secondary'}
            onClick={evt => {
              setAnchorEl(evt.currentTarget);
              evt.stopPropagation();
            }}>
            <MoreHoriz className={commonClasses.iconRegular} />
          </IconButton>
        </Grid>
        {anchorEl && (
          <MenuWrapper
            id={'ticket-menu'}
            anchorEl={anchorEl}
            handleClose={evt => {
              evt.stopPropagation();
              setAnchorEl(null);
            }}
            menuItems={menuItems}
            handleMenuItemClick={elem => {
              handleMenuItemClick(elem, data, 'ml_template');
              setAnchorEl(null);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func
};

export default withTranslation()(UserItem);
