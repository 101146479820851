import { gql } from 'apollo-boost';

export const CREATE_IOT_PLACE = gql`
    mutation Place($name: String, $subscriptionId: String, $lat: Float, $lng: Float, $id: String) {
        addIOTPlace(name: $name, subscriptionId: $subscriptionId, lat: $lat, lng: $lng, id: $id) {
            id
        }
    }
`;

export const UPDATE_IOT_SENSOR = gql`
    mutation Sensor($deviceName: String, $placeId: String, $id: String, $maintenanceType: String) {
        updateIOTSensor(deviceName: $deviceName, placeId: $placeId, id: $id, maintenanceType: $maintenanceType) {
            deviceNo
        }
    }
`;

export const CREATE_IOT_MAINTENANCE = gql`
    mutation Maintenanc($person: JSON, $startDate: Date, $endDate: Date, $comments: String, $placeId: String, $id: String, $action: String) {
        addMaintenance(person: $person, startDate: $startDate, endDate: $endDate, comments: $comments, placeId: $placeId, id: $id, action: $action) {
            id
        }
    }
`;