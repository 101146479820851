import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DemosVideoPopup from '../Popups/DemosVideoPopup';
import DemosVideoPlayerComponent from '../DemosVideoPlayerComponent';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});


const VideosPanel = ({ t, videoData }) => {
    const classes = useStyles();
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [openVideoPopup, setOpenVideoPopup] = useState(false);

    const openVideo = (videoUrl) => {
        setSelectedVideo(videoUrl);
        setOpenVideoPopup(true);
    };

    const closeVideo = () => {
        setOpenVideoPopup(false);
        setSelectedVideo(null);
    };

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Grid container spacing={1}>
                {videoData && videoData?.map((video, i) =>
                    <Grid item key={i} xs={12} sm={3}>
                        <Card className={classes.root}>
                            <CardActionArea onClick={() => openVideo(video?.video_url)}>
                                <CardMedia
                                    className={classes.media}
                                    image="https://cdn.pixabay.com/photo/2017/11/10/05/34/play-2935460_1280.png"
                                    title="Watch this video"
                                    allow="autoPlay"
                                />
                                <CardContent>
                                    <Typography gutterBottom color="textSecondary" variant="h5" component="h2">
                                        {video?.video_name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => openVideo(video?.video_url)}>
                                    Watch this Video
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>)}
            </Grid>
            {selectedVideo && openVideoPopup &&
                <DemosVideoPopup onOpen={openVideoPopup}
                    onClose={closeVideo} >
                    <DemosVideoPlayerComponent t={t} videoUrl={selectedVideo} />
                </DemosVideoPopup>
            }
        </>
    )
}

export default withTranslation()(React.memo(VideosPanel));
