import React, { useState } from 'react';
import ChartContainer from '../.././../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../.././../../../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GenericTable from '../../../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { dateFormatterLLL } from '../../../../../../utils';
import CreateQuoteModal from './CreateQuoteModal';
import RefDocModal from './RefDocModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PdfFile from "../../../../../../assets/Amended-RFQ-SCM.pdf";

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 215,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
  
}));

const bidData = [
    {
        name: "Test Bid 1", 
        uploaded_on: dateFormatterLLL(new Date()), 
        status: "Pending", 
        due_date: dateFormatterLLL(new Date()),
        vendores_allowed: ""
    },
    {
        name: "Test Bid 2", 
        uploaded_on: dateFormatterLLL(new Date()), 
        status: "Open", 
        due_date: dateFormatterLLL(new Date()), 
        vendores_allowed: ""
    }
];

const Quotes = ({ t }) => {
    const classes = useStyles();
    const [bidList, setBidList] = useState([]);
    const [openCreateQuoteModal, setOpenCreateQuoteModal] = useState(false);
    const [openRefDocModal, setOpenRefDocModal] = useState(false)
    const eventAlertTimelineFilter = [
        {
            label: "Search By Name",
            handleChange: (evt) => {},
            val: null,
            type: "text",
            styleOverrides: { marginRight: 24, marginTop: 10 },
            className: classes.dateInput
        },
        {
            label: t("startDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('status.label'),
            handleChange: (evt, newValue) => {
            },
            val: "",
            options: ["Open", "Closed", "Offered"],
            styleOverrides: { width: 180, marginTop: 10 }
        }
    ];
    const columns = [
        {
            label: "Bid Name",
            key: "name"
        },
        {
            label: "Uploaded On",
            key: "uploaded_on"
        },
        {
            label: "Vendores Name",
            key: "vendores_name"
        },
        {
            label: "Quote Refrence",
            key: "quote_reference"
        },
        {
            label: "Action",
            key: "action"
        }
    ];

    const handleOpenRefDoc = () => {
        window.open(PdfFile, {target: "_blank"});
    }

    React.useEffect(() => {
        setBidList(bidData.map(itm => {
            itm.action = <>
                <IconButton style={{marginRight: 10}} aria-label="print">
                    <PrintIcon color="primary" />
                </IconButton>
                <IconButton style={{marginRight: 10}} aria-label="edit">
                    <EditIcon color="primary" />
                </IconButton>
                <IconButton aria-label="delete">
                    <DeleteIcon color="secondary" />
                </IconButton>
                <IconButton aria-label="delete">
                    <VisibilityIcon color="primary" />
                </IconButton>
            </>;
            itm.quote_reference = <>
                <IconButton onClick={handleOpenRefDoc} aria-label="delete">
                    <PictureAsPdfIcon color="primary" />
                </IconButton>
            </>;
            return itm;
        }));
    }, []);

    const handleCreateBidSubmit = () => {
        
    }

    return (
        <div style={{width: "100%", display: "inline-block"}}>
            <ChartContainer
                customMargin={{marginTop: 0}}
                filterList={eventAlertTimelineFilter}
                filterSubmit={evt => {
                    console.log("filter");
                }}
                createNewButton={evt => {
                    console.log("create New");
                    setOpenCreateQuoteModal(true);
                }}
                createLabel="Upload a Quote">
            </ChartContainer>
            <Grid
            container
            style={marginGenerator('mt-25')}>
                <GenericTable list={bidList} columns={columns} />
            </Grid>
            <CreateQuoteModal 
                open={openCreateQuoteModal}
                handleClose={evt => {
                    setOpenCreateQuoteModal(false);
                }}
                handleCreateBidSubmit={handleCreateBidSubmit}
            />
            <RefDocModal 
                open={openRefDocModal}
                handleClose={evt => {
                    setOpenRefDocModal(false);
                }}
            />
        </div>
    );
};

Quotes.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default Quotes;
