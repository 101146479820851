import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { marginGenerator } from '../../../../../../theme/utils';
import SesiroLogo from '../../../../../../assets/icons/sesiro.png';
import { IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  left: {
    float: 'left'
  },
  right: {
    float: 'right'
  },
  blueBorder: {
    border: '5px solid ',
    borderRight: '5px solid #06373e',
    borderTop: '5px solid #2e8a88',
    borderBottom: '5px solid #2e8a88',
    borderLeft: '5px solid #47cfc7',
    width: '350px',
    padding: '40px',
    borderRadius: '20px'
  }
}));

function SesiroHeader1({ t }) {
  const classes = useStyles();

  return (
    <Grid container style={{ padding: '0 32px' }}>
      <Grid item xs={12}>
        <div className={classes.main}>
          <div className={classes.left}>
            <img
              style={{
                width: '200px',
                height: 'auto',
                marginBottom: '5px',
                marginTop: '20px'
              }}
              src={SesiroLogo}
              alt="Sesiro"
            />
            <div>
              <p
                style={{
                  color: '#999d9e',
                  fontSize: '13px',
                  fontfamily: 'sans-serif'
                }}>
                Plot 64288, Airport Road, Block 8 <br /> P.O Box 329, Gaborone,
                Botswana
                <br /> Tel: +267 3648838 | Fax: +267 3952941
                <br /> enquiries@sesiro.co.bw
                <br /> claims@sesiro.co.bw / complaints@sesiro.co.bw
                <br /> www.debswana.com
              </p>
            </div>
            <Grid container spacing={12}>
              <Grid
                item
                xs={14}
                style={{ marginLeft: '380px', marginTop: '-280px' }}
                className={classes.right}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#6cccf5',
                    fontSize: '35px',
                    fontWeight: '200',
                    fontFamily: 'sans-serif'
                  }}
                  className={classes.blueBorder}>
                  <p
                    style={{
                      color: '#999d9e',
                      fontSize: '14px',
                      fontfamily: 'sans-serif',
                      marginBottom: '1px'
                    }}>
                    SESIRO CO.2000/12
                  </p>
                  <b>GLASS</b> CLAIM FORM
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(SesiroHeader1);
