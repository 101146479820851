import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import Layout from '../../../../components/Layout';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useHistory, useLocation } from 'react-router';
import { getParam } from '../../../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_SUBSCRIPTION_LIST,
  GET_VNF_MARKET_PLACE_BY_ID
} from '../../queries';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
import { CREATE_IMAGE } from '../../mutations';
import Message from '../../../../components/Message';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}
function createData(name, value, fat, carbs, protein) {
  return { name, value };
}
const rows = [
  createData('Version', '2020.09.09'),
  createData('By', 'Amazon Web Services'),
  createData('Categories', 'Operating Systems'),
  createData('Operating System', 'Windows, Windows Server 2019 Base 10'),
  createData('Delivery Methods', 'Amazon Machine Image')
];
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  listView: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {},
  image: {
    width: '225px',
    marginRight: '15px',
    marginTop: '15px'
  },
  title: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 600,
    color: '#008bcc',
    marginBottom: '10px'
  },
  inline: {
    display: 'inline',
    lineHeight: '26px',
    marginBottom: '5px'
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  detailHeader: {
    backgroundColor: '#e8e8e8',
    padding: '20px'
  },
  tabsHead: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-evenly'
    }
  },
  images: {
    minWidth: '150px',
    padding: '20px'
  },
  cover: {
    width: '125px',
    maxWidth: '100%'
  },
  rightSection: {
    textAlign: 'center',
    '& button': {
      width: '100%'
    }
  },
  saveBtn: {
    margin: '10px 0 20px'
  },
  price: {
    fontSize: '20px',
    marginBottom: '15px'
  },
  pricingOuter: {
    padding: '10px',
    boxShadow: '0 0 2px #717171'
  },
  freeTier: {
    backgroundColor: '#008bcc',
    color: '#ffffff',
    padding: '5px 10px'
  },
  tabHeading: {
    fontSize: '25px',
    color: '#008bcc',
    marginBottom: '15px'
  },
  tabDescription: {
    fontSize: '17px'
  },
  highlights: {
    boxShadow: '0 0 2px #717171',
    marginTop: '45px',
    marginLeft: '20px',
    padding: '25px 10px',
    '& h4': {
      color: '#008bcc'
    },
    '& li': {
      marginBottom: '15px'
    }
  },
  overviewTable: {
    width: '100%',
    '& td': {
      width: '50%',
      fontSize: '16px'
    }
  }
}));
function VNFMarketplaceDetailPanel({ t, data, open, id, handleClose }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const organization_id = data.organizationId;
  const [value, setValue] = React.useState(2);
  const [tValue, setTValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTValue(newValue);
  };
  //const id = getParam();
  /*
     Vnf MarketPlace Details Queries
    */
  const {
    loading: activeLoading,
    error: activeError,
    data: VnfMarketPlace
  } = useQuery(GET_VNF_MARKET_PLACE_BY_ID, {
    variables: {
      vnfMarketPlaceId: id
    }
  });
  const [createImage] = useMutation(CREATE_IMAGE, {
    onError: error => {
      console.log('Error', error);
      //  Message.error(error);
    },
    onCompleted: data => {
      if (data.createImage.Image_ID) {
        Message.success('image created successfully');
        handleClose('success');
      }
    }
  });
  const handleCreateImage = formData => {
    if (window.confirm('Are you sure to subscribe this image?')) {
      console.log('body', formData);
      formData.organisation_id = organization_id;
      return createImage({
        variables: formData
      });
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="marketplace-modal"
      aria-describedby="marketplace-modal"
      open={open}
      style={{ alignItems: 'baseline' }}
      onClose={() => {
        handleClose('');
      }}>
      <DialogTitle id="ml-template-title">
        <Typography
          variant="subtitle1"
          color="textPrimary"
          xs={12}
          style={{ fontSize: '25px' }}
          align={'center'}>
          {t('vnfMarketplace.label')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.detailHeader}>
          <Grid container spacing={3} contentStyle={{ maxWidth: '80%' }}>
            <Grid item xs={9}>
              <List className={classes.listView}>
                <ListItem alignItems="flex-start" className={classes.listItem}>
                  <div className={classes.images}>
                    <img
                      alt="Cindy Baker"
                      className={classes.cover}
                      src={
                        VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                          ? VnfMarketPlace.getVnfMarketPlaceById.imageLogo
                          : ''
                      }
                    />
                  </div>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="h4"
                          variant="body2"
                          className={classes.title}
                          color="textPrimary">
                          {VnfMarketPlace &&
                          VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.imageName
                            : ''}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary">
                          {VnfMarketPlace &&
                          VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.imageName
                            : ''}
                        </Typography>
                        {/*{' — Do you have Paris recommendations? Have you ever'}*/}
                        <Typography paragraph className={classes.description}>
                          {VnfMarketPlace &&
                          VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.description
                            : ''}
                        </Typography>
                        {/*<Typography
                                                    variant="body2"
                                                    className={classes.description}
                                                    style={{marginTop: '10px', display: "flex", alignItems: 'center'}}
                                                    color="textPrimary"
                                                >
                                                    <span style={{display: 'inline-block', marginRight: '15px'}}>Windows</span> <Rating name="read-only" value={value} readOnly/> {' — 2 AWS review'}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    className={classes.freeTier}
                                                    color="textPrimary"
                                                >
                                                    {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById ? VnfMarketPlace.getVnfMarketPlaceById.imageType : ""}
                                                </Typography>*/}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3}>
              <Card
                className={(classes.root, classes.rightSection)}
                variant="outlined">
                <CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleCreateImage({
                        image_name:
                          VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.imageName
                            : '',
                        image_version: 'V1',
                        createdBy: 'Anil',
                        image_logo:
                          VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.imageLogo
                            : '',
                        image_url:
                          VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                            ? VnfMarketPlace.getVnfMarketPlaceById.imageURL
                            : ''
                      });
                    }}>
                    Continue to subscribe
                  </Button>
                  {/*<Button variant="outlined" className={classes.saveBtn} color="secondary">
                                        Save to list
                                    </Button>*/}
                  <Grid className={classes.pricingOuter}>
                    <Typography color="textSecondary" gutterBottom>
                      typical total price
                    </Typography>
                    <Typography color="textSecondary" className={classes.price}>
                      {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                        ? VnfMarketPlace.getVnfMarketPlaceById.imagePrice
                        : ''}
                    </Typography>
                    <Typography paragraph className={classes.description}>
                      {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                        ? VnfMarketPlace.getVnfMarketPlaceById.description
                        : ''}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <div className={(classes.root, classes.tabsHead)}>
              <AppBar position="static" color="default">
                <Tabs
                  value={tValue}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example">
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Usage" {...a11yProps(1)} />
                  <Tab label="Support" {...a11yProps(2)} />
                  {/* <Tab label="Reviews" {...a11yProps(3)} />*/}
                </Tabs>
              </AppBar>
              <TabPanel value={tValue} index={0}>
                <Grid container className={classes.tabContentOuter}>
                  <Grid lg={7}>
                    <Typography component="h4" className={classes.tabHeading}>
                      Product Overview
                    </Typography>
                    <Typography
                      component="p"
                      className={classes.tabDescription}>
                      {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                        ? VnfMarketPlace.getVnfMarketPlaceById.overview
                        : ''}
                    </Typography>
                    <TableContainer
                      component={Paper}
                      className={classes.overviewTable}>
                      <Table
                        className={classes.table}
                        aria-label="simple table">
                        <TableBody>
                          {VnfMarketPlace &&
                            VnfMarketPlace.getVnfMarketPlaceById &&
                            VnfMarketPlace.getVnfMarketPlaceById.overviewList.map(
                              (row, index) => {
                                return (
                                  <TableRow key={row.name}>
                                    <TableCell
                                      align="left"
                                      style={{ color: '#b7b7b7' }}>
                                      {row.name}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.value}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          {/* {rows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell align="left" style={{color: '#b7b7b7'}}>{row.name}</TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}*/}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid lg={5}>
                    <Grid className={classes.highlights}>
                      <Typography component="h4">Highlights</Typography>
                      <ul>
                        {VnfMarketPlace &&
                          VnfMarketPlace.getVnfMarketPlaceById &&
                          VnfMarketPlace.getVnfMarketPlaceById.highlightsList.map(
                            (item, index) => {
                              return (
                                <li>
                                  <a href={item.link} target={'_blank'}>
                                    {item.name}
                                  </a>
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tValue} index={1}>
                <Grid container className={classes.tabContentOuter}>
                  <Grid lg={12}>
                    <Typography component="h4" className={classes.tabHeading}>
                      Usage Information
                    </Typography>
                    {/*<Typography
                                            component="span"
                                            style={{fontWeight: '600', fontSize: "20px", marginBottom: '15px', display: "grid"}}
                                        >
                                            End User License Agreement
                                        </Typography>*/}
                    <Typography
                      component="p"
                      className={classes.tabDescription}>
                      {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                        ? VnfMarketPlace.getVnfMarketPlaceById.review
                        : ''}
                    </Typography>
                  </Grid>
                  {/* <Grid lg={5}> */}
                  {/*<Grid className={classes.highlights}>
                                            <Typography
                                                component="h4"
                                            >
                                                Additional Resources
                                            </Typography>
                                            <ul>
                                                <li>MongoDB</li>
                                                <li>mongo-express</li>
                                                <li>Docker</li>
                                            </ul>
                                        </Grid>*/}
                  {/*<Grid className={classes.highlights}>
                                        <Typography
                                            component="h4"
                                        >
                                            Amazon Machine Image
                                        </Typography>
                                        <Typography
                                            component="p"
                                            className={classes.tabDescription}
                                        >
                                            An Amazon Machine Image (AMI) provides the information required to launch an instance, which is a virtual server in the cloud. You specify an AMI when you launch an instance,
                                            and you can launch as many instances from the AMI as you need. You can also launch instances from as many different AMIs as you need.
                                        </Typography>
                                    </Grid>*/}
                  {/* </Grid> */}
                </Grid>
              </TabPanel>
              <TabPanel value={tValue} index={2}>
                <Grid container className={classes.tabContentOuter}>
                  <Grid lg={12}>
                    <Typography component="h4" className={classes.tabHeading}>
                      Support Information
                    </Typography>
                    {/* <Typography
                                            component="span"
                                            style={{fontWeight: '600', fontSize: "20px", marginBottom: '15px', display: "grid"}}
                                        >
                                            AWS Infrastructure
                                        </Typography>*/}
                    <Typography
                      component="p"
                      className={classes.tabDescription}>
                      {VnfMarketPlace && VnfMarketPlace.getVnfMarketPlaceById
                        ? VnfMarketPlace.getVnfMarketPlaceById.support
                        : ''}
                    </Typography>
                    {/*<Typography
                                        component="span"
                                        style={{fontWeight: '600', fontSize: "20px", marginBottom: '15px', display: "grid"}}
                                    >
                                        Long term contract details
                                    </Typography>
                                    <Typography
                                        component="p"
                                        className={classes.tabDescription}
                                    >
                                        Long term software contracts require a one time up front payment with no ongoing charges and covers all software fees for the selected unit type. Hourly AWS infrastructure usage
                                        fees still apply.
                                    </Typography>*/}
                  </Grid>
                  {/* <Grid lg={5}> */}
                  {/*<Grid className={classes.highlights}>*/}
                  {/*    <Typography*/}
                  {/*        component="h4"*/}
                  {/*    >*/}
                  {/*        Additional Resources*/}
                  {/*    </Typography>*/}
                  {/*    <ul>*/}
                  {/*        <li>AWS Infrastructure Support</li>*/}
                  {/*    </ul>*/}
                  {/*</Grid>*/}
                  {/* </Grid> */}
                </Grid>
              </TabPanel>
              {/*<TabPanel value={tValue} index={3}>
                            <Grid container className={classes.tabContentOuter}>
                                <Grid lg={7}>
                                    <Typography
                                        component="h4"
                                        className={classes.tabHeading}
                                    >
                                        Customer Reviews
                                    </Typography>
                                    <Typography
                                        component="p"
                                        className={classes.tabDescription}
                                    >
                                        There are currently no reviews for this software.
                                    </Typography>
                                </Grid>
                                <Grid lg={5}>
                                    <Grid className={classes.highlights}>
                                        <Typography
                                            component="h4"
                                        >
                                            Write a review
                                        </Typography>
                                        <Typography
                                            component="p"
                                            className={classes.tabDescription}
                                        >
                                            Share your thoughts about this product.
                                        </Typography>
                                        <Button variant="outlined" className={classes.saveBtn} color="secondary">
                                            Write a customer review
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>*/}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
VNFMarketplaceDetailPanel.propTypes = {
  t: PropTypes.func.isRequired
};
export default withTranslation()(VNFMarketplaceDetailPanel);
