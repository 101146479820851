import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import OutlinedSelect from '../../../../../../../../components/OutlinedSelect';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function IOTSensorModal({
    t,
    open,
    handleUpdateSensorSubmitClick,
    data,
    selectePlaceList,
    handleClose,
    modalState,
}) {

    const sensorInitFields = { project: "", deviceNo: "", deviceName: "", sclk: "", state: "", battery: "", csq: "", imei: 0, iccid: "", lat: 0, lng: 0, deviceType: "", deviceName: "", dataType: "", service_id: "", placeId: "" };

    const [sensorFormState, setSensorFormState] = useState([{ ...sensorInitFields }]);
    const classes = useStyles();

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setSensorFormState([{ ...sensorInitFields }]);
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const sensorData = { project: data.project, deviceNo: data.deviceNo, deviceName: data.deviceName, sclk: data.sclk, state: data.state, battery: data.battery, csq: data.csq, imei: data.imei, iccid: data.iccid, lat: data.lat, lng: data.lng, deviceType: data.deviceType, dataType: data.dataType, service_id: data.service_id, placeId: data.placeId, id: data._id };
            setSensorFormState({ ...sensorData });
        }
    }, [data]);

    const handleSensorChange = (value, name) => {
        setSensorFormState({ ...sensorFormState, [name]: value });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="sensor-template-title">
                {t('edit.label')} {t('sensor.label')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Device Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            readOnly={true}
                            color={'secondary'}
                            value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={`${t('deviceName.label')}*`}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={sensorFormState.deviceName}
                            onChange={evt => handleSensorChange(evt.target.value, 'deviceName')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id={`place-select`}
                            options={selectePlaceList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                handleSensorChange(newValue, 'placeId')
                            }}
                            value={sensorFormState.placeId}
                            style={{ border: '1px solid #ebebeb' }}
                            renderInput={(params) => <TextField {...params} label={t('place.label')} variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 20}}>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Project"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            readOnly={true}
                            color={'secondary'}
                            value={sensorFormState.project}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Device Type"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={sensorFormState.deviceType}
                            onChange={evt => handleSensorChange(evt.target.value, 'deviceType')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Battery"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={sensorFormState.battery}
                            onChange={evt => handleSensorChange(evt.target.value, 'battery')}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 20}}>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Data Type"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={sensorFormState.dataType}
                            onChange={evt => handleSensorChange(evt.target.value, 'sclk')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"SCLK"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={sensorFormState.sclk}
                            onChange={evt => handleSensorChange(evt.target.value, 'sclk')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setSensorFormState({ ...sensorInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleUpdateSensorSubmitClick(sensorFormState);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

IOTSensorModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleUpdateSensorSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(IOTSensorModal);