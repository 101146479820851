import React, { useState, useQuery } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../utils/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { checkValidEmail } from '../../../../../utils';
import OutlinedSelect from '../../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateInternalProjectsModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    handleUploadFiles,
    countryList,
    userData
}) {
    const classes = useStyles();
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [billingCountry, setBillingCountry] = useState('');
    const [opportunityName, setOpportunityName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [document, setDocument] = useState([]);
    React.useEffect(() => {
        // if (modalState.clearData && open) {
        //     handleClose();
        // }
        console.log("countryList : ",countryList)
        if (modalState.clearData === true) {
            setProjectName('');
            setProjectDescription('');
            setBillingCountry('');
            setEmailAddress('');
            setOpportunityName('');
            setFirstName('');
            setLastName('');
            setDocument([]);
        }
        if(userData && userData.userInfo && userData.userInfo.length != 0){
            setFirstName(userData.userInfo.firstName);
            setLastName(userData.userInfo.lastName);
            setEmailAddress(userData.userInfo.email);
        }
    }, [modalState, userData]);

    const handleDocuments = (index) => {
        setDocument(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('addInternalProjects.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('projectName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectName}
                                type={'text'}
                                placeholder={t('projectName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('project.label') + ' ' + t('title.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={opportunityName}
                                type={'text'}
                                placeholder={t('project.label') + ' ' + t('title.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setOpportunityName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('firstName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={firstName}
                                disabled
                                type={'text'}
                                placeholder={t('firstName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setFirstName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('lastName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={lastName} disabled
                                type={'text'}
                                placeholder={t('lastName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setLastName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('emailAddress.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={emailAddress} disabled
                                type={'text'}
                                placeholder={t('emailAddress.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setEmailAddress(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('project.label') + ' ' + t('description.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectDescription}
                                type={'text'}
                                placeholder={t('project.label') + ' ' + t('description.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectDescription(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={t('billing.label') + ' ' + t('country.label') + '*'}
                                handleChange={evt => {
                                    console.log("evt.target.value : ",evt.target.value);
                                    if (evt.target.value) {
                                        setBillingCountry(evt.target.value);
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={countryList ? (countryList.getCountries.map(item => item.name)) : []}  
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attachments.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={document && document.length >= 5 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && document.length < 5) {
                                        if (evt.target.files.length <= 5 && (document.length + evt.target.files.length) <= 5) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setDocument(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 5. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {document && document.length ?
                                document.map((item, index) => (
                                    <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleDocuments(index)} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if(userData && userData.userInfo && userData.userInfo.length != 0){
                                setFirstName(userData.userInfo.firstName);
                                setLastName(userData.userInfo.lastName);
                                setEmailAddress(userData.userInfo.email);
                            }
                            if (!projectName || !projectDescription || !billingCountry || !emailAddress || !opportunityName || !firstName || !lastName) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let getDocument = [];
                            if (document != [] && document != null && document.length > 0) {
                                for (let i = 0; i < document.length; i++) {
                                    let getAttachDoc = await handleUploadFiles(document[i], 'internalProjects', 'finance_team_section');
                                    if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                        getDocument.push(getAttachDoc.data.allUploadFile.url);
                                    }
                                    else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                            }
                            handleSubmitClick({
                                project_name: projectName,
                                project_description: projectDescription,
                                billing_country: billingCountry,
                                opportunity_name: opportunityName,
                                first_name: firstName,
                                last_name: lastName,
                                email: emailAddress,
                                attachments: getDocument
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateInternalProjectsModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateInternalProjectsModal);
