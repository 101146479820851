import React from 'react';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@material-ui/core';

const EditConnectionsPopup = ({
  editopenPopup,
  editsetOpenPopup,
  portName,
  setPortName,
  portSpeed,
  setPortSpeed,
  editerror,
  handleSubmit,
  modeldata,
  connectionType,
  setEditError,
  errors,
  classes
}) => {
  return (
    <>
      <Popup
        title="Edit Configured Services"
        openPopup={editopenPopup}
        setOpenPopup={editsetOpenPopup}>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={5}>
                  <div style={{ color: 'grey', fontWeight: '500' }}>
                    Connection Name
                  </div>
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={portName}
                    onChange={e => setPortName(e.target.value)}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                Rate Limit
              </Grid>
              <Grid item sm={1}>
                :
              </Grid>
              <Grid item sm={6}>
                <TextField
                  value={portSpeed}
                  onChange={e => setPortSpeed(e.target.value)}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                Link Type
              </Grid>
              <Grid item sm={1}>
                :
              </Grid>
              <Grid item sm={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  disabled
                  value={modeldata?.link_type}
                />
              </Grid>
            </Grid>
            {connectionType && connectionType === 'port-port' && (
              <>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>A-end VLAN</span>
                  </Grid>
                  <Grid item sm={1}>
                    :
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      disabled
                      fullWidth={true}
                      value={modeldata?.a_end_vlan_id}
                    />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>B-end VLAN</span>
                  </Grid>
                  <Grid item sm={1}>
                    :
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      disabled
                      value={modeldata?.b_end_vlan_id}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {connectionType && connectionType === 'fcr-port' && (
              <>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>B-end VLAN</span>
                  </Grid>
                  <Grid item sm={1}>
                    :
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth={true}
                      disabled
                      value={modeldata?.b_end_vlan_id}
                    />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>Subnet</span>
                  </Grid>
                  <Grid item sm={1}>
                    :
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      value={modeldata?.subnet}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {connectionType && connectionType === 'port-fcr' && (
              <>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>A-end VLAN</span>
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth={true}
                      disabled
                      value={modeldata?.a_end_vlan_id}
                    />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>Subnet</span>
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      disabled
                      fullWidth={true}
                      value={modeldata?.subnet}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {connectionType && connectionType === 'fcr-fcr' && (
              <>
                <Grid item container alignItems="center" justify="center">
                  <Grid item sm={5}>
                    <span>Subnet</span>
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      disabled
                      fullWidth={true}
                      value={modeldata?.subnet}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {errors && (
              <h4 style={{ color: 'red', textAlign: 'center' }}>{editerror}</h4>
            )}
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              editsetOpenPopup(false);
              setEditError('');
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={e => handleSubmit(modeldata?._id, e)}>
            Save Changes
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default EditConnectionsPopup;
