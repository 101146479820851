import React, {useState, useEffect} from 'react';
import CustomTabs from '../../components/CustomTabs';
import PropTypes from 'prop-types';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import {USER_ROLES, CURRENCIES, DEFAULT_ADDRESS } from '../../utils/constants';
import { GET_ORG_BILLING_UTILIZATION_ITEMS, GET_LAST_SIX_MONTH_ORG_INVOICE_REPORT, GET_ORG_INVOICE_REPORT, GET_ORGANIZATION_USER_DETAILS, GET_INVOICE_SAGEX3_LIST } from './queries';
import { CREATE_ORG_BILLING_INVOICE, UPDATE_ORG_BILLING_INVOICE_STATUS } from './mutations';
import Message from '../../components/Message';
import BillingUtilization from './BillingUtilization';
import Invoices from './Invoices';

const BillingPanel = ({ t, subscriptionList, organization }) => {

    const role = localStorage.getItem('role');
    const organizationName = localStorage.getItem('orgName');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allowConfigure, setAllowConfigure] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [billingData, setBillingData] = useState(null);
    const [paidBtnLoading, setPaidBtnLoading] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [invoiceChartData, setInvoiceChartData] = useState([]);
    const [invoiceReportList, setInvoiceReportList] = useState([]);
    const [loadingInvoiceData, setLoadingInvoiceData] = useState(false);
    const [invoiceDateFilter, setInvoiceDateFilter] = useState(null);
    const [invoiceCompanyDetails, setInvoiceCompanyDetails] = useState(null);  
    const [invoiceSageX3List, setInvoiceSageX3List] = useState([]);
    const [loadingInvoiceSageX3Data, setLoadingInvoiceSageX3Data] = useState(true);

    if(!organization){
        organization = localStorage.getItem("orgId");
    }

    const [getOrgnazationUserDetails] = useLazyQuery(GET_ORGANIZATION_USER_DETAILS, {
        fetchPolicy: "no-cache",
        onCompleted: userData => {
            if (userData.getOrgnazationUserDetails) {
                let dtls = userData.getOrgnazationUserDetails;
                let countryDtl = DEFAULT_ADDRESS;
                if(dtls.organization && dtls.organization.country){
                    countryDtl = JSON.parse(dtls.organization.country);
                    countryDtl.email = (countryDtl.emails) ? JSON.parse(countryDtl.emails).sales : "support@inq.com";
                }
                setInvoiceCompanyDetails(countryDtl);
                setUserInfo(userData.getOrgnazationUserDetails);
            }
        }
    });
    
    const [getOrgBillingUtilizationItems] = useLazyQuery(GET_ORG_BILLING_UTILIZATION_ITEMS, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setLoadingData(false);
            if (data.getOrgBillingUtilizationItems && data.getOrgBillingUtilizationItems.data) {
                const result = JSON.parse(data.getOrgBillingUtilizationItems.data);
                if (result.invoice && result.invoice.length > 0) {
                    let invoiceData = result.invoice[0];
                    if (invoiceData.currency) {
                        let curncy = currencyList.filter(itm => itm.code === invoiceData.currency.code);
                        setCurrency(curncy.length > 0 ?  curncy[0] : currencyList.length > 0 ? currencyList[0] : null);
                    } else {
                        setCurrency(currencyList.length > 0 ? currencyList[0] : null);
                    }
                }else{
                    setCurrency(currencyList.length > 0 ? currencyList[0] : null);
                }
                setBillingData(result);
            }
        },
        onError: error => {
            setLoadingData(false);
            Message.error(error);
        }
    });

    const [getLastSixMonthOrgInvoiceReport] = useLazyQuery(GET_LAST_SIX_MONTH_ORG_INVOICE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: invoiceData => {
            if (invoiceData.getLastSixMonthOrgInvoiceReport && invoiceData.getLastSixMonthOrgInvoiceReport) {
                setInvoiceChartData(invoiceData.getLastSixMonthOrgInvoiceReport);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getOrgInvoiceReport] = useLazyQuery(GET_ORG_INVOICE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: invoiceData => {
            if (invoiceData.getOrgInvoiceReport && invoiceData.getOrgInvoiceReport) {
                setInvoiceReportList(invoiceData.getOrgInvoiceReport);
            }
            setLoadingInvoiceData(false);
        },
        onError: error => {
            setLoadingInvoiceData(true);
            Message.error(error);
        }
    });

    const [createOrgBillingInvoice] = useMutation(CREATE_ORG_BILLING_INVOICE, {
        onError: error => {
            setSaveBtnLoading(false);
            console.log('Error', error);
            Message.error(error);
        },
        onCompleted: data => {
            setSaveBtnLoading(false);
            if (data.addOrgBillingInvoice) {
                Message.success('Invoice create successfully');
                setLoadingData(true);
                let date = startDate;
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                return getOrgBillingUtilizationItems({
                    variables: {
                        organizationId: organization,
                        startDate: firstDay.getTime() / 1000,
                        endDate: lastDay.getTime() / 1000
                    }
                });
            }
        }
    });

    const [updateOrgBillingInvoice] = useMutation(UPDATE_ORG_BILLING_INVOICE_STATUS, {
        onError: error => {
            console.log('Error', error);
            Message.error(error);
        },
        onCompleted: data => {
            if (data.updateOrgBillingInvoice) {
                Message.success('Invoice paid successfully');
                setPaidBtnLoading(false);
                let date = startDate;
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                getOrgBillingUtilizationItems({
                    variables: {
                        organizationId: organization,
                        startDate: firstDay.getTime() / 1000,
                        endDate: lastDay.getTime() / 1000
                    }
                });
                setLoadingInvoiceData(true);
                getLastSixMonthOrgInvoiceReport({
                    variables: {
                        organizationId: organization
                    }
                });
                getOrgInvoiceReport({
                    variables: {
                        organizationId: organization
                    }
                });
            }
        }
    });

    const [getInvoice] = useLazyQuery(GET_INVOICE_SAGEX3_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoadingInvoiceSageX3Data(false);
            // console.log("invoiceSageX3 data : ", data);
            if (data && data.invoiceSageX3) {
                let parseResult = JSON.parse(data.invoiceSageX3.teamListData);
                // console.log("parseResult invoiceSageX3 :", parseResult);
                setInvoiceSageX3List(parseResult);
            } else {
                setInvoiceSageX3List([])
            }
        },
        onError: error => {
            setLoadingInvoiceSageX3Data(false);
            console.log("error in invoice sagex3 API:", error)
        }
    });

    useEffect(() => {
        setCurrencyList(CURRENCIES);
        if (role && (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.IP_COUNTRYADMIN))) {
            setAllowConfigure(true);
        }
        setLoadingData(true);
        let date = startDate;
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        getOrgBillingUtilizationItems({
            variables: {
                organizationId: organization,
                startDate: firstDay.getTime() / 1000,
                endDate: lastDay.getTime() / 1000
            }
        });
        getOrgnazationUserDetails({
            variables: {
                organizationId: organization
            }
        });
        setLoadingInvoiceData(true);
        getLastSixMonthOrgInvoiceReport({
            variables: {
                organizationId: organization
            }
        });
        getOrgInvoiceReport({
            variables: {
                organizationId: organization
            }
        });
        getInvoice({
            variables: {
                organizationName: organizationName,
                links: ''
            }
        })
    }, []);

    const handleChangeDateFilter = (date) => {
        setLoadingData(true);
        var y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        setStartDate(lastDay);
        getOrgBillingUtilizationItems({
            variables: {
                organizationId: organization,
                startDate: firstDay.getTime() / 1000,
                endDate: lastDay.getTime() / 1000
            }
        });
    }

    const handleSubmitInvoice = (invoiceData) => {
        if (!invoiceData) {
            return null;
        }
        setSaveBtnLoading(true);
        return createOrgBillingInvoice({
            variables: {
                data: JSON.stringify({...invoiceData, organizationId: organization, currency})
            }
        });
    }

    const handleChangeCurrency = (val) => {
        setCurrency(val);
    }

    const handlePaidInvoice = (data) => {
        setPaidBtnLoading(true);
        return updateOrgBillingInvoice({variables: data});
    }

    const handleChangeInvoiceDateFilter = (date) => {
        setInvoiceDateFilter(date);
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let invoiceDate = month + "/" + year;
        setLoadingInvoiceData(true);
        getOrgInvoiceReport({
            variables: {
                organizationId: organization,
                invoiceDate
            }
        });
    }
    const handleSageInvoiceTableNextClick = (links) => {
        setLoadingInvoiceSageX3Data(true);
        if(links != '' && links != null){
            getInvoice({
                variables: {
                    organizationName: organizationName,
                    links: links
                }
            })
        }
    }
    let configTabs = [
        {
            label: t('utilization.label'),
            children: (
                <>
                    <BillingUtilization loadingData={loadingData} billingData={billingData} allowConfigure={allowConfigure} startDate={startDate} currency={currency} handleChangeCurrency={handleChangeCurrency} currencyList={currencyList} t={t} handleSubmitInvoice={handleSubmitInvoice} handlePaidInvoice={handlePaidInvoice} paidBtnLoading={paidBtnLoading} saveBtnLoading={saveBtnLoading} handleChangeDateFilter={handleChangeDateFilter}/>
                </>
            )
        },
        {
            label: t('invoices.label'),
            children: (
                <>
                        <Invoices t={t} userInfo={userInfo ? userInfo : null} startDate={invoiceDateFilter} handleChangeDateFilter={handleChangeInvoiceDateFilter} loadingData={loadingInvoiceData} invoiceChartData={invoiceChartData} invoiceReportList={invoiceReportList} invoiceCompanyDetails={invoiceCompanyDetails} invoiceSageX3List={invoiceSageX3List} loadingInvoiceSageX3Data={loadingInvoiceSageX3Data} handleSageInvoiceTableNextClick={handleSageInvoiceTableNextClick} />
                </>
            )
        }
    ];

    return (
        <div>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            />
        </div>
    );
};
BillingPanel.propTypes = {
    t: PropTypes.func.isRequired,
    // data: PropTypes.object.isRequired,
    // handleCreateButtonClick: PropTypes.func.isRequired
};
export default BillingPanel;