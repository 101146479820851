import React, {useState} from 'react'
import Table from '@material-ui/core/Table';
import { get } from "lodash-es";
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';

import OcrDocUploadForm from '../OcrDocUploadForm';
import OcrMultipleDocUpload from '../OcrMultipleDocUpload';
import SearchBox from '../../../../../components/SearchBox';
import OcrListingItem from './OcrListingItem';
import {marginGenerator} from '../../../../../theme/utils';

const OcrListingPage = ({
    t,
    ocrSubmitLoading,
    handleSubmitOfOcrParse,
    ocrData,
    ocrFetchLoading,
    handleSearch,
    uploadOcrDoc,
    isOcrFormEnable,
    handleOcrFormClose,
    ocrRuleData,
    isMultipleOcrDocUploadenable,
    uploadMultipleOcrDoc,
    handleMultipleOcrDocUploadFormClose,
    handleSubmitOfMultipleUpload,
    ocrMultipleDocLoading,
    dashboardPanelData,
    page,
    handlePageChange,
    organizationId,
    handleFilterdataForOptions,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    loadingOcrFolder
}) => {

    let columns = [
        {
            label: 'Document Name',
            key: "name"
        },
        {
            label: t('createdDate.label'),
            key: "date"
        },
        {
            label: t('createdBy.label'),
            key: "by"
        },
        {
            label: 'Labels Extract',
            key: "extract"
        },
        {
            label: t('action.label'),
            key: "action"
        }
    ];

    const handleSearchChange = (val) => handleSearch(val);
    return (
        <>  
            <Grid container style={{...marginGenerator("mb-20")}}>
                <Grid item xs="2">
                    <SearchBox type="wide" placeholderText="Search FileName / label" handleSearch={handleSearchChange} />
                </Grid>
                <Grid item xs="6"/>
                <Grid item xs="2">
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadOcrDoc}
                            style={{...marginGenerator('ml-10'), float:"right"}}
                        >
                            {t('createNewParser.label')}
                        </Button>
                </Grid>
                <Grid item xs="2">
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadMultipleOcrDoc}
                            style={{...marginGenerator('ml-10'), float:"right"}}
                        >
                            Upload Multiple Doc
                        </Button>
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ocrFetchLoading ?
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'} style={{textAlign: "center"}}><CircularProgress size={25}/></Typography>
                                    </TableCell>
                                </TableRow>
                            :
                                <>
                                    {ocrData && ocrData.data.length > 0 ? ocrData.data.map((rowitem, rowIndex) => (
                                        <OcrListingItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            organizationId={organizationId}
                                        />
                                    )) :
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                    { !ocrFetchLoading &&
                    <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                    <Grid container item xs={2}>
                    <span>Total {Math.ceil(get(ocrData, 'count', null)/20)} page,  {get(ocrData, 'count', null)} Row</span>
                    </Grid>
                    <Grid container item xs={2}>
                        <Pagination 
                            color="secondary"
                            count={ocrData.count ? Math.ceil(ocrData.count/20) : 0} 
                            page={page} 
                            onChange={(evt, value) => {
                                handlePageChange("ocr_document", value);
                            }}
                        />
                    </Grid>
                  </Grid>
                  </>
                    }
                </TableContainer>
            </Grid>
            <OcrDocUploadForm
                t={t}
                open={isOcrFormEnable}
                onClose={handleOcrFormClose}
                ocrSubmitLoading={ocrSubmitLoading}
                handleSubmitOfOcrParse={handleSubmitOfOcrParse}
                handleFilterdataForOptions={handleFilterdataForOptions}
                ocrPlaceData={ocrPlaceData}
                ocrProjectData={ocrProjectData}
                ocrFolderData={ocrFolderData}
                loadingOcrPlace={loadingOcrPlace}
                loadingOcrProject={loadingOcrProject}
                loadingOcrFolder={loadingOcrFolder}
            />
            <OcrMultipleDocUpload
                t={t}
                open={isMultipleOcrDocUploadenable} 
                onClose={handleMultipleOcrDocUploadFormClose}   
                ocrRuleData={ocrRuleData}  
                handleSubmitOfMultipleUpload={handleSubmitOfMultipleUpload}
                ocrMultipleDocLoading={ocrMultipleDocLoading}
                handleFilterdataForOptions={handleFilterdataForOptions}
                ocrPlaceData={ocrPlaceData}
                ocrProjectData={ocrProjectData}
                ocrFolderData={ocrFolderData}
                loadingOcrPlace={loadingOcrPlace}
                loadingOcrProject={loadingOcrProject}
                loadingOcrFolder={loadingOcrFolder}
            />  
        </>
    )
}

export default OcrListingPage
