import React, { useState } from 'react';
import { get } from "lodash-es";
import { makeStyles } from '@material-ui/core/styles';
import { marginGenerator } from '../../../../theme/utils';
import PriorityPanel from '../PriorityPanel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
    Icon,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TableContainer,
    Typography,
    Button,
    Avatar,
    CircularProgress
} from '@material-ui/core';
import commonStyles from '../../../../theme/commonStyles';
import PlacesWithMap from '../PlacesWithMap';
import { dateFormatterWithTimeZoneLLLHHMMSS, renderEdgeAITags } from '../../../../utils';
import Pagination from '@material-ui/lab/Pagination';
import Input from '@material-ui/core/Input';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import DateTimeSelector from '../../../../components/DateTimeSelector';
import Autocomplete from '../../../../components/Autocomplete';
import videoIcon from '../../../../assets/icons/video-icon.png';
import InqLogo from '../../../../assets/icons/image.png';

const EventsPanel = ({
    t,
    filterList,
    handleImageModalClick,
    page,
    handlePageChange,
    handleShowEventTags,
    loading,
    filterLoading,
    list
}) => {

    const columns = [
        {
            label: t('places.label'),
            key: "place"
        },
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('eventDateTime.label'),
            key: "eventDateTime"
        },
        {
            label: t('detectedModel.label'),
            key: "detectedModel"
        },
        {
            label: t('priority.label'),
            key: "priority"
        },
        {
            label: t('tags.label'),
            key: "tags"
        },
        {
            label: t('imageSnap.label'),
            key: "imageSnap"
        }
    ];
    const commonClasses = commonStyles();

    const [locationState, setLocationState] = useState({ latitude: '', longitude: '' });
    const [isMapEnable, setIsMapEnable] = useState(false);
    const [goToPage, setGoToPage] = useState(null);
    const [timeZoneData, setTimeZoneData] = useState('');

 
    const handleSetPositionBase = pos => {
        setLocationState({ latitude: pos.latitude, longitude: pos.longitude });
    };

    const handleClose = () => {
        setIsMapEnable(false);
    };

    React.useEffect(() => {
       const timeDetails = localStorage.getItem('timeZoneDetails');
       console.log('timeDetails', timeDetails);
       if(timeDetails){
        setTimeZoneData(timeDetails)
       }
    }, []);
    
   return (
        <div>
            {filterList &&
                filterList.map((filter, index) => {
                    if (filter.type && filter.type == 'input') {
                        return (
                            <DateTimeSelector key={index} inputStyle={{ padding: 10, minWidth: 225 }} label={filter.label} dateFormat={"yyyy-MM-dd HH:mm"} value={filter.val} handleChange={filter.handleChange} styleOverrides={filter.styleOverrides} className={filter.className ? filter.className : null} disableFuture={true} />
                        )
                    } else if (filter.type && filter.type === "autocomplete") {
                        return (
                            <Autocomplete
                                key={index}
                                styleOverrides={filter.styleOverrides || null}
                                handleChange={filter.handleChange}
                                options={filter.options}
                                label={filter.label}
                                size="small"
                                onInputChange={filter.onInputChange}
                            />
                        );
                    } else {
                        return (
                            <OutlinedSelect
                                key={index}
                                val={filter.val}
                                label={filter.label}
                                handleChange={filter.handleChange}
                                IconComponent={filter.iconComponent || null}
                                options={filter.options}
                                styleOverrides={filter.styleOverrides || null}
                                disabled={filter.disabled || false}
                            />
                        )
                    }
                })
            }
            <>
                <Grid container style={{ ...marginGenerator('mt-24') }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item, index) => (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!filterLoading ?
                                    list && list.events.length > 0 ?
                                        list.events.map((rowitem, rowIndex) => {
                                            return (
                                                <TableRow key={rowIndex}>
                                                    <TableCell key={rowIndex}>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Icon
                                                                    component={() => (
                                                                        <LocationOnIcon
                                                                            color={'secondary'}
                                                                            style={{ marginTop: '2px', marginRight: '6px' }}
                                                                        />
                                                                    )}
                                                                    alt={'icon'}
                                                                />
                                                            </Grid>
                                                            <Grid>
                                                                <Typography
                                                                    variant={'subtitle1'}
                                                                    onClick={() => {
                                                                        setLocationState({ latitude: rowitem.lat, longitude: rowitem.lng });
                                                                        setIsMapEnable(true);
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {rowitem.place_name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}> {rowitem.camera_name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                {dateFormatterWithTimeZoneLLLHHMMSS(rowitem.event_time, localStorage.getItem('timeZoneDetails'))}
                                                                   {/* {dateFormatterLLLHHMMSS(rowitem.created_at)} */}
                                                                    {/* {dateFormatterLLLHHMMSS((rowitem.event_time && rowitem.event_time.includes("Z")) ? rowitem.event_time : rowitem.event_time + "Z")} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Typography variant={'subtitle1'}>
                                                                    {rowitem.type}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <PriorityPanel priority={rowitem.priority == 1 ? 'P1' : rowitem.priority == 2 ? 'P2' : 'P3'} t={t} />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <Button 
                                                                style={{width: 120, overflow: "hidden"}}
                                                                color="primary" 
                                                                onClick={() => handleShowEventTags(rowitem.tags)}>
                                                                    {renderEdgeAITags(rowitem.tags)}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                            <Grid>
                                                                <img
                                                                    onClick={(evt) => {
                                                                        if(rowitem.image){
                                                                            handleImageModalClick(rowitem.image);
                                                                        }
                                                                        return null;
                                                                    }}
                                                                    src={rowitem.image ? rowitem.image.includes("mp4") ? videoIcon : rowitem.image : InqLogo}
                                                                    alt={'event'}
                                                                    style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: "50%" }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        )
                                        :
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Record Not Found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                                            <CircularProgress color="secondary" />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        {!loading &&
                            <>
                                <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Grid container item xs={2}>
                                        <span>Total {Math.ceil(get(list, 'count', null) / 20)} page,  {get(list, 'count', null)} Row</span>
                                    </Grid>
                                    <Grid container item xs={2}>
                                        <Pagination
                                            color="secondary"
                                            count={Math.ceil(list.count / 20)}
                                            page={page}
                                            onChange={handlePageChange} 
                                        />
                                    </Grid>
                                    <Grid container item xs={1}>
                                        <span>Go to page </span>
                                    </Grid>
                                    <Grid container item xs={4}>
                                        <Input
                                            value={goToPage}
                                            type={'text'}
                                            style={{ width: '10%' }}
                                            color={'secondary'}
                                            onChange={evt => setGoToPage(evt.target.value)}
                                        />
                                        <Button
                                            onClick={() => handlePageChange('goto', parseInt(goToPage))}
                                            variant={'outlined'}
                                            color="primary"
                                            style={{ marginLeft: 10 }}
                                        >
                                            Go
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </TableContainer>
                </Grid>
                <PlacesWithMap handleSetPositionBase={handleSetPositionBase} isMapEnable={isMapEnable} handleClose={handleClose} locationState={locationState} />
            </>
        </div>
    )
}

export default EventsPanel
