import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const initFormState = {customerName: '', amount: '', description: '', remark: ''};

function CreateTransectionModal({
    t,
    open,
    handleCreateTransectionSubmit,
    handleClose,
    modalState
}) {
    const [transFormState, setTransFormState] = useState({...initFormState});
    const classes = useStyles();

    const handleInputChange = (value, field) => {
        setTransFormState({...transFormState, [field]: value});
    }

    React.useEffect(() => {
        if(modalState.clearData && open){
          setTransFormState({...initFormState});
          handleClose();
        }
    }, [modalState]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="node-modal"
            aria-describedby="node-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="node-modal-title">{t('createNew.label')} {t('transaction.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={transFormState.customerName}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('customerName.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'customerName')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={transFormState.amount}
                                type={'number'}
                                className={classes.input}
                                placeholder={`${t('amount.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'amount')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={transFormState.description}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('description.label')}`}
                                style={{width: '100%'}}
                                multiline={true}
                                rows={2}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'description')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <Input
                                value={transFormState.remark}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('remark.label')} (Maximum 30 characters)`}
                                style={{width: '100%'}}
                                multiline={true}
                                rows={2}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'remark')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!transFormState.customerName || !transFormState.amount){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateTransectionSubmit({...transFormState});
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateTransectionModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreatePlaceSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateTransectionModal);
