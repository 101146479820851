import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import '../../styles.css';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../../src/components/Message';
import Divider from '@material-ui/core/Divider';
import { REQUIRED_ERROR, COUNTRY_LIST } from '../../../../utils/constants';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { EQUIPMENT_REPLACED } from '../../../../utils/constants';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from '../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

let rfoCategory = [
  {
    key: '1',
    name: 'Normal Ticket'
  },
  {
    key: '2',
    name: 'Parent Ticket'
  }
];

function CloseTicketModal({
  open,
  handleClose,
  handleSubmitCloseTicket,
  buttonLoading,
  clearModal,
  type,
  country,
  rfoList,
  ticketData,
  t
}) {
  const [endDate, setendDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [equipemntReplaced, setEquipemntReplaced] = useState('');
  const [extraNote, setExtraNote] = useState('');
  const [rfo, setRfo] = useState([]);
  const [vendorEndDate, setVendorEndDate] = useState(new Date());

  useEffect(() => {
    if (clearModal) {
      setDescription('');
      setExtraNote('');
      setendDate(new Date());
      setVendorEndDate(new Date());
      setEquipemntReplaced('')
    }
  }, [clearModal]);

  const handleSubmitData = () => {
    if (description && type == 'single') {
      let startDate = new Date(ticketData.createdAt)
      let closeDate = new Date(endDate)
      let vendorStartDate = new Date(ticketData.vendorStartDate)
      let vendorCloseDate = ticketData.vendorStartDate != null ? new Date(vendorEndDate) : ticketData.vendorEndDate
    
      if(startDate > closeDate){
        Message.error("Ticket Close date must be greater then ticket create date");
        return null;
      }
      if(ticketData.vendorStartDate != null && vendorStartDate > vendorCloseDate){
        Message.error("Vendor Close date must be greater then Vendor start date");
        return null;
      }
      if(equipemntReplaced == ''){
        Message.error("Please select Equipment Replace value");
        return null;
      }

      if(country !== 'null' && country === COUNTRY_LIST.CAMEROON && rfo.length == 0){
        Message.error("Please select RFO");
        return null;
      }

      if(country !== 'null' && country === COUNTRY_LIST.CAMEROON && rfo.name){
        handleSubmitCloseTicket({ description, endDate, rfo, extraNote, vendorCloseDate, equipemntReplaced });
      }else{
        handleSubmitCloseTicket({ description, endDate, extraNote, vendorCloseDate, equipemntReplaced });
      }

    }else if(description && type == 'closeMultiple'){
      let closeDate = new Date(endDate)

      handleSubmitCloseTicket({ description, endDate, extraNote });
    }else {
      Message.error(REQUIRED_ERROR);
    }
  };
  const classes = useStyles();

  const handleChangeRFO = (event) => {
    setRfo(event.target.value)
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add Ticket Description before closing
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('reportComment.label')} *
              </Typography>
              <TextField
                type="textarea"
                placeholder="Describe what was done to restore."
                variant="standard"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={4}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('extraNotes.label')}
              </Typography>
              <TextField
                type="textarea"
                placeholder="Extra note."
                variant="standard"
                name="extraNote"
                value={extraNote}
                onChange={e => setExtraNote(e.target.value)}
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={4}
              />
            </Grid>

            { type == 'single' && country !== 'null' && country === COUNTRY_LIST.CAMEROON && (
             <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                <OutlinedSelect
                  label={"RFO"}
                  handleChange={handleChangeRFO}
                  val={rfo.name}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%' }}
                  options={rfoList ? rfoList : []}
                  id="standard-select"
                />
              </Grid>
            )}

            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <div className="datePicker">
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {t('endDate.label')}
                </Typography>
                <DatePicker
                  timeIntervals={30}
                  selected={endDate}
                  startDate={endDate}
                  endDate = {new Date()}
                  maxDate={new Date()}
                  onChange={date => setendDate(date)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy HH:mm"
                  showTimeInput
                />
              </div>
            </Grid>
            {type == 'single' && ticketData && ticketData.vendorStartDate && ticketData.vendorStartDate != null ? (
              <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                <div className="datePicker">
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    style={{
                      ...marginGenerator('mb-8'),
                      ...marginGenerator('mt-40')
                    }}>
                    {t('vendorEndDate.label')}
                  </Typography>
                  <DatePicker
                    timeIntervals={30}
                    selected={vendorEndDate}
                    onChange={date => setVendorEndDate(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                    startDate={vendorEndDate}
                    endDate={new Date()}
                    maxDate={new Date()}
                  />
                </div>
              </Grid>
              ): null
            }
            { type == 'single' && (
             <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Inq equipment replaced
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={equipemntReplaced}
                    className="his"
                    style={{ width: '100%' }}
                    placeholder="Please select equipemnt Replaced"
                    onChange={event => setEquipemntReplaced(event.target.value)}>
                    <MenuItem value="" hidden selected>
                      Select Equipment Replaced
                    </MenuItem>

                    <MenuItem value={EQUIPMENT_REPLACED.YES}>
                      {EQUIPMENT_REPLACED.YES}
                    </MenuItem>
                    <MenuItem value={EQUIPMENT_REPLACED.NO}>
                      {EQUIPMENT_REPLACED.NO}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
           </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(CloseTicketModal);
