import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const CategoryItem = ({
  t,
  data,
  handleMenuItemClick,
  menuItems
}) => {

  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let emails = (data.emails) ? JSON.parse(data.emails) : null;

  return (
    <TableRow key={data.id} >
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > 
                        {data.name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} >
                        {data.createdAt ? dateFormatterLLL(data.createdAt) : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid >
                <IconButton
                    size={'small'}
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'secondary'}
                    onClick={evt => {
                        setAnchorEl(evt.currentTarget);
                        evt.stopPropagation();
                    }}
                >
                <MoreHoriz className={commonClasses.iconRegular} />
                </IconButton>
            </Grid>
            {anchorEl && <MenuWrapper
                id={'ticket-menu'}
                anchorEl={anchorEl}
                handleClose={evt => {
                    evt.stopPropagation();
                    setAnchorEl(null);
                }}
                menuItems={menuItems}
                handleMenuItemClick={elem => {
                    handleMenuItemClick(elem, data);
                    setAnchorEl(null);
                }}
            />}
        </TableCell>
    </TableRow>
  );
};

CategoryItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func
};

export default withTranslation()(CategoryItem);
