import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const createClient = async payload => {
  try {
    const response = await axios.post('client', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getAllClientData = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `client?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getClientById = async clientId => {
  try {
    const response = await axios.get(`client/${clientId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const updateClientById = async (clientId, payload) => {
  try {
    const response = await axios.put(`client/${clientId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deleteClient = async clientId => {
  try {
    const response = await axios.delete(`client/${clientId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getStatusForLinkType = async payload => {
  try {
    const response = await axios.post('connection/link/link_type', payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getAllMinTerm = async () => {
  try {
    const response = await axios.get(`term`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getClientListByNameFilter = async (page = 1, limit = 10, word = '') => {
  try {
    const response = await axios.get(
      `client?limit=${limit}&page=${page}&search=${word}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

export {
  createClient,
  getAllClientData,
  getClientById,
  updateClientById,
  deleteClient,
  getStatusForLinkType,
  getAllMinTerm,
  getClientListByNameFilter
};
