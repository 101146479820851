import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';

const RadioEngineerItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
}) => {
    const commonClasses = commonStyles();
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.case_number ? data.case_number : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.customer_name} / {data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.install_team}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    <OutlinedSelect
                        label={`${t('Select Action')}`}
                        val={data.is_available ? 'Assign_project' : ''}
                        disabled={data.is_available ? true : false}
                        handleChange={evt => {
                            handleMenuItemClick(evt.target.value, data);
                        }}
                        styleOverrides={{ width: '50%', marginRight: 2 }}
                        options={menuItems ? menuItems : []}
                    />
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
RadioEngineerItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(RadioEngineerItem);