import React from 'react';
import { useHistory } from 'react-router-dom';
import ConnectionTable from './ConnectionTable';
import { Button, Grid } from '@material-ui/core';
import { useAuth } from '../../../contexts/AuthContext';

export default function Connection(props) {
  let history = useHistory();
  const { loggedInUserRoles } = useAuth();

  const handleConnection = () => {
    localStorage.setItem('createConnection', 'yes');
    history.push('/fabricService/CreateConnection');
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <br />
        <Grid item xs={12} style={{ marginLeft: '10px' }}>
          <Button
            variant="outlined"
            disabled={
              loggedInUserRoles?.includes('fabric_readonly') === false
                ? false
                : true
            }
            onClick={() => handleConnection()}>
            Create Connection
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ConnectionTable />
        </Grid>
      </Grid>
    </>
  );
}
