import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import {
  UPDATE_USER_LIST_ITEM,
  ADD_USER_LIST_ITEM,
  DELETE_USER_ITEM
} from '../mutations';
import { GET_ALL_ADDITIONAL_AGENT_LIST } from '../queries';
import Layout from '../../../components/Layout';
import Pagination from '@material-ui/lab/Pagination';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceManager } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import AdditionalAgentItem from '../components/AdditionalAgent';
import Message from '../../../components/Message';
import EditAdditionalAgent from '../EditAdditionalAgent';
import AddAdditionalAgent from '../AddAdditionalAgent';

const breadcrumbList = [
  { label: 'home.label', link: ServiceManager.Dashboard },
  { label: 'users.label', link: ServiceManager.users }
];

const AgentList = ({ t }) => {
  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [agentListData, setAgentListData] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [clearAddModal, setClearAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoadingAdd, setButtonLoadingAdd] = useState(false);
  const [openUserAddModal, setOpenUserAddModal] = React.useState(false);

  const [editModalData, setEditModalData] = useState([]);

  const menuItems = [{ label: t('edit.label') }, { label: t('delete.label') }];

  const history = useHistory();
  const location = useLocation();

  const [getAdditionalAgent] = useLazyQuery(GET_ALL_ADDITIONAL_AGENT_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.allUserPagination &&
        data.allUserPagination.data.length
      ) {
        setAgentListData(data.allUserPagination.data);
        setTotalCount(data.allUserPagination.total);
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    if (location.search === `?action=reload`) {
      getAdditionalAgent({
        variables: {
          page: page
        }
      });
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    getAdditionalAgent({
      variables: {
        page: page
      }
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getAdditionalAgent({
      variables: {
        page: value
      }
    });
  };

  const [deleteAdditionalAgent] = useMutation(DELETE_USER_ITEM, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Additional Agent Deleted successfully.');
      getAdditionalAgent({
        variables: {
          page: page
        }
      });
    }
  });

  const [updateAdditionalAgent] = useMutation(UPDATE_USER_LIST_ITEM, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('Additional Agent updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      getAdditionalAgent({
        variables: {
          page: page
        }
      });
    }
  });

  const [addAdditionalAgent] = useMutation(ADD_USER_LIST_ITEM, {
    onError: error => {
      setButtonLoadingAdd(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoadingAdd(false);
      Message.success('Incident added successfully');
      setOpenUserAddModal(false);
      setClearAddModal(true);
      getAdditionalAgent({
        variables: {
          page: page
        }
      });
    }
  });

  let AgentListing = agentListData && agentListData.length && agentListData;

  const handleMenuItemClick = (action, data) => {
    if (action.label === 'Delete') {
      if (
        window.confirm(
          'Are you sure? you want to delete this Additional Agent!'
        )
      ) {
        return deleteAdditionalAgent({
          variables: {
            id: data._id
          }
        });
      }
    }
    if (action.label === 'Edit') {
      setOpenUserModal(true);
      setEditModalData(data);
    }
  };

  const handleSubmit = formData => {
    setButtonLoading(true);
    return updateAdditionalAgent({
      variables: {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.emailAddress,
        status: formData.status
      }
    });
  };

  const handleSubmitAdd = formData => {
    setButtonLoadingAdd(true);
    return addAdditionalAgent({
      variables: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.emailAddress,
        status: formData.status
      }
    });
  };

  const columns = [
    {
      label: t('firstName.label'),
      key: 'firstName'
    },
    {
      label: t('lastName.label'),
      key: 'lastName'
    },
    {
      label: t('emailAddress.label'),
      key: 'emailAddress'
    },
    {
      label: t('status.label'),
      key: 'status'
    }
  ];

  const handleAddNewSubAdminClick = () => {
    setOpenUserAddModal(true);
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('additionalAgent.label')}
        subText={`${t('additionalAgentSubTxt.label')}`}
        primaryButtonText={t('addAdditionalAgent.label')}
        handlePrimaryButtonClick={handleAddNewSubAdminClick}
      />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                AgentListing.length > 0 ? (
                  AgentListing.map((rowitem, rowIndex) => (
                    <AdditionalAgentItem
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                      menuItems={menuItems}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant={'subtitle2'}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ width: '100%', marginTop: 24, marginBottom: 24 }}>
          <Pagination
            style={{ float: 'right' }}
            count={Math.ceil(totalCount / 25)}
            color="secondary"
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </Grid>
      <EditAdditionalAgent
        open={openUserModal}
        handleClose={evt => setOpenUserModal(false)}
        handleSubmit={handleSubmit}
        buttonLoading={buttonLoading}
        clearModal={clearModal}
        data={editModalData}
      />
      <AddAdditionalAgent
        open={openUserAddModal}
        handleClose={evt => setOpenUserAddModal(false)}
        handleSubmitAdd={handleSubmitAdd}
        buttonLoadingAdd={buttonLoadingAdd}
        clearModal={clearAddModal}
      />
    </Layout>
  );
};
export default withTranslation()(AgentList);
