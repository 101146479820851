import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../../theme/colors';
import Typography from '@material-ui/core/Typography';
import { marginGenerator } from '../../../../../theme/utils';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
                {loading && <CircularProgress size={14} />}
                {!loading && t('submit.label')}
        </Button>
    );
}

const initProd = { name: "", avgUptime: "", description: "", status: "Active", enableAvgCost: "No", avgCost: "" };

function CreateServiceProductModal({
    t,
    open,
    data,
    handleCreateNewProductSubmit,
    handleClose,
    modalState
}) {
    const [productState, setProductState] = useState({...initProd});
    const classes = useStyles();
    
    React.useEffect(() => {
        if(modalState.clearData && open){
            setProductState({...initProd});
        }
    }, [modalState]);

    React.useEffect(() => {
        if(data && data._id){
            setProductState({name: data.name, status: data.status, description: data.description, avgUptime: data.avgUptime, avgCost: data.avgCost, enableAvgCost: data.enableAvgCost ? "Yes" : "No"});
        }
    }, [data]);

    const handleChangeValue = (evt) => {
        const {name, value} = evt.target;
        setProductState({...productState, [name]: value});
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="place-template-title">{t('createNew.label')} {t('product.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <Input
                            value={productState.name}
                            type={'text'}
                            placeholder={`${t('product.label')} ${t('name.label')}*`}
                            style={{width: '100%'}}
                            color={'secondary'}
                            name="name"
                            onChange={handleChangeValue}
                        />
                    </Grid>
                    <Grid item xs={6}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <OutlinedSelect
                            val={productState.status}
                            label={`${t('status.label')} *`}
                            handleChange={evt => {
                                setProductState({...productState, status: evt.target.value})
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{ width: '100%', marginRight: 0 }}
                            options={["Active", "Inactive"]}
                        />
                    </Grid>
                    <Grid item xs={6}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <Input
                            value={productState.avgUptime}
                            type={'number'}
                            placeholder={`${t('productUpTime.label')}*`}
                            style={{minWidth: 215}}
                            color={'secondary'}
                            name="avgUptime"
                            onChange={handleChangeValue}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'subtitle2'}>{`${t('enabledCost.label')}`}</Typography>
                        <Radio
                            checked={productState.enableAvgCost === "Yes" ? true : false}
                            classes={{ root: classes.iconButton }}
                            style={{
                            ...marginGenerator('mr-25'),
                            ...(productState.enableAvgCost === "Yes"
                                ? { backgroundColor: colors.secondary.main }
                                : '')
                            }}
                            onChange={() => setProductState({...productState, enableAvgCost: "Yes"})}
                            value={"Yes"}
                            name="site-select"
                            checkedIcon={
                                <div className={classes.icon}>
                                    <Typography
                                    variant={'subtitle1'}
                                    style={{ color: colors.common.white }}>
                                    {"Yes"}
                                    </Typography>
                                </div>
                            }
                            icon={
                                <div>
                                    <Typography variant={'subtitle1'} color={'textPrimary'}>Yes</Typography>
                                </div>
                            }
                        />
                        <Radio
                            checked={productState.enableAvgCost === "No" ? true : false}
                            classes={{ root: classes.iconButton }}
                            style={{
                            ...marginGenerator('mr-25'),
                            ...(productState.enableAvgCost === "No"
                                ? { backgroundColor: colors.secondary.main }
                                : '')
                            }}
                            onChange={() => setProductState({...productState, enableAvgCost: "No"})}
                            value={"No"}
                            name="site-select"
                            checkedIcon={
                                <div className={classes.icon}>
                                    <Typography variant={'subtitle1'} style={{ color: colors.common.white }}>No</Typography>
                                </div>
                            }
                            icon={
                                <div className={classes.icon}>
                                    <Typography variant={'subtitle1'} color={'textPrimary'}>No</Typography>
                                </div>
                            }
                        />
                    </Grid>
                    {productState.enableAvgCost === "Yes" && 
                        <Grid item xs={6}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                            <Input
                                value={productState.avgCost}
                                type={'number'}
                                placeholder={`${t('avgCost.label')}*`}
                                style={{minWidth: 215, ...marginGenerator("mt-24")}}
                                color={'secondary'}
                                name="avgCost"
                                onChange={handleChangeValue}
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <TextField
                            style={{width:"100%"}}
                            id="outlined-textarea"
                            multiline
                            rows={4}
                            placeholder={t("description.label")}
                            value={productState.description}
                            name={"description"}
                            onChange={handleChangeValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!productState.name || !productState.avgUptime){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateNewProductSubmit(productState);
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateServiceProductModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateNewProductSubmit: PropTypes.func.isRequired,
    handleCreateNewProduct: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateServiceProductModal);