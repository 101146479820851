import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({

    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
     blackBorderTitle:{
        margin: '18px 9px',
    },
    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        font: 'black',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id:"",
    Sname:"",
    Scode:"",
    Scontact:"",
    Sphone:"",
    GoodsDesc:"",
    BSPurpose:"",
    TCost:"",
    AdvanceAmt:"",
    RqNo:"",
    PO:"",
    AdvDesc:"",
    Effort:"",
    MCMBenefit:"",
    DDate: null,
    AdvDate: null,
    Bname:"",
    BDate: null,
    BSignature:"",
    MaterialMan:"",
    MMDate: null,
    MMSignature:"",
    CommercialMan:"",
    CMDate: null,
    CMSignature:"",
    GeneralMan:"",
    GMDate: null,
    GMSignature:"",
};


const tableItems = [
    {
        key: 'long_term',
        value: 'Long Term',
        label: "Permanent / Fixed Term (long term)",
        description: "Permanent & FTC 12 months and above"
    },
    {
        key: 'short_term',
        value: 'Short term',
        label: "Fixed Term (short term)",
        description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
    },
    {
        key: 'casual',
        label: "Casual",
        value: "Casual",
        description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
    }
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function PrepaymentFormModal({
    t,
    open,
    handleClose,
    data,
    users,
    userdata,
    modalState,
    handlePrepaymentSubmit,
    handleOpenSignPanModal

})


{     const classes = useStyles();
      const [urFormState, setURFormState] = useState({ ...urInitFields });

      React.useEffect(() => {
        if (!open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
      }, [open, handleClose]);
    
      React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setURFormState({...data, id });
        }else{
            // setShowUserSelect(true);
            // setShowLearnDevelopment(true);
            // setShowHOD(true);
            // setShowHRManager(true);
            // setShowGeneralManager(true);
        }
    }, [data]);
  
    const handleChange = (value, name, key) => {
            setURFormState({ ...urFormState, [name]: value });
    }
  
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>


            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent >
                <h1>Supplier Advancement Payment Requisition Form</h1>
                <Grid container spacing={2} className={classes.greyBackgroundBorder} style={{ marginBottom: 20 }} >
                        <Grid className={classes.blackBorder}  item xs={12}>
                            <Typography variant="h4" >Supplier Information</Typography>
                        </Grid>
                         <Grid className={classes.blackBorder} item xs={4}> 
                          <h5>Supplier Name</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.Sname}
                                  onChange={(evt) => handleChange(evt.target.value, "Sname")}
                               />
                             </Grid>

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Supplier Code</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.Scode}
                                  onChange={(evt) => handleChange(evt.target.value, "Scode")}
                               />
                             </Grid>

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Supplier Contact</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.Scontact}
                                  onChange={(evt) => handleChange(evt.target.value, "Scontact")}
                               />
                             </Grid>

                          <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Supplier Phone</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.Sphone}
                                  onChange={(evt) => handleChange(evt.target.value, "Sphone")}
                               />
                             </Grid>

                           <Grid container spacing={2} style={{ marginBottom: 20 }} >
                                <Grid item xs={12}>
                                   <Typography variant="h4" >Procurement Information</Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Description of goods or services</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.GoodsDesc}
                                  onChange={(evt) => handleChange(evt.target.value, "GoodsDesc")}
                               />
                             </Grid>

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Business Purpose</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.BSPurpose}
                                  onChange={(evt) => handleChange(evt.target.value, "BSPurpose")}
                               />
                             </Grid>

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Total Cost</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.TCost}
                                  onChange={(evt) => handleChange(evt.target.value, "TCost")}
                               />
                             </Grid>
                             

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Amount of Advance</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.AdvanceAmt}
                                  onChange={(evt) => handleChange(evt.target.value, "AdvanceAmt")}
                               />
                             </Grid>
                             
                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Requisition No.</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.RqNo}
                                  onChange={(evt) => handleChange(evt.target.value, "RqNo")}
                               />
                             </Grid>


                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>MCM PO</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.PO}
                                  onChange={(evt) => handleChange(evt.target.value, "PO")}
                               />
                             </Grid>
                             
           
                             <Grid container spacing={2} style={{ marginBottom: 20 }} >
                                <Grid item xs={12}>
                                   <Typography variant="h4" >Advance Payment Information</Typography>
                                </Grid>
                            </Grid>


                            <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Description of Advance</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.AdvDesc}
                                  onChange={(evt) => handleChange(evt.target.value, "AdvDesc")}
                               />
                             </Grid>

                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Buyer's efforts to eliminate or reduce this advance</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.Effort}
                                  onChange={(evt) => handleChange(evt.target.value, "Effort")}
                               />
                             </Grid>


                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Benefit  to MCM for making the advance</h5>
                         </Grid>
                            <Grid className={classes.blackBorder} item xs={8}>
                               <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={urFormState.MCMBenefit}
                                  onChange={(evt) => handleChange(evt.target.value, "MCMBenefit")}
                               />
                             </Grid>

                             
                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Delivery date of goods or services</h5>
                         </Grid>
                         <Grid className={classes.blackBorder} item xs={8}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                              value={urFormState.DDate}
                                              handleChange={newDate => handleChange(newDate, "DDate")}
                                            />
                                        </Grid>
 
                             
                             <Grid className={classes.blackBorder} item xs={4}>
                          <h5>Date Advance Needed</h5>
                         </Grid>
                             <Grid className={classes.blackBorder} item xs={8}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                              value={urFormState.AdvDate}
                                              handleChange={newDate => handleChange(newDate, "AdvDate")}
                                            />
                                        </Grid>
 
                             <Grid container spacing={2} style={{ marginBottom: 20 }} >
                                <Grid item xs={12}>
                                   <Typography  variant="h4" >Approval</Typography>
                                </Grid>
                            </Grid>

                             <Grid className={classes.blackBorder} item xs={12}>
                             <FormGroup row>
                                <Grid className={classes.blackBorder} item xs={12}>
                                   <h5>Buyer's Name</h5>
                                </Grid>
                                <Grid className={classes.blackBorder} item xs={4}>
                                            <Input
                                              type={'text'}
                                              style={{ width: '100%' }}
                                              className={classes.input}
                                              color={'secondary'}
                                              value={urFormState.Bname}
                                              onChange={(evt) => handleChange(evt.target.value, "Bname")}
                                            />
                                        </Grid>
                                        <Grid className={classes.blackBorder} container  xs={4}>
                                            <Grid item xs={6} style={{ textAlign: 'right'}}>
                                                <IconButton
                                                onClick={() => handleOpenSignPanModal("BSignature")}
                                                >
                                                 Signature<img src={SignIcon} width="26" height={26} alt=""/>
                                                 
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid className={classes.blackBorder} item xs={4}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              value={urFormState.BDate}
                                              handleChange={newDate => handleChange(newDate, "BDate")}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                            />
                                        </Grid>
 
                                            
                                        <Grid className={classes.blackBorder} item xs={12}>
                                          <h5>Materials Manager</h5>
                                        </Grid>
                                        <Grid className={classes.blackBorder}  item xs={4}>
                                            <Input
                                              type={'text'}
                                              style={{ width: '100%' }}
                                              className={classes.input}
                                              color={'secondary'}
                                              value={urFormState.MaterialMan}
                                              onChange={(evt) => handleChange(evt.target.value, "MaterialMan")}
                                            />
                                        </Grid>

                                        <Grid className={classes.blackBorder} container  xs={4}>
                                            <Grid item xs={6} style={{ textAlign: 'right'}}>
                                                <IconButton
                                                onClick={() => handleOpenSignPanModal("MMSignature")}
                                                >
                                                 Signature<img src={SignIcon} width="26" height={26} alt=""/>
                                                 
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid className={classes.blackBorder}item xs={4}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                              value={urFormState.MMDate}
                                              handleChange={newDate => handleChange(newDate, "MMDate")}
                                            />
                                        </Grid>

                                        <Grid className={classes.blackBorder} item xs={12}>
                                   <h5>Commercial Manager</h5>
                                </Grid>
                                <Grid className={classes.blackBorder} item xs={4}>
                                            <Input
                                              type={'text'}
                                              style={{ width: '100%' }}
                                              className={classes.input}
                                              color={'secondary'}
                                              value={urFormState.CommercialMan}
                                              onChange={(evt) => handleChange(evt.target.value, "CommercialMan")}
                                            />
                                        </Grid>

                                        <Grid className={classes.blackBorder} container  xs={4}>
                                            <Grid item xs={6} style={{ textAlign: 'right'}}>
                                                <IconButton
                                                onClick={() => handleOpenSignPanModal("CMSignature")}
                                                >
                                               Signature<img src={SignIcon} width="26" height={26} alt=""/>
                                                 
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid className={classes.blackBorder} item xs={4}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                              value={urFormState.CMDate}
                                              handleChange={newDate => handleChange(newDate, "CMDate")}
                                            />
                                        </Grid>

                                        <Grid className={classes.blackBorder} item xs={12}>
                                   <h5>General Manager</h5>
                                </Grid>
                                         <Grid className={classes.blackBorder} item xs={4}>
                                            <Input
                                              type={'text'}
                                              style={{ width: '100%' }}
                                              className={classes.input}
                                              color={'secondary'}
                                              value={urFormState.GeneralMan}
                                              onChange={(evt) => handleChange(evt.target.value, "GeneralMan")}
                                            />
                                        </Grid>

                                        <Grid className={classes.blackBorder} container  xs={4}>
                                            <Grid item xs={6} style={{ textAlign: 'right'}}>
                                                <IconButton
                                                 onClick={() => handleOpenSignPanModal("GMSignature")}
                                                >
                                                 Signature<img src={SignIcon} width="26" height={26} alt=""/>
                                                
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid className={classes.blackBorder} item xs={4}>
                                           <DateTimeSelector
                                              inputStyle={{ minWidth: 250 }}
                                              label="Date"
                                              dateFormat={"yyyy-MM-dd"}
                                              styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                              value={urFormState.GMDate}
                                              handleChange={newDate => handleChange(newDate, "GMDate")}
                                            />
                                        </Grid>
                                        <p>(For amounts above BWP 50,000.00 it shall require the approval of both the Commercial and General Manager)</p>


                             </FormGroup>
                             </Grid>

                       <Grid item xs = {12} style={{marginTop:'60px'}}>
                      <Typography> NOTES: (Refer to Procurement Policy and Advance/ Prepayment Procedure) </Typography>

                    <Typography variant="h4"> RESPONSIBILITY</Typography>

                    <Typography variant="h4">PROCUREMENT</Typography> 
                   <ol>
                 <li> Supply Chain - It is the responsibility of the buyers to negotiate procurements terms with the suppliers, inthis case the payment terms. The buyers should achieve the Mine standard terms of 30 days from invoicedate at all times. However, in case the Supplier insists on a prepayment, the Buyer will escalate thenegotiation to the Material Manager and/or Finance Manager. </li>
                    <li>Advance payment or prepayment should be the last option. Supply Chain section is also responsible toensure that the payment terms are communicated to Finance. The Supply Cain section is responsible for timely recovery of funds for unfulfilled contracts. The Buyers are responsible to ensure that advance payments are properly authorised (at the correct level) before being submitted to Finance for payment. </li>  
                    </ol> 
                        
                    <Typography variant="h4"> FINANCE</Typography>
                    <p>
                        <ol>
                  <li>  The Financial Accountant is responsible for the arrangements of Letters of Credit to provide cover for the Mine where the order is above P100 000.00.</li>
                  <li>  The Assistant Accountants, both Accounts Payable and Bank, are responsible for ensuring that advance payment are properly authorised (at the correct level) before being submitted for authorisation to the bank signatories.</li>
                  <li>  The Accounting Officer is responsible for ensuring that the advance payment is authorised before being captured for payment.</li>
                  <li>  The Accounting Office and Assistant Accountant are responsible for correct posting/capturing of the prepayments.</li>
                  <li>  It is the primary responsibility of the Accounting Officer to ensure that the prepayment account is reconciled on a monthly basis and items are cleared. The Assistant Accountant will review the reconciliation and ensure the above happens.</li>
                    </ol>
                        </p>

                    
                    <Typography variant="h4"> AUTHORISATION OF PREPAYMENT</Typography>

                    <p>
                    The authorisers, before authorising the advance payments must ensure that all other options have being explored and assess the risk of making the prepayment to the Mine  
                    </p>

                    <p>
                    <p> Preference is to issue Letters of Credits. </p>  
                    <ol>
                 <li> Prepayments are to be made to suppliers who do not accept other payment terms.</li> 
                 <li> The prepayments must be approved in writing by the Commercial Manager and/or the General Manager as per the authorisation limit below.</li> 
                        <ul>
                          <li>Commercial Manager – up to P50 000</li>  
                           <li> Commercial and General Manager – P50 000 and up to P250 000. This shall be for situations where an irrevocable letter of credit cannot be arranged.</li>  
                        </ul>
             
                 <li> The approval should happen in the fully completed Supplier advance payment requisition form.</li> 
                 <li> The Buyer will then arrange for the prepayment to be processed through finance.</li> 
                 <li> Prepayment should be backed by a bank guarantee from the supplier.</li> 
                     </ol>
                    </p>

                 </Grid>

                </Grid>
            </DialogContent>
                            <DialogActions>
                             <Grid container justify={'space-between'}>
                                <Button
                                  variant={'outlined'}
                                  onClick={() => {
                                  setURFormState({ ...urInitFields });
                                  handleClose();
                                  }}
                                >
                                {t('cancel.label')}
                                 </Button>
                                <ButtonComponent
                                 t={t}
                                 onClick={() => {
                                    if(!urFormState.Sname || !urFormState.Scode || !urFormState.Scontact){
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                  handlePrepaymentSubmit(urFormState);
                                 }}
                                 loading={modalState.btnLoading}
                                 />
                            </Grid>
                            </DialogActions>
                           </Dialog>
      );
}

PrepaymentFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handlePrepaymentSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(PrepaymentFormModal);