import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },

    input: {
        minWidth: '224'
    },


    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    userFullName: "",
    date: null,
    designation: "",
    payrollNo: "",
    generalComment: "",
    HOD: "",
    MBAManager:"",
    SectionHead:"",
    HODSign: "",
    MBAManagerSign:"",
    SectionHeadSign:"",
    reasonForChange: [],
    StatusList: [],
    Allowance: [],
    jobGrade: '',
    name: '',
    pay: '',
    section: '',
    department: '',
    costCenter: '',
    other: '',
    effectiveDate: null,
    shiftAllowance: '',
    contopsAllowance: '',
    underGroundAllowance: '',
    others: '',
    others2: '',
    others3: '',
    ManagerDate: null,
    SectionDate: null,
    HODDate: null
};

const reasonForChange = [
    "Transfer",
    "Upgrade",
    "Promotion",
    "Change in Basic Pay",
    "Acting Allowance (AA)",
    "Resignation End of Contract",
    "Change in Job Title",
    "Other"
];

const allBenefits = [
    "Gratuity",
    "Severance",
    "Pension"
];

const genderList = [
    "Male",
    "Female"
];

const StatusList = [
    "Old",
    "New"
];

const Allowance = [
    "Action",
    "Cancel"
];

const EmployeeList = [
    "Temporary Employee",
    "Permanent Employee"
];

const MaritalStatus = [
    "Married",
    "UnMarried",
    "Divorced"
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    return imgURL;
}

function ChangeOfStatusModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [showHOD, setShowHOD] = useState(false);
    const [showMBAManager, setShowMBAManager] = useState(false);
    const [showSectionHead, setShowSectionHead] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setShowHOD((data.createdById === userdata.id) ? true : false);
            setShowMBAManager((data.createdById === userdata.id) ? true : false);
            setShowSectionHead((data.createdById === userdata.id) ? true : false);
            setURFormState({...data, id });
        }else{
            setShowHOD(true)
            setShowMBAManager(true)
            setShowSectionHead(true)
        }
    }, [data]);

    const handleChange = (value, name) => {
        if(name === 'reasonForChange'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
            if (value.target.checked) {
                    newState.reasonForChange.push(value.target.value);
            }else{
                let index = newState.reasonForChange.indexOf(value.target.value);
                if (index !== -1) {
                    newState.reasonForChange.splice(index, 1);
                    }
                }
                return newState;
            });
        } else if(name === 'StatuList'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.StatusList.push(value.target.value);
                } else {
                    let index = newState.StatusList.indexOf(value.target.value);
                if (index !== -1) {
                    newState.StatusList.splice(index, 1);
                    }
                }
                return newState;
            });
        } else if(name === 'jobGrade' || name === 'name'|| name === 'pay' || name === 'section' || name === 'department' || name === 'costCenter' || name === 'other' || name === 'shiftAllowance'
        || name === 'contopsAllowance' || name === 'underGroundAllowance' || name === 'others' || name === 'others2' || name === 'others3') {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                newState[name] = value.target.value;
                } else {
                newState[name] = '';
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>

<DialogContent>
<FormGroup row>
<Grid container spacing={2} style={{ marginBottom: 20 }} >
    <Grid className={classes.blackBorder} item xs={12}>
        <h4 className={classes.blackBorderTitle}>SECTION A: EMPLOYEE DETAILS</h4>
    </Grid>

    <Grid className={classes.blackBorder} item xs={4}>
         <h5 className={classes.blackBorderTitle}>Date</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
        <DateTimeSelector
        inputStyle={{ minWidth: 250}}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date}
        handleChange={newDate => handleChange(newDate, "date")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
        />
    </Grid>

    <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Full Name</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.userFullName}
        onChange={(evt) => handleChange(evt.target.value, "userFullName")}
        />
     </Grid>

    <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Designation</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.designation}
        onChange={(evt) => handleChange(evt.target.value, "designation")}
     />
    </Grid>

    <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Payroll No</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.payrollNo}
        onChange={(evt) => handleChange(evt.target.value, "payrollNo")}
    />
    </Grid>
</Grid>
</FormGroup>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<FormGroup row>
    <Grid className={classes.blackBorder} item xs={12}>
        <h4 className={classes.blackBorderTitle}>SECTION B: REASON FOR CHANGE</h4>
    </Grid>
<Grid className={classes.blackBorder} item xs={12}>
<FormGroup row>
    {reasonForChange.map((itm) => (
<Grid item xs={4} key={itm}>
<FormControlLabel
style={{ ...marginGenerator('mb-8') }}
value={itm}
checked={urFormState.reasonForChange ? urFormState.reasonForChange.includes(itm) : false}
control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
labelPlacement="start"
onChange={(evt) => {
    handleChange(evt, "reasonForChange");
}}
label={itm}
/>
</Grid>
))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Job Grade</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
{StatusList.map((itm) => (
        <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.jobGrade ? urFormState.jobGrade.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt,"jobGrade");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Name</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
{StatusList.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.name ? urFormState.name.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "name");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Pay</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {StatusList.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.pay ? urFormState.pay.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
    handleChange(evt, "pay");
    }}
    label={itm}
 />
    </Grid>
   ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Section</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {StatusList.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.section ? urFormState.section.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "section");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Department</h5>
</Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <FormGroup row>
    {StatusList.map((itm) => (
        <Grid item xs={4} key={itm}>
        <FormControlLabel
        style={{ ...marginGenerator('mb-8') }}
        value={itm}
        checked={urFormState.department ? urFormState.department.includes(itm) : false}
        control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
        labelPlacement="start"
        onChange={(evt) => {
            handleChange(evt, "department");
        }}
        label={itm}
        />
        </Grid>
        ))}
</FormGroup>
</Grid>


<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Cost Center</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
{StatusList.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.costCenter ? urFormState.costCenter.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "costCenter");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Other</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
{StatusList.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.other ? urFormState.other.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "other");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
   <h5 className={classes.blackBorderTitle}>Effective Date</h5>
</Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <DateTimeSelector
    inputStyle={{ minWidth: 250 }}
    label="Date"
    dateFormat={"yyyy-MM-dd"}
    value={urFormState.effectiveDate}
    handleChange={newDate => handleChange(newDate, "effectiveDate")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
/>
</Grid>

</FormGroup>
</Grid>
<Grid container spacing={2} style={{ marginBottom: 20 }} >

<FormGroup row>
<Grid className={classes.blackBorder} item xs={12}>
    <h4 className={classes.blackBorderTitle}>SECTION C: ALLOWANCES</h4>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Shift Allowance 2R 3R</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.shiftAllowance ? urFormState.shiftAllowance.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "shiftAllowance");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>CONTOPS Allowance</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.contopsAllowance ? urFormState.contopsAllowance.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "contopsAllowance");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Underground Allowance</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.underGroundAllowance ? urFormState.underGroundAllowance.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "underGroundAllowance");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Other</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.others ? urFormState.others.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "others");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <h5 className={classes.blackBorderTitle}>Other</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
    {Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.others2 ? urFormState.others2.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "others2");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<h5 className={classes.blackBorderTitle}>Other</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={8}>
<FormGroup row>
{Allowance.map((itm) => (
    <Grid item xs={4} key={itm}>
    <FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value={itm}
    checked={urFormState.others3 ? urFormState.others3.includes(itm) : false}
    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
    labelPlacement="start"
    onChange={(evt) => {
        handleChange(evt, "others3");
    }}
    label={itm}
    />
    </Grid>
    ))}
</FormGroup>
</Grid>
</FormGroup>
</Grid>

<FormGroup row>
<Grid container spacing={2} style={{ marginBottom: 20 }} >

<Grid className={classes.blackBorder} item xs={12}>
    <h4 className={classes.blackBorderTitle}>SECTION D: COMMENTS</h4>
</Grid>

<Grid className={classes.blackBorder} item xs={12}>
<TextField
    type="textarea"
    placeholder="General Comments"
    variant="standard"
    fullWidth={true}
    multiline
    rows={5}
    onChange={(evt) => handleChange(evt.target.value, "generalComment")}
    value={urFormState.generalComment}
/>
</Grid>
</Grid>
</FormGroup>

<FormGroup row>
<Grid container spacing={2} style={{ marginBottom: 20 }} >
    <Grid className={classes.blackBorder} item xs={12}>
        <h4 className={classes.blackBorderTitle}>SECTION E: MANAGERS & SIGNATURES</h4>
    </Grid>
<Grid className={classes.blackBorder} item xs={12}>

<FormGroup row>
<Grid item xs={12}>
        <h5 className={classes.blackBorderTitle}>MBA MANAGER SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <OutlinedSelect
    options={(users && users.length) ? users : []}
    label="HR Manager Casual"
    disabled={(data && data.MBAManagerSign) ? true : false}
    val={urFormState.MBAManager}
    handleChange={(evt) => handleChange(evt.target.value, "MBAManager")}
     styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
    <IconButton
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("MBAManagerSign")}
        >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.ManagerDate}
        handleChange={newDate => handleChange(newDate, "ManagerDate")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
</Grid>

<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>SECTION HEAD SIGNATURE</h5>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <OutlinedSelect
        options={ (users && users.length) ? users: []}
        label="HR Manager"
        disabled={(data && data.SectionHeadSign) ? true : false}
        val={urFormState.SectionHead}
        handleChange={(evt) => {
        handleChange(evt.target.value, "SectionHead");}}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>
<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
    <IconButton
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("SectionHeadSign")}
        >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
     <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label= "Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.SectionDate}
        handleChange={newDate => handleChange(newDate, "SectionDate")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
 </Grid>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>HEAD OF DEAPRTMENT SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <OutlinedSelect
    options={(users && users.length) ? users : []}
    label="General Manager"
    disabled={(data && data.HODSign) ? true : false}
    val={urFormState.HOD}
    handleChange={(evt) => handleChange(evt.target.value, "HODSign")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton
    variant={'outlined'}
    onClick={() => handleOpenSignPanModal("HODSign")}
>Signature  <img src={SignIcon} width="26" height={26} alt=""/>
</IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
<DateTimeSelector
    inputStyle={{ minWidth: 250 }}
    label="Date"
    dateFormat={"yyyy-MM-dd"}
    value={urFormState.HODDate}
    handleChange={newDate => handleChange(newDate, "HODDate")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
/>
</Grid>
</FormGroup>
</Grid>

</Grid>
</FormGroup>
</DialogContent>

<DialogActions>
<Grid container justify={'space-between'}>
<Button
    variant={'outlined'}
    onClick={() => {
    setURFormState({ ...urInitFields });
    handleClose();
}}
>
{t('cancel.label')}
</Button>
<ButtonComponent
t={t}
onClick={() => {
    if(!urFormState.userFullName){
        Message.error(REQUIRED_ERROR);
        return null;
    }
    handleCreateRequestSubmit(urFormState);
}}
loading={modalState.btnLoading}
/>
</Grid>
</DialogActions>
</Dialog>
);
}

ChangeOfStatusModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ChangeOfStatusModal);
