import React from 'react'

const OcrSupportPage = () => {
    return (
        <div>
            suport 
        </div>
    )
}

export default OcrSupportPage
