import {gql} from 'apollo-boost';
// Upload files
export const UPLOAD_FILES = gql`
  mutation AllUploadFile($file: Upload!, $project_uid: String, $team_name: String){
    allUploadFile(file: $file, project_uid: $project_uid, team_name: $team_name) {
        url
        filename
        mimetype
        encoding
    }
  }
`;
// create cash advance request team
export const ADD_CASH_ADVANCE_REQUEST = gql`
  mutation addCashAdvanceRequest($name: String, $department: String, $date: String, $transaction_type: String, $total: Int, $cash_advance: Int, $cash_returned: Int, $bank_name: String, $name_of_staff: String, $account_number: String, $users_comment: String, $attach_receipt: String, $work_flow_transaction: JSON, $line_manager: String, $line_manager_uid: String, $next_team_member_email: String, $current_user_uid: String, $current_user_email: String){
    addCashAdvanceRequest(name: $name, department: $department, date: $date, transaction_type: $transaction_type, total: $total, cash_advance: $cash_advance, cash_returned: $cash_returned, bank_name: $bank_name, name_of_staff: $name_of_staff, account_number: $account_number, users_comment: $users_comment, attach_receipt: $attach_receipt, work_flow_transaction: $work_flow_transaction, line_manager: $line_manager, line_manager_uid: $line_manager_uid, next_team_member_email: $next_team_member_email, current_user_uid: $current_user_uid, current_user_email: $current_user_email) {
        teamData
        status
        message
    }
  }
`;
// create line manager request team
export const ADD_LINE_MANAGER_TEAM = gql`
  mutation addLineManagerRequestTeam($work_flow_management: String, $comment: String, $approval: String, $ehod_team: String, $ehod_uid: String, $next_team_member_email: String){
    addLineManagerRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, ehod_team: $ehod_team, ehod_uid: $ehod_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create ehod request team
export const ADD_EHOD_REQUEST_TEAM = gql`
  mutation addEhodRequestTeam($work_flow_management: String, $comment: String, $approval: String, $md_team: String, $md_uid: String, $next_team_member_email: String){
    addEhodRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, md_team: $md_team, md_uid: $md_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create md request team
export const ADD_MD_REQUEST_TEAM = gql`
  mutation addMdRequestTeam($work_flow_management: String, $comment: String, $approval: String, $petty_cashier_team: String, $petty_cashier_uid: String, $next_team_member_email: String){
    addMdRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, petty_cashier_team: $petty_cashier_team, petty_cashier_uid: $petty_cashier_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create petty cashier request team
export const ADD_PETTY_CASHIER_REQUEST_TEAM = gql`
  mutation addPettyCashierRequestTeam($work_flow_management: String, $comment: String, $approval: String, $team_name: String, $next_team_member_name: String, $next_team_member_uid: String, $next_team_member_email: String){
    addPettyCashierRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, team_name: $team_name, next_team_member_name: $next_team_member_name, next_team_member_uid: $next_team_member_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create Procurement request team
export const ADD_PROCUREMENT_REQUEST_TEAM = gql`
  mutation addProcurmentRequestTeam($work_flow_management: String, $procurement_docs: JSON, $comment: String, $approval: String, $petty_cashier_team: String, $petty_cashier_uid: String, $next_team_member_email: String){
    addProcurmentRequestTeam(work_flow_management: $work_flow_management, procurement_docs: $procurement_docs, comment: $comment, approval: $approval, petty_cashier_team: $petty_cashier_team, petty_cashier_uid: $petty_cashier_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create Human capital request team
export const ADD_HUMAN_CAPITAL_REQUEST_TEAM = gql`
  mutation addHumanCapitalRequestTeam($work_flow_management: String, $human_capital_docs: JSON, $comment: String, $approval: String, $petty_cashier_team: String, $petty_cashier_uid: String, $next_team_member_email: String){
    addHumanCapitalRequestTeam(work_flow_management: $work_flow_management, human_capital_docs: $human_capital_docs, comment: $comment, approval: $approval, petty_cashier_team: $petty_cashier_team, petty_cashier_uid: $petty_cashier_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create teasury management request team
export const ADD_TREASURY_MANAGEMENT_REQUEST_TEAM = gql`
  mutation addTreasuryManagementRequestTeam($work_flow_management: String, $comment: String, $approval: String, $final_petty_cashier_team: String, $final_petty_cashier_uid: String, $next_team_member_email: String){
    addTreasuryManagementRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, final_petty_cashier_team: $final_petty_cashier_team, final_petty_cashier_uid: $final_petty_cashier_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;
// create ehod request team
export const ADD_FINAL_PETTY_CASHIER_REQUEST_TEAM = gql`
  mutation addFinalPettyCashierRequestTeam($work_flow_management: String, $comment: String, $approval: String, $approval_by_username: String, $approval_by_user_email: String, $apprval_by_user_id: String){
    addFinalPettyCashierRequestTeam(work_flow_management: $work_flow_management, comment: $comment, approval: $approval, approval_by_username: $approval_by_username, approval_by_user_email: $approval_by_user_email, apprval_by_user_id: $apprval_by_user_id) {
        teamData
        status
        message
    }
  }
`;
// update petty cashier request team
export const UPDATE_PETTY_CASHIER_REQUEST_TEAM = gql`
  mutation updatePettyCashierRequestTeam($uid: String, $treasury_comment: String, $comment: String, $approval: String, $team_name: String, $next_team_member_name: String, $next_team_member_uid: String, $next_team_member_email: String){
    updatePettyCashierRequestTeam(uid: $uid, treasury_comment: $treasury_comment, comment: $comment, approval: $approval, team_name: $team_name, next_team_member_name: $next_team_member_name, next_team_member_uid: $next_team_member_uid, next_team_member_email: $next_team_member_email) {
        teamData
        status
        message
    }
  }
`;