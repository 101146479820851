import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { marginGenerator } from '../../../../../theme/utils';
import { dateFormatterLLL, getParam } from '../../../../../utils';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ALL_SERVICE_TICKETS, GET_ASSIGNEE_LIST } from '../../../queries';
import Typography from '@material-ui/core/Typography';
import TicketStatus from '../../../components/TicketStatus';
import TicketPriority from '../../../components/TicketPriority';
import PropTypes from 'prop-types';
import { Paths } from '../../../../../routes/routePaths';
import Avatar from '../../../../../components/Avatar';
import { fade, Icon } from '@material-ui/core';
import { colors } from '../../../../../theme/colors';
import LocationOn from '@material-ui/icons/LocationOn';
import Accordians from '../../../../../components/Accordion';
import Divider from '@material-ui/core/Divider';
import Message from '../../../../../components/Message';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  fright: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const TicketDetailsPanel = ({ ticketData, userData, t, reloadTickets }) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: assignee } = useQuery(GET_ASSIGNEE_LIST);
  const { loading, error, data: allServicetickets } = useQuery(
    GET_ALL_SERVICE_TICKETS,
    {
      variables: {
        mailThreadId: ticketData.mailThreadId,
        isParent: false
      }
    }
  );

  return (
    <>
      <Grid container justify={'space-between'}>
        <Grid container item xs={8}>
          <Grid item style={marginGenerator('mr-32')}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('status.label')}
            </Typography>
            <TicketStatus t={t} status={ticketData.status} />
          </Grid>
          <Grid item>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('priority.label')}
            </Typography>
            <TicketPriority t={t} priority={ticketData.priority} />
          </Grid>

          {ticketData.assignee !== null && (
            <Grid
              container
              item
              direction={'column'}
              style={marginGenerator('mt-32')}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('ticketAssign.label')}
              </Typography>
              {ticketData.assignee}
            </Grid>
          )}
        </Grid>
        <Grid container item xs={3} direction={'column'}>
          {ticketData.subscription &&
            ticketData.subscription.service &&
            ticketData.subscription.service.name ? (
            <Grid item style={marginGenerator('mb-32')}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('subscription.label')}
              </Typography>
              <Grid
                container
                item
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}>
                <Avatar
                  name={ticketData.subscription.service.name}
                  variant={'medium'}
                  avatarStyles={{
                    borderRadius: 0,
                    background: fade(colors.info.main, 0.1)
                  }}
                  identifierStyle={{
                    color: colors.info.main,
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                />
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  {ticketData.subscription.service.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {ticketData.sites && ticketData.sites.length ? (
            <Grid item style={marginGenerator('mb-32')}>
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {t('site.label')}
              </Typography>
              {ticketData.sites.map((item, index) => (
                <Grid
                  container
                  key={index}
                  style={marginGenerator('mt-16')}
                  item
                  direction={'row'}
                  alignItems={'center'}
                  justify={'flex-start'}>
                  <div
                    style={{
                      backgroundColor: fade(colors.secondary.main, 0.1),
                      height: '24px',
                      width: '24px',
                      ...marginGenerator('mr-8'),
                      justifyContent: 'center'
                    }}
                    className={classes.column}>
                    <Icon
                      component={() => (
                        <LocationOn
                          className={classes.iconRegular}
                          color={'secondary'}
                        />
                      )}
                      alt="location"
                    />
                  </div>
                  <Typography variant={'subtitle2'} color={'textPrimary'}>
                    {item.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
          <Grid item>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('createdBy.label')}
            </Typography>
            <Grid
              container
              item
              direction={'row'}
              alignItems={'center'}
              justify={'flex-start'}>
              <Avatar name={ticketData.createdByName} variant={'medium'} />
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {ticketData.ticketType === 'mailTicket'
                  ? ticketData.createdBy
                  : ticketData.createdByName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        variant={'fullWidth'}
        absolute
        style={{ bottom: 'auto', marginTop: 18 }}
      />
      <Grid
        container
        justify={'space-between'}
        style={{ paddingBottom: '150px', paddingTop: '20px' }}>
        <Grid item xs={12} style={marginGenerator('mb-32')}>
          {!loading &&
            !error &&
            allServicetickets &&
            allServicetickets.allServicetickets.map((data, index) => (
              <Accordians
                description={data.description}
                panel={index + 1}
                createdBy={data.createdByName}
                createdAt={dateFormatterLLL(data.createdAt)}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
};

TicketDetailsPanel.propTypes = {
  ticketData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default TicketDetailsPanel;
