import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import QuoteImg_1 from '../../../../../../assets/compare-quotes/quote-1.jpg';
import QuoteImg_4 from '../../../../../../assets/compare-quotes/quote-4.jpg';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { IconButton, Typography } from '@material-ui/core';

function SelectedCompareModal({
    t,
    open,
    handleClose,
    handleOpenCompareModal
}) {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Comparing Selected Quotes for Test Bid 1</DialogTitle>
            <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <Typography style={{marginBottom: 10}} variant="subtitle1">Quote 1, Vendor: Test 1</Typography>
                        <img src={QuoteImg_1} style={{width: 380, height: 400, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <div style={{width: "100%"}}>
                            <Button style={{marginTop: 12, marginRight: 90}} variant="contained" color="primary" >Select</Button>
                            <Button style={{marginTop: 12}} variant="contained" color="secondary" >Reject</Button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{position: "relative", width: "100%", textAlign: "center"}}>
                        <Typography style={{marginBottom: 10}} variant="subtitle1">Quote 2, Vendor: Test 2-l</Typography>
                        <img src={QuoteImg_4} style={{width: 380, height: 400, border: "2px solid lightgray"}} />
                        <IconButton style={{position: "absolute", top: "50%", left: 0}}>
                            <ArrowLeftIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <IconButton style={{position: "absolute", top: "50%", right: 0}}>
                            <ArrowRightIcon color="primary" style={{fontSize: 40}} />
                        </IconButton>
                        <div style={{width: "100%"}}>
                            <Button style={{marginTop: 12, marginRight: 90}} variant="contained" color="primary" >Select</Button>
                            <Button style={{marginTop: 12}} variant="contained" color="secondary" >Reject</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

SelectedCompareModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(SelectedCompareModal);