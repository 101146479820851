import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import AttchedCamera from '../AttchedCamera';
import PlacesWithMap from '../PlacesWithMap';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL, convertUTCDateToLocalDate } from '../../../../utils';
import Message from '../../../../components/Message';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';


const NotificationPanelItem = ({
  t,
  data, 
  handleMenuItemClick,
  menuItems,
  allowConfigure
}) => {
  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAttachedCameraEnable, setIsAttachedCameraEnable] = useState(false);
  const [locationState, setLocationState] = useState({latitude: '', longitude: ''});
  const [isMapEnable, setIsMapEnable]         = useState(false);

  const handleClose = () => {
    setIsAttachedCameraEnable(false);
  };

  const handleSetPositionBase = pos => {
      setLocationState({ latitude: pos.latitude, longitude: pos.longitude });
  };

  const handleCloseMap =  () => {
      setIsMapEnable(false);
  };
  return (
      <>
    <TableRow key={data.place_id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Icon
                        component={() => (
                            <LocationOnRoundedIcon
                                color={'secondary'}
                                style={{marginTop: '2px', marginRight: '6px'}}
                            />
                        )}
                        alt={'icon'}
                    />
                </Grid>
                <Grid>
                    <Typography 
                    variant={'subtitle1'}
                    onClick={()=>{
                        setLocationState({ latitude: data.lat, longitude: data.lng });
                        setIsMapEnable(true);  
                      }} 
                    style={{ cursor: 'pointer'}}
                    >
                        {data.name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                    {data.created_by_name ? data.created_by_name : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography 
                      variant={'subtitle1'}
                      style={{ cursor:  data.noOfCam && data.noOfCam !== 0 ? 'pointer' :'auto' }} 
                      onClick={()=> {
                        if(data.noOfCam && data.noOfCam !== 0){
                            setIsAttachedCameraEnable(true)
                        }
                       }}
                    >
                        {data.noOfCam ? data.noOfCam : 0}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.lat}, {data.lng}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        {allowConfigure && 
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={menuItems}
                    handleMenuItemClick={elem => {
                        handleMenuItemClick(elem, data, 'place');
                        setAnchorEl(null);
                    }}
                />}
            </TableCell>
        }
    </TableRow>
        <AttchedCamera 
        t={t}
        rederDataType='camera' 
        data={data} 
        title={t('attachedCameras.label')} 
        isAttachedCameraEnable={isAttachedCameraEnable} 
        handleClose={handleClose}
        />
        <PlacesWithMap 
        handleSetPositionBase={handleSetPositionBase} 
        isMapEnable={isMapEnable} 
        handleClose={handleCloseMap} 
        locationState={locationState}
        />
    </>
  );
};

NotificationPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(NotificationPanelItem);