import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import {useState} from 'react';
import ScriptItems from "./OpportunityFilesNigeriaItems";
import ProjectFilesNigeriaItems from "./OpportunityFilesNigeriaItems";

function OpportunityFilesNigeriaPanel({
                                          t,
                                          list,
                                          handleMenuItemClick,
                                          loading,
                                          error
                                      }) {
    const columns = [
        {
            label: t('projectFile.label'),
            key: 'updatedBy'
        }, {
            label: t('comment.label'),
            key: 'updatedBy'
        }, {
            label: t('action.label'),
            key: 'Action'
        }
    ];
    return (
        <div>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading ? (
                                list.length > 0 ? (
                                    list.map((script, rowIndex) => (
                                        <ProjectFilesNigeriaItems
                                            key={rowIndex}
                                            t={t}
                                            script={script}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
OpportunityFilesNigeriaPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default OpportunityFilesNigeriaPanel;
