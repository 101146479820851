import React from 'react';
import { get } from "lodash-es";
import { withTranslation } from 'react-i18next';
import { dateFormatterLLL } from '../../../../utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
    width: "100%"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px' },
  pre: { padding: '10px 30px', margin: '0' }
});

const AttchedCamera = ({ t, data, isAttachedCameraEnable, handleClose, title, rederDataType, loading }) => {

  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isAttachedCameraEnable}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        {rederDataType === 'camera' ? (
          <>
            <DialogContent dividers className={classes.dialogContain}>
              {loading ?
                <div style={{ width: "100%", textAlign: "center", marginBottom: 24 }}>
                  <CircularProgress />
                </div>
                :
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('cameraName.label')}</TableCell>
                        <TableCell>{t('places.label')}</TableCell>
                        <TableCell>{t('onboardBy.label')}</TableCell>
                        <TableCell>{t('onboardDate.label')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data && data?.length > 0) && data.map((row, indx) => (
                          <TableRow key={indx}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row?.place?.name || '-'}</TableCell>
                            <TableCell>{row?.created_by_name || "-"}</TableCell>
                            <TableCell>{dateFormatterLLL(row.created_at)}</TableCell>
                          </TableRow>
                        ))}
                      {data?.cameras && data.cameras.length > 0 && data.cameras.map((row, indx) => (
                        <TableRow key={indx}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.place ? row.place.name : '-'}</TableCell>
                          <TableCell>{row.created_by_name ? row.created_by_name : "-"}</TableCell>
                          <TableCell>{dateFormatterLLL(row.created_at)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent dividers className={classes.dialogContain}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('name.label')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.schedules && data.schedules.length > 0 && data.schedules.map(sch => (
                        sch.length > 0 && sch.map((el, i) => (
                          <>
                            <TableRow key={i}>
                              <TableCell>{get(el, 'model_template.name', null)}</TableCell>
                            </TableRow>
                          </>
                        ))
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </>
        )
        }
      </Dialog>
    </>
  )
}

export default withTranslation()(AttchedCamera);
