import React,{useState} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_OCR_PROCESS, DELETE_OCR_PROCESS, ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS} from '../../../../Ocr/mutations';
import Message from '../../../../../../components/Message';
import OcrConfigurationProcessDesignListing from './OcrConfigurationProcessDesignListing';
import CreateOcrProcess  from './CreateOcrProcess';

const OcrConfigurationProcessDesign = ({
    t,
    subscriptionUserListLoading,
    subscriptionUserData,
    groupListLoading,
    ocrGroupData,
    ocrOcrUsersDataLoading,
    ocrUsersList,
    handleFilterForOcrUser,
    organizationId,
    subscriptionId,
    ocrProcessList,
    ocrOcrProcessDataLoading,
    allOcrUsersData,
    ocrUsersListLoading,
    refetchOcrProcessData,
    assignProjectEnable
}) => {

  const [isCreateProcessEnable, setIsCreateProcessEnable] = useState(false);
  const [selecteIdForDiffrentActions, setSelecteIdForDiffrentActions] = useState('')

  const enableCreateProcess = () => setIsCreateProcessEnable(true);
  const handleCloseOfCreateProcess = () => setIsCreateProcessEnable(false);

  const [createOcrProcess, { loading: createOcrProcessLoading }] = useMutation(CREATE_OCR_PROCESS, {
    onError: error => {
        console.log('err', error);
    },
    onCompleted: data => {
        Message.success(data && data.addOcrProcess.message);
        setIsCreateProcessEnable(false);
        refetchOcrProcessData();
    }
  });

  const [deleteOCRProcess, { loading: deleteOcrProcessLoading }] = useMutation(DELETE_OCR_PROCESS, {
    onError: error => {
        console.log('err', error);
        setSelecteIdForDiffrentActions('');

    },
    onCompleted: data => {
        setSelecteIdForDiffrentActions('');
        Message.success(data && data.deleteOcrProcess.message);
        refetchOcrProcessData();
    }
  });

  const hanldeSubmitOfAllOcrProcess = (type, data) => {
    if(type === 'add'){
      if(!data[0].groupId) return Message.error('Plese select value from group dropdown !!');
      if(data.length> 0){
        data.map(el =>{
        if(!el.title) return Message.error('Tiltle is Requird !!');
        })
      }
      console.log('data', data);
      data.subscription = subscriptionId;
        createOcrProcess({
          variables:{
            organization: organizationId,
            processSteps: data,
        }
        })  
    } else if(type === 'delete'){
      setSelecteIdForDiffrentActions(data);
      deleteOCRProcess({
        variables:{
          id: data
        }
      })
    }
  };

  return (
    <>
        <OcrConfigurationProcessDesignListing
          t={t}
          enableCreateProcess={enableCreateProcess}
          ocrProcessList={ocrProcessList}
          ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
          selecteIdForDiffrentActions={selecteIdForDiffrentActions}
          deleteOcrProcessLoading={deleteOcrProcessLoading}
          hanldeSubmitOfAllOcrProcess={hanldeSubmitOfAllOcrProcess}
          assignProjectEnable={assignProjectEnable}
        />
        <CreateOcrProcess
          t={t}
          open={isCreateProcessEnable}
          onClose={handleCloseOfCreateProcess}
          subscriptionUserListLoading={subscriptionUserListLoading}
          subscriptionUserData={subscriptionUserData}
          groupListLoading={groupListLoading}
          ocrGroupData={ocrGroupData}
          ocrOcrUsersDataLoading={ocrOcrUsersDataLoading}
          ocrUsersList={ocrUsersList}
          handleFilterForOcrUser={handleFilterForOcrUser}
          hanldeSubmitOfAllOcrProcess={hanldeSubmitOfAllOcrProcess}
          createOcrProcessLoading={createOcrProcessLoading}
          allOcrUsersData={allOcrUsersData}
          ocrUsersListLoading={ocrUsersListLoading}
        />
    </>
  )
}

export default OcrConfigurationProcessDesign