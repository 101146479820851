import React,{useState} from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import commonStyles from '../../../../../theme/commonStyles';
import MenuWrapper from '../../../../../components/MenuWrapper';
import StatusPanel from '../StatusPanel';

const useStyles = makeStyles({
    root: { fontSize: '16px'},
    pre: { padding: '10px 30px', margin: '0' }
  });

const IotDeviceItem = ({
    t,
    key,
    data,
    allowConfigure,
    handleMenuItemClick
}) => {
    "260779311004"

  const [anchorEl, setAnchorEl] = useState(null);
  const commonClasses = commonStyles();

  const menuItems = [
    { label: t('edit.label') }
  ];

  return (
        <>
    <TableRow key={data.id} >
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > 
                        {data.notification}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} > {data.phone_number}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'} >
                        {data.place}             
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <StatusPanel t={t} status={data.signal_status} />
                </Grid>
            </Grid>
        </TableCell>
        {allowConfigure && 
            <TableCell>
                <Grid >
                    <IconButton
                        disabled={!allowConfigure}
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular} />
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={[...menuItems]}
                    handleMenuItemClick={elem => {
                        setAnchorEl(null);
                        if(elem.label === t('edit.label')){
                            handleMenuItemClick(elem, data, 'iot_device');
                        }
                    }}
                />}
            </TableCell>
        }
    </TableRow>   
        </>
    )
}

export default IotDeviceItem
