import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateDeveloperTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    allBduTeamUserList
}) {
    const classes = useStyles();
    const [serviceTestResult, setServiceTestResult] = useState('');
    const [jobCard, setJobCard] = useState('');
    const [sitePictures, setSitePictures] = useState('');
    const [equipmentDeployed, setEquipmentDeployed] = useState('');
    const [pmTeam, setPMTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setServiceTestResult('');
            setJobCard('');
            setSitePictures('');
            setEquipmentDeployed('');
            setPMTeam('');
            setNextTeamMemberEmail('');
        }
    }, [modalState, open]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('developerTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : ((preValuesOfForm.project_info && preValuesOfForm.project_info.case_number) ? preValuesOfForm.project_info.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('serviceTestResult.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setServiceTestResult(file);
                                    } else {
                                        setServiceTestResult('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('jobCard.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setJobCard(file);
                                    } else {
                                        setJobCard('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('sitePictures.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setSitePictures(file);
                                    } else {
                                        setSitePictures('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={equipmentDeployed}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setEquipmentDeployed(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("projectManagementTeam.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setPMTeam(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            let convertContactDate = "";
                            if (!serviceTestResult || !jobCard || !sitePictures || !equipmentDeployed || !pmTeam || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // create URL for quatation file
                            let getServiceTestResult = "";
                            if (serviceTestResult != "" && serviceTestResult != null) {
                                let getServiceTestResultUrl = await handleUploadFiles(serviceTestResult, preValuesOfForm.project, 'engineer_team_botswana');
                                if (getServiceTestResultUrl.data && getServiceTestResultUrl.data.allUploadFile && getServiceTestResultUrl.data.allUploadFile.url) {
                                    getServiceTestResult = getServiceTestResultUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create URL for quatation file
                            let getJobCard = "";
                            if (jobCard != "" && jobCard != null) {
                                let getJobCardUrl = await handleUploadFiles(jobCard, preValuesOfForm.project, 'engineer_team_botswana');
                                if (getJobCardUrl.data && getJobCardUrl.data.allUploadFile && getJobCardUrl.data.allUploadFile.url) {
                                    getJobCard = getJobCardUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create URL for quatation file
                            let getSitePictures = "";
                            if (sitePictures != "" && sitePictures != null) {
                                let getSitePicturesUrl = await handleUploadFiles(sitePictures, preValuesOfForm.project, 'engineer_team_botswana');
                                if (getSitePicturesUrl.data && getSitePicturesUrl.data.allUploadFile && getSitePicturesUrl.data.allUploadFile.url) {
                                    getSitePictures = getSitePicturesUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                service_test_result: getServiceTestResult,
                                job_card: getJobCard,
                                site_service_picture: getSitePictures,
                                equipment_deployed: equipmentDeployed,
                                pm_team: pmTeam,
                                next_team_member_email: nextTeamMemberEmail,
                                auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateDeveloperTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateDeveloperTeamModal);