import { gql } from 'apollo-boost';

/*
  Get all service tickets
 */

export const GET_SERVICE_TICKETS = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $subscription: String
    $ownerId: String
    $assignee: String
  ) {
    servicetickets(
      status: $status
      priority: $priority
      subscription: $subscription
      assignee: $assignee
      ownerId: $ownerId
    ) {
      id
      ticketId
      title
      createdAt
      assignee
      createdByName
      description
      status
      priority
      updatedAt
      updatedByName
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

export const GET_ALL_SERVICE_TICKETS = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $type: String
    $page: Int
    $max: Int
    $category: String
    $search : String
    $subscription: String
    $ticketType: String
    $ticketId : String
    $ticketCategory: String
    $organizationName: String
    $siteName : String
    $vendor: String
    $region: String
    $vodacomProblem: String
    $incidentType: String
    $incidentList: [String]
    $incidentTypeGroup: String
    $sortBy: String
    $isParent: Boolean
    $startDate: String
    $endDate: String
    $assignee: String
    $country: String
    $mailThreadId: String
    $customer: String
  ) {
    allServicetickets(
      status: $status
      priority: $priority
      type: $type
      page: $page
      max: $max
      category: $category
      subscription: $subscription
      search : $search
      ticketId : $ticketId
      ticketType: $ticketType
      ticketCategory: $ticketCategory
      region : $region
      organizationName: $organizationName
      siteName: $siteName
      vodacomProblem: $vodacomProblem
      incidentType: $incidentType
      incidentList: $incidentList
      incidentTypeGroup: $incidentTypeGroup
      vendor: $vendor
      sortBy: $sortBy
      isParent: $isParent
      endDate: $endDate
      startDate: $startDate
      customer: $customer
      country: $country
      assignee: $assignee
      mailThreadId: $mailThreadId
    ) {
      id
      ticketId
      title
      createdAt
      mailThreadId
      isParent
      linkType
      natureOfCase
      severity
      createdByName
      createdBy
      status
      priority
      attachments
      incidentTypeGroup
      incidentCause
      incidentType
      escalationLevel
      customer
      userInfo
      vendor
      assignee
      ownerId
      updatedAt
      ticketType
      updatedBy
      vodacomProblem
      baseStation {
        id
        ulCTCBaseStationCode
      }
      location {
        id
        ulCTCLocation
      }
      additionalAgent1
      additionalAgent2
      salesSpecilistData
      lusakaZMData {
        id
        memberName
      }
      ndolaZMData {
        id
        memberName
      }
      kitweZMData {
        id
        memberName
      }
      livingStoneZMData {
        id
        memberName
      }
      solweziZMData {
        id
        memberName
      }
      lagosNGData {
        id
        memberName
      }
      phcNGData {
        id
        memberName
      }
      abujaNGData {
        id
        memberName
      }
      projectCategory
      reportComment
      reportComment2
      reportComment3
      reportComment4
      reportComment5
      incidentEndTime
      organizationName
      vendorEndDate
      vendorStartDate
      closeDescription
      description
      sites {
        id
        name
      }
      subscription {
        id
        name
        service {
          id
          name
        }
      }
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

export const GET_ALL_SERVICE_TICKETS_LIMIT = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $type: String
    $page: Int
    $max: Int
    $category: String
    $search : String
    $subscription: String
    $ticketType: String
    $ticketId : String
    $ticketCategory: String
    $organizationName: String
    $siteName : String
    $vendor: String
    $region: String
    $vodacomProblem: String
    $incidentType: String
    $incidentList: [String]
    $incidentTypeGroup: String
    $sortBy: String
    $isParent: Boolean
    $startDate: String
    $endDate: String
    $assignee: String
    $country: String
    $mailThreadId: String
    $customer: String
    $ownerId : String
    $listType : String
  ) {
    allServiceticketsWithLimit(
      status: $status
      priority: $priority
      type: $type
      page: $page
      max: $max
      category: $category
      subscription: $subscription
      search : $search
      ticketId : $ticketId
      ticketType: $ticketType
      ticketCategory: $ticketCategory
      region : $region
      organizationName: $organizationName
      siteName: $siteName
      vodacomProblem: $vodacomProblem
      incidentType: $incidentType
      incidentList: $incidentList
      incidentTypeGroup: $incidentTypeGroup
      vendor: $vendor
      sortBy: $sortBy
      isParent: $isParent
      endDate: $endDate
      startDate: $startDate
      customer: $customer
      country: $country
      assignee: $assignee
      mailThreadId: $mailThreadId
      ownerId: $ownerId
      listType: $listType
    ) {
      data
      total
    }
  }
`;

export const GET_ALL_SERVICE_SUBSCRIPTION_TICKETS = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $subscription: String
    $serviceId: String
    $ticketType: String
    $isParent: Boolean
    $assignee: String
    $country: String
    $mailThreadId: String
    $customer: String
  ) {
    allServiceSubscriptionTickets(
      status: $status
      priority: $priority
      subscription: $subscription
      serviceId: $serviceId
      ticketType: $ticketType
      isParent: $isParent
      customer: $customer
      country: $country
      assignee: $assignee
      mailThreadId: $mailThreadId
    ) {
      id
      ticketId
      title
      createdAt
      mailThreadId
      isParent
      createdByName
      createdBy
      status
      customer
      priority
      attachments
      incidentTypeGroup
      incidentCause
      incidentType
      escalationLevel
      vendor
      assignee
      ownerId
      updatedAt
      ticketType
      updatedBy
      description
      sites {
        id
        name
      }
      subscription {
        id
        name
        service {
          id
          name
        }
      }
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

export const GET_ALL_KNOWLEDGEBASE_TICKETS = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $subscription: String
    $ticketType: String
    $isParent: Boolean
    $assignee: String
    $mailThreadId: String
  ) {
    knowledgeBaseTickets(
      status: $status
      priority: $priority
      subscription: $subscription
      ticketType: $ticketType
      isParent: $isParent
      assignee: $assignee
      mailThreadId: $mailThreadId
    ) {
      id
      ticketId
      title
      createdAt
      description
      mailThreadId
      isParent
      createdByName
      createdBy
      status
      priority
      ticketType
      updatedAt
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

/*
  Get service ticket details by id
 */
export const GET_SERVICE_TICKET_DETAILS_BY_ID = gql`
  query ServiceTicket($id: String!) {
    getServiceTicketById(id: $id) {
      id
      ticketId
      title
      createdAt
      mailThreadId
      isParent
      linkType
      natureOfCase
      severity
      createdByName
      createdBy
      status
      priority
      attachments
      incidentTypeGroup
      incidentCause
      incidentType
      escalationLevel
      customer
      vendor
      assignee
      ownerId
      userInfo
      updatedAt
      ticketType
      updatedBy
      vodacomProblem
      baseStation {
        id
        ulCTCBaseStationCode
      }
      location {
        id
        ulCTCLocation
      }
      additionalAgent1
      additionalAgent2
      salesSpecilistData
      lusakaZMData {
        id
        memberName
      }
      ndolaZMData {
        id
        memberName
      }
      kitweZMData {
        id
        memberName
      }
      livingStoneZMData {
        id
        memberName
      }
      solweziZMData {
        id
        memberName
      }
      lagosNGData {
        id
        memberName
      }
      phcNGData {
        id
        memberName
      }
      abujaNGData {
        id
        memberName
      }
      projectCategory
      reportComment
      reportComment2
      reportComment3
      reportComment4
      reportComment5
      incidentEndTime
      vendorEndDate
      vendorStartDate
      equipemntReplaced
      description
      sites {
        id
        name
      }
      allCommunication {
        title
        description
        createdAt
        customerEmail
        createdByName
        attachments
        type
      }
      subscription {
        id
        service {
          id
          name
        }
      }
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
      allCCEmails
    }
  }
`;

/*
  Get service ticket details by subscription id
 */
export const GET_SERVICE_TICKETS_BY_SUBSCRIPTION_ID = gql`
  query($id: String!) {
    serviceticketsBySubscription(subscription: $id) {
      id
      ticketId
      title
      createdAt
      createdByName
      status
      priority
      updatedAt
      updatedBy
      description
    }
  }
`;

/*
  Get service ticket categories
 */
export const GET_TICKET_CATEGORIES = gql`
  query ServiceTicketCategory {
    categories {
      id
      name
      status
    }
  }
`;

/*
Get escalation level list
*/

export const GET_ESCALATION_LIST = gql`
  query Escalation {
    escalationgroup {
      id
      name
    }
  }
`;

/*
Get incident type list
*/
export const GET_INCIDENT_TYPE_LIST = gql`
  query IncidentType($incidentTypeGroup: String, $ticketType: String) {
    incidenttype(incidentTypeGroup: $incidentTypeGroup, ticketType: $ticketType) {
      id
      name
      incidentTypeGroup
      escalationGroup
      allowOverride
      requireContract
      notes
    }
  }
`;

/*
Get incident type list
*/

export const GET_INCIDENT_TYPE_PAGINATION_LIST = gql`
  query IncidentType($page: Int) {
    incidenttypepagination(page: $page) {
      data
      total
    }
  }
`;

/*
Get incident type group list
*/

export const GET_INCIDENT_TYPE_GROUP_PAGINATION_LIST = gql`
  query IncidentGroupList($page: Int) {
    incidentgrouppagination(page: $page) {
      data
      total
    }
  }
`;

export const GET_INCIDENT_TYPE_GROUP_LIST = gql`
  query IncidentGroupList($parent: String, $ticketType: String) {
    incidenttypegroup(parent: $parent, ticketType: $ticketType) {
      id
      name
    }
  }
`;

/*
Get all customer list
*/

export const GET_CUSTOMER_LIST = gql`
  query Customer {
    customer {
      id
      customerId
      name
      contactPerson
      notes
    }
  }
`;

/*
Get all vendor list
*/

export const GET_VENDOR_LIST = gql`
  query Vendor {
    vendor {
      name
      register
      address
      contactPersonName
      contactPersonEmail
      contactPersonPhone
    }
  }
`;

/*
Get all assignee list
*/

export const GET_ASSIGNEE_LIST = gql`
  query Assignee($ticketType : String) {
    assignee(ticketType: $ticketType) {
      name
      surname
      telephone
      email
    }
  }
`;

/*
  Get all subscriptions without orgId
 */
export const GET_ALL_SUBSCRIPTION_LIST = gql`
  query Subscription($status: String, $id: [String]) {
    getAllSubscriptionsListing(status: $status, id: $id) {
      id
      name
      noOfCPEs
      noOfSites
      avgUptime
      status
      isDelete
      sites {
        name
        id
        status
        circuitId
        platform
      }
      service {
        name
        id
        serviceType {
          name
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query {
    userInfo {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

// get usersByCountryId

export const GET_USER_LIST = gql`
  query  usersByCountryId($orgName: String){
    usersByCountryId(orgName: $orgName) {
      id
      firstName
      lastName
      email
      attributes
      organisations
      basestation
      role
      location
      vendor
      configured_DN_CIR
      configured_UP_CIR
      platform
      blocked
      active
      status
      customerName
      CircuitId
    }
  }
`;


export const GET_SITES = gql`
  query  getSites($email: String){
    getSites(email: $email) {
      id
      name
      circuitId
    }
  }
`;

// get usersByBaseStationCode

export const GET_USER_LIST_BY_BASESTATION = gql`
  query getUsersInfoByBaseStationCode($baseStationCode: String) {
    getUsersInfoByBaseStationCode(baseStationCode: $baseStationCode) {
      id
      firstName
      lastName
      email
      attributes
      location
      platform
    }
  }
`;

export const GET_USER_LIST_BY_LOCATION = gql`
  query getUsersInfoByLocation($location: [String]) {
    getUsersInfoByLocation(location: $location) {
      id
      firstName
      lastName
      email
      attributes
      location
      platform
    }
  }
`;

//get all region list

export const GET_ALL_REGION_LIST = gql`
  query {
    getRegionsList {
      name
      status
      code
      countryId
    }
  }
`;

export const GET_ORGANISATION_NAME = gql`
  query getOrgName($ownerId: String) {
    getOrgName(ownerId: $ownerId) {
      name
    }
  }
`;

export const GET_USER_INFO_BY_ID = gql`
  query UserInfoById($id: String) {
    getUserInfoById(id: $id) {
      id
      firstName
      lastName
      email
      attributes
    }
  }
`;

/*
Get All User list
*/

export const GET_ALL_INTERNAL_USER_LIST = gql`
  query AllUserList($ticketType: String) {
    getAllUserList(ticketType: $ticketType) {
      id
      firstName
      lastName
      emailAddress
      status
      department
    }
  }
`;

/*
Get All PhcNG list
*/

export const GET_ALL_ABUJANG_LIST = gql`
  query AbujaPhcNGList {
    getAbujaNGList {
      id
      memberName
      memberEmail
      memberRole
      memberType
    }
  }
`;

/*
Get All PhcNG list
*/

export const GET_ALL_PHCNG_LIST = gql`
  query PhcNGList {
    getPhcNGList {
      id
      memberName
      memberEmail
      memberRole
      memberType
    }
  }
`;

/*
Get All LagosNG list
*/

export const GET_ALL_LAGOSNG_LIST = gql`
  query LagosNGList {
    getLagosNGList {
      id
      memberName
      memberEmail
      memberRole
      memberType
    }
  }
`;

/*
Get All LagosNG list
*/

export const GET_SALES_SPECILIST = gql`
  query AbujaPhcNGList {
    getSalesSpecilist {
      id
      memberName
      memberEmail
      memberRole
      memberType
    }
  }
`;

/*
Get All base station list
*/

export const GET_BASE_STATION_LIST = gql`
  query BaseStation {
    getBaseStationList {
      id
      ulCTCBaseStationCode
    }
  }
`;

/*
Get All location list
*/

export const GET_LOCATION_LIST = gql`
  query Location {
    getLocationList {
      id
      ulCTCLocation
    }
  }
`;

export const GET_ALL_LUSAKANG_LIST = gql`
  query LusakaNGList {
    getLusakaNGList {
      id
      memberName
      memberEmail
    }
  }
`;

export const GET_ALL_NDOLANG_LIST = gql`
  query NdolaNGList {
    getNdolaNGList {
      id
      memberName
      memberEmail
    }
  }
`;

export const GET_ALL_LIVINGSTONENG_LIST = gql`
  query LivingstoneNGList {
    getLivingstoneNGList {
      id
      memberName
      memberEmail
    }
  }
`;

export const GET_ALL_KITWENG_LIST = gql`
  query KitweNGList {
    getKitweNGList {
      id
      memberName
      memberEmail
    }
  }
`;

export const GET_ALL_SOLWEZING_LIST = gql`
  query SolweziNGList {
    getSolweziNGList {
      id
      memberName
      memberEmail
    }
  }
`;


export const CUSTOMER_DATA_WITH_ORGANISATION = gql`
    query AllCustomerWithOrganisation($id: String) {
      getAllCustomerWithOrganisation(id: $id){
        CircuitId
        customerName
        organizationName
        firstName
        lastName
        platform
        BaseStation
        vendor
        configured_DN_CIR
        configured_UP_CIR
      }
    }
`;

export const GET_SITE_BY_ORG_NAME = gql`
    query getSiteDataByOrgName($organizationname: String){
      getSiteDataByOrgName(organizationname: $organizationname){
        data
      }
    }
`;

export const CHANGE_MANAGEMENT_LIST = gql`
    query ChangeManagement(
        $status: String 
        $page: Int
        $startDate: String
        $endDate: String
      ){
      changeManagement(
        status: $status 
        page: $page
        startDate: $startDate
        endDate: $endDate
        ){
          data
          total
      }
    }
`;

export const GET_ALL_REGION_LIST_DATA = gql`
  query Region($countryId : String){
    getRegionListByCountry(countryId: $countryId){
      id
      name
    }
  }
`;

export const GET_CHANGE_MANAGEMENT_BY_ID = gql`
  query ChangeManagement($id: String){
    getChangeManagementById(id: $id){
      id
      status
      requestId
      createdAt
      createdBy
      createdByName
      updatedByName
      updatedAt
      inCharge
      changeRequest
    }
  }
`

export const GET_SERVICETICKET_SETTINGS_LIST = gql`
  query ServiceTicketSettings($key: String){
    getServiceTicketSettings(key: $key){
      name
      incidentTypeGroup
      key
    }
  }
`

export const GET_CHANGE_MANAGEMENT_USERS = gql`
  query ChangeManagementUsers{
    getChangeManagementUsers{
      mdEmail
      mdName
      cabEmail
      cabName
    }
  }
`

export const GET_CUSTOMERS_FROM_MONGO = gql`
  query CustomerFromMongo($search: String){
    getCustomerDataFromMongo(search: $search){
      id
      firstName
      lastName
      email
      attributes
      organisations
      basestation
      role
      location
      vendor
      configured_DN_CIR
      configured_UP_CIR
      platform
      blocked
      active
      status
    }
  }
`