import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateInstallTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    modalContentLoading,
}) {
    const classes = useStyles();
    const [qualityAssuranceTeam, setQualityAssuranceTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [attachAnyOtherDoc, setAttachAnyOtherDoc] = useState([]);
    const [signal, setSignal] = useState('');
    const [feetportForm, setFeetportForm] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setQualityAssuranceTeam('');
            setNextTeamMemberEmail('');
            setSignal('');
            setAttachAnyOtherDoc([]);
            setFeetportForm('');
            setOnHold('');
            setCategory('');
        }
    }, [modalState, open, modalContentLoading]);
    const handleAttachAnyOtherDoc = (index) => {
        setAttachAnyOtherDoc(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('installTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.case_number ? preValuesOfForm.project_info.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('feetportForm.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    type={'file'}
                                    inputProps={{ accept: "image/*" }}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0) {
                                            let file = evt.target.files[0];
                                            setFeetportForm(file);
                                        } else {
                                            setFeetportForm('');
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('feetportForm.label')}</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Button variant={'outlined'}
                                    disabled={!preValuesOfForm.feetport_form_url ? true : false}
                                    color={'primary'}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        if (preValuesOfForm.feetport_form_url != undefined) {
                                            window.open(preValuesOfForm.feetport_form_url);
                                        }
                                    }}>{t('clickHereToViewFiles.label')}</Button>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('installationPictures.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    type={'file'}
                                    inputProps={{ multiple: true }}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    disabled={attachAnyOtherDoc && attachAnyOtherDoc.length >= 7 ? true : false}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0 && attachAnyOtherDoc.length < 7) {
                                            if (evt.target.files.length <= 7 && (attachAnyOtherDoc.length + evt.target.files.length) <= 7) {
                                                let file = evt.target.files;
                                                for (let i = 0; i < file.length; i++) {
                                                    setAttachAnyOtherDoc(prevState => {
                                                        let newState = [...prevState]
                                                        newState.push(file[i]);
                                                        return newState
                                                    });
                                                }
                                            } else {
                                                evt.target.value = null;
                                                Message.error("Files length must be equal or less than 7. Please try again.");
                                            }
                                        } else {
                                            evt.target.value = null;
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                {attachAnyOtherDoc && attachAnyOtherDoc.length ?
                                    attachAnyOtherDoc.map((item, index) => (
                                        <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleAttachAnyOtherDoc(index)} aria-label="delete">
                                            <DeleteIcon color="secondary" /></IconButton>
                                        </Typography>
                                    )) : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('signal.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={signal}
                                    type={'text'}
                                    placeholder={t('signal.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setSignal(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={qualityAssuranceTeam}
                                    label={`${t('qualityAssuarance.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setQualityAssuranceTeam(evt.target.value)
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={allUserList && allUserList.length ? allUserList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label') + ' ' + t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label') + ' ' + t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!feetportForm || !signal || !qualityAssuranceTeam || !nextTeamMemberEmail || !attachAnyOtherDoc || (attachAnyOtherDoc.length == 0)) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // create URL for feetportForm file
                            let getFeetportForm = "";
                            if (feetportForm != "" && feetportForm != null) {
                                let getFeetportFormUrl = await handleUploadFiles(feetportForm, preValuesOfForm.project, 'malawi_feetport_form');
                                if (getFeetportFormUrl.data && getFeetportFormUrl.data.allUploadFile && getFeetportFormUrl.data.allUploadFile.url) {
                                    getFeetportForm = getFeetportFormUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create url for installation pictures
                            let getAttachAnyOtherDoc = [];
                            if (attachAnyOtherDoc != [] && attachAnyOtherDoc != null && attachAnyOtherDoc.length > 0) {
                                for (let i = 0; i < attachAnyOtherDoc.length; i++) {
                                    let getAttachDoc = await handleUploadFiles(attachAnyOtherDoc[i], preValuesOfForm.project, 'im_team_section');
                                    if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                        getAttachAnyOtherDoc.push(getAttachDoc.data.allUploadFile.url);
                                    }
                                    else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                            }
                            // feetport_form_url: preValuesOfForm.feetport_form_url,
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                job_order_form_url: preValuesOfForm.job_order_form_url,
                                feetport_form_url: getFeetportForm,
                                quality_assurance_team: qualityAssuranceTeam,
                                next_team_member_email: nextTeamMemberEmail,
                                upload_pics: getAttachAnyOtherDoc,
                                signal: signal,
                                category: category,
                                on_hold: onHold,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateInstallTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateInstallTeamModal);
