import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import Grid from '@material-ui/core/Grid';
import EventsMap from '../../../../components/EdgeAIDashBoardPanel/EventsMap';
import Statcard from '../../../../../../components/Statcard';
import BarChartWrapper from '../../../../../../components/Charts/BarChartWrapper';
import cpe_orange from '../../../../../../assets/icons/cpe_orange.png';

const getLast7Days = (X) => {
    var dates = [];
    for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString());
    }
    return dates;
}
const chartData = getLast7Days(-20).map(itm => {
    const count = parseInt(Math.random() * (30-1) + 1);
    return {date: itm, count}  
});

const DashboardPanel = ({
  t,
  subscriptionData
}) => {
    const [mapPlaceList, setMapPlaceList] = useState([]);

    useEffect(() => {
        console.log("subscriptionData: ", subscriptionData);
        if(subscriptionData && subscriptionData.sites){
            const placeData = subscriptionData.sites.map(item => {
                return { position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, title: item.name, infoData: null, placeId: item.id };
            });
            setMapPlaceList(placeData);
        }
    }, []);

    const statList = [
        {
            label: t('number.label'),
            value: 10,
            icon: cpe_orange,
            type: "cam_active"
        },
        {
            label: subscriptionData.service ? subscriptionData.service.name : "",
            value: 13,
            icon: cpe_orange,
            type: "cam_down"
        },
        {
            label: t('number.label'),
            value: 34,
            icon: cpe_orange,
            type: "cam_down"
        },
        {
            label: t('number.label'),
            value: 45,
            icon: cpe_orange,
            type: "cam_down"
        }
    ];

    return (
        <>
            <ChartContainer
            customMargin={{marginBottom: 24}} 
            title={subscriptionData.service ? subscriptionData.service.name + " Map" : "Map"} >
                <Grid
                container
                style={{marginTop: 20}}>
                    <EventsMap 
                        id={'other-map'} 
                        markers={mapPlaceList}
                    />
                </Grid>
            </ChartContainer>
            <Grid
            container
            justify={'space-between'}
            style={{marginTop: 20}}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                    />
                ))}
            </Grid>
            <ChartContainer
            customMargin={{marginBottom: 32, marginTop: 24}}
            title={subscriptionData.service ? subscriptionData.service.name + " Chart" : "Chart"} >
                <BarChartWrapper
                    data={chartData}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                />
            </ChartContainer>
        </>
    );
};

DashboardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default DashboardPanel;
