import React from 'react';
import {colors} from '../../../../theme/colors';
import '../../../../components/Map/mapStyle.css';

class Map extends React.PureComponent {
    componentDidMount() {
        if (window.google) {
            this.initMap();
        }
    }

    initMap() {
        const mapOptions = {
            zoom: 6,
            mapTypeControl: false,
            streetViewControl: false
        };
        const map = new window.google.maps.Map(
            document.getElementById(`map-canvas${this.props.id}`),
            mapOptions
        );
        const infowindow = new window.google.maps.InfoWindow();
        const customMarker = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: colors.error.main,
            fillOpacity: 1,
            scale: 7,
            strokeColor: colors.common.white,
            strokeWeight: 4
        };
        if (this.props.markers && this.props.markers.length) {
            this.props.markers.forEach((item, index) => {
                const marker = new window.google.maps.Marker({
                    position: item.position,
                    map: map,
                    title: item.title,
                    icon: customMarker,
                    iconSize: 10
                });
                marker.setMap(map);
                map.setCenter(marker.getPosition());
                this.bindInfoWindow(marker, map, infowindow, item);
            });
        }
    }

    bindInfoWindow = (marker, map, infowindow, content) => {
        // let camCount = content.infoData ? content.infoData.place_name : '0';
        /*    let totalModels = content.infoData ? content.infoData.totalModels : '0';
            let activeModels = content.infoData ? content.infoData.activeModels : '0';
            let totalEvents = content.infoData ? content.infoData.totalEvents : '0';*/
        window.google.maps.event.addListener(marker, 'click', () => {
            let contentString =
                '<div id="iw-container">' +
                '<div class="iw-content">' +
                '<div class="iw-subTitle">' +
                content.title +
                '</div>';
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
        });
    };

    render() {
        const {id} = this.props;
        return (
            <div id={`map-canvas${id}`} style={{height: '100%', width: '100%'}}/>
        );
    }
}
export default Map;
