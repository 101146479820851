import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import Message from '../../../../components/Message';
import { CircularProgress } from '@material-ui/core';

const scheduleOptions = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" }
];

const initState = {
    frequency_type: "",
    send_time: null,
    report_type: "openclose_by_date",
    notification_templates: [] 
};

function ScheduleReportModal({
    t,
    notiTemplateList,
    open,
    handleClose,
    handleSubmit,
    loading
}) {

    const [scheduleFormState, setScheduleFormState] = useState({...initState});

    const handleInputChange = (val, field) => {
        if(field === "frequency_type"){
            setScheduleFormState({...scheduleFormState, [field]: val, send_time: null});
        }else{
            setScheduleFormState({...scheduleFormState, [field]: val});
        }
    }

    return (
        <Dialog
            open={open}
            onClose={evt => {
                setScheduleFormState({...initState});
                handleClose();
            }}
            fullWidth={true}
            maxWidth={"md"}
            disableBackdropClick={true}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="form-dialog-title" color="primary">{t("scheduleReport.label")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <OutlinedSelect
                            val={scheduleFormState.notification_templates}
                            label={`${t('notification.label')} ${t('template.label')}*`}
                            handleChange={evt => handleInputChange(evt.target.value, "notification_templates")}
                            styleOverrides={{ width: '100%' }}
                            options={notiTemplateList}
                            multiple={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            val={scheduleFormState.report_type}
                            label={`${t('reportType.label')} *`}
                            handleChange={evt => handleInputChange(evt.target.value, "report_type")}
                            styleOverrides={{ width: '100%' }}
                            options={[{id: "openclose_by_date", name: "Open Close"}]}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 15}} >
                    <Grid item xs={6}>
                        <OutlinedSelect
                            val={scheduleFormState.frequency_type}
                            label={`${t('scheduleType.label')} *`}
                            handleChange={evt => handleInputChange(evt.target.value, "frequency_type")}
                            styleOverrides={{ width: '100%' }}
                            options={scheduleOptions}
                        />
                    </Grid>
                    {scheduleFormState.frequency_type &&
                        <Grid item xs={6}>
                            <DateTimeSelector
                                inputStyle={{ padding: 10, minWidth: 242 }}
                                label={(scheduleFormState.frequency_type === "daily") ? "HH:mm" : "YYYY-MM-dd"}
                                dateFormat={(scheduleFormState.frequency_type === "daily") ? "HH:mm" : "yyyy-MM-dd"}
                                value={scheduleFormState.send_time}
                                handleChange={newDate => handleInputChange(newDate, "send_time")}
                                disableFuture={true}
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button color="primary" variant={'contained'} disabled={loading} onClick={evt => {
                    console.log("scheduleFormState: ", scheduleFormState);
                    if(!scheduleFormState.notification_templates.length || !scheduleFormState.send_time || !scheduleFormState.report_type || !scheduleFormState.frequency_type){
                        Message.error(REQUIRED_ERROR);
                        return null;
                    }
                    handleSubmit(scheduleFormState);
                }}>
                    {loading ? <CircularProgress size={20} color="primary" /> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScheduleReportModal;