import { gql } from 'apollo-boost';

export const UPDATE_SITE = gql`
    mutation Site($id: String, $orderStatus: String) {
        updateSite(id: $id, orderStatus: $orderStatus) {
            id
        }
    }
`;
export const UPDATE_DIA_SITE = gql`
    mutation Site($id: String, $orderStatus: String) {
        updateDiaSite(id: $id, orderStatus: $orderStatus) {
            id
        }
    }
`;
export const UPDATE_DIAN_SITE = gql`
    mutation Site($id: String, $opportunityId: String, $orderStatus: String) {
        updateDiaNigeriaSite(id: $id, opportunityId: $opportunityId, orderStatus: $orderStatus) {
            id
        }
    }
`;
export const UPDATE_SUBSCRIPTION = gql`
    mutation Subscription($id: String, $status: String, $analyticsUrl: String, $mode: String) {
        updateSubscription(id: $id, status: $status, analyticsUrl: $analyticsUrl, mode: $mode) {
            id
        }
    }
`;
export const UPDATE_SUBSCRIPTION_AND_SITES = gql`
    mutation Subscription($id: String, $name: String, $sites: JSON) {
        updateSubscriptionAndSites(id: $id, name: $name, sites: $sites) {
            id
        }
    }
`;
export const ASSIGN_SUBSCRIPTION_TO_USERS = gql`
mutation Subscription($id: String, $userIds: [String]) {
    assignSubscriptionToUsers(id: $id, userIds: $userIds) {
        id
    }
}
`;
export const CREATE_ORGANISATION = gql`
    mutation UncannyOrganization($name: String, $image: String) {
        addOrganization(name: $name, image: $image) {
            organization_id
        }
    }
`;
export const UPDATE_ML_TEMPLATE = gql`
    mutation MlTemplate(
        $id: Int
        $name: String
        $model_type: String
        $slug: String
        $definition: JSON
        $organization: String
        $priority: String
        $notification_frequency: Int
        $lineCrossObjects: JSON
    ) {
        updateMLTemplate(
            id: $id
            name: $name
            model_type: $model_type
            slug: $slug
            definition: $definition
            organization: $organization
            priority: $priority
            notification_frequency: $notification_frequency
            lineCrossObjects: $lineCrossObjects
        ) {
            id
            organization
        }
    }
`;

export const UPDATE_ML_TEMPLATE_WITH_CAMERA = gql`
    mutation MlTemplateCamera(
        $id: Int
        $payload: [JSON]
    ) {
        updateMLTemplateWithCamera(
            id: $id
            payload: $payload
        ) {
            success
            data
            message
        }
    }
`;

export const CREATE_ML_TEMPLATE = gql`
    mutation MlTemplate(
        $name: String
        $model_type: String
        $slug: String
        $definition: JSON
        $organization: String
        $priority: String
        $provider: String
        $notification_frequency: Int
        $lineCrossObjects: JSON
    ) {
        addMLTemplate(
            name: $name
            model_type: $model_type
            slug: $slug
            definition: $definition
            organization: $organization
            priority: $priority
            provider: $provider,
            notification_frequency: $notification_frequency
            lineCrossObjects: $lineCrossObjects
        ) {
            id
            organization
        }
    }
`;
export const DELETE_ML_TEMPLATE = gql`
    mutation MlTemplate($id: Int) {
        deleteMLTemplate(id: $id) {
            id
        }
    }
`;
export const CREATE_SDN_NOTIFICATION_TEMPLATE = gql`
    mutation SDNNotificationTemplate(
        $vnf_id: String
        $device_id: String
        $controller_id: String
        $notification_name: String
        $notification_type: String
        $notification_parameter: String
        $organisation_id: String
        $created_by: String
        $notification_priority: Int
        $notification_template: String
    ) {
        addSDNNotificationTemplate(
            vnf_id: $vnf_id
            device_id: $device_id
            controller_id: $controller_id
            notification_name: $notification_name
            notification_type: $notification_type
            notification_parameter: $notification_parameter
            organisation_id: $organisation_id
            created_by: $created_by
            notification_priority: $notification_priority
            notification_template: $notification_template
        ) {
            Notification_ID
            verified
        }
    }
`;
export const VERIFY_SDN_NOTIFICATION_TEMPLATE = gql`
    mutation VerifySDNNotificationTemplate(
        $otp: String
        $notification_id: String
    ) {
        verifySDNNotificationTemplate(
            otp: $otp
            notification_id: $notification_id
        ) {
            Notification_ID
            verified
        }
    }
`;
export const CREATE_NOTIFICATION_TEMPLATE = gql`
    mutation NotificationTemplate(
        $name: String
        $notification_type: String
        $slug: String
        $organization: String
        $dispatch_address: String
        $notification_message: String
        $approver_notification: Boolean
        $message_fields: [String]
        $site_id: String
        $auth_token: String
        $start_time: String
        $end_time: String
    ) {
        addNotificationTemplate(
            name: $name
            notification_type: $notification_type
            slug: $slug
            dispatch_address: $dispatch_address
            approver_notification: $approver_notification
            organization: $organization
            notification_message: $notification_message
            message_fields: $message_fields
            site_id: $site_id
            start_time: $start_time
            end_time: $end_time
            auth_token: $auth_token
        ) {
            id
            organization
        }
    }
`;
export const UPDATE_NOTIFICATION_TEMPLATE = gql`
    mutation NotificationTemplate(
        $id: Int
        $name: String
        $notification_type: String
        $slug: String
        $organization: String
        $dispatch_address: String
        $approver_notification: Boolean
        $notification_message: String
        $message_fields: [String]
        $site_id: String
        $auth_token: String
    ) {
        updateNotificationTemplate(
            id: $id
            name: $name
            notification_type: $notification_type
            slug: $slug
            dispatch_address: $dispatch_address
            approver_notification: $approver_notification
            organization: $organization
            notification_message: $notification_message
            message_fields: $message_fields
            site_id: $site_id
            auth_token: $auth_token
        ) {
            id
            organization
        }
    }
`;
export const UPDATE_NOTIFICATION_TEMPLATE_WITH_ML_TEMPLATE = gql`
    mutation NotificationTemplateWithMLTemplate(
        $id: Int
        $tempId: [String]
    ) {
        updateNotificationTemplateWithMLTemplate(
            id: $id
            tempId: $tempId
        ) {
            id
            organization
        }
    }
`;
export const DELETE_NOTIFICATION_TEMPLATE = gql`
    mutation Notification($id: Int) {
        deleteNotificationTemplate(id: $id) {
            id
        }
    }
`;
export const CREATE_PLACE = gql`
    mutation Place($name: String, $orgId: String, $type: String, $loc: [Float]) {
        addPlace(name: $name, orgId: $orgId, loc: $loc, type: $type) {
            id
        }
    }
`;
export const CREATE_MONITORING = gql`
    mutation Monitoring($monitoringUrl: String, $monitoringType: String) {
        addMonitoring(monitoringUrl: $monitoringUrl, monitoringType: $monitoringType) {
            status
        }
    }
`;
export const UPDATE_PLACE = gql`
    mutation Place(
        $place_id: String
        $name: String
        $orgId: String
        $type: String
        $loc: [Float]
    ) {
        updateEdgeAIPlace(
            place_id: $place_id
            name: $name
            orgId: $orgId
            loc: $loc
            type: $type
        ) {
            id
        }
    }
`;
export const DELETE_PLACE = gql`
    mutation Place($id: String) {
        deletePlace(id: $id) {
            id
        }
    }
`;
export const CREATE_CAMERA = gql`
    mutation Camera($camData: [JSON]) {
        addCamera(camData: $camData) {
            success
            message
        }
    }
`;
export const UPDATE_CAMERA = gql`
    mutation Camera($camData: JSON) {
        updateCamera(camData: $camData) {
            success
            message
        }
    }
`;
export const DELETE_CAMERA = gql`
    mutation Camera($id: String) {
        deleteCamera(id: $id) {
            camera_id
        }
    }
`;
export const DELETE_MULTIPLE_CAMERA = gql`
    mutation Camera($ids: [String]) {
        deleteMultipleCameras(cameraIds: $ids) {
            camera_id
        }
    }
`;
export const DELETE_EDGEAI_SUBSCRIPTION = gql`
    mutation Subscription($subscriptionId: String) {
        deleteEdgeAiSubscription(subscriptionId: $subscriptionId) {
            id
        }
    }
`;
export const ACKNOWLADGE_NOTIFICATION = gql`
    mutation NotificationsReport($ids: [Int]) {
        acknowledgeNotification(notifications: $ids) {
            id
        }
    }
`;
export const UNACKNOWLADGE_NOTIFICATION = gql`
    mutation NotificationsReport($id: Int, $isAcknowledged: Boolean) {
        unAcknowledgeNotification(id: $id, isAcknowledged: $isAcknowledged) {
            id
        }
    }
`;

export const NOTIFICTION_PROCESS = gql`
    mutation NotificationsProcessReport(
        $orgId: String
        $id: Int
        $isAcknowledged: Boolean
        $status: String
        $comments: String
        $proceedTime: Date
        $cameraId: String
        $processSteps: [JSON] ) {
        notificationsProcess( 
            orgId: $orgId
            id: $id
            isAcknowledged: $isAcknowledged
            status: $status
            comments: $comments
            processSteps: $processSteps
            proceedTime: $proceedTime
            cameraId: $cameraId 
        ){
            success
            message
        }
    }
`;

export const CREATE_NODE = gql`
    mutation Nodes(
        $orgId: String
        $isProvisioned: Boolean
        $workerCount: Int
        $workerSize: String
        $workerGpuCount: Int
        $workerDiskSize: Int
        $provider: String
        $name: String
        $instance_ip: String
    ) {
        addNode(
            orgId: $orgId
            isProvisioned: $isProvisioned
            workerCount: $workerCount
            workerSize: $workerSize
            workerGpuCount: $workerGpuCount
            workerDiskSize: $workerDiskSize
            provider: $provider
            name: $name
            instance_ip: $instance_ip
        ) {
            id
        }
    }
`;
export const DELETE_NODE = gql`
    mutation Nodes($id: String) {
        deleteNode(id: $id) {
            id
        }
    }
`;
export const CREATE_OPPORTUNITY = gql`
    mutation createOpportunity($opportunityData: String) {
        createOpportunity(opportunityData: $opportunityData) {
            status
        }
    }
`;
export const CREATE_CONTROLLER = gql`
    mutation CreateController(
        $controller_name: String
        $deployment_type: String
        $role: String
        $provision_type: String
        $web_presence: String
        $created_by: String
        $organisation_id: String
        $version: String
    ) {
        createController(
            controller_name: $controller_name
            deployment_type: $deployment_type
            role: $role
            provision_type: $provision_type
            web_presence: $web_presence
            created_by: $created_by
            organisation_id: $organisation_id
            version: $version
        ) {
            controller_id
            status
        }
    }
`;
export const CREATE_UCPE = gql`
    mutation CreateUcpe(
        $controller_id: String
        $name: String
        $description: String
        $address: String
        $device_id: String
        $place_id: Int
        $device_username: String
        $device_password: String
        $port: String
        $call_home: Boolean
        $created_by: String
        $organisation_id: String
    ) {
        createUcpe(
            controller_id: $controller_id
            name: $name
            description: $description
            address: $address
            device_id: $device_id
            place_id: $place_id
            device_username: $device_username
            device_password: $device_password
            port: $port
            call_home: $call_home
            created_by: $created_by
            organisation_id: $organisation_id
        ) {
            Device_ID
            status
        }
    }
`;
export const CREATE_SFC = gql`
    mutation CreateSFC(
        $controller_id: String
        $device_id: String
        $bridge_name: String
        $bridgeType: String
        $created_by: String
        $organisation_id: String
    ) {
        createSFC(
            controller_id: $controller_id
            device_id: $device_id
            bridge_name: $bridge_name
            bridgeType: $bridgeType
            created_by: $created_by
            organisation_id: $organisation_id
        ) {
            status
        }
    }
`;
export const CREATE_SCRIPT = gql`
    mutation CreateScript(
        $controller_id: String
        $device_id: [String]
        $scriptFile: String
        $file_name: String
        $scriptType: String
        $created_by: String
        $organisation_id: String
    ) {
        createScript(
            controller_id: $controller_id
            device_id: $device_id
            scriptFile: $scriptFile
            file_name: $file_name
            scriptType: $scriptType
            created_by: $created_by
            organisation_id: $organisation_id
        ) {
            status
        }
    }
`;
export const EDIT_UCPE = gql`
    mutation EditUcpe(
        $ucpeId: String
        $address: String
        $place_id: String
        $device_username: String
        $device_password: String
        $port: String
        $created_by: String
        $organisation_id: String
    ) {
        editUcpe(
            ucpeId: $ucpeId
            address: $address
            place_id: $place_id
            device_username: $device_username
            device_password: $device_password
            port: $port
            created_by: $created_by
            organisation_id: $organisation_id
        ) {
            Device_ID
            status
        }
    }
`;
export const CREATE_IMAGE = gql`
    mutation CreateImage(
        $image_name: String
        $image_version: String
        $image_logo: String
        $image_url: String
        $created_by: String
        $organisation_id: String
    ) {
        createImage(
            image_name: $image_name
            image_version: $image_version
            image_logo: $image_logo
            image_url: $image_url
            createdBy: $created_by
            organisation_id: $organisation_id
        ) {
            Image_ID
            status
        }
    }
`;
export const CREATE_VNF_TEMPLATE = gql`
    mutation createVnfTemplate(
        $controller_id: String
        $vnf_name: String
        $vm_image_id: String
        $vnf_numVcpus: Int
        $vnf_memory_in_MB: Int
        $vnf_storage_in_GB: Int
        $vnf_interfaces: [Interface]
        $vnf_cloud_init_data_source: String
        $vnf_cloud_init_drive_type: String
        $vnf_cloud_init_content_params: [cloudInitContent]
        $createdBy: String
        $vnf_description: String
        $vnf_provider: String
        $vnf_version: String
        $organisation_id: String
    ) {
        createVnfTemplate(
            controller_id: $controller_id
            vnf_name: $vnf_name
            vm_image_id: $vm_image_id
            vnf_numVcpus: $vnf_numVcpus
            vnf_memory_in_MB: $vnf_memory_in_MB
            vnf_storage_in_GB: $vnf_storage_in_GB
            vnf_interfaces: $vnf_interfaces
            vnf_cloud_init_data_source: $vnf_cloud_init_data_source
            vnf_cloud_init_drive_type: $vnf_cloud_init_drive_type
            vnf_cloud_init_content_params: $vnf_cloud_init_content_params
            createdBy: $createdBy
            vnf_description: $vnf_description
            vnf_provider: $vnf_provider
            vnf_version: $vnf_version
            organisation_id: $organisation_id
        ) {
            vnf_template_ID
            status
        }
    }
`;
/*export const CREATE_VNF = gql`
  mutation createVnf(
    $vnf_template_id: String
    $device_id: String
    $vnf_instance_name: String
    $vnf_type: String
    $vnf_version: String
    $vnf_flavour: String
    $vnf_ecManaged: Boolean
    $vnf_autoRestart: Boolean
    $vnf_ssh: String
    $vnf_web: String
    $vnf_connections: [VnfConnections]
    $vnf_props: [VnfProps]
    $createdBy: String
  ) {
    createVnf(
      vnf_template_id: $vnf_template_id
      device_id: $device_id
      vnf_instance_name: $vnf_instance_name
      vnf_type: $vnf_type
      vnf_version: $vnf_version
      vnf_flavour: $vnf_flavour
      vnf_ecManaged: $vnf_ecManaged
      vnf_autoRestart: $vnf_autoRestart
      vnf_ssh: $vnf_ssh
      vnf_web: $vnf_web
      vnf_connections: $vnf_connections
      vnf_props: $vnf_props
      createdBy: $createdBy
    ) {
      VNF_ID
      status
    }
  }
`;*/
export const CREATE_VNF = gql`
    mutation createVnf($vnf_muldata: String) {
        createVnf(vnf_muldata: $vnf_muldata,) {
            status
        }
    }
`;
export const CONTROL_VNF = gql`
    mutation controlVnf($vnfId: String, $type: String) {
        controlVnf(vnfId: $vnfId, type: $type) {
            status
        }
    }
`;
export const EDIT_VNF = gql`
    mutation editVnf(
        $vnf_ssh_ip: String,
        $vnf_ssh_port: String,
        $vnf_web: String,
        $vnf_id: String) {
        editVnf(
            vnf_ssh_ip: $vnf_ssh_ip,
            vnf_ssh_port: $vnf_ssh_port,
            vnf_web: $vnf_web,
            vnf_id: $vnf_id) {
            status
        }
    }
`;
export const EDIT_SDN_NOTIFICATION = gql`
    mutation EditSDNNotification($notification_template: String, $id: String) {editSDNNotification(
        notification_template: $notification_template, id: $id) {
        status
    }
    }
`;
export const CONFIGURE_UCPE = gql`
    mutation CreateConfigureUcpe($configucpes: String, $device_id: String) {
        createConfigureUcpe(configucpes: $configucpes, device_id: $device_id) {
            status
        }
    }
`;
export const DELETE_CONFIGURE_UCPE_INTERFACE = gql`
    mutation DeleteConfigureUcpeInterface($interface_name: [String], $device_id: String, $organisation_id: String) {
        deleteConfigureUcpeInterface(interface_name: $interface_name, device_id: $device_id, organisation_id: $organisation_id) {
            status
        }
    }
`;
export const DELETE_CONTROLLER = gql`
    mutation deleteController($controller_id: String, $updated_by: String) {
        deleteController(controller_id: $controller_id, updated_by: $updated_by) {
            controller_id
            status
        }
    }
`;
export const DELETE_SUBSCRIPTION = gql`
    mutation deleteOrganization($organisation_id: String, $subscription_id: String) {
        deleteSubscription(organisation_id: $organisation_id, subscription_id: $subscription_id) {
            status
        }
    }
`;
export const DELETE_UCPE = gql`
    mutation deleteUcpe($device_id: String, $updated_by: String) {
        deleteUcpe(device_id: $device_id, updated_by: $updated_by) {
            device_id
            status
        }
    }
`;
export const DELETE_SFC = gql`
    mutation DeleteSFC($bridge_id: String) {
        deleteSFC(bridge_id: $bridge_id) {
            status
        }
    }
`;
export const DELETE_SCRIPT = gql`
    mutation DeleteScript($uuid: String) {
        deleteScript(uuid: $uuid) {
            status
        }
    }
`;
export const DELETE_IMAGE = gql`
    mutation deleteImage($image_id: String, $updated_by: String) {
        deleteImage(image_id: $image_id, updated_by: $updated_by) {
            Image_ID
            status
        }
    }
`;
export const DELETE_VNF_TEMPLATE = gql`
    mutation deleteVnfTemplate($vnf_template_id: String) {
        deleteVnfTemplate(vnf_template_id: $vnf_template_id) {
            vnf_template_id
            status
        }
    }
`;
export const DELETE_VNF = gql`
    mutation deleteVnf($vnf_id: String) {
        deleteVnf(vnf_id: $vnf_id) {
            vnf_id
            status
        }
    }
`;
export const CREATE_SDN_PLACE = gql`
    mutation Place(
        $place_name: String
        $organisation: String
        $gps_longitude: Float
        $gps_latitude: Float
    ) {
        addSDNPlace(
            place_name: $place_name
            organisation: $organisation
            gps_latitude: $gps_latitude
            gps_longitude: $gps_longitude
        ) {
            id
        }
    }
`;
export const UPDATE_SDN_PLACE = gql`
    mutation Place(
        $place_id: String
        $name: String
        $orgId: String
        $type: String
        $loc: [Float]
    ) {
        updatePlace(
            place_id: $place_id
            name: $name
            orgId: $orgId
            loc: $loc
            type: $type
        ) {
            id
        }
    }
`;
export const DELETE_SDN_PLACE = gql`
    mutation deleteSDNPlace($id: Int) {
        deleteSDNPlace(id: $id) {
            id
        }
    }
`;
export const DELETE_SDN_NOTIFICATION = gql`
    mutation deleteSDNNotification($id: String) {
        deleteSDNNotification(id: $id) {
            status
        }
    }
`;
export const UPDATE_LIVE_CAMERA_URL = gql`
    mutation LiveCamera($id: String, $liveCamUrls: String) {
        updateLiveCameraUrl(id: $id, liveCamUrls: $liveCamUrls) {
            id
        }
    }
`;
export const CREATE_VPNS = gql`
    mutation VPNS($vpn_ip: String, $vpn_type: String, $organization: Float, $username: String, $password: String, $vpn_route: String, $ike_protocol: String, $esp_protocol: String, $interface: String, $secret_key: String) {
        addVpns(vpn_ip: $vpn_ip, vpn_type: $vpn_type, organization: $organization, username: $username, password: $password, vpn_route: $vpn_route, ike_protocol: $ike_protocol, esp_protocol: $esp_protocol, interface: $interface, secret_key: $secret_key) {
            id
        }
    }
`;
export const UPDATE_VPNS = gql`
    mutation VPNS($id: String, $vpn_ip: String, $vpn_type: String, $username: String, $password: String, $vpn_route: String, $ike_protocol: String, $esp_protocol: String, $interface: String, $secret_key: String, $organization: Float) {
        updateVpns(id: $id, vpn_ip: $vpn_ip, vpn_type: $vpn_type, username: $username, password: $password, vpn_route: $vpn_route, ike_protocol: $ike_protocol, esp_protocol: $esp_protocol, interface: $interface, secret_key: $secret_key, organization: $organization) {
            id
        }
    }
`;
export const DELETE_VPNS = gql`
    mutation Vpns($id: String) {
        deleteVpns(id: $id) {
            id
        }
    }
`;
export const CREATE_RTSP = gql`
    mutation RTSP($vpn: String, $camera_url: String, $camera_protocol: String, $camera_name: String ) {
        addRtspProxy(vpn: $vpn, camera_url: $camera_url, camera_protocol: $camera_protocol, camera_name: $camera_name) {
            id
        }
    }
`;
export const UPDATE_RTSP = gql`
    mutation RTSP($id: String, $vpn: String, $camera_url: String, $camera_protocol: String, $camera_name: String ) {
        addRtspProxy(id: $id, vpn: $vpn, camera_url: $camera_url, camera_protocol: $camera_protocol, camera_name: $camera_name) {
            id
        }
    }
`;
export const DELETE_RTSP = gql`
    mutation RTSP($id: String) {
        deleteRtspProxy(id: $id) {
            id
        }
    }
`;
export const CREATE_BILLING_INVOICE = gql`
    mutation BillingUtilizationItems($data: String ) {
        addBillingInvoice(data: $data) {
            data
        }
    }
`;
export const UPDATE_BILLING_INVOICE_STATUS = gql`
    mutation BillingUtilizationItems($id: String, $status: String ) {
        updateBillingInvoice(status: $status, id: $id) {
            data
        }
    }
`;
export const MARK_OPPORTUNITY_FINANCE_APPROVED = gql`
    mutation MarkOpportunityFinanceApproved($opportunityId: String) {
        markOpportunityFinanceApproved(opportunityId: $opportunityId) {
            status
        }
    }
`;
export const DECLINE_BY_FINANCE = gql`
    mutation DeclineByFinance($opportunityId: String, $reason: String) {
        declineByFinance(opportunityId: $opportunityId, reason: $reason) {
            status
        }
    }
`;
export const OPPORTUNITY_ACTIVATE = gql`
    mutation ActivateOrder($opportunityId: String) {
        activateOrder(opportunityId: $opportunityId) {
            status
        }
    }
`;
export const UPDATE_SWITCH = gql`
    mutation UpdateSwitch(
        $orderid: String,
        $customer_name: String,
        $vlan: String,
        $name: String,
        $port: String,
        $incoming_port: String,
        $location: String
    ) {
        updateSwitch(
            orderid: $orderid,
            customer_name: $customer_name,
            vlan: $vlan,
            name: $name,
            port: $port,
            incoming_port: $incoming_port,
            location: $location
        ) {
            status
        }
    }
`;
export const UPDATE_ROUTER = gql`
    mutation UpdateRouter(
        $orderid: String,
        $customer_name: String,
        $interface: String,
        $description: String,
        $ip_address: String,
        $gateway: String,
        $location: String
    ) {
        updateRouter(
            orderid: $orderid,
            customer_name: $customer_name,
            interface: $interface,
            description: $description,
            ip_address: $ip_address,
            gateway: $gateway,
            location: $location
        ) {
            status
        }
    }
`;
export const UPDATE_ROUTER_NIGERIA = gql`
    mutation UpdateRouterNigeria(
        $opportunity: String,
        $router: String,
        $interface: String,
        $description: String,
        $ip_address: String,
        $gateway: String,
        $bandwidth: String
    ) {
        updateRouterNigeria(
            opportunity: $opportunity,
            router: $router,
            interface: $interface,
            description: $description,
            ip_address: $ip_address,
            gateway: $gateway,
            bandwidth: $bandwidth
        ) {
            status
        }
    }
`;
export const UPDATE_SWITCH_NIGERIA = gql`
    mutation UpdateSwitchNigeria(
        $formData: String
    ) {
        updateSwitchNigeria(
            formData: $formData
        ) {
            status
        }
    }
`;
export const UPDATE_BWMGR = gql`
    mutation UpdateBwmgr(
        $orderid: String,
        $customer_name: String,
        $addr: String,
        $bwin: String,
        $bwout: String,
        $name: String,
        $location: String
    ) {
        updateBwmgr(
            orderid: $orderid,
            customer_name: $customer_name,
            addr: $addr,
            bwin: $bwin,
            bwout: $bwout,
            name: $name,
            location: $location
        ) {
            status
        }
    }
`;
export const MARK_OPPORTUNITY_VOID = gql`
    mutation MarkOpportunityVoid($opportunityId: String, $region: String) {
        markOpportunityVoid(opportunityId: $opportunityId, region: $region) {
            status
        }
    }
`;
export const SAVE_CONNECTIVITY_INFO = gql`
    mutation SaveConnectivityInfo($opportunityId: String, $vLanId: String, $port: String, $ipAddress: String) {
        saveConnectivityInfo(opportunityId: $opportunityId, vLanId: $vLanId, port: $port, ipAddress: $ipAddress) {
            status
        }
    }
`;
export const SAVE_SITE_SURVEY = gql`
    mutation SaveSiteSurvey($formData: String) {
        saveSiteSurvey(formData: $formData) {
            status
        }
    }
`;
export const SAVE_PROJECT_INITIATE = gql`
    mutation SaveProjectInitiate($formData: String) {
        saveProjectInitiate(formData: $formData) {
            status
        }
    }
`;
export const SAVE_STORE_FORM = gql`
    mutation SaveStoreForm($formData: String) {
        saveStoreForm(formData: $formData) {
            status
        }
    }
`;
export const ADD_OPPORTUNITY_DOCUMENT = gql`
    mutation AddOpportunityDocument($opportunityId: String, $opportunity_file: String, $file_name: String, $comment: String) {
        addOpportunityDocument(opportunityId: $opportunityId, opportunity_file: $opportunity_file, file_name: $file_name, comment: $comment) {
            status
        }
    }
`;
export const UPDATE_OPPORTUNITY_DOCUMENT = gql`
    mutation UpdateOpportunityDocument($opportunityId: String, $opportunity_file: String, $file_name: String, $comment: String, $fileId: String) {
        updateOpportunityFileDian(opportunityId: $opportunityId, opportunity_file: $opportunity_file, file_name: $file_name, comment: $comment, fileId: $fileId) {
            status
        }
    }
`;
export const SAVE_PROJECT_FILE_DIAN = gql`
    mutation SaveProjectFileDian($opportunityId: String, $project_file: String, $file_name: String, $comment: String) {
        saveProjectFileDian(opportunityId: $opportunityId, project_file: $project_file, file_name: $file_name, comment: $comment) {
            status
        }
    }
`;
export const UPDATE_PROJECT_FILE_DIAN = gql`
    mutation UpdateProjectFileDian($opportunityId: String, $project_file: String, $file_name: String, $comment: String, $fileId: String) {
        updateProjectFileDian(opportunityId: $opportunityId, project_file: $project_file, file_name: $file_name, comment: $comment, fileId: $fileId) {
            status
        }
    }
`;
export const UPDATE_PROJECT_STATUS_DIAN = gql`
    mutation UpdateProjectStatusDian($opportunityId: String, $status: String, $user: String) {
        updateProjectStatusDian(opportunityId: $opportunityId, status: $status, user: $user) {
            status
        }
    }
`;
export const SAVE_ELASTIC_USER = gql`
    mutation SaveElasticUser($IpAddress: String, $vlanid: String, $opportunityId: String, $username: String, $password: String) {
        saveElasticUser(IpAddress: $IpAddress, vlanid: $vlanid, opportunityId: $opportunityId, username: $username, password: $password) {
            status
        }
    }
`;
export const DELETE_NIGERIA_PROJECT_FILE = gql`
    mutation DeleteProjectFile($id: String) {
        deleteProjectFile(id: $id) {
            status
        }
    }
`;
export const DELETE_NIGERIA_OPPORTUNITY_FILE = gql`
    mutation DeleteOpportunityFile($id: String) {
        deleteOpportunityFile(id: $id) {
            status
        }
    }
`;
export const UPLOAD_VIDEO_FILE = gql`
  mutation VideoFile($file: Upload!){
    uploadVideoFile(file: $file) {
        url
        filename
        mimetype
        encoding
    }
  }
`;
export const CREATE_VIDEO = gql`
  mutation Video($videoData: JSON){
    addVideo(videoData: $videoData) {
        success
        message      
    }
  }
`;
export const CREATE_ON_DEMAND_HEATMAP = gql`
  mutation onDemandHeatmap($cameraId: String, $startTime: String, $endTime: String, $objectType: String, $activeReportMenu: String, $threshold: String) {
    createOnDemandHeatmap(cameraId: $cameraId, startTime: $startTime, endTime: $endTime, objectType: $objectType, activeReportMenu: $activeReportMenu, threshold: $threshold) {
      success
    }
  }
`;

export const DELETE_PENDING_SUBSCRIPTION_BY_ID = gql`
    mutation SubscriptionPendingDelete($id: String) {
        deletePendingSubscription(id: $id) {
            id
        }
    }
`;

export const CREATE_PROCESS_WORKFLOW_STEPS = gql`
    mutation ProcessWorkFlow($processSteps: [JSON], $id: String) {
        addProcessWorkFlow(processSteps: $processSteps, id: $id) {
            success
        }
    }
`;

export const DELETE_PROCESS_WORKFLOW_STEPS = gql`
    mutation ProcessWorkFlow($id: String) {
        deleteProcessWorkFlow(id: $id) {
            id
        }
    }
`;

export const ADD_SITE_PHONES = gql`
    mutation SitePhones($phoneDetails: [JSON]) {
        addSitePhones(phoneDetails: $phoneDetails) {
            success
            response
            message
        }
    }
`;

export const UPDATE_SITE_PHONES = gql`
    mutation UpdateSitePhones($organization: String, $phoneDetails: JSON) {
        updateSitePhones(organization: $organization ,phoneDetails: $phoneDetails) {
            success
            response
            message
        }
    }
`;

export const CONVERT_VIEWERS_IMAGES_VIDEO = gql`
    mutation ViewersImagesVideo($images: [String], $startTime: Float, $endTime: Float, $cameraId: String) {
        convertViewersImages(images: $images, cameraId: $cameraId, startTime: $startTime, endTime: $endTime) {
            success
        }
    }
`;

export const ASSIGN_USERS_TO_GROUP = gql`
    mutation AssignUserToGroup($users: [String], $groupId: String) {
        assignUserToGroup(users: $users, groupId: $groupId) {
            success
        }
    }
`;

export const ADD_CAMERA_TO_NOTIFICATION_TEMPLATE = gql`
    mutation AssginCameraToNotifictionTemp($id: Int, $cameraDetails: [JSON]) {
        addAssginCameraToNotifictionTemp(id: $id,cameraDetails: $cameraDetails) {
            success
            response
        }
    }
`;

export const CREATE_PROCEED_NOTIFICATION_REPORT = gql`
    mutation ProceedNotificationReport(
        $startTime: Int
        $endTime: Int
        $agreedCount: Int
        $disagreedCount: Int
        $totalCount: Int
        $processedCount: Int
        $organization: String
    ) {
        createProeedNotificationReport(
            startTime: $startTime
            endTime: $endTime
            agreedCount: $agreedCount
            disagreedCount: $disagreedCount
            totalCount: $totalCount
            processedCount: $processedCount
            organization: $organization
        ) {
            success
        }
    }
`
export const CREATE_TRANSECTION = gql`
    mutation Transection(
        $customerName: String
        $amount: Float
        $remarks: String
        $description: String
        $organization: String
    ) {
        createTransection(
            customerName: $customerName
            amount: $amount
            remarks: $remarks
            description: $description
            organization: $organization
        ) {
            data
        }
    }
`;

export const DELETE_TRANSECTION = gql`
    mutation Transection(
        $id: String
    ) {
        deleteTransection(
            id: $id
        ) {
            data
        }
    }
`;

export const CREATE_SCHEDULE_REPORT = gql`
    mutation ScheduleReport(
        $organization: String
        $report_type: String
        $frequency_type: String
        $send_time: Int
        $notification_templates: [Int]
    ) {
        createScheduleReport(
            report_type: $report_type
            frequency_type: $frequency_type
            notification_templates: $notification_templates
            send_time: $send_time
            organization: $organization
        ) {
            success
        }
    }
`;

export const DELETE_SCHEDULE_REPORT = gql`
    mutation ScheduleReport(
        $id: Int
    ) {
        deleteScheduleReport(
            id: $id
        ) {
            success
        }
    }
`;

export const CREATE_SMTP_DATA = gql`
    mutation createSmtpData(
        $orgId: String
        $mailServer: String 
        $from: String 
        $userName: String 
        $password: String 
        $port: String
    ){
        createSmtpData(
            orgId: $orgId
            mailServer: $mailServer 
            from: $from 
            userName: $userName 
            password: $password 
            port: $port
        ){
            id
        }
    }
`;

export const START_LIVE_CAM_STREAM = gql`
    mutation LiveCamStream(
        $cameraId: String
    ){
        startLiveCamStream(
            cameraId: $cameraId
        ){
            status
        }
    }
`;