import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Popup from '../../Popup';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const ViewConnectionsPopup = ({
  openConnectionsPopup,
  setOpenConnectionsPopup,
  classes,
  item,
  setBgpAddView,
  setConnectionId,
  setConnCircleBEndIp,
  setBgpEditView,
  setBgpView
}) => {
  return (
    <>
      <Popup
        openPopup={openConnectionsPopup}
        setOpenPopup={setOpenConnectionsPopup}
        title="Configured Connections"
        width="md">
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ color: 'white' }}>
                <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                  <TableCell
                    align="center"
                    style={{ color: 'white', paddingLeft: '10px' }}>
                    Source/Destination Type
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Source/Destination <br /> Name
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Client <br /> Name
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Connection <br /> name
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Rate Limit
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Link Type
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    A-end <br /> VLAN
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    B-end <br /> VLAN
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="center">
                    BGP
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                ) : (
                  item
                    // ?.filter(
                    //   items =>
                    //     items.b_end_FCR_id !== undefined ||
                    //     items.b_end_port_id !== undefined
                    // )
                    ?.map(items => (
                      <TableRow key={items._id}>
                        <TableCell component="th" align="center" scope="row">
                          {items.b_end_FCR_id?.type ||
                            items.b_end_PORT_id?.type ||
                            items.b_end_port_id?.type ||
                            items.a_end_PORT_id?.type ||
                            items.a_end_port_id?.type ||
                            items.a_end_FCR_id?.type}
                        </TableCell>
                        <TableCell align="left">
                          {items.b_end_FCR_id?.name ||
                            items.a_end_FCR_id?.name ||
                            items.a_end_PORT_id?.name ||
                            items.a_end_port_id?.name ||
                            items.b_end_PORT_id?.name ||
                            items.b_end_port_id?.name}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">{items?.name}</TableCell>
                        <TableCell align="left">{items?.speed}</TableCell>
                        <TableCell align="left">{items?.link_type}</TableCell>
                        <TableCell align="left">
                          {items?.a_end_vlan_id || ''}
                        </TableCell>
                        <TableCell align="left">
                          {items?.b_end_vlan_id || ''}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '140px', display: 'flex' }}>
                          <IconButton
                            disabled={items.conn_type === 'port-port'}>
                            <AddCircleOutlineOutlinedIcon
                              className="icons"
                              style={{
                                color:
                                  items.conn_type === 'port-port'
                                    ? 'grey'
                                    : '#5a1f2a'
                              }}
                              onClick={() => {
                                setBgpAddView(true);
                                setConnectionId(items?._id);
                                setConnCircleBEndIp(items?.conn_cidr_a_ip);
                              }}
                            />
                          </IconButton>
                          <IconButton
                            disabled={items.conn_type === 'port-port'}>
                            <SettingsOutlinedIcon
                              className="icons"
                              style={{
                                color:
                                  items.conn_type === 'port-port'
                                    ? 'grey'
                                    : '#5a1f2a'
                              }}
                              onClick={() => {
                                setBgpEditView(true);
                                setConnectionId(items?._id);
                              }}
                            />
                          </IconButton>
                          <IconButton
                            disabled={items.conn_type === 'port-port'}
                            onClick={() => setBgpView(true)}>
                            <VisibilityOutlinedIcon
                              className="icons"
                              style={{
                                color:
                                  items.conn_type === 'port-port'
                                    ? 'grey'
                                    : '#5a1f2a'
                              }}
                              onClick={() => {
                                setBgpView(true);
                                setConnectionId(items?._id);
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => setOpenConnectionsPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default ViewConnectionsPopup;
