import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Invoice from '../components/reports/Invoice';

function DownloadPDFButton({ invoiceId, t, userInfo, invoiceData, invoiceReport }) {
    const [ready, setReady] = React.useState(false);
    React.useEffect(()=> {
        setTimeout(() => {
            setReady(true);
        }, 1000);
    }, []);
    
    return (
        <>
            {ready &&
                <div style={ !invoiceReport ? { float: "right", marginRight: 17 } : null}>
                    <PDFDownloadLink key={invoiceId} document={<Invoice key={invoiceId} invoice={invoiceData} t={t} userInfo={userInfo} />} fileName={`${invoiceData.invoiceDate}-invoice.pdf`}>
                        {({ blob, url, loading, error }) => (loading ? '' : 
                            <Button variant="outlined" color="primary">{t("download.label")}</Button>
                        )}
                    </PDFDownloadLink>
                </div>
            }
        </>
    );
}

export default DownloadPDFButton;
