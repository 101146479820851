import React from 'react';
import { marginGenerator } from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ServiceItem from '../ServiceItem';

const ServicesListPanel = ({
  t,
  serviceList,
  handleSubscribeClick
}) => {
  return (
    <div>
      <Typography
        variant={'subtitle2'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-32')
        }}>
        {t('showingAll.label')} {serviceList.length}{' '}
        {t('availableSolutions.label')}
      </Typography>
      {serviceList.map((item, index) => (
        <ServiceItem data={item} key={index} index={index} handleSubscribeClick={handleSubscribeClick}/>
      ))}
    </div>
  );
};

ServicesListPanel.propTypes = {
  serviceList: PropTypes.array.isRequired,
  handleServiceItemClick: PropTypes.func.isRequired
};

export default ServicesListPanel;
