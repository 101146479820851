import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PrepaymentPanelItem from './PrepaymentPanelItem';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import PrepaymentFormModal from './PrepaymentFormModal'
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import {
    GET_PREPAYMENT
} from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_PREPAYMENT, DELETE_PREPAYMENT } from '../../mutation';



const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function PrepaymentForm({
    t,
    allowConfigure,
    data,
    menuItems,
    users,
    departments
}) {
    const classes = useStyles();
    const subscriptionId = data.id;

    const [filterStateData, setFilterStateData] = useState({...initFilterState});
    const [PrepaymentModalState, setPrepaymentModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedPrepaymentItem, setSelectedPrepaymentItem] = useState(null);
    const [PrepaymentFormModalOpen, setPrepaymentFormModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({BSignature: "", MMSignature: "", CMSignature: "", GMSignature:""});
   

    const {
       data: userdata,
      loading: userDataLoading,
      error: userDataError
     } = useQuery(GET_USER_INFO);

      const [getAllPrepayment, {
     loading,
       data: PrepaymentList
    }] = useLazyQuery(GET_PREPAYMENT, {
      fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllPrepayment({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllPrepayment({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [createPrepayment] = useMutation(CREATE_PREPAYMENT, {
        onError: error => {
          //  Message.error(error);
        },
        onCompleted: () => {
            Message.success('Prepayment request created successfully');
            setPrepaymentModalState({clearData: true, btnLoading: false});
            setSelectedPrepaymentItem(null);
            setSignatureType("")
            setPrepaymentFormModalOpen(false);
            return getAllPrepayment({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deletePrepayment, { loading: deletePrepaymentLoading }] = useMutation(DELETE_PREPAYMENT, {
        onError: error => {
           Message.error(error);
        },
        onCompleted: () => {
            Message.success('Prepayment deleted successfully');
            return getAllPrepayment({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handlePrepaymentSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
     //   debugger;
        return createPrepayment({ variables: formData,});
    }

    const columns = [
        {
            label: 'Supplier name',
            key: "Sname"
        },
        {
            label: "Supplier code",
            key: "Scode"
        },
        {
            label: 'Supplier Phone',
            key: "Sphone"
        },
        {
            label: 'Total cost',
            key: "TCost"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const handleNewPrepayment = () => {
        setPrepaymentFormModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }
    
    const filterList = [
        {
          label: t("search.label"),
          handleChange: (evt) => {
              console.log("evt.target.value: ", evt.target.value);
              setFilterStateData({...filterStateData, search: evt.target.value});
          },
          value: filterStateData.search,
          type: "text",
          styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
      },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
              setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },

        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
              setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        }
    ];
     
    const handleAddPrepayment = (Prepayment) => {
        setPrepaymentFormModalOpen(true);
        setSelectedPrepaymentItem(Prepayment);
    }

    const handleMenuItemClick = (type, rowItem) => {
        setPrepaymentModalState({clearData: false, btnLoading: false});
        setSelectedPrepaymentItem(rowItem);
        if(type === "sign"){

           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setPrepaymentFormModalOpen(true)
        }else if(type === 'delete'){
            console.log("rowItem prepayment ", rowItem.id);
            if (
                window.confirm('Are you sure you want to close this request?') ===
                false
            ) {
                return false;
            }
            return deletePrepayment({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }


    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewPrepayment}
                        >
                            {"Add New Request"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                        <TableCell key={index} style={item.style ? item.style : null} >
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    { console.log("PrepaymentList == ", PrepaymentList)}
                      {!loading ? PrepaymentList && PrepaymentList.getAllPrepayment.data && PrepaymentList.getAllPrepayment.data.length > 0 ?
                            PrepaymentList.getAllPrepayment.data.map((rowitem, rowIndex) => (
                                  <PrepaymentPanelItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    menuItems={menuItems}
                                    handleMenuItemClick={handleMenuItemClick}
                                    allowConfigure={allowConfigure}
                                  />
                              )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow>
                            : null
                        }
                        {loading &&
                         <TableRow>
                         <TableCell colSpan={columns.length}>
                             <div style={{textAlign: "center", width: "100%"}}>
                                 <CircularProgress size={20} color="primary" />
                             </div>
                         </TableCell>
                     </TableRow>
                 }
                </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            
            <PrepaymentFormModal
                open={PrepaymentFormModalOpen}
                handleClose={evt => {setPrepaymentFormModalOpen(false);
                setSelectedPrepaymentItem(null);}}
                modalState={PrepaymentModalState}
                users={users}
                userdata={userdata && userdata.userInfo}
                data={selectedPrepaymentItem}
                departments={departments}
                signatures={signatureState}
                handleOpenSignPanModal={handleOpenSignPanModal}
                handlePrepaymentSubmit={handlePrepaymentSubmit}
            />

            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    );
}

PrepaymentForm.propTypes = {

    t: PropTypes.func.isRequired,
   // list: PropTypes.object,
  //  menuItems: PropTypes.array.isRequired,
  //  handleMenuItemClick: PropTypes.func.isRequired
};

export default PrepaymentForm;
