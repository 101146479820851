import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { REQUIRED_ERROR } from '../../../../../../utils/constants';
import Message from '../../../../../../components/Message';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '5px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: 'black',
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        background: '#5a1f2a',
        fontSize: '15px',
        color: 'white',
        borderRadius: '5px',
        border: '1px solid',
        marginLeft: '15px',
        "&:hover": {
            background: "#5a1f2ad1",
            color: 'white'
          }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    }
}));

const initFormState = { mailServer: "", from: "", userName: "", password: "", port: ""};

const SmtpSetting = ({ 
    t, 
    handleSubmit, 
    loading, 
    orgDetails,
    dataLoading
}) => {
    const classes = useStyles();
    const [formState, setFormState] = React.useState({ ...initFormState });

    const handleInputChange = (evt) => {
        const {name, value} = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    React.useEffect(() => {
        if(orgDetails){
            setFormState({ 
                mailServer: orgDetails.mail_server ? orgDetails.mail_server : "", 
                from: orgDetails.mail_from ? orgDetails.mail_from : "", 
                userName: orgDetails.mail_username ? orgDetails.mail_username : "", 
                password: orgDetails.mail_password ? orgDetails.mail_password : "", 
                port: orgDetails.mail_port ? orgDetails.mail_port : "" 
            });
        }
    }, [orgDetails]);

    const formValidationCheck = () => {
        if (!formState.mailServer || !formState.from || !formState.userName || !formState.password || !formState.port) {
            Message.error(REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit(formState);
    }

    const handleFormSubmit = (formState) => {
        handleSubmit(formState)
    }

    return (
        <>
            <main>
                <div style={{
                    borderRadius: 5,
                    padding: '10px 50px'
                }}>
                {dataLoading &&
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                }
                <Grid container style={{marginBottom: '15px', marginTop: '15px'} }>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{marginBottom: '20px', marginTop: '15px'} }>
                            {t('mailServer.label')} *
                        </Typography>
                        <Input
                            disabled={dataLoading}
                            value={formState.mailServer}
                            type={'text'}
                            name="mailServer"
                            className={classes.inputStyle}
                            placeholder={"Mail Server"}
                            style={{ maxWidth: '90%', width: '900%' }}
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{marginBottom: '20px', marginTop: '15px'} }>
                            {t('from.label')} *
                        </Typography>
                        <Input
                            disabled={dataLoading}
                            value={formState.from}
                            type={'text'}
                            className={classes.inputStyle}
                            placeholder={"From"}
                            style={{ maxWidth: '90%', width: '900%' }}
                            name="from"
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom: '15px', marginTop: '15px'} }>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{marginBottom: '20px', marginTop: '15px', color:'#333333'} }>
                            {t('userName.label')} *
                        </Typography>
                        <Input
                            disabled={dataLoading}
                            value={formState.userName}
                            type={'text'}
                            placeholder={"Username"}
                            className={classes.inputStyle}
                            style={{ maxWidth: '90%', width: '900%' }}
                            color={'secondary'}
                            name="userName"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{marginBottom: '20px', marginTop: '15px', color:'#333333'} }>
                            {t('password.label')} *
                        </Typography>
                        <Input
                            disabled={dataLoading}
                            value={formState.password}
                            type="password"
                            className={classes.inputStyle}
                            placeholder={"Password"}
                            style={{ maxWidth: '90%', width: '900%' }}
                            color={'secondary'}
                            name="password"
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom: '15px', marginTop: '15px'} }>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{marginBottom: '20px', marginTop: '15px', color:'#333333'} }>
                            {t('port.label')} *
                        </Typography>
                        <Input
                            disabled={dataLoading}
                            value={formState.port}
                            type={'text'}
                            placeholder={"Port"}
                            className={classes.inputStyle}
                            style={{ maxWidth: '90%', width: '900%' }}
                            color={'secondary'}
                            name="port"
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <div className={classes.placeBtn}>
                    <Button color="primary" className={classes.btn}
                        onClick={formValidationCheck}>
                        {loading ? <CircularProgress color="inherit" size={20} /> : (orgDetails && orgDetails.mail_server) ? "Edit" : "Save"}
                    </Button>
                </div>
                </div>
            </main>
        </>
    );
};

export default SmtpSetting
