import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
// import { USER_ROLES } from "../../../../../utils/constants";
import { useEffect } from 'react';
import { dateFormatterLLL } from '../../../../../utils';

const ProjectHistoryViewItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName
}) => {
    const commonClasses = commonStyles();
    // const role = localStorage.getItem('role');
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.auto_generated_project_id ? data.auto_generated_project_id : ((data.project_info && data.project_info.case_number) ? data.project_info.case_number : 'N/A')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.customer_name) ? data.project_info.customer_name : 'N/A'} /
                            {(data.project_info && data.project_info.organization_name) ? data.project_info.organization_name : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.project_owner) ? data.project_info.project_owner : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.project_type) ? data.project_info.project_type : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        {data.history && data.history.length ?
                            data.history.map((item, index) => (
                                <React.Fragment>
                                    <Typography variant={'body2'} style={{marginTop: '5px'}}><span style={{ fontWeight: 'bold' }}>{item.project_at}</span> : {item.created_at ? dateFormatterLLL(data.created_at) : '-'}</Typography>
                                    {item.comment ? <Typography variant={'body2'}>Comment : {item.last_team_member_name} - {item.comment}</Typography> : ''}
                                </React.Fragment>
                            )) : 'NA'}
                    </Grid>
                </Grid>
            </TableCell>
            {/* <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.project_at ? data.project_at : 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell> */}
        </TableRow>
    );
};
ProjectHistoryViewItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(ProjectHistoryViewItem);