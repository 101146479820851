import React, {useState} from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import PlacePanelItem from '../PlacePanelItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function PlacePanel({
    t,
    menuItems,
    list,
    handleCreateButtonClick,
    handleMenuItemClick,
    loading,
    error,
    allowConfigure,
    handleSearch,
    page,
    handlePageChange
}) {
    
    const classes = useStyles();
    const [goToPage, setGoToPage]           = useState(null);
    let columns = [
        {
            label: t('places.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('createdBy.label'),
            key: "createdByName"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('numberOfCam.label'),
            key: "noOfCam"
        },
        {
            label: t('gpsCoOrdinates.label'),
            key: "gpsCoOrdinate"
        }
    ];
    if (allowConfigure) {
        columns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
    }
    
    const handleSearchChange = (val) => {
        handleSearch("place", val);
    }
    return (
        <div>
            {allowConfigure &&
                <Grid container justify={'space-between'}>
                    <Grid item>{<SearchBox handleSearch={handleSearchChange} />}</Grid>
                    <Grid item>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() =>
                                handleCreateButtonClick('place_template')
                            }>
                            {t('createNow.label')}
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list.getAllPlaceList && list.getAllPlaceList.places.length > 0 ?
                                    list.getAllPlaceList.places.map((rowitem, rowIndex) => (
                                        <PlacePanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                            allowConfigure={allowConfigure}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color="primary"/>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    { !loading &&
                        <>
                            <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                                <Grid item xs={2}>
                                    <span>Total {Math.ceil(get(list, 'getAllPlaceList.count', null)/20)} page,  {get(list, 'getAllPlaceList.count', null)} Row</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Pagination 
                                        color="secondary" 
                                        count={list && list.getAllPlaceList && list.getAllPlaceList.count ? Math.ceil(list.getAllPlaceList.count/20) : 0} 
                                        page={page} 
                                        onChange={(evt, value) => {
                                            handlePageChange("place", value);
                                        }} 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <span>Go to page </span>
                                </Grid>    
                                <Grid item xs={1}>
                                    <Input
                                        value={goToPage}
                                        type="number"
                                        style={{ minWidth: 100 }}
                                        color={'secondary'}
                                        onChange={evt => {
                                            let maxRecord = list && list.getAllPlaceList ? parseInt(Math.ceil(list.getAllPlaceList.count/20)) : 0;
                                            if(parseInt(evt.target.value) > maxRecord){
                                                return null;
                                            }
                                            setGoToPage(evt.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        onClick={()=> handlePageChange('place',parseInt(goToPage))}
                                        variant={'outlined'}
                                        color="primary"
                                        disabled={!goToPage}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Go
                                    </Button>   
                                </Grid>
                            </Grid>
                        </>
                    }
                </TableContainer>
            </Grid>
        </div>
    );
}

PlacePanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default PlacePanel;
