import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { getUrl } from '../../utils';
import { TicketingSystem } from '../../routes/routePaths';
import { useHistory } from 'react-router';

const PieChartWrapper = ({ data, dataKeyXAxis, stackKeyNames, type }) => {
  const history = useHistory();
  const onChartClick = params => {
    const path = getUrl(TicketingSystem.Subscription, params.data.id);
    history.push({ pathname: path });
    return null;
  };
  let onEvents = {
    click: onChartClick
  };

  return (
    <div>
      <ReactEcharts
        onEvents={type && type === 'click' && onEvents}
        option={{
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          legend: {
            type: 'scroll',
            orient: 'horizontal',
            data: stackKeyNames
          },
          series: [
            {
              type: 'pie',
              radius: '60%',
              center: ['50%', '50%'],
              selectedMode: 'single',
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }}
      />
    </div>
  );
};

export default PieChartWrapper;
