import React, { useState, useRef } from 'react';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import SecondaryLayout from './components/SecondaryLayout';
import { colors } from '../../theme/colors';
import { useMutation } from '@apollo/react-hooks';
import { RESET_PASSWORD_OTP_VERIFICATION, FORGOT_PASSWORD_EMAIL_VERIFICATION, RESEND_OTP } from './mutations';
import {  Paths } from '../../routes/routePaths';
import Message from '../../components/Message';
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { REQUIRED_ERROR } from '../../utils/constants';
import VerifyEmailModal from './components/verifyEmailModal';

function ForgotPassword({ t }) {

  const [email, setEmail] = useState('');
  const [verifyEmailModalState, setVerifyEmailModalState] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [ emailVerified, setEmailVerified ] = useState(false);
  const [ verifiedUserState, setVerifiedUserState ] = useState(null);

  const loginBtn = useRef(null);
  const [forgotPasswordEmailVerification] = useMutation(FORGOT_PASSWORD_EMAIL_VERIFICATION, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      const response = data.forgotPasswordEmailVerification;
      if(response.success && response.msg === 'email_verified' && response.id){
        setEmailVerified(true);
        setVerifiedUserState(response.id);
        setTimeout(() => {
          setVerifyEmailModalState(true);
        }, 2000);
      }else{
        Message.error("Invalid email address");
        setEmailVerified(false);
        setVerifyEmailModalState(false);
        setVerifiedUserState(null);
      }
    }
  });

  const [verifyResetPasswordOTP] = useMutation(RESET_PASSWORD_OTP_VERIFICATION, {
    onError: error => {
      setVerificationLoading(false);
      Message.success('Invalid OTP!');
    },
    onCompleted: data => {
      setVerificationLoading(false);
      if(data.verifyResetPasswordOTP && data.verifyResetPasswordOTP.id){
        Message.success('OTP verified successfully. Please reset your password.');
        setTimeout(() => {
          setVerifyEmailModalState(false);
          let URL = `${Paths.ResetPassword}?id=${data.verifyResetPasswordOTP.id}&verify=${data.verifyResetPasswordOTP.accessToken}`;
          window.location.replace(URL);
        }, 1500);
      }else{
        Message.success('Invalid OTP!');
      }
    }
  });

  const [resendOTP] = useMutation(RESEND_OTP, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if(data.resendOTP && data.resendOTP.email){
        Message.success('OTP sent successfully on your registerd email address');
      }
    }
  });

  const handleVerifyOTPClick = (data) => {
    setVerificationLoading(true);
    setTimeout(() => {
      return verifyResetPasswordOTP({
        variables: {
          id: verifiedUserState,
          otp: data.otp
        }
      });
    }, 1500);
  }

  const handleResendOtp = () => {
    if(emailVerified && email){
      return resendOTP({variables:{email:email, action:'forgot_password'}});
    }
  }

  return (
    <SecondaryLayout
      scope={'ForgotPassword'}
      contentStyle={{ maxWidth: '328px', ...marginGenerator('mt-162') }}>
      <Header
        title={t('forgotPassword.label')}
        titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
        subText={t('forgotSub.label')}
        subtitleVariant={'h2'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0, fontSize:'14px' }}
      />
      <Grid container style={{ ...marginGenerator('mt-24') }}>
        <Input
          value={email}
          type={'text'}
          placeholder={`${t('email.label')} *`}
          style={{minWidth:'326px'}}
          color={'secondary'}
          onChange={evt => setEmail(evt.target.value)}
        />
        { emailVerified ? 
          <Button
          ref={loginBtn}
          variant={'contained'}
          size={'large'}
          style={{ ...marginGenerator('mt-24'), backgroundColor: '#43a047', color:'#fff' }}
          >
            <Icon
            component={() => (
                <CheckCircleIcon
                style={{ marginTop: '2px', marginRight: '6px', color:'#fff' }}
                />
            )}
            alt={'icon'}
            />
            {t('verifiedEmailBtn.label')}
          </Button>
        :
          <Button
          ref={loginBtn}
          variant={'contained'}
          size={'large'}
          style={{ ...marginGenerator('mt-24') }}
          color={'primary'}
          onClick={() => {
            if (!email) {
              Message.error(REQUIRED_ERROR);
              return null;
            }
            return forgotPasswordEmailVerification({
              variables: {
                email: email
              }
            });
          }}>
          {t('sendLinkBtn.label')}
          </Button>
        }
      </Grid>
      { verifiedUserState &&
        <VerifyEmailModal
          t={t}
          open={verifyEmailModalState}
          handleClose={() => setVerifyEmailModalState(false)}
          handleVerifyEmailClick={handleVerifyOTPClick}
          handleResendOtp={handleResendOtp}
          loading={verificationLoading}
          verifiedEmail={email}
          type={'reset_password'}
        />
      }
    </SecondaryLayout>
  );
}

export default withTranslation()(ForgotPassword);
