import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const getServiceProviders = async (page = '', limit = '', word = '') => {
  try {
    const response = await axios.get(
      `serviceProvider?page=${page}&limit=${limit}&search=${word}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getServiceProviderById = async id => {
  try {
    const response = await axios.get(`serviceProvider/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const addServiceProvider = async payload => {
  try {
    const response = await axios.post(`serviceProvider`, payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const updateServiceProviderById = async (id, payload) => {
  try {
    const response = await axios.put(`serviceProvider/${id}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deleteServiceProviderById = async id => {
  try {
    const response = await axios.delete(`serviceProvider/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

export {
  getServiceProviders,
  getServiceProviderById,
  addServiceProvider,
  updateServiceProviderById,
  deleteServiceProviderById
};
