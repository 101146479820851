import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/camera.png';

const styles = StyleSheet.create({
    container: {
        // marginRight: 1,
        marginBottom: 2,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        height: 100,
        fontSize: 8,
    },
    leftBox: {
        padding: "7px",
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
    },
    rightContainer: {
        flexDirection: 'column',
        paddingTop: 30,
        paddingLeft: 0,
        paddingBottom: 0,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    logo: {
        width: '70px',
        height: '30px'
    },
    rightColumn: {
        flexDirection: 'column',
        width: '33%',
        marginLeft: 5,
        marginBottom: 5
    },
    floatRight: {
        // textAlign: 'right'
    },
    HeadingRed: {
        fontSize: 12,
        fontStyle: 'bold',
        textDecorationColor: 'Red',
        textAlign: 'center'
    },
    centerHeading: {
        fontStyle: 'bold',
    },
    leftColumn: {
        flexDirection: 'column',
        width: '33%',
        marginRight: 2
    },
    centerColumn: {
        width: '35%',
        padding: "7px",
        flexDirection: 'column',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
        marginRight: 3,
    },
    lastColumn: {
        width: '29%',
        padding: "7px",
        flexDirection: 'column',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
        marginRight: 3,
    },
    financeRow: {
        flexDirection: 'row',
        fontStyle: 'italic',
        fontSize: 10
    }
});
const InvoiceFooterMenu = ({ invoiceCompanyDetails }) => (
    <Fragment>
        <View style={styles.container}>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>Finance Representative Sign Off: </Text>
                <Text>&nbsp;</Text>
                <View style={styles.financeRow}>
                    <Text>Olayemi Okunnu   </Text>
                    <Image
                        src={logo}
                        style={styles.logo}
                    />
                    <Text>  2022/07/07</Text>
                </View>
                <Text>________________   ________________   _____________</Text>
                <Text> Full Name                      Signature                       Date</Text>
            </View>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>Customer Acknowledgement:</Text>
                <Text>&nbsp;</Text>
                <View style={styles.financeRow}>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text>  2022/07/07</Text>
                </View>
                <Text>________________   ________________   _____________</Text>
                <Text> Full Name                      Signature                       Date</Text>
            </View>
            <View style={styles.lastColumn}>
                <View>

                <Text style={styles.centerHeading}>Sub-Total ({invoiceCompanyDetails.CUR})                        {(invoiceCompanyDetails.QTY * invoiceCompanyDetails.NETPRI).toFixed(2)}</Text>
                </View>
                <Text>&nbsp;</Text>
                <Text>&nbsp;</Text>
                <Text style={styles.centerHeading}>VAT ({invoiceCompanyDetails.CUR})           {invoiceCompanyDetails.RATTAXLIN} %             {(invoiceCompanyDetails.QTY * invoiceCompanyDetails.NETPRI * 0.075).toFixed(2)}</Text>
                <Text>&nbsp;</Text>
                <Text style={styles.centerHeading}>Total Due ({invoiceCompanyDetails.CUR})                        {(invoiceCompanyDetails.QTY * invoiceCompanyDetails.NETPRI * 1.075).toFixed(2)}</Text>
            </View>
        </View>
        {/* <View style={styles.container}>
            <View style={styles.detailColumn}>
                <Text style={styles.name}>Luke Skywalker</Text>
                <Text style={styles.subtitle}>Jedi Master</Text>
            </View>
            <View style={styles.linkColumn}>
                <Link href="mailto:luke@theforce.com" style={styles.link}>
                    luke@theforce.com
                </Link>
            </View>
        </View> */}
    </Fragment>
);
export default InvoiceFooterMenu