import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {useMutation} from '@apollo/react-hooks';
import StepperFrom from '../../../../components/NotificationEventsPanel/StepsForProcessAgreeAction/StepperForm';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const initState = {isCompleted: false, comments: "", title: "", description: ""};

const OcrProcessWorkFlow = ({
    t,
    open,
    onClose,
    stepData,
    handleSubmitOfProcessSteps,
    addStespsToProcessLoading,
    projectName,
    ocrUsersListLoading,
    allOcrUsersData,
    filesDetails
}) => {
    const classes = useStyles();
    const [activeCount, setActiveCount] = useState(0);
    const [stepFormDataState, setStepFormDataState] = useState([]);

    const handleChange = (evt) => {
        const {name, value} = evt.target;
        setStepFormDataState(prevState => {
            let newState = [...prevState];
            if(name === "isCompleted"){
                newState[activeCount][name] = evt.target.checked;
            }else if (name === "approvedOrRejectStatus"){
                newState[activeCount][name] = value === 'true'? true: false;
                //newState[activeCount]["isApprovalProcess"] = true;
                newState[activeCount]["signatureProcess"] = false;
            }else{
                newState[activeCount][name] = value;
            }
            return newState;
        });
    }

    const handleChangeOfAutoComplete = (name, value) => {
        setStepFormDataState(prevState => {
            let newState = [...prevState];
            if(name === "approvalUser" && value){
                newState[activeCount][name] = value.user_id;
                newState[activeCount]['optionForUser'] = value;
                newState[activeCount]["approvalUserOcrId"] = value.id;
            }else if (name === "fileId"){
                //newState[activeCount][name] = value.id;
                //newState[activeCount]["optionForFile"] = value;
                //newState[activeCount]["fileName"] = value.file_name;
            }
            return newState;
        });
    }

    const handleClick = (name, value) => {
        setStepFormDataState(prevState => {
            let newState = [...prevState];

            if(name === "signature"){
            newState[activeCount]["signatureProcess"] = true;
            //newState[activeCount]["isApprovalProcess"] = false;
            }else if(name === "isApproval"){
            //newState[activeCount]["isApprovalProcess"] = true;
            newState[activeCount]["signatureProcess"] = false;
            } else if(name === "") {
                //newState[activeCount]["isApprovalProcess"] = false;
                newState[activeCount]["signatureProcess"] = false;
            } else if(name === "signatureImg"){
                newState[activeCount][name] = value;
            }
            return newState;
        });
    }

    const updateProcessTime = () => {
        setStepFormDataState(prevState => {
            let newState = [...prevState];
            newState[activeCount].processTime = new Date();
            console.log("newState[activeCount].processTime: ", newState[activeCount].processTime);
            return newState;
        });
    };

    useEffect(() => {
        if(stepData && stepData.length){
            let initStp = [];
            stepData.map(itm => {
                let tmpObj = {
                               //isCompleted: false,
                               comments: "",
                               title: itm.title,
                               description: itm.description,
                               //status: itm.status,
                               processTime: null,
                               signature: itm.signature,
                               isApproval: itm.isApproval,
                               signatureProcess: false,
                               subscription: itm.subscription,
                               //signatureProcessComments: "",
                               //isApprovalProcess: false,
                               //approvalProcessComments: "",
                               signatureImg: "",
                               projectName: projectName,
                               ocrProcessId: itm._id,
                               //approvedOrRejectStatus: null,
                               ocrUsersListLoading,
                               allOcrUsersData,
                               //allFiles:filesDetails && filesDetails.files,
                               fileId: filesDetails && filesDetails.id,
                               fileName: filesDetails && filesDetails.file_name,
                               fileUrl: filesDetails && filesDetails.img_url,
                               approvalUser: '',
                               approvalUserOcrId: '',
                               optionForUser: '',
                               //optionForFile: '',
                               processOptions: ''
                            };
                initStp.push(tmpObj);
            });
            setStepFormDataState(initStp);
        }
    }, [stepData]);

    useEffect(() => {
      if(!open){
        setActiveCount(0);
        setStepFormDataState([]);
      }
    }, [open]);
    
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
    disableBackdropClick={true} 
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            <Typography variant="h4" align="center">{t("processWorkFlow.label")}</Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <Stepper activeStep={activeCount} sx={{ py: 3 }} alternativeLabel>
                {stepData && stepData.length > 0 && stepData.map((el) => (
                    <Step key={el.title}>
                        <StepLabel>{el.title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {stepData && stepData.length > 0 && stepData[activeCount] && 
            <StepperFrom 
              handleChange={handleChange} 
              handleClick={handleClick}
              stepFormData={stepFormDataState && stepFormDataState[activeCount] ? stepFormDataState[activeCount] : initState} 
              t={t} 
              data={stepData[activeCount]} 
              type="ocrProcess"
              handleChangeOfAutoComplete={handleChangeOfAutoComplete}
            />
              }
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={()=>{
                    setActiveCount((prev) => prev-1);
                }}
                style={{marginRight: 20}}
                disabled={activeCount === 0}
                color="secondary"
                variant="outlined"
            >
                {t('back.label')}
            </Button>
            <Button 
                onClick={()=> {
                    if(!stepFormDataState[activeCount].comments){
                        Message.error("Please fill out comments field!");
                        return null;
                    }
                    updateProcessTime();
                    if(stepData && stepData.length === activeCount+1){
                        handleSubmitOfProcessSteps(stepFormDataState);
                    } else {
                        setActiveCount((prev) => prev+1);
                    }
                }}
                variant="outlined"
                color="primary"
                autoFocus
            >
                {addStespsToProcessLoading ? <CircularProgress size={20} color="secondary" />
                 : (stepData.length === activeCount+1) ? t('submit.label') : t('next.label')} 
            </Button>      
        </DialogActions>
    </Dialog>
  )
}

export default OcrProcessWorkFlow