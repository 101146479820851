import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SUB_ADMIN_CUSTOMERS } from '../../ServiceManager/queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { TicketingSystem } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import UserItem from '../components/UserItem';
import { getUrl } from '../../../utils';
import SearchBox from '../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Dashboard },
  { label: 'customers.label', link: TicketingSystem.Customers }
];

const UsersList = ({ t }) => {
  const [ticketsSearched, setTicketsSearched] = useState(false);
  const [updatedSearchTickets, setUpdatedSearchTickets] = useState([]);
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchData, setSearchData] = useState('');

  const menuItems = [{ label: t('viewSites.label') }, { label: t('viewDetails.label') }];

  const history = useHistory();
  const location = useLocation();
  /*
        List Queries
    */

  const { loading, error, data, refetch: reloadUserList } = useQuery(
    GET_SUB_ADMIN_CUSTOMERS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  let CustomerListing = ticketsSearched
    ? updatedSearchTickets
    : data &&
      data.getSubAdminCustemers &&
      data.getSubAdminCustemers.length &&
      data.getSubAdminCustemers;

  // const [getSubAdminCustemers] = useLazyQuery(GET_SUB_ADMIN_CUSTOMERS, {
  //   fetchPolicy: 'no-cache',
  //   onError: error => {
  //     console.log('Error', error);
  //     setLoading(false);
  //   },
  //   onCompleted: data => {
  //     setLoading(false);
  //     if (data.getSubAdminCustemers) {
  //       let usersList = [];
  //       if (data.getSubAdminCustemers.users) {
  //         data.getSubAdminCustemers.users.map(itm => {
  //           let tmpData = { ...itm };

  //           usersList.push(tmpData);
  //         });
  //       }
  //       setTotalCount(
  //         data.getSubAdminCustemers.count ? data.getSubAdminCustemers.count : 0
  //       );
  //       setUsers(usersList);
  //     }
  //   }
  // });

  // useEffect(() => {
  //   setLoading(true);
  //   getSubAdminCustemers({ variables: { page } });
  // }, []);

  // useEffect(() => {
  //   if (location.search === `?action=reload`) {
  //     getSubAdminCustemers({ variables: { page: 1, search: '' } });
  //   }
  // }, [location]);

  // const handlePageChange = (event, value) => {
  //   setLoading(true);
  //   getSubAdminCustemers({ variables: { page: value, search: searchData } });
  //   setPage(value);
  // };

  // const handleSearchCustomers = query => {
  //   setPage(1);
  //   setLoading(true);
  //   setSearchData(query);
  //   getSubAdminCustemers({ variables: { page: 1, search: query } });
  // };

  const handleSearchCustomers = query => {
    if (query !== '') {
      const updatedList =
        data.getSubAdminCustemers &&
        data.getSubAdminCustemers.length &&
        data.getSubAdminCustemers.filter(
          (item, index) =>
            (item.firstName &&
              item.firstName != null &&
              item.firstName.toLowerCase().includes(query.toLowerCase())) ||
            (item.lastName &&
              item.lastName != null &&
              item.lastName.toLowerCase().includes(query.toLowerCase())) ||
            (item.email &&
              item.email != null &&
              item.email.toLowerCase().includes(query.toLowerCase())) ||
            (item.role &&
              item.role != null &&
              item.role.toLowerCase().includes(query.toLowerCase())) ||
            (item.id &&
              item.id != null &&
              item.id.toLowerCase().includes(query.toLowerCase())) ||
            (item.organisations &&
              item.organisations[0] &&
              item.organisations[0].name
                .toLowerCase()
                .includes(query.toLowerCase()))
        );
      setTicketsSearched(true);
      setUpdatedSearchTickets(updatedList);
    } else {
      setTicketsSearched(false);
      setUpdatedSearchTickets([]);
    }
  };

  const handleMenuItemClick = (action, data) => {
    if (action.label === 'View Sites') {
      const id = data.id;
      const path = getUrl(TicketingSystem.SubscriptionSite, id);
      history.push(path);
    }
    if (action.label === 'View Details') {
      const id = data.id;
      const path = getUrl(TicketingSystem.CustomersDetails, id);
      history.push(path);
    }
  };

  const columns = [
    {
      label: t('organisations.label'),
      key: 'organisations'
    },
    {
      label: t('name.label'),
      key: 'name'
    },
    {
      label: t('email.label'),
      key: 'email'
    },
    {
      label: t('role.label'),
      key: 'role'
    },
    {
      label: t('createdDate.label'),
      key: 'createdDate'
    },
    {
      label: t('action.label'),
      key: 'button'
    }
  ];

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('customers.label')}
        subText={`${t('customersSubTxt.label')}`}
      />
      <SearchBox handleSearch={handleSearchCustomers} />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                CustomerListing.length > 0 ? (
                  CustomerListing.map((rowitem, rowIndex) => (
                    <UserItem
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                      menuItems={menuItems}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Records not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant={'subtitle2'}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div style={{ width: '100%', marginTop: 24, marginBottom: 24 }}>
          <Pagination
            style={{ float: 'right' }}
            count={Math.ceil(totalCount / 20)}
            color="secondary"
            page={page}
            onChange={handlePageChange}
          />
        </div> */}
      </Grid>
    </Layout>
  );
};
export default withTranslation()(UsersList);
