import "date-fns";
import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {IsNumeric} from '../../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadPDFButton from './DownloadPDFButton';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";

const TableCell = withStyles({
    root: {
        borderBottom: 0
    }
})(MuiTableCell);
function calculateTotalHrs(date) {
    let month = date.getUTCMonth() + 1; //months from 1-12
    let year = date.getUTCFullYear();
    let days = new Date(year, month, 0).getDate();
    return parseInt(days) * 24;
}
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 40
    },
    loader: {
        width: "100%",
        textAlign: "center"
    },
    yearMonthFilter: {
        padding: 10
    }
}));
const initAddOns = {moduleName: "", number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0};
const initCapacityState = {number: 0, mrcCost: 0, totalAmount: 0, freeTierDiscount: 0, finalPrice: 0};
const initTotal = {taxes: 0, totalCost: 0, totalDiscount: 0, totalPayableAmt: 0};
const BillingDiaUtilization = ({
                                   t,
                                   loadingData,
                                   billingData,
                                   handleChangeDateFilter,
                                   startDate,
                                   allowConfigure,
                                   handleSubmitInvoice,
                                   saveBtnLoading,
                                   handlePaidInvoice,
                                   paidBtnLoading,
                                   userInfo,
                                   currency,
                                   handleChangeCurrency,
                                   currencyList
                               }) => {
    const classes = useStyles();
    const [notAllowChange, setNotAllowChange] = useState(true);
    const [addonsState, setAddonsState] = useState([initAddOns]);
    const [totalState, setTotalState] = useState(initTotal);
    const [capacityState, setCapacityState] = useState(initCapacityState);
    const [notificationState, setNotificationState] = useState([]);
    const [modelsEnabledState, setModelsEnabledState] = useState([]);
    const [invoicePaid, setInvoicePaid] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);
    const [readyState, setReadyState] = useState(false);
    const [invoiceBillData, setInvoiceBillData] = useState({startDate: null, endDate: null, invoiceNumber: "", totalPayableAmount: 0, currancy: "", invoiceDate: ""});
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const ref = React.createRef();
    useEffect(() => {
        if (billingData) {
            let noOfCapacity = 0;
            let notifications = [];
            let models = [];
            setAddonsState([initAddOns]);
            setCapacityState(initCapacityState);
            setTotalState(initTotal);
            setInvoicePaid(false);
            setReadyState(true);
            if (billingData.invoice && billingData.invoice.length > 0) {
                let invoiceData = billingData.invoice[0];
                setInvoiceBillData({...invoiceBillData, totalPayableAmount: invoiceData.totalPayableAmount, invoiceNumber: invoiceData.invoiceNumber, invoiceDate: invoiceData.invoiceDate});
                setTotalState({
                    taxes: invoiceData.taxAmount,
                    totalCost: invoiceData.totalCost,
                    totalDiscount: invoiceData.totalDiscount,
                    totalPayableAmt: invoiceData.totalPayableAmount ? invoiceData.totalPayableAmount : 0
                });
                setInvoiceId(invoiceData._id);
                if (invoiceData.status === "Paid") {
                    setInvoicePaid(true);
                }
                let addons = [];
                if (invoiceData.utilizations && invoiceData.utilizations.length > 0) {
                    invoiceData.utilizations.map(item => {
                        if (item.type === "capacity") {
                            setCapacityState({number: item.numberOfItems, mrcCost: item.perItemCost, totalAmount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice})
                        } else if (item.type === "addons") {
                            addons.push({moduleName: item.moduleName, number: item.numberOfItems, mrcCost: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice});
                        }
                    });
                }
                setAddonsState(addons.length > 0 ? addons : [initAddOns]);
            } else {
                setInvoiceId(null);
                noOfCapacity = billingData.speedCount;
                setCapacityState({number: noOfCapacity, mrcCost: 0, totalAmount: 0, freeTierDiscount: 0, finalPrice: 0});
            }
        }
        setNotAllowChange(!allowConfigure);
    }, [billingData]);
    useEffect(() => {
        let date = startDate ? startDate : new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setInvoiceBillData({...invoiceBillData, startDate: firstDay, endDate: lastDay});
    }, [startDate]);
    useEffect(() => {
        if (currency) {
            let crcy = currencyList.filter(itm => {
                if (currency === itm.id) {
                    return itm;
                }
            });
            setSelectedCurrency(crcy.length ? crcy[0] : null);
        }
    }, [currency]);
    const handleCreateAddOns = () => {
        setAddonsState([...addonsState, initAddOns]);
    }
    const handleChangeAddons = (evt, idx) => {
        const {name, value} = evt.target;
        if (name !== "moduleName") {
            if (!value) {
                calculateAddonsPrice(evt, idx);
                setAddonsState(
                    addonsState.map((item, i) =>
                        i === idx
                            ? {...item, [name]: 0}
                            : item
                    ));
                return null;
            }
            if (!IsNumeric(value)) {
                return null;
            }
            calculateAddonsPrice(evt, idx);
        }
        setAddonsState(
            addonsState.map((item, i) =>
                i === idx
                    ? {...item, [name]: name !== "moduleName" ? parseFloat(value) : value}
                    : item
            ));
    }
    const handleChangeCapacityState = (evt) => {
        const {name, value} = evt.target;
        if (!value) {
            calculateCapacityStatePrice(evt);
            setCapacityState({...capacityState, [name]: 0});
            return null;
        }
        if (!IsNumeric(value)) {
            return null;
        }
        calculateCapacityStatePrice(evt);
        setCapacityState({...capacityState, [name]: parseFloat(value)});
    }
    const calculateAddonsPrice = (evt, indx) => {
        const {name, value} = evt.target;
        console.log(name, value);
        const preData = addonsState[indx];
        let amount = preData.amount;
        let finalPrice = preData.finalPrice;
        if (name === "number") {
            if (preData.costPerMonth > 0) {
                amount = preData.costPerMonth * parseFloat(value);
            } else {
                amount = 0;
            }
        } else if (name === "costPerMonth") {
            if (value > 0) {
                amount = preData.number * parseFloat(value);
            } else {
                amount = 0;
            }
        }
        if (name === "freeTierDiscount") {
            finalPrice = amount - parseFloat(value);
        } else {
            finalPrice = preData.freeTierDiscount ? amount - preData.freeTierDiscount : amount;
        }
        setAddonsState(
            addonsState.map((item, i) => {
                    if (i === indx) {
                        item.finalPrice = finalPrice;
                        item.amount = amount;
                        return item;
                    } else {
                        return item;
                    }
                }
            ));
    }
    const handleChangeTaxes = (evt) => {
        const {name, value} = evt.target;
        if (!value) {
            setTotalState({...totalState, [name]: 0});
            return null;
        }
        if (!IsNumeric(value)) {
            return null;
        }
        setTotalState({...totalState, [name]: parseFloat(value)});
    }
    useEffect(() => {
        let totalCost = 0;
        let totalDiscount = 0;
        if (capacityState.totalAmount) {
            totalCost = totalCost + capacityState.totalAmount;
        }
        if (capacityState.freeTierDiscount) {
            totalDiscount = totalDiscount + capacityState.freeTierDiscount;
        }
        if (addonsState.length) {
            for (let adns of addonsState) {
                if (adns.amount) {
                    totalCost = totalCost + adns.amount;
                }
                if (adns.freeTierDiscount) {
                    totalDiscount = totalDiscount + adns.freeTierDiscount;
                }
            }
        }
        let totalPayableAmount = totalDiscount ? totalCost - totalDiscount : totalCost;
        if (totalState.taxes) {
            totalPayableAmount = totalPayableAmount + totalState.taxes;
        }
        setTotalState({...totalState, totalPayableAmt: totalPayableAmount, totalCost, totalDiscount});
    }, [totalState.taxes, capacityState, addonsState]);
    const calculateCapacityStatePrice = (evt, indx) => {
        const {name, value} = evt.target;
        const preData = capacityState;
        let amount = preData.totalAmount;
        let finalPrice = preData.finalPrice;
        if (name === "mrcCost") {
            if (value > 0) {
                let totalHrs = calculateTotalHrs(startDate);
                let totalCostPerHrs = totalHrs * parseInt(value);
                totalCostPerHrs = totalCostPerHrs * capacityState.number;
                amount = totalCostPerHrs;
            } else {
                amount = 0;
            }
        }
        if (name === "freeTierDiscount") {
            finalPrice = amount - parseFloat(value);
        } else {
            finalPrice = preData.freeTierDiscount ? amount - preData.freeTierDiscount : amount;
        }
        preData.finalPrice = finalPrice;
        preData.totalAmount = amount;
        setCapacityState({...preData});
    }
    return (
        <div className={classes.root}>
            <Grid container style={{marginBottom: 15}}>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="month"
                            views={["month", "year"]}
                            value={startDate}
                            maxDate={new Date()}
                            size="small"
                            onChange={handleChangeDateFilter}
                            InputProps={
                                {style: {minWidth: 220, padding: 9}}
                            }
                        />
                    </MuiPickersUtilsProvider>
                    {allowConfigure &&
                    <OutlinedSelect
                        val={currency}
                        label={`${t('currency.label')} *`}
                        handleChange={evt => {
                            handleChangeCurrency(evt.target.value);
                        }}
                        styleOverrides={{width: '220px', marginRight: 0, marginLeft: 10}}
                        options={currencyList.length ? currencyList : []}
                    />
                    }
                    {readyState ? invoiceId ?
                        <DownloadPDFButton t={t} invoiceId={invoiceId} invoiceData={{...invoiceBillData, capacityState, notificationState, modelsEnabledState, addonsState, totalState, currency: selectedCurrency}}
                                           userInfo={userInfo}/> : <Button disabled={true} variant="outlined" color="primary" style={{float: "right", marginRight: 17}}>{t("download.label")}</Button> : null}
                </Grid>
            </Grid>
            <div ref={ref}/>
            {loadingData ?
                <div className={classes.loader}>
                    <CircularProgress color="secondary"/>
                </div>
                :
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            {/*************  Capacity module **************** */}
                            <Table className={classes.table} aria-label="caption table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{width: "12%"}} component="th" scope="row">{t("capacity.label")}</TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                value={capacityState.number}
                                                disabled={true}
                                                id="filled-basic"
                                                variant="filled"
                                                label={t('bandwidth.label')}
                                                style={{minWidth: "100px"}}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                id="filled-basic"
                                                variant="filled"
                                                label={t("mrcCost.label")}
                                                style={{minWidth: "180px"}}
                                                name="mrcCost"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                value={capacityState.mrcCost}
                                                disabled={(notAllowChange || !capacityState.number)}
                                                onChange={handleChangeCapacityState}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                id="filled-basic"
                                                variant="filled"
                                                label={t("amount.label")}
                                                style={{minWidth: "100px"}}
                                                name="totalAmount"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                value={capacityState.totalAmount}
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                id="filled-basic"
                                                variant="filled"
                                                label={t("freeTierDiscount.label")}
                                                name="freeTierDiscount"
                                                value={capacityState.freeTierDiscount}
                                                onChange={handleChangeCapacityState}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                disabled={(notAllowChange || !capacityState.number)}
                                                style={{minWidth: "150px"}}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                size="small"
                                                id="filled-basic"
                                                variant="filled"
                                                label={t("finalPrice.label")}
                                                value={capacityState.finalPrice}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                    ),
                                                }}
                                                style={{minWidth: "100px"}}
                                                disabled={true}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {/*************  Addons module **************** */}
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("addOns.label")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {allowConfigure &&
                                    <Button style={{marginTop: 22, marginRight: 17, float: "right"}} size="medium" variant="contained" color="primary" onClick={handleCreateAddOns}>{t("createAddons.label")}</Button>
                                    }
                                </Grid>
                            </Grid>
                            {addonsState.map((item, index) => {
                                return (
                                    <Table key={index} className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.moduleName}
                                                        onChange={evt => {
                                                            handleChangeAddons(evt, index)
                                                        }}
                                                        id="filled-basic"
                                                        label={t("moduleName.label")}
                                                        name="moduleName"
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{minWidth: "80px"}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{width: "13%"}}>
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        name="number"
                                                        value={item.number}
                                                        onChange={evt => {
                                                            handleChangeAddons(evt, index)
                                                        }}
                                                        label={t("number.label")}
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{minWidth: "80px", marginRight: 8}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.costPerMonth}
                                                        id="filled-basic"
                                                        name="costPerMonth"
                                                        onChange={evt => {
                                                            handleChangeAddons(evt, index)
                                                        }}
                                                        label={t("costPerMonth.label")}
                                                        disabled={notAllowChange || !item.number}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{minWidth: "180px"}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        value={item.amount}
                                                        name="amount"
                                                        disabled={true}
                                                        label={t("amount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{minWidth: "100px"}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.freeTierDiscount}
                                                        id="filled-basic"
                                                        disabled={notAllowChange || !item.number}
                                                        name="freeTierDiscount"
                                                        label={t("freeTierDiscount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={evt => {
                                                            handleChangeAddons(evt, index)
                                                        }}
                                                        style={{minWidth: "150px"}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.finalPrice}
                                                        id="filled-basic"
                                                        label={t("finalPrice.label")}
                                                        name="finalPrice"
                                                        variant="filled"
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{minWidth: "100px"}}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}
                            <Grid container style={{marginTop: 30}}>
                                <Grid item xs={10}>
                                    <Typography variant="subtitle1">{t("taxes.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        disabled={notAllowChange}
                                        value={totalState.taxes}
                                        onChange={handleChangeTaxes}
                                        id="filled-basic"
                                        name="taxes"
                                        label={t("amount.label")}
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{minWidth: 168, maxWidth: 168}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 30}}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">{t("total.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalCost.label")}
                                        variant="filled"
                                        value={totalState.totalCost}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{minWidth: 150, maxWidth: 165}}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalDiscount.label")}
                                        variant="filled"
                                        value={totalState.totalDiscount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{minWidth: 150, maxWidth: 165}}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("toatlPricePayable.label")}
                                        variant="filled"
                                        value={totalState.totalPayableAmt}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{minWidth: 168, maxWidth: 168}}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {allowConfigure &&
                    <Grid container className={classes.container}>
                        <Grid item xs={(!invoicePaid && invoiceId) ? 8 : 10}>
                        </Grid>
                        <Grid item xs={2}>
                            <Button disabled={invoicePaid || saveBtnLoading} style={{marginTop: 22, width: "90%"}} size="medium" variant="contained" color="primary" onClick={evt => {
                                // let valid = true;
                                if (saveBtnLoading) {
                                    return null;
                                }
                                handleSubmitInvoice({totalState, capacityState, modelsEnabledState, addonsState, notificationState, invoiceId, startDate: new Date(startDate).getTime() / 1000});
                            }}>{!saveBtnLoading ? t("submit.label") : <CircularProgress color="secondary" size={12}/>}</Button>
                        </Grid>
                        {(!invoicePaid && invoiceId) &&
                        <Grid item xs={2}>
                            <Button disabled={paidBtnLoading} onClick={evt => {
                                if (paidBtnLoading) {
                                    return null;
                                }
                                handlePaidInvoice({status: "Paid", id: invoiceId})
                            }} style={{marginTop: 22, width: "92%"}} size="medium" variant="contained" color="secondary">{!paidBtnLoading ? t("paid.label") : <CircularProgress color="secondary" size={12}/>}</Button>
                        </Grid>
                        }
                    </Grid>
                    }
                </>
            }
        </div>
    );
};
export default BillingDiaUtilization;
