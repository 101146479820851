import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import { colors } from '../../../../theme/colors';
import ServiceItem from '../ServiceItem';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  }
}));

function SelectSiteModal({
  open,
  handleClose,
  t,
  selectedService,
  handleBeginConfigurationClick
}) {
  const classes = useStyles();
  const [selectedSites, setSelectedSites] = useState(null);
  const [selectedOtherOpt, setSelectedOtherOpt] = useState(null);
  const [subscriptionName, setSubscriptionName] = useState('');

  return (
    <Dialog
      aria-labelledby="select-site-modal"
      aria-describedby="select-site-modal"
      open={open}
      onClose={() => {
        handleClose();
        setSelectedSites(null);
      }}>
      <DialogContent>
        <Grid
          container
          justify={'center'}
          className={classes.selectContainer}
          style={{
            ...paddingGenerator('pb-56')
          }}>
          <Typography variant={'h2'}>{t('selectNoOfSites.label')}</Typography>
          <Typography>{t('howManySites.label')}</Typography>
          <Grid
            style={{
              ...marginGenerator('mt-56'),
              marginLeft: selectedOtherOpt === 'Other' ? '-90px' : ''
            }}>
            <Grid style={{ ...marginGenerator('mb-25') }}>
              <Input
                value={subscriptionName}
                placeholder={t('subscriptionName.label')}
                color={'secondary'}
                style={{ minWidth: '100%' }}
                onChange={evt => {
                  setSubscriptionName(evt.target.value);
                }}
              />
            </Grid>
            {[1, 2, 3, 4, 5, 'Other'].map((item, index) => (
              <Radio
                checked={item === selectedSites || item === selectedOtherOpt}
                key={index}
                classes={{ root: classes.root }}
                style={{
                  ...marginGenerator('mr-16'),
                  ...(item === selectedSites || item === selectedOtherOpt
                    ? { backgroundColor: colors.secondary.main }
                    : '')
                }}
                onChange={evt => {
                  if (evt.target.value !== 'Other') {
                    setSelectedSites(item);
                    setSelectedOtherOpt(null);
                  } else {
                    setSelectedSites(null);
                    setSelectedOtherOpt(item);
                  }
                }}
                value={item}
                name="site-select"
                checkedIcon={
                  <div className={classes.icon}>
                    <Typography
                      variant={'subtitle1'}
                      style={{ color: colors.common.white }}>
                      {item}
                    </Typography>
                  </div>
                }
                icon={
                  <div className={classes.icon}>
                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                      {item}
                    </Typography>
                  </div>
                }
              />
            ))}
            {selectedOtherOpt === 'Other' && (
              <Input
                value={selectedSites ? selectedSites : ''}
                type={'number'}
                color={'secondary'}
                inputProps={{ min: 5 }}
                style={{
                  position: 'absolute',
                  minWidth: '85px',
                  maxWidth: '85px'
                }}
                onChange={evt => {
                  setSelectedSites(parseInt(evt.target.value));
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid style={paddingGenerator('pt-36')}>
          <ServiceItem
            t={t}
            data={selectedService}
            index={2}
            variant={'mini'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
              setSelectedSites(null);
            }}>
            {t('cancel.label')}
          </Button>
          <Button
            onClick={() => {
              if (!selectedSites || !subscriptionName) {
                return null;
              }
              handleBeginConfigurationClick(selectedSites, subscriptionName);
              handleClose();
              setSelectedSites(null);
            }}
            color="secondary"
            variant={'contained'}
            disabled={!selectedSites || !subscriptionName}>
            {t('beginConfiguration.label')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

SelectSiteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedService: PropTypes.object.isRequired,
  handleBeginConfigurationClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(SelectSiteModal);
