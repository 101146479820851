import React from 'react';
import PortToPort from './Step3InternalComponents/PortToPort';
import FcrToPort from './Step3InternalComponents/FcrToPort';
import FcrToFcr from './Step3InternalComponents/FcrToFcr';
import PortToFcr from './Step3InternalComponents/PortToFcr';
import { useAppContext } from '../../../../../contexts/AppContext';

const Step3New = props => {
  const {
    handlePrev,
    handleNext,
    setState,
    client,
    getSubnet,
    destinationPodId
  } = props;

  const { state } = useAppContext();

  return (
    <>
      {state.connectionDetails.sourceType === 'port' &&
      state.connectionDetails.destinationType === 'port' ? (
        <PortToPort
          handlePrev={handlePrev}
          handleNext={handleNext}
          setState={setState}
          destinationPodId={destinationPodId}
        />
      ) : (
        ''
      )}
      {state.connectionDetails.sourceType === 'fcr' &&
      state.connectionDetails.destinationType === 'port' ? (
        <FcrToPort
          handlePrev={handlePrev}
          handleNext={handleNext}
          setState={setState}
          client={client}
          getSubnet={getSubnet}
          destinationPodId={destinationPodId}
        />
      ) : (
        ''
      )}
      {state.connectionDetails.sourceType === 'fcr' &&
      state.connectionDetails.destinationType === 'fcr' ? (
        <FcrToFcr
          handlePrev={handlePrev}
          handleNext={handleNext}
          setState={setState}
          getSubnet={getSubnet}
          client={client}
        />
      ) : (
        ''
      )}
      {state.connectionDetails.sourceType === 'port' &&
      state.connectionDetails.destinationType === 'fcr' ? (
        <PortToFcr
          handlePrev={handlePrev}
          handleNext={handleNext}
          setState={setState}
          getSubnet={getSubnet}
          client={client}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Step3New;
