import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MLTemplateItem from '../MLTemplateItem';
import {dateFormatterLLL} from '../../../../utils';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import commonStyles from '../../../../theme/commonStyles';
import MenuWrapper from '../../../../components/MenuWrapper';
import {useState} from 'react';
import ControllerStatusPanel from '../ControllerStatusPanel';
import {USER_ROLES} from "../../../../utils/constants";
import VNFTemplateItems from "../VNFTemplatePanel/VNFTemplateItems";
import ControllerItems from "./ControllerItems";

function ControllerPanel({
                             t,
                             menuItems,
                             role,
                             list,
                             handleCreateButtonClick,
                             handleMenuItemClick,
                             loading,
                             error
                         }) {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuCustomItems = [{label: t('delete.label')}];
    const columns = [
        {
            label: t('controller.label') + ' ' + t('name.label'),
            key: 'controllerName'
        },
        {
            label: t('deploymentType.label'),
            key: 'deploymentType'
        },
        {
            label: t('role.label'),
            key: 'role'
        },
        {
            label: t('createdAt.label'),
            key: 'createdAt'
        },
        {
            label: t('createdBy.label'),
            key: 'createdBy'
        },
        {
            label: t('dns.label'),
            key: 'dns'
        },
        {
            label: t('status.label'),
            key: 'status'
        }
    ];
    if (role.includes(USER_ROLES.IP_SUPERADMIN)) {
        columns.push({
            label: /*t('edit.label') + '/' +*/ t('delete.label'),
            key: 'button'
        })
    }
    if (!loading && !error) {
        list =
            list.getControllers && list.getControllers.length
                ? list.getControllers
                : [];
    }
    return (
        <div>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), float: 'right'}}>
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('controller_modal')}>
                    {t('addController.label')}
                </Button>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ? (
                                list.length > 0 ? (
                                    list.map((controller, rowIndex) => (
                                        <ControllerItems
                                            key={rowIndex}
                                            t={t}
                                            role={role}
                                            controller={controller}
                                            menuItems={menuCustomItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
ControllerPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default ControllerPanel;
