import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../../../assets/icons/inq_logo_primary.png';

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingBottom: 10,
    border:"1 solid black",
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  },
  leftColumn: {
    flexDirection: 'column',
    paddingLeft: 20,
    width: 360,
    width: '10%',
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0
    },
    '@media orientation: landscape': {
      width: 200
    }
  },
  centerContainer: {
    flexDirection: 'column',
    width: '70%',
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0
    },
    '@media orientation: landscape': {
      width: 200
    }
  },
  rightContainer: {
    flexDirection: 'column',
    paddingRight: 5,
    paddingBottom: 0,
    width: '20%',
    '@media max-width: 400': {
      paddingTop: 10,
      paddingRight: 0
    }
  },
  logo: {
    width: 40,
    height: 'auto'
  },
  header:{
    textAlign:'center',
    fontSize: 13,
  },
});
const InvoiceNo = ({ userInfo, country, org, time }) => {
  let date = new Date(time);
  let longMonth = date.toLocaleString('en-us', { month: 'long' });
  let year = date ? date.getFullYear() : ""
  year = isNaN(year) ? "" : year
  longMonth = longMonth == 'Invalid Date' ? "" : longMonth
  return (
    <Fragment>
      <View style={styles.container}>
        <View style={styles.leftColumn}></View>
        <View style={styles.centerContainer}>
          <View style={styles.header}>
            <Text>Inq. Digital {country} Report - {longMonth} {year}</Text>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <Image src={logo} style={styles.logo} />
        </View>
      </View>
  </Fragment>
  )
}
export default InvoiceNo;
