import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { dateFormatterLLL } from '../../../../../../utils';
import SignIcon from '../../../../../../assets/icons/sign-pen.png';

const BackupControlPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    allowConfigure,
    handleAddMeetingNotes
}) => {
    const commonClasses = commonStyles();
  return (
    <TableRow key={data._id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography
                    variant={'subtitle1'}
                    style={{ cursor: 'pointer' }}
                >
                    {data.name ? data.name : "-"}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {dateFormatterLLL(data.schedule)}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data.checkedServerRoomName ? data.checkedServerRoomName : "-"}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data.performedServerRoomName ? data.performedServerRoomName : "-"}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    {/* <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data.organizer ? data.organizer : "-"}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
    <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data.meetingNotes ? data.meetingNotes : "-"}
                </Typography>
            </Grid>
        </Grid>
    </TableCell> */}
    {allowConfigure &&
        <TableCell>
            {/* <IconButton onClick={() => handleMenuItemClick("sign", data)}>
                <img src={SignIcon} width="26" height={26}/>
            </IconButton> */}
            <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                <DeleteIcon color="secondary" />
            </IconButton>
        </TableCell>
    }
</TableRow>
  )
}

export default BackupControlPanelItem