import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import { CircularProgress, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import MeetingRequestPanelItem from './MeetingRequestPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import MeetingRequestModal from './MeetingRequestModal';
import MeetingNoteModal from './MeetingNoteModal';
import MeetingSignatureModal from './MeetingSignatureModal';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Pagination from '@material-ui/lab/Pagination';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import Message from '../../../../../../components/Message';
import {
    GET_MEETING_LIST
} from '../../queries';
import { CREATE_MEETING, DELETE_MEETING } from '../../mutation';


const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function MeetingRequestPanel({
    t,
    allowConfigure,
    subscriptionUserData,
    data
}) {
    const classes = useStyles();
    const subscriptionId = data.id;
    
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [meetingRequestModalOpen, setMeetingRequestModalOpen] = useState(false);
    const [meetingNotesModalOpen, setMeetingNotesModalOpen] = useState(false);
    const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [filterStateData, setFilterStateData] = useState({...initFilterState});

    const [getAllMeetings, {
        loading,
        data: meetingRequestList
    }] = useLazyQuery(GET_MEETING_LIST, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllMeetings({variables: {...filterStateData, subscriptionId}});
    }, []);

    useEffect(() => {
        if(filterStateData.startDate && filterStateData.endDate){
         getAllMeetings({variables: {...filterStateData, subscriptionId}});
        }else{
            getAllMeetings({variables: {...filterStateData, subscriptionId}});
        }
    }, [filterStateData, subscriptionId]);

    const [createMeeting, { loading: createMeetingLoading }] = useMutation(CREATE_MEETING, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Meeting created successfully');
            setMeetingRequestModalOpen(false);
            setMeetingNotesModalOpen(false);
            setCRModalState({clearData: true, btnLoading: false});
            setSelectedMeetingItem(null);
            setMeetingSignatureModalOpen(false);
            setSignatureType("");
            return getAllMeetings({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteMeeting, { loading: deleteMeetingLoading }] = useMutation(DELETE_MEETING, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Meeting deleted successfully');
            return getAllMeetings({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateMeetingSubmit = (formData) => {
        formData.attendees = formData.attendees.map(itm => itm.id);
        formData.subscriptionId = subscriptionId;
        return createMeeting({ variables: formData });
    }
    
    const columns = [
        {
            label: 'Name',
            key: "name"
        },
        {
            label: 'Schedule Date',
            key: "schedule"
        },
        {
            label: 'Status',
            key: "status"
        },
        {
            label: "Attendees",
            key: "attendees"
        },
        {
            label: 'Venue',
            key: "venue"
        },
        {
            label: 'Meeting Notes',
            key: "meetingNotes"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const handleNewMeetingRequest = () => {
        setMeetingRequestModalOpen(true);
    }
 
    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                console.log("evt.target.value: ", evt.target.value);
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            value: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        }, 
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        }
    ];

    const handleAddMeetingNotes = (meeting) => {
        setMeetingNotesModalOpen(true);
        setSelectedMeetingItem(meeting);
    }

    const handleAddNotesSubmit = (notes) => {
        if(notes === ''){
            Message.error("Notes cannot be empty");
        }else{
            return createMeeting({
                variables: {
                    id: selectedMeetingItem.id,
                    notes: notes,
                    subscriptionId: subscriptionId
                }
            });
        }
    }

    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedMeetingItem(rowItem);
        if(type === "sign"){
            setMeetingSignatureModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setMeetingRequestModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteMeeting({
                variables: {
                    id : rowItem.id
                }
            })
        }
    }
    
    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    onChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewMeetingRequest}
                        >
                            {"Add Meeting"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading ? meetingRequestList && meetingRequestList.getAllMeetings && meetingRequestList.getAllMeetings.length > 0 ?
                                meetingRequestList.getAllMeetings.map((rowitem, rowIndex) => (
                                    <MeetingRequestPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        handleAddMeetingNotes={handleAddMeetingNotes}
                                        users={subscriptionUserData}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record Not Found!</Typography>
                                    </TableCell>
                                </TableRow>
                                : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <MeetingRequestModal
                open={meetingRequestModalOpen}
                handleClose={evt => {
                    setMeetingRequestModalOpen(false);
                    setSelectedMeetingItem(null);
                }}
                modalState={{...crModalState, btnLoading: createMeetingLoading}}
                handleCreateMeetingSubmit={handleCreateMeetingSubmit}
                subscriptionUserData={subscriptionUserData}
                data={selectedMeetingItem}
            />
            <MeetingNoteModal
                open={meetingNotesModalOpen}
                data={selectedMeetingItem}
                handleClose={evt => {
                    setMeetingNotesModalOpen(false);
                    setSelectedMeetingItem(null);
                }}
                handleAddNotesSubmit={handleAddNotesSubmit}
            />
            <CanvasSignaturePadModal 
                open={meetingSignatureModalOpen} 
                handleClose={() => setMeetingSignatureModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    return createMeeting({
                        variables: {
                            id: selectedMeetingItem.id,
                            sign: image,
                            subscriptionId: subscriptionId
                        }
                    });
                }}
            />
        </div>
    );
}

MeetingRequestPanel.propTypes = {
    t: PropTypes.func.isRequired
};
export default MeetingRequestPanel;
