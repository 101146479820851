import React from 'react';
import Popup from '../../Popup';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  Paper,
  TableRow,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const ViewConnectionsPopup = ({
  openViewConnectionsForFcrPopup,
  setOpenViewConnectionsForFcrPopup,
  setConnFcrId,
  setConnectionId,
  setBgpView,
  classes,
  setConnCircleBEndIp,
  setBgpEditView,
  loading,
  item,
  setBgpAddView
}) => {
  return (
    <>
      <Popup
        title="Configured Connections"
        openPopup={openViewConnectionsForFcrPopup}
        setOpenPopup={setOpenViewConnectionsForFcrPopup}
        width="md">
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ color: 'white' }}>
                <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                  <TableCell
                    style={{ color: 'white', paddingLeft: '10px' }}
                    align="center">
                    Source/Destination Type
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Source/Destination Name
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Connection name
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Rate Limit
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Link Type
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    B-end VLAN
                  </TableCell>
                  <TableCell
                    style={{ color: 'white', width: '105px' }}
                    align="center">
                    BGP
                  </TableCell>
                  <TableCell style={{ color: 'white' }} align="left">
                    Static
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : item.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                ) : (
                  item
                    // .filter(
                    //   items =>
                    //     items.b_end_FCR_id !== undefined ||
                    //     items.b_end_port_id !== undefined
                    // )
                    .map(items => (
                      <TableRow key={items.index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: '10px', width: '100px' }}>
                          {items.b_end_FCR_id?.type ||
                            items.b_end_PORT_id?.type ||
                            items.b_end_port_id?.type ||
                            items.a_end_PORT_id?.type ||
                            items.a_end_port_id?.type ||
                            items.a_end_FCR_id?.type}
                        </TableCell>
                        <TableCell align="left" style={{ width: '100px' }}>
                          {items.b_end_FCR_id?.name ||
                            items.a_end_FCR_id?.name ||
                            items.a_end_PORT_id?.name ||
                            items.a_end_port_id?.name ||
                            items.b_end_PORT_id?.name ||
                            items.b_end_port_id?.name}
                        </TableCell>
                        <TableCell align="left" style={{ width: '100px' }}>
                          {items?.name}
                        </TableCell>
                        <TableCell align="left" style={{ width: '100px' }}>
                          {items?.speed}
                        </TableCell>
                        <TableCell align="left" style={{ width: '100px' }}>
                          {items?.link_type}
                        </TableCell>
                        <TableCell align="left" style={{ width: '70px' }}>
                          {items?.b_end_vlan_id || items?.a_end_vlan_id || ''}
                        </TableCell>
                        <TableCell align="left" style={{ width: '105px' }}>
                          <Tooltip
                            title="Add BGP"
                            placement="bottom"
                            arrow
                            backgroundColor="#5a1f2a">
                            <AddCircleOutlineOutlinedIcon
                              className="icons"
                              style={{ color: '#5a1f2a' }}
                              onClick={() => {
                                setBgpAddView(true);
                                setConnectionId(items?._id);
                                setConnCircleBEndIp(items.a_end_FCR_id !== undefined ? items?.conn_cidr_a_ip : items?.conn_cidr_b_ip);
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            title="Edit BGP"
                            placement="bottom"
                            arrow
                            backgroundColor="#5a1f2a">
                            <SettingsOutlinedIcon
                              className="icons"
                              onClick={() => {
                                setConnectionId(items?._id);
                                setConnFcrId(items.a_end_FCR_id?._id ? items.a_end_FCR_id?._id : items.b_end_FCR_id?._id);
                                setConnCircleBEndIp(items.a_end_FCR_id !== undefined ? items?.conn_cidr_a_ip : items?.conn_cidr_b_ip)
                                setBgpEditView(true);
                              }}
                              style={{ color: '#5a1f2a' }}
                            />
                          </Tooltip>
                          <Tooltip
                            title="View BGP"
                            placement="bottom"
                            arrow
                            backgroundColor="#5a1f2a">
                            <VisibilityOutlinedIcon
                              className="icons"
                              style={{ color: '#5a1f2a' }}
                              onClick={() => {
                                setConnectionId(items?._id);
                                setConnFcrId(items.a_end_FCR_id?._id ? items.a_end_FCR_id?._id : items.b_end_FCR_id?._id);
                                setBgpView(true);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" style={{ width: '100px' }}>
                          NA
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => setOpenViewConnectionsForFcrPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default ViewConnectionsPopup;
