import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
// import icError from './../../assets/icons/error.png';
// import icSuccess from './../../assets/icons/check_success.png';
import withTheme from '@material-ui/core/styles/withTheme';
import './MessageBar.css';
import Snackbar from '@material-ui/core/Snackbar';

const GlobalMessage = props => {
  const { type, content, index } = props;
  // let icon = null;
  // switch (type) {
  //   case 'success':
  //     icon = icSuccess;
  //     break;
  //   case 'error':
  //     icon = icError;
  //     break;
  // }

  return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={index > 0 ? {bottom: index * 80} : null}
        open={Boolean(content)}
        message={content}
      />
  );
};

class Message {
  static success(content, index) {
    this.message('success', content, index);
  }
  static warn(content, index) {
    this.message('warn', content, index);
  }
  static error(content, index) {
    this.message('error', content, index);
  }
  static message(type, content, index) {
    const container = document.createElement('div');
    document.body.appendChild(container);
    render(<GlobalMessage type={type} content={content} index={index}/>, container);
    setTimeout(() => {
      unmountComponentAtNode(container);
      container.parentNode.removeChild(container);
    }, 6000);
  }
}

export default withTheme(Message);
