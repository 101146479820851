import React, {useState, useEffect, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import {useMutation} from '@apollo/react-hooks';
import { get, toNumber } from "lodash-es";
import {useFormik} from "formik";
import * as Yup from "yup";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


import Message from '../../../../../../components/Message';
import {
    CAPTURE_WEIGHT_OF_SHAYONA_TRIP_TRUCK
  } from '../../../../Shayona/mutations';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%",
    }
  });
  
  const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        type="submit"
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

const CaptureWeightOfTruck = ({
    t,
    open,
    onClose,
    data
}) => {

    const classes = useStyles();
    const {
           organizationId,
           reloadShayonaApisOrPagination,
           cameraLoading,
           cameraList
          } = useContext(ShayonaCementsContext);

    const validationSchema = Yup.object({
        // grossWeight: Yup
        //           .string('Please fill gross weight')
        //           .required('Please fill gross weight'),  
        cameraDetails:  Yup
                  .object() 
                  .shape({
                    name: Yup.string().required('Please select a camera'),
                    camera_id: Yup.number().required(),
                  }),            
    });
  
    const formik = useFormik({
      initialValues: {
        //grossWeight: get(data, 'grossWeight', '') || '',
        cameraDetails: {}
      },
      validationSchema: validationSchema,
      enableReinitialize:true,
      onSubmit: (values) => {
      handleSubmtiOfTruckDetails(values);
      }, 
    });
  
   const {handleSubmit, handleBlur, handleChange, touched, errors, values, resetForm, setFieldValue} = formik;

   const [tripCaptureWeight, { loading: tripCaptureWeightLoading }] = useMutation(CAPTURE_WEIGHT_OF_SHAYONA_TRIP_TRUCK, {
    onError: error => {
        console.log('err', error);

    },
    onCompleted: data => {
        Message.success(data && data.captureWeightOfTripTruck.message);
        onClose();
        reloadShayonaApisOrPagination('trip','');
    }
  });

   const handleSubmtiOfTruckDetails = (values) => {
    tripCaptureWeight({
        variables:{
            organization: organizationId,
            tripId: data && data.trip_id,
            //grossWeight:  parseFloat(values && values.grossWeight),
            cameraId: get(values, 'cameraDetails.camera_id', null).toString() || ''
        }
    })
   };
  
   useEffect(() => {
    if(!open){
      resetForm();
    }
   }, [open]);
   
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
         Capture weight process
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
                <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  {/* <Grid item xs={6}>
                                      <TextField
                                        value={values.grossWeight}
                                        label="Gross Weight" 
                                        name="grossWeight"
                                        style={{ width: '100%'}}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.grossWeight && errors.grossWeight}
                                        error={touched.grossWeight && Boolean(errors.grossWeight)}
                                      />
                                  </Grid> */}
                                  <Grid item xs={8}>
                                  <Autocomplete
                                      id="asynchronous-demo"
                                      style={{ width: "100%", marginTop: 24, border: "1px solid #ebebeb" }}
                                      name="cameraDetails"
                                      onChange={(event, newValue) => {
                                        setFieldValue('cameraDetails', newValue && newValue);
                                      }}
                                      value={values.cameraDetails}
                                      getOptionSelected={(option, value) => option.id === value}
                                      getOptionLabel={(option) => option.name}
                                      options={get(cameraList, 'getAllCameraList.cameras', [])}
                                      loading={cameraLoading}
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              autoFocus={false}
                                              label={t("cameras.label")}
                                              variant="outlined"
                                              InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                      <React.Fragment>
                                                          {cameraLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                          {params.InputProps.endAdornment}
                                                      </React.Fragment>
                                                  ),
                                              }}
                                          />
                                      )}
                                  />
                                  {touched.cameraDetails && <p style={{ fontSize:11, color:'red' }}>{get(errors, 'cameraDetails.name', '')}</p>}
                                  </Grid>
                                  <Grid item xs={4}/>
                                </Grid>
                                <DialogActions>
                                  <Grid container justify={'space-between'}>
                                      <Button 
                                          variant={'outlined'}
                                          color="primary"
                                          onClick={onClose}
                                      >
                                          {t('cancel.label')}
                                      </Button>
                                      <ButtonComponent
                                       t={t}
                                       loading={tripCaptureWeightLoading}
                                      />
                                  </Grid>          
                              </DialogActions>
                            </form>
        </DialogContent>
    </Dialog>
  )
}

export default CaptureWeightOfTruck