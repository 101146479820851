import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { TICKET_PRIORITY } from '../../../../utils/constants';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { UPDATE_MAIL_TICKET } from '../../mutations';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    iconEdit: {
      cursor: 'pointer',
      fontSize: 'large',
      marginLeft: '10px'
    }
  })
);

export default function TicketChangePriority({ id, reloadTickets, ticketData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [priority, setPriority] = React.useState('');

  const handleChange = event => {
    setPriority(event.target.value);
  };

  const [updateMailServiceTicket] = useMutation(UPDATE_MAIL_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket priority updated successfully.');
      reloadTickets();
      setOpen(false);
      setPriority('');
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <EditTwoToneIcon
        color="primary"
        onClick={handleOpen}
        className={classes.iconEdit}
      />

      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={'sm'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Change Ticket Priority
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Priority
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority}
                    className="his"
                    style={{ width: '100%' }}
                    placeholder="Please select priority"
                    onChange={handleChange}>
                    <MenuItem value="" hidden selected>
                      Select Priority
                    </MenuItem>

                    <MenuItem value={TICKET_PRIORITY.LOW}>
                      {TICKET_PRIORITY.LOW}
                    </MenuItem>
                    <MenuItem value={TICKET_PRIORITY.MEDIUM}>
                      {TICKET_PRIORITY.MEDIUM}
                    </MenuItem>
                    <MenuItem value={TICKET_PRIORITY.HIGH}>
                      {TICKET_PRIORITY.HIGH}
                    </MenuItem>
                    <MenuItem value={TICKET_PRIORITY.URGENT}>
                      {TICKET_PRIORITY.URGENT}
                    </MenuItem>
                    <MenuItem value={TICKET_PRIORITY.CRITICAL}>
                      {TICKET_PRIORITY.CRITICAL}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant={'contained'}
              disabled={false}
              color={'primary'}
              onClick={() => {
                if (!priority) {
                  Message.error(REQUIRED_ERROR);
                  return null;
                }

                return updateMailServiceTicket({
                  variables: {
                    serviceTicketId: id,
                    priority: priority,
                    title: ticketData.title,
                    mailThreadId: ticketData.ticketId
                  }
                });
              }}>
              Update Priority
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
