// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_PROJECT_ZAMBIA_TEAM_LIST } from "../../../../queries";
import { USER_ROLES } from "../../../../../../../utils/constants";
import Header from "../../../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProjectManagerTeamItem from "../ProjectManagerTeamItem";
import { GET_USER_INFO } from '../../../../../../Profile/queries';
import CreateProjectManagerTeamModal from "../CreateProjectManagerTeamModal";
import { ADD_BDU_TEAM_SECTION, UPLOAD_FILES } from "../../../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../../../commonQueries';
import ProjectAttachmentModal from "../../../ProjectAttachmentModal";
import CompleteDetailModal from "../../../CompleteDetailModal";
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const ProjectManagerTeam = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [ProjectManagerTeam, setProjectManagerTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [preValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
        // }
        getAllUserList({
            variables: {
                role: USER_ROLES.INSTALLATION_TEAM
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "project_owner=is_admin&page=1"
                }
            })
            // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=1"
            //         }
            //     })
            // }
        }
    }, [refreshTab]);
    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get installation users list in bdu team', error);
        },
        onCompleted: data => {
            // console.log("data role change bdu: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            setAllUserList(parseData);

            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in chnage control team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            // if (role.includes(USER_ROLES.CHANGE_CONTROL_TEAM) && !checkRoleResult) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&change_control_team=" + full_name
            //         }
            //     })
            // }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "project_owner=is_admin&page=" + value + checkSearchFilterData
            }
        })
        // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=" + value
        //         }
        //     })
        // }
    };
    const [getAllList] = useLazyQuery(GET_PROJECT_ZAMBIA_TEAM_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getProjectZambiaTeamList) {
                let parseResult = JSON.parse(data.getProjectZambiaTeamList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get IM list in change control team:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('accountName.label'),
            key: "account_name"
        },
        {
            label: t('accountNumber.label'),
            key: "account_number"
        },
        {
            label: t('projectManagementTeam.label'),
            key: "bdu_team"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        // console.log("data change : ", data);
        setPreValuesOfForm(data);
        setProjectManagerTeam(true);
    }
    const [addProjectManagerTeam] = useMutation(ADD_BDU_TEAM_SECTION, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error add change control form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addProjectManagerZambiaTeamSection : ", data);
            setModalState({...modalState, btnLoading: false});
            if (data && data.addProjectManagerZambiaTeamSection) {
                if (JSON.parse(data.addProjectManagerZambiaTeamSection.status) === true) {
                    Message.success('New BDU team section created successfully');
                    setModalState({ btnLoading: false, clearData: true });
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addProjectManagerZambiaTeamSection.teamData);
                    handleCloseProjectManagerTeam();
                    // handleIntegrationEngineerTeam();
                } else {
                    let message = JSON.parse(data.addProjectManagerZambiaTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSubmitProjectManagerTeamForm = (formData) => {
        setModalState({...modalState, btnLoading: true });
        handleRefreshTab(false);
        addProjectManagerTeam({
            variables: {
                project_uid: formData.project_uid,
                last_mile_type: formData.last_mile_type,
                router_type: formData.router_type,
                additional_requirements: formData.additional_requirements,
                attach_installtion_form: formData.attach_installtion_form,
                install_team_person_name: formData.install_team_person_name,
                next_team_member_email: formData.next_team_member_email,
                last_team_member_name: CurrentUserName,
                auto_generated_project_id: formData.auto_generated_project_id
            }
        })
    }
    const handleCloseProjectManagerTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setProjectManagerTeam(false);
    }
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({...modalState, btnLoading: true });
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error upload files in change control team', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: true });
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleViewCompleteDetailClick = () => {
        setCompleteDetails({ ...completeDetails, open: true });
    }
    const handleClickViewAllDetailsAndAttachment = (data) => {
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : ((data.project_info && data.project_info.case_number) ? data.project_info.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: ((data.project_info && data.project_info.customer_name ? data.project_info.customer_name : 'N/A') + ' / ' + (data.project_info && data.project_info.organization_name ? data.project_info.organization_name : 'N/A')) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('accountName.label'), value: data.account_name, button: false },
            { label: t('accountNumber.label'), value: data.account_number, button: false },
            { label: t('equipmentType.label'), value: data.equipment_type, button: false },
            { label: t('sla.label'), value: data.sla, button: false },
            { label: t('projectManagementTeam.label'), value: data.bdu_team, button: false },
            { label: t('attachQuotation.label'), value: data.attach_quotation, button: true },
        ]
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')){
            Message.error("Please select start or end date.");
            return false;
        } 
        if(convertStartDate === 'Invalid date'){
            convertStartDate = '';
        } 
        if(convertEndDate === 'Invalid date'){
            convertEndDate = '';
        } 
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin" 
            }
        })
    };
    return (
        <>
            <Header title={t('projects.label')} />
            <Grid container>
            <Grid item xs={12}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <ProjectManagerTeamItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName} handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleClickViewAllDetailsAndAttachment={handleClickViewAllDetailsAndAttachment} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                        `` </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateProjectManagerTeamModal 
                t={t} 
                open={ProjectManagerTeam}
                handleSubmitClick={handleSubmitProjectManagerTeamForm} 
                handleClose={handleCloseProjectManagerTeam} 
                modalState={modalState} 
                preValuesOfForm={preValuesOfForm} 
                handleUploadFiles={handleUploadFiles} 
                allUserList={allUserList} />
                <ProjectAttachmentModal
                    open={ProjectAttachment.open}
                    t={t}
                    handleClose={() => {
                        setProjectAttachment({ open: false, data: [] });
                    }}
                    attachmentList={ProjectAttachment.data}
                />
                <CompleteDetailModal
                    open={completeDetails.open}
                    t={t}
                    handleClose={() => {
                        setCompleteDetails({ ...completeDetails, open: false });
                    }}
                    data={completeDetails.data}
                    loading={completeDetails.loading}
                    previous_comment={completeDetails.previous_comment}
                    documents={completeDetails.documents}
                    projectDetail={completeDetails.projectDetail}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(ProjectManagerTeam);
