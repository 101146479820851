import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { DialogActions, FormControl, Input, Divider, CircularProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import { paddingGenerator } from '../../../../theme/utils';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import { removeSpacialCharecter } from '../../../../utils';

const initFormState = { templateId: "", faceImage: "", list_type: "", name: "", notification: false };

function EnrolFaceModal({
  t,
  open,
  handleClose,
  handleSubmitEnrolFace,
  selectedFace,
  mlTemplateList,
  loading
}) {

  const [formState, setFormState] = useState({ ...initFormState });

  useEffect(() => {
    setFormState({ ...initFormState });
  }, [open]);

  useEffect(() => {
    if (selectedFace) {
      setFormState({...formState, faceImage: (selectedFace.enroll_image) ? selectedFace.enroll_image : "" });
    }
  }, [selectedFace]);

  const handleChange = (evt, field) => {
    let { value } = evt.target;
    if(field === "name"){
      value = removeSpacialCharecter(value);
      value = value.replace(/\s+/g, '_');
    }
    setFormState({...formState, [field]: value});
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      disableBackdropClick={true}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="form-dialog-title" color="primary">Enrol New Face</DialogTitle>
      <DialogContent style={{ padding: 15 }}>
          {selectedFace && 
            <>
              <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={3}>
                    <img id="imageid" src={(selectedFace.matched_signed_url) ? selectedFace.matched_signed_url : selectedFace.signed_url} width="200px" height="150px" />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedSelect
                    val={formState.templateId}
                    label={"Select FR Template*"}
                    handleChange={evt => {
                      setFormState({ ...formState, templateId: evt.target.value });
                    }}
                    styleOverrides={{ width: '100%' }}
                    options={mlTemplateList}
                  />
                </Grid>
              </Grid>
              <Divider/>
              <Grid container spacing={1} style={{ marginTop: 20, marginBottom: 20 }}>
                <Grid item xs={4}>
                  <OutlinedSelect
                    val={formState.list_type}
                    label={t('listType.label')}
                    selectStyle={{ ...paddingGenerator('p-8') }}
                    styleOverrides={{ width: '100%', marginRight: 0, minWidth: 150 }}
                    handleChange={evt => {
                      handleChange(evt, "list_type")
                    }}
                    options={["blacklist", "whitelist"]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    value={formState.name}
                    type={'text'}
                    placeholder={`${t('name.label')}*`}
                    style={{ width: '100%', minWidth: 150 }}
                    color={'secondary'}
                    onChange={evt => {
                    }}
                    onChange={evt => {
                      handleChange(evt, "name")
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormControl
                    control={
                      <Checkbox
                        checked={formState.notification}
                        onChange={evt => {
                          handleChange(evt, "notification")
                        }}
                        disabled={(formState.list_type === "blacklist")}
                        title={t("enableNotif.label")}
                        color="primary"
                      />
                    }
                    label={t("notification.label")}
                  />
                </Grid>
              </Grid>
            </>
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading}
          variant={'contained'}
          onClick={(evt) => {
            if(!formState.templateId || !formState.name || !formState.faceImage){
              Message.error(REQUIRED_ERROR);
              return null;
            }
            return handleSubmitEnrolFace(formState)
          }}>
          {loading ?  <CircularProgress size={14} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnrolFaceModal;