import React, { useContext } from 'react';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Statcard from '../../../../../../../components/Statcard';
import BarChartWrapper from '../../../../../../../components/Charts/BarChartWrapper';
import { ShayonaCementsContext } from '../../../../../../../contexts/ShayonaCementsProvider';

const ShayonaStatCardAndChart = ({
    t
}) => {
    const {
        shayonaDasboardDataLoading,
        shayonaDashboardData
      } = useContext(ShayonaCementsContext);
      
  return (
    <>
              <Grid container style={{marginTop: 30, marginBottom: 20}}>
                <Grid item xs="4">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Trucks`}
                        statValue={shayonaDasboardDataLoading ? <CircularProgress size={19} /> : get(shayonaDashboardData, 'num_trucks', 0)}
                    />
                </Grid>
                <Grid item xs="4">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Bags`}
                        statValue={shayonaDasboardDataLoading ? <CircularProgress size={19} /> : get(shayonaDashboardData, 'num_bags', 0)}
                    />
                </Grid>
                <Grid item xs="4">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Disputes`}
                        statValue={shayonaDasboardDataLoading ? <CircularProgress size={19} /> :  get(shayonaDashboardData, 'num_disputes', 0)}
                    />
                </Grid>
            </Grid>
            <Grid container style={{marginBottom: 20}}>
               <Grid item xs="12">
                  <BarChartWrapper
                    data={get(shayonaDashboardData, 'chart', [])}
                    dataKeyChart={'Trip'}
                    dataKeyXAxis={'Text'}
                    loading={shayonaDasboardDataLoading}
                  />
                </Grid>
            </Grid> 
    </>
  )
}

export default ShayonaStatCardAndChart