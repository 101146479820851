import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import {
    GET_TRANSPORT_REQUESTS
} from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_TRANSPORT_REQUEST, DELETE_USER_REQUEST } from '../../mutation';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function AssetTransferForm({
    t,
    allowConfigure,
}) {
    const classes = useStyles();
    const [userRequestModalOpen, setUserRequestModalOpen] = useState(false);
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, search: "" });
    const columns = [
        {
            label: 'User Name',
            key: "userName"
        },
        {
            label: "Department",
            key: "department"
        },
        {
            label: 'Section',
            key: "section"
        },
        {
            label: 'Designation',
            key: "designation"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const handleNewUserRequest = () => {
        setUserRequestModalOpen(true);
    }
    
    const filterList = [
        {
            label: t("search.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Status',
            handleChange: evt => {

            },
            val: filterStateData.status,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: []
        }
    ];


    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewUserRequest}
                        >
                            {"Add new"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                        <TableCell key={index} style={item.style ? item.style : null} >
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={columns.length} align="center" component="td">
                                demo
                            </TableCell>
                        </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            <crModalState
                open={userRequestModalOpen}
                handleClose={evt => setUserRequestModalOpen(false)}
            />
        </div>
    );
}

AssetTransferForm.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default AssetTransferForm;
