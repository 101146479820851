import React from 'react';
import { SERVICE_TYPES } from '../../../../utils/constants';
import { marginGenerator } from '../../../../theme/utils';
import EdgeAIDashBoardPanel from '../EdgeAIDashBoardPanel'

const DashboardPanel = ({ t, type, subscriptionData, handleImageModalClick, aiTypes, placeList, subActiveIndex, selectedPath }) => {
    return (
        <div style={{ ...marginGenerator('mb-56'), overflow: 'hidden' }}>
            {type === SERVICE_TYPES.EDGE_AI &&
                <EdgeAIDashBoardPanel placeList={placeList} subscriptionData={subscriptionData} t={t} handleImageModalClick={handleImageModalClick} aiTypes={aiTypes} subActiveIndex={subActiveIndex} selectedPath={selectedPath} />
            }
        </div>
    );
};
export default DashboardPanel;
