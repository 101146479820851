import React, {useState, useEffect} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableFooter from './InvoiceTableFooter'
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  }
});

const InvoiceItemsTable = ({invoice, t}) => {

  const [cameraState, setCameraState] = useState([]);
  const [notificationState, setNotificationState] = useState([]);
  const [modelsEnabledState, setModelsEnabledState] = useState([]);
  const [addonsState, setAddonsState] = useState([]);
  
  useEffect(() => {
    if(invoice.cameraState){
      let tmpObj = {
        description: t("noOfCamera.label"),
        qty: invoice.cameraState.number,
        rate: invoice.cameraState.perCamCost,
        discount: invoice.cameraState.freeTierDiscount,
      };
      setCameraState([tmpObj]);
    }
  }, [invoice.cameraState]);

  useEffect(() => {
    if(invoice.notificationState && invoice.notificationState.length){
      let notifications = invoice.notificationState.map(item => {
        return {
          description: item.name,
          qty: item.number,
          rate: item.perNotiCost,
          discount: item.freeTierDiscount,
        };
      });
      setNotificationState(notifications);
    }
  }, [invoice.notificationState]);

  useEffect(() => {
    if(invoice.modelsEnabledState && invoice.modelsEnabledState.length){
      let modelsEnabled = invoice.modelsEnabledState.map(item => {
        return {
          description: item.name,
          qty: item.timeActivated,
          rate: item.costPerModel,
          discount: item.freeTierDiscount,
        };
      });
      setModelsEnabledState(modelsEnabled);
    }
  }, [invoice.modelsEnabledState]);

  useEffect(() => {
    if(invoice.addonsState && invoice.addonsState.length){
      let addons = invoice.addonsState.map(item => {
        return {
          description: item.moduleName,
          qty: item.number,
          rate: item.costPerMonth,
          discount: item.freeTierDiscount
        };
      });
      setAddonsState(addons);
    }
  }, [invoice.notificationState]);

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader row={{title: t("cameras.label"), head1: t("number.label"), head2: t("perCamCost.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow items={cameraState} currency={invoice.currency ? invoice.currency.symbol: "$"} />
  
      <InvoiceTableHeader row={{title: t("modelsEnabled.label"), head1: t("number.label"), head2: t("perModelCost.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow items={modelsEnabledState} currency={invoice.currency ? invoice.currency.symbol: "$"} />
    
      <InvoiceTableHeader row={{title: t("notifications.label"), head1: t("number.label"), head2: t("costPerNoti.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol: "$"} items={notificationState} />
      
      <InvoiceTableHeader row={{title: t("addOns.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol: "$"} items={addonsState} />

      <InvoiceTableFooter currency={invoice.currency ? invoice.currency.symbol: "$"} total={invoice.totalState ? invoice.totalState : null} />
    </View>
  )
}


export default InvoiceItemsTable