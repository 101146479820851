import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DatePicker from 'react-datepicker';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateStoreRequestFormModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    handleUploadFiles,
    modalContentLoading,
    handleOpenFeetportForm,
    preValuesOfForm
}) {
    const classes = useStyles();
    const [staffName, setStaffName] = useState('');
    const [department, setDepartment] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [projectCode, setProjectCode] = useState('');
    const [date, setDate] = useState('');
    const [sendCopyTo, setSendCopyTo] = useState('');
    const [ncar, setNcar] = useState('');
    const [lineManagerSignature, setLineManagerSignature] = useState('');
    const [managerName, setManagerName] = useState('');
    const [itemsReceivedBySignature, setItemsReceivedBySignature] = useState('');
    const [issuedBySignature, setIssuedBySignature] = useState('');
    const [issuedByname, setIssuedByname] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [description, setDescription] = useState('');
    const [qty, setQty] = useState('');
    const [unitsOfMeasure, setUnitsOfMeasure] = useState('');
    const [fixedAssetTag, setFixedAssetTag] = useState('');
    const [po, setPo] = useState('');
    const [serial, setSerial] = useState('');
    const [installation, setInstallation] = useState(false);
    const [serviceRepair, setServiceRepair] = useState(false);
    const [transfer, setTransfer] = useState(false);
    const [sale, setSale] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setStaffName('');
            setDepartment('');
            setCustomerName('');
            setProjectCode('');
            setDate('');
            setSendCopyTo('');
            setNcar('');
            setLineManagerSignature('');
            setManagerName('');
            setItemsReceivedBySignature('');
            setIssuedBySignature('');
            setIssuedByname('');
            setPartNumber('');
            setDescription('');
            setQty('');
            setUnitsOfMeasure('');
            setFixedAssetTag('');
            setPo('');
            setSerial('');
            setInstallation(false);
            setServiceRepair(false);
            setTransfer(false);
            setSale(false);
        }
    }, [modalState, open, modalContentLoading, preValuesOfForm]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('storeRequestForm.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('staffName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={staffName}
                                type={'text'}
                                placeholder={t('staffName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setStaffName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('department.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={department}
                                type={'text'}
                                placeholder={t('department.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDepartment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('customerName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={customerName}
                                type={'text'}
                                placeholder={t('customerName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCustomerName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('projectCode.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectCode}
                                type={'text'}
                                placeholder={t('projectCode.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectCode(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('date.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={date}
                                    onChange={date => setDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('sendCopyTo.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={sendCopyTo}
                                type={'text'}
                                placeholder={t('sendCopyTo.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSendCopyTo(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('ncar.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={ncar}
                                type={'text'}
                                placeholder={t('ncar.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNcar(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('managerName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={managerName}
                                type={'text'}
                                placeholder={t('managerName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setManagerName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('issuedByname.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={issuedByname}
                                type={'text'}
                                placeholder={t('issuedByname.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setIssuedByname(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('partNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={partNumber}
                                type={'text'}
                                placeholder={t('partNumber.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setPartNumber(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('description.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={description}
                                type={'text'}
                                placeholder={t('description.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDescription(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('qty.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={qty}
                                type={'text'}
                                placeholder={t('qty.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setQty(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('unitsOfMeasure.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={unitsOfMeasure}
                                type={'text'}
                                placeholder={t('unitsOfMeasure.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setUnitsOfMeasure(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('fixedAssetTag.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={fixedAssetTag}
                                type={'text'}
                                placeholder={t('fixedAssetTag.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setFixedAssetTag(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('po.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={po}
                                type={'text'}
                                placeholder={t('po.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setPo(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('serial.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={serial}
                                type={'text'}
                                placeholder={t('serial.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSerial(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('lineManagerSignature.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                inputProps={{ accept: "image/*" }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setLineManagerSignature(file);
                                    } else {
                                        setLineManagerSignature('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                     <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('itemsReceivedBySignature.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                inputProps={{ accept: "image/*" }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setItemsReceivedBySignature(file);
                                    } else {
                                        setItemsReceivedBySignature('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('issuedBySignature.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                inputProps={{ accept: "image/*" }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setIssuedBySignature(file);
                                    } else {
                                        setIssuedBySignature('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Typography variant="subtitle2" color="textPrimary" >
                                <Checkbox
                                color="secondary"
                                value={installation}
                                onChange={(event) => {
                                    setInstallation(event.target.checked)} }
                            />{t('installation.label')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Typography variant="subtitle2" color="textPrimary" >
                                <Checkbox
                                color="secondary"
                                value={serviceRepair}
                                onChange={(event) => {
                                    setServiceRepair(event.target.checked)} }
                            />{t('serviceRepair.label')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Typography variant="subtitle2" color="textPrimary" >
                                <Checkbox
                                color="secondary"
                                value={transfer}
                                onChange={(event) => {
                                    setTransfer(event.target.checked)} }
                            />{t('transfer.label')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Typography variant="subtitle2" color="textPrimary" >
                                <Checkbox
                                color="secondary"
                                value={sale}
                                onChange={(event) => {
                                    setSale(event.target.checked)} }
                            />{t('sales.label')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!staffName || !department || !customerName || !projectCode || !date || !sendCopyTo || !ncar ||
                                !managerName || !issuedByname || !partNumber || !description || !qty ||
                                !unitsOfMeasure || !fixedAssetTag || !po || !serial) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // create URL for signature file
                            let getItemsReceivedBySignature = "";
                            let getLineManagerSignature = "";
                            let getIssuedBySignature = "";
                            // if (itemsReceivedBySignature != "" && itemsReceivedBySignature != null) {
                            //     let getItemsReceivedBySignatureUrl = await handleUploadFiles(itemsReceivedBySignature, preValuesOfForm.project, 'store_request_form');
                            //     if (getItemsReceivedBySignatureUrl.data && getItemsReceivedBySignatureUrl.data.allUploadFile && getItemsReceivedBySignatureUrl.data.allUploadFile.url) {
                            //         getItemsReceivedBySignature = getItemsReceivedBySignatureUrl.data.allUploadFile.url;
                            //     }
                            //     else {
                            //         Message.error("File not uploaded! Please try again.");
                            //     }
                            // }
                            // if (lineManagerSignature != "" && lineManagerSignature != null) {
                            //     let getLineManagerSignatureUrl = await handleUploadFiles(lineManagerSignature, preValuesOfForm.project, 'store_request_form');
                            //     if (getLineManagerSignatureUrl.data && getLineManagerSignatureUrl.data.allUploadFile && getLineManagerSignatureUrl.data.allUploadFile.url) {
                            //         getLineManagerSignature = getLineManagerSignatureUrl.data.allUploadFile.url;
                            //     }
                            //     else {
                            //         Message.error("File not uploaded! Please try again.");
                            //     }
                            // }
                            // if (issuedBySignature != "" && issuedBySignature != null) {
                            //     let getIssuedBySignatureUrl = await handleUploadFiles(issuedBySignature, preValuesOfForm.project, 'store_request_form');
                            //     if (getIssuedBySignatureUrl.data && getIssuedBySignatureUrl.data.allUploadFile && getIssuedBySignatureUrl.data.allUploadFile.url) {
                            //         getIssuedBySignature = getIssuedBySignatureUrl.data.allUploadFile.url;
                            //     }
                            //     else {
                            //         Message.error("File not uploaded! Please try again.");
                            //     }
                            // }
                            handleSubmitClick({
                                date: moment(date).format("YYYY-MM-DD"),
                                staff_name: staffName,
                                department: department,
                                customer: customerName,
                                project_code: projectCode,
                                ncar: ncar,
                                send_copy_to: sendCopyTo,
                                // line_manager_signature: getLineManagerSignature,
                                manager_name: managerName,
                                // items_received_by_signature: getItemsReceivedBySignature,
                                issued_by_name: issuedByname,
                                // issued_by_signature: getIssuedBySignature,
                                description: [{
                                    part_number: partNumber,
                                    description: description,
                                    qty: qty,
                                    units_of_measure: unitsOfMeasure,
                                    serial: serial,
                                    fixed_asset_tag: fixedAssetTag,
                                    po: po
                                }],
                                installation: installation,
                                service_repair: serviceRepair,
                                transfer: transfer,
                                sale: sale,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateStoreRequestFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateStoreRequestFormModal);