import React from 'react';
import { Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

RHFSlider.propTypes = {
  name: PropTypes.string
};

export default function RHFSlider({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Slider {...field} valueLabelDisplay="auto" {...other} />
      )}
    />
  );
}
