import React, { useState } from 'react';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import { InternalPaths } from '../../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Message from '../../../components/Message';
import { CREATE_SUB_ADMIN } from './mutations';
import { GET_COUNTRY_LIST, GET_ALL_ROLES } from '../../commonQueries';
import { colors } from '../../../theme/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  REQUIRED_ERROR,
  INVALID_EMAIL,
  INVALID_PHONE,
  USER_ROLES,
  SUBSCRIPTION_STATUS
} from '../../../utils/constants';
import { checkValidEmail } from '../../../utils';
import OutlinedSelect from '../../../components/OutlinedSelect';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ORGANISATIONS_LIST_FOR_SELECT } from '../Organisations/queries';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GET_SUBSCRIPTION_LIST } from '../../Subscriptions/queries';

const breadcrumbList = [
  { label: 'home.label', link: InternalPaths.Dashboard },
  { label: 'users.label', link: InternalPaths.users },
  { label: 'addAdmins.label', link: InternalPaths.createNewUser }
];

const roleList = [
  'countryadmin',
  'eadmin',
  'servicemanager',
  'finance',
  'user',
  'it',
  'csoc_manager',
  'csoc',
  'third_party_vendor'
];

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      variant={'contained'}
      size={'large'}
      style={{ ...marginGenerator('mt-24') }}
      color={'primary'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('createUser.label')}
    </Button>
  );
}

function CreateNewUser({ t }) {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [countryId, setCountryId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgListOrig, setOrgList] = useState([]);
  const [isCountryAdmin, setIsCountryAdmin] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState("");

  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  const { data: roleList } = useQuery(GET_ALL_ROLES, {
    onError: error => {
      console.log('Error', error);
      setLoading(false);
    },
    onCompleted: data => {
      setLoading(false);
      let parseData = data.getAllRoles.RoleData ? JSON.parse(data.getAllRoles.RoleData) : [];
      setRolesList(parseData)
    }
  });
  const [createSubAdmin] = useMutation(CREATE_SUB_ADMIN, {
    onError: error => {
      console.log('Error', error);
      setLoading(false);
    },
    onCompleted: data => {
      setLoading(false);
      Message.success('User created successfully');
      history.push({ pathname: InternalPaths.users, search: `?action=reload` });
    }
  });

  const { loading: orgLoading, data: orgList } = useQuery(ORGANISATIONS_LIST_FOR_SELECT, {
    onCompleted: data => {
      setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
    }
  });

  const [getSubscriptionList, {loading: subsLoding}] = useLazyQuery(GET_SUBSCRIPTION_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      console.log("res.getAllSubscriptions: ", res.getAllSubscriptions);
      setSubscriptionList(res.getAllSubscriptions ? res.getAllSubscriptions : []);
    }
  })

  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role.includes(USER_ROLES.IP_COUNTRYADMIN)) {
      setIsCountryAdmin(true);
      setCountryId(localStorage.getItem("countryId"));
    }
  }, []);

  React.useEffect(() => {
    if(orgId){
      return getSubscriptionList({variables: {
        status: SUBSCRIPTION_STATUS.ACTIVE,
        ownerId: orgId
      }});
    }
    setSubscriptionList([]);
  }, [orgId]);

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '468px', ...marginGenerator('mt-115') }}>
      <Header
        title={t('addAdmins.label')}
        titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
        subText={t('addAdminsSubText.label')}
        subtitleVariant={'body1'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0 }}
      />
      <Grid container style={{ ...marginGenerator('mt-15'), ...marginGenerator('mb-24') }}>
        <Grid container item justify={'space-between'}>
          <Input
            value={firstName}
            type={'text'}
            placeholder={`${t('firstName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setFirstName(evt.target.value)}
          />
          <Input
            value={lastName}
            type={'text'}
            placeholder={`${t('lastName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setLastName(evt.target.value)}
          />
        </Grid>
        <Autocomplete
          id="asynchronous-demo"
          style={{ width: '100%', marginTop: 24, border: '1px solid #ebebeb' }}
          name="orgId"
          onChange={(event, newValue) => {
            setOrgId(newValue ? newValue.id : "");
          }}
          getOptionSelected={(option, value) => option.id === value}
          getOptionLabel={option => option.name}
          options={orgListOrig}
          loading={orgLoading}
          renderInput={params => (
            <TextField
              {...params}
              autoFocus={false}
              label={t('organization.label')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {orgLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        <Grid
          container
          item
          justify={'space-between'}
          style={{ ...marginGenerator('mt-24'), ...marginGenerator('mb-15') }}>
          <OutlinedSelect
            val={countryId}
            label={`${t('country.label')} *`}
            handleChange={evt => {
              setCountryId(evt.target.value);
            }}
            selectStyle={{ ...paddingGenerator('p-8') }}
            styleOverrides={{ width: '225px', ...marginGenerator('mr-0') }}
            options={countryList ? countryList.getCountries : []}
            disabled={isCountryAdmin}
          />
          <OutlinedSelect
            val={role}
            label={`${t('role.label')} *`}
            handleChange={evt => {
              setRole(evt.target.value);
            }}
            selectStyle={{ ...paddingGenerator('p-8') }}
            styleOverrides={{ width: '222px', ...marginGenerator('mr-0') }}
            options={rolesList ? rolesList : []}
          />
        </Grid>
        {role && (role === USER_ROLES.IP_USER || role === USER_ROLES.EP_FINANCE || role === USER_ROLES.EP_IT) &&
          <Autocomplete
            id="asynchronous-demo"
            style={{ width: '100%', marginTop: 24, border: '1px solid #ebebeb' }}
            onChange={(event, newValue) => {
              setSubscriptionId(newValue.id);
            }}
            getOptionSelected={(option, value) => option.id === value}
            getOptionLabel={option => option.name}
            options={subscriptionList}
            loading={subsLoding}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus={false}
                label={t('subscriptions.label')}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {subsLoding ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />}
        <Input
          value={phone}
          type={'text'}
          placeholder={`${t('phone.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-40') }}
          color={'secondary'}
          autoComplete={'off'}
          onChange={evt => setPhone(evt.target.value)}
        />
        <Input
          value={email}
          type={'text'}
          placeholder={`${t('email.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-24') }}
          color={'secondary'}
          onChange={evt => setEmail(evt.target.value)}
        />
        <Input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder={`${t('password.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Input
          value={confirmPassword}
          type={showConfPassword ? 'text' : 'password'}
          placeholder={`${t('confPassword.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setConfirmPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <ButtonComponent
          t={t}
          onClick={() => {
            if (
              !firstName ||
              !lastName ||
              !email ||
              !phone ||
              !role ||
              !password ||
              !confirmPassword
            ) {
              Message.error(REQUIRED_ERROR);
              return null;
            }

            if (!checkValidEmail(email)) {
              setEmail('');
              Message.error(INVALID_EMAIL);
              return null;
            }

            const regex = /^\d+$/;
            if (!regex.test(phone) || phone.length > 10 || phone.length < 7) {
              Message.error(INVALID_PHONE);
              return null;
            }

            if(role && (role === USER_ROLES.EP_FINANCE || role === USER_ROLES.EP_IT || role === USER_ROLES.IP_USER)){
              if(!subscriptionId || !orgId){
                Message.error(REQUIRED_ERROR);
                return null;
              }
            }

            if (password !== confirmPassword) {
              Message.error('Password and confirm password must be same');
              return null;
            }
            setLoading(true);
            return createSubAdmin({
              variables: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                role: role,
                phone: phone,
                orgId: orgId,
                countryId: countryId,
                password: password,
                subscriptionId: subscriptionId
              }
            });
          }}
          loading={loading}
        />
      </Grid>
    </Layout>
  );
}

export default withTranslation()(CreateNewUser);
