import * as React from 'react';
import Layout from '../../components/Layout';
import { Paths } from '../../routes/routePaths';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SupportExpansionPanel from './components/SupportExpansionPanel';
import Docs from './components/Docs';
import SupportCard from './components/SupportCard';
import EmailIcon from '../../assets/icons/email.png';

const expansionData = [
  {
    que: 'Q. Microsoft Patch Management For Home Users?',
    ans:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    que: 'Q. How to upgrade To Microsoft Windows Vista?',
    ans:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    que:
      'Q. Best places to find the best Computer Accessories in and around Nairobi?',
    ans:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    que:
      'Q. Why Inkjet Printing Is Very Appealing Compared To Ordinary Printing?',
    ans:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    que: 'Q. Microsoft Patch Management For Home Users?',
    ans:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  }
];

const Troubleshooting = [
  'Linux Or Windows Which Is It',
  'Headset No Longer Wired For Sound',
  'Make Myspace Your Best ',
  'Microsoft Patch Management For Home Users'
];

const SupportCards = [
  {
    icon: <EmailIcon />,
    title: 'Email us',
    heading: 'support@inq.com',
    subtext: 'Ideal and recommended',
    color: '#0183eb'
  },
  {
    icon: <EmailIcon />,
    title: 'Chat with us',
    heading: 'Open chat box',
    subtext: 'Average response rate of 2 mins',
    color: '#e94b5b'
  },
  {
    icon: <EmailIcon />,
    title: 'Chat with us',
    heading: 'Open chat box',
    subtext: 'Average resolution rate 80.2%',
    color: '#ff9a01'
  }
];

export default function Support() {
  const breadcrumbList = [
    { label: 'home.label', link: Paths.Dashboard },
    { label: 'support.label', link: Paths.Support }
  ];

  const useStyles = makeStyles(theme => ({
    section: {
      margin: '30px 0'
    },
    docsList: {
      margin: '20px 0'
    }
  }));

  const classes = useStyles();

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Typography variant={'h2'}>Reach out to us</Typography>
      <Typography variant={'subtitle2'}>
        We regret to see you here. It’s unfortunate. But we are here to help
        you. Talk to us :)
      </Typography>
      <Grid container spacing={0} className={classes.section}>
        {SupportCards.map((option, index) => (
          <Grid item xs={4}>
            <SupportCard
              icon={option.icon}
              title={option.title}
              heading={option.heading}
              subtext={option.subtext}
              color={option.color}
              key={index}
            />
          </Grid>
        ))}
      </Grid>

      <div className={classes.section}>
        <Typography variant={'h2'}>
          Get help from our recommended readings
        </Typography>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={4}>
            <Typography variant={'subtitle1'} color={'textPrimary'}>
              Troubleshooting docs
            </Typography>
            <div className={classes.docsList}>
              {Troubleshooting.map((title, index) => (
                <Docs title={title} key={index} />
              ))}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={'subtitle1'} color={'textPrimary'}>
              Router Related docs
            </Typography>
            <div className={classes.docsList}>
              {Troubleshooting.map((title, index) => (
                <Docs title={title} key={index} />
              ))}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={'subtitle1'} color={'textPrimary'}>
              Account and Billing related
            </Typography>
            <div className={classes.docsList}>
              {Troubleshooting.map((title, index) => (
                <Docs title={title} key={index} />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Typography variant={'h2'}>
          Get help from our recommended readings
        </Typography>
        {expansionData.map((item, index) => (
          <SupportExpansionPanel que={item.que} ans={item.ans} key={index} />
        ))}
      </div>
    </Layout>
  );
}
