import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import {
  InternalPaths,
  OrganisationPaths,
  ServiceTicketPaths
} from '../../../routes/routePaths';
import Header from '../../../components/Header';
import { dateFormatterLLL, getUrl } from '../../../utils';
import { useHistory } from 'react-router';
import Statcard from '../../../components/Statcard';
import { useQuery } from '@apollo/react-hooks';
import { ALL_SERVICE_TICKETS, INTERNAL_STATS } from './queries';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { marginGenerator } from '../../../theme/utils';
import TicketPanel from '../../ServiceTickets/components/TicketPanel';
import cpe_orange from '../../../assets/icons/cpe_orange.png';
import org_black from '../../../assets/icons/org_black.png';
import org_secondary from '../../../assets/icons/org_secondary.png';
import user from '../../../assets/icons/user.png';
import { USER_ROLES } from '../../../utils/constants';

const breadcrumbList = [{ label: 'home.label', link: InternalPaths.Dashboard }];

const StatPanel = ({ t, statList }) => {
  return (
    <div>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-8')
        }}>
        {t('overview.label')}
      </Typography>
      <Grid container justify={'space-between'}>
        {statList.map((item, index) => (
          <Statcard
            key={index}
            statLabel={item.label}
            icon={item.icon}
            statValue={item.value}
          />
        ))}
      </Grid>
    </div>
  );
};

export const RecentTicketsPanel = ({
  t,
  ticketList,
  handleTicketItemClick,
  handleMenuItemClick
}) => {
  const ticketListUpd = ticketList;
  return (
    <div>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-8')
        }}>
        {t('recentTickets.label')}
      </Typography>
      <TicketPanel
        handleTicketItemClick={handleTicketItemClick}
        ticketList={ticketListUpd}
        type={'recentTickets'}
        filterList={[]}
        handleMenuItemClick={handleMenuItemClick}
        t={t}
        search={false}
      />
    </div>
  );
};

const InternalDashboard = ({ t }) => {
  const history = useHistory();
  const role = localStorage.getItem('role');
  const {
    loading: statsLoading,
    error: statsError,
    data: statsData
  } = useQuery(INTERNAL_STATS);

  const {
    loading: serviceTicketLoading,
    error: serviceTicketError,
    data: serviceTickets
  } = useQuery(ALL_SERVICE_TICKETS);

  const statList =
    !statsLoading && !statsError
      ? [
          {
            label: t('totalOrganizations.label'),
            value: statsData.internalDashboardStats.totalOrgs,
            icon: org_black
          },
          {
            label: t('OrgsSubscribed.label'),
            value: statsData.internalDashboardStats.orgsSubscribed,
            icon: org_secondary
          },
          {
            label: t('totalSubscriptions.label'),
            value: statsData.internalDashboardStats.totalSubscriptions,
            icon: cpe_orange
          },
          {
            label: t('totalMembers.label'),
            value: statsData.internalDashboardStats.totalUsers,
            icon: user
          }
        ]
      : [];

  const handleTicketItemClick = (item, id) => {
    const orgId = localStorage.getItem('orgId')
      ? localStorage.getItem('orgId')
      : null;
    // const path = getUrl(ServiceTicketPaths.Details, id);
    // const path = getUrl(ServiceTicketPaths.Details, orgId);
    const path = getUrl(OrganisationPaths.details, orgId);
    // history.push({ pathname: path, search: `?history=dashboard` });
    history.push({
      pathname: path,
      search: `?${item?.updatedByName}?${item?.email || '-'}`
    });
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(item, id);
    }
  };

  const handleCreateAdminClick = () => {
    const path = getUrl(InternalPaths.createNewUser);
    history.push({ pathname: path });
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('dashboard.label')}
        subText={`${t('statsLastUpdated.label')} ${dateFormatterLLL(
          Date.now()
        )}`}
        primaryButtonText={
          role.includes(USER_ROLES.IP_SUPERADMIN) ? t('addAdmins.label') : null
        }
        handlePrimaryButtonClick={
          role.includes(USER_ROLES.IP_SUPERADMIN)
            ? handleCreateAdminClick
            : null
        }
      />
      {!statsLoading && !statsError && <StatPanel t={t} statList={statList} />}
      {!serviceTicketLoading && !serviceTicketError && (
        <RecentTicketsPanel
          t={t}
          ticketList={serviceTickets.dashboardRecentTickets}
          handleTicketItemClick={handleTicketItemClick}
          handleMenuItemClick={handleMenuItemClick}
        />
      )}
    </Layout>
  );
};
export default withTranslation()(InternalDashboard);
