import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
    width: "100%"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px' },
  pre: { padding: '10px 30px', margin: '0' }
});

const MemberList = ({ t, memberListLoading, isMemberDialogEnable, handleClose, memberList }) => {

  const classes = useStyles();
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isMemberDialogEnable}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Member List
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
          {memberListLoading ? (
            <CircularProgress
              size={24}
              style={{ marginLeft: 395, position: "relative", top: 4 }}
            />
          ) : (
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('name.label')}</TableCell>
                    <TableCell>{t('email.label')}</TableCell>
                    <TableCell>{t('phone.label')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memberList && memberList.length > 0 ? memberList.map((row, indx) => (
                    <TableRow key={indx}>
                      <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                      <TableCell>{row.email ? row.email : "-"}</TableCell>
                      <TableCell>{row.phone ? row.phone : '-'}</TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell />
                      <TableCell>No Record Found</TableCell>
                      <TableCell />
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MemberList
