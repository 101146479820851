import React from 'react';
import Table from '@material-ui/core/Table';
import {CircularProgress, TableHead, TableSortLabel} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import {useState} from 'react';
import UcpeItems from "./UcpeItems";
import SearchBox from "../../../../components/SearchBox";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OutlinedSelect from "../../../../components/OutlinedSelect";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function UcpePanel({
                       t,
                       menuItems,
                       list,
                       handleCreateButtonClick,
                       handleMenuItemClick,
                       handleSearchUcpe,
                       page,
                       handlePageChange,
                       loading,
                       filterList,
                       error
                   }) {
    const classes = useStyles();
    const columns = [
        {
            label: t('controllerIP.label'),
            key: 'controllerIP'
        }, {
            label: t('deviceIP.label'),
            key: 'deviceIP'
        }, {
            label: t('uCPEName.label'),
            key: 'uCPEName'
        },
        {
            label: t('region.label'),
            key: 'region'
        },
        {
            label: t('cpu.label'),
            key: 'cpu'
        },
        {
            label: t('ram.label'),
            key: 'ram'
        },
        {
            label: t('createdTime.label'),
            key: 'createdTime'
        },
        {
            label: t('createdBy.label'),
            key: 'createdBy'
        },
        {
            label: t('status.label'),
            key: 'status'
        },
        {
            label: t('action.label'),
            key: 'button'
        }
    ];
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdTime');
    /* if (!loading && !error) {
         list = list.getUcpes && list.getUcpes.length ? list.getUcpes : [];
     }*/
    if (!loading && !error) {
        list =
            list.getUcpesPagination && list.getUcpesPagination.results && JSON.parse(list.getUcpesPagination.results).results && JSON.parse(list.getUcpesPagination.results).results.length
                ? JSON.parse(list.getUcpesPagination.results)
                : [];
    }
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div>
            <SearchBox handleSearch={handleSearchUcpe}/>
            {filterList &&
            filterList.map((filter, index) => (
                <OutlinedSelect
                    key={index}
                    val={filter.val}
                    label={filter.label}
                    handleChange={filter.handleChange}
                    IconComponent={filter.iconComponent || null}
                    options={filter.options}
                    styleOverrides={filter.styleOverrides || null}
                    disabled={filter.disabled || false}
                />
            ))}
            <Grid
                style={{display: 'inline-flex'}}>
                <Typography variant={'subtitle2'}>{list.pagination && list.pagination.total_entries ? list.pagination.total_entries + ' of ' + list.pagination.total_devices : <CircularProgress size={14}/>}</Typography>
            </Grid>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), float: 'right'}}>
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('ucpe_modal')}>
                    {t('createNew.label')}
                </Button>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    /*   <TableCell key={index}>
                                           <Typography variant={'subtitle2'}>{item.label}</Typography>
                                       </TableCell>*/
                                    <TableCell
                                        key={index}
                                        padding={'default'}
                                        sortDirection={orderBy === item.key ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === item.key}
                                            direction={orderBy === item.key ? order : 'asc'}
                                            onClick={createSortHandler(item.key)}
                                        >
                                            {item.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ? (
                                list && list.results && list.results.length > 0 ? (
                                    list.results.map((ucpe, rowIndex) => (
                                        <UcpeItems
                                            key={rowIndex}
                                            t={t}
                                            ucpe={ucpe}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {!loading &&
                    <div>
                        <Pagination color="secondary" className={classes.paging}
                                    count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                    onChange={handlePageChange}/>
                    </div>
                    }
                </TableContainer>
            </Grid>
        </div>
    );
}
UcpePanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default UcpePanel;
