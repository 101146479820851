import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Box from '@material-ui/core/Box';

const VpnsPanelItem = ({
  t,
  data,
  handleMenuItemClick,
  menuItems,
  allowConfigure
}) => {

    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
  
    return (
        <TableRow key={data.id}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.id}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.camera_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.camera_url ? data.camera_url : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.camera_protocol ? data.camera_protocol : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.is_active ? 
                                <Box color="success.main">{t('active.label')}</Box>
                            :
                                <Box color="error.main">{t('inactive.label')}</Box>
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {dateFormatterLLL(data.created_at)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {allowConfigure && 
                <TableCell>
                    <Grid>
                        <IconButton
                            size={'small'}
                            disableRipple={true}
                            disableFocusRipple={true}
                            color={'secondary'}
                            onClick={evt => {
                                setAnchorEl(evt.currentTarget);
                                evt.stopPropagation();
                            }}
                        >
                            <MoreHoriz className={commonClasses.iconRegular}/>
                        </IconButton>
                    </Grid>
                    {anchorEl && <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={menuItems}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, data);
                            setAnchorEl(null);
                        }}
                    />}
                </TableCell>
            }
        </TableRow>
    );
};

VpnsPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(VpnsPanelItem);