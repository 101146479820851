import React, { useContext} from 'react';
import PropTypes from 'prop-types';
import { get } from "lodash-es";
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Folder from '@material-ui/icons/Folder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import { Badge } from '@material-ui/core';

import { ProfileContext } from '../../../../../../contexts/ProfileProvider';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));
 
function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, type, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText} 
          </Typography>
          {/* <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography> */}
          {typeof labelInfo !== "undefined" && <Badge style={{right: 10}} badgeContent={parseInt(labelInfo)} color="primary" />}
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        ...(type === "folder" && {paddingLeft: 20}),
        ...(type === "place" && {padding: 8})
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    marginRight: 38,
  },
});

export default function OcrTreeSideBar({ treeData, handleClickItem, selectedFile, treeExapandArray, ocrProcessList,expendBySearch, selectedNodes, handleToggle, handleSelect, isSearchable = false }) {

  const classes = useStyles();
  const { profile } = useContext(ProfileContext);

  let searchableAttribute = {}
  if (isSearchable){
    searchableAttribute = {
      expanded: expendBySearch,
      selected: selectedNodes,
      onNodeToggle: handleToggle,
      onNodeSelect: handleSelect,
      multiSelect: 'true',
    }
  }

  
  
  return (
        <TreeView
          className={classes.root}
          defaultExpanded={treeData && treeData.length ? [treeData[0].id] : []}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          { ...searchableAttribute }
        > 

      {treeData && treeData.length ? treeData.map(place => (
        <StyledTreeItem 
          nodeId={place.id} 
          labelText={place.name}
          type={"place"} 
          labelIcon={LocationCityIcon}
          onClick={evt => handleClickItem(place, "place")} >
          {place.projects && place.projects.length ? place.projects.map(proj => {
            let stepData = [];
            if (ocrProcessList && ocrProcessList.length > 0){
            stepData =  ocrProcessList.filter(el => el.assignedProjectIdToString === proj.id? proj.isProcessavilbaleForProject = true: null);
            }
            if(stepData.length> 0){
            proj.stepData = stepData.filter(el=> el.approvalUserid === get(profile, 'userInfo.id', '')? el : null);
            }
          return( 
            <StyledTreeItem
              type="project"
              nodeId={proj.id}
              labelText={proj.name}
              labelInfo={proj.folders ? proj.folders.length : "0"}
              labelIcon={AssignmentIcon}
              onClick={evt => handleClickItem(proj, "project", place.id)}
            >

             
            {proj.folders && proj.folders.length ? proj.folders.map(folder => {
               
            return( 
              <>
                
              <StyledTreeItem
                nodeId={folder.id}
                type="folder"
                labelText={folder.name}
                labelInfo={folder.files ? folder.files.length : "0"}
                labelIcon={Folder}
                onClick={evt => handleClickItem(folder, "folder", proj.id, place.id)}
              >

{folder.subfolders && folder.subfolders.length ? folder.subfolders.map(subfolders => {
                     let stepData = [];
                     if (ocrProcessList && ocrProcessList.length > 0){
                       stepData =  ocrProcessList.filter(el => el.assignedProjectIdToString === subfolders.project_id? subfolders.isProcessAvilbaleForsubfolders = true: null);
                     }
                     if(stepData.length> 0){
                       subfolders.stepData = stepData;
                     }
                     let stepDataForFolder = [];
                     if (ocrProcessList && ocrProcessList.length > 0){
                       stepDataForFolder =  ocrProcessList.filter(el => el.assignedFolderIdToString === subfolders.folder_id? subfolders.isProcessAvilbaleForsubfolders = true: null); 
                     }
                     if(stepDataForFolder.length> 0){
                       subfolders.stepData = stepDataForFolder;
                     } 
                     return ( 
                     <StyledTreeItem
                       type="folder"
                       nodeId={subfolders.id}
                       labelInfo={subfolders?.files?.length > 0 ? subfolders?.files?.length : "0"}
                       labelText={`${subfolders.name.slice(0, 22)} ${subfolders.name.length > 22 ? `.${subfolders.name.split('.').pop()}`: ''} `}
                       onClick={evt => handleClickItem(subfolders, "folder", folder.id, folder.project_id, place.id)}
                       labelIcon={Folder}
                      //  color={subfolders.id === selectedsubfolders ? "#1a73e8" : "#1a73e8"}
                       bgColor="#e8f0fe"
                     >
                         
                         {subfolders.files && subfolders.files.length ? subfolders.files.map(file => {
  let stepData = [];
  if (ocrProcessList && ocrProcessList.length > 0){
    stepData =  ocrProcessList.filter(el => el.assignedProjectIdToString === file.project_id? file.isProcessAvilbaleForFile = true: null);
  }
  if(stepData.length> 0){
    file.stepData = stepData;
  }
  let stepDataForFolder = [];
  if (ocrProcessList && ocrProcessList.length > 0){
    stepDataForFolder =  ocrProcessList.filter(el => el.assignedFolderIdToString === file.folder_id? file.isProcessAvilbaleForFile = true: null); 
  }
  if(stepDataForFolder.length> 0){
    file.stepData = stepDataForFolder;
  } 
  return ( 
  <StyledTreeItem
    type="file"
    nodeId={file.id}
    labelText={`${file.file_name.slice(0, 22)} ${file.file_name.length > 22 ? `.${file.file_name.split('.').pop()}`: ''} `}
    onClick={evt => handleClickItem(file, "file", folder.id, proj.id, place.id)}
    labelIcon={DescriptionIcon}
    color={file.id === selectedFile ? "#1a73e8" : "#1a73e8"}
    bgColor="#e8f0fe"
  />
  );}) 
: null}
                         </StyledTreeItem>
                     );}) 
                   : null}



                {folder.files && folder.files.length ? folder.files.map(file => {
                  let stepData = [];
                  if (ocrProcessList && ocrProcessList.length > 0){
                    stepData =  ocrProcessList.filter(el => el.assignedProjectIdToString === file.project_id? file.isProcessAvilbaleForFile = true: null);
                  }
                  if(stepData.length> 0){
                    file.stepData = stepData;
                  }
                  let stepDataForFolder = [];
                  if (ocrProcessList && ocrProcessList.length > 0){
                    stepDataForFolder =  ocrProcessList.filter(el => el.assignedFolderIdToString === file.folder_id? file.isProcessAvilbaleForFile = true: null); 
                  }
                  if(stepDataForFolder.length> 0){
                    file.stepData = stepDataForFolder;
                  } 
                  return ( 
                  <StyledTreeItem
                    type="file"
                    nodeId={file.id}
                    labelText={`${file.file_name.slice(0, 22)} ${file.file_name.length > 22 ? `.${file.file_name.split('.').pop()}`: ''} `}
                    onClick={evt => handleClickItem(file, "file", folder.id, proj.id, place.id)}
                    labelIcon={DescriptionIcon}
                    color={file.id === selectedFile ? "#1a73e8" : "#1a73e8"}
                    bgColor="#e8f0fe"
                  />
                  );}) 
                : null}
              </StyledTreeItem>
              </>
            )
          }) 
            : null}
            </StyledTreeItem>
          )})
          : null }
        </StyledTreeItem>
      )) : null }
    </TreeView>
  );
}
