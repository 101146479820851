import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths, ServiceTicketPaths } from '../../routes/routePaths';
import TicketDetails from './TicketDetails';
import ServiceTicketLists from './ServiceTicketLists';
import NewServiceTicket from './NewServiceTicket';

class ServiceTicket extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={ServiceTicketPaths.Details}
          component={TicketDetails}
        />
        <Route
          exact={true}
          path={Paths.ServiceTickets}
          component={ServiceTicketLists}
        />
        <Route
          exact={true}
          path={ServiceTicketPaths.NewServiceTicket}
          component={NewServiceTicket}
        />
      </Switch>
    );
  }
}
export default ServiceTicket;
