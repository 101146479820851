import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import {
    UPDATE_PMO_PROJECT, CLOSE_PMO_SPECIAL_PROJECT, ADD_INSTALL_TEAM_LEAD,
    ADD_IM_TEAM, ADD_CHNAGE_CONTROL_TEAM, ADD_INTEGRATION_ENGINEER_TEAM, ADD_QUALITY_ASSURANCE_TEAM,
    ADD_PROJECT_MANAGEMENT_TEAM, ADD_FINANCE_TEAM, ADD_CSOC_TEAM, ADD_RADIO_ENGINEERING_TEAM,
    ADD_OUTSIDE_PROJECT_MANAGEMENT_TEAM, CREATE_CHANGE_CONTROL_REQUEST_FORM, UPLOAD_FILES, ADD_ACTIVATION_TEAM
} from "../../mutations";
import { GET_PMO_SPECIAL_PROJECTS, GET_PROJECTS_USER } from "../../queries";
import SpecialProjectsItem from "../SpecialProjectsItem";
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreateInstallTeamLeadModal from "../CreateInstallTeamLeadModal";
import CreateRadioEngineeringTeamModal from "../CreateRadioEngineeringTeamModal";
import CreateOutsideProjectManagementTeamModal from "../CreateOutsideProjectManagementTeamModal";
import Button from '@material-ui/core/Button';
import { GET_USER_INFO } from '../../../../Profile/queries';
import { GET_ALL_USER_BY_ROLES } from '../../../../commonQueries';
import CreateProjectInitiateModal from "../../../../Subscriptions/components/CreateProjectInitiateModal";
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import ProjectAttachmentModal from "../ProjectAttachmentModal";
import CreateActivationTeamModal from "../Botswana/Components/CreateActivationTeamModal";
import CircularProgress from '@material-ui/core/CircularProgress';
import RejectProjectDescriptionModal from '../RejectProjectDescriptionModal';
import CreateServiceDeliveryTeamModal from '../Malawai/Components/CreateServiceDeliveryTeamModal';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    floatRight: {
        float: 'right'
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const Projets = ({ t, refreshTab, handleRefreshTab }) => {
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [projectsUser, setProjectUser] = useState([]);
    const [installTeamSection, setInstallTeamSection] = useState(false);
    const [radioEngineeringTeam, setRadioEngineeringTeam] = useState(false);
    const [outsideProjectManagementTeam, setOutsideProjectManagementTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [selectedProjectTypeAndUid, setSelectedProjectTypeAndUid] = useState({ project_type: '', uid: '', case_number: '', opportunity_uid: '', customer_name: '', organization_name: '', auto_generated_project_id: '', project_owner: '', project_name: '' });
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [AllInstallTeamUserList, setAllInstallTeamUserList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [changeControlRequestQuestionFormModalState, setChangeControlRequestQuestionFormModalState] = useState(false);
    const [ChangeControlRequestQuestionModalState, setChangeControlRequestQuestionModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [ChangeControlRequestFormUrl, setChangeControlRequestFormUrl] = useState('');
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [ShowBackButton, setShowBackButton] = useState(false);
    const [disableCloseProject, setDisableCloseProject] = useState(true);
    const [IsEmptySiteArray, setIsEmptySiteArray] = useState(false);
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [activationTeamSection, setActivationTeamSection] = useState(false);
    const [modalContentLoading, setModalContentLoading] = useState(false);
    const [rejectProjectDescription, setRejectProjectDescription] = useState({ open: false, btnLoading: false, clearData: false });
    const [serviceDeliveryTeam, setServiceDeliveryTeam] = useState(false);
    const [feetPortFormModalState, setFeetPortFormModalState] = useState({ open: false, btnLoading: false, clearData: false });
    const [feetportFormUrl, setFeetportFormUrl] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in special project', error);
        },
        onCompleted: data => {
            // console.log("data : ", data);
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            setCurrentUserName(full_name);
        }
    });
    // }
    const menuItems = [
        { label: t('Initiated') },
        { label: t('NACR sent for Signoff') },
        { label: t('NACR Not Required') },
        { label: t('NACR Approved') },
        { label: t('NACR Declined') },
        { label: t('Generate OPEX COST and New Service') },
        { label: t('Control Doc Sent for Approval') },
        { label: t('Generate Purchase Request') },
        { label: t('Request for Site Survey') },
        { label: t('Requested for Detailed Engineering Doc') },
        { label: t('Completed') },
    ];
    let projectsType = [];
    if (COUNTRY_LIST.NIGERIA === countryName) {
        projectsType.push({
            id: 'Regular project',
            name: 'Regular Project'
        },
            {
                id: 'High Capacity',
                name: 'High Capacity'
            },
            {
                id: 'Coverage Outside Inq',
                name: 'Coverage Outside Inq'
            },
            {
                id: 'Close Project',
                name: 'Close Project'
            })
    } else if (COUNTRY_LIST.BOTSWANA === countryName) {
        projectsType.push({
            id: 'Dedicated Internet Access',
            name: 'Dedicated Internet Access'
        },
            {
                id: 'MPLS',
                name: 'MPLS'
            },
            {
                id: 'Broadband',
                name: 'Broadband'
            },
            {
                id: 'Domain Services',
                name: 'Domain Services'
            },
            {
                id: 'Cloud Data Backup',
                name: 'Cloud Data Backup'
            },
            {
                id: 'Virtual Machine Service',
                name: 'Virtual Machine Service'
            })
    } else if (COUNTRY_LIST.MALAWI === countryName) {
        projectsType.push({
            id: 'Client Installation',
            name: 'Client Installation'
        },
            {
                id: 'Network Installation',
                name: 'Network Installation'
            },
            {
                id: 'Client Maintenance',
                name: 'Client Maintenance'
            },
            {
                id: 'Network Maintenance',
                name: 'Network Maintenance'
            },
            {
                id: 'Client Survey',
                name: 'Client Survey'
            },
            {
                id: 'Network Survey',
                name: 'Network Survey'
            })
    }
    const [getProjectsUser] = useLazyQuery(GET_PROJECTS_USER, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getProjectsUser) {
                let parseResult = JSON.parse(data.getProjectsUser.projectsUserData);
                parseResult = parseResult.map(item => {
                    return {
                        id: item.name,
                        name: item.name,
                    }
                })
                setProjectUser(parseResult);
            }
        },
    });
    // const [getProjectsUser] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
    //     onError: error => {
    //         // console.log('Error', error);
    //     },
    //     onCompleted: data => {
    //         let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
    //         setProjectUser(parseData);
    //     }
    // });
    const [closePmoSpecialProject] = useMutation(CLOSE_PMO_SPECIAL_PROJECT, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            setModalContentLoading(false);
            Message.success('Project closed successfully');
            setLoading(true);
            setError(true);
            var addProjectType = "";
            if (ShowBackButton === true) {
                addProjectType = "&project_type=Close Project"
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                    setDisableCloseProject(false);
                }
            }
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin" + addProjectType
                }
            })
        }
    });
    const [updatePmoProject] = useMutation(UPDATE_PMO_PROJECT, {
        onError: error => {
            console.log('Error updatePmoProject', error);
        },
        onCompleted: data => {
            if (rejectProjectDescription.open === true) {
                Message.success('Project rejected successfully');
                setRejectProjectDescription({ open: false, btnLoading: false, clearData: true });
            } else {
                Message.success('Project updated successfully');
            }
            setLoading(true);
            setError(true);
            var addProjectType = "";
            if (ShowBackButton === true) {
                addProjectType = "&project_type=Close Project";
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                    setDisableCloseProject(false);
                }
            }
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                getAllProjectList({
                    variables: {
                        pageNumberAndOwner: "page=1&project_owner=is_admin" + addProjectType
                    }
                })
            }
        }
    });
    useEffect(() => {
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER) ) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin"
                }
            })
        }
        getAllInstallTeamUserList({
            variables: {
                role: USER_ROLES.INSTALL_TEAM_LEAD
            }
        })
        getProjectsUser({
            variables: {
                billingCountry: "billing_country=" + countryName
            }
        })
    }, []);
    const classes = useStyles();
    const [getAllInstallTeamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error role install special project', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role install: ", parseData);
                setAllInstallTeamUserList(parseData);
            }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        var addProjectType = "";
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        if (ShowBackButton === true) {
            addProjectType = "&project_type=Close Project"
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                setDisableCloseProject(false);
            }
        }
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=" + value + "&project_owner=is_admin" + addProjectType + checkSearchFilterData
                }
            })
        }
    };
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')){
            Message.error("Please select start or end date.");
            return false;
        } 
        if(convertStartDate === 'Invalid date'){
            convertStartDate = '';
        } 
        if(convertEndDate === 'Invalid date'){
            convertEndDate = '';
        } 
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        }
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin"
                }
            })
        }
    };
    const handleClosedProjectsList = (value) => {
        setShowBackButton(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            setDisableCloseProject(false);
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin&project_type=Close Project"
                }
            })
        }
    };
    const handleOpenProjectsList = (value) => {
        setShowBackButton(false);
        setDisableCloseProject(true);
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PMO_READ_ONLY) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllProjectList({
                variables: {
                    pageNumberAndOwner: "page=1&project_owner=is_admin"
                }
            })
        }
    };
    let columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customerName"
        },
        {
            label: t('status.label'),
            key: "project_status"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('modified.label'),
            key: "modified"
        },
        {
            label: t('owner.label'),
            key: "project_owner"
        },
        {
            label: t('attachments.label'),
            key: "attachments"
        },
        {
            label: t('reject.label') + ' ' + t('project.label'),
            key: "reject"
        },
    ];
    if ((role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN))) {
        columns.push({
            label: t('action.label'),
            key: "button"
        });
    }
    const [getAllProjectList] = useLazyQuery(GET_PMO_SPECIAL_PROJECTS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            if (data && data.getPmoSpecialProjectsList) {
                let parseResult = JSON.parse(data.getPmoSpecialProjectsList.projectData);
                //console.log("parseResult :", parseResult)
                setList(parseResult);
            }
        },
        onError: error => {
            console.log("pmo error special project:", error);
            setList([]);
            setLoading(false);
            setError(false);
        }
    });
    const [addInstallTeam] = useMutation(ADD_INSTALL_TEAM_LEAD, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addInstallTeam : ", data);
            if (data && data.addInstallTeamLeadSection) {
                if (JSON.parse(data.addInstallTeamLeadSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addInstallTeamLeadSection.teamData);
                    Message.success('New install team lead section created successfully');
                    setPreValuesOfForm({});
                    handleCloseInstallTeamModal();
                    // if (selectedProjectTypeAndUid.project_type === 'Regular project') {
                    //     handleImTeam();
                    // } else if (selectedProjectTypeAndUid.project_type === 'High Capacity' || selectedProjectTypeAndUid.project_type === 'Coverage Outside Inq') {
                    //     handleChangeControlTeam();
                    // }
                } else {
                    let message = JSON.parse(data.addInstallTeamLeadSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addActivationTeam] = useMutation(ADD_ACTIVATION_TEAM, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addActivationTeam : ", data);
            if (data && data.addActivationTeamSection) {
                if (JSON.parse(data.addActivationTeamSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addActivationTeamSection.teamData);
                    Message.success('New activation team section created successfully');
                    handleCloseInstallTeamModal();
                    setModalState({ btnLoading: false, clearData: true });
                    setActivationTeamSection(false);
                } else {
                    let message = JSON.parse(data.addActivationTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({...modalState, btnLoading: true });
        //console.log("file in handle : ",file);
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: true });
            //console.log('Response of uopload files', res);
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const [addRadioEngineeringTeam] = useMutation(ADD_RADIO_ENGINEERING_TEAM, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addRadioEngineeringTeam : ", data);
            if (data && data.addRadioEngineeringTeamSection) {
                if (JSON.parse(data.addRadioEngineeringTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New radio engineering team section created successfully');
                    let parseData = JSON.parse(data.addRadioEngineeringTeamSection.teamData);
                    setPreValuesOfForm(parseData);
                    handleCloseRadioEngineeringTeam();
                    if (selectedProjectTypeAndUid.project_type === 'High Capacity') {
                        setChangeControlRequestFormUrl('');
                        handleCreateInstallTeam();
                    }
                } else {
                    let message = JSON.parse(data.addRadioEngineeringTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const [addOutsideProjectManagementTeam] = useMutation(ADD_OUTSIDE_PROJECT_MANAGEMENT_TEAM, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addOutsideProjectManagementTeam : ", data);
            if (data && data.addOutsideProjectManagementTeamSection) {
                if (JSON.parse(data.addOutsideProjectManagementTeamSection.status) === true) {
                    handleRefreshTab(true);
                    Message.success('New outside project management team section created successfully');
                    let parseData = JSON.parse(data.addOutsideProjectManagementTeamSection.teamData);
                    setPreValuesOfForm(parseData);
                    handleCloseOutsideProjectManagementTeam();
                    setChangeControlRequestFormUrl('');
                    handleCreateInstallTeam();
                } else {
                    let message = JSON.parse(data.addOutsideProjectManagementTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleMenuItemClick = (elem, uid) => {
        if (
            window.confirm('Are you sure you want to make this person become an owner ?') ===
            false
        ) {
            return false;
        }
        // console.log("elem : ", elem);
        // console.log("uid : ", uid);
        return updatePmoProject({
            variables: {
                uid: uid,
                project_owner: elem,
                status: 'Assigned to PM'
            }
        });
    }
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }

    const handleCloseSpecialProjectTypeClick = (data) => {
        if (
            window.confirm('Are you sure you want to close this project ?') ===
            false
        ) {
            return false;
        }
        return closePmoSpecialProject({
            variables: {
                uid: data.uid,
            }
        });
    }

    const handleSubmitInstallTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        handleRefreshTab(false);
        addInstallTeam({
            variables: {
                project_files: formData.projectFiles,
                project_uid: formData.project_uid,
                change_control_questionaire_form: formData.changeControlForm,
                // affix_circuit_id: formData.affixCircuitId,
                im_team: formData.attachIMPersonnel,
                comment: formData.comment,
                recommendation: formData.recommendation,
                cost_control_form: formData.costControlForm,
                previous_comment: formData.previous_comment,
                equipments_to_be_deployed: formData.equipments_to_be_deployed,
                site_array: formData.site_array,
                documents: formData.attachAnyOtherDoc,
                project_type: formData.project_type,
                last_team_member_name: CurrentUserName,
            }
        })
    }
    const handleSubmitActivationTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        addActivationTeam({
            variables: {
                project_uid: formData.project_uid,
                customer_name: formData.customer_name,
                location: formData.location,
                assigned_Ip: formData.assigned_Ip,
                vlan: formData.vlan,
                service_Id: formData.service_Id,
                allocated_bandwidth: formData.allocated_bandwidth,
                sideA_address: formData.sideA_address,
                sideB_address: formData.sideB_address,
                base_station: formData.base_station,
                domain_name: formData.domain_name,
                domain_owner: formData.domain_owner,
                contact_person_details: formData.contact_person_details,
                domain_service_attached: formData.domain_service_attached,
                email_package: formData.email_package,
                address_service: formData.address_service,
                VMIP: formData.VMIP,
                capicity_allocated: formData.capicity_allocated,
                VM_specs: formData.VM_specs,
                storage_capacity: formData.storage_capacity,
                number_of_machines: formData.number_of_machines,
            }
        })
    }
    const handleSubmitServiceDeliveryTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        addActivationTeam({
            variables: {
                project_uid: formData.project_uid,
                customer_name: formData.customer_name,
                location: formData.location,
                assigned_Ip: formData.assigned_Ip,
                vlan: formData.vlan,
                service_Id: formData.service_Id,
                allocated_bandwidth: formData.allocated_bandwidth,
                sideA_address: formData.sideA_address,
                sideB_address: formData.sideB_address,
                base_station: formData.base_station,
                domain_name: formData.domain_name,
                domain_owner: formData.domain_owner,
                contact_person_details: formData.contact_person_details,
                domain_service_attached: formData.domain_service_attached,
                email_package: formData.email_package,
                address_service: formData.address_service,
                VMIP: formData.VMIP,
                capicity_allocated: formData.capicity_allocated,
                VM_specs: formData.VM_specs,
                storage_capacity: formData.storage_capacity,
                number_of_machines: formData.number_of_machines,
                last_team_member_name: CurrentUserName,
            }
        })
    }
    const handleSubmitRadioEngineeringTeam = (formData) => {
        // console.log("radio : ", formData);
        setModalState({...modalState, btnLoading: true });
        handleRefreshTab(false);
        addRadioEngineeringTeam({
            variables: {
                project_files: formData.projectFiles,
                recommendation: formData.recommendation,
                install_team: formData.installTeam,
                project_uid: formData.project_uid,
                comment: formData.comment,
                previous_comment: formData.previous_comment,
                equipments_to_be_deployed: "",
                auto_generated_project_id: "",
                last_team_member_name: CurrentUserName,
            }
        })
    }
    const handleSubmitOutsideProjectManagementTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        handleRefreshTab(false);
        addOutsideProjectManagementTeam({
            variables: {
                project_files: formData.projectFiles,
                cost_control_form: formData.costControlForm,
                install_team: formData.installTeam,
                purchase_order_form: formData.purchaseOrderForm,
                select_vendor: formData.vendor,
                project_uid: formData.project_uid,
                email_vendor: formData.vendorEmail,
                equipments_to_be_deployed: "",
                auto_generated_project_id: "",
                next_team_member_email: formData.next_team_member_email,
                last_team_member_name: CurrentUserName,
            }
        })
    }
    // open model
    const handleCreateInstallTeam = () => {
        setInstallTeamSection(true);
    }
    const handleOutsideProjectManagementTeam = () => {
        setOutsideProjectManagementTeam(true);
    }
    // close model
    const handleCloseInstallTeamModal = () => {
        setIsEmptySiteArray(true);
        setModalState({ btnLoading: false, clearData: true });
        setInstallTeamSection(false);
    }
    const handleCloseRadioEngineeringTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setRadioEngineeringTeam(false);
    }
    const handleCloseOutsideProjectManagementTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setOutsideProjectManagementTeam(false);
    }
    /**
     * Create Project Initiate Form Submit
     */
    const handleSaveProjectInitiate = (formData) => {
        setChangeControlRequestQuestionModalState({ btnLoading: true, clearData: false });
        formData.project_name = selectedProjectTypeAndUid.uid;
        formData.opportunity = selectedProjectTypeAndUid.opportunity_uid;
        formData.opportunityId = selectedProjectTypeAndUid.opportunity_uid;
        SaveChangeControlRequestQuestionForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [SaveChangeControlRequestQuestionForm] = useMutation(CREATE_CHANGE_CONTROL_REQUEST_FORM, {
        onError: error => {
            setChangeControlRequestQuestionModalState({ btnLoading: false, clearData: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data : ", data);
            setChangeControlRequestQuestionModalState({ btnLoading: false, clearData: false });
            if (data && data.createCostControlRequest) {
                if (JSON.parse(data.createCostControlRequest.status) === true) {
                    let parseData = JSON.parse(data.createCostControlRequest.teamData);
                    setChangeControlRequestFormUrl(parseData.pdf_link);
                    Message.success('Change control request questionarie form created successfully');
                    setChangeControlRequestQuestionFormModalState(false);
                    setInstallTeamSection(true);
                } else {
                    let message = JSON.parse(data.createCostControlRequest.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleOpenChangeControlRequestQuestionForm = () => {
        setChangeControlRequestQuestionFormModalState(true);
    }
    const handleRejectProjectClick = async (data) => {
        setSelectedProjectTypeAndUid({
            project_type: data.project_type,
            uid: data.uid,
            case_number: data.case_number,
            opportunity_uid: data.opportunity_uid,
            customer_name: data.customer_name,
            organization_name: data.organization_name,
            project_owner: data.project_owner,
            project_name: data.project_name,
            auto_generated_project_id: (data.auto_generated_project_id ? data.auto_generated_project_id : '')
        });
        setRejectProjectDescription({...rejectProjectDescription, btnLoading: true });
    }
    const handleSubmitRejectProjectDescription = async (data) => {
        setRejectProjectDescription({...rejectProjectDescription, btnLoading: true });
        setLoading(true);
        setError(true);
        await updatePmoProject({
            variables: {
                uid: data.uid,
                is_rejected: true,
                rejected_description: data.description
            }
        });
        return handleRefreshTab(true);
    }
    return (
        <>
            {/*  {(role.includes(USER_ROLES.EP_IT) || role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) &&
            <Header
                title={t('myIncidents.label')}
                subText={`${t('myIncidents.label')}`}
                // primaryButtonText={t('createNew.label')}
                //handlePrimaryButtonClick={handleCreateButtonClick}
            />}*/}
            <Grid container>
                <Grid item xs={8}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid style={{ margin: '0px 0px 15px 0px' }}>
                        {ShowBackButton &&
                            <Button
                                variant={'outlined'}
                                visibility="hidden"
                                color={'primary'}
                                style={{ marginRight: '15px', float: 'right' }}
                                onClick={handleOpenProjectsList}
                            >{t('back.label')}
                            </Button>
                        }
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ marginRight: '15px', float: 'right' }}
                            onClick={handleClosedProjectsList}
                        >{t('closeProjects.label')}
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <SpecialProjectsItem data={item} key={index} handleMenuItemClick={handleMenuItemClick} handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleRejectProjectClick={handleRejectProjectClick} disableCloseProject={disableCloseProject} menuItems={menuItems} projectsUser={projectsUser} projectsType={projectsType} handleCloseSpecialProjectTypeClick={handleCloseSpecialProjectTypeClick} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateInstallTeamLeadModal
                    modalContentLoading={modalContentLoading}
                    open={installTeamSection}
                    handleSubmitClick={handleSubmitInstallTeam}
                    t={t}
                    isActionUpdate={false}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    handleClose={handleCloseInstallTeamModal}
                    modalState={modalState}
                    AllInstallTeamUserList={AllInstallTeamUserList}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    IsEmptySiteArray={IsEmptySiteArray}
                    handleUploadFiles={handleUploadFiles}
                    ChangeControlRequestFormUrl={ChangeControlRequestFormUrl}
                    handleOpenChangeControlRequestQuestionForm={handleOpenChangeControlRequestQuestionForm}
                />
                <CreateRadioEngineeringTeamModal modalContentLoading={modalContentLoading} open={radioEngineeringTeam} handleSubmitClick={handleSubmitRadioEngineeringTeam} t={t} CurrentUserName={CurrentUserName}
                    handleClose={handleCloseRadioEngineeringTeam} modalState={modalState} AllInstallTeamUserList={AllInstallTeamUserList} selectedProjectTypeAndUid={selectedProjectTypeAndUid} />
                <CreateOutsideProjectManagementTeamModal modalContentLoading={modalContentLoading} open={outsideProjectManagementTeam} handleSubmitClick={handleSubmitOutsideProjectManagementTeam} t={t}
                    handleClose={handleCloseOutsideProjectManagementTeam} handleUploadFiles={handleUploadFiles} modalState={modalState} AllInstallTeamUserList={AllInstallTeamUserList} selectedProjectTypeAndUid={selectedProjectTypeAndUid} />
                <CreateActivationTeamModal
                    t={t}
                    modalContentLoading={modalContentLoading}
                    open={activationTeamSection}
                    handleSubmitClick={handleSubmitActivationTeam}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    handleClose={() => {
                        setModalState({ btnLoading: false, clearData: true });
                        setActivationTeamSection(false);
                    }}
                    modalState={modalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                />
                <CreateServiceDeliveryTeamModal
                    t={t}
                    modalContentLoading={modalContentLoading}
                    open={serviceDeliveryTeam}
                    handleSubmitClick={handleSubmitServiceDeliveryTeam}
                    CurrentUserName={CurrentUserName}
                    preValuesOfForm={preValuesOfForm}
                    AllInstallTeamUserList={AllInstallTeamUserList}
                    feetportFormUrl={feetportFormUrl}
                    handleOpenFeetportForm={() => {
                        setChangeControlRequestQuestionFormModalState(true);
                    }}
                    handleClose={() => {
                        setModalState({ btnLoading: false, clearData: true });
                        setServiceDeliveryTeam(false);
                    }}
                    modalState={modalState}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                />
                {/* change control request questionaire */}
                <CreateProjectInitiateModal
                    t={t}
                    open={changeControlRequestQuestionFormModalState}
                    handleSaveProjectInitiate={handleSaveProjectInitiate}
                    headingName={t('changeControlQuestionaireForm.label')}
                    subheading={false}
                    // data={data}
                    handleClose={() => {
                        setChangeControlRequestQuestionModalState({ btnLoading: false, clearData: false });
                        setChangeControlRequestQuestionFormModalState(false);
                    }}
                    modalState={ChangeControlRequestQuestionModalState}
                />
                <ProjectAttachmentModal
                    open={ProjectAttachment.open}
                    t={t}
                    handleClose={() => {
                        setProjectAttachment({ open: false, data: [] });
                    }}
                    attachmentList={ProjectAttachment.data}
                />
                <RejectProjectDescriptionModal
                    open={rejectProjectDescription.open}
                    t={t}
                    modalState={rejectProjectDescription}
                    handleClose={() => {
                        setRejectProjectDescription({ open: false, btnLoading: false, clearData: true });
                    }}
                    selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    handleSubmitClick={handleSubmitRejectProjectDescription}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(Projets);
