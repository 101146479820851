import {gql} from 'apollo-boost';
/*
  Add Service Type
 */
export const CREATE_AND_UPDATE_SERVICE_TYPE = gql`
  mutation ServiceType($name: String, $countries: [String], $id: String){
    addUpdateServiceType(name: $name, countries: $countries, id: $id) {
      id
    }
  }
`
/*
  Add Service Product
 */
export const CREATE_SERVICE_PRODUCT = gql`
  mutation Service($id: String, $name: String, $description: String, $serviceTypeId: String, $avgUptime: Float, $status: String, $avgCost: Float, $enableAvgCost: Boolean){
    addService(id: $id, name: $name, description: $description, serviceTypeId: $serviceTypeId, avgUptime: $avgUptime, status: $status, avgCost: $avgCost, enableAvgCost: $enableAvgCost) {
      data
    }
  }
`
export const ADD_UPDATE_SERVICE_BILLING = gql`
  mutation Service($data: String){
    addUpdateServiceBilling(data: $data) {
      id
    }
  }
`