import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import commonStyles from '../../theme/commonStyles';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { paddingGenerator } from '../../theme/utils';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
  breadCrumbBar: {
    minHeight: theme.spacing(5),
    backgroundColor: theme.palette.common.white
  },
  primaryOptions: {
    cursor: 'pointer',
    fontWeight: 'normal'
  },
  chevron: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

function Breadcrumb({ breadcrumbList, t, history }) {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const handleItemClick = (item, index) => {
    let breadCrumbValLength = breadcrumbList.length - 1;
    if (breadCrumbValLength - index > 0) {
      history.go(index - breadCrumbValLength);
    }
  };

  return (
    <Toolbar
      className={classes.breadCrumbBar}
      style={
        window.innerWidth > 1024 ? { ...paddingGenerator('pl-40') } : null
      }>
      {breadcrumbList &&
        breadcrumbList.map((item, index) => (
          <React.Fragment key={`bread-${index}`}>
            <Typography
              variant={'subtitle2'}
              onClick={() => handleItemClick(item, index)}
              className={classes.primaryOptions}>
              {t(item.label)}
            </Typography>
            {index !== breadcrumbList.length - 1 && (
              <ChevronRight
                color="disabled"
                className={clsx([classes.chevron, commonClasses.iconRegular])}
              />
            )}
          </React.Fragment>
        ))}
    </Toolbar>
  );
}

Breadcrumb.propTypes = {
  breadcrumbList: PropTypes.array.isRequired
};

export default withTranslation()(withRouter(Breadcrumb));
