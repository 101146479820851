import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const initFormState = {id: null, camera_url: "", camera_protocol: "", camera_name: ""};

function CreateRtspProxy({
    t,
    open,
    handleCreateRtspSubmit,
    handleClose,
    modalState,
    rtspData
}) {
    
    const [rtspFormState, setRtspFormState] = useState({...initFormState});
    const classes = useStyles();
    const handleInputChange = (value, field) => {
        setRtspFormState({...rtspFormState, [field]: value});
    }

    React.useEffect(() => {
        if(modalState.clearData && open){
            setRtspFormState({...initFormState});
            handleClose();
        }
    }, [modalState]);

    React.useEffect(()=> {
        if(rtspData){
            setRtspFormState({id: rtspData.id, camera_url: rtspData.camera_url, camera_protocol: rtspData.camera_protocol, camera_name: rtspData.camera_name});
        }else{
            setRtspFormState({...initFormState});
        }
    }, [rtspData]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="node-modal"
            aria-describedby="node-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="node-modal-title">{ rtspData ? t('update.label') : t('createNew.label')} {t('rtsp.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Grid style={{...marginGenerator('mb-15')}}>
                            <TextField
                                value={rtspFormState.camera_name}
                                type={'text'}
                                label={t('name.label')}
                                className={classes.input}
                                placeholder={`${t('name.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'camera_name')}
                            />
                        </Grid>
                        <Grid style={{...marginGenerator('mb-15')}}>
                            <TextField
                                value={rtspFormState.camera_url}
                                type={'text'}
                                className={classes.input}
                                label={t('cameraUrl.label')}
                                placeholder={`${t('cameraUrl.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'camera_url')}
                            />
                        </Grid>
                        <Grid style={{...marginGenerator('mb-15')}}>
                            <TextField
                                value={rtspFormState.camera_protocol}
                                type={'text'}
                                label={t('cameraProtocol.label')}
                                className={classes.input}
                                placeholder={`${t('cameraProtocol.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'camera_protocol')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!rtspFormState.camera_name || !rtspFormState.camera_url || !rtspFormState.camera_protocol){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateRtspSubmit({...rtspFormState});
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateRtspProxy.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRtspSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateRtspProxy);
