import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { renderText } from '../../displayComponent';
import { getPodList } from '../../../../services/fcrService';
import Message from '../../../../components/Message';
import { getServiceProviders } from '../../../../services/serviceProviderService';
import { getUnderlayProvidersList } from '../../../../services/underLayProviderService';
import {
  addPremiumLink,
  getPremiumLinkById,
  updatePremiumLinkById
} from '../../../../services/premiumLinkService';

const CreatePremiumLinkForm = () => {
  const history = useHistory();
  const [serviceProvidersList, setServiceProvidersList] = useState([]);
  const [underlayProvidersList, setUnderlayProvidersList] = useState([]);
  const [podList, setPodList] = useState([]);
  const { mode, id } = useParams();

  const initialValues = {
    serviceProvider: '',
    underLayProvider: '',
    bizPriority: '100',
    maxBw: '',
    sla: '',
    aPod: '',
    bPod: '',
    spLink: '',
    nwInfra: 'revshare',
    active: 'yes'
  };
  const validationSchema = yup.object().shape({
    serviceProvider: yup.string().required('Required'),
    underLayProvider: yup.string().required('Required'),
    bizPriority: yup.string().required('Required'),
    maxBw: yup.string().required('Required'),
    sla: yup.string().required('Required'),
    aPod: yup.string().required('Required'),
    bPod: yup.string().required('Required'),
    spLink: yup.string().required('Required'),
    nwInfra: yup.string().required('Required'),
    active: yup.string().required('Required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      serviceProvider: data?.serviceProvider,
      biz_priority: data?.bizPriority,
      max_bw: data?.maxBw,
      sla: data?.sla,
      splink_id: data?.spLink,
      nwinfra: data?.nwInfra,
      active: data?.active
    };
    if (mode === 'add') {
      payload.underlayProvider = data?.underLayProvider;
      payload.a_pod = data?.aPod;
      payload.b_pod = data?.bPod;
    }

    if (mode === 'add') {
      const res = await addPremiumLink(payload);
      if (res?.status === 200) {
        history.push('/fabricService/premiumlink');
      } else {
        if (res?.response?.data?.message) {
          Message.error(res?.response?.data?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    } else {
      const res = await updatePremiumLinkById(id, payload);
      if (res?.status === 200) {
        if (res?.response?.data?.message || res?.message) {
          Message.success(res?.response?.data?.message || res?.message);
        } else {
          Message.success('Premium Link created successfully');
        }
        history.push('/fabricService/premiumlink');
      } else {
        if (res?.response?.data?.message || res?.message) {
          Message.error(res?.response?.data?.message || res?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    }
  };

  const GetPodList = async () => {
    const res = await getPodList();
    if (res?.status === 200) setPodList(res?.data?.result?.data);
  };

  const GetServiceProvidersList = async () => {
    const res = await getServiceProviders();
    if (res?.status === 200) {
      setServiceProvidersList(res?.data?.result);
    }
  };

  const GetUnderlayProvidersList = async () => {
    const res = await getUnderlayProvidersList();
    if (res?.status === 200) {
      setUnderlayProvidersList(res?.data?.result);
    }
  };

  useEffect(() => {
    GetServiceProvidersList();
    GetUnderlayProvidersList();
    GetPodList();
  }, []);

  const GetPremiumLink = async () => {
    const res = await getPremiumLinkById(id);
    setValue('serviceProvider', res?.data?.result?.serviceProvider);
    setValue('underLayProvider', res?.data?.result?.underlayProvider);
    setValue('bizPriority', res?.data?.result?.biz_priority);
    setValue('maxBw', res?.data?.result?.max_bw);
    setValue('sla', res?.data?.result?.sla?.$numberDecimal);
    setValue('aPod', res?.data?.result?.a_pod);
    setValue('bPod', res?.data?.result?.b_pod);
    setValue('spLink', res?.data?.result?.splink_id);
    setValue('nwInfra', res?.data?.result?.nwinfra);
    setValue('active', res?.data?.result?.active);
  };

  useEffect(() => {
    if (id) {
      GetPremiumLink();
    }
  }, [id]);

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>
                {renderText({
                  label:
                    mode === 'add' ? 'Create Premium Link' : 'Edit Premium Link'
                })}
              </Box>
            </Grid>{' '}
          </Paper>
        </Grid>

        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Service Provider
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="serviceProvider"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {serviceProvidersList?.data?.map(provider => (
                          <option key={provider?._id} value={provider?._id}>
                            {provider?.name}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  {mode === 'add' && (
                    <Grid item container justify="center">
                      <Grid item sm={3}>
                        Underlay Provider
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={5}>
                        <RHFTextField
                          select
                          name="underLayProvider"
                          SelectProps={{
                            native: true
                          }}
                          variant="outlined"
                          size="small">
                          <option value="">Select</option>
                          {underlayProvidersList?.data?.map(provider => (
                            <option key={provider?._id} value={provider?._id}>
                              {provider?.name}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Biz Priority
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="bizPriority"
                        placeholder="Enter a value between 1 to 1000"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Maximum Bandwidth
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="maxBw"
                        placeholder="Enter Bandwidth"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      SLA
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="sla"
                        placeholder="Enter SLA"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  {mode === 'add' && (
                    <Grid item container justify="center">
                      <Grid item sm={3}>
                        A-End Pod
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={5}>
                        <RHFTextField
                          select
                          name="aPod"
                          SelectProps={{
                            native: true
                          }}
                          variant="outlined"
                          size="small">
                          <option value="">Select</option>
                          {podList?.map(pod => (
                            <option key={pod?._id} value={pod?._id}>
                              {pod?.name}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Grid>
                  )}
                  {mode === 'add' && (
                    <Grid item container justify="center">
                      <Grid item sm={3}>
                        B-End Pod
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={5}>
                        <RHFTextField
                          select
                          name="bPod"
                          SelectProps={{
                            native: true
                          }}
                          variant="outlined"
                          size="small">
                          <option value="">Select</option>
                          {podList?.map(pod => (
                            <option key={pod?._id} value={pod?._id}>
                              {pod?.name}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      SP Link
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="spLink"
                        placeholder="Enter SP Link"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      NW-Infra
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="nwInfra"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'Dynamic', value: 'dynamic' },
                          { key: 'Rev Share', value: 'revshare' },
                          { key: 'Static', value: 'static' }
                        ]?.map(infra => (
                          <option key={infra?.value} value={infra?.value}>
                            {infra?.key}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Active
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="active"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'Yes', value: 'yes' },
                          { key: 'No', value: 'no' }
                        ]?.map(zone => (
                          <option key={zone?.value} value={zone?.value}>
                            {zone?.key}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() =>
                          history.push('/fabricService/premiumlink')
                        }>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit">
                        {mode === 'add' ? 'Submit' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreatePremiumLinkForm;
