import React from 'react';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead, Grid } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { marginGenerator } from '../../../../theme/utils';
import ActivityLogItem from '../ActivityLogItem';
import Input from '@material-ui/core/Input';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const ActivityLogPanel = ({
  t,
  list,
  columns,
  filterList,
  handlePageChange,
  page,
  totalCount,
  loading,
  handleSubmitFilter
}) => {

    const enterPressed = (event) => {
        let code = event.keyCode || event.which;
        if(code === 13) {
            handleSubmitFilter();
        }
    }

    return (
    <div>
        <Grid container spacing={3}>
            {filterList.length > 0 &&
            filterList.map((filter, index) => (
                <Grid item xs={3}>
                    <Input
                        type={filter.type}
                        key={index}
                        value={filter.val}
                        placeholder={filter.label}
                        color={'secondary'}
                        onChange={filter.handleChange}
                        onKeyPress={enterPressed}
                        style={{...marginGenerator('mt-20'), ...marginGenerator('mr-10'), maxWidth: "100%", minWidth: "100%"}}
                    />
                </Grid>
            ))}
            <Grid item xs={3}>
                <Button style={{...marginGenerator('mt-20'), ...marginGenerator('mr-10')}} onClick={handleSubmitFilter} variant="outlined" color="secondary">{t("submit.label")}</Button>
            </Grid>
        </Grid>
        <Grid container style={{...marginGenerator('mt-20'), ...marginGenerator('mb-24')}}>
            {loading ?
                <div style={{width:"100%", padding: 20, textAlign: "center", display:"inline-block"}}>
                    <CircularProgress color="secondary" />
                </div>
            :
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return ( 
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {list.length > 0 ?
                            list.map((item, index) => (
                                <ActivityLogItem
                                    key={index}
                                    data={item}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow> 
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <div style={{width: "100%", marginTop: 24}}>
                <Pagination style={{float: "right"}} count={Math.ceil(totalCount/20)} color="secondary" page={page} onChange={handlePageChange} />
            </div>
        </Grid>
    </div>
    );
};

ActivityLogPanel.propTypes = {
  list: PropTypes.array.isRequired,
  filterList: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default withTranslation()(ActivityLogPanel);