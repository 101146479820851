import React, { useState } from 'react';
import { get } from "lodash-es";
import { useMutation } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import VpnsPanelItem from './VpnsPanelItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateVpns from './CreateVpns';
import CreateRtspProxy from './CreateRtspProxy';
import ViewRtspProxies from './ViewRtspProxies';
import { CREATE_VPNS, UPDATE_VPNS, DELETE_VPNS, CREATE_RTSP, UPDATE_RTSP, DELETE_RTSP } from '../../mutations';
import Message from '../../../../components/Message';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import SearchBox from '../../../../components/SearchBox';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function Vpns({
    t,
    menuItems,
    list,
    loading,
    error,
    allowConfigure,
    subscriptionData,
    relaodVpnsData,
    page,
    handleSearch,
    handlePageChange
}) {

    const classes = useStyles();
    // VPNS DATA STATE
    const [openCreateVpnsModal, setOpenCreateVpnsModal] = useState(false);
    const [modalState, setModalState] = useState({clearData: false, btnLoading: false});
    const [editVpnsData, setEditVpnsData] = useState(null);
    // RTSP DATA STATE
    const [actionVpnsId, setActionVpnsId] = useState(null);
    const [openCreateRtspModal, setOpenCreateRtspModal] = useState(false);
    const [rtspModalState, setRtspModalState] = useState({clearData: false, btnLoading: false});
    const [editRtspData, setEditRtspData] = useState(null);
    const [rtspProxies, setRtspProxies] = useState(null);
    const [viewRtspProxiesModal, setViewRtspProxiesModal] = useState(false);
    const [viewSelectedId, setViewSelectedId] = useState(null);
    const [viewIdModalOpen, setViewIdModalOpen] = useState(false);
    const [goToPage, setGoToPage]    = useState(null);
    
    let columns = [
        {
            label: t('vnpsId.label'),
            key: "id"
        },
        {
            label: t('vnpsIp.label'),
            key: "vpn_ip"
        },
        {
            label: t('vpnsType.label'),
            key: "vpn_type"
        },
        {
            label: t('vpnsRoute.label'),
            key: "vpn_route"
        },
        {
            label: t('interface.label'),
            key: "interface"
        },
        {
            label: t('status.label'),
            key: "status"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        }
    ];

    let rtspMenus = [{
        label: t("edit.label")
    },{
        label: t("delete.label")
    }];

    let rtspColumns = [
        {
            label: t('rtspId.label'),
            key: "id"
        },{
            label: t('name.label'),
            key: "camera_name"
        },{
            label: t('cameraUrl.label'),
            key: "camera_url"
        },{
            label: t('cameraProtocol.label'),
            key: "camera_protocol"
        },{
            label: t('status.label'),
            key: "is_active"
        },{
            label: t('createdAt.label'),
            key: "created_at"
        }
    ];

    const handleMenuItemClick = (elem, data) => {      
        setRtspProxies(null);
        if(!data){
          return false;
        }
        let id = data.id;
        if (elem.label === t('edit.label')) {
            setEditVpnsData(data);
            setOpenCreateVpnsModal(true);
        } else if (elem.label === t('delete.label')) {
            if(window.confirm("Are you sure? You want to delete this vpns.") === true){
                return deleteVpns({variables: { id }});
            }else{
                return null;
            }
        }else if(elem.label === t('addProxies.label')){
            setActionVpnsId(id);
            setOpenCreateRtspModal(true);
        }else if(elem.label === t('viewProxies.label')){
            setRtspProxies(data.proxies ? data.proxies : []);
            setViewRtspProxiesModal(true);
        }
    };

    const handleRtspMenuItemClick = (elem, data) => {
        if(!data){
            return false;
        }
        let id = data.id;
        if (elem.label === t('edit.label')) {
            setEditRtspData(data);
            setOpenCreateRtspModal(true);
        } else if (elem.label === t('delete.label')) {
            if(window.confirm("Are you sure? You want to delete this rtsp proxy.") === true){
                return deleteRtsp({variables: { id }});
            }else{
                return null;
            }
        }
    };

    if (allowConfigure) {
        columns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
        rtspColumns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
    }

    // VPNS Methods
    const handleCreateVpnsClick = () => {
        setOpenCreateVpnsModal(true);
        setEditVpnsData(null);
    }

    const handleCreateVpnsSubmit = (formData) => {
        setModalState({btnLoading: true, clearData: false});
        if(formData.id && editVpnsData){
            return updateVpns({variables: {...formData, organization: parseFloat(subscriptionData.organizationId)}});
        }
        return createVpns({variables: {...formData, organization: parseFloat(subscriptionData.organizationId)}});
    }

    const [updateVpns] = useMutation(UPDATE_VPNS, {
        onError: error => {
          console.log('Error', error);
        },
        onCompleted: data => {
            setModalState({btnLoading: false, clearData: true});
            setOpenCreateVpnsModal(false);
            relaodVpnsData();
            setEditVpnsData(null);
            Message.success('VPNS update successfully.');
        }
    });

    const [deleteVpns] = useMutation(DELETE_VPNS, {
        onError: error => {
          console.log('Error', error);
        },
        onCompleted: data => {
            relaodVpnsData();
            Message.success('VPNS delete successfully.');
        }
    });

    const [createVpns] = useMutation(CREATE_VPNS, {
        onError: error => {
            setModalState({ btnLoading: false, clearData: false });
            Message.error(error);
            console.log('Error: ', error);
        },
        onCompleted: data => {
            setModalState({btnLoading: false, clearData: true});
            setOpenCreateVpnsModal(false);
            relaodVpnsData();
            Message.success('VPNS create successfully.');
        }
    });

    // Rtsp Methods
    const handleCreateRtspSubmit = (formData) => {
        if(formData.id && editRtspData){
            setRtspModalState({btnLoading: true, clearData: false});
            return updateRtsp({variables: {...formData, vpn : editRtspData.vpn_id}});
        }
        setRtspModalState({btnLoading: true, clearData: false});
        return createRtsp({variables: {...formData, vpn: actionVpnsId}});
    }

    const [createRtsp] = useMutation(CREATE_RTSP, {
        onError: error => {
            setRtspModalState({btnLoading: false, clearData: false});
            console.log('Error', error);
        },
        onCompleted: data => {
            setRtspModalState({btnLoading: false, clearData: true});
            setOpenCreateRtspModal(false);
            relaodVpnsData();
            Message.success('RTSP create successfully.');
        }
    });

    const [updateRtsp] = useMutation(UPDATE_RTSP, {
        onError: error => {
            setRtspModalState({btnLoading: false, clearData: false});
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('RTSP update successfully.');
            setRtspModalState({ btnLoading: false, clearData: true });
            setOpenCreateRtspModal(false);
            relaodVpnsData();
            setActionVpnsId(null);
            setEditRtspData(null);
        }
    });

    const [deleteRtsp] = useMutation(DELETE_RTSP, {
        onError: error => {
          console.log('Error', error);
        },
        onCompleted: data => {
            relaodVpnsData();
            Message.success('RTSP delete successfully.');
        }
    });

    const viewIdHandleClick = (id) => {
        setViewSelectedId(id);
        setViewIdModalOpen(true);
    }

    const handleSearchChange = (val) => {
        handleSearch("vpns", val);
    }

    return (
        <div>
            {allowConfigure && 
                <Grid container justify={'space-between'}>
                    <Grid item>{<SearchBox handleSearch={handleSearchChange} />}</Grid>
                    <Grid item>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={handleCreateVpnsClick}>
                            {t('createNow.label')}
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list.getVpnsList && list.getVpnsList.vpns.length > 0 ?
                                    list.getVpnsList.vpns.map((rowitem, rowIndex) => (
                                        <VpnsPanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                            allowConfigure={allowConfigure}
                                            viewIdHandleClick={viewIdHandleClick}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color="primary"/>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    { !loading &&
                        <>
                            <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                                <Grid item xs={2}>
                                    <span>Total {Math.ceil(get(list, 'getVpnsList.count', null)/20)} page,  {get(list, 'getVpnsList.count', null)} Row</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Pagination 
                                        color="secondary"
                                        count={list.getVpnsList ? Math.ceil(list.getVpnsList.count/20) : 0} 
                                        page={page} 
                                        onChange={(evt, value) => {
                                            handlePageChange("place", value);
                                        }} 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <span>Go to page </span>
                                </Grid>    
                                <Grid item xs={1}>
                                    <Input
                                        value={goToPage}
                                        type={'text'}
                                        style={{ minWidth: 100 }}
                                        color={'secondary'}
                                        onChange={evt => {
                                            let maxRecord = list && list.getVpnsList ? parseInt(Math.ceil(list.getVpnsList.count/20)) : 0;
                                            if(parseInt(evt.target.value) > maxRecord){
                                                return null;
                                            }
                                            setGoToPage(evt.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        onClick={()=>handlePageChange('place',parseInt(goToPage))}
                                        variant={'outlined'}
                                        color="primary"
                                        disabled={!goToPage}
                                        style={{ marginLeft: 10 }}
                                        >
                                        Go
                                    </Button>      
                                </Grid>
                            </Grid>
                        </>
                    }
                </TableContainer>
            </Grid>
            
            {/* VPNS Create and Update Modal */}
            <CreateVpns open={openCreateVpnsModal} handleCreateVpnsSubmit={handleCreateVpnsSubmit} modalState={modalState} handleClose={evt => {setOpenCreateVpnsModal(false); setEditVpnsData(null);}} vpnsData={editVpnsData} />
            
            {/* RTSP Create and Update Modal */}
            {(actionVpnsId || editRtspData) && 
                <CreateRtspProxy open={openCreateRtspModal} handleCreateRtspSubmit={handleCreateRtspSubmit} modalState={rtspModalState} handleClose={evt => { setOpenCreateRtspModal(false); }} rtspData={editRtspData} />
            }
            {rtspProxies && 
                <ViewRtspProxies open={viewRtspProxiesModal} handleClose={evt => {setViewRtspProxiesModal(false);}} data={rtspProxies} menuItems={rtspMenus} handleMenuItemClick={handleRtspMenuItemClick} columns={rtspColumns} allowConfigure={allowConfigure}/>
            }
        </div>
    );
}
Vpns.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default Vpns;