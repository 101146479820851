import React, { useState, useEffect } from 'react';
import CustomTabs from '../../../../components/CustomTabs';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_BILLING_UTILIZATION_ITEMS, GET_LAST_SIX_MONTH_INVOICE_REPORT, GET_INVOICE_REPORT } from '../../queries';
import { GET_SUBSCRIPTION_USER_DETAILS } from '../../../Profile/queries';
import { CREATE_BILLING_INVOICE, UPDATE_BILLING_INVOICE_STATUS } from '../../mutations';
import { USER_ROLES, CURRENCIES, DEFAULT_ADDRESS } from '../../../../utils/constants';
import Message from '../../../../components/Message';
import BillingUtilization from './BillingUtilization';
import Invoices from './Invoices';
import { Box, Typography } from '@material-ui/core';

const BillingPanel = ({ t, data, subActiveIndex, selectedPath }) => {

    const role = localStorage.getItem('role');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allowConfigure, setAllowConfigure] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [billingData, setBillingData] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [paidBtnLoading, setPaidBtnLoading] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [invoiceChartData, setInvoiceChartData] = useState([]);
    const [invoiceReportList, setInvoiceReportList] = useState([]);
    const [loadingInvoiceData, setLoadingInvoiceData] = useState(false);
    const [invoiceDateFilter, setInvoiceDateFilter] = useState(null);
    const [invoiceCompanyDetails, setInvoiceCompanyDetails] = useState(null);
    const organization = data.organizationId;

    let currencies = CURRENCIES.map(item => {
        return {
            code: item.code,
            id: item.code,
            htmlCode: item.htmlCode,
            symbol: item.symbol_native,
            name: `${item.symbol_native}-${item.code}`
        }
    });

    const [getSubscriptionUserDetails] = useLazyQuery(GET_SUBSCRIPTION_USER_DETAILS, {
        fetchPolicy: "no-cache",
        onCompleted: userData => {
            if (userData.getSubscriptionUserDetails) {
                let dtls = userData.getSubscriptionUserDetails;
                let countryDtl = DEFAULT_ADDRESS;
                if (dtls.organization && dtls.organization.country) {
                    countryDtl = JSON.parse(dtls.organization.country);
                    countryDtl.email = (countryDtl.emails) ? JSON.parse(countryDtl.emails).sales : "support@inq.com";
                }
                setInvoiceCompanyDetails(countryDtl); setUserInfo(userData.getSubscriptionUserDetails);
            }
        }
    });

    const [getBillingUtilizationItems] = useLazyQuery(GET_BILLING_UTILIZATION_ITEMS, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setLoadingData(false);
            if (data.getBillingUtilizationItems && data.getBillingUtilizationItems.data) {
                const result = JSON.parse(data.getBillingUtilizationItems.data);
                if (result.invoice && result.invoice.length > 0) {
                    let invoiceData = result.invoice[0];
                    if (invoiceData.currency) {
                        setCurrency(invoiceData.currency);
                    } else {
                        setCurrency(currencies.length > 0 ? currencies[0].code : null);
                    }
                }
                setBillingData(result);
            }
        },
        onError: error => {
            setLoadingData(false);
            Message.error(error);
        }
    });

    const [getLastSixMonthInvoiceReport] = useLazyQuery(GET_LAST_SIX_MONTH_INVOICE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: invoiceData => {
            if (invoiceData.getLastSixMonthInvoiceReport && invoiceData.getLastSixMonthInvoiceReport) {
                setInvoiceChartData(invoiceData.getLastSixMonthInvoiceReport);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getInvoiceReport] = useLazyQuery(GET_INVOICE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: invoiceData => {
            if (invoiceData.getInvoiceReport && invoiceData.getInvoiceReport) {
                setInvoiceReportList(invoiceData.getInvoiceReport);
            }
            setLoadingInvoiceData(false);
        },
        onError: error => {
            setLoadingInvoiceData(true);
            Message.error(error);
        }
    });
    const [createBillingInvoice] = useMutation(CREATE_BILLING_INVOICE, {
        onError: error => {
            console.log('Error', error);
            Message.error('Something went wrong!');
        },
        onCompleted: data => {
            if (data.addBillingInvoice) {
                Message.success('Invoice create successfully');
                setSaveBtnLoading(false);
                setLoadingData(true);
                let date = startDate;
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                getBillingUtilizationItems({
                    variables: {
                        organization,
                        startDate: firstDay.getTime() / 1000,
                        endDate: lastDay.getTime() / 1000
                    }
                });
            }
        }
    });
    const [updateBillingInvoiceStatus] = useMutation(UPDATE_BILLING_INVOICE_STATUS, {
        onError: error => {
            console.log('Error', error);
            Message.error('Something went wrong!');
        },
        onCompleted: data => {
            if (data.updateBillingInvoice) {
                Message.success('Invoice paid successfully');
                setPaidBtnLoading(false);
                let date = startDate;
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                getBillingUtilizationItems({
                    variables: {
                        organization,
                        startDate: firstDay.getTime() / 1000,
                        endDate: lastDay.getTime() / 1000
                    }
                });
            }
        }
    });
    useEffect(() => {
        // console.log("data: ", data.id);
        setCurrencyList(currencies);
        if (role && (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.IP_COUNTRYADMIN))) {
            setAllowConfigure(true);
        }
        getSubscriptionUserDetails({
            variables: {
                id: data.id
            }
        });
        setLoadingData(true);
        let date = startDate;
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        getBillingUtilizationItems({
            variables: {
                organization,
                startDate: firstDay.getTime() / 1000,
                endDate: lastDay.getTime() / 1000
            }
        });
        setLoadingInvoiceData(true);
        getLastSixMonthInvoiceReport({
            variables: {
                subscriptionId: data.id
            }
        });
        getInvoiceReport({
            variables: {
                subscriptionId: data.id
            }
        });
    }, []);
    const handleChangeDateFilter = (date) => {
        setLoadingData(true);
        var y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        setStartDate(lastDay);
        getBillingUtilizationItems({
            variables: {
                organization,
                startDate: firstDay.getTime() / 1000,
                endDate: lastDay.getTime() / 1000
            }
        });
    }
    const handleChangeCurrency = (val) => {
        setCurrency(val);
    }
    const handleSubmitInvoice = (invoiceData) => {
        if (!invoiceData) {
            return null;
        }
        setSaveBtnLoading(true);
        createBillingInvoice({
            variables: {
                data: JSON.stringify({ ...invoiceData, subscriptionId: data.id, currency })
            }
        });
    }
    const handlePaidInvoice = (data) => {
        setPaidBtnLoading(true);
        return updateBillingInvoiceStatus({ variables: data });
    }
    const handleChangeInvoiceDateFilter = (date) => {
        setInvoiceDateFilter(date);
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let invoiceDate = month + "/" + year;
        setLoadingInvoiceData(true);
        getInvoiceReport({
            variables: {
                subscriptionId: data.id,
                invoiceDate
            }
        });
    }

    let configTabs = [
        {
            label: t('utilization.label'),
            children: (
                <>
                    <BillingUtilization userInfo={userInfo ? userInfo : null} t={t} data={data} allowConfigure={allowConfigure} billingData={billingData} loadingData={loadingData} handleChangeDateFilter={handleChangeDateFilter} startDate={startDate} handleSubmitInvoice={handleSubmitInvoice} saveBtnLoading={saveBtnLoading} handlePaidInvoice={handlePaidInvoice} paidBtnLoading={paidBtnLoading} currency={currency} handleChangeCurrency={handleChangeCurrency} currencyList={currencyList} invoiceCompanyDetails={invoiceCompanyDetails} />
                </>
            )
        },
        {
            label: t('invoices.label'),
            children: (
                <>
                    {userInfo ?
                        <Invoices t={t} userInfo={userInfo ? userInfo : null} startDate={invoiceDateFilter} handleChangeDateFilter={handleChangeInvoiceDateFilter} loadingData={loadingInvoiceData}
                            invoiceChartData={invoiceChartData} invoiceReportList={invoiceReportList} invoiceCompanyDetails={invoiceCompanyDetails} />
                        : null}
                </>
            )
        }
    ];

    return (
        <div>
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            /> */}
            {selectedPath === "Billing" && <> {configTabs[subActiveIndex].children}</>}
        </div>
    );
};
BillingPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};
export default BillingPanel;