import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {marginGenerator} from '../../../../theme/utils';
import PlacesMap from '../../../NewSubscription/components/PlacesMap';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
  });

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const PlacesWithMap = ({handleSetPositionBase, isMapEnable, locationState, handleClose}) => {

    const classes = useStyles();

    return (
        <>
            <Dialog 
              fullWidth={true}
              maxWidth={'md'}
              open={isMapEnable}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} />
                    <DialogContent className={classes.dialogContain}>
                        <Grid
                            item
                            xs={12}
                            container
                            style={marginGenerator('mb-16')}
                            className={classes.mapContainer}>
                            <PlacesMap
                                id={'place-map'}
                                handleSetPosition={handleSetPositionBase}
                                position={
                                    locationState &&
                                    locationState['latitude'] &&
                                    locationState['longitude']
                                    ? {
                                        lat: parseFloat(locationState['latitude']),
                                        lng: parseFloat(locationState['longitude'])
                                        }
                                    : null
                                }
                                
                            />
                            </Grid>
                    </DialogContent>
            </Dialog>   
        </>
    )
}

export default PlacesWithMap
