import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../../../components/OutlinedSelect';
import Message from '../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../utils/constants';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const initFormState = { bidId: "", vendorName: "", name: "" };

const fileTypes = [{
    name: "QUOTE",
    id: "quote"
}, {
    name: "SBD",
    id: "sbd"
},
{
    name: "BSD",
    id: "bsd"
}];

const initFileState = [{file: null, type: "", fileValue: null}, {file: null, type: "", fileValue: null}, {file: null, type: "", fileValue: null}];
function CreateQuoteModal({
    t,
    open,
    handleClose,
    handleCreateQuoteSubmit,
    modalState,
    selectBidList
}) {

    const [quoteFormState, setQuoteFormState] = useState({ ...initFormState });
    const [fileState, setFileState] = useState(initFileState);

    const handleInputChange = (value, field) => {
        setQuoteFormState({ ...quoteFormState, [field]: value });
    }

    useEffect(() => {
        if (modalState.clearData && open) {
            setQuoteFormState({ ...initFormState });
            setFileState(initFileState);
            handleClose();
        }
    }, [modalState]);

    const handleFileStateChange = (evt, field, idx) => {
        setFileState((prevState) => {
            const newState = [...prevState];
            newState[idx][field] = evt.target.value;
            if(field === "file"){
                newState[idx].file = (evt.target.files && evt.target.files.length) ? evt.target.files[0] : null;
            }
            return newState;
        });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Upload New Quote</DialogTitle>
            <DialogContent>
                <Grid style={{...marginGenerator('mt-8')}}>
                    <Grid>
                        <OutlinedSelect
                            options={selectBidList ? selectBidList : []}
                            label={"Select Bid"}
                            val={quoteFormState.bidId}
                            handleChange={evt => handleInputChange(evt.target.value, 'bidId')}
                            styleOverrides={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid style={{ marginTop: 15 }}>
                        <Input
                            value={quoteFormState.name}
                            type={'text'}
                            placeholder={"Quote Name"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => handleInputChange(evt.target.value, 'name')}
                        />
                    </Grid>
                    <Grid style={{ marginTop: 15 }}>
                        <Input
                            value={quoteFormState.vendorName}
                            type={'text'}
                            placeholder={"Vendor Name"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => handleInputChange(evt.target.value, 'vendorName')}
                        />
                    </Grid>
                    {fileState.map((item, idx) => 
                        <Grid spacing={2} style={{marginTop: 15}} container key={idx}>
                            <Grid item xs={8}>
                                <Input
                                    value={item.fileType}
                                    type={'file'}
                                    placeholder={`Upload Doc`}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => handleFileStateChange(evt, 'file', idx)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <OutlinedSelect
                                    options={fileTypes}
                                    label={"Select Type"}
                                    val={item.type}
                                    handleChange={evt => handleFileStateChange(evt, 'type', idx)}
                                    styleOverrides={{ maxWidth: 140 }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        loading={modalState.btnLoading}
                        onClick={() => {
                            if(!quoteFormState.bidId || !quoteFormState.vendorName){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            return handleCreateQuoteSubmit(quoteFormState, fileState);
                        }}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateQuoteModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateQuoteSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateQuoteModal);