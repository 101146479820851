import { gql } from 'apollo-boost';
/*
  Get pmo project list
 */
export const GET_CASH_ADVANCE_REQUEST_LIST = gql`
    query getCashAdvanceRequest($pageNumberOrUid: String) {
        getCashAdvanceRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_LINE_MANAGER_TEAM_LIST = gql`
    query GetLineManagerRequest($pageNumberOrUid: String) {
        getLineManagerRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_EHOD_TEAM_LIST = gql`
    query GetEhodRequest($pageNumberOrUid: String) {
        getEhodRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_MD_TEAM_LIST = gql`
    query getMdRequest($pageNumberOrUid: String) {
        getMdRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_MD_AND_PROCUREMENT_TEAM_LIST = gql`
    query getMdAndProcurementRequest($pageNumberOrUid: String) {
        getMdAndProcurementRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_PETTY_CASHIER_TEAM_LIST = gql`
    query getPettyCashierRequest($pageNumberOrUid: String) {
        getPettyCashierRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_PROCUREMENT_TEAM_LIST = gql`
    query getProcurementRequest($pageNumberOrUid: String) {
        getProcurementRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_TREASURY_MANAGEMENT_TEAM_LIST = gql`
    query getTreasuryManagementRequest($pageNumberOrUid: String) {
        getTreasuryManagementRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_FINAL_PETTY_CASHIER_TEAM_LIST = gql`
    query getFinalPettyCashierRequest($pageNumberOrUid: String) {
        getFinalPettyCashierRequest(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;