import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navbar';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Footer from '../Footer';
import { checkInternalPlatform } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(26),
    maxWidth: '1225px',
    marginBottom: '50px',
    minHeight: '45vh'
  },
  content2: {
    flexGrow: 1,
    marginTop: theme.spacing(24),
    maxWidth: '100%',
    minHeight: '45vh'
  },
}));

function Layout({ children, breadcrumbList, contentStyle, headerNotifications, handleSetImageModalSrc, handleNotificationClick, serviceName }) {
  const classes = useStyles();
  const otherPlatform = checkInternalPlatform();
  return (
    <>
      <Navbar breadcrumbList={breadcrumbList} headerNotifications={headerNotifications} handleSetImageModalSrc={handleSetImageModalSrc ? handleSetImageModalSrc : null} handleNotificationClick={handleNotificationClick} />
      <Container
        className={serviceName === "Edge AI Service" || serviceName === "Intelligent Documents" || serviceName === "DocAI 2.0" ? classes.content2 : classes.content}
        style={contentStyle}
        disableGutters={window.innerWidth > 1024}>
        {children}
      </Container>
      {!otherPlatform && <Footer />}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbList: PropTypes.array.isRequired,
  contentStyle: PropTypes.object
};

export default Layout;
