import {gql} from 'apollo-boost';
/*
  Get Vnf Market place list
 */
export const GET_VNF_MARKET_PLACE = gql`
  query VnfMarketPlaces($categoryId: String) {
    getVnfMarketPlaces(categoryId: $categoryId) {
      _id
      imageName
      imageURL
      imageLogo
      imageType
      description
      imagePrice
      createdAt
    }
  }
`;

/*
  Get vnf marketplace details
 */
export const GET_VNF_MARKET_PLACES_BY_ID = gql`
  query VnfMarketPlacesById($vnfMarketPlaceId: String) {
    getVnfMarketPlacesById(vnfMarketPlaceId: $vnfMarketPlaceId) {
       _id
      imageName
      imageURL
      imageLogo
      imageType
      description
      overview
      review
      support
      highlightsList
      overviewList
      imagePrice
      createdAt
    }
  }
`;
/*
  Get category list
 */
export const GET_CATEGORIES = gql`
  query Categories {
    getCategories {
      _id
      name
      createdAt
    }
  }
`;