import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import Checkbox from '@material-ui/core/Checkbox';
import ProductListFIle from '../../../../../../../assets/productList.json';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateJobOrderTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    handleUploadFiles,
    modalContentLoading,
    handleOpenFeetportForm,
    preValuesOfForm
}) {
    const classes = useStyles();
    const [clientInfo, setClientInfo] = useState('');
    const [product, setProduct] = useState('');
    const [techie, setTechie] = useState('');
    const [notes, setNotes] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [quantity, setQuantity] = useState('');
    const [stockLocation, setStockLocation] = useState('');
    const [submitForApproval, setSubmitForApproval] = useState(false);
    let productList = ProductListFIle;
    const techieList = [
        { id: 'Thandizo Zamadunga (thandizo@africa-online.net', name: 'Thandizo Zamadunga (thandizo@africa-online.net' },
        { id: 'Vanguard Gomani (vanguard@africa-online.net)', name: 'Vanguard Gomani (vanguard@africa-online.net)' },
        { id: 'Thoko Chikhosi (thoko@skyband.mw)', name: 'Thoko Chikhosi (thoko@skyband.mw)' },
        { id: 'Llyod Chithira (Ilyod@skyband.mw)', name: 'Llyod Chithira (Ilyod@skyband.mw)' },
        { id: 'Victor bema (vickbema@gmail.com)', name: 'Victor bema (vickbema@gmail.com)' },
        { id: 'Victor Sambani (sambanijones@gmail.com)', name: 'Victor Sambani (sambanijones@gmail.com)' },
        { id: 'Maxwell Msambila (maxwell@skyband.mw)', name: 'Maxwell Msambila (maxwell@skyband.mw)' },
        { id: 'Boyd Masumba (boyd@inq.inc)', name: 'Boyd Masumba (boyd@inq.inc)' },
        { id: 'Capewell Kumwenda (capewell@skyband.mw)', name: 'Capewell Kumwenda (capewell@skyband.mw)' },
        { id: 'George Kampira (george@skyband.mw)', name: 'George Kampira (george@skyband.mw)' },
        { id: 'Estella Kumalo (estellak@skyband.mw)', name: 'Estella Kumalo (estellak@skyband.mw)' },
        { id: 'Chifundo Dzombe (chifundodzombe@gmail.com)', name: 'Chifundo Dzombe (chifundodzombe@gmail.com)' },
        { id: 'Titos Thombodzi (titos@skyband.mw)', name: 'Titos Thombodzi (titos@skyband.mw)' },
        { id: 'Ernest Kabande (ernest@skyband.mw)', name: 'Ernest Kabande (ernest@skyband.mw)' },
        { id: 'Ronald Mbewe (ronmbewe 10@gmail.com)', name: 'Ronald Mbewe (ronmbewe 10@gmail.com)' },
        { id: 'Carlos Damiano (carlos@skyband mw)', name: 'Carlos Damiano (carlos@skyband mw)' },
        { id: 'Christopher Soko (csoko@skyband.mw)', name: 'Christopher Soko (csoko@skyband.mw)' },
        { id: 'Richard Sambakusi (sambakusi@skyband.mw)', name: 'Richard Sambakusi (sambakusi@skyband.mw)' },
        { id: 'INQ Digital Mzuzu (marina@inq.inc)', name: 'INQ Digital Mzuzu (marina@inq.inc)' },
    ]
    const stockLocationList = [
        { id: 'BTHQ', name: 'BTHQ' },
        { id: 'BTSH', name: 'BTSH' },
        { id: 'BTRMA', name: 'BTRMA' },
        { id: 'LLHQ', name: 'LLHQ' },
        { id: 'LLSH', name: 'LLSH' },
        { id: 'LLRMA', name: 'LLRMA' },
        { id: 'MZHQ', name: 'MZHQ' },
        { id: 'MZSH', name: 'MZSH' },
        { id: 'MZRMA', name: 'MZRMA' },
    ]
    React.useEffect(() => {
        if (modalState.clearData === true) {
            setClientInfo('');
            setProduct('');
            setTechie('');
            setNotes('');
            setSerialNumber('');
            setQuantity('');
            setStockLocation('');
            setSubmitForApproval(false);
        }
    }, [modalState, open, modalContentLoading, preValuesOfForm]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('createJobOrderForm.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('clientInfo.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={clientInfo}
                                type={'text'}
                                placeholder={t('clientInfo.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setClientInfo(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={product}
                                label={`${t('product.label')} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setProduct(evt.target.value)
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                options={productList && productList.length ? productList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={techie}
                                label={`${t('techie.label')} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setTechie(evt.target.value)
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                options={techieList && techieList.length ? techieList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('notes.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={notes}
                                type={'text'}
                                placeholder={t('notes.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNotes(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('serialNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={serialNumber}
                                type={'text'}
                                placeholder={t('serialNumber.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSerialNumber(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('quantity.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={quantity}
                                type={'text'}
                                placeholder={t('quantity.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setQuantity(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={stockLocation}
                                label={`${t('stockLocation.label')} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setStockLocation(evt.target.value)
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                options={stockLocationList && stockLocationList.length ? stockLocationList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Typography variant="subtitle2" color="textPrimary" >
                                <Checkbox
                                color="secondary"
                                value={submitForApproval}
                                onChange={(event) => {
                                    setSubmitForApproval(event.target.checked)} }
                            />{t('submitForApproval.label')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!clientInfo || !product || !techie || !notes || !serialNumber || !quantity || !stockLocation) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let getSubmitForApproval = '';
                            if(submitForApproval === true){
                                getSubmitForApproval = 'Yes'
                            } else {
                                getSubmitForApproval = 'No'
                            }
                            handleSubmitClick({
                                project_name: preValuesOfForm.project,
                                client_info: clientInfo,
                                product: product,
                                techie: techie,
                                notes: notes,
                                serial_number: serialNumber,
                                quantity: quantity,
                                stock_location: stockLocation,
                                submit_for_approval: getSubmitForApproval,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateJobOrderTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateJobOrderTeamModal);





