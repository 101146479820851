import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import Grid from '@material-ui/core/Grid';
import EventsMap from '../../../../components/EdgeAIDashBoardPanel/EventsMap';
import Statcard from '../../../../../../components/Statcard';
import BarChartWrapper from '../../../../../../components/Charts/BarChartWrapper';
import cpu from '../../../../../../assets/icons/cpu.png';
import location_pin from '../../../../../../assets/icons/pin.png';
import {
    GET_IOT_PLACE_LIST,
    GET_SENSOR_LIST,
} from '../../queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { colors } from '../../../../../../theme/colors';

const getLast7Days = (X) => {
    var dates = [];
    for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString());
    }
    return dates;
}
const chartData = getLast7Days(-20).map(itm => {
    const count = parseInt(Math.random() * (30-1) + 1);
    return {date: itm, count}  
});

const DashboardPanel = ({
  t,
  subscriptionData
}) => {
    const [mapPlaceList, setMapPlaceList] = useState([]);

    const [statCardData, setStatCardData] = useState({totalSensors: 0, openSensors: 0, closeSensors: 0, totalPlaces: 0});

    const [getIOTSensors] = useLazyQuery(GET_SENSOR_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            if(res.getAllSensorList && res.getAllSensorList.sensors){
                console.log("res.getAllSensorList: ", res.getAllSensorList);
                const placeData = []; 
                let openCount = 0;
                let closeCount = 0;
                res.getAllSensorList.sensors.map(item => {
                    if(item.lat && item.lng){
                        placeData.push({ position: { lat: item.lat, lng: item.lng }, title: (item.deviceName) ? item.deviceName : item.deviceNo, infoData: null, placeId: item._id, markerColor: (item.maintenanceType === "Maintenance") ? colors.common.red : colors.common.green });
                    }
                    if(item.state && parseInt(item.state)){
                        openCount++;
                    }else{
                        closeCount++;
                    }
                });
                setStatCardData({...statCardData, totalSensors: res.getAllSensorList.count, openSensors: openCount, closeSensors: closeCount});
                console.log("placeData: ", placeData);
                setMapPlaceList(placeData);
            }
        }
    });

    useEffect(() => {
        getIOTSensors({
            variables: {
                search: "",
                page: 1,
                limit: 20000
            }
        });
    }, []);

    const statList = [
        {
            label: "Total Sensors",
            value: statCardData.totalSensors,
            icon: cpu,
            type: "total_sensors"
        },
        {
            label: "Open Sensors",
            value: statCardData.openSensors,
            icon: cpu,
            type: "open_sensors"
        },
        {
            label: "Close Sensors",
            value: statCardData.closeSensors,
            icon: cpu,
            type: "close_sensors"
        },
        {
            label: "Total Places",
            value: statCardData.totalPlaces,
            icon: location_pin,
            type: "total_paces"
        }
    ];

    const closeInfoWindow = () => {
        
    }

    return (
        <>
            <ChartContainer
            customMargin={{marginBottom: 24}} 
            title={subscriptionData.service ? subscriptionData.service.name + " Map" : "Map"} >
                <Grid
                container
                style={{marginTop: 20}}>
                    <EventsMap 
                        id={'events-map'}
                        handleMarkerClick={evt => {
                            console.log("handleMarkerClick: ");
                        }}
                        closeInfoWindow={closeInfoWindow}
                        infoWindowData={null}
                        infoWindowLoading={false} 
                        markers={mapPlaceList}
                    />
                </Grid>
            </ChartContainer>
            <Grid
            container
            justify={'space-between'}
            style={{marginTop: 20}}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                    />
                ))}
            </Grid>
            <ChartContainer
            customMargin={{marginBottom: 32, marginTop: 24}}
            title={subscriptionData.service ? subscriptionData.service.name + " Chart" : "Chart"} >
                <BarChartWrapper
                    data={chartData}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                />
            </ChartContainer>
        </>
    );
};

DashboardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default DashboardPanel;