import {gql} from 'apollo-boost';

export const CREATE_SHAYONA_TRUCK = gql`
mutation AddTruckData($organization: String, $truckDetails: JSON) {
    addTruckData(organization: $organization, truckDetails: $truckDetails) {
        success
        message
        data
    }
}
`;

export const EDIT_SHAYONA_TRUCK = gql`
mutation EditTruckData($organization: String, $truckDetails: JSON) {
    editTruckData(organization: $organization, truckDetails: $truckDetails) {
        success
        message
        data
    }
}
`;

export const DELETE_SHAYONA_TRUCK = gql`
mutation DeleteTruck($organization: String, $categoryId: Int) {
    deleteTruck(organization: $organization, categoryId: $categoryId) {
        success
        message
    }
}
`;

export const DELETE_SHAYONA_TRIP = gql`
mutation deleteTrip($organization: String, $tripId: Int, $is_active: Boolean) {
    deleteTrip(organization: $organization, tripId: $tripId, is_active:$is_active) {
        success
        message
    }
}
`;

export const EDIT_SHAYONA_TRUCK_OF_TRIP = gql`
mutation editTruckOftripData($organization: String, $tripId: Int, $truckNumber: String) {
    editTruckOftripData(organization: $organization, tripId: $tripId, truckNumber: $truckNumber) {
        success
        message
    }
}
`;

export const PRINT_SHAYONA_TRIP_PACKAGE_SLIP = gql`
mutation printTripPackageSlip($organization: String, $tripId: Int, $bagsAllocated: Int) {
    printTripPackageSlip(organization: $organization, tripId: $tripId, bagsAllocated: $bagsAllocated) {
        success
        message
    }
}
`;

export const CAPTURE_WEIGHT_OF_SHAYONA_TRIP_TRUCK = gql`
mutation CaptureWeightOfTripTruck($organization: String, $tripId: Int, $cameraId: String) {
    captureWeightOfTripTruck(organization: $organization, tripId: $tripId, cameraId: $cameraId) {
        success
        message
    }
}
`;

export const LOADING_BAGS_OF_SHAYONA_TRIP_TRUCK = gql`
mutation LoadingBagsOfTripTruck($organization: String, $tripId: Int, $emptyWeight: String) {
    loadingBagsOfTripTruck(organization: $organization, tripId: $tripId, emptyWeight: $emptyWeight) {
        success
        message
    }
}
`;

export const PRINT_EXIT_OF_SHAYONA_TRIP_TRUCK = gql`
mutation printExitSlipTripTruck($organization: String, $tripId: Int,  $grossWeight: String, $brands: [JSON]) {
    printExitSlipTripTruck(organization: $organization, tripId: $tripId, grossWeight: $grossWeight, brands:$brands) {
        success
        message
        gross_weight
    }
}
`;

export const SET_ALERT_AS_WRONG_ONE = gql`
mutation setAlertasWrongOne($organization: String, $alertId: Int, $comments: String) {
    setAlertasWrongOne(organization: $organization, alertId: $alertId, comments: $comments) {
        success
        message
    }
}
`;

export const CAPTURE_EMPTY_WEIGHT_OF_SHAYONA_TRIP_TRUCK = gql`
mutation CaptureEmptyWeightTripTruck($organization: String, $tripId: Int) {
    captureEmptyWeightTripTruck(organization: $organization, tripId: $tripId) {
        success
        message
        empty_weight
    }
}
`;

export const CAPTURE_GROSS_WEIGHT_OF_SHAYONA_TRIP_TRUCK = gql`
mutation CaptureGrossWeightTripTruck($organization: String, $tripId: Int) {
    captureGrossWeightTripTruck(organization: $organization, tripId: $tripId) {
        success
        message
        gross_weight
    }
}
`;

export const APPROVEING_ALERT_OF_ALERTS = gql`
mutation ApproveAlertOfAlerts($organization: String, $alertId: Int, $comments: String, $alert_info: JSON, $url: String, $template_id: [Int]) {
    approveAlertOfAlerts(organization: $organization, alertId: $alertId, comments: $comments, alert_info: $alert_info, url: $url, template_id: $template_id) {
        success
        message
    }
}
`;

export const CREATE_BRAND = gql`
mutation CreateBrand($name: String) {
    createBrand(name: $name) {
        success
        message
    }
}
`;

export const UPDATE_ALERT = gql`
mutation UpdateAlert($comment: String, $is_approved: Boolean, $alertId: Int) {
    updateAlert(comment: $comment, is_approved: $is_approved, alertId: $alertId) {
        success
        message
    }
}
`;

export const STOP_COUNTING_TRIP = gql`
mutation StopCountingTripTruck($tripId: Int) {
    stopCountingTripTruck(tripId: $tripId) {
        success
        message
    }
}
`;

export const RESUME_COUNTING_TRIP = gql`
mutation ResumeCountingTripTruck($organization: String, $tripId: Int) {
    resumeCountingTripTruck(organization: $organization, tripId: $tripId) {
        success
        message
    }
}
`;
