import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import MonitoringItem from './MonitoringItem';
import {marginGenerator} from "../../../../theme/utils";
import Header from "../../../../components/Header";
import {USER_ROLES} from "../../../../utils/constants";

function Monitoring({
                        t,
                        elasticUserList,
                        columns,
                        loading,
                        role,
                        userRole,
                        error,
                        handleClick,
                        handleCreateButtonClick,
                    }) {
    return (
        <div>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), ...marginGenerator('mt-20')}}>
                <Header
                    title={t('monitoring_management.label')}
                    subText={`${t('monitoring_description.label')}`}
                    primaryButtonText={(role.includes(userRole.IP_COUNTRYADMIN) || role.includes(userRole.IP_SUPERADMIN)) ? t('createNew.label') : ''}
                    handlePrimaryButtonClick={(role.includes(userRole.IP_COUNTRYADMIN) || role.includes(userRole.IP_SUPERADMIN)) ? handleCreateButtonClick : null}
                />
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                elasticUserList.getMonitoringList && elasticUserList.getMonitoringList.length > 0 ?
                                    elasticUserList.getMonitoringList.map((rowitem, index) => {
                                            let rowIndex = index + 1;
                                            return <MonitoringItem
                                                rowIndex={rowIndex}
                                                t={t}
                                                data={rowitem}
                                                handleClick={handleClick}
                                            />;
                                        }
                                    ) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color='primary'/>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
Monitoring.propTypes = {
    t: PropTypes.func.isRequired
};
export default Monitoring;
