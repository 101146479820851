import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Message from '../../../../components/Message';
import {
  REQUIRED_ERROR,
  PROVIDERS,
  NODE_MANAGEMENT_TYPE
} from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../theme/colors';
import '../CreateMLTemplateModal/index.css';
import CSVReader from 'react-csv-reader';
import sampleCsvfile from '../../../../assets/camerasFileSample.csv';
import { encodeCameraRTSPURL } from '../../../../utils';

const camFpsOptions = ['0.1', '0.2', '0.4', '0.5', '0.8'];
const camRSOptions = ['640*480', '1024*780', '1920*1280'];

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
};
function CreateCameraTemplateModal({
  t,
  open,
  handleCreateCameraSubmitClick,
  data,
  selectePlaceList,
  selectMlTemplateList,
  selectNotiTemplateList,
  handleClose,
  modalState,
  subscriptionData,
  selecteNodeList,
  VPNSList,
  cameraFpsOptions,
  cameraRSOptions
}) {
  const cameraInitFields = {
    name: '',
    rtsp_url: '',
    fps: '',
    placeId: '',
    width: '',
    height: '',
    cameraRSL: '',
    vpnsId: null,
    rtspProxy: null,
    remote_stream: 'No',
    place: null
  };
  const aiModelInitFields = { ai_template_id: '', from_time: '', to_time: '' };
  const notificationInitFields = {
    notification_template_id: '',
    ai_template_id: []
  };

  const [cameraState, setCameraState] = useState([{ ...cameraInitFields }]);
  const [aiModelState, setAIModelState] = useState([{ ...aiModelInitFields }]);
  const [notificationState, setNotificationState] = useState([
    { ...notificationInitFields }
  ]);
  console.log(notificationState);
  const [removeSelectedCameraFields, setSelectedRemoveCameraFields] = useState(
    []
  );
  const [removeSelectedAIFields, setSelectedRemoveAIFields] = useState([]);
  const [
    removeSelectedNotificationFields,
    setSelectedRemoveNotificationFields
  ] = useState([]);
  const [removeFieldState, setRemoveFieldState] = useState({
    camera: [],
    ai_template: [],
    notification: []
  });
  const [selectedMlTemplates, setSelectedMlTemplates] = useState([]);
  console.log(selectedMlTemplates);
  const [provider, setProvider] = useState('edgeai');
  const [node, setNode] = useState('');
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeList, setNodeList] = useState([]);
  const [mlTemplateList, setMLTemplateList] = useState([]);
  const [selecteVpnsList, setSelectVpnsList] = useState([]);
  const classes = useStyles();

  const handleSelectedTemplates = (event, newValue) => {
    console.log("newValue----->>>>", newValue);
    if (newValue.length > 0) {
      setNotificationState([
        {
          ...notificationState[0],
          ai_template_id: newValue.map(option => option.id)
        }
      ]);
    } else {
      setNotificationState([
        {
          ...notificationState[0],
          ai_template_id: []
        }
      ]);
    }
  };

  React.useEffect(() => {
    if (modalState.clearData && open) {
      setCameraState([{ ...cameraInitFields }]);
      setAIModelState([{ ...aiModelInitFields }]);
      setNotificationState([{ ...notificationInitFields }]);
      handleClose();
    }
  }, [modalState]);

  React.useEffect(() => {
    if (data && data.camera_id) {
      let camData = {
        name: data.name,
        rtsp_url: data.url,
        fps: data.fps,
        placeId: data.place.place_id,
        width: data.content_width,
        height: data.content_height,
        cameraRSL: `${data.content_width}*${data.content_height}`
      };
      setCameraState([{ ...camData }]);
      setAIModelState([{ ...aiModelInitFields }]);
      setNotificationState([{ ...notificationInitFields }]);
    }
    if (VPNSList.length > 0) {
      let vpnsList = [];
      for (let vpn of VPNSList) {
        vpnsList.push({ name: vpn.vpn_ip, proxies: vpn.proxies });
      }
      setSelectVpnsList(vpnsList);
    }
  }, [data, VPNSList]);

  const onHandleSelectCameraRow = (evnt, index) => {
    let val = evnt.target.value.toString();
    let updatedState = { ...removeSelectedCameraFields };
    updatedState[index] = evnt.target.checked;
    setSelectedRemoveCameraFields(updatedState);
    if (evnt.target.checked) {
      setRemoveFieldState(prevState => ({
        ...prevState,
        camera: [...prevState.camera, val]
      }));
    } else {
      let prevRemoveState = { ...removeFieldState };
      let elemIdx = prevRemoveState.camera.indexOf(val);
      prevRemoveState.camera.splice(elemIdx, 1);
      setRemoveFieldState(prevRemoveState);
    }
  };

  const onHandleSelectAIRow = (evnt, index) => {
    let val = evnt.target.value.toString();
    let updatedState = { ...removeSelectedAIFields };
    updatedState[index] = evnt.target.checked;
    setSelectedRemoveAIFields(updatedState);
    if (evnt.target.checked) {
      setRemoveFieldState(prevState => ({
        ...prevState,
        ai_template: [...prevState.ai_template, val]
      }));
    } else {
      let prevRemoveState = { ...removeFieldState };
      let elemIdx = prevRemoveState.ai_template.indexOf(val);
      prevRemoveState.ai_template.splice(elemIdx, 1);
      setRemoveFieldState(prevRemoveState);
    }
  };

  const onHandleSelectNotifRow = (evnt, index) => {
    let val = evnt.target.value.toString();
    let updatedState = { ...removeSelectedNotificationFields };
    updatedState[index] = evnt.target.checked;
    setSelectedRemoveNotificationFields(updatedState);
    if (evnt.target.checked) {
      setRemoveFieldState(prevState => ({
        ...prevState,
        notification: [...prevState.notification, val]
      }));
    } else {
      let prevRemoveState = { ...removeFieldState };
      let elemIdx = prevRemoveState.notification.indexOf(val);
      prevRemoveState.notification.splice(elemIdx, 1);
      setRemoveFieldState(prevRemoveState);
    }
  };

  const addNewCameraFileds = () => {
    if (subscriptionData.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED) {
      if (!provider || !node) {
        Message.error('Please select provider and node first.');
        return null;
      }
      let noOfCam = 0;
      if (selectedNode && selectedNode.noOfCam) {
        noOfCam = selectedNode.noOfCam;
      }
      noOfCam = noOfCam + parseInt(cameraState.length);
      console.log('noOfCam: ', noOfCam);
      if (noOfCam >= 100) {
        Message.error('Camera limit is exide for this node!');
        return null;
      }
    }
    setCameraState(oldArray => [...oldArray, { ...cameraInitFields }]);
  };

  const addNewAIModelFileds = () => {
    if (selectMlTemplateList.length <= aiModelState.length) {
      Message.error(
        `You can add only ${selectMlTemplateList.length} ai templates!`
      );
      return null;
    }
    setAIModelState(prevAiState => [...prevAiState, aiModelInitFields]);
  };

  const addNewNotificationFileds = () => {
    setNotificationState(oldArray => [
      ...oldArray,
      { ...notificationInitFields }
    ]);
  };

  const handleCameraChange = (value, idx, column) => {
    const updatedCamera = [...cameraState];
    updatedCamera[idx][column] = value;
    setCameraState(updatedCamera);
  };

  const handleChangeCameraResolution = (value, idx) => {
    if (value) {
      let camRsArr = value.split('*');
      if (camRsArr.length) {
        handleCameraChange(camRsArr[0], idx, 'width');
        handleCameraChange(camRsArr[1], idx, 'height');
      }
    } else {
      handleCameraChange('', idx, 'width');
      handleCameraChange('', idx, 'height');
    }
    handleCameraChange(value, idx, 'cameraRSL');
  };

  const handleAIModelChange = (value, idx, column) => {
    const updatedAIModal = [...aiModelState];
    if (updatedAIModal.length && column === 'ai_template_id' && value) {
      let oldAITypes = [];
      updatedAIModal.map(aiItm => {
        if (aiItm.ai_template_id) {
          let mlTmp = mlTemplateList.filter(
            itm => itm.id === aiItm.ai_template_id
          );
          oldAITypes.push(mlTmp[0].model_type);
        }
      });
      let newSelectAI = mlTemplateList.filter(itm => itm.id === value);
      if (newSelectAI && newSelectAI.length > 0) {
        let mType = newSelectAI[0].model_type;
        if (oldAITypes.includes(mType)) {
          Message.error(
            'AI type already added! Please select another AI Model.'
          );
          return null;
        }
      }
    }
    updatedAIModal[idx][column] = value;
    setAIModelState(updatedAIModal);
    if (column === 'ai_template_id') {
      let selectedIds = updatedAIModal.map(value => {
        if (value.ai_template_id) {
          return value.ai_template_id;
        }
      });
      selectedIds = selectedIds.filter(function (item, pos) {
        return selectedIds.indexOf(item) == pos;
      });
      const filteredMLTemp = selectMlTemplateList.filter(template => {
        if (selectedIds.indexOf(template.id) !== -1) {
          return template;
        }
      });
      setSelectedMlTemplates(filteredMLTemp);
    }
    console.log('value: ', value);
  };

  const handleNotificationChange = (value, idx, column) => {
    const updatedNoti = [...notificationState];
    if (updatedNoti.length && column === 'notification_template_id' && value) {
      let checkNotiModels = updatedNoti.some(function (el) {
        return el.notification_template_id === value;
      });
      if (checkNotiModels) {
        Message.error('Notification template already added!');
        return null;
      }
    }
    updatedNoti[idx][column] = value;
    setNotificationState(updatedNoti);
  };

  const removeCameraField = () => {
    const updatedCamera = [...cameraState];
    const updateRemoveState = { ...removeFieldState };
    for (let index of updateRemoveState.camera) {
      index = parseInt(index);
      updatedCamera.splice(index, 1);
    }
    setCameraState(updatedCamera);
    const newRmState = { ...removeFieldState };
    newRmState.camera = [];
    setRemoveFieldState(newRmState);
  };

  const removeAIModelFileds = () => {
    const updatedAIModal = [...aiModelState];
    const updateRemoveState = { ...removeFieldState };
    for (let index of updateRemoveState.ai_template) {
      index = parseInt(index);
      updatedAIModal.splice(index, 1);
    }
    setAIModelState(updatedAIModal);
    const newRmState = { ...removeFieldState };
    newRmState.ai_template = [];
    setRemoveFieldState(newRmState);
  };

  const removeNotificationFields = () => {
    const updatedNoti = [...notificationState];
    const updateRemoveState = { ...removeFieldState };
    for (let index of updateRemoveState.notification) {
      index = parseInt(index);
      updatedNoti.splice(index, 1);
    }
    setNotificationState(updatedNoti);
    const newRmState = { ...removeFieldState };
    newRmState.notification = [];
    setRemoveFieldState(newRmState);
  };

  const handleProviderChange = value => {
    setProvider(value);
    if (selecteNodeList.length) {
      let nodes = selecteNodeList.filter(item => {
        if (item.provider === value) {
          if (item.noOfCam) {
            if (parseInt(item.noOfCam) <= 100) {
              return item;
            }
          } else {
            return item;
          }
        }
      });
      setNodeList(nodes);
    }
    let mlTemplates = [];
    if (value) {
      mlTemplates = selectMlTemplateList.filter(item => {
        if (item.provider === value) {
          return item;
        }
      });
    }
    setMLTemplateList(mlTemplates);
  };

  const handleChangeNode = val => {
    setNode(val);
    let nodes = selecteNodeList.filter(item => {
      if (item.id === val) {
        return item;
      }
    });
    if (nodes.length) {
      setSelectedNode(nodes[0]);
    }
  };

  React.useEffect(() => {
    if (selectMlTemplateList.length) {
      handleProviderChange(provider);
    }
  }, [selectMlTemplateList]);

  const handleUploadLPRCsv = (csvData, fileInfo) => {
    if (csvData && csvData.length) {
      let camCsvData = [];
      let defaultPlaceId = null;
      if (
        subscriptionData &&
        subscriptionData.sites &&
        subscriptionData.sites.length > 0
      ) {
        const firstPlace = selectePlaceList.filter(
          itm => itm.name === subscriptionData.sites[0].name
        );
        if (firstPlace.length) {
          defaultPlaceId = firstPlace[0];
        }
      }
      csvData.map(plate => {
        if (plate.camera_name && plate.rtsp_url) {
          let tmpObj = {};
          tmpObj.name = plate.camera_name;
          tmpObj.rtsp_url = encodeCameraRTSPURL(plate.rtsp_url);
          tmpObj.fps = plate.camera_fps ? plate.camera_fps : '0.1';
          if (
            plate.camera_resolution &&
            plate.camera_resolution.includes('*')
          ) {
            tmpObj.cameraRSL = plate.camera_resolution;
            let camRsArr = plate.camera_resolution.split('*');
            if (camRsArr.length) {
              tmpObj.width = camRsArr[0];
              tmpObj.height = camRsArr[1];
            }
          } else {
            tmpObj.cameraRSL = '640*480';
            tmpObj.width = 640;
            tmpObj.height = 480;
          }
          if (defaultPlaceId) {
            tmpObj.place = defaultPlaceId;
          }
          tmpObj.remote_stream =
            plate.remote_stream &&
              (plate.remote_stream === 'Yes' || plate.remote_stream === 'yes')
              ? 'Yes'
              : 'No';
          camCsvData.push(tmpObj);
        }
      });
      setCameraState(camCsvData);
      if (selectMlTemplateList.length) {
        const statusMLModel = selectMlTemplateList.filter(
          itm => itm.model_type === 'camera_status'
        );
        if (statusMLModel.length) {
          setAIModelState([
            {
              ai_template_id: statusMLModel[0].id,
              from_time: '23:59',
              to_time: '00:00'
            }
          ]);
        }
      }
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="camera-template-modal"
      aria-describedby="camera-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle id="camera-template-title">
        {t('createNew.label')} {t('camera.label')} {t('configuration.label')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={() => {
                  addNewCameraFileds();
                }}>
                {t('add.label')} {t('camera.label')}
              </Button>
              <Button
                color="secondary"
                variant={'contained'}
                style={{ ...marginGenerator('ml-20') }}
                disabled={!removeFieldState.camera.length}
                onClick={() => {
                  removeCameraField();
                  setSelectedRemoveCameraFields([]);
                }}>
                {t('remove.label')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <OutlinedSelect
                val={provider}
                label={`${t('selectProviderLbl.label')} *`}
                handleChange={evt => {
                  handleProviderChange(evt.target.value);
                }}
                selectStyle={{ ...paddingGenerator('p-8') }}
                styleOverrides={{ width: '100%', marginRight: 0 }}
                options={PROVIDERS}
              />
            </Grid>
            {subscriptionData.nodeManagmentMode ===
              NODE_MANAGEMENT_TYPE.UNMANAGED && (
                <Grid item xs={3}>
                  <OutlinedSelect
                    val={node}
                    label={`${t('node.label')} *`}
                    handleChange={evt => {
                      handleChangeNode(evt.target.value);
                    }}
                    selectStyle={{ ...paddingGenerator('p-8') }}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={nodeList}
                  />
                </Grid>
              )}
            <div className="mlFilecontainer">
              <Typography variant="body1" color="primary">
                {t('cameraCsvUpload.label')}
              </Typography>
              <CSVReader
                cssClass="ml-csv-input"
                accept=".csv"
                cssInputClass="ml-csv-input"
                onFileLoaded={handleUploadLPRCsv}
                parserOptions={papaparseOptions}
              />
              <Button color="primary" href={sampleCsvfile} download>
                Download sample file
              </Button>
            </div>
            {cameraState.map((val, idx) => {
              return (
                <React.Fragment key={`cam-${idx}`}>
                  <Grid
                    key={`heading-${idx}`}
                    item
                    xs={12}
                    style={{ ...paddingGenerator('pl-0') }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      <Checkbox
                        style={paddingGenerator('pl-0')}
                        checked={removeSelectedCameraFields[idx]}
                        value={idx}
                        onChange={event => {
                          onHandleSelectCameraRow(event, idx);
                        }}
                      />
                      {t('camera.label')} {idx + 1}
                    </Typography>
                  </Grid>
                  <Grid key={`name-${idx}`} item xs={4}>
                    <Input
                      type={'text'}
                      placeholder={`${t('cameraName.label')}*`}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={cameraState[idx].name}
                      onChange={evt =>
                        handleCameraChange(evt.target.value, idx, 'name')
                      }
                    />
                  </Grid>
                  <Grid key={`width-${idx}`} item xs={4}>
                    <OutlinedSelect
                      val={
                        cameraState[idx].vpnsId ? cameraState[idx].vpnsId : null
                      }
                      label={`${t('vpns.label')}`}
                      handleChange={evt => {
                        handleCameraChange(evt.target.value, idx, 'vpnsId');
                        handleCameraChange('', idx, 'rtspProxy');
                        handleCameraChange('', idx, 'rtsp_url');
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={
                        selecteVpnsList && selecteVpnsList.length > 0
                          ? selecteVpnsList
                          : []
                      }
                    />
                  </Grid>
                  <Grid key={`width-${idx}`} item xs={4}>
                    <OutlinedSelect
                      val={cameraState[idx].rtspProxy}
                      label={`${t('rtspProxy.label')}`}
                      handleChange={evt => {
                        handleCameraChange(evt.target.value, idx, 'rtspProxy');
                        handleCameraChange(
                          encodeCameraRTSPURL(evt.target.value),
                          idx,
                          'rtsp_url'
                        );
                      }}
                      disabled={!cameraState[idx].vpnsId}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={
                        cameraState[idx].vpnsId &&
                          cameraState[idx].vpnsId.proxies.length > 0
                          ? cameraState[idx].vpnsId.proxies.map(itm => ({
                            name: itm.camera_name,
                            id: itm.camera_url
                          }))
                          : []
                      }
                    />
                  </Grid>

                  <Grid key={`camerartsp-${idx}`} item xs={4}>
                    <Input
                      value={cameraState[idx].rtsp_url}
                      type={'text'}
                      placeholder={t('camerartsp.label')}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      onChange={evt => {
                        handleCameraChange(
                          encodeCameraRTSPURL(evt.target.value),
                          idx,
                          'rtsp_url'
                        );
                      }}
                    />
                  </Grid>
                  <Grid key={`camerafps-${idx}`} item xs={4}>
                    <OutlinedSelect
                      val={cameraState[idx].fps}
                      label={`${t('camerafps.label')} *`}
                      handleChange={evt => {
                        handleCameraChange(evt.target.value, idx, 'fps');
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={
                        cameraFpsOptions && cameraFpsOptions.length
                          ? cameraFpsOptions
                          : camFpsOptions
                      }
                    />
                  </Grid>
                  <Grid key={`place-${idx}`} item xs={4}>
                    <Autocomplete
                      id={`place-select-${idx}`}
                      options={selectePlaceList}
                      getOptionLabel={option => option.name}
                      onChange={(event, newValue) => {
                        console.log('newValue: ', newValue);
                        handleCameraChange(newValue, idx, 'place');
                      }}
                      value={cameraState[idx].place}
                      style={{ border: '1px solid #ebebeb' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={`${t('place.label')} *`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid key={`width-${idx}`} item xs={4}>
                    <OutlinedSelect
                      val={cameraState[idx].cameraRSL}
                      label={`${t('camRsl.label')} *`}
                      handleChange={evt => {
                        handleChangeCameraResolution(evt.target.value, idx);
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={
                        cameraRSOptions && cameraRSOptions.length
                          ? cameraRSOptions
                          : camRSOptions
                      }
                    />
                  </Grid>

                  <Grid key={`height-${idx}`} item xs={8}>
                    <Grid item xs={3} style={{ display: 'inline-block' }}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {t('remoteStream.label')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{ display: 'inline-block', marginLeft: 10 }}>
                      {['Yes', 'No'].map((field, index) => {
                        return (
                          <Radio
                            key={index}
                            checked={field === cameraState[idx].remote_stream}
                            classes={{ root: classes.iconButton }}
                            style={{
                              ...marginGenerator('mr-16'),
                              ...marginGenerator('mt-0'),
                              ...(field === cameraState[idx].remote_stream
                                ? { backgroundColor: colors.secondary.main }
                                : '')
                            }}
                            onChange={evt =>
                              handleCameraChange(field, idx, 'remote_stream')
                            }
                            value={field}
                            name="remote_stream"
                            checkedIcon={
                              <div>
                                <Typography
                                  variant={'subtitle2'}
                                  style={{ color: colors.common.white }}>
                                  {field}
                                </Typography>
                              </div>
                            }
                            icon={
                              <div className={classes.icon}>
                                <Typography
                                  variant={'subtitle1'}
                                  color={'textPrimary'}>
                                  {field}
                                </Typography>
                              </div>
                            }
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item xs={12} style={{ ...marginGenerator('mt-24') }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                disabled={
                  selectMlTemplateList && selectMlTemplateList.length > 0
                    ? false
                    : true
                }
                onClick={() => {
                  addNewAIModelFileds();
                }}>
                {t('add.label')} {t('aimodel.label')}
              </Button>
              <Button
                style={{ ...marginGenerator('ml-20') }}
                color="secondary"
                disabled={!removeFieldState.ai_template.length}
                variant={'contained'}
                onClick={() => {
                  removeAIModelFileds();
                  setSelectedRemoveAIFields([]);
                }}>
                {t('remove.label')}
              </Button>
            </Grid>
            {aiModelState.map((val, idx) => {
              return (
                <React.Fragment key={`ai-${idx}`}>
                  <Grid
                    key={`aiheading-${idx}`}
                    item
                    xs={12}
                    style={{ ...paddingGenerator('pl-0') }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      <Checkbox
                        style={paddingGenerator('pl-0')}
                        checked={removeSelectedAIFields[idx]}
                        value={idx}
                        onChange={event => {
                          onHandleSelectAIRow(event, idx);
                        }}
                      />
                      {t('aimodel.label')} {idx + 1}
                    </Typography>
                  </Grid>
                  <Grid key={`aiTemplate-${idx}`} item xs={4}>
                    <OutlinedSelect
                      val={aiModelState[idx].ai_template_id}
                      label={`${t('aiTemplate.label')} *`}
                      handleChange={evt => {
                        handleAIModelChange(
                          evt.target.value,
                          idx,
                          'ai_template_id'
                        );
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={mlTemplateList}
                    />
                  </Grid>
                  <Grid key={`fromtime-${idx}`} item xs={4}>
                    <Input
                      type={'time'}
                      placeholder={`${t('fromTime.label')}*`}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={aiModelState[idx].from_time}
                      onChange={evt =>
                        handleAIModelChange(evt.target.value, idx, 'from_time')
                      }
                    />
                  </Grid>
                  <Grid key={`totime-${idx}`} item xs={4}>
                    <Input
                      type={'time'}
                      placeholder={`${t('toTime.label')}*`}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={aiModelState[idx].to_time}
                      onChange={evt =>
                        handleAIModelChange(evt.target.value, idx, 'to_time')
                      }
                    />
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item xs={12} style={{ ...marginGenerator('mt-10') }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                disabled={
                  selectNotiTemplateList && selectNotiTemplateList.length > 0
                    ? false
                    : true
                }
                onClick={() => {
                  addNewNotificationFileds();
                }}>
                {t('add.label')} {t('notification.label')}
              </Button>
              <Button
                color="secondary"
                variant={'contained'}
                disabled={!removeFieldState.notification.length}
                style={{ ...marginGenerator('ml-20') }}
                onClick={() => {
                  removeNotificationFields();
                  setSelectedRemoveNotificationFields([]);
                }}>
                {t('remove.label')}
              </Button>
            </Grid>
            {notificationState.map((val, idx) => {
              return (
                <React.Fragment key={`noti-${idx}`}>
                  <Grid
                    key={`notiHeading-${idx}`}
                    item
                    xs={12}
                    style={{
                      ...paddingGenerator('pl-5'),
                      ...paddingGenerator('pl-0')
                    }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      <label htmlFor={`remove-check-${idx}`}>
                        <Checkbox
                          style={paddingGenerator('pl-0')}
                          checked={removeSelectedNotificationFields[idx]}
                          id={`remove-check-${idx}`}
                          value={idx}
                          onChange={event => {
                            onHandleSelectNotifRow(event, idx);
                          }}
                        />
                        {t('notification.label')} {idx + 1}
                      </label>
                    </Typography>
                  </Grid>
                  <Grid key={`notification-${idx}`} item xs={6}>
                    <OutlinedSelect
                      val={notificationState[idx].notification_template_id}
                      label={`${t('notification.label')} ${t(
                        'template.label'
                      )}*`}
                      handleChange={evt => {
                        handleNotificationChange(
                          evt.target.value,
                          idx,
                          'notification_template_id'
                        );
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={selectNotiTemplateList}
                    />
                  </Grid>
                  <Grid key={`aitemplateid-${idx}`} item xs={6}>
                    {/* Commented out till the code review */}
                    {/* <OutlinedSelect
                      val={notificationState[idx].ai_template_id}
                      label={`${t('aiTemplate.label')} *`}
                      handleChange={evt => {
                        handleNotificationChange(
                          evt.target.value,
                          idx,
                          'ai_template_id'
                        );
                      }}
                      selectStyle={{ ...paddingGenerator('p-8') }}
                      styleOverrides={{ width: '100%', marginRight: 0 }}
                      options={selectedMlTemplates}
                    /> */}
                    <Autocomplete
                      multiple
                      id="notification_template_ids"
                      options={selectedMlTemplates}
                      getOptionLabel={option => option.name}
                      value={selectedMlTemplates.filter(option =>
                        notificationState[0].ai_template_id.includes(option.id)
                      )}
                      onChange={handleSelectedTemplates}
                      style={{ width: '100%', border: '1px solid #ebebeb' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={`${t('aiTemplate.label')} *`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              setCameraState([{ ...cameraInitFields }]);
              setAIModelState([{ ...aiModelInitFields }]);
              setNotificationState([{ ...notificationInitFields }]);
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              if (
                subscriptionData.nodeManagmentMode ===
                NODE_MANAGEMENT_TYPE.UNMANAGED
              ) {
                if (!node) {
                  Message.error(REQUIRED_ERROR);
                  return null;
                }
              }
              if ((!provider && !cameraState.length) || !aiModelState.length) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              let checkValidate = false;
              if (cameraState.length > 0) {
                for (let cam of cameraState) {
                  if (!cam.place || !cam.name) {
                    checkValidate = true;
                  }
                }
              }
              if (aiModelState.length > 0) {
                for (let aiMdl of aiModelState) {
                  if (!aiMdl.ai_template_id) {
                    checkValidate = true;
                  }
                }
              }
              if (checkValidate) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              handleCreateCameraSubmitClick({
                cameras: cameraState,
                aiModels: aiModelState,
                notifications: notificationState,
                provider,
                node
              });
            }}
            loading={modalState.btnLoading}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

CreateCameraTemplateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreateCameraSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCameraTemplateModal);
