import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DatePicker from 'react-datepicker';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateProjectCharterFormModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    handleUploadFiles,
    modalContentLoading,
    handleOpenFeetportForm,
    selectedProjectTypeAndUid
}) {
    const classes = useStyles();
    const [numberOfSites, setNumberOfSites] = useState('');
    const [tvc, setTvc] = useState('');
    const [sdManager, setSdManager] = useState('');
    const [projectScope, setProjectScope] = useState('');
    const [deliverables, setDeliverables] = useState('');
    const [projectStartDate, setProjectStartDate] = useState('');
    const [estimateDeliveryDate, setEstimateDeliveryDate] = useState('');
    const [customerExpectedDeliveryDate, setCustomerExpectedDeliveryDate] = useState('');
    const [estimatedBillingDate, setEstimatedBillingDate] = useState('');
    const [riskAndResponse, setRiskAndResponse] = useState('');
    const [constraints, setConstraints] = useState('');
    const [assumptions, setAssumptions] = useState('');
    const [dependencies, setDependencies] = useState('');
    const [firmQuotesTagetDate, setFirmQuotesTagetDate] = useState('');
    const [firmQuotesActualDate, setFirmQuotesActualDate] = useState('');
    const [businessCasesTargetDate, setBusinessCasesTargetDate] = useState('');
    const [businessCasesActualDate, setBusinessCasesActualDate] = useState('');
    const [purchaseOrderTargetDate, setPurchaseOrderTargetDate] = useState('');
    const [purchaseOrderActualDate, setPurchaseOrderActualDate] = useState('');
    const [changeControlTargetDate, setChangeControlTargetDate] = useState('');
    const [changeControlActualDate, setChangeControlActualDate] = useState('');
    const [qualityAssuranceTargetDate, setQualityAssuranceTargetDate] = useState('');
    const [qualityAssuranceActualDate, setQualityAssuranceActualDate] = useState('');
    const [sacTargetDate, setSacTargetDate] = useState('');
    const [sacActualDate, setSacActualDate] = useState('');
    const [handoverTargetDate, setHandoverTargetDate] = useState('');
    const [handoverActualDate, setHandoverActualDate] = useState('');
    const [other, setOther] = useState('');
    const [projectManager, setProjectManager] = useState('');
    const [serviceDeliveryManager, setServiceDeliveryManager] = useState('');
    const [solutionsArchitect, setSolutionsArchitect] = useState('');
    const [networkManager, setNetworkManager] = useState('');
    const [fieldEngineer, setFieldEngineer] = useState('');
    const [finanaceManager, setFinanaceManager] = useState('');
    const [csocNocEngineer, setCsocNocEngineer] = useState('');
    const [countryManager, setCountryManager] = useState('');
    const [procurement, setProcurement] = useState('');
    const [civilWork, setCivilWork] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setNumberOfSites('');
            setTvc('');
            setSdManager('');
            setProjectScope('');
            setDeliverables('');
            setProjectStartDate('');
            setEstimateDeliveryDate('');
            setCustomerExpectedDeliveryDate('');
            setEstimatedBillingDate('');
            setRiskAndResponse('');
            setConstraints('');
            setAssumptions('');
            setDependencies('');
            setFirmQuotesTagetDate('');
            setFirmQuotesActualDate('');
            setBusinessCasesTargetDate('');
            setBusinessCasesActualDate('');
            setPurchaseOrderTargetDate('');
            setPurchaseOrderActualDate('');
            setChangeControlTargetDate('');
            setChangeControlActualDate('');
            setQualityAssuranceTargetDate('');
            setQualityAssuranceActualDate('');
            setSacTargetDate('');
            setSacActualDate('');
            setHandoverTargetDate('');
            setHandoverActualDate('');
            setOther('');
            setProjectManager('');
            setServiceDeliveryManager('');
            setSolutionsArchitect('');
            setNetworkManager('');
            setFieldEngineer('');
            setFinanaceManager('');
            setCsocNocEngineer('');
            setCountryManager('');
            setProcurement('');
            setCivilWork('');
        }
    }, [modalState, open, modalContentLoading, selectedProjectTypeAndUid]);

    return (
        <Dialog
            // classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create Project Charter Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('tvc.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={tvc}
                                type={'text'}
                                placeholder={t('tvc.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setTvc(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('numberOfSites.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={numberOfSites}
                                type={'text'}
                                placeholder={t('numberOfSites.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNumberOfSites(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('sdManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={sdManager}
                                type={'text'}
                                placeholder={t('sdManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSdManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('projectScope.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectScope}
                                type={'text'}
                                placeholder={t('projectScope.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectScope(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('deliverables.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={deliverables}
                                type={'text'}
                                placeholder={t('deliverables.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDeliverables(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('riskAndResponse.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={riskAndResponse}
                                type={'text'}
                                placeholder={t('riskAndResponse.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setRiskAndResponse(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('constraints.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={constraints}
                                type={'text'}
                                placeholder={t('constraints.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setConstraints(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('assumptions.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={assumptions}
                                type={'text'}
                                placeholder={t('assumptions.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setAssumptions(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('dependencies.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={dependencies}
                                type={'text'}
                                placeholder={t('dependencies.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDependencies(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('others.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={other}
                                type={'text'}
                                placeholder={t('other.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setOther(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('projectManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectManager}
                                type={'text'}
                                placeholder={t('projectManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('serviceDeliveryManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={serviceDeliveryManager}
                                type={'text'}
                                placeholder={t('serviceDeliveryManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setServiceDeliveryManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('solutionsArchitect.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={solutionsArchitect}
                                type={'text'}
                                placeholder={t('solutionsArchitect.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSolutionsArchitect(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('networkManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={networkManager}
                                type={'text'}
                                placeholder={t('networkManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNetworkManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('fieldEngineer.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={fieldEngineer}
                                type={'text'}
                                placeholder={t('fieldEngineer.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setFieldEngineer(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('finanaceManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={finanaceManager}
                                type={'text'}
                                placeholder={t('finanaceManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setFinanaceManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('csocNocEngineer.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={csocNocEngineer}
                                type={'text'}
                                placeholder={t('csocNocEngineer.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCsocNocEngineer(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('countryManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={countryManager}
                                type={'text'}
                                placeholder={t('countryManager.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCountryManager(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('procurement.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={procurement}
                                type={'text'}
                                placeholder={t('procurement.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProcurement(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('civilWork.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={civilWork}
                                type={'text'}
                                placeholder={t('civilWork.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCivilWork(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('projectStartDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={projectStartDate}
                                    onChange={date => setProjectStartDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('estimateDeliveryDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={estimateDeliveryDate}
                                    onChange={date => setEstimateDeliveryDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('customerExpectedDeliveryDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={customerExpectedDeliveryDate}
                                    onChange={date => setCustomerExpectedDeliveryDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('estimatedBillingDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={estimatedBillingDate}
                                    onChange={date => setEstimatedBillingDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('firmQuotesTagetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={firmQuotesTagetDate}
                                    onChange={date => setFirmQuotesTagetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('firmQuotesActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={firmQuotesActualDate}
                                    onChange={date => setFirmQuotesActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('businessCasesTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={businessCasesTargetDate}
                                    onChange={date => setBusinessCasesTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('businessCasesActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={businessCasesActualDate}
                                    onChange={date => setBusinessCasesActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('purchaseOrderTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={purchaseOrderTargetDate}
                                    onChange={date => setPurchaseOrderTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('purchaseOrderActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={purchaseOrderActualDate}
                                    onChange={date => setPurchaseOrderActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('changeControlTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={changeControlTargetDate}
                                    onChange={date => setChangeControlTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('changeControlActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={changeControlActualDate}
                                    onChange={date => setChangeControlActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('qualityAssuranceTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={qualityAssuranceTargetDate}
                                    onChange={date => setQualityAssuranceTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('qualityAssuranceActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={qualityAssuranceActualDate}
                                    onChange={date => setQualityAssuranceActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('sacTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={sacTargetDate}
                                    onChange={date => setSacTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('sacActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={sacActualDate}
                                    onChange={date => setSacActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('handoverTargetDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={handoverTargetDate}
                                    onChange={date => setHandoverTargetDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">{t('handoverActualDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <div className="datePicker" style={{ display: 'flex' }}>
                                <DatePicker
                                    selected={handoverActualDate}
                                    onChange={date => setHandoverActualDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!numberOfSites || !tvc || !sdManager || !projectScope || !deliverables || !projectStartDate || !estimateDeliveryDate || !customerExpectedDeliveryDate ||
                                !estimatedBillingDate || !riskAndResponse || !constraints || !assumptions ||
                                !dependencies || !firmQuotesTagetDate || !firmQuotesActualDate || !businessCasesTargetDate || !businessCasesActualDate ||
                                !purchaseOrderTargetDate || !purchaseOrderActualDate || !changeControlTargetDate || !changeControlActualDate || !qualityAssuranceTargetDate ||
                                !qualityAssuranceActualDate || !sacTargetDate || !sacActualDate || !handoverTargetDate || !handoverActualDate || !other ||
                                !projectManager || !serviceDeliveryManager || !solutionsArchitect || !networkManager || !fieldEngineer || !finanaceManager || !csocNocEngineer ||
                                !countryManager || !procurement || !civilWork) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmitClick({
                                project_name: selectedProjectTypeAndUid.uid,
                                project_name: selectedProjectTypeAndUid.project_name,
                                account_manager: selectedProjectTypeAndUid.organization_name,
                                customer: selectedProjectTypeAndUid.customer_name,
                                tvc: tvc,
                                number_of_sites: numberOfSites,
                                project_start_date: moment(projectStartDate).format("YYYY-MM-DD"),
                                estimated_delivery_date: moment(estimateDeliveryDate).format("YYYY-MM-DD"),
                                customer_expected_delivery_date: moment(customerExpectedDeliveryDate).format("YYYY-MM-DD"),
                                estimated_billing_date: moment(estimatedBillingDate).format("YYYY-MM-DD"),
                                sd_manager: sdManager,
                                project_scope: projectScope,
                                deliverables: deliverables,
                                risk_and_response: riskAndResponse,
                                constraints: constraints,
                                assumptions: assumptions,
                                dependencies: dependencies,
                                one_target_completion_date: moment(firmQuotesTagetDate).format("YYYY-MM-DD"),
                                one_actual_completion_date: moment(firmQuotesActualDate).format("YYYY-MM-DD"),
                                two_target_completion_date: moment(businessCasesTargetDate).format("YYYY-MM-DD"),
                                two_actual_completion_date: moment(businessCasesActualDate).format("YYYY-MM-DD"),
                                three_target_completion_date: moment(purchaseOrderTargetDate).format("YYYY-MM-DD"),
                                three_actual_completion_date: moment(purchaseOrderActualDate).format("YYYY-MM-DD"),
                                four_target_completion_date: moment(changeControlTargetDate).format("YYYY-MM-DD"),
                                four_actual_completion_date: moment(changeControlActualDate).format("YYYY-MM-DD"),
                                five_target_completion_date: moment(qualityAssuranceTargetDate).format("YYYY-MM-DD"),
                                five_actual_completion_date: moment(qualityAssuranceActualDate).format("YYYY-MM-DD"),
                                six_target_completion_date: moment(sacTargetDate).format("YYYY-MM-DD"),
                                six_actual_completion_date: moment(sacActualDate).format("YYYY-MM-DD"),
                                seven_target_completion_date: moment(handoverTargetDate).format("YYYY-MM-DD"),
                                seven_actual_completion_date: moment(handoverActualDate).format("YYYY-MM-DD"),
                                others: other,
                                projct_manager: projectManager,
                                service_delivery_manager: serviceDeliveryManager,
                                solutions_architect: solutionsArchitect,
                                network_manager: networkManager,
                                field_engineer: fieldEngineer,
                                finanace_manager: finanaceManager,
                                csoc_noc_engineer: csocNocEngineer,
                                country_manager: countryManager,
                                procurement: procurement,
                                civil_work: civilWork
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateProjectCharterFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateProjectCharterFormModal);