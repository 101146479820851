import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('terminateSubscription.label')}
        </Button>
    );
}
const SDNTerminateSubscriptionPanel = ({t, modalState, data, handleDeleteOrgButtonClick}) => {
    console.log('data', data);
    return (
        <div>
            <ButtonComponent
                t={t}
                onClick={() => {
                    handleDeleteOrgButtonClick({organization_id: data.organizationId, subscription_id: data.id});
                }}
                loading={modalState.btnLoading}/>
        </div>
    );
};
SDNTerminateSubscriptionPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};
export default SDNTerminateSubscriptionPanel;
