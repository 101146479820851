import React from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../components/hook-form';
import { renderText } from '../displayComponent';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPortNum } from '../../../services/portService';
import Message from '../../../components/Message';
import { useAuth } from '../../../contexts/AuthContext';

const Step2Port = ({ handleNext, setState, handlePrev, podId, activeStep }) => {
  const { user } = useAuth();

  const initialValues = {
    portName: '',
    portSpeed: '',
    minTerm: '',
    marketPlace: '',
    highAvailability: '',
    port1: null,
    port2: null
  };
  const validationSchema = yup.object().shape({
    portName: yup.string().required('This field is required'),
    portSpeed: yup.string().required('This field is required'),
    minTerm: yup.string().required('This field is required'),
    marketPlace: yup.string().required('This field is required'),
    highAvailability: yup.string().required('This field is required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, watch } = methods;

  const highAvailability = watch('highAvailability');

  const onSubmit = async data => {
    setState({ data });
    const payload = {
      pod_id: podId,
      high_availability: data?.highAvailability,
      static_port1_num: +data?.port1
    };

    // Check if static_port2_num has a value
    if (
      data?.port2 !== undefined &&
      data?.port2 !== null &&
      data?.port2 !== ''
    ) {
      payload.static_port2_num = +data.port2;
    }

    if (activeStep === 1 && user?.email === 'testuser40@mail.com') {
      const res = await createPortNum(payload);
      if (res?.status === 200) {
        handleNext();
      } else {
        Message.error(res?.message || 'Something went wrong');
      }
    } else {
      handleNext();
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Paper style={{ padding: '0 10px' }}>
              <Box>{renderText({ label: 'Configuring Port' })}</Box>
              <Grid
                container
                spacing={2}
                style={{ padding: '10px', width: '500px' }}>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Port Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="portName"
                      label="Port Name"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Port Speed
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={7}
                    justify="center"
                    alignItems="center">
                    <RHFSelect name="portSpeed" variant="outlined" size="small">
                      <option value="">Select</option>
                      {[{ key: '1 Gbps', value: '1 Gbps' }]?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Minimum Term
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={7}
                    justify="space-between"
                    alignItems="center">
                    <Grid item sm={12}>
                      <RHFSelect name="minTerm" variant="outlined" size="small">
                        <option value="">Select</option>
                        {[
                          { key: '1 month', value: '1' },
                          { key: '12 months', value: '12' },
                          { key: '24 months', value: '24' },
                          { key: '36 months', value: '36' }
                        ]?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item sm={2}>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    Market Place
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFSelect
                      name="marketPlace"
                      size="small"
                      variant="outlined">
                      <option value="">Select</option>
                      {[
                        { key: 'No', value: 'no' },
                        { key: 'Yes', value: 'yes' }
                      ]?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid item sm={3}>
                    High Availability
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFSelect
                      name="highAvailability"
                      size="small"
                      variant="outlined">
                      <option value="">Select</option>
                      {[
                        { key: 'No', value: 'no' },
                        { key: 'Yes', value: 'yes' }
                      ]?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
                {highAvailability === 'no' &&
                  user?.email === 'testuser40@mail.com' && (
                    <Grid item container justify="center" alignItems="center">
                      <Grid item sm={3}>
                        Select Port
                      </Grid>
                      <Grid item sm={1}>
                        <span>:</span>
                      </Grid>
                      <Grid item sm={7}>
                        <RHFTextField
                          variant="outlined"
                          name="port1"
                          size="small"
                          autoComplete="off"
                          required
                        />
                      </Grid>
                    </Grid>
                  )}

                {highAvailability === 'yes' &&
                  user?.email === 'testuser40@mail.com' && (
                    <>
                      <Grid item container justify="center" alignItems="center">
                        <Grid item sm={3}>
                          Select Port 1
                        </Grid>
                        <Grid item sm={1}>
                          <span>:</span>
                        </Grid>
                        <Grid item sm={7}>
                          <RHFTextField
                            variant="outlined"
                            name="port1"
                            size="small"
                            autoComplete="off"
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid item container justify="center" alignItems="center">
                        <Grid item sm={3}>
                          Select Port 2
                        </Grid>
                        <Grid item sm={1}>
                          <span>:</span>
                        </Grid>
                        <Grid item sm={7}>
                          <RHFTextField
                            variant="outlined"
                            name="port2"
                            size="small"
                            autoComplete="off"
                            required
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item container spacing={2} justify="space-between">
            <Grid item>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default Step2Port;
