import {
  Button,
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  CircularProgress,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Popup from '../../Popup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getBgpByConnId, updateBgpById } from '../../../../services/fcrService';
import Message from '../../../../components/Message';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const validationSchema = yup.object().shape({
  remoteAs: yup
    .number()
    .typeError('Enter a value between 65001 & 65100')
    .min(65001, 'Enter a value between 65001 & 65100')
    .max(65100, 'Enter a value between 65001 & 65100')
});

const EditBgpPopup = ({
  BgpEditView,
  setBgpEditView,
  connFcrId,
  connectionId,
  classes
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();
  const [bgpId, setBgpId] = useState('');
  const [error, setError] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState('No');
  const [bgpOperStatus, setBgpOperStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialValues = { neighbourIp: '', remoteAs: '' };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset, setValue, formState: { isValid } } = methods;

  const onSubmit = async ({ remoteAs }) => {
    const payload = {
      neighbor_remote: remoteAs.toString(),
      deleteBgp: deleteStatus
    };

    const res = await updateBgpById(bgpId, payload);
    if (res?.status === 200) {
      if (res?.data?.message) {
        Message.success(res?.data?.message);
      } else {
        Message.success('Updated Successfully');
      }
      setBgpEditView(false);
      reset();
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else {
        Message.error('Something went wrong');
      }
    }
    reset();
  };

  const GetBgpByConnId = async () => {
    setLoading(true);
    const res = await getBgpByConnId(connectionId, connFcrId);
    if (res?.data?.statusCode === 200) {
      res && setBgpOperStatus(res?.data?.result?.oper_status);
      setNieghbourIp(res?.data?.result?.conn_id?.conn_cidr_b_ip);
      setValue('remoteAs', res?.data?.result?.neighbor_remote);
      setBgpId(res?.data?.result?._id);
      setLoading(false);
    } else {
      setError(res?.response?.data?.message || res?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetBgpByConnId();
  }, []);

  return (
    <>
      <Popup
        title="Edit BGP"
        openPopup={BgpEditView}
        setOpenPopup={setBgpEditView}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid item>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                  }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {bgpOperStatus !== 'Deleting...' && (
                    <Grid item>
                      {!error ? (
                        <>
                          <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered>
                            <Tab label="Standard" {...a11yProps(0)} />
                            <Tab label="Advanced" {...a11yProps(1)} disabled />
                          </Tabs>
                          <TabPanel value={tabValue} index={0}>
                            <Grid container direction="column" spacing={2}>
                              <Grid
                                item
                                container
                                justify="center"
                                alignItems="center">
                                <Grid
                                  item
                                  style={{ color: 'grey', fontWeight: '500' }}
                                  sm={4}>
                                  Neighbor IP
                                </Grid>
                                <Grid item sm={1}>
                                  <span>:</span>
                                </Grid>
                                <Grid item sm={7}>
                                  <TextField
                                    value={nieghbourIp}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="off"
                                    disabled
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                justify="center"
                                alignItems="center">
                                <Grid
                                  item
                                  style={{ color: 'grey', fontWeight: '500' }}
                                  sm={4}>
                                  Remote-AS
                                </Grid>
                                <Grid item sm={1}>
                                  <span>:</span>
                                </Grid>
                                <Grid item sm={7}>
                                  <RHFTextField
                                    variant="outlined"
                                    name="remoteAs"
                                    autoComplete="off"
                                    size="small"
                                    fullWidth
                                    disabled={
                                      bgpOperStatus === 'Error' || error
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justify="center">
                                <Grid
                                  item
                                  style={{ color: 'grey', fontWeight: '500' }}
                                  sm={4}>
                                  Delete BGP
                                </Grid>
                                <Grid item sm={1}>
                                  :
                                </Grid>
                                <Grid item sm={7}>
                                  <TextField
                                    select
                                    value={deleteStatus}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    SelectProps={{
                                      native: true
                                    }}
                                    disabled={
                                      bgpOperStatus === 'Error' || error
                                    }
                                    onChange={e =>
                                      setDeleteStatus(e.target.value)
                                    }>
                                    {[
                                      { key: 'Yes', value: 'Yes' },
                                      { key: 'No', value: 'No' }
                                    ].map(option => (
                                      <option
                                        key={option.key}
                                        value={option.value}>
                                        {option.key}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                              {bgpOperStatus === 'Error' && (
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justify="center">
                                  <Grid
                                    item
                                    style={{ color: 'grey', fontWeight: '500' }}
                                    sm={4}>
                                    Status
                                  </Grid>
                                  <Grid item sm={1}>
                                    :
                                  </Grid>
                                  <Grid item sm={7}>
                                    <Typography color="error">
                                      {bgpOperStatus}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel
                            value={tabValue}
                            index={1}
                            style={{ height: '175px' }}></TabPanel>
                        </>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <span>{error}</span>
                        </div>
                      )}
                    </Grid>
                  )}

                  {bgpOperStatus === 'Deleting...' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <CircularProgress />
                      <span style={{ marginLeft: '5px' }}>Deleting BGP...</span>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {!error && bgpOperStatus !== 'Deleting...' ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    setBgpEditView(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={bgpOperStatus === 'Error' || error || !isValid}>
                  Submit
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setBgpEditView(false);
                  }}>
                  Close
                </Button>
              </>
            )}
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default EditBgpPopup;
