import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash-es';
import CustomTabs from '../../../../components/CustomTabs';
import OcrConfiguration from './OcrConfiguration';
import ProductListing from './productListing/OcrConfigurationDocuments/OcrConfigurationDocumentsReport';
import CreateProject from '../../components/OcrPanel/OcrConfiguration/OcrConfigurationProject/CreateProject';
import CreateFolder from '../../components/OcrPanel/OcrConfiguration/OcrConfigurationFolder/CreateFolder';
import CreateSubFolder from '../../components/OcrPanel/OcrConfiguration/OcrConfigurationFolder/CreateSubFolder';
import OcrDocUploadForm from '../../components/OcrPanel/OcrDocUploadForm';
import OcrMultipleDocUpload from '../../components/OcrPanel/OcrMultipleDocUpload';
import OcrDocViewer from '../../components/OcrPanel/OcrDocViewer';
import { OcrContext } from '../../../../contexts/OcrProvider';
import OcrConfigurationGAndUAssignForProejctAndGroup from '../../components/OcrPanel/OcrConfiguration/OcrConfigurationGroups&Users/OcrConfigurationGAndUAssignForProejctAndGroup';
import OcrConfigurationGAndUCreateGroupOrUser from '../../components/OcrPanel/OcrConfiguration/OcrConfigurationGroups&Users/OcrConfigurationGAndUCreateGroupOrUser';
import { getMyLocation, isSuperUser } from '../../../../utils';
import { GET_USER_INFO } from '../../../TicketingSystem/queries';
import SalesTrackerDashboard from './components/DashboardPanel/SalesTrackerDashboard';

import {
  GET_OCR_LIST,
  GET_OCR_RULE_LIST,
  GET_OCR_DASHBOARD_DATA,
  GET_OCR_IMAGE_FOR_RULE,
  GET_OCR_PLACE_LIST,
  GET_OCR_PROJECT_LIST,
  GET_OCR_FOLDER_LIST,
  GET_OCR_DOC_TREE_DATA,
  GET_OCR_PARSE_IMG,
  GET_SUBSCRIPTION_USERS,
  GET_OCR_TREEE_VIEW_SEARCH,
  GET_OCR_GROUP_LIST,
  GET_OCR_GROUP_DATA_BY_ID,
  GET_USERS_HAVE_FOLDER_ACCESS_OCR_LIST,
  GET_OCR_USERS_LIST,
  GET_OCR_PROCESS_LIST
} from '../../Ocr/queries';
import {
  CREATE_OCR_PARSE_DATA,
  CREATE_OCR_RULE_DATA,
  CREATE_MULTIPLE_OCR_PARSE_DATA,
  DELETE_OCR_RULE_DATA,
  EDIT_OCR_RULE_DATA,
  CREATE_OCR_PLACE,
  CREATE_OCR_PROJECT,
  CREATE_OCR_FOLDER,
  DELETE_OCR_PLACE,
  DELETE_OCR_PROJECT,
  DELETE_OCR_FOLDER,
  DELETE_OCR_FILE,
  CREATE_OCR_GROUP,
  EDIT_OCR_GROUP,
  DELETE_OCR_GROUP,
  ASSIGN_FOLDER_TO_GROUP,
  ASSIGN_FOLDER_TO_USER,
  ASSIGN_PROJECT_TO_USER,
  CREATE_OCR_ACTIVITY_LOG,
  ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS
} from '../../Ocr/mutations';
import Message from '../../../../components/Message';
import { colors } from '../../../../theme/colors';
import { withTranslation } from 'react-i18next';
import {
  GET_DISTRIBUTORS_LIST,
  GET_SALES_DATA,
  GET_SALES_DASHBOARD_MAP_DETAILS
} from './queries';

const IntelligentSaleTracker = ({ t, data }) => {
  const { organizationId, ownerId, id } = data.getSubscriptionById;
  const PAGE_LIMIT = 10000;
  const [activeIndex, setActiveIndex] = useState(0);
  const [ocrSubmitLoading, setOcrSubmitLoading] = useState(false);
  const [ocrFetchLoading, setOcrFetchLoading] = useState(false);
  const [ocrData, setOcrData] = useState('');
  const [isOcrFormEnable, setIsOcrFormEnable] = useState(false);
  const [ocrRuleFetchingloading, setOcrRuleFetchingloading] = useState(false);
  const [ocrRuleData, setOcrRuleData] = useState('');
  const [
    isMultipleOcrDocUploadenable,
    setIsMultipleOcrDocUploadenable
  ] = useState(false);
  const [ocrMultipleDocSubmitting, setOcrMultipleDocSubmitting] = useState(
    false
  );
  const [dashboardPanelData, setDashboardPanelData] = useState('');
  const [ocrImageDataForCreateRule, setOcrImageDataForCreateRule] = useState(
    []
  );
  const [ocrRuleDeletetingStarted, setOcrRuleDeletetingStarted] = useState(
    false
  );
  const [selecteIdForDelete, setSelecteIdForDelete] = useState('');
  const [paginationState, setPaginationState] = useState({
    ocr_document: 1,
    ocr_rule: 1,
    ocr_place: 1,
    ocr_project: 1,
    ocr_folder: 1
  });
  const [ocrDocumentSearchvalue, setOcrDocumentSearchvalue] = useState('');
  const [ocrRuleSearchvalue, setOcrRuleSearchvalue] = useState('');
  const [ocrFolderSearchvalue, setOcrFolderSearchvalue] = useState('');
  const [isCreateLocationEnable, setIsCreateLocationEnable] = useState(false);
  const [isCreateProjectEnable, setIsCreateProjectEnable] = useState(false);
  const [isCreateFolderEnable, setIsCreateFolderEnable] = useState(false);
  const [isCreateSubFolderEnable, setIsCreateSubFolderEnable] = useState(false);
  const [ocrSubFolderSubmit, setOcrSubFolderSubmit] = useState(false);
  const [ocrPlaceData, setOcrPlaceData] = useState('');
  const [ocrProjectData, setOcrProjectData] = useState('');
  const [ocrFolderData, setOcrFolderData] = useState('');
  const [ocrPlaceListLoading, setOcrPlaceListLoading] = useState(false);
  const [ocrProjectListLoading, setOcrProjectLoaListding] = useState(false);
  const [ocrFolderListLoading, setocrFolderListLoading] = useState(false);
  const [ocrProjectSubmit, setOcrProjectSubmit] = useState(false);
  const [ocrLocationSubmit, setOcrLocationSubmit] = useState(false);
  const [ocrFolderSubmit, setOcrFolderSubmit] = useState(false);
  const [locationsForSalesDashBoard, setLocationsForSalesDashBoard] = useState(
    []
  );
  const [isAssignProjectEnable, setIsAssignProjectEnable] = useState(false);
  const [isParseDocViwerEnable, setIsParseDocViwerEnable] = useState(false);
  const [isCreateGroupOrUserEnable, setIsCreateGroupOrUserEnable] = useState(
    false
  );
  const [doctextORImg, setDoctextORImg] = useState('');
  const [ocrParseImgLoading, setOcrParseImgLoading] = useState(false);
  const [ocrParseImgs, setOcrParseImgs] = useState('');
  const [img_url, setimg_url] = useState('');
  const [treeStruckcherDataForModal, setTreeStruckcherDataForModal] = useState(
    null
  );
  const [subscriptionUserData, setSubscriptionUserData] = useState([]);
  const [ocrTreeViewData, setocrTreeViewData] = useState([]);
  const [ocrGroupData, setOcrGroupData] = useState([]);
  const [assignType, setAssignType] = useState('');
  const [
    isaddUserToGroupElementEnable,
    setIsaddUserToGroupElementEnable
  ] = useState(false);
  const [
    selctedIdForGroupSingleData,
    setSelctedIdForGroupSingleData
  ] = useState('');
  const [groupSingleData, setGroupSingleData] = useState({});
  const [
    uaserPayloadForGroupAndProject,
    setUaserPayloadForGroupAndProject
  ] = useState('');
  const [apiTimer, setApiTimer] = useState(0);
  const [usersDataByFolderId, setUsersDataByFolderId] = useState([]);
  const [ocrDocumentsListing, setOcrDocumentsListing] = useState('');
  const [ocrUsersList, setOcrUsersList] = useState([]);
  const [ocrProcessList, setOcrProcessList] = useState([]);
  const { handleRefetchedOrFilter } = useContext(OcrContext);

  const {
    data: userdata,
    loading: userDataLoading,
    error: userDataError
  } = useQuery(GET_USER_INFO);

  const uploadOcrDoc = () => setIsOcrFormEnable(true);
  const uploadMultipleOcrDoc = () => {
    setIsMultipleOcrDocUploadenable(true);
  };
  const createLocationEnable = () => setIsCreateLocationEnable(true);
  const createProjectEnable = () => setIsCreateProjectEnable(true);
  const createFolderEnable = () => setIsCreateFolderEnable(true);
  const createSubFolderEnable = () => setIsCreateSubFolderEnable(true);
  const assignProjectEnable = (type, data) => {
    console.log('type', type);
    console.log('data', data);
    setUaserPayloadForGroupAndProject(data);
    setAssignType(type);
    setIsAssignProjectEnable(true);
  };
  const createGroupOrUserEnable = (type, data) => {
    if (type === t('assignFolderToUserOcr.label')) {
      getUsesrsHaveFolderAccessById({
        variables: {
          organization: organizationId,
          folder_id: data.folderId
        }
      });
    }
    setUaserPayloadForGroupAndProject(data);
    setAssignType(type);
    setIsCreateGroupOrUserEnable(true);
  };
  const handleOcrFormClose = () => setIsOcrFormEnable(false);

  const handleMultipleOcrDocUploadFormClose = () =>
    setIsMultipleOcrDocUploadenable(false);
  const handleCreateLocationEnableFormClose = () =>
    setIsCreateLocationEnable(false);
  const handleCreateProjectEnableFormClose = () =>
    setIsCreateProjectEnable(false);
  const handlecreateFolderEnableFormClose = () =>
    setIsCreateFolderEnable(false);
  const handlecreateSubFolderEnableFormClose = () =>
    setIsCreateSubFolderEnable(false);
  const handleCloseOfProject = () => {
    setUaserPayloadForGroupAndProject('');
    setIsAssignProjectEnable(false);
    setAssignType('');
  };
  const handleCloseofGroupOrUserEnable = () => {
    setIsaddUserToGroupElementEnable(false);
    setIsCreateGroupOrUserEnable(false);
    setSelctedIdForGroupSingleData('');
    setAssignType('');
  };

  const {
    data: dashboardData,
    loading: dashboardLoading,
    error: dashboardError
  } = useQuery(GET_OCR_DASHBOARD_DATA, {
    variables: { organization: organizationId }
  });

  // fetching document OCR tree structure data on init load
  const {
    data: ocrDocTreeData,
    loading: ocrDocTreeLoading,
    error: ocrDocTreeError,
    refetch: reloadTreeData
  } = useQuery(GET_OCR_DOC_TREE_DATA, {
    variables: {
      organization: organizationId,
      userData: userdata,
      is_superUser: isSuperUser(localStorage.getItem('role')) ? true : false
    }
  });

  const {
    data: ocrDocTreeViewSearchData,
    loading: ocrDocTreeViewSearchLoading,
    error: ocrDocTreeViewSearchError,
    refetch: reloadTreeViewSearchData
  } = useQuery(GET_OCR_TREEE_VIEW_SEARCH, {
    variables: {
      organization: organizationId,
      search: null
    }
  });
  const { data: ocrSelectPlaces } = useQuery(GET_OCR_PLACE_LIST, {
    variables: {
      organization: organizationId,
      limit: 1000,
      page: 1
    }
  });
  const { data: ocrSelectProjects } = useQuery(GET_OCR_PROJECT_LIST, {
    variables: {
      organization: organizationId,
      limit: 1000,
      page: 1
    }
  });

  const { data: ocrUsersData, loading: ocrUsersListLoading } = useQuery(
    GET_OCR_USERS_LIST,
    {
      variables: {
        organization: organizationId,
        groupId: ''
      }
    }
  );

  useEffect(() => {
    setDashboardPanelData(get(dashboardData, 'getOcrDashBoarddata.data', ''));
    setocrTreeViewData(
      get(ocrDocTreeViewSearchData, 'getOcrTreeViewSearch.data', [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!dashboardLoading, !ocrDocTreeViewSearchLoading]);

  const [getOcrListBySearch, { loading }] = useLazyQuery(GET_OCR_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getOcrListdata) {
        setOcrData(data.getOcrListdata);
      }
      setOcrFetchLoading(false);
    },
    onError: error => {
      Message.error(error);
      setOcrFetchLoading(false);
    }
  });

  const [
    getOcrListForDocuments,
    { loading: getOcrListForDocumentsLoading }
  ] = useLazyQuery(GET_OCR_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getOcrListdata) {
        setOcrDocumentsListing(data.getOcrListdata);
      }
    },
    onError: error => {
      Message.error(error);
    }
  });

  const [getOcrRuleListBySearch, { loading: loadingOcrRule }] = useLazyQuery(
    GET_OCR_RULE_LIST,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data && data.getOcrRuleListdata) {
          setOcrRuleData(data.getOcrRuleListdata);
        }
        setOcrRuleFetchingloading(false);
      },
      onError: error => {
        Message.error(error);
        setOcrRuleFetchingloading(false);
      }
    }
  );

  const [getOcrPlaceListBySearch, { loading: loadingOcrPlace }] = useLazyQuery(
    GET_OCR_PLACE_LIST,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data && data.getOcrPlaceList) {
          setOcrPlaceData(data.getOcrPlaceList);
        }
        setOcrPlaceListLoading(false);
      },
      onError: error => {
        Message.error(error);
        setOcrPlaceListLoading(false);
      }
    }
  );

  const [
    getOcrFolderListBySearch,
    { loading: loadingOcrFolder }
  ] = useLazyQuery(GET_OCR_FOLDER_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getOcrFolderList) {
        setOcrFolderData(data.getOcrFolderList);
      }
      setocrFolderListLoading(false);
    },
    onError: error => {
      Message.error(error);
      setocrFolderListLoading(false);
    }
  });

  const [
    getUserListOfSubscriptiontByOwnerId,
    { loading: userListLoading }
  ] = useLazyQuery(GET_SUBSCRIPTION_USERS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const userData = get(data, 'getSubuscriptionUsers.data', []);
      if (userData && userData.length > 0) {
        setSubscriptionUserData(userData);
      }
      // setOcrFetchLoading(false);
    },
    onError: error => {
      Message.error(error);
      // setOcrFetchLoading(false);
    }
  });

  const [
    getOcrProjectListBySearch,
    { loading: loadingOcrProject }
  ] = useLazyQuery(GET_OCR_PROJECT_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getOcrProjectList) {
        setOcrProjectData(data.getOcrProjectList);
      }
      setOcrProjectLoaListding(false);
    },
    onError: error => {
      Message.error(error);
      setOcrProjectLoaListding(false);
    }
  });

  const getMarkerColorByEvents = (placeEvents, totalEvents) => {
    let perc = (parseFloat(placeEvents) / parseFloat(totalEvents)) * 100;
    let color = colors.common.green;
    if (!isNaN(Math.round(perc))) {
      if (Math.round(perc) > 10 && Math.round(perc) < 30) {
        color = colors.common.orange;
      } else if (Math.round(perc) > 40) {
        color = colors.common.red;
      }
    }
    return color;
  };

  const [getOcrParseImg] = useLazyQuery(GET_OCR_PARSE_IMG, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const alldata = get(data, 'getOcrParseImageData.data', '');
      setOcrParseImgs(alldata);
      setOcrParseImgLoading(false);
    },
    onError: error => {
      Message.error(error);
      setOcrParseImgLoading(false);
    }
  });

  const [getGroupListByOrgId, { loading: groupListLoading }] = useLazyQuery(
    GET_OCR_GROUP_LIST,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const groupData = get(data, 'getOcrGroupList.data', []);

        if (groupData && groupData.length > 0) {
          setOcrGroupData(groupData);
        }
      },
      onError: error => {
        Message.error(error);
      }
    }
  );

  const [getGroupDataById, { loading: groupSingleDataLoading }] = useLazyQuery(
    GET_OCR_GROUP_DATA_BY_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        console.log('data', data);
        const groupSingleData = get(data, 'getOcrGroupDataById.data', {});

        if (groupSingleData) {
          setGroupSingleData(groupSingleData);
        }
      },
      onError: error => {
        Message.error(error);
      }
    }
  );

  const [
    getUsesrsHaveFolderAccessById,
    { loading: getUsesrsHaveFolderAccessLoading }
  ] = useLazyQuery(GET_USERS_HAVE_FOLDER_ACCESS_OCR_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      console.log('data', data);
      const usesrsDataByFolderId = get(
        data,
        'getUsersHaveFolderAccessOcrList.data',
        []
      );

      if (usesrsDataByFolderId) {
        setUsersDataByFolderId(usesrsDataByFolderId);
      }
    },
    onError: error => {
      Message.error(error);
    }
  });

  const [getOcrImgRuleData, { loading: ocrImageDataLoading }] = useLazyQuery(
    GET_OCR_IMAGE_FOR_RULE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const ocrRuleData = get(data, 'getOcrImageListForRule.data', []);
        if (ocrRuleData) {
          setOcrImageDataForCreateRule(ocrRuleData);
        }
      },
      onError: error => {
        Message.error(error);
      }
    }
  );

  const [getOcrUsersData, { loading: ocrOcrUsersDataLoading }] = useLazyQuery(
    GET_OCR_USERS_LIST,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const ocrUsersList = get(data, 'getOcrUsersList.data', []);
        if (ocrUsersList) {
          setOcrUsersList(ocrUsersList);
        }
      },
      onError: error => {
        Message.error(error);
      }
    }
  );

  const [
    getOcrProcessData,
    { loading: ocrOcrProcessDataLoading }
  ] = useLazyQuery(GET_OCR_PROCESS_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const ocrProcessData = get(data, 'getOcrProcessList.data', []);
      if (ocrProcessData) {
        const updatedData = ocrProcessData.map(el => {
          el.assignedProjectIdToString = el.assignedProject.join();
          el.assignedFolderIdToString = el.assignedFolder.join();
          return el;
        });
        setOcrProcessList(updatedData);
      }
    },
    onError: error => {
      Message.error(error);
    }
  });

  const [addOCRParseData] = useMutation(CREATE_OCR_PARSE_DATA, {
    onError: error => {
      console.log('err', error);
      setOcrSubmitLoading(false);
    },
    onCompleted: data => {
      setOcrSubmitLoading(false);
      Message.success(data && data.addOcrData.message);
      handleSearch('');
      reloadTreeData();
      handleOcrFormClose();
    }
  });

  const [addMutlipleOCRParseData] = useMutation(
    CREATE_MULTIPLE_OCR_PARSE_DATA,
    {
      onError: error => {
        setOcrMultipleDocSubmitting(false);
        console.log('err', error);
      },
      onCompleted: data => {
        setOcrMultipleDocSubmitting(false);
        Message.success(data && data.addMultipleOcrData.message);
        handleSearch('');
        reloadTreeData();
        handleMultipleOcrDocUploadFormClose();
      }
    }
  );

  const [createOCRPlace] = useMutation(CREATE_OCR_PLACE, {
    onError: error => {
      console.log('err', error);
      setOcrLocationSubmit(false);
    },
    onCompleted: data => {
      setOcrLocationSubmit(false);
      Message.success(data && data.createOcrPlace.message);
      getOcrPlaceListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_place,
          limit: PAGE_LIMIT
        }
      });
      reloadTreeData();
      handleCreateLocationEnableFormClose();
    }
  });

  const [createOCRProject] = useMutation(CREATE_OCR_PROJECT, {
    onError: error => {
      console.log('err', error);
      setOcrProjectSubmit(false);
    },
    onCompleted: data => {
      setOcrProjectSubmit(false);
      getOcrProjectListBySearch({
        variables: {
          organization: organizationId,
          place: null,
          search: null,
          page: paginationState.ocr_project,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.createOcrProject.message);
      //handleSearchOfOcrRule(data='');
      reloadTreeData();
      handleCreateProjectEnableFormClose();
    }
  });

  const [createOCRFolder] = useMutation(CREATE_OCR_FOLDER, {
    onError: error => {
      console.log('err', error);
      setOcrFolderSubmit(false);
    },
    onCompleted: data => {
      setOcrFolderSubmit(false);
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          project: null,
          place: null,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.createOcrFolder.message);
      //handleSearchOfOcrRule(data='');
      reloadTreeData();
      handlecreateFolderEnableFormClose();
    }
  });

  const [createOCRSubFolder] = useMutation(CREATE_OCR_FOLDER, {
    onError: error => {
      console.log('err', error);
      setOcrSubFolderSubmit(false);
    },
    onCompleted: data => {
      setOcrSubFolderSubmit(false);
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          project: null,
          place: null,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.createOCRSubFolder.message);
      //handleSearchOfOcrRule(data='');
      reloadTreeData();
      handlecreateSubFolderEnableFormClose();
    }
  });

  const [deleteOCRPlace] = useMutation(DELETE_OCR_PLACE, {
    onError: error => {
      console.log('err', error);
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
    },
    onCompleted: data => {
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
      reloadTreeData();
      getOcrPlaceListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_place,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.deleteOcrPlace.message);
    }
  });

  const [deleteOCRProjec] = useMutation(DELETE_OCR_PROJECT, {
    onError: error => {
      console.log('err', error);
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
    },
    onCompleted: data => {
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
      reloadTreeData();
      getOcrProjectListBySearch({
        variables: {
          organization: organizationId,
          place: null,
          search: null,
          page: paginationState.ocr_project,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.deleteOcrProject.message);
    }
  });

  const [deleteOCRFolder] = useMutation(DELETE_OCR_FOLDER, {
    onError: error => {
      console.log('err', error);
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
    },
    onCompleted: data => {
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
      reloadTreeData();
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          project: null,
          place: null,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.deleteOcrFolder.message);
    }
  });

  const [deleteOCRFile] = useMutation(DELETE_OCR_FILE, {
    onError: error => {
      console.log('err', error);
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
    },
    onCompleted: data => {
      setOcrRuleDeletetingStarted(false);
      setSelecteIdForDelete('');
      reloadTreeData();
      getOcrListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_document,
          limit: PAGE_LIMIT
        }
      });
      Message.success(data && data.deleteOcrFile.message);
    }
  });

  const [createOcrGroup, { loading: createOcrGroupLoading }] = useMutation(
    CREATE_OCR_GROUP,
    {
      onError: error => {
        console.log('err', error);
      },
      onCompleted: data => {
        getGroupListByOrgId({ variables: { organization: organizationId } });
        Message.success(data && data.addOcrGroup.message);
      }
    }
  );

  const [editOcrGroup, { loading: editOcrGroupLoading }] = useMutation(
    EDIT_OCR_GROUP,
    {
      onError: error => {
        console.log('err', error);
      },
      onCompleted: data => {
        Message.success(data && data.editOcrGroup.message);
        if (selctedIdForGroupSingleData) {
          getGroupDataById({
            variables: {
              id: selctedIdForGroupSingleData
            }
          });
        } else {
          handleCloseOfProject();
        }
      }
    }
  );

  const [deleteOcrGroup, { loading: deleteOcrGroupLoading }] = useMutation(
    DELETE_OCR_GROUP,
    {
      onError: error => {
        console.log('err', error);
        setSelecteIdForDelete('');
      },
      onCompleted: data => {
        setSelecteIdForDelete('');
        getGroupListByOrgId({ variables: { organization: organizationId } });
        Message.success(data && data.deleteOcrGroup.message);
      }
    }
  );

  const [
    assignFolderTogroup,
    { loading: assignFolderToGroupLoading }
  ] = useMutation(ASSIGN_FOLDER_TO_GROUP, {
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.assignFolderToOcrGroup.message);
      handleRefetchedOrFilter(
        'groupsOfFolder',
        uaserPayloadForGroupAndProject &&
          uaserPayloadForGroupAndProject.folderId
      );
      handleCloseOfProject();
    }
  });

  const [
    assignFolderToUser,
    { loading: assignFolderToUserLoading }
  ] = useMutation(ASSIGN_FOLDER_TO_USER, {
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.assignFolderToOcrUser.message);
      if (assignType === t('assignFolderToUserOcr.label')) {
        getUsesrsHaveFolderAccessById({
          variables: {
            organization: organizationId,
            folder_id: uaserPayloadForGroupAndProject.folderId
          }
        });
      }
    }
  });

  const [
    assignProjectToUser,
    { loading: assignProjectToUserLoading }
  ] = useMutation(ASSIGN_PROJECT_TO_USER, {
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.assignProjectToUserOcr.message);
      handleCloseOfProject();
    }
  });

  const [
    addProjectOfFolderToOCRProcess,
    { loading: addProjectOfFolderToOCRProcessLoading }
  ] = useMutation(ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS, {
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.addProjectOrFolderToOcrProcess.message);
      handleCloseOfProject();
      refetchingProcessData();
    }
  });

  const handleSubmitOfOcrParse = async (
    files,
    ocrtext,
    ocrImg,
    ruleData,
    locationValue,
    projectValue,
    folderalue
  ) => {
    if (!locationValue || !projectValue || !folderalue)
      return Message.error('Plese select value from dropdown !!');
    setOcrSubmitLoading(true);
    let convertingBase66ToImg;

    if (ocrImg && files.type === 'application/pdf') {
      const filename = files.name.replace(/\.[^.]+$/, '.jpeg');
      const res = await fetch(ocrImg);
      const blob = await res.blob();
      convertingBase66ToImg = new File([blob], filename, {
        type: 'image/jpeg'
      });
    }
    let myLocationresult = await getMyLocation();
    addOCRParseData({
      variables: {
        organization: organizationId,
        heading: '',
        ocr_text: ocrtext.replace(/\n/g, ''),
        doc:
          ocrtext && convertingBase66ToImg !== undefined
            ? convertingBase66ToImg
            : files,
        place: locationValue,
        project: projectValue,
        folder: folderalue,
        rule: ruleData.rule,
        geo_location: myLocationresult != undefined ? myLocationresult : null
      }
    });
  };

  const handleSubmitOfMultipleUpload = async uploadData => {
    if (
      !uploadData.locationValue ||
      !uploadData.projectValue ||
      !uploadData.folderalue
    )
      return Message.error('Plese select value from dropdown !!');
    setOcrMultipleDocSubmitting(true);
    let myLocationresult = await getMyLocation();
    addMutlipleOCRParseData({
      variables: {
        organization: organizationId,
        image_list: uploadData.allFiles,
        rule: uploadData.selectedRuleId,
        place: uploadData.locationValue,
        project: uploadData.projectValue,
        folder: uploadData.folderalue,
        geo_location: myLocationresult != undefined ? myLocationresult : null
      }
    });
  };

  const handleSubmitOfOcrProject = data => {
    if (!data.projectName) return Message.error('Project Name is Requird !!');
    if (data.projectName === data.projectDescription)
      return Message.error(
        'Project Name and Project Description cannot be same !!'
      );

    setOcrProjectSubmit(true);
    createOCRProject({
      variables: {
        organization: organizationId,
        name: data.projectName,
        description: data.projectDescription,
        place: data.locationValue
      }
    });
  };

  const handleSubmitOfOcrPlace = data => {
    if (!data.name) return Message.error('Place Name is Requird !!');
    if (!data.placeDescription)
      return Message.error('Place Description is Requird !!');
    if (data.name === data.placeDescription)
      return Message.error(
        'Place Name and Place Description cannot be same !!'
      );

    setOcrLocationSubmit(true);
    createOCRPlace({
      variables: {
        organization: organizationId,
        name: data.name,
        place_description: data.placeDescription,
        lat: data.location.latitude,
        lng: data.location.longitude
      }
    });
  };

  const handleSubmitOfOcrFolder = data => {
    if (!data.folderName) return Message.error('Folder Name is Requird !!');
    if (data.folderName === data.folderDescription)
      return Message.error(
        'Folder Name and Folder Description cannot be same !!'
      );

    setOcrFolderSubmit(true);
    createOCRFolder({
      variables: {
        organization: organizationId,
        name: data.folderName,
        description: data.folderDescription,
        place: data.locationValue,
        project: data.projectValue
      }
    });
  };

  const handleSubmitOfOcrSubFolder = data => {
    if (!data.folderName) return Message.error('SubFolder Name is Requird !!');
    if (data.folderName === data.folderDescription)
      return Message.error(
        'Folder Name and Folder Description cannot be same !!'
      );

    setOcrSubFolderSubmit(true);
    createOCRSubFolder({
      variables: {
        organization: organizationId,
        name: data.folderName,
        description: data.folderDescription,
        place: data.locationValue,
        project: data.projectValue,
        isSubfolder: true,
        folder: data.folderValue
      }
    });
  };

  const handleSubmitOfDeleteOcrTreeItems = data => {
    if (
      window.confirm(`Are you sure you want to delete this ${data.name}?`) ===
      false
    ) {
      return false;
    }
    setOcrRuleDeletetingStarted(true);
    setSelecteIdForDelete(data.id);
    if (data.type === 'place') {
      deleteOCRPlace({
        variables: {
          organization: organizationId,
          placeId: data.id
        }
      });
    } else if (data.type === 'project') {
      deleteOCRProjec({
        variables: {
          organization: organizationId,
          projectId: data.id
        }
      });
    } else if (data.type === 'folder') {
      deleteOCRFolder({
        variables: {
          organization: organizationId,
          folderId: data.id
        }
      });
    } else if (data.type === 'file') {
      deleteOCRFile({
        variables: {
          organization: organizationId,
          fileId: data.id
        }
      });
    }
  };

  const handleFilterdataForOptions = (data, type) => {
    if (data && type === 'project') {
      getOcrProjectListBySearch({
        variables: {
          organization: organizationId,
          place: data,
          search: null,
          page: paginationState.ocr_project,
          limit: PAGE_LIMIT
        }
      });
    } else if (data && type === 'folder') {
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          place: data.place,
          project: data.project,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
    } else if (data === null && type === 'null') {
      getOcrProjectListBySearch({
        variables: {
          organization: organizationId,
          place: null,
          search: null,
          page: paginationState.ocr_project,
          limit: PAGE_LIMIT
        }
      });
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          place: null,
          project: null,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
    }
  };

  const handleSearch = val => {
    setOcrDocumentSearchvalue(val);
    setOcrFetchLoading(true);
    let filterBy = {
      variables: {
        organization: organizationId,
        search: val,
        page: paginationState.ocr_document,
        limit: PAGE_LIMIT
      }
    };
    return getOcrListBySearch(filterBy);
  };

  const handleSearchOfOcrRule = val => {
    setOcrRuleSearchvalue(val);
    setOcrRuleFetchingloading(true);
    let filterBy = {
      variables: {
        organization: organizationId,
        search: val,
        page: paginationState.ocr_rule,
        limit: PAGE_LIMIT
      }
    };
    return getOcrRuleListBySearch(filterBy);
  };

  const handleSearchOfOcrFolder = val => {
    setOcrFolderSearchvalue(val);
    setocrFolderListLoading(true);
    let filterBy = {
      variables: {
        organization: organizationId,
        search: val,
        project: null,
        place: null,
        page: paginationState.ocr_folder,
        limit: PAGE_LIMIT
      }
    };
    return getOcrFolderListBySearch(filterBy);
  };

  const handlePageChange = (type, page) => {
    setPaginationState({ ...paginationState, [type]: page });
    if (type === 'ocr_document') {
      setOcrFetchLoading(true);
      let filterBy = {
        variables: {
          organization: organizationId,
          search: ocrDocumentSearchvalue,
          page: page,
          limit: PAGE_LIMIT
        }
      };
      return getOcrListBySearch(filterBy);
    } else if (type === 'ocr_rule') {
      setOcrRuleFetchingloading(true);
      let filterBy = {
        variables: {
          organization: organizationId,
          search: ocrRuleSearchvalue,
          page: page,
          limit: PAGE_LIMIT
        }
      };
      return getOcrRuleListBySearch(filterBy);
    } else if (type === 'ocr_place') {
      setOcrPlaceListLoading(true);
      let filterBy = {
        variables: {
          organization: organizationId,
          search: null,
          page: page,
          limit: PAGE_LIMIT
        }
      };
      return getOcrPlaceListBySearch(filterBy);
    } else if (type === 'ocr_project') {
      setOcrProjectLoaListding(true);
      let filterBy = {
        variables: {
          organization: organizationId,
          search: null,
          place: null,
          page: page,
          limit: PAGE_LIMIT
        }
      };
      return getOcrProjectListBySearch(filterBy);
    } else if (type === 'ocr_folder') {
      setocrFolderListLoading(true);
      let filterBy = {
        variables: {
          organization: organizationId,
          search: null,
          project: null,
          place: null,
          page: page,
          limit: PAGE_LIMIT
        }
      };
      return getOcrFolderListBySearch(filterBy);
    }
  };

  const handleOpenFileView = file => {
    setIsParseDocViwerEnable(true);
    setOcrParseImgLoading(true);
    getOcrParseImg({
      variables: {
        organization: organizationId,
        id: file.id
      }
    });
  };

  const handleSubmitOfAllOcrGroupAction = (type, data) => {
    if (type === 'add') {
      if (!data.groupOrUserName)
        return Message.error('Group Name is Requird !!');
      if (!data.accsesvalue)
        return Message.error('Plese select a value from access dropdown !!');

      createOcrGroup({
        variables: {
          organization: organizationId,
          name: data.groupOrUserName,
          permission: data.accsesvalue
        }
      });
    } else if (type === 'edit') {
      console.log('data', data);
      editOcrGroup({
        variables: {
          organization: organizationId,
          id: selctedIdForGroupSingleData
            ? selctedIdForGroupSingleData
            : data.id,
          users: data.user ? data.user : uaserPayloadForGroupAndProject
          //id: selctedIdForGroupSingleData,
          //users: data.user,
        }
      });
    } else if (type === 'delete') {
      setSelecteIdForDelete(data.id);
      deleteOcrGroup({
        variables: {
          organization: organizationId,
          id: data.id
        }
      });
    } else if (type === 'getById') {
      console.log('data', data);
      setSelctedIdForGroupSingleData(data.id);
      setIsaddUserToGroupElementEnable(true);
      getGroupDataById({
        variables: {
          id: data.id
        }
      });
    } else if (type === t('assignToProject.label')) {
      assignProjectToUser({
        variables: {
          organization: organizationId,
          project_id: data.id,
          permission: '',
          user_detail: uaserPayloadForGroupAndProject
        }
      });
    } else if (type === 'usersFolderData') {
      getUsesrsHaveFolderAccessById({
        variables: {
          organization: organizationId,
          folder_id: uaserPayloadForGroupAndProject.folderId
        }
      });
    }
  };

  const ocrAssignAndAddActions = (type, data) => {
    if (type === t('assignFolderToUserOcr.label')) {
      assignFolderToUser({
        variables: {
          organization: organizationId,
          folder_id: uaserPayloadForGroupAndProject.folderId,
          permission: data.accsesvalue,
          user_detail: data.selectedUserData
        }
      });
    } else if (type === t('assignFolderToGroupOcr.label')) {
      assignFolderTogroup({
        variables: {
          organization: organizationId,
          folder_id: uaserPayloadForGroupAndProject.folderId,
          group_id: data.id
        }
      });
    }
  };

  const handleSubmitOrFilterForDocumentConfigTab = data => {
    const { startTime, endTime, place, project } = data;

    getOcrListForDocuments({
      variables: {
        organization: organizationId,
        project,
        place,
        search: '',
        start_date: startTime ? startTime : null,
        end_date: endTime ? endTime : null
      }
    });
  };

  const handleFilterForOcrUser = data => {
    getOcrUsersData({
      variables: {
        organization: organizationId,
        group_id: data.id
      }
    });
  };

  const handleSubmitForOtehrOcrProcess = (type, data) => {
    if (type === t('assignProjectToProcess.label')) {
      addProjectOfFolderToOCRProcess({
        variables: {
          id:
            uaserPayloadForGroupAndProject &&
            uaserPayloadForGroupAndProject.processId,
          assignedProjectId: data.id,
          assignedFolderId: ''
        }
      });
    } else if (type === t('assignFolderToProcess.label')) {
      addProjectOfFolderToOCRProcess({
        variables: {
          id:
            uaserPayloadForGroupAndProject &&
            uaserPayloadForGroupAndProject.processId,
          assignedProjectId: data.projectId,
          assignedFolderId: data.folderId
        }
      });
    }
  };

  const refetchingProcessData = () => {
    getOcrProcessData({ variables: { organization: organizationId } });
  };

  const handleCloseOFParseDocViwer = () => setIsParseDocViwerEnable(false);

  const handleChangeActiveNotiTab = (st, tabeValue) => {
    //setActiveNotificationTab(st);
    if (tabeValue) {
      setActiveIndex(tabeValue);
    }
  };

  const {
    loading: distributorsListLoading,
    data: distributorsList,
    error: distributionListError
  } = useQuery(GET_DISTRIBUTORS_LIST, {
    fetchPolicy: 'no-cache'
  });

  const {
    loading: salesDataLoading,
    data: salesData,
    error: salesDataError
  } = useQuery(GET_SALES_DATA, {
    fetchPolicy: 'no-cache'
  });

  const [
    getSalesDashBoardMapDetails,
    { loading: loadingSalesDashboardMap }
  ] = useLazyQuery(GET_SALES_DASHBOARD_MAP_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      setLocationsForSalesDashBoard(res?.getSalesDashboardMapDetails?.data);
    },
    onError: error => {
      Message.error(error);
    }
  });

  const handleFilterForSalesDashboardMap = data => {
    const { startTime, endTime, distributor } = data;
    getSalesDashBoardMapDetails({
      variables: {
        distributor: distributor ? distributor : '',
        start_date: startTime ? startTime.toString() : '',
        end_date: endTime ? endTime.toString() : ''
      }
    });
  };

  useEffect(() => {
    getSalesDashBoardMapDetails({
      variables: {
        start_date: '',
        end_date: '',
        distributor: ''
      }
    });
  }, []);

  let tabList = [
    {
      label: t('dashboard.label'),
      children: (
        <SalesTrackerDashboard
          t={t}
          loadingSalesDashboardMap={loadingSalesDashboardMap}
          distributorsData={distributorsList?.getDistributorsList?.data}
          salesDashboardData={salesData?.getSalesData?.data}
          locationsForSalesDashBoard={locationsForSalesDashBoard}
          handleFilterForSalesDashboardMap={handleFilterForSalesDashboardMap}
          getSalesDashBoardMapDetails={getSalesDashBoardMapDetails}
        />
      )
    },

    {
      label: 'Uploaded Barcodes',
      children: (
        <ProductListing
          t={t}
          organizationId={organizationId}
          ocrPlaceData={ocrPlaceData}
          ocrProjectData={ocrProjectData}
          ocrFolderData={ocrFolderData}
          loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
          loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
          loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
          treeData={
            !ocrDocTreeLoading &&
            !ocrDocTreeError &&
            ocrDocTreeData.getOcrDocTreeData
              ? ocrDocTreeData.getOcrDocTreeData
              : []
          }
          treeDataLoading={ocrDocTreeLoading}
          isFolderEnable={isCreateFolderEnable}
          isSubFolderEnable={isCreateSubFolderEnable}
          handlecreateSubFolderEnableFormClose={
            handlecreateSubFolderEnableFormClose
          }
          createSubFolderEnable={createSubFolderEnable}
          handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
          ocrSubFolderSubmit={ocrSubFolderSubmit}
          handlecreateFolderEnableFormClose={handlecreateFolderEnableFormClose}
          handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
          ocrFolderSubmit={ocrFolderSubmit}
          handleFilterdataForOptions={handleFilterdataForOptions}
          createFolderEnable={createFolderEnable}
          ocrProjectSubmit={ocrProjectSubmit}
          handleSubmitOfOcrProject={handleSubmitOfOcrProject}
          handleCreateProjectEnableFormClose={
            handleCreateProjectEnableFormClose
          }
          isProjectEnable={isCreateProjectEnable}
          createProjectEnable={createProjectEnable}
          handleSearchOfOcrFolder={handleSearchOfOcrFolder}
          handleOpenFileView={handleOpenFileView}
          selecteIdForDelete={selecteIdForDelete}
          ocrConfigItemsDeletetingStarted={ocrRuleDeletetingStarted}
          uploadOcrDoc={uploadOcrDoc}
          setTreeStruckcherDataForModal={setTreeStruckcherDataForModal}
          handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
          uploadMultipleOcrDoc={uploadMultipleOcrDoc}
          activeIndex={activeIndex}
          ocrDocTreeViewSearchData={ocrDocTreeViewSearchData}
          ocrDocTreeViewSearchLoading={ocrDocTreeViewSearchLoading}
          ocrTreeViewData={ocrTreeViewData}
          assignProjectEnable={assignProjectEnable}
          handleSearchOfParseDocument={handleSearch}
          ocrFetchLoading={ocrFetchLoading || loading}
          ocrData={ocrData}
          createGroupOrUserEnable={createGroupOrUserEnable}
          ocrProcessList={ocrProcessList}
          ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
        />
      )
    }
  ];

  let tabListForSuperUser = [
    ...tabList,
    {
      label: t('configuration.label'),
      children: (
        <OcrConfiguration
          t={t}
          organizationId={organizationId}
          createLocationEnable={createLocationEnable}
          handleCreateLocationEnableFormClose={
            handleCreateLocationEnableFormClose
          }
          isLocationEnable={isCreateLocationEnable}
          ocrPlaceData={ocrPlaceData}
          ocrProjectData={ocrProjectData}
          ocrFolderData={ocrFolderData}
          loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
          loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
          loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
          page={paginationState}
          handlePageChange={handlePageChange}
          createProjectEnable={createProjectEnable}
          handleCreateProjectEnableFormClose={
            handleCreateProjectEnableFormClose
          }
          isProjectEnable={isCreateProjectEnable}
          createFolderEnable={createFolderEnable}
          handlecreateFolderEnableFormClose={handlecreateFolderEnableFormClose}
          isFolderEnable={isCreateFolderEnable}
          isSubFolderEnable={isCreateSubFolderEnable}
          createSubFolderEnable={createSubFolderEnable}
          handlecreateSubFolderEnableFormClose={
            handlecreateSubFolderEnableFormClose
          }
          handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
          ocrSubFolderSubmit={ocrSubFolderSubmit}
          handleSubmitOfOcrProject={handleSubmitOfOcrProject}
          ocrProjectSubmit={ocrProjectSubmit}
          handleSubmitOfOcrPlace={handleSubmitOfOcrPlace}
          ocrLocationSubmit={ocrLocationSubmit}
          handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
          ocrFolderSubmit={ocrFolderSubmit}
          handleFilterdataForOptions={handleFilterdataForOptions}
          ocrConfigItemsDeletetingStarted={ocrRuleDeletetingStarted}
          selecteIdForDelete={selecteIdForDelete}
          handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
          assignProjectEnable={assignProjectEnable}
          createGroupOrUserEnable={createGroupOrUserEnable}
          subscriptionUserListLoading={userListLoading}
          subscriptionUserData={subscriptionUserData}
          ocrFetchLoadingForDocuments={getOcrListForDocumentsLoading}
          ocrDocumentsListing={ocrDocumentsListing}
          handleSubmitOrFilterForDocumentConfigTab={
            handleSubmitOrFilterForDocumentConfigTab
          }
          groupListLoading={groupListLoading}
          ocrGroupData={ocrGroupData}
          ocrOcrUsersDataLoading={ocrOcrUsersDataLoading}
          ocrUsersList={ocrUsersList}
          handleFilterForOcrUser={handleFilterForOcrUser}
          ocrSelectPlaces={
            ocrSelectPlaces &&
            ocrSelectPlaces.getOcrPlaceList &&
            ocrSelectPlaces.getOcrPlaceList.data
              ? ocrSelectPlaces.getOcrPlaceList.data
              : []
          }
          ocrSelectProjects={
            ocrSelectProjects &&
            ocrSelectProjects.getOcrProjectList &&
            ocrSelectProjects.getOcrProjectList.data
              ? ocrSelectProjects.getOcrProjectList.data
              : []
          }
          subscriptionId={id}
          ocrProcessList={ocrProcessList}
          ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
          allOcrUsersData={
            !ocrUsersListLoading && ocrUsersData
              ? get(ocrUsersData, 'getOcrUsersList.data', [])
              : []
          }
          ocrUsersListLoading={ocrUsersListLoading}
          refetchOcrProcessData={refetchingProcessData}
        />
      )
    }
  ];

  useEffect(() => {
    if (organizationId) {
      setOcrFetchLoading(true);
      getOcrProcessData({ variables: { organization: organizationId } });
      getOcrListBySearch({
        variables: {
          organization: organizationId,
          place: '',
          project: '',
          start_date: null,
          end_date: null,
          search: null,
          page: paginationState.ocr_document,
          limit: PAGE_LIMIT
        }
      });
      getOcrRuleListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_rule,
          limit: PAGE_LIMIT
        }
      });
      getOcrPlaceListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_place,
          limit: PAGE_LIMIT
        }
      });
      getOcrProjectListBySearch({
        variables: {
          organization: organizationId,
          place: null,
          search: null,
          page: paginationState.ocr_project,
          limit: PAGE_LIMIT
        }
      });
      getOcrFolderListBySearch({
        variables: {
          organization: organizationId,
          project: null,
          place: null,
          search: null,
          page: paginationState.ocr_folder,
          limit: PAGE_LIMIT
        }
      });
      getUserListOfSubscriptiontByOwnerId({ variables: { groupId: ownerId } });
      getGroupListByOrgId({ variables: { organization: organizationId } });
      getOcrListForDocuments({
        variables: {
          organization: organizationId,
          place: '',
          project: '',
          start_date: null,
          end_date: null,
          search: null
        }
      });
      getOcrImgRuleData({
        variables: { organization: organizationId, search: null }
      });
      getOcrUsersData({
        variables: { organization: organizationId, groupId: '' }
      });
    }
  }, [organizationId]);

  useEffect(() => {
    if (activeIndex === 1 || activeIndex === 6) {
      setTreeStruckcherDataForModal(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setApiTimer(apiTimer + 1);
      getOcrListBySearch({
        variables: {
          organization: organizationId,
          search: null,
          page: paginationState.ocr_document,
          limit: PAGE_LIMIT
        }
      });
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [
    apiTimer,
    getOcrListBySearch,
    organizationId,
    paginationState.ocr_document
  ]);

  return (
    <>
      <CustomTabs
        activeIndex={activeIndex}
        tabs={
          isSuperUser(localStorage.getItem('role'))
            ? tabListForSuperUser
            : tabList
        }
        handleChangeActiveNotiTab={handleChangeActiveNotiTab}
      />
      <OcrDocViewer
        t={t}
        open={isParseDocViwerEnable}
        onClose={handleCloseOFParseDocViwer}
        docData={doctextORImg}
        type="parseDoc"
        loading={ocrParseImgLoading}
        imgUrl={ocrParseImgs}
        isBarcode={ocrParseImgs.is_barcode}
        imgName={img_url ? img_url : ''}
        handleOpenFileView={handleOpenFileView}
      />
      <CreateProject
        t={t}
        open={isCreateProjectEnable}
        onClose={handleCreateProjectEnableFormClose}
        loading={loadingOcrPlace || ocrPlaceListLoading}
        ocrPlaceData={ocrPlaceData}
        handleSubmitOfOcrProject={handleSubmitOfOcrProject}
        ocrProjectSubmit={ocrProjectSubmit}
        isLocationDataexists={treeStruckcherDataForModal}
      />
      <CreateFolder
        t={t}
        open={isCreateFolderEnable}
        onClose={handlecreateFolderEnableFormClose}
        handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
        ocrProjectData={ocrProjectData}
        ocrPlaceData={ocrPlaceData}
        ocrFolderSubmit={ocrFolderSubmit}
        placeLoading={loadingOcrPlace || ocrPlaceListLoading}
        projectLoading={loadingOcrProject || ocrProjectListLoading}
        handleFilterdataForOptions={handleFilterdataForOptions}
        isProjectDataExists={treeStruckcherDataForModal}
      />
      <CreateSubFolder
        t={t}
        open={isCreateSubFolderEnable}
        onClose={handlecreateSubFolderEnableFormClose}
        handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
        ocrProjectData={ocrProjectData}
        ocrPlaceData={ocrPlaceData}
        ocrFolderData={ocrFolderData}
        ocrSubFolderSubmit={ocrSubFolderSubmit}
        placeLoading={loadingOcrPlace || ocrPlaceListLoading}
        projectLoading={loadingOcrProject || ocrProjectListLoading}
        folderLoading={loadingOcrFolder || ocrFolderListLoading}
        handleFilterdataForOptions={handleFilterdataForOptions}
        isProjectDataExists={treeStruckcherDataForModal}
      />
      <OcrDocUploadForm
        t={t}
        open={isOcrFormEnable}
        onClose={handleOcrFormClose}
        ocrSubmitLoading={ocrSubmitLoading}
        handleSubmitOfOcrParse={handleSubmitOfOcrParse}
        handleFilterdataForOptions={handleFilterdataForOptions}
        ocrPlaceData={ocrPlaceData}
        ocrProjectData={ocrProjectData}
        ocrFolderData={ocrFolderData}
        loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
        loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
        loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
        isDataExists={treeStruckcherDataForModal}
      />
      <OcrMultipleDocUpload
        t={t}
        open={isMultipleOcrDocUploadenable}
        onClose={handleMultipleOcrDocUploadFormClose}
        ocrRuleData={ocrRuleData}
        handleSubmitOfMultipleUpload={handleSubmitOfMultipleUpload}
        ocrMultipleDocLoading={ocrMultipleDocSubmitting}
        handleFilterdataForOptions={handleFilterdataForOptions}
        ocrPlaceData={ocrPlaceData}
        ocrProjectData={ocrProjectData}
        ocrFolderData={ocrFolderData}
        loadingOcrPlace={loadingOcrPlace}
        loadingOcrProject={loadingOcrProject}
        loadingOcrFolder={loadingOcrFolder}
        isDataExists={treeStruckcherDataForModal}
      />
      <OcrConfigurationGAndUAssignForProejctAndGroup
        t={t}
        open={isAssignProjectEnable}
        onClose={handleCloseOfProject}
        assignType={assignType}
        groupListLoading={groupListLoading}
        ocrGroupData={ocrGroupData}
        loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
        ocrProjectData={ocrProjectData}
        editOcrGroupLoading={editOcrGroupLoading}
        handleSubmitOfAllOcrGroupAction={handleSubmitOfAllOcrGroupAction}
        ocrAssignAndAddActions={ocrAssignAndAddActions}
        assignFolderToGroupLoading={assignFolderToGroupLoading}
        assignProjectToUserLoading={assignProjectToUserLoading}
        treeDataLoading={ocrDocTreeLoading}
        exitingGroupDetails={
          uaserPayloadForGroupAndProject &&
          uaserPayloadForGroupAndProject.exitingGroupDetails
        }
        handleSubmitForOtehrOcrProcess={handleSubmitForOtehrOcrProcess}
        addProjectOfFolderToOCRProcessLoading={
          addProjectOfFolderToOCRProcessLoading
        }
        ocrFolderData={ocrFolderData}
        loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
        handleFilterdataForOptions={handleFilterdataForOptions}
        selectedFolderId={
          uaserPayloadForGroupAndProject &&
          uaserPayloadForGroupAndProject.folderId
        }
      />
      <OcrConfigurationGAndUCreateGroupOrUser
        t={t}
        open={isCreateGroupOrUserEnable}
        onClose={handleCloseofGroupOrUserEnable}
        subscriptionUserListLoading={userListLoading}
        subscriptionUserData={subscriptionUserData}
        handleSubmitOfAllOcrGroupAction={handleSubmitOfAllOcrGroupAction}
        createOcrGroupLoading={createOcrGroupLoading}
        editOcrGroupLoading={editOcrGroupLoading}
        deleteOcrGroupLoading={deleteOcrGroupLoading}
        groupListLoading={groupListLoading}
        ocrGroupData={ocrGroupData}
        selecteIdForDelete={selecteIdForDelete}
        assignType={assignType}
        isaddUserToGroupElementEnable={isaddUserToGroupElementEnable}
        groupSingleDataLoading={groupSingleDataLoading}
        singleGroupData={groupSingleData}
        ocrAssignAndAddActions={ocrAssignAndAddActions}
        assignFolderToUserLoading={assignFolderToUserLoading}
        usersDataByFolderId={usersDataByFolderId}
        getUsesrsHaveFolderAccessLoading={getUsesrsHaveFolderAccessLoading}
      />
    </>
  );
};
export default withTranslation()(React.memo(IntelligentSaleTracker));
