import { gql } from 'apollo-boost';

/*
  Create sub admin salesTrackercreateSubAdmin
 */
export const CREATE_SUB_ADMIN = gql`
  mutation CreateSubAdmin(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $role: String
    $password: String
    $countryId: String
    $orgId: String
    $subscriptionId: String
  ) {
    createSubAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
      password: $password
      countryId: $countryId
      orgId: $orgId
      subscriptionId: $subscriptionId
    ) {
      email
    }
  }
`;


export const SALES_TRACKER_CREATE_SUB_ADMIN = gql`
  mutation SalesTrackercreateSubAdmin(
    $firstName: String
    $lastName: String
    $distributor: String
    $email: String
    $phone: String
    $role: String
    $password: String
    $countryId: String
    $orgId: String
    $subscriptionId: String
  ) {
    salesTrackercreateSubAdmin(
      firstName: $firstName
      lastName: $lastName
      distributor: $distributor
      email: $email
      phone: $phone
      role: $role
      password: $password
      countryId: $countryId
      orgId: $orgId
      subscriptionId: $subscriptionId
    ) {
      email
    }
  }
`;

/*
  Update a user
 */
export const UPDATE_SUB_ADMIN = gql`
  mutation UpdateUser(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $phone: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
    ) {
      email
    }
  }
`;

/*
  Delete a user
*/

export const DELETE_USERS = gql`
  mutation DeleteUser($id: String) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UpdateUser(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $phone: String
    $countryId: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
      countryId: $countryId
    ) {
      id
    }
  }
`;
