import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
const circuitType = [
    {
        id: 'Point to Point Type',
        name: 'Point to Point Type'
    }, {
        id: 'Linear Type',
        name: 'Linear Type'
    }, {
        id: 'Ring Type',
        name: 'Ring Type'
    }
];
const serviceType = [
    {
        id: 'Protected',
        name: 'Protected'
    }, {
        id: 'Unprotected',
        name: 'Unprotected'
    }
];
const cityType = [
    {
        id: 'Intra City',
        name: 'Intra City'
    }, {
        id: 'Inter City',
        name: 'Inter City'
    }
];
const locationDetails = [
    {
        id: 'Gaborone',
        name: 'Gaborone'
    }, {
        id: 'Francistown',
        name: 'Francistown'
    }, {
        id: 'Others',
        name: 'Others'
    }
];
const requestFor = [
    {
        id: 'New',
        name: 'New'
    }, {
        id: 'Upgrade',
        name: 'Upgrade'
    }, {
        id: 'Downgrade',
        name: 'Downgrade'
    }, {
        id: 'Cease/ Disconnect',
        name: 'Cease/ Disconnect'
    }
];
const contractTenure = [
    {
        id: '12 months',
        name: '12 months'
    }, {
        id: '24 months',
        name: '24 months'
    }, {
        id: '36 months',
        name: '36 months'
    }, {
        id: '48 months',
        name: '48 months'
    }, {
        id: '60 months',
        name: '60 months'
    }
];
const billingMethod = [
    {
        id: 'Advance Payment',
        name: 'Advance Payment'
    }, {
        id: 'Post Paid',
        name: 'Post Paid'
    }
];
const billingFrequency = [
    {
        id: 'Monthly',
        name: 'Monthly'
    }, {
        id: 'Quarterly',
        name: 'Quarterly'
    }, {
        id: 'Bi Annually',
        name: 'Bi Annually'
    }
];
const invoicingMethod = [
    {
        id: 'Electronic',
        name: 'Electronic'
    }, {
        id: 'Manual',
        name: 'Manual'
    }
];
const paymentTerm = [
    {
        id: '15 days',
        name: '15 days'
    }, {
        id: '21 days',
        name: '21 days'
    }, {
        id: '30 days',
        name: '30 days'
    }
];
const paymentMethod = [
    {
        id: 'Fund Transfer',
        name: 'Fund Transfer'
    }, {
        id: 'Cheque',
        name: 'Cheque'
    }, {
        id: 'Cash',
        name: 'Cash'
    }
];

function CreateOrderModal({
                              t,
                              open,
                              handleCreateOrder,
                              handleClose,
                              opportunityDetail,
                              data,
                              modalState
                          }) {
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} Order
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will generate the order form pdf and email it to Bofinet.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        to_email: "",
                        circuitType: "",
                        serviceType: "",
                        cityType: "",
                        location: "",
                        NllsLDOther: "",
                        requestFor: opportunityDetail.requestFor,
                        contractTenure: opportunityDetail.contractTenure,
                        currentBandwidth: "",
                        requiredBandwidth: "",
                        NllsLaContactPN: opportunityDetail.NllsLaContactPN,
                        NllsLaContactNum: "",
                        NllsLaContactemail: "",
                        NllsLaFaxNum: "",
                        NllsLbContactPN: opportunityDetail.NllsLbContactPN,
                        NllsLbContactNum: "",
                        NllsLbContactemail: "",
                        NllsLbFaxNum: "",
                        NllsLbCompname: opportunityDetail.NllsLbCompname,
                        NllsLbPhyadd: opportunityDetail.NllsLbPhyadd,
                        NllsLbCity: opportunityDetail.NllsLbCity,
                        OrderId: "",
                        BearerName: opportunityDetail.bearer_name,
                        NllsSpeed: opportunityDetail.NllsSpeed,
                        CipPc: "NLL",
                        CipIf: "",
                        CipMrc: "",
                        CipBmPp: "",
                        billingMethod: "",
                        billingFrequency: "",
                        invoicingMethod: "",
                        paymentTerm: "",
                        paymentMethod: ""
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        console.log('values', JSON.stringify(values))
                        return
                        handleCreateOrder(values)
                        resetForm()
                        //alert(JSON.stringify(values));
                    }}
                    validationSchema={Yup.object().shape({
                        to_email: Yup.string()
                            .email()
                            .max(255, 'Email must be 255 character!')
                            .required('Please Enter Email'),
                        BearerName: Yup.string()
                            .required('Please Select Bearer Name'),
                        circuitType: Yup.string()
                            .required('Please Select Circuit Type'),
                        serviceType: Yup.string()
                            .required('Please Select Service Type'),
                        cityType: Yup.string()
                            .required('Please Select City Type'),
                        location: Yup.string()
                            .required('Please Select location'),
                        requestFor: Yup.string()
                            .required('Please Enter Request For'),
                        contractTenure: Yup.string()
                            .required('Please Select Contract Tenure'),
                        NllsSpeed: Yup.string()
                            .required('Please Enter Speed'),
                        currentBandwidth: Yup.string()
                            .max(50, 'Current Bandwidth must be 50 character!'),
                        requiredBandwidth: Yup.string()
                            .max(50, 'Required Bandwidth must be 50 character!'),
                        NllsLaContactPN: Yup.string()
                            .max(50, 'Location A Contact Person must be 50 character!')
                            .required('Please Enter Location A Contact Person'),
                        NllsLaContactNum: Yup.string()
                            .required('Please Enter Location A Contact Number'),
                        NllsLaContactemail: Yup.string()
                            .email()
                            .required('Please Enter Location A Contact Email'),
                        NllsLaFaxNum: Yup.string()
                            .required('Please Enter Location A Contact Fax'),
                        NllsLbContactPN: Yup.string()
                            .max(50, 'Location B Contact Person must be 50 character!')
                            .required('Please Enter Location B Contact Person'),
                        NllsLbContactNum: Yup.string()
                            .max(50, 'Location B Contact Number must be 50 character!')
                            .required('Please Enter Location B Contact Number'),
                        NllsLbContactemail: Yup.string()
                            .email()
                            .max(100, 'Location B Contact Email must be 100 character!')
                            .required('Please Enter Location B Contact Email'),
                        NllsLbFaxNum: Yup.string()
                            .max(10, 'Location B Contact Fax must be 12 character!')
                            .required('Please Enter Location B Contact Fax'),
                        NllsLbCompname: Yup.string()
                            .max(50, 'Location B Company Name must be 50 character!')
                            .required('Please Enter Location B Company Name'),
                        NllsLbPhyadd: Yup.string()
                            .max(50, 'Location B Physical Address must be 255 character!')
                            .required('Please Enter Location B Physical Address'),
                        NllsLbCity: Yup.string()
                            .max(50, 'Location B city must be 50 character')
                            .required('Please Select Location B City'),
                        CipPc: Yup.string()
                            .required('Please Enter Invoice Product Code'),
                        CipIf: Yup.string()
                            .required('Please Enter Invoice Installation Fee'),
                        CipMrc: Yup.string()
                            .required('Please Enter Invoice Monthly Charge'),
                        billingMethod: Yup.string()
                            .required('Please Select Billing Method'),
                        billingFrequency: Yup.string()
                            .required('Please Select Billing Frequency'),
                        invoicingMethod: Yup.string()
                            .required('Please Select Invoicing Method'),
                        paymentTerm: Yup.string()
                            .required('Please Select Payment Term'),
                        paymentMethod: Yup.string()
                            .required('Please Select Payment Method'),
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.to_email}
                                                    name={'to_email'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.to_email && touched.to_email) && errors.to_email}
                                                    label={`${t('toEmail.label')} *`}
                                                    placeholder={`Multiple emails could be entered using comma separated values.`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.BearerName}
                                                    name={'BearerName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.BearerName && touched.BearerName) && errors.BearerName}
                                                    label={`${t('BearerName.label')} *`}
                                                    placeholder={`${t('BearerName.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.circuitType}
                                                    label={`${t('circuitType.label')} *`}
                                                    name={'circuitType'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={circuitType}
                                                />
                                                {(errors.circuitType && touched.circuitType) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.circuitType}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.serviceType}
                                                    label={`${t('serviceType.label')} *`}
                                                    name={'serviceType'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={serviceType ? serviceType : []}
                                                />
                                                {(errors.serviceType && touched.serviceType) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.serviceType}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.cityType}
                                                    label={`${t('cityType.label')} *`}
                                                    name={'cityType'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={cityType ? cityType : []}
                                                />
                                                {(errors.cityType && touched.cityType) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.cityType}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.location}
                                                    label={`${t('location.label')} *`}
                                                    name={'location'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={locationDetails ? locationDetails : []}
                                                />
                                                {(errors.location && touched.location) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.location}</FormHelperText>
                                                }
                                            </Grid>
                                            {values.location === 'Others' ?
                                                <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                    <TextField
                                                        value={values.NllsLDOther}
                                                        label={`${t('NllsLDOther.label')}*`}
                                                        placeholder={`${t('NllsLDOther.label')}*`}
                                                        style={{width: '100%'}}
                                                        color={'secondary'}
                                                        name={'NllsLDOther'}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={(errors.NllsLDOther && touched.NllsLDOther) && errors.NllsLDOther}
                                                    />
                                                </Grid> : ''}
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.requestFor}
                                                    label={`${t('requestFor.label')} *`}
                                                    name={'requestFor'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={requestFor ? requestFor : []}
                                                />
                                                {(errors.requestFor && touched.requestFor) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.requestFor}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-40')}>
                                                <OutlinedSelect
                                                    val={values.contractTenure}
                                                    label={`${t('contractTenure.label')} *`}
                                                    name={'contractTenure'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={contractTenure ? contractTenure : []}
                                                />
                                                {(errors.contractTenure && touched.contractTenure) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.contractTenure}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12} style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('capacitySpeed.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.NllsSpeed}
                                                    label={`${t('forNewRequest.label')}*`}
                                                    placeholder={`${t('forNewRequest.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsSpeed'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsSpeed && touched.NllsSpeed) && errors.NllsSpeed}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.currentBandwidth}
                                                    label={`${t('currentBandwidth.label')}`}
                                                    placeholder={`${t('currentBandwidth.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'currentBandwidth'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.currentBandwidth && touched.currentBandwidth) && errors.currentBandwidth}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.requiredBandwidth}
                                                    label={`${t('requiredBandwidth.label')}`}
                                                    placeholder={`${t('requiredBandwidth.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'requiredBandwidth'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.requiredBandwidth && touched.requiredBandwidth) && errors.requiredBandwidth}
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12} style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('locationA.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLaContactPN}
                                                    label={`${t('NllsLaContactPN.label')}*`}
                                                    placeholder={`${t('NllsLaContactPN.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLaContactPN'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLaContactPN && touched.NllsLaContactPN) && errors.NllsLaContactPN}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLaContactNum}
                                                    label={`${t('NllsLaContactNum.label')}*`}
                                                    placeholder={`${t('NllsLaContactNum.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLaContactNum'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLaContactNum && touched.NllsLaContactNum) && errors.NllsLaContactNum}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLaContactemail}
                                                    label={`${t('NllsLaContactemail.label')}*`}
                                                    placeholder={`${t('NllsLaContactemail.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLaContactemail'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLaContactemail && touched.NllsLaContactemail) && errors.NllsLaContactemail}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLaFaxNum}
                                                    label={`${t('NllsLaFaxNum.label')}*`}
                                                    placeholder={`${t('NllsLaFaxNum.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLaFaxNum'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLaFaxNum && touched.NllsLaFaxNum) && errors.NllsLaFaxNum}
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12} style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('locationB.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLbContactPN}
                                                    label={`${t('NllsLbContactPN.label')}*`}
                                                    placeholder={`${t('NllsLbContactPN.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbContactPN'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbContactPN && touched.NllsLbContactPN) && errors.NllsLbContactPN}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLbContactNum}
                                                    label={`${t('NllsLbContactNum.label')}*`}
                                                    placeholder={`${t('NllsLbContactNum.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbContactNum'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbContactNum && touched.NllsLbContactNum) && errors.NllsLbContactNum}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLbContactemail}
                                                    label={`${t('NllsLbContactemail.label')}*`}
                                                    placeholder={`${t('NllsLbContactemail.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbContactemail'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbContactemail && touched.NllsLbContactemail) && errors.NllsLbContactemail}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.NllsLbFaxNum}
                                                    label={`${t('NllsLbFaxNum.label')}*`}
                                                    placeholder={`${t('NllsLbFaxNum.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbFaxNum'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbFaxNum && touched.NllsLbFaxNum) && errors.NllsLbFaxNum}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.NllsLbCompname}
                                                    label={`${t('NllsLbCompname.label')}*`}
                                                    placeholder={`${t('NllsLbCompname.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbCompname'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbCompname && touched.NllsLbCompname) && errors.NllsLbCompname}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.NllsLbPhyadd}
                                                    label={`${t('NllsLbPhyadd.label')}*`}
                                                    placeholder={`${t('NllsLbPhyadd.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbPhyadd'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbPhyadd && touched.NllsLbPhyadd) && errors.NllsLbPhyadd}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.NllsLbCity}
                                                    label={`${t('NllsLbCity.label')}*`}
                                                    placeholder={`${t('NllsLbCity.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'NllsLbCity'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NllsLbCity && touched.NllsLbCity) && errors.NllsLbCity}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                    xs={12} style={{fontSize: '20px', textDecoration: 'underline'}}>
                                                    {t('invoice.label')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.CipPc}
                                                    label={`${t('CipPc.label')}*`}
                                                    placeholder={`${t('CipPc.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'CipPc'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.CipPc && touched.CipPc) && errors.CipPc}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.CipIf}
                                                    label={`${t('CipIf.label')}*`}
                                                    placeholder={`Installation Fees NRC`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'CipIf'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.CipIf && touched.CipIf) && errors.CipIf}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.CipMrc}
                                                    label={`${t('CipMrc.label')}*`}
                                                    placeholder={`Monthly Charges (MRC)`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    name={'CipMrc'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.CipMrc && touched.CipMrc) && errors.CipMrc}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.billingMethod}
                                                    label={`${t('billingMethod.label')} *`}
                                                    name={'billingMethod'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={billingMethod ? billingMethod : []}
                                                />
                                                {(errors.billingMethod && touched.billingMethod) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.billingMethod}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.billingFrequency}
                                                    label={`${t('billingFrequency.label')} *`}
                                                    name={'billingFrequency'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={billingFrequency ? billingFrequency : []}
                                                />
                                                {(errors.billingFrequency && touched.billingFrequency) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.billingFrequency}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.invoicingMethod}
                                                    label={`${t('invoicingMethod.label')} *`}
                                                    name={'invoicingMethod'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={invoicingMethod ? invoicingMethod : []}
                                                />
                                                {(errors.invoicingMethod && touched.invoicingMethod) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.invoicingMethod}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.paymentTerm}
                                                    label={`${t('paymentTerm.label')} *`}
                                                    name={'paymentTerm'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={paymentTerm}
                                                />
                                                {(errors.paymentTerm && touched.paymentTerm) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.paymentTerm}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                                <OutlinedSelect
                                                    val={values.paymentMethod}
                                                    label={`${t('paymentMethod.label')} *`}
                                                    name={'paymentMethod'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={paymentMethod}
                                                />
                                                {(errors.paymentMethod && touched.paymentMethod) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.paymentMethod}</FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

CreateOrderModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateOrderModal);
