import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateProcurementTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    handleUploadFiles,
    customerLoading,
    PreValuesOfForm
}) {
    const classes = useStyles();
    const [Approval, setApproval] = useState('');
    const [Comment, setComment] = useState('');
    const [PettyCashier, setPettyCashier] = useState('');
    const [PettyCashierUid, setPettyCashierUid] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [Documents, setDocuments] = useState([]);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setApproval('');
            setComment('');
            setPettyCashier('');
            setPettyCashierUid('');
            setNextTeamMemberEmail('');
            setDocuments([]);
        }
    }, [modalState, allUserList, customerLoading]);

    const handleDocuments = (index) => {
        setDocuments(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }
    const ApprovalList = [
        {
            id: t("approved.label"),
            name: t("approved.label")
        },
        {
            id: t("rejected.label"),
            name: t("rejected.label")
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('procurementTeam.label')} Section</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('approval.label')}`}
                                handleChange={evt => {
                                    setApproval(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={ApprovalList ? ApprovalList : []}
                            />
                        </Grid> */}
                        <Typography variant="subtitle2">{t('review.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={Comment}
                                type={'text'}
                                placeholder={t('review.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={Comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                        {/* <Typography variant="subtitle2">{t('pettyCashier.label')}*</Typography> */}
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {/* <Autocomplete
                                id="country-select-demo"
                                val={PettyCashier}
                                options={allUserList ? allUserList.getAllUserByOnlyCountyID : []}
                                getOptionLabel={option =>
                                    `${option.firstName ? option.firstName : ""} ${option.lastName ? option.lastName : ""}`
                                }
                                onChange={(event, newValue) => {
                                    if (newValue == null) {
                                        setPettyCashier('');
                                        setPettyCashierUid('');
                                        setNextTeamMemberEmail('');
                                    }
                                    let fullName = newValue.firstName + ' ' + newValue.lastName;
                                    setPettyCashier(fullName);
                                    setPettyCashierUid(newValue.id);
                                    setNextTeamMemberEmail(newValue.email);
                                }}
                                loading={customerLoading}
                                autoHighlight
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ebebeb !important'
                                        }}
                                        {...params}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {customerLoading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            /> */}
                            <OutlinedSelect
                                label={`${t('pettyCashier.label')} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setPettyCashier(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setPettyCashier(allUserList[i].name);
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                setPettyCashierUid(allUserList[i].uid);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attachAnyOtherDoc.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={Documents && Documents.length >= 3 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && Documents.length < 3) {
                                        if (evt.target.files.length <= 3 && (Documents.length + evt.target.files.length) <= 3) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setDocuments(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 3. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {Documents && Documents.length ?
                                Documents.map((item, index) => (
                                    <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleDocuments(index)} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!Comment || !PettyCashier || !PettyCashierUid || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let getDocuments = [];
                            if (Documents != [] && Documents != null && Documents.length > 0) {
                                for (let i = 0; i < Documents.length; i++) {
                                    let getAttachDoc = await handleUploadFiles(Documents[i], PreValuesOfForm.work_flow.uid, 'im_team_section');
                                    if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                        getDocuments.push(getAttachDoc.data.allUploadFile.url);
                                    }
                                    else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                            }
                            handleSubmitClick({
                                approval: '',
                                comment: Comment,
                                petty_cashier_team: PettyCashier,
                                petty_cashier_uid: PettyCashierUid,
                                next_team_member_email: nextTeamMemberEmail,
                                documents: getDocuments,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateProcurementTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateProcurementTeamModal);
