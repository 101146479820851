import { gql } from 'apollo-boost';

export const MTTR_REPORT_BY_COUNTRIES = gql`
  query AllCountriesMttrReportList($monthYear: String) {
    allCountriesMttrReportList(monthYear: $monthYear) {
        countryName
        countryId
        orgName
        orgId 
        restoreTimeMinutes
        totalIncident
    }
  }
`;