import React from 'react';
import Table from '@material-ui/core/Table';
import {CircularProgress, TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {dateFormatterLLL} from '../../../../utils';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import commonStyles from '../../../../theme/commonStyles';
import MenuWrapper from '../../../../components/MenuWrapper';
import {useState} from 'react';
import ControllerStatusPanel from '../ControllerStatusPanel';
import Vnfitems from "./VnfItems";
import UcpeItems from "../UcpePanel/UcpeItems";

function VNFPanel({
                      t,
                      menuItems,
                      list,
                      handleCreateButtonClick,
                      handleMenuItemClick,
                      loading,
                      error,
                      filterList
                  }) {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const columns = [
        {
            label: t('vnfName.label'),
            key: 'vnfName'
        },
        {
            label: t('uCPEName.label'),
            key: 'uCPE'
        },
        {
            label: t('vnfTemplates.label'),
            key: 'vnfTemplates'
        },
        {
            label: t('createdAt.label'),
            key: 'createdAt'
        },
        {
            label: t('createdBy.label'),
            key: 'createdBy'
        },
        {
            label: t('status.label'),
            key: 'vnfStatus'
        },
        {
            label: t('launchWebUI.label'),
            key: 'launchWebUI'
        },
        {
            label: t('launchSSH.label'),
            key: 'launchSSH'
        },
        {
            label: t('edit.label') + '/' + t('delete.label'),
            key: 'button'
        }
    ];
    if (!loading && !error) {
        let getVnf = list.getVnfs && list.getVnfs.VnfData ? JSON.parse(list.getVnfs.VnfData) : [];
        list = getVnf && getVnf.data.length ? getVnf : {data: []};
    }
    return (
        <div>
            {filterList &&
            filterList.map((filter, index) => (
                <OutlinedSelect
                    key={index}
                    val={filter.val}
                    label={filter.label}
                    handleChange={filter.handleChange}
                    IconComponent={filter.iconComponent || null}
                    options={filter.options}
                    styleOverrides={filter.styleOverrides || null}
                    disabled={filter.disabled || false}
                />
            ))}
            <Grid
                style={{display: 'inline-flex'}}>
                <Typography variant={'subtitle2'}>{list && list.filtered_vnf ? list.filtered_vnf + ' of ' + list.total_vnf : <CircularProgress size={14}/>}</Typography>
            </Grid>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), float: 'right'}}>
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('vnf_modal')}>
                    {t('createNew.label')}
                </Button>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ? (
                                list && list.data.length > 0 ? (
                                    list.data.map((vnf, rowIndex) => (
                                        <Vnfitems
                                            key={rowIndex}
                                            t={t}
                                            vnf={vnf}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
VNFPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default VNFPanel;
