import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Message from '../../../../../components/Message';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginBottom:'10px',
        cursor:'pointer'
      },
      selectedCard: {
          border: '1px solid #5a1f2a',
          background: '#5a1f2a',
          borderRadius: '2px'
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
        color: '#000',
      },
      controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      playIcon: {
        height: 38,
        width: 38,
      },
      iconForTemplate: {
          height: '25px',
          marginRight:'5px'
        },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const GsmSwitchTypes = ({
    t,
    open,
    handleClose,
    gsmTypes,
    handleSubmitGSMType
}) => {
    const classes = useStyles();
    const [selectedType, setSelectedType] = React.useState('');
    
    return (
        <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth={'md'} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">{t('gsmType.label')}</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                {gsmTypes.length && gsmTypes.map((item, idx) => {
                    return (
                    <Grid item xs={6} key={idx}>
                        <input style={{display:'none'}} id={idx} type='radio' name={'type'} value={item.id} data-name={item.name} className={'aiRadio'} onClick={evt => {
                            setSelectedType({id:evt.target.value, name: evt.target.getAttribute('data-name')});
                        }}/>
                        <label for={idx} style={{cursor:'pointer'}}>
                            <Card className={classes.root, (selectedType && selectedType.id === item.id) ? classes.selectedCard : null}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography variant="subtitle2" color="primary" style={selectedType && selectedType.id === item.id ? {color: '#fff'} : null}>
                                            {/* <img className={classes.iconForTemplate} src={item.icon ? item.icon : "../../../assets/icons/human.png"} alt={""} /> */}
                                            {item.name}
                                        </Typography>
                                    </CardContent>
                                </div>
                            </Card>
                        </label>
                    </Grid>);
                })}
            </Grid>
        </DialogContent>
        <DialogActions>
        <Button
            variant={'outlined'}
            onClick={() => {
                handleClose();
            }}
        >
        {t('cancel.label')}
        </Button>
        <Button variant="contained" color="secondary" onClick={evt => {
            if(!selectedType){
                Message.error('Please select gsm type!');
                return null;
            }
            handleSubmitGSMType(selectedType);
            handleClose();
        }}>
            {t('submit.label')}
        </Button>
        </DialogActions>
        </Dialog>
    )
}

export default GsmSwitchTypes
