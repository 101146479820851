import React from 'react';
import { marginGenerator } from '../../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import TicketItem from '../TicketItem';
import PropTypes from 'prop-types';

const TicketPanel = ({ t, ticketList, type, handleTicketItemClick }) => {
  return (
    <div>
      {ticketList.length ? (
        <Typography
          variant={'subtitle2'}
          style={{
            ...marginGenerator('mb-32')
          }}>
          {t('showing.label')} {ticketList.length}{' '}
          {t('suggestions.label').toLowerCase()}
        </Typography>
      ) : (
        ''
      )}
      {ticketList.map((item, index) => (
        <TicketItem
          key={index}
          data={item}
          type={type}
          handleTicketItemClick={handleTicketItemClick}
        />
      ))}
    </div>
  );
};

TicketPanel.propTypes = {
  ticketList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired
};

export default TicketPanel;
