import { gql } from 'apollo-boost';

/*
  Get Sub Users list
 */
export const GET_COUNTRIES = gql`
  query Countries {
    getCountries {
      id
      name
      code
      emails
      status
      defaultLang
      createdAt
      addressLine1
      addressLine2
    }
  }
`;