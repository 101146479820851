import React, { Fragment } from 'react';
import {Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    padding: 5,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  },
  header:{
    textAlign:'center',
    fontSize: 15,
    marginTop: 20,
  },
  subHeading:{
    textAlign:'center',
    fontSize: 20,
    marginTop: 20,
    paddingTop: 20
  },
  heading:{
    textAlign:'center',
    fontSize: 20,
    fontWeight: 'heavy',
    paddingTop: 50,
    paddingBottom: 50
  },
});
const InvoiceFrontPage = ({time, org, country}) => {
let date = new Date(time);
let longMonth = date.toLocaleString('en-us', { month: 'long' });
let year = date ? date.getFullYear() : ""
year = isNaN(year) ? "" : year
longMonth = longMonth == 'Invalid Date' ? "" : longMonth
return(
  <Fragment>
      <View style={{border:"1 solid black"}}>
        <View style={styles.header}>
          <Text>Inq. Digital {country} Report</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.heading}>
          <Text>{` ${org} ${longMonth} ${year} `} </Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.subHeading}>
          <Text>Monthly Status Report Operations and Maintenance</Text>
        </View>
      </View>
  </Fragment>
);
}
export default InvoiceFrontPage;
