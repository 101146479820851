import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../theme/commonStyles';
import clsx from 'clsx';
import { marginGenerator } from '../../theme/utils';

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarIdentifier: {
    fontSize: '10px',
    lineHeight: 1.2,
    letterSpacing: '0.09px',
    color:'white'
  }
}));

function Avatar({ name, variant, avatarStyles, identifierStyle }) {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <div
      className={clsx(
        variant === 'medium'
          ? commonClasses.iconMedium
          : commonClasses.iconRegular,
        classes.avatarContainer
      )}
      style={{ ...marginGenerator('mr-8'), ...avatarStyles }}>
      <Typography className={classes.avatarIdentifier} style={identifierStyle}>
        {name.split('')[0]}
      </Typography>
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default Avatar;
