import React, {useState, useEffect} from 'react';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModemIssuePanelItem from './ModemIssuePanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';

import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import ModemIsuueForm from './ModemIsuueForm';

import Message from '../../../../../../components/Message';
import {
    GET_MODEM_ISSUE_REQUESTS,
    GET_SECTION_LIST
} from '../../queries';
import { CREATE_MODEM_ISSUE_REQUEST, DELETE_MODEM } from '../../mutation';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const ModemIsuuePanel = ({
    t,
    allowConfigure,
    data,
    users,
    departments
}) => {

    const classes = useStyles();

    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [briefModalOpen, setBriefModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({userSign: "", infoSign: ""});
    const [selectedUserItem, setSelectedUserItem] = useState(null);

    const subscriptionId = data.id;

    const [getAllModemIssuesRequests, {
        loading,
        data: modemIssuesRequestList
    }] = useLazyQuery(GET_MODEM_ISSUE_REQUESTS, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllModemIssuesRequests({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllModemIssuesRequests({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);
    
    const [createRemoteAccessRequest] = useMutation(CREATE_MODEM_ISSUE_REQUEST, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Modem request created successfully');
            setBriefModalOpen(false);
            return getAllModemIssuesRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteModem, { loading: deleteModemLoading }] = useMutation(DELETE_MODEM, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Modem deleted successfully');
            return getAllModemIssuesRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createRemoteAccessRequest({ variables: {
            ...signatureState,
            ...formData
        }});
    }


    const {
        loading: sectionLoading,
        data: sectionList,
        error: sectionError,
        refetch: reloadSections
    } = useQuery(GET_SECTION_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            subscriptionId: subscriptionId
        }
    });

    const columns = [
        {
            label: 'User Name',
            key: "userName"
        },
        {
            label: 'Issue Date',
            key: "date"
        },
        {
            label: 'Model',
            key: "model"
        },
        {
            label: "Modem Colour",
            key: "modemColour"
        },
        // {
        //     label: 'Organizer',
        //     key: "organizer"
        // },
        // {
        //     label: 'Brief Notes',
        //     key: "briefNotes"
        // },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const meetingRequestList = [
        {
            name: "Onboarding",
            schedule: new Date(),
            status: "Scheduled",
            attendees: "red",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Intro",
            schedule: new Date(),
            status: "Scheduled",
            attendees: "black",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Marketing",
            schedule: new Date(),
            status: "Completed",
            attendees: "black",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Sales",
            schedule: new Date(),
            status: "Canceled",
            attendees: "red",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        }
    ];

    const handleNewMeetingRequest = () => {
        setBriefModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Section',
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, section: evt.target.value});
            },
            val: filterStateData.section,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: sectionList && sectionList.getAllSections ? sectionList.getAllSections.data :[]
        }
    ];

    const handleMenuItemClick = (type, rowItem) => {
        setSelectedUserItem(rowItem);
        if(type === "sign"){
            setSelectedUserItem(rowItem);
           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
        setBriefModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteModem({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }
  return (
        <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewMeetingRequest}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !loading ? modemIssuesRequestList && modemIssuesRequestList.getAllModemIssueRequests
                            && modemIssuesRequestList.getAllModemIssueRequests
                            .data.length > 0 ?
                            modemIssuesRequestList.getAllModemIssueRequests
                            .data.map((rowitem, rowIndex) => (
                                    <ModemIssuePanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow> : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <ModemIsuueForm
                t={t}
                open={briefModalOpen}
                handleClose={() => setBriefModalOpen(false)}
                handleOpenSignPanModal={handleOpenSignPanModal}
                data={selectedUserItem}
                signatures={signatureState}
                handleCreateRequestSubmit={handleCreateRequestSubmit}
                users={users}
                departments={departments}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    console.log("image: ", image);
                    setSignaturePadModalOpen(false);
                }}
            />
    </div>
  )
}

export default ModemIsuuePanel