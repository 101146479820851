import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

function DeleteConfirmBox({t, open, type, handleClose, handleDelete, loading}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Delete " + type}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('deleteConfirmContent.label')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                    onClick={() => {
                        handleClose();
                    }} 
                    color="secondary"
                    variant={"contained"}
                    >
                        {t('cancel.label')}
                    </Button>
                    <Button 
                    variant={"contained"}
                    disabled={loading}
                    onClick={() => {
                        handleDelete();
                    }} 
                    color="primary" 
                    autoFocus>
                        {loading ? <CircularProgress size={14}/> : t('delete.label')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
DeleteConfirmBox.propTypes = {
    t: PropTypes.func.isRequired,
    handleClose: PropTypes.object.isRequired,
    handleOK: PropTypes.object.isRequired,
};
export default withTranslation()(DeleteConfirmBox);
