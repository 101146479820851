import React from 'react'
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Statcard from '../../../../../components/Statcard';
import DashbordWordCollection from './DashbordWordCollection';
import OcrDashBoardMapAndDetails from './OcrDashBoardMapAndDetails';

const OcrDashBoardPage = ({
    t,
    dashboardPanelData,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    loadingOcrFolder,
    handleFilterdataForOptions,
    ocrDashboardMapLoading,
    ocrDashboardMapData,
    handleFilterForDashboardMap
}) => {
    const  { number_of_folders, number_of_places, number_of_projects} = !ocrDashboardMapLoading && get(ocrDashboardMapData, 'dasboardAllData', {});
    
    return (
        <>
            <Grid container style={{marginTop: 35, marginBottom: 20}}>
                <OcrDashBoardMapAndDetails
                    t={t}
                    ocrPlaceData={ocrPlaceData}
                    ocrProjectData={ocrProjectData}
                    ocrFolderData={ocrFolderData}
                    loadingOcrPlace={loadingOcrPlace}
                    loadingOcrProject={loadingOcrProject}
                    loadingOcrFolder={loadingOcrFolder}
                    handleFilterdataForOptions={handleFilterdataForOptions}
                    ocrDashboardMapLoading={ocrDashboardMapLoading}
                    ocrDashboardMapData={ocrDashboardMapData}
                    handleFilterForDashboardMap={handleFilterForDashboardMap}
                />
            </Grid>
            <Grid container style={{marginTop: 30, marginBottom: 20}}>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Location`}
                        statValue={number_of_places}
                        customStyles={{width: 180, padding: "8px 16px"}}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Project`}
                        statValue={number_of_projects}
                        customStyles={{width: 180}}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Folders`}
                        statValue={number_of_folders}
                        customStyles={{width: 180}}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Docs`}
                        statValue={dashboardPanelData.number_of_doc}
                        customStyles={{width: 180, padding: "8px 16px"}}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Rules`}
                        statValue={dashboardPanelData.number_of_rules}
                        customStyles={{width: 180}}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Labels`}
                        statValue={dashboardPanelData.label_count}
                        customStyles={{width: 180}}
                    />
                </Grid>
            </Grid>
            <Grid container style={{marginBottom: 20}}>
               <Grid item xs="12">
                  <DashbordWordCollection t={t} words={dashboardPanelData && dashboardPanelData.word_map} />
                </Grid>
            </Grid>   
        </>
    )
}

export default OcrDashBoardPage
