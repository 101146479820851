import React, {useState, useEffect} from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableFooter from './InvoiceTableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
    }
});
const InvoiceItemsTable = ({invoice, t}) => {
    const [capacityState, setCameraState] = useState([]);
    const [addonsState, setAddonsState] = useState([]);
    console.log('invoice', invoice);
    useEffect(() => {
        if (invoice.capacityState) {
            let tmpObj = {
                description: t("capacity.label"),
                qty: invoice.capacityState.number,
                rate: invoice.capacityState.perCamCost,
                discount: invoice.capacityState.freeTierDiscount,
            };
            setCameraState([tmpObj]);
        }
    }, [invoice.capacityState]);
    useEffect(() => {
        if (invoice.addonsState && invoice.addonsState.length) {
            let addons = invoice.addonsState.map(item => {
                return {
                    description: item.moduleName,
                    qty: item.number,
                    rate: item.costPerMonth,
                    discount: item.freeTierDiscount
                };
            });
            setAddonsState(addons);
        }
    }, [invoice.notificationState]);
    return (
        <View style={styles.tableContainer}>
            <InvoiceTableHeader row={{title: t("capacity.label"), head1: t("number.label"), head2: t("mrcCost.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}}/>
            <InvoiceTableRow items={capacityState} currency={invoice.currency ? invoice.currency.symbol : "$"}/>
            <InvoiceTableHeader row={{title: t("addOns.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}}/>
            <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol : "$"} items={addonsState}/>
            <InvoiceTableFooter currency={invoice.currency ? invoice.currency.symbol : "$"} total={invoice.totalState ? invoice.totalState : null}/>
        </View>
    )
}
export default InvoiceItemsTable
