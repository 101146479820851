import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../theme/colors';
import { marginGenerator } from '../../../../theme/utils';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  }
}));

function RoiModal({
    t,
    open,
    handleClose,
    roiCamera,
    imageSrc,
    roiButtonLoading,
    handleUpdateRoi,
    roiSubmitLoading
  }) {

  const [cameraModelRoi, setCameraModelRoi] = useState({});
  const classes = useStyles();

  React.useEffect(() => {
    if(roiCamera && roiCamera.schedules && roiCamera.schedules.length && roiCamera.schedules[0].length){
      let initRoi = {};
      for(let itm of roiCamera.schedules[0]){
        let roiVal = [];
        if(itm.roi && itm.roi instanceof Array){
          itm.roi.map(roiItm => {
            let tmpObj = {...roiItm};
            tmpObj.inverse_roi = (roiItm.inverse_roi && roiItm.inverse_roi === "True") ? "Yes" : "No";
            roiVal.push(tmpObj);
          });
        }else if(itm.rois && itm.rois instanceof Array){
          itm.rois.map(roiItm => {
            let tmpObj = {...roiItm};
            tmpObj.inverse_roi = (roiItm.inverse_roi && roiItm.inverse_roi === "True") ? "Yes" : "No";
            roiVal.push(tmpObj);
          });
        }else if(itm.roi){
          let tmpObj = {...itm.roi};
          tmpObj.inverse_roi = (itm.roi.inverse_roi && itm.roi.inverse_roi === "True") ? "Yes" : "No";
          roiVal.push(tmpObj);
        }
        initRoi[`${roiCamera.camera_id}_aim_${itm.model_template.id}`] = roiVal;
      }
      setCameraModelRoi(initRoi);
    }
  }, [roiCamera]);

  const updateRoiFromStorage = (coords, key) => {
    let lineData = JSON.parse(coords);
    setCameraModelRoi({...cameraModelRoi, [key]: lineData});
    localStorage.removeItem(key);
    localStorage.removeItem("roi_img_url");
  }

  window.addEventListener('storage', (event) => {
    if ( event.key.indexOf("_aim_") > -1 ){
      updateRoiFromStorage(event.newValue, event.key);
    }
  }, false);
  
  const handleChangeRoi = (evt, id) => {
    let newState = {...cameraModelRoi};
    newState[id][0].inverse_roi = evt.target.value;
    setCameraModelRoi(newState);
  }

  const handleDeleteRoi = (modelId) => {
    if(window.confirm("Are you sure? You want to delete ROI.")){
      setCameraModelRoi({...cameraModelRoi, [`${roiCamera.camera_id}_aim_${modelId}`]: null});
      return null;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose} 
      fullWidth={true}
      maxWidth={"md"}
      disableBackdropClick={true}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="form-dialog-title" color="primary">{t("roiModalHead.label")} {roiCamera && roiCamera.config && roiCamera.config.camData ? roiCamera.config.camData.name : ""}</DialogTitle>
      <DialogContent style={{padding: 15}}>
        {roiButtonLoading ? <div style={{display: "inline-block", textAlign: "center", width: "100%"}}><CircularProgress style={{marginTop: 20}} size={30} /> </div> : 
          roiCamera && roiCamera.schedules && roiCamera.schedules.length > 0 && roiCamera.schedules[0].length > 0 ?
          <List className={classes.aiParamList}>
            {roiCamera.schedules[0].map((model, indx) => {
              return (
                <ListItem key={indx}>
                  <Grid container spacing={1} style={{marginBottom: 16, borderBottom: "2px solid #d5d3d3", paddingBottom: 10}}>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" color={"primary"} style={{fontWeight:"bold"}}>{model.model_template ? model.model_template.name : "-"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`] && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length > 0) &&
                          cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].map(itm => (
                          <React.Fragment key={itm.x1}>
                            <Typography variant="subtitle1" style={{fontSize: 12}}>
                              [<b>X1 : </b> {itm.x1}
                              <b>&nbsp;, Y1 : </b> {itm.y1}
                              <b>&nbsp;, X2 : </b> {itm.x2}
                              <b>&nbsp;, Y2 : </b> {itm.y2} ]
                            </Typography>
                          </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={3}>
                      {(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`] && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length > 0) &&
                      <Typography variant="subtitle1">
                        {t("inverseRoi.label")}
                      </Typography>}
                      {(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`]  && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length > 0) && ["Yes", "No"].map((field, index) => {
                        return (
                            <Radio
                                key={index}
                                checked={field === cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`][0].inverse_roi}
                                classes={{ root: classes.iconButton }}
                                style={{
                                    ...marginGenerator('mr-16'),
                                    ...marginGenerator('mt-0'),
                                    ...(field === cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`][0].inverse_roi
                                    ? { backgroundColor: colors.secondary.main }
                                    : '')
                                }}
                                onChange={evt => handleChangeRoi(evt, `${roiCamera.camera_id}_aim_${model.model_template.id}`)}
                                value={field}
                                name="site-select"
                                checkedIcon={
                                    <div>
                                        <Typography
                                            variant={'subtitle2'}
                                            style={{ color: colors.common.white }}>
                                            {field}
                                        </Typography>
                                    </div>
                                }
                                icon={
                                <div className={classes.icon}>
                                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                                    {field}
                                    </Typography>
                                </div>
                                }
                            />
                        );
                      })}
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "right"}}>
                      <Button
                        onClick={evt => {
                          localStorage.setItem("roi_img_url", imageSrc);
                          if(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`] && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length){
                            localStorage.setItem("edit_roi", JSON.stringify(cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`]));
                          }else{
                            localStorage.removeItem("edit_roi");
                          }
                          window.open(`/getMultipleRoiAxis?action=${roiCamera.camera_id}_aim_${model.model_template.id}`, "_blank");
                        }}
                        size="small"
                        variant={'outlined'}
                        color="primary"
                        style={{marginRight: 10, padding: "8px 12px"}} >
                          {(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`] && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length) ? t("editRoi.label") : t("getRoi.label")}
                      </Button>
                      {(cameraModelRoi && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`] && cameraModelRoi[`${roiCamera.camera_id}_aim_${model.model_template.id}`].length > 0) &&
                      <IconButton onClick={evt => {
                        handleDeleteRoi(model.model_template.id);
                      }}
                      aria-label="delete">
                        <DeleteIcon color="secondary" />
                      </IconButton>}
                    </Grid>
                </Grid>
                </ListItem>
              )
            })}
          </List>
        : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button 
        disabled={!cameraModelRoi || roiButtonLoading || roiSubmitLoading} 
        color="primary" 
        variant={'contained'} 
        onClick={evt => {
          handleUpdateRoi(cameraModelRoi);
        }}> {roiSubmitLoading ? <CircularProgress size={16} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RoiModal;