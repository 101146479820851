import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({

    })
);

const reportFields = ['Line Manager Approval', 'Line manager Comment', 'Impact', 'Urgency', 'CAB Approval', 'CAB Comment', 'E-HOD', 'E-HOD Approval', 'E-HOD Comment', 'MD Approval']

function ChangeRequestReportModal({
    open,
    handleClose,
    handleSubmit
}) {
    const classes = useStyles();
    const [reportSelectedField, setReportSelectedField] = useState([])

    useEffect(() => {

    }, []);

    const handleSubmitData = () => {
        handleClose()
        handleSubmit(reportSelectedField)
        setReportSelectedField([])
    };

    const handleChange = (evt) => {
        setReportSelectedField((prevState) => {
            const newState = [...prevState]
            if (evt.target.checked) {
                newState.push(evt.target.value)
            } else {
                let index = newState.indexOf(evt.target.value)
                if (index !== -1) {
                    newState.splice(index, 1)
                }
            }
            return newState
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                className={classes.beg}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Select Field For Reporting
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                            <div className={classes.custmersStyle}>
                                <FormGroup row>
                                    {reportFields.length > 0 && reportFields.map((itm) => (
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{}}
                                                value={itm}
                                                label={itm}
                                                checked={reportSelectedField ? reportSelectedField.includes(itm) : false}
                                                control={<Checkbox style={{ border: '0px' }} />}
                                                labelPlacement="end"
                                                onChange={(evt) => {
                                                    handleChange(evt);
                                                }}
                                            />
                                        </Grid>

                                    ))}
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="default"
                        variant="contained"
                        size="small">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitData}
                        variant="contained"
                        color="primary"
                        size="small">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withTranslation()(ChangeRequestReportModal);
