import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Invoice from '../components/reports/Invoice';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

function DownloadPDFButton({ data, monthandyear, orgName,platform,circuitId,siteName, t }) {
  const [ready, setReady] = React.useState(false);
  const [formData,setformData] = useState()
  React.useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    setformData(data)
  }, [data]);

  return (
    <>
      {ready && data?.length && (
        <div style={{ float: 'right', marginRight: 17 }}>
        <PDFDownloadLink
  key={formData[0][0].id}
  document={
    <Invoice
      uniqueId={data[0][0].id}
      invoice={data}
      org={orgName}
      country={data[0][0].country}
      time={monthandyear}
      t={t}
      userInfo={data[0][0].customerData}
      platform={platform}
      circuitId={circuitId}
      siteName={siteName}
    />
  }
  fileName={`${orgName}-availability-report.pdf`}
>
  {({ loading, error }) => (
    loading ? (
      <CircularProgress color="inherit" size={10} />
    ) : error ? (
      <div>Error: {error.message}</div>
    ) : (
      <Button variant="outlined" color="primary">
        {t('download.label')}
      </Button>
    )
  )}
</PDFDownloadLink>

        </div>
      )}
    </>
  );
}

export default withTranslation()(DownloadPDFButton);
