import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  paper: { maxWidth: "800px" },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const DialogTitle = ((props) => {
    const { children, hanldeAddNew, classes, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {hanldeAddNew ? (
          <Button className={classes.closeButton} variant="outlined" onClick={hanldeAddNew}>Add New</Button>
        ) : null}
      </MuiDialogTitle>
    );
});
const initRegion = {name: "", code: ""};
function CreateCountryModal({
    t,
    open,
    data,
    handleSubmitClick,
    handleClose,
    modalState
}) {
    const classes = useStyles();
    const [regions, setRegions] = useState([initRegion]);
    
    React.useEffect(() => {
        if(modalState.clearData && open){
            setRegions([initRegion]);
            handleClose();
        }
    }, [modalState]);

    const handleChange = (val, index, type) => {
        setRegions((prevState) => {
            let newState = [...prevState];
            newState[index] = {...newState[index], [type]: val};
            return newState;
        });
    }

    const handleRemoveItem = (index) => {
        if(regions.length === 1) return null;
        setRegions((prevState) => {
            let newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    }
    
    return (
        <Dialog
            classes={{ paper: classes.paper}}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle 
            id="country-title" 
            classes={classes} 
            hanldeAddNew={evt => setRegions((prevState) => {
                let newState = [...prevState];
                newState.push(initRegion);
                return newState;
            })}>
                {t("addRegion.label") + " to " + data.name }
            </DialogTitle>
            <DialogContent dividers>
            <Grid container spacing={1}>
                {regions.map((itm, indx) => 
                    <React.Fragment key={indx}>
                        <Grid item xs={6}>
                            <Input
                                value={itm.name}
                                type={'text'}
                                placeholder={t('name.label')}
                                style={{ width: '100%', marginBottom: 12 }}
                                color={'secondary'}
                                onChange={evt => handleChange(evt.target.value, indx, "name")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                value={itm.code}
                                type={'text'}
                                placeholder={t('code.label')}
                                style={{ minWidth: 185, marginBottom: 12 }}
                                color={'secondary'}
                                onChange={evt => handleChange(evt.target.value, indx, "code")}
                            />
                        </Grid>
                        {indx !== 0 &&
                            <Grid item xs={2}>
                                <IconButton onClick={evt => handleRemoveItem(indx)}><DeleteIcon color="secondary" /></IconButton>
                            </Grid>
                        }
                    </React.Fragment>
                )}
            </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        let checkValid = false;
                        for (let index = 0; index < regions.length; index++) {
                            const element = regions[index];
                            if(!element.name){
                                checkValid = true;
                                break;
                            }
                        }
                        if(checkValid){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleSubmitClick(regions);
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateCountryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCountryModal);