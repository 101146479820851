import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/LinearProgress';
import { Input } from '@material-ui/core';

import { UPDATE_OCR_KEY_VALUE } from '../../../Ocr/mutations';
import EidtKeyValuePair from './components/EidtKeyValuePair';
import CustomTabs from '../../../../../components/CustomTabs';
import Message from '../../../../../components/Message';
import CsvDownloadImg from '../../../../../assets/icons/csv-icon1.png';
import PdfDownloadImg from '../../../../../assets/icons/pdf-icon.png';

import OcrDocImgSlider from './components/OcrDocImgSlider';
import OcrFileLocationMap from './OcrFileLocationMap';

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}
      style={{ display: 'flex', marginLeft: 26 }}>
      {loading && <CircularProgress size={14} />}
      {!loading && 'Download csv'}
    </Button>
  );
}


const saveTextFile = (text, fileName) => {
  const element = document.createElement("a");
  const file = new Blob([text], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.txt`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};


function RawTextButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      
      onClick={onClick}
      disabled={loading}
      style={{ display: 'flex', marginTop:10}}>
      {loading && <CircularProgress size={14} />}
      {!loading && 'Download Raw Text'}
    </Button>
  );
}


function DownloadImgButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}
      style={{ display: 'flex', marginLeft: 26 }}>
      {loading && <CircularProgress size={14} />}
      {!loading && 'Download Image'}
    </Button>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContain: {
    width: '100%'
  }
});

const useStyles = makeStyles(theme => ({
  testCon: { marginRight: '6px', marginLeft: '6px' },
  chartTitle: { marginTop: '10px' },
  paging: {
    float: 'right',
    marginBottom: 30,
    marginTop: 20
  },
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2)
  },
  inputBase: {
    width: '100%',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 15,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const OcrDocViewer = ({
  t,
  open,
  onClose,
  docData,
  type,
  loading,
  imgUrl,
  imgName,
  ocrFormvalue,
  handleOpenFileView,
  isBarcode
}) => {
  const classes = useStyles();
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);
  const [isImgDownloadStarted, setIsImgDownloadStarted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [ocrJsonValue, setOcrJsonValue] = useState(false);
    const [editedObject, setEditedObject] = useState(imgUrl.ocr_json);
    const [jsonValue, sertjsonValue]=useState()
    const [isSubmit, setisSubmit]= useState(false)
//  console.log(imgUrl,"imgUrl")

  const fileExtension = imgUrl && imgUrl.file_name.split('.').pop();

  const handlerForkeyPairs = (key, value) =>{
    setEditedObject((prevObject) => ({
      ...prevObject,
      [key]: value,
    }));
  };

  //EditKeyValue
  const handleInputChange =  useCallback((key, value) => {
    setEditedObject((prevObject) => ({
      ...prevObject,
      [key]: value,
    }));
  }, []);

 

 
  const editValueFunc = (data, action) => {
    if (data) {
      return Object.keys(data).map((key) => (
        <div key={key}>
          <span>{key}: </span>
          <Input
            color="secondary"
            type="text"
            placeholder={data[key]}
            onBlur={(e) => handleInputChange(key, e.target.value)}
          />
        </div>
      ));
    }
    return null;
  };
  


  const loopThroughtOFformKeyvalue = (data, action) => {
    if (data) {
      let elements = [];
      for (const [key, value] of Object.entries(data)) {
        elements.push(
          <div>
        
            <span>{key ? `${key}:` : ''}</span>
              {
                action === 'edit' ? (
                  <Input
                  type="text"
                  value={value}
                  color={'secondary'}
                  // onBlur={e=> handlerForkeyPairs(key, e.target.value)}
                  onChange={(e) => handlerForkeyPairs(key, e.target.value)}
              />
                ): (
                  <span >{value !== '' ? value : '-'}</span>
                )
              }
          </div>
        );
      }
      return elements;
    }
  };

  let mapTab = [{
    label: 'Map',
    children: loading ? (
      <CircularProgress color="secondary" />
    ) : (
      (imgUrl && imgUrl.geo_location) ? (
        <Grid container style={{marginTop: 35, marginBottom: 20}}>
          <OcrFileLocationMap
            markerLatLong={{
              lat: parseFloat(imgUrl.geo_location.lat),
              lng: parseFloat(imgUrl.geo_location.long)
            }}
          />
        </Grid>
      ) : (
        <div>
          Don't Have File GeoLocation Data
        </div>
      )
      
    )
  }]

  let tabList = [
    {
      label: 'Segmentation View',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <OcrDocImgSlider t={t} data={imgUrl && imgUrl.processed_images} />
      )
    },
    {
      label: 'Raw Text',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Card className={classes.root} style={{ marginTop: 12 }}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <div>
              <Typography
                variant="body2"
                id="doc-data"
              
                style={{ fontWeight: 200 }}>
                {imgUrl && imgUrl.ocr_text && imgUrl.ocr_text.includes('\n') &&
                  imgUrl.ocr_text.split('\n').map(item => {
                    return (
                      <span>
                        {item}
                        <br />
                      </span>
                    );
                   
                  })}
              </Typography>
              <RawTextButtonComponent
                color="secondary"
                variant={'contained'}
                loading={isDownloadStarted}
                onClick={() => {
                  saveTextFile(imgUrl 
                    && imgUrl?.ocr_text, imgUrl 
                    && imgUrl.ocr_text?.split(" ")[0])
                }}
              />
              </div>
            </CardContent>
          </div>
        </Card>
      )
    },
 
    {
      label: 'Table View(CSV)',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        imgUrl &&
        imgUrl.csv_url && (
          <>
            <div>
              <img
                src={CsvDownloadImg}
                alt=""
                style={{ height: 211, width: 170 }}
              />
              <ButtonComponent
                color="secondary"
                variant={'contained'}
                loading={isDownloadStarted}
                onClick={() => {
                  setIsDownloadStarted(true);
                }}
              />
            </div>
          </>
        )
      )
    },
    {
      label: 'Key: Value Pair',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <div>{loopThroughtOFformKeyvalue(imgUrl?.form_json)}</div>
      )
    },
    {
      label: 'Original Document',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'end'
          }}>
          {fileExtension === 'pdf' ? (
            <div>
              <img
                src={PdfDownloadImg}
                alt=""
                style={{ height: 211, width: 170 }}
              />
              <a
                href={imgUrl && imgUrl.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 12
                }}>
                click on it for view the doc
              </a>
            </div>
          ) : (
            <div>
              <img
                src={imgUrl && imgUrl.url}
                alt={imgName}
                style={{
                  height: 300,
                  width: 280,
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 12
                }}
              />
              <DownloadImgButtonComponent
                color="secondary"
                variant={'contained'}
                loading={isImgDownloadStarted}
                onClick={() => {
                  setIsImgDownloadStarted(true);
                }}
              />
            </div>
          )}
        </div>
      )
    },
    {
      label: 'Edit Key: Value Pair',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (

    
      <div>{editValueFunc(ocrJsonValue)}</div>
     
      )
    },
    ...mapTab
  ];


  const tabListForBarcode = [
    {
      label: 'Barcode Scan Info',
      children: loading ? (
        <CircularProgress color="secondary" />
      ) : (
        imgUrl.barcode_info && (<div>{imgUrl.barcode_info}</div>)
      )
    },
    ...mapTab
  ]

  const hadnleDowloader = (url, file_name) => {
    if (isDownloadStarted || isImgDownloadStarted) {
      setIsDownloadStarted(false);
      setIsImgDownloadStarted(false);
      FileSaver.saveAs(url, file_name);
    }
  };

  const handleChangeActiveNotiTab = (st, tabeValue) => {
    if (tabeValue) {
      setActiveIndex(tabeValue);
    }
  };

  const [
    updateOcrKeyValue,
    { loading: updateOcrKeyValueLoading }
  ] = useMutation(UPDATE_OCR_KEY_VALUE, {
    
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.updateOcrKeyValue.message);
      setisSubmit(false)
      handleOpenFileView(imgUrl);
    }
  });

  useEffect(() => {
    hadnleDowloader(imgUrl.csv_url, imgName);
  }, [isDownloadStarted]);

  useEffect(() => {
    hadnleDowloader(imgUrl.url, imgName);
  }, [isImgDownloadStarted]);

  useEffect(() => {
    setOcrJsonValue(imgUrl.form_json);
  }, [imgUrl.form_json]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Uploaded OCR Doc
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContain}>
        {isBarcode === "undefined" || isBarcode === "" ? <CircularProgress color="secondary" /> :
          <CustomTabs
            activeIndex={activeIndex}
            tabs={isBarcode ? tabListForBarcode : tabList}
            handleChangeActiveNotiTab={handleChangeActiveNotiTab}
          /> 
        }
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button color="secondary" variant={'contained'} onClick={onClose}>
            close
          </Button>
          {activeIndex === 5 && (
            <Button
              color="secondary"
              variant={'contained'}
              disabled={isSubmit}
              onClick={() => {
                setisSubmit(true);
              
                const convertedObject = Object.entries(imgUrl.form_json).reduce((acc, [property, value]) => {
                  const editedValue = editedObject[property];
                  acc[property] = typeof editedValue !== 'undefined' ? editedValue : value;
                  return acc;
                }, {});
              
                
              
                updateOcrKeyValue({
                  variables: {
                    id: imgUrl?.id,
                    formJson: convertedObject
                  }
                });
              }}
              
              
              >

               

                {!isSubmit ? 'Submit' : 'submitting update..'}
        
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default OcrDocViewer;
