import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_CHANGE_MANAGEMENT_BY_ID } from '../queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import {
    Paths,
    TicketingSystem
} from '../../../routes/routePaths';
import { LinearProgress } from "@material-ui/core";
import CustomTabs from '../../../components/CustomTabs';
import { dateFormatterLLL, getParam } from '../../../utils';
import RequestDetailsPanel from './components/RequestDetailsPanel';
import { useLocation, withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../theme/commonStyles';
import { getUrl } from '../../../utils';
import Message from '../../../components/Message';
import { STATUS_TYPES } from '../../../utils/constants';
import { CLOSE_CM_REQUEST } from '../mutations';
import { ExportCSV } from './components/Export';

const useStyles = makeStyles(theme => ({
    commentBar: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3, 0),
        maxWidth: '1100px'
    },
    commentContainer: {
        bottom: 0,
        left: 0,
        borderBottom: '1px solid #eaeaea',
        borderTop: '1px solid #eaeaea',
        ...(window.innerWidth < 1100 && { padding: 24 })
    },
    exportBtn: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    updateBtn: {
        marginRight: '10px'
    }
}));

const ChangeDetail = ({ t, history }) => {
    const classes = useStyles();
    const id = getParam();
    const [loading, setLoading] = useState(true);
    const [CMDetail, setCMDetail] = useState([])
    const [CMDetailLastItem, setCMDetailLastItem] = useState([])

    let [breadcrumbList, setBreadcrumbList] = useState([
        { label: 'home.label', link: Paths.Dashboard },
        { label: 'changeManagement.label', link: Paths.CM },
        { label: 'changeDetail.label', link: TicketingSystem.CMDetails }
    ]);

    useEffect(() => {
        changeManagementById({
            variables: {
                id: id
            }
        })
    }, [])

    const [changeManagementById, { refetch: reloadCMRequest }] = useLazyQuery(GET_CHANGE_MANAGEMENT_BY_ID, {
        fetchPolicy: "no-cache",
        onError: e => {
            console.log("Error", e);
            Message.error("Something Went Wrong")
            history.push()
            setLoading(false)
        },
        onCompleted: res => {
            let length = res.getChangeManagementById.changeRequest.length
            let lastItem = res && res.getChangeManagementById && res.getChangeManagementById.changeRequest
                && res.getChangeManagementById.changeRequest[length - 1]

            setLoading(false)
            setCMDetail(res.getChangeManagementById)
            setCMDetailLastItem(lastItem)
        }
    });

    const [closeCMRequestById] = useMutation(CLOSE_CM_REQUEST, {
        onError: error => {
            console.log('Error', error);
            Message.error('There is some error in closing ticket. Please try again');
        },
        onCompleted: () => {
            Message.success('Change Management Request Closed Successfully.');
            reloadCMRequest()
        }
    });

    const handleCloseTicketAction = () => {
        if (
            window.confirm('Are you sure you want to close the change request?') ===
            false
        ) {
            return false;
        }

        return closeCMRequestById({
            variables: {
                id: id,
                status: 'Closed'
            }
        });
    };

    let viewers = [CMDetailLastItem]

    return (
        <Layout breadcrumbList={breadcrumbList}>
            {loading ? <LinearProgress color='primary' /> : (
                <>
                    <Header
                        title={CMDetailLastItem.title}
                        subText={`${t('id.label').toUpperCase()} ${CMDetail.requestId}
                  • ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
                            CMDetail.createdAt
                        )} ${CMDetail.status === 'Closed' ?
                            t('TicketClosedOn.label') + " " + dateFormatterLLL(
                                CMDetail.updatedAt
                            ) : ""}`}
                        primaryButtonText={
                            CMDetail.status != STATUS_TYPES.CLOSED ? t('closeTicket.label') : null
                        }
                        handlePrimaryButtonClick={
                            CMDetail.status != STATUS_TYPES.CLOSED ? handleCloseTicketAction : null
                        }
                        secondaryButtonText={CMDetail.status === 'approved' ? t('back.label') : t('changeRequestForm.label')}
                        handleSecondaryButtonClick={() => {
                            const closePath = getUrl(TicketingSystem.CM);
                            const formPath = TicketingSystem.ChangeRequest + "/?id=" + id
                            CMDetail.status === 'approved' ? history.push(closePath) : history.push(formPath)
                        }}
                    />

                    <div className={classes.exportBtn}>
                        <ExportCSV csvData={viewers} fileName={`ChangeRequest-${CMDetail.requestId}`} type={'singleReport'} />
                    </div>

                    <CustomTabs
                        tabs={[
                            {
                                label: t('details.label'),
                                children: (
                                    <RequestDetailsPanel
                                        requestLastData={CMDetailLastItem}
                                        requestData={CMDetail}
                                        reloadTickets={reloadCMRequest}
                                        t={t}
                                    />
                                )
                            },
                            {
                                label: t('activity.label'),
                                children: (<></>)
                            }
                        ]}
                    />
                </>
            )}
        </Layout>
    );
};
export default withTranslation()(withRouter(ChangeDetail));
