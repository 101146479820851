// coming soon
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import {USER_ROLES} from "../../../../../utils/constants";
import Header from "../../../../../components/Header";

const Notifications = ({t}) => {
    const role = localStorage.getItem('role');
    const [categoryModalState, setCategoryModalState] = useState(false);
    const [modalState, setModalState] = useState({btnLoading: false, clearData: false});
    const [updateCategory, setUpdateCategory] = useState(null);
    const menuItems = [
        {label: t('delete.label')},
        {label: t('edit.label')}
    ];
    const handleCreateButtonClick = searchParam => {
        setCategoryModalState(true);
    };
    // const { data, loading, error, refetch: reloadCateries } = useQuery(GET_CATEGORIES);
    const columns = [
        {
            label: t('modified.label'),
            key: "modified"
        },
        {
            label: t('project_code.label'),
            key: "project_code"
        },
        {
            label: t('status.label'),
            key: "status"
        },
        {
            label: t('customerName.label'),
            key: "customerName"
        },
        {
            label: t('action.label'),
            key: "button"
        }
        // {
        //     label: t('edit.label') + '/' + t('delete.label'),
        //     key: "button"
        // }
    ];
    /* const [addUpdateCategory] = useMutation(ADD_UPDATE_CATEGORY, {
         onError: error => {
             console.log('Error', error);
         },
         onCompleted: data => {
             setModalState({ btnLoading: false, clearData: true });
             setUpdateCategory(null);
             Message.success(`Category ${updateCategory ? 'updated' : 'created'} successfully`);
             reloadCateries();
         }
     });
     const [deleteMarketPlaceCategory] = useMutation(DELETE_VNF_MARKETPLACE_CATEGORY, {
         onError: error => {
             console.log('Error', error);
         },
         onCompleted: data => {
             Message.success('Marketplace category deleted successfully');
             reloadCateries();
         }
     });*/
    const handleMenuItemClick = (elem, data) => {
        if (elem.label === t('edit.label')) {
            setModalState({btnLoading: false, clearData: false});
            setUpdateCategory(data);
            setCategoryModalState(true);
        } else if (elem.label === t('delete.label')) {
            if (
                window.confirm('Are you sure you want to delete this marketplace category ?') ===
                false
            ) {
                return false;
            }
            /*  return deleteMarketPlaceCategory({
                  variables: {
                      category_id: data._id
                  }
              });*/
        }
    }
    const handleClose = () => {
        setCategoryModalState(false);
    }
    const handleSubmitClick = (formData) => {
        setModalState({btnLoading: true, clearData: false});
        //  return addUpdateCategory({ variables: formData });
    }
    return (
        <>
            {(role.includes(USER_ROLES.IP_VNFADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) &&
            <Header
                title={t('notifications.label')}
                subText={`${t('notifications.label')}`}
                // primaryButtonText={t('createNew.label')}
                handlePrimaryButtonClick={handleCreateButtonClick}
            />}
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {/* <TableBody>
                            {!loading && !error ?
                                data.getCategories.length ?
                                    data.getCategories.map((item, index) => (
                                        <CategoryItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody> */}
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(Notifications);
