import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ALL_SERVICES_TYPES, GET_BILLING_DATA_BY_SERVICE, GET_ALL_SERVICES_LIST_BY_TYPES } from './queries';
import { CREATE_AND_UPDATE_SERVICE_TYPE, CREATE_SERVICE_PRODUCT, ADD_UPDATE_SERVICE_BILLING } from './mutations';
import { GET_COUNTRY_LIST } from '../../commonQueries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { InternalPaths } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import ServiceItem from './ServiceItem'
import CreateServiceTypeModal from './components/CreateServiceTypeModal';
import Message from '../../../components/Message';
import ServiceProductsModal from './components/ServiceProducts';
import CreateServiceProductModal from "./components/ServiceProducts/CreateServiceProductModal"
import BillingModal from "./components/BillingModal";
import { CURRENCIES } from "../../../utils/constants";

const breadcrumbList = [
  { label: 'home.label', link: InternalPaths.Dashboard },
  { label: 'users.label', link: InternalPaths.users }
];

const ServicesAndProducts = ({ t }) => {

  const [openCreateServiceModalState, setOpenCreateServiceModalState] = useState(false);
  const [modalState, setModalState] = useState({clearData: false, btnLoading: false});
  const [editServiceData, setEditServiceData] = useState(null);
  const [serviceProductList, setServiceProductList] = useState(null);
  const [serviceProductListModal, setServiceProductListModal] = useState(false);
  const [createNewProductModal, setCreateNewProductModal] = useState(false);
  const [productModalState, setProductModalState] = useState({clearData: false, btnLoading: false});
  const [editProductData, setEditProductData] = useState(null);
  const [openBillingModalState, setOpenBillingModalState] = useState(false);
  const [productBillModalState, setProductBillModalState] = useState({clearData: false, btnLoading: false});
  const [currency, setCurrency] = useState(null);
  const [billingDataLoading, setBillingDataLoading] = useState(false);
  const [serviceBillingData, setServiceBillingData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  /*
    Service Type List Queries
  */
  const { loading, error, data, refetch: reloadServiceTypeList } = useQuery(
    GET_ALL_SERVICES_TYPES, {
      fetchPolicy: "no-cache"
    }
  );

  const [getBillingDataByService] = useLazyQuery(GET_BILLING_DATA_BY_SERVICE, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log("Error", error);
    },
    onCompleted: (res) => {
      console.log("res ", res);
      setBillingDataLoading(false);
      setServiceBillingData(res.getBillingDataByService && res.getBillingDataByService.length > 0 ? res.getBillingDataByService : [])
    }
  });

  const [getServiceListByType, {loading: servicesLoading}] = useLazyQuery(GET_ALL_SERVICES_LIST_BY_TYPES, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log("Error", error);
    },
    onCompleted: (res) => {
      setServiceList(res.getServiceListByType ? res.getServiceListByType : [])
    }
  });

  useEffect(() => {
    setServiceProductList(null);
    setEditServiceData(null);
  }, [data]);

  useEffect(() => {
    let curncy = CURRENCIES.filter(itm => {
      return itm.code === "USD" 
    });
    setCurrency(curncy.length > 0 ? curncy[0] : null);
  }, []);

  const [addUpdateServiceType] = useMutation(CREATE_AND_UPDATE_SERVICE_TYPE, {
    onError: error => {
      console.log('Error', error);
      Message.error(error);
      setModalState({clearData: true, btnLoading: false});
    },
    onCompleted: async data => {
      if(data.addUpdateServiceType){
        setEditServiceData(null);
        reloadServiceTypeList();
        Message.success(editServiceData ? "Service updated successfully" : "Service added successfully");
        setModalState({clearData: true, btnLoading: false});
      }else{
        Message.success("Something went wrong!");
      }
      setOpenCreateServiceModalState(false);
    }
  });

  const [addServiceProduct] = useMutation(CREATE_SERVICE_PRODUCT, {
    onError: error => {
      console.log('Error', error);
      Message.error(error);
      setProductModalState({btnLoading: false, clearData: false});
    },
    onCompleted: async data => {
      Message.success(`Product ${editProductData ? "updated" : "added"} successfully`);
      setEditProductData(null);
      setProductModalState({btnLoading: false, clearData: true});
      setCreateNewProductModal(false);
      return getServiceListByType({variables: {
        serviceTypeId: serviceProductList.id
      }});
    }
  });

  const [addUpdateServiceBilling] = useMutation(ADD_UPDATE_SERVICE_BILLING, {
    onError: error => {
      console.log('Error', error);
      Message.error(error);
      setProductBillModalState({btnLoading: false, clearData: false});
    },
    onCompleted: async data => {
      Message.success(`Product billing details added successfully`);
      setProductBillModalState({btnLoading: false, clearData: true});
      setOpenBillingModalState(false);
    }
  });

  /*
    Countries List Queries
  */
  const { loading: countryLoading, error: countryError, data: countriesList } = useQuery(
    GET_COUNTRY_LIST
  );

  const handleAddNewServiceClick = () => {
    setEditServiceData(null);
    setModalState({clearData: true, btnLoading: false});
    setOpenCreateServiceModalState(true);
  };

  const columns = [
    {
      label: t('serviceName.label'),
      key: 'name'
    },
    {
      label: t('countryEnabled.label'),
      key: 'countryId'
    },
    {
      label: t('viewProducts.label'),
      key: 'viewProducts'
    },
    {
      label:t('edit.label'),
      key:"button"
    }
  ];

  const handleEditService = (serviceData) => {
    let editService = serviceData;
    setModalState({clearData: false, btnLoading: false});
    let countryId = (editService.countryId && editService.countryId.length > 0) ? editService.countryId.map(itm => itm._id) : [];
    setEditServiceData({...editService, countryId: countryId});
    setOpenCreateServiceModalState(true);
  }

  const handleViewProducts = (serviceData) => {
    setServiceList([]);
    setServiceProductList(serviceData);
    setProductModalState({btnLoading: false, clearData: false});
    setServiceProductListModal(true);
    return getServiceListByType({variables: {
      serviceTypeId: serviceData.id
    }});
  }

  const handleCreateServiceTypeSubmit = (formData) => {
    setModalState({clearData: false, btnLoading: true});
    addUpdateServiceType({variables: {...formData}});
  }

  const handleCreateNewProduct = () => {
    setEditProductData(null);
    setProductModalState({btnLoading: false, clearData: false});
    setCreateNewProductModal(true);
  }

  const handleCreateNewProductSubmit = (formData) => {
    if(serviceProductList){
      setProductModalState({btnLoading: true, clearData: false});
      formData.serviceTypeId = serviceProductList.id;
      formData.avgUptime = parseFloat(formData.avgUptime);
      formData.avgUptime = parseFloat(formData.avgCost);
      formData.avgCost = formData.avgCost ? parseFloat(formData.avgCost) : 0;
      formData.enableAvgCost = formData.enableAvgCost === "Yes" ? true : false;
      if(editProductData && editProductData._id){
        formData.id = editProductData._id;
      }
      addServiceProduct({variables: {...formData}});
    }else{
      return null;
    }
  }

  const handleEditProduct = (product) => {
    setEditProductData(product);
    setCreateNewProductModal(true);
  }

  const handleSubmitBilling = (formData) => {
    if(editProductData){
      setProductBillModalState({btnLoading: true, clearData: false});
      let data = JSON.stringify({...formData, serviceId: editProductData._id, currency});
      addUpdateServiceBilling({variables: {data}});
    }else{
      return null;
    }
  }

  const handleChangeCurrency = (evt) => {
    console.log("evt: ", evt);
    setCurrency(evt.target.value);
  }

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('services.label')}
        subText={`${t('serviceSubTxt.label')}`}
        primaryButtonText={t('addNewService.label')}
        handlePrimaryButtonClick={handleAddNewServiceClick}
      />
      <Grid container>
        <TableContainer>
          <Table style={{marginBottom: 32}}>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !error ? (
                data.getAllServiceTypes.length > 0 ? (
                  data.getAllServiceTypes.map((rowitem, rowIndex) => (
                    <ServiceItem
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                      handleEditService={handleEditService}
                      handleViewProducts={handleViewProducts}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant={'subtitle2'}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      
      <CreateServiceTypeModal 
        open={openCreateServiceModalState} 
        handleClose={evt => {
          setOpenCreateServiceModalState(false);
        }}
        handleCreateServiceTypeSubmit={handleCreateServiceTypeSubmit} 
        modalState={modalState}
        countriesList={!countryLoading && !countryError ? (countriesList && countriesList.getCountries) ? countriesList.getCountries : [] : []}
        data={editServiceData} 
      />

      {serviceProductList &&
        <ServiceProductsModal 
          data={serviceProductList} 
          t={t} 
          open={serviceProductListModal} 
          handleAddProduct={handleCreateNewProduct}
          handleEditProduct={handleEditProduct}
          handleClose={()=> {
            setServiceProductList(null);
            setServiceProductListModal(false);
            setServiceList([]);
          }}
          handleViewBilling={(data) => {
            getBillingDataByService({ variables: { serviceId: data._id }});
            setBillingDataLoading(true);
            setOpenBillingModalState(true); 
            setEditProductData(data);
          }}
          loading={servicesLoading}
          services={serviceList}
        />
      }

      {serviceProductList &&
        <CreateServiceProductModal 
          open={createNewProductModal} 
          handleClose={evt => {
            setProductModalState({clearData: true, btnLoading: false});
            setCreateNewProductModal(false);
            setEditProductData(null);
          }}
          handleCreateNewProductSubmit={handleCreateNewProductSubmit} 
          modalState={productModalState}
          data={editProductData}
        />
      }
      
      {editProductData &&
        <BillingModal
          t={t}
          data={editProductData}
          open={openBillingModalState} 
          handleSubmitBilling={handleSubmitBilling} 
          modalState={productBillModalState}
          currencies={CURRENCIES}
          currency={currency}
          handleChangeCurrency={handleChangeCurrency}
          handleClose={evt => {
            setProductModalState({clearData: true, btnLoading: false});
            setEditProductData(null);
            setOpenBillingModalState(false);
          }}
        />
      }
    </Layout>
  );
};
export default withTranslation()(ServicesAndProducts);
