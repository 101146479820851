import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { get } from "lodash-es";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../../../../../../theme/utils';
import {  convertLocalToUTCDate } from '../../../../../../utils';
import EventsMap from '../../../../components/EdgeAIDashBoardPanel/EventsMap';

const useStyles = makeStyles(theme => ({
    mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2),
      position: "relative",
      overflow: "hidden"
    },
    dateInput: {
      width: 250,
      minWidth: 200,
      padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    }
    
  }));

const initMapFilter = {time: 'Today', startDate: null, endDate: null, placeId: '', projectId: '', folderId: ''};

const OcrDashBoardMapAndDetails = ({
    t,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    loadingOcrFolder,
    handleFilterdataForOptions,
    ocrDashboardMapLoading,
    ocrDashboardMapData,
    handleFilterForDashboardMap
}) => {
    const classes = useStyles();
    const [mapPlaceList, setMapPlaceList] = useState([]);
    const [mapPlaceListForStatus, setMapPlaceListForStatus] = useState([]);
    const [ocrDashboardMapInfoWindowData, setOcrDashboardMapInfoWindowData] = useState(null);
    const [ocrDashboardMapInfoWindowLoading, setOcrDashboardMapInfoWindowLoading] = useState(false);
    const [ocrDashboardMapState, setOcrDashboardMapState] = useState({...initMapFilter});

    const closeInfoWindow = () => {
        setOcrDashboardMapInfoWindowData(null);
    }

    const handleMarkerClick = (placeId) => null;

    const handleClearFilter = (type) => {
        if(type === "place_map"){
            setOcrDashboardMapState({...initMapFilter});
        }
    };

    const handleAutoPlaceSearch = (type, value) => null;

    const ocrDasboardMapFilter =  [
        {
            label: t("startDate.label"),
            handleChange:(newDate) => {
                setOcrDashboardMapState({...ocrDashboardMapState, startDate: newDate});
            },
            val: ocrDashboardMapState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange:(newDate) => {
                setOcrDashboardMapState({...ocrDashboardMapState, endDate: newDate})
            },
            val: ocrDashboardMapState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if(!newValue){
                    handleAutoPlaceSearch("eamp", "");
                }
                handleFilterdataForOptions(newValue && newValue.id, 'project');
                setOcrDashboardMapState({...ocrDashboardMapState, placeId: newValue && newValue.id});
            },
            onInputChange: (val) => {
                handleAutoPlaceSearch("eamp", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("eamp", "");
            },
            val: ocrDashboardMapState.placeId,
            options: (!loadingOcrPlace && ocrPlaceData) ? ocrPlaceData.data : [],
            loading: loadingOcrPlace,
            type: "autocomplete",
            styleOverrides: { marginRight: 0, display: "inline-block", width: 260, marginTop: 10 },
        },
        {
            label: t('projects.label'),
            handleChange: evt => {
                handleFilterdataForOptions({place: ocrDashboardMapState.placeId,project:evt.target.value}, 'folder');
                setOcrDashboardMapState({...ocrDashboardMapState, projectId: evt.target.value});
            },
            val: ocrDashboardMapState.projectId,
            options: loadingOcrProject ? ([<MenuItem value={null}>Loading ...</MenuItem>]) : (!loadingOcrProject && ocrProjectData) ? ocrProjectData.data : [],
            styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
        },
        {
            label: t('folders.label'),
            handleChange: evt => {
                setOcrDashboardMapState({...ocrDashboardMapState, folderId: evt.target.value});
            },
            val: ocrDashboardMapState.folderId,
            options: loadingOcrFolder ? ([<MenuItem value={null}>Loading ...</MenuItem>]) : (!loadingOcrFolder && ocrFolderData) ? ocrFolderData.data : [],
            styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
        }
    ];


    useEffect(() => {
        let startTime = "";
        let endTime = "";
        if(ocrDashboardMapState.startDate && ocrDashboardMapState.endDate){
            startTime = new Date(convertLocalToUTCDate(ocrDashboardMapState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(ocrDashboardMapState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        setOcrDashboardMapInfoWindowData(null);
        handleFilterForDashboardMap({startTime, endTime, place:ocrDashboardMapState.placeId, project:ocrDashboardMapState.projectId, folder:ocrDashboardMapState.folderId})
    }, [ocrDashboardMapState]);


  return (
    <>
        <ChartContainer
            title={null}
            filterList={ocrDasboardMapFilter}
            clearFilter={evt => handleClearFilter("place_map")}>
            <Grid
            container
            className={classes.mapContainer}
            style={marginGenerator('mt-32')}>
                {ocrDashboardMapLoading ? <div className={classes.infoWindowLoading}>
                    <CircularProgress color="secondary" />
                </div> :
                    <EventsMap 
                        id={'ocr-map'} 
                        markers={get(ocrDashboardMapData, 'modifyMapdata', [])} 
                        handleMarkerClick={handleMarkerClick}
                        closeInfoWindow={closeInfoWindow}
                        infoWindowData={ocrDashboardMapInfoWindowData}
                        infoWindowLoading={ocrDashboardMapInfoWindowLoading}
                    />
                }
            </Grid>
        </ChartContainer>
    </>
  )
}

export default OcrDashBoardMapAndDetails