import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../theme/colors';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';
import {
  REQUIRED_ERROR,
  MULTI_OBJECTS_LIST,
  FREQUENCY_OPTIONS,
  ACD_OBJECTS_LIST,
  OBJECTS_DETECTION_LIST
} from '../../../../utils/constants';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import { removeSpacialCharecter, IsNumeric } from '../../../../utils';
import Fab from '@material-ui/core/Fab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CSVReader from 'react-csv-reader';
import sampleCsvfile from '../../../../assets/licencePlatesFile.csv';
import faceRecSampleZip from '../../../../assets/face_recognition_sample.zip';

import './index.css';
import JSZip from 'jszip';

const MAX_FILE_SIZE = 3 * 1024 * 1024; //3MB

const useStyles = makeStyles(theme => ({
  paper: { maxWidth: 900 },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  },
  objDetecType: {
    height: 48,
    width: 70
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px'
  }
}));

function valuetext(value) {
  return `${value}°C`;
}

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

function CreateMLTemplateModal({
  t,
  open,
  handleCreateMLSubmitClick,
  handleClose,
  aiTypes,
  handleSelectAIType,
  modalState,
  selectedAiType,
  providerTypes,
  multiObjectOpt,
  frequencies,
  accidentDetectionObj,
  customDetectionObjects
}) {
  const classes = useStyles();
  const initMultiObjectFields = {
    name: '',
    threshold: 0,
    number: '',
    condition: '',
    between_number: ''
  };
  const initAcDFields = {
    name: '',
    threshold: 0,
    accident_threshold: 0,
    number: '',
    condition: '',
    between_number: ''
  };
  const multiObjectConditions = [
    { id: 'greater', name: 'Greater then' },
    { id: 'in_between', name: 'In between' },
    { id: 'lesser', name: 'Lesser then' }
  ];

  const initFaceRecoState = {
    name: '',
    image: '',
    files: '',
    list_type: '',
    notification: false,
    disable_noti_field: false,
    imageName: ''
  };
  const initAbsDetecFields = {
    name: '',
    threshold: 0,
    number: '',
    condition: '',
    between_number: '',
    custom_duration: ''
  };
  const initLPRState = {
    plate_number: '',
    list_type: '',
    notification: false,
    disable_noti_field: false,
    car_description: '',
    min_character: '',
    max_character: ''
  };
  const initObjectDetecFields = {
    type: '',
    class: '',
    threshold: 0,
    notification: true,
    base_class: ''
  };

  const [templateName, setTemplateName] = useState('');
  const [aiTemplateType, setAiTemplateType] = useState('');
  const [priority, setPriority] = useState('');
  const [aiParamSubFields, setAiParamSubFields] = useState({});
  const [aiParamsOptions, setAiParamOptions] = useState([]);
  const [aiTemplateSubElem, setAITemplateSubElem] = useState(null);
  const [changeSubFieldState, setChangeSubFieldState] = useState(false);
  const [lcrTimer, setLcrTimer] = useState(false);
  const [aiTypeName, setAiTypeName] = useState('');
  const [multiObjects, setMultiObjects] = useState([]);
  const [accidentDetections, setAccidentDetections] = useState([]);
  const [faceRecognition, setFaceRecognition] = useState([
    { ...initFaceRecoState }
  ]);
  const [lineCrossList, setLineCrossList] = useState([]);
  const [notificationFrequency, setNotificationFrequency] = useState('');
  const [definationName, setDefinationName] = useState('');
  const [lineCrossBase64Image, setLineCrossBase64Image] = useState('');
  const [absenceDetection, setAbsenceDetection] = useState([]);
  const [licencePlates, setLicencePlates] = useState([{ ...initLPRState }]);
  const [objectDetections, setObjectDetections] = useState([
    { ...initObjectDetecFields }
  ]);
  const [faceRecZip, setFaceRecZip] = useState(null);
  // const [objectOption, setObjectOption] = useState([]);
  const [speedDetectionModel, setSpeedDetectionModel] = useState({
    distance: '',
    object: '',
    polygons: []
  });

  React.useEffect(() => {
    if (modalState.clearData && open) {
      setTemplateName('');
      setPriority('');
      setAiTemplateType('');
      setAITemplateSubElem(null);
      setAiParamSubFields({});
      setAiParamOptions([]);
      setFaceRecognition([{ ...initFaceRecoState }]);
      setLineCrossList([]);
      setMultiObjects([]);
      setLicencePlates([{ ...initLPRState }]);
      handleClose();
    }
  }, [modalState]);

  const [checked, setChecked] = React.useState([]);
  const handleCheckAIParam = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    updateAiSubFields(aiTemplateType);
  }, [checked]);

  useEffect(() => {
    if (lcrTimer) {
      const interval = setInterval(() => {
        if (
          selectedAiType === 'people_counting_alert' &&
          localStorage.getItem('lineCrossData')
        ) {
          let lineCrsCords = JSON.parse(localStorage.getItem('lineCrossData'));
          onChangeAiSubFields(
            lineCrsCords.startX,
            { fieldType: 'text' },
            'start_x'
          );
          onChangeAiSubFields(
            lineCrsCords.startY,
            { fieldType: 'text' },
            'start_y'
          );

          onChangeAiSubFields(
            lineCrsCords.endX,
            { fieldType: 'text' },
            'end_x'
          );
          onChangeAiSubFields(
            lineCrsCords.endY,
            { fieldType: 'text' },
            'end_y'
          );
          localStorage.removeItem('lineCrossData');
          updateAiSubFields(aiTemplateType);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [lcrTimer]);

  const updateLineCrossObjectFromStorage = lineData => {
    if (lineData && lineData.linedata) {
      setLineCrossList(lineData.linedata);
      setLineCrossBase64Image(lineData.line_img_url);
    }
  };

  window.addEventListener(
    'storage',
    function (event) {
      if (event.key === 'linedata' && event.newValue) {
        let lineData = JSON.parse(event.newValue);
        updateLineCrossObjectFromStorage(lineData);
      }
      if (event.key === 'speed_detection' && event.newValue) {
        let lineData = JSON.parse(event.newValue);
        setSpeedDetectionModel({ ...speedDetectionModel, polygons: lineData });
      }
    },
    false
  );

  const onChangeAiSubFields = (value, item, field) => {
    let prevState = aiParamSubFields;
    if (item.fieldType == 'text') {
      prevState[field] = value;
    } else {
      prevState[item.key] = value;
    }
    setAiParamSubFields(prevState);
    setChangeSubFieldState(!changeSubFieldState);
  };

  const updateAiSubFields = value => {
    let elements = {};
    if (aiParamsOptions && aiParamsOptions.length) {
      for (let i = 0; i < aiParamsOptions.length; i++) {
        let item = aiParamsOptions[i];
        if (item.fieldType === 'radio') {
          elements[item.key] = item.options.map((field, index) => {
            return (
              <Radio
                key={index}
                disabled={!checked.includes(item.key)}
                checked={field === aiParamSubFields[item.key]}
                classes={{ root: classes.iconButton }}
                style={{
                  ...marginGenerator('mr-16'),
                  ...marginGenerator('mt-0'),
                  ...(field === aiParamSubFields[item.key]
                    ? { backgroundColor: colors.secondary.main }
                    : '')
                }}
                onChange={evt => onChangeAiSubFields(field, item)}
                value={field}
                name="site-select"
                checkedIcon={
                  <div>
                    <Typography
                      variant={'subtitle2'}
                      style={{ color: colors.common.white }}>
                      {field}
                    </Typography>
                  </div>
                }
                icon={
                  <div className={classes.icon}>
                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                      {field}
                    </Typography>
                  </div>
                }
              />
            );
          });
        } else if (item.fieldType === 'text') {
          elements[item.key] = item.options
            ? item.options.map((field, index) => {
              return (
                <Input
                  disabled={!checked.includes(item.key)}
                  key={index}
                  value={aiParamSubFields[field.name]}
                  type={item.inputType ? item.inputType : 'text'}
                  placeholder={field.label}
                  style={{
                    minWidth: 'auto',
                    maxWidth: '160px',
                    marginLeft: '15px'
                  }}
                  color={'secondary'}
                  onChange={evt =>
                    onChangeAiSubFields(evt.target.value, item, field.name)
                  }
                />
              );
            })
            : null;
        } else if (item.fieldType === 'slider') {
          elements[item.key] = (
            <Slider
              disabled={!checked.includes(item.key)}
              defaultValue={
                item.defaultValue ? parseFloat(item.defaultValue) : 0
              }
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={item.min}
              max={item.max}
              onChange={(evt, number) => {
                onChangeAiSubFields(number, item);
              }}
            />
          );
        } else if (item.fieldType === 'dropdown') {
          elements[item.key] = (
            <OutlinedSelect
              disabled={!checked.includes(item.key)}
              val={aiParamSubFields[item.key]}
              label={item.name}
              handleChange={evt => {
                onChangeAiSubFields(evt.target.value, item);
              }}
              selectStyle={{ ...paddingGenerator('p-8') }}
              styleOverrides={{
                width: '100%',
                marginRight: 0,
                minWidth: '180px'
              }}
              options={item.options}
            />
          );
        } else if (item.fieldType === 'multi_select') {
          elements[item.key] = (
            <OutlinedSelect
              disabled={!checked.includes(item.key)}
              multiple={true}
              val={
                aiParamSubFields[item.key] &&
                  aiParamSubFields[item.key].length > 0
                  ? aiParamSubFields[item.key]
                  : []
              }
              label={item.name}
              handleChange={evt => {
                onChangeAiSubFields(evt.target.value, item);
              }}
              selectStyle={{ ...paddingGenerator('p-8') }}
              styleOverrides={{
                width: '100%',
                marginRight: 0,
                minWidth: '300px'
              }}
              options={item.options}
            />
          );
        }
      }
    }
    setAITemplateSubElem(elements);
    if (
      value === 'people_counting_alert' ||
      value === 'line_cross' ||
      value === 'line_cross2'
    ) {
      setLcrTimer(true);
    }
  };

  useEffect(() => {
    updateAiSubFields(aiTemplateType);
  }, [changeSubFieldState]);

  const onCreateMLTemplateSubmit = () => {
    let postData = {
      name: templateName,
      type: aiTemplateType,
      priority: priority
    };
    let definition = { [aiTemplateType]: {} };
    let lineCrossObjectData = [];
    if (aiTemplateType === 'people_counting_alert') {
      definition[aiTemplateType].line_params = {
        end_x: 0,
        end_y: 0,
        start_x: 0,
        start_y: 0
      };
      definition[aiTemplateType].region_params = {
        lower_region: 'exit',
        upper_region: 'entry'
      };
    } else if (aiTemplateType === 'custom') {
      definition = { [definationName]: {} };
    }
    if (aiTemplateType === 'multi_object') {
      if (multiObjects.length) {
        let objects = {};
        for (let i = 0; i < multiObjects.length; i++) {
          if (multiObjects[i].name) {
            let key = multiObjects[i].name;
            Object.assign(objects, {
              [key]: {
                threshold: multiObjects[i].threshold
                  ? multiObjects[i].threshold.toString()
                  : '0',
                number: multiObjects[i].number
                  ? multiObjects[i].number.toString()
                  : '0',
                condition: multiObjects[i].condition,
                between_number: multiObjects[i].between_number
                  ? multiObjects[i].between_number.toString()
                  : '0'
              }
            });
          }
        }
        delete definition[aiTemplateType];
        definition['multi-object'] = { objects };
      }
    } else if (aiTemplateType === 'accident_detection') {
      if (accidentDetections.length) {
        let objects = {};
        for (let i = 0; i < accidentDetections.length; i++) {
          if (accidentDetections[i].name) {
            let key = accidentDetections[i].name;
            Object.assign(objects, {
              [key]: {
                threshold: accidentDetections[i].threshold
                  ? accidentDetections[i].threshold.toString()
                  : '0',
                accident_threshold: accidentDetections[i].accident_threshold
                  ? accidentDetections[i].accident_threshold.toString()
                  : '0'
              }
            });
          }
        }
        definition[aiTemplateType] = { objects };
      }
    } else if (aiTemplateType === 'face_recognition') {
      if (faceRecognition.length) {
        let faceObjects = [];
        for (let i = 0; i < faceRecognition.length; i++) {
          let key = faceRecognition[i].name;
          if (faceRecognition[i].files) {
            let enable_notification = true;
            if (
              faceRecognition[i].list_type &&
              faceRecognition[i].list_type === 'whitelist'
            ) {
              enable_notification = faceRecognition[i].notification
                ? true
                : false;
            }
            faceObjects.push({
              [key]: faceRecognition[i].files,
              list_type: faceRecognition[i].list_type
                ? faceRecognition[i].list_type
                : 'whitelist',
              enable_notification
            });
          }
        }
        definition[aiTemplateType] = { faces: faceObjects };
      }
    } else if (aiTemplateType === 'license_plate_recognition') {
      if (licencePlates.length) {
        let plates = [];
        for (let i = 0; i < licencePlates.length; i++) {
          let enable_notification = true;
          if (
            licencePlates[i].list_type &&
            licencePlates[i].list_type === 'whitelist'
          ) {
            enable_notification = licencePlates[i].notification ? true : false;
          }
          plates.push({
            plate_number: licencePlates[i].plate_number,
            list_type: licencePlates[i].list_type
              ? licencePlates[i].list_type
              : 'whitelist',
            enable_notification,
            car_description: licencePlates[i].car_description,
            min_character: licencePlates[i].min_character
              ? parseInt(licencePlates[i].min_character)
              : 0,
            max_character: licencePlates[i].max_character
              ? parseInt(licencePlates[i].max_character)
              : 0
          });
        }
        definition[aiTemplateType] = { plates };
      }
    } else if (
      aiTemplateType === 'line_cross' ||
      aiTemplateType === 'line_cross2'
    ) {
      if (lineCrossList.length) {
        let lines = {};
        for (let i = 0; i < lineCrossList.length; i++) {
          let key = lineCrossList[i].name
            ? lineCrossList[i].name
            : 'line' + (i + 1);
          Object.assign(lines, {
            [key]: {
              start_x: lineCrossList[i].relativeCords.startX
                ? lineCrossList[i].relativeCords.startX.toString()
                : '0',
              start_y: lineCrossList[i].relativeCords.startY
                ? lineCrossList[i].relativeCords.startY.toString()
                : '0',
              end_x: lineCrossList[i].relativeCords.endX
                ? lineCrossList[i].relativeCords.endX.toString()
                : '0',
              end_y: lineCrossList[i].relativeCords.endY
                ? lineCrossList[i].relativeCords.endY.toString()
                : '0',
              objects: lineCrossList[i].lineObjects,
              thresh_conf: lineCrossList[i].thresh_conf.toString()
            }
          });
          lineCrossObjectData.push({
            coordinates: lineCrossList[i].coordinates
              ? lineCrossList[i].coordinates
              : null,
            pointers: lineCrossList[i].pointers
              ? lineCrossList[i].pointers
              : null,
            objects: lineCrossList[i].lineObjects,
            thresh_conf: lineCrossList[i].thresh_conf.toString(),
            relativeCords: lineCrossList[i].relativeCords,
            name: lineCrossList[i].name
          });
        }
        definition[aiTemplateType] = { lines };
      }
      postData.lineCrossObjectData = lineCrossObjectData;
      postData.lineCrossImage = lineCrossBase64Image;
    } else if (aiTemplateType === 'volume_analysis') {
      if (lineCrossList.length) {
        let lines = {};
        for (let i = 0; i < lineCrossList.length; i++) {
          let key = lineCrossList[i].name
            ? lineCrossList[i].name
            : 'line' + (i + 1);
          Object.assign(lines, {
            [key]: {
              start_x: lineCrossList[i].relativeCords.startX
                ? lineCrossList[i].relativeCords.startX.toString()
                : '0',
              start_y: lineCrossList[i].relativeCords.startY
                ? lineCrossList[i].relativeCords.startY.toString()
                : '0',
              end_x: lineCrossList[i].relativeCords.endX
                ? lineCrossList[i].relativeCords.endX.toString()
                : '0',
              end_y: lineCrossList[i].relativeCords.endY
                ? lineCrossList[i].relativeCords.endY.toString()
                : '0'
            }
          });
          lineCrossObjectData.push({
            coordinates: lineCrossList[i].coordinates
              ? lineCrossList[i].coordinates
              : null,
            pointers: lineCrossList[i].pointers
              ? lineCrossList[i].pointers
              : null,
            relativeCords: lineCrossList[i].relativeCords,
            name: lineCrossList[i].name
          });
        }
        definition[aiTemplateType] = { lines };
      }
      postData.lineCrossObjectData = lineCrossObjectData;
      postData.lineCrossImage = lineCrossBase64Image;
    } else if (aiTemplateType === 'absence_detection') {
      if (absenceDetection.length) {
        let objects = {};
        for (let i = 0; i < absenceDetection.length; i++) {
          if (absenceDetection[i].name) {
            let key = absenceDetection[i].name;
            Object.assign(objects, {
              [key]: {
                threshold: absenceDetection[i].threshold
                  ? absenceDetection[i].threshold.toString()
                  : '0',
                number: absenceDetection[i].number
                  ? absenceDetection[i].number.toString()
                  : '0',
                condition: absenceDetection[i].condition,
                between_number: absenceDetection[i].between_number
                  ? absenceDetection[i].between_number.toString()
                  : '0',
                custom_duration: absenceDetection[i].custom_duration
                  ? absenceDetection[i].custom_duration.toString()
                  : '0',
                duration_condition: absenceDetection[i].durationCondition
                  ? absenceDetection[i].durationCondition
                  : ''
              }
            });
          }
        }
        definition[aiTemplateType] = { objects };
      }
    } else if (aiTemplateType === 'custom_object_detection') {
      if (objectDetections.length) {
        const objects = objectDetections.map(obj => {
          obj.threshold = obj.threshold ? obj.threshold.toString() : '0';
          return obj;
        });
        definition[aiTemplateType] = { objects };
      }
    } else if (aiTemplateType === 'speed_detection') {
      if (speedDetectionModel) {
        definition[aiTemplateType] = {
          ...speedDetectionModel,
          distance: parseFloat(speedDetectionModel.distance)
        };
      }
    } else {
      if (aiParamsOptions && aiParamsOptions.length) {
        for (let i = 0; i < aiParamsOptions.length; i++) {
          let item = aiParamsOptions[i];
          if (checked.includes(item.key)) {
            if (item.key === 'line_crossing_start') {
              definition[
                aiTemplateType
              ].line_params.start_x = aiParamSubFields.start_x
                  ? parseFloat(aiParamSubFields.start_x)
                  : 0;
              definition[
                aiTemplateType
              ].line_params.start_y = aiParamSubFields.start_y
                  ? parseFloat(aiParamSubFields.start_y)
                  : 0;
            } else if (item.key === 'line_crossing_end') {
              definition[
                aiTemplateType
              ].line_params.end_x = aiParamSubFields.end_x
                  ? parseFloat(aiParamSubFields.end_x)
                  : 0;
              definition[
                aiTemplateType
              ].line_params.end_y = aiParamSubFields.end_y
                  ? parseFloat(aiParamSubFields.end_y)
                  : 0;
            } else if (
              item.key === 'time' &&
              aiTemplateType === 'sack_counting'
            ) {
              definition[aiTemplateType][item.key] =
                parseInt(aiParamSubFields[item.key]) * 60;
            } else if (aiTemplateType === 'custom') {
              definition[definationName][item.key] =
                aiParamSubFields[item.key] &&
                  aiParamSubFields[item.key] == 'Yes'
                  ? true
                  : false;
            } else {
              if (item.key === 'show_person_trail' || item.key === 'enable') {
                definition[aiTemplateType][item.key] =
                  aiParamSubFields[item.key] &&
                    aiParamSubFields[item.key] == 'Yes'
                    ? true
                    : false;
              } else {
                definition[aiTemplateType][item.key] = aiParamSubFields[
                  item.key
                ]
                  ? aiParamSubFields[item.key]
                  : item.defaultValue;
              }
            }
          } else {
            if (item.fieldType !== 'text') {
              definition[aiTemplateType][item.key] = item.defaultValue;
            }
          }
          if (item.key === 'thresh_conf') {
            definition[aiTemplateType][item.key] = definition[aiTemplateType][
              item.key
            ].toString();
          }
          if (item.isThreshold) {
            definition[aiTemplateType][item.key] = definition[aiTemplateType][
              item.key
            ].toString();
          }
          if (item.isNumber) {
            definition[aiTemplateType][item.key] = parseInt(
              definition[aiTemplateType][item.key]
            );
          }
        }
      }
    }
    postData.definition = definition;
    postData.provider = providerTypes.edgeAI.includes(aiTemplateType)
      ? 'edgeai'
      : 'uncanny';
    postData.notification_frequency = notificationFrequency;
    return handleCreateMLSubmitClick(postData);
  };

  React.useEffect(() => {
    if (selectedAiType) {
      let aiTypeDtl = null;
      for (let key in aiTypes) {
        if (aiTypes[key].id === selectedAiType) {
          aiTypeDtl = aiTypes[key];
          setAiTypeName(aiTypes[key].name);
        }
      }
      setAiParamSubFields({});
      setChecked([]);
      setAiTemplateType(selectedAiType);
      // getAiParamOptions(selectedAiType);
      setAiParamOptions(
        aiTypeDtl && aiTypeDtl.params.length ? aiTypeDtl.params : []
      );
      updateAiSubFields(selectedAiType);
    }
  }, [selectedAiType]);

  const handleAddObjects = type => {
    if (type === 'acd') {
      setAccidentDetections(oldArray => [...oldArray, { ...initAcDFields }]);
    } else if (type === 'abs_detec') {
      setAbsenceDetection(oldArray => [...oldArray, { ...initAbsDetecFields }]);
    } else if (type === 'LPR') {
      setLicencePlates(oldArray => [...oldArray, { ...initLPRState }]);
    } else if (type === 'custom_object_detection') {
      setObjectDetections(oldArray => [
        ...oldArray,
        { ...initObjectDetecFields }
      ]);
    } else {
      setMultiObjects(oldArray => [...oldArray, { ...initMultiObjectFields }]);
    }
  };

  const handleObjectInputChange = (value, index, field) => {
    const updatedState = [...multiObjects];
    updatedState[index][field] = value;
    setMultiObjects(updatedState);
  };

  const handleAbsDetectInputChange = (value, index, field) => {
    const updatedState = [...absenceDetection];
    updatedState[index][field] = value;
    setAbsenceDetection(updatedState);
  };

  const handleAcdObjectInputChange = (value, index, field) => {
    const updatedState = [...accidentDetections];
    updatedState[index][field] = value;
    setAccidentDetections(updatedState);
  };

  const handleObjectDetectionChange = (value, index, field) => {
    setObjectDetections(prev => {
      const updatedState = [...prev];
      updatedState[index][field] = value;
      return updatedState;
    });
  };

  const handleAddNewFace = () => {
    setFaceRecognition(oldArray => [...oldArray, { ...initFaceRecoState }]);
  };

  const handleFaceRecoInputChange = (value, index, field) => {
    const updatedState = [...faceRecognition];
    if (field === 'image') {
      updatedState[index][field] = value.target.value;
      const file = value.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        updatedState[index]['files'] = reader.result;
      };
    } else {
      updatedState[index][field] = value;
    }
    setFaceRecognition(updatedState);
  };

  const handleLPRChange = (value, index, field) => {
    const updatedState = [...licencePlates];
    updatedState[index][field] = value;
    setLicencePlates(updatedState);
  };

  const handleRemoveMultiData = (idx, type) => {
    if (type === 'accident_detection') {
      let updatedState = [...accidentDetections];
      updatedState.splice(idx, 1);
      setAccidentDetections(updatedState);
    } else if (type === 'multi_object') {
      let updatedState = [...multiObjects];
      updatedState.splice(idx, 1);
      setMultiObjects(updatedState);
    } else if (type === 'abs_detec') {
      let updatedState = [...absenceDetection];
      updatedState.splice(idx, 1);
      setAbsenceDetection(updatedState);
    } else if (type === 'license_plate_recognition') {
      let updatedState = [...licencePlates];
      updatedState.splice(idx, 1);
      setLicencePlates(updatedState);
    } else if (type === 'custom_object_detection') {
      const temp = [...objectDetections];
      temp.splice(idx, 1);
      setObjectDetections(temp);
    } else if (type === 'face_recognition') {
      const temp = [...faceRecognition];
      temp.splice(idx, 1);
      setFaceRecognition(temp);
    }
  };

  const handleUploadLPRCsv = (csvData, fileInfo) => {
    if (csvData && csvData.length) {
      let lprCsvData = [];
      csvData.map(plate => {
        if (plate.list_type && plate.plate_number) {
          let tmpObj = {};
          tmpObj.list_type = plate.list_type;
          tmpObj.plate_number = plate.plate_number;
          tmpObj.car_description = plate.car_description
            ? plate.car_description
            : '';
          tmpObj.notification =
            plate.list_type === 'blacklist'
              ? true
              : Boolean(plate.notification);
          tmpObj.disable_noti_field =
            plate.list_type === 'blacklist' ? true : false;
          lprCsvData.push(tmpObj);
        }
      });
      setLicencePlates(lprCsvData);
    }
  };

  const handleChangeFaceRecZip = async evt => {
    let files = evt.target.files ? evt.target.files : [];
    if (files && files.length > 0) {
      let f = files[0];
      setFaceRecZip(evt.target.value);
      let csvDataArray = await JSZip.loadAsync(f).then(async zip => {
        let csvFileData = { csvData: [], imageData: [] };
        if (zip.files && Object.keys(zip.files).length > 0) {
          for (let i = 0; i < Object.keys(zip.files).length; i++) {
            let filename = Object.keys(zip.files)[i];
            if (filename) {
              let nameArr = filename.split('.');
              if (nameArr.length && nameArr.includes('csv')) {
                let csvString = await zip.file(filename).async('string');
                if (csvString) {
                  let csvStringArray = csvString.split('\r\n');
                  if (csvStringArray.length > 0) {
                    csvFileData.csvData = csvStringArray.slice(1).map(row => {
                      let temp = row.split(',');
                      return {
                        list_type: temp[0] ? temp[0] : '',
                        name: temp[1] ? temp[1] : '',
                        notification: temp[2] ? temp[2] : false
                      };
                    });
                  }
                }
              } else {
                let imgBase64 = await zip.file(filename).async('base64');
                csvFileData.imageData.push({
                  imgBase64: `data:image/${nameArr[
                    nameArr.length - 1
                  ].toLowerCase()};base64,${imgBase64}`,
                  name: filename
                });
              }
            }
          }
        }
        return csvFileData;
      });
      if (csvDataArray.imageData.length > 0) {
        let initFRArr = [];
        csvDataArray.imageData.map(itm => {
          let initObj = {};
          if (itm.name) {
            let imgNameArr = itm.name.split('.');
            initObj.name = imgNameArr.length > 0 ? imgNameArr[0] : '';
            initObj.files = itm.imgBase64;
            initObj.imageName = itm.name;
            if (csvDataArray.csvData.length) {
              const matchedData = csvDataArray.csvData.filter(csvItm => {
                if (csvItm.name && imgNameArr.includes(csvItm.name)) {
                  return csvItm;
                }
              });
              if (matchedData.length > 0) {
                initObj.list_type = matchedData[0].list_type;
                initObj.notification = matchedData[0].notification
                  ? Boolean(matchedData[0].notification)
                  : false;
                if (
                  matchedData[0].list_type &&
                  matchedData[0].list_type === 'blacklist'
                ) {
                  initObj.notification = true;
                  initObj.disable_noti_field = true;
                }
              }
            }
            initFRArr.push(initObj);
          }
        });
        setFaceRecognition(initFRArr);
      }
    } else {
      setFaceRecZip(null);
    }
    return null;
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="ml-template-modal"
      aria-describedby="ml-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle id="ml-template-title">
        {t('createNew.label')} {t('machineLearning.label')}{' '}
        {t('template.label')}
      </DialogTitle>
      <DialogContent>
        <Grid
          style={{
            ...paddingGenerator('pb-30')
          }}>
          <Grid
            style={{
              ...marginGenerator('mt-10')
            }}>
            <Grid
              style={{
                ...marginGenerator('mt-8')
              }}>
              <Grid>
                <Input
                  value={templateName}
                  type={'text'}
                  placeholder={`${t('template.label')} ${t('name.label')}*`}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  onChange={evt => setTemplateName(evt.target.value)}
                />
              </Grid>
              <Grid style={marginGenerator('mt-24')}>
                <Button
                  onClick={handleSelectAIType}
                  variant="contained"
                  color="primary">
                  {selectedAiType ? 'Change AI Type' : 'Select AI Type'}
                </Button>
              </Grid>
              {aiParamsOptions && aiParamsOptions.length > 0 && (
                <List className={classes.aiParamList}>
                  <ListSubheader>{aiTypeName}</ListSubheader>
                  {selectedAiType && selectedAiType === 'custom' && (
                    <Grid style={marginGenerator('mt-10')}>
                      <Input
                        value={definationName}
                        type={'text'}
                        placeholder={`${t('defination.label')} ${t(
                          'name.label'
                        )}*`}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setDefinationName(evt.target.value)}
                      />
                    </Grid>
                  )}
                  {(aiTemplateType === 'people_counting_alert' ||
                    aiTemplateType === 'line_cross' ||
                    aiTemplateType === 'line_cross2') && (
                      <ListItem role={undefined} dense button>
                        <a
                          style={{ display: 'table-cell' }}
                          href={
                            aiTemplateType === 'line_cross' ||
                              aiTemplateType === 'line_cross2'
                              ? '/multiLineCrosingImage'
                              : '/lineCrosingImage'
                          }
                          target="_blank"
                          rel="noopener noreferrer">
                          <Button variant={'outlined'} color="primary">
                            Get Line Crossing Axis
                          </Button>
                        </a>
                      </ListItem>
                    )}
                  {aiParamsOptions.map((item, index) => {
                    const labelId = `checkbox-list-label-${index}`;
                    return (
                      <ListItem
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleCheckAIParam(item.key)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(item.key) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={item.name} />
                        <ListItemSecondaryAction
                          style={
                            item.fieldType == 'slider'
                              ? { minWidth: '400px' }
                              : null
                          }>
                          {aiTemplateSubElem && aiTemplateSubElem[item.key]}
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}

              {selectedAiType &&
                (selectedAiType === 'line_cross' ||
                  selectedAiType === 'line_cross2') ? (
                <List className={classes.aiParamList}>
                  <ListSubheader>{aiTypeName}</ListSubheader>
                  <ListItem role={undefined} dense button>
                    <a
                      style={{ display: 'table-cell' }}
                      href={'/multiLineCrosingImage'}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button variant={'outlined'} color="primary">
                        Get Line Crossing Axis
                      </Button>
                    </a>
                  </ListItem>
                  {lineCrossList.length > 0 &&
                    lineCrossList.map((item, index) => {
                      return (
                        <ListItem key={index} role={undefined} dense button>
                          <Grid
                            container
                            spacing={3}
                            style={{
                              ...marginGenerator('mb-16'),
                              margin: 'auto'
                            }}>
                            <Grid item xs={2} style={{ margin: 'auto' }}>
                              <Typography
                                variant="subtitle1"
                                color={'primary'}
                                style={{ fontWeight: 'bold' }}>
                                {item.name} :{' '}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ margin: 'auto' }}>
                              <Typography variant="subtitle1">
                                <b>Objects :</b> {item.lineObjects.join(', ')}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ margin: 'auto' }}>
                              <Typography variant="subtitle1">
                                <b>Threshold :</b> {item.thresh_conf}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                style={{ marginBottom: '6px' }}>
                                <b>Start X : </b> {item.relativeCords.startX}
                                <b>&nbsp;, Start Y : </b>{' '}
                                {item.relativeCords.startY}
                              </Typography>
                              <Typography variant="subtitle1">
                                <b>End X :</b> {item.relativeCords.endX}
                                <b>&nbsp;, End Y :</b> {item.relativeCords.endY}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                </List>
              ) : null}

              {selectedAiType && selectedAiType === 'volume_analysis' ? (
                <List className={classes.aiParamList}>
                  <ListSubheader>{aiTypeName}</ListSubheader>
                  <ListItem role={undefined} dense button>
                    <a
                      style={{ display: 'table-cell' }}
                      href={'/volumeLineCrossImage'}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button variant={'outlined'} color="primary">
                        Get Line Crossing Axis
                      </Button>
                    </a>
                  </ListItem>
                  {lineCrossList.length > 0 &&
                    lineCrossList.map((item, index) => {
                      return (
                        <ListItem key={index} role={undefined} dense button>
                          <Grid
                            container
                            spacing={3}
                            style={{
                              ...marginGenerator('mb-16'),
                              margin: 'auto'
                            }}>
                            <Grid item xs={3} style={{ margin: 'auto' }}>
                              <Typography
                                variant="subtitle1"
                                color={'primary'}
                                style={{ fontWeight: 'bold' }}>
                                {item.name} :{' '}
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="subtitle1"
                                style={{ marginBottom: '6px' }}>
                                <b>Start X : </b> {item.relativeCords.startX}
                                <b>&nbsp;, Start Y : </b>{' '}
                                {item.relativeCords.startY}
                              </Typography>
                              <Typography variant="subtitle1">
                                <b>End X :</b> {item.relativeCords.endX}
                                <b>&nbsp;, End Y :</b> {item.relativeCords.endY}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                </List>
              ) : null}

              {selectedAiType && selectedAiType === 'multi_object' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={evt => {
                      handleAddObjects('mo');
                    }}
                    variant="outlined"
                    color="primary">
                    {t('addObject.label')}
                  </Button>
                  {multiObjects &&
                    multiObjects.length > 0 &&
                    multiObjects.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{
                            ...marginGenerator('mt-10'),
                            paddingBottom: 10
                          }}>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.name}
                              label={`${t('object.label')} ${t('name.label')}`}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{ width: '100%', marginRight: 0 }}
                              handleChange={evt => {
                                handleObjectInputChange(
                                  evt.target.value,
                                  index,
                                  'name'
                                );
                              }}
                              options={
                                multiObjectOpt && multiObjectOpt.length
                                  ? multiObjectOpt
                                  : MULTI_OBJECTS_LIST
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography id="discrete-slider" gutterBottom>
                              {t('threshold.label')}
                            </Typography>
                            <Slider
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="auto"
                              step={0.1}
                              marks
                              min={0}
                              max={1}
                              onChange={(evt, number) => {
                                handleObjectInputChange(
                                  number,
                                  index,
                                  'threshold'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <OutlinedSelect
                              val={object.number}
                              label={t('number.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              handleChange={evt => {
                                handleObjectInputChange(
                                  evt.target.value,
                                  index,
                                  'number'
                                );
                              }}
                              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.condition}
                              label={t('condition.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              handleChange={evt => {
                                handleObjectInputChange(
                                  evt.target.value,
                                  index,
                                  'condition'
                                );
                              }}
                              options={multiObjectConditions}
                            />
                          </Grid>
                          {object.condition === 'in_between' && (
                            <Grid item xs={2}>
                              <Input
                                value={object.between_number}
                                type={'number'}
                                placeholder={`${t('number.label')}*`}
                                style={{ width: '100%', minWidth: 120 }}
                                color={'secondary'}
                                onChange={evt => {
                                  handleObjectInputChange(
                                    evt.target.value,
                                    index,
                                    'between_number'
                                  );
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item xs={1} style={{ margin: 'auto' }}>
                            <Fab
                              onClick={evt => {
                                handleRemoveMultiData(index, 'multi_object');
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid #ebebeb',
                              paddingTop: 18,
                              marginLeft: 12
                            }}></div>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType && selectedAiType === 'absence_detection' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={evt => {
                      handleAddObjects('abs_detec');
                    }}
                    variant="outlined"
                    color="primary">
                    {t('addObject.label')}
                  </Button>
                  {absenceDetection &&
                    absenceDetection.length > 0 &&
                    absenceDetection.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{
                            ...marginGenerator('mt-10'),
                            paddingBottom: 10
                          }}>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.name}
                              label={`${t('object.label')} ${t('name.label')}`}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{ width: '100%', marginRight: 0 }}
                              handleChange={evt => {
                                handleAbsDetectInputChange(
                                  evt.target.value,
                                  index,
                                  'name'
                                );
                              }}
                              options={
                                multiObjectOpt && multiObjectOpt.length
                                  ? multiObjectOpt
                                  : MULTI_OBJECTS_LIST
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography id="discrete-slider" gutterBottom>
                              {t('threshold.label')}
                            </Typography>
                            <Slider
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="auto"
                              step={0.1}
                              marks
                              min={0}
                              max={1}
                              onChange={(evt, number) => {
                                handleAbsDetectInputChange(
                                  number,
                                  index,
                                  'threshold'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <OutlinedSelect
                              val={object.number}
                              label={t('number.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              handleChange={evt => {
                                handleAbsDetectInputChange(
                                  evt.target.value,
                                  index,
                                  'number'
                                );
                              }}
                              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.condition}
                              label={t('condition.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              handleChange={evt => {
                                handleAbsDetectInputChange(
                                  evt.target.value,
                                  index,
                                  'condition'
                                );
                              }}
                              options={multiObjectConditions}
                            />
                          </Grid>
                          {object.condition === 'in_between' && (
                            <Grid item xs={2}>
                              <Input
                                value={object.between_number}
                                type={'number'}
                                placeholder={`${t('number.label')}*`}
                                style={{ width: '100%', minWidth: 120 }}
                                color={'secondary'}
                                onChange={evt => {
                                  handleAbsDetectInputChange(
                                    evt.target.value,
                                    index,
                                    'between_number'
                                  );
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item xs={3}>
                            <Input
                              value={object.custom_duration}
                              type={'number'}
                              placeholder={`${t('durationMin.label')}*`}
                              style={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              color={'secondary'}
                              onChange={evt => {
                                if (
                                  evt.target.value &&
                                  IsNumeric(evt.target.value)
                                ) {
                                  if (parseInt(evt.target.value) > 120) {
                                    Message.error(
                                      'Number must be less then 120!'
                                    );
                                    return false;
                                  }
                                  handleAbsDetectInputChange(
                                    evt.target.value,
                                    index,
                                    'custom_duration'
                                  );
                                } else {
                                  handleAbsDetectInputChange(
                                    evt.target.value,
                                    index,
                                    'custom_duration'
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.durationCondition}
                              label={t('durationCondition.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 100
                              }}
                              handleChange={evt => {
                                handleAbsDetectInputChange(
                                  evt.target.value,
                                  index,
                                  'durationCondition'
                                );
                              }}
                              options={[
                                { id: 'greater', name: 'Greater then' },
                                { id: 'lesser', name: 'Lesser then' }
                              ]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Fab
                              onClick={evt => {
                                handleRemoveMultiData(index, 'abs_detec');
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid #ebebeb',
                              paddingTop: 18,
                              marginLeft: 12
                            }}></div>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType && selectedAiType === 'accident_detection' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={evt => {
                      handleAddObjects('acd');
                    }}
                    variant="outlined"
                    color="primary">
                    {t('addObject.label')}
                  </Button>
                  {accidentDetections &&
                    accidentDetections.length > 0 &&
                    accidentDetections.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{
                            ...marginGenerator('mt-10'),
                            paddingBottom: 10
                          }}>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.name}
                              label={`${t('object.label')} ${t('name.label')}`}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{ width: '100%', marginRight: 0 }}
                              handleChange={evt => {
                                handleAcdObjectInputChange(
                                  evt.target.value,
                                  index,
                                  'name'
                                );
                              }}
                              options={
                                accidentDetectionObj &&
                                  accidentDetectionObj.length
                                  ? accidentDetectionObj
                                  : ACD_OBJECTS_LIST
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography id="discrete-slider" gutterBottom>
                              {t('threshold.label')}
                            </Typography>
                            <Slider
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="auto"
                              step={0.1}
                              marks
                              min={0}
                              max={1}
                              onChange={(evt, number) => {
                                handleAcdObjectInputChange(
                                  number,
                                  index,
                                  'threshold'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography id="discrete-slider" gutterBottom>
                              {t('accidentThreshold.label')}
                            </Typography>
                            <Slider
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="auto"
                              step={0.1}
                              marks
                              min={0}
                              max={1}
                              onChange={(evt, number) => {
                                handleAcdObjectInputChange(
                                  number,
                                  index,
                                  'accident_threshold'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={1} style={{ margin: 'auto' }}>
                            <Fab
                              onClick={evt => {
                                handleRemoveMultiData(
                                  index,
                                  'accident_detection'
                                );
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                          <div
                            style={{
                              width: '100%',
                              borderBottom: '2px solid #ebebeb',
                              paddingTop: 18,
                              marginLeft: 12
                            }}></div>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType && selectedAiType === 'face_recognition' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={handleAddNewFace}
                    variant="outlined"
                    color="primary">
                    {t('addNewFace.label')}
                  </Button>
                  <div className="mlFilecontainer">
                    <Typography variant="body1" color="primary">
                      {t('mlFRZipUpload.label')}
                    </Typography>
                    <Input
                      value={faceRecZip}
                      type={'file'}
                      placeholder={`${t('name.label')}*`}
                      style={{ width: '100%', minWidth: 150 }}
                      color={'secondary'}
                      onChange={handleChangeFaceRecZip}
                    />
                  </div>
                  <Button color="primary" href={faceRecSampleZip} download>
                    Download sample zip file
                  </Button>
                  {faceRecognition.length > 0 &&
                    faceRecognition.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={1}
                          style={{ ...marginGenerator('mt-10') }}>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.list_type}
                              label={t('listType.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                width: '100%',
                                marginRight: 0,
                                minWidth: 150
                              }}
                              handleChange={evt => {
                                let newFRState = [...faceRecognition];
                                newFRState[index].list_type = evt.target.value;
                                if (
                                  evt.target.value &&
                                  evt.target.value === 'blacklist'
                                ) {
                                  newFRState[index].notification = true;
                                  newFRState[index].disable_noti_field = true;
                                } else {
                                  newFRState[index].disable_noti_field = false;
                                }
                                setFaceRecognition(newFRState);
                              }}
                              options={['blacklist', 'whitelist']}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              value={object.name}
                              type={'text'}
                              placeholder={`${t('name.label')}*`}
                              style={{ width: '100%', minWidth: 150 }}
                              color={'secondary'}
                              onChange={evt => {
                                let value = removeSpacialCharecter(
                                  evt.target.value
                                );
                                handleFaceRecoInputChange(
                                  value.replace(/\s+/g, '_'),
                                  index,
                                  'name'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              value={object.image}
                              type={'file'}
                              placeholder={`${t('faceImg.label')}*`}
                              style={{ width: '100%', minWidth: 150 }}
                              color={'secondary'}
                              inputProps={{
                                accept: 'image/png, image/jpg, image/jpeg'
                              }}
                              onChange={evt => {
                                const file = evt.target.files[0];
                                if (file) {
                                  if (/\.(jpe?g)$/i.test(file.name) === false) {
                                    Message.error(
                                      'File must be JPEG or JPG format!'
                                    );
                                    return null;
                                  }
                                  let _size = file.size;
                                  if (_size > MAX_FILE_SIZE) {
                                    Message.error(
                                      'File size must be less than 3MB!'
                                    );
                                    return null;
                                  }
                                }
                                handleFaceRecoInputChange(evt, index, 'image');
                              }}
                            />
                            {object.imageName && (
                              <Typography variant="body1">
                                {object.imageName}
                              </Typography>
                            )}
                          </Grid>
                          <Grid xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={object.notification}
                                  onChange={evt => {
                                    handleFaceRecoInputChange(
                                      evt.target.checked,
                                      index,
                                      'notification'
                                    );
                                  }}
                                  disabled={object.disable_noti_field}
                                  title={t('enableNotif.label')}
                                  color="primary"
                                />
                              }
                              label={t('notification.label')}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Fab
                              style={{ marginLeft: 10 }}
                              onClick={evt => {
                                handleRemoveMultiData(
                                  index,
                                  'face_recognition'
                                );
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType &&
                selectedAiType === 'license_plate_recognition' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={evt => {
                      handleAddObjects('LPR');
                    }}
                    variant="outlined"
                    color="primary">
                    {t('addNewLPR.label')}
                  </Button>
                  <div className="mlFilecontainer">
                    <Typography variant="body1" color="primary">
                      {t('mlCsvUpload.label')}
                    </Typography>
                    <CSVReader
                      cssClass="ml-csv-input"
                      accept=".csv"
                      cssInputClass="ml-csv-input"
                      onFileLoaded={handleUploadLPRCsv}
                      parserOptions={papaparseOptions}
                    />
                  </div>
                  <Button color="primary" href={sampleCsvfile} download>
                    Download sample file
                  </Button>
                  {licencePlates.length > 0 &&
                    licencePlates.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{ ...marginGenerator('mt-10') }}>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.list_type}
                              label={t('listType.label')}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{ width: '100%', minWidth: 160 }}
                              handleChange={evt => {
                                let newFRState = [...licencePlates];
                                newFRState[index].list_type = evt.target.value;
                                if (
                                  evt.target.value &&
                                  evt.target.value === 'blacklist'
                                ) {
                                  newFRState[index].notification = true;
                                  newFRState[index].disable_noti_field = true;
                                } else {
                                  newFRState[index].disable_noti_field = false;
                                }
                                setLicencePlates(newFRState);
                              }}
                              options={['blacklist', 'whitelist', 'unknown']}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              value={object.plate_number}
                              type={'text'}
                              placeholder={`${t('plateNumber.label')}*`}
                              style={{ width: '100%', minWidth: 160 }}
                              color={'secondary'}
                              onChange={evt => {
                                let pltNum = removeSpacialCharecter(
                                  evt.target.value
                                );
                                pltNum = pltNum.replace(/\s+/g, '');
                                handleLPRChange(
                                  pltNum.toUpperCase(),
                                  index,
                                  'plate_number'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              value={object.min_character}
                              type={'number'}
                              placeholder={`Min Character`}
                              style={{ width: '100%', minWidth: 120 }}
                              color={'secondary'}
                              onChange={evt => {
                                handleLPRChange(
                                  evt.target.value,
                                  index,
                                  'min_character'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Input
                              value={object.max_character}
                              type={'number'}
                              placeholder={`Max Character`}
                              style={{ width: '100%', minWidth: 120 }}
                              color={'secondary'}
                              onChange={evt => {
                                handleLPRChange(
                                  evt.target.value,
                                  index,
                                  'max_character'
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Input
                              value={object.car_description}
                              type={'text'}
                              multiline={true}
                              placeholder={`${t('carDescription.label')}*`}
                              style={{ width: '100%', minWidth: 160 }}
                              color={'secondary'}
                              onChange={evt => {
                                handleLPRChange(
                                  evt.target.value,
                                  index,
                                  'car_description'
                                );
                              }}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={object.notification}
                                  onChange={evt => {
                                    handleLPRChange(
                                      evt.target.checked,
                                      index,
                                      'notification'
                                    );
                                  }}
                                  disabled={object.disable_noti_field}
                                  title={t('enableNotif.label')}
                                  color="primary"
                                />
                              }
                              label={t('notification.label')}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Fab
                              style={{ marginLeft: 10 }}
                              onClick={evt => {
                                handleRemoveMultiData(
                                  index,
                                  'license_plate_recognition'
                                );
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType &&
                selectedAiType === 'custom_object_detection' ? (
                <>
                  <List className={classes.aiParamList}>
                    <ListSubheader style={{ padding: '0px' }}>
                      {aiTypeName}
                    </ListSubheader>
                  </List>
                  <Button
                    onClick={evt => {
                      handleAddObjects('custom_object_detection');
                    }}
                    variant="outlined"
                    color="primary">
                    {t('addObject.label')}
                  </Button>
                  {objectDetections &&
                    objectDetections.length > 0 &&
                    objectDetections.map((object, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{
                            ...marginGenerator('mt-10'),
                            paddingBottom: 10
                          }}>
                          <Grid item xs={3}>
                            {['absent', 'present'].map(item => (
                              <Radio
                                checked={item === object.type}
                                key={item}
                                classes={{ root: classes.objDetecType }}
                                style={{
                                  marginRight: index === 0 ? 10 : 0,
                                  ...(item === object.type
                                    ? { backgroundColor: colors.secondary.main }
                                    : '')
                                }}
                                onChange={() =>
                                  handleObjectDetectionChange(
                                    item,
                                    index,
                                    'type'
                                  )
                                }
                                value={item}
                                name="site-select"
                                checkedIcon={
                                  <div className={classes.icon}>
                                    <Typography
                                      variant={'subtitle1'}
                                      style={{ color: colors.common.white }}>
                                      {item}
                                    </Typography>
                                  </div>
                                }
                                icon={
                                  <div className={classes.icon}>
                                    <Typography
                                      variant={'subtitle1'}
                                      color={'textPrimary'}>
                                      {item}
                                    </Typography>
                                  </div>
                                }
                              />
                            ))}
                          </Grid>
                          <Grid item xs={2}>
                            <OutlinedSelect
                              val={object.base_class}
                              label={'Base Object'}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{
                                minWidth: '120px',
                                marginRight: 0
                              }}
                              handleChange={evt => {
                                handleObjectDetectionChange(
                                  evt.target.value,
                                  index,
                                  'base_class'
                                );
                              }}
                              options={
                                multiObjectOpt && multiObjectOpt.length
                                  ? multiObjectOpt
                                  : MULTI_OBJECTS_LIST
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <OutlinedSelect
                              val={object.class}
                              label={`${t('object.label')} ${t('name.label')}`}
                              selectStyle={{ ...paddingGenerator('p-8') }}
                              styleOverrides={{ width: '100%', marginRight: 0 }}
                              handleChange={evt => {
                                handleObjectDetectionChange(
                                  evt.target.value,
                                  index,
                                  'class'
                                );
                              }}
                              options={
                                customDetectionObjects &&
                                  customDetectionObjects.length > 0
                                  ? customDetectionObjects
                                  : OBJECTS_DETECTION_LIST
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography id="discrete-slider" gutterBottom>
                              {t('threshold.label')}
                            </Typography>
                            <Slider
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="auto"
                              step={0.1}
                              marks
                              min={0}
                              max={1}
                              onChange={(evt, number) =>
                                handleObjectDetectionChange(
                                  number,
                                  index,
                                  'threshold'
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={1} style={{ margin: 'auto' }}>
                            <Fab
                              onClick={evt => {
                                handleRemoveMultiData(
                                  index,
                                  'custom_object_detection'
                                );
                              }}
                              size="small"
                              color="secondary"
                              aria-label="remove"
                              title="Remove Object"
                              className={classes.margin}>
                              <DeleteForeverIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              ) : null}

              {selectedAiType && selectedAiType === 'speed_detection' ? (
                <List className={classes.aiParamList}>
                  <ListSubheader>{aiTypeName}</ListSubheader>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      marginTop: 20,
                      paddingLeft: 15,
                      marginBottom: 20
                    }}>
                    <Grid xs={2}>
                      <a
                        style={{ display: 'table-cell' }}
                        href={'/getPolygons'}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Button variant={'outlined'} color="primary">
                          Get Polygons
                        </Button>
                      </a>
                    </Grid>
                    <Grid xs={5}>
                      <Input
                        value={speedDetectionModel.distance}
                        type={'text'}
                        label={'Distance Between in Mtrs.'}
                        placeholder={'Mtrs'}
                        style={{ minWidth: 'auto', width: '100%' }}
                        color={'secondary'}
                        onChange={evt =>
                          setSpeedDetectionModel({
                            ...speedDetectionModel,
                            distance: evt.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid xs={5}>
                      <OutlinedSelect
                        val={speedDetectionModel.object}
                        label={'Select Object *'}
                        handleChange={evt =>
                          setSpeedDetectionModel({
                            ...speedDetectionModel,
                            object: evt.target.value
                          })
                        }
                        selectStyle={{ ...paddingGenerator('p-8') }}
                        styleOverrides={{ width: '100%', marginRight: 0 }}
                        options={['forklift']}
                      />
                    </Grid>
                  </Grid>
                  {speedDetectionModel.polygons.length > 0 &&
                    speedDetectionModel.polygons.map((item, index) => {
                      return (
                        <ListItem key={index} role={undefined} dense button>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              ...marginGenerator('mb-16'),
                              margin: 'auto'
                            }}>
                            <Grid item xs={6}>
                              <Typography
                                variant="subtitle1"
                                style={{ marginBottom: '6px' }}>
                                <b>X1 : </b> {item?.x1 || '-'}
                                <b>&nbsp;, Y1 : </b> {item?.y1 || '-'}
                              </Typography>
                              <Typography variant="subtitle1">
                                <b>X2 :</b> {item?.x2 || '-'}
                                <b>&nbsp;, Y2 :</b> {item?.y2 || '-'}
                              </Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography variant="subtitle1">
                                <b>X3 :</b> {item?.x2 || '-'}
                                <b>&nbsp;, Y3 :</b> {item?.y2 || '-'}
                              </Typography>
                              <Typography variant="subtitle1">
                                <b>X4 :</b> {item?.x2 || '-'}
                                <b>&nbsp;, Y4 :</b> {item?.y2 || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                </List>
              ) : null}

              {selectedAiType && providerTypes.edgeAI.includes(selectedAiType) && (
                <Grid style={marginGenerator('mt-24')}>
                  <OutlinedSelect
                    val={notificationFrequency}
                    label={`${t('notificationFreq.label')} *`}
                    handleChange={evt => {
                      setNotificationFrequency(evt.target.value);
                    }}
                    selectStyle={{ ...paddingGenerator('p-8') }}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={
                      frequencies && frequencies.length > 0
                        ? frequencies
                        : FREQUENCY_OPTIONS
                    }
                  />
                </Grid>
              )}

              <Grid
                style={{
                  ...marginGenerator('mt-34')
                }}>
                <Typography variant={'subtitle2'}>{`${t(
                  'priority.label'
                )}*`}</Typography>
                {['P1', 'P2', 'P3'].map((item, index) => (
                  <Radio
                    checked={item === priority}
                    key={index}
                    classes={{ root: classes.root }}
                    style={{
                      ...marginGenerator('mr-25'),
                      ...(item === priority
                        ? { backgroundColor: colors.secondary.main }
                        : '')
                    }}
                    onChange={() => setPriority(item)}
                    value={item}
                    name="site-select"
                    checkedIcon={
                      <div className={classes.icon}>
                        <Typography
                          variant={'subtitle1'}
                          style={{ color: colors.common.white }}>
                          {item}
                        </Typography>
                      </div>
                    }
                    icon={
                      <div className={classes.icon}>
                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                          {item}
                        </Typography>
                      </div>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              let checkValid = true;
              if (!templateName || !aiTemplateType || !priority) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              if (aiTemplateType === 'face_recognition') {
                if (faceRecognition.length > 0) {
                  for (let i = 0; i < faceRecognition.length; i++) {
                    if (
                      !faceRecognition[i].list_type ||
                      !faceRecognition[i].name ||
                      !faceRecognition[i].files
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                }
              } else if (aiTemplateType === 'multi_object') {
                if (multiObjects.length > 0) {
                  for (let i = 0; i < multiObjects.length; i++) {
                    if (
                      !multiObjects[i].name ||
                      !multiObjects[i].number ||
                      !multiObjects[i].threshold
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                } else {
                  checkValid = false;
                }
              } else if (
                aiTemplateType === 'line_cross' ||
                aiTemplateType === 'line_cross2' ||
                aiTemplateType === 'volume_analysis'
              ) {
              } else if (aiTemplateType === 'custom') {
                if (!definationName) {
                  checkValid = false;
                }
              } else if (aiTemplateType === 'accident_detection') {
                if (accidentDetections.length > 0) {
                  for (let i = 0; i < accidentDetections.length; i++) {
                    if (
                      !accidentDetections[i].name ||
                      !accidentDetections[i].threshold ||
                      !accidentDetections[i].accident_threshold
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                } else {
                  checkValid = false;
                }
              } else if (aiTemplateType === 'absence_detection') {
                if (absenceDetection.length > 0) {
                  for (let i = 0; i < absenceDetection.length; i++) {
                    if (
                      !absenceDetection[i].name ||
                      !absenceDetection[i].number ||
                      !absenceDetection[i].threshold
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                } else {
                  checkValid = false;
                }
              } else if (aiTemplateType === 'license_plate_recognition') {
                if (licencePlates.length > 0) {
                  for (let i = 0; i < licencePlates.length; i++) {
                    if (!licencePlates[i].list_type) {
                      checkValid = false;
                      break;
                    }
                    if (
                      licencePlates[i].list_type !== 'unknown' &&
                      !licencePlates[i].plate_number
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                } else {
                  checkValid = false;
                }
              } else if (aiTemplateType === 'custom_object_detection') {
                if (objectDetections.length > 0) {
                  for (let i = 0; i < objectDetections.length; i++) {
                    if (
                      !objectDetections[i].type ||
                      !objectDetections[i].class
                    ) {
                      checkValid = false;
                      break;
                    }
                  }
                } else {
                  checkValid = false;
                }
              } else if (aiTemplateType === 'speed_detection') {
                if (!speedDetectionModel.distance) {
                  checkValid = false;
                }
                if (!speedDetectionModel.object) {
                  checkValid = false;
                }
                if (!speedDetectionModel.polygons.length) {
                  checkValid = false;
                }
              } else {
                if (!aiParamSubFields || !checked.length) {
                  checkValid = false;
                }
              }

              if (
                providerTypes.edgeAI.includes(aiTemplateType) &&
                !notificationFrequency
              ) {
                checkValid = false;
              }

              if (!checkValid) {
                Message.error(REQUIRED_ERROR);
                return null;
              }
              return onCreateMLTemplateSubmit();
            }}
            loading={modalState.btnLoading}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

CreateMLTemplateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreateMLSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateMLTemplateModal);
