import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GenericTable from '../../../../components/GenericTable';

function VideoListModal({
    t,
    open,
    data,
    loading,
    camera,
    handleClose,
}) {

    const columns = [
        {
            key: "url",
            label: t("download.label")
        },
        {
            key: "startTime",
            color: "initial",
            label: t("startTime.label")
        },
        {
            key: "endTime",
            color: "initial",
            label: t("endTime.label")
        },
        {
            key: "size",
            color: "initial",
            label: t("size.label")
        },
        {
            key: "createdBy",
            color: "initial",
            label: t("createdBy.label")
        },
        {
            key: "createdAt",
            color: "initial",
            label: t("createdDate.label")
        }
    ];
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="place-template-title">Videos list for {camera}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <GenericTable 
                        list={data} 
                        loading={loading} 
                        columns={columns} 
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}


export default withTranslation()(React.memo(VideoListModal));
