import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const ExportEngineerReport = ({ csvData, fileName }) => {
    console.log("data", csvData, fileName)

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    let finalData = csvData && csvData.length && csvData.map(item => {
      return {
        Engineer : item.engineer,
        OpenTicket : item.openTicket,
        ClosedTicket: item.closedTicket,
        TotalTicket : parseInt(item.openTicket) + parseInt(item.closedTicket),
      }
    })
    const ws = XLSX.utils.json_to_sheet(finalData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      style={{float:"right"}}
      onClick={e => exportToCSV(csvData, fileName)}>
        <GetAppIcon />
    </Button>
  );
};

export default ExportEngineerReport
