import React, { useEffect, useState } from 'react';
import ChartContainer from '../../../../components/Charts/ChartContainer';
// import EventsMapOfDashboard from './EventsMapOfDashboard';
import { CAMERA_STATUS, FILTER_MODEL_TYPES } from '../../../../utils/constants';
import Grid from '@material-ui/core/Grid';
import Statcard from '../../../../components/Statcard';
import CustomTabsForEdgeAiDashBoard from './CustomTabsForEdgeAiDashBoard';
import cpu from '../../../../assets/icons/cpu.png';
import camera from '../../../../assets/icons/camera.png';
import camera_down from '../../../../assets/icons/camera_down.png';
import recording from '../../../../assets/icons/recording.png';
import { marginGenerator } from '../../../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
    GET_EDGE_DASHBOARD_COUNT,
    GET_EVENT_AND_ALERT_TIMELINE,
    GET_EVENT_AND_ALERT_MODEL,
    GET_EDGE_DASHBOARD_ML_TEMPLATE,
    GET_MAP_PLACE_LIST,
    GET_CAMERA_LIST,
    GET_EVENTS_BY_CAMERA,
    GET_PLACE_LIST,
    GET_PLACE_EVENTS,
    GET_PLACES_FOR_STATUS_MAP
} from '../../queries';
import Typography from '@material-ui/core/Typography';
import BarChartWrapper from '../../../../components/Charts/BarChartWrapper';
import commonStyles from '../../../../theme/commonStyles';
import CameraEvents from '../CameraEvents';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {
    dateFormatterLLL,
    convertUTCDateToLocalDate,
    convertLocalToUTCDate,
    getStartEndTimeForReport
} from '../../../../utils';
import moment from 'moment';
import DownCameraListModal from './DownCameraListModal';
import { colors } from '../../../../theme/colors';
import useSound from 'use-sound';
import notifySound from '../../../../assets/notify-sound.mp3';
import { useToasts } from 'react-toast-notifications'
import Notifier from "react-desktop-notification";
import CircularProgress from '@material-ui/core/CircularProgress';
import EventsMap from './EventsMap';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../../../../components/TabPanel';
// import CSVReader from 'react-csv-reader';
// import { useMutation } from '@apollo/react-hooks';
// import { CREATE_USER_GROUP_FROM_CSV } from '../../../commonMutations';

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2),
        position: "relative",
        overflow: "hidden"
    },
    dateInput: {
        width: 250,
        minWidth: 200,
        padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    }

}));

const initMapFilter = { time: 'Today', startDate: null, endDate: null, placeId: '', modelType: '' };
const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
};

export const calculateVideoTimeByCam = (cameras) => {
    let crnTimestmp = new Date().getTime();
    let totalVideoTime = 0;
    for (let i = 0; i < cameras.length; i++) {
        if (cameras[i].status === "active") {
            let camTimestmp = new Date(cameras[i].created_at).getTime();
            let camVideoTime = parseInt(crnTimestmp) - parseInt(camTimestmp);
            totalVideoTime = parseInt(totalVideoTime) + parseInt(camVideoTime);
        }
    }
    let days = Math.floor(totalVideoTime / (24 * 60 * 60 * 1000));
    let daysms = totalVideoTime % (24 * 60 * 60 * 1000);
    let hours = Math.floor((daysms) / (60 * 60 * 1000));
    let hoursms = totalVideoTime % (60 * 60 * 1000);
    let minutes = Math.floor((hoursms) / (60 * 1000));
    let minutesms = totalVideoTime % (60 * 1000);
    let sec = Math.floor((minutesms) / (1000));
    return `${days}d ${hours}h ${minutes}m ${sec}s`;
}

export const calculateCameraUpTime = (cameras) => {
    if (!cameras.length) return 0;
    const activeCamera = cameras.filter(itm => itm.status === "active" && !itm.is_deleted);
    const totalCamera = cameras.filter(itm => !itm.is_deleted);
    return Math.round(activeCamera.length / totalCamera.length * 100);
}

const EdgeAIDashBoardPanel = ({ t, subscriptionData, handleImageModalClick, aiTypes, placeList, subActiveIndex, selectedPath }) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [play] = useSound(notifySound);
    const { addToast } = useToasts();
    const [eventsAndAlertsTimelineState, setEventsAndAlertsTimelineState] = useState({ ...initMapFilter });
    const [eventsAndAlertsModelState, setEventsAndAlertsModelState] = useState({ ...initMapFilter, search: "", page: 1, limit: 20 });
    const [eventsAndAlertsMapState, setEventsAndAlertsMapState] = useState({ ...initMapFilter });
    const [eventsAndAlertsMapCameraSatusState, setEventsAndAlertsMapCameraSatusState] = useState({ ...initMapFilter });
    const [inferenceModelFilterState, setInferenceModelFilterState] = useState({ time: 'Today', modelType: '' });
    const [filteredEventAlertTimeline, setFilteredEventAlertTimeline] = useState([]);
    const [filteredEventAlertModel, setFilteredEventAlertModel] = useState([]);
    const [filteredMlModel, setFilteredMlModel] = useState([]);
    const [placeFilterList, setPlaceFilterList] = useState([]);
    const [activeFilterState, setActiveFilterState] = useState({ eam: false, eal: false, mlt: false });
    // const [inferenceModelsCheckedRow, setInferenceModelsCheckedRow] = useState([]);
    const [apiTimer, setApiTimer] = useState(0);
    const [cameraVideoTime, setCameraVideoTime] = useState(null);
    const [filterLoader, setFilterLoader] = useState(false);
    const [camDownCount, setCamDownCount] = useState(0);
    const [selectedEventCamera, setSelectedEventCamera] = useState("");
    const [eventPage, setEventPage] = useState(1);
    const [totalEventCount, setTotalEventCount] = useState(0);
    const [selectedCamEvents, setSelectedCamEvents] = useState([]);
    const [camEventsLoading, setCamEventsLoading] = useState(false);
    const [aiModelTypesFilters, setAiModelTypesFilters] = useState(FILTER_MODEL_TYPES);
    const [eventAlertTimelineLoading, setEventAlertTimelineLoading] = useState(false);
    const [showDownCamListState, setShowDownCamListState] = useState(false);
    const [downCamerasList, setDownCamerasList] = useState([]);
    const [mapPlaceList, setMapPlaceList] = useState([]);
    const [mapPlaceListForStatus, setMapPlaceListForStatus] = useState([]);
    const [mapPlaceEventsCount, setMapPlaceEventsCount] = useState(0);
    const [mapPlacesLoading, setMapPlacesLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [eventsMapInfoWindowData, setEventsMapInfoWindowData] = useState(null);
    const [eventsMapInfoWindowLoading, setEventsMapInfoWindowLoading] = useState(false);
    const [statusMapPlacesLoading, setStatusMapPlacesLoading] = useState(false);
    const [eventsCamLoading, setEventsCamLoading] = useState(false);
    const [isEventsCamPageChange, setIsEventsCamPageChange] = useState(false);
    const [activeSearchFilter, setActiveSearchFilter] = useState("");
    const [filterPlacesList, setFilterPlacesList] = useState({ eamd: [], eamp: [], east: [], eatl: [] });

    /* Edge AI Service DashBoard API */
    const organization = (subscriptionData.organizationId) ? subscriptionData.organizationId : '';

    const [getPlaceList] = useLazyQuery(GET_PLACE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            let placeFilterOptions = res.getAllPlaceList ? res.getAllPlaceList.places.map(itm => {
                return { id: itm.place_id, name: itm.name };
            }) : [];
            setFilterPlacesList({ ...filterPlacesList, [activeSearchFilter]: placeFilterOptions });
        }
    });

    useEffect(() => {
        setCamEventsLoading(true);
        let start = new Date();
        start.setHours(0, 0);
        let end = new Date();
        end.setHours(23, 59);
        start = new Date(moment(start).format('YYYY-MM-DD HH:mm'));
        end = new Date(moment(end).format('YYYY-MM-DD HH:mm'));
        setEventsAndAlertsTimelineState({ ...eventsAndAlertsTimelineState, startDate: start, endDate: end });
        setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, startDate: start, endDate: end });
        getAllCameraList({
            variables: {
                organization,
                search: "",
                page: 1,
                limit: 20000
            }
        });
        setMapPlacesLoading(true);
        getPlaceEvents({
            variables: {
                organization,
                startTime: null,
                endTime: null,
                placeId: "",
                modelName: ""
            }
        });
        setStatusMapPlacesLoading(true);
        getPlacesForStatusMap({
            variables: {
                organization,
                placeId: "",
                startTime: "",
                endTime: "",
                modelName: ""
            }
        });
    }, []);

    useEffect(() => {
        if (aiTypes && aiTypes.length) {
            let modelFiltersOpt = aiTypes.map(itm => {
                let tmpObj = {};
                if (itm.type === "multi_object") {
                    tmpObj.id = "multi-object";
                } else {
                    tmpObj.id = itm.type;
                }
                tmpObj.name = itm.name;
                return tmpObj;
            });
            if (modelFiltersOpt.length) {
                setAiModelTypesFilters(modelFiltersOpt);
            }
        }
    }, [aiTypes]);

    const getMarkerColorByEvents = (placeEvents, totalEvents) => {
        let perc = (parseFloat(placeEvents) / parseFloat(totalEvents)) * 100;
        let color = colors.common.green;
        if (!isNaN(Math.round(perc))) {
            if (Math.round(perc) > 10 && Math.round(perc) < 30) {
                color = colors.common.orange;
            } else if (Math.round(perc) > 40) {
                color = colors.common.red;
            }
        }
        return color;
    }

    const [getPlaceEvents] = useLazyQuery(GET_PLACE_EVENTS, {
        fetchPolicy: "no-cache",
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: res => {
            let data = [];
            let totalPlaceEvents = 0;
            if (res.getEventsByPlaceGraph && res.getEventsByPlaceGraph.length) {
                res.getEventsByPlaceGraph.map(itm => {
                    if (itm.count) {
                        totalPlaceEvents = totalPlaceEvents + parseInt(itm.count);
                    }
                });
                for (let i = 0; i < res.getEventsByPlaceGraph.length; i++) {
                    let item = res.getEventsByPlaceGraph[i];
                    let eventsCnt = item.count ? item.count : 0;
                    let markerColor = getMarkerColorByEvents(eventsCnt, totalPlaceEvents);
                    const eventsPercentage = parseFloat(eventsCnt) / parseFloat(totalPlaceEvents) * 100;
                    data.push({ position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) }, title: item.name, infoData: item, markerColor, eventsPercentage, placeId: item.place_id });
                }
            }
            setMapPlaceList(data);
            setMapPlaceEventsCount(totalPlaceEvents);
            setMapPlacesLoading(false);
        }
    });

    const [getMapPlaceList] = useLazyQuery(GET_MAP_PLACE_LIST, {
        fetchPolicy: "no-cache",
        onError: er => {
            setStatusMapPlacesLoading(false);
            setEventsMapInfoWindowLoading(false);
            setEventsMapInfoWindowData(null);
        },
        onCompleted: res => {
            if (eventsMapInfoWindowLoading) {
                setEventsMapInfoWindowLoading(false);
                setEventsMapInfoWindowData(res.getMapPlaceList && res.getMapPlaceList.length > 0 ? res.getMapPlaceList[0] : null);
            }
        }
    });

    const {
        loading: dashBoardCountLoading,
        data: dashBoardCountList,
        error: dashBoardCountError
    } = useQuery(GET_EDGE_DASHBOARD_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            organization: organization
        }
    });

    const [getAllCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getAllCameraList && data.getAllCameraList.cameras && data.getAllCameraList.cameras.length) {
                let camVideoTime = calculateVideoTimeByCam(data.getAllCameraList.cameras);
                setCameraVideoTime(camVideoTime);
                let faildCamConut = 0;
                let downCamList = [];
                for (let item of data.getAllCameraList.cameras) {
                    if (!item.is_deleted && item.status === CAMERA_STATUS.FAILED) {
                        faildCamConut++;
                        downCamList.push(item);
                    }
                }
                setFirstLoad(true);
                if (firstLoad) {
                    const diffCam = downCamList.filter(({ camera_id: id1 }) => !downCamerasList.some(({ camera_id: id2 }) => id2 === id1));
                    if (diffCam && diffCam.length > 0) {
                        let camDetails = diffCam[0];
                        play();
                        addToast(
                            <b>Camera - {camDetails.config && camDetails.config.camData ? camDetails.config.camData.name : ""} has been down!</b>
                            , { appearance: 'error' });
                        Notifier.focus("You have received a new event", `Camera - ${camDetails.config && camDetails.config.camData ? camDetails.config.camData.name : ""} has been down!`, window.location.href);
                    }
                }
                setDownCamerasList(downCamList);
                setCamDownCount(faildCamConut);
            }
        }
    });

    const [getEventsByCamera] = useLazyQuery(GET_EVENTS_BY_CAMERA, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getEventsByCamera) {
                setTotalEventCount(data.getEventsByCamera.count ? data.getEventsByCamera.count : 0);
                setSelectedCamEvents(data.getEventsByCamera.events ? data.getEventsByCamera.events : []);
                setCamEventsLoading(false);
            }
        },
        onError: error => {
            setSelectedCamEvents([]);
            setTotalEventCount(0);
            setCamEventsLoading(false);
        }
    });

    const [getPlacesForStatusMap] = useLazyQuery(GET_PLACES_FOR_STATUS_MAP, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            const placesData = [];
            if (res.getPlacesForStatusMap && res.getPlacesForStatusMap.length) {
                for (let i = 0; i < res.getPlacesForStatusMap.length; i++) {
                    let item = res.getPlacesForStatusMap[i];
                    placesData.push({ placeId: item.place_id, position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) }, title: item.name, infoData: item.ag_data });
                }
            }
            setStatusMapPlacesLoading(false);
            setMapPlaceListForStatus(placesData);
        },
        onError: error => {
            setStatusMapPlacesLoading(false);
        }
    });

    useEffect(() => {
        if (selectedEventCamera) {
            setEventPage(1);
            setCamEventsLoading(true);
            let period = (eventsAndAlertsModelState.time) ? eventsAndAlertsModelState.time : 'Today';
            let startTime = getStartEndTimeForReport(period).startTime;
            let endTime = getStartEndTimeForReport(period).endTime;
            if (eventsAndAlertsModelState.startDate && eventsAndAlertsModelState.endDate) {
                startTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.startDate));
                startTime = Math.floor(startTime.getTime() / 1000);
                endTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.endDate));
                endTime = Math.floor(endTime.getTime() / 1000);
            }
            setApiTimer(apiTimer + 1);
            return getEventsByCamera({
                variables: {
                    cameraId: selectedEventCamera,
                    startTime: startTime,
                    endTime: endTime,
                    organization,
                    page: eventPage,
                    modelType: eventsAndAlertsModelState.modelType
                }
            });
        } else {
            setSelectedCamEvents([]);
        }
    }, [selectedEventCamera]);

    const [getFilterEventAlertTimelineData] = useLazyQuery(GET_EVENT_AND_ALERT_TIMELINE, {
        fetchPolicy: 'no-cache',
        onError: er => {
            setEventAlertTimelineLoading(false);
        },
        onCompleted: data => {
            setEventAlertTimelineLoading(false);
            if (data.getEventAndAlertTimelineData) {
                const filterdData = data.getEventAndAlertTimelineData.map(itm => {
                    let date = new Date(itm.date);
                    date = moment(date).format('YYYY-MM-DD HH:SS');
                    return { date: date, count: itm.count }
                });
                setActiveFilterState({ ...activeFilterState, eal: true });
                setFilteredEventAlertTimeline(filterdData);
            }
        }
    });

    const [getFilterEventAlertModelData] = useLazyQuery(GET_EVENT_AND_ALERT_MODEL, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setFilterLoader(false);
            if (data.getEventAndAlertModelData && data.getEventAndAlertModelData.length > 0 && data.getEventAndAlertModelData[0].camera_id) {
                if (!selectedEventCamera || isEventsCamPageChange) {
                    setSelectedEventCamera(data.getEventAndAlertModelData[0].camera_id)
                }
            } else {
                setCamEventsLoading(false);
            }
            setIsEventsCamPageChange(false);
            setFilteredEventAlertModel(data.getEventAndAlertModelData ? data.getEventAndAlertModelData : []);
            setActiveFilterState({ ...activeFilterState, eam: true });
            setEventsCamLoading(false);
        },
        onError: error => {
            setFilterLoader(false);
            setEventsCamLoading(false);
        }
    });

    const [getFilterMlModelData] = useLazyQuery(GET_EDGE_DASHBOARD_ML_TEMPLATE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            let mlData = data.getDashboardMLTemplateData;
            if (inferenceModelFilterState.modelType) {
                mlData = mlData.filter(function (item) {
                    return item.model_type === inferenceModelFilterState.modelType;
                });
            }
            setFilteredMlModel(mlData);
            setActiveFilterState({ ...activeFilterState, mlt: true });
        }
    });

    let statsData = {
        noOfCameras: (!dashBoardCountError && !dashBoardCountLoading && dashBoardCountList.getEdgeDashboardCount) ? dashBoardCountList.getEdgeDashboardCount.activeCameras : 0,
        videoTime: cameraVideoTime ? cameraVideoTime : '0d 0h 0m 0s',
        inferenceModels: (!dashBoardCountError && !dashBoardCountLoading && dashBoardCountList.getEdgeDashboardCount) ? dashBoardCountList.getEdgeDashboardCount.models : 0
    };

    const statList = [
        {
            label: t('noOfCameras.label'),
            value: statsData.noOfCameras,
            icon: camera,
            type: "cam_active"
        },
        {
            label: t('camerasDown.label'),
            value: camDownCount,
            icon: camera_down,
            type: "cam_down"
        },
        {
            label: t('videoTime.label'),
            value: statsData.videoTime,
            icon: recording,
            type: "video_time"
        },
        {
            label: t('inferenceModels.label'),
            value: statsData.inferenceModels,
            icon: cpu,
            type: "inference_models"
        }
    ];

    const ReCallAPIEvery30Second = () => {
        let period = (eventsAndAlertsTimelineState.time) ? eventsAndAlertsTimelineState.time : 'Today';
        let startTime = getStartEndTimeForReport(period).startTime;
        let endTime = getStartEndTimeForReport(period).endTime;
        if (eventsAndAlertsTimelineState.startDate && eventsAndAlertsTimelineState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsTimelineState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsTimelineState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        getFilterEventAlertTimelineData({
            variables: {
                organization: organization,
                startTime: startTime,
                endTime: endTime,
                placeId: (eventsAndAlertsTimelineState.placeId && eventsAndAlertsTimelineState.placeId.id) ? eventsAndAlertsTimelineState.placeId.id : ""
            }
        }
        );

        period = (eventsAndAlertsModelState.time) ? eventsAndAlertsModelState.time : 'Today';
        startTime = getStartEndTimeForReport(period).startTime;
        endTime = getStartEndTimeForReport(period).endTime;
        if (eventsAndAlertsModelState.startDate && eventsAndAlertsModelState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        getFilterEventAlertModelData({
            variables: {
                organization: organization,
                startTime: startTime,
                endTime: endTime,
                modelType: eventsAndAlertsModelState.modelType,
                page: eventsAndAlertsModelState.page,
                limit: eventsAndAlertsModelState.limit
            }
        }
        );
        if (selectedEventCamera) {
            let period = (eventsAndAlertsModelState.time) ? eventsAndAlertsModelState.time : 'Today';
            let startTime = getStartEndTimeForReport(period).startTime;
            let endTime = getStartEndTimeForReport(period).endTime;
            if (eventsAndAlertsModelState.startDate && eventsAndAlertsModelState.endDate) {
                startTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.startDate));
                startTime = Math.floor(startTime.getTime() / 1000);
                endTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.endDate));
                endTime = Math.floor(endTime.getTime() / 1000);
            }
            getEventsByCamera({
                variables: {
                    cameraId: selectedEventCamera,
                    startTime: startTime,
                    endTime: endTime,
                    organization,
                    page: eventPage,
                    modelType: eventsAndAlertsModelState.modelType
                }
            });
        }

        period = (inferenceModelFilterState.time) ? inferenceModelFilterState.time : 'Today';
        getFilterMlModelData({
            variables: {
                organization: organization,
                startTime: getStartEndTimeForReport(period).startTime,
                endTime: getStartEndTimeForReport(period).endTime
            }
        });
        getAllCameraList({
            variables: {
                organization,
                search: "",
                page: 1,
                limit: 20000
            }
        });
    }

    const handleAutoPlaceSearch = (type, value) => {
        setActiveSearchFilter(type);
        if (!value) {
            if (placeList && placeList.length > 0) {
                let placeFilterOptions = placeList.map(itm => {
                    return { id: itm.place_id, name: itm.name };
                });
                setFilterPlacesList({ ...filterPlacesList, [type]: placeFilterOptions });
            }
        } else {
            setFilterPlacesList({ ...filterPlacesList, [type]: [] });
            return getPlaceList({
                variables: {
                    organization,
                    search: value,
                    limit: 20,
                    page: 1
                }
            });
        }
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setApiTimer(apiTimer + 1);
            ReCallAPIEvery30Second();
        }, 30000);
        return () => {
            clearTimeout(timer);
        };
    }, [apiTimer]);

    React.useEffect(() => {
        let period = (eventsAndAlertsTimelineState.time) ? eventsAndAlertsTimelineState.time : 'Today';
        let startTime = getStartEndTimeForReport(period).startTime;
        let endTime = getStartEndTimeForReport(period).endTime;
        if (eventsAndAlertsTimelineState.startDate && eventsAndAlertsTimelineState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsTimelineState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsTimelineState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        setEventAlertTimelineLoading(true);
        getFilterEventAlertTimelineData({
            variables: {
                organization: organization,
                startTime: startTime,
                endTime: endTime,
                placeId: (eventsAndAlertsTimelineState.placeId && eventsAndAlertsTimelineState.placeId.id) ? eventsAndAlertsTimelineState.placeId.id : ""
            }
        }
        );
        setApiTimer(apiTimer + 1);
    }, [eventsAndAlertsTimelineState]);

    React.useEffect(() => {
        let period = (eventsAndAlertsModelState.time) ? eventsAndAlertsModelState.time : 'Today';
        let startTime = getStartEndTimeForReport(period).startTime;
        let endTime = getStartEndTimeForReport(period).endTime;
        if (eventsAndAlertsModelState.startDate && eventsAndAlertsModelState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        getFilterEventAlertModelData({
            variables: {
                organization: organization,
                startTime: startTime,
                endTime: endTime,
                modelType: eventsAndAlertsModelState.modelType,
                placeId: eventsAndAlertsModelState.placeId ? eventsAndAlertsModelState.placeId.id : "",
                search: eventsAndAlertsModelState.search,
                page: eventsAndAlertsModelState.page,
                limit: eventsAndAlertsModelState.limit
            }
        });
        setApiTimer(apiTimer + 1);
    }, [eventsAndAlertsModelState]);

    React.useEffect(() => {
        let period = (inferenceModelFilterState.time) ? inferenceModelFilterState.time : 'Today';
        getFilterMlModelData({
            variables: {
                organization: organization,
                startTime: getStartEndTimeForReport(period).startTime,
                endTime: getStartEndTimeForReport(period).endTime
            }
        });
        setApiTimer(apiTimer + 1);
    }, [inferenceModelFilterState]);

    React.useEffect(() => {
        setMapPlacesLoading(true);
        let startTime = "";
        let endTime = "";
        if (eventsAndAlertsMapState.startDate && eventsAndAlertsMapState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }

        setEventsMapInfoWindowData(null);
        getPlaceEvents({
            variables: {
                organization,
                startTime: startTime ? startTime : null,
                endTime: endTime ? endTime : null,
                modelName: eventsAndAlertsMapState.modelType,
                placeId: eventsAndAlertsMapState.placeId ? eventsAndAlertsMapState.placeId.id : "",
            }
        });
    }, [eventsAndAlertsMapState]);

    React.useEffect(() => {
        let startTime = "";
        let endTime = "";
        if (eventsAndAlertsMapCameraSatusState.startDate && eventsAndAlertsMapCameraSatusState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapCameraSatusState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapCameraSatusState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        setStatusMapPlacesLoading(true);
        getPlacesForStatusMap({
            variables: {
                organization: organization,
                startTime: startTime.toString(),
                endTime: endTime.toString(),
                placeId: eventsAndAlertsMapCameraSatusState.placeId ? eventsAndAlertsMapCameraSatusState.placeId.id : "",
                modelName: eventsAndAlertsMapCameraSatusState.modelType ? eventsAndAlertsMapCameraSatusState.modelType : ""
            }
        }
        );
    }, [eventsAndAlertsMapCameraSatusState]);

    React.useEffect(() => {
        if (placeList && placeList.length > 0) {
            let placeFilterOptions = placeList.map(itm => {
                return { id: itm.place_id, name: itm.name };
            });
            setFilterPlacesList({ eamd: placeFilterOptions, eamp: placeFilterOptions, east: placeFilterOptions, eatl: placeFilterOptions });
        }
    }, [placeList]);

    const eventAlertTimelineFilter = [
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsTimelineState({ ...eventsAndAlertsTimelineState, startDate: newDate });
            },
            val: eventsAndAlertsTimelineState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsTimelineState({ ...eventsAndAlertsTimelineState, endDate: newDate })
            },
            val: eventsAndAlertsTimelineState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    handleAutoPlaceSearch("eatl", "");
                }
                setEventsAndAlertsTimelineState({ ...eventsAndAlertsTimelineState, placeId: newValue });
            },
            val: eventsAndAlertsTimelineState.placeId,
            options: filterPlacesList.eatl,
            loading: false,
            type: "autocomplete",
            onInputChange: (val) => {
                handleAutoPlaceSearch("eatl", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("eatl", "");
            },
            styleOverrides: { marginRight: 0, display: "inline-block", width: 220, marginTop: 10 },
        }
    ];

    const eventAlertModelFilter = [
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterLoader(true);
                setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, startDate: newDate, page: 1 });
            },
            val: eventsAndAlertsModelState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterLoader(true);
                setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, endDate: newDate, page: 1 })
            },
            val: eventsAndAlertsModelState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    handleAutoPlaceSearch("eamd", "");
                }
                setFilterLoader(true);
                setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, placeId: newValue, page: 1 });
            },
            onInputChange: (val) => {
                handleAutoPlaceSearch("eamd", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("eamd", "");
            },
            val: eventsAndAlertsModelState.placeId,
            options: filterPlacesList.eamd,
            loading: false,
            type: "autocomplete",
            styleOverrides: { marginRight: 0, display: "inline-block", width: 220, marginTop: 10 },
        },
        {
            label: t('models.label'),
            handleChange: evt => {
                setFilterLoader(true);
                setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, modelType: evt.target.value, page: 1 });
            },
            val: eventsAndAlertsModelState.modelType,
            options: aiModelTypesFilters,
            styleOverrides: { marginLeft: 10, marginTop: 10, width: 220 }
        }
    ];

    const eventAlertMapFilter = [
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsMapState({ ...eventsAndAlertsMapState, startDate: newDate });
            },
            val: eventsAndAlertsMapState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsMapState({ ...eventsAndAlertsMapState, endDate: newDate })
            },
            val: eventsAndAlertsMapState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    handleAutoPlaceSearch("eamp", "");
                }
                setEventsAndAlertsMapState({ ...eventsAndAlertsMapState, placeId: newValue });
            },
            onInputChange: (val) => {
                handleAutoPlaceSearch("eamp", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("eamp", "");
            },
            val: eventsAndAlertsMapState.placeId,
            options: filterPlacesList.eamp,
            loading: false,
            type: "autocomplete",
            styleOverrides: { marginRight: 0, display: "inline-block", width: 260, marginTop: 10 },
        },
        {
            label: t('models.label'),
            handleChange: evt => {
                setEventsAndAlertsMapState({ ...eventsAndAlertsMapState, modelType: evt.target.value });
            },
            val: eventsAndAlertsMapState.modelType,
            options: aiModelTypesFilters,
            styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
        }
    ];

    const eventAlertCameraStatusMapFilter = [
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if (!newValue) {
                    handleAutoPlaceSearch("east", "");
                }
                setEventsAndAlertsMapCameraSatusState({ ...eventsAndAlertsMapCameraSatusState, placeId: newValue });
            },
            onInputChange: (val) => {
                handleAutoPlaceSearch("east", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("east", "");
            },
            val: eventsAndAlertsMapCameraSatusState.placeId,
            options: filterPlacesList.east,
            loading: false,
            type: "autocomplete",
            styleOverrides: { marginRight: 0, display: "inline-block", width: 260, marginTop: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsMapCameraSatusState({ ...eventsAndAlertsMapCameraSatusState, startDate: newDate });
            },
            val: eventsAndAlertsMapCameraSatusState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setEventsAndAlertsMapCameraSatusState({ ...eventsAndAlertsMapCameraSatusState, endDate: newDate })
            },
            val: eventsAndAlertsMapCameraSatusState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
    ];

    // const inferenceModelFilter = [
    //     {
    //         label: 'Period',
    //         handleChange: evt => setInferenceModelFilterState({
    //             ...inferenceModelFilterState,
    //             time: evt.target.value
    //         }),
    //         val: inferenceModelFilterState.time,
    //         options: TIME_FILTER
    //     },
    //     {
    //         label: t('models.label'),
    //         handleChange: evt => {
    //             setInferenceModelFilterState({...inferenceModelFilterState, modelType: evt.target.value});
    //         },
    //         val: inferenceModelFilterState.modelType,
    //         options: ['LineCrossing', 'Behavioural', 'MultiplePerson', 'HeatMap'],
    //         styleOverrides: { marginRight: 0 }
    //     }
    // ];

    const inferenceModelsColumns = [
        {
            label: t('model.label'),
            key: 'acl'
        },
        {
            label: t('createdBy.label'),
            key: 'created_by_name'
        },
        {
            label: t('createdDate.label'),
            key: 'created_at'
        },
        {
            label: t('status.label'),
            key: 'status'
        },
        {
            label: t('lastUpdated.label'),
            key: 'lastUpdated'
        }
    ];

    const handleListCameraEvents = (item) => {
        setSelectedEventCamera(item.camera_id);
    }

    const handleEventsPageChange = (e, value) => {
        setEventPage(value);
        if (selectedEventCamera) {
            setCamEventsLoading(true);
            let period = (eventsAndAlertsModelState.time) ? eventsAndAlertsModelState.time : 'Today';
            let startTime = getStartEndTimeForReport(period).startTime;
            let endTime = getStartEndTimeForReport(period).endTime;
            if (eventsAndAlertsModelState.startDate && eventsAndAlertsModelState.endDate) {
                startTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.startDate));
                startTime = Math.floor(startTime.getTime() / 1000);
                endTime = new Date(convertLocalToUTCDate(eventsAndAlertsModelState.endDate));
                endTime = Math.floor(endTime.getTime() / 1000);
            }
            return getEventsByCamera({
                variables: {
                    cameraId: selectedEventCamera,
                    startTime: startTime,
                    endTime: endTime,
                    organization,
                    page: value,
                    modelType: eventsAndAlertsModelState.modelType
                }
            });
        } else {
            setSelectedCamEvents([]);
        }
    }

    const handleStatCardClick = (type) => {
        if (type === "cam_down") {
            setShowDownCamListState(true);
        }
        return null;
    }

    const handleClearFilter = (type) => {
        if (type === "place_map") {
            setEventsAndAlertsMapState({ ...initMapFilter });
        } else if (type === "event_timeline") {
            setEventsAndAlertsTimelineState({ ...initMapFilter });
        } else if (type === "event_model") {
            setEventsAndAlertsModelState({ ...initMapFilter });
        }
    }

    const currentTabValue = tabValue => {
        setActiveIndex(tabValue);
    };

    const handleMarkerClick = (placeId) => {
        setEventsMapInfoWindowLoading(true);
        let startTime = "";
        let endTime = "";
        if (eventsAndAlertsMapState.startDate && eventsAndAlertsMapState.endDate) {
            startTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(eventsAndAlertsMapState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }
        getMapPlaceList({
            variables: {
                organization: organization,
                startTime: startTime ? startTime.toString() : null,
                endTime: endTime ? endTime.toString() : null,
                modelName: eventsAndAlertsMapState.modelType ? eventsAndAlertsMapState.modelType : "",
                placeId: placeId
            }
        });
    }

    const closeInfoWindow = () => {
        setEventsMapInfoWindowData(null);
    }

    const handleEventsCameraPageChange = (e, val) => {
        setFilterLoader(true);
        setIsEventsCamPageChange(true);
        setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, page: val });
    }

    const handleKeyPressSearch = (e) => {
        if (e.key === "Enter") {
            setFilterLoader(true);
            setEventsAndAlertsModelState({ ...eventsAndAlertsModelState, page: 1, search: e.target.value });
        }
    }

    // const [uploadUserFromCsv] = useMutation(CREATE_USER_GROUP_FROM_CSV, {
    //     onCompleted: (res) => {
    //         console.log("RES: ", res);
    //     }
    // });

    // const handleUploadUserCsvFile = (csvData, fileInfo) => {
    //     if (csvData && csvData.length) {
    //         let getCsvData = [];
    //         csvData.map(ac => {
    //             if (ac.account_name___ && ac.account_owner__full_name && ac.primary_contact_email_id) {
    //                 let nameArr = ac.account_owner__full_name.split(" ");
    //                 if(ac.product_name !== "DIA" && ac.product_name !== "EDGE AI" && ac.product_name !== "MPLS" && ac.product_name !== "SDN"){
    //                     getCsvData.push({
    //                         firstname: (nameArr.length > 0) ? nameArr[0] : "",
    //                         lastname: (nameArr.length > 1) ? nameArr[1] : '',
    //                         emailId: ac.primary_contact_email_id,
    //                         group: ac.account_name___,
    //                         product_name: ac.product_name,
    //                         siteName: "Botswana"
    //                     });
    //                 }
    //             }
    //         });
    //         return uploadUserFromCsv({
    //             variables: {
    //                 users: JSON.stringify(getCsvData)
    //             }
    //         });
    //     }
    // };

    const tabList = [
        {
            label: t('cameraEventsMap.label'),
            children: (
                <>
                    <ChartContainer
                        title={null}
                        filterList={eventAlertMapFilter}
                        clearFilter={evt => { handleClearFilter("place_map") }}>
                        <Grid
                            container
                            className={classes.mapContainer}
                            style={marginGenerator('mt-32')}>
                            {mapPlacesLoading ? <div className={classes.infoWindowLoading}>
                                <CircularProgress color="secondary" />
                            </div> :
                                <EventsMap
                                    id={'events-map'}
                                    markers={mapPlaceList}
                                    handleMarkerClick={handleMarkerClick}
                                    closeInfoWindow={closeInfoWindow}
                                    infoWindowData={eventsMapInfoWindowData}
                                    infoWindowLoading={eventsMapInfoWindowLoading}
                                />
                            }
                        </Grid>
                    </ChartContainer>
                </>)
        },
        {
            label: t('cameraStatusMap.lable'),
            children: (
                <>
                    <ChartContainer
                        title={null}
                        filterList={eventAlertCameraStatusMapFilter}>
                        <Grid
                            container
                            className={classes.mapContainer}
                            style={marginGenerator('mt-32')}>
                            {statusMapPlacesLoading ?
                                <div className={classes.infoWindowLoading}>
                                    <CircularProgress color="secondary" />
                                </div>
                                :
                                // <Map 
                                //     id={'sites-map-status'} 
                                //     markers={mapPlaceListForStatus.length > 0 ? mapPlaceListForStatus : mapPlaceList}
                                //     handleSetPosition={handleSetPositionBase}
                                //     position={
                                //     locationState &&
                                //     locationState['latitude'] &&
                                //     locationState['longitude']
                                //     ? {
                                //         lat: parseFloat(locationState['latitude']),
                                //         lng: parseFloat(locationState['longitude'])
                                //     } : null}
                                // />}
                                <EventsMap
                                    id={'cam-status-map'}
                                    markers={mapPlaceListForStatus.length > 0 ? mapPlaceListForStatus : []}
                                    handleMarkerClick={null}
                                    closeInfoWindow={closeInfoWindow}
                                    infoWindowData={null}
                                    infoWindowLoading={false}
                                />
                            }
                        </Grid>
                    </ChartContainer>
                </>
            )
        },
    ]

    return (
        <div>
            {/* <CSVReader
                inputStyle={{ width: '100%', color: 'secondary', border: 'black 5px' }}
                cssClass="ml-csv-input"
                accept=".csv"
                cssInputClass="ml-csv-input"
                onFileLoaded={handleUploadUserCsvFile}
                parserOptions={papaparseOptions}
            /> */}
            {/* <CustomTabsForEdgeAiDashBoard
                activeIndex={activeIndex}
                currentTabValue={currentTabValue}
                tabs={tabList}
            /> */}
            {selectedPath === "Dashboard" && <> {tabList[subActiveIndex].children}</>}
            <Grid
                container
                justify={'space-between'}
                style={marginGenerator('mt-56')}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                        handleClick={evt => {
                            handleStatCardClick(item.type);
                        }}
                    />
                ))}
            </Grid>
            <ChartContainer
                title={t('eventsAndAlerts.label')}
                filterList={eventAlertTimelineFilter}
                clearFilter={evt => { handleClearFilter("event_timeline") }}>
                <BarChartWrapper
                    data={activeFilterState.eal ? filteredEventAlertTimeline : []}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                    loading={eventAlertTimelineLoading}
                />
            </ChartContainer>
            <ChartContainer
                title={t('eventsAndAlertsModel.label')}
                filterList={eventAlertModelFilter}
                clearFilter={evt => { handleClearFilter("event_model") }}>
                <CameraEvents
                    t={t}
                    data={(activeFilterState.eam) ? filteredEventAlertModel : []}
                    handleImageModalClick={handleImageModalClick}
                    filterLoader={filterLoader}
                    handleListCameraEvents={handleListCameraEvents}
                    handleEventsPageChange={handleEventsPageChange}
                    eventsData={selectedCamEvents}
                    totalEventCount={totalEventCount}
                    selectedCamera={selectedEventCamera}
                    camEventsLoading={camEventsLoading}
                    eventPage={eventPage}
                    handleEventsCameraPageChange={handleEventsCameraPageChange}
                    eventsCamPage={eventsAndAlertsModelState.page}
                    handleKeyPressSearch={handleKeyPressSearch}
                />
            </ChartContainer>
            <ChartContainer
                title={`${t('machineLearning.label')} ${t('template.label')}`}
                filterList={null}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {inferenceModelsColumns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeFilterState.mlt && filteredMlModel.length ?
                                filteredMlModel.map((rowitem, rowIndex) => (
                                    <TableRow key={rowIndex} >
                                        <TableCell key={rowIndex}>
                                            <Grid className={commonClasses.column}>
                                                {/* <Grid>
                                        <Checkbox 
                                            checked={inferenceModelsCheckedRow[rowIndex]} 
                                            value={rowitem.id}
                                            onChange={(event) => {
                                                setInferenceModelsCheckedRow([...inferenceModelsCheckedRow, inferenceModelsCheckedRow[rowIndex] = event.target.checked]);
                                            }}
                                        />
                                        </Grid> */}
                                                <Grid>
                                                    <Typography variant={'subtitle1'} >
                                                        {rowitem.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} >
                                                        {rowitem.created_by_name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} >
                                                        {dateFormatterLLL(rowitem.created_at)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} >
                                                        {rowitem.is_active ?
                                                            <Box color="success.main">Active</Box>
                                                            :
                                                            <Box color="error.main">Inactive</Box>
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} >
                                                        {(rowitem.event && rowitem.event.length > 0) ? dateFormatterLLL(convertUTCDateToLocalDate(new Date(rowitem.event[0].event_time))) : '-'}
                                                        {/* { dateFormatterLLL(rowitem.updated_at) } */}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        {/* <TableCell>
                                    <Grid >
                                        <IconButton
                                            size={'small'}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            color={'secondary'}
                                            onClick={evt => {
                                                setAnchorEl(evt.currentTarget);
                                                evt.stopPropagation();
                                            }}
                                        >
                                        <MoreHoriz className={commonClasses.iconRegular} />
                                        </IconButton>
                                    </Grid>
                                    {anchorEl && <MenuWrapper
                                        id={'ticket-menu'}
                                        anchorEl={anchorEl}
                                        handleClose={evt => {
                                            evt.stopPropagation();
                                            setAnchorEl(null);
                                        }}
                                        menuItems={menuItems}
                                        handleMenuItemClick={item => {
                                            setAnchorEl(null);
                                        }}
                                    />}
                                </TableCell> */}
                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={inferenceModelsColumns.length}>
                                        <p>Record not found!</p>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </ChartContainer>
            {
                showDownCamListState &&
                <DownCameraListModal t={t} cameras={downCamerasList} open={showDownCamListState}
                    handleClose={evt => {
                        setShowDownCamListState(false);
                    }} />
            }
        </div >
    );
};

EdgeAIDashBoardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default EdgeAIDashBoardPanel;
