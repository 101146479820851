import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
//import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';


const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
  }));
  
  function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

  const urInitFields = {
    id: "",
    fullName: "",
    department: "",
    desgnation: "",
    section:"",
    officeNumber: "",
    cellPhone: "",
    model:"",
    modemColour:"",
    series:"",
    serialNumber: "",
    issueDate: null,
    simCardNum: "",
    termsAndCond: "",
    userDateSubmitted: null,
    name: "",
    infoDateSubmitted: null
};

const ModemIsuueForm = ({
    t,
    open,
    handleClose,
    handleOpenSignPanModal,
    data,
    signatures,
    handleCreateRequestSubmit,
    users,
    departments
}) => {
  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const [departmentSections, setDepartmentSections] = useState([]);

  React.useEffect(() => {
    if (!open) {
        setURFormState({ ...urInitFields });
        handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        const department = data.department && data.department
        const section = data.section && data.section._id
        if(data.section){
            setDepartmentSections([data.section]);
        }
        setURFormState({...data, department, section, id});
    }else{
        // setShowUserSelect(true);
        // setShowLearnDevelopment(true);
        // setShowHOD(true);
        // setShowHRManager(true);
        // setShowGeneralManager(true);
    }
}, [data]);

const handleChange = (value, name, key) => {
        setURFormState({ ...urFormState, [name]: value });
}

  return (
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose();
    }}>
    <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
        <HrHeader />
    </DialogTitle>
    <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Modem issue Form</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >USER DETAILS</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.fullName}
                            onChange={evt => handleChange(evt.target.value, 'fullName')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {/* <Input
                            type={'text'}
                            placeholder={"Department"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.department}
                            onChange={evt => handleChange(evt.target.value, 'department')}
                        /> */}
                         <OutlinedSelect
                            options={(departments && departments.length) ? departments : []}
                            label="Department"
                            selectStyle={{ ...paddingGenerator('p-4') }}
                            val={urFormState.department}
                            handleChange={(evt) => {
                                const item = departments.find(itm => evt.target.value === itm.id);
                                setDepartmentSections(item ? item.sections : []);
                                handleChange(evt.target.value, "department");
                            }}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Designation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.desgnation}
                            onChange={evt => handleChange(evt.target.value, 'desgnation')}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        {/* <Input
                            type={'text'}
                            placeholder={"Section"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.section}
                            onChange={evt => handleChange(evt.target.value, 'section')}
                        /> */}
                          <OutlinedSelect
                            options={(departmentSections && departmentSections.length) ? departmentSections : []}
                            label="Section"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.section}
                            selectStyle={{ ...paddingGenerator('p-4') }}
                            handleChange={(evt) => {
                                handleChange(evt.target.value, "section");
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Office Number (Ext)"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.officeNumber}
                            onChange={evt => handleChange(evt.target.value, 'officeNumber')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Cell phone"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.cellPhone}
                            onChange={evt => handleChange(evt.target.value, 'cellPhone')}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >MASCOM WIRELESS MODEM DETAILS</Typography>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Model"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.model}
                            onChange={evt => handleChange(evt.target.value, 'model')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Modem Colour"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.modemColour}
                            onChange={evt => handleChange(evt.target.value, 'modemColour')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Series"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.series}
                            onChange={evt => handleChange(evt.target.value, 'series')}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Serial Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.serialNumber}
                            onChange={evt => handleChange(evt.target.value, 'serialNumber')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                         <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Issue Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.issueDate}
                            handleChange={newDate => handleChange(newDate, "issueDate")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Sim Card Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.simCardNum}
                            onChange={evt => handleChange(evt.target.value, 'simCardNum')}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >USER DECLARA TION</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <RadioGroup
                            row
                            aria-label="approve_rejected"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `termsAndCond`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Approved"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.termsAndCond === 'Approved' ? true : false}
                                labelPlacement="start"
                                label="Terms and Condition"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("userSign")}
                        >
                         Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.userDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "userDateSubmitted")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >INFORMATION MANAGEMENT OFFICIAL</Typography>
                    </Grid>
                    <Grid item xs={4}>
                    {/* <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.name}
                            onChange={evt => handleChange(evt.target.value, 'name')}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.name}
                            handleChange={(evt) => handleChange(evt.target.value, "name")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("infoSign")}
                        >
                          Signature <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>  
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.infoDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "infoDateSubmitted")}
                        />
                    </Grid>
                </Grid>
    </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() =>handleCreateRequestSubmit(urFormState)}
                    loading={false}
                />
            </Grid>
        </DialogActions>
   </Dialog>
  )
}

export default ModemIsuueForm