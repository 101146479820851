import React, { useState } from 'react';
import { get } from 'lodash-es';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MLTemplateItem from '../MLTemplateItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import AssignCameraForMLTemplate from '../AssignCameraForMLTemplate';
import AttchedCamera from '../AttchedCamera';
import { GET_ATTACHED_CAMERA_LIST } from '../../queries';
import { useLazyQuery } from '@apollo/react-hooks';
function MLPanel({
  t,
  menuItems,
  list,
  handleCreateButtonClick,
  handleMenuItemClick,
  loading,
  error,
  allowConfigure,
  handleSearch,
  page,
  handlePageChange,
  cameraList,
  camLoading,
  camError,
  checkedcameraFromMlTemp,
  hadleUpdateCameraInMlTemp,
  updateCameraTempLoading,
  handleCameraSearch
}) {
  const [goToPage, setGoToPage] = useState(null);
  const [isAssignCameraEnable, setIsAssignCameraEnable] = useState(false);
  const [rowItemdata, setRowItemdata] = useState('');
  const [isAttachedCameraEnable, setIsAttachedCameraEnable] = useState(false);

  const [
    getAttachedCameraList,
    { data: attechedCameraList, loading: attechedCameraloading }
  ] = useLazyQuery(GET_ATTACHED_CAMERA_LIST, {
    fetchPolicy: 'no-cache'
  });

  let columns = [
    {
      label: t('template.label') + ' ' + t('name.label'),
      key: 'name'
    },
    {
      label: t('createdBy.label'),
      key: 'createdByName'
    },
    {
      label: t('createdDate.label'),
      key: 'createdDate'
    },
    {
      label: t('numberOfAttachedCam.label'),
      key: 'noOfCam'
    },
    {
      label: t('priority.label'),
      key: 'priority'
    }
  ];

  if (allowConfigure) {
    columns.push({
      label:
        t('edit.label') +
        '/' +
        t('delete.label') +
        '/' +
        t('assignToCamera.label'),
      key: 'button'
    });
  }

  const handleSearchChange = val => {
    handleSearch('ml_template', val);
  };

  const assignCamerahandleClose = () => {
    setRowItemdata('');
    setIsAssignCameraEnable(false);
  };

  const assignCamerahandleOpen = data => {
    setRowItemdata(data);
    setIsAssignCameraEnable(true);
  };

  const handleAttechedCameraList = rowItem => {
    setIsAttachedCameraEnable(true);
    console.log('rowItem: ', rowItem);
    return getAttachedCameraList({
      variables: {
        id: rowItem.id
      }
    });
  };

  return (
    <div>
      {allowConfigure && (
        <Grid container justify={'space-between'}>
          <Grid item>
            <SearchBox handleSearch={handleSearchChange} />
          </Grid>
          <Grid item>
            <Button
              align={'right'}
              variant={'outlined'}
              color={'primary'}
              onClick={() => handleCreateButtonClick('ml_template')}>
              {t('createNow.label')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container style={{ marginBottom: 20 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      <Typography variant={'subtitle2'}>
                        {item.label}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !error ? (
                list.getAllMlTemplateList &&
                list.getAllMlTemplateList.templates &&
                list.getAllMlTemplateList.templates.length > 0 ? (
                  list.getAllMlTemplateList.templates.map(
                    (rowitem, rowIndex) => (
                      <MLTemplateItem
                        key={rowIndex}
                        t={t}
                        data={rowitem}
                        menuItems={menuItems}
                        handleMenuItemClick={handleMenuItemClick}
                        allowConfigure={allowConfigure}
                        cameraList={cameraList}
                        camLoading={camLoading}
                        handleAttechedCameraList={handleAttechedCameraList}
                        camError={camError}
                        checkedcameraFromMlTemp={checkedcameraFromMlTemp}
                        hadleUpdateCameraInMlTemp={hadleUpdateCameraInMlTemp}
                        updateCameraTempLoading={updateCameraTempLoading}
                        assignCamerahandleOpen={assignCamerahandleOpen}
                      />
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    {loading && !error && <LinearProgress color="primary" />}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && (
            <>
              <Grid
                container
                justify={'space-between'}
                style={{
                  marginTop: 20,
                  marginBottom: 30,
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}>
                <Grid item xs={2}>
                  <span>
                    Total{' '}
                    {Math.ceil(
                      get(list, 'getAllMlTemplateList.count', null) / 10
                    )}{' '}
                    page, {get(list, 'getAllMlTemplateList.count', null)} Row
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <Pagination
                    color="secondary"
                    count={
                      list && list.getAllMlTemplateList
                        ? Math.ceil(list.getAllMlTemplateList.count / 10)
                        : 1
                    }
                    page={page}
                    onChange={(evt, value) => {
                      handlePageChange('ml_template', value);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <span>Go to page</span>
                </Grid>
                <Grid item xs={1}>
                  <Input
                    value={goToPage}
                    type="number"
                    style={{ minWidth: 100 }}
                    color={'secondary'}
                    onChange={evt => {
                      let maxRecord =
                        list && list.getAllMlTemplateList
                          ? parseInt(
                              Math.ceil(list.getAllMlTemplateList.count / 10)
                            )
                          : 0;
                      if (parseInt(evt.target.value) > maxRecord) {
                        return null;
                      }
                      setGoToPage(evt.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    onClick={() =>
                      handlePageChange('ml_template', parseInt(goToPage))
                    }
                    variant={'outlined'}
                    color="primary"
                    disabled={!goToPage}
                    style={{ marginLeft: 10 }}>
                    Go
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </TableContainer>
      </Grid>
      <AssignCameraForMLTemplate
        t={t}
        data={rowItemdata}
        title={t('assignToCamera.label')}
        isAttachedCameraEnable={isAssignCameraEnable}
        handleClose={assignCamerahandleClose}
        cameraList={cameraList}
        camLoading={camLoading}
        camError={camError}
        checkedcameraFromMlTemp={checkedcameraFromMlTemp}
        hadleUpdateCameraInMlTemp={hadleUpdateCameraInMlTemp}
        updateCameraTempLoading={updateCameraTempLoading}
        handleCameraSearch={handleCameraSearch}
      />
      <AttchedCamera
        t={t}
        rederDataType="camera"
        data={
          attechedCameraList ? attechedCameraList.getAttachedCameraList : []
        }
        loading={attechedCameraloading}
        title={t('attachedCameras.label')}
        isAttachedCameraEnable={isAttachedCameraEnable}
        handleClose={() => setIsAttachedCameraEnable(false)}
      />
    </div>
  );
}

MLPanel.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.object,
  handleCreateButtonClick: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
};
export default MLPanel;
