import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { InternalPaths } from '../../../routes/routePaths';
import CustomTabs from "../../../components/CustomTabs";
import Projets from "./components/Projects";
import InstallTeam from "./components/InstallTeam";
import ImTeam from "./components/ImTeam";
import ChangeControlTeam from "./components/ChangeControlTeam";
import IntegrationEngineer from "./components/IntegrationEngineer";
import QualityAssurance from "./components/QualityAssurance";
import ProjectManagement from "./components/ProjectManagement";
import Finance from "./components/Finance";
import Equipments from "./components/Equipments";
import HistoricalCircuitIds from "./components/HistoricalCircuitIds";
import ContactCircuitManagement from "./components/HistoricalCircuitIds/ContactCircuitManagement";
import BaseStations from "./components/HistoricalCircuitIds/BaseStations";
import Csoc from "./components/Csoc";
import ProjectTrack from "./components/ProjectTrack";
import RadioEngineer from "./components/RadioEngineer";
import OutsideProjectManagement from "./components/OutsideProjectManagement";
import RejectedProject from "./components/RejectedProject";
import Notifications from "./components/Notifications";
import Analytics from './components/Analytics';
import { USER_ROLES, COUNTRY_LIST } from "../../../utils/constants";
import Typography from "@material-ui/core/Typography";
import SpecialProjects from './components/SpecialProjects';
import StockControlTeam from './components/Malawai/Components/StockControlTeam';
import InstallTeamMalawi from './components/Malawai/Components/InstallTeamMalawi';
import BillingTeamMalawi from './components/Malawai/Components/BillingTeamMalawi';
import QualityAssuranceTeamMalawi from './components/Malawai/Components/QualityAssuranceTeamMalawi';
import InternalProjects from './components/InternalProjects';
import SolutionTeam from './components/Zambia/Components/SolutionTeam';
import ProjectManagerTeam from './components/Zambia/Components/ProjectManagerTeam';
import Pmo2Team from './components/Zambia/Components/Pmo2Team';
import ProvisioningTeam from './components/Zambia/Components/ProvisioningTeam';
import BduTeam from './components/Zambia/Components/BduTeam';
import InstallTeamZambia from './components/Zambia/Components/InstallTeamZambia';
import PMTeamZambia from './components/Zambia/Components/PMTeamZambia';
import BillingTeam from './components/Zambia/Components/BillingTeam';
import CsocTeamZambia from './components/Zambia/Components/CsocTeam';
import DeveloperTeam from './components/Botswana/Components/DeveloperTeam';
import PMTeamBotswana from './components/Botswana/Components/PMTeam';
import FinanceTeamBotswana from './components/Botswana/Components/FinanceTeam';
import ClientServiceTeam from './components/Botswana/Components/ClientServiceTeam';
import NocTeam from './components/Cameroon/Components/NocTeam';
import FieldOperationsTeam from './components/Cameroon/Components/FieldOperationsTeam';
import CsocTeamCameroon from './components/Cameroon/Components/CsocTeam';
import FinanceTeamCameroon from './components/Cameroon/Components/FinanceTeam';
import ItTeam from './components/IvoryCoast/Components/ItTeam';
import FinanceTeamIvoryCoast from './components/IvoryCoast/Components/FinanceTeam';
import DeliveryTeam from './components/IvoryCoast/Components/DeliveryTeam';
import ProjectHistoryView from './components/ProjectHistoryView';
import Button from '@material-ui/core/Button';

const breadcrumbList = [
    { label: 'home.label', link: InternalPaths.Dashboard },
    { label: 'pmoDashboard.label', link: InternalPaths.pmoDashboard }
];
const PmoDashboard = ({ t }) => {
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const [refreshTab, setRefreshTab] = useState(false);
    const handleRefreshTab = (event) => {
        setRefreshTab(event);
    }
    const inqCircuitUrl = 'https://inqcircuitid.web.app/';
    let getAllNigeriaPmoRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER, USER_ROLES.INSTALL_TEAM_LEAD, USER_ROLES.INSTALL_TEAM,
    USER_ROLES.CHANGE_CONTROL_TEAM, USER_ROLES.INTEGRATION_TEAM, USER_ROLES.QUALITY_CONTROL_TEAM, USER_ROLES.EP_FINANCE,
    USER_ROLES.CSOC_MANAGER, USER_ROLES.IM_TEAM, USER_ROLES.RADIO_ENGINEER_TEAM, USER_ROLES.INSTALL_TEAM_ADMIN, USER_ROLES.IM_TEAM_ADMIN, USER_ROLES.CHANGE_CONTROL_TEAM_ADMIN,
    USER_ROLES.INTEGRATION_TEAM_ADMIN, USER_ROLES.QUALITY_CONTROL_TEAM_ADMIN, USER_ROLES.FINANCE_TEAM_ADMIN, USER_ROLES.CSOC_TEAM_ADMIN, USER_ROLES.PMO_READ_ONLY];
    let checkNigeriaRoleResult = getAllNigeriaPmoRole.some(i => role.includes(i));
    let getAllPmoAdminRole = [USER_ROLES.PROJECT_MANAGER, USER_ROLES.PMO_ADMIN, USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_READ_ONLY];
    let checkPmoAdminRoleResult = getAllPmoAdminRole.some(i => role.includes(i));
    // get all Zambia role
    let tabs = [];
    if (COUNTRY_LIST.NIGERIA === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (checkNigeriaRoleResult) {
            tabs.push({
                label: t('internalProjects.label'),
                children: (
                    <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },{
                label: t('installTeam.label'),
                children: (
                    <InstallTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: "IM",
                children: (
                    <ImTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: "Control",
                children: (
                    <ChangeControlTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: 'Integration',
                children: (
                    <IntegrationEngineer t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: 'Quality',
                children: (
                    <QualityAssurance t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: 'PMO',
                children: (
                    <ProjectManagement t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.EP_FINANCE) || role.includes(USER_ROLES.FINANCE_TEAM_ADMIN) || checkPmoAdminRoleResult) {
            tabs.push({
                label: 'Finance',
                children: (
                    <Finance t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (role.includes(USER_ROLES.CSOC_MANAGER) || role.includes(USER_ROLES.CSOC_TEAM_ADMIN) || checkPmoAdminRoleResult) {
            tabs.push({
                label: 'CSOC',
                children: (
                    <Csoc t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (checkNigeriaRoleResult) {
            tabs.push({
                label: t('historicalCircuitIds.label'),
                children: (
                    <HistoricalCircuitIds t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: t('ProjectTrack.label'),
                children: (
                    <ProjectTrack t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        if (checkNigeriaRoleResult) {
            tabs.push({
                label: t('contactcircuitmanagement.label'),
                children: (
                    <ContactCircuitManagement t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, 
            {
                label: t('basestations.label'),
                children: (
                    <BaseStations t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: t('ProjectTrack.label'),
                children: (
                    <ProjectTrack t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }

        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                children: (
                    <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }, {
                label: t('specialProjects.label'),
                children: (
                    <SpecialProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
    } else if (COUNTRY_LIST.MALAWI === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        tabs.push({
            label: t('internalProjects.label'),
            children: (
                <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },{
            label: t('stockControl.label'),
            children: (
                <StockControlTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },
            {
                label: t('installTeam.label'),
                children: (
                    <InstallTeamMalawi t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: 'Quality Assurance',
                children: (
                    <QualityAssuranceTeamMalawi t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: 'Billing',
                children: (
                    <BillingTeamMalawi t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },)
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                    children: (
                        <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                    )
            })
        }
    } else if (COUNTRY_LIST.ZAMBIA === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        tabs.push({
            label: t('internalProjects.label'),
            children: (
                <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },
            // {
            //     label: 'Solution Team',
            //     children: (
            //         <SolutionTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            //     )
            // },
            {
                label: 'PMO 2',
                children: (
                    <Pmo2Team t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            // {
            //     label: 'PM Team',
            //     children: (
            //         <ProjectManagerTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            //     )
            // },
            {
                label: 'BDU Team',
                children: (
                    <BduTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: 'Provisioning',
                children: (
                    <ProvisioningTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: t('installTeam.label'),
                children: (
                    <InstallTeamZambia t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: 'PM',
                children: (
                    <PMTeamZambia t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: t('billing.label'),
                children: (
                    <BillingTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: 'CSOC',
                children: (
                    <CsocTeamZambia t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            },
            {
                label: t('ProjectTrack.label'),
                children: (
                    <ProjectTrack t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            }
        )
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                    children: (
                        <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                    )
            })
        }
    } else if (COUNTRY_LIST.BOTSWANA === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        tabs.push({
            label: t('internalProjects.label'),
            children: (
                <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },{
            label: 'Developer/Engineer',
            children: (
                <DeveloperTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: 'PMO',
            children: (
                <PMTeamBotswana t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: 'Finance Team',
            children: (
                <FinanceTeamBotswana t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: 'Client Service',
            children: (
                <ClientServiceTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                    children: (
                        <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                    )
            })
        }
    } else if (COUNTRY_LIST.CAMEROON === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        tabs.push({
            label: t('internalProjects.label'),
            children: (
                <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },{
            label: 'NOC',
            children: (
                <NocTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: 'Field Operation',
            children: (
                <FieldOperationsTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: 'CSOC',
            children: (
                <CsocTeamCameroon t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },{
            label: 'Service Delivery',
            children: (
                <FinanceTeamCameroon t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                    children: (
                        <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                    )
            })
        }
    } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('project.label'),
                children: (
                    <Projets t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                )
            })
        }
        tabs.push({
            label: t('internalProjects.label'),
            children: (
                <InternalProjects t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },{
            label: t('itTeam.label'),
            children: (
                <ItTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: t('deliveryTeam.label'),
            children: (
                <DeliveryTeam t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        }, {
            label: t('financeTeam.label'),
            children: (
                <FinanceTeamIvoryCoast t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
        if (checkPmoAdminRoleResult) {
            tabs.push({
                label: t('analytics.label'),
                    children: (
                        <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
                    )
            })
        }
    }
    if (checkPmoAdminRoleResult || role.includes(USER_ROLES.PMO_USER) || role.includes(USER_ROLES.SOLUTION_TEAM)) {
        tabs.push({
            label: t('rejectedProject.label'),
            children: (
                <RejectedProject t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
    }
    tabs.push({
        label: t('equipments.label'),
        children: (
            <Equipments t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
        )
    },{
        label: t('historyView.label'),
        children: (
            <ProjectHistoryView t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
        )
    })
    return (
        <Layout breadcrumbList={breadcrumbList}>
            {COUNTRY_LIST.NIGERIA === countryName &&
                <Button
                    color="secondary"
                    variant={'contained'}
                    onClick={() => {
                        window.open(inqCircuitUrl);
                    }}>{t('nigCircuitIdGenerator.label')}</Button>
            }
            {(tabs.length === 0) &&
                <Typography variant={'subtitle2'}>{t('unathorisedUserPage.label')}</Typography>
            }
            <CustomTabs
                tabs={tabs}
            />
        </Layout>
    );
};
export default withTranslation()(PmoDashboard);
