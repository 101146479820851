/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { get } from "lodash-es";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { Button, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ChartContainer from '../../../../../../../components/Charts/ChartContainer';
import commonStyles from '../../../../../../../theme/commonStyles';
import { dateFormatterLLL,  convertLocalToUTCDate } from '../../../../../../../utils';

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick}
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Export'}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2),
      position: "relative",
      overflow: "hidden"
    },
    dateInput: {
      width: 250,
      minWidth: 200,
      padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    }
    
  }));

const initMapFilter = {time: 'Today', startDate: null, endDate: null, placeId: '', projectId: ''};

const OcrConfigurationDocumentsReport = ({
    t,
    ocrPlaceData,
    ocrProjectData,
    loadingOcrPlace,
    loadingOcrProject,
    ocrDocumentsListing,
    ocrFetchLoadingForDocuments,
    handleFilterdataForOptions,
    handleSubmitOrFilterForDocumentConfigTab
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [ocrDocumentReportState, setOcrDocumentReportState] = useState({...initMapFilter});
    const [documentsDataForEcel, setDocumentsDataForEcel] = useState([]);
    //const [inputValue, setInputValue] = React.useState('');

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let wscols = [];
    const Heading = [
        {
        documentName: "Document Name",
        uploadedOn:"Uploaded On",
        uploadedBy:"Uploaded By"
        }
    ];

    const exportToCSV = (csvData, fileName, wscols) => {
        console.log('wscols', wscols);
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["documentName", "uploadedOn", "uploadedBy"],
            skipHeader: true,
            origin: 0 //ok
        });
        ws["!cols"] = wscols;
        XLSX.utils.sheet_add_json(ws, csvData, {
            header: ["documentName", "uploadedOn", "uploadedBy"],
            skipHeader: true,
            origin: -1 //ok
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
      
    let columns = [
        {
            label: t('sNo.label'),
            key: "sno"
        },
        {
            label: 'Document Name',
            key: "name"
        },
        {
            label: 'Uploaded On',
            key: "on"
        },
        {
            label: 'Uploaded By',
            key: "by"
        }
    ];

    const handleAutoPlaceSearch = (type, value) => null;

    const handleClearFilter = (type) => {
        if(type === "place_map"){
            setOcrDocumentReportState({...initMapFilter});
            //this code will require in future
            // const tryee =  ocrDasboardMapFilter[2].handleChange = (evt, newValue) => {
            //     // debugger;
            //     // console.log('called');
            //     // if(newValue === undefined){
            //     // debugger;
            //     //      setOcrDocumentReportState({...ocrDocumentReportState, placeId: ''});
            //     // }
            // };

            // tryee();
        }
    };

    const ocrDasboardMapFilter =  [
        {
            label: t("startDate.label"),
            handleChange:(newDate) => {
                setOcrDocumentReportState({...ocrDocumentReportState, startDate: newDate});
            },
            val: ocrDocumentReportState.startDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange:(newDate) => {
                setOcrDocumentReportState({...ocrDocumentReportState, endDate: newDate})
            },
            val: ocrDocumentReportState.endDate,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput,
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
                if(!newValue){
                     handleAutoPlaceSearch("eamp", "");
                }
                handleFilterdataForOptions(newValue && newValue.id, 'project');
                setOcrDocumentReportState({...ocrDocumentReportState, placeId: newValue && newValue.id});
            },
            onInputChange: (val) => {
                handleAutoPlaceSearch("eamp", val);
            },
            handleClose: (evt) => {
                handleAutoPlaceSearch("eamp", "");
            },
            val: ocrDocumentReportState.placeId,
            options: (!loadingOcrPlace && ocrPlaceData) ? ocrPlaceData.data : [],
            loading: loadingOcrPlace,
            type: "autocomplete",
            //inputValue: inputValue,
            styleOverrides: { marginRight: 0, display: "inline-block", width: 260, marginTop: 10 },
        },
        {
            label: t('projects.label'),
            handleChange: evt => {
                setOcrDocumentReportState({...ocrDocumentReportState, projectId: evt.target.value});
            },
            val: ocrDocumentReportState.projectId,
            options: loadingOcrProject ? ([<MenuItem value={null}>Loading ...</MenuItem>]) : (!loadingOcrProject && ocrProjectData) ? ocrProjectData.data : [],
            styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
        }
    ];


    useEffect(() => {
        let startTime = "";
        let endTime = "";
        if(ocrDocumentReportState.startDate && ocrDocumentReportState.endDate){
            startTime = new Date(convertLocalToUTCDate(ocrDocumentReportState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(ocrDocumentReportState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }

        handleSubmitOrFilterForDocumentConfigTab({
            startTime,
            endTime,
            place:ocrDocumentReportState.placeId,
            project:ocrDocumentReportState.projectId
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocrDocumentReportState]);

    useEffect(() => {
        const docs = [];
        if(ocrDocumentsListing && ocrDocumentsListing.data.length> 0){
            ocrDocumentsListing.data.map((el,i)=> (
                docs.push({
                documentName: el.folder_name+'/'+el.file_name,
                 uploadedOn: dateFormatterLLL(el.created_at),
                 uploadedBy: el.created_by_name
                })
            ));
        }
        if(docs.length>0){
            wscols = [
               { wch: Math.max(...docs.map(doc => doc.documentName.length)) },
               { wch: Math.max(...docs.map(doc => doc.uploadedOn.length)) },
               { wch: Math.max(...docs.map(doc => doc.uploadedBy? doc.uploadedBy.length: null)) },
           ];
        }

        setDocumentsDataForEcel(docs);
    }, [ocrDocumentsListing]);    
    
  return (
       <>
            <ChartContainer
                title={null}
                filterList={ocrDasboardMapFilter}
                clearFilter={evt => handleClearFilter("place_map")}
            >
               {null}
            </ChartContainer>
            <ButtonComponent 
                t={t} 
                onClick={e => exportToCSV(documentsDataForEcel, `InqDMS_DocumentsReport-${moment(new Date()).format("DD MMM YYYY")}`, wscols)}
                loading={false}
                disabledValue={ocrFetchLoadingForDocuments || (ocrDocumentsListing && ocrDocumentsListing.data.length === 0) || documentsDataForEcel.length === 0}
            />
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!ocrFetchLoadingForDocuments && ocrDocumentsListing?
                        ocrDocumentsListing.data.length ? 
                        ocrDocumentsListing.data.map((rowitem, rowIndex) => (
                            <TableRow key={rowitem.id}>
                                    <TableCell>
                                    <Grid className={commonClasses.column}>
                                        <Grid>
                                            <Typography variant={'subtitle1'}>
                                            {rowIndex +1}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid className={commonClasses.column}>
                                        <Grid>
                                            <Typography variant={'subtitle1'}>
                                            {rowitem.folder_name ||  rowitem.file_name ? rowitem.folder_name+'/'+rowitem.file_name : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid className={commonClasses.column}>
                                        <Grid>
                                            <Typography variant={'subtitle1'}>
                                                {dateFormatterLLL(rowitem.created_at)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid className={commonClasses.column}>
                                        <Grid>
                                            <Typography variant={'subtitle1'}>
                                                {rowitem.created_by_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )) : 
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow> : null}
                        <TableRow>
                            {ocrFetchLoadingForDocuments &&
                                <TableCell colSpan={columns.length}>
                                    {ocrFetchLoadingForDocuments && <LinearProgress color="primary"/>}
                                </TableCell>
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
       </>     
        
  )
}

export default OcrConfigurationDocumentsReport