import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import OutlinedSelect from '../../../../../.././components/OutlinedSelect';
import { paddingGenerator } from '../../../../../../theme/utils';
import Message from '../../../../../../components/Message';
import {
  SET_ALERT_AS_WRONG_ONE,
  APPROVEING_ALERT_OF_ALERTS
} from '../../../../Shayona/mutations';
import { GET_NOTIFICATION_TEMPLATE_LIST } from '../../../../queries';
import { ShayonaCementsContext } from '../../../../../../contexts/ShayonaCementsProvider';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContain: {
    width: '100%'
  }
});

const useStyles = makeStyles(theme => ({
  testCon: { marginRight: '6px', marginLeft: '6px' },
  chartTitle: { marginTop: '10px' },
  paging: {
    float: 'right',
    marginBottom: 30,
    marginTop: 20
  },
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2)
  },
  inputBase: {
    width: '100%',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 15,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading || disabledValue}
      type="submit">
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const AlertRecordComment = ({ t, open, onClose, data }) => {
  const classes = useStyles();
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [filterNotificationList, setFilterNotificationList] = useState([]);
  
  const { organizationId, reloadShayonaApisOrPagination } = useContext(
    ShayonaCementsContext
  );

  const validationSchema = Yup.object({
    comments: Yup.string('Please fill comment').required('Please fill comment')
  });

  const formik = useFormik({
    initialValues: {
      comments: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmtiOfTruckDetails(values);
    }
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
    resetForm
  } = formik;

  const [
    approveAlertOfShayonaAlert,
    { loading: approveAlertOfShayonaAlertLoading }
  ] = useMutation(APPROVEING_ALERT_OF_ALERTS, {
    onError: error => {
      console.log('err', error);
    },
    onCompleted: data => {
      Message.success(data && data.approveAlertOfAlerts.message);
      onClose();
      reloadShayonaApisOrPagination('alert', '');
    }
  });

  const {
    loading: notiTemplateLoading,
    data: notiTemplateList,
    error: notiTemplateError
  } = useQuery(GET_NOTIFICATION_TEMPLATE_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      organization: organizationId,
      limit: 1000,
      search: '',
      page: 1
    }
  });

  const handleSubmtiOfTruckDetails = values => {
    approveAlertOfShayonaAlert({
      variables: {
        organization: organizationId,
        alertId: data && data.alert_id,
        comments: values && values.comments,
        template_id: selectedTemplates,
        url: `${window.location.origin}/shayona/${data && data.alert_id}`,
        alert_info: data
      }
    });
  };

  useEffect(() => {
    if (!open) {
      resetForm();
      setSelectedTemplates([]);
    }
  }, [open]);

  useEffect(() => { 
    setFilterNotificationList(()=>{
    let list = !notiTemplateLoading && !notiTemplateError && notiTemplateList?.getAllNotificationTemplateList?.templates
    list = list.length>0 && list.filter(el=> el.approver_notification === true ? el: null)
    return list;
    })
  },[notiTemplateList, notiTemplateLoading, notiTemplateError])
  
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Record FeedBack comment
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContain}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            style={{ alignItems: 'center', marginBottom: '5px' }}>
            <Grid item xs={5}>
              <OutlinedSelect
                val={selectedTemplates}
                label="Template Name"
                handleChange={evt => setSelectedTemplates(evt.target.value)}
                selectStyle={{ ...paddingGenerator('p-8') }}
                styleOverrides={{ width: '100%', marginRight: 0 }}
                options={
                  !notiTemplateLoading && !notiTemplateError
                    ? filterNotificationList
                    : []
                }
                multiple={true}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="standard-multiline-flexible"
                rows={4}
                variant="standard"
                value={values.comments}
                label="Comments"
                name="comments"
                style={{ width: '100%' }}
                multiline
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.comments && errors.comments}
                error={touched.comments && Boolean(errors.comments)}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Grid container justify={'space-between'}>
              <Button variant={'outlined'} color="primary" onClick={onClose}>
                {t('cancel.label')}
              </Button>
              <ButtonComponent
                t={t}
                loading={approveAlertOfShayonaAlertLoading}
              />
            </Grid>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AlertRecordComment;
