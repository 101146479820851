import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ALL_SERVICE_TICKETS, GET_USER_INFO_BY_ID, CUSTOMER_DATA_WITH_ORGANISATION } from '../queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceManager } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import { TicketingSystem } from '../../../routes/routePaths';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import SitesList from '../components/SitesList';
import { IS_PARENT, STATUS_TYPES } from '../../../utils/constants';
import SearchBox from '../../../components/SearchBox';
import { getParam } from '../../../utils';

const breadcrumbList = [
  { label: 'home.label', link: ServiceManager.Dashboard },
  { label: 'customers.label', link: ServiceManager.Customers }
];

const UsersList = ({ t }) => {
  const [customerName, setCustomerName] = useState('');
  const [siteList, setSiteList] = useState([]);
  const id = getParam();
  const history = useHistory();
  const location = useLocation();
  /*
        List Queries
    */
  const [getCustomerDataList] = useLazyQuery(CUSTOMER_DATA_WITH_ORGANISATION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      setSiteList(res.getAllCustomerWithOrganisation)
    }
  });

  React.useEffect(() => {
    if(id){
      getCustomerDataList({
        variables: {
          id : id
        }
      })
    }
  },[])

  let CustomerListing = siteList && siteList.length && siteList;

  const columns = [
    {
      label: t('customerName.label'),
      key: 'name'
    },
    {
      label: t('circuitId.label'),
      key: 'circuitId'
    },
    {
      label: t('baseStation.label'),
      key: 'baseStation'
    },
    {
      label: t('organisations.label'),
      key: 'organisations'
    },
    {
      label: t('platform.label'),
      key: 'platform'
    },
    {
      label: t('vendors.label'),
      key: 'vendors'
    },
    {
      label: t('bandwidthUp.label'),
      key: 'bandwidthUp'
    },
    {
      label: t('bandwidthDn.label'),
      key: 'bandwidthDn'
    },
    {
      label: t('site.label'),
      key: 'site'
    },
    {
      label: t('action.label'),
      key: 'button'
    }
  ];

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('customersSite.label')}
        subText={`${t('customersSIteSubTxt.label')}`}
      />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                CustomerListing && CustomerListing.length > 0 ? (
                  CustomerListing.map((rowitem, rowIndex) => (
                    <SitesList
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Layout>
  );
};
export default withTranslation()(UsersList);
