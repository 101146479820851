import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateChangeControlTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    CurrentUserName,
    handleUploadFiles,
    handleProjectAttachmentClick,
    handleViewCompleteDetailClick,
    isActionUpdate
}) {
    const classes = useStyles();
    const [changeControlForm, setChangeControlForm] = useState('');
    const [circuitId, setCircuitId] = useState('');
    const [attachMannual, setAttachMannual] = useState('');
    const [generateConfigForm, setGenerateConfigForm] = useState('');
    const [integrationEngineer, setIntegrationEngineer] = useState('');
    const [comment, setComment] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [documentDeleteLoading, setDocumentDeleteLoading] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setChangeControlForm('');
            setCircuitId('');
            setAttachMannual('');
            setGenerateConfigForm('');
            setIntegrationEngineer('');
            setComment('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
            setDocumentDeleteLoading(false);
        }
        if (isActionUpdate === true) {
            setIntegrationEngineer(preValuesOfForm.integration_engineer);
            setComment(preValuesOfForm.comment);
            setNextTeamMemberEmail(preValuesOfForm.next_team_member_email);
            setOnHold(preValuesOfForm.project_info && preValuesOfForm.project_info.status ? preValuesOfForm.project_info.status : '');
            setCategory(preValuesOfForm.project_info && preValuesOfForm.project_info.category ? preValuesOfForm.project_info.category : '');
        }
    }, [modalState, CurrentUserName, isActionUpdate]);

    React.useEffect(() => { }, [integrationEngineer, comment, onHold, category, nextTeamMemberEmail, preValuesOfForm])
    const convertToBase64ChangeControlForm = (img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            let splitPdf = reader.result.split(',');
            setChangeControlForm(splitPdf[1]);
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }

    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{isActionUpdate === true ? t('update.label') : t('create.label')} {t('changeControlTeamSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : (preValuesOfForm.case_number ? preValuesOfForm.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.customer_name} / {preValuesOfForm.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewCompleteDetails.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={handleViewCompleteDetailClick}>{t('clickHereToViewCompleteDetails.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewAttachedDocuments.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={preValuesOfForm.previous_docs && preValuesOfForm.previous_docs.length == 0 ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleProjectAttachmentClick(preValuesOfForm.previous_docs);
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid> */}
                    {/*<Grid item xs={6}>
                        <Typography variant="subtitle2">{t('changeControlForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.change_control_questionaire_form != undefined) {
                                        window.open(preValuesOfForm.change_control_questionaire_form);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>*/}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('installationMannual.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {/* <Input
                                type={'file'}
                                placeholder={t('installationMannual.label')}
                                style={{ width: '60%' }}
                                color={'secondary'}
                                onChange={evt => convertToBase64AttachMannual(evt.target.files[0])}
                            /> */}
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.attach_manual ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.attach_manual != undefined) {
                                        window.open(preValuesOfForm.attach_manual);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('circuitId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.circuit_id}
                                type={'text'} disabled
                                placeholder={t('circuitId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCircuitId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : ''}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label')}
                                style={{ width: '100%' }}
                                disabled
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('generateConfigChangeForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                placeholder={t('generateConfigChangeForm.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={((isActionUpdate === true) && (preValuesOfForm.generate_config_change_form != "")) ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setGenerateConfigForm(file);
                                    } else {
                                        setGenerateConfigForm("");
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.generate_config_change_form) ?
                                <Typography variant={'body2'}> <Button variant={'outlined'}
                                    disabled={!preValuesOfForm.generate_config_change_form ? true : false}
                                    style={{ marginBottom: 5 }}
                                    onClick={() => {
                                        if (preValuesOfForm.generate_config_change_form != undefined) {
                                            window.open(preValuesOfForm.generate_config_change_form);
                                        }
                                    }}><InsertDriveFileIcon></InsertDriveFileIcon> File 1</Button> <IconButton onClick={() => {
                                        setDocumentDeleteLoading(true);
                                        preValuesOfForm.generate_config_change_form = '';
                                        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
                                    }} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                </Typography>
                                : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Typography variant="subtitle2">{t('integrationEngineer.label')}*</Typography> */}
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('integrationEngineer.label')} *`}
                                val={integrationEngineer}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setIntegrationEngineer(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                val={category}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                val={onHold}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('previousComment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {preValuesOfForm.previous_comment && preValuesOfForm.previous_comment.length ?
                                JSON.parse(preValuesOfForm.previous_comment).map((item, index) => (
                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (preValuesOfForm.circuit_id != '') {
                                if (isActionUpdate === true) {
                                    if (!integrationEngineer || (!generateConfigForm && !preValuesOfForm.generate_config_change_form)) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                } else {
                                    if (!generateConfigForm || !integrationEngineer || !nextTeamMemberEmail) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                }
                                let getPreviousComment = preValuesOfForm.previous_comment ? JSON.parse(preValuesOfForm.previous_comment) : [];
                                if (comment != '') {
                                    if (isActionUpdate === true && preValuesOfForm.comment == comment) {
                                    } else {
                                        getPreviousComment.push({ name: CurrentUserName, comment: comment });
                                    }
                                }
                                let previous_comment = Object.entries(getPreviousComment).map(e => e[1]);
                                let getNewConfigForm = "";
                                if (generateConfigForm != "" && generateConfigForm != null) {
                                    let getGeneratemanual = await handleUploadFiles(generateConfigForm, preValuesOfForm.project, 'change_control_team_section');
                                    if (getGeneratemanual.data && getGeneratemanual.data.allUploadFile && getGeneratemanual.data.allUploadFile.url) {
                                        getNewConfigForm = getGeneratemanual.data.allUploadFile.url;
                                    } else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                                if (isActionUpdate === true) {
                                    // merge document
                                    if (getNewConfigForm === "") {
                                        getNewConfigForm = preValuesOfForm.generate_config_change_form;
                                    }
                                }
                                handleSubmitClick({
                                    formUid: isActionUpdate ? preValuesOfForm.uid : '',
                                    changeControlForm: preValuesOfForm.change_control_questionaire_form,
                                    circuitId: preValuesOfForm.circuit_id,
                                    attachMannual: preValuesOfForm.attach_manual,
                                    generateConfigForm: getNewConfigForm,
                                    integrationEngineer: integrationEngineer,
                                    comment: comment,
                                    formName: 'changeControlForm',
                                    project_uid: preValuesOfForm.project,
                                    previous_comment: JSON.stringify(previous_comment),
                                    equipments_to_be_deployed: preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : '',
                                    auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                                    next_team_member_email: nextTeamMemberEmail,
                                    category: category,
                                    on_hold: onHold,
                                });
                            } else {
                                if (!changeControlForm || !circuitId || !attachMannual || !generateConfigForm || !integrationEngineer) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                handleSubmitClick({
                                    changeControlForm: changeControlForm,
                                    circuitId: circuitId,
                                    attachMannual: attachMannual,
                                    generateConfigForm: generateConfigForm,
                                    integrationEngineer: integrationEngineer,
                                    comment: comment,
                                    category: category,
                                    on_hold: onHold,
                                    formName: 'changeControlForm',
                                });
                            }
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateChangeControlTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateChangeControlTeamModal);
