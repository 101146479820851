import { gql } from 'apollo-boost';

/*
  Add Birthday event
*/

export const ADD_BIRTHDAY_EVENT = gql`
    mutation AddBirthdays(
        $employeedetail: String
        $firstName: String
        $lastName: String
        $birthday: String
    ) {
        addBirthdays(
            employeedetail: $employeedetail
            firstName: $firstName
            lastName: $lastName
            birthday: $birthday
        ) {
           id
        }
    }
`;


/*
  Add Birthday event
*/


export const ADD_EVENTS = gql`
    mutation AddEvents(
        $name: String
        $date: String
        $expiryDate: String
    ) {
        addEvents(
            name: $name
            date: $date
            expiryDate: $expiryDate
        ) {
           id
        }
    }
`;

export const UPDATE_EVENTS = gql`
    mutation UpdateEvent(
        $id: String
        $name: String
        $date: String
        $expiryDate: String
    ){
        updateEvent(
            id: $id
            name: $name
            date: $date
            expiryDate: $expiryDate
        ){
            name
        }
    }
`;