
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { renderText } from '../displayComponent';
import Step1Fcr from '../StepsForCreateFcr/Step1Fcr';
import Step2Fcr from '../StepsForCreateFcr/Step2Fcr';
import Step3Fcr from '../StepsForCreateFcr/Step3Fcr';
import { getDatacenters } from '../../../services/dataCenterService';

// const api = axios.create({
//   baseURL: 'http://34.236.252.91:8082/api/getdatacenter'
// });

const useStyles = makeStyles({
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
});

const CreateFcr = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    data: {},
    getItems: [],
    errors: {},
    currentStep: 0
  });


  const [activeStep, setActiveStep] = useState(0);
  const [routerId, setRouterId] = useState('');
  const [nextStepIsValid, setNextStepIsValid] = useState(false);
  const [podId, setPodId] = useState('');
  const [dataCenters, setDataCenters] = useState([]);
  const [selectedDataCenterId, setSelectedDataCenterId] = useState('');

  const StepperStep = [
    { label: 'Set Fcr' },
    { label: 'Config' },
    { label: 'Summary' }
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };

  const getPodId = podId => {
    if (podId) {
      setNextStepIsValid(true);
      setPodId(podId);
    }
  };

  const getRouterId = routerId => {
    setRouterId(routerId);
  };

  const getStepsItem = steps => {
    switch (steps) {
      case 0:
        return (
          <Step1Fcr
            getPodId={getPodId}
            selectedDataCenterId={selectedDataCenterId}
            setSelectedDataCenterId={setSelectedDataCenterId}
            dataCenters={dataCenters}
            handleNext={handleNext}
            nextStepIsValid={nextStepIsValid}
          />
        );
      case 1:
        return (
          <Step2Fcr
            getRouterId={getRouterId}
            handlePrev={handlePrev}
            setState={setState}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <Step3Fcr
            formData={state.data}
            routerId={routerId}
            handlePrev={handlePrev}
            classes={classes}
            podId={podId}
          />
        );
      default:
        return <Step1Fcr />;
    }
  };

  const getDataCenters = async () => {
    const res = await getDatacenters();
    if (res.status === 200) {
      setDataCenters(res?.data?.result?.data);
    }
  };

  const handleDataCenter = e => {
    const selectedDataCenterId = e.target.value;
    setSelectedDataCenterId(selectedDataCenterId);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await api.get('/');
  //       const getData = response.data.message;
  //       setState({ getData });
  //     } catch (err) {}
  //   };
  //   fetchData();
  //   const fetchlocalstorage = async () => {
  //     try {
  //       const get = localStorage.getItem('datacenter_by_id');
  //       setState({ get });
  //     } catch (err) {}
  //   };
  //   fetchlocalstorage();
  // }, []);

  useEffect(() => {
    getDataCenters();
  }, []);

  return (
    <>
      <Grid container direction="column">
        {activeStep === 0 && (
          <Grid item container justify="flex-end">
            <Box display="flex" flexDirection="column" mr={2} mt={1}>
              <Typography variant="subtitle2">Select country</Typography>
              <TextField
                select
                value={selectedDataCenterId}
                onChange={handleDataCenter}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                size="small">
                <option value="">Global</option>
                {dataCenters?.map(dataCenter => {
                  return (
                    <option key={dataCenter._id} value={dataCenter._id}>
                      {dataCenter.country}
                    </option>
                  );
                })}
              </TextField>
            </Box>
          </Grid>
        )}
        <hr />
        <Grid item>
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>{renderText({ label: 'New FCR' })}</Box>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{ padding: '10px', backgroundColor: '#f8f8f8' }}>
                {StepperStep?.map((item, i) => (
                  <Step key={i}>
                    <StepLabel>{item.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Box
            p={2}
            mb={2}
            mt={2}
            style={{ display: 'flex', justifyContent: 'center' }}>
            {getStepsItem(activeStep)}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};;

export default CreateFcr;
