import {gql} from 'apollo-boost';

export const CREATE_OCR_PARSE_DATA = gql`
mutation AddOcr($organization: String, $place: String, $project: String, $folder: String, $heading: String, $ocr_text: String, $doc: Upload, $rule: JSON, $geo_location: JSON) {
    addOcrData(organization: $organization, place: $place, project: $project, folder: $folder, heading: $heading, ocr_text: $ocr_text, doc:$doc, rule: $rule, geo_location: $geo_location) {
        success
        message
        #data
    }
}
`;

export const CREATE_MULTIPLE_OCR_PARSE_DATA = gql`
mutation AddMultipleOcr($organization: String, $place: String, $project: String, $folder: String, $image_list: [Upload], $rule: String, $geo_location: JSON) {
    addMultipleOcrData(organization: $organization, place: $place, project: $project, folder: $folder, image_list:$image_list, rule:$rule, geo_location: $geo_location) {
        success
        message
        data
    }
}
`;

export const CREATE_OCR_RULE_DATA = gql`
mutation AddOcrRule($organization: String, $doc_uuid: String, $rule_name: String, $description: String, $selected_sections:[CropDataInput] ) {
    addOcrRuleData(organization: $organization, doc_uuid: $doc_uuid, rule_name: $rule_name, description: $description, selected_sections:$selected_sections) {
        success
        message
        data
    }
}
`;


export const DELETE_OCR_RULE_DATA = gql`
mutation DeleteOcrRule($id: String, $organization: String) {
    deleteOcrRule(id:$id, organization: $organization) {
        success
        message
    }
}
`;

export const DELETE_OCR_RULE_CSV = gql`
mutation DeleteOcrRuleCsv($ruleId: String) {
    deleteOcrRuleCsv(ruleId:$ruleId) {
        success
        message
    }
}
`;


export const EDIT_OCR_RULE_DATA = gql`
mutation EditOcrRule($id: String, $rule_name: String, $description: String, $selected_sections:[CropDataInput]  ) {
    editOcrRuleData(id: $id,rule_name: $rule_name, description: $description, selected_sections:$selected_sections) {
        success
        message
        data
    }
}
`;

export const CREATE_OCR_PLACE = gql`
mutation AddOcrPalce($organization: String, $name: String, $place_description: String, $lat: Float, $lng: Float) {
    createOcrPlace(organization: $organization, name: $name, place_description: $place_description, lat:$lat, lng:$lng) {
        success
        message
        data
    }
}
`;

export const CREATE_OCR_PROJECT = gql`
mutation AddOcrProject($organization: String, $name: String, $description: String, $place: String) {
    createOcrProject(organization: $organization, name: $name, description: $description, place:$place) {
        success
        message
        data
    }
}
`;

export const CREATE_OCR_FOLDER = gql`
mutation AddOcrFolder($organization: String, $name: String, $description: String, $place: String, $project: String,  $isSubfolder: Boolean, $folder: String) {
    createOcrFolder(organization: $organization, name: $name, description: $description, place:$place, project: $project, isSubfolder: $isSubfolder, folder: $folder) {
        success
        message
        data
    }
}
`;

export const DELETE_OCR_PLACE = gql`
mutation DeleteOcrPlace($organization: String, $placeId: String) {
    deleteOcrPlace(organization: $organization, placeId:$placeId) {
        success
        message
    }
}
`;

export const DELETE_OCR_PROJECT = gql`
mutation DeleteOcrProject($organization: String, $projectId: String) {
    deleteOcrProject(organization: $organization, projectId:$projectId) {
        success
        message
    }
}
`;

export const DELETE_OCR_FOLDER = gql`
mutation DeleteOcrFolder($organization: String, $folderId: String) {
    deleteOcrFolder(organization: $organization, folderId:$folderId) {
        success
        message
    }
}
`;

export const DELETE_OCR_FILE = gql`
mutation DeleteOcrFile($organization: String, $fileId: String) {
    deleteOcrFile(organization: $organization, fileId:$fileId) {
        success
        message
    }
}
`;

export const CREATE_OCR_GROUP = gql`
mutation AddOcrGroup($organization: String, $name: String, $permission: String) {
    addOcrGroup(organization: $organization, name: $name, permission: $permission) {
        success
        message
        data
    }
}
`;

export const EDIT_OCR_GROUP = gql`
mutation EditOcrGroup($organization: String, $name: String, $users: [JSON], $permission: String, $id: String) {
    editOcrGroup(organization: $organization, name: $name, users: $users, permission: $permission,id: $id) {
        success
        message
        data
    }
}
`;

export const DELETE_OCR_GROUP = gql`
mutation DeleteOcrGroup($organization: String, $id: String) {
    deleteOcrGroup(organization: $organization, id: $id) {
        success
        message
    }
}
`;

export const ASSIGN_FOLDER_TO_USER = gql`
mutation AssignFolderToOcrUser($organization: String, $permission: String, $user_detail: JSON, $folder_id: String) {
    assignFolderToOcrUser(organization: $organization, permission: $permission user_detail: $user_detail, folder_id: $folder_id) {
        success
        message
        data
    }
}
`;

export const ASSIGN_FOLDER_TO_GROUP = gql`
mutation AssignFolderToOcrGroup($organization: String, $folder_id: String, $group_id: String) {
    assignFolderToOcrGroup(organization: $organization, folder_id: $folder_id, group_id: $group_id) {
        success
        message
        data
    }
}
`;

export const ASSIGN_PROJECT_TO_USER = gql`
mutation AssignProjectToUserOcr($organization: String, $permission: String, $user_detail: JSON, $project_id: String) {
    assignProjectToUserOcr(organization: $organization, permission: $permission user_detail: $user_detail, project_id: $project_id) {
        success
        message
        data
    }
}
`;

export const SEND_OCR_ATTACHMENTS_EMAIL = gql`
mutation SendOcrEmail($id: String, $emailId: String, $message: String, $organization: String) {
    sendOcrAttachmentEmail(id: $id, emailId: $emailId, message: $message, organization: $organization) {
        success
        message
    }
}
`;
export const CREATE_OCR_ACTIVITY_LOG = gql`
mutation SendOcrEmail(
    $organization: String, 
    $actionId: String, 
    $actionType: String, 
    $description: String, 
    $place: String, 
    $folder: String, 
    $project: String, 
    $type: String
) {
    createOcrActivity(
        organization: $organization, 
        actionId: $actionId, 
        actionType: $actionType, 
        description: $description, 
        place: $place, 
        folder: $folder, 
        project: $project, 
        type: $type
    ) {
        success
        message
    }
}
`;

export const CREATE_OCR_PROCESS = gql`
mutation AddOcrProcess($organization: String, $processSteps: [JSON]) {
    addOcrProcess(organization: $organization, processSteps:$processSteps) {
        success
        message
        data
    }
}
`;

export const DELETE_OCR_PROCESS = gql`
mutation DeleteOcrProcess($id: String) {
    deleteOcrProcess(id:$id) {
        success
        message
    }
}
`;

export const ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS = gql`
mutation AddProjectOrFolderToOcrProcess($id: String, $assignedProjectId: String, $assignedFolderId: String) {
    addProjectOrFolderToOcrProcess(id: $id assignedProjectId: $assignedProjectId, assignedFolderId: $assignedFolderId) {
        success
        message
        data
    }
}
`;

export const ADD_STEPS_TO_PROCESS = gql`
mutation AddOcrProcessFlowSteps($organization: String, $processFlowSteps: [JSON]) {
    addOcrProcessFlowSteps(organization: $organization, processFlowSteps:$processFlowSteps) {
        success
        message
        data
    }
}
`;

export const UPDATE_STATUS_OF_PROCESS = gql`
mutation UpdateStatusProcessSteps($organization: String, $processId: String, $status:Boolean, $approvalOrRejectComments: String, $fileId: String, $approved_user_id: String) {
    updateStatusProcessSteps(organization: $organization, processId: $processId,status:$status,approvalOrRejectComments: $approvalOrRejectComments, fileId: $fileId, approved_user_id: $approved_user_id) {
        success
        message
        data
    }
}
`;

export const EDIT_FOLDER = gql`
mutation EditFolder($organization: String, $id: String, $name:String) {
    editFolder(organization: $organization, id:$id, name: $name) {
        success
        message
        data
    }
}
`;

export const EDIT_PROJECT = gql`
mutation EditProject($organization: String, $id: String, $name:String) {
    editProject(organization: $organization, id:$id, name: $name) {
        success
        message
        data
    }
}
`;

export const REMOVE_USER_FROM_GROUP = gql`
mutation RemoveUserFromGroup($group_id: String, $user_id: String) {
    removeUserFromGroup(group_id: $group_id, user_id:$user_id) {
        success
        message
        data
    }
}
`;

export const REMOVE_USER_FROM_FOLDER = gql`
mutation RemoveUserFromFolder($folder_id: String, $user_id: String) {
    removeUserFromFolder(folder_id: $folder_id, user_id:$user_id) {
        success
        message
        data
    }
}
`;

export const REMOVE_GROUP_FROM_FOLDER = gql`
mutation RemoveGroupFromFolder($group_id: String, $folder_id: String) {
    removeGroupFromFolder(group_id: $group_id, folder_id:$folder_id) {
        success
        message
        data
    }
}
`;

export const UPDATE_OCR_KEY_VALUE = gql`
mutation UpdateOcrKeyValue($id: String,$formJson: JSON) {
    updateOcrKeyValue(id: $id,formJson: $formJson) {
        success
        message
        data
    }
}
`;