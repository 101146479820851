import React, {useState, useEffect} from 'react';
import { get } from "lodash-es";
import {useMutation} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

import OutlinedSelect from '../../../../../.././../components/OutlinedSelect';
import commonStyles from '../../../../../../../theme/commonStyles';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import {
    REMOVE_USER_FROM_GROUP,
    REMOVE_USER_FROM_FOLDER
} from '../../../../../Ocr/mutations';
import Message from '../../../../../../../components/Message';
import OcrConfigrationGroupItem from './OcrConfigrationGroupItem';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Add Group'}
        </Button>
    );
  }

  function ButtonComponentOfAssignToUser(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  };

  function ButtonComponentForDeleteUserFromGroup(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="primary"
            onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <DeleteIcon color="secondary" />}
        </Button>
    );
}

const OcrConfigurationGAndUCreateGroupOrUser = ({
    t,
    open,
    onClose,
    subscriptionUserListLoading,
    subscriptionUserData,
    handleSubmitOfAllOcrGroupAction,
    createOcrGroupLoading,
    editOcrGroupLoading,
    deleteOcrGroupLoading,
    groupListLoading,
    ocrGroupData,
    selecteIdForDelete,
    assignType,
    isaddUserToGroupElementEnable,
    groupSingleDataLoading,
    singleGroupData,
    assignFolderToUserLoading,
    ocrAssignAndAddActions,
    usersDataByFolderId,
    getUsesrsHaveFolderAccessLoading
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [groupOrUserName, setGroupOrUserName] = useState('');
    const [accsesvalue, setAccsesvalue] = useState('');
    const [selectedUser, setSelectedUser] = useState('')
    const [existingUserAtGroup, setExistingUserAtGroup] = useState([]);
    const [selecteUserIdForDelete, setSelecteUserIdForDelete] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');

    const accessOptions = [
        {id: 'view', name: 'Read Only'},
        {id: 'edit', name: 'Edit'},
    ];

    let columns = [
        {
            label:t('name.label'),
            key: "name"
        },
        {
            label: t('action.label'),
            key: "action"
        }
    ];

    const [removeUserFromGroup, { loading: removeUserFromGroupLoading }] = useMutation(REMOVE_USER_FROM_GROUP, {
        onError: error => {
            console.log('err', error);
            setSelecteUserIdForDelete('');
        },
        onCompleted: data => {
            setSelecteUserIdForDelete('');
            Message.success(data && data.removeUserFromGroup.message);
            handleSubmitOfAllOcrGroupAction('getById',{id:selectedGroupId})
        }
    });

    const [removeUserFromFolder, { loading: removeUserFromFolderLoading }] = useMutation(REMOVE_USER_FROM_FOLDER, {
        onError: error => {
            console.log('err', error);
            setSelecteUserIdForDelete('');
        },
        onCompleted: data => {
            setSelecteUserIdForDelete('');
            Message.success(data && data.removeUserFromFolder.message);
            handleSubmitOfAllOcrGroupAction('usersFolderData',{id:selectedGroupId})
        }
    });

    const handleRemoveUserFromDiffrentElement = (type, data) =>{
        setSelecteUserIdForDelete(data && data.id);
        if(type === 'fromGroup'){
            removeUserFromGroup({
                variables:{
                    group_id: selectedGroupId,
                    user_id: data && data.user_id
                }
            });
        } else if(type === 'fromFolder'){
            removeUserFromFolder({
                variables:{
                    folder_id: data && data.folder_id,
                    user_id: data && data.user_id
                }  
            })
        }
    };

    useEffect(() => {
      if(singleGroupData){
        setExistingUserAtGroup(get(singleGroupData, 'users', []));
      }
    }, [singleGroupData]);

    useEffect(() => {
        if(!open){
            setExistingUserAtGroup([]);
            setSelectedUser('');
            setAccsesvalue('');
            setSelectedGroupId('');
        }
    }, [open]);

  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {assignType}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            {
                assignType === t('createGroup.label') && (
                    <>
                            <Grid container style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                <Grid item xs={3}>
                                    <TextField
                                    value={groupOrUserName}
                                    id="outlined-search" 
                                    label="Group Name" 
                                    type="search"
                                    style={{ width: '80%'}}
                                    onChange={(e) => setGroupOrUserName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        val={accsesvalue}
                                        label="Select Access"
                                        handleChange={evt => setAccsesvalue(evt.target.value)}
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{ width: '100%', marginRight: 0 }}
                                        options={accessOptions}
                                    />
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={2}>
                                  <ButtonComponent 
                                    t={t}
                                    loading={createOcrGroupLoading}
                                    onClick={()=> handleSubmitOfAllOcrGroupAction('add', {groupOrUserName, accsesvalue})}
                                   />
                                </Grid>
                                <Grid item xs={3} />
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 35 , marginBottom: '5px'  }}>
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((item, index) => (
                                                        <TableCell key={index}>
                                                            <Typography variant={'h4'}>{item.label}</Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!groupListLoading ?
                                                ocrGroupData.length > 0 ?
                                                ocrGroupData.map((rowitem, rowIndex) => (
                                                            <OcrConfigrationGroupItem
                                                                key={rowIndex}
                                                                t={t}
                                                                data={rowitem}
                                                                handleSubmitOfAllOcrGroupAction={handleSubmitOfAllOcrGroupAction}
                                                                deleteOcrGroupLoading={deleteOcrGroupLoading}
                                                                selecteIdForDelete={selecteIdForDelete}
                                                                setSelectedGroupId={setSelectedGroupId}
                                                            />
                                                        )) :
                                                        <TableRow>
                                                            <TableCell colSpan={columns.length}>
                                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={columns.length}>
                                                            {groupListLoading && <LinearProgress color="primary"/>}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    {
                                        isaddUserToGroupElementEnable && (
                                            <>
                                            <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                                <Typography variant={'h4'}>Existing User Name of {singleGroupData && singleGroupData.name}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {!groupSingleDataLoading ?
                                                     singleGroupData && get(singleGroupData, 'users', []).length > 0 ?
                                                     get(singleGroupData, 'users', []).map((rowitem, rowIndex) => (
                                                        <TableRow key={rowitem.id}>
                                                            <TableCell>
                                                                <Grid className={commonClasses.column}>
                                                                    <Grid>
                                                                        <Typography variant={'subtitle1'}>
                                                                         {rowitem.first_name} {rowitem.last_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid className={commonClasses.column}>
                                                                    <Grid>
                                                                        <ButtonComponentForDeleteUserFromGroup
                                                                            t={t}
                                                                            loading={selecteUserIdForDelete === rowitem.id ? removeUserFromGroupLoading : false}
                                                                            onClick={() => handleRemoveUserFromDiffrentElement('fromGroup', rowitem)}
                                                                        /> 
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                            )) :
                                                            <TableRow>
                                                                <TableCell colSpan={columns.length}>
                                                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        :
                                                        <TableRow>
                                                            <TableCell colSpan={columns.length}>
                                                                {groupSingleDataLoading && <LinearProgress color="primary"/>}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                            <div style={{ marginTop: 8 }}>
                                                <Autocomplete
                                                    id={`user-select`}
                                                    options={!subscriptionUserListLoading && subscriptionUserData}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(event, newValue) => {
                                                        if(newValue){
                                                        setSelectedUser(newValue);
                                                        handleSubmitOfAllOcrGroupAction('edit', {user:  
                                                            [{
                                                                first_name: newValue.firstName,
                                                                last_name: newValue.lastName,
                                                                email: newValue.email,
                                                                user_id: newValue.id,
                                                                phone_no:null
                                                            }]});
                                                        }
                                                    }}
                                                    style={{ border: '1px solid #ebebeb', width: '80%' }}
                                                    renderInput={(params) => <TextField {...params} label={`${t('users.label')} *`} variant="outlined" />}
                                                />
                                            </div>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Grid>
                    </>
                )
            }
          { assignType === t('assignFolderToUserOcr.label') && (
              <>
                                    <TableContainer style={{ border: '1px solid blue', padding: 4 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                            <Typography variant={'h4'}>Existing User Name</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                            <Typography variant={'h4'}>Acess Types</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!getUsesrsHaveFolderAccessLoading ?
                                                usersDataByFolderId.length > 0 ?
                                                usersDataByFolderId.map((rowitem, rowIndex) => (
                                                    <TableRow key={rowitem.id}>
                                                        <TableCell>
                                                            <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                    {rowitem.first_name} {rowitem.last_name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <Typography variant={'subtitle1'}>
                                                                    { rowitem.permission === 'view'? 'Read Only' : 'Edit'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                        <Grid className={commonClasses.column}>
                                                                <Grid>
                                                                    <ButtonComponentForDeleteUserFromGroup
                                                                            t={t}
                                                                            loading={selecteUserIdForDelete === rowitem.id ? removeUserFromFolderLoading : false}
                                                                            onClick={() =>handleRemoveUserFromDiffrentElement('fromFolder', rowitem)}
                                                                    /> 
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                        )) :
                                                        <TableRow>
                                                            <TableCell colSpan={columns.length}>
                                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={columns.length}>
                                                            {getUsesrsHaveFolderAccessLoading && <LinearProgress color="primary"/>}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer> 
                       <div style={{ display: 'flex' ,marginTop: 8, alignItems: 'baseline' }}>
                           <Autocomplete
                               id={`user-select`}
                               options={!subscriptionUserListLoading && subscriptionUserData}
                               getOptionLabel={(option) => option.name}
                               onChange={(event, newValue) => {
                                   if(newValue){
                                   setSelectedUser(newValue);
                                   }
                               }}
                               style={{ border: '1px solid #ebebeb', width: '50%' }}
                               renderInput={(params) => <TextField {...params} label={`${t('users.label')} *`} variant="outlined" />}
                           />
                           <div style={{ marginLeft: 8, width: '50%' }}>
                            <OutlinedSelect
                                val={accsesvalue}
                                label="Select Access"
                                handleChange={evt => setAccsesvalue(evt.target.value)}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0 }}
                                options={accessOptions}
                            />
                           </div>
                       </div>
             </>
                   
          )}
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={onClose}
                >
                    {t('cancel.label')}
                </Button>
                { assignType === t('assignFolderToUserOcr.label') && (
                  <ButtonComponentOfAssignToUser 
                    t={t}
                    loading={assignFolderToUserLoading}
                    onClick={()=> ocrAssignAndAddActions(t('assignFolderToUserOcr.label'), {
                        selectedUserData:{
                        first_name:selectedUser.firstName,
                        last_name:selectedUser.lastName,
                        email:selectedUser.email,
                        user_id:selectedUser.id,
                        phone_no:''
                        }, accsesvalue})}
                  />
                )}
            </Grid>          
        </DialogActions>
    </Dialog>
  )
}

export default OcrConfigurationGAndUCreateGroupOrUser