export const Styles = {
  formContainer: {
    width: '100%',
    // height:"98vh",
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  form: {
    minHeight: '200px',
    padding: '16px'
  },
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  },
  nextButton: {
    backgroundColor: '#5a1f2a',
    color: 'white',
    borderRadius: '10px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#7b4c55'
    }
  },
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
};
