import React, { useState, useEffect } from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Message from '../../../../../components/Message';
import GsmSwitchTypes from '../GsmSwitchTypes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Grid from '@material-ui/core/Grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: { marginRight: '6px', marginLeft: '6px' },
    chartTitle: { marginTop: '10px' },
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 15,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const gsmTypes = {
    GSMSWITCH: 'gsm_switch'
};

const IotForm = ({
    t,
    open,
    onClose,
    placeList,
    notificationTemplates,
    countryCodes,
    iotloading,
    updateIotLoading,
    iotMutaion,
    selectedItem,
    editIotDevice,
    organizationId,
    allPalceList
}) => {
    const classes = useStyles();
    const initGsmSwitchFields = { id: '', cc: '', renderOptionForcc: '', phone: '', place: '', notifictionTemp: '' };

    const [gsmSwitchs, setGsmSwitchs] = useState([]);
    const [editGsmSwitchs, setEditGsmSwitchs] = useState([initGsmSwitchFields])
    const [isGsmTypesenable, setIsGsmTypesenable] = useState(false);
    const [gsmTypeName, setGsmTypeName] = useState('');
    const [selectedGsmType, setSelectedGsmType] = useState('');
    const [notifictionMaster, setNotifictionMaster] = useState(null);
    const [countryCode, setCountryCode] = useState('');

    const handleCloseOfGsmTypes = () => {
        setIsGsmTypesenable(false);
    };

    const handleAddObjects = (type) => {
        if (type === gsmTypes.GSMSWITCH) {
            setGsmSwitchs(oldArray => [...oldArray, { ...initGsmSwitchFields }]);
        }
    };

    const handleRemoveMultiData = (idx, type) => {
        if (type === "multi_object") {
            let updatedState = [...gsmSwitchs];
            updatedState.splice(idx, 1);
            setGsmSwitchs(updatedState);
        }
    };

    const handleObjectInputChange = (value, index, field) => {
        console.log("value: ", value, field, selectedItem);
        if (selectedItem) {
            const updatedState = [...editGsmSwitchs];
            if (field === 'cc' && value) {
                updatedState[index]['renderOptionForcc'] = value;
                updatedState[index][field] = value.dial_code;
            }
            updatedState[index][field] = value;
            setEditGsmSwitchs(updatedState);
        } else {
            const updatedState = [...gsmSwitchs];
            if (field === 'cc') {
                updatedState[index]['renderOptionForcc'] = value;
                updatedState[index][field] = value.dial_code;
            }
            updatedState[index][field] = value;
            setGsmSwitchs(updatedState);
        }

    }

    const handleSubmitGSMType = value => {
        setGsmTypeName(value && value.name);
        setSelectedGsmType(value && value.id);
        handleAddObjects(value && value.id);
    };

    const handleChangeOfMasterNotifiction = (evt, field) => {
        setNotifictionMaster(evt);
        const updatedState = [...gsmSwitchs];
        updatedState.map(el => {
            el.notifictionTemp = evt;
            return el;
        })
        setGsmSwitchs(updatedState);
    };

    // const showingCounttryCode = () => {
    //     let renderOption = '';
    //     if(countryCodes.length>0){
    //         countryCodes.map(el => {
    //             if(el.dial_code === countryCode){
    //                return renderOption = el;
    //             }})
    //     }
    //     console.log('renderOption', renderOption);
    //     console.log('countryCode', countryCode);
    //     return renderOption;
    // };


    const handlSubmitGsm = () => {
        if (selectedItem) {
            const newData = {};
            newData['id'] = editGsmSwitchs[0].id;
            newData['notification'] = editGsmSwitchs[0].notifictionTemp;
            newData['place'] = editGsmSwitchs[0].place.place_id;
            newData['phone_number'] = `${editGsmSwitchs[0].cc.dial_code.slice(1)}${editGsmSwitchs[0].phone}`;
            newData['phone_type'] = "gsm_switch"; //gsm is static now  
            editIotDevice({
                variables: {
                    organization: organizationId,
                    phoneDetails: newData
                }
            });
            updateIotLoading(true);
        } else {
            const gsmSwitchsWithPlacesMapFunc = gsmSwitchs.map(el => el.place.name);
            const toFindDuplicates = gsmSwitchsWithPlacesMapFunc => gsmSwitchsWithPlacesMapFunc.filter((item, index) => gsmSwitchsWithPlacesMapFunc.indexOf(item) !== index);
            const duplicateElement = toFindDuplicates(gsmSwitchsWithPlacesMapFunc);

            if (duplicateElement.length > 0) {
                Message.error("Place Should Be unique");
            } else {
                const gsmDataForApi = gsmSwitchs.length > 0 && gsmSwitchs.map(el => {
                    const newData = {};
                    newData['notification'] = el.notifictionTemp;
                    newData['place'] = el.place.place_id;
                    newData['phone_number'] = `${el.cc.dial_code.slice(1)}${el.phone}`;
                    newData['phone_type'] = "gsm_switch"; //gsm is static now  
                    return newData;
                });
                iotMutaion({
                    variables: {
                        phoneDetails: gsmDataForApi
                    }
                });
                updateIotLoading(true);
            }
        }

    };

    useEffect(() => {
        if (selectedItem) {
            handleSubmitGSMType({
                id: selectedItem.phone_type,
                name: "Gsm Switch"
            });
            let addPlus = '';
            const updatedState = editGsmSwitchs;
            if (selectedItem.id) {
                updatedState[0]['id'] = selectedItem.id;
            }
            if (selectedItem.phone_number.slice(0, 1) !== '+') {
                addPlus = `+${selectedItem.phone_number.slice(0, -10)}`;
                updatedState[0]['phone'] = selectedItem.phone_number.slice(2, 12);
            } else {
                addPlus = selectedItem.phone_number.slice(0, -10);
                updatedState[0]['phone'] = selectedItem.phone_number.slice(3, 13);
            }
            if (countryCodes.length > 0) {
                updatedState[0]['cc'] = countryCodes.find(el => el.id === addPlus);
                updatedState[0]['renderOptionForcc'] = countryCodes.find(el => el.id === addPlus);
            }
            updatedState[0]['place'] = allPalceList.find(place => place.place_id === selectedItem.place_id);
            updatedState[0]['notifictionTemp'] = notificationTemplates.find(notiTemp => notiTemp.id === selectedItem.notification_id).id;
            console.log('updatedState', updatedState);
            setEditGsmSwitchs(updatedState);
        }
    }, [selectedItem]);


    useEffect(() => {
        setGsmSwitchs([]);
        setGsmTypeName('');
        setSelectedGsmType('');
    }, [!open])

    //console.log('editGsmSwitchs', editGsmSwitchs);

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={onClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    Device Form
                </DialogTitle>
                <DialogTitle id="customized-dialog-title" onClose={onClose} />
                <DialogContent dividers className={classes.dialogContain}>
                    <Grid
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-10')
                            }}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-8')
                                }}>
                                <Grid style={marginGenerator('mt-24')}>
                                    <Button onClick={() => setIsGsmTypesenable(true)} variant="contained" color="primary">
                                        {gsmTypeName ? 'Change Gsm' : 'Select Gsm'}
                                    </Button>
                                </Grid>
                                {
                                    selectedItem ? (<>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{gsmTypeName}</ListSubheader>
                                        </List>
                                        <Grid container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                            <Grid item xs={2}>
                                                <Autocomplete
                                                    style={{ width: '100%', marginRight: 0 }}
                                                    options={countryCodes}
                                                    getOptionLabel={(option) => option.name}
                                                    value={editGsmSwitchs[0].renderOptionForcc}
                                                    onChange={(evt, newValue) => {
                                                        handleObjectInputChange(newValue, 0, 'cc');
                                                    }}
                                                    renderInput={(params) => (
                                                        <InputBase
                                                            ref={params.InputProps.ref}
                                                            inputProps={params.inputProps}
                                                            autoFocus
                                                            className={classes.inputBase}
                                                            value={editGsmSwitchs[0].cc}
                                                            placeholder={t('code.label')}
                                                            variant={'outlined'}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Input
                                                    value={editGsmSwitchs[0].phone}
                                                    type={'text'}
                                                    placeholder={`${t('phone.label')} *`}
                                                    color={'secondary'}
                                                    autoComplete={'off'}
                                                    onChange={evt => {
                                                        handleObjectInputChange(evt.target.value, 0, 'phone');
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1} />
                                            <Grid item xs={2}>
                                                <OutlinedSelect
                                                    val={editGsmSwitchs[0].place}
                                                    label={t('places.label')}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                    handleChange={evt => {

                                                        handleObjectInputChange(evt.target.value, 0, 'place');
                                                    }}
                                                    options={allPalceList}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <OutlinedSelect
                                                    val={editGsmSwitchs[0].notifictionTemp}
                                                    label={t('notifications.label')}
                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                    styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                    handleChange={evt => {
                                                        handleObjectInputChange(evt.target.value, 0, 'notifictionTemp');
                                                    }}
                                                    options={notificationTemplates}
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ margin: 'auto' }} />
                                            <div style={{ width: "100%", borderBottom: "2px solid #ebebeb", paddingTop: 18, marginLeft: 12 }}></div>
                                        </Grid>
                                    </>
                                    ) : (
                                        selectedGsmType && selectedGsmType === gsmTypes.GSMSWITCH ?
                                            <>
                                                <List className={classes.aiParamList}>
                                                    <ListSubheader style={{ padding: '0px' }}>{gsmTypeName}</ListSubheader>
                                                </List>
                                                <Grid container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            onClick={() => handleAddObjects("gsm_switch")}
                                                            variant="outlined"
                                                            color="primary"
                                                        >
                                                            {t('provisionAddNumber.label')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {gsmSwitchs && gsmSwitchs.length > 1 && (
                                                            <OutlinedSelect
                                                                val={notifictionMaster}
                                                                label={t('notifications.label')}
                                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                                styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                                handleChange={(evt) => handleChangeOfMasterNotifiction(evt.target.value, 'notifictionTemp')}
                                                                options={notificationTemplates}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} />
                                                </Grid>
                                                {gsmSwitchs && gsmSwitchs.length > 0 && gsmSwitchs.map((gsmSwitch, index) => {
                                                    return (
                                                        <Grid key={index} container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                            <Grid item xs={2}>
                                                                <Autocomplete
                                                                    style={{ width: '100%', marginRight: 0 }}
                                                                    options={countryCodes}
                                                                    getOptionLabel={(option) => option.name}
                                                                    value={gsmSwitch.renderOptionForcc}
                                                                    // onChange={(evt, newValue) => {
                                                                    //     setCountryCode(newValue ? newValue.dial_code : '');
                                                                    // }}
                                                                    onChange={(evt, newValue) => {
                                                                        handleObjectInputChange(newValue, index, 'cc');
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <InputBase
                                                                            ref={params.InputProps.ref}
                                                                            inputProps={params.inputProps}
                                                                            autoFocus
                                                                            className={classes.inputBase}
                                                                            value={gsmSwitch.cc}
                                                                            placeholder={t('code.label')}
                                                                            variant={'outlined'}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Input
                                                                    value={gsmSwitch.phone}
                                                                    type={'text'}
                                                                    placeholder={`${t('phone.label')} *`}
                                                                    color={'secondary'}
                                                                    autoComplete={'off'}
                                                                    onChange={evt => {
                                                                        handleObjectInputChange(evt.target.value, index, 'phone');
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} />
                                                            <Grid item xs={2}>
                                                                {/* <OutlinedSelect
                                                                val={gsmSwitch.place}
                                                                label={t('places.label')}
                                                                selectStyle={{...paddingGenerator('p-8')}}
                                                                styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                                handleChange={ evt => {
                                                                    handleObjectInputChange(evt.target.value, index, 'place');    
                                                                }}
                                                                options={placeList}
                                                            /> */}
                                                                <Autocomplete
                                                                    style={{ width: '100%', marginRight: 0 }}
                                                                    options={allPalceList}
                                                                    getOptionLabel={(option) => option.name}
                                                                    value={gsmSwitch.place}
                                                                    onChange={(evt, newValue) => {
                                                                        handleObjectInputChange(newValue, 0, 'place');
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <InputBase
                                                                            ref={params.InputProps.ref}
                                                                            inputProps={params.inputProps}
                                                                            autoFocus
                                                                            className={classes.inputBase}
                                                                            value={gsmSwitch.place}
                                                                            placeholder={t('places.label')}
                                                                            variant={'outlined'}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <OutlinedSelect
                                                                    val={gsmSwitch.notifictionTemp}
                                                                    label={t('notifications.label')}
                                                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                                                    styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                                    handleChange={evt => {
                                                                        handleObjectInputChange(evt.target.value, index, 'notifictionTemp');
                                                                    }}
                                                                    options={notificationTemplates}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ margin: 'auto' }}>
                                                                <Fab onClick={evt => { handleRemoveMultiData(index, 'multi_object') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                                    <DeleteForeverIcon />
                                                                </Fab>
                                                            </Grid>
                                                            <div style={{ width: "100%", borderBottom: "2px solid #ebebeb", paddingTop: 18, marginLeft: 12 }}></div>
                                                        </Grid>
                                                    )
                                                })}
                                            </>
                                            : null
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify={'space-between'}>
                        <Button
                            variant={'outlined'}
                            color="primary"
                            onClick={onClose}
                        >
                            {t('cancel.label')}
                        </Button>
                        <ButtonComponent
                            t={t}
                            onClick={handlSubmitGsm}
                            loading={iotloading} />
                    </Grid>
                </DialogActions>
            </Dialog>
            <GsmSwitchTypes
                t={t}
                open={isGsmTypesenable}
                handleClose={handleCloseOfGsmTypes}
                gsmTypes={[
                    { id: "gsm_switch", name: "Gsm Switch", provider: "", type: "gsm_switch" }
                ]}
                handleSubmitGSMType={handleSubmitGSMType}
            />
        </>
    )
}

export default IotForm
