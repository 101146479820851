import React from 'react';
import { marginGenerator } from '../../../../theme/utils';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Typography from '@material-ui/core/Typography';
import TicketItem from '../TicketItem';
import PropTypes from 'prop-types';
import SearchBox from '../../../../components/SearchBox';

const TicketPanel = ({
  t,
  ticketList,
  type,
  filterList,
  handleTicketItemClick,
  handleMenuItemClick,
  handleSearch,
  search = true
}) => {

  return (
    <div>
      {search && <SearchBox handleSearch={handleSearch} />}
      {filterList &&
        filterList.map((filter, index) => (
          <OutlinedSelect
            key={index}
            val={filter.val}
            label={filter.label}
            handleChange={filter.handleChange}
            IconComponent={filter.iconComponent}
            options={filter.options}
            styleOverrides={filter.styleOverrides || null}
            disabled={filter.disabled || false}
          />
        ))}
      <Typography
        variant={'subtitle2'}
        style={{
          ...(filterList.length ? marginGenerator('mt-32'): marginGenerator('mt-0')),
          ...marginGenerator('mb-32')
        }}>
        {t('showing.label')} {ticketList.length}{' '}
        {t(`${type}.label`).toLowerCase()}
      </Typography>
      {ticketList.map((item, index) => (
        <TicketItem
          key={index}
          data={item}
          handleTicketItemClick={handleTicketItemClick}
          handleMenuItemClick={handleMenuItemClick}
        />
      ))}
    </div>
  );
};

TicketPanel.propTypes = {
  ticketList: PropTypes.array.isRequired,
  filterList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired,
  search: PropTypes.bool
};

export default TicketPanel;
