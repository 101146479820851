import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const BillingPanel = ({
  t,
  data,
  countryCodes
}) => {
    //let configTabs = [];
    return (
        <div>
            <Typography variant="h6">{data ? data.name : "-"}</Typography>
        </div>
    );
};

BillingPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};

export default BillingPanel;