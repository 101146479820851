import { gql } from 'apollo-boost';

/*
  Internal dashboard stats
 */
export const INTERNAL_STATS = gql`
  query Dashboad {
    internalDashboardStats {
      totalOrgs
      orgsSubscribed
      totalSubscriptions
      totalUsers
    }
  }
`;

/*
  All service tickets
 */
  export const ALL_SERVICE_TICKETS = gql`
  query Dashboard {
    dashboardRecentTickets {
      id
      ticketId
      title
      createdAt
      createdByName
      status
      priority
      updatedAt
      updatedByName
    }
  }
`;

export const GET_ALL_SUBSCRIPTION_AND_TICKETS = gql`
  query TicketSubscriptions {
    allServiceticketsAndSubscriptions {
      id
      name
      count
    }
  }
`;

export const CSOC_DASHBOARD_STATS = gql`
  query CsocDashboard {
    csocDashboardStat {
      openTicketCount
      closeTicketCount
      totalTicketCount
      unassignedTicketCount
    }
  }
`;

export const GET_ALL_ORG = gql`
  query getAllOrganizations {
    getAllOrganizations {
      organization_id
      name
      image
    }
  }
`;
