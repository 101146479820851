import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { InternalPaths, OrganisationPaths } from '../../../routes/routePaths';
import Header from '../../../components/Header';
import { useHistory } from 'react-router';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ORGANISATIONS_LIST, GET_USERS_LIST } from './queries';
import CustomTabs from '../../../components/CustomTabs';
import OrganisationPanel from './components/OrganisationPanel';
import { getUrl } from '../../../utils';
import { USER_ROLES } from '../../../utils/constants';
import { CREATE_ORGANIZATION } from '../../OnBoarding/mutations';
import CreateOrgModal from './components/CreateOrgModal';
import Message from '../../../components/Message';

const breadcrumbList = [
  { label: 'home.label', link: InternalPaths.Dashboard },
  { label: 'organisations.label', link: InternalPaths.organisations }
];

const OrganisationList = ({ t }) => {
  const history = useHistory();
  const role = localStorage.getItem('role');
  const [searched, setSearched] = useState(false);
  const [subscribedSearched, setSubscribedSearched] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const [orgListOrig, setOrgList] = useState([]);
  const [allOrgList, setAllOrgList] = useState([]);
  const [createOrgModalState, setCreateOrgModalState] = useState({
    open: false,
    btnLoading: false,
    clearData: false
  });
  const [searchOrganisation, setSearchOrganisation] = useState([]);
  const [subscribedOrgList, setSubscribedOrgList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [orgPage, setOrgPage] = useState(0);

  const {
    loading: orgLoading,
    error: orgError,
    data: orgList,
    refetch: reloadOrgList
  } = useQuery(ORGANISATIONS_LIST,
  {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      let orgs = [];
      if (data.organizations && data.organizations.length) {
        for (let item of data.organizations) {
          let tmpObj = {country: item.country ? item.country : "-", name: item.name, id: item.id, noOfUsers: item.noOfUsers};
          let deletedSubscriptions = [];
          let pendingSubscriptions = [];
          let activeSubscriptions = [];
          const susbNameArr = [];
          if (item.subscriptions && item.subscriptions.length) {
            for (let sub of item.subscriptions) {
              if (sub.isDelete) {
                deletedSubscriptions.push(sub);
              } else if (sub.status === 'Active') {
                activeSubscriptions.push(sub);
              } else if (sub.status === 'Pending') {
                pendingSubscriptions.push(sub);
              }
              if(!sub.isDelete){
                susbNameArr.push(sub.name);
              }
            }
          }
          tmpObj.subscriptions = (activeSubscriptions.length > 0) ? activeSubscriptions[0].name
            ? activeSubscriptions[0].name : activeSubscriptions[0].service.name : "";
          if(tmpObj.subscriptions){
            tmpObj.subscriptions += (activeSubscriptions.length - 1) ? ` ${t('and.label')} ${activeSubscriptions.length - 1} ${t('others.label')}` : "";
          }
          tmpObj.pendingSubscriptions = (pendingSubscriptions.length > 0) ? pendingSubscriptions[0].name
            ? pendingSubscriptions[0].name : pendingSubscriptions[0].service.name : "";
          if(tmpObj.pendingSubscriptions){
            tmpObj.pendingSubscriptions += (pendingSubscriptions.length - 1) ? ` ${t('and.label')} ${pendingSubscriptions.length - 1} ${t('others.label')}` : "";
          }
          tmpObj.deletedSubscriptions = (deletedSubscriptions.length > 0) ? deletedSubscriptions[0].name
          ? deletedSubscriptions[0].name : deletedSubscriptions[0].service.name : "";
          if(tmpObj.deletedSubscriptions){
            tmpObj.deletedSubscriptions += (deletedSubscriptions.length - 1) ? ` ${t('and.label')} ${deletedSubscriptions.length - 1} ${t('others.label')}` : "";
          }
          tmpObj.subsNames = susbNameArr.join(", ");
          orgs.push(tmpObj);
        }
      }
      setOrgList(orgs);
      setAllOrgList(orgs);
    }
  });

  const [getUsersList] = useLazyQuery(GET_USERS_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setUserLoading(false);
      setUserList(data);
    }
  });
  
  const handleOrgSearch = val => {
    if (val.length && allOrgList.length) {
      setSearched(true);
      let result = allOrgList.filter(o => o.name.includes(val));
      setSearchOrganisation(result);
    } else {
      setSearched(false);
    }
  };

  const handleSubscribedSearch = val => {
    if (val.length) {
      setSubscribedSearched(true);
      let result = subscribedOrgList.filter(o => o.name.includes(val));
      setUpdatedList(result);
    } else {
      setSubscribedSearched(false);
    }
  };

  const handleOrgItemClick = (id, name, email) => {
    const path = getUrl(OrganisationPaths.details, id);
    history.push({ pathname: path, search: `?${name}?${email || '-'}` });
  };

  const handleCreateOrgClick = () => {
    if (
      role.includes(USER_ROLES.IP_SUPERADMIN) ||
      role.includes(USER_ROLES.IP_COUNTRYADMIN)
    ) {
      if(orgLoading) return;
      setUserLoading(true);
      getUsersList();
      setCreateOrgModalState({
        open: true,
        clearData: false,
        btnLoading: false
      });
    } else {
      return null;
    }
  };

  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    onError: error => {
      console.log('Error', error);
      Message.error("Something went wrong!");
      setCreateOrgModalState({...createOrgModalState , btnLoading: false });
    },
    onCompleted: data => {
      if(data.createOrganization && data.createOrganization.id === "false"){
        Message.error(data.createOrganization.name);
        setCreateOrgModalState({...createOrgModalState , btnLoading: false });
        return null;
      }else{
        Message.success('Organization created successfully.');
        setCreateOrgModalState({
          open: false,
          clearData: true,
          btnLoading: false
        });
        reloadOrgList();
      }
    }
  });

  const handleCreateOrgSubmit = formData => {
    setCreateOrgModalState({...createOrgModalState , btnLoading: true });
    return createOrganization(formData);
  };

  useEffect(() => {
    if(orgListOrig.length){
      const subscribedList =
      orgListOrig && orgListOrig.length
      ? orgListOrig.filter(item => item.subscriptions !== "")
      : [];
      setSubscribedOrgList(subscribedList);
    }
  }, [orgListOrig]);

  const handlePageChange = (newPage) => {

  }

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('organisations.label')}
        subText={t('organisationsSub.label')}
        primaryButtonText={
          role.includes(USER_ROLES.IP_SUPERADMIN) ||
          role.includes(USER_ROLES.IP_COUNTRYADMIN)
            ? `${t('createNew.label')} ${t('organization.label')}`
            : null
        }
        handlePrimaryButtonClick={
          role.includes(USER_ROLES.IP_SUPERADMIN) ||
          role.includes(USER_ROLES.IP_COUNTRYADMIN)
            ? handleCreateOrgClick
            : null
        }
      />
      <CustomTabs
        tabs={[
          {
            label: t('allOrganisations.label'),
            children: (
              <>
                <OrganisationPanel
                  handleOrgItemClick={handleOrgItemClick}
                  orgList={(!orgLoading && !orgError)
                    ? allOrgList : []
                  }
                  handleSearch={handleOrgSearch}
                  t={t}
                  handlePageChange={handlePageChange}
                  page={orgPage}
                  isLoading={orgLoading}
                />
              </>
            )
          },
          {
            label: t('subscribed.label'),
            children: (
              <>
                <OrganisationPanel
                  handleOrgItemClick={handleOrgItemClick}
                  orgList={subscribedOrgList}
                  handleSearch={handleSubscribedSearch}
                  t={t}
                />
              </>
            )
          }
        ]}
      />
      <CreateOrgModal
        t={t}
        handleCreateOrgSubmit={handleCreateOrgSubmit}
        open={createOrgModalState.open}
        handleClose={evt =>
          setCreateOrgModalState({
            open: false,
            clearData: false,
            btnLoading: false
          })
        }
        modalState={createOrgModalState}
        users={userList && userList.userAccounts ? userList.userAccounts : []}
        userLoading={userLoading}
      />
    </Layout>
  );
};
export default withTranslation()(OrganisationList);
