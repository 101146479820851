import axios from 'axios';
import { FABRIC_URL } from '../envConstants';

const axiosInstance = axios.create({
  // baseURL: `${process.env.REACT_APP_API_BASE_URL}`
  baseURL: FABRIC_URL
});

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
