import React from 'react';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@material-ui/core';

const EditFcrPopup = ({
  openEditFcrPopup,
  setOpenEditFcrPopup,
  portName,
  setPortName,
  asn,
  setAsn,
  selectedFcrData,
  bgp,
  setBgp,
  handleSubmit,
  portSpeed,
  setPortSpeed,
  classes
}) => {
  return (
    <>
      <Popup
        title="Edit Configured Services"
        openPopup={openEditFcrPopup}
        setOpenPopup={setOpenEditFcrPopup}>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item container>
              <Grid item container alignItems="center" justify="center">
                <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                  FCR Name
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={portName}
                    onChange={e => setPortName(e.target.value)}
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item container alignItems="center" justify="center">
                <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                  Port Speed
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={portSpeed}
                    onChange={e => setPortSpeed(e.target.value)}
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item container alignItems="center" justify="center">
                <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                  ASN
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={asn}
                    onChange={e => setAsn(e.target.value)}
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item container alignItems="center" justify="center">
                <Grid item style={{ color: 'grey', fontWeight: '500' }} sm={5}>
                  BGP State
                </Grid>
                <Grid item sm={1}>
                  :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    select
                    value={bgp}
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true
                    }}
                    onChange={e => setBgp(e.target.value)}>
                    {[
                      { key: 'Enabled', value: 'Enabled' },
                      { key: 'Disabled', value: 'Disabled' }
                    ].map(option => (
                      <option key={option.key} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenEditFcrPopup(false);
            }}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={e => handleSubmit(selectedFcrData._id, e)}>
            Save Changes
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default EditFcrPopup;
