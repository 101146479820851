import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../theme/commonStyles';
import clsx from 'clsx';
import { TICKET_PRIORITY } from '../../../../utils/constants';
import { paddingGenerator } from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  priorityContainer: {
    height: '15px',
    borderRadius: '16px',
    alignItems: 'center',
    display: 'flex'
  },
  highPriority: {
    background: theme.palette.error.main
  },
  mediumPriority: {
    background: theme.palette.warning.main
  },
  lowPriority: {
    background: theme.palette.success.main
  },
  textStyle: {
    color: theme.palette.common.white
  }
}));

const PriorityPanel = ({ t, priority }) => {
    const classes = useStyles();
    const commonClasses = commonStyles();

    var statusClasses = classes.lowPriority;
    var label = "P3";
    if(priority === "P1"){
        statusClasses = classes.highPriority;
        label = "P1";
    }else if(priority === "P2"){
        statusClasses = classes.mediumPriority;
        label = "P2";
    }

    return (
        <div
        className={clsx(
            classes.priorityContainer,
            statusClasses
        )}
        style={{
            ...paddingGenerator('pt-7'),
            ...paddingGenerator('pb-6'),
            ...paddingGenerator('pl-14'),
            ...paddingGenerator('pr-14')
        }}>
        <Typography
            className={clsx(commonClasses.buttonTextSmall, classes.textStyle)}
            style={{fontSize:'13px'}}
            variant={'body1'}>
            {label}
        </Typography>
        </div>
    );
};

PriorityPanel.propTypes = {
  priority: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default PriorityPanel;
