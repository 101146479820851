import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateCashAdvanceRequestTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    CurrentUserName,
    handleUploadFiles,
    customerLoading
}) {
    const classes = useStyles();
    const [Department, setDepartment] = useState('');
    const [Date, setDate] = useState('');
    const [TransactionType, setTransactionType] = useState('');
    // workflow transaction
    const WorkflowInitFields = { description: '', amount: '' };
    const [WorkflowState, setWorkflowState] = useState([{ ...WorkflowInitFields }]);
    const [removeSelectedWorkflowFields, setSelectedRemoveWorkflowFields] = useState([]);
    const [removeFieldState, setRemoveFieldState] = useState({ Workflow: [] });
    const [Total, setTotal] = useState(0);
    const [CashAdvance, setCashAdvance] = useState(0);
    const [CashReturnDue, setCashReturnDue] = useState(0);
    const [NameOfStaff, setNameOfStaff] = useState('');
    const [BankName, setBankName] = useState('');
    const [AccountNumber, setAccountNumber] = useState('');
    const [UsersComment, setUsersComment] = useState('');
    const [AttachReceipt, setAttachReceipt] = useState('');
    const [LineManager, setLineManager] = useState('');
    const [LinkManagerUid, setLinkManagerUid] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [DisableCashAdvanceFields, setDisableCashAdvanceFields] = useState(false);
    const [DisableBankDetails, setDisableBankDetails] = useState(false);
    const isNumber = (str) => /^[0-9]*$/.test(str);
    const transactionDate = moment().format("YYYY-MM-DD");
    React.useEffect(() => {
        if (modalState.clearData === true) {
            setDepartment('');
            setDate();
            setTransactionType('');
            setWorkflowState([{ ...WorkflowInitFields }]);
            setSelectedRemoveWorkflowFields([]);
            setRemoveFieldState({ Workflow: [] });
            setTotal(0);
            setCashAdvance(0);
            setCashReturnDue(0);
            setNameOfStaff('');
            setBankName('');
            setAccountNumber('');
            setUsersComment('');
            setAttachReceipt('');
            setLineManager('');
            setLinkManagerUid('');
            setNextTeamMemberEmail('');
            setDisableCashAdvanceFields(false);
            setDisableBankDetails(false);
        }
    }, [modalState, CurrentUserName, allUserList, customerLoading]);

    const DepartmentList = [
        {
            id: t("BDG.label"),
            name: t("BDG.label")
        },
        {
            id: t("commercial.label"),
            name: t("commercial.label")
        },
        {
            id: t("customerServiceExperience.label"),
            name: t("customerServiceExperience.label")
        },
        {
            id: t("finance.label"),
            name: t("finance.label")
        },
        {
            id: t("humanCapital.label"),
            name: t("humanCapital.label")
        },
        {
            id: t("legal.label"),
            name: t("legal.label")
        },
        {
            id: t("technology.label"),
            name: t("technology.label")
        }
    ]
    const TransactionTypeList = [
        {
            id: t("advance.label"),
            name: t("advance.label")
        },
        {
            id: t("expense.label"),
            name: t("expense.label")
        },
        {
            id: t("retirement.label"),
            name: t("retirement.label")
        },
    ]
    const handleChangeAccountNumber = (e) => {
        const { value } = e.target;
        if (isNumber(value)) {
            setAccountNumber(value);
        }
    };
    const handleChangeCashAdvance = (e) => {
        const { value } = e.target;
        if (isNumber(value)) {
            setCashAdvance(value);
            // check retirement total
            if(TransactionType === t("retirement.label")){
                if(Total > value){
                    setDisableBankDetails(false);
                } else {
                    setDisableBankDetails(true);
                }
            }
        }
    };
    const handleChangeCashReturnDue = (e) => {
        const { value } = e.target;
        if (isNumber(value)) {
            setCashReturnDue(value);
        }
    };
    const addNewWorkflowFields = () => {
        // if(subscriptionData.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED){
        // 	if(!provider || !node){
        // 		Message.error('Please select provider and node first.');
        // 		return null;
        // 	}
        // 	let noOfCam = 0;
        // 	if(selectedNode && selectedNode.noOfCam){
        // 		noOfCam = selectedNode.noOfCam;
        // 	}
        let noOfCam = parseInt(WorkflowState.length);
        if (noOfCam >= 100) {
            Message.error('Transaction limit is exide for this node!');
            return null;
        }
        // }
        setWorkflowState(oldArray => [...oldArray, { ...WorkflowInitFields }]);
    }
    const removeWorkflowFields = () => {
        const updatedWorkflow = [...WorkflowState];
        const updateRemoveState = { ...removeFieldState };
        for (let index of updateRemoveState.Workflow) {
            index = parseInt(index);
            // console.log("remove fields : ", index);
            // if(updatedWorkflow.length > (index+1)){
            //     let getAutoGenerated = updatedWorkflow[index].auto_generated_project_id
            // }
            if (updatedWorkflow.length > 1) {
                updatedWorkflow.splice(index, 1);
            }
        }
        // // uncheck all the checkbox
        // var uncheck = document.getElementsByTagName('input');
        // for (var i = 0; i < uncheck.length; i++) {
        //     if (uncheck[i].type == 'checkbox') {
        //         uncheck[i].checked = false;
        //     }
        // }
        // update Workflow array
        setWorkflowState(updatedWorkflow);
        // Add amount
        let arrayOfWokrflow = updatedWorkflow;
        let sum = 0;
        arrayOfWokrflow.map(e => {
            sum = sum + parseInt(e.amount)
        })
        setTotal(sum);
        // check retirement total
        if(TransactionType === t("retirement.label")){
            if(sum > CashAdvance){
                setDisableBankDetails(false);
            } else {
                setDisableBankDetails(true);
            }
        }
        const newRmState = { ...removeFieldState };
        newRmState.Workflow = [];
        setRemoveFieldState(newRmState);
    }
    const handleWorkflowChange = (evt, idx, column) => {
        const updatedWorkflow = [...WorkflowState];
        if(column === 'amount'){
            const { value } = evt.target;
            if (isNumber(value)) {
                updatedWorkflow[idx][column] = value;
                // Add amount
                let arrayOfWokrflow = updatedWorkflow;
                let sum = 0;
                arrayOfWokrflow.map(e => {
                    sum = sum + parseInt(e.amount)
                })
                setTotal(sum);
                // check retirement total
                if(TransactionType === t("retirement.label")){
                    if(sum > CashAdvance){
                        setDisableBankDetails(false);
                    } else {
                        setDisableBankDetails(true);
                    }
                }
            }
        } else {
            updatedWorkflow[idx][column] = evt.target.value;
        }
        setWorkflowState(updatedWorkflow);
    }
    const onHandleSelectWorkflowRow = (evnt, index) => {
        let val = evnt.target.value.toString();
        let updatedState = { ...removeSelectedWorkflowFields };
        updatedState[index] = evnt.target.checked;
        setSelectedRemoveWorkflowFields(updatedState);
        if (evnt.target.checked) {
            setRemoveFieldState(prevState => ({
                ...prevState,
                Workflow: [...prevState.Workflow, val]
            }));
        } else {
            let prevRemoveState = { ...removeFieldState };
            let elemIdx = prevRemoveState.Workflow.indexOf(val);
            prevRemoveState.Workflow.splice(elemIdx, 1);
            setRemoveFieldState(prevRemoveState);
        }
    }
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('cashAdvanceExpenseRequestForm.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('name.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={CurrentUserName} disabled
                                type={'text'}
                                placeholder={t('Name.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('department.label')}`}
                                handleChange={evt => {
                                    setDepartment(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={DepartmentList ? DepartmentList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('date.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={transactionDate} disabled
                                type={'text'}
                                placeholder={t('date.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('transactionType.label')}`}
                                handleChange={evt => {
                                    setTransactionType(evt.target.value);
                                    if (evt.target.value == t("advance.label") || evt.target.value == t("expense.label")) {
                                        setCashAdvance(0);
                                        setCashReturnDue(0);
                                        setDisableCashAdvanceFields(true);
                                        setDisableBankDetails(false);
                                    } else {
                                        setDisableCashAdvanceFields(false);
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={TransactionTypeList ? TransactionTypeList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => {
                                addNewWorkflowFields();
                            }}
                        >
                            {t('addTransaction.label')}
                        </Button>
                        <Button
                            color="secondary"
                            variant={'contained'}
                            style={{ ...marginGenerator('ml-20') }}
                            disabled={!removeFieldState.Workflow.length}
                            onClick={() => {
                                removeWorkflowFields();
                                setSelectedRemoveWorkflowFields([]);
                            }}
                        >
                            {t('remove.label')}
                        </Button>
                    </Grid>
                    {WorkflowState.map((val, idx) => {
                        return (
                            <React.Fragment key={`Workflow-${idx}`}>
                                <Grid key={`heading-${idx}`} item xs={12} style={{ ...paddingGenerator('pl-0') }}>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        <Checkbox
                                            style={paddingGenerator('pl-0')}
                                            checked={removeSelectedWorkflowFields[idx]}
                                            value={idx}
                                            onChange={(event) => {
                                                onHandleSelectWorkflowRow(event, idx);
                                            }}
                                        />
                                        {t('transaction.label')} {(idx + 1)}
                                    </Typography>
                                </Grid>
                                <Grid key={`description-${idx}`} item xs={8}>
                                    <Input
                                        type={'text'}
                                        placeholder={`${t('description.label')}*`}
                                        style={{ width: '100%' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={WorkflowState[idx].description}
                                        onChange={evt => handleWorkflowChange(evt, idx, 'description')}
                                    />
                                </Grid>
                                <Grid key={`amount-${idx}`} item xs={4}>
                                    <Input
                                        type={'text'}
                                        placeholder={`${t('amount.label')}*`}
                                        style={{ width: '100%', minWidth: 'auto' }}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={WorkflowState[idx].amount}
                                        onChange={evt => handleWorkflowChange(evt, idx, 'amount')}
                                    />
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('total.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={Total} disabled
                                type={'text'}
                                placeholder={t('total.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('cashAdvance.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={CashAdvance}
                                disabled={DisableCashAdvanceFields ? true : false}
                                type={'text'}
                                placeholder={t('cashAdvance.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleChangeCashAdvance}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('cashReturnrDue.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={CashReturnDue}
                                disabled={DisableCashAdvanceFields ? true : false}
                                type={'text'}
                                placeholder={t('cashReturnrDue.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleChangeCashReturnDue}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('nameOfStaff.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={NameOfStaff}
                                disabled={DisableBankDetails ? true : false}
                                type={'text'}
                                placeholder={t('nameOfStaff.label')+'*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNameOfStaff(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('bankName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={BankName}
                                disabled={DisableBankDetails ? true : false}
                                type={'text'}
                                placeholder={t('bankName.label')+'*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setBankName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('accountNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <TextField
                                value={AccountNumber}
                                disabled={DisableBankDetails ? true : false}
                                type={'text'}
                                placeholder={t('accountNumber.label')+'*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleChangeAccountNumber}
                                inputProps={{
                                    maxlength: 10
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('usersComment.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={UsersComment}
                                type={'text'}
                                placeholder={t('usersComment.label')+'*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setUsersComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attachReceipt.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                placeholder={t('AttachReceipt.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setAttachReceipt(file);
                                    } else {
                                        setAttachReceipt("");
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('lineManager.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Autocomplete
                                id="country-select-demo"
                                val={LineManager}
                                options={allUserList ? allUserList.getAllUserByOnlyCountyID : []}
                                getOptionLabel={option =>
                                    `${option.firstName ? option.firstName : ""} ${option.lastName ? option.lastName : ""}`
                                }
                                onChange={(event, newValue) => {
                                    if (newValue == null) {
                                        setLineManager('');
                                        setLinkManagerUid('');
                                        setNextTeamMemberEmail('');
                                    }
                                    let fullName = newValue.firstName + ' ' + newValue.lastName;
                                    // console.log("newvalue : ", newValue)
                                    setLineManager(fullName);
                                    setLinkManagerUid(newValue.id);
                                    setNextTeamMemberEmail(newValue.email);
                                }}
                                loading={customerLoading}
                                autoHighlight
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ebebeb !important'
                                        }}
                                        {...params}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {customerLoading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                            {/* <OutlinedSelect
                                label={`${t('lineManager.label')} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setLineManager(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                setLinkManagerUid(allUserList[i].uid);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!Department || !TransactionType || !Total ||  !UsersComment || !AttachReceipt || !LineManager || !LinkManagerUid || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if(TransactionType === t("retirement.label") && (CashAdvance >= Total)){
                            } else if(!NameOfStaff || !BankName || !AccountNumber) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // workflow transaction
                            // console.log("WorkflowState : ", WorkflowState);
                            for (let index = 0; index < WorkflowState.length; index++) {
                                if (!WorkflowState[index].description || !WorkflowState[index].amount) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            // create URL for quatation file
                            let getAttachReceipt = "";
                            if (AttachReceipt != "" && AttachReceipt != null) {
                                let getAttachReceiptUrl = await handleUploadFiles(AttachReceipt, "CashAdvanceForm", 'cash_advance_form');
                                if (getAttachReceiptUrl.data && getAttachReceiptUrl.data.allUploadFile && getAttachReceiptUrl.data.allUploadFile.url) {
                                    getAttachReceipt = getAttachReceiptUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            let convertCashAdvance = CashAdvance;
                            let convertCashReturnDue = CashReturnDue;
                            if(typeof(CashAdvance) == 'string'){
                                convertCashAdvance = Number(CashAdvance);
                            }
                            if(typeof(CashReturnDue) == 'string'){
                                convertCashReturnDue = Number(CashReturnDue);
                            }
                            handleSubmitClick({
                                name: CurrentUserName,
                                department: Department,
                                date: transactionDate,
                                transaction_type: TransactionType,
                                total: Total,
                                cash_advance: convertCashAdvance,
                                cash_returned: convertCashReturnDue,
                                name_of_staff: NameOfStaff,
                                bank_name: BankName,
                                account_number: AccountNumber,
                                users_comment: UsersComment,
                                attach_receipt: getAttachReceipt,
                                work_flow_transaction: WorkflowState,
                                line_manager: LineManager,
                                line_manager_uid: LinkManagerUid,
                                next_team_member_email: nextTeamMemberEmail,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateCashAdvanceRequestTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCashAdvanceRequestTeamModal);
