import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import OcrTreeListings from './OcrTreeListings';
import OcrTreeSideBar from './OcrTreeSideBar';
import { CircularProgress } from '@material-ui/core';
import OcrSendEmailModal from './OcrSendEmailModal';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Message from '../../../../../components/Message';
import { SEND_OCR_ATTACHMENTS_EMAIL, ADD_STEPS_TO_PROCESS } from '../../../Ocr/mutations';
import { GET_OCR_TREEE_VIEW_SEARCH } from '../../../Ocr/queries';
import OcrProcessWorkFlow from './OcrProcessWorkFlow';
import {OcrContext} from '../../../../../contexts/OcrProvider';

const initViewOpt = {
  place: false,
  project: false,
  folder: false,
  file: false
};

const OcrTreeStructure = ({
  t,
  organizationId,
  ocrPlaceData,
  ocrProjectData,
  ocrFolderData,
  treeDataLoading,
  treeData,
  handleFilterdataForOptions,
  ocrFolderSubmit,
  handleSubmitOfOcrFolder,
  handlecreateFolderEnableFormClose,
  isFolderEnable,
  createFolderEnable,
  handleSubmitOfOcrProject,
  handleCreateProjectEnableFormClose,
  isProjectEnable,
  ocrProjectSubmit,
  handleOpenFileView,
  createProjectEnable,
  uploadOcrDoc,
  setTreeStruckcherDataForModal,
  handleSubmitOfDeleteOcrTreeItems,
  ocrConfigItemsDeletetingStarted,
  selecteIdForDelete,
  uploadMultipleOcrDoc,
  activeIndex,
  ocrDocTreeViewSearchData,
  ocrDocTreeViewSearchLoading,
  ocrTreeViewData,
  assignProjectEnable,
  handleSearchOfParseDocument,
  ocrFetchLoading,
  ocrData,
  createGroupOrUserEnable,
  ocrProcessList,
  ocrOcrProcessDataLoading
}) => {

  const [viewOptions, setViewOptions] = useState(initViewOpt);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState('');
  const [treeExapandArray, setTreeExapandArray] = useState([]);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [sendEmailFileData, setSendEmailFileData] = useState(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [ocrAutocompleteList, setOcrAutocompleteList] = useState([]);
  const [isProcessEnable, setIsProcessEnable] = useState(false);
  const [stepDataForOcrWorlFlow, setStepDataForOcrWorlFlow] = useState([]);
  const [selectedPrjoectNameForOcrSteps, setSelectedPrjoectNameForOcrSteps] = useState('');


  const { 
    handleRefetchedOrFilter,
    ocrUsersListLoading,
    allOcrUsersData
  } = useContext(OcrContext);
  
  const handleClickItem = (item, type, ...parents) => {
    if (type === "file") {
      return null;
    }

    setTreeStruckcherDataForModal({ item, type, parents: parents });
    setSelectedItem({ item, type, parents: parents })
    setViewOptions({ ...initViewOpt, [type]: true });
    let data = [];
    if (type === "place") {
      data = item.projects ? item.projects : []
    } else if (type === "project") {
      data = item.folders ? item.folders : []
    } else if (type === "folder") {
      if (item.fileId) {
        setSelectedFile(item.fileId);
        setTreeExapandArray([
          parents[0],
          parents[1],
          item.id
        ]);
      }
      data = item.files ? item.files : []
    }
    setSelectedItemData(data);
  }

  const handleSearch = (key) => {
    if (!selectedItem) return false;

    const { type, item } = selectedItem;
    let data = [];
    if (type === "place") {
      data = item.projects ? item.projects : [];
    } else if (type === "project") {
      data = item.folders ? item.folders : [];
    } else if (type === "folder") {
      data = item.files ? item.files : [];
    }
    data = data.filter(itm => {
      if (type === "folder") {
        return itm.file_name.includes(key);
      }
      return itm.name.includes(key);
    });
    setSelectedItemData(data);
  }

  useEffect(() => {
    if (treeData && treeData.length > 0 && activeIndex === 2) {
      handleClickItem(treeData[0], 'place', []);
      //setTreeExapandArray([treeData[0].id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, treeData]);

  const handleSendEmailClick = (item) => {
    setSendEmailFileData(item);
    setSendEmailModalOpen(true);
  }

  const handleSendEmailSubmit = (emailData) => {
    if (sendEmailFileData) {
      setSendEmailLoading(true);
      return sendOcrAttachmentEmail({
        variables: {
          organization: organizationId,
          id: sendEmailFileData.id,
          ...emailData
        }
      });
    }
  }

  const [sendOcrAttachmentEmail] = useMutation(SEND_OCR_ATTACHMENTS_EMAIL, {
    onCompleted: (res) => {
      if (res.sendOcrAttachmentEmail) {
        Message.success(res.sendOcrAttachmentEmail.message);
      }
      setSendEmailModalOpen(false);
      setSendEmailFileData(null);
      setSendEmailLoading(false);
    },
    onError: (err) => {
      setSendEmailModalOpen(false);
      setSendEmailFileData(null);
      setSendEmailLoading(false);
    }
  });


  const [addStespsToProcess, { loading: addStespsToProcessLoading }] = useMutation(ADD_STEPS_TO_PROCESS, {
    onError: error => {
        console.log('err', error);

    },
    onCompleted: data => {
        Message.success(data && data.addOcrProcessFlowSteps.message);
        handleRefetchedOrFilter('stepsProcess', '');
        handleCloseOfProcessFlow();
    }
  });

  const [getOcrTreeViewSearch, {loading: ocrAutocompleteLoading}] = useLazyQuery(GET_OCR_TREEE_VIEW_SEARCH, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      let searchedData = []
      if(res.getOcrTreeViewSearch && res.getOcrTreeViewSearch.data){
        searchedData = res.getOcrTreeViewSearch.data;
      }
      setOcrAutocompleteList(searchedData && searchedData.length ? searchedData.map(item => {
        return {
          name: item.name,
          id: item.id,
          place: item.place,
          folder: item.folder,
          project: item.project,
          heading: item.heading,
          ocr_text: item.ocr_text
        }
      }) : []);
    }
  });

  const handleSubmitOfProcessSteps = stepsData => {
    stepsData.forEach(el => {
    if(el.approvedOrRejectStatus === "") return Message.error('Plese select aprroved or reject !!');
    });

    addStespsToProcess({
      variables: {
          organization: organizationId,
          processFlowSteps: stepsData
      }
    })
  };

  const handleChangeOfAutocamplte = (newValue) => {
    if(newValue !== ""){
      return getOcrTreeViewSearch({
        variables: {
          organization: organizationId,
          search: newValue
        }
      });
    }else{
      setOcrAutocompleteList([]);
    }
  };
  const enableProceeFlow = (data, rowData) =>{
    setSelectedPrjoectNameForOcrSteps(rowData);
    setIsProcessEnable(true);
    if(data && data.length> 0){
    setStepDataForOcrWorlFlow(data);
    }
  };
  const handleCloseOfProcessFlow = () => {
    setStepDataForOcrWorlFlow([]);
    setIsProcessEnable(false);
  } 
  
  return (
    <Grid container>
      <Grid item xs={3}>
        {treeDataLoading ?
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CircularProgress />
          </div>
          :
          <OcrTreeSideBar
            t={t}
            treeData={treeData}
            handleClickItem={handleClickItem}
            selectedFile={selectedFile}
            treeExapandArray={treeExapandArray}
            ocrProcessList={ocrProcessList}
            isSearchable={false}
          />
        }
      </Grid>
      <Grid item xs={9}>
        <OcrTreeListings
          t={t}
          ocrFolderData={ocrFolderData}
          viewOptions={viewOptions}
          handleFilterdataForOptions={handleFilterdataForOptions}
          createFolderEnable={createFolderEnable}
          ocrChildData={selectedItemData}
          selectedItem={selectedItem}
          handleSearch={handleSearch}
          handleOpenFileView={handleOpenFileView}
          createProjectEnable={createProjectEnable}
          uploadOcrDoc={uploadOcrDoc}
          selecteIdForDelete={selecteIdForDelete}
          ocrFileDeletetingStarted={ocrConfigItemsDeletetingStarted}
          handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
          uploadMultipleOcrDoc={uploadMultipleOcrDoc}
          handleClickItem={handleClickItem}
          ocrDocTreeViewSearchData={ocrDocTreeViewSearchData}
          ocrDocTreeViewSearchLoading={ocrDocTreeViewSearchLoading}
          ocrTreeViewData={ocrTreeViewData}
          assignProjectEnable={assignProjectEnable}
          handleSearchOfParseDocument={handleChangeOfAutocamplte}
          ocrFetchLoading={ocrFetchLoading}
          ocrData={ocrData}
          ocrAutocompleteList={ocrAutocompleteList}
          organizationId={organizationId}
          handleSendEmailClick={handleSendEmailClick}
          handleChangeOfAutocamplte={handleChangeOfAutocamplte}
          ocrAutocompleteLoading={ocrAutocompleteLoading}
          createGroupOrUserEnable={createGroupOrUserEnable}
          enableProceeFlow={enableProceeFlow}
          ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
        />
      </Grid>
      {sendEmailFileData &&
        <OcrSendEmailModal
          t={t}
          open={sendEmailModalOpen}
          onClose={evt => {
            setSendEmailModalOpen(false);
            setSendEmailFileData(null);
          }}
          loading={sendEmailLoading}
          handleSubmit={handleSendEmailSubmit}
        />
      }
      <OcrProcessWorkFlow
       t={t}
       open={isProcessEnable}
       onClose={handleCloseOfProcessFlow}
       stepData={stepDataForOcrWorlFlow}
       handleSubmitOfProcessSteps={handleSubmitOfProcessSteps}
       addStespsToProcessLoading={addStespsToProcessLoading}
       projectName={selectedPrjoectNameForOcrSteps  && selectedPrjoectNameForOcrSteps.project_name}
       ocrUsersListLoading={ocrUsersListLoading}
       allOcrUsersData={allOcrUsersData}
       filesDetails={selectedPrjoectNameForOcrSteps  && selectedPrjoectNameForOcrSteps}
      />
    </Grid>
  );
};

export default OcrTreeStructure;
