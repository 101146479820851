import { gql } from 'apollo-boost';
/*
  Get Notification Template Details By Id
 */
  export const GET_LIVENESS_VIDEOS = gql`
  query LivenessVideoRecord($organization: String, $search: String, $page: Int, $limit: Int) {
      getLivenessVideos(organization: $organization, search: $search, page: $page, limit: $limit) {
        count
        videos
      }
  }
`;

export const GET_VIDEO_SIGNED_URL = gql`
  query LivenessVideoRecord($id: String, $url: String) {
      getVideoSignedUrl(id: $id, url: $url) {
        signed_url
      }
  }
`;