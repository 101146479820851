import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core/styles';

import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  styles: {
    padding: '9px 0px 8px 8px',
    border: '1px solid #ebebeb',
    borderRadius: '0px'
  }
}));

const DateFilter = ({ handleDateFilter }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState();

  const handleDatePicket = date => {
    setStartDate(date);
    handleDateFilter(date);
  };

  return (
    <DatePicker
      className={classes.styles}
      selected={startDate}
      onChange={date => handleDatePicket(date)}
      disabledKeyboardNavigation
      placeholderText="Choose Date"
    />
  );
};

export default DateFilter;
