import React, { useState } from 'react';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import HeatmapPanelItem from './HeatmapPanelItem';
import PieChartWrapper from '../../../../components/Charts/PieChartWrapper';
import LineChartWrapper from '../../../../components/Charts/LineChartWrapper';
import BarChartWrapper from '../../../../components/Charts/BarChartWrapper';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { marginGenerator } from '../../../../theme/utils';
import {
  GET_EDGE_AI_ANALYTICS_DATA,
  GET_NOTIFICATION_REPORT_CHART,
  GET_DIRECTIONAL_LINE_CROSS_REPORT,
  GET_PROCEED_NOTIFICATIONS_REPORT,
  GET_NOTIFICATIONS_EXCEL_REPORT,
  GET_NOTIFICATIONS_COUNTS_PER_DAY,
  GET_AGENT_WISE_REPORT,
  GET_PROCESSED_ALARM_COUNTS_REPORT
} from '../../AnalyticsQuery/queries';
import {
  convertLocalToUTCDate,
  evaluate,
  renderEdgeAITags,
  dateFormatterDDMMMYYYYHMS,
  removeSpacialCharecter,
  dateFormatterYYYYMMDD,
  convertLocalToUTC,
  dateFormatterLLL,
  getDifferenceInMinutes,
  JSONToCSVConvertor,
  dateFormatterLLLHHMMSS,
  dateFormatterDDMMMYYYY
} from '../../../../utils';
import {
  ACD_OBJECTS_LIST,
  AI_MODEL_LIST_TYPES,
  EDGE_AI_ORGS
} from '../../../../utils/constants';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import { GET_EVENT_IMAGES, GET_CAMERA_LIST } from '../../queries';
import MessageInfo from '../../../../components/Message';
import {
  CREATE_ON_DEMAND_HEATMAP,
  CREATE_PROCEED_NOTIFICATION_REPORT
} from '../../mutations';
import Slider from '@material-ui/core/Slider';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import CustomMuiDataTable from '../../../../components/CustomMuiDataTable';
import GenericTable from '../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import DataSheetTable from './DataSheetTable';
import Autocomplete from '../../../../components/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Statcard from '../../../../components/Statcard';
import { Pagination } from '@material-ui/lab';
import PriorityPanel from '../PriorityPanel';
import videoIcon from '../../../../assets/icons/video-icon.png';
import EventTagsModal from '../EventTagsModal';
import EventsVideoPlayBackModal from '../NotificationEventsPanel/EventsVideoPlayBackModal';
import VideocamIcon from '@material-ui/icons/Videocam';
import Icon from '@material-ui/core/Icon';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import Message from '../../../../components/Message';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow: 'hidden'
  },
  navRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#fff',
    marginBottom: 24
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  controls: {
    textAlign: 'center',
    marginTop: 15,
    display: 'inline-block'
  },
  input: {
    padding: '9px 22px'
    // marginLeft: "30px"
  },
  imgSlider: {
    width: '95%',
    marginLeft: 20
  },
  sliderCard: {
    width: '100%'
  },
  sliderGrid: {
    marginBottom: 20
  },
  searcBtn: {
    paddingBottom: 9,
    paddingTop: 10
  },
  tableEventImg: {
    borderRadius: '50%',
    width: 50,
    height: 50
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading, disabled, text, styling } = props;
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      size="medium"
      color="primary"
      style={{ marginLeft: styling }}
      className={classes.searcBtn}
      onClick={onClick}
      disabled={loading || disabled}>
      {loading && <CircularProgress size={14} />}
      {!loading && text}
    </Button>
  );
}

const trackingOtherFilterOpt = [
  { name: 'Equal to (=)', id: '==' },
  // {name: "Between (>= && <=)", id: "between"},
  { name: 'Greater then or equal to (>=)', id: '>=' },
  { name: 'Greater then (>)', id: '>' },
  { name: 'Less then or equal to (<=)', id: '<=' },
  { name: 'Less then (<)', id: '<' }
];

const reportList = [
  {
    key: 'tracker',
    name: 'Tracker',
    reports: [
      {
        name: 'Object Analytics',
        key: 'dwell_time',
        filters: [
          'cameraId',
          'startTime',
          'interval',
          'objectType',
          'X1',
          'Y1',
          'X2',
          'Y2'
        ],
        showPieChart: true
      },
      {
        name: 'Dwell time analysis',
        key: 'dwell_time_analysis',
        filters: [
          'cameraId',
          'startTime',
          'interval',
          'objectType',
          'X1',
          'Y1',
          'X2',
          'Y2'
        ],
        showPieChart: false
      },
      {
        name: 'Rate Of Change',
        key: 'rate_of_change',
        filters: [
          'cameraId',
          'startTime',
          'interval',
          'objectType',
          'X1',
          'Y1',
          'X2',
          'Y2'
        ],
        showPieChart: false
      },
      {
        name: 'License Plates',
        key: 'license_plates',
        filters: [
          'cameraId',
          'startTime',
          'interval',
          'list_type',
          'plate_number'
        ],
        showPieChart: false
      },
      {
        name: 'Face Recognition',
        key: 'face_recognition',
        templateName: 'face_recognition',
        filters: [
          'cameraId',
          'startTime',
          'interval',
          'list_type',
          'face_name'
        ],
        showPieChart: true
      },
      {
        name: 'Attendance Report (Face Recognition)',
        key: 'attendance_report',
        templateName: 'face_recognition',
        filters: [
          'cameraId',
          'startTime',
          'endTime',
          'officeTime',
          'thresholdDuration'
        ],
        showPieChart: false
      },
      {
        name: 'Open and Close Timer',
        key: 'open_close_timer',
        filters: ['cameraId', 'startTime', 'endTime', 'modelType'],
        showPieChart: false
      },
      {
        name: 'Sack Counting Report',
        key: 'sack_counting',
        filters: ['cameraId', 'startTime', 'endTime'],
        showPieChart: false
      }
    ]
  },
  {
    key: 'multiObject',
    templateName: 'multi-object',
    name: 'Multi-object',
    reports: [
      {
        name: 'Daily Aggregation',
        key: 'mo_report1',
        filters: ['cameraId', 'startTime', 'endTime'],
        endPoint: '/multi-object/violation/line/day',
        showPieChart: true
      },
      {
        name: 'Hourly Aggregation',
        key: 'mo_report2',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/multi-object/violation/line/hour',
        showPieChart: true
      },
      {
        name: 'Per Minute Aggregation',
        key: 'mo_report3',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/multi-object/violation/line/minute',
        showPieChart: true
      }
    ]
  },
  {
    key: 'licenseRecognition',
    templateName: 'license_plate_recognition',
    name: 'License Recognition',
    reports: [
      {
        name: 'Daily Aggregation',
        key: 'lr_report1',
        filters: ['cameraId', 'startTime', 'endTime'],
        endPoint: '/license-plate/line/day',
        showPieChart: false
      },
      {
        name: 'Hourly Aggregation',
        key: 'lr_report2',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/license-plate/line/hour',
        showPieChart: false
      },
      {
        name: 'Per Minute Aggregation',
        key: 'lr_report3',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/license-plate/line/minute',
        showPieChart: false
      }
    ]
  },
  {
    key: 'socialDistancing ',
    templateName: 'social_distancing',
    name: 'Social distancing',
    reports: [
      {
        name: 'Daily Aggregation',
        key: 'sd_report1',
        filters: ['cameraId', 'startTime', 'endTime'],
        endPoint: '/social-distancing/line/day',
        showPieChart: false
      },
      {
        name: 'Hourly Aggregation',
        key: 'sd_report2',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/social-distancing/line/hour',
        showPieChart: false
      },
      {
        name: 'Per Minute Aggregation',
        key: 'sd_report3',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/social-distancing/line/minute',
        showPieChart: false
      }
    ]
  },
  {
    key: 'facemask',
    name: 'Face Mask',
    templateName: 'facemask',
    reports: [
      {
        name: 'Daily Aggregation',
        key: 'fm_report1',
        filters: ['cameraId', 'startTime', 'endTime'],
        endPoint: '/facemask/line/day',
        showPieChart: false
      },
      {
        name: 'Hourly Aggregation',
        key: 'fm_report2',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/facemask/line/hour',
        showPieChart: false
      },
      {
        name: 'Per Minute Aggregation',
        key: 'fm_report3',
        filters: ['cameraId', 'startTime', 'interval'],
        endPoint: '/facemask/line/minute',
        showPieChart: false
      }
    ]
  },
  {
    key: 'lineCross',
    templateName: 'line_cross',
    name: 'Line Cross',
    reports: [
      {
        name: 'Daily Aggregation',
        key: 'lcreport1',
        filters: ['cameraId', 'startTime', 'endTime', 'lines'],
        endPoint: '/line-cross/line/day',
        showPieChart: false
      },
      {
        name: 'Hourly Aggregation',
        key: 'lc_report2',
        filters: ['cameraId', 'startTime', 'interval', 'lines'],
        endPoint: '/line-cross/line/hour',
        showPieChart: false
      },
      {
        name: 'Per Minute Aggregation',
        key: 'lc_report3',
        filters: ['cameraId', 'startTime', 'interval', 'lines'],
        endPoint: '/line-cross/line/minute',
        showPieChart: false
      }
    ]
  }
];

const intervalList = [
  { name: '1hr', id: 1 },
  { name: '6hrs', id: 6 },
  { name: '12hrs', id: 12 },
  { name: '18hrs', id: 18 },
  { name: '24hrs', id: 24 }
];
const initFilters = {
  cameraId: [],
  startTime: null,
  interval: '',
  objectType: [],
  position: '',
  endTime: null,
  x1: '',
  y1: '',
  x2: '',
  y2: '',
  x1Value: '',
  y1Value: '',
  x2Value: '',
  y2Value: '',
  waitTime: '',
  lines: [],
  waitTimeCond: '',
  threshold: '0.7',
  heatmapObject: '',
  plate_number: '',
  list_type: '',
  face_name: '',
  reportType: 'unique_count',
  thresholdDuration: '',
  officeTime: null
};
const initDataObj = {
  dataKeyXAxis: [],
  stackKeyNames: [],
  lineData: [],
  pieData: [],
  barChartData: [],
  newPlates: [],
  barChartHeading: '',
  pieChartHeading: '',
  tblHeading: ''
};
Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
};

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};

function getDatesFromStartEnd(startDate, stopDate) {
  let dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(dateFormatterYYYYMMDD(currentDate));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

const proceedNotificationColumn = [
  {
    label: 'Start Date',
    key: 'dateTime'
  },
  {
    label: 'End Date',
    key: 'endDate'
  },
  {
    label: 'Total Count',
    key: 'totalCount'
  },
  {
    label: 'Processed Count',
    key: 'processedCount'
  },
  {
    label: 'Agreed Count',
    key: 'agreedCount'
  },
  {
    label: 'Disagreed Count',
    key: 'disagreedCount'
  },
  {
    label: 'Download',
    key: 'download'
  }
];

const notificationColumn = [
  {
    label: 'Places',
    key: 'place'
  },
  {
    label: 'Camera Name',
    key: 'camera'
  },
  {
    label: 'Event Date Time',
    key: 'createdAt'
  },
  {
    label: 'Prority',
    key: 'priority'
  },
  {
    label: 'Tags',
    key: 'tags'
  },
  {
    label: 'Proof snap',
    key: 'image'
  },
  {
    label: 'Video',
    key: 'video'
  }
];

const initDrLineState = {
  Total: 0,
  Unique: 0,
  TotalOut: 0,
  TotalInside: 0,
  UniqueOut: 0,
  UniqueInside: 0
};

const PreBuildAnalytics = ({
  t,
  cameraList,
  placeList,
  mlTemplateList,
  loading,
  data,
  aiTypes,
  heatmapLoading,
  heatmapError,
  heatmapList,
  handleImageModalClick,
  handleAutoPlaceSearch
}) => {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState({});
  const [activeMenuItem, setActiveMenuItem] = useState({});
  const [filterState, setFilterState] = useState(null);
  const [graphDataState, setGraphDataState] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeReportMenu, setActiveReportMenu] = useState('');
  const [detectedObjects, setDetectedObjects] = useState({});
  const [activeMenuData, setActiveMenuData] = useState(null);
  const [listedMLTemplates, setListedMLTemplates] = useState([]);
  const [selectCameraList, setSelectCameraList] = useState([]);
  const [selectLines, setSelectLines] = useState([]);
  const [dwellTimeActiveObj, setDwellTimeActiveObj] = useState('');
  const [rocActiveObj, setRocActiveObj] = useState('');
  const [disableState, setDisableState] = useState(true);
  const [roiEventLoading, setRoiEventLoading] = useState({
    dwell_time: false,
    dwell_time_analysis: false,
    rate_of_change: false
  });
  const [objAnalyticsActiveObj, setObjAnalyticsActiveObj] = useState('');
  const [selectedCameraName, setSelectedCameraName] = useState('');
  const [pdfBtnLoadding, setPdfBtnLoadding] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [reloadAckngLoading, setReloadAckngLoading] = useState(false);
  const [activeReportCameraList, setActiveReportCameraList] = useState({});
  const [expanded, setExpanded] = React.useState('panel1');
  const [dirLineApiCall, setDirLineApiCall] = useState(0);
  const [dlCrossLastEndTime, setDLCrossLastEndTime] = useState(null);
  const [downloadCsvLoading, setDownloadCsvLoading] = useState({});
  const [acknowledgeFilters, setAcknowledgeFilters] = useState(null);
  const [dlineCrossData, setDlineCrossData] = useState({ ...initDrLineState });
  const [dlinePersonCountData, setDlinePersonCountData] = useState({
    ...initDrLineState
  });
  const [eventTagModalState, setEventTagModalState] = useState(false);
  const [activeEventTags, setActiveEventTags] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openVideoPlayBackModal, setOpenVideoPlayBackModal] = useState(false);

  // const {
  //     data: userdata,
  //     loading: userDataLoading,
  //     error: userDataError
  // } = useQuery(GET_USER_INFO);

  const [getCameraList] = useLazyQuery(GET_CAMERA_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const camList =
        data.getAllCameraList && data.getAllCameraList.cameras
          ? data.getAllCameraList.cameras.map(item => {
            return { name: item.name, id: item.camera_id };
          })
          : [];
      setActiveReportCameraList({
        ...activeReportCameraList,
        [activeReportMenu]: camList
      });
    }
  });

  const customDateFormat = date => {
    var dateStr =
      ('00' + date.getDate()).slice(-2) +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2);
    return dateStr;
  };

  const customDateFormat1 = date => {
    var dateStr =
      ('00' + date.getDate()).slice(-2) +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ':' +
      ('00' + date.getSeconds()).slice(-2);
    return dateStr;
  };

  const [getAnalyticsChartData] = useLazyQuery(GET_EDGE_AI_ANALYTICS_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res.getAnalyticsChartData && res.getAnalyticsChartData.data) {
        if (activeReportMenu && activeReportMenu === 'dwell_time') {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            if (
              trackingData[0].objects.length > 0 &&
              trackingData[0].data &&
              trackingData[0].data.length > 0
            ) {
              let lineChartData = [];
              let xAxis = [];
              // let pieChartData = [];
              // const objects = trackingData[0].objects;
              const dwellTimeData = trackingData[0].data;
              const dataArr = [];
              dwellTimeData.map(itm => {
                xAxis.push(customDateFormat1(new Date(itm.ts.value)));
                dataArr.push(parseInt(itm.avgCount));
              });
              // let objCnt = dataArr.reduce((a, b) => a + b, 0);
              // let pieTmpObj = { value: objCnt, name: objects[0] };
              let tmpObje = {
                type: 'bar',
                data: dataArr,
                large: true
              };
              lineChartData.push(tmpObje);
              setGraphDataState(prevState => {
                let newSatate = { ...prevState };
                newSatate[activeReportMenu].lineData = lineChartData;
                newSatate[activeReportMenu].dataKeyXAxis = xAxis;
                newSatate[activeReportMenu].pieData = [];
                newSatate[activeReportMenu].stackKeyNames = [];
                return newSatate;
              });
            } else {
              setDetectedObjects({ [activeReportMenu]: [] });
            }
          }
        } else if (
          activeReportMenu &&
          (activeReportMenu === 'dwell_time_analysis' ||
            activeReportMenu === 'rate_of_change')
        ) {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            if (
              trackingData[0].objects.length > 0 &&
              trackingData[0].data &&
              trackingData[0].data.length > 0
            ) {
              const objects = trackingData[0].objects;
              let activeObj;
              if (activeReportMenu === 'rate_of_change') {
                if (rocActiveObj) {
                  activeObj = rocActiveObj;
                } else {
                  if (objects && objects.length) {
                    activeObj = objects[0];
                    setRocActiveObj(objects[0]);
                  }
                  setDetectedObjects({ [activeReportMenu]: objects });
                }
              } else {
                if (dwellTimeActiveObj) {
                  activeObj = dwellTimeActiveObj;
                } else {
                  if (objects && objects.length) {
                    activeObj = objects[0];
                    setDwellTimeActiveObj(objects[0]);
                  }
                  setDetectedObjects({ [activeReportMenu]: objects });
                }
              }
              let coutObjPerMin = trackingData[0].data;
              let countedObj = {};
              let lineChartData = [];
              let xAxis = [];
              let timeArray = [];
              if (filterState[activeReportMenu]) {
                coutObjPerMin = coutObjPerMin.filter(itm => {
                  // let cond1 = true, cond2 = true, cond3 = true, cond4 = true;
                  // if (filterState[activeReportMenu].x1 && filterState[activeReportMenu].x1Value) {
                  //     if (!evaluate(itm.x1, filterState[activeReportMenu].x1Value, filterState[activeReportMenu].x1)) {
                  //         cond1 = false;
                  //     }
                  // }
                  // if (filterState[activeReportMenu].x2 && filterState[activeReportMenu].x2Value) {
                  //     if (!evaluate(itm.x2, parseFloat(filterState[activeReportMenu].x2Value), filterState[activeReportMenu].x2)) {
                  //         cond2 = false;
                  //     }
                  // }
                  // if (filterState[activeReportMenu].y1 && filterState[activeReportMenu].y1Value) {
                  //     if (!evaluate(itm.y1, filterState[activeReportMenu].y1Value, filterState[activeReportMenu].y1)) {
                  //         cond3 = false;
                  //     }
                  // }
                  // if (filterState[activeReportMenu].y2 && filterState[activeReportMenu].y2Value) {
                  //     if (!evaluate(itm.y2, filterState[activeReportMenu].y2Value, filterState[activeReportMenu].y2)) {
                  //         cond4 = false;
                  //     }
                  // }
                  // if (cond1 && cond2 && cond3 && cond4) {
                  // }
                  if (itm.ts) {
                    itm.ts.value = customDateFormat(new Date(itm.ts.value));
                    timeArray.push(itm.ts.value);
                  }
                  return itm;
                });
              }
              timeArray = [...new Set(timeArray)];
              if (timeArray.length > 0) {
                xAxis = timeArray.map(function (val) {
                  let matchObjects = coutObjPerMin.filter(
                    s => s.ts.value === val
                  );
                  countedObj[val] = {};
                  if (activeReportMenu === 'rate_of_change') {
                    if (matchObjects.length) {
                      countedObj[val][matchObjects[0].OBJECT_NAME] =
                        matchObjects[0].diff_to_prev;
                    }
                  } else {
                    matchObjects.map(itm => {
                      let minutes = itm.wt
                        ? Math.floor(parseInt(itm.wt) / 60)
                        : 0;
                      if (
                        countedObj[val] &&
                        typeof countedObj[val][itm.OBJECT_NAME] !== 'undefined'
                      ) {
                        countedObj[val] = {
                          [itm.OBJECT_NAME]:
                            parseInt(countedObj[val][itm.OBJECT_NAME]) +
                            minutes,
                          count: countedObj[val].count
                            ? countedObj[val].count + 1
                            : 0
                        };
                      } else {
                        countedObj[val] = {
                          [itm.OBJECT_NAME]: minutes,
                          count: 1
                        };
                      }
                    });
                  }
                  return val;
                });
              }
              let dataArr = [];
              if (activeReportMenu === 'rate_of_change') {
                for (let key in countedObj) {
                  if (
                    countedObj.hasOwnProperty(key) &&
                    typeof countedObj[key][activeObj] !== 'undefined'
                  ) {
                    dataArr.push(countedObj[key][activeObj]);
                  }
                }
              } else {
                for (let key in countedObj) {
                  if (
                    countedObj.hasOwnProperty(key) &&
                    typeof countedObj[key][activeObj] !== 'undefined'
                  ) {
                    let cntObj = Math.floor(
                      countedObj[key][activeObj] / countedObj[key].count
                        ? countedObj[key].count
                        : 1
                    );
                    dataArr.push(cntObj);
                  }
                }
              }

              let tmpObje = {
                name: activeReportMenu === 'rate_of_change' ? 'ROC' : 'Minutes',
                type: 'line',
                smooth: true,
                data: dataArr
              };
              lineChartData.push(tmpObje);
              setGraphDataState(prevState => {
                let newSatate = { ...prevState };
                newSatate[activeReportMenu].lineData = lineChartData;
                newSatate[activeReportMenu].dataKeyXAxis = xAxis;
                newSatate[activeReportMenu].stackKeyNames = objects;
                return newSatate;
              });
            } else {
              setDetectedObjects({ [activeReportMenu]: [] });
            }
          }
        } else if (activeReportMenu && activeReportMenu === 'license_plates') {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            let plateCountData =
              trackingData[0] &&
                trackingData[0].data &&
                trackingData[0].data.length > 0
                ? trackingData[0].data
                : [];
            let barChartData = [];
            let pieChartData = [];
            let totalUniquePlatesCount = 0;
            if (plateCountData.length) {
              barChartData = plateCountData.map(itm => {
                let date = new Date(itm.ts.value);
                date = moment(date).format('YYYY-MM-DD HH:SS');
                totalUniquePlatesCount =
                  totalUniquePlatesCount + parseInt(itm.plate_count);
                return { date: date, count: itm.plate_count };
              });
            }
            let stackKeyNames =
              trackingData[0].repeatedPlates &&
                trackingData[0].repeatedPlates.length > 0
                ? trackingData[0].repeatedPlates.map(itm => {
                  let pltNoArr = itm.PLATE_NUMBER.split(':');
                  return pltNoArr && pltNoArr.length ? pltNoArr[0] : itm;
                })
                : [];
            stackKeyNames = [...new Set(stackKeyNames)];
            if (
              trackingData[0].repeatedPlates &&
              trackingData[0].repeatedPlates.length > 0
            ) {
              trackingData[0].repeatedPlates.map(itm => {
                let pieTmpObj = {
                  value: itm.plate_count,
                  name: itm.PLATE_NUMBER
                };
                pieChartData.push(pieTmpObj);
              });
            }
            const columns = [
              {
                name: 'ts',
                label: t('time.label'),
                options: {
                  filter: false,
                  sort: true,
                  display: true
                }
              },
              {
                name: 'plate_count',
                label: t('count.label'),
                options: {
                  filter: false,
                  sort: true,
                  display: true
                }
              },
              {
                name: 'PLATE_NUMBER',
                label: t('plateNumber.label'),
                options: {
                  filter: true,
                  sort: false,
                  display: true,
                  filterType: 'multiselect'
                }
              }
            ];
            const newPlates =
              trackingData[0].newPlates && trackingData[0].newPlates.length
                ? trackingData[0].newPlates.map(itm => {
                  let date = new Date(itm.ts.value);
                  date = moment(date).format('YYYY-MM-DD HH:SS');
                  itm.ts = date;
                  return itm;
                })
                : [];
            setGraphDataState(prevState => {
              let newSatate = { ...prevState };
              newSatate[activeReportMenu].lineData = [];
              newSatate[activeReportMenu].barChartData = barChartData;
              newSatate[activeReportMenu].dataKeyXAxis = [];
              newSatate[activeReportMenu].stackKeyNames = stackKeyNames;
              newSatate[activeReportMenu].pieData = pieChartData;
              newSatate[activeReportMenu].newPlates = newPlates;
              newSatate[activeReportMenu].columns = columns;
              newSatate[activeReportMenu].totalCount = totalUniquePlatesCount;
              newSatate[activeReportMenu].barChartHeading = t(
                'licensePlatesUnqCountPerHr.label'
              );
              newSatate[activeReportMenu].pieChartHeading = t(
                'licensePlatesRepetedCountPerHr.label'
              );
              newSatate[activeReportMenu].tblHeading = t(
                'licensePlatesNewCountPerHr.label'
              );
              return newSatate;
            });
          }
        } else if (
          activeReportMenu &&
          activeReportMenu === 'face_recognition'
        ) {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            let faceCountData =
              trackingData[0] &&
                trackingData[0].unique_count &&
                trackingData[0].unique_count.length > 0
                ? trackingData[0].unique_count
                : [];
            let barChartData = [];
            let pieChartData = [];
            if (faceCountData.length) {
              barChartData = faceCountData.map(itm => {
                let date = new Date(itm.date);
                date = moment(date).format('YYYY-MM-DD HH:SS');
                return { date: date, count: itm.count };
              });
            }
            let stackKeyNames = [];
            if (
              trackingData[0].repeated_count &&
              trackingData[0].repeated_count.length > 0
            ) {
              trackingData[0].repeated_count.map(itm => {
                let pieTmpObj = { value: itm.value, name: itm.name };
                pieChartData.push(pieTmpObj);
                stackKeyNames.push(itm.name);
              });
            }
            stackKeyNames = [...new Set(stackKeyNames)];
            setGraphDataState(prevState => {
              let newSatate = { ...prevState };
              newSatate[activeReportMenu].lineData = [];
              newSatate[activeReportMenu].barChartData = barChartData;
              newSatate[activeReportMenu].dataKeyXAxis = [];
              newSatate[activeReportMenu].stackKeyNames = stackKeyNames;
              newSatate[activeReportMenu].pieData = pieChartData;
              newSatate[activeReportMenu].newPlates = [];
              newSatate[activeReportMenu].columns = null;
              newSatate[activeReportMenu].barChartHeading = t(
                'faceRecUnqCountPerHr.label'
              );
              newSatate[activeReportMenu].pieChartHeading = t(
                'faceRecRepetedCountPerHr.label'
              );
              return newSatate;
            });
          }
        } else if (
          activeReportMenu &&
          activeReportMenu === 'attendance_report'
        ) {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            const attendanceReportData = trackingData[0];
            const dateArray = getDatesFromStartEnd(
              new Date(filterState[activeReportMenu].startTime),
              new Date(filterState[activeReportMenu].endTime)
            );
            let lateComerBarChartData = [];
            let lateComers = {};
            let earlyComerBarChartData = [];
            let earlyComer = {};
            if (dateArray.length > 0) {
              lateComerBarChartData = dateArray.map(itm => {
                //Get Late commers report data
                let lateComersData = null;
                let tmpObj = { date: itm, count: 0 };
                if (
                  typeof attendanceReportData.late_comers_data !== 'undefined'
                ) {
                  lateComersData = attendanceReportData.late_comers_data;
                } else {
                  lateComersData = attendanceReportData;
                }
                if (
                  lateComersData &&
                  lateComersData.hasOwnProperty(itm) &&
                  lateComersData[itm]
                ) {
                  console.log('lateComersData[itm]: ', lateComersData[itm]);
                  tmpObj.count = lateComersData[itm].count
                    ? lateComersData[itm].count
                    : 0;
                  if (
                    lateComersData[itm].faces &&
                    lateComersData[itm].faces.length
                  ) {
                    lateComers[itm] = lateComersData[itm].faces.map(face => {
                      if (face && Object.keys(face).length) {
                        let fName = Object.keys(face)[0];
                        let time = face[fName]
                          ? dateFormatterDDMMMYYYYHMS(new Date(face[fName]))
                          : '-';
                        return {
                          name: fName,
                          time,
                          face: face?.image_url ? (
                            <img
                              src={face.image_url}
                              style={{ width: 80, cursor: 'pointer' }}
                              alt={fName}
                            />
                          ) : (
                            '-'
                          )
                        };
                      }
                    });
                  }
                }
                //Get Early commers/On Time report data
                let earlyComersData = null;
                if (
                  typeof attendanceReportData.early_comers_data !== 'undefined'
                ) {
                  earlyComersData = attendanceReportData.early_comers_data;
                }
                let earlyTmpObj = { date: itm, count: 0 };
                if (
                  earlyComersData &&
                  earlyComersData.hasOwnProperty(itm) &&
                  earlyComersData[itm]
                ) {
                  earlyTmpObj.count = earlyComersData[itm].count
                    ? earlyComersData[itm].count
                    : 0;
                  if (
                    earlyComersData[itm].faces &&
                    earlyComersData[itm].faces.length
                  ) {
                    earlyComer[itm] = earlyComersData[itm].faces.map(face => {
                      if (face && Object.keys(face).length) {
                        let fName = Object.keys(face)[0];
                        let time = face[fName]
                          ? dateFormatterDDMMMYYYYHMS(new Date(face[fName]))
                          : '-';
                        return {
                          name: fName,
                          time,
                          face: face.image_url ? (
                            <img
                              style={{ width: 80, cursor: 'pointer' }}
                              onClick={handleOpenImg}
                              src={face.image_url}
                              alt={fName}
                            />
                          ) : (
                            '-'
                          )
                        };
                      }
                    });
                  }
                }
                earlyComerBarChartData.push(earlyTmpObj);
                return tmpObj;
              });
            }
            const columns = [
              {
                name: 'name',
                label: t('faceName.label')
              },
              {
                name: 'face',
                label: 'Face Image'
              },
              {
                name: 'time',
                label: t('arrivalTime.label')
              }
            ];
            setGraphDataState(prevState => {
              let newSatate = { ...prevState };
              newSatate[activeReportMenu].lineData = [];
              newSatate[activeReportMenu].barChartData = lateComerBarChartData;
              newSatate[activeReportMenu].dataKeyXAxis = [];
              newSatate[activeReportMenu].stackKeyNames = [];
              newSatate[activeReportMenu].pieData = [];
              newSatate[activeReportMenu].columns = columns;
              newSatate[activeReportMenu].lateComers = lateComers;
              newSatate[activeReportMenu].earlyComer = earlyComer;
              newSatate[activeReportMenu].barChartHeading = t(
                'lateComersCountPerDay.label'
              );
              newSatate[
                activeReportMenu
              ].earlyComerBarChartData = earlyComerBarChartData;
              return newSatate;
            });
          }
        } else if (
          activeReportMenu &&
          activeReportMenu === 'open_close_timer'
        ) {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length) {
            const columns = [
              {
                key: 'date',
                label: t('date.label')
              },
              {
                key: 'openTime',
                label: t('openTime.label')
              },
              {
                key: 'closeTime',
                label: t('closeTime.label')
              },
              {
                key: 'images',
                label: t('images.label')
              }
            ];
            const openCloseData = trackingData.map(item => {
              return {
                date: item.date,
                openTime:
                  item.firstEvent && item.firstEvent.time
                    ? dateFormatterLLL(item.firstEvent.time)
                    : '-',
                closeTime:
                  item.lastEvent && item.lastEvent.time
                    ? dateFormatterLLL(item.lastEvent.time)
                    : '-',
                images: (
                  <>
                    {item.firstEvent && item.firstEvent.image && (
                      <img
                        onClick={handleOpenImg}
                        className={classes.tableEventImg}
                        src={item.firstEvent.image}
                        alt={'Open Event'}
                      />
                    )}
                    {item.lastEvent && item.lastEvent.image && (
                      <img
                        onClick={handleOpenImg}
                        className={classes.tableEventImg}
                        style={{ marginLeft: 10 }}
                        src={item.lastEvent.image}
                        alt={'Close Event'}
                      />
                    )}
                  </>
                )
              };
            });
            setGraphDataState(prevState => {
              let newSatate = { ...prevState };
              newSatate[activeReportMenu].lineData = [];
              newSatate[activeReportMenu].barChartData = [];
              newSatate[activeReportMenu].dataKeyXAxis = [];
              newSatate[activeReportMenu].stackKeyNames = [];
              newSatate[activeReportMenu].pieData = [];
              newSatate[activeReportMenu].columns = columns;
              newSatate[activeReportMenu].tableHeading =
                'Open and Close Timer List';
              newSatate[activeReportMenu].tableData = openCloseData;
              return newSatate;
            });
          }
        } else if (activeReportMenu && activeReportMenu === 'sack_counting') {
          const sackReportData = JSON.parse(res.getAnalyticsChartData.data);
          let sackExcelReportData = [];
          sackReportData.map(itm => {
            let formatedTime = customDateFormat(new Date(itm.time));
            sackExcelReportData.push({ count: itm.count, date: formatedTime });
            return formatedTime;
          });
          const key = 'date';
          sackExcelReportData = [
            ...new Map(
              sackExcelReportData.map(item => [item[key], item])
            ).values()
          ];
          const totalCount = sackExcelReportData.reduce(
            (n, { count }) => n + count,
            0
          );
          setGraphDataState(prevState => {
            let newSatate = { ...prevState };
            newSatate[activeReportMenu].lineData = [];
            newSatate[activeReportMenu].barChartData = sackExcelReportData;
            newSatate[activeReportMenu].dataKeyXAxis = [];
            newSatate[activeReportMenu].stackKeyNames = [];
            newSatate[activeReportMenu].barChartHeading = t(
              'sackCoutingReportGraph.label'
            );
            newSatate[
              activeReportMenu
            ].sackExcelReportData = sackExcelReportData;
            newSatate[activeReportMenu].totalCount = totalCount;
            return newSatate;
          });
        } else {
          let trackingData = JSON.parse(res.getAnalyticsChartData.data);
          if (trackingData.length > 0) {
            let objects = [];
            let totalVideoAlertCounts = 0;
            let totalVideoCounts = 0;
            const series =
              trackingData[0].series &&
              trackingData[0].series.map(item => {
                item.smooth = true;
                if (activeReportMenu === 'processed_videos') {
                  if (item.name === 'video') {
                    totalVideoCounts =
                      item.data && item.data.length > 0
                        ? item.data.reduce((a, b) => a + b, 0)
                        : 0;
                  }
                  if (item.name === 'alerts') {
                    totalVideoAlertCounts =
                      item.data && item.data.length > 0
                        ? item.data.reduce((a, b) => a + b, 0)
                        : 0;
                  }
                }
                objects.push(item.name);
                return item;
              });
            objects = [...new Set(objects)];
            let xAxisData =
              trackingData[0].xAxis &&
                trackingData[0].xAxis.data &&
                trackingData[0].xAxis.data.length > 0
                ? trackingData[0].xAxis.data
                : [];
            if (activeReportMenu === 'processed_videos') {
              xAxisData = xAxisData.map(itm => dateFormatterDDMMMYYYYHMS(itm));
            } else {
              //if(activeMenuData.endPoint && activeMenuData.endPoint.includes("/hour")){
              //     xAxisData = xAxisData.map(itm => {
              //         let dateTimeArr = itm.split(" ");
              //         if(dateTimeArr.length > 0 && dateTimeArr.length === 2){
              //             let dateStr = dateTimeArr[0];
              //             let timeStr = dateTimeArr[1];
              //             if(timeStr){
              //                 let timeArr = timeStr.split(":");
              //                 if(timeArr.length > 0){
              //                     return new Date(dateStr + " " + timeArr[0] + ":00:00");
              //                 }
              //             }
              //         }else{
              //             return new Date(itm);
              //         }
              //     });
              // }else if(activeMenuData.endPoint && activeMenuData.endPoint.includes("/minute")){
              // }else if(activeMenuData.endPoint && activeMenuData.endPoint.includes("/day")){
              // }
            }
            setGraphDataState(prevState => {
              let newState = { ...prevState };
              newState[activeReportMenu].lineData = series ? series : [];
              newState[activeReportMenu].dataKeyXAxis = xAxisData;
              newState[activeReportMenu].stackKeyNames = objects;
              newState[
                activeReportMenu
              ].totalVideoAlertCounts = totalVideoAlertCounts;
              newState[activeReportMenu].totalVideoCounts = totalVideoCounts;
              newState[activeReportMenu].isExecute = true;
              return newState;
            });
          }
        }
      }
      setBtnLoading(false);
    },
    onError: e => {
      setBtnLoading(false);
      console.log('Error: ', e);
    }
  });

  const [getTopEventsROIImage] = useLazyQuery(GET_EVENT_IMAGES, {
    fetchPolicy: 'no-cache',
    onError: error => {
      setRoiEventLoading({ ...roiEventLoading, [activeReportMenu]: false });
    },
    onCompleted: res => {
      setRoiEventLoading({ ...roiEventLoading, [activeReportMenu]: false });
      if (res.getEventImages && res.getEventImages.length > 0) {
        const imgurl = res.getEventImages[0].url;
        localStorage.setItem('roi_img_url', imgurl);
        if (
          activeReportMenu === 'directional_line_crossing' ||
          activeReportMenu === 'directional_line_person_counting'
        ) {
          window.open(
            '/directionalLineCross?action=' + activeReportMenu,
            '_blank'
          );
        } else {
          window.open(
            '/getMultipleRoiAxis?action=' + activeReportMenu,
            '_blank'
          );
        }
        return null;
      } else {
        MessageInfo.success('Event not found for this camera!');
      }
    }
  });

  const [createOnDemandHeatmap] = useMutation(CREATE_ON_DEMAND_HEATMAP, {
    onError: err => {
      setBtnLoading(false);
    },
    onCompleted: res => {
      setFilterState({ ...filterState, heatmap: initFilters });
      setBtnLoading(false);
      MessageInfo.success('Heatmap Will be generated in Events Model.');
    }
  });

  const [createProeedNotificationReport] = useMutation(
    CREATE_PROCEED_NOTIFICATION_REPORT,
    {
      onCompleted: res => {
        return getProceedNotifications({
          variables: {
            organization: data.organizationId
          }
        });
      }
    }
  );

  const handleShowEventTags = tags => {
    setActiveEventTags(tags);
    setEventTagModalState(true);
  };

  const [getNotificationsReportList] = useLazyQuery(
    GET_NOTIFICATION_REPORT_CHART,
    {
      fetchPolicy: 'no-cache',
      onError: err => {
        setBtnLoading(false);
        setReloadAckngLoading(false);
      },
      onCompleted: async res => {
        setBtnLoading(false);
        setReloadAckngLoading(false);
        if (activeReportMenu === 'notification_report') {
          if (res.getNotificationsReportList) {
            const notifications = res.getNotificationsReportList.notifications.map(
              rowitem => {
                return {
                  place: rowitem.place
                    ? rowitem.place.name
                    : rowitem.camera && rowitem.camera.place
                      ? rowitem.camera.place.name
                      : '-',
                  camera: rowitem.camera ? rowitem.camera.name : '-',
                  createdAt: dateFormatterLLLHHMMSS(rowitem.created_at),
                  priority: (
                    <PriorityPanel
                      priority={
                        rowitem.priority == 1
                          ? 'P1'
                          : rowitem.priority == 2
                            ? 'P2'
                            : 'P3'
                      }
                      t={t}
                    />
                  ),
                  image: (
                    <img
                      onClick={evt => {
                        handleImageModalClick(rowitem.image_url);
                      }}
                      src={
                        rowitem.image_url
                          ? rowitem.image_url.includes('mp4')
                            ? videoIcon
                            : rowitem.image_url
                          : ''
                      }
                      alt={'event'}
                      style={{
                        height: 50,
                        width: 50,
                        cursor: 'pointer',
                        borderRadius: '50%'
                      }}
                    />
                  ),
                  video: (
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedRow(rowitem);
                        setOpenVideoPlayBackModal(true);
                      }}>
                      <Icon
                        component={() => (
                          <VideocamIcon
                            color={'secondary'}
                            style={{ marginTop: '2px', marginRight: '6px' }}
                          />
                        )}
                        alt={'icon'}
                      />
                    </Button>
                  ),
                  tags: (
                    <>
                      <Button
                        color="primary"
                        onClick={() => {
                          if (Object.keys(rowitem.tags).toString() === 'type') {
                            MessageInfo.error('No Details Availble');
                          } else {
                            handleShowEventTags(rowitem.tags);
                          }
                        }}>
                        {renderEdgeAITags(rowitem.tags)}
                      </Button>
                    </>
                  )
                };
              }
            );
            setGraphDataState(prevState => {
              let newSatate = { ...prevState };
              newSatate[activeReportMenu].notifications = notifications;
              newSatate[activeReportMenu].count =
                res.getNotificationsReportList.count;
              return newSatate;
            });
          }
          return null;
        }
        let agreeCount =
          res.getNotificationsReportList &&
            res.getNotificationsReportList.agreeCount
            ? res.getNotificationsReportList.agreeCount
            : 0;
        let disagreeCount =
          res.getNotificationsReportList &&
            res.getNotificationsReportList.disagreeCount
            ? res.getNotificationsReportList.disagreeCount
            : 0;
        let processedCount =
          res.getNotificationsReportList &&
            res.getNotificationsReportList.acknowledgedCount
            ? res.getNotificationsReportList.acknowledgedCount
            : 0;
        let pieChartData = [
          {
            name: 'Total',
            value:
              res.getNotificationsReportList &&
                res.getNotificationsReportList.count
                ? res.getNotificationsReportList.count
                : 0
          },
          { name: 'Processed', value: processedCount },
          { name: 'Agreed', value: agreeCount },
          { name: 'Disagreed', value: disagreeCount }
        ];
        if (
          activeReportMenu === 'acknowledges' &&
          data.organizationId === EDGE_AI_ORGS.CTRACK &&
          res.getNotificationsReportList &&
          res.getNotificationsReportList.count &&
          acknowledgeFilters &&
          acknowledgeFilters.dateTime &&
          acknowledgeFilters.endTime
        ) {
          let reportData = {
            startTime: acknowledgeFilters.dateTime,
            endTime: acknowledgeFilters.endTime,
            agreedCount: 0,
            disagreedCount: 0,
            totalCount: 0,
            processedCount: 0,
            organization: EDGE_AI_ORGS.CTRACK
          };
          reportData.disagreedCount = disagreeCount;
          reportData.agreedCount = agreeCount;
          reportData.processedCount = processedCount;
          reportData.totalCount = res.getNotificationsReportList.count;
          if (reportData.totalCount) {
            createProeedNotificationReport({
              variables: reportData
            });
          }
        }
        setGraphDataState(prevState => {
          let newSatate = { ...prevState };
          newSatate['acknowledges'].pieData = pieChartData;
          newSatate['acknowledges'].stackKeyNames = [
            'Total',
            'Processed',
            'Agreed',
            'Disagreed'
          ];
          return newSatate;
        });
        setReportLoading(false);
      }
    }
  );

  const [getAgentWiseReport,
    { loading: getAgentWiseReportLoading }] = useLazyQuery(
      GET_AGENT_WISE_REPORT,
      {
        fetchPolicy: 'no-cache',
        onError: err => {
          setBtnLoading(false);
        },
        onCompleted: async res => {
          setBtnLoading(false);
          if (activeReportMenu === 'processed_alarm_counts_agents') {
            if (res.getAgentWiseReport) {
              let pieChartData = [];
              if (
                res.getAgentWiseReport &&
                res.getAgentWiseReport.data.length > 0
              ) {
                res.getAgentWiseReport.data.map(itm => {
                  let pieTmpObj = {
                    value: itm.processed_count,
                    name: itm.agent_name
                  };
                  pieChartData.push(pieTmpObj);
                });
              }
              setGraphDataState(prevState => {
                let newSatate = { ...prevState };
                newSatate[activeReportMenu].pieData = pieChartData;
                newSatate[activeReportMenu].pieChartDataLoaded = true;
                return newSatate;
              });
            }
            return null;
          }
          let agreeCount =
            res.getNotificationsReportList &&
              res.getNotificationsReportList.agreeCount
              ? res.getNotificationsReportList.agreeCount
              : 0;
          let disagreeCount =
            res.getNotificationsReportList &&
              res.getNotificationsReportList.disagreeCount
              ? res.getNotificationsReportList.disagreeCount
              : 0;
          let processedCount =
            res.getNotificationsReportList &&
              res.getNotificationsReportList.acknowledgedCount
              ? res.getNotificationsReportList.acknowledgedCount
              : 0;
          let pieChartData = [
            {
              name: 'Total',
              value:
                res.getNotificationsReportList &&
                  res.getNotificationsReportList.count
                  ? res.getNotificationsReportList.count
                  : 0
            },
            { name: 'Processed', value: processedCount },
            { name: 'Agreed', value: agreeCount },
            { name: 'Disagreed', value: disagreeCount }
          ];
          if (
            activeReportMenu === 'acknowledges' &&
            data.organizationId === EDGE_AI_ORGS.CTRACK &&
            res.getNotificationsReportList &&
            res.getNotificationsReportList.count &&
            acknowledgeFilters &&
            acknowledgeFilters.dateTime &&
            acknowledgeFilters.endTime
          ) {
            let reportData = {
              startTime: acknowledgeFilters.dateTime,
              endTime: acknowledgeFilters.endTime,
              agreedCount: 0,
              disagreedCount: 0,
              totalCount: 0,
              processedCount: 0,
              organization: EDGE_AI_ORGS.CTRACK
            };
            reportData.disagreedCount = disagreeCount;
            reportData.agreedCount = agreeCount;
            reportData.processedCount = processedCount;
            reportData.totalCount = res.getNotificationsReportList.count;
            if (reportData.totalCount) {
              createProeedNotificationReport({
                variables: reportData
              });
            }
          }
          setGraphDataState(prevState => {
            let newSatate = { ...prevState };
            newSatate['acknowledges'].pieData = pieChartData;
            newSatate['acknowledges'].stackKeyNames = [
              'Total',
              'Processed',
              'Agreed',
              'Disagreed'
            ];
            return newSatate;
          });
          setReportLoading(false);
        }
      }
    );

  const [getDirectionalLineCrossReport] = useLazyQuery(
    GET_DIRECTIONAL_LINE_CROSS_REPORT,
    {
      fetchPolicy: 'no-cache',
      onError: err => {
        setBtnLoading(false);
      },
      onCompleted: res => {
        if (dirLineApiCall > 0) {
          if (res.getDirectionalLineCrossReport) {
            if (activeReportMenu === 'directional_line_person_counting') {
              setDlinePersonCountData(prevState => {
                let newState = { ...prevState };
                newState.Total = parseInt(
                  prevState.Total +
                  parseInt(res.getDirectionalLineCrossReport.Total)
                );
                newState.Unique = parseInt(
                  prevState.Unique +
                  parseInt(res.getDirectionalLineCrossReport.Unique)
                );
                newState.TotalOut = parseInt(
                  prevState.TotalOut +
                  parseInt(res.getDirectionalLineCrossReport.TotalOut)
                );
                newState.UniqueOut = parseInt(
                  prevState.UniqueOut +
                  parseInt(res.getDirectionalLineCrossReport.UniqueOut)
                );
                return newState;
              });
            } else {
              setDlineCrossData(prevState => {
                let newState = { ...prevState };
                newState.Total = parseInt(
                  prevState.Total +
                  parseInt(res.getDirectionalLineCrossReport.Total)
                );
                newState.Unique = parseInt(
                  prevState.Unique +
                  parseInt(res.getDirectionalLineCrossReport.Unique)
                );
                return newState;
              });
            }
          }
          let newStartTime = new Date(dlCrossLastEndTime);
          let startTime = dlCrossLastEndTime;
          let endTime =
            dirLineApiCall > 1
              ? startTime.addHours(8)
              : filterState[activeReportMenu].endTime;
          setDLCrossLastEndTime(endTime);
          newStartTime =
            new Date(convertLocalToUTCDate(newStartTime)).getTime() / 1000;
          endTime = new Date(convertLocalToUTCDate(endTime)).getTime() / 1000;
          setDirLineApiCall(prevData => {
            let newData = prevData;
            return parseInt(newData) - 1;
          });
          let camId = filterState[activeReportMenu].cameraId.id;
          const dlFilterData = {
            organization: data.organizationId,
            cameraId: camId.toString(),
            dateTime: newStartTime,
            endTime,
            line_croods: {
              x1: filterState[activeReportMenu].x1Value,
              y1: filterState[activeReportMenu].y1Value,
              x2: filterState[activeReportMenu].x2Value,
              y2: filterState[activeReportMenu].y2Value
            },
            direction: filterState[activeReportMenu].direction,
            actionType: filterState[activeReportMenu].actionType,
            reportType: activeReportMenu,
            ...filterState[activeReportMenu].imageResolution
          };
          return getDirectionalLineCrossReport({ variables: dlFilterData });
        } else {
          let dirLineCrossData = {};
          if (activeReportMenu === 'directional_line_person_counting') {
            dirLineCrossData = res.getDirectionalLineCrossReport
              ? { ...res.getDirectionalLineCrossReport }
              : initDrLineState;
            dirLineCrossData = {
              Total: dirLineCrossData.Total + dlinePersonCountData.Total,
              Unique: dirLineCrossData.Unique + dlinePersonCountData.Unique,
              TotalOut:
                dirLineCrossData.TotalOut + dlinePersonCountData.TotalOut,
              UniqueOut:
                dirLineCrossData.UniqueOut + dlinePersonCountData.UniqueOut
            };
            dirLineCrossData.TotalInside =
              dirLineCrossData.Total - dirLineCrossData.TotalOut;
            dirLineCrossData.UniqueInside =
              dirLineCrossData.Unique - dirLineCrossData.UniqueOut;
          } else {
            dirLineCrossData = res.getDirectionalLineCrossReport
              ? { ...res.getDirectionalLineCrossReport }
              : initDrLineState;
            dirLineCrossData = {
              Total: dirLineCrossData.Total + dlineCrossData.Total,
              Unique: dirLineCrossData.Unique + dlineCrossData.Unique,
              actionType: res.getDirectionalLineCrossReport.actionType
                ? res.getDirectionalLineCrossReport.actionType
                : '-'
            };
          }
          setBtnLoading(false);
          setReportLoading(false);
          setGraphDataState(prevState => {
            let newSatate = { ...prevState };
            newSatate[activeReportMenu].directionalLineData = dirLineCrossData;
            newSatate[activeReportMenu].lineData = [];
            newSatate[activeReportMenu].barChartData = [];
            newSatate[activeReportMenu].dataKeyXAxis = [];
            newSatate[activeReportMenu].stackKeyNames = [];
            newSatate[activeReportMenu].pieData = [];
            newSatate[activeReportMenu].newPlates = [];
            newSatate[activeReportMenu].columns = null;
            return newSatate;
          });
        }
      }
    }
  );

  const handleFilterChange = (evt, name = null) => {
    let key;
    let value;
    if (name === 'threshold') {
      key = name;
      value = evt;
    } else {
      key = name !== null ? name : evt.target.name;
      value = evt.target.value ? evt.target.value : '';
    }
    if (activeReportMenu === 'dwell_time_analysis' && key === 'objectType') {
      setDwellTimeActiveObj(value);
    } else if (activeReportMenu === 'rate_of_change' && key === 'objectType') {
      setRocActiveObj(value);
    } else if (activeReportMenu === 'dwell_time' && key === 'objectType') {
      setObjAnalyticsActiveObj(value);
    } else {
      setFilterState(prevState => {
        let newState = { ...prevState };
        if (key === 'plate_number') {
          value = removeSpacialCharecter(value);
          value = value.replace(/\s+/g, '');
          value = value.toUpperCase();
        }
        newState[activeReportMenu] = {
          ...newState[activeReportMenu],
          [key]: value
        };
        return newState;
      });
    }
  };

  const handleOpenNavClick = key => {
    if (btnLoading) return null;
    setOpenNav({ ...openNav, [key]: !openNav[key] });
  };

  React.useEffect(() => {
    if (
      reportList.length > 0 &&
      reportList[0].reports &&
      reportList[0].reports.length > 0
    ) {
      setActiveReportMenu(reportList[0].reports[0].key);
      setActiveMenuItem({
        ...activeMenuItem,
        [reportList[0].reports[0].key]: true
      });
      setFilterState({
        ...filterState,
        [reportList[0].reports[0].key]: initFilters
      });
      setActiveMenuData(reportList[0].reports[0]);
      setOpenNav({ ...openNav, [reportList[0].key]: true });
      setGraphDataState(prevState => {
        let newSatate = { ...prevState };
        newSatate[reportList[0].reports[0].key] = { ...initDataObj };
        if (typeof newSatate['acknowledges'] == 'undefined') {
          newSatate['acknowledges'] = {
            dataKeyXAxis: [],
            stackKeyNames: [],
            lineData: [],
            pieData: []
          };
        }
        return newSatate;
      });
      setReportLoading(true);
      getNotificationsReportList({
        variables: {
          organization: data.organizationId,
          cameraId: '',
          placeId: '',
          dateTime: null,
          endTime: null,
          page: 1,
          modelType: '',
          limit: 10
        }
      });
    }
    let initCamList = {};
    reportList.map(itm => {
      if (itm.reports && itm.reports.length) {
        itm.reports.map(report => {
          if (report.filters && report.filters.includes('cameraId')) {
            initCamList[report.key] = [];
          }
        });
      }
    });
    setActiveReportCameraList(initCamList);
    getCameraList({
      variables: {
        organization: data.organizationId,
        page: 1,
        limit: 20,
        search: ''
      }
    });
  }, []);

  React.useEffect(() => {
    let types = {};
    if (mlTemplateList && mlTemplateList.length) {
      for (let item of mlTemplateList) {
        if (typeof types[item.model_type] === 'undefined') {
          types[item.model_type] = item.noOfCam;
        } else {
          types[item.model_type] = types[item.model_type] + item.noOfCam;
        }
      }
    }

    if (types) {
      const mlTemplates = [];
      for (let key in types) {
        if (types.hasOwnProperty(key) && types[key] > 0) {
          mlTemplates.push(key);
        }
      }
      setListedMLTemplates(mlTemplates);
    }

    // if(mlTemplateList && mlTemplateList.length > 0 && cameraList && cameraList.length > 0){
    //     for(let cam of cameraList){
    //         cam.model_type = [];
    //         if(cam.templates && cam.templates.length){
    //             mlTemplateList.map(function( obj ) {
    //                 if(cam.templates.includes(obj.id)){
    //                     cam.model_type.push(obj.model_type);
    //                 }
    //             });
    //         }
    //     }
    //     setSelectCameraList(cameraList);
    // }
  }, [mlTemplateList]);

  React.useEffect(() => {
    if (filterState && filterState[activeReportMenu]) {
      let disabled = true;
      if (filterState[activeReportMenu].cameraId instanceof Array) {
        if (
          filterState[activeReportMenu].cameraId &&
          filterState[activeReportMenu].cameraId.length > 0
        ) {
          disabled = false;
        } else {
          disabled = true;
        }
      } else {
        if (filterState[activeReportMenu].cameraId) {
          disabled = false;
        } else {
          disabled = true;
        }
      }
      setDisableState(disabled);
    }
  }, [filterState]);

  const handleSubNavItemClick = (item, report) => {
    if (btnLoading) return null;
    console.log("ITEM-------->>>>>", item);
    setActiveMenuData(item);
    setActiveMenuItem({ [item.key]: true });
    setActiveReportMenu(item.key);
    getCameraList({
      variables: {
        organization: data.organizationId,
        page: 1,
        limit: 20,
        search: ''
      }
    });
    if (report && report.templateName) {
      setSelectCameraList(
        cameraList
          ? cameraList.filter(cam => {
            if (
              cam.model_type &&
              cam.model_type.length > 0 &&
              cam.model_type.includes(report.templateName)
            ) {
              return cam;
            }
          })
          : []
      );
    } else if (item && item.templateName) {
      setSelectCameraList(
        cameraList
          ? cameraList.filter(cam => {
            if (
              cam.model_type &&
              cam.model_type.length > 0 &&
              cam.model_type.includes(item.templateName)
            ) {
              return cam;
            }
          })
          : []
      );
    } else {
      setSelectCameraList(cameraList);
    }

    setFilterState({
      ...filterState,
      [item.key]: filterState[item.key] ? filterState[item.key] : initFilters
    });
    setGraphDataState(prevState => {
      let newSatate = { ...prevState };
      if (typeof newSatate[item.key] == 'undefined') {
        newSatate[item.key] = {
          dataKeyXAxis: [],
          stackKeyNames: [],
          lineData: [],
          pieData: []
        };
      }
      return newSatate;
    });
  };

  const handleSelectCamera = camId => {
    setFilterState(prevState => {
      let newState = { ...prevState };
      newState[activeReportMenu] = {
        ...newState[activeReportMenu],
        cameraId: camId
      };
      return newState;
    });
    if (camId && Array.isArray(camId)) {
      let selectedCamName = camId.map(el =>
        activeReportCameraList[activeReportMenu]
          ? activeReportCameraList[activeReportMenu].find(
            cam => cam.id === el.id
          )
          : []
      );
      setSelectedCameraName(prevState => {
        let newState = { ...prevState };
        newState = { ...newState, selectedCamName };
        return newState;
      });
    }
    if (['lcreport1', 'lc_report2', 'lc_report3'].includes(activeReportMenu)) {
      let selectedCam = activeReportCameraList[activeReportMenu].filter(
        cam => cam.id === camId
      );
      let lines = [];
      if (
        selectedCam.length > 0 &&
        selectedCam[0].templates &&
        selectedCam[0].templates.length > 0
      ) {
        for (let model of mlTemplateList) {
          if (
            selectedCam[0].templates.includes(model.id) &&
            model.model_type === 'line_cross'
          ) {
            if (
              model.definition &&
              model.definition.line_cross &&
              model.definition.line_cross.lines
            ) {
              for (let line of Object.keys(model.definition.line_cross.lines)) {
                lines.push(line);
              }
            }
          }
        }
      }
      setSelectLines(lines);
    }
  };

  const handleOpenImg = evt => {
    if (evt.target.src) {
      handleImageModalClick(evt.target.src);
    }
    return null;
  };

  const handleFilterSubmit = () => {
    if (filterState && filterState[activeReportMenu]) {
      if (activeReportMenu === 'dwell_time') {
        if (!objAnalyticsActiveObj) {
          MessageInfo.error('Please select object first!');
          return null;
        }
      }
      if (activeReportMenu === 'rate_of_change') {
        if (!rocActiveObj) {
          MessageInfo.error('Please select object first!');
          return null;
        }
      }
      if (activeReportMenu === 'dwell_time_analysis') {
        if (!dwellTimeActiveObj) {
          MessageInfo.error('Please select object first!');
          return null;
        }
      }
      if (activeReportMenu === 'attendance_report') {
        if (
          !filterState[activeReportMenu].startTime ||
          !filterState[activeReportMenu].endTime
        ) {
          MessageInfo.error('Please select start date and end date!');
          return null;
        }
        if (!filterState[activeReportMenu].officeTime) {
          MessageInfo.error('Please select office time!');
          return null;
        }
      }
      if (graphDataState[activeReportMenu]) {
        setGraphDataState(prevState => {
          let newSatate = { ...prevState };
          newSatate[activeReportMenu].lineData = [];
          newSatate[activeReportMenu].dataKeyXAxis = [];
          newSatate[activeReportMenu].pieData = [];
          newSatate[activeReportMenu].stackKeyNames = [];
          return newSatate;
        });
      }

      //Setting up startTime and endTime
      let startTime = filterState[activeReportMenu].startTime;
      let endTime = new Date(filterState[activeReportMenu].startTime);
      if (activeMenuData.filters.includes('interval')) {
        endTime.setHours(
          endTime.getHours() + parseInt(filterState[activeReportMenu].interval)
        );
      } else if (activeMenuData.filters.includes('endTime')) {
        endTime = filterState[activeReportMenu].endTime;
      }
      // Change Start time end time for Attendance report
      if (
        activeReportMenu === 'attendance_report' ||
        activeReportMenu === 'open_close_timer'
      ) {
        startTime = new Date(dateFormatterYYYYMMDD(startTime) + ' 00:00:00');
        endTime = new Date(dateFormatterYYYYMMDD(endTime) + ' 23:59:59');
      }
      startTime = new Date(convertLocalToUTCDate(startTime)).getTime() / 1000;
      endTime = new Date(convertLocalToUTCDate(endTime)).getTime() / 1000;

      let objectAnalyticsFilters = filterState[activeReportMenu];
      let cameraIdsArr = [];
      if (filterState[activeReportMenu].cameraId instanceof Array) {
        if (filterState[activeReportMenu].cameraId.length > 0) {
          cameraIdsArr = filterState[activeReportMenu].cameraId
            ? filterState[activeReportMenu].cameraId.map(itm =>
              itm.id.toString()
            )
            : [];
        } else {
          if (activeReportMenu === 'attendance_report') {
            cameraIdsArr.push('all-cam');
          } else {
            MessageInfo.error('Please select camera!');
            return null;
          }
        }
      } else {
        if (filterState[activeReportMenu].cameraId) {
          cameraIdsArr.push(
            filterState[activeReportMenu].cameraId &&
              filterState[activeReportMenu].cameraId.id
              ? filterState[activeReportMenu].cameraId.id.toString()
              : ''
          );
        } else {
          cameraIdsArr.push('all-cam');
        }
      }
      if (activeReportMenu === 'attendance_report') {
        let punchToTime = new Date(filterState[activeReportMenu].officeTime);
        punchToTime.addHours(
          filterState[activeReportMenu].thresholdDuration
            ? parseInt(filterState[activeReportMenu].thresholdDuration)
            : 4
        );
        objectAnalyticsFilters.punchToTime = convertLocalToUTC(punchToTime);
      }
      let filterData = {
        organization: data.organizationId,
        cameraId: cameraIdsArr,
        startTime,
        endTime,
        objectType:
          activeReportMenu === 'dwell_time' && objAnalyticsActiveObj
            ? [objAnalyticsActiveObj]
            : activeReportMenu === 'rate_of_change' && rocActiveObj
              ? [rocActiveObj]
              : activeReportMenu === 'dwell_time_analysis' && dwellTimeActiveObj
                ? [dwellTimeActiveObj]
                : filterState[activeReportMenu].objectType,
        urlEndPoint: activeMenuData.endPoint ? activeMenuData.endPoint : '',
        objectAnalyticsFilters: JSON.stringify(objectAnalyticsFilters),
        lines: filterState[activeReportMenu].lines,
        activeReportMenu: activeReportMenu
      };
      if (activeReportMenu === 'attendance_report') {
        filterData.officeTime = filterState[activeReportMenu].officeTime
          ? convertLocalToUTC(filterState[activeReportMenu].officeTime)
          : '00:00';
      }
      setBtnLoading(true);
      return getAnalyticsChartData({ variables: filterData });
    } else {
      return null;
    }
  };

  const handleChangeLineFilter = evt => {
    setFilterState(prevState => {
      let newState = { ...prevState };
      newState[activeReportMenu] = {
        ...newState[activeReportMenu],
        lines: evt.target.value
      };
      return newState;
    });
  };

  const handleGetRoi = () => {
    if (activeReportMenu && filterState[activeReportMenu].cameraId.length > 0) {
      setRoiEventLoading({ ...roiEventLoading, [activeReportMenu]: true });
      return getTopEventsROIImage({
        variables: {
          cameraId: filterState[activeReportMenu].cameraId[0].id.toString(),
          lastEvent: true
        }
      });
    }
    return null;
  };

  const handleDrawDirectionalLine = () => {
    if (
      activeReportMenu &&
      filterState[activeReportMenu] &&
      filterState[activeReportMenu].cameraId
    ) {
      setRoiEventLoading({ ...roiEventLoading, [activeReportMenu]: true });
      return getTopEventsROIImage({
        variables: {
          cameraId: filterState[activeReportMenu].cameraId.id.toString(),
          lastEvent: true
        }
      });
    }
    MessageInfo.error('Please select camera first!');
    return null;
  };

  const updateRoiFromStorage = coords => {
    let lineData = JSON.parse(coords);
    console.log('lineData: ', lineData);
    if (
      (activeReportMenu === 'directional_line_crossing' ||
        activeReportMenu === 'directional_line_person_counting') &&
      lineData.imageResolution
    ) {
      setFilterState(prevState => {
        let newState = { ...prevState };
        newState[activeReportMenu] = {
          ...newState[activeReportMenu],
          x1Value: lineData.x1,
          y1Value: lineData.y1,
          x2Value: lineData.x2,
          y2Value: lineData.y2,
          imageResolution: lineData.imageResolution
        };
        return newState;
      });
    } else if (lineData && lineData instanceof Array && lineData.length) {
      let x1Arr = [],
        x2Arr = [],
        y1Arr = [],
        y2Arr = [];
      lineData.map(itm => {
        x1Arr.push(itm.x1);
        x2Arr.push(itm.x2);
        y1Arr.push(itm.y1);
        y2Arr.push(itm.y2);
      });
      setFilterState(prevState => {
        let newState = { ...prevState };
        newState[activeReportMenu] = {
          ...newState[activeReportMenu],
          x1Value: x1Arr.join(','),
          y1Value: y1Arr.join(','),
          x2Value: x2Arr.join(','),
          y2Value: y2Arr.join(',')
        };
        return newState;
      });
    }
    localStorage.removeItem(activeReportMenu);
    localStorage.removeItem('roi_img_url');
    return null;
  };

  window.addEventListener(
    'storage',
    event => {
      if (event.key === activeReportMenu && event.newValue) {
        return updateRoiFromStorage(event.newValue);
      }
    },
    false
  );

  const handleOnDemandHeatMapSubmit = () => {
    if (filterState && filterState[activeReportMenu]) {
      let startTime = new Date(filterState[activeReportMenu].startTime);
      let endTime = new Date(filterState[activeReportMenu].endTime);

      if (
        activeReportMenu !== 'acknowledges' &&
        activeReportMenu !== 'notification_report' &&
        activeReportMenu !== 'processed_alarm_counts_agents' &&
        !filterState[activeReportMenu].cameraId
      ) {
        MessageInfo.error('Please select camera');
        return null;
      }

      if (
        activeReportMenu === 'directional_line_crossing' &&
        (!filterState[activeReportMenu].x1Value ||
          !filterState[activeReportMenu].actionType)
      ) {
        MessageInfo.error('Please fill all fields*');
        return null;
      }

      if (
        activeReportMenu === 'processed_alarm_counts_agents' &&
        (!filterState[activeReportMenu].startTime ||
          !filterState[activeReportMenu].endTime)
      ) {
        MessageInfo.error('Please select a start and end time*');
        return null;
      }

      if (activeReportMenu === 'heatmap') {
        endTime.setHours(
          endTime.getHours() + filterState[activeReportMenu].interval
        );
      } else {
        endTime = filterState[activeReportMenu].endTime
          ? new Date(filterState[activeReportMenu].endTime)
          : null;
      }

      if (activeReportMenu === 'directional_line_person_counting') {
        let tempEndTime = moment(endTime).format('HH:mm:ss');
        endTime = new Date(
          moment(startTime).format('YYYY-MM-DD') + ' ' + tempEndTime
        );
        startTime = new Date(
          moment(startTime).format('YYYY-MM-DD') + ' 00:00:00'
        );
      }

      if (
        activeReportMenu === 'directional_line_crossing' ||
        activeReportMenu === 'directional_line_person_counting'
      ) {
        let Difference_In_Time = endTime.getTime() - startTime.getTime();
        let Difference_In_Houre = Difference_In_Time / (1000 * 3600);
        setDirLineApiCall(
          parseInt(Math.round(parseInt(Difference_In_Houre) / 8)) - 1
        );
        if (parseInt(Difference_In_Houre) > 8) {
          endTime = startTime.addHours(8);
          setDLCrossLastEndTime(endTime);
        }
      }

      setBtnLoading(true);
      endTime = endTime
        ? new Date(convertLocalToUTCDate(endTime)).getTime() / 1000
        : null;
      startTime = startTime
        ? new Date(convertLocalToUTCDate(startTime)).getTime() / 1000
        : null;
      if (activeReportMenu === 'heatmap') {
        let camId = filterState[activeReportMenu].cameraId.id;
        return createOnDemandHeatmap({
          variables: {
            cameraId: camId.toString(),
            startTime: startTime.toString(),
            endTime: endTime.toString(),
            objectType: filterState[activeReportMenu].heatmapObject
              ? filterState[activeReportMenu].heatmapObject
              : 'person',
            activeReportMenu: activeReportMenu,
            threshold: filterState[activeReportMenu].threshold
              ? filterState[activeReportMenu].threshold.toString()
              : '0.7'
          }
        });
      } else if (activeReportMenu === 'acknowledges') {
        setReportLoading(true);
        let camId = filterState[activeReportMenu].cameraId
          ? filterState[activeReportMenu].cameraId.id.toString()
          : '';
        let camName = filterState[activeReportMenu].cameraId
          ? filterState[activeReportMenu].cameraId.name
          : '';
        let aknFilters = {
          organization: data.organizationId,
          cameraId: camId,
          placeId: filterState[activeReportMenu].placeId,
          dateTime: startTime,
          endTime: endTime,
          page: 1,
          modelType: filterState[activeReportMenu].modelType
            ? filterState[activeReportMenu].modelType
            : '',
          limit: 10
        };
        setAcknowledgeFilters({ ...aknFilters, cameraName: camName });
        return getNotificationsReportList({ variables: aknFilters });
      } else if (activeReportMenu === 'processed_videos') {
        let cameraIdsArr = [];
        if (
          filterState[activeReportMenu].cameraId &&
          filterState[activeReportMenu].cameraId.length > 0
        ) {
          cameraIdsArr = filterState[activeReportMenu].cameraId.map(itm =>
            itm.id.toString()
          );
        } else {
          cameraIdsArr.push('all-cam');
        }
        let filterData = {
          organization: data.organizationId,
          cameraId: cameraIdsArr,
          startTime,
          endTime,
          urlEndPoint: '',
          activeReportMenu: activeReportMenu,
          filterType: filterState[activeReportMenu].modelType
            ? filterState[activeReportMenu].modelType
            : ''
        };
        return getAnalyticsChartData({ variables: filterData });
      } else if (activeReportMenu === 'notification_report') {
        let camId = '';
        if (filterState[activeReportMenu].cameraId) {
          camId = filterState[activeReportMenu].cameraId.id.toString();
        }
        let modelType = '';
        if (
          filterState[activeReportMenu].modelType &&
          filterState[activeReportMenu].modelType.length
        ) {
          modelType = filterState[activeReportMenu].modelType.join();
        }
        return getNotificationsReportList({
          variables: {
            organization: data.organizationId,
            cameraId: camId,
            dateTime: startTime,
            endTime: endTime,
            page: 1,
            modelType,
            status: filterState[activeReportMenu].status
              ? filterState[activeReportMenu].status
              : '',
            limit: 10
          }
        });
      } else if (activeReportMenu === 'processed_alarm_counts_agents') {
        return getAgentWiseReport({
          variables: {
            orgId: data.organizationId,
            startDate: startTime,
            endDate: endTime
          }
        });
      } else if (activeReportMenu === 'directional_line_crossing') {
        setDlineCrossData({ ...initDrLineState });
        let camId = filterState[activeReportMenu].cameraId.id;
        return getDirectionalLineCrossReport({
          variables: {
            organization: data.organizationId,
            cameraId: camId.toString(),
            dateTime: startTime,
            endTime: endTime,
            line_croods: {
              x1: filterState[activeReportMenu].x1Value,
              y1: filterState[activeReportMenu].y1Value,
              x2: filterState[activeReportMenu].x2Value,
              y2: filterState[activeReportMenu].y2Value
            },
            direction: filterState[activeReportMenu].direction,
            actionType: filterState[activeReportMenu].actionType,
            ...filterState[activeReportMenu].imageResolution
          }
        });
      } else if (activeReportMenu === 'directional_line_person_counting') {
        setDlinePersonCountData({ ...initDrLineState });
        let camId = filterState[activeReportMenu].cameraId.id;
        return getDirectionalLineCrossReport({
          variables: {
            organization: data.organizationId,
            cameraId: camId.toString(),
            dateTime: startTime,
            endTime: endTime,
            line_croods: {
              x1: filterState[activeReportMenu].x1Value,
              y1: filterState[activeReportMenu].y1Value,
              x2: filterState[activeReportMenu].x2Value,
              y2: filterState[activeReportMenu].y2Value
            },
            direction: filterState[activeReportMenu].direction,
            actionType: '',
            reportType: activeReportMenu,
            ...filterState[activeReportMenu].imageResolution
          }
        });
      }
    } else {
      MessageInfo.error('Please select camera and start time');
      return null;
    }
  };

  const handleReloadAcknowledges = () => {
    setReloadAckngLoading(true);
    let startTime = new Date(filterState[activeReportMenu].startTime);
    let endTime = new Date(filterState[activeReportMenu].endTime);
    endTime = endTime
      ? new Date(convertLocalToUTCDate(endTime)).getTime() / 1000
      : null;
    startTime = startTime
      ? new Date(convertLocalToUTCDate(startTime)).getTime() / 1000
      : null;
    let camId = filterState[activeReportMenu].cameraId
      ? filterState[activeReportMenu].cameraId.id.toString()
      : '';
    const aknFilters = {
      organization: data.organizationId,
      cameraId: camId,
      placeId: filterState[activeReportMenu].placeId,
      dateTime: startTime,
      endTime: endTime,
      page: 1,
      modelType: filterState[activeReportMenu].modelType
        ? filterState[activeReportMenu].modelType
        : '',
      limit: 10
    };
    return getNotificationsReportList({ variables: aknFilters });
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleToGetCanvasToImg = () => {
    if (graphDataState[activeReportMenu]) {
      if (
        graphDataState[activeReportMenu].lineData &&
        graphDataState[activeReportMenu].lineData.length > 0 &&
        graphDataState[activeReportMenu].lineData[0].data &&
        graphDataState[activeReportMenu].lineData[0].data.length
      ) {
        let lineHeadng = {
          date: 'Time',
          count: 'Count'
        };
        let lineDataList = graphDataState[
          activeReportMenu
        ].lineData[0].data.map((itm, key) => {
          let time = '-';
          if (
            graphDataState[activeReportMenu].dataKeyXAxis &&
            graphDataState[activeReportMenu].dataKeyXAxis.length &&
            graphDataState[activeReportMenu].dataKeyXAxis[key]
          ) {
            time = graphDataState[activeReportMenu].dataKeyXAxis[key];
          }
          return {
            date: time,
            count: itm
          };
        });
        const filename = `${activeReportMenu}-line-chart-${new Date().getTime()}`;
        const newList = [lineHeadng].concat(lineDataList);
        JSONToCSVConvertor(newList, filename);
      }
      if (
        graphDataState[activeReportMenu].barChartData &&
        graphDataState[activeReportMenu].barChartData.length > 0
      ) {
        let barHeadng = {
          date: 'Time',
          count: 'Count'
        };
        const filename = `${activeReportMenu}-bar-chart-${new Date().getTime()}`;
        const newList = [barHeadng].concat(
          graphDataState[activeReportMenu].barChartData
        );
        JSONToCSVConvertor(newList, filename);
      }
      if (
        graphDataState[activeReportMenu].pieData &&
        graphDataState[activeReportMenu].pieData.length > 0
      ) {
        let pieHeadng = {
          count: 'Count',
          name: 'Name'
        };
        const filename = `${activeReportMenu}-pie-chart-${new Date().getTime()}`;
        const newList = [pieHeadng].concat(
          graphDataState[activeReportMenu].pieData
        );
        JSONToCSVConvertor(newList, filename);
      }
      if (
        activeReportMenu === 'license_plates' &&
        graphDataState[activeReportMenu].newPlates &&
        graphDataState[activeReportMenu].newPlates.length
      ) {
        let tblHeadng = {
          ts: 'Time',
          PLATE_NUMBER: 'Plate Number',
          plate_count: 'Count'
        };
        const filename = `${activeReportMenu}-new-plates-${new Date().getTime()}`;
        const newList = [tblHeadng].concat(
          graphDataState[activeReportMenu].newPlates
        );
        JSONToCSVConvertor(newList, filename);
      }
    }
  };

  const handleExportSackReport = () => {
    if (
      graphDataState[activeReportMenu] &&
      graphDataState[activeReportMenu].sackExcelReportData
    ) {
      let fields = {
        count: 'Count',
        date: 'Time'
      };
      const filename = `sack-report-${new Date().getTime()}`;
      const newList = [fields].concat(
        graphDataState[activeReportMenu].sackExcelReportData
      );
      return JSONToCSVConvertor(newList, filename);
    }
    return true;
  };

  const onInputChange = val => {
    let organization = data.organizationId;
    setActiveReportCameraList({
      ...activeReportCameraList,
      [activeReportMenu]: []
    });
    getCameraList({
      variables: { organization, page: 1, limit: 20, search: val }
    });
  };

  const handleAccordionChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDownloadNotificationExcel = row => {
    if (row) {
      setDownloadCsvLoading({ [row.id]: true });
      return getProceedNotificationExcelReport({
        variables: {
          dateTime: row.startTime,
          endTime: row.endTime,
          organization: data.organizationId,
          status: 'agree',
          limit: row.agreedCount
        }
      });
    }
  };

  const [getProceedNotificationExcelReport] = useLazyQuery(
    GET_NOTIFICATIONS_EXCEL_REPORT,
    {
      fetchPolicy: 'no-cache',
      onError: e => {
        setDownloadCsvLoading({});
      },
      onCompleted: res => {
        setDownloadCsvLoading({});
        if (res.getProceedNotificationExcelReport) {
          let fields = {
            cameraName: 'Camera Name',
            eventTime: 'Event Date & Time',
            driverCell: 'Driver Cell Number',
            proceedTime: 'Time Of Submit',
            proceedBy: 'Username',
            timeOfCall: 'Time Of Call'
          };
          const list = res.getProceedNotificationExcelReport.map(item => {
            let tmpObj = {
              cameraName: item.cameraName ? item.cameraName : '-',
              eventTime: item.eventTime
                ? dateFormatterLLL(new Date(item.eventTime))
                : '-',
              driverCell: '-',
              proceedTime: item.proceedTime
                ? dateFormatterLLL(new Date(item.proceedTime))
                : '-',
              proceedBy: item.proceedBy ? item.proceedBy : '-',
              timeOfCall: '-'
            };
            if (item.processSteps && item.processSteps.length) {
              if (item.processSteps[0].processTime) {
                tmpObj.timeOfCall =
                  getDifferenceInMinutes(
                    new Date(item.processSteps[0].processTime),
                    new Date(item.proceedTime)
                  ) + ' Minutes';
              }
              item.processSteps.forEach((value, i) => {
                fields[`workflowOption${i}`] = `Step ${i + 1} Output`;
                fields[`comments${i}`] = `Step ${i + 1} Comment`;
                tmpObj[`workflowOption${i}`] = value.workflowOption
                  ? value.workflowOption
                  : '-';
                tmpObj[`comments${i}`] = value.comments;
              });
            }
            return tmpObj;
          });
          const filename = 'processed-report';
          const newList = [fields].concat(list);
          return JSONToCSVConvertor(newList, filename);
        }
      }
    }
  );

  const [
    getProceedNotifications,
    { loading: proceedReportLoading }
  ] = useLazyQuery(GET_PROCEED_NOTIFICATIONS_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res.getProceedNotificationReport) {
        const list = res.getProceedNotificationReport.map(item => {
          let tmpObj = { ...item };
          tmpObj.dateTime = dateFormatterLLL(new Date(tmpObj.startTime * 1000));
          tmpObj.endDate = dateFormatterLLL(new Date(tmpObj.endTime * 1000));
          return tmpObj;
        });
        setGraphDataState(prevState => {
          let newSatate = { ...prevState };
          newSatate['acknowledges'] = {
            ...newSatate['acknowledges'],
            proceedNotificationList: list
          };
          return newSatate;
        });
      }
    }
  });

  const handleNotificationPageChange = val => {
    let startTime = filterState[activeReportMenu].startTime
      ? new Date(
        convertLocalToUTCDate(filterState[activeReportMenu].startTime)
      ).getTime() / 1000
      : null;
    let endTime = filterState[activeReportMenu].endTime
      ? new Date(
        convertLocalToUTCDate(filterState[activeReportMenu].endTime)
      ).getTime() / 1000
      : null;
    let camId = '';
    if (filterState[activeReportMenu].cameraId) {
      camId = filterState[activeReportMenu].cameraId.id.toString();
    }
    setFilterState({
      ...filterState,
      [activeReportMenu]: { ...filterState[activeReportMenu], page: val }
    });
    let modelType = '';
    if (
      filterState[activeReportMenu].modelType &&
      filterState[activeReportMenu].modelType.length
    ) {
      modelType = filterState[activeReportMenu].modelType.join();
    }
    return getNotificationsReportList({
      variables: {
        organization: data.organizationId,
        cameraId: camId,
        dateTime: startTime,
        endTime: endTime,
        page: val,
        modelType,
        status: filterState[activeReportMenu].status
          ? filterState[activeReportMenu].status
          : '',
        limit: 10
      }
    });
  };

  const [
    getNotificationCountPerDay,
    { loading: exportNotificationLoading }
  ] = useLazyQuery(GET_NOTIFICATIONS_COUNTS_PER_DAY, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (
        res.getNotificationCountPerDay &&
        res.getNotificationCountPerDay.length
      ) {
        let fields = {
          'sno.': 'S.No.',
          date: 'Date',
          total_count: 'Total Count',
          agreeCount: 'Agree Count',
          disagreeCount: 'Disagree Count',
          acknowledgedCount: 'Acknowledged Count'
        };
        const list = res.getNotificationCountPerDay.map((itm, idx) => {
          return {
            sno: idx + 1,
            date: dateFormatterDDMMMYYYY(new Date(itm.date)),
            total_count: itm.total_count,
            agreeCount: itm.agreeCount,
            disagreeCount: itm.disagreeCount,
            acknowledgedCount: itm.acknowledgedCount
          };
        });
        const filename = `notification-report-${new Date().getTime()}`;
        const newList = [fields].concat(list);
        return JSONToCSVConvertor(newList, filename);
      }
      MessageInfo.error('Record not found!');
    }
  });

  const handleExportNotificationReport = () => {
    let startTime = filterState[activeReportMenu].startTime
      ? new Date(
        convertLocalToUTCDate(filterState[activeReportMenu].startTime)
      ).getTime() / 1000
      : null;
    let endTime = filterState[activeReportMenu].endTime
      ? new Date(
        convertLocalToUTCDate(filterState[activeReportMenu].endTime)
      ).getTime() / 1000
      : null;
    let camId = '';
    if (filterState[activeReportMenu].cameraId) {
      camId = filterState[activeReportMenu].cameraId.id.toString();
    }
    let modelType = '';
    if (
      filterState[activeReportMenu].modelType &&
      filterState[activeReportMenu].modelType.length
    ) {
      modelType = filterState[activeReportMenu].modelType.join();
    }
    return getNotificationCountPerDay({
      variables: {
        organization: data.organizationId,
        cameraId: camId,
        dateTime: startTime,
        endTime: endTime,
        modelType,
        status: filterState[activeReportMenu].status
          ? filterState[activeReportMenu].status
          : ''
      }
    });
  };

  const hadnleDowloader = async (data, name) => {
    await FileSaver.saveAs(data.presigned_url, `${name}.csv`);
  };

  const [
    getProcessedAlarmCountReport,
    { loading: processedAlarmCountReportLoading }
  ] = useLazyQuery(GET_PROCESSED_ALARM_COUNTS_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const alldata = get(data, 'getProcessedAlarmCountReport', '');
      hadnleDowloader(alldata, "Agent wise Report");
      Message.success(data && data.getProcessedAlarmCountReport.message);
    },
    onError: error => {
      Message.error(error);
    }
  });

  const handleExportProcessedAlarmsCountsReport = () => {
    let startTime = new Date(filterState[activeReportMenu].startTime);
    let endTime = new Date(filterState[activeReportMenu].endTime);
    endTime = endTime
      ? new Date(convertLocalToUTCDate(endTime)).getTime() / 1000
      : null;
    startTime = startTime
      ? new Date(convertLocalToUTCDate(startTime)).getTime() / 1000
      : null;
    if (
      activeReportMenu === 'processed_alarm_counts_agents' &&
      (!filterState[activeReportMenu].startTime ||
        !filterState[activeReportMenu].endTime)
    ) {
      MessageInfo.error('Please select a start and end time*');
      return null;
    }

    return getProcessedAlarmCountReport({
      variables: {
        orgId: data.organizationId,
        startDate: startTime,
        endDate: endTime
      }
    });
  };

  return (
    <div className={classes.root}>
      {mlTemplateList &&
        mlTemplateList.length > 0 &&
        cameraList &&
        cameraList.length > 0 &&
        !loading ? (
        <Grid container>
          <Grid item xs={2}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.navRoot}>
              {reportList.length &&
                reportList.map((item, key) => {
                  if (item.key === 'tracker') {
                    return (
                      <React.Fragment key={key}>
                        <ListItem
                          button
                          onClick={evt => handleOpenNavClick(item.key)}>
                          <ListItemText
                            primary={item.name}
                            style={{ fontWeight: 'bold' }}
                          />
                          {item.reports.length ? (
                            openNav[item.key] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                        {item.reports.length > 0 && (
                          <Collapse
                            in={openNav[item.key] ? true : false}
                            timeout="auto"
                            unmountOnExit>
                            <List component="div" disablePadding>
                              {item.reports.map(itm => (
                                <ListItem
                                  key={itm.key}
                                  onClick={evt => handleSubNavItemClick(itm)}
                                  selected={
                                    activeMenuItem[itm.key]
                                      ? activeMenuItem[itm.key]
                                      : false
                                  }
                                  button
                                  className={classes.nested}>
                                  <ListItemText primary={itm.name} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    );
                  } else if (
                    item.templateName &&
                    listedMLTemplates.includes(item.templateName)
                  ) {
                    return (
                      <React.Fragment key={key}>
                        <ListItem
                          button
                          onClick={evt => handleOpenNavClick(item.key)}>
                          <ListItemText
                            primary={item.name}
                            style={{ fontWeight: 'bold' }}
                          />
                          {item.reports.length ? (
                            openNav[item.key] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                        {item.reports.length > 0 && (
                          <Collapse
                            in={openNav[item.key] ? true : false}
                            timeout="auto"
                            unmountOnExit>
                            <List component="div" disablePadding>
                              {item.reports.map(itm => (
                                <ListItem
                                  key={itm.key}
                                  onClick={evt =>
                                    handleSubNavItemClick(itm, item)
                                  }
                                  selected={
                                    activeMenuItem[itm.key]
                                      ? activeMenuItem[itm.key]
                                      : false
                                  }
                                  button
                                  className={classes.nested}>
                                  <ListItemText primary={itm.name} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: [
                      'cameraId',
                      'startTime',
                      'interval',
                      'heatmapObject',
                      'threshold'
                    ]
                  });
                  setActiveMenuItem({ heatmap: true });
                  setActiveReportMenu('heatmap');
                  setFilterState({ ...filterState, heatmap: initFilters });
                  setSelectCameraList(cameraList);
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                }}
                button
                selected={activeReportMenu === 'heatmap' ? true : false}>
                <ListItemText primary={'Heatmap'} />
              </ListItem>
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: [
                      'cameraId',
                      'startTime',
                      'endTime',
                      'placeId',
                      'modelType'
                    ]
                  });
                  setActiveMenuItem({ acknowledges: true });
                  setActiveReportMenu('acknowledges');
                  setFilterState({
                    ...filterState,
                    acknowledges: {
                      placeId: '',
                      cameraId: '',
                      modelType: '',
                      dateTime: null,
                      endTime: null,
                      page: 1
                    }
                  });
                  setSelectCameraList(cameraList);
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                  if (EDGE_AI_ORGS.CTRACK === data.organizationId) {
                    getProceedNotifications({
                      variables: {
                        organization: data.organizationId
                      }
                    });
                  }
                }}
                button
                selected={activeReportMenu === 'acknowledges' ? true : false}>
                <ListItemText primary={'Acknowledges'} />
              </ListItem>
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: ['cameraId', 'startTime', 'endTime', 'modelType']
                  });
                  setActiveMenuItem({ processed_videos: true });
                  setActiveReportMenu('processed_videos');
                  setSelectCameraList(cameraList);
                  setFilterState({
                    ...filterState,
                    processed_videos: {
                      cameraId: [],
                      modelType: '',
                      startTime: null,
                      endTime: null
                    }
                  });
                  setGraphDataState(prevState => {
                    let newSatate = { ...prevState };
                    if (typeof newSatate['processed_videos'] == 'undefined') {
                      newSatate['processed_videos'] = {
                        dataKeyXAxis: [],
                        stackKeyNames: [],
                        lineData: [],
                        pieData: [],
                        totalVideoAlertCounts: 0,
                        totalVideoCounts: 0
                      };
                    }
                    return newSatate;
                  });
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                }}
                button
                selected={
                  activeReportMenu === 'processed_videos' ? true : false
                }>
                <ListItemText primary={'Processed Videos'} />
              </ListItem>
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: [
                      'cameraId',
                      'startTime',
                      'endTime',
                      'modelType',
                      'status'
                    ]
                  });
                  setActiveMenuItem({ notification_report: true });
                  setActiveReportMenu('notification_report');
                  setSelectCameraList(cameraList);
                  setFilterState({
                    ...filterState,
                    notification_report: {
                      cameraId: null,
                      modelType: [],
                      startTime: null,
                      endTime: null,
                      status: '',
                      page: 1
                    }
                  });
                  setGraphDataState(prevState => {
                    let newSatate = { ...prevState };
                    if (
                      typeof newSatate['notification_report'] === 'undefined'
                    ) {
                      newSatate['notification_report'] = {
                        dataKeyXAxis: [],
                        stackKeyNames: [],
                        lineData: [],
                        pieData: [],
                        count: 0,
                        notifications: []
                      };
                    }
                    return newSatate;
                  });
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                }}
                button
                selected={
                  activeReportMenu === 'notification_report' ? true : false
                }>
                <ListItemText primary={'Notification report'} />
              </ListItem>
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: [
                      'cameraId',
                      'startTime',
                      'endTime',
                      'line_cross_area',
                      'angle_type'
                    ]
                  });
                  setActiveMenuItem({ directional_line_crossing: true });
                  setActiveReportMenu('directional_line_crossing');
                  setFilterState({
                    ...filterState,
                    directional_line_crossing: {
                      cameraId: '',
                      direction: '',
                      actionType: '',
                      dateTime: null,
                      endTime: null,
                      x1Value: 0,
                      x2Value: 0,
                      y1Value: 0,
                      y2Value: 0,
                      imageResolution: null
                    }
                  });
                  setSelectCameraList(cameraList);
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                  setGraphDataState(prevState => {
                    let newSatate = { ...prevState };
                    if (
                      typeof newSatate['directional_line_crossing'] ==
                      'undefined'
                    ) {
                      newSatate['directional_line_crossing'] = {
                        dataKeyXAxis: [],
                        stackKeyNames: [],
                        lineData: [],
                        pieData: [],
                        directionalLineData: null
                      };
                    }
                    return newSatate;
                  });
                }}
                button
                selected={
                  activeReportMenu === 'directional_line_crossing'
                    ? true
                    : false
                }>
                <ListItemText primary={'Directional line crossing'} />
              </ListItem>
              <ListItem
                onClick={evt => {
                  setActiveMenuData({
                    filters: [
                      'cameraId',
                      'startTime',
                      'endTime',
                      'line_cross_area',
                      'angle_type'
                    ]
                  });
                  setActiveMenuItem({ directional_line_person_counting: true });
                  setActiveReportMenu('directional_line_person_counting');
                  setFilterState({
                    ...filterState,
                    directional_line_person_counting: {
                      cameraId: '',
                      direction: '',
                      actionType: '',
                      dateTime: null,
                      endTime: null,
                      x1Value: 0,
                      x2Value: 0,
                      y1Value: 0,
                      y2Value: 0,
                      imageResolution: null
                    }
                  });
                  setSelectCameraList(cameraList);
                  getCameraList({
                    variables: {
                      organization: data.organizationId,
                      page: 1,
                      limit: 20,
                      search: ''
                    }
                  });
                  setGraphDataState(prevState => {
                    let newSatate = { ...prevState };
                    if (
                      typeof newSatate['directional_line_person_counting'] ==
                      'undefined'
                    ) {
                      newSatate['directional_line_person_counting'] = {
                        dataKeyXAxis: [],
                        stackKeyNames: [],
                        lineData: [],
                        pieData: [],
                        directionalLineData: null
                      };
                    }
                    return newSatate;
                  });
                }}
                button
                selected={
                  activeReportMenu === 'directional_line_person_counting'
                    ? true
                    : false
                }>
                <ListItemText primary={'Person inside Office Count'} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setActiveMenuData({
                    filters: [
                      'startTime',
                      'endTime',
                    ]
                  });
                  setActiveMenuItem({ processed_alarm_counts_agents: true });
                  setActiveReportMenu('processed_alarm_counts_agents');
                  setFilterState({
                    ...filterState,
                    processed_alarm_counts_agents: {
                      startTime: null,
                      endTime: null,
                    }
                  });
                  setGraphDataState(prevState => {
                    let newSatate = { ...prevState };
                    newSatate['processed_alarm_counts_agents'] = {
                      dataKeyXAxis: [],
                      stackKeyNames: [],
                      lineData: [],
                      pieData: [],
                      pieChartDataLoaded: false,
                      directionalLineData: null
                    };
                    return newSatate;
                  });
                }}
                selected={
                  activeReportMenu === 'processed_alarm_counts_agents'
                    ? true
                    : false
                }>
                <ListItemText primary={'Processed Alarm Counts (Agents)'} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={10}>
            <Grid style={{ paddingLeft: 25, ...marginGenerator('mb-24') }}>
              {activeReportMenu !== 'directional_line_person_counting' &&
                activeReportMenu !== 'directional_line_crossing' &&
                activeReportMenu !== 'heatmap' &&
                activeReportMenu !== 'acknowledges' &&
                activeReportMenu !== 'processed_videos' &&
                activeReportMenu !== 'notification_report' &&
                activeReportMenu !== 'processed_alarm_counts_agents' ? (
                <React.Fragment>
                  {filterState && filterState[activeReportMenu] && (
                    <>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          ...marginGenerator('mb-15'),
                          ...marginGenerator('mt-0')
                        }}>
                        <Grid item xs={4}>
                          <Autocomplete
                            styleOverrides={{ minWidth: '100%' }}
                            handleChange={(evt, newVal) => {
                              handleSelectCamera(newVal);
                            }}
                            val={filterState[activeReportMenu].cameraId}
                            options={
                              activeReportCameraList &&
                                activeReportCameraList[activeReportMenu]
                                ? activeReportCameraList[activeReportMenu]
                                : []
                            }
                            label={t('camera.label')}
                            size="small"
                            handleClose={evt => {
                              setActiveReportCameraList({
                                ...activeReportCameraList,
                                [activeReportMenu]: []
                              });
                              getCameraList({
                                variables: {
                                  organization: data.organizationId,
                                  page: 1,
                                  limit: 20,
                                  search: ''
                                }
                              });
                            }}
                            onInputChange={onInputChange}
                            multiple={
                              activeMenuData.filters.includes('lines') ||
                                activeReportMenu === 'open_close_timer'
                                ? false
                                : true
                            }
                          />
                        </Grid>
                        {activeMenuData &&
                          activeMenuData.filters.includes('lines') && (
                            <Grid item xs={3}>
                              <OutlinedSelect
                                val={filterState[activeReportMenu].lines}
                                label={t('lines.label')}
                                handleChange={handleChangeLineFilter}
                                styleOverrides={{
                                  minWidth: '100%',
                                  maxWidth: '100%'
                                }}
                                options={selectLines ? selectLines : []}
                                disabled={
                                  !filterState[activeReportMenu].cameraId
                                }
                                multiple={true}
                              />
                            </Grid>
                          )}
                        <Grid item xs={3}>
                          <DateTimeSelector
                            inputStyle={{ padding: 10, minWidth: 242 }}
                            label={
                              activeReportMenu === 'attendance_report' ||
                                activeReportMenu === 'open_close_timer'
                                ? 'YYYY-MM-dd'
                                : 'YYYY-MM-dd HH:mm'
                            }
                            dateFormat={
                              activeReportMenu === 'attendance_report' ||
                                activeReportMenu === 'open_close_timer'
                                ? 'yyyy-MM-dd'
                                : 'yyyy-MM-dd HH:mm'
                            }
                            value={filterState[activeReportMenu].startTime}
                            handleChange={newDate => {
                              setFilterState(prevState => {
                                let newState = { ...prevState };
                                newState[activeReportMenu] = {
                                  ...newState[activeReportMenu],
                                  startTime: newDate
                                };
                                return newState;
                              });
                            }}
                            disabled={
                              activeReportMenu === 'attendance_report'
                                ? false
                                : disableState
                            }
                            disableFuture={true}
                          />
                        </Grid>
                        {activeMenuData &&
                          activeMenuData.filters.includes('endTime') && (
                            <Grid item xs={3}>
                              <DateTimeSelector
                                inputStyle={{ padding: 10, minWidth: 242 }}
                                label={
                                  activeReportMenu === 'attendance_report' ||
                                    activeReportMenu === 'open_close_timer'
                                    ? 'YYYY-MM-dd'
                                    : 'YYYY-MM-dd HH:mm'
                                }
                                dateFormat={
                                  activeReportMenu === 'attendance_report' ||
                                    activeReportMenu === 'open_close_timer'
                                    ? 'yyyy-MM-dd'
                                    : 'yyyy-MM-dd HH:mm'
                                }
                                value={filterState[activeReportMenu].endTime}
                                handleChange={newDate => {
                                  setFilterState(prevState => {
                                    let newState = { ...prevState };
                                    newState[activeReportMenu] = {
                                      ...newState[activeReportMenu],
                                      endTime: newDate
                                    };
                                    return newState;
                                  });
                                }}
                                disableFuture={true}
                                disabled={
                                  activeReportMenu === 'attendance_report'
                                    ? false
                                    : disableState
                                }
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('officeTime') && (
                            <Grid item xs={3}>
                              <DateTimeSelector
                                inputStyle={{ padding: 10, minWidth: 242 }}
                                label={'HH:MM'}
                                dateFormat={'HH:mm'}
                                value={filterState[activeReportMenu].officeTime}
                                handleChange={newDate => {
                                  setFilterState(prevState => {
                                    let newState = { ...prevState };
                                    newState[activeReportMenu] = {
                                      ...newState[activeReportMenu],
                                      officeTime: newDate
                                    };
                                    return newState;
                                  });
                                }}
                                disableFuture={true}
                                disabled={
                                  activeReportMenu === 'attendance_report'
                                    ? false
                                    : disableState
                                }
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes(
                            'thresholdDuration'
                          ) && (
                            <Grid item xs={3}>
                              <OutlinedSelect
                                val={
                                  filterState[activeReportMenu]
                                    .thresholdDuration
                                    ? filterState[activeReportMenu]
                                      .thresholdDuration
                                    : ''
                                }
                                label={t('thresholdDuration.label')}
                                handleChange={evt =>
                                  handleFilterChange(evt, 'thresholdDuration')
                                }
                                styleOverrides={{ minWidth: '100%' }}
                                disabled={
                                  activeReportMenu === 'attendance_report'
                                    ? false
                                    : disableState
                                }
                                options={[
                                  { id: '1', name: '1hr' },
                                  { id: '2', name: '2hr' },
                                  { id: '3', name: '3hr' },
                                  { id: '4', name: '4hr' }
                                ]}
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('interval') && (
                            <Grid item xs={2}>
                              <OutlinedSelect
                                val={
                                  filterState[activeReportMenu].interval
                                    ? filterState[activeReportMenu].interval
                                    : ''
                                }
                                label={t('timeInterval.label')}
                                handleChange={evt =>
                                  handleFilterChange(evt, 'interval')
                                }
                                styleOverrides={{ minWidth: '100%' }}
                                disabled={disableState}
                                options={intervalList}
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('objectType') && (
                            <Grid item xs={2}>
                              <OutlinedSelect
                                val={
                                  activeReportMenu === 'dwell_time'
                                    ? objAnalyticsActiveObj
                                    : activeReportMenu === 'dwell_time_analysis'
                                      ? dwellTimeActiveObj
                                      : activeReportMenu === 'rate_of_change'
                                        ? rocActiveObj
                                        : filterState[activeReportMenu].objectType
                                }
                                label={t('object.label')}
                                handleChange={evt =>
                                  handleFilterChange(evt, 'objectType')
                                }
                                styleOverrides={{
                                  minWidth: '100%',
                                  maxWidth: '100%'
                                }}
                                options={
                                  activeReportMenu === 'dwell_time' ||
                                    activeReportMenu === 'dwell_time_analysis' ||
                                    activeReportMenu === 'rate_of_change'
                                    ? ACD_OBJECTS_LIST
                                    : detectedObjects
                                      ? detectedObjects[activeReportMenu]
                                      : []
                                }
                                multiple={
                                  activeReportMenu === 'dwell_time_analysis' ||
                                    activeReportMenu === 'rate_of_change' ||
                                    activeReportMenu === 'dwell_time'
                                    ? false
                                    : true
                                }
                                disabled={disableState}
                              />
                            </Grid>
                          )}
                        {(activeReportMenu &&
                          activeReportMenu === 'dwell_time') ||
                          activeReportMenu === 'dwell_time_analysis' ||
                          activeReportMenu === 'rate_of_change' ? (
                          <React.Fragment>
                            {activeMenuData &&
                              activeMenuData.filters.includes('X1') && (
                                <>
                                  <Grid item xs={3}>
                                    <OutlinedSelect
                                      val={filterState[activeReportMenu].x1}
                                      label={'x1'}
                                      handleChange={evt =>
                                        handleFilterChange(evt, 'x1')
                                      }
                                      styleOverrides={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      options={trackingOtherFilterOpt}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Input
                                      value={
                                        filterState[activeReportMenu].x1Value
                                      }
                                      type={'text'}
                                      disabled={
                                        !filterState[activeReportMenu].x1
                                      }
                                      placeholder={'Enter x1 value'}
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      color={'secondary'}
                                      style={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      className={classes.input}
                                      name="x1Value"
                                      onChange={evt => handleFilterChange(evt)}
                                    />
                                  </Grid>
                                </>
                              )}
                            {activeMenuData &&
                              activeMenuData.filters.includes('Y1') && (
                                <>
                                  <Grid item xs={3}>
                                    <OutlinedSelect
                                      val={filterState[activeReportMenu].y1}
                                      label={'y1'}
                                      handleChange={evt =>
                                        handleFilterChange(evt, 'y1')
                                      }
                                      styleOverrides={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      options={trackingOtherFilterOpt}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Input
                                      value={
                                        filterState[activeReportMenu].y1Value
                                      }
                                      type={'text'}
                                      disabled={
                                        !filterState[activeReportMenu].y1
                                      }
                                      placeholder={'Enter y1 value'}
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      color={'secondary'}
                                      style={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      className={classes.input}
                                      name="y1Value"
                                      onChange={evt => handleFilterChange(evt)}
                                    />
                                  </Grid>
                                </>
                              )}
                            {activeMenuData &&
                              activeMenuData.filters.includes('X2') && (
                                <>
                                  <Grid item xs={3}>
                                    <OutlinedSelect
                                      val={filterState[activeReportMenu].x2}
                                      label={'x2'}
                                      handleChange={evt =>
                                        handleFilterChange(evt, 'x2')
                                      }
                                      styleOverrides={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      options={trackingOtherFilterOpt}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Input
                                      value={
                                        filterState[activeReportMenu].x2Value
                                      }
                                      type={'text'}
                                      disabled={
                                        !filterState[activeReportMenu].x2
                                      }
                                      placeholder={'Enter x2 value'}
                                      color={'secondary'}
                                      style={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      className={classes.input}
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      name="x2Value"
                                      onChange={evt => handleFilterChange(evt)}
                                    />
                                  </Grid>
                                </>
                              )}
                            {activeMenuData &&
                              activeMenuData.filters.includes('Y2') && (
                                <>
                                  <Grid item xs={3}>
                                    <OutlinedSelect
                                      val={filterState[activeReportMenu].y2}
                                      label={'y2'}
                                      handleChange={evt =>
                                        handleFilterChange(evt, 'y2')
                                      }
                                      styleOverrides={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      options={trackingOtherFilterOpt}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Input
                                      value={
                                        filterState[activeReportMenu].y2Value
                                      }
                                      type={'text'}
                                      disabled={
                                        !filterState[activeReportMenu].y2
                                      }
                                      placeholder={'Enter y2 value'}
                                      color={'secondary'}
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      style={{
                                        minWidth: '100%',
                                        maxWidth: '100%'
                                      }}
                                      className={classes.input}
                                      name="y2Value"
                                      onChange={evt => handleFilterChange(evt)}
                                    />
                                  </Grid>
                                </>
                              )}
                          </React.Fragment>
                        ) : null}
                        {activeMenuData &&
                          activeMenuData.filters.includes('waitTime') && (
                            <React.Fragment>
                              <Grid item xs={3}>
                                <OutlinedSelect
                                  val={
                                    filterState[activeReportMenu].waitTimeCond
                                  }
                                  label={t('wait_time.label')}
                                  handleChange={evt =>
                                    handleFilterChange(evt, 'waitTimeCond')
                                  }
                                  styleOverrides={{
                                    minWidth: '100%',
                                    maxWidth: '100%'
                                  }}
                                  options={trackingOtherFilterOpt}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Input
                                  value={filterState[activeReportMenu].waitTime}
                                  type={'text'}
                                  id="outlined-basic"
                                  size="small"
                                  variant="outlined"
                                  color={'secondary'}
                                  style={{ minWidth: '100%', maxWidth: '100%' }}
                                  className={classes.input}
                                  name="waitTime"
                                  placeholder={t('wait_time.label')}
                                  disabled={
                                    disableState ||
                                    !filterState[activeReportMenu].waitTimeCond
                                  }
                                  onChange={evt => handleFilterChange(evt)}
                                />
                              </Grid>
                            </React.Fragment>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('list_type') && (
                            <Grid item xs={2}>
                              <OutlinedSelect
                                val={filterState[activeReportMenu].list_type}
                                label={t('listType.label')}
                                handleChange={evt =>
                                  handleFilterChange(evt, 'list_type')
                                }
                                styleOverrides={{ minWidth: '100%' }}
                                disabled={disableState}
                                options={AI_MODEL_LIST_TYPES}
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('plate_number') && (
                            <Grid item xs={2}>
                              <Input
                                value={
                                  filterState[activeReportMenu].plate_number
                                }
                                type={'text'}
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                color={'secondary'}
                                style={{ minWidth: '100%', maxWidth: '100%' }}
                                className={classes.input}
                                name="plate_number"
                                placeholder={t('plateNumber.label')}
                                disabled={disableState}
                                onChange={evt => handleFilterChange(evt)}
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters.includes('face_name') && (
                            <Grid item xs={3}>
                              <Input
                                value={filterState[activeReportMenu].face_name}
                                type={'text'}
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                color={'secondary'}
                                style={{ minWidth: '100%', maxWidth: '100%' }}
                                className={classes.input}
                                name="face_name"
                                placeholder={t('faceName.label')}
                                disabled={disableState}
                                onChange={evt => handleFilterChange(evt)}
                              />
                            </Grid>
                          )}
                        {activeMenuData &&
                          activeMenuData.filters &&
                          activeMenuData.filters.includes('modelType') && (
                            <Grid item xs={3}>
                              <OutlinedSelect
                                val={filterState[activeReportMenu].modelType}
                                label={t('models.label')}
                                handleChange={evt => {
                                  handleFilterChange(evt, 'modelType');
                                }}
                                disabled={disableState}
                                styleOverrides={{
                                  minWidth: '100%',
                                  maxWidth: '100%'
                                }}
                                options={
                                  aiTypes && aiTypes.length > 0 ? aiTypes : []
                                }
                              />
                            </Grid>
                          )}
                        <Grid item xs={6}>
                          <ButtonComponent
                            disabled={
                              activeReportMenu === 'attendance_report'
                                ? false
                                : disableState ||
                                !filterState[activeReportMenu].startTime
                            }
                            t={t}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={handleFilterSubmit}
                            loading={btnLoading}
                            text={t('submit.label')}
                            styling={0}
                          />
                          {((activeReportMenu &&
                            activeReportMenu === 'dwell_time') ||
                            activeReportMenu === 'dwell_time_analysis' ||
                            activeReportMenu === 'rate_of_change') && (
                              <Button
                                t={t}
                                disabled={
                                  disableState ||
                                  !filterState[activeReportMenu].startTime ||
                                  roiEventLoading[activeReportMenu]
                                }
                                variant="outlined"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                className={classes.searcBtn}
                                onClick={handleGetRoi}>
                                {roiEventLoading[activeReportMenu] ? (
                                  <CircularProgress size={14} />
                                ) : (
                                  t('getRoi.label')
                                )}
                              </Button>
                            )}
                          {activeReportMenu === 'sack_counting' ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={
                                graphDataState[activeReportMenu] &&
                                  graphDataState[activeReportMenu]
                                    .sackExcelReportData
                                  ? false
                                  : true
                              }
                              onClick={handleExportSackReport}
                              style={{ marginLeft: 10 }}>
                              Export Excel
                            </Button>
                          ) : (
                            <ButtonComponent
                              t={t}
                              disabled={
                                loading ||
                                disableState ||
                                !filterState[activeReportMenu].startTime
                              }
                              variant="outlined"
                              color="primary"
                              onClick={handleToGetCanvasToImg}
                              loading={pdfBtnLoadding}
                              text={'Export Excel'}
                              styling={10}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {activeReportMenu === 'sack_counting' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].totalCount ? (
                    <Grid
                      container
                      spacing={1}
                      style={{ ...marginGenerator('mt-24') }}>
                      <Statcard
                        statLabel="Cumulative Count"
                        statValue={
                          graphDataState[activeReportMenu].totalCount
                            ? graphDataState[activeReportMenu].totalCount
                            : 0
                        }
                        customStyles={{ width: 280, padding: '8px 16px' }}
                      />
                    </Grid>
                  ) : null}
                  {activeReportMenu === 'license_plates' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].totalCount ? (
                    <Grid
                      container
                      spacing={1}
                      style={{ ...marginGenerator('mt-24') }}>
                      <Statcard
                        statLabel="Total Plates Count"
                        statValue={
                          graphDataState[activeReportMenu].totalCount
                            ? graphDataState[activeReportMenu].totalCount
                            : 0
                        }
                        customStyles={{ width: 280, padding: '8px 16px' }}
                      />
                    </Grid>
                  ) : null}

                  {activeReportMenu === 'dwell_time_analysis' &&
                    dwellTimeActiveObj && (
                      <Typography
                        style={{ textAlign: 'center' }}
                        color="primary"
                        variant="subtitle1"
                        gutterBottom>
                        {`${t(
                          'dwell_time.label'
                        )} - ${dwellTimeActiveObj} ( minutes )`}
                      </Typography>
                    )}
                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].lineData &&
                    graphDataState[activeReportMenu].lineData.length > 0 ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        id="pdf-content"
                        style={
                          activeReportMenu !== 'dwell_time_analysis'
                            ? { ...marginGenerator('mt-24') }
                            : null
                        }>
                        <LineChartWrapper
                          data={
                            graphDataState[activeReportMenu].lineData
                              ? graphDataState[activeReportMenu].lineData
                              : []
                          }
                          dataKeyXAxis={
                            graphDataState[activeReportMenu].dataKeyXAxis
                              ? graphDataState[activeReportMenu].dataKeyXAxis
                              : []
                          }
                          stackKeyNames={
                            graphDataState[activeReportMenu].stackKeyNames
                              ? graphDataState[activeReportMenu].stackKeyNames
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].barChartData &&
                    graphDataState[activeReportMenu].barChartData.length > 0 ? (
                    <Grid container spacing={1} id="pdf-content">
                      <Grid
                        item
                        xs={activeReportMenu === 'attendance_report' ? 6 : 12}>
                        {graphDataState[activeReportMenu].barChartHeading && (
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{
                              ...marginGenerator('mb-15'),
                              ...marginGenerator('mt-24')
                            }}>
                            {graphDataState[activeReportMenu].barChartHeading}
                          </Typography>
                        )}
                        <BarChartWrapper
                          data={graphDataState[activeReportMenu].barChartData}
                          dataKeyChart={'count'}
                          dataKeyXAxis={'date'}
                          loading={false}
                        />
                      </Grid>
                      {activeReportMenu === 'attendance_report' && (
                        <Grid item xs={6}>
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{
                              ...marginGenerator('mb-15'),
                              ...marginGenerator('mt-24')
                            }}>
                            {t('earlyComersCountPerDay.label')}
                          </Typography>
                          <BarChartWrapper
                            data={
                              graphDataState[activeReportMenu]
                                .earlyComerBarChartData
                                ? graphDataState[activeReportMenu]
                                  .earlyComerBarChartData
                                : []
                            }
                            dataKeyChart={'count'}
                            dataKeyXAxis={'date'}
                            loading={false}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : null}

                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].pieData.length > 0 ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={12}
                        style={{ ...marginGenerator('mt-24') }}>
                        {graphDataState[activeReportMenu].pieChartHeading && (
                          <Typography variant="h6" color="primary">
                            {graphDataState[activeReportMenu].pieChartHeading}
                          </Typography>
                        )}
                        <PieChartWrapper
                          data={
                            graphDataState[activeReportMenu].pieData
                              ? graphDataState[activeReportMenu].pieData
                              : []
                          }
                          stackKeyNames={
                            graphDataState[activeReportMenu].stackKeyNames
                              ? graphDataState[activeReportMenu].stackKeyNames
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].newPlates &&
                    graphDataState[activeReportMenu].newPlates.length > 0 ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        style={{ ...marginGenerator('mt-24') }}>
                        <CustomMuiDataTable
                          t={t}
                          data={graphDataState[activeReportMenu].newPlates}
                          columns={graphDataState[activeReportMenu].columns}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {activeReportMenu === 'attendance_report' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].lateComers ? (
                    <Grid container spacing={1}>
                      <div style={{ marginTop: 24, width: '100%' }}>
                        <Accordion
                          square
                          expanded={expanded === 'panel1'}
                          onChange={handleAccordionChange('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id="panel1d-header">
                            <Typography color="primary">
                              {t('lateCommingEmp.label')}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid item xs={12}>
                              {Object.keys(
                                graphDataState[activeReportMenu].lateComers
                              ).length > 0
                                ? Object.keys(
                                  graphDataState[activeReportMenu].lateComers
                                ).map((itm, i) => {
                                  return (
                                    <Grid style={{ marginBottom: 24 }}>
                                      <Typography
                                        style={{ paddingBottom: 12 }}
                                        variant="h6"
                                        color="primary">
                                        {itm}
                                      </Typography>
                                      <DataSheetTable
                                        id={`pdf-late-comer-table-${i}`}
                                        columns={
                                          graphDataState[activeReportMenu]
                                            .columns
                                        }
                                        data={
                                          graphDataState[activeReportMenu]
                                            .lateComers[itm]
                                        }
                                      />
                                    </Grid>
                                  );
                                })
                                : 'Record not found!'}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          square
                          expanded={expanded === 'panel2'}
                          onChange={handleAccordionChange('panel2')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2d-content"
                            id="panel2d-header">
                            <Typography color="primary">
                              {t('onTimeEmp.label')}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid item xs={12}>
                              {graphDataState[activeReportMenu] &&
                                graphDataState[activeReportMenu].earlyComer &&
                                Object.keys(
                                  graphDataState[activeReportMenu].earlyComer
                                ).length > 0
                                ? Object.keys(
                                  graphDataState[activeReportMenu].earlyComer
                                ).map((itm, i) => {
                                  return (
                                    <Grid style={{ marginBottom: 24 }}>
                                      <Typography
                                        style={{ paddingBottom: 12 }}
                                        variant="h6"
                                        color="primary">
                                        {itm}
                                      </Typography>
                                      <DataSheetTable
                                        id={`pdf-early-comer-table-${i}`}
                                        columns={
                                          graphDataState[activeReportMenu]
                                            .columns
                                        }
                                        data={
                                          graphDataState[activeReportMenu]
                                            .earlyComer[itm]
                                        }
                                      />
                                    </Grid>
                                  );
                                })
                                : 'Record not found!'}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  ) : null}

                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].tableData ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        ...marginGenerator('mt-30'),
                        ...marginGenerator('mb-30')
                      }}>
                      <Typography variant="h6" color="primary">
                        {graphDataState[activeReportMenu].tableHeading}
                      </Typography>
                      <GenericTable
                        list={graphDataState[activeReportMenu].tableData}
                        columns={graphDataState[activeReportMenu].columns}
                      />
                    </Grid>
                  ) : null}
                </React.Fragment>
              ) : (
                <Grid
                  container
                  spacing={1}
                  style={{
                    ...marginGenerator('mb-15'),
                    ...marginGenerator('mt-0')
                  }}>
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('cameraId') && (
                      <Grid item xs={3}>
                        <Autocomplete
                          styleOverrides={{ minWidth: '100%' }}
                          handleChange={(evt, newVal) => {
                            handleSelectCamera(newVal);
                          }}
                          val={filterState[activeReportMenu].cameraId}
                          options={
                            activeReportCameraList &&
                              activeReportCameraList[activeReportMenu]
                              ? activeReportCameraList[activeReportMenu]
                              : []
                          }
                          label={t('camera.label')}
                          size="small"
                          handleClose={evt => {
                            setActiveReportCameraList({
                              ...activeReportCameraList,
                              [activeReportMenu]: []
                            });
                            getCameraList({
                              variables: {
                                organization: data.organizationId,
                                page: 1,
                                limit: 20,
                                search: ''
                              }
                            });
                          }}
                          onInputChange={onInputChange}
                          multiple={
                            activeReportMenu === 'processed_videos'
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('placeId') && (
                      <Grid item xs={3}>
                        <Autocomplete
                          styleOverrides={{
                            minWidth: '100%',
                            maxWidth: '100%'
                          }}
                          handleChange={(evt, newVal) => {
                            if (!newVal) {
                              handleAutoPlaceSearch('');
                            }
                            setFilterState(prevState => {
                              let newState = { ...prevState };
                              newState[activeReportMenu] = {
                                ...newState[activeReportMenu],
                                placeId: newVal && newVal.id ? newVal.id : ''
                              };
                              return newState;
                            });
                          }}
                          options={
                            placeList && placeList.length > 0 ? placeList : []
                          }
                          label={t('place.label')}
                          size="small"
                          onInputChange={handleAutoPlaceSearch}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('startTime') && (
                      <Grid item xs={3}>
                        <DateTimeSelector
                          inputStyle={{ padding: 10, minWidth: 242 }}
                          label={
                            activeReportMenu ===
                              'directional_line_person_counting'
                              ? 'YYYY-MM-dd'
                              : 'YYYY-MM-dd HH:mm'
                          }
                          dateFormat={
                            activeReportMenu ===
                              'directional_line_person_counting'
                              ? 'yyyy-MM-dd'
                              : 'yyyy-MM-dd HH:mm'
                          }
                          inputValue={
                            activeReportMenu ===
                              'directional_line_person_counting' &&
                              filterState[activeReportMenu].startTime
                              ? moment(
                                filterState[activeReportMenu].startTime
                              ).format('YYYY-MM-DD 00:00')
                              : null
                          }
                          value={filterState[activeReportMenu].startTime}
                          handleChange={newDate => {
                            setFilterState(prevState => {
                              let newState = { ...prevState };
                              newState[activeReportMenu] = {
                                ...newState[activeReportMenu],
                                startTime: newDate
                              };
                              return newState;
                            });
                          }}
                          disableFuture={true}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('endTime') && (
                      <Grid item xs={3}>
                        <DateTimeSelector
                          inputStyle={{ padding: 10, minWidth: 242 }}
                          label={
                            activeReportMenu ===
                              'directional_line_person_counting'
                              ? 'HH:mm'
                              : 'YYYY-MM-dd HH:mm'
                          }
                          dateFormat={
                            activeReportMenu ===
                              'directional_line_person_counting'
                              ? 'HH:mm'
                              : 'yyyy-MM-dd HH:mm'
                          }
                          inputValue={
                            activeReportMenu ===
                              'directional_line_person_counting' &&
                              filterState[activeReportMenu].endTime
                              ? moment(
                                filterState[activeReportMenu].startTime
                              ).format(
                                'YYYY-MM-DD ' +
                                moment(
                                  filterState[activeReportMenu].endTime
                                ).format('HH:ss')
                              )
                              : null
                          }
                          value={filterState[activeReportMenu].endTime}
                          handleChange={newDate => {
                            setFilterState(prevState => {
                              let newState = { ...prevState };
                              newState[activeReportMenu] = {
                                ...newState[activeReportMenu],
                                endTime: newDate
                              };
                              return newState;
                            });
                          }}
                          disableFuture={true}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('interval') && (
                      <Grid item xs={3}>
                        <OutlinedSelect
                          val={
                            filterState[activeReportMenu].interval
                              ? filterState[activeReportMenu].interval
                              : ''
                          }
                          label={t('timeInterval.label')}
                          handleChange={evt =>
                            handleFilterChange(evt, 'interval')
                          }
                          styleOverrides={{ minWidth: '100%' }}
                          options={intervalList}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('heatmapObject') && (
                      <Grid item xs={3}>
                        <OutlinedSelect
                          val={
                            filterState[activeReportMenu].heatmapObject
                              ? filterState[activeReportMenu].heatmapObject
                              : ''
                          }
                          label={t('object.label')}
                          handleChange={evt =>
                            handleFilterChange(evt, 'heatmapObject')
                          }
                          styleOverrides={{ minWidth: '100%' }}
                          disabled={disableState}
                          options={ACD_OBJECTS_LIST}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('threshold') && (
                      <Grid
                        item
                        xs={4}
                        style={{ paddingLeft: 10, paddingRight: 15 }}>
                        <Typography id="discrete-slider-small-steps">
                          {t('threshold.label')}
                        </Typography>
                        <Slider
                          value={
                            filterState[activeReportMenu].threshold
                              ? parseFloat(
                                filterState[activeReportMenu].threshold
                              )
                              : 0.7
                          }
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider-small-steps"
                          valueLabelDisplay="auto"
                          step={0.1}
                          marks
                          min={0}
                          max={1}
                          onChange={(evt, number) => {
                            handleFilterChange(number, 'threshold');
                          }}
                          disabled={disableState}
                        />
                      </Grid>
                    )}
                  {activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('modelType') && (
                      <Grid item xs={3}>
                        <OutlinedSelect
                          val={filterState[activeReportMenu].modelType}
                          label={t('models.label')}
                          multiple={
                            activeReportMenu === 'notification_report'
                              ? true
                              : false
                          }
                          handleChange={evt => {
                            handleFilterChange(evt, 'modelType');
                          }}
                          styleOverrides={{
                            minWidth: '100%',
                            maxWidth: '100%'
                          }}
                          options={aiTypes && aiTypes.length > 0 ? aiTypes : []}
                        />
                      </Grid>
                    )}
                  {activeReportMenu === 'notification_report' &&
                    activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('status') && (
                      <Grid item xs={3}>
                        <OutlinedSelect
                          val={filterState[activeReportMenu].status}
                          label={t('status.label')}
                          handleChange={evt => {
                            handleFilterChange(evt, 'status');
                          }}
                          styleOverrides={{
                            minWidth: '100%',
                            maxWidth: '100%'
                          }}
                          options={[
                            { id: 'unprocessed', name: 'Unprocessed' },
                            { id: 'agree', name: 'Agree' },
                            { id: 'disagree', name: 'Disagree' }
                          ]}
                        />
                      </Grid>
                    )}
                  {activeReportMenu === 'directional_line_crossing' &&
                    activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('line_cross_area') && (
                      <>
                        <Grid item xs={2}>
                          <OutlinedSelect
                            val={filterState[activeReportMenu].actionType}
                            label={t('actionType.label')}
                            handleChange={evt => {
                              handleFilterChange(evt, 'actionType');
                            }}
                            styleOverrides={{
                              minWidth: '100%',
                              maxWidth: '100%'
                            }}
                            options={['IN', 'OUT']}
                          />
                        </Grid>
                        {filterState[activeReportMenu] &&
                          filterState[activeReportMenu].actionType ? (
                          <Grid item xs={2}>
                            <OutlinedSelect
                              val={filterState[activeReportMenu].direction}
                              label={t('direction.label')}
                              handleChange={evt => {
                                handleFilterChange(evt, 'direction');
                              }}
                              styleOverrides={{
                                minWidth: '100%',
                                maxWidth: '100%'
                              }}
                              options={[
                                { id: 'ltr', name: 'Left To Right' },
                                { id: 'rtl', name: 'Right To Left' },
                                { id: 'ttb', name: 'Top To Bottom' },
                                { id: 'btt', name: 'Bottom To Top' }
                              ]}
                            />
                          </Grid>
                        ) : null}
                        <Grid item xs={2}>
                          <Button
                            t={t}
                            disabled={
                              disableState && roiEventLoading[activeReportMenu]
                            }
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%' }}
                            className={classes.searcBtn}
                            onClick={handleDrawDirectionalLine}>
                            {roiEventLoading[activeReportMenu] ? (
                              <CircularProgress size={14} />
                            ) : (
                              t('getLineCross.label')
                            )}
                          </Button>
                        </Grid>
                        {filterState[activeReportMenu] &&
                          filterState[activeReportMenu].x1Value ? (
                          <Grid item xs={2}>
                            <Typography variant="body1">
                              X1 : {filterState[activeReportMenu].x1Value}, Y1 :{' '}
                              {filterState[activeReportMenu].y1Value}{' '}
                            </Typography>
                            <Typography variant="body1">
                              X2 : {filterState[activeReportMenu].x2Value}, Y2 :{' '}
                              {filterState[activeReportMenu].y2Value}{' '}
                            </Typography>
                          </Grid>
                        ) : null}
                      </>
                    )}
                  {activeReportMenu === 'directional_line_person_counting' &&
                    activeMenuData &&
                    activeMenuData.filters &&
                    activeMenuData.filters &&
                    activeMenuData.filters.includes('line_cross_area') && (
                      <>
                        <Grid item xs={2}>
                          <OutlinedSelect
                            val={filterState[activeReportMenu].direction}
                            label={t('direction.label')}
                            handleChange={evt => {
                              handleFilterChange(evt, 'direction');
                            }}
                            styleOverrides={{
                              minWidth: '100%',
                              maxWidth: '100%'
                            }}
                            options={[
                              { id: 'ltr', name: 'Left To Right' },
                              { id: 'ttb', name: 'Top To Bottom' }
                            ]}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            t={t}
                            disabled={
                              disableState && roiEventLoading[activeReportMenu]
                            }
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%' }}
                            className={classes.searcBtn}
                            onClick={handleDrawDirectionalLine}>
                            {roiEventLoading[activeReportMenu] ? (
                              <CircularProgress size={14} />
                            ) : (
                              t('getLineCross.label')
                            )}
                          </Button>
                        </Grid>
                        {filterState[activeReportMenu] &&
                          filterState[activeReportMenu].x1Value ? (
                          <Grid item xs={2}>
                            <Typography variant="body1">
                              X1 : {filterState[activeReportMenu].x1Value}, Y1 :{' '}
                              {filterState[activeReportMenu].y1Value}{' '}
                            </Typography>
                            <Typography variant="body1">
                              X2 : {filterState[activeReportMenu].x2Value}, Y2 :{' '}
                              {filterState[activeReportMenu].y2Value}{' '}
                            </Typography>
                          </Grid>
                        ) : null}
                      </>
                    )}
                  <Grid item xs={1}>
                    <ButtonComponent
                      t={t}
                      variant="outlined"
                      size="medium"
                      color="primary"
                      styling={0}
                      onClick={handleOnDemandHeatMapSubmit}
                      loading={btnLoading}
                      text={t('submit.label')}
                    />
                  </Grid>
                  {activeReportMenu === 'notification_report' && (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      className={classes.searcBtn}
                      disabled={
                        graphDataState[activeReportMenu] &&
                          graphDataState[activeReportMenu].count
                          ? false
                          : true
                      }
                      size="medium"
                      onClick={handleExportNotificationReport}>
                      Export Excel
                    </Button>
                  )}
                  {activeReportMenu === 'processed_alarm_counts_agents' && (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      className={classes.searcBtn}
                      size="medium"
                      onClick={handleExportProcessedAlarmsCountsReport}
                    >
                      {processedAlarmCountReportLoading ? <CircularProgress size={14} /> : "Export Excel"}
                    </Button>
                  )}
                  {activeReportMenu === 'acknowledges' && (
                    <Grid item xs={2}>
                      {reloadAckngLoading ? (
                        <CircularProgress size={14} />
                      ) : (
                        <IconButton
                          onClick={handleReloadAcknowledges}
                          aria-label="Refresh">
                          <RefreshIcon />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                  {activeReportMenu === 'acknowledges' && reportLoading ? (
                    <div style={{ textAlign: 'center', marginTop: 25 }}>
                      <CircularProgress size={25} />
                    </div>
                  ) : null}
                  {activeReportMenu === 'processed_videos' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].isExecute ? (
                    <>
                      <Grid
                        container
                        spacing={4}
                        style={{ ...marginGenerator('mt-30') }}>
                        <Grid item sm={6}>
                          <Typography color="primary" variant="subtitle1">
                            {t('totalVideoCount.label')}
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography color="primary" variant="subtitle1">
                            {t('totalVideoAlertsCount.label')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={4}
                        style={{ ...marginGenerator('mt-24') }}>
                        <Grid item sm={6}>
                          <Typography color="textPrimary" variant="subtitle1">
                            {graphDataState[activeReportMenu].totalVideoCounts
                              ? graphDataState[activeReportMenu]
                                .totalVideoCounts
                              : 0}
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography color="textPrimary" variant="subtitle1">
                            {graphDataState[activeReportMenu]
                              .totalVideoAlertCounts
                              ? graphDataState[activeReportMenu]
                                .totalVideoAlertCounts
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {activeReportMenu === 'acknowledges' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].pieData &&
                    graphDataState[activeReportMenu].pieData.length > 0 ? (
                    <>
                      <Grid
                        container
                        spacing={2}
                        style={{ ...marginGenerator('mt-30') }}>
                        {graphDataState[activeReportMenu].pieData.map(itm => {
                          return (
                            <Grid key={itm.name} item sm={3}>
                              <Typography color="primary" variant="subtitle1">
                                {itm.name}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ ...marginGenerator('mt-30') }}>
                        {graphDataState[activeReportMenu].pieData.map(itm => {
                          return (
                            <Grid key={itm.name} item sm={3}>
                              <Typography
                                color="textPrimary"
                                variant="subtitle1">
                                {itm.value}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  ) : null}
                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].pieData.length > 0 ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={12}
                        style={{ ...marginGenerator('mt-24') }}>
                        <PieChartWrapper
                          data={
                            graphDataState[activeReportMenu].pieData
                              ? graphDataState[activeReportMenu].pieData
                              : []
                          }
                          stackKeyNames={
                            graphDataState[activeReportMenu].stackKeyNames
                              ? graphDataState[activeReportMenu].stackKeyNames
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].pieData.length === 0 &&
                    graphDataState[activeReportMenu]?.pieChartDataLoaded && activeReportMenu === 'processed_alarm_counts_agents' && !getAgentWiseReportLoading ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={12}
                        style={{ ...marginGenerator('mt-24') }}>
                        <Typography variant='h2'>
                          No data available
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                  {graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].lineData &&
                    graphDataState[activeReportMenu].lineData.length > 0 ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        style={
                          activeReportMenu !== 'dwell_time_analysis'
                            ? { ...marginGenerator('mt-24') }
                            : null
                        }>
                        <LineChartWrapper
                          data={
                            graphDataState[activeReportMenu].lineData
                              ? graphDataState[activeReportMenu].lineData
                              : []
                          }
                          dataKeyXAxis={
                            graphDataState[activeReportMenu].dataKeyXAxis
                              ? graphDataState[activeReportMenu].dataKeyXAxis
                              : []
                          }
                          stackKeyNames={
                            graphDataState[activeReportMenu].stackKeyNames
                              ? graphDataState[activeReportMenu].stackKeyNames
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  {activeReportMenu === 'heatmap' ? (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        style={{ ...marginGenerator('mt-24') }}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align={'left'} width="15%">
                                  <Typography variant={'subtitle1'}>
                                    Camera
                                  </Typography>
                                </TableCell>
                                <TableCell align={'left'} width="15%">
                                  <Typography variant={'subtitle1'}>
                                    Requested Time
                                  </Typography>
                                </TableCell>
                                <TableCell align={'left'} width="25%">
                                  <Typography variant={'subtitle1'}>
                                    Heatmap TimeFrame
                                  </Typography>
                                </TableCell>
                                <TableCell align={'left'} width="10%">
                                  <Typography variant={'subtitle1'}>
                                    Object
                                  </Typography>
                                </TableCell>
                                <TableCell align={'left'} width="10%">
                                  <Typography variant={'subtitle1'}>
                                    Heatmap Image
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!heatmapLoading && !heatmapError ? (
                                heatmapList.getHeatmapRecordsList &&
                                  heatmapList.getHeatmapRecordsList.data.length >
                                  0 ? (
                                  heatmapList.getHeatmapRecordsList.data.map(
                                    (rowitem, rowIndex) => (
                                      <HeatmapPanelItem
                                        key={rowIndex}
                                        t={t}
                                        row={rowitem}
                                      />
                                    )
                                  )
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={5}>
                                      <Typography variant={'subtitle2'}>
                                        Record not found!
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    {heatmapLoading && !heatmapError && (
                                      <Typography
                                        variant={'subtitle2'}
                                        style={{ textAlign: 'center' }}>
                                        <CircularProgress size={25} />
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  ) : null}
                  {activeReportMenu === 'directional_line_crossing' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].directionalLineData ? (
                    <Grid container spacing={2} style={{ marginTop: 32 }}>
                      <Statcard
                        statLabel={`Total ${graphDataState[activeReportMenu].directionalLineData.actionType} Count`}
                        statValue={
                          graphDataState[activeReportMenu].directionalLineData
                            .Total
                            ? graphDataState[activeReportMenu]
                              .directionalLineData.Total
                            : 0
                        }
                        customStyles={{ width: 280, padding: '8px 16px' }}
                      />
                      <Statcard
                        statLabel={`Unique ${graphDataState[activeReportMenu].directionalLineData.actionType} Count`}
                        statValue={
                          graphDataState[activeReportMenu].directionalLineData
                            .Unique
                            ? graphDataState[activeReportMenu]
                              .directionalLineData.Unique
                            : 0
                        }
                        customStyles={{ width: 280 }}
                      />
                    </Grid>
                  ) : null}
                  {activeReportMenu === 'directional_line_person_counting' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].directionalLineData ? (
                    <>
                      <Grid container spacing={2} style={{ marginTop: 32 }}>
                        <Statcard
                          statLabel={`Total Came In`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .Total
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.Total
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                        <Statcard
                          statLabel={`Total Went Out`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .TotalOut
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.TotalOut
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                        <Statcard
                          statLabel={`Total People Inside`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .TotalInside
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.TotalInside
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                      </Grid>
                      <Grid container spacing={2} style={{ marginTop: 32 }}>
                        <Statcard
                          statLabel={`Unique Came In`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .Unique
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.Unique
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                        <Statcard
                          statLabel={`Unique Out`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .UniqueOut
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.UniqueOut
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                        <Statcard
                          statLabel={`Unique People Inside`}
                          statValue={
                            graphDataState[activeReportMenu].directionalLineData
                              .UniqueInside
                              ? graphDataState[activeReportMenu]
                                .directionalLineData.UniqueInside
                              : 0
                          }
                          customStyles={{ width: 280, padding: '8px 16px' }}
                        />
                      </Grid>
                    </>
                  ) : null}
                  {activeReportMenu === 'acknowledges' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].proceedNotificationList ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        ...marginGenerator('mt-30'),
                        ...marginGenerator('mb-30')
                      }}>
                      <Typography variant="h6" color="primary">
                        {t('proceedNotifications.label')}
                      </Typography>
                      <GenericTable
                        list={
                          graphDataState[activeReportMenu]
                            .proceedNotificationList
                        }
                        columns={proceedNotificationColumn}
                        loading={proceedReportLoading}
                        handleDownloadClick={handleDownloadNotificationExcel}
                        downloadLoading={downloadCsvLoading}
                      />
                    </Grid>
                  ) : null}
                  {activeReportMenu === 'notification_report' &&
                    graphDataState[activeReportMenu] &&
                    graphDataState[activeReportMenu].notifications &&
                    graphDataState[activeReportMenu].notifications.length ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        ...marginGenerator('mt-30'),
                        ...marginGenerator('mb-30'),
                        marginLeft: 10
                      }}>
                      <Typography variant="h6" color="primary">
                        {t('notifications.label')}
                      </Typography>
                      <GenericTable
                        list={graphDataState[activeReportMenu].notifications}
                        columns={notificationColumn}
                        loading={false}
                      />
                      <Grid item xs={3} style={{ marginTop: 15 }}>
                        <span>
                          Total{' '}
                          {Math.ceil(
                            graphDataState[activeReportMenu].count / 20
                          )}{' '}
                          page, {graphDataState[activeReportMenu].count} Row
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Pagination
                          color="secondary"
                          count={Math.ceil(
                            graphDataState[activeReportMenu].count / 20
                          )}
                          page={
                            filterState[activeReportMenu].page
                              ? filterState[activeReportMenu].page
                              : 1
                          }
                          onChange={(evt, value) => {
                            handleNotificationPageChange(value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null
      }
      {loading && <CircularProgress size={20} />}
      <EventTagsModal
        open={eventTagModalState}
        handleClose={() => {
          setEventTagModalState(false);
          setActiveEventTags({});
        }}
        tags={activeEventTags}
      />
      {
        selectedRow && (
          <EventsVideoPlayBackModal
            open={openVideoPlayBackModal}
            handleClose={evt => {
              setOpenVideoPlayBackModal(false);
              setSelectedRow(null);
            }}
            data={selectedRow}
          />
        )
      }
    </div >
  );
};

PreBuildAnalytics.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default PreBuildAnalytics;
