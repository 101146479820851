import React from 'react';
import { withTranslation } from 'react-i18next';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 20,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  },
  leftContainer: {
    flexDirection: 'column',
    width: 160,
    paddingTop: 30,
    paddingBottom: 20,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0
    },
    '@media orientation: landscape': {
      width: 200
    }
  },
  centerContainer: {
    flexDirection: 'column',
    width: 90,
    paddingTop: 30,
    paddingBottom: 20,
    paddingLeft: 0,
    paddingBottom: 0,
    color: '#9696A4',
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  rightContainer: {
    flexDirection: 'column',
    width:320,
    paddingTop: 30,
    paddingBottom: 20,
    paddingLeft: 0,
    paddingBottom: 0,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  }
});

const BillTo = ({ invoice, t, org,platform,circuitId,siteName }) => {
  let userInfo = invoice && invoice.length && invoice[0].customerData
  return(
  <View style={styles.container}>
    <View style={styles.leftContainer}>
      <Text>{org ? org : ""}</Text>
      <Text>
        {userInfo && userInfo.attributes && userInfo.attributes.Address ? userInfo.attributes.Address[0] : ''}
      </Text>
      <Text>{userInfo && userInfo.phone ? userInfo.phone : ""}</Text>
      <Text>{platform}</Text>
    </View>
    <View style={styles.centerContainer}>
      <Text>{t('circuitId.label')}</Text>
      <Text>{t('siteName.label')}</Text>
    </View>
    <View style={styles.rightContainer}>
      {/* <Text>{circuitId}</Text>
      <Text>{siteName}</Text> */}
        <Text>{invoice[0].circuitId}</Text>
      <Text>{invoice[0].siteName}</Text>
    </View>
  </View>
)};

export default withTranslation()(BillTo);
