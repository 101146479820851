import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import './sidebarStyle.css';
import { SideBarContext } from '../../contexts/SideBarProvider';
import Message from '../Message';
import EventDialog from '../../containers/ControlPlatform/Events/EventDialog';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'inline-block',
    width: '230px',
    verticalAlign: 'top',
    flexGrow: 1,
    background: '#5a1f2a',
    maxWidth: '350px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    position: 'initial',
    height: '100%',
    border: '0'
  },
  drawerContainer: {
    overflow: 'auto',
    // height: '90vh',
    background: '#5a1f2a'
  },
  atag: {
    color: '#ffc8c8'
  },
  socialIcons: {
    fontSize: '2rem',
    color: 'white'
  }
}));

const Sidebar = ({ t }) => {
  const classes = useStyles();
  const [eventDialog, setEventDialog] = useState(false)
  const [eventData, setEventData] = useState({})
  const { birthdayList, eventsList, updateEventFun } = useContext(SideBarContext)

  const handleDoubleClick = (evt, data) => {
    if (evt.detail === 2) {
      setEventData(data)
      setEventDialog(true)
    }
  }

  const handleEventStatus = (status) => {
    if (status) {
      setEventDialog(false)
      Message.success('Event Updated Successfully.');
      updateEventFun()
    } else {
      Message.error('Something Went Wrong')
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* <Toolbar /> */}
        <div className={classes.drawerContainer}>
          <div className='scrollEvents'>
            <Grid item xs container direction="column" spacing={1} style={{ alignItems: 'baseline', padding: '15px 25px' }}>
              <Grid item>
                <Typography style={{ color: 'white' }} variant="h6">
                  {t('events.label')}
                </Typography>
              </Grid>
              {eventsList && eventsList.length > 0 ? eventsList.map(item => (
                <>
                  <Grid item>
                    <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                      {item.date}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography onClick={evt => handleDoubleClick(evt, item)} style={{ color: 'white', fontSize: '0.75rem' }}>
                      {item.name}
                    </Typography>
                  </Grid>
                </>
              )) : null}
            </Grid>
          </div>
          <Divider />
          <div className='scrollBirthday'>
            <Grid item xs container direction="column" spacing={1} style={{ alignItems: 'baseline', padding: '15px 25px' }}>
              <Grid item>
                <Typography style={{ color: 'white' }} variant="h6">
                  {t('birthday.label')}
                </Typography>
              </Grid>
              {birthdayList && birthdayList.length > 0 ? birthdayList.map(item => (
                <>
                  <Grid item>
                    <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                      {item.date}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                      {item.name}
                    </Typography>
                  </Grid>
                </>
              )) : null}
            </Grid>
          </div>
          <Divider />
          <Grid item xs container direction="column" spacing={1} style={{ alignItems: 'baseline', padding: '15px 25px' }}>
            <Grid item>
              <Typography style={{ color: 'white' }} variant="h6">
                {t('news.label')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                inq. Digital bags three BOICT awards
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'red', fontSize: '0.75rem' }}>
                <a className={classes.atag} href='https://techcabal.com/2022/07/06/inq-digital-bags-three-boict-awards/' target="_blank">Read</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                Edge coming to the rescue of cloud
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'red', fontSize: '0.75rem' }}>
                <a className={classes.atag} href='https://www.itweb.co.za/content/O2rQGqAE5nQqd1ea' target="_blank">Read</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white', fontSize: '0.75rem' }}>
                inq. expands footprint into south africa
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'red', fontSize: '0.75rem' }}>
                <a className={classes.atag} href='https://www.inq.inc/blog/2022/05/05/inq-expands-footprint-into-south-africa-with-the-acquisition-of-syrex/' target="_blank">Read</a>
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '15px 25px' }}>
            <a href="https://twitter.com/inq_Edge" target='_blank' ><TwitterIcon className={classes.socialIcons} /></a>
            <a href="https://www.facebook.com/InqEdge" target='_blank' ><FacebookIcon className={classes.socialIcons} /></a>
            <a href="https://www.linkedin.com/company/65299141/admin/" target='_blank' ><LinkedInIcon className={classes.socialIcons} /></a>
            <a href="https://www.instagram.com/inq.edge/" target='_blank' ><InstagramIcon className={classes.socialIcons} /></a>
            <a href="https://www.youtube.com/channel/UCiomXhsNtvKQeoXCaCjW00Q" target='_blank' ><YouTubeIcon className={classes.socialIcons} /></a>
          </div>
        </div>
      </Drawer>
      <EventDialog
        open={eventDialog}
        handleClose={evt => setEventDialog(false)}
        handleEventStatus={handleEventStatus}
        data={eventData}
      />
    </div>
  );
}

export default withTranslation()(Sidebar)