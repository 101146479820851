import React, {useState, useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Input, TextField, Radio, Typography, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';

import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import { colors } from '../../../../../../../theme/colors';
import OutlinedSelect from '../../../../../.././../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
    root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
    iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
            color="secondary"
            variant={'contained'}
            onClick={onClick} 
            disabled={loading}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
let initStep = {
                  title: "",
                  description: "",
                  //status: "",
                  options: [],
                  signature: false,
                  approval: false,
                  //isApprovalUserListEnable: false,
                  //approvalUser: "",
                  //approvalUserOcrId: "",
                  groupId: "",
                  assignedUsers: ""
                };

const CreateOcrProcess = ({
    t,
    open,
    onClose,
    subscriptionUserListLoading,
    subscriptionUserData,
    groupListLoading,
    ocrGroupData,
    ocrOcrUsersDataLoading,
    ocrUsersList,
    handleFilterForOcrUser,
    hanldeSubmitOfAllOcrProcess,
    createOcrProcessLoading,
    allOcrUsersData,
    ocrUsersListLoading
}) => {

    const classes = useStyles();
    const [processStepsState, setProcessStepsState] = useState([{...initStep}]);
    const [filedsValue, setFiledsValue] = useState({
        user: "",
        group: ""
    });

    const handleInputChange = (e, indx) => {
        const { name, value, checked } = e.target;
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            if(checked && name !== "status"){                
                newState[indx][name] = checked;
                if(name === 'approval'){
                //newState[indx]['isApprovalUserListEnable'] = checked;
                }
            }else if(!checked && name !== "status" && (name === 'approval' || name === 'signature')){
               newState[indx][name] = checked;
               if(name === 'approval'){
                //newState[indx]['isApprovalUserListEnable'] = checked;
               }
            } else {
             newState[indx][name] = value;
             newState = processStepsState.map(el => {
                            el.groupId = filedsValue.group;
                            el.assignedUsers = filedsValue.user;
                            return el;
                        });
            }
            return newState;
        });
    }
    
    const handleAddProcessOption = (index) => {
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            newState[index].options.push("");
            return newState;
        });

        initStep =  {
            title: "",
            description: "",
            //status: "",
            options: [],
            signature: false,
            approval: false,
            //isApprovalUserListEnable: false,
            //approvalUser: "",
            //approvalUserOcrId: "",
            groupId: "",
            assignedUsers: ""
        };
    }

    const handleOptionChange = (e, idx1, idx2) => {
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            newState[idx1].options[idx2] = e.target.value;
            return newState;
        });
    };

    const handleRemoveMultiData = (idx) => {
            let updatedState = [...processStepsState];
            updatedState.splice(idx, 1);
            setProcessStepsState(updatedState);
    };

    useEffect(() => {
        if(!open){
          setProcessStepsState([{...initStep}]);
          setFiledsValue({ user: "", group: "" });
        }
      }, [open]);
      
  return (
    <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={onClose}
            >
            <DialogTitle id="place-template-title">{t('create.label')} {t('processWorkFlow.label')}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs="2">
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() =>{
                                setProcessStepsState(oldArray => [...oldArray, {...initStep}]);
                            } }
                            style={{ height: 50}}
                            >
                            {t('addNewStep.label')}
                        </Button>
                    </Grid>
                    <Grid item xs="5">
                    <OutlinedSelect
                        val={filedsValue.group}
                        label="Select Group"
                        handleChange={evt =>{
                            handleFilterForOcrUser({id:evt.target.value});
                            setFiledsValue({...filedsValue, group:evt.target.value})
                        }}
                        selectStyle={{...paddingGenerator('p-8')}}
                        styleOverrides={{ width: '100%', marginRight: 0, height: 50 }}
                        options={(!groupListLoading && ocrGroupData) ? ocrGroupData : []}
                      />
                    </Grid>
                    <Grid item xs="5">
                        <Autocomplete
                            id={`user-select`}
                            options={(!ocrOcrUsersDataLoading && ocrUsersList) ? ocrUsersList : []}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            onChange={(event, newValue) => {
                                if(newValue){
                                    setFiledsValue({...filedsValue, user:newValue.id});
                                } else {
                                    setFiledsValue({...filedsValue, user: ""});
                                }
                            }}
                            loading={ocrOcrUsersDataLoading}
                            style={{ border: '1px solid #ebebeb', width: '100%', height: 50, marginLeft: 12 }}
                            renderInput={(params) => <TextField {...params} label={`Select ${t('users.label')} *`} variant="outlined" />}
                            disabled={!filedsValue.group}
                        />
                    </Grid>
                </Grid>
                <Grid style={{...marginGenerator("mt-16")}} container spacing={3}>
                    {processStepsState.map((step, idx) => {
                        return (
                        <React.Fragment key={`step_${idx}`}>
                                <Grid item xs={6}>
                                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                                        Step {idx+1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {
                                        idx!== 0 && (
                                            <IconButton 
                                                onClick={evt => handleRemoveMultiData(idx)} 
                                                aria-label="delete"
                                            >
                                                <DeleteIcon color="secondary"/>
                                            </IconButton>
                                        )
                                    }
                                </Grid>
                            <Grid item xs={6}>
                                <Input
                                    value={step.title}
                                    type={'text'}
                                    placeholder={`${t('title.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    name="title"
                                    onChange={evt => handleInputChange(evt, idx)}
                                />
                            </Grid>
                            {/* <Grid item xs={2}>
                                <Typography variant={'subtitle1'} color={'textPrimary'}>
                                    Action visable
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {["Yes", "No"].map((field, index) => {
                                    return (
                                        <Radio
                                            key={index}
                                            checked={field === processStepsState[idx].status}
                                            classes={{ root: classes.iconButton }}
                                            style={{
                                                ...marginGenerator('mr-16'),
                                                ...marginGenerator('mt-0'),
                                                ...(field === processStepsState[idx].status ? { backgroundColor: colors.secondary.main }
                                                : '')
                                            }}
                                            onChange={evt => handleInputChange(evt, idx)}
                                            value={field}
                                            name="status"
                                            checkedIcon={
                                                <>
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        style={{ color: colors.common.white }}>
                                                        {field}
                                                    </Typography>
                                                </>
                                            }
                                            icon={
                                            <div className={classes.icon}>
                                                <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                {field}
                                                </Typography>
                                            </div>
                                            }
                                        />
                                    )
                                })}
                            </Grid> */}
                            <Grid item xs={12}>
                                <Button
                                    variant={'outlined'}
                                    color="primary"
                                    onClick={evt => handleAddProcessOption(idx)}
                                >
                                    {t('addOption.label')}
                                </Button>
                            </Grid>
                            {step.options && step.options.map((opt, optIndex) => (
                                <>
                                    <Grid item xs={9}>
                                        <Input
                                            value={opt}
                                            key={`${optIndex}-${idx}`}
                                            type={'text'}
                                            placeholder={`${t('option.label')} - ${optIndex+1}`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                            name="options"
                                            onChange={evt => handleOptionChange(evt, idx, optIndex)}
                                            />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={evt => {
                                            setProcessStepsState(prevState => {
                                                let newState = [...prevState];
                                                newState[idx].options.splice(optIndex, 1);
                                                return newState;
                                            });
                                        }} 
                                        aria-label="delete">
                                            <DeleteIcon color="secondary"/>
                                        </IconButton>
                                    </Grid>
                                </>
                            ))}
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    placeholder={t("description.label")}
                                    multiline
                                    value={step.description}
                                    rows={2}
                                    onChange={evt => handleInputChange(evt, idx)}
                                    name="description"
                                    variant="standard"
                                    style={{maxWidth: "100%", minWidth: "100%"}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        style={{padding: 0}}
                                        checked={step.signature}
                                        value={step.signature}
                                        name="signature"
                                        onChange={evt => handleInputChange(evt, idx)}
                                    />
                                    Signature
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                            <Typography variant="body2">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        style={{padding: 0}}
                                        checked={step.approval}
                                        value={step.approval}
                                        name="approval"
                                        onChange={evt => handleInputChange(evt, idx)}
                                    />
                                    Enable Approval Flow
                                </Typography>      
                            </Grid>
                            <Grid item xs={4}>
                                {/* {
                                    step.isApprovalUserListEnable && (
                                        <Autocomplete
                                        id={`user-select`}
                                        //value={step.approvalUser}
                                        options={(!ocrUsersListLoading && allOcrUsersData) ? allOcrUsersData : []}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                        onChange={(event, newValue) => {
                                            if(newValue){
                                                let newState = [...processStepsState];
                                                newState[idx]['approvalUser'] = newValue.user_id;
                                                newState[idx]['approvalUserOcrId'] = newValue.id
                                            }
                                        }}
                                        loading={ocrUsersListLoading}
                                        name="approv-user"
                                        style={{ border: '1px solid #ebebeb', width: '100%', height: 50, marginLeft: 12 }}
                                        renderInput={(params) => <TextField {...params} label={`Select ${t('users.label')} *`} variant="outlined" />}
                                  /> 
                                    )
                                }      */}
                            </Grid>
                        </React.Fragment>);
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={onClose}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                        t={t}
                        onClick={() => {
                            hanldeSubmitOfAllOcrProcess('add',processStepsState, {groupData: filedsValue});
                        }}
                        loading={createOcrProcessLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
  )
}

export default CreateOcrProcess