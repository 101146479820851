import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { InternalPaths } from '../../../routes/routePaths';
import CustomTabs from "../../../components/CustomTabs";
import Analytics from './components/Analytics';
import { USER_ROLES, COUNTRY_LIST } from "../../../utils/constants";
import Typography from "@material-ui/core/Typography";
import Camtel from './components/Camtel/components/Camtel';
import ShowCamtel from './components/Camtel/components/ShowCamtel';

const breadcrumbList = [
    { label: 'home.label', link: InternalPaths.Dashboard },
    { label: 'biDashboard.label', link: InternalPaths.biDashboard }
];
const BiDashboard = ({ t }) => {
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const [refreshTab, setRefreshTab] = useState(false);
    const handleRefreshTab = (event) => {
        setRefreshTab(event);
    }
    let tabs = [];
    if (role.includes(USER_ROLES.BI_DASHBOARD_ADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) {
        tabs.push({
            label: t('biDashboard.label'),
            children: (
                <Analytics t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
    }
    if(role.includes(USER_ROLES.CAMTEL_TEST)){
        tabs.push({
            label: 'Camtel Add',
            children: (
                <Camtel t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        },
        {
            label:"Camel Show",
            children: (
                <ShowCamtel t={t} refreshTab={refreshTab} handleRefreshTab={handleRefreshTab} />
            )
        })
    }
    return (
        <Layout breadcrumbList={breadcrumbList}>
            {(tabs.length === 0) &&
                <Typography variant={'subtitle2'}>{t('unathorisedUserPage.label')}</Typography>
            }
            <CustomTabs
                tabs={tabs}
            />
        </Layout>
    );
};
export default withTranslation()(BiDashboard);
