import React, {useContext} from 'react';
import { get } from "lodash-es";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';

import commonStyles from '../../../../../../../theme/commonStyles';
import { dateFormatterLLL } from '../../../../../../../utils';
import Message from '../../../../../../../components/Message';
import { ProfileContext } from '../../../../../../../contexts/ProfileProvider';

const OcrProcessItem = ({
    key,
    t,
    data,
    handleClickOnProcessView,
    handleClickOnApproveOrRejectBtn
}) => {
    
  const commonClasses = commonStyles();
  const { profile } = useContext(ProfileContext);
  
  return (
    <TableRow key={data.id} >
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'} > 
                    {data.approvedUserName ? data.approvedUserName : '-'} commented on {data.fileName? `${data.fileName} File` : ''} {data.projectName? `from ${data.projectName} Project` : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'} >{data.processTime ? dateFormatterLLL(data.processTime) : '-'}</Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <VisibilityIcon style={{cursor: "pointer"}} onClick={() => handleClickOnProcessView(data)} color="primary" />          
            </Grid>
            {
                data.approvalUserId === get(profile, 'userInfo.id', '') && (
                    <div 
                    style={{ 
                        display: 'flex' ,
                        border: '1px solid black',
                        padding: '2px',
                        width: '60%',
                        justifyContent: 'space-around',
                        marginLeft: '5px'
                    }} 
                >
                    <Button 
                        onClick={()=>handleClickOnApproveOrRejectBtn(true, data)}
                        color="primary"
                        variant="outlined"
                    >
                        Approved
                    </Button>
                    <Button 
                        onClick={()=>handleClickOnApproveOrRejectBtn(false, data)}
                        color="secondary"
                        variant="outlined"
                    >
                        Reject
                    </Button>
                </div>
                )
            }
        </Grid>
    </TableCell>
</TableRow>   
  )
}

export default OcrProcessItem