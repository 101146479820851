import "date-fns";
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { IsNumeric } from '../../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DownloadPDFButton from './DownloadPDFButton';

const TableCell = withStyles({
    root: {
        borderBottom: 0
    }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 40
    },
    loader: {
        width: "100%",
        textAlign:"center"
    },
    yearMonthFilter: {
        padding: 10
    }
}));

const initAddOns          = { moduleName: "", number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 };
const initTotal           = { taxes: 0, totalCost: 0, totalDiscount: 0, totalPayableAmt: 0 };
const initControllerState = { name: "", totalHrs: 10, costPerHrs: 0, amount: 0, freeTierDiscount: 0, finalCost: 0};
const initUcpesState      = { small: { number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }, medium: { number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }, large: { number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }};
const initVnfsState       = { name: "FortiOS", costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0, totalHrs: 10, id: "12345" };

const BillingUtilization = ({ t, loadingData, billingData, handleChangeDateFilter, startDate, allowConfigure, handleSubmitInvoice, saveBtnLoading, handlePaidInvoice, paidBtnLoading, userInfo, currency, handleChangeCurrency, currencyList, invoiceCompanyDetails }) => {

    const classes = useStyles();
    const [controllerState, setControllerState] = useState([]);
    const [notAllowChange, setNotAllowChange]   = useState(true);
    const [addonsState, setAddonsState]         = useState([initAddOns]);
    const [totalState, setTotalState]           = useState(initTotal);
    const [vnfsState, setVnfsState]             = useState([]);
    const [ucpesState, setUcpesState]           = useState({...initUcpesState});
    const [invoicePaid, setInvoicePaid]         = useState(false);
    const [invoiceId, setInvoiceId]             = useState(null);
    const [readyState, setReadyState]           = useState(false);
    const [invoiceBillData, setInvoiceBillData] = useState({ startDate: null, endDate: null, invoiceNumber: "", totalPayableAmount: 0, currancy: "", invoiceDate: "" });
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        let vnfs = [];
        let controllers         = [];
        let smallUcpesCount     = 0;
        let mediumUcpesCount    = 0;
        let largeUcpesCount     = 0;
        if(billingData){
            setAddonsState([initAddOns]);
            setControllerState(initControllerState);
            setTotalState(initTotal);
            setInvoicePaid(false);
            setReadyState(true);
            if(billingData.invoice && billingData.invoice.length > 0){
                let smallUcpes = {}, largeUcpes = {}, mediumUcpes = {};
                let invoiceData = billingData.invoice[0];
                setInvoiceBillData({...invoiceBillData, totalPayableAmount: invoiceData.totalPayableAmount, invoiceNumber: invoiceData.invoiceNumber, invoiceDate: invoiceData.invoiceDate });
                setTotalState({taxes: invoiceData.taxAmount, totalCost: invoiceData.totalCost, totalDiscount: invoiceData.totalDiscount, totalPayableAmt: invoiceData.totalPayableAmount ? invoiceData.totalPayableAmount : 0});
                setInvoiceId(invoiceData._id);
                if(invoiceData.status === "Paid"){
                    setInvoicePaid(true);
                }
                let addons = [];
                if(invoiceData.utilizations && invoiceData.utilizations.length > 0){
                    invoiceData.utilizations.map(item => {
                        if(item.type === "controller"){
                            controllers.push({ id: item.modelId, name: item.modelname, costPerHrs: item.perItemCost, totalHrs: 10, freeTierDiscount: item.discount, finalPrice: item.finalPrice, amount: item.amount });
                        }else if(item.type === "addons"){
                            addons.push({ moduleName: item.moduleName, number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        }else if(item.type === "vnfs"){
                            vnfs.push({ id: item.modelId, name: item.modelname, number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        }else if(item.type === "small_ucpes"){
                            smallUcpes = {number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice};
                        }else if(item.type === "medium_ucpes"){
                            mediumUcpes = {number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice};
                        }else if(item.type === "large_ucpes"){
                            largeUcpes = {number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice};
                        }
                    });
                }
                setUcpesState({small: smallUcpes, medium: mediumUcpes, large: largeUcpes});
                setAddonsState(addons.length > 0 ? addons : [initAddOns]);
            }else{
                console.log("billingData ==================: ", billingData);
                setInvoiceId(null);
                if(billingData && billingData.ucpes && billingData.ucpes.length){
                    billingData.ucpes.map(itm => {
                        if(itm.vcpus && itm.device_status === "success"){
                            if(parseInt(itm.vcpus) == 4){
                                smallUcpesCount++;
                            }else if(parseInt(itm.vcpus) == 8){
                                mediumUcpesCount++;
                            }else if(parseInt(itm.vcpus) == 16){
                                largeUcpesCount++;
                            }
                        }
                    });
                }
                if(billingData.vnfs && billingData.vnfs.length > 0){
                    for(let vnf of billingData.vnfs){
                        if(vnf.vnfT_status && vnf.vnfT_status === "success"){
                            vnfs.push({ id: vnf.id, name: vnf.vnfT_name, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 });
                        }
                    }
                }
                if(billingData.controllers){
                    for(let cntr of billingData.controllers){
                        let date = new Date();
                        var firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                        let lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
                        if(startDate.getMonth() === date.getMonth()){
                            lastDay = date;
                        }
                        let diff = lastDay.valueOf() - firstDay.valueOf();
                        let diffInHours = diff/1000/60/60;
                        console.log("firstDay: ", firstDay, "lastDay ============= : ", lastDay, "diffInHours: ", diffInHours);
                        controllers.push({ id: cntr.controller_id, name: cntr.controller_name, costPerHrs: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0, totalHrs: diffInHours ? parseInt(diffInHours) : 1 });
                    }
                }
                setUcpesState({small: { number: smallUcpesCount, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }, medium: { number: mediumUcpesCount, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }, large: { number: largeUcpesCount, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 }});
            }
            setControllerState(controllers);
            setVnfsState(vnfs.length > 0 ? vnfs : [initVnfsState]);
        }
    }, [billingData]);

    useEffect(() => {
        setNotAllowChange(!allowConfigure);
    }, [allowConfigure]);

    useEffect(() => {
        let date = startDate ? startDate : new Date();
        let firstDay  = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay   = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setInvoiceBillData({...invoiceBillData, startDate: firstDay, endDate: lastDay});
    }, [startDate]);

    useEffect(() => {
        if(currency){
            let crcy = currencyList.filter(itm => {
                if(currency === itm.id){
                    return itm;
                }
            });
            setSelectedCurrency(crcy.length ? crcy[0] : null);
        }
    }, [currency]);

    const handleCreateAddOns = () => {
        setAddonsState([...addonsState, initAddOns]);
    }

    const handleChangeAddons = (evt, idx) => {
        const { name, value } = evt.target;
        if(name !== "moduleName"){
            if(!value){
                calculateAddonsPrice(evt, idx);
                setAddonsState(
                    addonsState.map((item, i) =>
                        i === idx
                        ? {...item, [name] : 0 }
                        : item
                ));
                return null;
            }
            if(!IsNumeric(value)){
                return null;
            }
            calculateAddonsPrice(evt, idx);
        }
        setAddonsState(
            addonsState.map((item, i) =>
                i === idx
                ? {...item, [name] : name !== "moduleName" ? parseFloat(value) : value }
                : item
        ));
    }

    const handleChangeControllerState = (evt, idx) => {
        const { name, value } = evt.target;
        if(name !== "name"){
            if(!value){
                calculateControllerPrice(evt, idx);
                setControllerState(
                    controllerState.map((item, i) =>
                        i === idx
                        ? {...item, [name] : 0 }
                        : item
                ));
                return null;
            }
            if(!IsNumeric(value)){
                return null;
            }
            calculateControllerPrice(evt, idx);
            setControllerState( controllerState.map((item, i) => i === idx ? {...item, [name] : parseFloat(value) } : item ));
        }else{
            setControllerState(
                controllerState.map((item, i) =>
                    i === idx
                    ? {...item, [name] : value }
                    : item
            ));
        }
    }

    const handleChangeVnfs = (evt, idx) => {
        const { name, value } = evt.target;
        if(!value){
            calculateVnfsPrice(evt, idx);
            setVnfsState(
                vnfsState.map((item, i) =>
                    i === idx
                    ? {...item, [name] : 0 }
                    : item
            ));
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        calculateVnfsPrice(evt, idx);
        setVnfsState(
            vnfsState.map((item, i) =>
                i === idx
                ? {...item, [name] : value ? parseFloat(value) : 0 }
                : item
        ));
    }

    const calculateAddonsPrice = (evt, indx) => {
        const { name, value } = evt.target;
        // console.log(name, value);
        const preData = addonsState[indx];
        let amount = preData.amount;
        let finalPrice = preData.finalPrice;
        if(name === "number"){
            if(preData.costPerMonth > 0){
                amount = preData.costPerMonth*parseFloat(value);
            }else{
                amount = 0;
            }
        }else if(name === "costPerMonth"){
            if(value > 0){
                amount = value ? preData.number*parseFloat(value) : 0;
            }else{
                amount = 0;
            }
        }
        if(name === "freeTierDiscount"){
            finalPrice = value ? amount-parseFloat(value) : amount;
        }else{
            finalPrice = preData.freeTierDiscount ? amount-preData.freeTierDiscount : amount;
        }
        setAddonsState(
            addonsState.map((item, i) => {
                if(i === indx){
                    item.finalPrice = finalPrice;
                    item.amount = amount;
                    return item;
                }else{
                    return item;
                }
            }
        ));
    }

    const calculateVnfsPrice = (evt, indx) => {
        const { name, value } = evt.target;
        const preData   = vnfsState[indx];
        let amount      = preData.amount;
        let finalPrice  = preData.finalPrice;
        if(name === "costPerMonth"){
            if(value > 0){
                amount = value ? parseFloat(value) : 0;
            }else{
                amount = 0;
            }
        }
        if(name === "freeTierDiscount"){
            finalPrice = value ? amount-parseFloat(value) : amount;
        }else{
            finalPrice = preData.freeTierDiscount ? amount-preData.freeTierDiscount : amount;
        }
        setVnfsState(
            vnfsState.map((item, i) => {
                if(i === indx){
                    item.finalPrice = finalPrice;
                    item.amount = amount;
                    return item;
                }else{
                    return item;
                }
            }
        ));
    }

    const handleChangeTaxes = (evt) => {
        const { name, value } = evt.target;
        if(!value){
            setTotalState({...totalState, [name]: 0});
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        setTotalState({...totalState, [name]: parseFloat(value)});
    }

    const handleChangeUcpes = (evt) => {
        const {name, value} = evt.target;
        let nameArr = name.split(".");
        if(!nameArr.length){
            return null;
        }
        if(!value){
            calculateUcpesPrice(evt, nameArr[0]);
            setUcpesState((prevState) => {
                let newState = {...prevState};
                newState[nameArr[0]] = {...newState[nameArr[0]], [nameArr[1]]: 0};
                return newState;
            });
            return null;
        }
        if(!IsNumeric(value)){
            return null;
        }
        calculateUcpesPrice(evt, nameArr[0]);
        setUcpesState((prevState) => {
            let newState = {...prevState};
            newState[nameArr[0]] = {...newState[nameArr[0]], [nameArr[1]]: value};
            return newState;
        });
    }

    const calculateUcpesPrice = (evt, type) => {
        const { name, value } = evt.target;
        const preData = ucpesState[type];
        let amount = preData.amount;
        let finalPrice = preData.finalPrice;
        if(name === `${type}.costPerMonth`){
            if(value > 0){
                amount = preData.number*parseInt(value);
            }else{
                amount = 0;
            }
        }
        if(name === `${type}.freeTierDiscount`){
            finalPrice = (value) ? amount-parseFloat(value) : amount;
        }else{
            finalPrice = preData.freeTierDiscount ? amount-preData.freeTierDiscount : amount;
        }
        preData.finalPrice = finalPrice;
        preData.amount = amount;
        setUcpesState((prevState) => {
            let newState = {...prevState};
            newState[type] = preData;
            return newState;
        });
    }

    useEffect(() => {
        let totalCost = 0;
        let totalDiscount = 0;
        if(ucpesState.small){
            if(ucpesState.small.amount){
                totalCost = totalCost+parseFloat(ucpesState.small.amount);
            }
            if(ucpesState.small.freeTierDiscount){
                totalDiscount = parseFloat(totalDiscount)+parseFloat(ucpesState.small.freeTierDiscount);
            }
        }
        if(ucpesState.large){
            if(ucpesState.large.amount){
                totalCost = totalCost+parseFloat(ucpesState.large.amount);
            }
            if(ucpesState.large.freeTierDiscount){
                totalDiscount = parseFloat(totalDiscount)+parseFloat(ucpesState.large.freeTierDiscount);;
            }
        }
        if(ucpesState.medium){
            if(ucpesState.medium.amount){
                totalCost = totalCost+parseFloat(ucpesState.medium.amount);
            }
            if(ucpesState.medium.freeTierDiscount){
                totalDiscount = parseFloat(totalDiscount)+parseFloat(ucpesState.medium.freeTierDiscount);;
            }
        }
        if(controllerState.length){
            for(let cnt of controllerState){
                if(cnt.amount){
                    totalCost = totalCost+parseFloat(cnt.amount);
                }
                if(cnt.freeTierDiscount){
                    totalDiscount = totalDiscount+parseFloat(cnt.freeTierDiscount);
                }
            }
        }
        if(vnfsState.length){
            for(let vnfs of vnfsState){
                if(vnfs.amount){
                    totalCost = totalCost+parseFloat(vnfs.amount);
                }
                if(vnfs.freeTierDiscount){
                    totalDiscount = totalDiscount+parseFloat(vnfs.freeTierDiscount);
                }
            }
        }
        if(addonsState.length){
            for(let adns of addonsState){
                if(adns.amount){
                    totalCost = totalCost+parseFloat(adns.amount);
                }
                if(adns.freeTierDiscount){
                    totalDiscount = totalDiscount+parseFloat(adns.freeTierDiscount);
                }
            }
        }
        let totalPayableAmount = totalDiscount ? totalCost-totalDiscount : totalCost;
        if(totalState.taxes){
            totalPayableAmount = totalPayableAmount+totalState.taxes;
        }
        setTotalState({...totalState, totalPayableAmt: totalPayableAmount, totalCost, totalDiscount});
    }, [totalState.taxes, vnfsState, controllerState, addonsState, ucpesState]);

    const calculateControllerPrice = (evt, indx) => {
        const { name, value } = evt.target;
        const preData   = controllerState[indx];
        let finalPrice  = preData.finalPrice;
        let amount      = preData.amount;
        if(name === "costPerHrs"){
            if(value > 0){
                amount = preData.totalHrs*parseFloat(value);
            }else{
                amount = 0;
            }
        }

        if(name === "freeTierDiscount"){
            finalPrice = value ? amount-parseFloat(value) : amount;
        }else{
            finalPrice = preData.freeTierDiscount ? amount-preData.freeTierDiscount : amount;
        }
        setControllerState(
            controllerState.map((item, i) => {
                if(i === indx){
                    item.amount     = amount;
                    item.finalPrice = finalPrice;
                    return item;
                }else{
                    return item;
                }
            }
        ));
    }

    return (
        <div className={classes.root}>
            <Grid container style={{marginBottom: 15}}>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["month", "year"]}
                            value={startDate}
                            size="small"
                            onChange={handleChangeDateFilter}
                            InputProps= {
                                {style: {minWidth: 220, padding: 9}}
                            }
                        />
                    </MuiPickersUtilsProvider>
                    { allowConfigure &&
                        <OutlinedSelect
                            val={currency}
                            label={`${t('currency.label')} *`}
                            handleChange={evt => {
                                handleChangeCurrency(evt.target.value);
                            }}
                            styleOverrides={{ width: '220px', marginRight: 0, marginLeft:10 }}
                            options={ currencyList.length ? currencyList : []}
                        />
                    }
                    {readyState ? invoiceId ? <DownloadPDFButton t={t} invoiceId={invoiceId} invoiceData={{...invoiceBillData, controllerState, vnfsState, ucpesState, addonsState, totalState, currency: selectedCurrency }} userInfo={userInfo} invoiceCompanyDetails={invoiceCompanyDetails} /> : <Button disabled={true} variant="outlined" color="primary" style={{ float: "right", marginRight: 17 }}>{t("download.label")}</Button> : null }
                </Grid>
            </Grid>
            {loadingData ?
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            :
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            {/*************  Controller module **************** */}
                            <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("controllers.label")}</Typography>
                            <Table className={classes.table} aria-label="caption table">
                                <TableBody>
                                    {controllerState.map((item, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell style={{ width:"8%" }} component="th" scope="row">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={key+1}
                                                        disabled={true}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("sNo.label")}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.name}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("controller.label") + " " + t("name.label")}
                                                        style={{ minWidth: "100px" }}
                                                        disabled={true}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("costPerhr.label")}
                                                        style={{ minWidth: "180px" }}
                                                        name="costPerHrs"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        value={item.costPerHrs}
                                                        disabled={notAllowChange}
                                                        onChange={evt => { handleChangeControllerState(evt, key) }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("freeTierDiscount.label")}
                                                        name="freeTierDiscount"
                                                        value={item.freeTierDiscount}
                                                        onChange={evt => { handleChangeControllerState(evt, key) }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        disabled={notAllowChange}
                                                        style={{ minWidth: "150px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("finalPrice.label")}
                                                        value={item.finalPrice}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                        disabled={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {/*************  UCPES module **************** */}
                            {ucpesState &&
                                <React.Fragment>
                                    <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("ucpes.label")}</Typography>
                                    <Table className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            <TableRow key={"small"}>
                                                <TableCell style={{ width:"8%" }} component="th" scope="row">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={1}
                                                        disabled={true}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("sNo.label")}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("numberOfSmallUcpes.label")}
                                                        value={ucpesState.small.number}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        disabled={true}
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.small.number) > 0)}
                                                        value={ucpesState.small.costPerMonth}
                                                        label={t("costPerMonth.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="small.costPerMonth"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.small.number) > 0)}
                                                        value={ucpesState.small.freeTierDiscount}
                                                        label={t("freeTierDiscount.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="small.freeTierDiscount"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("finalPrice.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        value={ucpesState.small.finalPrice}
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={"medium"}>
                                                <TableCell style={{ width:"8%" }} component="th" scope="row">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={2}
                                                        disabled={true}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("sNo.label")}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("numberOfMediumUcpes.label")}
                                                        value={ucpesState.medium.number}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        disabled={true}
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.medium.number) > 0)}
                                                        value={ucpesState.medium.costPerMonth}
                                                        label={t("costPerMonth.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="medium.costPerMonth"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.medium.number) > 0)}
                                                        value={ucpesState.medium.freeTierDiscount}
                                                        label={t("freeTierDiscount.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="medium.freeTierDiscount"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("finalPrice.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        value={ucpesState.medium.finalPrice}
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={"large"}>
                                                <TableCell style={{ width:"8%" }} component="th" scope="row">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={3}
                                                        disabled={true}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        label={t("sNo.label")}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("numberOfLargeUcpes.label")}
                                                        value={ucpesState.large.number}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        disabled={true}
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.large.number) > 0)}
                                                        value={ucpesState.large.costPerMonth}
                                                        label={t("costPerMonth.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="large.costPerMonth"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        disabled={notAllowChange || !(parseInt(ucpesState.large.number) > 0)}
                                                        value={ucpesState.large.freeTierDiscount}
                                                        label={t("freeTierDiscount.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        name="large.freeTierDiscount"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={handleChangeUcpes}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        label={t("finalPrice.label")}
                                                        id="filled-basic"
                                                        variant="filled"
                                                        value={ucpesState.large.finalPrice}
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            }

                            {/*************  VPNS module **************** */}
                            {vnfsState.length > 0 &&
                                <React.Fragment>
                                    <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("vnfs.label")}</Typography>
                                    <Table className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            {vnfsState.map((item, key) => {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell style={{ width:"8%" }} component="th" scope="row">
                                                            <TextField
                                                                InputLabelProps={{style: {fontSize: 12}}}
                                                                size="small"
                                                                value={key+1}
                                                                disabled={true}
                                                                id="filled-basic"
                                                                variant="filled"
                                                                label={t("sNo.label")}
                                                                style={{ minWidth: "100px" }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                InputLabelProps={{style: {fontSize: 12}}}
                                                                size="small"
                                                                value={item.name ? item.name : "-"}
                                                                id="filled-basic"
                                                                variant="filled"
                                                                name="name"
                                                                style={{ minWidth: "100px" }}
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                InputLabelProps={{style: {fontSize: 12}}}
                                                                size="small"
                                                                id="filled-basic"
                                                                variant="filled"
                                                                label={t("costPerMonth.label")}
                                                                style={{ minWidth: "180px" }}
                                                                name="costPerMonth"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                                    ),
                                                                }}
                                                                value={item.costPerMonth}
                                                                disabled={notAllowChange}
                                                                onChange={evt => { handleChangeVnfs(evt, key) }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                InputLabelProps={{style: {fontSize: 12}}}
                                                                size="small"
                                                                id="filled-basic"
                                                                variant="filled"
                                                                label={t("freeTierDiscount.label")}
                                                                name="freeTierDiscount"
                                                                value={item.freeTierDiscount}
                                                                onChange={evt => { handleChangeVnfs(evt, key) }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                                    ),
                                                                }}
                                                                disabled={notAllowChange}
                                                                style={{ minWidth: "150px" }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextField
                                                                InputLabelProps={{style: {fontSize: 12}}}
                                                                size="small"
                                                                id="filled-basic"
                                                                variant="filled"
                                                                label={t("finalPrice.label")}
                                                                value={item.finalPrice}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                                    ),
                                                                }}
                                                                style={{ minWidth: "100px" }}
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            }

                            {/*************  Addons module **************** */}
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography color="primary" style={{marginTop: 30}} variant="h6" component="h6">{t("addOns.label")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {allowConfigure &&
                                        <Button style={{ marginTop: 22, marginRight: 17, float: "right" }} size="medium" variant="contained" color="primary" onClick={handleCreateAddOns}>{t("createAddons.label")}</Button>
                                    }
                                </Grid>
                            </Grid>
                            {addonsState.map((item, index) => {
                                return (
                                    <Table key={index} className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={index+1}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        id="filled-basic"
                                                        label={t("sNo.label")}
                                                        disabled={true}
                                                        variant="filled"
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.moduleName}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        id="filled-basic"
                                                        label={t("moduleName.label")}
                                                        name="moduleName"
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{width: "13%"}}>
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        id="filled-basic"
                                                        name="number"
                                                        value={item.number}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        label={t("number.label")}
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{ minWidth: "80px", marginRight: 8 }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.costPerMonth}
                                                        id="filled-basic"
                                                        name="costPerMonth"
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        label={t("costPerMonth.label")}
                                                        disabled={notAllowChange || !item.number}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.freeTierDiscount}
                                                        id="filled-basic"
                                                        disabled={notAllowChange || !item.number}
                                                        name="freeTierDiscount"
                                                        label={t("freeTierDiscount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        style={{ minWidth: "150px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        size="small"
                                                        value={item.finalPrice}
                                                        id="filled-basic"
                                                        label={t("finalPrice.label")}
                                                        name="finalPrice"
                                                        variant="filled"
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}
                            <Grid container style={{marginTop: 30}}>
                                <Grid item xs={10}>
                                    <Typography variant="subtitle1">{t("taxes.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        disabled={notAllowChange}
                                        value={totalState.taxes}
                                        onChange={handleChangeTaxes}
                                        id="filled-basic"
                                        name="taxes"
                                        label={t("amount.label")}
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 168, maxWidth: 168 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 30}}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">{t("total.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalCost.label")}
                                        variant="filled"
                                        value={totalState.totalCost}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 150, maxWidth: 165 }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalDiscount.label")}
                                        variant="filled"
                                        value={totalState.totalDiscount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 150, maxWidth: 165 }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("toatlPricePayable.label")}
                                        variant="filled"
                                        value={totalState.totalPayableAmt}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 168, maxWidth: 168 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {allowConfigure &&
                        <Grid container className={classes.container}>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={2}>
                                <Button disabled={invoicePaid || saveBtnLoading} style={{ marginTop: 22, width: "90%" }} size="medium" variant="contained" color="primary" onClick={evt => {
                                    if(saveBtnLoading){
                                        return null;
                                    }
                                    handleSubmitInvoice({ totalState, controllerState, ucpesState, addonsState, vnfsState, invoiceId, startDate: new Date(startDate).getTime()/1000 });
                                }}>{!saveBtnLoading ? t("submit.label") : <CircularProgress color="secondary" size={12} /> }</Button>
                            </Grid>
                            {(!invoicePaid && invoiceId) &&
                                <Grid item xs={2}>
                                    <Button disabled={paidBtnLoading} onClick={evt => {
                                        if(paidBtnLoading){
                                            return null;
                                        }
                                        handlePaidInvoice({ status: "Paid", id: invoiceId })
                                    }} style={{marginTop: 22, width: "92%"}} size="medium" variant="contained" color="secondary">{!paidBtnLoading ? t("paid.label") : <CircularProgress color="secondary" size={12} /> }</Button>
                                </Grid>
                            }
                        </Grid>
                    }
                </>
            }
        </div>
    );
};
export default BillingUtilization;
