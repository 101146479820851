import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '12px 4px',
    },
    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    position: "",
    number: "",
    costCode: "",
    userDepartment: "",
    userSection: "",
    labourReqFrom: null,
    labourReqTo: null,
    reasonForRequest: "",
    recommendComment: "",
    orgnaiser: "",
    orgnaiserDate: null,
    sectionHead: "",
    sectionHeadDate: null,
    hrMangerCasul: "",
    typeOfReq: "",
    insertType : [],
    extensionFrom: null,
    extensionTo: null,
    sign: "",
    approver: "",
    HOD: "",
    HODDate: null,
    complaint: "",
    positionExist : "",
    strength : "",
    variance :"",
    approverComment: "",
    approverSign: "",
    validation: "",
    validationSign: "",
    learnDevelopmentComment: "",
    HRManagerCasual: "",
    HRManagerCasualSign: "",
    HRManagerCasualComment: "",
    HRManager: "",
    HRManagerSign: "",
    HRManagerComment: "",
    GeneralManager: "",
    GeneralManagerSign: ""
};


const tableItems = [
    {
        key: 'long_term',
        value: 'Long Term',
        label: "Permanent / Fixed Term (long term)",
        description: "Permanent & FTC 12 months and above"
    },
    {
        key: 'short_term',
        value: 'Short term',
        label: "Fixed Term (short term)",
        description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
    },
    {
        key: 'casual',
        label: "Casual",
        value: "Casual",
        description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
    }
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function LeaveFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [isFormElementShown, setIsFormElementShown] = useState(true);
    const [userDeclaration, setUserDeclaration] = useState(true);
    const [departmentSections, setDepartmentSections] = useState([]);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [showHRManagerCasual, setShowHRManagerCasual] = useState(false);
    const [showHRManager, setShowHRManager] = useState(false);
    const [showGeneralManager, setShowGeneralManager] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const userDepartment = data.userDepartment && data.userDepartment._id
            const userSection = data.userSection && data.userSection.id
            const id = data._id;
            setShowUserSelect((data.createdById === userdata.id) ? true : false);
            setShowValidation((data.createdById === userdata.id) ? true : false);
            setShowHRManagerCasual((data.createdById === userdata.id) ? true : false);
            setShowHRManager((data.createdById === userdata.id) ? true : false);
            setShowGeneralManager((data.createdById === userdata.id) ? true : false);
            setURFormState({...data, userDepartment, userSection, id });
            if(data.userSection){
                setDepartmentSections([data.userSection]);
            }
        }else{
            setShowUserSelect(true);
            setShowValidation(true);
            setShowHRManagerCasual(true);
            setShowHRManager(true);
            setShowGeneralManager(true);
        }
    }, [data]);

    const handleChange = (value, name) => {
        if (name === "insertType") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.insertType.push(value.target.value);
                } else {
                    let index = newState.insertType.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.insertType.splice(index, 1);
                    }
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >PLEASE RECRUIT FOR THE FOLLOWING POSITION/S:</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Position</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Position"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.position}
                                onChange={(evt) => handleChange(evt.target.value, "position")}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>No. Required</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Number Required"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.number}
                                onChange={(evt) => handleChange(evt.target.value, "number")}
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Labour Required From</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ width: '100%', minWidth: '264px'}}
                                label={'Labour Required From'}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.labourReqFrom}
                                handleChange={newDate => handleChange(newDate, "labourReqFrom")}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Labour Required To</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ width: '100%', minWidth: '264px'}}
                                label={'Labour Required To'}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.labourReqTo}
                                handleChange={newDate => handleChange(newDate, "labourReqTo")}
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Department</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <OutlinedSelect
                                options={(departments && departments.length) ? departments : []}
                                label="Department"
                                selectStyle={{ ...paddingGenerator('p-4') }}
                                val={urFormState.userDepartment}
                                handleChange={(evt) => {
                                    const item = departments.find(itm => evt.target.value === itm.id);
                                    setDepartmentSections(item ? item.sections : []);
                                    handleChange(evt.target.value, "userDepartment");
                                }}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Section</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <OutlinedSelect
                                options={(departmentSections && departmentSections.length) ? departmentSections : []}
                                label="Section"
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                val={urFormState.userSection}
                                selectStyle={{ ...paddingGenerator('p-4') }}
                                handleChange={(evt) => {
                                    evt.target.value && evt.target.value._id &&
                                    handleChange(evt.target.value, "userSection");
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Cost Code</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Cost Code"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.costCode}
                                onChange={(evt) => handleChange(evt.target.value, "costCode")}
                            />
                        </Grid>

                        <Grid container style={{ marginTop: 20, paddingLeft: 10 }} >
                            <Grid item xs={12}>
                                <Typography variant="h4" >Type of Request</Typography>
                            </Grid>

                            <Grid item xs={1}></Grid>

                            <Grid container spacing={1} xs={11} className={classes.blackBorder}>
                                <Grid item xs={4}>
                                    <RadioGroup
                                        row
                                        aria-label="typeOfReq"
                                        value={urFormState.typeOfReq}
                                        name="typeOfReq"
                                        onChange={(evt) => {
                                            handleChange(evt.target.value, "typeOfReq");
                                        }}
                                    >
                                        <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="New"
                                            labelPlacement="start"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            label="New"
                                        />
                                        <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="Extension"
                                            labelPlacement="start"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            label="Extension"
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: 220 }}
                                        label={'Extention From'}
                                        dateFormat={"yyyy-MM-dd"}
                                        disabled={urFormState.typeOfReq === 'Extension' ? false : true}
                                        value={urFormState.extensionFrom}
                                        handleChange={newDate => handleChange(newDate, "extensionFrom")}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DateTimeSelector
                                        inputStyle={{ minWidth: 220 }}
                                        label={'Extention To'}
                                        disabled={urFormState.typeOfReq === 'Extension' ? false : true}
                                        dateFormat={"yyyy-MM-dd"}
                                        value={urFormState.extensionTo}
                                        handleChange={newDate => handleChange(newDate, "extensionTo")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} style={{ marginBottom: 20 }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" >INSERT</Typography>
                        </Grid>
                        
                        <Grid container xs={12} className={classes.blackBorder}> 
                            {tableItems && tableItems.map((rowItem, rowIndex) => (
                                <>
                                    <Grid item xs={1} className={classes.blackBorder}>
                                        <FormControlLabel
                                            style={{ marginLeft: '0px' }}
                                            value={rowItem.value}
                                            checked={urFormState.insertType ? urFormState.insertType.includes(rowItem.value) : false}
                                            control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-2')}} />}
                                            labelPlacement="end"
                                            onChange={(evt) => {
                                                handleChange(evt, "insertType");
                                            }}
                                            // label={rowItem.label}
                                        />
                                    </Grid>

                                    <Grid item xs={4} className={classes.blackBorder}>
                                        <h5 className={classes.blackBorderTitle}>{rowItem.label}</h5>
                                    </Grid>

                                    <Grid item xs={7} className={classes.blackBorder} >
                                        <Typography
                                            variant={'subtitle1'}
                                            style={{ cursor: 'pointer', color:'#2e2e2e' }}
                                        >
                                            {rowItem.description ? rowItem.description : "-"}
                                        </Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>

                        {/* <TableContainer>
                            <Table>
                                <TableBody>
                                    {tableItems && tableItems.map((rowItem, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell>
                                                <Grid className={classes.column}>
                                                    <FormControlLabel
                                                        style={{ ...marginGenerator('mb-8') }}
                                                        value={rowItem.value}
                                                        checked={urFormState.insertType ? urFormState.insertType.includes(rowItem.value) : false}
                                                        control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                                        labelPlacement="end"
                                                        onChange={(evt) => {
                                                            handleChange(evt, "insertType");
                                                        }}
                                                        label={rowItem.label}
                                                    />
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid className={classes.column}>
                                                    <Grid>
                                                        <Typography
                                                            variant={'subtitle1'}
                                                            style={{ cursor: 'pointer', color:'#2e2e2e' }}
                                                        >
                                                            {rowItem.description ? rowItem.description : "-"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >REASONS FOR THE REQUEST</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>REASONS FOR THE REQUEST</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                type="textarea"
                                placeholder="Reason For The Request"
                                variant="standard"
                                fullWidth={true}
                                multiline
                                rows={5}
                                onChange={(evt) => handleChange(evt.target.value, "reasonForRequest")}
                                value={urFormState.reasonForRequest}
                            />
                        </Grid>

                        
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Originator</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Originator"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.orgnaiser}
                                onChange={(evt) => handleChange(evt.target.value, "orgnaiser")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ width: '100%', minWidth: '264px'}}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.orgnaiserDate}
                                handleChange={newDate => handleChange(newDate, "orgnaiserDate")}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Section Head</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Section Head"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.sectionHead}
                                onChange={(evt) => handleChange(evt.target.value, "sectionHead")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ width: '100%', minWidth: '264px'}}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.sectionHeadDate}
                                handleChange={newDate => handleChange(newDate, "sectionHeadDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >RECOMMENDED / NOT RECOMMENDED</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Comments</h5>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                type="textarea"
                                placeholder="Reason For The Request"
                                variant="standard"
                                fullWidth={true}
                                multiline
                                rows={5}
                                onChange={(evt) => handleChange(evt.target.value, "recommendComment")}
                                value={urFormState.recommendComment}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Head of Department</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                placeholder={"Head of Department"}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.HOD}
                                onChange={(evt) => handleChange(evt.target.value, "HOD")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeSelector
                                inputStyle={{ width: '100%', minWidth: '264px'}}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.HODDate}
                                handleChange={newDate => handleChange(newDate, "HODDate")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: 20, paddingLeft: 10 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Type of Request</Typography>
                        </Grid>

                        <Grid container xs={12} className={classes.blackBorder}>
                            <RadioGroup
                                row
                                aria-label="positionExist"
                                value={urFormState.positionExist}
                                name="positionExist"
                                onChange={(evt) => {
                                    handleChange(evt.target.value, "positionExist");
                                }}
                            >
                                <FormControlLabel
                                    style={{ ...marginGenerator('mb-8') }}
                                    value="Yes"
                                    labelPlacement="start"
                                    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                    label="Position Exists"
                                />
                                <FormControlLabel
                                    style={{ ...marginGenerator('mb-8') }}
                                    value="No"
                                    labelPlacement="start"
                                    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                    label="Position does not exist"
                                />
                            </RadioGroup>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} style={{ textAlign: 'center' }} className={classes.blackBorder}>
                                <h5 style={{ margin: '10px' }}>Complement</h5>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }} className={classes.blackBorder}>
                                <h5 style={{ margin: '10px' }}>Strength</h5>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }} className={classes.blackBorder}>
                                <h5 style={{ margin: '10px' }}>Variance</h5>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} className={classes.blackBorder}>
                                <Input
                                    type={'text'}
                                    placeholder={"Complement"}
                                    style={{ width: '100%' }}
                                    disabled={urFormState.positionExist === 'Yes' ? false : true}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={urFormState.complaint}
                                    onChange={(evt) => handleChange(evt.target.value, "complaint")}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.blackBorder}>
                                <Input
                                    type={'text'}
                                    placeholder={"Strength"}
                                    style={{ width: '100%' }}
                                    disabled={urFormState.positionExist === 'Yes' ? false : true}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={urFormState.strength}
                                    onChange={(evt) => handleChange(evt.target.value, "strength")}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.blackBorder}>
                                <Input
                                    type={'text'}
                                    placeholder={"Variance"}
                                    style={{ width: '100%' }}
                                    disabled={urFormState.positionExist === 'Yes' ? false : true}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={urFormState.variance}
                                    onChange={(evt) => handleChange(evt.target.value, "variance")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showUserSelect || data.approver === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >APPROVED / NOT APPROVED (Production/Commercial departments only)</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >APPROVED / NOT APPROVED (Production/Commercial departments only)</Typography>
                            </Grid>}
                        {showUserSelect &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="Approver"
                                    disabled={(data && data.approverSign) ? true : false}
                                    val={urFormState.approver}
                                    handleChange={(evt) => handleChange(evt.target.value, "approver")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.approver === userdata.id) ? (
                            <>
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("approverSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="textarea"
                                    placeholder="Approver Comment"
                                    variant="standard"
                                    fullWidth={true}
                                    multiline
                                    rows={5}
                                    onChange={(evt) => handleChange(evt.target.value, "approverComment")}
                                    value={urFormState.approverComment}
                                />
                            </Grid>
                            </>
                        ) : null}
                        {((signatures && signatures.approverSign) || (data && data.approverSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.approverSign) ? data.approverSign : signatures.approverSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showValidation || data.validation === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >VALIDATION</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >VALIDATION</Typography>
                            </Grid>}
                        {showValidation &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="Validation"
                                    disabled={(data && data.validationSign) ? true : false}
                                    val={urFormState.validation}
                                    handleChange={(evt) => handleChange(evt.target.value, "validation")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.validation === userdata.id) ? (
                            <>
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("validationSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="textarea"
                                    placeholder="Validation Comment"
                                    variant="standard"
                                    fullWidth={true}
                                    multiline
                                    rows={5}
                                    onChange={(evt) => handleChange(evt.target.value, "validationComment")}
                                    value={urFormState.validationComment}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Input
                                    type={'text'}
                                    placeholder={"Human Resorce Official"}
                                    style={{ width: '100%' }}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={urFormState.hrMangerCasul}
                                    onChange={(evt) => handleChange(evt.target.value, "hrMangerCasul")}
                                />
                            </Grid>
                            </>
                        ) : null}
                        {((signatures && signatures.validationSign) || (data && data.validationSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.validationSign) ? data.validationSign : signatures.validationSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>   

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showHRManagerCasual || data.HRManagerCasual === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >HUMAN RESOURCES MANAGER’S APPROVAL (Casuals and Short term only)</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >HUMAN RESOURCES MANAGER’S APPROVAL (Casuals and Short term only)</Typography>
                            </Grid>}
                        {showHRManagerCasual &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="HR Manager Casual"
                                    disabled={(data && data.HRManagerCasualSign) ? true : false}
                                    val={urFormState.HRManagerCasual}
                                    handleChange={(evt) => handleChange(evt.target.value, "HRManagerCasual")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.HRManagerCasual === userdata.id) ? (
                            <>
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("HRManagerCasualSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="textarea"
                                    placeholder="Head Of Department Comment"
                                    variant="standard"
                                    fullWidth={true}
                                    multiline
                                    rows={5}
                                    onChange={(evt) => handleChange(evt.target.value, "HRManagerCasualComment")}
                                    value={urFormState.HRManagerCasualComment}
                                />
                            </Grid>
                            </>
                        ) : null}
                        {((signatures && signatures.HRManagerCasualSign) || (data && data.HRManagerCasualSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.HRManagerCasualSign) ? data.HRManagerCasualSign : signatures.HRManagerCasualSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showHRManager || data.HRManager === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >RECOMMENDED / NOT RECOMMENDED (FTCs and Permanent positions)</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >RECOMMENDED / NOT RECOMMENDED (FTCs and Permanent positions)</Typography>
                            </Grid>}
                        {showHRManager &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="HR Manager"
                                    disabled={(data && data.HRManagerSign) ? true : false}
                                    val={urFormState.HRManager}
                                    handleChange={(evt) => handleChange(evt.target.value, "HRManager")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.HRManager === userdata.id) ? (
                            <>
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("HRManagerSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="textarea"
                                    placeholder="HR Manager Comment"
                                    variant="standard"
                                    fullWidth={true}
                                    multiline
                                    rows={5}
                                    onChange={(evt) => handleChange(evt.target.value, "HRManagerComment")}
                                    value={urFormState.HRManagerComment}
                                />
                            </Grid>
                            </>
                        ) : null}
                        {((signatures && signatures.HRManagerSign) || (data && data.HRManagerSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.HRManagerSign) ? data.HRManagerSign : signatures.HRManagerSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showGeneralManager || data.GeneralManager === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >GENERAL MANAGERS APPROVAL (Permanent positions only)</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >GENERAL MANAGERS APPROVAL (Permanent positions only)</Typography>
                            </Grid>}
                        {showGeneralManager &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="General Manager"
                                    disabled={(data && data.GeneralManagerSign) ? true : false}
                                    val={urFormState.GeneralManager}
                                    handleChange={(evt) => handleChange(evt.target.value, "GeneralManager")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.GeneralManager === userdata.id) ? (
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("GeneralManagerSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        {((signatures && signatures.GeneralManagerSign) || (data && data.GeneralManagerSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.GeneralManagerSign) ? data.GeneralManagerSign : signatures.GeneralManagerSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!urFormState.position || !urFormState.userDepartment || !urFormState.userSection){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateRequestSubmit(urFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

LeaveFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(LeaveFormModal);