import * as React from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SERVICE_TICKETS } from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { Paths, ServiceTicketPaths } from '../../routes/routePaths';
import CustomTabs from '../../components/CustomTabs';
import { useState } from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Flag from '@material-ui/icons/Flag';
import DNS from '@material-ui/icons/Dns';
import { getUrl } from '../../utils';
import { useHistory } from 'react-router-dom';
import TicketPanel from './components/TicketPanel';
import { STATUS_TYPES, TICKET_PRIORITY } from '../../utils/constants';
import { GET_SUBSCRIPTION_LIST } from '../Subscriptions/queries';
import { CLOSE_SERVICE_TICKET } from './mutations';
import Message from '../../components/Message';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'serviceTickets.label', link: Paths.ServiceTickets }
];

export const getSubscriptionOptions = list => {
  return list.map((item, index) => {
    return { id: item.id, name: item.service.name };
  });
};

const ServiceTicketLists = ({ t, navigation }) => {
  const [status, setStatus] = useState('');
  const [priority, setPriority] = useState('');
  const [openPriority, setOpenPriority] = useState('');
  const [closedPriority, setClosedPriority] = useState('');
  const [subscription, setSubscription] = useState('');
  const [openSubscription, setOpenSubscription] = useState('');
  const [closedSubscription, setClosedSubscription] = useState('');

  const [updatedRecentTickets, setUpdatedRecentTickets] = useState([]);
  const [updatedOpenTickets, setUpdatedOpenTickets] = useState([]);
  const [updatedClosedTickets, setUpdatedClosedTickets] = useState([]);

  const [recentTicketsSearched, setRecentTicketsSearched] = useState(false);
  const [openTicketsSearched, setOpenTicketsSearched] = useState(false);
  const [closedTicketsSearched, setClosedTicketsSearched] = useState(false);

  const history = useHistory();

  /*
     List Queries
    */
  const {
    loading: serviceTicketLoading,
    error,
    data: serviceTickets,
    refetch: reloadServiceTicket
  } = useQuery(GET_SERVICE_TICKETS);

  const {
    loading: openLoading,
    error: openError,
    data: openServiceTickets,
    refetch: reloadOpenTicket
  } = useQuery(GET_SERVICE_TICKETS, {
    variables: { status: STATUS_TYPES.OPEN }
  });
  const {
    loading: closedLoading,
    error: closedError,
    data: closedServiceTickets,
    refetch: reloadCloseTicket
  } = useQuery(GET_SERVICE_TICKETS, {
    variables: { status: STATUS_TYPES.CLOSED }
  });
  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
    data: subscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST);

  /*
    Filter queries
   */
  const [getRecentTicketFilteredList] = useLazyQuery(GET_SERVICE_TICKETS, {
    onCompleted: data => {
      setRecentTicketsSearched(true);
      setUpdatedRecentTickets(data.servicetickets);
    }
  });

  const [getOpenTicketFilteredList] = useLazyQuery(GET_SERVICE_TICKETS, {
    onCompleted: data => {
      setOpenTicketsSearched(true);
      setUpdatedOpenTickets(data.servicetickets);
    }
  });

  const [getClosedTicketFilteredList] = useLazyQuery(GET_SERVICE_TICKETS, {
    onCompleted: data => {
      setClosedTicketsSearched(true);
      setUpdatedClosedTickets(data.servicetickets);
    }
  });

  /*
    Filter Queries
   */

  const filterListRecentTickets = [
    {
      label: 'Status',
      iconComponent: CheckCircle,
      handleChange: evt => {
        const statusVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            status: statusVal,
            ...(priority ? { priority } : {})
          }
        });

        setStatus(statusVal);
      },
      val: status,
      options: [STATUS_TYPES.OPEN, STATUS_TYPES.CLOSED]
    },
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            priority: priorityVal,
            ...(status ? { status } : {})
          }
        });

        setPriority(priorityVal);
      },
      val: priority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getRecentTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(priority ? { priority } : {}),
            ...(status ? { status } : {})
          }
        });

        setSubscription(subscriptionVal);
      },
      val: subscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const filterListOpen = [
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            priority: priorityVal,
            status: STATUS_TYPES.OPEN
          }
        });

        setOpenPriority(priorityVal);
      },

      val: openPriority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getOpenTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(openPriority ? { priority: openPriority } : {}),
            status: STATUS_TYPES.OPEN
          }
        });

        setOpenSubscription(subscriptionVal);
      },
      val: openSubscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const filterClosed = [
    {
      label: 'Priority',
      iconComponent: Flag,
      handleChange: evt => {
        const priorityVal = evt.target.value;

        getClosedTicketFilteredList({
          variables: {
            priority: priorityVal,
            status: STATUS_TYPES.CLOSED
          }
        });

        setClosedPriority(priorityVal);
      },
      val: closedPriority,
      options: [
        TICKET_PRIORITY.HIGH,
        TICKET_PRIORITY.MEDIUM,
        TICKET_PRIORITY.LOW
      ]
    },
    {
      label: 'Subscription',
      iconComponent: DNS,
      handleChange: evt => {
        const subscriptionVal = evt.target.value;

        getClosedTicketFilteredList({
          variables: {
            subscriptionId: subscriptionVal,
            ...(closedPriority ? { priority: closedPriority } : {}),
            status: STATUS_TYPES.CLOSED
          }
        });

        setClosedSubscription(subscriptionVal);
      },
      val: closedSubscription,
      options:
        !subscriptionsError && !subscriptionsLoading
          ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
          : [],
      styleOverrides: { minWidth: '172px' }
    }
  ];

  const [closeServiceTicket] = useMutation(CLOSE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      Message.success('Ticket closed successfully.');
      reloadServiceTicket();
      reloadOpenTicket();
      reloadCloseTicket();
    }
  });

  const handleTicketItemClick = id => {
    const path = getUrl(ServiceTicketPaths.Details, id);
    history.push(path);
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
      return closeServiceTicket({
        variables: { id }
      });
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(id);
    }
  };

  const handleSearchRecentTickets = query => {
    if (query !== '') {
      const updatedList =
        serviceTickets.servicetickets &&
        serviceTickets.servicetickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase())
        );
      setRecentTicketsSearched(true);
      setUpdatedRecentTickets(updatedList);
    } else {
      setRecentTicketsSearched(false);
      setUpdatedRecentTickets([]);
    }
  };

  const handleSearchOpenTickets = query => {
    if (query !== '') {
      const updatedList =
        openServiceTickets.servicetickets &&
        openServiceTickets.servicetickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase())
        );
      setUpdatedOpenTickets(updatedList);
      setOpenTicketsSearched(true);
    } else {
      setOpenTicketsSearched(false);
      setUpdatedOpenTickets([]);
    }
  };

  const handleSearchClosedTickets = query => {
    if (query !== '') {
      const updatedList =
        closedServiceTickets.servicetickets &&
        closedServiceTickets.servicetickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase())
        );
      setClosedTicketsSearched(true);
      setUpdatedClosedTickets(updatedList);
    } else {
      setClosedTicketsSearched(false);
      setUpdatedClosedTickets([]);
    }
  };

  const handleRaiseNewTicketClick = () => {
    history.push(ServiceTicketPaths.NewServiceTicket);
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('serviceTickets.label')}
        subText={`${
          openServiceTickets && openServiceTickets.servicetickets
            ? openServiceTickets.servicetickets.length
            : 0
        } ${t('openTickets.label')} • ${
          updatedRecentTickets ? updatedRecentTickets.length : 0
        } ${t('updatedTickets.label')}`}
        primaryButtonText={t('raiseNewTicket.label')}
        handlePrimaryButtonClick={handleRaiseNewTicketClick}
      />
      <CustomTabs
        tabs={[
          {
            label: t('recentTickets.label'),
            children: (
              <>
                {!serviceTicketLoading && !error && serviceTickets && (
                  <TicketPanel
                    ticketList={
                      recentTicketsSearched
                        ? updatedRecentTickets
                        : serviceTickets.servicetickets
                    }
                    type={'recentTickets'}
                    t={t}
                    handleMenuItemClick={handleMenuItemClick}
                    filterList={filterListRecentTickets}
                    handleTicketItemClick={handleTicketItemClick}
                    handleSearch={handleSearchRecentTickets}
                  />
                )}
              </>
            )
          },
          {
            label: t('openTickets.label'),
            children: (
              <>
                {!openLoading && !openError && openServiceTickets && (
                  <TicketPanel
                    ticketList={
                      openTicketsSearched
                        ? updatedOpenTickets
                        : openServiceTickets.servicetickets
                    }
                    type={'openTickets'}
                    t={t}
                    handleMenuItemClick={handleMenuItemClick}
                    filterList={filterListOpen}
                    handleTicketItemClick={handleTicketItemClick}
                    handleSearch={handleSearchOpenTickets}
                  />
                )}
              </>
            )
          },
          {
            label: t('closedTickets.label'),
            children: (
              <>
                {!closedLoading && !closedError && closedServiceTickets && (
                  <TicketPanel
                    ticketList={
                      closedTicketsSearched
                        ? updatedClosedTickets
                        : closedServiceTickets.servicetickets
                    }
                    type={'closedTickets'}
                    t={t}
                    handleMenuItemClick={handleMenuItemClick}
                    filterList={filterClosed}
                    handleTicketItemClick={handleTicketItemClick}
                    handleSearch={handleSearchClosedTickets}
                  />
                )}
              </>
            )
          }
        ]}
      />
    </Layout>
  );
};
export default withTranslation()(ServiceTicketLists);
