import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, withStyles } from '@material-ui/core';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../../../components/hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { getStatusForLinkType } from '../../../../../../services/clientService';
import IPut from 'iput';
import { getPortById } from '../../../../../../services/portService';
import {
  checkVlanAvailability,
  getAvailableVlansByPodId
} from '../../../../../../services/connectionService';
import VlansPopup from '../Popups/VlansPopup';
import { isIP } from 'is-ip';
import { useAppContext } from '../../../../../../contexts/AppContext';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    height: '43px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const PortToFcr = props => {
  const { classes, handlePrev, handleNext, setState, getSubnet } = props;
  const [linkTypeStatus, setLinkTypeStatus] = useState('');
  const [availableVlans, setAvailableVlans] = useState([]);
  const [sourcePodId, setSourcePodId] = useState('');
  const [openVlanPopup, setOpenVlanPopup] = useState(false);
  const [messageAEnd, setMessageAEnd] = useState('');
  const [nextStepAllowed, setNextStepAllowed] = useState(false);
  const [subnetIsValid, setSubnetIsValid] = useState(true);
  const [aEndIsValid, setAEndIsValid] = useState(false);

  const { state } = useAppContext();

  const initialValues = {
    connectionName: '',
    rateLimit: '',
    A_end: '',
    MinimumTerm: 'standard',
    clientName: ''
  };

  const validationSchema = yup.object().shape({
    connectionName: yup.string().required('This field is required'),
    clientName: yup.string().required('This field is required'),
    rateLimit: yup
      .number()
      .typeError('Enter a value between 0 & 1000')
      .min(0, 'Enter a value between 0 & 1000')
      .max(1000, 'Enter a value between 0 & 1000')
      .required('This field is required'),
    A_end: yup
      .number()
      .typeError('This field is required')
      .required('This field is required'),
    MinimumTerm: yup.string().required('This field is required')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const { handleSubmit, setValue, control, watch } = methods;

  const onSubmit = data => {
    setState({ data });
    handleNext();
  };

  const handleChange = val => {
    getSubnet(val);
    if (isIP(val)) {
      setSubnetIsValid(true);
      setNextStepAllowed(true);
    } else {
      setSubnetIsValid(false);
      setNextStepAllowed(false);
    }
  };

  const GetStatusForLinkType = async () => {
    const payload = {
      source: state.sourcePortDetails.sourcePortId,
      destination: state.destinationFcrDetails.destinationFcrId,
      link_type: 'port-fcr'
    };
    const res = await getStatusForLinkType(payload);
    setLinkTypeStatus(res?.data?.result);
    if (res?.data?.result === 'Local') {
      setValue('MinimumTerm', 'local');
    }
  };

  const A_end = watch('A_end');

  const checkAendVlan = async () => {
    try {
      const payload = {
        a_end_port_id: state.sourcePortDetails.sourcePortId,
        b_end_port_id: state.destinationFcrDetails.destinationFcrId,
        a_end_vlan_id: A_end
      };
      const res = await checkVlanAvailability(payload);
      if (res?.status === 200) {
        setMessageAEnd(res?.data?.message || res?.message);
        setAEndIsValid(true);
      } else {
        setMessageAEnd(
          res?.response?.data?.message || res?.message || res?.data?.message
        );
        setAEndIsValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const GetVlans = async id => {
    if (id) {
      setOpenVlanPopup(true);
      const res = await getAvailableVlansByPodId(id);
      setAvailableVlans(res?.data?.result);
    }
  };

  const getPodIdOfSourcePort = async () => {
    const sourcePortId = state.sourcePortDetails.sourcePortId;
    const res = await getPortById(sourcePortId);
    setSourcePodId(res?.data?.result?.pod?._id);
  };

  useEffect(() => {
    if (A_end) checkAendVlan();
  }, [A_end]);

  useEffect(() => {
    GetStatusForLinkType();
    getPodIdOfSourcePort();
    setValue('clientName', state?.clientDetails?.clientName);
  }, [linkTypeStatus, setValue]);

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '10px', width: '500px' }}>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Connection Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="connectionName"
                label="Connection Name"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <Grid item sm={3}>
              Client Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="clientName"
                size="small"
                autoComplete="off"
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Subnet
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={1}
              sm={8}>
              <Grid item sm={7}>
                <IPut onChange={handleChange} />
              </Grid>
              <Grid item sm={5}>
                /30
              </Grid>
            </Grid>
            {!subnetIsValid && (
              <span sx={{ color: 'red' }}>Enter a valid IP Address</span>
            )}
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Rate Limit
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="rateLimit"
                label="Rate Limit (Mbps)"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Link Type
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              {linkTypeStatus === 'Local' ? (
                <RHFTextField
                  variant="outlined"
                  name="MinimumTerm"
                  size="small"
                  disabled
                />
              ) : (
                <RHFSelect name="MinimumTerm" variant="outlined" size="small">
                  <option value="">Select</option>
                  {[
                    {
                      key: 'Standard Unprotected',
                      value: 'standard-unprotected'
                    },
                    {
                      key: 'Premium Unprotected',
                      value: 'premium-unprotected'
                    },
                    { key: 'Standard Protected', value: 'standard-protected' }
                  ].map(item => (
                    <option key={item.key} value={item?.value}>
                      {item?.key}
                    </option>
                  ))}
                </RHFSelect>
              )}
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              A-end V-LAN
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              sm={8}
              spacing={1}
              justify="center"
              alignItems="center">
              <Grid item sm={8}>
                <Controller
                  name="A_end"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      label="A-end V-LAN"
                      autoComplete="off"
                      fullWidth
                      value={
                        typeof field.value === 'number' && field.value === 0
                          ? ''
                          : field.value
                      }
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                {A_end && (
                  <span
                    style={{
                      color: messageAEnd === 'Available' ? '#000' : 'red'
                    }}>
                    {messageAEnd}
                  </span>
                )}
              </Grid>
              <Grid item sm={4}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => GetVlans(sourcePodId)}>
                  Check Availability
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box p={2}>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Box>
            <Box p={2}>
              <Button
                type="submit"
                disabled={
                  nextStepAllowed && aEndIsValid && A_end !== null
                    ? false
                    : true
                }
                variant="contained"
                size="medium"
                color="primary">
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      {openVlanPopup && (
        <VlansPopup
          openVlanPopup={openVlanPopup}
          setOpenVlanPopup={setOpenVlanPopup}
          availableVlans={availableVlans}
          setAvailableVlans={setAvailableVlans}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(PortToFcr);
