import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '0.5px solid black',
        outline: '0.5px solid',
        padding: '0px !important',
    },
    // blackBorderTitle:{
    //     margin: '8px 0px',
    // },
    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    //   id: "",
        payeeName: "",
        payeePRN: "",
        paymentPurpose: "",
        costCode: "",
        amount: "",
        amountinWords: "",
        printName: "",
        signature: "",
        requisitionerDate: null,
        printName2: "",
        signature2: "",
        authoriserDate: null,
        bankName: "",
        accNumber: "",
        branchCode: "",
        swiftCode: "",
        paymentDate: null,
        paymentSignature: "",
        costcodeAllocation: "",
        reviewer: "",
        authoriser: "",
        processedBy: "",
        jnlNo: ""
    };

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function PaymentRequisitionFormModal({
    t,
    open,
    data,
    modalState,
    userdata,
    handleClose,
    handleCreatePaymentRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

  const classes = useStyles();
  const [paymentRequisitionFormState, setPaymentRequisitionFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
      if (modalState.clearData && open) {
          setPaymentRequisitionFormState({ ...urInitFields });
          handleClose();
      }
  }, [modalState]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        setPaymentRequisitionFormState({...data, id});
    }
}, [data]);


  const handleChange = (value, field) => {
        setPaymentRequisitionFormState({ ...paymentRequisitionFormState, [field]: value });
  }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
              <Grid className={classes.blackBorder} item xs={12} style={{ marginBottom: 30 }}>
                  <FormGroup row>
                    <Grid className={classes.blackBorder} item xs={6}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                        RECORD NO: FINA/ACC/PR/002 & 004/FORM/04</h5>
                      </Grid>
                        <Grid className={classes.blackBorder} container  xs={6}>
                          <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                            PAGE 1 OF 1</h5>
                      </Grid>
                      <Grid className={classes.blackBorder} item xs={6}>
                        <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                          REVIEW PERIOD: 2 YEARS</h5>
                    </Grid>
                      <Grid className={classes.blackBorder} container  xs={6}>
                        <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                          FIRST ISSUE DA TE: OCTOBER 1999</h5>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                        EFFECTIVE DA TE: FEBRUAR Y 2019</h5>
                  </Grid>
                    <Grid className={classes.blackBorder} container  xs={6}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                        REVISION NO: 6</h5>
                  </Grid>
                </FormGroup>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: 20 }} >
                      <Grid item xs={12}>
                          <Typography variant="h4" style={{ textAlign: "center", fontFamily: 'sans-serif', fontSize: '20px'}} >PAYMENT REQUISITION FORM</Typography>
                          <hr />
                      </Grid>

                      <Grid  item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}} >Payee Name</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.payeeName}
                              onChange={(evt) => handleChange(evt.target.value, "payeeName")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Payee PRN/ID</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.payeePRN}
                              onChange={(evt) => handleChange(evt.target.value, "payeePRN")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Purpose of Payment:</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.paymentPurpose}
                              onChange={(evt) => handleChange(evt.target.value, "paymentPurpose")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Cost Code/Project</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.costCode}
                              onChange={(evt) => handleChange(evt.target.value, "costCode")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Amount</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.amount}
                              onChange={(evt) => handleChange(evt.target.value, "amount")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Amount in words</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={paymentRequisitionFormState.amountinWords}
                            onChange={(evt) => handleChange(evt.target.value, "amountinWords")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                          <Typography variant="h4" style={{fontFamily: 'sans-serif'}} >Requisitioner</Typography>
                          <hr />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Print Name</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.printName}
                              onChange={(evt) => handleChange(evt.target.value, "printName")}
                          />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Signature</h5>
                      </Grid>
                      <Grid  item xs={9}>
                        <IconButton style={{ marginLeft: 20, marginBottom: 20 }}
                            variant={'outlined'}
                            onClick={() => handleOpenSignPanModal("signature")}
                            >
                            Sign here  <img src={SignIcon} width="46" height={26} alt=""/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={2}>
                          <h5  style={{fontFamily: 'sans-serif'}}>Date</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            dateFormat={"yyyy-MM-dd"}
                            value={paymentRequisitionFormState.requisitionerDate}
                            handleChange={newDate => handleChange(newDate, "requisitionerDate")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                          <Typography variant="h4" style={{ textAlign: "left", fontFamily: 'sans-serif'}} >Authoriser</Typography>
                          <hr />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Print Name</h5>
                      </Grid>
                      <Grid item xs={9}>
                              <Input
                                  type={'text'}
                                  style={{ width: '100%' }}
                                  className={classes.input}
                                  color={'secondary'}
                                  value={paymentRequisitionFormState.printName2}
                                  onChange={(evt) => handleChange(evt.target.value, "printName2")}
                              />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Signature</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <IconButton style={{ marginLeft: 20, marginBottom: 20 }}
                            variant={'outlined'}
                            onClick={() => handleOpenSignPanModal("signature2")}
                            >
                            Sign here  <img src={SignIcon} width="46" height={26} alt=""/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Date</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            dateFormat={"yyyy-MM-dd"}
                            value={paymentRequisitionFormState.authoriserDate}
                            handleChange={newDate => handleChange(newDate, "authoriserDate")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                          <Typography variant="h4" style={{fontFamily: 'sans-serif'}} >Payee's banking details:</Typography>
                          <hr />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Name of Bank</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.bankName}
                              onChange={(evt) => handleChange(evt.target.value, "bankName")}
                          />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Account Number</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.accNumber}
                              onChange={(evt) => handleChange(evt.target.value, "accNumber")}
                          />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Branch Code</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.branchCode}
                              onChange={(evt) => handleChange(evt.target.value, "branchCode")}
                          />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Swift Code</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.swiftCode}
                              onChange={(evt) => handleChange(evt.target.value, "swiftCode")}
                          />
                      </Grid>

                      <Grid item xs={12}>
                          <Typography variant="h4" style={{fontFamily: 'sans-serif'}} >Payment Acknowledged:</Typography>
                          <hr />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Name</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.paymentName}
                              onChange={(evt) => handleChange(evt.target.value, "paymentName")}
                          />
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Signature</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <IconButton style={{ marginLeft: 20, marginBottom: 20 }}
                            variant={'outlined'}
                            onClick={() => handleOpenSignPanModal("paymentSignature")}
                            >
                            Sign here  <img src={SignIcon} width="46" height={26} alt=""/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Date</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            dateFormat={"yyyy-MM-dd"}
                            value={paymentRequisitionFormState.paymentDate}
                            handleChange={newDate => handleChange(newDate, "paymentDate")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                      </Grid>

                  <p style={{color: "#6f7070", fontSize:'16px', fontfamily:'sans-serif', fontWeight: '400', marginBottom: -10 }}>
                    <br/>For Financial Services use only.
                    <br/>
                    <br/>VOUCHER No.:
                      </p>
                    <Grid item xs={11} style={{ fontSize: '15px', marginBottom: 20}}>
                      <Input
                          type={'text'}
                          style={{ width: '100%', border: '0px', outline: 'none'}}
                          className={classes.input}
                          color={'secondary'}
                          value={paymentRequisitionFormState.voucherNo}
                          onChange={(evt) => handleChange(evt.target.value, "voucherNo")}
                      />
                    </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Cost Code Allocation</h5>
                      </Grid>
                      <Grid item xs={9}>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={paymentRequisitionFormState.costcodeAllocation}
                            onChange={(evt) => handleChange(evt.target.value, "costcodeAllocation")}
                        />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Reviewed by:</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.reviewer}
                              onChange={(evt) => handleChange(evt.target.value, "reviewer")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Authorised by:</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.authoriser}
                              onChange={(evt) => handleChange(evt.target.value, "authoriser")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Processed/Prepared by:</h5>
                      </Grid>
                      <Grid item xs={9}>
                          <Input
                              type={'text'}
                              style={{ width: '100%' }}
                              className={classes.input}
                              color={'secondary'}
                              value={paymentRequisitionFormState.processedBy}
                              onChange={(evt) => handleChange(evt.target.value, "processedBy")}
                          />
                      </Grid>

                      <Grid item xs={2}>
                          <h5 style={{fontFamily: 'sans-serif'}}>Jnl No.</h5>
                      </Grid>
                      <Grid item xs={9}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={paymentRequisitionFormState.jnlNo}
                                onChange={(evt) => handleChange(evt.target.value, "jnlNo")}
                            />
                          </Grid>
                      </Grid>
                      <p style={{color: "#6f7070", fontSize:'16px', fontfamily:'sans-serif', fontWeight: '300'}}>
                        <br/>Note: Petty cash requests must not exceed P500.00 or R2, 000.00
                        <br/>
                      </p>
            </DialogContent>

            <DialogActions>
                <Grid container justify={'space-between'}>
                  <Button
                      variant={'outlined'}
                      onClick={() => {
                      setPaymentRequisitionFormState({ ...urInitFields });
                      handleClose();
                  }}
                  >
                  {t('cancel.label')}
                  </Button>
                  <ButtonComponent
                  t={t}
                  onClick={() => {
                      if(!paymentRequisitionFormState.payeeName){
                          Message.error(REQUIRED_ERROR);
                          return null;
                      }
                      handleCreatePaymentRequestSubmit(paymentRequisitionFormState);
                  }}
                  loading={modalState.btnLoading}
                  />
                </Grid>
                </DialogActions>
        </Dialog>
    );
}

PaymentRequisitionFormModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreatePaymentRequestSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(PaymentRequisitionFormModal);
