import moment from 'moment';
import 'moment-timezone';
import { USER_ROLES } from './constants';

export const getUrl = (url, id) => {
  return url.replace(':id', `:${id}`);
};
export const getParam = () => {
  return window.location.pathname.split(':')[1];
};
export const getIdParam = () => {
  if (window.location) {
    if (window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get('id');
    }
  }
}
/*
  Date Formatters
*/
export const dateFormatterLLL = input => moment(input).format('LLL');
export const dateFormatterLLLHHMMSS = input => moment(input).format("ll hh:mm:ss A");
export const dateFormatterDDMMMYYYYHMS = input =>
  moment(input).format('DD-MM-YYYY HH:mm:ss');
export const dateFormatterDDMMMYYYY = input =>
  moment(input).format('DD MMM YYYY');
export const dateFormatterYYYYMMDD = input =>
  moment(input).format('YYYY-MM-DD');
export const dateFormatterHHMMSS = input => moment(input).format('HH:mm:ss');
export const dateFormatterDDMMMYYYYHHSS = input =>
  moment(input).format('dddd, MMMM Do YYYY, h:mm:ss a');
export const convertUTCDateToLocalDate = date => {
  date = new Date(date);
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;
};
export const convertLocalToUTCDate = date => {
  let now = new Date(date);
  return moment.utc(now).format('YYYY-MM-DD HH:mm:ssZ');
};
export const convertLocalToUTCDateNew = date => {
  let now = new Date(date);
  return moment.utc(now).format('YYYY-MM-DD HH:mm:ss');
};
export const dateFormatterWithTimeZoneLLLHHMMSS = (input, timeZone) => {
  return moment.tz(input, timeZone ? timeZone : 'Etc/GMT+2').utc().format('ll HH:mm:ss A');
}

export const timeFormatterWithTimeZoneLLLHHMMSS = (input, timeZone) => {
  let now = new Date(input);
  return moment.tz(now, timeZone ? timeZone : 'Etc/GMT+2').utc().format('HH:mm');
};

export const onLogout = () => {
  localStorage.removeItem('syncomToken');
  localStorage.removeItem('syncomRefreshToken');
  localStorage.removeItem('orgId');
  localStorage.removeItem('orgName');
  localStorage.removeItem('country');
  localStorage.removeItem('countryId');
  localStorage.removeItem('role');
  localStorage.removeItem("logo_url");
  localStorage.removeItem("org_type");
  localStorage.removeItem('domain');
  localStorage.removeItem('showInqLogo');
  localStorage.removeItem("logo_url");
  localStorage.removeItem("login_logo");
  localStorage.removeItem("activeTicketTab");
  window.location.replace('/');
};
export const getRegularCase = item => {
  return item.length ? item.charAt(0).toUpperCase() + item.slice(1) : '';
};
export const generatePassword = length => {
  var passwd = '';
  var chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$*';
  for (let i = 0; i < length; i++) {
    var c = Math.floor(Math.random() * chars.length + 1);
    passwd += chars.charAt(c);
  }
  return passwd;
};
export const getStartEndTimeForReport = filter => {
  // ['Today', 'Yesterday', 'Last week', 'Last month'];
  let date = new Date();
  const interval = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
  if (filter === 'Yesterday') {
    date = new Date(date.setDate(date.getDate() - 1));
    let startOfDay = Math.floor(date.getTime() / interval) * interval;
    let endOfDay = Math.floor((startOfDay + interval - 1) / 1000);
    startOfDay = Math.floor(startOfDay / 1000);
    return { startTime: startOfDay, endTime: endOfDay };
  } else if (filter === 'Last week') {
    let curr = new Date(date);
    let lastDate = new Date(curr.setDate(curr.getDate() - 7));
    let startOfDay = Math.floor(lastDate.getTime() / 1000);
    let endOfDay = Math.floor(date.getTime() / interval) * interval;
    endOfDay = Math.floor(endOfDay / 1000);
    return { startTime: startOfDay, endTime: endOfDay };
  } else if (filter === 'Last month') {
    let curr = new Date(date);
    let lastDate = new Date(curr.setDate(curr.getDate() - 30));
    let startOfDay = Math.floor(lastDate.getTime() / 1000);
    let endOfDay = Math.floor(date.getTime() / interval) * interval;
    endOfDay = Math.floor(endOfDay / 1000);
    return { startTime: startOfDay, endTime: endOfDay };
  } else if (filter === 'Last_10_Days') {
    let curr = new Date(date);
    let lastDate = new Date(curr.setDate(curr.getDate() - 10));
    let startOfDay = Math.floor(lastDate.getTime() / 1000);
    let endOfDay = Math.floor(date.getTime() / interval) * interval;
    endOfDay = Math.floor(endOfDay / 1000);
    return { startTime: startOfDay, endTime: endOfDay };
  } else {
    let startOfDay = Math.floor(date.getTime() / interval) * interval;
    let endOfDay = Math.floor((startOfDay + interval - 1) / 1000);
    startOfDay = Math.floor(startOfDay / 1000);
    return { startTime: startOfDay, endTime: endOfDay };
  }
};

export const convertLocalToUTC = time => {
  let now = new Date(time);
  return moment.utc(now).format('HH:mm');
};

export const formatHHMM = time => {
  let now = new Date(time);
  return moment(now).format('HH:mm');
};

export const convertUTCToLocal = time => {
  let stillUtc = moment.utc(time).toDate();
  return moment(stillUtc)
    .local()
    .format('HH:mm');
};

export const checkValidEmail = email => {
  if (!email) return false;
  // const regx = /^([\w-.]+@(?!gmail\.)(?!outlook\.")(?!rediffmail\.")(?!yopmail\.)(?!yahoo\.)(?!hotmail\.)([\w-]+.)+[\w-]{2,4})?$/;
  // return regx.test(email.toLowerCase());
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const checkValidURL = userInput => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res != null;
};
export const getEventImageUrl = url => {
  if (url.match(/inq-ai-notify.s3.af-south-1.amazonaws.com/g)) {
    return url;
  }
  return `http://34.91.31.158${url}`;
};
export const checkUserSettingAccess = roles => {
  if (!roles) {
    return false;
  }
  if (
    roles.includes(USER_ROLES.EP_FINANCE) ||
    roles.includes(USER_ROLES.EP_IT) ||
    roles.includes(USER_ROLES.IP_USER) ||
    roles.includes(USER_ROLES.IP_USER) ||
    roles.includes(USER_ROLES.IP_COUNTRYADMIN) ||
    roles.includes(USER_ROLES.IP_SUPERADMIN) ||
    roles.includes(USER_ROLES.IP_VNFADMIN)
  ) {
    return false;
  } else if (roles.includes(USER_ROLES.EP_ADMIN)) {
    return true;
  }
  return false;
};

export const IsNumeric = num => /^[0-9]+([.][0-9]+)?$/.test(num);

export const removeSpacialCharecter = text => {
  return text.replace(/[&\/\\#,+()$~%.'":*?<>{}!@^|;_`]/g, '');
};
export const convertPriceFormat = x => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
export const GenerateRandomString = length => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const evaluate = (param1, param2, operator) => {
  return eval(param1 + operator + param2);
}

export const getRandomHEXColor = () => {
  const SEED = '0123456789abcdef';
  let output = '#';
  while (output.length < 7) {
    output += SEED[Math.floor(Math.random() * SEED.length)];
  }
  return output;
}

export const getDifferenceInMinutes = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1);
  return parseInt(Math.round(diffInMs / (1000 * 60)));
}

export const renderEdgeAITags = (tags) => {
  if (!tags) return "-";
  let tagName = "";
  if (Object.keys(tags).toString() === 'type') {
    tagName = Object.values(tags).toString();
  } else {
    tagName = Object.keys(tags).toString();
  }
  if (Object.keys(tags).includes("P1") || Object.keys(tags).includes("P2")) {
    return "person";
  }
  if (tagName === 'driver_activity_recognition' && tags.driver_activity_recognition) {
    if (tags.driver_activity_recognition instanceof Array) {
      tagName = tags.driver_activity_recognition.join(", ");
    }
  }
  if (Object.keys(tags).includes("custom_object")) {
    tagName = JSON.stringify(Object.values(tags)[0], null, 2);
    if (Object.values(tags)[0]["objs"] || Object.values(tags)[0]["bbox"]) {
      const objs = Object.values(tags)[0]["objs"];
      const bbox = Object.values(tags)[0]["bbox"];
      tagName = Object.assign(Object.keys(objs), Object.keys(bbox));
    }
  }
  return tagName ? tagName : "-";
};

export const JSONToCSVConvertor = (JSONData, ReportTitle) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";
  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "INQ_";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;
  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const encodeCameraRTSPURL = (url) => {
  let password = url;
  if (!url) return url;
  let arr = url.split(":");
  if (arr.length > 2) {
    let arr2 = arr[2].split("@");
    arr2.splice(arr2.length - 1, 1);
    password = arr2.join('@');
  }
  let encodedPass = password.replace(/[!@$^#~'()*]/g, (c) => {
    return '%' + c.charCodeAt(0).toString(16)
  });
  return url.replace(password, encodedPass);
}

export const getBase64FromUrl = async (url) => {
  console.log("URL:", url);
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

export const getOffsetByTimeZone = (timeZone) => {
  try {
    let isNegative = false;
    const timeZoneName = Intl.DateTimeFormat("ia", {
      timeZoneName: "short",
      timeZone
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
    const offset = timeZoneName.slice(3);
    if (!offset) return 0;
    const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
    if (!matchData) return null;
    const [, sign, hour, minute] = matchData;
    let result = parseInt(hour) * 60;
    if (sign === "+") isNegative = true;
    if (minute) result += parseInt(minute);
    return (isNegative && minute) ? -result : result;
  } catch (e) {
    return 0;
  }
};

export const getScheduleTimeByTimeZone = (time, timeZone) => {
  if (!timeZone) return time;

  let date = new Date(moment(new Date()).format(`YYYY-MM-DD ${time}:00`));
  let localTime = date.getTime();
  let offset = getOffsetByTimeZone(timeZone) / 60;
  let newTimeOffset = localTime + 3600000 * offset;
  return moment(new Date(newTimeOffset)).format("HH:mm");
}

export const isOtherPlatform = () => {
  const currentDns = window.location.hostname;
  let isOther = true;
  if (currentDns === 'platform-staging.inq.inc') {
    isOther = false;
  } else if (currentDns === 'platform.inq.inc') {
    isOther = false;
  } else if (currentDns === 'localhost') {
    // isOther = false;
  }
  return isOther;
}

export const getAppTitle = (hostName) => {
  const dnsArr = hostName?.split('.');
  if (dnsArr?.[0] === 'platform') {
    return dnsArr?.[1] || "inq";
  }
  return dnsArr?.[0] || 'inq';
}

export function getFaviconEl() {
  return document.getElementById("favicon");
}

export function getFileExtension(filename) {
  if (!filename) {
    return '';
  }
  let parts = filename.split('.');
  return parts[parts.length - 1];
}



const getPosition = () => {
  return new Promise((res, rej) => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(res, rej);
    }
  });
}

export const getMyLocation = async () => {
  let position = await getPosition()
  return {
    lat: `${position.coords.latitude}`,
    long: `${position.coords.longitude}`,
  }
}

export const isSuperUser = (localStorageRole) => {
  if (
    localStorageRole.includes(USER_ROLES.IP_SUPERADMIN) ||
    localStorageRole.includes(USER_ROLES.EP_ADMIN) ||
    localStorageRole.includes(USER_ROLES.IP_COUNTRYADMIN)
  ) {
    return true
  }
  else {
    return false
  }
}



export const formatName = name => name
  .replace(/_/g, ' ')  // Replace underscores with spaces
  .replace(/(^\w|\s\w)/g, m => m.toUpperCase()); 