import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {paddingGenerator, marginGenerator   } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});


function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const DialogTitle = withStyles(styles)((props) => {
    const { t, children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

function CreateLiveCamModal({
    t,
    open,
    data,
    handleAddURLSubmitClick,
    handleClose,
    modalState
}) {

    const [urls, setUrls] = useState([{name: '', url:''}]);
    
    const handleAddNewCameraURL = () => {
        setUrls(oldArray => [...oldArray, {name: '', url: ''}]);
    }

    const handleChangeInput = (value, idx, column) => {
		const updatedCamera = [...urls];
		updatedCamera[idx][column] = value;
		setUrls(updatedCamera);
    }

    useEffect(() => {
        if(data.liveCamUrls && data.liveCamUrls.length){
            setUrls(data.liveCamUrls);
        } 
    }, [data]);
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle t={t} id="place-template-title">{t('add.label')} {t('url.label')}
            <Button
            variant={'outlined'}
            style={{float:'right'}}
            onClick={() => {
                handleAddNewCameraURL();
            }}>
                {t('addNew.label')}
            </Button>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    { urls.map((item, index) => {
                        return (
                        <React.Fragment key={index}>
                            <Grid item xs={12} style={{...paddingGenerator('pb-0')}}>
                                <Typography variant={'subtitle2'} style={{position:'relative'}}>
                                    {t('camera.label')} {index+1}
                                    <Icon
                                    component={() => (
                                        <DeleteForeverIcon
                                            color={'secondary'}
                                            style={{ marginLeft:'10px', cursor: 'pointer', position:'absolute', top:'-6px' }}
                                            onClick={() => {
                                                const updatedCamera = [...urls];
                                                updatedCamera.splice(index, 1);
                                                setUrls(updatedCamera);
                                            }}
                                        />
                                    )} 
                                    alt={'icon'} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4}
                                style={{
                                    ...paddingGenerator('pb-30')
                                }}>
                                <Input
                                    value={item.name}
                                    type={'text'}
                                    placeholder={`${t('camera.label')} ${t('name.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={ (evt) => {
                                        handleChangeInput(evt.target.value, index, 'name');
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7}
                                style={{
                                    ...paddingGenerator('pb-30'),
                                    ...marginGenerator('ml-20')
                                }}>
                                <Input
                                    value={item.url}
                                    type={'url'}
                                    placeholder={`${t('embed.label')} ${t('url.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={ (evt) => {
                                        handleChangeInput(evt.target.value, index, 'url');
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!urls.length){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        let validate = true;
                        for(let i = 0; i < urls.length; i++){
                            if(!urls[i].name || !urls[i].url){
                                validate = false;
                            }
                        }
                        if(!validate){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleAddURLSubmitClick('liveCam', {urls});
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateLiveCamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleAddURLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateLiveCamModal);
