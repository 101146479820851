import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import ChartContainer from '../.././../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../.././../../../../theme/utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GenericTable from '../../../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { dateFormatterLLL } from '../../../../../../utils';
import CreateBidModal from './CreateBidModal';
import CompareIcon from '@material-ui/icons/Compare';
import CompareDocModal from '../Quotes/RefDocModal';
import SelectedCompareModal from '../Quotes/SelectedCompareModal';
import { GET_ALL_BIDS, GET_QUOTE_DETAILS_BY_BID, GET_ALL_QUOTES } from '../../queries';
import { CREATE_BID } from '../../mutation';
import Message from '../../../../../../components/Message';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WinnerQuoteModal from './WinnerQuoteModal';

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2),
        position: "relative",
        overflow: "hidden"
    },
    dateInput: {
        width: 215,
        minWidth: 200,
        padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    }
}));

const Bids = ({ t, handleReloadBid }) => {

    const classes = useStyles();
    const [bidList, setBidList] = useState([]);
    const [bidFilterState, setBidFilterState] = useState({ search: "", status: "", page: 1, limit: 20, startDate: null, endDate: null });
    const [openCreateBidModal, setOpenCreateBidModal] = useState(false);
    const [compareModelOpen, setCompareModelOpen] = useState(false);
    const [selectedCompareModelOpen, setSelectedCompareModelOpen] = useState(false);
    const [winnerQuoteModalOpen, setWinnerQuoteModalOpen] = useState(false);
    const [bidModalState, setBidModalState] = useState({ btnLoading: false, clearData: false });
    const [selectedBidItem, setSelectedBidItem] = useState(null);
    const [winnerQuoteDetails, setWinnerQuoteDetails] = useState(null);
    const [quoteList, setQuoteList] = useState([]);

    const [getWinnerQuoteDetailsByBid, { loading: winnerLoading }] = useLazyQuery(GET_QUOTE_DETAILS_BY_BID, {
        fetchPolicy: "no-cache",
        onError: (e) => {
            Message.error(e);
            setWinnerQuoteModalOpen(false);
            setSelectedBidItem(null);
        },
        onCompleted: (res) => {
            setWinnerQuoteDetails(res.getWinnerQuoteDetailsByBid)
        }
    })

    const handleViewWinner = (bidItem) => {
        setWinnerQuoteModalOpen(true);
        setSelectedBidItem(bidItem);
        return getWinnerQuoteDetailsByBid({
            variables: {
                bidId: bidItem.id
            }
        });
    }

    const handleOpenPdfUrl = (url) => {
        window.open(url, { target: "_blank" });
    }

    const [getAllBids, { loading }] = useLazyQuery(GET_ALL_BIDS, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setBidList((res.getBids && res.getBids.length) ? res.getBids.map(itm => {
                itm.action = <>
                    <IconButton onClick={() => handleViewWinner(itm)} aria-label="view">
                        <VisibilityIcon color="primary" />
                    </IconButton>
                    <IconButton disabled={itm.file_url ? false : true} onClick={evt => handleOpenPdfUrl(itm.file_url)} style={{ marginRight: 10 }} aria-label="print">
                        <PrintIcon color="primary" />
                    </IconButton>
                    <IconButton style={{ marginRight: 10 }} aria-label="edit">
                        <EditIcon color="primary" />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <DeleteIcon color="secondary" />
                    </IconButton>
                </>;
                itm.compare = <>
                    <IconButton onClick={() => handleCompareClick(itm)} aria-label="delete">
                        <CompareIcon color="primary" />
                    </IconButton>
                </>;
                itm.created_at = dateFormatterLLL(itm.created_at);
                return itm;
            }) : []);
        }
    });

    const [getAllQuotes, { loading: quoteLoading }] = useLazyQuery(GET_ALL_QUOTES, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setQuoteList((res.getQuotes && res.getQuotes.length) ? res.getQuotes : []);
        }
    });

    const [createBid] = useMutation(CREATE_BID, {
        onError: (e) => {
            Message.error(e);
            setBidModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: (res) => {
            setBidModalState({ btnLoading: false, clearData: true });
            handleReloadBid();
            return getAllBids({
                variables: {
                    ...bidFilterState
                }
            });
        }
    });

    React.useEffect(() => {
        return getAllBids({
            variables: {
                ...bidFilterState
            }
        });
    }, []);

    const handleCompareClick = (bid) => {
        if (bid) {
            setCompareModelOpen(true);
            return getAllQuotes({
                variables: {
                    search: "",
                    bidId: bid.id 
                }
            });
        }
        return null;
    }

    const bidFilterList = [
        {
            label: "Search By Name",
            handleChange: (evt) => setBidFilterState({ ...bidFilterState, search: evt.target.value }),
            val: bidFilterState.search,
            type: "text",
            styleOverrides: { marginRight: 24, marginTop: 10 },
            className: classes.dateInput
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => setBidFilterState({ ...bidFilterState, startDate: newDate }),
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => setBidFilterState({ ...bidFilterState, endDate: newDate }),
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            type: "select",
            label: t('status.label'),
            handleChange: (evt) => setBidFilterState({ ...bidFilterState, status: evt.target.value }),
            val: bidFilterState.status,
            options: ["Pending", "Open", "Completed"],
            styleOverrides: { width: 180, marginTop: 10 }
        }
    ];

    const columns = [
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Uploaded On",
            key: "created_at"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Due Date",
            key: "due_date"
        },
        {
            label: "Compare",
            key: "compare"
        },
        {
            label: "Action",
            key: "action"
        }
    ];

    const handleCreateBidSubmit = (formData) => {
        setBidModalState({ btnLoading: true, clearData: false });
        delete formData.fileValue;
        return createBid({ variables: { ...formData } });
    }

    return (
        <div style={{ width: "100%", display: "inline-block" }}>
            <ChartContainer
                customMargin={{ marginTop: 0 }}
                filterList={bidFilterList}
                filterSubmit={evt => {
                    console.log("bidFilterState: ", bidFilterState);
                    return getAllBids({
                        variables: {
                            ...bidFilterState
                        }
                    });
                }}
                createNewButton={() => setOpenCreateBidModal(true)}
                createLabel="Create New Bid">
            </ChartContainer>
            <Grid
                container
                style={marginGenerator('mt-25')}>
                <GenericTable loading={loading} list={bidList} columns={columns} />
            </Grid>
            <CreateBidModal
                open={openCreateBidModal}
                handleClose={evt => {
                    setOpenCreateBidModal(false);
                }}
                modalState={bidModalState}
                handleCreateBidSubmit={handleCreateBidSubmit}
            />
            <CompareDocModal
                open={compareModelOpen}
                quotes={quoteList}
                handleClose={() => {
                    setCompareModelOpen(false);
                    setQuoteList([]);
                }}
                loading={quoteLoading}
                handleOpenCompareModal={() => {
                    setSelectedCompareModelOpen(true);
                    setCompareModelOpen(false);
                }}
            />
            <SelectedCompareModal
                open={selectedCompareModelOpen}
                handleClose={() => {
                    setSelectedCompareModelOpen(false);
                }}
            />
            <WinnerQuoteModal
                open={winnerQuoteModalOpen}
                data={winnerQuoteDetails}
                loading={winnerLoading}
                handleClose={evt => {
                    setWinnerQuoteModalOpen(false);
                    setWinnerQuoteDetails(null);
                    setSelectedBidItem(null);
                }}
            />
        </div>
    );
};

Bids.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default Bids;
