import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TicketActive from '../../../../assets/icons/ticket_active.png';
import TicketInactive from '../../../../assets/icons/ticket_inactive.png';
import commonStyles from '../../../../theme/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { STATUS_TYPES, TICKET_PRIORITY } from '../../../../utils/constants';
import { dateFormatterLLL, convertUTCDateToLocalDate } from '../../../../utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import TicketStatus from '../TicketStatus';
import Avatar from '../../../../components/Avatar';
import FlagWarning from './../../../../assets/icons/flag_warning.png';
import FlagError from './../../../../assets/icons/flag_error.png';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';

const useStyles = makeStyles(theme => ({
  ticketContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    flexGrow: 1,
    cursor: 'pointer'
  },
  closedTitle: {
    color: theme.palette.grey[300]
  },
  updateIndicator: {
    height: '6px',
    width: '6px',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    background: theme.palette.success.main,
    borderRadius: '50%'
  },
  TopBar: {
    display: 'flex'
  }
}));

const TicketItem = ({
  t,
  type,
  data,
  handleTicketItemClick,
  handleMenuItemClick,
  selectedCloseTicketsId,
  exportTicketId,
  uniqueId,
  handleExportMultipleTickets,
  handleCloseMultipleTickets
}) => {
  const commonClasses = commonStyles();
  const classes = useStyles();
  const userInfoAttributes = data.userInfo && data.userInfo.attributes != ''
    ? JSON.parse(data.userInfo.attributes)
    : [];
  const [anchorEl, setAnchorEl] = useState(null);
  let menus = [{ label: t('viewDetails.label') }];
  if (data.status === STATUS_TYPES.OPEN || data.status === STATUS_TYPES.INPROGRESS || data.status === STATUS_TYPES.HOLD) {
    menus.push({ label: t('closeTicket.label') });
  }

  const handleChange = (val, event) => {
    handleCloseMultipleTickets(val, event);
  };

  const handleExport = (val, event) => {
    handleExportMultipleTickets(val, event);
  };

  const handleOverDueTickets = createdAt => {
    const currentDate = new Date(Date.now()).getTime();
    let ticketOverDue = '';
    const ticketCreatedDate = new Date(createdAt).getTime();
    let diffInMilliSeconds = (currentDate - ticketCreatedDate) / 1000;
    let hrs = Math.abs(Math.floor(diffInMilliSeconds / 3600) % 24);
    let minDiff = (currentDate - ticketCreatedDate) / 1000;
    let minutes = Math.abs(Math.floor(minDiff / 60) % 60);
    let Difference_In_Time =
      new Date(currentDate).getTime() - new Date(ticketCreatedDate).getTime();
    let days = Difference_In_Time / (1000 * 3600 * 24);
    if (days < 1 && hrs < 4) {
      ticketOverDue = 'NORMAL';
      return ticketOverDue;
    } else if (days < 1 && hrs > 3 && hrs < 12) {
      ticketOverDue = 'CRITICAL';
      return ticketOverDue;
    } else if (days < 1 && hrs > 11 && hrs < 24) {
      ticketOverDue = 'URGENT';
      return ticketOverDue;
    } else if (days > 1 || hrs > 23) {
      ticketOverDue = 'HIGH';
      return ticketOverDue;
    }
  };

  const checkBoxFunction = () => {
    if (type && (type === 'openTickets' || type === 'newTickets')) {
      if (uniqueId && uniqueId === 'exportTicket') {
        return (
          <Grid item>
            <Checkbox
              checked={exportTicketId.includes(data.id)}
              value={data.id}
              onChange={event => handleExport(data.id, event)}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item>
            <Checkbox
              checked={selectedCloseTicketsId.includes(data._id)}
              value={data._id}
              onChange={event => handleChange(data._id, event)}
            />
          </Grid>
        );
      }
    }
  };

  return (
    <div className={classes.TopBar}>
      {checkBoxFunction()}
      <Grid
        container
        style={{ ...paddingGenerator('pt-8'), ...paddingGenerator('pb-24') }}
        className={classes.ticketContainer}
        onClick={() => handleTicketItemClick(data._id)}>
        <Grid item>
          <img
            src={
              data.status === STATUS_TYPES.CLOSED ? TicketInactive : TicketActive
            }
            alt={'ticket'}
            style={marginGenerator('mt-8')}
            className={commonClasses.iconMedium}
          />
        </Grid>
        <Grid item style={marginGenerator('ml-24')}>
          <Typography
            variant={'h4'}
            style={{ maxWidth: '950px' }}
            className={
              data.status === STATUS_TYPES.CLOSED ? classes.closedTitle : ''
            }>
            {data.title}
            {moment(data.updatedAt).isSame(moment(), 'day') && (
              <sup>
                <div className={classes.updateIndicator} />
              </sup>
            )}
          </Typography>
          <Typography variant={'subtitle2'}>
            {t('id.label').toUpperCase()} {data.ticketId} •{' '}
            {userInfoAttributes &&
              userInfoAttributes != null &&
              userInfoAttributes.CircuitID && userInfoAttributes.CircuitID[0] ? (
              <>
                {t('circuitId.label').toUpperCase()}{':'}{" "}
                {userInfoAttributes.CircuitID[0]} •{' '}
              </>
            ) : null}
            {t('ticketRaisedOn.label')} {dateFormatterLLL(data.createdAt)} •{' '}
            {t('incidentCause.label')} {data.incidentCause}
          </Typography>
          <Grid
            container
            item
            className={classes.playerContainer}
            style={marginGenerator('mt-16')}
            alignItems={'center'}>
            <Avatar name={data.createdByName} />
            <Typography variant={'subtitle2'}>
              {t('createdBy.label')} {data.createdByName}{' '}
            </Typography>
            {data.userInfo != null ? (
              <Typography variant={'subtitle2'}>
                &nbsp;• {t('customer.label')}{' '}
                {`${data.userInfo && data.userInfo.firstName
                    ? data.userInfo.firstName
                    : ''
                  } ${data.userInfo && data.userInfo.lastName
                    ? data.userInfo.lastName
                    : ''
                  }`}{' '}
              </Typography>
            ) : null}

            {data.assignee != null ? (
              <Typography variant={'subtitle2'}>
                &nbsp;• {t('ticketAssignee.label')} {data.assignee}{' '}
              </Typography>
            ) : null}
          </Grid>

          {data.status === STATUS_TYPES.OPEN || data.status === STATUS_TYPES.INPROGRESS || data.status === STATUS_TYPES.HOLD ? (
            <Typography style={marginGenerator('mt-16')} variant={'subtitle2'}>
              {t('ticketOverdue.label')} {handleOverDueTickets(data.createdAt)}
            </Typography>
          ) : null}

          {data.status === STATUS_TYPES.CLOSED && (
            <Typography style={marginGenerator('mt-16')} variant={'subtitle2'}>
              {t('closedOn.label')} {dateFormatterLLL(data.updatedAt)}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs
          container
          justify={'flex-end'}
          alignItems={'flex-start'}
          style={marginGenerator('mt-8')}>
          <TicketStatus status={data.status} t={t} />
          {data.priority === TICKET_PRIORITY.LOW ||
            data.priority === TICKET_PRIORITY.URGENT ||
            data.priority === TICKET_PRIORITY.CRITICAL ||
            data.priority === TICKET_PRIORITY.MEDIUM ||
            data.priority === TICKET_PRIORITY.HIGH ? (
            <img
              src={
                data.priority === TICKET_PRIORITY.HIGH
                  ? FlagError
                  : data.priority === TICKET_PRIORITY.MEDIUM
                    ? FlagWarning
                    : data.priority === TICKET_PRIORITY.LOW
                      ? FlagWarning
                      : FlagError
              }
              style={marginGenerator('ml-16')}
              className={commonClasses.iconMedium}
              alt={data.priority}
            />
          ) : (
            <div style={marginGenerator('ml-16')} />
          )}
          <IconButton
            size={'small'}
            disableRipple={true}
            disableFocusRipple={true}
            color={'secondary'}
            style={marginGenerator('ml-32')}
            onClick={evt => {
              setAnchorEl(evt.currentTarget);
              evt.stopPropagation();
            }}
          // className={classes.secondaryButtonStyle}
          >
            <MoreHoriz className={commonClasses.iconRegular} />
          </IconButton>
        </Grid>
        {anchorEl && (
          <MenuWrapper
            id={'ticket-menu'}
            anchorEl={anchorEl}
            handleClose={evt => {
              evt.stopPropagation();
              setAnchorEl(null);
            }}
            menuItems={menus}
            handleMenuItemClick={item => {
              handleMenuItemClick(item, data.ticketId, data._id);
              setAnchorEl(null);
            }}
          />
        )}
      </Grid>
    </div>
  );
};

TicketItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleTicketItemClick: PropTypes.func.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(TicketItem);
