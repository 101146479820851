import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { TicketingSystem } from '../../../routes/routePaths';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from '../../../components/Header';
import { dateFormatterLLL, getUrl } from '../../../utils';
import { useHistory } from 'react-router';
import CustomerTable from './components/CustomerTable';
import Statcard from '../../../components/Statcard';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_LIST } from '../queries';
import {
  CSOC_DASHBOARD_STATS,
  GET_ALL_SUBSCRIPTION_AND_TICKETS
} from './queries';
import { ALL_SERVICE_TICKETS } from './queries';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { marginGenerator } from '../../../theme/utils';
import { colors } from '../../../theme/colors';
import { STATUS_TYPES, IS_PARENT, USER_ROLES } from '../../../utils/constants';
import CsocPieChartWrapper from '../../../components/Charts/CsocPieChartWrapper';
import TicketPanel from '../../ServiceTickets/components/TicketPanel';
import ticket_active from '../../../assets/icons/ticket_active.png';
import ticket_inactive from '../../../assets/icons/ticket_inactive.png';
import LinearProgress from '@material-ui/core/LinearProgress';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Dashboard }
];

const StatPanel = ({ t, statList, label }) => {
  return (
    <div>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-8')
        }}>
        {label}
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          ...marginGenerator('mb-25')
        }}>
        {statList.map((item, index) => (
          <Statcard
            key={index}
            statLabel={item.label}
            icon={item.icon}
            statValue={item.value}
          />
        ))}
      </Grid>
    </div>
  );
};

export const RecentTicketsPanel = ({
  t,
  ticketList,
  handleTicketItemClick,
  handleMenuItemClick
}) => {
  const ticketListUpd = ticketList.slice(0, 5);
  return (
    <div>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-32'),
          ...marginGenerator('mb-8')
        }}>
        {t('recentTickets.label')}
      </Typography>
      <TicketPanel
        handleTicketItemClick={handleTicketItemClick}
        ticketList={ticketListUpd}
        type={'recentTickets'}
        filterList={[]}
        handleMenuItemClick={handleMenuItemClick}
        t={t}
        search={false}
      />
    </div>
  );
};

export const DashboardStatsPanel = ({ t, data, type }) => {
  return (
    <div>
      <CsocPieChartWrapper data={data} type={type} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  msg: { fontSize: '15px', marginLeft: '25px', paddingBottom: '50px' }
}));

const TicketingDashboard = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const role = localStorage.getItem('role');
  const [selectedCustomer, setSelectedCustomer] = React.useState({});
  const [showCustomer, setShowCustomer] = React.useState(false);
  // const { loading: customerLoading, data: users } = useQuery(GET_USER_LIST);
  const {
    loading: subsTicketLoading,
    error: subsTicketError,
    data: allServiceticketsAndSubscriptions
  } = useQuery(GET_ALL_SUBSCRIPTION_AND_TICKETS);

  const {
    loading: serviceTicketLoading,
    error: serviceTicketError,
    data: serviceTickets,
    refetch: reloadOpenTicket
  } = useQuery(ALL_SERVICE_TICKETS, {
    variables: { status: STATUS_TYPES.OPEN, isParent: IS_PARENT.TRUE }
  });

  const openTickets =
    !serviceTicketLoading && !serviceTicketError && serviceTickets.dashboardRecentTickets;

  const {
    loading: csocDashboardStatLoading,
    error: csocDashboardStatError,
    data: csocDashboardStat
  } = useQuery(CSOC_DASHBOARD_STATS);

  const csocDashboard =
    !csocDashboardStatLoading &&
    !csocDashboardStatError &&
    csocDashboardStat.csocDashboardStat;

  const chartData = [
    { name: 'Total Tickets', value: csocDashboard.totalTicketCount },
    { name: 'Open Tickets', value: csocDashboard.openTicketCount },
    { name: 'Closed Tickets', value: csocDashboard.closeTicketCount },
    {
      name: role.includes(USER_ROLES.CSOC_MANAGER) || role.includes(USER_ROLES.SD_READONLY) ? 'UnAssigned Tickets' : '',
      value: role.includes(USER_ROLES.CSOC_MANAGER) || role.includes(USER_ROLES.SD_READONLY)
        ? csocDashboard.unassignedTicketCount
        : ''
    }
  ];

  const subscriptionChart =
    !subsTicketLoading &&
    !subsTicketError &&
    allServiceticketsAndSubscriptions.allServiceticketsAndSubscriptions.map(
      data => {
        return {
          name: data.name,
          value: data.count,
          id: data.id
        };
      }
    );

  const ticketStatList =
    !csocDashboardStatLoading && !csocDashboardStatError
      ? [
        {
          label: t('totalTickets.label'),
          value: csocDashboard.totalTicketCount,
          icon: ticket_active
        },
        {
          label: t('openTickets.label'),
          value: csocDashboard.openTicketCount,
          icon: ticket_active
        },
        {
          label: t('closedTickets.label'),
          value: csocDashboard.closeTicketCount,
          icon: ticket_inactive
        },
        {
          label: t('unAssignedTicket.label'),
          value: csocDashboard.unassignedTicketCount,
          icon: ticket_active
        }
      ]
      : [];

  let ticketStatListing;
  if (
    role.includes(USER_ROLES.CSOC_MANAGER) ||
    role.includes(USER_ROLES.SD_READONLY) ||
    role.includes(USER_ROLES.IP_SUPERADMIN)
  ) {
    ticketStatListing = ticketStatList;
  } else {
    ticketStatListing = ticketStatList.splice(0, 3);
  }

  const handleTicketItemClick = id => {
    const path = getUrl(TicketingSystem.Details, id);
    history.push({ pathname: path, search: `?history=dashboard` });
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(id);
    }
  };

  const CustomerColumn = [
    { name: 'CircuitID' },
    { name: 'Name' },
    { name: 'Email' },
    { name: 'Location' },
    { name: 'Vendor' },
    { name: 'Base Station' },
    { name: 'Configured DN CIR' },
    { name: 'Configured UP CIR' },
    { name: 'Platform' },
    { name: 'Organization' },
    { name: 'Role' },
    { name: 'Status' }
  ];

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('dashboard.label')}
        subText={`${t('statsLastUpdated.label')} ${dateFormatterLLL(
          Date.now()
        )}`}
      />

      {!csocDashboardStatLoading && !csocDashboardStatError && (
        <StatPanel
          t={t}
          statList={ticketStatListing}
          label={t('overview.label')}
        />
      )}

      {!serviceTicketLoading && !serviceTicketError && (
        <RecentTicketsPanel
          t={t}
          ticketList={openTickets}
          handleTicketItemClick={handleTicketItemClick}
          handleMenuItemClick={handleMenuItemClick}
        />
      )}
      {serviceTicketLoading && !serviceTicketError && (
        <LinearProgress
          color="primary"
          style={{
            top: '98px',
            left: '0px',
            position: 'absolute',
            width: '100%'
          }}
        />
      )}
      <div className={classes.root}>
        <Typography
          variant={'h4'}
          style={{
            ...marginGenerator('mt-32'),
            ...marginGenerator('mb-8'),
            borderBottom: `1px solid ${colors.grey[200]}`
          }}>
          {t('TicketStat.label')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <DashboardStatsPanel t={t} data={chartData} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <DashboardStatsPanel
                t={t}
                type={'click'}
                data={subscriptionChart}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/* <div className={classes.root}>
        <Typography
          variant={'h4'}
          style={{
            ...marginGenerator('mt-32'),
            ...marginGenerator('mb-8'),
            borderBottom: `1px solid ${colors.grey[200]}`
          }}>
          {t('customerInfo.label')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              id="country-select-demo"
              val={selectedCustomer}
              options={users ? users.usersByCountryId : []}
              getOptionLabel={option =>
                `${
                  option.attributes.CircuitID
                    ? option.attributes.CircuitID[0]
                    : ''
                } ${option.firstName ? option.firstName : ""} ${option.lastName ? option.lastName : ""}`
              }
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setShowCustomer(false);
                  setSelectedCustomer({});
                }
                setSelectedCustomer(newValue);
                setShowCustomer(true);
              }}
              loading={customerLoading}
              autoHighlight
              style={{ width: '94%' }}
              renderInput={params => (
                <TextField
                  style={{
                    width: '100%',
                    border: '1px solid #ebebeb !important'
                  }}
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {customerLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {showCustomer ? (
              <CustomerTable data={selectedCustomer} column={CustomerColumn} />
            ) : (
              <div className={classes.msg}>{t('allCustomerDetails.label')}</div>
            )}
          </Grid>
        </Grid>
      </div> */}
    </Layout>
  );
};
export default withTranslation()(TicketingDashboard);
