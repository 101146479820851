import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import {useState} from 'react';
import UcpeItems from "./SFCItems";
import SFCItems from "./SFCItems";

function SFCPanel({
                       t,
                       menuItems,
                       list,
                       handleCreateButtonClick,
                       handleMenuItemClick,
                       loading,
                       error
                   }) {
    const columns = [
       /* {
            label: t('sNo.label'),
            key: 'SL.No'
        }, */{
            label: t('bridgeType.label'),
            key: 'BridgeName'
        }, {
            label: t('BridgeName.label'),
            key: 'BridgeName'
        }
    ];
    if (!loading && !error) {
        list = list.getSFC && list.getSFC.length ? list.getSFC : [];
    }
    return (
        <div>
            <Grid
                style={{display: 'flex', alignItems: 'right'}}
                style={{...marginGenerator('mb-20'), float: 'right'}}>
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => handleCreateButtonClick('sfc_modal')}>
                    {t('createNew.label')}
                </Button>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ? (
                                list.length > 0 ? (
                                    list.map((sfc, rowIndex) => (
                                        <SFCItems
                                            key={rowIndex}
                                            t={t}
                                            sfc={sfc}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>
                                                Record not found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
SFCPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default SFCPanel;
