import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SUB_ADMIN_CUSTOMERS } from '../queries';
import { DELETE_USERS } from '../mutations';
import { UPDATE_USER } from '../../OnBoarding/mutations';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceManager } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import UserItem from '../components/UserItem';
import Message from '../../../components/Message';
import { getUrl } from '../../../utils';
import EditUser from '../EditUsers';
import SearchBox from '../../../components/SearchBox';

const breadcrumbList = [
  { label: 'home.label', link: ServiceManager.Dashboard },
  { label: 'customers.label', link: ServiceManager.Customers }
];

const UsersList = ({ t }) => {
  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [ticketsSearched, setTicketsSearched] = useState(false);
  const [updatedSearchTickets, setUpdatedSearchTickets] = useState([]);

  const menuItems = [
    { label: t('edit.label') },
    { label: t('delete.label') },
    { label: t('addSites.label') }
  ];

  const history = useHistory();
  const location = useLocation();
  /*
        List Queries
    */
  const { loading, error, data, refetch: reloadUserList } = useQuery(
    GET_SUB_ADMIN_CUSTOMERS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (location.search === `?action=reload`) {
      reloadUserList();
    }
  }, [location]);

  const [deleteUser] = useMutation(DELETE_USERS, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('User Deleted successfully.');
      reloadUserList();
    }
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('User updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      reloadUserList();
    }
  });

  const handleSearchCustomers = query => {
    if (query !== '') {
      const updatedList =
        data.getSubAdminCustemers &&
        data.getSubAdminCustemers.filter(
          (item, index) =>
            item.firstName.toLowerCase().includes(query.toLowerCase()) ||
            item.lastName.toLowerCase().includes(query.toLowerCase()) ||
            item.email.toLowerCase().includes(query.toLowerCase()) ||
            item.phone.toLowerCase().includes(query.toLowerCase()) ||
            item.role.toLowerCase().includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase())
        );
      setTicketsSearched(true);
      setUpdatedSearchTickets(updatedList);
    } else {
      setTicketsSearched(false);
      setUpdatedSearchTickets([]);
    }
  };

  let CustomerListing = ticketsSearched
    ? updatedSearchTickets
    : data &&
      data.getSubAdminCustemers &&
      data.getSubAdminCustemers.length &&
      data.getSubAdminCustemers;

  const handleMenuItemClick = (action, data) => {
    if (action.label === 'Delete') {
      if (window.confirm('Are you sure? you want to delete this user!')) {
        return deleteUser({
          variables: {
            id: data.id
          }
        });
      }
    }
    if (action.label === 'Edit') {
      setOpenUserModal(true);
      setEditModalData(data);
    }
    if (action.label === 'Add site') {
      const id = data.id;
      const path = getUrl(ServiceManager.Subscription, id);
      history.push(path);
    }
  };

  const handleSubmit = formData => {
    setButtonLoading(true);
    return updateUser({
      variables: {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: formData.role
      }
    });
  };

  const columns = [
    {
      label: t('name.label'),
      key: 'name'
    },
    {
      label: t('email.label'),
      key: 'email'
    },
    {
      label: t('phone.label'),
      key: 'phone'
    },
    {
      label: t('role.label'),
      key: 'role'
    },
    {
      label: t('createdDate.label'),
      key: 'createdDate'
    },
    {
      label: t('action.label'),
      key: 'button'
    }
  ];

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('customers.label')}
        subText={`${t('customersSubTxt.label')}`}
      />
      <SearchBox handleSearch={handleSearchCustomers} />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !error ? (
                CustomerListing.length > 0 ? (
                  CustomerListing.map((rowitem, rowIndex) => (
                    <UserItem
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                      menuItems={menuItems}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant={'subtitle2'}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <EditUser
        open={openUserModal}
        handleClose={evt => setOpenUserModal(false)}
        handleSubmit={handleSubmit}
        buttonLoading={buttonLoading}
        clearModal={clearModal}
        data={editModalData}
      />
    </Layout>
  );
};
export default withTranslation()(UsersList);
