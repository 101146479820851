import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {FieldArray, Formik, Field, getIn} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function CreateSiteSurveyModal({
                                   t,
                                   open,
                                   handleSaveSiteSurvey,
                                   handleClose,
                                   data,
                                   modalState
                               }) {
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    const formData = (formData) => {
        return {
            opportunity: formData.opportunityId,
            customer_name: formData.customerName,
            address: formData.Address,
            email: formData.Email,
            site_rep: formData.SiteContactRep,
            site_rep_telephone: formData.SiteRepTelephone,
            surveyor: formData.Surveyor,
            surveyor_phone: formData.SurveyorPhone,
            vbn_incident_ref: formData.VBNIncidentRef,
            latitude: formData.latitude,
            longitude: formData.longitude,
            altitude: formData.altitude,
            survey_for: formData.SurveyIsForWhat,
            site_structure: formData.IsThereATower,
            site_structure_comments: formData.Comment,
            site_work_platform: formData.IsThereWorkPlatform,
            site_work_platform_safely_accessible: formData.IsThereWorkPlatformSAFELY,
            site_work_platform_safely_reason: formData.IfNoWhy,
            total_floors: formData.TotalNumberOfFloors,
            earthing_down_conductor: formData.EarthingDownConductor,
            site_structure_allowed_by_user: formData.WillOwnerAllow,
            site_structure_height: formData.TowerHeightM,
            site_structure_dish: formData.CanTheMast,
            equipment_room_floor: formData.EquipmentRoom,
            total_height: formData.TotalHeightB,
            remarks: formData.Remarks,
            new_possible_installation: formData.possibleInstallation,
            penetration_required: formData.roofPenetrations,
            existing_cable_entrance: formData.existingCableEntrance,
            customer_assistance: formData.assistWithService,
            available_space_at_site: formData.poleAtSite,
            site_structure_errection_allowed: formData.erectedAtProposedLocation,
            site_structure_errection_comments: formData.site_structure_errection_comments,
            proposed_latitude: formData.proposed_latitude,
            proposed_longitude: formData.proposed_longitude,
            proposed_altitude: formData.proposed_altitude,
            cable_length_required_siteA: formData.cable_length_required_siteA,
            earth_cable_length_required_siteA: formData.earth_cable_length_required_siteA,
            cable_length_required_siteB: formData.cable_length_required_siteB,
            earth_cable_length_required_siteB: formData.earth_cable_length_required_siteB,
            cable_type: formData.cable_type,
            cable_routing_details: formData.cable_routing_details,
            ups_available: formData.ups_available,
            avr_available: formData.avr_available,
            power_reading_ln: formData.power_reading_ln,
            power_reading_le: formData.power_reading_le,
            power_reading_ne: formData.power_reading_ne,
            equipment_free_from_load: formData.equipment_free_from_load,
            equipment_cooling_side_siteA: formData.equipment_cooling_side_siteA,
            equipment_cooling_working_siteA: formData.equipment_cooling_working_siteA,
            equipment_cooling_side_siteB: formData.equipment_cooling_side_siteB,
            equipment_cooling_working_siteB: formData.equipment_cooling_working_siteB,
            safe_installation: formData.safe_installation,
            safe_installation_reason: formData.safe_installation_reason,
            step_on_roof_plates: formData.step_on_roof_plates,
            health_prodecures: formData.health_prodecures,
            other_details: formData.other_details,
            issues_faced: formData.issues_faced,
            new_earthing_required: formData.new_earthing_required,
            existing_earthing_on_site: formData.existing_earthing_on_site,
            bwa: formData.bwa,
            obstruction: formData.obstruction,
            site_photos: formData.site_photos
        }
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} Site Survey
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will site survey information it to Nigeria.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        customerName: "",
                        Phone: "",
                        Email: "",
                        Address: "",
                        Surveyor: null,
                        VBNIncidentRef: "",
                        SurveyorPhone: "",
                        NameOfSite: "",
                        SiteContactRep: "",
                        SiteRepTelephone: "",
                        latitude: "",
                        longitude: "",
                        altitude: "",
                        proposed_latitude: "",
                        proposed_longitude: "",
                        proposed_altitude: "",
                        SurveyIsForWhat: "",
                        IsThereATower: "",
                        Comment: "",
                        IsThereWorkPlatform: "",
                        IsThereWorkPlatformSAFELY: "",
                        IfNoWhy: "",
                        TotalNumberOfFloors: "",
                        EarthingDownConductor: "",
                        WillOwnerAllow: "",
                        TowerHeightM: "",
                        CanTheMast: "",
                        EquipmentRoom: "",
                        TotalHeightB: "",
                        Remarks: "",
                        possibleInstallation: "",
                        roofPenetrations: "",
                        existingCableEntrance: "",
                        assistWithService: "",
                        poleAtSite: "",
                        erectedAtProposedLocation: "",
                        site_structure_errection_comments: "",
                        cable_length_required_siteA: "",
                        earth_cable_length_required_siteA: "",
                        cable_length_required_siteB: "",
                        earth_cable_length_required_siteB: "",
                        cable_type: "",
                        cable_routing_details: "",
                        ups_available: "",
                        avr_available: "",
                        power_reading_ln: "",
                        power_reading_le: "",
                        power_reading_ne: "",
                        equipment_free_from_load: "",
                        equipment_cooling_side_siteA: "",
                        equipment_cooling_working_siteA: "",
                        equipment_cooling_side_siteB: "",
                        equipment_cooling_working_siteB: "",
                        safe_installation: "",
                        safe_installation_reason: "",
                        step_on_roof_plates: "",
                        health_prodecures: "",
                        other_details: "",
                        issues_faced: "",
                        new_earthing_required: "",
                        existing_earthing_on_site: "",
                        bwa: [{
                            name: '',
                            bearing: '',
                            distance: '',
                            observation_point: '',
                            base_station_visible: ''
                        }],
                        obstruction: [{
                            bts: '',
                            coordinates: '',
                            hasl: '',
                            height: '',
                            type_of_obstruction: ''
                        }],
                        site_photos: [{
                            image: '',
                            file_name: '',
                            caption: '',
                        }]
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        console.log('values', JSON.stringify(formData(values)))
                        handleSaveSiteSurvey(formData(values))
                        if (modalState.clearData) {
                            resetForm()
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        customerName: Yup.string()
                            .max(10, 'Customer Name must be 10 character!')
                            .required('Please Enter customerName'),
                        Address: Yup.string()
                            .max(100, 'Address must be 100 character!')
                            .required('Please Enter Address'),
                        Phone: Yup.string()
                            .max(13, 'Phone must be 13 character!')
                            .required('Please Enter Phone'),
                        VBNIncidentRef: Yup.string()
                            .max(6, 'VBN Incident Ref must be 6 character!')
                            .required('Please Enter VBN Incident Ref'),
                        Surveyor: Yup.string()
                            .max(100, 'Surveyor must be 100 character!')
                            .required('Please Enter Surveyor'),
                        SurveyorPhone: Yup.string()
                            .max(13, 'Surveyor Phone must be 13 character!')
                            .required('Please Enter Surveyor Phone'),
                        NameOfSite: Yup.string()
                            .max(6, 'Name Of Site must be 6 character!')
                            .required('Please Enter Surveyor Name Of Site'),
                        SiteContactRep: Yup.string()
                            .max(100, 'Site Contact Rep must be 100 character!')
                            .required('Please Enter Surveyor Site Contact Rep'),
                        SiteRepTelephone: Yup.string()
                            .max(13, 'Site Rep Telephone must be 13 character!')
                            .required('Please Enter Surveyor Site Rep Telephone'),
                        latitude: Yup.string()
                            .required('Please Enter Surveyor latitude'),
                        longitude: Yup.string()
                            .required('Please Enter longitude'),
                        altitude: Yup.string()
                            .required('Please Enter altitude'),
                        proposed_latitude: Yup.string()
                            .required('Please Enter Proposed latitude'),
                        proposed_longitude: Yup.string()
                            .required('Please Enter proposed longitude'),
                        proposed_altitude: Yup.string()
                            .required('Please Enter proposed altitude'),
                        SurveyIsForWhat: Yup.string()
                            .required('Please Enter Survey Is Fo rWhat'),
                        IsThereATower: Yup.string()
                            .required('Please Enter IsThereATower'),
                        Comment: Yup.string()
                            .required('Please Enter Comment'),
                        IsThereWorkPlatform: Yup.string()
                            .required('Please Enter IsThereWorkPlatform'),
                        IsThereWorkPlatformSAFELY: Yup.string()
                            .required('Please Select IsThereWorkPlatformSAFELY'),
                        TotalNumberOfFloors: Yup.string()
                            .required('Please Select TotalNumberOfFloors'),
                        EarthingDownConductor: Yup.string()
                            .required('Please Select EarthingDownConductor'),
                        WillOwnerAllow: Yup.string()
                            .required('Please Select WillOwnerAllow'),
                        TowerHeightM: Yup.string()
                            .required('Please enter TowerHeightM'),
                        CanTheMast: Yup.string()
                            .required('Please enter CanTheMast'),
                        EquipmentRoom: Yup.string()
                            .required('Please select EquipmentRoom'),
                        TotalHeightB: Yup.string()
                            .required('Please enter TotalHeightB'),
                        Remarks: Yup.string()
                            .max(200, 'Remarks Of Site must be 200 character!')
                            .required('Please enter Remarks'),
                        possibleInstallation: Yup.string()
                            .required('Please enter Possible Installation'),
                        roofPenetrations: Yup.string()
                            .required('Please select Roof Penetrations'),
                        existingCableEntrance: Yup.string()
                            .required('Please select Existing Cable Entrance'),
                        assistWithService: Yup.string()
                            .required('Please select Assist With Service'),
                        poleAtSite: Yup.string()
                            .required('Please select Pole At Site'),
                        erectedAtProposedLocation: Yup.string()
                            .required('Please select erected At Proposed Location'),
                        site_structure_errection_comments: Yup.string()
                            .required('Please enter site structure errection comments'),
                        cable_length_required_siteA: Yup.string()
                            .required('Please enter site cable length required siteA'),
                        earth_cable_length_required_siteA: Yup.string()
                            .required('Please enter earth cable length required siteA'),
                        cable_type: Yup.string()
                            .required('Please enter cable type'),
                        cable_routing_details: Yup.string()
                            .required('Please enter cable routing details'),
                        ups_available: Yup.string()
                            .required('Please enter ups available'),
                        avr_available: Yup.string()
                            .required('Please enter avr available'),
                        power_reading_ln: Yup.string()
                            .required('Please enter power reading ln'),
                        power_reading_le: Yup.string()
                            .required('Please enter power reading le'),
                        power_reading_ne: Yup.string()
                            .required('Please enter power reading ne'),
                        equipment_free_from_load: Yup.string()
                            .required('Please enter equipment free from load'),
                        equipment_cooling_side_siteA: Yup.string()
                            .required('Please enter equipment cooling side siteA'),
                        equipment_cooling_working_siteA: Yup.string()
                            .required('Please enter equipment cooling working siteA'),
                        safe_installation: Yup.string()
                            .required('Please enter safe installation'),
                        step_on_roof_plates: Yup.string()
                            .required('Please select step on roof plates'),
                        health_prodecures: Yup.string()
                            .required('Please select health prodecures'),
                        new_earthing_required: Yup.string()
                            .required('Please select new earthing required'),
                        existing_earthing_on_site: Yup.string()
                            .required('Please select existing earthing on site'),
                        other_details: Yup.string()
                            .max(200, 'Other details Phone must be 200 character!')
                            .required('Please enter other details'),
                        issues_faced: Yup.string()
                            .max(200, 'Issues faced Phone must be 200 character!')
                            .required('Please enter issues faced'),
                        Email: Yup.string()
                            .max(150, 'Email must be 150 character!')
                            .email('Invalid email address')
                            .required('Please Enter Email'),
                        bwa: Yup.array()
                            .of(
                                Yup.object().shape({
                                    name: Yup.string().min(4, 'too short').required('Required'),
                                    bearing: Yup.string().min(3, 'too short').required('Required'),
                                    distance: Yup.string().min(3, 'too short').required('Required'),
                                    observation_point: Yup.string().min(3, 'too short').required('Required'),
                                    base_station_visible: Yup.string().min(3, 'too short').required('Required'),
                                })
                            )
                            .required('Must have bwa') // these constraints are shown if and only if inner constraints are satisfied
                            .min(1, 'Minimum of 1 bwa'),
                        obstruction: Yup.array()
                            .of(
                                Yup.object().shape({
                                    bts: Yup.string().min(4, 'too short').required('Required'),
                                    coordinates: Yup.string().min(3, 'too short').required('Required'),
                                    hasl: Yup.string().min(3, 'too short').required('Required'),
                                    height: Yup.string().min(3, 'too short').required('Required'),
                                    type_of_obstruction: Yup.string().min(3, 'too short').required('Required'),
                                })
                            )
                            .required('Must have obstruction') // these constraints are shown if and only if inner constraints are satisfied
                            .min(1, 'Minimum of 1 obstruction'),
                        site_photos: Yup.array()
                            .of(
                                Yup.object().shape({
                                    image: Yup.string().min(4, 'too short').required('Required'),
                                    file_name: Yup.string().min(4, 'too short').required('Required'),
                                    caption: Yup.string().min(3, 'too short').required('Required')
                                })
                            )
                            .required('Must have site photos') // these constraints are shown if and only if inner constraints are satisfied
                            .min(1, 'Minimum of 1 site photos')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Engineering Department
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.customerName}
                                                    name={'customerName'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.customerName && touched.customerName) && errors.customerName}
                                                    placeholder={`${t('customerName.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.Address}
                                                    name={t('address.label')}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Address && touched.Address) && errors.Address}
                                                    placeholder={`${t('address.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.Phone}
                                                    name={'Phone'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Phone && touched.Phone) && errors.Phone}
                                                    placeholder={`${t('phone.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.Email}
                                                    name={'Email'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Email && touched.Email) && errors.Email}
                                                    placeholder={`${t('email.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.VBNIncidentRef}
                                                    name={'VBNIncidentRef'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.VBNIncidentRef && touched.VBNIncidentRef) && errors.VBNIncidentRef}
                                                    placeholder={`${t('VBNIncidentRef.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.Surveyor}
                                                    name={'Surveyor'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Surveyor && touched.Surveyor) && errors.Surveyor}
                                                    placeholder={`${t('Surveyor.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SurveyorPhone}
                                                    name={'SurveyorPhone'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SurveyorPhone && touched.SurveyorPhone) && errors.SurveyorPhone}
                                                    placeholder={`${t('Surveyor.label') + ' ' + t('phone.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Survey Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.NameOfSite}
                                                    name={'NameOfSite'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.NameOfSite && touched.NameOfSite) && errors.NameOfSite}
                                                    placeholder={`${t('NameOfSite.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SiteContactRep}
                                                    name={'SiteContactRep'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteContactRep && touched.SiteContactRep) && errors.SiteContactRep}
                                                    placeholder={`${t('SiteContactRep.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.SiteRepTelephone}
                                                    name={'SiteRepTelephone'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SiteRepTelephone && touched.SiteRepTelephone) && errors.SiteRepTelephone}
                                                    placeholder={`${t('SiteRepTelephone.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.latitude}
                                                    name={'latitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.latitude && touched.latitude) && errors.latitude}
                                                    placeholder={`${t('latitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.longitude}
                                                    name={'longitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.longitude && touched.longitude) && errors.longitude}
                                                    placeholder={`${t('longitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.altitude}
                                                    name={'altitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.altitude && touched.altitude) && errors.altitude}
                                                    placeholder={`${t('altitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.SurveyIsForWhat}
                                                    name={'SurveyIsForWhat'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.SurveyIsForWhat && touched.SurveyIsForWhat) && errors.SurveyIsForWhat}
                                                    placeholder={`${t('SurveyIsForWhat.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    General
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.IsThereATower}
                                                    name={'IsThereATower'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.IsThereATower && touched.IsThereATower) && errors.IsThereATower}
                                                    placeholder={`${t('IsThereATower.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.Comment}
                                                    name={'Comment'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Comment && touched.Comment) && errors.Comment}
                                                    placeholder={`${t('comment.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.IsThereWorkPlatform}
                                                    name={'IsThereWorkPlatform'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.IsThereWorkPlatform && touched.IsThereWorkPlatform) && errors.IsThereWorkPlatform}
                                                    placeholder={`${t('IsThereWorkPlatform.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.IsThereWorkPlatformSAFELY}
                                                    label={`${t('IsThereWorkPlatformSAFELY.label')} *`}
                                                    name={'IsThereWorkPlatformSAFELY'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={[{id: 'Yes', name: 'Yes'}, {id: 'No', name: 'No'}]}
                                                />
                                                {(errors.IsThereWorkPlatformSAFELY && touched.IsThereWorkPlatformSAFELY) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.IsThereWorkPlatformSAFELY}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.IfNoWhy}
                                                    name={'IfNoWhy'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.IfNoWhy && touched.IfNoWhy) && errors.IfNoWhy}
                                                    placeholder={`${t('IfNoWhy.label')} `}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.TotalNumberOfFloors}
                                                    label={`${t('TotalNumberOfFloors.label')} *`}
                                                    name={'TotalNumberOfFloors'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={[1, 2, 3, 4, 5].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.TotalNumberOfFloors && touched.TotalNumberOfFloors) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.TotalNumberOfFloors}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.EarthingDownConductor}
                                                    label={`${t('EarthingDownConductor.label')} *`}
                                                    name={'EarthingDownConductor'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.EarthingDownConductor && touched.EarthingDownConductor) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.EarthingDownConductor}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.WillOwnerAllow}
                                                    label={`${t('WillOwnerAllow.label')} *`}
                                                    name={'WillOwnerAllow'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.WillOwnerAllow && touched.WillOwnerAllow) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.WillOwnerAllow}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.TowerHeightM}
                                                    name={'TowerHeightM'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.TowerHeightM && touched.TowerHeightM) && errors.TowerHeightM}
                                                    placeholder={`${t('TowerHeightM.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.CanTheMast}
                                                    name={'CanTheMast'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.CanTheMast && touched.CanTheMast) && errors.CanTheMast}
                                                    placeholder={`${t('CanTheMast.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                    <OutlinedSelect
                                                        val={values.EquipmentRoom}
                                                        label={`${t('EquipmentRoom.label')} *`}
                                                        name={'EquipmentRoom'}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        selectStyle={{...paddingGenerator('p-8')}}
                                                        styleOverrides={{width: '100%', marginRight: 0}}
                                                        options={[1, 2, 3, 4, 5, 6].map(item => {
                                                            return {id: item, name: item}
                                                        })}
                                                    />
                                                    {(errors.EquipmentRoom && touched.EquipmentRoom) &&
                                                    <FormHelperText
                                                        style={{marginTop: '20px'}}>{errors.EquipmentRoom}</FormHelperText>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.TotalHeightB}
                                                    name={'TotalHeightB'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.TotalHeightB && touched.TotalHeightB) && errors.TotalHeightB}
                                                    placeholder={`${t('TotalHeightB.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.Remarks}
                                                    name={'Remarks'}
                                                    onChange={handleChange}
                                                    multiline
                                                    rows={4}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.Remarks && touched.Remarks) && errors.Remarks}
                                                    placeholder={`${t('remarks.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    BWA/PTP
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FieldArray
                                                    name="bwa"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.bwa.map((bwa, index) => (
                                                                <Grid key={index} container spacing={3}>
                                                                    {/** both these conventions do the same */}
                                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                        {(index !== 0) ? <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                            -
                                                                        </Button> : <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push({
                                                                                name: '',
                                                                                bearing: '',
                                                                                distance: '',
                                                                                observation_point: '',
                                                                                base_station_visible: ''
                                                                            })}>
                                                                            +
                                                                        </Button>}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={bwa.name}
                                                                            name={`bwa[${index}].name`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `bwa[${index}].name`)
                                                                                && getIn(touched, `bwa[${index}].name`))
                                                                            && getIn(errors, `bwa[${index}].name`)}
                                                                            placeholder={`${t('baseStationName.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={bwa.bearing}
                                                                            name={`bwa.${index}.bearing`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `bwa[${index}].bearing`)
                                                                                && getIn(touched, `bwa[${index}].bearing`))
                                                                            && getIn(errors, `bwa[${index}].bearing`)}
                                                                            placeholder={`${t('bearingToBS.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={bwa.distance}
                                                                            name={`bwa.${index}.distance`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `bwa[${index}].distance`)
                                                                                && getIn(touched, `bwa[${index}].distance`))
                                                                            && getIn(errors, `bwa[${index}].distance`)}
                                                                            placeholder={`${t('distanceToBs.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            value={bwa.observation_point}
                                                                            name={`bwa.${index}.observation_point`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `bwa[${index}].observation_point`)
                                                                                && getIn(touched, `bwa[${index}].observation_point`))
                                                                            && getIn(errors, `bwa[${index}].observation_point`)}
                                                                            placeholder={`${t('observationPoint.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            value={bwa.base_station_visible}
                                                                            name={`bwa.${index}.base_station_visible`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `bwa[${index}].base_station_visible`)
                                                                                && getIn(touched, `bwa[${index}].base_station_visible`))
                                                                            && getIn(errors, `bwa[${index}].base_station_visible`)}
                                                                            placeholder={`${t('canYouSeeBaseStationPOP.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Coordinates of possible obstruction to LOS
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FieldArray
                                                    name="obstruction"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.obstruction.map((obstruction, index) => (
                                                                <Grid key={index} container spacing={3}>
                                                                    {/** both these conventions do the same */}
                                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                        {(index !== 0) ? <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                            -
                                                                        </Button> : <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push({
                                                                                bts: '',
                                                                                coordinates: '',
                                                                                hasl: '',
                                                                                height: '',
                                                                                type_of_obstruction: ''
                                                                            })}>
                                                                            +
                                                                        </Button>}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={obstruction.bts}
                                                                            name={`obstruction[${index}].bts`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `obstruction[${index}].bts`)
                                                                                && getIn(touched, `obstruction[${index}].bts`))
                                                                            && getIn(errors, `obstruction[${index}].bts`)}
                                                                            placeholder={`${t('ObstructionBTS.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={obstruction.coordinates}
                                                                            name={`obstruction.${index}.coordinates`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `obstruction[${index}].coordinates`)
                                                                                && getIn(touched, `obstruction[${index}].coordinates`))
                                                                            && getIn(errors, `obstruction[${index}].coordinates`)}
                                                                            placeholder={`${t('Coordinates.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            value={obstruction.hasl}
                                                                            name={`obstruction.${index}.hasl`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `obstruction[${index}].hasl`)
                                                                                && getIn(touched, `obstruction[${index}].hasl`))
                                                                            && getIn(errors, `obstruction[${index}].hasl`)}
                                                                            placeholder={`${t('HASL.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            value={obstruction.height}
                                                                            name={`obstruction.${index}.height`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `obstruction[${index}].height`)
                                                                                && getIn(touched, `obstruction[${index}].height`))
                                                                            && getIn(errors, `obstruction[${index}].height`)}
                                                                            placeholder={`${t('ObstructionHeight.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            value={obstruction.type_of_obstruction}
                                                                            name={`obstruction.${index}.type_of_obstruction`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `obstruction[${index}].type_of_obstruction`)
                                                                                && getIn(touched, `obstruction[${index}].type_of_obstruction`))
                                                                            && getIn(errors, `obstruction[${index}].type_of_obstruction`)}
                                                                            placeholder={`${t('TypeObstruction.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    )}
                                                />
                                            </Grid>
                                            <Divider/>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.possibleInstallation}
                                                    name={'possibleInstallation'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.possibleInstallation && touched.possibleInstallation) && errors.possibleInstallation}
                                                    placeholder={`${t('possibleInstallation.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.roofPenetrations}
                                                    label={`${t('roofPenetrations.label')} *`}
                                                    name={'roofPenetrations'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.roofPenetrations && touched.roofPenetrations) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.roofPenetrations}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.existingCableEntrance}
                                                    label={`${t('existingCableEntrance.label')} *`}
                                                    name={'existingCableEntrance'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.existingCableEntrance && touched.existingCableEntrance) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.existingCableEntrance}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <TextField
                                                    value={values.assistWithService}
                                                    name={'assistWithService'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.assistWithService && touched.assistWithService) && errors.assistWithService}
                                                    placeholder={`${t('assistWithService.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.poleAtSite}
                                                    label={`${t('poleAtSite.label')} *`}
                                                    name={'poleAtSite'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.poleAtSite && touched.poleAtSite) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.poleAtSite}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.erectedAtProposedLocation}
                                                    label={`${t('erectedAtProposedLocation.label')} *`}
                                                    name={'erectedAtProposedLocation'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.erectedAtProposedLocation && touched.erectedAtProposedLocation) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.erectedAtProposedLocation}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.site_structure_errection_comments}
                                                    name={'site_structure_errection_comments'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.site_structure_errection_comments && touched.site_structure_errection_comments) && errors.site_structure_errection_comments}
                                                    placeholder={`${t('site_structure_errection_comments.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.proposed_latitude}
                                                    name={'proposed_latitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.latitude && touched.proposed_latitude) && errors.proposed_latitude}
                                                    placeholder={`${t('proposed_latitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.proposed_longitude}
                                                    name={'proposed_longitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.proposed_longitude && touched.proposed_longitude) && errors.proposed_longitude}
                                                    placeholder={`${t('proposed_longitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.proposed_altitude}
                                                    name={'proposed_altitude'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.proposed_altitude && touched.proposed_altitude) && errors.proposed_altitude}
                                                    placeholder={`${t('proposed_altitude.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Divider/>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.cable_length_required_siteA}
                                                    name={'cable_length_required_siteA'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.cable_length_required_siteA && touched.cable_length_required_siteA) && errors.cable_length_required_siteA}
                                                    placeholder={`${t('cable_length_required_siteA.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.earth_cable_length_required_siteA}
                                                    name={'earth_cable_length_required_siteA'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.earth_cable_length_required_siteA && touched.earth_cable_length_required_siteA) && errors.earth_cable_length_required_siteA}
                                                    placeholder={`${t('earth_cable_length_required_siteA.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.cable_length_required_siteB}
                                                    name={'cable_length_required_siteB'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.cable_length_required_siteB && touched.cable_length_required_siteB) && errors.cable_length_required_siteB}
                                                    placeholder={`${t('cable_length_required_siteB.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.earth_cable_length_required_siteB}
                                                    name={'earth_cable_length_required_siteB'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.earth_cable_length_required_siteB && touched.earth_cable_length_required_siteB) && errors.earth_cable_length_required_siteB}
                                                    placeholder={`${t('earth_cable_length_required_siteB.label')} `}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.cable_type}
                                                    name={'cable_type'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.cable_type && touched.cable_type) && errors.cable_type}
                                                    placeholder={`${t('cable_type.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.cable_routing_details}
                                                    name={'cable_routing_details'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.cable_routing_details && touched.cable_routing_details) && errors.cable_routing_details}
                                                    placeholder={`${t('cable_routing_details.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.ups_available}
                                                    label={`${t('ups_available.label')} *`}
                                                    name={'ups_available'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.ups_available && touched.ups_available) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.ups_available}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.avr_available}
                                                    label={`${t('avr_available.label')} *`}
                                                    name={'avr_available'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.avr_available && touched.avr_available) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.avr_available}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Power Reading
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.power_reading_ln}
                                                    name={'power_reading_ln'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.power_reading_ln && touched.power_reading_ln) && errors.power_reading_ln}
                                                    placeholder={`${t('power_reading_ln.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.power_reading_le}
                                                    name={'power_reading_le'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.power_reading_le && touched.power_reading_le) && errors.power_reading_le}
                                                    placeholder={`${t('power_reading_le.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.power_reading_ne}
                                                    name={'power_reading_ne'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.power_reading_ne && touched.power_reading_ne) && errors.power_reading_ne}
                                                    placeholder={`${t('power_reading_ne.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Divider/>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.equipment_free_from_load}
                                                    label={`${t('equipment_free_from_load.label')} *`}
                                                    name={'equipment_free_from_load'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.equipment_free_from_load && touched.equipment_free_from_load) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.equipment_free_from_load}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.equipment_cooling_side_siteA}
                                                    name={'equipment_cooling_side_siteA'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.equipment_cooling_side_siteA && touched.equipment_cooling_side_siteA) && errors.equipment_cooling_side_siteA}
                                                    placeholder={`${t('equipment_cooling_side_siteA.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.equipment_cooling_working_siteA}
                                                    name={'equipment_cooling_working_siteA'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.equipment_cooling_working_siteA && touched.equipment_cooling_working_siteA) && errors.equipment_cooling_working_siteA}
                                                    placeholder={`${t('equipment_cooling_working_siteA.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.equipment_cooling_side_siteB}
                                                    name={'equipment_cooling_side_siteB'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.equipment_cooling_side_siteB && touched.equipment_cooling_side_siteB) && errors.equipment_cooling_side_siteB}
                                                    placeholder={`${t('equipment_cooling_side_siteB.label')}`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.equipment_cooling_working_siteA}
                                                    name={'equipment_cooling_working_siteB'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.equipment_cooling_working_siteA && touched.equipment_cooling_working_siteA) && errors.equipment_cooling_working_siteA}
                                                    placeholder={`${t('equipment_cooling_working_siteB.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.safe_installation}
                                                    label={`${t('safe_installation.label')} *`}
                                                    name={'safe_installation'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.safe_installation && touched.safe_installation) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.safe_installation}</FormHelperText>
                                                }
                                            </Grid>
                                            {values.safe_installation === 'No' ? <Grid item xs={4}>
                                                <TextField
                                                    value={values.safe_installation_reason}
                                                    name={'safe_installation_reason'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.safe_installation_reason && touched.safe_installation_reason) && errors.safe_installation_reason}
                                                    placeholder={`${t('safe_installation_reason.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid> : ""}
                                            <Grid item xs={4} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.step_on_roof_plates}
                                                    label={`${t('step_on_roof_plates.label')} *`}
                                                    name={'step_on_roof_plates'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.step_on_roof_plates && touched.step_on_roof_plates) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.step_on_roof_plates}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={values.health_prodecures}
                                                    name={'health_prodecures'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.health_prodecures && touched.health_prodecures) && errors.health_prodecures}
                                                    placeholder={`${t('health_prodecures.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.other_details}
                                                    name={'other_details'}
                                                    onChange={handleChange}
                                                    multiline
                                                    rows={4}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.other_details && touched.other_details) && errors.other_details}
                                                    placeholder={`${t('other_details.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    value={values.issues_faced}
                                                    name={'issues_faced'}
                                                    onChange={handleChange}
                                                    multiline
                                                    rows={4}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.issues_faced && touched.issues_faced) && errors.issues_faced}
                                                    placeholder={`${t('issues_faced.label')} *`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.new_earthing_required}
                                                    label={`${t('new_earthing_required.label')} *`}
                                                    name={'new_earthing_required'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.new_earthing_required && touched.new_earthing_required) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.new_earthing_required}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.existing_earthing_on_site}
                                                    label={`${t('existing_earthing_on_site.label')} *`}
                                                    name={'existing_earthing_on_site'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={['Yes', 'No'].map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.existing_earthing_on_site && touched.existing_earthing_on_site) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.existing_earthing_on_site}</FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Image Uploader
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FieldArray
                                                    name="site_photos"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.site_photos.map((site_photos, index) => (
                                                                <Grid key={index} container spacing={3}>
                                                                    {/** both these conventions do the same */}
                                                                    <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                        {(index !== 0) ? <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                            -
                                                                        </Button> : <Button
                                                                            color="secondary"
                                                                            variant={'contained'}
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push({
                                                                                image: '',
                                                                                file_name: '',
                                                                                caption: '',
                                                                            })}>
                                                                            +
                                                                        </Button>}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            value={site_photos.caption}
                                                                            name={`site_photos[${index}].caption`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `site_photos[${index}].caption`)
                                                                                && getIn(touched, `site_photos[${index}].caption`))
                                                                            && getIn(errors, `site_photos[${index}].caption`)}
                                                                            placeholder={`${t('caption.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            type={'file'}
                                                                            name={`site_photos[${index}].image`}
                                                                            onChange={(event) => {
                                                                                let img = event.target.files[0];
                                                                                setFieldValue(`site_photos[${index}].file_name`, img && img.name);
                                                                                const reader = new FileReader();
                                                                                reader.readAsDataURL(img);
                                                                                reader.onload = function () {
                                                                                    console.log('images-base64', reader.result);
                                                                                    setFieldValue(`site_photos[${index}].image`, reader.result);
                                                                                };
                                                                                reader.onerror = function (error) {
                                                                                    console.log('Error: ', error);
                                                                                };
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            helperText={(getIn(errors, `site_photos[${index}].image`)
                                                                                && getIn(touched, `site_photos[${index}].image`))
                                                                            && getIn(errors, `site_photos[${index}].image`)}
                                                                            placeholder={`${t('images.label')} *`}
                                                                            color={'secondary'}
                                                                            style={{width: '100%'}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

CreateSiteSurveyModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSaveSiteSurvey: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateSiteSurveyModal);
