import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import {useLazyQuery} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import commonStyles from '../../../../../../../theme/commonStyles';
import { dateFormatterLLL } from '../../../../../../../utils';
import MenuWrapper from '../../../../../../../components/MenuWrapper';


const OcrConfigurationGroupsAndUsersItem = ({
    key,
    t,
    data,
    assignProjectEnable
}) => {

  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const assignMenuItems = [
    { label: t('assignToProject.label') },
    { label: t('addToGroup.label') }
  ];
console.log('data', data);
  return (
    <>
    <TableRow key={data.id}>
      <TableCell>
          <Grid className={commonClasses.column}>
              <Grid>
                  <Typography variant={'subtitle1'}>
                    {data ? data.name : '-'}
                  </Typography>
              </Grid>
          </Grid>
      </TableCell>
      <TableCell>
          <Grid className={commonClasses.column}>
              <Grid>
                  <Typography variant={'subtitle1'}>
                    {data ? data.email : '-'}
                  </Typography>
              </Grid>
          </Grid>
      </TableCell>
      <TableCell>
          <Grid className={commonClasses.column}>
              <Grid>
                  <Typography variant={'subtitle1'}>
                      {dateFormatterLLL(data.createdDate)}
                  </Typography>
              </Grid>
          </Grid>
      </TableCell>
      <TableCell>
                <Grid >
                    <IconButton
                        //disabled={!allowConfigure}
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular} />
                    </IconButton>
                </Grid>
                <MenuWrapper
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={[...assignMenuItems]}
                    handleMenuItemClick={elem => {
                        //handleMenuItemClick(elem, data, 'ml_template');
                        setAnchorEl(null);
                        if(elem.label === t('assignToProject.label')){
                          assignProjectEnable(t('assignToProject.label'), {
                            selectedUserData:{
                                first_name:data.firstName,
                                last_name:data.lastName,
                                email:data.email,
                                user_id:data.id,
                                phone_no:''
                            }
                          });
                        } else if(elem.label === t('addToGroup.label')){
                          //setIsAddToGroupEnable(true);
                        }
                    }}
                />
            </TableCell>
    </TableRow>   
    </>
  )
}

export default OcrConfigurationGroupsAndUsersItem