import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { renderText } from '../../displayComponent';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../components/hook-form';
import { getRegions } from '../../../../services/fcrService';
import { getAllMinTerm } from '../../../../services/clientService';
import {
  addDataCenter,
  getDatacenterById,
  updateDataCenterById
} from '../../../../services/dataCenterService';
import Message from '../../../../components/Message';

const CreateDatacenterForm = () => {
  const [regions, setRegions] = useState([]);
  const [minimumTerm, setMinimumTerm] = useState([]);
  const history = useHistory();
  const { mode, id } = useParams();
  const initialValues = {
    dataCenterName: '',
    dataCenterCode: '',
    region: '',
    minTerm: '',
    stage: '',
    role: '',
    portSpeed: '',
    asn: '',
    address: '',
    city: '',
    country: '',
    latitude: '',
    longitude: '',
    numOfPorts: '',
    numOfFcrs: ''
  };
  const validationSchema = yup.object().shape({
    dataCenterName: yup.string().required('This field is required'),
    dataCenterCode: yup.string().required('This field is required'),
    region: yup.string().required('This field is required'),
    minTerm: yup.string().required('This field is required'),
    stage: yup.string().required('This field is required'),
    role: yup.string().required('This field is required'),
    portSpeed: yup.string().required('This field is required'),
    asn: yup.string().required('This field is required'),
    address: yup.string().required('This field is required'),
    city: yup.string().required('This field is required'),
    country: yup.string().required('This field is required'),
    latitude: yup.string().required('This field is required'),
    longitude: yup.string().required('This field is required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      region_id: data?.region,
      dc_code: data?.dataCenterCode,
      name: data?.dataCenterName,
      portSpeed: data?.portSpeed,
      min_term: data?.minTerm,
      country: data?.country,
      city: data?.city,
      address: data?.address,
      latitude: data?.latitude,
      longitude: data?.longitude,
      stage: data?.stage,
      role: data?.role,
      asn: data?.asn,
      no_ports_org_dc: 1,
      no_fcr_org_dc: 1
    };
    if (mode === 'add') {
      const res = await addDataCenter(payload);
      if (res?.status === 200) {
        history.push('/fabricService/datacenters');
      } else {
        if (res?.response?.data?.message) {
          Message.error(res?.response?.data?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    } else {
      const res = await updateDataCenterById(id, payload);

      if (res?.status === 200) {
        history.push('/fabricService/datacenters');
      } else {
        if (res?.response?.data?.message) {
          Message.error(res?.response?.data?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    }
  };

  const GetRegions = async () => {
    const res = await getRegions();
    if (res?.status === 200) setRegions(res?.data?.result?.data);
  };
  const GetMinimumTerm = async () => {
    const res = await getAllMinTerm();
    setMinimumTerm(res?.data?.result);
  };

  useEffect(() => {
    GetRegions();
    GetMinimumTerm();
  }, []);

  const getDatacenter = async () => {
    const res = await getDatacenterById(id);
    setValue('region', res?.data?.result?.region_id?._id);
    setValue('dataCenterCode', res?.data?.result?.dc_code);
    setValue('dataCenterName', res?.data?.result?.name);
    setValue('portSpeed', res?.data?.result?.portSpeed);
    setValue('minTerm', res?.data?.result?.min_term?._id);
    setValue('stage', res?.data?.result?.stage);
    setValue('role', res?.data?.result?.role);
    setValue('asn', res?.data?.result?.asn);
    setValue('address', res?.data?.result?.address);
    setValue('city', res?.data?.result?.city);
    setValue('country', res?.data?.result?.country);
    setValue('latitude', res?.data?.result?.latitude);
    setValue('longitude', res?.data?.result?.longitude);
  };

  useEffect(() => {
    if (id) {
      getDatacenter();
    }
  }, [id]);

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>
                {renderText({
                  label:
                    mode === 'add' ? 'Create Data Center' : 'Edit Data Center'
                })}
              </Box>
            </Grid>{' '}
          </Paper>
        </Grid>
        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Data Center Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="dataCenterName"
                        placeholder="Enter data center name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Data Center Code
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="dataCenterCode"
                        placeholder="Enter data center code"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Region
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="region"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {regions?.map(region => (
                          <option key={region?._id} value={region?._id}>
                            {region?.region_name}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Minimum Term
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid
                      item
                      sm={5}
                      justify="space-between"
                      alignItems="center"
                      sx={{ border: 2 }}>
                      <RHFSelect name="minTerm" variant="outlined" size="small">
                        <option value="">Select</option>
                        {minimumTerm &&
                          minimumTerm?.map(term => (
                            <option key={term?._id} value={term._id}>
                              {term?.term_number}
                            </option>
                          ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Stage
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid
                      item
                      sm={5}
                      justify="space-between"
                      alignItems="center"
                      sx={{ border: 2 }}>
                      <RHFSelect name="stage" variant="outlined" size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'Staging', value: 'staging' },
                          { key: 'Production', value: 'production' }
                        ]?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Role
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid
                      item
                      sm={5}
                      justify="space-between"
                      alignItems="center"
                      sx={{ border: 2 }}>
                      <RHFSelect name="role" variant="outlined" size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'Hub', value: 'hub' },
                          { key: 'Spoke', value: 'spoke' }
                        ]?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Port Speed
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFSelect
                        name="portSpeed"
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[{ key: '1 Gbps', value: '1Gbps' }]?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      ASN
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="asn"
                        placeholder="Enter ASN"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Address
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="address"
                        placeholder="Enter address"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>

                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      City
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="city"
                        placeholder="Enter city"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Country
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="country"
                        placeholder="Enter country"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Latitude
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="latitude"
                        placeholder="Enter latitude"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Longitude
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="longitude"
                        placeholder="Enter longitude"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() =>
                          history.push('/fabricService/datacenters')
                        }>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit">
                        {mode === 'add' ? 'Submit' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateDatacenterForm;
