import React from 'react';
import ReactEcharts from 'echarts-for-react';

const style = {
  height: "45vh",
  width: "100%"
};

const AreaEChartWrapper = ({
  value
}) => {

  return (
    <div>
      <ReactEcharts
        style={style}
        option={
          {
            series: [
              {
                type: "gauge",
                axisLine: {
                  lineStyle: {
                    width: 25,
                    color: [
                      [0.2, "#59D075"],
                      [0.5, "#FFDA00"],
                      [0.8, "#FFA500"],
                      [1, "#fd666d"]
                    ]
                  }
                },
                pointer: {
                  itemStyle: {
                    color: "auto"
                  }
                },
                axisTick: {
                  distance: -25,
                  length: 8,
                  lineStyle: {
                    color: "#fff",
                    width: 2
                  }
                },
                splitLine: {
                  distance: -25,
                  length: 25,
                  lineStyle: {
                    color: "#fff",
                    width: 6
                  }
                },
                axisLabel: {
                  color: "auto",
                  distance: 10,
                  fontSize: 15
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}% usage",
                  color: "auto",
                  fontSize: 16
                },
                data: [
                  {
                    value
                  }
                ]
              }
            ]
          }
        }
      />
    </div>
  );
};

export default AreaEChartWrapper;
