import React from 'react';
import ReactEcharts from "echarts-for-react";

const HeatMapChartWrapper = ({xAxis, stackKeyNames, heatMapData}) => {

    // prettier-ignore
    const data = heatMapData ? heatMapData.map(function (item) {
      return [item[1], item[0], item[2] || '-'];
    }) : [];

    return (
        <div>
          <ReactEcharts
            option={ {
                tooltip: {
                  position: 'top'
                },
                grid: {
                  height: '75%',
                  top: '10%'
                },
                xAxis: {
                  type: 'category',
                  data: xAxis,
                  splitArea: {
                    show: true
                  }
                },
                yAxis: {
                  type: 'category',
                  data: stackKeyNames,
                  splitArea: {
                    show: true
                  }
                },
                visualMap: {
                  min: 0,
                  max: 10,
                  calculable: true,
                  orient: 'vertical',
                  right: '1%',
                  top: '10%'
                },
                dataZoom: xAxis.length > 20 ? [
                  {
                    show: true,
                    start: 94,
                    end: 100
                  },
                  {
                    type: 'inside',
                    start: 94,
                    end: 100
                  }
                ] : [],
                series: [
                  {
                    name: 'Count',
                    type: 'heatmap',
                    data: data,
                    label: {
                      show: true
                    },
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
                ]
            }} />
        </div>
    );
};

export default HeatMapChartWrapper;