import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/core/styles/withStyles';
import { colors } from '../../../../theme/colors';
import './Map.css';

const styles = {
  search: {
    display: 'inline',
    position: 'relative',
    backgroundColor: colors.common.white,
    marginLeft: 0
  },
  inputInput: {
    padding: '8px 16px',
    width: 200,
    border: `1px solid ${colors.grey[200]}`,
    backgroundColor: colors.common.white,
    margin: '10px'
  }
};

class PlacesMap extends React.PureComponent {
  componentDidMount() {
    if (window.google) {
      this.initMap();
    }
  }

  initMap() {
    const latlng = new window.google.maps.LatLng(17.397, 26.644);
    const { handleSetPosition, position, search = true, id } = this.props;

    let marker = null;

    const mapOptions = {
      zoom: 2,
      center: latlng,
      mapTypeControl: false,
      streetViewControl: false
    };

    const map = new window.google.maps.Map(
      document.getElementById(`map-places${this.props.id}`),
      mapOptions
    );

    if (position) {
      marker = new window.google.maps.Marker({
        map: map,
        position: position
      });

      marker.setMap(map);
    }
    if (search) {
      // Create the search box and link it to the UI element.
      const input = document.getElementById(`pac-input${this.props.id}`);
      const searchBox = new window.google.maps.places.SearchBox(input);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener('bounds_changed', function() {
        searchBox.setBounds(map.getBounds());
      });

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', function() {
        const places = searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }

        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            console.log('Returned place contains no geometry');
            return;
          }

          if (marker) {
            marker.setMap(null);
          }

          marker = new window.google.maps.Marker({
            map: map,
            title: place.name,
            position: place.geometry.location
          });

          handleSetPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          }, place, id);

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });

      map.addListener('click', function(evt) {
        if (marker) {
          marker.setMap(null);
        }
        console.log('evt ====== ', evt);

        marker = new window.google.maps.Marker({
          map: map,
          position: evt.latLng
        });

        // handleSetPosition({
        //   latitude: evt.latLng.lat(),
        //   longitude: evt.latLng.lng()
        // });
        handleSetPosition({
          latitude: evt.latLng.lat(),
          longitude: evt.latLng.lng()
        }, evt, id);
        marker.setMap(map);
      });
    }
  }

  render() {
    const { id, classes, search = true } = this.props;
    return (
      <>
        {search && (
          <div className={classes.search}>
            <InputBase
              placeholder="Search or pin location on the map"
              id={`pac-input${id}`}
              classes={{
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}
        <div id={`map-places${id}`} style={{ height: '100%', width: '100%' }} />
      </>
    );
  }
}

export default withStyles(theme => styles)(PlacesMap);
