import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useMutation } from '@apollo/react-hooks';
import { ADD_CUSTOMER_RATING } from '../mutations';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function SignIn() {
  const [rating, setRating] = useState('');
  const [id, setId] = useState('');
  const [exist, setExist] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    starRating();
  }, []);

  const starRating = () => {
    if (window.location) {
      if (window.location.search) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const rating = urlParams.get('rating');
        const id = urlParams.get('id');
        setId(id);
        setRating(rating);
        if (id != null && rating != null) {
          return addRating({
            variables: {
              ticketId: id,
              rating: rating
            }
          });
        } else {
          console.log('not in');
        }
      }
    }
  };

  const [addRating] = useMutation(ADD_CUSTOMER_RATING, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data && data.supportRating && data.supportRating.message) {
        setExist(true);
        console.log('Already Exist.');
      } else {
        setExist(false);
        console.log('Rating saved to DB successfully.');
      }
    }
  });
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <StarIcon />
        </Avatar>
        <Typography component="h1" variant="h1" color="primary">
          Rating
        </Typography>
        {exist ? (
          <Typography component="h1" variant="h4" color="primary">
            Rating Already exist with this ID.
          </Typography>
        ) : (
          <>
            <Typography component="h1" variant="h4" color="primary">
              Thanks for sharing your feedback with us.
            </Typography>
            <Typography component="h1" variant="h5" color="primary">
              You give {rating} star rating for ticket Id {id}.
            </Typography>
          </>
        )}
      </div>
    </Container>
  );
}
