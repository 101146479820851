import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import { useEffect } from 'react';
import { dateFormatterLLL } from '../../../../../utils';

const EquipmentsItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
}) => {
    const commonClasses = commonStyles();
    const countryName = localStorage.getItem('country');
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.case_number) ? data.project_info.case_number : (data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A'))}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.customer_name) ? data.project_info.customer_name : data.customer_name} /
                            {(data.project_info && data.project_info.organization_name) ? data.project_info.organization_name : data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {COUNTRY_LIST.ZAMBIA === countryName ? (data.equipment_type ? data.equipment_type : 'NA') : (data.equipments_to_be_deployed ? data.equipments_to_be_deployed : 'NA')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
EquipmentsItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(EquipmentsItem);