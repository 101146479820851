import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { Paths } from '../../routes/routePaths';
import CustomTabs from "../../components/CustomTabs";
import { USER_ROLES } from "../../utils/constants";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Header from "../../components/Header";
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import ElasticImage from '../../assets/icons/elastic.png';
import SplynxImage from '../../assets/icons/splynx.jpg';
import SalesforceImage from '../../assets/icons/salesforce.jpg';
import PaySpaceImage from '../../assets/icons/paySpace.png';
import SageImage from '../../assets/icons/sage.png';
import { makeStyles } from '@material-ui/core/styles';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'otherServices.label', link: Paths.OtherServices }
];
// style : {
//   backgroundColor: 'primary.dark',
//   '&:hover': {
//     backgroundColor: 'primary.main',
//     opacity: [0.9, 0.8, 0.7],
//   },
// }

const useStyles = makeStyles(theme => ({
  padding10: { padding: "10%" },
  fontSize20: { fontSize: "20px" },
  card: {
    textAlign: 'center',
    border: '2px solid #f0f0f0',
    borderRadius: '5px'
  }
}));

const OtherServices = ({ t }) => {
  const classes = useStyles();
  const role = localStorage.getItem('role');
  const [refreshTab, setRefreshTab] = useState(false);
  const handleClickOpen = (event) => {
    setRefreshTab(event);
  }
  const ElasticUrl = "https://6035754e1e074c1691b4fdbde8bf040c.europe-west2.gcp.elastic-cloud.com:9243/login?next=%2Fspaces%2Fspace_selector";
  const SplynxUrl = "http://217.14.87.28/admin/login/";
  const SalesforceUrl = "https://inq.my.salesforce.com/";
  const SageX3Url = "https://inq.cloud.sage.co.za/";
  const PayspaceUrl = "https://identity.yourhcm.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dnextgen%26redirect_uri%3Dhttps%253A%252F%252Fsecure.payspace.com%252Fnextgen%252Fsignin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520offline_access%2520nextgen.application%2520api.full_access%26response_mode%3Dform_post%26nonce%3D637816457073479585.MTEyNmVjMDgtYWIxYy00YzEwLTgzYWQtMGVjYTU2YTJhYjVjMmJmNjY4NzQtYjI0MC00ZGEyLWFhYTgtZTY0NTdkNzY4NjBj%26acr_values%3Dtenant%253Asecure.payspace.com%26ui_locales%3Den-US%26state%3DCfDJ8EJZ0kq96PRIqmF-gc1SwRw6hPzSlrgVMIS6_M8wXNCPzgvG1SVYaQ4ga-46KhQi2i6LRm-UCDMQcIlmYkg0uOJtoLddWy6iWYL0C9kpgEVaHA7pPF5JnT32eNe2oL0TdisfKnCAn8zyYTmtpQxJaDpYBF18zpjDzIskiA3Z3udxdU5x3CPtewKK51eBuYrMAWNTnw9iWrYlIBsQcWNrWs8V_ZX2mSEJfD0bWs3Uw7BDGpJiyUhgow6xuVY98TGjt721QuW8VIBQKtLr2D6Kw9ZSNN_oTIldFSn_dVIXSkuSRxZiU_dBWhk4laEgnx47vw"
  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header title={t('otherServices.label')} />
      <Grid container>
        <Grid item xs={4}>
          <Grid className={classes.padding10}>
            <Card className={classes.card} onClick={() => window.open(ElasticUrl)}>
              <CardActionArea className={classes.padding10}>
                <img
                  width="150px"
                  height="60px"
                  src={ElasticImage}
                  alt="Elastic IP"
                />
                <br /><br />
                <Button className={classes.fontSize20} color="primary" target="_blank" variant="text" href={ElasticUrl}>
                  Elastic IP
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.padding10}>
            <Card className={classes.card} onClick={() => window.open(SplynxUrl)}>
              <CardActionArea className={classes.padding10}>
                <img
                  width="150px"
                  height="60px"
                  src={SplynxImage}
                  alt="Splynx"
                />
                <br /><br />
                <Button className={classes.fontSize20} color="primary" target="_blank" variant="text" href={SplynxUrl}>
                  Splynx
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.padding10}>
            <Card className={classes.card} onClick={() => window.open(SalesforceUrl)}>
              <CardActionArea className={classes.padding10}>
                <img
                  width="150px"
                  height="60px"
                  src={SalesforceImage}
                  alt="Salesforce"
                />
                <br />
                <br />
                <Button className={classes.fontSize20} color="primary" target="_blank" variant="text" href={SalesforceUrl}>
                Salesforce
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.padding10}>
            <Card className={classes.card} onClick={() => window.open(SageX3Url)}>
              <CardActionArea className={classes.padding10}>
                <img
                  width="150px"
                  height="60px"
                  src={SageImage}
                  alt="SageX3"
                />
                <br />
                <br />
                <Button className={classes.fontSize20} color="primary" target="_blank" variant="text" href={SageX3Url}>
                SageX3
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.padding10}>
            <Card className={classes.card} onClick={() => window.open(PayspaceUrl)}>
              <CardActionArea className={classes.padding10}>
                <img
                  width="150px"
                  height="60px"
                  src={PaySpaceImage}
                  alt="Payspace"
                />
                <br />
                <br />
                <Button className={classes.fontSize20} color="primary" target="_blank" variant="text" href={PayspaceUrl}>
                Payspace
                </Button>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withTranslation()(OtherServices);