import React,{ useContext,forwardRef }  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { ShayonaCementsContext } from '../../../../../../contexts/ShayonaCementsProvider';
import { dateFormatterLLL } from '../../../../../../utils';


const PrintSlip = forwardRef(({
    t,
    type,
    truckDetails,
    formDetails
}, ref) => {
  const {
    data
  } = useContext(ShayonaCementsContext);
  return (
    <div ref={ref}>
      <Grid container>
        <Grid item xs="5" />
        <Grid item xs="6">
          <Typography variant={'h1'}>{data?.name}</Typography>
        </Grid>   
      </Grid>
      <Grid container>
        <Grid item xs="3">
          <Typography variant={'h6'}>Slip no:</Typography>
        </Grid> 
        <Grid item xs="2">
          <Typography variant={'h6'}>{truckDetails?.trip_id}</Typography>
        </Grid>  
        {type !== 'exit' && (
          <>
          <Grid item xs="2">
            <Typography variant={'h6'}>Truck no:</Typography>
          </Grid> 
          <Grid item xs="3">
            <Typography variant={'h6'}>{truckDetails?.truck_number}</Typography>
          </Grid> 
        </>  
        )}
      </Grid>

      {/*entry slip */}
      { type === 'entry' && (
        <>
        <Grid container> 
          <Grid item xs="5">
          <Typography variant={'h6'}>Number of bags to be loaded:</Typography>
          </Grid>
          <Grid item xs="6">
            <Typography variant={'h6'}>{formDetails?.bagsAllocated}</Typography>
          </Grid>    
        </Grid>
        <Grid container> 
          <Grid item xs="5">
          <Typography variant={'h6'}>Date:</Typography>
          </Grid>
          <Grid item xs="6">
            <Typography variant={'h6'}>{dateFormatterLLL(truckDetails?.detected_on)}</Typography>
          </Grid>    
        </Grid>
        </>
      )}

        {/*slip_print slip */}
        { type === 'slip_print' && (
        <>
        <Grid container> 
          <Grid item xs="5">
          <Typography variant={'h6'}>Number of bags to be loaded:</Typography>
          </Grid>
          <Grid item xs="6">
            <Typography variant={'h6'}>{truckDetails?.bags_allocated}</Typography>
          </Grid>    
        </Grid>
        <Grid container> 
          <Grid item xs="5">
          <Typography variant={'h6'}>Date:</Typography>
          </Grid>
          <Grid item xs="6">
            <Typography variant={'h6'}>{dateFormatterLLL(truckDetails?.detected_on)}</Typography>
          </Grid>    
        </Grid>
        </>
      )}
      {/* exit slip */}
      {
        type === 'exit' && ( 
          <>
          <Grid container> 
            <Grid item xs="3">
            <Typography variant={'h6'}>Truck Number :</Typography>
            </Grid>
            <Grid item xs="3">
              <Typography variant={'h6'}>{truckDetails?.truck_number}</Typography>
            </Grid> 
            <Grid item xs="3">
            <Typography variant={'h6'}>Bags to be loaded :</Typography>
            </Grid>
            <Grid item xs="3">
              <Typography variant={'h6'}>{truckDetails?.bags_allocated}</Typography>
            </Grid>    
         </Grid>
        <Grid container> 
          <Grid item xs="3">
          <Typography variant={'h6'}>Bags Loaded :</Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant={'h6'}>{truckDetails?.bags_loaded}</Typography>
          </Grid> 
          <Grid item xs="3">
          <Typography variant={'h6'}>Empty Weight :</Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant={'h6'}>{truckDetails?.empty_weight}</Typography>
          </Grid>    
        </Grid>
        <Grid container> 
          <Grid item xs="3">
          <Typography variant={'h6'}>Gross Weight :</Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant={'h6'}>{formDetails?.grossWeight}</Typography>
          </Grid>
          <Grid item xs="3">
          <Typography variant={'h6'}>Date :</Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant={'h6'}>{dateFormatterLLL(truckDetails?.detected_on)}</Typography>
          </Grid>   
        </Grid>
        </>
      )}

    </div>
  )
})

export default PrintSlip