import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator, paddingGenerator } from '../../theme/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const roleList = [
  { id: '1', value: 'countryadmin' },
  { id: '2', value: 'eadmin' },
  { id: '3', value: 'finance' },
  { id: '4', value: 'user' },
  { id: '5', value: 'it' },
  { id: '6', value: 'csoc_manager' },
  { id: '7', value: 'csoc' },
  { id: '8', value: 'third_party_vendor' }
];

const initState = {
  firstName: '',
  lastName: '',

  role: '',
  id: ''
};
function EditUsers({
  open,
  handleClose,
  handleSubmit,
  buttonLoading,
  clearModal,
  data,
  t
}) {
  const [formData, setFormData] = React.useState(initState);
  const [role, setRole] = useState('');

  useEffect(() => {
    if (clearModal) {
      setFormData(initState);
    }
  }, [clearModal]);

  useEffect(() => {
    if (data) {
      setFormData({
        firstName: data.firstName,
        lastName: data.lastName,

        role: data.role,
        id: data.id
      });
    }
  }, [data]);

  const handleFormData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitData = () => {
    handleSubmit(formData);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Users</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="First Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="firstName"
                value={formData.firstName}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Last Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="lastName"
                value={formData.lastName}
                onChange={handleFormData}
              />
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Email Address"
                type="email"
                variant="outlined"
                name="email"
                fullWidth={true}
                value={formData.email}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Phone Number"
                type="number"
                variant="outlined"
                fullWidth={true}
                name="phone"
                value={formData.phone}
                onChange={handleFormData}
              />
            </Grid> */}

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="role"
                  value={formData.role}
                  onChange={handleFormData}
                  label="Role">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {roleList &&
                    roleList.map((data, index) => (
                      <MenuItem key={index} value={data.value}>
                        {data.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(EditUsers);
