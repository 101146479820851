import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '15px',
    marginBottom: '50px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const serverRoomInitFields = {
  fullName: '',
  reportedDateTime: null,
  designation: '',
  serverRoom: '',

  allIdentifiedIssuesCorrected: '',
  allIdentifiedIssuesCorrectedComments: '',
  correctionsOfPrevIssue: '',
  correctionsOfPrevIssueComments: '',
  allAreaCleaned: '',
  allAreaCleanedComments: '',
  allWalkabouts: '',
  allWalkaboutsComments: '',
  floorsLiquidsFree: '',
  floorsLiquidsFreeComments: '',
  flammableMaterialsStored: '',
  flammableMaterialsStoredComments: '',
  otherHousekeepingIssue: '',
  otherHousekeepingIssueComments: '',
  temperatureHumidityStandard: '',
  temperatureHumidityStandardComments: '',
  airflowCondition: '',
  airflowConditionComments: '',
  floorRaised: '',
  floorRaisedComments: '',
  automatedMonitoringSystem: '',
  automatedMonitoringSystemComments: '',
  emsAlertSent: '',
  emsAlertSentComments: '',
  powerUps: '',
  powerUpsComments: '',
  electricalCircuitBreakers: '',
  electricalCircuitBreakersComments: '',
  voltageAndCurrentCapacities: '',
  voltageAndCurrentCapacitiesComments: '',
  enoughPowerOutlets: '',
  enoughPowerOutletsComments: '',
  fireDetectionInstalled: '',
  fireDetectionInstalledComments: '',
  fireExtinguishEquip: '',
  fireExtinguishEquipComments: '',
  firePanelInstalled: '',
  firePanelInstalledComments: '',
  accessControlInstalled: '',
  accessControlInstalledComments: '',
  securityAlarmInstalled: '',
  securityAlarmInstalledComments: '',
  videoCamera: '',
  videoCameraComments: '',
  upsDate: null,
  upsComments: '',
  airConditionerDate: null,
  airConditionerComments: '',
  emsSensorsIndicatorsDate: null,
  emsSensorsIndicatorsComments: '',
  humidityTemperatureSensorsDate: null,
  humidityTemperatureSensorsComments: '',
  fireExtinguishersDate: null,
  fireExtinguishersComments: '',
  fireDetectionSensorsDate: null,
  fireDetectionSensorsComments: '',

  imHeadId: '',
  imHeadName: '',
  imHeadDate: null,

  imSectionHeadId: '',
  imSectionHeadName: '',
  imSectionHeadDate: null
};

const ServerRoomInspectionForm = ({
  t,
  open,
  handleClose,
  handleOpenSignPanModal,
  handleCreateServerRoomSubmit,
  data,
  users
}) => {
  const classes = useStyles();

  const [serverRoomFormState, setServerRoomFormState] = useState({
    ...serverRoomInitFields
  });

  React.useEffect(() => {
    if (!open) {
      setServerRoomFormState({ ...serverRoomInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data.id) {
      const id = data.id;
      setServerRoomFormState({ ...data, id });
    }
  }, [data]);

  const handleChange = (value, field) => {
    setServerRoomFormState({ ...serverRoomFormState, [field]: value });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        <HrHeader />
      </DialogTitle>
      <DialogContent>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">
                IM Server Rooms Inspection Checklist
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Input
                type={'text'}
                placeholder={'Full Name'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={serverRoomFormState.fullName}
                onChange={evt => handleChange(evt.target.value, 'fullName')}
              />
            </Grid>
            <Grid item xs={4}>
              <DateTimeSelector
                inputStyle={{ minWidth: 260 }}
                label={'Date & Time Reported'}
                dateFormat={'yyyy-MM-dd HH:mm'}
                value={serverRoomFormState.reportedDateTime}
                handleChange={newDate =>
                  handleChange(newDate, 'reportedDateTime')
                }
                disableFuture={true}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type={'text'}
                placeholder={'Designation'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={serverRoomFormState.designation}
                onChange={evt => handleChange(evt.target.value, 'designation')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('userSign')}>
                Signature <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <Input
                type={'text'}
                placeholder={'Server Room'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={serverRoomFormState.serverRoom}
                onChange={evt => handleChange(evt.target.value, 'serverRoom')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                This inspection checklist monitors the compliance activities in
                the Server rooms. It also serves as a hazard
                assessmenttocurrentactivities.
                TheinspectionshallbecompletedinallMCMServerroomsonamonthlybases.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                All corrections are expected to be completed in a timely manner.
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Review for Prior Corrections</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Have all identified issues been corrected and noted on the
              previous server room inspection summary?
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="allIdentifiedIssuesCorrected"
                name="allIdentifiedIssuesCorrected"
                value={serverRoomFormState.allIdentifiedIssuesCorrected}
                onChange={event => {
                  handleChange(
                    event.target.value,
                    `allIdentifiedIssuesCorrected`
                  );
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'allIdentifiedIssuesCorrectedComments'
                  )
                }
                value={serverRoomFormState.allIdentifiedIssuesCorrectedComments}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are all corrections of previously identified issues still
              effective and not recurring?
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="correctionsOfPrevIssue"
                name="correctionsOfPrevIssue"
                value={serverRoomFormState.correctionsOfPrevIssue}
                onChange={event => {
                  handleChange(event.target.value, `correctionsOfPrevIssue`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'correctionsOfPrevIssueComments'
                  )
                }
                value={serverRoomFormState.correctionsOfPrevIssueComments}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Housekeeping</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are all areas cleaned on a regular basis?
            </Typography>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="allAreaCleaned"
                name="allAreaCleaned"
                value={serverRoomFormState.allAreaCleaned}
                onChange={event => {
                  handleChange(event.target.value, `allAreaCleaned`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'allAreaCleanedComments')
                }
                value={serverRoomFormState.allAreaCleanedComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are all walkabouts at least 15 inches and free from other items
              that could cause a tripping hazard?
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="allWalkabouts"
                name="allWalkabouts"
                value={serverRoomFormState.allWalkabouts}
                onChange={event => {
                  handleChange(event.target.value, `allWalkabouts`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'allWalkaboutsComments')
                }
                value={serverRoomFormState.allWalkaboutsComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are all floors free of liquids to avoid trips and falls?
            </Typography>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="floorsLiquidsFree"
                name="floorsLiquidsFree"
                value={serverRoomFormState.floorsLiquidsFree}
                onChange={event => {
                  handleChange(event.target.value, `floorsLiquidsFree`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'floorsLiquidsFreeComments')
                }
                value={serverRoomFormState.floorsLiquidsFreeComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are flammable materials such as cardboard and paper stored in the
              server room
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="flammableMaterialsStored"
                name="flammableMaterialsStored"
                value={serverRoomFormState.flammableMaterialsStored}
                onChange={event => {
                  handleChange(event.target.value, `flammableMaterialsStored`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'flammableMaterialsStoredComments'
                  )
                }
                value={serverRoomFormState.flammableMaterialsStoredComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are there any other housekeeping issues that need to be addressed?
            </Typography>
            {/* <Grid item xs={6}>
                                <RadioGroup
                                    row
                                    aria-label="otherHousekeepingIssue"
                                    name="otherHousekeepingIssue"
                                    value={serverRoomFormState.otherHousekeepingIssue}
                                    onChange={(event) => {
                                        handleChange(event.target.value, `otherHousekeepingIssue`);
                                    }}
                                >
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="Yes"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        labelPlacement="start"
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="No"
                                        labelPlacement="start"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value="N/A"
                                        labelPlacement="start"
                                        control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </Grid> */}
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'otherHousekeepingIssueComments'
                  )
                }
                value={serverRoomFormState.otherHousekeepingIssueComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Environmental Check</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Does the server room meet the temperature and humidity standards?
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="temperatureHumidityStandard"
                name="temperatureHumidityStandard"
                value={serverRoomFormState.temperatureHumidityStandard}
                onChange={event => {
                  handleChange(
                    event.target.value,
                    `temperatureHumidityStandard`
                  );
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'temperatureHumidityStandardComments'
                  )
                }
                value={serverRoomFormState.temperatureHumidityStandardComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Does server room conditioners provide sufficient front to back
              airflow?
            </Typography>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="airflowCondition"
                name="airflowCondition"
                value={serverRoomFormState.airflowCondition}
                onChange={event => {
                  handleChange(event.target.value, `airflowCondition`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'airflowConditionComments')
                }
                value={serverRoomFormState.airflowConditionComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is server room floor raised?
            </Typography>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="floorRaised"
                name="floorRaised"
                value={serverRoomFormState.floorRaised}
                onChange={event => {
                  handleChange(event.target.value, `floorRaised`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'floorRaisedComments')
                }
                value={serverRoomFormState.floorRaisedComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Do you have an automated Environmental Monitoring System?
            </Typography>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="automatedMonitoringSystem"
                name="automatedMonitoringSystem"
                value={serverRoomFormState.automatedMonitoringSystem}
                onChange={event => {
                  handleChange(event.target.value, `automatedMonitoringSystem`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'automatedMonitoringSystemComments'
                  )
                }
                value={serverRoomFormState.automatedMonitoringSystemComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are alerts being sent from EMS?
            </Typography>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="emsAlertSent"
                name="emsAlertSent"
                value={serverRoomFormState.emsAlertSent}
                onChange={event => {
                  handleChange(event.target.value, `emsAlertSent`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'emsAlertSentComments')
                }
                value={serverRoomFormState.emsAlertSentComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Power</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is there a UPS to power the equipment?
            </Typography>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="powerUps"
                name="powerUps"
                value={serverRoomFormState.powerUps}
                onChange={event => {
                  handleChange(event.target.value, `powerUps`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'powerUpsComments')
                }
                value={serverRoomFormState.powerUpsComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are all electrical circuit breakers identified?
            </Typography>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="electricalCircuitBreakers"
                name="electricalCircuitBreakers"
                value={serverRoomFormState.electricalCircuitBreakers}
                onChange={event => {
                  handleChange(event.target.value, `electricalCircuitBreakers`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'electricalCircuitBreakersComments'
                  )
                }
                value={serverRoomFormState.electricalCircuitBreakersComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are the circuit breakers for the equipment suitable in terms of
              voltage and current-carrying capacities?
            </Typography>
            {/* <Grid item xs={4}/> */}
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="voltageAndCurrentCapacities"
                name="voltageAndCurrentCapacities"
                value={serverRoomFormState.voltageAndCurrentCapacities}
                onChange={event => {
                  handleChange(
                    event.target.value,
                    `voltageAndCurrentCapacities`
                  );
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'voltageAndCurrentCapacitiesComments'
                  )
                }
                value={serverRoomFormState.voltageAndCurrentCapacitiesComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are enough power outlets provided within 2 meters (6.5 feet) for
              each rack?
            </Typography>
            {/* <Grid item xs={4}/> */}
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="enoughPowerOutlets"
                name="enoughPowerOutlets"
                value={serverRoomFormState.enoughPowerOutlets}
                onChange={event => {
                  handleChange(event.target.value, `enoughPowerOutlets`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'enoughPowerOutletsComments')
                }
                value={serverRoomFormState.enoughPowerOutletsComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Safety Checklist</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is the fire detection and suppression system installed in the
              server room?
            </Typography>
            {/* <Grid item xs={4}/> */}
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="fireDetectionInstalled"
                name="fireDetectionInstalled"
                value={serverRoomFormState.fireDetectionInstalled}
                onChange={event => {
                  handleChange(event.target.value, `fireDetectionInstalled`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'fireDetectionInstalledComments'
                  )
                }
                value={serverRoomFormState.fireDetectionInstalledComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is the server room adequately equipped to extinguish a fire?
            </Typography>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="fireExtinguishEquip"
                name="fireExtinguishEquip"
                value={serverRoomFormState.fireExtinguishEquip}
                onChange={event => {
                  handleChange(event.target.value, `fireExtinguishEquip`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'fireExtinguishEquipComments')
                }
                value={serverRoomFormState.fireExtinguishEquipComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is there a fire panel installed in the server room?
            </Typography>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="firePanelInstalled"
                name="firePanelInstalled"
                value={serverRoomFormState.firePanelInstalled}
                onChange={event => {
                  handleChange(event.target.value, `firePanelInstalled`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'firePanelInstalledComments')
                }
                value={serverRoomFormState.firePanelInstalledComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Security</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is there Access Control system installed?
            </Typography>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="accessControlInstalled"
                name="accessControlInstalled"
                value={serverRoomFormState.accessControlInstalled}
                onChange={event => {
                  handleChange(event.target.value, `accessControlInstalled`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'accessControlInstalledComments'
                  )
                }
                value={serverRoomFormState.accessControlInstalledComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Is there any security alarm installed?
            </Typography>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="securityAlarmInstalled"
                name="securityAlarmInstalled"
                value={serverRoomFormState.securityAlarmInstalled}
                onChange={event => {
                  handleChange(event.target.value, `securityAlarmInstalled`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'securityAlarmInstalledComments'
                  )
                }
                value={serverRoomFormState.securityAlarmInstalledComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle2"
              style={{ paddingTop: 10, display: 'inline-block' }}
              color="textPrimary">
              Are there surveillance video camera in the server room?
            </Typography>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label="videoCamera"
                name="videoCamera"
                value={serverRoomFormState.videoCamera}
                onChange={event => {
                  handleChange(event.target.value, `videoCamera`);
                }}>
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="Yes"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  labelPlacement="start"
                  label="Yes"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="No"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  style={{ ...marginGenerator('mb-8') }}
                  value="N/A"
                  labelPlacement="start"
                  control={
                    <Radio
                      style={{ border: '0px', ...marginGenerator('mr-8') }}
                    />
                  }
                  label="N/A"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'videoCameraComments')
                }
                value={serverRoomFormState.videoCameraComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Maintenance Plan</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                Uninterrupted Power Supply(UPS)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.upsDate}
                handleChange={newDate => handleChange(newDate, 'upsDate')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt => handleChange(evt.target.value, 'upsComments')}
                value={serverRoomFormState.upsComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                Air Conditioner
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.airConditionerDate}
                handleChange={newDate =>
                  handleChange(newDate, 'airConditionerDate')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'airConditionerComments')
                }
                value={serverRoomFormState.airConditionerComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                EMS Sensors and Indicators
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.emsSensorsIndicatorsDate}
                handleChange={newDate =>
                  handleChange(newDate, 'emsSensorsIndicatorsDate')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'emsSensorsIndicatorsComments')
                }
                value={serverRoomFormState.emsSensorsIndicatorsComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                Humidity and Temperature Sensors
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.humidityTemperatureSensorsDate}
                handleChange={newDate =>
                  handleChange(newDate, 'humidityTemperatureSensorsDate')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(
                    evt.target.value,
                    'humidityTemperatureSensorsComments'
                  )
                }
                value={serverRoomFormState.humidityTemperatureSensorsComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                Fire Extinguishers
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.fireExtinguishersDate}
                handleChange={newDate =>
                  handleChange(newDate, 'fireExtinguishersDate')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'fireExtinguishersComments')
                }
                value={serverRoomFormState.fireExtinguishersComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                style={{ paddingTop: 20, display: 'inline-block' }}
                color="textPrimary">
                Fire Detection Sensors
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 190 }}
                label={'Date inspected'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.fireDetectionSensorsDate}
                handleChange={newDate =>
                  handleChange(newDate, 'fireDetectionSensorsDate')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'fireDetectionSensorsComments')
                }
                value={serverRoomFormState.fireDetectionSensorsComments}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">INSPECTION SIGN OFF</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Head of IM</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={3}>
              <OutlinedSelect
                options={users && users.length ? users : []}
                label="User"
                disabled={data && data.authorisationSign ? true : false}
                val={serverRoomFormState.imHeadId}
                handleChange={evt => handleChange(evt.target.value, 'imHeadId')}
                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('imHeadSign')}>
                Sign <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <DateTimeSelector
                inputStyle={{ minWidth: 280 }}
                label={'Date'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.imHeadDate}
                handleChange={newDate => handleChange(newDate, 'imHeadDate')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Section Head</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={3}>
              <OutlinedSelect
                options={users && users.length ? users : []}
                label="User"
                disabled={data && data.authorisationSign ? true : false}
                val={serverRoomFormState.imSectionHeadId}
                handleChange={evt =>
                  handleChange(evt.target.value, 'imSectionHeadId')
                }
                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('imSectionHeadSign')}>
                Sign <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <DateTimeSelector
                inputStyle={{ minWidth: 280 }}
                label={'Date'}
                dateFormat={'yyyy-MM-dd'}
                value={serverRoomFormState.imSectionHeadDate}
                handleChange={newDate =>
                  handleChange(newDate, 'imSectionHeadDate')
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              handleCreateServerRoomSubmit(serverRoomFormState);
            }}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ServerRoomInspectionForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateServerRoomSubmit: PropTypes.func.isRequired,
  handleOpenSignPanModal: PropTypes.func.isRequired
};

export default withTranslation()(ServerRoomInspectionForm);
