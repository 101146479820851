import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import 'fabric-webpack'
import Message from '../../../../components/Message';

const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
const fabric = window.fabric;

class MultiLineCrosingImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorInitialized: false,
            drawedLine: [],
            selectedImg: '',
            image: null,
            lineObjects: {},
            imageResolution: {},
            imageBase64Url: ""
        }
        // Fabric.js state
        this.lines = {}
        this.mouseDown = false;
    }

    roundOF2 = (num) => {
        return Math.round(num * 100) / 100;
    }

    renderIcon(ctx, left, top, fabricObject) {
        let img = document.createElement('img');
        img.src = deleteIcon;
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);
        ctx.restore();
    }

    componentDidMount() {
        localStorage.removeItem('speed_detection');
        if (this.elCanvas) {
            this.editorCanvas = new fabric.Canvas(this.elCanvas, { selection: false, width: 0.1, height: 0.1 });
            if (localStorage.getItem("editLineCrossData")) {
                let lineData = JSON.parse(localStorage.getItem("editLineCrossData"));
                if (lineData.image) {
                    console.log("line data", lineData);
                    let imgData = lineData.image;
                    let img = new Image();
                    img.src = imgData;
                    img.onload = (e) => {
                        this.mainElem.style = { display: 'block' };
                        this.elCanvas.width = img.width;
                        this.elCanvas.height = img.height;
                        this.setState({ imageResolution: { width: img.width, height: img.height }, imageBase64Url: lineData.image });
                        this.editorCanvas.setDimensions({ width: img.width, height: img.height });
                        this.editorCanvas.setBackgroundImage(lineData.image, this.editorCanvas.renderAll.bind(this.editorCanvas), {
                            backgroundImageOpacity: 0.5,
                            backgroundImageStretch: false
                        });
                        if (lineData.lines && lineData.lines.length > 0) {
                            let existLines = [];
                            for (let i = 0; i < lineData.lines.length; i++) {
                                let line = new fabric.Line(lineData.lines[i].pointers, { ...lineData.lines[i].coordinates });
                                this.editorCanvas.add(line);
                                existLines.push({ relativeCords: lineData.lines[i].relativeCords, element: line, lineObjects: lineData.lines[i].objects, name: lineData.lines[i].name, thresh_conf: parseFloat(lineData.lines[i].thresh_conf) });
                            }
                            this.setState({ drawedLine: existLines });
                            localStorage.removeItem("editLineCrossData");
                        }
                    };
                }
            }
            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = 'blue';
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.controls.deleteControl = new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetY: 16,
                cursorStyle: 'pointer',
                mouseUpHandler: this.deleteObject,
                render: this.renderIcon,
                cornerSize: 24
            });
        }
    }

    handleOnSelectImage = (evt) => {
        if (evt.target.files && evt.target.files.length) {
            if (this.state.drawedLine.length) {
                this.editorCanvas.remove(this.state.drawedLine[0].element);
            }
            this.setState({ selectedImg: evt.target.value });
            const file = evt.target.files[0];
            let img = new Image();
            img.src = URL.createObjectURL(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                this.mainElem.style = { display: 'block' };
                this.elCanvas.width = img.width;
                this.elCanvas.height = img.height;
                this.setState({ imageResolution: { width: img.width, height: img.height } });
                this.editorCanvas.setDimensions({ width: img.width, height: img.height });
                this.editorCanvas.setBackgroundImage(reader.result, this.editorCanvas.renderAll.bind(this.editorCanvas), {
                    backgroundImageOpacity: 0.5,
                    backgroundImageStretch: false
                });
                if (localStorage.getItem("editLineCrossData")) {
                    let lineData = JSON.parse(localStorage.getItem("editLineCrossData"));
                    if (lineData.lines && lineData.lines.length > 0) {
                        let existLines = [];
                        for (let i = 0; i < lineData.lines.length; i++) {
                            let line = new fabric.Line(lineData.lines[i].pointers, { ...lineData.lines[i].coordinates });
                            this.editorCanvas.add(line);
                            existLines.push({ relativeCords: lineData.lines[i].relativeCords, element: line, lineObjects: lineData.lines[i].objects, name: lineData.lines[i].name, thresh_conf: parseFloat(lineData.lines[i].thresh_conf) });
                        }
                        this.setState({ drawedLine: existLines });
                    }
                    localStorage.removeItem("editLineCrossData");
                }
            };
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({ imageBase64Url: reader.result });
            };
            this.setState({ image: evt.target.value });
        }
    }

    handleSelectObject = (value, indx) => {
        let drawedLine = this.state.drawedLine;
        if (drawedLine[indx]) {
            drawedLine[indx].lineObjects = value;
        }
        this.setState({ drawedLine });
    }

    handleDeleteLine = (index) => {
        if (this.state.drawedLine[index]) {
            this.editorCanvas.remove(this.state.drawedLine[index].element);
            const drawedLine = this.state.drawedLine;
            drawedLine.splice(index, 1);
            this.setState({ drawedLine });
        }
    }

    deleteObject = (eventData, transform) => {
        let target = transform.target;
        let canvas = target.canvas;
        canvas.remove(target);
        this.setState({ count: this.state.count-1 });
        canvas.requestRenderAll();
    }

    handleSaveCordinates = () => {
        let objects = this.editorCanvas.getObjects();
        const lineCordsArray = [];
        objects.map((activeObject) => {
            const { tl, tr, br, bl } = activeObject.lineCoords;
            console.log("activeObject.lineCoords: ", activeObject.lineCoords);
            let lineCords = { x1: tl.x, y1: tl.y, x2: tr.x, y2: tr.y, x3: br.x, y3: br.y, x4: bl.x, y4: bl.y };
            lineCords.x1 = this.roundOF2(parseInt(lineCords.x1));
            lineCords.y1 = this.roundOF2(parseInt(lineCords.y1));
            lineCords.x2 = this.roundOF2(parseInt(lineCords.x2));
            lineCords.y2 = this.roundOF2(parseInt(lineCords.y2));
            lineCords.x3 = this.roundOF2(parseInt(lineCords.x3));
            lineCords.y3 = this.roundOF2(parseInt(lineCords.y3));
            lineCords.x4 = this.roundOF2(parseInt(lineCords.x4));
            lineCords.y4 = this.roundOF2(parseInt(lineCords.y4));
            lineCordsArray.push(lineCords);
        });
        // console.log("lineCordsArray: ", lineCordsArray);
        // return null;
        localStorage.setItem('speed_detection', JSON.stringify(lineCordsArray));
        window.close();
    }

    createNewShape = () => {
        // Define the polygon's points (coordinates)
        // const points = [
        //     { x: 100, y: 100 },
        //     { x: 150, y: 50 },
        //     { x: 200, y: 100 },
        //     { x: 150, y: 150 }
        // ];
        
        // Create a polygon
        const polygon = new fabric.Rect({
            left: 250,
            top: 150,
            fill: '',
            width: 300,
            height: 150,
            objectCaching: false,
            hasRotatingPoint: false,
            stroke: 'red',
            strokeWidth: 2,
        });

        this.editorCanvas.add(polygon);
        this.editorCanvas.setActiveObject(polygon);
        this.setState({ count: this.state.count + 1, created: true });
    }

    render() {
        return (
            <>
                <div
                    ref={el => this.mainElem = el}
                    className="counting-areas-editor"
                    style={{ display: 'none' }}
                >
                    <canvas
                        ref={(el) => this.elCanvas = el}
                        width={0}
                        height={0}
                        className="editor-canvas" />
                    <style jsx={'true'}>{`
            .editor-canvas{
              border:1px solid;
            }
            .counting-areas-editor{
              margin: 3%;
            }
            .counting-areas-editor, .editor-canvas  {
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: '2'};
            }

            {/* @media (min-aspect-ratio: 16/9) {
              :global(.canvas-container),.editor-canvas {
                width: 100% !important;
                height: auto !important;
              }
            }
            @media (max-aspect-ratio: 16/9) {
              :global(.canvas-container),.editor-canvas {
                width: auto !important;
                height: 100% !important;
              }
            } */}
          `}</style>
                </div>
                <div style={{ marginBottom: 50 }}>
                    <Grid style={{ marginBottom: 20, marginLeft: 30, width: '10%' }}>
                        <Input
                            value={this.state.selectedImg}
                            type={'file'}
                            placeholder={`Image *`}
                            style={{ width: '100%', marginTop: "35px" }}
                            color={'secondary'}
                            inputProps={{ accept: "image/*" }}
                            onChange={this.handleOnSelectImage}
                        />
                    </Grid>
                    <Grid style={{ marginBottom: 20, marginLeft: 30, width: '100%', display: "inline-block" }}>
                        <Button
                            disabled={!(this.state.selectedImg) || (this.editorCanvas?.getObjects()?.length >= 2)}
                            color="primary"
                            onClick={this.createNewShape}
                            variant="outlined"
                            style={{ width: "279px", marginRight: 20 }}
                        >
                            Draw
                        </Button>
                        <Button
                            color="primary"
                            variant={'contained'}
                            onClick={() => {
                                this.handleSaveCordinates();
                            }}
                            disabled={!(this.editorCanvas && this.editorCanvas?.getObjects()?.length === 2 )}
                            style={{ width: "279px" }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </div>
            </>
        )
    }
}

export default withTranslation()(MultiLineCrosingImage);