import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_CAMERA_LIST, GET_EDGE_AI_ANALYTICS_DASHBOARD } from '../../../queries';
import { marginGenerator } from '../../../../../theme/utils';
import { REQUIRED_ERROR, MULTI_OBJECTS_LIST } from '../../../../../utils/constants';
import { convertLocalToUTCDate, dateFormatterYYYYMMDD } from '../../../../../utils'
import Message from '../../../../../components/Message';
import OutlinedSelect from "../../../../../components/OutlinedSelect";
import DateTimeSelector from '../../../../../components/DateTimeSelector';
import Autocomplete from '../../../../../components/Autocomplete';
import Statcard from '../../../../../components/Statcard';
import camera from '../../../../../assets/icons/camera.png';
import camera_down from '../../../../../assets/icons/camera_down.png';
import HeatMapChartWrapper from '../../../../../components/Charts/HeatMapChartWrapper';
import ChartContainer from '../../../../../components/Charts/ChartContainer';
import AnalyticsDashboardPieChart from '../../../../../components/Charts/PieChartWrapper';
import BarChartWrapper from '../../../../../components/Charts/BarChartWrapper';
import GenericTable from '../../../../../components/GenericTable';
import AnalyticsDashboardLineChart from '../../../../../components/Charts/AnalyticsDashboardLineChart';
import LineChartWrapper from '../../../../../components/Charts/LineChartWrapper';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    mainHead: {
        textAlign: "center",
        borderTop: "3px solid #e5e5e5",
        paddingTop: 20
    },
    loading: {
        textAlign: "center",
        marginTop: 30,
        marginBottom: 35
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    const classes = useStyles();
    return (
        <Button
        variant="outlined" 
        size="medium" 
        color="primary"
        className={classes.searcBtn}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function valuetext(value) {
    return `${value}°C`;
}

const customDateFormat1 = (date) => {
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
    return dateStr;
}

const objectColorCodes = {
    car: ['#1A2980', '#26D0CE'], 
    person: ['#e52d27', '#b31217'], 
    bus: ['#E5E5BE', '#003973'], 
    "cell phone": ['#1488CC', '#2B32B2'], 
    bicycle: ['#B5AC49', '#3CA55C'], 
    truck: ['#AA076B', '#61045F'], 
    motorbike: ['#b29f94', '#603813']
};

const initReportData = {multi_object: {}, face_recognition: {}, license_plate_recognition: {}, line_cross: {}, counts: {config_template_count: 0, down_camera_count: 0, avg_dwell_time: 0, avg_people: 0}};

const AnalyticsDashboardPanel = ({ 
    t,
    data
}) => {

    const classes = useStyles();
    const [filterState, setFilterState] = useState({cameraId: [], startDate: null, endDate: null, threshold: "", selectedObject: ""});
    const [cameraList, setCameraList] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [reportData, setReportData] = useState({...initReportData});
    const [reportVisable, setReportVisable] = useState(false);
    const [totalCameraCount, setTotalCameraCount] = useState(0);
    const [configTemplates, setConfigTemplates] = useState([]);
    const [downCameraCount, setDownCameraCount] = useState(0);
    const [initLoading, setInitLoading] = useState(false);
    const [reportDataLoading, setReportDataLoading] = useState(false);

    const [getCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: camRes => {
            setTotalCameraCount(camRes.getAllCameraList && camRes.getAllCameraList.count ? camRes.getAllCameraList.count : 0);
            const camList = (camRes.getAllCameraList && camRes.getAllCameraList.cameras) ? camRes.getAllCameraList.cameras.map(item => {
                let schedules = item.schedules && item.schedules.length ?  item.schedules[0].map(mdl => mdl.model_template && mdl.model_template.model_type ? mdl.model_template.model_type : mdl.model_template) : [];
                return {name: item.name, id: item.camera_id, config_templates: schedules};
            }) : [];
            setCameraList(camList);
            if(!initLoading){
                setReportData({...initReportData});
                setReportVisable(false);
                setReportDataLoading(true);
                let startDate = new Date();
                startDate.setDate(startDate.getDate()-3);
                startDate = new Date(`${dateFormatterYYYYMMDD(startDate)} 00:00:00 AM`);
                let endDate = new Date();
                setFilterState({cameraId: camList, startDate, endDate, selectedObject: "", threshold: ""});
                startDate = new Date(convertLocalToUTCDate(startDate)).getTime()/1000;
                endDate = new Date(convertLocalToUTCDate(endDate)).getTime()/1000;
                let variables = {
                    orgId: data.organizationId,
                    cameraId: camList,
                    object: "",
                    threshold: 0,
                    startDate,
                    endDate
                }
                let templatesArr = [];
                if(camList && camList.length){
                    templatesArr = camList.map(itm => itm.config_templates);
                    templatesArr = templatesArr.reduce(function (r, a) { return r.concat(a); }, []);
                    templatesArr = [...new Set(templatesArr)];
                    setConfigTemplates(templatesArr);
                }
                return getAnalyticsDashboardReport({variables});
            }
        }
    });

    useEffect(() => {
        if(!initLoading && cameraList.length){
            setInitLoading(true);
        }
    }, [cameraList]);

    const [getDownCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setDownCameraCount(data.getAllCameraList && data.getAllCameraList.count ? data.getAllCameraList.count : 0);
        }
    });

    const updateLicensePlateReport = (lpData) => {
        if(lpData){
            let plateCountData   = (lpData.data && lpData.data.length > 0) ? lpData.data : [];
            let uniquePlateBarChartData   = [];
            let repetedPlatesPieChartData = [];
            let totalUniquePlatesCount    = 0;
            let totalPlatesCount          = 0;

            if(plateCountData.length){
                uniquePlateBarChartData = plateCountData.map(itm => {
                    let date = new Date(itm.ts.value);
                    date = moment(date).format('YYYY-MM-DD HH:SS');
                    totalUniquePlatesCount = totalUniquePlatesCount+parseInt(itm.plate_count);
                    return {name: date , count: itm.plate_count};
                });
            }
            let stackKeyNames = (lpData.repeatedPlates && lpData.repeatedPlates.length > 0) ? lpData.repeatedPlates.map(itm => {
                let pltNoArr = itm.PLATE_NUMBER.split(":");
                return (pltNoArr && pltNoArr.length) ? pltNoArr[0] : itm;
            }) : [];
            stackKeyNames = [...new Set(stackKeyNames)];
            let maxValueDataArr = [];
            if(lpData.repeatedPlates && lpData.repeatedPlates.length > 0){
                lpData.repeatedPlates.map(itm => {
                    let pieTmpObj = {value: itm.plate_count, name: itm.PLATE_NUMBER};
                    totalPlatesCount = totalPlatesCount+parseInt(itm.plate_count);
                    maxValueDataArr.push(itm.plate_count);
                    repetedPlatesPieChartData.push(pieTmpObj);
                });
            }
            return {maxValue: maxValueDataArr.length ? Math.max(...maxValueDataArr) : 0 ,totalPlatesCount, totalUniquePlatesCount, repetedPlatesPieChartData, uniquePlateBarChartData, stackKeyNames};
        }
    }

    const updateMultiObjectReport = (mlObjData) => {
        let dwellTimeData = mlObjData.dwell_time_report ? mlObjData.dwell_time_report : [];
        let highestObjectDetection = mlObjData.highest_object_detections ? mlObjData.highest_object_detections : [];
        let highest_count = mlObjData.highest_count ? mlObjData.highest_count : 0;

        console.log("mlObjData: ", mlObjData);

        let top5ObjectPieChartData   = [];
        let top5ObjectPieChartStack  = [];
        let dwellTimeReportData      = {};
        let highestObjReportData     = {};
        let highestPeopleDetection   = [];
        let heatMapDataArr           = [];
        let avgDwellTime = 0;
        if(dwellTimeData.length){
            let dwellTimeLineChartData   = [];
            let countedObj      = {};
            let xAxis           = [];
            let timeArray       = [];
            dwellTimeData = dwellTimeData.map(itm => {
                if(itm.ts){
                    itm.ts.value = customDateFormat1(new Date(itm.ts.value));
                    timeArray.push(itm.ts.value);
                }
                return itm;
            });
            timeArray = [...new Set(timeArray)];
            if(timeArray.length > 0){
                xAxis = timeArray.map(function (val) {
                    let matchObjects = dwellTimeData.filter(s => s.ts.value == val);
                    matchObjects.map(itm => {
                        if(countedObj[val] && typeof countedObj[val][itm.OBJECT_NAME] !== "undefined"){
                            countedObj[val] = {[itm.OBJECT_NAME]: parseInt(countedObj[val][itm.OBJECT_NAME])+itm.wt, count: countedObj[val].count ? countedObj[val].count+1 : 0}
                        }else{
                            countedObj[val] = {[itm.OBJECT_NAME]: itm.wt, count: 1}
                        }
                    });
                    return val;
                });
            }
            for(let obj of MULTI_OBJECTS_LIST){
                let dataArr = [];
                for (let key in countedObj) {
                    if (countedObj.hasOwnProperty(key) && typeof countedObj[key][obj] !== "undefined") {
                        let cntObj = Math.floor(countedObj[key][obj]/countedObj[key].count ? countedObj[key].count : 1);
                        dataArr.push(cntObj);
                        avgDwellTime = avgDwellTime+parseInt(cntObj);
                    }
                }
                let tmpObje = {
                    name: obj,
                    type: 'line',
                    smooth: true,
                    data: dataArr
                };
                dwellTimeLineChartData.push(tmpObje);
            }
            avgDwellTime = avgDwellTime ? Math.round(avgDwellTime/xAxis.length) : 0;
            dwellTimeReportData.dwellTimeLineChartData = dwellTimeLineChartData;
            dwellTimeReportData.dwellTimeDataKeyXAxis = xAxis;
            dwellTimeReportData.dwellTimeStackKeyNames = MULTI_OBJECTS_LIST;
        }

        if(highestObjectDetection.length){
            
            let highObjDetectionChartData   = [];
            let countedObj      = {};
            let xAxis           = [];
            let timeArray       = [];
            let visualMapArr    = [];
            highestObjectDetection = highestObjectDetection.map(itm => {
                if(itm.ts){
                    itm.ts.value = customDateFormat1(new Date(itm.ts.value));
                    timeArray.push(itm.ts.value);
                }
                return itm;
            });
            timeArray = [...new Set(timeArray)];
            if(timeArray.length > 0){
                xAxis = timeArray.map(function (val) {
                    let matchObjects = highestObjectDetection.filter(s => s.ts.value == val);
                    matchObjects.map(itm => {
                        if(countedObj[val] && typeof countedObj[val][itm.OBJECT_NAME] !== "undefined"){
                            countedObj[val] = {[itm.OBJECT_NAME]: countedObj[val][itm.OBJECT_NAME]+Math.round(itm.objectCount)}
                        }else{
                            countedObj[val] = {[itm.OBJECT_NAME]: Math.round(itm.objectCount), count: matchObjects.length}
                        }
                    });
                    return val;
                });
            }
            let seriesIndex = 0;
            for(let obj of MULTI_OBJECTS_LIST){
                let dataArr = [];
                for (let key in countedObj) {
                    if (countedObj.hasOwnProperty(key) && typeof countedObj[key][obj] !== "undefined") {
                        let cntObj = countedObj[key][obj];
                        dataArr.push(cntObj);
                        if(obj === "person"){
                            highestPeopleDetection.push({name: key, count: cntObj ? cntObj : 0});
                        }
                    }
                }
                let boxIndex = 0;
                xAxis.map(itm => {
                    heatMapDataArr.push([seriesIndex, boxIndex, countedObj[itm] && countedObj[itm][obj] ? countedObj[itm][obj] : 0]);
                    boxIndex++;
                });
                let tmpObje = {
                    name: obj,
                    type: 'line',
                    smooth: true,
                    data: dataArr,
                    color: objectColorCodes[obj][0]
                };
                highObjDetectionChartData.push(tmpObje);
                let tmpVsMap = {
                    show: false,
                    type: 'continuous',
                    seriesIndex: seriesIndex,
                    dimension: 0,
                    min: 0,
                    max: dataArr.length ? Math.max(...dataArr) : 100,
                    color: objectColorCodes[obj]
                };
                seriesIndex++;
                visualMapArr.push(tmpVsMap);
            }
            console.log("highObjDetectionChartData: ", highObjDetectionChartData);
            highestObjReportData.highObjDetectionChartData = highObjDetectionChartData;
            highestObjReportData.highObjDataKeyXAxis = xAxis;
            highestObjReportData.highObjStackKeyNames = MULTI_OBJECTS_LIST;
            highestObjReportData.visualMap = visualMapArr;
        }
        
        if(mlObjData.to5_objects && mlObjData.to5_objects.length){
            top5ObjectPieChartData = mlObjData.to5_objects.map(itm => {
                top5ObjectPieChartStack.push(itm.OBJECT_NAME);
                return {value: Math.floor(itm.object_count), name: itm.OBJECT_NAME};
            });
        }
        const maxTop5ObjVal = top5ObjectPieChartData.length ? top5ObjectPieChartData.reduce(function(prev, current) {
            return (prev.value > current.value) ? prev : current
        }) : null; //returns object
        
        return {...dwellTimeReportData, ...highestObjReportData, top5ObjectPieChartData, top5ObjectPieChartStack, top5MaxValue: maxTop5ObjVal && maxTop5ObjVal.value ? maxTop5ObjVal.value : 0, highestPeopleDetection, uniquePeopleCount: mlObjData.unique_people_count ? mlObjData.unique_people_count : 0, avgDwellTime, highestPeopleCount: highest_count, heatMapData: heatMapDataArr, heatMapStack: MULTI_OBJECTS_LIST, heatMapxAxis: highestObjReportData.highObjDataKeyXAxis};
    }

    const updateFaceRecoReport = (frData) => {
        if(frData){
            let frBarChartData    = [];
            let frPieChartData    = [];
            let totalUniqueFaceCount = 0;
            let totalFacesCount = 0;
            let faceCountData   = (frData.unique_face_data && frData.unique_face_data.length > 0) ? frData.unique_face_data : [];
            if(faceCountData.length){
                frBarChartData = faceCountData.map(itm => {
                    let date = new Date(itm.date);
                    date = moment(date).format('YYYY-MM-DD HH:SS');
                    totalUniqueFaceCount = totalUniqueFaceCount+parseInt(itm.count);
                    return {name: date , count: itm.count};
                });
            }
            let stackKeyNames = [];
            let maxValueDataArr = [];
            if(frData.repeated_data && frData.repeated_data.length > 0){
                frData.repeated_data.map(itm => {
                    let pieTmpObj = {value: itm.value, name: itm.name};
                    frPieChartData.push(pieTmpObj);
                    stackKeyNames.push(itm.name);
                    totalFacesCount = totalFacesCount+parseInt(itm.value);
                    maxValueDataArr.push(itm.value);
                });
            }
            stackKeyNames = [...new Set(stackKeyNames)];
            return {frPieChartData, frBarChartData, stackKeyNames, totalFacesCount, totalUniqueFaceCount, maxValue: maxValueDataArr.length ? Math.max(...maxValueDataArr) : 0 };
        }
    }

    const updateLineCrossReport = (lineData) => {
        if(lineData){
            let stackKeyNames = [];
            let lineCrossPieChartData = [];
            if(lineData.active_lines && lineData.active_lines.length > 0){
                lineData.active_lines.map(itm => {
                    let pieTmpObj = {value: itm.value, name: itm.name};
                    lineCrossPieChartData.push(pieTmpObj);
                    stackKeyNames.push(itm.name);
                });
                stackKeyNames = [...new Set(stackKeyNames)];
            }
            let lineCrossEvents = [];
            if(lineData.events_count){
                let lines = Object.keys(lineData.events_count);
                lines.map(line => {
                    let lineItem = lineData.events_count[line];
                    let lineObjects = Object.keys(lineItem);
                    lineObjects.map(obj => {
                        return lineItem[obj].map(itm => {
                            let name = `${obj}-${itm.confidence.toString()}`;
                            let tmpObje = {
                                name,
                                type: 'bar',
                                stack: obj,
                                barWidth: 15,
                                data: [320, 332, 301, 334, 390, 130, 230]
                            };
                            lineCrossEvents.push(tmpObje);
                        });
                    });
                });
            }
            return {lineCrossPieChartData, stackKeyNames, lineCrossEvents };
        }
    }

    const [getAnalyticsDashboardReport] = useLazyQuery(GET_EDGE_AI_ANALYTICS_DASHBOARD, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if(data.getAnalyticsDashboardReport && data.getAnalyticsDashboardReport.reportData){
                const dashboardReportData = JSON.parse(data.getAnalyticsDashboardReport.reportData);
                if(dashboardReportData){
                    let fetchedReportData = {};
                    if(dashboardReportData.multi_object){
                        fetchedReportData.multi_object = updateMultiObjectReport(dashboardReportData.multi_object);
                    }
                    if(dashboardReportData.license_plate_recognition){
                        fetchedReportData.license_plate_recognition = updateLicensePlateReport(dashboardReportData.license_plate_recognition);
                    }
                    if(dashboardReportData.face_recognition){
                        fetchedReportData.face_recognition = updateFaceRecoReport(dashboardReportData.face_recognition);
                    }
                    if(dashboardReportData.line_cross){
                        fetchedReportData.line_cross = updateLineCrossReport(dashboardReportData.line_cross);
                    }
                    setReportData({...fetchedReportData, counts: dashboardReportData.counts});
                }
            }
            setReportDataLoading(false);
            setReportVisable(true);
            setBtnLoading(false);
        },
        onError: (e) => {
            setBtnLoading(false);
            setReportDataLoading(false);
        }
    });

    useEffect(() => {
        if(data){
            let organization = data.organizationId;
            getCameraList({variables: {organization, page: 1, limit: 20}});
            getDownCameraList({
                variables:{
                    organization,
                    search: "",
                    page: 1,
                    status: "failed"
                }
            });
        }
    }, [data]);

    const handleSubmit = () => {
        setBtnLoading(true);
        setReportData({...initReportData});
        setReportVisable(false);
        let startDate = new Date(filterState.startDate);
        let endDate = new Date(filterState.endDate);
        startDate = new Date(convertLocalToUTCDate(startDate)).getTime()/1000;
        endDate = new Date(convertLocalToUTCDate(endDate)).getTime()/1000;
        let variables = {
            orgId: data.organizationId,
            cameraId: filterState.cameraId,
            object: filterState.selectedObject,
            threshold: filterState.threshold ? filterState.threshold : 0,
            startDate,
            endDate
        }
        return getAnalyticsDashboardReport({variables});
    }

    const onInputChange = (val) => {
        let organization = data.organizationId;
        getCameraList({variables: {organization, page: 1, limit: 20, search: val}});
    }
    
    const statList = [
        {
            label: t('noOfCameras.label'),
            value: totalCameraCount,
            icon: camera,
            type: "cam_active"
        },
        {
            label: t('camerasDown.label'),
            value: downCameraCount,
            icon: camera_down,
            type: "cam_down"
        },
        {
            label: t('numberOfCamSelected.label'),
            value: filterState.cameraId.length,
            type: "cam_selected"
        },
        {
            label: t('numberOfmlTemplate.label'),
            value: configTemplates.length,
            type: "ml_config"
        }
    ];

    const handleFilterChange = (value, key) => {
        setFilterState({...filterState, [key]: value});
    }

    return (
        <Grid style={{overflow:"hidden"}}>
            <Grid className={classes.root} spacing={2} container style={{...marginGenerator('mt-24'), ...marginGenerator('mb-20')}}>
                <Grid item xs={3}>
                    <Autocomplete
                        styleOverrides={{ minWidth: '100%' }}
                        handleChange={(evt, newVal) => {
                            let templatesArr = [];
                            if(newVal && newVal.length > 0){
                                templatesArr = newVal.map(itm => itm.config_templates);
                                templatesArr = templatesArr.reduce(function (r, a) { return r.concat(a); }, []);
                                templatesArr = [...new Set(templatesArr)];
                                setConfigTemplates(templatesArr);
                            }
                            handleFilterChange(newVal, "cameraId")
                        }}
                        val={filterState.cameraId}
                        options={cameraList}
                        label={t("camera.label")}
                        size="small"
                        handleClose={evt => {
                            getCameraList({variables: {organization: data.organizationId, page: 1, limit: 20, search: ""}});
                        }}
                        onInputChange={onInputChange}
                        multiple={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DateTimeSelector 
                        inputStyle={{padding: 10, width: "100%"}} 
                        label={"yyyy-MM-dd HH:mm"} 
                        dateFormat={"yyyy-MM-dd HH:mm"} 
                        value={filterState.startDate} 
                        handleChange={(newDate) => handleFilterChange(newDate, "startDate")} 
                        disableFuture={true} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <DateTimeSelector 
                        inputStyle={{padding: 10, width: "100%"}} 
                        label={"yyyy-MM-dd HH:mm"} 
                        dateFormat={"yyyy-MM-dd HH:mm"} 
                        value={filterState.endDate} 
                        handleChange={(newDate) => handleFilterChange(newDate, "endDate")} 
                        disableFuture={true} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography style={{fontSize: 12}} id="discrete-slider" gutterBottom>{t('threshold.label')}</Typography>
                    <Slider
                      value={filterState.threshold}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                      onChange={(evt, number) => {
                        handleFilterChange(number, "threshold")
                      }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OutlinedSelect
                        val={filterState.selectedObject}
                        label={t("object.label")}
                        handleChange={evt => handleFilterChange(evt.target.value, "selectedObject")}
                        styleOverrides={{ minWidth: '100%', maxWidth: '100%' }}
                        options={MULTI_OBJECTS_LIST}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ButtonComponent
                        disabled={!filterState.cameraId || !filterState.startDate || !filterState.endDate} 
                        onClick={evt => {
                            if(!filterState.cameraId || !filterState.startDate || !filterState.endDate){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmit();
                        }}
                        t={t}
                        variant="outlined" 
                        size="small"
                        color="primary"
                        loading={btnLoading}
                    />
                    {/* <Button 
                    variant="outlined" 
                    size="medium" 
                    style={{marginLeft: 30}}
                    color="primary"
                    disabled={!cameraId || !endDateTime || !startDateTime}>
                        Export PDF
                    </Button> */}
                </Grid>
            </Grid>
            <Grid
            container
            spacing={2}
            style={marginGenerator('mt-35')}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                        customStyles={{width: 280}}
                    />
                ))}
            </Grid>

            {!reportVisable && reportDataLoading && <div className={classes.loading}>
                <CircularProgress size={25} />
            </div>}

            {reportVisable &&
                <>
                    {/* -------------------- Multi Objects report section ------------------------ */}
                    {configTemplates.includes("multi-object") && 
                        <>
                            <Grid style={{marginTop: 35}}>
                                <Typography variant="h6" className={classes.mainHead} color="primary">{t("multiObject.label")} {t("reports.label")}</Typography>
                            </Grid>
                            <Grid container spacing={2} style={{marginTop: 35}}>
                                <Statcard
                                    statLabel={t("avgPeopleHour.label")}
                                    statValue={reportData.counts && reportData.multi_object.uniquePeopleCount ? reportData.multi_object.uniquePeopleCount : 0}
                                    customStyles={{width: 280, padding: "8px 16px"}}
                                />
                                <Statcard
                                    statLabel={"Highest Number Of People"}
                                    statValue={reportData.counts && reportData.multi_object.highestPeopleCount ? reportData.multi_object.highestPeopleCount : 0}
                                    customStyles={{width: 280}}
                                />
                                <Statcard
                                    statLabel={t("avrageDwellTime.label")}
                                    statValue={reportData.counts && reportData.multi_object.avgDwellTime ? reportData.multi_object.avgDwellTime : 0}
                                    customStyles={{width: 280}}
                                />
                            </Grid>
                            <Grid container style={{marginBottom: 35}}>
                                <Grid item xs={7}>
                                    <ChartContainer
                                    title={t('top5ObjectChart.label')}
                                    customMargin={{...marginGenerator("mt-20")}}
                                    >
                                        <AnalyticsDashboardPieChart 
                                            data={reportData.multi_object && reportData.multi_object.top5ObjectPieChartData ? reportData.multi_object.top5ObjectPieChartData : []}
                                            stackKeyNames={reportData.multi_object && reportData.multi_object.top5ObjectPieChartStack ? reportData.multi_object.top5ObjectPieChartStack : []}
                                            maxValue={reportData.multi_object && reportData.multi_object.top5MaxValue ? reportData.multi_object.top5MaxValue : []}
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={5}>
                                    <ChartContainer
                                    title={t('top5ObjectList.label')}
                                    customMargin={{...marginGenerator("mt-20"), ...marginGenerator("ml-15")}}
                                    >
                                        <GenericTable 
                                        columns={[{label: "Object name", key: 'name'}, {label: "Count", key: "value"}]} 
                                        list={reportData.multi_object && reportData.multi_object.top5ObjectPieChartData ? reportData.multi_object.top5ObjectPieChartData : []}
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                    title={t('highestPeopleDetected.label')}
                                    customMargin={{...marginGenerator("mt-20")}}
                                    >
                                        <BarChartWrapper
                                            loading={false}
                                            data={reportData.multi_object && reportData.multi_object.highestPeopleDetection ? reportData.multi_object.highestPeopleDetection : []}
                                            dataKeyChart={'count'}
                                            dataKeyXAxis={'name'}
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                    title={t('highestObjectDetected.label')}
                                    customMargin={{...marginGenerator("mt-20")}}
                                    >
                                        <AnalyticsDashboardLineChart 
                                            data={reportData.multi_object && reportData.multi_object.highObjDetectionChartData ? reportData.multi_object.highObjDetectionChartData : []}
                                            dataKeyXAxis={reportData.multi_object && reportData.multi_object.highObjDataKeyXAxis ? reportData.multi_object.highObjDataKeyXAxis : []}
                                            visualMap={reportData.multi_object && reportData.multi_object.visualMap ? reportData.multi_object.visualMap : []}
                                            stackKeyNames={reportData.multi_object && reportData.multi_object.highObjStackKeyNames ? reportData.multi_object.highObjStackKeyNames : []}
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                    title={`${t('dwellTime.label')} ${t("reports.label")}`}
                                    customMargin={{...marginGenerator("mt-20")}}
                                    >
                                        <LineChartWrapper 
                                            data={(reportData.multi_object && reportData.multi_object.dwellTimeLineChartData) ? reportData.multi_object.dwellTimeLineChartData : []} 
                                            dataKeyXAxis={reportData.multi_object.dwellTimeDataKeyXAxis ? reportData.multi_object.dwellTimeDataKeyXAxis : []}
                                            stackKeyNames={reportData.multi_object.dwellTimeStackKeyNames ? reportData.multi_object.dwellTimeStackKeyNames : []} 
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                        title={"HeatMap - Peak Hours identification"}
                                        customMargin={{...marginGenerator("mt-20")}}
                                    >
                                        <HeatMapChartWrapper 
                                            heatMapData={(reportData.multi_object && reportData.multi_object.heatMapData) ? reportData.multi_object.heatMapData : []} 
                                            xAxis={reportData.multi_object && reportData.multi_object.heatMapxAxis ? reportData.multi_object.heatMapxAxis : []}
                                            stackKeyNames={reportData.multi_object && reportData.multi_object.heatMapStack ? reportData.multi_object.heatMapStack : []}
                                        />
                                    </ChartContainer>
                                </Grid>
                            </Grid>
                        </>
                    }
                    
                    {/* -------------------- Face Recognition report section ------------------------ */}
                    {configTemplates.includes("face_recognition") && 
                        <>
                            <Grid style={{marginTop: 45}}>
                                <Typography variant="h6" className={classes.mainHead} color="primary">{t("faceRecognition.label")} {t("reports.label")}</Typography>
                            </Grid>
                            <Grid container spacing={2} style={{marginTop: 35}}>
                                <Statcard
                                    statLabel={"Unique Faces Count"}
                                    statValue={reportData.face_recognition && reportData.face_recognition.totalUniqueFaceCount ? reportData.face_recognition.totalUniqueFaceCount : 0}
                                    customStyles={{width: 280, padding: "8px 16px"}}
                                />
                                <Statcard
                                    statLabel={"Total Number of Faces"}
                                    statValue={reportData.face_recognition && reportData.face_recognition.totalFacesCount ? reportData.face_recognition.totalFacesCount : 0}
                                    customStyles={{width: 280}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ChartContainer
                                customMargin={{...marginGenerator("mt-20")}}
                                title={t("faceRecUnqCountPerHr.label")}
                                >
                                    <BarChartWrapper
                                        loading={false}
                                        data={reportData.face_recognition && reportData.face_recognition.frBarChartData ? reportData.face_recognition.frBarChartData : []}
                                        dataKeyChart={'count'}
                                        dataKeyXAxis={'name'}
                                    />
                                </ChartContainer>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 35}}>
                                <ChartContainer
                                customMargin={{...marginGenerator("mt-20")}}
                                title={t("faceRecRepetedCountPerHr.label")}
                                >
                                    <AnalyticsDashboardPieChart 
                                        data={reportData.face_recognition && reportData.face_recognition.frPieChartData ? reportData.face_recognition.frPieChartData : []}
                                        stackKeyNames={reportData.face_recognition && reportData.face_recognition.stackKeyNames ? reportData.face_recognition.stackKeyNames : []}
                                        maxValue={reportData.face_recognition && reportData.face_recognition.maxValue ? reportData.face_recognition.maxValue : 0} 
                                    />
                                </ChartContainer>
                            </Grid>
                        </>
                    }


                    {/* -------------------- License Plate Recognition report section ------------------------ */}
                    {configTemplates.includes("license_plate_recognition") && 
                        <>
                            <Grid style={{marginTop: 45}}>
                                <Typography variant="h6" className={classes.mainHead} color="primary">{t("licencePlateReco.label")} {t("reports.label")}</Typography>
                            </Grid>
                            <Grid container spacing={2} style={{marginTop: 35}}>
                                <Statcard
                                    statLabel={"Unique Number Plates Count"}
                                    statValue={reportData.license_plate_recognition && reportData.license_plate_recognition.totalUniquePlatesCount ? reportData.license_plate_recognition.totalUniquePlatesCount : 0}
                                    customStyles={{width: 280, padding: "8px 16px"}}
                                />
                                <Statcard
                                    statLabel={"Total Number of Plates"}
                                    statValue={reportData.license_plate_recognition && reportData.license_plate_recognition.totalPlatesCount ? reportData.license_plate_recognition.totalPlatesCount : 0}
                                    customStyles={{width: 280}}
                                />
                            </Grid>
                            <Grid container style={{marginBottom: 35}}>
                                <Grid item xs={12}>
                                    <ChartContainer
                                    customMargin={{...marginGenerator("mt-20")}}
                                    title={t("licensePlatesUnqCountPerHr.label")}
                                    >
                                        <BarChartWrapper
                                            loading={false}
                                            data={reportData.license_plate_recognition && reportData.license_plate_recognition.uniquePlateBarChartData ? reportData.license_plate_recognition.uniquePlateBarChartData : []}
                                            dataKeyChart={'count'}
                                            dataKeyXAxis={'name'}
                                        />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                    customMargin={{...marginGenerator("mt-20")}}
                                    title={t("licensePlatesRepetedCountPerHr.label")}
                                    >
                                        <AnalyticsDashboardPieChart 
                                            data={reportData.license_plate_recognition && reportData.license_plate_recognition.repetedPlatesPieChartData ? reportData.license_plate_recognition.repetedPlatesPieChartData : []}
                                            stackKeyNames={reportData.license_plate_recognition && reportData.license_plate_recognition.stackKeyNames ? reportData.license_plate_recognition.stackKeyNames : []} 
                                            maxValue={reportData.license_plate_recognition && reportData.license_plate_recognition.maxValue ? reportData.license_plate_recognition.maxValue : 0}
                                        />
                                    </ChartContainer>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {/* -------------------- Line Cross report section ------------------------ */}
                    {configTemplates.includes("line_cross") &&
                        <>
                            <Grid style={{marginTop: 35}}>
                                <Typography variant="h6" className={classes.mainHead} color="primary">{t("lineCross.label")} {t("reports.label")}</Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <ChartContainer
                                customMargin={{...marginGenerator("mt-20"), ...marginGenerator("mb-25")}}
                                title={t("noOfLineCrossEvents.label")}
                                >
                                    <AnalyticsDashboardBarChart />
                                </ChartContainer>
                            </Grid> */}
                            <Grid item xs={12}>   
                                <ChartContainer
                                customMargin={{...marginGenerator("mt-20")}}
                                title={t("activeLines.label")}
                                >
                                    <AnalyticsDashboardPieChart 
                                        data={reportData.line_cross && reportData.line_cross.lineCrossPieChartData ? reportData.line_cross.lineCrossPieChartData : []}
                                        stackKeyNames={reportData.line_cross && reportData.line_cross.stackKeyNames ? reportData.line_cross.stackKeyNames : []}
                                    />
                                </ChartContainer>
                            </Grid>
                        </>
                    }
                </>
            }
        </Grid>
    );
};

AnalyticsDashboardPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default AnalyticsDashboardPanel;
