import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, width } = props;
  return (
    <Dialog
      open={openPopup}
      onClose={setOpenPopup ? () => setOpenPopup(false) : () => {}}
      fullWidth
      maxWidth={width}>
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}
