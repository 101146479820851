import React, { useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UPLOAD_VIDEO_FILE } from "../../../mutations";

const useStyles = makeStyles(theme => ({
	paper: {},
	icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
	iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
	const { t, onClick, loading } = props;
	return (
		<Button
			color="secondary"
			variant={'contained'}
			onClick={onClick} disabled={loading}>
			{loading && <CircularProgress size={14} />}
			{!loading && t('submit.label')}
		</Button>
	);
}
function CreateVideoTemplateModal({
	t,
	open,
	handleCreateVideoSubmitClick,
	selectePlaceList,
	selectMlTemplateList,
	selectNotiTemplateList,
	handleClose,
	modalState,
	videoFpsOptions,
	handleSubmitLoadingVideo
}) {

	const videoInitFields = { name: '', fps: '', placeId: '', description: "", video_file: null, video_url: "", selected_file: null};
	const aiModelInitFields = { ai_template_id: '', from_time: '', to_time: '', start_date: null };
	const notificationInitFields = { notification_template_id: '', ai_template_id: '' };

	const [videoState, setVideoState] = useState({ ...videoInitFields });
	const [aiModelState, setAIModelState] = useState([{ ...aiModelInitFields }]);
	const [notificationState, setNotificationState] = useState([{ ...notificationInitFields }]);
	const [removeSelectedAIFields, setSelectedRemoveAIFields] = useState([]);
	const [removeSelectedNotificationFields, setSelectedRemoveNotificationFields] = useState([]);
	const [removeFieldState, setRemoveFieldState] = useState({ video: [], ai_template: [], notification: [] });
	const [selectedMlTemplates, setSelectedMlTemplates] = useState([]);
	const [mlTemplateList, setMLTemplateList] = useState([]);
	const classes = useStyles();
	
	React.useEffect(() => {
		if (modalState.clearData && open) {
			setVideoState({ ...videoInitFields });
			setAIModelState([{ ...aiModelInitFields }]);
			setNotificationState([{ ...notificationInitFields }]);
			handleClose();
		}
	}, [modalState]);

	const handleVideoChange = (value, column) => {
		setVideoState({...videoState, [column]: value});
	}

	const onHandleSelectAIRow = (evnt, index) => {
		let val = evnt.target.value.toString();
		let updatedState = { ...removeSelectedAIFields };
		updatedState[index] = evnt.target.checked;
		setSelectedRemoveAIFields(updatedState);
		if (evnt.target.checked) {
			setRemoveFieldState(prevState => ({
				...prevState,
				ai_template: [...prevState.ai_template, val]
			}));
		} else {
			let prevRemoveState = { ...removeFieldState };
			let elemIdx = prevRemoveState.ai_template.indexOf(val);
			prevRemoveState.ai_template.splice(elemIdx, 1);
			setRemoveFieldState(prevRemoveState);
		}
	}

	const onHandleSelectNotifRow = (evnt, index) => {
		let val = evnt.target.value.toString();
		let updatedState = { ...removeSelectedNotificationFields };
		updatedState[index] = evnt.target.checked;
		setSelectedRemoveNotificationFields(updatedState);
		if (evnt.target.checked) {
			setRemoveFieldState(prevState => ({
				...prevState,
				notification: [...prevState.notification, val]
			}));
		} else {
			let prevRemoveState = { ...removeFieldState };
			let elemIdx = prevRemoveState.notification.indexOf(val);
			prevRemoveState.notification.splice(elemIdx, 1);
			setRemoveFieldState(prevRemoveState);
		}
	}

	const addNewAIModelFileds = () => {
		if (selectMlTemplateList.length <= aiModelState.length) {
			Message.error(`You can add only ${selectMlTemplateList.length} ai templates!`);
			return null;
		}
		setAIModelState(prevAiState => [...prevAiState, aiModelInitFields]);
	}

	const addNewNotificationFileds = () => {
		setNotificationState(oldArray => [...oldArray, { ...notificationInitFields }]);
	}
	
	const handleUploadFileChange = (evt) => {
		let fileState = {video_file: evt.target.value};
		if(evt.target.files && evt.target.files.length > 0){
			let file = evt.target.files[0];
			fileState.selected_file = file;
		}
		setVideoState({...videoState, ...fileState});
	}

	const [uploadVideoFile] = useMutation(UPLOAD_VIDEO_FILE, {
        onError: error => {
            console.log('Error', error);
			handleSubmitLoadingVideo({
				btnLoading: false,
				clearData: false
			});
        },
        onCompleted: res => {
            console.log('Error', res.uploadVideoFile);
            if(res && res.uploadVideoFile.url){
				setVideoState({ ...videoState, url: res.uploadVideoFile.url });
				handleCreateVideoSubmitClick({ video: {...videoState, url: res.uploadVideoFile.url}, aiModels: aiModelState, notifications: notificationState});
			}else{
				handleSubmitLoadingVideo({
					btnLoading: false,
					clearData: false
				});
				Message.error("Video file not uploaded! Please try again.");
			}
		}
    });
	
	const handleAIModelChange = (value, idx, column) => {
		const updatedAIModal = [...aiModelState];
		if (updatedAIModal.length && column === 'ai_template_id' && value) {
			let oldAITypes = [];
			updatedAIModal.map(aiItm => {
				if (aiItm.ai_template_id) {
					let mlTmp = mlTemplateList.filter(itm => itm.id === aiItm.ai_template_id);
					oldAITypes.push(mlTmp[0].model_type);
				}
			});
			let newSelectAI = mlTemplateList.filter(itm => itm.id === value);
			if (newSelectAI && newSelectAI.length > 0) {
				let mType = newSelectAI[0].model_type;
				if (oldAITypes.includes(mType)) {
					Message.error("AI type already added! Please select another AI Model.");
					return null;
				}
			}
		}
		updatedAIModal[idx][column] = value;
		setAIModelState(updatedAIModal);
		if (column === 'ai_template_id') {
			let selectedIds = updatedAIModal.map(value => {
				if (value.ai_template_id) {
					return value.ai_template_id;
				}
			});
			selectedIds = selectedIds.filter(function (item, pos) {
				return selectedIds.indexOf(item) == pos;
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if (selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
		}
	}

	const handleNotificationChange = (value, idx, column) => {
		const updatedNoti = [...notificationState];
		if (updatedNoti.length && column === 'notification_template_id' && value) {
			let checkNotiModels = updatedNoti.some(function (el) {
				return el.notification_template_id === value;
			});
			if (checkNotiModels) {
				Message.error("Notification template already added!");
				return null;
			}
		}
		updatedNoti[idx][column] = value;
		setNotificationState(updatedNoti);
	}

	const removeAIModelFileds = () => {
		const updatedAIModal = [...aiModelState];
		const updateRemoveState = { ...removeFieldState };
		for (let index of updateRemoveState.ai_template) {
			index = parseInt(index);
			updatedAIModal.splice(index, 1);
		}
		setAIModelState(updatedAIModal);
		const newRmState = { ...removeFieldState };
		newRmState.ai_template = [];
		setRemoveFieldState(newRmState);
	}

	const removeNotificationFields = () => {
		const updatedNoti = [...notificationState];
		const updateRemoveState = { ...removeFieldState };
		for (let index of updateRemoveState.notification) {
			index = parseInt(index);
			updatedNoti.splice(index, 1);
		}
		setNotificationState(updatedNoti);
		const newRmState = { ...removeFieldState };
		newRmState.notification = [];
		setRemoveFieldState(newRmState);
	}

	const handleProviderChange = (value) => {
		let mlTemplates = [];
		if(value){
			mlTemplates = selectMlTemplateList.filter(item => {
				if(item.provider === value){
					return item;
				}
			});
		}
		setMLTemplateList(mlTemplates);
	}

	React.useEffect(() => {
		if(selectMlTemplateList.length){
			handleProviderChange("edgeai");
		}
	}, [selectMlTemplateList]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'lg'}
			aria-labelledby="video-template-modal"
			aria-describedby="video-template-modal"
			open={open}
			onClose={() => {
				handleClose();
			}}>
			<DialogTitle id="video-template-title">{t('createNew.label')} {t('video.label')} {t('configuration.label')}
			</DialogTitle>
			<DialogContent>
				<div className={classes.root}>
					<Grid container spacing={3}>
						<React.Fragment>
							<Grid item xs={4}>
								<Input
									type={'text'}
									placeholder={`${t('videoName.label')}*`}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									value={videoState.name}
									onChange={evt => handleVideoChange(evt.target.value, 'name')}
								/>
							</Grid>
							<Grid item xs={4}>
								<Input
									type={'file'}
									placeholder={`${t('uploadFile.label')}*`}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									value={videoState.video_file}
									onChange={handleUploadFileChange}
									inputProps={{ accept: "video/*" }}
								/>
							</Grid>
							<Grid item xs={4}>
								<Input
									value={videoState.description}
									type={'text'}
									placeholder={t('description.label')}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									onChange={evt => handleVideoChange(evt.target.value, 'description')}
								/>
							</Grid>
							<Grid item xs={4}>
								<OutlinedSelect
									val={videoState.fps}
									label={`${t('videofps.label')} *`}
									handleChange={evt => {
										handleVideoChange(evt.target.value, 'fps');
									}}
									selectStyle={{ ...paddingGenerator('p-8') }}
									styleOverrides={{ width: '100%', marginRight: 0 }}
									options={videoFpsOptions ? videoFpsOptions : []}
								/>
							</Grid>
							<Grid item xs={4}>
								<Autocomplete
									id={`place-select`}
									options={selectePlaceList}
									getOptionLabel={(option) => option.name}
									onChange={(event, newValue) => {
										handleVideoChange(newValue, 'place')
									}}
									style={{ border: '1px solid #ebebeb' }}
									renderInput={(params) => <TextField {...params} label={`${t('place.label')} *`} variant="outlined" />}
								/>
							</Grid>
						</React.Fragment>

						<Grid item xs={12} style={{ ...marginGenerator("mt-24") }}>
							<Button
								variant={'outlined'}
								color={'primary'}
								disabled={selectMlTemplateList && selectMlTemplateList.length > 0 ? false : true}
								onClick={() => {
									addNewAIModelFileds();
								}}
							>
								{t('add.label')} {t('aimodel.label')}
							</Button>
							<Button
								style={{ ...marginGenerator('ml-20') }}
								color="secondary"
								disabled={!removeFieldState.ai_template.length}
								variant={'contained'}
								onClick={() => {
									removeAIModelFileds();
									setSelectedRemoveAIFields([]);
								}}
							>
								{t('remove.label')}
							</Button>
						</Grid>
						{aiModelState.map((val, idx) => {
							return (
								<React.Fragment key={`ai-${idx}`}>
									<Grid item xs={12} style={{ ...paddingGenerator('pl-0') }}>
										<Typography variant="subtitle2" color="textPrimary" >
											<Checkbox
												style={paddingGenerator('pl-0')}
												checked={removeSelectedAIFields[idx]}
												value={idx}
												onChange={(event) => {
													onHandleSelectAIRow(event, idx);
												}}
											/>
											{t('aimodel.label')} {(idx + 1)}
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<OutlinedSelect
											val={aiModelState[idx].ai_template_id}
											label={`${t('aiTemplate.label')} *`}
											handleChange={evt => {
												handleAIModelChange(evt.target.value, idx, 'ai_template_id');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectMlTemplateList ? selectMlTemplateList : []}
										/>
									</Grid>
									<Grid item xs={4}>
										<Input
											id="datetime-local"
											type="datetime-local"
											placeholder={`${t('startTime.label')}*`}
											style={{ width: '100%' }}
											className={classes.input}
											color={'secondary'}
											value={aiModelState[idx].start_date}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'start_date')}
										/>
									</Grid>
									<Grid item xs={2}>
										<Input
											type={'number'}
											placeholder={`${t('fromTimeInMin.label')}*`}
											style={{ width: '100%', minWidth: 175 }}
											color={'secondary'}
											value={aiModelState[idx].from_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'from_time')}
										/>
									</Grid>
									<Grid item xs={2}>
										<Input
											type={'number'}
											placeholder={`${t('toTimeInMin.label')}*`}
											style={{ width: '100%', minWidth: 175 }}
											color={'secondary'}
											value={aiModelState[idx].to_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'to_time')}
										/>
									</Grid>
								</React.Fragment>
							)
						})}

						<Grid item xs={12} style={{ ...marginGenerator("mt-10") }}>
							<Button
								variant={'outlined'}
								color={'primary'}
								disabled={selectNotiTemplateList && selectNotiTemplateList.length > 0 ? false : true}
								onClick={() => {
									addNewNotificationFileds();
								}}
							>
								{t('add.label')} {t('notification.label')}
							</Button>
							<Button
								color="secondary"
								variant={'contained'}
								disabled={!removeFieldState.notification.length}
								style={{ ...marginGenerator('ml-20') }}
								onClick={() => {
									removeNotificationFields();
									setSelectedRemoveNotificationFields([]);
								}}
							>
								{t('remove.label')}
							</Button>
						</Grid>
						{notificationState.map((val, idx) => {
							return (
								<React.Fragment key={`noti-${idx}`}>
									<Grid key={`notiHeading-${idx}`} item xs={12} style={{ ...paddingGenerator('pl-5'), ...paddingGenerator('pl-0') }}>
										<Typography variant="subtitle2" color="textPrimary" >
											<label htmlFor={`remove-check-${idx}`}>
												<Checkbox
													style={paddingGenerator('pl-0')}
													checked={removeSelectedNotificationFields[idx]}
													id={`remove-check-${idx}`}
													value={idx}
													onChange={(event) => {
														onHandleSelectNotifRow(event, idx);
													}}
												/>
												{t('notification.label')} {(idx + 1)}
											</label>
										</Typography>
									</Grid>
									<Grid key={`notification-${idx}`} item xs={6}>
										<OutlinedSelect
											val={notificationState[idx].notification_template_id}
											label={`${t('notification.label')} ${t('template.label')}*`}
											handleChange={evt => {
												handleNotificationChange(evt.target.value, idx, 'notification_template_id');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectNotiTemplateList ? selectNotiTemplateList : []}
										/>
									</Grid>
									<Grid key={`aitemplateid-${idx}`} item xs={6}>
										<OutlinedSelect
											val={notificationState[idx].ai_template_id}
											label={`${t('aiTemplate.label')} *`}
											handleChange={evt => {
												handleNotificationChange(evt.target.value, idx, 'ai_template_id');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectedMlTemplates ? selectedMlTemplates : []}
										/>
									</Grid>
								</React.Fragment>
							)
						})}
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Grid container justify={'space-between'}>
					<Button
						variant={'outlined'}
						onClick={() => {
							setVideoState([{ ...videoInitFields }]);
							setAIModelState([{ ...aiModelInitFields }]);
							setNotificationState([{ ...notificationInitFields }]);
							handleClose();
						}}
					>
						{t('cancel.label')}
					</Button>
					<ButtonComponent
						t={t}
						onClick={() => {
							console.log("videoState: ", videoState);
							if (!videoState.name || !videoState.fps || !videoState.video_file || !aiModelState.length) {
								Message.error(REQUIRED_ERROR);
								return null;
							}
							let checkValidate = false;
							if (aiModelState.length > 0) {
								for (let aiMdl of aiModelState) {
									if (!aiMdl.ai_template_id) {
										checkValidate = true;
									}
								}
							}
							if (checkValidate) {
								Message.error(REQUIRED_ERROR);
								return null;
							}
							handleSubmitLoadingVideo({
								btnLoading: true,
								clearData: false
							});
							return uploadVideoFile({
								variables: {
									file: videoState.selected_file 
								}
							});
						}}
						loading={modalState.btnLoading} />
				</Grid>
			</DialogActions>
		</Dialog>
	);
}

CreateVideoTemplateModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	handleCreateVideoSubmitClick: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(CreateVideoTemplateModal);
