import React, { useState } from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import TableContainer from '@material-ui/core/TableContainer';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import CameraPanelItem from '../CameraPanelItem';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import RoiModal from '../EdgeAIRoiModal';
import { UPDATE_CAMERA } from '../../mutations';
import { GET_EVENT_IMAGES } from '../../queries';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import Message from '../../../../components/Message';
import { convertLocalToUTC, convertUTCToLocal } from '../../../../utils';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import Autocomplete from '../../../../components/Autocomplete';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function CameraPanel({
    t,
    menuItems,
    list,
    filterList,
    handleCreateButtonClick,
    handleMenuItemClick,
    loading,
    error,
    onHandleSelectCameraRow,
    checkboxCameraRow,
    handleMultipleButtonClick,
    filterLoading,
    allowConfigure,
    handleSearch,
    page,
    handlePageChange,
    reloadList
}) {

    const classes = useStyles();
    const [roiModalState, setRoiModalState] = React.useState(false);
    const [roiImgSrc, setRoiImgSrc] = React.useState("");
    const [roiCamera, setRoiCamera] = React.useState(null);
    const [roiButtonLoading, setRoiButtonLoading] = React.useState(false);
    const [roiSubmitLoading, setRoiSubmitLoading] = React.useState(false);
    const [camRoiCoords, setCamRoiCoords] = React.useState(null);
    const [goToPage, setGoToPage] = useState(null);

    let columns = [
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "name"
        }, {
            label: t('places.label'),
            key: "place"
        },
        {
            label: t('onboardBy.label'),
            key: "onboardBy"
        },
        {
            label: t('onboardDate.label'),
            key: "onboardDate"
        },
        {
            label: t('attachedModels.label'),
            key: "attachedModels"
        },
        {
            label: t('cameraStatus.label'),
            key: "cameraStatus"
        },
        {
            label: t('roi.label'),
            key: "roi"
        }
    ];

    if (allowConfigure) {
        columns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
    }

    const [updateCameraRoi] = useMutation(UPDATE_CAMERA, {
        onError: error => {
            Message.success(error);
            setRoiSubmitLoading(false);
        },
        onCompleted: data => {
            setRoiModalState(false);
            reloadList();
            setCamRoiCoords(null);
            setRoiCamera(null);
            setRoiSubmitLoading(false);
            Message.success('Camera ROI updated successfully.');
        }
    });

    const [getTopEventsROIImage] = useLazyQuery(GET_EVENT_IMAGES, {
        fetchPolicy: "no-cache",
        onError: error => {
            setRoiButtonLoading(false);
        },
        onCompleted: res => {
            setRoiButtonLoading(false);
            if (res.getEventImages && res.getEventImages.length > 0) {
                let imgurl = res.getEventImages[0].url;
                setRoiImgSrc(imgurl);
            } else {
                setRoiCamera(null);
                Message.success('Event not found for this camera!');
                setRoiModalState(false);
            }
        }
    });

    const handleSearchChange = (val) => {
        handleSearch("camera", val);
    }

    const handleAddRoi = (cam) => {
        if (cam && cam.camera_id) {
            setRoiCamera(cam);
            setRoiButtonLoading(true);
            setRoiModalState(true);
            if (cam.config.camData && cam.config.camData.roi) {
                setCamRoiCoords(cam.config.camData.roi);
            }
            return getTopEventsROIImage({
                variables: {
                    cameraId: cam.camera_id.toString(),
                    lastEvent: true
                }
            });
        } else {
            return null;
        }
    }

    const handleUpdateRoi = (coords) => {
        if (roiCamera && coords) {
            let tempObj = {};
            setRoiSubmitLoading(true);
            tempObj.orgId = roiCamera.config.orgId;
            tempObj.camera_id = roiCamera.camera_id;
            tempObj.camData = roiCamera.config.camData;
            tempObj.fps = roiCamera.config.fps;
            tempObj.remote_stream = roiCamera.config.remote_stream ? roiCamera.config.remote_stream : false;
            tempObj.url = roiCamera.url ? roiCamera.url : roiCamera.config.url;
            if (roiCamera.config.node_id) {
                tempObj.node_id = roiCamera.config.node_id;
            }
            tempObj.provider = roiCamera.config.provider;
            tempObj.configTemplate = [];
            tempObj.placeId = (roiCamera.place && roiCamera.place.place_id) ? roiCamera.place.place_id : "";
            if (roiCamera.schedules && roiCamera.schedules.length > 0 && roiCamera.schedules[0].length > 0) {
                for (let j = 0; j < roiCamera.schedules[0].length; j++) {
                    let tmpAiModal = {};
                    let schedule = roiCamera.schedules[0][j];
                    const roiVal = [];
                    if (coords[`${roiCamera.camera_id}_aim_${schedule.model_template.id}`] && coords[`${roiCamera.camera_id}_aim_${schedule.model_template.id}`].length) {
                        coords[`${roiCamera.camera_id}_aim_${schedule.model_template.id}`].map(roiItm => {
                            let tmpRow = {...roiItm}; 
                            tmpRow.x1 = roiItm.x1.toString();
                            tmpRow.x2 = roiItm.x2.toString();
                            tmpRow.y1 = roiItm.y1.toString();
                            tmpRow.y2 = roiItm.y2.toString();
                            tmpRow.inverse_roi = (coords[`${roiCamera.camera_id}_aim_${schedule.model_template.id}`][0].inverse_roi === "Yes") ? true : false;
                            roiVal.push(tmpRow);
                        });
                    }
                    let startDate = schedule.start_time ? convertUTCToLocal(schedule.start_time + ' 00') : "";
                    let endDate = schedule.end_time ? convertUTCToLocal(schedule.end_time + ' 00') : "";
                    tmpAiModal.startTime = startDate ? convertLocalToUTC(startDate + ' 00') : '00:00';
                    tmpAiModal.endTime = endDate ? convertLocalToUTC(endDate + ' 00') : '00:00';
                    tmpAiModal.frequency = 1;
                    tmpAiModal.templateId = (schedule.model_template.id) ? schedule.model_template.id : '';
                    tmpAiModal.delete = false;
                    tmpAiModal.scheduleId = schedule.id;
                    tmpAiModal.notificationTemplateId = [];
                    tmpAiModal.rois = roiVal;
                    // tmpAiModal.roi = schedule.roi ? schedule.roi : (roiVal.length>0) ? roiVal[0] : null;
                    tempObj.configTemplate.push(tmpAiModal);
                }
                return updateCameraRoi({
                    variables: { camData: tempObj }
                });
            }
        }
    }

    return (
        <div>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                {filterList && filterList.map((filter, index) => (
                    <Grid item xs={filter.fieldType === "autocomplete" ? "3" : "2"}>
                        {filter.fieldType && filter.fieldType === "autocomplete" ?
                            <Autocomplete
                                key={index}
                                styleOverrides={filter.styleOverrides || null}
                                handleChange={filter.handleChange}
                                options={filter.options}
                                label={filter.label}
                                size="small"
                                onInputChange={filter.onInputChange}
                            />
                            // <Autocomplete
                            //     key={index}
                            //     freeSolo
                            //     id="size-small-outlined"
                            //     style={filter.styleOverrides || null}
                            //     onChange={filter.handleChange}
                            //     getOptionSelected={(option, value) => option._id === value}
                            //     getOptionLabel={(option) => option.name}
                            //     options={filter.options}
                            //     size="small"
                            //     renderInput={(params) => (
                            //     <TextField
                            //         {...params}
                            //         label={filter.label}
                            //         variant="outlined"
                            //         InputProps={{
                            //             ...params.InputProps,
                            //             endAdornment: (
                            //             <React.Fragment>
                            //                 {params.InputProps.endAdornment}
                            //             </React.Fragment>
                            //             ),
                            //         }}
                            //     />
                            //     )}
                            // />
                            :
                            <OutlinedSelect
                                key={index}
                                val={filter.val}
                                label={filter.label}
                                handleChange={filter.handleChange}
                                IconComponent={filter.iconComponent || null}
                                options={filter.options}
                                styleOverrides={filter.styleOverrides || null}
                                disabled={filter.disabled || false}
                            />
                        }
                    </Grid>
                ))}
                <Grid item xs="2">
                    <SearchBox handleSearch={handleSearchChange} />
                </Grid>
                <Grid item xs="3">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={() =>
                                handleCreateButtonClick('camera_template')
                            }>
                            {t('createNow.label')}
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() =>
                                handleMultipleButtonClick('delete')
                            }
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                        >
                            {t('delete.label')}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell align={"left"} width="15%" key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error && !filterLoading ?
                                list.getAllCameraList && list.getAllCameraList.cameras.length > 0 ? list.getAllCameraList.cameras.map((rowitem, rowIndex) => (
                                    <CameraPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        menuItems={menuItems}
                                        handleMenuItemClick={handleMenuItemClick}
                                        onHandleSelectCameraRow={onHandleSelectCameraRow}
                                        checkboxCameraRow={checkboxCameraRow}
                                        allowConfigure={allowConfigure}
                                        handleAddRoi={handleAddRoi}
                                    />
                                )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <Typography variant={'subtitle2'} style={{ textAlign: "center" }}><CircularProgress size={25} /></Typography>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    {!loading &&
                        <>
                            <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Grid item xs={2}>
                                    <span>Total {Math.ceil(get(list, 'getAllCameraList.count', null) / 20)} page,  {get(list, 'getAllCameraList.count', null)} Row</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Pagination
                                        color="secondary"
                                        count={list.getAllCameraList ? Math.ceil(list.getAllCameraList.count / 20) : 0}
                                        page={page}
                                        onChange={(evt, value) => {
                                            handlePageChange("camera", value);
                                        }} />
                                </Grid>
                                <Grid item xs={1}>
                                    <span>Go to page </span>
                                </Grid>
                                <Grid item xs={1}>
                                    <Input
                                        value={goToPage}
                                        type="number"
                                        style={{ minWidth: 100 }}
                                        color={'secondary'}
                                        onChange={evt => {
                                            let maxRecord = list && list.getAllCameraList ? parseInt(Math.ceil(list.getAllCameraList.count / 20)) : 0;
                                            if (parseInt(evt.target.value) > maxRecord) {
                                                return null;
                                            }
                                            setGoToPage(evt.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        onClick={() => handlePageChange('camera', parseInt(goToPage))}
                                        variant={'outlined'}
                                        color="primary"
                                        disabled={!goToPage}
                                        style={{ marginLeft: 10 }}
                                    >Go</Button>
                                </Grid>
                            </Grid>
                        </>}
                </TableContainer>
            </Grid>
            <RoiModal
                t={t}
                roiCamera={roiCamera}
                roiButtonLoading={roiButtonLoading}
                camRoiCoords={camRoiCoords}
                imageSrc={roiImgSrc}
                open={roiModalState}
                handleClose={evt => {
                    localStorage.removeItem("roi_img_url");
                    setRoiCamera(null);
                    setRoiModalState(false);
                }}
                roiSubmitLoading={roiSubmitLoading}
                handleUpdateRoi={handleUpdateRoi}
            />
        </div>
    );
}

CameraPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default CameraPanel;
