import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SERVICE_TICKET_DETAILS_BY_ID } from '../queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import {
  Paths,
  ServiceTicketPaths,
  TicketingSystem
} from '../../../routes/routePaths';
import CustomTabs from '../../../components/CustomTabs';
import { dateFormatterLLL, getParam } from '../../../utils';
import TicketDetailsPanel from './components/TicketDetailPanel';
import { useLocation, withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import Chat from '@material-ui/icons/Chat';
import { marginGenerator } from '../../../theme/utils';
import IconButton from '@material-ui/core/IconButton';
import commonStyles from '../../../theme/commonStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { getUrl } from '../../../utils';
import Chip from '@material-ui/core/Chip';
import Image from '../../../assets/icons/image.png';
import ActivityList from '../../../components/ActivityList';
import { GET_USER_INFO } from '../queries';
import { UPDATE_SERVICE_TICKET, CLOSE_SERVICE_TICKET } from '../mutations';
import { UPLOAD_FILE } from '../../commonMutations';
import Message from '../../../components/Message';
import { STATUS_TYPES } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
  commentBar: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 0),
    maxWidth: '1100px'
  },
  commentContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    ...(window.innerWidth < 1100 && { padding: 24 })
  }
}));

const TicketDetails = ({ t, history }) => {
  const currentUrl = window.location.href;
  let [breadcrumbList, setBreadcrumbList] = useState([
    { label: 'home.label', link: Paths.Dashboard },
    { label: 'ticketsDetails.label', link: ServiceTicketPaths.Details }
  ]);
  const id = getParam();
  const location = useLocation();

  const { loading, error, data, refetch: reloadServiceTicket } = useQuery(
    GET_SERVICE_TICKET_DETAILS_BY_ID,
    {
      variables: { id }
    }
  );

  const reloadTickets = () => {
    reloadServiceTicket();
  };

  const {
    data: userInfo,
    loading: userInfoLoading,
    error: userInfoError
  } = useQuery(GET_USER_INFO);

  useEffect(() => {
    if (location.search === `?history=dashboard`) {
      breadcrumbList = breadcrumbList.filter((item, index) => index !== 1);
      setBreadcrumbList(breadcrumbList);
    }
    reloadServiceTicket();
  }, [location]);

  const classes = useStyles();
  const commonClasses = commonStyles();
  const ticketData = !loading && !error && data.getServiceTicketById;
  const userData = !userInfoLoading && !userInfoError && userInfo.userInfo;
  const inputFile = React.createRef();

  return (
    <>
      {!loading && !error && (
        <Layout breadcrumbList={breadcrumbList}>
          <Header
            title={ticketData.title}
            subText={`${t('id.label').toUpperCase()} ${
              ticketData.ticketId
            } • ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
              ticketData.createdAt
            )}`}
            secondaryButtonText={t('back.label')}
            handleSecondaryButtonClick={() => {
              const path = getUrl(TicketingSystem.KnowladgeBase);
              history.push(path);
            }}
          />
          <CustomTabs
            tabs={[
              {
                label: t('details.label'),
                children: (
                  <TicketDetailsPanel
                    ticketData={ticketData}
                    userData={userData}
                    reloadTickets={reloadTickets}
                    t={t}
                  />
                )
              },
              {
                label: t('activity.label'),
                children: <ActivityList list={ticketData.activityLogs} />
              }
            ]}
          />
        </Layout>
      )}
    </>
  );
};
export default withTranslation()(withRouter(TicketDetails));
