import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    mainTable: {
        marginBottom: 20,
        borderBottomColor: '#9696A4',
        borderBottomWidth: 1,
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold'
    },
    description: {
        width: '30%'
    },
    head1: {
        width: '20%',
        textAlign: "center"
    },
    head2: {
        width: '25%',
        textAlign: "center"
    },
    rate: {
        width: '25%',
        textAlign: "center"
    }
});

const InvoiceTableRow = ({items, currency}) => {
    return (<View style={styles.mainTable}>
        {
            items.map( (item, key) => {
                return (<View key={key} style={styles.row}>
                    <Text style={styles.description}>{item.description}</Text>
                    <Text style={styles.head1}>{currency}{item.amount ? item.amount.toFixed(2) : 0}</Text>
                    <Text style={styles.head2}>{currency}{item.discount ? item.discount.toFixed(2) : 0}</Text>
                    <Text style={styles.rate}>{currency}{item.total ? item.total.toFixed(2) : 0}</Text>
                </View>)
            })
        }
    </View>);
};
  
  export default InvoiceTableRow