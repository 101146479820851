import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid
} from '@material-ui/core';
import React, { useState } from 'react';
import { renderText } from '../displayComponent';
import { useHistory } from 'react-router';
import axios from 'axios';
import { FABRIC_URL } from '../../../envConstants';
import Message from '../../../components/Message';
import Popup from '../Popup';

const Step3Fcr = ({ formData, routerId, handlePrev, classes, podId }) => {
  let history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);

  const handleSave = async e => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('syncomToken');
      await axios
        .post(
          `${FABRIC_URL}fcr`,
          {
            name: formData?.fcrName,
            term: formData?.MinimumTerm,
            asn: formData?.ASN,
            router_id: routerId + '/32',
            bgp_state: formData?.BGP,
            speed: '1 GBPS',
            type: 'fcr',
            pod_id: podId,
            client_name: formData?.clientName
          },
          { headers: { accessToken: `${token}` } }
        )
        .then(res => {
          Message.success(
            res?.message ||
              res?.response?.data?.message ||
              res?.data?.message ||
              'Fcr Created Successfully'
          );
          history.push('/fabricService/FCR');
        })
        .catch(error => {
          console.log(error);
          Message.error(
            error?.response?.data?.message || 'Something went wrong'
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Box>{renderText({ label: 'Summary', variant: 'h6' })}</Box>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={2}
            justify="center">
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                <span>Fcr Name</span>
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {formData?.fcrName}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                <span>Client Name</span>
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {formData?.clientName}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                Term
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {formData?.MinimumTerm}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                ASN
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {formData?.ASN}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                Router-id
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {routerId}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                BGP State
              </Grid>
              <Grid item sm={2} container justify="center">
                :
              </Grid>
              <Grid item sm={5}>
                {formData?.BGP}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="space-evenly" alignItems="center">
            <Grid item>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                onClick={() => {
                  setOpenPopup(true);
                }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {openPopup && (
        <Popup
          title="Add Configured Services"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Are you sure you add this service from your configured services?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => setOpenPopup(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleSave}>
              Order Now
            </Button>
          </DialogActions>
        </Popup>
      )}
    </>
  );
};

export default Step3Fcr;
