import React from 'react';
import SalesHeatMap from './SalesHeatMap';

const SalesMapWithHeapMap = ({ locationsForSalesDashBoard }) => {
  return (
    <>
      <SalesHeatMap locationsForSalesDashBoard={locationsForSalesDashBoard} />
    </>
  );
};

export default SalesMapWithHeapMap;
