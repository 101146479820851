import { gql } from 'apollo-boost';

/*
  Update service ticket
 */
export const UPDATE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $logMessage: String
    $serviceTicketId: String
    $title: String
    $mailThreadId: String
    $attachments: [String]
  ) {
    updateServiceTicket(
      logMessage: $logMessage
      serviceTicketId: $serviceTicketId
      title: $title
      mailThreadId: $mailThreadId
      attachments: $attachments
    ) {
      id
    }
  }
`;

/*
  Close service ticket
 */
export const CLOSE_SERVICE_TICKET = gql`
  mutation ServiceTickets($id: String) {
    closeServiceTicket(id: $id) {
      id
    }
  }
`;

/*
  Close multiple service ticket
 */

export const CLOSE_MULTIPLE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $id: [String]
    $currentUrl: String
    $ticketCloseTime: String
    $closeDescription: String
    $extraNote: String
  ) {
    closeMultipleServiceTicket(
      id: $id
      currentUrl: $currentUrl
      ticketCloseTime: $ticketCloseTime
      closeDescription: $closeDescription
      extraNote: $extraNote
    ) {
      id
    }
  }
`;

/*
  Create service ticket
 */
export const CREATE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $title: String
    $description: String
    $subscription: String
    $country: String
    $category: String
    $attachments: [String]
    $sites: [String]
  ) {
    createServiceTicket(
      title: $title
      description: $description
      country: $country
      subscription: $subscription
      category: $category
      attachments: $attachments
      sites: $sites
    ) {
      id
    }
  }
`;
