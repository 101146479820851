import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#9696A4',
    borderBottomWidth: 1,
    height: 24,
    fontStyle: 'bold',
    fontSize: 7,
    flexGrow: 1
  },
  description: {
    width: '14%'
  },
  head1: {
    width: '14%',
    textAlign: 'center'
  },
  head2: {
    width: '10%',
    textAlign: 'center'
  },
  head3: {
    width: '15%',
    textAlign: 'center'
  },
  head4: {
    width: '8%',
    textAlign: 'center'
  },
  rate: {
    width: '10%',
    textAlign: 'center'
  },
  head5: {
    width: '7%',
    textAlign: 'center'
  },
  head6: {
    width: '22%',
    textAlign: 'center'
  }
});

const InvoiceTableHeader = ({ row, items }) => {
  let siteName = items && items[0] && items[0].siteName ? items[0].siteName : ""
  return (
    <>
      {
        items && items[0] && items[0].id != "" ? (
          <View style={styles.container}>
            <Text style={styles.description}>{row.title}</Text>
            <Text style={styles.head1}>{row.head1}</Text>
            <Text style={styles.head2}>{row.head2}</Text>
            <Text style={styles.rate}>{row.rate}</Text>
            <Text style={styles.head4}>{row.head4}</Text>
            <Text style={styles.head5}>{row.head5}</Text>
            <Text style={styles.head3}>{row.head3}</Text>
            <Text style={styles.head6}>{row.head6}</Text>
          </View>
      ) : <Text>{"The Site " + siteName + " is not Impacted"}</Text>
      }
    </>
    );
};

export default InvoiceTableHeader;
