import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import {GET_VM_IMAGES_LIST, GET_CONTROLLERS_LIST, GET_TEMPLATE_LIST} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Image from "../../../../assets/icons/image.png";
import Close from "@material-ui/icons/Close";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    input: {
        minWidth: '224px'
    }
}));
// const images = [
// ];
const RAM_LIST = [
    {
        id: 1024 * 2,
        name: '2GB'
    }, {
        id: 1024 * 4,
        name: '4GB'
    }, {
        id: 1024 * 6,
        name: '6GB'
    }, {
        id: 1024 * 8,
        name: '8GB'
    }, {
        id: 1024 * 10,
        name: '10GB'
    }, {
        id: 1024 * 12,
        name: '12GB'
    }, {
        id: 1024 * 14,
        name: '14GB'
    }, {
        id: 1024 * 16,
        name: '16GB'
    }, {
        id: 1024 * 18,
        name: '18GB'
    }, {
        id: 1024 * 20,
        name: '20GB'
    }, {
        id: 1024 * 22,
        name: '22GB'
    }, {
        id: 1024 * 24,
        name: '24GB'
    }, {
        id: 1024 * 26,
        name: '26GB'
    }, {
        id: 1024 * 28,
        name: '28GB'
    }, {
        id: 1024 * 30,
        name: '30GB'
    }, {
        id: 1024 * 32,
        name: '32GB'
    }];
const DISK_LIST = [
    {
        id: 10,
        name: '10GB'
    }, {
        id: 11,
        name: '11GB'
    }, {
        id: 12,
        name: '12GB'
    }, {
        id: 13,
        name: '13GB'
    }, {
        id: 14,
        name: '14GB'
    }, {
        id: 15,
        name: '15GB'
    }, {
        id: 16,
        name: '16GB'
    }, {
        id: 17,
        name: '17GB'
    }, {
        id: 18,
        name: '18GB'
    }, {
        id: 19,
        name: '19GB'
    }, {
        id: 20,
        name: '20GB'
    }, {
        id: 21,
        name: '21GB'
    }, {
        id: 22,
        name: '22GB'
    }, {
        id: 23,
        name: '23GB'
    }, {
        id: 24,
        name: '24GB'
    }, {
        id: 25,
        name: '25GB'
    }, {
        id: 26,
        name: '26GB'
    }, {
        id: 27,
        name: '27GB'
    }, {
        id: 28,
        name: '28GB'
    }, {
        id: 29,
        name: '29GB'
    }, {
        id: 30,
        name: '30GB'
    }, {
        id: 31,
        name: '31GB'
    }, {
        id: 32,
        name: '32GB'
    }, {
        id: 33,
        name: '33GB'
    }, {
        id: 34,
        name: '34GB'
    }, {
        id: 35,
        name: '35GB'
    }, {
        id: 36,
        name: '36GB'
    }, {
        id: 37,
        name: '37GB'
    }, {
        id: 38,
        name: '38GB'
    }, {
        id: 39,
        name: '39GB'
    }, {
        id: 40,
        name: '40GB'
    }];
const interfaceTypes = [
    {
        id: 'PhysicalPort',
        name: 'PhysicalPort'
    }
];
const cloudInitContentParamsPath = [
    {
        id: 'License',
        name: 'License'
    }/*, {
        id: 'cloudInit',
        name: 'cloudInit'
    }*/
];

function CreateVNFTemplateModal({
                                    t,
                                    data,
                                    open,
                                    handleCreateVnfTemplate,
                                    handleClose,
                                    modalState
                                }) {
    let images = [];
    let controllers = [];
    const organization_id = data.organizationId
    const {
        loading: loadingImages,
        data: imagesData,
        error: imagesError
    } = useQuery(GET_VM_IMAGES_LIST, {
        variables: {
            organization_id
        }
    });
    const {
        loading: loadingControllers,
        data: controllersData,
        error: controllersError
    } = useQuery(GET_CONTROLLERS_LIST, {
        variables: {
            organization_id
        }
    });
    const [vnfTemplateNames, setVnfTemplateNames] = useState('');
    const [controllerId, setControllerId] = useState('');
    const [vnfVersion, setVnfVersion] = useState('');
    const [vnfDescription, setVnfDescription] = useState('');
    const [vnfProvider, setVnfProvider] = useState('');
    const [vnfImage, setVnfImage] = useState('');
    const [vCPU, setVCPU] = useState('');
    const [ram, setRAM] = useState('');
    const [storageDisk, setStorageDisk] = useState('');
    const [cloudInitDataSource, setCloudInitDataSource] = useState('ConfigDrive');
    const [cloudInitDriveType, setCloudInitDriveType] = useState('');
    const interfaceInitField = {name: '', type: '', description: ''};
    const cloudInitContentInitField = {path: '', description: 'Operational license'};
    const [interfaceState, setInterfaceState] = useState([
        {...interfaceInitField}
    ]);
    const [cloudInitContentParamsState, setCloudInitContentParamsState] = useState([
        {...cloudInitContentInitField}
    ]);
    const [
        removeSelectedInterfaceField,
        setSelectedRemoveInterfaceField
    ] = useState([]);
    const [
        removeSelectedCloudInitContentInitField,
        setSelectedCloudInitContentInitField
    ] = useState([]);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setVnfTemplateNames('');
            setVnfImage('');
            setVCPU('');
            setRAM('');
            setStorageDisk('');
            setInterfaceState('');
            setCloudInitContentParamsState('');
            setCloudInitDataSource('');
            setCloudInitDriveType('');
            setControllerId('');
            setVnfVersion('');
            setVnfDescription('');
            setVnfProvider('');
            handleClose();
        }
    }, [modalState]);
    const [removeFieldState, setRemoveFieldState] = useState({interface: []});
    const [removeCloudInitContentInitState, setRemoveCloudInitContentInitState] = useState({cloudInitContent: []});
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const addNewInterfaceField = () => {
        setInterfaceState(oldArray => [...oldArray, {...interfaceInitField}]);
    };
    const addNewCloudInitContentInitField = () => {
        setCloudInitContentParamsState(oldArray => [...oldArray, {...cloudInitContentInitField}]);
    };
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imageLogo, setImageLogo] = useState('');
    const removeInterfaceField = () => {
        const updatedInterface = [...interfaceState];
        const updateRemoveState = {...removeFieldState};
        for (let index of updateRemoveState.interface) {
            index = parseInt(index);
            updatedInterface.splice(index, 1);
        }
        setInterfaceState(updatedInterface);
        const newRmState = {...removeFieldState};
        newRmState.interface = [];
        setRemoveFieldState(newRmState);
    };
    const removeCloudInitContentInitField = () => {
        const updatedCloudInitContent = [...cloudInitContentParamsState];
        const updateRemoveState1 = {...removeCloudInitContentInitState};
        console.log(updateRemoveState1, 'updateRemoveState1')
        for (let index of updateRemoveState1.cloudInitContent) {
            index = parseInt(index);
            updatedCloudInitContent.splice(index, 1);
        }
        setCloudInitContentParamsState(updatedCloudInitContent);
        const newRmState1 = {...removeCloudInitContentInitState};
        newRmState1.cloudInitContent = [];
        setRemoveCloudInitContentInitState(newRmState1);
    };
    const onHandleSelectInterfaceRow = (evnt, index) => {
        let val = evnt.target.value.toString();
        let updatedState = {...removeSelectedInterfaceField};
        updatedState[index] = evnt.target.checked;
        console.log('updatedState', updatedState);
        setSelectedRemoveInterfaceField(updatedState);
        if (evnt.target.checked) {
            setRemoveFieldState(prevState => ({
                ...prevState,
                interface: [...prevState.interface, val]
            }));
        } else {
            let prevRemoveState = {...removeFieldState};
            let elemIdx = prevRemoveState.interface.indexOf(val);
            prevRemoveState.cloudInitContent.splice(elemIdx, 1);
            setRemoveFieldState(prevRemoveState);
        }
    };
    const onHandleSelectCloudInitContentRow = (evnt, index) => {
        let val = evnt.target.value.toString();
        let updatedState = {...removeSelectedCloudInitContentInitField};
        updatedState[index] = evnt.target.checked;
        console.log('updatedState', updatedState);
        setSelectedCloudInitContentInitField(updatedState);
        if (evnt.target.checked) {
            setRemoveCloudInitContentInitState(prevState => ({
                ...prevState,
                cloudInitContent: [...prevState.cloudInitContent, val]
            }));
        } else {
            let prevRemoveState = {...removeCloudInitContentInitState};
            let elemIdx = prevRemoveState.cloudInitContent.indexOf(val);
            prevRemoveState.cloudInitContent.splice(elemIdx, 1);
            setRemoveCloudInitContentInitState(prevRemoveState);
        }
    };
    const handleInterfaceChange = (value, idx, column) => {
        const updatedInterface = [...interfaceState];
        updatedInterface[idx][column] = value;
        setInterfaceState(updatedInterface);
    };
    const handleCloudInitContentParamsChange = (value, idx, column) => {
        const updatedCloudInitContentParams = [...cloudInitContentParamsState];
        updatedCloudInitContentParams[idx][column] = value;
        setCloudInitContentParamsState(updatedCloudInitContentParams);
    };
    if (!loadingImages && !imagesError) {
        images =
            imagesData.getVmImages && imagesData.getVmImages.length
                ? imagesData.getVmImages.filter(
                image => image.image_status === 'success'
                )
                : [];
    }
    if (!loadingControllers && !controllersError) {
        controllers =
            controllersData.getControllers && controllersData.getControllers.length
                ? controllersData.getControllers.filter(
                controller => controller.controller_status === 'success'
                )
                : [];
    }
    // Upload Function
    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            //console.log(reader.result);
            setImageLogo(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Grid style={{...paddingGenerator('pb-30')}}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            container spacing={3}
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid item item xs={6}>
                                <Input
                                    value={vnfTemplateNames}
                                    type={'text'}
                                    placeholder={`${t('vnfTemplateNames.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setVnfTemplateNames(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <OutlinedSelect
                                    val={controllerId}
                                    label={`${t('controllerId.label')} *`}
                                    handleChange={evt => {
                                        setControllerId(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        controllers.length > 0
                                            ? controllers.map(controller => {
                                                return {
                                                    id: controller.controller_id,
                                                    name: controller.controller_name
                                                };
                                            })
                                            : []
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={vnfVersion}
                                    type={'text'}
                                    placeholder={`${t('vnfVersion.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setVnfVersion(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={vnfDescription}
                                    type={'text'}
                                    placeholder={`${t('vnfDescription.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setVnfDescription(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={vnfProvider}
                                    type={'text'}
                                    placeholder={`${t('vnfProvider.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setVnfProvider(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={vnfImage}
                                    label={`${t('vnfImage.label')} *`}
                                    handleChange={evt => {
                                        setVnfImage(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        images.length > 0
                                            ? images.map(image => {
                                                return {
                                                    id: image.vm_images_id,
                                                    name: image.image_name
                                                };
                                            })
                                            : []
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={vCPU}
                                    label={`${t('vCPU.label')} *`}
                                    handleChange={evt => {
                                        setVCPU(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        [{
                                            id: 1,
                                            name: 1
                                        }, {
                                            id: 2,
                                            name: 2
                                        }, {
                                            id: 4,
                                            name: 4
                                        }, {
                                            id: 6,
                                            name: 6
                                        }, {
                                            id: 8,
                                            name: 8
                                        }]
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={ram}
                                    label={`${t('ram.label')} *`}
                                    handleChange={evt => {
                                        setRAM(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        RAM_LIST
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={storageDisk}
                                    label={`${t('storageDisk.label')} *`}
                                    handleChange={evt => {
                                        setStorageDisk(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        DISK_LIST
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{
                                        ...marginGenerator('mb-8'),
                                        ...marginGenerator('mt-40')
                                    }}>
                                    {t('cloudInitDataSource.label')}
                                </Typography>
                            </Grid>
                            <Grid container spacing={3} style={{...marginGenerator('mt-8')}}>
                                <Grid item xs={6}>
                                    <Input
                                        value={cloudInitDataSource}
                                        type={'text'}
                                        placeholder={`${t('cloudInitDataSource.label')}*`}
                                        style={{width: '100%'}}
                                        color={'secondary'}
                                        onChange={evt => setCloudInitDataSource(evt.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <OutlinedSelect
                                        val={cloudInitDriveType}
                                        label={`${t('cloudInitDriveType.label')} *`}
                                        handleChange={evt => {
                                            setCloudInitDriveType(evt.target.value);
                                        }}
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={
                                            [{
                                                id: 'None',
                                                name: 'None'
                                            }, {
                                                id: 'cdrom',
                                                name: 'cdrom'
                                            }]
                                        }
                                    />
                                </Grid>
                                {/*<Grid style={marginGenerator('mt-24')}>
                                    <Button
                                        variant={'outlined'}
                                        color={'primary'}
                                        onClick={handleFileUploadClick}
                                    >
                                        {`${t('logoImage.label')}`}
                                    </Button>
                                    {uploadedFile && (
                                        <Chip
                                            avatar={<img src={Image} alt={'file'}/>}
                                            label={
                                                uploadedFile.name.length > 12
                                                    ? `${uploadedFile.name.slice(0, 12)}...`
                                                    : uploadedFile.name
                                            }
                                            onDelete={() => {
                                                setUploadedFile('');
                                                setImageLogo('');
                                            }}
                                            deleteIcon={<Close/>}
                                            variant="outlined"
                                        />
                                    )}
                                    <input
                                        type="file"
                                        id="attachment"
                                        ref={inputFile}
                                        onChange={event => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            setUploadedFile(event.target.files[0]);
                                            convertToBase64(event.target.files[0]);
                                        }}
                                        style={{display: 'none'}}
                                    />
                                </Grid>*/}
                                {(cloudInitDriveType === 'cdrom') && <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{
                                        ...marginGenerator('mb-8'),
                                        ...marginGenerator('mt-40')
                                    }}>
                                    {t('cloudInitContentParams.label')}
                                </Typography>}
                                {cloudInitDriveType && <Grid item xs={12}>
                                    <Button
                                        variant={'outlined'}
                                        color={'primary'}
                                        onClick={() => {
                                            addNewCloudInitContentInitField();
                                        }}>
                                        {t('add.label')}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant={'contained'}
                                        style={{...marginGenerator('ml-20')}}
                                        disabled={!removeCloudInitContentInitState.cloudInitContent.length}
                                        onClick={() => {
                                            removeCloudInitContentInitField();
                                            setSelectedCloudInitContentInitField([]);
                                        }}>
                                        {t('remove.label')}
                                    </Button>
                                </Grid>}
                                {cloudInitDriveType && cloudInitContentParamsState.length > 0 &&
                                cloudInitContentParamsState.map((val, idx) => {
                                    return (
                                        <React.Fragment key={`interface-${idx}`}>
                                            <Grid
                                                key={`heading-${idx}`}
                                                item
                                                xs={12}
                                                style={{
                                                    ...paddingGenerator('pl-5'),
                                                    ...paddingGenerator('pl-0')
                                                }}>
                                                <Typography variant="subtitle2" color="textPrimary">
                                                    <Checkbox
                                                        style={paddingGenerator('pl-0')}
                                                        checked={removeSelectedCloudInitContentInitField[idx]}
                                                        value={idx}
                                                        onChange={event => {
                                                            onHandleSelectCloudInitContentRow(event, idx);
                                                        }}
                                                    />
                                                    {t('cloudInitContentParams.label')} {idx + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid key={`type-${idx}`} item xs={4}>
                                                <OutlinedSelect
                                                    val={cloudInitContentParamsState[idx].path}
                                                    label={`${t('path.label')} *`}
                                                    handleChange={evt => {
                                                        handleCloudInitContentParamsChange(evt.target.value, idx, 'path')
                                                    }}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={
                                                        cloudInitContentParamsPath
                                                    }
                                                />
                                            </Grid>
                                            <Grid key={`description-${idx}`} item xs={4}>
                                                <Input
                                                    type={'text'}
                                                    placeholder={`${t('description.label')}*`}
                                                    style={{width: '100%'}}
                                                    className={classes.input}
                                                    color={'secondary'}
                                                    value={cloudInitContentParamsState[idx].description}
                                                    onChange={evt =>
                                                        handleCloudInitContentParamsChange(
                                                            evt.target.value,
                                                            idx,
                                                            'description'
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{
                                    ...marginGenerator('mb-8'),
                                    ...marginGenerator('mt-40')
                                }}>
                                {t('interfaces.label')}
                            </Typography>
                            <Grid item xs={12}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => {
                                        addNewInterfaceField();
                                    }}>
                                    {t('add.label')} {t('interface.label')}
                                </Button>
                                <Button
                                    color="secondary"
                                    variant={'contained'}
                                    style={{...marginGenerator('ml-20')}}
                                    disabled={!removeFieldState.interface.length}
                                    onClick={() => {
                                        removeInterfaceField();
                                        setSelectedRemoveInterfaceField([]);
                                    }}>
                                    {t('remove.label')}
                                </Button>
                            </Grid>
                            {interfaceState.length > 0 &&
                            interfaceState.map((val, idx) => {
                                return (
                                    <React.Fragment key={`interface-${idx}`}>
                                        <Grid
                                            key={`heading-${idx}`}
                                            item
                                            xs={12}
                                            style={{
                                                ...paddingGenerator('pl-5'),
                                                ...paddingGenerator('pl-0')
                                            }}>
                                            <Typography variant="subtitle2" color="textPrimary">
                                                <Checkbox
                                                    style={paddingGenerator('pl-0')}
                                                    checked={removeSelectedInterfaceField[idx]}
                                                    value={idx}
                                                    onChange={event => {
                                                        onHandleSelectInterfaceRow(event, idx);
                                                    }}
                                                />
                                                {t('interface.label')} {idx + 1}
                                            </Typography>
                                        </Grid>
                                        <Grid key={`name-${idx}`} item xs={4}>
                                            <Input
                                                type={'text'}
                                                placeholder={`${t('name.label')}*`}
                                                style={{width: '100%'}}
                                                className={classes.input}
                                                color={'secondary'}
                                                value={interfaceState[idx].name}
                                                onChange={evt =>
                                                    handleInterfaceChange(evt.target.value, idx, 'name')
                                                }
                                            />
                                        </Grid>
                                        <Grid key={`type-${idx}`} item xs={4}>
                                            {/*           <Input
                                                type={'text'}
                                                placeholder={`${t('type.label')}*`}
                                                style={{width: '100%'}}
                                                className={classes.input}
                                                color={'secondary'}
                                                value={interfaceState[idx].type}
                                                onChange={evt =>
                                                    handleInterfaceChange(evt.target.value, idx, 'type')
                                                }
                                            />*/}
                                            <OutlinedSelect
                                                val={interfaceState[idx].type}
                                                label={`${t('type.label')} *`}
                                                handleChange={evt => {
                                                    handleInterfaceChange(evt.target.value, idx, 'type')
                                                }}
                                                selectStyle={{...paddingGenerator('p-8')}}
                                                styleOverrides={{width: '100%', marginRight: 0}}
                                                options={
                                                    interfaceTypes
                                                }
                                            />
                                        </Grid>
                                        <Grid key={`description-${idx}`} item xs={4}>
                                            <Input
                                                type={'text'}
                                                placeholder={`${t('description.label')}*`}
                                                style={{width: '100%'}}
                                                className={classes.input}
                                                color={'secondary'}
                                                value={interfaceState[idx].description}
                                                onChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'description'
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateVnfTemplate({
                                controller_id: controllerId,
                                vnf_name: vnfTemplateNames,
                                vm_image_id: vnfImage,
                                vnf_numVcpus: parseInt(vCPU),
                                vnf_memory_in_MB: parseInt(ram),
                                vnf_storage_in_GB: parseInt(storageDisk),
                                vnf_interfaces: interfaceState,
                                vnf_cloud_init_data_source: cloudInitDataSource,
                                vnf_cloud_init_drive_type: cloudInitDriveType,
                                vnf_cloud_init_content_params: cloudInitContentParamsState,
                                vnf_description: vnfDescription,
                                vnf_provider: vnfProvider,
                                vnf_version: vnfVersion
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateVNFTemplateModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateVNFTemplateModal);
