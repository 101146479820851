import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

  
function LiveMultipleCameraPlayerModal(
    {
        open,
        handleClose,
        urls
    }
) {
  
  const classes = useStyles();
  
  return (
    <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container>
            {urls.map((url) => (<Grid item xs={((12/urls.length) <= 4) ? 4 : 12/urls.length }>
                  <iframe 
                    frameborder="0"
                    height="400px"
                    width="100%"
                    className='videoFrame'
                    id="background-video"
                    src={url}
                    allow="autoplay"
                  >
                  </iframe>
                </Grid>
            ))}
            </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

LiveMultipleCameraPlayerModal.propTypes = {
    urls: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default LiveMultipleCameraPlayerModal;
