import React, { useState, useContext } from 'react'
import Table from '@material-ui/core/Table';
import { useLazyQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import { Chip, IconButton, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DownloadIcon from '@material-ui/icons/GetApp';
import Message from '../../../../../../components/Message';
import OcrFolderItem from '../../OcrConfiguration/OcrConfigurationFolder/OcrFolderItem';
import { marginGenerator } from '../../../../../../theme/utils';
import { dateFormatterLLL} from '../../../../../../utils';
import SearchBox from '../../../../../../components/SearchBox';
import {
    GET_OCR_PARSE_IMG,
} from '../../../../Ocr/queries';
import MenuWrapper from '../../../../../../components/MenuWrapper';
import {OcrContext} from '../../../../../../contexts/OcrProvider';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TimelineIcon from '@material-ui/icons/Timeline';
import { isSuperUser } from '../../../../../../utils';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="primary"
            onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <DeleteIcon color="secondary" />}
        </Button>
    );
}

function DownloadButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        // <Button
        //     variant={'outlined'}
        //     onClick={onClick}
        //     disabled={loading}
        // >
        <IconButton
            onClick={onClick}
            disabled={loading}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && <DownloadIcon /> }
        </IconButton>
    );
}

const OcrTreeListings = ({
    t,
    viewOptions,
    createFolderEnable,
    createSubFolderEnable,
    ocrChildData,
    handleSearch,
    handleOpenFileView,
    createProjectEnable,
    uploadOcrDoc,
    selecteIdForDelete,
    ocrFileDeletetingStarted,
    handleSubmitOfDeleteOcrTreeItems,
    uploadMultipleOcrDoc,
    assignProjectEnable,
    organizationId,
    handleSendEmailClick,
    createGroupOrUserEnable,
    ocrAutocompleteList,
    handleChangeOfAutocamplte,
    ocrAutocompleteLoading,
    enableProceeFlow,
    ocrOcrProcessDataLoading,
    handleClickItem,
    handleTreeSearch,
    setExpendBySearch,
    setSelectedNodes,
    treeData,
    globalDocSearch
}) => {

 

    let columns = [];
    const [open, setOpen] = useState(false);
    const [ocrParseImgLoading, setOcrParseImgLoading] = useState(false);
    const [ocrParseImgs, setOcrParseImgs] = useState('');
    const [selecteIdForDownload, setSelecteIdForDownload] = useState('');
    const [isDownloadStarted, setIsDownloadStarted] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFolderDetails, setSelectedFolderDetails] = useState('')
    const { 
        handleRefetchedOrFilter
      } = useContext(OcrContext);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleChangeOfAutocamplte(searchTerm);
        }, 1500);
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm]);

    React.useEffect(() => {
        if(globalDocSearch){
            const delayDebounceFn = setTimeout(() => {
                handleOnClickSearchItemSelected(null, globalDocSearch);
            }, 1500);
            return () => clearTimeout(delayDebounceFn)
        }
    }, [globalDocSearch]);



    if (viewOptions.place) {
        columns.push(
            {
                label: 'Project Name',
                key: "name"
            },
            {
                label: 'Description',
                key: "description"
            },
            {
                label: t('createdBy.label'),
                key: "created_by_name"
            },
            {
                label: t('createdDate.label'),
                key: "created_at"
            },
            {
                label: t('action.label'),
                key: "action"
            }
        );
    } else if (viewOptions.project) {
        columns.push(
            {
                label: 'Folder Name',
                key: "name"
            },
            {
                label: 'Description',
                key: "description"
            },
            {
                label: t('createdBy.label'),
                key: "created_by_name"
            },
            {
                label: t('createdDate.label'),
                key: "created_at"
            },
            {
                label: t('action.label'),
                key: "action"
            }
        );
    } else if (viewOptions.folder) {
        columns.push(
            {
                label: 'Files Name',
                key: "file_name"
            },
            {
                label: 'Uploaded Date',
                key: "created_at"
            },
            {
                label: 'Uploaded By',
                key: "created_by_name"
            },
            {
                label: 'Heading',
                key: "heading"
            },
            {
                label: t('action.label'),
                key: "action"
            }
        );
    }

    const handleOnClickSearchItemSelected = (event, newValue) => {
        if(newValue === null){
            setExpendBySearch([
                treeData && treeData.length ? treeData[0].id : ''
            ]);
            setSelectedNodes([]);
            handleClickItem(treeData[0], 'place', []);
        }
        else if (treeData.length > 0 && newValue.id && newValue.project) {
            const project = treeData[0]?.projects.filter(el => el.id === newValue.project)
            const folder = project[0]?.folders?.filter(el => el.id === newValue.folder);

            handleTreeSearch(folder[0], "folder",newValue.id, newValue.project, newValue.place);

            setExpendBySearch([
                newValue.place,
                newValue.project,
                newValue.folder
            ]);
            setSelectedNodes([
                newValue.folder,
                newValue.id
            ]);
        }
    };

    const [getOcrParseImg] = useLazyQuery(GET_OCR_PARSE_IMG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const alldata = get(data, 'getOcrParseImageData.data', '')
            setOcrParseImgs(alldata);
            if (isDownloadStarted) {
                hadnleDowloader(alldata, 'download');
            }
            setOcrParseImgLoading(false);
            setSelecteIdForDownload('');
        },
        onError: error => {
            Message.error(error);
            setOcrParseImgLoading(false);
            setSelecteIdForDownload('');
        }
    });


    const hadnleDowloader = (data, type) => {
        if (type === 'download') {
            setIsDownloadStarted(false);
            FileSaver.saveAs(data.url, data.file_name);
        }
    };

    const assignMenuItems = [
        { label: t('assignUserOcr.label') },
        { label: t('assignFolderToGroupOcr.label') }
    ];
    
    return <>
        <Grid container justify={'space-between'} style={{ ...marginGenerator("mb-20") }}>
            <Grid item>
                <SearchBox placeholderText={`Search projects/folders/files...`} handleSearch={handleSearch} />
            </Grid>
            <Grid style={{ width: '40%' }}>
                <Autocomplete
                    id="asynchronous-demo"
                    style={{ width: "100%" }}
                    size="small"
                    onInputChange={ev => setSearchTerm(ev.target.value) }
                    getOptionSelected={(option, value) => option.id === value.id }
                    onChange={(event, value) => handleOnClickSearchItemSelected(event,value)}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                        handleChangeOfAutocamplte("");
                    }}
                    getOptionLabel={(option) => `${option.name} ${option.heading} ${option.ocr_text}`}
                    renderOption={(option) => <Typography variant="body1">{option.name}</Typography> }
                    options={ocrAutocompleteList}
                    loading={ocrAutocompleteLoading}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        placeholder="Search your file.." 
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {ocrAutocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                {viewOptions.place &&
                    <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={createProjectEnable}
                    >
                        Create New Project
                    </Button>
                }
                {viewOptions.project &&
                    <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={createFolderEnable}
                    >
                        Create New Folder
                    </Button>
                }
                {viewOptions.folder &&
                    (<>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={createSubFolderEnable}
                        >
                            Create SubFolder
                        </Button>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadOcrDoc}
                            style={{ ...marginGenerator('ml-10')}}
                        >
                            Upload File
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadMultipleOcrDoc}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                        >
                            Upload Multiple Doc
                        </Button>
                    </>
                    )
                }
                
                { viewOptions.subfolders &&

                    (<>
                    
                    <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadOcrDoc}
                            style={{ ...marginGenerator('ml-10')}}
                        >
                            Upload File
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={uploadMultipleOcrDoc}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                        >
                            Upload Multiple Doc
                        </Button>
                    </>)
                }
            </Grid>
        </Grid>
        <TableContainer style={{ overflowX: 'hidden' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                         

                         <>
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>


                       
                            </>

                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ocrChildData && ocrChildData.length > 0 ? ocrChildData.map((rowItem) => (
                        <>
         
                        
                        <TableRow
                            style={{ cursor: 'pointer' }}
                            key={rowItem.id}
                        >
                            {columns.map((colItem, colIndex) => {
                                return (
                                    <>
                           

                                        <TableCell key={colIndex}>
                                            {viewOptions.folder && colItem.key === "action" ?
                                                (
                                                    (rowItem.status || rowItem.is_barcode) ? (
                                                        <div style={{ display: 'flex' }}>
                                                            <Button
                                                                color="primary"
                                                                onClick={evt => handleOpenFileView(rowItem)}
                                                            >
                                                                <VisibilityIcon />
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                onClick={evt => handleSendEmailClick(rowItem)}
                                                            >
                                                                <MailOutlineIcon />
                                                            </Button>
                                                            <ButtonComponent
                                                                t={t}
                                                                loading={selecteIdForDelete === rowItem.id ? ocrFileDeletetingStarted : false}
                                                                onClick={() => {
                                                                    handleSubmitOfDeleteOcrTreeItems({ id: rowItem.id, name: rowItem.file_name, type: 'file' });
                                                                }}
                                                            />
                                                            <DownloadButtonComponent
                                                                t={t}
                                                                loading={selecteIdForDownload === rowItem.id ? ocrParseImgLoading : false}
                                                                onClick={() => {
                                                                    setOcrParseImgLoading(true);
                                                                    setSelecteIdForDownload(rowItem.id);
                                                                    setIsDownloadStarted(true);
                                                                    getOcrParseImg({
                                                                        variables: {
                                                                            organization: organizationId,
                                                                            id: rowItem.id,
                                                                            isDownload: "true"
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                             {
                                                                ocrOcrProcessDataLoading ? <CircularProgress size={14} /> : (
                                                                rowItem.isProcessAvilbaleForFile? (
                                                                <Button
                                                                    color="primary"
                                                                    variant={'outlined'}
                                                                    
                                                                >
                                                                    <TimelineIcon onClick={() => enableProceeFlow(rowItem.stepData, rowItem)} />
                                                                </Button>
                                                                ): null
                                                                )
                                                            }
                                                        </div>
                                                    ) :
                                                        (
                                                            <Typography variant={'subtitle1'}>Pending</Typography>
                                                        )
                                                ):

                                                colItem.key === "created_at" ? dateFormatterLLL(rowItem.created_at) 

                                                : viewOptions.project && colItem.key === "action" ? (
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: rowItem.isProcessavilbaleForFolder ? 'space-around': 'flex-start' }}>
                                                        <Button
                                                            color="primary"
                                                            onClick={evt => Message.error('This feature not implemented yet')}
                                                        >
                                                            <VisibilityIcon />
                                                        </Button>
                                                        <Grid>
                                                            {
                                                                isSuperUser(localStorage.getItem('role')) ? (<IconButton
                                                                    size={'small'}
                                                                    disableRipple={true}
                                                                    disableFocusRipple={true}
                                                                    color={'primary'}
                                                                    onClick={evt => {
                                                                        setAnchorEl(evt.currentTarget);
                                                                    }}
                                                                >
                                                                    <GroupAddIcon onClick={()=> setSelectedFolderDetails(rowItem)} />
                                                                </IconButton>) : (
                                                                    null
                                                                )
                                                            }
                                                            
                                                        </Grid>
                                                        <MenuWrapper
                                                            id={'ticket-menu'}
                                                            anchorEl={anchorEl}
                                                            handleClose={evt => {
                                                                evt.stopPropagation();
                                                                setAnchorEl(null);
                                                            }}
                                                            menuItems={[...assignMenuItems]}
                                                            handleMenuItemClick={elem => {
                                                                setAnchorEl(null);
                                                                if(elem.label === t('assignUserOcr.label')){
                                                                    createGroupOrUserEnable(t('assignFolderToUserOcr.label'),{folderId:selectedFolderDetails && selectedFolderDetails.id});
                                                                    setSelectedFolderDetails('');
                                                                } else if(elem.label === t('assignFolderToGroupOcr.label')){
                                                                    assignProjectEnable(t('assignFolderToGroupOcr.label'), {exitingGroupDetails: selectedFolderDetails && selectedFolderDetails,folderId:selectedFolderDetails && selectedFolderDetails.id});
                                                                    handleRefetchedOrFilter('groupsOfFolder', selectedFolderDetails && selectedFolderDetails.id);
                                                                    setSelectedFolderDetails('');
                                                                }
                                                            }}
                                                        />
                                                        {/* {
                                                            ocrOcrProcessDataLoading ? <CircularProgress size={14} /> : (
                                                            rowItem.isProcessavilbaleForFolder? (
                                                            <Button
                                                                color="primary"
                                                                variant={'outlined'}
                                                                onClick={() => enableProceeFlow(rowItem.stepData, rowItem)}
                                                            >
                                                                {t('processeFlow.label')}
                                                            </Button>
                                                            ): null
                                                            )
                                                        } */}
                                                    </div>
                                                ) 
                                                :viewOptions.place && colItem.key === "action" ? (
                                                    null
                                                    // <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    //     {
                                                    //         ocrOcrProcessDataLoading ? <CircularProgress size={14} /> : (
                                                    //         rowItem.isProcessavilbaleForProject? (
                                                    //         <Button
                                                    //             color="primary"
                                                    //             variant={'outlined'}
                                                    //             onClick={() => enableProceeFlow(rowItem.stepData, rowItem)}
                                                    //         >
                                                    //             {t('processeFlow.label')}
                                                    //         </Button>
                                                    //         ): '-'
                                                    //         )
                                                    //     }
                                                    // </div>    
                                                )
                                                :(
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        color={colItem.color || 'textPrimary'}
                                                        style={colItem.style ? colItem.style : null}
                                                    >
                                                        {rowItem[colItem.key] || '-'}
                                                    </Typography>
                                                )}
                                        </TableCell>
                                    </>
                                );
                            })}
                        </TableRow>
                        </>
                    )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                Record not found!
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>;
};

export default OcrTreeListings;
