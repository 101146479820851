import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  search: {
    display: 'inline',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      backgroundColor: theme.palette.grey[200]
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      '&:focus-within': {
        width: 200,
        marginRight: 16,
        paddingRight: 25
      }
    }
  },
  searchIconContainer: {
    padding: '8px',
    zIndex: 1,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[200]}`
  },
  searchIcon: {
    height: '19px',
    width: '19px'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%',
    border: `1px solid ${theme.palette.common.white}`,
    [theme.breakpoints.up('sm')]: {
      width: 0,
      '&:focus': {
        border: `1px solid ${theme.palette.grey[200]}`,
        width: 200,
        backgroundColor: theme.palette.grey[200]
      }
    }
  },
  wideInput: {
    transition: theme.transitions.create('width'),
    padding: theme.spacing(2, 2, 2, 5),
    width: '100%',
    minWidth: '300px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 25,
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: '300px',
        border: `1px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.grey[200]
      }
    }
  }
}));

function SearchBox({ handleSearch, type, onEnterSearch, placeholderText = '' }) {
  const classes = useStyles();
  let placeholder;
  if (type && type === 'wide') {
    placeholder = placeholderText ? placeholderText :'Search for similar incident';
  } else {
    placeholder = 'search';
  }

  return (
    <div className={classes.search}>
      {type && type === 'wide' ? (
        ''
      ) : (
        <div className={classes.searchIconContainer}>
          <SearchIcon className={classes.searchIcon} />
        </div>
      )}
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input:
            type && type === 'wide' ? classes.wideInput : classes.inputInput
        }}
        onKeyPress={onEnterSearch ? evt => onEnterSearch(evt, evt.target.value) : null}
        onChange={evt => handleSearch(evt.target.value, evt)}
        inputProps={{
          'aria-label': 'search'
        }}
      />
    </div>
  );
}

SearchBox.propTypes = {};

export default SearchBox;
