import { Box, Button, Grid, TextField, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../../../components/hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IPut from 'iput';
import { getStatusForLinkType } from '../../../../../../services/clientService';
import {
  checkVlanAvailability,
  getAvailableVlansByPodId
} from '../../../../../../services/connectionService';
import VlansPopup from '../Popups/VlansPopup';
import { isIP } from 'is-ip';
import { useAppContext } from '../../../../../../contexts/AppContext';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    height: '43px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const FcrToPort = props => {
  const {
    classes,
    handlePrev,
    handleNext,
    setState,
    client,
    getSubnet,
    destinationPodId
  } = props;
  const [linkTypeStatus, setLinkTypeStatus] = useState('');
  const [messageBEnd, setMessageBEnd] = useState('');
  const [availableVlans, setAvailableVlans] = useState([]);
  const [openVlanPopup, setOpenVlanPopup] = useState(false);
  const [nextStepAllowed, setNextStepAllowed] = useState(false);
  const [subnetIsValid, setSubnetIsValid] = useState(true);
  const [bEndIsValid, setBEndIsValid] = useState(false);

  const { state } = useAppContext();

  const initialValues = {
    connectionName: '',
    rateLimit: '',
    A_end: '',
    B_end: '',
    MinimumTerm: 'standard',
    clientName: ''
  };
  const validationSchema = yup.object().shape({
    connectionName: yup.string().required('This field is required'),
    clientName: yup.string().required('This field is required'),
    rateLimit: yup
      .number()
      .typeError('Enter a value between 0 & 1000')
      .min(0, 'Enter a value between 0 & 1000')
      .max(1000, 'Enter a value between 0 & 1000')
      .required('This field is required'),
    B_end: yup
      .number()
      .typeError('This field is required')
      .required('This field is required'),
    MinimumTerm: yup.string().required('This field is required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, setValue, control, watch } = methods;

  const B_end = watch('B_end');

  const handleChange = val => {
    getSubnet(val);
    if (isIP(val)) {
      setSubnetIsValid(true);
      setNextStepAllowed(true);
    } else {
      setSubnetIsValid(false);
      setNextStepAllowed(false);
    }
  };

  const onSubmit = data => {
    setState({ data });
    handleNext();
  };

  const GetStatusForLinkType = async () => {
    const payload = {
      source: state.sourceFcrDetails.sourceFcrId,
      destination: state.destinationPortDetails.destinationPortId,
      link_type: 'fcr-port'
    };
    const res = await getStatusForLinkType(payload);
    setLinkTypeStatus(res?.data?.result);
    if (res?.data?.result === 'Local') {
      setValue('MinimumTerm', 'local');
    }
  };

  const checkBendVlan = async () => {
    const payload = {
      a_end_port_id: state.sourceFcrDetails.sourceFcrId,
      b_end_port_id: state.destinationPortDetails.destinationPortId,
      b_end_vlan_id: B_end
    };
    try {
      const res = await checkVlanAvailability(payload);
      if (res?.status === 200) {
        setMessageBEnd(res?.data?.message || res?.message);
        setBEndIsValid(true);
      } else {
        setMessageBEnd(
          res?.response?.data?.message || res?.message || res?.data?.message
        );
        setBEndIsValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const GetVlans = async id => {
    if (id) {
      setOpenVlanPopup(true);
      const res = await getAvailableVlansByPodId(id);
      setAvailableVlans(res?.data?.result);
    }
  };

  useEffect(() => {
    if (B_end) checkBendVlan();
  }, [B_end]);

  useEffect(() => {
    GetStatusForLinkType();
    setValue('clientName', state?.clientDetails?.clientName);
  }, [client, setValue, linkTypeStatus]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '10px', width: '500px' }}>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Connection Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="connectionName"
                label="Connection Name"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Client Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="clientName"
                size="small"
                autoComplete="off"
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Rate Limit
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="rateLimit"
                label="Rate Limit (Mbps)"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Link Type
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              {linkTypeStatus === 'Local' ? (
                <RHFTextField
                  variant="outlined"
                  name="MinimumTerm"
                  size="small"
                  disabled
                />
              ) : (
                <RHFSelect name="MinimumTerm" variant="outlined" size="small">
                  <option value="">Select</option>
                  {[
                    {
                      key: 'Standard Unprotected',
                      value: 'standard-unprotected'
                    },
                    {
                      key: 'Premium Unprotected',
                      value: 'premium-unprotected'
                    },
                    { key: 'Standard Protected', value: 'standard-protected' }
                  ].map(item => (
                    <option key={item.key} value={item?.value}>
                      {item?.key}
                    </option>
                  ))}
                </RHFSelect>
              )}
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              B-end V-LAN
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              sm={8}
              spacing={1}
              justify="center"
              alignItems="center">
              <Grid item sm={8}>
                <Controller
                  name="B_end"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      label="B-end V-LAN"
                      autoComplete="off"
                      fullWidth
                      value={
                        typeof field.value === 'number' && field.value === 0
                          ? ''
                          : field.value
                      }
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                {B_end && (
                  <span
                    style={{
                      color: messageBEnd === 'Available' ? '#000' : 'red'
                    }}>
                    {messageBEnd}
                  </span>
                )}
              </Grid>
              <Grid item sm={4}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => GetVlans(destinationPodId)}>
                  Check Availability
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Subnet
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={1}
              sm={8}>
              <Grid item sm={7}>
                <IPut onChange={handleChange} />
              </Grid>
              <Grid item sm={5}>
                /30
              </Grid>
            </Grid>
            {!subnetIsValid && (
              <span sx={{ color: 'red' }}>Enter a valid IP Address</span>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box p={2}>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Box>
            <Box p={2}>
              <Button
                type="submit"
                disabled={
                  B_end !== null && bEndIsValid && nextStepAllowed
                    ? false
                    : true
                }
                variant="contained"
                size="medium"
                color="primary">
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      {openVlanPopup && (
        <VlansPopup
          openVlanPopup={openVlanPopup}
          setOpenVlanPopup={setOpenVlanPopup}
          availableVlans={availableVlans}
          setAvailableVlans={setAvailableVlans}
        />
      )}
    </>
  );
};

export default withStyles(styles)(FcrToPort);
