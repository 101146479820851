import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  paper: { maxWidth: "800px" },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px'
  }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateCategoryModal({
    t,
    open,
    data,
    handleSubmitClick,
    handleClose,
    modalState
}) {
    const classes = useStyles();
    const [name, setName] = useState('');

    React.useEffect(() => {
        if(modalState.clearData && open){
            setName('');
			handleClose();
        }
    }, [modalState]);


    React.useEffect(() => {
        if(data){
            setName(data.name);
		}
    }, [data]);
    
    return (
        <Dialog
            classes={{ paper: classes.paper}}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="country-title">{data ? t('edit.label') : t('createNew.label')} {t('country.label')}</DialogTitle>
            <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid  
                    style={{
                    ...marginGenerator('mt-8')
                    }}>
                        <Input
                            value={name}
                            type={'text'}
                            placeholder={t('name.label')}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => setName(evt.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!name){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleSubmitClick({name: name, _id: (data && data._id) ? data._id : null });
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateCategoryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCategoryModal);
