import {
  Button,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormProvider,
  RHFTextField
} from '../../../../../components/hook-form';
import { AddBgp } from '../../../../../services/fcrService';
import Popup from '../../../Popup';
import Message from '../../../../../components/Message';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const validationSchema = yup.object().shape({
  remoteAs: yup
    .number()
    .typeError('Enter a value between 65001 & 65100')
    .min(65001, 'Enter a value between 65001 & 65100')
    .max(65100, 'Enter a value between 65001 & 65100')
    .required('This field is required')
});

const AddBgpPopupPort = ({
  BgpAddView,
  setBgpAddView,
  connectionId,
  connCircleBEndIp,
  classes
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();
  const [error, setError] = useState('');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialValues = { neighbourIp: '', remoteAs: '' };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async ({ remoteAs }) => {
    const payload = {
      conn_id: connectionId,
      neighbor_ip: nieghbourIp,
      neighbor_remote: remoteAs.toString()
    };
    const res = await AddBgp(payload);
    if (res?.status === 200) {
      Message.success('BGP Added Succesfully');
      setBgpAddView(false);
      reset();
    } else {
      setError(res?.response?.data?.message);
      Message.error(res?.message);
    }
    reset();
  };

  useEffect(() => {
    setNieghbourIp(connCircleBEndIp);
  }, [connCircleBEndIp]);

  return (
    <>
      <Popup
        title="Add BGP"
        openPopup={BgpAddView}
        setOpenPopup={setBgpAddView}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid item>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="Standard" {...a11yProps(0)} />
                <Tab label="Advanced" {...a11yProps(1)} disabled />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Grid item container alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Neighbor IP
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <TextField
                      value={nieghbourIp}
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '10px' }}>
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Remote-AS
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="remoteAs"
                      label="Remote-AS"
                      autoComplete="off"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                {error && (
                  <Grid item>
                    <h5 style={{ color: 'red', textAlign: 'center' }}>
                      {error}
                    </h5>
                  </Grid>
                )}
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={1}
                style={{ height: '175px' }}></TabPanel>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Grid item container justify="center" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    setBgpAddView(false);
                  }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default AddBgpPopupPort;
