import { gql } from 'apollo-boost';

/*
  Get Sub Users list
 */
export const GET_SUB_ADMIN_USERS = gql`
  query SubAdmin {
    getSubAdminUsers {
      id
      firstName
      lastName
      email
      phone
      role
      countryId
      created_at
    }
  }
`;

export const GET_SUB_ADMIN_CUSTOMERS = gql`
  query SubAdmin {
    getSubAdminCustemers {
      id
      firstName
      lastName
      email
      phone
      organisations
      role
      countryId
      created_at
    }
  }
`;

/*
Get all vendor list
*/

export const GET_VENDOR_LIST = gql`
  query Vendor {
    vendor {
      id
      name
      register
      address
      contactPersonName
      contactPersonEmail
      contactPersonPhone
    }
  }
`;

export const GET_ALL_ADDITIONAL_AGENT_LIST = gql`
  query AllUserList($page: Int) {
    allUserPagination(page: $page) {
      data
      total
    }
  }
`;

export const GET_ALL_SALES_SPECILIST = gql`
  query AbujaPhcNGList($page: Int) {
    salesSpecilistPagination(page: $page) {
      data
      total
    }
  }
`;

export const GET_LAGOS_NGLIST = gql`
  query AbujaPhcNGList($page: Int) {
    getLagosNGPagination(page: $page) {
      data
      total
    }
  }
`;

export const GET_ABUJA_NGLIST = gql`
  query AbujaPhcNGList($page: Int) {
    getAbujaNGPagination(page: $page) {
      data
      total
    }
  }
`;

export const GET_PHC_NGLIST = gql`
  query AbujaPhcNGList($page: Int) {
    getPhcNGPagination(page: $page) {
      data
      total
    }
  }
`;
