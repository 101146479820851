import React,{useState, useEffect, useRef, useCallback} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import SearchBox from '../../../../../../components/SearchBox';
import Message from '../../../../../../components/Message';
import { ACTION_TYPES } from '../../../../../../utils/constants';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

var PDFJS = window.pdfjsLib;

const CreateRule = ({
          t, 
          open, 
          onClose, 
          ocrData, 
          handleSubmitOfOcrRule,
          ocrRuleSubmitLoading,
          ocrImageDataForCreateRule,
          actionType,
          ruleData={},
          handleSubmitEditOfOcrRule = () => {},
          handleSearchOfOcrRuleImg
        }) => {

    const classes = useStyles();
    const [ruleName, setRuleName] = useState('');
    const [description, setDescription] = useState('');
    const [docId, setDocId] = useState('');
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [crop, setCrop] = useState({ unit: '%',height: 20, width: 30, aspect: null });
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [upImg, setUpImg] = useState();
    const [cropImgValueForApi, setCropImgValueForApi] = useState('');
    const [selectedImgFromList, setSelectedImgFromList] = useState('');

    const onSelectFile = async (e, data) => {
      try {
        const isFileExist = e.target.files;
        // this code is req in future
        // let slecetdFile = '';
        // if(data && data.imgUrl && data.name){
        //   const defaultType = 'image/jpeg'
        //   let header = new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //   });
        //   let options = {
        //     mode: 'no-cors',
        //     header: header,
        //   };

        //   const response = await fetch(data.imgUrl, options);
        //   const blob = new Blob([{size: data.size, type: "image/jpeg"}]);
        //   const buffer = new ArrayBuffer(data.size);
        //   const view = new Int32Array(buffer);
        //   debugger;
        //   slecetdFile = new File([buffer], data.name, {
        //     type: defaultType
        //   });
        //   debugger;
        //   console.log('slecetdFile', slecetdFile);
        // }

        // const isFileExist = e ? e.target.files : [slecetdFile];
        if (isFileExist && isFileExist.length > 0) {
          if(isFileExist[0].type === 'application/pdf'){
          const uri = URL.createObjectURL(e.target.files[0]);
          var pdf = await PDFJS.getDocument({ url: uri });
          var page = await pdf.getPage(1);

          var viewport = page.getViewport(1);
      
          var render_context = {
            canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
            viewport: viewport
          };
          setWidth(viewport.width);
          setHeight(viewport.height);
          await page.render(render_context);

          var canvas = document.getElementById("pdf-canvas");
          var img = canvas.toDataURL("image/png");
          const base64Data = img;
          const base64 = await fetch(base64Data);
      
          const base64Response = await fetch(base64Data);
          const blob = await base64Response.blob();
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(blob);
          console.log('img', img);
          }else if (isFileExist[0].type === 'image/jpeg' || isFileExist[0].type === 'image/png'){
            const reader = new FileReader();
            //debugger;
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(isFileExist[0]);
            //debugger;
          }
        } 
      } catch (error) {
        console.log('error', error);
      }
    };

    const onLoad = useCallback((img) => {
      imgRef.current = img;
    }, []);

    useEffect(() => {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
      }
      const corpValus = {};
      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;
      
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;
      
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      corpValus.x = crop.x;
      corpValus.y = crop.y;
      corpValus.width = crop.width;
      corpValus.height = crop.height;
      corpValus.scale_x = scaleX;
      corpValus.scale_y = scaleY;
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      setCropImgValueForApi(corpValus);
    }, [completedCrop]);
    
    useEffect(() => {
      setRuleName('');
      setDescription('');
      setDocId('');
      setUpImg();
      setCropImgValueForApi('');
      imgRef.current = null;
    }, [!open]);

    useEffect(() => {
      setRuleName(ruleData.rule_name);
      setDescription(ruleData.description);
      const fetchedorpValus = {};

      fetchedorpValus.x = ruleData.x;
      fetchedorpValus.y = ruleData.y;
      fetchedorpValus.width = ruleData.width;
      fetchedorpValus.height = ruleData.height;
      fetchedorpValus.scale_x = ruleData.scale_x;
      fetchedorpValus.scale_y = ruleData.scale_y;
      setCropImgValueForApi(fetchedorpValus);
      
    }, [ruleData.rule_name,
        ruleData.description,
        ruleData.x,
        ruleData.y,
        ruleData.height,
        ruleData.width,
        ruleData.scale_x,
        ruleData.scale_y]);

        // useEffect(() => {
        //   if(docId){
        //     imgRef.current = null;
        //     previewCanvasRef.current = null;
        //     setUpImg();
        //   }
        // }, [docId]);
        
    return (
        <Dialog 
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                 Create Parse Rule
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
            <Grid container spacing={2} style={{ alignItems: 'flex-start' , marginBottom: '5px'  }}>
                <Grid item xs={3}>
                    <TextField
                      value={ruleName}
                      id="outlined-search" 
                      label="Rule Name" 
                      type="search"
                      style={{ width: '100%'}}
                      onChange={(e) => setRuleName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={6}>
                  <TextField
                    value={description}
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    style={{ width: '100%'}}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                <Grid item xs={1}/>
                </Grid>
              </Grid>
              {
                actionType ===  ACTION_TYPES.CREATE && (
                  <>
                       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'baseline' }}> 
                      <Typography >Select Existings Document</Typography>
                      <div>
                      <SearchBox type="wide" placeholderText="Search Existing Documents" handleSearch={handleSearchOfOcrRuleImg} />
                      </div>
                      <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={() => {
                          setDocId('');
                          setSelectedImgFromList('');
                        }}
                      >
                        unselct a doc
                    </Button>
              </div>
              <div style={{ border: '1px solid Black' ,display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', height: 360}}>
                    {ocrImageDataForCreateRule && ocrImageDataForCreateRule.length > 0 ? ocrImageDataForCreateRule.map((rowitem) => (

                              <Chip
                                avatar={
                                <Avatar 
                                  alt=""
                                  src={rowitem.url}
                                  style={{ height: 190, width: 140, borderRadius: 12}}
                                  />
                                }
                                label={rowitem.file_name}
                                variant={rowitem.id === docId? '': "outlined"}
                                style={{ marginRight: '5px', height: 290, width: 278, border: 'none', marginTop: 60 }}
                                onClick={()=> {
                                  setDocId(rowitem.id);
                                  setSelectedImgFromList(rowitem.url);
                                  //onSelectFile('',{imgUrl:rowitem.url, name:rowitem.file_name, size:rowitem.file_size});
                                }}
                                color={rowitem.id === docId? 'primary': ""}
                              />        
                          )) :
                          <Typography variant={'subtitle2'} style={{ display: 'flex', justifyContent: 'center'  }}>Record not found!</Typography>                   
                    }
              </div>   
                  </>
                )
              }     
              <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '5px'  }}>OR</Typography>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                    {
                      docId ?
                      (
                        <img
                        src={selectedImgFromList}
                        alt=''
                        style={{ height: 221, width: 199}}
                      />
                      ):(
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                          <input 
                            accept="application/pdf,image/x-png,image/jpeg"
                            onChange={onSelectFile} 
                            type="file"
                          />
                        </div>
                      )
                    }
                </Grid>
                <Grid item xs={4} />
              </Grid>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
              <div id="pdf-main-container" 
                style={{width: 400,
                                margin: '20px auto'
              }}
              >
                  <div id="pdf-contents">
                    <div id="image-convas-row" style={{
                      display: 'flex',
                      margin: '20px 0',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}>
                    <canvas ref={previewCanvasRef} id="pdf-canvas" width={width} height={height} />
                    </div>
                  </div>
              </div>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={onClose}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                      t={t}
                      loading={ocrRuleSubmitLoading}
                      onClick={() => {
                        if(actionType === ACTION_TYPES.CREATE){
                          handleSubmitOfOcrRule({
                            description,
                            ruleName,
                            docId,
                            x: cropImgValueForApi.x,
                            y: cropImgValueForApi.y,
                            height: cropImgValueForApi.height,
                            width: cropImgValueForApi.width,
                            scale_x:cropImgValueForApi.scale_x,
                            scale_y:cropImgValueForApi.scale_y,
                          })
                        } else if (actionType === ACTION_TYPES.EDIT){
                          handleSubmitEditOfOcrRule({
                            id: ruleData.id,
                            description,
                            ruleName,
                            x: cropImgValueForApi.x || ruleData.x,
                            y: cropImgValueForApi.y || ruleData.y,
                            height: cropImgValueForApi.height || ruleData.height,
                            width: cropImgValueForApi.width || ruleData.width,
                            scale_x:cropImgValueForApi.scale_x ||  ruleData.scale_x,
                            scale_y:cropImgValueForApi.scale_y || ruleData.scale_y
                          })
                        }
                      }}
                    />
                </Grid>          
            </DialogActions>
        </Dialog>
    )
}

export default CreateRule
