import { gql } from 'apollo-boost';

/*
  Get all service tickets
 */
export const GET_SERVICE_TICKETS = gql`
  query ServiceTickets(
    $status: String
    $priority: String
    $subscription: String
    $ownerId: String
  ) {
    servicetickets(
      status: $status
      priority: $priority
      subscription: $subscription
      ownerId: $ownerId
    ) {
      id
      ticketId
      title
      createdAt
      createdByName
      status
      priority
      updatedAt
      updatedByName
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

/*
  Get service ticket details by id
 */
export const GET_SERVICE_TICKET_DETAILS_BY_ID = gql`
  query ServiceTicket($id: String!) {
    getServiceTicketById(id: $id) {
      id
      ticketId
      title
      createdAt
      mailThreadId
      isParent
      createdByName
      createdBy
      status
      priority
      attachments
      incidentTypeGroup
      incidentCause
      incidentType
      escalationLevel
      customer
      vendor
      assignee
      ownerId
      updatedAt
      ticketType
      updatedBy
      description
      sites {
        id
        name
      }
      subscription {
        id
        service {
          id
          name
        }
      }
      activityLogs {
        logMessage
        attachments
        createdAt
        createdByName
      }
    }
  }
`;

/*
  Get service ticket details by subscription id
 */
export const GET_SERVICE_TICKETS_BY_SUBSCRIPTION_ID = gql`
  query($id: String!) {
    serviceticketsBySubscription(subscription: $id) {
      id
      ticketId
      title
      createdAt
      createdByName
      status
      priority
      updatedAt
      updatedBy
      description
    }
  }
`;

/*
  Get service ticket categories
 */
export const GET_TICKET_CATEGORIES = gql`
  query ServiceTicketCategory {
    categories {
      id
      name
      status
    }
  }
`;
