import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from '../../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
import PropertyHeader from '../../../PropertyHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    subscriptionId: "",
    policyNo: "",
    vatRegNo: "",
    insured_name: "",
    insured_occupation: "",
    insured_address: "",
    insured_telNo: "",
    lossOccurance_date: null,
    lossOccurance_when: "",
    lossPlace_where: "",
    lossPlace_isPremisesOccupied: "",
    lossPlace_whom: "",
    lossPlace_whenLast: null,
    lossPlace_purposeOccupation: "",
    lossCause_fullDescribe: "",
    lossCause_otherPartyNameAddress: "",
    lossPrevious_isSufferedDamage: "",
    lossPrevious_isSufferedDamageDetails: "",
    lossPrevious_providerName: "",
    police_refNo: "",
    police_stationReported: "",
    police_reportedDate: null,
    other_isOtherPartyInterested: "",
    other_isOtherPartyInterestedName: "",
    value_when: "",
    declaration_capacity: "",
    declaration_date: null,
};

const PropertyDamegeForm = ({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    datas=[]
}) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [propertyDamageFormState, setpropertyDamageFormState] = useState({ ...urInitFields });

    const columns = [
        {
            label: 'NUMBER',
            key: "NUMBER"
        },
        {
            label: 'DESCRIPTION OF PROPERTY',
            key: "DESCRIPTION OF PROPERTY"
        },
        {
            label: 'DATE ACQUIRED',
            key: "DATE ACQUIRED"
        },
        {
            label: "FROM WHOM WAS IT PURCHASED",
            key: "FROM WHOM WAS IT PURCHASED"
        },
        {
            label: 'REPLACEMENT COST',
            key: "REPLACEMENT COST"
        },
        {
            label: 'LESS DEPRECIATION',
            key: "LESS DEPRECIATION"
        },
        {
            label: 'AMOUNT CLAIMED OR ACQUIRED',
            key: "AMOUNT CLAIMED OR ACQUIRED"
        }
    ];

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setpropertyDamageFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setpropertyDamageFormState({...data, id});
        }
    }, [data]);

    const handleChange = (value, name) => {
      // if (name === "allBenefits") {
      //     setURFormState((prevState) => {
      //         const newState = { ...prevState };
      //         if (value.target.checked) {
      //             newState.allBenefits.push(value.target.value);
      //         } else {
      //             let index = newState.allBenefits.indexOf(value.target.value);
      //             if (index !== -1) {
      //                 newState.allBenefits.splice(index, 1);
      //             }
      //         }
      //         return newState;
      //     });
      // }else if(name === 'allowances'){
      //     setURFormState((prevState) => {
      //         const newState = { ...prevState };
      //         if (value.target.checked) {
      //             newState.allowances.push(value.target.value);
      //         } else {
      //             let index = newState.allowances.indexOf(value.target.value);
      //             if (index !== -1) {
      //                 newState.allowances.splice(index, 1);
      //             }
      //         }
      //         return newState;
      //     });
      // } else if(name === 'recClub' || name === 'PMAregistraion' || name === 'gym' || name === 'pensionFormsRegistraion') {
      //     setURFormState((prevState) => {
      //         const newState = { ...prevState };
      //         if (value.target.checked) {
      //             debugger;
      //             newState[name] = value.target.value;
      //         } else {
      //             newState[name] = '';
      //         }
      //         return newState;
      //     });
      // } else {
      // }
        setpropertyDamageFormState({ ...propertyDamageFormState, [name]: value });
    }


  return (
    <Dialog
    fullWidth={true}
    maxWidth={'lg'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose();
    }}>
<DialogTitle id="sensor-template-title">
<PropertyHeader />
</DialogTitle>
<DialogContent>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
    <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>PROPERTY LOSS/DAMAGE FORM</h4>
</Grid> 
<Grid className={classes.blackBorder} item xs={6}>
<Input
    type={'text'}
    placeholder={"Policy Number (*)"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}value={propertyDamageFormState.policyNo}
    onChange={(evt) => handleChange(evt.target.value, "policyNo")}
/>
</Grid>
<Grid className={classes.blackBorder} item xs={6}>
<Input
    type={'text'}
    placeholder={"VAT Registration Number (*)"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}value={propertyDamageFormState.vatRegNo}
    onChange={(evt) => handleChange(evt.target.value, "vatRegNo")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
    <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>INSURED</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={6}>
<Input
type={'text'}
placeholder={"Name (*)"}
style={{ width: '100%' }}
className={classes.input}
color={'secondary'}value={propertyDamageFormState.insured_name}
onChange={(evt) => handleChange(evt.target.value, "insured_name")}
/>
</Grid>
<Grid className={classes.blackBorder} item xs={6}>
<Input
type={'text'}
placeholder={"Occupation (*)"}
style={{ width: '100%' }}
className={classes.input}
color={'secondary'}
value={propertyDamageFormState.insured_occupation}
onChange={(evt) => handleChange(evt.target.value, "insured_occupation")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={6}>
<Input
type={'text'}
placeholder={"Address"}
style={{ width: '100%' }}
className={classes.input}
color={'secondary'}
value={propertyDamageFormState.insured_address}
onChange={(evt) => handleChange(evt.target.value, "insured_address")}
/>
</Grid>
<Grid className={classes.blackBorder} item xs={6}>
<Input
type={'text'}
placeholder={"(Day) Tel. No."}
style={{ width: '100%' }}
className={classes.input}
color={'secondary'}
value={propertyDamageFormState.insured_telNo}
onChange={(evt) => handleChange(evt.target.value, "insured_telNo")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >              
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
    <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>LOSS/DAMAGE OCCURANCE</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={6}>
<DateTimeSelector
    inputStyle={{ minWidth: 260 }}
    label={'Date & Time of Breakage'}
    dateFormat={"yyyy-MM-dd"}
    value={propertyDamageFormState.lossOccurance_date}
    handleChange={evt => handleChange(evt.target.value, "lossOccurance_date")}
/>
</Grid>
<Grid className={classes.blackBorder} item xs={6}>
<Input
    type={'text'}
    placeholder={"When Was Loss/Damage Discovered?"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.lossOccurance_when}
    onChange={(evt) => handleChange(evt.target.value, "lossOccurance_when")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>LOSS/DAMAGE PLACE</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={6}>
<Input
    type={'text'}
    placeholder={"Place Where Loss/Damage Occured"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.lossPlace_where}
    onChange={(evt) => handleChange(evt.target.value, "lossPlace_where")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={6}>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>Was The Premises Occupied?</h5>
</Grid>
<RadioGroup
    row
    aria-label="change_classification"
    name="change_classification"
    value={propertyDamageFormState.lossPlace_isPremisesOccupied}
    onChange={(evt) => handleChange(evt.target.value, "lossPlace_isPremisesOccupied")}
>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="No"
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    labelPlacement="start"
    label="No"
/>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="Yes"
    labelPlacement="start"
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    label="Yes"
/>
</RadioGroup>
</Grid>
                   
<Grid className={classes.blackBorder} item xs={6}>
<Input
    type={'text'}
    placeholder={"If Yes, By Whom?"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.lossPlace_whom}
    onChange={(evt) => handleChange(evt.target.value, "lossPlace_whom")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={6}>
<DateTimeSelector
    inputStyle={{ minWidth: 260 }}
    label={'If Not Occupied, When Last Occupied?'}
    dateFormat={"yyyy-MM-dd"}
    value={propertyDamageFormState.lossPlace_whenLast}
    onChange={(evt) => handleChange(evt.target.value, "lossPlace_whom")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={12}>
<Input
type={'text'}
placeholder={"Purpose Of Occupation"}
style={{ width: '100%' }}
className={classes.input}
color={'secondary'}
value={propertyDamageFormState.lossPlace_purposeOccupation}
onChange={(evt) => handleChange(evt.target.value, "lossPlace_purposeOccupation")}
/>
</Grid>
</Grid> 

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>CAUSE OF LOSS/DAMAGE</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={6}>
<TextField
    type="textarea"
    placeholder="Describe Fully How The Loss Or Damage Occured Stating How (If Applicable) Entry Was Gained To Premises"
    variant="standard"
    fullWidth={true}
    multiline
    rows={2}
    rowsMax={2}
    value={propertyDamageFormState.lossCause_fullDescribe}
    onChange={(evt) => handleChange(evt.target.value, "lossCause_fullDescribe")}
/>
</Grid>
<Grid className={classes.blackBorder} item xs={6}>
<TextField
type="textarea"
placeholder="If Loss/Damage Was Caused By Another Party Give Name And Address"
variant="standard"
fullWidth={true}
multiline
rows={2}
rowsMax={2}
value={propertyDamageFormState.lossCause_otherPartyNameAddress}
onChange={(evt) => handleChange(evt.target.value, "lossCause_otherPartyNameAddress")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
    <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>PREVIOUS LOSS/DAMAGE</h4>
</Grid> 
                
<Grid className={classes.blackBorder} item xs={12}>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>Have You Previously Suffered A Loss/Damage?</h5>
</Grid>
<RadioGroup
    row
    aria-label="change_classification"
    name="change_classification"value={propertyDamageFormState.lossPrevious_isSufferedDamage}
    onChange={(evt) => handleChange(evt.target.value, "lossPrevious_isSufferedDamage")}
>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="No"
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    labelPlacement="start"
    label="No"
/>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="Yes"
    labelPlacement="start"
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    label="Yes"
/>
</RadioGroup>
</Grid>

<Grid className={classes.blackBorder} item xs={12}>
<TextField
    type="textarea"
    placeholder="If Yes, Give details"
    variant="standard"
    fullWidth={true}
    multiline
    rows={2}
    rowsMax={2}value={propertyDamageFormState.lossPrevious_isSufferedDamageDetails}
    onChange={(evt) => handleChange(evt.target.value, "lossPrevious_isSufferedDamageDetails")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={12}>
<Input
    type={'text'}
    placeholder={"If Insured, Provide Name Of Insurer"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.lossPrevious_providerName}
    onChange={(evt) => handleChange(evt.target.value, "lossPrevious_providerName")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>POLICE</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={4}>
<Input
    type={'text'}
    placeholder={"Police Ref. No."}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.police_refNo}
    onChange={(evt) => handleChange(evt.target.value, "police_refNo")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<Input
    type={'text'}
    placeholder={"Station Reported"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.police_stationReported}
    onChange={(evt) => handleChange(evt.target.value, "police_stationReported")}
/>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
<DateTimeSelector
    inputStyle={{ minWidth: 260 }}
    label={'Date Reported'}
    dateFormat={"yyyy-MM-dd"}
    value={propertyDamageFormState.police_reportedDate}
    handleChange={evt => handleChange(evt.target.value, "police_reportedDate")}
/>
</Grid>
</Grid>

<Grid container spacing={2} style={{ marginBottom: 20 }} >

<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>OTHER INTEREST</h4>
</Grid> 

<Grid className={classes.blackBorder} item xs={12}>
<Grid item xs={12}>
<h5 className={classes.blackBorderTitle}>Does Any Other Party Have Interest In The Insured Property, E.g. Credit Agreement?</h5>
</Grid>

<RadioGroup
    row
    aria-label="change_classification"
    name="change_classification"value={propertyDamageFormState.other_isOtherPartyInterested}
    onChange={(evt) => handleChange(evt.target.value, "other_isOtherPartyInterested")}
>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="No"  
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    labelPlacement="start"
    label="No"
/>
<FormControlLabel
    style={{ ...marginGenerator('mb-8') }}
    value="Yes"
    labelPlacement="start"
    control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
    label="Yes"
/>
</RadioGroup>
</Grid>
<Grid className={classes.blackBorder} item xs={12}>
<Input
    type={'text'}
    placeholder={"If So, Give Name Of Insurer"}
    style={{ width: '100%' }}
    className={classes.input}
    color={'secondary'}
    value={propertyDamageFormState.other_isOtherPartyInterestedName}
    onChange={(evt) => handleChange(evt.target.value, "other_isOtherPartyInterestedName")}
/>
</Grid>
</Grid> 

<Grid container spacing={2} style={{ marginBottom: 20 }} >

<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>VALUE</h4>
</Grid> 
        
        <Grid className={classes.blackBorder} item xs={12}>
        <Input
            type={'text'}
            placeholder={"When Last Valued?"}
            style={{ width: '100%' }}
            className={classes.input}
            color={'secondary'}
            value={propertyDamageFormState.value_when}
            onChange={(evt) => handleChange(evt.target.value, "value_when")}
        />
        </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>DECLARATION</h4>
</Grid> 
       
            <Grid className={classes.blackBorder} item xs={12} />
            <Grid className={classes.blackBorder} item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'When Last Valued?'}
                            dateFormat={"yyyy-MM-dd"}
                            value={propertyDamageFormState.declaration_date}
                        handleChange={evt => handleChange(evt.target.value, "declaration_date")}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Capacity"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={propertyDamageFormState.declaration_capacity}
                            onChange={(evt) => handleChange(evt.target.value, "declaration_capacity")}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder}  item xs={4} style={{ textAlign: 'center'}}>
                    <IconButton
                    variant={'outlined'}
                    onClick={() => handleOpenSignPanModal("supervisorSignature")}
                    >
                    Insured's Signature <img src={SignIcon} width="26" height={26} alt=""/>
                    </IconButton>
                    </Grid>
                    </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>

                    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>SCHEDULE OF PROPERTY DAMAGED, LOST OR STOLEN</h4>
</Grid> 
                    
        </Grid>
        <Grid container>
        <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datas.length > 0 ?
                                datas.map((rowitem, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <Typography variant="subtitle1">DEBS-MCM-NMS-0{++rowIndex}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() => {
                        if( !propertyDamageFormState.policyNo || 
                            !propertyDamageFormState.vatRegNo || 
                            !propertyDamageFormState.insured_name ||
                            !propertyDamageFormState.insured_occupation ){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateRequestSubmit(propertyDamageFormState);
                    }}
                    loading={modalState.btnLoading}
                />
            </Grid>
        </DialogActions>
    </Dialog>
  )
}

PropertyDamegeForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};


export default PropertyDamegeForm