import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator, paddingGenerator } from '../../theme/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const memberRoleList = [{ id: '1', value: 'MEMBER' }];

const memberTypeList = [{ id: '1', value: 'USER' }];

const initState = {
  memberName: '',
  memberEmail: '',
  memberRole: '',
  memberType: '',
  id: ''
};
function EditNGListItem({
  open,
  handleClose,
  handleSubmit,
  buttonLoading,
  clearModal,
  data,
  type,
  t
}) {
  const [formData, setFormData] = React.useState(initState);

  useEffect(() => {
    if (clearModal) {
      setFormData(initState);
    }
  }, [clearModal]);

  useEffect(() => {
    if (data) {
      setFormData({
        memberName: data.memberName,
        memberEmail: data.memberEmail,
        memberRole: data.memberRole,
        memberType: data.memberType,
        id: data._id
      });
    }
  }, [data]);

  const handleFormData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitData = () => {
    handleSubmit(formData, type);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {type === 'lagos'
            ? t('editLagos.label')
            : type === 'phc'
            ? t('editPhc.label')
            : t('editAbuja.label')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Member Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="memberName"
                value={formData.memberName}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Member Email"
                type="email"
                variant="outlined"
                fullWidth={true}
                name="memberEmail"
                value={formData.memberEmail}
                onChange={handleFormData}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Member Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="memberRole"
                  value={formData.memberRole}
                  onChange={handleFormData}
                  label="Select Member Role">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {memberRoleList &&
                    memberRoleList.map((data, index) => (
                      <MenuItem key={index} value={data.value}>
                        {data.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Member Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="memberType"
                  value={formData.memberType}
                  onChange={handleFormData}
                  label="Select Member Type">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {memberTypeList &&
                    memberTypeList.map((data, index) => (
                      <MenuItem key={index} value={data.value}>
                        {data.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(EditNGListItem);
