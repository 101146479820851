import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { dateFormatterLLL } from '../../../../../../utils';
import SignIcon from '../../../../../../assets/icons/sign-pen.png';

const UserPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    allowConfigure
}) => {
    const commonClasses = commonStyles();
    
    return (
        <>
            <TableRow key={data._id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography
                                variant={'subtitle1'}
                                style={{ cursor: 'pointer' }}
                            >
                                {data.userFullName ? data.userFullName : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.expiryDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.employeeType ? data.employeeType : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.userDepartment ? data.userDepartment.name : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.userSection ? data.userSection.name : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.userDesignation ? data.userDesignation : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.recommendationSign === '' ? 'Pending' : "Approved"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.authorisationSign ==='' ? 'Pending' : "Completed"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.accountType ? data.accountType : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {allowConfigure &&
                    <TableCell>
                        <IconButton onClick={() => handleMenuItemClick("sign", data)}>
                            <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                            <EditIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

UserPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(UserPanelItem);