import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import SensorPanelItem from './SensorPanelItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBox from '../../../../../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

function SensorPanel({
    t,
    loading,
    error,
    list,
    allowConfigure,
    handleMenuItemClick,
    menuItems,
    handlePageChange,
    sensorFilterState,
    handleSearch,
    maintenanceType,
    handleChangeMaintenance
}) {
    
    const [goToPage, setGoToPage] = useState(null);
    const columns = [
        {
            label: t('deviceName.label'),
            key: "deviceName"
        },
        {
            label: t('deviceNo.label'),
            key: "deviceNo"
        },
        {
            label: t('place.label'),
            key: "place"
        },
        {
            label: "Project",
            key: "projectName"
        },
        {
            label: t('dataType.label'),
            key: "dataType"
        },
        {
            label: t('status.label'),
            key: "status"
        },
        {
            label: t('latLng.label'),
            key: "latLng"
        },
        {
            label: t('serviceId.label'),
            key: "serviceId"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        }
    ];

    if (allowConfigure) {
        columns.push({
            label: t('maintenance.label'),
            key: "button"
        });
        columns.push({
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        });
    }
    
    const handleSearchChange = (val) => {
       handleSearch("sensor", val);
    }
    
    return (
        <div>
            {allowConfigure &&
                <Grid container justify={'space-between'}>
                    <Grid item>{<SearchBox handleSearch={handleSearchChange} />}</Grid>
                    <Grid item>
                        {/* <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            >
                            {t('createNow.label')}
                        </Button> */}
                    </Grid>
                </Grid>
            }
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.getAllSensorList && list.getAllSensorList.sensors.length > 0 ?
                                    list.getAllSensorList.sensors.map((rowitem, rowIndex) => (
                                        <SensorPanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                            allowConfigure={allowConfigure}
                                            handleChangeMaintenance={handleChangeMaintenance}
                                            maintenanceType={maintenanceType}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color="primary"/>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading &&
                <>
                    <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Grid item xs={2}>
                            <span>Total {(list && list.getAllSensorList) ? Math.ceil(list.getAllSensorList.count / 20) : 0} Page,  {(list && list.getAllSensorList) ? list.getAllSensorList.count : 0} Row</span>
                        </Grid>
                        <Grid item xs={2}>
                            <Pagination
                                color="secondary"
                                count={(list && list.getAllSensorList) ? Math.ceil(list.getAllSensorList.count / 20) : 0}
                                page={sensorFilterState.page}
                                onChange={(evt, value) => {
                                    handlePageChange("sensor", value);
                                }} />
                        </Grid>
                        <Grid item xs={1}>
                            <span>Go to page </span>
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={goToPage}
                                type="number"
                                style={{ minWidth: 100 }}
                                color={'secondary'}
                                onChange={evt => {
                                    const maxRecord = list && list.getAllSensorList ? parseInt(Math.ceil(list.getAllSensorList.count / 20)) : 0;
                                    if (parseInt(evt.target.value) > maxRecord) {
                                        return null;
                                    }
                                    setGoToPage(evt.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => handlePageChange('sensor', parseInt(goToPage))}
                                variant={'outlined'}
                                color="primary"
                                disabled={!goToPage}
                                style={{ marginLeft: 10 }}
                            >Go</Button>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        </div>
    );
}

SensorPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default SensorPanel;
