import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dateFormatterDDMMMYYYYHMS } from '../../../../utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DownloadPDFButton from '../../Analytics/BillingPanel/BillingUtilization/DownloadPDFButton';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  },
  container: {
    padding: '10px'
  }
}));

function ReportTable({ data, column, loading, monthandyear, orgName,mytableData,platform,circuitId,siteName, t }) {
  const [reportData, setreportData]= useState()
  const [tableData, settableData]= useState()

  useEffect(()=>{
    data && data.length ? setreportData(data) : setreportData([])
  },[data])

  useEffect(()=>{
    mytableData && mytableData.length ? settableData(mytableData.filter(item => item.id != '')) : settableData([])
  },[mytableData])


  // let reportData = data && data.length && data
  // let tableData = data && data.length > 0 && data.filter(item => item.id != '')
  const classes = useStyles();

  function groupBy(objectArray, property) {
    if (!objectArray || objectArray.length === 0) {
      return [];
    }
  
    const groupedData = new Map();
  
    objectArray.forEach(obj => {
      const key = obj[property];
      if (!groupedData.has(key)) {
        groupedData.set(key, []);
      }
      groupedData.get(key).push(obj);
    });
  
    return Array.from(groupedData.values());
  }
  
  const groupedData = groupBy(reportData, 'circuitId');
  

  const targetMeetFun = input => {
    let number = input.toString().split(':');
    if (number[0] > 0) {
      return 'No';
    } else {
      if (number[1] >= 4) {
        return "No"
      } else {
        return 'Yes';
      }
    }
  };
  return (
    <>
      {groupedData && groupedData.length ?
       <DownloadPDFButton 
       data={groupedData} 
       monthandyear={monthandyear} 
       orgName={orgName} 
       platform={platform}
       circuitId={circuitId}
       siteName={siteName}
       />
        : null}
      {loading ? (
        <div
          style={{
            width: '100%',
            padding: 20,
            textAlign: 'center',
            display: 'inline-block'
          }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableContainer style={{marginBottom:130}} >
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {column &&
                  column.map(column => (
                    <TableCell key={uuidv4()}>{column.name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && tableData.length > 0 ? (
                tableData.map(row => (
                  <StyledTableRow key={uuidv4()}>
                    <TableCell>
                      {dateFormatterDDMMMYYYYHMS(row.incidentTime)}
                    </TableCell>
                    <TableCell>
                      {dateFormatterDDMMMYYYYHMS(row.incidentEndTime)}
                    </TableCell>
                    <TableCell>{row.timeToRestore}</TableCell>
                    <TableCell>{targetMeetFun(row.timeToRestore)}</TableCell>
                    <TableCell>{row.ticketId}</TableCell>
                    <TableCell>{row.incidentType}</TableCell>
                    <TableCell>{row.vodacomProblem ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{row.closeDescription && row.closeDescription}</TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={column.length}>
                    <Typography variant={'subtitle2'}>
                      Record not found!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default withTranslation()(ReportTable);
