import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import commonStyles from '../../../../theme/commonStyles';
import clsx from 'clsx';
import {paddingGenerator} from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    priorityContainer: {
        height: '15px',
        borderRadius: '16px',
        alignItems: 'center'
    },
    highPriority: {
        background: theme.palette.error.main
    },
    mediumPriority: {
        background: theme.palette.warning.main
    },
    lowPriority: {
        background: theme.palette.success.main
    },
    textStyle: {
        color: theme.palette.common.white
    }
}));

const ControllerStatusPanel = ({t, status}) => {
    const classes = useStyles();
    const commonClasses = commonStyles();

    var statusClasses = '';
    if (status === "active" || status === "success" || status === 'provisioned') {
        statusClasses = classes.lowPriority;
    } else if (status === "pending" || status === 'deleting' || status === 'down') {

        statusClasses = classes.mediumPriority;
    } else if (status === false) {
        status = 'pending'
        statusClasses = classes.mediumPriority;
    } else if (status === true) {
        status = 'active'
        statusClasses = classes.lowPriority;
    } else {
        statusClasses = classes.highPriority;
    }

    return (
        <div
            className={clsx(
                classes.priorityContainer,
                statusClasses
            )}
            style={{
                ...paddingGenerator('pt-7'),
                ...paddingGenerator('pb-6'),
                ...paddingGenerator('pl-14'),
                ...paddingGenerator('pr-14')
            }}>
            <Typography
                className={clsx(classes.textStyle)}
                style={{fontSize: '13px', textAlign: 'center'}}
                variant={'body1'}
            >
                {status}
            </Typography>
        </div>
    );
};

ControllerStatusPanel.propTypes = {
    priority: PropTypes.string,
    t: PropTypes.func.isRequired
};

export default ControllerStatusPanel;
