import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DatePicker from 'react-datepicker';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateDeliveryTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    modalContentLoading,
    SiteAcceptanceFormUrl,
    handleOpenSiteAcceptanceForm
}) {
    const classes = useStyles();
    const [SiteAcceptanceForm, setSiteAcceptanceForm] = useState('');
    const [fieldEngineer, setFieldEngineer] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setSiteAcceptanceForm('');
            setFieldEngineer('');
            setNextTeamMemberEmail('');
        }
        if (SiteAcceptanceFormUrl) {
            setSiteAcceptanceForm(SiteAcceptanceFormUrl);
        }
    }, [modalState, open, modalContentLoading, SiteAcceptanceFormUrl]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('deliveryTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.case_number ? preValuesOfForm.project_info.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('FillAndAttachSiteAcceptanceForm.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Button variant={'outlined'}
                                    color={'primary'}
                                    style={{ width: '100%' }}
                                    onClick={handleOpenSiteAcceptanceForm}>{t('FillAndAttachSiteAcceptanceForm.label')}</Button>
                            </Grid>
                        </Grid>
                        {SiteAcceptanceFormUrl != '' &&
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ color: 'green' }}>
                                    <CheckCircleIcon style={{ color: 'green', fontSize: 'inherit', marginRight: '2px' }} />
                                    Form attached
                                </Typography>
                                <Button variant={'outlined'}
                                    color={'primary'}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        window.open(SiteAcceptanceFormUrl);
                                    }}>{t('clickHereToViewFiles.label')}</Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={fieldEngineer}
                                    label={`${t('financeTeam.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setFieldEngineer(evt.target.value)
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={allUserList && allUserList.length ? allUserList : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async() => {
                            if (!SiteAcceptanceForm || !fieldEngineer || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                site_acceptance: SiteAcceptanceForm,
                                finance_team: fieldEngineer,
                                next_team_member_email: nextTeamMemberEmail,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateDeliveryTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateDeliveryTeamModal);