import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SERVICE_TICKET_DETAILS_BY_ID } from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { Paths, ServiceTicketPaths } from '../../routes/routePaths';
import CustomTabs from '../../components/CustomTabs';
import { dateFormatterLLL, getParam } from '../../utils';
import TicketDetailsPanel from './components/TicketDetailsPanel';
import { useLocation, withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import Chat from '@material-ui/icons/Chat';
import { marginGenerator } from '../../theme/utils';
import IconButton from '@material-ui/core/IconButton';
import commonStyles from '../../theme/commonStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Image from '../../assets/icons/image.png';
import ActivityList from '../../components/ActivityList';
import { UPDATE_SERVICE_TICKET, CLOSE_SERVICE_TICKET } from './mutations';
import { UPLOAD_FILE } from '../commonMutations';
import Message from '../../components/Message';
import { STATUS_TYPES } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  commentBar: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 0),
    maxWidth: '1100px'
  },
  commentContainer: {
    bottom: 0,
    left: 0,
    padding: '24px',
    borderTop: '1px solid #eaeaea',
    borderBottom: '1px solid #eaeaea',
    ...(window.innerWidth < 1100 && { padding: 24 })
  }
}));

const TicketDetails = ({ t, history }) => {
  const id = getParam();
  const location = useLocation();

  const { loading, error, data, refetch: reloadServiceTicket } = useQuery(
    GET_SERVICE_TICKET_DETAILS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: { id }
    }
  );

  let [breadcrumbList, setBreadcrumbList] = useState([
    { label: 'home.label', link: Paths.Dashboard },
    { label: 'serviceTickets.label', link: Paths.ServiceTickets },
    { label: 'ticketsDetails.label', link: ServiceTicketPaths.Details }
  ]);

  useEffect(() => {
    if (location.search === `?history=dashboard`) {
      breadcrumbList = breadcrumbList.filter((item, index) => index !== 1);
      setBreadcrumbList(breadcrumbList);
    }
  }, [location]);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [comment, setComment] = useState('');
  const classes = useStyles();
  const commonClasses = commonStyles();
  const ticketData = !loading && !error && data.getServiceTicketById;
  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const [updateServiceTicket] = useMutation(UPDATE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      setComment('');
      setUploadedFile(null);
      reloadServiceTicket();
      Message.success('Message sent successfully.');
    }
  });

  const [closeServiceTicket] = useMutation(CLOSE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      Message.success('Ticket closed successfully.');
      reloadServiceTicket();
    }
  });

  const handleCloseTicketAction = () => {
    if (data.getServiceTicketById) {
      return closeServiceTicket({
        variables: { id: data.getServiceTicketById.id }
      });
    }
  };

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      return updateServiceTicket({
        variables: {
          serviceTicketId: ticketData.id,
          title: ticketData.title,
          mailThreadId: ticketData.ticketId,
          attachments: [data.uploadDocument.url],
          ...(comment ? { logMessage: comment } : {})
        }
      });
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  return (
    <>
      {!loading && !error && (
        <Layout breadcrumbList={breadcrumbList}>
          <Header
            title={ticketData.title}
            subText={`${t('id.label').toUpperCase()} ${ticketData.ticketId
              } • ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
                ticketData.createdAt
              )}`}
            primaryButtonText={
              ticketData.status === STATUS_TYPES.OPEN
                ? t('closeTicket.label')
                : null
            }
            handlePrimaryButtonClick={
              ticketData.status === STATUS_TYPES.OPEN
                ? handleCloseTicketAction
                : null
            }
            secondaryButtonText={t('goBack.label')}
            handleSecondaryButtonClick={() => history.goBack()}
          />
          <CustomTabs
            tabs={[
              {
                label: t('details.label'),
                children: <TicketDetailsPanel ticketData={ticketData} t={t} />
              },
              {
                label: t('activity.label'),
                children: <ActivityList list={ticketData.activityLogs} />
              }
            ]}
          />
          <Grid
            container
            className={classes.commentContainer}
            justify={'center'}>
            <Grid
              container
              item
              className={classes.commentBar}
              justify={'space-between'}>
              <Grid container item xs={7}>
                <Grid item style={marginGenerator('mr-16')}>
                  <Chat />
                </Grid>
                <Grid item xs={11}>
                  <InputBase
                    fullWidth
                    value={comment}
                    id="comment-textarea"
                    placeholder="Add a ticket note, comment or an update"
                    multiline
                    onChange={evt => setComment(evt.target.value)}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid item>
                {uploadedFile && (
                  <Chip
                    avatar={<img src={Image} alt={'file'} />}
                    label={
                      uploadedFile.name.length > 12
                        ? `${uploadedFile.name.slice(0, 12)}...`
                        : uploadedFile.name
                    }
                    onDelete={() => {
                      setUploadedFile('');
                    }}
                    deleteIcon={<Close />}
                    variant="outlined"
                  />
                )}
                <IconButton
                  size={'medium'}
                  disableRipple={true}
                  disableFocusRipple={true}
                  color={'secondary'}
                  onClick={handleFileUploadClick}
                  style={marginGenerator('ml-16')}>
                  <AttachFile className={commonClasses.iconRegular} />
                </IconButton>
                <input
                  type="file"
                  id="attachment"
                  ref={inputFile}
                  onChange={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    setUploadedFile(event.target.files[0]);
                  }}
                  style={{ display: 'none' }}
                />
                <Button
                  variant={'contained'}
                  disabled={(!comment && !uploadedFile) || uploading}
                  color={'primary'}
                  // TODO: Call update ticket mutation
                  onClick={() =>
                    uploadedFile
                      ? prepareUpload()
                      : updateServiceTicket({
                        variables: {
                          serviceTicketId: ticketData.id,
                          title: ticketData.title,
                          mailThreadId: ticketData.ticketId,
                          ...(comment ? { logMessage: comment } : {})
                        }
                      })
                  }
                  style={marginGenerator('ml-24')}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      )}
    </>
  );
};
export default withTranslation()(withRouter(TicketDetails));
