import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useTheme } from '@material-ui/core/styles';
import { get } from "lodash-es";
import CustomTabs from '../../../../components/CustomTabs';
import OcrDashBoardPage from './OcrDashBoardPage';
import OcrListingPage from './OcrListingPage';
import OcrBillingPage from './OcrBillingPage';
import OcrSupportPage from './OcrSupportPage';
import OcrProcessesPage from './OcrProcessesPage';
import OcrParseRules from './OcrParseRules';
import OcrConfiguration from './OcrConfiguration';
import OcrTreeStructure from './OcrTreeStructure';
import CreateProject from './OcrConfiguration/OcrConfigurationProject/CreateProject';
import CreateFolder from './OcrConfiguration/OcrConfigurationFolder/CreateFolder';
import CreateSubFolder from './OcrConfiguration/OcrConfigurationFolder/CreateSubFolder'
import OcrDocUploadForm from './OcrDocUploadForm';
import OcrMultipleDocUpload from './OcrMultipleDocUpload';
import OcrDocViewer from './OcrDocViewer';
import { OcrContext } from '../../../../contexts/OcrProvider';
import OcrConfigurationGAndUAssignForProejctAndGroup from './OcrConfiguration/OcrConfigurationGroups&Users/OcrConfigurationGAndUAssignForProejctAndGroup';
import OcrConfigurationGAndUCreateGroupOrUser from './OcrConfiguration/OcrConfigurationGroups&Users/OcrConfigurationGAndUCreateGroupOrUser';
import { getMyLocation, isSuperUser } from '../../../../utils';
import { GET_USER_INFO } from '../../../TicketingSystem/queries';
import { MdOutlineSpaceDashboard, MdOutlineContactSupport, MdOutlineAccountTree } from "react-icons/md";
import { FaDollarSign, FaUserCog } from "react-icons/fa";
import { VscServerProcess } from "react-icons/vsc";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";

import {
    GET_OCR_LIST,
    GET_OCR_RULE_LIST,
    GET_OCR_DASHBOARD_DATA,
    GET_OCR_IMAGE_FOR_RULE,
    GET_OCR_PLACE_LIST,
    GET_OCR_PROJECT_LIST,
    GET_OCR_FOLDER_LIST,
    GET_OCR_DOC_TREE_DATA,
    GET_OCR_PARSE_IMG,
    GET_OCR_DASHBOARD_MAP_DEATILS,
    GET_SUBSCRIPTION_USERS,
    GET_OCR_TREEE_VIEW_SEARCH,
    GET_OCR_GROUP_LIST,
    GET_OCR_GROUP_DATA_BY_ID,
    GET_USERS_HAVE_FOLDER_ACCESS_OCR_LIST,
    GET_OCR_USERS_LIST,
    GET_OCR_PROCESS_LIST
} from '../../Ocr/queries';
import {
    CREATE_OCR_PARSE_DATA,
    CREATE_OCR_RULE_DATA,
    CREATE_MULTIPLE_OCR_PARSE_DATA,
    DELETE_OCR_RULE_DATA,
    EDIT_OCR_RULE_DATA,
    CREATE_OCR_PLACE,
    CREATE_OCR_PROJECT,
    CREATE_OCR_FOLDER,
    DELETE_OCR_PLACE,
    DELETE_OCR_PROJECT,
    DELETE_OCR_FOLDER,
    DELETE_OCR_FILE,
    CREATE_OCR_GROUP,
    EDIT_OCR_GROUP,
    DELETE_OCR_GROUP,
    ASSIGN_FOLDER_TO_GROUP,
    ASSIGN_FOLDER_TO_USER,
    ASSIGN_PROJECT_TO_USER,
    CREATE_OCR_ACTIVITY_LOG,
    ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS
} from '../../Ocr/mutations';
import Message from '../../../../components/Message';
import { colors } from '../../../../theme/colors';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../../../../components/TabPanel';


const OcrPanel = ({ t, data, getTabList, sideBarActiveIndex }) => {
    const theme = useTheme();
    const { organizationId, ownerId, id } = data.getSubscriptionById;
    const PAGE_LIMIT = 10000;
    const [activeIndex, setActiveIndex] = useState(0);
    const [ocrSubmitLoading, setOcrSubmitLoading] = useState(false);
    const [ocrFetchLoading, setOcrFetchLoading] = useState(false);
    const [ocrData, setOcrData] = useState('');
    const [isOcrFormEnable, setIsOcrFormEnable] = useState(false);
    const [isCreateRuleEnable, setIsCreateRuleEnable] = useState(false);
    const [isEditRuleEnable, setIsEditRuleEnable] = useState(false);
    const [ocrRuleFetchingloading, setOcrRuleFetchingloading] = useState(false);
    const [ocrRuleData, setOcrRuleData] = useState('');
    const [ocrRuleSubmitLoading, setOcrRuleSubmitLoading] = useState(false);
    const [isMultipleOcrDocUploadenable, setIsMultipleOcrDocUploadenable] = useState(false);
    const [ocrMultipleDocSubmitting, setOcrMultipleDocSubmitting] = useState(false);
    const [dashboardPanelData, setDashboardPanelData] = useState('');
    const [ocrImageDataForCreateRule, setOcrImageDataForCreateRule] = useState([]);
    const [ocrRuleDeletetingStarted, setOcrRuleDeletetingStarted] = useState(false);
    const [selecteIdForDelete, setSelecteIdForDelete] = useState('');
    const [paginationState, setPaginationState] = useState({ ocr_document: 1, ocr_rule: 1, ocr_place: 1, ocr_project: 1, ocr_folder: 1 });
    const [ocrDocumentSearchvalue, setOcrDocumentSearchvalue] = useState('');
    const [ocrRuleSearchvalue, setOcrRuleSearchvalue] = useState('');
    const [ocrFolderSearchvalue, setOcrFolderSearchvalue] = useState('');
    const [isCreateLocationEnable, setIsCreateLocationEnable] = useState(false);
    const [isCreateProjectEnable, setIsCreateProjectEnable] = useState(false);
    const [isCreateFolderEnable, setIsCreateFolderEnable] = useState(false);
    const [isCreateSubFolderEnable, setIsCreateSubFolderEnable] = useState(false);
    const [ocrSubFolderSubmit, setOcrSubFolderSubmit] = useState(false);
    const [selectedEditIdData, setSelectedEditIdData] = useState('');
    const [ocrPlaceData, setOcrPlaceData] = useState('');
    const [ocrProjectData, setOcrProjectData] = useState('');
    const [ocrFolderData, setOcrFolderData] = useState('');
    const [ocrPlaceListLoading, setOcrPlaceListLoading] = useState(false);
    const [ocrProjectListLoading, setOcrProjectLoaListding] = useState(false);
    const [ocrFolderListLoading, setocrFolderListLoading] = useState(false);
    const [ocrProjectSubmit, setOcrProjectSubmit] = useState(false);
    const [ocrLocationSubmit, setOcrLocationSubmit] = useState(false);
    const [ocrFolderSubmit, setOcrFolderSubmit] = useState(false);
    const [ocrDashboardMapLoading, setOcrDashboardMapLoading] = useState(false);
    const [ocrDashboardMapData, setOcrDashboardMapData] = useState('');
    const [isAssignProjectEnable, setIsAssignProjectEnable] = useState(false);
    const [isAddToGroupEnable, setIsAddToGroupEnable] = useState(false);
    const [isParseDocViwerEnable, setIsParseDocViwerEnable] = useState(false);
    const [isCreateGroupOrUserEnable, setIsCreateGroupOrUserEnable] = useState(false);
    const [doctextORImg, setDoctextORImg] = useState("");
    const [ocrParseImgLoading, setOcrParseImgLoading] = useState(false);
    const [ocrParseImgs, setOcrParseImgs] = useState('');
    const [img_url, setimg_url] = useState("");
    const [treeStruckcherDataForModal, setTreeStruckcherDataForModal] = useState(null);
    const [subscriptionUserData, setSubscriptionUserData] = useState([]);
    const [ocrTreeViewData, setocrTreeViewData] = useState([]);
    const [ocrGroupData, setOcrGroupData] = useState([]);
    const [assignType, setAssignType] = useState('');
    const [isaddUserToGroupElementEnable, setIsaddUserToGroupElementEnable] = useState(false);
    const [selctedIdForGroupSingleData, setSelctedIdForGroupSingleData] = useState('');
    const [groupSingleData, setGroupSingleData] = useState({});
    const [uaserPayloadForGroupAndProject, setUaserPayloadForGroupAndProject] = useState('');
    const [apiTimer, setApiTimer] = useState(0);
    const [usersDataByFolderId, setUsersDataByFolderId] = useState([]);
    const [ocrDocumentsListing, setOcrDocumentsListing] = useState('');
    const [ocrUsersList, setOcrUsersList] = useState([]);
    const [ocrProcessList, setOcrProcessList] = useState([]);
    const [documentSearchValue, setDocumentSearchValue] = useState('');
    const [globalDocSearchList, setGlobalDocSearchList] = useState([]);
    const [globalDocSearch, setGlobalDocSearch] = useState(null);
    const [dataLoading, setDataLoading] = useState(true)

    const hostName = window.location.hostname;
    // const hostName ="trans.edgedock.inq.co.bw"
    const {
        handleRefetchedOrFilter
    } = useContext(OcrContext);


    const {
        data: userdata,
        loading: userDataLoading,
        error: userDataError
    } = useQuery(GET_USER_INFO);

    const uploadOcrDoc = () => setIsOcrFormEnable(true);
    const createRuleHandle = () => setIsCreateRuleEnable(true);
    const editRuleHandle = (allocrRuleData, id) => {
        setIsEditRuleEnable(true);
        const filterValue = allocrRuleData.filter(el => el.id === id);
        setSelectedEditIdData(filterValue[0]);

    };
    const uploadMultipleOcrDoc = () => {
        setIsMultipleOcrDocUploadenable(true);
    }
    const createLocationEnable = () => setIsCreateLocationEnable(true);
    const createProjectEnable = () => setIsCreateProjectEnable(true);
    const createFolderEnable = () => setIsCreateFolderEnable(true);
    const createSubFolderEnable = () => setIsCreateSubFolderEnable(true);
    const assignProjectEnable = (type, data) => {
        console.log('type', type);
        console.log('data', data);
        setUaserPayloadForGroupAndProject(data);
        setAssignType(type);
        setIsAssignProjectEnable(true);
    }
    const addGroupEnable = () => setIsAddToGroupEnable(true);
    const createGroupOrUserEnable = (type, data) => {
        if (type === t('assignFolderToUserOcr.label')) {
            getUsesrsHaveFolderAccessById({
                variables: {
                    organization: organizationId,
                    folder_id: data.folderId
                }
            })
        }
        setUaserPayloadForGroupAndProject(data);
        setAssignType(type);
        setIsCreateGroupOrUserEnable(true);
    }
    const handleOcrFormClose = () => setIsOcrFormEnable(false);
    const handleRuleFormClose = () => setIsCreateRuleEnable(false);
    const handleEditRuleFormClose = () => {
        setSelectedEditIdData('');
        setIsEditRuleEnable(false);
    }
    const handleMultipleOcrDocUploadFormClose = () => setIsMultipleOcrDocUploadenable(false);
    const handleCreateLocationEnableFormClose = () => setIsCreateLocationEnable(false);
    const handleCreateProjectEnableFormClose = () => setIsCreateProjectEnable(false);
    const handlecreateFolderEnableFormClose = () => setIsCreateFolderEnable(false);
    const handlecreateSubFolderEnableFormClose = () => setIsCreateSubFolderEnable(false);

    const handleCloseOfProject = () => {
        setUaserPayloadForGroupAndProject('');
        setIsAssignProjectEnable(false);
        setAssignType('');
    }
    const handleCloseofGroup = () => setIsAddToGroupEnable(false);
    const handleCloseofGroupOrUserEnable = () => {
        setIsaddUserToGroupElementEnable(false);
        setIsCreateGroupOrUserEnable(false);
        setSelctedIdForGroupSingleData('');
        setAssignType('');
    }

    const { data: dashboardData, loading: dashboardLoading, error: dashboardError } = useQuery(GET_OCR_DASHBOARD_DATA, { variables: { organization: organizationId } });





    // fetching document OCR tree structure data on init load

    const { data: ocrDocTreeData, loading: ocrDocTreeLoading, error: ocrDocTreeError, refetch: reloadTreeData } = useQuery(GET_OCR_DOC_TREE_DATA,
        // const { data: ocrDocTreeData, error: ocrDocTreeError, refetch: reloadTreeData } = useQuery(GET_OCR_DOC_TREE_DATA, 
        {
            variables: {
                organization: organizationId,
                userData: userdata,
                is_superUser: isSuperUser(localStorage.getItem('role')) ? true : false
            },
            // skip: loading, // Skip query execution if loading is true
            onCompleted: () => {
                setDataLoading(false); // Set loading back to false when query is completed
                //   alert("loading completed")
            },
        });

    const refreshTree = async () => {
        try {
            setDataLoading(true); // Set dataLoading to true before starting refetch
            await reloadTreeData();
        } catch (error) {
            console.error('Error while refetching:', error);
        } finally {
            setDataLoading(false); // Set dataLoading back to false after refetch
        }
    }

    const { data: ocrDocTreeViewSearchData, loading: ocrDocTreeViewSearchLoading, error: ocrDocTreeViewSearchError, refetch: reloadTreeViewSearchData } = useQuery(GET_OCR_TREEE_VIEW_SEARCH,
        {
            variables: {
                organization: organizationId,
                search: null
            }
        });
    const { data: ocrSelectPlaces } = useQuery(GET_OCR_PLACE_LIST, {
        variables: {
            organization: organizationId,
            limit: 1000,
            page: 1
        }
    });
    const { data: ocrSelectProjects } = useQuery(GET_OCR_PROJECT_LIST, {
        variables: {
            organization: organizationId,
            limit: 1000,
            page: 1
        }
    });

    const { data: ocrUsersData, loading: ocrUsersListLoading } = useQuery(GET_OCR_USERS_LIST, {
        variables: {
            organization: organizationId,
            groupId: ''
        }
    });

    useEffect(() => {
        setDashboardPanelData(get(dashboardData, 'getOcrDashBoarddata.data', ''));
        setocrTreeViewData(get(ocrDocTreeViewSearchData, 'getOcrTreeViewSearch.data', []));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!dashboardLoading, !ocrDocTreeViewSearchLoading]);

    const [getOcrListBySearch, { loading }] = useLazyQuery(GET_OCR_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrListdata) {
                setOcrData(data.getOcrListdata);
            }
            setOcrFetchLoading(false);
        },
        onError: error => {
            Message.error(error);
            setOcrFetchLoading(false);
        }
    });

    const [getOcrListForDocuments, { loading: getOcrListForDocumentsLoading }] = useLazyQuery(GET_OCR_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrListdata) {
                setOcrDocumentsListing(data.getOcrListdata);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getOcrRuleListBySearch, { loading: loadingOcrRule }] = useLazyQuery(GET_OCR_RULE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrRuleListdata) {
                setOcrRuleData(data.getOcrRuleListdata);
            }
            setOcrRuleFetchingloading(false);
        },
        onError: error => {
            Message.error(error);
            setOcrRuleFetchingloading(false);
        }
    });

    const [getOcrPlaceListBySearch, { loading: loadingOcrPlace }] = useLazyQuery(GET_OCR_PLACE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrPlaceList) {
                setOcrPlaceData(data.getOcrPlaceList);
            }
            setOcrPlaceListLoading(false);
        },
        onError: error => {
            Message.error(error);
            setOcrPlaceListLoading(false);
        }
    });

    const [getOcrProjectListBySearch, { loading: loadingOcrProject }] = useLazyQuery(GET_OCR_PROJECT_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrProjectList) {
                setOcrProjectData(data.getOcrProjectList);
            }
            setOcrProjectLoaListding(false);
        },
        onError: error => {
            Message.error(error);
            setOcrProjectLoaListding(false);
        }
    });

    const [getOcrFolderListBySearch, { loading: loadingOcrFolder }] = useLazyQuery(GET_OCR_FOLDER_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getOcrFolderList) {
                setOcrFolderData(data.getOcrFolderList);
            }
            setocrFolderListLoading(false);
        },
        onError: error => {
            Message.error(error);
            setocrFolderListLoading(false);
        }
    });

    const getMarkerColorByEvents = (placeEvents, totalEvents) => {
        let perc = (parseFloat(placeEvents) / parseFloat(totalEvents)) * 100;
        let color = colors.common.green;
        if (!isNaN(Math.round(perc))) {
            if (Math.round(perc) > 10 && Math.round(perc) < 30) {
                color = colors.common.orange;
            } else if (Math.round(perc) > 40) {
                color = colors.common.red;
            }
        }
        return color;
    }

    const [getOcrDashBoardMapDetails, { loading: loadingOcrDashboardMap }] = useLazyQuery(GET_OCR_DASHBOARD_MAP_DEATILS, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            let data = [];
            let totalProject = 0;
            let totalFolder = 0;
            let totalDocs = 0;
            if (res && res.getOcrDashboardMapDetaills) {
                const mapData = get(res, 'getOcrDashboardMapDetaills.data.map_data', []);
                if (mapData && mapData.length > 0) {
                    mapData.map(itm => {
                        if (itm.info) {
                            totalProject = totalProject + parseInt(itm.info.project);
                            totalFolder = totalFolder + parseInt(itm.info.folder);
                            totalDocs = totalDocs + parseInt(itm.info.docs);
                        }
                    });
                    for (let i = 0; i < mapData.length; i++) {
                        let item = mapData[i];
                        let eventsCnt = item.info.project ? item.info.project : 0;
                        let markerColor = getMarkerColorByEvents(eventsCnt, totalProject);
                        const eventsPercentage = parseFloat(eventsCnt) / parseFloat(totalProject) * 100;
                        data.push({ position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) }, title: item.name, infoData: item, markerColor, eventsPercentage, placeId: item.id });
                    }
                }
            }
            setOcrDashboardMapData({ dasboardAllData: res.getOcrDashboardMapDetaills?.data, modifyMapdata: data });
            setOcrDashboardMapLoading(false);
        },
        onError: error => {
            Message.error(error);
            setOcrDashboardMapLoading(false);
        }
    });

    const [getOcrParseImg] = useLazyQuery(GET_OCR_PARSE_IMG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const alldata = get(data, 'getOcrParseImageData.data', '')
            setOcrParseImgs(alldata);
            setOcrParseImgLoading(false);
        },
        onError: error => {
            Message.error(error);
            setOcrParseImgLoading(false);
        }
    });

    const [getUserListOfSubscriptiontByOwnerId, { loading: userListLoading }] = useLazyQuery(GET_SUBSCRIPTION_USERS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const userData = get(data, 'getSubuscriptionUsers.data', []);
            if (userData && userData.length > 0) {
                setSubscriptionUserData(userData);
            }
            // setOcrFetchLoading(false);
        },
        onError: error => {
            Message.error(error);
            // setOcrFetchLoading(false);
        }
    });

    const [getGroupListByOrgId, { loading: groupListLoading }] = useLazyQuery(GET_OCR_GROUP_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const groupData = get(data, 'getOcrGroupList.data', []);

            if (groupData && groupData.length > 0) {
                setOcrGroupData(groupData);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getGroupDataById, { loading: groupSingleDataLoading }] = useLazyQuery(GET_OCR_GROUP_DATA_BY_ID, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {

            const groupSingleData = get(data, 'getOcrGroupDataById.data', {});

            if (groupSingleData) {
                setGroupSingleData(groupSingleData)
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getUsesrsHaveFolderAccessById, { loading: getUsesrsHaveFolderAccessLoading }] = useLazyQuery(GET_USERS_HAVE_FOLDER_ACCESS_OCR_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {

            const usesrsDataByFolderId = get(data, 'getUsersHaveFolderAccessOcrList.data', []);

            if (usesrsDataByFolderId) {
                setUsersDataByFolderId(usesrsDataByFolderId)
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getOcrImgRuleData, { loading: ocrImageDataLoading }] = useLazyQuery(GET_OCR_IMAGE_FOR_RULE, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const ocrRuleData = get(data, 'getOcrImageListForRule.data', []);
            if (ocrRuleData) {
                setOcrImageDataForCreateRule(ocrRuleData)
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getOcrUsersData, { loading: ocrOcrUsersDataLoading }] = useLazyQuery(GET_OCR_USERS_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const ocrUsersList = get(data, 'getOcrUsersList.data', []);
            if (ocrUsersList) {
                setOcrUsersList(ocrUsersList);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [getOcrProcessData, { loading: ocrOcrProcessDataLoading }] = useLazyQuery(GET_OCR_PROCESS_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const ocrProcessData = get(data, 'getOcrProcessList.data', []);
            if (ocrProcessData) {
                const updatedData = ocrProcessData.map(el => {
                    el.assignedProjectIdToString = el.assignedProject.join();
                    el.assignedFolderIdToString = el.assignedFolder.join();
                    return el;
                })
                setOcrProcessList(updatedData)
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const [addOCRParseData] = useMutation(CREATE_OCR_PARSE_DATA, {
        onError: error => {
            console.log('err', error);
            setOcrSubmitLoading(false);
        },
        onCompleted: data => {
            setOcrSubmitLoading(false);
            Message.success(data && data.addOcrData.message);
            handleSearch('');
            reloadTreeData();
            handleOcrFormClose();
        }
    });

    const [addMutlipleOCRParseData] = useMutation(CREATE_MULTIPLE_OCR_PARSE_DATA, {
        onError: error => {
            setOcrMultipleDocSubmitting(false);
            console.log('err', error)
        },
        onCompleted: data => {
            setOcrMultipleDocSubmitting(false);
            Message.success(data && data.addMultipleOcrData.message);
            handleSearch('');
            reloadTreeData();
            handleMultipleOcrDocUploadFormClose();
        }
    });

    const [createOCRRule] = useMutation(CREATE_OCR_RULE_DATA, {
        onError: error => {
            console.log('err', error);
            setOcrRuleSubmitLoading(false);

        },
        onCompleted: data => {
            setOcrRuleSubmitLoading(false);
            Message.success(data && data.addOcrRuleData.message);
            handleSearchOfOcrRule(data = '');
            handleRuleFormClose();
        }
    });

    const [editOCRRule] = useMutation(EDIT_OCR_RULE_DATA, {
        onError: error => {
            console.log('err', error);
            setOcrRuleSubmitLoading(false);

        },
        onCompleted: data => {
            setOcrRuleSubmitLoading(false);
            Message.success(data && data.editOcrRuleData.message);
            handleSearchOfOcrRule(data = '');
            handleEditRuleFormClose();
        }
    });

    const [deleteOCRRule] = useMutation(DELETE_OCR_RULE_DATA, {
        onError: error => {
            console.log('err', error);
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');
            Message.success(data && data.deleteOcrRule.message);
            handleSearchOfOcrRule(data = '');
        }
    });

    const [createOCRPlace] = useMutation(CREATE_OCR_PLACE, {
        onError: error => {
            console.log('err', error);
            setOcrLocationSubmit(false);

        },
        onCompleted: data => {
            setOcrLocationSubmit(false);
            Message.success(data && data.createOcrPlace.message);
            getOcrPlaceListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_place, limit: PAGE_LIMIT } });
            reloadTreeData();
            handleCreateLocationEnableFormClose();
        }
    });

    const [createOCRProject] = useMutation(CREATE_OCR_PROJECT, {
        onError: error => {
            console.log('err', error);
            setOcrProjectSubmit(false);

        },
        onCompleted: data => {
            setOcrProjectSubmit(false);
            getOcrProjectListBySearch({ variables: { organization: organizationId, place: null, search: null, page: paginationState.ocr_project, limit: PAGE_LIMIT } });
            Message.success(data && data.createOcrProject.message);
            //handleSearchOfOcrRule(data='');
            reloadTreeData();
            handleCreateProjectEnableFormClose();
        }
    });

    const [createOCRFolder] = useMutation(CREATE_OCR_FOLDER, {
        onError: error => {
            console.log('err', error);
            setOcrFolderSubmit(false);

        },
        onCompleted: data => {
            setOcrFolderSubmit(false);
            getOcrFolderListBySearch({ variables: { organization: organizationId, project: null, place: null, search: null, page: paginationState.ocr_folder, limit: PAGE_LIMIT } });
            Message.success(data && data.createOcrFolder.message);
            //handleSearchOfOcrRule(data='');
            reloadTreeData();
            handlecreateFolderEnableFormClose();
        }
    });

    const [createOCRSubFolder] = useMutation(CREATE_OCR_FOLDER, {
        onError: (error) => {
            console.error('Error during OCR subfolder creation:', error);
            setOcrSubFolderSubmit(false);
        },
        onCompleted: (data) => {

            Message.success("Sub-folder Created Successfully");
            setOcrSubFolderSubmit(false);
            getOcrFolderListBySearch({
                variables: {
                    organization: organizationId,
                    project: null,
                    place: null,
                    search: null,
                    page: paginationState.ocr_folder,
                    limit: PAGE_LIMIT,
                },
            });
            reloadTreeData();
            handlecreateSubFolderEnableFormClose();
        },
    });


    const [deleteOCRPlace] = useMutation(DELETE_OCR_PLACE, {
        onError: error => {
            console.log('err', error);
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');
            reloadTreeData();
            getOcrPlaceListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_place, limit: PAGE_LIMIT } });
            Message.success(data && data.deleteOcrPlace.message);
        }
    });

    const [deleteOCRProjec] = useMutation(DELETE_OCR_PROJECT, {
        onError: error => {
            console.log('err', error);
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');
            reloadTreeData();
            getOcrProjectListBySearch({ variables: { organization: organizationId, place: null, search: null, page: paginationState.ocr_project, limit: PAGE_LIMIT } });
            Message.success(data && data.deleteOcrProject.message);
        }
    });

    const [deleteOCRFolder] = useMutation(DELETE_OCR_FOLDER, {
        onError: error => {
            console.log('err', error);
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');
            reloadTreeData();
            getOcrFolderListBySearch({ variables: { organization: organizationId, project: null, place: null, search: null, page: paginationState.ocr_folder, limit: PAGE_LIMIT } });
            Message.success(data && data.deleteOcrFolder.message);
        }
    });

    const [deleteOCRFile] = useMutation(DELETE_OCR_FILE, {
        onError: error => {
            console.log('err', error);
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setOcrRuleDeletetingStarted(false);
            setSelecteIdForDelete('');
            reloadTreeData();
            getOcrListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_document, limit: PAGE_LIMIT } });
            Message.success(data && data.deleteOcrFile.message);
        }
    });

    const [createOcrGroup, { loading: createOcrGroupLoading }] = useMutation(CREATE_OCR_GROUP, {
        onError: error => {
            console.log('err', error);
        },
        onCompleted: data => {
            getGroupListByOrgId({ variables: { organization: organizationId } });
            Message.success(data && data.addOcrGroup.message);
        }
    });

    const [editOcrGroup, { loading: editOcrGroupLoading }] = useMutation(EDIT_OCR_GROUP, {
        onError: error => {
            console.log('err', error);

        },
        onCompleted: data => {
            Message.success(data && data.editOcrGroup.message);
            if (selctedIdForGroupSingleData) {
                getGroupDataById({
                    variables: {
                        id: selctedIdForGroupSingleData
                    }
                })
            } else {
                handleCloseOfProject();
            }
        }
    });

    const [deleteOcrGroup, { loading: deleteOcrGroupLoading }] = useMutation(DELETE_OCR_GROUP, {
        onError: error => {
            console.log('err', error);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setSelecteIdForDelete('');
            getGroupListByOrgId({ variables: { organization: organizationId } });
            Message.success(data && data.deleteOcrGroup.message);
        }
    });

    const [assignFolderTogroup, { loading: assignFolderToGroupLoading }] = useMutation(ASSIGN_FOLDER_TO_GROUP, {
        onError: error => {
            console.log('err', error);
        },
        onCompleted: data => {
            Message.success(data && data.assignFolderToOcrGroup.message);
            handleRefetchedOrFilter('groupsOfFolder', uaserPayloadForGroupAndProject && uaserPayloadForGroupAndProject.folderId);
            handleCloseOfProject();
        }
    });

    const [assignFolderToUser, { loading: assignFolderToUserLoading }] = useMutation(ASSIGN_FOLDER_TO_USER, {
        onError: error => {
            console.log('err', error);
        },
        onCompleted: data => {
            Message.success(data && data.assignFolderToOcrUser.message);
            if (assignType === t('assignFolderToUserOcr.label')) {
                getUsesrsHaveFolderAccessById({
                    variables: {
                        organization: organizationId,
                        folder_id: uaserPayloadForGroupAndProject.folderId
                    }
                })
            }
        }
    });

    const [assignProjectToUser, { loading: assignProjectToUserLoading }] = useMutation(ASSIGN_PROJECT_TO_USER, {
        onError: error => {
            console.log('err', error);
        },
        onCompleted: data => {
            Message.success(data && data.assignProjectToUserOcr.message);
            handleCloseOfProject();
        }
    });

    const [addProjectOfFolderToOCRProcess, { loading: addProjectOfFolderToOCRProcessLoading }] = useMutation(ADD_PROJECT_OR_FOLDER_TO_OCR_PROCESS, {
        onError: error => {
            console.log('err', error);

        },
        onCompleted: data => {
            Message.success(data && data.addProjectOrFolderToOcrProcess.message);
            handleCloseOfProject();
            refetchingProcessData();
        }
    });

    const handleSubmitOfOcrParse = async (files, ocrtext, ocrImg, ruleData, locationValue, projectValue, folderalue) => {
        if (!locationValue || !projectValue || !folderalue) return Message.error('Plese select value from dropdown !!');
        setOcrSubmitLoading(true);
        let convertingBase66ToImg;

        if (ocrImg && files.type === 'application/pdf') {
            const filename = files.name.replace(/\.[^.]+$/, '.jpeg');
            const res = await fetch(ocrImg);
            const blob = await res.blob();
            convertingBase66ToImg = new File([blob], filename, { type: 'image/jpeg' });
        }
        let myLocationresult = await getMyLocation();
        addOCRParseData({
            variables: {
                organization: organizationId,
                heading: '',
                ocr_text: ocrtext.replace(/\n/g, ''),
                doc: ocrtext && convertingBase66ToImg !== undefined ? convertingBase66ToImg : files,
                place: locationValue,
                project: projectValue,
                folder: folderalue,
                rule: ruleData.rule,
                geo_location: myLocationresult != undefined ? myLocationresult : null,
            }
        });
    };

    const handleSubmitOfMultipleUpload = async (uploadData) => {
        if (!uploadData.locationValue || !uploadData.projectValue || !uploadData.folderalue) return Message.error('Plese select value from dropdown !!');
        setOcrMultipleDocSubmitting(true);
        let myLocationresult = await getMyLocation();
        addMutlipleOCRParseData({
            variables: {
                organization: organizationId,
                image_list: uploadData.allFiles,
                rule: uploadData.selectedRuleId,
                place: uploadData.locationValue,
                project: uploadData.projectValue,
                folder: uploadData.folderalue,
                geo_location: myLocationresult != undefined ? myLocationresult : null,
            }
        })
    };

    const handleSubmitOfDeleteOcrRule = (id) => {
        setOcrRuleDeletetingStarted(true);
        setSelecteIdForDelete(id);
        deleteOCRRule({
            variables: {
                id,
                organization: organizationId
            }
        });
    };

    const handleSubmitOfOcrRule = async ruleData => {

        if (!ruleData.ruleName) return Message.error('Rule Name is Requird !!');
        if (ruleData.ruleName === ruleData.description) return Message.error('Rule Name and Rule Description cannot be same !!');

        //Code to handle duplicate crop title 
        const hasDuplicateTitle = ruleData.selected_sections.reduce((acc, item) => {
            if (acc) return true;
            return ruleData.selected_sections.filter((i) => i.cropTitle === item.cropTitle).length > 1;
        }, false);


        if (hasDuplicateTitle) {
            return Message.error('Duplicate value: Same crop title appears more than once.');
        }
        //END: Code to handle duplicate crop title 
        const { docId } = ruleData;
        setOcrRuleSubmitLoading(true);

        createOCRRule({
            variables: {
                organization: organizationId,
                description: ruleData.description,
                rule_name: ruleData.ruleName,
                doc_uuid: docId,
                selected_sections: ruleData.selected_sections

            }
        })


    };

    const handleSubmitEditOfOcrRule = async ruleData => {

        if (!ruleData.ruleName) return Message.error('Rule Name is Requird !!');
        if (ruleData.ruleName === ruleData.description) return Message.error('Rule Name and Rule Description cannot be same !!');

        //Code to handle duplicate crop title 
        const hasDuplicateTitle = ruleData.selected_sections.reduce((acc, item) => {
            if (acc) return true;
            return ruleData.selected_sections.filter((i) => i.cropTitle === item.cropTitle).length > 1;
        }, false);


        if (hasDuplicateTitle) {
            return Message.error('Duplicate value: Same crop title appears more than once.');
        }
        //END: Code to handle duplicate crop title 


        setOcrRuleSubmitLoading(true);
        editOCRRule({
            variables: {
                id: ruleData.id,
                description: ruleData.description,
                rule_name: ruleData.ruleName,
                selected_sections: ruleData.selected_sections
            }
        })
    };

    const handleSubmitOfOcrProject = data => {

        if (!data.projectName) return Message.error('Project Name is Requird !!');
        if (data.projectName === data.projectDescription) return Message.error('Project Name and Project Description cannot be same !!');

        setOcrProjectSubmit(true);
        createOCRProject({
            variables: {
                organization: organizationId,
                name: data.projectName,
                description: data.projectDescription,
                place: data.locationValue
            }
        })
    };

    const handleSubmitOfOcrPlace = data => {

        if (!data.name) return Message.error('Place Name is Requird !!');
        if (!data.placeDescription) return Message.error('Place Description is Requird !!');
        if (data.name === data.placeDescription) return Message.error('Place Name and Place Description cannot be same !!');

        setOcrLocationSubmit(true);
        createOCRPlace({
            variables: {
                organization: organizationId,
                name: data.name,
                place_description: data.placeDescription,
                lat: data.location.latitude,
                lng: data.location.longitude
            }
        })
    }

    const handleSubmitOfOcrFolder = data => {

        if (!data.folderName) return Message.error('Folder Name is Requird !!');
        if (data.folderName === data.folderDescription) return Message.error('Folder Name and Folder Description cannot be same !!');

        setOcrFolderSubmit(true);
        createOCRFolder({
            variables: {
                organization: organizationId,
                name: data.folderName,
                description: data.folderDescription,
                place: data.locationValue,
                project: data.projectValue
            }
        })
    };

    const handleSubmitOfOcrSubFolder = data => {

        if (!data.folderName) return Message.error('SubFolder Name is Requird !!');
        if (data.folderName === data.folderDescription) return Message.error('Folder Name and Folder Description cannot be same !!');

        setOcrSubFolderSubmit(true);
        createOCRSubFolder({
            variables: {
                organization: organizationId,
                name: data.folderName,
                description: data.folderDescription,
                place: data.locationValue,
                project: data.projectValue,
                isSubfolder: true,
                folder: data.folderValue,
            }
        })
    };

    const handleSubmitOfDeleteOcrTreeItems = (data) => {
        if (
            window.confirm(`Are you sure you want to delete this ${data.name}?`) ===
            false
        ) {
            return false;
        }
        setOcrRuleDeletetingStarted(true);
        setSelecteIdForDelete(data.id);
        if (data.type === 'place') {
            deleteOCRPlace({
                variables: {
                    organization: organizationId,
                    placeId: data.id
                }
            });
        } else if (data.type === 'project') {
            deleteOCRProjec({
                variables: {
                    organization: organizationId,
                    projectId: data.id
                }
            });
        } else if (data.type === 'folder') {
            deleteOCRFolder({
                variables: {
                    organization: organizationId,
                    folderId: data.id
                }
            });
        } else if (data.type === 'file') {
            deleteOCRFile({
                variables: {
                    organization: organizationId,
                    fileId: data.id
                }
            });
        }
    };

    const handleFilterdataForOptions = (data, type) => {
        if (data && type === "project") {
            getOcrProjectListBySearch({
                variables: {
                    organization: organizationId,
                    place: data,
                    search: null,
                    page: paginationState.ocr_project,
                    limit: PAGE_LIMIT
                }
            });
        } else if (data && type === "folder") {
            getOcrFolderListBySearch({
                variables: {
                    organization: organizationId,
                    place: data.place,
                    project: data.project,
                    search: null,
                    page: paginationState.ocr_folder,
                    limit: PAGE_LIMIT
                }
            });
        } else if (data === null && type === "null") {
            getOcrProjectListBySearch({
                variables: {
                    organization: organizationId,
                    place: null,
                    search: null,
                    page: paginationState.ocr_project,
                    limit: PAGE_LIMIT
                }
            });
            getOcrFolderListBySearch({
                variables: {
                    organization: organizationId,
                    place: null,
                    project: null,
                    search: null,
                    page: paginationState.ocr_folder,
                    limit: PAGE_LIMIT
                }
            });
        }
    };

    const handleSearch = val => {
        setOcrDocumentSearchvalue(val);
        setOcrFetchLoading(true);
        let filterBy = {
            variables: {
                organization: organizationId,
                search: val,
                page: paginationState.ocr_document,
                limit: PAGE_LIMIT
            }
        }
        return getOcrListBySearch(filterBy);
    };

    const handleSearchOfOcrRule = val => {
        setOcrRuleSearchvalue(val);
        setOcrRuleFetchingloading(true);
        let filterBy = {
            variables: {
                organization: organizationId,
                search: val,
                page: paginationState.ocr_rule,
                limit: PAGE_LIMIT
            }
        }
        return getOcrRuleListBySearch(filterBy);
    };

    const handleSearchOfOcrRuleImg = val => {
        let filterBy = {
            variables: {
                organization: organizationId,
                search: val
            }
        }
        return getOcrImgRuleData(filterBy);
    };

    const handleSearchOfOcrFolder = val => {
        setOcrFolderSearchvalue(val);
        setocrFolderListLoading(true);
        let filterBy = {
            variables: {
                organization: organizationId,
                search: val,
                project: null,
                place: null,
                page: paginationState.ocr_folder,
                limit: PAGE_LIMIT
            }
        }
        return getOcrFolderListBySearch(filterBy);
    };

    const handlePageChange = (type, page) => {
        setPaginationState({ ...paginationState, [type]: page });
        if (type === "ocr_document") {
            setOcrFetchLoading(true);
            let filterBy = {
                variables: {
                    organization: organizationId,
                    search: ocrDocumentSearchvalue,
                    page: page,
                    limit: PAGE_LIMIT
                }
            }
            return getOcrListBySearch(filterBy);
        } else if (type === 'ocr_rule') {
            setOcrRuleFetchingloading(true);
            let filterBy = {
                variables: {
                    organization: organizationId,
                    search: ocrRuleSearchvalue,
                    page: page,
                    limit: PAGE_LIMIT
                }
            }
            return getOcrRuleListBySearch(filterBy);
        } else if (type === 'ocr_place') {
            setOcrPlaceListLoading(true);
            let filterBy = {
                variables: {
                    organization: organizationId,
                    search: null,
                    page: page,
                    limit: PAGE_LIMIT
                }
            }
            return getOcrPlaceListBySearch(filterBy);
        } else if (type === 'ocr_project') {
            setOcrProjectLoaListding(true);
            let filterBy = {
                variables: {
                    organization: organizationId,
                    search: null,
                    place: null,
                    page: page,
                    limit: PAGE_LIMIT
                }
            }
            return getOcrProjectListBySearch(filterBy);
        } else if (type === 'ocr_folder') {
            setocrFolderListLoading(true);
            let filterBy = {
                variables: {
                    organization: organizationId,
                    search: null,
                    project: null,
                    place: null,
                    page: page,
                    limit: PAGE_LIMIT
                }
            }
            return getOcrFolderListBySearch(filterBy);
        }
    }

    const handleOpenFileView = (file) => {
        setIsParseDocViwerEnable(true);
        setOcrParseImgLoading(true);
        getOcrParseImg({
            variables: {
                organization: organizationId,
                id: file.id
            }
        });
    };

    const handleFilterForDashboardMap = (data) => {
        const { startTime, endTime, place, project, folder } = data;
        getOcrDashBoardMapDetails({
            variables: {
                organization: organizationId,
                project,
                place,
                folder,
                start_date: startTime ? startTime : null,
                end_date: endTime ? endTime : null
            }
        });

    };

    const handleSubmitOfAllOcrGroupAction = (type, data) => {
        if (type === 'add') {
            if (!data.groupOrUserName) return Message.error('Group Name is Requird !!');
            if (!data.accsesvalue) return Message.error('Plese select a value from access dropdown !!');

            createOcrGroup({
                variables: {
                    organization: organizationId,
                    name: data.groupOrUserName,
                    permission: data.accsesvalue
                }
            })
        } else if (type === 'edit') {
            console.log('data', data);
            editOcrGroup({
                variables: {
                    organization: organizationId,
                    id: selctedIdForGroupSingleData ? selctedIdForGroupSingleData : data.id,
                    users: data.user ? data.user : uaserPayloadForGroupAndProject,
                    //id: selctedIdForGroupSingleData,
                    //users: data.user,
                }
            })
        } else if (type === 'delete') {
            setSelecteIdForDelete(data.id);
            deleteOcrGroup({
                variables: {
                    organization: organizationId,
                    id: data.id
                }
            })
        } else if (type === 'getById') {
            console.log('data', data);
            setSelctedIdForGroupSingleData(data.id);
            setIsaddUserToGroupElementEnable(true);
            getGroupDataById({
                variables: {
                    id: data.id
                }
            })
        } else if (type === t('assignToProject.label')) {

            assignProjectToUser({
                variables: {
                    organization: organizationId,
                    project_id: data.id,
                    permission: '',
                    user_detail: uaserPayloadForGroupAndProject
                }
            })
        } else if (type === 'usersFolderData') {
            getUsesrsHaveFolderAccessById({
                variables: {
                    organization: organizationId,
                    folder_id: uaserPayloadForGroupAndProject.folderId
                }
            })
        }
    }

    const ocrAssignAndAddActions = (type, data) => {
        if (type === t('assignFolderToUserOcr.label')) {
            assignFolderToUser({
                variables: {
                    organization: organizationId,
                    folder_id: uaserPayloadForGroupAndProject.folderId,
                    permission: data.accsesvalue,
                    user_detail: data.selectedUserData
                }
            })
        } else if (type === t('assignFolderToGroupOcr.label')) {
            assignFolderTogroup({
                variables: {
                    organization: organizationId,
                    folder_id: uaserPayloadForGroupAndProject.folderId,
                    group_id: data.id
                }
            })
        }
    };

    const handleSubmitOrFilterForDocumentConfigTab = (data) => {
        const { startTime, endTime, place, project } = data;

        getOcrListForDocuments({
            variables: {
                organization: organizationId,
                project,
                place,
                search: '',
                start_date: startTime ? startTime : null,
                end_date: endTime ? endTime : null
            }
        });
    };

    const handleFilterForOcrUser = (data) => {
        getOcrUsersData({
            variables: {
                organization: organizationId,
                group_id: data.id
            }
        });
    };

    const handleSubmitForOtehrOcrProcess = (type, data) => {

        if (type === t('assignProjectToProcess.label')) {
            addProjectOfFolderToOCRProcess({
                variables: {
                    id: uaserPayloadForGroupAndProject && uaserPayloadForGroupAndProject.processId,
                    assignedProjectId: data.id,
                    assignedFolderId: ''
                }
            });
        } else if (type === t('assignFolderToProcess.label')) {
            addProjectOfFolderToOCRProcess({
                variables: {
                    id: uaserPayloadForGroupAndProject && uaserPayloadForGroupAndProject.processId,
                    assignedProjectId: data.projectId,
                    assignedFolderId: data.folderId
                }
            })
        }
    };

    const refetchingProcessData = () => {
        getOcrProcessData({ variables: { organization: organizationId } });
    };

    const handleCloseOFParseDocViwer = () => setIsParseDocViwerEnable(false);

    const handleChangeActiveNotiTab = (st, tabeValue) => {
        //setActiveNotificationTab(st);
        if (tabeValue) {
            setActiveIndex(tabeValue);
        }
    };

    const [getGlobalDocTreeSearch, { loading: globalDocSearchLoading }] = useLazyQuery(GET_OCR_TREEE_VIEW_SEARCH, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            let searchedData = []
            if (res.getOcrTreeViewSearch && res.getOcrTreeViewSearch.data) {
                searchedData = res.getOcrTreeViewSearch.data;
            }
            setGlobalDocSearchList(searchedData && searchedData.length ? searchedData.map(item => {
                return {
                    name: item.name,
                    id: item.id,
                    place: item.place,
                    folder: item.folder,
                    project: item.project,
                    heading: item.heading,
                    ocr_text: item.ocr_text
                }
            }) : []);
        }
    });

    const handleChangeGlobalSearch = (evt, type = null) => {
        if (globalDocSearchLoading) {
            return null;
        }
        if (evt && evt.key === undefined) {
            setDocumentSearchValue(evt.target.value)
        }
        if (type === 'submit' || (evt && evt.key === 'Enter')) {
            return getGlobalDocTreeSearch({
                variables: {
                    organization: organizationId,
                    search: documentSearchValue
                }
            });
        }

    }

    const handleGlobalDocItemClick = (doc) => {
        setActiveIndex(1);
        setGlobalDocSearch(doc);
    }

    let tabList = [
        {
            label: t('dashboard.label'),
            icon: <MdOutlineSpaceDashboard />,
            children: (
                <OcrDashBoardPage
                    t={t}
                    dashboardPanelData={dashboardPanelData}
                    ocrPlaceData={ocrPlaceData}
                    ocrProjectData={ocrProjectData}
                    ocrFolderData={ocrFolderData}
                    loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
                    loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
                    loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
                    handleFilterdataForOptions={handleFilterdataForOptions}
                    ocrDashboardMapLoading={ocrDashboardMapLoading || loadingOcrDashboardMap}
                    ocrDashboardMapData={ocrDashboardMapData}
                    handleFilterForDashboardMap={handleFilterForDashboardMap}
                    handleChangeGlobalSearch={handleChangeGlobalSearch}
                    documentSearchValue={documentSearchValue}
                    globalDocSearchList={globalDocSearchList}
                    globalDocSearchLoading={globalDocSearchLoading}
                    handleGlobalDocItemClick={handleGlobalDocItemClick}
                />

            )
        },
        // {
        //     label: 'Parse Documents',
        //     children:(
        //         <OcrListingPage
        //             t={t}
        //             ocrSubmitLoading={ocrSubmitLoading}
        //             handleSubmitOfOcrParse={handleSubmitOfOcrParse}
        //             ocrData={ocrData}
        //             ocrFetchLoading={ocrFetchLoading||loading}
        //             handleSearch={handleSearch}
        //             uploadOcrDoc={uploadOcrDoc}
        //             isOcrFormEnable={isOcrFormEnable}
        //             handleOcrFormClose={handleOcrFormClose}
        //             ocrRuleData={ocrRuleData}
        //             isMultipleOcrDocUploadenable={isMultipleOcrDocUploadenable}
        //             uploadMultipleOcrDoc={uploadMultipleOcrDoc}
        //             handleMultipleOcrDocUploadFormClose={handleMultipleOcrDocUploadFormClose}
        //             handleSubmitOfMultipleUpload={handleSubmitOfMultipleUpload}
        //             ocrMultipleDocLoading={ocrMultipleDocSubmitting}
        //             dashboardPanelData={dashboardPanelData}
        //             page={paginationState.ocr_document}
        //             handlePageChange={handlePageChange}
        //             organizationId={organizationId}
        //             handleFilterdataForOptions={handleFilterdataForOptions}
        //             ocrPlaceData={ocrPlaceData}
        //             ocrProjectData={ocrProjectData}
        //             ocrFolderData={ocrFolderData}
        //             loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
        //             loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
        //             loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
        //         />
        //     )
        // },
        {
            label: 'Doc Tree',
            icon: <MdOutlineAccountTree />,
            children: (
                <OcrTreeStructure
                    t={t}
                    reloadTreeData={refreshTree}
                    dataLoading={dataLoading}
                    organizationId={organizationId}
                    ocrPlaceData={ocrPlaceData}
                    ocrProjectData={ocrProjectData}
                    ocrFolderData={ocrFolderData}
                    loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
                    loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
                    loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
                    treeData={!ocrDocTreeLoading && !ocrDocTreeError && ocrDocTreeData.getOcrDocTreeData ? ocrDocTreeData.getOcrDocTreeData : []}
                    treeDataLoading={ocrDocTreeLoading}
                    isFolderEnable={isCreateFolderEnable}
                    isSubFolderEnable={isCreateSubFolderEnable}
                    handlecreateSubFolderEnableFormClose={handlecreateSubFolderEnableFormClose}
                    createSubFolderEnable={createSubFolderEnable}
                    handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
                    ocrSubFolderSubmit={ocrSubFolderSubmit}
                    handlecreateFolderEnableFormClose={handlecreateFolderEnableFormClose}
                    handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
                    ocrFolderSubmit={ocrFolderSubmit}
                    handleFilterdataForOptions={handleFilterdataForOptions}
                    createFolderEnable={createFolderEnable}
                    ocrProjectSubmit={ocrProjectSubmit}
                    handleSubmitOfOcrProject={handleSubmitOfOcrProject}
                    handleCreateProjectEnableFormClose={handleCreateProjectEnableFormClose}
                    isProjectEnable={isCreateProjectEnable}
                    createProjectEnable={createProjectEnable}
                    handleSearchOfOcrFolder={handleSearchOfOcrFolder}
                    handleOpenFileView={handleOpenFileView}
                    selecteIdForDelete={selecteIdForDelete}
                    ocrConfigItemsDeletetingStarted={ocrRuleDeletetingStarted}
                    uploadOcrDoc={uploadOcrDoc}
                    setTreeStruckcherDataForModal={setTreeStruckcherDataForModal}
                    handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                    uploadMultipleOcrDoc={uploadMultipleOcrDoc}
                    activeIndex={activeIndex}
                    ocrDocTreeViewSearchData={ocrDocTreeViewSearchData}
                    ocrDocTreeViewSearchLoading={ocrDocTreeViewSearchLoading}
                    ocrTreeViewData={ocrTreeViewData}
                    assignProjectEnable={assignProjectEnable}
                    handleSearchOfParseDocument={handleSearch}
                    ocrFetchLoading={ocrFetchLoading || loading}
                    ocrData={ocrData}
                    createGroupOrUserEnable={createGroupOrUserEnable}
                    ocrProcessList={ocrProcessList}
                    ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
                    globalDocSearch={globalDocSearch}
                />
            )
        },
        {
            label: t('parseRules.label'),
            icon: <HiOutlineDocumentMagnifyingGlass />,
            children: (
                <OcrParseRules
                    t={t}
                    isCreateRuleEnable={isCreateRuleEnable}
                    createRuleHandle={createRuleHandle}
                    handleRuleFormClose={handleRuleFormClose}
                    ocrRuleData={ocrRuleData}
                    ocrRuleFetchingloading={ocrRuleFetchingloading || loadingOcrRule}
                    handleSearch={handleSearch}
                    ocrData={ocrData}
                    handleSubmitOfOcrRule={handleSubmitOfOcrRule}
                    ocrRuleSubmitLoading={ocrRuleSubmitLoading}
                    ocrImageDataForCreateRule={ocrImageDataForCreateRule}
                    handleSubmitOfDeleteOcrRule={handleSubmitOfDeleteOcrRule}
                    ocrConfigItemsDeletetingStarted={ocrRuleDeletetingStarted}
                    selecteIdForDelete={selecteIdForDelete}
                    handleSearchOfOcrRule={handleSearchOfOcrRule}
                    isEditRuleEnable={isEditRuleEnable}
                    editRuleHandle={editRuleHandle}
                    handleEditRuleFormClose={handleEditRuleFormClose}
                    handleSubmitEditOfOcrRule={handleSubmitEditOfOcrRule}
                    page={paginationState.ocr_rule}
                    handlePageChange={handlePageChange}
                    dataForUpdateRuleModal={selectedEditIdData}
                    organizationId={organizationId}
                    handleSearchOfOcrRuleImg={handleSearchOfOcrRuleImg}
                />
            )
        },
        {
            label: t('processes.label'),
            icon: <VscServerProcess style={{ color: "white" }} />,
            children: (
                <OcrProcessesPage
                    t={t}
                />
            )
        },
        {
            label: t('support.label'),
            icon: <MdOutlineContactSupport />,
            children: (
                <OcrSupportPage
                    t={t}
                />
            )
        }
    ];

    let tabListForSuperUser = [...tabList,
    {
        label: t('billing.label'),
        icon: <FaDollarSign />,
        children: (
            <OcrBillingPage
                t={t}
            />
        )
    }, {
        label: t('configuration.label'),
        icon: <FaUserCog style={{ color: "white" }} />,
        children: (
            <OcrConfiguration
                t={t}
                organizationId={organizationId}
                createLocationEnable={createLocationEnable}
                handleCreateLocationEnableFormClose={handleCreateLocationEnableFormClose}
                isLocationEnable={isCreateLocationEnable}
                ocrPlaceData={ocrPlaceData}
                ocrProjectData={ocrProjectData}
                ocrFolderData={ocrFolderData}
                loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
                loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
                loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
                page={paginationState}
                handlePageChange={handlePageChange}
                createProjectEnable={createProjectEnable}
                handleCreateProjectEnableFormClose={handleCreateProjectEnableFormClose}
                isProjectEnable={isCreateProjectEnable}
                createFolderEnable={createFolderEnable}
                handlecreateFolderEnableFormClose={handlecreateFolderEnableFormClose}
                isFolderEnable={isCreateFolderEnable}
                isSubFolderEnable={isCreateSubFolderEnable}
                createSubFolderEnable={createSubFolderEnable}
                handlecreateSubFolderEnableFormClose={handlecreateSubFolderEnableFormClose}
                handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
                ocrSubFolderSubmit={ocrSubFolderSubmit}
                handleSubmitOfOcrProject={handleSubmitOfOcrProject}
                ocrProjectSubmit={ocrProjectSubmit}
                handleSubmitOfOcrPlace={handleSubmitOfOcrPlace}
                ocrLocationSubmit={ocrLocationSubmit}
                handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
                ocrFolderSubmit={ocrFolderSubmit}
                handleFilterdataForOptions={handleFilterdataForOptions}
                ocrConfigItemsDeletetingStarted={ocrRuleDeletetingStarted}
                selecteIdForDelete={selecteIdForDelete}
                handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                assignProjectEnable={assignProjectEnable}
                createGroupOrUserEnable={createGroupOrUserEnable}
                subscriptionUserListLoading={userListLoading}
                subscriptionUserData={subscriptionUserData}
                ocrFetchLoadingForDocuments={getOcrListForDocumentsLoading}
                ocrDocumentsListing={ocrDocumentsListing}
                handleSubmitOrFilterForDocumentConfigTab={handleSubmitOrFilterForDocumentConfigTab}
                groupListLoading={groupListLoading}
                ocrGroupData={ocrGroupData}
                ocrOcrUsersDataLoading={ocrOcrUsersDataLoading}
                ocrUsersList={ocrUsersList}
                handleFilterForOcrUser={handleFilterForOcrUser}
                ocrSelectPlaces={(ocrSelectPlaces && ocrSelectPlaces.getOcrPlaceList && ocrSelectPlaces.getOcrPlaceList.data) ? ocrSelectPlaces.getOcrPlaceList.data : []}
                ocrSelectProjects={(ocrSelectProjects && ocrSelectProjects.getOcrProjectList && ocrSelectProjects.getOcrProjectList.data) ? ocrSelectProjects.getOcrProjectList.data : []}
                subscriptionId={id}
                ocrProcessList={ocrProcessList}
                ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
                allOcrUsersData={!ocrUsersListLoading && ocrUsersData ? get(ocrUsersData, 'getOcrUsersList.data', []) : []}
                ocrUsersListLoading={ocrUsersListLoading}
                refetchOcrProcessData={refetchingProcessData}
            />
        )
    }
    ]

    useEffect(() => {
        if (organizationId) {
            setOcrFetchLoading(true);
            getOcrProcessData({ variables: { organization: organizationId } });
            getOcrListBySearch({ variables: { organization: organizationId, place: "", project: "", start_date: null, end_date: null, search: null, page: paginationState.ocr_document, limit: PAGE_LIMIT } });
            getOcrRuleListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_rule, limit: PAGE_LIMIT } });
            getOcrPlaceListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_place, limit: PAGE_LIMIT } });
            getOcrProjectListBySearch({ variables: { organization: organizationId, place: null, search: null, page: paginationState.ocr_project, limit: PAGE_LIMIT } });
            getOcrFolderListBySearch({ variables: { organization: organizationId, project: null, place: null, search: null, page: paginationState.ocr_folder, limit: PAGE_LIMIT } });
            getOcrDashBoardMapDetails({ variables: { organization: organizationId, project: '', place: '', folder: '', start_date: null, end_date: null } });
            getUserListOfSubscriptiontByOwnerId({ variables: { groupId: ownerId } });
            getGroupListByOrgId({ variables: { organization: organizationId } });
            getOcrListForDocuments({ variables: { organization: organizationId, place: "", project: "", start_date: null, end_date: null, search: null } });
            getOcrImgRuleData({ variables: { organization: organizationId, search: null } });
            getOcrUsersData({ variables: { organization: organizationId, groupId: '' } });
        }
    }, [organizationId]);

    useEffect(() => {
        if (activeIndex === 1 || activeIndex === 6) {
            setTreeStruckcherDataForModal(null);
        }
    }, [activeIndex]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setApiTimer(apiTimer + 1);
            getOcrListBySearch({ variables: { organization: organizationId, search: null, page: paginationState.ocr_document, limit: PAGE_LIMIT } });
        }, 30000);
        return () => {
            clearTimeout(timer);
        };
    }, [apiTimer, getOcrListBySearch, organizationId, paginationState.ocr_document]);


    if (hostName.includes("trans.edgedock.inq.co.bw")) {
        // Filter out items with the labels 'support.label' and 'billing.label'
        tabListForSuperUser = tabListForSuperUser.filter(
            item => item.label !== t('support.label') && item.label !== t('billing.label')
        );
    }


    useEffect(() => {
        getTabList(isSuperUser(localStorage.getItem('role')) ? tabListForSuperUser : tabList);
    }, []);

    const handleChange = (event, newValue) => {
        if (typeof handleChangeActiveNotiTab !== "undefined") {
            handleChangeActiveNotiTab(false, newValue);
        }
    };

    return (
        <>
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={isSuperUser(localStorage.getItem('role')) ? tabListForSuperUser : tabList}
                handleChangeActiveNotiTab={handleChangeActiveNotiTab}
            /> */}

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={sideBarActiveIndex}
                onChangeIndex={handleChange}
            >
                {(isSuperUser(localStorage.getItem('role')) ? tabListForSuperUser : tabList).length &&
                    (isSuperUser(localStorage.getItem('role')) ? tabListForSuperUser : tabList)?.map((tab, tabIndex) => (
                        <TabPanel
                            value={sideBarActiveIndex}
                            index={tabIndex}
                            dir={theme.direction}
                            key={tabIndex}>
                            {tab.children}
                        </TabPanel>
                    ))}
            </SwipeableViews>
            <OcrDocViewer
                t={t}
                open={isParseDocViwerEnable}
                onClose={handleCloseOFParseDocViwer}
                docData={doctextORImg}
                type="parseDoc"
                loading={ocrParseImgLoading}
                imgUrl={ocrParseImgs}
                isBarcode={ocrParseImgs.is_barcode}
                imgName={img_url ? img_url : ""}
                handleOpenFileView={handleOpenFileView}
            />
            <CreateProject
                t={t}
                open={isCreateProjectEnable}
                onClose={handleCreateProjectEnableFormClose}
                loading={loadingOcrPlace || ocrPlaceListLoading}
                ocrPlaceData={ocrPlaceData}
                handleSubmitOfOcrProject={handleSubmitOfOcrProject}
                ocrProjectSubmit={ocrProjectSubmit}
                isLocationDataexists={treeStruckcherDataForModal}
            />
            <CreateFolder
                t={t}
                open={isCreateFolderEnable}
                onClose={handlecreateFolderEnableFormClose}
                handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
                ocrProjectData={ocrProjectData}
                ocrPlaceData={ocrPlaceData}
                ocrFolderSubmit={ocrFolderSubmit}
                placeLoading={loadingOcrPlace || ocrPlaceListLoading}
                projectLoading={loadingOcrProject || ocrProjectListLoading}
                handleFilterdataForOptions={handleFilterdataForOptions}
                isProjectDataExists={treeStruckcherDataForModal}
            />
            <CreateSubFolder
                t={t}
                open={isCreateSubFolderEnable}
                onClose={handlecreateSubFolderEnableFormClose}
                handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
                ocrProjectData={ocrProjectData}
                ocrPlaceData={ocrPlaceData}
                ocrFolderData={ocrFolderData}
                ocrSubFolderSubmit={ocrSubFolderSubmit}
                placeLoading={loadingOcrPlace || ocrPlaceListLoading}
                projectLoading={loadingOcrProject || ocrProjectListLoading}
                folderLoading={loadingOcrFolder || ocrFolderListLoading}
                handleFilterdataForOptions={handleFilterdataForOptions}
                isProjectDataExists={treeStruckcherDataForModal}
            />
            <OcrDocUploadForm
                t={t}
                open={isOcrFormEnable}
                onClose={handleOcrFormClose}
                ocrSubmitLoading={ocrSubmitLoading}
                handleSubmitOfOcrParse={handleSubmitOfOcrParse}
                handleFilterdataForOptions={handleFilterdataForOptions}
                ocrPlaceData={ocrPlaceData}
                ocrProjectData={ocrProjectData}
                ocrFolderData={ocrFolderData}
                loadingOcrPlace={loadingOcrPlace || ocrPlaceListLoading}
                loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
                loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
                isDataExists={treeStruckcherDataForModal}
            />
            <OcrMultipleDocUpload
                t={t}
                open={isMultipleOcrDocUploadenable}
                onClose={handleMultipleOcrDocUploadFormClose}
                ocrRuleData={ocrRuleData}
                handleSubmitOfMultipleUpload={handleSubmitOfMultipleUpload}
                ocrMultipleDocLoading={ocrMultipleDocSubmitting}
                handleFilterdataForOptions={handleFilterdataForOptions}
                ocrPlaceData={ocrPlaceData}
                ocrProjectData={ocrProjectData}
                ocrFolderData={ocrFolderData}
                loadingOcrPlace={loadingOcrPlace}
                loadingOcrProject={loadingOcrProject}
                loadingOcrFolder={loadingOcrFolder}
                isDataExists={treeStruckcherDataForModal}
            />
            <OcrConfigurationGAndUAssignForProejctAndGroup
                t={t}
                open={isAssignProjectEnable}
                onClose={handleCloseOfProject}
                assignType={assignType}
                groupListLoading={groupListLoading}
                ocrGroupData={ocrGroupData}
                loadingOcrProject={loadingOcrProject || ocrProjectListLoading}
                ocrProjectData={ocrProjectData}
                editOcrGroupLoading={editOcrGroupLoading}
                handleSubmitOfAllOcrGroupAction={handleSubmitOfAllOcrGroupAction}
                ocrAssignAndAddActions={ocrAssignAndAddActions}
                assignFolderToGroupLoading={assignFolderToGroupLoading}
                assignProjectToUserLoading={assignProjectToUserLoading}
                treeDataLoading={ocrDocTreeLoading}
                exitingGroupDetails={uaserPayloadForGroupAndProject && uaserPayloadForGroupAndProject.exitingGroupDetails}
                handleSubmitForOtehrOcrProcess={handleSubmitForOtehrOcrProcess}
                addProjectOfFolderToOCRProcessLoading={addProjectOfFolderToOCRProcessLoading}
                ocrFolderData={ocrFolderData}
                loadingOcrFolder={loadingOcrFolder || ocrFolderListLoading}
                handleFilterdataForOptions={handleFilterdataForOptions}
                selectedFolderId={uaserPayloadForGroupAndProject && uaserPayloadForGroupAndProject.folderId}
            />
            <OcrConfigurationGAndUCreateGroupOrUser
                t={t}
                open={isCreateGroupOrUserEnable}
                onClose={handleCloseofGroupOrUserEnable}
                subscriptionUserListLoading={userListLoading}
                subscriptionUserData={subscriptionUserData}
                handleSubmitOfAllOcrGroupAction={handleSubmitOfAllOcrGroupAction}
                createOcrGroupLoading={createOcrGroupLoading}
                editOcrGroupLoading={editOcrGroupLoading}
                deleteOcrGroupLoading={deleteOcrGroupLoading}
                groupListLoading={groupListLoading}
                ocrGroupData={ocrGroupData}
                selecteIdForDelete={selecteIdForDelete}
                assignType={assignType}
                isaddUserToGroupElementEnable={isaddUserToGroupElementEnable}
                groupSingleDataLoading={groupSingleDataLoading}
                singleGroupData={groupSingleData}
                ocrAssignAndAddActions={ocrAssignAndAddActions}
                assignFolderToUserLoading={assignFolderToUserLoading}
                usersDataByFolderId={usersDataByFolderId}
                getUsesrsHaveFolderAccessLoading={getUsesrsHaveFolderAccessLoading}
            />
        </>
    )
}

export default OcrPanel
