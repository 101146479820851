import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({

    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    urFormState: {
    
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px',
        marginTop: '25px',
        border: '0px',
        outline: 'none'
        
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    expclass:"",
    application:"", 
    userProjectTitle:"",
    userSubject:"",
    userCostAllocation:"",
    authorisedAmt1:"",
    amtUsedtodate1:"",
    amtAppliedFor1:"",
    authorisedAmt2:"",
    amtUsedtodate2:"",
    amtAppliedFor2:"",
    authorisedAmt3:"",
    amtUsedtodate3:"",
    amtAppliedFor3:"",
    authorisedAmt4:"",
    amtUsedtodate4:"",
    amtAppliedFor4:"",
    authorisedAmt5:"",
    amtUsedtodate5:"",
    amtAppliedFor5:"",
    authorisedAmt6:"",
    amtUsedtodate6:"",
    amtAppliedFor6:"",
    authorisedAmt7:"",
    amtUsedtodate7:"",
    amtAppliedFor7:"",
    authorisedAmt8:"",
    amtUsedtodate8:"",
    amtAppliedFor8:"",
    authorisedAmt9:"",
    amtUsedtodate9:"",
    amtAppliedFor9:"",
    authorisedAmt10:"",
    amtUsedtodate10:"",
    amtAppliedFor10:"",
    vat1:"",
    escalation1:"",
    contingency1:"",
    total1:"",
    totalauthorisedexpenditure:"",
    revisedauthorisedfec:"",
    vat2:"",
    escalation2:"",
    contingency2:"",
    total2:"",
    vat3:"",
    escalation3:"",
    contingency3:"",
    total3:"",
    vat4:"",
    escalation4:"",
    contingency4:"",
    total4:"",
    originator:"",
    originatorSign:"",
    date1: null,
    sectionHead:"",
    sectionHeadSign:"",
    date2: null,
    HoD:"",
    HoDSign:"",
    date3: null,
    assistantAccountant:"",
    assistantAccountantSign:"",
    date4: null,
    costAccountant:"",
    costAccountantSign:"",
    date5: null,
    financeManager:"",
    financeManagerSign:"",
    date6: null,
    commercialManager:"",
    commercialManagerSign:"",
    date7: null,
    generalManager:"",
    generalManagerSign:"",
    date8: null,
    deputyManagingDirector:"",
    deputyManagingDirectorSign:"",
    date9: null,
    comments:"",
    generalComment:"",

};


const tableItems = [
    {
        key: 'long_term',
        value: 'Long Term',
        label: "Permanent / Fixed Term (long term)",
        description: "Permanent & FTC 12 months and above"
    },
    {
        key: 'short_term',
        value: 'Short term',
        label: "Fixed Term (short term)",
        description: "Duration of employment if fixed term = 4 days to 11 months (commonly known as temporary)"
    },
    {
        key: 'casual',
        label: "Casual",
        value: "Casual",
        description: "A casual employee is an employee who does not work for more than 3 days or 22.5 hours a week and whose term of contract is for less than 12 months"
    }
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function FundsApplicationFormModal({
    open,
    handleClose,
    t,
    data,
    users,
    departments,
    userdata,
    modalState,
    handleFundsApplicationSubmit,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
})
{
    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setURFormState({...data, id });
        }
    }, [data]);

    const handleChange = (value, name, key) => {
        setURFormState({ ...urFormState, [name]: value});
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
                
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ textAlign: "center"}} >FUNDS APPLICATION FORM</Typography>
                        <hr />
                    </Grid>
                    <Grid item xs={12} style={{ backgroundColor: "#ffffE0"}}>
                        <p style={{margin: '0px'}}>
                            This form is used when applying for or amending the application of (previously authorised) funds for either of minor assets,capital projects or working cost
                        </p>
                    </Grid>
                    <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Class of expenditure:</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                placeholder={'Additional Capital'}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.expclass}
                                onChange = {(evt) => handleChange(evt.target.value, "expclass")}
                             />
                        </Grid>

                        <Grid item xs={2}>
                            <h5 className={classes.blackBorderTitle}>Type of application:</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                placeholder={'Change of scope'}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.application}
                                onChange = {(evt) => handleChange(evt.target.value, "application")}
                                
                            />
                        </Grid>

    <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Project Title:</h5>
    </Grid>

    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.userProjectTitle}
        onChange = {(evt) => handleChange(evt.target.value, "userProjectTitle")}
        
        />
     </Grid>
     <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Subject:</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.userSubject}
        onChange = {(evt) => handleChange(evt.target.value, "userSubject")}
        
        />
     </Grid>

     <Grid className={classes.blackBorder} item xs={4}>
        <h5 className={classes.blackBorderTitle}>Cost Allocation:</h5>
    </Grid>
    <Grid className={classes.blackBorder} item xs={8}>
    <Input
        type={'text'}
        style={{ width: '100%' }}
        className={classes.input}
        color={'secondary'}
        value={urFormState.userCostAllocation}
        onChange = {(evt) => handleChange(evt.target.value, "userCostAllocation")}
        
        />
     </Grid>

     </Grid>
     <FormGroup row>
        </FormGroup> 
<Grid container spacing={2} style={{ marginBottom: 20 }} >

</Grid>

<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#ffffE0"}} >
    <h4 className={classes.blackBorderTitle}>REASON FOR APPLICATION:</h4>
</Grid>  
<Grid className={classes.blackBorder} item xs={12}>
<TextField
    type="textarea"
    variant="standard"
    fullWidth={true}
    multiline
    rows={5}
    value={urFormState.generalComment}
    onChange = {(evt) => handleChange(evt.target.value, "generalComment")}
/>
</Grid>
    
     <Grid item xs={12}>
       <Grid container spacing={2} style={{ marginBottom: 20 }}>

        <Grid item xs={12}>
            <Typography variant="h7">NB:Scope changes must clearly articulate the impact on the project &/or estimate/asset from which funds are being sourced.Attach additional documentation in support as necessary</Typography>
          </Grid>
         </Grid>
     </Grid>
<FormGroup row>
<Grid container spacing={2} style={{ marginBottom: 20 }} >
<Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#ffffE0"}}>
<h4 className={classes.blackBorderTitle}>FINANCIAL STATUS</h4> 
</Grid>
        
    <Grid className={classes.blackBorder} item xs={12}>
     <FormGroup row>
     <Grid className={classes.blackBorder} container  xs={3}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 2}}>
                      Authorised Amount</h5>
                </Grid>
        <Grid className={classes.blackBorder} container  xs={3}>
                    <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                      Amt Used to date</h5>
                </Grid>
                <Grid className={classes.blackBorder} item xs={3}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                        Amt Available</h5>
                  </Grid>
                  <Grid className={classes.blackBorder} item xs={3}>
                      <h5 className={classes.blackBorderTitle} style={{ fontSize: '15px', fontWeight: '600', fontFamily: 'sans-serif', marginLeft: 5}}>
                      Amt Applied For</h5>
                  </Grid>
                  <Grid className={classes.blackBorder} container  xs={3}>
                      <Input
                              type={'text'}
                              placeholder={""}
                              style={{minWidth:'190px'}}
                              className={classes.input}
                              color={'secondary'}
                              value={urFormState.authorisedAmt1}
                              onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt1")}
                              //value={sensorFormState.deviceNo}
                          />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                      <Input
                              type={'text'}
                              placeholder={""}
                              style={{minWidth:'190px'}}
                              className={classes.input}
                              color={'secondary'}
                              value={urFormState.amtUsedtodate1}
                              onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate1")}
                              //value={sensorFormState.deviceNo}
                          />
                </Grid>

                <Grid className={classes.blackBorder} container  xs={3}>
                      <Input
                              type={'text'}
                              placeholder={""}
                              style={{minWidth:'190px'}}
                              className={classes.input}
                              color={'secondary'}
                              value={urFormState.amtAppliedFor1}
                              onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor1")}
                              //value={sensorFormState.deviceNo}
                          />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input 
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt2}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate2}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>

                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor2}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt3}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate3}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor3}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt4}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate4}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor4}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt5}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt5")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate5}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate5")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid> 
               
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor5}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor5")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
            
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt6}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt6")}
                  
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate6}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate6")}
                          
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor6}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor6")}
                          
                      />
                </Grid>
            
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt7}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt7")}
                          
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate7}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate7")}
                          
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor7}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor7")}
                        
                      />
                </Grid>
             
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.authorisedAmt8}
                          onChange = {(evt) => handleChange(evt.target.value, "authorisedAmt8")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtUsedtodate8}
                          onChange = {(evt) => handleChange(evt.target.value, "amtUsedtodate8")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.amtAppliedFor8}
                          onChange = {(evt) => handleChange(evt.target.value, "amtAppliedFor8")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
        
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"VAT"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.vat1}
                          onChange = {(evt) => handleChange(evt.target.value, "vat1")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"VAT"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.vat2}
                          onChange = {(evt) => handleChange(evt.target.value, "vat2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"VAT"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.vat3}
                          onChange = {(evt) => handleChange(evt.target.value, "vat3")}

                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
             
            
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"VAT"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.vat4}
                          onChange = {(evt) => handleChange(evt.target.value, "vat4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Escalation"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.escalation1}
                          onChange = {(evt) => handleChange(evt.target.value, "escalation1")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
              
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Escalation"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.escalation2}
                          onChange = {(evt) => handleChange(evt.target.value, "escalation2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Escalation"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.escalation3}
                          onChange = {(evt) => handleChange(evt.target.value, "escalation3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Escalation"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.escalation4}
                          onChange = {(evt) => handleChange(evt.target.value, "escalation4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
               
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Contingency"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.contingency1}
                          onChange = {(evt) => handleChange(evt.target.value, "contingency1")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Contingency"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.contingency2}
                          onChange = {(evt) => handleChange(evt.target.value, "contingency2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Contingency"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.contingency3}
                          onChange = {(evt) => handleChange(evt.target.value, "contingency3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
               
                <Grid className={classes.blackBorder} container  xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"Contingency"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.contingency4}
                          onChange = {(evt) => handleChange(evt.target.value, "contingency4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"TOTAL"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.total1}
                          onChange = {(evt) => handleChange(evt.target.value, "total1")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"TOTAL"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.total2}
                          onChange = {(evt) => handleChange(evt.target.value, "total2")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"TOTAL"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.total3}
                          onChange = {(evt) => handleChange(evt.target.value, "total3")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"TOTAL"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.total4}
                          onChange = {(evt) => handleChange(evt.target.value, "total4")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                     />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"TOTAL AUTHORISED EXPENDITURE"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.totalauthorisedexpenditure}
                          onChange = {(evt) => handleChange(evt.target.value, "totalauthorisedexpenditure")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={"REVISED AUTHORISED FEC(after approval)"}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          value={urFormState.revisedauthorisedfec}
                          onChange = {(evt) => handleChange(evt.target.value, "revisedauthorisedfec")}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>
                <Grid className={classes.blackBorder} container xs={3}>
                  <Input
                          type={'text'}
                          placeholder={""}
                          style={{minWidth:'190px'}}
                          className={classes.input}
                          color={'secondary'}
                          //value={sensorFormState.deviceNo}
                      />
                </Grid>

        </FormGroup>   
        </Grid>       
    </Grid>
</FormGroup>
       

<FormGroup row> 
<Grid container spacing={2} style={{ marginTop: 20 }} >  
    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#ffffE0"}}>
        <h4 className={classes.blackBorderTitle}>CHECK & RECOMMENDED -please print name in addition to signature</h4>            
    </Grid>
<Grid className={classes.blackBorder} item xs={12}>

<FormGroup row>
<Grid item xs={12}>
        <h5 className={classes.blackBorderTitle}>ORIGINATOR SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <Input type='text'
    options={(users && users.length) ? users : []}
    label="Originator"
    val={urFormState.originator}
    onChange = {(evt) => handleChange(evt.target.value, "originator")}
     styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>


<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
    <IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("originatorSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4} >
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date1}
        handleChange={newDate => handleChange(newDate, "date1")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
</Grid>

<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>SECTION HEAD SIGNATURE</h5>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>                              
<Input type='text'
        options={ (users && users.length) ? users: []}
        label="Section Head"
        val={urFormState.sectionHead}
        onChange = {(evt) => handleChange(evt.target.value, "sectionHead")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>
<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("sectionHeadSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
     <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label= "Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date2}
        handleChange={newDate => handleChange(newDate, "date2")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
 </Grid>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>HEAD OF DEAPRTMENT SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
<Input type='text'
    options={(users && users.length) ? users : []}
    label="Head of Department"
    val={urFormState.HoD}
    onChange = {(evt) => handleChange(evt.target.value, "HoD")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("HoDSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4} >
<DateTimeSelector
    inputStyle={{ minWidth: 250 }}
    label="Date"
    dateFormat={"yyyy-MM-dd"}
    value={urFormState.date3}
    handleChange={newDate => handleChange(newDate, "date3")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
/>
</Grid>

<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>ASSISTANT ACCOUNTANT SIGNATURE</h5>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>                              
<Input type='text'
        options={ (users && users.length) ? users: []}
        label="Assistant Accountant"
        val={urFormState.assistantAccountant}
        onChange = {(evt) => handleChange(evt.target.value, "assistantAccount")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>
<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("assistantAccountantSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4} >
     <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label= "Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date4}
        handleChange={newDate => handleChange(newDate, "date4")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
 </Grid>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>COST ACCOUNTANT SIGNATURE</h5>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>                              
    <Input type='text'
        options={ (users && users.length) ? users: []}
        label="Cost Accountant"
        val={urFormState.costAccountant}
        onChange = {(evt) => handleChange(evt.target.value, "costAccountant")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>
<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("costAccountantSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date5}
        handleChange={newDate => handleChange(newDate, "date5")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
</Grid>

</FormGroup>
</Grid>               

</Grid>
</FormGroup>

<FormGroup row> 
<Grid container spacing={2} style={{ marginTop: 25 }} >  
    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#ffffE0"}}>
        <h4 className={classes.blackBorderTitle}>APPROVAL</h4>            
    </Grid>


<Grid className={classes.blackBorder} item xs={12}>

<FormGroup row>
<Grid item xs={12}>
        <h5 className={classes.blackBorderTitle}>FINANCE MANAGER SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    <Input type='text'
    options={(users && users.length) ? users : []}
    label="Finance Manager"
    val={urFormState.financeManager}
    onChange = {(evt) => handleChange(evt.target.value, "financeManager")}
     styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("financeManagerSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date6}
        handleChange={newDate => handleChange(newDate, "date6")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
</Grid>

<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>COMMERCIAL MANAGER SIGNATURE</h5>
</Grid>

<Grid className={classes.blackBorder} item xs={4}>                              
<Input type='text'
        options={ (users && users.length) ? users: []}
        label="Commercial Manager"
        val={urFormState.commercialManager}
        onChange = {(evt) => handleChange(evt.target.value, "commercialManager")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>
<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
<IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("commercialManagerSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
    </IconButton>
</Grid>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
     <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label= "Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date7}
        handleChange={newDate => handleChange(newDate, "date7")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
 </Grid>
<Grid item xs={12}>
    <h5 className={classes.blackBorderTitle}>GENERAL MANAGER SIGNATURE</h5>
</Grid>
<Grid className={classes.blackBorder} item xs={4}>
    
<Input type='text'
    options={(users && users.length) ? users : []}
    label="General Manager"
    val={urFormState.generalManager}
    onChange = {(evt) => handleChange(evt.target.value, "generalManager")}
    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>
<Grid item xs={6} style={{ textAlign: 'right'}}>
      <IconButton 
         variant={'outlined'}
         onClick={() => handleOpenSignPanModal("generalManagerSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
     </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4} >
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date8}
        handleChange={newDate => handleChange(newDate, "date8")}
        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
     />
</Grid>


</FormGroup>
</Grid>               

</Grid>
</FormGroup>
                    

<FormGroup row> 
<Grid container spacing={2} style={{ marginTop: 20 }} >  
    <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#ffffE0"}} >
        <h4 className={classes.blackBorderTitle}>AUTHORISATION</h4>            
    </Grid>
<Grid className={classes.blackBorder} item xs={12}>

<FormGroup row>
<Grid item xs={12}>
        <Input type='text'
         options={(users && users.length) ? users : []}
         placeholder="Comments"
         val={urFormState.comments}
         onChange = {(evt) => handleChange(evt.target.value, "comments")}
         styleOverrides={{ minWidth: '100%', marginRight: 2 }}
       />
</Grid>


<Grid className={classes.blackBorder} item xs={4} >
    <Input type='text'
    options={(users && users.length) ? users : []}
    placeholder="DEPUTY MANAGING DIRECTOR-NAME"
    val={urFormState.deputyManagingDirector}
    onChange = {(evt) => handleChange(evt.target.value, "deputyManagingDirector")}
     styleOverrides={{ minWidth: '100%', marginRight: 2 }}
    />
</Grid>

<Grid className={classes.blackBorder} container  xs={4}>

    <Grid item xs={6} style={{ textAlign: 'right'}}>
       <IconButton 
        variant={'outlined'}
        onClick={() => handleOpenSignPanModal("deputyManagingDirectorSign")}
         >Signature<img src={SignIcon} width="26" height={26} alt=""/>
       </IconButton>
</Grid>
</Grid>

<Grid className={classes.blackBorder} item xs={4} >
    <DateTimeSelector
        inputStyle={{ minWidth: 250 }}
        label="Date"
        dateFormat={"yyyy-MM-dd"}
        value={urFormState.date9}
        handleChange={newDate => handleChange(newDate, "date9")}
        styleOverrides={{ minWidth: '100%',  marginRight: 2 }}
     />
</Grid>



</FormGroup>
</Grid>               

</Grid>
</FormGroup>


 </DialogContent>

<DialogActions>
    <Grid container justify={'space-between'}>
    <Button
     variant={'outlined'}
     onClick={() => {
     setURFormState({ ...urInitFields });
     handleClose();
}}
>

{t('cancel.label')}
</Button>

<ButtonComponent
t={t}
onClick={() => {
    // if(!urFormState.userSubject){
    //     Message.error(REQUIRED_ERROR);
    //     return null;
    // }
    handleFundsApplicationSubmit(urFormState);
}}
loading={modalState.btnLoading}
/>
</Grid>
</DialogActions>
</Dialog>

    );
}

FundsApplicationFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleFundsApplicationSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(FundsApplicationFormModal);