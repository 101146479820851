import React, { useState } from 'react';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_PASSWORD } from './mutations';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout';
import { colors } from '../../theme/colors';
import Message from '../../components/Message';
import { REQUIRED_ERROR } from '../../utils/constants';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Paths } from '../../routes/routePaths';
import CircularProgress from '@material-ui/core/CircularProgress';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        variant={'contained'}
        size={'large'}
        style={{ ...marginGenerator('mt-24') }}
        color={'primary'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('resetPassword.label')}
        </Button>
    );
}

function ChangePassword({ t }) {
    
    const history = useHistory();

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCrntPassword, setShowCrntPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    let [breadcrumbList, setBreadcrumbList] = useState([
        { label: 'home.label', link: Paths.Dashboard },
        { label: 'resetPassword.label', link: Paths.ChangePassword }
    ]);
    /* 
    Reset Password
    */
   const [changePassword] = useMutation(CHANGE_PASSWORD, {
        onError: error => {
            setLoading(false);
        },
        onCompleted: data => {
            setLoading(false);
            if(data.changePassword && data.changePassword.success){
                Message.success('Your password successfully updated.');
                history.push(Paths.Dashboard);
            }else{
                Message.error('Current password is not correct.');
                setCurrentPassword('');
            }
        }
    });

    return (
        <Layout breadcrumbList={breadcrumbList} contentStyle={{ maxWidth: '400px', ...marginGenerator('mt-162') }}>
            <Header
                title={t('resetPassword.label')}
                titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
                subText={t('resetPassSub.label')}
                subtitleVariant={'h2'}
                subtitleStyleOverride={{ color: colors.grey[400], padding: 0, fontSize:'14px' }}
            />
            <Grid container style={{ ...marginGenerator('mt-24') }}>
                <Input
                value={currentPassword}
                type={showCrntPassword ? 'text' : 'password'}
                placeholder={`${t('currentPassword.label')} *`}
                style={{
                    width: '100%',
                    ...paddingGenerator('pr-12')
                }}
                color={'secondary'}
                onChange={evt => setCurrentPassword(evt.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            style={{
                            ...paddingGenerator('pt-8'),
                            ...paddingGenerator('pr-4')
                            }}
                            onClick={() => setShowCrntPassword(!showCrntPassword)}>
                            {showCrntPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                />
                <Input
                value={password}
                type={showPassword ? 'text' : 'password'}
                placeholder={`${t('newPassword.label')} *`}
                style={{
                    width: '100%',
                    ...marginGenerator('mt-24'),
                    ...paddingGenerator('pr-12')
                }}
                color={'secondary'}
                onChange={evt => setPassword(evt.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            style={{
                            ...paddingGenerator('pt-8'),
                            ...paddingGenerator('pr-4')
                            }}
                            onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                />
                <Input
                value={confirmPassword}
                type={showConfPassword ? 'text' : 'password'}
                placeholder={`${t('reNewPassword.label')} *`}
                style={{
                    width: '100%',
                    ...marginGenerator('mt-24'),
                    ...paddingGenerator('pr-12')
                }}
                color={'secondary'}
                onChange={evt => setConfirmPassword(evt.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            style={{
                            ...paddingGenerator('pt-8'),
                            ...paddingGenerator('pr-4')
                            }}
                            onClick={() => setShowConfPassword(!showConfPassword)}>
                            {showConfPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                />
                <ButtonComponent 
                t={t} 
                onClick={() => {
                    if (!currentPassword || !password || !confirmPassword) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                    }
                    if(password !== confirmPassword){
                        Message.error("New password and confirm password must be same");
                        return null;
                    }
                    setLoading(true);
                    return changePassword({
                        variables: {
                            currentPassword,
                            password
                        }
                    })
                }}
                loading={loading} />
            </Grid>
        </Layout>
    );
}

export default withTranslation()(ChangePassword);
