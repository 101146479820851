import React, { useState } from 'react';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography
} from '@material-ui/core';
import { deletePod } from '../../../../services/podService';
import Message from '../../../../components/Message';

const DeletePodConfirmPopup = ({
  openDeleteConfirmPopup,
  setOpenDeleteConfirmPopup,
  GetPodList,
  rowData,
  classes
}) => {
  const handleDelete = async e => {
    e.preventDefault();
    const res = await deletePod(rowData?._id);
    if (res?.data?.statusCode === 200) {
      setOpenDeleteConfirmPopup(false);
      Message.success('Pod Deleted');
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else {
        Message.error('Something went wrong');
      }
      setOpenDeleteConfirmPopup(false);
    }
    await GetPodList();
  };

  return (
    <>
      <Popup
        title="Delete POD"
        openPopup={openDeleteConfirmPopup}
        setOpenPopup={setOpenDeleteConfirmPopup}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Are you sure you want to remove this POD?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => setOpenDeleteConfirmPopup(false)}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default DeletePodConfirmPopup;
