import React from 'react';
import CustomTabs from '../../../../../../components/CustomTabs';
import ChangeRequestPanel from '../../components/ChangeRequestPanel';
import UserRequestPanel from '../../components/UserRequestPanel';
import MeetingRequestPanel from '../../components/MeetingRequestPanel';
import BriefingPanel from '../../components/BriefingPanel';
import BackupControlPanel from '../../components/BackupControlPanel';
import ServerRoomInspectionPanel from '../../components/ServerRoomInspectionPanel';
import IncidentIsuuePanel from '../../components/IncidentIsuuePanel';
import ThirdPartyAccessRequstPanel from '../../components/ThirdPartyAccessRequstPanel';
import RemoteAccessPanel from '../../components/RemoteAccessPanel';
import LaptopIssuePanel from '../../components/LaptopIssuePanel';
import ModemIsuuePanel from '../../components/ModemIsuuePanel';
import { USER_ROLES } from '../../../../../../utils/constants';

const InformationMangementPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex,
    departments
}) => {

    const role = localStorage.getItem('role');
    let tabList = [];
    if(data){              
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Meeting",
                children: (
                    <>
                        <MeetingRequestPanel
                            t={t}
                            data={data}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            subscriptionUserData={subscriptionUserData}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Briefing",
                children: (
                    <>
                        <BriefingPanel
                            t={t}
                            data={data}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            subscriptionUserData={subscriptionUserData}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Change Request",
                children: (
                    <>
                        <ChangeRequestPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                        />
                    </>
                )
            });
            tabList.push({
                label: "User Request",
                children: (
                    <>
                        <UserRequestPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Backup Control",
                children: (
                    <>
                        <BackupControlPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Server Room",
                children: (
                    <>
                        <ServerRoomInspectionPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Incident Isuue",
                children: (
                    <>
                        <IncidentIsuuePanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                        />
                    </>
                )
            });
            tabList.push({
                label: "ThirdParty Access",
                children: (
                    <>
                        <ThirdPartyAccessRequstPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Remote Access",
                children: (
                    <>
                        <RemoteAccessPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Laptop Issue",
                children: (
                    <>
                        <LaptopIssuePanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Modem Isuue",
                children: (
                    <>
                        <ModemIsuuePanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }
    }

    return (
        <CustomTabs 
            activeIndex={activeIndex} 
            tabs={tabList}
        />
    )
}

export default InformationMangementPanel