import React, { useContext } from 'react';
import { get } from "lodash-es";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../utils';
import Message from "../../../../../../../components/Message";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import { ShayonaCementsContext } from '../../../../../../../contexts/ShayonaCementsProvider';
import {
    Paths
  } from '../../../../../../../routes/routePaths';
import { getParam } from '../../../../../../../utils';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="primary"
        onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <CloseIcon color="secondary"/> }
        </Button>
    );
}

const ShayonaAlertsItem = ({
    key,
    t,
    data,
    handleEnableAlertFeedBacksDialogue
}) => {
    const commonClasses = commonStyles();
    const id = getParam()
    //console.log('id', id);
    //console.log('Paths', Paths);
    // const {
    //   tripsStatusOption
    // } = useContext(ShayonaCementsContext);

    const alertsStatusNames = {
        entry:'Entry',
        slip_printed:'Slip Printed',
        tare_weighed:'Tare Weighted',
        loading_done:'Loading Done',
        exited:'Exited'
    };
    
  return (
    <TableRow key={data.id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? data.truck_number : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? dateFormatterLLL(data.detected_on) : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {/* {data? alertsStatusNames[data.status]: '-'} */}
                  {data ? data.empty_weight : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data? data.allowed_bags: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'} color={data.bags_loaded>data.allowed_bags ? "secondary" : "" }>
                    {data? data.bags_loaded: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'} color={data.gross_weight> data.ideal_weight ? "secondary" : ""}>
                    {data ?data.gross_weight: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data ?data.ideal_weight: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data ?data.diff_weight: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data ?data.diff_bags: '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
               { (data?.wrong_alert_comment === '-' || data?.wrong_alert_comment === null) ? (
                     <>
                        {/* <Link
                        to={{
                            pathname: `${Paths.Subscriptions}/active/details/:${id}?alertId=${data.alert_id}`
                        }}
                        onClick={() =>handleEnableAlertFeedBacksDialogue('approver',data)}
                        >
                            {<CircularProgress size={19} />}
                        </Link> */}
                        <IconButton
                            onClick={() =>handleEnableAlertFeedBacksDialogue('record',data)}
                        >
                          <CheckIcon 
                                color="success"
                            />
                        </IconButton>

                            <ButtonComponent 
                                t={t}
                                loading={false}
                                onClick={() =>handleEnableAlertFeedBacksDialogue('feedBack',data)}
                            />
                            </>
                    ): (
                        <>
                        <Button
                            color="primary"
                            variant={'outlined'}
                            onClick={()=> handleEnableAlertFeedBacksDialogue('show',data)}
                        >
                            View
                        </Button>
                        {
                            data.approval_status === 'sent_for_approval' ? 
                            <CircularProgress size={19} /> :''
                        }
                    </>
                        )
                }
            </Grid>
        </Grid>
    </TableCell>
</TableRow> 
  )
}

export default ShayonaAlertsItem