import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Message from "../../../../components/Message";
import {REQUIRED_ERROR} from "../../../../utils/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useMutation} from "@apollo/react-hooks";
import {CONFIGURE_UCPE, DELETE_CONFIGURE_UCPE_INTERFACE} from "../../mutations";

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    input: {
        minWidth: '224px'
    }
}));
function valuetext(value) {
    return `${value}°C`;
}
function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const types = [
    {
        id: 'Management',
        name: 'Management'
    },
    {
        id: 'Bridge-TAP',
        name: 'Bridge-TAP'
    },
    {
        id: 'Bridge-DPDK',
        name: 'Bridge-DPDK'
    },
    {
        id: 'sr-iov',
        name: 'SRIOV'
    }
];
const connections = [
    {
        id: 'VNF_MANAGEMENT',
        name: 'VNF Management'
    },
    {
        id: 'INBAND_MANAGEMENT',
        name: 'InBand Management'
    },
    {
        id: 'DATA',
        name: 'Data'
    }
];
const adapters = [
    {
        id: 2,
        name: 2
    },
    {
        id: 3,
        name: 3
    },
    {
        id: 4,
        name: 4
    },
    {
        id: 5,
        name: 5
    },
    {
        id: 6,
        name: 6
    }
];
function CreateUcpeConfiguration({
                                     t,
                                     open,
                                     networkInterfaceList,
                                     ucpeConfigurationLoadingModal,
                                     device_id,
                                     data,
                                     ucpeInterfaceList,
                                     handleCreateUcpeConfiguraton,
                                     handleCheckInterfaceExits,
                                     handleClose,
                                     modalState
                                 }) {
    const organization_id = data.organizationId
    const interfaceInitField = {
        en0: '',
        type: '',
        editable: false,
        bridgeName: '',
        typeOfConnection: '',
        nic: '',
        vnfIpAddress: '',
        managementIpAddress: '',
        port: '',
        communication: '',
        number_of_adaptors: ''
    };
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setInterfaceState([interfaceInitField]);
            handleClose();
        }
    }, [modalState]);
    const [interfaceState, setInterfaceState] = useState([
        {...interfaceInitField}
    ]);
    const [
        removeSelectedInterfaceField,
        setSelectedRemoveInterfaceField
    ] = useState([]);
    const [removeFieldState, setRemoveFieldState] = useState({interface: []});
    const [removeFieldInterfaceNameState, setRemoveFieldInterfaceNameState] = useState({interface_name: []});
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        setInterfaceState([]);
        if (ucpeInterfaceList && ucpeInterfaceList.length > 0) {
            ucpeInterfaceList.map((item, index) => {
                item.editable = true;
                setInterfaceState(oldArray => [...oldArray, {...item}]);
            })
        } else {
            setInterfaceState([interfaceInitField]);
        }
    }, [ucpeInterfaceList]);
    const addNewInterfaceField = () => {
        setInterfaceState(oldArray => [...oldArray, {...interfaceInitField}]);
    };
    // Delete Interface
    const [deleteDeviceInterface] = useMutation(DELETE_CONFIGURE_UCPE_INTERFACE, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: data => {
            if (data.deleteConfigureUcpeInterface.status) {
                handleClose();
                Message.success('Ucpe configuraton interface deleted successfully');
            }
        }
    });
    const removeInterfaceField = () => {
        console.log(removeFieldInterfaceNameState)
        if (window.confirm("Are you sure want to delete?")) {
            deleteDeviceInterface({
                variables: {
                    organisation_id: organization_id,
                    device_id: device_id,
                    interface_name: removeFieldInterfaceNameState.interface_name
                }
            })
            const updatedInterface = [...interfaceState];
            const updateRemoveState = {...removeFieldState};
            for (let index of updateRemoveState.interface) {
                index = parseInt(index);
                updatedInterface.splice(index, 1);
            }
            setInterfaceState(updatedInterface);
            const newRmState = {...removeFieldState};
            newRmState.interface = [];
            setRemoveFieldState(newRmState);
        }
    };
    const onHandleSelectInterfaceRow = (evnt, index, item) => {
        let val = evnt.target.value.toString();
        let updatedState = {...removeSelectedInterfaceField};
        updatedState[index] = evnt.target.checked;
        setSelectedRemoveInterfaceField(updatedState);
        if (evnt.target.checked) {
            setRemoveFieldState(prevState => ({
                ...prevState,
                interface: [...prevState.interface, val]
            }));
            setRemoveFieldInterfaceNameState(prevState => ({
                ...prevState,
                interface_name: [...prevState.interface_name, item.en0]
            }));
        } else {
            let prevRemoveInterfaceState = {...removeFieldInterfaceNameState};
            let elemIdx2 = prevRemoveInterfaceState.interface_name.indexOf(val);
            prevRemoveInterfaceState.interface_name.splice(elemIdx2, 1);
            setRemoveFieldInterfaceNameState(prevRemoveInterfaceState);
            let prevRemoveState = {...removeFieldState};
            let elemIdx = prevRemoveState.interface.indexOf(val);
            prevRemoveState.interface.splice(elemIdx, 1);
            setRemoveFieldState(prevRemoveState);
        }
    };
    const handleInterfaceChange = (value, idx, column) => {
        if (column === "en0") {
            handleCheckInterfaceExits({value, device_id});
        }
        const updatedInterface = [...interfaceState];
        updatedInterface[idx][column] = value;
        setInterfaceState(updatedInterface);
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ucpe-modal"
            aria-describedby="ucpe-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('interfaces.label')}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => {
                            addNewInterfaceField();
                        }}>
                        {t('add.label')} {t('interface.label')}
                    </Button>
                    <Button
                        color="secondary"
                        variant={'contained'}
                        style={{...marginGenerator('ml-20')}}
                        disabled={!removeFieldState.interface.length}
                        onClick={() => {
                            removeInterfaceField();
                            setSelectedRemoveInterfaceField([]);
                        }}>
                        {t('remove.label')}
                    </Button>
                </Grid>
                {!ucpeConfigurationLoadingModal ? (
                    interfaceState.map((val, idx) => {
                        return (
                            <React.Fragment key={`interface-${idx}`}>
                                <Grid
                                    key={`heading-${idx}`}
                                    item
                                    xs={12}
                                    style={{
                                        ...paddingGenerator('pl-5'),
                                        ...paddingGenerator('pl-0'),
                                        ...marginGenerator('mt-24')
                                    }}>
                                    <Typography variant="subtitle2" color="textPrimary">
                                        <Checkbox
                                            style={paddingGenerator('pl-0')}
                                            checked={removeSelectedInterfaceField[idx]}
                                            value={idx}
                                            onChange={event => {
                                                onHandleSelectInterfaceRow(event, idx, val);
                                            }}
                                        />
                                        {t('interface.label')} {idx + 1}
                                    </Typography>
                                </Grid>
                                <Grid key={`en0-${idx}`} style={marginGenerator('mt-24')}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].en0}
                                        disabled={interfaceState[idx].editable ? true : null}
                                        label={`${t('en0.label')} *`}
                                        handleChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'en0')
                                        }
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={networkInterfaceList}
                                    />
                                </Grid>
                                <Grid key={`type-${idx}`} style={marginGenerator('mt-24')}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].type}
                                        disabled={interfaceState[idx].editable ? true : null}
                                        label={`${t('type.label')} *`}
                                        handleChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'type')
                                        }
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={types ? types : []}
                                    />
                                </Grid>
                                {interfaceState[idx].type === 'Bridge-DPDK' ||
                                interfaceState[idx].type === 'Bridge-TAP' ? (
                                    <React.Fragment>
                                        <Grid
                                            key={`bridgeName-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <Input
                                                value={interfaceState[idx].bridgeName}
                                                type={'text'}
                                                placeholder={`${t('bridgeName.label')}*`}
                                                style={{width: '100%'}}
                                                color={'secondary'}
                                                onChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'bridgeName'
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            key={`typeOfConnection-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <OutlinedSelect
                                                val={interfaceState[idx].typeOfConnection}
                                                label={`${t('typeOfConnection.label')} *`}
                                                handleChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'typeOfConnection'
                                                    )
                                                }
                                                selectStyle={{...paddingGenerator('p-8')}}
                                                styleOverrides={{width: '100%', marginRight: 0}}
                                                options={connections ? connections : []}
                                            />
                                        </Grid>
                                        {/*{interfaceState[idx].typeOfConnection ===
                                    'INBAND_MANAGEMENT' ? (
                                        <React.Fragment>
                                            <Grid
                                                key={`managementIpAddress-${idx}`}
                                                style={marginGenerator('mt-24')}>
                                                <Input
                                                    value={interfaceState[idx].managementIpAddress}
                                                    type={'text'}
                                                    placeholder={`${t('managementIpAddress.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    onChange={evt =>
                                                        handleInterfaceChange(
                                                            evt.target.value,
                                                            idx,
                                                            'managementIpAddress'
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                key={`port-${idx}`}
                                                style={marginGenerator('mt-24')}>
                                                <Input
                                                    value={interfaceState[idx].port}
                                                    type={'text'}
                                                    placeholder={`${t('port.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                    onChange={evt =>
                                                        handleInterfaceChange(
                                                            evt.target.value,
                                                            idx,
                                                            'port'
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )}*/}
                                        {/*{interfaceState[idx].typeOfConnection === 'DATA' ? (
                                        <Grid
                                            key={`communication-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <OutlinedSelect
                                                val={interfaceState[idx].communication}
                                                label={`${t('communication.label')} *`}
                                                handleChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'communication'
                                                    )
                                                }
                                                selectStyle={{...paddingGenerator('p-8')}}
                                                styleOverrides={{width: '100%', marginRight: 0}}
                                                options={[
                                                    {id: 'Communication', name: 'Communication'}
                                                ]}
                                            />
                                        </Grid>
                                    ) : (
                                        ''
                                    )}*/}
                                    </React.Fragment>
                                ) : interfaceState[idx].type === 'sr-iov' ? (
                                    <Grid key={`adapters-${idx}`} style={marginGenerator('mt-24')}>
                                        <OutlinedSelect
                                            val={interfaceState[idx].number_of_adaptors}
                                            disabled={interfaceState[idx].editable ? true : null}
                                            label={`${t('adapters.label')} *`}
                                            handleChange={evt =>
                                                handleInterfaceChange(evt.target.value, idx, 'number_of_adaptors')
                                            }
                                            selectStyle={{...paddingGenerator('p-8')}}
                                            styleOverrides={{width: '100%', marginRight: 0}}
                                            options={adapters}
                                        />
                                    </Grid>
                                ) : (
                                    ''
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <>
                        {'Loading...'}
                        <LinearProgress color="primary"
                                        style={{top: '0px', left: '0px', position: 'absolute', width: '100%'}}/>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            console.log('interfaceState', interfaceState);
                            if (interfaceState[0].en0 == '') {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateUcpeConfiguraton(interfaceState);
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateUcpeConfiguration.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateUcpeConfiguration);
