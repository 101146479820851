import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InternalNavbar from '../InternalNavbar';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Sidebar from '../Sidebar'
import Footer from '../Footer'
// import {DomainContext} from '../../containers/OnBoarding/Login'

const useStyles = makeStyles(theme => ({
  content: {
    // display: 'inline-block',
    width: 'calc(100% - 260px)',
    padding: '15px'
  },
  mainSec: {
    marginTop: '152px',
    display: 'flex'
  },
  childContent: {
    padding: '25px',
    paddingLeft: '35px'
  }
}));

function InternalLayout({ children, breadcrumbList, contentStyle, headerNotifications, handleSetImageModalSrc, handleNotificationClick }) {
  const classes = useStyles();
//   const domain = useContext(DomainContext)
  return (
    <>
      <InternalNavbar type='internal' breadcrumbList={breadcrumbList} headerNotifications={headerNotifications} handleSetImageModalSrc={handleSetImageModalSrc ? handleSetImageModalSrc : null} handleNotificationClick={handleNotificationClick} />
      <div className={classes.mainSec}>
        <Sidebar />
        <Container
          className={classes.content}
          style={contentStyle}
          disableGutters={window.innerWidth > 1024}>
          <>
          <div className={classes.childContent}>{children}</div>
          </>
        </Container>
      </div>
      <Footer />
    </>
  );
}

InternalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbList: PropTypes.array.isRequired,
  contentStyle: PropTypes.object
};

export default InternalLayout;
