import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    bottom: 0,
    height: '85px',
    background: '#3A3A3A',
    width: '100%',
    // borderTop: `8px solid ${theme.palette.secondary.main}`,
  }
}));

function Footer({ t }) {
  const classes = useStyles();

  return (
    <Grid
        item
        xs
        container
        direction="column"
        spacing={0}
        justify="center"
        alignItems={'center'}
        className={classes.root}>
        <Grid item>
            <Typography variant="body2" style={{ color: '#C4C4C3' }}>
            {'\u00A9'} {t('copyrightInq.label')} {moment().format('YYYY')}. All rights reserved
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body2" style={{ color: '#C4C4C3' }}>
              inq. Head Office: 1 Cyber City 6th Floor, Tower A, Ebene, Mauritius.
            </Typography>
        </Grid>
    </Grid>
  );
}

export default withTranslation()(Footer);
