import React, {useState} from 'react';
import CustomTabs from '../../../../components/CustomTabs';
import { withTranslation } from 'react-i18next';
import Dashboard from './components/Dashboard';
import Bids from './components/Bids';
import Quotes from './components/Quotes';

const RFPManagementService = ({
    t, 
    data,
    activeIndex,
}) => {
    
    let tabList = [];
    if(data){
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <>
                        <Dashboard
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                        />
                    </>
                )
            },
            {
                label: "Bids",
                children: (
                    <>
                        <Bids
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                        />
                    </>
                )
            },
            {
                label: "Quotes",
                children: (
                    <>
                        <Quotes
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                        />
                    </>
                )
            }
        ];
    }
    
    return (
        <>
            <CustomTabs 
                activeIndex={activeIndex} 
                tabs={tabList}
            />
      
        </>
    );
};
export default withTranslation()(React.memo(RFPManagementService));
