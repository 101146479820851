import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
    formatName,
    getParam,
    getScheduleTimeByTimeZone
} from '../../../../utils';
import {
    SUBSCRIPTION_STATUS,
    USER_ROLES,
    NODE_MANAGEMENT_TYPE,
    EDGE_AI_LIVE_CAM_URL,
    OBJECTS_DETECTION_LIST,
    LIVE_CAM_STREAM_URL
} from '../../../../utils/constants';
import {
    CREATE_ML_TEMPLATE,
    CREATE_NOTIFICATION_TEMPLATE,
    CREATE_PLACE,
    CREATE_CAMERA,
    UPDATE_ML_TEMPLATE,
    UPDATE_NOTIFICATION_TEMPLATE,
    UPDATE_PLACE,
    UPDATE_CAMERA,
    CREATE_NODE,
    UPDATE_SUBSCRIPTION,
    UPDATE_LIVE_CAMERA_URL,
    DELETE_EDGEAI_SUBSCRIPTION,
    CREATE_TRANSECTION
} from '../../mutations';
import { useHistory } from 'react-router';
import CustomTabs from '../../../../components/CustomTabs';
import TicketPanel from '../../../ServiceTickets/components/TicketPanel';
import ImageModal from '../../../../components/ImageModal';
import Message from '../../../../components/Message';
import DashboardPanel from '../../components/DashboardPanel';
//EDGE AI Service Modules
import {
    GET_ML_TEMPLATE_LIST,
    GET_ML_TEMPLATE_BY_ID,
    GET_NOTIFICATION_TEMPLATE_LIST,
    GET_PLACE_LIST,
    GET_NODE_LIST,
    GET_VPNS_LIST,
    GET_LINE_CROSS_OBJECT,
    GET_EDGE_AI_TYPES,
    GET_EDGE_AI_SETTINGS
} from '../../queries';
import EdgeAIConfigurationPanel from '../../components/EdgeAIConfigurationPanel';
import CreateMLTemplateModal from '../../components/CreateMLTemplateModal';
import UpdateMLTemplateModal from '../../components/UpdateMLTemplateModal';
import CreateNotificationTemplateModal from '../../components/CreateNotificationTemplateModal';
import CreatePlaceTemplateModal from '../../components/CreatePlaceTemplateModal';
import CreateCameraTemplateModal from '../../components/CreateCameraTemplateModal';
import EdgeAIReportPanel from '../../components/EdgeAIReportPanel';
import EdgeAIAnalyticsPanel from '../../components/EdgeAIAnalyticsPanel';
import EdgeAIAnalyticsURLModal from '../../components/EdgeAIAnalyticsPanel/EdgeAIAnalyticsURLModal';
import EdgeAILiveCamPanel from '../../components/EdgeAILiveCamPanel';
import CreateLiveCamModal from '../../components/EdgeAILiveCamPanel/CreateLiveCamModal';
import UpdateCameraModal from '../../components/UpdateCameraModal';
import SelectAITypeModal from '../../components/SelectAITypeModal';
import CreateNodeModal from '../../components/CreateNodeModal';
import EdgeAISettingsPanel from '../../components/EdgeAISettingsPanel';
import BillingPanel from '../../components/BillingPanel';
import DeleteConfirmBox from '../../components/DeleteConfirmBox';
import EdgeAIEventImagesViewers from '../../components/EdgeAIEventImagesViewers';
import { withTranslation } from 'react-i18next';
import CreateTransectionModal from '../../components/CreateTransectionModal';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../../../../components/TabPanel';
import { MdOutlineSpaceDashboard, MdOutlineContactSupport, MdOutlineEvent, MdOutlinePreview, MdOutlineAnalytics, MdSettings, MdOndemandVideo } from "react-icons/md";
import { FaDollarSign, FaCamera, FaUserCog } from "react-icons/fa";
import EdgeAIDemosPanel from '../../components/EdgeAIDemosPanel';

const EdgeAIService = ({
    t,
    data,
    reloadSubscription,
    countryCodes,
    handleChangeActiveNotiTab,
    activeIndex,
    activeNotificationTab,
    serviceTickets,
    filterListRecentTickets,
    handleTicketItemClick,
    handleTicketMenuItemClick,
    handleSearchRecentTickets,
    recentTicketsSearched,
    updatedRecentTickets,
    handleChangeActiveIndex,
    getTabList,
    selectedPath,
    subActiveIndex,
    demoVideosLoading,
    demoVideos
}) => {
    const id = getParam();
    const history = useHistory();
    const role = localStorage.getItem('role');
    const theme = useTheme();
    const [createMLTemplateModal, setCreateMLModalState] = useState(false);
    const [updateMLTemplateModalState, setUpdateMLTemplateModalState] = useState(
        false
    );
    const [
        createNotificationTemplateModal,
        setCreateNotificationModalState
    ] = useState(false);
    const [createPlaceTemplateModal, setCreatePlaceModalState] = useState(false);
    const [createCameraTemplateModal, setCreateCameraModalState] = useState(
        false
    );
    const [createNodeModalState, setCreateNodeModalState] = useState(false);
    const [updateCameraModalState, setUpdateCameraModalState] = useState(false);
    const [imageModal, setImageModalState] = useState(false);
    const [imageModalSrc, setImageModalSrcState] = useState('');
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [updateModalState, setUpdateModalState] = useState({
        mlTemplate: {},
        notification: {},
        place: {},
        camera: {}
    });
    const [updateMlTemplateLoading, setupdateMlTemplateLoading] = useState(false);
    const [aiTypeModalState, setAiTypeModalState] = useState(false);
    const [reloadDataState, setReloadDataState] = useState('');
    const [selectePlaceList, setSelectePlaceList] = useState([]);
    const [selectMlTemplateList, setSelectMlTemplateList] = useState([]);
    const [selectNotiTemplateList, setSelectNotiTemplateList] = useState([]);
    const [selecteNodeList, setSelecteNodeList] = useState([]);
    const [VPNSList, setVPNSList] = useState([]);
    const [lineCrossObjectState, setLineCrossObjectState] = useState(null);
    const [lineCrossDataLoading, setLineCrossDataLoading] = useState(false);
    const [mlModalState, setMLModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [notificationModalState, setNotificationModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [placeModalState, setPlaceModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [cameraModalState, setCameraModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [edgeAIAnalyticsURLModal, setEdgeAIAnalyticsURLModal] = useState(false);
    const [analyticsModalState, setAnalyticsModalState] = useState({
        url: '',
        btnLoading: false,
        clearData: false
    });
    const [createLiveCamModal, setCreateLiveCamModal] = useState(false);
    const [liveCamModalState, setLiveCamModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [nodeModalState, setNodeModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [transectionModalState, setTransectionModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [createTransectionModalState, setCreateTransectionModalState] = useState(false);
    const [selectedAiType, setSelectedAiType] = useState('');
    const [deleteType, setDeleteType] = useState('');
    const [deleteEdgeAISubLoading, setDeleteEdgeAISubLoading] = useState(false);
    const [aiTypes, setAITypes] = useState([]);
    const [providerTypes, setProviderTypes] = useState(null);
    const [multiObjects, setMultiObjects] = useState([]);
    const [edgeAIActions, setEdgeAIActions] = useState(null);
    const [edgeAILiveCamURL, setEdgeAILiveCamURL] = useState(EDGE_AI_LIVE_CAM_URL);
    const [edgeAITagFilters, setEdgeAITagFilters] = useState(null);
    const [edgeAICameraFps, setEdgeAICameraFps] = useState([]);
    const [edgeAICameraResolutions, setEdgeAICameraResolutions] = useState([]);
    const [edgeAIFrequencies, setEdgeAIFrequencies] = useState([]);
    const [accidentDetectionObj, setAccidentDetectionObj] = useState([]);
    const [customDetectionObjects, setCustomDetectionObjects] = useState([]);

    React.useEffect(() => {
        let orgFilter = {
            variables: {
                organization: data.getSubscriptionById.organizationId,
                search: '',
                page: 1,
                limit: 20
            }
        };
        let orgLimitFltr = {
            variables: {
                organization: data.getSubscriptionById.organizationId,
                search: '',
                page: 1,
                limit: 500
            }
        }
        getNodeList(orgFilter);
        getPlaceList(orgFilter);
        getMLTemplateList(orgLimitFltr);
        getNotificationTemplateList(orgLimitFltr);
        getVpnsList(orgLimitFltr);

        let orgId = data.getSubscriptionById.organizationId;
        if (orgId === "1638263571872650" || orgId === "1612189967185530") {
            handleChangeActiveIndex(6);
        }
        localStorage.removeItem('timeZoneDetails');
    }, []);

    const { data: edgeAITypeData } = useQuery(GET_EDGE_AI_TYPES, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            let aiTypeList = data.getAllEdgeAITypes.map(itm => {
                itm.id = itm.type;
                return itm;
            });
            setAITypes(aiTypeList);
        }
    });

    const { data: edgeAISettingsData } = useQuery(GET_EDGE_AI_SETTINGS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getEdgeAISettings) {
                let settingData = data.getEdgeAISettings;
                setProviderTypes(settingData.providerTypes ? settingData.providerTypes : null);
                setMultiObjects(settingData.multiobjects ? settingData.multiobjects : []);
                setEdgeAIActions(settingData.actions ? settingData.actions : []);
                setEdgeAITagFilters(settingData.tagsFilter ? settingData.tagsFilter : null);
                setEdgeAICameraFps(settingData.cameraFps ? settingData.cameraFps : []);
                setEdgeAICameraResolutions(settingData.cameraResolutions ? settingData.cameraResolutions : []);
                setEdgeAIFrequencies(settingData.frequencies ? settingData.frequencies : []);
                setEdgeAILiveCamURL(settingData.liveCamURL ? settingData.liveCamURL : EDGE_AI_LIVE_CAM_URL);
                setAccidentDetectionObj(settingData.accidentDetectionObj ? settingData.accidentDetectionObj : []);
                setCustomDetectionObjects(settingData.customDetectionObjects ? settingData.customDetectionObjects : OBJECTS_DETECTION_LIST);
            }
        }
    });

    /**
     * EDGE AI SERVICE QUERY/MUTATION
     */
    const [createNewMLTemplate] = useMutation(CREATE_ML_TEMPLATE, {
        onError: error => {
            setMLModalState({ btnLoading: false, clearData: false });

            Message.error(error);
        },
        onCompleted: data => {
            if (data.addMLTemplate.id) {
                setSelectedAiType('');
                setMLModalState({ btnLoading: false, clearData: true });
                Message.success('Machine learning template created successfully.');
                setReloadDataState('ml_template');
            }
        }
    });
    const [updateMLTemplate] = useMutation(UPDATE_ML_TEMPLATE, {
        onError: error => {
            Message.error(error);
            setMLModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            setMLModalState({ btnLoading: false, clearData: true });
            if (data.updateMLTemplate && data.updateMLTemplate.id) {
                Message.success('Machine learning template updated successfully.');
                setReloadDataState('ml_template');
            }
        }
    });
    const [createNotificationTemplate] = useMutation(
        CREATE_NOTIFICATION_TEMPLATE,
        {
            onError: error => {
                Message.error(error);
                setNotificationModalState({ btnLoading: false, clearData: false });
            },
            onCompleted: data => {
                setNotificationModalState({ btnLoading: false, clearData: true });
                if (data.addNotificationTemplate && data.addNotificationTemplate.id) {
                    Message.success('Notification template created successfully');
                    setReloadDataState('notification');
                }
            }
        }
    );
    const [updateNotificationTemplate] = useMutation(
        UPDATE_NOTIFICATION_TEMPLATE,
        {
            onError: error => {
                Message.error(error);
                setNotificationModalState({ btnLoading: false, clearData: false });
            },
            onCompleted: data => {
                setNotificationModalState({ btnLoading: false, clearData: true });
                if (data.updateNotificationTemplate && data.updateNotificationTemplate.id) {
                    Message.success('Notification template updated successfully');
                    setReloadDataState('notification');
                }
            }
        }
    );
    const [createPlace] = useMutation(CREATE_PLACE, {
        onError: error => {
            Message.success(error);
            setPlaceModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            if (data.addPlace.id) {
                setPlaceModalState({ btnLoading: false, clearData: true });
                Message.success('Place created successfully');
                setReloadDataState('places');
            }
        }
    });
    const [updatePlace] = useMutation(UPDATE_PLACE, {
        onError: error => {
            Message.success(error);
            setPlaceModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            setPlaceModalState({ btnLoading: false, clearData: true });
            Message.success('Place updated successfully');
            setReloadDataState('places');
        }
    });
    const [createCamera] = useMutation(CREATE_CAMERA, {
        onError: error => {
            setCameraModalState({ btnLoading: false, clearData: false });
            Message.error(error);
        },
        onCompleted: data => {
            if (data.addCamera && data.addCamera.success) {
                setCameraModalState({ btnLoading: false, clearData: true });
                Message.success('Camera configuration created successfully');
                setReloadDataState('cameras');
            } else {
                setCameraModalState({ btnLoading: false, clearData: false });
                Message.error(data.addCamera.message ? data.addCamera.message : "Something went wrong!");
            }
        }
    });
    const [createNode] = useMutation(CREATE_NODE, {
        onError: error => {
            Message.success(error);
            setNodeModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            setNodeModalState({ btnLoading: false, clearData: true });
            Message.success('Node created successfully.');
            setReloadDataState('node');
        }
    });
    const [createTransection] = useMutation(CREATE_TRANSECTION, {
        onError: error => {
            Message.success(error);
            setTransectionModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            setTransectionModalState({ btnLoading: false, clearData: true });
            Message.success('Transection created successfully.');
            setReloadDataState('transection');
        }
    });
    const [updateCamera] = useMutation(UPDATE_CAMERA, {
        onError: error => {
            Message.success(error);
            setCameraModalState({ btnLoading: false, clearData: false });
        },
        onCompleted: data => {
            setUpdateModalState({ ...updateModalState, camera: {} });
            setCameraModalState({ btnLoading: false, clearData: true });
            Message.success('Camera configuration updated successfully');
            setReloadDataState('cameras');
        }
    });
    const [deleteEdgeAiSubscription] = useMutation(DELETE_EDGEAI_SUBSCRIPTION, {
        onError: error => {
            setDeleteEdgeAISubLoading(false);
            setDeleteType('');
            setDeleteModalState(false);
            history.goBack();
        },
        onCompleted: data => {
            setDeleteEdgeAISubLoading(false);
            setDeleteType('');
            setDeleteModalState(false);
            Message.success('Subscription terminated successfully!');
            history.goBack();
        }
    });
    const handleDeleteConfirmClose = () => {
        setDeleteModalState(false);
    };
    /* Delete Controller*/
    const handleConfirmDelete = () => {
        if (deleteType === t('subscription.label')) {
            setDeleteEdgeAISubLoading(true);
            if (id) {
                return deleteEdgeAiSubscription({
                    variables: {
                        subscriptionId: id
                    }
                });
            }
            return null;
        }
    };
    const activeSites = (data.getSubscriptionById &&
        data.getSubscriptionById.sites && data.getSubscriptionById.sites.length && data.getSubscriptionById.sites.filter(item => item.status === SUBSCRIPTION_STATUS.ACTIVE)) || [];

    const [getMLTemplateList] = useLazyQuery(GET_ML_TEMPLATE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setSelectMlTemplateList(
                data.getAllMlTemplateList && data.getAllMlTemplateList.templates
                    ? data.getAllMlTemplateList.templates
                    : []
            );
        }
    });
    const [getMLTemplateDetailById] = useLazyQuery(GET_ML_TEMPLATE_BY_ID, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getMLTemplateDetailById) {
                setUpdateModalState({ ...updateModalState, mlTemplate: data.getMLTemplateDetailById });
                setupdateMlTemplateLoading(false);
            }
        },
        onError: error => {
            Message.error(error);
        }
    });
    const [getPlaceList] = useLazyQuery(GET_PLACE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setSelectePlaceList(
                data.getAllPlaceList && data.getAllPlaceList.places
                    ? data.getAllPlaceList.places
                    : []
            );
        }
    });
    const [getNodeList] = useLazyQuery(GET_NODE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getNodeList && data.getNodeList.nodes && data.getNodeList.nodes.length) {
                let nodeList = data.getNodeList.nodes.filter(item => item.mode === NODE_MANAGEMENT_TYPE.UNMANAGED);
                setSelecteNodeList(nodeList);
            }
        }
    });
    const [getVpnsList] = useLazyQuery(GET_VPNS_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (
                data.getVpnsList &&
                data.getVpnsList.vpns &&
                data.getVpnsList.vpns.length
            ) {
                setVPNSList(data.getVpnsList.vpns);
            }
        }
    });
    const [getNotificationTemplateList] = useLazyQuery(
        GET_NOTIFICATION_TEMPLATE_LIST,
        {
            fetchPolicy: 'no-cache',
            onCompleted: data => {
                setSelectNotiTemplateList(
                    data.getAllNotificationTemplateList &&
                        data.getAllNotificationTemplateList.templates
                        ? data.getAllNotificationTemplateList.templates
                        : []
                );
            }
        }
    );
    const [getLineCrossObjectById] = useLazyQuery(GET_LINE_CROSS_OBJECT, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            if (res.getLineCrossObjectById) {
                setLineCrossObjectState(res.getLineCrossObjectById);
            }
            setLineCrossDataLoading(false);
        }
    });
    const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
        onCompleted: data => {
            Message.success('Analytics URL added successfully.');
            reloadSubscription();
            setEdgeAIAnalyticsURLModal(false);
        }
    });
    const [updateLiveCameraUrl] = useMutation(UPDATE_LIVE_CAMERA_URL, {
        onCompleted: data => {
            Message.success('LiveCam URL added successfully.');
            reloadSubscription();
            setCreateLiveCamModal(false);
            setLiveCamModalState({ btnLoading: false, clearData: true });
        }
    });
    const handleCreateButtonClick = item => {
        if (item === 'ml_template') {
            setMLModalState({ btnLoading: false, clearData: false });
            setUpdateModalState({ ...updateModalState, mlTemplate: {} });
            setSelectedAiType('');
            setCreateMLModalState(true);
        } else if (item === 'notification_template') {
            setNotificationModalState({ btnLoading: false, clearData: false });
            setUpdateModalState({ ...updateModalState, notification: {} });
            setCreateNotificationModalState(true);
        } else if (item === 'place_template') {
            setPlaceModalState({ btnLoading: false, clearData: false });
            setUpdateModalState({ ...updateModalState, place: {} });
            setCreatePlaceModalState(true);
        } else if (item === 'camera_template') {
            if (data.getSubscriptionById) {
                let orgFilter = {
                    variables: {
                        organization: data.getSubscriptionById.organizationId,
                        search: '',
                        page: 1,
                        limit: 20
                    }
                };
                let orgLimitFltr = {
                    variables: {
                        organization: data.getSubscriptionById.organizationId,
                        search: '',
                        page: 1,
                        limit: 500
                    }
                }
                getNodeList(orgFilter);
                getPlaceList(orgFilter);
                getMLTemplateList(orgLimitFltr);
                getNotificationTemplateList(orgLimitFltr);
                getVpnsList(orgLimitFltr);
            }
            setCameraModalState({ btnLoading: false, clearData: false });
            setUpdateModalState({ ...updateModalState, camera: {} });
            setCreateCameraModalState(true);
        } else if (item === 'node') {
            setNodeModalState({ btnLoading: false, clearData: false });
            setCreateNodeModalState(true);
        } else if (item === 'transection') {
            setTransectionModalState({ btnLoading: false, clearData: false });
            setCreateTransectionModalState(true);
        }
    };
    const handleAddURLSubmitClick = (act, formData) => {
        if (act === 'analytics') {
            return updateSubscription({
                variables: {
                    id: data.getSubscriptionById.id,
                    analyticsUrl: formData.url
                }
            });
        } else {
            setLiveCamModalState({ btnLoading: true, clearData: false });
            return updateLiveCameraUrl({
                variables: {
                    id: data.getSubscriptionById.id,
                    liveCamUrls: JSON.stringify(formData.urls)
                }
            });
        }
    };
    const handleCreateURLClick = action => {
        if (action === 'analytics') {
            setEdgeAIAnalyticsURLModal(true);
        } else {
            setCreateLiveCamModal(true);
        }
    };
    const handleSetImageModalSrc = src => {
        let url = (typeof src === "string" ? src : src.target ? src.target.src : src);
        setImageModalSrcState(url);
        setImageModalState(true);
    };
    const handleCreateMLSubmitClick = formData => {
        if (
            formData.name &&
            formData.type &&
            formData.definition &&
            formData.priority
        ) {
            let model_type;
            if (formData.type == 'heatmap_alert') {
                model_type = 'HeatMap';
            } else if (formData.type == 'people_counting_alert') {
                model_type = 'LineCrossing';
            } else if (formData.type == 'multi_person_alert') {
                model_type = 'MultiplePerson';
            } else if (formData.type == 'behavioural_alert') {
                model_type = 'Behavioural';
            } else if (formData.type == 'multi_object') {
                model_type = 'multi-object';
            } else {
                model_type = formData.type;
            }
            let mlData = {
                name: formData.name,
                model_type: model_type,
                slug: formData.name,
                priority: formData.priority,
                definition: formData.definition,
                organization: data.getSubscriptionById.organizationId,
                provider: formData.provider,
                notification_frequency: formData.notification_frequency,
                lineCrossObjects: {
                    lineCrossObjectData: formData.lineCrossObjectData,
                    image: formData.lineCrossImage
                }
            };
            setLineCrossDataLoading(false);
            setMLModalState({ btnLoading: true, clearData: false });
            if (formData.id) {
                mlData.id = formData.id;
                return updateMLTemplate({
                    variables: mlData
                });
            } else {
                return createNewMLTemplate({
                    variables: mlData
                });
            }
        } else {
            return false;
        }
    };
    const handleCreateNotificationSubmitClick = formData => {
        if (
            formData.name &&
            formData.notification_type &&
            formData.dispatch_address &&
            formData.notification_message
        ) {
            formData.slug = formData.name;
            formData.organization = data.getSubscriptionById.organizationId;
            setNotificationModalState({ btnLoading: true, clearData: false });
            if (formData.id) {
                return updateNotificationTemplate({
                    variables: formData
                });
            } else {
                return createNotificationTemplate({
                    variables: formData
                });
            }
        } else {
            return false;
        }
    };
    const handleCreatePlaceSubmitClick = formData => {
        if (formData.name && formData.location) {
            let placeData = {
                name: formData.name,
                loc: [formData.location.latitude, formData.location.longitude],
                type: 'general',
                orgId: data.getSubscriptionById.organizationId
            };
            setPlaceModalState({ btnLoading: true, clearData: false });
            if (formData.place_id) {
                placeData.place_id = formData.place_id;
                return updatePlace({
                    variables: placeData
                });
            } else {
                return createPlace({
                    variables: placeData
                });
            }
        } else {
            return false;
        }
    };
    const handleCreateCameraSubmitClick = formData => {
        const camData = [];
        if (formData.cameras.length > 0) {
            setCameraModalState({ btnLoading: true, clearData: false });
            for (let i = 0; i < formData.cameras.length; i++) {
                let tempObj = {};
                let cam = formData.cameras[i];
                tempObj.orgId = data.getSubscriptionById.organizationId;
                tempObj.camData = {
                    name: cam.name,
                    urlJpeg: '',
                    urlVideo: '',
                    flags: '',
                    streamContentWidth: cam.width.toString(),
                    streamContentHeight: cam.height.toString(),
                    placeId: cam.place ? cam.place.place_id : ''
                };
                tempObj.fps = cam.fps.toString();
                tempObj.remote_stream = (cam.remote_stream && cam.remote_stream === "Yes") ? true : false;
                tempObj.url = cam.rtsp_url;
                tempObj.configTemplate = [];
                tempObj.provider = formData.provider;
                tempObj.node_id = formData.node ? formData.node : '';
                if (formData.aiModels.length > 0 && formData.aiModels[0].ai_template_id) {
                    for (let j = 0; j < formData.aiModels.length; j++) {
                        let tmpAiModal = {};
                        let aiMdl = formData.aiModels[j];
                        // tmpAiModal.startTime = aiMdl.from_time
                        //     ? convertLocalToUTC(aiMdl.from_time + ' 00')
                        //     : '00:00';
                        // tmpAiModal.endTime = aiMdl.to_time
                        //     ? convertLocalToUTC(aiMdl.to_time + ' 00')
                        //     : '00:00';
                        tmpAiModal.startTime = aiMdl.from_time ? getScheduleTimeByTimeZone(aiMdl.from_time, localStorage.getItem('timeZoneDetails')) : '00:00';
                        tmpAiModal.endTime = aiMdl.to_time ? getScheduleTimeByTimeZone(aiMdl.to_time, localStorage.getItem('timeZoneDetails')) : '00:00';
                        tmpAiModal.frequency = 1;
                        tmpAiModal.templateId = aiMdl.ai_template_id;
                        tmpAiModal.notificationTemplateId = [];
                        if (
                            formData.notifications.length > 0 &&
                            formData.notifications[0].notification_template_id
                        ) {
                            for (let k = 0; k < formData.notifications.length; k++) {
                                if (
                                    formData.notifications[k].ai_template_id ==
                                    aiMdl.ai_template_id
                                ) {
                                    tmpAiModal.notificationTemplateId.push(
                                        formData.notifications[k].notification_template_id
                                    );
                                }
                            }
                        }
                        tempObj.configTemplate.push(tmpAiModal);
                    }
                    camData.push(tempObj);
                }
            }
            if (camData.length) {
                return createCamera({
                    variables: { camData }
                });
            }
        }
    };
    const handleUpdateCameraSubmitClick = formData => {
        let camera = updateModalState.camera;
        let camData = {};
        let deletedAIModels = [];
        if (formData.cameras.length > 0) {
            if (formData.deletedAIModels && formData.deletedAIModels.length) {
                if (formData.prevData.schedules && formData.prevData.schedules.length) {
                    let prevShedules = formData.prevData.schedules[0];
                    deletedAIModels = prevShedules.filter(item => {
                        if (formData.deletedAIModels.includes(item.id)) {
                            return item;
                        }
                    });
                }
            }
            setCameraModalState({ btnLoading: true, clearData: false });
            for (let i = 0; i < formData.cameras.length; i++) {
                let cam = formData.cameras[i];
                camData.orgId = data.getSubscriptionById.organizationId;
                camData.camera_id = camera.camera_id;
                camData.camData = {
                    name: cam.name ? cam.name : camera.name,
                    urlJpeg: camera.image_url,
                    urlVideo: camera.video_url,
                    flags: camera.flags,
                    streamContentWidth: cam.width ? cam.width : camera.content_width,
                    streamContentHeight: cam.height ? cam.height : camera.content_height,
                };
                camData.placeId = (cam.placeId && cam.placeId.place_id) ? cam.placeId.place_id : camera.place.place;
                camData.fps = cam.fps ? cam.fps : camera.fps;
                camData.remote_stream = cam.remote_stream ? (cam.remote_stream === "Yes") ? true : false : camera.remote_stream ? camera.remote_stream : false;
                camData.url = cam.rtsp_url ? cam.rtsp_url : camera.url;
                if (formData.node) {
                    camData.node_id = formData.node;
                }
                camData.provider = formData.provider
                    ? formData.provider
                    : formData.prevData.provider;
                camData.configTemplate = [];
                if (
                    formData.aiModels.length > 0 &&
                    formData.aiModels[0].ai_template_id
                ) {
                    for (let j = 0; j < formData.aiModels.length; j++) {
                        let tmpAiModal = {};
                        let aiMdl = formData.aiModels[j];
                        let todayDate = moment(new Date()).format('YYYY-MM-DD');
                        let start_time = moment(new Date(`${todayDate} ${aiMdl.start_time}`)).format("HH:mm");
                        let end_time = moment(new Date(`${todayDate} ${aiMdl.end_time}`)).format("HH:mm");
                        tmpAiModal.startTime = (aiMdl.from_time) ? (aiMdl.startTime !== aiMdl.from_time) ? getScheduleTimeByTimeZone(aiMdl.from_time, localStorage.getItem('timeZoneDetails')) : start_time : '00:00';
                        tmpAiModal.endTime = (aiMdl.to_time) ? (aiMdl.endTime !== aiMdl.to_time) ? getScheduleTimeByTimeZone(aiMdl.to_time, localStorage.getItem('timeZoneDetails')) : end_time : '00:00';
                        tmpAiModal.frequency = 1;
                        tmpAiModal.templateId = aiMdl.ai_template_id;
                        tmpAiModal.delete = false;
                        if (aiMdl.scheduleId) {
                            tmpAiModal.scheduleId = aiMdl.scheduleId;
                        }
                        tmpAiModal.notificationTemplateId = [];
                        if (
                            formData.notifications.length > 0 &&
                            formData.notifications[0].notification_template_id
                        ) {
                            for (let k = 0; k < formData.notifications.length; k++) {
                                if (!formData.notifications[k].id) {
                                    if (
                                        formData.notifications[k].ai_template_id ==
                                        aiMdl.ai_template_id
                                    ) {
                                        tmpAiModal.notificationTemplateId.push(
                                            formData.notifications[k].notification_template_id
                                        );
                                    }
                                }
                            }
                        }
                        camData.configTemplate.push(tmpAiModal);
                    }
                }
                if (deletedAIModels.length) {
                    for (let k = 0; k < deletedAIModels.length; k++) {
                        let aiMdl = deletedAIModels[k];
                        let tempObj = {
                            templateId: aiMdl.model_template.id
                                ? aiMdl.model_template.id
                                : '',
                            startTime: aiMdl.start_time,
                            endTime: aiMdl.end_time,
                            scheduleId: aiMdl.id,
                            frequency: 1,
                            delete: true,
                            notificationTemplateId: []
                        };
                        camData.configTemplate.push(tempObj);
                    }
                }
                if (
                    formData.deletedNotifications &&
                    formData.deletedNotifications.length
                ) {
                    let delNotiObj = {
                        delete: true,
                        notificationFilters: formData.deletedNotifications
                    };
                    camData.configTemplate.push(delNotiObj);
                }
            }
            if (camData) {
                return updateCamera({
                    variables: { camData }
                });
            }
        }
    };
    const handleUpdateAction = actData => {
        setLineCrossDataLoading(false);
        setupdateMlTemplateLoading(false);
        if (actData.action === 'ml_template') {
            if (actData.data && actData.data.model_type) {
                switch (actData.data.model_type) {
                    case 'HeatMap':
                        actData.data.model_type = 'heatmap_alert';
                        break;
                    case 'LineCrossing':
                        actData.data.model_type = 'people_counting_alert';
                        break;
                    case 'Behavioural':
                        actData.data.model_type = 'behavioural_alert';
                        break;
                    case 'MultiplePerson':
                        actData.data.model_type = 'multi_person_alert';
                        break;
                    case 'multi-object':
                        actData.data.model_type = 'multi_object';
                        break;
                    default:
                        break;
                }
                if (actData.data.model_type === 'line_cross' || actData.data.model_type === 'line_cross2' || actData.data.model_type === 'volume_analysis') {
                    setLineCrossDataLoading(true);
                    getLineCrossObjectById({
                        variables: { templateId: actData.data.id }
                    });
                }
                if (actData.data.model_type === 'face_recognition') {
                    getMLTemplateDetailById({ variables: { id: actData.data.id } });
                    setupdateMlTemplateLoading(true);
                } else {
                    setUpdateModalState({ ...updateModalState, mlTemplate: actData.data });
                }
                setSelectedAiType(actData.data.model_type);
                setUpdateMLTemplateModalState(true);
            }
        } else if (actData.action === 'notification') {
            setUpdateModalState({ ...updateModalState, notification: actData.data });
            setCreateNotificationModalState(true);
        } else if (actData.action === 'place') {
            setUpdateModalState({ ...updateModalState, place: actData.data });
            setCreatePlaceModalState(true);
        } else if (actData.action === 'camera') {
            let orgFilter = {
                variables: {
                    organization: data.getSubscriptionById.organizationId,
                    search: '',
                    page: 1,
                    limit: 20
                }
            };
            let orgLimitFltr = {
                variables: {
                    organization: data.getSubscriptionById.organizationId,
                    search: '',
                    page: 1,
                    limit: 500
                }
            }
            getPlaceList(orgFilter);
            getMLTemplateList(orgLimitFltr);
            getNotificationTemplateList(orgLimitFltr);
            getVpnsList(orgLimitFltr);
            if (
                data.getSubscriptionById.nodeManagmentMode ===
                NODE_MANAGEMENT_TYPE.UNMANAGED
            ) {
                getNodeList(orgFilter);
            }
            setUpdateModalState({ ...updateModalState, camera: actData.data });
            setUpdateCameraModalState(true);
        }
    };
    const handleCreateNodeSubmit = formData => {
        setNodeModalState({ btnLoading: true, clearData: false });
        formData.orgId = data.getSubscriptionById.organizationId;
        formData.workerCount = parseInt(formData.workerCount);
        formData.workerGpuCount = parseInt(formData.workerGpuCount);
        formData.workerDiskSize = parseInt(formData.workerDiskSize);
        delete formData.cpu;
        delete formData.ram;
        if (!formData.isProvisioned) {
            delete formData.instance_ip;
        }
        return createNode({
            variables: formData
        });
    };
    const handleSelectAIType = () => {
        setAiTypeModalState(true);
    };
    const handleSubmitAIType = type => {
        setSelectedAiType(type);
    };
    const handleDeleteEdgeAISubscription = () => {
        if (role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) {
            setDeleteType(t('subscription.label'));
            setDeleteModalState(true);
        }
        return null;
    };

    const handleCreateTransectionSubmit = (transData) => {
        setTransectionModalState({ btnLoading: true, clearData: false });
        return createTransection({
            variables: {
                ...transData,
                amount: transData.amount ? parseFloat(transData.amount) : 0,
                organization: data.getSubscriptionById.organizationId,
            }
        });
    }

    let tabList = [];
    if (data && !demoVideosLoading) {
        tabList = [
            {
                label: t('dashboard.label'),
                icon: <MdOutlineSpaceDashboard />,
                children: (
                    <>
                        <DashboardPanel
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                            data={activeSites}
                            ticketData={
                                (serviceTickets &&
                                    serviceTickets.servicetickets.slice(0, 10)) ||
                                []
                            }
                            type={data.getSubscriptionById.service.serviceType.name}
                            handleImageModalClick={handleSetImageModalSrc}
                            aiTypes={aiTypes}
                            placeList={selectePlaceList}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('cameraEventsMap.label') }, { name: t('cameraStatusMap.lable') }]
            }
        ];
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('configuration.label'),
                icon: <FaUserCog style={{ color: "white" }} />,
                children: (
                    <>
                        <EdgeAIConfigurationPanel
                            t={t}
                            data={data.getSubscriptionById}
                            handleUpdateAction={handleUpdateAction}
                            handleCreateButtonClick={handleCreateButtonClick}
                            reloadData={reloadDataState}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('machineLearning.label') + ' ' + t('template.label') }, { name: t('notification.label') + ' ' + t('template.label') }, { name: t('places.label') }, { name: t('vpns.label') }, { name: t('camera.label') }, { name: t('nodeMng.label') }, { name: t('videos.label') }, { name: t('oitDevices.label') }]
            });
        }
        tabList.push({
            label: t('support.label'),
            icon: <MdOutlineContactSupport />,
            children: (
                <>
                    {serviceTickets && (
                        <TicketPanel
                            ticketList={
                                recentTicketsSearched
                                    ? updatedRecentTickets
                                    : serviceTickets.servicetickets.slice(0, 10)
                            }
                            type={'recentTickets'}
                            t={t}
                            handleMenuItemClick={handleTicketMenuItemClick}
                            filterList={filterListRecentTickets}
                            handleTicketItemClick={handleTicketItemClick}
                            handleSearch={handleSearchRecentTickets}
                        />
                    )}
                </>
            )
        });
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('billing.label'),
                icon: <FaDollarSign />,
                children: (
                    <>
                        <BillingPanel
                            t={t}
                            data={data.getSubscriptionById}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('utilization.label') }, { name: t('invoices.label') }]
            });
        }
        if (
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
        ) {
            tabList.push({
                label: t('reporting.label'),
                icon: <MdOutlineEvent />,
                children: (
                    <>
                        <EdgeAIReportPanel
                            t={t}
                            data={data.getSubscriptionById}
                            handleCreateButtonClick={handleCreateButtonClick}
                            handleSetImageModalSrc={handleSetImageModalSrc}
                            aiTypes={aiTypes}
                            tagsFilter={edgeAITagFilters}
                            mlTemplateList={selectMlTemplateList}
                            activeNotificationTab={activeNotificationTab}
                            handleChangeActiveNotiTab={handleChangeActiveNotiTab}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('organization.label') }, { name: t('places.label') }, { name: t('cameras.label') }, { name: t('notification.label') }, { name: t('events.label') }, { name: t('faces.label') }]
            });
        }
        if (
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
        ) {
            tabList.push({
                label: t('viewers.label'),
                icon: <MdOutlinePreview />,
                children: (
                    <>
                        <EdgeAIEventImagesViewers t={t} data={data.getSubscriptionById} />
                    </>
                )
            });
        }
        if (
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.IP_SUPERADMIN)
        ) {
            tabList.push({
                label: t('analytics.label'),
                icon: <MdOutlineAnalytics />,
                children: (
                    <>
                        <EdgeAIAnalyticsPanel
                            t={t}
                            mlTemplateList={selectMlTemplateList}
                            data={data.getSubscriptionById}
                            handleCreateURLClick={handleCreateURLClick}
                            handleImageModalClick={handleSetImageModalSrc}
                            handleAddURLSubmitClick={handleAddURLSubmitClick}
                            aiTypes={aiTypes}
                            notiTemplateList={selectNotiTemplateList}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('dashboard.label') }, { name: t('preBuild.label') }, { name: t('custom.label') }, { name: "Schedule Report" }]
            });
        } else {
            if (
                !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
            ) {
                tabList.push({
                    label: t('analytics.label'),
                    icon: <MdOutlineAnalytics />,
                    children: (
                        <>
                            <EdgeAIAnalyticsPanel
                                t={t}
                                mlTemplateList={selectMlTemplateList}
                                data={data.getSubscriptionById}
                                aiTypes={aiTypes}
                                notiTemplateList={selectNotiTemplateList}
                                selectedPath={selectedPath}
                                subActiveIndex={subActiveIndex}
                            />
                        </>
                    ),
                    subPaths: [{ name: t('dashboard.label') }, { name: t('preBuild.label') }, { name: t('custom.label') }, { name: "Schedule Report" }]
                });
            }
        }
        if (
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.IP_SUPERADMIN)
        ) {
            tabList.push({
                label: t('liveCam.label'),
                icon: <FaCamera />,
                children: (
                    <>
                        <EdgeAILiveCamPanel
                            t={t}
                            data={data.getSubscriptionById}
                            handleCreateURLClick={handleCreateURLClick}
                            handleAddURLSubmitClick={handleAddURLSubmitClick}
                            viewCustomLiveCam={true}
                            liveCamURL={edgeAILiveCamURL ? edgeAILiveCamURL : EDGE_AI_LIVE_CAM_URL}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: data.getSubscriptionById.liveCamUrls && data.getSubscriptionById.liveCamUrls.length > 0 ? [{ name: t('liveView.label') }, { name: t('liveCam.label') }] : [{ name: t('liveView.label') }]
            });
        } else {
            if (
                !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
            ) {
                tabList.push({
                    label: t('liveCam.label'),
                    icon: <FaCamera />,
                    children: (
                        <>
                            <EdgeAILiveCamPanel
                                t={t}
                                data={data.getSubscriptionById}
                                viewCustomLiveCam={false}
                                liveCamURL={edgeAILiveCamURL ? edgeAILiveCamURL : EDGE_AI_LIVE_CAM_URL}
                                selectedPath={selectedPath}
                                subActiveIndex={subActiveIndex}
                            />
                        </>
                    ),
                    subPaths: data.getSubscriptionById.liveCamUrls && data.getSubscriptionById.liveCamUrls.length > 0 ? [{ name: t('liveView.label') }, { name: t('liveCam.label') }] : [{ name: t('liveView.label') }]
                });
            }
        }
        if (
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.IP_SUPERADMIN) ||
            role.includes(USER_ROLES.EP_ADMIN)
        ) {
            tabList.push({
                label: t('settings.label'),
                icon: <MdSettings />,
                children: (
                    <>
                        <EdgeAISettingsPanel
                            t={t}
                            loading={deleteEdgeAISubLoading}
                            handleDeleteSubscription={handleDeleteEdgeAISubscription}
                            data={data.getSubscriptionById}
                            allowConfigure={role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                        />
                    </>
                ),
                subPaths: [{ name: t('processWorkFlow.label') }]
            });
        }
        if (
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.IP_SUPERADMIN) ||
            role.includes(USER_ROLES.EP_ADMIN)
        ) {
            tabList.push({
                label: t('demos.label'),
                icon: <MdOndemandVideo />,
                children: (
                    <>
                        <EdgeAIDemosPanel
                            t={t}
                            selectedPath={selectedPath}
                            subActiveIndex={subActiveIndex}
                            videosData={demoVideos?.getDemoVideos?.data}
                        />
                    </>
                ),
                subPaths: [{ name: t('banking.label') }, { name: t('insurance.label') }, { name: t('healthcare.label') }, { name: t('retail.label') }, { name: t('telecom.label') }, { name: t('travelhosp.label') }, { name: t('utilmanuf.label') }, { name: t('miningpowerindustry.label') }, { name: t('oilandgas.label') },]
            });
        }
    }

    React.useEffect(() => {
        getTabList(tabList);
    }, []);

    const handleChange = (event, newValue) => {
        if (typeof handleChangeActiveNotiTab !== "undefined") {
            handleChangeActiveNotiTab(false, newValue);
        }
    };

    return (
        <>
            {/* <CustomTabs
                handleChangeActiveNotiTab={handleChangeActiveNotiTab}
                activeIndex={activeIndex}
                tabs={tabList}
            /> */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeIndex}
                onChangeIndex={handleChange}>
                {tabList.length &&
                    tabList?.map((tab, tabIndex) => (
                        <TabPanel
                            value={activeIndex}
                            index={tabIndex}
                            dir={theme.direction}
                            key={tabIndex}>
                            {tab.children}
                        </TabPanel>
                    ))}
            </SwipeableViews>
            {/* *************** EDGE AI MODALS *************** */}
            <React.Fragment>
                <CreateMLTemplateModal
                    t={t}
                    open={createMLTemplateModal}
                    handleCreateMLSubmitClick={handleCreateMLSubmitClick}
                    aiTypes={aiTypes}
                    providerTypes={providerTypes}
                    handleClose={() => {
                        setMLModalState({ clearData: false, btnLoading: false });
                        setLineCrossObjectState(null);
                        setCreateMLModalState(false);
                        setLineCrossDataLoading(false);
                    }}
                    handleSelectAIType={handleSelectAIType}
                    selectedAiType={selectedAiType}
                    modalState={mlModalState}
                    multiObjectOpt={multiObjects}
                    accidentDetectionObj={accidentDetectionObj}
                    customDetectionObjects={customDetectionObjects}
                    frequencies={edgeAIFrequencies}
                />
                {updateMLTemplateModalState && (
                    <UpdateMLTemplateModal
                        t={t}
                        open={updateMLTemplateModalState}
                        handleCreateMLSubmitClick={handleCreateMLSubmitClick}
                        data={updateModalState.mlTemplate}
                        aiTypes={aiTypes}
                        providerTypes={providerTypes}
                        lineCrossObject={lineCrossObjectState}
                        lineCrossDataLoading={lineCrossDataLoading}
                        handleClose={() => {
                            setMLModalState({ clearData: false, btnLoading: false });
                            setLineCrossObjectState(null);
                            setLineCrossDataLoading(false);
                            setUpdateMLTemplateModalState(false);
                        }}
                        selectedAiType={selectedAiType}
                        handleSelectAIType={handleSelectAIType}
                        modalState={mlModalState}
                        dataLoading={updateMlTemplateLoading}
                        multiObjectOpt={multiObjects}
                        accidentDetectionObj={accidentDetectionObj}
                        customDetectionObjects={customDetectionObjects}
                        frequencies={edgeAIFrequencies}
                    />
                )}
                <CreateNotificationTemplateModal
                    t={t}
                    open={createNotificationTemplateModal}
                    handleCreateNotificationSubmitClick={
                        handleCreateNotificationSubmitClick
                    }
                    data={updateModalState.notification}
                    countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                    handleClose={() => {
                        setCreateNotificationModalState(false);
                        setNotificationModalState({
                            clearData: false,
                            btnLoading: false
                        });
                    }}
                    modalState={notificationModalState}
                    isForShayona={false}
                />
                <CreatePlaceTemplateModal
                    t={t}
                    open={createPlaceTemplateModal}
                    handleCreatePlaceSubmitClick={handleCreatePlaceSubmitClick}
                    data={updateModalState.place}
                    handleClose={() => {
                        setCreatePlaceModalState(false);
                        setPlaceModalState({ clearData: false, btnLoading: false });
                    }}
                    modalState={placeModalState}
                />
                <CreateCameraTemplateModal
                    t={t}
                    open={createCameraTemplateModal}
                    data={updateModalState.camera}
                    handleCreateCameraSubmitClick={handleCreateCameraSubmitClick}
                    subscriptionData={data.getSubscriptionById}
                    selectePlaceList={selectePlaceList}
                    selectMlTemplateList={selectMlTemplateList}
                    selectNotiTemplateList={selectNotiTemplateList}
                    handleClose={() => {
                        setCreateCameraModalState(false);
                        setCameraModalState({ clearData: false, btnLoading: false });
                    }}
                    selecteNodeList={selecteNodeList}
                    modalState={cameraModalState}
                    VPNSList={VPNSList}
                    cameraRSOptions={edgeAICameraResolutions}
                    cameraFpsOptions={edgeAICameraFps}
                />
                {updateCameraModalState && (
                    <UpdateCameraModal
                        t={t}
                        open={updateCameraModalState}
                        handleUpdateCameraSubmitClick={handleUpdateCameraSubmitClick}
                        data={updateModalState.camera}
                        subscriptionData={data.getSubscriptionById}
                        selectePlaceList={selectePlaceList}
                        selectMlTemplateList={selectMlTemplateList}
                        selectNotiTemplateList={selectNotiTemplateList}
                        handleClose={() => {
                            setUpdateCameraModalState(false);
                            setCameraModalState({
                                clearData: false,
                                btnLoading: false
                            });
                        }}
                        selecteNodeList={selecteNodeList}
                        modalState={cameraModalState}
                        cameraRSOptions={edgeAICameraResolutions}
                        cameraFpsOptions={edgeAICameraFps}
                    />
                )}
                <EdgeAIAnalyticsURLModal
                    t={t}
                    open={edgeAIAnalyticsURLModal}
                    handleAddURLSubmitClick={handleAddURLSubmitClick}
                    data={
                        data.getSubscriptionById.analyticsUrl
                            ? data.getSubscriptionById.analyticsUrl
                            : ''
                    }
                    modalState={analyticsModalState}
                    handleClose={() => {
                        setEdgeAIAnalyticsURLModal(false);
                    }}
                />
                <CreateLiveCamModal
                    t={t}
                    open={createLiveCamModal}
                    handleAddURLSubmitClick={handleAddURLSubmitClick}
                    data={data.getSubscriptionById}
                    modalState={liveCamModalState}
                    handleClose={() => {
                        setCreateLiveCamModal(false);
                    }}
                />
                <ImageModal
                    t={t}
                    open={imageModal}
                    imageSrc={imageModalSrc}
                    handleClose={() => setImageModalState(false)}
                />
                <SelectAITypeModal
                    t={t}
                    open={aiTypeModalState}
                    aiTypes={aiTypes}
                    handleSubmitAIType={handleSubmitAIType}
                    handleClose={() => setAiTypeModalState(false)}
                />
                <CreateNodeModal
                    t={t}
                    open={createNodeModalState}
                    modalState={nodeModalState}
                    handleCreateNodeSubmit={handleCreateNodeSubmit}
                    handleClose={() => {
                        setCreateNodeModalState(false);
                    }}
                />
                <DeleteConfirmBox
                    t={t}
                    open={deleteModalState}
                    handleClose={handleDeleteConfirmClose}
                    type={deleteType}
                    loading={deleteEdgeAISubLoading}
                    handleDelete={handleConfirmDelete}
                />
                <CreateTransectionModal
                    t={t}
                    open={createTransectionModalState}
                    modalState={transectionModalState}
                    handleCreateTransectionSubmit={handleCreateTransectionSubmit}
                    handleClose={() => {
                        setTransectionModalState({ btnLoading: false, clearData: false });
                        setCreateTransectionModalState(false);
                    }}
                />
            </React.Fragment>
        </>
    );
};
export default withTranslation()(React.memo(EdgeAIService));
