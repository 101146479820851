import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './clientConfig';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './i18n';
import { muiTheme } from './theme';
import ProfileProvider from './contexts/ProfileProvider';
import { ToastProvider } from 'react-toast-notifications';
import SideBarProvider from './contexts/SideBarProvider';
import { HOST_SITE_TITLE } from './utils/constants';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';

const hostName = window.location.hostname;

const App = () => {

  useEffect(() => {
    if (HOST_SITE_TITLE[hostName]) {
      document.title = HOST_SITE_TITLE[hostName];
    }
  }, []);

  return (
    <AppProvider>
      <AuthProvider>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <ApolloProvider client={client}>
              <ProfileProvider>
                <SideBarProvider>
                  <ToastProvider
                    autoDismiss
                    autoDismissTimeout={10000}
                    placement="bottom-right">
                    <Routes />
                  </ToastProvider>
                </SideBarProvider>
              </ProfileProvider>
            </ApolloProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </AppProvider>
  );
};

export default App;
