import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../../../utils';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Icon from '@material-ui/core/Icon';
import OutlinedSelect from '../../../../../../../../../components/OutlinedSelect';

const MaintenancePanelItem = ({
    t,
    data,
    handleChangeMaintenance,
    allowConfigure
}) => {
    const commonClasses = commonStyles();

    return (
        <>
            <TableRow key={data.place_id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Icon
                                component={() => (
                                    <LocationOnRoundedIcon
                                        color={'secondary'}
                                        style={{ marginTop: '2px', marginRight: '6px' }}
                                    />
                                )}
                                alt={'icon'}
                            />
                        </Grid>
                        <Grid>
                            <Typography
                                variant={'subtitle1'}
                                style={{ cursor: 'pointer' }}
                            >
                                {data.placeId ? data.placeId.name : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.noOfSensors}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.person ? data.person.firstName + " " +  data.person.lastName : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.startDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.endDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {allowConfigure &&
                    <TableCell>
                        <OutlinedSelect
                            val={data.action}
                            label={"Select"}
                            handleChange={evt => handleChangeMaintenance(evt.target.value, data)}
                            selectStyle={null}
                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: '180px' }}
                            options={['Pending', 'Completed', 'Canceled', 'Other']}
                        />
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

MaintenancePanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(MaintenancePanelItem);