import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../theme/commonStyles';
import clsx from 'clsx';
import { TICKET_PRIORITY } from '../../../../utils/constants';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Flag from '@material-ui/icons/Flag';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  ticketPriorityContainer: {
    height: '15px',
    borderRadius: '16px',
    alignItems: 'center',
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  highPriority: {
    borderColor: theme.palette.error.main
  },
  mediumPriority: {
    borderColor: theme.palette.warning.main
  },
  high: {
    color: theme.palette.error.main
  },
  medium: {
    color: theme.palette.warning.main
  }
}));

const TicketPriority = ({ t, priority }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const priorityLable = () => {
    if (priority === TICKET_PRIORITY.CRITICAL) {
      return t('critical.label');
    } else if (priority === TICKET_PRIORITY.URGENT) {
      return t('urgent.label');
    } else if (priority === TICKET_PRIORITY.HIGH) {
      return t('high.label');
    } else if (priority === TICKET_PRIORITY.MEDIUM) {
      return t('medium.label');
    } else if (priority === TICKET_PRIORITY.LOW) {
      return t('low.label');
    }
  };

  return (
    <>
      <div
        className={clsx(
          classes.ticketPriorityContainer,
          priority === TICKET_PRIORITY.LOW
            ? classes.mediumPriority
            : classes.highPriority
        )}
        style={{
          ...paddingGenerator('pt-3'),
          ...paddingGenerator('pb-4'),
          ...paddingGenerator('pl-12'),
          ...paddingGenerator('pr-12')
        }}>
        <Icon
          component={() => (
            <Flag
              className={clsx(
                commonClasses.iconRegular,
                priority === TICKET_PRIORITY.LOW ? classes.medium : classes.high
              )}
              style={marginGenerator('mr-8')}
            />
          )}
        />
        <Typography
          className={clsx(
            commonClasses.buttonTextSmall,
            priority === TICKET_PRIORITY.LOW ? classes.medium : classes.high
          )}
          variant={'body1'}>
          {priorityLable()}
        </Typography>
      </div>
    </>
  );
};

TicketPriority.propTypes = {
  priority: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default TicketPriority;
