import React, {useState, useEffect} from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Message from '../../../../../../components/Message';
import BackupControlPanelItem from './BackupControlPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import BackupControlForm from '../BackupControlPanel/BackupControlForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    GET_BACKUP_CONTROL_REQUESTS
} from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_BACKUP_CONTROL_REQUEST, DELETE_BACKUP_CONTROL } from '../../mutation';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));


const BackupControlPanel = ({
    t,
    data,
    allowConfigure
}) => {

    const classes = useStyles();

    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [briefModalOpen, setBriefModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [selectedUserItem, setSelectedUserItem] = useState(null);
    const [signatureState, setSignatureState] = useState({authorisationSign: "", recommendationSign: "", approverSign: "",
    learnDevelopmentSign: "", HODSign: "", HRManagerSign:"", GeneralManagerSign:"", validationSign:"", checkedAuthSign:"", performedAuthSign:""});

    const subscriptionId = data.id;

    const columns = [
        {
            label: 'Name',
            key: "name"
        },
        {
            label: 'Date',
            key: "date"
        },
        {
            label: 'Checked By',
            key: "checkedBy"
        },
        {
            label: "Performed By",
            key: "performedBy"
        },
        // {
        //     label: 'Organizer',
        //     key: "organizer"
        // },
        // {
        //     label: 'Brief Notes',
        //     key: "briefNotes"
        // },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const [getAllBackupControlRequestsList, {
        loading,
        data: backupControlRequestList
    }] = useLazyQuery(GET_BACKUP_CONTROL_REQUESTS, {
        fetchPolicy: "no-cache"
    });

    const [createBackupControlRequest] = useMutation(CREATE_BACKUP_CONTROL_REQUEST, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Backup Control request created successfully');
            setBriefModalOpen(false);
            return getAllBackupControlRequestsList({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createBackupControlRequest({ variables: {
            ...signatureState,
            ...formData
        }});
    }

    const [deleteBackupControl, { loading: deleteBackupControlLoading }] = useMutation(DELETE_BACKUP_CONTROL, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Backup control deleted successfully');
            return getAllBackupControlRequestsList({variables: {...filterStateData, subscriptionId}});
        }
    });

    useEffect(() => {
        return getAllBackupControlRequestsList({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllBackupControlRequestsList({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const meetingRequestList = [
        {
            name: "Server 1",
            schedule: new Date(),
            status: "Admin",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Server 2",
            schedule: new Date(),
            status: "Admin",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Server 3",
            schedule: new Date(),
            status: "Admin",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Server 4",
            schedule: new Date(),
            status: "Admin",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        }
    ];

    const handleNewMeetingRequest = () => {
        setBriefModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                console.log("evt.target.value: ", evt.target.value);
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        // {
        //     label: 'Section',
        //     handleChange: evt => {

        //     },
        //     val: filterStateData.section,
        //     styleOverrides: { minWidth: 200, marginRight: 20 },
        //     options: []
        // }
    ];

    const handleMenuItemClick = (type, rowItem) => {
        setSelectedUserItem(rowItem);
        if(type === "sign"){
            setSelectedUserItem(rowItem);
           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
        setBriefModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteBackupControl({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }
    
  return (
    <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewMeetingRequest}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !loading ? backupControlRequestList && backupControlRequestList.getAllBackupControlRequests && backupControlRequestList.getAllBackupControlRequests.data.length > 0 ?
                                backupControlRequestList.getAllBackupControlRequests.data.map((rowitem, rowIndex) => (
                                    <BackupControlPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow> : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <BackupControlForm
        t={t}
        open={briefModalOpen}
        handleClose={() => setBriefModalOpen(false)}
        data={selectedUserItem}
        signatures={signatureState}
        handleOpenSignPanModal={handleOpenSignPanModal}
        handleCreateRequestSubmit={handleCreateRequestSubmit}
    />
    <CanvasSignaturePadModal 
        open={signaturePadModalOpen} 
        handleClose={() => setSignaturePadModalOpen(false)} 
        handleSaveSignature={(image) => {
            setSignaturePadModalOpen(false);
            setSignatureState((prevState) => {
                const newState = {...prevState};
                newState[signatureType] = image;
                return newState;
            });
        }}
    />
    </div>
  )
}

export default BackupControlPanel