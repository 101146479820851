import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../utils/constants';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const initFormState = {name: "", closingDate: null, fileValue: null, file: null}
function CreateBidModal({
    t,
    open,
    handleClose,
    modalState,
    handleCreateBidSubmit
}) {
    
    const [bidFormState, setBidFormState] = useState({...initFormState});
    
    const handleInputChange = (value, field) => {
        setBidFormState({...bidFormState, [field]: value});
    }

    useEffect(() => {
        if(modalState.clearData && open){
            setBidFormState({...initFormState});
            handleClose();
        }
    }, [modalState]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('createNew.label')} Bid</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...marginGenerator('mt-8')
                    }}>
                    <Grid>
                        <Input
                            value={bidFormState.name}
                            type={'text'}
                            placeholder={"Bid Name"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => handleInputChange(evt.target.value, 'name')}
                        />
                    </Grid>
                    <Grid style={{marginTop: 15}}>
                        <DateTimeSelector 
                            inputStyle={{padding: 10, minWidth: 225}} 
                            disablePast={true}
                            label={"Closing Date"} 
                            value={bidFormState.closingDate}
                            dateFormat={"yyyy-MM-dd"} 
                            handleChange={(newData) => handleInputChange(newData, 'closingDate')}
                            styleOverrides={{width: "100%"}} 
                        />
                    </Grid>
                    <Grid style={{marginTop: 15}}>
                        <Input
                            value={bidFormState.fileValue}
                            type={'file'}
                            placeholder={"Upload Doc"}
                            inputProps={{accept: "application/msword, application/pdf"}}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => {
                                setBidFormState({...bidFormState, fileValue: evt.target.value, file: (evt.target.files && evt.target.files.length) ? evt.target.files[0] : null});
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        loading={modalState.btnLoading}
                        onClick={() => {
                            if(!bidFormState.name || !bidFormState.closingDate || !bidFormState.fileValue){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateBidSubmit({...bidFormState});
                        }}
                     />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateBidModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateBidModal);
