import {
  Button,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Popup from '../../../Popup';
import { getBgpByConnId } from '../../../../../services/fcrService';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ViewBgpPopupPort = ({ BgpView, setBgpView, connectionId, classes }) => {
  const [value, setValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();
  const [remoteAs, setRemoteAs] = useState();
  const [error, setError] = useState('');
  const [operStatus, setOperStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GetBgpByConnId = async () => {
    setLoading(true);
    const res = await getBgpByConnId(connectionId);
    if (res?.data?.statusCode === 200) {
      setNieghbourIp(res?.data?.result?.conn_id?.conn_cidr_b_ip);
      setRemoteAs(res?.data?.result?.neighbor_remote);
      setOperStatus(res?.data?.result?.oper_status);
      setLoading(false);
    } else {
      setError(res?.response?.data?.message);
      setNieghbourIp('No Data Found');
      setRemoteAs('No Data Found');
      setLoading(false);
    }
  };

  useEffect(() => {
    GetBgpByConnId();
  }, []);

  return (
    <>
      <Popup title="View Bgp" openPopup={BgpView} setOpenPopup={setBgpView}>
        <DialogContent dividers>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab label="Standard" {...a11yProps(0)} />
              <Tab label="Advanced" {...a11yProps(1)} disabled />
            </Tabs>
            <TabPanel value={value} index={0}>
              {loading ? (
                <>
                  <Grid container justify="center" spacing={2}>
                    <CircularProgress />
                  </Grid>
                </>
              ) : (
                <>
                  {operStatus !== 'Deleting...' && (
                    <>
                      <Grid container direction="column" spacing={2}>
                        <Grid
                          item
                          container
                          justify="center"
                          alignItems="center">
                          <Grid
                            item
                            style={{ color: 'grey', fontWeight: '500' }}
                            sm={4}>
                            Neighbor IP
                          </Grid>
                          <Grid item sm={1}>
                            <span>:</span>
                          </Grid>
                          <Grid item sm={7}>
                            <TextField
                              value={nieghbourIp}
                              size="small"
                              variant="outlined"
                              autoComplete="off"
                              disabled
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          justify="center"
                          alignItems="center">
                          <Grid
                            item
                            style={{ color: 'grey', fontWeight: '500' }}
                            sm={4}>
                            Remote-AS
                          </Grid>
                          <Grid item sm={1}>
                            <span>:</span>
                          </Grid>
                          <Grid item sm={7}>
                            <TextField
                              name="remoteAs"
                              size="small"
                              value={remoteAs}
                              variant="outlined"
                              disabled
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        {operStatus === 'Error' && (
                          <Grid
                            item
                            container
                            alignItems="center"
                            justify="center">
                            <Grid
                              item
                              style={{ color: 'grey', fontWeight: '500' }}
                              sm={4}>
                              Status
                            </Grid>
                            <Grid item sm={1}>
                              :
                            </Grid>
                            <Grid item sm={7}>
                              <Typography color="error">
                                {operStatus}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {error && (
                        <Grid item>
                          <h5 style={{ color: 'red', textAlign: 'center' }}>
                            {error}
                          </h5>
                        </Grid>
                      )}
                    </>
                  )}
                  {operStatus === 'Deleting...' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <CircularProgress />
                      <span style={{ marginLeft: '5px' }}>Deleting BGP...</span>
                    </div>
                  )}
                </>
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              style={{ height: '175px' }}></TabPanel>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid item container justify="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setBgpView(false);
                }}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Popup>
    </>
  );
};

export default ViewBgpPopupPort;
