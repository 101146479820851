import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { USER_ROLES } from "../../../../../../../utils/constants";
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../../../utils';

const QualityAssuranceTeamMalawi = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName,
    handleClickViewAllDetailsAndAttachment,
    handleProjectAttachmentClick
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.case_number) ? data.project_info.case_number : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {(data.project_info && data.project_info.customer_name) ? data.project_info.customer_name : 'N/A'} /
                            {(data.project_info && data.project_info.organization_name) ? data.project_info.organization_name : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            disabled={!data.feetport_form_url ? true : false}
                            color={'primary'}
                            style={{ width: '100%'}}
                            onClick={() => {
                                if (data.feetport_form_url != undefined) {
                                    window.open(data.feetport_form_url);
                                }
                            }}>{t('feetportForm.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.quality_assurance_team}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.signal}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                    <Button variant={'outlined'}
                            disabled={(data.upload_pics && (data.upload_pics.length == 0) ? true : false)}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleProjectAttachmentClick(data.upload_pics);
                            }}>{t('installationPictures.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%', marginBottom: '10px' }}
                            onClick={() => {
                                handleClickViewAllDetailsAndAttachment(data);
                            }}>{t('ViewCompleteDetails.label')}</Button>
                        <Button variant={'outlined'}
                            disabled={(data.project_info && data.project_info.previous_doc && data.project_info.previous_doc.length) == 0 ? true : false}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleProjectAttachmentClick(data.project_info.previous_doc);
                            }}>{t('attachments.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    {(role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.QUALITY_ASSURANCE_MALAWI_TEAM)) ?
                        <OutlinedSelect
                            label={`${t('Select Action')}`}
                            val={data.is_available ? 'Sent' : ''}
                            disabled={false}
                            handleChange={evt => {
                                handleMenuItemClick(evt.target.value, data);
                            }}
                            styleOverrides={{ width: '50%', marginRight: 2 }}
                            options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                        />
                        :
                        <OutlinedSelect
                            label={`${t('Select Action')}`}
                            val={data.is_available ? 'Sent' : ''}
                            disabled={data.is_available || (data.quality_assurance_team != CurrentUserName) ? true : false}
                            handleChange={evt => {
                                handleMenuItemClick(evt.target.value, data);
                            }}
                            styleOverrides={{ width: '50%', marginRight: 2 }}
                            options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                        />
                    }
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
QualityAssuranceTeamMalawi.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(QualityAssuranceTeamMalawi);