import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { renderText } from '../../displayComponent';
import Message from '../../../../components/Message';
import {
  addUnderlayProvider,
  getUnderlayProviderById,
  updateUnderlayProviderById
} from '../../../../services/underLayProviderService';

const UnderLayProviderForm = () => {
  const history = useHistory();
  const { mode, id } = useParams();

  const initialValues = {
    underlayProviderName: ''
  };
  const validationSchema = yup.object().shape({
    underlayProviderName: yup.string().required('Required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      name: data?.underlayProviderName
    };
    if (mode === 'add') {
      const res = await addUnderlayProvider(payload);
      console.log(res);
      if (res?.status === 200) {
        history.push('/fabricService/underlayproviders');
      } else {
        if (res?.response?.data?.message) {
          Message.error(res?.response?.data?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    } else {
      const res = await updateUnderlayProviderById(id, payload);
      if (res?.status === 200) {
        if (res?.response?.data?.message || res?.message) {
          Message.success(res?.response?.data?.message || res?.message);
        } else {
          Message.success('Underlay Provider created successfully');
        }
        history.push('/fabricService/underlayproviders');
      } else {
        if (res?.response?.data?.message || res?.message) {
          Message.error(res?.response?.data?.message || res?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    }
  };

  const GetUnderlayProvider = async () => {
    const res = await getUnderlayProviderById(id);
    setValue('underlayProviderName', res?.data?.result?.name);
  };

  useEffect(() => {
    if (id) {
      GetUnderlayProvider();
    }
  }, [id]);

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>
                {renderText({
                  label:
                    mode === 'add'
                      ? 'Create Underlay Provider'
                      : 'Edit Underlay Provider'
                })}
              </Box>
            </Grid>{' '}
          </Paper>
        </Grid>

        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Underlay Provider Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="underlayProviderName"
                        placeholder="Enter Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() =>
                          history.push('/fabricService/underlayproviders')
                        }>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit">
                        {mode === 'add' ? 'Submit' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UnderLayProviderForm;
