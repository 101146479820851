import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { ServiceTicketPaths } from '../../../routes/routePaths';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CardContent from '@material-ui/core/CardContent';
import InternalLayout from '../../../components/InternalLayout';
import { STATUS_TYPES } from '../../../utils/constants';
import Header from '../../../components/Header';
import { dateFormatterLLL } from '../../../utils';
import { GET_SERVICE_TICKETS } from '../../ServiceTickets/queries';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import PageviewIcon from '@material-ui/icons/Pageview';
import BookIcon from '@material-ui/icons/Book';

const breadcrumbList = [
  { label: 'home.label', link: {} }
];
const useStyles = makeStyles(theme => ({
  root: {
    width: '150px',
    height: '170px',
    backgroundColor: '#E5E5E5',
    borderRadius: 10,
  },
  serviceArea: {
    marginTop: theme.spacing(5)
  },
  cardArea: {
    padding: '10px'
  },
  icons: {
    fontSize: '40px',
    color: '#FF4952'
  },
  desc: {
    marginTop: '15px',
    fontSize: '15px',
    color: 'black'
  }
}));

export const getSubscriptionOptions = list => {
  return list.map((item, index) => {
    return { id: item.id, name: item.service.name };
  });
};

const CPDashboard = ({ t, navigation }) => {
  const classes = useStyles();
  let role = localStorage.getItem('role');

  React.useEffect(() => {
    getServiceTickets({
      variables: { status: STATUS_TYPES.OPEN }
    })
  }, [])

  const [getServiceTickets, {
    loading: openLoading,
    error: openError,
    data: openServiceTickets
  }] = useLazyQuery(GET_SERVICE_TICKETS);

  const handleCardClick = (type) => {
    if (type === 'raise') {
      window.open(ServiceTicketPaths.NewServiceTicket, '_blank');
    } else if (type === 'edgeAi') {
      window.open('https://www.inq.inc/edge-ai-iot/', '_blank');
    } else if (type === 'docs') {
      window.open('https://bw.inq.inc/business-automation/', '_blank');
    } else if (type === 'blogs') {
      window.open('https://www.inq.inc/news-blog/', '_blank');
    }
  }

  return (
    <InternalLayout breadcrumbList={breadcrumbList}>
      <Header
        title={t('dashboard.label')}
        subText={`${t('statsLastUpdated.label')} ${dateFormatterLLL(
          Date.now()
        )}`}
      />
      <div className={classes.serviceArea}>
        <Typography style={{ marginBottom: '20px', fontSize: '30px', fontWeight: '700' }}>
          {t('services.label')}
        </Typography>
        <Grid container spacing={10}>
          <Grid item xs="2">
            <Card className={classes.root}>
              <CardActionArea className={classes.cardArea} onClick={() => handleCardClick('raise')}>
                <CardContent>
                  <PostAddIcon className={classes.icons} />
                  <Typography className={classes.desc}>
                    {t('raiseService.label')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* <Grid item xs="2">
              <Card className={classes.root}>
                <CardActionArea className={classes.cardArea} onClick={() => handleCardClick('download')}>
                  <CardContent>
                    <DescriptionIcon className={classes.icons} />
                    <Typography className={classes.desc}>
                      {t('readDocument.label')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}
          <Grid item xs="2">
            <Card className={classes.root}>
              <CardActionArea className={classes.cardArea}>
                <CardContent>
                  <Typography className={classes.icons}>
                    {openServiceTickets ? openServiceTickets.servicetickets.length : 0}
                  </Typography>
                  <Typography className={classes.desc}>
                    {t('openTickets.label')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs="2">
            <Card className={classes.root}>
              <CardActionArea className={classes.cardArea} onClick={() => handleCardClick('edgeAi')}>
                <CardContent>
                  <WebAssetIcon className={classes.icons} />
                  <Typography className={classes.desc}>
                    {t('edgeAI.label')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs="2">
            <Card className={classes.root}>
              <CardActionArea className={classes.cardArea} onClick={() => handleCardClick('docs')}>
                <CardContent>
                  <PageviewIcon className={classes.icons} />
                  <Typography className={classes.desc}>
                    {t('intelliDocs.label')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs="2">
            <Card className={classes.root}>
              <CardActionArea className={classes.cardArea} onClick={() => handleCardClick('blogs')}>
                <CardContent>
                  <BookIcon className={classes.icons} />
                  <Typography className={classes.desc}>
                    {t('blogs.label')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
    </InternalLayout>
  );
};
export default withTranslation()(CPDashboard);
