import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '12px 4px',
    },
    sessionHeading:{
        marginLeft: '6px',
    },
    greyBackgroundBorder:{
        border: '1px solid black',
        outline: '1px solid',
        background: '#bbb',
        font: 'black',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    userFullName: "",
    prNumber: "",
    jobGrade: "",
    costCode: "",
    userDepartment: "",
    userSection: "",
    userSectionId: "",
    leaveFrom: null,
    leaveTo: null,
    phoneNumber: "",
    address: "",
    sign: "",
    typeOfLeave: [],
    approver: "",
    approverSign: "",
    learnDevelopment: "",
    learnDevelopmentSign: "",
    learnDevelopmentComment: "",
    HOD: "",
    HODSign: "",
    HODComment: "",
    HRManager: "",
    HRManagerSign: "",
    HRManagerComment: "",
    GeneralManager: "",
    GeneralManagerSign: ""
};

const typeOfLeave = [
    "Accumulative",
    "Non Accumulative (Mandatory)",
    "Time Off in lieu of approved Overtime",
    "Sick",
    "Encashment",
    "Unpaid",
    "Union",
    "Maternity",
    "Family",
    "Study",
    "Sports"
];

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    // document.getElementById("app").innerHTML = "<img src='" + imgURL + "' />";
    return imgURL;
}

function LeaveFormModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {
    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [isFormElementShown, setIsFormElementShown] = useState(true);
    const [userDeclaration, setUserDeclaration] = useState(true);
    const [departmentSections, setDepartmentSections] = useState([]);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showLearnDevelopment, setShowLearnDevelopment] = useState(false);
    const [showHOD, setShowHOD] = useState(false);
    const [showHRManager, setShowHRManager] = useState(false);
    const [showGeneralManager, setShowGeneralManager] = useState(false);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const userDepartment = data.userDepartment && data.userDepartment._id
            const userSection = data.userSection && data.userSection;
            const id = data._id;
            const userSectionId  = data.userSection && data.userSection._id;
            setShowUserSelect((data.createdById === userdata.id) ? true : false);
            setShowLearnDevelopment((data.createdById === userdata.id) ? true : false);
            setShowHOD((data.createdById === userdata.id) ? true : false);
            setShowHRManager((data.createdById === userdata.id) ? true : false);
            setShowGeneralManager((data.createdById === userdata.id) ? true : false);
            setURFormState({...data, userDepartment, userSection, id, userSectionId });
            setDepartmentSections([data.userSection])
        }else{
            setShowUserSelect(true);
            setShowLearnDevelopment(true);
            setShowHOD(true);
            setShowHRManager(true);
            setShowGeneralManager(true);
        }
    }, [data]);

    const handleChange = (value, name) => {
        if (name === "typeOfLeave") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.typeOfLeave.push(value.target.value);
                } else {
                    let index = newState.typeOfLeave.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.typeOfLeave.splice(index, 1);
                    }
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
            if(name === 'userSection'){
            setURFormState({ ...urFormState, [name]: value ,'userSectionId': value._id });
            }
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ textAlign: "center"}} >LEAVE APPLICATION FORM</Typography>
                        <hr />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Name</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.userFullName}
                            onChange={(evt) => handleChange(evt.target.value, "userFullName")}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>PR No</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"PR Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.prNumber}
                            onChange={(evt) => handleChange(evt.target.value, "prNumber")}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Job Grade / Band</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Job Grade / Band"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.jobGrade}
                            onChange={(evt) => handleChange(evt.target.value, "jobGrade")}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Cost Code</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Cost Code"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.costCode}
                            onChange={(evt) => handleChange(evt.target.value, "costCode")}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Department</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid>
                            <OutlinedSelect
                                options={(departments && departments.length) ? departments : []}
                                label="Department"
                                selectStyle={{ ...paddingGenerator('p-4') }}
                                val={urFormState.userDepartment}
                                handleChange={(evt) => {
                                    const item = departments.find(itm => evt.target.value === itm.id);
                                    setDepartmentSections(item ? item.sections : []);
                                    handleChange(evt.target.value, "userDepartment");
                                }}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Section</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid>
                            <OutlinedSelect
                                options={(departmentSections && departmentSections.length) ? departmentSections : []}
                                label="Section"
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                val={urFormState.userSection}
                                selectStyle={{ ...paddingGenerator('p-4') }}
                                handleChange={(evt) => {
                                    evt.target.value && evt.target.value._id &&
                                    handleChange(evt.target.value, "userSection");
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Leave Required From</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label={'Leave Required From'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.leaveFrom}
                            handleChange={newDate => handleChange(newDate, "leaveFrom")}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Leave Required To</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 250 }}
                            label={'Leave Required To'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.leaveTo}
                            handleChange={newDate => handleChange(newDate, "leaveTo")}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Applicants Contact</Typography>
                    </Grid>

                    <Grid item xs={2}>
                        <h5 className={classes.blackBorderTitle}>Phone No:</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Phone Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.phoneNumber}
                            onChange={(evt) => handleChange(evt.target.value, "phoneNumber")}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <h5 className={classes.blackBorderTitle}>Address:</h5>
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"Address"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.address}
                            onChange={(evt) => handleChange(evt.target.value, "address")}
                        />
                    </Grid>

                    <Grid item spacing={2} style={{ marginTop: 10 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Type of Leave and Number of days applied for</Typography>
                        </Grid>
                        <Grid container xs={12} className={classes.blackBorder}>
                            <FormGroup row>
                                {typeOfLeave.map((itm) => (
                                    <Grid item xs={4} key={itm} className={classes.blackBorder} style={{  textAlign: 'center' }}>
                                        <FormControlLabel
                                            style={{  margin: '0px' }}
                                            value={itm}
                                            checked={urFormState.typeOfLeave ? urFormState.typeOfLeave.includes(itm) : false}
                                            control={<Checkbox style={{ border: '0px' }} />}
                                            labelPlacement="start"
                                            onChange={(evt) => {
                                                handleChange(evt, "typeOfLeave");
                                            }}
                                            label={itm}
                                        />
                                    </Grid>
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    {data === null && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="h4" >Applicant Signature</Typography>
                        </Grid>
                        <Grid xs={1}>
                            <IconButton
                                variant={'outlined'}
                                onClick={() => handleOpenSignPanModal("sign")}>
                                <img src={SignIcon} width="26" height={26} />
                            </IconButton>
                        </Grid>
                        </>
                    )}
                    {((signatures && signatures.sign) || (data && data.sign)) &&
                        <Grid xs={1}>
                            <img src={convertBase64ToUrl((data && data.sign) ? data.sign : signatures.sign)} width={60} height={60} />
                        </Grid>
                    }
                </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        {(data && (showUserSelect || data.approver === userdata.id)) ?
                            <Grid item xs={12}>
                                <Typography variant="h4" >Approver Detail</Typography>
                            </Grid> : <Grid item xs={12}>
                                <Typography variant="h4" >Approver Detail</Typography>
                            </Grid>}
                        {showUserSelect &&
                            <Grid item xs={3}>
                                <OutlinedSelect
                                    options={(users && users.length) ? users : []}
                                    label="Approver"
                                    disabled={(data && data.approverSign) ? true : false}
                                    val={urFormState.approver}
                                    handleChange={(evt) => handleChange(evt.target.value, "approver")}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        }
                        {(data && data.approver === userdata.id) ? (
                            <Grid xs={1}>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={() => handleOpenSignPanModal("approverSign")}>
                                    <img src={SignIcon} width="26" height={26} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        {((signatures && signatures.approverSign) || (data && data.approverSign)) &&
                            <Grid xs={1}>
                                <img src={convertBase64ToUrl((data && data.approverSign) ? data.approverSign : signatures.approverSign)} width={60} height={60} />
                            </Grid>
                        }
                    </Grid>

                    { urInitFields.typeOfLeave.includes('Study') ? (
                        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                            {(data && (showLearnDevelopment || data.learnDevelopment === userdata.id)) ?
                                <Grid item xs={12}>
                                    <Typography variant="h4" >Learning and Development Comments</Typography>
                                </Grid> : <Grid item xs={12}>
                                    <Typography variant="h4" >Learning and Development Comments</Typography>
                                </Grid>}
                            {showLearnDevelopment &&
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        label="Learn and Development"
                                        disabled={(data && data.learnDevelopmentSign) ? true : false}
                                        val={urFormState.learnDevelopment}
                                        handleChange={(evt) => handleChange(evt.target.value, "learnDevelopment")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                            }
                            {(data && data.learnDevelopment === userdata.id) ? (
                                <>
                                <Grid xs={1}>
                                    <IconButton
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("learnDevelopmentSign")}>
                                        <img src={SignIcon} width="26" height={26} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="textarea"
                                        placeholder="Learn and Development Comment"
                                        variant="standard"
                                        fullWidth={true}
                                        multiline
                                        rows={5}
                                        onChange={(evt) => handleChange(evt.target.value, "learnDevelopmentComment")}
                                        value={urFormState.learnDevelopmentComment}
                                    />
                                </Grid>
                                </>
                            ) : null}
                            {((signatures && signatures.learnDevelopmentSign) || (data && data.learnDevelopmentSign)) &&
                                <Grid xs={1}>
                                    <img src={convertBase64ToUrl((data && data.learnDevelopmentSign) ? data.learnDevelopmentSign : signatures.learnDevelopmentSign)} width={60} height={60} />
                                </Grid>
                            }
                        </Grid>
                    ) : null }

                    { urInitFields.typeOfLeave.includes('Study') || urInitFields.typeOfLeave.includes('Family')
                    || urInitFields.typeOfLeave.includes('Unpaid') || urInitFields.typeOfLeave.includes('Sports') ? (
                        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                            {(data && (showHOD || data.HOD === userdata.id)) ?
                                <Grid item xs={12}>
                                    <Typography variant="h4" >Head of Department (Unpaid, Family, Study and Sports Leave only)</Typography>
                                </Grid> : <Grid item xs={12}>
                                    <Typography variant="h4" >Head of Department (Unpaid, Family, Study and Sports Leave only)</Typography>
                                </Grid>}
                            {showHOD &&
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        label="Head Of Department"
                                        disabled={(data && data.HODSign) ? true : false}
                                        val={urFormState.HOD}
                                        handleChange={(evt) => handleChange(evt.target.value, "HOD")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                            }
                            {(data && data.HOD === userdata.id) ? (
                                <>
                                <Grid xs={1}>
                                    <IconButton
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("HODSign")}>
                                        <img src={SignIcon} width="26" height={26} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="textarea"
                                        placeholder="Head Of Department Comment"
                                        variant="standard"
                                        fullWidth={true}
                                        multiline
                                        rows={5}
                                        onChange={(evt) => handleChange(evt.target.value, "HODComment")}
                                        value={urFormState.HODComment}
                                    />
                                </Grid>
                                </>
                            ) : null}
                            {((signatures && signatures.HODSign) || (data && data.HODSign)) &&
                                <Grid xs={1}>
                                    <img src={convertBase64ToUrl((data && data.HODSign) ? data.HODSign : signatures.HODSign)} width={60} height={60} />
                                </Grid>
                            }
                        </Grid>
                    ) : null }

                    { urInitFields.typeOfLeave.includes('Study') || urInitFields.typeOfLeave.includes('Family')
                    || urInitFields.typeOfLeave.includes('Unpaid') || urInitFields.typeOfLeave.includes('Sports') ? (
                        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                            {(data && (showHRManager || data.HRManager === userdata.id)) ?
                                <Grid item xs={12}>
                                    <Typography variant="h4" >Human Resources Manager (Unpaid, Family, Study and Sports Leave)</Typography>
                                </Grid> : <Grid item xs={12}>
                                    <Typography variant="h4" >Human Resources Manager (Unpaid, Family, Study and Sports Leave)</Typography>
                                </Grid>}
                            {showHRManager &&
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        label="HR Manager"
                                        disabled={(data && data.HRManagerSign) ? true : false}
                                        val={urFormState.HRManager}
                                        handleChange={(evt) => handleChange(evt.target.value, "HRManager")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                            }
                            {(data && data.HRManager === userdata.id) ? (
                                <>
                                <Grid xs={1}>
                                    <IconButton
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("HRManagerSign")}>
                                        <img src={SignIcon} width="26" height={26} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="textarea"
                                        placeholder="HR Manager Comment"
                                        variant="standard"
                                        fullWidth={true}
                                        multiline
                                        rows={5}
                                        onChange={(evt) => handleChange(evt.target.value, "HRManagerComment")}
                                        value={urFormState.HRManagerComment}
                                    />
                                </Grid>
                                </>
                            ) : null}
                            {((signatures && signatures.HRManagerSign) || (data && data.HRManagerSign)) &&
                                <Grid xs={1}>
                                    <img src={convertBase64ToUrl((data && data.HRManagerSign) ? data.HRManagerSign : signatures.HRManagerSign)} width={60} height={60} />
                                </Grid>
                            }
                        </Grid>
                    ) : null }

                    { urInitFields.typeOfLeave.includes('Unpaid') || urInitFields.typeOfLeave.includes('Sports') ? (
                        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                            {(data && (showGeneralManager || data.GeneralManager === userdata.id)) ?
                                <Grid item xs={12}>
                                    <Typography variant="h4" >GENERAL MANAGERS APPROVAL (Unpaid / Sports leave only)</Typography>
                                </Grid> : <Grid item xs={12}>
                                    <Typography variant="h4" >GENERAL MANAGERS APPROVAL (Unpaid / Sports leave only)</Typography>
                                </Grid>}
                            {showGeneralManager &&
                                <Grid item xs={3}>
                                    <OutlinedSelect
                                        options={(users && users.length) ? users : []}
                                        label="General Manager"
                                        disabled={(data && data.GeneralManagerSign) ? true : false}
                                        val={urFormState.GeneralManager}
                                        handleChange={(evt) => handleChange(evt.target.value, "GeneralManager")}
                                        styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    />
                                </Grid>
                            }
                            {(data && data.GeneralManager === userdata.id) ? (
                                <Grid xs={1}>
                                    <IconButton
                                        variant={'outlined'}
                                        onClick={() => handleOpenSignPanModal("GeneralManagerSign")}>
                                        <img src={SignIcon} width="26" height={26} />
                                    </IconButton>
                                </Grid>
                            ) : null}
                            {((signatures && signatures.GeneralManagerSign) || (data && data.GeneralManagerSign)) &&
                                <Grid xs={1}>
                                    <img src={convertBase64ToUrl((data && data.GeneralManagerSign) ? data.GeneralManagerSign : signatures.GeneralManagerSign)} width={60} height={60} />
                                </Grid>
                            }
                        </Grid>
                    ) : null }
                    <Grid item xs={12}>
                        <hr />
                        <ol>
                            <li> <b>Sick Leave</b> to be sent directly to the Clinic.</li>
                            <li> <b>Sports and Unpaid Leave</b> to be approved by the General Manager.</li>
                            <li> <b>Study leave</b> to go through Learning and Development Section.</li>
                            <li> <b>Family Leave</b> must be supported by justification</li>
                            <li> <b>Leave Encashment Outside Payroll</b> will require justification and
                                can only be encashed once in every 6 months unless
                                approved by the Human Resources Manager</li>
                        </ol>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!urFormState.userFullName || !urFormState.userDepartment || !urFormState.userSection){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateRequestSubmit(urFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

LeaveFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(LeaveFormModal);
