import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import VideoPlayer from '../../../../../components/VideoPlayer';
import commonStyles from '../../../../../theme/commonStyles';
import AttchedCamera from '../../AttchedCamera';
import { dateFormatterLLL } from '../../../../../utils';
import Message from '../../../../../components/Message';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Videocam from '@material-ui/icons/Videocam';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import VideoStatus from '../VideoStatus';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
// import { VIDEO_STATUS } from '../../../../../utils/constants';
import Button from '@material-ui/core/Button';

const VideoPanelItem = ({
  t,
  data,
  handleMenuItemClick,
  menuItems,
  checkedVideosRow,
  handleSelectVideoRow,
  allowConfigure,
  handleAddRoi
}) => {

  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAttachedCameraEnable, setIsAttachedCameraEnable] = useState(false);
  const [isVideoPlayerEnable, setIsVideoPlayerEnable] = useState(false);

  const handleClose = () => {
    setIsAttachedCameraEnable(false);
  };
  
  const videoPlayeHandleClose = () => {
    setIsVideoPlayerEnable(false);
  };

  return (
      <>
    <TableRow key={data.camera_id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                {/* {allowConfigure &&
                <Grid>
                    <Checkbox
                    disabled={data.is_deleted ? true : data.provider === 'uncanny' && (data.status === "provisioning" || data.status === "pending")}
                        checked={checkedVideosRow[data.camera_id]} 
                        value={data.camera_id}
                        onChange={(event) => {
                            handleSelectVideoRow(event, data.camera_id);
                        }}
                    />
                </Grid>} */}
                <Grid
                    onClick={()=> setIsVideoPlayerEnable(true)}
                    style={{ cursor: 'pointer' }}
                >
                    <Icon
                        component={() => (
                            <Videocam
                                color={'secondary'}
                                style={{marginTop: '2px', marginRight: '6px'}}
                            />
                        )}
                        alt={'icon'}
                    />
                </Grid>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}> { data.place ? data.place.name : ''}</Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.created_by_name}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography 
                      variant={'subtitle1'}
                      style={{ cursor: data.attachedModel === 0 ? 'auto' :'pointer' }}
                      onClick={()=> {
                        if(data.attachedModel && data.attachedModel !== 0){
                            Message.error('This feature not implemented yet');
                            // setIsAttachedCameraEnable(true)
                        }
                       }}
                    >
                        {(data.attachedModel) ? data.attachedModel : 0}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    {data.is_deleted ? <div>
                        <Chip
                            size="small"
                            label={t("deleted.label")}
                            color="secondary"
                        />
                </div> : <VideoStatus t={t} status={data.status}/>}
                </Grid>
            </Grid>
        </TableCell>
        {/* <TableCell>
            {!data.is_deleted || data.status !== CAMERA_STATUS.PANDING || data.status !== CAMERA_STATUS.PROV ?
                <Button onClick={evt => handleAddRoi(data)} color="primary">{t("add.label")}</Button>
            : null}
        </TableCell> */}
        {allowConfigure && 
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        disabled={true}
                        onClick={evt => {
                            if(data.is_deleted) return null;
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    disabled={true}
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={menuItems}
                    handleMenuItemClick={elem => {
                        if(data.is_deleted){
                            return null
                        }
                        handleMenuItemClick(elem, data, 'camera');
                        setAnchorEl(null);
                    }}
                />}
            </TableCell>
        }
    </TableRow>
    <AttchedCamera
    t={t}
    rederDataType='modal'
    data={data}
    title={t('attachedModels.label')}
    isAttachedCameraEnable={isAttachedCameraEnable}
    handleClose={handleClose}
      />
    <VideoPlayer
        t={t}
        videoUrl={data.url}
        open={isVideoPlayerEnable}
        handleCloseVideo={videoPlayeHandleClose}
    />  
    </>
  );
};

VideoPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired,
    allowConfigure: PropTypes.bool
};

export default withTranslation()(VideoPanelItem);