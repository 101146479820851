import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import '../../styles.css';
import { useMutation } from '@apollo/react-hooks';
import AttachFile from '@material-ui/icons/AttachFile';
import Dialog from '@material-ui/core/Dialog';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../../src/components/Message';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import { UPLOAD_FILE } from '../../../commonMutations';
import Close from '@material-ui/icons/Close';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import DatePicker from 'react-datepicker';
import {
  CircularProgress,
  LinearProgress,
  InputBase,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    detailsContainer: {
      border: `1px solid ${theme.palette.grey[200]}`
    },
    iconButton: {
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(1.25, 3),
      borderRadius: '24px',
      marginTop: theme.spacing(2)
    }
  })
);

const initState = {
  title: '',
  description: ''
};
function UpdateCustomer({
  open,
  handleClose,
  handleSubmitUpdateCustomer,
  buttonLoading,
  ticketData,
  t
}) {
  const [title, setTitle] = useState(ticketData.title);
  const [attachments, setAttachments] = useState('');
  const [description, setDescription] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [attUpload, setAttUpload] = useState(false);
  const [allCCEmails, setAllCCEmails] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [emailCC, setEmailCC] = useState('');
  // const senderEmailCC = ticketData.emailCC ;

  // senderEmailCC && senderEmailCC?.length && console.log(senderEmailCC,"senderEmailCC") ;



  const userEmail = ticketData.ticketType === 'mailTicket' ? ticketData.createdBy :
    ticketData.userInfo && ticketData.userInfo.email ? ticketData.userInfo.email : ''

  const handleSubmitData = () => {
    let allDetails = {
    
      title: title,
      description: description,
      email: userEmail,
      allCCEmails: allCCEmails,
      userName: ticketData && ticketData.userInfo && ticketData.userInfo.firstName ? ticketData.userInfo.firstName : "Customer"
    }
    handleSubmitUpdateCustomer(allDetails);
  };

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      setAttUpload(false)
      let allDetails = {
        title: title,
        description: description,
        email: userEmail,
        allCCEmails: allCCEmails,
        ticketTitle: ticketData.title,
        ticketId: ticketData.ticketId,
        serviceTicketId: ticketData.id,
        createdOn: ticketData.createdAt,
        userName: ticketData && ticketData.userInfo && ticketData.userInfo.firstName ? ticketData.userInfo.firstName : "Customer",
        attachments: [data.uploadDocument.url]
      }
      handleSubmitUpdateCustomer(allDetails);
    },
    onError: e => {
      setAttUpload(false)
      Message.error("Something Went Wrong")
      console.log(e);
    }
  });

  const prepareUpload = () => {
    setAttUpload(true)
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const emailHandlerKeyPress = (e) => {
    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
    if (e.key === 'Enter') {
      if (e.target.value === '') {
        Message.error('Please Enter Email Ids seprated by commas')
        return false
      }
      var emailIds = e.target.value.split(",")
      var allEmails = []
      emailIds.map(email => {
        if (!pattern.test(email)) {
          Message.error(`${email} is not valid`)
          setValidEmail(false)
          return false
        } else {
          setValidEmail(true)
          if (!allEmails.includes(email)) allEmails.push(email)
        }
      })
      setAllCCEmails(allEmails)
      Message.success("Email CC added successfully")
    }
  }

  const handleEmailCC = (e) => {
    setEmailCC(e.target.value)
  }

  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add Customer Updates
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('customerUpdateTitle.label')} *
              </Typography>
              <TextField
                type="textarea"
                placeholder="Add Customer Update Title"
                variant="standard"
                name="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('addCC.label')}
              </Typography>
              <Input
                value={emailCC}
                type={'text'}
                placeholder={`${t('addCCDesc.label')}`}
                style={{ width: '100%' }}
                color={'secondary'}
                onChange={evt => handleEmailCC(evt)}
                onKeyPress={e => emailHandlerKeyPress(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('customerUpdateDescription.label')} *
              </Typography>
              <Grid item className={classes.detailsContainer}>
                <Grid
                  container
                  item
                  style={{
                    ...paddingGenerator('pt-16'),
                    ...paddingGenerator('pb-16'),
                    ...paddingGenerator('pl-24'),
                    ...paddingGenerator('pr-24')
                  }}>
                  <InputBase
                    fullWidth
                    value={description}
                    id="details-textarea"
                    placeholder="Add Customer Update Description"
                    multiline
                    onChange={evt => setDescription(evt.target.value)}
                    variant="standard"
                  />
                </Grid>
                <Grid container item justify={'flex-end'}>
                  {uploadedFile && (
                    <Chip
                      avatar={<img src={Image} alt={'file'} />}
                      label={
                        uploadedFile.name.length > 12
                          ? `${uploadedFile.name.slice(0, 12)}...`
                          : uploadedFile.name
                      }
                      onDelete={() => {
                        setUploadedFile('');
                      }}
                      deleteIcon={<Close />}
                      variant="outlined"
                    />
                  )}
                  <IconButton
                    size={'medium'}
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'secondary'}
                    onClick={handleFileUploadClick}
                    style={marginGenerator('ml-16')}>
                    <AttachFile className={classes.iconRegular} />
                  </IconButton>
                  <input
                    type="file"
                    id="attachment"
                    ref={inputFile}
                    onChange={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      setUploadedFile(event.target.files[0]);
                    }}
                    style={{ display: 'none' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!title || !description) {
                Message.error(REQUIRED_ERROR);
                return null;
              } else {
                uploadedFile
                  ? prepareUpload()
                  : handleSubmitData()
              }
            }}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : attUpload ? <LinearProgress color="white" style={{ fontSize: 15 }} /> : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(UpdateCustomer);
