import React from 'react';
import { Grid } from '@material-ui/core';
import InsightForm from './InsightForm';

const Insight = () => {
  return (
    <>
      <Grid container direction="column">
        <Grid item container direction="column" spacing={4}>
          <Grid item>
            <InsightForm />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Insight;
