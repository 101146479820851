import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_START_END_DATE_PROJECT_ANALYSIS, GET_START_END_DATE_PAGINATED_PROJECT_ANALYSIS, GET_EXCEL_FILE_URL_PAGINATED_PROJECT_ANALYSIS } from "../../queries";
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import CustomTable from "./components/Table";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnalyticsItem from "./components/AnalyticsItem";
import ProjectInProgressTableItem from "./components/ProjectInProgressTableItem";
import QuantityOfProjectTableItem from "./components/QuantityOfProjectTableItem";
import AgeingProjectsInProgressTableItem from "./components/AgeingProjectsInProgressTableItem";
import AnalysisOfOutOfSLACausesTableItem from "./components/AnalysisOfOutOfSLACausesTableItem";
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { marginGenerator } from '../../../../../theme/utils';
import { colors } from '../../../../../theme/colors';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PieChartWrapper from '../../../../../components/Charts/PieChartWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import LineChartWrapper from '../../../../../components/Charts/LineChartWrapper';
import BarHorizontalChartWrapper from '../../../../../components/Charts/BarHorizontalChartWrapper';
import ChartContainer from '../../../../../components/Charts/ChartContainer';
import ProjectDetailListModal from './components/ProjectDetailListModal';
import Message from "../../../../../components/Message";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    root1: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
      },
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));
export const IncidentStatusPOC = ({ t, data }) => {
    return (
        <div>
            <PieChartWrapper data={data} />
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
//   const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//       backgroundColor: theme.palette.background.paper,
//     },
//   }));



const Analytics = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [PMOWeeklyReport, setPMOWeeklyReport] = useState({ loading: false, data: [], total: 50 });
    const [ProjectDetailList, setProjectDetailList] = useState({ open: false, loading: false, error: '', data: [], listName: '', excelSheetUrl: '' });
    const [ProjectInProgressClosedAndHold, setProjectInProgressClosedAndHold] = useState({ loading: false, data: [], error: false });
    const [QuantityOfProjectTable, setQuantityOfProjectTable] = useState({ loading: false, data: [], error: false });
    const [AgeingProjectsInProgress, setAgeingProjectsInProgress] = useState({ loading: false, data: [], error: false });
    const [AnalysisOfOutOfSLACausesTable, setAnalysisOfOutOfSLACausesTable] = useState({ loading: false, data: [], error: false });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const countryName = localStorage.getItem('country');

    // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    let currencyCode = '';
    let countAnalyticsListCall = 1;
    let currencySymbol = '';
    let count = 1;
    if (COUNTRY_LIST.NIGERIA === countryName) {
        currencyCode = '';
        currencySymbol = '';
    } else if (COUNTRY_LIST.BOTSWANA === countryName) {
        currencyCode = '(BWP)';
        currencySymbol = '(P)';
    } else if (COUNTRY_LIST.MALAWI === countryName) {
        currencyCode = '(MWK)';
        currencySymbol = '(MK)';
    } else if (COUNTRY_LIST.ZAMBIA === countryName) {
        currencyCode = '(ZMW)';
        currencySymbol = '(ZK)';
    } else if (COUNTRY_LIST.CAMEROON === countryName) {
        currencyCode = '(XAF)';
        currencySymbol = '(FCFA)';
    } else if (COUNTRY_LIST.IVORY_COAST === countryName) {
        currencyCode = '(XOF)';
        currencySymbol = '(CFA)';
    }
    let billing_country = localStorage.getItem('country');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    const PMOWeeklyReportColumn = [
        { name: t('WIPReportSummary.label') },
        { name: t('nrc.label') + currencyCode },
        { name: t('mrc.label') + currencyCode }
    ];
    const PMOWeeklyReportData = [
        { summary: 'Completed', nrc: 'N/A', mrc: 'N/A' },
        { summary: '1 - 30 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: '31 - 60 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: '61 - 90 days', nrc: 'N/A', mrc: 'N/A' },
        { summary: 'Total', nrc: 'N/A', mrc: 'N/A' },
    ];
    // const projectInProgressData = [
    //     { name: t('mrc.label') + currencySymbol, value: 50 },
    // { name: t('nrc.label') + currencySymbol, value: 50 }];
    const [projectInProgressData, setProjectInProgressData] = useState([{ name: t('mrc.label') + currencySymbol, value: 50 },
    { name: t('nrc.label') + currencySymbol, value: 50 }]);
    const NrcNNgnData = [
        { name: t('nrc.label') + currencySymbol + ', ' + currencyCode + ' 1', value: 80 },
        { name: t('nrc.label') + currencySymbol + ', ' + currencyCode + ' 2', value: 10 },
        { name: t('nrc.label') + currencySymbol + ', ' + currencyCode + ' 3', value: 10 },
    ];
    const [countsOfProjectsProgressData, setCountsOfProjectsProgressData] = useState([
        { name: '0 - 30', value: 22.2 },
        { name: '31 - 60', value: 22.2 },
        { name: '61 - 90', value: 11.1 },
        { name: '91 - 120', value: 0 },
        { name: '121 - above', value: 44.5 }
    ]);
    const projectInProgressClosedAndHoldColumn = [
        { label: t('column.label') },
        { label: t('projectInProgress.label') },
        { label: t('projectsClosed.label') },
        { label: t('projectOnHold.label') },
        { label: t('totalProjectInPmoPortfolio.label') + currencyCode },
    ];
    const ProjectInProgressClosedAndHoldtData = [
        { column: 'NRC' + currencySymbol, project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
        { column: 'MRC' + currencySymbol, project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
        // { column: 'SITES COUNT', project_in_progress: 'N/A', project_closed: 'N/A', project_on_hold: 'N/A', total_project_in_pmo_portfolio: 'N/A' },
    ];
    const QuantityOfProjectTableColumn = [
        { label: t('column.label') },
        { label: t('quantityOfProjects.label') },
        { label: t('nrc.label') + currencySymbol },
        { label: t('mrc.label') + currencySymbol },
    ];
    const QuantityOfProjectTabletData = [
        { column: 'Projections', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
        { column: 'Current Revenue', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
        { column: 'Expected Revenue', quantity_of_project: 'N/A', nrc: 'N/A', mrc: 'N/A' },
    ];
    const AgeingProjectsInProgressTableColumn = [
        { label: t('ageing.label') },
        { label: t('count.label') },
        { label: t('nrc.label') + currencySymbol },
        { label: t('mrc.label') + currencySymbol },
        { label: t('totalRevenueN.label') + currencySymbol },
    ];
    const AgeingProjectsInProgressTabletData = [
        { ageing: '0 - 30', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '31 - 60', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '61 - 90', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: '91 - above', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
        { ageing: 'Total', counts: 'N/A', nrc: 'N/A', mrc: 'N/A', total_revenue: 'N/A' },
    ];
    const AnalysisOfOutOfSLACausesTableColumn = [
        { label: t('column.label') },
        { label: t('category.label') },
        { label: t('count.label') },
        { label: t('nrc.label') },
        { label: t('mrc.label') },
    ];
    const AnalysisOfOutOfSLACausesTabletData = [
        { column: '1', category: 'Customer install delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '2', category: 'Customer integration delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '3', category: 'Customer acceptance delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '4', category: 'Inq Internal delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '5', category: '3rd party delay', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '6', category: 'Equipment order and delivery', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
        { column: '7', category: 'Solution redesigning delays', count: 'N/A', nrc: 'N/A', mrc: 'N/A', percentage: 'N/A' },
    ];
    const FY21ProjectRevenueData = [
        {
            name: 'MRC',
            type: 'line',
            stack: 'Total',
            data: [7.06, 3.42, 13.10, 11.96, 11.85, 14.17, 6.56, 15.50, 2.65]
        },
        {
            name: 'NRC',
            type: 'line',
            stack: 'Total',
            data: [9.09, 4.98, 10.63, 11.73, 6.70, 9.56, 8.25, 16.54, 3.75]
        }
    ]
    const FY21ProjectRevenueDataKeyXAxis = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]
    const [projectInProgressBarChartData, setProjectInProgressBarChartData] = useState([
        {
            name: 'MRC' + currencySymbol,
            type: 'bar',
            data: [22564852, 2658654, 4582635, 29568945]
        },
        {
            name: 'NRC' + currencySymbol,
            type: 'bar',
            data: [30564852, 3658654, 4982635, 39568945]
        }
    ]);
    const projectInProgressBarChartYAxisData = ['Projects in Progress', 'Projects Closed', 'Projects On Hold', 'Total Projects in PMO Portfolio' + currencyCode];
    useEffect(() => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (COUNTRY_LIST.IVORY_COAST === countryName) {
            billing_country = `cote d'ivoire`;
        }
        if (count === 1) {
            let startDateBefore30days = moment(convertEndDate).subtract(30, 'day').format("YYYY-MM-DD");
            setStartDate(new Date(startDateBefore30days));
            getAllList({
                variables: {
                    startEnddate: "start_date=" + startDateBefore30days + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
            count++;
        }
        // // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            let startDateBefore30days = moment(convertEndDate).subtract(30, 'day').format("YYYY-MM-DD");
            setStartDate(new Date(startDateBefore30days));
            getAllList({
                variables: {
                    startEnddate: "start_date=" + startDateBefore30days + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
            count++;
        }
    }, []);
    // }, [projectInProgressData, projectInProgressBarChartData, countsOfProjectsProgressData]);
    const handlePageChangeProjectList = (event, value) => {
        setProjectDetailList({ ...ProjectDetailList, loading: true, error: '', data: [] })
        setPage(value);
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        if (COUNTRY_LIST.IVORY_COAST === countryName) {
            billing_country = `cote d'ivoire`;
        }
        getAllPaginatedProjectList({
            variables: {
                startEnddate: "start_date=" + startDate + "&end_date=" + endDate + '&page=' + value + "&billing_country=" + billing_country + "&data_for=" + ProjectDetailList.listName
            }
        })
    };
    const [getAllList,{loading:listLoading}] = useLazyQuery(GET_START_END_DATE_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            if (data && data.getStartEndDateProjectAnalysis) {
                let parseResult = JSON.parse(data.getStartEndDateProjectAnalysis.teamListData);
                // console.log("parse data anylectis : ", parseResult);
                setList(parseResult);
                if (parseResult && parseResult.projects_in_progress) {
                    setProjectInProgressData([
                        { name: t('mrc.label') + currencySymbol, value: parseResult.projects_in_progress.total_mrc_for_progress.total_mrc__sum ? parseResult.projects_in_progress.total_mrc_for_progress.total_mrc__sum : 50 },
                        { name: t('nrc.label') + currencySymbol, value: parseResult.projects_in_progress.total_nrc_for_progress.total_nrc__sum ? parseResult.projects_in_progress.total_nrc_for_progress.total_nrc__sum : 50 }]);
                    setProjectInProgressBarChartData([
                        {
                            name: 'MRC' + currencySymbol,
                            type: 'bar',
                            data: [
                                parseResult.projects_in_progress.total_mrc_for_progress.total_mrc__sum ? parseResult.projects_in_progress.total_mrc_for_progress.total_mrc__sum : 205200,
                                parseResult.projects_in_progress.total_mrc_for_closed.total_mrc__sum ? parseResult.projects_in_progress.total_mrc_for_closed.total_mrc__sum : 205200,
                                parseResult.projects_in_progress.on_hold_mrc.total_mrc__sum ? parseResult.projects_in_progress.on_hold_mrc.total_mrc__sum : 205200,
                                parseResult.projects_in_progress.total_project_mrc.total_mrc__sum ? parseResult.projects_in_progress.total_project_mrc.total_mrc__sum : 205200]
                        },
                        {
                            name: 'NRC' + currencySymbol,
                            type: 'bar',
                            data: [
                                parseResult.projects_in_progress.total_nrc_for_progress.total_nrc__sum ? parseResult.projects_in_progress.total_nrc_for_progress.total_nrc__sum : 205200,
                                parseResult.projects_in_progress.total_nrc_for_closed.total_nrc__sum ? parseResult.projects_in_progress.total_nrc_for_closed.total_nrc__sum : 205200,
                                parseResult.projects_in_progress.on_hold_nrc.total_nrc__sum ? parseResult.projects_in_progress.on_hold_nrc.total_nrc__sum : 205200,
                                parseResult.projects_in_progress.total_project_nrc.total_nrc__sum ? parseResult.projects_in_progress.total_project_nrc.total_nrc__sum : 205200]
                        }
                    ])
                }
                if (parseResult) {
                    setCountsOfProjectsProgressData([
                        { name: '0 - 30', value: parseResult.project_30_days && parseResult.project_30_days.count ? parseResult.project_30_days.count : 10 },
                        { name: '31 - 60', value: parseResult.project_60_days && parseResult.project_60_days.count ? parseResult.project_60_days.count : 10 },
                        { name: '61 - 90', value: parseResult.project_90_days && parseResult.project_90_days.count ? parseResult.project_90_days.count : 10 },
                        { name: '91 - 120', value: parseResult.project_120_days && parseResult.project_120_days.count ? parseResult.project_120_days.count : 10 },
                        { name: '121 - above', value: parseResult.project_121_above && parseResult.project_121_above.count ? parseResult.project_121_above.count : 60 }
                    ])
                }
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get start end date data Analytics :", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const [getAllPaginatedProjectList] = useLazyQuery(GET_START_END_DATE_PAGINATED_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getStartEndDatePaginatedProjectAnalysis) {
                let parseResult = JSON.parse(data.getStartEndDatePaginatedProjectAnalysis.teamListData);
                // console.log("parse data anylectis : ", parseResult);
                setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: parseResult })
            } else {
                setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
            }
        },
        onError: error => {
            console.log("error get start end date data Analytics :", error)
            setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
        }
    });
    const [getExcelFileUrlPaginatedProjectList] = useLazyQuery(GET_EXCEL_FILE_URL_PAGINATED_PROJECT_ANALYSIS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setDownloadFileLoading(false);
            if (data && data.getExcelFileURLPaginatedProjectAnalysis) {
                let parseResult = JSON.parse(data.getExcelFileURLPaginatedProjectAnalysis.teamListData);
                // console.log("parse data anylectis excel url: ", parseResult);
                if (parseResult && parseResult.url) {
                    Message.success('Projects excel file downloaded successfully');
                    window.location.href = parseResult.url;
                }
                // setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: parseResult })
            } else {
                // setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
            }
        },
        onError: error => {
            setDownloadFileLoading(false);
            console.log("error get start end date data Analytics :", error)
            setProjectDetailList({ ...ProjectDetailList, loading: false, error: '', data: [] })
        }
    });
    const columns = [
        {
            label: t('WIPReportSummary.label'),
            key: "report_summary"
        },
        {
            label: t('project.label') + ' ' + t('launched.label'),
            key: "report_summary"
        },
        {
            label: t('project.label') + ' ' + t('closed.label'),
            key: "report_summary"
        },
        {
            label: t('actual.label') + ' ' + t('nrc.label'),
            key: "report_summary"
        },
        {
            label: t('actual.label') + ' ' + t('mrc.label'),
            key: "report_summary"
        },
        {
            label: t('total.label') + ' ' + t('nrc.label'),
            key: "report_summary"
        },
        {
            label: t('total.label') + ' ' + t('mrc.label'),
            key: "report_summary"
        },
    ];

    const handleMenuItemClick = (elem, data) => { }
    const handleDateSubmitClick = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        let date1 = new Date(convertStartDate).getTime();
        let date2 = new Date(convertEndDate).getTime();
        if (date2 >= date1) {
            setLoading(true);
            setLoading(true);
            setError(true);
            if (COUNTRY_LIST.IVORY_COAST === countryName) {
                billing_country = `cote d'ivoire`;
            }
            getAllList({
                variables: {
                    startEnddate: "start_date=" + convertStartDate + "&end_date=" + convertEndDate + "&billing_country=" + billing_country
                }
            })
        } else {
            Message.error('Start date is greater than end date. Please select proper start date and end date');
        }
    };
    const handleSubmitProjectDetailList = (list, listName) => {
        setPage(1);
        // console.log("list handleSubmitProjectDetailList : ", list);
        // console.log("list handleSubmitProjectDetailList : ", listName);
        if (list && list.length != 0) {
            if (list.results && list.results.length != 0) {
                setProjectDetailList({ open: true, loading: false, error: '', data: list, listName: listName, excelSheetUrl: '' })
            }
        }
    }
    const handleClickDownloadExcelSheet = () => {
        if (COUNTRY_LIST.IVORY_COAST === countryName) {
            billing_country = `cote d'ivoire`;
        }
        setDownloadFileLoading(true);
        getExcelFileUrlPaginatedProjectList({
            variables: {
                startEnddate: "start_date=" + startDate + "&end_date=" + endDate + "&billing_country=" + billing_country + "&data_for=" + ProjectDetailList.listName
            }
        })
    }

  

    return (
        <>
        
       <div  className={classes.root1}>
      <AppBar position="static">
      <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t('ageingProjectsInProgress.label')} {...a11yProps(0)}  style={value === 0 ? { color: 'white' } : {}} />
          <Tab label= {t('projectInProgress.label')} {...a11yProps(1)}  style={value === 1 ? { color: 'white' } : {}} />
          <Tab label={t('inqPMGWeeklyReport.label')} {...a11yProps(2)}  style={value === 2 ? { color: 'white' } : {}} />
          <Tab label={'Project Report'} {...a11yProps(3)}  style={value === 3 ? { color: 'white' } : {}} />
          <Tab label=   {t('analysisOfOutOfSLACausesForOnHoldProjects.label')} {...a11yProps(4)}  style={value === 4 ? { color: 'white' } : {}} />
          <Tab label= {t('analysisOfOutOfSLACausesForInProgressProjects.label')} {...a11yProps(5)}  style={value === 5 ? { color: 'white' } : {}} />
          <Tab label={`${t('project.label')} and  ${t('count.label')}`} {...a11yProps(6)}  style={value === 6 ? { color: 'white' } : {}} />
          <Tab label= {t('FY21ProjectRevenue.label') + currencyCode + ' million)'} {...a11yProps(8)} {...a11yProps(7)}  style={value === 7 ? { color: 'white' } : {}} />
          {/* <Tab label= {t('FY21ProjectRevenue.label') + currencyCode + ' million)'} {...a11yProps(8)}  style={value === 8 ? { color: 'white' } : {}} />
          <Tab label={t('projectInProgress.label')} {...a11yProps(9)}  style={value === 9 ? { color: 'white' } : {}} />
          
        */}
        </Tabs>
      </AppBar>

    
      <TabPanel value={value} index={0}>
      <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('ageingProjectsInProgress.label')}
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <AgeingProjectsInProgressTableItem
                        data={list}
                        column={AgeingProjectsInProgressTableColumn}
                        loading={loading}
                        error={error}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('projectInProgress.label')}
                    </Typography>c
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <ProjectInProgressTableItem
                        data={list}
                        column={projectInProgressClosedAndHoldColumn}
                        loading={loading}
                        error={error}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Grid item xs={6}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('inqPMGWeeklyReport.label')}
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    {/* <CustomTable
                    data={PMOWeeklyReportData}
                    column={PMOWeeklyReportColumn}
                    loading={PMOWeeklyReport.loading}
                    /> */}
                    <CustomTable
                        data={list}
                        column={PMOWeeklyReportColumn}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('projectInProgress.label')}
                    </Typography>
                    {loading ? (
                        <div
                            style={{
                                width: '100%',
                                padding: 20,
                                textAlign: 'center',
                                display: 'inline-block'
                            }}>
                            <CircularProgress color="secondary" />
                        </div>
                    ) : <IncidentStatusPOC t={t} data={projectInProgressData} />
                    }<br />
                </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>Project Report
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <AnalyticsItem
                        data={list}
                        column={columns}
                        loading={loading}
                        startDate={startDate}
                        endDate={endDate}
                        error={error}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
      </TabPanel>

      <TabPanel value={value} index={4}>
      <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('analysisOfOutOfSLACausesForOnHoldProjects.label')}
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <AnalysisOfOutOfSLACausesTableItem
                        data={list}
                        column={AnalysisOfOutOfSLACausesTableColumn}
                        loading={loading}
                        error={error}
                        t={t}
                        onHold={true}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
      </TabPanel>
     
      <TabPanel value={value} index={5}>
      <Grid item xs={12}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('analysisOfOutOfSLACausesForInProgressProjects.label')}
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <AnalysisOfOutOfSLACausesTableItem
                        data={list}
                        column={AnalysisOfOutOfSLACausesTableColumn}
                        loading={loading}
                        error={error}
                        t={t}
                        onHold={false}
                        handleSubmitProjectDetailList={handleSubmitProjectDetailList}
                    /><br />
                </Grid>
      </TabPanel>

      <TabPanel value={value} index={6}>
      <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('project.label')}
                    </Typography>
                    {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <IncidentStatusPOC t={t} data={NrcNNgnData} />
                    <br />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <Typography
                        variant={'h4'}
                        style={{
                            ...marginGenerator('mt-32'),
                            ...marginGenerator('mb-8'),
                            borderBottom: `1px solid ${colors.grey[200]}`
                        }}>
                        {t('count.label')}
                    </Typography>
                    {loading ? (
                        <div
                            style={{
                                width: '100%',
                                padding: 20,
                                textAlign: 'center',
                                display: 'inline-block'
                            }}>
                            <CircularProgress color="secondary" />
                        </div>
                    ) :
                        <IncidentStatusPOC t={t} data={countsOfProjectsProgressData} />}
                    <br />
                </Grid>
      </TabPanel>

      <TabPanel value={value} index={7}>
      <Grid item xs={12}>

      {!listLoading && <div>
        
        <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('startDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'subtitle2'}>{t('endDate.label')}</Typography>
                    <DatePicker
                        portalId="root-portal"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        color="primary"
                        variant={'contained'}
                        onClick={handleDateSubmitClick}>{t('submit.label')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {COUNTRY_LIST.NIGERIA === countryName ? <Typography variant={'subtitle2'}>Currency denoted in USD($)</Typography> : ''}
                </Grid>
        
        </div>}
                    <LineChartWrapper
                        data={FY21ProjectRevenueData}
                        dataKeyXAxis={FY21ProjectRevenueDataKeyXAxis}
                        stackKeyNames={['MRC', 'NRC']}
                        title={t('FY21ProjectRevenue.label') + currencyCode + ' million)'}
                    />
                    <br />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <div
                            style={{
                                width: '100%',
                                padding: 20,
                                textAlign: 'center',
                                display: 'inline-block'
                            }}>
                            <CircularProgress color="secondary" />
                        </div>
                    ) :
                        <BarHorizontalChartWrapper
                            data={projectInProgressBarChartData}
                            dataKeyYAxis={projectInProgressBarChartYAxisData}
                            title={t('projectInProgress.label')}
                        />
                    }
                    <br />
                    <br />
                </Grid>
      </TabPanel>

   

    

        <ProjectDetailListModal
                    t={t}
                    open={ProjectDetailList.open}
                    handleClose={() => {
                        setProjectDetailList({ open: false, loading: false, error: '', data: [], listName: '', excelSheetUrl: '' });
                        setPage(1);
                    }}
                    data={ProjectDetailList.data}
                    page={page}
                    loading={ProjectDetailList.loading}
                    error={ProjectDetailList.error}
                    listName={ProjectDetailList.listName}
                    excelSheetUrl={ProjectDetailList.excelSheetUrl}
                    handlePageChangeProjectList={handlePageChangeProjectList}
                    handleClickDownloadExcelSheet={handleClickDownloadExcelSheet}
                    downloadFileLoading={downloadFileLoading}
                />
    </div>
        </>
    );
};
export default withTranslation()(Analytics);