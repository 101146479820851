import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { get } from "lodash-es";
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { useDropzone } from 'react-dropzone';

import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
    width: "100%"
  }
});

const useStyles = makeStyles(theme => ({
  testCon: { marginRight: '6px', marginLeft: '6px' },
  chartTitle: { marginTop: '10px' },
  paging: {
    float: "right",
    marginBottom: 30,
    marginTop: 20
  },
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2)
  },
  inputBase: {
    width: '100%',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 15,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const acceptStyle = {
  borderColor: "#afeeee",
  backgroundColor: "#e0ffff"
};

const rejectStyle = {
  borderColor: "#ff7f50",
  backgroundColor: "#ffe4e1"
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading || disabledValue}
    >
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const OcrMultipleDocUpload = ({
  t,
  open,
  onClose,
  ocrRuleData,
  handleSubmitOfMultipleUpload,
  ocrMultipleDocLoading,
  handleFilterdataForOptions,
  ocrPlaceData,
  ocrProjectData,
  ocrFolderData,
  loadingOcrPlace,
  loadingOcrProject,
  loadingOcrFolder,
  isDataExists=null
}) => {
  
  const classes = useStyles();
  const [selectedRuleId, setSelectedRuleId] = useState('');
  const [allFiles, setAllFiles] = useState([]);
  const [locationValue, setLocationValue] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [folderalue, setFolderalue] = useState('');

  const onDrop = useCallback(acceptedFiles => {
    setAllFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: "image/*,.pdf" });


  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragReject, isDragAccept]
  );

  useEffect(() => {
    setSelectedRuleId('');
    setAllFiles([]);
    setLocationValue('');
    setProjectValue('');
    setFolderalue('');
    handleFilterdataForOptions(null, 'null');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!open])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
      <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
        Upload Multiple Doc for OCR
          <div>
              <OutlinedSelect
                  val={get(isDataExists, 'parents.[1]', null) ? get(isDataExists, 'parents.[1]', null):locationValue}
                  label="Select Loaction"
                  handleChange={evt => {
                      handleFilterdataForOptions(evt.target.value, 'project');
                      setLocationValue(evt.target.value);
                  }}
                  selectStyle={{...paddingGenerator('p-8')}}
                  styleOverrides={{ width: '100%'}}
                  options={(!loadingOcrPlace && ocrPlaceData) ? ocrPlaceData.data : []}
                  disabled={get(isDataExists, 'parents.[1]', null)}
              />
              </div>
              <div>
              <OutlinedSelect
                  val={get(isDataExists, 'parents.[0]', null) ? get(isDataExists, 'parents.[0]', null):projectValue}
                  label="Select Project"
                  handleChange={evt => {
                      handleFilterdataForOptions({place: locationValue,project:evt.target.value}, 'folder');
                      setProjectValue(evt.target.value);
                  }}
                  selectStyle={{...paddingGenerator('p-8')}}
                  styleOverrides={{ width: '100%' }}
                  options={(!loadingOcrProject && ocrProjectData) ? ocrProjectData.data : []}
                  disabled={get(isDataExists, 'parents.[0]', null)}
              />
              </div>
              <div>
              <OutlinedSelect
                  val={get(isDataExists, 'item.id', null) ? get(isDataExists, 'item.id', null):folderalue}
                  label="Select Folder"
                  handleChange={evt => {
                    setFolderalue(evt.target.value);
                  }}
                  selectStyle={{...paddingGenerator('p-8')}}
                  styleOverrides={{ width: '100%' }}
                  options={(!loadingOcrFolder && ocrFolderData) ? ocrFolderData.data : []}
                  disabled={get(isDataExists, 'item.id', null)}
              />
              </div>
          </div>     
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContain}>
        <Typography style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>Select any Rule</Typography>

        {ocrRuleData && ocrRuleData.data.length > 0 ? ocrRuleData.data.map((rowitem) => (
          <Chip
            label={rowitem.rule_name ? rowitem.rule_name : '-'}
            variant={rowitem.id === selectedRuleId ? '' : "outlined"}
            style={{ marginRight: '5px' }}
            onClick={() => setSelectedRuleId(rowitem.id)}
            color={rowitem.id === selectedRuleId ? 'primary' : ""}
          />
        )) :
          <Typography variant={'subtitle2'} style={{ display: 'flex', justifyContent: 'center' }}>Record not found!</Typography>
        }

        <section className="container" style={{ marginTop: 20 }}>
          <div {...getRootProps({ className: "dropzone", style })}>
            <input {...getInputProps()} />
            <p>Drag'n'drop images, or click to select files</p>
            <p>we only support images right now for bulk upload</p>
          </div>
          <aside>
            <h4>Files</h4>
            <ul>{
              allFiles.map((file) => (
                <li key={file.path}>
                  {file.path} - {file.size} bytes
                </li>
              ))}
            </ul>
          </aside>
        </section>
        <Grid container spacing={2}>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            color="primary"
            onClick={onClose}
          >
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            loading={ocrMultipleDocLoading}
            disabledValue={!selectedRuleId}
            onClick={() => {
              handleSubmitOfMultipleUpload({
                selectedRuleId,
                allFiles,
                locationValue: locationValue || get(isDataExists, 'parents.[1]', null),
                projectValue: projectValue || get(isDataExists, 'parents.[0]', null),
                folderalue: folderalue || get(isDataExists, 'item.id', null)
              })
            }}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default OcrMultipleDocUpload
