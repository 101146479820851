import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const getUnderlayProvidersList = async (page = '', limit = '', word = '') => {
  try {
    const response = await axios.get(
      `underlayProvider?page=${page}&limit=${limit}&search=${word}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getUnderlayProviderById = async id => {
  try {
    const response = await axios.get(`underlayProvider/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const addUnderlayProvider = async payload => {
  try {
    const response = await axios.post(`underlayProvider`, payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const updateUnderlayProviderById = async (id, payload) => {
  try {
    const response = await axios.put(`underlayProvider/${id}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deleteUnderlayProviderById = async id => {
  try {
    const response = await axios.delete(`underlayProvider/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

export {
  getUnderlayProvidersList,
  getUnderlayProviderById,
  addUnderlayProvider,
  updateUnderlayProviderById,
  deleteUnderlayProviderById
};
