import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import SearchBox from '../../../../../components/SearchBox';
import OutlinedSelect from '../../../../../components/OutlinedSelect';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
  
const useStyles = makeStyles({
    root: { fontSize: '16px'},
    pre: { padding: '10px 30px', margin: '0' }
});

const AssignSubscriptionToUserModal = ({t, 
    open, 
    handleClose, 
    list, 
    handleSubmitAssignUser,
    subscriptionData,
    teamLoading,
    assignLoading
}) => {

    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        if(subscriptionData && subscriptionData.assignUsers){
            setSelectedUsers(subscriptionData.assignUsers); 
        }
    }, [subscriptionData]);

    const handleSelectUser = (evt) => {
        setSelectedUsers(prevState => {
            let oldState = [...prevState];
            if (evt.target.checked) {
                oldState.push(evt.target.value);
            } else {
                oldState.splice(oldState.indexOf(evt.target.value), 1);
            }
            return oldState;
        });
    }

    return (
        <Dialog 
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Users List
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('select.label')}</TableCell>
                                <TableCell>{t('name.label')}</TableCell>
                                <TableCell>{t('email.label')}</TableCell>
                                <TableCell>{t('role.label')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.length > 0 ? list.map((row, indx) => (
                                <TableRow key={indx}>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            style={{padding: 0}}
                                            value={row.id}
                                            checked={selectedUsers.includes(row.id)}
                                            onChange={handleSelectUser}
                                        />
                                    </TableCell>
                                    <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                                    <TableCell>{row.email ? row.email : "-"}</TableCell>
                                    <TableCell>{row.roles ? row.roles : '-'}</TableCell>
                                </TableRow>
                            )) : 
                                <TableRow>
                                    <TableCell colSpan={4}>{teamLoading ? <CircularProgress size={20} /> : "Record Not Found!" }</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={evt => {
                    handleClose();
                    setSelectedUsers([]);
                }}
                color="primary"
                autoFocus
                >
                    Close
                </Button>
                <Button 
                onClick={() => {
                    if(!selectedUsers.length || assignLoading){
                        return null;
                    }
                    return handleSubmitAssignUser(selectedUsers);
                }}
                color="primary"
                variant="contained"
                disabled={(!selectedUsers.length || assignLoading)}
                >
                    {assignLoading ? <CircularProgress size={20} /> : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AssignSubscriptionToUserModal;