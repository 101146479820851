import React, {useState, useEffect} from 'react';
import { get } from "lodash-es";
import InputLabel from '@material-ui/core/InputLabel';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

const CreateProject = ({
    t,
    open,
    onClose,
    loading,
    ocrPlaceData,
    handleSubmitOfOcrProject,
    ocrProjectSubmit,
    isLocationDataexists=null
}) => {

    const classes = useStyles();
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [locationValue, setLocationValue] = useState('');

    useEffect(() => {
      setProjectName('');
      setProjectDescription('');
      setLocationValue('');
    }, [!open]);

    return (
    <Dialog 
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
             Create Project
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
        <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
            <Grid item xs={3}>
                <OutlinedSelect
                    val={get(isLocationDataexists, 'item.id', null) ? get(isLocationDataexists, 'item.id', null):locationValue}
                    label="Select Location"
                    handleChange={evt => {
                        setLocationValue(evt.target.value);
                    }}
                    IconComponent={null}
                    selectStyle={{...paddingGenerator('p-8')}}
                    styleOverrides={{ width: '100%', marginRight: 0 }}
                    options={(!loading && ocrPlaceData) ? ocrPlaceData.data : []}
                    disabled={get(isLocationDataexists, 'item.id', null)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                  value={projectName}
                  id="outlined-search" 
                  label="Project Name" 
                  type="search"
                  style={{ width: '100%'}}
                  onChange={(e) => setProjectName(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={projectDescription}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                style={{ width: '100%'}}
                onChange={(e) => setProjectDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={onClose}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent 
                  t={t}
                  loading={ocrProjectSubmit}
                  disabledValue={false}
                  onClick={() => handleSubmitOfOcrProject({locationValue: get(isLocationDataexists, 'item.id', null)|| locationValue, projectName, projectDescription })}
                />
            </Grid>          
        </DialogActions>
    </Dialog>
  );
};

export default CreateProject;
