import React from 'react';
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";


const resizeStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0",
    overflow: 'hidden'
  };

const DashbordWordCollection = ({
    t,
    words
}) => {
  
  return (
    <div>
    <Resizable
      defaultSize={{
        width: 1195,
        height: 400
      }}
      style={resizeStyle}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {
          words && words.length > 0 ? (
           <ReactWordcloud words={words} />
          ):(
            <div 
            style={{ color: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%' }}
            >
              There are not enough Words yet, please upload documents to see WordMap chart appering here.
            </div>
          )
        }
      </div>
    </Resizable>
  </div>
  );
};

export default DashbordWordCollection;
