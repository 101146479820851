import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import { getFileExtension } from '../../../../utils';
import { marginGenerator } from '../../../../theme/utils';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { t, children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

function LiveCamPlayerModal({
    t,
    open,
    url,
    handleClose
}) {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle t={t} id="place-template-title">
                <IconButton style={{ float: 'right' }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid xs={12}>
                        {url && getFileExtension(url) === 'm3u8' ?
                            <ReactPlayer
                                width="100%"
                                height="100%"
                                url={url}
                                controls
                                playing={true}
                            /> :
                            <React.Fragment>
                                <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" target="_blank" title={t("openUrlTitle.label")} href={url}>{t("openUrl.label")}</Button>
                                <Grid style={{ ...marginGenerator('mb-24') }}>
                                    <iframe
                                        title='LiveCam'
                                        style={{ width: '100%', height: 500, overflow: 'visible', display: "inline-block" }}
                                        src={url}
                                        width="100%"
                                        frameBorder="0"
                                        loading="lazy"
                                    />
                                </Grid>
                            </React.Fragment>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

LiveCamPlayerModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(LiveCamPlayerModal);
