import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import {
  getPodList,
  getPodListWithRegionId,
  getRegions
} from '../../../services/fcrService';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';

const Step1Port = ({
  getPodId,
  selectedDataCenterId,
  setSelectedDataCenterId,
  dataCenters,
  handleNext,
  nextStepIsValid
}) => {
  let history = useHistory();

  const [regions, setRegions] = useState([]);
  const [isActive, setIsActive] = useState('');
  const [tests, setTest] = useState('');
  const [selectCountryFilter, setSelectCountryFilter] = useState('');
  const [selectCityFilter, setSelectCityFilter] = useState('');
  const [selectRegionFilter, setSelectRegionFilter] = useState('');
  const [podList, setPodList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const selectCountry = event => {
    setSelectCountryFilter(event.target.value);
    setSelectCityFilter('');
    setIsActive('');
    const filterPodListAsPerCountries =
      podList &&
      podList?.filter(pod => pod?.dc_id?.country === event.target.value);
    const response = filterPodListAsPerCountries?.map(pod => pod?.dc_id?.city);
    const provinces = [...new Set(response)];
    setProvinces(provinces);
  };

  const selectCity = async event => {
    setSelectCityFilter(event.target.value);
    setIsActive('');
  };

  const selectRegion = async e => {
    e.preventDefault();
    setSelectRegionFilter(e.target.value);
    if (e.target.value === '') {
      setSelectedDataCenterId('');
    }
    setIsActive('');
    setSelectCityFilter('');
    setSelectCountryFilter('');
  };

  const handleDataCenter = (event, index) => {
    event.preventDefault();
    const podId = event.target.value;
    getPodId(podId);
    setIsActive(index);
  };

  useEffect(() => {
    const getPodByRegionId = async () => {
      setLoading(true);
      if (selectRegionFilter === undefined || selectRegionFilter === '') {
        const res = await getPodList();
        if (res?.status === 200) {
          setPodList(res?.data?.result?.data);
          setLoading(false);
        } else setLoading(false);
      } else {
        const res = await getPodListWithRegionId(selectRegionFilter);
        if (res?.status === 200) {
          setPodList(res?.data?.result?.data);
          setLoading(false);
        } else setLoading(false);
      }
    };
    getPodByRegionId();
  }, [selectRegionFilter, selectCountryFilter]);

  useEffect(() => {
    const duplicateCountries = podList?.map(pod => pod?.dc_id?.country);
    const NoDuplicateContries = [...new Set(duplicateCountries)];
    setCountries(NoDuplicateContries);
  }, [podList]);

  useEffect(() => {
    const duplicateCity = podList
      ?.filter(filtered =>
        selectCountryFilter || selectCityFilter
          ? filtered?.dc_id?.country === selectCountryFilter ||
            filtered?.dc_id?.city === selectCityFilter
          : true
      )
      ?.map(pod => pod?.dc_id?.city);
    const NoDuplicateCities = [...new Set(duplicateCity)];
    setProvinces(NoDuplicateCities);
  }, [podList]);

  useEffect(() => {
    const getRegion = async () => {
      const res = await getRegions();
      if (res?.status === 200) {
        const getRegion = res?.data?.result?.data;
        setRegions(getRegion);
      }
      const test = JSON.parse(localStorage.getItem('test'));
      setTest(test);
    };
    getRegion();
    const GetPodList = async () => {
      setLoading(true);
      const res = await getPodList();
      setPodList(res?.data?.result?.data);
      if (res?.data?.statusCode === 200) {
        setLoading(false);
      } else setLoading(false);
    };
    GetPodList();
  }, []);

  useEffect(() => {
    const selectedDataCenter = dataCenters.find(
      obj => obj?._id === selectedDataCenterId
    );

    if (selectedDataCenterId === '') {
      setSelectRegionFilter('');
      setSelectCountryFilter('');
      setSelectCityFilter('');
    } else {
      setSelectRegionFilter(selectedDataCenter?.region_id?._id);
      setSelectCountryFilter(selectedDataCenter?.country);
    }
  }, [selectedDataCenterId, dataCenters]);

  return (
    <>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center">
          <Typography variant="body2">
            For instructions on{' '}
            <a
              href="https://www.google.com"
              target="blank"
              style={{ textDecoration: 'none' }}>
              how to create a port
            </a>
            , visit Inq port documentation.
          </Typography>
        </Box>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}>
          <Grid item>
            <Paper
              style={{
                marginTop: '35px',
                maxWidth: '500px',
                borderRadius: '10px',
                padding: '10px'
              }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TextField
                    select
                    value={selectRegionFilter}
                    onChange={selectRegion}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    size="small">
                    <option value="">Region</option>
                    {regions &&
                      regions?.map(region => {
                        return (
                          <option key={region._id} value={region._id}>
                            {region.region_name}
                          </option>
                        );
                      })}
                  </TextField>

                  <TextField
                    select
                    value={selectCountryFilter}
                    onChange={selectCountry}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    size="small">
                    <option value="">Country</option>
                    {countries?.map(country => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    select
                    value={selectCityFilter}
                    onChange={selectCity}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    size="small">
                    <option value="">Province/State</option>
                    {provinces?.map(province => (
                      <option key={province} value={province}>
                        {province}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <div style={{ height: '200px', width: '500px' }}>
                    <PerfectScrollbar>
                      {!loading &&
                        podList &&
                        podList
                          ?.filter(filtered =>
                            selectCountryFilter || selectCityFilter
                              ? filtered?.dc_id?.country ===
                                  selectCountryFilter ||
                                filtered?.dc_id?.city === selectCityFilter
                              : true
                          )
                          ?.map((pod, index) => (
                            <button
                              variant="outlined"
                              onClick={event => handleDataCenter(event, index)}
                              style={{
                                marginLeft: '50px',
                                width: '400px',
                                height: '70px',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '10px',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor:
                                  index === isActive ? '#5a1f2a' : '',
                                color: index === isActive ? 'white' : ''
                              }}
                              key={pod?._id}
                              value={pod?._id}>
                              {pod?.name}
                              <br />
                              {pod.dc_id?.city},{pod.dc_id?.country},
                              {pod?.region_id?.region_name}
                            </button>
                          ))}
                      {podList === undefined && (
                        <div
                          style={{
                            height: '200px',
                            width: '450px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          No Pods
                        </div>
                      )}
                      {loading && (
                        <div
                          style={{
                            height: '200px',
                            width: '450px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <CircularProgress />
                        </div>
                      )}
                    </PerfectScrollbar>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Grid>
            {!nextStepIsValid && (
              <Typography variant="subtitle1" color="error">
                Select a location to proceed
              </Typography>
            )}
            <Grid item>
              <Button
                onClick={handleNext}
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                disabled={!nextStepIsValid}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Step1Port;
