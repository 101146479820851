import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import LaptopIssuePanelItem from './LaptopIssuePanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import LaptopIssue from './LaptopIssue';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import { GET_LAPTOP_ISSUES, GET_SECTION_LIST } from '../../queries'
import { CREATE_LAPTOP_ISSUE_REQUEST, DELETE_LAPTOP } from '../../mutation';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const LaptopIssuePanel = ({
    t,
    allowConfigure,
    data,
    departments,
    users
}) => {
    const classes = useStyles();

    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
    const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
    const [briefModalOpen, setBriefModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");


    const [selectedUserItem, setSelectedUserItem] = useState(null);
    const [signatureState, setSignatureState] = useState({
        imangmentSign: "",userSign: ""
    });

    const subscriptionId = data.id;

    const [getAllLaptopIssuesRequestsList, {
        loading,
        data: laptopIssuesList
    }] = useLazyQuery(GET_LAPTOP_ISSUES, {
        fetchPolicy: "no-cache"
    });

    const [createLaptopIssueReportRequest] = useMutation(CREATE_LAPTOP_ISSUE_REQUEST, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Laptop issue request created successfully');
            setBriefModalOpen(false);
            return getAllLaptopIssuesRequestsList({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createLaptopIssueReportRequest({ variables: {
            ...signatureState,
            ...formData
        }});
    }

    const [deleteLaptop, { loading: deleteLaptopLoading }] = useMutation(DELETE_LAPTOP, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Laptop deleted successfully');
            return getAllLaptopIssuesRequestsList({variables: {...filterStateData, subscriptionId}});
        }
    });

    const {
        loading: sectionLoading,
        data: sectionList,
        error: sectionError,
        refetch: reloadSections
    } = useQuery(GET_SECTION_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            subscriptionId: subscriptionId
        }
    });
    
    const columns = [
        {
            label: 'User Name',
            key: "userName"
        },
        {
            label: 'Issue Date',
            key: "date"
        },
        {
            label: 'Department',
            key: "department"
        },
        {
            label: "Desigantion",
            key: "desigantion"
        },{
            label: 'Actions',
            key: "actions"
        },
        // {
        //     label: 'Organizer',
        //     key: "organizer"
        // },
        // {
        //     label: 'Brief Notes',
        //     key: "briefNotes"
        // },
    ];

    const meetingRequestList = [
        {
            name: "Onboarding",
            schedule: new Date(),
            status: "Scheduled",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Intro",
            schedule: new Date(),
            status: "Scheduled",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Marketing",
            schedule: new Date(),
            status: "Completed",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        },
        {
            name: "Sales",
            schedule: new Date(),
            status: "Canceled",
            attendees: "test",
            organizer: "test",
            meetingNotes: "multiple line code",
            actions: ""
        }
    ];

    const handleNewMeetingRequest = () => {
        setBriefModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Section',
            handleChange: (evt) => {
                console.log('section', evt.target.value);
                setFilterStateData({...filterStateData, section: evt.target.value});
            },
            val: filterStateData.section,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: sectionList && sectionList.getAllSections ? sectionList.getAllSections.data :[]
        }
    ];

    const handleMenuItemClick = (type, rowItem) => {
        setSelectedUserItem(rowItem);
        if(type === "sign"){
           setSelectedUserItem(rowItem);
           setSelectedMeetingItem(rowItem);
           setSignaturePadModalOpen(true);
           setSignatureType(type);
        }else if(type === "edit"){
           setBriefModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteLaptop({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    useEffect(() => {
        return getAllLaptopIssuesRequestsList({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllLaptopIssuesRequestsList({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    return (
    <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewMeetingRequest}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))} 
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !loading ? laptopIssuesList && laptopIssuesList.getAllLaptopIssue && laptopIssuesList.getAllLaptopIssue.data.length > 0 ?
                        laptopIssuesList.getAllLaptopIssue.data.map((rowitem, rowIndex) => (
                            <LaptopIssuePanelItem
                                key={rowIndex}
                                t={t}
                                data={rowitem}
                                handleMenuItemClick={handleMenuItemClick}
                                allowConfigure={allowConfigure}
                            />
                        )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow> : null
                    }
                    {loading && 
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <div style={{textAlign: "center", width: "100%"}}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <LaptopIssue
                t={t}
                open={briefModalOpen}
                handleClose={() => setBriefModalOpen(false)}
                handleOpenSignPanModal={handleOpenSignPanModal}
                handleCreateRequestSubmit={handleCreateRequestSubmit}
                data={selectedUserItem}
                signatures={signatureState}
                departments={departments}
                users={users}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
    </div>
  )
}

export default LaptopIssuePanel