import React, { useState, useEffect, useContext } from 'react';
import ShayonaTripsListing from './ShayonaTripsListing';
import { makeStyles } from '@material-ui/core/styles';
import { convertLocalToUTCDate } from '../../../../../utils';
import { ShayonaCementsContext } from '../../../../../contexts/ShayonaCementsProvider';


const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 250,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
  
}));

const initTripFilter = {time: 'Today', startDate: null, endDate: null, status: ''};

const ShayonaTrips = ({
  t
}) => {
  
  const classes = useStyles();
  const [shayonaTripState, setShayonaTripState] = useState({...initTripFilter});
  const {
    organizationId,
    shayonaApisFilterMethod,
    tripsStatusOption
  } = useContext(ShayonaCementsContext);

  const handleClearFilter = (type) => {
        setShayonaTripState({...initTripFilter});
        shayonaApisFilterMethod('trip',{startTime: null, endTime: null, status:'', truckNumber: ''});
  };
  
  const shayonaTripFilter =  [
    {
        label: t("startDate.label"),
        handleChange:(newDate) => {
            setShayonaTripState({...shayonaTripState, startDate: newDate});
        },
        val: shayonaTripState.startDate,
        type: "input",
        styleOverrides: { marginRight: 24 },
        className: classes.dateInput
    },
    {
        label: t("endDate.label"),
        handleChange:(newDate) => {
            setShayonaTripState({...shayonaTripState, endDate: newDate})
        },
        val: shayonaTripState.endDate,
        type: "input",
        styleOverrides: { marginRight: 24 },
        className: classes.dateInput,
    },
    {
      label: t('criteria.label'),
      handleChange: evt => {
          setShayonaTripState({...shayonaTripState, status: evt.target.value});
      },
      val: shayonaTripState.status,
      options: tripsStatusOption,
      styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
    }
  ];

  useEffect(() => {
    let startTime = null;
    let endTime = null;
    if(shayonaTripState.startDate && shayonaTripState.endDate){
        startTime = new Date(convertLocalToUTCDate(shayonaTripState.startDate));
        startTime = Math.floor(startTime.getTime() / 1000);
        endTime = new Date(convertLocalToUTCDate(shayonaTripState.endDate));
        endTime = Math.floor(endTime.getTime() / 1000);
    }
    if(startTime || endTime || shayonaTripState.status){
    shayonaApisFilterMethod('trip',{startTime, endTime, status:shayonaTripState.status, truckNumber: ''});
    }
}, [shayonaTripState]);

  return (
    <> 
      <ShayonaTripsListing
        t={t}
        filterList={shayonaTripFilter}
        handleClearFilter={handleClearFilter}
      />
    </>
  )
}

export default ShayonaTrips