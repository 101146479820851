import { gql } from 'apollo-boost';

export const INCIDENT_STATUS_POC_STATS = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    incidentStatusPOC(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      IncidentCompleted
      IncidentInProgress
      IncidentNotStarted
      TotalIncident
    }
  }
`;

export const INCIDENT_BY_SUBJECT = gql`
  query IncidentBySubject(
    $page: Int
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    incidentBySubject(
      page: $page
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      data
      total
    }
  }
`;

export const TOP_10_INCIDENT_TYPE = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    top1oIncidentType(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      name
      TotalIncident
    }
  }
`;

export const TOP_10_INCIDENT_BY_MONTH = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    top1oIncidentByMonth(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      name
      TotalIncident
    }
  }
`;

export const INCIDENT_BY_FISCAL_YEAR = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    totalIncidentByFiscalYear(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      month
      TotalIncident
    }
  }
`;

export const TOP5_INCIDENT_BY_FISCAL_YEAR = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    top5FaultTypeFiscalYear(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      data
    }
  }
`;

export const TOP5_INCIDENT_GROUP_BY_FISCAL_YEAR = gql`
  query IncidentStatusPOC(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    top5CategoryTypeFiscalYear(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      data
    }
  }
`;

export const GET_ALL_SERVICES_BY_COUNTRY = gql`
  query servicesByCountry(
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    servicesByCountry(
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      data
    }
  }
`;

export const INCIDENT_BY_REGION = gql`
  query IncidentByRegion(
    $page: Int
    $customer: String
    $startDate: String
    $endDate: String
  ) {
    incidentByRegion(
      page: $page
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      data
      total
    }
  }
`;

export const NEW_CUSTOMER_AVAILABILITY_REPORT = gql`
  query newAvailabilityReport(
    $customer: String
    $monthYear: String
    $subscription: String
    $reportType : String
    $organisations : String
    $org : String
    $platform: String
    $circuitId: String
    $customerName: String
  ) {
    newcustomerAvailabilityReport(
      customer: $customer
      monthYear: $monthYear
      organisations: $organisations
      platform: $platform
      org: $org
      subscription: $subscription
      reportType : $reportType
      circuitId : $circuitId
      customerName : $customerName
    ) {
      data {

         ticketId
         vodacomProblem
         incidentType
         incidentEndTime
         incidentTime
         closeDescription
         timeToRestore
         siteName
         circuitId
       }
      organisationName
      monthYear
      siteName
      platform
      circuitId
    }
  }
`;

export const GET_ALL_ENGINEERS_STATISTIC = gql`
  query getAllEngineerStatistics(
    $engineerStartDate : String
    $engineerEndDate : String
    $ticketType: String
  ){
    getAllEngineerStatistics(
      engineerStartDate : $engineerStartDate
      engineerEndDate : $engineerEndDate
      ticketType: $ticketType
    ){
      engineer
      openTicket
      closedTicket
    }
  }
`;