// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { ADD_UPDATE_CATEGORY, DELETE_VNF_MARKETPLACE, DELETE_VNF_MARKETPLACE_CATEGORY } from "../../mutations";
import { GET_OLD_CIRCUIT_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HistoricalCircuitIdsItem from "../HistoricalCircuitIdsItem";
import { GET_USER_INFO } from '../../../../Profile/queries';
import CreateImTeamModal from "../CreateImTeamModal";
import { ADD_IM_TEAM } from "../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../commonQueries';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const HistoricalCircuitIds = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [imTeam, setImTeam] = useState(false);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [allUserList, setAllUserList] = useState('');
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        getAllList({
            variables: {
                pageOrsearch: "page=1"
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            getAllList({
                variables: {
                    pageOrsearch: "page=1"
                }
            })
        }
    }, [refreshTab, CurrentUserName]);
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageOrsearch: "page=" + value + checkSearchFilterData
            }
        })
    };
    const [getAllList] = useLazyQuery(GET_OLD_CIRCUIT_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("install data : ", data);
            if (data && data.getOldCircuitList) {
                let parseResult = JSON.parse(data.getOldCircuitList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get historical list:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const columns = [
        {
            label: t('circuitId.label'),
            key: "contract_number"
        },
        {
            label: t('organisationName.label'),
            key: "contract_name"
        },
        {
            label: t('date.label'),
            key: "dstart_date"
        },
        {
            label: t('baseStation.label'),
            key: "baseStation"
        },
        {
            label: t('platform.label'),
            key: "platform"
        },
        {
            label: t('vendors.label'),
            key: "vendor"
        },
        {
            label: t('location.label'),
            key: "location"
        },
        {
            label: t('configuredDownCiruit.label'),
            key: "configured_down_ciruit"
        },
        {
            label: t('configuredUpCircuit.label'),
            key: "configured_up_circuit"
        },
        {
            label: t('status.label'),
            key: "status"
        },
        {
            label: t('productCLass.label'),
            key: "product_class"
        },
        {
            label: t('billing.label') + ' ' + t('date.label'),
            key: "billing_date"
        },
        {
            label: t('node.label'),
            key: "node"
        },
    ];
    const handleMenuItemClick = (elem, data) => {

    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')){
            Message.error("Please select start or end date.");
            return false;
        } 
        if(convertStartDate === 'Invalid date'){
            convertStartDate = '';
        } 
        if(convertEndDate === 'Invalid date'){
            convertEndDate = '';
        } 
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageOrsearch: "page=1&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageOrsearch: "page=1" 
            }
        })
    };
    return (
        <>
            <Header title={t('historicalCircuitIds.label')} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <HistoricalCircuitIdsItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(HistoricalCircuitIds);
