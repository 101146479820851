import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import BotashHeader from '../../botashHeader';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '100%'
  },
  blackBorder: {
    border: '1px solid black',
    outline: '1px solid',
    padding: '0px !important',
  },
  bottom: {
    border: '1px solid black',
    textUnderlineOffset: '1px solid',
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, 'pot', { type: contentType });
}

function convertBase64ToUrl(imgStr) {
  imgStr = imgStr.replace('data:image/png;base64,', '');
  let enc = window.atob(imgStr);
  let image = new File([enc], `${new Date().getTime()}.jpg`, {
    type: 'image/jpeg'
  });
  let file = b64toBlob(imgStr, 'image/jpeg');
  let imgURL = URL.createObjectURL(file);
  return imgURL;
}

const urInitFields = {
  id: '',
  name: '',
  companyNo: '',
  section: '',
  deparmemt: '',
  emplyDateTwo: null,
  emplyDateOne: null,
  engaegementDate: null
};

function BotashPocForm({
  t,
  open,
  data,
  users,
  userdata,
  handleClose,
  modalState,
  handleCreateRequestSubmit,
  handleOpenSignPanModal
}) {
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const classes = useStyles();
  React.useEffect(() => {
    if (!open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
      const id = data._id;
      const name = data.name;
      const companyNo = data.companyNo;
      const section = data.section;
      const deparmemt = data.deparmemt;
      const emplyDateTwo = data.emplyDateTwo;
      const emplyDateOne = data.emplyDateOne;
      const engaegementDate = data.engaegementDate;

      setURFormState({ id, name, companyNo, section, deparmemt, emplyDateOne, emplyDateTwo, engaegementDate });
    } else {
      // setShowUserSelect(true);
      // setShowLearnDevelopment(true);
      // setShowHOD(true);
      // setShowHRManager(true);
      // setShowGeneralManager(true);
    }
  }, [data]);

  const handleChange = (value, name, key) => {
    setURFormState({ ...urFormState, [name]: value });
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        <BotashHeader />
      </DialogTitle>
      <DialogContent>


        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <h4 style={{ fontWeight: 450 }}>The purpose of the checklist is to ensure that when an employee joins the company, he/she goes through all the necessary stages of induction. HR Services is to ensure that this form is completed</h4>
          </Grid>

          <Grid item xs={2}><h4 style={{ fontWeight: 400 }}>Name</h4>
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.name}
              onChange={evt => handleChange(evt.target.value, 'name')}
            />
          </Grid>
          <Grid item xs={2}><h4 style={{ fontWeight: 400 }}>Company No.</h4>
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.companyNo}
              onChange={evt => handleChange(evt.target.value, 'companyNo')}
            />
          </Grid>

        </Grid>


        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={2}><h4 style={{ fontWeight: 400 }}>Section</h4>
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.section}
              onChange={evt => handleChange(evt.target.value, 'section')}
            />
          </Grid>
          <Grid item xs={2}><h4 style={{ fontWeight: 400 }}>Department</h4>
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.deparmemt}
              onChange={evt => handleChange(evt.target.value, 'deparmemt')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={2}><h4 style={{ fontWeight: 400 }}>Engagement Date</h4>
          </Grid>
          <Grid item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: '125%' }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.engaegementDate}
              handleChange={newDate => handleChange(newDate, 'engaegementDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20, borderBottom: '1.5px solid black' }} >
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 600 }}>Checklist Item</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 600 }}>Person to sign</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 600 }}>Signature</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>1.Travel expense cliam</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Hr </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.travelExpense ? (
              <img
                src={convertBase64ToUrl(
                  data && data.travelExpense
                    ? data.travelExpense
                    : data.travelExpense
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('travelExpense')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>2.Medical examination</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Clinic </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.medicalExamination ? (
              <img
                src={convertBase64ToUrl(
                  data && data.medicalExamination
                    ? data.medicalExamination
                    : data.medicalExamination
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('medicalExamination')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>3.Medical Aids Forms</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Clinic </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.medicalAids ? (
              <img
                src={convertBase64ToUrl(
                  data && data.medicalAids ? data.medicalAids : data.medicalAids
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('medicalAids')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>4.Safety Induction</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>SHE </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.safetyInduction ? (
              <img
                src={convertBase64ToUrl(
                  data && data.safetyInduction
                    ? data.safetyInduction
                    : data.safetyInduction
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('safetyInduction')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>5.Company ID Card </h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Security </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.companyIDCard ? (
              <img
                src={convertBase64ToUrl(
                  data && data.companyIDCard
                    ? data.companyIDCard
                    : data.companyIDCard
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('companyIDCard')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>6.HR Induction</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>HR</h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.hrInduction ? (
              <img
                src={convertBase64ToUrl(
                  data && data.hrInduction ? data.hrInduction : data.hrInduction
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('hrInduction')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>7.Housing</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>HR </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.housing ? (
              <img
                src={convertBase64ToUrl(
                  data && data.housing ? data.housing : data.housing
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('housing')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>8.Legal Appointments</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>SHE </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.legalAppointments ? (
              <img
                src={convertBase64ToUrl(
                  data && data.legalAppointments
                    ? data.legalAppointments
                    : data.legalAppointments
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('legalAppointments')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>9.Botrec Membership</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Admin Services </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.botrecMembership ? (
              <img
                src={convertBase64ToUrl(
                  data && data.botrecMembership
                    ? data.botrecMembership
                    : data.botrecMembership
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('botrecMembership')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>10.ICT System</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>ICT </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.ictSystem ? (
              <img
                src={convertBase64ToUrl(
                  data && data.ictSystem ? data.ictSystem : data.ictSystem
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('ictSystem')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>10.Communication Office</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Com. Office </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.communicationOffice ? (
              <img
                src={convertBase64ToUrl(
                  data && data.communicationOffice
                    ? data.communicationOffice
                    : data.communicationOffice
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('communicationOffice')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>12.ISO</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>Works Chemist </h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.ISO ? (
              <img
                src={convertBase64ToUrl(data && data.ISO ? data.ISO : data.ISO)}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('ISO')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>13.Introduction to Section</h4>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ fontWeight: 400 }}>HR</h4>
          </Grid>
          <Grid item xs={4}>
            {data && data.IntroToSection ? (
              <img
                src={convertBase64ToUrl(
                  data && data.IntroToSection
                    ? data.IntroToSection
                    : data.IntroToSection
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginTop: 25 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('IntroToSection')}>
                Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          {/* <Grid item xs={1}><h4 style={{ fontWeight: 400 }}>Employee's Signature</h4>
          </Grid> */}
          <Grid item xs={5}>
            {data && data.emplySignOne ? (
              <img
                src={convertBase64ToUrl(
                  data && data.emplySignOne
                    ? data.emplySignOne
                    : data.emplySignOne
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%' }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('emplySignOne')}>
                Employee Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
          {/* <Grid item xs={1}><h4 style={{ fontWeight: 400, marginLeft:60 }}>Employer's Signature</h4>
          </Grid> */}
          <Grid item xs={5}>
            {data && data.emplySignTwo ? (
              <img
                src={convertBase64ToUrl(
                  data && data.emplySignTwo
                    ? data.emplySignTwo
                    : data.emplySignTwo
                )}
                width={60}
                height={60}
              />
            ) : (
              <Button
                style={{ minWidth: '100%', marginLeft: 70 }}
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('emplySignTwo')}>
                Employer's Signature <img src={SignIcon} width="20" height={25} />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={1}><h4 style={{ fontWeight: 400 }}>Date</h4>
          </Grid>
          <Grid item xs={5}>
            <DateTimeSelector
              inputStyle={{ minWidth: '125%' }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.emplyDateOne}
              handleChange={newDate => handleChange(newDate, 'emplyDateOne')}
            />
          </Grid>
          {/* <Grid item xs={4} /> */}
          <Grid item xs={1}><h4 style={{ fontWeight: 400 }}>Date</h4>
          </Grid>
          <Grid item xs={5}>
            <DateTimeSelector
              inputStyle={{ minWidth: '125%' }}
              label={'Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.emplyDateTwo}
              handleChange={newDate => handleChange(newDate, 'emplyDateTwo')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              setURFormState({ ...urInitFields });
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              handleCreateRequestSubmit(urFormState);
            }}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

BotashPocForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreateRequestSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(BotashPocForm);
