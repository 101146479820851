import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const OcrDocImgSlider = ({
    t,
    data
}) => {
    return (
      <div className="slide-container" style={{ marginTop: 18}}>
        {
          data && data.length > 0 ?
              (  
              <Slide>
              { data.map((each, index)=> (
                <div key={index} className="each-slide">
                <img className="lazy" src={each} alt="sample" />
              </div>
              ))} 
            </Slide> 
            )
          : null
        }
      </div>
    )
}

export default OcrDocImgSlider;