import { Box, Button, Grid, TextField, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  FormProvider,
  RHFSelect,
  RHFTextField
} from '../../../../../../components/hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { getAllClientData } from '../../../../../../services/clientService';
import {
  checkVlanAvailability,
  getAvailableVlansByPodId
} from '../../../../../../services/connectionService';
import { getPortById } from '../../../../../../services/portService';
import VlansPopup from '../Popups/VlansPopup';
import { useAppContext } from '../../../../../../contexts/AppContext';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    height: '43px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const PortToPort = props => {
  const { classes, handlePrev, handleNext, setState, destinationPodId } = props;
  const [clients, setClients] = useState([]);
  const [sourcePodId, setSourcePodId] = useState('');
  const [messageAEnd, setMessageAEnd] = useState('');
  const [messageBEnd, setMessageBEnd] = useState('');
  const [openVlanPopup, setOpenVlanPopup] = useState(false);
  const [availableVlans, setAvailableVlans] = useState([]);
  const [aEndIsValid, setAEndIsValid] = useState(false);
  const [bEndIsValid, setBEndIsValid] = useState(false);

  const { state } = useAppContext();

  const initialValues = {
    connectionName: '',
    rateLimit: '',
    MinimumTerm: 'standard',
    A_end: '',
    B_end: ''
  };

  const validationSchema = yup.object().shape({
    connectionName: yup.string().required('This field is required'),
    clientName: yup.string().required('This field is required'),
    rateLimit: yup
      .number()
      .typeError('Enter a value between 0 & 1000')
      .min(0, 'Enter a value between 0 & 1000')
      .max(1000, 'Enter a value between 0 & 1000')
      .required('This field is required'),
    MinimumTerm: yup.string().required('This field is required'),
    A_end: yup
      .number()
      .typeError('This field is required')
      .required('This field is required'),
    B_end: yup
      .number()
      .typeError('This field is required')
      .required('This field is required')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const { handleSubmit, setValue, watch, control } = methods;

  const onSubmit = data => {
    setState({ data });
    handleNext();
  };

  const GetClientList = async () => {
    const res = await getAllClientData();
    setClients(res?.data?.result?.data);
  };

  const A_end = watch('A_end');
  const B_end = watch('B_end');

  const GetPodIdOfSourcePort = async () => {
    const sourcePortId = state.sourcePortDetails.sourcePortId;
    const res = await getPortById(sourcePortId);
    if (res) setSourcePodId(res?.data?.result?.pod?._id);
  };

  const checkAendVlan = async () => {
    const payload = {
      a_end_port_id: state.sourcePortDetails.sourcePortId,
      b_end_port_id: state.destinationPortDetails.destinationPortId,
      a_end_vlan_id: A_end
    };
    try {
      const res = await checkVlanAvailability(payload);
      if (res?.status === 200) {
        setMessageAEnd(res?.data?.message || res?.message);
        setAEndIsValid(true);
      } else {
        setMessageAEnd(
          res?.response?.data?.message || res?.message || res?.data?.message
        );
        setAEndIsValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkBendVlan = async () => {
    const payload = {
      a_end_port_id: state.sourcePortDetails.sourcePortId,
      b_end_port_id: state.destinationPortDetails.destinationPortId,
      b_end_vlan_id: B_end
    };
    try {
      const res = await checkVlanAvailability(payload);
      if (res?.status === 200) {
        setMessageBEnd(res?.data?.message || res?.message);
        setBEndIsValid(true);
      } else {
        setMessageBEnd(
          res?.response?.data?.message || res?.message || res?.data?.message
        );
        setBEndIsValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const GetVlans = async id => {
    if (id) {
      const res = await getAvailableVlansByPodId(id);
      setAvailableVlans(res?.data?.result);
      setOpenVlanPopup(true);
    }
  };

  useEffect(() => {
    GetClientList();
    GetPodIdOfSourcePort();
  }, [setValue]);

  useEffect(() => {
    if (A_end) checkAendVlan();
  }, [A_end]);

  useEffect(() => {
    if (B_end) checkBendVlan();
  }, [B_end]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '10px', width: '500px' }}>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Connection Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="connectionName"
                label="Connection Name"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <Grid item sm={3}>
              Client Name
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFSelect name="clientName" variant="outlined" size="small">
                <option value="">Select</option>
                {clients &&
                  clients?.map(clientData => (
                    <option
                      key={clientData._id}
                      value={clientData?.client_name}>
                      {clientData?.client_name}
                    </option>
                  ))}
              </RHFSelect>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Rate Limit
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFTextField
                variant="outlined"
                name="rateLimit"
                label="Rate Limit (Mbps)"
                size="small"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              Link Type
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid item sm={8}>
              <RHFSelect name="MinimumTerm" variant="outlined" size="small">
                <option value="">Select</option>
                {[
                  {
                    key: 'Standard Unprotected',
                    value: 'standard-unprotected'
                  },
                  {
                    key: 'Premium Unprotected',
                    value: 'premium-unprotected'
                  },
                  { key: 'Standard Protected', value: 'standard-protected' }
                ].map(item => (
                  <option key={item.key} value={item?.value}>
                    {item?.key}
                  </option>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              A-end V-LAN
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              sm={8}
              spacing={1}
              justify="center"
              alignItems="center">
              <Grid item sm={8}>
                <Controller
                  name="A_end"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      label="A-end V-LAN"
                      autoComplete="off"
                      fullWidth
                      value={
                        typeof field.value === 'number' && field.value === 0
                          ? ''
                          : field.value
                      }
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                {A_end && (
                  <span
                    style={{
                      color: messageAEnd === 'Available' ? '#000' : 'red'
                    }}>
                    {messageAEnd}
                  </span>
                )}
              </Grid>
              <Grid item sm={4}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => GetVlans(sourcePodId)}>
                  Check Availability
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item sm={3}>
              B-end V-LAN
            </Grid>
            <Grid item sm={1}>
              <span>:</span>
            </Grid>
            <Grid
              item
              container
              sm={8}
              spacing={1}
              justify="center"
              alignItems="center">
              <Grid item sm={8}>
                <Controller
                  name="B_end"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      label="B-end V-LAN"
                      fullWidth
                      value={
                        typeof field.value === 'number' && field.value === 0
                          ? ''
                          : field.value
                      }
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                {B_end && (
                  <span
                    style={{
                      color: messageBEnd === 'Available' ? '#000' : 'red'
                    }}>
                    {messageBEnd}
                  </span>
                )}
              </Grid>
              <Grid item sm={4}>
                <Button
                  size="small"
                  onClick={() => GetVlans(destinationPodId)}
                  variant="outlined">
                  Check Availability
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box p={2}>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Box>
            <Box p={2}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={
                  B_end !== null && A_end !== null && aEndIsValid && bEndIsValid
                    ? false
                    : true
                }
                type="submit">
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      {openVlanPopup && (
        <VlansPopup
          openVlanPopup={openVlanPopup}
          setOpenVlanPopup={setOpenVlanPopup}
          availableVlans={availableVlans}
          setAvailableVlans={setAvailableVlans}
        />
      )}
    </>
  );
};

export default withStyles(styles)(PortToPort);
