import React from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import OutlinedSelect from '../../../../../../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../../theme/utils';
import SignatureCanvasPad from '../../../OcrPanel/components/SignatureCanvasPad';
import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
// import FormControl from '@material-ui/core/FormControl';

const StepperFrom = ({
    t,
    data,
    stepFormData,
    handleChange,
    handleClick = () => {},
    type,
    handleChangeOfAutoComplete = () => {}
}) => {
    
    return (
        <>
            <Grid container spacing={2}>
                {/* notifiction Process */}
                {
                   type === 'notifiction' && (
                       <>
                        <Grid item xs={12}>
                        <Typography variant="subtitle2" >{data.description}</Typography>
                        </Grid>
                        {data.status && data.status === "Yes" &&
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Completed"
                                    name="isCompleted"
                                    checked={stepFormData.isCompleted}
                                    onChange={handleChange}
                                    value={stepFormData.isCompleted}
                                />
                            </Grid>
                        }
                        {data.workflowOptions && data.workflowOptions.length ?
                            <Grid item xs={6}>  
                                <OutlinedSelect
                                    val={stepFormData.workflowOption}
                                    name="workflowOption"
                                    label={t("workflowOpt.label")}
                                    handleChange={handleChange}
                                    styleOverrides={{ width: '100%' }}
                                    options={data.workflowOptions}
                                />
                            </Grid> : null}
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                rows={2}
                                placeholder={t('comments.label')}
                                style={{ width: '100%', marginTop: 12 }}
                                onChange={handleChange}
                                value={stepFormData.comments}
                                variant="standard"
                                multiline
                                name="comments"
                            />
                        </Grid>
                        </>
                   )  
                }
                {/* ocr Process */}
                {
                    type === 'ocrProcess' && (
                    <>
                        <Grid item xs={12}>
                        <Typography variant="subtitle2" >{data.description}</Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                                <Button
                                    align={'right'}
                                    variant={'outlined'}
                                    color={'primary'}
                                    name="signature"
                                    onClick={() =>handleClick('')}
                                    style={{ height: 50, float: 'right'}}
                                    >
                                    Hide signature or approval forms
                                </Button>
                        </Grid> */}
                        {/* {data.status && data.status === "Yes" &&
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Completed"
                                    name="isCompleted"
                                    checked={stepFormData.isCompleted}
                                    onChange={handleChange}
                                    value={stepFormData.isCompleted}
                                />
                            </Grid>
                        } */}
                        {data.processOptions && data.processOptions.length ?
                            <Grid item xs={6}>  
                                <OutlinedSelect
                                    val={stepFormData.processOptions}
                                    name="processOptions"
                                    label="Process"
                                    handleChange={handleChange}
                                    styleOverrides={{ width: '100%' }}
                                    options={data.processOptions}
                                />
                            </Grid>
                            : null
                        }
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    rows={2}
                                    placeholder={t('comments.label')}
                                    style={{ width: '100%', marginTop: 12 }}
                                    onChange={handleChange}
                                    value={stepFormData.comments}
                                    variant="standard"
                                    multiline
                                    name="comments"
                                />
                            </Grid>
                        {/* {
                                (!stepFormData.isApprovalProcess && !stepFormData.signatureProcess) && (
                                    <Grid item xs={12}>
                                    <TextField
                                        id="standard-multiline-flexible"
                                        rows={2}
                                        placeholder={t('comments.label')}
                                        style={{ width: '100%', marginTop: 12 }}
                                        onChange={handleChange}
                                        value={stepFormData.comments}
                                        variant="standard"
                                        multiline
                                        name="comments"
                                    />
                                </Grid>
                                )
                        } */}
                        {data.signature && data.signature? 
                            <Grid item xs={2}>
                                <Button
                                    align={'right'}
                                    variant={'outlined'}
                                    color={'primary'}
                                    name="signature"
                                    onClick={() =>handleClick('signature')}
                                    style={{ height: 50}}
                                    >
                                    Signature
                                </Button>
                            </Grid>
                        : null}
                        {data.isApproval && data.isApproval? 
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Enable Approval Flow"
                                    name="approvalFlow"
                                    checked={data.isApproval}
                                    onChange={handleChange}
                                    value={data.isApproval}
                                />
                               {/* <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="approvedOrRejectStatus"
                                    value={stepFormData.approvedOrRejectStatus}
                                    onChange={handleChange}
                                >
                                <FormControlLabel value={true} control={<Radio />} label="Approved" />
                                <FormControlLabel value={false} control={<Radio />} label="Rejected" />
                                </RadioGroup>
                              </FormControl> */}
                            </Grid>
                        : null}
                        {data.isApproval && data.isApproval? 
                            <Grid item xs={3}>
                                <Autocomplete
                                        value={stepFormData.optionForUser}
                                        id="combo-box-demo"
                                        options={(!stepFormData.ocrUsersListLoading && stepFormData.allOcrUsersData) ? stepFormData.allOcrUsersData : []}
                                        getOptionLabel={option =>
                                            option
                                              ? `${option.first_name} ${option.last_name}`
                                              : ''
                                        }
                                        onChange={(event, newValue) => {
                                            handleChangeOfAutoComplete("approvalUser", newValue);
                                        }}
                                        loading={stepFormData.ocrUsersListLoading}
                                        name="approvalUser"
                                        style={{ border: '1px solid #ebebeb', width: '100%', height: 50, marginLeft: 12 }}
                                        renderInput={(params) => <TextField {...params} label={`Select ${t('users.label')} *`} variant="outlined" />}
                                />
                            </Grid>
                        : null}
                        {data.isApproval && data.isApproval? 
                            <Grid item xs={4}>
                                  {/* <Autocomplete
                                        value={stepFormData.optionForFile}
                                        id="controllable-states-demo"
                                        options={stepFormData && stepFormData.allFiles ? stepFormData.allFiles : []}
                                        getOptionLabel={(option) => option.file_name}
                                        onChange={(event, newValue) => {
                                            handleChangeOfAutoComplete("fileId", newValue);
                                        }}
                                        loading={false}
                                        name="fileId"
                                        style={{ border: '1px solid #ebebeb', width: '100%', height: 50, marginLeft: 12 }}
                                        renderInput={(params) => <TextField {...params} label="Select File" variant="outlined" />}
                                />  */}
                                <img
                                 src={stepFormData.img_url}
                                 alt={stepFormData.fileName}
                                 style={{ height: 120, width: 60 }}   
                                />
                            </Grid>
                        : null}
                            {
                               stepFormData.signatureProcess && (
                                <>
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            id="standard-multiline-flexible"
                                            rows={2}
                                            placeholder="signature comments"
                                            style={{ width: '100%', marginTop: 12 }}
                                            onChange={handleChange}
                                            value={stepFormData.signatureProcessComments}
                                            variant="standard"
                                            multiline
                                            name="signatureProcessComments"
                                        />
                                    </Grid>  */}
                                    <Grid item xs={12}>
                                        <SignatureCanvasPad
                                            t={t}
                                            value={stepFormData.comments}
                                            handleClick={handleClick}
                                            name="signatureImg"
                                        />
                                    </Grid>
                                </>                                   
                               ) 
                            }
                            {/* {
                               stepFormData.isApprovalProcess && (
                                <Grid item xs={12}>
                                    <TextField
                                        id="standard-multiline-flexible"
                                        rows={2}
                                        placeholder={stepFormData.approvedOrRejectStatus ? "approval comments": "reject comments"}
                                        style={{ width: '100%', marginTop: 12 }}
                                        onChange={handleChange}
                                        value={stepFormData.approvalProcessComments}
                                        variant="standard"
                                        multiline
                                        name="approvalProcessComments"
                                    />
                                </Grid>                                    
                               ) 
                            } */}
                        </>
                )
                }
            </Grid>
        </>
    )
}

export default StepperFrom
