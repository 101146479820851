import axios from '../utils/httpClient';
import moment from 'moment';

const accessToken = localStorage.getItem('syncomToken');

const getFcrById = async fcrId => {
  try {
    const response = await axios.get(`fcr/${fcrId}`, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getRegions = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `region?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

const getPodListWithRegionId = async regionId => {
  try {
    const response = await axios.get(`pod?region_id=${regionId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getPodList = async (page = '', limit = '') => {
  try {
    const response = await axios.get(
      `pod?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};
const generateHexCode = async () => {
  try {
    const response = await axios.get(`ipsec/genrate/hex`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const createIpsec = async payload => {
  try {
    const response = await axios.post('ipsec', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getIpsecListByFcrId = async fcrId => {
  try {
    const response = await axios.get(`ipsec?fcr_id=${fcrId}`, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getIpsecById = async ipSecId => {
  try {
    const response = await axios.get(`ipsec/${ipSecId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const deleteIpsec = async ipSecId => {
  try {
    const response = await axios.delete(`ipsec/${ipSecId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const updateIpSec = async (ipSecId, payload) => {
  try {
    const response = await axios.put(`ipsec/${ipSecId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const AddBgp = async payload => {
  try {
    const response = await axios.post('bgp', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getBgpByConnId = async (connId, connFcrId) => {
  try {
    const response = await axios.get(`bgp/data/conn_fcr?conn_id=${connId}&fcr_id=${connFcrId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const updateBgpById = async (bgpId, payload) => {
  try {
    const response = await axios.put(`bgp/${bgpId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const getFcrListWithRegionId = async (page = 1, limit = 10, regionId) => {
  try {
    const response = await axios.get(
      `fcr?page=${page}&limit=${limit}&sort=-createdAt&region=${regionId}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};
const getFcrListByNameFilter = async (
  page = 1,
  limit = 10,
  word = '',
  regionId
) => {
  try {
    const response = await axios.get(
      `fcr?page=${page}&limit=${limit}&sort=-createdAt&search=${word}&region=${regionId}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};
const getAllFcrList = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `fcr?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getIpSecLogDetails = async ipSecId => {
  try {
    const response = await axios.get(`serviceLog?ipsec=${ipSecId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const getFcrLogDetails = async id => {
  try {
    const response = await axios.get(`serviceLog?log_type=fcr&fcr=${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const deleteFcrById = async fcrId => {
  try {
    const response = await axios.delete(`fcr/${fcrId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const updateFcrById = async (fcrId, payload) => {
  try {
    const response = await axios.put(`fcr/${fcrId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getChartDataOfFcr = function (
  dateFilter,
  selectedFcrData,
  setLoader,
  setChartData
) {
  let query = '';
  var date = new Date();

  if (dateFilter === 15 || dateFilter === 60) {
    date.setDate(date.getDate());
    query = date;
  }

  const testURL = `https://54dc60343a3b46d3a26840aa77dc46d3.europe-west2.gcp.elastic-cloud.com:9243/fabric-snmp-${moment(
    query
  ).format('YYYY')}.${moment(query).format('MM')}/_search`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'ApiKey QnVuWnRZSUJ0am1vUGpUUUxSTDQ6cGZabnhkYWZTR3lISWFxVzBRV1RPdw=='
    },
    body: JSON.stringify({
      size: dateFilter,
      query: {
        bool: {
          filter: [
            {
              range: {
                '@timestamp': {
                  gte: moment
                    .utc(date)
                    .subtract(dateFilter, 'm')
                    .format(),
                  lt: moment.utc(date).format()
                }
              }
            }
          ],
          must: [
            {
              match_phrase: {
                'interfaces.ifDescr': `l3-${String(
                  selectedFcrData?.display_fcr_id
                ).slice(0, 6)}`
              }
            }
          ]
        }
      },
      sort: [{ '@timestamp': 'asc' }]
    })
  };

  setLoader(true);
  fetch(testURL, requestOptions)
    .then(response => response.json())
    .then(async data => {
      (await data?.hits?.hits?.length) > 0
        ? setChartData(
          data?.hits?.hits
        )
        : setChartData([]);
      setLoader(false);
    })
    .catch(function (error) {
      console.log({ error });
      setLoader(false);
    });
};

export {
  getFcrById,
  getRegions,
  getPodListWithRegionId,
  getPodList,
  createIpsec,
  generateHexCode,
  getIpsecListByFcrId,
  getIpsecById,
  deleteIpsec,
  updateIpSec,
  AddBgp,
  getBgpByConnId,
  updateBgpById,
  getFcrListWithRegionId,
  getAllFcrList,
  getIpSecLogDetails,
  deleteFcrById,
  getFcrLogDetails,
  getFcrListByNameFilter,
  getChartDataOfFcr,
  updateFcrById
};
