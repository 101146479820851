import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import {useLazyQuery} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../utils';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="primary"
        onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <DeleteIcon color="secondary"/> }
        </Button>
    );
  }

const OcrFolderItem = ({
    key,
    t,
    data,
    view,
    ocrFolderDeletetingStarted,
    selecteIdForDelete,
    handleSubmitOfDeleteOcrTreeItems,
    enableEditFolderModal
}) => {
  const commonClasses = commonStyles();

  return (
    <>
    <TableRow key={data.id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? data.name : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? data.description : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    {
        view === 'confrigtion' && (
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                    {data.place_name}
                    </Typography>
                </Grid>
            </Grid>
       </TableCell>
        )
    }{
        view === 'confrigtion' && (
        <TableCell>
           <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data.project_name}
                </Typography>
            </Grid>
            </Grid>
        </TableCell>
        )
    }
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data.created_by_name}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {dateFormatterLLL(data.created_at)}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
            <Button 
            color="primary"
            onClick={()=> enableEditFolderModal(data)}
            variant="outlined"
            >
             Edit
            </Button>
            <ButtonComponent 
                t={t}
                loading={selecteIdForDelete === data.id ? ocrFolderDeletetingStarted: false}
                onClick={() =>{
                handleSubmitOfDeleteOcrTreeItems({id:data.id, name:data.name, type: 'folder'});
                }}
            />
            </Grid>
        </Grid>
    </TableCell>
</TableRow>   
</>
  );
};

export default OcrFolderItem;
