import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL} from '../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ControllerStatusPanel from "../ControllerStatusPanel";
import Button from "@material-ui/core/Button";
import {USER_ROLES} from "../../../../utils/constants";

const ControllerItems = ({
                              t,
                              key,
                              controller,
                              role,
                              handleMenuItemClick,
                              menuItems
                          }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={key}>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {controller.controller_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {controller.deployment_type}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {controller.role}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {dateFormatterLLL(controller.created_at)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {controller.created_by}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {controller.controller_ip}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <ControllerStatusPanel
                        status={controller.controller_status}
                        t={t}
                    />
                </Typography>
            </TableCell>
            {role.includes(USER_ROLES.IP_SUPERADMIN) && <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}>
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && (
                    <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={menuItems}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(
                                elem,
                                controller.controller_id,
                                'controller'
                            );
                            setAnchorEl(null);
                        }}
                    />
                )}
            </TableCell>}
        </TableRow>
    );
};
ControllerItems.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(ControllerItems);
