import React from 'react';
import CustomTabs from '../../../../../../components/CustomTabs';
import { USER_ROLES } from '../../../../../../utils/constants';
import TransportFormPanel from '../../components/TransportFormPanel';

const EngineeringPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex,
    departments
}) => {
    const role = localStorage.getItem('role');
    let tabList = [];
    if(data){              
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Transport Form",
                children: (
                    <>
                        <TransportFormPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }
    }

    return (
        <CustomTabs 
            activeIndex={activeIndex} 
            tabs={tabList}
        />
    )
}

export default EngineeringPanel