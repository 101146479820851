import {gql} from 'apollo-boost';
/*
  Create an organization
 */
export const CREATE_ORGANIZATION = gql`
    mutation CreateOrganization(
        $name: String
        $address: String
        $region: String
        $country: String
        $zipcode: String
        $users: [String]
        $type: String
        $logo: Upload
        $dns: String
    ) {
        createOrganization(
            name: $name
            address: $address
            regionId: $region
            countryId: $country
            zipcode: $zipcode
            users: $users
            logo: $logo
            type: $type
            dns: $dns
        ) {
            id
            name
        }
    }
`;
/*
  Create a user
 */
export const CREATE_USER = gql`
    mutation CreateUser(
        $firstName: String
        $lastName: String
        $email: String
        $phone: String
        $password: String
    ) {
        createUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            phone: $phone
            password: $password
        ) {
            email
        }
    }
`;
/*
  Create a user
 */
export const CREATE_GSUIT_USER = gql`
    mutation CreateUser(
        $firstName: String
        $lastName: String
        $email: String
        $googleId: String
        $accessToken: String
        $organisation: String
    ) {
        createGsuitUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            googleId: $googleId
            accessToken: $accessToken
            organisation: $organisation
        ) {
            email
            googleId
            status
            success
            msg
            accessToken
            refreshToken
            tokenType
            expiresIn
            refreshExpiresIn
            organisations
        }
    }
`;
/*
  Update a user
 */
export const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: String
        $firstName: String
        $lastName: String
        $role: String
        $phone: String
        $organisations: [String]
        $countryId: String
    ) {
        updateUser(
            id: $id
            firstName: $firstName
            lastName: $lastName
            role: $role
            phone: $phone
            organisations: $organisations
            countryId: $countryId
        ) {
            email
        }
    }
`;
export const UPDATE_GSUIT_USER_ACCOUNT = gql`
    mutation UpdateGsuitUserAccount(
        $organizationName: String
        $roles: String
        $email: String
    ) {
        updateGsuitUserAccount(
            organizationName: $organizationName
            roles: $roles
            email: $email
        ) {
            email
        }
    }
`;
/*
  Add user to organization
 */
export const ADD_USER_TO_ORG = gql`
    mutation AddUserToOrg($userEmail: String, $orgName: String, $role: String) {
        addUserToAnOrganization(
            userEmail: $userEmail
            orgName: $orgName
            role: $role
        ) {
            id
        }
    }
`;
/*
  ADD MEMBER TEMP DATA
 */
export const ADD_MEMBER_DATA = gql`
    mutation InviteMember($email: String, $password: String) {
        addMemberData(email: $email, tempKey: $password) {
            id
        }
    }
`;
/*
  OTP verification
 */
export const EMAIL_OTP_VERIFICATION = gql`
    mutation OTPVerification($email: String, $otp: String, $login: String) {
        emailOTPVerification(email: $email, otp: $otp, login: $login) {
            id
            accessToken
            refreshToken
            expiresIn
            organisations
            success
            msg
        }
    }
`;
/*
  Resend OTP
 */
export const RESEND_OTP = gql`
    mutation ResendOTP($email: String, $action: String) {
        resendOTP(email: $email, action: $action) {
            email
        }
    }
`;
/*
  Resend OTP
 */
export const SDN_RESEND_OTP = gql`
    mutation ResendSDNOTP($notification_id: String) {
        resendSDNOTP(notification_id: $notification_id) {
            status
        }
    }
`;
/*
  Reset Password Email verification
 */
export const FORGOT_PASSWORD_EMAIL_VERIFICATION = gql`
    mutation ForgotPasswordEmailVerification($email: String) {
        forgotPasswordEmailVerification(email: $email) {
            id
            success
            msg
        }
    }
`;
/*
  Reset Password OTP verification
 */
export const RESET_PASSWORD_OTP_VERIFICATION = gql`
    mutation OTPVerification($id: String, $otp: String) {
        verifyResetPasswordOTP(id: $id, otp: $otp) {
            id
            success
            msg
            accessToken
        }
    }
`;
/*
  Reset Password
 */
export const RESET_PASSWORD = gql`
    mutation ResetPassword($id: String, $password: String, $token: String) {
        resetPassword(id: $id, password: $password, token: $token) {
            success
            msg
        }
    }
`;
/*
  Invite a user
 */
export const INVITE_USER = gql`
    mutation InviteUser($inviteUsers: String) {
        inviteUser(inviteUsers: $inviteUsers) {
            email
        }
    }
`;
/*
  Invite a user
 */
export const ASSIGN_INVITE_USER_ROLE_ORG = gql`
    mutation AssignInviteUserRoleAnOrg($users: String) {
        assignInviteUserRoleAnOrg(users: $users) {
            email
        }
    }
`;
export const DELETE_USER = gql`
    mutation DeleteUser($id: String) {
        deleteUser(id: $id) {
            id
        }
    }
`;
export const DELETE_DEFAULT_ROLE = gql`
    mutation DeleteDefaultRole($id: String) {
        deleteDefaultRole(id: $id) {
            id
        }
    }
`;
/*
  Change Password
 */
export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($password: String, $currentPassword: String) {
        changePassword(password: $password, currentPassword: $currentPassword) {
            success
            msg
        }
    }
`;
