import React, { useState, useEffect } from 'react';
import '../styles.css';
import { Paths, TicketingSystem } from '../../../routes/routePaths';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  REQUIRED_ERROR,
  TICKET_DATA,
  IN_CHARGE,
  CHANGE_URGENCY,
  CHANGE_MATRIX_IMPACT,
  CHANGE_CATEGORY
} from '../../../utils/constants';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import { dateFormatterLLL, getParam, getIdParam, dateFormatterDDMMMYYYYHMS } from '../../../utils';
import Grid from '@material-ui/core/Grid';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Image from '../../../assets/icons/image.png';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import commonStyles from '../../../theme/commonStyles';
import { ADD_CHANGE_MANAGEMENT_TICKET, UPDATE_CHANGE_MANAGEMENT_TICKET } from '../mutations';
import Message from '../../../components/Message';
import { UPLOAD_FILE } from '../../commonMutations';
import { GET_ALL_INTERNAL_USER_LIST, GET_USER_INFO, GET_CHANGE_MANAGEMENT_BY_ID, GET_CHANGE_MANAGEMENT_USERS } from '../queries';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'changeManagement.label', link: TicketingSystem.CM },
  { label: 'changeRequestForm.label', link: TicketingSystem.ChangeRequest }
];

const useStyles = makeStyles(theme => ({
  detailsContainer: {
    border: `1px solid #b3b3b3`
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  dateSize: {
    width: "100%",
    minWidth: "100%"
  },
  changeDescription: {
    padding: '15px',
    color: '#969696'
  },
  updateBtn: {
    marginLeft: '15px'
  },
  iconStyle: {
    color: "white",
    border: "2px dotted #fff",
    background: "#5a1f2a"
  }
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500
    }
  }
};

function NewChangeRequest({ t }) {
  const id = getIdParam()
  const country = localStorage.getItem('country');
  const history = useHistory();
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [inChargeEmail, setInChargeEmail] = useState('');
  const [agent, setAgent] = useState([]);
  const [lineManagerData, setLineManagerData] = useState([]);
  const [eHODData, setEHODData] = useState([]);
  const [changeType, setChangeType] = useState([]);
  const [showLineManager, setShowLineManager] = useState(false);
  const [showCAB, setShowCAB] = useState(false);
  const [showEHOD, setShowEHOD] = useState(false);
  const [showEHODDrop, setShowEHODDrop] = useState(true);
  const [showEhodEsclate, setShowEhodEsclate] = useState(true);
  const [showMD, setShowMD] = useState(false);
  const [needMDApproval, setNeedMDApproval] = useState(false);
  const [showMDApprovalBtn, setShowMDApprovalBtn] = useState(false);
  const [lineManager, setLineManager] = useState([]);
  const [reserveAgent, setReserveAgent] = useState([]);
  const [reserveAgent2, setReserveAgent2] = useState([]);
  const [changeUrgency, setChangeUrgency] = useState([]);
  const [changeMatrixImpact, setChangeMatrixImpact] = useState([]);
  const [changeImpact, setChangeImpact] = useState('');
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const [risk, setRisk] = useState('');
  const [userAcceptanceTesting, setUserAcceptanceTesting] = useState('');
  const [lineManagerComment, setLineManagerComment] = useState('');
  const [cabLeadComment, setCABLeadComment] = useState('');
  const [EHODComment, setEHODComment] = useState('');
  const [rollbackPlan, setRollbackPlan] = useState('');
  const [escalateEHOD, setEscalateEHOD] = useState(false);
  const [createBtn, setCreateBtn] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileLoading, setUploadedFileLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [escalateLoading, setEscalateLoading] = useState(false);
  const [returnLoading, setReturnLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [showReturnBtn, setShowReturnBtn] = useState(false);
  const [showChangeMatrix, setShowChangeMatrix] = useState(false);
  const [showEscalateBtn, setShowEscalateBtn] = useState(false);
  const [showApproveBtn, setShowApproveBtn] = useState(false);
  const [showRejectBtn, setShowRejectBtn] = useState(false);
  const [mdChecked, setMdChecked] = useState(false);
  const [hideEscalateBtn, setHideEscalateBtn] = useState(false);
  const [getUserListData, { data: getAllUserList }] = useLazyQuery(GET_ALL_INTERNAL_USER_LIST);
  const [getChangeManagementUsersList, { data: getChangeManagementUsers }] = useLazyQuery(GET_CHANGE_MANAGEMENT_USERS);
  const [getUserData, { data: userInfo }] = useLazyQuery(GET_USER_INFO);
  const [changeManagementById] = useLazyQuery(GET_CHANGE_MANAGEMENT_BY_ID, {
    onError: e => {
      console.log("Error", e);
    },
    onCompleted: res => {
      if (res && res.getChangeManagementById && res.getChangeManagementById.status == 'Closed') {
        Message.success(`Change Request Already Closed`);
        setLoadingBtn(false)
        history.push(TicketingSystem.CM);
        return false
      }
      if (res && res.getChangeManagementById && res.getChangeManagementById.status == 'approved') {
        Message.success('Change Request is already approved');
        setLoadingBtn(false)
        history.push(TicketingSystem.CM);
        return false
      }
      if (res && res.getChangeManagementById && res.getChangeManagementById.status == 'reject') {
        Message.success(`Change Request is Rejected by ${res.getChangeManagementById.inCharge}`);
        setLoadingBtn(false)
        history.push(TicketingSystem.CM);
        return false
      }
      if (res && res.getChangeManagementById && res.getChangeManagementById.status == 'return') {
        Message.success(`Change Request Return to ${res.getChangeManagementById.createdByName}`);
        setLoadingBtn(false)
        history.push(TicketingSystem.CM);
        return false
      }
      if (res && res.getChangeManagementById && res.getChangeManagementById.changeRequest &&
        res.getChangeManagementById.changeRequest.length) {
        setLoadingBtn(false)
        setCreateBtn(false)
        let length = res.getChangeManagementById.changeRequest.length
        let requestedData = res && res.getChangeManagementById && res.getChangeManagementById.changeRequest
          && res.getChangeManagementById.changeRequest[length - 1]
        let changeCategoryItem = CHANGE_CATEGORY.find(function (item, index) {
          if (item.value == requestedData.changeType)
            return true
        })
        setTitle(requestedData.title)
        setDetails(requestedData.description)
        setAgent(requestedData.changeAgent)
        setChangeType(changeCategoryItem)
        setChangeImpact(requestedData.changeImpact)
        setRisk(requestedData.risk)
        setUserAcceptanceTesting(requestedData.userAcceptanceTesting)
        setRollbackPlan(requestedData.rollbackPlan)
        setLineManager(requestedData.lineManager)
        setReserveAgent(requestedData.reserveAgent)
        setReserveAgent2(requestedData.reserveAgent2)
        handleDateChange(requestedData.implimentStartDate)
        handleEndDateChange(requestedData.implimentEndDate)
        setLineManagerData(res && res.getChangeManagementById)
        requestedData.lineManagerComment && setLineManagerComment(requestedData.lineManagerComment)
        requestedData.cabLeadComment && setCABLeadComment(requestedData.cabLeadComment)
        requestedData.eHOD && setEHODData(requestedData.eHOD)
        requestedData.EHODComment && setEHODComment(requestedData.EHODComment)
        requestedData.changeUrgency && setChangeUrgency(CHANGE_URGENCY.find(function (item, index) {
          if (item.name == requestedData.changeUrgency) {
            return true
          }
        }))
        requestedData.changeMatrixImpact && setChangeMatrixImpact(CHANGE_MATRIX_IMPACT.find(function (item, index) {
          if (item.name == requestedData.changeMatrixImpact) {
            return true
          }
        }))
        if (requestedData.inCharge == IN_CHARGE.AGENT) {
          setInChargeEmail(requestedData && requestedData.lineManager && requestedData.lineManager.emailAddress)
          setShowReturnBtn(true)
          setShowLineManager(true)
          setShowApproveBtn(true)
          setShowRejectBtn(true)
        }
        if (requestedData.inCharge == IN_CHARGE.LINEMANAGER) {
          setInChargeEmail(getChangeManagementUsers && getChangeManagementUsers.getChangeManagementUsers.cabEmail)
          setShowLineManager(true)
          setShowCAB(true)
          setShowChangeMatrix(true)
          setDisableButtons(false)
          setShowEscalateBtn(true)
          setHideEscalateBtn(true)
        }
        if (requestedData.inCharge == IN_CHARGE.CAB) {
          setInChargeEmail(requestedData && requestedData.eHOD && requestedData.eHOD.emailAddress)
          setShowEhodEsclate(false)
          setShowLineManager(true)
          setDisableButtons(false)
          setShowChangeMatrix(true)
          setShowCAB(true)
          setShowEHOD(true)
          setEscalateEHOD(true)
          setShowRejectBtn(true)
          setHideEscalateBtn(true)
          changeMatrixFunction(requestedData.changeMatrixImpact, requestedData.changeUrgency)
        }
        if (requestedData.inCharge == IN_CHARGE.EHOD) {
          setInChargeEmail(getChangeManagementUsers && getChangeManagementUsers.getChangeManagementUsers.mdEmail)
          setShowEhodEsclate(false)
          setShowLineManager(true)
          setDisableButtons(false)
          setShowChangeMatrix(true)
          setShowCAB(true)
          setShowEHOD(true)
          setEscalateEHOD(true)
          setShowRejectBtn(true)
          setShowApproveBtn(true)
        }
      } else {
        Message.error('No record found for this ID');
        setLoadingBtn(false)
      }
    }
  });

  const changeMatrixFunction = (impact, urgency) => {
    if (impact === 'High' && urgency === 'High') {
      setShowEscalateBtn(true)
      setShowMDApprovalBtn(true)
      setMdChecked(true)
      setNeedMDApproval(true)
      setHideEscalateBtn(false)
    }
    if (impact === 'High' && urgency === 'Medium') {
      setShowApproveBtn(true)
    }
    if (impact === 'High' && urgency === 'Low') {
      setShowApproveBtn(true)
    }

    if (impact === 'Medium' && urgency === 'High') {
      setShowApproveBtn(true)
    }
    if (impact === 'Medium' && urgency === 'Medium') {
      setShowApproveBtn(true)
    }
    if (impact === 'Medium' && urgency === 'Low') {
      setShowApproveBtn(true)
    }

    if (impact === 'Low' && urgency === 'High') {
      setShowApproveBtn(true)
    }
    if (impact === 'Low' && urgency === 'Medium') {
      setShowApproveBtn(true)
    }
    if (impact === 'Low' && urgency === 'Low') {
      setShowApproveBtn(true)
    }
  }

  const [CreateCMTicket] = useMutation(ADD_CHANGE_MANAGEMENT_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Change Request created successfully.');
      setUploadedFileLoading(false);
      history.push(TicketingSystem.CM);
    }
  });

  const [updateCMTicket] = useMutation(UPDATE_CHANGE_MANAGEMENT_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setUploadedFileLoading(false)
      Message.success('Change Request updated successfully.');
      history.push(TicketingSystem.CM);
    }
  });

  useEffect(() => {
    if (id) {
      setLoadingBtn(true)
      changeManagementById({
        variables: {
          id: id
        }
      });
      getChangeManagementUsersList()
    }

    getUserListData({
      variables: {
        ticketType: TICKET_DATA.SERVICETICKET
      }
    })
  }, [])

  useEffect(() => {
    getUserData()
    if (userInfo && userInfo.userInfo && getAllUserList && getAllUserList.getAllUserList &&
      getAllUserList.getAllUserList.length) {
      agentData()
    }
  }, [getAllUserList, userInfo]);

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      if (lineManagerData && lineManagerData._id) {
        return CreateCMTicket({
          variables: {
            title: title,
            inCharge: IN_CHARGE.AGENT,
            changeImpact: changeImpact,
            description: details,
            changeType: changeType.value,
            lineManager: lineManager.id,
            changeAgent: agent.id,
            reserveAgent: reserveAgent.id,
            reserveAgent2: reserveAgent2.id,
            implimentStartDate: selectedDate,
            implimentEndDate: selectedEndDate,
            risk: risk,
            userAcceptanceTesting: userAcceptanceTesting,
            rollbackPlan: rollbackPlan,
            attachments: [data.uploadDocument.url]
          }
        });
      } else {
        return updateCMTicket({
          variables: {
            id: id,
            title: title,
            status: "escalate",
            changeImpact: changeImpact,
            description: details,
            changeType: changeType.value,
            lineManager: lineManager.id ? lineManager.id : lineManager._id,
            changeAgent: agent.id ? agent.id : agent._id,
            reserveAgent: reserveAgent.id ? reserveAgent.id : reserveAgent._id,
            reserveAgent2: reserveAgent2.id ? reserveAgent2.id : reserveAgent2._id,
            implimentStartDate: selectedDate,
            implimentEndDate: selectedEndDate,
            risk: risk,
            userAcceptanceTesting: userAcceptanceTesting,
            rollbackPlan: rollbackPlan,
            lineManagerComment: lineManagerComment && lineManagerComment,
            changeUrgency: changeUrgency.name,
            changeMatrixImpact: changeMatrixImpact.name,
            cabLeadComment: cabLeadComment && cabLeadComment,
            eHOD: eHODData.id ? eHODData.id : eHODData._id,
            EHODComment: EHODComment && EHODComment,
            inCharge: lineManagerData.inCharge == IN_CHARGE.AGENT ? IN_CHARGE.LINEMANAGER :
              lineManagerData.inCharge == IN_CHARGE.LINEMANAGER ? IN_CHARGE.CAB : lineManagerData.inCharge == IN_CHARGE.CAB ?
                IN_CHARGE.EHOD : lineManagerData.inCharge == IN_CHARGE.EHOD && IN_CHARGE.MD,
            attachments: [data.uploadDocument.url]
          }
        });
      }
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const inputFile = React.createRef();

  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  const agentData = () => {
    let agentData = getAllUserList.getAllUserList.find(function (item, index) {
      if (item.emailAddress == userInfo.userInfo.email) {
        return true
      }
    })
    setAgent(agentData)
  }

  const handleChange = () => {
    setEscalateEHOD(!escalateEHOD);
    setHideEscalateBtn(!hideEscalateBtn)
  };

  const updateChangeManagement = (data) => {
    return updateCMTicket({
      variables: {
        id: id,
        title: title,
        status: data.status,
        changeImpact: changeImpact,
        description: details,
        changeType: changeType.value,
        lineManager: lineManager.id ? lineManager.id : lineManager._id,
        changeAgent: agent.id ? agent.id : agent._id,
        reserveAgent: reserveAgent.id ? reserveAgent.id : reserveAgent._id,
        reserveAgent2: reserveAgent2.id ? reserveAgent2.id : reserveAgent2._id,
        implimentStartDate: selectedDate,
        implimentEndDate: selectedEndDate,
        risk: risk,
        userAcceptanceTesting: userAcceptanceTesting,
        rollbackPlan: rollbackPlan,
        lineManagerComment: lineManagerComment && lineManagerComment,
        cabLeadComment: cabLeadComment,
        changeUrgency: changeUrgency.name,
        changeMatrixImpact: changeMatrixImpact.name,
        eHOD: eHODData.id ? eHODData.id : eHODData._id,
        EHODComment: EHODComment,
        inCharge: lineManagerData.inCharge == IN_CHARGE.AGENT ? IN_CHARGE.LINEMANAGER :
          lineManagerData.inCharge == IN_CHARGE.LINEMANAGER ? IN_CHARGE.CAB : lineManagerData.inCharge == IN_CHARGE.CAB ?
            IN_CHARGE.EHOD : lineManagerData.inCharge == IN_CHARGE.EHOD && IN_CHARGE.MD
      }
    });
  }

  const handleChangeMDApproval = () => {
    setMdChecked(!mdChecked)
    setNeedMDApproval(true)
    setHideEscalateBtn(!hideEscalateBtn)
  }

  const changeMatrixFunctionCAB = (impact, urgency) => {
    if (impact && urgency) {
      if ((impact === 'Medium' && urgency === 'Low') ||
        (impact === 'Low' && urgency === 'Medium') ||
        (impact === 'Low' && urgency === 'Low')) {
        setShowApproveBtn(true)
        setShowEhodEsclate(false)
        setShowEscalateBtn(false)
        setShowEHODDrop(false)
      } else {
        setShowApproveBtn(false)
        setShowEhodEsclate(true)
        setShowEscalateBtn(true)
        setShowEHODDrop(true)
      }
    }
  }

  const handleChangeMatrixImpact = (event, newValue) => {
    setChangeMatrixImpact(newValue);
    changeMatrixFunctionCAB(newValue.name, changeUrgency.name)
  }

  const handleChangeUrgency = (event, newValue) => {
    setChangeUrgency(newValue);
    changeMatrixFunctionCAB(changeMatrixImpact.name, newValue.name)
  }

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '700px' }}>
      <Header
        title={t('changeRequestForm.label')}
        divider={true}
        subText={t('changeRequestSub.label')}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
      />

      {loadingBtn && <LinearProgress color="secondary" />}

      <Grid style={{ ...marginGenerator('mt-32') }}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('title.label')}*
        </Typography>
        <Input
          value={title}
          type={'text'}
          style={{ width: '100%' }}
          color={'secondary'}
          onChange={evt => setTitle(evt.target.value)}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('changeType.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo7"
          value={changeType}
          options={CHANGE_CATEGORY ? CHANGE_CATEGORY : []}
          getOptionLabel={option => option.value}
          onChange={(event, newValue) => {
            setChangeType(newValue)
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        {changeType && changeType.description && (
          <div className={classes.changeDescription}>{changeType.description}</div>
        )}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('changeSummary.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>

          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={details}
              id="details-textarea"
              multiline
              onChange={evt => setDetails(evt.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid container item justify={'flex-end'} className='test'>
            {uploadedFile && (
              <Chip
                avatar={<img src={Image} alt={'file'} />}
                label={
                  uploadedFile.name.length > 12
                    ? `${uploadedFile.name.slice(0, 12)}...`
                    : uploadedFile.name
                }
                onDelete={() => {
                  setUploadedFile('');
                }}
                deleteIcon={<Close />}
                variant="outlined"
              />
            )}
            <IconButton
              size={'medium'}
              disableRipple={true}
              disableFocusRipple={true}
              color={'secondary'}
              onClick={handleFileUploadClick}
              className={classes.iconStyle}
              style={marginGenerator('ml-16')}>
              <AttachFile className={commonClasses.iconRegular} />
            </IconButton>
            <input
              type="file"
              id="attachment"
              ref={inputFile}
              onChange={event => {
                event.stopPropagation();
                event.preventDefault();
                setUploadedFile(event.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('lineManager.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={lineManager}
          options={getAllUserList ? getAllUserList.getAllUserList : []}
          getOptionLabel={option =>
            option && option.firstName
              ? `${option.firstName} ${option.lastName}`
              : ''
          }
          onChange={(event, newValue) => {
            setLineManager(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('changeAgent.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={agent}
          options={getAllUserList ? getAllUserList.getAllUserList : []}
          getOptionLabel={option =>
            option && option.firstName
              ? `${option.firstName} ${option.lastName}`
              : ''
          }
          onChange={(event, newValue) => {
            setAgent(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('reserveChangeAgent.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={reserveAgent}
          options={getAllUserList ? getAllUserList.getAllUserList : []}
          getOptionLabel={option =>
            option && option.firstName
              ? `${option.firstName} ${option.lastName}`
              : ''
          }
          onChange={(event, newValue) => {
            setReserveAgent(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('reserveChangeAgent2.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={reserveAgent2}
          options={getAllUserList ? getAllUserList.getAllUserList : []}
          getOptionLabel={option =>
            option && option.firstName
              ? `${option.firstName} ${option.lastName}`
              : ''
          }
          onChange={(event, newValue) => {
            setReserveAgent2(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('changeImpact.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={changeImpact}
              id="details-textarea"
              multiline
              onChange={evt => setChangeImpact(evt.target.value)}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('implimentationStartDate.label')}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.dateSize}>
              <DateTimePicker
                autoOk
                ampm={false}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('implimentationEndDate.label')}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.dateSize}>
              <DateTimePicker
                autoOk
                minDate={selectedDate}
                ampm={false}
                value={selectedEndDate}
                onChange={handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('risk.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={risk}
              id="details-textarea"
              multiline
              onChange={evt => setRisk(evt.target.value)}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('userAcceptanceTesting.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={userAcceptanceTesting}
              id="details-textarea"
              multiline
              onChange={evt => setUserAcceptanceTesting(evt.target.value)}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('rollbackPlan.label')}
        </Typography>

        <Grid container item className={classes.detailsContainer}>
          <Grid
            container
            item
            style={{
              ...paddingGenerator('pt-16'),
              ...paddingGenerator('pb-16'),
              ...paddingGenerator('pl-24'),
              ...paddingGenerator('pr-24')
            }}>
            <InputBase
              fullWidth
              value={rollbackPlan}
              id="details-textarea"
              multiline
              onChange={evt => setRollbackPlan(evt.target.value)}
              variant="standard"
            />
          </Grid>
        </Grid>
        {showLineManager ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('lineManagerComment.label')}
            </Typography>

            <Grid container item className={classes.detailsContainer}>
              <Grid
                container
                item
                style={{
                  ...paddingGenerator('pt-16'),
                  ...paddingGenerator('pb-16'),
                  ...paddingGenerator('pl-24'),
                  ...paddingGenerator('pr-24')
                }}>
                <InputBase
                  fullWidth
                  value={lineManagerComment}
                  id="details-textarea"
                  multiline
                  onChange={evt => {
                    setLineManagerComment(evt.target.value)
                    if (evt.target.value == '') {
                      setDisableButtons(true)
                    } else {
                      setDisableButtons(false)
                    }
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        {showChangeMatrix ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('priorityUrgency.label')}
            </Typography>

            <Autocomplete
              id="combo-box-demo3"
              value={changeUrgency}
              options={CHANGE_URGENCY ? CHANGE_URGENCY : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                handleChangeUrgency(event, newValue);
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />

            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('priorityImpact.label')}
            </Typography>

            <Autocomplete
              id="combo-box-demo3"
              value={changeMatrixImpact}
              options={CHANGE_MATRIX_IMPACT ? CHANGE_MATRIX_IMPACT : []}
              getOptionLabel={option => (option ? option.name : '')}
              onChange={(event, newValue) => {
                handleChangeMatrixImpact(event, newValue)
              }}
              style={{ width: '94%' }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      width: '100%',
                      padding: '18px',
                      border: '1px solid #ebebeb !important'
                    }}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </>
        ) : null}
        {showCAB ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('cabLeadComment.label')}
            </Typography>

            <Grid container item className={classes.detailsContainer}>
              <Grid
                container
                item
                style={{
                  ...paddingGenerator('pt-16'),
                  ...paddingGenerator('pb-16'),
                  ...paddingGenerator('pl-24'),
                  ...paddingGenerator('pr-24')
                }}>
                <InputBase
                  fullWidth
                  value={cabLeadComment}
                  id="details-textarea"
                  multiline
                  onChange={evt => {
                    setCABLeadComment(evt.target.value)
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>

            {showEhodEsclate && (
              <div className="additionalField">
                <FormControlLabel
                  label="Escalate to E-HOD"
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                />
              </div>
            )}

            {showEHODDrop ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
                  {t('eHOD.label')} *
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  value={eHODData}
                  disabled={!escalateEHOD}
                  options={getAllUserList ? getAllUserList.getAllUserList : []}
                  getOptionLabel={option =>
                    option && option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : ''
                  }
                  onChange={(event, newValue) => {
                    setEHODData(newValue);
                  }}
                  style={{ width: '94%' }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        style={{
                          width: '100%',
                          padding: '18px',
                          border: '1px solid #ebebeb !important'
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </>
            ) : null}
          </>
        ) : null}
        {showEHOD ? (
          <>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
              {t('eHODComment.label')}
            </Typography>

            <Grid container item className={classes.detailsContainer}>
              <Grid
                container
                item
                style={{
                  ...paddingGenerator('pt-16'),
                  ...paddingGenerator('pb-16'),
                  ...paddingGenerator('pl-24'),
                  ...paddingGenerator('pr-24')
                }}>
                <InputBase
                  fullWidth
                  value={EHODComment}
                  id="details-textarea"
                  multiline
                  onChange={evt => {
                    setEHODComment(evt.target.value)
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>

            {showMDApprovalBtn && (
              <div className="additionalField">
                <FormControlLabel
                  label="MD Approval"
                  control={
                    <Checkbox
                      checked={mdChecked}
                      onChange={handleChangeMDApproval}
                      name="checkeMD"
                      color="primary"
                    />
                  }
                />
              </div>
            )}
          </>
        ) : null}
      </Grid>

      {createBtn ? (
        <Grid
          container
          style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
          justify={'flex-end'}>
          <Button
            variant={'contained'}
            disabled={btnDisable ? btnDisable : uploadedFileLoading}
            color={'primary'}
            onClick={() => {
              if (
                !title ||
                !details ||
                !changeImpact ||
                !changeType.value ||
                !lineManager.id ||
                !agent.id ||
                !reserveAgent.id ||
                !reserveAgent2.id ||
                !selectedDate ||
                !selectedEndDate ||
                !risk ||
                !userAcceptanceTesting ||
                !rollbackPlan
              ) {
                Message.error(REQUIRED_ERROR);
                return null;
              } else {
                setUploadedFileLoading(true);
                uploadedFile
                  ? prepareUpload()
                  : CreateCMTicket({
                    variables: {
                      title: title,
                      inCharge: lineManagerData.inCharge == IN_CHARGE.AGENT ? IN_CHARGE.LINEMANAGER :
                        lineManagerData.inCharge == IN_CHARGE.LINEMANAGER ? IN_CHARGE.CAB : lineManagerData.inCharge == IN_CHARGE.CAB ?
                          IN_CHARGE.EHOD : lineManagerData.inCharge == IN_CHARGE.EHOD ? IN_CHARGE.MD : IN_CHARGE.AGENT,
                      changeImpact: changeImpact,
                      description: details,
                      changeType: changeType.value,
                      lineManager: lineManager.id,
                      changeAgent: agent.id,
                      reserveAgent: reserveAgent.id,
                      reserveAgent2: reserveAgent2.id,
                      implimentStartDate: selectedDate,
                      implimentEndDate: selectedEndDate,
                      risk: risk,
                      userAcceptanceTesting: userAcceptanceTesting,
                      rollbackPlan: rollbackPlan
                    }
                  });
              }
            }}>
            {uploadedFileLoading ? (
              <CircularProgress size={14} />
            ) : (
              'Create CR'
            )}
          </Button>
        </Grid>
      ) : (
        <>
          {
            userInfo && userInfo.userInfo.email === inChargeEmail ? (
              <Grid
                container
                style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
                justify={'flex-end'}>
                {showApproveBtn ? (
                  <Button
                    variant={'contained'}
                    className={classes.updateBtn}
                    disabled={disableButtons ? disableButtons : btnDisable ? btnDisable : approveLoading}
                    color={'primary'}
                    onClick={() => {
                      if (
                        !title ||
                        !details ||
                        !changeImpact ||
                        !changeType.value ||
                        !lineManager.emailAddress ||
                        !agent.emailAddress ||
                        !reserveAgent.emailAddress ||
                        !reserveAgent2.emailAddress ||
                        !selectedDate ||
                        !selectedEndDate ||
                        !risk ||
                        !userAcceptanceTesting ||
                        !rollbackPlan ||
                        !lineManagerComment
                      ) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      } else {
                        setApproveLoading(true);
                        uploadedFile
                          ? prepareUpload()
                          : updateChangeManagement({ status: lineManagerData.inCharge == IN_CHARGE.AGENT ? "In Progress" : "approved" })
                      }
                    }}>
                    {approveLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Approve CR'
                    )}
                  </Button>
                ) : null}

                {showRejectBtn ? (
                  <Button
                    variant={'contained'}
                    className={classes.updateBtn}
                    disabled={disableButtons ? disableButtons : btnDisable ? btnDisable : rejectLoading}
                    color={'primary'}
                    onClick={() => {
                      if (
                        !title ||
                        !details ||
                        !changeImpact ||
                        !changeType.value ||
                        !lineManager.emailAddress ||
                        !agent.emailAddress ||
                        !reserveAgent.emailAddress ||
                        !reserveAgent2.emailAddress ||
                        !selectedDate ||
                        !selectedEndDate ||
                        !risk ||
                        !userAcceptanceTesting ||
                        !rollbackPlan ||
                        !lineManagerComment
                      ) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      } else {
                        setRejectLoading(true);
                        uploadedFile
                          ? prepareUpload()
                          : updateChangeManagement({ status: "reject" })
                      }
                    }}>
                    {rejectLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Reject CR'
                    )}
                  </Button>
                ) : null}

                {showEscalateBtn ? (
                  <Button
                    variant={'contained'}
                    className={classes.updateBtn}
                    disabled={disableButtons ? disableButtons : btnDisable ? btnDisable : escalateLoading ? escalateLoading : hideEscalateBtn}
                    color={'primary'}
                    onClick={() => {
                      if (
                        !title ||
                        !details ||
                        !changeImpact ||
                        !changeType.value ||
                        !lineManager.emailAddress ||
                        !agent.emailAddress ||
                        !reserveAgent.emailAddress ||
                        !reserveAgent2.emailAddress ||
                        !selectedDate ||
                        !selectedEndDate ||
                        !risk ||
                        !userAcceptanceTesting ||
                        !rollbackPlan ||
                        !lineManagerComment
                      ) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      } else {
                        setEscalateLoading(true);
                        uploadedFile
                          ? prepareUpload()
                          : updateChangeManagement({ status: "escalate" })
                      }
                    }}>
                    {escalateLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Escalate'
                    )}
                  </Button>
                ) : null}

                {showReturnBtn ? (
                  <Button
                    variant={'contained'}
                    disabled={disableButtons ? disableButtons : btnDisable ? btnDisable : returnLoading}
                    color={'primary'}
                    className={classes.updateBtn}
                    onClick={() => {
                      if (
                        !title ||
                        !details ||
                        !changeImpact ||
                        !changeType.value ||
                        !lineManager.emailAddress ||
                        !agent.emailAddress ||
                        !reserveAgent.emailAddress ||
                        !reserveAgent2.emailAddress ||
                        !selectedDate ||
                        !selectedEndDate ||
                        !risk ||
                        !userAcceptanceTesting ||
                        !rollbackPlan ||
                        !lineManagerComment
                      ) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      } else {
                        setReturnLoading(true);
                        uploadedFile
                          ? prepareUpload()
                          : updateChangeManagement({ status: "return" })
                      }
                    }}>
                    {returnLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Return CR'
                    )}
                  </Button>
                ) : null}
                <Button
                  variant={'contained'}
                  className={classes.updateBtn}
                  disabled={btnDisable ? btnDisable : uploadedFileLoading}
                  color={'primary'}
                  onClick={() => {

                  }}>
                  {uploadedFileLoading ? (
                    <CircularProgress size={14} />
                  ) : (
                    'Cancel'
                  )}
                </Button>
              </Grid>
            ) : null
          }
        </>
      )}
    </Layout>
  );
}

export default withTranslation()(NewChangeRequest);
