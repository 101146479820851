// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { ADD_INSTALL_TEAM_LEAD, CREATE_CHANGE_CONTROL_REQUEST_FORM } from "../../mutations";
import { GET_CATEGORIES, GET_RADIO_ENGINEERING_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RadioEngineerItem from "../RadioEngineerItem";
import CreateProjectInitiateModal from "../../../../Subscriptions/components/CreateProjectInitiateModal";
import { GET_USER_INFO } from '../../../../Profile/queries';
import { GET_ALL_USER_BY_ROLES } from '../../../../commonQueries';
import CreateInstallTeamLeadModal from "../CreateInstallTeamLeadModal";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
const RadioEngineer = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [ChangeControlRequestFormUrl, setChangeControlRequestFormUrl] = useState('');
    const [AllInstallTeamUserList, setAllInstallTeamUserList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [changeControlRequestQuestionFormModalState, setChangeControlRequestQuestionFormModalState] = useState(false);
    const [ChangeControlRequestQuestionModalState, setChangeControlRequestQuestionModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [installFormExtraParameter, setInstallFormExtraParameter] = useState({ recommendation: false, costControlFrom: false });
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [selectedProjectTypeAndUid, setSelectedProjectTypeAndUid] = useState({ project_type: '', uid: '', case_number: '', opportunity_uid: '' });
    const [preValuesOfForm, setPreValuesOfForm] = useState({});
    const [installTeamSection, setInstallTeamSection] = useState(false);
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        getAllList({
            variables: {
                pageNumberOrUid: 'page=1&project_owner=is_admin'
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                getAllList({
                    variables: {
                        pageNumberOrUid: "project_owner=is_admin&page=1"
                    }
                })
            }
        }
        getAllInstallTeamUserList({
            variables: {
                role: USER_ROLES.INSTALL_TEAM
            }
        })
    }, [refreshTab]);
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        getAllList({
            variables: {
                pageNumberOrUid: 'project_owner=is_admin&page=' + value
            }
        })
    };
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            // console.log('Error', error);
        },
        onCompleted: data => {
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // let full_name = data.userInfo.id;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            // if (role.includes(USER_ROLES.INSTALL_TEAM) && !checkRoleResult) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&im_team=" + full_name
            //         }
            //     })
            // }
        }
    });
    const [getAllList] = useLazyQuery(GET_RADIO_ENGINEERING_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getRadioEngineeringTeamList) {
                let parseResult = JSON.parse(data.getRadioEngineeringTeamList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error :", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('installTeam.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        setSelectedProjectTypeAndUid({ ...{ uid: data.project, project_type: 'High Capacity', case_number: data.case_number } })
        setPreValuesOfForm(data);
        handleCreateInstallTeam();
        // if (elem.label === t('edit.label')) {
        //     // setModalState({...modalState, btnLoading: false});
        //     // setUpdateCategory(data);
        //     // setCategoryModalState(true);
        // } else if (elem.label === t('delete.label')) {
        //     if (
        //         window.confirm('Are you sure you want to delete this marketplace category ?') ===
        //         false
        //     ) {
        //         return false;
        //     }
        //     /*  return deleteMarketPlaceCategory({
        //           variables: {
        //               category_id: data._id
        //           }
        //       });*/
        // }
    }
    const [getAllInstallTeamUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            // console.log('Error role install', error);
        },
        onCompleted: data => {
            if (data.getAllUserByRoles) {
                // console.log("data role install: ", data);
                let parseData = data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
                // console.log("parseData role install: ", parseData);
                setAllInstallTeamUserList(parseData);
            }
        }
    });
    const [addInstallTeam] = useMutation(ADD_INSTALL_TEAM_LEAD, {
        onError: error => {
            setModalState({...modalState, btnLoading: false});
            console.log('Error', error);
            setError(true);
        },
        onCompleted: data => {
            setModalState({ btnLoading: false, clearData: true });
            // console.log("data addInstallTeam : ", data);
            if (data && data.addInstallTeamLeadSection) {
                if (JSON.parse(data.addInstallTeamLeadSection.status) === true) {
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addInstallTeamLeadSection.teamData);
                    Message.success('New install team lead section created successfully');
                    setPreValuesOfForm({});
                    handleRefreshTab(true);
                    handleCloseInstallTeamModal();
                    // if (selectedProjectTypeAndUid.project_type === 'Regular project') {
                    //     handleImTeam();
                    // } else if (selectedProjectTypeAndUid.project_type === 'High Capacity' || selectedProjectTypeAndUid.project_type === 'Coverage Outside Inq') {
                    //     handleChangeControlTeam();
                    // }
                } else {
                    let message = JSON.parse(data.addInstallTeamLeadSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSubmitInstallTeam = (formData) => {
        setModalState({...modalState, btnLoading: true });
        handleRefreshTab(false);
        if(formData.on_hold != (t("project.label") + ' ' + t("canceled.label"))) {
            addInstallTeam({
                variables: {
                    project_uid: formData.project_uid,
                    change_control_questionaire_form: formData.changeControlForm,
                    // affix_circuit_id: formData.affixCircuitId,
                    im_team: formData.attachIMPersonnel,
                    comment: formData.comment,
                    recommendation: formData.recommendation,
                    cost_control_form: formData.costControlForm,
                    previous_comment: formData.previous_comment,
                    equipments_to_be_deployed: formData.equipments_to_be_deployed,
                    last_team_member_name: CurrentUserName,
                    last_team_member_email: userData.userInfo.email,
                }
            })
        }
    }
    const handleCreateInstallTeam = () => {
        setInstallTeamSection(true);
    }
    const handleCloseInstallTeamModal = () => {
        setModalState({ btnLoading: false, clearData: true });
        setInstallTeamSection(false);
    }
    /**
 * Create Project Initiate Form Submit
 */
    const handleSaveProjectInitiate = (formData) => {
        setChangeControlRequestQuestionModalState({...ChangeControlRequestQuestionModalState, btnLoading: true });
        formData.project_name = selectedProjectTypeAndUid.uid;
        // formData.opportunity = selectedProjectTypeAndUid.opportunity_uid;
        // formData.opportunityId = selectedProjectTypeAndUid.opportunity_uid;
        SaveChangeControlRequestQuestionForm({ variables: { formData: JSON.stringify(formData) } })
    }
    const [SaveChangeControlRequestQuestionForm] = useMutation(CREATE_CHANGE_CONTROL_REQUEST_FORM, {
        onError: error => {
            setChangeControlRequestQuestionModalState({...ChangeControlRequestQuestionModalState, btnLoading: false });
            console.log('Error', error);
        },
        onCompleted: data => {
            // console.log("data : ", data);
            setChangeControlRequestQuestionModalState({...ChangeControlRequestQuestionModalState, btnLoading: false });
            if (data && data.createCostControlRequest) {
                if (JSON.parse(data.createCostControlRequest.status) === true) {
                    let parseData = JSON.parse(data.createCostControlRequest.teamData);
                    setChangeControlRequestFormUrl(parseData.pdf_link);
                    Message.success('Change control request questionarie form created successfully');
                    setChangeControlRequestQuestionFormModalState(false);
                    setInstallTeamSection(true);
                } else {
                    let message = JSON.parse(data.createCostControlRequest.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleOpenChangeControlRequestQuestionForm = () => {
        setChangeControlRequestQuestionFormModalState(true);
    }
    return (
        <>
            {(role.includes(USER_ROLES.IP_VNFADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) &&
                <Header
                    title={t('RadioTeamEngineeringSection.label')}
                    subText={`${t('RadioTeamEngineeringSection.label')}`}
                    // primaryButtonText={t('createNew.label')}
                    handlePrimaryButtonClick={handleCreateButtonClick}
                />}
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <RadioEngineerItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CreateInstallTeamLeadModal open={installTeamSection} handleSubmitClick={handleSubmitInstallTeam} t={t} CurrentUserName={CurrentUserName} preValuesOfForm={preValuesOfForm}
                    handleClose={handleCloseInstallTeamModal} modalState={modalState} AllInstallTeamUserList={AllInstallTeamUserList} installFormExtraParameter={installFormExtraParameter} selectedProjectTypeAndUid={selectedProjectTypeAndUid}
                    isActionUpdate={false} ChangeControlRequestFormUrl={ChangeControlRequestFormUrl} handleOpenChangeControlRequestQuestionForm={handleOpenChangeControlRequestQuestionForm} />
                <CreateProjectInitiateModal
                    t={t}
                    open={changeControlRequestQuestionFormModalState}
                    handleSaveProjectInitiate={handleSaveProjectInitiate}
                    headingName={t('changeControlQuestionaireForm.label')}
                    subheading={false}
                    // data={data}
                    handleClose={() => {
                        setChangeControlRequestQuestionModalState({...ChangeControlRequestQuestionModalState, btnLoading: false });
                        setChangeControlRequestQuestionFormModalState(false);
                    }}
                    modalState={ChangeControlRequestQuestionModalState}
                />
            </Grid>
        </>
    );
};
export default withTranslation()(RadioEngineer);
