import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateSalesTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    handleUploadFiles,
    modalContentLoading,
    selectedProjectTypeAndUid
}) {
    const classes = useStyles();
    const [salesForce, setSalesForce] = useState('');
    const [clientName, setClientName] = useState('');
    const [physicalAddress, setPhysicalAddress] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [gpsLocation, setGpsLocation] = useState('');
    const [email, setEmail] = useState('');
    const [deliveryDueDate, setDeliveryDueDate] = useState('');
    const [taxPayerNumber, setTaxPayerNumber] = useState('');
    const [rccm, setRccm] = useState('');
    const [itTeam, setItTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [equipmentsToBeDeployed, setEquipmentsToBeDeployed] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setSalesForce('');
            setClientName('');
            setPhysicalAddress('');
            setContactPerson('');
            setEquipmentsToBeDeployed('');
            setGpsLocation('');
            setEmail('');
            setDeliveryDueDate('');
            setTaxPayerNumber('');
            setRccm('');
            setItTeam('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
        }
    }, [modalState, open, modalContentLoading, selectedProjectTypeAndUid]);
   
    const onHoldList = [
        {
            id: t("project.label")+' '+t("inProgress.label"),
            name: t("project.label")+' '+t("inProgress.label")
        },
        {
            id: t("project.label")+' '+t("onHold.label"),
            name: t("project.label")+' '+t("onHold.label")
        },
        {
            id: t("project.label")+' '+t("completed.label"),
            name: t("project.label")+' '+t("completed.label")
        },
        {
            id: t("project.label")+' '+t("canceled.label"),
            name: t("project.label")+' '+t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('salesTeam.label')}</DialogTitle>
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('salesForce.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={salesForce}
                                    type={'text'}
                                    placeholder={t('salesForce.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setSalesForce(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('clientName.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={clientName}
                                    type={'text'}
                                    placeholder={t('clientName.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setClientName(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('physicalAddress.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={physicalAddress}
                                    type={'text'}
                                    placeholder={t('physicalAddress.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setPhysicalAddress(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('contactPerson.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={contactPerson}
                                    type={'text'}
                                    placeholder={t('contactPerson.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setContactPerson(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('gpsLocation.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={gpsLocation}
                                    type={'text'} 
                                    placeholder={t('gpsLocation.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setGpsLocation(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('email.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={email}
                                    type={'text'} 
                                    placeholder={t('email.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setEmail(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('deliveryDueDate.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={deliveryDueDate}
                                        onChange={date => setDeliveryDueDate(date)}
                                        placeholderText="Please select a date"
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('taxPayerNumber.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={taxPayerNumber}
                                    type={'text'} 
                                    placeholder={t('taxPayerNumber.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setTaxPayerNumber(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('rccm.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={rccm}
                                    type={'text'} 
                                    placeholder={t('rccm.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setRccm(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={equipmentsToBeDeployed}
                                    type={'text'}
                                    placeholder={t('equipmentsToBeDeployed.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setEquipmentsToBeDeployed(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label')+' '+t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label')+' '+t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t("itTeam.label")} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setItTeam(evt.target.value);
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={allUserList ? allUserList : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!salesForce || !clientName || !physicalAddress || !contactPerson || !gpsLocation ||
                                !email || !deliveryDueDate || !taxPayerNumber || !rccm || !equipmentsToBeDeployed || !itTeam || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (email != '' && !checkValidEmail(email)) {
                                Message.error(INVALID_EMAIL);
                                return null;
                            }
                            handleSubmitClick({
                                project_uid: selectedProjectTypeAndUid.uid,
                                equipments_to_be_deployed: equipmentsToBeDeployed,
                                salesforce: salesForce,
                                client_name: clientName,
                                physical_address: physicalAddress,
                                contact_person: contactPerson,
                                gps_cordinate: gpsLocation,
                                email: email,
                                delivery_due_date: moment(deliveryDueDate).format("YYYY-MM-DD"),
                                tax_payer_number: taxPayerNumber,
                                rccm: rccm,
                                it_team: itTeam,
                                next_team_member_email: nextTeamMemberEmail,
                                category: category,
                                on_hold: onHold,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateSalesTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateSalesTeamModal);