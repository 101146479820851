import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 0,
      flexDirection: 'row',
      '@media max-width: 400': {
          flexDirection: 'column',
      },
    },
    leftContainer: {
      flexDirection: 'column',
      width: 250,
      paddingTop: 30,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    centerContainer: {
      flexDirection: 'column',
      width: 110,
      paddingTop: 30,
      paddingLeft: 0,
      paddingBottom: 0,
      color: "#9696A4",
      '@media max-width: 400': {
        paddingTop: 10,
        paddingLeft: 0,
      },
    },
    rightContainer: {
      flexDirection: 'column',
      paddingTop: 30,
      paddingLeft: 0,
      paddingBottom: 0,
      '@media max-width: 400': {
        paddingTop: 10,
        paddingLeft: 0,
      },
    },
});

function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
}
const BillTo = ({userInfo, invoice}) => (
  <View style={styles.container}>
    <View style={styles.leftContainer}>
        <Text>{`${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
        <Text>{userInfo?.organization ? userInfo?.organization.name : ""}</Text>
        <Text>{userInfo?.organization ? userInfo?.organization?.address ? userInfo?.organization?.address : "" : ""} {userInfo?.organization ? userInfo?.organization?.country ? userInfo?.organization?.country?.name : "" : ""}</Text>
        <Text>{userInfo?.phone}</Text>
        <Text>{userInfo?.email}</Text>
    </View>
    <View style={styles.centerContainer}>
      <Text>Period:</Text>
      <Text>Date billed:</Text>
      <Text>Order no:</Text>
      <Text>Amount:</Text>
    </View>
    <View style={styles.rightContainer}>
      <Text>{`${convertDate(invoice.startDate)} - ${convertDate(invoice.endDate)}`}</Text>
      <Text>{convertDate(invoice.endDate)}</Text>
      <Text>#{invoice.invoiceNumber}</Text>
      <Text>{invoice.currency ? invoice.currency.symbol : "$"}{invoice.totalPayableAmount.toFixed(2)}</Text>
    </View>
  </View>
);

  export default BillTo
