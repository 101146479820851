import React, {useState,useContext} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import {marginGenerator} from '../../../../../../theme/utils';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';
import ShayonaConfigurationTruckItem from './ShayonaConfigurationTruckItem';
import {
    DELETE_SHAYONA_TRUCK
} from '../../../../Shayona/mutations';
import Message from '../../../../../../components/Message';


const ShayonaConfigurationTruckMaster = ({
    t,
    creteNewTruck
}) => {

    const {
        organizationId,
        shayonaTruckLoading,
        shayonaTruckList,
        reloadShayonaApisOrPagination,
        paginationPage,
        goToPage,
        setGoToPage
    } = useContext(ShayonaCementsContext);
    const [selecteIdForDelete, setSelecteIdForDelete] = useState('');

    const [deleteTruck, { loading: deleteTruckLoading }] = useMutation(DELETE_SHAYONA_TRUCK, {
        onError: error => {
            console.log('err', error);
            setSelecteIdForDelete('');

        },
        onCompleted: data => {
            setSelecteIdForDelete('');
            Message.success(data && data.deleteTruck.message);
            reloadShayonaApisOrPagination('truck','');
        }
    });

    const handleSubmitOfDeleteTruck = (id) => {
        setSelecteIdForDelete(id);
        deleteTruck({
            variables:{
                organization:organizationId,
                categoryId: id
            }
        })
    };

    let columns = [
        {
            label: 'Truck' + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        // {
        //     label: 'Empty Weight Range',
        //     key: "empty"
        // },
        {
            label: "Allowed Max Bags",
            key: "bags"
        },
        {
            label: "Gross weight Range",
            key: "gross"
        },
        {
            label: "Allowed Diff weight",
            key: "diffWeight"
        },
        {
            label: t('action.label'),
            key: "action"
        }
    ];

  return (
    <>
        <Grid container style={{...marginGenerator("mb-20")}}>
        <Grid item xs="10"/>
        <Grid item xs="2">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={() => creteNewTruck('Add truck', '')}
                >
                {t('createNow.label')}
            </Button>
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!shayonaTruckLoading ?
                      get(shayonaTruckList, 'getTruckList.data', []) && get(shayonaTruckList, 'getTruckList.data', []).length > 0 ?
                      get(shayonaTruckList, 'getTruckList.data', []).map((rowitem, rowIndex) => (
                                <ShayonaConfigurationTruckItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    deleteTruckLoading={deleteTruckLoading}
                                    selecteIdForDelete={selecteIdForDelete}
                                    handleSubmitOfDeleteTruck={handleSubmitOfDeleteTruck}
                                    creteNewTruck={creteNewTruck}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {shayonaTruckLoading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            { !shayonaTruckLoading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(shayonaTruckList, 'getTruckList.count', null)/10)} page,  {get(shayonaTruckList, 'getTruckList.count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={get(shayonaTruckList, 'getTruckList.count', null) ? Math.ceil(get(shayonaTruckList, 'getTruckList.count', null)/10) : 0} 
                                page={paginationPage} 
                                onChange={(evt, value) => {
                                    reloadShayonaApisOrPagination('truck',value);
                                }} 
                            />
                        </Grid>
                        <Grid item xs={1}>
                                    <span>Go to page </span>
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={goToPage}
                                type="number"
                                style={{ minWidth: 100 }}
                                color={'secondary'}
                                onChange={evt => {
                                    let maxRecord = get(shayonaTruckList, 'getTruckList.count', null) ? parseInt(Math.ceil(get(shayonaTruckList, 'getTruckList.count', null)/10)) : 0;
                                    if (parseInt(evt.target.value) > maxRecord) {
                                        return null;
                                    }
                                    setGoToPage(evt.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => reloadShayonaApisOrPagination('truck', parseInt(goToPage))}
                                variant={'outlined'}
                                color="primary"
                                disabled={!goToPage}
                                style={{ marginLeft: 10 }}
                            >Go</Button>
                        </Grid>
                    </Grid>
                </>
            }
        </TableContainer>
    </Grid>
    </>
  )
}

export default ShayonaConfigurationTruckMaster