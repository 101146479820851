import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { dateFormatterLLL } from '../../../../../../utils';
import SignIcon from '../../../../../../assets/icons/sign-pen.png';
import { Button } from '@material-ui/core';

const MeetingPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    allowConfigure,
    handleAddMeetingNotes,
    users
}) => {
    const commonClasses = commonStyles();
    const [attendees, setAttendees] = useState("");

    React.useEffect(() => {
        if(users && users.length){
            const names = data.attendees && data.attendees.map(itm => {
                const existRecord = users && users.find(usr => usr.id === itm);
                return existRecord ? existRecord.name : "";
            });
            setAttendees(names.join(", "));
        }
    }, [users]);

    const checkStatus = (data) => {
        if(new Date(data.dateTime).getTime() > new Date().getTime()){
            if(data.sign === null && data.notes === null){
                return 'Incoming'
            }else{
                return 'Held'
            }
        }
        if(new Date().getTime() > new Date(data.dateTime).getTime()){
            if(data.sign === null && data.notes === null){
                return 'Not Held'
            }else{
                return 'Held'
            }
        }
    }

    return (
        <>
            <TableRow key={data._id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography
                                variant={'subtitle1'}
                                style={{ cursor: 'pointer' }}
                            >
                                {data.title ? data.title : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.dateTime)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {checkStatus(data)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {attendees ? attendees : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.venue ? data.venue : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleAddMeetingNotes(data)} variant="outlined" color="primary">{data && data.notes !== null ? "View Notes" : "+ Add Notes"}</Button>
                </TableCell>
                {allowConfigure &&
                    <TableCell>
                        <IconButton onClick={() => handleMenuItemClick("sign", data)}>
                            <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                            <EditIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

MeetingPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(MeetingPanelItem);