import moment from 'moment';

export const staticGraph_30 = [
  {
    _source: {
      '@timestamp': moment()
        .subtract(30, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(29, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(28, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(27, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(26, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(25, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(24, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(23, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },

  {
    _source: {
      '@timestamp': moment()
        .subtract(22, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(21, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(20, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(19, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(18, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(17, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(16, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(15, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(14, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(13, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(12, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(11, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(10, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(9, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(8, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(7, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(6, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(5, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(4, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(3, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(2, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(1, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  }
];

export const staticGraph_7 = [
  {
    _source: {
      '@timestamp': moment()
        .subtract(7, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(6, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(5, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(4, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(3, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(2, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(1, 'days')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  }
];

export const staticGraph_24 = [
  {
    _source: {
      '@timestamp': moment()
        .subtract(24, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(23, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(22, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(21, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(20, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(19, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(18, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(17, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(16, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(15, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(14, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(13, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(12, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(11, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(10, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(9, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(8, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(7, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(6, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(5, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(4, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(3, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(2, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  },
  {
    _source: {
      '@timestamp': moment()
        .subtract(1, 'h')
        .format(),
      interfaces: {
        inBoundUtiliz: 0
      }
    }
  }
];

export const dummyGraphData7 = [
  {
    _id: 'drT1logBevPYC7qY7e8S',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T17:42:37.169Z',
      denomi: 116737669918,
      timeDiff: 116.737669918,
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(15, 'minutes')
        .utc()
        .format(),
      type: 'snmp',
      'sysName.0': 'test-lus1-pod1-switchB',
      lastPollCycleIfInOctets: 0,
      '@version': '1'
    },
    sort: [1686159873906]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'KbX3logBevPYC7qYzEWj',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T17:44:33.906Z',
      denomi: 122755647099,
      timeDiff: 122.755647099,
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(13, 'minutes')
        .utc()
        .format(),
      type: 'snmp',
      'sysName.0': 'test-lus1-pod1-switchB',
      lastPollCycleIfInOctets: 0,
      '@version': '1'
    },
    sort: [1686159996661]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'KbX3logBevPYC7qYzEWj',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T17:44:33.906Z',
      denomi: 122755647099,
      timeDiff: 122.755647099,
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(11, 'minutes')
        .utc()
        .format(),
      type: 'snmp',
      'sysName.0': 'test-lus1-pod1-switchB',
      lastPollCycleIfInOctets: 0,
      '@version': '1'
    },
    sort: [1686159996661]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'KbX3logBevPYC7qYzEWj',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T17:44:33.906Z',
      denomi: 122755647099,
      timeDiff: 122.755647099,
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(9, 'minutes')
        .utc()
        .format(),
      type: 'snmp',
      'sysName.0': 'test-lus1-pod1-switchB',
      lastPollCycleIfInOctets: 0,
      '@version': '1'
    },
    sort: [1686159996661]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'zrb7logByU1gUli0YPlR',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T17:46:36.661Z',
      denomi: 234312802899.00003,
      timeDiff: 234.31280289900002,
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(7, 'minutes')
        .utc()
        .format(),
      type: 'snmp',
      'sysName.0': 'test-lus1-pod1-switchB',
      lastPollCycleIfInOctets: 0,
      '@version': '1'
    },
    sort: [1686160230973]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'Jrf8logByU1gUli0Yy2e',
    _score: null,
    _source: {
      '@timestamp': moment()
        .subtract(5, 'minutes')
        .utc()
        .format(),
      lastPollCycleTimestamp: '2023-06-07T17:50:30.973Z',
      type: 'snmp',
      denomi: 66444142076.00001,
      timeDiff: 66.444142076,
      lastPollCycleIfInOctets: 0,
      '@version': '1',
      host: {
        ip: '197.220.1.225'
      },
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      hostname: null
    },
    sort: [1686160297417]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: '_bcBl4gBevPYC7qYARYx',
    _score: null,
    _source: {
      '@timestamp': moment()
        .utc()
        .format(),
      lastPollCycleTimestamp: '2023-06-07T17:51:37.417Z',
      type: 'snmp',
      denomi: 301868663894,
      timeDiff: 301.868663894,
      lastPollCycleIfInOctets: 0,
      '@version': '1',
      host: {
        ip: '197.220.1.225'
      },
      interfaces: {
        ifDescr: 'uBondrGuEqF',
        ifName: 'uBondrGuEqF',
        ip: '',
        index: '78',
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifAlias: 'uBondrGuEqF',
        ifHCInOctets: 0,
        status: ' ',
        numerator: 0,
        inBoundUtiliz: 0
      },
      hostname: null
    },
    sort: [1686160599285]
  }
];

export const dummyGraphData60 = [
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(60, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(59, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(58, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(57, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(56, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(55, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(54, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(53, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(52, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(51, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(50, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(49, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(48, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(47, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(46, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(45, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(44, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(43, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(42, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(41, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(40, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(39, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(38, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(37, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(36, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(35, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(34, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(33, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(32, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(31, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(30, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(29, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(28, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(27, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(26, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(25, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(24, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(23, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(22, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(21, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(20, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(19, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(18, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(17, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(16, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(15, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(14, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(13, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(12, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(11, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(10, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(9, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(8, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(7, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(6, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(5, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(4, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(3, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(2, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  },
  {
    _index: 'fabric-snmp-2023.06',
    _id: 'sq3PlogBevPYC7qYfWa8',
    _score: null,
    _source: {
      lastPollCycleTimestamp: '2023-06-07T16:58:32.098Z',
      interfaces: {
        ifHCOutOctets: 0,
        inCoffDiff: 0,
        ifInOctets: 0,
        ifHCInOctets: 0,
        inBoundUtiliz: 0
      },
      host: {
        ip: '197.220.1.225'
      },
      hostname: 'test-lus1-pod1-switchB',
      '@timestamp': moment()
        .subtract(1, 'minutes')
        .utc()
        .format(),
      '@version': '1'
    },
    sort: [1686157354889]
  }
];
