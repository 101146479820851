import {gql} from 'apollo-boost';

export const CREATE_ORG_BILLING_INVOICE = gql`
    mutation BillingUtilizationItems($data: String ) {
        addOrgBillingInvoice(data: $data) {
            data
        }
    }
`;
export const UPDATE_ORG_BILLING_INVOICE_STATUS = gql`
    mutation BillingUtilizationItems($id: String, $status: String ) {
        updateOrgBillingInvoice(status: $status, id: $id) {
            data
        }
    }
`;