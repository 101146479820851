import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Image from '../../../../assets/icons/image.png';
import Close from '@material-ui/icons/Close';
import commonStyles from '../../../../theme/commonStyles';
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateImagesModal({
                               t,
                               open,
                               handleCreateImage,
                               handleClose,
                               modalState
                           }) {
    const [imageName, setImageName] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [version, setVersion] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imageLogo, setImageLogo] = useState('');
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setImageName('');
            setVersion('');
            setImageLogo('');
            setUploadedFile('');
            setImageURL('');
            handleClose();
        }
    }, [modalState]);
    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        console.log("img :",img)
        const reader = new FileReader();
        reader.readAsDataURL(img);

        reader.onload = function () {
            //console.log(reader.result);
            // setImageLogo(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="images-modal"
            aria-describedby="images-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{isUpdateState === true ? t('edit.label') : t('createNew.label')}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid>
                                <Input
                                    value={imageName}
                                    type={'text'}
                                    placeholder={`${t('imageName.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImageName(evt.target.value)}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <Input
                                    value={imageURL}
                                    type={'text'}
                                    placeholder={`${t('imageUrl.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImageURL(evt.target.value)}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <Input
                                    value={version}
                                    type={'text'}
                                    placeholder={`${t('version.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setVersion(evt.target.value)}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={handleFileUploadClick}
                                >
                                    {`${t('logoImage.label')}`}
                                </Button>
                                {uploadedFile && (
                                    <Chip
                                        avatar={<img src={Image} alt={'file'}/>}
                                        label={
                                            uploadedFile.name.length > 12
                                                ? `${uploadedFile.name.slice(0, 12)}...`
                                                : uploadedFile.name
                                        }
                                        onDelete={() => {
                                            setUploadedFile('');
                                            setImageLogo('');
                                        }}
                                        deleteIcon={<Close/>}
                                        variant="outlined"
                                    />
                                )}
                                <input
                                    type="file"
                                    id="attachment"
                                    ref={inputFile}
                                    onChange={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        setUploadedFile(event.target.files[0]);
                                        convertToBase64(event.target.files[0]);
                                    }}
                                    style={{display: 'none'}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateImage({
                                image_name: imageName,
                                image_version: version,
                                image_logo: imageLogo,
                                image_url: imageURL
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateImagesModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateImagesModal);
