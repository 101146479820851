import React from 'react';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import { colors } from '../../../../theme/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import ExportEngineerReport from '../../Analytics/ExportEngineerReport'


const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  }
}));

export default function EngineerReportTable({ data, column, loading }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {data && data.length ? <ExportEngineerReport csvData={data} fileName='engineer_report' /> : null}
      {loading ? (
        <div
          style={{
            width: '100%',
            padding: 20,
            textAlign: 'center',
            display: 'inline-block'
          }}>
          <LinearProgress color="secondary" />
        </div>
      ) : (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead style={{"background-color": "grey"}}>
                    <TableRow>
                        {column &&
                        column.map(column => (
                            <TableCell style={{'text-align':'center'}} key={uuidv4()}>{column.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                        <TableRow hover tabIndex={-1} key={i}>
                            <TableCell style={{'text-align':'center'}}>
                                {page * rowsPerPage + i+1}
                            </TableCell>
                            <TableCell style={{'text-align':'center'}}>
                                {item.engineer}
                            </TableCell>
                            <TableCell style={{'text-align':'center'}}>
                                {item.openTicket}
                            </TableCell>
                            <TableCell style={{'text-align':'center'}}>
                                {item.closedTicket}
                            </TableCell>
                            <TableCell style={{'text-align':'center'}}>
                                {parseInt(item.openTicket) + parseInt(item.closedTicket)}
                            </TableCell>
                        </TableRow>
                    );
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[7, 15, 25, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
      )}
    </>
  );
}
