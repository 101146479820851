import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@material-ui/core';
import { Markup } from 'interweave';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    compareQuoteBox: {
        width: "100%",
        height: 290,
        overflow: "overlay",
        border: "2px solid lightgray",
        padding: 5
    }
}));
function RefDocModal({
    t,
    open,
    handleClose,
    quotes,
    loading,
    handleOpenCompareModal
}) {

    const classes = useStyles();

    console.log("quotes: ", quotes);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Compare Quotes</DialogTitle>
            <DialogContent>
                {loading ?
                    <div style={{ textAlign: "center", marginTop: 24 }}><CircularProgress size={25} /> </div>
                    :
                    <Grid container spacing={3}>
                        {quotes && quotes.length &&
                            quotes.map((quote) => (
                                <Grid key={quote.id} item xs={4}>
                                    <div className={classes.compareQuoteBox}>
                                        <Markup content={quote.raw_text} /> 
                                    </div>
                                    <div style={{ width: "100%", marginTop: 10 }}>
                                        <Typography variant="h6">Name: {quote.name}</Typography>
                                        <Typography variant="subtitle1">Vendor: {quote.name}</Typography>
                                        <Button style={{ marginTop: 10 }} variant="outlined" color="primary" >Add To Compare</Button>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenCompareModal}
                    >
                        Compare Selected Quotes
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

RefDocModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(RefDocModal);