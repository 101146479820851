import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator, paddingGenerator } from '../../theme/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const actionList = [
  { id: '1', value: 'Active' },
  { id: '2', value: 'InActive' }
];

const initState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  status: '',
  id: ''
};
function AddAdditionalAgent({
  open,
  handleClose,
  handleSubmitAdd,
  buttonLoadingAdd,
  clearModal,
  data,
  t
}) {
  const [formData, setFormData] = React.useState(initState);

  useEffect(() => {
    if (clearModal) {
      setFormData(initState);
    }
  }, [clearModal]);

  const handleFormData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitData = () => {
    handleSubmitAdd(formData);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Additional Agent</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="First Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="firstName"
                value={formData.firstName}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Last Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="lastName"
                value={formData.lastName}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Escalation Group"
                type="email Address"
                variant="outlined"
                name="emailAddress"
                fullWidth={true}
                value={formData.emailAddress}
                onChange={handleFormData}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="status"
                  value={formData.status}
                  onChange={handleFormData}
                  label="Status">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {actionList &&
                    actionList.map((data, index) => (
                      <MenuItem key={index} value={data.value}>
                        {data.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoadingAdd}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoadingAdd ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(AddAdditionalAgent);
