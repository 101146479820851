import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Image from "../../../../assets/icons/image.png";
import {GET_ALL_DEVICE_INTERFACES, GET_ALL_VNF_NETWORK_INTERFACES, GET_SFC_BRIDGE_NAME, GET_TEMPLATE_LIST, GET_UCPE_LIST, GET_UNUSED_DEVICE_INTERFACES} from '../../queries';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import {Close} from "@material-ui/icons";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    input: {
        minWidth: '224px'
    }
}));
const types = [
    {
        id: 'Management',
        name: 'Management'
    }, {
        id: 'PassThrough',
        name: 'PassThrough'
    },
    {
        id: 'Bridge-DPDK',
        name: 'Bridge-DPDK'
    },
    {
        id: 'Bridge-TAP',
        name: 'Bridge-TAP'
    },
    {
        id: 'sr-iov',
        name: 'SRIOV'
    },
    {
        id: 'sfc',
        name: 'SFC'
    }
];
const bridgeTypes = [
    {
        id: 'DPDK',
        name: 'DPDK'
    },
    {
        id: 'TAP',
        name: 'TAP'
    }
];
const nic = [
    {
        id: 'virtio',
        name: 'virtio'
    },
    {
        id: 'e1000',
        name: 'e1000'
    },
    {
        id: 'rtl8139',
        name: 'rtl8139'
    }
];
function CreateVNFModal({t, data, open, handleCreateVnf, handleClose, modalState}) {
    let templates = [];
    let ucpes = [];
    const [deviceId, setDeviceId] = useState("");
    const organization_id = data.organizationId
    const {
        loading: loadingTemplates,
        data: templatesData,
        error: templatesError,
        refetch: reloadVnfTemplate
    } = useQuery(GET_TEMPLATE_LIST, {
        variables: {
            organization_id,
            device_id: deviceId
        }
    });
    useEffect(() => {
        reloadVnfTemplate();
    }, [deviceId])
    const {loading: loadingUcpes, data: ucpeData, error: ucpeError} = useQuery(GET_UCPE_LIST, {
        variables: {
            organization_id,
            search: ''
        }
    });
    const interfaceInitField = {
        vnfName: '',
        vnfTemplate: '',
        uCPE: '',
        vnfAutoRestart: true,
        sshIP: '',
        sshPort: '',
        webUIIP: '',
        webUIPort: '',
        webUIUrl: '',
        webUIType: '',
        device_interface_list: [],
        interface_network: [],
        lic_data: [],
        conf_data: []
    };
    const [interfaceState, setInterfaceState] = useState([
        {...interfaceInitField}
    ]);
    const [
        removeSelectedInterfaceField,
        setSelectedRemoveInterfaceField
    ] = useState([]);
    const [removeFieldState, setRemoveFieldState] = useState({interface: []});
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const [vnfInterfaceList, setVnfInterfaces] = useState({0: []});
    const [lastIndex, setLastIndex] = useState('');
    const [selectedDevice, setSelectedDevice] = useState({vnfTemplateL: '', deviceId: ''});
    const [uploadedFile, setUploadedFile] = useState({});
    const [vnfCloudInit, setVnfCloudInit] = useState({});
    const [unusedInterface, setUnusedInterface] = useState([]);
    const [bridgeNameList, setSFCBridgeNameList] = useState([]);
    const [imageLogo, setImageLogo] = useState('');
    const [vnfCloudInitType, setVnfCloudInitType] = useState('');
    React.useEffect(() => {
        if (modalState.clearData && open) {
            //    setInterfaceState([interfaceInitField]);
            handleClose();
        }
    }, [modalState]);
    const addNewInterfaceField = () => {
        // vnfInterfaceList.map((item, idx3) => {
        //     interfaceInitField.interface_network.push({name: '', type: '', networkName: '', filteredArray: []})
        // });
        setInterfaceState(oldArray => [...oldArray, {...interfaceInitField}]);
    };
    /*VNF Interfaces*/
    const [getVnfInterfaceList] = useLazyQuery(GET_ALL_VNF_NETWORK_INTERFACES, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getVnfNetworkInterfaces && data.getVnfNetworkInterfaces.vnf_interface && data.getVnfNetworkInterfaces.vnf_interface.length) {
                let arr = [];
                data.getVnfNetworkInterfaces.vnf_interface.map((item, idx3) => {
                    arr.push({name: item.vnf_ni_name, type: '', networkName: '', nic: '', filteredArray: []});
                    setUploadedFile({...uploadedFile, [lastIndex]: {License: null,}})
                });
                const updatedInterface = [...interfaceState];
                updatedInterface[lastIndex]['interface_network'] = arr;
                setInterfaceState(updatedInterface);
            }
            setVnfInterfaces(prevState => ({
                ...prevState,
                [lastIndex]: data.getVnfNetworkInterfaces ? data.getVnfNetworkInterfaces.vnf_interface : []
            }));
            setVnfCloudInit(prevState => {
                let updatedState = {};
                if (prevState && prevState[lastIndex]) {
                    updatedState = {...prevState, [lastIndex]: data.getVnfNetworkInterfaces ? data.getVnfNetworkInterfaces.vnfT_cloudInit : []};
                } else {
                    updatedState = {[lastIndex]: data.getVnfNetworkInterfaces ? data.getVnfNetworkInterfaces.vnfT_cloudInit : []};
                }
                return updatedState;
            });
        }
    });
    /*GEt Device Interface*/
    const [getDeviceInterfaceList] = useLazyQuery(GET_ALL_DEVICE_INTERFACES, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            const updatedInterface = [...interfaceState];
            updatedInterface[lastIndex]['device_interface_list'] = (data.getDeviceInterfaceList) ? data.getDeviceInterfaceList : [];
            setInterfaceState(updatedInterface);
        }
    });
    /*GEt UNUSED Device Interface*/
    const [getUnusedDeviceInterfaceList] = useLazyQuery(GET_UNUSED_DEVICE_INTERFACES, {
        fetchPolicy: "no-cache",
        onCompleted: response => {
            if (response && response.getDeviceUnusedInterfaceList && response.getDeviceUnusedInterfaceList.length) {
                // const updatedInterface = [...interfaceState];
                let unusedData = response.getDeviceUnusedInterfaceList.map(item => {
                    return {id: item.interface_name, name: item.interface_name}
                })
                //  updatedInterface[idx]['interface_network'][idx2]['filteredArray'] = unusedData
                setUnusedInterface(unusedData)
            }
        }
    });
    /*GEt SFC Bridge Name*/
    const [GetSfcBridgeNameList] = useLazyQuery(GET_SFC_BRIDGE_NAME, {
        fetchPolicy: "no-cache",
        onCompleted: response => {
            if (response && response.getSFCBridgeNameList && response.getSFCBridgeNameList.length) {
                let SfcBridgeNameList = response.getSFCBridgeNameList.map(item => {
                    return {id: item.bridge_name, name: item.bridge_name}
                })
                setSFCBridgeNameList(SfcBridgeNameList)
            }
        }
    });
    const removeInterfaceField = () => {
        const updatedInterface = [...interfaceState];
        const updateRemoveState = {...removeFieldState};
        for (let index of updateRemoveState.interface) {
            index = parseInt(index);
            updatedInterface.splice(index, 1);
        }
        setInterfaceState(updatedInterface);
        const newRmState = {...removeFieldState};
        newRmState.interface = [];
        setRemoveFieldState(newRmState);
    };
    const onHandleSelectInterfaceRow = (evnt, index) => {
        let val = evnt.target.value.toString();
        let updatedState = {...removeSelectedInterfaceField};
        updatedState[index] = evnt.target.checked;
        setSelectedRemoveInterfaceField(updatedState);
        if (evnt.target.checked) {
            setRemoveFieldState(prevState => ({
                ...prevState,
                interface: [...prevState.interface, val]
            }));
        } else {
            let prevRemoveState = {...removeFieldState};
            let elemIdx = prevRemoveState.interface.indexOf(val);
            prevRemoveState.interface.splice(elemIdx, 1);
            setRemoveFieldState(prevRemoveState);
        }
    };
    React.useEffect(() => {
        let filterBy = {
            variables: {
                vnfTmpltName: selectedDevice.vnfTemplate,
                deviceID: selectedDevice.deviceId,
            }
        };
        if (selectedDevice) getVnfInterfaceList(filterBy);
    }, [selectedDevice.vnfTemplate, selectedDevice.deviceId, getVnfInterfaceList]);
    const handleInterfaceChange = (value, idx, column) => {
        setLastIndex(idx);
        if (column === 'vnfTemplate' && interfaceState[idx]['uCPE']) {
            let filterBy = {
                variables: {
                    vnfTmpltName: value,
                    deviceID: interfaceState[idx]['uCPE'],
                }
            };
            getVnfInterfaceList(filterBy);
        }
        if (column === 'uCPE') {
            setDeviceId(value)
            GetSfcBridgeNameList({
                variables: {
                    deviceID: value,
                }
            })
            getUnusedDeviceInterfaceList({
                variables: {
                    deviceID: value,
                }
            })
            getDeviceInterfaceList({
                variables: {
                    deviceID: value,
                }
            })
        }
        const updatedInterface = [...interfaceState];
        updatedInterface[idx][column] = value;
        setInterfaceState(updatedInterface);
    };
    const handleNetworkInterfaceChange = (value, idx, idx2, column) => {
        const updatedInterface = [...interfaceState];
        if (column === 'type') {
            const filterArr = interfaceState[idx].device_interface_list.filter(item => {
                if (value === item.ni_type) {
                    return item;
                }
            });
            if (value !== 'sfc') {
                updatedInterface[idx]['interface_network'][idx2]['filteredArray'] = value === 'PassThrough' && unusedInterface.length ? unusedInterface : filterArr.map(item => {
                    let o = Object.assign({}, item);
                    if (value === 'Bridge-DPDK' || value === 'Bridge-TAP') {
                        o.name = (o.bridge_interface && o.bridge_interface.length) ? o.bridge_interface[0].bridge_name : '-';
                    } else {
                        o.name = o.ni_name;
                    }
                    return o
                });
            }
            if (value === 'sfc') {
                updatedInterface[idx]['interface_network'][idx2]['filteredArray'] = value === 'sfc' && bridgeNameList.length && bridgeNameList;
            }
        }
        updatedInterface[idx]['interface_network'][idx2][column] = value;
        setInterfaceState(updatedInterface);
    };
    if (!loadingTemplates && !templatesError) {
        templates =
            templatesData.getVnfTemplates && templatesData.getVnfTemplates.length
                ? templatesData.getVnfTemplates.filter(
                template => template.vnfT_status === 'success'
                )
                : [];
    }
    if (!loadingUcpes && !ucpeError) {
        ucpes =
            ucpeData.getUcpes && ucpeData.getUcpes.length
                ? ucpeData.getUcpes.filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
                : [];
    }
    // Upload Function
    const convertToBase64 = (img, idx, idx2, path) => {
        let updatedInterface = [...interfaceState];
        if (!img) {
            if (path === "License") {
                if (updatedInterface[idx]['lic_data'].length && updatedInterface[idx]['lic_data'][idx2]) {
                    updatedInterface[idx]['lic_data'].splice(idx2, 1);
                }
            } else {
                if (updatedInterface[idx]['conf_data'].length && updatedInterface[idx]['conf_data'][idx2]) {
                    updatedInterface[idx]['conf_data'].splice(idx2, 1);
                }
            }
            setInterfaceState(updatedInterface);
            return null;
        }
        setVnfCloudInitType(path === "License" ? 'license_file' : 'config_file');
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            if (path === "License") {
                updatedInterface[idx]['lic_data'].push({license_file: reader.result});
            } else {
                updatedInterface[idx]['conf_data'].push({config_file: reader.result});
            }
            setInterfaceState(updatedInterface);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="vnf-modal"
            aria-describedby="vnf-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    style={{...marginGenerator('mb-8')}}>
                    {t('vnfs.label')}
                </Typography>
                <Grid container spacing={3}>
                    {/*<Grid item xs={12}>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => {
                                addNewInterfaceField();
                            }}>
                            {t('add.label')} {t('vnfs.label')}
                        </Button>
                        <Button
                            color="secondary"
                            variant={'contained'}
                            style={{...marginGenerator('ml-20')}}
                            disabled={!removeFieldState.interface.length}
                            onClick={() => {
                                removeInterfaceField();
                                setSelectedRemoveInterfaceField([]);
                            }}>
                            {t('remove.label')}
                        </Button>
                    </Grid>*/}
                    {interfaceState.map((val, idx) => {
                        return (
                            <React.Fragment key={`vnf-${idx}`}>
                                <Grid
                                    key={`heading-${idx}`}
                                    item
                                    xs={12}
                                    style={{
                                        ...paddingGenerator('pl-5'),
                                        ...paddingGenerator('pl-0'),
                                        ...marginGenerator('mt-24')
                                    }}>
                                    <Typography variant="subtitle2" color="textPrimary">
                                        <Checkbox
                                            style={paddingGenerator('pl-0')}
                                            checked={removeSelectedInterfaceField[idx]}
                                            value={idx}
                                            onChange={event => {
                                                onHandleSelectInterfaceRow(event, idx);
                                            }}
                                        />
                                        {t('vnf.label')} {idx + 1}
                                    </Typography>
                                </Grid>
                                <Grid key={`vnfName-${idx}`} item xs={6}>
                                    <Input
                                        type={'text'}
                                        placeholder={`${t('vnfName.label')}*`}
                                        style={{width: '100%'}}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={interfaceState[idx].vnfName}
                                        onChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'vnfName')
                                        }
                                    />
                                </Grid>
                                <Grid
                                    key={`uCPE-${idx}`}
                                    item
                                    xs={6}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].uCPE}
                                        label={`${t('uCPE.label')} *`}
                                        handleChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'uCPE')
                                        }
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={
                                            ucpes.length > 0
                                                ? ucpes.map(ucpe => {
                                                    return {id: ucpe.device_id, name: ucpe.device_name};
                                                })
                                                : []
                                        }
                                    />
                                </Grid>
                                <Grid
                                    key={`vnfTemplate-${idx}`}
                                    item
                                    style={{...marginGenerator('mt-8'), ...marginGenerator('mb-24')}}
                                    xs={6}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].vnfTemplate}
                                        label={`${t('vnfTemplate.label')} *`}
                                        handleChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'vnfTemplate')
                                        }
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={
                                            templates.length > 0
                                                ? templates.map(template => {
                                                    return {
                                                        id: template.vnf_template_id,
                                                        name: template.vnfT_name
                                                    };
                                                })
                                                : []
                                        }
                                    />
                                </Grid>
                                <Grid
                                    key={`vnfAutoRestart-${idx}`}
                                    item
                                    style={{...marginGenerator('mt-8'), ...marginGenerator('mb-24')}}
                                    xs={6}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].vnfAutoRestart}
                                        label={`${t('vnfAutoRestart.label')} *`}
                                        handleChange={evt =>
                                            handleInterfaceChange(
                                                evt.target.value,
                                                idx,
                                                'vnfAutoRestart'
                                            )
                                        }
                                        selectStyle={{...paddingGenerator('p-8')}}
                                        styleOverrides={{width: '100%', marginRight: 0}}
                                        options={[
                                            {id: true, name: 'Yes'},
                                            {id: false, name: 'No'}
                                        ]}
                                    />
                                </Grid>
                                {/*<Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    xs={12}
                                    style={marginGenerator('mt-24')}>
                                    {t('sshManagementNetwork.label')}
                                </Typography>*/}
                                <Grid
                                    key={`sshIP-${idx}`}
                                    item
                                    xs={6}>
                                    <Input
                                        type={'text'}
                                        label={t('sshManagementNetwork.label')}
                                        placeholder={`${t('sshIP.label')}*`}
                                        style={{width: '100%'}}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={interfaceState[idx].sshIP}
                                        onChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'sshIP')
                                        }
                                    />
                                </Grid>
                                <Grid
                                    key={`sshPort-${idx}`}
                                    item
                                    xs={6}>
                                    <Input
                                        type={'text'}
                                        placeholder={`${t('sshPort.label')}*`}
                                        style={{width: '100%'}}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={interfaceState[idx].sshPort}
                                        onChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'sshPort')
                                        }
                                    />
                                </Grid>
                                {/* <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    xs={12}
                                    style={marginGenerator('mt-24')}>
                                    {t('webUIManagementNetwork.label')}
                                </Typography>*/}
                                {/*<Grid
                                    key={`webUIIP-${idx}`}
                                    item
                                    xs={6}>
                                    <Input
                                        type={'text'}
                                        label={t('webUIManagementNetwork.label')}
                                        placeholder={`${t('managementIP.label')}*`}
                                        style={{width: '100%'}}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={interfaceState[idx].webUIIP}
                                        onChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'webUIIP')
                                        }
                                    />
                                </Grid>
                                <Grid
                                    key={`webUIPort-${idx}`}
                                    item
                                    xs={6}>
                                    <Input
                                        type={'text'}
                                        placeholder={`${t('managementPort.label')}*`}
                                        style={{width: '100%'}}
                                        className={classes.input}
                                        color={'secondary'}
                                        value={interfaceState[idx].webUIPort}
                                        onChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'webUIPort')
                                        }
                                    />
                                </Grid>*/}
                                <Grid container spacing={3}>
                                    <Grid
                                        key={`webUIType-${idx}`}
                                        item
                                        style={{...marginGenerator('mt-8'), ...marginGenerator('mb-24')}}
                                        xs={3}>
                                        <OutlinedSelect
                                            val={interfaceState[idx].webUIType}
                                            label={`${t('type.label')} *`}
                                            handleChange={evt =>
                                                handleInterfaceChange(
                                                    evt.target.value,
                                                    idx,
                                                    'webUIType'
                                                )
                                            }
                                            styleOverrides={{width: '100%', marginRight: 0}}
                                            options={[
                                                {id: 'http://', name: 'http://'},
                                                {id: 'https://', name: 'https://'}
                                            ]}
                                        />
                                    </Grid>
                                    <Grid
                                        key={`webUIUrl-${idx}`}
                                        item
                                        xs={9}>
                                        <Input
                                            type={'text'}
                                            placeholder={`${t('url.label')}*`}
                                            style={{width: '100%'}}
                                            className={classes.input}
                                            color={'secondary'}
                                            value={interfaceState[idx].webUIUrl}
                                            onChange={evt =>
                                                handleInterfaceChange(evt.target.value, idx, 'webUIUrl')
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    xs={12}
                                    style={marginGenerator('mt-24')}
                                    align={'center'}>
                                    {t('interfaces.label')}
                                </Typography>
                                {vnfInterfaceList[idx] && vnfInterfaceList[idx].map((item, idx2) => {
                                    return (
                                        <Grid container spacing={3}>
                                            <Grid key={`type-${idx}-${idx2}`} item xs={3}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                    xs={12}
                                                    style={marginGenerator('mt-24')}
                                                    align={'center'}>
                                                    {item.vnf_ni_name}
                                                </Typography>
                                            </Grid>
                                            <Grid key={`type-${idx}-${idx2}`}
                                                  style={{...marginGenerator('mb-24')}}
                                                  item xs={3}>
                                                <OutlinedSelect
                                                    val={interfaceState[idx].interface_network[idx2].type}
                                                    label={`${t('type.label')} *`}
                                                    handleChange={evt =>
                                                        handleNetworkInterfaceChange(evt.target.value, idx, idx2, 'type')
                                                    }
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={types}
                                                />
                                            </Grid>
                                            {interfaceState[idx].interface_network[idx2].type ? (
                                                <Grid key={`unassignedInterface-${idx}-${idx2}`}
                                                      style={{...marginGenerator('mb-24')}}
                                                      item xs={3}>
                                                    <OutlinedSelect
                                                        val={interfaceState[idx].interface_network[idx2].networkName}
                                                        label={`${t('unassignedInterface.label')} *`}
                                                        handleChange={evt =>
                                                            handleNetworkInterfaceChange(
                                                                evt.target.value,
                                                                idx,
                                                                idx2,
                                                                'networkName'
                                                            )
                                                        }
                                                        selectStyle={{...paddingGenerator('p-8')}}
                                                        styleOverrides={{width: '100%', marginRight: 0}}
                                                        options={
                                                            interfaceState[idx].interface_network[idx2].filteredArray.length > 0
                                                                ? interfaceState[idx].interface_network[idx2].filteredArray.map(interface_network => {
                                                                    return {
                                                                        id: interface_network.name,
                                                                        name: interface_network.name
                                                                    };
                                                                })
                                                                : []
                                                        }
                                                    />
                                                </Grid>
                                            ) : (
                                                ''
                                            )}
                                            {interfaceState[idx].interface_network[idx2].type === 'Bridge-TAP' ? (
                                                <Grid
                                                    key={`typeOfNic-${idx}`}
                                                    item xs={3}
                                                    style={{...marginGenerator('mb-24')}}>
                                                    <OutlinedSelect
                                                        val={interfaceState[idx].nic}
                                                        label={`${t('nic.label')} *`}
                                                        handleChange={evt =>
                                                            handleNetworkInterfaceChange(
                                                                evt.target.value,
                                                                idx,
                                                                idx2,
                                                                'nic'
                                                            )
                                                        }
                                                        selectStyle={{...paddingGenerator('p-8')}}
                                                        styleOverrides={{width: '100%', marginRight: 0}}
                                                        options={nic ? nic : []}
                                                    />
                                                </Grid>
                                            ) : (
                                                ''
                                            )}
                                            {/*    {interfaceState[idx].type === 'Bridge' ? (
                                            <React.Fragment>
                                                <Grid key={`bridgeType-${idx}`} item xs={3}>
                                                    <OutlinedSelect
                                                        val={interfaceState[idx].bridgeType}
                                                        label={`${t('bridgeType.label')} *`}
                                                        handleChange={evt =>
                                                            handleInterfaceChange(
                                                                evt.target.value,
                                                                idx,
                                                                'bridgeType'
                                                            )
                                                        }
                                                        selectStyle={{...paddingGenerator('p-8')}}
                                                        styleOverrides={{width: '100%', marginRight: 0}}
                                                        options={bridgeTypes}
                                                    />
                                                </Grid>
                                                <Grid key={`bridgeName-${idx}`} item xs={3}>
                                                    <OutlinedSelect
                                                        val={interfaceState[idx].bridgeName}
                                                        label={`${t('bridgeName.label')} *`}
                                                        handleChange={evt =>
                                                            handleInterfaceChange(
                                                                evt.target.value,
                                                                idx,
                                                                'bridgeName'
                                                            )
                                                        }
                                                        selectStyle={{...paddingGenerator('p-8')}}
                                                        styleOverrides={{width: '100%', marginRight: 0}}
                                                        options={[]}
                                                    />
                                                </Grid>
                                                {interfaceState[idx].bridgeType === 'TAP' ? (
                                                    <Grid key={`virtIo-${idx}`} item xs={3}>
                                                        <OutlinedSelect
                                                            val={interfaceState[idx].virtIo}
                                                            label={`${t('virtIo.label')} *`}
                                                            handleChange={evt =>
                                                                handleInterfaceChange(
                                                                    evt.target.value,
                                                                    idx,
                                                                    'virtIo'
                                                                )
                                                            }
                                                            selectStyle={{...paddingGenerator('p-8')}}
                                                            styleOverrides={{width: '100%', marginRight: 0}}
                                                            options={[
                                                                {id: 'VIRTIO', name: 'VIRTIO'},
                                                                {id: 'E1000', name: 'E1000'}
                                                            ]}
                                                        />
                                                    </Grid>
                                                ) : (
                                                    ''
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                        {interfaceState[idx].type === 'SRIOV' ? (
                                            <Grid key={`sriovInterface-${idx}`} item xs={3}>
                                                <OutlinedSelect
                                                    val={interfaceState[idx].sriovInterface}
                                                    label={`${t('sriovInterface.label')} *`}
                                                    handleChange={evt =>
                                                        handleInterfaceChange(
                                                            evt.target.value,
                                                            idx,
                                                            'sriovInterface'
                                                        )
                                                    }
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={[]}
                                                />
                                            </Grid>
                                        ) : (
                                            ''
                                        )}*/}
                                        </Grid>
                                    )
                                })}
                                {vnfCloudInit[idx] &&
                                <>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        xs={12}
                                        style={marginGenerator('mt-44')}
                                        align={'center'}>
                                        {t('vnfT_cloudInit.label')}
                                    </Typography>
                                    <Grid container spacing={3}>
                                        {vnfCloudInit[idx] && vnfCloudInit[idx].map((item, idx2) => {
                                            return (item.path === 'License') ?
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textPrimary"
                                                        xs={12}
                                                        style={marginGenerator('mt-24')}>
                                                        {t('License.label')}
                                                    </Typography>
                                                    {/*  <Button
                                                        variant={'outlined'}
                                                        color={'primary'}
                                                        onClick={() => {
                                                            handleFileUploadClick(item.path)
                                                        }}
                                                    >
                                                        {`${t('License.label')}`}
                                                    </Button>*/}
                                                    {uploadedFile[idx] && uploadedFile[idx][item.path] && (
                                                        <Chip
                                                            avatar={<img src={Image} alt={'file'}/>}
                                                            label={
                                                                uploadedFile[idx][item.path].name > 12
                                                                    ? `${uploadedFile[idx][item.path].name.slice(0, 12)}...`
                                                                    : uploadedFile[idx][item.path].name
                                                            }
                                                            onDelete={() => {
                                                                setUploadedFile({...uploadedFile, [idx]: {[item.path]: ''}});
                                                                setImageLogo('');
                                                            }}
                                                            deleteIcon={<Close/>}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    <Input
                                                        type="file"
                                                        id="attachment"
                                                        onChange={event => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            convertToBase64(event.target.files[0], idx, idx2, item.path);
                                                        }}
                                                    />
                                                </Grid> :
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textPrimary"
                                                        xs={12}
                                                        style={marginGenerator('mt-24')}>
                                                        {t('cloudInit.label')}
                                                    </Typography>
                                                    {/*<Button
                                                        variant={'outlined'}
                                                        color={'primary'}
                                                        onClick={() => {
                                                            handleFileUploadClick(item.path)
                                                        }}
                                                    >
                                                        {`${t('cloudInit.label')}`}
                                                    </Button>*/}
                                                    {uploadedFile[idx] && uploadedFile[idx][item.path] && (
                                                        <Chip
                                                            avatar={<img src={Image} alt={'file'}/>}
                                                            label={
                                                                uploadedFile[idx][item.path].name > 12
                                                                    ? `${uploadedFile[idx][item.path].name.slice(0, 12)}...`
                                                                    : uploadedFile[idx][item.path].name
                                                            }
                                                            onDelete={() => {
                                                                setUploadedFile({...uploadedFile, [idx]: {[item.path]: ''}});
                                                                setImageLogo('');
                                                            }}
                                                            deleteIcon={<Close/>}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    <Input
                                                        type="file"
                                                        id="attachment"
                                                        onChange={event => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            convertToBase64(event.target.files[0], idx, idx2, item.path);
                                                        }}
                                                    />
                                                    {/* <input
                                                        type="file"
                                                        id="attachment"
                                                        onChange={event => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            convertToBase64(event.target.files[0], idx, idx2, item.path);
                                                        }}
                                                    /> */}
                                                </Grid>
                                        })}
                                    </Grid>
                                </>}
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            console.log('interfaceState', interfaceState);
                            /*if (!validate(interfaceState)) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }*/
                            handleCreateVnf(interfaceState);
                        }}
                        loading={modalState.btnLoading}/>
                    {/*  <Button
                        onClick={() => {
                            handleCreateVnf(interfaceState);
                            handleClose();
                        }}
                        color="secondary"
                        variant={'contained'}>
                        {t('submit.label')}
                    </Button>*/}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateVNFModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateVNFModal);
