import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { marginGenerator } from '../../../../../../theme/utils';
import MorupleLogo from '../../../../../../assets/icons/moruple.png';

const useStyles = makeStyles(theme => ({
    left: {
        float: 'left',
    },
    right:{
        float: 'right',
    }
}));

function HrHeader({ t }) {

    const classes = useStyles();

    return (
        <Paper>
            <Grid container style={{ padding: '0 32px' }}>
                <Grid item xs={12} >
                    <div className={classes.main}>
                        <div className={classes.left}>
                            <img style={{width: '200px', height: 'auto'}} src={MorupleLogo} alt="Morupule" />
                        </div>
                        <div className={classes.right}>
                            <p style={{color: 'black', fontSize:'15px'}}>ISO 14001 certified <br/> OSHAS 18001 certified</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default withTranslation()(HrHeader);