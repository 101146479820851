import { Grid } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

const DemosVideoPlayer = ({ t, videoUrl }) => {
    return (
        <>
            <Grid container>
                <Grid item container xs={12} justify='center' alignItems='center' >
                    <ReactPlayer url={videoUrl} controls={true} playing={true} />
                </Grid>
            </Grid>
        </>
    )
}

export default withTranslation()(React.memo(DemosVideoPlayer));
