// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_ACTIVATION_TEAM_BOTSWANA_LIST } from "../../../../queries";
import { USER_ROLES } from "../../../../../../../utils/constants";
import Header from "../../../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CamtelItem from "../CamtelItem";
import { GET_USER_INFO } from '../../../../../../Profile/queries';
import { ADD_CAMTEL_SECTION, UPLOAD_FILES } from "../../../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../../../commonQueries';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CamtelLogo from "../../../../../../../assets/icons/camtelLogo.jpg";
import { GET_COUNTRY_LIST } from '../../../../../../commonQueries';
import Webcam from "react-webcam";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const Camtel = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const countryId = localStorage.getItem('countryId');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [DeveloperTeam, setDeveloperTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [preValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // let videoRef = useRef(null);
    // let photoRef = useRef(null)
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        // console.log("new image : ", imageSrc)
    }, [webcamRef, setImgSrc]);

    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
        // }
        getAllUserList({
            variables: {
                role: USER_ROLES.PMO_ADMIN
            }
        })
        // resfresh listing when create new listing created
        // if (refreshTab === true) {
        //     setLoading(true);
        //     // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "project_owner=is_admin&page=1"
        //         }
        //     })
        //     // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
        //     //     getAllList({
        //     //         variables: {
        //     //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=1"
        //     //         }
        //     //     })
        //     // }
        // }
    }, [refreshTab]);
    const { data: countryList } = useQuery(GET_COUNTRY_LIST);

    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get installation users list in pm zambia team', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            let filterByCountryId = [];
            filterByCountryId = parseData.filter((x) => {
                if (x.attributes && x.attributes.countryId && (x.attributes.countryId.length != 0)) {
                    return x.attributes.countryId[0] == countryId;
                }
            })
            setAllUserList(filterByCountryId);
            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in chnage control team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            // if (role.includes(USER_ROLES.CHANGE_CONTROL_TEAM) && !checkRoleResult) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&change_control_team=" + full_name
            //         }
            //     })
            // }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "project_owner=is_admin&page=" + value + checkSearchFilterData
            }
        })
        // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=" + value
        //         }
        //     })
        // }
    };
    const [getAllList] = useLazyQuery(GET_ACTIVATION_TEAM_BOTSWANA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getActivationTeamBotswanaList) {
                let parseResult = JSON.parse(data.getActivationTeamBotswanaList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get pm zambia list in install team:", error)
            setList([]);
            setLoading(false);
            setError(false);
        }
    });
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('clientName.label'),
            key: "clientName"
        },
        {
            label: t('location.label'),
            key: "location"
        },
        {
            label: t("serviceId.label"),
            key: "serviceId"
        },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        // console.log("data change : ", data);
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : ((data.project_info && data.project_info.case_number) ? data.project_info.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: ((data.project_info && data.project_info.customer_name ? data.project_info.customer_name : 'N/A') + ' / ' + (data.project_info && data.project_info.organization_name ? data.project_info.organization_name : 'N/A')) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('clientName.label'), value: data.client_name, button: false },
            { label: t('location.label'), value: data.location, button: false },
            { label: t('assignedIp.label'), value: data.assigned_Ip, button: false },
            { label: t('vlan.label'), value: data.vlan, button: false },
            { label: t('serviceId.label'), value: data.service_Id, button: false },
            { label: t('allocatedBandwidth.label'), value: data.allocated_bandwidth, button: false },
            { label: t('baseStation.label'), value: data.base_station, button: false },
            { label: t('domainName.label'), value: data.domain_name, button: false },
            { label: t('contactPersonDetails.label'), value: data.contact_person_details, button: false },
            { label: t('emailPackage.label'), value: data.email_package, button: false },
            { label: t('VMSpecs.label'), value: data.vm_specs, button: false },
            { label: t('backupCapacity.label'), value: data.backup_capacity, button: false },
            { label: t('numberOfMachines.label'), value: data.number_of_machines, button: false },
            { label: t('VMIP.label'), value: data.vm_ip, button: false },
        ]
        setPreValuesOfForm(data);
        setCompleteDetails({ ...completeDetails, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
        setDeveloperTeam(true);
    }
    const [addDeveloperTeam] = useMutation(ADD_CAMTEL_SECTION, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error add billing form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addCamtelFormSection : ", data);
            setModalState({ ...modalState, btnLoading: false });
            if (data && data.addCamtelFormSection) {
                if (JSON.parse(data.addCamtelFormSection.status) === true) {
                    Message.success('New camtel user created successfully');
                    setModalState({ btnLoading: false, clearData: true });
                    // setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addCamtelFormSection.teamData);
                    setModalState({ btnLoading: false, clearData: true });
                    setImgSrc(null);
                    // setDeveloperTeam(false);
                    // handleIntegrationEngineerTeam();
                } else {
                    let message = JSON.parse(data.addCamtelFormSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSubmitClick = (formData) => {
        setModalState({ ...modalState, btnLoading: true });
        handleRefreshTab(false);
        if(imgSrc == null){
            Message.error("Please capture profile photo.");
        } else {
            addDeveloperTeam({
                variables: {
                    nin: formData.nin,
                    surname: formData.surname,
                    middle_name: formData.middle_name,
                    first_name: formData.first_name,
                    mothers_maider_name: formData.mothers_maider_name,
                    means_of_identification: formData.means_of_identification,
                    phone_number: formData.phone_number,
                    alternate_phone_number: formData.alternate_phone_number,
                    email_address: formData.email_address,
                    nationality: formData.nationality,
                    date_of_birth: formData.date_of_birth,
                    address: formData.address,
                    profession: formData.profession,
                    height: formData.height,
                    gender: formData.gender,
                    profile_photo: imgSrc,
                    // profile_photo: formData.profile_photo,imgSrc
                    signature: formData.signature,
                }
            })
        }
    }
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({ ...modalState, btnLoading: true });
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error upload files in change control team', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ ...modalState, btnLoading: false });
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleViewCompleteDetailClick = () => {
        setCompleteDetails({ ...completeDetails, open: true });
    }
    const handleClickViewAllDetailsAndAttachment = (data) => {
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : ((data.project_info && data.project_info.case_number) ? data.project_info.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: ((data.project_info && data.project_info.customer_name ? data.project_info.customer_name : 'N/A') + ' / ' + (data.project_info && data.project_info.organization_name ? data.project_info.organization_name : 'N/A')) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('clientName.label'), value: data.client_name, button: false },
            { label: t('location.label'), value: data.location, button: false },
            { label: t('assignedIp.label'), value: data.assigned_Ip, button: false },
            { label: t('vlan.label'), value: data.vlan, button: false },
            { label: t('serviceId.label'), value: data.service_Id, button: false },
            { label: t('allocatedBandwidth.label'), value: data.allocated_bandwidth, button: false },
            { label: t('baseStation.label'), value: data.base_station, button: false },
            { label: t('domainName.label'), value: data.domain_name, button: false },
            { label: t('contactPersonDetails.label'), value: data.contact_person_details, button: false },
            { label: t('emailPackage.label'), value: data.email_package, button: false },
            { label: t('VMSpecs.label'), value: data.vm_specs, button: false },
            { label: t('backupCapacity.label'), value: data.backup_capacity, button: false },
            { label: t('numberOfMachines.label'), value: data.number_of_machines, button: false },
            { label: t('VMIP.label'), value: data.vm_ip, button: false },
        ]
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
    };
    return (
        <>
            <Header style={{paddingBottom:'2px!important'}} title={t("camtel.label")} />
            <Header title={'Data Capturing System'} />
            <Grid container style={{overflowX:'hidden'}}>
                <Grid item xs={12}>
                    <Grid style={{ textAlign: 'Center' }}>
                        <img
                            width="150px"
                            height="120px"
                            src={CamtelLogo}
                            alt="SageX3"
                        />
                    </Grid>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Profile Photo(Please take a picture)*</Typography>
                    <Grid style={{ textAlign: 'Center' }}>
                        <div>
                        <Webcam
                            style={{width:'220px',marginRight:'10px'}}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                        />
                            {imgSrc && (
                                <img
                                    src={imgSrc}
                                />
                            )}
                            <br />
                         <Button
                            style={{marginLeft:'10px'}}
                                color="primary"
                                variant={'contained'}
                                onClick={capture}>Take Picture
                            </Button>
                        </div>
                        <br />
                    </Grid>
                </Grid>
                <CamtelItem
                    t={t}
                    modalState={modalState}
                    countryList={countryList && countryList.getCountries}
                    handleSubmitClick={handleSubmitClick}
                    handleUploadFiles={handleUploadFiles}
                    CurrentUserName={CurrentUserName} />
            </Grid>
        </>
    );
};
export default withTranslation()(Camtel);
