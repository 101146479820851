import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';

const INITIAL_STATE = {
  sourcePortDetails: {},
  destinationPortDetails: {},
  sourceFcrDetails: {},
  destinationFcrDetails: {},
  connectionDetails: {},
  clientDetails: {},
  sourcePodDetails: {},
  destinationPodDetails: {},
  sourceDataCenterDetails: {},
  destinationDataCenterDetails: {}
};

const AppContext = createContext({
  state: INITIAL_STATE,
  dispatch: () => {}
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOURCE_PORT_ID':
      let sourcePortDetails = state.sourcePortDetails;
      let sourcePortId = action.payload;
      return {
        ...state,
        sourcePortDetails: {
          ...sourcePortDetails,
          sourcePortId: sourcePortId
        }
      };
    case 'SET_DESTINATION_PORT_ID':
      let destinationPortDetails = state.destinationPortDetails;
      let destinationPortId = action.payload;
      return {
        ...state,
        destinationPortDetails: {
          ...destinationPortDetails,
          destinationPortId: destinationPortId
        }
      };
    case 'SET_SOURCE_FCR_ID':
      let sourceFcrDetails = state.sourceFcrDetails;
      let sourceFcrId = action.payload;
      return {
        ...state,
        sourceFcrDetails: {
          ...sourceFcrDetails,
          sourceFcrId: sourceFcrId
        }
      };
    case 'SET_DESTINATION_FCR_ID':
      let destinationFcrDetails = state.destinationFcrDetails;
      let destinationFcrId = action.payload;
      return {
        ...state,
        destinationFcrDetails: {
          ...destinationFcrDetails,
          destinationFcrId: destinationFcrId
        }
      };
    case 'SET_CLIENT_NAME':
      let clientDetails = state.clientDetails;
      let clientName = action.payload;
      return {
        ...state,
        clientDetails: {
          ...clientDetails,
          clientName: clientName
        }
      };
    case 'SET_SOURCE_TYPE':
      let connectionDetails = state.connectionDetails;
      let sourceType = action.payload;
      return {
        ...state,
        connectionDetails: {
          ...connectionDetails,
          sourceType: sourceType
        }
      };
    case 'SET_DESTINATION_TYPE':
      let connectionDetail = state.connectionDetails;
      let destinationType = action.payload;
      return {
        ...state,
        connectionDetails: {
          ...connectionDetail,
          destinationType: destinationType
        }
      };
    case 'SET_DESTINATION_POD_ID':
      let destinationPodDetails = state.destinationPodDetails;
      let destinationPodId = action.payload;
      return {
        ...state,
        destinationPodDetails: {
          ...destinationPodDetails,
          destinationPodId: destinationPodId
        }
      };
    default: {
      return state;
    }
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const contextValue = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export default AppContext;
