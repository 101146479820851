import React from 'react';

import GlassPanel from './GlassPanel';
import PropertyDamege from './PropertyDamege';
import MotorAccidentPanel from './MotorAccidentPanel';
import SesiroKycPanel from './SesiroKycPanel';
import SesiroPropasolPanle from './SesiroPropasolPanle';
import CustomTabs from '../../../../../../components/CustomTabs';

const RiskPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex
}) => {
    let tabList = [];
    tabList.push(
        {
        label: "Glass Panel",
        children: (
            <>
                <GlassPanel
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Property Demage",
        children: (
            <>
                <PropertyDamege
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Motor Accident Panel",
        children: (
            <>
                <MotorAccidentPanel
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Sesiro Kyc Panel",
        children: (
            <>
                <SesiroKycPanel
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    },
    {
        label: "Sesiro Proposal Panle",
        children: (
            <>
                <SesiroPropasolPanle
                    t={t}
                    allowConfigure={allowConfigure}
                    subscriptionUserData={subscriptionUserData}
                    data={data}
                />
            </>
        )
    }
    );
  return (
    <CustomTabs 
    activeIndex={activeIndex} 
    tabs={tabList}
/>
  )
}

export default RiskPanel