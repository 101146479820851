import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { dateFormatterLLL } from '../../../../../../utils';
import SignIcon from '../../../../../../assets/icons/sign-pen.png';

const BriefingPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    allowConfigure,
    users,
    handleAddMeetingNotes
}) => {

    const commonClasses = commonStyles();
    const [attendees, setAttendees] = useState("");

    React.useEffect(() => {
        if(users && users.length){
            const names = data.attendees && data.attendees.map(itm => {
                const existRecord = users && users.find(usr => usr.id === itm);
                return existRecord ? existRecord.name : "";
            });
            setAttendees(names.join(", "));
        }
    }, [users]);

  return (
    <>
    <TableRow key={data._id}>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography
                        variant={'subtitle1'}
                        style={{ cursor: 'pointer' }}
                    >
                        {data.title ? data.title : "-"}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {dateFormatterLLL(data.issueDateTime)}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.section? data.section.name : "-"}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.modemColour ? data.modemColour : "-"}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.modemColour ? data.modemColour : "-"}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell>
            <Grid className={commonClasses.column}>
                <Grid>
                    <Typography variant={'subtitle1'}>
                        {data.modemColour ? data.modemColour : "-"}
                    </Typography>
                </Grid>
            </Grid>
        </TableCell>
        {allowConfigure &&
            <TableCell>
                <IconButton onClick={() => handleMenuItemClick("sign", data)}>
                    <img src={SignIcon} width="26" height={26}/>
                </IconButton>
                <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                    <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                    <DeleteIcon color="secondary" />
                </IconButton>
            </TableCell>
        }
    </TableRow>
</>
  )
}

export default BriefingPanelItem