import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  Tooltip,
  TablePagination
} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useHistory } from 'react-router-dom';
// import { getPodListByNameFilter } from '../../../../services/podService';
import { useAuth } from '../../../../contexts/AuthContext';
import { getServiceProviders } from '../../../../services/serviceProviderService';
import DeleteServiceProviderConfirmPopup from '../ServiceProviderPopups/DeleteServiceProviderConfirmPopup';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    minHeight: '100vh'
  },
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
});

const ServiceProviderTable = () => {
  const classes = useStyles();
  const [serviceProvidersList, setServiceProvidersList] = useState({
    data: []
  });
  const [rowData, setRowData] = useState({});
  const [openDeleteConfirmPopup, setOpenDeleteConfirmPopup] = useState(false);
  const [searchByName, setSearchByName] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const history = useHistory();
  const { loggedInUserRoles } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(+event.target.value);
  };

  const nameFilter = async word => {
    const res = await getServiceProviders(page + 1, limit, word);
    if (res && res.status === 200) setServiceProvidersList(res?.data?.result);
  };

  const GetServiceProvidersList = async () => {
    const res = await getServiceProviders(page + 1, limit);
    if (res?.status === 200) {
      setServiceProvidersList(res?.data?.result);
    }
  };

  useEffect(() => {
    if (searchByName === '') {
      GetServiceProvidersList();
    } else {
      nameFilter(searchByName);
    }
  }, [searchByName, page, limit]);

  return (
    <>
      <Grid container direction="column" xs={12} spacing={2}>
        <Grid item style={{ marginLeft: '10px' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Enter name"
            onChange={e => {
              setSearchByName(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead style={{ backgroundColor: '#5a1f2a' }}>
                <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                  <TableCell
                    align="center"
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}>
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}>
                    Options
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceProvidersList &&
                  serviceProvidersList?.data?.map(serviceProvider => (
                    <TableRow>
                      <TableCell align="center" style={{ width: '100px' }}>
                        {serviceProvider?.name}
                      </TableCell>
                      <TableCell align="center" style={{ width: '180px' }}>
                        <Tooltip
                          title={
                            loggedInUserRoles?.includes('fabric_readonly') ===
                            true
                              ? ''
                              : 'Edit'
                          }
                          placement="bottom"
                          arrow
                          backgroundColor="#5a1f2a">
                          {loggedInUserRoles?.includes('fabric_readonly') ===
                          false ? (
                            <SettingsOutlinedIcon
                              className="icons"
                              onClick={e =>
                                history.push(
                                  `/fabricService/serviceproviders/edit/${serviceProvider?._id}`
                                )
                              }
                              style={{ color: '#5a1f2a' }}
                            />
                          ) : (
                            <SettingsOutlinedIcon style={{ color: 'grey' }} />
                          )}
                        </Tooltip>
                        <Tooltip
                          title={
                            loggedInUserRoles?.includes('fabric_readonly') ===
                            true
                              ? ''
                              : 'Delete'
                          }
                          placement="bottom"
                          arrow
                          backgroundColor="#5a1f2a">
                          {loggedInUserRoles?.includes('fabric_readonly') ===
                          false ? (
                            <DeleteOutlineOutlinedIcon
                              className="icons"
                              style={{ color: '#5a1f2a' }}
                              onClick={() => {
                                setRowData(serviceProvider);
                                setOpenDeleteConfirmPopup(true);
                              }}
                            />
                          ) : (
                            <DeleteOutlineOutlinedIcon
                              style={{ color: 'grey' }}
                            />
                          )}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {serviceProvidersList?.data?.length !== 0 && (
          <Grid item container justify="flex-end">
            <TablePagination
              component="div"
              variant="outlined"
              color="primary"
              sx={{
                '.MuiTablePagination-displayedRows': {
                  marginBottom: 0
                },
                '.MuiTablePagination-selectLabel': {
                  marginBottom: 0
                }
              }}
              count={serviceProvidersList?.total_record}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
      {openDeleteConfirmPopup && (
        <DeleteServiceProviderConfirmPopup
          openDeleteConfirmPopup={openDeleteConfirmPopup}
          setOpenDeleteConfirmPopup={setOpenDeleteConfirmPopup}
          GetServiceProvidersList={GetServiceProvidersList}
          rowData={rowData}
          classes={classes}
        />
      )}
    </>
  );
};

export default ServiceProviderTable;
