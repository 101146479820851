import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {GET_ELASTIC_USER, GET_MONITORING_LIST} from '../../queries';
import ElasticMonitoringPanel from "./ElasticMonitoringPanel";
import Monitoring from "./Monitoring";
import Divider from "@material-ui/core/Divider";
import CreateMonitoringModal from "../CreateMonitoringModal";
import {CREATE_MONITORING} from "../../mutations";
import Message from "../../../../components/Message";
import {USER_ROLES} from "../../../../utils/constants";

function MonitoringPanel({
                             t,
                             data,
                             isReload
                         }) {
    const role = localStorage.getItem('role');
    const opportunityId = data.opportunityId;
    const [createMonitoringTemplateModal, setCreateMonitoringModalState] = useState(false);
    const [monitoringModalState, setMonitoringModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    useEffect(() => {
        if (isReload) {
            reloadMonitorPanel();
        }
    }, [isReload]);
    let columns = [
        {
            label: t('srNo.label'),
            key: 'Sl.No'
        },
        {
            label: t('username.label'),
            key: 'username'
        },
        {
            label: t('password.label'),
            key: 'password'
        }, {
            label: t('action.label'),
            key: 'button'
        }
    ];
    let monitoringColumns = [
        {
            label: t('srNo.label'),
            key: 'Sl.No'
        },
        {
            label: t('type.label'),
            key: 'type'
        }, {
            label: t('action.label'),
            key: 'button'
        }
    ];
    const {
        loading: loading,
        data: elasticUserList,
        error: error,
        refetch: reloadMonitorPanel
    } = useQuery(GET_ELASTIC_USER, {
        variables: {
            opportunityId: opportunityId
        }
    });
    const {
        loading: monitoringLoading,
        data: monitoringList,
        error: monitoringError,
        refetch: reloadMonitorList
    } = useQuery(GET_MONITORING_LIST);
    const handleClick = (item) => {
        const encodedData = window.btoa(item.username + ':' + item.password);
        window.open('https://inqmonitor.inq.inc/proxy/monitor.php?token=' + encodedData, '_blank');
    };
    const handleMonitoringClick = (data) => {
        if (elasticUserList && elasticUserList.getElasticUser && elasticUserList.getElasticUser.length) {
            const encodedData = window.btoa(elasticUserList.getElasticUser[0].username + ':' + elasticUserList.getElasticUser[0].password);
            window.open(data.monitoringUrl + encodedData, '_blank');
        }
    };
    /**
     * @Description Add Monitoring Mutation
     */
    const [createMonitoring] = useMutation(CREATE_MONITORING, {
        onError: error => {
            setMonitoringModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            if (data.addMonitoring.status) {
                setMonitoringModalState({btnLoading: false, clearData: true});
                Message.success('Monitoring created successfully');
                reloadMonitorList();
            }
        }
    });
    /**
     *
     * @param formData
     * @returns {boolean|*}
     */
    const handleCreateMonitoringSubmitClick = formData => {
        if (formData.monitoringUrl && formData.monitoringType) {
            let monitoringData = {
                monitoringType: formData.monitoringType,
                monitoringUrl: formData.monitoringUrl
            };
            setMonitoringModalState({btnLoading: true, clearData: false});
            return createMonitoring({
                variables: monitoringData
            });
        } else {
            return false;
        }
    };
    const handleCreateButtonClick = () => {
        setCreateMonitoringModalState(true);
    }
    return (
        <React.Fragment>
            <ElasticMonitoringPanel
                t={t}
                loading={loading}
                columns={columns}
                elasticUserList={elasticUserList}
                handleClick={handleClick}
                error={error}/>
            <Divider/>
            <Monitoring
                t={t}
                loading={monitoringLoading}
                columns={monitoringColumns}
                elasticUserList={monitoringList}
                userRole={USER_ROLES}
                role={role}
                handleClick={handleMonitoringClick}
                handleCreateButtonClick={handleCreateButtonClick}
                error={monitoringError}/>
            <CreateMonitoringModal
                t={t}
                open={createMonitoringTemplateModal}
                handleCreateMonitoringSubmitClick={handleCreateMonitoringSubmitClick}
                handleClose={() => {
                    setCreateMonitoringModalState(false);
                    setMonitoringModalState({clearData: false, btnLoading: false});
                }}
                modalState={monitoringModalState}
            />
        </React.Fragment>
    );
}
MonitoringPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object
};
export default MonitoringPanel;
