/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { get } from "lodash-es";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TableContainer from '@material-ui/core/TableContainer';
import Input from '@material-ui/core/Input';
import CSVReader from 'react-csv-reader';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AttachFile from '@material-ui/icons/AttachFile';
import Message from './Message/index';
import {
    SALES_TRACKER_UPLOAD_FILE
} from './commonMutations';
import Table from '@material-ui/core/Table';
import { Button, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Pagination from '@material-ui/lab/Pagination';

import ChartContainer from '../../../../../../../components/Charts/ChartContainer';
import commonStyles from '../../../../../../../theme/commonStyles';
import { dateFormatterLLL, convertUTCDateToLocalDate, convertLocalToUTCDate, getStartEndTimeForReport } from '../../../../../../../utils';
import {
    GET_USER_PRODUCTS,
} from './queries';




function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick}
            disabled={loading || disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Export'}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2),
        position: "relative",
        overflow: "hidden"
    },
    dateInput: {
        width: 250,
        minWidth: 200,
        padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    },
   
      button: {
        marginLeft: theme.spacing(2),
      },

}));




const initMapFilter = { time: 'Today', startDate: null, endDate: null, placeId: '', projectId: '' };

const OcrConfigurationDocumentsReport = ({
    t,
    ocrPlaceData,
    ocrProjectData,
    loadingOcrPlace,
    loadingOcrProject,
    ocrDocumentsListing,
    ocrFetchLoadingForDocuments,
    handleFilterdataForOptions,
    handleSubmitOrFilterForDocumentConfigTab
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [ocrDocumentReportState, setOcrDocumentReportState] = useState({ ...initMapFilter });
    const [documentsDataForEcel, setDocumentsDataForEcel] = useState([]);
    //const [inputValue, setInputValue] = React.useState('');
    const [uploadedFileLoading, setUploadedFileLoading] = useState(false);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [productList, setProductList] = useState(null)
    const [page,setPage]=useState(1);
    const [search,setSearch]=useState("");
    const [pageNumber,setpageNumber]=useState();
    const [btnSearch, sertbtnSearch] =useState(true)

    const inputFile = React.createRef();

  

 
    const [getUserProducts, {loading: getproductLoading}] = useLazyQuery(GET_USER_PRODUCTS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if(data && data.getUserProducts){
                setProductList(data.getUserProducts.results.data.products_data);
                setpageNumber(data.getUserProducts.results.data["Total count"])
                //  console.log(data.getUserProducts.results.data,"productList")
            }
            // setOcrFetchLoading(false);
        },
        onError: error => {
            Message.error(error);
            // setOcrFetchLoading(false);
        }
    });

    useEffect(()=>{
        getUserProducts({ variables:{
           page,
           search
        }});
    },[page])

    useEffect(()=>{
        setPage(1);
        getUserProducts({ variables:{
           page,
           search
        }});
    },[btnSearch])
    

    
   
    let columns = [
        
        {
            label: 'Product Name',
            key: "Product Name"
        },
        {
            label: 'Barcode',
            key: "Barcode"
        },
        {
            label: 'Product Format',
            key: "Product Format"
        },
        {
            label: 'Username',
            key: "Username"
        },

        {
            label: 'Date',
            key: "Date"
        }
    ];

 const handlePagination = (evt,value)=>{
    setPage(value)

 }  

    return (
        <>


<Grid
       container
       style={{
         minHeight: '200px',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'stretch', // Equal height for both items
         gap: '10px', // Space between items
       }}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Input
          variant={'outlined'}
          style={{ width: '100%' }}
          color={'secondary'}
          placeholder="Query by barcode or username"
          onChange={evt => setSearch(evt.target.value)}
 
        />
      </Grid>
      <Grid item xs={12} sm={2} md={1}>
        <Button
        disabled={getproductLoading}
          variant={'contained'}
          style={{width: '100%'}}
          color= {'primary'} 
         onClick={()=>{sertbtnSearch(!btnSearch)}}
          >
            { getproductLoading ? <span> Fetching...</span> :    "Search"}
       
        </Button>
      </Grid>
    </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
    {!getproductLoading && productList ? (
        productList.map((rowitem, rowIndex) => (
            <TableRow key={rowIndex}>
                
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {rowitem.product_name}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {rowitem.barcode}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {rowitem.product_format}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {rowitem.user_name}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Typography variant={'subtitle1'}>
                            {dateFormatterLLL(rowitem.upload_date)}
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        ))
    ) : (
        
        <TableRow>
            <TableCell colSpan={columns.length}>
                <Typography variant={'subtitle2'}>..Loading</Typography>
            </TableCell>
        </TableRow>
    )}
    <TableRow>
        {getproductLoading && (
            <TableCell colSpan={columns.length}>
                {getproductLoading && <LinearProgress color="primary" />}
            </TableCell>
        )}
    </TableRow>
</TableBody>

                </Table>
                {!getproductLoading && <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                             </Grid>
                <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={Math.ceil(pageNumber/10)}
                                page={page} 
                                onChange={(evt, value) => {
                                    handlePagination(evt, value);
                                }} 
                            />
                        </Grid>
                        </Grid>}
            </TableContainer>
        </>

    )
}

export default OcrConfigurationDocumentsReport