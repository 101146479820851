import React, {useEffect, useState, useContext} from 'react';
import {useLazyQuery} from '@apollo/react-hooks';
import { get } from "lodash-es";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from "@material-ui/core/CardMedia";
import Box from '@material-ui/core/Box';
import {OcrContext} from '../../../../../../contexts/OcrProvider';
import {
    GET_OCR_PARSE_IMG
} from '../../../../Ocr/queries';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const OcrProcessView = ({
    t,
    open,
    onClose,
    data
}) => {
    const classes = useStyles();
    const [fileData, setFileDate] = useState('');
    const { 
        organizationId
      } = useContext(OcrContext);

    const [getOcrParseImg, { loading: getFileDetilsLoading }] = useLazyQuery(GET_OCR_PARSE_IMG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const alldata = get(data, 'getOcrParseImageData.data', '')
            setFileDate(alldata);
        },
        onError: error => {
            Message.error(error);
        }
    });

    useEffect(() => {
        if(data && data.fileId){
            getOcrParseImg({
                variables:{
                    organization: organizationId,
                    id:data.fileId
                }
            })
        }
    }, [data]);
    
    useEffect(() => {
        if(!open){
        setFileDate('');
        }
    }, [open]);
    
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
    disableBackdropClick={true} 
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            <Typography variant="h4" align="center">{t("allProcessSteps.label")}</Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <Card style={{ display: "flex", flexDirection: "row-reverse", justifyContent: 'space-evenly' }}>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                    <CardContent>
                        <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                        Title:  <b>{data.title ? data.title : '-'}</b>
                        </Typography>
                        <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                        Description:  <b>{data.description ? data.description : '-'}</b>
                        </Typography>
                        <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                        Comments:  <b>{data.comments ? data.comments : '-'}</b>
                        </Typography>
                        <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                        Process option:  <b>{data.selectedProcessOption ? data.selectedProcessOption : '-'}</b>
                        </Typography>
                        {
                            data.approvalOrRejectComments && (
                                <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                 Approval Or Reject Comments:  <b>{data.approvalOrRejectComments ? data.approvalOrRejectComments : '-'}</b>
                                </Typography>
                            )
                        }
                        {
                            (data.approvedOrRejectStatus === true || data.approvedOrRejectStatus  === false)  && (
                                <Typography style={{ mb: 1.5 }} color="text.secondary" gutterBottom> 
                                  Approval Or Reject Status:  <b>{data.approvedOrRejectStatus && data.approvedOrRejectStatus === true ? "Approved" : data.approvedOrRejectStatus === false ? 'Rejected': null}</b>
                                </Typography>
                            )
                        }
                        {
                            data.signatureImg && (
                                    <img
                                        src={data.signatureImg}
                                        alt="signature"
                                        style={{ 
                                            height: 'auto',
                                            width: 'auto',
                                            padding: 5,
                                            border: '2px solid #c0b9b9',
                                            borderRadius: 9,
                                            marginTop: 10
                                        }}
                                    />
                            )
                    }    
                    </CardContent>
                </Box>
                {
                       getFileDetilsLoading ? <CircularProgress color="primary"/> 
                       :  (
                        <CardMedia
                        component="img"
                        style={{ width: 551 }}
                        image={fileData && fileData.url}
                        alt={fileData && fileData.file_name}
                        />
                       )
                }
            </Card>

        </DialogContent>
        <DialogActions>
            <Button 
                onClick={onClose}
                style={{marginRight: 20}}
                color="secondary"
                variant="outlined"
            >
                {t('close.label')}
            </Button>      
        </DialogActions>
    </Dialog>
  )
}

export default OcrProcessView