import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { USER_ROLES, COUNTRY_LIST } from "../../../../../utils/constants";
import Button from '@material-ui/core/Button';

const ProjectsItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    projectsUser,
    projectsType,
    handleProjectTypeClick,
    disableCloseProject,
    handleProjectAttachmentClick,
    handleRejectProjectClick,
    handleOpenProceedToBilling,
    handleProjectRowClick
}) => {
    
    const role = localStorage.getItem('role');
    const countryName = localStorage.getItem('country');
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    let emails = (data.emails) ? JSON.parse(data.emails) : null;
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.case_number ? data.case_number : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.customer_name} / {data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.project_status}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.updated_at ? dateFormatterLLL(data.updated_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.project_owner ? data.project_owner : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            disabled={data.attachments && data.attachments.length == 0 ? true : false}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleProjectAttachmentClick(data.attachments);
                                // if (data.attachments != undefined && data.attachments != '' && data.attachments != null) {
                                //     window.open(data.attachments);
                                // }
                            }}>{t('attachments.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%' }}
                            disabled={!(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) ? true : false}
                            onClick={() => {
                                handleRejectProjectClick(data);
                            }}>{t('reject.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            {/* {(COUNTRY_LIST.NIGERIA === countryName) &&
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%' }}
                            disabled={!(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) ? true : false}
                            onClick={() => {
                                handleProjectRowClick(data);
                            }}>{t('details.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>} */}
            {(COUNTRY_LIST.MALAWI === countryName || COUNTRY_LIST.NIGERIA === countryName) &&
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%' }}
                            disabled={!(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) || data.project_status=='Project Completed' ? true : false}
                            onClick={() => {
                                handleOpenProceedToBilling(data);
                            }}>{t('proceedToBilling.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>}
            <TableCell>
                <Grid>
                    {(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN)) &&
                        <OutlinedSelect
                            val={data.project_owner ? data.project_owner : ''}
                            disabled={data.project_type === 'Close Project' ? true : false}
                            label={`${t('Select Projects Owner')} *`}
                            handleChange={evt => {
                                handleMenuItemClick(evt.target.value, data.uid);
                            }}
                            styleOverrides={{ width: '100%', marginBottom: '15px' }}
                            options={projectsUser ? projectsUser : []}
                        />
                    }
                    {(role.includes(USER_ROLES.PROJECT_MANAGER) || role.includes(USER_ROLES.PMO_ADMIN)) &&
                        <OutlinedSelect
                            val={data.project_type ? data.project_type : ''}
                            disabled={data.project_type === 'Close Project' && disableCloseProject ? true : false}
                            // disabled={data.project_type ? true : false}
                            label={`${t('Select Projects Type')} *`}
                            handleChange={evt => {
                                handleProjectTypeClick(evt.target.value, data);
                            }}
                            styleOverrides={{ width: '100%' }}
                            options={projectsType ? projectsType : []}
                        />
                    }
                </Grid>
            </TableCell>
        </TableRow>
    );
};
ProjectsItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(ProjectsItem);
