import React from 'react';
import ReactEcharts from 'echarts-for-react';

const SalesTrackerDashboardBarChart = ({ distributorsData }) => {
  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0,0,0,0.3)'
    }
  };

  const chartOptions = {
    tooltip: {},
    legend: {
      left: '0%'
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '20%',
      height: '80%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data:
          distributorsData?.length !== 0
            ? distributorsData?.map(item => item.distributor_name)
            : [],
        axisLine: { onZero: true },
        splitLine: { show: false },
        splitArea: { show: false }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Products Sold',
        type: 'bar',
        barWidth: 25,
        emphasis: emphasisStyle,
        data:
          distributorsData?.length !== 0
            ? distributorsData?.map(item => item.product_sold)
            : []
      }
    ]
  };

  return (
    <>
      <ReactEcharts option={chartOptions} />
    </>
  );
};

export default SalesTrackerDashboardBarChart;
