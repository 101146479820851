import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody'; 
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
//import BackupControlPanelItem from './BackupControlPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../../components/CanvasSignaturePadModal';
import MotorAccidentForm from './MotorAccidentForm';

const useStyles = makeStyles(theme => ({
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  },
  dateInput: {
      width: 180,
      minWidth: 180,
      padding: 10
  },
}));

const MotorAccidentPanel = ({
    t,
    allowConfigure,
    subscriptionUserData
}) => {

  const classes = useStyles();

  const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
  const [selectedMeetingItem, setSelectedMeetingItem] = useState(null);
  const [meetingSignatureModalOpen, setMeetingSignatureModalOpen] = useState(false);
  const [briefModalOpen, setBriefModalOpen] = useState(false);
  const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
  const [signatureType, setSignatureType] = useState("");

  const columns = [
    {
        label: 'Name',
        key: "name"
    },
    {
        label: 'Date',
        key: "date"
    },
    {
        label: 'Checked By',
        key: "checkedBy"
    },
    {
        label: "Performed By",
        key: "performedBy"
    },
    // {
    //     label: 'Organizer',
    //     key: "organizer"
    // },
    // {
    //     label: 'Brief Notes',
    //     key: "briefNotes"
    // },
    // {
    //     label: 'Actions',
    //     key: "actions"
    // }
];


const meetingRequestList = [
  {
      name: "Server 1",
      schedule: new Date(),
      status: "Admin",
      attendees: "test",
      organizer: "test",
      meetingNotes: "multiple line code",
      actions: ""
  },
  {
      name: "Server 2",
      schedule: new Date(),
      status: "Admin",
      attendees: "test",
      organizer: "test",
      meetingNotes: "multiple line code",
      actions: ""
  },
  {
      name: "Server 3",
      schedule: new Date(),
      status: "Admin",
      attendees: "test",
      organizer: "test",
      meetingNotes: "multiple line code",
      actions: ""
  },
  {
      name: "Server 4",
      schedule: new Date(),
      status: "Admin",
      attendees: "test",
      organizer: "test",
      meetingNotes: "multiple line code",
      actions: ""
  }
];

const handleNewMeetingRequest = () => {
  setBriefModalOpen(true);
}

const handleOpenSignPanModal = (type) => {
  setSignaturePadModalOpen(true);
  setSignatureType(type);
}

const filterList = [
  {
      label: t("search.label"),
      handleChange: (newDate) => {
      },
      val: filterStateData.search,
      type: "text",
      styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
  },
  {
      label: t("startDate.label"),
      handleChange: (newDate) => {
      },
      val: filterStateData.startDate,
      type: "input",
      styleOverrides: { marginRight: 20 },
      className: classes.dateInput
  },
  {
      label: t("endDate.label"),
      handleChange: (newDate) => {
      },
      val: filterStateData.endDate,
      type: "input",
      styleOverrides: { marginRight: 20 },
      className: classes.dateInput
  }
];
  return (
    <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewMeetingRequest}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {meetingRequestList.length > 0 ?
                        meetingRequestList.map((rowitem, rowIndex) => (
                          null
                            // <BackupControlPanelItem
                            //     key={rowIndex}
                            //     t={t}
                            //     data={rowitem}
                            //     handleMenuItemClick={handleMenuItemClick}
                            //     allowConfigure={allowConfigure}
                            //     //handleAddMeetingNotes={handleAddMeetingNotes}
                            // />
                        )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <MotorAccidentForm
                t={t}
                open={briefModalOpen}
                handleClose={() => setBriefModalOpen(false)}
                handleOpenSignPanModal={handleOpenSignPanModal}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    console.log("image: ", image);
                    setSignaturePadModalOpen(false);
                }}
            />
    </div>
  )
}

export default MotorAccidentPanel