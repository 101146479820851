import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { useEffect } from 'react';
import { USER_ROLES } from "../../../../../../../utils/constants";
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../../../utils';

const ShowCamtelItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName,
    handleClickViewAllDetailsAndAttachment,
    handleProjectAttachmentClick
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.nin}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            <span style={{ color: 'black' }}>First Name: </span>{data.first_name}<br />
                            <span style={{ color: 'black' }}>Middle Name: </span>{data.middle_name}<br />
                            <span style={{ color: 'black' }}>Surname: </span>{data.surname}<br />
                            <span style={{ color: 'black' }}>Mothers maiden name: </span>{data.mothers_maider_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.means_of_identification}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            <span style={{ color: 'black' }}>Phone Number: </span>{data.phone_number}<br />
                            <span style={{ color: 'black' }}>Alternate Phone Number: </span>{data.alternate_phone_number}<br />
                            <span style={{ color: 'black' }}>Email Aaddress: </span>{data.email_address}<br /><br />
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            <span style={{ color: 'black' }}>Nationality: </span>{data.nationality}<br />
                            <span style={{ color: 'black' }}>Date of Birth: </span>{data.date_of_birth}<br />
                            <span style={{ color: 'black' }}>Address: </span>{data.address}<br />
                            <span style={{ color: 'black' }}>Profession: </span>{data.profession}<br />
                            <span style={{ color: 'black' }}>Height: </span>{data.height}<br />
                            <span style={{ color: 'black' }}>Gender: </span>{data.gender}<br />
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid style={{ textAlign: 'Center' }}>
                        <img
                            style={{ borderRadius: '50%' }}
                            width="120px"
                            height="120px"
                            src={data.profile_photo}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid style={{ textAlign: 'Center' }}>
                        <img
                            style={{ borderRadius: '5px' }}
                            width="150px"
                            height="120px"
                            src={data.signature}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
ShowCamtelItem.propTypes = {
    data: PropTypes.object.isRequired,
};
export default withTranslation()(ShowCamtelItem);