import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import {GET_AWS_REGIONS} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
const roleTypes = [
    {
        id: 'ucpe_manager',
        name: 'uCPE Manager'
    },
    {
        id: 'SD-WAN Controller',
        name: 'SD-WAN Controller'
    },
    {
        id: 'Security Controller',
        name: 'Security Controller'
    },
    {
        id: 'Other',
        name: 'Other'
    }
];
const provisioningTypes = [
    {
        id: 'Automatic',
        name: 'Automatic'
    },
    {
        id: 'Manual',
        name: 'Manual'
    }
];
const versionType = [
    {
        id: '2.3.0',
        name: '2.3.0'
    },
    {
        id: '2.4.0',
        name: '2.4.0'
    }
];
const webUIOptions = [
    {
        id: 'Yes',
        name: 'Yes'
    },
    {
        id: 'No',
        name: 'No'
    }
];
function CreateControllerModal({
                                   t,
                                   open,
                                   handleCreateController,
                                   handleClose,
                                   data,
                                   modalState
                               }) {
    const [controllerName, setControllerName] = useState('');
    const [deploymentType, setDeploymentType] = useState('');
    const [roleType, setRoleType] = useState('');
    const [otherRoleType, setOtherRoleType] = useState('');
    const [provisioningType, setProvisioningType] = useState('');
    const [version, setVersion] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [webUIOption, setWebUIOption] = useState('');
    const [webUIPort, setWebUIPort] = useState('');
    const [baseUrl, setBaseUrl] = useState('');
    const [priority, setPriority] = useState('');
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setControllerName('');
            setDeploymentType('');
            setRoleType('');
            setProvisioningType('');
            setVersion('');
            setWebUIOption('');
            handleClose();
        }
    }, [modalState]);
    const {
        loading: loadingRegions,
        data: regionsList,
        error: regionsError
    } = useQuery(GET_AWS_REGIONS);
    const deploymentTypes =
        !loadingRegions && !regionsError
            ? regionsList.getAwsRegions.map(region => {
                return {id: region, name: region};
            })
            : [];
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid>
                                <Input
                                    value={controllerName}
                                    type={'text'}
                                    placeholder={`${t('controller.label')} ${t('name.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setControllerName(evt.target.value)}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={deploymentType}
                                    label={`${t('deploymentType.label')} *`}
                                    handleChange={evt => {
                                        setDeploymentType(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={deploymentTypes}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={roleType}
                                    label={`${t('role.label')} *`}
                                    handleChange={evt => {
                                        setRoleType(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={roleTypes ? roleTypes : []}
                                />
                            </Grid>
                            {roleType === 'Other' ? (
                                <Grid style={marginGenerator('mt-24')}>
                                    <Input
                                        value={otherRoleType}
                                        type={'text'}
                                        placeholder={`${t('role.label')} ${t('name.label')}*`}
                                        style={{width: '100%'}}
                                        color={'secondary'}
                                        onChange={evt => setOtherRoleType(evt.target.value)}
                                    />
                                </Grid>
                            ) : null}
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={provisioningType}
                                    label={`${t('provisioningType.label')} *`}
                                    handleChange={evt => {
                                        setProvisioningType(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={provisioningTypes ? provisioningTypes : []}
                                />
                            </Grid>
                            {provisioningType === 'Manual' ? (
                                <Grid style={marginGenerator('mt-24')}>
                                    <Input
                                        value={ipAddress}
                                        type={'text'}
                                        placeholder={`${t('dns.label')} ${t('address.label')}*`}
                                        style={{width: '100%'}}
                                        color={'secondary'}
                                        onChange={evt => setIpAddress(evt.target.value)}
                                    />
                                </Grid>
                            ) : null}
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={webUIOption}
                                    label={`${t('webUIPresent.label')} *`}
                                    handleChange={evt => {
                                        setWebUIOption(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={webUIOptions ? webUIOptions : []}
                                />
                            </Grid>
                            {webUIOption === 'Yes' ? (
                                <>
                                    <Grid style={marginGenerator('mt-24')}>
                                        <Input
                                            value={webUIPort}
                                            type={'text'}
                                            placeholder={`${t('webUIPort.label')}*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                            onChange={evt => setWebUIPort(evt.target.value)}
                                        />
                                    </Grid>
                                    <Grid style={marginGenerator('mt-24')}>
                                        <Input
                                            value={baseUrl}
                                            type={'text'}
                                            placeholder={`${t('baseUrl.label')}*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                            onChange={evt => setBaseUrl(evt.target.value)}
                                        />
                                    </Grid>
                                </>
                            ) : null}
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={version}
                                    label={`${t('version.label')} *`}
                                    handleChange={evt => {
                                        setVersion(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={versionType ? versionType : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateController({
                                controller_name: controllerName,
                                version: version,
                                deployment_type: deploymentType,
                                role: roleType,
                                provision_type:
                                    provisioningType === 'Automatic' ? 'automatic' : 'manual',
                                web_presence: webUIOption === 'Yes' ? 'true' : 'false'
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateControllerModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateControllerModal);
