import React, { useState, useEffect, useContext } from 'react';
import {useMutation} from '@apollo/react-hooks';
import { get } from "lodash-es";
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {useFormik} from "formik";
import * as Yup from "yup";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Message from '../../../../../../components/Message';
import {
  CREATE_SHAYONA_TRUCK,
  EDIT_SHAYONA_TRUCK
} from '../../../../Shayona/mutations';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
      width: "100%",
  }
});

const useStyles = makeStyles(theme => ({
  testCon: {marginRight: '6px', marginLeft: '6px'},
  chartTitle: {marginTop: '10px'},
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  },
  mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2)
  },
  inputBase: {
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 15,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick} 
      disabled={loading||disabledValue}
      type="submit"
      >
          {loading && <CircularProgress size={14} />}
          {!loading && t('submit.label')}
      </Button>
  );
}

const TruckMasterModal = ({
  t,
  open,
  onClose,
  title,
  data
}) => {
  const classes = useStyles();
  const {organizationId,
    reloadShayonaApisOrPagination
         } = useContext(ShayonaCementsContext);

  // const handlChangeOftruckDetails = (evt) => {
  //   const {name, value} = evt.target;
  //   setTruckDetails(prevState => {
  //     const truckData = {...prevState};
  //     truckData[name] = value;
  //     return truckData;
  //   })
  // };

  const validationSchema = Yup.object({
    truckDetails : Yup.object({
      truckName: Yup
                  .string('Truck name is required')
                  .required('Truck name is required')
    })
  });

  const formik = useFormik({
    initialValues: {
      truckDetails:{ 
        truckName: get(data, 'category_name', '') || '',
        allowedBags: get(data, 'allowed_bags', '') || '',
        // emptyStartWeight: get(data, 'empty_weight_range_start', '') || null,
        // emptyEndWeight: get(data, 'empty_weight_range_end', '') || null,
        perBagWeight : get(data, 'per_bag_weight', '') || null,
        grossStartWeight: get(data, 'gross_weight_range_start', '') || null,
        grossEndWeight: get(data, 'gross_weight_range_end', '') ||  null,
        allowedDiffWeight: get(data, 'allowed_diff_weight', '') || null,
        category_id: get(data, 'category_id', '') || null
      }
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    onSubmit: (values) => {
      if(data && data.category_id){
      handleSubmtiOfTruckDetails('edit',values);
      } else {
      handleSubmtiOfTruckDetails('add',values);
      }
    },
  });

 const {handleSubmit, handleBlur, handleChange, touched, errors, values, resetForm } = formik;

 const [createShayonaTruck, { loading: createShayonaTruckLoading }] = useMutation(CREATE_SHAYONA_TRUCK, {
    onError: error => {
        console.log('err', error);
    },
    onCompleted: data => {
        Message.success(data && data.addTruckData.message);
        onClose();
        reloadShayonaApisOrPagination('truck','');
    }
  });

  const [editShayonaTruck, { loading: editShayonaTruckLoading }] = useMutation(EDIT_SHAYONA_TRUCK, {
    onError: error => {
        console.log('err', error);
    },
    onCompleted: data => {
        Message.success(data && data.editTruckData.message);
        onClose();
        reloadShayonaApisOrPagination('truck','');
    }
  });

 const handleSubmtiOfTruckDetails = (type, data) => {
   if(type === 'add'){
    createShayonaTruck({
      variables:{
        organization: organizationId,
        truckDetails: data && data.truckDetails
      }
    });
   } else if(type === 'edit'){
    editShayonaTruck({
      variables:{
        organization: organizationId,
        truckDetails: data && data.truckDetails 
      }
    });
   }
 }

 useEffect(() => {
  if(!open){
    resetForm();
  }
 }, [open]);
 
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
             {title}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
              <>
                <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={12}>
                                      <TextField
                                        value={values && values.truckDetails.truckName}
                                        label="Truck Name" 
                                        name="truckDetails.truckName"
                                        style={{ width: '100%'}}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.truckDetails?.truckName && errors.truckDetails?.truckName}
                                        error={touched.truckDetails?.truckName && Boolean(errors.truckDetails?.truckName)}
                                      />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={12}>
                                      <TextField
                                        value={values && values.truckDetails.allowedBags}
                                        id="outlined-search" 
                                        label="Allowed bags" 
                                        type="search"
                                        name="truckDetails.allowedBags"
                                        style={{ width: '100%'}}
                                        onChange={handleChange}
                                      />
                                  </Grid>
                                </Grid>
                                <div style={{ display : 'flex', justifyContent: 'center' }}>Per bag weight</div>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                <Grid item xs={12}>
                                      <TextField
                                        value={values && values.truckDetails.perBagWeight}
                                        id="outlined-size-small" 
                                        label="Kg" 
                                        type="search"
                                        name="truckDetails.perBagWeight"
                                        style={{ width: '100%'}}
                                        size="small"
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                  {/* <Grid item xs={6}>
                                      <TextField
                                        value={values && values.truckDetails.emptyStartWeight}
                                        id="outlined-size-small" 
                                        label="Start" 
                                        type="search"
                                        name="truckDetails.emptyStartWeight"
                                        style={{ width: '46%'}}
                                        size="small"
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        value={values && values.truckDetails.emptyEndWeight}
                                        id="outlined-size-small" 
                                        label="End" 
                                        type="search"
                                        name="truckDetails.emptyEndWeight"
                                        style={{ width: '46%'}}
                                        size="small"
                                        onChange={handleChange}
                                      />
                                    </Grid> */}
                                </Grid>
                                <div style={{ display : 'flex', justifyContent: 'center', marginTop: 5 }}>Gross Weight Range</div>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={6}>
                                      <TextField
                                        value={values && values.truckDetails.grossStartWeight}
                                        id="outlined-search" 
                                        label="Start" 
                                        type="search"
                                        name="truckDetails.grossStartWeight"
                                        style={{ width: '46%'}}
                                        size="small"
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                    <TextField
                                        value={values && values.truckDetails.grossEndWeight}
                                        id="outlined-search" 
                                        label="End" 
                                        type="search"
                                        name="truckDetails.grossEndWeight"
                                        style={{ width: '46%'}}
                                        size="small"
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={12}>
                                      <TextField
                                        value={values && values.truckDetails.allowedDiffWeight}
                                        id="outlined-search" 
                                        label="Allowed diff weight" 
                                        type="search"
                                        name="truckDetails.allowedDiffWeight"
                                        style={{ width: '100%'}}
                                        onChange={handleChange}
                                      />
                                  </Grid>
                                </Grid>
                                <DialogActions>
                                  <Grid container justify={'space-between'}>
                                      <Button 
                                          variant={'outlined'}
                                          color="primary"
                                          onClick={onClose}
                                      >
                                          {t('cancel.label')}
                                      </Button>
                                      <ButtonComponent
                                       t={t}
                                       loading={createShayonaTruckLoading || editShayonaTruckLoading}
                                      />
                                  </Grid>          
                              </DialogActions>
                            </form>
              </>
        </DialogContent>
    </Dialog>
  )
}

export default TruckMasterModal