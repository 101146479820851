import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { CircularProgress, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
});
    
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, t, handleAddProduct, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        <Button className={classes.closeButton} color="primary" variant="contained" onClick={handleAddProduct}>{t("addNew.label")}</Button>
      </MuiDialogTitle>
    );
});
  
function ServiceProducts({
    t,
    open,
    data,
    services,
    handleClose,
    handleAddProduct,
    handleEditProduct,
    handleViewBilling,
    loading
}) {
    
    const columns = [
        {
            label: t("productName.label"),
            key: "name"
        },
        {
            label: t("description.label"),
            key: "description"
        },
        {
            label: t("productUpTime.label"),
            key: "avgUptime"
        },
        {
            label: t("status.label"),
            key: "status"
        },
        {
            label: t("billing.label"),
            key: "billing"
        },
        {
            label: `${t("edit.label")}/${t("delete.label")}`,
            key: "edit_delete"
        }
    ];
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="customized-dialog-title"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            
            {/* <DialogTitle id="place-template-title"></DialogTitle> */}
            <DialogTitle id="customized-dialog-title" t={t} handleAddProduct={handleAddProduct}>
                {data ? `${data.name} - ` : ""}{t('products.label')}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    {loading ? 
                        <div style={{width: "100%", textAlign: "center"}}>
                            <CircularProgress/>
                        </div>
                    :
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item, index) => (
                                            <TableCell key={index}>
                                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {services && services.length ? services.map(itm => 
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant={'subtitle2'}>
                                                    {itm.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell width="30%">
                                                <Typography variant={'subtitle2'}>
                                                    {itm.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'subtitle2'}>
                                                    {itm.avgUptime}%
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color={itm.status === "Inactive" ? "secondary" : "primary"} variant={'subtitle2'}>
                                                    {itm.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant="outlined" size="small" color="primary" onClick={evt => handleViewBilling(itm)}>{t("billing.label")}</Button>
                                            </TableCell>
                                            <TableCell>
                                                <EditIcon onClick={evt => handleEditProduct(itm)} style={{cursor: "pointer"}} color="primary" titleAccess={t("edit.label")}/>
                                                <DeleteForever style={{ cursor: "pointer", paddingLeft: 10 }} color="secondary" titleAccess={t("delete.label")} />
                                            </TableCell>
                                        </TableRow>
                                    ) : 
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>
                                                    Record not found!
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Grid container justify={'space-between'}> */}
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                        style={{float: "right"}}
                    >
                        {t('cancel.label')}
                    </Button>
                {/* </Grid> */}
            </DialogActions>
        </Dialog>
    );
}

ServiceProducts.propTypes = {
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ServiceProducts);