import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Input, TextField, Radio, Typography, Divider } from '@material-ui/core';
import { marginGenerator } from '../../../../../../theme/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../../../../../theme/colors';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
    root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
    iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
            color="secondary"
            variant={'contained'}
            onClick={onClick} 
            disabled={loading}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const initStep = {title: "", description: "", comments: "", status: "", options: []};

const CreateProcessStepsModal = ({
    t,
    open,
    handleCreateProcessStepSubmit,
    handleClose,
    modalState,
    processData
}) => {

    const classes = useStyles();
    const [processStepsState, setProcessStepsState] = useState([{...initStep}]);

    React.useEffect(() => {
        console.log("processData: ", processData);
        if(processData){
            setProcessStepsState([{title: processData.title, description: processData.description, status: processData.status, options: processData.workflowOptions ? processData.workflowOptions : []}]);
        }else{
            setProcessStepsState([{...initStep}]);
        }
    }, [processData]);

    const handleInputChange = (e, indx) => {
        const { name, value } = e.target;
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            newState[indx][name] = value;
            return newState;
        });
    }
    
    const handleAddProcessOption = (index) => {
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            newState[index].options.push("");
            return newState;
        });
    }

    const handleOptionChange = (e, idx1, idx2) => {
        setProcessStepsState(prevState => {
            let newState = [...prevState];
            newState[idx1].options[idx2] = e.target.value;
            return newState;
        });
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={evt => {
                handleClose();
                setProcessStepsState([{...initStep}]);
            }}>
            <DialogTitle id="place-template-title">{t('create.label')} {t('processWorkFlow.label')}</DialogTitle>
            <DialogContent>
                {!processData &&
                    <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => setProcessStepsState([...processStepsState, initStep])}>
                        {t('addNewStep.label')}
                    </Button>
                }
                <Grid style={{...marginGenerator("mt-16")}} container spacing={3}>
                    {processStepsState.map((step, idx) => {
                        return (
                        <React.Fragment key={`step_${idx}`}>
                            {!processData &&
                                <Grid item xs={12}>
                                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                                        Step {idx+1}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <Input
                                    value={step.title}
                                    type={'text'}
                                    placeholder={`${t('title.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    name="title"
                                    onChange={evt => handleInputChange(evt, idx)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant={'subtitle1'} color={'textPrimary'}>
                                    Action visable
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {["Yes", "No"].map((field, index) => {
                                    return (
                                        <Radio
                                            key={index}
                                            checked={field === processStepsState[idx].status}
                                            classes={{ root: classes.iconButton }}
                                            style={{
                                                ...marginGenerator('mr-16'),
                                                ...marginGenerator('mt-0'),
                                                ...(field === processStepsState[idx].status ? { backgroundColor: colors.secondary.main }
                                                : '')
                                            }}
                                            onChange={evt => handleInputChange(evt, idx)}
                                            value={field}
                                            name="status"
                                            checkedIcon={
                                                <>
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        style={{ color: colors.common.white }}>
                                                        {field}
                                                    </Typography>
                                                </>
                                            }
                                            icon={
                                            <div className={classes.icon}>
                                                <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                {field}
                                                </Typography>
                                            </div>
                                            }
                                        />
                                    )
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant={'outlined'}
                                    color="primary"
                                    onClick={evt => handleAddProcessOption(idx)}
                                >
                                    {t('addOption.label')}
                                </Button>
                            </Grid>
                            {step.options && step.options.map((opt, optIndex) => (
                                <>
                                    <Grid item xs={9}>
                                        <Input
                                            value={opt}
                                            key={`${optIndex}-${idx}`}
                                            type={'text'}
                                            placeholder={`${t('option.label')} - ${optIndex+1}`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                            name="options"
                                            onChange={evt => handleOptionChange(evt, idx, optIndex)}
                                            />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={evt => {
                                            setProcessStepsState(prevState => {
                                                let newState = [...prevState];
                                                newState[idx].options.splice(optIndex, 1);
                                                return newState;
                                            });
                                        }} 
                                        aria-label="delete">
                                            <DeleteIcon color="secondary"/>
                                        </IconButton>
                                    </Grid>
                                </>
                            ))}
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    placeholder={t("description.label")}
                                    multiline
                                    value={step.description}
                                    rows={2}
                                    onChange={evt => handleInputChange(evt, idx)}
                                    name="description"
                                    variant="standard"
                                    style={{maxWidth: "100%", minWidth: "100%"}}
                                />
                            </Grid>
                        </React.Fragment>);
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={handleClose}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                        t={t}
                        onClick={() => {
                            handleCreateProcessStepSubmit(processStepsState);
                        }}
                        loading={modalState.loading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateProcessStepsModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateProcessStepSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateProcessStepsModal);