import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import QueryStatsIcon from '@material-ui/icons/BarChartRounded';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL} from '../../../../../../../utils';
const useStyles = makeStyles({

});


export default function BasicTable({products}) {
  const classes = useStyles();

  const [productData, setProductData] = useState()
  const [tableHead, setTableHead] = useState()

  useEffect(()=>{

    products ? setProductData(products) : setProductData()
  },[products])

  let columns = [
    
    {
        label: 'Sub Product\'s Name',
        key: "name"
    },
    {
        label: 'Barcode',
        key: "Barcode"
    },
   {
    label: 'Sold',
    key: "Sold Total"
  },
  {
    label: 'Date Uploaded',
    key: "Date"
 },
];

  return (
    <TableContainer component={Paper} style={{ maxWidth: '800px', margin: '0 auto', marginTop: '20px', padding: '20px' }}>
    <Table  aria-label="simple table" style={{ borderCollapse: 'collapse', width: '100%' }}>
      <TableHead>
        <TableRow>
          {columns.map((item, index) => (
            <TableCell key={index} style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <Typography variant="subtitle2">{item.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {productData?.map((row, index) => (
          <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
            <TableCell component="th" scope="row" style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              {row.format}
            </TableCell>
            <TableCell align="right" style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{row['barcode no']}</TableCell>
            <TableCell align="right" style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{row.sold}</TableCell>
            <TableCell align="right" style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              {dateFormatterLLL(row.created_at)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  
  );
}
