import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { InternalPaths, OrganisationPaths } from '../../../routes/routePaths';
import OrganisationsList from './OrganisationsList';
import OrganisationDetails from './OrganisationDetails';

class Organisations extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={OrganisationPaths.details}
          component={OrganisationDetails}
        />
        <Route
          exact={true}
          path={InternalPaths.organisations}
          component={OrganisationsList}
        />
      </Switch>
    );
  }
}
export default Organisations;
