import { gql } from 'apollo-boost';
/*
  Get Notification Template Details By Id
 */
export const GET_ALL_BIDS = gql`
  query Bids($search: String, $page: Int, $limit: Int, $status: String, $startDate: Date, $endDate: Date) {
      getBids(search: $search, page: $page, limit: $limit, status :$status, startDate: $startDate, endDate: $endDate) {
        id
        created_at
        created_by_name
        name
        due_date
        status
        file_url
      }
  }
`;
export const GET_QUOTE_DETAILS_BY_BID = gql`
  query Quote($bidId: String) {
    getWinnerQuoteDetailsByBid(bidId: $bidId) {
      docs
      name
      id
      raw_text
      is_winner
      vendor_name
      extracted_price
    }
  }
`;
/*
Get All Quotes List
*/
export const GET_ALL_QUOTES = gql`
  query Quotes($search: String, $page: Int, $limit: Int, $bidId: String) {
    getQuotes(search: $search, page: $page, limit: $limit, bidId: $bidId) {
      id
      created_at
      created_by_name
      name
      vendor_name
      extracted_price
      is_winner
      bid
      raw_text
      docs
    }
  }
`;