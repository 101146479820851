import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from 'react-datepicker';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateBillingTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    modalContentLoading,
    selectedProjectTypeAndUid,
    showPreValueOfForm
}) {
    const classes = useStyles();
    const [Date, setDate] = useState('');
    const [ClientName, setClientName] = useState('');
    const [AccountId, setAccountId] = useState('');
    const [ServiceId, setServiceId] = useState('');
    const [Product, setProduct] = useState('');
    const [ClientCategory, setClientCategory] = useState('');
    const [BillingStartDate, setBillingStartDate] = useState('');
    const [MrcExl, setMrcExl] = useState('');
    const [NrcExl, setNrcExl] = useState('');
    const [BillingPeriod, setBillingPeriod] = useState('');
    const [Currency, setCurrency] = useState('');
    const [Document, setDocument] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Occurrence, setOccurrence] = useState('');
    const [BillEndIndicator, setBillEndIndicator] = useState('');
    const [ChargePeriod, setChargePeriod] = useState('');
    const [Prorated, setProrated] = useState('');
    const [NetworkCode, setNetworkCode] = useState('');
    const [SupplierBaseCost, setSupplierBaseCost] = useState('');
    const [ChargeStartDate, setChargeStartDate] = useState('');
    const [ChargeEndDate, setChargeEndDate] = useState('');
    const isNumber = (str) => /^[0-9.]*$/.test(str);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setDate('');
            setClientName('');
            setAccountId('');
            setServiceId('');
            setProduct('');
            setClientCategory('');
            setBillingStartDate('');
            setMrcExl('');
            setNrcExl('');
            setBillingPeriod('');
            setCurrency('');
            setDocument('');
            setOnHold('');
            setCategory('');
            setEndDate('');
            setOccurrence('');
            setBillEndIndicator('');
            setChargePeriod('');
            setProrated('');
            setNetworkCode('');
            setSupplierBaseCost('');
            setChargeStartDate('');
            setChargeEndDate('');
        }
    }, [modalState, open, modalContentLoading]);
    const OccurrenceList = [
        {
            id: '1',
            name: '1'
        },
        {
            id: '2',
            name: '2'
        },
    ]
    const ChargePeriodList = [
        {
            id: 'M',
            name: 'M'
        },
        {
            id: 'Y',
            name: 'Y'
        },
    ]
    const ProratedList = [
        {
            id: '0',
            name: '0'
        },
        {
            id: '1',
            name: '1'
        },
    ]
    const handleChangeSupplierBaseCost = (evt) => {
        const { value } = evt.target;
        if (isNumber(value)) {
            let convertNumber = parseFloat(value).toFixed(2);
            // console.log("typeof number : ", convertNumber);
            // let res = parseFloat(Number(value).toFixed(2));
            // console.log("typeof res : ", res);
            setSupplierBaseCost(convertNumber);
        }
    }
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    const clientCategoryList = [
        {
            id: t("corporate.label"),
            name: t("corporate.label")
        },
        {
            id: t("consumer.label"),
            name: t("consumer.label")
        },
        {
            id: t("SME.label"),
            name: t("SME.label")
        },
    ]
    const billingPeriodList = [
        {
            id: t("monthly.label"),
            name: t("monthly.label")
        },
        {
            id: t("quarterly.label"),
            name: t("quarterly.label")
        },
        {
            id: t("semiAnnual.label"),
            name: t("semiAnnual.label")
        },
        {
            id: t("annual.label"),
            name: t("annual.label")
        },
    ]
    const currencyList = [
        {
            id: 'USD',
            name: 'USD'
        },
        {
            id: 'MWK',
            name: 'MWK'
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('billingTeam.label')}</DialogTitle>
            {(showPreValueOfForm === true) ? <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.case_number ? preValuesOfForm.project_info.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle> : <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>}
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('date.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={Date}
                                        onChange={date => setDate(date)}
                                        placeholderText="Please select a date"
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('clientName.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={ClientName}
                                    type={'text'}
                                    placeholder={t('clientName.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setClientName(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('accountId.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={AccountId}
                                    type={'text'}
                                    placeholder={t('accountId.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setAccountId(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('serviceId.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={ServiceId}
                                    type={'text'}
                                    placeholder={t('serviceId.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setServiceId(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('product.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={Product}
                                    type={'text'}
                                    placeholder={t('product.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setProduct(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={ClientCategory}
                                    label={`${t('clientCategory.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setClientCategory(evt.target.value)
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={clientCategoryList && clientCategoryList.length ? clientCategoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('mrcExcl.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={MrcExl}
                                    type={'text'}
                                    placeholder={t('mrcExcl.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setMrcExl(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('nrcExcl.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={NrcExl}
                                    type={'text'}
                                    placeholder={t('nrcExcl.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setNrcExl(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={BillingPeriod}
                                    label={`${t('billingPeriod.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setBillingPeriod(evt.target.value)
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={billingPeriodList && billingPeriodList.length ? billingPeriodList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={Currency}
                                    label={`${t('currency.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setCurrency(evt.target.value)
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={currencyList && currencyList.length ? currencyList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('attachServiceAcceptanceDocument.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    type={'file'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0) {
                                            let file = evt.target.files[0];
                                            setDocument(file);
                                        } else {
                                            setDocument('');
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    val={assignToBillingToBill}
                                    label={`${t('assignToBillingToBill.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setAssignToBillingToBill(evt.target.value)
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0, marginBottom: 15 }}
                                    options={allUserList && allUserList.length ? allUserList : []}
                                />
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label') + ' ' + t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label') + ' ' + t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('billing.label')} {t('start.label')} {t('date.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={BillingStartDate}
                                        selectsStart
                                        onChange={date => setBillingStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Please select a date"
                                        startDate={BillingStartDate}
                                        endDate={EndDate}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('endDate.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={EndDate}
                                        selectsEnd
                                        onChange={date => setEndDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Please select a date"
                                        endDate={EndDate}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('occurrence.label') + ' *'}`}
                                    handleChange={evt => {
                                        setOccurrence(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={OccurrenceList ? OccurrenceList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('billEndIndicator.label') + ' *'}`}
                                    handleChange={evt => {
                                        setBillEndIndicator(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={OccurrenceList ? OccurrenceList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('chargePeriod.label') + ' *'}`}
                                    handleChange={evt => {
                                        setChargePeriod(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={ChargePeriodList ? ChargePeriodList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('prorated.label') + ' *'}`}
                                    handleChange={evt => {
                                        setProrated(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={ProratedList ? ProratedList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('networkCode.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={NetworkCode}
                                    type={'text'}
                                    placeholder={t('networkCode.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setNetworkCode(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('supplierBaseCost.label')}</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={SupplierBaseCost}
                                    type={'text'}
                                    placeholder={t('supplierBaseCost.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => handleChangeSupplierBaseCost(evt)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('charge.label')} {t('startDate.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={ChargeStartDate}
                                        selectsStart
                                        onChange={date => setChargeStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Please select a date"
                                        startDate={ChargeStartDate}
                                        endDate={ChargeEndDate}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('charge.label')} {t('endDate.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <div className="datePicker">
                                    <DatePicker
                                        selected={ChargeEndDate}
                                        selectsEnd
                                        onChange={date => setChargeEndDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Please select a date"
                                        endDate={ChargeEndDate}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!Date || !ClientName || !AccountId || !ServiceId || !Product || !ClientCategory ||
                                !BillingStartDate || !MrcExl || !NrcExl || !BillingPeriod || !Currency || !Document || !EndDate || !Occurrence || !BillEndIndicator ||
                                !ChargePeriod || !Prorated || !NetworkCode || !ChargeStartDate || !ChargeEndDate) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            // create URL for Document file
                            let getDocument = "";
                            if (Document != "" && Document != null) {
                                let getDocumentUrl = await handleUploadFiles(Document, (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid, 'malawi_attach_service_acceptance_document');
                                if (getDocumentUrl.data && getDocumentUrl.data.allUploadFile && getDocumentUrl.data.allUploadFile.url) {
                                    getDocument = getDocumentUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                project_uid: (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid,
                                job_order_form_url: (showPreValueOfForm === true) ? preValuesOfForm.job_order_form_url : '',
                                feetport_form_url: (showPreValueOfForm === true) ? preValuesOfForm.feetport_form_url : '',
                                date: moment(Date).format("YYYY-MM-DD"),
                                client_name: ClientName,
                                account_id: AccountId,
                                service_id: ServiceId,
                                product: Product,
                                client_category: ClientCategory,
                                billing_start_date: moment(BillingStartDate).format("YYYY-MM-DD"),
                                mrc_exl: MrcExl,
                                nrc_exl: NrcExl,
                                billing_period: BillingPeriod,
                                currency: Currency,
                                attach_service_acceptance_document: getDocument,
                                next_team_member_email: 'accounts-mw@inq.inc',
                                category: category,
                                on_hold: onHold,
                                end_date: moment(EndDate).format("YYYY-MM-DD"),
                                occurrence: Occurrence,
                                billend_indicator: BillEndIndicator,
                                charge_period: ChargePeriod,
                                prorated: Prorated,
                                network_code: NetworkCode,
                                supplier_base_cost: SupplierBaseCost,
                                charge_start_date: moment(ChargeStartDate).format("YYYY-MM-DD"),
                                charge_end_date: moment(ChargeEndDate).format("YYYY-MM-DD"),
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateBillingTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateBillingTeamModal);
