import React, { useState } from 'react';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import { GET_INVITE_MEMBERS } from '../../../Internal/Organisations/queries';
import { GET_INVITE_MEMBER_TEMP } from '../../../Profile/queries';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const TeamPanel = ({
    t,
    userData,
    handleInviteUserClick,
    handleManageMemberClick,
    handleDeleteMemberClick,
    updatedMemberData,
}) => {

    const commonClasses = commonStyles();
    const [viewPasswordState, setViewPasswordState] = useState({});
    const {
        loading: teamLoading,
        error: teamError,
        data: teamList,
        refetch: reloadMemberList
        } = useQuery(GET_INVITE_MEMBERS, {
        fetchPolicy: "no-cache",
        variables: {
            orgId: userData.organization.id
        }
    });

    const {
        loading: tempLoading,
        error: tempError,
        data: tempList,
        refetch:reloadTempList
        } = useQuery(GET_INVITE_MEMBER_TEMP, {
        variables: {
            userId: userData.id
        }
    });
    
    const columns = [
        {
            label: t('name.label'),
            key: ['name', 'email'],
        },
        {
            label: t('email.label'),
            key: 'email'
        },
        {
            label: t('role.label'),
            key: 'role'
        },
        {
            label: t('password.label'),
            key: 'password'
        },
        {
            label: t('manage.label'),
            key: 'country'
        }
    ];

    if(!teamLoading && !teamError){

        teamList.inviteMembersAnOrganization = teamList.inviteMembersAnOrganization.filter((item) => {
            if(item.id !== userData.id){
                return item;
            }
        });

        if(!tempLoading && !tempError && tempList.getInviteMembers){
            teamList.inviteMembersAnOrganization.map(item => {    
                let key = tempList.getInviteMembers.filter(temp => {
                    if(temp.userId === item.id){
                        return temp;
                    }
                });
                item.password = (key.length > 0 && key[0].tempKey) ? key[0].tempKey : 'Reseted By User';
            });
        }
    }

    if(updatedMemberData === true){
        reloadTempList();
        reloadMemberList();
    }

    const showMemberPassword = (id, value) => {
        setViewPasswordState(prevState => ({...prevState, [id]: value }));
    }

    return (
        <div>            
            <Grid style={{ display: 'flex', alignItems: 'right' }} style={{...marginGenerator('mb-20'), float: 'right'}} >
                <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={() =>
                    handleInviteUserClick()
                }>
                    {t('inviteNew.label')}
                </Button>
            </Grid>
            <Grid container >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { !teamError && !teamLoading ? 
                                teamList.inviteMembersAnOrganization.length ?
                                teamList.inviteMembersAnOrganization.map((rowitem, rowIndex) => (
                                    <TableRow key={rowIndex} >
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} > 
                                                        {rowitem.firstName}&nbsp;{rowitem.lastName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} > 
                                                        {rowitem.email}
                                                    </Typography>   
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Typography variant={'subtitle1'} > 
                                                        {rowitem.roles ? rowitem.roles : '-' }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column} style={{position:"relative"}}>
                                                {rowitem.password && 
                                                    <Grid>
                                                        { rowitem.password === 'Reseted By User' ?
                                                             <Typography variant={'subtitle2'}>{rowitem.password}</Typography>
                                                        :
                                                            <>
                                                                {
                                                                viewPasswordState[rowitem.id] ?
                                                                <Icon
                                                                    component={() => (
                                                                        <VisibilityOffIcon
                                                                        color={'secondary'}
                                                                        style={{cursor:'pointer'}}
                                                                        onClick={()=>{
                                                                            showMemberPassword(rowitem.id, false);
                                                                        }}
                                                                        />
                                                                    )}
                                                                    alt={'icon'}
                                                                /> :
                                                                <Icon
                                                                    component={() => (
                                                                        <VisibilityIcon
                                                                        color={'secondary'}
                                                                        style={{cursor:'pointer'}}
                                                                        onClick={()=>{
                                                                            showMemberPassword(rowitem.id, true);
                                                                        }}
                                                                        />
                                                                    )}
                                                                    alt={'icon'}
                                                                />
                                                                }
                                                                <span style={{position:"absolute", top:'6px', left:'35px'}}>{viewPasswordState[rowitem.id] ? rowitem.password : '*********'}</span>
                                                            </>
                                                        }
                                                    
                                                    </Grid>
                                                }
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={commonClasses.column}>
                                                <Grid>
                                                    <Button
                                                    align={'right'}
                                                    variant={'contained'}
                                                    color={'secondary'}
                                                    onClick={() =>
                                                        handleManageMemberClick(rowitem)
                                                    }
                                                    >
                                                        {t('manage.label')}
                                                    </Button>
                                                </Grid>
                                                <Grid>
                                                    <Button
                                                    align={'right'}
                                                    variant={'contained'}
                                                    color={'primary'}
                                                    style={{minWidth:"auto", padding:"6px 8px 9px", ...marginGenerator('ml-10') }}
                                                    onClick={() => {
                                                            if(!window.confirm("Are you sure? you want to delete this user.")){
                                                                return null;
                                                            }
                                                            handleDeleteMemberClick(rowitem.id)
                                                        }
                                                    }>
                                                    <Icon
                                                        component={() => (
                                                            <DeleteForeverIcon
                                                                style={{color:"#fff"}}
                                                            />
                                                        )}
                                                        alt={'icon'}
                                                        />
                                                    </Button>
                                                </Grid>
                                                
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
};

TeamPanel.propTypes = {
  userData: PropTypes.object.isRequired,
  handleInviteUserClick: PropTypes.func.isRequired,
};

export default TeamPanel;
