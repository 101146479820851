import React, { useRef, useState, useEffect } from 'react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete,
  DirectionsRenderer,
  Polyline
} from '@react-google-maps/api';
import axios, { Axios } from 'axios';
import Barru from './Barru.svg';
import { FABRIC_URL } from '../../envConstants';

const center = { lat: 48.8584, lng: 2.2945 };

const markers = [
  {
    id: 1,
    name: 'Chicago, Illinois',
    position: { lat: 26.137979, lng: 28.198371 }
  },
  {
    id: 2,
    name: 'Denver, Colorado',
    position: { lat: -1.328116, lng: 36.866976 }
  }
];

const test = [
  {
    id: 1,
    name: 'bharath, Illinois',
    position: { lat: 41.881832, lng: -27.623177 }
  },
  {
    id: 2,
    name: 'naresh, Illinois',
    position: { lat: 21.881832, lng: -87.623177 }
  }
];

export default function Maps() {
  const [ite, setIte] = useState([]);
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [activeMarker, setActiveMarker] = useState(null);

  const originRef = useRef();

  const destinationRef = useRef();
  useEffect(() => {
    const portData = async () => {
      const org = localStorage.getItem('orgId');
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(
        `${FABRIC_URL}connection/list?org_code=${org}`,
        { headers: { accessToken: `${token}` } }
      );
      const portDatas = await res.data.result.data;
      setItems(portDatas);
    };
    portData();
  }, []);

  useEffect(() => {
    const portData = async () => {
      const org = localStorage.getItem('orgId');
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(`${FABRIC_URL}port?org_code=${org}`, {
        headers: { accessToken: `${token}` }
      });
      const portDatas = await res.data.result.data;
      setIte(portDatas);
    };
    portData();
  }, []);
  useEffect(() => {
    const portData = async () => {
      const org = localStorage.getItem('orgId');
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(`${FABRIC_URL}fcr`, {
        headers: { accessToken: `${token}` }
      });
      const portDatas = await res.data.result.data;
      setItem(portDatas);
    };
    portData();
  }, []);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAnJ5cu8iq7CujJqm2tojU_TNKuNRHFLhE',
    libraries: ['places', 'visualization']
  });

  if (!isLoaded) {
    return <h1>its working</h1>;
  }

  async function CalculateRoute() {
    if (originRef.current.value === '' || destinationRef.current.value === '') {
      return;
    }
    //eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      //eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
    originRef.current.value = '';
    destinationRef.current.value = '';
  }
  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const handleActivePolyline = marker => {
    if (marker === activeMarker) {
      return alert('its working');
    }
    setActiveMarker(marker);
  };

  var google = window.google;
  const handleOnLoad = map => {
    // const google = window.google;
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };
  return (
    <GoogleMap
      onLoad={handleOnLoad}
      // defaultCenter={{lat:-33.918861 ,lng:18.423300}}
      defaultZoom={5}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: '93vw', height: '50vh' }}>
      {ite.map(test => (
        <Marker
          key={test._id}
          position={{
            lat: parseFloat(test.datacenter.latitude),
            lng: parseFloat(test.datacenter.longitude)
          }}
          onClick={() => handleActiveMarker(test._id)}
          icon={{
            url:
              'https://image.shutterstock.com/image-vector/network-port-icon-260nw-349033844.jpg',
            scaledSize: new google.maps.Size(30, 30)
          }}>
          {activeMarker === test._id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                {test.name} <br />
                {test.port_speed}
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
      {item.map(test => (
        <Marker
          key={test._id}
          position={{
            lat: parseFloat(test.datacenter.latitude),
            lng: parseFloat(test.datacenter.longitude)
          }}
          onClick={() => handleActiveMarker(test._id)}
          icon={{
            url: './Images/azure.png',
            scaledSize: new google.maps.Size(30, 30)
          }}>
          {activeMarker === test._id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                {test.name} <br />
                {test.port_speed}
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
      \
      {items.map(test => (
        <Polyline
          options={{
            strokeColor: 'red',
            strokeOpacity: 0.75,
            strokeWeight: 2
          }}
          path={[
            {
              lat: parseFloat(
                test.a_end_port_id === undefined
                  ? test.a_end_FCR_id.datacenter.latitude
                  : test.a_end_port_id.datacenter.latitude
              ),
              lng: parseFloat(
                test.a_end_port_id === undefined
                  ? test.a_end_FCR_id.datacenter.longitude
                  : test.a_end_port_id.datacenter.longitude
              )
            },
            {
              lat: parseFloat(
                test.b_end_port_id === undefined
                  ? test.b_end_FCR_id.datacenter.latitude
                  : test.b_end_port_id.datacenter.latitude
              ),
              lng: parseFloat(
                test.b_end_port_id === undefined
                  ? test.b_end_FCR_id.datacenter.longitude
                  : test.b_end_port_id.datacenter.longitude
              )
            }
          ]}
          onClick={() => handleActivePolyline(test.display_connection_id)}>
          {activeMarker === test.display_connection_id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                {test.name},{test.conn_type} <br /> A_end :
                {test.a_end_port_id === undefined
                  ? test.a_end_FCR_id.name
                  : test.a_end_port_id.name}{' '}
                , B_end :
                {test.b_end_port_id === undefined
                  ? test.b_end_FCR_id.name
                  : test.b_end_port_id.name}{' '}
              </div>
            </InfoWindow>
          ) : null}
        </Polyline>
      ))}
    </GoogleMap>
  );
}
