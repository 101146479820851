import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFTextField
} from '../../../../../components/hook-form';
import { updateClientById } from '../../../../../services/clientService';
import Popup from '../../../Popup';
import Message from '../../../../../components/Message';

const phoneRegExp = /^([+]\d{2})?\d{10}$/;

const validationSchema = yup.object().shape({
  clientName: yup.string().required('This is a required field'),
  headQuarter: yup.string().required('This is a required field'),
  BusinessEmail: yup
    .string()
    .email('Not a valid email')
    .required('This field is required'),
  techEmail: yup
    .string()
    .email('Not a valid email')
    .required('This field is required'),
  telephone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
});

const EditClientPopup = ({
  openEditPopup,
  setOpenEditPopup,
  clientData,
  classes
}) => {
  const initialValues = {
    clientName: '',
    headQuarter: '',
    BusinessEmail: '',
    techEmail: '',
    telephone: ''
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      client_name: data?.clientName,
      head_quarters: data?.headQuarter,
      bussiness_contact_email: data?.BusinessEmail,
      technical_contact_email: data?.techEmail,
      telephone_contact: data?.telephone
    };

    const res = await updateClientById(clientData?._id, payload);
    if (res?.status === 200) {
      Message.success('Updated Successfully');
      reset();
      setOpenEditPopup(false);
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else {
        Message.error('Something went wrong');
      }
      reset();
      setOpenEditPopup(false);
    }
  };

  useEffect(() => {
    if (clientData && Object.keys(clientData)?.length) {
      setValue('clientName', clientData?.client_name);
      setValue('headQuarter', clientData?.head_quarters);
      setValue('BusinessEmail', clientData?.bussiness_contact_email);
      setValue('techEmail', clientData?.technical_contact_email);
      setValue('telephone', clientData?.telephone_contact);
    }
  }, [clientData, setValue]);

  return (
    <>
      <Popup
        title="Edit Client Data"
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container direction="column" spacing={3}>
              <Grid item container spacing={3}>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="clientName"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Headquarter
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="headQuarter"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Business Email
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="BusinessEmail"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Technical Email
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="techEmail"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Telephone
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="telephone"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setOpenEditPopup(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default EditClientPopup;
