import React, { useEffect, useState } from 'react';
import {useLazyQuery} from '@apollo/react-hooks';
import Message from '../../../../../components/Message';
import {GET_MEMBERS} from '../../queries';
import Typography from '@material-ui/core/Typography';
import MemberList from '../MemberList';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import DNS from '@material-ui/icons/Dns';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import PeopleIcon from '@material-ui/icons/People';
import { CircularProgress } from "@material-ui/core";

const OrganisationPanel = ({
  t,
  orgList,
  handleOrgItemClick,
  handlePageChange,
  page,
  isLoading
}) => {
  
  const commonClasses = commonStyles();
  const [isMemberDialogEnable, setIsMemberDialogEnable] = useState(false);
  const [memberListLoading, setMemberListLoading] = useState(false);
  const [memberList, setMemberList] = useState(null);

const [getMembersListByOrganization] = useLazyQuery(GET_MEMBERS, {
  onError: error => {
      Message.error(error);
      setMemberListLoading(false);
  },
  onCompleted: data => {
      setMemberList(data.usersOfAnOrganization);
      setMemberListLoading(false);
  }
});

  const handleClose = () => {
    setIsMemberDialogEnable(false);
  };

  const columns = [
    {
      name: "id",
      label: t('id.label'),
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "name",
      label: t('name.label'),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant={'subtitle1'}
              color={'textPrimary'}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                handleOrgItemClick(tableMeta.rowData[0], value, "")
              }
            >
              {value || '-'}
            </Typography>
          )
        }
      }
    },
    {
      name: "country",
      label: t('country.label'),
      options: {
        filter: true
      }
    },
    {
      name: "subscriptions",
      label: t('subscription.label'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value ? (
            <Grid 
              className={commonClasses.column}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                handleOrgItemClick(tableMeta.rowData[0], tableMeta.rowData[1], "")
              }
            >
              <Grid item >
                <Icon
                  component={() => (
                    <DNS
                      color={'secondary'}
                      style={{ marginTop: '2px', marginRight: '6px' }}
                    />
                  )}
                  alt={'icon'}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant={'subtitle1'}
                  color={"secondary"}>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          ) : "-";
        }
      }
    },
    {
      name: "pendingSubscriptions",
      label: t('pendingsubscriptions.label'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value ? (
            <Grid 
            className={commonClasses.column}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              handleOrgItemClick(tableMeta.rowData[0], tableMeta.rowData[1], "")
            }
            >
              <Grid item>
                <Icon
                  component={() => (
                    <DNS
                      color={'secondary'}
                      style={{ marginTop: '2px', marginRight: '6px' }}
                    />
                  )}
                  alt={'icon'}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant={'subtitle1'}
                  color={"secondary"}>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          ) : "-";
        }
      }
    },
    {
      name: "deletedSubscriptions",
      label: t('dltsubscriptions.label'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value ? (
            <Grid 
            className={commonClasses.column}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              handleOrgItemClick(tableMeta.rowData[0], tableMeta.rowData[1], "")
            }>
              <Grid item>
                <Icon
                  component={() => (
                    <DNS
                      color={'secondary'}
                      style={{ marginTop: '2px', marginRight: '6px' }}
                    />
                  )}
                  alt={'icon'}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant={'subtitle1'}
                  color={"secondary"}>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          ) : null;
        }
      }
    },
    {
      name: "noOfUsers",
      label: t('members.label'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid 
              className={commonClasses.column}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setMemberListLoading(true);
                getMembersListByOrganization({
                  variables:{
                    orgId:tableMeta.rowData[0] 
                  }
                })
                setIsMemberDialogEnable(true);
              }}
            >
              <Grid item>
                <Icon
                  component={() => (
                    <PeopleIcon
                      color={'secondary'}
                      style={{ marginTop: '2px', marginRight: '6px' }}
                    />
                  )}
                  alt={'icon'}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant={'subtitle1'}
                  color={"secondary"}>
                  View
                </Typography>
              </Grid>
            </Grid>
          )
        }
      }
    },
    {
      name: "subsNames",
      label: "subsNames",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
  ];

  const options = {
    filter: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: 'standard',
    rowsPerPage: 10,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false
  };

  return (
    <>
    <MUIDataTable
      title={
        <Typography variant="h6">
          {isLoading && (
            <CircularProgress
              size={24}
              style={{ marginLeft: 15, position: "relative", top: 4 }}
            />
          )}
        </Typography>
      }
      data={orgList}
      t
      columns={columns}
      options={options}
      too
    />
    <MemberList
    t={t}
    isMemberDialogEnable={isMemberDialogEnable}
    memberList={memberList}
    memberListLoading={memberListLoading}
    handleClose={handleClose}
    />
    </>
  );
};

OrganisationPanel.propTypes = {
  orgList: PropTypes.array.isRequired,
  handleOrgItemClick: PropTypes.func.isRequired,
  search: PropTypes.bool
};

export default OrganisationPanel;
