import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@material-ui/core';
import Popup from '../../Popup';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import { Tooltip as ReChartsTooltip } from 'recharts';
import {
  dummyGraphData60,
  dummyGraphData7
} from '../../../../utils/staticGraph';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload?.length) {
    return (
      <div
        style={{
          border: '1px solid red',
          borderRadius: '8%',
          backgroundColor: '#661723',
          color: '#fff',
          fontSize: '13px',
          padding: '3px'
        }}>
        {payload &&
          payload.map((item, i) => (
            <div key={i}>
              <p className="label">{`Date/Time : ${new Date(
                item.payload._source['@timestamp']
              ).toLocaleString()}`}</p>
              <p className="label">{`${item.name} : ${item.value}`}</p>
            </div>
          ))}
      </div>
    );
  }

  return null;
};

const FcrsStatsPopup = ({
  openStatsPopup,
  setOpenStatsPopup,
  metricType,
  setMetricType,
  chartData,
  loader,
  dateFilter,
  setDateFilter,
  classes
}) => {
  const handleCloseStatsPopup = () => {
    setOpenStatsPopup(false);
    setMetricType('Mbps');
    setDateFilter(15);
  };
  return (
    <>
      <Popup
        title="Service Graph-Traffic"
        openPopup={openStatsPopup}
        setOpenPopup={handleCloseStatsPopup}
        width="md">
        <DialogContent
          style={{
            overflow: 'hidden',
            maxHeight: '400px',
            paddingLeft: '1.5em'
          }}>
          <Grid container direction="column" spacing={2}>
            <Grid item container>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '20px'
                }}>
                <span style={{ fontSize: 'small' }}>Metric Type</span>
                <div>
                  <TextField
                    select
                    value={metricType}
                    onChange={e => setMetricType(e.target.value)}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    size="small">
                    {[
                      { key: 'Mbps', value: 'Mbps' },
                      { key: 'Packets', value: 'packets' },
                      { key: 'Errors', value: 'errors' }
                    ].map(item => (
                      <option key={item.key} value={item.value}>
                        {item.key}
                      </option>
                    ))}
                  </TextField>
                </div>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px'
                }}>
                <span style={{ fontSize: 'small' }}>Date Range</span>
                <div>
                  <TextField
                    select
                    value={dateFilter}
                    onChange={e => setDateFilter(parseInt(e.target.value))}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    size="small">
                    {[
                      { key: 'Last 15 Minutes', value: 15 },
                      { key: 'Last 1 Hour', value: 60 }
                    ].map(item => (
                      <option key={item.key} value={item.value}>
                        {item.key}
                      </option>
                    ))}
                  </TextField>
                </div>
              </Box>
            </Grid>
            {loader && (
              <Grid item container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
            {!loader && chartData?.length !== 0 && (
              <Grid item>
                <LineChart
                  width={900}
                  height={350}
                  data={chartData}
                  margin={{
                    top: 15,
                    right: 35,
                    left: 10,
                    bottom: 50
                  }}>
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis
                    dataKey="_source.@timestamp"
                    tickFormatter={unixTime =>
                      dateFilter === 15 || dateFilter === 60
                        ? moment(unixTime).format('hh:mm')
                        : moment(unixTime).format('DD/MM')
                    }
                    angle={-45}
                    interval={
                      dateFilter === 15 ? 0 : dateFilter === 60 ? 3 : null
                    }
                    tickCount={
                      dateFilter === 15 ? 15 : dateFilter === 60 ? 20 : null
                    }
                    dy={20}
                    type={chartData?.length > 0 ? 'category' : 'number'}
                    domain={[0, 'dataMax + 5']}>
                    <Label
                      stroke="#8884d8"
                      value="Date/Time"
                      position="insideBottom"
                      offset={-15}
                      dy={25}
                    />
                  </XAxis>
                  <Line
                    type="monotone"
                    dataKey="_source.interfaces.inBoundUtiliz"
                    stroke="#ea4436"
                    name="inBoundUtiliz"
                    activeDot={{ r: 8 }}
                  />

                  <Line
                    type="monotone"
                    dataKey="_score"
                    name="_score"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <YAxis
                    domain={[0, 'dataMax + 400']}
                    interval={0}
                    label={{
                      stroke: '#ea4436',
                      value: metricType,
                      angle: -90,
                      position: 'insideLeft'
                    }}
                  />
                  <ReChartsTooltip content={<CustomTooltip />} />
                </LineChart>
              </Grid>
            )}
            {!loader && chartData?.length === 0 && (
              <Grid item>
                <LineChart
                  width={900}
                  height={350}
                  margin={{
                    top: 15,
                    right: 35,
                    left: 10,
                    bottom: 50
                  }}
                  data={dateFilter === 15 ? dummyGraphData7 : dummyGraphData60}>
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis
                    dataKey="_source.@timestamp"
                    tickFormatter={unixTime =>
                      dateFilter === 15 || dateFilter === 60
                        ? moment(unixTime).format('hh:mm')
                        : moment(unixTime).format('DD/MM')
                    }
                    angle={-45}
                    interval={
                      dateFilter === 15 ? 0 : dateFilter === 60 ? 3 : null
                    }
                    tickCount={
                      dateFilter === 15 ? 15 : dateFilter === 60 ? 20 : null
                    }
                    dy={20}
                    type={'category'}
                    domain={[0, 'dataMax + 5']}>
                    <Label
                      stroke="#8884d8"
                      value="Date/Time"
                      position="insideBottom"
                      offset={-15}
                      dy={25}
                    />
                  </XAxis>
                  <Line
                    type="monotone"
                    dataKey="_source.interfaces.inBoundUtiliz"
                    stroke="#ea4436"
                    name="inBoundUtiliz"
                    activeDot={{ r: 8 }}
                  />

                  <YAxis
                    type="number"
                    domain={[0, 'dataMax + 10']}
                    interval={0}
                    angle={-45}
                    allowDataOverflow={true}
                    label={{
                      stroke: '#ea4436',
                      value: metricType,
                      angle: -90,
                      position: 'insideLeft'
                    }}
                  />
                  <ReChartsTooltip content={<CustomTooltip />} />
                </LineChart>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={handleCloseStatsPopup}>
            Close
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default FcrsStatsPopup;
