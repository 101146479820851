import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../theme/commonStyles';
import clsx from 'clsx';
import { STATUS_TYPES } from '../../../../utils/constants';
import { paddingGenerator } from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  ticketStatusContainer: {
    height: '15px',
    borderRadius: '16px',
    alignItems: 'center',
    display: 'flex'
  },
  openTicket: {
    background: theme.palette.primary.main
  },
  closedTicket: {
    background: theme.palette.primary.light
  },
  textStyle: {
    color: theme.palette.common.white
  }
}));

const TicketStatus = ({ t, status }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <div
      className={clsx(
        classes.ticketStatusContainer,
        (status === STATUS_TYPES.NEW || status === STATUS_TYPES.HOLD || status === STATUS_TYPES.OPEN || status === STATUS_TYPES.INPROGRESS) ? classes.openTicket : classes.closedTicket
      )}
      style={{
        ...paddingGenerator('pt-4'),
        ...paddingGenerator('pb-5'),
        ...paddingGenerator('pl-12'),
        ...paddingGenerator('pr-12')
      }}>
      <Typography
        className={clsx(commonClasses.buttonTextSmall, classes.textStyle)}
        variant={'body1'}>
        {status === STATUS_TYPES.NEW ? t('new.label') : status === STATUS_TYPES.HOLD ? t('hold.label') : status === STATUS_TYPES.INPROGRESS ? t('inProgress.label') : status === STATUS_TYPES.OPEN ? t('open.label') : t('close.label')}
      </Typography>
    </div>
  );
};

TicketStatus.propTypes = {
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default TicketStatus;
