import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Popup from '../../Popup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updateRegion } from '../../../../services/regionService';
import Message from '../../../../components/Message';

const validationSchema = yup.object().shape({
  regionName: yup
    .string()
    .typeError('Required')
    .required('Required'),
  regionCode: yup
    .string()
    .typeError('Required')
    .required('Required')
});

const EditRegionPopup = ({
  openEditRegionPopup,
  setOpenEditRegionPopup,
  rowData,
  classes
}) => {
  const initialValues = { regionName: '', regionCode: '' };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async values => {
    const payload = {
      region_code: values?.regionCode
    };
    const res = await updateRegion(rowData?._id, payload);
    console.log(res);
    if (res?.status === 200) {
      Message.success('Region Updated Succesfully');
      setOpenEditRegionPopup(false);
      reset();
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else {
        Message.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (rowData) {
      setValue('regionName', rowData?.region_name);
      setValue('regionCode', rowData?.region_code);
    }
  }, [rowData, setValue]);

  return (
    <>
      <Popup
        title="Update Region"
        openPopup={openEditRegionPopup}
        setOpenPopup={setOpenEditRegionPopup}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid item container alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Region Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="regionName"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '10px' }}>
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Region Code
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      variant="outlined"
                      name="regionCode"
                      autoComplete="off"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setOpenEditRegionPopup(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Update
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default EditRegionPopup;
