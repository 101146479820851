import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import Image from "../../../../assets/icons/image.png";
import Close from "@material-ui/icons/Close";
import ProjectFilesNigeriaPanel from "../ProjectFilesNigeriaPanel";
import OpportunityFilesNigeriaPanel from "../OpportunityFilesNigeriaPanel";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_NIGERIA_OPPORTUNITY_FILE, DELETE_NIGERIA_PROJECT_FILE} from "../../mutations";
import Message from "../../../../components/Message";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function AddOpportunityDocumentsModal({
                                          t,
                                          open,
                                          handleSaveAddDocument,
                                          handleClose,
                                          data,
                                          list,
                                          loading,
                                          reloadProjectFile,
                                          modalState
                                      }) {
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const [opportunityFile, setOpportunityFile] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [opportunityFileData, setOpportunityFileData] = useState({});
    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        setFileName(img && img.name)
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            console.log(reader.result);
            setOpportunityFile(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setUploadedFile({name: ''})
            handleClose();
        }
    }, [modalState]);
    /**
     * Delete Opportunity File
     * */
    const [deleteOpportunityFile] = useMutation(DELETE_NIGERIA_OPPORTUNITY_FILE, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            reloadProjectFile('opportunityFile')
            Message.success('Opportunity File Deleted successfully.');
        }
    });
    const handleMenuItemClick = (action, data) => {
        if (action.label === 'Delete') {
            if (window.confirm('Are you sure? you want to delete this file!')) {
                return deleteOpportunityFile({
                    variables: {
                        id: data.uid
                    }
                });
            }
        }
        if (action.label === 'Edit') {
            console.log(data, 'data')
            if (data.uid) {
                setOpportunityFileData(data)
                setUploadedFile({name: data.file_name})
            }
        }
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')} Supporting Document
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will add supporting documents for an opportunity.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        opportunity_file: opportunityFile,
                        file_name: fileName,
                        comment: opportunityFileData && opportunityFileData.comment
                    }}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        const requestPayload = {
                            fileId: opportunityFileData && opportunityFileData.uid,
                            opportunityId: "",
                            opportunity_file: opportunityFile,
                            file_name: fileName,
                            comment: values.comment

                        }
                        handleSaveAddDocument(requestPayload)
                        resetForm()
                        //alert(JSON.stringify(values));
                    }}
                    validationSchema={Yup.object().shape({
                        comment: Yup.string()
                            .required('Please enter Comments')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                                <Button
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    onClick={handleFileUploadClick}
                                                >
                                                    {`${t('opportunityFile.label')}`}
                                                </Button>
                                                {uploadedFile && (
                                                    <Chip
                                                        avatar={<img src={Image} alt={'file'}/>}
                                                        label={
                                                            uploadedFile.name.length > 12
                                                                ? `${uploadedFile.name.slice(0, 12)}...`
                                                                : uploadedFile.name
                                                        }
                                                        onDelete={() => {
                                                            setUploadedFile('');
                                                            setOpportunityFile('');
                                                        }}
                                                        deleteIcon={<Close/>}
                                                        variant="outlined"
                                                    />
                                                )}
                                                <input
                                                    type="file"
                                                    id="attachment"
                                                    ref={inputFile}
                                                    onChange={event => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        setUploadedFile(event.target.files[0]);
                                                        convertToBase64(event.target.files[0]);
                                                    }}
                                                    style={{display: 'none'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={values.comment}
                                                    name={'comment'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.comment && touched.comment) && errors.comment}
                                                    placeholder={`${t('comment.label')}*`}
                                                    style={{width: '100%'}}
                                                    color={'secondary'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
                <OpportunityFilesNigeriaPanel
                    t={t}
                    list={list}
                    loading={loading}
                    handleMenuItemClick={handleMenuItemClick}
                    error={false}/>
            </DialogContent>
        </Dialog>
    );
}
AddOpportunityDocumentsModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSaveAddDocument: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(AddOpportunityDocumentsModal);
