import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../utils/constants';
import VideoRecorder from "react-video-recorder";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && "Upload"}
        </Button>
    );
}
const videoInitFields = { person: "", videoFile: null };

function CreateVideoRecordModal({
    t,
    open,
    handleSubmit,
    handleClose,
    modalState
}) {


    const [videoFormState, setVideoFormState] = useState([{ ...videoInitFields }]);
    const classes = useStyles();

    React.useEffect(() => {
        if (modalState && modalState.clearData && open) {
            setVideoFormState([{ ...videoInitFields }]);
            handleClose();
        }
    }, [modalState]);

    const handleChange = (value, name) => {
        setVideoFormState({ ...videoFormState, [name]: value });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="sensor-template-title">
                Record Video
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Person"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={videoFormState.person}
                            onChange={evt => handleChange(evt.target.value, 'person')}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 30 }}>
                        <Typography color="primary" variant="subtitle1" style={{marginBottom: 12, fontWeight: 600}}>
                            Instructions :
                            After the recording started please make your head movements
                            Eg : Turn to right and then to Left,
                            Also make some eye blinks to accurately check the Liveliness.
                        </Typography>
                        <div style={{ width: "100%", height: 400 }}>
                            <VideoRecorder
                                timeLimit={10000}
                                isOnInitially
                                isFlipped
                                constraints={{
                                    audio: true,
                                    video: {
                                        width: { exact: 580, ideal: 580 },
                                        height: { exact: 640, ideal: 640 },
                                        aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                                        resizeMode: "crop-and-scale"
                                    }
                                }}
                                onRecordingComplete={(videoBlob) => {
                                    // Do something with the video...
                                    const videoFile = new File(
                                        [videoBlob],
                                        `${new Date().getTime()}-video.mp4`,
                                        { type: 'video/mp4' }
                                    );
                                    setVideoFormState({ ...videoFormState, videoFile });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setVideoFormState({ ...videoInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!videoFormState.videoFile || !videoFormState.person) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmit(videoFormState);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateVideoRecordModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateVideoRecordModal);