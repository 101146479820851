import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import FundsApplicationPanelItem from './FundsApplicationPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import FundsApplicationFormModal from './FundsApplicationFormModal';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import {
    GET_FUNDS_APPLICATION } from '../../queries';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_FUNDS_APPLICATION, DELETE_FUNDS_APPLICATION } from '../../mutation';


const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function FundsApplicationForm({
    t,
    allowConfigure,
    data,
    subscriptionUserData,
    menuItems,
    users,
    departments
}) {
    const classes = useStyles();
    const [filterStateData, setFilterStateData] = useState({...initFilterState});
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedFundsApplicationItem, setSelectedFundsApplicationItem] = useState(null);
    const [FundsApplicationFormModalOpen, setFundsApplicationFormModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({originatorSign: "", sectionHeadSign: "", HoDSign: "",
    assistantAccountantSign: "", costAccountantSign:"", financeManagerSign: "", commercialManagerSign: "", generalManagerSign: "", deputyManagingDirectorSign:""});

    const subscriptionId = data.id;

    const [getAllFundsApplications, {
        loading,
        data: FundsApplicationList
    }] = useLazyQuery(GET_FUNDS_APPLICATION, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllFundsApplications({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        if(filterStateData.startDate && filterStateData.endDate){
        getAllFundsApplications({variables: {...filterStateData, subscriptionId}});
        }else{
            getAllFundsApplications({variables: {...filterStateData, subscriptionId}});
        }
    }, [filterStateData, subscriptionId]);

    const [createFundsApplication, { loading: createFundsApplicationLoading }] = useMutation(CREATE_FUNDS_APPLICATION, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Funds Application created successfully');
            setFundsApplicationFormModalOpen(false);
            setCRModalState({clearData: true, btnLoading: false});
            setSelectedFundsApplicationItem(null);
            setSignaturePadModalOpen(false);
            setSignatureType("");
            return getAllFundsApplications({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteFundsApplication, { loading: deleteFundsApplicationLoading }] = useMutation(DELETE_FUNDS_APPLICATION, {
        onError: error => { 
            console.log("error ", error);
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Funds Application deleted successfully');
            setFundsApplicationFormModalOpen(false);
            return getAllFundsApplications({variables: {...filterStateData, subscriptionId}});
        }
    });

    const  handleFundsApplicationSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createFundsApplication({ variables: {
            ...formData
        }});
    }

    const columns = [

        {
            label: 'Project Title',
            key: "userProjectTitle"
        },
        {
            label: "Subject",
            key: "userSubject"
        },
        {
            label: 'Cost Allocation',
            key: "userCostAllocation"
        },
        {
            label: 'Application',
            key: "application"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];


    
    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }
    
    const filterList = [
        {
            label: t("search.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Status',
            handleChange: evt => {

            },
            val: filterStateData.status,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: []
        }
    ];

    const handleNewFundsApplication= () => {
        setFundsApplicationFormModalOpen(true);
        setSelectedFundsApplicationItem(FundsApplicationList);
    }

    const handleMenuItemClick = (type, rowItem) => {
        console.log("foobar == ", rowItem._id);
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedFundsApplicationItem(rowItem);
        if(type === "sign"){
            setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setFundsApplicationFormModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteFundsApplication({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }
    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewFundsApplication}
                        >
                            {"Add Application"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            
            <Grid container>
            
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                        <TableCell key={index} style={item.style ? item.style : null} >
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {!loading ? FundsApplicationList && FundsApplicationList.getAllFundsApplications && FundsApplicationList.getAllFundsApplications.data && FundsApplicationList.getAllFundsApplications.data.length > 0 ?
                                FundsApplicationList.getAllFundsApplications.data.map((rowitem, rowIndex) => (
                                    <FundsApplicationPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        handleNewFundsApplication={handleNewFundsApplication}
                                        users={subscriptionUserData}
                                    />
                                )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow>
                     : null
                    }
                    {loading && 
                    <TableRow>
                    <TableCell colSpan={columns.length}>
                        <div style={{textAlign: "center", width: "100%"}}>
                            <CircularProgress size={20} color="primary" />
                        </div>
                    </TableCell>
                </TableRow>
            }
        </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        <FundsApplicationFormModal
                open={FundsApplicationFormModalOpen}
                handleClose={evt => {
                    setFundsApplicationFormModalOpen(false);
                    setSelectedFundsApplicationItem(null);
                }}
                modalState={{...crModalState, btnLoading: createFundsApplicationLoading}}
                handleFundsApplicationSubmit={handleFundsApplicationSubmit}
                subscriptionUserData={subscriptionUserData}
                data={selectedFundsApplicationItem}
                handleOpenSignPanModal={handleOpenSignPanModal}
            />

            <CanvasSignaturePadModal 
              open={signaturePadModalOpen} 
              handleClose={() => setSignaturePadModalOpen(false)} 
              handleSaveSignature={(image) => {
                  setSignaturePadModalOpen(false);
                  return createFundsApplication({
                      variables: {
                          id: selectedFundsApplicationItem.id,
                          sign: image,
                          subscriptionId: subscriptionId
                      }
                  });
              }}
            />
        </div>
    );
}

FundsApplicationForm.propTypes = {
    t: PropTypes.func.isRequired
};
export default FundsApplicationForm;
