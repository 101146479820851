import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { ExportCSV } from './ExportCsv';
import { GET_SERVICE_TICKET_DETAILS_BY_ID } from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {
  Paths,
  ServiceTicketPaths,
  TicketingSystem
} from '../../routes/routePaths';
import { CircularProgress } from "@material-ui/core";
import CustomTabs from '../../components/CustomTabs';
import { dateFormatterLLL, getParam } from '../../utils';
import TicketDetailsPanel from './components/TicketDetailsPanel';
import { useLocation, withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import Chat from '@material-ui/icons/Chat';
import { marginGenerator } from '../../theme/utils';
import ProductList from './ProductList';
import TicketSubscriptions from './TicketSubscriptions';
import IconButton from '@material-ui/core/IconButton';
import commonStyles from '../../theme/commonStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { getUrl } from '../../utils';
import Chip from '@material-ui/core/Chip';
import Image from '../../assets/icons/image.png';
import ActivityList from '../../components/ActivityList';
import { GET_USER_INFO, GET_SERVICETICKET_SETTINGS_LIST } from './queries';
import { UPDATE_SERVICE_TICKET, CLOSE_SERVICE_TICKET, UPDATE_MAIL_TICKET, UPDATE_CUSTOMER, ADD_PRODUCT_TO_TICKET } from './mutations';
import { UPLOAD_FILE } from '../commonMutations';
import Message from '../../components/Message';
import { STATUS_TYPES, USER_ROLES, COUNTRY_LIST } from '../../utils/constants';
import CloseTicketModal from './components/CloseTicketModal';
import TicketUpdateTitle from './components/TicketUpdateTitle';
import UpdateCustomer from './components/UpdateCustomer';

const useStyles = makeStyles((theme) => ({
  commentBar: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 0),
    maxWidth: '1100px'
  },
  commentContainer: {
    bottom: 0,
    left: 0,
    borderBottom: '1px solid #eaeaea',
    borderTop: '1px solid #eaeaea',
    ...(window.innerWidth < 1100 && { padding: 24 })
  },
  exportBtn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  updateBtn: {
    marginRight: '10px'
  }
}));


const TicketDetails = ({ t, history }) => {
  const currentUrl = window.location.href;
  const country = localStorage.getItem('country');
  let role = localStorage.getItem('role');
  const id = getParam();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showCloseTicketModal, setShowCloseTicketModal] = useState(false);
  const [showUpdateTicketModal, setShowUpdateTicketModal] = useState(false);
  const [showUpdateCustomerModal, setShowUpdateCustomerModal] = useState(false);
  const [comment, setComment] = useState('');
  const [rfoList, setRfoList] = useState([]);
  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const classes = useStyles();
  const commonClasses = commonStyles();

  let [breadcrumbList, setBreadcrumbList] = useState([
    { label: 'home.label', link: Paths.Dashboard },
    { label: 'serviceTickets.label', link: Paths.ServiceTickets },
    { label: 'ticketsDetails.label', link: ServiceTicketPaths.Details }
  ]);

  const { loading, error, data, refetch: reloadServiceTicket } = useQuery(
    GET_SERVICE_TICKET_DETAILS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: { id }
    }
  );

  const {
    data: userInfo,
    loading: userInfoLoading,
    error: userInfoError
  } = useQuery(GET_USER_INFO);

  let userInfoAttributes = data && data.getServiceTicketById && data.getServiceTicketById.userInfo &&
    data.getServiceTicketById.userInfo.attributes != ''
    ? JSON.parse(data.getServiceTicketById.userInfo.attributes)
    : [];

  const ticketData = !loading && !error && data.getServiceTicketById;
//  console.log(ticketData?.emailCC,"senderEmailCC") ;
// ticketData?.emailCC.map(item => console.log(item,"item"))


  const reloadTickets = () => {
    reloadServiceTicket();
  };

 

  const userData = !userInfoLoading && !userInfoError && userInfo.userInfo;

  useEffect(() => {
    if (location.search === `?history=dashboard`) {
      breadcrumbList = breadcrumbList.filter((item, index) => index !== 1);
      setBreadcrumbList(breadcrumbList);
    }
    if (country === COUNTRY_LIST.CAMEROON) {
      getServiceTicketSettings({
        variables: {
          key: "rfo"
        }
      })
    }
    reloadServiceTicket();
  }, [location]);

  const [getServiceTicketSettings] = useLazyQuery(GET_SERVICETICKET_SETTINGS_LIST, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data && data.getServiceTicketSettings && data.getServiceTicketSettings.length > 0) {
        setRfoList(data.getServiceTicketSettings)
      }
    }
  });

  const inputFile = React.createRef();
  const handleFileUploadClick = () => {
    inputFile.current.click();
  };

  // const [getOrganisationName] = useLazyQuery(GET_ORGANISATION_NAME, {
  //   onError: error => {
  //     console.log('Error', error);
  //   },
  //   onCompleted: data => {
  //     console.log('data', data);
  //   }
  // });

  // if (orgId) {
  //   console.log('In');
  //   getOrganisationName({
  //     variables: {
  //       id: orgId
  //     }
  //   });
  // }

  const [updateServiceTicket] = useMutation(UPDATE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      setComment('');
      setUploadedFile(null);
      reloadServiceTicket();
      Message.success('Message sent successfully.');
    }
  });

 

  const [updateMailServiceTicket] = useMutation(UPDATE_MAIL_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      setShowUpdateTicketModal(false)
      Message.success('Ticket Incident OutLine updated successfully.');
      reloadServiceTicket();
    }
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      setShowUpdateCustomerModal(false)
      Message.success('Update sent to customer successfully.');
      reloadServiceTicket();
    }
  });

  const [closeServiceTicket] = useMutation(CLOSE_SERVICE_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
      setButtonLoading(false);
      setShowCloseTicketModal(false);
      setClearModal(true);
      Message.success('Ticket closed successfully.');
      reloadServiceTicket();
    }
  });

  const handleCloseTicketAction = () => {
    if (ticketData.assignee === null) {
      Message.error('You cannot close UnAssigned tickets');
    } else {
      setShowCloseTicketModal(true);
    }
  };

  const handleSubmitCloseTicket = data => {
    setButtonLoading(true);
    return closeServiceTicket({
      variables: {
        id: ticketData.id,
        currentUrl: currentUrl,
        ticketCloseTime: data.endDate,
        closeDescription: data.description,
        rfo: data.rfo ? data.rfo.name : "",
        extraNote: data.extraNote,
        title: ticketData.title,
        mailThreadId: ticketData.mailThreadId,
        vendorEndDate: data.vendorCloseDate,
        equipemntReplaced: data.equipemntReplaced
      }
    });
  };

  const handleSubmitUpdateTicketTitle = data => {
    setButtonLoading(true);
    return updateMailServiceTicket({
      variables: {
        serviceTicketId: ticketData.id,
        title: data.title,
        mailThreadId: ticketData.ticketId
      }
    });
  }

  const handleSubmitUpdateCustomer = data => {
    setButtonLoading(true);
    return updateCustomer({
      variables: {
        title: data.title,
        description: data.description,
        email: data.email,
        allCCEmails: data.allCCEmails,
        ticketTitle: ticketData.title,
        ticketId: ticketData.ticketId,
        serviceTicketId: ticketData.id,
        createdOn: ticketData.createdAt,
        mailThreadId: ticketData.mailThreadId,
        userName: data.userName,
        attachments: data.attachments
      }
    });
  }

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      return updateServiceTicket({
        variables: {
          serviceTicketId: ticketData.id,
          title: ticketData.title,
          mailThreadId: ticketData.ticketId,
          attachments: [data.uploadDocument.url],
          ...(comment ? { logMessage: comment } : {})
        }
      });
    },
    onError: e => {
      console.log(e);
    }
  });

  const prepareUpload = () => {
    return uploadFile({
      variables: {
        file: uploadedFile
      }
    });
  };

  const fileName = 'TicketDetail';

  const viewers = [
    {
      id: ticketData && ticketData.id,
      title: ticketData && ticketData.title,
      description: ticketData && ticketData.description,
      abujaNGData:
        ticketData &&
        ticketData.abujaNGData &&
        ticketData.abujaNGData.memberName,
      additionalAgent1:
        ticketData &&
        ticketData.additionalAgent1 &&
        ticketData.additionalAgent1.firstName,
      additionalAgent2:
        ticketData &&
        ticketData.additionalAgent2 &&
        ticketData.additionalAgent2.firstName,
      assignee: ticketData && ticketData.assignee && ticketData.assignee,
      baseStation:
        ticketData &&
        ticketData.baseStation &&
        ticketData.baseStation.ulCTCBaseStationCode,
      createdAt: ticketData && ticketData.createdAt,
      createdBy: ticketData && ticketData.createdBy,
      createdByName: ticketData && ticketData.createdByName,
      customer: ticketData && ticketData.customer,
      escalationLevel: ticketData && ticketData.escalationLevel,
      incidentCause: ticketData && ticketData.incidentCause,
      incidentType: ticketData && ticketData.incidentType,
      incidentTypeGroup: ticketData && ticketData.incidentTypeGroup,
      isParent: ticketData && ticketData.isParent,
      kitweZMData:
        ticketData &&
        ticketData.kitweZMData &&
        ticketData.kitweZMData.memberName,
      lagosNGData:
        ticketData &&
        ticketData.lagosNGData &&
        ticketData.lagosNGData.memberName,
      linkType: ticketData && ticketData.linkType,
      livingStoneZMData:
        ticketData &&
        ticketData.livingStoneZMData &&
        ticketData.livingStoneZMData.memberName,
      location:
        ticketData && ticketData.location && ticketData.location.ulCTCLocation,
      lusakaZMData:
        ticketData &&
        ticketData.lusakaZMData &&
        ticketData.lusakaZMData.memberName,
      natureOfCase: ticketData && ticketData.natureOfCase,
      ndolaZMData:
        ticketData &&
        ticketData.ndolaZMData &&
        ticketData.ndolaZMData.memberName,
      phcNGData:
        ticketData && ticketData.phcNGData && ticketData.phcNGData.memberName,
      priority: ticketData && ticketData.priority,
      projectCategory:
        ticketData && ticketData.projectCategory && ticketData.projectCategory,
      reportComment:
        ticketData && ticketData.reportComment && ticketData.reportComment,
      reportComment2:
        ticketData && ticketData.reportComment2 && ticketData.reportComment2,
      reportComment3:
        ticketData && ticketData.reportComment3 && ticketData.reportComment3,
      reportComment4:
        ticketData && ticketData.reportComment4 && ticketData.reportComment4,
      reportComment5:
        ticketData && ticketData.reportComment5 && ticketData.reportComment5,
      salesSpecilistData:
        ticketData &&
        ticketData.salesSpecilistData &&
        ticketData.salesSpecilistData.memberName,
      severity: ticketData && ticketData.severity,
      solweziZMData:
        ticketData &&
        ticketData.solweziZMData &&
        ticketData.solweziZMData.memberName,
      status: ticketData && ticketData.status,
      subscription:
        ticketData &&
        ticketData.subscription &&
        ticketData.subscription.service?.name,
      ticketId: ticketData && ticketData.ticketId,
      vendor: ticketData && ticketData.vendor && ticketData.vendor?.name,
      vodacomProblem:
        ticketData && ticketData.vodacomProblem && ticketData.vodacomProblem
    }
  ];

  const handleUpdateTitleBtn = () => {
    setShowUpdateTicketModal(true)
  }

  const handleUpdateCustomerBtn = () => {
    setShowUpdateCustomerModal(true)
  }

  const handleReOpenBtnClick = () => {
    setButtonLoading(true)
    return updateMailServiceTicket({
      variables: {
        serviceTicketId: ticketData.id,
        status: STATUS_TYPES.OPEN,
        ticketCloseTime: null,
        closeDescription: "",
        extraNote: "",
        vendorEndDate: null,
        equipemntReplaced: "",
        title: ticketData.title,
        mailThreadId: ticketData.mailThreadId
      }
    });
  }

  return (
    <>
      {!loading && !error && (
        <Layout breadcrumbList={breadcrumbList}>
          <Header
            title={ticketData.title}
            subText={`${t('id.label').toUpperCase()} ${ticketData.ticketId
              } • ${userInfoAttributes &&
                userInfoAttributes != null &&
                userInfoAttributes.CircuitID && userInfoAttributes.CircuitID[0] ?
                t('circuitId.label').toUpperCase() + ":" + " " + userInfoAttributes.CircuitID[0]
                : ''}
              • ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
                  ticketData.createdAt
                )} ${ticketData.status == 'Closed' && ticketData.incidentEndTime != null ?
                  t('TicketClosedOn.label') + " " + dateFormatterLLL(
                    ticketData.incidentEndTime
                  ) : ""}`}
            primaryButtonText={
              ticketData.status != STATUS_TYPES.CLOSED ? !role.includes(USER_ROLES.SD_READONLY) && t('closeTicket.label') : null
            }
            handlePrimaryButtonClick={
              ticketData.status != STATUS_TYPES.CLOSED ? handleCloseTicketAction : null
            }
            secondaryButtonText={
              ticketData.ticketType === 'mailTicket' &&
                ticketData.status === 'Open'
                ? !role.includes(USER_ROLES.SD_READONLY) && t('updateTicket.label')
                : t('back.label')
            }
            handleSecondaryButtonClick={() => {
              const path = getUrl(TicketingSystem.Update, ticketData.id);
              const closePath = getUrl(TicketingSystem.TicketPortal);
              ticketData.ticketType === 'mailTicket' &&
                ticketData.status !== 'Closed'
                ? history.push(path)
                : history.push(closePath);
            }}
          />
        <div className={classes.exportBtn}>
  <Grid style={{ marginRight: '10px' }}>
    <TicketSubscriptions ticketData={ticketData} />
  </Grid>

  <Grid xs={6} md={4} style={{ marginRight: '10px' }}>
    <ProductList ticketData={ticketData} />
  </Grid>

  {!role.includes(USER_ROLES.SD_READONLY) &&
    (ticketData.status === STATUS_TYPES.OPEN || ticketData.status === STATUS_TYPES.INPROGRESS) && (
      <Button
        variant={'contained'}
        className={classes.updateBtn}
        color={'primary'}
        onClick={handleUpdateTitleBtn}
      >
        {t('updateTitle.label')}
      </Button>
    )}

  {!role.includes(USER_ROLES.SD_READONLY) &&
    (ticketData.status === STATUS_TYPES.OPEN || ticketData.status === STATUS_TYPES.INPROGRESS) &&
    country !== 'null' &&
    (country === COUNTRY_LIST.BOTSWANA ||
      country === COUNTRY_LIST.MALAWI ||
      country === COUNTRY_LIST.EDGEDOCK
      || country === COUNTRY_LIST.NIGERIA
      ) && (
      <Button
        variant={'contained'}
        className={classes.updateBtn}
        color={'primary'}
        onClick={handleUpdateCustomerBtn}
      >
        {t('updateCustomer.label')}
      </Button>
    )}

  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status === STATUS_TYPES.CLOSED && (
    <Button
      variant={'contained'}
      className={classes.updateBtn}
      color={'primary'}
      onClick={handleReOpenBtnClick}
    >
      {buttonLoading ? (
        <CircularProgress size={14} color="inherit" />
      ) : (
        t('reOpenTicket.label')
      )}
    </Button>
  )}

  <ExportCSV csvData={viewers} fileName={fileName} />
</div>

         
          <CustomTabs
            tabs={[
              {
                label: t('details.label'),
                children: (
                  <TicketDetailsPanel
                    ticketData={ticketData}
                    userData={userData}
                    reloadTickets={reloadTickets}
                    t={t}
                  />
                )
              },
              {
                label: t('activity.label'),
                children: <ActivityList list={ticketData.activityLogs} />
              },
            ]}

          />

          <Grid
            container
            className={classes.commentContainer}
            justify={'center'}>
            {/* <Divider
              variant={'fullWidth'}
              absolute
              style={{ bottom: 'auto' }}
            /> */}
            <Grid
              container
              item
              className={classes.commentBar}
              justify={'space-between'}>
              <Grid container item xs={7}>
                <Grid item style={marginGenerator('mr-16')}>
                  <Chat />
                </Grid>
                <Grid item xs={11}>
                  <InputBase
                    fullWidth
                    value={comment}
                    id="comment-textarea"
                    placeholder="Add a ticket note, comment or an update"
                    multiline
                    onChange={evt => setComment(evt.target.value)}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid item>
                {uploadedFile && (
                  <Chip
                    avatar={<img src={Image} alt={'file'} />}
                    label={
                      uploadedFile?.name?.length > 12
                        ? `${uploadedFile?.name?.slice(0, 12)}...`
                        : uploadedFile?.name
                    }
                    onDelete={() => {
                      setUploadedFile('');
                    }}
                    deleteIcon={<Close />}
                    variant="outlined"
                  />
                )}
                <IconButton
                  size={'medium'}
                  disableRipple={true}
                  disableFocusRipple={true}
                  color={'secondary'}
                  onClick={handleFileUploadClick}
                  style={marginGenerator('ml-16')}>
                  <AttachFile className={commonClasses.iconRegular} />
                </IconButton>
                <input
                  type="file"
                  id="attachment"
                  ref={inputFile}
                  onChange={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    setUploadedFile(event.target.files[0]);
                  }}
                  style={{ display: 'none' }}
                />
                <Button
                  variant={'contained'}
                  disabled={(!comment && !uploadedFile) || uploading}
                  color={'primary'}
                  // TODO: Call update ticket mutation
                  onClick={() =>
                    uploadedFile
                      ? prepareUpload()
                      : updateServiceTicket({
                        variables: {
                          serviceTicketId: ticketData.id,
                          title: ticketData.title,
                          mailThreadId: ticketData.ticketId,
                          ...(comment ? { logMessage: comment } : {})
                        }
                      })
                  }
                  style={marginGenerator('ml-24')}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <CloseTicketModal
            open={showCloseTicketModal}
            handleClose={evt => setShowCloseTicketModal(false)}
            handleSubmitCloseTicket={handleSubmitCloseTicket}
            clearModal={clearModal}
            buttonLoading={buttonLoading}
            ticketData={ticketData}
            rfoList={rfoList.length > 0 ? rfoList : []}
            type="single"
            country={country}
          />
          <TicketUpdateTitle
            open={showUpdateTicketModal}
            handleClose={evt => setShowUpdateTicketModal(false)}
            handleSubmitUpdateTicketTitle={handleSubmitUpdateTicketTitle}
            buttonLoading={buttonLoading}
            ticketData={ticketData}
          />
          <UpdateCustomer
            open={showUpdateCustomerModal}
            handleClose={evt => setShowUpdateCustomerModal(false)}
            handleSubmitUpdateCustomer={handleSubmitUpdateCustomer}
            buttonLoading={buttonLoading}
            ticketData={ticketData}
          />
        </Layout>
      )}
    </>
  );
};
export default withTranslation()(withRouter(TicketDetails));
