import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import { checkValidEmail } from '../../../../../utils';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateFinanceTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    CurrentUserName,
    handleUploadFiles,
    handleProjectAttachmentClick,
    handleViewCompleteDetailClick,
    isActionUpdate
}) {
    const classes = useStyles();
    const [circuitId, setCircuitId] = useState('');
    const [mrc, setMrc] = useState('');
    const [oldMrc, setOldMrc] = useState('');
    const [nrc, setNrc] = useState('');
    const [frequencyOfBilling, setFrequencyOfBilling] = useState('');
    const [currency, setCurrency] = useState('');
    const [document, setDocument] = useState([]);
    const [sendEmail, setSendEmail] = useState('');
    const [csoc, setCsoc] = useState('');
    const [comment, setComment] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [documentDeleteLoading, setDocumentDeleteLoading] = useState(false);
    React.useEffect(() => {
        // if (modalState.clearData && open) {
        //     handleClose();
        // }
        if (modalState.clearData === true) {
            setCircuitId('');
            setMrc('');
            setOldMrc('');
            setNrc('');
            setFrequencyOfBilling('');
            setCurrency('');
            setDocument([]);
            setSendEmail('');
            setCsoc('');
            setComment('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
            setDocumentDeleteLoading(false);
        }
        if (isActionUpdate === true) {
            setCsoc(preValuesOfForm.csoc);
            setComment(preValuesOfForm.comment);
            setNextTeamMemberEmail(preValuesOfForm.next_team_member_email);
            setOnHold(preValuesOfForm.project_info && preValuesOfForm.project_info.status ? preValuesOfForm.project_info.status : '');
            setCategory(preValuesOfForm.project_info && preValuesOfForm.project_info.category ? preValuesOfForm.project_info.category : '');
        }
    }, [modalState, CurrentUserName, isActionUpdate]);

    React.useEffect(() => {}, [csoc, comment, onHold, category, nextTeamMemberEmail, preValuesOfForm])
    const handleDocuments = (index) => {
        setDocument(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }
    const handleUpdateDocuments = (index) => {
        setDocumentDeleteLoading(true);
        preValuesOfForm.docs.splice(index, 1);
        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
        // console.log("preValuesOfForm.docs : ", preValuesOfForm.docs);
    }
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{isActionUpdate === true ? t('update.label') : t('create.label')} {t('financeTeamSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : (preValuesOfForm.case_number ? preValuesOfForm.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.customer_name} / {preValuesOfForm.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewCompleteDetails.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={handleViewCompleteDetailClick}>{t('clickHereToViewCompleteDetails.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewAttachedDocuments.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={preValuesOfForm.previous_docs && preValuesOfForm.previous_docs.length == 0 ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleProjectAttachmentClick(preValuesOfForm.previous_docs);
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('changeControlForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.change_control_form ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.change_control_form != undefined) {
                                        window.open(preValuesOfForm.change_control_form);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('installationMannual.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.attach_manual ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.attach_manual != undefined) {
                                        window.open(preValuesOfForm.attach_manual);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('circuitId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.circuit_id}
                                type={'text'} disabled
                                placeholder={t('circuitId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCircuitId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : ''}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label')}
                                style={{ width: '100%' }}
                                disabled
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('configChangeForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.config_change_form ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.config_change_form != undefined) {
                                        window.open(preValuesOfForm.config_change_form);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('jobCompletionCertificate.label')}*</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.job_completion_certificate ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.job_completion_certificate != undefined) {
                                        window.open(preValuesOfForm.job_completion_certificate);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('faultInformationManagementMatrix.label')}*</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.fault_information_management_matrix ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.fault_information_management_matrix != undefined) {
                                        window.open(preValuesOfForm.fault_information_management_matrix);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('letterServiceDetails.label')}*</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.letter_service_details ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.letter_service_details != undefined) {
                                        window.open(preValuesOfForm.letter_service_details);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('pingResult.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.ping_result}
                                type={'text'} disabled
                                placeholder={t('pingResult.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('billing.label') + ' ' + t('startDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.billing_start_date}
                                type={'text'} disabled
                                placeholder={t('pingResult.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('inputFinanceDetails.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.finance_detail ? preValuesOfForm.finance_detail.mrc : ''}
                                type={'text'} disabled
                                placeholder={t('mrc.label')}
                                style={{ width: '48%', marginRight: '2%', marginBottom: '10px' }}
                                color={'secondary'}
                                onChange={evt => setMrc(evt.target.value)}
                            />
                            <Input
                                value={preValuesOfForm.finance_detail ? preValuesOfForm.finance_detail.old_mrc : ''}
                                type={'text'} disabled
                                placeholder={t('old.label') + ' ' + t('mrc.label')}
                                style={{ width: '50%' }}
                                color={'secondary'}
                                onChange={evt => setOldMrc(evt.target.value)}
                            />
                            <Input
                                value={preValuesOfForm.finance_detail ? preValuesOfForm.finance_detail.nrc : ''}
                                type={'text'} disabled
                                placeholder={t('nrc.label')}
                                style={{ width: '48%', marginRight: '2%', marginBottom: '10px' }}
                                color={'secondary'}
                                onChange={evt => setNrc(evt.target.value)}
                            />
                            <Input
                                value={preValuesOfForm.finance_detail ? preValuesOfForm.finance_detail.freq_of_billing : ''}
                                type={'text'} disabled
                                placeholder={t('monthly.label')}
                                style={{ width: '50%' }}
                                color={'secondary'}
                                onChange={evt => setFrequencyOfBilling(evt.target.value)}
                            />
                            <Input
                                value={preValuesOfForm.finance_detail ? preValuesOfForm.finance_detail.currency : ''}
                                type={'text'} disabled
                                placeholder={t('currency.label')}
                                style={{ width: '48%', marginRight: '2%' }}
                                color={'secondary'}
                                onChange={evt => setCurrency(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('sendEmail.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.send_email}
                                type={'text'} disabled
                                placeholder={t('sendEmail.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSendEmail(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("csocTeam.label")} *`}
                                val={csoc}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setCsoc(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('document.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {isActionUpdate === true ? <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={preValuesOfForm.docs && document && (document.length + preValuesOfForm.docs.length) >= 3 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && (document.length + preValuesOfForm.docs.length) < 3) {
                                        if (evt.target.files.length <= 3 && (document.length + evt.target.files.length + preValuesOfForm.docs.length) <= 3) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setDocument(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 3. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            /> : <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={document && document.length >= 3 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && document.length < 3) {
                                        if (evt.target.files.length <= 3 && (document.length + evt.target.files.length) <= 3) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setDocument(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 3. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            />}
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {document && document.length ?
                                document.map((item, index) => (
                                    <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleDocuments(index)} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.docs && preValuesOfForm.docs.length) ?
                                preValuesOfForm.docs.map((item, index) => (
                                    <Typography variant={'body2'}> <Button variant={'outlined'}
                                        disabled={!item.file ? true : false}
                                        style={{ marginBottom: 5 }}
                                        onClick={() => {
                                            if (item.file != undefined) {
                                                window.open(item.file);
                                            }
                                        }}><InsertDriveFileIcon></InsertDriveFileIcon> File {index + 1}</Button> <IconButton onClick={evt => handleUpdateDocuments(index)} aria-label="delete">
                                            <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={category}
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={onHold}
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('previousComment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {preValuesOfForm.previous_comment && preValuesOfForm.previous_comment.length ?
                                JSON.parse(preValuesOfForm.previous_comment).map((item, index) => (
                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (preValuesOfForm.circuit_id != '') {
                                if (isActionUpdate === true) {
                                    if (!csoc || (document && (document.length == 0) && preValuesOfForm.docs && (preValuesOfForm.docs.length == 0))) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                } else {
                                    if (!csoc || !document || document.length == 0 || !nextTeamMemberEmail) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                }
                                let getPreviousComment = preValuesOfForm.previous_comment ? JSON.parse(preValuesOfForm.previous_comment) : [];
                                if (comment != '') {
                                    if (isActionUpdate === true && preValuesOfForm.comment == comment) {
                                    } else {
                                        getPreviousComment.push({ name: CurrentUserName, comment: comment });
                                    }
                                }
                                let previous_comment = Object.entries(getPreviousComment).map(e => e[1]);
                                let getDocument = [];
                                if (document != [] && document != null && document.length > 0) {
                                    for (let i = 0; i < document.length; i++) {
                                        let getAttachDoc = await handleUploadFiles(document[i], preValuesOfForm.project, 'finance_team_section');
                                        if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                            getDocument.push(getAttachDoc.data.allUploadFile.url);
                                        }
                                        else {
                                            Message.error("File not uploaded! Please try again.");
                                        }
                                    }
                                }
                                if (isActionUpdate === true) {
                                    // merge document
                                    if (preValuesOfForm.docs && preValuesOfForm.docs.length > 0) {
                                        for (let i = 0; i < preValuesOfForm.docs.length; i++) {
                                            getDocument.push(preValuesOfForm.docs[i].file);
                                        }
                                    }
                                }
                                handleSubmitClick({
                                    formUid: isActionUpdate ? preValuesOfForm.uid : '',
                                    circuitId: preValuesOfForm.circuit_id,
                                    sendEmail: preValuesOfForm.send_email,
                                    finance_detail: preValuesOfForm.finance_detail,
                                    job_completion_certificate: preValuesOfForm.job_completion_certificate ? preValuesOfForm.job_completion_certificate : '',
                                    fault_information_management_matrix: preValuesOfForm.fault_information_management_matrix ? preValuesOfForm.fault_information_management_matrix : '',
                                    letter_service_details: preValuesOfForm.letter_service_details ? preValuesOfForm.letter_service_details : '',
                                    billing_start_date: preValuesOfForm.billing_start_date ? preValuesOfForm.billing_start_date : null,
                                    document: getDocument,
                                    csoc: csoc,
                                    comment: comment,
                                    formName: 'FinanceForm',
                                    project_uid: preValuesOfForm.project,
                                    change_control_form: preValuesOfForm.change_control_form,
                                    attach_manual: preValuesOfForm.attach_manual,
                                    config_change_form: preValuesOfForm.config_change_form,
                                    ping_result: preValuesOfForm.ping_result,
                                    previous_comment: JSON.stringify(previous_comment),
                                    equipments_to_be_deployed: preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : '',
                                    auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                                    next_team_member_email: nextTeamMemberEmail,
                                    category: category,
                                    on_hold: onHold,
                                });
                            } else {
                                if (!csoc || !circuitId || !mrc || !oldMrc || !nrc || !frequencyOfBilling || !currency || !document || !sendEmail) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                if (!checkValidEmail(sendEmail)) {
                                    Message.error(INVALID_EMAIL);
                                    return null;
                                }
                                handleSubmitClick({
                                    circuitId: circuitId,
                                    csoc: csoc,
                                    comment: comment,
                                    finance_detail: {
                                        mrc: mrc,
                                        old_mrc: oldMrc,
                                        nrc: nrc,
                                        freq_of_billing: frequencyOfBilling,
                                        currency: currency,
                                    },
                                    document: document,
                                    sendEmail: sendEmail,
                                    category: category,
                                    on_hold: onHold,
                                    formName: 'FinanceForm'
                                });
                            }
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateFinanceTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateFinanceTeamModal);
