import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Markup } from 'interweave';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  imageAlign: {
    width: '130px',
    height: 'auto',
    border: '2px dashed #661723'
  },
}));

export default function Accordions({
  description,
  createdAt,
  createdBy,
  panel,
  attachments,
  type,
  ticketType
}) {
  var control = `pane${panel}bh-content`;
  var id = `panel${panel}bh-header`;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
  }
  function convertBase64ToUrl(imgStr) {
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
      type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    return imgURL;
  }

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === `panel${panel}`}
        onChange={handleChange(`panel${panel}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={control}
          id={id}>
          <Typography className={classes.heading}>{createdBy}</Typography>
          <Typography className={classes.secondaryHeading}>
            {createdAt}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {description !== null ? <Markup content={description} /> : 'No record found'}
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
          {attachments && attachments.length > 0 &&
            attachments.map((item, index) => (
              <Grid item xs={2} style={{ marginRight: 8 }}>
                <Link
                  variant={'body2'}
                  color={'textPrimary'}
                  href={ticketType === 'mailTicket' ? type && type === 'serviceDesk' ? item : convertBase64ToUrl(item) : item}
                  target={'_blank'}>
                  <img
                    className={classes.imageAlign}
                    src={ticketType === 'mailTicket' ? type && type === 'serviceDesk' ? item : convertBase64ToUrl(item) : item}
                    href={ticketType === 'mailTicket' ? type && type === 'serviceDesk' ? item : convertBase64ToUrl(item) : item}
                    target="_blank"
                  />
                </Link>
              </Grid>
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
