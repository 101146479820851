import React from 'react';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

function GenericTable({ 
  t,
  columns,
  list,
  handleItemClick,
  loading,
  handleDeleteItem,
  handleDownloadClick,
  downloadLoading
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} style={item.style ? item.style : null} >
                <Typography variant={'subtitle2'}>{item.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? 
            <TableRow>
              <TableCell colSpan={columns.length} align="center" component="td">
                <CircularProgress />
              </TableCell>
            </TableRow>
          :
          list.length > 0 ? list.map((rowItem, rowIndex) => (
            <TableRow
              style={{ cursor: 'pointer' }}
              key={rowIndex}
              onClick={() => {
                if(typeof handleItemClick !== "undefined"){
                  handleItemClick(rowItem.id, rowItem.name, rowItem.email);
                }
                return null;
              }}>
              {columns.map((colItem, colIndex) => {
                return (
                  <TableCell key={colIndex}>
                    {colItem.key === "delete" ?
                      <IconButton onClick={evt => handleDeleteItem(rowItem)} aria-label="delete">
                        <DeleteIcon color="secondary"/>
                      </IconButton>
                    : (colItem.key === "url") ? (!rowItem[colItem.key]) ?
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        color="secondary"
                        sx={{
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0
                        }}
                        size={25}
                        thickness={5}
                      />
                      : 
                      <a href={rowItem[colItem.key]}>
                        <IconButton>
                          <GetAppIcon color="secondary"/>
                        </IconButton>
                      </a>
                    :
                    colItem.key === "download" ? 
                      (downloadLoading && typeof downloadLoading[rowItem.id] !== "undefined") ? 
                        <CircularProgress size={22} /> 
                      :  <IconButton onClick={evt => handleDownloadClick(rowItem)}>
                        <GetAppIcon color="secondary"/>
                      </IconButton>
                    : 
                      <Typography
                        variant={'subtitle1'}
                        color={colItem.color || 'textPrimary'}
                        style={colItem.style ? colItem.style : null}
                        >
                        {rowItem[colItem.key] || '-'}
                      </Typography>}
                  </TableCell>
                );
              })}
            </TableRow>
          )) : 
            <TableRow>
              <TableCell colSpan={columns.length}>
                Record not found!
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTable;
