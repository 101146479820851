import React from 'react';
import {
    useLoadScript,
    GoogleMap,
    Marker,
} from "@react-google-maps/api";

const OcrFileLocationMap = ({
    markerLatLong,
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD1d45qiv804kgEGCiHHwyQFLXzIxaoroE",
        libraries: ["places", "visualization"]
    });

    return (
        <>
            {
                markerLatLong && markerLatLong.lat & markerLatLong.lng? 
                    (isLoaded ?
                        <GoogleMap
                            mapContainerStyle={{
                                height: "328px",
                                width: "100%"
                            }}
                            center={markerLatLong}
                            zoom={12}
                        >
                            <Marker key="marker_1"
                                position={markerLatLong}
                            />
                        </GoogleMap>
                        : <div>
                            Map is loading ...
                        </div>
                    )
                :<div>Map data is missing</div>
            }
            
        </>
    )
}

export default OcrFileLocationMap