import React,{useState, useEffect, useRef, useCallback} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';


import SearchBox from '../../../../../../components/SearchBox';
import Message from '../../../../../../components/Message';
import { ACTION_TYPES } from '../../../../../../utils/constants';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

var PDFJS = window.pdfjsLib;

const CreateRule = ({
          t, 
          open, 
          onClose, 
          ocrData, 
          handleSubmitOfOcrRule,
          ocrRuleSubmitLoading,
          ocrImageDataForCreateRule,
          actionType,
          ruleData={},
          handleSubmitEditOfOcrRule = () => {},
          handleSearchOfOcrRuleImg
        }) => {
          ruleData ? console.log(ruleData?.selected_sections,"ruleData"): console.log("nothing ruledata")
    const classes = useStyles();
    const [ruleName, setRuleName] = useState('');
    const [description, setDescription] = useState('');
    const [docId, setDocId] = useState('');
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop1, setCompletedCrop] = useState(null);
    // const [crop, setCrop] = useState({ unit: '%',height: 20, width: 30, aspect: null });
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [upImg, setUpImg] = useState();
    const [cropImgValueForApi, setCropImgValueForApi] = useState('');
    const [selectedImgFromList, setSelectedImgFromList] = useState('');
    const [selectedSection, setselectedSection ] = useState()
    const [existingCrops, setexistingCrops] = useState()
    
    const [cropArray, setCropArray] = useState([
      { crop: { unit: "%", height: 20, width: 30, aspect: null }, title: "Crop Title" },
      // Add more crops here with default titles if needed
    ]);
    
    const onSelectFile = async (e, data) => {
      try {
        const isFileExist = e.target.files;
        // this code is req in future
        // let slecetdFile = '';
        // if(data && data.imgUrl && data.name){
        //   const defaultType = 'image/jpeg'
        //   let header = new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //   });
        //   let options = {
        //     mode: 'no-cors',
        //     header: header,
        //   };

        //   const response = await fetch(data.imgUrl, options);
        //   const blob = new Blob([{size: data.size, type: "image/jpeg"}]);
        //   const buffer = new ArrayBuffer(data.size);
        //   const view = new Int32Array(buffer);
        //   debugger;
        //   slecetdFile = new File([buffer], data.name, {
        //     type: defaultType
        //   });
        //   debugger;
        //   console.log('slecetdFile', slecetdFile);
        // }

        // const isFileExist = e ? e.target.files : [slecetdFile];
        if (isFileExist && isFileExist.length > 0) {
          if(isFileExist[0].type === 'application/pdf'){
          const uri = URL.createObjectURL(e.target.files[0]);
          var pdf = await PDFJS.getDocument({ url: uri });
          var page = await pdf.getPage(1);

          var viewport = page.getViewport(1);
      
          var render_context = {
            canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
            viewport: viewport
          };
          setWidth(viewport.width);
          setHeight(viewport.height);
          await page.render(render_context);

          var canvas = document.getElementById("pdf-canvas");
          var img = canvas.toDataURL("image/png");
          const base64Data = img;
          const base64 = await fetch(base64Data);
      
          const base64Response = await fetch(base64Data);
          const blob = await base64Response.blob();
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(blob);
          // console.log('img', img);
          }else if (isFileExist[0].type === 'image/jpeg' || isFileExist[0].type === 'image/png'){
            const reader = new FileReader();
            //debugger;
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(isFileExist[0]);
            //debugger;
          }
        } 
      } catch (error) {
        console.log('error', error);
      }
    };

    const onLoad = useCallback((img) => {
      imgRef.current = img;
    }, []);


    const initializeCropArray = (initialData) => {
      const newCropArray = initialData?.map((cropData) => {
        const { x, y, height, width, scale_x, scale_y, cropTitle } = cropData;
        return {
          crop: {
            x: parseFloat(x),
            y: parseFloat(y),
            height: parseFloat(height),
            width: parseFloat(width),
            unit: '%', // You may need to adjust this based on your data
            aspect: null, // You may need to adjust this based on your data
          },
          title: cropTitle,
        };
      });
  console.log(newCropArray,'newCropArray')
      setCropArray(newCropArray);
    };


    const Viewrule = (x) => {
      // Check if x matches the crop title
      const index = cropArray.findIndex((item) => item.title === x);
  
      // Check if the index is within the valid range
      if (index !== -1) {
        // Create a new array with the reordered elements
        const updatedArray = [
          ...cropArray.slice(0, index),
          ...cropArray.slice(index + 1),
          cropArray[index],
        ];
  console.log(updatedArray,"updatedArray")
        // Update the state with the new array
        setCropArray(updatedArray);
      
      } else {
        console.log('Crop with title not found.');
      }
    };
  

    // useEffect(() => {
    //   if(ruleData){
    //     initializeCropArray(ruleData?.selected_sections);
    //   }
    //  console.log(cropArray,'cropArray');
    // }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount
  

   
    
    
    
    useEffect(() => {
      setRuleName('');
      setDescription('');
      setDocId('');
      setUpImg();
      setCropImgValueForApi('');
      setexistingCrops('');
      if(ruleData?.selected_sections){
        initializeCropArray(ruleData?.selected_sections);
      }else {
        setCropArray([
          { crop: { unit: "%", height: 20, width: 30, aspect: null }, title: "Crop Title 1" },
          // Add more crops here with default titles if needed
        ]);
        imgRef.current = null;
      }
      
   
     
    }, [!open]);

    useEffect(() => {
      setRuleName(ruleData.rule_name);
      setDescription(ruleData.description);
      ruleData.selected_sections ? setexistingCrops(Object.values(ruleData.selected_sections)) : setexistingCrops() ;
      const fetchedorpValus = {};

      fetchedorpValus.x = ruleData.x;
      fetchedorpValus.y = ruleData.y;
      fetchedorpValus.width = ruleData.width;
      fetchedorpValus.height = ruleData.height;
      fetchedorpValus.scale_x = ruleData.scale_x;
      fetchedorpValus.scale_y = ruleData.scale_y;
      setCropImgValueForApi(fetchedorpValus);
      
    }, [ruleData.rule_name,
        ruleData.description,
        ruleData.x,
        ruleData.y,
        ruleData.height,
        ruleData.width,
        ruleData.scale_x,
        ruleData.scale_y]);

        useEffect(() => {
          const cropValuesMap = {};
        
          cropArray.forEach((cropData, index) => {
           
            const { crop, title } = cropData;
            if (!completedCrop1 || !previewCanvasRef.current || !imgRef.current) {
              return;
            }
        
            const cropValues = {};
            const image = imgRef.current;
            const canvas = previewCanvasRef.current;
            const completedCrop = crop;
            console.log(completedCrop, "ctx")
            console.log(crop, "cropctx")
        
            const scaleX = image?.naturalWidth / image?.width;
            const scaleY = image?.naturalHeight / image?.height;
            const ctx = canvas?.getContext('2d');
            const pixelRatio = window.devicePixelRatio;
        
            canvas.width = completedCrop.width * pixelRatio * scaleX;
            canvas.height = completedCrop.height * pixelRatio * scaleY;
        
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';
        
            cropValues.x = completedCrop.x;
            cropValues.y = completedCrop.y;
            cropValues.width = completedCrop.width;
            cropValues.height = completedCrop.height;
            cropValues.scale_x = scaleX;
            cropValues.scale_y = scaleY;
            cropValues.cropTitle = title;
      
            ctx.drawImage(
              image,
              completedCrop.x * scaleX,
              completedCrop.y * scaleY,
              completedCrop.width * scaleX,
              completedCrop.height * scaleY,
              0,
              0,
              completedCrop.width * scaleX,
              completedCrop.height * scaleY
            );
        
            
            cropValuesMap[index] = cropValues;
          });
        
          setCropImgValueForApi(cropValuesMap);
          setselectedSection(cropValuesMap)
        
          
    
        }, [cropArray, completedCrop1]);

        const handleCropChange = (newCrop, index) => {
          // Update the crop state in the cropArray for the specific index
          setCropArray((prevCropArray) => {
            const updatedCropArray = [...prevCropArray];
            updatedCropArray[index]['crop'] = newCrop;
     

            return updatedCropArray;
          });
        };

        const handleTitleChange = (event, index) => {
          const newCropArray = [...cropArray];
          newCropArray[index].title = event.target.value;
          setCropArray(newCropArray);
        };
        
      
        const addNewCrop = (index) => {
          // Add a new crop object with a default title to the cropArray
          const newIndex = index+1;
          setCropArray((prevCropArray) => [
            ...prevCropArray,
            { crop: { unit: '%', height: 20, width: 30, aspect: null }, title: "New Crop Title "+newIndex }
          ]);
          // removeCrop(index)
        };
        
      
        // const removeCrop = (indexToRemove) => {
        //   setCropArray((prevCrops) => prevCrops.filter((_, index) => index !== indexToRemove));
        // };

        const removeCrop = (index) => {

          //remove from selected sections Object.values(selectedSection)

       
          // Remove the crop from the cropArray for the specific index
          setCropArray((prevCropArray) => {
            const updatedCropArray = [...prevCropArray];
            updatedCropArray.splice(index, 1);
            return updatedCropArray;
          });
        };
        
      
        const handleCropComplete = (crop, index) => {
         console.log(crop, 'handleCompletedCrop')
          // You can perform any action with the completed crop data here
          setCompletedCrop(crop)
        };

    
        const viewExistingCrop =(index,initialData)=>{
          if(!upImg){
            return Message.error('Please browse and choose the file template for this rule below!!!');
          }
          Viewrule(initialData.cropTitle)
          

        }
        
    return (
        <Dialog 
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                 Create Parse Rule
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
            <Grid container spacing={2} style={{ alignItems: 'flex-start' , marginBottom: '5px'  }}>
            <Grid  xs={3} spacing={3}>
  <Grid >
    <TextField
      value={ruleName}
      id="outlined-search"
      label="Rule Name"
      type="search"
      fullWidth
      onChange={(e) => setRuleName(e.target.value)}
    />
  </Grid>

  <Grid spacing={3}>
    {existingCrops && existingCrops?.length && (
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ fontWeight:'bolder'}}>Existing Crop title(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {existingCrops.map((row, index) => (
    <TableRow key={row.id} style={{ padding: '5px' }}>
     <TableCell
  align="center"
  onClick={() => viewExistingCrop(index, row)}
  component="th"
  scope="row"
  style={{ cursor: 'pointer' }} // Adding style with cursor
>
  {row.cropTitle}
</TableCell>

    </TableRow>
  ))}
</TableBody>

        </Table>
      </TableContainer>
    )}
  </Grid>
</Grid>


               
                <Grid item xs={2}/>
                <Grid item xs={6}>
                  <TextField
                    value={description}
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    style={{ width: '100%'}}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                <Grid item xs={1}/>
                </Grid>
              </Grid>
              {
                actionType ===  ACTION_TYPES.CREATE && (
                  <>
                       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'baseline' }}> 
                      <Typography >Select Existings Document</Typography>
                      <div>
                      <SearchBox type="wide" placeholderText="Search Existing Documents" handleSearch={handleSearchOfOcrRuleImg} />
                      </div>
                      <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={() => {
                          setDocId('');
                          setSelectedImgFromList('');
                        }}
                      >
                        unselct a doc
                    </Button>
              </div>
              <div style={{ border: '1px solid Black' ,display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', height: 360}}>
                    {ocrImageDataForCreateRule && ocrImageDataForCreateRule.length > 0 ? ocrImageDataForCreateRule.map((rowitem) => (

                              <Chip
                                avatar={
                                <Avatar 
                                  alt=""
                                  src={rowitem.url}
                                  style={{ height: 190, width: 140, borderRadius: 12}}
                                  />
                                }
                                label={rowitem.file_name}
                                variant={rowitem.id === docId? '': "outlined"}
                                style={{ marginRight: '5px', height: 290, width: 278, border: 'none', marginTop: 60 }}
                                onClick={()=> {
                                  setDocId(rowitem.id);
                                  setSelectedImgFromList(rowitem.url);
                                  //onSelectFile('',{imgUrl:rowitem.url, name:rowitem.file_name, size:rowitem.file_size});
                                }}
                                color={rowitem.id === docId? 'primary': ""}
                              />        
                          )) :
                          <Typography variant={'subtitle2'} style={{ display: 'flex', justifyContent: 'center'  }}>Record not found!</Typography>                   
                    }
              </div>   
                  </>
                )
              }     
              <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '5px'  }}>OR</Typography>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                    {
                      docId ?
                      (
                        <img
                        src={selectedImgFromList}
                        alt=''
                        style={{ height: 221, width: 199}}
                      />
                      ):(
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                          <input 
                            accept="application/pdf,image/x-png,image/jpeg"
                            onChange={onSelectFile} 
                            type="file"
                          />
                        </div>
                      )
                    }
                </Grid>
                <Grid item xs={4} />
              </Grid>


              <div>
            
      
{cropArray?.length > 0 && (
<div key={cropArray.length - 1}>
<Box display="flex" flexDirection="row" p={1} m={1} justifyContent="space-evenly">
    {upImg?.length && <Button size="small"  variant={'contained'} color="primary" onClick={()=>{addNewCrop(cropArray.length - 1)}}>Add New Crop</Button>}
    {cropArray.length > 1 && upImg?.length && <Button size="small" variant={'outlined'} onClick={() => removeCrop(cropArray.length - 1)}>Remove Crop</Button>}
   {upImg?.length && <TextField
   id="standard-basic" 
   label="Add Crop Title"
   size="small"
      value={cropArray[cropArray.length - 1]?.title || ''}
      onChange={(e) => handleTitleChange(e, cropArray.length - 1)}
    /> } 
  </Box>
  {/* <ReactCrop
   onImageLoaded={onLoad}
    src={upImg}
    crop={cropArray[cropArray.length - 1]?.crop || { unit: '%', height: 20, width: 30, aspect: null }}
    onChange={(newCrop) => handleCropChange(newCrop, cropArray.length - 1)}
    onComplete={(crop) => handleCropComplete(crop, cropArray.length - 1)}
  /> */}

<ReactCrop
  onImageLoaded={onLoad}
  src={upImg}
  crop={{
    x: cropArray[cropArray.length - 1]?.crop?.x,
    y:cropArray[cropArray.length - 1]?.crop?.y,
    height:cropArray[cropArray.length - 1]?.crop?.height,
    width:cropArray[cropArray.length - 1]?.crop?.width,
  }}
  onChange={(newCrop) => handleCropChange(newCrop, cropArray.length - 1)}
  onComplete={(crop) => handleCropComplete(crop, cropArray.length - 1)}
/>
</div>
)}



    {/* Button to add a new crop */}
  
  </div>


              <div id="pdf-main-container" 
                style={{width: 400,
                                margin: '20px auto'
              }}
              >
                  <div id="pdf-contents">
                    <div id="image-convas-row" style={{
                      display: 'flex',
                      margin: '20px 0',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}>
                    <canvas ref={previewCanvasRef} id="pdf-canvas" width={width} height={height} />
                    </div>
                  </div>
              </div>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button 
                        variant={'outlined'}
                        color="primary"
                        onClick={onClose}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                      t={t}
                      loading={ocrRuleSubmitLoading}
                      onClick={() => {
                        if(actionType === ACTION_TYPES.CREATE){
                          handleSubmitOfOcrRule({
                            description,
                            ruleName,
                            docId,
                            selected_sections:Object.values(selectedSection)
                         
                          })
                        } else if (actionType === ACTION_TYPES.EDIT){
                          handleSubmitEditOfOcrRule({
                            id: ruleData.id,
                            description,
                            ruleName,
                            selected_sections:Object.values(selectedSection)
                          })
                        }
                      }}
                    />
                </Grid>          
            </DialogActions>
        </Dialog>
    )
}

export default CreateRule
