import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = { 
    id:"",
    crNumber: "",
    dateSubmitted: null,
    requesterName: "",
    rgDesignation: "",
    rgSection:"",
    rgEmail: "",
    rgPhone: "",
    supervisior: "",
    svDesignation: "",
    svEmail: "",
    svPhone: "",
    changeLocation: "",
    changeRequested: "",
    reasonToChange: "",
    changeClassification: "",
    hardwareName: "",
    softwareName: "",
    risk: "",
    mitigationMeasures: "",
    relativeCost: "",
    estimatedEfforts: "",
    responsible: "",
    accountable: "",
    consulted: "",
    informed: "",
    imo:  "",
    imoRecommendation: "",
    imoReason:  "",
    priority: "",
    commercialManager:  "",
    commercialManagerStatus: "",
};


function ChangeRequestModal({
    t,
    open,
    data,
    users,
    userdata,
    handleClose,
    modalState,
    handleCreateChangeRequestSubmit,
    handleOpenSignPanModal
}) {

    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const classes = useStyles();
  
    React.useEffect(() => {
      if (!open) {
          setURFormState({ ...urInitFields });
          handleClose();
      }
    }, [open, handleClose]);
  
    React.useEffect(() => {
      if (data && data._id) {
          const id = data._id;
          setURFormState({...data, id });
      }else{
          // setShowUserSelect(true);
          // setShowLearnDevelopment(true);
          // setShowHOD(true);
          // setShowHRManager(true);
          // setShowGeneralManager(true);
      }
  }, [data]);

  const handleChange = (value, name, key) => {
          setURFormState({ ...urFormState, [name]: value });
  }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Create New Change Request</Typography>
                        </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    
                    <Grid item xs={4}>
                    <Grid item xs={8}>
                    <h4 style={{fontWeight: 400}}>Change Requester No.</h4>
                    </Grid>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            disabled={true}
                            color={'secondary'}
                            value={urFormState.crNumber}
                            onChange={(evt) => handleChange(evt.target.value, "crNumber")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                    <h4 style={{fontWeight: 400}}>Date Submitted </h4>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateSubmitted}
                            handleChange={newDate => handleChange(newDate, "dateSubmitted")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <h4 style= {{fontWeight: 400}}>Requester Name </h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.requesterName}
                            onChange={(evt) => handleChange(evt.target.value, "requesterName")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <h4 style={{fontWeight: 400}}>Requester Section </h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.rgSection}
                            onChange={(evt) => handleChange(evt.target.value, "rgSection")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                      <h4 style={{fontWeight: 400}}>Requester Designation </h4>                     
                        <Input
                            type={'text'}
                            className={classes.input}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            value={urFormState.rgDesignation}
                            onChange={(evt) => handleChange(evt.target.value, "rgDesignation")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <h4 style={{fontWeight: 400}}>Email</h4>                  
                        <Input
                            type={'text'}
                            className={classes.input}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            value={urFormState.rgEmail}
                            onChange={(evt) => handleChange(evt.target.value, "rgEmail")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <h4 style={{fontWeight: 400}}>Phone </h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.rgPhone}
                            onChange={(evt) => handleChange(evt.target.value, "rgPhone")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button  style={{minWidth: '260px', marginTop:25}}
                         variant={'outlined'}
                         onClick={() => handleOpenSignPanModal("rgSign")}>
                            Requester Signature <img src={SignIcon} width= "20" height={25}/> 
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                    <h4 style= {{fontWeight: 400}}>Supervisor</h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            value={urFormState.supervisior}
                            onChange={(evt) => handleChange(evt.target.value, "supervisior")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <h4 style={{fontWeight: 400}}>Supervisor's Designation </h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.svDesignation}
                            onChange={(evt) => handleChange(evt.target.value, "svDesignation")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <h4 style={{fontWeight:400}}>Email </h4>                      
                        <Input
                            type={'text'}
                            className={classes.input}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            value={urFormState.svEmail}
                            onChange={(evt) => handleChange(evt.target.value, "svEmail")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                    <h4 style={{fontWeight: 400}}>Phone No.</h4>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.svPhone}
                            onChange={(evt) => handleChange(evt.target.value, "svPhone")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <h4 style={{fontWeight: 400}}>Change Location</h4>                     
                       <Input
                            type={'text'}
                            className={classes.input}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            value={urFormState.changeLocation}
                            onChange={(evt) => handleChange(evt.target.value, "changeLocation")}
                        />
                    </Grid>
                   

                    <Grid item xs={4}>
                        <Button style={{minWidth: '260px', marginTop:64}}
                          variant={'outlined'}
                          onClick={() => handleOpenSignPanModal("svSign")}>
                           Supervisor Signature <img src={SignIcon} width= "20" height={25} />
                        </Button>
                    </Grid>
                </Grid>

                
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Change Description (_Attach Information, if necessary)</Typography>
                    </Grid>
                </Grid>
                  <Grid container spacing={2} style={{ marginBottom: 40 }} >
                    
                    <Grid item xs={12}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Change Requested </h4>   
                    </Grid>
                        <TextField
                            type="textarea"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={(evt) => handleChange(evt.target.value, "changeRequested")}
                            value={urFormState.changeRequested}
                        />
                    </Grid>

                    
                    <Grid item xs={12}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Reasons to Change </h4>   
                    </Grid>
                        <TextField
                            type="textarea"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={(evt) => handleChange(evt.target.value, "reasonToChange")}
                            value={urFormState.reasonToChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Information Technology Assest To Be Changed</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" style={{ paddingTop: 10 }} color="textPrimary" >Change Classification: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <RadioGroup
                            row
                            aria-label="changeClassification"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `changeClassification`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Normal"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.changeClassification === 'Normal' ? true : false}
                                labelPlacement="start"
                                label="Normal"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Standard"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.changeClassification === 'Standard' ? true : false}
                                label="Standard"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Emergency"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.changeClassification === 'Emergency' ? true : false}
                                label="Emergency"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Name of  the Hardware</h4>   
                    </Grid>
                        <TextField
                            type="textarea"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={(evt) => handleChange(evt.target.value, "hardwareName")}
                            value={urFormState.hardwareName}
                        />
                    </Grid>

                    <Grid item xs={6}>
                         <Grid>
                           <h4 style={{fontWeight: 400}}>Name of Application/Software</h4>   
                         </Grid>
                          <TextField
                            type="textarea"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={(evt) => handleChange(evt.target.value, "softwareName")}
                            value={urFormState.softwareName}
                          />
                    </Grid>
                </Grid>
                
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Risk Assessement</Typography>
                    </Grid>

                   
                    <Grid item xs={6}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Findings/Risks </h4>   
                    </Grid>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.risk}
                            onChange={(evt) => handleChange(evt.target.value, "risk")}
                        />
                    </Grid>

                   
                    <Grid item xs={6}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Mitigation Measures </h4>   
                    </Grid>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.mitigationMeasures}
                            onChange={(evt) => handleChange(evt.target.value, "mitigationMeasures")}
                        />
                    </Grid>

                    <Grid item xs={6}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Relative Cost </h4>   
                    </Grid>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.relativeCost}
                            onChange={(evt) => handleChange(evt.target.value, "relativeCost")}
                        />
                    </Grid>

                    <Grid item xs={6}>
                    <Grid>
                    <h4 style={{fontWeight: 400}}>Estimated Effort in Man Days or Hours </h4>   
                    </Grid>
                        <Input
                            type={'text'}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.estimatedEfforts}
                            onChange={(evt) => handleChange(evt.target.value, "estimatedEfforts")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={2}>
                        <Typography variant="h7" >Responsible</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.responsible}
                            handleChange={(evt) => handleChange(evt.target.value, "responsible")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("responsibleSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h7" >Accountable</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.accountable}
                            handleChange={(evt) => handleChange(evt.target.value, "accountable")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("accountableSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={2}>
                        <Typography variant="h7" >Consulted</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.consulted}
                            handleChange={(evt) => handleChange(evt.target.value, "consulted")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("consultedSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h7" >Informed</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.informed}
                            handleChange={(evt) => handleChange(evt.target.value, "informed")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("informedSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Information Management Official</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 20 }} >
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.imo}
                            handleChange={(evt) => handleChange(evt.target.value, "imo")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("supervisorSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2" style={{ paddingTop: 10, display: "inline-block" }} color="textPrimary">Recommendation for:</Typography>
                        <RadioGroup 
                          style={{ display: "contents" }}
                          row aria-label="recommendation_for"
                          name="imoRecommendation"
                          onChange={(evt) => {
                            handleChange(evt.target.value, `imoRecommendation`);
                          }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Approval"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.imoRecommendation === 'Approval' ? true : false}
                                labelPlacement="start"
                                label="Approval"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Rejection"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.imoRecommendation === 'Rejection' ? true : false}
                                label="Rejection"
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
              
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                    <h4 style={{fontWeight: 400}}>Reasons </h4>   
                      <TextField
                            type="textarea"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={4}
                            onChange={(evt) => handleChange(evt.target.value, "imoReason")}
                            value={urFormState.imoReason}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={1}>
                        <Typography variant="subtitle2" style={{ paddingTop: 10 }} color="textPrimary" >Priority:</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <RadioGroup
                            row
                            aria-label="priority"
                            name="priority"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `priority`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="High"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.priority === 'High' ? true : false}
                                labelPlacement="start"
                                label="High"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Medium"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.priority === 'Medium' ? true : false}
                                label="Medium"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Low"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.priority === 'Low' ? true : false}
                                label="Low"
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Commercial Manager</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.commercialManager}
                            handleChange={(evt) => handleChange(evt.target.value, "commercialManager")}
                        />
                    </Grid>
                    <Grid xs={1}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("commercialManagerSign")}>
                           <img src={SignIcon} width="26" height={26}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <RadioGroup
                            row
                            aria-label="approve_rejected"
                            name="commercialManagerStatus"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `commercialManagerStatus`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Approved"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.commercialManagerStatus === 'Approved' ? true : false}
                                labelPlacement="start"
                                label="Approved"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Rejected"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.commercialManagerStatus === 'Rejected' ? true : false}
                                label="Rejected"
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateChangeRequestSubmit(urFormState);
                        }}
                        loading={false}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ChangeRequestModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateChangeRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ChangeRequestModal);