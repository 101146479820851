import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL } from '../../../../../../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../../../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import OutlinedSelect from '../../../../../../../../../components/OutlinedSelect';

const SensorPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    maintenanceType,
    allowConfigure,
    handleChangeMaintenance
}) => {
    
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    
    return (
        <>
            <TableRow key={data._id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography
                                variant={'subtitle1'}
                                style={{ cursor: 'pointer' }}
                            >
                                {data.deviceName ? data.deviceName : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.deviceNo}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.placeName ? data.placeName : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.project ? data.project : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.dataType ? data.dataType : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.state}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.lat}, {data.lng}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.service_id}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.createdAt)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {allowConfigure &&
                    <TableCell>
                        <OutlinedSelect
                            val={(maintenanceType && maintenanceType[data._id]) ? maintenanceType[data._id].toString() : "Active"}
                            label={"Select"}
                            handleChange={evt => handleChangeMaintenance(evt.target.value, data)}
                            selectStyle={null}
                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: '180px' }}
                            options={["Active", "Maintenance"]}
                            disabled={(data.lat) ? false : true}
                        />
                    </TableCell>
                }
                {allowConfigure &&
                    <TableCell>
                        <Grid>
                            <IconButton
                                size={'small'}
                                disableRipple={true}
                                disableFocusRipple={true}
                                color={'secondary'}
                                onClick={evt => {
                                    setAnchorEl(evt.currentTarget);
                                    evt.stopPropagation();
                                }}
                            >
                                <MoreHoriz className={commonClasses.iconRegular} />
                            </IconButton>
                        </Grid>
                        {anchorEl && <MenuWrapper
                            id={'ticket-menu'}
                            anchorEl={anchorEl}
                            handleClose={evt => {
                                evt.stopPropagation();
                                setAnchorEl(null);
                            }}
                            menuItems={menuItems}
                            handleMenuItemClick={elem => {
                                handleMenuItemClick(elem, data, 'sensor');
                                setAnchorEl(null);
                            }}
                        />}
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

SensorPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(SensorPanelItem);