import React, { useState,useContext } from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import {OcrContext} from '../../../../../../contexts/OcrProvider';
import {marginGenerator} from '../../../../../../theme/utils';
import OcrProcessItem from './OcrProcessItem';
import OcrProcessView from '../OcrProcessView';
import OcrProcessApproveOrRejectModal from '../OcrProcessApproveOrRejectModal';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
  }));

const OcrProcessListing = ({
    t
}) => {

    const classes = useStyles();

    const [isProcessViewEnable, setIsProcessViewEnable] = useState(false);
    const [processViewData, setProcessViewData] = useState('');
    const [isApprovedOrRejectModalEnable, setIsApprovedOrRejectModalEnable] = useState(false);
    const [actionClickType, setActionClickType] = useState('');

    const { 
            ocrProcessStepsData,
            ocrProcessStepsLoading
          } = useContext(OcrContext);

    let columns = [
      {
          label: `Process ${t('details.label')}`,
          key: "name"
      },
      {
          label: t('actedOn.label'),
          key: "createdDate"
      },
      {
          label: t('action.label'),
          key: "action"
      } 
    ];

    const handleCloseProcessView = () => {
        setProcessViewData('');
        setIsProcessViewEnable(false);
    }

    const handleClickOnProcessView = (data) => {
        setProcessViewData(data);
        setIsProcessViewEnable(true);
    };

    const handleClickOnApproveOrRejectBtn = (type, data) => {
        setProcessViewData(data);
        setActionClickType(type);
        setIsApprovedOrRejectModalEnable(true);
    };

    const handleCloseOfApproveOrReject = () => {
        setActionClickType('');
        setProcessViewData('');
        setIsApprovedOrRejectModalEnable(false);
    };
    
  return (
    <>
        <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!ocrProcessStepsLoading ?
                      ocrProcessStepsData && ocrProcessStepsData.data.length > 0 ?
                      ocrProcessStepsData.data.map((rowitem, rowIndex) => (
                                <OcrProcessItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    handleClickOnProcessView={handleClickOnProcessView}
                                    handleClickOnApproveOrRejectBtn={handleClickOnApproveOrRejectBtn}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {ocrProcessStepsLoading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            {/* { !loading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(ocrFolderData, 'count', null)/20)} page,  {get(ocrFolderData, 'count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={ocrFolderData && ocrFolderData.count ? Math.ceil(ocrFolderData.count/20) : 0} 
                                page={page} 
                                onChange={(evt, value) => {
                                    handlePageChange("ocr_folder", value);
                                }} 
                            />
                        </Grid>
                    </Grid>
                </>
            } */}
        </TableContainer>
    </Grid>
    <OcrProcessView
        t={t}
        open={isProcessViewEnable}
        onClose={handleCloseProcessView}
        data={processViewData}    
    />
    <OcrProcessApproveOrRejectModal
        t={t}
        open={isApprovedOrRejectModalEnable}
        onClose={handleCloseOfApproveOrReject}
        type={actionClickType}
        data={processViewData}
    />
    </>
  )
}

export default OcrProcessListing