import React, { useState } from 'react';
import ChartContainer from '../.././../../../../components/Charts/ChartContainer';
import Statcard from '../.././../../../../components/Statcard';
import { marginGenerator } from '../.././../../../../theme/utils';
import BarChartWrapper from '../.././../../../../components/Charts/BarChartWrapper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 250,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
  
}));

const bidsChartData = [
    {date: "2022-04-10 10:00", count: 10},
    {date: "2022-04-10 11:00", count: 2},
    {date: "2022-04-10 12:00", count: 13},
    {date: "2022-04-10 13:00", count: 45},
    {date: "2022-04-10 14:00", count: 12},
    {date: "2022-04-10 15:00", count: 3},
    {date: "2022-04-10 16:00", count: 45},
    {date: "2022-04-10 17:00", count: 5},
    {date: "2022-04-10 18:00", count: 43},
    {date: "2022-04-10 19:00", count: 54},
    {date: "2022-04-10 20:00", count: 32},
    {date: "2022-04-10 21:00", count: 23},
    {date: "2022-04-10 22:00", count: 53},
    {date: "2022-04-10 23:00", count: 22},
    {date: "2022-04-10 24:00", count: 53},
    {date: "2022-04-11 10:00", count: 8},
    {date: "2022-04-11 11:00", count: 10},
    {date: "2022-04-11 12:00", count: 34},
    {date: "2022-04-11 13:00", count: 6}
]
const Dashboard = ({ t, subscriptionData, handleImageModalClick, aiTypes, placeList }) => {
    const classes = useStyles();
    const eventAlertTimelineFilter = [
        {
            label: t("startDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (evt) => {},
            val: null,
            type: "input",
            styleOverrides: { marginRight: 24 },
            className: classes.dateInput
        },
        {
            label: t('places.label'),
            handleChange: (evt, newValue) => {
            },
            val: "",
            options: [],
            loading: false,
            type: "autocomplete",
            onInputChange: (val) => {
            },
            handleClose: (evt) => {
            },
            styleOverrides: { marginRight: 0, display: "inline-block", width: 220, marginTop: 10 },
        }
    ];
    const statList = [
        {
            label: "Number Of Bids",
            value: 20,
            type: "bids"
        },
        {
            label: "Number Of Quotes",
            value: 13,
            type: "cam_down"
        },
        {
            label: "Bids closing in 7 Days",
            value: 10,
            type: "inference_models"
        }
    ];

    return (
        <div style={{width: "100%", display: "inline-block"}}>
            <ChartContainer
            customMargin={{marginTop: 0}}
            filterList={eventAlertTimelineFilter}>
            </ChartContainer>
            <Grid
            container
            style={marginGenerator('mt-25')}>
                {statList.map((item, index) => (
                    <Statcard
                        key={index}
                        statLabel={item.label}
                        icon={item.icon}
                        statValue={item.value}
                        type={item.type}
                        customStyles={{marginRight: 15}}
                    />
                ))}
            </Grid>
            <ChartContainer
            title={"Bids Report"}
            customMargin={{marginTop: 25, marginBottom: 25}}>
                <BarChartWrapper
                    data={bidsChartData}
                    dataKeyChart={'count'}
                    dataKeyXAxis={'date'}
                />
            </ChartContainer>
        </div>
    );
};

Dashboard.propTypes = {
    t: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired
};

export default Dashboard;
