import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { 
    CREATE_SCHEDULE_REPORT,
    DELETE_SCHEDULE_REPORT
} from '../../../mutations';
import { GET_SCHEDULE_REPORTS } from "../../../AnalyticsQuery/queries";
import MessageInfo from '../../../../../components/Message';
import GenericTable from "../../../../../components/GenericTable";
import { convertLocalToUTCDate, dateFormatterLLLHHMMSS } from '../../../../../utils';
import { Pagination } from '@material-ui/lab';
import ScheduleReportModal from '../ScheduleReportModal';

const scheduleReportColumn = [
    {
        label: "Notification Templates",
        key: "notification_templates"
    },
    {
        label: "Report Type",
        key: "report_type"
    },
    {
        label: "Schedule Type",
        key: "frequency_type"
    },
    {
        label: "Start Time",
        key: "send_time"
    },
    {
        label: "Created By",
        key: "created_by_name"
    },
    {
        label: "Created Date",
        key: "created_at"
    },
    {
        label: "Action",
        key: "delete"
    }
];

const ScheduleReportAnalytics = ({
    t,
    data,
    notiTemplateList
}) => {

    const [openScheduleReportModal, setOpenScheduleReportModal] = useState(false);
    const [scheduleCreateLoading, setScheduleCreateLoading] = useState(false);
    const [scheduleReportData, setScheduleReportData] = useState({ reports: [], count: 0 });
    const [scheduleReportPage, setScheduleReportPage] = useState(1);

    const [getScheduleReport, { loading: sheduleLoading }] = useLazyQuery(GET_SCHEDULE_REPORTS, {
        fetchPolicy: "no-cache",
        onCompleted: res => {
            if (res.getScheduleReport) {
                const reportData = res.getScheduleReport.reports ? res.getScheduleReport.reports.map(itm => {
                    itm.created_at = dateFormatterLLLHHMMSS(new Date(itm.created_at));
                    itm.send_time = dateFormatterLLLHHMMSS(new Date((parseInt(itm.send_time)*1000)));
                    itm.notification_templates = (itm.notification_templates && itm.notification_templates.length) ? notiTemplateList.filter(noti => itm.notification_templates.includes(noti.id)).map(newItm => newItm.name) : [];
                    itm.notification_templates = itm.notification_templates.join(", ");
                    
                    return itm;
                }) : [];
                setScheduleReportData({count: res.getScheduleReport.count, reports: reportData});
            }
        }
    });

    React.useEffect(() => {
        getScheduleReport({
            variables: {
                organization: data.organizationId,
                limit: 20,
                page: 1
            }
        });
    }, []);

    const [createScheduleReport] = useMutation(CREATE_SCHEDULE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: res => {
            setOpenScheduleReportModal(false);
            setScheduleCreateLoading(false);
            MessageInfo.success("Schedule created successfully");
            return getScheduleReport({
                variables: {
                    organization: data.organizationId,
                    limit: 20,
                    page: 1
                }
            });
        },
        onError: err => {
            setScheduleCreateLoading(false);
            MessageInfo.error(err);
        }
    });

    const [deleteScheduleReport] = useMutation(DELETE_SCHEDULE_REPORT, {
        fetchPolicy: "no-cache",
        onCompleted: res => {
            MessageInfo.success("Schedule deleted successfully");
            return getScheduleReport({
                variables: {
                    organization: data.organizationId,
                    limit: 20,
                    page: 1
                }
            });
        }
    });

    const handleCreateScheduleReport = () => {
        setOpenScheduleReportModal(true);
    }

    const handleCreateScheduleSubmit = (scheduleData) => {
        if (scheduleData) {
            scheduleData.send_time = new Date(convertLocalToUTCDate(scheduleData.send_time)).getTime() / 1000;
            setScheduleCreateLoading(true);
            return createScheduleReport({
                variables: { ...scheduleData, organization: data.organizationId }
            });
        }
    }

    const handleDeleteSchedule = (itm) => {
        if (itm && itm.id) {
            if (window.confirm("Are you sure? you want to delete this schedule")) {
                return deleteScheduleReport({
                    variables: {
                        id: itm.id
                    }
                });
            }
        }
    }

    return (
        <>
            <Grid container justify={'space-between'}>
                <Grid item>
                    Schedule List
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        size="medium"
                        onClick={handleCreateScheduleReport}
                    >
                        Create Schedule
                    </Button>
                </Grid>
            </Grid>
            <Grid container >
                <div style={{ marginTop: 20, width: "100%" }}>
                    <GenericTable list={scheduleReportData.reports} columns={scheduleReportColumn} loading={sheduleLoading} handleDeleteItem={handleDeleteSchedule} />
                </div>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                    <Pagination
                        color="secondary"
                        count={Math.ceil(scheduleReportData.count / 20)}
                        page={scheduleReportPage}
                        onChange={(evt, value) => {
                            setScheduleReportPage(value);
                            return getScheduleReport({
                                variables: {
                                    organization: data.organizationId,
                                    limit: 20,
                                    page: value
                                }
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <ScheduleReportModal
                t={t}
                open={openScheduleReportModal}
                handleClose={evt => {
                    setOpenScheduleReportModal(false);
                }}
                notiTemplateList={notiTemplateList}
                loading={scheduleCreateLoading}
                handleSubmit={handleCreateScheduleSubmit}
            />
        </>
    );
};

ScheduleReportAnalytics.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default ScheduleReportAnalytics;