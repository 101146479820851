/*
  Color specifications
 */

export const colors = {
  common: {
    black: '#2e2e2e',
    white: '#fff',
    green: '#4caf50',
    red: '#f44336',
    orange: '#ff9800'
  },
  background: {
    default: '#fff'
  },
  primary: {
    light: 'rgb(102, 23, 35)',
    main: '#5a1f2a',
    dark: '#3e151d',
    lighter: '#ad9095'
  },
  secondary: {
    light: '#ef818c',
    main: '#e94b5b',
    lighter: '#f4a5ad',
    lightest: 'rgba(233, 75, 91, 0.2)'
  },
  grey: {
    100: '#f0f0f0',
    200: '#ebebeb',
    300: '#ababab',
    400: '#969696',
    500: '#6c6c6c',
    600: '#484848'
  },
  error: {
    main: '#e53935'
  },
  warning: {
    main: '#ff9a01' // Change expected
  },
  success: {
    main: '#43a047'
  },
  info: {
    main: '#0183eb'
  },
  text: {
    primary: '#2e2e2e',
    secondary: '#6c6c6c'
  },
  chartColors: {
    graphGreen: '#6abc6d',
    graphCoolBlue: '#3b8ccf',
    graphDustyOrange: '#f5753b',
    graphTopaz: '#1aa5b5',
    graphCarolinaBlue: '#80afff',
    graphAquaBlue: '#0ec6dc',
    graphSeafoamBlue: '#7dcbc4',
    graphBlush: '#f19a99',
    graphLavenderPink: '#cf91da',
    graphMaize: '#ffd63f',
    graphBlue: '#1976d2',

    graphGrad400: '#9fa7dc',
    graphGrad500: '#c5c9ea',
    graphGrad300: '#7884cd',
    graphGrad200: '#5b69c3',
    graphGrad100: '#3e4eb8',
    graphGrad600: '#e8eaf6'
  }
};

export const hostColors = {
  'echotel.partner.inq.inc': {
    primary: {
      light: '#a2b339',
      main: '#a2b339',
      dark: '#a2b339',
      lighter: '#a2b339'
    },
    secondary: {
      light: '#504a48',
      main: '#504a48',
      lighter: '#504a48',
      lightest: '#504a48'
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#43a047'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#2e2e2e',
      secondary: '#6c6c6c'
    }
  },
  'cartrack.partner.inq.inc': {
    primary: {
      light: '#f47735',
      main: '#f47735',
      dark: '#f47735',
      lighter: '#f47735'
    },
    secondary: {
      light: '#000000',
      main: '#000000',
      lighter: '#000000',
      lightest: '#000000'
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#43a047'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#f47735',
      secondary: '#000000'
    }
  },
  'platform.echoskywalker.com': {
    primary: {
      light: '#a2b339',
      main: '#a2b339',
      dark: '#a2b339',
      lighter: '#a2b339'
    },
    secondary: {
      light: '#504a48',
      main: '#504a48',
      lighter: '#504a48',
      lightest: '#504a48'
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#43a047'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#2e2e2e',
      secondary: '#6c6c6c'
    }
  },
  'trans.edgedock.inq.co.bw': {
    primary: {
      light: '#f04c51',
      main: '#ee353c',
      dark: '#ec1f26',
      lighter: '#f26267'
    },
    secondary: {
      light: '#2f3132',
      main: '#2f3132',
      lighter: '#444647',
      lightest: '#595a5b'
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#43a047'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#2e2e2e',
      secondary: '#6c6c6c'
    }
  },
  'platform.edgedock.co.za': {
    primary: {
      light: '#4294C2',
      main: '#183D85',
      dark: '#174F90',
      lighter: '#3375B1'
    },
    secondary: {
      light: '#D2E6E7',
      main: '#9DF09A',
      lighter: '#73B1B4',
      lightest: '#58A1A$'
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#9DF09A'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#2e2e2e',
      secondary: '#6c6c6c'
    }
  }
};
