import React, { Fragment } from 'react';
import { dateFormatterDDMMMYYYYHMS } from '../../../../../../utils';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  mainTable: {
    marginBottom: 20,
    borderBottomColor: '#9696A4',
    borderBottomWidth: 1,
    fontSize: 6
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 25,
    fontSize: 6,
    fontStyle: 'bold'
  },
  description: {
    width: '14%',
    textAlign: 'center'
  },
  head1: {
    width: '14%',
    textAlign: 'center'
  },
  head2: {
    width: '10%',
    textAlign: 'center'
  },
  head3: {
    width: '15%',
    textAlign: 'center'
  },
  head4: {
    width: '8%',
    textAlign: 'center'
  },
  head5: {
    width: '7%',
    textAlign: 'center'
  },
  head6: {
    width: '22%',
    textAlign: 'center',
    paddingLeft: 1,
    paddingRight: 1
  },
  rate: {
    width: '10%',
    textAlign: 'center'
  }
});

const targetMeetFun = input => {
  let number = input.toString().split(':');
  if (number[0] > 0) {
    return 'No';
  } else {
    if(number[1] >= 4){
      return "No"
    }else{
      return 'Yes';
    }
  }
};

const InvoiceTableRow = ({ items }) => {
  return (
    
    <View style={styles.mainTable}>
    {items && items.length && items[0].id !== '' && items.map((item) => (
      item.ticketId !== "" && (
        <View key={item.id} style={styles.row}>
          <Text style={styles.description}>
            {dateFormatterDDMMMYYYYHMS(item.incidentTime)}
          </Text>
          <Text style={styles.head1}>
            {dateFormatterDDMMMYYYYHMS(item.incidentEndTime)}
          </Text>
          <Text style={styles.head2}>{item.ticketId}</Text>
          <Text style={styles.rate}>{item.timeToRestore}</Text>
          <Text style={styles.head4}>{targetMeetFun(item.timeToRestore)}</Text>
          <Text style={styles.head5}>{item.vodacomProblem ? 'Yes' : 'No'}</Text>
          <Text style={styles.head3}>{item.incidentType}</Text>
          <Text style={styles.head6}>
            {item.closeDescription ? item.closeDescription.slice(0, 250) : ''}
          </Text>
        </View>
      )
    ))}
  </View>
  );
};

export default InvoiceTableRow;
