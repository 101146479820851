import React, {useState} from 'react'
import { withTranslation } from 'react-i18next';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import commonStyles from '../../../../../../../../theme/commonStyles';
import { dateFormatterLLL } from '../../../../../../../../utils';
import MenuWrapper from '../../../../../../../../components/MenuWrapper';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="primary"
            onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <DeleteIcon color="secondary" />}
        </Button>
    );
}

const OcrConfigurationProcessDesignItem = ({
    key,
    t,
    data,
    selecteIdForDiffrentActions,
    deleteOcrProcessLoading,
    hanldeSubmitOfAllOcrProcess,
    assignProjectEnable
}) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
  
    const assignMenuItems = [
      { label: t('assignProjectToProcess.label') },
      { label: t('assignFolderToProcess.label') }
    ];
    
  return (
    <TableRow key={data._id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? data.title : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {dateFormatterLLL(data.createdAt)}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <div style={{ display: 'flex' }}>
                <Grid>
                  <IconButton
                      size={'small'}
                      disableRipple={true}
                      disableFocusRipple={true}
                      color={'secondary'}
                      onClick={evt => {
                          setAnchorEl(evt.currentTarget);
                          evt.stopPropagation();
                      }}
                  >
                      <MoreHoriz className={commonClasses.iconRegular} />
                  </IconButton>
              </Grid>
              <MenuWrapper
                  id={'ticket-menu'}
                  anchorEl={anchorEl}
                  handleClose={evt => {
                      evt.stopPropagation();
                      setAnchorEl(null);
                  }}
                  menuItems={[...assignMenuItems]}
                  handleMenuItemClick={elem => {
                      setAnchorEl(null);
                      if(elem.label === t('assignProjectToProcess.label')){
                        assignProjectEnable(t('assignProjectToProcess.label'), { processId: data._id });
                      } else if(elem.label === t('assignFolderToProcess.label')){
                        assignProjectEnable(t('assignFolderToProcess.label'), { processId: data._id });
                      }
                  }}
              />
            <ButtonComponent
                t={t}
                loading={selecteIdForDiffrentActions === data._id ? deleteOcrProcessLoading : false}
                onClick={() =>{
                    hanldeSubmitOfAllOcrProcess('delete',data._id);
                }}
            /> 
        </div>
          </TableCell>
  </TableRow>  
  )
}

export default OcrConfigurationProcessDesignItem