import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel" la

            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));

export default function BiDashboardItem({ t, data, total, column, loading, startDate, endDate, error, handleSubmitProjectDetailList }) {
    const classes = useStyles();

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: '100%',
                        padding: 20,
                        textAlign: 'center',
                        display: 'inline-block'
                    }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {column &&
                                    column.map(column => (
                                        <TableCell>{column.label}</TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                data && data.bi_dashboard ? data.bi_dashboard.map((item, index) => (
                                    <React.Fragment>
                                        {item &&
                                            <StyledTableRow>
                                                <TableCell>{item.billing_country == "Cote d'ivoire" ? "Ivory Coast" : item.billing_country}</TableCell>
                                                <TableCell>{(item.updated_projects && item.updated_projects.total_nrc.total_nrc__sum) ? parseInt(item.updated_projects.total_nrc.total_nrc__sum).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }) : '$0'}</TableCell>
                                                <TableCell>{(item.updated_projects && item.updated_projects.total_mrc.total_mrc__sum) ? parseInt(item.updated_projects.total_mrc.total_mrc__sum).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }) : '$0'}</TableCell>
                                                <TableCell>{item.updated_projects.count ? <button onClick={() => {
                                                    let countryNameList = item.billing_country + '_updated_projects_count_list';
                                                    handleSubmitProjectDetailList(item.updated_projects[countryNameList], countryNameList)
                                                }
                                                }>{item.updated_projects.count}</button> : 0}</TableCell>
                                                <TableCell>{item.created_projects.count ? <button onClick={() => {
                                                    let countryNameList = item.billing_country + '_created_projects_count_list';
                                                    handleSubmitProjectDetailList(item.created_projects[countryNameList], countryNameList)
                                                }
                                                }>{item.created_projects.count}</button> : 0}</TableCell>
                                                <TableCell>{item.updated_projects.closed ? <button onClick={() => {
                                                    let countryNameList = item.billing_country + '_updated_projects_closed_list';
                                                    handleSubmitProjectDetailList(item.updated_projects[countryNameList], countryNameList)
                                                }
                                                }>{item.updated_projects.closed}</button> : 0}</TableCell>
                                                <TableCell>{item.created_projects.closed ? <button onClick={() => {
                                                    let countryNameList = item.billing_country + '_created_projects_closed_list';
                                                    handleSubmitProjectDetailList(item.created_projects[countryNameList], countryNameList)
                                                }
                                                }>{item.created_projects.closed}</button> : 0}</TableCell>
                                            </StyledTableRow>
                                        }
                                        {/* <StyledTableRow>
                                            <TableCell>{moment(startDate).format("DD MMM YYYY")} - {moment(endDate).format("DD MMM YYYY")}</TableCell>
                                            {data && data.projects_till_dates ?
                                                <React.Fragment>
                                                    <TableCell>{data.projects_till_dates.new_project_created ? data.projects_till_dates.new_project_created : '0'}</TableCell>
                                                    <TableCell>{data.projects_till_dates.launched ? data.projects_till_dates.launched : '0'}</TableCell>
                                                    <TableCell>{data.projects_till_dates.new_project_closed ? data.projects_till_dates.new_project_closed : '0'}</TableCell>
                                                    <TableCell>{data.projects_till_dates.closed ? data.projects_till_dates.closed : '0'}</TableCell>
                                                    <TableCell>{data.projects_till_dates.total_nrc.total_nrc__sum ? data.projects_till_dates.total_nrc.total_nrc__sum : '0'}</TableCell>
                                                    <TableCell>{data.projects_till_dates.total_mrc.total_mrc__sum ? data.projects_till_dates.total_mrc.total_mrc__sum : '0'}</TableCell>
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    <TableCell>NA</TableCell>
                                                    <TableCell>NA</TableCell>
                                                    <TableCell>NA</TableCell>
                                                    <TableCell>NA</TableCell>
                                                    <TableCell>NA</TableCell>
                                                    <TableCell>NA</TableCell>
                                                </React.Fragment>}
                                        </StyledTableRow> */}
                                    </React.Fragment>
                                ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={column.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}