import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { NewSubscriptionPaths, Paths } from '../../routes/routePaths';
import { useHistory } from 'react-router-dom';
import { marginGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import ConfigureSiteItem from './components/ConfigureSiteItem';
import { SERVICE_TYPES, SUBSCRIPTION_STATUS } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_SITE_TO_SUBSCRIPTION,
  UPDATE_SITE_DETAILS
} from '../NewSubscription/mutations';
import Message from '../../components/Message';
import { ServiceManager } from '../../routes/routePaths';
import { UPDATE_SITE_TO_SUBSCRIPTION } from './mutations';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import { GET_SUBSCRIPTION_DETAILS } from '../Subscriptions/queries';
import { getUrl } from '../../utils';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscriptions.label', link: Paths.Subscriptions },
  { label: 'newSubscriptions.label', link: Paths.NewSubscription },
  { label: 'configureSites.label', link: NewSubscriptionPaths.ConfigureSites }
];

const getInputs = (type, t) => {
  let inputArr = [];
  if (type === SERVICE_TYPES.MPLS) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        placeholder: '',
        endAdorment: 'MBPS'
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.DIA) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        placeholder: ''
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      },
      {
        label: t('noOfPublicIPs.label'),
        type: 'number',
        stateVar: `noOfPublicIPs`,
        placeholder: ''
      },
      {
        label: t('blockedUrls.label'),
        type: 'string',
        stateVar: `blockedUrls`,
        placeholder: ''
      },
      {
        label: t('portForwarding.label'),
        type: 'string',
        stateVar: `portForwarding`,
        placeholder: ''
      },
      {
        label: t('ddosPrevention.label'),
        type: 'string',
        stateVar: `ddosPrevention`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.EDGE_AI) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('numberOfCam.label'),
        type: 'number',
        stateVar: `numberOfCamera`,
        placeholder: 'numberOfCamPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fibre', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      }
    ];
  } else if (type === SERVICE_TYPES.SDN) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        //endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fiber', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      },
      {
        label: t('serviceType.label'),
        type: 'selectDropdown',
        stateVar: `serviceType`,
        options: ['SD-WAN', 'Security', 'BYOVNF'],
        placeholder: 'serviceTypePh.label'
      }
    ];
  }

  return [
    {
      label: t('siteName.label'),
      type: 'string',
      stateVar: `siteName`,
      placeholder: 'siteNamePh.label'
    },
    ...inputArr
  ];
};

const ConfigureSites = ({ t }) => {
  const location = useLocation();
  const [state, setState] = useState({});
  const [locationState, setLocationState] = useState({});
  const [inputArr, setInputArr] = useState([]);
  const [currentSite, setCurrentSite] = useState(null);
  const [error, setError] = useState(false);

  const [getSubscriptionDetails] = useLazyQuery(GET_SUBSCRIPTION_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const { getSubscriptionById } = data;
      const { sites } = getSubscriptionById;
      let updatedSites = {};
      sites &&
        sites.length &&
        sites.forEach((item, index) => {
          if (item.status === SUBSCRIPTION_STATUS.NEW) {
            const configuration =
              item.configuration && JSON.parse(item.configuration);
            let confObj = {};
            configuration.forEach((item, index) => {
              confObj = { ...confObj, [item.key]: item.value };
            });
            updatedSites = {
              ...updatedSites,
              [`site${index + 1}`]: {
                ...state[`site${index}`],
                id: item.id,
                siteName: item.name,
                address: item.address,
                latitude: item.latitude,
                longitude: item.longitude,
                zipcode: item.zipcode,
                landmark: item.landmark,
                ...confObj
              }
            };
          } else {
            return null;
          }
        });
      setState({ ...state, ...updatedSites });
    }
  });

  useEffect(() => {
    const splitArr = location.search.split('?');
    const id = splitArr[1].split('=')[1];
    const sites = splitArr[2].split('=')[1];
    const type = splitArr[3].split('=')[1].replace(/%20/g, ' ');

    setState({ ...state, id, sites: parseInt(sites), type });
    setInputArr(getInputs(type, t));
    getSubscriptionDetails({
      variables: { id }
    });
  }, [location]);

  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);

  const { data: countryList } = useQuery(GET_COUNTRY_LIST);

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const [updateSitesOnSubscription] = useMutation(UPDATE_SITE_TO_SUBSCRIPTION, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: async data => {
      const { updateSitesOnSubscription } = data;
      setState({
        ...state,
        [currentSite]: {
          ...state[currentSite],
          id: updateSitesOnSubscription.id
        }
      });
    }
  });

  const menuItems = [
    // { label: t('openSiteConfiguration.label') },
    { label: t('removeSite.label') }
  ];

  const handleInputChange = (name, value, site) => {
    if (name === 'country') {
      getRegionList({ variables: { countryId: value } });
    }
    setState({ ...state, [site]: { ...state[site], [name]: value } });
  };

  const handleResetConfigClick = site => {
    setState({ ...state, [site]: {} });
  };

  const handleMenuItemClick = (item, site) => {
    if (item.label === menuItems[0].label) {
      const tempState = state;
      delete tempState[site];
      setState({ ...tempState, sites: state.sites - 1 });
    }
  };

  const handleSetPosition = (pos, site) => {
    setLocationState({
      ...locationState,
      [site]: {
        latitude: pos.latitude,
        longitude: pos.longitude
      }
    });
  };

  const handleSaveClick = async site => {
    setCurrentSite(site);
    const siteItem = state[site];
    const locationItem = locationState[site];
    const configuration = inputArr
      .map((item, index) =>
        item.stateVar !== 'siteName'
          ? { key: item.stateVar, value: siteItem[item.stateVar] }
          : null
      )
      .filter((item, index) => item !== null);

    await updateSitesOnSubscription({
      variables: {
        name: siteItem.siteName,
        subscriptionId: state.id,
        configuration: JSON.stringify(configuration),
        latitude: siteItem.latitude || locationItem.latitude,
        longitude: siteItem.longitude || locationItem.longitude,
        address: siteItem.address,
        regionId: siteItem.region,
        countryId: siteItem.country,
        zipcode: siteItem.zipcode
      }
    });
    setState({ ...state, [site]: { ...state[site], saved: true } });
  };

  const checkUpdate = () => {
    for (let i = 1; i <= state.sites; i++) {
      if (state[`site` + i].saved !== true) {
        setError(true);
        break;
      }
    }

    if (error) {
      Message.error('Error in adding sites.');
    } else {
      Message.success('Sites added successfully.');
      history.push(ServiceManager.Customers);
    }
  };

  const checkValid = () => {
    return Object.keys(state)
      .filter(
        (item, index) =>
          item.includes('site') && typeof state[item] !== 'number'
      )
      .map((item, index) => !(state[item].id || state[item].saved))
      .includes(true);
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('configureSitesDesc.label')}
        subText={t('configureSitesSubtext.label')}
        primaryButtonText={t('cancel.label')}
        secondaryButtonText={t('goBack.label')}
        handlePrimaryButtonClick={() => history.go(-2)}
        handleSecondaryButtonClick={handleGoBack}
        divider={true}
      />
      <Grid style={{ ...marginGenerator('mt-32') }}>
        {[...Array(state.sites)].length &&
          [...Array(state.sites)].map((item, index) =>
            state[`site${index + 1}`] &&
            state[`site${index + 1}`].removed === true ? null : (
              <ConfigureSiteItem
                t={t}
                key={index}
                data={inputArr}
                currentState={state}
                handleMenuItemClick={handleMenuItemClick}
                index={index + 1}
                menuItems={menuItems}
                handleSetPosition={handleSetPosition}
                handleResetConfigClick={handleResetConfigClick}
                handleInputChange={handleInputChange}
                handleSaveClick={handleSaveClick}
                countryList={countryList && countryList.getCountries}
                regionList={regionList && regionList.getRegionsByCountryId}
              />
            )
          )}
      </Grid>
      <Grid
        container
        justify={'space-between'}
        style={{ ...marginGenerator('mt-24'), ...marginGenerator('mb-24') }}>
        <Button
          variant={'outlined'}
          onClick={() =>
            setState({
              ...state,
              sites: state.sites + 1,
              [`site${state.sites + 1}`]: {}
            })
          }>
          {t('addSite.label')}
        </Button>

        <Button
          onClick={() => checkUpdate()}
          disabled={checkValid()}
          color="primary"
          variant={'contained'}>
          {t('update.label')}
        </Button>
      </Grid>
    </Layout>
  );
};
export default withTranslation()(ConfigureSites);
