import {
  Button,
  Paper,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  CircularProgress,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getBgpByConnId } from '../../../../services/fcrService';
import Popup from '../../Popup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ViewBgpPopup = ({ BgpView, setBgpView, connFcrId, connectionId, classes }) => {
  const [value, setValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();
  const [remoteAs, setRemoteAs] = useState();
  const [error, setError] = useState('');
  const [bgpOperStatus, setBgpOperStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    const GetBgpByConnId = async () => {
      setLoading(true);

      const res = await getBgpByConnId(connectionId, connFcrId);
      if (res?.data?.statusCode === 200) {
        res && setBgpOperStatus(res?.data?.result?.oper_status);
        setNieghbourIp(res?.data?.result?.neighbor_ip);
        setRemoteAs(res?.data?.result?.neighbor_remote);
        setLoading(false);
      } else {
        setError(res?.message);
        setLoading(false);
      }
    };
    GetBgpByConnId();
  }, []);

  return (
    <>
      <Popup title="View BGP" openPopup={BgpView} setOpenPopup={setBgpView}>
        <DialogContent>
          <Grid item>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px'
                }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {bgpOperStatus !== 'Deleting...' && (
                  <Grid item>
                    {!error ? (
                      <>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered>
                          <Tab label="Standard" {...a11yProps(0)} />
                          <Tab label="Advanced" {...a11yProps(1)} disabled />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center">
                              <Grid
                                item
                                style={{ color: 'grey', fontWeight: '500' }}
                                sm={4}>
                                Neighbor IP
                              </Grid>
                              <Grid item sm={1}>
                                <span>:</span>
                              </Grid>
                              <Grid item sm={7}>
                                <TextField
                                  value={nieghbourIp}
                                  size="small"
                                  variant="outlined"
                                  autoComplete="off"
                                  disabled
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center">
                              <Grid
                                item
                                style={{ color: 'grey', fontWeight: '500' }}
                                sm={4}>
                                Remote-AS
                              </Grid>
                              <Grid item sm={1}>
                                <span>:</span>
                              </Grid>
                              <Grid item sm={7}>
                                <TextField
                                  name="remoteAs"
                                  size="small"
                                  value={remoteAs}
                                  variant="outlined"
                                  disabled
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            {bgpOperStatus === 'Error' && (
                              <Grid
                                item
                                container
                                alignItems="center"
                                justify="center">
                                <Grid
                                  item
                                  style={{ color: 'grey', fontWeight: '500' }}
                                  sm={4}>
                                  Status
                                </Grid>
                                <Grid item sm={1}>
                                  :
                                </Grid>
                                <Grid item sm={7}>
                                  <Typography color="error">
                                    {bgpOperStatus}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          {error && (
                            <Grid item>
                              <h5 style={{ color: 'red', textAlign: 'center' }}>
                                {error}
                              </h5>
                            </Grid>
                          )}
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={1}
                          style={{ height: '175px' }}></TabPanel>
                      </>
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <span>{error}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {bgpOperStatus === 'Deleting...' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <CircularProgress />
                    <span style={{ marginLeft: '5px' }}>Deleting BGP...</span>
                  </div>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              setBgpView(false);
            }}>
            Close
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default ViewBgpPopup;
