import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../theme/utils';
import { ORGANISATIONS_LIST_FOR_SELECT } from '../../../../../../containers/Internal/Organisations/queries';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../components/Message';
//import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../../components/OutlinedSelect';
import { GET_COUNTRY_LIST, GET_ALL_ROLES } from '../../../../../commonQueries';
import { CREATE_SUB_ADMIN } from '../../../../../../containers/Internal/Users/mutations';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  REQUIRED_ERROR,
  INVALID_EMAIL,
  INVALID_PHONE,
  USER_ROLES,
  SUBSCRIPTION_STATUS
} from '../../../../../../utils/constants';
import { GET_SUBSCRIPTION_LIST } from '../../../../../Subscriptions/queries';



const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      height: 48,
      width: 48,
      borderRadius: '50%'
    },
    iconButton: {
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(1.25, 3),
      borderRadius: '24px',
      marginTop: theme.spacing(2)
    },
    aiParamList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      marginTop: '24px'
    }
  }));
  
  function ButtonComponent(props) {
      const { t, onClick, loading } = props;
      return (
          <Button 
          color="secondary"
          variant={'contained'}
          onClick={onClick} disabled={loading}>
              {loading && <CircularProgress size={14} />}
              {!loading && t('submit.label')}
          </Button>
      );
  }

const CreateNewUserFromOcr = ({
    t,
    open,
    onClose
}) => {
    const classes = useStyles();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [countryId, setCountryId] = useState('');
    const [orgId, setOrgId] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orgListOrig, setOrgList] = useState([]);
    const [isCountryAdmin, setIsCountryAdmin] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState("");

    const { data: countryList } = useQuery(GET_COUNTRY_LIST);
    const { data: roleList } = useQuery(GET_ALL_ROLES, {
      onError: error => {
        console.log('Error', error);
        setLoading(false);
      },
      onCompleted: data => {
        setLoading(false);
        let parseData = data.getAllRoles.RoleData ? JSON.parse(data.getAllRoles.RoleData) : [];
        setRolesList(parseData)
      }
    });
    const { loading: orgLoading, data: orgList } = useQuery(ORGANISATIONS_LIST_FOR_SELECT, {
      onCompleted: data => {
        setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
      }
    });
    const [getSubscriptionList, {loading: subsLoding}] = useLazyQuery(GET_SUBSCRIPTION_LIST, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        setSubscriptionList(res.getAllSubscriptions ? res.getAllSubscriptions : []);
      }
    });

    const [createSubAdmin, { loading: creteUserLoading }] = useMutation(CREATE_SUB_ADMIN, {
      onError: error => {
        console.log('Error', error);
        setLoading(false);
      },
      onCompleted: data => {
        setLoading(false);
        onClose();
        Message.success('User created successfully');
      }
    });

    const handleSubmitOfUser = () => {
      const localStorageOrgId = localStorage.getItem('orgId')
      const localStorageCountryId = localStorage.getItem('countryId')
      createSubAdmin({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          role: 'user',
          phone: phone,
          orgId: localStorageOrgId,
          countryId: localStorageCountryId,
          password: password,
          subscriptionId: subscriptionId
        }
      }); 
    }
    
  return (
    <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    aria-labelledby="country-modal"
    aria-describedby="country-modal"
    open={open}
    onClose={onClose}>
    <DialogTitle id="country-title"> {t('addAdmins.label')}</DialogTitle>
    <DialogContent>
    <Grid container spacing={3}>
    <Grid container item justify={'space-between'}>
          <Input
            value={firstName}
            type={'text'}
            placeholder={`${t('firstName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setFirstName(evt.target.value)}
          />
          <Input
            value={lastName}
            type={'text'}
            placeholder={`${t('lastName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setLastName(evt.target.value)}
          />
        </Grid>
        {/* <Autocomplete
          id="asynchronous-demo"
          style={{ width: '100%', marginTop: 24, border: '1px solid #ebebeb' }}
          name="orgId"
          onChange={(event, newValue) => {
            setOrgId(newValue ? newValue.id : "");
          }}
          getOptionSelected={(option, value) => option.id === value}
          getOptionLabel={option => option.name}
          options={orgListOrig}
          loading={orgLoading}
          renderInput={params => (
            <TextField
              {...params}
              autoFocus={false}
              label={t('organization.label')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {orgLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        <Grid
          container
          item
          justify={'space-between'}
          style={{ ...marginGenerator('mt-24'), ...marginGenerator('mb-15') }}>
          <OutlinedSelect
            val={countryId}
            label={`${t('country.label')} *`}
            handleChange={evt => {
              setCountryId(evt.target.value);
            }}
            selectStyle={{ ...paddingGenerator('p-8') }}
            styleOverrides={{ width: '225px', ...marginGenerator('mr-0') }}
            options={countryList ? countryList.getCountries : []}
            disabled={isCountryAdmin}
          />
          <OutlinedSelect
            val={role}
            label={`${t('role.label')} *`}
            handleChange={evt => {
              setRole(evt.target.value);
            }}
            selectStyle={{ ...paddingGenerator('p-8') }}
            styleOverrides={{ width: '222px', ...marginGenerator('mr-0') }}
            options={rolesList ? rolesList : []}
          />
        </Grid>
         */}
        {role && (role === USER_ROLES.IP_USER || role === USER_ROLES.EP_FINANCE || role === USER_ROLES.EP_IT) &&
          <Autocomplete
            id="asynchronous-demo"
            style={{ width: '100%', marginTop: 24, border: '1px solid #ebebeb' }}
            onChange={(event, newValue) => {
              setSubscriptionId(newValue.id);
            }}
            getOptionSelected={(option, value) => option.id === value}
            getOptionLabel={option => option.name}
            options={subscriptionList}
            loading={subsLoding}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus={false}
                label={t('subscriptions.label')}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {subsLoding ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />}
        <Input
          value={phone}
          type={'text'}
          placeholder={`${t('phone.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-40') }}
          color={'secondary'}
          autoComplete={'off'}
          onChange={evt => setPhone(evt.target.value)}
        />
        <Input
          value={email}
          type={'text'}
          placeholder={`${t('email.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-24') }}
          color={'secondary'}
          onChange={evt => setEmail(evt.target.value)}
        />
        <Input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder={`${t('password.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Input
          value={confirmPassword}
          type={showConfPassword ? 'text' : 'password'}
          placeholder={`${t('confPassword.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setConfirmPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
    </Grid>
    </DialogContent>
    <DialogActions>
        <Grid container justify={'space-between'}>
            <Button
                variant={'outlined'}
                onClick={onClose}
            >
            {t('cancel.label')}
            </Button>
            <ButtonComponent 
            t={t} 
            onClick={handleSubmitOfUser}
            loading={creteUserLoading} />
        </Grid>
    </DialogActions>
</Dialog>
  )
}

export default CreateNewUserFromOcr