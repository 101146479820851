import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
//import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = {
  id: '',
  userName: '',
  designation: '',
  email: '',
  companyName: '',
  supervisorName: '',
  contactNumber: '',
  passportNumber: '',
  supervisorEmail: '',
  supervisorPhoneNo: '',
  supervisorPassportNo: '',
  termsAndCond: false,
  sign: '',
  vpnSupervisorName: '',
  vpnSupervisorDepartment: '',
  vpnSubmitedDate: null,
  userDate: null,
  submittedDate: null,
  accessDevice: '',
  sectionHead: '',
  authorisationSectionHead: '',
  authorisationSectionHeadSign: '',
  authorisationSectionHeadDate: null,
  authorisationSectionHeadComments: '',
  iWe: '',
  of: '',
  thusDoneBySignedBy: '',
  at: '',
  this: '',
  dayOf: '',
  nonDisclosureUndertaking: '',
  witness1: '',
  witness2: ''
};

const ThirdPartyAccessRequst = ({
  t,
  open,
  handleClose,
  handleOpenSignPanModal,
  data,
  signatures,
  handleCreateRequestSubmit
}) => {
  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
    if (!open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
      const id = data._id;
      setURFormState({ ...data, id });
    } else {
      // setShowUserSelect(true);
      // setShowLearnDevelopment(true);
      // setShowHOD(true);
      // setShowHRManager(true);
      // setShowGeneralManager(true);
    }
  }, [data]);

  const handleChange = (value, name, key) => {
    setURFormState({ ...urFormState, [name]: value });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        <HrHeader />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">
              THIRD PARTY VPN ACCESS REQUEST TO ADD / AMEND A USER ID
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">User Details</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'User Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.userName}
              onChange={evt => handleChange(evt.target.value, 'userName')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Designation'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.designation}
              onChange={evt => handleChange(evt.target.value, 'designation')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Email'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.email}
              onChange={evt => handleChange(evt.target.value, 'email')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Company Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.companyName}
              onChange={evt => handleChange(evt.target.value, 'companyName')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Supervisor Email'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.supervisorEmail}
              onChange={evt =>
                handleChange(evt.target.value, 'supervisorEmail')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Supervisor Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.supervisorName}
              onChange={evt => handleChange(evt.target.value, 'supervisorName')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Contact/Phone Number'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.contactNumber}
              onChange={evt => handleChange(evt.target.value, 'contactNumber')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Passport Number/ID'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.passportNumber}
              onChange={evt => handleChange(evt.target.value, 'passportNumber')}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">User Declaration</Typography>
            <RadioGroup
              row
              aria-label="approve_rejected"
              name="termsAndCond"
              onChange={evt => {
                handleChange(evt.target.value, `termsAndCond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Approved"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.termsAndCond === 'Approved' ? true : false}
                labelPlacement="start"
                label=""
              />
            </RadioGroup>
            <Typography style={{ display: 'inline' }} variant="body1">
              I agree to make use of IT systems in accordance with the Morupule
              Coal Mine (MCM) IT User Policy. To review your obligation on this
              policy please refer to this document. MCM IT User policy on the
              File Server or ask any IT personnel to assist. To ensure that the
              system is not unlawful, unauthorized or improper purpose and
              comply with the policy, MCM may monitor, record, inspect and/or
              disclose any use of the system and/or files contained upon it as
              necessary. Any such use or non-compliance could result in
              disciplinary action and/or civil criminal penalties being taken
              against you.
            </Typography>
          </Grid>
          {/* <Grid item xs={5}>
            <RadioGroup
              row
              aria-label="approve_rejected"
              name="termsAndCond"
              onChange={evt => {
                handleChange(evt.target.value, `termsAndCond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Approved"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.termsAndCond === 'Approved' ? true : false}
                labelPlacement="start"
                label="Terms and Condition"
              />
            </RadioGroup>
          </Grid> */}
          <Grid xs={2}>
            <IconButton
              variant={'outlined'}
              onClick={() => handleOpenSignPanModal('userSign')}>
              <img src={SignIcon} width="26" height={26} alt="" />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Date Submitted'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.userDate}
              handleChange={newDate => handleChange(newDate, 'userDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Required VPN Access</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Supervisor Name'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.vpnSupervisorName}
              onChange={evt =>
                handleChange(evt.target.value, 'vpnSupervisorName')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Supervisor Deparment'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.vpnSupervisorDepartment}
              onChange={evt =>
                handleChange(evt.target.value, 'vpnSupervisorDepartment')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Access Expiry Date'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.vpnSubmitedDate}
              handleChange={newDate => handleChange(newDate, 'vpnSubmitedDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={8}>
            <Input
              type={'text'}
              placeholder={'Device To be Accessed'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.accessDevice}
              onChange={evt => handleChange(evt.target.value, 'accessDevice')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Authorisation</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Section Head'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.sectionHead}
              onChange={evt => handleChange(evt.target.value, 'sectionHead')}
            />
          </Grid>
          <Grid xs={2}>
            <IconButton
              variant={'outlined'}
              onClick={() => handleOpenSignPanModal('sectionHeadSign')}>
              <img src={SignIcon} width="26" height={26} alt="" />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <DateTimeSelector
              inputStyle={{ minWidth: 260 }}
              label={'Date Submitted'}
              dateFormat={'yyyy-MM-dd'}
              value={urFormState.authorisationSectionHeadDate}
              handleChange={newDate =>
                handleChange(newDate, 'authorisationSectionHeadDate')
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <TextField
              type="textarea"
              placeholder="Additional Comments(if any)"
              variant="standard"
              fullWidth={true}
              multiline
              rows={2}
              rowsMax={4}
              value={urFormState.authorisationSectionHeadComments}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'authorisationSectionHeadComments'
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Non disclosure undertaking to mcm
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'I/We'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.iWe}
              onChange={evt => handleChange(evt.target.value, 'iWe')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Of'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.of}
              onChange={evt => handleChange(evt.target.value, 'of')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'Thus done by and signed by'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.thusDoneBySignedBy}
              onChange={evt =>
                handleChange(evt.target.value, 'thusDoneBySignedBy')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'AT'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.at}
              onChange={evt => handleChange(evt.target.value, 'at')}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'THIS'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.this}
              onChange={evt => handleChange(evt.target.value, 'this')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Input
              type={'text'}
              placeholder={'DAY OF'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.dayOf}
              onChange={evt => handleChange(evt.target.value, 'dayOf')}
            />
          </Grid>
          <Grid xs={2}>
            <IconButton
              variant={'outlined'}
              onClick={() => handleOpenSignPanModal('thusSignedBy')}>
              <img src={SignIcon} width="26" height={26} alt="" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              1.0 Acknowledge that I am/we are aware that, for any work
              undertaken for MCM and/or its subsidiaries during the course of
              negotiations, tendering or other pre-contract periods as well as,
              if applicable, any period of performance, I/We have or shall have
              confidential information as defined in paragraph 4.0 herein,
              disclosed to me/us, or that such information shall come to my/our
              knowledge.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              2.0 undertake for and on behalf of myself/ourselves and my/our
              consultants, partners, members, associates, employees,
              contractors, sub-contractors, agents and sub-agents (as
              applicable), whether permanent or temporary, to make every effort
              to hold in trust and confidence all confidential information
              received and not to use, pass on, publish, disclose or in any
              manner make available any such confidential information to anyone
              without the prior written consent of MCM.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              3.0 undertake, on request by MCM, to produce to it a
              Non-disclosure Undertaking in terms similar to this undertaking
              executed by each of my/our consultants, partners, members,
              associates, employees, contractors, sub-contractors, agents and
              sub-agents (as applicable), whether permanent or temporary.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.0 Agree that “confidential information” shall mean:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1 all information disclosed to me/us or which comes to my/our
              knowledge, directly or indirectly:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1.1 as a result of or during my/our participation or assistance
              in the installation, commissioning, training, testing, setup,
              programming, repair, maintenance, or the execution of any other
              service, service of computers, access control equipment, card key
              codes or any ancillary or related equipment;:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1.2 as a result of my/our receiving or having received any
              invitation to tender for any contract or attending or having
              attended any discussion relative to the installation,
              commissioning, training, of any other service, service of
              computers, access control equipment, card key codes or any
              ancillary or related equipment;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1.2.1 at the time it was disclosed, was already known to me/us
              or was public knowledge, or thereafter became public knowledge
              other than through my/our default, or
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1.2.2 became known to me/us, without restriction as to its use
              and disclosure, from a third party with valid title thereto, or
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              4.1.2.3 was or is developed by or known to me/us and any of my/our
              consultants, partners, members, associates, employees,
              contractors, sub-contractors, agents or sub-agents, either before
              or subsequent to the disclosure of such information to me/us, such
              consultant, partners, associates, employees, contractors,
              sub-contractors, agent or sub-agent having no knowledge of the
              content of such disclosure.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              5.0 undertake to disclose such confidential information only to
              those of my/our consultants, partners, members, associates,
              employees, contractors, sub-contractors, agents or sub- agents,
              whether permanent or temporary, who require knowledge of such
              information to enable the carrying out of any work directly or
              indirectly connected with the installation, commissioning,
              programming, repair, maintenance or the execution of any other
              service, service of computers, access control equipment, card key
              codes or any ancillary or related equipment.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              6.0 undertake to refrain from using commercially, technically or
              otherwise confidential information without the prior written
              consent of MCM.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              7.0 undertake to report to MCM any unauthorized disclosure, actual
              or threatened, of any confidential information that I/we may
              become aware of and to co-operate with MCM in taking such action
              as may be deemed to be appropriate in the circumstances.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              8.0 declare that I/we have read and understood the contents and
              provisions of this undertaking and that any breach of
              confidentiality by myself/ourselves and/or my/our consultants,
              partners, members, associates, employees, contractors,
              sub-contractors, agents or sub-agents, whether permanent or
              temporary, shall cause irreparable harm and give rise to claim by
              the injured party and/or MCM against the offending party which
              will compensate such injured party for all loss, liability,
              damages or expenses arising out of or which may be attributable
              to:-
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              8.1 any intentional or negligent disclosure of the confidential
              information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              8.2 any unauthorized use of confidential information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              8.3 undertake not to use any Intellectual Property Rights
              belonging to MCM without MCMs’ written permission
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h6">
              In the presence of the undersigned witness, (Please name and
              signature)
            </Typography>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Input
                type={'text'}
                placeholder={'Witness'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.witness1}
                onChange={evt => handleChange(evt.target.value, 'witness1')}
              />
            </Grid>
            <Grid xs={2} />
            <Grid xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('witness1Sign')}>
                <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Input
                type={'text'}
                placeholder={'Witness'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.witness2}
                onChange={evt => handleChange(evt.target.value, 'witness2')}
              />
            </Grid>
            <Grid xs={2} />
            <Grid xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('witness2Sign')}>
                <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => handleCreateRequestSubmit(urFormState)}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ThirdPartyAccessRequst;
