import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_VENDOR_LIST } from '../queries';
import { UPDATE_VENDORS, ADD_VENDORS, DELECTE_VENDOR } from '../mutations';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceManager } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import VendorItem from '../components/VendorItem';
import Message from '../../../components/Message';
import EditVendors from '../EditVendors';
import AddVendors from '../AddVendors';
import SearchBox from '../../../components/SearchBox';

const breadcrumbList = [
  { label: 'home.label', link: ServiceManager.Dashboard },
  { label: 'users.label', link: ServiceManager.users }
];

const UsersList = ({ t }) => {
  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);

  const [clearAddModal, setClearAddModal] = useState(false);
  const [buttonLoadingAdd, setButtonLoadingAdd] = useState(false);
  const [openUserAddModal, setOpenUserAddModal] = React.useState(false);

  const [editModalData, setEditModalData] = useState([]);
  const [ticketsSearched, setTicketsSearched] = useState(false);
  const [updatedSearchTickets, setUpdatedSearchTickets] = useState([]);

  const menuItems = [{ label: t('edit.label') }, { label: t('delete.label') }];

  const history = useHistory();
  const location = useLocation();
  /*
        List Queries
    */

  const { loading, error, data, refetch: reloadVendorList } = useQuery(
    GET_VENDOR_LIST
  );

  useEffect(() => {
    if (location.search === `?action=reload`) {
      reloadVendorList();
    }
  }, [location]);

  const [deleteVendor] = useMutation(DELECTE_VENDOR, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('User Deleted successfully.');
      reloadVendorList();
    }
  });

  const [updateVedors] = useMutation(UPDATE_VENDORS, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('User updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      reloadVendorList();
    }
  });

  const [addVendors] = useMutation(ADD_VENDORS, {
    onError: error => {
      setButtonLoadingAdd(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoadingAdd(false);
      Message.success('User updated successfully');
      setOpenUserAddModal(false);
      setClearAddModal(true);
      reloadVendorList();
    }
  });

  const handleSearchUsers = query => {
    if (query !== '') {
      const updatedList =
        data.vendor &&
        data.vendor.filter(
          (item, index) =>
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            item.contactPersonEmail
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            item.contactPersonPhone
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            item.address.toLowerCase().includes(query.toLowerCase()) ||
            item.contactPersonName
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase())
        );
      setTicketsSearched(true);
      setUpdatedSearchTickets(updatedList);
    } else {
      setTicketsSearched(false);
      setUpdatedSearchTickets([]);
    }
  };

  let UserListing = ticketsSearched
    ? updatedSearchTickets
    : data && data.vendor && data.vendor.length && data.vendor;

  const handleMenuItemClick = (action, data) => {
    if (action.label === 'Delete') {
      if (window.confirm('Are you sure? you want to delete this user!')) {
        return deleteVendor({
          variables: {
            id: data.id
          }
        });
      }
    }
    if (action.label === 'Edit') {
      setOpenUserModal(true);
      setEditModalData(data);
    }
  };

  const handleSubmit = formData => {
    setButtonLoading(true);
    return updateVedors({
      variables: {
        id: formData.id,
        name: formData.name,
        contactPersonEmail: formData.contactPersonEmail,
        contactPersonName: formData.contactPersonName,
        address: formData.address,
        contactPersonPhone: formData.contactPersonPhone
      }
    });
  };

  const handleSubmitAdd = formData => {
    setButtonLoading(true);
    return addVendors({
      variables: {
        name: formData.name,
        contactPersonEmail: formData.contactPersonEmail,
        contactPersonName: formData.contactPersonName,
        address: formData.address,
        contactPersonPhone: formData.contactPersonPhone
      }
    });
  };

  const columns = [
    {
      label: t('vendorName.label'),
      key: 'vendorName'
    },
    {
      label: t('email.label'),
      key: 'email'
    },
    {
      label: t('phone.label'),
      key: 'phone'
    },
    {
      label: t('address.label'),
      key: 'address'
    },
    {
      label: t('contactPerson.label'),
      key: 'contactPerson'
    },
    {
      label: t('action.label'),
      key: 'button'
    }
  ];

  const handleAddNewSubAdminClick = () => {
    setOpenUserAddModal(true);
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('vendor.label')}
        subText={`${t('vendorSubTxt.label')}`}
        primaryButtonText={t('addvendors.label')}
        handlePrimaryButtonClick={handleAddNewSubAdminClick}
      />
      <SearchBox handleSearch={handleSearchUsers} />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !error ? (
                UserListing.length > 0 ? (
                  UserListing.map((rowitem, rowIndex) => (
                    <VendorItem
                      key={rowIndex}
                      t={t}
                      data={rowitem}
                      menuItems={menuItems}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant={'subtitle2'}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <EditVendors
        open={openUserModal}
        handleClose={evt => setOpenUserModal(false)}
        handleSubmit={handleSubmit}
        buttonLoading={buttonLoading}
        clearModal={clearModal}
        data={editModalData}
      />
      <AddVendors
        open={openUserAddModal}
        handleClose={evt => setOpenUserAddModal(false)}
        handleSubmitAdd={handleSubmitAdd}
        buttonLoading={buttonLoadingAdd}
        clearModal={clearAddModal}
      />
    </Layout>
  );
};
export default withTranslation()(UsersList);
