import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { getUrl } from '../../utils';
import { TicketingSystem } from '../../routes/routePaths';
import { useHistory } from 'react-router';

const PieChartWrapper = ({ data, dimensions }) => {
  const history = useHistory();
  const onChartClick = params => {
    const path = getUrl(TicketingSystem.Subscription, params.data.id);
    history.push({ pathname: path });
    return null;
  };
  let onEvents = {
    click: onChartClick
  };

  return (
    <div style={{marginBottom:130}}>
      <ReactEcharts
        option={{
          legend: {},
          tooltip: {},
          dataset: data,
          xAxis: { type: 'category',
            axisLabel: { interval: 0, rotate: 30 }
          },
          yAxis: {},
          series: [
            { type: 'bar' },
            { type: 'bar' },
            { type: 'bar' },
            { type: 'bar' },
            { type: 'bar' }
          ]
        }}
      />
    </div>
  );
};

export default PieChartWrapper;
