import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/inq_logo_primary.png';

const styles = StyleSheet.create({
    container: {
        // marginRight: 1,
        marginBottom: 2,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        height: 45,
        fontSize: 9,
        fontStyle: 'bold',
    },
    leftBox: {
        padding: "7px",
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
    },
    rightContainer: {
        flexDirection: 'column',
        paddingTop: 30,
        paddingLeft: 0,
        paddingBottom: 0,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    logo: {
        width: '100%',
        height: '100%'
    },
    rightColumn: {
        flexDirection: 'column',
        width: '33%',
        marginLeft: 5,
        marginBottom: 5
    },
    floatRight: {
        // textAlign: 'right'
    },
    HeadingRed: {
        fontSize: 12,
        fontStyle: 'bold',
        textDecorationColor: 'Red',
        textAlign: 'center'
    },
    centerHeading: {
        fontStyle: 'bold',
    },
    leftColumn: {
        flexDirection: 'column',
        width: '33%',
        marginRight: 2
    },
    centerColumn: {
        width: '100%',
        paddingBottom: "7px",
        paddingTop: "7px",
        paddingLeft: "40px",
        paddingRight: "40px",
        flexDirection: 'column',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
        marginRight: 3,
    }
});
const InvoiceNote = ({ invoiceCompanyDetails }) => (
    <Fragment>
        <View>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
        </View>
        <View style={styles.container}>
            <View style={styles.centerColumn}>
                <Text>Note: Please issue a separate bank draft/cheque for appropriate Withholding Tax on the sum reflected on this invoice (before VAT) in favour of Federal
                    Inland Revenue Service (FIRS)</Text>
            </View>
        </View>
    </Fragment>
);
export default InvoiceNote