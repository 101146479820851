import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {
    GET_ALL_CONTROLLERS,
    GET_ALL_DEVICE_INTERFACES,
    GET_ALL_NETWORK_INTERFACES,
    GET_ALL_VNF_NETWORK_INTERFACES,
    GET_TEMPLATE_LIST,
    GET_UCPE_LIST
} from '../../queries';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import PlacesMap from "../../../NewSubscription/components/PlacesMap";
import Message from "../../../../components/Message";
import {REQUIRED_ERROR} from "../../../../utils/constants";
import InputBase from "@material-ui/core/InputBase";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    }
}));
function EditSDNNotificationModal({t, data, open, handleEditSDNNotification, handleClose, modalState}) {
    const organization_id = data.organizationId
    const [notificationMsg, setNotificationMsg] = useState('');
    const classes = useStyles();
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setNotificationMsg('');
            handleClose();
        }
    }, [modalState]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ip-address-template-modal"
            aria-describedby="ip-address-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="place-template-title">{t('edit.label')} {t('notificationMsg.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={marginGenerator('mt-24')} className={classes.detailsContainer}>
                        <InputBase
                            fullWidth
                            value={notificationMsg}
                            id="details-textarea"
                            placeholder={t('notificationMsg.label')}
                            multiline
                            selectStyle={{...paddingGenerator('p-8')}}
                            onChange={evt => setNotificationMsg(evt.target.value)}
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleEditSDNNotification({notification_template: notificationMsg});
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
EditSDNNotificationModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(EditSDNNotificationModal);
