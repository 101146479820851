import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Image from '../../../../../assets/icons/image.png';
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import commonStyles from "../../../../../theme/commonStyles";
import {marginGenerator, paddingGenerator} from "../../../../../theme/utils";
import {useQuery} from "@apollo/react-hooks";
import { GET_CATEGORIES } from "../../queries";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function CreateVnfMarketPlaceModal({
                                       t,
                                       open,
                                       VnfMarketPlace,
                                       handleCreateVnfMarketPlace,
                                       handleEditVnfMarketPlace,
                                       isEdit,
                                       handleClose,
                                       modalState
                                   }) {
    const [imageName, setImageName] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [imageType, setImageType] = useState('');
    const [imagePrice, setImagePrice] = useState('');
    const [description, setDescription] = useState('');
    const [overview, setOverview] = useState('');
    const [review, setReviewInfo] = useState('');
    const [support, setSupportInfo] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imageLogo, setImageLogo] = useState('');
    const [overviewList, setOverviewList] = useState([{name: "", value: ""}]);
    const [highlightsList, setHighlightsList] = useState([{name: "", link: ""}]);
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [catId, setCatId] = useState('');
    const [catListOrig, setCategoryList] = useState([]);
    React.useEffect(() => {
        if (open) {
            setImageName('');
            setImageLogo('');
            setImageType('');
            setReviewInfo('');
            setSupportInfo('');
            setOverview('');
            setDescription('');
            setOverviewList([{name: "", value: ""}]);
            setHighlightsList([{name: "", link: ""}]);
            setImagePrice('');
            setUploadedFile('');
            setImageURL('');
        }
    }, [open]);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setImageName('');
            setImageLogo('');
            setImageType('');
            setReviewInfo('');
            setSupportInfo('');
            setOverview('');
            setDescription('');
            setOverviewList([{name: "", value: ""}]);
            setHighlightsList([{name: "", link: ""}]);
            setImagePrice('');
            setUploadedFile('');
            setImageURL('');
            handleClose();
        }
    }, [modalState]);

    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            //console.log(reader.result);
            setImageLogo(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    /*
    * Overview
    */
    // handle input change
    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...overviewList];
        list[index][name] = value;
        setOverviewList(list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...overviewList];
        list.splice(index, 1);
        setOverviewList(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        setOverviewList([...overviewList, {name: "", value: ""}]);
    };
    /*
    * Overview
    */
    // handle Highlights change
    const handleHighlightChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...highlightsList];
        list[index][name] = value;
        setHighlightsList(list);
    };
    // handle click event of the Remove button
    const handleRemoveHighlightClick = index => {
        const list = [...overviewList];
        list.splice(index, 1);
        setHighlightsList(list);
    };
    // handle click event of the Add button
    const handleAddHighlightClick = () => {
        setHighlightsList([...highlightsList, {name: "", link: ""}]);
    };
    React.useEffect(() => {
        if (VnfMarketPlace && VnfMarketPlace.getVnfMarketPlacesById) {
            console.log('VnfMarketPlace', JSON.stringify(VnfMarketPlace.getVnfMarketPlacesById));
            const {imageName, imageURL, imageLogo, review, support, highlightsList, overviewList, imagePrice, imageType, description, overview} = VnfMarketPlace.getVnfMarketPlacesById;
            setImageName(imageName);
            setImageLogo(imageLogo);
            setImageType(imageType);
            setReviewInfo(review);
            setSupportInfo(support);
            setOverview(overview);
            setDescription(description);
            setOverviewList(overviewList);
            setHighlightsList(highlightsList);
            setImagePrice(imagePrice);
            setUploadedFile('');
            setImageURL(imageURL);
        }
    }, [VnfMarketPlace]);
    const {loading: orgLoading, data: orgList} = useQuery(GET_CATEGORIES, {
            onCompleted: data => {
                setCategoryList(data.getCategories);
            }
        }
    );
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="images-modal"
            aria-describedby="images-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{isEdit === true ? t('edit.label') : t('createNew.label')}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            container spacing={3}
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="asynchronous-demo"
                                    style={{width: "100%", marginTop: 24, border: "1px solid #ebebeb"}}
                                    name="catId"
                                    onChange={(event, newValue) => {
                                        if (!newValue) {
                                            setCatId('');
                                            return true;
                                        }
                                        setCatId(newValue._id);
                                    }}
                                    getOptionSelected={(option, value) => option._id === value}
                                    getOptionLabel={(option) => option.name}
                                    options={catListOrig}
                                    loading={orgLoading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            autoFocus={false}
                                            label={t("category.label")}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {orgLoading ?
                                                            <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    value={imageName}
                                    type={'text'}
                                    placeholder={`${t('imageName.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImageName(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    value={imageURL}
                                    type={'text'}
                                    placeholder={`${t('imageUrl.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImageURL(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    value={imageType}
                                    type={'text'}
                                    placeholder={`${t('imageType.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImageType(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    value={imagePrice}
                                    type={'text'}
                                    placeholder={`${t('imagePrice.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setImagePrice(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={handleFileUploadClick}
                                >
                                    {`${t('logoImage.label')}`}
                                </Button>
                                {uploadedFile && (
                                    <Chip
                                        avatar={<img src={Image} alt={'file'}/>}
                                        label={
                                            uploadedFile.name.length > 12
                                                ? `${uploadedFile.name.slice(0, 12)}...`
                                                : uploadedFile.name
                                        }
                                        onDelete={() => {
                                            setUploadedFile('');
                                            setImageLogo('');
                                        }}
                                        deleteIcon={<Close/>}
                                        variant="outlined"
                                    />
                                )}
                                <input
                                    type="file"
                                    id="attachment"
                                    ref={inputFile}
                                    onChange={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        setUploadedFile(event.target.files[0]);
                                        convertToBase64(event.target.files[0]);
                                    }}
                                    style={{display: 'none'}}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description"
                                    value={description}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    placeholder="Description"
                                    multiline
                                    onChange={evt => setDescription(evt.target.value)}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="Overview" {...a11yProps(0)} />
                                        <Tab label="Usage" {...a11yProps(1)} />
                                        <Tab label="Support" {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <Grid item xs={12} style={marginGenerator('mt-24')}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Product overview"
                                                style={{width: '100%'}}
                                                color={'secondary'}
                                                placeholder="Product overview"
                                                multiline
                                                value={overview}
                                                onChange={evt => setOverview(evt.target.value)}
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={marginGenerator('mt-24')}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                align={'left'}>
                                                {'Product Overview'}
                                            </Typography>
                                            {overviewList.map((x, i) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={5} style={marginGenerator('mt-24')}>
                                                            <Input
                                                                value={x.name}
                                                                name={'name'}
                                                                type={'text'}
                                                                placeholder={`${t('name.label')}*`}
                                                                style={{width: '100%'}}
                                                                color={'secondary'}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} style={marginGenerator('mt-24')}>
                                                            <Input
                                                                value={x.value}
                                                                type={'text'}
                                                                name={'value'}
                                                                placeholder={`${t('value.label')}*`}
                                                                style={{width: '100%'}}
                                                                color={'secondary'}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} style={marginGenerator('mt-24')}>
                                                            {overviewList.length !== 1 &&
                                                            <CancelIcon onClick={() => handleRemoveClick(i)} style={{
                                                                ...marginGenerator('mt-13'),
                                                                cursor: "pointer",
                                                                color: "#ff0000"
                                                            }}/>}
                                                            {overviewList.length - 1 === i &&
                                                            <AddCircleIcon onClick={handleAddClick} style={{
                                                                ...marginGenerator('mt-13'),
                                                                cursor: "pointer"
                                                            }}/>}
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                        <Grid item xs={12} style={marginGenerator('mt-24')}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary"
                                                align={'left'}>
                                                {'Highlights'}
                                            </Typography>
                                            {highlightsList.map((x, i) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={5} style={marginGenerator('mt-24')}>
                                                            <Input
                                                                value={x.name}
                                                                name={'name'}
                                                                type={'text'}
                                                                placeholder={`${t('name.label')}*`}
                                                                style={{width: '100%'}}
                                                                color={'secondary'}
                                                                onChange={e => handleHighlightChange(e, i)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} style={marginGenerator('mt-24')}>
                                                            <Input
                                                                value={x.link}
                                                                type={'text'}
                                                                name={'link'}
                                                                placeholder={`${t('link.label')}*`}
                                                                style={{width: '100%'}}
                                                                color={'secondary'}
                                                                onChange={e => handleHighlightChange(e, i)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} style={marginGenerator('mt-24')}>
                                                            {highlightsList.length !== 1 &&
                                                            <CancelIcon onClick={() => handleRemoveHighlightClick(i)}
                                                                        style={{
                                                                            ...marginGenerator('mt-13'),
                                                                            cursor: "pointer",
                                                                            color: "#ff0000"
                                                                        }}/>}
                                                            {highlightsList.length - 1 === i &&
                                                            <AddCircleIcon onClick={handleAddHighlightClick} style={{
                                                                ...marginGenerator('mt-13'),
                                                                cursor: "pointer"
                                                            }}/>}
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <Grid item xs={12} style={marginGenerator('mt-24')}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Usage Information"
                                                style={{width: '100%'}}
                                                color={'secondary'}
                                                placeholder="Usage Information"
                                                value={review}
                                                multiline
                                                onChange={evt => setReviewInfo(evt.target.value)}
                                                rows={4}
                                            />
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        <Grid item xs={12} style={marginGenerator('mt-24')}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Support Information"
                                                style={{width: '100%'}}
                                                color={'secondary'}
                                                placeholder="Support Information"
                                                value={support}
                                                multiline
                                                onChange={evt => setSupportInfo(evt.target.value)}
                                                rows={4}
                                            />
                                        </Grid>
                                    </TabPanel>
                                </SwipeableViews>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            isEdit ?
                                handleEditVnfMarketPlace({
                                    categoryId: catId,
                                    imageName: imageName,
                                    imageLogo: imageLogo,
                                    imageURL: imageURL,
                                    imageType: imageType,
                                    imagePrice: imagePrice,
                                    overview: overview,
                                    review: review,
                                    support: support,
                                    description: description,
                                    highlightsList: JSON.stringify(highlightsList),
                                    overviewList: JSON.stringify(overviewList),
                                }) : handleCreateVnfMarketPlace({
                                    categoryId: catId,
                                    imageName: imageName,
                                    imageLogo: imageLogo,
                                    imageURL: imageURL,
                                    imageType: imageType,
                                    imagePrice: imagePrice,
                                    overview: overview,
                                    review: review,
                                    support: support,
                                    description: description,
                                    highlightsList: JSON.stringify(highlightsList),
                                    overviewList: JSON.stringify(overviewList),
                                });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateVnfMarketPlaceModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateVnfMarketPlaceModal);
