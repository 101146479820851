import React from 'react';
import { Grid } from '@material-ui/core';

const Unauthorized = () => {
  return (
    <>
      <Grid container direction="column" spacing={1}>
        
        <Grid item style={{ marginLeft: '10px' }}>
          <h4>You are unauthorized to see this page...</h4>
        </Grid>
      </Grid>
    </>
  );
};

export default Unauthorized;
