import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Checkbox, FormGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
import KYCHeader from '../../../KYCHeader';

const useStyles = makeStyles(theme => ({
	blackBorder: {
		border: '1px solid black',
		outline: '1px solid',
		padding: '0px !important',
	},
	blackBorderTitle:{
		margin: '18px 9px',
	},
	paper: {
		padding: '15px',
		marginBottom: '50px'
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
	iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    firstNames: "",
    surname: "",
    pid: "",
    dateofBirth: null,
    title: "",
    gender: "",
    citizen: "",
    nationality: "",
    postalAddress: "",
    physicalAddress: "",
    location: "",
    workPhone: "",
    homePhone: "",
    mobilePhone: "",
    fax: "",
    email: "",
    employer: "",
    occupation: "",
    placeOfWork: "",
    address1: [],
    accName: "",
    accNumber: "",
    bank: "",
    branch: "",
    branchCode: "",
    business: "",
    name: "",
    kycSignature: "",
    date: null,
    place: "",
};

const Gender = [
    "Male",
    "Female",
]

const Citizen = [
    "Yes",
    "No",
]

const ProofOfAddress = [
    "Water Bill",
    "Telephone Bill",
    "Electricity Bill",
    "Traditional Authority Form",
    "Confirmation Of Employment",
]

const SesiroKycForm = ({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateChangeRequestSubmit,
    handleOpenSignPanModal
}) => {
    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            setURFormState({...data, id});
        }
    }, [data]);

    const handleChange = (value, name) => {
        if(name === 'gender'){
            if (value.target.checked) {
                setURFormState({ ...urFormState, [name]: value.target.value });
            }
            else{
                setURFormState({ ...urFormState, [name]: "" });
            }

            // setURFormState((prevState) => {
            //     const newState = { ...prevState };
            //     if (value.target.checked) {
            //         newState.gender.push(value.target.value);
            //     }else{
            //         let index = newState.gender.indexOf(value.target.value);
            //         if (index !== -1) {
            //             newState.gender.splice(index, 1);
            //         }
            //     }
            //     return newState;
            // });
        } else if(name === 'citizen'){
            if (value.target.checked) {
                setURFormState({ ...urFormState, [name]: value.target.value });
            }
            else{
                setURFormState({ ...urFormState, [name]: "" });
            }
            // setURFormState((prevState) => {
            //     const newState = { ...prevState };
            //     if (value.target.checked) {
            //         newState.citizen.push(value.target.value);
            //     } else {
            //         let index = newState.citizen.indexOf(value.target.value);
            //         if (index !== -1) {
            //             newState.citizen.splice(index, 1);
            //         }
            //     }
            // });
        } else if(name === 'address1'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.address1.push(value.target.value);
                } else {
                    let index = newState.address1.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.address1.splice(index, 1);
                    }
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
    <Dialog
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="sensor-template-modal"
        aria-describedby="sensor-template-modal"
        open={open}
        onClose={() => {
            handleClose();
        }}>
        <DialogTitle id="sensor-template-title">
        <KYCHeader />
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center'}}>KNOW YOUR CUSTOMER</Typography>
            </Grid>
        </Grid>
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
				<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>IDENTITY DETAILS</h4>
			</Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"First Names"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.firstNames}
                            onChange={(evt) => handleChange(evt.target.value, "firstNames")}
                        />
                    </Grid>
                
                    <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Surname"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.surname}
                            onChange={(evt) => handleChange(evt.target.value, "surname")}
                        />
                    </Grid>
                    
                    <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"National ID /Passport Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.pid}
                            onChange={(evt) => handleChange(evt.target.value, "pid")}
                        />
                    </Grid>
            
                    <Grid className={classes.blackBorder} item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: '100%' }}
                            label={'Date of Birth'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.dateofBirth}
                            handleChange={newDate => handleChange(newDate,"dateofBirth")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid> 

                    
                    <Grid className={classes.blackBorder} item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Title(E.g Mr.Mrs,Miss,Dr)"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.title}
                            onChange={(evt) => handleChange(evt.target.value, "title")}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={6}>
                        <Grid item xs={12}>
			            <h5 className={classes.blackBorderTitle}>Gender</h5>
			            </Grid>

                        <FormGroup row>
                            {Gender.map((itm) => (
                <Grid item xs={4} key={itm}>
                <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value={itm}
                checked={urFormState.gender? urFormState.gender === itm : false}
                control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
                labelPlacement="start"
                onChange={(evt) => {
                    handleChange(evt, "gender");
                }}
                label={itm}
                />
                </Grid>
                ))}
        </FormGroup>
        </Grid>
        
                        <Grid className={classes.blackBorder} item xs={6}>
			                <Grid item xs={12}>
			                    <h5 className={classes.blackBorderTitle}>Citizen/Permanent Resident</h5>
			                </Grid>
                            <FormGroup row>
                            {Citizen.map((itm) => (
                    <Grid item xs={4} key={itm}>
                    <FormControlLabel
                    style={{ ...marginGenerator('mb-8') }}
                    value={itm}
                    checked={urFormState.citizen ? urFormState.citizen === itm : false}
                    control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
                    labelPlacement="start"
                    onChange={(evt) => {
                        handleChange(evt, "citizen");
                    }}
                    label={itm}
                    />
                    </Grid>
                    ))}
                     </FormGroup>
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Nationality"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.nationality}
                            onChange={(evt) => handleChange(evt.target.value, "nationality")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid className={classes.blackBorder} item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Postal Address"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={3}
                            value={urFormState.postalAddress}
                            onChange={(evt) => handleChange(evt.target.value, "postalAddress")}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <TextField
                            type="textarea"
                            placeholder="Physical Address"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={3}
                            value={urFormState.physicalAddress}
                            onChange={(evt) => handleChange(evt.target.value, "physicalAddress")}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <TextField
                            type="textarea"
                            placeholder="Village/ Town/ City"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={3}
                            value={urFormState.location}
                            onChange={(evt) => handleChange(evt.target.value, "location")}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Telephone (Work)"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.workPhone}
                            onChange={(evt) => handleChange(evt.target.value, "workPhone")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Telephone (Home)"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.homePhone}
                            onChange={(evt) => handleChange(evt.target.value, "homePhone")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    
                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Mobile"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.mobilePhone}
                            onChange={(evt) => handleChange(evt.target.value, "mobilePhone")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Fax"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.fax}
                            onChange={(evt) => handleChange(evt.target.value, "fax")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Email"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.email}
                            onChange={(evt) => handleChange(evt.target.value, "email")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Name of Employer"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.employer}
                            onChange={(evt) => handleChange(evt.target.value, "employer")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    
                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Occupation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.occupation}
                            onChange={(evt) => handleChange(evt.target.value, "occupation")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Place Of Work"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.placeOfWork}
                            onChange={(evt) => handleChange(evt.target.value, "placeOfWork")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
                    <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>PROOF OF ADDRESS</h4>
                </Grid>
            
                <Grid  className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}> Please Submit The Latest Of Any Of The Following Valid Documents (Please Tick Submitted Document)</h5>
                </Grid>

                    <Grid className={classes.blackBorder} style={{ textAlign: 'center'}} item xs={12}>
                        <FormGroup row>
                            {ProofOfAddress.map((itm) => (
                        <Grid item xs={4} key={itm}>
                        <FormControlLabel
                        style={{ ...marginGenerator('mb-8') }}
                        value={itm}
                        checked={urFormState.address1 ? urFormState.address1.includes(itm) : false}
                        control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
                        labelPlacement="start"
                        onChange={(evt) => {
                            handleChange(evt, "address1");
                        }}
                        label={itm}
                        />
                        </Grid>
                        ))}
                        </FormGroup> 
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
                <h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>BANKING DETAILS</h4>
            </Grid>
    
            <Grid className={classes.blackBorder} item xs={12}>
                <Input
                    type={'text'}
                    placeholder={"Account Name"}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    value={urFormState.accName}
                    onChange={(evt) => handleChange(evt.target.value, "accName")}
                    //value={sensorFormState.deviceNo}
                />
            </Grid>
                    
                <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        placeholder={"Account No."}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={urFormState.accNumber}
                        onChange={(evt) => handleChange(evt.target.value, "accNumber")}
                        //value={sensorFormState.deviceNo}
                    />
                </Grid>
            
                <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        placeholder={"Bank"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={urFormState.bank}
                        onChange={(evt) => handleChange(evt.target.value, "bank")}
                        //value={sensorFormState.deviceNo}
                    />
                </Grid>
        
                <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        placeholder={"Branch"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={urFormState.branch}
                        onChange={(evt) => handleChange(evt.target.value, "branch")}
                        //value={sensorFormState.deviceNo}
                    />
                </Grid>
                
                <Grid className={classes.blackBorder} item xs={6}>
                    <Input
                        type={'text'}
                        placeholder={"Branch Code"}
                        style={{ width: '100%' }}
                        className={classes.input}
                        color={'secondary'}
                        value={urFormState.branchCode}
                        onChange={(evt) => handleChange(evt.target.value, "branchCode")}
                        //value={sensorFormState.deviceNo}
                    />
                </Grid>
                
                <Grid className={classes.blackBorder} item xs={12}>
                    <TextField
                        type="textarea"
                        placeholder="State Nature Of Business If Funds Received From Sources Other Than Salary:"
                        variant="standard"
                        fullWidth={true}
                        multiline
                        rows={3}
                        value={urFormState.business}
                        onChange={(evt) => handleChange(evt.target.value, "business")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
				<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>ANTI MONEY LAUNDERING & COUNTER TERRORIST FINANCING REQUIREMENTS</h4>
			</Grid>
                    <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>In accordance with the Financial Intelligence Regulations,the following documents should be provided for verification:</h5>
                    </Grid>

                    <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>1. Identification Document - E.g. Certified Copy Of I.D/Passport & Worker's Permit For Foreigners</h5>
                    <h5 className={classes.blackBorderTitle}>2. Source Of Funds/ Proof Of Income/ Employer Confirmation Letter</h5>
                    <h5 className={classes.blackBorderTitle}>3. Proof Of Address - E.g. Latest Of Either Telephone Bill,Electricity Bill,Water Bill/Lease Agreement,Affidavit/Tribal Authority Letter Or Letter From Employer </h5>
                    <h5 className={classes.blackBorderTitle}>4. If Acting On Behalf Of Another - I.D Card Of That Person & Authority To Act On Behalf Of The Other Person</h5>
                    </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid className={classes.blackBorder} item xs={12} style={{ backgroundColor: "#6cccf560"}}>
				<h4 className={classes.blackBorderTitle} style={{ fontSize: '18px', fontWeight: '900', fontFamily: 'sans-serif'}}>DECLARATION</h4>
			</Grid>
            <Grid className={classes.blackBorder} item xs={12}>
                    <h5 className={classes.blackBorderTitle}>I hereby declare that the details furnished above are true and correct for the best of my knowledge and belief and I undertake to inform you of any changes therein,immediately. 
                    In any case of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be liable for it.</h5>
                    </Grid> 

            <Grid className={classes.blackBorder} item xs={6}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.date}
                            handleChange={newDate => handleChange(newDate,"date")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid className={classes.blackBorder} item xs={6}>
                       <Input
                            type={'text'}
                            placeholder={"Place"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.place}
                            onChange={(evt) => handleChange(evt.target.value, "place")}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

            <Grid className={classes.blackBorder} item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

                    <Grid className={classes.blackBorder}  item xs={6} style={{ textAlign: 'center'}}>
                <IconButton 
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal("Signature")}
                >Signature<img src={SignIcon} width="26" height={26} alt=""/>
                </IconButton>
            </Grid>
            
        </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() => {
                        handleCreateChangeRequestSubmit(urFormState);
                    }}
                    loading={modalState.btnLoading}
                />
            </Grid>
        </DialogActions>
    </Dialog>
    )
}

SesiroKycForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateChangeRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default SesiroKycForm