import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/core/styles/withStyles';
import { colors } from '../../theme/colors';
import './mapStyle.css';

const styles = {
  search: {
    display: 'inline',
    position: 'relative',
    backgroundColor: colors.common.white,
    marginLeft: 0
  },
  inputInput: {
    padding: '8px 16px',
    width: 200,
    border: `1px solid ${colors.grey[200]}`,
    backgroundColor: colors.common.white,
    margin: '10px'
  }
};

class Map extends React.PureComponent {
  componentDidMount() {
    if (window.google) {
      this.initMap();
    }
  }
  
  initMap() {
    const { handleSetPosition, search = true, id } = this.props;
    let marker = null;
    const mapOptions = {
      zoom: 6,
      mapTypeControl: false,
      streetViewControl: false
    };
    const map = new window.google.maps.Map(
      document.getElementById(`map-canvas${this.props.id}`),
      mapOptions
    );
    const customMarker = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: colors.error.main,
      fillOpacity: 1,
      scale: 7,
      strokeColor: colors.common.white,
      strokeWeight: 4,
    };
    if (search) {
      // Create the search box and link it to the UI element.
      const input = document.getElementById(`pac-input${this.props.id}`);
      const searchBox = new window.google.maps.places.SearchBox(input);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener('bounds_changed', function () {
        searchBox.setBounds(map.getBounds());
      });
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', function () {
        const places = searchBox.getPlaces();
        if (places.length === 0) {
          return;
        }
        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach(function (place) {
          if (!place.geometry) {
            console.log('Returned place contains no geometry');
            return;
          }

          if (marker) {
            marker.setMap(null);
          }

          marker = new window.google.maps.Marker({
            map: map,
            title: place.name,
            position: place.geometry.location
          });
          
          if(handleSetPosition){
            handleSetPosition({
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng()
            }, place, id);
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
      if(handleSetPosition){
        map.addListener('click', function (evt) {
          if (marker) {
            marker.setMap(null);
          }
          marker = new window.google.maps.Marker({
            map: map,
            position: evt.latLng
          });
          handleSetPosition({
            latitude: evt.latLng.lat(),
            longitude: evt.latLng.lng()
          }, evt, id);
          marker.setMap(map);
        });
      }
    }
    if (this.props.markers && this.props.markers.length) {
      const infowindow = new window.google.maps.InfoWindow();
      this.props.markers.forEach((item, index) => {
        if(id === 'sites-map-status'){
          if(item.infoData && item.infoData.inactiveCameras && item.infoData.inactiveCameras.length > 0){
            customMarker.fillColor = colors.common.red;
          }else{
            customMarker.fillColor = colors.common.green;
          }
        }
        const marker = new window.google.maps.Marker({
          position: item.position,
          map: map,
          title: item.title,
          icon: customMarker,
          iconSize: 15
        });
        console.log("marker: ", marker);
        marker.setMap(map);
        map.setCenter(marker.getPosition());
        this.bindInfoWindow(marker, map, infowindow, item, id);
      });
    }
  }

  bindInfoWindow = (marker, map, infowindow, content, id) => {
    let camCount = (content.infoData && content.infoData.totalCameras) ? content.infoData.totalCameras : '0';
    const activeCamerasData = (content.infoData && content.infoData.activeCameras) ? content.infoData.activeCameras : '0';
    const totalDownCameras = (content.infoData && content.infoData.inactiveCameras) ? content.infoData.inactiveCameras : [];
    window.google.maps.event.addListener(marker, 'click', () => {
      let statusContentString = '<div id="iw-container">' +
      '<div class="iw-content">' +
      '<div class="iw-subTitle">' + content.title + '</div>' +
      '<div class="iw-item">Number of cameras : ' + camCount + '</div>' +
      '<div class="iw-item">Number of up cameras : ' + activeCamerasData + '</div>' +
      '<div class="iw-item">Number of down cameras : ' + totalDownCameras.length + '</div>';
      if(totalDownCameras.length){
        let downCameraNames = '';
        for (const cam of totalDownCameras) {
          downCameraNames +=   '<span class="iw-item">' + cam.name + '<br/>' + '</span>';
        }
        statusContentString += '<div class="iw-item"><b>Down cameras lists:</b><br/> '
        + downCameraNames +
        '</div>';
      }
      statusContentString += '</div>';
      infowindow.setContent(statusContentString);
      infowindow.open(map, marker);
    });
  }

  render() {
    const { id, classes, search = true } = this.props;
    return (
      <>
        {search && (
          <div className={classes.search}>
            <InputBase
              placeholder="Search or pin location on the map"
              id={`pac-input${id}`}
              classes={{
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}
        <div id={`map-canvas${id}`} style={{ height: '100%', width: '100%' }} />
      </>
    );
  }
}

export default withStyles(theme => styles)(Map);
