import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { marginGenerator } from '../../../../theme/utils';
import { dateFormatterLLL, getParam } from '../../../../utils';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  GET_ASSIGNEE_LIST,
  GET_VENDOR_LIST,
  GET_ORGANISATION_NAME
} from '../../queries';
import { UPDATE_MAIL_TICKET_ASSIGNEE } from '../../mutations';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TicketStatus from '../TicketStatus';
import TicketPriority from '../TicketPriority';
import PropTypes from 'prop-types';
import { REQUIRED_ERROR, TICKET_DATA, USER_ROLES } from '../../../../utils/constants';
import Button from '@material-ui/core/Button';
import Avatar from '../../../../components/Avatar';
import { CircularProgress, fade, Icon } from '@material-ui/core';
import { colors } from '../../../../theme/colors';
import LocationOn from '@material-ui/icons/LocationOn';
import AdjustIcon from '@material-ui/icons/Adjust';
import Accordians from '../../../../components/Accordion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Message from '../../../../components/Message';
import { makeStyles } from '@material-ui/core/styles';
import TicketChangePriority from '../TicketChangePriority';
import TicketChangeStatus from '../TicketChangeStatus'
import TicketChangeIncidentTypeGroup from '../TicketChangeIncidentTypeGroup';
import TicketChangeIncidentType from '../TicketChangeIncidentType';
import TicketChangeAdditionalData from '../TicketChangeAdditionalData';
import TicketChangeVendor from '../TicketChangeVendor';
import TicketChangeInqProblem from '../TicketChangeInqProblem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TicketChangeCreateDate from '../TicketChangeCreateDate'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  fright: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageAlign: {
    width: '180px',
    height: 'auto',
    border: '2px dashed #661723'
  },
  list: {
    height: 150,
    border: '1px solid #5a1f2a',
    overflowX: 'hidden',
  }
}));

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, "pot", { type: contentType });
}

export function convertBase64ToUrl(imgStr) {
  let enc = window.atob(imgStr);
  let image = new File([enc], `${new Date().getTime()}.jpg`, {
    type: "image/jpeg"
  });
  let file = b64toBlob(imgStr, "image/jpeg");
  let imgURL = URL.createObjectURL(file);
  return imgURL;
}

const TicketDetailsPanel = ({ ticketData, userData, t, reloadTickets }) => {
  let currentUrl = window.location.href;
  let role = localStorage.getItem('role');
  const [assigneeData, setAssigneeData] = React.useState("");
  const [vendorData, setVendorData] = React.useState("");
  const [organisationName, setOrganisationName] = React.useState("");

  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [showAgents, setShowAgents] = React.useState(false);
  const [showVendors, setShowVendors] = React.useState(false);

  React.useEffect(() => {
    getAssigneeData({
      variables: {
        ticketType: ticketData.ticketType === 'internalTicket' ? TICKET_DATA.INTERNALTICKET : TICKET_DATA.SERVICETICKET
      }
    })
    if (ticketData.ownerId) {
      getOrgName({
        variables: {
          ownerId: ticketData.ownerId
        }
      });
    }
  }, []);

  const [getAssigneeData, { data: assignee }] = useLazyQuery(GET_ASSIGNEE_LIST);
  const { data: vendor } = useQuery(GET_VENDOR_LIST);

  const [assignServiceTicket] = useMutation(UPDATE_MAIL_TICKET_ASSIGNEE, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket assigned successfully.');
      setButtonLoading(false);
      setValue('')
      setShowVendors(false);
      setShowAgents(false);
      setAssigneeData({})
      setVendorData({})
      reloadTickets();
    }
  });

  const [getOrgName] = useLazyQuery(GET_ORGANISATION_NAME, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.getOrgName) {
        setOrganisationName(data.getOrgName.name);
      }
    }
  });

  const handleRadioChange = event => {
    if (event.target.value === 'agent') {
      setValue(event.target.value);
      setShowVendors(false);
      setShowAgents(true);
    }
    if (event.target.value === 'vendor') {
      setValue(event.target.value);
      setShowAgents(false);
      setShowVendors(true);
    }
  };

  let assignTab = null;
  if (ticketData.status != 'Closed') {
    assignTab = (
      <>
        <Grid container justify={'space-between'}>
          <Grid container item xs={6}></Grid>
          <Grid container item xs={6} direction="column">
            <RadioGroup
              row
              aria-label="quiz"
              name="quiz"
              value={value}
              onChange={handleRadioChange}>
              <FormControlLabel
                value="agent"
                control={<Radio />}
                labelPlacement="start"
                label="Select Agent"
              />
              {ticketData.ticketType != "internalTicket" ? (
                <FormControlLabel
                  value="vendor"
                  labelPlacement="start"
                  control={<Radio />}
                  label="Select Vendor"
                />
              ) : null}
            </RadioGroup>
          </Grid>
        </Grid>
        {showAgents && (
          <Grid container justify={'space-between'}>
            <Grid container item xs={6}></Grid>
            <Grid container item xs={6} direction="column">
              <Grid item style={marginGenerator('mr-32')}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {ticketData.assignee === null ? (
                    <>{t('selectAssignee.label')}*</>
                  ) : (
                    <>{t('escalateticket.label')}*</>
                  )}
                </Typography>

                <div className={classes.fright}>
                  <Autocomplete
                    id="combo-box-demo"
                    val={assigneeData}
                    options={assignee ? assignee.assignee : []}
                    getOptionLabel={option => (option && option.name ? `${option.name} ${option.surname}` : "")}
                    onChange={(event, newValue) => {
                      newValue && newValue !== null && setAssigneeData(newValue);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <div ref={params.InputProps.ref}>
                        <input
                          style={{
                            width: '90%',
                            padding: '18px',
                            border: '1px solid #ebebeb !important'
                          }}
                          type="text"
                          {...params.inputProps}
                        />
                      </div>
                    )}
                  />
                  <Button
                    variant={'contained'}
                    size={'medium'}
                    color={'primary'}
                    onClick={() => {
                      if (!assigneeData) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      }
                      setButtonLoading(true);
                      setAssigneeData({})
                      return assignServiceTicket({
                        variables: {
                          serviceTicketId: Id,
                          assignee: assigneeData.email.toLowerCase(),
                          currentUrl: currentUrl,
                          title: ticketData.title,
                          mailThreadId: ticketData.ticketId,
                          ticketId: ticketData.ticketId
                        }
                      });
                    }}>
                    {ticketData.assignee === null ? (
                      <>{buttonLoading ? <CircularProgress size={20} color="secondary" /> : t('assignee.label')}</>
                    ) : (
                      <>{buttonLoading ? <CircularProgress size={20} color="secondary" /> : t('escalate.label')}</>
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
        {ticketData.ticketType != "internalTicket" && showVendors && (
          <Grid container justify={'space-between'}>
            <Grid container item xs={6}></Grid>
            <Grid container item xs={6} direction="column">
              <Grid item style={marginGenerator('mr-32')}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{
                    ...marginGenerator('mb-8'),
                    ...marginGenerator('mt-40')
                  }}>
                  {ticketData.assignee === null ? (
                    <>{t('selectVendor.label')}*</>
                  ) : (
                    <>{t('escalateticket.label')}*</>
                  )}
                </Typography>

                <div className={classes.fright}>
                  <Autocomplete
                    id="combo-box-demo"
                    val={vendorData}
                    options={vendor ? vendor.vendor : []}
                    getOptionLabel={option => `${option.name} `}
                    onChange={(event, newValue) => {
                      setVendorData(newValue);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <div ref={params.InputProps.ref}>
                        <input
                          style={{
                            width: '90%',
                            padding: '18px',
                            border: '1px solid #ebebeb !important'
                          }}
                          type="text"
                          {...params.inputProps}
                        />
                      </div>
                    )}
                  />
                  <Button
                    variant={'contained'}
                    size={'medium'}
                    color={'primary'}
                    onClick={() => {
                      if (!vendorData) {
                        Message.error(REQUIRED_ERROR);
                        return null;
                      }
                      setButtonLoading(true);
                      setVendorData({})
                      return assignServiceTicket({
                        variables: {
                          serviceTicketId: Id,
                          assignee: vendorData.contactPersonEmail.toLowerCase(),
                          currentUrl: currentUrl,
                          title: ticketData.title,
                          mailThreadId: ticketData.ticketId,
                          ticketId: ticketData.ticketId
                        }
                      });
                    }}>
                    {ticketData.assignee === null ? (
                      <>{buttonLoading ? <CircularProgress size={20} color="secondary" /> : t('assignee.label')}</>
                    ) : (
                      <>{buttonLoading ? <CircularProgress size={20} color="secondary" /> : t('escalate.label')}</>
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  const Id = getParam();

  const getLocationName = (location) => {
    return location.map(item => item.ulCTCLocation).join(", ")
  }

  return (
    <>
      <Grid container justify={'space-between'}>
        <Grid container item xs={8}>
          <Grid item style={marginGenerator('mr-16')}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('status.label')}
            </Typography>
            <TicketStatus t={t} status={ticketData.status} />
          </Grid>
          {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && (
            <TicketChangeStatus reloadTickets={reloadTickets} id={Id} ticketData={ticketData} />
          )}
          <Grid item style={{ marginLeft: '32px' }}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('priority.label')}
            </Typography>
            <TicketPriority t={t} priority={ticketData.priority} />
          </Grid>
          {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && (
            <TicketChangePriority reloadTickets={reloadTickets} id={Id} ticketData={ticketData} />
          )}
          <Grid item style={{ marginLeft: '32px' }}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('inqProblem.label')}
            </Typography>
            {ticketData.vodacomProblem ? "True" : "False"}
          </Grid>
          {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && (
            <TicketChangeInqProblem reloadTickets={reloadTickets} id={Id} ticketData={ticketData} />
          )}

          <Grid item style={{ marginLeft: '32px' }}>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('ticketRaisedOn.label')}
            </Typography>
            {ticketData.createdAt && ticketData.createdAt ?
              dateFormatterLLL(ticketData.createdAt) : null}
          </Grid>
          {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && (
            <TicketChangeCreateDate
              reloadTickets={reloadTickets}
              id={Id}
              ticketData={ticketData}
              createdDate={ticketData.createdAt}
            />
          )}

          {ticketData.status === 'Closed' && ticketData.equipemntReplaced != null ? (
            <Grid item style={{ marginLeft: '32px' }}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('equipemntReplaced.label')}
              </Typography>
              {ticketData.equipemntReplaced && ticketData.equipemntReplaced}
            </Grid>
          ) : null
          }

          {ticketData.assignee !== null ||
            ticketData.vendor !== null ||
            ticketData.location !== null ? (
            <Grid container item style={{ marginTop: '25px' }}>
              <Grid item style={{ marginRight: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.assignee ? t('ticketAssign.label') : null}
                </Typography>
                {ticketData.assignee ? ticketData.assignee : null}
              </Grid>
              <Grid item style={{ marginRight: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.vendor ? t('vendor.label') : null}
                  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && ticketData.vendor ? (
                    <TicketChangeVendor reloadTickets={reloadTickets} id={Id} ticketData={ticketData} />
                  ) : null}
                </Typography>
                {ticketData.vendor ? ticketData.vendor : null}
              </Grid>
              {ticketData.location && ticketData.location.length > 0 ? (
                <Grid item style={{ marginRight: '16px' }}>
                  <Typography
                    variant={'subtitle2'}
                    color={'textPrimary'}
                    style={marginGenerator('mb-16')}>
                    {t('location.label')}
                  </Typography>
                  {getLocationName(ticketData.location)}
                </Grid>
              ) : null}
            </Grid>
          ) : null}

          {ticketData.incidentType !== null ||
            ticketData.incidentTypeGroup !== null ? (
            <Grid container item style={{ marginTop: '25px' }}>
              <Grid item style={{ marginRight: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.incidentType ? t('incidentType.label') : null}
                  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && ticketData.incidentType ? (
                    <TicketChangeIncidentType
                      reloadTickets={reloadTickets}
                      incidentTypeGroup={ticketData?.incidentTypeGroup}
                      id={Id}
                      ticketData={ticketData}
                      type={ticketData.ticketType}
                    />
                  ) : null}
                </Typography>
                {ticketData.incidentType ? ticketData.incidentType : null}
              </Grid>
              <Grid item>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.incidentTypeGroup
                    ? t('incidentTypeGroup.label')
                    : null}
                  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && ticketData.incidentTypeGroup ? (
                    <TicketChangeIncidentTypeGroup
                      reloadTickets={reloadTickets}
                      id={Id}
                      ticketData={ticketData}
                      type={ticketData.ticketType}
                    />
                  ) : null}
                </Typography>
                {ticketData.incidentTypeGroup
                  ? ticketData.incidentTypeGroup
                  : null}
              </Grid>
            </Grid>
          ) : null}

          {ticketData.phcNGData !== null ||
            ticketData.lagosNGData !== null ||
            ticketData.abujaNGData !== null ? (
            <Grid container item style={{ marginTop: '25px' }}>
              <Grid item style={{ marginRight: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.abujaNGData && ticketData.abujaNGData.memberName
                    ? t('abujaFieldOps.label')
                    : null}
                  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && ticketData.abujaNGData &&
                    ticketData.abujaNGData.memberName ? (
                    <TicketChangeAdditionalData
                      reloadTickets={reloadTickets}
                      ticketData={ticketData}
                      id={Id}
                      type="abuja"
                    />
                  ) : null}
                </Typography>
                {ticketData.abujaNGData && ticketData.abujaNGData.memberName
                  ? ticketData.abujaNGData.memberName
                  : null}
              </Grid>
              <Grid item style={{ marginRight: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.lagosNGData && ticketData.lagosNGData.memberName
                    ? t('lagosFieldOps.label')
                    : null}
                  {!role.includes(USER_ROLES.SD_READONLY) && ticketData.status != 'Closed' && ticketData.lagosNGData &&
                    ticketData.lagosNGData.memberName ? (
                    <TicketChangeAdditionalData
                      reloadTickets={reloadTickets}
                      ticketData={ticketData}
                      type="lagos"
                      id={Id}
                    />
                  ) : null}
                </Typography>
                {ticketData.lagosNGData && ticketData.lagosNGData.memberName
                  ? ticketData.lagosNGData.memberName
                  : null}
              </Grid>
              <Grid item style={{ marginLeft: '16px' }}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.phcNGData && ticketData.phcNGData.memberName
                    ? t('phcFieldOps.label')
                    : null}
                  {ticketData.status != 'Closed' && ticketData.phcNGData && ticketData.phcNGData.memberName ? (
                    <TicketChangeAdditionalData
                      reloadTickets={reloadTickets}
                      ticketData={ticketData}
                      type="phc"
                      id={Id}
                    />
                  ) : null}
                </Typography>
                {ticketData.phcNGData && ticketData.phcNGData.memberName
                  ? ticketData.phcNGData.memberName
                  : null}
              </Grid>
            </Grid>
          ) : null}

          {ticketData.vendorStartDate && ticketData.vendorStartDate != null ||
            ticketData.vendorEndDate && ticketData.vendorEndDate != null ? (
            <Grid container item style={{ marginTop: '25px' }}>
              <Grid item style={marginGenerator('mr-16')}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.vendorStartDate && ticketData.vendorStartDate != null ? t('vendorStartDate.label') : null}
                </Typography>
                {ticketData.vendorStartDate && ticketData.vendorStartDate != null ?
                  dateFormatterLLL(ticketData.vendorStartDate) : null}
              </Grid>

              <Grid item style={marginGenerator('mr-16')}>
                <Typography
                  variant={'subtitle2'}
                  color={'textPrimary'}
                  style={marginGenerator('mb-16')}>
                  {ticketData.vendorEndDate && ticketData.vendorEndDate != null ? t('vendorEndDate.label') : null}
                </Typography>
                {ticketData.vendorEndDate && ticketData.vendorEndDate != null ?
                  dateFormatterLLL(ticketData.vendorEndDate) : null}
              </Grid>
            </Grid>
          ) : null}

          {ticketData &&
            ticketData.baseStation &&
            ticketData.baseStation.ulCTCBaseStationCode !== null ? (
            <Grid item style={{ marginTop: '25px', marginRight: '16px' }}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {ticketData.baseStation ? t('baseStation.label') : null}
              </Typography>
              {ticketData.baseStation.ulCTCBaseStationCode
                ? ticketData.baseStation.ulCTCBaseStationCode
                : null}
            </Grid>
          ) : null}
        </Grid>
        <Grid container item xs={3} direction={'column'}>
          {ticketData.ownerId ? (
            <Grid item style={marginGenerator('mb-32')}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('ownerId.label')}
              </Typography>
              <Grid
                container
                item
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}>
                <div
                  style={{
                    backgroundColor: fade(colors.secondary.main, 0.1),
                    height: '24px',
                    width: '24px',
                    ...marginGenerator('mr-8'),
                    justifyContent: 'center'
                  }}
                  className={classes.column}>
                  <Icon
                    component={() => (
                      <AdjustIcon
                        className={classes.iconRegular}
                        color={'secondary'}
                      />
                    )}
                    alt="location"
                  />
                </div>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  {organisationName}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {ticketData.subscription &&
            ticketData.subscription.service &&
            ticketData.subscription.service.name ? (
            <Grid item style={marginGenerator('mb-32')}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('subscription.label')}
              </Typography>
              <Grid
                container
                item
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}>
                <Avatar
                  name={ticketData.subscription.service.name}
                  variant={'medium'}
                  avatarStyles={{
                    borderRadius: 0,
                    background: fade(colors.info.main, 0.1)
                  }}
                  identifierStyle={{
                    color: colors.info.main,
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                />
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                  {ticketData.subscription.service.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {ticketData.sites && ticketData.sites.length ? (
            <Grid item style={marginGenerator('mb-32')}>
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {t('site.label')}
              </Typography>
              {ticketData.sites.map((item, index) => (
                <Grid
                  container
                  key={index}
                  style={marginGenerator('mt-16')}
                  item
                  direction={'row'}
                  alignItems={'center'}
                  justify={'flex-start'}>
                  <div
                    style={{
                      backgroundColor: fade(colors.secondary.main, 0.1),
                      height: '24px',
                      width: '24px',
                      ...marginGenerator('mr-8'),
                      justifyContent: 'center'
                    }}
                    className={classes.column}>
                    <Icon
                      component={() => (
                        <LocationOn
                          className={classes.iconRegular}
                          color={'secondary'}
                        />
                      )}
                      alt="location"
                    />
                  </div>
                  <Typography variant={'subtitle2'} color={'textPrimary'}>
                    {item.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
          <Grid item>
            <Typography
              variant={'subtitle2'}
              color={'textPrimary'}
              style={marginGenerator('mb-16')}>
              {t('createdBy.label')}
            </Typography>
            <Grid
              container
              item
              direction={'row'}
              alignItems={'center'}
              justify={'flex-start'}>
              <Avatar name={ticketData.createdByName} variant={'medium'} />
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {ticketData.ticketType === 'mailTicket'
                  ? ticketData.createdBy
                  : ticketData.createdByName}
              </Typography>
            </Grid>
          </Grid>
          {ticketData && ticketData.allCCEmails && ticketData.allCCEmails.length > 0 &&
            <Grid item style={marginGenerator('mt-32')}>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={marginGenerator('mb-16')}>
                {t('allCCEmail.label')}
              </Typography>
              <List className={classes.list}>
                {ticketData.allCCEmails.map(email => (
                  <ListItem key={email}>
                    <ListItemText primary={email} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={marginGenerator('mb-32')}>
          <Typography variant={'subtitle2'} color={'textPrimary'}>
            {ticketData.attachments.length ? t('attachments.label') : null}
          </Typography>
        </Grid>
        {ticketData.attachments &&
          ticketData.attachments.map((item, index) => (
            <Grid item xs={2} style={marginGenerator('mb-8')}>
              <Link
                variant={'body2'}
                color={'textPrimary'}
                href={ticketData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                target={'_blank'}>
                <img
                  className={classes.imageAlign}
                  src={ticketData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                  href={ticketData.ticketType === 'mailTicket' ? convertBase64ToUrl(item) : item}
                  target="_blank"
                />
              </Link>
            </Grid>
          ))}
      </Grid>

      <Divider
        variant={'fullWidth'}
        absolute
        style={{ bottom: 'auto', marginTop: 18 }}
      />
      <Grid
        container
        justify={'space-between'}
        style={{ paddingBottom: '50px', paddingTop: '20px' }}>
        <Grid item xs={12} style={marginGenerator('mb-32')}>
          <Accordians
            description={ticketData.description}
            panel={0}
            createdBy={ticketData.createdByName}
            createdAt={dateFormatterLLL(ticketData.createdAt)}
          />
          {
            ticketData.allCommunication && ticketData.allCommunication !== null && ticketData.allCommunication.length > 0 &&
            ticketData.allCommunication.map((data, index) => (
              <Accordians
                description={data.description}
                panel={index + 1}
                ticketType={ticketData.ticketType}
                createdBy={data.createdByName}
                type={data.type}
                attachments={data.attachments}
                createdAt={dateFormatterLLL(data.createdAt)}
              />
            ))}
        </Grid>
        {!role.includes(USER_ROLES.SD_READONLY) && assignTab}
      </Grid>
    </>
  );
};

TicketDetailsPanel.propTypes = {
  ticketData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default TicketDetailsPanel;
