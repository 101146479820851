import React, {useState, useEffect} from 'react';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import * as FileSaver from 'file-saver';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/LinearProgress';

import CustomTabs from '../../../../../components/CustomTabs';
import CsvDownloadImg from '../../../../../assets/icons/csv-icon1.png'
import PdfDownloadImg from '../../../../../assets/icons/pdf-icon.png'

import OcrDocImgSlider from './components/OcrDocImgSlider';

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick}
       disabled={loading}
       style={{ display: 'flex', marginLeft: 26 }}

       >
          {loading && <CircularProgress size={14} />}
          {!loading && 'Download csv'}
      </Button>
  );
}

function DownloadImgButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick}
       disabled={loading}
       style={{ display: 'flex', marginLeft: 26 }}

       >
          {loading && <CircularProgress size={14} />}
          {!loading && 'Download Image'}
      </Button>
  );
}


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
const OcrDocViewer = ({
    t,
    open,
    onClose,
    docData,
    type,
    loading,
    imgUrl,
    imgName,
    ocrFormvalue
}) => {
    const classes = useStyles();
    const [isDownloadStarted, setIsDownloadStarted] = useState(false);
    const [isImgDownloadStarted, setIsImgDownloadStarted] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const fileExtension = imgUrl && imgUrl.file_name.split('.').pop();

    const loopThroughtOFformKeyvalue = data  => {
      if(data){ 
        let elements = [];
        //ocr form data is coming null,, thats why this is tested with this static data
        const staticdata = {
          "City ": "",
          "State ": "",
          "Zip Code ": "",
          "Last Name ": "",
          "First Name ": "",
          "Work Phone ": "",
          "Personal Phone ": "",
          "Street Address 1 ": "",
          "Street Address 2 ": ""
        }
       for (const [key, value] of Object.entries(data)) {
        elements.push( <div>
              <span>{key ? `${key}:` : ''}</span>
              <span >{value !== '' ? value : '-'}</span>
          </div>
          )
      }
      return elements;
     }
    }
        
    let tabList = [
      {
          label: 'Segmentation View',
          children:(
            loading? 
            <CircularProgress color="secondary"
            />
            : 
            (
              <OcrDocImgSlider
              t={t}
              data={imgUrl && imgUrl.processed_images}
              />
            )
        )
      },
      {
          label: 'Raw Text',
          children:(
              loading? 
              <CircularProgress color="secondary"
              />
              : 
              (
              <Card className={classes.root} style={{ marginTop: 12 }}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography variant="body2" id="doc-data" style={{ fontWeight: 200  }}>
                        {
                          imgUrl&&imgUrl.ocr_text.split('\n').map((item) => {
                            return (
                              <span>
                                {item}
                                <br/>
                              </span>
                            )
                          })}
                        </Typography>
                    </CardContent>
                </div>
              </Card>
              )
          )
      },
      // {
      //     label: 'JSON',
      //     children:(
      //       loading? 
      //       <CircularProgress color="secondary"
      //       />
      //       : 
      //       (
      //       <Card className={classes.root} style={{ marginTop: 12 }}>
      //         <div className={classes.details}>
      //             <CardContent className={classes.content}>
      //                 <Typography variant="subtitle1">
      //                 {JSON.stringify(docData)}
      //                 </Typography>
      //             </CardContent>
      //         </div>
      //       </Card>
      //       )
      //   ) 
      // },
      {
        label: 'Table View(CSV)',
        children:(
          loading? 
          <CircularProgress color="secondary"
          />
          : 
          (
              imgUrl && imgUrl.csv_url && (
                <>
                <div>
                <img src={CsvDownloadImg} alt='' style={{ height: 211, width: 170 }} />
                <ButtonComponent
                 color="secondary"
                 variant={'contained'}
                 loading={isDownloadStarted}
                 onClick={() => {
                   setIsDownloadStarted(true);
                  }}
                />
                </div>
                </>
              ) 
          )
      )  
      },
      {
        label: 'Key: Value Pair',
        children:(
          loading? 
          <CircularProgress color="secondary"
          />
          : 
          (
            <div>{loopThroughtOFformKeyvalue(imgUrl.ocr_json)}</div>
          )
      )   
      },
      {
        label: 'Original Document',
        children:(
          loading? 
          <CircularProgress color="secondary"
          />
          : 
          (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'end' }}>
            { fileExtension === 'pdf' ?
             (
               <div>
               <img src={PdfDownloadImg} alt='' style={{ height: 211, width: 170 }} />
               <a 
                 href={imgUrl && imgUrl.url} 
                 target="_blank" 
                 rel="noopener noreferrer"
                 style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}
                 >
                 click on it for view the doc
               </a> 
               </div>
             ) :
             (
               <div>
               <img
                 src={imgUrl && imgUrl.url}
                 alt={imgName}
                 style={{ height: 300, width: 280, display: 'flex', justifyContent: 'center', marginTop: 12 }}
               />
               <DownloadImgButtonComponent
                 color="secondary"
                 variant={'contained'}
                 loading={isImgDownloadStarted}
                 onClick={() => {
                  setIsImgDownloadStarted(true);
                  }}
                />
               </div>
             )
             }
             </div>
          )
      )  
      },
  ];

    const hadnleDowloader = (url, file_name) => {
      if(isDownloadStarted || isImgDownloadStarted){
      setIsDownloadStarted(false);
      setIsImgDownloadStarted(false);
      FileSaver.saveAs(url,file_name);
      }
  };

  useEffect(() => {
    hadnleDowloader(imgUrl.csv_url, imgName);
  }, [isDownloadStarted]);


  useEffect(() => {
    hadnleDowloader(imgUrl.url, imgName);
  }, [isImgDownloadStarted]);

  return (
    <Dialog 
    fullScreen
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
             Uploaded OCR Doc
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <CustomTabs 
              activeIndex={activeIndex} 
              tabs={tabList}
            />
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    color="secondary"
                    variant={'contained'}
                    onClick={onClose}
                >
                    close
                </Button>
            </Grid>          
        </DialogActions>
    </Dialog>
  );
};

export default OcrDocViewer;
