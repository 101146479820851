import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {FieldArray, Formik, getIn} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_DEVICE_LIST_BY_TYPE, GET_DEVICE_LIST_BY_TYPE_NIGERIA} from "../../../queries";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateUpdateSwitch({
                                t,
                                open,
                                handleCreateUpdateSwitch,
                                handleClose,
                                connectivityInfoData,
                                modalState
                            }) {
    const classes = useStyles();
    const [deviceList, setDeviceList] = useState([]);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    React.useEffect(() => {
        getDeviceList({
            variables: {
                type: 'switch'
            }
        })
    }, []);
    const [getDeviceList] = useLazyQuery(GET_DEVICE_LIST_BY_TYPE_NIGERIA, {
        fetchPolicy: 'no-cache',
        onCompleted: response => {
            setDeviceList(response.getDeviceListByTypeNigeria);
        }
    });
    return (
        <Formik
            initialValues={{
                opportunity: '',
                switch: '',
                name: '',
                switch_interfaces: [{
                    vlan: '',
                    description: '',
                    interface: '',
                    access_mode: ''
                }]
            }}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                console.log('values', JSON.stringify(values))
                handleCreateUpdateSwitch(values)
                resetForm()
                //alert(JSON.stringify(values));
            }}
            validationSchema={Yup.object().shape({
                switch: Yup.string()
                    .required('Please Enter switch'),
                name: Yup.string()
                    .required('Please enter name'),
                switch_interfaces: Yup.array()
                    .of(
                        Yup.object().shape({
                            vlan: Yup.string().required('Required'),
                            description: Yup.string().required('Required'),
                            interface: Yup.string().required('Required'),
                            access_mode: Yup.string().required('Required'),
                        })
                    )
                    .required('Must have switch interfaces') // these constraints are shown if and only if inner constraints are satisfied
                    .min(1, 'Minimum of 1 switch interfaces'),
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                } = props;
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid style={{...paddingGenerator('pb-30')}}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-10')
                                }}>
                                <Grid container spacing={3} style={{
                                    ...marginGenerator('mt-8')
                                }}>
                                    <Grid item xs={6} style={marginGenerator('mt-16')}>
                                        <OutlinedSelect
                                            val={values.switch}
                                            label={`${t('switch.label')} *`}
                                            name={'switch'}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            selectStyle={{...paddingGenerator('p-8')}}
                                            styleOverrides={{width: '100%', marginRight: 0}}
                                            options={deviceList && deviceList.length && deviceList.map(item => {
                                                return {id: item.device_name, name: item.device_name};
                                            })}
                                        />
                                        {(errors.switch && touched.switch) &&
                                        <FormHelperText
                                            style={{marginTop: '20px'}}>{errors.switch}</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.name}
                                            name={'name'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.name && touched.name) && errors.name}
                                            label={`${t('name.label')} *`}
                                            placeholder={`${t('name.label')}*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FieldArray
                                            name="switch_interfaces"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values.switch_interfaces.map((switch_interfaces, index) => (
                                                        <Grid key={index} container spacing={3}>
                                                            {/** both these conventions do the same */}
                                                            <Grid item xs={12} style={{textAlign: 'right'}}>
                                                                {(index !== 0) ? <Button
                                                                    color="secondary"
                                                                    variant={'contained'}
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                    -
                                                                </Button> : <Button
                                                                    color="secondary"
                                                                    variant={'contained'}
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.push({
                                                                        vlan: '',
                                                                        description: '',
                                                                        interface: '',
                                                                        access_mode: ''
                                                                    })}>
                                                                    +
                                                                </Button>}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={switch_interfaces.vlan}
                                                                    name={`switch_interfaces[${index}].vlan`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(getIn(errors, `switch_interfaces[${index}].vlan`)
                                                                        && getIn(touched, `switch_interfaces[${index}].vlan`))
                                                                    && getIn(errors, `switch_interfaces[${index}].vlan`)}
                                                                    placeholder={`${t('vlan.label')} *`}
                                                                    color={'secondary'}
                                                                    style={{width: '100%'}}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={switch_interfaces.description}
                                                                    name={`switch_interfaces.${index}.description`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(getIn(errors, `switch_interfaces[${index}].description`)
                                                                        && getIn(touched, `switch_interfaces[${index}].description`))
                                                                    && getIn(errors, `switch_interfaces[${index}].description`)}
                                                                    placeholder={`${t('description.label')} *`}
                                                                    color={'secondary'}
                                                                    style={{width: '100%'}}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={switch_interfaces.interface}
                                                                    name={`switch_interfaces.${index}.interface`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(getIn(errors, `switch_interfaces[${index}].interface`)
                                                                        && getIn(touched, `switch_interfaces[${index}].interface`))
                                                                    && getIn(errors, `switch_interfaces[${index}].interface`)}
                                                                    placeholder={`${t('interface.label')} *`}
                                                                    color={'secondary'}
                                                                    style={{width: '100%'}}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <OutlinedSelect
                                                                    val={switch_interfaces.access_mode}
                                                                    label={`${t('access_mode.label')} *`}
                                                                    name={`switch_interfaces.${index}.access_mode`}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                                    options={['access', 'trunk'].map(item => {
                                                                        return {id: item, name: item};
                                                                    })}
                                                                />
                                                                {(getIn(errors, `switch_interfaces[${index}].access_mode`)
                                                                    && getIn(touched, `switch_interfaces[${index}].access_mode`))
                                                                && getIn(errors, `switch_interfaces[${index}].access_mode`) &&
                                                                <FormHelperText
                                                                    style={{marginTop: '20px'}}>{getIn(errors, `switch_interfaces[${index}].access_mode`)}</FormHelperText>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Grid container justify={'center'}>
                                <Button
                                    style={{marginRight: '5px'}}
                                    variant={'outlined'}
                                    onClick={() => {
                                        handleClose();
                                    }}>
                                    {t('cancel.label')}
                                </Button>
                                <ButtonComponent t={t} loading={modalState.btnLoading}/>
                            </Grid>
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
}
CreateUpdateSwitch.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateUpdateSwitch);
