import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const getAllSourcePods = async () => {
  try {
    const response = await axios.get('pod', {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getDestinationPods = async sourceId => {
  try {
    const response = await axios.get(
      `network_query/destination/port_fcr?pod_id=${sourceId}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

const getNetworkQuery = async payload => {
  try {
    const response = await axios.post('network_query', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

export { getAllSourcePods, getDestinationPods, getNetworkQuery };
