import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  withStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { createConnection } from '../../../../../services/connectionService';
import { renderText } from '../../../displayComponent';
import Popup from '../../../Popup';
import Message from '../../../../../components/Message';
import { useAppContext } from '../../../../../contexts/AppContext';

const styles = {
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
};

const Step4New = ({ classes, formData, handlePrev, subnet }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const history = useHistory();
  const { state } = useAppContext();

  const handleSave = async e => {
    e.preventDefault();
    if (
      state.connectionDetails.sourceType === 'fcr' &&
      state.connectionDetails.destinationType === 'port'
    ) {
      try {
        const payload = {
          name: formData?.connectionName,
          a_end_port_id: state.sourceFcrDetails.sourceFcrId,
          b_end_port_id: state.destinationPortDetails.destinationPortId,
          link_type: formData?.MinimumTerm,
          speed: `${formData?.rateLimit} Mbps`,
          b_end_vlan_id: `${formData?.B_end}`,
          subnet: subnet + '/30',
          client_name: formData?.clientName
        };
        const res = await createConnection(payload);
        if (res?.status === 200) {
          Message.success(
            res?.response?.data?.message ||
              res?.data?.message ||
              res?.message ||
              'Connection created'
          );
          history.push('/fabricService/Connections');
        } else {
          Message.error(
            res?.response?.data?.message || res?.data?.message || res?.message
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (
      state.connectionDetails.sourceType === 'port' &&
      state.connectionDetails.destinationType === 'fcr'
    ) {
      try {
        const payload = {
          name: formData?.connectionName,
          a_end_port_id: state.sourcePortDetails.sourcePortId,
          b_end_port_id: state.destinationFcrDetails.destinationFcrId,
          link_type: formData?.MinimumTerm,
          speed: `${formData?.rateLimit} Mbps`,
          a_end_vlan_id: `${formData?.A_end}`,
          subnet: subnet + '/30',
          client_name: formData?.clientName
        };
        const res = await createConnection(payload);
        if (res?.status === 200) {
          Message.success(
            res?.response?.data?.message ||
              res?.data?.message ||
              res?.message ||
              'Connection created'
          );
          history.push('/fabricService/Connections');
        } else {
          Message.error(
            res?.response?.data?.message || res?.data?.message || res?.message
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (
      state.connectionDetails.sourceType === 'port' &&
      state.connectionDetails.destinationType === 'port'
    ) {
      try {
        const payload = {
          name: formData?.connectionName,
          client_name: formData?.clientName,
          a_end_port_id: state.sourcePortDetails.sourcePortId,
          b_end_port_id: state.destinationPortDetails.destinationPortId,
          a_end_vlan_id: `${formData?.A_end}`,
          b_end_vlan_id: `${formData?.B_end}`,
          link_type: formData?.MinimumTerm,
          speed: `${formData?.rateLimit} Mbps`
        };
        const res = await createConnection(payload);
        if (res?.status === 200) {
          Message.success(
            res?.response?.data?.message ||
              res?.data?.message ||
              res?.message ||
              'Connection created'
          );
          history.push('/fabricService/Connections');
        } else {
          Message.error(
            res?.response?.data?.message || res?.data?.message || res?.message
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (
      state.connectionDetails.sourceType === 'fcr' &&
      state.connectionDetails.destinationType === 'fcr'
    ) {
      try {
        const payload = {
          name: formData?.connectionName,
          link_type: formData?.MinimumTerm,
          speed: `${formData?.rateLimit} Mbps`,
          a_end_port_id: state.sourceFcrDetails.sourceFcrId,
          b_end_port_id: state.destinationFcrDetails.destinationFcrId,
          subnet: subnet + '/30',
          client_name: formData?.clientName
        };
        const res = await createConnection(payload);
        if (res?.status === 200) {
          Message.success(
            res?.response?.data?.message ||
              res?.data?.message ||
              res?.message ||
              'Connection created'
          );
          history.push('/fabricService/Connections');
        } else {
          Message.error(
            res?.response?.data?.message || res?.data?.message || res?.message
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Box>{renderText({ label: 'Summary', variant: 'h6' })}</Box>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={2}
            justify="center">
            {state.connectionDetails.sourceType === 'port' &&
              state.connectionDetails.destinationType === 'port' && (
                <>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Connection Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.connectionName}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Client Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.clientName}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Port Speed
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.rateLimit}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Link Type
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.MinimumTerm}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      A-end VLAN
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.A_end}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      B-end VLAN
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.B_end}
                    </Grid>
                  </Grid>
                </>
              )}
            {state.connectionDetails.sourceType === 'fcr' &&
              state.connectionDetails.destinationType === 'port' && (
                <>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Connection Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.connectionName}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Client Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.clientName}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Rate Limit
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.rateLimit}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Link Type
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.MinimumTerm}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      B-end VLAN
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.B_end}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Subnet
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {subnet}
                    </Grid>
                  </Grid>
                </>
              )}
            {state.connectionDetails.sourceType === 'fcr' &&
              state.connectionDetails.destinationType === 'fcr' && (
                <>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Connection Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.connectionName}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Client Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.clientName}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Rate Limit
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.rateLimit}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Link Type
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.MinimumTerm}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Subnet
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {subnet}
                    </Grid>
                  </Grid>
                </>
              )}
            {state.connectionDetails.sourceType === 'port' &&
              state.connectionDetails.destinationType === 'fcr' && (
                <>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Connection Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.connectionName}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Client Name
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.clientName}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Rate Limit
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.rateLimit}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Subnet
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {subnet}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      Link Type
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.MinimumTerm}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sm={5} container justify="center">
                      A-end VLAN
                    </Grid>
                    <Grid item sm={2} container justify="center">
                      :
                    </Grid>
                    <Grid item sm={5} container justify="center">
                      {formData?.A_end}
                    </Grid>
                  </Grid>
                </>
              )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box p={2}>
              <Button variant="outlined" onClick={handlePrev}>
                Prev
              </Button>
            </Box>
            <Box p={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  setOpenPopup(true);
                }}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Popup
        title="Add Configured Services"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Are you sure you add this service from your configured services?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => setOpenPopup(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSave}>
            Order Now
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default withStyles(styles)(Step4New);
