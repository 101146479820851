import { gql } from 'apollo-boost';
/*
  Get pmo project list
 */
export const GET_PMO_PROJECTS = gql`
    query PmoProjects($pageNumberAndOwner: String) {
        getPmoProjectsList(pageNumberAndOwner: $pageNumberAndOwner) {
            projectData
        }
    }
`;

export const GET_PMO_INTERNAL_PROJECTS = gql`
    query GetPmoInternalProjectsList($pageNumberAndOwner: String) {
        getPmoInternalProjectsList(pageNumberAndOwner: $pageNumberAndOwner) {
            projectData
        }
    }
`;
/*
  Get projects user data
 */
export const GET_PROJECTS_USER = gql`
    query ProjectsUser($billingCountry: String) {
        getProjectsUser(billingCountry: $billingCountry) {
            projectsUserData
        }
    }
`;
export const GET_INSTALL_LIST = gql`
    query GetInstallTeamList($pageNumberOrUid: String) {
        getInstallTeamList(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_INSTALL_TEAM_LEAD_LIST = gql`
    query GetInstallTeamLeadList($pageNumberOrUid: String) {
        getInstallTeamLeadList(pageNumberOrUid: $pageNumberOrUid) {
            teamListData
        }
    }
`;
export const GET_IM_LIST = gql`
query getImTeamList($pageNumberOrUid: String) {
    getImTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_CHANGE_CONTROL_LIST = gql`
query getChangeControlTeamList($pageNumberOrUid: String) {
    getChangeControlTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_INTEGRATION_ENGINEER_LIST = gql`
query getIntegrationEngineerTeamList($pageNumberOrUid: String) {
    getIntegrationEngineerTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_QUALITY_ASSURANCE_LIST = gql`
query getQualityAssuranceTeamList($pageNumberOrUid: String) {
    getQualityAssuranceTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_PROJECT_MANANGEMENT_LIST = gql`
query getProjectManagementTeamList($pageNumberOrUid: String) {
    getProjectManagementTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_FINANCE_LIST = gql`
query getFinanaceTeamList($pageNumberOrUid: String) {
    getFinanaceTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_CSOC_LIST = gql`
query getCSOCTeamList($pageNumberOrUid: String) {
    getCSOCTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_RADIO_ENGINEERING_LIST = gql`
query getRadioEngineeringTeamList($pageNumberOrUid: String) {
    getRadioEngineeringTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_OUTSIDE_PROJECT_MANAGEMENT_LIST = gql`
query getOutsideProjectManagementTeamList($pageNumberOrUid: String) {
    getOutsideProjectManagementTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_CUSTOMER_LIST = gql`
query getCustomerList($search: String) {
    getCustomerList(search: $search) {
        teamListData
    }
}
`;
export const GET_OLD_CIRCUIT_LIST = gql`
query getOldCircuitList($pageOrsearch: String) {
    getOldCircuitList(pageOrsearch: $pageOrsearch) {
        teamListData
    }
}
`;
export const GET_PROJECT_TRACK_LIST = gql`
query getProjectTrackList($pageOrsearch: String) {
    getProjectTrackList(pageOrsearch: $pageOrsearch) {
        teamListData
    }
}
`;
export const GET_PROJECT_HISTORY_VIEW_LIST = gql`
query getProjectHistoryViewList($pageOrsearch: String) {
    getProjectHistoryViewList(pageOrsearch: $pageOrsearch) {
        teamListData
    }
}
`;
export const GET_REJECTED_PROJECT_LIST = gql`
query getRejectedProjectList($pageOrsearch: String) {
    getRejectedProjectList(pageOrsearch: $pageOrsearch) {
        teamListData
    }
}
`;
export const GET_START_END_DATE_PROJECT_ANALYSIS = gql`
query getStartEndDateAllCountryProjectAnalytics($startEnddate: String) {
    getStartEndDateAllCountryProjectAnalytics(startEnddate: $startEnddate) {
        teamListData
    }
}
`;
export const GET_START_END_DATE_PAGINATED_PROJECT_ANALYSIS = gql`
query getStartEndDatePaginatedProjectBidashboard($startEnddate: String) {
    getStartEndDatePaginatedProjectBidashboard(startEnddate: $startEnddate) {
        teamListData
    }
}
`;
export const GET_EXCEL_FILE_URL_PAGINATED_PROJECT_ANALYSIS = gql`
query getExcelFileURLPaginatedProjectBidashboard($startEnddate: String) {
    getExcelFileURLPaginatedProjectBidashboard(startEnddate: $startEnddate) {
        teamListData
    }
}
`;
export const GET_PMO_SPECIAL_PROJECTS = gql`
    query PmoSpecialProjects($pageNumberAndOwner: String) {
        getPmoSpecialProjectsList(pageNumberAndOwner: $pageNumberAndOwner) {
            projectData
        }
    }
`;
export const GET_PROJECT_RELETED_ALL_FORMS = gql`
query getProjectRelatedAllForms($uid: String) {
    getProjectRelatedAllForms(uid: $uid) {
        projectData
    }
}
`;
export const GET_SERVICE_DELIVERY_TEAM_LIST = gql`
query GetServiceDeliveryTeamList($pageNumberOrUid: String) {
    getServiceDeliveryTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_STOCK_CONTROL_TEAM_LIST = gql`
query GetStockControlTeamList($pageNumberOrUid: String) {
    getStockControlTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_INSTALL_TEAM_MALAWI_LIST = gql`
query GetInstallTeamMalawiList($pageNumberOrUid: String) {
    getInstallTeamMalawiList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_CAMTEL_LIST = gql`
query GetCamtelData($pageNumberOrUid: String) {
    getCamtelData(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_PROJECT_ZAMBIA_TEAM_LIST = gql`
query GetProjectZambiaTeamList($pageNumberOrUid: String) {
    getProjectZambiaTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_BDU_TEAM_LIST = gql`
query GetBduTeamList($pageNumberOrUid: String) {
    getBduTeamList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_INSTALL_TEAM_ZAMBIA_LIST = gql`
query GetInstallTeamZambiaList($pageNumberOrUid: String) {
    getInstallTeamZambiaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_PM_TEAM_ZAMBIA_LIST = gql`
query GetPMTeamZambiaList($pageNumberOrUid: String) {
    getPMTeamZambiaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_BILLING_TEAM_ZAMBIA_LIST = gql`
query GetBillingTeamZambiaList($pageNumberOrUid: String) {
    getBillingTeamZambiaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_ACTIVATION_TEAM_BOTSWANA_LIST = gql`
query GetActivationTeamBotswanaList($pageNumberOrUid: String) {
    getActivationTeamBotswanaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_DEVELOPER_TEAM_BOTSWANA_LIST = gql`
query GetDeveloperTeamBotswanaList($pageNumberOrUid: String) {
    getDeveloperTeamBotswanaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_PM_TEAM_BOTSWANA_LIST = gql`
query GetPMTeamBotswanaList($pageNumberOrUid: String) {
    getPMTeamBotswanaList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_SERVICE_DELIVERY_TEAM_CAMEROON_LIST = gql`
query GetServicedeliveryTeamCameroonList($pageNumberOrUid: String) {
    getServicedeliveryTeamCameroonList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_NOC_TEAM_CAMEROON_LIST = gql`
query GetNOCTeamCameroonList($pageNumberOrUid: String) {
    getNOCTeamCameroonList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_FIELD_OPERATIONS_TEAM_CAMEROON_LIST = gql`
query GetFieldOperationsTeamCameroonList($pageNumberOrUid: String) {
    getFieldOperationsTeamCameroonList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_CSOC_TEAM_CAMEROON_LIST = gql`
query GetCSOCTeamCameroonList($pageNumberOrUid: String) {
    getCSOCTeamCameroonList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_SALES_TEAM_IVORY_COAST_LIST = gql`
query GetSalesTeamIvoryCoastList($pageNumberOrUid: String) {
    getSalesTeamIvoryCoastList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_IT_TEAM_IVORY_COAST_LIST = gql`
query GetItTeamIvoryCoastList($pageNumberOrUid: String) {
    getItTeamIvoryCoastList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;
export const GET_FINANCE_TEAM_IVORY_COAST_LIST = gql`
query GetFinanceTeamIvoryCoastList($pageNumberOrUid: String) {
    getFinanceTeamIvoryCoastList(pageNumberOrUid: $pageNumberOrUid) {
        teamListData
    }
}
`;