import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import {
  ADD_LAGOS_NGLIST_ITEM,
  UPDATE_LAGOS_NGLIST_ITEM,
  DELETE_LAGOS_NGLIST_ITEM,
  ADD_ABUJA_NGLIST_ITEM,
  UPDATE_ABUJA_NGLIST_ITEM,
  DELETE_ABUJA_NGLIST_ITEM,
  ADD_PHC_NGLIST_ITEM,
  UPDATE_PHC_NGLIST_ITEM,
  DELETE_PHC_NGLIST_ITEM
} from '../mutations';
import { GET_ALL_ADDITIONAL_AGENT_LIST } from '../queries';
import { GET_PHC_NGLIST, GET_ABUJA_NGLIST, GET_LAGOS_NGLIST } from '../queries';
import Layout from '../../../components/Layout';
import Pagination from '@material-ui/lab/Pagination';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceManager } from '../../../routes/routePaths';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import NGDataListing from '../NGDataListing';
import Message from '../../../components/Message';
import EditNGListItem from '../EditNGListItem';
import AddNGListItem from '../AddNGListItem';
import CustomTabs from '../../../components/CustomTabs';

const breadcrumbList = [
  { label: 'home.label', link: ServiceManager.Dashboard },
  { label: 'users.label', link: ServiceManager.users }
];

const AgentList = ({ t }) => {
  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [phcPage, setPhcPage] = React.useState(1);
  const [abujaPage, setAbujaPage] = React.useState(1);
  const [phcNGListData, setPhcNGListData] = useState([]);
  const [lagosNGListData, setLagosNGListData] = useState([]);
  const [abujaNGListData, setAbujaNGListData] = useState([]);
  const [phcTotalCount, setPhcTotalCount] = React.useState(0);
  const [abujaTotalCount, setAbujaTotalCount] = React.useState(0);
  const [lagosTotalCount, setLagosTotalCount] = React.useState(0);
  const [clearAddModal, setClearAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phcLoading, setPhcLoading] = useState(false);
  const [abujaLoading, setAbujaLoading] = useState(false);
  const [buttonLoadingAdd, setButtonLoadingAdd] = useState(false);
  const [openUserAddModal, setOpenUserAddModal] = React.useState(false);
  const [listType, setListType] = React.useState('');

  const [editModalData, setEditModalData] = useState([]);

  const menuItems = [
    { label: t('add.label') },
    { label: t('edit.label') },
    { label: t('delete.label') }
  ];

  const history = useHistory();
  const location = useLocation();

  const [getPhcNGListData] = useLazyQuery(GET_PHC_NGLIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.getPhcNGPagination &&
        data.getPhcNGPagination.data.length
      ) {
        setPhcNGListData(data.getPhcNGPagination.data);
        setPhcTotalCount(data.getPhcNGPagination.total);
        setPhcLoading(false);
      }
    }
  });

  const [getAbujaNGListData] = useLazyQuery(GET_ABUJA_NGLIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.getAbujaNGPagination &&
        data.getAbujaNGPagination.data.length
      ) {
        setAbujaNGListData(data.getAbujaNGPagination.data);
        setAbujaTotalCount(data.getAbujaNGPagination.total);
        setAbujaLoading(false);
      }
    }
  });

  const [getLagosNGListData] = useLazyQuery(GET_LAGOS_NGLIST, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.getLagosNGPagination &&
        data.getLagosNGPagination.data.length
      ) {
        setLagosNGListData(data.getLagosNGPagination.data);
        setLagosTotalCount(data.getLagosNGPagination.total);
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    if (location.search === `?action=reload`) {
      getPhcNGListData({
        variables: {
          page: phcPage
        }
      });
      getAbujaNGListData({
        variables: {
          page: abujaPage
        }
      });
      getLagosNGListData({
        variables: {
          page: page
        }
      });
    }
  }, [location]);

  useEffect(() => {
    setPhcLoading(true);
    getPhcNGListData({
      variables: {
        page: phcPage
      }
    });
    setAbujaLoading(true);
    getAbujaNGListData({
      variables: {
        page: abujaPage
      }
    });
    setLoading(true);
    getLagosNGListData({
      variables: {
        page: page
      }
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getLagosNGListData({
      variables: {
        page: value
      }
    });
  };

  const handlePhcPageChange = (event, value) => {
    setPhcPage(value);
    setPhcLoading(true);
    getPhcNGListData({
      variables: {
        page: value
      }
    });
  };

  const handleAbujaPageChange = (event, value) => {
    setAbujaPage(value);
    setAbujaLoading(true);
    getAbujaNGListData({
      variables: {
        page: value
      }
    });
  };

  const [deleteLagosItem] = useMutation(DELETE_LAGOS_NGLIST_ITEM, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Lagos Ng Item Deleted successfully.');
      getLagosNGListData({
        variables: {
          page: page
        }
      });
    }
  });

  const [updateLagosItem] = useMutation(UPDATE_LAGOS_NGLIST_ITEM, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('Lagos NG Item updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      getLagosNGListData({
        variables: {
          page: page
        }
      });
    }
  });

  const [addLagosItem] = useMutation(ADD_LAGOS_NGLIST_ITEM, {
    onError: error => {
      setButtonLoadingAdd(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoadingAdd(false);
      Message.success('Lagos NG Item added successfully');
      setOpenUserAddModal(false);
      setClearAddModal(true);
      getLagosNGListData({
        variables: {
          page: page
        }
      });
    }
  });

  const [deleteAbujaItem] = useMutation(DELETE_ABUJA_NGLIST_ITEM, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Abuja Ng Item Deleted successfully.');
      getAbujaNGListData({
        variables: {
          page: abujaPage
        }
      });
    }
  });

  const [updateAbujaItem] = useMutation(UPDATE_ABUJA_NGLIST_ITEM, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('Abuja NG Item updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      getAbujaNGListData({
        variables: {
          page: abujaPage
        }
      });
    }
  });

  const [addAbujaItem] = useMutation(ADD_ABUJA_NGLIST_ITEM, {
    onError: error => {
      setButtonLoadingAdd(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoadingAdd(false);
      Message.success('Abuja NG Item added successfully');
      setOpenUserAddModal(false);
      setClearAddModal(true);
      getAbujaNGListData({
        variables: {
          page: abujaPage
        }
      });
    }
  });

  const [deletePhcItem] = useMutation(DELETE_PHC_NGLIST_ITEM, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Phc Ng Item Deleted successfully.');
      getPhcNGListData({
        variables: {
          page: phcPage
        }
      });
    }
  });

  const [updatePhcItem] = useMutation(UPDATE_PHC_NGLIST_ITEM, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoading(false);
      Message.success('Phc NG Item updated successfully');
      setOpenUserModal(false);
      setClearModal(true);
      getPhcNGListData({
        variables: {
          page: phcPage
        }
      });
    }
  });

  const [addPhcItem] = useMutation(ADD_PHC_NGLIST_ITEM, {
    onError: error => {
      setButtonLoadingAdd(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setButtonLoadingAdd(false);
      Message.success('Phc NG Item added successfully');
      setOpenUserAddModal(false);
      setClearAddModal(true);
      getPhcNGListData({
        variables: {
          page: phcPage
        }
      });
    }
  });

  let LagosListing =
    lagosNGListData && lagosNGListData.length && lagosNGListData;
  let AbujaListing =
    abujaNGListData && abujaNGListData.length && abujaNGListData;
  let PhcListing = phcNGListData && phcNGListData.length && phcNGListData;

  const handleMenuItemClick = (action, data, type) => {
    setListType(type);
    if (type === 'lagos') {
      if (action.label === 'Delete') {
        if (
          window.confirm(
            'Are you sure? you want to delete this Lagos NG List Item!'
          )
        ) {
          return deleteLagosItem({
            variables: {
              id: data._id
            }
          });
        }
      }
      if (action.label === 'Edit') {
        setOpenUserModal(true);
        setEditModalData(data);
      }
      if (action.label === 'Add') {
        setOpenUserAddModal(true);
      }
    }

    if (type === 'phc') {
      if (action.label === 'Delete') {
        if (
          window.confirm(
            'Are you sure? you want to delete this Phc NG List Item!'
          )
        ) {
          return deletePhcItem({
            variables: {
              id: data._id
            }
          });
        }
      }
      if (action.label === 'Edit') {
        setOpenUserModal(true);
        setEditModalData(data);
      }
      if (action.label === 'Add') {
        setOpenUserAddModal(true);
      }
    }

    if (type === 'abuja') {
      if (action.label === 'Delete') {
        if (
          window.confirm(
            'Are you sure? you want to delete this Abuja Ng List Item!'
          )
        ) {
          return deleteAbujaItem({
            variables: {
              id: data._id
            }
          });
        }
      }
      if (action.label === 'Edit') {
        setOpenUserModal(true);
        setEditModalData(data);
      }
      if (action.label === 'Add') {
        setOpenUserAddModal(true);
      }
    }
  };

  const handleSubmit = (formData, type) => {
    if (type === 'lagos') {
      setButtonLoading(true);
      return updateLagosItem({
        variables: {
          id: formData.id,
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }

    if (type === 'phc') {
      setButtonLoading(true);
      return updatePhcItem({
        variables: {
          id: formData.id,
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }

    if (type === 'abuja') {
      setButtonLoading(true);
      return updateAbujaItem({
        variables: {
          id: formData.id,
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }
  };

  const handleSubmitAdd = (formData, type) => {
    setButtonLoadingAdd(true);
    if (type === 'lagos') {
      return addLagosItem({
        variables: {
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }

    if (type === 'phc') {
      return addPhcItem({
        variables: {
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }

    if (type === 'abuja') {
      return addAbujaItem({
        variables: {
          memberName: formData.memberName,
          memberEmail: formData.memberEmail,
          memberRole: formData.memberRole,
          memberType: formData.memberType
        }
      });
    }
  };

  const columns = [
    {
      label: t('memberName.label'),
      key: 'memberName'
    },
    {
      label: t('memberEmail.label'),
      key: 'memberEmail'
    },
    {
      label: t('memberRole.label'),
      key: 'memberRole'
    },
    {
      label: t('memberType.label'),
      key: 'memberType'
    },
    {
      label: t('action.label'),
      key: 'action'
    }
  ];

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header title={t('ngList.label')} subText={`${t('ngList.label')}`} />

      <CustomTabs
        tabs={[
          {
            label: t('lagosFieldOps.label'),
            children: (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((item, index) => (
                          <TableCell key={index}>
                            <Typography variant={'subtitle2'}>
                              {item.label}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading ? (
                        LagosListing.length > 0 ? (
                          LagosListing.map((rowitem, rowIndex) => (
                            <NGDataListing
                              key={rowIndex}
                              t={t}
                              data={rowitem}
                              menuItems={menuItems}
                              type="lagos"
                              handleMenuItemClick={handleMenuItemClick}
                            />
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <Typography variant={'subtitle2'}>
                                Record not found!
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>
                              Loading...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ width: '100%', marginTop: 24, marginBottom: 24 }}>
                  <Pagination
                    style={{ float: 'right' }}
                    count={Math.ceil(lagosTotalCount / 25)}
                    color="secondary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </div>
              </>
            )
          },
          {
            label: t('phcFieldOps.label'),
            children: (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((item, index) => (
                          <TableCell key={index}>
                            <Typography variant={'subtitle2'}>
                              {item.label}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!phcLoading ? (
                        PhcListing.length > 0 ? (
                          PhcListing.map((rowitem, rowIndex) => (
                            <NGDataListing
                              key={rowIndex}
                              t={t}
                              data={rowitem}
                              type="phc"
                              menuItems={menuItems}
                              handleMenuItemClick={handleMenuItemClick}
                            />
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <Typography variant={'subtitle2'}>
                                Record not found!
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>
                              Loading...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ width: '100%', marginTop: 24, marginBottom: 24 }}>
                  <Pagination
                    style={{ float: 'right' }}
                    count={Math.ceil(phcTotalCount / 25)}
                    color="secondary"
                    page={phcPage}
                    onChange={handlePhcPageChange}
                  />
                </div>
              </>
            )
          },
          {
            label: t('abujaFieldOps.label'),
            children: (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((item, index) => (
                          <TableCell key={index}>
                            <Typography variant={'subtitle2'}>
                              {item.label}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!abujaLoading ? (
                        AbujaListing.length > 0 ? (
                          AbujaListing.map((rowitem, rowIndex) => (
                            <NGDataListing
                              key={rowIndex}
                              t={t}
                              data={rowitem}
                              type="abuja"
                              menuItems={menuItems}
                              handleMenuItemClick={handleMenuItemClick}
                            />
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <Typography variant={'subtitle2'}>
                                Record not found!
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Typography variant={'subtitle2'}>
                              Loading...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ width: '100%', marginTop: 24, marginBottom: 24 }}>
                  <Pagination
                    style={{ float: 'right' }}
                    count={Math.ceil(abujaTotalCount / 25)}
                    color="secondary"
                    page={abujaPage}
                    onChange={handleAbujaPageChange}
                  />
                </div>
              </>
            )
          }
        ]}
      />
      <EditNGListItem
        open={openUserModal}
        handleClose={evt => setOpenUserModal(false)}
        handleSubmit={handleSubmit}
        buttonLoading={buttonLoading}
        clearModal={clearModal}
        data={editModalData}
        type={listType}
      />
      <AddNGListItem
        open={openUserAddModal}
        type={listType}
        handleClose={evt => setOpenUserAddModal(false)}
        handleSubmitAdd={handleSubmitAdd}
        buttonLoadingAdd={buttonLoadingAdd}
        clearModal={clearAddModal}
      />
    </Layout>
  );
};
export default withTranslation()(AgentList);
