import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DatePicker from 'react-datepicker';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateFeetportTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    handleUploadFiles,
    modalContentLoading,
    handleOpenFeetportForm,
    selectedProjectTypeAndUid
}) {
    const classes = useStyles();
    const [formType, setFormType] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [physicalAddress, setPhysicalAddress] = useState('');
    const [date, setDate] = useState('');
    const [siteOwner, setSiteOwner] = useState('');
    const [siteOwnerIfOther, setSiteOwnerIfOther] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [equipment, setEquipment] = useState('');
    const [equipmentIfOther, setEquipmentIfOther] = useState('');
    const [otherDetailOrProblem, setOtherDetailOrProblem] = useState('');
    const [GPSLocation, setGPSLocation] = useState('');
    const [installationMaintenanceStatus, setInstallationMaintenanceStatus] = useState('');
    const [comments, setComments] = useState('');
    const [createdOn, setCreatedOn] = useState('');
    const [assignedOn, setAssignedOn] = useState('');
    const [initiateOn, setInitiateOn] = useState('');
    const [completedOn, setCompletedOn] = useState('');
    const [status, setStatus] = useState('');
    const [signature, setSignature] = useState('');
    const [attachAnyOtherDoc, setAttachAnyOtherDoc] = useState([]);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setFormType('');
            setTicketNumber('');
            setCustomerName('');
            setPhysicalAddress('');
            setDate('');
            setSiteOwner('');
            setSiteOwnerIfOther('');
            setContactPerson('');
            setContactNumber('');
            setEmailAddress('');
            setEquipment('');
            setEquipmentIfOther('');
            setOtherDetailOrProblem('');
            setGPSLocation('');
            setInstallationMaintenanceStatus('');
            setComments('');
            setCreatedOn('');
            setAssignedOn('');
            setInitiateOn('');
            setCompletedOn('');
            setStatus('');
            setSignature('');
            setAttachAnyOtherDoc([]);
        }
    }, [modalState, open, modalContentLoading, selectedProjectTypeAndUid]);

    const handleAttachAnyOtherDoc = (index) => {
        setAttachAnyOtherDoc(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('service.label')} {t('delivery.label')} {t('feetportForm.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('formType.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={formType}
                                type={'text'}
                                placeholder={t('formType.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setFormType(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('ticketNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={ticketNumber}
                                type={'text'}
                                placeholder={t('ticketNumber.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setTicketNumber(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('site.label')} / {t('POP.label')} / {t('customerName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={customerName}
                                type={'text'}
                                placeholder={t('customerName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCustomerName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('physicalAddress.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={physicalAddress}
                                type={'text'}
                                placeholder={t('physicalAddress.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setPhysicalAddress(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('date.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={date}
                                    onChange={date => setDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('siteOwner.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={siteOwner}
                                type={'text'}
                                placeholder={t('siteOwner.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSiteOwner(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('siteOwnerIfOther.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={siteOwnerIfOther}
                                type={'text'}
                                placeholder={t('siteOwnerIfOther.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSiteOwnerIfOther(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('contactPerson.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={contactPerson}
                                type={'text'}
                                placeholder={t('contactPerson.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setContactPerson(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('contactNumber.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={contactNumber}
                                type={'text'}
                                placeholder={t('contactNumber.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setContactNumber(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('emailAddress.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={emailAddress}
                                type={'text'}
                                placeholder={t('emailAddress.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setEmailAddress(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipment.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={equipment}
                                type={'text'}
                                placeholder={t('equipment.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setEquipment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentIfOther.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={equipmentIfOther}
                                type={'text'}
                                placeholder={t('equipmentIfOther.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setEquipmentIfOther(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('otherDetailOrProblem.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={otherDetailOrProblem}
                                type={'text'}
                                placeholder={t('otherDetailOrProblem.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setOtherDetailOrProblem(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('GPSLocation.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={GPSLocation}
                                type={'text'}
                                placeholder={t('GPSLocation.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setGPSLocation(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('installationMaintenanceStatus.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={installationMaintenanceStatus}
                                type={'text'}
                                placeholder={t('installationMaintenanceStatus.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setInstallationMaintenanceStatus(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comments.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comments}
                                type={'text'}
                                placeholder={t('comments.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComments(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('createdOn.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={createdOn}
                                    onChange={date => setCreatedOn(date)}
                                    placeholderText="Please select a date and time"
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    showTimeInput
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('assignedOn.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                                <div className="datePicker">
                                <DatePicker
                                    selected={assignedOn}
                                    onChange={date => setAssignedOn(date)}
                                    placeholderText="Please select a date and time"
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    showTimeInput
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('initiateOn.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                                    <div className="datePicker">
                                <DatePicker
                                    selected={initiateOn}
                                    onChange={date => setInitiateOn(date)}
                                    placeholderText="Please select a date and time"
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    showTimeInput
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('completedOn.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                                     <div className="datePicker">
                                <DatePicker
                                    selected={completedOn}
                                    onChange={date => setCompletedOn(date)}
                                    placeholderText="Please select a date and time"
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    showTimeInput
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('status.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={status}
                                type={'text'}
                                placeholder={t('status.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setStatus(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('signature.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                                 <Input
                                type={'file'}
                                inputProps={{ accept:"image/*" }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setSignature(file);
                                    } else {
                                        setSignature('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('installationPictures.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                             <Input
                                type={'file'}
                                inputProps={{ multiple: true, accept:"image/*" }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={attachAnyOtherDoc && attachAnyOtherDoc.length >= 7 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && attachAnyOtherDoc.length < 7) {
                                        if (evt.target.files.length <= 7 && (attachAnyOtherDoc.length + evt.target.files.length) <= 7) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setAttachAnyOtherDoc(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 7. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {attachAnyOtherDoc && attachAnyOtherDoc.length ?
                                attachAnyOtherDoc.map((item, index) => (
                                    <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleAttachAnyOtherDoc(index)} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!formType || !ticketNumber || !customerName || !physicalAddress || !date || !siteOwner || !contactPerson ||
                                !contactNumber || !emailAddress || !equipment || !GPSLocation|| !installationMaintenanceStatus|| !createdOn || !assignedOn ||
                                !initiateOn || !completedOn || !status || !signature || !attachAnyOtherDoc || (attachAnyOtherDoc.length == 0)) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (emailAddress != '' && !checkValidEmail(emailAddress)) {
                                Message.error(INVALID_EMAIL);
                                return null;
                            }
                            // create URL for signature file
                            let getSignature = "";
                            if (signature != "" && signature != null) {
                                let getSignatureUrl = await handleUploadFiles(signature, selectedProjectTypeAndUid.uid, 'malawi_feetport_form');
                                if (getSignatureUrl.data && getSignatureUrl.data.allUploadFile && getSignatureUrl.data.allUploadFile.url) {
                                    getSignature = getSignatureUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create url for installation pictures
                            let getAttachAnyOtherDoc = [];
                            if (attachAnyOtherDoc != [] && attachAnyOtherDoc != null && attachAnyOtherDoc.length > 0) {
                                for (let i = 0; i < attachAnyOtherDoc.length; i++) {
                                    let getAttachDoc = await handleUploadFiles(attachAnyOtherDoc[i], selectedProjectTypeAndUid.uid, 'im_team_section');
                                    if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                        getAttachAnyOtherDoc.push(getAttachDoc.data.allUploadFile.url);
                                    }
                                    else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                            }
                            handleSubmitClick({
                                project_name : selectedProjectTypeAndUid.uid,
                                form_type: formType,
                                ticket_number: ticketNumber,
                                customer_name: customerName,
                                physical_address: physicalAddress,
                                date:moment(date).format("YYYY-MM-DD"),
                                site_owner: siteOwner,
                                site_owner_If_other: siteOwnerIfOther,
                                contact_person: contactPerson,
                                contact_number: contactNumber,
                                email_address: emailAddress,
                                equipment: equipment,
                                equipment_If_other: equipmentIfOther,
                                other_detail_or_problem: otherDetailOrProblem,
                                GPS_location: GPSLocation,
                                installation_maintenance_status: installationMaintenanceStatus,
                                comments: comments,
                                created_on: moment(createdOn).format(),
                                assigned_on: moment(assignedOn).format(),
                                initiate_on: moment(initiateOn).format(),
                                completed_on: moment(completedOn).format(),
                                status: status,
                                signature: getSignature,
                                installation_pictures: getAttachAnyOtherDoc
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateFeetportTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateFeetportTeamModal);





