import React from 'react'
import ParseRulesListings from './ParseRulesListings';
import CreateRule from './CreateRule';
import { ACTION_TYPES } from '../../../../../utils/constants';

const OcrParseRules = ({
            t, 
            isCreateRuleEnable, 
            createRuleHandle,
            handleRuleFormClose,
            ocrRuleData,
            loadingOcrRule,
            handleSearch,
            ocrData,
            handleSubmitOfOcrRule,
            ocrRuleSubmitLoading,
            ocrImageDataForCreateRule,
            ocrRuleFetchingloading,
            handleSubmitOfDeleteOcrRule,
            ocrConfigItemsDeletetingStarted,
            selecteIdForDelete,
            handleSearchOfOcrRule,
            isEditRuleEnable,
            editRuleHandle,
            handleEditRuleFormClose,
            handleSubmitEditOfOcrRule,
            page,
            handlePageChange,
            dataForUpdateRuleModal,
            organizationId,
            handleSearchOfOcrRuleImg
          }) => {
    return (
        <>
           <ParseRulesListings 
             t={t}
             createRuleHandle={createRuleHandle}
             editRuleHandle={editRuleHandle}
             ocrRuleData={ocrRuleData}
             ocrRuleFetchingloading={ocrRuleFetchingloading}
             handleSearch={handleSearch}
             handleSubmitOfDeleteOcrRule={handleSubmitOfDeleteOcrRule}
             ocrRuleDeletetingStarted={ocrConfigItemsDeletetingStarted}
             selecteIdForDelete={selecteIdForDelete}
             handleSearchOfOcrRule={handleSearchOfOcrRule}
             open={isEditRuleEnable}
             onClose={handleRuleFormClose}
             ocrRuleSubmitLoading={ocrRuleSubmitLoading}
             handleEditRuleFormClose={handleEditRuleFormClose}
             handleSubmitEditOfOcrRule={handleSubmitEditOfOcrRule}
             page={page}
             handlePageChange={handlePageChange}    
             dataForUpdateRuleModal={dataForUpdateRuleModal}
             organizationId={organizationId}
           />  
           <CreateRule 
             t={t}
             open={isCreateRuleEnable}
             onClose={handleRuleFormClose}
             ocrData={ocrData}
             handleSubmitOfOcrRule={handleSubmitOfOcrRule}
             ocrRuleSubmitLoading={ocrRuleSubmitLoading}
             ocrImageDataForCreateRule={ocrImageDataForCreateRule}
             actionType={ACTION_TYPES.CREATE}
             handleSearchOfOcrRuleImg={handleSearchOfOcrRuleImg}
           />
        </>
    )
}

export default OcrParseRules
