// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_CAMTEL_LIST } from "../../../../queries";
import { USER_ROLES } from "../../../../../../../utils/constants";
import Header from "../../../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ShowCamtelItem from "../ShowCamtelItem";
import { GET_USER_INFO } from '../../../../../../Profile/queries';
import { ADD_CHNAGE_CONTROL_TEAM, UPLOAD_FILES, UPDATE_PMO_PROJECT } from "../../../../mutations";
import { GET_ALL_USER_BY_ROLES } from '../../../../../../commonQueries';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const ShowCamtel = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [changeControlTeam, setChangeControlTeam] = useState(false);
    const [modalState, setModalState] = useState({ btnLoading: false, clearData: false });
    const [preValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [ProjectAttachment, setProjectAttachment] = useState({ open: false, data: [] });
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "page=1"
            }
        })
        // }
        getAllUserList({
            variables: {
                role: USER_ROLES.INTEGRATION_TEAM
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1"
                }
            })
            // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=1"
            //         }
            //     })
            // }
        }
    }, [refreshTab]);
    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get integration users list in change control team', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            setAllUserList(parseData);

            // setOrgList(data.getOrganizationsList ? data.getOrganizationsList : []);
        }
    });
    const { data: userData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in chnage control team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            // console.log("full name : ", full_name);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.PMO_ADMIN, USER_ROLES.PROJECT_MANAGER];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            // if (role.includes(USER_ROLES.CHANGE_CONTROL_TEAM) && !checkRoleResult) {
            //     getAllList({
            //         variables: {
            //             pageNumberOrUid: "page=1&change_control_team=" + full_name
            //         }
            //     })
            // }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
        getAllList({
            variables: {
                pageNumberOrUid: "page=" + value + checkSearchFilterData
            }
        })
        // } else if ((role.includes(USER_ROLES.CHANGE_CONTROL_TEAM))) {
        //     getAllList({
        //         variables: {
        //             pageNumberOrUid: "change_control_team=" + CurrentUserName + "&page=" + value
        //         }
        //     })
        // }
    };
    const [getAllList] = useLazyQuery(GET_CAMTEL_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getCamtelData) {
                let parseResult = JSON.parse(data.getCamtelData.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get IM list in change control team:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: "NIN",
            key: "NIN"
        },
        {
            label: "Name",
            key: "NIN1"
        },
        {
            label: "Means of Identification",
            key: "NIN3"
        },
        {
            label: "Contact details",
            key: "NIN4"
        },
        {
            label: "Others",
            key: "NIN5"
        },
        {
            label: "Profile Photo",
            key: "NIN6"
        },
        {
            label: "Signature",
            key: "NIN7"
        },
    ];
    const handleMenuItemClick = (elem, data) => {
        // console.log("data change : ", data);
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: (data.customer_name + ' / ' + data.organization_name) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('installationMannual.label'), value: data.attach_manual, button: true },
            { label: t('circuitId.label'), value: data.circuit_id, button: false },
            { label: t('equipmentsToBeDeployed.label'), value: data.equipments_to_be_deployed, button: false },
            { label: t('changeControlTeam.label'), value: data.change_control_team, button: false },
            { label: t('pasteAnyOtherInfo.label'), value: data.paste_any_other_info, button: false },
        ]
        setPreValuesOfForm(data);
        setCompleteDetails({ ...completeDetails, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
        handleChangeControlTeam();
    }
    const [addChangeControlTeam] = useMutation(ADD_CHNAGE_CONTROL_TEAM, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error add change control form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addChangeControlTeamSection : ", data);
            setModalState({ btnLoading: false, clearData: true });
            if (data && data.addChangeControlTeamSection) {
                if (JSON.parse(data.addChangeControlTeamSection.status) === true) {
                    Message.success('New change control team section created successfully');
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addChangeControlTeamSection.teamData);
                    handleCloseChangeControlTeam();
                    // handleIntegrationEngineerTeam();
                } else {
                    let message = JSON.parse(data.addChangeControlTeamSection.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleSubmitChangeControlTeamForm = (formData) => {
        setModalState({ ...modalState, btnLoading: true });
        handleRefreshTab(false);
        updatePmoProjectCategoryAndOnHold({
            variables: {
                uid: formData.project_uid,
                category: formData.category,
                status: formData.on_hold
            }
        });
        addChangeControlTeam({
            variables: {
                change_control_form: formData.changeControlForm,
                circuit_id: formData.circuitId,
                generate_config_change_form: formData.generateConfigForm,
                attach_manual: formData.attachMannual,
                integration_engineer: formData.integrationEngineer,
                project_uid: formData.project_uid,
                comment: formData.comment,
                previous_comment: formData.previous_comment,
                equipments_to_be_deployed: formData.equipments_to_be_deployed,
                auto_generated_project_id: formData.auto_generated_project_id,
                next_team_member_email: formData.next_team_member_email,
                last_team_member_name: CurrentUserName,
            }
        })
    }
    const [updatePmoProjectCategoryAndOnHold] = useMutation(UPDATE_PMO_PROJECT, {
        onError: error => {
            console.log('Error updatePmoProject', error);
        },
        onCompleted: data => {
            // console.log("data category on hold", data);
        }
    });
    const handleChangeControlTeam = () => {
        setChangeControlTeam(true);
    }
    const handleCloseChangeControlTeam = () => {
        setModalState({ btnLoading: false, clearData: true });
        setChangeControlTeam(false);
    }
    const handleUploadFiles = async (file, project_uid, team_name) => {
        setModalState({ ...modalState, btnLoading: true });
        return await allUploadFile({
            variables: {
                file: file,
                project_uid: project_uid,
                team_name: team_name
            }
        });
    }
    const [allUploadFile] = useMutation(UPLOAD_FILES, {
        onError: error => {
            setModalState({ ...modalState, btnLoading: false });
            console.log('Error upload files in change control team', error);
            Message.error("File not uploaded! Please try again.");
        },
        onCompleted: res => {
            setModalState({ btnLoading: false, clearData: true });
            if (res && res.allUploadFile.url) {
            } else {
                Message.error("File not uploaded! Please try again.");
            }
        }
    });
    const handleProjectAttachmentClick = (data) => {
        if (data && data.length != 0) {
            setProjectAttachment({ open: true, data: data });
        }
    }
    const handleViewCompleteDetailClick = () => {
        setCompleteDetails({ ...completeDetails, open: true });
    }
    const handleClickViewAllDetailsAndAttachment = (data) => {
        let projectDetail = [
            { label: t('caseId.label'), value: (data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')) },
            { label: t('customer.label') + ' / ' + t('organisationName.label'), value: (data.customer_name + ' / ' + data.organization_name) },
            { label: t('project.label') + ' ' + t('owner.label'), value: (data.project_info && data.project_info.project_owner ? data.project_info.project_owner : 'N/A') },
            { label: t('projectName.label'), value: (data.project_info && data.project_info.project_name ? data.project_info.project_name : 'N/A') },
        ]
        let detailArray = [
            { label: t('installationMannual.label'), value: data.attach_manual, button: true },
            { label: t('circuitId.label'), value: data.circuit_id, button: false },
            { label: t('equipmentsToBeDeployed.label'), value: data.equipments_to_be_deployed, button: false },
            { label: t('changeControlTeam.label'), value: data.change_control_team, button: false },
            { label: t('pasteAnyOtherInfo.label'), value: data.paste_any_other_info, button: false },
        ]
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: projectDetail });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
            }
        })
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        getAllList({
            variables: {
                pageNumberOrUid: "page=1&project_owner=is_admin"
            }
        })
    };
    return (
        <>
            <Header style={{paddingBottom:'2px!important'}} title={t("camtel.label")} />
            <Header title={'Data Capturing System'} />
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.length ?
                                    list.map((item, index) => (
                                        <ShowCamtelItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} CurrentUserName={CurrentUserName} handleProjectAttachmentClick={handleProjectAttachmentClick}
                                            handleClickViewAllDetailsAndAttachment={handleClickViewAllDetailsAndAttachment} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                        `` </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(ShowCamtel);
