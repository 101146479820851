import { gql } from 'apollo-boost';
/*
  Get all subscriptions
 */
export const GET_SUBSCRIPTION_LIST = gql`
    query Subscription($status: String, $ownerId: String) {
        getAllSubscriptions(status: $status, ownerId: $ownerId) {
            id
            name
            noOfCPEs
            noOfSites
            noOfActiveSites
            avgUptime
            status
            isDelete
            ownerId
            assignUsers
            sites {
                id
                name
                status
            }
            service {
                id
                name
                serviceType {
                    name
                }
            }
            cameras
        }
    }
`;
/*
list Service manager
*/
export const GET_SUBSCRIPTION_LIST_SERVICE_MANAGER = gql`
    query Subscription($status: String, $ownerId: String) {
        getAllSubscriptionsServicemanager(status: $status, ownerId: $ownerId) {
            id
            name
            noOfCPEs
            noOfSites
            avgUptime
            status
            isDelete
            sites {
                id
                name
                status
            }
            service {
                id
                name
                serviceType {
                    name
                }
            }
        }
    }
`;
/*
  Get all subscriptions without orgId
 */
export const GET_ALL_SUBSCRIPTION_LIST = gql`
    query Subscription($status: String) {
        getAllSubscriptionsList(status: $status) {
            id
            name
            noOfCPEs
            noOfSites
            avgUptime
            status
            isDelete
            sites {
                name
                id
                status
            }
            service {
                name
                id
                serviceType {
                    name
                }
            }
        }
    }
`;
/*
  Get subscription details by id
 */
export const GET_SUBSCRIPTION_DETAILS = gql`
    query Subscription($id: String) {
        getSubscriptionById(id: $id) {
            id
            name
            noOfCPEs
            noOfSites
            noOfActiveSites
            avgUptime
            status
            organizationId
            opportunityId
            nodeManagmentMode
            analyticsUrl
            isDelete
            ownerId
            liveCamUrls {
                name
                url
            }
            sites {
                id
                name
                status
                latitude
                longitude
                address
                zipcode
                updatedAt
                updatedBy
                configuration
                totalCPEs
                activeCPEs
                cpuUsage {
                    value
                    timestamp
                }
                orderTrack {
                    status
                    updatedAt
                    updatedBy
                }
            }

            service {
                id
                avgUptime
                name
                description
                avgCost
                avgInstallationTime
                serviceType {
                    name
                }
            }
        }
    }
`;
/*
  Get Edge AI Org details
 */
export const GET_EDGE_AI_ORG_DETAIL = gql`
    query UncannyOrganization($id: String) {
        getEdgeAIOrganizationDetails(id: $id) {
            organization_id
            mail_server
            mail_from
            mail_username
            mail_password
            mail_port
            timezone
        }
    }
`;
/*
  Get All ML Template List
 */
export const GET_ML_TEMPLATE_LIST = gql`
    query MlTemplate($organization: String, $search: String, $page: Int, $limit: Int) {
        getAllMlTemplateList(organization: $organization, search: $search, page: $page, limit: $limit) {
            templates
            count
        }
    }
`;
/*
  Get All ML Template List
 */
export const GET_ML_TEMPLATE_BY_ID = gql`
    query MlTemplate($id: Int) {
        getMLTemplateDetailById(id: $id) {
            id
            name
            model_type
            slug
            definition
            priority
            provider
            organization
            created_at
            created_by_name
            created_by_id
            noOfCam
            notification_frequency
        }
    }
`;
/*
  Get All Notification Template List
 */
export const GET_NOTIFICATION_TEMPLATE_LIST = gql`
    query NotificationTemplate($organization: String, $search: String, $page: Int, $limit: Int) {
        getAllNotificationTemplateList(organization: $organization, search: $search, page: $page, limit: $limit) {
            count
            templates
        }
    }
`;
/*
  Get Notification Template Details By Id
 */
export const GET_NOTIFICATION_TEMPLATE_BY_ID = gql`
    query NotificationTemplate($id: Int) {
        getNotificationTemplateById(id: $id) {
            id
            name
            slug
            notification_type
            dispatch_address
            notification_message
            organization
            created_at
            created_by_name
            created_by_id
            noOfCam
            attached_cameras
        }
    }
`;
/*
  Get All Places List
 */
export const GET_PLACE_LIST = gql`
    query Place($organization: String, $search: String, $page: Int, $limit: Int) {
        getAllPlaceList(organization: $organization, search: $search, page: $page, limit: $limit) {
            count
            places
        }
    }
`;
/*
  Get Place Details By Id
 */
export const GET_PLACE_BY_ID = gql`
    query Place($id: String) {
        getPlaceById(place_id: $id) {
            place_id
            name
            place_type
            image
            lat
            lng
            organization
            created_at
            created_by_name
            created_by_id
            noOfCam
        }
    }
`;
/*
  Get Cameras List
 */
export const GET_CAMERA_LIST = gql`
    query Camera($organization: String, $placeId: String, $templateId: Int, $search: String, $page: Int, $limit: Int, $status: String, $client: String) {
        getAllCameraList(
            organization: $organization
            placeId: $placeId
            templateId: $templateId
            search: $search
            page: $page
            limit: $limit
            status: $status
            client: $client
        ) {
            count
            cameras
        }
    }
`;
/*
  Get Camera Details By Id
 */
export const GET_CAMERA_BY_ID = gql`
    query CameraDetails($camera_id: String) {
        getCameraById(camera_id: $camera_id) {
            details
            schedules
            notification_filters
        }
    }
`;
export const GET_EVENT_IMAGES = gql`
    query EventImages($cameraId: String, $startTime: Float, $endTime: Float, $lastEvent: Boolean, $viewers: Boolean) {
        getEventImages(
            cameraId: $cameraId
            startTime: $startTime
            endTime: $endTime
            lastEvent: $lastEvent
            viewers: $viewers
        ) {
            url
            time
            objects
        }
    }
`;
export const GET_EVENT_VIDEO_IMAGES = gql`
    query EventImages($cameraId: String, $startTime: Float, $endTime: Float, $lastEvent: Boolean, $viewers: Boolean) {
        getEventVideoImages(
            cameraId: $cameraId
            startTime: $startTime
            endTime: $endTime
            lastEvent: $lastEvent
            viewers: $viewers
        ) {
            url
            time
            objects
        }
    }
`;
/*
  Get Edge AI Node List
*/
export const GET_NODE_LIST = gql`
    query Nodes($organization: String, $page: Int, $limit: Int, $search: String) {
        getNodeList(organization: $organization, page: $page, limit: $limit, search: $search) {
            count
            nodes
        }
    }
`;
/*
  Get Edge AI Node List
*/
export const GET_NODE_DETAILS_BY = gql`
    query Nodes($id: String) {
        getNodeDetailsById(id: $id) {
            id
            name
            mode
            batch_id
            provider
            instance_type
            device_configuration
            status
            logs
            created_at
            noOfCam
            node_page_table
        }
    }
`;
/*
  Get Edge AI DashBoard Cont
 */
export const GET_EDGE_DASHBOARD_COUNT = gql`
    query EdgeDashboardCount($organization: String) {
        getEdgeDashboardCount(organization: $organization) {
            activeCameras
            pendingCameras
            models
            notificationTemplates
        }
    }
`;
/*
  Get Edge AI DashBoard Cont
 */
export const GET_EVENT_AND_ALERT_TIMELINE = gql`
    query EventAndAlertTimeline(
        $organization: String
        $startTime: Int
        $endTime: Int
        $placeId: String
    ) {
        getEventAndAlertTimelineData(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
            placeId: $placeId
        ) {
            date
            count
        }
    }
`;
/*
  Get Edge AI Camera For Alert Model
 */
export const GET_EVENT_AND_ALERT_MODEL = gql`
    query EventAndAlertModel(
        $organization: String
        $startTime: Int
        $endTime: Int
        $modelType: String
        $placeId: String
        $page: Int,
        $limit: Int,
        $search: String
    ) {
        getEventAndAlertModelData(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
            modelType: $modelType
            placeId: $placeId
            page: $page
            limit: $limit
            search: $search
        ) {
            camera_id
            name
            count
        }
    }
`;
/*
  Get Edge AI Camera Events Model
 */
export const GET_EVENTS_BY_CAMERA = gql`
    query CameraEvents(
        $organization: String
        $startTime: Int
        $endTime: Int
        $modelType: String
        $cameraId: String
        $page: Int
    ) {
        getEventsByCamera(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
            modelType: $modelType
            cameraId: $cameraId
            page: $page
        ) {
            count
            events
        }
    }
`;
/*
  Get Edge AI DashBoard Cont
 */
export const GET_EDGE_DASHBOARD_ML_TEMPLATE = gql`
    query DashboardMLTemplate(
        $organization: String
        $startTime: Int
        $endTime: Int
    ) {
        getDashboardMLTemplateData(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
        ) {
            id
            name
            event
            model_type
            is_active
            created_by_name
            created_at
            updated_at
        }
    }
`;
export const GET_MAP_PLACE_LIST = gql`
    query Place(
        $organization: String
        $startTime: String
        $endTime: String
        $modelName: String
        $placeId: String
        ) {
        getMapPlaceList(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
            modelName: $modelName
            placeId: $placeId
            ) {
            place_id
            name
            lat
            lng
            ag_data
        }
    }
`;
export const GET_TOP_EVENTS = gql`
    query TopEvents($organization: String, $cameraId: String) {
        getTopEvents(organization: $organization, cameraId: $cameraId) {
            camId
            tags
            type
            event_time
            image
            priority
            name
            camera_name
            place_name
        }
    }
`;
export const GET_TOP_EVENTS_BY_FILTER = gql`
    query TopEvents(
        $organization: String
        $placeId: String
        $cameraId: String
        $modelType: String
        $dateTime: Int
        $endTime: Int
        $page: Int
        $limit: Int
    ) {
        getTopEventsByFilter(
            organization: $organization
            placeId: $placeId
            cameraId: $cameraId
            modelType: $modelType
            dateTime: $dateTime
            endTime: $endTime
            page: $page
            limit: $limit
        ) {
            count
            events
        }
    }
`;
export const GET_LAST_10_DAYS_EVENTS = gql`
    query TopEventsGraph(
        $startTime: Int
        $endTime: Int
        $organization: String
        $modelType: String
    ) {
        getEventsByDateGraph(
            startTime: $startTime
            endTime: $endTime
            organization: $organization
            modelType: $modelType
        ) {
            date
            count
        }
    }
`;
export const GET_MODEL_EVENTS = gql`
    query ModelEventsGraph(
        $startTime: Int
        $endTime: Int
        $organization: String
    ) {
        getEventsByModelGraph(
            startTime: $startTime
            endTime: $endTime
            organization: $organization
        ) {
            model
            count
        }
    }
`;
export const GET_PLACE_EVENTS = gql`
    query PlaceEventsGraph(
        $startTime: Int
        $endTime: Int
        $organization: String
        $modelName: String
        $placeId: String
    ) {
        getEventsByPlaceGraph(
            startTime: $startTime
            endTime: $endTime
            organization: $organization
            modelName: $modelName
            placeId: $placeId
        ) {
            place_id
            name
            count
            lat
            lng
        }
    }
`;
export const GET_PRIORITY_EVENTS = gql`
    query PriorityEventsGraph(
        $startTime: Int
        $endTime: Int
        $organization: String
    ) {
        getEventsByPriorityGraph(
            startTime: $startTime
            endTime: $endTime
            organization: $organization
        ) {
            priority
            count
        }
    }
`;
export const GET_CAMERA_EVENTS = gql`
    query CameraEventsGraph(
        $startTime: Int
        $endTime: Int
        $organization: String
    ) {
        getEventsByCameraGraph(
            startTime: $startTime
            endTime: $endTime
            organization: $organization
        ) {
            name
            count
        }
    }
`;
export const GET_NOTIFICATION_REPORT = gql`
    query NotificationsReport(
        $organization: String
        $placeId: String
        $cameraId: String
        $modelType: String
        $dateTime: Int
        $endTime: Int
        $page: Int
        $tag: JSON
        $limit: Int
        $status: String
        $start_confidence: Float
        $end_confidence: Float
        $client: String
        # $tempId: Int
    ) {
        getNotificationsReportList(
            organization: $organization
            placeId: $placeId
            cameraId: $cameraId
            modelType: $modelType
            dateTime: $dateTime
            endTime: $endTime
            page: $page
            tag: $tag
            limit: $limit
            status: $status
            start_confidence: $start_confidence
            end_confidence: $end_confidence
            client: $client
            # temId: $temId
        ) {
            count
            acknowledgedCount
            agreeCount
            disagreeCount
            notifications
        }
    }
`;

export const GET_DEVICE_REPORT = gql`
    query DeviceReport(
        $organization: String
        $device_id: String
        $start_date: String
        $end_date: String
    ) {
        getDeviceReportList(
            organization: $organization
            device_id: $device_id
            start_date: $start_date
            end_date: $end_date
        ) {
            deviceReport
        }
    }
`;
export const GET_CONTROLLERS = gql`
    query Controllers($organization_id: String) {
        getControllers(organization_id: $organization_id) {
            id
            controller_id
            role
            controller_name
            created_at
            controller_status
            controller_ip
        }
    }
`;
export const GET_CONNECTIVITY_INFO = gql`
    query ConnectivityInfo(
        $ip_address: String
        $vlan: String
        $speed: String,
        $opportunityId: String
    ) {
        getConnectivityInfo(
            ip_address: $ip_address
            vlan: $vlan
            speed: $speed,
            opportunityId: $opportunityId
        ) {
            ip_address
            vlan
            speed
        }
    }
`;
export const GET_ELASTIC_USER = gql`
    query ElasticUser(
        $opportunityId: String
    ) {
        getElasticUser(
            opportunityId: $opportunityId
        ) {
            username
            password
        }
    }
`;
export const GET_MONITORING_LIST = gql`
    query MonitoringList {
        getMonitoringList {
            _id
            monitoringType
            monitoringUrl
        }
    }
`;
export const GET_ACTIVITY_LOGS = gql`
    query ActivityLogs(
        $fromDate: Date
        $toDate: Date
        $userId: String
        $searchText: String
        $page: Int
    ) {
        getAllActivityLogs(
            fromDate: $fromDate
            toDate: $toDate
            userId: $userId
            searchText: $searchText
            page: $page
        ) {
            result
            total
        }
    }
`;
export const GET_ACTIVITY_LOGS_BY_CAMERA = gql`
    query ActivityLogs(
        $cameraId: String
    ) {
        getAllActivityLogsByCamera(
            cameraId: $cameraId
        ) {
            id
    actionType
    cameraId
    cameraName
    ipAddress
    browser
    description
    createdAt
    createdBy
    createdByName 
        }
    }
`;
export const GET_ALL_OPPORTUNITY = gql`
    query AllOpportunities($page: Int) {
        getOpportunities(page: $page) {
            results
        }
    }
`;
export const GET_OPPORTUNITY_DETAILS = gql`
    query OpportunitiesDetails($opportunityId: String) {
        getOpportunitiesDetails(opportunityId: $opportunityId) {
            opportunitiesDetails
        }
    }
`;
export const GET_ALL_PARTS = gql`
    query AllParts {
        getAllParts {
            part_no
            part_description
            quantity
        }
    }
`;
export const GET_OPPORTUNITY_BY_ID = gql`
    query OpportunitiesById($opportunityId: String) {
        getOpportunityById(opportunityId: $opportunityId) {
            opportunitiesDetails
        }
    }
`;
export const GET_NIGERIA_OPPORTUNITY_BY_ID = gql`
    query NigeriaOpportunitiesById($opportunityId: String) {
        getNigeriaOpportunityById(opportunityId: $opportunityId) {
            opportunitiesDetails
        }
    }
`;
export const GET_NIGERIA_PROJECT_LOGS = gql`
    query NigeriaProjectLogsOpportunitiesById($opportunityId: String) {
        getNigeriaProjectLogsOpportunitiesById(opportunityId: $opportunityId) {
            projectLogs
        }
    }
`;
export const GET_ELASTIC_CLOUD_DATA = gql`
    query ElasticCloudData($requestPayload: String) {
        getElasticCloudFlowData(requestPayload: $requestPayload) {
            data
        }
    }
`;
export const GET_ALL_OPPORTUNITY_CONFIGURATION = gql`
    query AllOpportunityConfiguration($page: Int) {
        getOpportunityConfiguration(page: $page) {
            results
        }
    }
`;
export const GET_ALL_CONTROLLERS = gql`
    query AllControllers($organization_id: String) {
        getControllers(organization_id: $organization_id) {
            id
            controller_id
            controller_name
            controller_status
            created_at
            provision_type
            deployment_type
            role
            created_at
            created_by
            controller_ip
        }
    }
`;
export const GET_ALL_UCPES = gql`
    query AllUcpes($organization_id: String, $search: String) {
        getUcpes(organization_id: $organization_id, search: $search) {
            id
            device_name
            device_ip
            controller_ip
            device_status
            created_by
            created_at
            device_id
            memory
            vcpus
            location
        }
    }
`;
export const GET_ALL_UCPES_PAGINATION = gql`
    query AllUcpesPagination($organization_id: String, $search: String, $status: String, $page: Int) {
        getUcpesPagination(organization_id: $organization_id, search: $search, status: $status, page: $page) {
            results
        }
    }
`;
export const GET_ALL_SFC = gql`
    query AllSFC($organization_id: String) {
        getSFC(organization_id: $organization_id) {
            bridge_id
            bridge_type
            bridge_name
        }
    }
`;
export const GET_ALL_SCRIPT = gql`
    query AllScript($organization_id: String) {
        getScript(organization_id: $organization_id) {
            uuid
            controller__controller_ip
            script_type
            file_name
            module
            created_by
            updated_by
        }
    }
`;
export const GET_ALL_DEVICE_BY_SCRIPT = gql`
    query AllDeviceByScript($script_id: String) {
        getDeviceByScript(script_id: $script_id) {
            id
            device_id
            device_name
            device_status
        }
    }
`;
export const GET_ALL_VM_IMAGES = gql`
    query AllVMImages($organization_id: String, $status: String) {
        getVmImages(organization_id: $organization_id, status: $status) {
            id
            vm_images_id
            image_name
            image_size
            image_version
            image_status
            image_logo
            created_at
            created_by
        }
    }
`;
export const GET_ALL_VNF_TEMPLATES = gql`
    query AllVnfTemplates($organization_id: String) {
        getVnfTemplates(organization_id: $organization_id) {
            id
            vnf_template_id
            vnfT_name
            vnfT_status
            vnfT_numVcpu
            vnfT_memoryMB
            created_at
            created_by
            vnf_interface_count
            vm_image {
                vm_image_id
                vm_image_name
            }
        }
    }
`;
export const GET_ALL_VNF = gql`
    query AllVnfs($organization_id: String, $device_id: String, $vnf_template_id: String, $status: String) {
        getVnfs(organization_id: $organization_id, device_id: $device_id, vnf_template_id: $vnf_template_id, status: $status) {
            VnfData
        }
    }
`;
export const GET_VM_IMAGES_LIST = gql`
    query images($organization_id: String) {
        getVmImages(organization_id: $organization_id) {
            vm_images_id
            image_name
            image_status
        }
    }
`;
export const GET_CONTROLLERS_LIST = gql`
    query controllers($organization_id: String) {
        getControllers(organization_id: $organization_id) {
            controller_id
            controller_name
            controller_status
        }
    }
`;
export const GET_TEMPLATE_LIST = gql`
    query templates($organization_id: String, $device_id: String) {
        getVnfTemplates(organization_id: $organization_id, device_id: $device_id) {
            vnf_template_id
            vnfT_name
            vnfT_status
        }
    }
`;
export const GET_UCPE_LIST = gql`
    query ucpes($organization_id: String, $search: String, $controller_id: String) {
        getUcpes(organization_id: $organization_id, search: $search, controller_id: $controller_id) {
            id
            device_id
            device_name
            device_status
        }
    }
`;
export const GET_DASHBOARD_STATS = gql`
    query dashboardStats($organization_id: String) {
        getDashboardStats(organization_id: $organization_id) {
            avgDiskFree
            total_devices
            avgDisk
            avgMemFree
            avgMemory
            avgVcpusFree
            avgVcpus
            noOfUpDevices
            noOfDownDevices
        }
    }
`;
export const GET_DASHBOARD_DIA = gql`
    query dashboardDia {
        getDashboardDia {
            FeasibilityFeasible
            FeasibilityPending
            FeasibilityNotFeasible
            DesignSolutionPending
            Opportunities
        }
    }
`;
export const GET_MAP_DATA = gql`
    query dashboardMapData($organization_id: String, $search: String) {
        getUcpes(organization_id: $organization_id, search: $search) {
            id
            device_id
            device_name
            gps_latitude
            gps_longitude
            device_status
        }
    }
`;
export const GET_UCPE_EVENT_DATA = gql`
    query dashboardEventData($organization_id: String, $place: String) {
        getUcpeEvent(organization_id: $organization_id, place: $place) {
            deviceName
            event_log_count
        }
    }
`;
export const GET_DASHBOARD_EVENT_DATA = gql`
    query eventDashboardData(
        $deviceID: String
        $start_date: String
        $end_date: String
    ) {
        getEventDashboard(
            deviceID: $deviceID
            start_date: $start_date
            end_date: $end_date
        ) {
            deviceName
            timestamp
            type
            name
            sourceType
        }
    }
`;
export const GET_UCPE_STATS = gql`
    query ucpeStats($device_id: String, $start_date: String, $end_date: String) {
        getUcpeStats(
            device_id: $device_id
            start_date: $start_date
            end_date: $end_date
        ) {
            datetime
            diskFree
            disk
            memFree
            memory
            vcpusFree
            vcpus
        }
    }
`;
export const GET_AWS_REGIONS = gql`
    query awsRegions {
        getAwsRegions
    }
`;
export const GET_VNF_BY_UCPE = gql`
    query vnfByUcpe($device_id: String) {
        getVnfByUcpe(device_id: $device_id) {
            id
            vnf_id
            vnf_name
            created_at
            created_by
            vnf_status
            vnf_ssh
            vnf_web
        }
    }
`;
export const GET_VNF_BY_TEMPLATE = gql`
    query vnfByTemplate($template_id: String) {
        getVnfByTemplate(template_id: $template_id) {
            id
            vnf_id
            vnf_name
            created_at
            created_by
            vnf_status
            vnf_ssh
            vnf_web
        }
    }
`;
export const GET_ALL_PLACES = gql`
    query allPlaces($organization_id: String) {
        getAllPlaces(organization_id: $organization_id) {
            id
            created_at
            updated_at
            place_name
            gps_latitude
            gps_longitude
            organisation
        }
    }
`;
export const GET_ALL_NOTIFICATION = gql`
    query allNotification($organization_id: String) {
        getAllNotifications(organization_id: $organization_id) {
            id
            created_at
            updated_at
            notification_id
            notification_name
            notification_type
            notification_parameter
            notification_template
            notification_priority
            is_verified
            created_by
            organisation
        }
    }
`;
/* SDN NFV queries*/
export const GET_ALL_NETWORK_INTERFACES = gql`
    query NetworkInterfaces($deviceID: String) {
        getNetworkInterfaces(deviceID: $deviceID) {
            interface_name
            sriovCapable
            mgmt
            inband
            dpdkCapable
            macAddress
        }
    }
`;
/* VNF INTERFACE queries*/
export const GET_ALL_VNF_NETWORK_INTERFACES = gql`
    query VnfNetworkInterfaces($deviceID: String, $vnfTmpltName: String) {
        getVnfNetworkInterfaces(deviceID: $deviceID, vnfTmpltName: $vnfTmpltName) {
            id
            vnf_interface
            vnfT_cloudInit
        }
    }
`;
/* DEVICE INTERFACE queries*/
export const GET_ALL_DEVICE_INTERFACES = gql`
    query DeviceInterfaces($deviceID: String) {
        getDeviceInterfaceList(deviceID: $deviceID) {
            id
            bridge_interface
            network_interface_id
            number_of_adaptors
            ni_name
            ni_type
            ni_subtype
        }
    }
`;
/* UNUSED DEVICE INTERFACE queries*/
export const GET_UNUSED_DEVICE_INTERFACES = gql`
    query DeviceUnusedInterfaces($deviceID: String) {
        getDeviceUnusedInterfaceList(deviceID: $deviceID) {
            interface_name
        }
    }
`;
/* SFC BRIDGE NAME queries*/
export const GET_SFC_BRIDGE_NAME = gql`
    query SFCBridgeName($deviceID: String) {
        getSFCBridgeNameList(deviceID: $deviceID) {
            bridge_name
        }
    }
`;
/* DEVICE INTERFACE CHECK DUPLICATE*/
export const CHECK_DUPLICATE_DEVICE_INTERFACE = gql`
    query CheckDeviceInterface($deviceID: String, $ni_name: String) {
        checkDeviceInterface(deviceID: $deviceID, ni_name: $ni_name) {
            status
        }
    }
`;
/* DEVICE DETAILS*/
export const GET_DEVICE_DETAILS = gql`
    query DeviceDetails($deviceID: String) {
        getDeviceDetails(deviceID: $deviceID) {
            id
            created_at
            updated_at
            place_id
            device_id
            controller_id
            device_ip
            device_username
            network_port
        }
    }
`;
/*
  Get vnf marketplace details
 */
export const GET_VNF_MARKET_PLACE_BY_ID = gql`
    query VnfMarketPlaceById($vnfMarketPlaceId: String) {
        getVnfMarketPlaceById(vnfMarketPlaceId: $vnfMarketPlaceId) {
            _id
            imageName
            imageURL
            imageLogo
            imageType
            description
            overview
            review
            support
            highlightsList
            overviewList
            imagePrice
            createdAt
        }
    }
`;
/*
  Get VPNS
 */
export const GET_VPNS_LIST = gql`
    query VPNS($organization: String, $search: String, $page: Int, $limit: Int) {
        getVpnsList(organization: $organization, search: $search, page: $page, limit: $limit) {
            count
            vpns
        }
    }
`;
/*
  Get connectivity details by id
 */
export const GET_CONNECTIVITY_INFO_DETAILS = gql`
    query ConnectivityInfo($opportunityId: String) {
        getConnectivityInfoById(opportunityId: $opportunityId) {
            id
            vLanId
            port
            ipAddress
        }
    }
`;
/*
  Get device list by type
 */
export const GET_DEVICE_LIST_BY_TYPE = gql`
    query DeviceList($type: String) {
        getDeviceListByType(type: $type) {
            engine_name
            configuration_type
        }
    }
`;/*
  Get device list by type
 */
export const GET_DEVICE_LIST_BY_TYPE_NIGERIA = gql`
    query DeviceListNigeria($type: String) {
        getDeviceListByTypeNigeria(type: $type) {
            device_name
            device_type
        }
    }
`;
export const GET_BILLING_UTILIZATION_ITEMS = gql`
    query BillingUtilizationItems(
        $organization: String
        $startDate: Float
        $endDate: Float
    ) {
        getBillingUtilizationItems(
            organization: $organization
            startDate: $startDate
            endDate: $endDate
        ) {
            data
        }
    }
`;
export const GET_BILLING_DIA_UTILIZATION_ITEMS = gql`
    query BillingDiaUtilizationItems($opportunityId: String, $subscriptionId: String, $startDate: Float, $endDate: Float) {
        getBillingDiaUtilizationItems(opportunityId: $opportunityId, subscriptionId: $subscriptionId, startDate: $startDate, endDate: $endDate) {
            data
        }
    }
`;
export const GET_LAST_SIX_MONTH_INVOICE_REPORT = gql`
    query BillingInvoice($subscriptionId: String) {
        getLastSixMonthInvoiceReport(subscriptionId: $subscriptionId) {
            totalPayableAmount
            invoiceDate
        }
    }
`;
export const GET_INVOICE_REPORT = gql`
    query BillingInvoice($subscriptionId: String, $invoiceDate: String) {
        getInvoiceReport(
            subscriptionId: $subscriptionId
            invoiceDate: $invoiceDate
        ) {
            _id
            totalPayableAmount
            totalCost
            totalDiscount
            taxAmount
            invoiceDate
            invoiceNumber
            currency
            status
            utilizations
        }
    }
`;
export const GET_LINE_CROSS_OBJECT = gql`
    query LineCrossObject($templateId: Int) {
        getLineCrossObjectById(
            templateId: $templateId
        ) {
            _id
            templateId
            lines
            image
        }
    }
`;
export const GET_EDGE_AI_TYPES = gql`
    query EdgeAiTypes {
        getAllEdgeAITypes {
            id
            type
            name
            provider
            params
            icon
        }
    }
`;
export const GET_EDGE_AI_SETTINGS = gql`
    query EdgeAiTypes {
        getEdgeAISettings {
            actions
            providerTypes
            multiobjects
            tagsFilter
            cameraFps
            cameraResolutions
            frequencies
            liveCamURL
            accidentDetectionObj,
            customDetectionObjects
        }
    }
`;

export const GET_VIDEOS_LIST = gql`
    query Videos ($organization: String, $placeId: String, $templateId: Int, $search: String, $page: Int, $limit: Int, $status: String){
        getAllVideosList (
            organization: $organization
            placeId: $placeId
            search: $search
            page: $page
            limit: $limit
            templateId: $templateId
            status: $status
        ) {
            count
            videos
        }
    }
`;

export const GET_PLACES_FOR_STATUS_MAP = gql`
    query StatusMapPlaces(
        $organization: String
        $startTime: String
        $endTime: String
        $modelName: String
        $placeId: String
    ) {
        getPlacesForStatusMap(
            organization: $organization
            startTime: $startTime
            endTime: $endTime
            modelName: $modelName
            placeId: $placeId
        ) {
            place_id
            name
            lat
            lng
            ag_data
        }
    }
`;


export const GET_SITE_PHONES_LIST = gql`
  query SitePhones($orgId: String) {
      getSitePhonesList (orgId: $orgId) {
        phones
        count
      }
  }
`;

export const GET_PROCESS_WORKFLOW_STEPS = gql`
    query ProcessWorkFlow(
        $subscriptionId: String
    ) {
        getProcessWorkFlowSteps(
            subscription: $subscriptionId
        ) {
            id
            title
            description
            status
            subscription
            createdById
            createdByName
            createdAt
            workflowOptions
        }
    }
`;

export const GET_NOTIFICTION_PROCESS_STEPS = gql`
    query NotificationProceedSteps(
        $id: Int
        $orgId: String
    ) {
        getNotificationProceedSteps(
            id: $id
            orgId: $orgId
        ) {
            id
            title
            description
            status
            comments
            isCompleted
            createdByName
        }
    }
`;

export const GET_EDGE_AI_ANALYTICS_DASHBOARD = gql`
    query AnalyticsDashboardReport(
        $orgId: String
        $cameraId: [JSON]
        $startDate: Int
        $endDate: Int
        $object: String
        $threshold: Float
    ) {
        getAnalyticsDashboardReport(
            orgId: $orgId
            cameraId: $cameraId
            startDate: $startDate
            endDate: $endDate
            object: $object
            threshold: $threshold
        ) {
            reportData
        }
    }
`;

/*
  Get All Heatmap List
 */
export const GET_HEATMAP_LIST = gql`
  query HeatmapRecords {
    getHeatmapRecordsList {
        data
        count
      }
  }
  `;

/*
  Get All 
*/
export const GET_EDGE_AI_VIEWERS_VIDEOS = gql`
  query viewersVideos($cameraId: String) {
    getAllViewersVideos(cameraId: $cameraId) {
        url
        key
        size
        createdAt
        createdBy
        status
      }
  }
  `;

/*
  Get Edge AI Node Details
*/
export const GET_EDGE_AI_NODE_DETAILS = gql`
    query EdgeAINodeDetails($id: String, $organization: String) {
        getEdgeAINodeDetails(id: $id, organization: $organization) {
            response
            ipAddress
            cameras
        }
    }
`;

/*
  Get Edge AI Node CPU usage
*/
export const GET_EDGE_AI_NODE_USAGE = gql`
    query EdgeAINodeUsageDetails($id: String, $type: String) {
        getEdgeAINodeUsageDetails(id: $id, type: $type) {
            cpuUsage
            ramUsage
            gpuUsage
        }
    }
`;

/*
  Get Edge AI Node CPU usage
*/
export const GET_TRANSECTIONS = gql`
    query Transection(
        $organization: String, 
        $startDate: Date, 
        $endDate: Date, 
        $search: String,
        $page: Int,
        $limit: Int
    ) {
        getTransections(
            organization: $organization
            startDate: $startDate
            endDate: $endDate
            search: $search
            page: $page
            limit: $limit
        ) {
            data
            count
            totalAmount
        }
    }
`;

export const GET_UNKNOWN_FACES = gql`
    query UnknownFaces(
        $organization: String
        $cameraId: String
        $dateTime: Int
        $endTime: Int
        $NextContinuationToken: String
    ) {
        getUnknownFaceList(
            organization: $organization
            cameraId: $cameraId
            dateTime: $dateTime
            endTime: $endTime
            NextContinuationToken: $NextContinuationToken
        ) {
            faces
        }
    }
`;

export const GET_ATTACHED_CAMERA_LIST = gql`
    query AttachedCamera(
        $id: Int
    ) {
        getAttachedCameraList(
            id: $id
        ) {
            t4_cam_id
            place
            created_by_name
            name
            url
            created_at
        }
    }
`;

export const GET_DEMO_VIDEOS = gql`
    query GetDemoVideos {
        getDemoVideos {
            success
            data
            message
        }
    }
`