import React, { createContext, useState, useContext, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { getToken } from '../clientConfig';

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  loggedInUserRoles: null,
  // loading: null,
  error: null,
  login: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggedInUserRoles, setLoggedInUserRoles] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUserFromLocalStorage() {
      const token = getToken();
      if (token) {
        var tokenDecoded = jwt_decode(token);
        setUser(tokenDecoded);
        setLoggedInUserRoles(tokenDecoded?.realm_access?.roles);
      } else {
        localStorage.clear();
      }
      setLoading(false);
    }
    loadUserFromLocalStorage();
  }, [loading]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        loggedInUserRoles
        // error: errorMsg,
        // login,
        // loading,
        // logout,
        // verifyOtp
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
