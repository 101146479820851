import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL} from '../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ControllerStatusPanel from "../ControllerStatusPanel";
import Button from "@material-ui/core/Button";

const VNFTemplateItems = ({
                      t,
                      key,
                      vnfImage,
                      handleMenuItemClick,
                      menuItems
                  }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={key}>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.vnfT_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.vnfT_numVcpu}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.vnfT_memoryMB}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.vnf_interface_count}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.vm_image.vm_image_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {dateFormatterLLL(vnfImage.created_at)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {vnfImage.created_by}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    <ControllerStatusPanel
                        status={vnfImage.vnfT_status}
                        t={t}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}
                    >
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && <MenuWrapper
                    id={'ticket-menu'}
                    anchorEl={anchorEl}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    menuItems={menuItems}
                    handleMenuItemClick={elem => {
                        handleMenuItemClick(elem, vnfImage.vnf_template_id, 'vnfTemplate');
                        setAnchorEl(null);
                    }}
                />}
            </TableCell>
        </TableRow>
    );
};
VNFTemplateItems.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(VNFTemplateItems);
