import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const addPod = async payload => {
  try {
    const response = await axios.post('pod', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getPodById = async podId => {
  try {
    const response = await axios.get(`pod/podData/${podId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const updatePodById = async (podId, payload) => {
  try {
    const response = await axios.put(`pod/${podId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deletePod = async podId => {
  try {
    const response = await axios.delete(`pod/${podId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getPodListByNameFilter = async (page = 1, limit = 10, word = '') => {
  try {
    const response = await axios.get(
      `pod?limit=${limit}&page=${page}&search=${word}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

export { addPod, getPodById, updatePodById, deletePod, getPodListByNameFilter };
