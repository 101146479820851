import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { paddingGenerator, marginGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertUTCToLocal } from '../../../../../utils';
import Fab from '@material-ui/core/Fab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
	
const useStyles = makeStyles(theme => ({
	paper: {},
	icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
	iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
	const { t, onClick, loading } = props;
    return (
		<Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function UpdateVideoModal({
	t,
    open,
    handleUpdateVideoSubmitClick,
	data,
	selectePlaceList,
	selectMlTemplateList,
	selectNotiTemplateList,
	handleClose,
	modalState,
	subscriptionData,
	videoFpsOptions
}) {
	
	const videoInitFields = {name:'', rtsp_url:'', fps:'', placeId:'', width:'', height:'', videoRSL: '', remote_stream: "No"};
	const aiModelInitFields = {ai_template_id:'', from_time:'', to_time:''};
	const notificationInitFields = {notification_template_id:'', ai_template_id:''};
	
	const [videoState, setVideoState] = useState([{ ...videoInitFields }]);
    const [aiModelState, setAIModelState] = useState([]);
    const [notificationState, setNotificationState] = useState([{ ...notificationInitFields }]);
	const [selectedMlTemplates, setSelectedMlTemplates] = useState([]);
	const [provider, setProvider] = useState('');
	const [node, setNode] = useState('');
	const [mlTemplateList, setMLTemplateList] = useState([]);
	const [deletedAIModels, setDeletedAIModels] = useState([]);
	const [deletedNotifications, setDeletedNotifications] = useState([]);

	const classes = useStyles();
	
	React.useEffect(() => {
        if(modalState.clearData && open){
            setVideoState([{ ...videoInitFields }]);
			setAIModelState([{...aiModelInitFields}]);
			setNotificationState([{...notificationInitFields}]);
			setDeletedAIModels([]);
			setDeletedNotifications([]);
			handleClose();
        }
	}, [modalState]);
	
	React.useEffect(() => {
		if(data && data.video_id){		
			setAIModelState([]);
			setSelectedMlTemplates([]);
			setMLTemplateList([]);
			let notificationData = [];
			if(data.notification_filters){
				for(let i = 0; i < data.notification_filters.length; i++){
					let notification = data.notification_filters[i];
					let tempObj = {id: notification.id, notification_template_id: notification.notification_template.id, ai_template_id: notification.model_template.id};
					notificationData.push(tempObj)
				}
			}
			let camData = {name: data.name, rtsp_url: data.url, fps: data.fps, placeId: data.place, width: data.content_width, height: data.content_height, videoRSL: `${data.content_width}*${data.content_height}`, remote_stream: data.remote_stream ? "Yes" : "No" };
			setVideoState([{ ...camData }]);
			setNotificationState([...notificationData]);
		}
	}, [data]);

	React.useEffect(() => {
		if(selectMlTemplateList && selectMlTemplateList.length){
			let aiTemplateData = []; 
			if(data.schedules && data.schedules.length){
				for(let i = 0; i < data.schedules[0].length; i++){
					let schedule = data.schedules[0][i];
					let tempObj = {
						ai_template_id: (schedule.model_template.id) ? schedule.model_template.id: '', 
						from_time: convertUTCToLocal(schedule.start_time + ' 00'), 
						to_time: convertUTCToLocal(schedule.end_time + ' 00'),
						scheduleId: schedule.id
					}
					aiTemplateData.push(tempObj)
				}
			}
			setAIModelState([...aiTemplateData]);
			let selectedIds = aiTemplateData.map(value => {
				if(value.ai_template_id){
					return value.ai_template_id;
				}
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if(selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
			let mlTemplates = selectMlTemplateList.filter(item => {
				if(item.provider === data.provider){
					return item;
				}

			});
			setMLTemplateList(mlTemplates);
		}
	}, [selectMlTemplateList]);

    const handleVideoChange = (value, column) => {
		setVideoState({...videoState, [column]: value});
	}

	const handleAIModelChange = (value, idx, column) => {
		const updatedAIModal = [...aiModelState];
		if(updatedAIModal.length && column === 'ai_template_id' && value){
			let oldAITypes = [];
			updatedAIModal.map(aiItm => {
				if(aiItm.ai_template_id){
					let mlTmp = mlTemplateList.filter(itm => itm.id === aiItm.ai_template_id);
					oldAITypes.push(mlTmp[0].model_type);
				}
			});
			let newSelectAI = mlTemplateList.filter(itm => itm.id === value);
			if(newSelectAI && newSelectAI.length > 0){
				let mType = newSelectAI[0].model_type;
				if(oldAITypes.includes(mType)){
					Message.error("AI type already added! Please select another AI Model.");
					return null;
				}
			}
		}

		updatedAIModal[idx][column] = value;
		setAIModelState(updatedAIModal);

		if(column === 'ai_template_id'){
			let selectedIds = updatedAIModal.map(value => {
				if(value.ai_template_id){
					return value.ai_template_id;
				}
			});
			selectedIds = selectedIds.filter(function(item, pos) {
				return selectedIds.indexOf(item) == pos;
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if(selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
		}
	}
	
	const handleNotificationChange = (value, idx, column) => {
		const updatedNoti = [...notificationState];
		updatedNoti[idx][column] = value;
		setNotificationState(updatedNoti);
	}

	const addNewAIModelFileds = () => {
		if(selectMlTemplateList.length <= aiModelState.length){
			Message.error(`You can add only ${selectMlTemplateList.length} ai templates!`);
			return null;
		}
		setAIModelState(prevAiState => [...prevAiState, aiModelInitFields]);
	}

	const addNewNotificationFileds = () => {
		setNotificationState(oldArray => [...oldArray, {...notificationInitFields}]);
	}

	const removeMlTemplate = (id, index, scheduleId) => {
		let prevState = [...aiModelState];
		if(scheduleId){
			setDeletedAIModels([...deletedAIModels, scheduleId]);
		}
		if(id){
			let prevNotiState = [...notificationState];
			for(let i = 0; i < notificationState.length; i++){
				if(prevNotiState[i] && prevNotiState[i].ai_template_id && prevNotiState[i].ai_template_id === id){
					if(prevNotiState[i].id){
						setDeletedNotifications([...deletedNotifications, prevNotiState[i].id]);
					}
					prevNotiState.splice(i, 1);
				}
			}
			setNotificationState(prevNotiState);
			let selectedIds = prevState.map(value => {
				if(value.ai_template_id){
					return value.ai_template_id;
				}
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if(selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
		}
		prevState.splice(index, 1);
		setAIModelState(prevState);
	}

	const removeNotificationTemplate = (id, index, noti_id) => {
		let prevState = [...notificationState];
		prevState.splice(index, 1);
		setNotificationState(prevState);
		if(noti_id){
			setDeletedNotifications([...deletedNotifications, noti_id]);
		}
	}

	return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="video-template-modal"
            aria-describedby="video-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="video-template-title">{t('createNew.label')} {t('video.label')} {t('configuration.label')}
            </DialogTitle>
            <DialogContent>
				<div className={classes.root}>
					<Grid container spacing={3}>
                        <React.Fragment>
							<Grid item xs={4}>
								<Input
									type={'text'}
									placeholder={`${t('videoName.label')}*`}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									value={videoState.name}
									onChange={evt => handleVideoChange(evt.target.value, 'name')}
								/>
							</Grid>
							<Grid item xs={4}>
								<Input
									type={'file'}
									placeholder={`${t('uploadFile.label')}*`}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									value={videoState.video_file}
                                    disabled={true}
									inputProps={{ accept: "video/*" }}
								/>
							</Grid>
							<Grid item xs={4}>
								<Input
									value={videoState.description}
									type={'text'}
									placeholder={t('description.label')}
									style={{ width: '100%' }}
									className={classes.input}
									color={'secondary'}
									onChange={evt => handleVideoChange(evt.target.value, 'description')}
								/>
							</Grid>
							<Grid item xs={4}>
								<OutlinedSelect
									val={videoState.fps}
									label={`${t('videofps.label')} *`}
									handleChange={evt => {
										handleVideoChange(evt.target.value, 'fps');
									}}
									selectStyle={{ ...paddingGenerator('p-8') }}
									styleOverrides={{ width: '100%', marginRight: 0 }}
									options={videoFpsOptions ? videoFpsOptions : []}
								/>
							</Grid>
							<Grid item xs={4}>
								<Autocomplete
									id={`place-select`}
									options={selectePlaceList}
									getOptionLabel={(option) => option.name}
									onChange={(event, newValue) => {
										handleVideoChange(newValue, 'place')
									}}
									style={{ border: '1px solid #ebebeb' }}
									renderInput={(params) => <TextField {...params} label={`${t('place.label')} *`} variant="outlined" />}
								/>
							</Grid>
						</React.Fragment>
						<Grid item xs={12} style={{...marginGenerator("mt-24")}}>
							<Button
								variant={'outlined'}
								color={'primary'}
								
								onClick={() => {
									addNewAIModelFileds();
								}}
							>
								{t('add.label')} {t('aimodel.label')}
							</Button>
						</Grid>
						{ !aiModelState.length && <CircularProgress color="secondary" style={{margin:'auto'}} />}
						{aiModelState && aiModelState.map((val, idx) => {
							return (
								<React.Fragment key={`ai-${idx}`}>
									<Grid item xs={12} style={{...paddingGenerator('pl-5'), ...paddingGenerator('pl-0')}}>
										<Typography variant="subtitle2" color="textPrimary" >
											{t('aimodel.label')} {(idx+1)}
										</Typography>
									</Grid>
									<Grid item xs={5}>
										<OutlinedSelect
											disabled={aiModelState[idx].scheduleId ? true : false}
											val={aiModelState[idx].ai_template_id}
											label={`${t('aiTemplate.label')} *`}
											handleChange={evt => {
												handleAIModelChange(evt.target.value, idx, 'ai_template_id');
											}}
											selectStyle={{...paddingGenerator('p-8')}}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={mlTemplateList}
										/>
									</Grid>
									<Grid item xs={3}>
										<Input
											type={'time'}
											placeholder={`${t('fromTime.label')}*`}
											style={{width: '100%'}}
											className={classes.input}
											color={'secondary'}
											value={aiModelState[idx].from_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'from_time')}
										/>
									</Grid>
									<Grid item xs={3}>
										<Input
											type={'time'}
											placeholder={`${t('toTime.label')}*`}
											style={{width: '100%'}}
											className={classes.input}
											color={'secondary'}
											value={aiModelState[idx].to_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'to_time')}
										/>
									</Grid>
									<Grid item xs={1}>
										<Fab size="small" color="secondary" aria-label="Remove" className={classes.margin} onClick={evt => { removeMlTemplate(aiModelState[idx].ai_template_id, idx, aiModelState[idx].scheduleId ? aiModelState[idx].scheduleId : '') }}>
											<DeleteForeverIcon/>
										</Fab>
									</Grid>
								</React.Fragment>
							)
						})}

						<Grid item xs={12} style={{...marginGenerator("mt-10")}}>
							<Button
								variant={'outlined'}
								color={'primary'}
								disabled={selectNotiTemplateList && selectNotiTemplateList.length > 0 ? false : true}
								onClick={() => {
									addNewNotificationFileds();
								}}
							>
								{t('add.label')} {t('notification.label')}
							</Button>
						</Grid>
						{notificationState.map((val, idx) => {
							return (
								<React.Fragment key={`noti-${idx}`}>
									<Grid item xs={12} style={{...paddingGenerator('pl-5'), ...paddingGenerator('pl-0')}}>
										<Typography variant="subtitle2" color="textPrimary" >
											<label htmlFor={`remove-check-${idx}`}>
												{t('notification.label')} {(idx+1)}
											</label>
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<OutlinedSelect
											val={notificationState[idx].notification_template_id}
											label={`${t('notification.label')} ${t('template.label')}*`}
											handleChange={evt => {
												handleNotificationChange(evt.target.value, idx, 'notification_template_id');
											}}
											selectStyle={{...paddingGenerator('p-8')}}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectNotiTemplateList}
										/>
									</Grid>
									<Grid item xs={5}>
										<OutlinedSelect
											val={notificationState[idx].ai_template_id}
											label={`${t('aiTemplate.label')} *`}
											handleChange={evt => {
												handleNotificationChange(evt.target.value, idx, 'ai_template_id');
											}}
											selectStyle={{...paddingGenerator('p-8')}}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectedMlTemplates}
										/>
									</Grid>
									<Grid item xs={1}>
										<Fab size="small" color="secondary" aria-label="Remove" className={classes.margin}
										onClick={evt => { removeNotificationTemplate(notificationState[idx].notification_template_id, idx, notificationState[idx].id ? notificationState[idx].id : '') }}>
											<DeleteForeverIcon/>
										</Fab>
									</Grid>
								</React.Fragment>
							)
						})}
					</Grid>
				</div>
            </DialogContent>
            <DialogActions>							
				<Grid container justify={'space-between'}>
					<Button
						variant={'outlined'}
						onClick={() => {
							setVideoState([{ ...videoInitFields }]);
							setAIModelState([{ ...aiModelInitFields }]);
							setNotificationState([{ ...notificationInitFields }]);
							setDeletedAIModels([]);
							setDeletedNotifications([]);
							handleClose();
						}}
						>
							{t('cancel.label')}
					</Button>
					<ButtonComponent 
                    t={t} 
                    onClick={() => {
						if(!provider && !videoState.length){
                            Message.error(REQUIRED_ERROR);
                            return null;
						}
						handleUpdateVideoSubmitClick({videos: videoState, aiModels: aiModelState, notifications: notificationState, node, prevData: data, deletedAIModels, deletedNotifications, provider});
					}}
                    loading={modalState.btnLoading} />
				</Grid>
            </DialogActions>
        </Dialog>
    );
}

UpdateVideoModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleUpdateVideoSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(UpdateVideoModal);