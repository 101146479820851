import React from 'react';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import {marginGenerator} from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SDNNotificationPanelItem from '../SDNNotificationPanelItem';

function SDNNotificationPanel({
                                  t,
                                  menuItems,
                                  list,
                                  handleCreateButtonClick,
                                  handleMenuItemClick,
                                  loading,
                                  error
                              }) {
    const columns = [
        {
            label: t('notification.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('notificationType.label'),
            key: "notificationType"
        },
        {
            label: t('createdAt.label'),
            key: "createdAt"
        },
        {
            label: t('createdBy.label'),
            key: "createdDateBy"
        },
        {
            label: t('priority.label'),
            key: "priority"
        },
        {
            label: t('edit.label') + '/' + t('delete.label'),
            key: "button"
        }
    ];
    if (!loading && !error) {
        list = (list.getAllNotifications && list.getAllNotifications.length) ? list.getAllNotifications : [];
    }
    return (
        <div>
            <Grid style={{display: 'flex', alignItems: 'right'}} style={{...marginGenerator('mb-20'), float: 'right'}}>
                <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() =>
                        handleCreateButtonClick('notification_modal')
                    }>
                    {t('createNow.label')}
                </Button>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list.length > 0 ?
                                    list.map((rowitem, rowIndex) => (
                                        <SDNNotificationPanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            menuItems={menuItems}
                                            handleMenuItemClick={handleMenuItemClick}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
SDNNotificationPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default SDNNotificationPanel;
