import React, { useState } from 'react';
import {useLazyQuery } from '@apollo/react-hooks';

import OcrConfigurationLocation from './OcrConfigurationLoaction';
import OcrConfigurationProject from './OcrConfigurationProject';
import OcrConfigurationFolder from './OcrConfigurationFolder';
import CustomTabs from '../../../../../components/CustomTabs';
import OcrConfigurationGroupsAndUsers from './OcrConfigurationGroups&Users';
import OcrActivityLogs from '../OcrActivityLogs';
import OcrConfigurationDocuments from './OcrConfigurationDocuments/OcrConfigurationDocumentsReport/OcrConfigurationDocumentsReport';
import OcrConfigurationProcessDesign from './OcrConfigurationProcessDesign';
import { GET_OCR_ACTIVITY_LOGS } from '../../../Ocr/queries';
import { dateFormatterLLL, JSONToCSVConvertor } from '../../../../../utils';

const OcrConfiguration = ({
    t,
    organizationId,
    createLocationEnable,
    handleCreateLocationEnableFormClose,
    isLocationEnable,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    page,
    loadingOcrFolder,
    handlePageChange,
    isProjectEnable,
    handleCreateProjectEnableFormClose,
    createProjectEnable,
    handleSubmitOfOcrProject,
    handleSubmitOfOcrFolder,
    ocrProjectSubmit,
    handleSubmitOfOcrPlace,
    ocrLocationSubmit,
    createFolderEnable,
    isFolderEnable,
    isSubFolderEnable,
    handlecreateSubFolderEnableFormClose,
    createSubFolderEnable,
    handleSubmitOfOcrSubFolder,
    ocrSubFolderSubmit,
    handlecreateFolderEnableFormClose,
    ocrFolderSubmit,
    handleFilterdataForOptions,
    ocrConfigItemsDeletetingStarted,
    selecteIdForDelete,
    handleSubmitOfDeleteOcrTreeItems,
    assignProjectEnable,
    createGroupOrUserEnable,
    subscriptionUserListLoading,
    subscriptionUserData,
    ocrFetchLoadingForDocuments,
    ocrDocumentsListing,
    handleSubmitOrFilterForDocumentConfigTab,
    groupListLoading,
    ocrGroupData,
    ocrOcrUsersDataLoading,
    ocrUsersList,
    handleFilterForOcrUser,
    ocrSelectPlaces,
    ocrSelectProjects,
    subscriptionId,
    ocrProcessList,
    ocrOcrProcessDataLoading,
    allOcrUsersData,
    ocrUsersListLoading,
    refetchOcrProcessData
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activityLogsFilterState, setActivityLogsFilterState] = useState({ search: "", startDate: null, endDate: null, project: "", place: "", organization: organizationId, page: 1 });
    const [activityLogsData, setActivityLogsData] = useState({});
    const [activityLogPage, setActivityLogPage] = useState(1);
    const [projectFilterdList, setProjectFilterdList] = useState(null);
    const [isExportFile, setIsExportFile] = useState(false);
    const [activityLoading, setActivityLoading] = useState(false);

    const [getOcrActivityLogs] = useLazyQuery(GET_OCR_ACTIVITY_LOGS, {
        fetchPolicy: "no-cache",
        onError: (e) => {
            setActivityLoading(false);
            setIsExportFile(false);
        },
        onCompleted: (res) => {
            setIsExportFile(false);
            setActivityLoading(false);
            if(isExportFile && res.getOcrActivityLogs && res.getOcrActivityLogs.data){
                console.log("res.getOcrActivityLogs: ", res.getOcrActivityLogs);
                let fields = {
                    "actionType": "Action",
                    "createdBy": "Create By",
                    "createdAt": "Created Date",
                    "ipAddress": "IP Address",
                    "description": "Description"
                };
                const list = res.getOcrActivityLogs.data.map(item => {
                    let tmpObj = {
                        actionType: item.actionType,
                        createdBy: item.createdByName ? item.createdByName : "-",
                        createdAt: item.createdAt ?  dateFormatterLLL(new Date(item.createdAt)) : "-",
                        ipAddress: item.ipAddress ? item.ipAddress.client_ip : "-",
                        description: item.description
                    };
                    return tmpObj;
                });
                const filename = `activity-logs-report-${new Date().getTime()}`;
                const newList = [fields].concat(list);
                return JSONToCSVConvertor(newList, filename);
            }else{
                setActivityLogsData(res.getOcrActivityLogs);
            }
        }
    });
    
    React.useEffect(() => {
        setActivityLoading(true);
        return getOcrActivityLogs({
            variables: {...activityLogsFilterState}
        });
    }, []);

    const handleActivityFilterChange = (evt, key) => {
        const { value } = evt.target;
        if(key === "place"){
            if(value){
                setProjectFilterdList(ocrSelectProjects.filter(itm => itm.place === value));
            }else{
                setProjectFilterdList(null);
            }
        }
        setActivityLogsFilterState({...activityLogsFilterState, [key]: value});
    }
    const activityLogsFiltersList = [
        {
            label: t('search.label'),
            handleChange: evt => handleActivityFilterChange(evt, "search"),
            val: activityLogsFilterState.search,
            type: 'text',
            style:{ minWidth: 185, height: 40 }
        },
        {
            label: t('startDate.label'),
            handleChange: value => {
                setActivityLogsFilterState({...activityLogsFilterState, startDate: value})  
            },
            val: activityLogsFilterState.startDate,
            style: { marginLeft: 10 },
            type: 'date'
        },
        {
            label: t('endDate.label'),
            handleChange: value => {
                setActivityLogsFilterState({...activityLogsFilterState, endDate: value})  
            },
            val: activityLogsFilterState.endDate,
            style: { marginLeft: 10 },
            type: 'date'
        },
        {
            label: t('location.label'),
            handleChange: evt => {
                handleActivityFilterChange(evt, "place");
            },
            val: activityLogsFilterState.place,
            options: ocrSelectPlaces,
            type: 'select',
            style:{minWidth: 185, marginLeft: 10}
        },
        {
            label: t('project.label'),
            handleChange: evt => handleActivityFilterChange(evt, "project"),
            val: activityLogsFilterState.project,
            options: (projectFilterdList) ? projectFilterdList : ocrSelectProjects,
            type: 'select',
            style:{minWidth: 185, marginLeft: 10}
        }
    ];
    const handleActivityLogFilter = () => {
        setActivityLogPage(1);
        setActivityLoading(true);
        return getOcrActivityLogs({
            variables: {...activityLogsFilterState, page: 1}
        });
    }
    const handleActivityLogPageChange = (val) => {
        setActivityLogPage(val);
        setActivityLoading(true);
        return getOcrActivityLogs({
            variables: {...activityLogsFilterState, page: val}
        });
    }
    const handleExportActivityLogs = () => {
        setIsExportFile(true);
        return getOcrActivityLogs({
            variables: {
                organization: organizationId,
                search: activityLogsFilterState.search,
                startDate: activityLogsFilterState.startDate,
                endDate: activityLogsFilterState.endDate,
                place: activityLogsFilterState.place,
                project: activityLogsFilterState.project
            }
        });
    }

    const tabList = [
        {
            label: 'Location',
            children: (
                <OcrConfigurationLocation
                    t={t}
                    ocrPlaceData={ocrPlaceData}
                    createLocationEnable={createLocationEnable}
                    handleCreateLocationEnableFormClose={handleCreateLocationEnableFormClose}
                    isLocationEnable={isLocationEnable}
                    loading={loadingOcrPlace}
                    page={page.ocr_place}
                    handlePageChange={handlePageChange}
                    handleSubmitOfOcrPlace={handleSubmitOfOcrPlace}
                    ocrLocationSubmit={ocrLocationSubmit}
                    ocrPlaceDeletetingStarted={ocrConfigItemsDeletetingStarted}
                    selecteIdForDelete={selecteIdForDelete}
                    handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                />
            )
        },
        // {
        //     label: 'Project',
        //     children: (
        //         <OcrConfigurationProject
        //             t={t}
        //             ocrProjectData={ocrProjectData}
        //             loading={loadingOcrProject}
        //             page={page.ocr_project}
        //             handlePageChange={handlePageChange}
        //             isProjectEnable={isProjectEnable}
        //             handleCreateProjectEnableFormClose={handleCreateProjectEnableFormClose}
        //             createProjectEnable={createProjectEnable}
        //             ocrPlaceData={ocrPlaceData}
        //             placeLoading={loadingOcrPlace}
        //             handleSubmitOfOcrProject={handleSubmitOfOcrProject}
        //             ocrProjectSubmit={ocrProjectSubmit}
        //             ocrProjectDeletetingStarted={ocrConfigItemsDeletetingStarted}
        //             selecteIdForDelete={selecteIdForDelete}
        //             handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
        //         />
        //     )
        // },
        // {
        //     label: 'Distributors',
        //     children: (
        //         <OcrConfigurationFolder
        //             t={t}
        //             ocrFolderData={ocrFolderData}
        //             loading={loadingOcrFolder}
        //             page={page.ocr_folder}
        //             handlePageChange={handlePageChange}
        //             createFolderEnable={createFolderEnable}
        //             isFolderEnable={isFolderEnable}
        //             isSubFolderEnable={isSubFolderEnable}
        //             handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
        //             handlecreateSubFolderEnableFormClose={handlecreateSubFolderEnableFormClose}
        //             createSubFolderEnable={createSubFolderEnable}
        //             ocrSubFolderSubmit={ocrSubFolderSubmit}
        //             handlecreateFolderEnableFormClose={handlecreateFolderEnableFormClose}
        //             handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
        //             ocrProjectData={ocrProjectData}
        //             ocrPlaceData={ocrPlaceData}
        //             ocrFolderSubmit={ocrFolderSubmit}
        //             placeLoading={loadingOcrPlace}
        //             projectLoading={loadingOcrProject}
        //             handleFilterdataForOptions={handleFilterdataForOptions}
        //             ocrFolderDeletetingStarted={ocrConfigItemsDeletetingStarted}
        //             selecteIdForDelete={selecteIdForDelete}
        //             handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
        //         />
        //     )
        // },
        {
            label: 'Groups & Users',
            children: (
                <OcrConfigurationGroupsAndUsers
                    t={t}
                    assignProjectEnable={assignProjectEnable}
                    createGroupOrUserEnable={createGroupOrUserEnable}
                    subscriptionUserListLoading={subscriptionUserListLoading}
                    subscriptionUserData={subscriptionUserData}
                />
            )
        },
        {
            label:'Product Manager',
            children: (
                <OcrConfigurationDocuments
                    t={t}
                    ocrPlaceData={ocrPlaceData}
                    ocrProjectData={ocrProjectData}
                    loadingOcrPlace={loadingOcrPlace}
                    loadingOcrProject={loadingOcrProject}
                    ocrFetchLoadingForDocuments={ocrFetchLoadingForDocuments}
                    ocrDocumentsListing={ocrDocumentsListing}
                    handleFilterdataForOptions={handleFilterdataForOptions}
                    handleSubmitOrFilterForDocumentConfigTab={handleSubmitOrFilterForDocumentConfigTab}
                />
              )   
        },
        // {
        //     label: 'Activity Logs',
        //     children: (
        //         <OcrActivityLogs
        //             t={t}
        //             filterList={activityLogsFiltersList}
        //             list={activityLogsData}
        //             loading={activityLoading}
        //             handleSubmitFilter={handleActivityLogFilter}
        //             page={activityLogPage}
        //             handlePageChange={handleActivityLogPageChange} 
        //             handleExportClick={handleExportActivityLogs}
        //             isExportFile={isExportFile}
        //         />
        //     )   
        // },
        // {
        //     label: 'Product Manager',
        //     children:(
        //         <OcrConfigurationProcessDesign
        //             t={t}
        //             subscriptionUserListLoading={subscriptionUserListLoading}
        //             subscriptionUserData={subscriptionUserData}
        //             groupListLoading={groupListLoading}
        //             ocrGroupData={ocrGroupData}
        //             ocrOcrUsersDataLoading={ocrOcrUsersDataLoading}
        //             ocrUsersList={ocrUsersList}
        //             handleFilterForOcrUser={handleFilterForOcrUser}
        //             organizationId={organizationId}
        //             subscriptionId={subscriptionId}
        //             ocrProcessList={ocrProcessList}
        //             ocrOcrProcessDataLoading={ocrOcrProcessDataLoading}
        //             allOcrUsersData={allOcrUsersData}
        //             ocrUsersListLoading={ocrUsersListLoading}
        //             refetchOcrProcessData={refetchOcrProcessData}
        //             assignProjectEnable={assignProjectEnable}
        //         />
        //       )   
        // }
    ];
    return <>
        <CustomTabs
            activeIndex={activeIndex}
            tabs={tabList}
        />
    </>;
};

export default OcrConfiguration;
