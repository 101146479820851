import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { dateFormatterDDMMMYYYYHMS } from '../../utils';

export const ExportCSV = ({ csvData, fileName }) => {

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const timeToRestore = (startTime, endTime) => {
    let startDate = new Date(startTime);
    let endDate = new Date(endTime);
    let seconds = Math.floor((endDate - startDate) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    let hour = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hour * 60;
    seconds =
      seconds - days * 24 * 60 * 60 - hour * 60 * 60 - minutes * 60;
    let timeToRestore = hours + ':' + minutes + ':' + seconds;
    return timeToRestore;
  }

  const exportToCSV = (csvData, fileName) => {
    let downTimeCount = csvData && csvData.length && csvData.filter(item => item.incidentEndTime != null)
    let finalData = csvData && csvData?.length && csvData.map(item => {
      let description = item.description != null && item.description?.length > 30000 ? item.description.slice(0, 30000).concat('...')
       : item.description?.length > 0 ? item.description: ""
      let circuit = item && item.userInfo && item.userInfo.attributes ? JSON.parse(item.userInfo.attributes) : ""
      return {
        id : item.id,
        ticketId : item.ticketId,
        circuitId : circuit && circuit.CircuitID && circuit.CircuitID[0] ? circuit.CircuitID[0] : "",
        status : item.status,
        title: item.title,
        incidentStartTime  : dateFormatterDDMMMYYYYHMS(item.createdAt),
        incidentEndTime : item.incidentEndTime != null ? dateFormatterDDMMMYYYYHMS(item.incidentEndTime) : "",
        DowntimeInMinHourSec : item.incidentEndTime != null ? timeToRestore(item.createdAt, item.incidentEndTime) : "",
        priority : item.priority,
        ticketType : item.ticketType,
        closeDescription : item.closeDescription ? item.closeDescription : "",
        organisation : item.organizationName && item.organizationName != null ? item.organizationName : '',
        site: item.sites?.[0]?.name,
        incidentTypeGroup : item.incidentTypeGroup,
        incidentType : item.incidentType,
        incidentCause: item.incidentCause,
        escalationLevel: item.escalationLevel,
        vendor: item.vendor,
        assignee: item.assignee,
        vodacomProblem: item.vodacomProblem,
        baseStation : item.baseStation,
        location: item.location,
        description: description
      }
    })
    
    if(downTimeCount.length){
      let AllDowntimes = finalData.filter(item => {
        if(item.DowntimeInMinHourSec !==""){
          return item.DowntimeInMinHourSec
        }
      })
      let totalDownTime = AllDowntimes && AllDowntimes.length && AllDowntimes.reduce((acc, curr) => {
          let accVal = acc.split(":")
          let currVal = curr.DowntimeInMinHourSec.split(":")
          let hours = parseInt(accVal[0]) + parseInt(currVal[0]);
          let minutes = parseInt(accVal[1]) + parseInt(currVal[1]);
          let second = parseInt(accVal[2]) + parseInt(currVal[2]);
          return hours + ":" + minutes + ":" + second;
        }, "0:0:0:0")
      
      function convertH2M(timeInHour){
        var timeParts = timeInHour.split(":");
        let timeInMinutes = (Number(timeParts[0]) * 60) + (Number(timeParts[1])) + (Number(Math.floor(timeParts[2]/60)));
        return timeInMinutes
      }

      const finalDownTimeTotal = (totalDownTime) => {
        let timeInSeconds = (convertH2M(totalDownTime) * 60)
        let seconds = Number(timeInSeconds);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let hour = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hour * 60;
        seconds =
          seconds - days * 24 * 60 * 60 - hour * 60 * 60 - minutes * 60;
        let totalTimeToRestore = hours + ':' + minutes + ':' + seconds;
        return totalTimeToRestore;
      }
      let finalDownTime = finalDownTimeTotal(totalDownTime)
      finalData.push({ DowntimeInMinHourSec : finalDownTime })
    }
    const ws = XLSX.utils.json_to_sheet(finalData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={e => exportToCSV(csvData, fileName)}>
      Export Ticket
    </Button>
  );
};
