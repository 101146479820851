import React, {useState, useContext, useMemo, useEffect} from 'react';
import { get, debounce } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from 'react-router';

import {
    SubscriptionPaths
  } from '../../../../../../routes/routePaths';
import { getUrl, getIdParam, getParam } from '../../../../../../utils';
import AlerstFeedbackModal from '../../components/AlerstFeedbackModal';
import AlertRecordComment from '../../components/AlertRecordComment';
import AlertApproverScreen from '../../components/AlertApproverScreen';
import ShowAlertFeedBack from '../../components/ShowAlertFeedBack';
import { ShayonaCementsContext } from '../../../../../../contexts/ShayonaCementsProvider';
import ShayonaAlertsItem from './ShayonaAlertsItem';
import SearchBox from '../../../../../../components/SearchBox';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import { marginGenerator } from '../../../../../../theme/utils';


const ShayonaAlertsListings = ({
    t,
    filterList,
    handleClearFilter
}) => {

    const history = useHistory();
    const id = getParam()
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const {
        organizationId,
        shayonaAlertsList,
        shayonaAlerLoading,
        reloadShayonaApisOrPagination,
        paginationPage,
        shayonaApisFilterMethod,
        goToPage,
        setGoToPage
      } = useContext(ShayonaCementsContext);
    const [isAlertFeedBackDialogueEnable, setIsAlertFeedBackDialogueEnable] = useState(false);
    const [isRecordFeedBackDialogueEnable, setIsRecordFeedBackDialogueEnable] = useState(false);
    const [isApprovedDialogueEnable, setIsApprovedDialogueEnable] = useState(false);
    const [isShowCommentModalEnable, setIsShowCommentModalEnable] = useState(false);
    const [alertRowData, setAlertRowData] = useState('');

    const handleEnableAlertFeedBacksDialogue = (type,data) => {
      setAlertRowData(data);
      if(type === 'feedBack'){
          setIsAlertFeedBackDialogueEnable(true);
      } else if(type === 'record'){
          setIsRecordFeedBackDialogueEnable(true);
      } else if (type ==='approver') {
        setIsApprovedDialogueEnable(true);   
      } else if(type === 'show'){
        setIsShowCommentModalEnable(true);
      }
    };
  
    const handleCloseOfAlertFeedBackDialogue = () => {
      setAlertRowData('');
      setIsAlertFeedBackDialogueEnable(false);
      setIsRecordFeedBackDialogueEnable(false);
      setIsApprovedDialogueEnable(false);
      setIsShowCommentModalEnable(false);
      if(!isShowCommentModalEnable){
        const path = getUrl(SubscriptionPaths.ActiveSubscriptionDetails, id);
        history.push({ pathname: path });
      }
    };

    let columns = [
        {
            label: 'Truck number',
            key: "number"
        },
        {
            label: "Alerted on",
            key: "alerted"
        },
        {
            label: "Empty weight",
            key: "empty"
        },
        {
            label: "Allocated bags",
            key: "allocated"
        },
        {
            label: "Bags loaded",
            key: "loaded"
        },
        {
            label: "Gross weight",
            key: "gross"
        },
        {
            label: "Ideal weight",
            key: "Ideal"
        },
        {
            label: "Diff weight",
            key: "diffWeight"
        },
        {
            label: "Diff Bags",
            key: "diffBags"
        },
        {
            label: t('action.label'),
            key: "action"
        } 
    ];

    const queryHandleDebounced = useMemo(
        () => debounce(val =>  shayonaApisFilterMethod('alert',{truckNumber:val}), 300)
    , []);

    useEffect(() => {
        return () => {
            queryHandleDebounced.cancel();
        }
    }, []);
    
  return (
    <Grid container>
        <Grid container>
            <Grid item xs="3">
                <SearchBox 
                      placeholderText="Search Truck Number"
                      handleSearch={(val) =>queryHandleDebounced(val)}
                />
            </Grid>
            <Grid item xs="9">
                <ChartContainer
                    title={null}
                    filterList={filterList}
                    clearFilter={handleClearFilter}
                    customMargin={marginGenerator('mt-2')}
                /> 
            </Grid> 
        </Grid>
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((item, index) => (
                        <TableCell key={index}>
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                 {!shayonaAlerLoading ?
                      get(shayonaAlertsList, 'getAlertsList.data', []) && get(shayonaAlertsList, 'getAlertsList.data', []).length > 0 ?
                      get(shayonaAlertsList, 'getAlertsList.data', []).map((rowitem, rowIndex) => (
                                <ShayonaAlertsItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    handleEnableAlertFeedBacksDialogue={handleEnableAlertFeedBacksDialogue}
                                />
                            )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow>
                    :
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            {shayonaAlerLoading && <LinearProgress color="primary"/>}
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
        { !shayonaAlerLoading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(shayonaAlertsList, 'getAlertsList.count', null)/10)} page,  {get(shayonaAlertsList, 'getAlertsList.count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={get(shayonaAlertsList, 'getAlertsList.count', null)? Math.ceil(get(shayonaAlertsList, 'getAlertsList.count', null)/10) : 0} 
                                page={paginationPage} 
                                onChange={(evt, value) => {
                                    reloadShayonaApisOrPagination('alert',value);
                                }} 
                            />
                        </Grid>
                        <Grid item xs={1}>
                                    <span>Go to page </span>
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={goToPage}
                                type="number"
                                style={{ minWidth: 100 }}
                                color={'secondary'}
                                onChange={evt => {
                                    let maxRecord = get(shayonaAlertsList, 'getAlertsList.count', null) ? parseInt(Math.ceil(get(shayonaAlertsList, 'getAlertsList.count', null)/10)) : 0;
                                    if (parseInt(evt.target.value) > maxRecord) {
                                        return null;
                                    }
                                    setGoToPage(evt.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => reloadShayonaApisOrPagination('alert', parseInt(goToPage))}
                                variant={'outlined'}
                                color="primary"
                                disabled={!goToPage}
                                style={{ marginLeft: 10 }}
                            >Go</Button>
                        </Grid>
                    </Grid>
                </>
            }
    </TableContainer>
    <AlerstFeedbackModal
        t={t}
        open={isAlertFeedBackDialogueEnable}
        onClose={handleCloseOfAlertFeedBackDialogue}
        data={alertRowData}
    />
    <AlertRecordComment 
        t={t}
        open={isRecordFeedBackDialogueEnable}
        onClose={handleCloseOfAlertFeedBackDialogue}
        data={alertRowData}
    />
   {
       params.get('alertId') && (
            <AlertApproverScreen
            t={t}
            open={isApprovedDialogueEnable}
            onClose={handleCloseOfAlertFeedBackDialogue}
            data={alertRowData}
        />
       )
   } 
    <ShowAlertFeedBack
        t={t}
        open={isShowCommentModalEnable}
        onClose={handleCloseOfAlertFeedBackDialogue}
        data={alertRowData}
    />
</Grid>
  )
}

export default ShayonaAlertsListings