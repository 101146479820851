import React from 'react';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import { colors } from '../../../../theme/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  },
  container: {
    padding: '10px'
  }
}));

export default function FinencialYearTable({ data, column, loading }) {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            padding: 20,
            textAlign: 'center',
            display: 'inline-block'
          }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {column &&
                  column.map(column => (
                    <TableCell key={uuidv4()}>{column.name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data).map((item, i) => (
                <StyledTableRow key={uuidv4()}>
                  <TableCell>{item[0]}</TableCell>
                  {column.map((name, key) => {
                    return (
                      key > 0 && (
                        <TableCell>
                          {item[1][name.key] ? item[1][name.key] : 0}
                        </TableCell>
                      )
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
