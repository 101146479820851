import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const initFormState = {id: '', vpn_ip: '', vpn_type: 'L2TP-PSK', username: '', password: '', secret_key: '', ike_protocol: '', esp_protocol: "", interface: "", secret_key: '', vpn_route: ""};

function CreateVpns({
    t,
    open,
    handleCreateVpnsSubmit,
    handleClose,
    modalState,
    vpnsData
}) {

    const [vnfsFormState, setVnfsFormState] = useState({...initFormState});
    const classes = useStyles();
    const handleInputChange = (value, field) => {
        setVnfsFormState({...vnfsFormState, [field]: value});
    }

    React.useEffect(() => {
        if(modalState.clearData && open){
          setVnfsFormState({...initFormState});
          handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if(vpnsData && vpnsData.id){
            let loadData = {vpn_ip: vpnsData.vpn_ip, vpn_type: vpnsData.vpn_type, username: vpnsData.username, password: vpnsData.password, secret_key: vpnsData.secret_key, ike_protocol: vpnsData.ike_protocol, esp_protocol: vpnsData.esp_protocol, interface: vpnsData.interface, secret_key: vpnsData.secret_key, vpn_route: vpnsData.vpn_route, id: vpnsData.id};
            setVnfsFormState(loadData);
        }else{
            setVnfsFormState(initFormState);
        }
    }, [vpnsData]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="node-modal"
            aria-describedby="node-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle
                id="node-modal-title">{vpnsData ? t('update.label') : t('createNew.label')} {t('vpns.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.vpn_ip}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('vnpsIp.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'vpn_ip')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <OutlinedSelect
                                val={vnfsFormState.vpn_type}
                                label={t('vpnsType.label')}
                                handleChange={evt => handleInputChange(evt.target.value, 'vpn_type')}
                                selectStyle={{...paddingGenerator('p-8')}}
                                styleOverrides={{ width: '100%', marginRight: 0, minWidth:'180px' }}
                                options={["L2TP-PSK"]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.username}
                                type={'text'}
                                className={classes.input}
                                // label={t('username.label')}
                                placeholder={`${t('username.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'username')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.password}
                                type={'password'}
                                className={classes.input}
                                // label={t('password.label')}
                                placeholder={`${t('password.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'password')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.ike_protocol}
                                type={'text'}
                                // label={t('ikeProtocol.label')}
                                className={classes.input}
                                placeholder={`${t('ikeProtocol.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'ike_protocol')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.esp_protocol}
                                // label={t('espProtocol.label')}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('espProtocol.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'esp_protocol')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                // label={t('interface.label')}
                                value={vnfsFormState.interface}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('interface.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'interface')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.secret_key}
                                // label={t('secretKey.label')}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('secretKey.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'secret_key')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid style={{...marginGenerator('mb-10')}}>
                            <TextField
                                value={vnfsFormState.vpn_route}
                                // label={t('vpnsRoute.label')}
                                type={'text'}
                                className={classes.input}
                                placeholder={`${t('vpnsRoute.label')}*`}
                                style={{width: '100%'}}
                                color={'secondary'}
                                onChange={evt => handleInputChange(evt.target.value, 'vpn_route')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!vnfsFormState.vpn_ip || !vnfsFormState.username  || !vnfsFormState.password || !vnfsFormState.secret_key || !vnfsFormState.ike_protocol || !vnfsFormState.esp_protocol || !vnfsFormState.interface || !vnfsFormState.secret_key || !vnfsFormState.vpn_route){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleCreateVpnsSubmit(vnfsFormState);
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateVpns.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreatePlaceSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateVpns);
