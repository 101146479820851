import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_DEVICE_LIST_BY_TYPE} from "../../../queries";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateUpdateBwmgr({
                               t,
                               open,
                               handleCreateUpdateBwmgr,
                               handleClose,
                               connectivityInfoData,
                               modalState
                           }) {
    const classes = useStyles();
    const [deviceList, setDeviceList] = useState([]);
    // console.log('JSON.parse(opportunityDetail).product_attributes', opportunityDetail.product_attributes.bandwidth)
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    React.useEffect(() => {
        getDeviceList({
            variables: {
                type: 'bwmgr'
            }
        })
    }, []);
    const [getDeviceList] = useLazyQuery(GET_DEVICE_LIST_BY_TYPE, {
        fetchPolicy: 'no-cache',
        onCompleted: response => {
            console.log('response', response)
            setDeviceList(response.getDeviceListByType);
        }
    });
    return (
        <Formik
            initialValues={{
                orderid: "",
                location: "",
                customer_name: "",
                name: "",
                addr: connectivityInfoData && connectivityInfoData.ipAddress ? connectivityInfoData.ipAddress : "",
                bwin: "",
                bwout: ""
            }}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                console.log('values', JSON.stringify(values))
                return
                handleCreateUpdateBwmgr(values)
                resetForm()
                //alert(JSON.stringify(values));
            }}
            validationSchema={Yup.object().shape({
                location: Yup.string()
                    .required('Please Enter location'),
                customer_name: Yup.string()
                    .required('Please enter customer name'),
                name: Yup.string()
                    .required('Please enter name'),
                addr: Yup.string()
                    .required('Please enter addr number'),
                bwin: Yup.string()
                    .required('Please enter bwin number'),
                bwout: Yup.string()
                    .required('Please Select gateway'),
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                } = props;
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid style={{...paddingGenerator('pb-30')}}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-10')
                                }}>
                                <Grid container spacing={3} style={{
                                    ...marginGenerator('mt-8')
                                }}>
                                    <Grid item xs={6} style={marginGenerator('mt-16')}>
                                        <OutlinedSelect
                                            val={values.location}
                                            label={`${t('location.label')} *`}
                                            name={'location'}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            selectStyle={{...paddingGenerator('p-8')}}
                                            styleOverrides={{width: '100%', marginRight: 0}}
                                            options={deviceList && deviceList.length && deviceList.map(item => {
                                                return {id: item.engine_name, name: item.engine_name};
                                            })}
                                        />
                                        {(errors.location && touched.location) &&
                                        <FormHelperText
                                            style={{marginTop: '20px'}}>{errors.location}</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.customer_name}
                                            name={'customer_name'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.customer_name && touched.customer_name) && errors.customer_name}
                                            label={`Customer Name*`}
                                            placeholder={`Customer Name*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.name}
                                            name={'name'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.name && touched.name) && errors.name}
                                            label={`${t('name.label')} *`}
                                            placeholder={`${t('name.label')}*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.addr}
                                            name={'addr'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.addr && touched.addr) && errors.addr}
                                            label={`addr *`}
                                            placeholder={`Enter addr *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.bwin}
                                            name={'bwin'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.bwin && touched.bwin) && errors.bwin}
                                            label={`bwin *`}
                                            placeholder={`Enter bwin *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.bwout}
                                            name={'bwout'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.bwout && touched.bwout) && errors.bwout}
                                            label={`Bwout *`}
                                            placeholder={`Enter Bwout *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Grid container justify={'center'}>
                                <Button
                                    style={{marginRight: '5px'}}
                                    variant={'outlined'}
                                    onClick={() => {
                                        handleClose();
                                    }}>
                                    {t('cancel.label')}
                                </Button>
                                <ButtonComponent t={t} loading={modalState.btnLoading}/>
                            </Grid>
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
}
CreateUpdateBwmgr.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateUpdateBwmgr);
