import React, {useState, useEffect, useContext} from 'react';
import { get } from "lodash-es";
import {useMutation} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import commonStyles from '../../../../../../../theme/commonStyles';
import {
  REMOVE_GROUP_FROM_FOLDER
} from '../../../../../Ocr/mutations';
import {OcrContext} from '../../../../../../../contexts/OcrProvider';
import Message from '../../../../../../../components/Message';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
      width: "100%"
  }
});

const useStyles = makeStyles(theme => ({
  testCon: {marginRight: '6px', marginLeft: '6px'},
  chartTitle: {marginTop: '10px'},
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  },
  mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2)
  },
  inputBase: {
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 15,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick} 
      disabled={loading||disabledValue}
      >
          {loading && <CircularProgress size={14} />}
          {!loading && t('submit.label')}
      </Button>
  );
}

function DeleteButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button 
      color="primary"
      onClick={onClick}
      >
          {loading && <CircularProgress size={19} />}
          {!loading && <DeleteIcon color="secondary"/> }
      </Button>
  );
}

const OcrConfigurationGAndUAssignForProejctAndGroup = ({
  t,
  open,
  onClose,
  assignType,
  groupListLoading,
  ocrGroupData,
  loadingOcrProject,
  ocrProjectData,
  editOcrGroupLoading,
  handleSubmitOfAllOcrGroupAction,
  ocrAssignAndAddActions,
  assignFolderToGroupLoading,
  assignProjectToUserLoading,
  treeDataLoading,
  exitingGroupDetails,
  handleSubmitForOtehrOcrProcess,
  addProjectOfFolderToOCRProcessLoading,
  ocrFolderData,
  loadingOcrFolder,
  handleFilterdataForOptions,
  selectedFolderId
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [selectedId, setSelectedId] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selecteUserIdForDelete, setSelecteUserIdForDelete] = useState('');
  const { 
    getOcrGroupsOfFolderListLoading,
    ocrGroupsOfFolders,
    handleRefetchedOrFilter
  } = useContext(OcrContext);

  const [removeGroupFromFolder, { loading: removeGroupFromFolderLoading }] = useMutation(REMOVE_GROUP_FROM_FOLDER, {
    onError: error => {
        console.log('err', error);
        setSelecteUserIdForDelete('');
    },
    onCompleted: data => {
        setSelecteUserIdForDelete('');
        Message.success(data && data.removeGroupFromFolder.message);
        handleRefetchedOrFilter('groupsOfFolder', selectedFolderId);
    }
  });

  const handleRemoveGroupFromFolder = (data) => {
    setSelecteUserIdForDelete(data && data.group_id);
    removeGroupFromFolder({
        variables:{
            folder_id: selectedFolderId,
            group_id: data && data.group_id
        }
    });
  };

  const hadnleSelectElement = (id) => {
    if(id){
    setSelectedId(id);
    }
  };

  useEffect(() => {
    if(!open){
      setSelectedId('');
      setSelectedProject('');
    }
  }, [open]);

  return (
    <Dialog 
    fullWidth={true}
    maxWidth={assignType === t('assignFolderToGroupOcr.label') ? 'lg':'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}> 
              {assignType}
              <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={() => {
                      setSelectedId('');
                    }}
                  >
                    Unselct a Element
                </Button>
          </div>      
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
          <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
              <Grid item xs={12}>
                {assignType === t('addToGroup.label') &&( 
                  <TableContainer>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>
                                      <Typography variant={'h4'}>Availble Group's</Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {!groupListLoading ?
                          ocrGroupData.length > 0 ?
                          ocrGroupData.map((rowitem, rowIndex) => (
                              <TableRow key={rowitem.id}>
                                  <TableCell>
                                      <Grid className={commonClasses.column}>
                                          <Grid>
                                          <Typography 
                                            color={selectedId === rowitem.id ? "primary": ""}
                                            variant={'subtitle2'}
                                            onClick={()=> hadnleSelectElement(rowitem.id)}
                                            style={{ cursor: 'pointer' }}
                                          >
                                              {rowitem.name}
                                          </Typography>
                                          </Grid>
                                      </Grid>
                                  </TableCell>
                              </TableRow>
                                  )) :
                                  <TableRow>
                                      <TableCell colSpan={1}>
                                          <Typography variant={'subtitle2'}>Record not found!</Typography>
                                      </TableCell>
                                  </TableRow>
                              :
                              <TableRow>
                                  <TableCell colSpan={1}>
                                      {groupListLoading && <LinearProgress color="primary"/>}
                                  </TableCell>
                              </TableRow>
                          }
                      </TableBody>
                  </Table>
              </TableContainer>
                  
                )}

                {assignType === t('assignFolderToGroupOcr.label') &&( 
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                      <TableContainer>
                                  <Table>
                                      <TableHead>
                                          <TableRow>
                                              <TableCell>
                                                      <Typography variant={'h4'}>Existing Group's</Typography>
                                              </TableCell>
                                              <TableCell>
                                                      <Typography variant={'h4'}>Acess Types</Typography>
                                              </TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {!getOcrGroupsOfFolderListLoading ?
                                          ocrGroupsOfFolders && ocrGroupsOfFolders.data.length > 0 ?
                                          ocrGroupsOfFolders.data.map((rowitem, rowIndex) => (
                                              <TableRow key={rowitem.id}>
                                                  <TableCell>
                                                      <Grid className={commonClasses.column}>
                                                          <Grid>
                                                              <Typography variant={'subtitle1'}>
                                                              {rowitem.group_name}
                                                              </Typography>
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                                  <TableCell>
                                                      <Grid className={commonClasses.column}>
                                                          <Grid>
                                                              <Typography variant={'subtitle1'}>
                                                              { rowitem.permission === 'view'? 'Read Only' : 'Edit'}
                                                              </Typography>
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                                  <TableCell>
                                                      <Grid className={commonClasses.column}>
                                                          <Grid>
                                                            <DeleteButtonComponent
                                                                t={t}
                                                                loading={selecteUserIdForDelete === rowitem.group_id ? removeGroupFromFolderLoading : false}
                                                                onClick={() =>handleRemoveGroupFromFolder(rowitem)}
                                                            />
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                              </TableRow>
                                                  )) :
                                                  <TableRow>
                                                      <TableCell colSpan={1}>
                                                          <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                      </TableCell>
                                                  </TableRow>
                                              :
                                              <TableRow>
                                                  <TableCell colSpan={1}>
                                                      {getOcrGroupsOfFolderListLoading && <LinearProgress color="primary"/>}
                                                  </TableCell>
                                              </TableRow>
                                          }
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                      </Grid>
                      <Grid item xs={6}>
                      <TableContainer>
                                  <Table>
                                      <TableHead>
                                          <TableRow>
                                              <TableCell>
                                                      <Typography variant={'h4'}>Available Group's</Typography>
                                              </TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {!groupListLoading ?
                                          ocrGroupData.length > 0 ?
                                          ocrGroupData.map((rowitem, rowIndex) => (
                                              <TableRow key={rowitem.id}>
                                                  <TableCell>
                                                      <Grid className={commonClasses.column}>
                                                          <Grid>
                                                            <Typography 
                                                              color={selectedId === rowitem.id ? "primary": ""}
                                                              variant={'subtitle1'}
                                                              onClick={()=>{
                                                                if(exitingGroupDetails.length>0){
                                                                  const filterData =  exitingGroupDetails.filter(el => el.group_id  ===  rowitem.id);
                                                                  
                                                                  if(filterData.length> 0){
                                                                    Message.error('This Group allready exists');
                                                                  } else {
                                                                    hadnleSelectElement(rowitem.id);
                                                                  }
                                                                }else {
                                                                  hadnleSelectElement(rowitem.id);
                                                                }
                                                              }}
                                                              style={{ cursor: 'pointer' }}
                                                            >
                                                              {rowitem.name}
                                                            </Typography>
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                              </TableRow>
                                                  )) :
                                                  <TableRow>
                                                      <TableCell colSpan={1}>
                                                          <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                      </TableCell>
                                                  </TableRow>
                                              :
                                              <TableRow>
                                                  <TableCell colSpan={1}>
                                                      {groupListLoading && <LinearProgress color="primary"/>}
                                                  </TableCell>
                                              </TableRow>
                                          }
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                      </Grid>
                    </Grid>
                )}

                {(assignType === t('assignToProject.label') || assignType === t('assignProjectToProcess.label'))  &&( 
                  <TableContainer>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>
                                      <Typography variant={'h4'}>Availble Project's</Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {!loadingOcrProject ?
                          ocrProjectData.data.length > 0 ?
                          ocrProjectData.data.map((rowitem, rowIndex) => (
                              <TableRow key={rowitem.id}>
                                  <TableCell>
                                      <Grid className={commonClasses.column}>
                                          <Grid>
                                             <Typography 
                                                  color={selectedId === rowitem.id ? "primary": ""}
                                                  variant="subtitle1"
                                                  onClick={()=> hadnleSelectElement(rowitem.id)}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  {rowitem.name}
                                              </Typography>
                                          </Grid>
                                      </Grid>
                                  </TableCell>
                              </TableRow>
                                  )) :
                                  <TableRow>
                                      <TableCell colSpan={1}>
                                          <Typography variant={'subtitle2'}>Record not found!</Typography>
                                      </TableCell>
                                  </TableRow>
                              :
                              <TableRow>
                                  <TableCell colSpan={1}>
                                      {loadingOcrProject && <LinearProgress color="primary"/>}
                                  </TableCell>
                              </TableRow>
                          }
                      </TableBody>
                  </Table>
              </TableContainer>
              )}

              {assignType === t('assignFolderToProcess.label')  &&( 
                 <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Autocomplete
                            id={`project-select`}
                            options={!loadingOcrProject && ocrProjectData? ocrProjectData.data :[]}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                if(newValue){
                                  handleFilterdataForOptions({place: newValue.place,project:newValue.id}, 'folder');
                                  setSelectedProject(newValue.id);
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                  handleFilterdataForOptions({place: "",project:""}, 'folder');
                            }}
                            style={{ border: '1px solid #ebebeb', width: '100%', marginTop: 25 }}
                            renderInput={(params) => <TextField {...params} label="Select Project *" variant="outlined" />}
                        />
                    </Grid> 
                    <Grid item xs={7}>
                      <TableContainer>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>
                                                  <Typography variant={'h4'}>Availble Folder's</Typography>
                                          </TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {!loadingOcrFolder ?
                                      ocrFolderData.data.length > 0 ?
                                      ocrFolderData.data.map((rowitem, rowIndex) => (
                                          <TableRow key={rowitem.id}>
                                              <TableCell>
                                                  <Grid className={commonClasses.column}>
                                                      <Grid>
                                                        <Typography 
                                                              color={selectedId === rowitem.id ? "primary": ""}
                                                              variant="subtitle1"
                                                              onClick={()=>{
                                                                if(!selectedProject) return  Message.error('Please select project first');   
                                                                hadnleSelectElement(rowitem.id);
                                                              }}
                                                              style={{ cursor: 'pointer' }}
                                                        >
                                                              {rowitem.name}
                                                        </Typography>
                                                      </Grid>
                                                  </Grid>
                                              </TableCell>
                                          </TableRow>
                                              )) :
                                              <TableRow>
                                                  <TableCell colSpan={1}>
                                                      <Typography variant={'subtitle2'}>Record not found!</Typography>
                                                  </TableCell>
                                              </TableRow>
                                          :
                                          <TableRow>
                                              <TableCell colSpan={1}>
                                                  {loadingOcrFolder && <LinearProgress color="primary"/>}
                                              </TableCell>
                                          </TableRow>
                                      }
                                  </TableBody>
                              </Table>
                      </TableContainer>
                    </Grid>  
                 </Grid>  
              )}
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={onClose}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent 
                  t={t}
                  loading={editOcrGroupLoading || assignFolderToGroupLoading || assignProjectToUserLoading || addProjectOfFolderToOCRProcessLoading}
                  onClick={()=> {
                      if(assignType === t('addToGroup.label')){
                        handleSubmitOfAllOcrGroupAction('edit', {id: selectedId});
                      }else if(assignType === t('assignFolderToUserOcr.label')){
                        ocrAssignAndAddActions('assignFolderToUserOcr', {id: selectedId});
                      } else if(assignType ===  t('assignFolderToGroupOcr.label')){
                        ocrAssignAndAddActions(t('assignFolderToGroupOcr.label'), {id: selectedId});
                      } else if(assignType === t('assignToProject.label')){
                        handleSubmitOfAllOcrGroupAction(t('assignToProject.label'), {id: selectedId})
                      } else if(assignType === t('assignProjectToProcess.label')){
                        handleSubmitForOtehrOcrProcess(t('assignProjectToProcess.label'), {id: selectedId})
                      } else if(assignType === t('assignFolderToProcess.label')){
                        handleSubmitForOtehrOcrProcess(t('assignFolderToProcess.label'), {projectId:selectedProject,folderId: selectedId})
                      }
                    }
                  }
                />
            </Grid>          
        </DialogActions>
    </Dialog>
  )
}

export default OcrConfigurationGAndUAssignForProejctAndGroup