import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../../../components/Message';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, FormGroup, IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: '1px solid black',
        outline: '1px solid',
        padding: '0px !important',
    },
    blackBorderTitle:{
        margin: '18px 9px',
    },
    paper: {
        padding: '15px',
        marginBottom: '50px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const urInitFields = {
    id: "",
    title: "",
    firstName: "",
    surname: "",
    MaidenSurname: "",
    middleName: "",
    dateOfBirth: null,
    gender: "",
    maritalStatus: "",
    noOfChild: "",
    dateEngaged: null,
    designation: "",
    grade: "",
    pay: "",
    userDepartment: "",
    userSection: "",
    costCode: "",
    employeeType: "",
    leaveEntitlement: "",
    allBenefits: [],
    allowances: [],
    dateEngagedOnRL: null,
    generalComment: "",
    gym: "",
    recClub: "",
    PMAregistraion: "",
    pensionFormsRegistraion: "",
    financialAccountant: "",
    financialAccountantDate: null,
    HRAdvisor:"",
    HRAdvisorDate: null,
    HROfficer: "",
    HROfficerDate: null,
};

const allowances = [
    "Housing",
    "Electricity",
    "Telephone",
    "Inducement",
    "Other"
]

const allBenefits = [
    "Gratuity",
    "Severance",
    "Pension"
];

const genderList = [
    "Male",
    "Female"
];

const EmployeeList = [
    "Temporary Employee",
    "Permanent Employee"
];

const MaritalStatus = [
    "Married",
    "UnMarried",
    "Divorced"
]

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", { type: contentType });
}
function convertBase64ToUrl(imgStr) {
    imgStr = imgStr.replace("data:image/png;base64,", "");
    let enc = window.atob(imgStr);
    let image = new File([enc], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg"
    });
    let file = b64toBlob(imgStr, "image/jpeg");
    let imgURL = URL.createObjectURL(file);
    return imgURL;
}

function EngagementAdviceModal({
    t,
    open,
    data,
    users,
    departments,
    userdata,
    handleClose,
    modalState,
    handleCreateRequestSubmit,
    handleOpenSignPanModal,
    signatures
}) {

    const classes = useStyles();
    const [urFormState, setURFormState] = useState({ ...urInitFields });
    const [departmentSections, setDepartmentSections] = useState([]);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setURFormState({ ...urInitFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data._id) {
            const id = data._id;
            const userDepartment = data.userDepartment && data.userDepartment._id
            const userSection = data.userSection && data.userSection._id
            setURFormState({...data, id, userDepartment, userSection });
            if(data.userSection){
                setDepartmentSections([data.userSection]);
            }
        }
    }, [data]);

    const handleChange = (value, name) => {
        if (name === "allBenefits") {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.allBenefits.push(value.target.value);
                } else {
                    let index = newState.allBenefits.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.allBenefits.splice(index, 1);
                    }
                }
                return newState;
            });
        }else if(name === 'allowances'){
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.allowances.push(value.target.value);
                } else {
                    let index = newState.allowances.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.allowances.splice(index, 1);
                    }
                }
                return newState;
            });
        } else if(name === 'recClub' || name === 'PMAregistraion' || name === 'gym' || name === 'pensionFormsRegistraion') {
            setURFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState[name] = value.target.value;
                } else {
                    newState[name] = '';
                }
                return newState;
            });
        } else {
            setURFormState({ ...urFormState, [name]: value });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ textAlign: "center"}} >ENGAGEMENT ADVICE FORM</Typography>
                            <hr />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h4" >Personal Details</Typography>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Title</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.title}
                                onChange={(evt) => handleChange(evt.target.value, "title")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>First Name</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.firstName}
                                onChange={(evt) => handleChange(evt.target.value, "firstName")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Surname</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.surname}
                                onChange={(evt) => handleChange(evt.target.value, "surname")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Maiden Surname (if applicable)</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.MaidenSurname}
                                onChange={(evt) => handleChange(evt.target.value, "MaidenSurname")}
                            />
                        </Grid>
                        
                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Middle Name</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.middleName}
                                onChange={(evt) => handleChange(evt.target.value, "middleName")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Date of Birth</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.dateOfBirth}
                                handleChange={newDate => handleChange(newDate, "dateOfBirth")}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Gender</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Grid>
                                <OutlinedSelect
                                    options={ genderList ? genderList : []}
                                    // label="Gender"
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    val={urFormState.gender}
                                    handleChange={(evt) => {
                                        handleChange(evt.target.value, "gender");
                                    }}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Marital Status</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Grid>
                                <OutlinedSelect
                                    options={MaritalStatus ? MaritalStatus : []}
                                    // label="Marital Status"
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    val={urFormState.maritalStatus}
                                    handleChange={(evt) => {
                                        handleChange(evt.target.value, "maritalStatus");
                                    }}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Number of Children</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.noOfChild}
                                onChange={(evt) => handleChange(evt.target.value, "noOfChild")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Job Details</Typography>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Date Engaged</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.dateEngaged}
                                handleChange={newDate => handleChange(newDate, "dateEngaged")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Designation</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.designation}
                                onChange={(evt) => handleChange(evt.target.value, "designation")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Grade</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.grade}
                                onChange={(evt) => handleChange(evt.target.value, "grade")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Pay</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.pay}
                                onChange={(evt) => handleChange(evt.target.value, "pay")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Department</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Grid>
                                <OutlinedSelect
                                    options={(departments && departments.length) ? departments : []}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    val={urFormState.userDepartment}
                                    handleChange={(evt) => {
                                        const item = departments.find(itm => evt.target.value === itm.id);
                                        setDepartmentSections(item ? item.sections : []);
                                        handleChange(evt.target.value, "userDepartment");
                                    }}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Section</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Grid>
                                <OutlinedSelect
                                    options={(departmentSections && departmentSections.length) ? departmentSections : []}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                    val={urFormState.userSection}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    handleChange={(evt) => {
                                        handleChange(evt.target.value, "userSection");
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Cost Code</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.costCode}
                                onChange={(evt) => handleChange(evt.target.value, "costCode")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Employee Type</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Grid>
                                <OutlinedSelect
                                    options={ EmployeeList ? EmployeeList : []}
                                    selectStyle={{ ...paddingGenerator('p-4') }}
                                    val={urFormState.employeeType}
                                    handleChange={(evt) => {
                                        handleChange(evt.target.value, "employeeType");
                                    }}
                                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Leave Entitlement</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.leaveEntitlement}
                                onChange={(evt) => handleChange(evt.target.value, "leaveEntitlement")}
                            />
                        </Grid>

                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Benefit (applicable)</h5>
                        </Grid>
                        
                        <Grid className={classes.blackBorder} item xs={8}>
                            <FormGroup row>
                                {allBenefits.map((itm) => (
                                    <Grid item xs={4} key={itm}>
                                        <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value={itm}
                                            checked={urFormState.allBenefits ? urFormState.allBenefits.includes(itm) : false}
                                            control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            onChange={(evt) => {
                                                handleChange(evt, "allBenefits");
                                            }}
                                            label={itm}
                                        />
                                    </Grid>
                                ))}
                            </FormGroup>
                        </Grid>
                        
                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Allowances (applicable)</h5>
                            {/* <Typography variant="h4" >Allowances (applicable)</Typography> */}
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <FormGroup row>
                                {allowances.map((itm) => (
                                    <Grid item xs={4} key={itm}>
                                        <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value={itm}
                                            checked={urFormState.allowances ? urFormState.allowances.includes(itm) : false}
                                            control={<Checkbox style={{ margin: '1px', padding: '1px', border: '0px'  }} />}
                                            labelPlacement="start"
                                            onChange={(evt) => {
                                                handleChange(evt, "allowances");
                                            }}
                                            label={itm}
                                        />
                                    </Grid>
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        
                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Other</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <FormGroup row>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value={'recClub'}
                                        checked={urFormState.recClub  === 'recClub' ? true : false}
                                        control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        labelPlacement="start"
                                        onChange={(evt) => {
                                            handleChange(evt, "recClub");
                                        }}
                                        label={'Rec Club '}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value={'gym'}
                                        checked={urFormState.gym  === 'gym' ? true : false}
                                        control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        labelPlacement="start"
                                        onChange={(evt) => {
                                            handleChange(evt, "gym");
                                        }}
                                        label={'Gym'}
                                    />
                                </Grid>
                            </FormGroup>
                        </Grid>
                        
                        <Grid className={classes.blackBorder} item xs={4}>
                            <h5 className={classes.blackBorderTitle}>Document Checklist</h5>
                        </Grid>
                        <Grid className={classes.blackBorder} item xs={8}>
                            <FormGroup row>
                                <Grid item xs={5}>
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value={'pensionFormsRegistraion'}
                                        checked={urFormState.pensionFormsRegistraion  === 'pensionFormsRegistraion' ? true : false}
                                        control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        labelPlacement="start"
                                        onChange={(evt) => {
                                            handleChange(evt, "pensionFormsRegistraion");
                                        }}
                                        label={'Pension Forms Registration'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControlLabel
                                        style={{ ...marginGenerator('mb-8') }}
                                        value={'PMAregistraion'}
                                        checked={urFormState.PMAregistraion  === 'PMAregistraion' ? true : false}
                                        control={<Checkbox style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                        labelPlacement="start"
                                        onChange={(evt) => {
                                            handleChange(evt, "PMAregistraion");
                                        }}
                                        label={'Pula Medical Aid Registration'}
                                    />
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        
                        <Grid item xs={3}>
                            <h5 className={classes.blackBorderTitle}>Date Engaged on RL</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.dateEngagedOnRL}
                                handleChange={newDate => handleChange(newDate, "dateEngagedOnRL")}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <h5 className={classes.blackBorderTitle}>General comments</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="textarea"
                                variant="standard"
                                fullWidth={true}
                                multiline
                                onChange={(evt) => handleChange(evt.target.value, "generalComment")}
                                value={urFormState.generalComment}
                            />
                        </Grid>

                        
                        <Grid item xs={3}>
                            <h5 className={classes.blackBorderTitle}>Responsible HR Officer</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.HROfficer}
                                onChange={(evt) => handleChange(evt.target.value, "HROfficer")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.HROfficerDate}
                                handleChange={newDate => handleChange(newDate, "HROfficerDate")}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <h5 className={classes.blackBorderTitle}>Approved: HR Advisor</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.HRAdvisor}
                                onChange={(evt) => handleChange(evt.target.value, "HRAdvisor")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.HRAdvisorDate}
                                handleChange={newDate => handleChange(newDate, "HRAdvisorDate")}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <h5 className={classes.blackBorderTitle}>Received: Financial Accountant</h5>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                type={'text'}
                                style={{ width: '100%' }}
                                className={classes.input}
                                color={'secondary'}
                                value={urFormState.financialAccountant}
                                onChange={(evt) => handleChange(evt.target.value, "financialAccountant")}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <h5 className={classes.blackBorderTitle}>Date</h5>
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeSelector
                                inputStyle={{ minWidth: 250 }}
                                dateFormat={"yyyy-MM-dd"}
                                value={urFormState.financialAccountantDate}
                                handleChange={newDate => handleChange(newDate, "financialAccountantDate")}
                                styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            />
                        </Grid>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setURFormState({ ...urInitFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!urFormState.firstName || !urFormState.userDepartment || !urFormState.userSection){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateRequestSubmit(urFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

EngagementAdviceModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(EngagementAdviceModal);