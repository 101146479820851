import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PmoDashboard from '../containers/Internal/PmoDashboard';
import BiDashboard from '../containers/Internal/BiDashboard';
import WorkflowManagement from '../containers/Internal/WorkflowManagement';
import {
  CreateAccountPaths,
  InternalPaths,
  Paths,
  TicketingSystem,
  ServiceManager,
  InternalUser
} from './routePaths';
/**
 * Import Containers here
 */
import Dashboard from '../containers/Dashboard';
import ServiceTicket from '../containers/ServiceTickets';
import Subscriptions from '../containers/Subscriptions';
import NewSubscription from '../containers/NewSubscription';
import Support from '../containers/Support';
import CreateAccount from '../containers/OnBoarding';
import Login from '../containers/OnBoarding/Login';
import ForgotPassword from '../containers/OnBoarding/ForgotPassword';
import ResetPassword from '../containers/OnBoarding/ResetPassword';
import ChangePassword from '../containers/OnBoarding/ChangePassword';
import ActivityLogs from '../containers/OnBoarding/ActivityLogs';
import LoginInProgress from '../containers/OnBoarding/LoginInProgress';
import PrivateRoute from './PrivateRoute';
import InternalDashboard from '../containers/Internal/Dashboard';
import Organisations from '../containers/Internal/Organisations';
import CreateNewUser from '../containers/Internal/Users/CreateNewUser';
import UsersList from '../containers/Internal/Users/UsersList';
import CustomerList from '../containers/Internal/Users/CustomerList';
import Countries from '../containers/Internal/Countries';
import Profile from '../containers/Profile';
import Settings from '../containers/Settings';
import TicketLists from '../containers/TicketingSystem';
import TicketDetails from '../containers/TicketingSystem/TicketDetails';
import NewServiceTicket from '../containers/TicketingSystem/NewServiceTicket';
import TicketDashboard from '../containers/TicketingSystem/Dashboard';
import UpdateTicket from '../containers/TicketingSystem/UpdateTicket';
import KnowladgeBase from '../containers/TicketingSystem/KnowladgeBase';
import LineCrosingImage from '../containers/LineCrosingImage';
import MultiLineCrosingImage from '../containers/MultiLineCrosingImage';
import VNFMarketplacePanel from '../containers/Subscriptions/components/VNFMarketplacePanel';
import VNFMarketplaceDetailPanel from '../containers/Subscriptions/components/VNFMarketplaceDetailPanel';
import VnfMarketPlace from '../containers/Internal/VnfMarketPlace';
import TicketSubscription from '../containers/TicketingSystem/Dashboard/Subscriptions';
import KnowladgeBaseDetail from '../containers/TicketingSystem/KnowladgeBase/KnowledgeBaseDetail';
import Rating from '../containers/TicketingSystem/UserRating';
import ServiceManagerDashBoard from '../containers/ServiceManager/DashBoard';
import Users from '../containers/ServiceManager/Users';
import Customers from '../containers/ServiceManager/Customers';
import SubscriptionList from '../containers/ServiceManager/Subscription';
import ConfigureSites from '../containers/ServiceManager/ConfigureSites';
import Vendors from '../containers/ServiceManager/Vendors';
import Analytics from '../containers/TicketingSystem/Analytics';
import CircuitHistory from '../containers/TicketingSystem/CircuitHistory';
import ChangeManagement from '../containers/TicketingSystem/ChangeManagement';
import NewChangeRequest from '../containers/TicketingSystem/ChangeManagement/newChangeRequest';
import UploadFile from '../containers/TicketingSystem/UploadFile';
import ServicesAndProducts from '../containers/Internal/ServicesAndProducts';
import TicketCustomers from '../containers/TicketingSystem/Customers';
import SubscriptionSite from '../containers/TicketingSystem/SubscriptionSites';
import CustomerDetails from '../containers/TicketingSystem/CustomerDetails';
import IncidentTypeList from '../containers/ServiceManager/IncidentType';
import IncidentTypeGroupList from '../containers/ServiceManager/IncidentTypeGroup';
import AgentList from '../containers/ServiceManager/Agents';
import NGList from '../containers/ServiceManager/NGList';
import EdgeAIROIPanel from '../containers/EdgeAIROIPanel';
import MultipleROI from '../containers/EdgeAIROIPanel/MultipleROI';
import InternalTicket from '../containers/TicketingSystem/InternalTicket';
import DirectionalLineCross from '../containers/DirectionalLineCross';
import VolumeLineCrossImage from '../containers/VolumeLineCrossImage';
import OtherServices from '../containers/OtherServices';
import CPDashboard from '../containers/ControlPlatform/Dashboard';
import Events from '../containers/ControlPlatform/Events';
import MttrAnalytics from '../containers/TicketingSystem/MttrAnalytics';
import ShayonaAlertApproverFlow from '../containers/Subscriptions/components/ShayonaCementsProduct/ShayonaAlerts/ShayonaAlertsListings/ShayonaAlertApproverFlow';
import ChangeDetail from '../containers/TicketingSystem/ChangeManagement/ChangeDetail';
import CyberSecurityForm from '../containers/TicketingSystem/ChangeManagement/CyberSecurity/CyberSecurityForm';
import CyberSecurity from '../containers/TicketingSystem/ChangeManagement/CyberSecurity';
import CyberSecurityDetail from '../containers/TicketingSystem/ChangeManagement/CyberSecurity/CyberSecurityDetail';
import FabricRoutes from '../containers/FabricServices/FabricRoutes';
import GetPolygons from '../containers/Subscriptions/Services/EdgeAIService/GetPolygons';

const Routes = () => (
  <Switch>
    <Redirect
      exact
      from={Paths.CreateAccount}
      to={CreateAccountPaths.CreateOrg}
    />
    <Route path={Paths.CreateAccount} component={CreateAccount} />
    <Route exact={true} path={'/'} component={Login} />
    <Route
      exact={true}
      path={'/shayona/:id'}
      component={ShayonaAlertApproverFlow}
    />
    <Route
      exact={true}
      path={Paths.ForgotPassword}
      component={ForgotPassword}
    />
    <Route path={Paths.ResetPassword} component={ResetPassword} />
    <PrivateRoute
      exact={true}
      path={ServiceManager.Dashboard}
      component={ServiceManagerDashBoard}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.Customers}
      component={Customers}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.Vendors}
      component={Vendors}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.IncidentType}
      component={IncidentTypeList}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.IncidentTypeGroup}
      component={IncidentTypeGroupList}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.AdditionalAgent}
      component={AgentList}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.NGList}
      component={NGList}
    />
    <PrivateRoute exact={true} path={ServiceManager.Users} component={Users} />
    <PrivateRoute
      exact={true}
      path={ServiceManager.Subscription}
      component={SubscriptionList}
    />
    <PrivateRoute
      exact={true}
      path={ServiceManager.ConfigureSites}
      component={ConfigureSites}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Details}
      component={TicketDetails}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Subscription}
      component={TicketSubscription}
    />
    <PrivateRoute path={TicketingSystem.Update} component={UpdateTicket} />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.NewServiceTicket}
      component={NewServiceTicket}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.NewServiceTicketId}
      component={NewServiceTicket}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.InternalTicket}
      component={InternalTicket}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Dashboard}
      component={TicketDashboard}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.KnowladgeBase}
      component={KnowladgeBase}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Customers}
      component={TicketCustomers}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.SubscriptionSite}
      component={SubscriptionSite}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CustomersDetails}
      component={CustomerDetails}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Analytics}
      component={Analytics}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.History}
      component={CircuitHistory}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CM}
      component={ChangeManagement}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CyberSecurityForm}
      component={CyberSecurityForm}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CyberSecurity}
      component={CyberSecurity}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CSDetails}
      component={CyberSecurityDetail}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.CMDetails}
      component={ChangeDetail}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.MttrAnalytics}
      component={MttrAnalytics}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.ChangeRequest}
      component={NewChangeRequest}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.Upload}
      component={UploadFile}
    />
    <PrivateRoute
      exact={true}
      path={TicketingSystem.KnowladgeBaseDetail}
      component={KnowladgeBaseDetail}
    />

    {/* <PrivateRoute
            exact={true}
            path="/fabricService"
            component={FabricRoutes}
        /> */}

    <PrivateRoute path={Paths.FabricServices} component={FabricRoutes} />

    {/* <PrivateRoute
            exact={true}
            path="/fabricService"
            component={FabricServices}
        /> */}
    {/* <PrivateRoute
            exact={true}
            path="/Services"
            component={CreatePortDashoard}
        />
      
        <PrivateRoute
            exact={true}
            path="/Services/createPort"
            component={FormComponent}
        />
        <PrivateRoute
            exact={true}
            path="/FCR/createFCR" 
            component={FormComponent_fcr}
        />
        <PrivateRoute
            exact={true}
            path="/FXC/addConnections"
            component={PortComponent}
        />
        <PrivateRoute
            exact={true}
            path="/Service/marketplace"
            component={MarketPlace}
        />
        <PrivateRoute
            exact={true}
            path="/Service/insight"
            component={iInsight
            }
        />
        <PrivateRoute
            exact={true}
            path="/FXC"
            component={Connection}
        />
        <PrivateRoute
            exact={true}
            path="/FCR"
            component={CreateFCRDashoard}
        /> */}
    <Route exact={true} path={TicketingSystem.Rating} component={Rating} />
    <PrivateRoute exact={true} path={Paths.Dashboard} component={Dashboard} />
    <PrivateRoute path={Paths.ServiceTickets} component={ServiceTicket} />
    <PrivateRoute path={Paths.Subscriptions} component={Subscriptions} />
    <PrivateRoute path={Paths.NewSubscription} component={NewSubscription} />
    <PrivateRoute path={Paths.Support} component={Support} />
    <PrivateRoute path={Paths.Profile} component={Profile} />
    <PrivateRoute path={Paths.ChangePassword} component={ChangePassword} />
    <PrivateRoute path={Paths.ActivityLogs} component={ActivityLogs} />
    <PrivateRoute path={Paths.Settings} component={Settings} />
    <PrivateRoute path={Paths.LoginInProgress} component={LoginInProgress} />
    <PrivateRoute path={Paths.LineCrosingImage} component={LineCrosingImage} />
    <PrivateRoute
      path={Paths.MultiLineCrosingImage}
      component={MultiLineCrosingImage}
    />
    <PrivateRoute path={Paths.VnfMarketplace} component={VNFMarketplacePanel} />
    <PrivateRoute
      path={Paths.VnfMarketplaceDetails}
      component={VNFMarketplaceDetailPanel}
    />
    <PrivateRoute path={Paths.GetRoiAxis} component={EdgeAIROIPanel} />
    <PrivateRoute path={Paths.GetMultiRoiAxis} component={MultipleROI} />
    <PrivateRoute
      path={Paths.DirectionalLineCross}
      component={DirectionalLineCross}
    />
    <PrivateRoute
      path={Paths.VolumeLineCrossImage}
      component={VolumeLineCrossImage}
    />
    {/*Internal Paths*/}
    <PrivateRoute
      internal={true}
      path={InternalPaths.Dashboard}
      component={InternalDashboard}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.organisations}
      component={Organisations}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.createNewUser}
      component={CreateNewUser}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.users}
      component={UsersList}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.customers}
      component={CustomerList}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.countries}
      component={Countries}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.vnfMarketPlace}
      component={VnfMarketPlace}
    />
    <PrivateRoute
      internal={true}
      path={InternalPaths.servicesAndProducts}
      component={ServicesAndProducts}
    />
    {/* Ticketing Paths */}
    <PrivateRoute
      exact={true}
      path={TicketingSystem.TicketPortal}
      component={TicketLists}
    />
    {/* PMO Dashboard Paths */}
    <PrivateRoute
      exact={true}
      path={InternalPaths.pmoDashboard}
      component={PmoDashboard}
    />
    {/* BI Dashboard Paths */}
    <PrivateRoute
      exact={true}
      path={InternalPaths.biDashboard}
      component={BiDashboard}
    />
    {/* Workflow Management Paths */}
    <PrivateRoute
      exact={true}
      path={InternalPaths.workflowManagement}
      component={WorkflowManagement}
    />
    {/* Other Services Paths */}
    <PrivateRoute
      exact={true}
      path={Paths.OtherServices}
      component={OtherServices}
    />
    {/* Internal Control Panel Users */}
    <PrivateRoute
      exact={true}
      path={InternalUser.Dashboard}
      component={CPDashboard}
    />
    <PrivateRoute exact={true} path={InternalUser.Events} component={Events} />
    <PrivateRoute
      path={Paths.GetPolygons}
      component={GetPolygons}
    />
  </Switch>
);
export default Routes;
