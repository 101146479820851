import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { dateFormatterLLL, convertUTCDateToLocalDate } from '../../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import CameraStatusPanel from '../CameraStatusPanel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" color={ props.value > 90 ? 'secondary' : 'primary' } {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textPrimary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const NodeManagementItem = ({
  t,
  data,
  handleMenuItemClick,
  menuItems,
  allowConfigure,
  extraDetails,
  organization,
  handleViewDetails
}) => {
    
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    // const [mappedCamIds, setMappedCamIds] = useState('');
    // React.useEffect(() => {
    //     if(data && data.cameras && data.cameras.length){
    //         let cameras = data.cameras.map(item => {
    //             return (item.t4_cam_id) ? item.t4_cam_id: item.name;
    //         });
    //         setMappedCamIds(cameras.join(', '));
    //     }
    // }, [data]);
  
    return (
        <TableRow key={data.id} >
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} >{data.name}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {extraDetails && 
                <React.Fragment>
                    <TableCell>
                        <Grid className={commonClasses.column}>
                            <Grid>
                                <Typography variant={'subtitle1'} > {data.id ? data.id : '-'}</Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Grid className={commonClasses.column}>
                            <Grid>
                                <Typography variant={'subtitle1'} > {organization}</Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Grid className={commonClasses.column}>
                            <Button variant="contained" onClick={() => handleViewDetails(data.cameras)}>View</Button>
                            {/* <Grid>
                            </Grid> */}
                        </Grid>
                    </TableCell>
                </React.Fragment>
            }
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} > {data.mode}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} > {data.instance_ip ? data.instance_ip : '-'}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align={'center'}>
                {/* <Grid className={commonClasses.column}>
                    <Grid> */}
                        <Typography variant={'subtitle1'} >
                            {data.noOfCam ? data.noOfCam : 0}
                        </Typography>
                    {/* </Grid>
                </Grid> */}
            </TableCell>
            <TableCell style={{width:'15%'}}>
                <Grid className={commonClasses.column}>
                    <Grid style={{width: '100%'}}>
                        <LinearProgressWithLabel value={data.noOfCam ? parseInt(data.noOfCam)*100/100 : 0} />
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    <CameraStatusPanel t={t} status={data.status}/>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} >
                            {dateFormatterLLL(convertUTCDateToLocalDate(data.created_at))}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {allowConfigure && 
                <TableCell>
                    <Grid >
                        <IconButton
                            size={'small'}
                            disableRipple={true}
                            disableFocusRipple={true}
                            color={'secondary'}
                            onClick={evt => {
                                setAnchorEl(evt.currentTarget);
                                evt.stopPropagation();
                            }}
                        >
                        <MoreHoriz className={commonClasses.iconRegular} />
                        </IconButton>
                    </Grid>
                    {anchorEl && <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={menuItems}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, data, 'node');
                            setAnchorEl(null);
                        }}
                    />}
                </TableCell>
            }
        </TableRow>
    );
};

NodeManagementItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(NodeManagementItem);