import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { paddingGenerator, marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR, PROVIDERS, NODE_MANAGEMENT_TYPE } from '../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertUTCToLocal, encodeCameraRTSPURL, getScheduleTimeByTimeZone } from '../../../../utils';
import Fab from '@material-ui/core/Fab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../theme/colors';
import moment from 'moment';
import { validate } from 'uuid';

const camFpsOptions = ["0.1", "0.2", "0.4", "0.5", "0.8"];
const camRSOptions = ["640*480", "1024*780", "1920*1280"];

const useStyles = makeStyles(theme => ({
	paper: {},
	icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		flexGrow: 1,
	},
	input: {
		minWidth: '224px'
	},
	iconButton: {
		width: 'auto',
		height: 'auto',
		padding: theme.spacing(1.25, 3),
		borderRadius: '24px',
		marginTop: theme.spacing(2),
		border: "1px solid #b8b8b8"
	}
}));

function ButtonComponent(props) {
	const { t, onClick, loading } = props;
	return (
		<Button
			color="secondary"
			variant={'contained'}
			onClick={onClick} disabled={loading}>
			{loading && <CircularProgress size={14} />}
			{!loading && t('submit.label')}
		</Button>
	);
}

function UpdateCameraModal({
	t,
	open,
	handleUpdateCameraSubmitClick,
	data,
	selectePlaceList,
	selectMlTemplateList,
	selectNotiTemplateList,
	handleClose,
	modalState,
	subscriptionData,
	selecteNodeList,
	cameraFpsOptions,
	cameraRSOptions
}) {
	const cameraInitFields = { name: '', rtsp_url: '', fps: '', placeId: '', width: '', height: '', cameraRSL: '', remote_stream: "No" };
	const aiModelInitFields = { ai_template_id: '', from_time: '', to_time: '' };
	const notificationInitFields = { notification_template_id: '', ai_template_id: '' };

	const [cameraState, setCameraState] = useState([{ ...cameraInitFields }]);
	const [aiModelState, setAIModelState] = useState([]);
	const [notificationState, setNotificationState] = useState([{ ...notificationInitFields }]);
	const [selectedMlTemplates, setSelectedMlTemplates] = useState([]);
	const [provider, setProvider] = useState('');
	const [node, setNode] = useState('');
	const [selectedNode, setSelectedNode] = useState(null);
	const [nodeList, setNodeList] = useState([]);
	const [mlTemplateList, setMLTemplateList] = useState([]);
	const [deletedAIModels, setDeletedAIModels] = useState([]);
	const [deletedNotifications, setDeletedNotifications] = useState([]);
	const [timeZoneData, setTimeZoneData] = useState('');
	const [modifiedNotTemplates, setModifiedNotTemplates] = useState([]);
	const [filteredNotiTemplateIds, setFilteredNotiTemplateIds] = useState([]);
	const classes = useStyles();

	React.useEffect(() => {
		const templateIdsToRemove = modifiedNotTemplates.map(item => item.notificationTemplateId);
		setFilteredNotiTemplateIds(templateIdsToRemove);
	}, [notificationState]);


	React.useEffect(() => {
		const timeDetails = localStorage.getItem('timeZoneDetails');

		if (timeDetails) {
			setTimeZoneData(timeDetails)
		}
	}, []);

	React.useEffect(() => {
		if (modalState.clearData && open) {
			setCameraState([{ ...cameraInitFields }]);
			setAIModelState([{ ...aiModelInitFields }]);
			setNotificationState([{ ...notificationInitFields }]);
			setDeletedAIModels([]);
			setDeletedNotifications([]);
			handleClose();
		}
	}, [modalState]);

	React.useEffect(() => {
		if (data && data.camera_id) {
			setAIModelState([]);
			setSelectedMlTemplates([]);
			setMLTemplateList([]);
			let notificationData = [];
			if (data.notification_filters) {
				for (let i = 0; i < data.notification_filters.length; i++) {
					let notification = data.notification_filters[i];
					let tempObj = { id: notification.id, notification_template_id: notification.notification_template.id, ai_template_id: notification.model_template.id };
					notificationData.push(tempObj)
				}
			}
			let camData = { name: data.name, rtsp_url: data.url, fps: data.fps, placeId: data.place, width: data.content_width, height: data.content_height, cameraRSL: `${data.content_width}*${data.content_height}`, remote_stream: data.remote_stream ? "Yes" : "No" };
			setCameraState([{ ...camData }]);
			setNotificationState([...notificationData]);

			const output = notificationData.reduce((acc, item) => {
				const existingItem = acc.find(x => x.notificationTemplateId === item.notification_template_id);
				if (existingItem) {
					existingItem.ai_template_ids.push(item.ai_template_id);
				} else {
					acc.push({
						notificationTemplateId: item.notification_template_id,
						ai_template_ids: [item.ai_template_id]
					});
				}
				return acc;
			}, []);
			setModifiedNotTemplates(output);
			setProvider(data.provider);
		}
	}, [data]);

	React.useEffect(() => {
		if (selectMlTemplateList && selectMlTemplateList.length) {
			let aiTemplateData = [];
			if (data && data.schedules && data.schedules.length) {
				for (let i = 0; i < data.schedules[0].length; i++) {
					let schedule = data.schedules[0][i];
					let todayDate = moment(new Date()).format('YYYY-MM-DD');
					let startTime = moment(new Date(`${todayDate} ${schedule.start_time}`)).format("HH:mm");
					let endTime = moment(new Date(`${todayDate} ${schedule.end_time}`)).format("HH:mm");
					let tempObj = {
						ai_template_id: (schedule.model_template.id) ? schedule.model_template.id : '',
						from_time: startTime,
						to_time: endTime,
						scheduleId: schedule.id,
						start_time: schedule.start_time,
						end_time: schedule.end_time,
						startTime,
						endTime,
					}
					aiTemplateData.push(tempObj)
				}
			}
			setAIModelState([...aiTemplateData]);
			let selectedIds = aiTemplateData.map(value => {
				if (value.ai_template_id) {
					return value.ai_template_id;
				}
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if (selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
			let mlTemplates = selectMlTemplateList.filter(item => {
				if (item.provider === data.provider) {
					return item;
				}
			});
			setMLTemplateList(mlTemplates);
		}
	}, [selectMlTemplateList]);

	React.useEffect(() => {
		if (subscriptionData.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED && selecteNodeList && selecteNodeList.length) {
			setNode(data.node);
			let nodes = selecteNodeList.filter(item => {
				if (item.provider === data.provider) {
					return item;
				}
			});
			setNodeList(nodes);
			nodes = nodes.filter(item => {
				if (item.id === data.node) {
					return item;
				}
			});
			if (nodes.length) {
				setSelectedNode(nodes[0]);
			}
		}
	}, [selecteNodeList]);

	const handleCameraChange = (value, idx, column) => {
		const updatedCamera = [...cameraState];
		updatedCamera[idx][column] = value;
		setCameraState(updatedCamera);
	}

	const handleChangeCameraResolution = (value, idx) => {
		if (value) {
			let camRsArr = value.split('*');
			if (camRsArr.length) {
				handleCameraChange(camRsArr[0], idx, 'width');
				handleCameraChange(camRsArr[1], idx, 'height');
			}
		} else {
			handleCameraChange('', idx, 'width');
			handleCameraChange('', idx, 'height');
		}
		handleCameraChange(value, idx, 'cameraRSL');
	}

	const handleAIModelChange = (value, idx, column) => {
		const updatedAIModal = [...aiModelState];
		if (updatedAIModal.length && column === 'ai_template_id' && value) {
			let oldAITypes = [];
			updatedAIModal.map(aiItm => {
				if (aiItm.ai_template_id) {
					let mlTmp = mlTemplateList.filter(itm => itm.id === aiItm.ai_template_id);
					oldAITypes.push(mlTmp[0].model_type);
				}
			});
			let newSelectAI = mlTemplateList.filter(itm => itm.id === value);
			if (newSelectAI && newSelectAI.length > 0) {
				let mType = newSelectAI[0].model_type;
				if (oldAITypes.includes(mType)) {
					Message.error("AI type already added! Please select another AI Model.");
					return null;
				}
			}
		}

		updatedAIModal[idx][column] = value;
		setAIModelState(updatedAIModal);

		if (column === 'ai_template_id') {
			let selectedIds = updatedAIModal.map(value => {
				if (value.ai_template_id) {
					return value.ai_template_id;
				}
			});
			selectedIds = selectedIds.filter(function (item, pos) {
				return selectedIds.indexOf(item) == pos;
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if (selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
		}
	}

	const handleNotificationChange = (value, idx, column) => {
		const updatedNoti = [...notificationState];
		const updatedModNotTemplates = [...modifiedNotTemplates];
		if (idx + 1 === updatedModNotTemplates.length) {
			updatedModNotTemplates[updatedModNotTemplates.length - 1].notificationTemplateId = value;
			setModifiedNotTemplates(updatedModNotTemplates);
		} else {
			// updatedNoti[idx][column] = value;
			// setNotificationState(updatedNoti);
		}
	};

	const updateAiTemplateIds = (notifications, models) =>
		notifications.map(notification => {
			const aiTemplateId = notification.ai_template_id;
			if (aiTemplateId === "") {
				const matchingModel = models.find(model => model.id === notification.notification_template_id);
				if (matchingModel) {
					return {
						...notification,
						ai_template_id: matchingModel.id.toString(),
					};
				}
			}
			return notification;
		});

	const handleSelectedTemplates = (event, newValue, reason, detail, notificationTemplateId) => {
		const existingTemplateIds = newValue.map((val, i) => val.id);
		const updatedNoti = [...notificationState];
		const updatedModifiedNoti = [...modifiedNotTemplates];

		if (reason === "select-option") {
			updatedNoti.push(
				{
					notification_template_id: notificationTemplateId, ai_template_id: newValue[newValue.length - 1].id
				}
			);
			updatedModifiedNoti.map((val, i) => {
				if (val.notificationTemplateId === notificationTemplateId) {
					val.ai_template_ids = existingTemplateIds;
				}
			})
			setNotificationState(updatedNoti);
		} else if (reason === "remove-option") {
			const deletedArr = [];
			// eslint-disable-next-line no-unused-expressions
			updatedNoti?.map((val) => {
				if (val.ai_template_id === detail.option.id) {
					deletedArr.push(val.id)
				}
			})
			const updatedNotiArr = updatedNoti.filter((val) =>
				val.ai_template_id !== detail.option.id
			)
			updatedModifiedNoti.map((val, i) => {
				if (val.notificationTemplateId === notificationTemplateId) {
					val.ai_template_ids = existingTemplateIds;
				}
			})
			setNotificationState(updatedNotiArr);
			setDeletedNotifications([...deletedNotifications, ...deletedArr]);
		} else if (reason === "clear") {
		}
		setModifiedNotTemplates(updatedModifiedNoti);
	};

	const addNewAIModelFileds = () => {
		if (selectMlTemplateList.length <= aiModelState.length) {
			Message.error(`You can add only ${selectMlTemplateList.length} ai templates!`);
			return null;
		}
		setAIModelState(prevAiState => [...prevAiState, aiModelInitFields]);
	}

	const handleProviderChange = (value) => {
		setProvider(value);
		let nodes = selecteNodeList.filter(item => {
			if (item.provider === value) {
				return item;
			}
		});
		setNodeList(nodes);
		let mlTemplates = selectMlTemplateList.filter(item => {
			if (item.provider === value) {
				return item;
			}
		});
		setMLTemplateList(mlTemplates);
	}

	const addNewNotificationFileds = () => {
		// if(selectNotiTemplateList.length <= notificationState.length){
		// 	Message.error(`You can add only ${selectMlTemplateList.length} notification templates!`);
		// 	return null;
		// }
		// setNotificationState(oldArray => [...oldArray, { ...notificationInitFields }]);
		setModifiedNotTemplates(oldArray => [...oldArray, { ai_template_ids: [], notificationTemplateId: '' }]);
	}

	const removeMlTemplate = (id, index, scheduleId) => {
		let prevState = [...aiModelState];
		if (scheduleId) {
			setDeletedAIModels([...deletedAIModels, scheduleId]);
		}
		if (id) {
			let prevNotiState = [...notificationState];
			for (let i = 0; i < notificationState.length; i++) {
				if (prevNotiState[i] && prevNotiState[i].ai_template_id && prevNotiState[i].ai_template_id === id) {
					if (prevNotiState[i].id) {
						setDeletedNotifications([...deletedNotifications, prevNotiState[i].id]);
					}
					prevNotiState.splice(i, 1);
				}
			}
			setNotificationState(prevNotiState);
			let selectedIds = prevState.map(value => {
				if (value.ai_template_id) {
					return value.ai_template_id;
				}
			});
			const filteredMLTemp = selectMlTemplateList.filter(template => {
				if (selectedIds.indexOf(template.id) !== -1) {
					return template;
				}
			});
			setSelectedMlTemplates(filteredMLTemp);
		}
		prevState.splice(index, 1);
		setAIModelState(prevState);
	}

	// const removeNotificationTemplate = (id, index, noti_id) => {
	// 	let prevState = [...notificationState];
	// 	prevState.splice(index, 1);
	// 	setNotificationState(prevState);
	// 	if (noti_id) {
	// 		setDeletedNotifications([...deletedNotifications, noti_id]);
	// 	}
	// }

	const removeNotificationTemplate = (notificationTemplateId) => {
		if (notificationTemplateId) {
			const deletedArr = [];
			const deletedNotObjArr = notificationState.filter((obj) => obj.notification_template_id === notificationTemplateId);
			deletedArr.push(deletedNotObjArr[0].id);
			setDeletedNotifications([...deletedNotifications, ...deletedArr]);
			let updatedNoti = notificationState.filter(obj => obj.notification_template_id !== notificationTemplateId);
			let updatedModifiedNoti = modifiedNotTemplates.filter(obj => obj.notificationTemplateId !== notificationTemplateId);
			setNotificationState(updatedNoti);
			setModifiedNotTemplates(updatedModifiedNoti);
		} else {
			let updatedModifiedNotTemplates = [...modifiedNotTemplates];
			setModifiedNotTemplates(updatedModifiedNotTemplates.slice(0, -1));
		}
	}

	const getFilteredTemplates = (notificationTemplateId) => {
		const template = modifiedNotTemplates.find(
			(item) => item.notificationTemplateId === notificationTemplateId
		);

		if (template) {
			return selectedMlTemplates.filter((mlTemplate) =>
				template.ai_template_ids.includes(mlTemplate.id)
			);
		}

		return [];
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'lg'}
			aria-labelledby="camera-template-modal"
			aria-describedby="camera-template-modal"
			open={open}
			onClose={() => {
				handleClose();
			}}>
			<DialogTitle
				id="camera-template-title">{t('createNew.label')} {t('camera.label')} {t('configuration.label')}
			</DialogTitle>
			<DialogContent>
				<div className={classes.root}>
					<Grid container spacing={3}>
						{cameraState.map((val, idx) => {
							return (
								<React.Fragment key={`cam-${idx}`}>
									<Grid key={`heading-${idx}`} item xs={12} style={{ ...paddingGenerator('pl-5'), ...paddingGenerator('pl-0') }}>
										<Typography variant="subtitle2" color="textPrimary" >
											{t('camera.label')}
										</Typography>
									</Grid>
									<Grid item xs={subscriptionData.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED ? 6 : 12} style={{ ...marginGenerator('mb-15') }}>
										<OutlinedSelect
											val={provider}
											label={`${t('provider.label')} *`}
											handleChange={evt => {
												handleProviderChange(evt.target.value);
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											disabled={true}
											options={PROVIDERS}
										/>
									</Grid>
									{subscriptionData.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED &&
										<Grid item xs={6} style={{ ...marginGenerator('mb-15') }}>
											<OutlinedSelect
												val={node}
												label={`${t('node.label')} *`}
												handleChange={evt => {
													setNode(evt.target.value);
												}}
												selectStyle={{ ...paddingGenerator('p-8') }}
												styleOverrides={{ width: '100%', marginRight: 0 }}
												options={nodeList}
											/>
										</Grid>
									}
									<Grid key={`name-${idx}`} item xs={4}>
										<Input
											type={'text'}
											placeholder={`${t('cameraName.label')}*`}
											style={{ width: '100%' }}
											className={classes.input}
											color={'secondary'}
											value={cameraState[idx].name}
											onChange={evt => handleCameraChange(evt.target.value, idx, 'name')}
										/>
									</Grid>
									<Grid key={`camerartsp-${idx}`} item xs={4}>
										<Input
											value={cameraState[idx].rtsp_url}
											type={'text'}
											placeholder={`${t('camerartsp.label')}*`}
											style={{ width: '100%' }}
											className={classes.input}
											color={'secondary'}
											onChange={evt => {
												handleCameraChange(encodeCameraRTSPURL(evt.target.value), idx, 'rtsp_url');
											}}
										/>
									</Grid>
									<Grid key={`camerafps-${idx}`} item xs={4}>
										<OutlinedSelect
											val={cameraState[idx].fps}
											label={`${t('camerafps.label')} *`}
											handleChange={evt => {
												handleCameraChange(evt.target.value, idx, 'fps');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={cameraFpsOptions && cameraFpsOptions.length ? cameraFpsOptions : camFpsOptions}
										/>
									</Grid>
									<Grid key={`place-${idx}`} item xs={4}>
										<Autocomplete
											id={`place-select-${idx}`}
											value={cameraState[idx].placeId}
											options={selectePlaceList}
											getOptionLabel={(option) => option.name}
											onChange={(event, newValue) => {
												handleCameraChange(newValue, idx, 'placeId')
											}}
											style={{ border: '1px solid #ebebeb' }}
											renderInput={(params) => <TextField {...params} label={t('place.label')} variant="outlined" />}
										/>
									</Grid>
									<Grid key={`width-${idx}`} item xs={3}>
										<OutlinedSelect
											val={cameraState[idx].cameraRSL}
											label={`${t('camRsl.label')} *`}
											handleChange={evt => {
												handleChangeCameraResolution(evt.target.value, idx);
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={cameraRSOptions && cameraRSOptions.length ? cameraRSOptions : camRSOptions}
										/>
									</Grid>
									<Grid key={`height-${idx}`} item xs={5}>
										<Grid item xs={3} style={{ display: "inline-block" }}>
											<Typography variant="subtitle2" color="textPrimary" >{t("remoteStream.label")}</Typography>
										</Grid>
										<Grid item xs={9} style={{ display: "inline-block", marginLeft: 10 }}>
											{["Yes", "No"].map((field, index) => {
												return (
													<Radio
														key={index}
														checked={field === cameraState[idx].remote_stream}
														classes={{ root: classes.iconButton }}
														style={{
															...marginGenerator('mr-16'),
															...marginGenerator('mt-0'),
															...(field === cameraState[idx].remote_stream ? { backgroundColor: colors.secondary.main }
																: '')
														}}
														onChange={evt => handleCameraChange(field, idx, 'remote_stream')}
														value={field}
														name="remote_stream"
														checkedIcon={
															<div>
																<Typography
																	variant={'subtitle2'}
																	style={{ color: colors.common.white }}>
																	{field}
																</Typography>
															</div>
														}
														icon={
															<div className={classes.icon}>
																<Typography variant={'subtitle1'} color={'textPrimary'}>
																	{field}
																</Typography>
															</div>
														}
													/>
												)
											})}
										</Grid>
									</Grid>
								</React.Fragment>
							);
						})}

						<Grid item xs={12} style={{ ...marginGenerator("mt-24") }}>
							<Button
								variant={'outlined'}
								color={'primary'}

								onClick={() => {
									addNewAIModelFileds();
								}}
							>
								{t('add.label')} {t('aimodel.label')}
							</Button>
							{/* <Button
								style={{...marginGenerator('ml-20')}}
								color="secondary"
								disabled={!removeFieldState.ai_template.length}
								variant={'contained'}	
								onClick={() => {
									removeAIModelFileds();
									setSelectedRemoveAIFields([]);
								}}
							>
								{t('remove.label')}
							</Button> */}
						</Grid>
						{!aiModelState.length && <CircularProgress color="secondary" style={{ margin: 'auto' }} />}
						{aiModelState && aiModelState.map((aiMdl, idx) => {
							return (
								<React.Fragment key={`ai-${idx}`}>
									<Grid item xs={12} style={{ ...paddingGenerator('pl-5'), ...paddingGenerator('pl-0') }}>
										<Typography variant="subtitle2" color="textPrimary" >
											{t('aimodel.label')} {(idx + 1)}
										</Typography>
									</Grid>
									<Grid item xs={5}>
										<OutlinedSelect
											disabled={aiMdl.scheduleId ? true : false}
											val={aiMdl.ai_template_id}
											label={`${t('aiTemplate.label')} *`}
											handleChange={evt => {
												handleAIModelChange(evt.target.value, idx, 'ai_template_id');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={mlTemplateList}
										/>
									</Grid>
									<Grid item xs={3}>
										<Input
											type={'time'}
											placeholder={`${t('fromTime.label')}*`}
											style={{ width: '100%' }}
											className={classes.input}
											color={'secondary'}
											value={aiMdl.from_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'from_time')}
										/>
									</Grid>
									<Grid item xs={3}>
										<Input
											type={'time'}
											placeholder={`${t('toTime.label')}*`}
											style={{ width: '100%' }}
											className={classes.input}
											color={'secondary'}
											value={aiMdl.to_time}
											onChange={evt => handleAIModelChange(evt.target.value, idx, 'to_time')}
										/>
									</Grid>
									<Grid item xs={1}>
										<Fab size="small" color="secondary" aria-label="Remove" className={classes.margin} onClick={evt => { removeMlTemplate(aiMdl.ai_template_id, idx, aiMdl.scheduleId ? aiMdl.scheduleId : '') }}>
											<DeleteForeverIcon />
										</Fab>
									</Grid>
								</React.Fragment>
							)
						})}

						<Grid item xs={12} style={{ ...marginGenerator("mt-10") }}>
							<Button
								variant={'outlined'}
								color={'primary'}
								disabled={selectNotiTemplateList && selectNotiTemplateList.length > 0 ? false : true}
								onClick={() => {
									addNewNotificationFileds();
								}}
							>
								{t('add.label')} {t('notification.label')}
							</Button>
						</Grid>
						{/* {notificationState?.map((val, idx) => {
							return (
								<React.Fragment key={`noti-${idx}`}>
									<Grid item xs={12} style={{ ...paddingGenerator('pl-5'), ...paddingGenerator('pl-0') }}>
										<Typography variant="subtitle2" color="textPrimary" >
											<label htmlFor={`remove-check-${idx}`}>
												{t('notification.label')} {(idx + 1)}
											</label>
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<OutlinedSelect
											disabled={val.id ? true : false}
											val={val.notification_template_id}
											label={`${t('notification.label')} ${t('template.label')}*`}
											handleChange={evt => {
												handleNotificationChange(evt.target.value, idx, 'notification_template_id');
											}}
											selectStyle={{ ...paddingGenerator('p-8') }}
											styleOverrides={{ width: '100%', marginRight: 0 }}
											options={selectNotiTemplateList}
										/>
									</Grid>
									<Grid item xs={5}>
										<Autocomplete
											multiple
											id="notification_template_ids"
											value={selectMlTemplateList.filter(obj => obj.id === val.ai_template_id
											)}
											options={selectedMlTemplates}
											getOptionLabel={option => option.name}
											onChange={(e, option) => handleSelectedTemplates(e, option)}
											style={{ width: '100%', border: '1px solid #ebebeb' }}
											renderInput={params => (
												<TextField
													{...params}
													label={`${t('aiTemplate.label')} *`}
													variant="outlined"
												/>
											)}
										/>
									</Grid>
									<Grid item xs={1}>
										<Fab size="small" color="secondary" aria-label="Remove" className={classes.margin}
											onClick={evt => { removeNotificationTemplate(val.notification_template_id, idx, val.id ? val.id : '') }}>
											<DeleteForeverIcon />
										</Fab>
									</Grid>
								</React.Fragment>
							)
						})} */}
						{
							modifiedNotTemplates?.map((val, idx) => {
								return (
									<React.Fragment key={`noti-${idx}`}>
										<Grid item xs={12} style={{ ...paddingGenerator('pl-5'), ...paddingGenerator('pl-0') }}>
											<Typography variant="subtitle2" color="textPrimary" >
												<label htmlFor={`remove-check-${idx}`}>
													{t('notification.label')} {(idx + 1)}
												</label>
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<OutlinedSelect
												val={val.notificationTemplateId}
												disabled={val.notificationTemplateId !== ""}
												label={`${t('notification.label')} ${t('template.label')}*`}
												handleChange={evt => {
													handleNotificationChange(evt.target.value, idx, 'notification_template_id');
												}}
												selectStyle={{ ...paddingGenerator('p-8') }}
												styleOverrides={{ width: '100%', marginRight: 0 }}
												options={
													val.notificationTemplateId !== "" ? selectNotiTemplateList : selectNotiTemplateList.filter(item => {
														return !filteredNotiTemplateIds.includes(item.id);
													})
												}
											/>
										</Grid>
										<Grid item xs={5}>
											<Autocomplete
												multiple
												id={`notification_template_ids_${val.notificationTemplateId}`}
												value={getFilteredTemplates(val.notificationTemplateId)}
												options={selectedMlTemplates}
												getOptionLabel={(option) => option.name}
												onChange={(e, option, reason, detail) => handleSelectedTemplates(e, option, reason, detail, val.notificationTemplateId)}
												style={{ width: '100%', border: '1px solid #ebebeb' }}
												renderInput={(params) => (
													<TextField
														{...params}
														label={`${t('aiTemplate.label')} *`}
														variant="outlined"
													/>
												)}
											/>
										</Grid>
										<Grid item xs={1}>
											<Fab size="small" color="secondary" aria-label="Remove" className={classes.margin}
												onClick={evt => {
													removeNotificationTemplate(
														val.notificationTemplateId
													)
												}}
											>
												<DeleteForeverIcon />
											</Fab>
										</Grid>
									</React.Fragment>
								)
							})
						}
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Grid container justify={'space-between'}>
					<Button
						variant={'outlined'}
						onClick={() => {
							setCameraState([{ ...cameraInitFields }]);
							setAIModelState([{ ...aiModelInitFields }]);
							setNotificationState([{ ...notificationInitFields }]);
							setDeletedAIModels([]);
							setDeletedNotifications([]);
							handleClose();
						}}
					>
						{t('cancel.label')}
					</Button>
					<ButtonComponent
						t={t}
						onClick={() => {
							if (!provider && !cameraState.length) {
								Message.error(REQUIRED_ERROR);
								return null;
							}
							handleUpdateCameraSubmitClick({ cameras: cameraState, aiModels: aiModelState, notifications: notificationState, node, prevData: data, deletedAIModels, deletedNotifications, provider });
						}}
						loading={modalState.btnLoading} />
				</Grid>
			</DialogActions>
		</Dialog >
	);
}

UpdateCameraModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleUpdateCameraSubmitClick: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(UpdateCameraModal);
