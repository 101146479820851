import React from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
}));

const ServiceManagerDashBoard = ({ t }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Layout></Layout>
    </div>
  );
};

export default withTranslation()(ServiceManagerDashBoard);
