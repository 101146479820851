import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import { Paths } from '../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { INVITE_USER, UPDATE_USER, DELETE_USER, ASSIGN_INVITE_USER_ROLE_ORG } from '../OnBoarding/mutations';
import { GET_USER_INFO } from '../../containers/Profile/queries';
import CustomTabs from '../../components/CustomTabs';
import TeamPanel from './components/TeamPanel'
import InviteMemberModal from './components/InviteMemberModal'
import Message from '../../components/Message';
import { generatePassword } from '../../utils'
import { USER_ROLES } from '../../utils/constants'

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'settings.label', link: Paths.Settings }
];

const Settings = ({ t }) => {

    const history = useHistory();
    const role = localStorage.getItem('role');

    const isEadminRole = role && role.includes(USER_ROLES.EP_ADMIN) ? true : false;
    if(!isEadminRole){
        Message.error('Operation not permitted!');
        history.push("/dashboard");
    }

    const { data, loading, error } = useQuery(GET_USER_INFO);    
    const [inviteMemberModalState, setInviteMemberModalState] = useState(false);
    const [updatedMemberData, setUpdatedMemberData] = useState(false);
    const [updateDataState, setUpdateDataState] = useState(null);
    const [modalState, setModalState] = useState({btnLoading: false, clearData: false});

    const [assignInviteUserRoleAnOrg] = useMutation(ASSIGN_INVITE_USER_ROLE_ORG, {
        onError: error => {
            console.log('Error', error);
            setModalState({btnLoading:false, clearData: false});
        },
        onCompleted: response => {
            setModalState({btnLoading:false, clearData: true});
            setUpdatedMemberData(true);
            Message.success('Member Invited successfully.');
        }
    });
    
    const [inviteUser] = useMutation(INVITE_USER, {
        onError: error => {
            console.log('Error', error);
            setModalState({btnLoading:false, clearData: false});
        },
        onCompleted: response => {
            if(response.inviteUser && response.inviteUser.email){
                let users = JSON.parse(response.inviteUser.email);
                if(users.length){
                    return assignInviteUserRoleAnOrg({
                        variables: {users: response.inviteUser.email}
                    });
                }else{
                    setModalState({btnLoading:false, clearData: true});
                    setUpdatedMemberData(true);
                    Message.success('Member Invited successfully.');
                }
            }
        }
    });

    const [deleteUser] = useMutation(DELETE_USER, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: response => {
            setUpdatedMemberData(true);
            Message.success('Member deleted successfully.');
        }
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: response => {
            setUpdateDataState(null);
            setUpdatedMemberData(true);
            setModalState({btnLoading:false, clearData: true});
            Message.success('Member updated successfully.');   
        }
    });

    const handleManageMemberClick = (data) => {
        setUpdateDataState(data);
        setInviteMemberModalState(true);  
    }

    const handleInviteUserClick = () => {
        setUpdateDataState(null);
        setInviteMemberModalState(true);
        setModalState({btnLoading:false, clearData: false});
    }

    const handleInviteSubmitClick = (formData) => {
        if(formData.length){
            setModalState({btnLoading:true, clearData: false});
            if(updateDataState && formData[0].id){
                let uName = (formData[0].userName) ? formData[0].userName.split(" ") : [];
                let firstName = uName.length > 0 ? uName[0] : '';
                let lastName = uName.length > 1 ? uName[1] : '';
                return updateUser({
                    variables: {
                        id: formData[0].id,
                        firstName: firstName,
                        lastName: lastName,
                        role: formData[0].role
                    }
                });
            }else{
                let memberData = [];
                for(let i = 0; i < formData.length; i++){
                    let password = generatePassword(8);
                    let uName = (formData[i].userName) ? formData[i].userName.split(" ") : [];
                    let firstName = uName.length > 0 ? uName[0] : '';
                    let lastName = uName.length > 1 ? uName[1] : '';
                    let postData = {
                        firstName: firstName,
                        lastName: lastName,
                        email: formData[i].email,
                        phone: '',
                        password: password,
                        role: formData[i].role
                    };
                    memberData.push(postData);
                }
                return inviteUser({
                    variables: {
                        inviteUsers: JSON.stringify(memberData)
                    }
                });
            }
        }else{
         return false;   
        }
    }

    const handleDeleteMemberClick = id => {
        return deleteUser({
            variables:{id}
        });
    }

    return (
        <Layout breadcrumbList={breadcrumbList}>
            <Header
                title={!loading && !error ? data.userInfo.organization.name : t('organization.label')}
                subText={!loading && !error ? (data.userInfo.organization.email) ? data.userInfo.organization.email : '-' : ''}
            />
            <CustomTabs
                tabs={[
                    {
                    label: t('team.label'),
                    children: (
                    <>
                        {!loading && !error ?
                            <TeamPanel 
                                t={t} 
                                userData={data.userInfo} 
                                handleInviteUserClick={handleInviteUserClick}
                                handleManageMemberClick={handleManageMemberClick}
                                updatedMemberData={updatedMemberData}
                                handleDeleteMemberClick={handleDeleteMemberClick}
                            />
                        : null}
                    </>
                    )
                    }
                ]}
            />
             <InviteMemberModal
                t={t}
                open={inviteMemberModalState}
                handleInviteSubmitClick={handleInviteSubmitClick}
                handleClose={() => setInviteMemberModalState(false)}
                data={updateDataState}
                modalState={modalState}
            />
        </Layout>
    );
};

export default withTranslation()(Settings);
