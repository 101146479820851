import React from 'react';
import CustomTabs from '../../../../../../components/CustomTabs';
import LabourFormPanel from '../../components/LabourFormPanel';
import LeaveFormPanel from '../../components/LeaveFormPanel';
import BackupControlPanel from '../../components/BackupControlPanel';
import EngagementAdvicePanel from '../../components/EngagementAdvicePanel'
import ChangeOfStatusPanel from '../ChangeOfStatusPanel';
import ActingFormPanel from '../../components/ActingFormPanel'
import ExitClearancePanel from '../ExitClearancePanel';
import { USER_ROLES } from '../../../../../../utils/constants';

const HrPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex,
    departments
}) => {

    const role = localStorage.getItem('role');
    let tabList = [];
    if(data){              
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Leave Form",
                children: (
                    <>
                        <LeaveFormPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Labour Request",
                children: (
                    <>
                        <LabourFormPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Engagement Advice Form",
                children: (
                    <>
                        <EngagementAdvicePanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Employee Exit Clearance Form",
                children: (
                    <>
                        <ExitClearancePanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Change of Status",
                children: (
                    <>
                        <ChangeOfStatusPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
            tabList.push({
                label: "Acting Form Revised",
                children: (
                    <>
                        <ActingFormPanel
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }
    }

    return (
        <CustomTabs 
            activeIndex={activeIndex} 
            tabs={tabList}
        />
    )
}

export default HrPanel