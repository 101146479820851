import React, { useContext, useState } from 'react'
import { ShayonaCementsContext } from '../../../../contexts/ShayonaCementsProvider';
import ShayonaDashboard from './ShayonaDashboard';
import ShayonaTrips from './ShayonaTrips';
import ShayonaAlerts from './ShayonaAlerts';
import ShayonaReports from './ShayonaReports';
import ShayonaConfiguration from './ShayonaConfiguration';
import CustomTabs from '../../../../components/CustomTabs';

const ShayonaCementsProduct = ({
  t,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    data,
    handleUpdateAction,
    handleCreateButtonClick,
    reloadData,
    countryCodes,
    handleAddURLSubmitClick,
    handleCreateURLClick,
    edgeAILiveCamURL
  } = useContext(ShayonaCementsContext);
  
  const tabList = [
    {
      label: t('dashboard.label'),
      children: (
        <ShayonaDashboard
          t={t}
        />
      )
    },
    {
      label: t('trips.label'),
      children: (
        <ShayonaTrips
          t={t}
        />
      )
    },
    {
      label: t('alerts.label'),
      children: (
        <ShayonaAlerts
          t={t}
        />
      )
    },
    {
      label: t('reports.label'),
      children: (
        <ShayonaReports
          t={t}
        />
      )
    },
    {
      label: t('configuration.label'),
      children: (
        <ShayonaConfiguration
          t={t}
          data={data}
          handleUpdateAction={handleUpdateAction}
          handleCreateButtonClick={handleCreateButtonClick}
          reloadData={reloadData}
          countryCodes={countryCodes}
          handleAddURLSubmitClick={handleAddURLSubmitClick}
          handleCreateURLClick={handleCreateURLClick}
          edgeAILiveCamURL={edgeAILiveCamURL}
        />
      )
    },
  ];
  return (
    <>
      <CustomTabs
        activeIndex={activeIndex}
        tabs={tabList}
        //handleChangeActiveNotiTab={handleChangeActiveNotiTab} 
      />
    </>
  )
}

export default ShayonaCementsProduct