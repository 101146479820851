import {
  Button,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
  TextField,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import Popup from '../../../Popup';
import {
  FormProvider,
  RHFTextField
} from '../../../../../components/hook-form';
import {
  getBgpByConnId,
  updateBgpById
} from '../../../../../services/fcrService';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={(0, 4)}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const validationSchema = yup.object().shape({
  remoteAs: yup
    .number()
    .typeError('Enter a value between 65001 & 65100')
    .min(65001, 'Enter a value between 65001 & 65100')
    .max(65100, 'Enter a value between 65001 & 65100')
});

const EditBgpPopupPort = ({
  BgpEditView,
  setBgpEditView,
  connectionId,
  classes
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [nieghbourIp, setNieghbourIp] = useState();
  const [operStatus, setOperStatus] = useState('');
  const [bgpId, setBgpId] = useState('');
  const [error, setError] = useState('');
  const [deleteStatus, setDeleteStatus] = useState('No');
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialValues = { neighbourIp: '', remoteAs: '' };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async ({ remoteAs }) => {
    const payload = {
      neighbor_remote: remoteAs.toString(),
      deleteBgp: deleteStatus
    };
    const res = await updateBgpById(bgpId, payload);
    if (res?.status === 200) {
      addToast('Edited Succesfully', { appearance: 'success' });
      setBgpEditView(false);
      reset();
    } else {
      setError(res?.response?.data?.message);
    }
    reset();
  };

  const GetBgpByConnId = async () => {
    setLoading(true);
    const res = await getBgpByConnId(connectionId);
    if (res?.data?.statusCode === 200) {
      setNieghbourIp(res?.data?.result?.conn_id?.conn_cidr_b_ip);
      setValue('remoteAs', res?.data?.result?.neighbor_remote);
      setBgpId(res?.data?.result?._id);
      setOperStatus(res?.data?.result?.oper_status);
      setLoading(false);
    } else {
      setError(res?.response?.data?.message || res?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetBgpByConnId();
  }, []);

  return (
    <>
      <Popup
        title="Edit BGP"
        openPopup={BgpEditView}
        setOpenPopup={setBgpEditView}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid item>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="Standard" {...a11yProps(0)} />
                <Tab label="Advanced" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                {loading ? (
                  <>
                    <Grid container justify="center" spacing={2}>
                      <CircularProgress />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container direction="column" spacing={2}>
                      <Grid item container alignItems="center">
                        <Grid
                          item
                          style={{ color: 'grey', fontWeight: '500' }}
                          sm={4}>
                          Neighbor IP
                        </Grid>
                        <Grid item sm={1}>
                          <span>:</span>
                        </Grid>
                        <Grid item sm={7}>
                          <TextField
                            value={nieghbourIp}
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item container justify="center" alignItems="center">
                        <Grid
                          item
                          style={{ color: 'grey', fontWeight: '500' }}
                          sm={4}>
                          Remote-AS
                        </Grid>
                        <Grid item sm={1}>
                          <span>:</span>
                        </Grid>
                        <Grid item sm={7}>
                          <RHFTextField
                            variant="outlined"
                            name="remoteAs"
                            autoComplete="off"
                            size="small"
                            disabled={operStatus === 'Error' || error !== ''}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item container alignItems="center" justify="center">
                        <Grid
                          item
                          style={{ color: 'grey', fontWeight: '500' }}
                          sm={4}>
                          Delete BGP
                        </Grid>
                        <Grid item sm={1}>
                          :
                        </Grid>
                        <Grid item sm={7}>
                          <TextField
                            select
                            value={deleteStatus}
                            variant="outlined"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true
                            }}
                            disabled={operStatus === 'Error' || error !== ''}
                            onChange={e => setDeleteStatus(e.target.value)}>
                            {[
                              { key: 'Yes', value: 'Yes' },
                              { key: 'No', value: 'No' }
                            ].map(option => (
                              <option key={option.key} value={option.value}>
                                {option.key}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      {operStatus === 'Error' && (
                        <Grid
                          item
                          container
                          alignItems="center"
                          justify="center">
                          <Grid
                            item
                            style={{ color: 'grey', fontWeight: '500' }}
                            sm={4}>
                            Status
                          </Grid>
                          <Grid item sm={1}>
                            :
                          </Grid>
                          <Grid item sm={7}>
                            <Typography color="error">{operStatus}</Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {error && (
                      <Grid item>
                        <h5 style={{ color: 'red', textAlign: 'center' }}>
                          {error}
                        </h5>
                      </Grid>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={1}
                style={{ height: '175px' }}></TabPanel>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {!error && operStatus !== 'Deleting...' ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    setBgpEditView(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={operStatus === 'Error' || error !== ''}>
                  Submit
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setBgpEditView(false);
                  }}>
                  Close
                </Button>
              </>
            )}
          </DialogActions>
        </FormProvider>
      </Popup>
    </>
  );
};

export default EditBgpPopupPort;
