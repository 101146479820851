// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { GET_PETTY_CASHIER_TEAM_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TreasuryManagementTeamItem from "../TreasuryManagementTeamItem";
import { GET_USER_INFO } from '../../../../Profile/queries';
import CreateTreasuryManagementTeamModal from "../CreateTreasuryManagementTeamModal";
import { ADD_CASH_ADVANCE_REQUEST, UPLOAD_FILES, ADD_TREASURY_MANAGEMENT_REQUEST_TEAM, UPDATE_PETTY_CASHIER_REQUEST_TEAM } from "../../mutations";
import { GET_ALL_USER_BY_ROLES, GET_ALL_USER_BY_ONLY_COUNTRY_ID_LIST } from '../../../../commonQueries';
import CompleteDetailModal from "../CompleteDetailModal";
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    button: {
        width: "40px !important",
        minWidth: "auto",
        borderRadius: "0px 5px 5px 0px"
    },
    marginBottom10: {
        marginBottom: '10px'
    }
}));
const TreasuryManagementTeam = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const [CurrentUserName, setCurrentUserName] = useState('');
    const [TreasuryManagementTeam, setTreasuryManagementTeam] = useState({ open: false, btnLoading: false, clearData: false });
    const [PreValuesOfForm, setPreValuesOfForm] = useState([]);
    const [allUserList, setAllUserList] = useState('');
    const [completeDetails, setCompleteDetails] = useState({ open: false, data: [], previous_comment: null, documents: [], loading: true, projectDetail: [] });
    const [SearchProjectsText, setSearchProjectsText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    const { loading: customerLoading, data: users } = useQuery(GET_ALL_USER_BY_ONLY_COUNTRY_ID_LIST, {
        fetchPolicy: 'no-cache',
        onError: error => {
            // console.log('Error get all users info', error);
        },
        onCompleted: data => {
            // console.log("users name : ", data);
        }
    });
    useEffect(() => {
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "team_name=Treasury Manager&page=1&project_manager=is_admin"
                }
            })
        }
        getAllUserList({
            variables: {
                role: USER_ROLES.PETTY_CASHIER_TEAM
            }
        })
        // resfresh listing when create new listing created
        if (refreshTab === true) {
            setLoading(true);
            if (refreshTab === true) {
                setLoading(true);
                // if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.PROJECT_MANAGER)) {
                if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
                    getAllList({
                        variables: {
                            pageNumberOrUid: "team_name=Treasury Manager&project_manager=is_admin&page=1"
                        }
                    })
                } else {
                    getAllList({
                        variables: {
                            pageNumberOrUid: "page=1&team_name=Treasury Manager&project_manager=" + currentUserData.userInfo.id
                        }
                    })
                }
            }
        }
    }, [refreshTab, users, customerLoading]);

    const [getAllUserList] = useLazyQuery(GET_ALL_USER_BY_ROLES, {
        onError: error => {
            console.log('Error get users list in TreasuryManagement team', error);
        },
        onCompleted: data => {
            // console.log("data role change: ", data);
            let parseData = data.getAllUserByRoles && data.getAllUserByRoles.RoleData ? JSON.parse(data.getAllUserByRoles.RoleData) : [];
            // console.log("parseData role change: ", parseData);
            setAllUserList(parseData);
        }
    });
    const { data: currentUserData } = useQuery(GET_USER_INFO, {
        onError: error => {
            console.log('Error get current users info in chnage control team', error);
        },
        onCompleted: data => {
            // let full_name = data.userInfo.id;
            let full_name = data.userInfo.firstName + ' ' + data.userInfo.lastName;
            let full_ID = data.userInfo.id;
            // console.log("currentUserData name : ", data);
            setCurrentUserName(full_name);
            let checkAdminRole = [USER_ROLES.IP_SUPERADMIN, USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN];
            let checkRoleResult = checkAdminRole.some(i => role.includes(i));
            if (!checkRoleResult) {
                getAllList({
                    variables: {
                        pageNumberOrUid: "team_name=Treasury Manager&page=1&project_manager=" + full_ID
                    }
                })
            }
        }
    });
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        let checkSearchFilterData = "";
        if (SearchProjectsText != '' && SearchProjectsText != null) {
            checkSearchFilterData = checkSearchFilterData + "&search=" + SearchProjectsText
        }
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        if (convertStartDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&start_date=" + convertStartDate
        }
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if (convertEndDate != 'Invalid date') {
            checkSearchFilterData = checkSearchFilterData + "&end_date=" + convertEndDate
        }
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "team_name=Treasury Manager&project_manager=is_admin&page=" + value + checkSearchFilterData
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "team_name=Treasury Manager&project_manager=" + currentUserData.userInfo.id + "&page=" + value
                }
            })
        }
    };

    const [getAllList] = useLazyQuery(GET_PETTY_CASHIER_TEAM_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("getPettyCashierRequest data : ", data);
            if (data && data.getPettyCashierRequest) {
                let parseResult = JSON.parse(data.getPettyCashierRequest.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error get petty cashier list:", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const columns = [
        {
            label: t('name.label'),
            key: "name"
        },
        {
            label: t('department.label'),
            key: "department"
        },
        {
            label: t('transactionType.label'),
            key: "transactionType"
        },
        {
            label: t('treasuryManagers.label'),
            key: "treasuryManagers"
        },
        {
            label: t('pettyCashiers.label') + ' ' + t('review.label'),
            key: "pettyCashiers"
        },
        // {
        //     label: t('pettyCashiers.label') + ' ' + t('comment.label'),
        //     key: "pettyCashiers"
        // },
        {
            label: t('createdDate.label'),
            key: "createdDate"
        },
        {
            label: t('track.label') +' '+ t('status.label'),
            key: "ProjectTrack"
        },
        {
            label: t('ViewCompleteDetails.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (data) => {
        // console.log("data change : ", data);
        setPreValuesOfForm(data);
        setTreasuryManagementTeam({ open: true, btnLoading: false, clearData: true });
    }

    const handleSubmitTreasuryManagementTeamForm = (formData) => {
        setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: true });
        handleRefreshTab(false);
        addTreasuryManagementTeamSection({
            variables: {
                work_flow_management: PreValuesOfForm.work_flow.uid,
                approval: formData.approval,
                comment: formData.comment,
                final_petty_cashier_team: formData.final_petty_cashier_team,
                final_petty_cashier_uid: formData.final_petty_cashier_uid,
                next_team_member_email: formData.next_team_member_email,
            }
        })
    }
    const [addTreasuryManagementTeamSection] = useMutation(ADD_TREASURY_MANAGEMENT_REQUEST_TEAM, {
        onError: error => {
            setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: false });
            console.log('Error add change control form', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data addTreasuryManagementRequestTeam : ", data);
            setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: false });
            if (data && data.addTreasuryManagementRequestTeam) {
                if (JSON.parse(data.addTreasuryManagementRequestTeam.status) === true) {
                    Message.success('New treasury manager request created successfully');
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.addTreasuryManagementRequestTeam.teamData);
                    setTreasuryManagementTeam({ open: false, btnLoading: false, clearData: true });
                } else {
                    let message = JSON.parse(data.addTreasuryManagementRequestTeam.message);
                    Message.error(message);
                }
            }
        }
    });
    const handleClickViewAllDetails = (data) => {
        let detailArray = [
            { label: t('name.label'), value: data.work_flow.name ? data.work_flow.name : 'NA', button: false },
            { label: t('department.label'), value: data.work_flow.department ? data.work_flow.department : 'NA', button: false },
            { label: t('date.label'), value: data.work_flow.date ? data.work_flow.date : 'NA', button: false },
            { label: t('transactionType.label'), value: data.work_flow.transaction_type ? data.work_flow.transaction_type : 'NA', button: false },
            { label: t('total.label'), value: data.work_flow.total ? data.work_flow.total : 'NA', button: false },
            { label: t('cashAdvance.label'), value: data.work_flow.cash_advance ? data.work_flow.cash_advance : 'NA', button: false },
            { label: t('cashReturnrDue.label'), value: data.work_flow.cash_returned ? data.work_flow.cash_returned : 'NA', button: false },
            { label: t('nameOfStaff.label'), value: data.work_flow.name_of_staff ? data.work_flow.name_of_staff : 'NA', button: false },
            { label: t('bankName.label'), value: data.work_flow.bank_name ? data.work_flow.bank_name : 'NA', button: false },
            { label: t('accountNumber.label'), value: data.work_flow.account_number ? data.work_flow.account_number : 'NA', button: false },
            { label: t('usersComment.label'), value: data.work_flow.users_comment ? data.work_flow.users_comment : 'NA', button: false },
            { label: t('attachReceipt.label'), value: data.work_flow.attach_receipt ? data.work_flow.attach_receipt : '', button: true },
            { label: t('treasuryManagers.label'), value: data.TreasuryManagement_team, button: false },
            { label: t('pettyCashiers.label') + ' ' + t('review'), value: data.comment, button: false },
            // { label: t('pettyCashiers.label') + ' ' + t('comment'), value: data.comment, button: false },
        ]
        let transaction = data.work_flow.work_flow_transaction;
        for (let index = 0; index < transaction.length; index++) {
            detailArray.push(
                { label: t('description.label') + ' ' + index, value: transaction[index].description, button: false },
                { label: t('amount.label') + ' ' + index, value: transaction[index].amount, button: false })
        }
        setCompleteDetails({ ...completeDetails, open: true, data: detailArray, previous_comment: data.previous_comment, documents: data.previous_docs, loading: false, projectDetail: [] });
    }
    const handleSearchProjectsList = (value) => {
        let convertStartDate = moment(startDate).format("YYYY-MM-DD");
        let convertEndDate = moment(endDate).format("YYYY-MM-DD");
        if ((convertStartDate != 'Invalid date' && convertEndDate === 'Invalid date') || (convertEndDate != 'Invalid date' && convertStartDate === 'Invalid date')) {
            Message.error("Please select start or end date.");
            return false;
        }
        if (convertStartDate === 'Invalid date') {
            convertStartDate = '';
        }
        if (convertEndDate === 'Invalid date') {
            convertEndDate = '';
        }
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "team_name=Treasury Manager&project_manager=is_admin&page=1&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&team_name=Treasury Manager&project_manager=" + currentUserData.userInfo.id + "&search=" + SearchProjectsText + "&start_date=" + convertStartDate + "&end_date=" + convertEndDate
                }
            })
        }
    };
    const handleResetSearchProjectsList = () => {
        setStartDate('');
        setEndDate('');
        setSearchProjectsText('');
        setLoading(true);
        setError(true);
        if (role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.WORKFLOW_MANAGEMENT_ADMIN)) {
            getAllList({
                variables: {
                    pageNumberOrUid: "team_name=Treasury Manager&project_manager=is_admin&page=1"
                }
            })
        } else {
            getAllList({
                variables: {
                    pageNumberOrUid: "page=1&team_name=Treasury Manager&project_manager=" + currentUserData.userInfo.id
                }
            })
        }
    };
    const handleSelectTeamName = (teamName) => {
        setTreasuryManagementTeam({...TreasuryManagementTeam, clearData: false });
        if(teamName === 'Procurement'){
            getAllUserList({
                variables: {
                    role: USER_ROLES.PROCUREMENT_TEAM
                }
            })
        } else if(teamName === 'Human Captial'){
            getAllUserList({
                variables: {
                    role: USER_ROLES.HUMAN_CAPITAL_TEAM
                }
            })
        } else if(teamName === 'Petty Cahier'){
            getAllUserList({
                variables: {
                    role: USER_ROLES.PETTY_CASHIER_TEAM
                }
            })
        } 
    }
    const handleSubmitPettyCashierTeamForm = (formData) => {
        setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: true });
        handleRefreshTab(false);
        addPettyCashierTeamSection({
            variables: {
                uid: PreValuesOfForm.uid,
                // treasury_comment: "Yes",
                approval: formData.approval,
                treasury_comment: formData.comment,
                team_name: formData.team_name,
                next_team_member_name: formData.next_team_member_name,
                next_team_member_uid: formData.next_team_member_uid,
                next_team_member_email: formData.next_team_member_email,
            }
        })
    }
    const [addPettyCashierTeamSection] = useMutation(UPDATE_PETTY_CASHIER_REQUEST_TEAM, {
        onError: error => {
            setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: false });
            console.log('Error updatePettyCashierRequestTeam', error);
            setError(true);
        },
        onCompleted: data => {
            // console.log("data updatePettyCashierRequestTeam : ", data);
            setTreasuryManagementTeam({ ...TreasuryManagementTeam, btnLoading: false });
            if (data && data.updatePettyCashierRequestTeam) {
                if (JSON.parse(data.updatePettyCashierRequestTeam.status) === true) {
                    Message.success('Petty cashier request updated successfully');
                    setLoading(true);
                    handleRefreshTab(true);
                    // let parseData = JSON.parse(data.updatePettyCashierRequestTeam.teamData);
                    setTreasuryManagementTeam({ open: false, btnLoading: false, clearData: true });
                } else {
                    let message = JSON.parse(data.updatePettyCashierRequestTeam.message);
                    Message.error(message);
                }
            }
        }
    });
    return (
        <>
            <Header title={t('treasuryManagerTeam.label')} />
            <Grid container>
                <Grid item xs={9}>
                    <Grid style={{ margin: '0px 0px 15px 0px', display: "flex" }}>
                        <Grid style={{ marginRight: '10px' }}>
                            <DatePicker
                                className={classes.marginBottom10}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t('startDate.label')}
                            />
                            <br />
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t('endDate.label')}
                            />
                        </Grid>
                        <Grid>
                            <Input
                                value={SearchProjectsText}
                                type={'text'}
                                placeholder={t('searchProjects.label')}
                                style={{ width: '80%', flex: 1 }}
                                color={'secondary'}
                                onChange={evt => setSearchProjectsText(evt.target.value)}
                            />
                            <Button
                                color="secondary"
                                variant={'contained'}
                                className={classes.button}
                                onClick={handleSearchProjectsList}
                            >
                                <SearchIcon></SearchIcon>
                            </Button>
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Button
                                color="secondary"
                                variant={'contained'}
                                onClick={handleResetSearchProjectsList}
                            >{t('reset.label')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <TreasuryManagementTeamItem
                                            data={item}
                                            key={index}
                                            handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems}
                                            CurrentUserUid={currentUserData && currentUserData.userInfo.id ? currentUserData.userInfo.id : ''}
                                            handleClickViewAllDetails={handleClickViewAllDetails} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                        `` </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
                <CompleteDetailModal
                    open={completeDetails.open}
                    t={t}
                    handleClose={() => {
                        setCompleteDetails({ ...completeDetails, open: false });
                    }}
                    data={completeDetails.data}
                    loading={completeDetails.loading}
                    previous_comment={completeDetails.previous_comment}
                    documents={completeDetails.documents}
                    projectDetail={completeDetails.projectDetail}
                />
                <CreateTreasuryManagementTeamModal
                    t={t}
                    open={TreasuryManagementTeam.open}
                    handleSubmitTreasuryManagementTeamForm={handleSubmitTreasuryManagementTeamForm}
                    handleSubmitPettyCashierTeamForm={handleSubmitPettyCashierTeamForm}
                    handleClose={() => {
                        setTreasuryManagementTeam({ open: false, btnLoading: false, clearData: true });
                    }}
                    modalState={TreasuryManagementTeam}
                    allUserList={allUserList}
                    handleSelectTeamName={handleSelectTeamName}
                    customerLoading={customerLoading} />
            </Grid>
        </>
    );
};
export default withTranslation()(TreasuryManagementTeam);
