import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import BillTo from './BillTo';
import InvoiceHeading from './InvoiceNo';
import InvoiceItemsTable from './InvoiceItemsTable';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    invoiceBox: {
        maxWidth: 800,
        margin: "auto",
        padding: 30,
        border: "1px solid #eee",
        boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
        fontSize: 16,
        lineHeight: 24,
        fontFamily: "Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif",
        color: "#555"
    },
    invoiceBoxTable: {
        width: "100%",
        lineHeight: "inherit",
        textAlign: "left"
    }
});

const Invoice = ({userInfo, invoice, t, invoiceCompanyDetails}) => (
    <Document wrap={true} key={invoice.invoiceNumber}>
        <Page size="A4" style={styles.page}>
            <InvoiceHeading invoiceCompanyDetails={invoiceCompanyDetails} />
            <BillTo invoice={invoice} userInfo={userInfo}/>
            <InvoiceItemsTable invoice={invoice} t={t} />
        </Page>
    </Document>
);
export default Invoice