import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));

export default function CustomTable({ data, total, column, loading, handleItemClick }) {
    const classes = useStyles();

    const minutesSec = (timeToRestore) => {
        timeToRestore = Number(timeToRestore) * 60;
        let h = Math.floor(timeToRestore / 3600);
        let m = Math.floor(timeToRestore % 3600 / 60);

        let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

        return hDisplay + mDisplay
    }

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: '100%',
                        padding: 20,
                        textAlign: 'center',
                        display: 'inline-block'
                    }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {column &&
                                    column.map(column => (
                                        <TableCell key={uuidv4()}>{column.name}</TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.length > 0 ? data.map(row => (
                                <StyledTableRow key={uuidv4()}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{minutesSec(row.mttr)}</TableCell>
                                    <TableCell>{minutesSec(row.customer)}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => handleItemClick(row.allData)}>
                                            View Customers
                                        </Button>
                                    </TableCell>
                                </StyledTableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={column.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {total && (
                                <StyledTableRow>
                                    <TableCell>Grand Total</TableCell>
                                    <TableCell>{total}</TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
