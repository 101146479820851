import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import EdgeAIBilling from "./EdgeAIBilling";
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function BillingModal({ data, t, handleClose, open, billingData, currency, currencyList, saveBtnLoading, handleSubmitBilling, currencies, handleChangeCurrency, modalState }) {

  return (
    <div>
      <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>Billing</DialogTitle>
        <DialogContent dividers>
          <EdgeAIBilling open={open} t={t} billingData={billingData} currency={currency} currencyList={currencyList} saveBtnLoading={saveBtnLoading} handleSubmitBilling={handleSubmitBilling} currencies={currencies} handleChangeCurrency={handleChangeCurrency} modalState={modalState} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation()(BillingModal);