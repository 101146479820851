import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import PlacesMap from '../../../NewSubscription/components/PlacesMap';
import {GET_ALL_PLACES, GET_CONTROLLERS_LIST, GET_VM_IMAGES_LIST} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import itemStyle from "echarts/src/model/mixin/itemStyle";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    input: {
        minWidth: '224px'
    }
}));
const deviceTypes = [
    {
        id: 'Small Branch',
        name: 'Small Branch'
    },
    {
        id: 'Branch',
        name: 'Branch'
    },
    {
        id: 'DC',
        name: 'DC'
    },
    {
        id: 'Cloud',
        name: 'Cloud'
    }
];
// const interfaceTypes = [
//   {
//     id: 'PCI',
//     name: 'PCI'
//   },
//   {
//     id: 'DPDK',
//     name: 'DPDK'
//   },
//   {
//     id: 'SRIOV',
//     name: 'SRIOV'
//   },
//   {
//     id: 'Tap',
//     name: 'Tap'
//   }
// ];
const callHomeTypes = [
    {
        id: 'yes',
        name: 'Yes'
    },
    {
        id: 'no',
        name: 'No'
    }
];

function EditUcpeModal({t, data, open, handleUpdateUcpe, handleClose, modalState, UcpeDetails}) {
    const organization_id = data.organizationId
    const {
        loading: loadingPlaces,
        data: placesList,
        error: placesError
    } = useQuery(GET_ALL_PLACES, {
        variables: {
            organization_id
        }
    });
    let placeFilterList =
        !loadingPlaces && !placesError && placesList.getAllPlaces.length > 0
            ? placesList.getAllPlaces.map(place => {
                return {
                    id: place.id,
                    name: place.place_name
                };
            })
            : [];
    const [placeId, setPlaceId] = useState('');
    const [deviceIP, setDeviceIP] = useState('');
    const [deviceUsername, setDeviceUsername] = useState('');
    const [devicePassword, setDevicePassword] = useState('');
    const [netconfPort, setNetconfPort] = useState('');
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setDeviceIP('');
            setPlaceId('');
            setDeviceUsername('');
            setDevicePassword('');
            setNetconfPort('');
            handleClose();
        }
        if (UcpeDetails && UcpeDetails.id) {
            setDeviceIP(UcpeDetails.device_ip);
            setPlaceId(UcpeDetails.place_id);
            setDeviceUsername(UcpeDetails.device_username);
            setNetconfPort(UcpeDetails.network_port);
        }
    }, [modalState, UcpeDetails]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ucpe-modal"
            aria-describedby="ucpe-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            container spacing={3}
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={placeId}
                                    label={`${t('places.label')} *`}
                                    handleChange={evt => {
                                        setPlaceId(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        placeFilterList.length > 0
                                            ? placeFilterList
                                            : []
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={deviceIP}
                                    type={'text'}
                                    placeholder={`${t('deviceIP.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setDeviceIP(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={deviceUsername}
                                    type={'text'}
                                    placeholder={`${t('deviceUsername.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setDeviceUsername(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Input
                                    value={devicePassword}
                                    type={'text'}
                                    placeholder={`${t('devicePassword.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setDevicePassword(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={marginGenerator('mt-24')}>
                                <Input
                                    value={netconfPort}
                                    type={'text'}
                                    placeholder={`${t('netconfPort.label')}*`}
                                    style={{width: '100%'}}
                                    color={'secondary'}
                                    onChange={evt => setNetconfPort(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleUpdateUcpe({
                                place_id: placeId,
                                address: deviceIP,
                                device_username: deviceUsername,
                                device_password: devicePassword,
                                port: netconfPort
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

EditUcpeModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(EditUcpeModal);
