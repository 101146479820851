import React, { useState, useEffect } from 'react';
import { get } from "lodash-es";
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  GET_CAMERA_LIST } from '../../queries';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
    width: "100%"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px' },
  pre: { padding: '10px 30px', margin: '0' }
});

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick} disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const AssignNotificationTemp = ({
  t,
  data,
  isAssignEnable,
  handleClose,
  title,
  assignassignCameraToNotifyTemp,
  assignMutationLoading,
  handleSubmitFunc
}) => {

  const classes = useStyles();
  const commonClasses = commonStyles();
  const [notifyTemp, setNotifyTemp] = useState({});

  const { 
    loading: cameraLoading, 
    data: allcameraList, 
    error : cameraError,
  } = useQuery(GET_CAMERA_LIST, {
      variables: {
          organization: data?.organization_id.toString(),
          page:1,
          limit: 1000
      } 
  });

  useEffect(() => {
    setNotifyTemp({});
  }, [!isAssignEnable]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isAssignEnable}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('cameraName.label')}</TableCell>
                  <TableCell>{t('camt4Id.label')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!cameraLoading && !cameraError ?
                  allcameraList.getAllCameraList && allcameraList.getAllCameraList.cameras && allcameraList.getAllCameraList.cameras.length > 0 ?
                    allcameraList.getAllCameraList.cameras.map((row, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell>
                              <Grid className={commonClasses.column}>
                                <Grid>
                                  <Typography variant={'h6'} >
                                    {row.name}
                                  </Typography>
                                  <Grid>
                                    {
                                      row.schedules && row.schedules.length > 0 && row.schedules.map(sch => (
                                        sch.length > 0 && sch.map((el, i) => (
                                          <>
                                            <Typography variant="body2" style={{ height: '277' }}>
                                              <Checkbox
                                                checked={notifyTemp && notifyTemp[el.id] ? notifyTemp[el.id] : false}
                                                value={`${row.camera_id}-${[i]}`}
                                                onChange={(event) => {
                                                  if (event.target.checked) {
                                                    setNotifyTemp({ ...notifyTemp, [el.id]: { camera_id: row.camera_id, model_template: el.model_template.id } });
                                                  } else {
                                                    setNotifyTemp((prevData) => {
                                                      const newData = { ...prevData }
                                                      delete newData[el.id];
                                                      return newData;
                                                    })
                                                  }
                                                  //onHandleSelectMlTemplateRow(event, el.id, `${el.id}-${[i]}`);
                                                }}
                                              />
                                              {get(el, 'model_template.name', null)}
                                            </Typography>
                                          </>
                                        ))
                                      ))
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>{row.t4_cam_id}</TableCell>
                          </TableRow>
                        </>
                    )) :
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                      </TableCell>
                    </TableRow>
                  :
                  <TableRow>
                    <TableCell colSpan={2}>
                      {cameraLoading && !cameraError && <div style={{width: "100%", textAlign: "center"}}>
                        <CircularProgress size={24} color="primary" />
                      </div>}
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container justify={'space-between'}>
            <Button
              variant={'outlined'}
              onClick={handleClose}
            >
              {t('cancel.label')}
            </Button>
            <ButtonComponent
              t={t}
              loading={assignMutationLoading}
              onClick={() => {
                const allPyaload = [];
                for (let key in notifyTemp) {
                  if (notifyTemp[key])
                    allPyaload.push({
                      camera_id: notifyTemp[key].camera_id,
                      model_template: notifyTemp[key].model_template
                    });
                };
                handleSubmitFunc(data.id, allPyaload);
                //hadleUpdatMlTempInNotifiction(data.id, allPyaload);
              }}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AssignNotificationTemp
