import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TicketPanel from './components/TicketPanel';
import { TICKET_PRIORITY, TICKET_TYPE, TICKET_INQ_ISSUE, TICKET_DATA, IS_PARENT, 
TICKET_STATUS_EXPORT, TICKET_CATEGORIES } from '../../utils/constants'
import MenuItem from '@material-ui/core/MenuItem';
import { GET_VENDOR_LIST, GET_ALL_SERVICE_TICKETS, GET_INCIDENT_TYPE_LIST,
GET_INCIDENT_TYPE_GROUP_LIST, GET_SITE_BY_ORG_NAME, GET_ALL_REGION_LIST_DATA, GET_TICKET_CATEGORIES } from './queries';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../components/OutlinedSelect';

const useStyles = makeStyles(theme => createStyles({}));

const TicketPriorityOption = [
  TICKET_PRIORITY.LOW,
  TICKET_PRIORITY.MEDIUM,
  TICKET_PRIORITY.HIGH,
  TICKET_PRIORITY.URGENT,
  TICKET_PRIORITY.CRITICAL
]

const TicketVodacomOption = [
  TICKET_INQ_ISSUE.INQPROBLEM,
  TICKET_INQ_ISSUE.CUSTOMERPROBLEM
]

const TicketTypeOption = [
  TICKET_TYPE.MAILTICKET,
  TICKET_TYPE.CUSTOMERTICKET,
  TICKET_TYPE.CSOCMANAGERTICKET,
  TICKET_TYPE.INTERNALTICKET
]

const TicketStstusOption = [
  TICKET_STATUS_EXPORT.OPEN,
  TICKET_STATUS_EXPORT.CLOSED,
  TICKET_STATUS_EXPORT.ALL
]

const TicketCategories = [
  TICKET_CATEGORIES.NORMAL,
  TICKET_CATEGORIES.PARENT
]

function CustamizeExportData({
  open,
  handleClose,
  data,
  t,
  selectedCloseTicketsId,
  assigneeList,
  handleExportMultipleTickets,
  handleSelectAllExportTickets,
  handleSubmitExportTickets,
  startDate,
  endDate,
  exportTicketId,
  handleExportAllTickets,
  uniqueId
}) {
  const countryId = localStorage.getItem('countryId');
  const classes = useStyles();
  const [status, setStatus] = useState('');
  const [ticketCategory, setTicketCategory] = useState('');
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');
  const [searchTicketId, setSearchTicketId] = useState('');
  const [priority, setPriority] = React.useState('');
  const [siteName, setSiteName] = React.useState([]);
  const [organizationName, setOrganizationName] = React.useState('');
  const [assigneeData, setAssigneeData] = React.useState([]);
  const [regionData, setRegionData] = React.useState([]);
  const [incidentType, setIncidentType] = React.useState([]);
  const [incidentTypeGroup, setIncidentTypeGroup] = React.useState([]);
  const [incidentTypeList, setIncidentTypeList] = React.useState([]);
  const [vendorName, setVendorName] = React.useState('');
  const [ticketType, setTicketType] = React.useState('');
  const [vodacomProblem, setVodacomProblem] = React.useState('');
  const [reportTicketsSearched, setReportTicketsSearched] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [updatedTickets, setUpdatedTickets] = useState([]);
  const [allSitesData, setAllSitesData] = useState([]);
  const { data: vendorList } = useQuery(GET_VENDOR_LIST);
  const [getRegionList, { data: regionList }] = useLazyQuery(GET_ALL_REGION_LIST_DATA);
  const { data: categoryData } = useQuery(GET_TICKET_CATEGORIES);

  let organisationList = data && data.length && data.filter(item => {
    if (item.organizationName != null) {
      return item.organizationName
    }
  })
  let AllOrganisation = organisationList && organisationList.length && organisationList.map(item => item.organizationName)
  let AllOrganisationList = AllOrganisation && AllOrganisation.length && [...new Set(AllOrganisation)];

  const [getIncidentType, { data: incidenttype }] = useLazyQuery(
    GET_INCIDENT_TYPE_LIST
  );

  const [getIncidentTypeGroup, { data: incidenttypegroup }] = useLazyQuery(
    GET_INCIDENT_TYPE_GROUP_LIST
  );

  const [getSIteData] = useLazyQuery(
    GET_SITE_BY_ORG_NAME, {
      onError: error => {
        console.log('Error', error);
      },
      onCompleted: res => {
        setAllSitesData(res.getSiteDataByOrgName.data)
      }
    }
  )

  useEffect(() => {
    getIncidentType({
      variables: {
        ticketType: TICKET_DATA.SERVICETICKET
      }
    });
    getIncidentTypeGroup({
      variables: {
        ticketType: TICKET_DATA.SERVICETICKET
      }
    });
    getRegionList({
      variables: {
        countryId: countryId
      }
    })
  }, []);

  const [getRecentTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS, {
    onCompleted: data => {
      setLoadingData(false)
      setReportTicketsSearched(true);
      setUpdatedTickets(data.allServicetickets);
    }
  });

  const handleChange = event => {
    setPriority(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        priority: event.target.value,
        isParent: IS_PARENT.TRUE,
        startDate: startDate,
        endDate: endDate,
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        ...(regionData && regionData.id ? { region: regionData.id } : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(category ? { category: category } : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };
  const handleChangeVendor = event => {
    setVendorName(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        vendor: event.target.value.name,
        isParent: IS_PARENT.TRUE,
        startDate: startDate,
        endDate: endDate,
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
        ...(category ? { category: category } : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(priority ? { priority: priority } : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };
  
  const handleChangeTicketStatus = event => {
    setStatus(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        status: event.target.value != 'All' ? event.target.value : '',
        ...(priority ? { priority: priority } : {}),
        ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
        isParent: IS_PARENT.TRUE,
        startDate: startDate,
        endDate: endDate,
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(category ? { category: category } : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };

  const handleChangeTicketCategory = event => {
    setTicketCategory(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        ticketCategory: event.target.value,
        ...(priority ? { priority: priority } : {}),
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        isParent: IS_PARENT.TRUE,
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(search && search != ''? {search: search} : {}),
        startDate: startDate,
        endDate: endDate,
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(category ? { category: category } : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };

  const handleChangeTicketCategoryType = event => {
    setCategory(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        category: event.target.value,
        startDate: startDate,
        endDate: endDate,
        ...(priority ? { priority: priority } : {}),
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        isParent: IS_PARENT.TRUE,
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };

  const handleChangeTicketType = event => {
    setTicketType(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        ticketType: event.target.value === 'Email Tickets' ? 'mailTicket' : event.target.value === 'Customer Tickets'
          ? 'serviceTicket' : event.target.value === 'Call Ticket' ? 'csocTicket' : '',
        ...(priority ? { priority: priority } : {}),
        isParent: IS_PARENT.TRUE,
        startDate: startDate,
        endDate: endDate,
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(category ? { category: category } : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
        ...(regionData && regionData.id ? { region: regionData.id } : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };
  const handleChangeVodacomProblem = event => {
    setVodacomProblem(event.target.value);
    setLoadingData(true)
    getRecentTicketFilteredList({
      variables: {
        vodacomProblem: event.target.value,
        isParent: IS_PARENT.TRUE,
        startDate: startDate,
        endDate: endDate,
        ...(status ? { status: status != 'All' ? status : '' } : {}),
        ...(category ? { category: category } : {}),
        ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
        ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
        ...(regionData && regionData.id ? { region: regionData.id } : {}),
        ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
        ...(search && search != ''? {search: search} : {}),
        ...(organizationName ? { organizationName: organizationName } : {}),
        ...(ticketType ? {
          ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
            ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
        } : {}),
        ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
        ...(priority ? { priority: priority } : {}),
        ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
        ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
      }
    });
  };

  const handleChangeRegion = (event, newValue) => {
    if (newValue === null) {
      setRegionData([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    } else {
      setRegionData(newValue)
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          region: newValue.id,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    }
  };

  const handleChangeAssignee = (event, newValue) => {
    if (newValue === null) {
      setAssigneeData([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(category ? { category: category } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    } else {
      setAssigneeData(newValue)
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          assignee: newValue.email,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    }
  };
  const handleChangeIncidentType = (event, newValue) => {
    if (newValue.length === 0) {
      setIncidentType([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(category ? { category: category } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    } else {
      let incidentArray =newValue && newValue.length && newValue.map(data => {
        return data;
      });
      let incidentTypeData = newValue && newValue.length && newValue.map(data => {
        return data.name;
      });
      setIncidentTypeList(incidentTypeData)
      setIncidentType(incidentArray);
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          incidentList: incidentTypeData,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    }
  };
  const handleChangeIncidentTypeGroup = (event, newValue) => {
    if (newValue === null) {
      setIncidentTypeGroup([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
        }
      });
    } else {
      setIncidentTypeGroup(newValue);
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          incidentTypeGroup: newValue.name,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(category ? { category: category } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
        }
      });
    }
  };

  const handleChangeOrganisation = (event, newValue) => {
    if (newValue === null) {
      setOrganizationName('');
      setAllSitesData([])
      setSiteName([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(category ? { category: category } : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        }
      });
    } else {
      setOrganizationName(newValue);
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          organizationName: newValue,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(category ? { category: category } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
      getSIteData({
        variables: {
          organizationname: newValue
        }
      })
    }
  }

  const handleChangeSites = (event, newValue) => {
    if (newValue === null) {
      setSiteName([]);
      getRecentTicketFilteredList({
        variables: {
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(category ? { category: category } : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
        }
      });
    } else {
      setSiteName(newValue);
      setLoadingData(true)
      getRecentTicketFilteredList({
        variables: {
          siteName: newValue._id,
          isParent: IS_PARENT.TRUE,
          startDate: startDate,
          endDate: endDate,
          ...(status ? { status: status != 'All' ? status : '' } : {}),
          ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
          ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
          ...(category ? { category: category } : {}),
          ...(regionData && regionData.id ? { region: regionData.id } : {}),
          ...(search && search != ''? {search: search} : {}),
          ...(organizationName ? { organizationName: organizationName } : {}),
          ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
          ...(priority ? { priority: priority } : {}),
          ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
          ...(ticketType ? {
            ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
              ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
          } : {}),
          ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
          ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
        }
      });
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      if (search != '') {
        setLoadingData(true)
        getRecentTicketFilteredList({
          variables: {
            search: search,
            isParent: IS_PARENT.TRUE,
            startDate: startDate,
            endDate: endDate,
            ...(status ? { status: status != 'All' ? status : '' } : {}),
            ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
            ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
            ...(organizationName ? { organizationName: organizationName } : {}),
            ...(regionData && regionData.id ? { region: regionData.id } : {}),
            ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
            ...(category ? { category: category } : {}),
            ...(ticketType ? {
              ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
                ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
            } : {}),
            ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
            ...(priority ? { priority: priority } : {}),
            ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
            ...(ticketType ? {
              ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
                ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
            } : {}),
            ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
            ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
            ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
          }
        })
      }
    }
  }

  const handleSearchTicketId = (event) => {
    if (event.key === 'Enter') {
      if (searchTicketId != '') {
        setLoadingData(true)
        getRecentTicketFilteredList({
          variables: {
            ticketId: searchTicketId,
            isParent: IS_PARENT.TRUE,
            startDate: startDate,
            endDate: endDate,
            ...(status ? { status: status != 'All' ? status : '' } : {}),
            ...(category ? { category: category } : {}),
            ...(ticketCategory ? { ticketCategory: ticketCategory } : {}),
            ...(searchTicketId && searchTicketId != ''? {ticketId: searchTicketId} : {}),
            ...(search && search != ''? {search: search} : {}),
            ...(organizationName ? { organizationName: organizationName } : {}),
            ...(regionData && regionData.id ? { region: regionData.id } : {}),
            ...(assigneeData && assigneeData.email ? {assignee: assigneeData.email} : {}),
            ...(ticketType ? {
              ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
                ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
            } : {}),
            ...(vendorName && vendorName.name ? { vendor: vendorName.name } : {}),
            ...(priority ? { priority: priority } : {}),
            ...(vodacomProblem ? { vodacomProblem: vodacomProblem } : {}),
            ...(ticketType ? {
              ticketType: ticketType === 'Email Tickets' ? 'mailTicket' : ticketType === 'Customer Tickets'
                ? 'serviceTicket' : ticketType === 'Call Ticket' ? 'csocTicket' : ''
            } : {}),
            ...(incidentTypeList && incidentTypeList.length ? { incidentList: incidentTypeList} : {}),
            ...(siteName && siteName._id ? { siteName: siteName._id } : {}),
            ...(incidentTypeGroup && incidentTypeGroup.name ? { incidentTypeGroup: incidentTypeGroup.name } : {})
          }
        })
      }
    }
  }

  const handleChangesearch = (event) => {
    setSearch(event.target.value)
  }

  const handleChangesearchTicketId = (event) => {
    setSearchTicketId(event.target.value)
  }

  const handleCloseMultipleTicketsDummy = () => { };
  const handleTicketItemClickDummy = () => { };
  const handleMenuItemClickDummy = () => { };

  let excelReportData = reportTicketsSearched ? updatedTickets : data;

  const handleExportAll = () => {
    setRegionData([])
    setAssigneeData([])
    setTicketCategory('')
    setCategory('')
    setPriority('')
    setSearchTicketId('')
    setSearch('')
    setStatus('')
    setVendorName('')
    setTicketType('')
    setVodacomProblem('')
    setOrganizationName('')
    setAllSitesData([])
    setSiteName([])
    setReportTicketsSearched(false);
    setIncidentTypeGroup([])
    setIncidentType([])
    handleExportAllTickets(excelReportData)
  }

  const handleCloseAll = () => {
    setPriority('')
    setRegionData([])
    setAssigneeData([])
    setTicketCategory('')
    setCategory('')
    setSearch('')
    setStatus('')
    setSearchTicketId('')
    setAllSitesData([])
    setSiteName([])
    setVendorName('')
    setTicketType('')
    setOrganizationName('')
    setVodacomProblem('')
    setIncidentTypeGroup([])
    setIncidentType([])
    setReportTicketsSearched(false);
    handleClose()
  }
  const handleSubmitExportAllTickets = () => {
    setPriority('')
    setRegionData([])
    setAssigneeData([])
    setTicketCategory('')
    setCategory('')
    setSearch('')
    setSiteName([])
    setStatus('')
    setSearchTicketId('')
    setAllSitesData([])
    setVendorName('')
    setTicketType('')
    setVodacomProblem('')
    setOrganizationName('')
    setIncidentTypeGroup([])
    setIncidentType([])
    setReportTicketsSearched(false);
    handleSubmitExportTickets()
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleCloseAll}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('exportSelectedTicket.label')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Input
                  type={'text'}
                  placeholder={t('search.label')}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  value={search}
                  onChange={event => handleChangesearch(event)}
                  onKeyPress={event => handleSearch(event)}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Input
                  type={'text'}
                  placeholder={t('enterTicketId.label')}
                  style={{ width: '100%' }}
                  color={'secondary'}
                  value={searchTicketId}
                  onChange={event => handleChangesearchTicketId(event)}
                  onKeyPress={event => handleSearchTicketId(event)}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Priority"}
                  handleChange={handleChange}
                  val={priority}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={TicketPriorityOption ? TicketPriorityOption : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Ticket Type"}
                  handleChange={handleChangeTicketType}
                  val={ticketType}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={TicketTypeOption ? TicketTypeOption : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select vodacom problem"}
                  handleChange={handleChangeVodacomProblem}
                  val={vodacomProblem}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={TicketVodacomOption ? TicketVodacomOption : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Vendor"}
                  handleChange={handleChangeVendor}
                  val={vendorName.name}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={vendorList && vendorList.vendor && vendorList.vendor.length ? vendorList.vendor : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Ticket Status"}
                  handleChange={handleChangeTicketStatus}
                  val={status}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={TicketStstusOption ? TicketStstusOption : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  id="combo-box-demo3"
                  value={regionData}
                  options={regionList ? regionList.getRegionListByCountry : []}
                  getOptionLabel={option => (option ? option.name : '')}
                  onChange={(event, newValue) => {
                    handleChangeRegion(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Region"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  id="combo-box-demo4"
                  value={organizationName}
                  options={AllOrganisationList && AllOrganisationList.length ? AllOrganisationList : []}
                  getOptionLabel={option => option}
                  onChange={(event, newValue) => {
                    handleChangeOrganisation(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Parent Organisation"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  id="combo-box-demo4"
                  value={siteName}
                  options={allSitesData && allSitesData.length ? allSitesData : []}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    handleChangeSites(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Child Organisation"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  id="combo-box-demo3"
                  value={incidentTypeGroup}
                  options={incidenttypegroup ? incidenttypegroup.incidenttypegroup : []}
                  getOptionLabel={option => (option ? option.name : '')}
                  onChange={(event, newValue) => {
                    handleChangeIncidentTypeGroup(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Incident Group"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  multiple
                  id="combo-box-demo4"
                  value={incidentType}
                  options={incidenttype ? incidenttype.incidenttype : []}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    handleChangeIncidentType(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Incident Type"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <Autocomplete
                  id="combo-box-demo3"
                  value={assigneeData}
                  options={assigneeList ? assigneeList.assignee : []}
                  getOptionLabel={option => (option ? option.email : '')}
                  onChange={(event, newValue) => {
                    handleChangeAssignee(event, newValue);
                  }}
                  style={{ width: "100%", border: "1px solid #ebebeb" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={false}
                      label={"Select Assignee"}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Ticket Type"}
                  handleChange={handleChangeTicketCategory}
                  val={ticketCategory}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={TicketCategories ? TicketCategories : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{
                  ...marginGenerator('mt-5')
                }}>
                <OutlinedSelect
                  label={"Select Ticket Category"}
                  handleChange={handleChangeTicketCategoryType}
                  val={category.name}
                  selectStyle={{ ...paddingGenerator('p-8') }}
                  styleOverrides={{ width: '100%', marginBottom: 10 }}
                  options={categoryData && categoryData.categories.length ? categoryData.categories : []}
                  id="standard-select"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {loadingData ? <CircularProgress size={20} color="primary" /> : (
              <TicketPanel
                ticketList={excelReportData}
                type={'openTickets'}
                t={t}
                uniqueId={uniqueId}
                filterList={[]}
                handleTicketItemClick={handleTicketItemClickDummy}
                handleMenuItemClick={handleMenuItemClickDummy}
                exportTicketId={exportTicketId}
                handleExportMultipleTickets={handleExportMultipleTickets}
                handleSelectAllExportTickets={handleSelectAllExportTickets}
              />
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleCloseAll}
            color="default"
            variant="contained"
            size="small">
            {t('cancel.label')}
          </Button>
          <Button
            onClick={handleExportAll}
            variant="contained"
            color="primary"
            size="small">
            {t('exportAll.label')}
          </Button>
          <Button
            onClick={handleSubmitExportAllTickets}
            variant="contained"
            color="primary"
            size="small">
            {t('exportCustom.label')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(CustamizeExportData);
