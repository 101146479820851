import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { dateFormatterLLL } from '../../../../../../utils';
import { Button } from '@material-ui/core';
import SignIcon from '../../../../../../assets/icons/sign-pen.png';

const ExpenseClaimPanelItem = ({
    t,
    data,
    handleMenuItemClick,
    handleAddExpenseClaim,
    menuItems,
    allowConfigure,
    users
}) => {
    const commonClasses = commonStyles();
    const [name, setName] = useState("");

    // React.useEffect(() => {
    //     if(users && users.length){
    //         const names = data.payee && data.payee.map(itm => {
    //             const existRecord = users && users.find(usr => usr.id === itm);
    //             return existRecord ? existRecord.payee : "";
    //         });
    //         setPayee(names.join(", "));
    //     }
    // }, [users]);


    return (
        <>
            <TableRow key={data.id}>
            {/* <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {dateFormatterLLL(data.name)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell> */}

                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.name ? data.name: "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>

                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'} >
                                {data.designation ? data.designation : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>


                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.voucher? data.voucher : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>

                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.tel? data.tel : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>

                {/* <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.recommendationSign ==='' ? 'Pending' : "Completed"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.authorisationSign ==='' ? 'Pending' : "Completed"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>

                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.accountType ? data.accountType : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                 */}
                
                {allowConfigure &&
                    <TableCell>
                        {/* <IconButton onClick={() => handleMenuItemClick("sign", data)}>
                            <img src={SignIcon} width="26" height={26}/>
                        </IconButton> */}
                        <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                            <EditIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

ExpenseClaimPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default withTranslation()(ExpenseClaimPanelItem);
