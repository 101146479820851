import React, { Fragment } from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/inq_logo_primary.png';

const styles = StyleSheet.create({
    container: {
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
    },
    leftColumn: {
        flexDirection: 'column',
        width: 360,
        paddingTop: 30,
        '@media max-width: 400': {
          width: '100%',
          paddingRight: 0,
        },
        '@media orientation: landscape': {
          width: 200,
        },
    },
    rightContainer: {
        flexDirection: 'column',
        paddingTop: 30,
        paddingLeft: 0,
        paddingBottom: 0,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    logo: {
        width: 50,
        height: 50
    },
});
const InvoiceNo = ({ invoiceCompanyDetails }) => (
    <Fragment>
        <View style={styles.container}>
            <View style={styles.leftColumn}>
                <Image
                    src={logo}
                    style={styles.logo}
                />
            </View>
            <View style={styles.rightContainer}>
                <Text>{invoiceCompanyDetails.name}</Text>
                <Text>{invoiceCompanyDetails.addressLine1}</Text>
                <Text>{invoiceCompanyDetails.addressLine2}</Text>
                <Text>{invoiceCompanyDetails.email}</Text>
            </View>
        </View>
    </Fragment>
);
export default InvoiceNo