import React from 'react';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../utils';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button 
      color="primary"
      onClick={onClick}
      >
          {loading && <CircularProgress size={19} />}
          {!loading && <DeleteIcon color="secondary"/> }
      </Button>
  );
}

const ShayonaConfigurationBrandItem = ({
    key,
    t,
    data,
    handleEnableAlertFeedBacksDialogue,
    deleteBrandLoading,
    selecteIdForDelete
}) => {
  const commonClasses = commonStyles();
  return (
    <TableRow key={data.id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data?.name ?? '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                  {data ? dateFormatterLLL(data.created_on) : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    {/* <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    {data? data.allowed_bags: '-'} 
                </Typography>
            </Grid>
        </Grid>
    </TableCell> */}
    <TableCell>
    <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                    coming soon
                </Typography>
            </Grid>
        </Grid>
        {/* <Grid className={commonClasses.column}>
            <Grid>
            <Button 
            color="primary"
            onClick={()=>  handleEnableAlertFeedBacksDialogue(data)}
            variant="outlined"
            >
             Edit
            </Button>
            <ButtonComponent 
                t={t}
                loading={selecteIdForDelete === data.category_id ? deleteBrandLoading: false}
                //onClick={() =>handleSubmitOfDeleteTruck(data.category_id)}
            />
            </Grid>
        </Grid> */}
    </TableCell>
</TableRow> 
  )
}

export default ShayonaConfigurationBrandItem