import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function DataSheetTable({id,columns, data}) {
  const classes = useStyles();
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" id={id}>
        <TableHead>
            <TableRow>
                {columns.map(itm => <TableCell style={{paddingLeft: 12}} component="th">{itm.label}</TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, indx) => (
            <TableRow key={`${row.name}-${indx}`}>
                {columns.map(itm => <TableCell style={{paddingLeft: 12}} component="td">{row[itm.name]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}