import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import Autocomplete from '../../../../../../../components/Autocomplete';
import { TextField } from '@material-ui/core';
import { IconButton, Typography } from '@material-ui/core';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const initFields = { title: "", dateTime: null, venue: "", facilitator: "", attendees: [], description: "", id: null };
function MeetingRequestModal({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateMeetingSubmit,
    subscriptionUserData
}) {

    const classes = useStyles();
    const [meetingFormState, setMeetingFormState] = useState({ ...initFields });

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setMeetingFormState({ ...initFields });
            handleClose();
        }
    }, [modalState]);

    React.useEffect(() => {
        if (data && data.id) {
            const attendees = subscriptionUserData.filter(itm => data.attendees.includes(itm.id));
            setMeetingFormState({...data, attendees, dateTime: data.dateTime });
        }
    }, [data]);

    const handleChange = (value, field) => {
        setMeetingFormState({ ...meetingFormState, [field]: value });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
                <HrHeader />
            </DialogTitle>
            <DialogContent>
            <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Create Meeting</Typography>
                        </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Meeting Title"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            onChange={(evt) => handleChange(evt.target.value, "title")}
                            value={meetingFormState.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeSelector
                            styleOverrides={{width: '100%'}}
                            inputStyle={{ minWidth: '100%', width: '100%' }}
                            label={"Meeting Date & Time"}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={meetingFormState.dateTime}
                            handleChange={newDate => handleChange(newDate, "dateTime")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Venue"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            onChange={(evt) => handleChange(evt.target.value, "venue")}
                            value={meetingFormState.venue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            type={'text'}
                            placeholder={"Facilitator"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            onChange={(evt) => handleChange(evt.target.value, "facilitator")}
                            value={meetingFormState.facilitator}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            styleOverrides={{width: "100%"}}
                            handleChange={(evt, newVal) => handleChange(newVal, "attendees")}
                            val={meetingFormState.attendees}
                            options={subscriptionUserData}
                            label={"Attendees"}
                            size="small"
                            multiple={true}
                            onInputChange={evt => {}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Description"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            onChange={(evt) => handleChange(evt.target.value, "description")}
                            value={meetingFormState.description}
                            rowsMax={4}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setMeetingFormState({ ...initFields });
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if(!meetingFormState.attendees.length || !meetingFormState.dateTime || !meetingFormState.title){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateMeetingSubmit(meetingFormState);
                        }}
                        loading={modalState.btnLoading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

MeetingRequestModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMeetingSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(MeetingRequestModal);