import React from 'react';
import PropTypes from 'prop-types';
import DateTimeSelector from '../DateTimeSelector';
import { Input } from '@material-ui/core';
import OutlinedSelect from '../OutlinedSelect';

const getFilterField = (filterItem) => {
    if (filterItem.type && filterItem.type === 'input') {
        return (
            <DateTimeSelector
                label={filterItem.label ? filterItem.label : ""}
                dateFormat={"yyyy-MM-dd HH:mm"}
                value={filterItem.val}
                handleChange={filterItem.handleChange}
                styleOverrides={filterItem.styleOverrides}
                inputStyle={{ minWidth: 220, padding: 10 }}
                disableFuture={true}
            />
        );
    } else if (filterItem.type && filterItem.type === 'text') {
        return (
            <Input
                placeholder={filterItem.label ? filterItem.label : ""}
                value={filterItem.val}
                handleChange={filterItem.handleChange}
                style={filterItem.styleOverrides}
            />
        );
    } else {
        return (
            <OutlinedSelect
                val={filterItem.val}
                label={filterItem.label}
                handleChange={filterItem.handleChange}
                IconComponent={filterItem.iconComponent}
                options={filterItem.options}
                styleOverrides={filterItem.styleOverrides || null}
                disabled={filterItem.disabled || false}
            />
        );
    }
}

const FiltersBar = ({
  filter
}) => {
  
  return (
    <>
        {getFilterField(filter)}
    </>
  );
};

FiltersBar.prototypes = {
  filter: PropTypes.object
};

export default FiltersBar;
