import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Stepper, Step, StepLabel } from '@material-ui/core';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step2Cloud from './Steps/Step2Cloud';
import { renderText } from '../../displayComponent';
import Step3New from './Steps/Step3New';
import Step4New from './Steps/Step4New';

const CreateConnection = () => {
  const [state, setState] = useState({
    data: {},
    getItems: [],
    errors: {},
    currentStep: 0
  });
  const [subnet, setSubnet] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [client, setClient] = useState('');
  const [destinationPodId, setDestinationPodId] = useState('');

  console.log(state.data);

  const StepperStep = [
    { label: 'Select Source' },
    { label: 'Select Destination' },
    { label: 'Connection Details' },
    { label: 'Summary' }
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };

  const getClientName = clientName => {
    setClient(clientName);
  };

  const fcrChanged = async () => {
    const clientName = localStorage.getItem('clientName');
    setClient(clientName);
  };
  const getSubnet = subnet => {
    setSubnet(subnet);
  };

  const getDestinationPodId = async podId => {
    setDestinationPodId(podId);
  };

  useEffect(() => {
    fcrChanged();
  }, []);

  const getStepsItem = steps => {
    switch (steps) {
      case 0:
        return <Step1 fcrChanged={fcrChanged} handleNext={handleNext} />;
      case 1:
        return localStorage.getItem('destination_type') === 'Cloud' ? (
          <Step2Cloud />
        ) : (
          <Step2
            handlePrev={handlePrev}
            fcrChanged={fcrChanged}
            handleNext={handleNext}
            getClientName={getClientName}
            getDestinationPodId={getDestinationPodId}
          />
        );
      case 2:
        return (
          <Step3New
            handlePrev={handlePrev}
            handleNext={handleNext}
            setState={setState}
            client={client}
            getSubnet={getSubnet}
            destinationPodId={destinationPodId}
          />
        );

      case 3:
        return (
          <Step4New
            formData={state.data}
            subnet={subnet}
            handlePrev={handlePrev}
          />
        );
      default:
        return <Step1 />;
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const token = localStorage.getItem('syncomToken');
  //       const org = localStorage.getItem('orgId');
  //       const res = await axios.get(`${FABRIC_URL}port?org_code=${org}`, {
  //         headers: { accessToken: `${token}` }
  //       });
  //       const getData = res.data.result;
  //       console.log(getData);
  //       setState({ getData });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  //   const fetchLocalStorage = async () => {
  //     try {
  //       const get = localStorage.getItem('datacenter_by_id');
  //       setState({ get });
  //     } catch (err) {}
  //   };
  //   fetchLocalStorage();
  // }, []);

  return (
    <>
      <Grid container direction="column">
        <hr />
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>
                {renderText({
                  label: 'Create New Connection'
                })}
              </Box>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{
                  padding: '10px',
                  backgroundColor: '#f8f8f8'
                }}>
                {StepperStep.map((item, i) => (
                  <Step key={i}>
                    <StepLabel>{item.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Box
            p={2}
            mb={2}
            mt={2}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            {getStepsItem(activeStep)}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
CreateConnection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default CreateConnection;
