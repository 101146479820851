import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomTabs from '../../../../components/CustomTabs';
import CustomLiveCameraPanel from './CustomLiveCameraPanel';
import LiveCameraViewURLPanel from './LiveCameraViewURLPanel';
import { GET_CAMERA_LIST } from '../../queries';
import { useLazyQuery } from '@apollo/react-hooks';
import axios from 'axios';
import { LIVE_CAM_STREAM_URL } from '../../../../utils/constants';
import Message from '../../../../components/Message';
import LiveCamPlayerModal from './LiveCamPlayerModal';
import { isEmpty } from 'lodash-es';
import LiveMultipleCameraPlayerModal from './LiveMultipleCameraPlayerModal';
import { Box, Typography } from '@material-ui/core';

const EdgeAILiveCamPanel = ({
    t,
    data,
    handleCreateURLClick,
    viewCustomLiveCam,
    liveCamURL,
    subActiveIndex,
    selectedPath
}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [liveCameraList, setLiveCameraList] = useState([]);
    const [multipleCameraPlayer, setMultipleCameraPlayer] = useState({});
    const [liveCamStreamModalOpen, setLiveCamStreamModalOpen] = useState(false);
    const [liveCamStreamURL, setLiveCamStreamURL] = useState(null);
    const [liveStreamCamera, setLiveStreamCamera] = useState(null);
    const [liveMultiCamStreamModalOpen, setLiveMultiCamStreamModalOpen] = useState(false);
    const [liveMultiCamStreamURLs, setLiveMultiCamStreamURLs] = useState([]);
    const [liveMultiCamLoading, setLiveMultiCamLoading] = useState(false);

    const handleOpenLiveCamStreamModal = (camera, type) => {
        setLiveCamStreamModalOpen(true);
        setLiveStreamCamera(camera);
        if (type === 'm3') {
            setLiveCamStreamURL(`${LIVE_CAM_STREAM_URL}streams/${camera?.camera_id}.m3u8`);
        } else {
            setLiveCamStreamURL(liveCamURL + camera?.node + camera.t4_cam_id);
        }
    }

    const handleMultipleCameraPlayer = async (type) => {

        if (!Object.keys(multipleCameraPlayer).length) {
            Message.error('Please select camera first!')
            return null;
        }
        const selectedCameras = liveCameraList.filter(cam => {
            return Object.keys(multipleCameraPlayer).includes(cam.camera_id.toString());
        });
        setLiveMultiCamLoading(true);
        const urls = [];
        for (let i = 0; i < selectedCameras.length; i++) {
            let camera = selectedCameras[i];
            const urlRes = await handlePlayMultipleStream(camera, type).then(res => {
                return res;
            });
            urls.push(urlRes);
        }
        if (type === 'start') {
            console.log("urls: ", urls);
            setLiveMultiCamLoading(false);
            setLiveMultiCamStreamURLs(urls);
            setLiveMultiCamStreamModalOpen(true);
        }
    }

    const handlePlayMultipleStream = async (camera, type) => {
        if (isEmpty(camera)) {
            Message.error("Camera not found!");
            return null;
        }
        const liveCam = await axios.post(`${LIVE_CAM_STREAM_URL}rest/v2/broadcasts/${camera?.camera_id}/${type}`, {}, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res).catch(err => err);
        if (liveCam?.data) {
            if (type === 'start') {
                const { success, message } = liveCam?.data;
                if (success || message.includes("Stream is already active")) {
                    return `${LIVE_CAM_STREAM_URL}streams/${camera?.camera_id}.m3u8`;
                } else if (!success && message.includes("No Stream Exists with")) {
                    return liveCamURL + camera?.node + camera.t4_cam_id;
                }
            }
        } else {
            if (type === "start") {
                return liveCamURL + camera?.node + camera.t4_cam_id;
            }
        }
    }

    const handlePlayStream = async (camera, type) => {
        if (isEmpty(camera)) {
            Message.error("Camera ID not found!");
            return null;
        }
        const liveCam = await axios.post(`${LIVE_CAM_STREAM_URL}rest/v2/broadcasts/${camera?.camera_id}/${type}`, {}, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res).catch(err => err);
        if (liveCam?.data) {
            if (type === 'start') {
                const { success, message } = liveCam?.data;
                if (success || message.includes("Stream is already active")) {
                    handleOpenLiveCamStreamModal(camera, 'm3');
                } else if (!success && message.includes("No Stream Exists with")) {
                    handleOpenLiveCamStreamModal(camera, 'live');
                } else {
                    Message.error("Camera Stream URL not found!");
                    return null;
                }
            }
        } else {
            if (type === "start") {
                handleOpenLiveCamStreamModal(camera, 'live');
            }
        }
    }

    const [getCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            if (data.getAllCameraList && data.getAllCameraList.cameras && data.getAllCameraList.cameras.length > 0) {
                let cameras = data.getAllCameraList.cameras.filter((itm) => {
                    if (itm.status === "active" && !itm.is_deleted) {
                        return itm;
                    }
                });
                setLiveCameraList(cameras);
            }
        }
    });

    useEffect(() => {
        if (data) {
            return getCameraList({
                variables: {
                    organization: data.organizationId,
                    page: 0,
                    limit: 20
                }
            });
        }
    }, [data]);

    const onInputChange = (val) => {
        let organization = data.organizationId;
        setLiveCameraList([]);
        getCameraList({ variables: { organization, page: 1, limit: 20, search: val } });
    }

    const handleChangeMultiCamera = (evt) => {
        let newSt = { ...multipleCameraPlayer };
        if (!evt.target.checked) {
            delete newSt[evt.target.value];
        } else {
            newSt = { ...newSt, [evt.target.value]: true }
        }
        setMultipleCameraPlayer({ ...newSt });
    }

    let configTabs = [
        {
            label: t('liveView.label'),
            children: (
                <>
                    <LiveCameraViewURLPanel
                        t={t}
                        data={data}
                        handlePlayStream={handlePlayStream}
                        cameraList={liveCameraList}
                        liveCamURL={liveCamURL}
                        handleChangeMultiCamera={handleChangeMultiCamera}
                        multipleCameraPlayer={multipleCameraPlayer}
                        onInputChange={onInputChange}
                        liveMultiCamLoading={liveMultiCamLoading}
                        handleMultipleCameraPlayer={handleMultipleCameraPlayer}
                    />
                </>
            )
        }
    ];

    if (data.liveCamUrls && data.liveCamUrls.length > 0 && viewCustomLiveCam) {
        configTabs.push({
            label: t('liveCam.label'),
            children: (
                <>
                    <CustomLiveCameraPanel
                        t={t}
                        data={data}
                        handleCreateURLClick={viewCustomLiveCam ? handleCreateURLClick : null}
                    />
                </>
            )
        });
    } else if (viewCustomLiveCam) {
        configTabs.push({
            label: t('liveCam.label'),
            children: (
                <>
                    <CustomLiveCameraPanel
                        t={t}
                        data={data}
                        handleCreateURLClick={handleCreateURLClick}
                    />
                </>
            )
        });
    }

    return (
        <div style={{ position: 'relative' }}>
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            /> */}
            {selectedPath === "LiveCam" && <> {configTabs[subActiveIndex].children}</>}
            {liveStreamCamera &&
                <LiveCamPlayerModal
                    open={liveCamStreamModalOpen}
                    url={liveCamStreamURL}
                    handleClose={(evt) => {
                        handlePlayStream(liveStreamCamera, 'stop');
                        setLiveCamStreamURL(null);
                        setLiveCamStreamModalOpen(false);
                        setLiveStreamCamera(null);
                    }}
                />
            }
            <LiveMultipleCameraPlayerModal
                open={liveMultiCamStreamModalOpen}
                urls={liveMultiCamStreamURLs}
                handleClose={(evt) => {
                    handleMultipleCameraPlayer('stop');
                    setMultipleCameraPlayer({})
                    setLiveMultiCamStreamURLs([]);
                    setLiveMultiCamLoading(false);
                    setLiveMultiCamStreamModalOpen(false);
                }}
            />
        </div>
    );
};

EdgeAILiveCamPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default React.memo(EdgeAILiveCamPanel);
