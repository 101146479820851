import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';

function ButtonComponent(props) {
    const { t, onClick, loading, data } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading || data && data.notes !== null}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function MeetingNotesModal({
    t,
    open,
    data,
    handleClose,
    handleAddNotesSubmit
}) {
    const [meetingNotes, setMeetingNotes] = useState("");

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="sensor-template-modal"
            aria-describedby="sensor-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="sensor-template-title">
                {data && data.notes !== null ? t('viewMeeting.label') : t('addMeeting.label') }
            </DialogTitle>
            
            <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <TextField
                            type="textarea"
                            placeholder="Description"
                            variant="standard"
                            fullWidth={true}
                            disabled={data && data.notes !== null}
                            multiline
                            rows={5}
                            onChange={(evt) => setMeetingNotes(evt.target.value)}
                            value={data && data.notes !== null ? data.notes : meetingNotes}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setMeetingNotes("");
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        data = {data}
                        onClick={() => {
                            handleAddNotesSubmit(meetingNotes);
                        }}
                        loading={false}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

MeetingNotesModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleAddNotesSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(MeetingNotesModal);