import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../theme/commonStyles';
import clsx from 'clsx';
import { VIDEO_STATUS } from '../../../../utils/constants';
import { paddingGenerator } from '../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  statusContainer: {
    height: '15px',
    borderRadius: '16px',
    alignItems: 'center',
    display: 'flex'
  },
  provStatus: {
    background: theme.palette.warning.main
  },
  activeStatus: {
    background: theme.palette.success.main
  },
  failedStatus: {
    background: theme.palette.error.main
  },
  textStyle: {
    color: theme.palette.common.white,
    fontSize: '14px'
  }
}));

const VideoStatus = ({ t, status }) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    status = status ? status.toLowerCase() : "queued";
    let statusClasses, label;
    if(status === VIDEO_STATUS.QUE){
      statusClasses = classes.provStatus;
      label = t('queued.label');
    }else if(status === VIDEO_STATUS.PROCESSING){
      statusClasses = classes.provStatus;
      label = t('processing.label');
    }else if(status === VIDEO_STATUS.PROCESSED){
      statusClasses = classes.activeStatus;
      label = t('processed.label');
    }
    return (
        <div
        className={clsx(
            classes.statusContainer,
            statusClasses
        )}
        style={{
            ...paddingGenerator('pt-8'),
            ...paddingGenerator('pb-6'),
            ...paddingGenerator('pl-12'),
            ...paddingGenerator('pr-12')
        }}>
          <Typography
              className={clsx(commonClasses.buttonTextSmall, classes.textStyle)}
              variant={'body1'}>
              {label}
          </Typography>
        </div>
    );
};

VideoStatus.propTypes = {
  status: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default VideoStatus;
