import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import IconButton from '@material-ui/core/IconButton';
import { marginGenerator } from '../../../../theme/utils';
import Slider from '@material-ui/core/Slider';
import { GET_CAMERA_LIST, GET_EVENT_IMAGES, GET_EDGE_AI_VIEWERS_VIDEOS } from '../../queries';
import { CONVERT_VIEWERS_IMAGES_VIDEO } from '../../mutations';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import { convertLocalToUTCDate, dateFormatterDDMMMYYYYHHSS, dateFormatterLLLHHMMSS, evaluate } from '../../../../utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import Canvas from '../../../../components/Canvas';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import Autocomplete from '../../../../components/Autocomplete';
import VideoListModal from './VideoListModal';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    controls: {
        textAlign: "center",
        marginTop: 15,
        display:"inline-block"
    },
    input: {
        padding : "9px 22px",
        marginLeft: "30px"
    },
    imgSlider: {
        width: "95%",
        marginLeft: 20
    },
    sliderCard: {
        width: "100%"
    },
    sliderGrid: {
        marginBottom: 20
    },
    searcBtn: {
        paddingBottom: 9,
        paddingTop: 10
    },
    img: {
        width: "100%",
        height: "100%"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading, disabled } = props;
    const classes = useStyles();
    return (
        <Button
        variant="outlined" 
        size="medium" 
        color="primary"
        className={classes.searcBtn}
        onClick={onClick} 
        disabled={loading || disabled} >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function roundOF2(num){
    return Math.round(num * 100) / 100;
}

const intervalList = [5, 10, 15, 20, 25, 30, 40, 50, 60];
const objectColor = {'bicycle': "#E52B50", 'person': "green", 'truck': "blue", 'motorbike': "#FDEE00", 'car': "red", 'bus': "#FF7E00", 'cell phone': "#3DDC84"};

function valuetext(value) {
    return `${value}°C`;
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const trackingOtherFilterOpt = [
    {name: "Equal to (=)", id: "=="},
    // {name: "Between (>= && <=)", id: "between"},
    {name: "Greater then or equal to (>=)", id: ">="},
    {name: "Greater then (>)", id: ">"},
    {name: "Less then or equal to (<=)", id: "<="},
    {name: "Less then (<)", id: "<"}
];

const EdgeAIEventImagesViewers = ({ 
    t,
    data
}) => {

    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const [cameraId, setCameraId] = useState("");
    const [cameraList, setCameraList] = useState([]);
    const [startDateTime, setStartDateTime] = useState(null);
    const [threshold, setThreshold] = useState(0);
    const [timeInterval, setTimeInterval] = useState("");
    const [imageList, setImageList] = useState([]);
    const [activeImage, setActiveImage] = useState("");
    const [localTimestamp, setLocalTimestamp] = useState("");
    const [timer, setTimer] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [recordNotFound, setRecordNotFound] = useState(false);
    const [canvasProp, setCanvasProp] = useState({height: 600, width: 1080});
    const [firstDrawStatus, setFirstDrawStatus] = useState(true);
    const [thresholdCond, setThresholdCond] = useState("");
    const [videoLoading, setVideoLoading] = useState(false);
    const [showVideoListModal, setShowVideoListModal] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [selectedCameraName, setSelectedCameraName] = useState("");
    const [imagesUrlList, setImagesUrlList] = useState([]);
    const [converterLoading, setConverterLoading] = useState(false);

    const [getCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const camList = (data.getAllCameraList && data.getAllCameraList.cameras) ? data.getAllCameraList.cameras.map(item => {
                return {name: item.name, id: item.camera_id};
            }) : [];
            setCameraList(camList);
        }
    });

    const getPerObjectCount = (objects) => {
        let objCounts = {}; 
        if(objects && objects.length){
            let objArr = objects.map(objVal => {
                return objVal.name;
            });
            objArr.forEach(function (x) { objCounts[x] = (objCounts[x] || 0) + 1; });
        }
        return objCounts;
    }

    const [getEventImages] = useLazyQuery(GET_EVENT_IMAGES, {
        fetchPolicy: 'no-cache',
        onError: err => {
            setBtnLoading(false);
            console.log("Error: ", err);
        },
        onCompleted: data => {
            console.log("data.getEventImages: ", data.getEventImages);
            setBtnLoading(false);
            if(data.getEventImages && data.getEventImages.length > 0){
                let viewersEvents = data.getEventImages;
                if(threshold){
                    viewersEvents = data.getEventImages.map(itm => {
                        if(itm.objects){
                            let filteredObjects = itm.objects.filter(function (e) {
                                if(e.confidence){
                                    let with2Decimals = e.confidence.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
                                    if(thresholdCond){
                                        if(evaluate(parseFloat(with2Decimals), parseFloat(threshold), thresholdCond)){
                                            return e;
                                        }
                                    }else{
                                        if(parseFloat(with2Decimals) == threshold){
                                            return e;
                                        }
                                    }
                                }
                            });
                            itm.objects = filteredObjects;
                        }
                        return itm;
                    });
                }
                setImagesUrlList(viewersEvents.map(itm => itm.url));
                setRecordNotFound(false);
                setFirstDrawStatus(true);
                setImageList(viewersEvents);
                let firstActImgData = {...viewersEvents[0]};
                firstActImgData.objectCount = getPerObjectCount(viewersEvents[0].objects ? viewersEvents[0].objects : null);
                setActiveImage(firstActImgData);
                if(viewersEvents[0].time){
                    let time = viewersEvents[0].time;
                    time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                    setLocalTimestamp(time);
                }
            }else{
                setRecordNotFound(true);
                setImageList([]);
                setActiveImage("");
                setLocalTimestamp("");
                setImagesUrlList([]);
            }
        }
    });

    const [getAllViewersVideos] = useLazyQuery(GET_EDGE_AI_VIEWERS_VIDEOS, {
        fetchPolicy: 'no-cache',
        onError: err => {
            setVideoLoading(false);
            console.log("Error: ", err);
        },
        onCompleted: videoRes => {
            if(videoRes.getAllViewersVideos){
                videoRes.getAllViewersVideos.sort(function(a,b){
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                setVideoList(videoRes.getAllViewersVideos.map(itm => {
                    let tmpObj = {...itm};
                    let keyArr = itm.key ? itm.key.split("-") : [];
                    if(keyArr.length){
                        tmpObj.startTime = dateFormatterLLLHHMMSS(new Date(keyArr[1]*1000));
                        if(keyArr[2]){
                            let key2 = keyArr[2].split(".")[0];
                            tmpObj.endTime = dateFormatterLLLHHMMSS(new Date(key2*1000));
                        }
                    }
                    tmpObj.createdAt = dateFormatterLLLHHMMSS(new Date(itm.createdAt));
                    tmpObj.size = formatBytes(itm.size);
                    return tmpObj; 
                }));
            }
            setVideoLoading(false);
        }
    });

    const [convertImagesToVideo] = useMutation(CONVERT_VIEWERS_IMAGES_VIDEO, {
        onError: err => {
            setConverterLoading(false);
        },
        onCompleted: res => {
            setConverterLoading(false);
        }
    });
    
    useEffect(() => {
        if(data){
            let organization = data.organizationId;
            getCameraList({variables: {organization, page: 1, limit: 20}});
        }
    }, [data]);

    const handleSubmit = () => {
        if(!cameraId){
            return false;
        }
        setImageList([]);
        setActiveImage("");
        setLocalTimestamp("");
        setBtnLoading(true);
        let startTime = new Date(startDateTime);
        let endTime = new Date(startDateTime);
        endTime.setMinutes(endTime.getMinutes() + timeInterval);
        startTime = new Date(convertLocalToUTCDate(startTime)).getTime()/1000;
        endTime = new Date(convertLocalToUTCDate(endTime)).getTime()/1000;
        return getEventImages({variables: { cameraId: cameraId.toString(), startTime, endTime, lastEvent: false, viewers: true }});
    }

    const handleChange = (event, newValue) => {
        if(timer){
            handlePause();
        }
        setFirstDrawStatus(true);
        setActiveIndex(newValue);
        if(imageList.length > newValue && imageList[newValue]){
            let firstActImgData = {...imageList[newValue]};
            firstActImgData.objectCount = getPerObjectCount(imageList[newValue].objects ? imageList[newValue].objects : null);
            setActiveImage(firstActImgData);
            if(imageList[newValue].time){
                let time = imageList[newValue].time;
                time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                setLocalTimestamp(time);
            }
        }
    };

    const handleAutoPlay = () => {
        let newIndex = activeIndex;
        let tempTimer = setInterval(() => {
            newIndex++;
            if(imageList.length > newIndex  && imageList[newIndex]){
                setActiveIndex(activeIndex => {
                    return activeIndex + 1;
                });
                setFirstDrawStatus(true);
                let firstActImgData = {...imageList[newIndex]};
                firstActImgData.objectCount = getPerObjectCount(imageList[newIndex].objects ? imageList[newIndex].objects : null);
                setActiveImage(firstActImgData);
                if(imageList[newIndex].time){
                    let time = imageList[newIndex].time;
                    time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                    setLocalTimestamp(time);
                }
            }else{
                setTimer(null);
                setActiveIndex(0);
                setFirstDrawStatus(true);
                if(imageList.length){
                    setActiveImage(imageList[0]);
                    if(imageList[0].time){
                        let time = imageList[0].time;
                        time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                        setLocalTimestamp(time);
                    }
                }
                clearInterval(tempTimer);
            }
        }, 3000);
        setTimer(tempTimer);
    }

    const handlePause = () => {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    }

    const draw = (context, img) => {
        if(!img){
            return null;
        }
        console.log("img: ", img);
        let imageObj1 = new Image();
        imageObj1.src = img.url;
        imageObj1.onload = (e) => {
            // setCanvasProp({height: imageObj1.height, width: imageObj1.width});
            context.drawImage(imageObj1, 0, 0, 1080, 600);
            context.beginPath();
            img.objects.map(r => {
                if(r.relative_coordinates){
                    let x1 = roundOF2(parseFloat(r.relative_coordinates.centre_x)*parseInt(1080));
                    let y1 = roundOF2(parseFloat(r.relative_coordinates.centre_y)*parseInt(600));
                    let x2 = roundOF2(parseFloat(r.relative_coordinates.width)*parseInt(1080));
                    let y2 = roundOF2(parseFloat(r.relative_coordinates.height)*parseInt(600));
                    context.lineWidth = "2";
                    context.strokeStyle = (objectColor[r.name]) ? objectColor[r.name] : "red";
                    context.strokeRect(x1, y1, x2, y2);
                    context.font='13px Arial';
                    context.fillStyle = (objectColor[r.name]) ? objectColor[r.name] : "red";
                    context.fillText(r.name, x1, y1);
                }
            });
        }
        setFirstDrawStatus(false);
    };

    const onInputChange = (val) => {
        let organization = data.organizationId;
        setCameraList([]);
        getCameraList({variables: {organization, page: 1, limit: 20, search: val}});
    }
    
    const handleShowVideoList = () => {
        if(cameraId){
            setShowVideoListModal(true);
            setVideoLoading(true);
            return getAllViewersVideos({variables: { cameraId: cameraId.toString() }});
        }
    }

    const handleConvertVideo = () => {
        if(imagesUrlList.length){
            setConverterLoading(true);
            Message.success("Your video process is starting, it will be available in videos listing after few minutes.");
            let startTime = new Date(startDateTime);
            let endTime = new Date(startDateTime);
            endTime.setMinutes(endTime.getMinutes() + timeInterval);
            startTime = new Date(convertLocalToUTCDate(startTime)).getTime()/1000;
            endTime = new Date(convertLocalToUTCDate(endTime)).getTime()/1000;
            return convertImagesToVideo({variables: {
                images: imagesUrlList,
                cameraId: cameraId.toString(),
                startTime,
                endTime
            }});
        }
    }

    return (
        <Grid style={{overflow:"hidden"}}>
            <Grid className={classes.root} spacing={1} container style={{...marginGenerator('mt-24'), ...marginGenerator('mb-20')}}>
                <Grid item xs={3}>
                    <Autocomplete
                        styleOverrides={{ minWidth: '100%' }}
                        handleChange={(evt, newVal) => {
                            setSelectedCameraName(newVal ? newVal.name : "");
                            setCameraId(newVal ? newVal.id : "");
                        }}
                        options={cameraList}
                        label={t("camera.label")}
                        size="small"
                        handleClose={evt => {
                            setCameraList([]);
                            return getCameraList({variables: {organization: data.organizationId, page: 1, limit: 20, search: ""}});
                        }}
                        onInputChange={onInputChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DateTimeSelector 
                        inputStyle={{padding: 10, minWidth: "100%"}} 
                        label={"yyyy-MM-dd HH:mm"} 
                        dateFormat={"yyyy-MM-dd HH:mm"} 
                        value={startDateTime} 
                        handleChange={(newDate) => setStartDateTime(newDate)} 
                        disableFuture={true} 
                        styleOverrides={{width: "100%"}}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OutlinedSelect
                        val={timeInterval}
                        label={t("interval.label")}
                        handleChange={evt => setTimeInterval(evt.target.value)}
                        styleOverrides={{ minWidth: 188 }}
                        options={intervalList}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontSize: 12}} id="discrete-slider" gutterBottom>{t('threshold.label')}</Typography>
                    <Slider
                      value={threshold}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                      onChange={(evt, number) => {
                        setThreshold(number);
                      }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OutlinedSelect
                        val={thresholdCond}
                        label={t("condition.label")}
                        handleChange={evt => setThresholdCond(evt.target.value)}
                        styleOverrides={{ minWidth: '100%', maxWidth: '100%' }}
                        options={trackingOtherFilterOpt}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ButtonComponent
                        disabled={!cameraId || !timeInterval || !startDateTime} 
                        onClick={evt => {
                            if(!cameraId || !timeInterval || !startDateTime){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            setImagesUrlList([]);
                            handleSubmit();
                        }}
                        t={t}
                        variant="outlined" 
                        size="medium"
                        color="primary"
                        loading={btnLoading}
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton disabled={!(imagesUrlList.length || converterLoading)} onClick={handleConvertVideo}>
                        {converterLoading ? <CircularProgress size={12} /> : 
                        <GetAppIcon color={!(imagesUrlList.length) ? "" : "secondary"}/>}
                    </IconButton>
                    <Button style={{marginLeft: 10}} onClick={handleShowVideoList} disabled={!cameraId} variant="contained" color="secondary" size="medium" >Videos</Button>
                </Grid>
            </Grid>
            {recordNotFound && <Typography variant="subtitle1" color="primary" style={{...marginGenerator("mt-10"), ...marginGenerator("mb-20"), width: "100%"}}>{ t("recordNotFound.label") }</Typography>}
            {imageList && imageList.length > 0 && 
                <>
                    <Grid container>
                        <Typography variant="h6" align="center" color="primary" style={{...marginGenerator("mt-10"), ...marginGenerator("mb-20"), width: "100%"}}>{ localTimestamp }</Typography>
                        <Grid item xs={11}>
                            {activeImage && <Canvas draw={(ctx) => {
                                if(firstDrawStatus){
                                    draw(ctx, activeImage);
                                }
                            }} height={canvasProp.height} width={canvasProp.width} /> }
                        </Grid>
                        {activeImage && 
                            <Grid item xs={1}>
                                <Typography variant="body1" color="primary" style={{...marginGenerator("mb-10")}}>Object-Count</Typography>
                                {activeImage.objectCount && Object.keys(activeImage.objectCount).map((obj, i) => <Typography key={obj} variant="body1" style={{...marginGenerator("mb-10"), color: objectColor[obj] ? objectColor[obj] : "red"}}>{obj} - {activeImage.objectCount[obj]}</Typography> )}
                            </Grid>
                        }
                    </Grid>
                    <Grid container className={classes.controls}>
                        <IconButton aria-label="play/pause">
                            { timer ? <PauseIcon className={classes.playIcon} onClick={handlePause} /> : <PlayArrowIcon className={classes.playIcon} onClick={handleAutoPlay} /> }
                        </IconButton>
                    </Grid>
                    <Grid className={classes.sliderGrid}>
                        <Slider
                            aria-label="custom thumb label"
                            value={activeIndex}
                            defaultValue={0}
                            max={imageList.length}
                            className={classes.imgSlider}
                            onChange={handleChange}
                        />
                    </Grid>
                </>
            }
            <VideoListModal 
            t={trackingOtherFilterOpt}
            loading={videoLoading} 
            data={videoList} 
            open={showVideoListModal}
            camera={selectedCameraName} 
            handleClose={evt => {
                setVideoList([]);
                setShowVideoListModal(false);
            }} />
        </Grid>
    );
};

EdgeAIEventImagesViewers.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default EdgeAIEventImagesViewers;
