import React from 'react';
import CustomTabs from '../../../../../../components/CustomTabs';
import { USER_ROLES } from '../../../../../../utils/constants';
import PaymentVoucherForm from '../PaymentVoucherForm';
import PrepaymentForm from '../PrepaymentForm';
import PaymentRequisitionForm from '../PaymentRequisitionForm';
import FundsApplicationForm from '../FundsApplicationForm';
import ExpenseClaimForm from '../ExpenseClaimForm';
import AssetTransferForm from '../AssetTransferForm';
import AdvanceRequisitionForm from '../AdvanceRequisitionForm';


const CashReportPanel = ({
    t,
    data,
    countryCodes,
    allowConfigure,
    subscriptionUserData,
    activeIndex,
    departments
}) => {
    const role = localStorage.getItem('role');
    let tabList = [];
    if(data){
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Payment Voucher Form",
                children: (
                    <>
                        <PaymentVoucherForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Prepayment Form",
                children: (
                    <>
                        <PrepaymentForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Payment Requisition Form",
                children: (
                    <>
                        <PaymentRequisitionForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Funds Application Form",
                children: (
                    <>
                        <FundsApplicationForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Expense Claim Form",
                children: (
                    <>
                        <ExpenseClaimForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Asset Transfer Form",
                children: (
                    <>
                        <AssetTransferForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }

        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: "Advance Requisition Form",
                children: (
                    <>
                        <AdvanceRequisitionForm
                            t={t}
                            data={data}
                            users={subscriptionUserData}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                            allowConfigure={true}
                            departments={departments}
                        />
                    </>
                )
            });
        }


    }

    return (
        <CustomTabs
            activeIndex={activeIndex}
            tabs={tabList}
        />
    )
}

export default CashReportPanel
