import { gql } from 'apollo-boost';

export const GET_PRODUCT_LIST = gql`
    query getProductList($page: Int, $search: String) {
        getProductList (page:$page, search:$search){
            count
  next
  previous
  results
        }
    }
`;
