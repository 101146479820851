import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import EditSubscriptionSiteItem from '../EditSubscriptionSiteItem';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function EditSubscriptionModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    subscriptionData,
    state,
    inputArr,
    handleMenuItemClick,
    menuItems,
    handleSetPosition,
    handleInputChange,
    countryList,
    regionList,
    loading
}) {
    
    const [subscriptionName, setSubscriptionName] = useState('');
    let count = 0;

    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
        if(subscriptionData && subscriptionData.name && count == 0){
            setSubscriptionName(subscriptionData.name);
            count++;
        }
    }, [modalState, inputArr, state, subscriptionData]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('edit.label')} {t('subscription.label')}</DialogTitle>
            <DialogContent>
                {!loading ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('subscriptionName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={subscriptionName}
                                type={'text'}
                                placeholder={t('subscriptionName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSubscriptionName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid style={{ ...marginGenerator('mt-5') }}>
                            {[...Array(state.sites)].length &&
                                [...Array(state.sites)].map((item, index) =>
                                    state[`site${index + 1}`] &&
                                        state[`site${index + 1}`].removed === true ? null : (
                                        <EditSubscriptionSiteItem
                                            t={t}
                                            key={index}
                                            data={inputArr}
                                            currentState={state}
                                            handleMenuItemClick={handleMenuItemClick}
                                            index={index + 1}
                                            menuItems={menuItems}
                                            handleSetPosition={handleSetPosition}
                                            handleInputChange={handleInputChange}
                                            countryList={countryList && countryList.getCountries}
                                            regionList={regionList && regionList.getRegionsByCountryId}
                                        />
                                    )
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                : <div style={{textAlign: "center", marginTop: 30}}>
                    <CircularProgress />
                </div>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!subscriptionName) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmitClick({
                                name: subscriptionName,
                                id: subscriptionData.id
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

EditSubscriptionModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(EditSubscriptionModal);