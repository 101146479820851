import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Checkbox, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
//import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../theme/commonStyles';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = {
  id: '',
  failedComments: '',
  performedServerRoomName: '',
  performedAuthSign: '',
  performedDate: null,
  checkedServerRoomName: '',
  checkedAuthSign: '',
  checkedDate: null,
  dailyAttendence: [
    {
      key: 0,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    { 
      key: 1,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 2,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 3,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 4,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 5,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 6,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 7,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    }
  ],
  mcmdailyAttendence: [
    {
      key: 0,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 1,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 2,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 3,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 4,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 5,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    }
  ],
  debsIsodailyAttendence: [
    {
      key: 0,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 1,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 2,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 3,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 4,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 5,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    }
  ],
  mcmAspdailyAttendence: [
    {
      key: 0,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 1,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 2,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 3,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 4,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 5,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    }
  ],
  debsMcmAspdailyAttendence: [
    {
      key: 0,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 1,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 2,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 3,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 4,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    },
    {
      key: 5,
      mondayValue: '',
      tuesdayValue: '',
      wenesdayValue: '',
      thursdayValue: '',
      fridayValue: '',
      saturdayValue: ''
    }
  ]
};

const BackupControlForm = ({
  t,
  open,
  handleClose,
  data,
  signatures,
  handleOpenSignPanModal,
  handleCreateRequestSubmit
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
    if (!open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
      const id = data._id;
      setURFormState({ ...data, id });
    } else {
      // setShowUserSelect(true);
      // setShowLearnDevelopment(true);
      // setShowHOD(true);
      // setShowHRManager(true);
      // setShowGeneralManager(true);
    }
  }, [data]);

  const columns = [
    {
      label: 'Day',
      key: 'day'
    },
    {
      label: 'Monday',
      key: 'monday'
    },
    {
      label: 'Tuesday',
      key: 'tuesday'
    },
    {
      label: 'Wednesday',
      key: 'wednesday'
    },
    {
      label: 'Thursday',
      key: 'thursday'
    },
    {
      label: 'Friday',
      key: 'friday'
    },
    {
      label: 'Saturday',
      key: 'saturday'
    }
  ];

  const handleChange = (value, name, key) => {
    if (
      name === 'dailyAttendence' ||
      name === 'mcmdailyAttendence' ||
      name === 'debsIsodailyAttendence' ||
      name === 'mcmAspdailyAttendence' ||
      name === 'debsMcmAspdailyAttendence'
    ) {
      setURFormState(prevState => {
        const newState = { ...prevState };
        if (value.target.checked) {
          const row = newState[name];
          row[key][value.target.name] = value.target.value;
        } else {
          //let index = newState.insertType.indexOf(value.target.value);
          // if (index !== -1) {
          //     newState.insertType.splice(index, 1);
          // }
        }
        return newState;
      });
    } else {
      setURFormState({ ...urFormState, [name]: value });
    }
  };
  //console.log('urFormState', urFormState)
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle style={{ padding: '16px 0' }} id="sensor-template-title">
        <HrHeader />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Backup control Form</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((item, index) => (
                    <TableCell key={index}>
                      <Typography variant={'subtitle2'}>
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {urFormState?.dailyAttendence.length > 0 ? (
                  urFormState?.dailyAttendence.map((rowitem, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <Typography variant="subtitle1">
                              DEBS-MCM-NMS-0{1 + rowIndex}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'mondayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`mondayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'mondayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`mondayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'tuesdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`tuesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'tuesdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`tuesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'wenesdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`wenesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'wenesdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`wenesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'thursdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`thursdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'thursdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`thursdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'fridayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`fridayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'fridayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`fridayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="dailyAttendence"
                            value={urFormState.dailyAttendence[rowIndex]}
                            name={`dailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `dailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'saturdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`saturdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.dailyAttendence[rowIndex]?.[
                                    'saturdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`saturdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {urFormState?.mcmdailyAttendence.length > 0 ? (
                  urFormState?.mcmdailyAttendence.map((rowitem, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <Typography variant="subtitle1">
                              MCM-MRX-0{1 + rowIndex}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'mondayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`mondayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'mondayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`mondayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'tuesdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`tuesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'tuesdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`tuesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'wenesdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`wenesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'wenesdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`wenesdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'thursdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`thursdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'thursdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`thursdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'fridayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`fridayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'fridayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`fridayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <RadioGroup
                            row
                            aria-label="mcmdailyAttendence"
                            value={urFormState.mcmdailyAttendence[rowIndex]}
                            name={`mcmdailyAttendence`}
                            onChange={evt => {
                              handleChange(evt, `mcmdailyAttendence`, rowIndex);
                            }}>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'saturdayValue'
                                  ] === 'success'
                                    ? true
                                    : false
                                }
                                value="success"
                                control={
                                  <Radio
                                    name={`saturdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="S"
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                checked={
                                  urFormState.mcmdailyAttendence[rowIndex]?.[
                                    'saturdayValue'
                                  ] === 'failed'
                                    ? true
                                    : false
                                }
                                value="failed"
                                control={
                                  <Radio
                                    name={`saturdayValue`}
                                    style={{
                                      border: '0px',
                                      ...marginGenerator('mr-8')
                                    }}
                                  />
                                }
                                labelPlacement="start"
                                label="F"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {urFormState?.debsIsodailyAttendence.length > 0 ? (
                  urFormState?.debsIsodailyAttendence.map(
                    (rowitem, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <Grid>
                              <Typography variant="subtitle1">
                                DEBS-MCM-ISO-0{1 + rowIndex}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsIsodailyAttendence"
                              value={
                                urFormState.debsIsodailyAttendence[rowIndex]
                              }
                              name={`debsIsodailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsIsodailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsIsodailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {urFormState?.mcmAspdailyAttendence.length > 0 ? (
                  urFormState?.mcmAspdailyAttendence.map(
                    (rowitem, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <Grid>
                              <Typography variant="subtitle1">
                                MCM-APS-0{1 + rowIndex}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="mcmAspdailyAttendence"
                              value={
                                urFormState.mcmAspdailyAttendence[rowIndex]
                              }
                              name={`mcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `mcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.mcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {urFormState?.debsMcmAspdailyAttendence.length > 0 ? (
                  urFormState?.debsMcmAspdailyAttendence.map(
                    (rowitem, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <Grid>
                              <Typography variant="subtitle1">
                                DEBS-MCM-NMS-0{1 + rowIndex}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['mondayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`mondayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['tuesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`tuesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['wenesdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`wenesdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['thursdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`thursdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['fridayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`fridayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid className={commonClasses.column}>
                            <RadioGroup
                              row
                              aria-label="debsMcmAspdailyAttendence"
                              value={
                                urFormState.debsMcmAspdailyAttendence[rowIndex]
                              }
                              name={`debsMcmAspdailyAttendence`}
                              onChange={evt => {
                                handleChange(
                                  evt,
                                  `debsMcmAspdailyAttendence`,
                                  rowIndex
                                );
                              }}>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'success'
                                      ? true
                                      : false
                                  }
                                  value="success"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="S"
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ ...marginGenerator('mb-8') }}
                                  checked={
                                    urFormState.debsMcmAspdailyAttendence[
                                      rowIndex
                                    ]?.['saturdayValue'] === 'failed'
                                      ? true
                                      : false
                                  }
                                  value="failed"
                                  control={
                                    <Radio
                                      name={`saturdayValue`}
                                      style={{
                                        border: '0px',
                                        ...marginGenerator('mr-8')
                                      }}
                                    />
                                  }
                                  labelPlacement="start"
                                  label="F"
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Reason if failed and intended remedy:
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Grid item xs={8}>
              <TextField
                type="textarea"
                placeholder="Comments"
                variant="standard"
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={2}
                onChange={evt =>
                  handleChange(evt.target.value, 'failedComments')
                }
                value={urFormState.failedComments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={3}>
              <Typography variant="h4">RESPONSIBILI TIES</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4">NAME</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4">SIGNATURE</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4">DATE</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={3}>
              <Typography variant="h4">Performed By</Typography>
            </Grid>
            <Grid item xs={3}>
              <Input
                type={'text'}
                placeholder={'Name'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.performedServerRoomName}
                onChange={evt =>
                  handleChange(evt.target.value, 'performedServerRoomName')
                }
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('performedAuthSign')}>
                Signature <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 260 }}
                label={'Date & Time Reported'}
                dateFormat={'yyyy-MM-dd'}
                value={urFormState.performedDate}
                handleChange={newDate => handleChange(newDate, 'performedDate')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={3}>
              <Typography variant="h4">Checked By</Typography>
            </Grid>
            <Grid item xs={3}>
              <Input
                type={'text'}
                placeholder={'Name'}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.checkedServerRoomName}
                onChange={evt =>
                  handleChange(evt.target.value, 'checkedServerRoomName')
                }
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <IconButton
                variant={'outlined'}
                onClick={() => handleOpenSignPanModal('checkedAuthSign')}>
                Signature <img src={SignIcon} width="26" height={26} alt="" />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <DateTimeSelector
                inputStyle={{ minWidth: 260 }}
                label={'Date & Time Reported'}
                dateFormat={'yyyy-MM-dd'}
                value={urFormState.checkedDate}
                handleChange={newDate => handleChange(newDate, 'checkedDate')}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => handleCreateRequestSubmit(urFormState)}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default BackupControlForm;
