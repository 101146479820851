import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../components/Message';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      marginBottom:'10px',
      cursor:'pointer'
    },
    selectedCard: {
        border: '1px solid #5a1f2a',
        background: '#5a1f2a',
        borderRadius: '2px'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
      color: '#000',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    iconForTemplate: {
        height: '25px',
        marginRight:'5px'
      },
}));

function SelectAITypeModal({t, open, handleClose, aiTypes, handleSubmitAIType}) {
    
    const classes = useStyles();
    const [selectedType, setSelectedType] = React.useState('');
    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('aiTempType.label')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        {aiTypes.length && aiTypes.map((item, idx) => {
                            return (
                            <Grid item xs={6} key={idx}>
                                <input style={{display:'none'}} id={idx} type='radio' name={'type'} value={item.id} className={'aiRadio'} onClick={evt => {
                                    setSelectedType(evt.target.value);
                                }}/>
                                <label for={idx} style={{cursor:'pointer'}}>
                                    <Card className={classes.root, (selectedType === item.id) ? classes.selectedCard : null}>
                                        <div className={classes.details}>
                                            <CardContent className={classes.content}>
                                                <Typography variant="subtitle2" color="primary" style={selectedType === item.id ? {color: '#fff'} : null}>
                                                    <img className={classes.iconForTemplate} src={item.icon ? item.icon : "../../../assets/icons/human.png"} alt={""} />
                                                    {item.name}
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </label>
                            </Grid>);
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                {t('cancel.label')}
                </Button>
                <Button variant="contained" color="secondary" onClick={evt => {
                    if(!selectedType){
                        Message.error('Please select ai type!');
                        return null;
                    }
                    handleSubmitAIType(selectedType);
                    handleClose();
                }}>
                    {t('submit.label')}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

SelectAITypeModal.propTypes = {
    t: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default withTranslation()(SelectAITypeModal);
