import React, {useState, useContext} from 'react';
import { get } from "lodash-es";
import { useMutation} from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import OcrFolderItem from './OcrFolderItem';
import CreateFolder from './CreateFolder';
import CreateSubFolder from './CreateSubFolder';
import OcrConfigurationEditFolder from './OcrConfigurationEditFolder';
import {
    EDIT_FOLDER
} from '../../../../Ocr/mutations';
import {marginGenerator} from '../../../../../../theme/utils';
import Message from '../../../../../../components/Message';
import {OcrContext} from '../../../../../../contexts/OcrProvider';


const useStyles = makeStyles(theme => ({
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  }
}));

const OcrConfigurationFolder = ({
    t,
    //ocrFolderData,
    //loading,
    page,
    handlePageChange,
    createFolderEnable,
    isFolderEnable,
    handlecreateFolderEnableFormClose,
    handleSubmitOfOcrFolder,
    ocrProjectData,
    ocrPlaceData,
    ocrFolderSubmit,
    placeLoading,
    projectLoading,
    folderLoading,
    handleFilterdataForOptions,
    ocrFolderDeletetingStarted,
    selecteIdForDelete,
    isSubFolderEnable,
    handleSubmitOfOcrSubFolder,
    handlecreateSubFolderEnableFormClose,
    createSubFolderEnable,
    ocrSubFolderSubmit,
    handleSubmitOfDeleteOcrTreeItems
}) => { 

  const classes = useStyles();
  const [isEditFolderEnable, setIsEditFolderEnable] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const { 
    organizationId,
    loadingOcrFolder,
    ocrFolderData,
    refetchFolder
  } = useContext(OcrContext);
  let columns = [
    {
        label:t('name.label'),
        key: "name"
    },
    {
      label:'Description',
      key: "description"
    },
    {
      label: 'Place',
      key: "place"
    },
    {
        label: 'Project',
        key: "project"
    },
    {
        label: t('createdBy.label'),
        key: "createdByName"
    },
    {
        label: t('createdDate.label'),
        key: "createdDate"
    },
    {
        label: t('action.label'),
        key: "action"
    } 
];
const [editFolder, { loading:editFolderLoading }] = useMutation(EDIT_FOLDER, {
    onError: error => {
        console.log('err', error);
    },
    onCompleted: data => {
        Message.success(data && data.editFolder.message);
        refetchFolder();
        handleCloseOfEditFolderModal();
    }
});

const handleEditSubmitOfFolder = (data) => {
    editFolder({
        variables:{
          organization: organizationId,
          id: data.id,
          name: data.folderName
        }
    })
};

const enableEditFolderModal = (data) => {
    setIsEditFolderEnable(true);
    setSelectedData(data);
};

const handleCloseOfEditFolderModal = () => {
    setIsEditFolderEnable(false);
    setSelectedData('');   
};

  return (
    <div>
    <Grid container style={{...marginGenerator("mb-20")}}>
        <Grid item xs="10"/>
        <Grid item xs="2">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={createFolderEnable}
                >
                {t('createFolder.label')}
            </Button>
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!loadingOcrFolder ?
                      ocrFolderData && ocrFolderData.data.length > 0 ?
                      ocrFolderData.data.map((rowitem, rowIndex) => (
                                <OcrFolderItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    view="confrigtion"
                                    ocrFolderDeletetingStarted={ocrFolderDeletetingStarted}
                                    selecteIdForDelete={selecteIdForDelete}
                                    handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                                    enableEditFolderModal={enableEditFolderModal}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {loadingOcrFolder && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            { !loadingOcrFolder &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(ocrFolderData, 'count', null)/20)} page,  {get(ocrFolderData, 'count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={ocrFolderData && ocrFolderData.count ? Math.ceil(ocrFolderData.count/20) : 0} 
                                page={page} 
                                onChange={(evt, value) => {
                                    handlePageChange("ocr_folder", value);
                                }} 
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </TableContainer>
    </Grid>
    <CreateFolder
      t={t}
      open={isFolderEnable}
      onClose={handlecreateFolderEnableFormClose}  
      handleSubmitOfOcrFolder={handleSubmitOfOcrFolder}
      ocrProjectData={ocrProjectData}
      ocrPlaceData={ocrPlaceData}
      ocrFolderSubmit={ocrFolderSubmit}
      placeLoading={placeLoading}
      projectLoading={projectLoading}
      handleFilterdataForOptions={handleFilterdataForOptions}
    />
    <CreateSubFolder
      t={t}
      open={isSubFolderEnable}
      onClose={handlecreateSubFolderEnableFormClose}  
      handleSubmitOfOcrSubFolder={handleSubmitOfOcrSubFolder}
      ocrProjectData={ocrProjectData}
      ocrPlaceData={ocrPlaceData}
      ocrFolderSubmit={ocrFolderSubmit}
      placeLoading={placeLoading}
      projectLoading={projectLoading}
      folderLoading={folderLoading}
      handleFilterdataForOptions={handleFilterdataForOptions}
    />
    <OcrConfigurationEditFolder
      t={t}
      open={isEditFolderEnable}
      onClose={handleCloseOfEditFolderModal}
      data={selectedData}
      editFolderLoading={editFolderLoading}
      handleEditSubmitOfFolder={handleEditSubmitOfFolder}   
    />
</div>
  );
};

export default OcrConfigurationFolder;
