import { gql } from 'apollo-boost';

/*
  Get Sub Users list
 */
export const GET_SUB_ADMIN_USERS = gql`
  query SubAdmin {
    getSubAdminUsers {
      id
      firstName
      lastName
      email
      phone
      role
      countryId
      organisations
      created_at
    }
  }
`;

export const GET_SUB_ADMIN_CUSTOMERS = gql`
  query SubAdmin($page: Int, $search: String) {
    getSubAdminCustemers(page: $page, search: $search) {
      count
      users
    }
  }
`;

/*
  Get Sub Users list
 */
  export const GET_PAGINATE_USERS = gql`
  query Users($role: String, $page: Int, $groupId: String, $search: String) {
    getPaginateUsers(role: $role, page: $page, groupId: $groupId, search: $search) {
      count
      users
    }
  }
`;
