import React, { useState, useEffect } from 'react';
import '../styles.css';
import { Paths, TicketingSystem } from '../../../routes/routePaths';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import commonStyles from '../../../theme/commonStyles';
import { CHANGE_MANAGEMENT_LIST } from '../queries';
import { CLOSE_CM_REQUEST } from '../mutations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TicketPanel from './components/ChangeRequestPanel'
import CustomTabs from '../../../components/CustomTabs';
import Pagination from '@material-ui/lab/Pagination';
import Message from '../../../components/Message';
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';
import { paddingGenerator, marginGenerator } from '../../../theme/utils';
import { getUrl } from '../../../utils';
import { Button, CircularProgress } from '@material-ui/core';
import ChangeRequestReportModal from './components/ChangeRequestReportModal';
import { ExportCSV } from './components/Export';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'changeManagementRequest.label', link: TicketingSystem.CM }
];

const useStyles = makeStyles(theme => ({
  exportDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25
  },
  leftSide: {
    display: 'flex'
  },
  styles: {
    padding: '15px 20px 15px 20px',
    border: '2px solid #1b1818',
    borderRadius: '10px',
    marginRight: '100px',
    marginLeft: '25px'
  },
}));

function ChangeManagement({ t }) {
  const country = localStorage.getItem('country');
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [reportFields, setReportFields] = useState([]);
  const [downloadReport, setDownloadReport] = useState(false);
  const [reportBtnLoading, setReportBtnLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [changeRequestReportList, setChangeRequestReportList] = useState(false);
  const [pageClosed, setPageClosed] = useState(1);
  const commonClasses = commonStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  useEffect(() => {
    reloadOpenCMTicket()
    reloadClosedCMTicket()
  }, [])

  const { loading, error, data: openCMTickets, refetch: reloadOpenCMTicket } = useQuery(CHANGE_MANAGEMENT_LIST, {
    variables: {
      status: 'Open',
      page: page
    }
  });

  const { closedLoading, closedError, data: closedCMTickets, refetch: reloadClosedCMTicket } = useQuery(CHANGE_MANAGEMENT_LIST, {
    variables: {
      status: 'Closed',
      page: pageClosed
    }
  });

  const [getChangeRequestReportList] = useLazyQuery(CHANGE_MANAGEMENT_LIST, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      Message.error("Something went wrong");
      console.log("Error:- ", err);
    },
    onCompleted: (res) => {
      if (res && res.changeManagement && res.changeManagement.total > 0) {
        let finalData = res.changeManagement.data.map(res => {
          let length = res.changeRequest.length
          let lastItem = res.changeRequest && res.changeRequest[length - 1]
          return lastItem
        })
        setChangeRequestReportList(finalData)
        setShowReportModal(true)
      } else {
        Message.error("No Record Found")
      }
      setReportBtnLoading(false)
    }
  })

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handlePageChangeClosed = (event, value) => {
    setPageClosed(value)
  }

  const handleTicketItemClick = id => {
    const path = getUrl(TicketingSystem.CMDetails, id);
    history.push(path);
  };

  const [closeCMRequestById] = useMutation(CLOSE_CM_REQUEST, {
    onError: error => {
      console.log('Error', error);
      Message.error('There is some error in closing ticket. Please try again');
    },
    onCompleted: () => {
      reloadOpenCMTicket()
      reloadClosedCMTicket()
      Message.success('Change Management Request Closed Successfully.');
    }
  });

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
      // close ticket request
      return closeCMRequestById({
        variables: { id }
      });
    } else if (item.label === t('viewDetails.label')) {
      handleTicketItemClick(id);
    }
  };

  const handleCyberSecurity = () => {
    history.push(TicketingSystem.CyberSecurity)
  };

  const handleChangeRequestButton = () => {
    history.push(TicketingSystem.ChangeRequest);
  }

  const handleExportClick = () => {
    setReportBtnLoading(true)
    getChangeRequestReportList({
      variables: {
        startDate: startDate,
        endDate: endDate
      }
    })
  }

  const handleSubmitReportFields = (fields) => {
    setReportFields(fields)
    setDownloadReport(true)
  }

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('changeManagementRequest.label')}
        divider={true}
        subText={t('changeManagementRequestSub.label')}
        primaryButtonText={t('cyberSecurity.label')}
        handlePrimaryButtonClick={handleCyberSecurity}
        secondaryButtonText={t('CMRequest.label')}
        handleSecondaryButtonClick={handleChangeRequestButton}
      />

      {!loading && !error && openCMTickets && openCMTickets.changeManagement.data && (
        <div className={classes.exportDiv}>
          <div className={classes.leftSide}>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{
                ...paddingGenerator('pt-12'),
                ...marginGenerator('mr-70')
              }}>
              {t('selectInterval.label')}
            </Typography>
            <DatePicker
              selected={startDate}
              onChange={date => {
                setDownloadReport(false)
                setStartDate(date)
              }}
              className={classes.styles}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
            <DatePicker
              selected={endDate}
              onChange={date => {
                setDownloadReport(false)
                setEndDate(date)
              }}
              selectsEnd
              className={classes.styles}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
          <div className={classes.rightSide}>
            {downloadReport ?
              <ExportCSV csvData={changeRequestReportList} fileName={`ChangeRequest-Report`} type={'multiReport'} fields={reportFields} /> :
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleExportClick}>
                {reportBtnLoading ? <CircularProgress size={22} color="secondary" /> : t('exportTickets.label')}
              </Button>}
          </div>
        </div>
      )}

      <CustomTabs
        tabs={[
          {
            label: t('openTickets.label'),
            children: (
              <>
                <TicketPanel
                  t={t}
                  loading={loading}
                  handleMenuItemClick={handleMenuItemClick}
                  handleTicketItemClick={handleTicketItemClick}
                  ticketList={openCMTickets && openCMTickets.changeManagement.data}
                />
                <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
                  count={openCMTickets && openCMTickets.changeManagement && openCMTickets.changeManagement.total ?
                    Math.ceil(openCMTickets.changeManagement.total / 30) : 0} page={page} onChange={handlePageChange} />
              </>
            )
          },
          {
            label: t('closedTickets.label'),
            children: (
              <>
                <TicketPanel
                  t={t}
                  loading={closedLoading}
                  handleMenuItemClick={handleMenuItemClick}
                  handleTicketItemClick={handleTicketItemClick}
                  ticketList={closedCMTickets && closedCMTickets.changeManagement.data}
                />
                <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
                  count={closedCMTickets && closedCMTickets.changeManagement && closedCMTickets.changeManagement.total ?
                    Math.ceil(closedCMTickets.changeManagement.total / 30) : 0} page={pageClosed} onChange={handlePageChangeClosed} />
              </>
            )
          }
        ]}
      />
      <ChangeRequestReportModal
        open={showReportModal}
        handleClose={evt => setShowReportModal(false)}
        handleSubmit={handleSubmitReportFields}
      />
    </Layout>
  );
}

export default withTranslation()(ChangeManagement);
