import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { v4 as uuidv4 } from 'uuid';


const useStyles = makeStyles(theme => {
});

function UpdateCustomer({
    open,
    handleClose,
    data,
    column,
    t
}) {
    const classes = useStyles();

    const minutesSec = (timeToRestore) => {
        timeToRestore = Number(timeToRestore) * 60;
        let h = Math.floor(timeToRestore / 3600);
        let m = Math.floor(timeToRestore % 3600 / 60);

        let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

        return hDisplay + mDisplay
    }

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                className={classes.beg}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <TableContainer className={classes.container} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {column &&
                                        column.map(column => (
                                            <TableCell key={uuidv4()}>{column.name}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.length > 0 ? data.map(row => (
                                    <TableRow key={uuidv4()}>
                                        <TableCell>{row.orgName}</TableCell>
                                        <TableCell>{minutesSec((row.restoreTimeMinutes / row.totalIncident))}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={column.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withTranslation()(UpdateCustomer);
