import React, { useEffect, useState } from 'react';
import { marginGenerator } from '../../../../theme/utils';
import CustomTabs from '../../../../components/CustomTabs';
import { GET_CAMERA_LIST, GET_NOTIFICATION_REPORT, GET_UNKNOWN_FACES, GET_ML_TEMPLATE_LIST } from '../../queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import EnrolFaceModal from './EnrolFaceModal';
import RegisterFaces from './components/RegisterFaces';
import UnknownFaces from './components/UnknownFaces';
import { getLocalToUtcTimeStemp } from '../EdgeAIReportPanel';
import Message from '../../../../components/Message';
import { UPDATE_ML_TEMPLATE } from '../../mutations';
import ImagePreviewModal from './components/ImagePreviewModal';

const EdgeAIFacesPanel = ({
    t,
    organization,
    handleShowEventTags
}) => {
    
    const [filterDataState, setFilterDataState] = useState({cameraId: null, searchByFace: "", startDate: null, endDate: null, page: 1});
    const [enrolFaceModalOpen, setEnrolFaceModalOpen] = useState(false);
    const [cameraList, setCameraList] = useState([]);
    const [unknownfilterDataState, setUnknownfilterDataState] = useState({
        cameraId: null, startDate: null, endDate: null
    });
    const [selectedFace, setSelectedFace] = useState(null);
    const [mlTemplateList, setMlTemplateList] = useState([]);

    const [selectedPrevImage, setSelectedPrevImage] = useState("");
    const [openImagePrevModal, setOpenImagePrevModal] = useState(false);

    const columns = [
        {
            label: t('imageSnap.label'),
            key: "face"
        },
        {
            label: t('face.label'),
            key: "face"
        },
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "name"
        },
        {
            label: t('eventDateTime.label'),
            key: "eventDateTime"
        }
    ];

    const unknowColumns = [
        {
            label: "Image Snap",
            key: "face"
        },
        {
            label: t('imageSnap.label'),
            key: "face"
        },
        {
            label: t('eventDateTime.label'),
            key: "eventDateTime"
        },
        {
            label: t('camera.label') + ' ' + t('name.label'),
            key: "camera_name"
        },
        {
            label: "Enrol",
            key: "enrol"
        }
    ];

    const { data: tmpMLData } = useQuery(GET_ML_TEMPLATE_LIST, {
            variables: {
                organization,
                page: 1,
                limit: 200
            },
            onCompleted: (res) => {
                if(res.getAllMlTemplateList && res.getAllMlTemplateList.templates){
                    const templateList = res.getAllMlTemplateList.templates.filter(item => item.model_type === "face_recognition");
                    setMlTemplateList(templateList);
                }
            }
        }
    );

    const handleFilterChange = (val, field) => {
        setFilterDataState({...filterDataState, [field]: val});
    }

    useEffect(() => {
        const startDate = (filterDataState.startDate) ? getLocalToUtcTimeStemp(filterDataState.startDate) : null;
        const endDate = (filterDataState.endDate) ? getLocalToUtcTimeStemp(filterDataState.endDate) : null;
        
        getUnknownFaceList({ variables: {
            organization,
            dateTime: startDate,
            endDate,
            NextContinuationToken: (filterDataState.NextContinuationToken) ? filterDataState.NextContinuationToken : "", 
            cameraId: (unknownfilterDataState.cameraId && unknownfilterDataState.cameraId.camera_id) ? unknownfilterDataState.cameraId.camera_id.toString() : ""
        }});
    }, [unknownfilterDataState]);
    
    useEffect(() => {

        const startDate = (filterDataState.startDate) ? getLocalToUtcTimeStemp(filterDataState.startDate) : null;
        const endDate = (filterDataState.endDate) ? getLocalToUtcTimeStemp(filterDataState.endDate) : null;
        
        const filterBy = {
            variables: {
                organization,
                modelType: "face_recognition",
                cameraId: (filterDataState.cameraId && filterDataState.cameraId.camera_id) ? filterDataState.cameraId.camera_id.toString() : "",
                limit: 20,
                page: filterDataState.page,
                dateTime: startDate,
                endDate,
            }
        };
        getRegisterFaceList(filterBy);
    }, [filterDataState]);

    useEffect(() => {
        getAllCameraList({
            variables: {
                organization,
                limit: 20,
                search: "",
                page: 1
            }
        });
    }, []);

    const [getAllCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: res => {
            if(res.getAllCameraList){
                setCameraList(res.getAllCameraList.cameras ? res.getAllCameraList.cameras : []);
            }
        }
    });

    const [getRegisterFaceList, { loading: regFaceLoading, data: registerFaceList, error: regFaceError }] = useLazyQuery(GET_NOTIFICATION_REPORT, {
        fetchPolicy: "no-cache",
    });

    const [getUnknownFaceList, { loading: unknownLoading, data: unknownFaceList, error: unknownError }] = useLazyQuery(GET_UNKNOWN_FACES, {
        fetchPolicy: "no-cache",
    });

    const handleUnkownFilterChange = (val, field) => {
        setUnknownfilterDataState({...unknownfilterDataState, [field]: val});
    }

    const registerFilterList = [
        {
            label: t('cameraFilter.label'),
            handleChange: (evt, newValue) => {
                handleFilterChange(newValue, "cameraId");
            },
            val: filterDataState.cameraId,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            type: "autocomplete",
            idKey: "camera_id",
            options: cameraList,
            onInputChange: (val) => {
                setCameraList([]);
                return getAllCameraList({
                    variables: {
                        organization,
                        limit: 20,
                        search: val,
                        page: 1
                    }
                });
            }
        },
        {
            label: t('startDate.label'),
            handleChange: newDate => {
                setFilterDataState({...filterDataState, startDate: newDate});
            },
            val: filterDataState.startDate,
            styleOverrides: {...marginGenerator("mr-12")},
            name:'date_time_filter',
            type:'input'
        },
        {
            label: t('endDate.label'),
            handleChange: newDate => {
                setFilterDataState({...filterDataState, endDate: newDate});
            },
            val: filterDataState.endDate,
            name:'end_date_filter',
            styleOverrides: {...marginGenerator("mr-12")},
            type:'input'
        }
    ];

    const unknowFilterList = [
        {
            label: t('cameraFilter.label'),
            handleChange: (evt, newValue) => {
                handleUnkownFilterChange(newValue, "cameraId");
            },
            val: unknownfilterDataState.cameraId,
            styleOverrides: { minWidth: 245, display: "inline-block", ...marginGenerator("mr-12") },
            type: "autocomplete",
            idKey: "camera_id",
            options: cameraList,
            onInputChange: (val) => {
                console.log("Callllllllll", val);
                setCameraList([]);
                return getAllCameraList({
                    variables: {
                        organization,
                        limit: 20,
                        search: val,
                        page: 1
                    }
                });
            }
        },
        {
            label: t('startDate.label'),
            handleChange: newDate => {
                setUnknownfilterDataState({...unknownfilterDataState, startDate: newDate});
            },
            val: unknownfilterDataState.startDate,
            styleOverrides: {...marginGenerator("mr-12")},
            name:'date_time_filter',
            type:'input'
        },
        {
            label: t('endDate.label'),
            handleChange: newDate => {
                setUnknownfilterDataState({...unknownfilterDataState, endDate: newDate});
            },
            val: unknownfilterDataState.endDate,
            name:'end_date_filter',
            styleOverrides: {...marginGenerator("mr-12")},
            type:'input'
        }
    ];

    const handleEnrolFaceClick = (faceItem) => {
        if(faceItem){
            setEnrolFaceModalOpen(true);
            setSelectedFace(faceItem);
        }
        return null;
    }

    const handleFaceClick = (face) => {
        console.log("face: ", face);
    }

    const [updateMLTemplate, { loading: updateMlLoading }] = useMutation(UPDATE_ML_TEMPLATE, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: data => {
            if (data.updateMLTemplate && data.updateMLTemplate.id) {
                Message.success('Face added successfully.');
                setSelectedFace(null);
                setEnrolFaceModalOpen(false);
            }
        }
    });

    const handleSubmitEnrolFace = (faceData) => {
        const selectedFRModel = mlTemplateList.find(itm => itm.id === faceData.templateId);
        if(!selectedFRModel){
            Message.error("Face Recognition model not found!");
            return null;
        }
        console.log("selectedFRModel: ", selectedFRModel);
        let definition = selectedFRModel.definition;
        let key = faceData.name;
        let enable_notification = true;
        if (faceData.list_type && faceData.list_type === "whitelist") {
            enable_notification = (faceData.notification) ? true : false;
        }
        definition['face_recognition'].faces.push({ [key]: faceData.faceImage, list_type: faceData.list_type ? faceData.list_type : "whitelist", enable_notification });
        let mlData = {
            name: selectedFRModel.name,
            model_type: selectedFRModel.model_type,
            slug: selectedFRModel.name,
            priority: selectedFRModel.priority,
            definition,
            organization,
            provider: selectedFRModel.provider,
            notification_frequency: selectedFRModel.notification_frequency,
            lineCrossObjects: null
        };
        mlData.id = selectedFRModel.id;
        return updateMLTemplate({
            variables: mlData
        });
    }

    const handlePageChange = (event, value) => {
        setFilterDataState({...filterDataState, page: value});
    }

    const handleUnkownPageChange = (lastKeyName) => {
        setUnknownfilterDataState({...unknownfilterDataState, NextContinuationToken: lastKeyName});
    }

    const handleImageModalClick = (url) => {
        setOpenImagePrevModal(true);
        setSelectedPrevImage(url);
    }

    return (
        <div>
            <CustomTabs
                activeIndex={0}
                tabs={[
                    {
                        label: `${t('registered.label')} ${t('faces.label')}`,
                        children: (
                            <RegisterFaces 
                                t={t}
                                filterList={registerFilterList} 
                                columns={columns} 
                                list={(!regFaceError && !regFaceLoading && registerFaceList) ? registerFaceList.getNotificationsReportList : null}
                                handleImageModalClick={handleImageModalClick}
                                loading={regFaceLoading}
                                handleShowEventTags={handleShowEventTags}
                                handlePageChange={handlePageChange}
                                page={filterDataState.page}
                            />
                        )
                    },
                    {
                        label: `${t('unknown.label')} ${t('faces.label')}`,
                        children: (
                            <UnknownFaces 
                                t={t}
                                filterList={unknowFilterList} 
                                columns={unknowColumns} 
                                unknownfilterDataState={unknownfilterDataState}
                                loading={unknownLoading}
                                list={(!unknownError && !unknownLoading && unknownFaceList) ? unknownFaceList.getUnknownFaceList : []}
                                handleImageModalClick={handleImageModalClick} 
                                handleEnrolFaceClick={handleEnrolFaceClick}
                                handlePageChange={handleUnkownPageChange}
                            />
                        )
                    }
                ]}
            />
            <EnrolFaceModal 
                t={t}
                open={enrolFaceModalOpen} 
                selectedFace={selectedFace}
                handleFaceClick={handleFaceClick}
                handleClose={evt => {
                    setSelectedFace(null);
                    setEnrolFaceModalOpen(false);
                }} 
                loading={updateMlLoading}
                mlTemplateList={mlTemplateList}
                handleSubmitEnrolFace={handleSubmitEnrolFace}
            />
            <ImagePreviewModal 
                open={openImagePrevModal}
                image={selectedPrevImage}
                handleClose={evt => {
                    setSelectedPrevImage("");
                    setOpenImagePrevModal(false);
                }}
            />
        </div>
    )
}

export default EdgeAIFacesPanel