import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {
  getAllClientData,
  deleteClient,
  getClientListByNameFilter
} from '../../../../services/clientService';
import EditClientPopup from './ClientPagePopups/EditClientPopup';
import ViewClientPopup from './ClientPagePopups/ViewClientPopup';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TablePagination,
  TextField
} from '@material-ui/core';
import Message from '../../../../components/Message';
import { useAuth } from '../../../../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    minHeight: '100vh'
  },
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
}));

const ClientTable = () => {
  const [allClientData, setAllClientData] = useState({ data: [] });
  const [clientData, setClientData] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [clientId, setClientId] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const { loggedInUserRoles } = useAuth();
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(+event.target.value);
  };

  const GetAllClientData = async () => {
    const res = await getAllClientData(page + 1, limit);
    if (res && res.status === 200) {
      const data = await res?.data?.result;
      setAllClientData(data);
    }
  };

  const nameFilter = async word => {
    const res = await getClientListByNameFilter(page + 1, limit, word);
    if (res && res.status === 200) setAllClientData(res?.data?.result);
  };

  const editUser = async (id, e) => {
    e.preventDefault();
    const selectedClient = allClientData?.data?.filter(el => id === el._id);
    if (selectedClient) setClientData(selectedClient[0]);

    setOpenEditPopup(true);
  };

  const viewUser = async (id, e) => {
    e.preventDefault();
    const selectedClient = allClientData?.data?.filter(el => id === el._id);
    if (selectedClient) setClientData(selectedClient[0]);
    setOpenViewPopup(true);
  };

  const deleteUser = async (id, e) => {
    e.preventDefault();
    const res = await deleteClient(id);
    if (res?.status === 200) {
      setOpenDeletePopup(false);
      Message.success('Client Deleted');
    } else {
      if (res?.message) {
        Message.error(res?.message);
      } else {
        Message.error('Something went wrong');
      }
    }
    await GetAllClientData();
  };

  useEffect(() => {
    if (searchByName === '') {
      GetAllClientData();
    } else {
      nameFilter(searchByName);
    }
    const intervalCall = setInterval(() => {
      if (searchByName === '') {
        GetAllClientData();
      } else {
        nameFilter(searchByName);
      }
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [openEditPopup, deleteClient, searchByName, page, limit]);

  return (
    <>
      <Grid container direction="column" xs={12} spacing={2}>
        <Grid item style={{ marginLeft: '10px' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search Client..."
            onChange={e => {
              setSearchByName(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TableContainer component={Paper} className={classes.table}>
            <Table stickyHeader>
              <TableHead style={{ backgroundColor: '#5a1f2a' }}>
                <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                  <TableCell
                    align="center"
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}>
                    Client Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}>
                    Headquarters
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}>
                    Business Contact Email
                  </TableCell>
                  <TableCell
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}
                    align="center">
                    Technical Contact Email
                  </TableCell>
                  <TableCell
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}
                    align="center">
                    Telephone
                  </TableCell>
                  <TableCell
                    style={{
                      color: 'white',
                      backgroundColor: '#5a1f2a'
                    }}
                    align="center">
                    Options
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allClientData &&
                  allClientData?.data?.map((item, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row" align="center">
                        {item?.client_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {item?.head_quarters}
                      </TableCell>
                      <TableCell align="center">
                        {item?.bussiness_contact_email}
                      </TableCell>
                      <TableCell align="center">
                        {item?.technical_contact_email}
                      </TableCell>
                      <TableCell align="center">
                        {item?.telephone_contact}
                      </TableCell>
                      <TableCell align="center">
                        {loggedInUserRoles.includes('fabric_readonly') ===
                        false ? (
                          <Tooltip
                            title="Edit"
                            placement="bottom"
                            arrow
                            backgroundColor="#5a1f2a">
                            <SettingsOutlinedIcon
                              className="icons"
                              onClick={e => editUser(item._id, e)}
                              style={{ color: '#5a1f2a' }}
                            />
                          </Tooltip>
                        ) : (
                          <SettingsOutlinedIcon style={{ color: 'grey' }} />
                        )}
                        <Tooltip
                          title="View"
                          placement="bottom"
                          arrow
                          backgroundColor="#5a1f2a">
                          <VisibilityOutlinedIcon
                            className="icons"
                            style={{ color: '#5a1f2a' }}
                            onClick={e => viewUser(item._id, e)}
                          />
                        </Tooltip>

                        {loggedInUserRoles.includes('fabric_readonly') ===
                        false ? (
                          <Tooltip
                            title="Delete"
                            placement="bottom"
                            arrow
                            backgroundColor="#5a1f2a">
                            <DeleteOutlineOutlinedIcon
                              className="icons"
                              style={{ color: '#5a1f2a' }}
                              onClick={() => {
                                setClientId(item._id);
                                setOpenDeletePopup(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <DeleteOutlineOutlinedIcon
                            style={{ color: 'grey' }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {allClientData?.data?.length !== 0 && (
          <Grid item container justify="flex-end">
            <TablePagination
              component="div"
              variant="outlined"
              color="primary"
              sx={{
                '.MuiTablePagination-displayedRows': {
                  marginBottom: 0
                },
                '.MuiTablePagination-selectLabel': {
                  marginBottom: 0
                }
              }}
              count={allClientData?.total_record}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
      <EditClientPopup
        openEditPopup={openEditPopup}
        setOpenEditPopup={setOpenEditPopup}
        clientData={clientData}
        classes={classes}
      />
      <ViewClientPopup
        openViewPopup={openViewPopup}
        setOpenViewPopup={setOpenViewPopup}
        clientData={clientData}
        classes={classes}
      />
      {openDeletePopup && (
        <Popup
          title="Delete client"
          openPopup={openDeletePopup}
          setOpenPopup={setOpenDeletePopup}>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Are you sure you want to delete this client?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenDeletePopup(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={e => deleteUser(clientId, e)}>
              Yes
            </Button>
          </DialogActions>
        </Popup>
      )}
    </>
  );
};

export default ClientTable;
