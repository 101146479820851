import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {marginGenerator} from '../../../../theme/utils';
import {colors} from '../../../../theme/colors';
import {SERVICE_TYPES} from "../../../../utils/constants";

const getLabel = (length, t) => {
    if (length === 1) {
        return t('orderReceived.label');
    } else if (length === 2) {
        return t('orderInProgress.label');
    } else if (length === 3) {
        return t('engineeringTeamAssigned.label');
    } else if (length === 4) {
        return t('provisioningComplete.label');
    }
};
const getDiaLabel = (length, t) => {
    if (length === 1) {
        return 'Opportunity Sent';
    } else if (length === 2) {
        return 'Opportunity Id Received';
    } else if (length === 3) {
        return 'Opportunity Feasible';
    } else if (length === 4) {
        return 'Finance Approved';
    } else if (length === 5) {
        return 'Order Id Received';
    } else if (length === 6) {
        return 'Order Placed';
    } else if (length === 7) {
        return 'Provision Complete';
    }
};
const getDiaNLabel = (length, t) => {
    if (length === 1) {
        return 'Opportunity Sent'
    } else if (length === 2) {
        return 'Opportunity Feasible'
    } else if (length === 3) {
        return 'Forwarded to Finance'
    } else if (length === 4) {
        return 'Site Survey Initiated'
    } else if (length === 5) {
        return 'Site Survey Completed'
    } else if (length === 6) {
        return 'Project Initiated'
    } else if (length === 7) {
        return 'Project Completed'
    } else if (length === 8) {
        return 'Provision Complete'
    }
};

const getServiceTypeStepperStatus = (serviceType, l, t) => {
    switch (serviceType) {
        case SERVICE_TYPES.DIA:
            return getDiaLabel(l, t);
        case SERVICE_TYPES.DIAN:
            return getDiaNLabel(l, t);
        default:
            return getLabel(l, t);
    }
}
const getServiceTypeStepperCount = (serviceType) => {
    switch (serviceType) {
        case SERVICE_TYPES.DIA:
            return [1, 2, 3, 4, 5, 6, 7];
        case SERVICE_TYPES.DIAN:
            return [1, 2, 3, 4, 5, 6, 7, 8];
        default:
            return [1, 2, 3, 4];
    }
}

function SteppedProgressBar({
                                chartWidth = 480,
                                chartHeight = 6,
                                data,
                                serviceType,
                                style,
                                t
                            }) {
    return (
        <Grid style={style}>
            <Grid style={marginGenerator('mb-16')}>
                <Typography variant={'subtitle2'} color={'textPrimary'}>
                    {getServiceTypeStepperStatus(serviceType, data.length, t)}
                </Typography>
            </Grid>

            <Grid
                container
                style={{
                    borderRadius: 3,
                    width: chartWidth,
                    height: chartHeight
                }}>
                {getServiceTypeStepperCount(serviceType).map((item, index) => (
                    <Grid
                        key={index}
                        item
                        style={{
                            width: 60,
                            backgroundColor:
                                data.length === getServiceTypeStepperCount(serviceType).length
                                    ? colors.success.main
                                    : index >= data.length
                                    ? colors.secondary.lightest
                                    : colors.secondary.main,
                            height: chartHeight,
                            borderRadius: 3,
                        }}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

SteppedProgressBar.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    chartWidth: PropTypes.number,
    chartHeight: PropTypes.number
};

export default SteppedProgressBar;
