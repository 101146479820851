import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import { TicketingSystem } from '../../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import Header from '../../../components/Header';
import Message from '../../../components/Message';
// import DatePicker from 'react-datepicker';
import CustomTable from './components/CustomTable';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../theme/colors';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { dateFormatterLLL } from '../../../utils';
import { MTTR_REPORT_BY_COUNTRIES } from './queries';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MttrCustomersList from './components/MttrCustomersList';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Dashboard },
  { label: 'uploadFile.label', link: TicketingSystem.MttrAnalytics }
];

const mttrReportColumn = [
  { name: 'Country Name' },
  { name: 'MTTR for 10 top customers' },
  { name: 'Average per customer' },
  { name: 'Customers Details' }
];

const mttrCustomerColumn = [
  { name: 'Organization Name' },
  { name: 'Customer MTTR' }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const MttrAnalytics = ({ t }) => {
  const classes = useStyles();
  const [mttrLoading, setMttrLoading] = useState(false)
  const [showMttrList, setShowMttrList] = useState(false)
  const [mttrReportList, setMttrReportList] = useState([])
  const [mttrCustomerListData, setMttrCustomerListData] = useState([])
  const [monthYear, setMonthYear] = useState(new Date());

  useEffect(() => {
    setMttrLoading(true)
    getMttrReportByCountry({
      variables: {
        monthYear: monthYear
      }
    })
  }, [monthYear])

  function groupBy(objectArray, property) {
    return objectArray && objectArray.length && objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(obj);
      return acc;
    }, {});
  }


  const [getMttrReportByCountry] = useLazyQuery(MTTR_REPORT_BY_COUNTRIES, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.allCountriesMttrReportList && data.allCountriesMttrReportList.length > 0) {
        const reportData = data.allCountriesMttrReportList;
        let groupedData = reportData && reportData.length && Object.values(groupBy(reportData, 'countryName'));
        groupedData = groupedData && groupedData.length > 0 ? groupedData.map((item, index) => {
          let data = item
          data = data.sort(function (a, b) {
            return b.restoreTimeMinutes - a.restoreTimeMinutes
          }).slice(0, 10)
          const totalMinutes = data && data.reduce((acc, curr) => {
            return acc = (Number(acc) + Number(curr.restoreTimeMinutes)).toFixed(2)
          }, 0.00)
          const countryName = data[0].countryName
          const totalIncidents = data && data.reduce((acc, curr) => {
            return acc = (Number(acc) + Number(curr.totalIncident))
          }, 0)
          const averageMinutesMttr = (totalMinutes / totalIncidents)
          const averageMinutesPerCustomer = (totalMinutes / totalIncidents) / data.length
          return {
            name: countryName,
            mttr: averageMinutesMttr.toFixed(2),
            customer: averageMinutesPerCustomer.toFixed(2),
            allData: data
          }
        }) : []
        setMttrLoading(false)
        setMttrReportList(groupedData)
      } else {
        setMttrLoading(false)
        setMttrReportList([])
      }
    }
  });

  const handleChange = (date) => {
    setMonthYear(date)
  }

  const handleItemClick = (data) => {
    setShowMttrList(true)
    setMttrCustomerListData(data);
  }

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('mttrAnalytics.label')}
        subText={`${t('statsLastUpdated.label')} ${dateFormatterLLL(
          Date.now()
        )}`}
      />
      <div className={classes.root}>
        <Typography
          variant={'h4'}
          style={{
            ...marginGenerator('mt-32'),
            ...marginGenerator('mb-8'),
            borderBottom: `1px solid ${colors.grey[200]}`
          }}>
          {t('averagePercentage.label')}
        </Typography>

        <Grid container style={{ ...marginGenerator('mt-32'), ...marginGenerator('mb-32') }}>
          <Grid item xs={6}>
            <Typography variant={'h6'}>{t('selectMonth.label')}</Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '50%', padding: '20px 0 0 0' }}>
              <DatePicker
                variant="inline"
                openTo="year"
                views={["month", "year"]}
                value={monthYear}
                size="small"
                onChange={handleChange}
                InputProps={
                  { style: { minWidth: 250, padding: 12 } }
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <CustomTable
              data={mttrReportList}
              column={mttrReportColumn}
              loading={mttrLoading}
              handleItemClick={handleItemClick}
            />
          </Grid>
        </Grid>
      </div>
      <MttrCustomersList
        open={showMttrList}
        handleClose={evt => setShowMttrList(false)}
        data={mttrCustomerListData}
        column={mttrCustomerColumn}
      />
    </Layout>
  );
};
export default withTranslation()(MttrAnalytics);
