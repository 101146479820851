import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const OpportunityFilesNigeriaItems = ({
                                      t,
                                      key,
                                      script,
                                      handleMenuItemClick
                                  }) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <TableRow key={key}>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {script.opportunity_file}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'subtitle2'}>
                    {script.comment}
                </Typography>
            </TableCell>
            <TableCell>
                <Grid>
                    <IconButton
                        size={'small'}
                        disableRipple={true}
                        disableFocusRipple={true}
                        color={'secondary'}
                        onClick={evt => {
                            setAnchorEl(evt.currentTarget);
                            evt.stopPropagation();
                        }}>
                        <MoreHoriz className={commonClasses.iconRegular}/>
                    </IconButton>
                </Grid>
                {anchorEl && (
                    <MenuWrapper
                        id={'ticket-menu'}
                        anchorEl={anchorEl}
                        handleClose={evt => {
                            evt.stopPropagation();
                            setAnchorEl(null);
                        }}
                        menuItems={[{label: t('delete.label')}, {label: t('edit.label')}]}
                        handleMenuItemClick={elem => {
                            handleMenuItemClick(elem, script, 'opportunityFile');
                            setAnchorEl(null);
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
OpportunityFilesNigeriaItems.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(OpportunityFilesNigeriaItems);
