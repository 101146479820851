import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Message from "../../../../components/Message";
import { REQUIRED_ERROR } from "../../../../utils/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useMutation } from "@apollo/react-hooks";
import { CONFIGURE_UCPE, DELETE_CONFIGURE_UCPE_INTERFACE } from "../../mutations";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from '@material-ui/core/InputBase';
import { fade } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    input: {
        minWidth: '224px'
    },
    inputBase: {
        marginTop: '24px',
        width: '100%',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 15,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    label: {
        color: 'black'
    }
}));
function valuetext(value) {
    return `${value}°C`;
}
function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const types = [
    {
        id: 'Management',
        name: 'Management'
    },
    {
        id: 'Bridge-TAP',
        name: 'Bridge-TAP'
    },
    {
        id: 'Bridge-DPDK',
        name: 'Bridge-DPDK'
    },
    {
        id: 'sr-iov',
        name: 'SRIOV'
    },
    {
        id: 'WAP',
        name: 'WAP'
    }
];
const connections = [
    {
        id: 'VNF_MANAGEMENT',
        name: 'VNF Management'
    },
    {
        id: 'INBAND_MANAGEMENT',
        name: 'InBand Management'
    },
    {
        id: 'DATA',
        name: 'Data'
    }
];
const adapters = [
    {
        id: 2,
        name: 2
    },
    {
        id: 3,
        name: 3
    },
    {
        id: 4,
        name: 4
    },
    {
        id: 5,
        name: 5
    },
    {
        id: 6,
        name: 6
    }
];
const wirelessMode = [
    {
        id: '802_11_g_n',
        name: '802.11 g/n'
    },
    {
        id: '802_11_g',
        name: '802.11 g'
    }
];
const wirelessConnections = [
    {
        id: 'DATA',
        name: 'Data-Communication'
    }
];
function CreateUcpeWapConfigure({
    t,
    open,
    networkInterfaceList,
    ucpeWapLoadingModal,
    device_id,
    data,
    ucpeInterfaceList,
    handleCreateUcpeWapConfigure,
    handleCheckInterfaceExits,
    handleClose,
    modalState,
    countryCodes
}) {
    const organization_id = data.organizationId
    const interfaceInitField = {
        en0: '',
        type: '',
        editable: false,
        bridgeName: '',
        typeOfConnection: '',
        nic: '',
        vnfIpAddress: '',
        managementIpAddress: '',
        port: '',
        communication: '',
        number_of_adaptors: '',
        countryCode: '',
        wirelessBand: '',
        wirelessMode: '',
        wirelessTypeOfConnection: '',
        radioChannel: '0 (Auto)',
        wirelessBridgeName: '',
        wirelessInterfaces: ''
    };
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setInterfaceState([interfaceInitField]);
            handleClose();
        }
    }, [modalState]);
    const [interfaceState, setInterfaceState] = useState([
        { ...interfaceInitField }
    ]);
    const [
        removeSelectedInterfaceField,
        setSelectedRemoveInterfaceField
    ] = useState([]);
    const [removeFieldState, setRemoveFieldState] = useState({ interface: [] });
    const [removeFieldInterfaceNameState, setRemoveFieldInterfaceNameState] = useState({ interface_name: [] });
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    React.useEffect(() => {
        setInterfaceState([]);
        // if (ucpeInterfaceList && ucpeInterfaceList.length > 0) {
        //     ucpeInterfaceList.map((item, index) => {
        //         item.editable = true;
        //         setInterfaceState(oldArray => [...oldArray, { ...item }]);
        //     })
        // } else {
            setInterfaceState([interfaceInitField]);
        // }
    }, [ucpeInterfaceList]);
    if (countryCodes.length) {
        console.log("countryCodes : ",countryCodes);
        countryCodes = countryCodes.map((item) => {
            item.name = item.flag;
            item.id = item.dial_code;
            return item;
        });
        console.log("after countryCodes : ",countryCodes);
    }
    const addNewInterfaceField = () => {
        setInterfaceState(oldArray => [...oldArray, { ...interfaceInitField }]);
    };
    // open configure model
    const openConfigureSSIDModel = () => {
        // setInterfaceState(oldArray => [...oldArray, { ...interfaceInitField }]);
    };
    // Delete Interface
    const [deleteDeviceInterface] = useMutation(DELETE_CONFIGURE_UCPE_INTERFACE, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: data => {
            if (data.deleteConfigureUcpeInterface.status) {
                handleClose();
                Message.success('Ucpe configuraton interface deleted successfully');
            }
        }
    });
    const removeInterfaceField = () => {
        console.log(removeFieldInterfaceNameState)
        if (window.confirm("Are you sure want to delete?")) {
            deleteDeviceInterface({
                variables: {
                    organisation_id: organization_id,
                    device_id: device_id,
                    interface_name: removeFieldInterfaceNameState.interface_name
                }
            })
            const updatedInterface = [...interfaceState];
            const updateRemoveState = { ...removeFieldState };
            for (let index of updateRemoveState.interface) {
                index = parseInt(index);
                updatedInterface.splice(index, 1);
            }
            setInterfaceState(updatedInterface);
            const newRmState = { ...removeFieldState };
            newRmState.interface = [];
            setRemoveFieldState(newRmState);
        }
    };
    const onHandleSelectInterfaceRow = (evnt, index, item) => {
        let val = evnt.target.value.toString();
        let updatedState = { ...removeSelectedInterfaceField };
        updatedState[index] = evnt.target.checked;
        setSelectedRemoveInterfaceField(updatedState);
        if (evnt.target.checked) {
            setRemoveFieldState(prevState => ({
                ...prevState,
                interface: [...prevState.interface, val]
            }));
            setRemoveFieldInterfaceNameState(prevState => ({
                ...prevState,
                interface_name: [...prevState.interface_name, item.en0]
            }));
        } else {
            let prevRemoveInterfaceState = { ...removeFieldInterfaceNameState };
            let elemIdx2 = prevRemoveInterfaceState.interface_name.indexOf(val);
            prevRemoveInterfaceState.interface_name.splice(elemIdx2, 1);
            setRemoveFieldInterfaceNameState(prevRemoveInterfaceState);
            let prevRemoveState = { ...removeFieldState };
            let elemIdx = prevRemoveState.interface.indexOf(val);
            prevRemoveState.interface.splice(elemIdx, 1);
            setRemoveFieldState(prevRemoveState);
        }
    };
    const handleInterfaceChange = (value, idx, column) => {
        if (column === "en0") {
            handleCheckInterfaceExits({ value, device_id });
        }
        const updatedInterface = [...interfaceState];
        updatedInterface[idx][column] = value;
        setInterfaceState(updatedInterface);
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ucpe-modal"
            aria-describedby="ucpe-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('configure.label')}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => {
                            addNewInterfaceField();
                        }}>
                        {t('add.label')} {t('configure.label')}
                    </Button>
                    <Button
                        color="secondary"
                        variant={'contained'}
                        style={{ ...marginGenerator('ml-20') }}
                        disabled={!removeFieldState.interface.length}
                        onClick={() => {
                            removeInterfaceField();
                            setSelectedRemoveInterfaceField([]);
                        }}>
                        {t('remove.label')}
                    </Button>
                </Grid>
                {!ucpeWapLoadingModal ? (
                    interfaceState.map((val, idx) => {
                        return (
                            <React.Fragment key={`interface-${idx}`}>
                                <Grid
                                    key={`heading-${idx}`}
                                    item
                                    xs={12}
                                    style={{
                                        ...paddingGenerator('pl-5'),
                                        ...paddingGenerator('pl-0'),
                                        ...marginGenerator('mt-24')
                                    }}>
                                    <Typography variant="subtitle2" color="textPrimary">
                                        <Checkbox
                                            style={paddingGenerator('pl-0')}
                                            checked={removeSelectedInterfaceField[idx]}
                                            value={idx}
                                            onChange={event => {
                                                onHandleSelectInterfaceRow(event, idx, val);
                                            }}
                                        />
                                        {t('interface.label')} {idx + 1}
                                    </Typography>
                                </Grid>
                                <Grid key={`en0-${idx}`} style={marginGenerator('mt-24')}>
                                    <OutlinedSelect
                                        val={interfaceState[idx].en0}
                                        disabled={interfaceState[idx].editable ? true : null}
                                        label={`${t('interfaces.label')}`}
                                        handleChange={evt =>
                                            handleInterfaceChange(evt.target.value, idx, 'en0')
                                        }
                                        selectStyle={{ ...paddingGenerator('p-8') }}
                                        styleOverrides={{ width: '100%', marginRight: 0 }}
                                        options={networkInterfaceList}
                                    />
                                </Grid>
                                    <React.Fragment>
                                        <Grid>
                                            <Autocomplete
                                                style={{ width: '100%' }}
                                                options={countryCodes}
                                                getOptionLabel={(option) => option.flag}
                                                onChange={(evt, newValue) => {
                                                    setCountryCode(newValue ? newValue.dial_code : '');
                                                }}
                                                renderInput={(params) => (
                                                    <InputBase
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        className={classes.inputBase}
                                                        placeholder={t('countryCode.label')}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            key={`wirelessBand-${idx}`}
                                            style={marginGenerator('mt-12')}>
                                            <OutlinedSelect
                                                val={interfaceState[idx].wirelessBand}
                                                label={`${t('wirelessBand.label')} *`}
                                                handleChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'wirelessBand'
                                                    )
                                                }
                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                styleOverrides={{ width: '100%', marginRight: 0 }}
                                                options={connections ? connections : []}
                                            />
                                        </Grid>
                                        <Grid
                                            key={`wirelessMode-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <OutlinedSelect
                                                val={interfaceState[idx].wirelessMode}
                                                label={`${t('wirelessMode.label')} *`}
                                                handleChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'wirelessMode'
                                                    )
                                                }
                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                styleOverrides={{ width: '100%', marginRight: 0 }}
                                                options={wirelessMode ? wirelessMode : []}
                                            />
                                        </Grid>
                                        <Grid
                                            key={`wirelessTypeOfConnection-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <OutlinedSelect
                                                val={interfaceState[idx].wirelessTypeOfConnection}
                                                label={`${t('typeOfConnection.label')} *`}
                                                handleChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'wirelessTypeOfConnection'
                                                    )
                                                }
                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                styleOverrides={{ width: '100%', marginRight: 0 }}
                                                options={wirelessConnections ? wirelessConnections : []}
                                            />
                                        </Grid>                                                                             
                                        <Grid
                                            key={`radioChannel-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <Typography variant="subtitle2" className={classes.label} gutterBottom>{`${t('radioChannel.label')}*`}</Typography>
                                            <Input
                                                value={interfaceState[idx].radioChannel}
                                                type={'text'}
                                                disabled ={true}
                                                placeholder={`${t('radioChannel.label')}`}
                                                style={{ width: '100%' }}
                                                color={'secondary'}
                                                onChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'radioChannel'
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            key={`wirelessBridgeName-${idx}`}
                                            style={marginGenerator('mt-24')}>
                                            <Input
                                                value={interfaceState[idx].wirelessBridgeName}
                                                type={'text'}
                                                placeholder={`${t('bridgeName.label')}*`}
                                                style={{ width: '100%' }}
                                                color={'secondary'}
                                                onChange={evt =>
                                                    handleInterfaceChange(
                                                        evt.target.value,
                                                        idx,
                                                        'wirelessBridgeName'
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                {/* } */}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <>
                        {'Loading...'}
                        <LinearProgress color="primary"
                            style={{ top: '0px', left: '0px', position: 'absolute', width: '100%' }} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            console.log('interfaceState', interfaceState);
                            if (interfaceState[0].en0 == '') {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateUcpeWapConfigure(interfaceState);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateUcpeWapConfigure.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateUcpeWapConfigure);
