import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Popup from '../../Popup';

const LogsPopup = ({
  logsData,
  openLogsPopup,
  setOpenLogsPopup,
  loading,
  classes
}) => {
  return (
    <Popup
      title="Logs"
      openPopup={openLogsPopup}
      setOpenPopup={setOpenLogsPopup}
      width="md">
      {' '}
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item container justify="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ color: 'white' }}>
                    <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                      <TableCell
                        style={{ color: 'white', paddingLeft: '10px' }}>
                        Timestamp
                      </TableCell>
                      <TableCell style={{ color: 'white' }} align="left">
                        User
                      </TableCell>
                      <TableCell
                        style={{ color: 'white', width: '150px' }}
                        align="left">
                        Message
                      </TableCell>
                      <TableCell style={{ color: 'white' }} align="left">
                        Severity
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logsData?.length !== 0 ? (
                      logsData.map(item => (
                        <TableRow key={item._id}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ paddingLeft: '10px' }}>
                            {moment(item.createdAt).format(
                              'YYYY-MM-DD HH:mm:ss a'
                            )}
                          </TableCell>
                          <TableCell align="left">{item.user.name}</TableCell>
                          <TableCell align="left">{item.message}</TableCell>
                          <TableCell align="left">{item.severity}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {' '}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenLogsPopup(false);
          }}>
          Close
        </Button>
      </DialogActions>
    </Popup>
  );
};

export default LogsPopup;
