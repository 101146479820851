import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
      width: "100%"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px'},
  pre: { padding: '10px 30px', margin: '0' }
});

export default function NodeDetailsModal({t, open, handleClose, cameras}) {
  const classes = useStyles();
  
  return (
      <Dialog 
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('mappedCam.label')}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>{t('camId.label')}</TableCell>
                        <TableCell>{t('camt4Id.label')}</TableCell>
                        <TableCell>{t('cameraName.label')}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {cameras.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.camera_id ? row.camera_id : row.name}
                            </TableCell>
                            <TableCell>{row.t4_cam_id}</TableCell>
                            <TableCell>{row.name}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
      </Dialog>
  );
}
