// coming soon
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Message from "../../../../../components/Message";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import { ADD_UPDATE_CATEGORY, DELETE_VNF_MARKETPLACE, DELETE_VNF_MARKETPLACE_CATEGORY } from "../../mutations";
import { GET_CATEGORIES, GET_OUTSIDE_PROJECT_MANAGEMENT_LIST } from "../../queries";
import { USER_ROLES } from "../../../../../utils/constants";
import Header from "../../../../../components/Header";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OutsideProjectManagementItem from "../OutsideProjectManagementItem";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
const OutsideProjectManagement = ({ t, refreshTab, handleRefreshTab }) => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [list, setList] = useState([]);
    const menuItems = [
        {
            id: 'Assign_project',
            name: t('assignee.label')
        }
    ];
    useEffect(() => {
        getAllList({
            variables: {
                pageNumberOrUid: 'page=1&project_owner=is_admin'
            }
        })
    }, []);
    const handlePageChange = (event, value) => {
        setLoading(true);
        setError(true);
        setPage(value);
        getAllList({
            variables: {
                pageNumberOrUid: 'project_owner=is_admin&page=' + value
            }
        })
    };
    const [getAllList] = useLazyQuery(GET_OUTSIDE_PROJECT_MANAGEMENT_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setLoading(false);
            setError(false);
            // console.log("im data : ", data);
            if (data && data.getOutsideProjectManagementTeamList) {
                let parseResult = JSON.parse(data.getOutsideProjectManagementTeamList.teamListData);
                // console.log("parseResult :", parseResult)
                setList(parseResult);
            } else {
                setList([])
            }
        },
        onError: error => {
            console.log("error :", error)
            setList([])
            setLoading(false);
            setError(false);
        }
    });
    const handleCreateButtonClick = searchParam => {
        // setCategoryModalState(true);
    };
    const columns = [
        {
            label: t('caseId.label'),
            key: "case_number"
        },
        {
            label: t('customer.label') + ' / ' + t('organisationName.label'),
            key: "customer_name"
        },
        {
            label: t('installTeam.label'),
            key: "install_team"
        },
        {
            label: t('action.label'),
            key: "button"
        }
    ];
    const handleMenuItemClick = (elem, data) => {
        // if (elem.label === t('edit.label')) {
        //     // setModalState({...modalState, btnLoading: false});
        //     // setUpdateCategory(data);
        //     // setCategoryModalState(true);
        // } else if (elem.label === t('delete.label')) {
        //     if (
        //         window.confirm('Are you sure you want to delete this marketplace category ?') ===
        //         false
        //     ) {
        //         return false;
        //     }
        //     /*  return deleteMarketPlaceCategory({
        //           variables: {
        //               category_id: data._id
        //           }
        //       });*/
        // }
    }

    const handleClose = () => {
        // setCategoryModalState(false);
    }

    const handleSubmitClick = (formData) => {
        // setModalState({...modalState, btnLoading: true });
        //   return addUpdateCategory({ variables: formData });
    }

    return (
        <>
            {(role.includes(USER_ROLES.IP_VNFADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) &&
                <Header
                    title={t('projectManagementTeamSection.label')}
                    subText={`${t('projectManagementTeamSection.label')}`}
                    // primaryButtonText={t('createNew.label')}
                    handlePrimaryButtonClick={handleCreateButtonClick}
                />}
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.results && list.results.length ?
                                    list.results.map((item, index) => (
                                        <OutsideProjectManagementItem data={item} key={index} handleMenuItemClick={handleMenuItemClick}
                                            menuItems={menuItems} />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                            <TableRow>
                                {loading &&
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Loading...</Typography>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                    {!loading &&
                        <div>
                            <Pagination color="secondary" className={classes.paging}
                                count={parseInt(list
                                    && list.pagination
                                    && list.pagination.total_pages ? list.pagination.total_pages : 0)} page={page}
                                onChange={handlePageChange} />
                        </div>
                    }
                </TableContainer>
            </Grid>
        </>
    );
};
export default withTranslation()(OutsideProjectManagement);
