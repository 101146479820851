import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import Image from "../../../../assets/icons/image.png";
import Close from "@material-ui/icons/Close";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
const projectStatusList = ['NACR sent for Signoff', 'NACR Not Required', 'NACR Approved', 'NACR Declined', 'Generate OPEX COST and New Service', 'Control Doc Sent for Approval', 'Generate Purchase Request', 'Request for Site Survey', 'Requested for Detailed Engineering Doc', 'Completed', 'Rejected']

function ProjectManagementDIANModal({
                                        t,
                                        open,
                                        handleSaveProjectManagement,
                                        handleClose,
                                        data,
                                        modalState
                                    }) {
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const [projectFile, setProjectFile] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        setFileName(img && img.name)
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            console.log(reader.result);
            setProjectFile(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                Update Project Status
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    xs={12}>
                    This form will update project status for the given opportunity.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        opportunityId: "",
                        status: "",
                        user: ""
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        console.log('values', JSON.stringify(values))
                        const requestPayload = {
                            opportunityId: "",
                            status: values.status
                        }
                        handleSaveProjectManagement(requestPayload)
                        resetForm()
                        //alert(JSON.stringify(values));
                    }}
                    validationSchema={Yup.object().shape({
                        status: Yup.string()
                            .required('Please enter Status')
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid style={{...paddingGenerator('pb-30')}}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-10')
                                        }}>
                                        <Grid container spacing={3} style={{
                                            ...marginGenerator('mt-8')
                                        }}>
                                            <Grid item xs={12} style={marginGenerator('mt-16')}>
                                                <OutlinedSelect
                                                    val={values.status}
                                                    label={`${t('status.label')} *`}
                                                    name={'status'}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    selectStyle={{...paddingGenerator('p-8')}}
                                                    styleOverrides={{width: '100%', marginRight: 0}}
                                                    options={projectStatusList.map(item => {
                                                        return {id: item, name: item}
                                                    })}
                                                />
                                                {(errors.status && touched.status) &&
                                                <FormHelperText
                                                    style={{marginTop: '20px'}}>{errors.status}</FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Grid container justify={'center'}>
                                        <Button
                                            style={{marginRight: '5px'}}
                                            variant={'outlined'}
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            {t('cancel.label')}
                                        </Button>
                                        <ButtonComponent t={t} loading={modalState.btnLoading}/>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

ProjectManagementDIANModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSaveProjectManagement: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ProjectManagementDIANModal);
