import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../../../components/OutlinedSelect';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
                {loading && <CircularProgress size={14} />}
                {!loading && t('submit.label')}
        </Button>
    );
}

function CreateServiceTypeModal({
    t,
    open,
    data,
    handleCreateServiceTypeSubmit,
    handleClose,
    modalState,
    countriesList
}) {
    const [name, setName] = useState('');
    const [countries, setCountries] = useState([]);
    const classes = useStyles();
    
    React.useEffect(() => {
        if(modalState.clearData && open){
            setName('');
            setCountries([]);
        }
    }, [modalState]);

    React.useEffect(() => {
        if(data && data.id){
            setName(data.name);
            setCountries(data.countryId);
        }
    }, [data]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="place-template-title">{t('createNew.label')} {t('service.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <Input
                            value={name}
                            type={'text'}
                            placeholder={`${t('service.label')} ${t('name.label')}*`}
                            style={{width: '100%'}}
                            color={'secondary'}
                            onChange={evt => setName(evt.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <OutlinedSelect
                            val={countries}
                            label={`${t('countryEnabled.label')} *`}
                            handleChange={evt => {
                                setCountries(evt.target.value)
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{ width: '100%', marginRight: 0 }}
                            options={countriesList && countriesList.length > 0 ? countriesList : []}
                            multiple={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent 
                    t={t} 
                    onClick={() => {
                        if(!name){
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        let postData = { name: name, countries };
                        if(data && data.id){
                            postData.id = data.id;
                        }
                        handleCreateServiceTypeSubmit(postData);
                    }}
                    loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateServiceTypeModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateServiceTypeSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateServiceTypeModal);