import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { colors } from '../../theme/colors';
import { fade } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

const BarChartWrapper = ({
  data,
  dataKeyXAxis,
  dataKeyChart,
  stacked = false,
  stackKeys,
  stackKeyNames,
  loading
}) => {
  return (
    <div style={{ width: '100%', height: 322, position: "relative", marginBottom:130 }}>
      {loading && <div style={{position: "absolute", width: "100%", height: "100%", textAlign: "center", backgroundColor: "#76747485", zIndex: 999}}> <CircularProgress style={{marginTop:"12%"}} color="inherit" size={35}/></div>}
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid stroke={colors.grey[200]} />
          <XAxis
            dataKey={dataKeyXAxis}
            stroke={colors.grey[200]}
            dy={10}
            tick={{ fill: colors.grey[400], fontSize: 12, marginTop: 20 }}
          />
          <YAxis
            stroke={colors.grey[200]}
            dx={-10}
            tick={{ fill: colors.grey[400], fontSize: 12 }}
          />
          <Tooltip />
          {!stacked ? (
            <Bar
              maxBarSize={32}
              dataKey={dataKeyChart}
              fill={colors.chartColors.graphBlue}
              stackId={1}
            />
          ) : (
            stackKeys.map((item, index) => {
              const fadeVal = Math.abs(
                1 - Math.floor((index / stackKeys.length) * 10) / 10
              );
              return (
                <Bar
                  key={index}
                  maxBarSize={32}
                  dataKey={item}
                  name={stackKeyNames[index]}
                  fill={fade(colors.chartColors.graphBlue, fadeVal)}
                  stackId={1}
                />
              );
            })
          )}
          {stacked && (
            <Legend
              verticalAlign={'bottom'}
              align={'center'}
              layout={'horizontal'}
              wrapperStyle={{
                color: colors.common.black,
                fontSize: 14,
                bottom: -16
              }}
              iconType={'circle'}
              iconSize={12}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartWrapper;
