import React from 'react';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import { colors } from '../../../../theme/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  },
  container: {
    padding: '10px'
  }
}));

export default function PaginationTable({
  t,
  loading,
  data,
  column,
  handlePageChange,
  page,
  totalCount
}) {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            padding: 20,
            textAlign: 'center',
            display: 'inline-block',
          
          }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableContainer className={classes.container} style={{ maxHeight: '500px', overflowY: 'auto', padding: '20px'}}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {column &&
                  column.map(column => (
                    <TableCell key={uuidv4()}>{column.name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map(row => (
                  <StyledTableRow key={uuidv4()}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.IncidentNotStarted}</TableCell>
                    <TableCell>{row.IncidentInProgress}</TableCell>
                    <TableCell>{row.IncidentCompleted}</TableCell>
                    <TableCell>{row.TotalIncident}</TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={column.length}>
                    <Typography variant={'subtitle2'}>
                      Record not found!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div style={{ width: '100%', marginTop: 24,   marginBottom:130 }}>
        <Pagination
          style={{ float: 'right' }}
          count={Math.ceil(totalCount / 10)}
          color="secondary"
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}
