/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { get } from "lodash-es";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TableContainer from '@material-ui/core/TableContainer';
import CSVReader from 'react-csv-reader';

import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import AttachFile from '@material-ui/icons/AttachFile';

import Message from './Message/index';
import {
    SALES_TRACKER_UPLOAD_FILE
} from './commonMutations';
import Table from '@material-ui/core/Table';
import { Button, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ProductDetail from "./ProductDetail"
import Pagination from '@material-ui/lab/Pagination';
import ChartContainer from '../../../../../../../components/Charts/ChartContainer';
import commonStyles from '../../../../../../../theme/commonStyles';
import { dateFormatterLLL, convertUTCDateToLocalDate, convertLocalToUTCDate, getStartEndTimeForReport } from '../../../../../../../utils';
import {
    GET_PRODUCT_LIST,
} from './queries';




function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick}
            disabled={loading || disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Export'}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2),
        position: "relative",
        overflow: "hidden"
    },
    dateInput: {
        width: 250,
        minWidth: 200,
        padding: 10
    },
    infoWindowLoading: {
        position: "absolute",
        width: "100%",
        height: 352,
        backgroundColor: "#9fa0a26e",
        top: 0,
        textAlign: "center",
        paddingTop: 130,
        zIndex: 999
    },

    button: {
        marginLeft: theme.spacing(2),
    },

}));




const initMapFilter = { time: 'Today', startDate: null, endDate: null, placeId: '', projectId: '' };

const OcrConfigurationDocumentsReport = ({
    t,
    ocrPlaceData,
    ocrProjectData,
    loadingOcrPlace,
    loadingOcrProject,
    ocrDocumentsListing,
    ocrFetchLoadingForDocuments,
    handleFilterdataForOptions,
    handleSubmitOrFilterForDocumentConfigTab
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [ocrDocumentReportState, setOcrDocumentReportState] = useState({ ...initMapFilter });
    const [documentsDataForEcel, setDocumentsDataForEcel] = useState([]);
    //const [inputValue, setInputValue] = React.useState('');
    const [uploadedFileLoading, setUploadedFileLoading] = useState(false);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [productList, setProductList] = useState(null)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState()
    const [productCount, setproductCount] = useState()
    const inputFile = React.createRef();
    const [btnSearch, setbtnSearch] = useState(true)
    const [isDownloading, setDownloading] = useState(false);

    const [salestrackeruploadDocument, { loading: uploading }] = useMutation(SALES_TRACKER_UPLOAD_FILE, {
        onCompleted: data => {
            Message.success('File Uploaded Succesfully!');
            setUploadedFileLoading(false);
        },
        onError: e => {
            setUploadedFileLoading(false);;
            console.log(e);

        }
    });


    const [getProductList, { loading: getproductLoading }] = useLazyQuery(GET_PRODUCT_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data && data.getProductList) {
                setProductList(data?.getProductList?.results?.data);
                setproductCount(data?.getProductList?.count)
                console.log(data, "pdlist")
            }
            // setOcrFetchLoading(false);
        },
        onError: error => {
            Message.error(error);
            // setOcrFetchLoading(false);
        }
    });

    useEffect(() => {
        getProductList({
            variables: {
                page,
                search: search ? search : null
            }
        });
    }, [page])

    useEffect(() => {
        setPage(1);
        getProductList({
            variables: {
                page,
                search: search ? search : null
            }
        });
    }, [btnSearch])
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let wscols = [];
    const Heading = [
        {
            documentName: "Document Name",
            uploadedOn: "Uploaded On",
            uploadedBy: "Uploaded By"
        }
    ];

    const exportToCSV = (csvData, fileName, wscols) => {
        console.log('wscols', wscols);
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["documentName", "uploadedOn", "uploadedBy"],
            skipHeader: true,
            origin: 0 //ok
        });
        ws["!cols"] = wscols;
        XLSX.utils.sheet_add_json(ws, csvData, {
            header: ["documentName", "uploadedOn", "uploadedBy"],
            skipHeader: true,
            origin: -1 //ok
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    let columns = [
        {
            label: t('sNo.label'),
            key: "sno"
        },
        {
            label: 'Product Name',
            key: "name"
        },
        {
            label: 'Product Details',
            key: "Sub Products"
        }
    ];

    const handleAutoPlaceSearch = (type, value) => null;

    const handleClearFilter = (type) => {
        if (type === "place_map") {
            setOcrDocumentReportState({ ...initMapFilter });
            //this code will require in future
            // const tryee =  ocrDasboardMapFilter[2].handleChange = (evt, newValue) => {
            //     // debugger;
            //     // console.log('called');
            //     // if(newValue === undefined){
            //     // debugger;
            //     //      setOcrDocumentReportState({...ocrDocumentReportState, placeId: ''});
            //     // }
            // };

            // tryee();
        }
    };

    // const ocrDasboardMapFilter = [
    //     {
    //         label: t("startDate.label"),
    //         handleChange: (newDate) => {
    //             setOcrDocumentReportState({ ...ocrDocumentReportState, startDate: newDate });
    //         },
    //         val: ocrDocumentReportState.startDate,
    //         type: "input",
    //         styleOverrides: { marginRight: 24 },
    //         className: classes.dateInput
    //     },
    //     {
    //         label: t("endDate.label"),
    //         handleChange: (newDate) => {
    //             setOcrDocumentReportState({ ...ocrDocumentReportState, endDate: newDate })
    //         },
    //         val: ocrDocumentReportState.endDate,
    //         type: "input",
    //         styleOverrides: { marginRight: 24 },
    //         className: classes.dateInput,
    //     },
    //     {
    //         label: t('places.label'),
    //         handleChange: (evt, newValue) => {
    //             if (!newValue) {
    //                 handleAutoPlaceSearch("eamp", "");
    //             }
    //             handleFilterdataForOptions(newValue && newValue.id, 'project');
    //             setOcrDocumentReportState({ ...ocrDocumentReportState, placeId: newValue && newValue.id });
    //         },
    //         onInputChange: (val) => {
    //             handleAutoPlaceSearch("eamp", val);
    //         },
    //         handleClose: (evt) => {
    //             handleAutoPlaceSearch("eamp", "");
    //         },
    //         val: ocrDocumentReportState.placeId,
    //         options: (!loadingOcrPlace && ocrPlaceData) ? ocrPlaceData.data : [],
    //         loading: loadingOcrPlace,
    //         type: "autocomplete",
    //         //inputValue: inputValue,
    //         styleOverrides: { marginRight: 0, display: "inline-block", width: 260, marginTop: 10 },
    //     },
    //     {
    //         label: t('projects.label'),
    //         handleChange: evt => {
    //             setOcrDocumentReportState({ ...ocrDocumentReportState, projectId: evt.target.value });
    //         },
    //         val: ocrDocumentReportState.projectId,
    //         options: loadingOcrProject ? ([<MenuItem value={null}>Loading ...</MenuItem>]) : (!loadingOcrProject && ocrProjectData) ? ocrProjectData.data : [],
    //         styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
    //     }
    // ];


    useEffect(() => {
        let startTime = "";
        let endTime = "";
        if (ocrDocumentReportState.startDate && ocrDocumentReportState.endDate) {
            startTime = new Date(convertLocalToUTCDate(ocrDocumentReportState.startDate));
            startTime = Math.floor(startTime.getTime() / 1000);
            endTime = new Date(convertLocalToUTCDate(ocrDocumentReportState.endDate));
            endTime = Math.floor(endTime.getTime() / 1000);
        }

        handleSubmitOrFilterForDocumentConfigTab({
            startTime,
            endTime,
            place: ocrDocumentReportState.placeId,
            project: ocrDocumentReportState.projectId
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocrDocumentReportState]);

    useEffect(() => {
        const docs = [];
        if (ocrDocumentsListing && ocrDocumentsListing.data.length > 0) {
            ocrDocumentsListing.data.map((el, i) => (
                docs.push({
                    documentName: el.folder_name + '/' + el.file_name,
                    uploadedOn: dateFormatterLLL(el.created_at),
                    uploadedBy: el.created_by_name
                })
            ));
        }
        if (docs.length > 0) {
            wscols = [
                { wch: Math.max(...docs.map(doc => doc.documentName.length)) },
                { wch: Math.max(...docs.map(doc => doc.uploadedOn.length)) },
                { wch: Math.max(...docs.map(doc => doc.uploadedBy ? doc.uploadedBy.length : null)) },
            ];
        }

        setDocumentsDataForEcel(docs);
    }, [ocrDocumentsListing]);


    const handleFileUploadClick = () => {
        setUploadedFileLoading(true)
        return salestrackeruploadDocument({
            variables: {
                file: uploadedFile
            }
        });
    }

    const handlePagination = (evt, value) => {
        setPage(value)

    }

  


        const handleDownloadTemplateClick = () => {
            const fileUrl = 'https://inq-docparser.s3.af-south-1.amazonaws.com/fanmilk/TEMPLATE-FAN+MILK+PRODUCT+BARCODES.xlsx';

            // Disable the button
            setDownloading(true);

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'TEMPLATE-FAN+MILK+PRODUCT+BARCODES.xlsx';

            link.onclick = () => {
                // Re-enable the button once the download is initiated
                setDownloading(false);
            };

            // Trigger a click event on the anchor element
            link.click();
        };

       




    return (
        <>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
               
            <Grid item xs={12} sm={6} md={4}>
                    <Button

                        variant={'contained'}
                        style={{ width: '40%' }}
                        color={'primary'}
                        onClick={handleDownloadTemplateClick}
                    >
                        {isDownloading ? 'Downloading...' : 'Template'}

                    </Button>

                </Grid>
                <Typography variant="subtitle2" style={{ marginRight: '16px' }}>Upload Barcode File</Typography>
                <input
                    type="file"
                    id="attachment"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ marginRight: '16px' }}
                    onChange={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        setUploadedFile(event.target.files[0]);
                    }}
                />

                {uploadedFile && (
                    <Button
                        style={{ minWidth: '80px' }}
                        color="secondary"
                        variant="contained"
                        disabled={uploadedFileLoading}
                        onClick={handleFileUploadClick}
                    >
                        {uploadedFileLoading ? <CircularProgress size={14} /> : 'Upload'}
                    </Button>
                )}
            </div>


            {/* 
            <ChartContainer
                title={null}
                filterList={ocrDasboardMapFilter}
                clearFilter={evt => handleClearFilter("place_map")}
            >
                {null}
            </ChartContainer>
            <ButtonComponent
                t={t}
                onClick={e => exportToCSV(documentsDataForEcel, `InqDMS_DocumentsReport-${moment(new Date()).format("DD MMM YYYY")}`, wscols)}
                loading={false}
                disabledValue={ocrFetchLoadingForDocuments || (ocrDocumentsListing && ocrDocumentsListing.data.length === 0) || documentsDataForEcel.length === 0}
            /> */}



            <Grid
                container
                style={{
                    minHeight: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch', // Equal height for both items
                    gap: '10px', // Space between items
                }}
            >
                
                <Grid item xs={12} sm={6} md={4}>

                    <Input
                        variant={'outlined'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        placeholder="Query by product name and barcode"
                        onChange={evt => setSearch(evt.target.value)}

                    />
                </Grid>
                <Grid item xs={12} sm={2} md={1}>
                    <Button
                        disabled={getproductLoading}
                        variant={'contained'}
                        style={{ width: '100%' }}
                        color={'primary'}
                        onClick={() => { setbtnSearch(!btnSearch) }}
                    >
                        {getproductLoading ? <span> Fetching...</span> : "Search"}

                    </Button>
                </Grid>
            </Grid>



            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell key={index}>
                                    <Typography variant={'subtitle2'}>{item.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!getproductLoading && productList ? (
                            productList.map((rowitem, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Typography variant={'subtitle1'}>
                                                {rowIndex + 1}
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Typography variant={'subtitle1'}>
                                                {rowitem['Product Name']}
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <ProductDetail

                                                productName={rowitem['Product Name']}
                                                products={rowitem?.Products}

                                            />

                                        </Grid>
                                    </TableCell>

                                </TableRow>
                            ))
                        ) : (

                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>..Loading</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            {getproductLoading && (
                                <TableCell colSpan={columns.length}>
                                    {getproductLoading && <LinearProgress color="primary" />}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableBody>

                </Table>

                {!getproductLoading && <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={3}>
                        <Pagination
                            color="secondary"
                            count={Math.ceil(productCount / 10)}
                            page={page}
                            onChange={(evt, value) => {
                                handlePagination(evt, value);
                            }}
                        />
                    </Grid>
                </Grid>}

            </TableContainer>
        </>

    )
}

export default OcrConfigurationDocumentsReport