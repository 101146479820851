import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ProceedStepsList({t, steps, loading}) {
  return (
    <>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {loading ? <div style={{width: "100%", textAlign: "center", display: "inline-block"}}><CircularProgress size={21} /></div> 
                :
                steps && steps.length > 0 && steps.map(itm => (
                    <>
                        <ListItem alignItems="flex-start" style={{width: "100%"}}>
                            <ListItemText
                            primary={<Typography
                                style={{marginBottom: 10}}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                {itm.title}
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        style={{marginBottom: 10}}
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <b>Completed:</b>  {itm.isCompleted ? "Yes" : "No"}
                                    </Typography>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        style={{marginBottom: 8}}
                                        variant="body2"
                                        color="text.primary"
                                        >
                                        <b>Comments:</b> {itm.comments}
                                    </Typography>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <b>Decription:</b> {itm.description}
                                    </Typography>

                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider component="li" />
                    </>
                ))
        }
        </List>
        </>
  );
}
