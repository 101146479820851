import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {withTranslation} from 'react-i18next';
import {
    GET_ALL_PARTS,
    GET_NIGERIA_OPPORTUNITY_BY_ID, GET_NIGERIA_PROJECT_LOGS,
    GET_OPPORTUNITY_BY_ID,
    GET_OPPORTUNITY_DETAILS,
    GET_SUBSCRIPTION_DETAILS
} from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {
    InternalPaths,
    OrganisationPaths,
    Paths,
    ServiceTicketPaths,
    SubscriptionPaths
} from '../../routes/routePaths';
import {dateFormatterLLL, getParam} from '../../utils';
import {GET_SERVICE_TICKETS, GET_SERVICE_TICKETS_BY_SUBSCRIPTION_ID} from '../ServiceTickets/queries';
import SiteItem from './components/SiteItem';
import Typography from '@material-ui/core/Typography';
import {marginGenerator} from '../../theme/utils';
import {SERVICE_TYPES, SUBSCRIPTION_STATUS, USER_ROLES} from '../../utils/constants';
import {useLocation, useHistory} from 'react-router';
import {
    ADD_OPPORTUNITY_DOCUMENT, DECLINE_BY_FINANCE,
    MARK_OPPORTUNITY_FINANCE_APPROVED,
    MARK_OPPORTUNITY_VOID,
    OPPORTUNITY_ACTIVATE, SAVE_CONNECTIVITY_INFO, SAVE_PROJECT_FILE_DIAN, SAVE_PROJECT_INITIATE, SAVE_SITE_SURVEY, SAVE_STORE_FORM,
    UPDATE_DIA_SITE, UPDATE_DIAN_SITE, UPDATE_OPPORTUNITY_DOCUMENT, UPDATE_PROJECT_FILE_DIAN, UPDATE_PROJECT_STATUS_DIAN,
    UPDATE_SITE
} from './mutations';
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateOrderModal from "./components/CreateOrderModal";
import Message from "../../components/Message";
import CreateConnectivityInfoModal from "./components/CreateConnectivityInfoModal";
import AddOpportunityDocumentsModal from "./components/AddOpportunityDocumentsModal";
import CreateSiteSurveyModal from "./components/CreateSiteSurveyModal";
import ProjectManagementDIANModal from "./components/ProjectManagementDIANModal";
import ProjectFileDIANModal from "./components/ProjectFileDIANModal";
import ProjectManagementTrackStatusDianModal from "./components/ProjectManagementTrackStatusDianModal";
import CreateProjectInitiateModal from "./components/CreateProjectInitiateModal";
import CreateStoreFormModal from "./components/CreateStoreFormModal";

const SitePanel = ({t, data, type, parent, handleMenuItemClick, serviceType, circuitId}) => {
    const label =
        type === SUBSCRIPTION_STATUS.ACTIVE
            ? 'sitesActive.label'
            : 'sitesUnderProgress.label';
    return (
        <div>
            <Typography
                variant={'subtitle2'}
                style={{
                    ...marginGenerator('mt-32'),
                    ...marginGenerator('mb-32')
                }}>
                {data.length} {t(`${label}`).toUpperCase()}
            </Typography>
            {data.map((item, index) => (
                <SiteItem
                    parent={parent}
                    handleMenuItemClick={handleMenuItemClick}
                    circuitId={circuitId}
                    data={item}
                    key={index}
                    serviceType={serviceType}
                    type={type}
                />
            ))}
        </div>
    );
};

const PendingSubscriptionDetails = ({t}) => {
        const id = getParam();
        const location = useLocation();
        const history = useHistory();
        const role = localStorage.getItem('role');
        const [apiTimer, setApiTimer] = useState(0);
        let [breadcrumbList, setBreadcrumbList] = useState([
            {label: 'home.label', link: Paths.Dashboard},
            {label: 'subscriptions.label', link: Paths.Subscriptions},
            {
                label: 'pendingSubscriptionDetails.label',
                link: SubscriptionPaths.PendingSubscriptionDetails
            }
        ]);
        const [parent, setParent] = useState('');
        const [opportunityDetail, setOpportunityDetails] = useState("");
        const [openCreateOrderModalState, setOpenCreateOrderModalState] = useState(false);
        const [openConnectivityInfoModalState, setOpenConnectivityInfoModalState] = useState(false);
        const [openAddDocumentModalState, setOpenAddDocumentModalState] = useState(false);
        const [openSiteSurveyFormModalState, setSiteSurveyFormModalState] = useState(false);
        const [openProjectInitiateFormModalState, setProjectInitiateFormModalState] = useState(false);
        const [openStoreFormModalState, setStoreFormModalState] = useState(false);
        const [openProjectManagementModalState, setProjectManagementModalState] = useState(false);
        const [openProjectManagementStatusTrackModalState, setProjectManagementStatusTrackModalState] = useState(false);
        const [openProjectFileModalState, setProjectFileModalState] = useState(false);
        const [loadingProjectLogs, setProjectLogsLoading] = useState(true);
        const [projectLogs, setProjectLogs] = useState([]);
        const [projectFiles, setProjectFiles] = useState([]);
        const [opportunityFiles, setOpportunityFiles] = useState([]);
        const [circuitId, setCircuitId] = useState('');
        const [orderLoadModalState, setOrderLoadModalState] = useState({
            btnLoading: false,
            clearData: false
        });
        const [siteSurveyLoadModalState, setSiteSurveyLoadModalState] = useState({
            btnLoading: false,
            clearData: false
        });
        const [projectInitiateLoadModalState, setProjectInitiateLoadModalState] = useState({
            btnLoading: false,
            clearData: false
        });
        const [storeFormLoadModalState, setStoreFromLoadModalState] = useState({
            btnLoading: false,
            clearData: false
        });
        const [addDocumentLoadModalState, setAddDocumentLoadModalState] = useState({
            btnLoading: false,
            clearData: false
        });
        useEffect(() => {
            if (location.search === `?history=dashboard`) {
                breadcrumbList = breadcrumbList.filter((item, index) => index !== 1);
                setBreadcrumbList(breadcrumbList);
            } else if (location.search === '?history=organisations') {
                breadcrumbList = [
                    {label: 'home.label', link: InternalPaths.Dashboard},
                    {label: 'organisations.label', link: InternalPaths.organisations},
                    {label: 'details.label', link: OrganisationPaths.details},
                    {
                        label: 'pendingSubscriptionDetails.label',
                        link: SubscriptionPaths.PendingSubscriptionDetails
                    }
                ];
                setBreadcrumbList(breadcrumbList);
                setParent('organisation');
            }
        }, [location]);
        /*
           Queries
          */
        const {loading, data, error} = useQuery(GET_SUBSCRIPTION_DETAILS, {
            variables: {
                id
            }
        });
        const {loading: partsLoading, data: partsData, error: partsError} = useQuery(GET_ALL_PARTS);
        const [opportunityOpportunityDetails] = useLazyQuery(GET_OPPORTUNITY_DETAILS, {
            fetchPolicy: 'no-cache',
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                setOpportunityDetails(JSON.parse(data.getOpportunitiesDetails.opportunitiesDetails))
                setOpenCreateOrderModalState(true);
            }
        });
        /*Get Opportunity Details*/
        const [opportunityDetails] = useLazyQuery(GET_OPPORTUNITY_BY_ID, {
            fetchPolicy: 'no-cache',
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: response => {
                if (response && response.getOpportunityById && response.getOpportunityById.opportunitiesDetails) {
                    const {
                        opportunity_number,
                        orderid,
                        is_finance_approved,
                        status
                    } = JSON.parse(response.getOpportunityById.opportunitiesDetails);
                    let orderStatus = '';
                    if (opportunity_number !== '' && !is_finance_approved) {
                        orderStatus = 'Opportunity Id Received';
                    }
                    if (status === 'Feasibility - Feasible') {
                        orderStatus = 'Opportunity Feasible';
                    }
                    if (is_finance_approved) {
                        orderStatus = 'Finance Approved';
                    }
                    if (orderid !== '') {
                        orderStatus = 'Order Id Received';
                    }
                    if (data
                        && data.getSubscriptionById
                        && data.getSubscriptionById.sites
                        && data.getSubscriptionById.sites.length
                        && data.getSubscriptionById.sites[0].orderTrack
                        && data.getSubscriptionById.sites[0].orderTrack.length) {
                        let isStatus = data.getSubscriptionById.sites[0].orderTrack.map(item => item.status === orderStatus)
                        if (isStatus.includes(true)) return false;
                        if (!orderStatus) return false;
                        updateSiteDiaStatus({
                            variables: {
                                id: data
                                    && data.getSubscriptionById
                                    && data.getSubscriptionById.sites
                                    && data.getSubscriptionById.sites.length
                                    && data.getSubscriptionById.sites[0].id,
                                orderStatus: orderStatus
                            }
                        });
                    }
                }
            }
        });
        /*Get Nigeria Opportunity Details*/
        const [nigeriaOpportunityDetails] = useLazyQuery(GET_NIGERIA_OPPORTUNITY_BY_ID, {
            fetchPolicy: 'no-cache',
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: response => {
                if (response && response.getNigeriaOpportunityById && response.getNigeriaOpportunityById.opportunitiesDetails) {
                    const {
                        site_survey_status,
                        opportunity_file,
                        project_status,
                        circuit_id
                    } = JSON.parse(response.getNigeriaOpportunityById.opportunitiesDetails);
                    setCircuitId(circuit_id);
                    setOpportunityFiles(opportunity_file)
                    setProjectLogsLoading(false);
                    let orderStatus = '';
                    if (site_survey_status === 'Completed') {
                        orderStatus = 'Site Survey Completed';
                    }
                    if (project_status === 'Completed') {
                        orderStatus = 'Project Completed';
                    }
                    if (data
                        && data.getSubscriptionById
                        && data.getSubscriptionById.sites
                        && data.getSubscriptionById.sites.length
                        && data.getSubscriptionById.sites[0].orderTrack
                        && data.getSubscriptionById.sites[0].orderTrack.length) {
                        console.log(data.getSubscriptionById.sites[0].orderTrack, 'data.getSubscriptionById.sites[0].orderTrack')
                        let isStatus = data.getSubscriptionById.sites[0].orderTrack.map(item => item.status === orderStatus)
                        console.log(orderStatus)
                        console.log(isStatus)
                        if (isStatus.includes(true)) return false;
                        if (!orderStatus) return false;
                        updateSiteDiaNigeriaStatus({
                            variables: {
                                id: data
                                    && data.getSubscriptionById
                                    && data.getSubscriptionById.sites
                                    && data.getSubscriptionById.sites.length
                                    && data.getSubscriptionById.sites[0].id,
                                orderStatus: orderStatus
                            }
                        });
                    }
                }
            }
        });
        /*Get Nigeria Project Logs*/
        const [nigeriaProjectLogsDetails] = useLazyQuery(GET_NIGERIA_PROJECT_LOGS, {
            fetchPolicy: 'no-cache',
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: response => {
                if (response && response.getNigeriaProjectLogsOpportunitiesById && response.getNigeriaProjectLogsOpportunitiesById.projectLogs) {
                    console.log(response.getNigeriaProjectLogsOpportunitiesById.projectLogs, 'Nigeria Project Logs')
                    const {
                        project_logs,
                    } = JSON.parse(response.getNigeriaProjectLogsOpportunitiesById.projectLogs);
                    setProjectLogs(project_logs)
                    setProjectManagementStatusTrackModalState(true);
                    setProjectLogsLoading(false);
                }
            }
        });
        /*Get Nigeria Project Files*/
        const [nigeriaProjectFilesDetails] = useLazyQuery(GET_NIGERIA_PROJECT_LOGS, {
            fetchPolicy: 'no-cache',
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: response => {
                if (response && response.getNigeriaProjectLogsOpportunitiesById && response.getNigeriaProjectLogsOpportunitiesById.projectLogs) {
                    console.log(response.getNigeriaProjectLogsOpportunitiesById.projectLogs, 'Nigeria Project Logs')
                    const {
                        project_files,
                    } = JSON.parse(response.getNigeriaProjectLogsOpportunitiesById.projectLogs);
                    setProjectFiles(project_files)
                    setProjectFileModalState(true);
                    setProjectLogsLoading(false);
                }
            }
        });
        useEffect(() => {
            const timer = setTimeout(() => {
                setApiTimer(apiTimer + 1);
                if (!loading
                    && !error
                    && data
                    && data.getSubscriptionById
                    && data.getSubscriptionById.opportunityId) {
                    if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIA) {
                        opportunityDetails({
                            variables: {
                                opportunityId: data
                                    && data.getSubscriptionById
                                    && data.getSubscriptionById.opportunityId
                            }
                        })
                    }
                    if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                        nigeriaOpportunityDetails({
                            variables: {
                                opportunityId: data
                                    && data.getSubscriptionById
                                    && data.getSubscriptionById.opportunityId
                            }
                        })
                    }
                }
            }, 30000);
            return () => {
                clearTimeout(timer);
            };
        }, [apiTimer, data && data.getSubscriptionById && data.getSubscriptionById.opportunityId]);
        const {
            loading: ticketDetailsLoading,
            data: ticketDetails,
            error: ticketDetailsError
        } = useQuery(GET_SERVICE_TICKETS_BY_SUBSCRIPTION_ID, {
            variables: {
                id
            }
        });
        const [updateSiteStatus] = useMutation(UPDATE_SITE, {
            onCompleted: data => {
                window.location.reload();
            },
            onError: e => {
                console.log(e);
            }
        });
        const [updateSiteDiaStatus] = useMutation(UPDATE_DIA_SITE, {
            onCompleted: data => {
                window.location.reload();
            },
            onError: e => {
                console.log(e);
            }
        });
        const [updateSiteDiaNigeriaStatus] = useMutation(UPDATE_DIAN_SITE, {
            onCompleted: data => {
                window.location.reload();
            },
            onError: e => {
                console.log(e);
            }
        });
        const activeSites =
            !loading &&
            !error &&
            data.getSubscriptionById.sites &&
            data.getSubscriptionById.sites.length &&
            data.getSubscriptionById.sites.filter(
                item => item.status === SUBSCRIPTION_STATUS.ACTIVE
            );
        const pendingSites =
            !loading &&
            !error &&
            data.getSubscriptionById.sites &&
            data.getSubscriptionById.sites.length &&
            data.getSubscriptionById.sites.filter(
                item => item.status === SUBSCRIPTION_STATUS.PENDING
            );
        const handleReloadListingData = (type) => {
            if (type === 'projectFile') {
                if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                    nigeriaProjectFilesDetails({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId
                        }
                    })
                }
            }
            if (type === 'opportunityFile') {
                if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                    nigeriaOpportunityDetails({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId
                        }
                    })
                }
            }
        }
        const handleStatusMenuItemClick = (status, id) => {
            // let checkSite = pendingSites && pendingSites.filter((item) => {
            //   if(item.id === id) return item;
            // });
            // if(checkSite && checkSite.length){
            //   const addedStatus = checkSite[0].orderTrack && checkSite[0].orderTrack.map(item => {
            //     return item.status
            //   });
            //   if(addedStatus && addedStatus.includes(status)){
            //     Message.error(`${status} status already updated!`);
            //     return null;
            //   }
            // }
            let opportunityId = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId;
            if (status === 'Place Order') {
                opportunityOpportunityDetails({
                    variables: {
                        opportunityId: opportunityId
                    }
                })
            } else if (status === 'Mark void') {
                let region = window.prompt("Please enter region", "");
                if (!region) return false;
                if (region.length >= 200) {
                    Message.success('Region Must be 200 Character!');
                    return false;
                }
                if (region) {
                    console.log('region', region);
                    markOpportunityVoid({
                        variables: {opportunityId: opportunityId, region: region}
                    });
                }
            } else if (status === 'Decline By Finance') {
                let reason = window.prompt("Please enter reason", "");
                if (!reason) return false;
                if (reason.length >= 200) {
                    Message.success('Region Must be 200 Character!');
                    return false;
                }
                if (reason) {
                    console.log('reason', reason);
                    declineByFinance({
                        variables: {opportunityId: opportunityId, reason: reason}
                    });
                    return false
                }
            } else if (status === 'Activate opportunity') {
                if (window.confirm("Are you sure want to activate opportunity?")) {
                    activateOrder({variables: {opportunityId: opportunityId}})
                }
                return false;
            } else if (status === 'Connectivity Info Received') {
                setOpenConnectivityInfoModalState(true);
                return false
            } else if (status === 'Add Documents') {
                if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                    nigeriaOpportunityDetails({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId
                        }
                    })
                    setOpenAddDocumentModalState(true);
                }
                return false
            } else if (status === 'Add Site Survey Form') {
                setSiteSurveyFormModalState(true);
                return false
            } else if (status === 'Project Initiated') {
                setProjectInitiateFormModalState(true);
                return false
            } else if (status === 'Project Management') {
                setProjectManagementModalState(true);
                return false
            } else if (status === 'Project Management Status Track') {
                if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                    nigeriaProjectLogsDetails({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId
                        }
                    })
                }
                return false
            } else if (status === 'Project File') {
                if (data.getSubscriptionById.service.name === SERVICE_TYPES.DIAN) {
                    nigeriaProjectFilesDetails({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId
                        }
                    })
                }
                return false
            }
            switch (data
            && data.getSubscriptionById
            && data.getSubscriptionById.service
            && data.getSubscriptionById.service.name) {
                case SERVICE_TYPES.DIA:
                    updateSiteDiaStatus({
                        variables: {
                            id,
                            orderStatus: status
                        }
                    });
                    break;
                case SERVICE_TYPES.DIAN:
                    updateSiteDiaNigeriaStatus({
                        variables: {
                            opportunityId: opportunityId,
                            id,
                            orderStatus: status
                        }
                    });
                    break;
                default:
                    updateSiteStatus({
                        variables: {
                            id,
                            orderStatus: status
                        }
                    });
            }
        };
        /**
         * Create Order Submit
         */
        const handleCreateOrder = (formData) => {
        }
        /**
         * Create Connectivity Info Form Submit
         */
        const handleSaveConnectivityInfo = (formData) => {
            formData.opportunityId = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            SaveConnectivityInfo({variables: formData})
        }
        /**
         * Create Site Survey Form Submit
         */
        const handleSaveSiteSurvey = (formData) => {
            setSiteSurveyLoadModalState({btnLoading: true, clearData: false});
            formData.opportunity = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            SaveSiteSurveyForm({variables: {formData: JSON.stringify(formData)}})
        }
        /**
         * Create Project Initiate Form Submit
         */
        const handleSaveProjectInitiate = (formData) => {
            setProjectInitiateLoadModalState({btnLoading: true, clearData: false});
            formData.opportunity = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            SaveProjectInitiateForm({variables: {formData: JSON.stringify(formData)}})
        }
        /**
         * Create Store Form Submit
         */
        const handleSaveStoreForm = (formData) => {
            setStoreFromLoadModalState({btnLoading: true, clearData: false});
            formData.opportunity = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            SaveStoreForm({variables: {formData: JSON.stringify(formData)}})
        }
        /**
         * Add Document For Opportunity Nigeria
         */
        const handleSaveAddDocument = (formData) => {
            setAddDocumentLoadModalState({btnLoading: true, clearData: false});
            formData.opportunityId = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            if (formData.fileId) {
                UpdateOpportunityFileDian({variables: formData})
            } else {
                delete formData.fileId
                AddOpportunityDocument({variables: formData})
            }
        }
        /**
         * Project Management For Opportunity Nigeria
         */
        const handleSaveProjectManagement = (formData) => {
            setAddDocumentLoadModalState({btnLoading: true, clearData: false});
            formData.opportunityId = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            UpdateProjectStatusDian({variables: formData})
        }
        /**
         * Add Project File For Opportunity Nigeria
         */
        const handleSaveProjectFile = (formData) => {
            setAddDocumentLoadModalState({btnLoading: true, clearData: false});
            formData.opportunityId = data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId
            if (formData.fileId) {
                UpdateProjectFileDian({variables: formData})
            } else {
                delete formData.fileId
                SaveProjectFileDian({variables: formData})
            }
        }
        const [SaveConnectivityInfo] = useMutation(SAVE_CONNECTIVITY_INFO, {
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                setOpenConnectivityInfoModalState(false);
                Message.success('Connectivity Information saved successfully');
                /* if (data.markOpportunityVoid.status !== 'false') {
                     Message.success('Connectivity Information saved successfully');
                 } else {
                     Message.sudccess('Something went wrong');
                 }*/
            }
        });
        const [SaveSiteSurveyForm] = useMutation(SAVE_SITE_SURVEY, {
            onError: error => {
                setSiteSurveyLoadModalState({btnLoading: false, clearData: false});
                console.log('Error', error);
            },
            onCompleted: data => {
                setSiteSurveyFormModalState(false);
                setSiteSurveyLoadModalState({btnLoading: false, clearData: true});
                Message.success('Site Survey saved successfully');
            }
        });
        const [SaveProjectInitiateForm] = useMutation(SAVE_PROJECT_INITIATE, {
            onError: error => {
                setProjectInitiateLoadModalState({btnLoading: false, clearData: false});
                console.log('Error', error);
            },
            onCompleted: ProjectInitiateResponse => {
                if (ProjectInitiateResponse.status) {
                    updateSiteDiaNigeriaStatus({
                        variables: {
                            opportunityId: data
                                && data.getSubscriptionById
                                && data.getSubscriptionById.opportunityId,
                            id,
                            orderStatus: 'Project Initiated'
                        }
                    });
                    setProjectInitiateFormModalState(false);
                    setProjectInitiateLoadModalState({btnLoading: false, clearData: true});
                    Message.success('Project Initiate saved successfully');
                }
            }
        });
        const [SaveStoreForm] = useMutation(SAVE_STORE_FORM, {
            onError: error => {
                setStoreFromLoadModalState({btnLoading: false, clearData: false});
                console.log('Error', error);
            },
            onCompleted: StoreFormResponse => {
                if (StoreFormResponse.saveStoreForm.status) {
                    setStoreFormModalState(false);
                    setStoreFromLoadModalState({btnLoading: false, clearData: true});
                    Message.success('Store Form saved successfully');
                }
            }
        });
        const [AddOpportunityDocument] = useMutation(ADD_OPPORTUNITY_DOCUMENT, {
            onError: error => {
                console.log('Error', error);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
            },
            onCompleted: data => {
                setOpenAddDocumentModalState(false);
                setAddDocumentLoadModalState({btnLoading: false, clearData: true});
                Message.success('Document saved successfully');
            }
        });
        const [UpdateOpportunityFileDian] = useMutation(UPDATE_OPPORTUNITY_DOCUMENT, {
            onError: error => {
                console.log('Error', error);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
            },
            onCompleted: data => {
                setOpenAddDocumentModalState(false);
                setAddDocumentLoadModalState({btnLoading: false, clearData: true});
                Message.success('Document updated successfully');
            }
        });
        const [SaveProjectFileDian] = useMutation(SAVE_PROJECT_FILE_DIAN, {
            onError: error => {
                console.log('Error', error);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
            },
            onCompleted: data => {
                setProjectFileModalState(false);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
                Message.success('Project FIle saved successfully');
                handleReloadListingData('projectFile')
            }
        });
        const [UpdateProjectFileDian] = useMutation(UPDATE_PROJECT_FILE_DIAN, {
            onError: error => {
                console.log('Error', error);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
            },
            onCompleted: data => {
                setProjectFileModalState(false);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
                Message.success('Project FIle updated successfully');
                handleReloadListingData('projectFile')
            }
        });
        const [UpdateProjectStatusDian] = useMutation(UPDATE_PROJECT_STATUS_DIAN, {
            onError: error => {
                console.log('Error', error);
                setAddDocumentLoadModalState({btnLoading: false, clearData: false});
            },
            onCompleted: data => {
                setProjectFileModalState(false);
                setAddDocumentLoadModalState({btnLoading: false, clearData: true});
                Message.success('Project status updated successfully');
            }
        });
        const [markOpportunityVoid] = useMutation(MARK_OPPORTUNITY_VOID, {
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                if (data.markOpportunityVoid.status !== 'false') {
                    Message.success('Mark Opportunity Void successfully');
                } else {
                    Message.success('Something went wrong');
                }
            }
        });
        const [markOpportunityFinanceApproved] = useMutation(MARK_OPPORTUNITY_FINANCE_APPROVED, {
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                if (data.markOpportunityFinanceApproved.status !== 'false') {
                    Message.success('Mark Opportunity Finance Approved successfully');
                } else {
                    Message.success('Something went wrong');
                }
            }
        });
        const [declineByFinance] = useMutation(DECLINE_BY_FINANCE, {
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                if (data.declineByFinance.status !== 'false') {
                    Message.success('Finance Declined successfully');
                } else {
                    Message.success('Something went wrong');
                }
            }
        });
        const [activateOrder] = useMutation(OPPORTUNITY_ACTIVATE, {
            onError: error => {
                console.log('Error', error);
            },
            onCompleted: data => {
                if (data.markOpportunityFinanceApproved.status !== 'false') {
                    Message.success('Order activated successfully');
                } else {
                    Message.success('Something went wrong');
                }
            }
        });
        let serviceTypeName;
        if (!loading && !error) {
            serviceTypeName = data.getSubscriptionById.service.serviceType
                ? data.getSubscriptionById.service.serviceType.name
                : '';
        }
        return (
            <Layout breadcrumbList={breadcrumbList}>
                {!loading && !error ? (
                    <>
                        <Header
                            title={data.getSubscriptionById && data.getSubscriptionById.service ? (data.getSubscriptionById.name) ? data.getSubscriptionById.service.name + " - " + data.getSubscriptionById.name : data.getSubscriptionById.service.name : "-"}
                            subText={!ticketDetailsError && !ticketDetailsLoading &&
                            ticketDetails.serviceticketsBySubscription.length
                                ? `${t('id.label').toUpperCase()} ${
                                    ticketDetails.serviceticketsBySubscription[0].ticketId
                                } ${
                                    ticketDetails.serviceticketsBySubscription &&
                                    ticketDetails.serviceticketsBySubscription.length
                                        ? `• ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
                                        ticketDetails.serviceticketsBySubscription[0]
                                            .createdAt
                                        )}`
                                        : ''
                                }`
                                : ''
                            }
                            primaryButtonText={t('raiseRequest.label')}
                            storeForm={t('storeForm.label')}
                            serviceType={data.getSubscriptionById && data.getSubscriptionById.service && data.getSubscriptionById.service.name}
                            handleStoreFormButtonClick={() => setStoreFormModalState(true)}
                            handlePrimaryButtonClick={() =>
                                history.push(ServiceTicketPaths.NewServiceTicket)
                            }
                            // secondaryButtonText={t('addASite.label')}
                            divider={true}
                            moreText={
                                data.getSubscriptionById.sites &&
                                data.getSubscriptionById.sites.length &&
                                `${data.getSubscriptionById.sites[0].name} ${t(
                                    'and.label'
                                )} ${data.getSubscriptionById.sites.length - 1} ${t(
                                    'otherSites.label'
                                )}`
                            }
                        />
                        {pendingSites && pendingSites.length ? (
                            <SitePanel
                                handleMenuItemClick={handleStatusMenuItemClick}
                                serviceType={data.getSubscriptionById.service.name}
                                data={pendingSites}
                                circuitId={circuitId}
                                type={SUBSCRIPTION_STATUS.PENDING}
                                t={t}
                                parent={parent}
                            />
                        ) : null}
                        {activeSites && activeSites.length ? (
                            <SitePanel
                                data={activeSites}
                                circuitId={circuitId}
                                serviceType={data.getSubscriptionById.service.name}
                                type={SUBSCRIPTION_STATUS.ACTIVE}
                                t={t}
                                handleMenuItemClick={() => null}
                                parent={parent}
                            />
                        ) : null}
                    </>
                ) : (
                    <></>
                )}
                {loading && !error &&
                <LinearProgress color="primary" style={{top: '98px', left: '0px', position: 'absolute', width: '100%'}}/>
                }
                <React.Fragment>
                    <CreateOrderModal
                        t={t}
                        open={openCreateOrderModalState}
                        handleCreateOrder={handleCreateOrder}
                        opportunityDetail={opportunityDetail}
                        handleClose={() => {
                            setOrderLoadModalState({btnLoading: false, clearData: false});
                            setOpenCreateOrderModalState(false);
                        }}
                        modalState={orderLoadModalState}
                    />
                    {serviceTypeName && (serviceTypeName === SERVICE_TYPES.DIAN || serviceTypeName === SERVICE_TYPES.DIA) &&
                        <React.Fragment>
                            <CreateConnectivityInfoModal
                                t={t}
                                open={openConnectivityInfoModalState}
                                handleSaveConnectivityInfo={handleSaveConnectivityInfo}
                                handleClose={() => {
                                    setOrderLoadModalState({btnLoading: false, clearData: false});
                                    setOpenConnectivityInfoModalState(false);
                                }}
                                modalState={orderLoadModalState}
                            />
                            <AddOpportunityDocumentsModal
                                t={t}
                                open={openAddDocumentModalState}
                                handleSaveAddDocument={handleSaveAddDocument}
                                list={opportunityFiles}
                                reloadProjectFile={handleReloadListingData}
                                loading={loadingProjectLogs}
                                handleClose={() => {
                                    setAddDocumentLoadModalState({btnLoading: false, clearData: false});
                                    setOpenAddDocumentModalState(false);
                                }}
                                modalState={addDocumentLoadModalState}
                            />
                            <ProjectManagementDIANModal
                                t={t}
                                open={openProjectManagementModalState}
                                handleSaveProjectManagement={handleSaveProjectManagement}
                                handleClose={() => {
                                    setAddDocumentLoadModalState({btnLoading: false, clearData: false});
                                    setProjectManagementModalState(false);
                                }}
                                modalState={addDocumentLoadModalState}
                            />
                            <ProjectFileDIANModal
                                t={t}
                                open={openProjectFileModalState}
                                handleSaveProjectFile={handleSaveProjectFile}
                                list={projectFiles}
                                reloadProjectFile={handleReloadListingData}
                                loading={loadingProjectLogs}
                                handleClose={() => {
                                    setAddDocumentLoadModalState({btnLoading: false, clearData: false});
                                    setProjectFileModalState(false);
                                }}
                                modalState={addDocumentLoadModalState}
                            />
                            <CreateSiteSurveyModal
                                t={t}
                                open={openSiteSurveyFormModalState}
                                handleSaveSiteSurvey={handleSaveSiteSurvey}
                                handleClose={() => {
                                    setSiteSurveyLoadModalState({btnLoading: false, clearData: false});
                                    setSiteSurveyFormModalState(false);
                                }}
                                modalState={siteSurveyLoadModalState}
                            />
                            <CreateProjectInitiateModal
                                t={t}
                                open={openProjectInitiateFormModalState}
                                handleSaveProjectInitiate={handleSaveProjectInitiate}
                                headingName={'Project Initiate'}
                                subheading={true}
                                data={data}
                                handleClose={() => {
                                    setProjectInitiateLoadModalState({btnLoading: false, clearData: false});
                                    setProjectInitiateFormModalState(false);
                                }}
                                modalState={projectInitiateLoadModalState}
                            />
                            <CreateStoreFormModal
                                t={t}
                                open={openStoreFormModalState}
                                partsLoading={partsLoading}
                                partsError={partsError}
                                partsData={partsData}
                                handleSaveStoreForm={handleSaveStoreForm}
                                data={data}
                                handleClose={() => {
                                    setStoreFromLoadModalState({btnLoading: false, clearData: false});
                                    setStoreFormModalState(false);
                                }}
                                modalState={storeFormLoadModalState}
                            />
                            <ProjectManagementTrackStatusDianModal
                                t={t}
                                open={openProjectManagementStatusTrackModalState}
                                list={projectLogs}
                                loading={loadingProjectLogs}
                                handleClose={() => {
                                    setProjectManagementStatusTrackModalState(false);
                                }}
                            />
                        </React.Fragment>
                    }
                </React.Fragment>
            </Layout>
        );
    }
;
export default withTranslation()(PendingSubscriptionDetails);
