import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Message from '../../../../components/Message';
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';
import { marginGenerator } from '../../../../theme/utils';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { UPDATE_MAIL_TICKET } from '../../mutations';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    iconEdit: {
      cursor: 'pointer',
      fontSize: 'large',
      marginLeft: '10px'
    }
  })
);

export default function TicketChangeCreateDate({ id, reloadTickets, createdDate, ticketData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [createDate, setCreateDate] = React.useState(new Date(createdDate));

  const [updateMailServiceTicket] = useMutation(UPDATE_MAIL_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket Created date updated successfully.');
      reloadTickets();
      setOpen(false);
      setCreateDate(new Date());
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <EditTwoToneIcon
        color="primary"
        onClick={handleOpen}
        className={classes.iconEdit}
      />

      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={'sm'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Change Created Date
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                <div className="datePicker">
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    style={{
                      ...marginGenerator('mb-8'),
                      ...marginGenerator('mt-40')
                    }}>
                    Select Created Date
                  </Typography>
                  <DatePicker
                    timeIntervals={30}
                    selected={createDate}
                    startDate={createDate}
                    onChange={date => setCreateDate(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant={'contained'}
              disabled={false}
              color={'primary'}
              onClick={() => {
                return updateMailServiceTicket({
                  variables: {
                    serviceTicketId: id,
                    createdAt: createDate,
                    title: ticketData.title,
                    mailThreadId: ticketData.ticketId
                  }
                });
              }}>
              Update Create Date
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
