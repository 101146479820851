import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_DEVICE_LIST_BY_TYPE, GET_DEVICE_LIST_BY_TYPE_NIGERIA} from "../../../queries";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            type="submit"
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    }
}));
function CreateUpdateRouter({
                                t,
                                open,
                                handleCreateUpdateRouter,
                                handleClose,
                                connectivityInfoData,
                                modalState
                            }) {
    const classes = useStyles();
    const [deviceList, setDeviceList] = useState([]);
    React.useEffect(() => {
        if (modalState.clearData && open) {
            handleClose();
        }
    }, [modalState]);
    React.useEffect(() => {
        getDeviceList({
            variables: {
                type: 'router'
            }
        })
    }, []);
    const [getDeviceList] = useLazyQuery(GET_DEVICE_LIST_BY_TYPE_NIGERIA, {
        fetchPolicy: 'no-cache',
        onCompleted: response => {
            setDeviceList(response.getDeviceListByTypeNigeria);
        }
    });
    return (
        <Formik
            initialValues={{
                opportunity: "",
                router: "",
                bandwidth: "",
                interface: "",
                ip_address: "",
                gateway: "",
                description: ""
            }}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                console.log('values', JSON.stringify(values))
                handleCreateUpdateRouter(values)
                //  resetForm()
                //alert(JSON.stringify(values));
            }}
            validationSchema={Yup.object().shape({
                router: Yup.string()
                    .required('Please Select Router'),
                bandwidth: Yup.string()
                    .required('Please enter bandwidth'),
                interface: Yup.string()
                    .required('Please enter interface'),
                ip_address: Yup.string()
                    .required('Please enter vlan number'),
                gateway: Yup.string()
                    .required('Please enter gateway'),
                description: Yup.string()
                    .required('Please Enter Description'),
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                } = props;
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid style={{...paddingGenerator('pb-30')}}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-10')
                                }}>
                                <Grid container spacing={3} style={{
                                    ...marginGenerator('mt-8')
                                }}>
                                    <Grid item xs={6} style={marginGenerator('mt-16')}>
                                        <OutlinedSelect
                                            val={values.router}
                                            label={`${t('router.label')} *`}
                                            name={'router'}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            selectStyle={{...paddingGenerator('p-8')}}
                                            styleOverrides={{width: '100%', marginRight: 0}}
                                            options={deviceList && deviceList.length && deviceList.map(item => {
                                                return {id: item.device_name, name: item.device_name};
                                            })}
                                        />
                                        {(errors.router && touched.router) &&
                                        <FormHelperText
                                            style={{marginTop: '20px'}}>{errors.router}</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.bandwidth}
                                            name={'bandwidth'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.bandwidth && touched.bandwidth) && errors.bandwidth}
                                            label={`Bandwidth*`}
                                            placeholder={`Enter Bandwidth*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.interface}
                                            name={'interface'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.interface && touched.interface) && errors.interface}
                                            label={`interface *`}
                                            placeholder={`Enter interface*`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.ip_address}
                                            name={'ip_address'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.ip_address && touched.ip_address) && errors.ip_address}
                                            label={`Ip Address *`}
                                            placeholder={`Enter Ip Address *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.gateway}
                                            name={'gateway'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.gateway && touched.gateway) && errors.gateway}
                                            label={`Gateway *`}
                                            placeholder={`Enter gateway *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={values.description}
                                            name={'description'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.description && touched.description) && errors.description}
                                            label={`Description *`}
                                            placeholder={`Enter Description... *`}
                                            style={{width: '100%'}}
                                            color={'secondary'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Grid container justify={'center'}>
                                <Button
                                    style={{marginRight: '5px'}}
                                    variant={'outlined'}
                                    onClick={() => {
                                        handleClose();
                                    }}>
                                    {t('cancel.label')}
                                </Button>
                                <ButtonComponent t={t} loading={modalState.btnLoading}/>
                            </Grid>
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
}
CreateUpdateRouter.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateUpdateRouter);
