import * as React from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { GET_ALL_KNOWLEDGEBASE_TICKETS } from '../queries';
import Layout from '../../../components/Layout';
import Typography from '@material-ui/core/Typography';
import { Paths, TicketingSystem } from '../../../routes/routePaths';
import SearchBox from '../../../components/SearchBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import TicketPanel from './components/TicketPanel';
import { getUrl } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { STATUS_TYPES, IS_PARENT } from '../../../utils/constants';
const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'knowladge.label', link: TicketingSystem.KnowladgeBase }
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const getSubscriptionOptions = list => {
  return list.map((item, index) => {
    return { id: item.id, name: item.service.name };
  });
};

const KnowladgeBase = ({ t, navigation }) => {
  const classes = useStyles();
  const [updatedClosedTickets, setUpdatedClosedTickets] = useState([]);
  const [closedTicketsSearched, setClosedTicketsSearched] = useState(false);

  const history = useHistory();

  useEffect(() => {
    reloadCloseTicket();
  }, []);

  const {
    loading: closedLoading,
    error: closedError,
    data: knowledgeBaseTickets,
    refetch: reloadCloseTicket
  } = useQuery(GET_ALL_KNOWLEDGEBASE_TICKETS, {
    variables: { status: STATUS_TYPES.CLOSED, isParent: IS_PARENT.TRUE }
  });

  const handleTicketItemClick = id => {
    const path = getUrl(TicketingSystem.KnowladgeBaseDetail, id);
    history.push(path);
  };

  const handleSearchClosedTickets = query => {
    if (query !== '') {
      const updatedList =
        knowledgeBaseTickets.knowledgeBaseTickets &&
        knowledgeBaseTickets.knowledgeBaseTickets.filter(
          (item, index) =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase()) ||
            item.ticketId.toLowerCase().includes(query.toLowerCase()) ||
            item.id.toLowerCase().includes(query.toLowerCase())
        );
      setClosedTicketsSearched(true);
      setUpdatedClosedTickets(updatedList);
    } else {
      setClosedTicketsSearched(false);
      setUpdatedClosedTickets([]);
    }
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <div className={classes.root}>
        {closedLoading && <CircularProgress color="secondary" />}
      </div>
      <center>
        {!closedLoading && (
          <>
            <Typography variant="h1">{t('knowledgeBase.label')}</Typography>
            <SearchBox type="wide" handleSearch={handleSearchClosedTickets} />
          </>
        )}
      </center>

      {!closedLoading && !closedError && knowledgeBaseTickets && (
        <TicketPanel
          ticketList={updatedClosedTickets}
          type={'closedTickets'}
          t={t}
          handleTicketItemClick={handleTicketItemClick}
        />
      )}
    </Layout>
  );
};
export default withTranslation()(KnowladgeBase);
