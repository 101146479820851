import React, { useEffect, useState } from 'react';
import commonStyles from '../../../theme/commonStyles';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_PAGINATE_USERS } from './queries';
import { ORGANISATIONS_LIST_FOR_SELECT } from '../Organisations/queries';
import { DELETE_USERS } from './mutations';
import { UPDATE_USER } from '../../OnBoarding/mutations';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { InternalPaths } from '../../../routes/routePaths';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Message from '../../../components/Message';
import EditUser from './EditUsers';
import MenuWrapper from '../../../components/MenuWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateUploadBulkUsersModal from './components/CreateUploadBulkUsersModal/index';
import { UPLOAD_BULK_USERS, ASSIGN_ORG_TO_BULK_USERS, SEND_DIRECT_RESET_PASSWORD_LINK } from '../../commonMutations';
import { GET_ALL_ROLES } from '../../commonQueries';
import Autocomplete from '../../../components/Autocomplete';
import OutlinedSelect from '../../../components/OutlinedSelect';
import SearchBox from '../../../components/SearchBox';
import UserItem from './UserItem';
import {TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
// import IconButton from '@material-ui/core/IconButton';
// import MoreHoriz from '@material-ui/icons/MoreHoriz';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import MUIDataTable from "mui-datatables";
// import Icon from '@material-ui/core/Icon';

const breadcrumbList = [
  { label: 'home.label', link: InternalPaths.Dashboard },
  { label: 'users.label', link: InternalPaths.users }
];

const UsersList = ({ t }) => {

  const [clearModal, setClearModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [selectOrgList, setSelectOrgList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [updateUserLoading, setUpdateUserLoading] = useState({});
  const [uploadBulkUsers, setUploadBulkUsers] = useState(false);
  const [uploadBulkUsersmodalState, setUploadBulkUsersModalState] = useState({ btnLoading: false, clearData: false });
  const [userFilterState, setUserFilterState] = useState({role: "", page: 1, groupId: "", search: ""});

  const menuItems = [{ label: t('edit.label') }, { label: t('delete.label') }, { label: t('sendResetPasswordLink.label') }];

  const history = useHistory();
  const location = useLocation();
  // const commonClasses = commonStyles();

  const {
    loading: roleLoading,
    error: roleError,
    data: roleList
  } = useQuery(GET_ALL_ROLES);

  const { loading: orgLoading, data: orgList } = useQuery(
    ORGANISATIONS_LIST_FOR_SELECT,
    {
      onCompleted: data => {
        setSelectOrgList(
          data.getOrganizationsList ? data.getOrganizationsList : []
        );
      }
    }
  );
  /*
    Get Users List Query
  */
  // const [getSubAdminUsers] = useLazyQuery(GET_SUB_ADMIN_USERS, {
  //   fetchPolicy: 'no-cache',
  //   onError: error => {
  //     console.log('Error', error);
  //     setLoading(false);
  //   },
  //   onCompleted: data => {
  //     setLoading(false);
  //     if (data.getSubAdminUsers) {
  //       let usersList = [];
  //       if (data.getSubAdminUsers) {
  //         data.getSubAdminUsers.map(itm => {
  //           let tmpData = { ...itm };
  //           let orgNameArr =
  //             itm.organisations && itm.organisations.length > 0
  //               ? itm.organisations.map(org => org.name)
  //               : [];
  //           tmpData['org'] = orgNameArr.length > 0 ? orgNameArr.join(', ') : '';
  //           tmpData['name'] = itm.firstName + " " + itm.lastName;
  //           usersList.push(tmpData);
  //         });
  //       }
  //       setUpdateUserLoading({});
  //       setUsers(usersList);
  //     }
  //   }
  // });

  /*
    Get Users List Query
  */
  const [getPaginateUsers] = useLazyQuery(GET_PAGINATE_USERS, {
    fetchPolicy: "cache-and-network" ,
    onError: error => {
      console.log('Error', error);
      setLoading(false); 
    },
    onCompleted: data => {
      setLoading(false);
      const usersList = (data.getPaginateUsers && data.getPaginateUsers.users) ? data.getPaginateUsers.users.map(itm => {
        let tmpData = { ...itm };
        let orgNameArr = itm.organisations && itm.organisations.length > 0 ? itm.organisations.map(org => org.name) : [];
        tmpData['org'] = orgNameArr.length > 0 ? orgNameArr.join(', ') : '';
        tmpData['name'] = itm.firstName + " " + itm.lastName;
        return tmpData;
      }) : [];
      setUsers(usersList);
      setUsersCount(data.getPaginateUsers ? data.getPaginateUsers.count : 0);
    }
  });

  useEffect(() => {
    setLoading(true);
    return getPaginateUsers({variables: {...userFilterState}});
  }, []);

  useEffect(() => {
    if (location.search === `?action=reload`) {
      return getPaginateUsers({variables: {...userFilterState, page: 1}});
    }
  }, [location]);

  const handleAddNewSubAdminClick = searchParam => {
    history.push({
      pathname: InternalPaths.createNewUser,
      ...(searchParam === '?browse' ? { search: searchParam } : {})
    });
  };

  const [deleteUser] = useMutation(DELETE_USERS, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('User Deleted successfully.');
      setSelectedUser(null);
      return getPaginateUsers({variables: {...userFilterState}});
    }
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: error => {
      setButtonLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('User updated successfully');
      setButtonLoading(false);
      setOpenUserModal(false);
      setClearModal(true);
      setSelectedUser(null);
      return getPaginateUsers({variables: {...userFilterState, page: 1}});
    }
  });

  const handleMenuItemClick = (action, user) => {
    if(!user) return null;
    if (action.label === 'Delete') {
      if (window.confirm('Are you sure? you want to delete this user!')) {
        return deleteUser({
          variables: {
            id: user.id
          }
        });
      }
    }
    if (action.label === 'Edit') {
      // let data = users.filter(itm => itm.id === id);
      if (user) {
        setOpenUserModal(true);
        setEditModalData(user);
      }
      return null;
    }
    if(action.label === t('sendResetPasswordLink.label')){
      handleSendResetPasswordLinkClick(user.email);
      return null;
      // let data = users.filter(itm => itm.id === id);
      // }
    }
  };

  const handleSubmit = formData => {
    if (!formData.id) {
      return null;
    }
    setUpdateUserLoading({[formData.id]: true});
    setButtonLoading(true);
    return updateUser({
      variables: { ...formData }
    });
  };

  const handleUploadBulkUsersClick = () => {
    setUploadBulkUsers(true);
  };

  const [uploadBulkUsersData] = useMutation(UPLOAD_BULK_USERS, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Customer file imported successfully.');
      setUploadBulkUsersModalState({ btnLoading: false, clearData: true });
      setUploadBulkUsers(false);
      if (data && data.uploadBulkUsers) {
        if (data.uploadBulkUsers.data) {
          let finalData = JSON.stringify(data.uploadBulkUsers.data);
          return assignOrgToBulkUsers({
            variables: {
              data: finalData
            }
          });
        }
      }
    }
  });

  const [assignOrgToBulkUsers] = useMutation(ASSIGN_ORG_TO_BULK_USERS, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      return getPaginateUsers({variables: {...userFilterState, page: 1}});
    }
  });

  const [sendResetPasswordLink] = useMutation(SEND_DIRECT_RESET_PASSWORD_LINK, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data && data.sendDirectResetPasswordLink) {
        if (data.sendDirectResetPasswordLink.success && data.sendDirectResetPasswordLink.msg === 'email_verified') {
          Message.error("Reset password link sent successfully.");
        } else {
          Message.error("Error in send reset password link.");
        }
      }
    }
  });

  const handleSubmitClick = (data) => {
    setUploadBulkUsersModalState({ btnLoading: true, clearData: false });
    return uploadBulkUsersData({
      variables: {
        data: data
      }
    });
  }

  const handleSendResetPasswordLinkClick = (email) => {
    if (
      window.confirm('Are you sure you want to send reset password link?') ===
      false
    ) {
      return false;
    }
    return sendResetPasswordLink({
      variables: {
        email: email,
      }
    });
  }

  // const columns = [
  //   {
  //     name: "id",
  //     label: t('id.label'),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false
  //     }
  //   },
  //   {
  //     name: "name",
  //     label: t('name.label'),
  //     options: {
  //       filter: true,
  //       customBodyRender: (value, tableMeta) => {
  //         return (
  //           <Grid className={commonClasses.column}>
  //             <Grid>
  //               {tableMeta.rowData && updateUserLoading && updateUserLoading[tableMeta.rowData[0]] ? 
  //                 <CircularProgress style={{marginRight: 10}} size={15} />
  //               : null}
  //               <Icon
  //                 component={() => (
  //                   <PersonOutlineIcon
  //                     color={'secondary'}
  //                     style={{ marginTop: '2px', marginRight: '6px' }}
  //                   />
  //                 )}
  //                 alt={'icon'}
  //               />
  //             </Grid>
  //             <Grid>
  //               <Typography variant={'subtitle1'}>
  //                 {value || '-'}
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         )
  //       }
  //     }
  //   },
  //   {
  //     name: "email",
  //     label: t('email.label'),
  //     options: {
  //       filter: true
  //     }
  //   },
  //   {
  //     name: "phone",
  //     label: t('phone.label'),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         return value ? (
  //           <Typography
  //             variant={'subtitle1'}
  //           >
  //             {value}
  //           </Typography>
  //         ) : "-";
  //       }
  //     }
  //   },
  //   {
  //     name: "org",
  //     label: t('organization.label'),
  //     options: {
  //       filter: true,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         return value ? (
  //           <Typography
  //             variant={'subtitle1'}
  //           >
  //             {value}
  //           </Typography>
  //         ) : "-";
  //       }
  //     }
  //   },
  //   {
  //     name: "role",
  //     label: t('role.label'),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value, tableMeta) => {
  //         return value ? (
  //           <Typography
  //             variant={'subtitle1'}
  //           >
  //             {value}
  //           </Typography>
  //         ) : "-";
  //       }
  //     }
  //   },
  //   {
  //     name: "createdDate",
  //     label: t('createdDate.label'),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         return value ? (
  //           <Typography
  //             variant={'subtitle1'}
  //           >
  //             {value}
  //           </Typography>
  //         ) : null;
  //       }
  //     }
  //   },
  //   // {
  //   //   name: "email",
  //   //   label: t('resetPassword.label'),
  //   //   options: {
  //   //     filter: false,
  //   //     sort: false,
  //   //     customBodyRender: (value) => {
  //   //       return (
  //   //         <Grid>
  //   //           <Button variant={'outlined'}
  //   //             color={'primary'}
  //   //             style={{ float: 'right' }}
  //   //             onClick={evt => {
  //   //               handleSendResetPasswordLinkClick(value);
  //   //             }}>{t('sendResetPasswordLink.label')}</Button>
  //   //         </Grid>
  //   //       );
  //   //     }
  //   //   }
  //   // },
  //   {
  //     name: "id",
  //     label: t('action.label'),
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         return (
  //           <>
  //             <Grid>
  //               <IconButton
  //                 size={'small'}
  //                 disableRipple={true}
  //                 disableFocusRipple={true}
  //                 color={'secondary'}
  //                 onClick={evt => {
  //                   setAnchorEl(evt.currentTarget);
  //                   setSelectedUser(value);
  //                   evt.stopPropagation();
  //                 }}>
  //                 <MoreHoriz className={commonClasses.iconRegular} />
  //               </IconButton>
  //             </Grid>
  //           </>
  //         )
  //       }
  //     }
  //   }
  // ];

  // const options = {
  //   filter: true,
  //   selectableRows: false,
  //   filterType: "dropdown",
  //   responsive: 'standard',
  //   rowsPerPage: 10,
  //   download: false,
  //   print: true,
  //   viewColumns: false,
  //   selectableRowsHeader: false
  // };

  const columns = [
    { label: t("name.label") },
    { label: t("email.label") },
    { label: t("phone.label") },
    { label: t("organization.label") },
    { label: t("role.label") },
    { label: t("createdAt.label") },
    { label: t("action.label") }
  ];
  

  const handleSearchUsers = (value) => {
    setUserFilterState({...userFilterState, search: value, page: 1});
  }

  const handleSetAnchorEl = (el, user) => {
    setSelectedUser(user);
    setAnchorEl(el);
  }

  React.useEffect(() => {
    setLoading(true);
    return getPaginateUsers({variables: {...userFilterState}});
  }, [userFilterState]);

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('users.label')}
        subText={`${t('usersSubTxt.label')}`}
        primaryButtonText={t('addAdmins.label')}
        handlePrimaryButtonClick={handleAddNewSubAdminClick}
        secondaryButtonText={t('uploadBulkUsers.label')}
        handleSecondaryButtonClick={handleUploadBulkUsersClick}
      />
      <Grid container style={{marginTop: 20, marginBottom: 32}}>
        <Grid item xs={3}>
          <SearchBox handleSearch={handleSearchUsers} />
        </Grid>
        <Grid item xs={3}>
          <OutlinedSelect 
            styleOverrides={{width: 250}} 
            label={"Select Role"} 
            options={!roleLoading && !roleError && roleList.getAllRoles ? JSON.parse(roleList.getAllRoles.RoleData) : []} 
            val={userFilterState.role}
            handleChange={evt => {
              setUserFilterState({...userFilterState, role: evt.target.value, page: 1});
            }} 
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            styleOverrides={{width: "100%"}}
            handleChange={(evt, newVal) => {
              setUserFilterState({...userFilterState, groupId: newVal ? newVal.id : "", page: 1});
            }}
            options={selectOrgList}
            label={"Orgnazation"}
            size="small"
            onInputChange={evt => {}}
          />
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => {
                            return ( 
                              <TableCell align={"left"} width="15%" key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                              </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                  { !loading ? 
                    users.length > 0 ? users.map((rowitem, rowIndex) => (
                        <UserItem
                          key={rowitem.id}
                          t={t}
                          data={rowitem}
                          menuItems={menuItems}
                          handleSetAnchorEl={handleSetAnchorEl}
                        />
                    )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                              <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow>
                    : <TableRow>
                        <TableCell colSpan={columns.length}>
                          {loading && <Typography variant={'subtitle2'} style={{textAlign: "center"}}><CircularProgress size={25}/></Typography> }
                        </TableCell>
                    </TableRow>
                  }
                </TableBody>
            </Table>
            {/* {(!loading || users.length) ? */}
            <>
              <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                <Grid item xs={2}>
                  <Typography variant={'subtitle2'} color="primary">Total Records: {usersCount}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Pagination 
                    color="primary"
                    count={Math.ceil(usersCount/20)}
                    page={userFilterState.page} 
                    onChange={(evt, value) => {
                      setUserFilterState({...userFilterState, page: value});
                    }}
                  />
                </Grid>
              </Grid>
            </> 
            {/* : null} */}
        </TableContainer>
        {anchorEl && (
          <MenuWrapper
            id={'ticket-menu'}
            anchorEl={anchorEl}
            handleClose={evt => {
              setAnchorEl(null);
              setSelectedUser(null);
              evt.stopPropagation();
            }}
            menuItems={menuItems}
            handleMenuItemClick={elem => {
              if (selectedUser) {
                handleMenuItemClick(elem, selectedUser);
                setAnchorEl(null);
              }
            }}
          />
        )}
        {/* {!loading ?
          <MUIDataTable
            title={null}
            data={users}
            t
            columns={columns}
            options={options}
          />
        : 
        <div style={{ marginTop: 10, textAlign: "center" }}><CircularProgress size={30} /></div>} */}
      </Grid>
      <EditUser
        open={openUserModal}
        handleClose={evt => setOpenUserModal(false)}
        handleSubmit={handleSubmit}
        organisations={selectOrgList}
        orgLoading={orgLoading}
        buttonLoading={buttonLoading}
        clearModal={clearModal}
        data={editModalData}
      />
      <CreateUploadBulkUsersModal
        t={t}
        open={uploadBulkUsers}
        handleClose={() => {
          setUploadBulkUsersModalState({ btnLoading: false, clearData: true });
          setUploadBulkUsers(false);
        }}
        handleSubmitClick={handleSubmitClick}
        modalState={uploadBulkUsersmodalState} />
    </Layout>
  );
};
export default withTranslation()(UsersList);
