import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GenericTable from '../../../../../components/GenericTable';

const useStyles = makeStyles(theme => ({
  paper: { maxWidth: "800px" },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    height: 48,
    width: 48,
    borderRadius: '50%'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  },
  aiParamList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const DialogTitle = ((props) => {
    const { children, handleClose, classes, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {handleClose ? (
          <IconButton className={classes.closeButton} variant="outlined" onClick={handleClose}>
              <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
const columns = [
    {
        key: "name",
        label: "Name"
    },
    {
        key: "code",
        label: "Code"
    }
];
function CreateCountryModal({
    t,
    open,
    data,
    handleClose,
    regions,
    loading
}) {
    const classes = useStyles();
    
    return (
        <Dialog
            classes={{ paper: classes.paper}}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle 
            id="country-title" 
            classes={classes} 
            handleClose={handleClose}>
                {data.name + " " + t("regions.label")  + " list"}
            </DialogTitle>
            <DialogContent dividers>
                <GenericTable columns={columns} list={regions} loading={loading} />
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('close.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateCountryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(CreateCountryModal);