import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function ProjectAttachmentModal({
    t,
    open,
    handleClose,
    attachmentList,
    // modalState,
}) {
    const classes = useStyles();
    React.useEffect(() => {
        // if (modalState.clearData && open) {
        //     handleClose();
        // }
    });

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('attachments.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <List className={classes.notiListRoot}>
                                {(attachmentList && attachmentList != null && attachmentList.length > 0) ? attachmentList.map((item, index) => (
                                    <>
                                        {/* <Button variant="text" width={'100%'}>File + {index + 1}</Button> */}
                                        <MenuItem alignItems="flex-start" key={index} onClick={() => {
                                            if (item != undefined && ((item.attachment_link || item.file || item.url) != '') && item != null) {
                                                window.open((item.attachment_link || item.file || item.url));
                                            }
                                        }}>
                                            <ListItemAvatar>
                                                <InsertDriveFileIcon></InsertDriveFileIcon>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Typography variant="subtitle2" style={{ marginTop: '8px' }}>File {index + 1}</Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </MenuItem>
                                        <Divider variant="inset" component="li" />
                                    </>
                                )) : null}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

ProjectAttachmentModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ProjectAttachmentModal);
