export const CAMERA_STATUS = {
    PROV: 'provisioning',
    ACTIVE: 'active',
    PANDING: 'pending',
    FAILED: 'failed'
};
export const IOT_DEVICE_STATUS = {
    PROV: 'provisioning',
    ACTIVE: 'active',
    PANDING: 'pending',
    FAILED: 'failed'
};
export const VIDEO_STATUS = {
    QUE: 'queued',
    PROCESSED: 'processed',
    PROCESSING: 'processing'
};
export const TICKET_STATUS = {
    INPROGRESS: "In Progress",
    HOLD: "Hold"
}
export const IS_PARENT = {
    TRUE: true,
    FALSE: false
};
export const COUNTRY_LIST = {
    IVORY_COAST: 'Ivory Coast',
    ZAMBIA: 'Zambia',
    BOTSWANA: 'Botswana',
    NIGERIA: 'Nigeria',
    MALAWI: 'Malawi',
    CAMEROON: 'Cameroon',
    EDGEDOCK: 'Edgedock'
};
export const IN_CHARGE = {
    AGENT: 'agent',
    LINEMANAGER: 'line manager',
    CAB: 'cab',
    EHOD: 'e-hod',
    MD: 'md'
}
export const STATUS_TYPES = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    NEW: 'New',
    INPROGRESS: "In Progress",
    HOLD: "Hold",
    PENDING: 'Pending',
    APPROVED: 'approved',
    ESCALATION: 'escalate'
};
export const TICKET_STATUS_EXPORT = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    ALL: 'All'
}
export const TICKET_PRIORITY = {
    CRITICAL: 'Critical',
    URGENT: 'Urgent',
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low'
};
export const TICKET_CATEGORIES = {
    NORMAL: "Normal Ticket",
    PARENT: "Parent Ticket"
}
export const TICKET_INQ_ISSUE = {
    INQPROBLEM: 'Inq Problem',
    CUSTOMERPROBLEM: 'Customer Problem'
}
export const EQUIPMENT_REPLACED = {
    YES: 'Yes',
    NO: 'No'
}
export const INCIDENT_OUTLINE = {
    UNKNOWN: 'Unknown',
    CUSTOMER: 'Customer',
    INQ_DIGITAL: 'Inq Digital'
};
export const TICKET_ASSIGNED = {
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED'
};
export const TICKET_TYPE = {
    MAILTICKET: 'Email Tickets',
    CUSTOMERTICKET: 'Customer Tickets',
    CSOCMANAGERTICKET: 'Call Ticket',
    INTERNALTICKET: 'Internal Ticket'
};
export const TICKET_DATA = {
    INTERNALTICKET: "internalTicket",
    SERVICETICKET: "serviceTicket"
}
export const SORT_BY = {
    LATESTFIRST: 'Latest First',
    OLDESTFIRST: 'Oldest First',
    LASTMODIFIED: 'Last Modified',
    FIRSTMODIFIED: 'First Modified'
};
export const SUBSCRIPTION_STATUS = {
    ACTIVE: 'Active',
    PENDING: 'Pending',
    NEW: 'New'
};
export const SERVICE_TYPES = {
    MPLS: 'MPLS',
    DIA: 'DIA Botswana Service',
    DIAN: 'Intelligent Connectivity - DIA - Nigeria',
    EDGE_AI: 'Edge AI Video Analytics',
    SDN: 'Managed NFV Solutions',
    CLOUD_SOLUTION: 'Cloud Solutions',
    DATA_BACKUP_SOLUTION: 'Data Backup Solutions',
    DIGITAL_SOLUTION: "Digital Solutions",
    DOMAIN_SERVICES: "Domain services",
    FIBER_CONNECTIVITY: "Fiber Connectivity",
    OCR: "Intelligent Documents",
    SC: "Intelligent Trips",
    RFP_MANAGEMENT_SYSTEM: "RFP Management System",
    IOT_SENSORS: "IOT Sensors",
    MORUPULE_COAL_MINE: "Intelligent Digital Forms",
    VALIO_SERVICE: "Intelligent Bid",
    LIVENESS_CHECK: "Liveness Check",
    INTELLIGENT_SALE_TRACKER: 'Intelligent Sale Tracker',
    DOC_AI_2_0: 'DocAI 2.0'
};
export const CYBER_SECURITY_TITLE = {
    TITLE: 'Cyber Security Incident Report Title'
}
export const CM_PRIORITY = [
    'Low',
    'Medium',
    'High'
]
export const ORDER_TRACK_STATUS = [
    'Order Received',
    'Order In Progress',
    'Team Assigned',
    'Provision Complete'
];
export const ORDER_DIA_TRACK_STATUS = [
    'Opportunity Sent',
    'Opportunity Id Received',
    'Opportunity Feasible',
    'Finance Approved',
    'Order Id Received',
    'Order Placed',
    'Provision Complete'
];
export const ORDER_DIAN_TRACK_STATUS = [
    'Opportunity Sent',
    'Opportunity Feasible',
    'Forwarded to Finance',
    'Site Survey Initiated',
    'Site Survey Completed',
    'Project Initiated',
    'Project Completed',
    'Provision Complete'
];
export const ORDER_DIA_ADMIN_TRACK_STATUS = [
    'Mark void',
    'Decline By Finance',
    'Activate opportunity',
    'Place Order',
    'Connectivity Info Received',
    'Provision Complete'
];
export const ORDER_DIAN_ADMIN_TRACK_STATUS = [
    'Add Documents',
    'Opportunity Feasible',
    'Forwarded to Finance',
    'Site Survey Initiated',
    'Add Site Survey Form',
    'Project Initiated',
    'Project Management',
    'Project Management Status Track',
    'Project File',
    'Provision Complete'
];
export const USER_ROLES = {
    SERVICE_MANAGER: 'servicemanager',
    EP_ADMIN: 'eadmin', // Permission : Subscribe, Configre
    EP_FINANCE: 'finance', // Permission : ReadOnly
    EP_IT: 'it', // Permission : Configre
    IP_USER: 'user', // Permission : ReadOnly
    IP_SUPERADMIN: 'superadmin', // Permission : All access
    IP_COUNTRYADMIN: 'countryadmin', // Permission : All access according it's assigned country
    CSOC_MANAGER: 'csoc_manager', //Permission: All Access of ticketing system
    CSOC: 'csoc', // Permission: Access to first level of ticket assigned
    THIRD_PARTY_VENDOR: 'third_party_vendor',
    IP_VNFADMIN: 'vnfadmin', // Permission : All access according it's assigned vnf
    IP_NUSER: 'normal_user',
    PMO_ADMIN: 'pmo_admin',
    CHANGE_CONTROL_TEAM: 'change_control_team',
    INSTALL_TEAM: 'install_team',
    INSTALL_TEAM_LEAD: 'install_team_lead',
    INTEGRATION_TEAM: 'integration_team',
    QUALITY_CONTROL_TEAM: 'quality_control_team',
    PROJECT_MANAGEMENT_TEAM: 'project_management_team',
    PROJECT_MANAGER: 'project_manager',
    IM_TEAM: 'im_team',
    RADIO_ENGINEER_TEAM: 'radio_engineer_team',
    INSTALL_TEAM_ADMIN: 'install_team_admin',
    IM_TEAM_ADMIN: 'im_team_admin',
    CHANGE_CONTROL_TEAM_ADMIN: 'change_control_team_admin',
    INTEGRATION_TEAM_ADMIN: 'integration_team_admin',
    QUALITY_CONTROL_TEAM_ADMIN: 'quality_control_team_admin',
    FINANCE_TEAM_ADMIN: 'finance_team_admin',
    CSOC_TEAM_ADMIN: 'csoc_team_admin',
    PMO_READ_ONLY: 'pmo_read_only',
    STOCK_CONTROL_TEAM: 'stock_control_team',
    FIELD_ENGINEER_TEAM: 'field_engineer_team',
    QUALITY_ASSURANCE_MALAWI_TEAM: 'quality_assurance_malawi_team',
    BILLING_TEAM: 'billing_team',
    PMO_USER: 'pmouser',
    BDU_TEAM: 'bdu_team',
    PROVISIONING_TEAM: 'provisioning_team',
    INSTALLATION_TEAM: 'installation_team',
    SERVICE_RELIABILITY_TEAM: 'service_reliability_team',
    SD_READONLY: 'sd_readonly',
    SOLUTION_TEAM: 'solution_team',
    DEVELOPER_TEAM: 'developer_team',
    CLIENT_SERVICE_TEAM: 'client_service_team',
    NOC_TEAM: 'noc_team',
    FIELD_OPERATION_TEAM: 'field_operation_team',
    SERVICE_DELIVERY_TEAM: 'service_delivery_team',
    DELIVERY_TEAM: 'delivery_team',
    SALES_TEAM: 'sales_team',
    IT_TEAM: 'it_team',
    BI_DASHBOARD_ADMIN: 'bi_dashboard_admin',
    CAMTEL_TEST: 'camtel_test',
    WORKFLOW_MANAGEMENT_ADMIN: 'workflow_management_admin',
    MD_TEAM: 'md_team',
    PETTY_CASHIER_TEAM: 'petty_cashier_team',
    PROCUREMENT_TEAM: 'procurement_team',
    TREASURY_MANAGER_TEAM: 'treasury_manager_team',
    HUMAN_CAPITAL_TEAM: 'human_capital_team',
};
export const NODE_MANAGEMENT_TYPE = {
    MANAGED: 'managed',
    UNMANAGED: 'unmanaged'
};
export const LINK_TYPE = [
    { id: '100', name: 'Canopy' },
    { id: '200', name: 'Fibre' },
    { id: '300', name: 'Radwin Backhaul' },
    { id: '400', name: 'LTE' }
];
export const NATURE_OF_CAUSE = [
    { id: '100', name: 'Total Service Outage' },
    { id: '200', name: 'Service degradation' },
    { id: '300', name: 'Primary Link Outage' },
    { id: '1000', name: 'Secondary Link Outage' }
];

export const CHANGE_URGENCY = [
    { id: '1', name: 'High' },
    { id: '2', name: 'Medium' },
    { id: '3', name: 'Low' }
];

export const CHANGE_MATRIX_IMPACT = [
    { id: '1', name: 'High' },
    { id: '2', name: 'Medium' },
    { id: '3', name: 'Low' }
];

export const CHANGE_CATEGORY = [
    {
        id: '1',
        value: 'Standard Change',
        key: 'standardChange',
        description: "Is a pre-authorized change that is low risk, relatively common and follows a specified procedure or work instruction"
    },
    {
        id: '2',
        value: 'Emergency Change',
        key: 'emergencyChange',
        description: "A change that must be implemented as soon as possible. This change is of such a high priority that it bypasses group and peer review and approval and goes straight to the Authorization state for approval by the CAB approval group"
    },
    {
        id: '3',
        value: 'Normal Change',
        key: 'normalChange',
        description: "These are the changes that must be evaluated, authorized, and then scheduled according to a standardized process"
    }
];

export const CONTACT_TYPE = [
    { name: "Auto-Ticket" },
    { name: "Chat" },
    { name: "Web" },
    { name: "Email" },
    { name: "Phone" },
    { name: "Walk-In" },
    { name: "Own" }
]
export const CUSTOMER_TYPE = [
    { value: 'Base Station' },
    { value: 'Location' }
]
export const SEVERITY_OPTIONS = [
    { id: '100', name: 'Sev 1' },
    { id: '200', name: 'Sev 2' },
    { id: '300', name: 'Sev 3' },
    { id: '400', name: 'Sev 4' },
    { id: '500', name: 'Sev 5' }
];
export const SEVERITY_OPTIONS_INTERNAL = [
    { id: '100', name: 'Sev 1' },
    { id: '200', name: 'Sev 2' },
    { id: '300', name: 'Sev 3' },
    { id: '400', name: 'Sev 4' },
    { id: '500', name: 'MTN SLA' },
    { id: '600', name: 'Liquid SLA' },
    { id: '700', name: 'Fibercom SLA' },
    { id: '800', name: 'Airtel SLA' },
    { id: '900', name: 'Smartnet SLA' }
];
export const PROVIDERS = [
    // { id: 'uncanny', name: 'People analytics' },
    { id: 'edgeai', name: 'Object analytics' }
];
export const DISKSIZES = [
    { id: '100', name: '100GB' },
    { id: '200', name: '200GB' },
    { id: '300', name: '300GB' },
    { id: '400', name: '400GB' },
    { id: '500', name: '500GB' },
    { id: '600', name: '600GB' },
    { id: '700', name: '700GB' },
    { id: '800', name: '800GB' },
    { id: '900', name: '900GB' },
    { id: '1000', name: '1TB' }
];
export const CPUCORE = [
    { id: '8', name: '8' },
    { id: '12', name: '12' },
    { id: '16', name: '16' }
];
export const RAM = [
    { id: '32768', name: '32GB' },
    { id: '65536', name: '64GB' },
    { id: '131072', name: '128GB' }
];
export const FILTER_MODEL_TYPES = [
    { name: 'Multi Objects', id: 'multi-object' },
    { name: 'Multi Line Cross', id: 'line_cross' },
    { name: 'Facemask', id: 'facemask' },
    { name: 'Social Distancing', id: 'social_distancing' },
    { id: 'action_detection', name: 'Action Detection' },
    { id: 'face_recognition', name: 'Face Recognition' },
    { id: 'license_plate_recognition', name: 'License plate recognition' },
    { id: 'safety_helmet_detection', name: 'Safety helmet detection' },
    { id: 'camera_status', name: 'Camera status' },
    { id: 'custom', name: 'Custom' },
    { id: 'heatmap', name: 'Heatmap' }
];
export const FILTER_TAGS_BY_MODEL = {
    'multi-object': [
        {
            name: 'Car',
            id: 'car',
            field: { type: 'number', label: 'number.label' }
        },
        {
            name: 'Person',
            id: 'person',
            field: { type: 'number', label: 'number.label' }
        },
        {
            name: 'Chair',
            id: 'chair',
            field: { type: 'number', label: 'number.label' }
        },
        {
            name: 'Bicycle',
            id: 'bicycle',
            field: { type: 'number', label: 'number.label' }
        },
        {
            name: 'Truck',
            id: 'truck',
            field: { type: 'number', label: 'number.label' }
        },
        {
            name: 'Motorbike',
            id: 'motorbike',
            field: { type: 'number', label: 'number.label' }
        },
        { name: 'Bus', id: 'bus', field: { type: 'number', label: 'number.label' } }
    ],
    line_cross: [
        {
            name: 'Plate',
            id: 'plates',
            field: { type: 'text', label: 'plateNo.label' }
        }
    ],
    facemask: [
        {
            name: 'Mask',
            id: 'mask',
            field: {
                type: 'select',
                label: 'selectMask.label',
                options: ['Yes', 'No']
            }
        }
    ],
    license_plate_recognition: [
        {
            name: 'Plate',
            id: 'license_plate_recognition',
            field: { type: 'text', label: 'plateNo.label' }
        }
    ],
    action_detection: [
        {
            name: 'Action list',
            id: 'action',
            field: {
                type: 'select',
                label: 'selectAct.label',
                options: ['standing', 'bending', 'sitting', 'picking', 'sleeping', 'fighting']
            }
        }
    ]
};
export const TIME_FILTER = ['Today', 'Yesterday', 'Last week', 'Last month'];
export const MULTI_OBJECTS_LIST = [
    'bicycle',
    'person',
    'truck',
    'motorbike',
    'car',
    'bus',
    'cell phone'
];
export const ACD_OBJECTS_LIST = [
    'bicycle',
    'person',
    'truck',
    'motorbike',
    'car',
    'bus'
];

export const OBJECTS_DETECTION_LIST = [
    { id: "handgun", name: "Handgun" },
    { id: "hardhat", name: "Hardhat ( Safety Helmet )" },
    { id: "mask_weared_incorrect", name: "Person Wearing Mask incorrectly" },
    { id: "person_with_headgear", name: "Person with headgear" },
    { id: "rifle", name: "Rifle" },
    { id: "vest", name: "Safety Vest" },
    { id: "without_mask", name: "Person without Mask" },
    { id: "with_mask", name: "Person with Mask" },
    { id: "oil_spilling", name: "Oil Spilling" },
    { id: "vegitation", name: "Vegetation" },
    { id: "bags", name: "Bags" },
    { id: "box", name: "Box" },
    { id: 'forklift', name: 'Forklift' },
    { id: 'sleep', name: 'Sleep' },
    { id: 'smoking', name: 'Smoking' },
    { id: 'seatbelt', name: 'Seatbelt' },
    { id: 'no_seatbelt', name: 'No Seatbelt' },
    { id: 'no_vest', name: 'No Safety Vest' }

];

export const FREQUENCY_OPTIONS = [
    { name: '10s', id: 10 },
    { name: '30s', id: 30 },
    { name: '60s', id: 60 },
    { name: '120s', id: 120 },
    { name: '240s', id: 240 },
    { name: '300s', id: 300 },
    { name: '600s', id: 600 }
];
export const REQUIRED_ERROR = 'Fields marked as * are required.';
export const FIELD_REQUIRED_ERROR = ' marked as * are required.';
export const EMAIL_NOT_VERIFIED =
    'Your email address is not verified. We have sent OTP to your email address please check and verify it.';
export const INVALID_EMAIL = 'Please enter your bussiness email.';
export const INVALID_PHONE = 'Please enter valid phone number.';
export const INVALID_URL = 'Please enter valid URL';
export const ACCESS_ERROR = 'You are not authorized to access this page.';
export const CURRENCIES = [
    {
        code: 'USD',
        name: 'US Dollar',
        name_plural: 'US dollars',
        symbol: '$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: '$'
    },
    {
        code: 'AUD',
        name: 'Australian Dollar',
        name_plural: 'Australian dollars',
        symbol: 'AU$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: '$'
    },
    {
        code: 'NGN',
        name: 'Nigerian Naira',
        name_plural: 'Nigerian nairas',
        symbol: '₦',
        symbol_native: '₦',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: '₦'
    },
    {
        code: 'XOF',
        name: 'CFA Franc BCEAO',
        name_plural: 'CFA francs BCEAO',
        symbol: 'CFA',
        symbol_native: 'CFA',
        decimal_digits: 0,
        rounding: 0,
        htmlCode: 'CFA'
    },
    {
        code: 'ZMW',
        name: 'Zambian Kwacha',
        name_plural: 'Zambian Kwachas',
        symbol: 'ZK',
        symbol_native: 'ZK',
        decimal_digits: 0,
        rounding: 0,
        htmlCode: 'ZK'
    },
    {
        code: 'BWP',
        name: 'Botswanan Pula',
        name_plural: 'Botswanan pulas',
        symbol: 'BWP',
        symbol_native: 'P',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: 'P'
    },
    {
        code: 'MWK',
        name: 'Malawian kwacha',
        name_plural: 'Malawian kwachas',
        symbol: 'K',
        symbol_native: 'K',
        decimal_digits: 0,
        rounding: 0,
        htmlCode: 'K'
    },
    {
        code: 'INR',
        name: 'Indian Rupee',
        name_plural: 'Indian rupees',
        symbol: 'Rs',
        symbol_native: 'Rs',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: 'Rs'
    },
    {
        code: 'ZAR',
        name: 'South African Rand',
        name_plural: 'South African Rand',
        symbol: 'R',
        symbol_native: 'R',
        decimal_digits: 2,
        rounding: 0,
        htmlCode: 'R'
    }
];
export const DEFAULT_ADDRESS = {
    addressLine1: '6th Floor; Tower A',
    addressLine2: '1 CyberCity, Ebene, Mauritius',
    email: 'support@inq.com'
};
export const EDGE_AI_LIVE_CAM_URL = 'http://edgeai-streamer-staging-856201964.af-south-1.elb.amazonaws.com/';
export const CAMERA_STATUS_OPTIONS = [{
    name: 'Active',
    id: 'active',
},
{
    name: 'Pending',
    id: 'pending',
},
{
    name: 'Failed',
    id: 'failed',
},
{
    name: 'Deleted',
    id: 'deleted',
}
];

export const CLIENT_FILTER_OPTIONS = [{
    name: 'Timelink',
    id: 'timelink',
},
{
    name: 'Profit Typhoon',
    id: 'profit-typhoon',
}
];

export const AI_MODEL_LIST_TYPES = [
    { name: 'Blacklist', id: 'blacklisted' },
    { name: 'Whitelist', id: 'whitelisted' },
    { name: 'Unknown', id: 'unknown' }
];

export const ACTION_TYPES = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
}

export const EDGE_AI_ORGS = {
    CTRACK: "1629984666484706",
    IHS: "1623671953691863",
    TEST_ORG: "1631085875539144",
    LIQUID_IT: "1638263571872650"
}
export const GROUP_TYPES = [
    { name: "Customer", id: "customer" },
    { name: "Partner", id: "partner" },
];
export const GROUP_TYPES_KEYS = {
    PARTNER: "partner",
    CUSTOMER: "customer"
}
export const HOST_SITE_TITLE = {
    "echotel.partner.inq.inc": "Echotel",
    "cartrack.partner.inq.inc": "Cartrack",
    "platform.echoskywalker.com": "Echoskywalker"
}

export const LIVE_CAM_STREAM_URL = `https://media.edgedock.co.za/LiveApp/`;

export const checkInternalPlatform = () => {
    const hostName = window.location.hostname;
    return (hostName === "platform-staging.inq.inc" || hostName === "platform.inq.inc" || hostName === "localhost") ? false : true;
}