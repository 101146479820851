import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Paper
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFTextField
} from '../../../../../components/hook-form';
import { updateClientById } from '../../../../../services/clientService';
import Popup from '../../../Popup';

const ViewClientPopup = ({
  openViewPopup,
  setOpenViewPopup,
  clientData,
  classes
}) => {
  const initialValues = { clientName: '' };
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      client_name: data?.clientName,
      head_quarters: data?.headQuarter,
      bussiness_contact_email: data?.BusinessEmail,
      technical_contact_email: data?.techEmail,
      telephone_contact: data?.telephone
    };
    const res = await updateClientById(clientData?._id, payload);
    if (res?.data?.statusCode === 200) {
      reset();
      setOpenViewPopup(false);
    }
  };

  useEffect(() => {
    if (clientData && Object.keys(clientData)?.length) {
      setValue('clientName', clientData?.client_name);
      setValue('headQuarter', clientData?.head_quarters);
      setValue('BusinessEmail', clientData?.bussiness_contact_email);
      setValue('techEmail', clientData?.technical_contact_email);
      setValue('telephone', clientData?.telephone_contact);
    }
  }, [clientData, setValue]);

  return (
    <div>
      <Popup
        title="View Client Data"
        openPopup={openViewPopup}
        setOpenPopup={setOpenViewPopup}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container direction="column" spacing={3}>
              <Grid item container spacing={3}>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Name
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="clientName"
                      disabled
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Headquarter
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="headQuarter"
                      disabled
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Business Email
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="BusinessEmail"
                      disabled
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Technical Email
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="techEmail"
                      disabled
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{ color: 'grey', fontWeight: '500' }}
                    sm={4}>
                    Telephone
                  </Grid>
                  <Grid item sm={1}>
                    <span>:</span>
                  </Grid>
                  <Grid item sm={7}>
                    <RHFTextField
                      name="telephone"
                      disabled
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                setOpenViewPopup(false);
              }}>
              Close
            </Button>
          </DialogActions>
        </FormProvider>
      </Popup>
    </div>
  );
};

export default ViewClientPopup;
