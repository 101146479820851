import React, {useEffect, useState} from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NotificationPanelItem from '../NotificationPanelItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBox from '../../../../components/SearchBox';
import Pagination from '@material-ui/lab/Pagination';
import { ADD_CAMERA_TO_NOTIFICATION_TEMPLATE } from '../../mutations';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_NOTIFICATION_TEMPLATE_BY_ID, GET_CAMERA_LIST } from '../../queries';
import AttchedCamera from '../AttchedCamera';
import Message from '../../../../components/Message';
import AssignNotificationTemp from '../AssignNotificationTemp';
function NotificationPanel({ 
    t,
    menuItems,
    list,
    handleCreateButtonClick,
    handleMenuItemClick,
    loading,
    error,
    allowConfigure,
    handleSearch,
    page,
    handlePageChange,
    reloadNotiTemplateData,
    organization
}) {

    const [goToPage, setGoToPage] = useState(null);
    const [isAttachedCameraEnable, setIsAttachedCameraEnable] = useState(false);
    const [isAssignEnable, setIsAssignEnable] = useState(false);
    const [dataForAttchedCamera, setDataForAttchedCamera] = useState([]);
    const [createAssignCameraNotifyTempLoading, setCreateAssignCameraNotifyTempLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [getAssignCameraList, {
        loading: camLoading,
        data: cameraList,
        error: camError
      }] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: "no-cache"
    });

    let columns = [
        {
            label:t('template.label')+' '+t('name.label'),
            key:"name"
        },
        {
            label:t('createdBy.label'),
            key:"createdByName"
        },
        {
            label:t('createdDate.label'),
            key:"createdDate"
        },
        {
            label:t('numberOfAttachedCam.label'),
            key:"noOfCam"
        }
    ];
    if(allowConfigure){
        columns.push({
            label:t('edit.label')+'/'+t('delete.label') + '/' + t('assignToCamera.label'),
            key:"button"
        });
        menuItems.push({ label: t('assignToCamera.label') });
    }

    const [assignCameraToNotifyTemp] = useMutation(ADD_CAMERA_TO_NOTIFICATION_TEMPLATE, {
        onError: error => {
            setCreateAssignCameraNotifyTempLoading(false);
            Message.error(error);
        },
        onCompleted: data => {
            setCreateAssignCameraNotifyTempLoading(false);
            reloadNotiTemplateData();
            Message.success(data && data.addAssginCameraToNotifictionTemp.response);
            setIsAssignEnable(false);
        }
    });

    const [getNotifictionTemAttachedCameraList, {loading: detailsLoading}] = useLazyQuery(GET_NOTIFICATION_TEMPLATE_BY_ID, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            if (data && data.getNotificationTemplateById) {
                const { attached_cameras } = data.getNotificationTemplateById;
                setDataForAttchedCamera({ cameras: attached_cameras ? attached_cameras : [] });
            }
        },
        onError: error => {
            Message.error(error);
        }
    });

    const handleClickNotiRow = (rowItm) => {
        if (rowItm.noOfCam) {
            setIsAttachedCameraEnable(true);
            return getNotifictionTemAttachedCameraList({
                variables: {
                    id: rowItm.id
                }
            });
        }
    }

    const handleSearchChange = (val) => {
        handleSearch("notification", val);
    }

    const handleSubmitOfNotifictionTempMutation = (id, allPyaload) => {
        setCreateAssignCameraNotifyTempLoading(true);
        assignCameraToNotifyTemp({
            variables: {
                id: id,
                cameraDetails: allPyaload
            }
        });
    };

    const handleAssignToCamera = (rowItm) => {
        setSelectedRow(rowItm);
        setIsAssignEnable(true);
        return getAssignCameraList({
            variables: {
                organization,
                page: 1,
                limit: 20000
            }
        });
    }
    
    
    return (
        <div>
            {allowConfigure && 
                <Grid container justify={'space-between'}>
                    <Grid item>
                        <SearchBox handleSearch={handleSearchChange} />
                    </Grid>
                    <Grid item>
                        <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() =>
                            handleCreateButtonClick('notification_template')
                        }>
                            {t('createNow.label')}
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container >
                <TableContainer>
                    <Table>
                        <TableHead>
                        <TableRow>
                            {columns.map((item, index) => {
                                return ( 
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { !loading && !error ? 
                            list.getAllNotificationTemplateList && list.getAllNotificationTemplateList.templates.length > 0 ?
                            list.getAllNotificationTemplateList.templates.map((rowitem, rowIndex) => (
                                <NotificationPanelItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    menuItems={menuItems}
                                    handleMenuItemClick={handleMenuItemClick}
                                    allowConfigure={allowConfigure}
                                    reloadNotiTemplateData={reloadNotiTemplateData}
                                    handleClickNotiRow={handleClickNotiRow}
                                    handleAssignToCamera={handleAssignToCamera}
                                />
                            )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    {loading && !error && <LinearProgress color="primary"/> }
                                </TableCell>
                            </TableRow>
                        }
                        </TableBody>
                    </Table>
                    {!loading &&
                        <>
                            <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                                <Grid item xs={2}>
                                    <span>Total {Math.ceil(get(list, 'getAllNotificationTemplateList.count', null)/20)} page,  {get(list, 'getAllNotificationTemplateList.count', null)} Row</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Pagination 
                                        color="secondary" 
                                        count={list && list.getAllNotificationTemplateList ? Math.ceil(list.getAllNotificationTemplateList.count/20) : 0} 
                                        page={page} 
                                        onChange={(evt, value) => {
                                            handlePageChange("notification", value);
                                        }} 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <span>Go to page </span>
                                </Grid>
                                <Grid item xs={1}>
                                    <Input
                                        value={goToPage}
                                        type="number"
                                        style={{ minWidth: 100 }}
                                        color={'secondary'}
                                        onChange={evt => {
                                            let maxRecord = list && list.getAllNotificationTemplateList ? parseInt(Math.ceil(list.getAllNotificationTemplateList.count/20)) : 0;
                                            if(parseInt(evt.target.value) > maxRecord){
                                                return null;
                                            }
                                            setGoToPage(evt.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        onClick={()=>handlePageChange('notification',parseInt(goToPage))}
                                        variant={'outlined'}
                                        disabled={!goToPage}
                                        color="primary"
                                        style={{ marginLeft: 10 }}
                                    >Go</Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                </TableContainer>
                
                <AttchedCamera
                    t={t}
                    loading={detailsLoading}
                    rederDataType='camera'
                    data={dataForAttchedCamera}
                    title={t('attachedCameras.label')}
                    isAttachedCameraEnable={isAttachedCameraEnable}
                    handleClose={evt => setIsAttachedCameraEnable(false)}
                />
                {selectedRow &&
                    <AssignNotificationTemp
                        t={t}
                        data={selectedRow}
                        title={t('assignNotificationTemplate.label')}
                        isAssignEnable={isAssignEnable}
                        handleClose={evt => {
                            setIsAssignEnable(false);
                            setSelectedRow(null);
                        }}
                        cameraList={cameraList}
                        camLoading={camLoading}
                        camError={camError}
                        assignassignCameraToNotifyTemp={assignCameraToNotifyTemp}
                        assignMutationLoading={createAssignCameraNotifyTempLoading}
                        handleSubmitFunc={handleSubmitOfNotifictionTempMutation}
                    />
                }
            </Grid>
        </div>
    );
}

NotificationPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default NotificationPanel;
