import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

const ElasticMonitoringItem = ({
                                   t,
                                   data,
                                   rowIndex,
                                   handleClick
                               }) => {
    const commonClasses = commonStyles();
    return (
        <TableRow key={rowIndex}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {rowIndex}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.username}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}> {data.password}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    <Button size='medium' variant='contained' color='primary'
                            onClick={(e) => handleClick(data)}>Launch</Button>
                </Grid>
            </TableCell>
        </TableRow>
    );
};
ElasticMonitoringItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(ElasticMonitoringItem);
