import React from 'react';
import { withTranslation } from 'react-i18next';
import { USER_ROLES } from '../../../../utils/constants';
import CustomTabs from '../../../../components/CustomTabs';
import TicketPanel from '../../../ServiceTickets/components/TicketPanel';
import DashboardPanel from './components/DashboardPanel';
import ConfigurationPanel from './components/ConfigurationPanel';

const IOTSensors = ({
    t, 
    data, 
    countryCodes, 
    serviceTickets,
    filterListRecentTickets,
    handleTicketItemClick,
    handleTicketMenuItemClick,
    handleSearchRecentTickets,
    recentTicketsSearched,
    updatedRecentTickets,
    activeIndex
}) => {

    const role = localStorage.getItem('role');
    let tabList = [];
    if(data){
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <>
                        <DashboardPanel
                            t={t}
                            subscriptionData={data.getSubscriptionById}
                        />
                    </>
                )
            }
        ];
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('configuration.label'),
                children: (
                    <>
                        <ConfigurationPanel
                            t={t}
                            data={data.getSubscriptionById}
                            countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                        />
                    </>
                )
            });
        }
        tabList.push({
            label: t('support.label'),
            children: (
                <>
                    {serviceTickets && (
                        <TicketPanel
                            ticketList={
                                recentTicketsSearched
                                    ? updatedRecentTickets
                                    : serviceTickets.servicetickets.slice(0, 10)
                            }
                            type={'recentTickets'}
                            t={t}
                            handleMenuItemClick={handleTicketMenuItemClick}
                            filterList={filterListRecentTickets}
                            handleTicketItemClick={handleTicketItemClick}
                            handleSearch={handleSearchRecentTickets}
                        />
                    )}
                </>
            )
        });
        
        if (
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.IP_SUPERADMIN) ||
            role.includes(USER_ROLES.EP_ADMIN)
        ) {
            tabList.push({
                label: t('settings.label'),
                children: (
                    <>
                        <p>Settings</p>  
                    </>
                )
            });
        }
    }
    
    return (
        <>
            <CustomTabs 
                activeIndex={activeIndex} 
                tabs={tabList}
            />            
        </>
    );
};
export default withTranslation()(React.memo(IOTSensors));
