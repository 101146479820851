import React, { useEffect } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import Popup from '../../../../Popup';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  dialogContent: {
    padding: '32px 30px'
  },
  dialogActions: {
    padding: '22px 22px'
  }
});

const VlansPopup = ({
  openVlanPopup,
  setOpenVlanPopup,
  availableVlans,
  setAvailableVlans
}) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      setAvailableVlans && setAvailableVlans([]);
    };
  }, []);

  return (
    <Popup
      title="Available V-Lans Ranges"
      openPopup={openVlanPopup}
      setOpenPopup={setOpenVlanPopup}>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            {availableVlans && availableVlans?.length !== 0 ? (
              availableVlans?.map((item, i) => (
                <List component="ul" key={i}>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item}></ListItemText>
                  </ListItem>
                </List>
              ))
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={() => setOpenVlanPopup(false)}>
          Close
        </Button>
      </DialogActions>
    </Popup>
  );
};

export default VlansPopup;
