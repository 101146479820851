import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import {
  Paths,
  SubscriptionPaths,
  ServiceTicketPaths
} from '../../routes/routePaths';
import Header from '../../components/Header';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import { colors } from './../../theme/colors';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_DETAILS } from '../Subscriptions/queries';
import { STATUS_TYPES, SUBSCRIPTION_STATUS } from '../../utils/constants';
import PendingItem from '../Subscriptions/components/PendingItem';
import ActiveItem from '../Subscriptions/components/ActiveItem';
import { getUrl } from '../../utils';
import { USER_ROLES, SERVICE_TYPES } from '../../utils/constants';
import { useHistory } from 'react-router';
import ActionCard from './components/ActionCard';
import Statcard from '../../components/Statcard';
import cpe_orange from '../../assets/icons/cpe_orange.png';
import cpe_down from '../../assets/icons/cpe_down.png';
import ram from '../../assets/icons/ram.png';
import { GET_SERVICE_TICKETS } from '../ServiceTickets/queries';
import { GET_USER_INFO } from '../../containers/Profile/queries';
import add_cpe from '../../assets/icons/add_cpe.png';
import read_docs from '../../assets/icons/read_docs.png';
import invoices from '../../assets/icons/invoices.png';
import add_ticket from '../../assets/icons/add_ticket.png';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditSubscriptionModal from '../Subscriptions/components/EditSubscriptionModal';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import { UPDATE_SUBSCRIPTION_AND_SITES } from '../Subscriptions/mutations';
import Message from '../../components/Message';
import ActionCardLayout from './components/ActionCardLayout'

const breadcrumbList = [{ label: 'home.label', link: Paths.Dashboard }];

const OrderInProgress = ({
  t,
  subscriptionList,
  handleSubscriptionItemClick,
  handleMoreClick,
  handleMenuItemClick,
  handleActiveSubscriptionItemClick
}) => {
  const activeMenuItems = [
    { label: t('viewDetails.label') },
    { label: t('viewConfiguration.label') },
    { label: t('openServiceTickets.label') },
    { label: t('raiseNewTicket.label') },
    {label: t('edit.label')}
  ];
  const list = subscriptionList;

  return (
    <div style={{ ...marginGenerator('mb-20') }}>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-16'),
          ...marginGenerator('mb-8'),
          ...paddingGenerator('pb-16'),
          borderBottom: `1px solid ${colors.grey[200]}`
        }}>
        {t('yourOrders.label')}
      </Typography>
      <Grid style={{ ...paddingGenerator('pl-25') }}>
        {list.map((item, index) => {
          if (item.status === SUBSCRIPTION_STATUS.PENDING) {
            return (
              <PendingItem
                key={index}
                data={item}
                handleSubscriptionItemClick={handleSubscriptionItemClick}
                handleMenuItemClick={handleMenuItemClick}
              />
            );
          } else if (item.status === SUBSCRIPTION_STATUS.ACTIVE) {
            return (
              <ActiveItem
                key={index}
                data={item}
                menuItems={activeMenuItems}
                handleSubscriptionItemClick={handleActiveSubscriptionItemClick}
                handleMenuItemClick={handleMenuItemClick}
              />
            );
          }
        })}
      </Grid>
      {subscriptionList.length - list.length !== 0 && (
        <Typography
          variant={'subtitle2'}
          color={'secondary'}
          onClick={handleMoreClick}
          style={{
            ...marginGenerator('mt-16'),
            ...paddingGenerator('pb-16'),
            ...paddingGenerator('pl-25'),
            borderBottom: `1px solid ${colors.grey[200]}`
          }}>
          {subscriptionList.length - list.length} {t('moreOrderItems.label')}
        </Typography>
      )}
    </div>
  );
};

// const HelpPanel = ({ t, history }) => {
//   const HelpPanelArr = [
//     {
//       title: t('browseServices.label'),
//       description:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiu ed ed.',
//       img: CpeBlue,
//       background: '#039be5',
//       handleClick: () => {
//         history.push({
//           pathname: Paths.NewSubscription,
//           search: '?browseDash'
//         });
//       }
//     },
//     {
//       title: t('readDocumentation.label'),
//       description:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiu ed ed.',
//       img: DocsOrange,
//       background: '#ff7043'
//     },
//     {
//       title: t('getHelp.label'),
//       description:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiu ed ed.',
//       img: HeadsetTeal,
//       background: '#26a69a'
//     }
//   ];
//   const classes = useStyles();
//   return (
//     <Grid
//       container
//       style={{
//         ...marginGenerator('mt-40'),
//         ...marginGenerator('mb-32')
//       }}>
//       {HelpPanelArr.map((item, index) => (
//         <Grid
//           container
//           item
//           style={{
//             cursor: 'pointer',
//             ...(index !== HelpPanelArr.length - 1
//               ? { ...marginGenerator('mr-24') }
//               : null),
//             ...marginGenerator('mb-24')
//           }}
//           onClick={item.handleClick}
//           key={index}
//           className={classes.helpPanelContainer}>
//           <Grid
//             container
//             item
//             style={{
//               ...paddingGenerator('pt-32'),
//               ...paddingGenerator('pl-40'),
//               ...paddingGenerator('pr-40')
//             }}>
//             <Typography variant="h4">{item.title}</Typography>
//             <Typography
//               variant="body1"
//               style={{ ...marginGenerator('mb-16'), color: colors.grey[300] }}>
//               {item.description}
//             </Typography>
//           </Grid>
//           <Grid
//             container
//             item
//             justify={'flex-end'}
//             style={{ background: item.background }}
//             className={classes.imgContainer}>
//             <img
//               src={item.img}
//               className={classes.iconStyle}
//               alt={item.title}
//               style={{
//                 ...marginGenerator('mr-36'),
//                 ...marginGenerator('mt--25')
//               }}
//             />
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

const getInputs = (type, t) => {
  let inputArr = [];
  console.log("type: ", type);
  if (type === SERVICE_TYPES.MPLS) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        placeholder: '',
        endAdorment: 'MBPS'
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.DIA) {
    inputArr = [
      {
        label: t('bandwidth.label'),
        type: 'number',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        placeholder: ''
      },
      {
        label: t('mtu.label'),
        type: 'number',
        stateVar: `mtu`,
        placeholder: ''
      },
      {
        label: t('period.label'),
        type: 'number',
        stateVar: `period`,
        placeholder: ''
      },
      {
        label: t('linkType.label'),
        type: 'string',
        stateVar: `linkType`,
        placeholder: ''
      },
      {
        label: t('noOfPublicIPs.label'),
        type: 'number',
        stateVar: `noOfPublicIPs`,
        placeholder: ''
      },
      {
        label: t('blockedUrls.label'),
        type: 'string',
        stateVar: `blockedUrls`,
        placeholder: ''
      },
      {
        label: t('portForwarding.label'),
        type: 'string',
        stateVar: `portForwarding`,
        placeholder: ''
      },
      {
        label: t('ddosPrevention.label'),
        type: 'string',
        stateVar: `ddosPrevention`,
        placeholder: ''
      }
    ];
  } else if (type === SERVICE_TYPES.EDGE_AI) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('numberOfCam.label'),
        type: 'number',
        stateVar: `numberOfCamera`,
        placeholder: 'numberOfCamPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fibre', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      }
    ];
  } else if (type === SERVICE_TYPES.SDN) {
    inputArr = [
      {
        label: t('availableBandwidth.label'),
        type: 'selectDropdown',
        stateVar: `bandwidth`,
        //endAdorment: 'MBPS',
        options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
        placeholder: 'availableBandwidthPh.label'
      },
      {
        label: t('period.label'),
        type: 'selectDropdown',
        stateVar: `period`,
        options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
        placeholder: 'periodPh.label'
      },
      {
        label: t('linkType.label'),
        type: 'selectDropdown',
        stateVar: `linkType`,
        options: ['Fiber', 'Wireless', 'VSAT'],
        placeholder: 'linkTypePh.label'
      },
      {
        label: t('serviceType.label'),
        type: 'selectDropdown',
        stateVar: `serviceType`,
        options: ['SD-WAN', 'Security', 'BYOVNF'],
        placeholder: 'serviceTypePh.label'
      }
    ];
  }
  return [
    {
      label: t('siteName.label'),
      type: 'string',
      stateVar: `siteName`,
      placeholder: 'siteNamePh.label'
    },
    ...inputArr
  ];
};

const OverviewPanel = ({ t, stats }) => {
  return (
    <div>
      <Typography
        variant={'h4'}
        style={{
          ...marginGenerator('mt-16'),
          ...marginGenerator('mb-8'),
          ...paddingGenerator('pb-16')
        }}>
        {t('overview.label')}
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          ...marginGenerator('mb-25')
        }}>
        {stats.map((item, index) => (
          <Statcard
            key={index}
            statLabel={item.label}
            icon={item.icon}
            statValue={item.value}
          />
        ))}
      </Grid>
    </div>
  );
};

const Dashboard = ({ t }) => {
  
  const role = localStorage.getItem('role');
  const history = useHistory();
  const [editSubscriptionModal, setEditSubscriptionModal] = useState({ open: false, btnLoading: false, clearData: false, data: [] });
  const [editSubscriptionState, setEditSubscriptionState] = useState({});
  const [editSubsLoading, setEditSubsLoading] = useState(false);
  const [inputArr, setInputArr] = useState([]);
  const [locationState, setLocationState] = useState({});

  const editMenuItems = [
    // { label: t('openSiteConfiguration.label') },
    { label: t('removeSite.label') }
  ];

  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
  const { data: countryList } = useQuery(GET_COUNTRY_LIST);

  const {
    loading: activeLoading,
    error: activeError,
    data: activeSubscriptions,
    refetch: reloadActiveSubscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      status: SUBSCRIPTION_STATUS.ACTIVE
    }
  });

  const {
    data: userdata,
    loading: userDataLoading,
    error: userDataError
  } = useQuery(GET_USER_INFO);

  const {
    loading: pendingLoading,
    error: pendingError,
    data: pendingSubscriptions,
    refetch: reloadPendingSubscriptionList
  } = useQuery(GET_SUBSCRIPTION_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      status: SUBSCRIPTION_STATUS.PENDING
    }
  });

  const {
    loading: allSubscriptionsLoading,
    error: allSubscriptionsError,
    data: allSubscriptions
  } = useQuery(GET_SUBSCRIPTION_LIST, {});

  const {
    loading: openLoading,
    error: openError,
    data: openServiceTickets
  } = useQuery(GET_SERVICE_TICKETS, {
    variables: { status: STATUS_TYPES.OPEN }
  });
  
  const [getSubscriptionByIdForEdit] = useLazyQuery(GET_SUBSCRIPTION_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setEditSubsLoading(false);
      if (data.getSubscriptionById) {
        setInputArr(getInputs((data.getSubscriptionById.service && data.getSubscriptionById.service.serviceType) ? data.getSubscriptionById.service.serviceType.name : "", t));
        const { getSubscriptionById } = data;
        setEditSubscriptionModal({...editSubscriptionModal, data: getSubscriptionById});
        const { sites } = getSubscriptionById;
        let updatedSites = {};
        sites && sites.length && sites.forEach((item, index) => {
            const configuration =
              item.configuration && JSON.parse(item.configuration);
            let confObj = {};
            configuration.forEach((item, index) => {
              confObj = { ...confObj, [item.key]: item.value };
            });
            updatedSites = {
              ...updatedSites,
              [`site${index + 1}`]: {
                ...editSubscriptionState[`site${index}`],
                id: item.id,
                siteName: item.name,
                address: item.address,
                latitude: item.latitude,
                longitude: item.longitude,
                zipcode: item.zipcode,
                landmark: item.landmark,
                ...confObj
              }
            };
        });
        setEditSubscriptionState({ ...editSubscriptionState, ...updatedSites });
      }
    }
  });

  const [updateSubscriptionAndSite] = useMutation(UPDATE_SUBSCRIPTION_AND_SITES, {
    onError: error => {
      setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
      console.log('Error', error);
    },
    onCompleted: async data => {
      if(data.updateSubscriptionAndSites && data.updateSubscriptionAndSites.id != null){
        Message.success("Subscription updateted successfully");
        if(editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.ACTIVE){
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
          reloadActiveSubscriptionList();
        } else if(editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.PENDING){
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
          reloadPendingSubscriptionList();
        }
      } else {
        setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
      }
    }
  });

  const handleSubmitEditSubscription = async (data) => {
    setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: true, clearData: false });
    let sites = Object.keys(editSubscriptionState).map((key) => editSubscriptionState[key]);
    await updateSubscriptionAndSite({
      variables: {
        id: data.id,
        name: data.name,
        sites: sites
      }
    })
  };

  const handleSubscriptionItemClick = id => {
    const path = getUrl(SubscriptionPaths.PendingSubscriptionDetails, id);
    history.push({ pathname: path, search: `?history=dashboard` });
    return null;
  };

  const handleMoreClick = () => {
    history.push({ pathname: Paths.Subscriptions, state: { activeIndex: 1 } });
    return null;
  };

  const handleActiveSubscriptionItemClick = (id, activeIndex) => {
    const path = getUrl(SubscriptionPaths.ActiveSubscriptionDetails, id);
    history.push({
      pathname: path,
      ...(activeIndex ? { state: { activeIndex } } : {})
    });
    return null;
  };

  const handleEditActiveSubscriptionClick = (id) => {
    if (id) {
      setEditSubsLoading(true);
      setEditSubscriptionModal({ open: true, btnLoading: false, clearData: false });
      return getSubscriptionByIdForEdit({
        variables: {
          id: id
        }
      });
    }
  };

  const handleInputChange = (name, value, site) => {
    if (name === 'country') {
      getRegionList({ variables: { countryId: value } });
    }
    setEditSubscriptionState({ ...editSubscriptionState, [site]: { ...editSubscriptionState[site], [name]: value } });
  };

  const handleSetPosition = (pos, site) => {
    setLocationState({
      ...locationState,
      [site]: {
        latitude: pos.latitude,
        longitude: pos.longitude
      }
    });
  };

  const handleMenuItemClick = (item, id) => {
    if (item.label === t('closeTicket.label')) {
    } else if (item.label === t('viewDetails.label')) {
      handleActiveSubscriptionItemClick(id);
    } else if (item.label === t('viewConfiguration.label')) {
      handleActiveSubscriptionItemClick(id, 1);
    } else if (item.label === t('openServiceTickets.label')) {
      handleActiveSubscriptionItemClick(id, 2);
    } else if (item.label === t('raiseNewTicket.label')) {
      history.push(ServiceTicketPaths.NewServiceTicket);
    } else if (item.label === t('edit.label')) {
      handleEditActiveSubscriptionClick(id);
    }
    return null;
  };

  // if (
  // !activeLoading &&
  // !activeError &&
  // !pendingLoading &&
  // !pendingError &&
  // !openLoading &&
  // !openError
  // ) {
  // }
  let statList = [
    {
      label: t('activeSubscriptions.label'),
      value: activeSubscriptions
        ? activeSubscriptions.getAllSubscriptions.length
        : 0,
      icon: cpe_orange
    },
    {
      label: t('pendingSubscriptions.label'),
      value: pendingSubscriptions
        ? pendingSubscriptions.getAllSubscriptions.length
        : 0,
      icon: cpe_down
    },
    {
      label: t('openTickets.label'),
      value: openServiceTickets ? openServiceTickets.servicetickets.length : 0,
      icon: ram
    }
  ];

  const ActionPanel = ({ t }) => {
    const actionItems = [
      {
        label: t('subscribeToNew.label'),
        icon: add_cpe,
        link: Paths.NewSubscription
      },
      {
        label: t('viewPendingInvoices.label'),
        icon: invoices,
        link: null
      },
      {
        label: t('raiseAServiceRequest.label'),
        icon: add_ticket,
        link: ServiceTicketPaths.NewServiceTicket
      },
      {
        label: t('readHelpAndDoc.label'),
        icon: read_docs,
        link: null
      }
    ];

    return (
        <Grid container spacing={5} style={marginGenerator('mt-16')}>
          {actionItems.map((item, index) => (
            <ActionCardLayout
              icon={item.icon}
              link={item.link}
              label={item.label}
              key={index}
            />
          ))}
        </Grid>
    );
  };

  const handleEditMenuItemClick = (item, site) => {};

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={
          !userDataLoading && !userDataError
            ? `${t('hello.label')}, ${userdata.userInfo.firstName}`
            : ''
        }
        subText={t('welcomeInqPanel.label')}
      />
      {/* ${t('statsLastUpdated.label')} ${dateFormatterLLL(
        Date.now()
      )} */}
      {role.includes(USER_ROLES.IP_NUSER) ? null : 
        <>
          <ActionPanel t={t} />
          <OverviewPanel t={t} stats={statList} />
          {!allSubscriptionsLoading &&
            !allSubscriptionsError &&
            allSubscriptions && (
              <OrderInProgress
                t={t}
                subscriptionList={allSubscriptions.getAllSubscriptions}
                handleSubscriptionItemClick={handleSubscriptionItemClick}
                handleMoreClick={handleMoreClick}
                handleMenuItemClick={handleMenuItemClick}
                handleActiveSubscriptionItemClick={
                  handleActiveSubscriptionItemClick
                }
              />
            )}
          {allSubscriptionsLoading && !allSubscriptionsError && (
            <LinearProgress
              color="primary"
              style={{
                top: '98px',
                left: '0px',
                position: 'absolute',
                width: '100%'
              }}
            />
          )}
          {/* <HelpPanel t={t} history={history} /> */}
        </>
      }

      <EditSubscriptionModal
        t={t}
        open={editSubscriptionModal.open}
        handleSubmitClick={handleSubmitEditSubscription}
        modalState={editSubscriptionModal}
        handleClose={() => {
          setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
        }}
        subscriptionData={editSubscriptionModal.data}
        state={editSubscriptionState}
        menuItems={editMenuItems}
        inputArr={inputArr}
        handleSetPosition={handleSetPosition}
        handleInputChange={handleInputChange}
        countryList={countryList && countryList.getCountries}
        regionList={regionList && regionList.getRegionsByCountryId}
        loading={editSubsLoading}
        handleMenuItemClick={handleEditMenuItemClick}
      />
    </Layout>
  );
};
export default withTranslation()(Dashboard);
