import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../../../theme/commonStyles';
import { IOT_DEVICE_STATUS } from '../../../../../utils/constants';
import { paddingGenerator } from '../../../../../theme/utils';
import PropTypes from 'prop-types';
import { grey, common } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  statusContainer: {
    height: '15px',
    borderRadius: '16px',
    alignItems: 'center',
    display: 'flex'
  },
  provStatus: {
    background: theme.palette.warning.main
  },
  activeStatus: {
    background: theme.palette.success.main
  },
  failedStatus: {
    background: theme.palette.error.main
  },
  defaultStatus: {
    background: grey[200],
    color: "black"
  },
  textStyle: {
    color: theme.palette.common.white,
    fontSize: '14px'
  },
  textStyleBlack: {
    color: theme.palette.common.black,
    fontSize: '14px'
  }
}));

const StatusPanel = ({ t, status }) => {
    const classes = useStyles();
    let textStyleClass = classes.textStyle;
    const commonClasses = commonStyles();
    status = status.toLowerCase();
    let statusClasses, label;
    if(status === IOT_DEVICE_STATUS.PANDING){
      statusClasses = classes.provStatus;
      label = t('pending.label');
    }else if(status === IOT_DEVICE_STATUS.ACTIVE){
      statusClasses = classes.activeStatus;
      label = t('active.label');
    }else if(status === IOT_DEVICE_STATUS.FAILED){
      statusClasses = classes.failedStatus;
      label = t('down.label');
    }else{
      statusClasses = classes.defaultStatus;
      textStyleClass = classes.textStyleBlack;
      label = status;
    }

    return (
        <div
        className={clsx(
            classes.statusContainer,
            statusClasses
        )}
        style={{
            ...paddingGenerator('pt-8'),
            ...paddingGenerator('pb-6'),
            ...paddingGenerator('pl-12'),
            ...paddingGenerator('pr-12')
        }}>

          <Typography
              className={clsx(commonClasses.buttonTextSmall, textStyleClass)}
              variant={'body1'}>
              {label}
          </Typography>
        </div>
    );
};

StatusPanel.propTypes = {
  status: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default StatusPanel;