import React, { useContext, useState, useMemo, useEffect, useRef} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { get, debounce } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from '@material-ui/core/Checkbox';

import { ShayonaCementsContext } from '../../../../../../contexts/ShayonaCementsProvider';
import ShayonaTripsItem from './ShayonaTripsItem';
import {
    DELETE_SHAYONA_TRIP,
    LOADING_BAGS_OF_SHAYONA_TRIP_TRUCK,
    PRINT_EXIT_OF_SHAYONA_TRIP_TRUCK
} from '../../../../Shayona/mutations';
import {marginGenerator} from '../../../../../../theme/utils';
import Message from '../../../../../../components/Message';
import EditTruckInTripListing from '../../components/EditTruckInTripListing';
import TripPrintSlipModal from '../../components/TripPrintSlipModal';
import EmptyWeightModal from '../../components/EmptyWeightModal';
import SearchBox from '../../../../../../components/SearchBox';
import ChartContainer from '../../../../../../components/Charts/ChartContainer';
import CaptureWeightOfTruck from '../../components/CaptureWeightOfTruck';
import ExitPrintSlipOfTruck from '../../components/ExitPrintSlipOfTruck';

const ShayonaTripsListing = ({
 t,
 filterList,
 handleClearFilter
}) => {
    const {
        organizationId,
        shayonaTripsList,
        shayonaTripsLoading,
        reloadShayonaApisOrPagination,
        paginationPage,
        shayonaApisFilterMethod,
        goToPage,
        setGoToPage
      } = useContext(ShayonaCementsContext);
      const [selectedDetail, setSelectedDetail] = useState('');
      const [isTruckNumberModalEnable, setIsTruckNumberModalEnable] = useState(false);
      const [isprintSlipModalEnable, setIsprintSlipModalEnable] = useState(false);
      const [isCaptureWeightEnable, setIsCaptureWeightEnable] = useState(false);
      const [isEmptyWeightEnable, setIsEmptyWeightEnable] = useState(false);
      const [isExitPrintSlipModalEnable, setIsExitPrintSlipModalEnable] = useState(false);
      const [isShowArchivedEnable, setIsShowArchivedEnable] = useState(false);

      const handleEnableModal = (type,data) => {
        setSelectedDetail(data);
          if(type === 'edit'){
            setIsTruckNumberModalEnable(true);
          } else if(type === 'print'){
            setIsprintSlipModalEnable(true);
          } else if(type === 'weight'){
            setIsCaptureWeightEnable(true);
          } else if(type === 'lodingbags'){
            setIsEmptyWeightEnable(true);
          } else if(type === 'exit'){
            setIsExitPrintSlipModalEnable(true);
          }
      }; 

      const modalhandleClose = () => {
        setSelectedDetail('');
        setIsTruckNumberModalEnable(false);
        setIsprintSlipModalEnable(false);
        setIsCaptureWeightEnable(false);
        setIsEmptyWeightEnable(false);
        setIsExitPrintSlipModalEnable(false);
      };

      const [deleteTrip, { loading: deleteTripLoading }] = useMutation(DELETE_SHAYONA_TRIP, {
          onError: error => {
              console.log('err', error);
              setSelectedDetail('');
  
          },
          onCompleted: data => {
              setSelectedDetail('');
              Message.success(data && data.deleteTrip.message);
              reloadShayonaApisOrPagination('trip','');
          }
      });

    //   const [loadingBags, { loading: loadingBagsTripLoading }] = useMutation(LOADING_BAGS_OF_SHAYONA_TRIP_TRUCK, {
    //     onError: error => {
    //         console.log('err', error);
    //         setSelectedDetail('');

    //     },
    //     onCompleted: data => {
    //         setSelectedDetail('');
    //         Message.success(data && data.loadingBagsOfTripTruck.message);
    //         reloadShayonaApisOrPagination('trip','');
    //     }
    //   });

      const [exitPrintOfTrip, { loading: exitPrintLoading }] = useMutation(PRINT_EXIT_OF_SHAYONA_TRIP_TRUCK, {
        onError: error => {
            console.log('err', error);
            setSelectedDetail('');

        },
        onCompleted: data => {
            setSelectedDetail('');
            Message.success(data && data.printExitSlipTripTruck.message);
            reloadShayonaApisOrPagination('trip','');
        }
      });
  
      const handleSubmitOfTripActions = (tripDetails, type) => {
          setSelectedDetail(tripDetails?.trip_id);
          if(type === 'delete'){
            deleteTrip({
                variables:{
                    organization:organizationId,
                    tripId: tripDetails?.trip_id,
                    is_active: tripDetails.active? false: true
                }
            })
          } else if(type === 'exit'){
            exitPrintOfTrip({
                variables:{
                    organization:organizationId,
                    tripId: tripDetails?.trip_id
                }
            })
          }
        //   else if(type === 'lodingbags'){
        //     loadingBags({
        //         variables:{
        //             organization:organizationId,
        //             tripId: id
        //         }
        //     })
        //   }
        };

    let columns = [
        {
            label: 'Truck number',
            key: "number"
        },
        {
            label: "Detected on",
            key: "detected"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Empty weight",
            key: "empty_weight"
        },
        {
            label: "Allocated bags",
            key: "allocated"
        },
        {
            label: "Bags loaded",
            key: "loaded"
        },
        {
            label: "Gross weight",
            key: "gross"
        },
        {
            label: "Updated on",
            key: "updatedOn"
        },
        {
            label: t('action.label'),
            key: "action"
        } 
    ];

    const queryHandleDebounced = useMemo(
        () => debounce(val => shayonaApisFilterMethod('trip',{truckNumber:val}), 300)
    , []);

    useEffect(() => {
        return () => {
            queryHandleDebounced.cancel();
        }
    }, []);

  return (
        <Grid container>
            <Grid container>
                <Grid item xs="3">
                <SearchBox 
                      placeholderText="Search Truck Number"
                      handleSearch={(val) =>queryHandleDebounced(val)}
                      style={marginGenerator('mt-40')}
                    />
                </Grid>
                <Grid item xs="9">
                    <ChartContainer
                        title={null}
                        filterList={filterList}
                        clearFilter={handleClearFilter}
                        customMargin={marginGenerator('mt-0')}
                    /> 
                </Grid>    
            </Grid>
            <Grid container>
                <Grid item xs="9"/>   
                <Grid item xs="3">
                <Typography variant="subtitle2" color="textPrimary" >
                    <Checkbox
                        checked={isShowArchivedEnable}
                        value={isShowArchivedEnable}
                        onChange={(event) => {
                            shayonaApisFilterMethod('trip',{archived:event.target.checked});
                            setIsShowArchivedEnable(event.target.checked);
                        }}
                    />
                    Show Archived
                </Typography>
                </Grid>
            </Grid>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!shayonaTripsLoading ?
                      get(shayonaTripsList, 'getTripsList.data', []) && get(shayonaTripsList, 'getTripsList.data', []).length > 0 ?
                      get(shayonaTripsList, 'getTripsList.data', []).map((rowitem, rowIndex) => (
                                <ShayonaTripsItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    deleteTripLoading={deleteTripLoading}
                                    exitPrintLoading={exitPrintLoading}
                                    selecteIdForDelete={selectedDetail}
                                    handleSubmitOfTripActions={handleSubmitOfTripActions}
                                    handleEnableModal={handleEnableModal}
                                    organizationId={organizationId}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {shayonaTripsLoading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            { !shayonaTripsLoading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(shayonaTripsList, 'getTripsList.count', null)/10)} page,  {get(shayonaTripsList, 'getTripsList.count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={get(shayonaTripsList, 'getTripsList.count', null)? Math.ceil(get(shayonaTripsList, 'getTripsList.count', null)/10) : 0} 
                                page={paginationPage} 
                                onChange={(evt, value) => {
                                    reloadShayonaApisOrPagination('trip',value);
                                }} 
                            />
                        </Grid>
                        <Grid item xs={1}>
                                    <span>Go to page </span>
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={goToPage}
                                type="number"
                                style={{ minWidth: 100 }}
                                color={'secondary'}
                                onChange={evt => {
                                    let maxRecord = get(shayonaTripsList, 'getTripsList.count', null) ? parseInt(Math.ceil(get(shayonaTripsList, 'getTripsList.count', null)/10)) : 0;
                                    if (parseInt(evt.target.value) > maxRecord) {
                                        return null;
                                    }
                                    setGoToPage(evt.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => reloadShayonaApisOrPagination('trip', parseInt(goToPage))}
                                variant={'outlined'}
                                color="primary"
                                disabled={!goToPage}
                                style={{ marginLeft: 10 }}
                            >Go</Button>
                        </Grid>
                    </Grid>
                </>
            }
        </TableContainer>
        <EditTruckInTripListing
            t={t}
            open={isTruckNumberModalEnable}
            onClose={modalhandleClose}  
            data={selectedDetail}
        />
        <TripPrintSlipModal
            t={t}
            open={isprintSlipModalEnable}
            onClose={modalhandleClose}  
            data={selectedDetail}
        />
        <CaptureWeightOfTruck
            t={t}
            open={isCaptureWeightEnable}
            onClose={modalhandleClose}  
            data={selectedDetail}  
        />
        <EmptyWeightModal
            t={t}
            open={isEmptyWeightEnable}
            onClose={modalhandleClose}  
            data={selectedDetail}  
        />
        <ExitPrintSlipOfTruck
            t={t}
            open={isExitPrintSlipModalEnable}
            onClose={modalhandleClose}  
            data={selectedDetail}  
        />
    </Grid>
  )
}

export default ShayonaTripsListing