import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaBars,
  FaHome,
  FaUser,
  FaGlobeAfrica,
  FaRegLightbulb
} from 'react-icons/fa';
import { RiDatabaseFill } from 'react-icons/ri';
import { AiTwotoneFileExclamation, AiFillSetting } from 'react-icons/ai';
import { BsFillHddNetworkFill } from 'react-icons/bs';
import { AnimatePresence, motion } from 'framer-motion';
import SidebarMenu from './SidebarMenu';
import { useAuth } from '../../contexts/AuthContext';
import FabricNavbar from '../../components/Navbar/FabricNavbar';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    minHeight: '45vh'
  }
}));

let routesForSuperAdmin = [
  {
    path: '/fabricService',
    name: 'Dashboard',
    icon: <FaHome />
  },
  {
    path: '/fabricService/Services',
    name: 'Ports',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 490 490">
        <g>
          <path
            d="M0,0v490h490V0H0z M430.1,332.9h-87.5v50.9h-33.1v50.9H180.4v-50.6h-33.1v-51.3H59.9v-278h46.7v66.5h38.5V54.8h40.8v66.5
h38.5V54.8h40.8v66.5h38.5V54.8h40.8v66.5H383V54.8h46.7v278.1L430.1,332.9L430.1,332.9z"
            fill="white"
          />
        </g>
      </svg>
    )
  },
  {
    path: '/fabricService/Connections',
    name: 'Connections',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36 8L13 8C10 8 4 10 4 16C4 22 10 24 13 24H35C38 24 44 26 44 32C44 38 38 40 35 40H12"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 12C42.2091 12 44 10.2091 44 8C44 5.79086 42.2091 4 40 4C37.7909 4 36 5.79086 36 8C36 10.2091 37.7909 12 40 12Z"
          fill="white"
          stroke="black"
          stroke-width="4"
          stroke-linejoin="round"
        />
        <path
          d="M8 44C10.2091 44 12 42.2091 12 40C12 37.7909 10.2091 36 8 36C5.79086 36 4 37.7909 4 40C4 42.2091 5.79086 44 8 44Z"
          fill="white"
          stroke="black"
          stroke-width="4"
          stroke-linejoin="round"
        />
      </svg>
    )
  },
  {
    path: '/fabricService/FCR',
    name: 'Fabric Cloud Router',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="43.422px"
        height="43.422px"
        viewBox="0 0 43.422 43.422">
        <g>
          <path
            d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093
   c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002
   c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591
   c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024
   c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128
   v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2
   V21.749h2.148v10.897h12.881v2L40.686,32.102z"
            fill="white"
          />
        </g>
      </svg>
    )
  },
  {
    path: '/fabricService/Client',
    name: 'Client',
    icon: <FaUser />
  },
  {
    path: '/fabricService/regions',
    name: 'Regions',
    icon: <FaGlobeAfrica />
  },
  {
    path: '/fabricService/datacenters',
    name: 'Data Centers',
    icon: <RiDatabaseFill />
  },
  {
    path: '/fabricService/pods',
    name: 'Pods',
    icon: <BsFillHddNetworkFill />
  },
  {
    path: '/fabricService/insight',
    name: 'Insight',
    icon: <FaRegLightbulb />
  },
  {
    path: '/',
    name: 'Settings',
    icon: <AiFillSetting />,
    subRoutes: [
      {
        path: '/fabricService/serviceproviders',
        name: 'Service Providers'
      },
      {
        path: '/fabricService/underlayproviders',
        name: 'Underlay Providers'
      },
      {
        path: '/fabricService/premiumlink',
        name: 'Premium Link'
      }
    ]
  },
  {
    path: '/file-manager',
    name: 'Inq Documentation',
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: '/settings/profile',
        name: 'Inq Documentation'
      },
      {
        path: '/settings/2fa',
        name: 'Inq Fabric Documentation'
      }
    ]
  }
];

let routesForOthers = [
  {
    path: '/fabricService',
    name: 'Dashboard',
    icon: <FaHome />
  },
  {
    path: '/fabricService/Services',
    name: 'Ports',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 490 490">
        <g>
          <path
            d="M0,0v490h490V0H0z M430.1,332.9h-87.5v50.9h-33.1v50.9H180.4v-50.6h-33.1v-51.3H59.9v-278h46.7v66.5h38.5V54.8h40.8v66.5
h38.5V54.8h40.8v66.5h38.5V54.8h40.8v66.5H383V54.8h46.7v278.1L430.1,332.9L430.1,332.9z"
            fill="white"
          />
        </g>
      </svg>
    )
  },
  {
    path: '/fabricService/Connections',
    name: 'Connections',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36 8L13 8C10 8 4 10 4 16C4 22 10 24 13 24H35C38 24 44 26 44 32C44 38 38 40 35 40H12"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 12C42.2091 12 44 10.2091 44 8C44 5.79086 42.2091 4 40 4C37.7909 4 36 5.79086 36 8C36 10.2091 37.7909 12 40 12Z"
          fill="white"
          stroke="black"
          stroke-width="4"
          stroke-linejoin="round"
        />
        <path
          d="M8 44C10.2091 44 12 42.2091 12 40C12 37.7909 10.2091 36 8 36C5.79086 36 4 37.7909 4 40C4 42.2091 5.79086 44 8 44Z"
          fill="white"
          stroke="black"
          stroke-width="4"
          stroke-linejoin="round"
        />
      </svg>
    )
  },
  {
    path: '/fabricService/FCR',
    name: 'Fabric Cloud Router',
    icon: (
      <svg
        style={{ height: '20px', width: '20px' }}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="43.422px"
        height="43.422px"
        viewBox="0 0 43.422 43.422">
        <g>
          <path
            d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093
   c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002
   c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591
   c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024
   c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128
   v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2
   V21.749h2.148v10.897h12.881v2L40.686,32.102z"
            fill="white"
          />
        </g>
      </svg>
    )
  },
  {
    path: '/fabricService/Client',
    name: 'Client',
    icon: <FaUser />
  },
  {
    path: '/file-manager',
    name: 'Inq Documentation',
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: '/settings/profile',
        name: 'Inq Documentation'
      },
      {
        path: '/settings/2fa',
        name: 'Inq Fabric Documentation'
      }
    ]
  }
];

const TestDrawer = ({ children }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [routes, setRoutes] = useState([]);

  const { loggedInUserRoles } = useAuth();

  useEffect(() => {
    if (loggedInUserRoles[0] === 'superadmin') {
      setRoutes(routesForSuperAdmin);
    } else {
      setRoutes(routesForOthers);
    }
  }, [loggedInUserRoles]);

  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2
      }
    },
    show: {
      width: '140px',
      padding: '5px 15px',
      transition: {
        duration: 0.2
      }
    }
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5
      }
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <>
      <FabricNavbar />
      <div style={{ display: 'flex', marginTop: '150px' }}>
        <div>
          <motion.div
            animate={{
              width: isOpen ? '200px' : '45px',

              transition: {
                duration: 0.5,
                type: 'spring',
                damping: 10
              }
            }}
            className={`sidebar `}>
            <div className="top_section">
              <AnimatePresence>
                {isOpen && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo">
                    Inq Fabric
                  </motion.h1>
                )}
              </AnimatePresence>

              <div className="bars" style={{ cursor: 'pointer' }}>
                <FaBars onClick={toggle} />
              </div>
            </div>
            <section className="routes">
              {routes?.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    exact
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active">
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text">
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>
          </motion.div>
        </div>
        <Container
          className={classes.content}
          disableGutters={window.innerWidth > 1024}>
          {children}
        </Container>
      </div>
    </>
  );
};

export default TestDrawer;
