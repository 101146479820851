import { gql } from 'apollo-boost';

/*
  Get all services
 */
export const GET_SERVICES_LIST = gql`
  query {
    servicesAvailableForUser {
      id
      name
      description
      avgUptime
      avgCost
      avgInstallationTime
      serviceType {
        id
        name
      }
    }
  }
`;

/*
  Get all service types
 */
export const GET_SERVICES_TYPES = gql`
  query {
    serviceTypesAvailableForUser {
      id
      name
      services
    }
  }
`;
