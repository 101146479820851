import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import {
    GET_SUBSCRIPTION_DETAILS,
    GET_EDGE_AI_NODE_DETAILS
} from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { useHistory, useParams } from 'react-router';
import {
    Paths,
    SubscriptionPaths
} from '../../routes/routePaths';
import iconPin from '../../assets/icons/cpe_orange.png'
import iconCPU from '../../assets/icons/cpu.png'
import iconRAM from '../../assets/icons/ram.png'
import Statcard from '../../components/Statcard';
import GaugeChartWrapper from '../../components/Charts/GaugeChartWrapper';
import ChartContainer from '../../components/Charts/ChartContainer';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import GenericTable from '../../components/GenericTable';
import { SUBSCRIPTION_STATUS, USER_ROLES } from '../../utils/constants';
import Message from '../../components/Message';
import LinearProgress from '@material-ui/core/LinearProgress';
import CachedIcon from '@material-ui/icons/Cached';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NodeDetailsModal from './components/NodeManagementPanel/NodeDetailsModal';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
const containerColumn = [
    {
        label: "S No.",
        key: "s_no"
    },
    {
        label: "Node Id",
        key: "nodeId"
    },
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Id",
        key: "id"
    },
    {
        label: "Camera's",
        key: "camera",
        style: {
            textAlign: "center"
        }
    },
    {
        label: "Restart",
        key: "restart",
        style: {
            textAlign: "center"
        }
    }
];
const processColumn = [
    {
        label: "Name",
        key: "name"
    },
    {
        label: "PID",
        key: "pid"
    },
    {
        label: "STATUS",
        key: "status"
    },
    {
        label: "RES",
        key: "res_memory",
    },
    {
        label: "CPU%",
        key: "cpu_percent"
    },
    {
        label: "MEM%",
        key: "mem_percent"
    },
    {
        label: "TIME+",
        key: "time_duretion"
    }
];

const customStyle = { border: "3px solid #efefef", borderRadius: 6, padding: 12, marginTop: 35 };
const useStyles = makeStyles(theme => ({
    processTable: {
        backgroundColor: "#000",
        padding: 5
    },
    processTableRow: { 
        color: "green", 
        padding: 5, 
        borderBottom: "none" 
    }
}));

const EdgeAIManageNode = ({ t }) => {

    const classes = useStyles();
    const role = localStorage.getItem('role');
    const { id, nodeId } = useParams();
    const history = useHistory();
    let [breadcrumbList, setBreadcrumbList] = useState([
        { label: 'home.label', link: Paths.Dashboard },
        { label: 'subscriptions.label', link: Paths.Subscriptions },
        {
            label: 'activeSubscriptionDetails.label',
            link: SubscriptionPaths.ActiveSubscriptionDetails
        },
        {
            label: 'nodeManagment.label',
            link: SubscriptionPaths.manageEdgeAINode
        }
    ]);
    const [nodeData, setNodeData] = useState({ ipAddress: "", noOfCPU: 0, noOfGPU: 0, ram: 0, cpuUsage: 0, gpuUsage: 0, runningContainers: [], ramUsage: 0, cameras: [], processes: [] });
    const [cameraListModelState, setCameraListModelState] = useState(false);

    useEffect(() => {
        if (!role.includes(USER_ROLES.IP_SUPERADMIN)) {
            history.goBack();
        }
    }, []);

    /* Queries */
    const { loading, data: subscriptionData, error } = useQuery(
        GET_SUBSCRIPTION_DETAILS,
        {
            variables: {
                id
            },
            onCompleted: (res) => {
                if (res.getSubscriptionById) {
                    return getEdgeAINodeDetails({
                        variables: {
                            id: nodeId,
                            organization: res.getSubscriptionById.organizationId
                        }
                    });
                }
            }
        }
    );

    const [getEdgeAINodeDetails, { loading: nodeDataLoading }] = useLazyQuery(GET_EDGE_AI_NODE_DETAILS, {
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            if (res.getEdgeAINodeDetails && res.getEdgeAINodeDetails.response) {
                const nodeDetails = JSON.parse(res.getEdgeAINodeDetails.response);
                console.log("nodeDetails: ", nodeDetails);
                const cpuUsgSum = nodeDetails.cpu.reduce((a, b) => a + b, 0);
                const cpuUsage = (cpuUsgSum / nodeDetails.cpu.length) || 0;
                const gpuUsgSum = nodeDetails.gpu.reduce((a, b) => a + b, 0);
                const gpuUsage = (gpuUsgSum / nodeDetails.gpu.length) || 0;
                const runningContainers = nodeDetails.container.map((itm, idx) => {
                    return {
                        s_no: idx + 1,
                        nodeId: nodeDetails.node_id,
                        id: itm.id,
                        name: itm.name,
                        camera: <IconButton size="small" color="primary" onClick={evt => handleContainerClick(itm, 'camera')}><VisibilityIcon /></IconButton>,
                        restart: <IconButton size="small" color="primary" onClick={evt => handleContainerClick(itm, "restart")}><CachedIcon /></IconButton>
                    };
                });
                const processes = nodeDetails.process.map((itm, idx) => {
                    return {
                        pid: itm.pid,
                        name: itm.name,
                        status: itm.status,
                        res_memory: 0,
                        cpu_percent: itm.cpu_percent ? itm.cpu_percent : 0,
                        time_duretion: (itm.cpu_times && itm.cpu_times.length > 0) ? itm.cpu_times[0] : 0,
                        mem_percent: itm.memory_percent ? itm.memory_percent.toFixed(2) : 0
                    };
                });
                setNodeData({ ...nodeData, cpuUsage: parseInt(cpuUsage), noOfCPU: nodeDetails.cpu.length, noOfGPU: nodeDetails.gpu.length, ram: bytesToSize(nodeDetails.memory && nodeDetails.memory.total ? nodeDetails.memory.total : 0), runningContainers: runningContainers, ipAddress: res.getEdgeAINodeDetails.ipAddress, gpuUsage: parseInt(gpuUsage), ramUsage: nodeDetails.memory && nodeDetails.memory.percent ? parseInt(nodeDetails.memory.percent) : 0, cameras: res.getEdgeAINodeDetails.cameras, processes });
            }
        },
        onError: (e) => {
            Message.error(e);
        }
    }
    );

    const statList = [
        {
            label: t('nodeIp.label'),
            value: nodeData.ipAddress,
            icon: iconPin
        },
        {
            label: t('noOfCPU.label'),
            value: nodeData.noOfCPU,
            icon: iconCPU
        },
        {
            label: t('noOfGPU.label'),
            value: nodeData.noOfGPU,
            icon: iconCPU
        },
        {
            label: t('ramInGM.label'),
            value: nodeData.ram,
            icon: iconRAM
        }
    ];

    let HeaderSubText = '';
    if (!loading && !error) {
        if (subscriptionData.getSubscriptionById && subscriptionData.getSubscriptionById.isDelete) {
            Message.error('Subscription termineted!');
            history.goBack();
        }
        const activeSites = (subscriptionData.getSubscriptionById &&
            subscriptionData.getSubscriptionById.sites && subscriptionData.getSubscriptionById.sites.length && subscriptionData.getSubscriptionById.sites.filter(item => item.status === SUBSCRIPTION_STATUS.ACTIVE)) || [];
        HeaderSubText = `${subscriptionData.getSubscriptionById.noOfActiveSites ? subscriptionData.getSubscriptionById.noOfActiveSites : activeSites.length} ${t('activeSites.label')} • ${subscriptionData.getSubscriptionById.noOfCPEs || 0} ${t('cameras.label')} • ${subscriptionData.getSubscriptionById.avgUptime || 100}% ${t('uptime.label')}`;
    }

    const handleContainerClick = (item, type) => {
        if (type === "restart") {

        } else {
            setCameraListModelState(true);
        }
    }

    return (
        <Layout breadcrumbList={breadcrumbList}>
            {!loading && !error ? (
                <>
                    <Header
                        title={subscriptionData.getSubscriptionById && subscriptionData.getSubscriptionById.service ? (subscriptionData.getSubscriptionById.name) ? subscriptionData.getSubscriptionById.service.name + " - " + subscriptionData.getSubscriptionById.name : subscriptionData.getSubscriptionById.service.name : "-"}
                        serviceType={subscriptionData.getSubscriptionById && subscriptionData.getSubscriptionById.service && subscriptionData.getSubscriptionById.service.name}
                        divider={true}
                        subText={HeaderSubText}
                    />
                    {nodeDataLoading ?
                        <div style={{ textAlign: "center", marginTop: 32 }}><CircularProgress /></div>
                        :
                        <>
                            <Grid container style={{ marginTop: 34 }} >
                                <Typography variant="h6" color="primary" >{t("nodeManagment.label")}</Typography>
                            </Grid>
                            <Grid
                                container
                                justify={'space-between'}
                                style={{ marginTop: 34 }}>
                                {statList.map((item, index) => (
                                    <Statcard
                                        key={index}
                                        statLabel={item.label}
                                        icon={item.icon}
                                        statValue={item.value}
                                        type={item.type ? item.type : ""}
                                        handleClick={evt => {
                                            return null;
                                        }}
                                    />
                                ))}
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} style={{ paddingRight: 40 }}>
                                    <ChartContainer
                                        title={t('cpuCons.label')}
                                        customMargin={customStyle}
                                        childStyleOveride={{ marginTop: 10 }}
                                    >
                                        <GaugeChartWrapper value={nodeData.cpuUsage ? nodeData.cpuUsage : 0} />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={4} style={{ paddingRight: 40 }}>
                                    <ChartContainer
                                        title={t('gpuCons.label')}
                                        customMargin={customStyle}
                                        childStyleOveride={{ marginTop: 10 }}
                                    >
                                        <GaugeChartWrapper value={nodeData.gpuUsage ? nodeData.gpuUsage : 0} />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={4} style={{ paddingRight: 40 }}>
                                    <ChartContainer
                                        title={t('ramCons.label')}
                                        customMargin={customStyle}
                                        childStyleOveride={{ marginTop: 10 }}
                                    >
                                        <GaugeChartWrapper value={nodeData.ramUsage ? nodeData.ramUsage : 0} />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChartContainer
                                        title={t('runningContain.label')}
                                        customMargin={{ marginTop: 32 }}
                                    >
                                        <GenericTable columns={containerColumn} list={nodeData.runningContainers ? nodeData.runningContainers : []} />
                                    </ChartContainer>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: 35 }}>
                                    <ChartContainer
                                        title={t('processes.label')}
                                        customMargin={{ marginTop: 32 }}
                                        childStyleOveride={{ marginTop: 10 }}
                                    >
                                        <div className={classes.processTable}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {processColumn.map((item, index) => (
                                                                <TableCell 
                                                                key={index} 
                                                                className={classes.processTableRow} >
                                                                    {item.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nodeData.processes && nodeData.processes.map((rowItem, rowIndex) => (
                                                        <TableRow
                                                            style={{ cursor: 'pointer' }}
                                                            key={rowIndex}
                                                        >
                                                            {processColumn.map((colItem, colIndex) => {
                                                                return (
                                                                    <TableCell 
                                                                        key={colIndex} 
                                                                        className={classes.processTableRow}
                                                                    >
                                                                        {rowItem[colItem.key]}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </ChartContainer>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {<NodeDetailsModal t={t} cameras={nodeData.cameras} open={cameraListModelState} handleClose={() => { setCameraListModelState(false) }} />}
                </>
            ) : (
                <LinearProgress
                    color="primary"
                    style={{
                        top: '98px',
                        left: '0px',
                        position: 'absolute',
                        width: '100%'
                    }}
                />
            )
            }

        </Layout>
    );
}
    ;
export default withTranslation()(EdgeAIManageNode);
