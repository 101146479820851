import React from 'react';
import Grid from '@material-ui/core/Grid';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { Input } from '@material-ui/core';
import { checkValidEmail } from '../../../../../utils';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%"
    }
});

const useStyles = makeStyles(theme => ({
    testCon: { marginRight: '6px', marginLeft: '6px' },
    chartTitle: { marginTop: '10px' },
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 15,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick}
            disabled={loading || disabledValue}
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const OcrSendEmailModal = ({
    t,
    open,
    onClose,
    handleSubmit,
    loading
}) => {

    const classes = useStyles();
    const [emailState, setEmailState] = React.useState({ emailId: "", message: "" });

    React.useEffect(() => {
        setEmailState({ emailId: "", message: "" });
    }, open);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={evt => {
                setEmailState({ emailId: "", message: "" });
                onClose()
            }}>
                Send Email
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContain}>
                <Grid container>
                    <Grid item xs={12}>
                        <Input 
                            style={{width: "100%", marginBottom: 24}}
                            label={t("email.label")}
                            value={emailState.emailId}
                            placeholder={t("email.label")}
                            onChange={evt => setEmailState({ ...emailState, emailId: evt.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            style={{width: "100%"}} 
                            label={t("message.label")}
                            multiline={true}
                            rows={2}
                            value={emailState.message}
                            placeholder={t("message.label")}
                            onChange={evt => setEmailState({ ...emailState, message: evt.target.value })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={evt => {
                            setEmailState({ emailId: "", message: "" });
                            onClose()
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        loading={loading}
                        onClick={evt => {
                            if(!emailState.emailId){
                                Message.error("Please enter Email address!");
                                return null;
                            }
                            if(!checkValidEmail(emailState.emailId)){
                                Message.error("Please enter Valid Email address!");
                                return null;
                            }
                            handleSubmit(emailState);
                        }}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default OcrSendEmailModal;