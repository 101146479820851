import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import InternalLayout from '../../../components/InternalLayout';
import Header from '../../../components/Header';
import Message from '../../../components/Message';
import CSVReader from 'react-csv-reader';
import { InternalUser } from '../../../routes/routePaths';
import { IMPORT_BIRTHDAY_TO_DB } from '../../commonMutations'
import Container from '@material-ui/core/Container';
import { Button, Input, CircularProgress } from '@material-ui/core';
import DateTimeSelector from '../../../components/DateTimeSelector';
import { ADD_BIRTHDAY_EVENT, ADD_EVENTS } from '../mutations'
import { REQUIRED_ERROR } from '../../../utils/constants';

const breadcrumbList = [
    { label: 'home.label', link: InternalUser.Dashboard },
    { label: 'uploadFile.label', link: InternalUser.Events }
];
const useStyles = makeStyles(theme => ({
    serviceArea: {
        marginTop: theme.spacing(4),
        maxWidth: 800
    },
}));

const getInputField = (filter) => {
    if (filter.type && filter.type === 'text') {
        return (
            <Input
                type={filter.type}
                value={filter.val}
                placeholder={filter.placeholder}
                style={filter.styleOverrides}
                color={'secondary'}
                onChange={filter.handleChange}
            />
        )
    } else {
        return (
            <DateTimeSelector
                label={filter.placeholder ? filter.placeholder : ""}
                dateFormat={"yyyy-MM-dd"}
                value={filter.val}
                handleChange={filter.handleChange}
                styleOverrides={filter.styleOverrides}
                inputStyle={{ minWidth: 220, padding: 10 }}
                disableFuture={filter.disableFuture}
            />
        );
    }
}

const CustomInput = ({ filter }) => {

    return (
        <>
            {getInputField(filter)}
        </>
    )
}
const initFormState = { firstName: "", lastName: "", employeedetail: "", birthday: new Date() }
const initialEventState = { eventName: "", startFrom: null, expiryDate: null }

const Events = ({ t, navigation }) => {
    const classes = useStyles();
    const [formData, setFormData] = React.useState({ ...initFormState })
    const [eventFormData, setEventFormData] = React.useState({ ...initialEventState })
    const [bdyButtonLoading, setBdyButtonLoading] = React.useState(false)
    const [eventButtonLoading, setEventButtonLoading] = React.useState(false)

    const [importBirthday] = useMutation(IMPORT_BIRTHDAY_TO_DB, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Employee Birthday File Imported successfully.');
            document.querySelector('.csv-input').value = '';
        }
    });

    const handleForce = (data, fileInfo) => {
        let finalData = JSON.stringify(data);
        return importBirthday({
            variables: {
                data: finalData
            }
        });
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
    };

    const inputArray = [
        {
            type: 'text',
            name: 'employeedetail',
            val: formData.employeedetail,
            placeholder: "Enter Employee detail",
            handleChange: (evt) => {
                setFormData({ ...formData, employeedetail: evt.target.value });
            },
            styleOverrides: { width: '90%' }
        },
        {
            type: 'text',
            name: 'firstName',
            val: formData.firstName,
            placeholder: "Enter First Name",
            handleChange: (evt) => {
                setFormData({ ...formData, firstName: evt.target.value });
            },
            styleOverrides: { width: '90%' }
        },
        {
            type: 'text',
            name: 'lastName',
            val: formData.lastName,
            placeholder: "Enter Last Name",
            handleChange: (evt) => {
                setFormData({ ...formData, lastName: evt.target.value });
            },
            styleOverrides: { width: '90%' }
        },
        {
            type: 'input',
            name: 'birthday',
            val: formData.birthday,
            placeholder: "Enter DOB",
            handleChange: (newDate) => {
                setFormData({ ...formData, birthday: newDate });
            },
            styleOverrides: { width: '90%' },
            disableFuture: true
        }
    ]

    const eventArray = [
        {
            type: 'text',
            name: 'eventName',
            val: eventFormData.eventName,
            placeholder: "Enter Event Name",
            handleChange: (evt) => {
                setEventFormData({ ...eventFormData, eventName: evt.target.value });
            },
            styleOverrides: { width: '90%' }
        },
        {
            type: 'input',
            name: 'startFrom',
            val: eventFormData.startFrom,
            placeholder: "Event Start Date",
            handleChange: (newDate) => {
                setEventFormData({ ...eventFormData, startFrom: newDate });
            },
            styleOverrides: { width: '90%' },
            disableFuture: false
        },
        {
            type: 'input',
            name: 'expiryDate',
            val: eventFormData.expiryDate,
            placeholder: "Event Expiry Date",
            handleChange: (newDate) => {
                setEventFormData({ ...eventFormData, expiryDate: newDate });
            },
            styleOverrides: { width: '90%' },
            disableFuture: false
        }
    ]

    const [addBirthday] = useMutation(ADD_BIRTHDAY_EVENT, {
        onError: error => {
            setBdyButtonLoading(false)
            Message.error('Something went wrong')
            console.log('Error', error);
        },
        onCompleted: data => {
            setBdyButtonLoading(false)
            setFormData({ ...initFormState })
            Message.success('Birthday Added Successfully');
        }
    });

    const handleSubmit = () => {
        if (formData.firstName === "" || formData.lastName === '' || formData.employeedetail === '' || formData.birthday === '') Message.error(REQUIRED_ERROR)
        else {
            setBdyButtonLoading(true)
            addBirthday({
                variables: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    employeedetail: formData.employeedetail,
                    birthday: formData.birthday
                }
            })
        }
    }

    const [addEvents] = useMutation(ADD_EVENTS, {
        onError: error => {
            setEventButtonLoading(false)
            Message.error('Something went wrong')
            console.log("Error:-", error)
        },
        onCompleted: data => {
            setEventButtonLoading(false)
            setEventFormData({ ...initialEventState })
            Message.success('Event Added Successfully')
        }
    })

    const handleSubmitEvent = () => {
        if (eventFormData.eventName === '' || eventFormData.startFrom === null || eventFormData.expiryDate === null) Message.error(REQUIRED_ERROR)
        else {
            setEventButtonLoading(true)
            addEvents({
                variables: {
                    name: eventFormData.eventName,
                    date: eventFormData.startFrom,
                    expiryDate: eventFormData.expiryDate
                }
            })
        }
    }

    return (
        <InternalLayout breadcrumbList={breadcrumbList}>
            <Header
                title={t('events.label')}
                subText={t('eventsDescription.label')}
            />
            <Container className={classes.serviceArea}>
                <main>
                    <Typography variant="h6" color="textPrimary" component="h6">
                        {t('uploadFile.label')}
                    </Typography>
                    <div className="filecontainer">
                        <CSVReader
                            cssClass="csv-input"
                            label="Select CSV to import Employee Birthdays"
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                        />
                    </div>
                </main>
                <main>
                    <Grid container>
                        <Grid item xs={12} style={{ padding: '25px 0px' }}>
                            <Typography variant="h6" color="textPrimary" component="h6">
                                {t('bdayDetails.label')}
                            </Typography>
                        </Grid>
                        <Grid item container spacing={3}>
                            {inputArray && inputArray.length ?
                                inputArray.map(filter => (
                                    <Grid item xs={6}>
                                        <CustomInput key={filter.name} filter={filter} />
                                    </Grid>
                                )) : null}
                        </Grid>
                        <Button variant="contained" onClick={handleSubmit} color="primary" style={{ marginTop: 10 }}>
                            {bdyButtonLoading ? <CircularProgress size={22} color="secondary" /> : 'Add Birthday'}
                        </Button>
                    </Grid>
                </main>
                <main style={{ marginTop: '35px' }}>
                    <Grid container>
                        <Grid item xs={12} style={{ padding: '25px 0px' }}>
                            <Typography variant="h6" color="textPrimary" component="h6">
                                {t('eventDetails.label')}
                            </Typography>
                        </Grid>
                        <Grid item container spacing={3}>
                            {eventArray && eventArray.length ?
                                eventArray.map(filter => (
                                    <Grid item xs={6}>
                                        <CustomInput key={filter.name} filter={filter} />
                                    </Grid>
                                )) : null}
                        </Grid>
                        <Button variant="contained" onClick={handleSubmitEvent} color="primary" style={{ marginTop: 10 }}>
                            {eventButtonLoading ? <CircularProgress size={22} color="secondary" /> : 'Add Event'}
                        </Button>
                    </Grid>
                </main>
            </Container>
        </InternalLayout >
    );
};
export default withTranslation()(Events);
