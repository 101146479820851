import moment from 'moment';
import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const createConnection = async payload => {
  try {
    const response = await axios.post('connection', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const checkVlanAvailability = async payload => {
  try {
    const response = await axios.post('connection/check/v_lan', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getAvailableVlansByPodId = async podId => {
  try {
    const response = await axios.get(`pod/${podId}`, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const viewConnectionById = async id => {
  try {
    const response = await axios.get(`connection/portId/${id}`, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getChartDataForConnection = function(
  dateFilter,
  modeldata,
  setLoader,
  setChartData
) {
  let query = '';
  var date = new Date();

  if (dateFilter === 15 || dateFilter === 60) {
    date.setDate(date.getDate());
    query = date;
  }

  const testURL = `https://54dc60343a3b46d3a26840aa77dc46d3.europe-west2.gcp.elastic-cloud.com:9243/fabric-snmp-${moment(
    query
  ).format('YYYY')}.${moment(query).format('MM')}/_search`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'ApiKey QnVuWnRZSUJ0am1vUGpUUUxSTDQ6cGZabnhkYWZTR3lISWFxVzBRV1RPdw=='
    },

    body: JSON.stringify({
      size: dateFilter,
      query: {
        bool: {
          filter: [
            {
              range: {
                '@timestamp': {
                  gte: moment
                    .utc(date)
                    .subtract(dateFilter, 'm')
                    .format(),
                  lt: moment.utc(date).format()
                }
              }
            }
          ],
          must: [
            {
              match_phrase: {
                'interfaces.ifDescr': `vx-${String(
                  modeldata[0]?.display_connection_id
                ).slice(0, 6)}`
              }
            }
          ]
        }
      },
      sort: [{ '@timestamp': 'asc' }]
    })
  };
  setLoader(true);
  fetch(testURL, requestOptions)
    .then(response => response?.json())
    .then(async data => {
      (await data?.hits?.hits?.length) > 0
        ? setChartData(data?.hits?.hits)
        : setChartData([]);
      setLoader(false);
    })
    .catch(function(error) {
      console.log({ error });
      setLoader(false);
    });
};

export {
  createConnection,
  checkVlanAvailability,
  getAvailableVlansByPodId,
  viewConnectionById,
  getChartDataForConnection
};
