import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Canvas from '../../../../components/Canvas';
import { convertLocalToUTCDate, dateFormatterDDMMMYYYYHHSS } from '../../../../utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_EVENT_VIDEO_IMAGES } from '../../queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { marginGenerator } from '../../../../theme/utils';
import Slider from '@material-ui/core/Slider';

function roundOF2(num){
    return Math.round(num * 100) / 100;
}
const objectColor = {'bicycle': "#E52B50", 'person': "green", 'truck': "blue", 'motorbike': "#FDEE00", 'car': "red", 'bus': "#FF7E00", 'cell phone': "#3DDC84"};

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    controls: {
        textAlign: "center",
        marginTop: 10,
        display:"inline-block"
    },
    input: {
        padding : "9px 22px",
        marginLeft: "30px"
    },
    imgSlider: {
        width: "95%",
        marginLeft: 20
    },
    sliderCard: {
        width: "100%"
    },
    sliderGrid: {
        marginBottom: 10
    },
    searcBtn: {
        paddingBottom: 9,
        paddingTop: 10
    },
    img: {
        width: "100%",
        height: "100%"
    }
}));

export default function EventsVideoPlayBackModal({open, handleClose, data}) {

    const classes = useStyles();

    const [activeImage, setActiveImage] = useState("");
    const [localTimestamp, setLocalTimestamp] = useState("");
    const [timer, setTimer] = useState(null);
    const [canvasProp, setCanvasProp] = useState({height: 500, width: 1200});
    const [firstDrawStatus, setFirstDrawStatus] = useState(true);
    const [loading, setLoading] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if(data && data.camera.camera_id){
            let cameraId = data.camera.camera_id;
            setImageList([]);
            setActiveImage("");
            setLocalTimestamp("");
            setLoading(true);
            let startTime = new Date(data.created_at);
            let endTime = new Date(data.created_at);
            // startTime.setMinutes(startTime.getMinutes() - 15);
            endTime.setMinutes(endTime.getMinutes() + 15);
            startTime = new Date(convertLocalToUTCDate(startTime)).getTime()/1000;
            endTime = new Date(convertLocalToUTCDate(endTime)).getTime()/1000;
            getEventVideoImages({variables: { cameraId: cameraId.toString(), startTime, endTime, lastEvent: false }});
        }
    }, []);

    const getPerObjectCount = (objects) => {
        let objCounts = {}; 
        if(objects && objects.length){
            let objArr = objects.map(objVal => {
                return objVal.name;
            });
            objArr.forEach(function (x) { objCounts[x] = (objCounts[x] || 0) + 1; });
        }
        return objCounts;
    }

    const [getEventVideoImages] = useLazyQuery(GET_EVENT_VIDEO_IMAGES, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            setLoading(false);
            console.log("res.getEventVideoImages: ", res.getEventVideoImages);
            if(res.getEventVideoImages && res.getEventVideoImages.length > 0){
                let viewersEvents = res.getEventVideoImages;
                // .map(itm => {
                //     if(itm.objects){
                //         let filteredObjects = itm.objects.filter(function (e) {
                //             if(e.confidence){
                //                 let with2Decimals = e.confidence.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
                //                 if(thresholdCond){
                //                     if(evaluate(parseFloat(with2Decimals), parseFloat(threshold), thresholdCond)){
                //                         return e;
                //                     }
                //                 }else{
                //                     if(parseFloat(with2Decimals) == threshold){
                //                         return e;
                //                     }
                //                 }
                //             }
                //         });
                //         itm.objects = filteredObjects;
                //     }
                //     return itm;
                // });
                setFirstDrawStatus(true);
                setImageList(viewersEvents);
                let firstActImgData = {...viewersEvents[0]};
                firstActImgData.objectCount = getPerObjectCount(viewersEvents[0]?.objects ? viewersEvents[0].objects : null);
                setActiveImage(firstActImgData);
                if(viewersEvents[0].time){
                    let time = viewersEvents[0].time;
                    time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                    setLocalTimestamp(time);
                }
                // setImagesUrlList(viewersEvents.map(itm => itm.url));
                // setRecordNotFound(false);
            }else{
                setImageList([]);
                setActiveImage("");
                setLocalTimestamp("");
            }
        },
        onError: error => {
            setLoading(false);
        }
    });

    const handleChange = (event, newValue) => {
        if(timer){
            handlePause();
        }
        setFirstDrawStatus(true);
        setActiveIndex(newValue);
        if(imageList.length > newValue && imageList[newValue]){
            let firstActImgData = {...imageList[newValue]};
            setActiveImage(firstActImgData);
            if(imageList[newValue].time){
                let time = imageList[newValue].time;
                time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                setLocalTimestamp(time);
            }
        }
    };

    const handleAutoPlay = () => {
        let newIndex = activeIndex;
        let tempTimer = setInterval(() => {
            newIndex++;
            if(imageList.length > newIndex  && imageList[newIndex]){
                setActiveIndex(activeIndex => {
                    return activeIndex + 1;
                });
                setFirstDrawStatus(true);
                let firstActImgData = {...imageList[newIndex]};
                setActiveImage(firstActImgData);
                if(imageList[newIndex].time){
                    let time = imageList[newIndex].time;
                    time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                    setLocalTimestamp(time);
                }
            }else{
                setTimer(null);
                setActiveIndex(0);
                setFirstDrawStatus(true);
                if(imageList.length){
                    setActiveImage(imageList[0]);
                    if(imageList[0].time){
                        let time = imageList[0].time;
                        time = dateFormatterDDMMMYYYYHHSS(new Date(time));
                        setLocalTimestamp(time);
                    }
                }
                clearInterval(tempTimer);
            }
        }, 3000);
        setTimer(tempTimer);
    }

    const handlePause = () => {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    }

    const draw = (context, img) => {
        if(!img){
            return null;
        }
        let imageObj1 = new Image();
        imageObj1.src = img.url;
        imageObj1.onload = (e) => {
            context.drawImage(imageObj1, 0, 0, 1100, 500);
            context.beginPath();
            img.objects.map(r => {
                if(r.relative_coordinates){
                    let x1 = roundOF2(parseFloat(r.relative_coordinates.centre_x)*parseInt(1080));
                    let y1 = roundOF2(parseFloat(r.relative_coordinates.centre_y)*parseInt(600));
                    let x2 = roundOF2(parseFloat(r.relative_coordinates.width)*parseInt(1080));
                    let y2 = roundOF2(parseFloat(r.relative_coordinates.height)*parseInt(600));
                    context.lineWidth = "2";
                    context.strokeStyle = (objectColor[r.name]) ? objectColor[r.name] : "red";
                    context.strokeRect(x1, y1, x2, y2);
                    context.font='13px Arial';
                    context.fillStyle = (objectColor[r.name]) ? objectColor[r.name] : "red";
                    context.fillText(r.name, x1, y1);
                }
            });
        }
        setFirstDrawStatus(false);
    };

    return (
      <Dialog 
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
            <div style={{position: "relative"}}>
                <IconButton style={{position: "absolute", right: 0}} onClick={handleClose}>
                    <CloseIcon color="secondary" />
                </IconButton>
            </div>
            {loading ? 
            <div style={{textAlign: "center", paddingTop: 30, paddingBottom: 30}}>
                <CircularProgress color="primary" />
            </div> :
                <div className={classes.root}>
                {imageList && imageList.length > 0 ?
                <>
                    <Grid container>
                        <Typography variant="h6" align="center" color="primary" style={{...marginGenerator("mt-6"), ...marginGenerator("mb-12"), width: "100%"}}>{ localTimestamp }</Typography>
                        <Canvas 
                            draw={(ctx) => {
                                if(firstDrawStatus){
                                    draw(ctx, activeImage);
                                }
                            }} 
                            height={canvasProp.height} 
                            width={canvasProp.width} 
                        />
                    </Grid>
                    <Grid container className={classes.controls}>
                        <IconButton aria-label="play/pause">
                            { timer ? <PauseIcon className={classes.playIcon} onClick={handlePause} /> : <PlayArrowIcon className={classes.playIcon} onClick={handleAutoPlay} /> }
                        </IconButton>
                    </Grid>
                    <Grid className={classes.sliderGrid}>
                        <Slider
                            aria-label="custom thumb label"
                            value={activeIndex}
                            defaultValue={0}
                            max={imageList.length}
                            className={classes.imgSlider}
                            onChange={handleChange}
                        />
                    </Grid>
                </> : 
                    <Grid container>
                        <Typography variant="h6" color="secondary" style={{...marginGenerator("mt-10"), ...marginGenerator("mb-20"), width: "100%"}}>Record not found!</Typography>
                    </Grid>
                }
                </div>
            }
        </DialogContent>
      </Dialog>
    );
}