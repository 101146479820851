import { gql } from 'apollo-boost';
/*
  Get Department List
 */
export const GET_DEPARTMENT_LIST = gql`
  query Department($subscriptionId: String) {
    getAllDepartments(subscriptionId: $subscriptionId) {
      sections
      name
      createdByName
      createdAt
      id
    }
  }
`;

export const GET_SECTION_LIST = gql`
  query Sections($subscriptionId: String) {
    getAllSections(subscriptionId: $subscriptionId) {
      success
      data
    }
  }
`;

/*
Get Meeting List
*/
export const GET_MEETING_LIST = gql`
  query Meeting($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllMeetings(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      title
      venue
      facilitator
      attendees
      description
      notes
      sign
      dateTime
      createdByName
      createdAt
    }
  }
`;


/*
Get Server Room List
*/
export const GET_SERVER_ROOM_LIST = gql`
  query ServerRoom($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllServerRooms(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      fullName
      reportedDateTime
      designation
      userSign
      serverRoom
      allIdentifiedIssuesCorrected
      allIdentifiedIssuesCorrectedComments
      correctionsOfPrevIssue
      correctionsOfPrevIssueComments
      allAreaCleaned
      allAreaCleanedComments
      allWalkabouts
      allWalkaboutsComments
      floorsLiquidsFree
      floorsLiquidsFreeComments
      flammableMaterialsStored
      flammableMaterialsStoredComments
      otherHousekeepingIssue
      otherHousekeepingIssueComments
      temperatureHumidityStandard
      temperatureHumidityStandardComments
      airflowCondition
      airflowConditionComments
      floorRaised
      floorRaisedComments
      automatedMonitoringSystem
      automatedMonitoringSystemComments
      emsAlertSent
      emsAlertSentComments
      powerUps
      powerUpsComments
      electricalCircuitBreakers
      electricalCircuitBreakersComments
      voltageAndCurrentCapacities
      voltageAndCurrentCapacitiesComments
      enoughPowerOutlets
      enoughPowerOutletsComments
      fireDetectionInstalled
      fireDetectionInstalledComments
      fireExtinguishEquip
      fireExtinguishEquipComments
      firePanelInstalled
      firePanelInstalledComments
      accessControlInstalled
      accessControlInstalledComments
      securityAlarmInstalled
      securityAlarmInstalledComments
      videoCamera
      videoCameraComments
      upsDate
      upsComments
      airConditionerDate
      airConditionerComments
      emsSensorsIndicatorsDate
      emsSensorsIndicatorsComments
      humidityTemperatureSensorsDate
      humidityTemperatureSensorsComments
      fireExtinguishersDate
      fireExtinguishersComments
      fireDetectionSensorsDate
      fireDetectionSensorsComments
      imHeadId
      imHeadName
      imHeadSign
      imHeadDate
      imSectionHeadId
      imSectionHeadName
      imSectionHeadSign
      imSectionHeadDate
    }
  }
`;



export const GET_BRIEF_LIST = gql`
  query Brifing($subscriptionId: String, $section: String ,$status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllBriefs(subscriptionId: $subscriptionId, section:$section,search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      title
      section
      attendees
      description
      notes
      sign
      issueDateTime
      createdByName
      createdAt
    }
  }
`;

export const GET_USER_REQUESTS = gql`
  query UserRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllUserRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      subscriptionId
      userFullName
      userDepartment {
        id
        name
      }
      userDesignation
      userSection {
        id
        name
      }
      userCellular
      employeeNumber
      accountType
      recommendationSign
      authorisationSign
      employeeType
      expiryDate
      requiredAccess
      recommendation
      authorisation
      createdAt
      createdByName
      createdById
    }
  }
`;

export const GET_LEAVE_REQUESTS = gql`
  query UserLeaveRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllLeaveRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_LABOUR_REQUESTS = gql`
  query LabourRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllLabourRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_TRANSPORT_REQUESTS = gql`
  query TransportRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllTransportRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_BACKUP_CONTROL_REQUESTS = gql`
  query BackupControlRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllBackupControlRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`; 

export const GET_INCIDENT_PROPERTRY_REQUESTS = gql`
  query IncidentReportRequests($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllIncidentReportRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_THIRD_PARTY_VPN_REQUESTS = gql`
  query ThirdPartyVPNRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllThirdPartyVPNRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_REMOTE_ACCESS_REQUESTS = gql`
  query RemoteAccessRequests($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllRemoteAccessRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_MODEM_ISSUE_REQUESTS = gql`
  query ModemIssueRequests($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date, $section: String) {
    getAllModemIssueRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate, section: $section) {
      success
      data
  }
  }
`;

export const GET_LAPTOP_ISSUES = gql`
  query laptopIssueRequests($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date, $section: String) {
    getAllLaptopIssue(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate, section: $section) {
      success
      data
  }
  }
`;

export const GET_CHANGE_ISSUES = gql`
  query ChangeRequests($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllChangeRequest(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;
export const GET_ENGAGEMENT_ADVICES = gql`
  query EngagementAdvices($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllEngagementAdvices(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_GLASS_PANEL = gql`
  query GlassPanel($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllGlassPanels(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;


export const GET_SESIRO_KYC = gql`
  query SesiroKyc($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllSesiroKycs(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_PROPERTY_DAMAGE = gql`
  query PropertyDamage($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllPropertyDamages(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;


export const GET_EXIT_CLEARANCE = gql`
  query ExitClearances($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllExitClearances(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_ALL_ACTINGS = gql`
  query Actings($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllActings(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_CHANGE_OF_STATUS = gql`
  query ChangeOfStatus($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllChangeOfStatus(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_PREPAYMENT = gql`
  query Prepayment($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllPrepayment(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
    }
  }
`;

export const GET_EXPENSE_CLAIM = gql`
  query ExpenseClaim($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllExpenseClaim(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
    }
  }
`;


export const GET_FUNDS_APPLICATION = gql`
  query FundsApplication($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllFundsApplications(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
    }
  }
  `;

export const GET_PAYMENT_VOUCHER = gql`
  query PaymentVoucher($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllPaymentVoucher(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      subscriptionId  
      date
      payee
      voucherNo
      chequeNo
      details1
      details2
      amount1
      amount2
      amount3
      amount4
      amount5
      amount6
      amount7
      amount8
      amount9
      amount10
      amount11
      amount12
      amount13
      amount14
      amount15
      amount16
      amount17
      amount18
      amount19
      amount20
      total1
      total2
      total3
      total4
      code1
      code2
      code3
      code4
      code5
      code6
      code7
      code8
      description1
      description2
      description3
      description4
      description5
      description6
      description7
      description8
      ReceivedBy
      ReceiverSign
      ReceiverDate
      PreparedBy
      PreparedSign
      PreparedDate
      CheckedBy
      CheckedSign
      CheckedDate
      AuthorizedBy
      AuthorizedSign
      AuthorizedDate
      ApprovedBy
      ApprovedSign
      ApprovedDate
      createdById
      createdByName
    }
  }
`;

export const GET_ADVANCE_REQUISITION = gql`
  query AdvanceRequisition($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllAdvanceRequisition(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
    }
  }
`;

export const GET_PROPOSAL_ISSUE = gql`
  query ProposalIssue($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllProposalIssue(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_KEYS_CONTROL_REGISTER = gql`
  query KeysControlRegister($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllKeysControlRegisterRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_PERSON_SEARCH_REGISTER = gql`
  query PersonSearchRegister($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllPersonSearchRegisterRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_REMOTE_CONTROL_REGISTER = gql`
  query RemoteControlRegister($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllRemoteControlRegisterRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_PAYMENT_REQUISITION = gql`
  query PaymentRequisition($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllPaymentRequisitions(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`;

export const GET_BOTASH_REQUESTS = gql`
  query BotashPocRequest($subscriptionId: String, $status: String, $search: String, $startDate: Date, $endDate: Date) {
    getAllBotashPocRegisterRequests(subscriptionId: $subscriptionId, search: $search, status: $status, startDate: $startDate, endDate: $endDate) {
      success
      data
  }
  }
`; 