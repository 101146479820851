import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import Table from '@material-ui/core/Table';
import { CircularProgress, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import LivenessPanelItem from './LivenessPanelItem';
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Pagination from '@material-ui/lab/Pagination';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { marginGenerator } from '../../../../../../theme/utils';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { GET_LIVENESS_VIDEOS, GET_VIDEO_SIGNED_URL } from '../../queries';
import { CREATE_LIVENESS_VIDEO_RECORD } from '../../mutation';
import CreateVideoRecordModal from '../../components/CreateVideoRecordModal';
import ImageModal from '../../../../../../components/ImageModal';
import Message from '../../../../../../components/Message';

const useStyles = makeStyles((theme) => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    }
}));

function LivenessPanel({
    t,
    data,
    allowConfigure
}) {

    const classes = useStyles();
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, search: "" });
    const [openVideoRecordModal, setOpenVideoRecordModal] = useState(false);
    const [videoModalState, setVideoModalState] = useState({clearData: false, btnLoading: false});
    const [apiTimer, setApiTimer] = useState(0);
    const [loading, setLoading] = useState(false);
    const [videoSignedUrl, setVideoSignedUrl] = useState("");
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [list, setList] = useState(null);

    const organization = data.organizationId;

    useEffect(() => {
        setLoading(true);
        return getLivenessVideos({
            variables: {
                organization,
                page: 1,
                limit: 20,
                search: ""
            }
        });
    }, []);

    const [getLivenessVideos, {error}] = useLazyQuery(GET_LIVENESS_VIDEOS, {
        fetchPolicy: "no-cache",
        onError: (e) => {
            setLoading(false);
        },
        onCompleted: (res) => {
            setLoading(false);
            setList(res);
        }
    });

    const [getVideoSignedUrl] = useLazyQuery(GET_VIDEO_SIGNED_URL, {
        fetchPolicy: "no-cache",
        onError: (e) => {
            Message.error(e);
        },
        onCompleted: (res) => {
            if(res.getVideoSignedUrl && res.getVideoSignedUrl.signed_url){
                setShowVideoModal(true);
                setVideoSignedUrl(res.getVideoSignedUrl.signed_url);
            }else{
                Message.error("Video not found!");
            }
        }
    });

    const [addLivenessVideoRecord] = useMutation(CREATE_LIVENESS_VIDEO_RECORD, {
        onCompleted: (res) => {
            setOpenVideoRecordModal(false);
            setVideoModalState({btnLoading: false, clearData: true});
            setLoading(true);
            return getLivenessVideos({
                variables: {
                    organization,
                    page: 1,
                    limit: 20,
                    search: ""
                }
            });
        },
        onError: (e) => {
            console.log("Error in, ", e);
            setVideoModalState({btnLoading: false, clearData: false});
        }
    });

    const columns = [
        {
            label: 'Person Name',
            key: "personName"
        },
        {
            label: 'Uploaded On',
            key: "uploadedOn"
        },
        {
            label: 'Status',
            key: "status"
        },
        // {
        //     label: "Result",
        //     key: "projectName"
        // },
        {
            label: 'Liveness',
            key: "liveness"
        }
    ];

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => setFilterStateData({...filterStateData, search: evt.target.value}),
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: 'Status',
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, status: evt.target.value});
            },
            val: filterStateData.status,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: [{id: true, name: "True"}, {id: false, name: "false"}]
        }
    ];

    if (allowConfigure) {
        columns.push({
            label: t('action.label'),
            key: "button"
        });
    }

    const handleCreateVideoSubmit = (formData) => {
        setVideoModalState({btnLoading: true, clearData: false});
        return addLivenessVideoRecord({variables: {
            ...formData,
            organization
        }});
    }
    
    React.useEffect(() => {
        let filterBy = {
            variables: {
                ...filterStateData,
                organization
            }
        }
        const timer = setTimeout(() => {
          setApiTimer(apiTimer + 1);
          return getLivenessVideos(filterBy);
        }, 30000);
        return () => {
          clearTimeout(timer);
        };
    }, [apiTimer]);

    const handleViewVideo = (item, type) => {
        if(type == "video"){
            return getVideoSignedUrl({
                variables: {
                    url: item.url,
                    id: item.id 
                }
            });
        }else{
            if(item.face_image){
                setShowVideoModal(true);
                setVideoSignedUrl(item.face_image);
            }else{
                Message.error("Face image not found!");
            }
            return null;
        }
    }

    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => {
                        setLoading(true);
                        setApiTimer(apiTimer + 1);
                        return getLivenessVideos({
                            variables: {
                                ...filterStateData,
                                organization,
                                page: 1,
                                limit: 20
                            }
                        });
                    }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={() => setOpenVideoRecordModal(true)}
                        >
                            {"Record Video"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.getLivenessVideos && list.getLivenessVideos.videos.length > 0 ?
                                    list.getLivenessVideos.videos.map((rowitem, rowIndex) => (
                                        <LivenessPanelItem
                                            key={rowIndex}
                                            data={rowitem}
                                            handleViewVideo={handleViewVideo}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <div style={{width: "100%", textAlign: "center"}}>
                                            <CircularProgress size={24}  color="primary" />
                                        </div>}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading &&
                    <>
                        <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Grid item xs={2}>
                                <span>Total {(list && list.getLivenessVideos) ? Math.ceil(list.getLivenessVideos.count / 20) : 0} Page,  {(list && list.getLivenessVideos) ? list.getLivenessVideos.count : 0} Row</span>
                            </Grid>
                            <Grid item xs={2}>
                                <Pagination
                                    color="secondary"
                                    count={(list && list.getLivenessVideos) ? Math.ceil(list.getLivenessVideos.count / 20) : 0}
                                    page={0}
                                    onChange={(evt, value) => {
                                        setLoading(true);
                                        setApiTimer(apiTimer + 1);
                                        return getLivenessVideos({
                                            variables: {
                                                ...filterStateData,
                                                organization,
                                                page: value,
                                                limit: 20
                                            }
                                        });
                                    }} />
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
            <CreateVideoRecordModal 
                open={openVideoRecordModal} 
                handleClose={() => setOpenVideoRecordModal(false)} 
                handleSubmit={handleCreateVideoSubmit} 
                modalState={videoModalState}
            />
            <ImageModal
                handleClose={() => {
                    setShowVideoModal(false);
                    setVideoSignedUrl("");
                }}
                open={showVideoModal}
                imageSrc={videoSignedUrl}
            />
        </div>
    );
}

LivenessPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default withTranslation()(LivenessPanel);
