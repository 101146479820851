import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#9696A4',
        borderBottomWidth: 1,
        height: 24,
        fontStyle: 'bold',
        fontSize: 10,
        flexGrow: 1,
        backgroundColor: '#661723',
        color: 'white'
    },
    description: {
        marginTop:'5px',
        width: '23%',
        marginLeft: '5px'
    },
    head1: {
        width: '5%',
        textAlign: "center",
        marginTop:'5px',
    },
    head2: {
        width: '8%',
        textAlign: "center",
        marginTop:'5px',
    },
  });
  const InvoiceTableHeader = ({row}) => {
      return (
        <View style={styles.container}>
            <Text style={styles.head1}>{row.title}</Text>
            <Text style={styles.head2}>{row.head1}</Text>
            <Text style={styles.description}>{row.head2}</Text>
            <Text style={styles.description}>{row.head3}</Text>
            <Text style={styles.head2}>{row.head4}</Text>
            <Text style={styles.head2}>{row.head5}</Text>
            <Text style={styles.head2}>{row.head6}</Text>
            <Text style={styles.head2}>{row.head7}</Text>
            <Text style={styles.head2}>{row.head8}</Text>
        </View>
    );
}
  
  export default InvoiceTableHeader