import React, { useState, createContext, useEffect } from 'react';
import { get } from "lodash-es";
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_SHAYONA_DASHBOARD_DATA,
  GET_SHAYONA_TRUKES_LIST,
  GET_SHAYONA_TRIPS_LIST,
  GET_SHAYONA_ALERTS_LIST,
  GET_SHAYONA_BRANDS_LIST,
  GET_SHAYONA_AUDIT_LIST
} from '../containers/Subscriptions/Shayona/queries';
import { convertLocalToUTC } from '../utils';
import {
  NODE_MANAGEMENT_TYPE,
  EDGE_AI_LIVE_CAM_URL
} from '../utils/constants';
import { useHistory } from 'react-router';
import {
  GET_ML_TEMPLATE_LIST,
  GET_ML_TEMPLATE_BY_ID,
  GET_NOTIFICATION_TEMPLATE_LIST,
  GET_PLACE_LIST,
  GET_NODE_LIST,
  GET_VPNS_LIST,
  GET_LINE_CROSS_OBJECT,
  GET_EDGE_AI_TYPES,
  GET_EDGE_AI_SETTINGS,
  GET_CAMERA_LIST
} from '../containers/Subscriptions/queries';
import {
  CREATE_ML_TEMPLATE,
  CREATE_NOTIFICATION_TEMPLATE,
  CREATE_PLACE,
  CREATE_CAMERA,
  UPDATE_ML_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE,
  UPDATE_PLACE,
  UPDATE_CAMERA,
  CREATE_NODE,
  UPDATE_SUBSCRIPTION,
  UPDATE_LIVE_CAMERA_URL,
  DELETE_EDGEAI_SUBSCRIPTION
} from '../containers/Subscriptions/mutations';
import Message from '../components/Message';
import CreateMLTemplateModal from '../containers/Subscriptions/components/CreateMLTemplateModal';
import UpdateMLTemplateModal from '../containers/Subscriptions/components/UpdateMLTemplateModal';
import CreateNotificationTemplateModal from '../containers/Subscriptions/components/CreateNotificationTemplateModal';
import CreatePlaceTemplateModal from '../containers/Subscriptions/components/CreatePlaceTemplateModal';
import CreateCameraTemplateModal from '../containers/Subscriptions/components/CreateCameraTemplateModal';
import CreateLiveCamModal from '../containers/Subscriptions/components/EdgeAILiveCamPanel/CreateLiveCamModal';
import UpdateCameraModal from '../containers/Subscriptions/components/UpdateCameraModal';
import SelectAITypeModal from '../containers/Subscriptions/components/SelectAITypeModal';
import CreateNodeModal from '../containers/Subscriptions/components/CreateNodeModal';
import DeleteConfirmBox from '../containers/Subscriptions/components/DeleteConfirmBox';
import ImageModal from '../components/ImageModal';

export const ShayonaCementsContext = createContext();

function ShayonaCementsProvider({ children, data, t, reloadSubscription, countryCodes }) {

  const { organizationId, id } = data.getSubscriptionById;
  const history = useHistory();
  const [createMLTemplateModal, setCreateMLModalState] = useState(false);
  const [updateMLTemplateModalState, setUpdateMLTemplateModalState] = useState(
    false
  );
  const [
    createNotificationTemplateModal,
    setCreateNotificationModalState
  ] = useState(false);
  const [createPlaceTemplateModal, setCreatePlaceModalState] = useState(false);
  const [createCameraTemplateModal, setCreateCameraModalState] = useState(
    false
  );
  const [createNodeModalState, setCreateNodeModalState] = useState(false);
  const [updateCameraModalState, setUpdateCameraModalState] = useState(false);
  const [imageModal, setImageModalState] = useState(false);
  const [imageModalSrc, setImageModalSrcState] = useState('');
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [updateModalState, setUpdateModalState] = useState({
    mlTemplate: {},
    notification: {},
    place: {},
    camera: {}
  });
  const [updateMlTemplateLoading, setupdateMlTemplateLoading] = useState(false);
  const [aiTypeModalState, setAiTypeModalState] = useState(false);
  const [reloadDataState, setReloadDataState] = useState('');
  const [selectePlaceList, setSelectePlaceList] = useState([]);
  const [selectMlTemplateList, setSelectMlTemplateList] = useState([]);
  const [selectNotiTemplateList, setSelectNotiTemplateList] = useState([]);
  const [selecteNodeList, setSelecteNodeList] = useState([]);
  const [VPNSList, setVPNSList] = useState([]);
  const [auditList, setAuditList] = useState([]);
  const [lineCrossObjectState, setLineCrossObjectState] = useState(null);
  const [lineCrossDataLoading, setLineCrossDataLoading] = useState(false);
  const [mlModalState, setMLModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [notificationModalState, setNotificationModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [placeModalState, setPlaceModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [cameraModalState, setCameraModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [shayonaTruckList, setShayonaTruckList] = useState('');
  const [shayonaTripsList, setShayonaTripsList] = useState('');
  const [shayonaAlertsList, setShayonaAlertsList] = useState('');
  const [shayonaDashboardData, setShayonaDashboardData] = useState('');
  const [shayonaBrandsData, setShayonaBrandsData] = useState('');
  const [paginationPage, setPaginationPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [edgeAIAnalyticsURLModal, setEdgeAIAnalyticsURLModal] = useState(false);
  const [analyticsModalState, setAnalyticsModalState] = useState({
    url: '',
    btnLoading: false,
    clearData: false
  });
  const [createLiveCamModal, setCreateLiveCamModal] = useState(false);
  const [liveCamModalState, setLiveCamModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [nodeModalState, setNodeModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [transectionModalState, setTransectionModalState] = useState({
    btnLoading: false,
    clearData: false
  });
  const [createTransectionModalState, setCreateTransectionModalState] = useState(false);
  const [selectedAiType, setSelectedAiType] = useState('');
  const [deleteType, setDeleteType] = useState('');
  const [deleteEdgeAISubLoading, setDeleteEdgeAISubLoading] = useState(false);
  const [aiTypes, setAITypes] = useState([]);
  const [providerTypes, setProviderTypes] = useState(null);
  const [multiObjects, setMultiObjects] = useState([]);
  const [edgeAIActions, setEdgeAIActions] = useState(null);
  const [edgeAILiveCamURL, setEdgeAILiveCamURL] = useState(EDGE_AI_LIVE_CAM_URL);
  const [edgeAITagFilters, setEdgeAITagFilters] = useState(null);
  const [edgeAICameraFps, setEdgeAICameraFps] = useState([]);
  const [edgeAICameraResolutions, setEdgeAICameraResolutions] = useState([]);
  const [edgeAIFrequencies, setEdgeAIFrequencies] = useState([]);
  const [accidentDetectionObj, setAccidentDetectionObj] = useState([]);
  const [goToPage, setGoToPage] = useState(null);
  const [apiTimer, setApiTimer] = useState(0);

  const { data: edgeAITypeData } = useQuery(GET_EDGE_AI_TYPES, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      let aiTypeList = data.getAllEdgeAITypes.map(itm => {
        itm.id = itm.type;
        return itm;
      });
      setAITypes(aiTypeList);
    }
  });

  const { data: edgeAISettingsData } = useQuery(GET_EDGE_AI_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.getEdgeAISettings) {
        let settingData = data.getEdgeAISettings;
        setProviderTypes(settingData.providerTypes ? settingData.providerTypes : null);
        setMultiObjects(settingData.multiobjects ? settingData.multiobjects : []);
        setEdgeAIActions(settingData.actions ? settingData.actions : []);
        setEdgeAITagFilters(settingData.tagsFilter ? settingData.tagsFilter : null);
        setEdgeAICameraFps(settingData.cameraFps ? settingData.cameraFps : []);
        setEdgeAICameraResolutions(settingData.cameraResolutions ? settingData.cameraResolutions : []);
        setEdgeAIFrequencies(settingData.frequencies ? settingData.frequencies : []);
        setEdgeAILiveCamURL(settingData.liveCamURL ? settingData.liveCamURL : EDGE_AI_LIVE_CAM_URL);
        setAccidentDetectionObj(settingData.accidentDetectionObj ? settingData.accidentDetectionObj : []);
      }
    }
  });

  // shayona start api's

  const [getDasboardDataByFilter, { loading:  shayonaDasboardDataLoading}] = useLazyQuery(GET_SHAYONA_DASHBOARD_DATA, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaDashboardData(get(data, 'getDashboardData', {}));
    }
  });
  
  const [getTrucklistByFilter, { loading:  shayonaTruckLoading}] = useLazyQuery(GET_SHAYONA_TRUKES_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaTruckList(data);
    }
  });

  const [getTripslistByFilter, { loading:  shayonaTripsLoading}] = useLazyQuery(GET_SHAYONA_TRIPS_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaTripsList(data);
    }
  });

    const [getTripslist, { loading}] = useLazyQuery(GET_SHAYONA_TRIPS_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaTripsList(data);
    }
  });

  const [getAlertslistByFilter, { loading:  shayonaAlerLoading}] = useLazyQuery(GET_SHAYONA_ALERTS_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaAlertsList(data);
    }
  });

  const [getBrandslistByFilter, { loading:  shayonaBrandsLoading}] = useLazyQuery(GET_SHAYONA_BRANDS_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setShayonaBrandsData(data);
    }
  });
  //end shayona api's

  const handleCreateButtonClick = item => {
    if (item === 'ml_template') {
      setMLModalState({ btnLoading: false, clearData: false });
      setUpdateModalState({ ...updateModalState, mlTemplate: {} });
      setSelectedAiType('');
      setCreateMLModalState(true);
    } else if (item === 'notification_template') {
      setNotificationModalState({ btnLoading: false, clearData: false });
      setUpdateModalState({ ...updateModalState, notification: {} });
      setCreateNotificationModalState(true);
    } else if (item === 'place_template') {
      setPlaceModalState({ btnLoading: false, clearData: false });
      setUpdateModalState({ ...updateModalState, place: {} });
      setCreatePlaceModalState(true);
    } else if (item === 'camera_template') {
      if (data.getSubscriptionById) {
        let orgFilter = {
          variables: {
            organization: data.getSubscriptionById.organizationId,
            search: '',
            page: 1,
            limit: 20
          }
        };
        let orgLimitFltr = {
          variables: {
            organization: data.getSubscriptionById.organizationId,
            search: '',
            page: 1,
            limit: 500
          }
        }
        getNodeList(orgFilter);
        getPlaceList(orgFilter);
        getMLTemplateList(orgLimitFltr);
        getNotificationTemplateList(orgLimitFltr);
        getVpnsList(orgLimitFltr);
      }
      setCameraModalState({ btnLoading: false, clearData: false });
      setUpdateModalState({ ...updateModalState, camera: {} });
      setCreateCameraModalState(true);
    } else if (item === 'node') {
      setNodeModalState({ btnLoading: false, clearData: false });
      setCreateNodeModalState(true);
    }
  }

/**
   * EDGE AI SERVICE QUERY/MUTATION
   */
const [createNewMLTemplate] = useMutation(CREATE_ML_TEMPLATE, {
  onError: error => {
    setMLModalState({ btnLoading: false, clearData: false });

    Message.error(error);
  },
  onCompleted: data => {
    if (data.addMLTemplate.id) {
      setSelectedAiType('');
      setMLModalState({ btnLoading: false, clearData: true });
      Message.success('Machine learning template created successfully.');
      setReloadDataState('ml_template');
    }
  }
});
const [updateMLTemplate] = useMutation(UPDATE_ML_TEMPLATE, {
  onError: error => {
    Message.error(error);
    setMLModalState({ btnLoading: false, clearData: false });
  },
  onCompleted: data => {
    setMLModalState({ btnLoading: false, clearData: true });
    if (data.updateMLTemplate && data.updateMLTemplate.id) {
      Message.success('Machine learning template updated successfully.');
      setReloadDataState('ml_template');
    }
  }
});
const [createNotificationTemplate] = useMutation(
  CREATE_NOTIFICATION_TEMPLATE,
  {
    onError: error => {
      Message.error(error);
      setNotificationModalState({ btnLoading: false, clearData: false });
    },
    onCompleted: data => {
      setNotificationModalState({ btnLoading: false, clearData: true });
      if (data.addNotificationTemplate && data.addNotificationTemplate.id) {
        Message.success('Notification template created successfully');
        setReloadDataState('notification');
      }
    }
  }
);
const [updateNotificationTemplate] = useMutation(
  UPDATE_NOTIFICATION_TEMPLATE,
  {
    onError: error => {
      Message.error(error);
      setNotificationModalState({ btnLoading: false, clearData: false });
    },
    onCompleted: data => {
      setNotificationModalState({ btnLoading: false, clearData: true });
      if (data.updateNotificationTemplate && data.updateNotificationTemplate.id) {
        Message.success('Notification template updated successfully');
        setReloadDataState('notification');
      }
    }
  }
);
const [createPlace] = useMutation(CREATE_PLACE, {
  onError: error => {
    Message.success(error);
    setPlaceModalState({ btnLoading: false, clearData: false });
  },
  onCompleted: data => {
    if (data.addPlace.id) {
      setPlaceModalState({ btnLoading: false, clearData: true });
      Message.success('Place created successfully');
      setReloadDataState('places');
    }
  }
});
const [updatePlace] = useMutation(UPDATE_PLACE, {
  onError: error => {
    Message.success(error);
    setPlaceModalState({ btnLoading: false, clearData: false });
  },
  onCompleted: data => {
    setPlaceModalState({ btnLoading: false, clearData: true });
    Message.success('Place updated successfully');
    setReloadDataState('places');
  }
});
const [createCamera] = useMutation(CREATE_CAMERA, {
  onError: error => {
    setCameraModalState({ btnLoading: false, clearData: false });
    Message.error(error);
  },
  onCompleted: data => {
    if (data.addCamera && data.addCamera.success) {
      setCameraModalState({ btnLoading: false, clearData: true });
      Message.success('Camera configuration created successfully');
      setReloadDataState('cameras');
    } else {
      setCameraModalState({ btnLoading: false, clearData: false });
      Message.error(data.addCamera.message ? data.addCamera.message : "Something went wrong!");
    }
  }
});
const [createNode] = useMutation(CREATE_NODE, {
  onError: error => {
    Message.success(error);
    setNodeModalState({ btnLoading: false, clearData: false });
  },
  onCompleted: data => {
    setNodeModalState({ btnLoading: false, clearData: true });
    Message.success('Node created successfully.');
    setReloadDataState('node');
  }
});
const [updateCamera] = useMutation(UPDATE_CAMERA, {
  onError: error => {
    Message.success(error);
    setCameraModalState({ btnLoading: false, clearData: false });
  },
  onCompleted: data => {
    setUpdateModalState({ ...updateModalState, camera: {} });
    setCameraModalState({ btnLoading: false, clearData: true });
    Message.success('Camera configuration updated successfully');
    setReloadDataState('cameras');
  }
});
const [deleteEdgeAiSubscription] = useMutation(DELETE_EDGEAI_SUBSCRIPTION, {
  onError: error => {
    setDeleteEdgeAISubLoading(false);
    setDeleteType('');
    setDeleteModalState(false);
    history.goBack();
  },
  onCompleted: data => {
    setDeleteEdgeAISubLoading(false);
    setDeleteType('');
    setDeleteModalState(false);
    Message.success('Subscription termineted successfully');
    history.goBack();
  }
});
const handleDeleteConfirmClose = () => {
  setDeleteModalState(false);
};
/* Delete Controller*/
const handleConfirmDelete = () => {
  if (deleteType === t('subscription.label')) {
    setDeleteEdgeAISubLoading(true);
    if (id) {
      return deleteEdgeAiSubscription({
        variables: {
          subscriptionId: id
        }
      });
    }
    return null;
  }
};

const {
  loading: cameraLoading,
  data: cameraList,
  error: cameraError,
  refetch: reloadCameraData
} = useQuery(GET_CAMERA_LIST, {
  fetchPolicy: "no-cache",
  variables: {
    organization: organizationId,
    search: '',
    page: 1,
    limit: 1000,
    placeId: "",
    status: ""
  }
});

const [getMLTemplateList] = useLazyQuery(GET_ML_TEMPLATE_LIST, {
  fetchPolicy: 'no-cache',
  onCompleted: data => {
    setSelectMlTemplateList(
      data.getAllMlTemplateList && data.getAllMlTemplateList.templates
        ? data.getAllMlTemplateList.templates
        : []
    );
  }
});
const [getMLTemplateDetailById] = useLazyQuery(GET_ML_TEMPLATE_BY_ID, {
  fetchPolicy: 'no-cache',
  onCompleted: data => {
    if (data.getMLTemplateDetailById) {
      setUpdateModalState({ ...updateModalState, mlTemplate: data.getMLTemplateDetailById });
      setupdateMlTemplateLoading(false);
    }
  },
  onError: error => {
    Message.error(error);
  }
});
const [getPlaceList] = useLazyQuery(GET_PLACE_LIST, {
  fetchPolicy: 'no-cache',
  onCompleted: data => {
    setSelectePlaceList(
      data.getAllPlaceList && data.getAllPlaceList.places
        ? data.getAllPlaceList.places
        : []
    );
  }
});
const [getNodeList] = useLazyQuery(GET_NODE_LIST, {
  fetchPolicy: 'no-cache',
  onCompleted: data => {
    if (data.getNodeList && data.getNodeList.nodes && data.getNodeList.nodes.length) {
      let nodeList = data.getNodeList.nodes.filter(item => item.mode === NODE_MANAGEMENT_TYPE.UNMANAGED);
      setSelecteNodeList(nodeList);
    }
  }
});
const [getVpnsList] = useLazyQuery(GET_VPNS_LIST, {
  fetchPolicy: 'no-cache',
  onCompleted: data => {
    if (
      data.getVpnsList &&
      data.getVpnsList.vpns &&
      data.getVpnsList.vpns.length
    ) {
      setVPNSList(data.getVpnsList.vpns);
    }
  }
});
const [getNotificationTemplateList] = useLazyQuery(
  GET_NOTIFICATION_TEMPLATE_LIST,
  {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setSelectNotiTemplateList(
        data.getAllNotificationTemplateList &&
          data.getAllNotificationTemplateList.templates
          ? data.getAllNotificationTemplateList.templates
          : []
      );
    }
  }
);
const [getLineCrossObjectById] = useLazyQuery(GET_LINE_CROSS_OBJECT, {
  fetchPolicy: 'no-cache',
  onCompleted: res => {
    if (res.getLineCrossObjectById) {
      setLineCrossObjectState(res.getLineCrossObjectById);
    }
    setLineCrossDataLoading(false);
  }
});

const [getAuditList, { loading:  shayonaAuditDataLoading}] = useLazyQuery(GET_SHAYONA_AUDIT_LIST, {
  "fetchPolicy": "no-cache",
  onError: error => {
    console.log('Error', error);
  },
  onCompleted: data => {
    setAuditList(
      data.getAuditList && data.getAuditList.data
        ? data.getAuditList.data
        : []
    );
  }
});
const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
  onCompleted: data => {
    Message.success('Analytics URL added successfully.');
    reloadSubscription();
    setEdgeAIAnalyticsURLModal(false);
  }
});
const [updateLiveCameraUrl] = useMutation(UPDATE_LIVE_CAMERA_URL, {
  onCompleted: data => {
    Message.success('LiveCam URL added successfully.');
    reloadSubscription();
    setCreateLiveCamModal(false);
    setLiveCamModalState({ btnLoading: false, clearData: true });
  }
});
const handleAddURLSubmitClick = (act, formData) => {
  if (act === 'analytics') {
    return updateSubscription({
      variables: {
        id: data.getSubscriptionById.id,
        analyticsUrl: formData.url
      }
    });
  } else {
    setLiveCamModalState({ btnLoading: true, clearData: false });
    return updateLiveCameraUrl({
      variables: {
        id: data.getSubscriptionById.id,
        liveCamUrls: JSON.stringify(formData.urls)
      }
    });
  }
};

const handleCreateNotificationSubmitClick = formData => {
  if (
    formData.name &&
    formData.notification_type &&
    formData.dispatch_address &&
    formData.notification_message
  ) {
    formData.slug = formData.name;
    formData.organization = data.getSubscriptionById.organizationId;
    setNotificationModalState({ btnLoading: true, clearData: false });
    if (formData.id) {
      return updateNotificationTemplate({
        variables: formData
      });
    } else {
      return createNotificationTemplate({
        variables: formData
      });
    }
  } else {
    return false;
  }
};
const handleCreatePlaceSubmitClick = formData => {
  if (formData.name && formData.location) {
    let placeData = {
      name: formData.name,
      loc: [formData.location.latitude, formData.location.longitude],
      type: 'general',
      orgId: data.getSubscriptionById.organizationId
    };
    setPlaceModalState({ btnLoading: true, clearData: false });
    if (formData.place_id) {
      placeData.place_id = formData.place_id;
      return updatePlace({
        variables: placeData
      });
    } else {
      return createPlace({
        variables: placeData
      });
    }
  } else {
    return false;
  }
};
const handleCreateCameraSubmitClick = formData => {
  const camData = [];
  if (formData.cameras.length > 0) {
    setCameraModalState({ btnLoading: true, clearData: false });
    for (let i = 0; i < formData.cameras.length; i++) {
      let tempObj = {};
      let cam = formData.cameras[i];
      tempObj.orgId = data.getSubscriptionById.organizationId;
      tempObj.camData = {
        name: cam.name,
        urlJpeg: '',
        urlVideo: '',
        flags: '',
        streamContentWidth: cam.width.toString(),
        streamContentHeight: cam.height.toString(),
        placeId: cam.place ? cam.place.place_id : ''
      };
      tempObj.fps = cam.fps.toString();
      tempObj.remote_stream = (cam.remote_stream && cam.remote_stream === "Yes") ? true : false;
      tempObj.url = cam.rtsp_url;
      tempObj.configTemplate = [];
      tempObj.provider = formData.provider;
      tempObj.node_id = formData.node ? formData.node : '';
      if (formData.aiModels.length > 0 && formData.aiModels[0].ai_template_id) {
        for (let j = 0; j < formData.aiModels.length; j++) {
          let tmpAiModal = {};
          let aiMdl = formData.aiModels[j];
          tmpAiModal.startTime = aiMdl.from_time
            ? convertLocalToUTC(aiMdl.from_time + ' 00')
            : '00:00';
          tmpAiModal.endTime = aiMdl.to_time
            ? convertLocalToUTC(aiMdl.to_time + ' 00')
            : '00:00';
          tmpAiModal.frequency = 1;
          tmpAiModal.templateId = aiMdl.ai_template_id;
          tmpAiModal.notificationTemplateId = [];
          if (
            formData.notifications.length > 0 &&
            formData.notifications[0].notification_template_id
          ) {
            for (let k = 0; k < formData.notifications.length; k++) {
              if (
                formData.notifications[k].ai_template_id ==
                aiMdl.ai_template_id
              ) {
                tmpAiModal.notificationTemplateId.push(
                  formData.notifications[k].notification_template_id
                );
              }
            }
          }
          tempObj.configTemplate.push(tmpAiModal);
        }
        camData.push(tempObj);
      }
    }
    if (camData.length) {
      return createCamera({
        variables: { camData }
      });
    }
  }
};
const handleUpdateCameraSubmitClick = formData => {
  let camera = updateModalState.camera;
  let camData = {};
  let deletedAIModels = [];
  if (formData.cameras.length > 0) {
    if (formData.deletedAIModels && formData.deletedAIModels.length) {
      if (formData.prevData.schedules && formData.prevData.schedules.length) {
        let prevShedules = formData.prevData.schedules[0];
        deletedAIModels = prevShedules.filter(item => {
          if (formData.deletedAIModels.includes(item.id)) {
            return item;
          }
        });
      }
    }
    setCameraModalState({ btnLoading: true, clearData: false });
    for (let i = 0; i < formData.cameras.length; i++) {
      let cam = formData.cameras[i];
      camData.orgId = data.getSubscriptionById.organizationId;
      camData.camera_id = camera.camera_id;
      camData.camData = {
        name: cam.name ? cam.name : camera.name,
        urlJpeg: camera.image_url,
        urlVideo: camera.video_url,
        flags: camera.flags,
        streamContentWidth: cam.width ? cam.width : camera.content_width,
        streamContentHeight: cam.height ? cam.height : camera.content_height,
      };
      camData.placeId = (cam.placeId && cam.placeId.place_id) ? cam.placeId.place_id : camera.place.place;
      camData.fps = cam.fps ? cam.fps : camera.fps;
      camData.remote_stream = cam.remote_stream ? (cam.remote_stream === "Yes") ? true : false : camera.remote_stream ? camera.remote_stream : false;
      camData.url = cam.rtsp_url ? cam.rtsp_url : camera.url;
      if (formData.node) {
        camData.node_id = formData.node;
      }
      camData.provider = formData.provider
        ? formData.provider
        : formData.prevData.provider;
      camData.configTemplate = [];
      if (
        formData.aiModels.length > 0 &&
        formData.aiModels[0].ai_template_id
      ) {
        for (let j = 0; j < formData.aiModels.length; j++) {
          let tmpAiModal = {};
          let aiMdl = formData.aiModels[j];
          tmpAiModal.startTime = aiMdl.from_time
            ? convertLocalToUTC(aiMdl.from_time + ' 00')
            : '00:00';
          tmpAiModal.endTime = aiMdl.to_time
            ? convertLocalToUTC(aiMdl.to_time + ' 00')
            : '00:00';
          tmpAiModal.frequency = 1;
          tmpAiModal.templateId = aiMdl.ai_template_id;
          tmpAiModal.delete = false;
          if (aiMdl.scheduleId) {
            tmpAiModal.scheduleId = aiMdl.scheduleId;
          }
          tmpAiModal.notificationTemplateId = [];
          if (
            formData.notifications.length > 0 &&
            formData.notifications[0].notification_template_id
          ) {
            for (let k = 0; k < formData.notifications.length; k++) {
              if (!formData.notifications[k].id) {
                if (
                  formData.notifications[k].ai_template_id ==
                  aiMdl.ai_template_id
                ) {
                  tmpAiModal.notificationTemplateId.push(
                    formData.notifications[k].notification_template_id
                  );
                }
              }
            }
          }
          camData.configTemplate.push(tmpAiModal);
        }
      }
      if (deletedAIModels.length) {
        for (let k = 0; k < deletedAIModels.length; k++) {
          let aiMdl = deletedAIModels[k];
          let tempObj = {
            templateId: aiMdl.model_template.id
              ? aiMdl.model_template.id
              : '',
            startTime: aiMdl.start_time,
            endTime: aiMdl.end_time,
            scheduleId: aiMdl.id,
            frequency: 1,
            delete: true,
            notificationTemplateId: []
          };
          camData.configTemplate.push(tempObj);
        }
      }
      if (
        formData.deletedNotifications &&
        formData.deletedNotifications.length
      ) {
        let delNotiObj = {
          delete: true,
          notificationFilters: formData.deletedNotifications
        };
        camData.configTemplate.push(delNotiObj);
      }
    }
    if (camData) {
      return updateCamera({
        variables: { camData }
      });
    }
  }
};
const handleUpdateAction = actData => {
  setLineCrossDataLoading(false);
  setupdateMlTemplateLoading(false);
  if (actData.action === 'ml_template') {
    if (actData.data && actData.data.model_type) {
      switch (actData.data.model_type) {
        case 'HeatMap':
          actData.data.model_type = 'heatmap_alert';
          break;
        case 'LineCrossing':
          actData.data.model_type = 'people_counting_alert';
          break;
        case 'Behavioural':
          actData.data.model_type = 'behavioural_alert';
          break;
        case 'MultiplePerson':
          actData.data.model_type = 'multi_person_alert';
          break;
        case 'multi-object':
          actData.data.model_type = 'multi_object';
          break;
      }
      if (actData.data.model_type === 'line_cross' || actData.data.model_type === 'line_cross2' || actData.data.model_type === 'volume_analysis') {
        setLineCrossDataLoading(true);
        getLineCrossObjectById({
          variables: { templateId: actData.data.id }
        });
      }
      if (actData.data.model_type === 'face_recognition') {
        getMLTemplateDetailById({ variables: { id: actData.data.id } });
        setupdateMlTemplateLoading(true);
      } else {
        setUpdateModalState({ ...updateModalState, mlTemplate: actData.data });
      }
      setSelectedAiType(actData.data.model_type);
      setUpdateMLTemplateModalState(true);
    }
  } else if (actData.action === 'notification') {
    setUpdateModalState({ ...updateModalState, notification: actData.data });
    setCreateNotificationModalState(true);
  } else if (actData.action === 'place') {
    setUpdateModalState({ ...updateModalState, place: actData.data });
    setCreatePlaceModalState(true);
  } else if (actData.action === 'camera') {
    let orgFilter = {
      variables: {
        organization: data.getSubscriptionById.organizationId,
        search: '',
        page: 1,
        limit: 20
      }
    };
    let orgLimitFltr = {
      variables: {
        organization: data.getSubscriptionById.organizationId,
        search: '',
        page: 1,
        limit: 500
      }
    }
    getPlaceList(orgFilter);
    getMLTemplateList(orgLimitFltr);
    getNotificationTemplateList(orgLimitFltr);
    getVpnsList(orgLimitFltr);
    if (
      data.getSubscriptionById.nodeManagmentMode ===
      NODE_MANAGEMENT_TYPE.UNMANAGED
    ) {
      getNodeList(orgFilter);
    }
    setUpdateModalState({ ...updateModalState, camera: actData.data });
    setUpdateCameraModalState(true);
  }
};
const handleCreateNodeSubmit = formData => {
  setNodeModalState({ btnLoading: true, clearData: false });
  formData.orgId = data.getSubscriptionById.organizationId;
  formData.workerCount = parseInt(formData.workerCount);
  formData.workerGpuCount = parseInt(formData.workerGpuCount);
  formData.workerDiskSize = parseInt(formData.workerDiskSize);
  delete formData.cpu;
  delete formData.ram;
  if (!formData.isProvisioned) {
    delete formData.instance_ip;
  }
  return createNode({
    variables: formData
  });
};
const handleSelectAIType = () => {
  setAiTypeModalState(true);
};
const handleSubmitAIType = type => {
  setSelectedAiType(type);
};

const handleCreateMLSubmitClick = formData => {
  if (
    formData.name &&
    formData.type &&
    formData.definition &&
    formData.priority
  ) {
    let model_type;
    if (formData.type == 'heatmap_alert') {
      model_type = 'HeatMap';
    } else if (formData.type == 'people_counting_alert') {
      model_type = 'LineCrossing';
    } else if (formData.type == 'multi_person_alert') {
      model_type = 'MultiplePerson';
    } else if (formData.type == 'behavioural_alert') {
      model_type = 'Behavioural';
    } else if (formData.type == 'multi_object') {
      model_type = 'multi-object';
    } else {
      model_type = formData.type;
    }
    let mlData = {
      name: formData.name,
      model_type: model_type,
      slug: formData.name,
      priority: formData.priority,
      definition: formData.definition,
      organization: data.getSubscriptionById.organizationId,
      provider: formData.provider,
      notification_frequency: formData.notification_frequency,
      lineCrossObjects: {
        lineCrossObjectData: formData.lineCrossObjectData,
        image: formData.lineCrossImage
      }
    };
    setLineCrossDataLoading(false);
    setMLModalState({ btnLoading: true, clearData: false });
    if (formData.id) {
      mlData.id = formData.id;
      return updateMLTemplate({
        variables: mlData
      });
    } else {
      return createNewMLTemplate({
        variables: mlData
      });
    }
  } else {
    return false;
  }
};

const reloadShayonaApisOrPagination = (type, data) => {
  console.log('type', type);
  console.log('data', data);
  if(data){
    setPaginationPage(data);
  }
  setStatus(data);
  if(type === 'truck'){
    getTrucklistByFilter({ variables: { organization: organizationId, limit: 10, page: parseInt(data) || 1 } });
  } else if(type === 'trip'){
    getTripslistByFilter({variables: { organization: organizationId, limit: 10, page: parseInt(data)|| 1, truckNumber: '', startTime: null, endTime: null, status: '', archived: false}});
  } else if(type === 'alert'){
    getAlertslistByFilter({variables: { organization: organizationId, limit: 10, page: parseInt(data)|| 1, truckNumber: '', startTime: null, endTime: null, status: data && data.status }});
  } else if(type === 'brand') {
  getBrandslistByFilter({variables:{organization: organizationId}});
  }
};

const shayonaApisFilterMethod = (type, data) => {
  console.log('type', type);
  console.log('data', data);
 if(type === 'trip'){
    getTripslistByFilter({variables: { organization: organizationId, limit: 10, page: 1, truckNumber: data && data.truckNumber, startTime: data && data.startTime, endTime: data && data.endTime, status: data && data.status, archived: (data && data.archived) || false }});
  } else if(type === 'alert'){
    getAlertslistByFilter({variables: { organization: organizationId, limit: 10, page: 1, truckNumber: data && data.truckNumber, startTime: data && data.startTime, endTime: data && data.endTime, status: data && data.status }});
  } else if(type === 'dashboard'){
    getDasboardDataByFilter({ variables: { organization: organizationId, startTime: data && data.startTime, endTime: data && data.endTime }});
  }
};

const tripsStatusOption = [
  {id: 'entry', name:'Entry'},
  {id: 'slip_printed', name:'Slip Printed'},
  {id: 'tare_weighed',name:'Tare Weighted'},
  {id: 'loading_done',name:'Loading Done'},
  {id: 'exited', name:'Exited'},
  {id: 'loading_stopped',name: 'Loading Stopped'},
  {id: 'loading_started', name:'Loading started'},
  {id: 'loading_resumed', name:'Loading Resumed'}
];

useEffect(() => {
  getDasboardDataByFilter({ variables: { organization: organizationId, startTime: null, endTime: null}});
  getTrucklistByFilter({variables: { organization: organizationId, limit: 10, page: 1 } });
  getTripslistByFilter({variables: { organization: organizationId, limit: 10, page: 1, truckNumber: '', startTime: null, endTime: null, status: '' }});
  getAlertslistByFilter({variables: { organization: organizationId, limit: 10, page: 1, truckNumber: '', startTime: null, endTime: null, status: '' }});
  getBrandslistByFilter({variables:{organization: organizationId}});
  getAuditList({variables:{organization: organizationId}});
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [organizationId]);

const ReCallAPIEvery30Second = () => {
    return getTripslist({variables: { organization: organizationId, limit: 10, page: parseInt(paginationPage), truckNumber: '', startTime: null, endTime: null, status: status && status.status }});
    ;
};
  
React.useEffect(() => {
      const timer = setTimeout(() => {
          setApiTimer(apiTimer + 1);
          ReCallAPIEvery30Second();
  }, 30000);
  return () => {
      clearTimeout(timer);
  };
}, [apiTimer, setPaginationPage, paginationPage]);

const handleCreateURLClick = action => {
  setCreateLiveCamModal(true);
};

return (
  <ShayonaCementsContext.Provider
    value={{
      organizationId,
      data: data.getSubscriptionById,
      shayonaTruckLoading,
      shayonaTruckList,
      reloadShayonaApisOrPagination,
      handleCreateButtonClick,
      handleUpdateAction,
      reloadData: reloadDataState,
      shayonaTripsList,
      shayonaTripsLoading,
      paginationPage,
      handleAddURLSubmitClick,
      handleCreateURLClick,
      edgeAILiveCamURL,
      shayonaApisFilterMethod,
      tripsStatusOption,
      shayonaAlerLoading,
      shayonaAlertsList,
      shayonaDasboardDataLoading,
      shayonaDashboardData,
      cameraLoading,
      cameraList,
      shayonaBrandsData,
      shayonaBrandsLoading,
      auditList,
      shayonaAuditDataLoading,
      goToPage,
      setGoToPage
    }}
  >
    <>
      {children}
      {/**************** EDGE AI MODALS *************** */}
      <React.Fragment>
        <CreateMLTemplateModal
          t={t}
          open={createMLTemplateModal}
          handleCreateMLSubmitClick={handleCreateMLSubmitClick}
          aiTypes={aiTypes}
          providerTypes={providerTypes}
          handleClose={() => {
            setMLModalState({ clearData: false, btnLoading: false });
            setLineCrossObjectState(null);
            setCreateMLModalState(false);
            setLineCrossDataLoading(false);
          }}
          handleSelectAIType={handleSelectAIType}
          selectedAiType={selectedAiType}
          modalState={mlModalState}
          multiObjectOpt={multiObjects}
          accidentDetectionObj={accidentDetectionObj}
          frequencies={edgeAIFrequencies}
        />
        {updateMLTemplateModalState && (
          <UpdateMLTemplateModal
            t={t}
            open={updateMLTemplateModalState}
            handleCreateMLSubmitClick={handleCreateMLSubmitClick}
            data={updateModalState.mlTemplate}
            aiTypes={aiTypes}
            providerTypes={providerTypes}
            lineCrossObject={lineCrossObjectState}
            lineCrossDataLoading={lineCrossDataLoading}
            handleClose={() => {
              setMLModalState({ clearData: false, btnLoading: false });
              setLineCrossObjectState(null);
              setLineCrossDataLoading(false);
              setUpdateMLTemplateModalState(false);
            }}
            selectedAiType={selectedAiType}
            handleSelectAIType={handleSelectAIType}
            modalState={mlModalState}
            dataLoading={updateMlTemplateLoading}
            multiObjectOpt={multiObjects}
            accidentDetectionObj={accidentDetectionObj}
            frequencies={edgeAIFrequencies}
          />
        )}
        <CreateNotificationTemplateModal
          t={t}
          open={createNotificationTemplateModal}
          handleCreateNotificationSubmitClick={
            handleCreateNotificationSubmitClick
          }
          data={updateModalState.notification}
          countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
          handleClose={() => {
            setCreateNotificationModalState(false);
            setNotificationModalState({
              clearData: false,
              btnLoading: false
            });
          }}
          modalState={notificationModalState}
          isForShayona={true}
        />
        <CreatePlaceTemplateModal
          t={t}
          open={createPlaceTemplateModal}
          handleCreatePlaceSubmitClick={handleCreatePlaceSubmitClick}
          data={updateModalState.place}
          handleClose={() => {
            setCreatePlaceModalState(false);
            setPlaceModalState({ clearData: false, btnLoading: false });
          }}
          modalState={placeModalState}
        />
        <CreateCameraTemplateModal
          t={t}
          open={createCameraTemplateModal}
          data={updateModalState.camera}
          handleCreateCameraSubmitClick={handleCreateCameraSubmitClick}
          subscriptionData={data.getSubscriptionById}
          selectePlaceList={selectePlaceList}
          selectMlTemplateList={selectMlTemplateList}
          selectNotiTemplateList={selectNotiTemplateList}
          handleClose={() => {
            setCreateCameraModalState(false);
            setCameraModalState({ clearData: false, btnLoading: false });
          }}
          selecteNodeList={selecteNodeList}
          modalState={cameraModalState}
          VPNSList={VPNSList}
          cameraRSOptions={edgeAICameraResolutions}
          cameraFpsOptions={edgeAICameraFps}
        />
        {updateCameraModalState && (
          <UpdateCameraModal
            t={t}
            open={updateCameraModalState}
            handleUpdateCameraSubmitClick={handleUpdateCameraSubmitClick}
            data={updateModalState.camera}
            subscriptionData={data.getSubscriptionById}
            selectePlaceList={selectePlaceList}
            selectMlTemplateList={selectMlTemplateList}
            selectNotiTemplateList={selectNotiTemplateList}
            handleClose={() => {
              setUpdateCameraModalState(false);
              setCameraModalState({
                clearData: false,
                btnLoading: false
              });
            }}
            selecteNodeList={selecteNodeList}
            modalState={cameraModalState}
            cameraRSOptions={edgeAICameraResolutions}
            cameraFpsOptions={edgeAICameraFps}
          />
        )}
        <CreateLiveCamModal
          t={t}
          open={createLiveCamModal}
          handleAddURLSubmitClick={handleAddURLSubmitClick}
          data={data.getSubscriptionById}
          modalState={liveCamModalState}
          handleClose={() => {
            setCreateLiveCamModal(false);
          }}
        />
        <ImageModal
          t={t}
          open={imageModal}
          imageSrc={imageModalSrc}
          handleClose={() => setImageModalState(false)}
        />
        <SelectAITypeModal
          t={t}
          open={aiTypeModalState}
          aiTypes={aiTypes}
          handleSubmitAIType={handleSubmitAIType}
          handleClose={() => setAiTypeModalState(false)}
        />
        <CreateNodeModal
          t={t}
          open={createNodeModalState}
          modalState={nodeModalState}
          handleCreateNodeSubmit={handleCreateNodeSubmit}
          handleClose={() => {
            setCreateNodeModalState(false);
          }}
        />
        <DeleteConfirmBox
          t={t}
          open={deleteModalState}
          handleClose={handleDeleteConfirmClose}
          type={deleteType}
          loading={deleteEdgeAISubLoading}
          handleDelete={handleConfirmDelete}
        />
      </React.Fragment>
    </>
  </ShayonaCementsContext.Provider>
);
}

export default ShayonaCementsProvider;
