import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import { marginGenerator } from '../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const options = [
  {name: 'Managed', id: 'managed', desc: "This node is auto-assigned by INQ-AI"}, 
  {name: 'Unmanaged', id: 'unmanaged', desc: "This node has to be manually created and assigned to Camera's"}
];

function ButtonComponent(props) {
  const { t, onClick, loading, nodeType } = props;
  return (
    <Button 
      variant="contained"
      color={'primary'}
      onClick={onClick} disabled={loading || !nodeType}>
        {loading && <CircularProgress size={14} />}
        {!loading && t('submit.label')}
    </Button>
  );
}

const useStyles = makeStyles(theme => ({
  nodeDesc: {
    fontSize: 12
  }
}));
function SelectNodeManagementModal({
  t, 
  open, 
  handleClose, 
  handleSelectNodeSubmit,
  btnLoading,
  activateCameraId
}) {
  
  const classes = useStyles();
  const [nodeType, setNodeType] = React.useState('');
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    setNodeType('');
  }, [activateCameraId])
    
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Mode For Node Management</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          onEntering={handleEntering}
          aria-label="ringtone"
          name="ringtone"
          value={nodeType}
          onChange={(evt) => {
            setNodeType(evt.target.value)
          }}
          >
          {options.map((item) => (
            <>
              <FormControlLabel 
              style={{...marginGenerator('mb-8')}} 
              value={item.id} 
              key={item.id} 
              control={<Radio style={{border:'0px', ...marginGenerator('mr-8')}} />} 
              label={item.name} />
              <FormHelperText>{item.desc}</FormHelperText>
            </>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
                handleClose();
            }}
          >
            {t('cancel.label')}
          </Button>
          
          <ButtonComponent 
            t={t}
            nodeType={nodeType}
            loading={btnLoading}
            onClick={(evt) => {
              if(!nodeType){
                Message.error(REQUIRED_ERROR);
                return null;
              }
              handleSelectNodeSubmit(nodeType);
            }}
          />
      </Grid>
      </DialogActions>
    </Dialog>
  );
}

SelectNodeManagementModal.propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSelectNodeSubmit: PropTypes.func.isRequired
};

export default withTranslation()(SelectNodeManagementModal);