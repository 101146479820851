import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useEffect } from 'react';
import { useState } from 'react';
import { USER_ROLES } from "../../../../../../../utils/constants";
import { dateFormatterLLL } from '../../../../../../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';
import moment from 'moment';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const CamtelItem = ({
    t,
    data,
    handleUploadFiles,
    handleSubmitClick,
    CurrentUserName,
    modalState,
    handleClickViewAllDetailsAndAttachment,
    handleProjectAttachmentClick,
    countryList
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    const countryList1 = [
        {
            id: 'Botswana',
            name: "Botswana"
        },
        {
            id: 'Cameroon',
            name: "Cameroon"
        },
        {
            id: 'Ivory Coast',
            name: "Ivory Coast"
        },
        {
            id: 'Malawi',
            name: "Malawi"
        },
        {
            id: 'Nigeria',
            name: "Nigeria"
        },
        {
            id: 'Zambia',
            name: "Zambia"
        },
    ];
    const [ProfilePhoto, setProfilePhoto] = useState('');
    const [Signature, setSignature] = useState('');
    const [Nin, setNin] = useState('');
    const [Surname, setSurname] = useState('');
    const [MiddleName, setMiddleName] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [MotherMaiderName, setMotherMaiderName] = useState('');
    const [MeansOfIdentification, setMeansOfIdentification] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [AlternatePhoneNumber, setAlternatePhoneNumber] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [Nationality, setNationality] = useState('');
    const [DateOfBirth, setDateOfBirth] = useState('');
    const [Address, setAddress] = useState('');
    const [Profession, setProfession] = useState('');
    const [Height, setHeight] = useState('');
    const [Gender, setGender] = useState('');

    useEffect(() => {
        if (modalState.clearData === true) {
            setProfilePhoto('');
            setSignature('');
            setNin('');
            setSurname('');
            setMiddleName('');
            setFirstName('');
            setMotherMaiderName('');
            setMeansOfIdentification('');
            setPhoneNumber('');
            setAlternatePhoneNumber('');
            setEmailAddress('');
            setNationality('');
            setDateOfBirth('');
            setAddress('');
            setProfession('');
            setHeight('');
            setGender('');
        }
    }, [modalState, CurrentUserName, countryList]);
    const allUserList = [
        {
            id: "Male",
            name: "Male"
        },
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Other",
            name: "Other"
        },
    ]
    const IdentifyList = [
        {
            id: "National passport",
            name: "National passport"
        },
        {
            id: "Driving license",
            name: "Driving license"
        },
        {
            id: "Other",
            name: "Other"
        },
    ]
    return (
        <Grid container>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">NIN (National Identification Number)*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={Nin}
                        type={'text'}
                        placeholder={t('NIN (National Identification Number)') + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setNin(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Surname*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={Surname}
                        type={'text'}
                        placeholder={'Surname' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setSurname(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Middle Name*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={MiddleName}
                        type={'text'}
                        placeholder={'Middle Name*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setMiddleName(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">First Name*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={FirstName}
                        type={'text'}
                        placeholder={'First Name' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setFirstName(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Mother Maider Name*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={MotherMaiderName}
                        type={'text'}
                        placeholder={'Mother Maider Name' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setMotherMaiderName(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Phone Number*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={PhoneNumber}
                        type={'text'}
                        placeholder={'Phone Number' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setPhoneNumber(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Alternate Phone Number*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={AlternatePhoneNumber}
                        type={'text'}
                        placeholder={'Alternate Phone Number*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setAlternatePhoneNumber(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Email Address*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={EmailAddress}
                        type={'text'}
                        placeholder={'Email Address' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setEmailAddress(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Address*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={Address}
                        type={'text'}
                        placeholder={'Address' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setAddress(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Profession*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={Profession}
                        type={'text'}
                        placeholder={'Profession' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setProfession(evt.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Height*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        value={Height}
                        type={'text'}
                        placeholder={'Height' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setHeight(evt.target.value)}
                    />
                </Grid>
            </Grid>

            {/* <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Profile Photo*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        type={'file'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => {
                            if (evt.target.files && evt.target.files.length > 0) {
                                let file = evt.target.files[0];
                                setProfilePhoto(file);
                            } else {
                                setProfilePhoto('');
                            }
                        }}
                    />
                </Grid>
            </Grid> */}
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Signature*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <Input
                        type={'file'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => {
                            if (evt.target.files && evt.target.files.length > 0) {
                                let file = evt.target.files[0];
                                setSignature(file);
                            } else {
                                setSignature('');
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <OutlinedSelect
                        label={`${"Gender"} *`}
                        handleChange={evt => {
                            if (evt.target.value) {
                                setGender(evt.target.value);
                            }
                        }}
                        selectStyle={{ ...paddingGenerator('p-8') }}
                        styleOverrides={{ width: '100%', marginBottom: 10 }}
                        options={allUserList ? allUserList : []}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <OutlinedSelect
                        label={`${"Nationality"} *`}
                        handleChange={evt => {
                            if (evt.target.value) {
                                setNationality(evt.target.value);
                            }
                        }}
                        selectStyle={{ ...paddingGenerator('p-8') }}
                        styleOverrides={{ width: '100%', marginBottom: 10 }}
                        options={countryList1 ? countryList1 : []}
                    // options={countryList ? (countryList.getCountries.map(item => item.name)) : []} 
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <OutlinedSelect
                        label={`${"Means Of Identification"} *`}
                        handleChange={evt => {
                            if (evt.target.value) {
                                setMeansOfIdentification(evt.target.value);
                            }
                        }}
                        selectStyle={{ ...paddingGenerator('p-8') }}
                        styleOverrides={{ width: '100%', marginBottom: 10 }}
                        options={IdentifyList ? IdentifyList : []}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: '10px' }}>
                <Typography variant="subtitle2">Date Of Birth*</Typography>
                <Grid
                    style={{
                        ...marginGenerator('mt-5')
                    }}>
                    <div className="datePicker" style={{width:'95%'}}>
                        <DatePicker
                            portalId="root-portal"
                            selected={DateOfBirth}
                            onChange={date => setDateOfBirth(date)}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Please select a date"
                        />
                    </div>
                    {/* <Input
                        value={DateOfBirth}
                        type={'text'}
                        placeholder={'Date Of Birth' + '*'}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setDateOfBirth(evt.target.value)}
                    /> */}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: '10px', textAlign:'center' }}>
                <hr></hr>
                <ButtonComponent
                    t={t}
                    onClick={async () => {
                        if (!Nin || !Surname || !FirstName || !MotherMaiderName || !MeansOfIdentification ||
                            !PhoneNumber || !EmailAddress || !Nationality || !DateOfBirth || !Address || !Profession || !Height || !Gender) {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        // create URL for quatation file
                        let getProfilePhoto = "";
                        // if (ProfilePhoto != "" && ProfilePhoto != null) {
                        //     let getProfilePhotoUrl = await handleUploadFiles(ProfilePhoto, 'camtel_test', 'engineer_team_botswana');
                        //     if (getProfilePhotoUrl.data && getProfilePhotoUrl.data.allUploadFile && getProfilePhotoUrl.data.allUploadFile.url) {
                        //         getProfilePhoto = getProfilePhotoUrl.data.allUploadFile.url;
                        //     }
                        //     else {
                        //         Message.error("File not uploaded! Please try again.");
                        //     }
                        // }
                        // create URL for quatation file
                        let getSignature = "";
                        if (Signature != "" && Signature != null) {
                            let getSignatureUrl = await handleUploadFiles(Signature, 'camtel_test', 'engineer_team_botswana');
                            if (getSignatureUrl.data && getSignatureUrl.data.allUploadFile && getSignatureUrl.data.allUploadFile.url) {
                                getSignature = getSignatureUrl.data.allUploadFile.url;
                            }
                            else {
                                Message.error("File not uploaded! Please try again.");
                            }
                        }
                        handleSubmitClick({
                            nin: Nin,
                            surname: Surname,
                            middle_name: MiddleName,
                            first_name: FirstName,
                            mothers_maider_name: MotherMaiderName,
                            means_of_identification: MeansOfIdentification,
                            phone_number: PhoneNumber,
                            alternate_phone_number: AlternatePhoneNumber,
                            email_address: EmailAddress,
                            nationality: Nationality,
                            date_of_birth: moment(DateOfBirth).format("YYYY-MM-DD"),
                            address: Address,
                            profession: Profession,
                            height: Height,
                            gender: Gender,
                            profile_photo: getProfilePhoto,
                            signature: getSignature,
                        });
                    }}
                    loading={modalState.btnLoading} />
                      <Button
                    style={{marginLeft:'10px'}}
                    variant={'outlined'}
                    onClick={() => {
                        setProfilePhoto('');
                        setSignature('');
                        setNin('');
                        setSurname('');
                        setMiddleName('');
                        setFirstName('');
                        setMotherMaiderName('');
                        setMeansOfIdentification('');
                        setPhoneNumber('');
                        setAlternatePhoneNumber('');
                        setEmailAddress('');
                        setNationality('');
                        setDateOfBirth('');
                        setAddress('');
                        setProfession('');
                        setHeight('');
                        setGender('');
                    }}
                >Reset</Button>
            </Grid>
        </Grid>
    );
};
export default withTranslation()(CamtelItem);
