import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormatterYYYYMMDD } from '../../../../../utils';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateQualityAssuranceTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    preValuesOfForm,
    allUserList,
    CurrentUserName,
    handleUploadFiles,
    handleProjectAttachmentClick,
    handleViewCompleteDetailClick,
    isActionUpdate
}) {
    const classes = useStyles();
    const [changeControlForm, setChangeControlForm] = useState('');
    const [circuitId, setCircuitId] = useState('');
    const [attachMannual, setAttachMannual] = useState('');
    const [configChangeForm, setConfigChangeForm] = useState('');
    const [pingResult, setPingResult] = useState('');
    const [qualityAssuarance, setQualityAssuarance] = useState('');
    const [attachDocument, setAttachDocument] = useState([]);
    const [comment, setComment] = useState('');
    const [projectManagement, setProjectManagement] = useState('');
    const [basestation, setBasestation] = useState('');
    const [location, setLocation] = useState('');
    const [configuredUpCircuit, setConfiguredUpCircuit] = useState('');
    const [configuredDownCircuit, setConfiguredDownCircuit] = useState('');
    const [productClass, setProductClass] = useState('');
    const [node, setNode] = useState('');
    const [status, setStatus] = useState('');
    const [billingDate, setBillingDate] = useState('');
    const [bucketLevel, setBucketLevel] = useState('');
    const [vendor, setVendor] = useState('');
    const [nodeCode, setNodeCode] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [mediaDescription, setMediaDescription] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [platform, setPlatform] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [billing, setBilling] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [documentDeleteLoading, setDocumentDeleteLoading] = useState(false);

    const locationList = [
        { id: 'inq_LAGOS_ZENON_PRI_S_1', name: 'inq_LAGOS_ZENON_PRI_S_1' },
        { id: 'inq_LAGOS_TRADE FAIR_SEC_S_1', name: 'inq_LAGOS_TRADE FAIR_SEC_S_1' },
        { id: 'inq_LAGOS_SWIFT_PRI_S_1', name: 'inq_LAGOS_SWIFT_PRI_S_1' },
        { id: 'inq_LAGOS_SURULERE_PRI_S_1', name: 'inq_LAGOS_SURULERE_PRI_S_1' },
        { id: 'inq_LAGOS_SHASHA_SEC_S_1', name: 'inq_LAGOS_SHASHA_SEC_S_1' },
        { id: 'inq_LAGOS_RACK_CENTRE_PRI_S_1', name: 'inq_LAGOS_RACK_CENTRE_PRI_S_1' },
        { id: 'inq_LAGOS_OJO_PRI_S_1', name: 'inq_LAGOS_OJO_PRI_S_1' },
        { id: 'inq_LAGOS_MEDALION_PRI_S_1', name: 'inq_LAGOS_MEDALION_PRI_S_1' },
        { id: 'inq_LAGOS_MARYLAND_SEC_S_1', name: 'inq_LAGOS_MARYLAND_SEC_S_1' },
        { id: 'inq_LAGOS_LEKKI_PRI_S_1', name: 'inq_LAGOS_LEKKI_PRI_S_1' },
        { id: 'inq_LAGOS_ILUPEJU_SEC_S_1', name: 'inq_LAGOS_ILUPEJU_SEC_S_1' },
        { id: 'inq_LAGOS_IKOYI-OFFICE_PRI_S_1', name: 'inq_LAGOS_IKOYI-OFFICE_PRI_S_1' },
        { id: 'inq_LAGOS_IKOTUN-IDIMU_SEC_S_1', name: 'inq_LAGOS_IKOTUN-IDIMU_SEC_S_1' },
        { id: 'inq_LAGOS_IKOTA-SHOPING-COMPLEX_SEC_S_1', name: 'inq_LAGOS_IKOTA-SHOPING-COMPLEX_SEC_S_1' },
        { id: 'inq_LAGOS_IKORODU_SEC_S_1', name: 'inq_LAGOS_IKORODU_SEC_S_1' },
        { id: 'inq_LAGOS_IGBO-EFON_SEC_S_1', name: 'inq_LAGOS_IGBO-EFON_SEC_S_1' },
        { id: 'inq_LAGOS_IFAKO-IJAIYE_SEC_S_1', name: 'inq_LAGOS_IFAKO-IJAIYE_SEC_S_1' },
        { id: 'inq_LAGOS_GERALD_SEC_S_1', name: 'inq_LAGOS_GERALD_SEC_S_1' },
        { id: 'inq_LAGOS_FESTAC_SEC_S_1', name: 'inq_LAGOS_FESTAC_SEC_S_1' },
        { id: 'inq_LAGOS_EBUTE META_SEC_S_1', name: 'inq_LAGOS_EBUTE META_SEC_S_1' },
        { id: 'inq_LAGOS_BOOKSHOP_PRI_S_1', name: 'inq_LAGOS_BOOKSHOP_PRI_S_1' },
        { id: 'inq_LAGOS_APAPA-PORT_SEC_S_1', name: 'inq_LAGOS_APAPA-PORT_SEC_S_1' },
        { id: 'inq_LAGOS_APAPA_PRI_S_1', name: 'inq_LAGOS_APAPA_PRI_S_1' },
        { id: 'inq_LAGOS_AJAH_PRI_S_1', name: 'inq_LAGOS_AJAH_PRI_S_1' },
        { id: 'inq_LAGOS_AGEGE_PRI_S_1', name: 'inq_LAGOS_AGEGE_PRI_S_1' },
        { id: 'inq_LAGOS_AGBARA_PRI_S_1', name: 'inq_LAGOS_AGBARA_PRI_S_1' },
        { id: 'inq_LAGOS_ABIJO_SEC_S_1', name: 'inq_LAGOS_ABIJO_SEC_S_1' },
        { id: 'inq_OGUN_ABEOBKUTA_PRI_S_1', name: 'inq_OGUN_ABEOBKUTA_PRI_S_1' },
        { id: 'inq_LAGOS_OTTA_PRI_S_1', name: 'inq_LAGOS_OTTA_PRI_S_1' },
        { id: 'inq_OGUN_KKONTEC_ZFS_S_1_NN1_H', name: 'inq_OGUN_KKONTEC_ZFS_S_1_NN1_H' },
        { id: 'inq_OGUN_SHAGAMU_ZFS_S_1_NN1_H', name: 'inq_OGUN_SHAGAMU_ZFS_S_1_NN1_H' },
        { id: 'inq_IBADAN_DUGBE_PRI_S_1', name: 'inq_IBADAN_DUGBE_PRI_S_1' },
        { id: 'inq_IBADAN_ODO-ONA_SEC_S_1', name: 'inq_IBADAN_ODO-ONA_SEC_S_1' },
        { id: 'inq_IBADAN_IWO-ROAD_SEC_S_1', name: 'inq_IBADAN_IWO-ROAD_SEC_S_1' },
        { id: 'inq_IBADAN_ODO-ONA_ZFS_SEC_S_1_V', name: 'inq_IBADAN_ODO-ONA_ZFS_SEC_S_1_V' },
        { id: 'inq_OSHOGBO_IKIRUN-RASCO_PRI_S_1', name: 'inq_OSHOGBO_IKIRUN-RASCO_PRI_S_1' },
        { id: 'inq_AKURE_PRI_S_1', name: 'inq_AKURE_PRI_S_1' },
        { id: 'inq_ILORIN_EMIR_PALACE_PRI_S_1', name: 'inq_ILORIN_EMIR_PALACE_PRI_S_1' },
        { id: 'inq_ILORIN_TIPPER_SEC_S_1', name: 'inq_ILORIN_TIPPER_SEC_S_1' },
        { id: 'inq_KOGI_LOKOJA_PRI_S_1', name: 'inq_KOGI_LOKOJA_PRI_S_1' },
        { id: 'inq_BENIN_IWEGIE_PRI_S_1', name: 'inq_BENIN_IWEGIE_PRI_S_1' },
        { id: 'inq_BENIN_AGBOR_ROAD_SEC_S_1', name: 'inq_BENIN_AGBOR_ROAD_SEC_S_1' },
        { id: 'inq_BENIN_UGBOWO_SEC_S_1', name: 'inq_BENIN_UGBOWO_SEC_S_1' },
        { id: 'inq_SOKOTO_PRI_S_1', name: 'inq_SOKOTO_PRI_S_1' },
        { id: 'inq_EBONYI_ABAKALIKI_PRI_S_1', name: 'inq_EBONYI_ABAKALIKI_PRI_S_1' },
        { id: 'inq_PHC_TELEPORT_PRI_S_1', name: 'inq_PHC_TELEPORT_PRI_S_1' },
        { id: 'inq_PHC_WIMPIE_SEC_S_1', name: 'inq_PHC_WIMPIE_SEC_S_1' },
        { id: 'inq_PHC_ELEME-JUNCTION_SEC_S_1', name: 'inq_PHC_ELEME-JUNCTION_SEC_S_1' },
        { id: 'inq_PHC_ONNE-SEC_S_1', name: 'inq_PHC_ONNE-SEC_S_1' },
        { id: 'inq_PHC_OHIA-ISIAPEDE-RUMUADAOLU_SEC_S_1', name: 'inq_PHC_OHIA-ISIAPEDE-RUMUADAOLU_SEC_S_1' },
        { id: 'inq_PHC_ELEKAHIA-POLICE-STATION_SEC_S_1', name: 'inq_PHC_ELEKAHIA-POLICE-STATION_SEC_S_1' },
        { id: 'inq_PHC_DIOBU-POLICE-STATION_SEC_S_1', name: 'inq_PHC_DIOBU-POLICE-STATION_SEC_S_1' },
        { id: 'inq_PHC_OZOLOBA_SEC_S_1', name: 'inq_PHC_OZOLOBA_SEC_S_1' },
        { id: 'inq_PHC_MTN-EBOCHA_ZFS_S_1_SS1_V', name: 'inq_PHC_MTN-EBOCHA_ZFS_S_1_SS1_V' },
        { id: 'inq_ENUGU_OGUI_ROAD_PRI_S_1', name: 'inq_ENUGU_OGUI_ROAD_PRI_S_1' },
        { id: 'inq_ENUGU_THINKERS_PRI_S_1', name: 'inq_ENUGU_THINKERS_PRI_S_1' },
        { id: 'inq_WARRI_UGBORI_PRI_S_1', name: 'inq_WARRI_UGBORI_PRI_S_1' },
        { id: 'inq_WARRRI_REFINERY_PRI_S_1', name: 'inq_WARRRI_REFINERY_PRI_S_1' },
        { id: 'inq_ABA_OVERAILI_PRI_S_1', name: 'inq_ABA_OVERAILI_PRI_S_1' },
        { id: 'inq_ONITSHA_ST_CHARLES_PRI_S_1', name: 'inq_ONITSHA_ST_CHARLES_PRI_S_1' },
        { id: 'inq_ONITSHA_AWKA_SEC_S_1', name: 'inq_ONITSHA_AWKA_SEC_S_1' },
        { id: 'inq_ASABA_OBI-UGBE-ROAD_SEC_S_1', name: 'inq_ASABA_OBI-UGBE-ROAD_SEC_S_1' },
        { id: 'inq_OWERRI_PRI_S_1', name: 'inq_OWERRI_PRI_S_1' },
        { id: 'inq_CALABAR_PRI_S_1', name: 'inq_CALABAR_PRI_S_1' },
        { id: 'inq_UYO_PRI_S_1', name: 'inq_UYO_PRI_S_1' },
        { id: 'inq_YENEGOA_PRI_S_1', name: 'inq_YENEGOA_PRI_S_1' },
        { id: 'inq_ABUJA_AIRPORT-CHIKA_SEC_S_1', name: 'inq_ABUJA_AIRPORT-CHIKA_SEC_S_1' },
        { id: 'inq_ABUJA_ASOKORO_PRI_S_1', name: 'inq_ABUJA_ASOKORO_PRI_S_1' },
        { id: 'inq_ABUJA_CHRIST_APOSTOLIC_KARU_SEC_S_1', name: 'inq_ABUJA_CHRIST_APOSTOLIC_KARU_SEC_S_1' },
        { id: 'inq_ABUJA_GARKI_SEC_S_1', name: 'inq_ABUJA_GARKI_SEC_S_1' },
        { id: 'inq_ABUJA_HASSAN-MUSA-ASOKORO_SEC_S_1', name: 'inq_ABUJA_HASSAN-MUSA-ASOKORO_SEC_S_1' },
        { id: 'inq_ABUJA_KADO_SEC_S_1', name: 'inq_ABUJA_KADO_SEC_S_1' },
        { id: 'inq_ABUJA_KATAMPE-SEC_S_1', name: 'inq_ABUJA_KATAMPE-SEC_S_1' },
        { id: 'inq_ABUJA_KUBWA_SEC_S_1', name: 'inq_ABUJA_KUBWA_SEC_S_1' },
        { id: 'inq_ABUJA_MAITAMAH_PRI_S_1', name: 'inq_ABUJA_MAITAMAH_PRI_S_1' },
        { id: 'inq_ABUJA_SHERATON-WUSE_SEC_S_1', name: 'inq_ABUJA_SHERATON-WUSE_SEC_S_1' },
        { id: 'inq_ABUJA_WUSE_PRI_S_1', name: 'inq_ABUJA_WUSE_PRI_S_1' },
        { id: 'inq_ABUJA_POLICEFORCE_SHEHU_SHAGARI_SEC_S_1', name: 'inq_ABUJA_POLICEFORCE_SHEHU_SHAGARI_SEC_S_1' },
        { id: 'inq_ABUJA_9MOBILE_ZFS_S_1_EE1_V', name: 'inq_ABUJA_9MOBILE_ZFS_S_1_EE1_V' },
        { id: 'inq_MINNA_BOSS-RD_PRI_S_1', name: 'inq_MINNA_BOSS-RD_PRI_S_1' },
        { id: 'inq_KADUNA_UNGWAN-SANUSI_PRI_S_1', name: 'inq_KADUNA_UNGWAN-SANUSI_PRI_S_1' },
        { id: 'inq_KADUNA_UNGWAN-RIMI_SEC_S_1', name: 'inq_KADUNA_UNGWAN-RIMI_SEC_S_1' },
        { id: 'inq_KADUNA_MAKERAI_SEC_S_1', name: 'inq_KADUNA_MAKERAI_SEC_S_1' },
        { id: 'inq_KADUNA_KACHIA-ROAD_SEC_S_1', name: 'inq_KADUNA_KACHIA-ROAD_SEC_S_1' },
        { id: 'inq_KANO_KAN_RADIO_PRI_S_1', name: 'inq_KANO_KAN_RADIO_PRI_S_1' },
        { id: 'inq_KANO_GWARAN_QUARTERS_PRI_S_1', name: 'inq_KANO_GWARAN_QUARTERS_PRI_S_1' },
        { id: 'inq_KANO_KAWON_QUARTERS_PRI_S_1', name: 'inq_KANO_KAWON_QUARTERS_PRI_S_1' },
        { id: 'inq_KANO_ECNX_ZFS_S_1_SS1_H', name: 'inq_KANO_ECNX_ZFS_S_1_SS1_H' },
        { id: 'inq_MAIDUGURI_PRI_S_1', name: 'inq_MAIDUGURI_PRI_S_1' },
        { id: 'inq_MAKURDI_NEAR-WURUKUN-MARKET_PRI_S_1', name: 'inq_MAKURDI_NEAR-WURUKUN-MARKET_PRI_S_1' },
        { id: 'inq_JOS_AKALI_PRI_S_1', name: 'inq_JOS_AKALI_PRI_S_1' },
        { id: 'inq_JOS_AIRFORCE_SEC_S_1', name: 'inq_JOS_AIRFORCE_SEC_S_1' },
        { id: 'inq_BAUCHI PRI_S_1', name: 'inq_BAUCHI PRI_S_1' },
        { id: 'inq_KATSINA_PRI_S_1', name: 'inq_KATSINA_PRI_S_1' },
        { id: 'inq_GOMBE_PRI_S_1', name: 'inq_GOMBE_PRI_S_1' },
        { id: 'inq_YOLA_PRI_S_1', name: 'inq_YOLA_PRI_S_1' },
    ]
    const baseStationList = [
        { id: 'ZEN-P01', name: 'ZEN-P01' },
        { id: 'TRF-S01', name: 'TRF-S01' },
        { id: 'SWF-S01', name: 'SWF-S01' },
        { id: 'SUR-S01', name: 'SUR-S01' },
        { id: 'SHA-S01', name: 'SHA-S01' },
        { id: 'RAC-P01', name: 'RAC-P01' },
        { id: 'OJO-S01', name: 'OJO-S01' },
        { id: 'MED-P01', name: 'MED-P01' },
        { id: 'MAR-S01', name: 'MAR-S01' },
        { id: 'LEK-S01', name: 'LEK-S01' },
        { id: 'ILU-S01', name: 'ILU-S01' },
        { id: 'IKY-P02', name: 'IKY-P02' },
        { id: 'IKM-S01', name: 'IKM-S01' },
        { id: 'IKT-S01', name: 'IKT-S01' },
        { id: 'IKO-S01', name: 'IKO-S01' },
        { id: 'IGB-S01', name: 'IGB-S01' },
        { id: 'IFJ-S01', name: 'IFJ-S01' },
        { id: 'IKY-P01', name: 'IKY-P01' },
        { id: 'FES-S01', name: 'FES-S01' },
        { id: 'EBU-S01', name: 'EBU-S01' },
        { id: 'BKS-P01', name: 'BKS-P01' },
        { id: 'APA-S02', name: 'APA-S02' },
        { id: 'APA-S01', name: 'APA-S01' },
        { id: 'AJA-S01', name: 'AJA-S01' },
        { id: 'AGE-S01', name: 'AGE-S01' },
        { id: 'AGB-S01', name: 'AGB-S01' },
        { id: 'ABI-S01', name: 'ABI-S01' },
        { id: 'ABE-P01', name: 'ABE-P01' },
        { id: 'OTA-S01', name: 'OTA-S01' },
        { id: 'KKO-SO1', name: 'KKO-SO1' },
        { id: 'SHG-S01', name: 'SHG-S01' },
        { id: 'DUG-P01', name: 'DUG-P01' },
        { id: 'ODO-S01', name: 'ODO-S01' },
        { id: 'IWO-S01', name: 'IWO-S01' },
        { id: 'ODO-ZFS', name: 'ODO-ZFS' },
        { id: 'OSH-P01', name: 'OSH-P01' },
        { id: 'AKU-P01', name: 'AKU-P01' },
        { id: 'ILE-P01', name: 'ILE-P01' },
        { id: 'ILO-S01', name: 'ILO-S01' },
        { id: 'LOK-P01', name: 'LOK-P01' },
        { id: 'IWE-P01', name: 'IWE-P01' },
        { id: 'AGR-S01', name: 'AGR-S01' },
        { id: 'UGW-S01', name: 'UGW-S01' },
        { id: 'SOK-P01', name: 'SOK-P01' },
        { id: 'ABAK-P01', name: 'ABAK-P01' },
        { id: 'GWT-P01', name: 'GWT-P01' },
        { id: 'WIM-S01', name: 'WIM-S01' },
        { id: 'PEJ-S01', name: 'PEJ-S01' },
        { id: 'ONE-S01', name: 'ONE-S01' },
        { id: 'OSI-S01', name: 'OSI-S01' },
        { id: 'POL-S01', name: 'POL-S01' },
        { id: 'DIP-S01', name: 'DIP-S01' },
        { id: 'OZO-S01', name: 'OZO-S01' },
        { id: 'EBO-ZFS', name: 'EBO-ZFS' },
        { id: 'OGU-S01', name: 'OGU-S01' },
        { id: 'THI-S01', name: 'THI-S01' },
        { id: 'UGR-P01', name: 'UGR-P01' },
        { id: 'REF-S01', name: 'REF-S01' },
        { id: 'OVE-S01', name: 'OVE-S01' },
        { id: 'STC-S01', name: 'STC-S01' },
        { id: 'AWK-S01', name: 'AWK-S01' },
        { id: 'UGB-S01', name: 'UGB-S01' },
        { id: 'OWR-P01', name: 'OWR-P01' },
        { id: 'CAL-P01', name: 'CAL-P01' },
        { id: 'UYO-P01', name: 'UYO-P01' },
        { id: 'YEN-P01', name: 'YEN-P01' },
        { id: 'CHI-S01', name: 'CHI-S01' },
        { id: 'ASO-S01', name: 'ASO-S01' },
        { id: 'CHR-S01', name: 'CHR-S01' },
        { id: 'GAR-S01', name: 'GAR-S01' },
        { id: 'SAM-S01', name: 'SAM-S01' },
        { id: 'KAO-P01', name: 'KAO-P01' },
        { id: 'KAM-S01', name: 'KAM-S01' },
        { id: 'KUB-S01', name: 'KUB-S01' },
        { id: 'MAI-P01', name: 'MAI-P01' },
        { id: 'SHW-S01', name: 'SHW-S01' },
        { id: 'WUS-P01', name: 'WUS-P01' },
        { id: 'SSP-S01', name: 'SSP-S01' },
        { id: 'A9M-ZFS', name: 'A9M-ZFS' },
        { id: 'MIN-P01', name: 'MIN-P01' },
        { id: 'SAN-P01', name: 'SAN-P01' },
        { id: 'RIM-S01', name: 'RIM-S01' },
        { id: 'MAK-S01', name: 'MAK-S01' },
        { id: 'KAC-S01', name: 'KAC-S01' },
        { id: 'RAD-P01', name: 'RAD-P01' },
        { id: 'GWA-S01', name: 'GWA-S01' },
        { id: 'KAW-S01', name: 'KAW-S01' },
        { id: 'ECN-ZFS', name: 'ECN-ZFS' },
        { id: 'MAD-P01', name: 'MAD-P01' },
        { id: 'MAU-P01', name: 'MAU-P01' },
        { id: 'AKA-P01', name: 'AKA-P01' },
        { id: 'JOA-S01', name: 'JOA-S01' },
        { id: 'BAU-P01', name: 'BAU-P01' },
        { id: 'KAT-P01', name: 'KAT-P01' },
        { id: 'GOM-P01', name: 'GOM-P01' },
        { id: 'YOL-P01', name: 'YOL-P01' },
    ]
    const bucketLevelList = [
        { id: 'L0-Level 0', name: 'L0-Level 0' },
        { id: 'L1-Level 1', name: 'L1-Level 1' },
        { id: 'L2-Level 2', name: 'L2-Level 2' },
        { id: 'L3-Level 3', name: 'L3-Level 3' },
        { id: 'L4-Level 4', name: 'L4-Level 4' },
        { id: 'L5-Level 5', name: 'L5-Level 5' }
    ]
    const vendorList = [
        { id: '21st Century', name: '21st Century' },
        { id: 'Airtel', name: 'Airtel' },
        { id: 'ATC', name: 'ATC' },
        { id: 'BCN', name: 'BCN' },
        { id: 'BELL', name: 'BELL' },
        { id: 'Broadbase', name: 'Broadbase' },
        { id: 'Cable  & Wireless', name: 'Cable  & Wireless' },
        { id: 'Colt', name: 'Colt' },
        { id: "Customer's fiber", name: "Customer's fiber" },
        { id: 'EMTS', name: 'EMTS' },
        { id: 'CEDARVIEW', name: 'CEDARVIEW' },
        { id: 'Epslon', name: 'Epslon' },
        { id: 'Glo 1', name: 'Glo 1' },
        { id: 'Global crossing', name: 'Global crossing' },
        { id: 'Helos', name: 'Helos' },
        { id: 'IHS', name: 'IHS' },
        { id: 'INQ', name: 'INQ' },
        { id: 'IPNX', name: 'IPNX' },
        { id: 'KKONTECH', name: 'KKONTECH' },
        { id: 'KPN', name: 'KPN' },
        { id: 'Layer 3', name: 'Layer 3' },
        { id: 'Mainone', name: 'Mainone' },
        { id: 'Mobax', name: 'Mobax' },
        { id: 'MTI', name: 'MTI' },
        { id: 'MTN', name: 'MTN' },
        { id: 'Multilinks', name: 'Multilinks' },
        { id: 'PCCW', name: 'PCCW' },
        { id: 'Phase 3', name: 'Phase 3' },
        { id: 'Swap Technologies', name: 'Swap Technologies' },
        { id: 'TATA', name: 'TATA' },
        { id: 'VDT', name: 'VDT' },
        { id: 'Wasek', name: 'Wasek' },
    ];
    const nodeList = [
        { id: 'Aba PE', name: 'Aba PE' },
        { id: 'Aba Wib', name: 'Aba Wib' },
        { id: 'Abakaliki PE', name: 'Abakaliki PE' },
        { id: 'Abeokuta PE', name: 'Abeokuta PE' },
        { id: 'Abeokuta Wib', name: 'Abeokuta Wib' },
        { id: 'ABJ PE01', name: 'ABJ PE01' },
        { id: 'ABJ PE02', name: 'ABJ PE02' },
        { id: 'ABJ PE03', name: 'ABJ PE03' },
        { id: 'Abuja POP wib', name: 'Abuja POP wib' },
        { id: 'Abuja Wib', name: 'Abuja Wib' },
        { id: 'Agbara PE01', name: 'Agbara PE01' },
        { id: 'Agege PE', name: 'Agege PE' },
        { id: 'Ajah PE', name: 'Ajah PE' },
        { id: 'Akure PE', name: 'Akure PE' },
        { id: 'Akure Wib', name: 'Akure Wib' },
        { id: 'Apapa PE01', name: 'Apapa PE01' },
        { id: 'Bauchi PE', name: 'Bauchi PE' },
        { id: 'Bauchi Wib', name: 'Bauchi Wib' },
        { id: 'Benin PE', name: 'Benin PE' },
        { id: 'Benin Wib', name: 'Benin Wib' },
        { id: 'Bookshop PE', name: 'Bookshop PE' },
        { id: 'Bookshop Wib', name: 'Bookshop Wib' },
        { id: 'Calabar PE', name: 'Calabar PE' },
        { id: 'Calabar Wib', name: 'Calabar Wib' },
        { id: 'Chalfont 2902', name: 'Chalfont 2902' },
        { id: 'Chalfont 7470', name: 'Chalfont 7470' },
        { id: 'Damaturu PE', name: 'Damaturu PE' },
        { id: 'Dutse PE', name: 'Dutse PE' },
        { id: 'Eket PE', name: 'Eket PE' },
        { id: 'Eket Wib', name: 'Eket Wib' },
        { id: 'Ekiti PE', name: 'Ekiti PE' },
        { id: 'Enugu PE', name: 'Enugu PE' },
        { id: 'Enugu Wib', name: 'Enugu Wib' },
        { id: 'Gombe PE', name: 'Gombe PE' },
        { id: 'Gombe Wib', name: 'Gombe Wib' },
        { id: 'GTW PHC Wib', name: 'GTW PHC Wib' },
        { id: 'Gusau PE', name: 'Gusau PE' },
        { id: 'Ibadan PE', name: 'Ibadan PE' },
        { id: 'Ibadan Wib', name: 'Ibadan Wib' },
        { id: 'Ikeja 3601', name: 'Ikeja 3601' },
        { id: 'Ikeja 7204', name: 'Ikeja 7204' },
        { id: 'Ikeja 7401', name: 'Ikeja 7401' },
        { id: 'Ikeja PE', name: 'Ikeja PE' },
        { id: 'Ikeja Wib', name: 'Ikeja Wib' },
        { id: 'Ikoyi 7470', name: 'Ikoyi 7470' },
        { id: 'Ikoyi PE', name: 'Ikoyi PE' },
        { id: 'Ikoyi Wib', name: 'Ikoyi Wib' },
        { id: 'Ilorin PE', name: 'Ilorin PE' },
        { id: 'Ilroin Wib', name: 'Ilroin Wib' },
        { id: 'Ilupeju PE', name: 'Ilupeju PE' },
        { id: 'Jalingo PE', name: 'Jalingo PE' },
        { id: 'Jos PE', name: 'Jos PE' },
        { id: 'Jos Wib', name: 'Jos Wib' },
        { id: 'Kaduna PE', name: 'Kaduna PE' },
        { id: 'Kaduna Wib', name: 'Kaduna Wib' },
        { id: 'Kano PE', name: 'Kano PE' },
        { id: 'Kano Wib', name: 'Kano Wib' },
        { id: 'Katsina PE', name: 'Katsina PE' },
        { id: 'Katsina WIB', name: 'Katsina WIB' },
        { id: 'Kebbi PE', name: 'Kebbi PE' },
        { id: 'Lafia PE', name: 'Lafia PE' },
        { id: 'Lekki PE', name: 'Lekki PE' },
        { id: 'Lokoja PE', name: 'Lokoja PE' },
        { id: 'Lokoja Wib', name: 'Lokoja Wib' },
        { id: 'Maiduguri PE', name: 'Maiduguri PE' },
        { id: 'Maiduguri Wib', name: 'Maiduguri Wib' },
        { id: 'Makurdi PE', name: 'Makurdi PE' },
        { id: 'Makurdi Wib', name: 'Makurdi Wib' },
        { id: 'MED PE01', name: 'MED PE01' },
        { id: 'Minna PE', name: 'Minna PE' },
        { id: 'Minna Wib', name: 'Minna Wib' },
        { id: 'Mobile 7270', name: 'Mobile 7270' },
        { id: 'Monacom PE', name: 'Monacom PE' },
        { id: 'Ojo PE', name: 'Ojo PE' },
        { id: 'Onitsha PE', name: 'Onitsha PE' },
        { id: 'Onitsha Wib', name: 'Onitsha Wib' },
        { id: 'Osogbo PE', name: 'Osogbo PE' },
        { id: 'Osogbo Wib', name: 'Osogbo Wib' },
        { id: 'Otta PE', name: 'Otta PE' },
        { id: 'Owerri PE', name: 'Owerri PE' },
        { id: 'Owerri Wib', name: 'Owerri Wib' },
        { id: 'PHC 3600', name: 'PHC 3600' },
        { id: 'PHC 7270', name: 'PHC 7270' },
        { id: 'PHC PE02', name: 'PHC PE02' },
        { id: 'PHC PE03', name: 'PHC PE03' },
        { id: 'PHC Wib', name: 'PHC Wib' },
        { id: 'RAC PE', name: 'RAC PE' },
        { id: 'RAC Wib', name: 'RAC Wib' },
        { id: 'SAK PE01', name: 'SAK PE01' },
        { id: 'Sokoto PE', name: 'Sokoto PE' },
        { id: 'Sokoto Wib', name: 'Sokoto Wib' },
        { id: 'Stallion 3601', name: 'Stallion 3601' },
        { id: 'Stallion 7206', name: 'Stallion 7206' },
        { id: 'Stallion 7401', name: 'Stallion 7401' },
        { id: 'Stallion 7470', name: 'Stallion 7470' },
        { id: 'Stallion PE', name: 'Stallion PE' },
        { id: 'Stallion Wib', name: 'Stallion Wib' },
        { id: 'Surulere PE01', name: 'Surulere PE01' },
        { id: 'Swift - PE01', name: 'Swift - PE01' },
        { id: 'Telehouse 7270', name: 'Telehouse 7270' },
        { id: 'Telehouse 7470', name: 'Telehouse 7470' },
        { id: 'Uyo PE', name: 'Uyo PE' },
        { id: 'Uyo Wib', name: 'Uyo Wib' },
        { id: 'Warri PE', name: 'Warri PE' },
        { id: 'Warri Wib', name: 'Warri Wib' },
        { id: 'Wimpie Wib', name: 'Wimpie Wib' },
        { id: 'Yenegoa PE', name: 'Yenegoa PE' },
        { id: 'Yenegoa Wib', name: 'Yenegoa Wib' },
        { id: 'Yola PE', name: 'Yola PE' },
        { id: 'Zenon 3601', name: 'Zenon 3601' },
        { id: 'Zenon 7206', name: 'Zenon 7206' },
        { id: 'Zenon 7401', name: 'Zenon 7401' },
        { id: 'Zenon 7470', name: 'Zenon 7470' },
        { id: 'Zenon PE', name: 'Zenon PE' },
        { id: 'Zenon Wib', name: 'Zenon Wib' },
    ]
    const nodeCodeList = [
        { id: 'ABA', name: 'ABA' },
        { id: 'ABAK', name: 'ABAK' },
        { id: 'ABE', name: 'ABE' },
        { id: 'ABU', name: 'ABU' },
        { id: 'ADO', name: 'ADO' },
        { id: 'AGB', name: 'AGB' },
        { id: 'AGE', name: 'AGE' },
        { id: 'AJA', name: 'AJA' },
        { id: 'AKU', name: 'AKU' },
        { id: 'APA', name: 'APA' },
        { id: 'BAU', name: 'BAU' },
        { id: 'BED', name: 'BED' },
        { id: 'BEN', name: 'BEN' },
        { id: 'BIR', name: 'BIR' },
        { id: 'BKS', name: 'BKS' },
        { id: 'CAL', name: 'CAL' },
        { id: 'CBN', name: 'CBN' },
        { id: 'CHA', name: 'CHA' },
        { id: 'CUS', name: 'CUS' },
        { id: 'DAM', name: 'DAM' },
        { id: 'DUT', name: 'DUT' },
        { id: 'EKE', name: 'EKE' },
        { id: 'ENU', name: 'ENU' },
        { id: 'GOM', name: 'GOM' },
        { id: 'GUS', name: 'GUS' },
        { id: 'IBA', name: 'IBA' },
        { id: 'IKJ', name: 'IKJ' },
        { id: 'IKY', name: 'IKY' },
        { id: 'ILR', name: 'ILR' },
        { id: 'JAL', name: 'JAL' },
        { id: 'JOS', name: 'JOS' },
        { id: 'KAD', name: 'KAD' },
        { id: 'KAN', name: 'KAN' },
        { id: 'KAT', name: 'KAT' },
        { id: 'LAF', name: 'LAF' },
        { id: 'LEK', name: 'LEK' },
        { id: 'LOK', name: 'LOK' },
        { id: 'MAI', name: 'MAI' },
        { id: 'MAK', name: 'MAK' },
        { id: 'MED', name: 'MED' },
        { id: 'MID', name: 'MID' },
        { id: 'MIN', name: 'MIN' },
        { id: 'MON', name: 'MON' },
        { id: 'NET', name: 'NET' },
        { id: 'OJO', name: 'OJO' },
        { id: 'ONT', name: 'ONT' },
        { id: 'OSO', name: 'OSO' },
        { id: 'OTA', name: 'OTA' },
        { id: 'OWR', name: 'OWR' },
        { id: 'PHC', name: 'PHC' },
        { id: 'RAC', name: 'RAC' },
        { id: 'SAK', name: 'SAK' },
        { id: 'SOK', name: 'SOK' },
        { id: 'SUR', name: 'SUR' },
        { id: 'SWF', name: 'SWF' },
        { id: 'TLN', name: 'TLN' },
        { id: 'UYO', name: 'UYO' },
        { id: 'WAR', name: 'WAR' },
        { id: 'WAS', name: 'WAS' },
        { id: 'YEN', name: 'YEN' },
        { id: 'YOL', name: 'YOL' },
        { id: 'ZEN', name: 'ZEN' },
    ]
    const productDescriptionList = [
        { id: 'Broadband Wireless LTE', name: 'Broadband Wireless LTE' },
        { id: 'Satellite Internet Contentioned', name: 'Satellite Internet Contentioned' },
        { id: 'Satellite VPN Contentioned', name: 'Satellite VPN Contentioned' },
        { id: 'Satellite VPN uncontentioned', name: 'Satellite VPN uncontentioned' },
        { id: 'Satellite WAN VPN', name: 'Satellite WAN VPN' },
        { id: 'Terrestrial Backhaul', name: 'Terrestrial Backhaul' },
        { id: 'Terrestrial Internet Contentioned', name: 'Terrestrial Internet Contentioned' },
        { id: 'Terrestrial Internet Uncontentioned', name: 'Terrestrial Internet Uncontentioned' },
        { id: 'Terrestrial VPN', name: 'Terrestrial VPN' },
        { id: 'Voice', name: 'Voice' },
        { id: 'WAN VPN', name: 'WAN VPN' },
        { id: 'INQ IOT Bundle', name: 'INQ IOT Bundle' },
    ]
    const mediaDescriptionList = [
        { id: 'Broadband-LTE', name: 'Broadband-LTE' },
        {id: 'EDGE AI',name: "EDGE_AI"},
        { id: 'Ceragon', name: 'Ceragon' },
        { id: 'Fibre', name: 'Fibre' },
        { id: 'RADWIN (P2M)', name: 'RADWIN (P2M)' },
        { id: 'RADWIN (P2P)', name: 'RADWIN (P2P)' },
        { id: 'MIMOSA', name: 'MIMOSA' },
        { id: 'Fibre/VSAT-Alcatel', name: 'Fibre/VSAT-Alcatel' },
        { id: 'Fibre-Alcatel', name: 'Fibre-Alcatel' },
        { id: 'Fibre-MPLS', name: 'Fibre-MPLS' },
        { id: 'Microwave/NEC radio', name: 'Microwave/NEC radio' },
        { id: 'Microwave/Fibre-Alcatel', name: 'Microwave/Fibre-Alcatel' },
        { id: 'Microwave/Radwin', name: 'Microwave/Radwin' },
        { id: 'Microwabe/VSAT-Alcatel', name: 'Microwabe/VSAT-Alcatel' },
        { id: 'Microwave -BWA', name: 'Microwave -BWA' },
        { id: 'Microwave-Proxim (P2M)', name: 'Microwave-Proxim (P2M)' },
        { id: 'Microwave-Proxim (P2P)', name: 'Microwave-Proxim (P2P)' },
        { id: 'Microwave - SAF', name: 'Microwave - SAF' },
        { id: 'VSAT/Fibre - Alcatel', name: 'VSAT/Fibre - Alcatel' },
        { id: 'VSAT - IDIRECT', name: 'VSAT - IDIRECT' },
        { id: 'VSAT SCPC', name: 'VSAT SCPC' },
    ]
    const platformList = [
        { id: 'Alcatel', name: 'Alcatel' },
        {id: 'EDGE AI',name: "EDGE_AI"},
        { id: 'BWA', name: 'BWA' },
        { id: 'Cable', name: 'Cable' },
        { id: 'Ceragon', name: 'Ceragon' },
        { id: 'Cloud Services', name: 'Cloud Services' },
        { id: 'ECI', name: 'ECI' },
        { id: 'Fibre', name: 'Fibre' },
        { id: 'Hosted Services', name: 'Hosted Services' },
        { id: 'LTE', name: 'LTE' },
        { id: 'M2M', name: 'M2M' },
        { id: 'Nec Radio', name: 'Nec Radio' },
        { id: 'Proxim', name: 'Proxim' },
        { id: 'RADWIN (P2M)', name: 'RADWIN (P2M)' },
        { id: 'RADWIN (P2P)', name: 'RADWIN (P2P)' },
        { id: 'MIMOSA', name: 'MIMOSA' },
        { id: 'SAF', name: 'SAF' },
        { id: 'Trunk', name: 'Trunk' },
        { id: 'VSAT -IDIRECT', name: 'VSAT -IDIRECT' },
        { id: 'VSAT - SCPC', name: 'VSAT - SCPC' },
        { id: 'Wifi Access', name: 'Wifi Access' },
    ]
    const productClassList = [
        { id: '3G Backhaul', name: '3G Backhaul' },
        { id: 'Africa IPConnect', name: 'Africa IPConnect' },
        { id: 'Africa SCPC Connect', name: 'Africa SCPC Connect' },
        { id: 'AfricaMPLS Connect', name: 'AfricaMPLS Connect' },
        { id: 'AfricaPLconnect', name: 'AfricaPLconnect' },
        { id: 'Airlink', name: 'Airlink' },
        { id: 'ATMConnect', name: 'ATMConnect' },
        { id: 'ATMLink', name: 'ATMLink' },
        { id: 'Backhaul', name: 'Backhaul' },
        { id: 'Business connect', name: 'Business connect' },
        { id: 'Business Express', name: 'Business Express' },
        { id: 'Dedicated Hosting', name: 'Dedicated Hosting' },
        { id: 'Edulink Basic', name: 'Edulink Basic' },
        { id: 'Edulink Pro', name: 'Edulink Pro' },
        { id: 'Edulink Super', name: 'Edulink Super' },
        { id: 'Email Security', name: 'Email Security' },
        { id: 'Hosted Exchange', name: 'Hosted Exchange' },
        { id: 'Hosted Microsoft OCS', name: 'Hosted Microsoft OCS' },
        { id: 'Hosted SAP', name: 'Hosted SAP' },
        { id: 'Hosted Sharepoint', name: 'Hosted Sharepoint' },
        { id: 'Hosting x-press(Basic Web Hosting)', name: 'Hosting x-press(Basic Web Hosting)' },
        { id: 'Inter. AfricaConnect', name: 'Inter. AfricaConnect' },
        { id: 'IPJET', name: 'IPJET' },
        { id: 'Licensing', name: 'Licensing' },
        { id: 'LTELink', name: 'LTELink' },
        { id: 'Managed Backup', name: 'Managed Backup' },
        { id: 'Managed Virtual Hosting', name: 'Managed Virtual Hosting' },
        { id: 'Metrolink', name: 'Metrolink' },
        { id: 'Nat. AfricaConnect', name: 'Nat. AfricaConnect' },
        { id: 'Nat. Voice', name: 'Nat. Voice' },
        { id: 'Network Security', name: 'Network Security' },
        { id: 'Online Backup(Client)', name: 'Online Backup(Client)' },
        { id: 'Online Backup(Server)', name: 'Online Backup(Server)' },
        { id: 'Operation and Maintenance', name: 'Operation and Maintenance' },
        { id: 'Perimeter Security', name: 'Perimeter Security' },
        { id: 'POSLink', name: 'POSLink' },
        { id: 'SimExpress', name: 'SimExpress' },
        { id: 'Trunk', name: 'Trunk' },
        { id: 'VLINK', name: 'VLINK' },
        { id: 'Voice', name: 'Voice' },
        { id: 'WAN VPN', name: 'WAN VPN' },
    ]
    const statusList = [
        { id: 'Active', name: 'Active' },
        { id: 'Deactivated', name: 'Deactivated' },
        { id: 'Decommissioned', name: 'Decommissioned' },
        { id: 'Duplicate', name: 'Duplicate' },
        { id: 'Inactive', name: 'Inactive' },
    ]
    const serviceDescriptionList = [
        { id: 'AFRICASAT-ABJ', name: 'AFRICASAT-ABJ' },
        { id: 'AFRICASAT-LAG', name: 'AFRICASAT-LAG' },
        { id: 'AFRICASAT-PHC', name: 'AFRICASAT-PHC' },
        { id: 'INTERNATIONAL', name: 'INTERNATIONAL' },
        { id: 'INTERNATIONAL', name: 'INTERNATIONAL' },
        { id: 'International Backhaul', name: 'International Backhaul' },
        { id: 'Internet VRF', name: 'Internet VRF' },
        { id: 'LOCAL', name: 'LOCAL' },
        { id: 'Vodacom Wirelessconnect', name: 'Vodacom Wirelessconnect' },
    ]
    const regionList = [
        { id: 'ANGOLA', name: 'ANGOLA' },
        { id: 'CA', name: 'CA' },
        { id: 'EAA', name: 'EAA' },
        { id: 'GHANA', name: 'GHANA' },
        { id: 'GUINEA', name: 'GUINEA' },
        { id: 'MALAWI', name: 'MALAWI' },
        { id: 'MALI', name: 'MALI' },
        { id: 'NIGER', name: 'NIGER' },
        { id: 'Nigeria', name: 'Nigeria' },
        { id: 'SA', name: 'SA' },
        { id: 'WNA', name: 'WNA' },
    ]
    const cityList = [
        { id: 'AA - Aba', name: 'AA - Aba' },
        { id: 'AB - Abuja ', name: 'AB - Abuja ' },
        { id: 'AE - Abeokuta', name: 'AE - Abeokuta' },
        { id: 'AJ - Abijan', name: 'AJ - Abijan' },
        { id: 'AK - Akure', name: 'AK - Akure' },
        { id: 'AL - Abakaliki', name: 'AL - Abakaliki' },
        { id: 'AS - Asaba', name: 'AS - Asaba' },
        { id: 'BA - Bauchi', name: 'BA - Bauchi' },
        { id: 'BE - Benin', name: 'BE - Benin' },
        { id: 'BI - Birnin Kebbi', name: 'BI - Birnin Kebbi' },
        { id: 'CA - Calabar', name: 'CA - Calabar' },
        { id: 'DA - Damaturu', name: 'DA - Damaturu' },
        { id: 'DU - Dutse', name: 'DU - Dutse' },
        { id: 'EK - Eket', name: 'EK - Eket' },
        { id: 'EN - Enugu', name: 'EN - Enugu' },
        { id: 'GO - Gombe', name: 'GO - Gombe' },
        { id: 'GU - Gusau', name: 'GU - Gusau' },
        { id: 'IB - Ibadan', name: 'IB - Ibadan' },
        { id: 'IL - Ilorin', name: 'IL - Ilorin' },
        { id: 'JA - Jalingo', name: 'JA - Jalingo' },
        { id: 'JB - Johannesburg', name: 'JB - Johannesburg' },
        { id: 'JO - Jos', name: 'JO - Jos' },
        { id: 'KA - Katsina', name: 'KA - Katsina' },
        { id: 'KD - Kaduna', name: 'KD - Kaduna' },
        { id: 'KN - Kano', name: 'KN - Kano' },
        { id: 'LA - Lagos', name: 'LA - Lagos' },
        { id: 'LF - Lafia', name: 'LF - Lafia' },
        { id: 'LO - Lokoja', name: 'LO - Lokoja' },
        { id: 'MA- Maiduguri', name: 'MA- Maiduguri' },
        { id: 'MI - Minna', name: 'MI - Minna' },
        { id: 'MK - Makurdi', name: 'MK - Makurdi' },
        { id: 'ON - Onitsha', name: 'ON - Onitsha' },
        { id: 'OS - Osogbo', name: 'OS - Osogbo' },
        { id: 'OW - Owerri', name: 'OW - Owerri' },
        { id: 'PH - Port Harcourt', name: 'PH - Port Harcourt' },
        { id: 'SO - Sokoto', name: 'SO - Sokoto' },
        { id: 'UK - London', name: 'UK - London' },
        { id: 'US - USA', name: 'US - USA' },
        { id: 'UY - Uyo', name: 'UY - Uyo' },
        { id: 'WA - Warri', name: 'WA - Warri' },
        { id: 'YE - Yenegoa', name: 'YE - Yenegoa' },
        { id: 'YO - Yola', name: 'YO - Yola' },
    ]
    const configuredDownList = [
        { id: '0', name: '0' },
        { id: '0.5', name: '0.5' },
        { id: '1 x STM1', name: '1 x STM1' },
        { id: '10', name: '10' },
        { id: '100000', name: '100000' },
        { id: '1024', name: '1024' },
        { id: '10240', name: '10240' },
        { id: '11264', name: '11264' },
        { id: '11 x STM1', name: '11 x STM1' },
        { id: '12000', name: '12000' },
        { id: '1216', name: '1216' },
        { id: '12288', name: '12288' },
        { id: '122880', name: '122880' },
        { id: '128', name: '128' },
        { id: '12888', name: '12888' },
        { id: '13 x STM1', name: '13 x STM1' },
        { id: '13312', name: '13312' },
        { id: '14336', name: '14336' },
        { id: '148', name: '148' },
        { id: '1534', name: '1534' },
        { id: '1536', name: '1536' },
        { id: '15360', name: '15360' },
        { id: '1544', name: '1544' },
        { id: '155', name: '155' },
        { id: '158720', name: '158720' },
        { id: '16', name: '16' },
        { id: '160', name: '160' },
        { id: '16384', name: '16384' },
        { id: '1648', name: '1648' },
        { id: '17408', name: '17408' },
        { id: '17920', name: '17920' },
        { id: '18432', name: '18432' },
        { id: '1856', name: '1856' },
        { id: '19.2', name: '19.2' },
        { id: '192', name: '192' },
        { id: '1920', name: '1920' },
        { id: '19456', name: '19456' },
        { id: '1984', name: '1984' },
        { id: '1Gbps', name: '1Gbps' },
        { id: '1 x E1', name: '1 x E1' },
        { id: '2 x STM1', name: '2 x STM1' },
        { id: '200', name: '200' },
        { id: '2000', name: '2000' },
        { id: '2048', name: '2048' },
        { id: '20480', name: '20480' },
        { id: '21504', name: '21504' },
        { id: '22528', name: '22528' },
        { id: '23552', name: '23552' },
        { id: '24576', name: '24576' },
        { id: '25 x STM1', name: '25 x STM1' },
        { id: '254', name: '254' },
        { id: '256', name: '256' },
        { id: '25600', name: '25600' },
        { id: '26624', name: '26624' },
        { id: '27648', name: '27648' },
        { id: '28672', name: '28672' },
        { id: '29696', name: '29696' },
        { id: '3 x STM1', name: '3 x STM1' },
        { id: '300', name: '300' },
        { id: '3072', name: '3072' },
        { id: '30720', name: '30720' },
        { id: '3092', name: '3092' },
        { id: '3096', name: '3096' },
        { id: '31744', name: '31744' },
        { id: '32', name: '32' },
        { id: '32 x STM1', name: '32 x STM1' },
        { id: '320', name: '320' },
        { id: '32768', name: '32768' },
        { id: '33792', name: '33792' },
        { id: '34816', name: '34816' },
        { id: '3500', name: '3500' },
        { id: '35840', name: '35840' },
        { id: '36864', name: '36864' },
        { id: '37888', name: '37888' },
        { id: '384', name: '384' },
        { id: '38912', name: '38912' },
        { id: '39936', name: '39936' },
        { id: '4 x STM1', name: '4 x STM1' },
        { id: '4096', name: '4096' },
        { id: '40960', name: '40960' },
        { id: '4196', name: '4196' },
        { id: '41984', name: '41984' },
        { id: '43008', name: '43008' },
        { id: '44032', name: '44032' },
        { id: '448', name: '448' },
        { id: '45056', name: '45056' },
        { id: '46080', name: '46080' },
        { id: '4800', name: '4800' },
        { id: '4 X E1', name: '4 X E1' },
        { id: '5 x STM1', name: '5 x STM1' },
        { id: '512', name: '512' },
        { id: '5120', name: '5120' },
        { id: '51200', name: '51200' },
        { id: '5 X E1', name: '5 X E1' },
        { id: '6 x STM1', name: '6 x STM1' },
        { id: '6000', name: '6000' },
        { id: '6016', name: '6016' },
        { id: '6072', name: '6072' },
        { id: '61', name: '61' },
        { id: '6144', name: '6144' },
        { id: '61440', name: '61440' },
        { id: '64', name: '64' },
        { id: '640', name: '640' },
        { id: '7 x STM1', name: '7 x STM1' },
        { id: '7000', name: '7000' },
        { id: '7168', name: '7168' },
        { id: '75000', name: '75000' },
        { id: '768', name: '768' },
        { id: '8 x STM1', name: '8 x STM1' },
        { id: '8000', name: '8000' },
        { id: '80920', name: '80920' },
        { id: '8096', name: '8096' },
        { id: '8192', name: '8192' },
        { id: '8196', name: '8196' },
        { id: '9.6', name: '9.6' },
        { id: '9216', name: '9216' },
        { id: '92160', name: '92160' },
        { id: '96', name: '96' },
        { id: 'RED', name: 'RED' },
    ]
    const configuredUpList = [
        { id: '0', name: '0' },
        { id: '0.5', name: '0.5' },
        { id: '1 x STM1', name: '1 x STM1' },
        { id: '10', name: '10' },
        { id: '100000', name: '100000' },
        { id: '1024', name: '1024' },
        { id: '10240', name: '10240' },
        { id: '11264', name: '11264' },
        { id: '11 x STM1', name: '11 x STM1' },
        { id: '12000', name: '12000' },
        { id: '1216', name: '1216' },
        { id: '12288', name: '12288' },
        { id: '122880', name: '122880' },
        { id: '128', name: '128' },
        { id: '12888', name: '12888' },
        { id: '13 x STM1', name: '13 x STM1' },
        { id: '13312', name: '13312' },
        { id: '14336', name: '14336' },
        { id: '148', name: '148' },
        { id: '1534', name: '1534' },
        { id: '1536', name: '1536' },
        { id: '15360', name: '15360' },
        { id: '1544', name: '1544' },
        { id: '155', name: '155' },
        { id: '158720', name: '158720' },
        { id: '16', name: '16' },
        { id: '160', name: '160' },
        { id: '16384', name: '16384' },
        { id: '1648', name: '1648' },
        { id: '17408', name: '17408' },
        { id: '17920', name: '17920' },
        { id: '18432', name: '18432' },
        { id: '1856', name: '1856' },
        { id: '19.2', name: '19.2' },
        { id: '192', name: '192' },
        { id: '1920', name: '1920' },
        { id: '19456', name: '19456' },
        { id: '1984', name: '1984' },
        { id: '1Gbps', name: '1Gbps' },
        { id: '1 x E1', name: '1 x E1' },
        { id: '2 x STM1', name: '2 x STM1' },
        { id: '200', name: '200' },
        { id: '2000', name: '2000' },
        { id: '2048', name: '2048' },
        { id: '20480', name: '20480' },
        { id: '21504', name: '21504' },
        { id: '22528', name: '22528' },
        { id: '23552', name: '23552' },
        { id: '24576', name: '24576' },
        { id: '25 x STM1', name: '25 x STM1' },
        { id: '254', name: '254' },
        { id: '256', name: '256' },
        { id: '25600', name: '25600' },
        { id: '26624', name: '26624' },
        { id: '27648', name: '27648' },
        { id: '28672', name: '28672' },
        { id: '29696', name: '29696' },
        { id: '3 x STM1', name: '3 x STM1' },
        { id: '300', name: '300' },
        { id: '3072', name: '3072' },
        { id: '30720', name: '30720' },
        { id: '3092', name: '3092' },
        { id: '3096', name: '3096' },
        { id: '31744', name: '31744' },
        { id: '32', name: '32' },
        { id: '32 x STM1', name: '32 x STM1' },
        { id: '320', name: '320' },
        { id: '32768', name: '32768' },
        { id: '33792', name: '33792' },
        { id: '34816', name: '34816' },
        { id: '3500', name: '3500' },
        { id: '35840', name: '35840' },
        { id: '36864', name: '36864' },
        { id: '37888', name: '37888' },
        { id: '384', name: '384' },
        { id: '38912', name: '38912' },
        { id: '39936', name: '39936' },
        { id: '4 x STM1', name: '4 x STM1' },
        { id: '4096', name: '4096' },
        { id: '40960', name: '40960' },
        { id: '4196', name: '4196' },
        { id: '41984', name: '41984' },
        { id: '43008', name: '43008' },
        { id: '44032', name: '44032' },
        { id: '448', name: '448' },
        { id: '45056', name: '45056' },
        { id: '46080', name: '46080' },
        { id: '4800', name: '4800' },
        { id: '4 X E1', name: '4 X E1' },
        { id: '5 x STM1', name: '5 x STM1' },
        { id: '512', name: '512' },
        { id: '5120', name: '5120' },
        { id: '51200', name: '51200' },
        { id: '5 X E1', name: '5 X E1' },
        { id: '6 x STM1', name: '6 x STM1' },
        { id: '6000', name: '6000' },
        { id: '6016', name: '6016' },
        { id: '6072', name: '6072' },
        { id: '61', name: '61' },
        { id: '6144', name: '6144' },
        { id: '61440', name: '61440' },
        { id: '64', name: '64' },
        { id: '640', name: '640' },
        { id: '7 x STM1', name: '7 x STM1' },
        { id: '7000', name: '7000' },
        { id: '7168', name: '7168' },
        { id: '75000', name: '75000' },
        { id: '768', name: '768' },
        { id: '8 x STM1', name: '8 x STM1' },
        { id: '8000', name: '8000' },
        { id: '80920', name: '80920' },
        { id: '8096', name: '8096' },
        { id: '8192', name: '8192' },
        { id: '8196', name: '8196' },
        { id: '9.6', name: '9.6' },
        { id: '9216', name: '9216' },
        { id: '92160', name: '92160' },
        { id: '96', name: '96' },
        { id: 'RED', name: 'RED' },
    ]
    const billingList = [
        { id: 'Charge', name: 'Charge' },
        { id: 'No Charge', name: 'No Charge' }
    ]
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    React.useEffect(() => {
        if (modalState.clearData === true) {
            setChangeControlForm('');
            setCircuitId('');
            setAttachMannual('');
            setConfigChangeForm('');
            setPingResult('');
            setQualityAssuarance('');
            setAttachDocument([]);
            setComment('');
            setProjectManagement('');
            setBasestation('');
            setLocation('');
            setConfiguredUpCircuit('');
            setConfiguredDownCircuit('');
            setProductClass('');
            setNode('');
            setStatus('');
            setBillingDate('');
            setBucketLevel('');
            setVendor('');
            setNodeCode('');
            setProductDescription('');
            setMediaDescription('');
            setServiceDescription('');
            setPlatform('');
            setRegion('');
            setCity('');
            setBilling('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
            setDocumentDeleteLoading(false);
        }
        if (isActionUpdate === true) {
            setProjectManagement(preValuesOfForm.project_management);
            setLocation(preValuesOfForm.location);
            setBasestation(preValuesOfForm.basestation);
            setConfiguredUpCircuit(preValuesOfForm.configured_up_circuit);
            setConfiguredDownCircuit(preValuesOfForm.configured_down_circuit);
            setProductClass(preValuesOfForm.product_class);
            setNode(preValuesOfForm.node);
            setStatus(preValuesOfForm.status);
            setBucketLevel(preValuesOfForm.bucket_level);
            setVendor(preValuesOfForm.vendor);
            setNodeCode(preValuesOfForm.node_code);
            setProductDescription(preValuesOfForm.product_description);
            setMediaDescription(preValuesOfForm.media_description);
            setServiceDescription(preValuesOfForm.service_description);
            setPlatform(preValuesOfForm.platform);
            setRegion(preValuesOfForm.region);
            setCity(preValuesOfForm.city);
            setBilling(preValuesOfForm.billing);
            if(preValuesOfForm.billing_date != "" && preValuesOfForm.billing_date != undefined && preValuesOfForm.billing_date != null){
                setBillingDate(new Date(preValuesOfForm.billing_date));
            }
            setComment(preValuesOfForm.comment);
            setNextTeamMemberEmail(preValuesOfForm.next_team_member_email);
            setOnHold(preValuesOfForm.project_info && preValuesOfForm.project_info.status ? preValuesOfForm.project_info.status : '');
            setCategory(preValuesOfForm.project_info && preValuesOfForm.project_info.category ? preValuesOfForm.project_info.category : '');
        }
    }, [modalState, CurrentUserName, isActionUpdate]);

    React.useEffect(() => { }, [projectManagement, location, basestation,
        configuredUpCircuit, configuredDownCircuit, productClass, node, status,
        bucketLevel, vendor, nodeCode, productDescription, mediaDescription,
        serviceDescription, platform, region, city, billing, billingDate,
        comment, onHold, category, nextTeamMemberEmail, preValuesOfForm])

    const handleAttachDocument = (index) => {
        setAttachDocument(prevState => {
            let newState = [...prevState]
            newState.splice(index, 1);
            return newState
        });
    }
    const handleUpdateDocuments = (index) => {
        setDocumentDeleteLoading(true);
        preValuesOfForm.docs.splice(index, 1);
        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
    }
    const documentTypes = [
        {
            id: 'fault_management',
            name: "Fault Management"
        },
        {
            id: 'assured_certificate',
            name: "Assured Certificate"
        },
        {
            id: 'refuribish_request',
            name: "Refuribish Request"
        }
    ];

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{isActionUpdate === true ? t('update.label') : t('create.label')} {t('qualityAssuaranceTeamSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : (preValuesOfForm.case_number ? preValuesOfForm.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.customer_name} / {preValuesOfForm.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewCompleteDetails.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={handleViewCompleteDetailClick}>{t('clickHereToViewCompleteDetails.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewAttachedDocuments.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={preValuesOfForm.previous_docs && preValuesOfForm.previous_docs.length == 0 ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleProjectAttachmentClick(preValuesOfForm.previous_docs);
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('changeControlForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.change_control_form ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.change_control_form != undefined) {
                                        window.open(preValuesOfForm.change_control_form);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('installationMannual.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.attach_manual ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.attach_manual != undefined) {
                                        window.open(preValuesOfForm.attach_manual);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('circuitId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.circuit_id}
                                type={'text'} disabled
                                placeholder={t('circuitId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCircuitId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : ''}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label')}
                                style={{ width: '100%' }}
                                disabled
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('configChangeForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={!preValuesOfForm.config_change_form ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (preValuesOfForm.config_change_form != undefined) {
                                        window.open(preValuesOfForm.config_change_form);
                                    }
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('pingResult.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.ping_result}
                                type={'text'} disabled
                                placeholder={t('pingResult.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setPingResult(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/* <Typography variant="subtitle2">{t('qualityAssuarance.label')}*</Typography> */}
                    {/* <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('qualityAssuarance.label')} *`}
                                handleChange={evt => {
                                    setQualityAssuarance(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={documentTypes ? documentTypes : []}
                            />
                        </Grid>
                    </Grid>*/}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attach.label')} {t('document.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {isActionUpdate === true ? <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={preValuesOfForm.docs && attachDocument && (attachDocument.length + preValuesOfForm.docs.length) >= 3 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && (attachDocument.length + preValuesOfForm.docs.length) < 3) {
                                        if (evt.target.files.length <= 3 && (attachDocument.length + evt.target.files.length + preValuesOfForm.docs.length) <= 3) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setAttachDocument(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 3. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            /> : <Input
                                type={'file'}
                                inputProps={{ multiple: true }}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={attachDocument && attachDocument.length >= 3 ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0 && attachDocument.length < 3) {
                                        if (evt.target.files.length <= 3 && (attachDocument.length + evt.target.files.length) <= 3) {
                                            let file = evt.target.files;
                                            for (let i = 0; i < file.length; i++) {
                                                setAttachDocument(prevState => {
                                                    let newState = [...prevState]
                                                    newState.push(file[i]);
                                                    return newState
                                                });
                                            }
                                        } else {
                                            evt.target.value = null;
                                            Message.error("Files length must be equal or less than 3. Please try again.");
                                        }
                                    } else {
                                        evt.target.value = null;
                                    }
                                }}
                            />}
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {attachDocument && attachDocument.length ?
                                attachDocument.map((item, index) => (
                                    <Typography variant={'body2'}> {index + 1}. {item.name} <IconButton onClick={evt => handleAttachDocument(index)} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.docs && preValuesOfForm.docs.length) ?
                                preValuesOfForm.docs.map((item, index) => (
                                    <Typography variant={'body2'}> <Button variant={'outlined'}
                                        disabled={!item.file ? true : false}
                                        style={{ marginBottom: 5 }}
                                        onClick={() => {
                                            if (item.file != undefined) {
                                                window.open(item.file);
                                            }
                                        }}><InsertDriveFileIcon></InsertDriveFileIcon> File {index + 1}</Button> <IconButton onClick={evt => handleUpdateDocuments(index)} aria-label="delete">
                                            <DeleteIcon color="secondary" /></IconButton>
                                    </Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('projectManagementTeam.label')} *`}
                                val={projectManagement}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setProjectManagement(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={location}
                                label={`${t('select.label') + ' ' + t('location.label')}`}
                                handleChange={evt => {
                                    setLocation(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={locationList ? locationList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={basestation}
                                label={`${t('select.label') + ' ' + t('baseStation.label')}`}
                                handleChange={evt => {
                                    setBasestation(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={baseStationList ? baseStationList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={configuredUpCircuit}
                                label={`${t('select.label') + ' ' + t('configuredUpCircuit.label')}`}
                                handleChange={evt => {
                                    setConfiguredUpCircuit(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={configuredUpList ? configuredUpList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={configuredDownCircuit}
                                label={`${t('select.label') + ' ' + t('configuredDownCiruit.label')}`}
                                handleChange={evt => {
                                    setConfiguredDownCircuit(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={configuredDownList ? configuredDownList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={productClass}
                                label={`${t('select.label') + ' ' + t('productCLass.label')}`}
                                handleChange={evt => {
                                    setProductClass(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={productClassList ? productClassList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={node}
                                label={`${t('select.label') + ' ' + t('node.label')}`}
                                handleChange={evt => {
                                    setNode(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={nodeList ? nodeList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={status}
                                label={`${t('select.label') + ' ' + t('status.label')}`}
                                handleChange={evt => {
                                    setStatus(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={statusList ? statusList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={bucketLevel}
                                label={`${t('select.label') + ' ' + t('bucketLevel.label')}`}
                                handleChange={evt => {
                                    setBucketLevel(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={bucketLevelList ? bucketLevelList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={vendor}
                                label={`${t('select.label') + ' ' + t('vendors.label')}`}
                                handleChange={evt => {
                                    setVendor(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={vendorList ? vendorList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={nodeCode}
                                label={`${t('select.label') + ' ' + t('node.label') + ' ' + t('code.label')}`}
                                handleChange={evt => {
                                    setNodeCode(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={nodeCodeList ? nodeCodeList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={productDescription}
                                label={`${t('select.label') + ' ' + t('product.label') + ' ' + t('description.label')}`}
                                handleChange={evt => {
                                    setProductDescription(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={productDescriptionList ? productDescriptionList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={mediaDescription}
                                label={`${t('select.label') + ' ' + t('media.label') + ' ' + t('description.label')}`}
                                handleChange={evt => {
                                    setMediaDescription(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={mediaDescriptionList ? mediaDescriptionList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={serviceDescription}
                                label={`${t('select.label') + ' ' + t('service.label') + ' ' + t('description.label')}`}
                                handleChange={evt => {
                                    setServiceDescription(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={serviceDescriptionList ? serviceDescriptionList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={platform}
                                label={`${t('select.label') + ' ' + t('platform.label')}`}
                                handleChange={evt => {
                                    setPlatform(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={platformList ? platformList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={region}
                                label={`${t('select.label') + ' ' + t('region.label')}`}
                                handleChange={evt => {
                                    setRegion(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={regionList ? regionList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={city}
                                label={`${t('select.label') + ' ' + t('city.label')}`}
                                handleChange={evt => {
                                    setCity(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={cityList ? cityList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={billing}
                                label={`${t('select.label') + ' ' + t('billing.label')}`}
                                handleChange={evt => {
                                    setBilling(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={billingList ? billingList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('billing.label') + ' ' + t('date.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={billingDate}
                                    onChange={date => setBillingDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Please select a date"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={category}
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={onHold}
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('previousComment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {preValuesOfForm.previous_comment && preValuesOfForm.previous_comment.length ?
                                JSON.parse(preValuesOfForm.previous_comment).map((item, index) => (
                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (preValuesOfForm.circuit_id != '') {
                                if (!isActionUpdate) {
                                    if (!projectManagement || !nextTeamMemberEmail) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                }
                                let getPreviousComment = preValuesOfForm.previous_comment ? JSON.parse(preValuesOfForm.previous_comment) : [];
                                if (comment != '') {
                                    if (isActionUpdate === true && preValuesOfForm.comment == comment) {
                                    } else {
                                        getPreviousComment.push({ name: CurrentUserName, comment: comment });
                                    }
                                }
                                let previous_comment = Object.entries(getPreviousComment).map(e => e[1]);
                                let getAttachDocument = [];
                                if (attachDocument != [] && attachDocument != null && attachDocument.length > 0) {
                                    for (let i = 0; i < attachDocument.length; i++) {
                                        let getAttachDoc = await handleUploadFiles(attachDocument[i], preValuesOfForm.project, 'quality_assurance_team_section');
                                        if (getAttachDoc.data && getAttachDoc.data.allUploadFile && getAttachDoc.data.allUploadFile.url) {
                                            getAttachDocument.push(getAttachDoc.data.allUploadFile.url);
                                        }
                                        else {
                                            Message.error("File not uploaded! Please try again.");
                                        }
                                    }
                                }
                                if (isActionUpdate === true) {
                                    // merge document
                                    if (preValuesOfForm.docs && preValuesOfForm.docs.length > 0) {
                                        for (let i = 0; i < preValuesOfForm.docs.length; i++) {
                                            getAttachDocument.push(preValuesOfForm.docs[i].file);
                                        }
                                    }
                                }
                                handleSubmitClick({
                                    formUid: isActionUpdate ? preValuesOfForm.uid : '',
                                    changeControlForm: preValuesOfForm.change_control_form,
                                    circuitId: preValuesOfForm.circuit_id,
                                    attachMannual: preValuesOfForm.attach_manual,
                                    configChangeForm: preValuesOfForm.config_change_form,
                                    pingResult: preValuesOfForm.ping_result,
                                    comment: comment,
                                    qualityAssuarance: qualityAssuarance,
                                    attachDocument: "",
                                    projectManagement: projectManagement,
                                    formName: 'QualityAssuranceForm',
                                    project_uid: preValuesOfForm.project,
                                    previous_comment: JSON.stringify(previous_comment),
                                    equipments_to_be_deployed: preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : '',
                                    auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                                    documents: getAttachDocument,
                                    basestation: basestation,
                                    location: location,
                                    configured_up_circuit: configuredUpCircuit,
                                    configured_down_circuit: configuredDownCircuit,
                                    product_class: productClass,
                                    node: node,
                                    bucket_level: bucketLevel,
                                    vendor: vendor,
                                    node_code: nodeCode,
                                    product_description: productDescription,
                                    media_description: mediaDescription,
                                    platform: platform,
                                    service_description: serviceDescription,
                                    region: region,
                                    city: city,
                                    billing: billing,
                                    status: status,
                                    billing_date: billingDate ? moment(billingDate).format("YYYY-MM-DD") : '',
                                    next_team_member_email: nextTeamMemberEmail,
                                    category: category,
                                    on_hold: onHold,
                                });
                            } else {
                                if (!changeControlForm || !circuitId || !attachMannual || !configChangeForm || !pingResult || !qualityAssuarance || !attachDocument) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                handleSubmitClick({ changeControlForm: changeControlForm, circuitId: circuitId, attachMannual: attachMannual, configChangeForm: configChangeForm, pingResult: pingResult, qualityAssuarance: qualityAssuarance, attachDocument: attachDocument, comment: comment, formName: 'QualityAssuranceForm' });
                            }
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateQualityAssuranceTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateQualityAssuranceTeamModal);
