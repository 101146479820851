import React from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import OcrLoactionItem from './OcrLoactionItem';
import OcrLoactionForm from './LocationForm';
import {marginGenerator} from '../../../../../../theme/utils';

const useStyles = makeStyles(theme => ({
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  }
}));

const OcrConfigurationLocation = ({
    t,
    createLocationEnable,
    handleCreateLocationEnableFormClose,
    isLocationEnable,
    ocrPlaceData,
    loading,
    page,
    handlePageChange,
    handleSubmitOfOcrPlace,
    ocrLocationSubmit,
    ocrPlaceDeletetingStarted,
    selecteIdForDelete,
    handleSubmitOfDeleteOcrTreeItems
}) => {

  const classes = useStyles();
  let columns = [
    {
        label: t('places.label') + ' ' + t('name.label'),
        key: "name"
    },
    {
      label: 'Description',
      key: "description"
    },
    {
        label: t('createdBy.label'),
        key: "createdByName"
    },
    {
        label: t('createdDate.label'),
        key: "createdDate"
    },
    {
        label: t('gpsCoOrdinates.label'),
        key: "gpsCoOrdinate"
    },
    {
        label: t('action.label'),
        key: "action"
    }
];

  return( 
    <div>
      <Grid container style={{...marginGenerator("mb-20")}}>
        <Grid item xs="10"/>
        <Grid item xs="2">
            <Button
                align={'right'}
                variant={'outlined'}
                color={'primary'}
                onClick={createLocationEnable}
                >
                {t('createLocation.label')}
            </Button>
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                     {!loading ?
                      ocrPlaceData.data && ocrPlaceData.data.length > 0 ?
                      ocrPlaceData.data.map((rowitem, rowIndex) => (
                                <OcrLoactionItem
                                    key={rowIndex}
                                    t={t}
                                    data={rowitem}
                                    ocrPlaceDeletetingStarted={ocrPlaceDeletetingStarted}
                                    selecteIdForDelete={selecteIdForDelete}
                                    handleSubmitOfDeleteOcrTreeItems={handleSubmitOfDeleteOcrTreeItems}
                                />
                            )) :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Typography variant={'subtitle2'}>Record not found!</Typography>
                                </TableCell>
                            </TableRow>
                        :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {loading && <LinearProgress color="primary"/>}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            { !loading &&
                <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                        <Grid item xs={2}>
                            <span>Total {Math.ceil(get(ocrPlaceData, 'count', null)/20)} page,  {get(ocrPlaceData, 'count', null)} Row</span>
                        </Grid>
                        <Grid item xs={3}>
                            <Pagination 
                                color="secondary" 
                                count={ocrPlaceData && ocrPlaceData.count ? Math.ceil(ocrPlaceData.count/20) : 0} 
                                page={page} 
                                onChange={(evt, value) => {
                                    handlePageChange("ocr_place", value);
                                }} 
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </TableContainer>
    </Grid>
    <OcrLoactionForm 
       t={t} 
       open={isLocationEnable}
       onClose={handleCreateLocationEnableFormClose}  
       handleSubmitOfOcrPlace={handleSubmitOfOcrPlace}
       ocrLocationSubmit={ocrLocationSubmit}   
    />
</div>
);
};

export default OcrConfigurationLocation;
