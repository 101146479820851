import { gql } from 'apollo-boost';

export const CREATE_DEPARTMENT = gql`
    mutation Department($name: String, $subscriptionId: String) {
        addDepartment(name: $name, subscriptionId: $subscriptionId) {
            id
        }
    }
`;

export const CREATE_SECTION = gql`
    mutation Section($name: String, $subscriptionId: String, $department: String) {
        addSection(name: $name, subscriptionId: $subscriptionId, department: $department) {
            id
        }
    }
`;

export const CREATE_SERVER_ROOM = gql`
    mutation ServerRoom(
        $id: String
        $fullName: String
        $reportedDateTime: Date
        $designation: String
        $userSign: String
        $serverRoom: String
        $allIdentifiedIssuesCorrected: String
        $allIdentifiedIssuesCorrectedComments: String
        $correctionsOfPrevIssue: String
        $correctionsOfPrevIssueComments: String    
        $allAreaCleaned: String
        $allAreaCleanedComments: String
        $allWalkabouts: String
        $allWalkaboutsComments: String
        $floorsLiquidsFree: String
        $floorsLiquidsFreeComments: String
        $flammableMaterialsStored: String
        $flammableMaterialsStoredComments: String
        $otherHousekeepingIssue: String
        $otherHousekeepingIssueComments: String
        $temperatureHumidityStandard: String
        $temperatureHumidityStandardComments: String
        $airflowCondition: String
        $airflowConditionComments: String
        $floorRaised: String
        $floorRaisedComments: String
        $automatedMonitoringSystem: String
        $automatedMonitoringSystemComments: String
        $emsAlertSent: String
        $emsAlertSentComments: String
        $powerUps: String
        $powerUpsComments: String
        $electricalCircuitBreakers: String
        $electricalCircuitBreakersComments: String
        $voltageAndCurrentCapacities: String
        $voltageAndCurrentCapacitiesComments: String
        $enoughPowerOutlets: String
        $enoughPowerOutletsComments: String
        $fireDetectionInstalled: String
        $fireDetectionInstalledComments: String
        $fireExtinguishEquip: String
        $fireExtinguishEquipComments: String
        $firePanelInstalled: String
        $firePanelInstalledComments: String
        $accessControlInstalled: String
        $accessControlInstalledComments: String
        $securityAlarmInstalled: String
        $securityAlarmInstalledComments: String
        $videoCamera: String
        $videoCameraComments: String
        $upsDate: Date
        $upsComments: String
        $airConditionerDate: Date
        $airConditionerComments: String
        $emsSensorsIndicatorsDate: Date
        $emsSensorsIndicatorsComments: String
        $humidityTemperatureSensorsDate: Date
        $humidityTemperatureSensorsComments: String
        $fireExtinguishersDate: Date
        $fireExtinguishersComments: String
        $fireDetectionSensorsDate: Date
        $fireDetectionSensorsComments: String
        $imHeadId: String
        $imHeadName: String
        $imHeadSign: String
        $imHeadDate: Date
        $imSectionHeadId: String
        $imSectionHeadName: String
        $imSectionHeadSign: String
        $imSectionHeadDate: Date
    ) {
        addServerRoom(
            id: $id
            fullName: $fullName
            reportedDateTime: $reportedDateTime
            designation: $designation
            userSign: $userSign
            serverRoom: $serverRoom
            allIdentifiedIssuesCorrected: $allIdentifiedIssuesCorrected
            allIdentifiedIssuesCorrectedComments: $allIdentifiedIssuesCorrectedComments
            correctionsOfPrevIssue: $correctionsOfPrevIssue
            correctionsOfPrevIssueComments: $correctionsOfPrevIssueComments
            allAreaCleaned: $allAreaCleaned
            allAreaCleanedComments: $allAreaCleanedComments
            allWalkabouts: $allWalkabouts
            allWalkaboutsComments: $allWalkaboutsComments
            floorsLiquidsFree: $floorsLiquidsFree
            floorsLiquidsFreeComments: $floorsLiquidsFreeComments
            flammableMaterialsStored: $flammableMaterialsStored
            flammableMaterialsStoredComments: $flammableMaterialsStoredComments
            otherHousekeepingIssue: $otherHousekeepingIssue
            otherHousekeepingIssueComments: $otherHousekeepingIssueComments
            temperatureHumidityStandard: $temperatureHumidityStandard
            temperatureHumidityStandardComments: $temperatureHumidityStandardComments
            airflowCondition: $airflowCondition
            airflowConditionComments: $airflowConditionComments
            floorRaised: $floorRaised
            floorRaisedComments: $floorRaisedComments
            automatedMonitoringSystem: $automatedMonitoringSystem
            automatedMonitoringSystemComments: $automatedMonitoringSystemComments
            emsAlertSent: $emsAlertSent
            emsAlertSentComments: $emsAlertSentComments
            powerUps: $powerUps
            powerUpsComments: $powerUpsComments
            electricalCircuitBreakers: $electricalCircuitBreakers
            electricalCircuitBreakersComments: $electricalCircuitBreakersComments
            voltageAndCurrentCapacities: $voltageAndCurrentCapacities
            voltageAndCurrentCapacitiesComments: $voltageAndCurrentCapacitiesComments
            enoughPowerOutlets: $enoughPowerOutlets
            enoughPowerOutletsComments: $enoughPowerOutletsComments
            fireDetectionInstalled: $fireDetectionInstalled
            fireDetectionInstalledComments: $fireDetectionInstalledComments
            fireExtinguishEquip: $fireExtinguishEquip
            fireExtinguishEquipComments: $fireExtinguishEquipComments
            firePanelInstalled: $firePanelInstalled
            firePanelInstalledComments: $firePanelInstalledComments
            accessControlInstalled: $accessControlInstalled
            accessControlInstalledComments: $accessControlInstalledComments
            securityAlarmInstalled: $securityAlarmInstalled
            securityAlarmInstalledComments: $securityAlarmInstalledComments
            videoCamera: $videoCamera
            videoCameraComments: $videoCameraComments
            upsDate: $upsDate
            upsComments: $upsComments
            airConditionerDate: $airConditionerDate
            airConditionerComments: $airConditionerComments
            emsSensorsIndicatorsDate: $emsSensorsIndicatorsDate
            emsSensorsIndicatorsComments: $emsSensorsIndicatorsComments
            humidityTemperatureSensorsDate: $humidityTemperatureSensorsDate
            humidityTemperatureSensorsComments: $humidityTemperatureSensorsComments
            fireExtinguishersDate: $fireExtinguishersDate
            fireExtinguishersComments: $fireExtinguishersComments
            fireDetectionSensorsDate: $fireDetectionSensorsDate
            fireDetectionSensorsComments: $fireDetectionSensorsComments
            imHeadId: $imHeadId
            imHeadName: $imHeadName
            imHeadSign: $imHeadSign
            imHeadDate: $imHeadDate
            imSectionHeadId: $imSectionHeadId
            imSectionHeadName: $imSectionHeadName
            imSectionHeadSign: $imSectionHeadSign
            imSectionHeadDate: $imSectionHeadDate
        ) {
            id
        }
    }
`;

export const DELETE_SERVER_ROOM = gql`
    mutation ServerRoom(
        $id : String
    ) {
        deleteServerRoom(
            id: $id
        ){
            id
        }
    }
`;

export const CREATE_MEETING = gql`
    mutation Meeting(
        $id: String
        $title: String
        $venue: String
        $facilitator: String
        $attendees: [String]
        $description: String
        $subscriptionId: String
        $dateTime: Date
        $notes: String
        $sign: String
    ) {
        addMeeting(
            id: $id
            title: $title
            venue: $venue
            facilitator: $facilitator
            attendees: $attendees
            description: $description
            subscriptionId: $subscriptionId
            dateTime: $dateTime
            notes: $notes
            sign: $sign
        ) {
            id
        }
    }
`;

export const DELETE_MEETING = gql`
    mutation Meeting(
        $id : String
    ) {
        deleteMeeting(
            id: $id
        ){
            id
        }
    }
`;

export const DELETE_MODEM = gql`
    mutation Modem(
        $id : String
    ) {
        deleteModemIssue(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const DELETE_LAPTOP = gql`
    mutation Laptop(
        $id : String
    ) {
        deleteLaptopIssue(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const DELETE_BACKUP_CONTROL = gql`
    mutation BackupControl(
        $id : String
    ) {
        deleteBackupControlRequest(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const DELETE_INCIDENT_REPORT = gql`
    mutation IncidentReport(
        $id : String
    ) {
        deleteIncidentReportRequest(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const DELETE_THIRDPARTY_VPN = gql`
    mutation ThirdPartyVPN(
        $id : String
    ) {
        deleteThirdPartyVPNRequest(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const DELETE_REMOTE_ACCESS = gql`
    mutation RemoteAccess(
        $id : String
    ) {
        deleteRemoteAccessRequest(
            id: $id
        ){
            success
            message
            data
        }
    }
`;

export const ADD_BRIEFING = gql`
    mutation Briefing(
        $id: String
        $title: String
        $section: String
        $attendees: [String]
        $description: String
        $subscriptionId: String
        $issueDateTime: Date
        $notes: String
    ){
        addBriefing(
            id: $id
            title: $title
            section: $section
            attendees: $attendees
            description: $description
            subscriptionId: $subscriptionId
            issueDateTime: $issueDateTime
            notes: $notes
        ){
            id
        }
    }
`;


export const DELETE_BRIEF = gql`
    mutation Briefing(
        $id : String
    ) {
        deleteBriefing(
            id: $id
        ){
            id
        }
    }
`;

// export const CREATE_LAPTOP_ISSUE = gql`
//     mutation LaptopIssue(
//         $id: String
//         $fullName: String
//         $userDepartment: String
//         $userDesignation: String
//         $userSection: String
//         $userCellular: String
//         $employeeNumber: String
//         $accountType: String
//         $employeeType: String
//         $expiryDate: Date
//         $requiredAccess: [String]
//         $recommendationSign: String
//         $recommendation: String
//         $authorisationSign: String
//         $authorisation: String
//         $subscriptionId: String
//     ) {
//         addLaptopIssue(
//             id: $id
//             userFullName: $userFullName
//             subscriptionId: $subscriptionId
//             userDepartment: $userDepartment
//             userDesignation: $userDesignation
//             userSection: $userSection
//             userCellular: $userCellular
//             employeeNumber: $employeeNumber
//             accountType: $accountType
//             employeeType: $employeeType
//             expiryDate: $expiryDate
//             requiredAccess: $requiredAccess
//             recommendation: $recommendation
//             recommendationSign: $recommendationSign
//             authorisationSign: $authorisationSign
//             authorisation: $authorisation

//         ) {
//             success
//             message
//             data
//         }
//     }
// `;


export const CREATE_USER_REQUEST = gql`
    mutation UserRequest(
        $id: String
        $subscriptionId: String
        $userFullName: String
        $userDepartment: String
        $userDesignation: String
        $userSection: String
        $userCellular: String
        $employeeNumber: String
        $accountType: String
        $employeeType: String
        $expiryDate: Date
        $recomendDate: Date
        $authDate: Date
        $userDeclareDate: Date
        $userDeclareSign: String
        $requiredAccess: [String]
        $recommendation: String
        $recommendationSign: String
        $authorisationSign: String
        $authorisation: String
        $userDeclareDate: Date
        $iamUser: String
        $iamUserSign: String
        $iamUserDate: Date
        $userIdCreated: String
    ) {
        addUserRequest(
            id: $id
            subscriptionId: $subscriptionId
            userFullName: $userFullName
            userDepartment: $userDepartment
            userDesignation: $userDesignation
            userSection: $userSection
            userCellular: $userCellular
            employeeNumber: $employeeNumber
            accountType: $accountType
            employeeType: $employeeType
            expiryDate: $expiryDate
            recomendDate: $recomendDate
            authDate: $authDate
            userDeclareDate: $userDeclareDate
            userDeclareSign: $userDeclareSign
            requiredAccess: $requiredAccess
            recommendation: $recommendation
            recommendationSign: $recommendationSign
            authorisationSign: $authorisationSign
            authorisation: $authorisation
            userDeclareDate: $userDeclareDate
            iamUser: $iamUser
            iamUserSign: $iamUserSign
            iamUserDate: $iamUserDate
            userIdCreated: $userIdCreated
        ) {
            id
        }
    }
`;


export const DELETE_USER_REQUEST = gql`
    mutation UserRequest(
        $id : String
    ) {
        deleteUserRequest(
            id: $id
        ){
            id
        }
    }
`;

export const CREATE_LEAVE_REQUEST = gql`
    mutation LeaveRequest(
        $id: String
        $subscriptionId: String
        $userFullName: String
        $prNumber: String
        $jobGrade: String
        $costCode: String,
        $userDepartment: String, 
        $leaveFrom: Date,
        $leaveTo: Date,
        $userSection: JSON
        $userSectionId:  String
        $sign: String,
        $authorisation: String
        $createdAt: Date
        $createdById: String
        $createdByName: String
        $phoneNumber: String
        $address: String
        $approver: String
        $learnDevelopment: String
        $HOD: String
        $HRManager: String
        $GeneralManager: String
        $HRManagerComment: String
        $orgnaiserDate: Date
        $sectionHeadDate: Date
        $HODDate: Date
    ) {
        addLeaveRequest(
            id: $id
            subscriptionId: $subscriptionId
            userFullName: $userFullName
            prNumber: $prNumber
            jobGrade: $jobGrade
            costCode: $costCode,
            userDepartment: $userDepartment,
            leaveFrom: $leaveFrom,
            leaveTo: $leaveTo,
            userSection: $userSection,
            userSectionId: $userSectionId,
            HRManagerComment: $HRManagerComment,
            sign: $sign,
            authorisation: $authorisation
            createdAt: $createdAt
            createdById: $createdById
            createdByName: $createdByName
            phoneNumber: $phoneNumber
            address: $address
            approver: $approver
            learnDevelopment: $learnDevelopment
            HOD: $HOD
            HRManager: $HRManager
            GeneralManager: $GeneralManager
            orgnaiserDate:$orgnaiserDate
            sectionHeadDate:$sectionHeadDate
            HODDate:$HODDate
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_LABOUR_REQUEST = gql`
    mutation LabourRequest(
            $id: String
            $subscriptionId: String 
            $postion: String
            $number: String
            $costCode: String,
            $userDepartment: String,
            $userSection: JSON,
            $labourReqFrom: Date,
            $labourReqTo: Date,
            $HRManagerComment: String,
            $userSectionId: String,
            $sign: String,
            $authorisation: String
            $createdAt: Date
            $createdById: String
            $createdByName: String
            $phoneNumber: String
            $address: String
            $approver: String
            $learnDevelopment: String
            $HOD: String
            $HRManager: String
            $GeneralManager: String
            $typeOfReq: String 
            $extensionFrom: Date
            $extensionTo: Date
            $insertType: String
            $reasonForRequest: String
            $orgnaiser: String
            $sectionHead: String
            $recommendComment: String
            $headOfDepartment: String
            $positionExistsOrNot: String 
            $complaint: String
            $strength: String
            $variance: String
            $validator: String
            $hrMangerCasul: String
            $recmondedAuthority: String
            $expiryDate: Date
            $recommendationSign: String
    ) {
        addLabourRequest(
            id:$id
            subscriptionId:$subscriptionId 
            postion:$postion
            number:$number
            costCode:$costCode
            userDepartment:$userDepartment
            userSection:$userSection
            labourReqFrom:$labourReqFrom
            labourReqTo:$labourReqTo
            HRManagerComment:$HRManagerComment
            userSectionId:$userSectionId
            sign:$sign
            authorisation:$authorisation
            createdAt:$createdAt
            createdById:$createdById
            createdByName:$createdByName
            phoneNumber:$phoneNumber
            address:$address
            approver:$approver
            learnDevelopment:$learnDevelopment
            HOD:$HOD
            HRManager:$HRManager
            GeneralManager:$GeneralManager
            typeOfReq:$typeOfReq 
            extensionFrom:$extensionFrom
            extensionTo:$extensionTo
            insertType: $insertType
            reasonForRequest:$reasonForRequest
            orgnaiser:$orgnaiser
            sectionHead:$sectionHead
            recommendComment:$recommendComment
            headOfDepartment:$headOfDepartment
            positionExistsOrNot:$positionExistsOrNot 
            complaint:$complaint
            strength:$strength
            variance:$variance
            validator:$validator
            hrMangerCasul:$hrMangerCasul
            recmondedAuthority:$recmondedAuthority
            expiryDate:$expiryDate
            recommendationSign:$recommendationSign
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_TRANSPORT_REQUEST = gql`
    mutation TransportRequest(
        $id: String
        $subscriptionId: String,
        $userDepartment: String,
        $userSection: JSON,
        $licenceNumber:  String,
        $placeToVisit:  String,
        $username:  String,
        $dateReq:  Date,
        $dateRequired:  Date,
        $dateSupAuth:  Date,
        $dateHODAppr:  Date,
        $returnDate:  Date,
        $purpose: String,
        $immediateSupervisor: String,
        $dateSupAuthSupervisor:  Date,
        $authorisedByApprovedByHod: String,
        $dateHodAppr:  Date,
        $vechileAvaility: String,
        $dateReqRecived: Date,
        $registerNumberDate: Date,
        $transportDate: Date,
        $approvedByEngineerManger: String,
        $approvedByEngineerMangerDate: Date,
        $approvelOfVechile:  String,
        $approvedByHod:  String,
        $approvedByHodDate:  Date,
        $approvedByGm:  String,
        $approvedByGmDate:  Date,
        $transportSupervisorSign: String,
        $userSign: String,
        $HOD: String,
        $complaint:  String,
        $positionExist:  String,
        $strength:  String,
        $variance: String,
        $approverComment:  String,
        $approverSign:  String,
        $validation:  String,
        $validationSign:  String,
        $learnDevelopmentComment:  String,
        $HRManagerCasual:  String,
        $HRManagerCasualSign:  String,
        $HRManagerCasualComment:  String,
        $HRManager:  String,
        $HRManagerSign:  String,
        $HRManagerComment:  String,
        $GeneralManager:  String,
        $GeneralManagerSign:  String,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addTransportRequest(
        id:$id
        subscriptionId:$subscriptionId,
        userDepartment:$userDepartment,
        userSection:$userSection,
        licenceNumber:$licenceNumber,
        placeToVisit:$placeToVisit,
        username:$username,
        dateReq:$dateReq,
        dateRequired:$dateRequired,
        dateSupAuth:$dateSupAuth,
        dateHODAppr:$dateHODAppr,
        returnDate:$returnDate,
        purpose:$purpose,
        immediateSupervisor:$immediateSupervisor,
        dateSupAuthSupervisor:$dateSupAuthSupervisor,
        authorisedByApprovedByHod:$authorisedByApprovedByHod,
        dateHodAppr:$dateHodAppr,
        vechileAvaility:$vechileAvaility,
        dateReqRecived:$dateReqRecived,
        registerNumberDate:$registerNumberDate,
        transportDate:$transportDate,
        approvedByEngineerManger:$approvedByEngineerManger,
        approvedByEngineerMangerDate:$approvedByEngineerMangerDate,
        approvelOfVechile:$approvelOfVechile,
        approvedByHod:$approvedByHod,
        approvedByHodDate:$approvedByHodDate,
        approvedByGm:$approvedByGm,
        approvedByGmDate:$approvedByGmDate,
        transportSupervisorSign: $transportSupervisorSign,
        userSign: $userSign,
        HOD:$HOD,
        complaint:$complaint,
        positionExist:$positionExist,
        strength:$strength,
        variance:$variance,
        approverComment:$approverComment,
        approverSign:$approverSign,
        validation:$validation,
        validationSign:$validationSign,
        learnDevelopmentComment:$learnDevelopmentComment,
        HRManagerCasual:$HRManagerCasual,
        HRManagerCasualSign:$HRManagerCasualSign,
        HRManagerCasualComment:$HRManagerCasualComment,
        HRManager:$HRManager,
        HRManagerSign:$HRManagerSign,
        HRManagerComment:$HRManagerComment,
        GeneralManager:$GeneralManager,
        GeneralManagerSign:$GeneralManagerSign,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_BACKUP_CONTROL_REQUEST = gql`
    mutation BackupControlRequest(
        $id: String
        $subscriptionId: String,
        $failedComments: String,
        $performedServerRoomName: String,
        $performedAuthSign: String,
        $performedDate: Date,
        $checkedServerRoomName: String,
        $checkedAuthSign:  String,
        $checkedDate:  Date,
        $dailyAttendence: [JSON],
        $mcmdailyAttendence: [JSON],
        $debsIsodailyAttendence: [JSON],
        $mcmAspdailyAttendence: [JSON],
        $debsMcmAspdailyAttendence: [JSON],
        $createdById:  String,
        $createdByName:  String,
    ) {
        addBackupControlRequest(
        id:$id
        subscriptionId:$subscriptionId,
        failedComments:$failedComments,
        performedServerRoomName:$performedServerRoomName,
        performedAuthSign:$performedAuthSign,
        performedDate:$performedDate,
        checkedServerRoomName:$checkedServerRoomName,
        checkedAuthSign:$checkedAuthSign,
        checkedDate:$checkedDate,
        dailyAttendence:$dailyAttendence,
        mcmdailyAttendence:$mcmdailyAttendence,
        debsIsodailyAttendence:$debsIsodailyAttendence,
        mcmAspdailyAttendence:$mcmAspdailyAttendence,
        debsMcmAspdailyAttendence:$debsMcmAspdailyAttendence,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_INCIDENT_REPORT_REQUEST = gql`
    mutation IncidentReportRequest(
        $id: String
        $subscriptionId: String,
        $date: Date,
        $systemFacility: String,
        $dateAndTimeReported: Date,
        $reportedBy: String,
        $serverty:String,
        $sectionTwo: String,
        $sectionThree: String,
        $sectionFour: String,
        $sectionFive: String,
        $nameOne: String,
        $designationOne: String,
        $dateOne: Date,
        $nameTwo: String,
        $designationTwo: String,
        $dateTwo: Date,
        $nameThree: String,
        $designationThree: String,
        $dateThree: Date,
        $infromantionMangementName: String,
        $infromantionMangementDate: Date,
        $performedAuthSign: String,
        $performedDate: Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addIncidentReportRequest(
        id:$id
        subscriptionId:$subscriptionId,
        date: $date,
        systemFacility: $systemFacility,
        dateAndTimeReported: $dateAndTimeReported,
        reportedBy: $reportedBy,
        serverty:$serverty,
        sectionTwo: $sectionTwo,
        sectionThree: $sectionThree,
        sectionFour: $sectionFour,
        sectionFive: $sectionFive,
        nameOne: $nameOne,
        designationOne: $designationOne,
        dateOne: $dateOne,
        nameTwo: $nameTwo,
        designationTwo: $designationTwo,
        dateTwo: $dateTwo,
        nameThree: $nameThree,
        designationThree: $designationThree,
        dateThree: $dateThree,
        infromantionMangementName: $infromantionMangementName,
        infromantionMangementDate: $infromantionMangementDate,
        performedAuthSign: $performedAuthSign,
        performedDate: $performedDate,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_THIRD_PARTY_VPN_REQUEST = gql`
    mutation ThirdPartyVPNRequest(
        $id: String
        $subscriptionId: String,
        $userName: String,
        $designation: String,
        $email: String,
        $companyName: String,
        $supervisorName: String,
        $supervisorEmail: String,
        $contactNumber: String,
        $passportNumber: String,
        $supervisorPhoneNo: String,
        $supervisorPassportNo: String,
        $termsAndCond: String,
        $sign: String,
        $userDate: Date,
        $vpnSubmitedDate: Date,
        $sectionHead: String,
        $submittedDate: Date,
        $accessDevice: String,
        $authorisationSectionHead: String,
        $authorisationSectionHeadSign: String,
        $authorisationSectionHeadDate: Date,
        $authorisationSectionHeadComments: String,
        $vpnSupervisorName: String,
        $vpnSupervisorDepartment: String,
        $dayOf: String,
        $iWe: String,
        $of: String,
        $thusDoneBySignedBy: String,
        $at: String,
        $this: String,
        $nonDisclosureUndertaking: String,
        $witness1: String,
        $witness2: String,
        $thusSignedBy: String,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addThirdPartyVPNRequest(
        id:$id
        subscriptionId:$subscriptionId,
        userName:$userName,
        designation:$designation,
        email:$email,
        companyName:$companyName,
        supervisorName:$supervisorName,
        supervisorEmail:$supervisorEmail,
        contactNumber:$contactNumber,
        passportNumber:$passportNumber,
        supervisorPhoneNo:$supervisorPhoneNo,
        supervisorPassportNo:$supervisorPassportNo,
        termsAndCond:$termsAndCond,
        sign:$sign,
        userDate:$userDate,
        vpnSubmitedDate:$vpnSubmitedDate,
        sectionHead:$sectionHead,
        submittedDate:$submittedDate,
        accessDevice:$accessDevice,
        vpnSupervisorName: $vpnSupervisorName,
        vpnSupervisorDepartment: $vpnSupervisorDepartment,
        dayOf: $dayOf,
        authorisationSectionHead:$authorisationSectionHead,
        authorisationSectionHeadSign:$authorisationSectionHeadSign,
        authorisationSectionHeadDate:$authorisationSectionHeadDate,
        authorisationSectionHeadComments:$authorisationSectionHeadComments,
        iWe:$iWe,
        of:$of,
        thusDoneBySignedBy:$thusDoneBySignedBy,
        at:$at,
        this:$this,
        nonDisclosureUndertaking:$nonDisclosureUndertaking,
        witness1:$witness1,
        witness2:$witness2,
        thusSignedBy:$thusSignedBy,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_REMOTE_ACCESS_REQUEST = gql`
    mutation RemoteAccessRequest(
        $id: String
        $subscriptionId: String,
        $custmInfo: String,
        $fullName: String,
        $termsAndCond: String,
        $networkId: String,
        $contactNo: String,
        $cellPhoneNo: String,
        $email: String,
        $itManager: String,
        $itCallRef: String,
        $itSign: String,
        $itDateSubmitted: Date,
        $custmName: String,
        $custmSign: String,
        $custmDateSubmitted: Date,
        $scurityTokenNumber: String,
        $govAndLogiSign: String,
        $govAndLogiDateSubmitted: Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addRemoteAccessRequest(
        id:$id
        subscriptionId:$subscriptionId,
        custmInfo:$custmInfo,
        fullName:$fullName,
        termsAndCond:$termsAndCond,
        networkId:$networkId,
        contactNo:$contactNo,
        cellPhoneNo:$cellPhoneNo,
        email:$email,
        itManager:$itManager,
        itCallRef:$itCallRef,
        itSign:$itSign,
        itDateSubmitted:$itDateSubmitted,
        custmName:$custmName,
        custmSign:$custmSign,
        custmDateSubmitted:$custmDateSubmitted,
        scurityTokenNumber:$scurityTokenNumber,
        govAndLogiSign:$govAndLogiSign,
        govAndLogiDateSubmitted:$govAndLogiDateSubmitted,    
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_MODEM_ISSUE_REQUEST = gql`
    mutation ModemIssueRequest(
        $id: String
        $subscriptionId: String,
        $fullName:  String,
        $department:  String,
        $desgnation:  String,
        $section: String,
        $officeNumber:  String,
        $cellPhone:  String,
        $model: String,
        $modemColour: String,
        $series: String,
        $serialNumber:  String,
        $issueDate:  String,
        $simCardNum:  String,
        $termsAndCond:  String,
        $userDateSubmitted: Date,
        $name:  String,
        $infoDateSubmitted: Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addModemIssueRequest(
        id:$id
        subscriptionId:$subscriptionId,
        fullName:$fullName,
        department:$department,
        desgnation:$desgnation,
        section:$section,
        officeNumber:$officeNumber,
        cellPhone:$cellPhone,
        model:$model,
        modemColour:$modemColour,
        series:$series,
        serialNumber:$serialNumber,
        issueDate:$issueDate,
        simCardNum:$simCardNum,
        termsAndCond:$termsAndCond,
        userDateSubmitted:$userDateSubmitted,
        name:$name,
        infoDateSubmitted:$infoDateSubmitted,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_LAPTOP_ISSUE_REQUEST = gql`
    mutation LaptopIssueRequest(
        $id: String
        $subscriptionId: String,
        $fullName:  String,
        $department:  String, 
        $designation:  String,
        $section:  String,
        $officeNumber:  String,
        $cellPhone:  String,
        $modal:  String,
        $laptopColour:  String,
        $series:  String,
        $serialNumber:  String,
        $issueDate:  Date,
        $computerName:  String,
        $termsAndCond: String,
        $userSign:  String,
        $userDateSubmitted:  Date,
        $imangmentName:  String,
        $imangmentSign:  String,
        $imangmentDate:  Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addLaptopIssue(
        id:$id
        subscriptionId:$subscriptionId,
        fullName:$fullName,
        department:$department, 
        designation:$designation,
        section:$section,
        officeNumber:$officeNumber,
        cellPhone:$cellPhone,
        modal:$modal,
        laptopColour:$laptopColour,
        series:$series,
        serialNumber:$serialNumber,
        issueDate:$issueDate,
        computerName:$computerName,
        termsAndCond:$termsAndCond,
        userSign:$userSign,
        userDateSubmitted:$userDateSubmitted,
        imangmentName:$imangmentName,
        imangmentSign:$imangmentSign,
        imangmentDate:$imangmentDate,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const CREATE_CHANGE_REQUEST = gql`
    mutation ChangeRequest(
        $id: String
        $subscriptionId: String,
        $crNumber: String,
        $dateSubmitted: Date,
        $requesterName: String,
        $rgDesignation: String,
        $rgSection: String,
        $rgEmail: String,
        $rgPhone: String,
        $rgSign: String,
        $supervisior: String,
        $svDesignation: String,
        $svEmail: String,
        $svPhone: String,
        $changeLocation: String,
        $svSign: String,
        $changeRequested: String,
        $reasonToChange: String,
        $changeClassification: String,
        $hardwareName: String,
        $softwareName: String,
        $risk: String,
        $mitigationMeasures: String,
        $relativeCost: String,
        $estimatedEfforts: String,
        $responsible: String,
        $accountable: String,
        $consulted: String,
        $informed: String,
        $responsibleSign: String,
        $accountableSign: String,
        $consultedSign: String,
        $informedSign: String,
        $imo: String,
        $imoRecommendation: String,
        $imoReason: String,
        $priority: String,
        $commercialManager: String,
        $commercialManagerStatus: String,
        $commercialManagerSign:  String,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addChangeRequest(
        id:$id
        subscriptionId:$subscriptionId,
        crNumber: $crNumber,
        dateSubmitted: $dateSubmitted,
        requesterName: $requesterName,
        rgDesignation: $rgDesignation,
        rgSection:$rgSection,
        rgEmail: $rgEmail,
        rgPhone: $rgPhone,
        rgSign: $rgSign,
        supervisior: $supervisior,
        svDesignation: $svDesignation,
        svEmail: $svEmail,
        svPhone: $svPhone,
        changeLocation: $changeLocation,
        svSign: $svSign,
        changeRequested: $changeRequested,
        reasonToChange: $reasonToChange,
        changeClassification: $changeClassification,
        hardwareName: $hardwareName,
        softwareName: $softwareName,
        risk: $risk,
        mitigationMeasures: $mitigationMeasures,
        relativeCost: $relativeCost,
        estimatedEfforts: $estimatedEfforts,
        responsible: $responsible,
        accountable: $accountable,
        consulted: $consulted,
        informed: $informed,
        responsibleSign: $responsibleSign,
        accountableSign: $accountableSign,
        consultedSign: $consultedSign,
        informedSign: $informedSign,
        imo: $imo,
        imoRecommendation: $imoRecommendation,
        imoReason: $imoReason,
        priority: $priority,
        commercialManager: $commercialManager,
        commercialManagerStatus: $commercialManagerStatus,
        commercialManagerSign:  $commercialManagerSign,    
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_CHANGE_REQUEST = gql`
    mutation ChangeRequest(
        $id : String
    ) {
        deleteChangeRequest(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_GLASS_PANEL = gql`
    mutation GlassPanel(
        $id: String,
        $subscriptionId: String,
        $policyNo: String,
        $VATRegNo: String,
        $insured_name: String,
        $insured_occupation: String,
        $insured_address: String,
        $insured_tellNo: String,
        $occurrence_dateTime: Date,
        $occurrence_causeBreakage: String,
        $occurrence_address: String,
        $occurrence_nameAddressBreakagePerson: String,
        $occurrence_nameAddressWitnesses: String,
        $premises_address: String,
        $premises_isOccupied: String,
        $premises_isOccupiedWhom: String,
        $premises_occupiedPurpose: String,
        $vehicle_make: String,
        $vehicle_regNo: String,
        $vehicle_model: String,
        $vehicle_year: String,
        $vehicle_windscreen: String,
        $vehicle_driverName: String,
        $vehicle_licenseNo: String,
        $vehicle_placeIssued: String,
        $vehicle_dateIssued: Date,
        $brokenGlassDetail_description: String,
        $brokenGlassDetail_size: String,
        $brokenGlassDetail_crackedORShattered: String,
        $brokenGlassDetail_signWriting: String,
        $value_totalValue: String,
        $value_lastDate: Date,
        $otherInsurance_isCovering: String,
        $otherInsurance_insurerName: String,
        $declaration_capacity: String,
        $declaration_lastValue: String,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addGlassPanel(
        id:$id,
        subscriptionId: $subscriptionId,
        policyNo: $policyNo,
        VATRegNo: $VATRegNo,
        insured_name: $insured_name,
        insured_occupation: $insured_occupation,
        insured_address: $insured_address,
        insured_tellNo: $insured_tellNo,
        occurrence_dateTime: $occurrence_dateTime,
        occurrence_causeBreakage: $occurrence_causeBreakage,
        occurrence_address: $occurrence_address,
        occurrence_nameAddressBreakagePerson: $occurrence_nameAddressBreakagePerson,
        occurrence_nameAddressWitnesses: $occurrence_nameAddressWitnesses,
        premises_address: $premises_address,
        premises_isOccupied: $premises_isOccupied,
        premises_isOccupiedWhom: $premises_isOccupiedWhom,
        premises_occupiedPurpose: $premises_occupiedPurpose,
        vehicle_make: $vehicle_make,
        vehicle_regNo: $vehicle_regNo,
        vehicle_model: $vehicle_model,
        vehicle_year: $vehicle_year,
        vehicle_windscreen: $vehicle_windscreen,
        vehicle_driverName: $vehicle_driverName,
        vehicle_licenseNo: $vehicle_licenseNo,
        vehicle_placeIssued: $vehicle_placeIssued,
        vehicle_dateIssued: $vehicle_dateIssued,
        brokenGlassDetail_description: $brokenGlassDetail_description,
        brokenGlassDetail_size: $brokenGlassDetail_size,
        brokenGlassDetail_crackedORShattered: $brokenGlassDetail_crackedORShattered,
        brokenGlassDetail_signWriting: $brokenGlassDetail_signWriting,
        value_totalValue: $value_totalValue,
        value_lastDate: $value_lastDate,
        otherInsurance_isCovering: $otherInsurance_isCovering,
        otherInsurance_insurerName: $otherInsurance_insurerName,
        declaration_capacity: $declaration_capacity,
        declaration_lastValue: $declaration_lastValue,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_GLASS_PANEL = gql`
    mutation GlassPanel(
        $id : String
    ) {
        deleteGlassPanel(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_SESIRO_KYC = gql`
    mutation SesiroKyc(
        $id: String,
        $subscriptionId: String,
        $pid: String,
        $firstNames: String,
        $surname: String,
        $dateofBirth: Date,
        $title: String,
        $gender: String,
        $citizen: String,
        $nationality: String,
        $postalAddress: String,
        $physicalAddress: String,
        $location: String,
        $workPhone: String,
        $homePhone: String,
        $mobilePhone: String,
        $fax: String,
        $email: String,
        $employer: String,
        $occupation: String,
        $placeOfWork: String,
        $address1: [String],
        $accName: String,
        $accNumber: String,
        $bank: String,
        $branch: String,
        $branchCode: String,
        $business: String,
        $name: String,
        $kycSignature: String,
        $date: Date,
        $place: String,
        $createdById: String,
        $createdByName: String,
    ) {
        addSesiroKyc(
        id:$id,
        subscriptionId: $subscriptionId,
        pid:$pid,
        firstNames: $firstNames,
        surname: $surname,
        dateofBirth: $dateofBirth,,
        title: $title,
        gender: $gender,
        citizen: $citizen,
        nationality: $nationality,
        postalAddress: $postalAddress,
        physicalAddress: $physicalAddress,
        location: $location,
        workPhone: $workPhone,
        homePhone: $homePhone,
        mobilePhone: $mobilePhone,
        fax: $fax,
        email: $email,
        employer: $employer,
        occupation: $occupation,
        placeOfWork: $placeOfWork,
        address1: $address1,
        accName: $accName,
        accNumber: $accNumber,
        bank: $bank,
        branch: $branch,
        branchCode: $branchCode,
        business: $business,
        name: $name,
        kycSignature: $kycSignature,
        date: $date,
        place: $place, 
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_SESIRO_KYC = gql`
    mutation SesiroKyc(
        $id : String
    ) {
        deleteSesiroKyc(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_PROPERTY_DAMAGE = gql`
    mutation PropertyDamage(
        $id: String,
        $subscriptionId: String,
        $policyNo: String,
        $vatRegNo: String,
        $insured_name: String,
        $insured_occupation: String,
        $insured_address: String,
        $insured_telNo: String,
        $lossOccurance_date: Date,
        $lossOccurance_when: String,
        $lossPlace_where: String,
        $lossPlace_isPremisesOccupied: String,
        $lossPlace_whom: String,
        $lossPlace_whenLast: Date,
        $lossPlace_purposeOccupation: String,
        $lossCause_fullDescribe: String,
        $lossCause_otherPartyNameAddress: String,
        $lossPrevious_isSufferedDamage: String,
        $lossPrevious_isSufferedDamageDetails: String,
        $lossPrevious_providerName: String,
        $police_refNo: String,
        $police_stationReported: String,
        $police_reportedDate: Date,
        $other_isOtherPartyInterested: String,
        $other_isOtherPartyInterestedName: String,
        $value_when: String,
        $declaration_capacity: String,
        $declaration_date: Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addPropertyDamage(
        id:$id,
        subscriptionId: $subscriptionId,
        policyNo: $policyNo,
        vatRegNo: $vatRegNo,
        insured_name: $insured_name,
        insured_occupation: $insured_occupation,
        insured_address: $insured_address,
        insured_telNo: $insured_telNo,
        lossOccurance_date: $lossOccurance_date,
        lossOccurance_when: $lossOccurance_when,
        lossPlace_where: $lossPlace_where,
        lossPlace_isPremisesOccupied: $lossPlace_isPremisesOccupied,
        lossPlace_whom: $lossPlace_whom,
        lossPlace_whenLast: $lossPlace_whenLast,
        lossPlace_purposeOccupation: $lossPlace_purposeOccupation,
        lossCause_fullDescribe: $lossCause_fullDescribe,
        lossCause_otherPartyNameAddress: $lossCause_otherPartyNameAddress,
        lossPrevious_isSufferedDamage: $lossPrevious_isSufferedDamage,
        lossPrevious_isSufferedDamageDetails: $lossPrevious_isSufferedDamageDetails,
        lossPrevious_providerName: $lossPrevious_providerName,
        police_refNo: $police_refNo,
        police_stationReported: $police_stationReported,
        police_reportedDate: $police_reportedDate,
        other_isOtherPartyInterested: $other_isOtherPartyInterested,
        other_isOtherPartyInterestedName: $other_isOtherPartyInterestedName,
        value_when: $value_when,
        declaration_capacity: $declaration_capacity,
        declaration_date: $declaration_date,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_PROPERTY_DAMAGE = gql`
    mutation PropertyDamage(
        $id : String
    ) {
        deletePropertyDamage(
            id: $id
        ){
            data
        }
    }
`;


export const CREATE_ENGAGEMENT_ADVICE = gql`
    mutation EngagementAdvice(
        $id: String
        $subscriptionId: String,
        $title: String,
        $firstName: String,
        $surName: String,
        $middleName: String,
        $dateOfBirth: Date,
        $gender: String,
        $maritalStatus: String,
        $noOfChild: String,
        $dateEngaged: Date,
        $designation: String,
        $grade: String,
        $pay: String,
        $userDepartment: String,
        $userSection: String,
        $costCode: String,
        $employeeType: String,
        $leaveEntitlement: String,
        $allBenefits:[String],
        $allowances:[String],
        $dateEngagedOnRL: Date,
        $generalComment: String,
        $gym: String,
        $recClub: String,
        $PMAregistraion: String,
        $pensionFormsRegistraion: String,
        $financialAccountant: String,
        $financialAccountantDate: Date,
        $HRAdvisor: String,
        $HRAdvisorDate: Date,
        $HROfficer: String,
        $HROfficerDate: Date, 
        $createdById:  String,
        $createdByName:  String,
    ) {
        addEngagementAdvice(
        id:$id
        subscriptionId:$subscriptionId,
        title:$title,
        firstName:$firstName,
        surName:$surName,
        middleName:$middleName,
        dateOfBirth:$dateOfBirth,
        gender:$gender,
        maritalStatus:$maritalStatus,
        noOfChild:$noOfChild,
        dateEngaged:$dateEngaged,
        designation:$designation,
        grade:$grade,
        pay:$pay,
        userDepartment:$userDepartment,
        userSection:$userSection,
        costCode:$costCode,
        employeeType:$employeeType,
        leaveEntitlement:$leaveEntitlement,
        allBenefits:$allBenefits,
        allowances:$allowances,
        dateEngagedOnRL:$dateEngagedOnRL,
        generalComment:$generalComment,
        gym:$gym,
        recClub:$recClub,
        PMAregistraion:$PMAregistraion,
        pensionFormsRegistraion:$pensionFormsRegistraion,
        financialAccountant:$financialAccountant,
        financialAccountantDate:$financialAccountantDate,
        HRAdvisor:$HRAdvisor,
        HRAdvisorDate:$HRAdvisorDate,
        HROfficer:$HROfficer,
        HROfficerDate:$HROfficerDate,    
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_ENGAGEMENT_ADVICE = gql`
    mutation EngagementAdvice(
        $id : String
    ) {
        deleteEngagementAdvice(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_EXIT_CLEARANCE = gql`
    mutation ExitClearance(
        $id: String
        $subscriptionId: String,
        $EmpName: String,
        $EmpPayrollNo: String,
        $EmpJobTitle: String,
        $EmpDepartment: String,
        $EmpSection: String,
        $EmpDateOfLastShift: Date,
        $storesComments: String,
        $storesSignature: String,
        $storesDate: Date,
        $securityDriverPermit: String,
        $securityDriverTag: String,
        $securityAccessCard: String,
        $securityComments: String,
        $securitySignature: String,
        $securityDate: Date,
        $accountsInstitutionOne: String,
        $accountsInstitutionTwo: String,
        $accountsInstitutionFour: String,
        $accountsInstitutionThree: String,
        $adminTravelAmount: String,
        $adminSTEAmount: String,
        $adminStaffPurchasesAmount: String,
        $adminCashAdvanceAmount: String,
        $adminOthersAmount: String,
        $mineAccountantSignature: String,
        $mineAccountantDate: Date,
        $isRecreationClubAccount: String,
        $recreationClubComments: String,
        $recreationClubAmountP: String,
        $clubSecretarySignature: String,
        $clubSecretaryDate: Date,
        $tuckShopOutstandingAmountP: String,
        $tuckShopSupervisorSignature: String,
        $tuckShopSupervisorDate: Date,
        $itLaptop: String,
        $itLaptopDate: Date,
        $itLaptopBag: String,
        $itLaptopBagDate: Date,
        $itSecureCable: String,
        $itSecureCableDate: Date,
        $itLearningMedia: String,
        $itComments: String,
        $itSupervisorSignature: String,
        $itSupervisorDate: Date,
        $saActiveDirectAccount: Date,
        $saSyspro: String,
        $saRTO: String,
        $saResourceLink: String,
        $saIsometrix: String,
        $saRLSelfService: String,
        $saOnKey: String,
        $systemAdministrationSupervisorSignature: String,
        $systemAdministrationSupervisorDate: Date,
        $hrShiftWorked: String,
        $hrOvertimeWorked: String,
        $taSupervisorDate: Date,
        $sectionHeadComments: String,
        $SupervisorSignature: String,
        $SupervisorDate: Date,
        $adminCellPhone: String,
        $adminCellPhoneBillAmount: String,
        $adminTelePhoneBillAmount: String,
        $gmSecretarySignature: String,
        $gmSecretaryDate: Date,
        $clinicExitMedicalExam: String,
        $clinicComments: String,
        $mineDoctorSignature: String,
        $mineDoctorDate: Date,
        $HousingInspection: String,
        $housingComments: String,
        $housingRepairCost: String,
        $HROfficalSignature: String,
        $utilitiesOfficialSignature: String,
        $utilitiesOfficialDate: Date,
        $controlAutomationAccount: String,
        $controlTelephonePin: String,
        $controlVoiceRadioRecovered: String,
        $controlComments: String,
        $ciOfficicalSignature: String,
        $ciOfficicalDate: Date,
        $recruitmentExitInterview: String,
        $recruitmentExitInterviewComments: String,
        $recruitmentPensionWithdrawal: String,
        $recruitmentPensionWithdrawalComments: String,
        $recruitmentTerminationAdvice: String,
        $recruitmentRecord: String,
        $recruitmentPermitSurrendered: String,
        $recruitmentComments: String,
        $humanResourcesOfficialSignature: String,
        $humanResourcesOfficialDate: Date,
        $employeeName: String,
        $employeeIsAgree: String,
        $employeeComments: String,
        $employeeSignature: String,
        $employeeDate: Date,
        $HRManagerComments: String,
        $HRManagerSignature: String,
        $HRManagerCommentsDate: Date,
        # $title: String,
        # $firstName: String,
        # $surName: String,
        # $middleName: String,
        # $dateOfBirth: Date,
        # $gender: String,
        # $maritalStatus: String,
        # $noOfChild: String,
        # $dateEngaged: Date,
        # $designation: String,
        # $grade: String,
        # $pay: String,
        # $userDepartment: String,
        # $userSection: String,
        # $costCode: String,
        # $employeeType: String,
        # $leaveEntitlement: String,
        # $allBenefits:[String],
        # $allowances:[String],
        # $dateEngagedOnRL: Date,
        # $generalComment: String,
        # $gym: String,
        # $recClub: String,
        # $PMAregistraion: String,
        # $pensionFormsRegistraion: String,
        # $financialAccountant: String,
        # $financialAccountantDate: Date,
        # $HRAdvisor: String,
        # $HRAdvisorDate: Date,
        # $HROfficer: String,
        # $HROfficerDate: Date, 
        $createdById:  String,
        $createdByName:  String,
    ) {
        addExitClearance(
        id:$id
        subscriptionId:$subscriptionId,
        EmpName: $EmpName,
        EmpPayrollNo: $EmpPayrollNo,
        EmpJobTitle: $EmpJobTitle,
        EmpDepartment: $EmpDepartment,
        EmpSection: $EmpSection,
        EmpDateOfLastShift: $EmpDateOfLastShift,
        storesComments: $storesComments,
        storesSignature: $storesSignature,
        storesDate: $storesDate,
        securityDriverPermit: $securityDriverPermit,
        securityDriverTag: $securityDriverTag,
        securityAccessCard: $securityAccessCard,
        securityComments: $securityComments,
        securitySignature: $securitySignature,
        securityDate: $securityDate,
        accountsInstitutionOne: $accountsInstitutionOne,
        accountsInstitutionTwo: $accountsInstitutionTwo,
        accountsInstitutionFour: $accountsInstitutionFour,
        accountsInstitutionThree: $accountsInstitutionThree,
        adminTravelAmount: $adminTravelAmount,
        adminSTEAmount: $adminSTEAmount,
        adminStaffPurchasesAmount: $adminStaffPurchasesAmount,
        adminCashAdvanceAmount: $adminCashAdvanceAmount,
        adminOthersAmount: $adminOthersAmount,
        mineAccountantSignature: $mineAccountantSignature,
        mineAccountantDate: $mineAccountantDate,
        isRecreationClubAccount: $isRecreationClubAccount,
        recreationClubComments: $recreationClubComments,
        recreationClubAmountP: $recreationClubAmountP,
        clubSecretarySignature: $clubSecretarySignature,
        clubSecretaryDate: $clubSecretaryDate,
        tuckShopOutstandingAmountP: $tuckShopOutstandingAmountP,
        tuckShopSupervisorSignature: $tuckShopSupervisorSignature,
        tuckShopSupervisorDate: $tuckShopSupervisorDate,
        itLaptop: $itLaptop,
        itLaptopDate: $itLaptopDate,
        itLaptopBag: $itLaptopBag,
        itLaptopBagDate: $itLaptopBagDate,
        itSecureCable: $itSecureCable,
        itSecureCableDate: $itSecureCableDate,
        itLearningMedia: $itLearningMedia,
        itComments: $itComments,
        itSupervisorSignature: $itSupervisorSignature,
        itSupervisorDate: $itSupervisorDate,
        saActiveDirectAccount: $saActiveDirectAccount,
        saSyspro: $saSyspro,
        saRTO: $saRTO,
        saResourceLink: $saResourceLink,
        saIsometrix: $saIsometrix,
        saRLSelfService: $saRLSelfService,
        saOnKey: $saOnKey,
        systemAdministrationSupervisorSignature: $systemAdministrationSupervisorSignature,
        systemAdministrationSupervisorDate: $systemAdministrationSupervisorDate,
        hrShiftWorked: $hrShiftWorked,
        hrOvertimeWorked: $hrOvertimeWorked,
        taSupervisorDate: $taSupervisorDate,
        sectionHeadComments: $sectionHeadComments,
        SupervisorSignature: $SupervisorSignature,
        SupervisorDate: $SupervisorDate,
        adminCellPhone: $adminCellPhone,
        adminCellPhoneBillAmount: $adminCellPhoneBillAmount,
        adminTelePhoneBillAmount: $adminTelePhoneBillAmount,
        gmSecretarySignature: $gmSecretarySignature,
        gmSecretaryDate: $gmSecretaryDate,
        clinicExitMedicalExam: $clinicExitMedicalExam,
        clinicComments: $clinicComments,
        mineDoctorSignature: $mineDoctorSignature,
        mineDoctorDate: $mineDoctorDate,
        HousingInspection: $HousingInspection,
        housingComments: $housingComments,
        housingRepairCost: $housingRepairCost,
        HROfficalSignature: $HROfficalSignature,
        utilitiesOfficialSignature: $utilitiesOfficialSignature,
        utilitiesOfficialDate: $utilitiesOfficialDate,
        controlAutomationAccount: $controlAutomationAccount,
        controlTelephonePin: $controlTelephonePin,
        controlVoiceRadioRecovered: $controlVoiceRadioRecovered,
        controlComments: $controlComments,
        ciOfficicalSignature: $ciOfficicalSignature,
        ciOfficicalDate: $ciOfficicalDate,
        recruitmentExitInterview: $recruitmentExitInterview,
        recruitmentExitInterviewComments: $recruitmentExitInterviewComments,
        recruitmentPensionWithdrawal: $recruitmentPensionWithdrawal,
        recruitmentPensionWithdrawalComments: $recruitmentPensionWithdrawalComments,
        recruitmentTerminationAdvice: $recruitmentTerminationAdvice,
        recruitmentRecord: $recruitmentRecord,
        recruitmentPermitSurrendered: $recruitmentPermitSurrendered,
        recruitmentComments: $recruitmentComments,
        humanResourcesOfficialSignature: $humanResourcesOfficialSignature,
        humanResourcesOfficialDate: $humanResourcesOfficialDate,
        employeeName: $employeeName,
        employeeIsAgree: $employeeIsAgree,
        employeeComments: $employeeComments,
        employeeSignature: $employeeSignature,
        employeeDate: $employeeDate,
        HRManagerComments: $HRManagerComments,
        HRManagerSignature: $HRManagerSignature,
        HRManagerCommentsDate: $HRManagerCommentsDate,
        # title:$title,
        # firstName:$firstName,
        # surName:$surName,
        # middleName:$middleName,
        # dateOfBirth:$dateOfBirth,
        # gender:$gender,
        # maritalStatus:$maritalStatus,
        # noOfChild:$noOfChild,
        # dateEngaged:$dateEngaged,
        # designation:$designation,
        # grade:$grade,
        # pay:$pay,
        # userDepartment:$userDepartment,
        # userSection:$userSection,
        # costCode:$costCode,
        # employeeType:$employeeType,
        # leaveEntitlement:$leaveEntitlement,
        # allBenefits:$allBenefits,
        # allowances:$allowances,
        # dateEngagedOnRL:$dateEngagedOnRL,
        # generalComment:$generalComment,
        # gym:$gym,
        # recClub:$recClub,
        # PMAregistraion:$PMAregistraion,
        # pensionFormsRegistraion:$pensionFormsRegistraion,
        # financialAccountant:$financialAccountant,
        # financialAccountantDate:$financialAccountantDate,
        # HRAdvisor:$HRAdvisor,
        # HRAdvisorDate:$HRAdvisorDate,
        # HROfficer:$HROfficer,
        # HROfficerDate:$HROfficerDate,    
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_EXIT_CLEARANCE = gql`
    mutation ExitClearance(
        $id : String
    ) {
        deleteExitClearance(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_ACTING = gql`
    mutation Acting(
        $id: String
        $subscriptionId: String,
        $jobTitle: String,
        $userFullName: String,
        $prNumber: String,
        $jobGrade: String,
        $appointedTo: String,
        $fromDate: Date,
        $toDate: Date,
        $noOfDays: String,
        $relivedEmployeeName: String,
        $relivedGrade: String,
        $relivedJobTitle: String,
        $reasonForActing: String,
        $originator: String,
        $hrManagerText: String,
        $mbaManager: String,
        $HOD: String,
        $finantialAccountant: String,
        $originatorSign: String, 
        $mbaManagerSign: String, 
        $HODSign: String, 
        $finantialAccountantSign: String,
        $actualForpayment: String,
        $occupationActed: String,
        $jobGradeActing: String,
        $salaryScale: String,
        $occActingEmp: String,
        $jobGradeActingEmp: String,
        $basicPay: String,
        $difference: String,
        $generalComment: String
        $createdById:  String,
        $createdByName:  String,
    ) {
        addActing(
        id:$id
        subscriptionId:$subscriptionId,
        jobTitle: $jobTitle,
        userFullName: $userFullName,
        prNumber: $prNumber,
        jobGrade: $jobGrade,
        appointedTo: $appointedTo,
        fromDate: $fromDate,
        toDate: $toDate,
        noOfDays: $noOfDays,
        relivedEmployeeName: $relivedEmployeeName,
        relivedGrade: $relivedGrade,
        relivedJobTitle: $relivedJobTitle,
        reasonForActing: $reasonForActing,
        originator: $originator,
        hrManagerText: $hrManagerText,
        mbaManager: $mbaManager,
        HOD: $HOD,
        finantialAccountant: $finantialAccountant,
        originatorSign: $originatorSign, 
        mbaManagerSign: $mbaManagerSign, 
        HODSign: $HODSign, 
        finantialAccountantSign: $finantialAccountantSign,
        actualForpayment: $actualForpayment,
        occupationActed: $occupationActed,
        jobGradeActing: $jobGradeActing,
        salaryScale: $salaryScale,
        occActingEmp: $occActingEmp,
        jobGradeActingEmp: $jobGradeActingEmp,
        basicPay: $basicPay,
        difference: $difference,
        generalComment: $generalComment,   
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_ACTING = gql`
    mutation Acting(
        $id : String
    ) {
        deleteActing(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_CHANGE_OF_STATUS = gql`
    mutation ChangeOfStatus(
        $id: String
        $subscriptionId: String,
        $userFullName: String,
        $date: Date,
        $designation: String,
        $payrollNo: String,
        $generalComment: String,
        $HOD: String, 
        $MBAManager:String, 
        $SectionHead:String,
        $HODSign: String, 
        $MBAManagerSign:String, 
        $SectionHeadSign:String,
        $reasonForChange: [String],
        $jobGrade: String,
        $name: String,
        $pay: String,
        $section: String,
        $department: String,
        $costCenter: String,
        $other: String,
        $effectiveDate: String,
        $shiftAllowance: String,
        $contopsAllowance: String,
        $underGroundAllowance: String,
        $others: String,
        $others2: String,
        $others3: String
        $createdById:  String,
        $createdByName:  String,
    ) {
        addChangeOfStatus(
        id:$id
        subscriptionId:$subscriptionId,
        userFullName: $userFullName,
        date: $date,
        designation: $designation,
        payrollNo: $payrollNo,
        generalComment: $generalComment,
        HOD: $HOD, 
        MBAManager:$MBAManager, 
        SectionHead:$SectionHead,
        HODSign: $HODSign, 
        MBAManagerSign:$MBAManagerSign, 
        SectionHeadSign:$SectionHeadSign,
        reasonForChange: $reasonForChange,
        jobGrade: $jobGrade,
        name: $name,
        pay: $pay,
        section: $section,
        department: $department,
        costCenter: $costCenter,
        other: $other,
        effectiveDate: $effectiveDate,
        shiftAllowance: $shiftAllowance,
        contopsAllowance: $contopsAllowance,
        underGroundAllowance: $underGroundAllowance,
        others: $others,
        others2: $others2,
        others3: $others3, 
        createdById: $createdById,
        createdByName: $createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_CHANGE_OF_STATUS = gql`
    mutation Acting(
        $id : String
    ) {
        deleteChangeOfStatus(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_PREPAYMENT = gql`
    mutation Prepayment(
        $id: String,
        $subscriptionId: String,
        $Sname: String,
        $Scode: String,
        $Scontact: String,
        $Sphone: String,
        $GoodsDesc: String,
        $BSPurpose: String,
        $TCost: String,
        $AdvanceAmt: String,
        $RqNo: String,
        $PO: String,
        $AdvDesc: String,
        $Effort: String,
        $MCMBenefit: String,
        $DDate: Date,
        $AdvDate: Date,
        $Bname: String,
        $BDate: Date,
        $BSignature: String,
        $MaterialMan: String,
        $MMDate: Date,
        $MMSignature: String,
        $CommercialMan: String,
        $CMDate: Date,
        $CMSignature: String,
        $GeneralMan: String,
        $GMDate: Date,
        $GMSignature: String,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addPrepayment(
        id:$id
        subscriptionId:$subscriptionId,
        Sname: $Sname,
        Scode: $Scode,
        Scontact: $Scontact,
        Sphone: $Sphone,
        GoodsDesc:$GoodsDesc,
        BSPurpose: $BSPurpose,
        TCost: $TCost,
        AdvanceAmt: $AdvanceAmt,
        RqNo: $RqNo,
        PO: $PO,
        AdvDesc: $AdvDesc,
        Effort: $Effort,
        MCMBenefit: $MCMBenefit,
        DDate: $DDate,
        AdvDate: $AdvDate,
        Bname: $Bname,
        BDate: $BDate,
        BSignature: $BSignature,
        MaterialMan: $MaterialMan,
        MMDate: $MMDate,
        MMSignature: $MMSignature,
        CommercialMan: $CommercialMan,
        CMDate: $CMDate,
        CMSignature: $CMSignature,
        GeneralMan: $GeneralMan,
        GMDate: $GMDate,
        GMSignature: $GMSignature,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            id
            subscriptionId
            Sname
        }
    }
`;

export const CREATE_EXPENSE_CLAIM = gql`
    mutation ExpenseClaim(
        $id: String
        $subscriptionId: String,
        $name: String,
        $pr: String,
        $tel: String,
        $designation:String,
        $cost:String,
        $dateFrom: Date,
        $dateTo: Date, 
        $voucher: String, 
        $purposeofTrip:String,
        $hotel:String,
        $hotelCost: String, 
        $hotelForeign: String,
        $allowance:String,
        $allowanceCost:String,
        $allowanceForeign:String,
        $allowance1:String,
        $dailyCost:String,
        $dailyForeign: String,
        $mealallowance: String,
        $mealCost:String,
        $mealForeign:String,
        $residence:String,
        $residenceCost:String,
        $residenceForeign:String,
        $car:String,
        $carCost:String,
        $carForeign:String, 
        $publicTransport:String,
        $publicCost:String,
        $publicForeign:String,
        $other:String,
        $otherCost:String,
        $otherForeign:String,
        $total:String,
        $total1:String,
        $total2:String,
        $ApplicantSign:String,
        $date:Date,
        $SectionSignature:String,
        $date1:Date,
        $DepartmentSignature:String,
        $date2:Date,
        $cash:String,
        $approved:String,
        $applicantDue:String,
        $costAllocation:String,
        $processor:String,
        $checker:String,   
    ){ 
    addExpenseClaim(
        id:$id
        subscriptionId:$subscriptionId,
        name: $name,
        pr: $pr,
        tel: $tel,
        designation: $designation,
        cost: $cost,
        dateFrom: $dateFrom,
        dateTo: $dateTo, 
        voucher: $voucher, 
        purposeofTrip: $purposeofTrip,
        hotel: $hotel,
        hotelCost: $hotelCost, 
        hotelForeign: $hotelForeign,
        allowance: $allowance,
        allowanceCost: $allowanceCost,
        allowanceForeign: $allowanceForeign,
        allowance1: $allowance1,
        dailyCost: $dailyCost,
        dailyForeign: $dailyForeign,
        mealallowance: $mealallowance,
        mealCost: $mealCost,
        mealForeign: $mealForeign,
        residence: $residence,
        residenceCost: $residenceCost,
        residenceForeign: $residenceForeign,
        car: $car,
        carCost: $carCost,
        carForeign: $carForeign, 
        publicTransport: $publicTransport,
        publicCost: $publicCost,
        publicForeign: $publicForeign,
        other: $other,
        otherCost: $otherCost,
        otherForeign: $otherForeign,
        total: $total,
        total1: $total1,
        total2: $total2,
        ApplicantSign: $ApplicantSign,
        date: $date,
        SectionSignature: $SectionSignature,
        date1: $date1,
        DepartmentSignature: $DepartmentSignature,
        date2: $date2,
        cash: $cash,
        approved: $approved,
        applicantDue: $applicantDue,
        costAllocation: $costAllocation,
        processor: $processor,
        checker: $checker,   
        ){
            id
            subscriptionId
            name
        }
    }
`;


export const CREATE_PAYMENT_VOUCHER = gql`
    mutation PaymentVoucher(
        $id: String
        $subscriptionId: String,
        $date: Date,
        $payee: String,
        $voucherNo: String,
        $chequeNo: String,
        $details1: String,
        $details2: String,
        $amount1: String,
        $amount2: String, 
        $amount3: String, 
        $amount4: String,
        $amount5: String, 
        $amount6: String, 
        $amount7: String,
        $amount8: String,
        $amount9: String,
        $amount10: String,
        $amount11: String,
        $amount12: String,
        $amount13: String,
        $amount14: String,
        $amount15: String,
        $amount16: String,
        $amount17: String,
        $amount18: String,
        $amount19: String,
        $amount20: String,
        $total1: String,
        $total2: String,
        $total3: String,
        $total4: String,
        $code1: String,
        $code2: String,
        $code3: String,
        $code4: String,
        $code5: String,
        $code6: String,
        $code7: String,
        $code8: String,
        $description1: String,
        $description2: String,
        $description3: String,
        $description4: String,
        $description5: String,
        $description6: String,
        $description7: String,
        $description8: String,
        $ReceivedBy: String,
        $ReceiverSign: String,
        $ReceiverDate: Date,
        $PreparedBy: String,
        $PreparedSign: String,
        $PreparedDate: Date,
        $CheckedBy: String,
        $CheckedSign: String,
        $CheckedDate: Date,
        $AuthorizedBy: String,
        $AuthorizedSign: String,
        $AuthorizedDate: Date,
        $ApprovedBy: String,
        $ApprovedSign: String,
        $ApprovedDate: Date,
        $createdById: String,
        $createdByName: String,  
    ) {
        addPaymentVoucher(
            id:$id,
            subscriptionId:$subscriptionId,
            date:$date,
            payee: $payee,
            voucherNo: $voucherNo,
            chequeNo: $chequeNo,
            details1: $details1,
            details2: $details2,
            amount1: $amount1, 
            amount2: $amount2, 
            amount3: $amount3,
            amount4: $amount4,
            amount5: $amount5, 
            amount6: $amount6,
            amount7: $amount7,
            amount8: $amount8,
            amount9: $amount9,
            amount10: $amount10,
            amount11: $amount11,
            amount12: $amount12,
            amount13: $amount13,
            amount14: $amount14,
            amount15: $amount15,
            amount16: $amount16,
            amount17: $amount17,
            amount18: $amount18,
            amount19: $amount19,
            amount20: $amount20,
            total1: $total1, 
            total2: $total2,
            total3: $total3,
            total4: $total4,
            code1: $code1,
            code2: $code2,
            code3: $code3,
            code4: $code4,
            code5: $code5,
            code6: $code6,
            code7: $code7,
            code8: $code8,
            description1: $description1,
            description2: $description2,
            description3: $description3,
            description4: $description4,
            description5: $description5,
            description6: $description6,
            description7: $description7,
            description8: $description8,
            ReceivedBy: $ReceivedBy,
            ReceiverSign: $ReceiverSign,
            ReceiverDate: $ReceiverDate,
            PreparedBy: $PreparedBy,
            PreparedSign: $PreparedSign,
            PreparedDate: $PreparedDate,
            CheckedBy: $CheckedBy,
            CheckedSign: $CheckedSign,
            CheckedDate: $CheckedDate,
            AuthorizedBy: $AuthorizedBy,
            AuthorizedSign: $AuthorizedSign,
            AuthorizedDate: $AuthorizedDate,
            ApprovedBy: $ApprovedBy,
            ApprovedSign: $ApprovedSign,
            ApprovedDate: $ApprovedDate,
            createdById: $createdById,
            createdByName: $createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_EXPENSE_CLAIM = gql`
    mutation ExpenseClaim(
        $id : String
    ) {
        deleteExpenseClaim(
            id: $id
        ){
            id
            subscriptionId
            name
        }
    }
`;

export const DELETE_PREPAYMENT = gql`
    mutation Prepayment(
        $id : String
    ) {
        deletePrepayment(
            id: $id
        ){
            id
            subscriptionId
            name
        }
    }
`;

export const DELETE_PAYMENT_VOUCHER_ = gql`
    mutation PaymentVoucher(
        $id : String
    ) {
        deletePaymentVoucher(
            id: $id
        ){
            id
            subscriptionId
            Sname
        }
    }
`;

export const CREATE_FUNDS_APPLICATION = gql`
    mutation FundsApplication (
        $id: String,
        $subscriptionId: String,
        $expclass: String,
        $application: String, 
        $userProjectTitle: String,
        $userSubject: String,
        $userCostAllocation: String, 
        $authorisedAmt1: String,
        $amtUsedtodate1: String,
        $amtAppliedFor1: String,
        $authorisedAmt2: String,
        $amtUsedtodate2: String,
        $amtAppliedFor2: String,
        $authorisedAmt3: String,
        $amtUsedtodate3: String,
        $amtAppliedFor3: String,
        $authorisedAmt4: String,
        $amtUsedtodate4: String,
        $amtAppliedFor4: String,
        $authorisedAmt5: String,
        $amtUsedtodate5: String,
        $amtAppliedFor5: String,
        $authorisedAmt6: String,
        $amtUsedtodate6: String,
        $amtAppliedFor6: String,
        $authorisedAmt7: String,
        $amtUsedtodate7: String,
        $amtAppliedFor7: String,
        $authorisedAmt8: String,
        $amtUsedtodate8: String,
        $amtAppliedFor8: String,
        $authorisedAmt9: String,
        $amtUsedtodate9: String,
        $amtAppliedFor9: String,
        $authorisedAmt10: String,
        $amtUsedtodate10: String,
        $amtAppliedFor10: String,
        $vat1: String,
        $escalation1:  String,
        $contingency1: String,
        $total1: String,
        $totalauthorisedexpenditure: String,
        $revisedauthorisedfec: String,
        $vat2: String,
        $escalation2:  String,
        $contingency2: String,
        $total2: String,
        $vat3:  String,
        $escalation3: String,
        $contingency3: String,
        $total3: String,
        $vat4: String,
        $escalation4: String,
        $contingency4: String,
        $total4: String,
        $originator: String,
        $originatorSign: String,
        $date1: Date,
        $sectionHead: String,
        $sectionHeadSign: String,
        $date2: Date,
        $HoD: String,
        $HoDSign: String,
        $date3: Date,
        $assistantAccountant: String,
        $assistantAccountantSign: String,
        $date4: Date,
        $costAccountant: String,
        $costAccountantSign: String,
        $date5: Date,
        $financeManager: String,
        $financeManagerSign: String,
        $date6: Date,
        $commercialManager: String,
        $commercialManagerSign: String,
        $date7: Date,
        $generalManager: String,
        $generalManagerSign: String,
        $date8: Date,
        $deputyManagingDirector: String,
        $deputyManagingDirectorSign: String,
        $date9: Date, 
        $comments: String,
        $generalComment: String,
        $createdById: String,
        $createdByName: String,
    ) {
        addFundsApplication(
        id: $id,
        subscriptionId: $subscriptionId,
        expclass: $expclass, 
        application: $application, 
        userProjectTitle: $userProjectTitle,
        userSubject: $userSubject,
        userCostAllocation: $userCostAllocation,
        authorisedAmt1: $authorisedAmt1,
        amtUsedtodate1: $amtUsedtodate1,
        amtAppliedFor1: $amtAppliedFor1,
        authorisedAmt2: $authorisedAmt2,
        amtUsedtodate2: $amtUsedtodate2,
        amtAppliedFor2: $amtAppliedFor2,
        authorisedAmt3: $authorisedAmt3,
        amtUsedtodate3: $amtUsedtodate3,
        amtAppliedFor3: $amtAppliedFor3,
        authorisedAmt4: $authorisedAmt4,
        amtUsedtodate4: $amtUsedtodate4,
        amtAppliedFor4: $amtAppliedFor4,
        authorisedAmt5: $authorisedAmt5,
        amtUsedtodate5: $amtUsedtodate5,
        amtAppliedFor5: $amtAppliedFor5,
        authorisedAmt6: $authorisedAmt6,
        amtUsedtodate6: $amtUsedtodate6,
        amtAppliedFor6: $amtAppliedFor6,
        authorisedAmt7: $authorisedAmt7,
        amtUsedtodate7: $amtUsedtodate7,
        amtAppliedFor7: $amtAppliedFor7,
        authorisedAmt8: $authorisedAmt8,
        amtUsedtodate8: $amtUsedtodate8,
        amtAppliedFor8: $amtAppliedFor8,
        authorisedAmt9: $authorisedAmt9,
        amtUsedtodate9: $amtUsedtodate9,
        amtAppliedFor9: $amtAppliedFor9,
        authorisedAmt10: $authorisedAmt10,
        amtUsedtodate10: $amtUsedtodate10,
        amtAppliedFor10: $amtAppliedFor10,
        vat1: $vat1,
        escalation1: $escalation1,
        contingency1: $contingency1,
        total1: $total1,
        totalauthorisedexpenditure: $totalauthorisedexpenditure,
        revisedauthorisedfec: $revisedauthorisedfec,
        vat2: $vat2,
        escalation2: $escalation2,
        contingency2: $contingency2,
        total2: $total2,
        vat3: $vat3,
        escalation3: $escalation3,
        contingency3: $contingency3,
        total3: $total3,
        vat4: $vat4,
        escalation4: $escalation4,
        contingency4: $contingency4,
        total4: $total4,
        originator: $originator,
        originatorSign: $originatorSign,
        date1: $date1,
        sectionHead: $sectionHead,
        sectionHeadSign: $sectionHeadSign,
        date2: $date2,
        HoD: $HoD,
        HoDSign: $HoDSign,
        date3: $date3,
        assistantAccountant: $assistantAccountant,
        assistantAccountantSign: $assistantAccountantSign,
        date4: $date4,
        costAccountant: $costAccountant,
        costAccountantSign: $costAccountantSign,
        date5: $date5,
        financeManager: $financeManager,
        financeManagerSign: $financeManagerSign,
        date6: $date6,
        commercialManager: $commercialManager,
        commercialManagerSign: $commercialManagerSign,
        date7: $date7,
        generalManager: $generalManager,
        generalManagerSign: $generalManagerSign,
        date8: $date8,
        deputyManagingDirector: $deputyManagingDirector,
        deputyManagingDirectorSign: $deputyManagingDirectorSign,
        date9: $date9,
        comments: $comments,
        generalComment: $generalComment,
        createdById: $createdById,
        createdByName: $createdByName,
        ) {
            id
            subscriptionId
            expclass
        }
    }
`;
export const DELETE_FUNDS_APPLICATION = gql`
    mutation FundsApplication(
        $id : String
    ) {
        deleteFundsApplication(
            id: $id
        ){
            id
            subscriptionId
            expclass
        }
    }
`;


export const CREATE_ADVANCE_REQUISITION = gql`
    mutation AdvanceRequisition(
        $id: String
        $subscriptionId: String,
        $from:String,
        $prn: String,
        $costCentre:String,
        $tel:String,
        $cheque: String,
        $cheque1: String,
        $amount: String,
        $amount1: String,
        $tripPurpose: String,
        $date:Date,
        $time:String,
        $date1:Date,
        $time1:String,
        $hotel: String,
        $hotelCost: String, 
        $hotelForeign: String,
        $allowance: String,
        $allowanceCost: String,
        $allowanceRand:String,
        $allowanceForeign: String,
        $allowance1: String,
        $dailyCost: String,
        $dailyRand: String,
        $dailyForeign: String,
        $mealallowance: String,
        $mealCost: String,
        $mealRand:String,
        $mealForeign:String
        $residence: String,
        $residenceCost: String,
        $residenceRand:String,
        $residenceForeign: String,
        $car: String,
        $carCost: String,
        $carRand:String,
        $carForeign:String, 
        $publicTransport:String,
        $publicCost: String,
        $publicRand: String,
        $publicForeign: String,
        $other: String,
        $otherCost: String,
        $otherRand: String,
        $otherForeign: String,
        $other1: String,
        $otherCost1: String,
        $otherRand1: String,
        $otherForeign1: String,
        $total: String,
        $total1: String,
        $total2: String,
        $applicant: String,
        $ApplicantSign: String,
        $date2: Date,
        $sectionHead: String,
        $SectionSignature: String,
        $date3: Date,
        $headofDepartment: String,
        $DepartmentSignature: String,
        $date4: Date,  
        $createdById:  String,
        $createdByName:  String,
    ) {
        addAdvanceRequisition(
        id:$id
        subscriptionId:$subscriptionId,
        from:$from,
        prn:$prn,
        costCentre:$costCentre,
        tel:$tel,
        cheque:$cheque,
        cheque1:$cheque1,
        amount:$amount,
        amount1:$amount1,
        tripPurpose:$tripPurpose,
        date:$date,
        time:$time,
        date1:$date1,
        time1:$time1,
        hotel: $hotel,
        hotelCost:$hotelCost,
        hotelForeign:$hotelForeign,
        allowance:$allowance,
        allowanceCost:$allowanceCost,
        allowanceRand:$allowanceRand,
        allowanceForeign:$allowanceForeign,
        allowance1:$allowance1,
        dailyCost:$dailyCost,
        dailyRand:$dailyRand,
        dailyForeign:$dailyForeign,
        mealallowance:$mealallowance,
        mealCost:$mealCost,
        mealRand:$mealRand,
        mealForeign:$mealForeign,
        residence:$residence,
        residenceCost:$residenceCost,
        residenceRand:$residenceRand,
        residenceForeign:$residenceForeign,
        car:$car,
        carCost:$carCost,
        carRand:$carRand,
        carForeign:$carForeign,
        publicTransport:$publicTransport,
        publicCost:$publicCost,
        publicRand:$publicRand,
        publicForeign:$publicForeign,
        other:$other,
        otherCost:$otherCost,
        otherRand:$otherRand,
        otherForeign:$otherForeign,
        other1:$other1,
        otherCost1:$otherCost1,
        otherRand1:$otherRand1,
        otherForeign1:$otherForeign1,
        total:$total,
        total1:$total1,
        total2:$total2,
        applicant:$applicant,
        ApplicantSign:$ApplicantSign,
        date2:$date2,
        sectionHead:$sectionHead,
        SectionSignature:$SectionSignature,
        date3:$date3,
        headofDepartment:$headofDepartment,
        DepartmentSignature:$DepartmentSignature,
        date4:$date4,
        createdById:$createdById,
        createdByName:$createdByName,  
        ) {
            id
            subscriptionId
            from
        }
    }
`;

export const DELETE_ADVANCE_REQUISITION = gql`
    mutation AdvanceRequisition(
        $id : String
    ) {
        deleteAdvanceRequisition(
            id: $id
        ){
            id
            subscriptionId
            from
        }
    }
`;

export const CREATE_PROPOSAL_ISSUE = gql`
    mutation ProposalIssue(
        $id: String
        $subscriptionId: String,
        $name: String,
        $surName: String,
        $site: String,
        $idNo: String,
        $employeeSecurityNo: String,
        $title: String,
        $maritalStatus: String,
        $email: String,
        $postal: String,
        $telNo: String,
        $residential: String,
        $mobile: String,
        $inceptionDateFrom: String,
        $inceptionDateTo: String,
        $premiumPaymentMethod: String,
        $buildingResidentialAddressFirst: String,
        $buildingResidentialAddressSecond: String,
        $buildingResidentialAddresThird: String,
        $buildingResidenceRentedFirst: String,
        $buildingResidenceRentedSecond: String,
        $buildingResidenceRentedThird: String,
        $buildingRoofConstructionOfWallsFirst: String,
        $buildingRoofConstructionOfWallsSecond: String,
        $buildingRoofConstructionOfWallsThree: String,
        $buildingRoofConstructionFirst: String,
        $buildingRoofConstructionSecond: String,
        $buildingRoofConstructionThree: String,
        $buildingCurrentlyOccupiedFirst: String,
        $buildingCurrentlyOccupiedSecond: String,
        $buildingCurrentlyOccupiedThird: String,
        $buildingInoccupancyFirst: String,
        $buildingInoccupancySecond: String,
        $buildingInoccupancyThree: String,
        $buildingInsuranceReplacementFirst: String,
        $buildingInsuranceReplacementSecond: String,
        $buildingInsuranceReplacementThree: String,
        $buildingBondholderFirst: String,
        $buildingBondholderSecond: String,
        $buildingBondholderThree: String,
        $buildingFirstThatchedStructures: String,
        $buildingSecondThatchedStructures: String,
        $buildingThreeThatchedStructures: String,
        $buildingOneSpecify: String,
        $buildingTwoSpecify: String,
        $buildingThreeSpecify: String,
        $residentalAddressFirst: String,
        $residentalAddressSecond: String,
        $StreetAndLocationFirst: String,
        $StreetAndLocationSecond: String,
        $constructionOfWallsFirst: String,
        $constructionOfWallsSecond: String,
        $roofConstructionFirst: String,
        $roofConstructionSecond: String,
        $isTheResidenceRentedFirst: String,
        $isTheResidenceRentedSecond: String,
        $residenceOccupiedFirst: String,
        $residenceOccupiedSecond: String,
        $unoccupancyPerAnnumFirst: String,
        $unoccupancyPerAnnumSecond: String,
        $haveAnAlarmSystemFirst: String,
        $haveAnAlarmSystemSecond: String,
        $IsTheAlarmLinkedFirst: String,
        $IsTheAlarmLinkedSecond: String,
        $responseCompanyFirst: String,
        $responseCompanySecond: String,
        $unspecifiedItems: String,
        $descriptionSumInsured: String,
        $unspecifiedItemsSectionThree: String,
        $descriptionSumSectionThree: String,
        $motorVechileTypeOfCoverFirst: String,
        $motorVechileTypeOfCoverSecond: String,
        $motorVechileTypeOfCoverThird: String,
        $motorVechileUseOfVechileFirst: String,
        $motorVechileUseOfVechileSecond: String,
        $motorVechileUseOfVechileThird: String,
        $motorVechileYearOfManufautreFirst: String,
        $motorVechileYearOfManufautreSecond: String,
        $motorVechileYearOfManufautreThird: String,
        $motorVechileMakeAndModelFirst: String,
        $motorVechileMakeAndModelSecond: String,
        $motorVechileMakeAndModelThird: String,
        $motorVechileColourFirst: String,
        $motorVechileColourSecond: String,
        $motorVechileColourThird: String,
        $motorVechileRegistrationNumberFirst: String,
        $motorVechileRegistrationNumberSecond: String,
        $motorVechileRegistrationNumberThird: String,
        $motorVechileChasisFirst: String,
        $motorVechileChasisSecond: String,
        $motorVechileChasisThird: String,
        $motorVechileValueFirst: String,
        $motorVechileValueSecond: String,
        $motorVechileValueThird: String,
        $motorVechileImportedVechileFrist: String,
        $motorVechileImportedVechileSecond: String,
        $motorVechileImportedVechileThird: String,
        $motorVechileIMMobiliserFirst: String,
        $motorVechileIMMobiliserSecond: String,
        $motorVechileIMMobiliserThird: String,
        $motorVechileTrackingDeviceFirst: String,
        $motorVechileTrackingDeviceSecond: String,
        $motorVechileTrackingDeviceThird: String,
        $motorVechileMakeTrackingFirst: String,
        $motorVechileMakeTrackingSecond: String,
        $motorVechileMakeTrackingThird: String,
        $motorVechileFinacialRegisterdOwnerFirst: String,
        $motorVechileFinacialRegisterdOwnerSecond: String,
        $motorVechileFinacialRegisterdOwnerThird: String,
        $motorVechileFinacialProposerFirst: String,
        $motorVechileFinacialProposerSecond: String,
        $motorVechileFinacialProposerThird: String,
        $motorVechileFinacialAccessoriesFirst: String,
        $motorVechileFinacialAccessoriesSecond: String,
        $motorVechileFinacialAccessoriesThird: String,
        $motorVechileFinacialValuOfAccessoriesFirst: String,
        $motorVechileFinacialValuOfAccessoriesSecond: String,
        $motorVechileFinacialValuOfAccessoriesThird: String,
        $motorVechileFinacialIntrustCarLoadFirst: String,
        $motorVechileFinacialIntrustCarLoadSecond: String,
        $motorVechileFinacialIntrustCarLoadThird: String,
        $motorVechileBankFirst: String,
        $motorVechileBankSecond: String,
        $motorVechileBankThird: String,
        $motorVechileLoanPeriodMonthFirst: String,
        $motorVechileLoanPeriodMonthSecond: String,
        $motorVechileLoanPeriodMonthThird: String,
        $motorVechileRegDriverDetailsFirst: String,
        $motorVechileRegDriverDetailsSecond: String,
        $motorVechileRegDriverDetailsThird: String,
        $motorVechileSurNameFirst: String,
        $motorVechileSurNameSecond: String,
        $motorVechileSurNameThird: String,
        $motorVechileFirstNameFirst: String,
        $motorVechileFirstNameSecond: String,
        $motorVechileFirstNameThird: String,
        $motorVechileDobFirst: String,
        $motorVechileDobSecond: String,
        $motorVechileDobThird: String,
        $motorVechileEbbCoverdFirst: String,
        $motorVechileEbbCoverdSecond: String,
        $motorVechileEbbCoverdThird: String,
        $motorVechileEaCoverdFirst: String,
        $motorVechileEaCoverdSecond: String,
        $motorVechileEaCoverdThird: String,
        $makeAndModalFirst: String,
        $makeAndModalSecond: String,
        $makeAndModalThird: String,
        $carvansFirstYear: String,
        $carvansSecondYear: String,
        $carvansThirdYear: String,
        $carvansFirstValue: String,
        $carvansSecondValue: String,
        $carvansThirdValue: String,
        $carvansRegistrationNumberFirst: String,
        $carvansRegistrationNumberSecond: String,
        $carvansRegistrationNumberThird: String,
        $make: String,
        $model: String,
        $year: String,
        $value: String,
        $registrationNumber: String,
        $use: String,
        $motorCycleLocked: String,
        $typeOfMotorCycle: String,
        $FinancialInterestMotorcycleLoan: String,
        $bank: String,
        $loanPeriodMonthsLeft: String,
        $sectionRequired: String,
        $totalPremimum: String,
        $nameOfInsurer: String,
        $policyNumber: String,
        $yearFirst: String,
        $descriptionFirst: String,
        $amountFirst: String,
        $yearSecond: String,
        $descriptionSecond: String,
        $amountSecond: String,
        $signatureOfInsuredDate: Date,
        $sesiroDate: Date,
        $sesiroAuthorizeDate: Date,
        $sesiroAuthorize: String,
        $signatureOfInsured: String,
        $sesiroSign: String,   
        $createdById:  String,
        $createdByName:  String,
        $specifiedItemsFirst:String,
        $serialNoFirst:String,
        $sumInsuredFirst:String,
        $premiumFirst:String,
        $specifiedItemsSecond:String,
        $serialNoSecond:String,
        $sumInsuredSecond:String,
        $premiumSecond:String,
        $specifiedItemsThird:String,
        $serialNoThird:String,
        $sumInsuredThird:String,
        $premiumThird:String,
        $specifiedItemsFourth:String,
        $serialNoFourth:String,
        $sumInsuredFourth:String,
        $premiumFourth:String,
        $specifiedItemsFive:String,
        $serialNoFive:String,
        $sumInsuredFive:String, 
        $premiumFive:String,
        $specifiedItemsSix:String,
        $serialNoSix:String,
        $sumInsuredSix:String,
        $premiumSix:String,
        $specifiedItemsSeven:String,
        $serialNoSeven:String,
        $sumInsuredSeven:String,
        $premiumSeven:String,
        $handBagSectionspecifiedItemsFirst: String,
        $handBagSectionserialNoFirst: String,
        $handBagSectionsumInsuredFirst: String,
        $handBagSectionpremiumFirst: String,
        $handBagSectionspecifiedItemsSecond: String,
        $handBagSectionserialNoSecond: String,
        $handBagSectionsumInsuredSecond: String,
        $handBagSectionpremiumSecond: String,
        $handBagSectionspecifiedItemsThird: String,
        $handBagSectionserialNoThird: String,
        $handBagSectionsumInsuredThird: String,
        $handBagSectionpremiumThird: String,
        $handBagSectionspecifiedItemsFourth: String,
        $handBagSectionserialNoFourth: String,
        $handBagSectionsumInsuredFourth: String,
        $handBagSectionpremiumFourth: String,
        $handBagSectionspecifiedItemsFive: String,
        $handBagSectionserialNoFive: String,
        $handBagSectionsumInsuredFive: String,
        $handBagSectionpremiumFive: String,
        $handBagSectionspecifiedItemsSix: String,
        $handBagSectionserialNoSix: String,
        $handBagSectionsumInsuredSix: String,
        $handBagSectionpremiumSix: String,
        $handBagSectionspecifiedItemsSeven: String,
        $handBagSectionserialNoSeven: String,
        $handBagSectionsumInsuredSeven: String,
        $handBagSectionpremiumSeven: String
    ) {
        addProposal(
        id:$id
        subscriptionId:$subscriptionId,
        name: $name,
        surName: $surName,
        site: $site,
        idNo: $idNo,
        employeeSecurityNo: $employeeSecurityNo,
        title: $title,
        maritalStatus: $maritalStatus,
        email: $email,
        postal: $postal,
        telNo: $telNo,
        residential: $residential,
        mobile: $mobile,
        inceptionDateFrom: $inceptionDateFrom,
        inceptionDateTo: $inceptionDateTo,
        premiumPaymentMethod: $premiumPaymentMethod,
        buildingResidentialAddressFirst: $buildingResidentialAddressFirst,
        buildingResidentialAddressSecond: $buildingResidentialAddressSecond,
        buildingResidentialAddresThird: $buildingResidentialAddresThird,
        buildingResidenceRentedFirst: $buildingResidenceRentedFirst,
        buildingResidenceRentedSecond: $buildingResidenceRentedSecond,
        buildingResidenceRentedThird: $buildingResidenceRentedThird,
        buildingRoofConstructionOfWallsFirst: $buildingRoofConstructionOfWallsFirst,
        buildingRoofConstructionOfWallsSecond: $buildingRoofConstructionOfWallsSecond,
        buildingRoofConstructionOfWallsThree: $buildingRoofConstructionOfWallsThree,
        buildingRoofConstructionFirst: $buildingRoofConstructionFirst,
        buildingRoofConstructionSecond: $buildingRoofConstructionSecond,
        buildingRoofConstructionThree: $buildingRoofConstructionThree,
        buildingCurrentlyOccupiedFirst: $buildingCurrentlyOccupiedFirst,
        buildingCurrentlyOccupiedSecond: $buildingCurrentlyOccupiedSecond,
        buildingCurrentlyOccupiedThird: $buildingCurrentlyOccupiedThird,
        buildingInoccupancyFirst: $buildingInoccupancyFirst,
        buildingInoccupancySecond: $buildingInoccupancySecond,
        buildingInoccupancyThree: $buildingInoccupancyThree,
        buildingInsuranceReplacementFirst: $buildingInsuranceReplacementFirst,
        buildingInsuranceReplacementSecond: $buildingInsuranceReplacementSecond,
        buildingInsuranceReplacementThree: $buildingInsuranceReplacementThree,
        buildingBondholderFirst: $buildingBondholderFirst,
        buildingBondholderSecond: $buildingBondholderSecond,
        buildingBondholderThree: $buildingBondholderThree,
        buildingFirstThatchedStructures: $buildingFirstThatchedStructures,
        buildingSecondThatchedStructures: $buildingSecondThatchedStructures,
        buildingThreeThatchedStructures: $buildingThreeThatchedStructures,
        buildingOneSpecify: $buildingOneSpecify,
        buildingTwoSpecify: $buildingTwoSpecify,
        buildingThreeSpecify: $buildingThreeSpecify,
        residentalAddressFirst: $residentalAddressFirst,
        residentalAddressSecond: $residentalAddressSecond,
        StreetAndLocationFirst: $StreetAndLocationFirst,
        StreetAndLocationSecond: $StreetAndLocationSecond,
        constructionOfWallsFirst: $constructionOfWallsFirst,
        constructionOfWallsSecond: $constructionOfWallsSecond,
        roofConstructionFirst: $roofConstructionFirst,
        roofConstructionSecond: $roofConstructionSecond,
        isTheResidenceRentedFirst: $isTheResidenceRentedFirst,
        isTheResidenceRentedSecond: $isTheResidenceRentedSecond,
        residenceOccupiedFirst: $residenceOccupiedFirst,
        residenceOccupiedSecond: $residenceOccupiedSecond,
        unoccupancyPerAnnumFirst: $unoccupancyPerAnnumFirst,
        unoccupancyPerAnnumSecond: $unoccupancyPerAnnumSecond,
        haveAnAlarmSystemFirst: $haveAnAlarmSystemFirst,
        haveAnAlarmSystemSecond: $haveAnAlarmSystemSecond,
        IsTheAlarmLinkedFirst: $IsTheAlarmLinkedFirst,
        IsTheAlarmLinkedSecond: $IsTheAlarmLinkedSecond,
        responseCompanyFirst: $responseCompanyFirst,
        responseCompanySecond: $responseCompanySecond,
        unspecifiedItems: $unspecifiedItems,
        descriptionSumInsured: $descriptionSumInsured,
        unspecifiedItemsSectionThree: $unspecifiedItemsSectionThree,
        descriptionSumSectionThree: $descriptionSumSectionThree,
        motorVechileTypeOfCoverFirst: $motorVechileTypeOfCoverFirst,
        motorVechileTypeOfCoverSecond: $motorVechileTypeOfCoverSecond,
        motorVechileTypeOfCoverThird: $motorVechileTypeOfCoverThird,
        motorVechileUseOfVechileFirst: $motorVechileUseOfVechileFirst,
        motorVechileUseOfVechileSecond: $motorVechileUseOfVechileSecond,
        motorVechileUseOfVechileThird: $motorVechileUseOfVechileThird,
        motorVechileYearOfManufautreFirst: $motorVechileYearOfManufautreFirst,
        motorVechileYearOfManufautreSecond: $motorVechileYearOfManufautreSecond,
        motorVechileYearOfManufautreThird: $motorVechileYearOfManufautreThird,
        motorVechileMakeAndModelFirst: $motorVechileMakeAndModelFirst,
        motorVechileMakeAndModelSecond: $motorVechileMakeAndModelSecond,
        motorVechileMakeAndModelThird: $motorVechileMakeAndModelThird,
        motorVechileColourFirst: $motorVechileColourFirst,
        motorVechileColourSecond: $motorVechileColourSecond,
        motorVechileColourThird: $motorVechileColourThird,
        motorVechileRegistrationNumberFirst: $motorVechileRegistrationNumberFirst,
        motorVechileRegistrationNumberSecond: $motorVechileRegistrationNumberSecond,
        motorVechileRegistrationNumberThird: $motorVechileRegistrationNumberThird,
        motorVechileChasisFirst: $motorVechileChasisFirst,
        motorVechileChasisSecond: $motorVechileChasisSecond,
        motorVechileChasisThird: $motorVechileChasisThird,
        motorVechileValueFirst: $motorVechileValueFirst,
        motorVechileValueSecond: $motorVechileValueSecond,
        motorVechileValueThird: $motorVechileValueThird,
        motorVechileImportedVechileFrist: $motorVechileImportedVechileFrist,
        motorVechileImportedVechileSecond: $motorVechileImportedVechileSecond,
        motorVechileImportedVechileThird: $motorVechileImportedVechileThird,
        motorVechileIMMobiliserFirst: $motorVechileIMMobiliserFirst,
        motorVechileIMMobiliserSecond: $motorVechileIMMobiliserSecond,
        motorVechileIMMobiliserThird: $motorVechileIMMobiliserThird,
        motorVechileTrackingDeviceFirst: $motorVechileTrackingDeviceFirst,
        motorVechileTrackingDeviceSecond: $motorVechileTrackingDeviceSecond,
        motorVechileTrackingDeviceThird: $motorVechileTrackingDeviceThird,
        motorVechileMakeTrackingFirst: $motorVechileMakeTrackingFirst,
        motorVechileMakeTrackingSecond: $motorVechileMakeTrackingSecond,
        motorVechileMakeTrackingThird: $motorVechileMakeTrackingThird,
        motorVechileFinacialRegisterdOwnerFirst: $motorVechileFinacialRegisterdOwnerFirst,
        motorVechileFinacialRegisterdOwnerSecond: $motorVechileFinacialRegisterdOwnerSecond,
        motorVechileFinacialRegisterdOwnerThird: $motorVechileFinacialRegisterdOwnerThird,
        motorVechileFinacialProposerFirst: $motorVechileFinacialProposerFirst,
        motorVechileFinacialProposerSecond: $motorVechileFinacialProposerSecond,
        motorVechileFinacialProposerThird: $motorVechileFinacialProposerThird,
        motorVechileFinacialAccessoriesFirst: $motorVechileFinacialAccessoriesFirst,
        motorVechileFinacialAccessoriesSecond: $motorVechileFinacialAccessoriesSecond,
        motorVechileFinacialAccessoriesThird: $motorVechileFinacialAccessoriesThird,
        motorVechileFinacialValuOfAccessoriesFirst: $motorVechileFinacialValuOfAccessoriesFirst,
        motorVechileFinacialValuOfAccessoriesSecond: $motorVechileFinacialValuOfAccessoriesSecond,
        motorVechileFinacialValuOfAccessoriesThird: $motorVechileFinacialValuOfAccessoriesThird,
        motorVechileFinacialIntrustCarLoadFirst: $motorVechileFinacialIntrustCarLoadFirst,
        motorVechileFinacialIntrustCarLoadSecond: $motorVechileFinacialIntrustCarLoadSecond,
        motorVechileFinacialIntrustCarLoadThird: $motorVechileFinacialIntrustCarLoadThird,
        motorVechileBankFirst: $motorVechileBankFirst,
        motorVechileBankSecond: $motorVechileBankSecond,
        motorVechileBankThird: $motorVechileBankThird,
        motorVechileLoanPeriodMonthFirst: $motorVechileLoanPeriodMonthFirst,
        motorVechileLoanPeriodMonthSecond: $motorVechileLoanPeriodMonthSecond,
        motorVechileLoanPeriodMonthThird: $motorVechileLoanPeriodMonthThird,
        motorVechileRegDriverDetailsFirst: $motorVechileRegDriverDetailsFirst,
        motorVechileRegDriverDetailsSecond: $motorVechileRegDriverDetailsSecond,
        motorVechileRegDriverDetailsThird: $motorVechileRegDriverDetailsThird,
        motorVechileSurNameFirst: $motorVechileSurNameFirst,
        motorVechileSurNameSecond: $motorVechileSurNameSecond,
        motorVechileSurNameThird: $motorVechileSurNameThird,
        motorVechileFirstNameFirst: $motorVechileFirstNameFirst,
        motorVechileFirstNameSecond: $motorVechileFirstNameSecond,
        motorVechileFirstNameThird: $motorVechileFirstNameThird,
        motorVechileDobFirst: $motorVechileDobFirst,
        motorVechileDobSecond: $motorVechileDobSecond,
        motorVechileDobThird: $motorVechileDobThird,
        motorVechileEbbCoverdFirst: $motorVechileEbbCoverdFirst,
        motorVechileEbbCoverdSecond: $motorVechileEbbCoverdSecond,
        motorVechileEbbCoverdThird: $motorVechileEbbCoverdThird,
        motorVechileEaCoverdFirst: $motorVechileEaCoverdFirst,
        motorVechileEaCoverdSecond: $motorVechileEaCoverdSecond,
        motorVechileEaCoverdThird: $motorVechileEaCoverdThird,
        makeAndModalFirst: $makeAndModalFirst,
        makeAndModalSecond: $makeAndModalSecond,
        makeAndModalThird: $makeAndModalThird,
        carvansFirstYear: $carvansFirstYear,
        carvansSecondYear: $carvansSecondYear,
        carvansThirdYear: $carvansThirdYear,
        carvansFirstValue: $carvansFirstValue,
        carvansSecondValue: $carvansSecondValue,
        carvansThirdValue: $carvansThirdValue,
        carvansRegistrationNumberFirst: $carvansRegistrationNumberFirst,
        carvansRegistrationNumberSecond: $carvansRegistrationNumberSecond,
        carvansRegistrationNumberThird: $carvansRegistrationNumberThird,
        make: $make,
        model: $model,
        year: $year,
        value: $value,
        registrationNumber: $registrationNumber,
        use: $use,
        motorCycleLocked: $motorCycleLocked,
        typeOfMotorCycle: $typeOfMotorCycle,
        FinancialInterestMotorcycleLoan: $FinancialInterestMotorcycleLoan,
        bank: $bank,
        loanPeriodMonthsLeft: $loanPeriodMonthsLeft,
        sectionRequired: $sectionRequired,
        totalPremimum: $totalPremimum,
        nameOfInsurer: $nameOfInsurer,
        policyNumber: $policyNumber,
        yearFirst: $yearFirst,
        descriptionFirst: $descriptionFirst,
        amountFirst: $amountFirst,
        yearSecond: $yearSecond,
        descriptionSecond: $descriptionSecond,
        amountSecond: $amountSecond,
        signatureOfInsuredDate: $signatureOfInsuredDate,
        sesiroDate: $sesiroDate,
        sesiroAuthorizeDate: $sesiroAuthorizeDate,     
        sesiroAuthorize: $sesiroAuthorize,
        signatureOfInsured: $signatureOfInsured,
        sesiroSign: $sesiroSign,  
        specifiedItemsFirst: $specifiedItemsFirst,
        serialNoFirst: $serialNoFirst,
        sumInsuredFirst: $sumInsuredFirst,
        premiumFirst: $premiumFirst,
        specifiedItemsSecond: $specifiedItemsSecond,
        serialNoSecond: $serialNoSecond,
        sumInsuredSecond: $sumInsuredSecond,
        premiumSecond: $premiumSecond,
        specifiedItemsThird: $specifiedItemsThird,
        serialNoThird: $serialNoThird,
        sumInsuredThird: $sumInsuredThird,
        premiumThird: $premiumThird,
        specifiedItemsFourth: $specifiedItemsFourth,
        serialNoFourth: $serialNoFourth,
        sumInsuredFourth: $sumInsuredFourth,
        premiumFourth: $premiumFourth,
        specifiedItemsFive: $specifiedItemsFive,
        serialNoFive: $serialNoFive,
        sumInsuredFive: $sumInsuredFive, 
        premiumFive: $premiumFive,
        specifiedItemsSix: $specifiedItemsSix,
        serialNoSix: $serialNoSix,
        sumInsuredSix: $sumInsuredSix,
        premiumSix: $premiumSix,
        specifiedItemsSeven: $specifiedItemsSeven,
        serialNoSeven: $serialNoSeven,
        sumInsuredSeven: $sumInsuredSeven,
        premiumSeven: $premiumSeven,
        handBagSectionspecifiedItemsFirst: $handBagSectionspecifiedItemsFirst,
        handBagSectionserialNoFirst: $handBagSectionserialNoFirst,
        handBagSectionsumInsuredFirst: $handBagSectionsumInsuredFirst,
        handBagSectionpremiumFirst: $handBagSectionpremiumFirst,
        handBagSectionspecifiedItemsSecond: $handBagSectionspecifiedItemsSecond,
        handBagSectionserialNoSecond: $handBagSectionserialNoSecond,
        handBagSectionsumInsuredSecond: $handBagSectionsumInsuredSecond,
        handBagSectionpremiumSecond: $handBagSectionpremiumSecond,
        handBagSectionspecifiedItemsThird: $handBagSectionspecifiedItemsThird,
        handBagSectionserialNoThird: $handBagSectionserialNoThird,
        handBagSectionsumInsuredThird: $handBagSectionsumInsuredThird,
        handBagSectionpremiumThird: $handBagSectionpremiumThird,
        handBagSectionspecifiedItemsFourth: $handBagSectionspecifiedItemsFourth,
        handBagSectionserialNoFourth: $handBagSectionserialNoFourth,
        handBagSectionsumInsuredFourth: $handBagSectionsumInsuredFourth,
        handBagSectionpremiumFourth: $handBagSectionpremiumFourth,
        handBagSectionspecifiedItemsFive: $handBagSectionspecifiedItemsFive,
        handBagSectionserialNoFive: $handBagSectionserialNoFive,
        handBagSectionsumInsuredFive: $handBagSectionsumInsuredFive,
        handBagSectionpremiumFive: $handBagSectionpremiumFive,
        handBagSectionspecifiedItemsSix: $handBagSectionspecifiedItemsSix,
        handBagSectionserialNoSix: $handBagSectionserialNoSix,
        handBagSectionsumInsuredSix: $handBagSectionsumInsuredSix,
        handBagSectionpremiumSix: $handBagSectionpremiumSix,
        handBagSectionspecifiedItemsSeven: $handBagSectionspecifiedItemsSeven,
        handBagSectionserialNoSeven: $handBagSectionserialNoSeven,
        handBagSectionsumInsuredSeven: $handBagSectionsumInsuredSeven,
        handBagSectionpremiumSeven: $handBagSectionpremiumSeven,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_PROPOSAL_ISSUE = gql`
    mutation ProposalIssue(
        $id : String
    ) {
        deleteProposalIssue(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_KEYS_CONTROL_REGISTER = gql`
    mutation KeysControlRegister(
        $id: String
        $subscriptionId: String,
        $title:  String,
        $firstIssueDate:  Date,
        $procedureNo:  String,
        $revisionNo:  String,
        $reviewPeriod:  String,
        $nextReviewDate:  Date,
        $preparedBy:  String,
        $distributionList:  String,
        $registerSection: [JSON],
        $createdById:  String,
        $createdByName:  String,
    ) {
        addKeysControlRegister(
        id:$id
        subscriptionId:$subscriptionId,
        title:$title,
        firstIssueDate:$firstIssueDate,
        procedureNo:$procedureNo,
        revisionNo:$revisionNo,
        reviewPeriod:$reviewPeriod,
        nextReviewDate:$nextReviewDate,
        preparedBy:$preparedBy,
        distributionList:$distributionList,
        registerSection:$registerSection,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_KEYS_CONTROL_REGISTER = gql`
    mutation KeysControlRegister(
        $id : String
    ) {
        deleteKeysControlRegisterIssue(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_PERSON_SEARCH_REGISTER = gql`
    mutation PersonSearchRegister(
        $id: String
        $subscriptionId: String,
        $title:  String,
        $firstIssueDate:  Date,
        $procedureNo:  String,
        $revisionNo:  String,
        $reviewPeriod:  String,
        $nextReviewDate:  Date,
        $preparedBy:  String,
        $distributionList:  String,
        $registerSection: [JSON],
        $createdById:  String,
        $createdByName:  String,
    ) {
        addPersonSearchRegister(
        id:$id
        subscriptionId:$subscriptionId,
        title:$title,
        firstIssueDate:$firstIssueDate,
        procedureNo:$procedureNo,
        revisionNo:$revisionNo,
        reviewPeriod:$reviewPeriod,
        nextReviewDate:$nextReviewDate,
        preparedBy:$preparedBy,
        distributionList:$distributionList,
        registerSection:$registerSection,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_PERSON_SEARCH_REGISTER = gql`
    mutation PersonSearchRegister(
        $id : String
    ) {
        deletePersonSearchRegisterIssue(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_REMOTE_CONTROL_REGISTER = gql`
    mutation RemoteControlRegister(
        $id: String
        $subscriptionId: String,
        $title:  String,
        $firstIssueDate:  Date,
        $procedureNo:  String,
        $revisionNo:  String,
        $reviewPeriod:  String,
        $nextReviewDate:  Date,
        $preparedBy:  String,
        $distributionList:  String,
        $registerSection: [JSON],
        $createdById:  String,
        $createdByName:  String,
    ) {
        addRemoteControlRegister(
        id:$id
        subscriptionId:$subscriptionId,
        title:$title,
        firstIssueDate:$firstIssueDate,
        procedureNo:$procedureNo,
        revisionNo:$revisionNo,
        reviewPeriod:$reviewPeriod,
        nextReviewDate:$nextReviewDate,
        preparedBy:$preparedBy,
        distributionList:$distributionList,
        registerSection:$registerSection,
        createdById:$createdById,
        createdByName:$createdByName,
        ) {
            success
            message
            data
        }
    }
`;

export const DELETE_REMOTE_CONTROL_REGISTER = gql`
    mutation RemoteControlRegister(
        $id : String
    ) {
        deleteRemoteControlRegisterIssue(
            id: $id
        ){
            data
        }
    }
`;

export const CREATE_PAYMENT_REQUISITION = gql`
    mutation PaymentRequisition(
        $id: String,
        $subscriptionId: String,
        $payeeName: String,
        $payeePRN: String,
        $paymentPurpose: String,
        $costCode: String,
        $amount: String,
        $amountinWords: String,
        $printName: String,
        $signature: String,
        $requisitionerDate: Date,
        $printName2: String,
        $signature2: String,
        $authoriserDate: Date,
        $bankName: String,
        $accNumber: String,
        $branchCode: String,
        $swiftCode: String,
        $paymentDate: Date,
        $paymentSignature: String,
        $reviewer: String,
        $authoriser: String,
        $processedBy: String,
        $jnlNo: String,
    ) {
    addPaymentRequisition(
        id: $id,
        subscriptionId: $subscriptionId,
        payeeName: $payeeName,
        payeePRN: $payeePRN,
        paymentPurpose: $paymentPurpose,
        costCode: $costCode,
        amount: $amount,
        amountinWords: $amountinWords,
        printName: $printName,
        signature: $signature,
        requisitionerDate: $requisitionerDate,
        printName2: $printName2,
        signature2: $signature2,
        authoriserDate: $authoriserDate,
        bankName: $bankName,
        accNumber: $accNumber,
        branchCode: $branchCode,
        swiftCode: $swiftCode,
        paymentDate: $paymentDate,
        paymentSignature: $paymentSignature,
        reviewer: $reviewer,
        authoriser: $authoriser,
        processedBy: $processedBy,
        jnlNo: $jnlNo,
    ) {
        success
        message
        data
    }
}`;

export const DELETE_PAYMENT_REQUISITION = gql`
    mutation PaymentRequisition(
        $id : String
    ) {
        deletePaymentRequisition(
            id: $id
        ){
            id
        }
    }
`;

export const CREATE_BOTASH_REQUESTS = gql`
    mutation BotashPocRequest(
        $id: String,
        $subscriptionId: String,
        $name: String,
        $companyNo: String,
        $section: String,
        $deparmemt: String,
        $travelExpense: String,
        $medicalExamination: String,
        $medicalAids: String,
        $safetyInduction: String,
        $companyIDCard: String,
        $hrInduction: String,
        $housing: String,
        $legalAppointments: String,
        $botrecMembership: String,
        $ictSystem: String,
        $communicationOffice: String,
        $ISO: String,
        $IntroToSection: String,
        $emplySignOne: String,
        $emplySignTwo: String,
        $emplyDateTwo: Date,
        $emplyDateOne:  Date,
        $engaegementDate:  Date,
        $createdById:  String,
        $createdByName:  String,
    ) {
        addBotashPocRegister(
        id: $id,
        subscriptionId: $subscriptionId,
        name:$name,
        companyNo:$companyNo,
        section:$section,
        deparmemt:$deparmemt,
        travelExpense:$travelExpense,
        medicalExamination:$medicalExamination,
        medicalAids:$medicalAids,
        safetyInduction:$safetyInduction,
        companyIDCard:$companyIDCard,
        hrInduction:$hrInduction,
        housing:$housing,
        legalAppointments:$legalAppointments,
        botrecMembership:$botrecMembership,
        ictSystem:$ictSystem,
        communicationOffice:$communicationOffice,
        ISO:$ISO,
        IntroToSection:$IntroToSection,
        emplySignOne:$emplySignOne,
        emplySignTwo:$emplySignTwo,
        emplyDateTwo:$emplyDateTwo,
        emplyDateOne:$emplyDateOne,
        engaegementDate:$engaegementDate,
        createdById:$createdById,
        createdByName:$createdByName,
    ) {
        success
        message
        data
    }
}`;

export const DELETE_BOTASH_REQUESTS = gql`
    mutation BotashPocRequest(
        $id: String
    ) {
        deleteBotashPocRegisterRequest(
            id: $id
        ){
            success
            message
            data
        }
    }
`;