import React, {useState, useEffect} from 'react';
import { get } from "lodash-es";
import {useMutation} from '@apollo/react-hooks';
import {
    ADD_SITE_PHONES,
    UPDATE_SITE_PHONES
} from '../../mutations';
import IotDeviceItem from './IotDeviceItem';
import Message from '../../../../components/Message';
import IotForm from './IotForm';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import LinearProgress from '@material-ui/core/LinearProgress';

const IotDevices = ({
    t,
    placeList,
    notificationTemplates,
    countryCodes,
    sitePhonsLoading,
    sitePhonsListdata,
    sitePhonsListError,
    page,
    handlePageChange,
    reloadSitePhonesData,
    allowConfigure,
    organizationId,
    allPalceList
}) => {
    const [isDeviceFormEnable, setIsDeviceFormEnable] = useState(false);
    const [createIotDeviceLoading, setCreateIotDeviceLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    
    // working code 
    // console.log('test', placeList.filter((el,i) => {
    //     if(['1630917674138615', '1630483896266735'][i] !== el.place_id){
    //          return el;
    //     }
    // }));
    let columns = [
        {
            label:'Notification Template',
            key:"notificationTemplate"
        },
        {
            label:'GSM Number',
            key:"gsmNumber"
        },
        {
            label: 'Place',
            key:"Place"
        },
        {
            label:'Status',
            key:"Status"
        }
    ];

    // if(allowConfigure){
    //     columns.push({
    //         label:t('edit.label'),
    //         key:"button"
    //     });
    // }

    const [createNewIotDevice] = useMutation(ADD_SITE_PHONES, {
        onError: error => {
            setCreateIotDeviceLoading(false);
            Message.error(error);
        },
        onCompleted: data => {
            console.log('data', data);
            setCreateIotDeviceLoading(false);
            reloadSitePhonesData();
            Message.success(`${data && data.addSitePhones.response.join()} ${data && data.addSitePhones.message}`);
            setIsDeviceFormEnable(false);
        }
    });

    const [editIotDevice] = useMutation(UPDATE_SITE_PHONES, {
        onError: error => {
            setCreateIotDeviceLoading(false);
            Message.error(error);
        },
        onCompleted: data => {
            console.log('data', data);
            setCreateIotDeviceLoading(false);
            reloadSitePhonesData();
            Message.success(`${data && data.updateSitePhones.response.join()} ${data && data.updateSitePhones.message}`);
            setIsDeviceFormEnable(false);
        }
    });

    const handleCloseOFDevice = () => (
        setIsDeviceFormEnable(false)
    );

    const handleMenuItemClick = (elem, itemData, type) => {
        if (!itemData) {
          return false;
        }

        if (elem.label === t('edit.label')) {
            setIsDeviceFormEnable(true);
            setSelectedItem(itemData);
        } 
      };

      useEffect(() => {
        if(!isDeviceFormEnable){
            setSelectedItem('');
        }
      }, [isDeviceFormEnable])
      

    return (
        <>
            <Grid container justify={'space-between'}>
                    <Grid item>
                        <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={()=>setIsDeviceFormEnable(true)}
                        >
                            {t('createDevice.label')}
                        </Button>
                    </Grid>
            </Grid>
            <Grid container style={{marginBottom: 20}} >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return ( 
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                         <TableBody>
                        { !sitePhonsLoading && !sitePhonsListError ? 
                            sitePhonsListdata.getSitePhonesList && sitePhonsListdata.getSitePhonesList.phones && sitePhonsListdata.getSitePhonesList.phones.length > 0 ?
                            sitePhonsListdata.getSitePhonesList.phones.map((rowitem, rowIndex) => (
                                <IotDeviceItem
                                    t={t}
                                    key={rowIndex}
                                    data={rowitem}
                                    allowConfigure={allowConfigure}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            :
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    {sitePhonsLoading && !sitePhonsListError && <LinearProgress color="primary"/> }
                                </TableCell>
                            </TableRow>
                        }
                        </TableBody>
                    </Table>
                    { !sitePhonsLoading &&
                    <>
                    <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                    <Grid container item xs={2}>
                    <span>Total {Math.ceil(get(sitePhonsListdata, 'getSitePhonesList.count', null)/20)} page,  {get(sitePhonsListdata, 'getSitePhonesList.count', null)} Row</span>
                    </Grid> 
                    {/* Pagination is not done yet in api side
                    <Grid container item xs={2}>
                        <Pagination 
                            color="secondary"
                            count={sitePhonsListdata.getSitePhonesList ? Math.ceil(sitePhonsListdata.getSitePhonesList.count/20) : 0} 
                            page={page} 
                            onChange={(evt, value) => {
                                handlePageChange("iot_device", value);
                            }}
                        />
                    </Grid> */}
                  </Grid>
                  </>
                    } 
                </TableContainer>
            </Grid>
            <IotForm 
              t={t}
              open={isDeviceFormEnable}
              onClose={handleCloseOFDevice}
              placeList={placeList}
              notificationTemplates={notificationTemplates}
              countryCodes={countryCodes}
              iotloading={createIotDeviceLoading}
              updateIotLoading={setCreateIotDeviceLoading}
              iotMutaion={createNewIotDevice}
              selectedItem={selectedItem}
              editIotDevice={editIotDevice}
              organizationId={organizationId}
              allPalceList={allPalceList}
            />    
        </>
    )
}

export default IotDevices
