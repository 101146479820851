import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const getDatacenters = async (page = '', limit = '') => {
  try {
    const response = await axios.get(
      `datacenter?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

const addDataCenter = async payload => {
  try {
    const response = await axios.post('datacenter', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};
const getDatacenterById = async dcId => {
  try {
    const response = await axios.get(`datacenter/${dcId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const updateDataCenterById = async (dcId, payload) => {
  try {
    const response = await axios.put(`datacenter/${dcId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deleteDataCenter = async dcId => {
  try {
    const response = await axios.delete(`datacenter/${dcId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getDataCenterListByNameFilter = async (
  page = 1,
  limit = 10,
  word = ''
) => {
  try {
    const response = await axios.get(
      `datacenter?limit=${limit}&page=${page}&search=${word}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

export {
  getDatacenters,
  addDataCenter,
  getDatacenterById,
  updateDataCenterById,
  deleteDataCenter,
  getDataCenterListByNameFilter
};
