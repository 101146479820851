import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
import HrHeader from '../../../HrHeader';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const PropertyDamageForm = ({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateChangeRequestSubmit,
    handleOpenSignPanModal,
    datas=[]
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();


  const columns = [
    {
        label: 'NUMBER',
        key: "NUMBER"
    },
    {
        label: 'DESCRIPTION OF PROPERTY',
        key: "DESCRIPTION OF PROPERTY"
    },
    {
        label: 'DATE ACQUIRED',
        key: "DATE ACQUIRED"
    },
    {
        label: "FROM WHOM WAS IT PURCHASED",
        key: "FROM WHOM WAS IT PURCHASED"
    },
    {
        label: 'REPLACEMENT COST',
        key: "REPLACEMENT COST"
    },
    {
        label: 'LESS DEPRECIATION',
        key: "LESS DEPRECIATION"
    },
    {
        label: 'AMOUNT CLAIMED OR ACQUIRED',
        key: "AMOUNT CLAIMED OR ACQUIRED"
    }
];


  return (
    <Dialog
    fullWidth={true}
    maxWidth={'lg'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose();
    }}>
        <DialogTitle id="sensor-template-title">
        <HrHeader />
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <Grid item xs={12}>
                <Typography variant="h4" >PROPERTY LOSS/DAMAGE CLAIM FORM</Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Policy Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"VAT Registration Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >INSURED</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"Occupation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"Address"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"(Day) Tel. no."}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >LOSS/ DAMAGE OCCURRENCE</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date & Time of breakage'}
                            dateFormat={"yyyy-MM-dd"}
                            value={null}
                            handleChange={newDate => {
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"When was loss/damage discovered?"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >LOSS/DAMAGE PLACE</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
                    <Grid item xs={6}>
                        <Input
                            type={'text'}
                            placeholder={"Place where loss/damage occured"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="subtitle2" style={{ paddingTop: 10, display: "inline-block" }} color="textPrimary">Were premises occupied?</Typography>
                        <RadioGroup
                            row
                            aria-label="change_classification"
                            name="change_classification"
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="No"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                label="No"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Yes"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                label="Yes"
                            />
                        </RadioGroup>
                    </Grid>

        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                     <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"if Yes, By whom?"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={1} />
                     <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'If not occupied, when last occupied?'}
                            dateFormat={"yyyy-MM-dd"}
                            value={null}
                            handleChange={newDate => {
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Purpose of occupation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >CAUSES OF LOSS/ DAMAGE</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={6}>
                        <TextField
                            type="textarea"
                            placeholder="Describe fully how the loss or damage occured stating how (if applicable) entry was gained to premises"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                            type="textarea"
                            placeholder="If loss/damage was caused by another party give name and address"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={2}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >PREVIOUS LOSS/ DAMAGE</Typography>
                    </Grid>
        </Grid>
                <Grid container spacing={1} style={{ marginBottom: 20 }}>
                <Typography variant="subtitle2" style={{ paddingTop: 10, display: "inline-block" }} color="textPrimary">Have you previously suffered a loss/damage?</Typography>
                    <Grid item xs={4}>
                        <RadioGroup
                            row
                            aria-label="change_classification"
                            name="change_classification"
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="No"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                label="No"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Yes"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                label="Yes"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            type="textarea"
                            placeholder="If Yes, Give details"
                            variant="standard"
                            fullWidth={true}
                            multiline
                            rows={2}
                            rowsMax={2}
                        />
                    </Grid>

        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
                    <Grid item xs={6}>
                       <Input
                            type={'text'}
                            placeholder={"If insured, provide name of insurer"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >POLICE</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Police Ref. no."}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Station Reported"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date reported'}
                            dateFormat={"yyyy-MM-dd"}
                            value={null}
                            handleChange={newDate => {
                            }}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >OTHER INTEREST</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
                   <Typography variant="subtitle2" style={{ paddingTop: 10, display: "inline-block" }} color="textPrimary">
                   Has any other party have interest in the insured property, eg. Credit Agreement?
                    </Typography>
                    <Grid item xs={6}>
                        <RadioGroup
                            row
                            aria-label="change_classification"
                            name="change_classification"
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="No"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                labelPlacement="start"
                                label="No"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Yes"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                label="Yes"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            type={'text'}
                            placeholder={"If so, give name of insurer"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>

        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >VALUE</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"When last valued?"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >DECLARATION</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={1} />
                    <Grid item xs={2}>
                        <IconButton
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("supervisorSign")}
                        >
                         Insured’s Signature <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            type={'text'}
                            placeholder={"Capacity"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            //value={sensorFormState.deviceNo}
                        />
                    </Grid>
                     <Grid item xs={1} />
                    <Grid item xs={5}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'When last valued?'}
                            dateFormat={"yyyy-MM-dd"}
                            value={null}
                            handleChange={newDate => {
                            }}
                        />
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" >SCHEDULE OF PROPERTY DAMAGED, LOST OR STOLEN</Typography>
                    </Grid>
        </Grid>
        <Grid container>
        <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datas.length > 0 ?
                                datas.map((rowitem, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <Typography variant="subtitle1">DEBS-MCM-NMS-0{++rowIndex}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid className={commonClasses.column}>
                                            <Grid>
                                            <FormControlLabel
                                            style={{ ...marginGenerator('mb-8') }}
                                            value="S/F"
                                            control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                            labelPlacement="start"
                                            label="S/F"
                                            />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() =>null}
                    loading={false}
                />
            </Grid>
        </DialogActions>
    </Dialog>
  )
}

export default PropertyDamageForm
