import React, {useState, useEffect} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableFooter from './InvoiceTableFooter'
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  }
});

const InvoiceItemsTable = ({invoice, t}) => {

  const [controllerState, setControllerState] = useState([]);
  const [vnfsState, setVpnsState] = useState([]);
  const [ucpesState, setUcpesState] = useState([]);
  const [addonsState, setAddonsState] = useState([]);
  
  useEffect(() => {
    if(invoice.controllerState && invoice.controllerState.length){
        let controllers = invoice.controllerState.map(item => {
          return {
            description: item.name,
            qty: item.totalHrs,
            rate: item.costPerHrs,
            discount: item.freeTierDiscount,
          };
        });
        setControllerState(controllers);
    }
  }, [invoice.controllerState]);

  useEffect(() => {
    if(invoice.vnfsState && invoice.vnfsState.length){
      let vnfs = invoice.vnfsState.map(item => {
        return {
          description: item.name,
          qty: 1,
          rate: item.costPerMonth,
          discount: item.freeTierDiscount,
        };
      });
      setVpnsState(vnfs);
    }
  }, [invoice.vnfsState]);

  useEffect(() => {
    if(invoice.ucpesState){
      let ucpes = [];
      if(invoice.ucpesState.small){
        ucpes.push({
          description: t("smallUcpes.label"),
          qty: invoice.ucpesState.small.number,
          rate: invoice.ucpesState.small.costPerMonth,
          discount: invoice.ucpesState.small.freeTierDiscount,
        });
      }
      if(invoice.ucpesState.medium){
        ucpes.push({
          description: t("mediumUcpes.label"),
          qty: invoice.ucpesState.medium.number,
          rate: invoice.ucpesState.medium.costPerMonth,
          discount: invoice.ucpesState.medium.freeTierDiscount,
        });
      }
      if(invoice.ucpesState.large){
        ucpes.push({
          description: t("largeUcpes.label"),
          qty: invoice.ucpesState.large.number,
          rate: invoice.ucpesState.large.costPerMonth,
          discount: invoice.ucpesState.large.freeTierDiscount,
        });
      }
      setUcpesState(ucpes);
    }
  }, [invoice.ucpesState]);

  useEffect(() => {
    if(invoice.addonsState && invoice.addonsState.length){
      let addons = invoice.addonsState.map(item => {
        return {
          description: item.moduleName,
          qty: item.number,
          rate: item.costPerMonth,
          discount: item.freeTierDiscount
        };
      });
      setAddonsState(addons);
    }
  }, [invoice.addonsState]);

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader row={{title: t("controllers.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow items={controllerState} currency={invoice.currency ? invoice.currency.symbol: "$"} />
  
      <InvoiceTableHeader row={{title: t("ucpes.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow items={ucpesState} currency={invoice.currency ? invoice.currency.symbol: "$"} />
    
      <InvoiceTableHeader row={{title: t("vnfs.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol: "$"} items={vnfsState} />
      
      <InvoiceTableHeader row={{title: t("addOns.label"), head1: t("number.label"), head2: t("costPerMonth.label"), head3: t("amount.label"), head4: t("discount.label"), head5: t("finalPrice.label")}} />
      <InvoiceTableRow currency={invoice.currency ? invoice.currency.symbol: "$"} items={addonsState} />

      <InvoiceTableFooter currency={invoice.currency ? invoice.currency.symbol: "$"} total={invoice.totalState ? invoice.totalState : null} />
    </View>
  )
}


export default InvoiceItemsTable