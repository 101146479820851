import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../theme/commonStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import { marginGenerator } from '../../theme/utils';
import Divider from '@material-ui/core/Divider';
import { colors } from '../../theme/colors';
import { SERVICE_TYPES } from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    headerContainer: {
        paddingBottom: theme.spacing(2)
    }
}));
export default function Header({
    title,
    subText,
    serviceType,
    storeForm,
    primaryButtonText,
    handlePrimaryButtonClick,
    handleStoreFormButtonClick,
    handleSecondaryButtonClick,
    handleMenuClick,
    secondaryButtonText,
    divider = false,
    moreText,
    titleVariant,
    titleStyleOverride,
    subtitleVariant,
    subtitleStyleOverride,
    containerStyle,
    iconComponent
}) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    return (
        <>
            <Grid
                container
                justify={'space-between'}
                className={classes.headerContainer}
                style={containerStyle}>
                <Grid item className={commonClasses.column}>
                    {iconComponent && (
                        <Grid item style={marginGenerator('mr-16')}>
                            {iconComponent}
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            variant={titleVariant || 'h2'}
                            style={titleStyleOverride || { maxWidth: '900px' }}>
                            {title}
                        </Typography>
                        <Typography
                            variant={subtitleVariant || 'subtitle2'}
                            style={subtitleStyleOverride}>
                            {subText}
                        </Typography>
                        {moreText && (
                            <Typography
                                variant={'subtitle1'}
                                style={{
                                    color: colors.error.main,
                                    ...marginGenerator('mt-16')
                                }}>
                                {moreText}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    {secondaryButtonText && (
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={handleSecondaryButtonClick}
                            style={marginGenerator('mr-16')}>
                            {secondaryButtonText}
                        </Button>
                    )}
                    {serviceType === SERVICE_TYPES.DIAN && storeForm && (
                        <Button variant={'outlined'} style={marginGenerator('mr-05')} onClick={handleStoreFormButtonClick}>
                            {storeForm}
                        </Button>
                    )}
                    {primaryButtonText && (
                        <Button variant={'outlined'} onClick={handlePrimaryButtonClick}>
                            {primaryButtonText}
                        </Button>
                    )}
                    {handleMenuClick && (
                        <IconButton
                            size={'medium'}
                            color={'primary'}
                            onClick={handleMenuClick}
                            style={marginGenerator('ml-16')}>
                            <MoreHoriz className={commonClasses.iconRegular} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            {/* {divider && (
                <Divider
                    variant={'fullWidth'}
                    absolute
                    style={{ bottom: 'auto', marginTop: 8 }}
                />
            )} */}
        </>
    );
}
Header.propTypes = {
    title: PropTypes.string.isRequired,
    subText: PropTypes.string,
    primaryButtonText: PropTypes.string,
    handlePrimaryButtonClick: PropTypes.func,
    handleSecondaryButtonClick: PropTypes.func,
    handleMenuClick: PropTypes.func,
    titleStyleOverride: PropTypes.object,
    titleVariant: PropTypes.string,
    subtitleStyleOverride: PropTypes.object,
    containerStyle: PropTypes.object,
    subtitleVariant: PropTypes.string
};
