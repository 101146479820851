import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_ACTIVITY_LOGS } from '../Subscriptions/queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { Paths } from '../../routes/routePaths';
import ActivityLogPanel from './components/ActivityLogPanel';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'activityLogs.label', link: Paths.ServiceTickets }
];

const ActivityLogs = ({ t }) => {
  
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [filterdList, setFilterdList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getFilteredList({
      variables: {
        searchText: searchText,
        toDate: toDate ? toDate : null,
        fromDate: fromDate ? fromDate : null,
        page: page
      }
    });
  }, []);

  /*
    Filter queries
  */
  const [getFilteredList] = useLazyQuery(GET_ACTIVITY_LOGS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getAllActivityLogs) {
        setFilterdList(
          data.getAllActivityLogs.result ? data.getAllActivityLogs.result : []
        );
        setTotalCount(
          data.getAllActivityLogs.total ? data.getAllActivityLogs.total : 0
        );
      }
      setLoading(false);
    },
    onError: error => {
      setTotalCount(0);
      setLoading(false);
      console.log(error);
    }
  });

  const filterList = [
    {
      label: t('search.label'),
      handleChange: evt => {
        const statusVal = evt.target.value;
        setSearchText(statusVal);
      },
      val: searchText,
      type: 'text'
    }
    // {
    //   label: t('fromDate.label'),
    //   handleChange: evt => {
    //     const statusVal = evt.target.value;
    //     setFromDate(statusVal);
    //   },
    //   val: fromDate,
    //   type: 'datetime-local'
    // },
    // {
    //   label: t('toDate.label'),
    //   handleChange: evt => {
    //     const statusVal = evt.target.value;
    //     setToDate(statusVal);
    //   },
    //   val: toDate,
    //   type: 'datetime-local'
    // }
  ];

  let columns = [
    {
      label: t('action.label'),
      key: 'actionType'
    },
    {
      label: t('description.label'),
      key: 'description'
    },
    {
      label: t('ip.label'),
      key: 'ipAddress'
    },
    {
      label: t('createdBy.label'),
      key: 'createdByName'
    },
    {
      label: t('createdDate.label'),
      key: 'createdDate'
    }
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getFilteredList({
      variables: {
        searchText: searchText,
        toDate: toDate ? toDate : null,
        fromDate: fromDate ? fromDate : null,
        page: value
      }
    });
  };

  const handleSubmitFilter = () => {
    setPage(1);
    setTotalCount(0);
    setLoading(true);
    getFilteredList({
      variables: {
        searchText: searchText,
        toDate: toDate ? toDate : null,
        fromDate: fromDate ? fromDate : null,
        page: 1
      }
    });
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('activityLogs.label')}
        subText={t('activityLogsSubTxt.label')}
      />
      <ActivityLogPanel
        list={filterdList}
        totalCount={totalCount}
        t={t}
        columns={columns}
        page={page}
        handlePageChange={handlePageChange}
        filterList={filterList}
        loading={loading}
        handleSubmitFilter={handleSubmitFilter}
      />
    </Layout>
  );
};
export default withTranslation()(ActivityLogs);
