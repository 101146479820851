import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import ServerRoomPanelItem from './ServerRoomPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import ServerRoomInspectionForm from '../ServerRoomInspectionPanel/ServerRoomInspectionForm';
import {
    GET_SERVER_ROOM_LIST
} from '../../queries';
import { CREATE_SERVER_ROOM, DELETE_SERVER_ROOM } from '../../mutation';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const ServerRoomInspectionPanel = ({
    t,
    allowConfigure,
    data,
    users,
}) => {

    const classes = useStyles();
    const subscriptionId = data.id;

    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedServerRoomItem, setSelectedServerRoomItem] = useState(null);
    const [serverRoomModalOpen, setServerRoomModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({
        userSign: "",
        imHeadSign: "",
        imSectionHeadSign: "",
    });


    const columns = [
        {
            label: 'Name',
            key: "fullName"
        },
        {
            label: 'Date',
            key: "reportedDateTime"
        },
        {
            label: 'Section Head',
            key: "imSectionHeadId"
        },
        {
            label: "Head of IM",
            key: "imHeadId"
        },{
            label: 'Actions',
            key: "actions"
        },
    ];

    const [getAllServerRooms, {
        loading,
        data: serverRoomRequestList
    }] = useLazyQuery(GET_SERVER_ROOM_LIST, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllServerRooms({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllServerRooms({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const handleNewServerRoomRequest = () => {
        setServerRoomModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        }
    ];

    

    const [createServerRoom, { loading: createServerRoomLoading }] = useMutation(CREATE_SERVER_ROOM, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Server Room Inspection created successfully');
            setServerRoomModalOpen(false);
            setSignaturePadModalOpen(false);
            setSignatureType("");
            setSelectedServerRoomItem(null);
            return getAllServerRooms({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteServerRoom, { loading: deleteServerRoomLoading }] = useMutation(DELETE_SERVER_ROOM, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Server Room Inspection deleted successfully');
            return getAllServerRooms({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateServerRoomSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createServerRoom({ variables: {
            ...signatureState,
            ...formData
        }});
    }

    const handleMenuItemClick = (type, rowItem) => {
        setSelectedServerRoomItem(rowItem);
        if(type === "edit"){
            setServerRoomModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this Server Room entery ?') ===
                false
            ) {
                return false;
            }
            return deleteServerRoom({
                variables: {
                    id : rowItem.id
                }
            })
        }
    }

    const handleOpenSignaturePad = (assigneeId) => {
        setSignaturePadModalOpen(true);
    }

    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    onChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewServerRoomRequest}
                        >
                            {"Open Form"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody> 
                            { !loading ? serverRoomRequestList && serverRoomRequestList.getAllServerRooms && serverRoomRequestList.getAllServerRooms.length > 0 ?
                                serverRoomRequestList.getAllServerRooms.map((rowitem, rowIndex) => (
                                    <ServerRoomPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        users={users}
                                    />
                                )) : 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow> : null
                            }
                               {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                    }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ServerRoomInspectionForm
                t={t}
                open={serverRoomModalOpen}
                handleClose={() => {
                    setServerRoomModalOpen(false);
                    setSelectedServerRoomItem(null);
                }}
                handleOpenSignPanModal={handleOpenSignPanModal}
                handleCreateServerRoomSubmit={handleCreateServerRoomSubmit}
                signatures={signatureState}
                data={selectedServerRoomItem}
                users={users}
            />

            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    )
}

export default ServerRoomInspectionPanel