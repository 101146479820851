import React, { useState, useEffect, useHistory } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { GROUP_TYPES, REQUIRED_ERROR } from '../../../../../utils/constants';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from './../../../../commonQueries';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const CreateOrgModal = ({
    t,
    open,
    handleCreateOrgSubmit,
    handleClose,
    modalState,
    users,
    userLoading
}) => {

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [region, setRegion] = useState('');
    const [country, setCountry] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [orgType, setOrgType] = useState("");
    const [orgLogoFile, setOrgLogoFile] = useState([]);
    const [orgLogo, setOrgLogo] = useState("");
    const [orgDns, setOrgDns] = useState("");

    const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
    const { data: countryList } = useQuery(GET_COUNTRY_LIST);

    useEffect(() => {
        if (modalState.clearData && open) {
            setName('');
            setAddress('');
            setRegion('');
            setCountry('');
            setZipcode('');
            setOrgLogo("");
            setOrgDns("")
            setOrgLogoFile([]);
            handleClose();
        }
    }, [modalState, users]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('createNew.label')} {t('organization.label')}</DialogTitle>
            <DialogContent>
                <Grid style={{ ...marginGenerator('mt-24') }}>
                    <Grid
                        container
                        justify={'space-between'}
                        item
                        style={marginGenerator('mt-24')}>
                        <OutlinedSelect
                            val={orgType}
                            label={`${t('type.label')} *`}
                            handleChange={evt => {
                                setOrgType(evt.target.value);
                                if (evt.target.value !== "partner") {
                                    setOrgLogo("");
                                    setOrgDns("");
                                    setOrgLogoFile([]);
                                }
                            }}
                            styleOverrides={{ width: '220px', marginRight: 0 }}
                            options={GROUP_TYPES}
                        />
                        {orgType && orgType === "partner" && 
                        <>
                            <Input
                                value={orgLogo}
                                type={'file'}
                                label={`${t('logo.label')} *`}
                                style={{ width: 200, minWidth: 200 }}
                                color={'secondary'}
                                inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
                                onChange={evt => {
                                    setOrgLogo(evt.target.value);
                                    setOrgLogoFile(evt.target.files);
                                }}
                            />
                            <Input
                                value={orgDns}
                                type={'text'}
                                placeholder={`${t('orgDns.label')} *`}
                                style={{ ...marginGenerator('mt-24'), width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setOrgDns(evt.target.value)}
                            />
                        </>
                        }
                    </Grid>
                    <Input
                        value={name}
                        type={'text'}
                        placeholder={`${t('orgName.label')} *`}
                        style={{ ...marginGenerator('mt-24'), width: '100%' }}
                        color={'secondary'}
                        onChange={evt => setName(evt.target.value)}
                    />
                    <Input
                        value={address}
                        type={'text'}
                        placeholder={`${t('address.label')} *`}
                        style={{ width: '100%', ...marginGenerator('mt-24') }}
                        color={'secondary'}
                        onChange={evt => setAddress(evt.target.value)}
                    />
                    <Grid
                        container
                        justify={'space-between'}
                        item
                        style={marginGenerator('mt-24')}>
                        <OutlinedSelect
                            val={country}
                            label={`${t('country.label')} *`}
                            handleChange={evt => {
                                if (evt.target.value) {
                                    getRegionList({ variables: { countryId: evt.target.value } });
                                }
                                setRegion('');
                                setCountry(evt.target.value);
                            }}
                            styleOverrides={{ width: '220px', marginRight: 0 }}
                            options={countryList ? countryList.getCountries : []}
                        />
                        <OutlinedSelect
                            val={region}
                            disabled={!country}
                            label={`${t('region.label')} *`}
                            handleChange={evt => {
                                setRegion(evt.target.value);
                            }}
                            styleOverrides={{ width: '220px', marginRight: 0 }}
                            options={regionList ? regionList.getRegionsByCountryId : []}
                        />
                    </Grid>
                    <Grid
                        container
                        justify={'space-between'}
                        item
                        style={marginGenerator('mt-24')}>
                        <Input
                            value={zipcode}
                            style={{ width: '100%' }}
                            type={'text'}
                            placeholder={`${t('zipCode.label')} *`}
                            color={'secondary'}
                            onChange={evt => setZipcode(evt.target.value)}
                        />
                    </Grid>
                    <Grid container justify={"space-between"} item>
                        <Autocomplete
                            id="asynchronous-demo"
                            multiple={true}
                            style={{ width: "100%", marginTop: 24, border: "1px solid #ebebeb" }}
                            name="userId"
                            onChange={(event, newValue) => {
                                setSelectedUser(newValue);
                            }}
                            value={selectedUser}
                            getOptionSelected={(option, value) => option.id === value}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
                            options={users}
                            loading={userLoading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus={false}
                                    label={t("users.label")}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!name || !address || !region || !country || !zipcode || !orgType) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let userIds = [];
                            if (selectedUser.length > 0) {
                                userIds = selectedUser.map(itm => itm.id);
                            }
                            if (orgType === "partner" && !orgLogo) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (orgType === "partner" && orgDns === '') {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleCreateOrgSubmit({
                                variables: {
                                    name: name,
                                    address: address,
                                    region: region,
                                    country: country,
                                    zipcode: zipcode,
                                    users: userIds,
                                    type: orgType,
                                    dns: orgDns,
                                    logo: (orgLogoFile.length > 0) ? orgLogoFile[0] : null
                                }
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateOrgModal.propTypes = {
    open: PropTypes.bool.isRequired,
    users: PropTypes.array,
    handleClose: PropTypes.func.isRequired,
    handleCreateOrgSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateOrgModal);