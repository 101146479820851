import { gql } from 'apollo-boost';

/*
  Get user info
 */
export const GET_USER_INFO = gql`
  query {
    userInfo {
      id
      firstName
      lastName
      email
      phone
      organization {
        id
        name
        email
        phone
        address
        region
        country
      }
    }
  }
`;

/*
  Get user info
 */
export const GET_SUBSCRIPTION_USER_DETAILS = gql`
  query getSubscriptionUserDetails($id: String) {
    getSubscriptionUserDetails(id: $id) {
      id
      firstName
      lastName
      email
      phone
      organization {
        id
        name
        email
        phone
        address
        region
        country
      }
    }
  }
`;

export const GET_INVITE_MEMBER_TEMP = gql`
  query InviteMember($userId: String) {
    getInviteMembers(createdBy: $userId) {
      id
      userId
      email
      tempKey
    }
  }
`;

export const VERIFY_RESET_PASSWORD_LINK = gql`
  query ResetPassword($id: String, $token: String) {
    verifyResetPasswordLink(id: $id, token: $token) {
      success
      msg
    }
  }
`;
