import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { FABRIC_URL } from '../../../../../envConstants';
import { useAppContext } from '../../../../../contexts/AppContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textField: {
    width: '200px' // Set the desired width
  }
});

export default function SelectSourceOfConnection({ fcrChanged }) {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [fcrs, setFcrs] = useState([]);
  const [item, setItem] = useState('');
  const [fcr, setFcr] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState('');
  const [selectedFcr, setSelectedFcr] = useState('');

  const { state, dispatch } = useAppContext();

  // Api call for port select dropdown
  useEffect(() => {
    const portData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(`${FABRIC_URL}port`, {
        headers: { accessToken: `${token}` }
      });
      const portDatas = await res.data.result.data;
      setItems(portDatas);
    };
    localStorage.getItem('createConnection') === 'yes' && portData();
  }, []);

  // Api call for fcr select dropdown
  useEffect(() => {
    const fcrData = async () => {
      const token = localStorage.getItem('syncomToken');
      const res = await axios.get(`${FABRIC_URL}fcr`, {
        headers: { accessToken: `${token}` }
      });
      const fcrDatas = await res.data.result.data;
      setFcrs(fcrDatas);
    };
    localStorage.getItem('createConnection') === 'yes' && fcrData();
  }, []);

  // Api call for showing details of source port
  useEffect(() => {
    const portData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('syncomToken');
        const sourcePortId = localStorage.getItem('sourcePortId');
        // const sourcePortId = state?.sourcePortDetails?.sourcePortId;
        const res = await axios.get(`${FABRIC_URL}port/${sourcePortId}`, {
          headers: { accessToken: `${token}` }
        });
        const portData = await res.data.result;
        setItem(portData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    localStorage.getItem('createConnection') === 'no' && portData();
  }, []);

  // Api call for showing details of source fcr
  useEffect(() => {
    const portDatas = async () => {
      try {
        const token = localStorage.getItem('syncomToken');
        const sourceFcrId = localStorage.getItem('sourceFcrId');
        // const sourceFcrId = state?.sourceFcrDetails?.sourceFcrId;
        setLoading(true);
        const res = await axios.get(`${FABRIC_URL}fcr/${sourceFcrId}`, {
          headers: { accessToken: `${token}` }
        });
        const portData = await res.data.result;
        setFcr(portData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    localStorage.getItem('createConnection') === 'no' && portDatas();
  }, []);

  const selectPort = async e => {
    const portId = e.target.value;
    setSelectedPort(portId);
    setSelectedFcr('');

    dispatch({
      type: 'SET_SOURCE_PORT_ID',
      payload: portId
    });
    dispatch({
      type: 'SET_SOURCE_TYPE',
      payload: 'port'
    });

    dispatch({
      type: 'SET_SOURCE_PORT_ID',
      payload: portId
    });
    dispatch({
      type: 'SET_SOURCE_TYPE',
      payload: 'port'
    });
  };

  const selectFcr = e => {
    const fcrId = e.target.value;
    setSelectedFcr(e.target.value);
    setSelectedPort('');
    const clientName = fcrs?.find(x => x._id === fcrId)?.client_id?.client_name;
    dispatch({
      type: 'SET_CLIENT_NAME',
      payload: clientName
    });

    fcrChanged();
    dispatch({
      type: 'SET_SOURCE_FCR_ID',
      payload: fcrId
    });
    dispatch({
      type: 'SET_SOURCE_TYPE',
      payload: 'fcr'
    });
  };

  return (
    <>
      {localStorage.getItem('createConnection') === 'yes' ? (
        <Grid container direction="column" xs={12} spacing={2}>
          <Grid item>
            <Typography variant="body1"> Select Source :</Typography>
          </Grid>
          <Grid item container alignItems="center" justify="space-around">
            <Grid item>
              <TextField
                select
                value={selectedPort}
                onChange={selectPort}
                className={classes.textField}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                size="small">
                <option value="">Select Port</option>
                {items
                  ?.filter(
                    item =>
                      item?.operational_state === 'Active' &&
                      (item?.admin_status === 'Deployable' ||
                        item?.admin_status === 'Deployed' ||
                        item?.admin_status === 'Provisioned')
                  )
                  ?.map(regionGet => {
                    return (
                      <option value={regionGet._id}>{regionGet.name}</option>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item>
              <Typography variant="button">OR</Typography>
            </Grid>
            <Grid item>
              <TextField
                select
                value={selectedFcr}
                onChange={selectFcr}
                className={classes.textField}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                size="small">
                <option value="">Select Fcr</option>
                {fcrs
                  ?.filter(
                    item =>
                      item?.oper_status === 'Active' ||
                      item?.oper_status === 'active'
                  )
                  ?.map(regionGet => {
                    return (
                      <option value={regionGet._id}>{regionGet.name}</option>
                    );
                  })}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {localStorage.getItem('createConnection') === 'no' ? (
        <Grid container justify="center">
          <h5>Source :</h5>
          {state.connectionDetails.sourceType === 'fcr' ? (
            <div>
              <button
                variant="outlined"
                style={{
                  marginLeft: '50px',
                  width: '400px',
                  height: '70px',
                  borderStyle: 'solid',
                  borderRadius: '5px',
                  borderWidth: '1px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  backgroundColor: '#5a1f2a',
                  color: 'white'
                }}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <span>
                    FCR Name: {fcr.name} <br />
                    Datacenter Name: {fcr?.datacenter_name}
                  </span>
                )}
              </button>
            </div>
          ) : (
            <div>
              <button
                variant="outlined"
                style={{
                  marginLeft: '50px',
                  width: '400px',
                  height: '70px',
                  borderStyle: 'solid',
                  borderRadius: '5px',
                  borderWidth: '1px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  backgroundColor: '#5a1f2a',
                  color: 'white'
                }}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <span>
                    Port Name: {item.name} <br />
                    Datacenter Name: {item?.datacenter_name}
                  </span>
                )}
              </button>
            </div>
          )}
        </Grid>
      ) : (
        ''
      )}
    </>
  );
}
