import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import {dateFormatterLLL, getRegularCase} from '../../../../utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FilledPending from '../../../../assets/icons/filled_pending.png';
import CheckSuccess from '../../../../assets/icons/check_success.png';
import PendingItem from '../../../../assets/icons/pending_actions.png';
import commonStyles from '../../../../theme/commonStyles';
import {
    SUBSCRIPTION_STATUS,
    ORDER_TRACK_STATUS, SERVICE_TYPES, ORDER_DIA_TRACK_STATUS, ORDER_DIAN_TRACK_STATUS
} from '../../../../utils/constants';
import {useState} from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeline.css';
import {colors} from '../../../../theme/colors';
import Map from '../../../../components/Map';
import SteppedProgressBar from '../SteppedProgressBar';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import NestedMenu from '../NestedMenu';

const useStyles = makeStyles(theme => ({
    itemContainer: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        flexGrow: 1
    },
    toggleContainer: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        flexGrow: 1
    },
    updateIndicator: {
        height: '6px',
        width: '6px',
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        background: theme.palette.success.main,
        borderRadius: '50%'
    },
    orderTrackingContianer: {
        background: '#fafafa'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));
const SiteItem = ({t, data, type, handleMenuItemClick, parent, serviceType, circuitId}) => {
    const [toggleState, setToggleState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const commonClasses = commonStyles();
    const configuration = data.configuration && JSON.parse(data.configuration);
    if (configuration) {
        if (serviceType === SERVICE_TYPES.DIAN) {
            configuration.push({"key": "circuitId", "value": circuitId ? circuitId : 'N/A'})
        }
    }
    const serviceTypes = {
        DIA: ORDER_DIA_TRACK_STATUS,
        DIAN: ORDER_DIAN_TRACK_STATUS
    }
    const getServiceTypeStatus = (serviceType) => {
        switch (serviceType) {
            case SERVICE_TYPES.DIA:
                return ORDER_DIA_TRACK_STATUS;
            case SERVICE_TYPES.DIAN:
                return ORDER_DIAN_TRACK_STATUS;
            default:
                return ORDER_TRACK_STATUS;
        }
    }
    const orderTrack = data.orderTrack
        ? getServiceTypeStatus(serviceType).map((item, index) => {
            if (index > data.orderTrack.length - 1) {
                return {status: item};
            } else {
                return data.orderTrack[index];
            }
        })
        : [];
    console.log('orderTrack', orderTrack);
    return (
        <>
            <Grid
                container
                style={{...paddingGenerator('pt-8'), ...paddingGenerator('pb-24')}}
                className={classes.itemContainer}>
                <Grid item xs style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item style={marginGenerator('mr-16')}>
                        <img
                            src={
                                type === SUBSCRIPTION_STATUS.ACTIVE
                                    ? CheckSuccess
                                    : FilledPending
                            }
                            className={commonClasses.iconLarge}
                            alt={'pending'}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h4'}>{data.name}</Typography>
                        <Typography variant={'subtitle2'}>
                            {t('updatedOn.label')} {dateFormatterLLL(data.updatedAt)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs
                    container
                    justify={'space-between'}
                    alignItems={'flex-start'}
                    style={marginGenerator('mt-8')}>
                    <SteppedProgressBar data={data.orderTrack} serviceType={serviceType} t={t} style={{marginLeft: '-100px'}}/>
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => setToggleState(!toggleState)}>
                            {toggleState ? t('hide.label') : t('track.label')}
                        </Button>
                        {parent === 'organisation' && (
                            <IconButton
                                size={'small'}
                                disableRipple={true}
                                disableFocusRipple={true}
                                color={'secondary'}
                                style={marginGenerator('ml-32')}
                                onClick={evt => {
                                    setAnchorEl(evt.currentTarget);
                                    evt.stopPropagation();
                                }}>
                                <MoreHoriz className={commonClasses.iconRegular}/>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {toggleState && (
                <Grid container className={classes.toggleContainer}>
                    <Grid item container justify={'space-between'}>
                        <Grid
                            item
                            xs={6}
                            style={{
                                ...paddingGenerator('pt-40'),
                                ...paddingGenerator('pb-24')
                            }}>
                            <>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'textPrimary'}
                                    style={{fontWeight: 'bold'}}>
                                    {t('configuration.label')}
                                </Typography>
                                <Grid item xs>
                                    {configuration &&
                                    configuration.map((item, index) => (
                                        <Grid
                                            container
                                            item
                                            justify={'space-between'}
                                            key={index}
                                            style={{...marginGenerator('mt-24')}}>
                                            <Typography
                                                variant={'subtitle1'}
                                                style={{fontWeight: 'normal'}}>
                                                {getRegularCase(item.key)}
                                            </Typography>
                                            <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                    <Grid
                                        container
                                        item
                                        justify={'space-between'}
                                        style={{...marginGenerator('mt-24')}}>
                                        <Typography
                                            variant={'subtitle1'}
                                            style={{fontWeight: 'normal'}}>
                                            Address
                                        </Typography>
                                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                                            {data.address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                            {data.latitude && data.longitude && (
                                <Grid
                                    item
                                    xs
                                    style={marginGenerator('mt-32')}
                                    className={classes.mapContainer}>
                                    <Map
                                        id={data.id}
                                        markers={[
                                            {
                                                position: {lat: data.latitude, lng: data.longitude},
                                                title: data.name
                                            }
                                        ]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {orderTrack && orderTrack.length && (
                            <Grid
                                item
                                xs={5}
                                className={classes.orderTrackingContianer}
                                style={{
                                    ...paddingGenerator('pt-40'),
                                    ...paddingGenerator('pl-56'),
                                    ...paddingGenerator('pb-24')
                                }}>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'textPrimary'}
                                    style={{fontWeight: 'bold'}}>
                                    {t('orderTracking.label')}
                                </Typography>
                                <VerticalTimeline animate={false} layout={'1-column'}>
                                    {orderTrack.map((item, index) => (
                                        <VerticalTimelineElement
                                            key={index}
                                            icon={
                                                <img
                                                    src={item.updatedAt ? CheckSuccess : PendingItem}
                                                    alt={'success'}
                                                    style={{width: 20, height: 20}}
                                                />
                                            }>
                                            <Grid
                                                container
                                                item
                                                direction={'column'}
                                                style={marginGenerator('ml-16')}>
                                                <Typography
                                                    variant={'subtitle1'}
                                                    style={{
                                                        color: item.updatedAt
                                                            ? colors.success.main
                                                            : colors.grey[400]
                                                    }}>
                                                    {item.status}
                                                </Typography>
                                                {item.updatedAt ? (
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        style={{fontWeight: 'normal'}}>
                                                        {t('updatedOn.label')}{' '}
                                                        {dateFormatterLLL(item.updatedAt)}
                                                    </Typography>
                                                ) : null}
                                            </Grid>
                                        </VerticalTimelineElement>
                                    ))}
                                </VerticalTimeline>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {anchorEl && (
                <NestedMenu
                    id={'siteItem-menu'}
                    anchorEl={anchorEl}
                    t={t}
                    serviceType={serviceType}
                    handleClose={evt => {
                        evt.stopPropagation();
                        setAnchorEl(null);
                    }}
                    handleMenuItemClick={item => {
                        handleMenuItemClick(item, data.id);
                        setAnchorEl(null);
                    }}
                    handleViewDetailsClick={() => {
                        setToggleState(!toggleState);
                        setAnchorEl(null);
                    }}
                />
            )}
        </>
    );
};
SiteItem.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};
export default withTranslation()(SiteItem);
