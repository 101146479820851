import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../../theme/commonStyles';
import { colors } from '../../../../theme/colors';
import Timer from '@material-ui/icons/Timer';
import Label from '@material-ui/icons/Label';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  uptimeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  itemContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    flexGrow: 1
  },
  itemAvatar: {
    height: 64,
    width: 64,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.success.main, 0.2)
  },
  avatarName: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.success.main
  }
}));

const colorArr = [colors.success.main, colors.info.main, colors.warning.main];

const ItemAvatar = ({ name, index }) => {
  const classes = useStyles();
  const getColor = colorArr[index % colorArr.length];
  return (
    <div
      className={classes.itemAvatar}
      style={{ backgroundColor: fade(getColor, 0.2) }}>
      <Typography className={classes.avatarName} style={{ color: getColor }}>
        {name.charAt(0)}
      </Typography>
    </div>
  );
};

const ServiceItem = ({
  t,
  data,
  handleSubscribeClick,
  index,
  variant = null,
  containerStyle = {},
  avatarContainerStyle = {}
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <>
      <Grid
        container
        style={{
          ...paddingGenerator('pt-8'),
          ...paddingGenerator('pb-24'),
          ...(variant === 'mini' ? { border: 'none' } : {}),
          ...containerStyle
        }}
        className={classes.itemContainer}>
        <Grid
          item
          xs={variant !== 'mini' ? 8 : 12}
          style={{ display: 'flex', alignItems: 'center' }}>
          <Grid item style={{ ...marginGenerator('mr-16'), ...avatarContainerStyle }}>
            <ItemAvatar name={data.name} index={index} />
          </Grid>

          <Grid item xs={variant !== 'mini' ? 10 : 12}>
            <Typography variant={'h4'}>{data.name}</Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
              {data.description}
            </Typography>

            <Grid
              container
              item
              style={marginGenerator('mt-16')}
              alignItems={'center'}>
              <Typography
                variant={'subtitle2'}
                className={classes.uptimeContainer}
                style={
                  data.avgUptime === 100
                    ? { color: colors.success.main }
                    : data.avgUptime < 100 && data.avgUptime > 50
                    ? { color: colors.warning.main }
                    : { color: colors.error.main }
                }>
                <Timer
                  className={commonClasses.iconRegular}
                  style={marginGenerator('mr-10')}
                />
                {data.avgUptime || 0}% {t('uptime.label')}
              </Typography>
              {variant === 'mini' && (
                <Typography
                  variant={'subtitle2'}
                  className={classes.uptimeContainer}
                  style={{ color: colors.error.main }}>
                  <Label
                    className={commonClasses.iconRegular}
                    style={marginGenerator('mr-10')}
                  />
                  $ {data.avgCost || 0}
                </Typography>
              )}
            </Grid>

            {variant !== 'mini' && (
              <Typography
                variant={'subtitle2'}
                style={{ ...marginGenerator('mt-16'), cursor: 'pointer' }}>
                {t('viewDetails.label')} >
              </Typography>
            )}
          </Grid>
        </Grid>

        {variant !== 'mini' && (
          <Grid
            item
            xs
            container
            justify={'space-between'}
            alignItems={'flex-start'}
            style={marginGenerator('mt-8')}>
            <Grid item>
              <Typography variant={'h4'}>$ {data.avgCost}</Typography>
              <Typography
                variant={'subtitle2'}
                color={'textPrimary'}
                style={{ fontWeight: 'normal' }}>
                {t('avgCostPerMonth.label')}
              </Typography>
            </Grid>
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={() => handleSubscribeClick(data)}>
              {t('subscribe.label')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

ServiceItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubscribeClick: PropTypes.func
};

export default withTranslation()(ServiceItem);
