import React, { useState, useEffect } from 'react';
import ChipInput from 'material-ui-chip-input'
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR, INVALID_PHONE, INVALID_URL } from '../../../../utils/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fade, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { checkValidURL, convertLocalToUTC, convertLocalToUTCDate } from '../../../../utils';
import DateTimeSelector from '../../../../components/DateTimeSelector';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    inputBase: {
        width: '100%',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 15,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
function CreateNotificationTemplateModal({
    t,
    open,
    data,
    countryCodes,
    handleCreateNotificationSubmitClick,
    handleClose,
    modalState,
    isForShayona
}) {

    const [templateName, setTemplateName] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [notificationMsg, setNotificationMsg] = useState('');
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [typeOfCall, setTypeOfCall] = useState('');
    const [webhookUrl, setWebhookUrl] = useState('');
    const [inMessageFields, setInMessageFields] = useState({ camera_id: false, place_location: false, json_tags: false });
    const [emails, setEmails] = useState([]);
    const [dispatchAddress, setDispatchAddress] = useState("");
    const [siteId, setSiteId] = useState("");
    const [isapproverChecked, setIsapproverChecked] = useState(false);
    const [elasticAuthToken, setElasticAuthToken] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const classes = useStyles();

    const types = [
        {
            id: 'email',
            name: "Email"
        },
        {
            id: 'sms',
            name: "SMS"
        },
        {
            id: 'whatsapp',
            name: "WhatsApp"
        },
        {
            id: 'phone',
            name: "Phone"
        },
        {
            id: 'webhook',
            name: "Webhook"
        },
        {
            id: 'sentinel',
            name: "Sentinel"
        },
        {
            id: 'elastic',
            name: "Elastic"
        }
    ];
    const messageFieldsList = [{ id: "camera_id", label: "Camera ID" }, { id: "place_location", label: "Place/Location" }, { id: "json_tags", label: "Json detection Tag" }];

    if (countryCodes.length) {
        countryCodes = countryCodes.map((item) => {
            item.name = item.flag + ' ' + item.dial_code;
            item.id = item.dial_code;
            return item;
        });
    }

    useEffect(() => {
        if (modalState.clearData && open) {
            setTemplateName('');
            setElasticAuthToken('');
            setEmailOrPhone('');
            setNotificationMsg('');
            setNotificationType('');
            setCountryCode('');
            setTypeOfCall('');
            setWebhookUrl('');
            setDispatchAddress('');
            setSiteId('');
            setStartTime(null);
            setEndTime(null);
            setInMessageFields({ camera_id: false, place_location: false, json_tags: false });
            handleClose();
        }
    }, [modalState]);

    useEffect(() => {
        if (data && data.id) {
            console.log("data: ", data);
            setTemplateName(data.name);
            let inMsgField = { camera_id: false, place_location: false, json_tags: false };
            if (data.message_fields && data.message_fields.length) {
                for (let fild of messageFieldsList) {
                    if (data.message_fields.includes(fild.id)) {
                        inMsgField[fild.id] = true;
                    }
                }
            }
            setInMessageFields(inMsgField);
            setNotificationMsg(data.notification_message);
            setNotificationType(data.notification_type);
            let dispatch_address = '';
            if (data.notification_type === "webhook" && data.dispatch_address) {
                dispatch_address = data.dispatch_address.split(" ");
                setWebhookUrl(dispatch_address.length > 1 ? dispatch_address[1] : "");
                setTypeOfCall(dispatch_address.length > 0 ? dispatch_address[0] : "");
            }
            if (typeof data.dispatch_address === 'string' && data.dispatch_address.slice(0, 1) !== '+') {
                dispatch_address = data.dispatch_address.split(",");
                setEmails(dispatch_address);
            } else {
                dispatch_address = data.dispatch_address;
                setEmails([]);
            }
            // if (data.notification_type && data.notification_type !== 'email' && data.notification_type !== 'webhook') {
            //     let cc = dispatch_address.slice(0, -10);
            //     dispatch_address = dispatch_address.split(cc).join('');
            //     setCountryCode(cc);
            // }
            if (data.notification_type && (data.notification_type === 'sentinel' || data.notification_type === 'elastic')) {
                setSiteId(data?.site_id || "");
                setElasticAuthToken(data?.auth_token || "");
            }
            if (data.notification_type && data.notification_type === 'email' && data.notification_type === 'webhook') {
                setEmailOrPhone(dispatch_address);
            } else {
                setDispatchAddress(data.dispatch_address);
            }
            if (data.approver_notification) {
                setIsapproverChecked(data.approver_notification)
            }

        } else {
            setTemplateName('');
            setNotificationType('');
            setNotificationMsg('');
            setEmailOrPhone('');
            setCountryCode('');
            setTypeOfCall('');
            setWebhookUrl('');
            setInMessageFields({ camera_id: false, place_location: false, json_tags: false });
        }
        let start = '';
        let end = '';
        if (data?.start_time && data?.end_time) {
            let todayDate = moment(new Date()).format('YYYY-MM-DD');
            let startTime = moment(new Date(`${todayDate} ${data.start_time}`)).format("HH:mm");
            let endTime = moment(new Date(`${todayDate} ${data.end_time}`)).format("HH:mm");
            start = startTime;
            end = endTime;
        } else {
            start = '00:00';
            end = '23:59';
        }
        setStartTime(start);
        setEndTime(end);
    }, [data]);

    const handleChangeMessageFields = (evt) => {
        setInMessageFields({ ...inMessageFields, [evt.target.value]: evt.target.checked });
    }

    const showingCounttryCode = () => {
        let renderOption = '';
        if (countryCodes.length > 0) {
            countryCodes.map(el => {
                if (el.dial_code === countryCode) {
                    return renderOption = el;
                }
            })
        }
        return renderOption;
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('createNew.label')} {t('notification.label')} {t('template.label')}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid>
                                <Input
                                    value={templateName}
                                    type={'text'}
                                    placeholder={`${t('template.label')} ${t('name.label')}*`}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setTemplateName(evt.target.value)}
                                />
                            </Grid>
                            <Grid style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={notificationType}
                                    label={`${t('notificationType.label')} *`}
                                    handleChange={evt => {
                                        setNotificationType(evt.target.value);
                                        setInMessageFields({ camera_id: false, place_location: false, json_tags: false });
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginRight: 0 }}
                                    options={types ? types : []}
                                />
                            </Grid>
                            {(notificationType !== 'sentinel' && notificationType !== 'elastic' && notificationType !== 'webhook') &&
                                <Grid style={marginGenerator('mt-34')} className={classes.detailsContainer}>
                                    <Grid
                                        style={{
                                            ...paddingGenerator('pt-16'),
                                            ...paddingGenerator('pb-16'),
                                            ...paddingGenerator('pl-24'),
                                            ...paddingGenerator('pr-24')
                                        }}>
                                        <InputBase
                                            fullWidth
                                            value={notificationMsg}
                                            id="details-textarea"
                                            placeholder={t('notificationMsg.label')}
                                            multiline
                                            onChange={evt => setNotificationMsg(evt.target.value)}
                                            variant="standard"
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {isForShayona &&
                                <Grid style={marginGenerator('mt-34')} className={classes.detailsContainer}>
                                    <Grid
                                    >
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                style={paddingGenerator('pl-0')}
                                                checked={isapproverChecked}
                                                value={isapproverChecked}
                                                onChange={(e) => setIsapproverChecked(e.target.checked)}
                                            />
                                            Approver notification
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={3} style={(notificationType && notificationType === 'webhook') ? marginGenerator('mt-25') : marginGenerator('mt-5')}>
                                {notificationType && (notificationType === 'phone' || notificationType === 'sms' || notificationType === 'whatsapp') &&
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                style={{ width: '100%', marginRight: 0 }}
                                                options={countryCodes}
                                                getOptionLabel={(option) => option.name}
                                                value={showingCounttryCode()}
                                                onChange={(evt, newValue) => {
                                                    setCountryCode(newValue ? newValue.dial_code : '');
                                                }}
                                                renderInput={(params) => (
                                                    <InputBase
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        autoFocus
                                                        className={classes.inputBase}
                                                        value={countryCode}
                                                        placeholder={t('code.label')}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Input
                                                value={emailOrPhone}
                                                type={'text'}
                                                placeholder={`${t('phone.label')}*`}
                                                style={{ width: '100%' }}
                                                color={'secondary'}
                                                onChange={evt => setEmailOrPhone(evt.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color="primary" variant="subtitle1" >{data.dispatch_address}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {notificationType && notificationType === 'webhook' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <OutlinedSelect
                                                val={typeOfCall}
                                                label={`${t('typeOfCall.label')} *`}
                                                handleChange={evt => setTypeOfCall(evt.target.value)}
                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                styleOverrides={{ width: '100%' }}
                                                options={["GET", "POST", "PUT", "PATCH", "DELETE"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Input
                                                value={webhookUrl}
                                                type={'text'}
                                                placeholder={`${t('url.label')} *`}
                                                style={{ width: '100%', marginTop: 10 }}
                                                color={'secondary'}
                                                onChange={evt => setWebhookUrl(evt.target.value)}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {notificationType && notificationType === 'email' && <Grid item xs={12}>
                                    <ChipInput
                                        placeholder={`${t('email.label')} *`}
                                        style={{ width: '100%' }}
                                        value={emails}
                                        onAdd={(chip) => {
                                            setEmails([...emails, chip]);
                                            setEmailOrPhone(1); //this setState is using here for avoiding required msg!!!
                                        }}
                                        onDelete={(chip, index) => {
                                            const filtered = emails.filter((el, i) => i !== index);
                                            setEmails(filtered);
                                        }}
                                    />
                                </Grid>}
                            </Grid>

                            {notificationType && (notificationType === 'email' || notificationType === "sms" || notificationType === "whatsapp") &&
                                <Grid style={marginGenerator('mt-24')}>
                                    <Typography variant="subtitle1">Included In Message</Typography>
                                    {messageFieldsList.map(field => {
                                        return (
                                            <FormControlLabel
                                                key={field.id}
                                                control={
                                                    <Checkbox
                                                        checked={inMessageFields[field.id] ? inMessageFields[field.id] : false}
                                                        onChange={handleChangeMessageFields}
                                                        value={field.id}
                                                        color="primary"
                                                    />
                                                }
                                                label={field.label}
                                            />
                                        )
                                    })}
                                </Grid>
                            }

                            {(notificationType === 'sentinel' || notificationType === 'elastic') &&
                                <>
                                    <Grid style={marginGenerator('mt-34')}>
                                        <Input
                                            value={dispatchAddress}
                                            type={'text'}
                                            placeholder={`${(notificationType === 'sentinel') ? 'Sentinel URL' : 'Cloud ID'} *`}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setDispatchAddress(evt.target.value)}
                                        />
                                    </Grid>
                                    {notificationType === 'sentinel' && <Grid style={marginGenerator('mt-24')}>
                                        <Input
                                            value={siteId}
                                            type={'text'}
                                            placeholder={`Site ID *`}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setSiteId(evt.target.value)}
                                        />
                                    </Grid>}
                                    {notificationType === 'elastic' && <Grid style={marginGenerator('mt-24')}>
                                        <Input
                                            value={elasticAuthToken}
                                            type={'text'}
                                            placeholder={`Auth Token *`}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setElasticAuthToken(evt.target.value)}
                                        />
                                    </Grid>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Input
                                    type={'time'}
                                    placeholder={`${t('fromTime.label')}*`}
                                    style={{ width: '100%', marginTop: 24, minWidth: 220 }}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={startTime}
                                    onChange={evt => setStartTime(evt.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    type={'time'}
                                    placeholder={`End Time*`}
                                    style={{ width: '100%', marginTop: 24, minWidth: 220 }}
                                    className={classes.input}
                                    color={'secondary'}
                                    value={endTime}
                                    onChange={evt => setEndTime(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!templateName || !notificationType) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let checkPhoneValid = false;
                            if (notificationType !== 'email' && notificationType !== 'webhook' && notificationType !== 'sentinel' && notificationType !== 'elastic') {
                                checkPhoneValid = true;
                            }
                            if (checkPhoneValid && !countryCode) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let dispatch_address = emails.length > 0 ? emails.join(" , ") : null;
                            if (checkPhoneValid && countryCode) {
                                const regex = /^\d+$/;
                                if (!regex.test(emailOrPhone) || emailOrPhone.length > 10 || emailOrPhone.length < 7) {
                                    Message.error(INVALID_PHONE);
                                    return null;
                                }
                                dispatch_address = countryCode + emailOrPhone;
                            }
                            if (notificationType === 'webhook') {
                                if (!webhookUrl || !typeOfCall) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                if (!checkValidURL(webhookUrl)) {
                                    Message.error(INVALID_URL);
                                    return null;
                                }
                                dispatch_address = `${typeOfCall} ${webhookUrl}`;
                            }
                            let message_fields = [];
                            for (let fild of messageFieldsList) {
                                if (inMessageFields[fild.id]) {
                                    message_fields.push(fild.id);
                                }
                            }
                            let otherFields = {};
                            if (notificationType === 'sentinel' || notificationType === 'elastic') {
                                if (!dispatchAddress) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                if (notificationType === 'elastic') {
                                    if (!elasticAuthToken) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                    otherFields.auth_token = elasticAuthToken;
                                }

                                // if (!checkValidURL(dispatchAddress)) {
                                //     Message.error(INVALID_URL);
                                //     return null;
                                // }
                                dispatch_address = dispatchAddress;
                                otherFields.site_id = siteId;
                            }
                            let start_time = startTime;
                            let end_time = endTime;
                            if (!startTime) {
                                start_time = '00:00';
                            }
                            if (!endTime) {
                                end_time = '23:59'
                            }

                            let postData = {
                                name: templateName,
                                notification_type: notificationType,
                                dispatch_address: dispatch_address,
                                notification_message: notificationMsg ? notificationMsg : templateName,
                                approver_notification: isapproverChecked,
                                message_fields,
                                start_time,
                                end_time,
                                ...otherFields
                            };
                            let todayDate = moment(new Date()).format('YYYY-MM-DD');
                            postData.start_time = convertLocalToUTC(`${todayDate} ${start_time}`) + ':00';
                            postData.end_time = convertLocalToUTC(`${todayDate} ${end_time}`) + ':00';
                            if (data && data.id) {
                                postData.id = data.id;
                            }
                            return handleCreateNotificationSubmitClick(postData);
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateNotificationTemplateModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateNotificationSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateNotificationTemplateModal);
