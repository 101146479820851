import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dateFormatterLLL } from '../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
      width: "100%"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: { fontSize: '16px'},
  pre: { padding: '10px 30px', margin: '0' }
});

export default function DownCameraListModal({t, open, handleClose, cameras}) {
  
const classes = useStyles();
  
  return (
      <Dialog 
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('downCamList.label')}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>{t('cameraName.label')}</TableCell>
                        <TableCell>{t('places.label')}</TableCell>
                        <TableCell>{t('onboardBy.label')}</TableCell>
                        <TableCell>{t('onboardDate.label')}</TableCell>
                        <TableCell>{t('attachedModels.label')}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {cameras.map((row, indx) => (
                        <TableRow key={indx}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.place ? row.place.name : '-'}</TableCell>
                            <TableCell>{row.created_by_name ? row.created_by_name : "-"}</TableCell>
                            <TableCell>{dateFormatterLLL(row.created_at)}</TableCell>
                            <TableCell>{(row.attachedModel) ? row.attachedModel : 0}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
      </Dialog>
  );
}