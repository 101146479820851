import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import { dateFormatterLLL } from '../../../../../../utils';

const ChangeRequestPanelItem = ({
    t,
    data,
    allowConfigure,
    handleMenuItemClick
}) => {
    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <TableRow key={data._id}>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography
                                variant={'subtitle1'}
                                style={{ cursor: 'pointer' }}
                            >
                                {data.title ? data.title : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data?.requestDate ? dateFormatterLLL(data?.requestDate): '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.rgSection ? data.rgSection : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.responsible ? data.responsible : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.requesterName ? data.requesterName : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid className={commonClasses.column} style={{width: 240}}>
                        <Grid>
                            <Typography variant={'subtitle1'}>
                                {data.reasonToChange? data.reasonToChange : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {allowConfigure &&
                    <TableCell>
                        <IconButton>
                            <SettingsIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("edit", data)}>
                            <EditIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleMenuItemClick("delete", data)}>
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

ChangeRequestPanelItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

    export default withTranslation()(ChangeRequestPanelItem);