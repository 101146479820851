import { gql } from 'apollo-boost';

/*
  Create sub admin
 */
export const CREATE_SUB_ADMIN = gql`
  mutation CreateSubAdmin(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $role: String
    $password: String
    $countryId: String
    $orgId: String
  ) {
    createSubAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
      password: $password
      countryId: $countryId
      orgId: $orgId
    ) {
      email
    }
  }
`;

/*
  Create new subscription
 */
export const CREATE_NEW_SUBSCRIPTION = gql`
  mutation Subscription($serviceId: String!, $noOfSites: Int) {
    createNewSubscription(serviceId: $serviceId, noOfSites: $noOfSites) {
      id
      noOfSites
    }
  }
`;

/*
  Update a user
 */
export const UPDATE_SUB_ADMIN = gql`
  mutation UpdateUser(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $phone: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
    ) {
      email
    }
  }
`;

/*
  Delete a user
*/

export const DELETE_USERS = gql`
  mutation DeleteUser($id: String) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UpdateUser(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $phone: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
    ) {
      id
    }
  }
`;

/*
  Add site to subscription
 */
export const UPDATE_SITE_TO_SUBSCRIPTION = gql`
  mutation Subscription(
    $name: String
    $subscriptionId: String
    $configuration: String
    $latitude: Float
    $longitude: Float
    $address: String
    $regionId: String
    $countryId: String
    $zipcode: String
  ) {
    updateSitesOnSubscription(
      name: $name
      subscriptionId: $subscriptionId
      configuration: $configuration
      latitude: $latitude
      longitude: $longitude
      address: $address
      regionId: $regionId
      countryId: $countryId
      zipcode: $zipcode
    ) {
      id
    }
  }
`;

export const UPDATE_VENDORS = gql`
  mutation Vendor(
    $id: String
    $name: String
    $address: String
    $contactPersonName: String
    $contactPersonEmail: String
    $contactPersonPhone: String
  ) {
    updateVedors(
      id: $id
      name: $name
      address: $address
      contactPersonName: $contactPersonName
      contactPersonEmail: $contactPersonEmail
      contactPersonPhone: $contactPersonPhone
    ) {
      id
    }
  }
`;

export const UPDATE_INCIDENT = gql`
  mutation Incident(
    $id: String
    $name: String
    $incidentTypeGroup: String
    $escalationGroup: String
    $allowOverride: String
    $requireContract: String
  ) {
    updateIncidentType(
      id: $id
      name: $name
      incidentTypeGroup: $incidentTypeGroup
      escalationGroup: $escalationGroup
      allowOverride: $allowOverride
      requireContract: $requireContract
    ) {
      id
    }
  }
`;

export const UPDATE_INCIDENT_GROUP = gql`
  mutation IncidentGroup($id: String, $name: String, $description: String) {
    updateIncidentGroup(id: $id, name: $name, description: $description) {
      id
    }
  }
`;

export const ADD_VENDORS = gql`
  mutation Vendor(
    $name: String
    $address: String
    $contactPersonName: String
    $contactPersonEmail: String
    $contactPersonPhone: String
  ) {
    addVendors(
      name: $name
      address: $address
      contactPersonName: $contactPersonName
      contactPersonEmail: $contactPersonEmail
      contactPersonPhone: $contactPersonPhone
    ) {
      id
    }
  }
`;

export const ADD_INCIDENT = gql`
  mutation Incident(
    $name: String
    $incidentTypeGroup: String
    $escalationGroup: String
    $allowOverride: String
    $requireContract: String
  ) {
    addIncidentType(
      name: $name
      incidentTypeGroup: $incidentTypeGroup
      escalationGroup: $escalationGroup
      allowOverride: $allowOverride
      requireContract: $requireContract
    ) {
      id
    }
  }
`;

export const ADD_INCIDENT_GROUP = gql`
  mutation IncidentGroup($name: String, $description: String) {
    addIncidentTypeGroup(name: $name, description: $description) {
      id
    }
  }
`;

export const DELECTE_VENDOR = gql`
  mutation Vendor($id: String) {
    deleteVendor(id: $id) {
      id
    }
  }
`;

export const DELECTE_INCIDENT_GROUP = gql`
  mutation IncidentGroup($id: String) {
    deleteIncidentTypeGroup(id: $id) {
      id
    }
  }
`;

export const DELECTE_INCIDENT = gql`
  mutation Incident($id: String) {
    deleteIncidentType(id: $id) {
      id
    }
  }
`;

export const ADD_USER_LIST_ITEM = gql`
  mutation AllUserList(
    $firstName: String
    $lastName: String
    $emailAddress: String
    $status: String
  ) {
    addUserListItem(
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      status: $status
    ) {
      id
    }
  }
`;

export const UPDATE_USER_LIST_ITEM = gql`
  mutation AllUserList(
    $id: String
    $firstName: String
    $lastName: String
    $emailAddress: String
    $status: String
  ) {
    updateUserList(
      id: $id
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      status: $status
    ) {
      id
    }
  }
`;

export const DELETE_USER_ITEM = gql`
  mutation AllUserList($id: String) {
    deleteUserListItem(id: $id) {
      id
    }
  }
`;

export const ADD_SALES_SPECILIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    addSalesSpecilistItem(
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const UPDATE_SALES_SPECILIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $id: String
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    updateSalesSpecilist(
      id: $id
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const DELETE_SALES_SPECILIST_ITEM = gql`
  mutation AbujaPhcNGList($id: String) {
    deleteSalesSpecilistItem(id: $id) {
      id
    }
  }
`;

export const ADD_LAGOS_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    addLagosNGListItem(
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const UPDATE_LAGOS_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $id: String
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    updateLagosNGList(
      id: $id
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const DELETE_LAGOS_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList($id: String) {
    deleteLagosNGListItem(id: $id) {
      id
    }
  }
`;

export const ADD_ABUJA_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    addAbujaNGListItem(
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const UPDATE_ABUJA_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $id: String
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    updateAbujaNGList(
      id: $id
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const DELETE_ABUJA_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList($id: String) {
    deleteAbujaNGListItem(id: $id) {
      id
    }
  }
`;

export const ADD_PHC_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    addPhcNGListItem(
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const UPDATE_PHC_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList(
    $id: String
    $memberName: String
    $memberEmail: String
    $memberRole: String
    $memberType: String
  ) {
    updatePhcNGList(
      id: $id
      memberName: $memberName
      memberEmail: $memberEmail
      memberRole: $memberRole
      memberType: $memberType
    ) {
      id
    }
  }
`;

export const DELETE_PHC_NGLIST_ITEM = gql`
  mutation AbujaPhcNGList($id: String) {
    deletePhcNGListItem(id: $id) {
      id
    }
  }
`;
