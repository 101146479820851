import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import PlacesMap from '../../../NewSubscription/components/PlacesMap';
import {
    GET_ALL_DEVICE_BY_SCRIPT,
    GET_ALL_PLACES,
    GET_ALL_SCRIPT,
    GET_CONTROLLERS_LIST,
    GET_UCPE_LIST,
    GET_VM_IMAGES_LIST
} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Image from "../../../../assets/icons/image.png";
import Close from "@material-ui/icons/Close";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import ScriptItems from "../ScriptPanel/ScriptItems";
import ScriptDeviceItems from "./ScriptDeviceItems";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    input: {
        minWidth: '224px'
    }
}));
const scriptTypes = [
    {
        id: 'once-before-startup',
        name: 'Once Before Startup'
    },
    {
        id: 'always-before-startup',
        name: 'Always Before Startup'
    }, {
        id: 'one-after-startup',
        name: 'One After Startup'
    },
    {
        id: 'always-after-startup',
        name: 'Always After Startup'
    }
];

function ScriptDeviceList({
                              t,
                              data,
                              open,
                              scriptId,
                              handleApplyScript,
                              handleClose,
                              modalState
                          }) {
    const organization_id = data.organizationId
    let list;
    const columns = [
        {
            label: t('deviceName.label'),
            key: 'DeviceName'
        },
        {
            label: t('action.label'),
            key: 'Action'
        }
    ];
    const handleMenuItemClick = () => {

    }
    const {
        loading: loadingAllScript,
        data: ScriptList,
        error: ScriptError,
        refetch: reloadDeviceByScript
    } = useQuery(GET_ALL_DEVICE_BY_SCRIPT, {
        variables: {
            script_id: scriptId
        }
    });
    if (!loadingAllScript && !ScriptError) {
        list = ScriptList.getDeviceByScript && ScriptList.getDeviceByScript.length ? ScriptList.getDeviceByScript : [];
        console.log(list, 'gjlbnldhodfnhhlist')
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="script-modal"
            aria-describedby="script-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                Script Device List
            </DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid container>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item, index) => (
                                            <TableCell key={index}>
                                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!loadingAllScript && !ScriptError ? (
                                        list.length > 0 ? (
                                            list.map((script, rowIndex) => (
                                                <ScriptDeviceItems
                                                    key={rowIndex}
                                                    t={t}
                                                    script={script}
                                                    handleMenuItemClick={handleMenuItemClick}
                                                />
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={columns.length}>
                                                    <Typography variant={'subtitle2'}>
                                                        Record not found!
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Loading...</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    {/*<ButtonComponent
                        t={t}
                        onClick={() => {
                            return false
                        }}
                        loading={modalState.btnLoading}/>*/}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ScriptDeviceList.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleApplyScript: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ScriptDeviceList);
