import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import InputBase from '@material-ui/core/InputBase';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_ALL_VNF, GET_CONTROLLERS_LIST, GET_UCPE_LIST} from "../../queries";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fade} from "@material-ui/core/styles";
import Message from "../../../../components/Message";
import {INVALID_EMAIL, INVALID_PHONE, REQUIRED_ERROR} from "../../../../utils/constants";
import {SDN_RESEND_OTP} from "../../../OnBoarding/mutations";

function ButtonComponent(props) {
    const {t, onClick, loading, btnText} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t(btnText)}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    detailsContainer: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    inputBase: {
        width: '100%',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 15,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }
}));
function CreateSDNNotificationModal({
                                        t,
                                        data,
                                        notificationData,
                                        open,
                                        countryCodes,
                                        handleCreateNotificationSubmitClick,
                                        handleVerifyNotificationEmailSubmitClick,
                                        handleClose,
                                        modalState
                                    }) {
    let controllers = [];
    let ucpes = [];
    let vnfs = [];
    const organization_id = data.organizationId
    const [notificationName, setNotificationName] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [notificationPriority, setNotificationPriority] = useState('');
    const [notificationMsg, setNotificationMsg] = useState('');
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [uCPEState, setUCPEState] = useState('');
    const [controllerId, setControllerId] = useState('');
    const [vnfState, setVnfState] = useState('');
    const [otpState, setOtpState] = useState('');
    const [countryCode, setCountryCode] = useState('');
    // 60 sec count
    const [seconds, setSeconds] = React.useState(100);
    React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setSeconds(0);
        }
    });
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setNotificationName('');
            setEmailOrPhone('');
            setNotificationMsg('');
            setNotificationType('');
            setNotificationPriority('');
            setUCPEState('');
            setControllerId('');
            setVnfState('');
            setCountryCode('');
            handleClose();
        }
    }, [modalState]);
    const classes = useStyles();
    const types = [
        {
            id: 'email',
            name: "Email"
        }/*,
        {
            id: 'phone',
            name: "Phone"
        }*/,
        {
            id: 'whatsapp',
            name: "WhatsApp"
        }
    ];
    const NotificationPriority = [
        {
            id: 1,
            name: "P0"
        },
        {
            id: 2,
            name: "P1"
        },
        {
            id: 3,
            name: "P2"
        }
    ];
    const {
        loading: loadingControllers,
        data: controllersData,
        error: controllersError
    } = useQuery(GET_CONTROLLERS_LIST, {
        variables: {
            organization_id
        }
    });
    const {loading: loadingUcpes, data: ucpeData, error: ucpeError} = useQuery(GET_UCPE_LIST, {
        variables: {
            organization_id,
            search: ''
        }
    });
    const {
        loading: loadingAllVnfs,
        data: vnfsList,
        error: vnfError
    } = useQuery(GET_ALL_VNF, {
        variables: {
            organization_id
        }
    });
    if (countryCodes.length) {
        countryCodes = countryCodes.map((item) => {
            item.name = item.flag + ' ' + item.dial_code;
            item.id = item.dial_code;
            return item;
        });
    }
    if (!loadingControllers && !controllersError) {
        controllers =
            controllersData.getControllers && controllersData.getControllers.length
                ? controllersData.getControllers.filter(
                controller => controller.controller_status === 'success'
                )
                : [];
    }
    if (!loadingUcpes && !ucpeError) {
        ucpes =
            ucpeData.getUcpes && ucpeData.getUcpes.length
                ? ucpeData.getUcpes.filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
                : [];
    }
    if (!loadingAllVnfs && !vnfError) {
        vnfs = vnfsList.getVnfs && vnfsList.getVnfs.VnfData ? JSON.parse(vnfsList.getVnfs.VnfData) : [];
        vnfs =
            vnfs && vnfs.data && vnfs.data.length
                ? vnfs.data.filter(vnf => vnf.vnf_status === 'success')
                : [];
    }
    const [resendOTP] = useMutation(SDN_RESEND_OTP, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            if (data.resendSDNOTP && data.resendSDNOTP.status) {
                Message.success('OTP sent successfully on your registered email address or phone');
            }
        }
    });
    /*
    * Resend Otp
    * */
    const handleResendOtp = () => {
        return resendOTP({variables: {notification_id: (notificationData && notificationData.Notification_ID) ? notificationData.Notification_ID : ''}})
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('createNew.label')} {t('notification.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <OutlinedSelect
                            val={controllerId}
                            label={`${t('controllerId.label')} *`}
                            handleChange={evt => {
                                setControllerId(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{width: '100%', marginRight: 0}}
                            options={
                                controllers.length > 0
                                    ? controllers.map(controller => {
                                        return {
                                            id: controller.controller_id,
                                            name: controller.controller_name
                                        };
                                    })
                                    : []
                            }
                        />
                    </Grid>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <OutlinedSelect
                            val={uCPEState}
                            label={`${t('uCPE.label')} *`}
                            handleChange={evt => {
                                setUCPEState(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{width: '100%', marginRight: 0}}
                            options={
                                ucpes.length > 0
                                    ? ucpes.map(ucpe => {
                                        return {id: ucpe.device_id, name: ucpe.device_name};
                                    })
                                    : []
                            }
                        />
                    </Grid>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <OutlinedSelect
                            val={vnfState}
                            label={`${t('vnfs.label')}`}
                            handleChange={evt => {
                                setVnfState(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{width: '100%', marginRight: 0}}
                            options={
                                vnfs.length > 0
                                    ? vnfs.map(vnf => {
                                        return {
                                            id: vnf.vnf_id,
                                            name: vnf.vnf_name
                                        };
                                    })
                                    : []
                            }
                        />
                    </Grid>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <OutlinedSelect
                            val={notificationPriority}
                            label={`${t('notificationsPriority.label')} *`}
                            handleChange={evt => {
                                setNotificationPriority(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{width: '100%', marginRight: 0}}
                            options={NotificationPriority ? NotificationPriority : []}
                        />
                    </Grid>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <OutlinedSelect
                            val={notificationType}
                            label={`${t('notificationType.label')} *`}
                            handleChange={evt => {
                                setNotificationType(evt.target.value);
                            }}
                            selectStyle={{...paddingGenerator('p-8')}}
                            styleOverrides={{width: '100%', marginRight: 0}}
                            options={types ? types : []}
                        />
                    </Grid>
                    <Grid item xs={6} style={{...marginGenerator('mt-34')}}>
                        <Input
                            value={notificationName}
                            type={'text'}
                            placeholder={`${t('notification.label')} ${t('name.label')}*`}
                            style={{width: '100%'}}
                            selectStyle={{...paddingGenerator('p-8')}}
                            color={'secondary'}
                            onChange={evt => setNotificationName(evt.target.value)}
                        />
                    </Grid>
                    {notificationType && notificationType !== 'email' ?
                        <React.Fragment>
                            <Grid item xs={4}>
                                <Autocomplete
                                    style={{width: '100%', marginRight: 0}}
                                    options={countryCodes}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(evt, newValue) => {
                                        setCountryCode(newValue ? newValue.dial_code : '');
                                    }}
                                    renderInput={(params) => (
                                        <InputBase
                                            ref={params.InputProps.ref}
                                            inputProps={params.inputProps}
                                            autoFocus
                                            className={classes.inputBase}
                                            placeholder={t('code.label')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Input
                                    value={emailOrPhone}
                                    type={'text'}
                                    placeholder={`${t('emailOrPhone.label')}*`}
                                    style={{width: '100%'}}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    color={'secondary'}
                                    onChange={evt => setEmailOrPhone(evt.target.value)}
                                />
                            </Grid>
                        </React.Fragment>
                        :
                        <Grid item xs={12}>
                            <Input
                                value={emailOrPhone}
                                type={'text'}
                                placeholder={`${t('emailOrPhone.label')}*`}
                                style={{width: '100%'}}
                                selectStyle={{...paddingGenerator('p-8')}}
                                color={'secondary'}
                                onChange={evt => setEmailOrPhone(evt.target.value)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} style={marginGenerator('mt-24')} className={classes.detailsContainer}>
                        <InputBase
                            fullWidth
                            value={notificationMsg}
                            id="details-textarea"
                            placeholder={t('notificationMsg.label')}
                            multiline
                            selectStyle={{...paddingGenerator('p-8')}}
                            onChange={evt => setNotificationMsg(evt.target.value)}
                            variant="standard"
                        />
                    </Grid>
                    {(modalState.verifyData) ?
                        <Grid style={marginGenerator('mt-24')}>
                            <Input
                                value={otpState}
                                type={'number'}
                                placeholder={`${t('otp.label')}*`}
                                style={{width: '100%'}}
                                selectStyle={{...paddingGenerator('p-8')}}
                                color={'secondary'}
                                onChange={evt => setOtpState(evt.target.value)}
                            />
                            <Grid
                                style={{
                                    ...marginGenerator('mt-36'),
                                    position: 'relative'
                                }}>
                                <Button
                                    disabled={seconds < 100}
                                    onClick={() => {
                                        handleResendOtp();
                                        setSeconds(0);
                                    }}
                                    color="secondary"
                                    variant={'contained'}
                                >
                                    {t('resendOtp.label')}
                                </Button>
                                {seconds < 100 && open &&
                                <CircularProgress style={{position: 'absolute', left: '120px'}} variant="static" value={seconds}/>
                                }
                            </Grid>
                        </Grid> : ''}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    {/*<Button
                        disabled={!notificationName || !notificationType || !emailOrPhone ? true : false}
                        onClick={() => {
                            handleCreateNotificationSubmitClick(
                                {
                                    vnf_id: vnfState,
                                    device_id: uCPEState,
                                    controller_id: controllerId,
                                    notification_name: notificationName,
                                    notification_type: notificationType,
                                    notification_parameter: emailOrPhone,
                                    notification_priority: notificationPriority,
                                    notification_template: notificationMsg
                                });
                        }}
                        color="secondary"
                        variant={'contained'}>
                        {t('submit.label')}
                    </Button>*/}
                    {(!modalState.verifyData) ? <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!notificationName || !notificationType || !emailOrPhone) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (notificationType !== 'email' && !countryCode) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let dispatch_address;
                            if (notificationType === 'email' && !countryCode) {
                                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (!regex.test(String(emailOrPhone).toLowerCase())) {
                                    Message.error(INVALID_EMAIL);
                                    return null;
                                }
                                dispatch_address = emailOrPhone;
                            }
                            if (notificationType !== 'email' && countryCode) {
                                const regex = /^\d+$/;
                                if (!regex.test(emailOrPhone) || emailOrPhone.length > 10 || emailOrPhone.length < 7) {
                                    Message.error(INVALID_PHONE);
                                    return null;
                                }
                                dispatch_address = countryCode + emailOrPhone;
                            }
                            handleCreateNotificationSubmitClick(
                                {
                                    vnf_id: vnfState,
                                    device_id: uCPEState,
                                    controller_id: controllerId,
                                    notification_name: notificationName,
                                    notification_type: notificationType,
                                    notification_parameter: dispatch_address,
                                    notification_priority: notificationPriority,
                                    created_by: "Anil",
                                    notification_template: notificationMsg
                                });
                        }}
                        loading={modalState.btnLoading}
                        btnText={'submit.label'}
                    /> : <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleVerifyNotificationEmailSubmitClick(
                                {
                                    otp: otpState,
                                    notification_id: (notificationData && notificationData.Notification_ID) ? notificationData.Notification_ID : ''
                                });
                        }}
                        loading={modalState.btnLoading}
                        btnText={'otpVerification.label'}
                    />}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateSDNNotificationModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateNotificationSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateSDNNotificationModal);
