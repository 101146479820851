import React, { useState, useEffect } from 'react';
import { Paths, TicketingSystem } from '../../../../routes/routePaths';
import Layout from '../../../../components/Layout';
import Header from '../../../../components/Header';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import commonStyles from '../../../../theme/commonStyles';
import { GET_CYBER_SECURITY_LIST } from './queries'
import { UPDATE_CYBER_SECURITY_FORM } from './mutations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TicketPanel from './components/CyberSecurityPanel'
import CustomTabs from '../../../../components/CustomTabs';
import Pagination from '@material-ui/lab/Pagination';
import Message from '../../../../components/Message';
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';
import { getUrl } from '../../../../utils';
import { Button, CircularProgress } from '@material-ui/core';

const breadcrumbList = [
    { label: 'home.label', link: Paths.Dashboard },
    { label: 'newServiceTicket.label', link: TicketingSystem.CM }
];

const useStyles = makeStyles(theme => ({
    exportDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 25
    },
    leftSide: {
        display: 'flex'
    },
    styles: {
        padding: '15px 20px 15px 20px',
        border: '2px solid #1b1818',
        borderRadius: '10px',
        marginRight: '100px',
        marginLeft: '25px'
    },
}));

function ChangeManagement({ t }) {
    const country = localStorage.getItem('country');
    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [reportFields, setReportFields] = useState([]);
    const [downloadReport, setDownloadReport] = useState(false);
    const [reportBtnLoading, setReportBtnLoading] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [changeRequestReportList, setChangeRequestReportList] = useState(false);
    const [pageClosed, setPageClosed] = useState(1);
    const commonClasses = commonStyles();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    useEffect(() => {
        reloadOpenCSData()
        reloadClosedCSData()
    }, [])

    const { loading, error, data: openCSData, refetch: reloadOpenCSData } = useQuery(GET_CYBER_SECURITY_LIST, {
        variables: {
            status: 'Open',
            page: page
        }
    });

    const { closedLoading, closedError, data: closedCSData, refetch: reloadClosedCSData } = useQuery(GET_CYBER_SECURITY_LIST, {
        variables: {
            status: 'Closed',
            page: pageClosed
        }
    });

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const handlePageChangeClosed = (event, value) => {
        setPageClosed(value)
    }

    const handleTicketItemClick = id => {
        const path = getUrl(TicketingSystem.CSDetails, id);
        history.push(path);
    };

    const [closeCSIncidentRequest] = useMutation(UPDATE_CYBER_SECURITY_FORM, {
        onError: error => {
            console.log('Error', error);
            Message.error('There is some error in closing ticket. Please try again');
        },
        onCompleted: () => {
            reloadOpenCSData()
            reloadClosedCSData()
            Message.success('Cyber Security Incident Closed Successfully.');
        }
    });

    const handleMenuItemClick = (item, id) => {
        if (item.label === t('closeTicket.label')) {
            // close ticket request
            return closeCSIncidentRequest({
                variables: {
                    id: id,
                    status: 'Closed'
                }
            });
        } else if (item.label === t('viewDetails.label')) {
            handleTicketItemClick(id);
        }
    };

    const handleCyberSecurity = () => {
        history.push(TicketingSystem.CyberSecurityForm)
    };

    const handleBackButton = () => {
        history.goBack();
    }

    return (
        <Layout breadcrumbList={breadcrumbList}>
            <Header
                title={t('cyberSecurityList.label')}
                divider={true}
                subText={t('cyberSecurityListSub.label')}
                primaryButtonText={t('back.label')}
                handlePrimaryButtonClick={handleBackButton}
                secondaryButtonText={t('cyberSecurityForm.label')}
                handleSecondaryButtonClick={handleCyberSecurity}
            />

            <CustomTabs
                tabs={[
                    {
                        label: t('openTickets.label'),
                        children: (
                            <>
                                <TicketPanel
                                    t={t}
                                    loading={loading}
                                    handleMenuItemClick={handleMenuItemClick}
                                    handleTicketItemClick={handleTicketItemClick}
                                    ticketList={openCSData && openCSData.cyberSecurity.data}
                                />
                                <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
                                    count={openCSData && openCSData.cyberSecurity && openCSData.cyberSecurity.total ?
                                        Math.ceil(openCSData.cyberSecurity.total / 30) : 0} page={page} onChange={handlePageChange} />
                            </>
                        )
                    },
                    {
                        label: t('closedTickets.label'),
                        children: (
                            <>
                                <TicketPanel
                                    t={t}
                                    loading={closedLoading}
                                    handleMenuItemClick={handleMenuItemClick}
                                    handleTicketItemClick={handleTicketItemClick}
                                    ticketList={closedCSData && closedCSData.cyberSecurity.data}
                                />
                                <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
                                    count={closedCSData && closedCSData.cyberSecurity && closedCSData.cyberSecurity.total ?
                                        Math.ceil(closedCSData.cyberSecurity.total / 30) : 0} page={pageClosed} onChange={handlePageChangeClosed} />
                            </>
                        )
                    }
                ]}
            />
        </Layout>
    );
}

export default withTranslation()(ChangeManagement);
