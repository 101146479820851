import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths, NewSubscriptionPaths } from '../../routes/routePaths';
import SelectSubscription from './SelectSubscription';
import ConfigureSites from './ConfigureSites';
import ReviewSites from './ReviewSites';

class NewSubscription extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={Paths.NewSubscription}
          component={SelectSubscription}
        />
        <Route
          exact={true}
          path={NewSubscriptionPaths.ConfigureSites}
          component={ConfigureSites}
        />
        <Route
          exact={true}
          path={NewSubscriptionPaths.ReviewSites}
          component={ReviewSites}
        />
      </Switch>
    );
  }
}
export default NewSubscription;
