import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SERVICES_TYPES } from './queries';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { NewSubscriptionPaths, Paths } from '../../routes/routePaths';
import CustomTabs from '../../components/CustomTabs';
import { useHistory, useLocation } from 'react-router-dom';
import ServicesListPanel from './components/ServiceListPanel';
import SelectSiteModal from './components/SelectSiteModal';
import { CREATE_NEW_SUBSCRIPTION } from './mutations';
import LinearProgress from '@material-ui/core/LinearProgress';
import { USER_ROLES, ACCESS_ERROR } from '../../utils/constants';
import Message from '../../components/Message';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscriptions.label', link: Paths.Subscriptions },
  { label: 'newSubscriptions.label', link: Paths.NewSubscription }
];

const SelectSubscription = ({ t }) => {
  const location = useLocation();
  const history = useHistory();
  const [selectSiteModal, setModalState] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [breadCrumbList, setBreadcrumbList] = useState([...breadcrumbList]);
  const role = localStorage.getItem('role');
  

  if (
    role.includes(USER_ROLES.EP_FINANCE) ||
    role.includes(USER_ROLES.EP_IT) ||
    role.includes(USER_ROLES.IP_USER)
  ) {
    Message.error(`Error: ${ACCESS_ERROR}`);
    history.push({
      pathname: Paths.Dashboard
    });
  }

  useEffect(() => {
    if (location.search && location.search.length) {
      let updatedBreadcrumbList =
        location.search === '?browse'
          ? breadcrumbList.slice(0, 2)
          : breadcrumbList.slice(0, 1);
      updatedBreadcrumbList.push({
        label: 'browseServices.label',
        link: Paths.NewSubscription
      });
      setBreadcrumbList(updatedBreadcrumbList);
    }
  }, [location]);

  /*
     List Queries
    */
  const {
    loading: typesLoading,
    error: typesError,
    data: serviceTypes
  } = useQuery(GET_SERVICES_TYPES, {fetchPolicy: "no-cache"});

  const [createNewSubscription] = useMutation(CREATE_NEW_SUBSCRIPTION, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      const { createNewSubscription } = data;
      history.push({
        pathname: NewSubscriptionPaths.ConfigureSites,
        search: `?id=${createNewSubscription.id}?sites=${createNewSubscription.noOfSites}?type=${selectedService.serviceType.name}`
      });
    }
  });

  const handleBeginConfigurationClick = (selectedSites, name) => {
    console.log("selectedService", selectedService);
    return createNewSubscription({
      variables: {
        serviceId: selectedService._id,
        noOfSites: selectedSites,
        ownerId: localStorage.getItem('orgId')
          ? localStorage.getItem('orgId')
          : null,
        name: name
      }
    });
  };

  const handleSubscribeClick = data => {
    setSelectedService(data);
    setModalState(true);
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const tabs =
    !typesLoading && !typesError ? serviceTypes.serviceTypesAvailableForUser &&
        serviceTypes.serviceTypesAvailableForUser.map(item => {
          return {
            label: item.name,
            children: (
              <>
                <ServicesListPanel
                  handleServiceItemClick={() => null}
                  handleSubscribeClick={handleSubscribeClick}
                  serviceList={item.services}
                  t={t}
                />
              </>
            )
          };
        })
      : [];

    

    
  return (
    <Layout breadcrumbList={breadCrumbList}>
      <Header
        title={t('services.label')}
        subText={t('selectSubscriptionDesc.label')}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
      />
      {tabs && tabs.length ? <CustomTabs tabs={tabs} 
         
      /> : null}
      {/* <button onClick={handleClick}>Click here for services</button> */}
      {(typesLoading) && (
        <LinearProgress
          color="primary"
          style={{
            top: '98px',
            left: '0px',
            position: 'absolute',
            width: '100%'
          }}
        />
      )}
        <SelectSiteModal
          selectedService={selectedService}
          open={selectSiteModal}
          handleBeginConfigurationClick={handleBeginConfigurationClick}
          handleClose={() => setModalState(false)}
        />
    </Layout>
  );
};
export default withTranslation()(SelectSubscription);
