import React, { useState, useCallback, useEffect } from 'react';
import { Paths, TicketingSystem } from '../../routes/routePaths';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { GET_ALL_SUBSCRIPTION_LIST } from '../Subscriptions/queries';
import { REQUIRED_ERROR, SUBSCRIPTION_STATUS } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { getParam } from '../../utils';
import { marginGenerator } from '../../theme/utils';
import { colors } from '../../theme/colors';
import Grid from '@material-ui/core/Grid';
import {
  GET_TICKET_CATEGORIES,
  GET_ESCALATION_LIST,
  GET_INCIDENT_TYPE_LIST,
  GET_INCIDENT_TYPE_GROUP_LIST,
  GET_VENDOR_LIST,
  GET_ASSIGNEE_LIST,
  GET_ALL_SUBSCRIPTION_LIST,
  GET_CUSTOMERS_FROM_MONGO
} from './queries';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../commonQueries';
import Button from '@material-ui/core/Button';
import commonStyles from '../../theme/commonStyles';
import { UPDATE_MAIL_TICKET } from './mutations';
import Message from '../../components/Message';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'serviceTickets.label', link: Paths.TicketSystem },
  { label: 'updateTicket.label', link: TicketingSystem.Update }
];

const useStyles = makeStyles(theme => ({
  reviewItemContainer: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  detailsContainer: {
    border: `1px solid ${theme.palette.grey[200]}`
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2)
  }
}));

function NewServiceTicket({ t }) {
  const id = getParam();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedService, setSelectedService] = useState('');
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedSitesData, setSelectedSitesData] = useState([]);
  const [incidentTypeGroup, setIncidentTypeGroup] = useState([]);
  const [incidentType, setIncidentType] = useState([]);
  const [escalationLevel, setEscalationLevel] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [region, setRegion] = useState('');
  const [search, setSearch] = useState('');
  const [assigneeData, setAssigneeData] = useState([]);
  const [uploadedFileLoading, setUploadedFileLoading] = useState(false);
  const [vodacomProblem, setVodacomProblem] = useState(false);

  const [getUserListFromMongo, { loading: mongoUserLoading, data: userList }] = useLazyQuery(GET_CUSTOMERS_FROM_MONGO);
  const { data: escalationgroup } = useQuery(GET_ESCALATION_LIST);
  const { data: incidenttype } = useQuery(GET_INCIDENT_TYPE_LIST);
  const { data: incidenttypegroup } = useQuery(GET_INCIDENT_TYPE_GROUP_LIST);
  const { data: vendor } = useQuery(GET_VENDOR_LIST);
  const { data: assignee } = useQuery(GET_ASSIGNEE_LIST);
  const { data: categoryData } = useQuery(GET_TICKET_CATEGORIES);

  const [
    getPendingSubscriptions,
    { data: pendingSubscriptions }
  ] = useLazyQuery(GET_ALL_SUBSCRIPTION_LIST);

  const [getActiveSubscriptions, { data: activeSubscriptions }] = useLazyQuery(
    GET_ALL_SUBSCRIPTION_LIST
  );

  const { data: countryList } = useQuery(GET_COUNTRY_LIST);
  const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);

  useEffect(() => {
    getUserListFromMongo()
  }, []);

  const [updateMailServiceTicket] = useMutation(UPDATE_MAIL_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket updated successfully.');
      setUploadedFileLoading(false);
      history.push(Paths.TicketSystem);
    }
  });

  const handleCancelClick = () => {
    history.goBack();
  };

  const serviceOptions =
    pendingSubscriptions &&
    activeSubscriptions &&
    pendingSubscriptions.getAllSubscriptionsListing &&
    activeSubscriptions.getAllSubscriptionsListing &&
    [
      ...pendingSubscriptions.getAllSubscriptionsListing,
      ...activeSubscriptions.getAllSubscriptionsListing
    ].map((item, index) => {
      return {
        id: item.id,
        name: item.name != null ? item.name : item.service.name
      };
    });

  const siteOptions =
    selectedService &&
    selectedService.id &&
    pendingSubscriptions &&
    activeSubscriptions &&
    pendingSubscriptions.getAllSubscriptionsListing &&
    activeSubscriptions.getAllSubscriptionsListing &&
    [
      ...pendingSubscriptions.getAllSubscriptionsListing,
      ...activeSubscriptions.getAllSubscriptionsListing
    ]
      .filter(item => item.id === selectedService.id)
      .map(item => item.sites)
      .flat(1);

  const handleSitesValue = (event, newValue) => {
    let siteArray =
      newValue &&
      newValue.length &&
      newValue.map(data => {
        return data;
      });
    setSelectedSites(siteArray);
    let siteArrayData =
      newValue &&
      newValue.length &&
      newValue.map(data => {
        return data.id;
      });
    setSelectedSitesData(siteArrayData);
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue.length === 0) {
      setSelectedCustomer([]);
      setSelectedService([]);
      setSelectedSites([]);
    } else {
      if (
        newValue[newValue.length - 1].attributes &&
        newValue[newValue.length - 1].attributes.Status &&
        newValue[newValue.length - 1].attributes.Status[0].toLowerCase() !=
        'active'
      ) {
        Message.error('User Account Is Not Active.');
      } else {
        let customerArray =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data;
          });
        setSelectedCustomer(customerArray);

        let customerArrayData =
          newValue &&
          newValue.length &&
          newValue.map(data => {
            return data.id;
          });
        newValue &&
          newValue.length &&
          getPendingSubscriptions({
            variables: {
              status: SUBSCRIPTION_STATUS.PENDING,
              id: customerArrayData
            }
          });
        newValue &&
          newValue.length &&
          getActiveSubscriptions({
            variables: {
              status: SUBSCRIPTION_STATUS.ACTIVE,
              id: customerArrayData
            }
          });
      }
    }
  };

  const classes = useStyles();
  const commonClasses = commonStyles();

  const handleChange = () => {
    setVodacomProblem(!vodacomProblem);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChangeSearchCustomer = (value) => {
    if (value && value.target.value) {
      setSearch(value.target.value)
      getUserListFromMongo({
        variables: {
          search: value.target.value
        }
      })
    }
  };

  const handleInputChange = useCallback(debounce(handleChangeSearchCustomer), []);

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '680px' }}>
      <Header
        title={t('updateTicket.label')}
        divider={true}
        primaryButtonText={t('cancel.label')}
        handlePrimaryButtonClick={handleCancelClick}
      />

      <Grid style={{ ...marginGenerator('mt-32') }}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={marginGenerator('mb-8')}>
          {t('whatIsItRelatedTo.label')} *
        </Typography>
        {categoryData &&
          categoryData.categories.length &&
          categoryData.categories.map((item, index) => (
            <Radio
              checked={item.id === selectedOption.id}
              key={index}
              classes={{ root: classes.iconButton }}
              style={{
                ...marginGenerator('mr-16'),
                ...(item.id === selectedOption.id
                  ? { backgroundColor: colors.secondary.main }
                  : '')
              }}
              onChange={() => setSelectedOption(item)}
              value={item.id}
              name="type-select"
              checkedIcon={
                <div>
                  <Typography
                    variant={'subtitle2'}
                    style={{ color: colors.common.white }}>
                    {item.name}
                  </Typography>
                </div>
              }
              icon={
                <div>
                  <Typography variant={'subtitle2'}>{item.name}</Typography>
                </div>
              }
            />
          ))}

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectCountry.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={countryData}
          options={countryList ? countryList.getCountries : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            newValue &&
              newValue.id &&
              getRegionList({ variables: { countryId: newValue.id } });
            setRegion('');
            setCountryData(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRegion.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={region}
          options={regionList ? regionList.getRegionsByCountryId : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setRegion(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectCustomer.label')}
        </Typography>

        <Autocomplete
          multiple
          id="tags-standard"
          value={selectedCustomer}
          options={userList && userList.getCustomerDataFromMongo ? userList.getCustomerDataFromMongo : []}
          getOptionLabel={option =>
            ` ${option.organisations && option.organisations[0]
              ? option.organisations[0].name
              : ''
            }
                ${option.location ? option.location : ""} ${option.basestation ? option.basestation : ""} ${option.vendor ? option.vendor : ""} ${option.configured_DN_CIR ? option.configured_DN_CIR : ""
            } ${option.configured_UP_CIR ? option.configured_UP_CIR : ""} ${option.platform ? option.platform : ""} ${option.status ? option.status : ""
            }`
          }
          onChange={(event, newValue) =>
            handleChangeCustomer(event, newValue)
          }
          onInputChange={evt => handleInputChange(evt)}
          loading={mongoUserLoading}
          autoHighlight
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              style={{
                width: '100%',
                border: '1px solid #ebebeb !important'
              }}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {mongoUserLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRelevantService.label')} *
        </Typography>

        <Autocomplete
          id="combo-box-demo2"
          value={selectedService}
          disabled={!Boolean(selectedCustomer)}
          options={
            serviceOptions && serviceOptions.length ? serviceOptions : []
          }
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => {
            setSelectedService(newValue);
            setSelectedSites([]);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('selectRelevantSites.label')}
        </Typography>

        <Autocomplete
          multiple
          id="tags-standard"
          value={selectedSites}
          disabled={!Boolean(selectedService)}
          options={siteOptions && siteOptions.length ? siteOptions : []}
          getOptionLabel={option => (option ? option.name : '')}
          onChange={(event, newValue) => handleSitesValue(event, newValue)}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              style={{
                width: '100%',
                border: '1px solid #ebebeb !important'
              }}
              {...params}
              variant="standard"
            />
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentTypeGroup.label')} *
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          val={incidentTypeGroup}
          options={incidenttypegroup ? incidenttypegroup.incidenttypegroup : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setIncidentTypeGroup(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('incidentType.label')}
        </Typography>

        <Autocomplete
          id="combo-box-demo"
          val={incidentType}
          options={incidenttype ? incidenttype.incidenttype : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setIncidentType(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('escalationLevel.label')}*
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          val={escalationLevel}
          options={escalationgroup ? escalationgroup.escalationgroup : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setEscalationLevel(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{ ...marginGenerator('mb-8'), ...marginGenerator('mt-40') }}>
          {t('vendor.label')}
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          val={vendorData}
          options={vendor ? vendor.vendor : []}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setVendorData(newValue);
          }}
          style={{ width: '94%' }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                style={{
                  width: '100%',
                  padding: '18px',
                  border: '1px solid #ebebeb !important'
                }}
                type="text"
                {...params.inputProps}
              />
            </div>
          )}
        />

        <div className="additionalField">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={vodacomProblem}
                name="checkedB"
                color="primary"
              />
            }
            label="INQ Problem"
          />
        </div>
      </Grid>
      <Grid
        container
        style={{ ...marginGenerator('mt-56'), ...marginGenerator('mb-56') }}
        justify={'flex-end'}>
        <Button
          variant={'contained'}
          disabled={uploadedFileLoading}
          color={'primary'}
          onClick={() => {
            if (
              !selectedOption.id ||
              !selectedService ||
              !countryData.id ||
              !region.id ||
              !selectedCustomer ||
              !selectedSitesData ||
              !incidentTypeGroup ||
              !incidentType ||
              !escalationLevel ||
              !vendorData
            ) {
              Message.error(REQUIRED_ERROR);
              return null;
            }
            setUploadedFileLoading(true);
            return updateMailServiceTicket({
              variables: {
                serviceTicketId: id,
                category: selectedOption.id,
                country: countryData.id,
                region: region.id,
                customer: selectedCustomer[0].id,
                selectedCustomer: selectedCustomer,
                subscription: selectedService.id,
                sites: selectedSitesData,
                incidentTypeGroup: incidentTypeGroup.name,
                incidentType: incidentType.name,
                escalationLevel: escalationLevel.name,
                vendor: vendorData.name,
                circuitId: selectedSites && selectedSites[0] && selectedSites[0].circuitId,
                platform: selectedSites && selectedSites[0] && selectedSites[0].platform,
                vodacomProblem: vodacomProblem,
                action: "updateTicket"
              }
            });
          }}>
          {uploadedFileLoading ? (
            <CircularProgress size={14} />
          ) : (
            'Update Ticket'
          )}
        </Button>
      </Grid>
    </Layout>
  );
}

export default withTranslation()(NewServiceTicket);
