import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import commonStyles from '../../../../../theme/commonStyles';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CompleteDetailModal({
    t,
    open,
    handleClose,
    handleProjectAttachmentClick,
    data,
    loading,
    previous_comment,
    documents,
    projectDetail
}) {
    const classes = useStyles();
    React.useEffect(() => { }, [open, data, loading]);
    const commonClasses = commonStyles();
    const columns = [
        {
            label: t('name.label'),
            key: "name"
        },
        {
            label: t('details.label'),
            key: "details"
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('ViewCompleteDetails.label')}</DialogTitle>
            <DialogTitle>
                {projectDetail.map((item, index) => (
                    <Typography variant="subtitle1">{item.label} : {item.value}</Typography>
                ))}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item, index) => (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading ? data && data.length ?
                                    <React.Fragment>
                                        {data.map((item, index) => (
                                            <TableRow>
                                                <TableCell>
                                                    <Grid className={commonClasses.column}>
                                                        <Grid>
                                                            <Typography variant={'subtitle1'}>
                                                                {item.label ? item.label : 'N/A'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid className={commonClasses.column}>
                                                        <Grid>
                                                            {item.button ?
                                                                <Button variant={'outlined'}
                                                                    disabled={(item.value == '' || item.value == null) ? true : false}
                                                                    color={'primary'}
                                                                    style={{ width: '100%' }}
                                                                    onClick={() => {
                                                                        if (item.value != undefined) {
                                                                            window.open(item.value);
                                                                        }
                                                                    }}>{t('clickHereToViewFiles.label')}</Button>
                                                                :
                                                                <Typography variant={'subtitle1'}>
                                                                    {item.value ? item.value : 'N/A'}
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {previous_comment && previous_comment.length ?
                                            <TableRow>
                                                <TableCell>
                                                    <Grid className={commonClasses.column}>
                                                        <Grid>
                                                            <Typography variant={'subtitle1'}>
                                                                {t('previousComment.label')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid className={commonClasses.column}>
                                                        <Grid>
                                                            {previous_comment && previous_comment.length ?
                                                                JSON.parse(previous_comment).map((item, index) => (
                                                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                                                )) : ''}
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                            : ''}
                                    </React.Fragment> :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow> : null}
                                <TableRow>
                                    {loading &&
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Loading...</Typography>
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CompleteDetailModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CompleteDetailModal);
