import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import Message from './components/Message';
import { SERVER_URL } from './envConstants';
import { onLogout } from './utils';
// import { WebSocketLink } from '@apollo/client/link/ws';

const cache = new InMemoryCache();

export const getToken = () => {
  const token = localStorage.getItem('syncomToken');
  return token ? token : '';
};

export const getOrgId = () => {
  const orgId = localStorage.getItem('orgId');
  return orgId ? orgId : '';
};

export const getCountryId = () => {
  const countryId = localStorage.getItem('countryId');
  return countryId ? countryId : '';
};

const request = async operation => {
  operation.setContext({
    headers: {
      authorization: getToken(),
      orgId: getOrgId(),
      countryId: getCountryId()
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

// const wsLink = new WebSocketLink({
//   uri: `ws://${SERVER_URL}/subscriptions`,
//   options: {
//     reconnect: true
//   }
// });

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(
          ({ message, locations, path, extensions }, index) => {
            Message.error(message, index);
            if (
              extensions.code === 'UNAUTHENTICATED' &&
              window.location.pathname !== '/'
            ) {
              onLogout();
            }
          }
        );
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        return null;
      }
    }),
    requestLink,
    new createUploadLink({
      uri: `${SERVER_URL}/graphql`
    })
  ]),
  credentials: 'include',
  fetchOptions: {
    mode: 'cors'
  },
  cache
});
