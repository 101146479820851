import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import '../../styles.css';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../../src/components/Message';
import Divider from '@material-ui/core/Divider';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator } from '../../../../theme/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const initState = {
  title: ''
};
function TicketUpdateTitle({
  open,
  handleClose,
  handleSubmitUpdateTicketTitle,
  buttonLoading,
  ticketData,
  t
}) {
  const [title, setTitle] = useState(ticketData.title);

  const handleSubmitData = () => {
    if (title) {
      handleSubmitUpdateTicketTitle({ title });
    } else {
      Message.error(REQUIRED_ERROR);
    }
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add Incident Outline
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{
                  ...marginGenerator('mb-8'),
                  ...marginGenerator('mt-40')
                }}>
                {t('enterIncident.label')} *
              </Typography>
              <TextField
                type="textarea"
                placeholder="Add Incident Outline"
                variant="standard"
                name="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth={true}
                multiline
                rows={2}
                rowsMax={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(TicketUpdateTitle);
