import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Page } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import BillTo from './BillTo';
import { withTranslation } from 'react-i18next';
import InvoiceHeading from './InvoiceNo';
import InvoiceTableFooter from './InvoiceTableFooter';
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24
  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 50,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
});

const InvoiceItemsTable = ({ invoice, t, org, country, userInfo, time,platform, circuitId, siteName }) => {
  return (
    <>
      { invoice && invoice.length && invoice.map(item => {
        return (
          <Page size="A4" style={styles.page}>
            <View style={styles.tableContainer} key={item[0].id}>
              <InvoiceHeading userInfo={userInfo} country={country} org={org} time={time} />
              <BillTo 
              invoice={item}
               org={org}
               platform={platform}
               circuitId={circuitId}
               siteName={siteName}  
               />
              <InvoiceTableHeader
                row={{
                  title: t('outService.label'),
                  head1: t('onService.label'),
                  head2: t('faultId.label'),
                  rate: t('restoreTime.label'),
                  head4: t('targetMeet.label'),
                  head5: t('includedIn.label'),
                  head3: t('faultType.label'),
                  head6: t('comments.label')
                }}
                items={item}
              />
              <InvoiceTableRow items={item} />

              <InvoiceTableFooter data={item} />
            </View>
          </Page>
        )
      })
      }
    </>
  );
};

export default withTranslation()(InvoiceItemsTable);
