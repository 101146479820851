import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import RtspProxyItem from './RtspProxyItem';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
function ViewRtspProxies({
    t,
    open,
    handleClose,
    columns,
    data,
    allowConfigure,
    handleMenuItemClick,
    menuItems
}) {
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="node-modal"
            aria-describedby="node-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="node-modal-title">{t('listRtspProxies.label')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item, index) => (
                                            <TableCell key={index}>
                                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.length > 0 ?
                                        data.map((rowitem, rowIndex) => (
                                            <RtspProxyItem
                                                key={rowIndex}
                                                t={t}
                                                data={rowitem}
                                                menuItems={menuItems}
                                                handleMenuItemClick={handleMenuItemClick}
                                                allowConfigure={allowConfigure}
                                            />
                                        )) :
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('close.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

ViewRtspProxies.propTypes = {
    handleClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ViewRtspProxies);
