import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DocsIcon from '../../../../assets/icons/docs.png'
import commonStyles from '../../../../theme/commonStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

export default function Docs(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem>
          <ListItemIcon>
            <img
              src={DocsIcon}
              className={commonClasses.iconSmall}
              alt={'docs'}
            />
          </ListItemIcon>
          <ListItemText primary={props.title} />
        </ListItem>
      </List>
    </div>
  );
}
