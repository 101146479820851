import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomTabs from '../../../../components/CustomTabs';
import PreBuildAnalytics from './PreBuildAnalytics';
import CustomAnalytics from './CustomAnalytics';
import AnalyticsDashboardPanel from './AnalyticsDashboardPanel';
import { GET_CAMERA_LIST, GET_PLACE_LIST, GET_HEATMAP_LIST } from "../../queries";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import ScheduleReportAnalytics from './ScheduleReportAnalytics';

const EdgeAIAnalyticsPanel = ({
    t,
    data,
    handleCreateURLClick,
    configureActions,
    mlTemplateList,
    aiTypes,
    handleImageModalClick,
    notiTemplateList,
    subActiveIndex,
    selectedPath
}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [cameraList, setCameraList] = useState([]);
    const [placeList, setPlaceList] = useState([]);
    const [cameraLoading, setCameraLoading] = useState([]);
    const [placeLoading, setPlaceLoading] = useState([]);

    useEffect(() => {
        if (data) {
            let organization = data.organizationId;
            getCameraList({ variables: { organization, page: 1, limit: 10000 } });
            getAllPlaceList({ variables: { organization, page: 1, limit: 20 } });
            if (organization === "1638263571872650") {
                setActiveIndex(2);
            }
        }
    }, [data]);

    const {
        data: heatmapData,
        loading: heatmapLoading,
        error: heatmapError
    } = useQuery(GET_HEATMAP_LIST);

    const [getCameraList] = useLazyQuery(GET_CAMERA_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setCameraLoading(false);
            const camList = (data.getAllCameraList && data.getAllCameraList.cameras) ? data.getAllCameraList.cameras.map(item => {
                let camObj = { name: item.name, id: item.camera_id, templates: [] };
                if (item.schedules && item.schedules.length) {
                    for (let conf of item.schedules[0]) {
                        if (conf.model_template.id && conf.model_template.id) {
                            camObj.templates.push(conf.model_template.id);
                        }
                    }
                }
                return camObj;
            }) : [];
            setCameraList(camList);
        }
    });

    const [getAllPlaceList] = useLazyQuery(GET_PLACE_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setPlaceLoading(false);
            const placeList = (data.getAllPlaceList && data.getAllPlaceList.places) ? data.getAllPlaceList.places.map(item => {
                let plcObj = { name: item.name, id: item.place_id };
                return plcObj;
            }) : [];
            setPlaceList(placeList);
        }
    });

    const handleAutoPlaceSearch = (value) => {
        setPlaceList([]);
        return getAllPlaceList({
            variables: {
                organization: data.organizationId,
                search: value ? value : "",
                limit: 20,
                page: 1
            }
        });
    }

    const configTabs = [
        {
            label: t('dashboard.label'),
            children: (
                <>
                    <AnalyticsDashboardPanel
                        t={t}
                        data={data}
                    />
                </>
            )
        },
        {
            label: t('preBuild.label'),
            children: (
                <>
                    <PreBuildAnalytics
                        t={t}
                        mlTemplateList={mlTemplateList}
                        data={data}
                        cameraList={cameraList}
                        placeList={placeList}
                        placeLoading={placeLoading}
                        loading={cameraLoading}
                        allowConfigure={configureActions}
                        aiTypes={aiTypes}
                        heatmapLoading={heatmapLoading}
                        heatmapError={heatmapError}
                        heatmapList={heatmapData}
                        handleImageModalClick={handleImageModalClick}
                        handleAutoPlaceSearch={handleAutoPlaceSearch}
                    />
                </>
            )
        },
        {
            label: t('custom.label'),
            children: (
                <>
                    <CustomAnalytics
                        t={t}
                        handleCreateURLClick={handleCreateURLClick}
                        data={data}
                        allowConfigure={configureActions}
                    />
                </>
            )
        },
        {
            label: "Schedule Report",
            children: (
                <>
                    <ScheduleReportAnalytics
                        t={t}
                        data={data}
                        notiTemplateList={notiTemplateList}
                    />
                </>
            )
        }
    ];
    return (
        <div>
            {/* <CustomTabs
                activeIndex={activeIndex}
                tabs={configTabs}
            /> */}
            {selectedPath === "Analytics" && <> {configTabs[subActiveIndex].children}</>}
        </div>
    );
};

EdgeAIAnalyticsPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default EdgeAIAnalyticsPanel;