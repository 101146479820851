import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { VERIFY_RESET_PASSWORD_LINK } from '../Profile/queries';
import { RESET_PASSWORD } from './mutations';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import SecondaryLayout from './components/SecondaryLayout';
import { colors } from '../../theme/colors';
import Message from '../../components/Message';
import { REQUIRED_ERROR } from '../../utils/constants';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

function ButtonComponent(props) {
    const { t, onClick, disabled, loading } = props;
    return (
        <Button 
        variant={'contained'}
        size={'large'}
        style={{ ...marginGenerator('mt-24') }}
        color={'primary'} 
        onClick={onClick} disabled={disabled || loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('resetPassword.label')}
        </Button>
    );
}

function ResetPassword({ t }) {
    
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [ verifyKey, setVerifyKey ] = useState(null);
    const [ id, setId ] = useState(null);
    const [ verified, setVerified ] = useState(false);
    const [firstCall, setFirstCall] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const splitArr = location.search.split('?');        
        if(splitArr.length && splitArr[1].split('&').length && splitArr[1].split('&')[0].split('=').length && splitArr[1].split('&')[0].split('=')[0] == 'id' && splitArr[1].split('&')[0].split('=')[1]  && splitArr[1].split('&')[1].split('=')[0] == 'verify' && splitArr[1].split('&')[1].split('=')[1] ){
            setId(splitArr[1].split('&')[0].split('=')[1]);
            setVerifyKey(splitArr[1].split('&')[1].split('=')[1]);
        }else{
            Message.error('Invalid Request!');
            window.location.replace('/');
        }
    }, [location]);

    /*
     VERIFY RESET PASSWORD LINK
    */
    const [verifyResetPasswordLink] = useLazyQuery(VERIFY_RESET_PASSWORD_LINK, {
        onError: error => {
            console.log('error', error);
        },
        onCompleted: data => {
            let res = data.verifyResetPasswordLink;
            if(!res.success){
                let msg = res.msg === 'link_expired' ? 'Reset password link expired. Please try again' : 'Invalid Request!';
                Message.error(msg);
                window.location.replace('/');
            }else{
                setVerified(true);
            }
        }
    });

    /* 
    Reset Password
    */
   const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
        if(data.resetPassword && data.resetPassword.success){
            Message.success('Your password successfully updated. Now you can login with new password.');
            window.location.replace('/');
        }else{
            let msg = data.resetPassword.msg === 'link_expired' ? 'Reset password link expired. Please try again' : 'Invalid Request!';
            Message.error(msg);
            window.location.replace('/');
        }
    }
  });

    if(id && verifyKey && !firstCall){
        setFirstCall(true);
        verifyResetPasswordLink({
            variables: {
                id,
                token:verifyKey
            }
        });
    }

    return (
        <SecondaryLayout
        scope={'ResetPassword'}
        contentStyle={{ maxWidth: '328px', ...marginGenerator('mt-162') }}>
        <Header
            title={t('resetPassword.label')}
            titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
            subText={t('resetPassSub.label')}
            subtitleVariant={'h2'}
            subtitleStyleOverride={{ color: colors.grey[400], padding: 0, fontSize:'14px' }}
        />
        <Grid container style={{ ...marginGenerator('mt-24') }}>
            <Input
            value={password}
            type={showPassword ? 'text' : 'password'}
            placeholder={`${t('newPassword.label')} *`}
            style={{
                width: '100%',
                ...paddingGenerator('pr-12')
            }}
            color={'secondary'}
            onChange={evt => setPassword(evt.target.value)}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    style={{
                    ...paddingGenerator('pt-8'),
                    ...paddingGenerator('pr-4')
                    }}
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </InputAdornment>
            }
            />
            <Input
            value={confirmPassword}
            type={showConfPassword ? 'text' : 'password'}
            placeholder={`${t('reNewPassword.label')} *`}
            style={{
                width: '100%',
                ...marginGenerator('mt-24'),
                ...paddingGenerator('pr-12')
            }}
            color={'secondary'}
            onChange={evt => setConfirmPassword(evt.target.value)}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    style={{
                    ...paddingGenerator('pt-8'),
                    ...paddingGenerator('pr-4')
                    }}
                    onClick={() => setShowConfPassword(!showConfPassword)}>
                    {showConfPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </InputAdornment>
            }
            />
            <ButtonComponent 
            t={t} 
            onClick={() => {
                if(!verified) return null;
                if (!password || !confirmPassword) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                }
                if(password !== confirmPassword){
                    Message.error("Password and confirm password must be same.");
                    return null;
                }
                setLoading(true);
                return resetPassword({
                    variables: {
                        id,
                        password,
                        token: verifyKey
                    }
                })
            }}
            disabled={!verified}
            loading={loading} />
        </Grid>
        </SecondaryLayout>
    );
}

export default withTranslation()(ResetPassword);
