import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '400px'
  },
  container: {
    padding: '10px'
  }
}));

export default function CustomTable({ data, total, column, loading }) {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            padding: 20,
            textAlign: 'center',
            display: 'inline-block'
          }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {column &&
                  column.map(column => (
                    <TableCell>{column.name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {data &&
                data.map(row => (
                  <StyledTableRow>
                    <TableCell>{row.summary}</TableCell>
                    <TableCell>{row.nrc}</TableCell>
                    <TableCell>{row.mrc}</TableCell>
                  </StyledTableRow>
                ))} */}
              {data && data.completed_projects ?
                <StyledTableRow>
                  <TableCell>Completed</TableCell>
                  <TableCell>{data.completed_projects.total_nrc.total_nrc__sum ? data.completed_projects.total_nrc.total_nrc__sum : '0'}</TableCell>
                  <TableCell>{data.completed_projects.total_mrc.total_mrc__sum ? data.completed_projects.total_mrc.total_mrc__sum : '0'}</TableCell>
                </StyledTableRow>
                : <StyledTableRow>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                </StyledTableRow>}
              {data && data.project_30_days ?
                <StyledTableRow>
                  <TableCell>1 - 30 days</TableCell>
                  <TableCell>{data.project_30_days.total_nrc.total_nrc__sum ? data.project_30_days.total_nrc.total_nrc__sum : '0'}</TableCell>
                  <TableCell>{data.project_30_days.total_mrc.total_mrc__sum ? data.project_30_days.total_mrc.total_mrc__sum : '0'}</TableCell>
                </StyledTableRow>
                : <StyledTableRow>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                </StyledTableRow>}
              {data && data.project_60_days ?
                <StyledTableRow>
                  <TableCell>31 - 60 days</TableCell>
                  <TableCell>{data.project_60_days.total_nrc.total_nrc__sum ? data.project_60_days.total_nrc.total_nrc__sum : '0'}</TableCell>
                  <TableCell>{data.project_60_days.total_mrc.total_mrc__sum ? data.project_60_days.total_mrc.total_mrc__sum : '0'}</TableCell>
                </StyledTableRow>
                : <StyledTableRow>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                </StyledTableRow>}
              {data && data.project_90_days ?
                <StyledTableRow>
                  <TableCell>61 - 90 days</TableCell>
                  <TableCell>{data.project_90_days.total_nrc.total_nrc__sum ? data.project_90_days.total_nrc.total_nrc__sum : '0'}</TableCell>
                  <TableCell>{data.project_90_days.total_mrc.total_mrc__sum ? data.project_90_days.total_mrc.total_mrc__sum : '0'}</TableCell>
                </StyledTableRow>
                : <StyledTableRow>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                  <TableCell>NA</TableCell>
                </StyledTableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}