import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';


const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  },
  root: {
      flexGrow: 1,
  },
  input: {
      minWidth: '224px'
  },
  iconButton: {
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(1.25, 3),
      borderRadius: '24px',
      marginTop: theme.spacing(2),
      border: "1px solid #b8b8b8"
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
      <Button
          color="secondary"
          variant={'contained'}
          onClick={onClick} disabled={loading}>
          {loading && <CircularProgress size={14} />}
          {!loading && t('submit.label')}
      </Button>
  );
}

const urInitFields = { 
    id:"",
    fullName: "",
    department: "", 
    designation: "",
    section : "",
    officeNumber: "",
    cellPhone: "",
    modal: "",
    laptopColour: "",
    series: "",
    serialNumber: "",
    issueDate: null,
    computerName: "",
    termsAndCond:"",
    userSign: "",
    userDateSubmitted: null,
    imangmentName: "",
    imangmentSign: "",
    imangmentDate: null
};

const LaptopIssue = ({
  t,
  open,
  handleClose,
  handleCreateRequestSubmit,
  handleOpenSignPanModal,
  data,
  departments,
  users
}) => {

  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const [departmentSections, setDepartmentSections] = useState([]);

  React.useEffect(() => {
    if (!open) {
        setURFormState({ ...urInitFields });
        handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        const department = data.department && data.department
        const section = data.section && data.section
        if(data.section){
            setDepartmentSections([data.section]);
        }
        setURFormState({...data, department, section, id });
    }else{
        // setShowUserSelect(true);
        // setShowLearnDevelopment(true);
        // setShowHOD(true);
        // setShowHRManager(true);
        // setShowGeneralManager(true);
    }
}, [data]);
const handleChange = (value, name, key) => {
        setURFormState({ ...urFormState, [name]: value });
}

  return (
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose();
    }}>
    <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
        <HrHeader />
    </DialogTitle>
    <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Laptop Isusue Form</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >User Details</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.fullName}
                            onChange={(evt) => handleChange(evt.target.value, "fullName")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedSelect
                            options={(departments && departments.length) ? departments : []}
                            label="Department"
                            selectStyle={{ ...paddingGenerator('p-4') }}
                            val={urFormState.department}
                            handleChange={(evt) => {
                                const item = departments.find(itm => evt.target.value === itm.id);
                                setDepartmentSections(item ? item.sections : []);
                                handleChange(evt.target.value, "department");
                            }}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Designation"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.designation}
                            onChange={(evt) => handleChange(evt.target.value, "designation")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                         <OutlinedSelect
                            options={(departmentSections && departmentSections.length) ? departmentSections : []}
                            label="Section"
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                            val={urFormState.section}
                            selectStyle={{ ...paddingGenerator('p-4') }}
                            handleChange={(evt) => {
                                handleChange(evt.target.value, "section");
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Office Number (Ext)"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.officeNumber}
                            onChange={(evt) => handleChange(evt.target.value, "officeNumber")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Cell phone"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.cellPhone}
                            onChange={(evt) => handleChange(evt.target.value, "cellPhone")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Laptop Details</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Model"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.modal}
                            onChange={(evt) => handleChange(evt.target.value, "modal")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Laptop Colour"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.laptopColour}
                            onChange={(evt) => handleChange(evt.target.value, "laptopColour")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Series"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.series}
                            onChange={(evt) => handleChange(evt.target.value, "series")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Serial Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.serialNumber}
                            onChange={(evt) => handleChange(evt.target.value, "serialNumber")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Issue date'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.issueDate}
                            handleChange={newDate => handleChange(newDate, "issueDate")}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Computer Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.computerName}
                            onChange={(evt) => handleChange(evt.target.value, "computerName")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >User Declaration</Typography>
                        <RadioGroup
                            row
                            aria-label="approve_rejected"
                            name="approve_rejected"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `termsAndCond`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Approved"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.termsAndCond === 'Approved' ? true : false}
                                labelPlacement="start"
                                label=""
                            />
                        </RadioGroup>
                        <Typography style={{ display: 'inline' }} variant="body1">
                            I agree to make use of IT systems in accordance with the Morupule
                            Coal Mine (MCM) IT User Policy. To review your obligation on this
                            policy please refer to this document. MCM IT User policy on the
                            File Server or ask any IT personnel to assist. To ensure that the
                            system is not unlawful, unauthorized or improper purpose and
                            comply with the policy, MCM may monitor, record, inspect and/or
                            disclose any use of the system and/or files contained upon it as
                            necessary. Any such use or non-compliance could result in
                            disciplinary action and/or civil criminal penalties being taken
                            against you.
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("userSign")}
                        >
                           <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.userDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "userDateSubmitted")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Informortion mangment official</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <Input
                            type={'text'}
                            placeholder={"name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.imangmentName}
                            onChange={(evt) => handleChange(evt.target.value, "imangmentName")}
                        /> */}
                        <OutlinedSelect
                            options={(users && users.length) ? users : []}
                            label="User"
                            //disabled={(data && data.recommendationSign) ? true : false}
                            val={urFormState.imangmentName}
                            handleChange={(evt) => handleChange(evt.target.value, "imangmentName")}
                            styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                        />
                    </Grid>
                    <Grid xs={1} />
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("imangmentSign")}
                        >
                           <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.imangmentDate}
                            handleChange={newDate => handleChange(newDate, "imangmentDate")}
                    />
                    </Grid>
                </Grid>
    </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => { handleCreateRequestSubmit(urFormState)}}
                        loading={false}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
  )
}


LaptopIssue.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateRequestSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(LaptopIssue);
