import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/inq_logo_primary.png';

const styles = StyleSheet.create({
    container: {
        // marginRight: 1,
        marginBottom: 2,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        height: 90,
        fontSize: 8,
    },
    leftBox: {
        padding: "7px",
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
    },
    // leftBox: {
    //     flexDirection: 'column',
    //     width: 360,
    //     border: 'black 5px solid',
    //     borderTop: '1px solid black',
    //     paddingTop: 30,
    //     '@media max-width: 400': {
    //         width: '100%',
    //         paddingRight: 0,
    //     },
    //     '@media orientation: landscape': {
    //         width: 200,
    //     },
    // },
    rightContainer: {
        flexDirection: 'column',
        paddingTop: 30,
        paddingLeft: 0,
        paddingBottom: 0,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    logo: {
        width: '100%',
        height: '100%'
    },
    rightColumn: {
        flexDirection: 'column',
        width: '20%',
        marginLeft: 5,
        marginBottom: 5
    },
    floatRight: {
        // textAlign: 'right'
    },
    HeadingRed: {
        fontSize: 12,
        fontStyle: 'bold',
        color: '#661723',
        textAlign: 'center'
    },
    centerHeading: {
        fontSize: 11,
        fontStyle: 'bold',
    },
    leftColumn: {
        flexDirection: 'column',
        width: '30%',
        marginRight: 2
    },
    centerColumn: {
        width: '50%',
        padding: "7px",
        flexDirection: 'column',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
        marginRight: 3,
        
    }
});
const InvoiceHeading = ({ invoiceCompanyDetails }) => (
    <Fragment>
        <View style={styles.container}>
            <View style={styles.leftColumn}>
                <View style={styles.leftBox}>
                    <Text>Date :   {invoiceCompanyDetails.ACCDAT}           Page 1 of 1</Text>
                </View>
                <View style={styles.leftBox}>
                    <View style={styles.HeadingRed}>
                        <Text>Sales Invoice</Text>
                    </View>
                </View>
                <View style={styles.leftBox}>
                    <Text>Invoice Number:          {invoiceCompanyDetails.NUM}</Text>
                </View>
            </View>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>inq. Digital Nigeria Limited</Text>
                <Text>3A Aja Nwachukwu Close, Ikoyi, Nigeria</Text>
                <Text>Switchboard:  +234 1 700 7000                    VAT Reg. Number:  VIV/10002285450</Text>
                <Text>CSOC:  +234 1 700 7000                             Co. Reg. Number:  RC285450</Text>
                <Text>TIN:  03794627-0001</Text>
            </View>
            <View style={styles.rightColumn}>
                <Image
                    src={logo}
                    style={styles.logo}
                />
            </View>
        </View>
        {/* <View style={styles.container}>
            <View style={styles.detailColumn}>
                <Text style={styles.name}>Luke Skywalker</Text>
                <Text style={styles.subtitle}>Jedi Master</Text>
            </View>
            <View style={styles.linkColumn}>
                <Link href="mailto:luke@theforce.com" style={styles.link}>
                    luke@theforce.com
                </Link>
            </View>
        </View> */}
    </Fragment>
);
export default InvoiceHeading