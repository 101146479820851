import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    bottom: 0,
    height: '100px',
    background: '#3A3A3A',
    position: 'fixed',
    width: '100%',
    marginTop:'130px'
  }
}));

function Footer({ t }) {
  const classes = useStyles();

  return (
    <>
    <Grid style={{height:'100px'}}></Grid>

    <Grid
        item
        xs
        container
        direction="column"
        spacing={0}
        justify="center"
        alignItems={'center'}
        className={classes.root}>
        <Grid item>
            <Typography variant="body2" style={{ color: 'white' }}>
            {'\u00A9'} {t('copyrightInq.label')} {moment().format('YYYY')}. All rights reserved
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body2" style={{ color: 'white' }}>
                inq. Head Office: 1 Cyber City 6th Floor, Tower A, Ebene, Mauritius.
                </Typography>
            </Grid>
    </Grid>
    </>
  );
}

export default withTranslation()(Footer);
