import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { marginGenerator } from '../../../../theme/utils';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../../../commonQueries';
import CircularProgress from '@material-ui/core/CircularProgress';

function CreateOrgModal({
    t,
    open,
    handleClose,
    handleSubmitCreateOrg,
    modelState
}) {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [region, setRegion] = useState('');
    const [country, setCountry] = useState('');
    const [zipcode, setZipcode] = useState('');

    const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
    const { data: countryList } = useQuery(GET_COUNTRY_LIST);

    React.useEffect(() => {
        if(modelState.clear){
            setAddress("");
            setRegion("");
            setCountry("");
            setZipcode("");
        }
    }, [modelState]);
   
    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="verify-email-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}
            disableBackdropClick
            disableEscapeKeyDown
            >
            <DialogTitle id="confirmation-dialog-title">{t('organizationDetails.label')}</DialogTitle>
            <DialogContent dividers>
                <Grid style={{ ...marginGenerator('mt-24') }}>
                    <Input
                    value={name}
                    type={'text'}
                    placeholder={`${t('orgName.label')} *`}
                    style={{ width: '100%' }}
                    color={'secondary'}
                    onChange={evt => setName(evt.target.value)}
                    />
                    <Input
                    value={address}
                    type={'text'}
                    placeholder={`${t('address.label')} *`}
                    style={{ width: '100%', ...marginGenerator('mt-24') }}
                    color={'secondary'}
                    onChange={evt => setAddress(evt.target.value)}
                    />
                    <Grid
                    container
                    justify={'space-between'}
                    item
                    style={marginGenerator('mt-24')}>
                    <OutlinedSelect
                        val={country}
                        label={`${t('country.label')} *`}
                        handleChange={evt => {
                        getRegionList({ variables: { countryId: evt.target.value } });
                        setRegion('');
                        setCountry(evt.target.value);
                        }}
                        styleOverrides={{ width: 180, marginRight: 0 }}
                        options={countryList ? countryList.getCountries : []}
                    />
                    <OutlinedSelect
                        val={region}
                        disabled={!country}
                        label={`${t('region.label')} *`}
                        handleChange={evt => {
                        setRegion(evt.target.value);
                        }}
                        styleOverrides={{ width: 180, marginRight: 0 }}
                        options={regionList ? regionList.getRegionsByCountryId : []}
                    />
                    </Grid>
                    <Grid
                    container
                    justify={'space-between'}
                    item
                    style={marginGenerator('mt-24')}>
                    <Input
                        value={zipcode}
                        style={{ width: '100%' }}
                        type={'text'}
                        placeholder={`${t('zipCode.label')} *`}
                        color={'secondary'}
                        onChange={evt => setZipcode(evt.target.value)}
                    />
                    </Grid>
                </Grid>   
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <Button
                    variant={'contained'}
                    size={'large'}
                    style={{ ...marginGenerator('mt-24') }}
                    disabled={modelState.loading}
                    color={'primary'}
                    onClick={() => {
                        if (!name || !address || !region || !country || !zipcode) {
                            Message.error(REQUIRED_ERROR);
                            return null;
                        }
                        handleSubmitCreateOrg({
                            variables: {
                                name: name,
                                address: address,
                                region: region,
                                country: country,
                                zipcode: zipcode
                            }
                        });
                    }}>
                    {modelState.loading ? <CircularProgress color="secondary"/>  : t('saveAndContinue.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateOrgModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitCreateOrg: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CreateOrgModal);
