import React, {useState, useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import PlacesMap from '../../../../../../NewSubscription/components/PlacesMap';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
const LocationForm = ({
    t,
    open,
    onClose,
    handleSubmitOfOcrPlace,
    ocrLocationSubmit
}) => {

    const [placeName, setPlaceName] = useState('');
    const [locationState, setLocationState] = useState({latitude:'', longitude:''});
    const [placeDescription, setPlaceDescription] = useState('');
    const classes = useStyles();
    
    const handleSetPositionBase = pos => {
        setLocationState({ latitude: pos.latitude, longitude: pos.longitude });
    };

    useEffect(() => {
            setPlaceName('');
            setLocationState({latitude:'',longitude:''});
            setPlaceDescription('');
    }, [!open]);

  return (
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    aria-labelledby="place-template-modal"
    aria-describedby="place-template-modal"
    open={open}
    onClose={onClose}>
    <DialogTitle
        id="place-template-title">{t('createNew.label')} {t('places.label')}</DialogTitle>
    <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}
                style={{
                    ...paddingGenerator('pb-30')
                }}>
                <TextField
                  value={placeName}
                  id="outlined-search" 
                  label={`${t('places.label')} ${t('name.label')}`} 
                  type="text"
                  style={{ width: '100%'}}
                  onChange={(e) => setPlaceName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}
                style={{
                    ...paddingGenerator('pb-30')
                }}>
                <TextField
                value={placeDescription}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={3}
                style={{ width: '100%'}}
                onChange={(e) => setPlaceDescription(e.target.value)}
              />
            </Grid>
            <Grid
                item
                xs={12}
                container
                style={marginGenerator('mt-20')}
                className={classes.mapContainer}>
                <PlacesMap
                    id={'place-map'}
                    handleSetPosition={handleSetPositionBase}
                    position={
                        locationState &&
                        locationState['latitude'] &&
                        locationState['longitude']
                        ? {
                            lat: parseFloat(locationState['latitude']),
                            lng: parseFloat(locationState['longitude'])
                            }
                        : null
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <Input
                    value={locationState['latitude']}
                    type={'text'}
                    placeholder={`${t('latitude.label')}*`}
                    style={{width: '100%'}}
                    className={classes.input}
                    color={'secondary'}
                    inputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Input
                    value={locationState['longitude']}
                    type={'text'}
                    placeholder={`${t('longitude.label')}*`}
                    style={{width: '100%'}}
                    className={classes.input}
                    color={'secondary'}
                    inputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
        </Grid>
    </DialogContent>
    <DialogActions>
        <Grid container justify={'space-between'}>
            <Button
                variant={'outlined'}
                onClick={onClose}
            >
                {t('cancel.label')}
            </Button>
            <ButtonComponent 
                t={t} 
                onClick={() => {
                    let postData = {name:placeName, placeDescription, location:locationState};
                    handleSubmitOfOcrPlace(postData);
                }}
                loading={ocrLocationSubmit}
             />
        </Grid>
    </DialogActions>
</Dialog>
  );
};

export default LocationForm;
