import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../../theme/commonStyles';
import EmailIcon from '../../../../assets/icons/email.png';
import { colors } from '../../../../theme/colors';

const useStyles = makeStyles({
  root: {
    maxWidth: 320,
    borderRadius: 4
  },

  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

export default function SupportCard(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();

  return (
    <Card
      className={classes.root}
      variant="outlined"
      style={{ backgroundColor: props.color }}>
      <CardContent style={{ padding: '26px 0 26px 34px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={EmailIcon}
            // src={props.icon}
            className={commonClasses.iconMedium}
            alt="Icon"
          />
          <Typography variant={'h5'} style={{ marginLeft: 14 }}>
            {props.title}
          </Typography>
        </div>

        <div style={{ marginTop: 15 }}>
          <Typography variant={'h5'}>{props.heading}</Typography>
        </div>

        <Typography
          variant={'subtitle2'}
          style={{ color: colors.common.white }}>
          {props.subtext}
        </Typography>
      </CardContent>
    </Card>
  );
}
