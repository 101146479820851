import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import {makeStyles} from '@material-ui/core/styles';
import PlacesMap from '../../../NewSubscription/components/PlacesMap';
import {GET_ALL_PLACES, GET_CONTROLLERS_LIST, GET_UCPE_LIST, GET_VM_IMAGES_LIST} from '../../queries';
import {useQuery} from '@apollo/react-hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Image from "../../../../assets/icons/image.png";
import Close from "@material-ui/icons/Close";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    input: {
        minWidth: '224px'
    }
}));
const scriptTypes = [
    {
        id: 'once-before-startup',
        name: 'Once Before Startup'
    },
    {
        id: 'always-before-startup',
        name: 'Always Before Startup'
    }, {
        id: 'one-after-startup',
        name: 'One After Startup'
    },
    {
        id: 'always-after-startup',
        name: 'Always After Startup'
    }
];
function CreateScriptModal({t, data, open, handleCreateScript, handleClose, modalState}) {
    let controllers = [];
    let ucpes = [];
    const organization_id = data.organizationId
    const {
        loading: loadingControllers,
        data: controllersData,
        error: controllersError
    } = useQuery(GET_CONTROLLERS_LIST, {
        variables: {
            organization_id
        }
    });
    const {loading: loadingUcpes, data: ucpeData, error: ucpeError} = useQuery(GET_UCPE_LIST, {
        variables: {
            organization_id,
            search: ''
        }
    });
    const [controllerId, setControllerId] = useState('');
    const [scriptType, setScriptType] = useState('');
    const [deviceID, setDeviceID] = useState([]);
    const classes = useStyles();
    const [isUpdateState, setIsUpdateState] = useState(false);
    const [scriptFile, setScriptFile] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const inputFile = React.createRef();
    const handleFileUploadClick = () => {
        inputFile.current.click();
    };
    const convertToBase64 = (img) => {
        setFileName(img && img.name)
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            setScriptFile(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setControllerId('');
            setScriptFile('');
            setDeviceID([]);
            setScriptFile('');
            handleClose();
        }
    }, [modalState]);
    if (!loadingControllers && !controllersError) {
        controllers =
            controllersData.getControllers && controllersData.getControllers.length
                ? controllersData.getControllers.filter(
                controller => controller.controller_status === 'success'
                )
                : [];
    }
    if (!loadingUcpes && !ucpeError) {
        ucpes =
            ucpeData.getUcpes && ucpeData.getUcpes.length
                ? ucpeData.getUcpes.filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
                : [];
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="script-modal"
            aria-describedby="script-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {isUpdateState === true ? t('edit.label') : t('createNew.label')}
            </DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        <Grid
                            container spacing={3}
                            style={{
                                ...marginGenerator('mt-8')
                            }}>
                            <Grid item xs={6}>
                                <OutlinedSelect
                                    val={controllerId}
                                    label={`${t('controllerId.label')} *`}
                                    handleChange={evt => {
                                        setControllerId(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        controllers.length > 0
                                            ? controllers.map(controller => {
                                                return {
                                                    id: controller.controller_id,
                                                    name: controller.controller_name
                                                };
                                            })
                                            : []
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <OutlinedSelect
                                    val={deviceID}
                                    label={`${t('uCPE.label')} *`}
                                    handleChange={evt =>
                                        setDeviceID(evt.target.value)
                                    }
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={
                                        ucpes.length > 0
                                            ? ucpes.map(ucpe => {
                                                return {id: ucpe.device_id, name: ucpe.device_name};
                                            })
                                            : []
                                    }
                                    multiple={true}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={handleFileUploadClick}
                                >
                                    {`${t('scriptFile.label')}`}
                                </Button>
                                {uploadedFile && (
                                    <Chip
                                        avatar={<img src={Image} alt={'file'}/>}
                                        label={
                                            uploadedFile.name.length > 12
                                                ? `${uploadedFile.name.slice(0, 12)}...`
                                                : uploadedFile.name
                                        }
                                        onDelete={() => {
                                            setUploadedFile('');
                                            setScriptFile('');
                                        }}
                                        deleteIcon={<Close/>}
                                        variant="outlined"
                                    />
                                )}
                                <input
                                    type="file"
                                    id="attachment"
                                    ref={inputFile}
                                    onChange={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        setUploadedFile(event.target.files[0]);
                                        convertToBase64(event.target.files[0]);
                                    }}
                                    style={{display: 'none'}}
                                />
                            </Grid>
                            <Grid item xs={6} style={marginGenerator('mt-24')}>
                                <OutlinedSelect
                                    val={scriptType}
                                    label={`${t('scriptType.label')} *`}
                                    handleChange={evt => {
                                        setScriptType(evt.target.value);
                                    }}
                                    selectStyle={{...paddingGenerator('p-8')}}
                                    styleOverrides={{width: '100%', marginRight: 0}}
                                    options={scriptTypes ? scriptTypes : []}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateScript({
                                scriptType: scriptType,
                                controller_id: controllerId,
                                device_id: deviceID,
                                scriptFile: scriptFile,
                                file_name: fileName,
                                created_by: "Anil",
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateScriptModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateScriptModal);
