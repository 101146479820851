import React, {useCallback, useEffect, useState} from 'react';
import CustomTabs from '../../../../components/CustomTabs';
import {useLocation} from 'react-router';
import PropTypes from 'prop-types';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {
    GET_ALL_CONTROLLERS,
    GET_ALL_NOTIFICATION,
    GET_ALL_PLACES,
    GET_ALL_SCRIPT,
    GET_ALL_SFC,
    GET_ALL_UCPES, GET_ALL_UCPES_PAGINATION,
    GET_ALL_VM_IMAGES,
    GET_ALL_VNF,
    GET_ALL_VNF_TEMPLATES
} from '../../queries';
import {
    DELETE_SDN_NOTIFICATION
} from '../../mutations';
import ControllerPanel from '../ControllerPanel';
import UcpePanel from '../UcpePanel';
import ImagesPanel from '../ImagesPanel';
import VNFTemplatePanel from '../VNFTemplatePanel';
import VNFPanel from '../VNFPanel';
import SDNNotificationPanel from '../SDNNotificationPanel';
import SDNPlacePanel from '../SDNPlacePanel';
import Message from "../../../../components/Message";
import SFCPanel from "../SFCPanel";
import ScriptPanel from "../ScriptPanel";
import {debounce} from "@material-ui/core";

const SDNConfigurationPanel = ({
                                   t,
                                   data,
                                   handleDeleteConfirmAction,
                                   handleUpdateAction,
                                   handleCreateButtonClick,
                                   reloadData
                               }) => {
    const menuItems = [{label: t('edit.label')}, {label: t('delete.label')}];
    const role = localStorage.getItem('role');
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [ucpe, setUcpe] = useState('');
    const [ucpeStatus, setUcpeStatus] = useState('');
    const [value, setValue] = useState('');
    const [imageStatus, setImageStatusValue] = useState('');
    const [vnfTemplate, setVnfTemplate] = useState('');
    const [vnfStatus, setVnfStatus] = useState('');
    const [filterLoading, setFilterLoading] = useState(false);
    const organization_id = data.organizationId;
    const [apiTimer, setApiTimer] = useState(0);
    const [ucpeError, setUcpeError] = useState(null);
    const [vnfError, setVnfError] = useState(null);
    const [allUcpeLoading, setUcpeLoading] = useState(true);
    const [ucpeList, setUcpeData] = useState([]);
    const [vnfList, setVnfData] = useState([]);
    const [ucpePage, setUcpePage] = useState(1);
    const [ucpeReloadPage, setReloadUcpePageList] = useState(false);
    const [vnfReloadPage, setReloadVnfPageList] = useState(false);
    useEffect(() => {
        if (location.state) setActiveIndex(location.state.activeIndex);
    }, [location]);
    const {
        loading: allControllersLoading,
        data: controllersList,
        error: controllersError,
        refetch: reloadControllers
    } = useQuery(GET_ALL_CONTROLLERS, {
        variables: {
            organization_id
        }
    });
    const {
        loading: loadingAllUcpes,
        data: ucpesList,
        error: ucpesError,
        refetch: reloadUcpes
    } = useQuery(GET_ALL_UCPES, {
        variables: {
            organization_id,
            search: value
        }
    });
    useEffect(() => {
        setUcpeLoading(true);
        getUcpeList({
            variables: {
                page: ucpePage,
                organization_id,
                search: value,
                status: ucpeStatus
            }
        })
        setFilterLoading(true);
        getVnfListByFilter({
            variables: {
                organization_id,
            }
        })
    }, []);
    const [getUcpeList] = useLazyQuery(GET_ALL_UCPES_PAGINATION, {
        fetchPolicy: 'no-cache',
        onError: error => {
            setUcpeLoading(false);
            setUcpeError(error);
            console.log('Error', error);
        },
        onCompleted: data => {
            setUcpeLoading(false);
            if (data && data.getUcpesPagination) {
                setUcpeData(data);
            }
        }
    });
    const {
        loading: loadingAllSFC,
        data: SFCList,
        error: SFCError,
        refetch: reloadSFC
    } = useQuery(GET_ALL_SFC, {
        variables: {
            organization_id
        }
    });
    const {
        loading: loadingAllScript,
        data: ScriptList,
        error: ScriptError,
        refetch: reloadScript
    } = useQuery(GET_ALL_SCRIPT, {
        variables: {
            organization_id
        }
    });
    const {
        loading: allVmImagesLoading,
        data: vmImagesList,
        error: vmImagesError,
        refetch: reloadVmImages
    } = useQuery(GET_ALL_VM_IMAGES, {
        variables: {
            organization_id,
            status: imageStatus
        }
    });
    const {
        loading: loadingAllVnfTemplates,
        data: vnfTemplatesList,
        error: vnfTemplateError,
        refetch: reloadVnfTemplate
    } = useQuery(GET_ALL_VNF_TEMPLATES, {
        variables: {
            organization_id
        }
    });
    /*  const {
          loading: loadingAllVnfs,
          data: vnfsList,
          error: vnfError,
          refetch: reloadVnfs
      } = useQuery(GET_ALL_VNF, {
          variables: {
              organization_id
          }
      });*/
    const [getVnfListByFilter] = useLazyQuery(GET_ALL_VNF, {
        fetchPolicy: 'no-cache',
        onError: error => {
            setFilterLoading(false);
            setVnfError(error)
            console.log('Error', error);
        },
        onCompleted: Vnfdata => {
            setVnfData(Vnfdata);
            setFilterLoading(false);
        }
    });
    const {
        loading: loadingAllPlaces,
        data: placesList,
        error: placesError,
        refetch: reloadPlaces
    } = useQuery(GET_ALL_PLACES, {
        variables: {
            organization_id
        }
    });
    const {
        loading: loadingAllNotification,
        data: notificationList,
        error: notificationError,
        refetch: reloadNotification
    } = useQuery(GET_ALL_NOTIFICATION, {
        variables: {
            organization_id
        }
    });
    const vnfFilter = [
        {
            label: 'Filter By uCPE',
            handleChange: evt => {
                setUcpe(evt.target.value);
                setFilterLoading(true);
                const device_id = (evt.target.value) ? evt.target.value : '';
                let filterBy = {
                    variables: {
                        organization_id: organization_id,
                        vnf_template_id: vnfTemplate,
                    }
                }
                if (device_id) {
                    filterBy.variables.device_id = device_id;
                }
                getVnfListByFilter(filterBy);
            },
            val: ucpe,
            styleOverrides: {minWidth: '172px'},
            options:
                !loadingAllUcpes && !ucpesError && ucpesList.getUcpes.length > 0
                    ? ucpesList.getUcpes
                        .filter(ucpe => (ucpe.device_status === 'success') || (ucpe.device_status === 'active'))
                        .map(ucpe => {
                            return {id: ucpe.device_id, name: ucpe.device_name};
                        })
                    : []
        },
        {
            label: 'Filter By VNF Template',
            handleChange: evt => {
                setFilterLoading(true);
                const template = evt.target.value;
                setVnfTemplate(template);
                const vnf_template_id = (evt.target.value) ? evt.target.value : '';
                let filterBy = {
                    variables: {
                        organization_id: organization_id,
                        device_id: ucpe
                    }
                }
                if (vnf_template_id) {
                    filterBy.variables.vnf_template_id = vnf_template_id;
                }
                getVnfListByFilter(filterBy);
            },
            val: vnfTemplate,
            styleOverrides: {minWidth: '172px'},
            options:
                !loadingAllVnfTemplates &&
                !vnfTemplateError &&
                vnfTemplatesList.getVnfTemplates.length > 0
                    ? vnfTemplatesList.getVnfTemplates
                        .filter(template => template.vnfT_status === 'success')
                        .map(template => {
                            return {
                                id: template.vnf_template_id,
                                name: template.vnfT_name
                            };
                        })
                    : []
        },
        {
            label: 'Filter By VNF Status',
            handleChange: evt => {
                setFilterLoading(true);
                const template = evt.target.value;
                setVnfStatus(template);
                const status = (evt.target.value) ? evt.target.value : '';
                let filterBy = {
                    variables: {
                        organization_id: organization_id
                    }
                }
                /*  if (vnfTemplate) {
                      filterBy.variables.vnf_template_id = vnfTemplate;
                  }*/
                if (status) {
                    filterBy.variables.status = status;
                }
                getVnfListByFilter(filterBy);
            },
            val: vnfStatus,
            styleOverrides: {minWidth: '172px'},
            options: [{id: 'success', name: 'Success'}, {id: 'down', name: 'Down'}, {id: 'pause', name: 'Pause'}]
        }
    ];
    const ucpeFilter = [
        {
            label: 'Filter By Status',
            handleChange: evt => {
                setUcpePage(1);
                setUcpeStatus(evt.target.value);
                setUcpeLoading(true);
                const status = (evt.target.value) ? evt.target.value : '';
                let filterBy = {
                    variables: {
                        page: 1,
                        organization_id,
                        search: value
                    }
                }
                if (status) {
                    filterBy.variables.status = status;
                }
                getUcpeList(filterBy)
            },
            val: ucpeStatus,
            styleOverrides: {minWidth: '172px'},
            options: [{id: 'active', name: 'Active'}, {id: 'down', name: 'Down'}]
        }
    ];
    if (reloadData && reloadData === 'controller') {
        reloadControllers();
    }
    if (reloadData && reloadData === 'ucpe') {
        reloadUcpes()
        // getUcpeList({
        //     variables: {
        //         page: ucpePage,
        //         organization_id,
        //         search: value
        //     }
        // })
    }
    if (reloadData && reloadData === 'sfc') {
        reloadSFC();
    }
    if (reloadData && reloadData === 'script') {
        reloadScript();
    }
    if (reloadData && reloadData === 'image') {
        reloadVmImages();
    }
    if (reloadData && reloadData === 'vnftemplate') {
        reloadVnfTemplate();
    }
    if (reloadData && reloadData === 'vnf') {
        getVnfListByFilter({
            variables: {
                organization_id,
            }
        })
        // reloadVnfs();
    }
    if (reloadData && reloadData === 'sdn_places') {
        reloadPlaces();
    }
    if (reloadData && reloadData === 'sdn_notifications') {
        reloadNotification();
    }
    React.useEffect(() => {
        getUcpeList({
            variables: {
                page: ucpePage,
                organization_id,
                search: value,
                status: ucpeStatus
            }
        })
    }, [ucpeReloadPage])
    React.useEffect(() => {
        getVnfListByFilter({
            variables: {
                organization_id,
                status: vnfStatus
            }
        })
    }, [vnfReloadPage])
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setApiTimer(apiTimer + 1);
            reloadControllers();
            setReloadUcpePageList(!ucpeReloadPage)
            setReloadVnfPageList(!vnfReloadPage)
            reloadVmImages();
            reloadVnfTemplate();
            reloadPlaces();
            reloadNotification();
        }, 30000);
        return () => {
            clearTimeout(timer);
        };
    }, [apiTimer]);
    const [deleteSDNNotification] = useMutation(DELETE_SDN_NOTIFICATION, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Notification deleted successfully');
            reloadNotification();
        }
    });
    /**
     * @description Create Debounced Search Functionality
     * @Author Anil Sharma
     * */
    const func = (value) => {
        setValue(value);
    }
    const [debouncedFunction] = useDebouncedCallback(func, 1000);
    function useDebouncedCallback(callback, delay) {
        const callbackfunc = useCallback(debounce(callback, delay), []);
        return [callbackfunc]
    }
    /**
     * @description Create Debounced Filter By Image Status Functionality
     * @Author Anil Sharma
     * */
    const statusFunc = (value) => {
        setImageStatusValue(value);
    }
    const [debouncedImageFilterFunction] = useDebouncedCallback(statusFunc, 1000);
    function useDebouncedCallback(callback, delay) {
        const callbackfunc = useCallback(debounce(callback, delay), []);
        return [callbackfunc]
    }
    /**
     *Handel Pagination
     *  */
    const handleChangeUcpePage = (event, pValue) => {
        setUcpeLoading(true);
        setUcpePage(pValue)
        getUcpeList({
            variables: {
                page: pValue,
                organization_id,
                search: value,
                status: ucpeStatus
            }
        })
    }
    const handleSearchUcpe = query => {
        if (query !== '') {
            debouncedFunction(query)
            if (value !== '') {
                getUcpeList({
                    variables: {
                        page: ucpePage,
                        organization_id,
                        search: value
                    }
                })
            }
        } else {
            getUcpeList({
                variables: {
                    page: ucpePage,
                    organization_id,
                    search: value
                }
            })
        }
    };
    const handleImageFilter = query => {
        if (query !== '') {
            debouncedImageFilterFunction(query)
            if (imageStatus !== '') {
                reloadVmImages()
            }
        } else {
            reloadVmImages()
        }
    };
    const handleMenuItemClick = (elem, id, type) => {
        if (elem.label === t('edit.label')) {
            handleUpdateAction({action: type, id: id});
        } else if (elem.label === t('delete.label')) {
            if (type === 'controller') {
                handleDeleteConfirmAction({action: type, id: id})
            } else if (type === 'ucpe') {
                handleDeleteConfirmAction({action: type, id: id})
                /* if (
                     window.confirm('Are you sure you want to delete this uCPE ?') ===
                     false
                 ) {
                     return false;
                 }
                 return deleteUcpe({
                     variables: {
                         device_id: id
                     }
                 });*/
            } else if (type === 'sfc') {
                handleDeleteConfirmAction({action: type, id: id})
            } else if (type === 'script') {
                handleDeleteConfirmAction({action: type, id: id})
            } else if (type === 'vmImage') {
                handleDeleteConfirmAction({action: type, id: id})
                /* if (
                     window.confirm('Are you sure you want to delete this image ?') ===
                     false
                 ) {
                     return false;
                 }
                 return deleteImage({
                     variables: {
                         image_id: id
                     }
                 });*/
            } else if (type === 'vnfTemplate') {
                handleDeleteConfirmAction({action: type, id: id})
                /* if (
                     window.confirm('Are you sure you want to delete this template ?') ===
                     false
                 ) {
                     return false;
                 }
                 return deleteVnfTemplate({
                     variables: {
                         vnf_template_id: id
                     }
                 });*/
            } else if (type === 'vnf') {
                handleDeleteConfirmAction({action: type, id: id})
                /* if (
                     window.confirm('Are you sure you want to delete this VNF ?') === false
                 ) {
                     return false;
                 }
                 return deleteVnf({
                     variables: {
                         vnf_id: id
                     }
                 });*/
            } else if (type === 'sdn_place') {
                handleDeleteConfirmAction({action: type, id: id})
                /* if (
                     window.confirm('Are you sure you want to delete this place ?') ===
                     false
                 ) {
                     return false;
                 }
                 console.log('place id ', id);
                 return deleteSDNPlace({
                     variables: {
                         id: id
                     }
                 });*/
            } else if (type === 'notification') {
                handleDeleteConfirmAction({action: type, id: id})
                /*if (
                    window.confirm('Are you sure you want to delete this notification ?') ===
                    false
                ) {
                    return false;
                }
                console.log('notification id ', id);
                return deleteSDNNotification({
                    variables: {
                        id: id
                    }
                });*/
            }
        } else if (elem.label === t('configure.label')) {
            console.log('id', id);
            handleUpdateAction({action: 'ucpe_configure', id: id});
        } else if (elem.label === t('wap.label')) {
            handleUpdateAction({action: 'ucpe_wap', id: id});
        } else if (elem.label === t('apply.label')) {
            console.log('id', id);
            handleUpdateAction({action: 'script_apply', id: id});
        } else if (elem.label === t('controls.label')) {
            console.log('id', id);
            handleUpdateAction({action: 'vnf_controls', id: id});
        }
    };
    return (
        <div>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={[
                    {
                        label: t('controllers.label'),
                        children: (
                            <>
                                <ControllerPanel
                                    t={t}
                                    menuItems={menuItems}
                                    role={role}
                                    list={controllersList}
                                    loading={allControllersLoading}
                                    error={controllersError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('places.label'),
                        children: (
                            <>
                                <SDNPlacePanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={placesList}
                                    loading={loadingAllPlaces}
                                    error={placesError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('uCPE.label'),
                        children: (
                            <>
                                <UcpePanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={ucpeList}
                                    loading={allUcpeLoading}
                                    error={ucpeError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleSearchUcpe={handleSearchUcpe}
                                    handleMenuItemClick={handleMenuItemClick}
                                    page={ucpePage}
                                    handlePageChange={handleChangeUcpePage}
                                    filterList={ucpeFilter}
                                />
                            </>
                        )
                    },
                    {
                        label: t('sfc.label'),
                        children: (
                            <>
                                <SFCPanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={SFCList}
                                    loading={loadingAllSFC}
                                    error={SFCError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('script.label'),
                        children: (
                            <>
                                <ScriptPanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={ScriptList}
                                    loading={loadingAllScript}
                                    error={ScriptError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('images.label'),
                        children: (
                            <>
                                <ImagesPanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={vmImagesList}
                                    loading={allVmImagesLoading}
                                    error={vmImagesError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleImageFilter={handleImageFilter}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('vnfTemplates.label'),
                        children: (
                            <>
                                <VNFTemplatePanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={vnfTemplatesList}
                                    loading={loadingAllVnfTemplates}
                                    error={vnfTemplateError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    },
                    {
                        label: t('vnfs.label'),
                        children: (
                            <>
                                <VNFPanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={vnfList}
                                    loading={filterLoading}
                                    error={vnfError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                    filterList={vnfFilter}
                                />
                            </>
                        )
                    },
                    {
                        label: t('notifications.label'),
                        children: (
                            <>
                                <SDNNotificationPanel
                                    t={t}
                                    menuItems={menuItems}
                                    list={notificationList}
                                    loading={loadingAllNotification}
                                    error={notificationError}
                                    handleCreateButtonClick={handleCreateButtonClick}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            </>
                        )
                    }
                ]}
            />
        </div>
    );
};
SDNConfigurationPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired
};
export default SDNConfigurationPanel;
