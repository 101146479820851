import React, { useState, useRef, useEffect } from 'react';
import { marginGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {useLocation} from "react-router-dom";

function DirectionalLineCross({ t }) {
    
    const canvasRef = useRef(null);
    const search = useLocation().search;
    const action = new URLSearchParams(search).get('action');
  
    //Load canvas for Line crossing area counting
    const [image, setImage] = useState("");
    const [style, setStyle] = useState(null);
    const [startCords, setStartCords] = useState({x:0, y:0});
    const [lineCords, setLineCords] = useState({x:0, y:0});
    const [imageResolution, setImageResolution] = useState({width: "", height: ""});
    const [finalCords, setFinalCords] = useState({x1: 0, y1: 0, x2: 0, y2: 0});

    useEffect(() => {
        if(!image){
            let imgUrl = localStorage.getItem("roi_img_url");
            handleOnSelectImage(imgUrl);
            return null;
        }
        console.log("router.query: ", action);
        if(canvasRef.current){
            const context = canvasRef.current.getContext("2d");
            let startPosition = {x: 0, y: 0};
            let lineCoordinates = {x: 0, y: 0};
            let isDrawStart = false;
            const getClientOffset = (event) => {
                const {pageX, pageY} = event.touches ? event.touches[0] : event;
                const x = pageX - canvasRef.current.offsetLeft;
                const y = pageY - canvasRef.current.offsetTop;
                return {
                    x,
                    y
                }
            }
            
            const drawLine = () => {
                context.beginPath();
                context.moveTo(startPosition.x, startPosition.y);
                context.lineTo(lineCoordinates.x, lineCoordinates.y);
                context.strokeStyle = 'red';
                context.lineWidth = 3;
                context.stroke();
            }
            
            const mouseDownListener = (event) => {
                startPosition = getClientOffset(event);
                isDrawStart = true;
            }
            
            const mouseMoveListener = (event) => {
                if(!isDrawStart) return;            
                lineCoordinates = getClientOffset(event);
                clearCanvas();
                drawLine();
            }
            
            const mouseupListener = (event) => {
                isDrawStart = false;
                setStartCords(startPosition);
                setLineCords(lineCoordinates);
            }
            
            const clearCanvas = () => {
                context.clearRect(0,0, canvasRef.current.width, canvasRef.current.height);
            }
            
            canvasRef.current.addEventListener('mousedown', mouseDownListener);
            canvasRef.current.addEventListener('mousemove', mouseMoveListener);
            canvasRef.current.addEventListener('mouseup', mouseupListener);
            
            canvasRef.current.addEventListener('touchstart', mouseDownListener);
            canvasRef.current.addEventListener('touchmove', mouseMoveListener);
            canvasRef.current.addEventListener('touchend', mouseupListener);
            context.save();
        }
    }, [image]);

    useEffect(() => {
        calculateResolution();
    }, [lineCords]);

    const roundOF2 = (num) => {
        return Math.round(num * 100) / 100;
    }

    const calculateResolution = () => {
        let startX = roundOF2(parseInt(startCords.x)/parseInt(imageResolution.width));
        let startY = roundOF2(parseInt(startCords.y)/parseInt(imageResolution.height));
        let endX = roundOF2(parseInt(lineCords.x)/parseInt(imageResolution.width));
        let endY = roundOF2(parseInt(lineCords.y)/parseInt(imageResolution.height));
        setFinalCords({x1: startX, y1: startY, x2: endX, y2: endY });
    }
    
    const handleOnSelectImage = (fileUrl) => {
        if(fileUrl){
            let img = new Image();
            img.src = fileUrl;
            img.onload = (e) => {
                setImageResolution({width: img.width, height: img.height});
            };
            setStyle({backgroundImage: `url(${fileUrl})`, border:"1px solid", backgroundRepeat: 'no-repeat', backgroundPosition: 'center'});
            setImage(fileUrl);
        }else{
            setImage("");
            setStyle(null);
        }
    }

    const handleSaveCordinates = () => {
        
        localStorage.removeItem(action);
        localStorage.removeItem("roi_img_url");
        localStorage.setItem(action, JSON.stringify({imageResolution, ...finalCords}));
        window.close();
    }

    return (
        <React.Fragment>
            {style && imageResolution &&
                <canvas ref={canvasRef} width={imageResolution.width} height={imageResolution.height} style={style} />
            }
            <Grid style={{ maxWidth:"300px", ...marginGenerator('mt-24'), ...marginGenerator('ml-24') }}>
                {finalCords && finalCords.x1 > 0 &&
                    <Grid style={{...marginGenerator('mt-20')}}>
                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                            Line crossing Start - <br/> X axis : {finalCords.x1}, Y axis: {finalCords.y1}
                        </Typography>
                        <Typography style={{...marginGenerator('mt-15')}} variant={'subtitle1'} color={'textPrimary'}>
                            Line crossing End - <br/> X axis: {finalCords.x2}, Y axis: {finalCords.y2}
                        </Typography>
                    </Grid>
                }
                <Grid style={{...marginGenerator('mt-20'), ...marginGenerator('mb-40')}}>
                    <Button
                        color="secondary"
                        variant={'contained'}
                        disabled={!finalCords.x1}
                        onClick={() => {
                            handleSaveCordinates();
                        }}
                        style={{width:"100%"}}
                    >
                        {t('save.label')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default withTranslation()(DirectionalLineCross);
