import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {marginGenerator, paddingGenerator} from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import PlacesMap from '../../../NewSubscription/components/PlacesMap';
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonComponent(props) {
    const {t, onClick, loading} = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14}/>}
            {!loading && t('submit.label')}
        </Button>
    );
}
const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    input: {
        minWidth: '224px'
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    }
}));
function CreateSDNPlaceModal({t, open, handleCreateSDNPlace, handleClose, modalState}) {
    const [placeName, setPlaceName] = useState('');
    const [locationState, setLocationState] = useState({
        latitude: '',
        longitude: ''
    });
    React.useEffect(() => {
        if (modalState.clearData && open) {
            setPlaceName('');
            setLocationState({latitude: '', longitude: ''});
            handleClose();
        }
    }, [modalState]);
    const classes = useStyles();
    const handleSetPositionBase = pos => {
        setLocationState({latitude: pos.latitude, longitude: pos.longitude});
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="place-template-modal"
            aria-describedby="place-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="place-template-title">
                {t('createNew.label')} {t('place.label')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            ...paddingGenerator('pb-30')
                        }}>
                        <Input
                            value={placeName}
                            type={'text'}
                            placeholder={`${t('name.label')}*`}
                            style={{width: '100%'}}
                            color={'secondary'}
                            onChange={evt => setPlaceName(evt.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        style={marginGenerator('mt-20')}
                        className={classes.mapContainer}>
                        <PlacesMap
                            id={'place-map'}
                            handleSetPosition={handleSetPositionBase}
                            position={
                                locationState &&
                                locationState['latitude'] &&
                                locationState['longitude']
                                    ? {
                                        lat: locationState['latitude'],
                                        lng: locationState['longitude']
                                    }
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            value={locationState['latitude']}
                            type={'text'}
                            placeholder={`${t('latitude.label')}*`}
                            style={{width: '100%'}}
                            className={classes.input}
                            color={'secondary'}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            value={locationState['longitude']}
                            type={'text'}
                            placeholder={`${t('longitude.label')}*`}
                            style={{width: '100%'}}
                            className={classes.input}
                            color={'secondary'}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}>
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            handleCreateSDNPlace({
                                place_name: placeName,
                                location: locationState
                            });
                        }}
                        loading={modalState.btnLoading}/>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
CreateSDNPlaceModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreatePlaceSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(CreateSDNPlaceModal);
