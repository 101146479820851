import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

const ServiceItem = ({
  t,
  data,
  handleEditService,
  handleViewProducts
}) => {
    
    const [countries, setCountries] = React.useState("-");
    React.useEffect(() => {
        let countryNames = data.countryId.map(itm => itm.name);
        countryNames = countryNames.join(", ");
        setCountries(countryNames ? countryNames : "-");
    }, [data]);
    const commonClasses = commonStyles();
    
    return (
        <TableRow key={data.id} >
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} > 
                            {data.name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'} >{countries}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="center">
                <Grid className={commonClasses.column} >
                    <VisibilityIcon style={{cursor: "pointer"}} onClick={evt => handleViewProducts(data)} color="secondary" />
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <EditIcon style={{cursor: "pointer"}} onClick={evt => handleEditService(data)} color="secondary" />
                </Grid>
            </TableCell>
        </TableRow>
    );
};

ServiceItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func
};

export default withTranslation()(ServiceItem);