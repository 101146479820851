import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import { USER_ROLES } from "../../../../../utils/constants";
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { dateFormatterLLL } from '../../../../../utils';

const InstallTeamItem = ({
    t,
    data,
    handleMenuItemClick,
    menuItems,
    CurrentUserName,
    handleClickViewAllDetailsAndAttachment,
    handleProjectAttachmentClick,
    handleEditTeamClick
}) => {
    const commonClasses = commonStyles();
    const role = localStorage.getItem('role');
    const sentMenuItems = [
        {
            id: 'Sent',
            name: t('sent.label')
        }
    ];
    useEffect(() => { }, [CurrentUserName])
    return (
        <TableRow key={data.uid}>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.auto_generated_project_id ? data.auto_generated_project_id : (data.case_number ? data.case_number : 'N/A')}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.customer_name} / {data.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.site_name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.install_team}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Typography variant={'subtitle1'}>
                            {data.created_at ? dateFormatterLLL(data.created_at) : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid className={commonClasses.column}>
                    <Grid>
                        <Button variant={'outlined'}
                            color={'primary'}
                            style={{ width: '100%', marginBottom: '10px' }}
                            onClick={() => {
                                handleClickViewAllDetailsAndAttachment(data);
                            }}>{t('ViewCompleteDetails.label')}</Button>
                        <Button variant={'outlined'}
                            disabled={data.documents && data.documents.length == 0 ? true : false}
                            color={'primary'}
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleProjectAttachmentClick(data.documents);
                            }}>{t('attachments.label')}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid>
                    {(role.includes(USER_ROLES.PMO_ADMIN) || role.includes(USER_ROLES.INSTALL_TEAM_ADMIN)) ?
                        <React.Fragment>
                            <OutlinedSelect
                                label={`${t('Select Action')}`}
                                val={data.is_available ? 'Sent' : ''}
                                disabled={false}
                                handleChange={evt => {
                                    handleMenuItemClick(evt.target.value, data);
                                }}
                                styleOverrides={{ width: '100%', marginBottom: '10px' }}
                                options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                            />
                            <Button variant={'outlined'}
                                disabled={false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleEditTeamClick(data);
                                }}>{t('edit.label')}</Button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <OutlinedSelect
                                label={`${t('Select Action')}`}
                                val={data.is_available ? 'Sent' : ''}
                                disabled={data.is_available || (data.install_team != CurrentUserName) ? true : false}
                                handleChange={evt => {
                                    handleMenuItemClick(evt.target.value, data);
                                }}
                                styleOverrides={{ width: '100%', marginBottom: '10px' }}
                                options={data.is_available ? sentMenuItems : (menuItems ? menuItems : [])}
                            />
                            <Button variant={'outlined'}
                                disabled={false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleEditTeamClick(data);
                                }}>{t('edit.label')}</Button>
                        </React.Fragment>
                    }
                </Grid>
            </TableCell>
            {/* } */}
        </TableRow>
    );
};
InstallTeamItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};
export default withTranslation()(InstallTeamItem);