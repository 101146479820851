import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
//import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../theme/utils';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import Radio from '@material-ui/core/Radio';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../assets/icons/sign-pen.png';
import HrHeader from '../../HrHeader';

const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
  }));
  
  function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

  const urInitFields = {
    id: "",
    custmInfo: "",
    termsAndCond: "",
    fullName: "",
    networkId: "",
    contactNo: "",
    cellPhoneNo: "",
    email: "",
    itManager: "",
    itCallRef: "",
    itSign: "",
    itDateSubmitted: null,
    custmName: "",
    custmSign: "",
    custmDateSubmitted: null,
    scurityTokenNumber: "",
    govAndLogiSign: "",
    govAndLogiDateSubmitted: null,
};

const RemoteAccessForm = ({
    t,
    open,
    handleClose,
    handleOpenSignPanModal,
    data,
    signatures,
    handleCreateRequestSubmit,
}) => {

  const classes = useStyles();
  const [urFormState, setURFormState] = useState({ ...urInitFields });

  React.useEffect(() => {
    if (!open) {
        setURFormState({ ...urInitFields });
        handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
        const id = data._id;
        setURFormState({...data,id});
    }else{
        // setShowUserSelect(true);
        // setShowLearnDevelopment(true);
        // setShowHOD(true);
        // setShowHRManager(true);
        // setShowGeneralManager(true);
    }
}, [data]);

const handleChange = (value, name, key) => {
    setURFormState({ ...urFormState, [name]: value });
}
  return (
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={() => {
        handleClose();
    }}>
    <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
        <HrHeader />
    </DialogTitle>
    <DialogContent>
    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Remote Access Form</Typography>
                    </Grid>
        </Grid>
    <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Type of Access being requested</Typography>
                    </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={4}>
                        <RadioGroup
                            row
                            aria-label="approve_rejected"
                            name="termsAndCond"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `termsAndCond`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Request"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.termsAndCond === 'Request' ? true : false}
                                labelPlacement="start"
                                label="Request"
                            />
                             <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Replace"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.termsAndCond === 'Replace' ? true : false}
                                labelPlacement="start"
                                label="Replace"
                            />
                        </RadioGroup>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Customer Information</Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={4}>
                    <RadioGroup
                            row
                            aria-label="Company"
                            name="custmInfo"
                            onChange={(evt) => {
                                handleChange(evt.target.value, `custmInfo`);
                            }}
                        >
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Morupule"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.custmInfo === 'Morupule' ? true : false}
                                labelPlacement="start"
                                label="MORUPULE"
                            />
                            <FormControlLabel
                                style={{ ...marginGenerator('mb-8') }}
                                value="Other"
                                labelPlacement="start"
                                control={<Radio style={{ border: '0px', ...marginGenerator('mr-8') }} />}
                                checked={urFormState.custmInfo === 'Other' ? true : false}
                                label="OTHER"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Customer Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.fullName}
                            onChange={evt => handleChange(evt.target.value, 'fullName')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Customer Network ID ( eg:blackk )"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.networkId}
                            onChange={evt => handleChange(evt.target.value, 'networkId')}
                        />
                    </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Customer Contact Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.contactNo}
                            onChange={evt => handleChange(evt.target.value, 'contactNo')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Customer Cellphone number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.cellPhoneNo}
                            onChange={evt => handleChange(evt.target.value, 'cellPhoneNo')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Email"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.email}
                            onChange={evt => handleChange(evt.target.value, 'email')}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >MORUPULE IT APPROVAL</Typography>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"IT Manager"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.itManager}
                            onChange={evt => handleChange(evt.target.value, 'itManager')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"CALL REF"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.itCallRef}
                            onChange={evt => handleChange(evt.target.value, 'itCallRef')}
                        />
                    </Grid>
                    <Grid xs={2}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("itSign")}
                        >
                           <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                    <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.itDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "itDateSubmitted")}
                        />
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={12}>
                        <Typography variant="h4" >Customer Signature</Typography>
                    </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Customer Full Name"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.custmName}
                            onChange={evt => handleChange(evt.target.value, 'custmName')}
                        />
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("custmSign")}
                        >
                         Customer Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.custmDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "custmDateSubmitted")}
                        />
                    </Grid>
                    
          </Grid>
         <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <Grid item xs={12}>
                <Typography variant="h4" >IT USE: Governance & Logistics</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 20 }} >
                    <Grid item xs={4}>
                        <Input
                            type={'text'}
                            placeholder={"Secure Token Number"}
                            style={{ width: '100%' }}
                            className={classes.input}
                            color={'secondary'}
                            value={urFormState.scurityTokenNumber}
                            onChange={evt => handleChange(evt.target.value, 'scurityTokenNumber')}
                        />
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <IconButton 
                        variant={'outlined'}
                        onClick={() => handleOpenSignPanModal("govAndLogiSign")}
                        >
                         IT Manager Signature  <img src={SignIcon} width="26" height={26} alt=""/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimeSelector
                            inputStyle={{ minWidth: 260 }}
                            label={'Date Submitted'}
                            dateFormat={"yyyy-MM-dd"}
                            value={urFormState.govAndLogiDateSubmitted}
                            handleChange={newDate => handleChange(newDate, "govAndLogiDateSubmitted")}
                        />
                    </Grid>
          </Grid>
          </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent
                    t={t}
                    onClick={() =>handleCreateRequestSubmit(urFormState)}
                    loading={false}
                />
            </Grid>
        </DialogActions>
   </Dialog>
  )
}

export default RemoteAccessForm