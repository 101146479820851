import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#9696A4',
        borderBottomWidth: 1,
        height: 24,
        fontStyle: 'bold',
        fontSize: 12,
        flexGrow: 1,
    },
    description: {
        width: '30%',
    },
    head1: {
        width: '20%',
        textAlign: "center"
    },
    head2: {
        width: '25%',
        textAlign: "center"
    },
    rate: {
        width: '25%',
        textAlign: "center"
    }
  });

  const InvoiceTableHeader = ({row}) => {
      return (
        <View style={styles.container}>
            <Text style={styles.description}>{row.title}</Text>
            <Text style={styles.head1}>{row.head3}</Text>
            <Text style={styles.head2}>{row.head4}</Text>
            <Text style={styles.rate}>{row.head5}</Text>
        </View>
    );
}
  
  export default InvoiceTableHeader