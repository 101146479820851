import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import GenricTable from '../../../../../../components/GenericTable';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';


const ProcessWorkFlow = ({ t, handleCreateProcessStep, loading, list, handleItemClick }) => {
    
    const columns = [
        {
            label: t('title.label'),
            key: "title"
        },
        {
            label: t('description.label'),
            key: "description",
            style: {whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: 500}
        },
        {
            label: t('status.label'),
            key: "status"
        },
        {
            label: t('createdBy.label'),
            key: "createdByName"
        },
        {
            label: t('action.label'),
            key: "action"
        }  
    ];
    const [processList, setProcessList] = React.useState([]);

    React.useEffect(() => {
        if(list && list.length){
            setProcessList(list.map(itm => {
                let action = <>
                    <IconButton onClick={evt => handleItemClick(itm, "edit")} aria-label="Edit">
                        <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={evt => handleItemClick(itm, "delete")} aria-label="Delete">
                        <DeleteIcon color="secondary" />
                    </IconButton>
                </>;
                return {
                    ...itm,
                    action  
                }
            }));  
        }
    }, [list])
    return (
        <>
            <Grid container justify={'space-between'}>
                <Grid item></Grid>
                <Grid item>
                    <Button
                        align={'right'}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() =>
                            handleCreateProcessStep()
                        }>
                        {t('createProcess.label')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <GenricTable columns={columns} list={processList} loading={loading} />
            </Grid>
        </>
    );
};
ProcessWorkFlow.propTypes = {
    t: PropTypes.func.isRequired
};
export default ProcessWorkFlow;
