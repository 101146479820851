import axios from '../utils/httpClient';

const accessToken = localStorage.getItem('syncomToken');

const AddRegion = async payload => {
  try {
    const response = await axios.post('region', payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const updateRegion = async (regionId, payload) => {
  try {
    const response = await axios.put(`region/${regionId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deleteRegion = async regionId => {
  try {
    const response = await axios.delete(`region/${regionId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getRegionListByNameFilter = async (page = 1, limit = 10, word = '') => {
  try {
    const response = await axios.get(
      `region?limit=${limit}&page=${page}&search=${word}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

export { AddRegion, updateRegion, deleteRegion, getRegionListByNameFilter };
