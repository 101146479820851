import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateInstallTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
}) {
    const classes = useStyles();
    const [soakPeriod, setSoakPeriod] = useState('');
    const [soakCompletionDate, setSoakCompletionDate] = useState('');
    const [projectCompletionCertificate, setProjectCompletionCertificate] = useState('');
    const [activationForm, setActivationForm] = useState('');
    const [quotation, setQuotation] = useState('');
    const [discount, seDiscount] = useState('');
    const [billingTeam, setBillingTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setSoakPeriod('');
            setSoakCompletionDate('');
            setProjectCompletionCertificate('');
            setActivationForm('');
            setQuotation('');
            seDiscount('');
            setBillingTeam('');
            setNextTeamMemberEmail('');
        }
    }, [modalState, open]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('projectManagementTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : ((preValuesOfForm.project_info && preValuesOfForm.project_info.case_number) ? preValuesOfForm.project_info.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('soakPeriod.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={soakPeriod}
                                type={'text'}
                                placeholder={t('soakPeriod.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSoakPeriod(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('soakCompletionDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={soakCompletionDate}
                                    onChange={date => setSoakCompletionDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Please select a date"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('projectCompletionCertificate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setProjectCompletionCertificate(file);
                                    } else {
                                        setProjectCompletionCertificate('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('quotation.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setQuotation(file);
                                    } else {
                                        setQuotation('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('activationForm.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setActivationForm(file);
                                    } else {
                                        setActivationForm('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('discount.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={discount}
                                type={'text'}
                                placeholder={t('discount.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => seDiscount(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("billing.label") + ' ' + t("team.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setBillingTeam(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            let convertContactDate = "";
                            if (!soakPeriod || !soakCompletionDate || !projectCompletionCertificate || !discount || !activationForm || !billingTeam || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            convertContactDate = dateFormatterYYYYMMDD(soakCompletionDate);
                            if (convertContactDate == "Invalid date") {
                                Message.error(convertContactDate);
                            }
                            // create URL for project completion form file
                            let getProjectCertificate = "";
                            if (projectCompletionCertificate != "" && projectCompletionCertificate != null) {
                                let getProjectCertificateUrl = await handleUploadFiles(projectCompletionCertificate, preValuesOfForm.project, 'pm_team_zambia');
                                if (getProjectCertificateUrl.data && getProjectCertificateUrl.data.allUploadFile && getProjectCertificateUrl.data.allUploadFile.url) {
                                    getProjectCertificate = getProjectCertificateUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create URL for activation form file
                            let getActivationForm = "";
                            if (activationForm != "" && activationForm != null) {
                                let getActivationFormUrl = await handleUploadFiles(activationForm, preValuesOfForm.project, 'pm_team_zambia');
                                if (getActivationFormUrl.data && getActivationFormUrl.data.allUploadFile && getActivationFormUrl.data.allUploadFile.url) {
                                    getActivationForm = getActivationFormUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            // create URL for quotation form file
                            let getQuotation = "";
                            // if (quotation != "" && quotation != null) {
                            //     let getQuotationUrl = await handleUploadFiles(quotation, preValuesOfForm.project, 'pm_team_zambia');
                            //     if (getQuotationUrl.data && getQuotationUrl.data.allUploadFile && getQuotationUrl.data.allUploadFile.url) {
                            //         getQuotation = getQuotationUrl.data.allUploadFile.url;
                            //     }
                            //     else {
                            //         Message.error("File not uploaded! Please try again.");
                            //     }
                            // }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                soak_period: soakPeriod,
                                soak_completion_date: convertContactDate,
                                project_completion_certificate: getProjectCertificate,
                                activattion_form: getActivationForm,
                                quotation: getQuotation,
                                discount: discount,
                                billing_team_name: billingTeam,
                                next_team_member_email: nextTeamMemberEmail,
                                auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateInstallTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateInstallTeamModal);