import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Message from '../../../../src/components/Message';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from '../../../utils/constants';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator } from '../../../theme/utils';
import { dateFormatterYYYYMMDD } from '../../../utils';
import DateTimeSelector from '../../../components/DateTimeSelector';
import { UPDATE_EVENTS } from '../mutations';

const useStyles = makeStyles(theme =>
    createStyles({
        formControl: {
            minWidth: '100%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        }
    })
);

function EventDialog({ open, handleClose, handleEventStatus, data, t }) {

    const [formData, setFormdata] = useState({ id: '', date: null, expiryDate: null, name: '' });
    const [buttonLoading, setBdyButtonLoading] = useState(false)

    useEffect(() => {
        if (data && data.id) {
            setFormdata({ ...formData, id: data.id, date: dateFormatterYYYYMMDD(data.fromDate), expiryDate: dateFormatterYYYYMMDD(data.expiryDate), name: data.name })
        }
    }, [data])

    const [updateEvent] = useMutation(UPDATE_EVENTS, {
        onError: error => {
            console.log('Error:-', error);
            handleEventStatus({ status: false })
        },
        onCompleted: data => {
            setBdyButtonLoading(false)
            handleEventStatus({ status: true })
        }
    })

    const handleSubmitData = () => {
        if (formData.name === "" || formData.date === null || formData.expiryDate === null) {
            Message.error(REQUIRED_ERROR)
        } else {
            setBdyButtonLoading(true)
            updateEvent({
                variables: {
                    id: formData.id,
                    name: formData.name,
                    date: formData.date,
                    expiryDate: formData.expiryDate,
                }
            })
        }
    };

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                className={classes.beg}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {t('updateEvent.label')}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{
                                    ...marginGenerator('mb-8'),
                                    ...marginGenerator('mt-40')
                                }}>
                                {t('eventName.label')} *
                            </Typography>
                            <TextField
                                type="textarea"
                                placeholder="Add Customer Update Title"
                                variant="standard"
                                name="name"
                                value={formData.name}
                                onChange={(evt) => setFormdata({ ...formData, name: evt.target.value })}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{
                                    ...marginGenerator('mb-8'),
                                    ...marginGenerator('mt-40')
                                }}>
                                {t('startDate.label')} *
                            </Typography>
                            <DateTimeSelector
                                label={"Select From Date"}
                                dateFormat={"yyyy-MM-dd"}
                                value={formData.date}
                                handleChange={(date) => setFormdata({ ...formData, date: date })}
                                styleOverrides={{ width: '100%' }}
                                inputStyle={{ minWidth: 220, padding: 10 }}
                                disableFuture={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{
                                    ...marginGenerator('mb-8'),
                                    ...marginGenerator('mt-40')
                                }}>
                                {t('expiryDate.label')} *
                            </Typography>
                            <DateTimeSelector
                                label={"Select Expiry Date"}
                                dateFormat={"yyyy-MM-dd"}
                                value={formData.expiryDate}
                                handleChange={(date) => setFormdata({ ...formData, expiryDate: date })}
                                styleOverrides={{ width: '100%' }}
                                inputStyle={{ minWidth: 220, padding: 10 }}
                                disableFuture={false}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="default"
                        variant="contained"
                        size="small">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitData}
                        disabled={buttonLoading}
                        variant="contained"
                        color="primary"
                        size="small">
                        {buttonLoading ? (
                            <CircularProgress
                                color="secondary"
                                style={{ width: 25, height: 25 }}
                            />
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withTranslation()(EventDialog);
