import {gql} from 'apollo-boost';

export const GET_SHAYONA_DASHBOARD_DATA = gql`
    query GetDashboardData($organization: String,$startTime: Int, $endTime: Int) {
        getDashboardData(organization: $organization, startTime: $startTime, endTime: $endTime) {
            success
            message
            chart
            num_bags
            num_disputes
            num_trucks
        }
    }
`;

export const GET_SHAYONA_TRUKES_LIST = gql`
    query GetTruckList($organization: String, $page: Int, $limit: Int) {
        getTruckList(organization: $organization, page: $page, limit: $limit) {
            success
            message
            count
            data
        }
    }
`;

export const GET_SHAYONA_TRIPS_LIST = gql`
    query GetTripsList($organization: String, $page: Int, $limit: Int, $truckNumber: String, $startTime: Int, $endTime: Int, $status: String, $archived: Boolean) {
        getTripsList(organization: $organization, page: $page, limit: $limit, truckNumber: $truckNumber, startTime: $startTime, endTime: $endTime, status: $status, archived: $archived) {
            success
            message
            count
            data
        }
    }
`;

export const GET_SHAYONA_ALERTS_LIST = gql`
    query GetAlertsList($organization: String, $page: Int, $limit: Int, $truckNumber: String, $startTime: Int, $endTime: Int, $status: String) {
        getAlertsList(organization: $organization, page: $page, limit: $limit, truckNumber: $truckNumber, startTime: $startTime, endTime: $endTime, status: $status) {
            success
            message
            count
            data
        }
    }
`;

export const GET_SHAYONA_BRANDS_LIST = gql`
    query GetBrandsList($organization: String) {
        getBrandsList(organization: $organization) {
            success
            message
            data
        }
    }
`;

export const GET_SHAYONA_AUDIT_LIST = gql`
    query GetAuditList($organization: String) {
        getAuditList(organization: $organization) {
            success
            message
            data
        }
    }
`;

export const GET_SHAYONA_TRIP_DETAILS = gql`
    query GetTripDetails($tripId: Int,$organization: String) {
        getTripDetails(tripId: $tripId,organization: $organization) {
            success
            message
            bags_loaded
        }
    }
`;