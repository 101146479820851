import React, { useState, createContext, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  GET_BIRTHDAY_LIST,
  GET_EVENTS_LIST
} from '../containers/commonQueries';

export const SideBarContext = createContext();

function SideBarProvider({ children }) {
  const [birthdayList, setBirthdayList] = useState([]);
  const [eventsList, setEventsList] = useState([]);

  useEffect(() => {
    getBirthdayList();
    getEventsList();
  }, []);

  const updateEventFun = () => {
    getEventsList();
  };

  const [getBirthdayList] = useLazyQuery(GET_BIRTHDAY_LIST, {
    onError: err => {
      console.log('err', err);
    },
    onCompleted: res => {
      if (res && res.getBirthdayList && res.getBirthdayList.length > 0) {
        let data = res.getBirthdayList.map(bday => {
          const date = new Date(
            new Date().getFullYear(),
            Number(bday.month) - 1,
            Number(bday.day)
          ); // 2009-11-10
          const month = date.toLocaleString('default', { month: 'long' });
          return {
            name: `${bday.firstName} ${bday.lastName}`,
            date: `${bday.day} ${month} ${new Date().getFullYear()}`
          };
        });
        setBirthdayList(data);
      }
    }
  });

  const [getEventsList] = useLazyQuery(GET_EVENTS_LIST, {
    fetchPolicy: 'no-cache',
    onError: err => {
      console.log('err', err);
    },
    onCompleted: res => {
      if (res && res.getAllEvents && res.getAllEvents.length > 0) {
        let data = res.getAllEvents.map(evnt => {
          let dt = new Date(Number(evnt.date));
          let ex = new Date(Number(evnt.expiryDate));
          let date = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
          let expiryDate = new Date(
            ex.getFullYear(),
            ex.getMonth(),
            ex.getDate()
          );
          const fromMonth = date.toLocaleString('default', { month: 'long' });
          const toMonth = expiryDate.toLocaleString('default', {
            month: 'long'
          });
          return {
            id: evnt.id,
            name: evnt.name,
            fromDate: date,
            expiryDate: expiryDate,
            date:
              fromMonth === toMonth
                ? `${dt.getDate()} - ${ex.getDate()}  ${fromMonth}`
                : `${dt.getDate()} ${fromMonth} - ${ex.getDate()} ${toMonth}`
          };
        });
        setEventsList(data);
      }
    }
  });

  return (
    <SideBarContext.Provider
      value={{
        birthdayList,
        eventsList,
        updateEventFun
      }}>
      {children}
    </SideBarContext.Provider>
  );
}

export default SideBarProvider;
