import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FTable from './Table';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { getRegions } from '../../../services/fcrService';
import { useAuth } from '../../../contexts/AuthContext';

const useStyles = makeStyles({
  topEle: {
    marginRight: '15px',
    marginTop: '15px'
  }
});

export default function CreatePortDashBoard(props) {
  const classes = useStyles();

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');

  const { loggedInUserRoles } = useAuth();

  const history = useHistory();

  const GetRegions = async () => {
    const res = await getRegions();
    if (res?.status === 200) setRegions(res?.data?.result?.data);
  };

  const handleRegion = e => {
    setRegion(e.target.value);
  };

  useEffect(() => {
    GetRegions();
  }, []);

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container xs={12} justify="flex-end">
          <Box display="flex" flexDirection="column" mr={2} mt={1}>
            <Typography variant="subtitle2">Select region</Typography>
            <TextField
              select
              value={region}
              onChange={handleRegion}
              SelectProps={{
                native: true
              }}
              variant="outlined"
              size="small">
              <option value="">Global</option>
              {regions &&
                regions?.map(region => (
                  <option key={region?._id} value={region?._id}>
                    {region?.region_name}
                  </option>
                ))}
            </TextField>
          </Box>
        </Grid>

        <Grid item style={{ marginLeft: '10px' }}>
          <Button
            variant="outlined"
            disabled={
              loggedInUserRoles?.includes('fabric_readonly') === false
                ? false
                : true
            }
            onClick={() => history.push('/fabricService/CreatePort')}>
            Create Port
          </Button>
        </Grid>
        <Grid item>
          <FTable regionId={region} />
        </Grid>
      </Grid>
    </>
  );
}
