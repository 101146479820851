import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel" la

            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));

export default function QuantityOfProjectTableItem({ data, column, loading, error }) {
    const classes = useStyles();

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: '100%',
                        padding: 20,
                        textAlign: 'center',
                        display: 'inline-block'
                    }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {column &&
                                    column.map(column => (
                                        <TableCell>{column.label}</TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                data ?  data.map(row => (
                                    <StyledTableRow>
                                        <TableCell>{row.column}</TableCell>
                                        <TableCell>{row.quantity_of_project}</TableCell>
                                        <TableCell>{row.nrc}</TableCell>
                                        <TableCell>{row.mrc}</TableCell>
                                    </StyledTableRow>)) :
                                    <TableRow>
                                        <TableCell colSpan={column.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}