import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Input } from '@material-ui/core';
import { marginGenerator } from '../../../../../theme/utils';
import DateTimeSelector from '../../../../../components/DateTimeSelector';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import Autocomplete from './AutoComplete';

const ChartContainer = ({
  title,
  children,
  filterList,
  clearFilter,
  customMargin,
  filterSubmit,
  childStyleOveride,
  createLabel,
  createNewButton
}) => {
  return (
    <Grid style={customMargin ? customMargin : { ...marginGenerator('mt-56') }}>
      <Grid container justify={'space-between'} alignItems="center">
        {title && (
          <Grid item>
            <Typography variant={'h4'}>{title}</Typography>
          </Grid>
        )}
        <Grid item container alignItems="center">
          <Grid item>
            {filterList &&
              filterList.map((filter, index) => {
                if (filter.type && filter.type === 'autocomplete') {
                  return (
                    <Autocomplete
                      key={index}
                      styleOverrides={filter.styleOverrides || null}
                      handleChange={filter.handleChange}
                      options={filter.options}
                      label={filter.label}
                      val={filter.val}
                      size="small"
                      onInputChange={filter.onInputChange}
                      handleClose={
                        filter.handleClose ? filter.handleClose : undefined
                      }
                    />
                  );
                } else if (filter.type && filter.type === 'input') {
                  return (
                    <DateTimeSelector
                      key={index}
                      inputStyle={{ padding: 10, minWidth: 225 }}
                      label={filter.label ? filter.label : ''}
                      dateFormat={'yyyy-MM-dd HH:mm'}
                      value={filter.val}
                      handleChange={filter.handleChange}
                      styleOverrides={filter.styleOverrides}
                      className={filter.className ? filter.className : null}
                      disableFuture={true}
                    />
                  );
                } else if (filter.type && filter.type === 'text') {
                  return (
                    <Input
                      key={index}
                      placeholder={filter.label ? filter.label : ''}
                      value={filter.val}
                      handleChange={filter.handleChange}
                      style={filter.styleOverrides}
                      className={filter.className ? filter.className : null}
                    />
                  );
                } else {
                  return (
                    <OutlinedSelect
                      key={index}
                      val={filter.val}
                      label={filter.label}
                      handleChange={filter.handleChange}
                      IconComponent={filter.iconComponent}
                      options={filter.options}
                      styleOverrides={filter.styleOverrides || null}
                      disabled={filter.disabled || false}
                    />
                  );
                }
              })}
          </Grid>

          {filterSubmit && (
            <Grid item>
              <Button
                onClick={filterSubmit}
                style={{ marginLeft: 24 }}
                variant={'contained'}
                color="primary">
                Filter
              </Button>
            </Grid>
          )}

          {clearFilter && (
            <Grid item>
              <Button
                style={{ marginLeft: 24 }}
                onClick={clearFilter}
                size="small"
                color="primary">
                Clear filter
              </Button>
            </Grid>
          )}
          {createNewButton && (
            <Grid item>
              <Button
                onClick={createNewButton}
                style={{ marginLeft: 25 }}
                variant={'outlined'}
                color="primary">
                {createLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        style={
          childStyleOveride ? childStyleOveride : marginGenerator('mt-32')
        }>
        {children}
      </Grid>
    </Grid>
  );
};

ChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  filterList: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
};

export default ChartContainer;
