import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import {
    InternalPaths,
    OrganisationPaths,
    ServiceTicketPaths,
    SubscriptionPaths
} from '../../../routes/routePaths';
import Header from '../../../components/Header';
import { useHistory } from 'react-router';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import CustomTabs from '../../../components/CustomTabs';
import { getParam, getUrl } from '../../../utils';
import {
    GET_CONNECTIVITY_INFO_DETAILS,
    GET_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_LIST
} from '../../Subscriptions/queries';
import { GET_ALL_ROLES } from '../../commonQueries';
import { DELETE_PENDING_SUBSCRIPTION_BY_ID, ASSIGN_USERS_TO_GROUP } from '../../Subscriptions/mutations';
import {
    STATUS_TYPES,
    SUBSCRIPTION_STATUS,
    TICKET_PRIORITY,
    SERVICE_TYPES
} from '../../../utils/constants';
import SubscriptionPanel from '../../Subscriptions/components/SubscriptionPanel';
import EditSubscriptionModal from '../../Subscriptions/components/EditSubscriptionModal';
import { GET_SERVICE_TICKETS } from '../../ServiceTickets/queries';
import TicketPanel from '../../ServiceTickets/components/TicketPanel';
import { getSubscriptionOptions } from '../../ServiceTickets/ServiceTicketLists';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Flag from '@material-ui/icons/Flag';
import DNS from '@material-ui/icons/Dns';
import { GET_MEMBERS, GET_ASSIGN_USERS, GET_INVITE_MEMBERS } from './queries';
import MembersPanel from './components/MembersPanel';

import { UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_AND_SITES } from '../../Subscriptions/mutations';
import { USER_ROLES } from '../../../utils/constants';
import SelectNodeManagementModal from './components/SelectNodeManagementModal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Message from '../../../components/Message';
import DIAConfigurationDetailsPanel from '../../Subscriptions/components/DIAConfigurationDetailsPanel';
import DIANConfigurationPanel from "../../Subscriptions/components/DIANConfigurationPanel";
import OrganisationBilling from "../../BillingPanel";
import AssignUserModal from './components/AssignUserModal';
import { GET_COUNTRY_LIST, GET_REGION_LIST } from '../../commonQueries';
import { DELETE_USERS, UPDATE_USERS } from '../Users/mutations';
import { ORGANISATIONS_LIST_FOR_SELECT } from '../Organisations/queries';
import EditUser from '../Users/EditUsers';
import AssignSubscriptionToUserModal from './components/AssignSubscriptionToUserModal';
import { GET_ALL_SERVICE_TICKETS_LIMIT } from '../../TicketingSystem/queries';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

const breadcrumbList = [
    { label: 'home.label', link: InternalPaths.Dashboard },
    { label: 'organisations.label', link: InternalPaths.organisations },
    { label: 'details.label', link: OrganisationPaths.details }
];
let permisson = false;
const getInputs = (type, t) => {
    let inputArr = [];
    if (type === SERVICE_TYPES.MPLS) {
        inputArr = [
            {
                label: t('bandwidth.label'),
                type: 'number',
                stateVar: `bandwidth`,
                placeholder: '',
                endAdorment: 'MBPS'
            },
            {
                label: t('mtu.label'),
                type: 'number',
                stateVar: `mtu`,
                placeholder: ''
            },
            {
                label: t('period.label'),
                type: 'number',
                stateVar: `period`,
                placeholder: ''
            },
            {
                label: t('linkType.label'),
                type: 'string',
                stateVar: `linkType`,
                placeholder: ''
            }
        ];
    } else if (type === SERVICE_TYPES.DIA) {
        inputArr = [
            {
                label: t('bandwidth.label'),
                type: 'number',
                stateVar: `bandwidth`,
                endAdorment: 'MBPS',
                placeholder: ''
            },
            {
                label: t('mtu.label'),
                type: 'number',
                stateVar: `mtu`,
                placeholder: ''
            },
            {
                label: t('period.label'),
                type: 'number',
                stateVar: `period`,
                placeholder: ''
            },
            {
                label: t('linkType.label'),
                type: 'string',
                stateVar: `linkType`,
                placeholder: ''
            },
            {
                label: t('noOfPublicIPs.label'),
                type: 'number',
                stateVar: `noOfPublicIPs`,
                placeholder: ''
            },
            {
                label: t('blockedUrls.label'),
                type: 'string',
                stateVar: `blockedUrls`,
                placeholder: ''
            },
            {
                label: t('portForwarding.label'),
                type: 'string',
                stateVar: `portForwarding`,
                placeholder: ''
            },
            {
                label: t('ddosPrevention.label'),
                type: 'string',
                stateVar: `ddosPrevention`,
                placeholder: ''
            }
        ];
    } else if (type === SERVICE_TYPES.EDGE_AI) {
        inputArr = [
            {
                label: t('availableBandwidth.label'),
                type: 'selectDropdown',
                stateVar: `bandwidth`,
                endAdorment: 'MBPS',
                options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
                placeholder: 'availableBandwidthPh.label'
            },
            {
                label: t('numberOfCam.label'),
                type: 'number',
                stateVar: `numberOfCamera`,
                placeholder: 'numberOfCamPh.label'
            },
            {
                label: t('period.label'),
                type: 'selectDropdown',
                stateVar: `period`,
                options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
                placeholder: 'periodPh.label'
            },
            {
                label: t('linkType.label'),
                type: 'selectDropdown',
                stateVar: `linkType`,
                options: ['Fibre', 'Wireless', 'VSAT'],
                placeholder: 'linkTypePh.label'
            }
        ];
    } else if (type === SERVICE_TYPES.SDN) {
        inputArr = [
            {
                label: t('availableBandwidth.label'),
                type: 'selectDropdown',
                stateVar: `bandwidth`,
                //endAdorment: 'MBPS',
                options: ['2', '4', '8', '10', '20', '50', '100', '100+'],
                placeholder: 'availableBandwidthPh.label'
            },
            {
                label: t('period.label'),
                type: 'selectDropdown',
                stateVar: `period`,
                options: ['PoC', '6 months', '1 year', '2 year', '3 year'],
                placeholder: 'periodPh.label'
            },
            {
                label: t('linkType.label'),
                type: 'selectDropdown',
                stateVar: `linkType`,
                options: ['Fiber', 'Wireless', 'VSAT'],
                placeholder: 'linkTypePh.label'
            },
            {
                label: t('serviceType.label'),
                type: 'selectDropdown',
                stateVar: `serviceType`,
                options: ['SD-WAN', 'Security', 'BYOVNF'],
                placeholder: 'serviceTypePh.label'
            }
        ];
    }
    return [
        {
            label: t('siteName.label'),
            type: 'string',
            stateVar: `siteName`,
            placeholder: 'siteNamePh.label'
        },
        ...inputArr
    ];
};

const OrganisationDetails = ({ t }) => {

    const history = useHistory();
    const id = getParam();

    const name = window.location.search.split('?')[1].replace(/%20/g, ' ');
    const email = window.location.search.split('?')[2];
    const [recentTicketsSearched, setRecentTicketsSearched] = useState(false);
    const [status, setStatus] = useState('');
    const [page, setPage] = useState(1);
    const [priority, setPriority] = useState('');
    const [subscription, setSubscription] = useState('');
    const [updatedRecentTickets, setUpdatedRecentTickets] = useState([]);
    const [updatedRecentTicketsCount, setUpdatedRecentTicketsCount] = useState([]);
    const [openSelectNodeModal, setOpenSelectNodeModal] = useState(false);
    const [activateCameraId, setActivateCameraId] = useState('');
    const [activateSubscriptionId, setActivateSubscriptionId] = useState('');
    const [submitNodeBtnLoading, setSubmitNodeBtnLoading] = useState(false);
    const [
        openConnectivityInfoModalState,
        setOpenConnectivityInfoModalState
    ] = useState(false);
    const [
        openNigeriaActivateModalState,
        setOpenNigeriaActivateModalState
    ] = useState(false);
    const [connectivityInfoLoading, setConnectivityInfoLoading] = useState(false);
    const [connectivityInfoData, setConnectivityInfoData] = useState('');
    const [assignUserModalOpen, setAssignUserModalOpen] = useState(false);
    const [assignUserList, setAssignUserList] = useState([]);
    const [filterdAssignUserList, setFilterdAssignUserList] = useState([]);
    const [assignUserLoading, setAssignUserLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [assignUserRoleFilter, setAssignUserRoleFilter] = useState("");
    const [assignSearchVal, setAssignSearchVal] = useState("");
    const [editSubscriptionModal, setEditSubscriptionModal] = useState({ open: false, btnLoading: false, clearData: false, data: [] });
    const [state, setState] = useState({});
    const [locationState, setLocationState] = useState({});
    const [inputArr, setInputArr] = useState([]);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [selectOrgList, setSelectOrgList] = useState([]);
    const [clearModal, setClearModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [assignSubsToUserModalOpen, setAssignSubsToUserModalOpen] = useState(false);
    const [assignSubscriptionId, setAssignSubscriptionId] = useState(null);

    useEffect(() => {
        const role = localStorage.getItem('role');
        localStorage.removeItem('timeZoneDetails');
        if (
            role.includes(USER_ROLES.IP_SUPERADMIN) ||
            role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
            role.includes(USER_ROLES.CSOC_MANAGER) ||
            role.includes(USER_ROLES.SD_READONLY) ||
            role.includes(USER_ROLES.CSOC) ||
            role.includes(USER_ROLES.THIRD_PARTY_VENDOR) ||
            role.includes(USER_ROLES.SERVICE_MANAGER)
        ) {
            permisson = true;
        }
    }, []);
    /*
        List Queries
    */
    const {
        loading: activeLoading,
        error: activeError,
        data: activeSubscriptions,
        refetch: reloadActiveSubscription
    } = useQuery(GET_SUBSCRIPTION_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            status: SUBSCRIPTION_STATUS.ACTIVE,
            ownerId: id
        }
    });

    const { data: teamList } = useQuery(GET_INVITE_MEMBERS, {
        fetchPolicy: "no-cache",
        variables: {
            orgId: id
        }
    });

    const {
        loading: pendingLoading,
        error: pendingError,
        data: pendingSubscriptions,
        refetch: reloadPendingSubscriptions
    } = useQuery(GET_SUBSCRIPTION_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            status: SUBSCRIPTION_STATUS.PENDING,
            ownerId: id
        }
    });
    const {
        loading: serviceTicketLoading,
        error,
        data: allServiceticketsWithLimit
    } = useQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
        variables: {
            ownerId: id,
            page: page,
            listType: 'orgTicketList'
        }
    });
    const {
        loading: membersLoading,
        error: membersError,
        data: membersData,
        refetch: reloadMembers
    } = useQuery(GET_MEMBERS, {
        fetchPolicy: "no-cache",
        variables: {
            orgId: id
        }
    });
    const {
        loading: subscriptionsLoading,
        error: subscriptionsError,
        data: subscriptionList
    } = useQuery(GET_SUBSCRIPTION_LIST, {
        variables: {
            ownerId: id
        }
    });
    const {
        loading: roleLoading,
        error: roleError,
        data: roleList
    } = useQuery(GET_ALL_ROLES);

    const { loading: orgLoading } = useQuery(
        ORGANISATIONS_LIST_FOR_SELECT,
        {
            onCompleted: data => {
                setSelectOrgList(
                    data.getOrganizationsList ? data.getOrganizationsList : []
                );
            }
        }
    );

    const [deleteSubscription] = useMutation(DELETE_PENDING_SUBSCRIPTION_BY_ID, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Pendding subscription deleted successfully.');
            reloadPendingSubscriptions();
        }
    });
    const filterListRecentTickets = [
        {
            label: 'Status',
            iconComponent: CheckCircle,
            handleChange: evt => {
                const statusVal = evt.target.value;
                getRecentTicketFilteredList({
                    variables: {
                        status: statusVal,
                        ownerId: id,
                        page: page,
                        ...(priority ? { priority } : {})
                    }
                });
                setStatus(statusVal);
            },
            val: status,
            options: [STATUS_TYPES.OPEN, STATUS_TYPES.CLOSED]
        },
        {
            label: 'Priority',
            iconComponent: Flag,
            handleChange: evt => {
                const priorityVal = evt.target.value;
                getRecentTicketFilteredList({
                    variables: {
                        priority: priorityVal,
                        ownerId: id,
                        page: page,
                        ...(status ? { status } : {})
                    }
                });
                setPriority(priorityVal);
            },
            val: priority,
            options: [
                TICKET_PRIORITY.HIGH,
                TICKET_PRIORITY.MEDIUM,
                TICKET_PRIORITY.LOW
            ]
        },
        {
            label: 'Subscription',
            iconComponent: DNS,
            handleChange: evt => {
                const subscriptionVal = evt.target.value;
                getRecentTicketFilteredList({
                    variables: {
                        subscriptionId: subscriptionVal,
                        ownerId: id,
                        page: page,
                        ...(priority ? { priority } : {}),
                        ...(status ? { status } : {})
                    }
                });
                setSubscription(subscriptionVal);
            },
            val: subscription,
            options:
                !subscriptionsError && !subscriptionsLoading
                    ? getSubscriptionOptions(subscriptionList.getAllSubscriptions)
                    : [],
            styleOverrides: { minWidth: '172px' }
        }
    ];
    const [updateSubscriptionStatus] = useMutation(UPDATE_SUBSCRIPTION, {
        onCompleted: data => {
            setActivateCameraId('');
            setSubmitNodeBtnLoading(false);
            setOpenSelectNodeModal(false);
            window.location.reload();
        },
        onError: e => {
            setActivateCameraId('');
            setSubmitNodeBtnLoading(false);
            setOpenSelectNodeModal(false);
            console.log(e);
        }
    });
    const [getRecentTicketFilteredList] = useLazyQuery(GET_ALL_SERVICE_TICKETS_LIMIT, {
        onCompleted: data => {
            setRecentTicketsSearched(true);
            setUpdatedRecentTickets(data.allServiceticketsWithLimit.data);
            setUpdatedRecentTicketsCount(data.allServiceticketsWithLimit.total)
        }
    });
    const handleSubscriptionActiveClick = data => {
        if (!permisson) {
            return null;
        }
        // if (
        //     data.service &&
        //     data.service.serviceType &&
        //     data.service.serviceType.name === SERVICE_TYPES.EDGE_AI
        // ) {
        //     setSubmitNodeBtnLoading(false);
        //     setOpenSelectNodeModal(true);
        //     setActivateCameraId(data.id);
        // } else {
        if (window.confirm('Are you sure? you want to active this subscription')) {
            let updateData = {
                id: data.id,
                status: SUBSCRIPTION_STATUS.ACTIVE
            }
            if (data.service && data.service.serviceType && (data.service.serviceType.name === SERVICE_TYPES.EDGE_AI || data.service.serviceType.name === SERVICE_TYPES.SC)) {
                updateData.mode = "unmanaged";
            }
            return updateSubscriptionStatus({
                variables: updateData
            });
        }
        // }
    };
    const handleOpenDiaConfigurationForm = data => {
        if (!permisson) {
            return null;
        }
        if (data && data.service && data.service.serviceType && data.service.serviceType.name ===
            SERVICE_TYPES.DIAN) {
            setActivateSubscriptionId(data.id);
            setOpenNigeriaActivateModalState(true);
            return null;
        }
        setConnectivityInfoLoading(true);
        setActivateSubscriptionId(data.id);
    };

    React.useEffect(() => {
        if (activateSubscriptionId) {
            return getSubscriptionById({
                variables: {
                    id: activateSubscriptionId
                }
            });
        }
    }, [activateSubscriptionId]);

    const handleSubscriptionItemClick = id => {
        const path = getUrl(SubscriptionPaths.PendingSubscriptionDetails, id);
        history.push({ pathname: path, search: `?history=organisations` });
        return null;
    };
    const handleMenuItemClick = (item, id) => {
        console.log("item ============", item);
        if (item.label === t('closeTicket.label')) {
        } else if (item.label === t('viewDetails.label')) {
            handleActiveSubscriptionItemClick(id);
        } else if (item.label === t('viewConfiguration.label')) {
            handleActiveSubscriptionItemClick(id, 1);
        } else if (item.label === t('openServiceTickets.label')) {
            handleActiveSubscriptionItemClick(id, 2);
        } else if (item.label === t('raiseNewTicket.label')) {
            history.push(ServiceTicketPaths.NewServiceTicket);
        } else if (item.label === t('edit.label')) {
            handleEditActiveSubscriptionClick(id);
        } else if (item.label === t('assignToUser.label')) {
            setAssignSubsToUserModalOpen(true);
            setAssignSubscriptionId(id);
        }
        return null;
    };
    const handleTicketItemClick = id => {
        const path = getUrl(ServiceTicketPaths.Details, id);
        history.push(path);
    };
    const handleTicketMenuItemClick = (item, id) => {
        if (item.label === t('closeTicket.label')) {
            // close ticket request
        } else if (item.label === t('viewDetails.label')) {
            handleTicketItemClick(id);
        }
    };
    const handleActiveSubscriptionItemClick = (id, activeIndex) => {
        const path = getUrl(SubscriptionPaths.ActiveSubscriptionDetails, id);
        history.push({
            pathname: path,
            ...(activeIndex ? { state: { activeIndex } } : {})
        });
        return null;
    };
    const handleEditActiveSubscriptionClick = (id) => {
        if (id) {
            return getSubscriptionByIdForEdit({
                variables: {
                    id
                }
            });
        }
    };
    const handleSubmitEditSubscription = async (data) => {
        setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: true, clearData: false });
        let sites = Object.keys(state).map((key) => state[key]);
        await updateSubscriptionAndSite({
            variables: {
                id: data.id,
                name: data.name,
                sites: sites
            }
        })
    };
    const [getSubscriptionByIdForEdit] = useLazyQuery(GET_SUBSCRIPTION_DETAILS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getSubscriptionById && data.getSubscriptionById.name) {
                setEditSubscriptionModal({ open: true, data: data.getSubscriptionById, btnLoading: false, clearData: false });
                setInputArr(getInputs(data.getSubscriptionById.service.name, t));
            }
            const { getSubscriptionById } = data;
            const { sites } = getSubscriptionById;
            let updatedSites = {};
            sites &&
                sites.length &&
                sites.forEach((item, index) => {
                    const configuration =
                        item.configuration && JSON.parse(item.configuration);
                    let confObj = {};
                    configuration.forEach((item, index) => {
                        confObj = { ...confObj, [item.key]: item.value };
                    });
                    updatedSites = {
                        ...updatedSites,
                        [`site${index + 1}`]: {
                            ...state[`site${index}`],
                            id: item.id,
                            siteName: item.name,
                            address: item.address,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            zipcode: item.zipcode,
                            landmark: item.landmark,
                            ...confObj
                        }
                    };
                });
            setState({ ...state, ...updatedSites });
        }
    });
    const [getRegionList, { data: regionList }] = useLazyQuery(GET_REGION_LIST);
    const { data: countryList } = useQuery(GET_COUNTRY_LIST);
    const [updateSubscriptionAndSite] = useMutation(UPDATE_SUBSCRIPTION_AND_SITES, {
        onError: error => {
            setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
            console.log('Error', error);
        },
        onCompleted: async data => {
            if (data.updateSubscriptionAndSites && data.updateSubscriptionAndSites.id != null) {
                Message.success("Subscription updateted successfully");
                // refresh subscrition list
                if (editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.ACTIVE) {
                    setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
                    reloadActiveSubscription();
                } else if (editSubscriptionModal.data.status == SUBSCRIPTION_STATUS.PENDING) {
                    setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
                    reloadPendingSubscriptions();
                }
            } else {
                setEditSubscriptionModal({ open: true, data: editSubscriptionModal.data, btnLoading: false, clearData: false });
            }
        }
    });

    const handleInputChange = (name, value, site) => {
        if (name === 'country') {
            getRegionList({ variables: { countryId: value } });
        }
        setState({ ...state, [site]: { ...state[site], [name]: value } });
    };

    const handleSetPosition = (pos, site) => {
        setLocationState({
            ...locationState,
            [site]: {
                latitude: pos.latitude,
                longitude: pos.longitude
            }
        });
    };

    const editMenuItems = [
        // { label: t('openSiteConfiguration.label') },
        { label: t('removeSite.label') }
    ];

    const handleEditMenuItemClick = (item, site) => { };

    const handleSearchRecentTickets = query => {
        if (query !== '') {
            const updatedList =
                allServiceticketsWithLimit.allServiceticketsWithLimit && allServiceticketsWithLimit.allServiceticketsWithLimit.data &&
                allServiceticketsWithLimit.allServiceticketsWithLimit.data.filter(
                    (item, index) =>
                        item.title.toLowerCase().includes(query.toLowerCase()) ||
                        item.ticketId.toLowerCase().includes(query.toLowerCase())
                );
            setRecentTicketsSearched(true);
            setUpdatedRecentTickets(updatedList);
        } else {
            setRecentTicketsSearched(false);
            setUpdatedRecentTickets([]);
        }
    };

    const handleSelectNodeSubmit = nodeType => {
        if (!nodeType && !activateCameraId) {
            Message.error('Invalid Request!');
            return null;
        }
        setSubmitNodeBtnLoading(true);
        updateSubscriptionStatus({
            variables: {
                id: activateCameraId,
                mode: nodeType,
                status: SUBSCRIPTION_STATUS.ACTIVE
            }
        });
    };
    const [getConnectivityInfo] = useLazyQuery(GET_CONNECTIVITY_INFO_DETAILS, {
        fetchPolicy: 'no-cache',
        onCompleted: response => {
            console.log('response', response);
            setConnectivityInfoData(response.getConnectivityInfoById);
            setOpenConnectivityInfoModalState(true);
            setConnectivityInfoLoading(false);
        }
    });
    const [getSubscriptionById] = useLazyQuery(
        GET_SUBSCRIPTION_DETAILS,
        {
            fetchPolicy: "no-cache",
            onCompleted: res => {
                if (
                    res.getSubscriptionById &&
                    res.getSubscriptionById.service &&
                    res.getSubscriptionById.service.serviceType &&
                    res.getSubscriptionById.service.serviceType.name ===
                    SERVICE_TYPES.DIA
                ) {
                    if (
                        res.getSubscriptionById.service &&
                        res.getSubscriptionById.service.serviceType &&
                        res.getSubscriptionById.service.serviceType.name ===
                        SERVICE_TYPES.DIA
                    ) {
                        getConnectivityInfo({
                            variables: {
                                opportunityId: res.getSubscriptionById.opportunityId
                            }
                        });
                    }
                }
            }
        }
    );
    const [getAssignUserList] = useLazyQuery(
        GET_ASSIGN_USERS,
        {
            fetchPolicy: "no-cache",
            onError: e => {
                setAssignUserList([]);
                setAssignUserLoading(false);
            },
            onCompleted: res => {
                let users = [];
                if (membersData && membersData.usersOfAnOrganization && membersData.usersOfAnOrganization.length) {
                    users = (res.getAssignUsers && res.getAssignUsers.length) ? res.getAssignUsers.filter(o => !membersData.usersOfAnOrganization.find(o2 => o.id === o2.id)) : [];
                } else {
                    users = res.getAssignUsers ? res.getAssignUsers : [];
                }
                if (assignSearchVal) {
                    const result = users.filter(elem => {
                        if ((elem && elem.firstName && elem.lastName && elem.email)) {
                            let name = elem.firstName + " " + elem.lastName;
                            if (name.includes(assignSearchVal.trim()) || elem.email.includes(assignSearchVal.trim())) {
                                return elem;
                            }
                        }
                    });
                    setFilterdAssignUserList(result);
                }
                setAssignUserList(users);
                setAssignUserLoading(false);
            }
        }
    );
    const [assignUserToGroup] = useMutation(ASSIGN_USERS_TO_GROUP, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            reloadMembers();
            setAssignUserModalOpen(false);
            setSelectedUsers([]);
            setAssignUserList([]);
            Message.success('Organisations successfully assigned to all selected users.');
        }
    });
    const handleAssignUserClick = () => {
        setAssignUserModalOpen(true);
        setAssignUserLoading(true);
        return getAssignUserList();
    }
    const handleSubmitAssignUser = () => {
        if (selectedUsers.length) {
            return assignUserToGroup({
                variables: {
                    users: selectedUsers,
                    groupId: id
                }
            });
        }
        return null;
    }
    const handleSelectAssignUser = (elem) => {
        setSelectedUsers(prevState => {
            let oldState = [...prevState];
            if (elem.target.checked) {
                oldState.push(elem.target.value);
            } else {
                oldState.splice(oldState.indexOf(elem.target.value), 1);
            }
            return oldState;
        });
    }
    const handleAssignUserSearch = (val) => {
        setAssignSearchVal(val);
        if (val) {
            const result = assignUserList.filter(elem => {
                if ((elem && elem.firstName && elem.lastName && elem.email)) {
                    let name = elem.firstName + " " + elem.lastName;
                    if (name.includes(val.trim()) || elem.email.includes(val.trim())) {
                        return elem;
                    }
                }
            });
            setFilterdAssignUserList(result);
        }
    }

    const [deleteUser] = useMutation(DELETE_USERS, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('User Deleted successfully.');
        }
    });

    const [updateUser] = useMutation(UPDATE_USERS, {
        onError: error => {
            setButtonLoading(false);
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('User updated successfully');
            setButtonLoading(false);
            setOpenUserModal(false);
            setClearModal(true);
            reloadMembers();
        }
    });

    const handleMemberActionClick = (type, user) => {
        if (!user) return null;
        console.log("user: ", user);
        if (type === 'delete') {
            if (window.confirm('Are you sure? you want to delete this user!')) {
                return deleteUser({
                    variables: {
                        id: user.id
                    }
                });
            }
        }
        if (type === 'edit') {
            if (user) {
                setOpenUserModal(true);
                setEditModalData({
                    ...user,
                    role: user.roles ? user.roles : "",
                    organisations: [id]
                });
            }
        }
        return user;
    }

    const handleUpdateUserSubmit = formData => {
        if (!formData.id) {
            return null;
        }
        setButtonLoading(true);
        return updateUser({
            variables: { ...formData }
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    return (
        <Layout breadcrumbList={breadcrumbList}>
            <Header title={name} subText={email} />
            {activeLoading && !activeError && (
                <LinearProgress
                    color="primary"
                    style={{
                        top: 153,
                        left: '0px',
                        position: 'absolute',
                        width: '100%',
                        zIndex: 9999
                    }}
                />
            )}
            <CustomTabs
                tabs={[
                    {
                        label: t('activeSubscriptions.label'),
                        children: (
                            <>
                                {!activeLoading && !activeError && activeSubscriptions && (
                                    <SubscriptionPanel
                                        subscriptionList={activeSubscriptions.getAllSubscriptions}
                                        type={SUBSCRIPTION_STATUS.ACTIVE}
                                        t={t}
                                        label={t('activeSubscriptions.label')}
                                        handleMenuItemClick={handleMenuItemClick}
                                        handleSubscriptionItemClick={
                                            handleActiveSubscriptionItemClick
                                        }
                                    />
                                )}
                            </>
                        )
                    },
                    {
                        label: t('pendingActivation.label'),
                        children: (
                            <>
                                {!pendingLoading && !pendingError && pendingSubscriptions && (
                                    <SubscriptionPanel
                                        subscriptionList={pendingSubscriptions.getAllSubscriptions}
                                        type={SUBSCRIPTION_STATUS.PENDING}
                                        t={t}
                                        label={t('pendingActivation.label')}
                                        handleMenuItemClick={handleMenuItemClick}
                                        handleSubscriptionItemClick={handleSubscriptionItemClick}
                                        handleSubscriptionActiveClick={
                                            handleSubscriptionActiveClick
                                        }
                                        handleOpenDiaConfigurationForm={
                                            handleOpenDiaConfigurationForm
                                        }
                                        permisson={permisson}
                                        deleteSubscription={deleteSubscription}
                                    />
                                )}
                            </>
                        )
                    },
                    {
                        label: t('serviceTickets.label'),
                        children: (
                            <>
                                {serviceTicketLoading && <LinearProgress color="primary" />}
                                {!serviceTicketLoading && !error && allServiceticketsWithLimit.allServiceticketsWithLimit.data && (
                                    <>
                                        <TicketPanel
                                            ticketList={
                                                recentTicketsSearched
                                                    ? updatedRecentTickets
                                                    : allServiceticketsWithLimit.allServiceticketsWithLimit.data
                                            }
                                            type={'recentTickets'}
                                            t={t}
                                            handleMenuItemClick={handleTicketMenuItemClick}
                                            filterList={filterListRecentTickets}
                                            handleTicketItemClick={handleTicketItemClick}
                                            handleSearch={handleSearchRecentTickets}
                                        />
                                        <Pagination color="secondary" size="large" style={{ float: "right", padding: "20px 0" }}
                                            count={recentTicketsSearched ? Math.ceil(updatedRecentTicketsCount / 30) : allServiceticketsWithLimit
                                                && allServiceticketsWithLimit.allServiceticketsWithLimit && allServiceticketsWithLimit.allServiceticketsWithLimit.total ?
                                                Math.ceil(allServiceticketsWithLimit.allServiceticketsWithLimit.total / 30) : 0} page={page} onChange={handlePageChange} />
                                    </>
                                )}

                            </>
                        )
                    },
                    {
                        label: t('members.label'),
                        children: (
                            <>
                                {!membersLoading && !membersError && membersData && (
                                    <MembersPanel
                                        membersList={membersData.usersOfAnOrganization}
                                        t={t}
                                        handleAssignUser={handleAssignUserClick}
                                        handleActionClick={handleMemberActionClick}
                                    />
                                )}
                            </>
                        )
                    },
                    {
                        label: t('billing.label'),
                        children: (
                            <>
                                {!activeLoading && !activeError && activeSubscriptions && (
                                    <OrganisationBilling
                                        subscriptionList={activeSubscriptions.getAllSubscriptions ? activeSubscriptions.getAllSubscriptions : []}
                                        organization={id}
                                        t={t}
                                    />
                                )}
                            </>
                        )
                    }
                ]}
            />
            {permisson && (
                <SelectNodeManagementModal
                    t={t}
                    handleSelectNodeSubmit={handleSelectNodeSubmit}
                    open={openSelectNodeModal}
                    handleClose={() => {
                        setActivateCameraId('');
                        setOpenSelectNodeModal(false);
                    }}
                    activateCameraId={activateCameraId}
                    btnLoading={submitNodeBtnLoading}
                />
            )}
            {permisson && !pendingLoading && !pendingError && pendingSubscriptions && (
                <DIAConfigurationDetailsPanel
                    t={t}
                    connectivityInfoData={connectivityInfoData}
                    activateSubscriptionId={activateSubscriptionId}
                    open={openConnectivityInfoModalState}
                    handleClose={() => {
                        setOpenConnectivityInfoModalState(false);
                    }}
                />
            )}
            {permisson && !pendingLoading && !pendingError && pendingSubscriptions && (
                <DIANConfigurationPanel
                    t={t}
                    activateSubscriptionId={activateSubscriptionId}
                    open={openNigeriaActivateModalState}
                    handleClose={() => {
                        setOpenNigeriaActivateModalState(false);
                    }}
                />
            )}
            {connectivityInfoLoading && (
                <LinearProgress
                    color="primary"
                    style={{
                        top: '98px',
                        left: '0px',
                        position: 'absolute',
                        width: '100%'
                    }}
                />
            )}
            <AssignSubscriptionToUserModal
                t={t}
                handleClose={() => {
                    setAssignSubsToUserModalOpen(false);
                    setAssignSubscriptionId(null);
                }}
                list={(teamList && teamList.inviteMembersAnOrganization) ? teamList.inviteMembersAnOrganization : []}
                open={assignSubsToUserModalOpen}
                handleSubmitAssignUser={handleSubmitAssignUser}
            />
            <AssignUserModal
                t={t}
                handleClose={() => {
                    setAssignUserModalOpen(false);
                    setAssignUserList([]);
                }}
                list={assignSearchVal ? filterdAssignUserList : assignUserList}
                open={assignUserModalOpen}
                loading={assignUserLoading}
                selectedUsers={selectedUsers}
                handleSelectUser={handleSelectAssignUser}
                handleSubmitAssignUser={handleSubmitAssignUser}
                handleSearchChange={handleAssignUserSearch}
                filterByRole={assignUserRoleFilter}
                roleList={(!roleLoading && !roleError && roleList.getAllRoles && roleList.getAllRoles.RoleData) ? JSON.parse(roleList.getAllRoles.RoleData) : []}
                handleSelectRole={evt => {
                    setAssignUserRoleFilter(evt.target.value);
                    setAssignUserLoading(true);
                    return getAssignUserList({
                        variables: {
                            role: evt.target.value
                        }
                    });
                }}
            />
            <EditSubscriptionModal
                t={t}
                open={editSubscriptionModal.open}
                handleSubmitClick={handleSubmitEditSubscription}
                modalState={editSubscriptionModal}
                handleClose={() => {
                    setEditSubscriptionModal({ open: false, data: [], btnLoading: false, clearData: false });
                }}
                subscriptionData={editSubscriptionModal.data}
                state={state}
                inputArr={inputArr}
                handleMenuItemClick={handleEditMenuItemClick}
                menuItems={editMenuItems}
                handleSetPosition={handleSetPosition}
                handleInputChange={handleInputChange}
                countryList={countryList && countryList.getCountries}
                regionList={regionList && regionList.getRegionsByCountryId}
            />
            <EditUser
                open={openUserModal}
                handleClose={evt => setOpenUserModal(false)}
                handleSubmit={handleUpdateUserSubmit}
                organisations={selectOrgList}
                orgLoading={orgLoading}
                buttonLoading={buttonLoading}
                clearModal={clearModal}
                data={editModalData}
            />
        </Layout>
    );
};
export default withTranslation()(OrganisationDetails);
