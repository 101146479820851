import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
// import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel" la

            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));

export default function AnalyticsItem({ t, data, total, column, loading, startDate, endDate, error, handleSubmitProjectDetailList }) {
    const classes = useStyles();
    const tableRef = useRef(null);
    const header = ["Firstname", "Lastname", "Age"];
    const body = [
        ["Edison", "Padilla", 14],
        ["Cheila", "Rodrigez", 56],
    ];
    useEffect(() => { }, [data]);
    const onDownload = () => {
        // useDownloadExcel({
        //     currentTableRef: tableRef.current,
        //     filename: 'Users table',
        //     sheet: 'Users'
        // })
        // downloadExcel({
        //     fileName: "react-export-table-to-excel -> downloadExcel method",
        //     sheet: "react-export-table-to-excel",
        //     tablePayload: {
        //         header,
        //         body: [
        //             ["Edison", "Padilla", 14],
        //             ["Cheila", "Rodrigez", 56],
        //             ["Cheila", "Rodrigez", 56],
        //             ["Cheila", "Rodrigez", 56],
        //             ["Cheila", "Rodrigez", 56],
        //         ],
        //     },
        // });
    }

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: '100%',
                        padding: 20,
                        textAlign: 'center',
                        display: 'inline-block'
                    }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <React.Fragment>
                    <TableContainer className={classes.container} component={Paper}>
                        <Table className={classes.table} aria-label="customized table" ref={tableRef}>
                            <TableHead>
                                <TableRow>
                                    {column &&
                                        column.map(column => (
                                            <TableCell>{column.label}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && !error ?
                                    (data && data.analytics) ?
                                        <React.Fragment>
                                            {(data.analytics && data.analytics.project_sales) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Total Sales</TableCell>
                                                        {Object.entries(data.analytics.project_sales).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.sales_team_nrc ? parseInt(val.sales_team_nrc) : 0) + (val.sales_team_mrc ? parseInt(val.sales_team_mrc) : 0) + (val.sales_team_arc ? parseInt(val.sales_team_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                                {/* <TableCell>{(val.sales_team_nrc ? parseInt(val.sales_team_nrc) : 0) + (val.sales_team_mrc ? parseInt(val.sales_team_mrc) : 0) + (val.sales_team_arc ? parseInt(val.sales_team_arc) : 0)}</TableCell> */}
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total MRC Sales</TableCell>
                                                        {Object.entries(data.analytics.project_sales).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.sales_team_mrc && val.sales_team_mrc ? parseInt(val.sales_team_mrc).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total NRC Sales</TableCell>
                                                        {Object.entries(data.analytics.project_sales).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.sales_team_nrc && val.sales_team_nrc ? parseInt(val.sales_team_nrc).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_installed) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Total Projects Installed</TableCell>
                                                        {Object.entries(data.analytics.project_installed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.install_team_nrc ? parseInt(val.install_team_nrc) : 0) + (val.install_team_mrc ? parseInt(val.install_team_mrc) : 0) + (val.install_team_arc ? parseInt(val.install_team_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total MRC Installed</TableCell>
                                                        {Object.entries(data.analytics.project_installed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.install_team_mrc && val.install_team_mrc ? parseInt(val.install_team_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total NRC Installed</TableCell>
                                                        {Object.entries(data.analytics.project_installed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.install_team_nrc && val.install_team_nrc ? parseInt(val.install_team_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_billed) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Total Billed</TableCell>
                                                        {Object.entries(data.analytics.project_billed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.finance_team_nrc ? parseInt(val.finance_team_nrc) : 0) + (val.finance_team_mrc ? parseInt(val.finance_team_mrc) : 0) + (val.finance_team_arc ? parseInt(val.finance_team_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total MRC Billed</TableCell>
                                                        {Object.entries(data.analytics.project_billed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.finance_team_mrc && val.finance_team_mrc ? parseInt(val.finance_team_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Total NRC Billed</TableCell>
                                                        {Object.entries(data.analytics.project_billed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.finance_team_nrc && val.finance_team_nrc ? parseInt(val.finance_team_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_in_wip) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in WIP</TableCell>
                                                        {Object.entries(data.analytics.project_in_wip).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.pending_for_billing_nrc ? parseInt(val.pending_for_billing_nrc) : 0) + (val.pending_for_billing_mrc ? parseInt(val.pending_for_billing_mrc) : 0) + (val.pending_for_billing_arc ? parseInt(val.pending_for_billing_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in WIP MRC</TableCell>
                                                        {Object.entries(data.analytics.project_in_wip).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.pending_for_billing_mrc && val.pending_for_billing_mrc ? parseInt(val.pending_for_billing_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in WIP NRC</TableCell>
                                                        {Object.entries(data.analytics.project_in_wip).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.pending_for_billing_nrc && val.pending_for_billing_nrc ? parseInt(val.pending_for_billing_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_pending_for_billing) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in Billing</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in Billing MRC</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Pending in Billing NRC</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                        <TableCell>TBD</TableCell>
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                                // <React.Fragment>
                                                //     <StyledTableRow>
                                                //         <TableCell>Pending in Billing</TableCell>
                                                //         {Object.entries(data.analytics.project_pending_for_billing).map(([key, val]) => (
                                                //             <React.Fragment>
                                                //                 <TableCell>{(val.pending_for_billing_nrc ? parseInt(val.pending_for_billing_nrc) : 0) + (val.pending_for_billing_mrc ? parseInt(val.pending_for_billing_mrc) : 0) + (val.pending_for_billing_arc ? parseInt(val.pending_for_billing_arc) : 0)}</TableCell>
                                                //             </React.Fragment>
                                                //         ))}
                                                //     </StyledTableRow>
                                                //     <StyledTableRow>
                                                //         <TableCell>Pending in Billing MRC</TableCell>
                                                //         {Object.entries(data.analytics.project_pending_for_billing).map(([key, val]) => (
                                                //             <React.Fragment>
                                                //                 <TableCell>{val.pending_for_billing_mrc && val.pending_for_billing_mrc ? parseInt(val.pending_for_billing_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                //             </React.Fragment>
                                                //         ))}
                                                //     </StyledTableRow>
                                                //     <StyledTableRow>
                                                //         <TableCell>Pending in Billing NRC</TableCell>
                                                //         {Object.entries(data.analytics.project_pending_for_billing).map(([key, val]) => (
                                                //             <React.Fragment>
                                                //                 <TableCell>{val.pending_for_billing_nrc && val.pending_for_billing_nrc ? parseInt(val.pending_for_billing_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                //             </React.Fragment>
                                                //         ))}
                                                //     </StyledTableRow>
                                                //     <br />
                                                // </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_installed_but_not_billed) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Installed but unbilled</TableCell>
                                                        {Object.entries(data.analytics.project_installed_but_not_billed).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.sales_team_nrc ? parseInt(val.sales_team_nrc) : 0) + (val.sales_team_mrc ? parseInt(val.sales_team_mrc) : 0) + (val.sales_team_arc ? parseInt(val.sales_team_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Installed but unbilled MRC</TableCell>
                                                        {Object.entries(data.analytics.project_lost).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.sales_team_mrc && val.sales_team_mrc ? parseInt(val.sales_team_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Installed but unbilled NRC</TableCell>
                                                        {Object.entries(data.analytics.project_lost).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.sales_team_nrc && val.sales_team_nrc ? parseInt(val.sales_team_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_lost) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Projects Lost</TableCell>
                                                        {Object.entries(data.analytics.project_lost).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{((val.project_lost_nrc ? parseInt(val.project_lost_nrc) : 0) + (val.project_lost_mrc ? parseInt(val.project_lost_mrc) : 0) + (val.project_lost_arc ? parseInt(val.project_lost_arc) : 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Projects Lost MRC</TableCell>
                                                        {Object.entries(data.analytics.project_lost).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.project_lost_mrc && val.project_lost_mrc ? parseInt(val.project_lost_mrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <TableCell>Projects Lost NRC</TableCell>
                                                        {Object.entries(data.analytics.project_lost).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{val.project_lost_nrc && val.project_lost_nrc ? parseInt(val.project_lost_nrc).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0}) : '$0'}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_average_install_team) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Avg Install Time</TableCell>
                                                        {Object.entries(data.analytics.project_average_install_team).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{(val ? parseInt(val) : '0')}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                            {(data.analytics && data.analytics.project_average_bill_team) &&
                                                <React.Fragment>
                                                    <StyledTableRow>
                                                        <TableCell>Avg Billing Time</TableCell>
                                                        {Object.entries(data.analytics.project_average_bill_team).map(([key, val]) => (
                                                            <React.Fragment>
                                                                <TableCell>{(val ? parseInt(val) : '0')}</TableCell>
                                                            </React.Fragment>
                                                        ))}
                                                    </StyledTableRow>
                                                    <br />
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                        :
                                        <TableRow>
                                            <TableCell colSpan={column.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </>
    );
}