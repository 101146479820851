import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import InqLogo from '../../assets/icons/inq_logo.png';
import { ProfileContext } from '../../contexts/ProfileProvider';
import InqLogo from '../../assets/icons/inq_Edge_Gateway_Logo_WBG.png';
import InqExLogo from '../../assets/icons/inq. Control Platform.png';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../Breadcrumb';
import {
  InternalPaths,
  InternalUser,
  Paths,
  TicketingSystem,
  ServiceManager,
  ServiceTicketPaths
} from '../../routes/routePaths';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MenuWrapper from '../MenuWrapper';
import i18n from './../../i18n';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { USER_ROLES, COUNTRY_LIST } from '../../utils/constants';
import Grid from '@material-ui/core/Grid';
import { Icon } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import commonStyles from '../../theme/commonStyles';
import { marginGenerator } from '../../theme/utils';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_USER_INFO } from '../../containers/Profile/queries';
import Avatar from '../Avatar';
import { colors } from '../../theme/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { LOGOUT } from '../../containers/commonQueries';
import { onLogout, checkUserSettingAccess } from '../../utils';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { dateFormatterLLLHHMMSS } from '../../utils';
import ImgAvatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  logo: {
    height: '20px',
    marginLeft: '25px'
  },
  dynamicLogo: {
    height: 50,
    borderRadius: 10,
    marginLeft: 40,
    backgroundColor: "#ffff"
  },
  primaryMenu: {
    display: 'flex',
    position: 'absolute'
  },
  serviceTicketMenu: {
    display: 'flex',
    position: 'absolute',
    marginLeft: '20px'
  },
  primaryOptions: {
    marginRight: '20px',
    cursor: 'pointer',
    fontSize: '15px'
  },
  primaryIntOptions: {
    cursor: 'pointer',
    fontSize: '15px',
    color: "white",
    fontWeight: 'lighter'
  },
  secondaryOptions: {
    cursor: 'pointer',
    color: 'white'
  },
  secondaryMenu: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    height: '100%'
  },
  selector: {
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    color: theme.palette.common.white
  },
  secondaryNavItem: {
    borderLeft: `1px solid ${fade(theme.palette.common.white, 0.2)}`,
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 3)
  },
  primaryNavItems: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 1.2)
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  notiIcon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    margin: 13
  },
  notif_heading: {
    borderLeft: "2px solid #5a1f2a",
    paddingLeft: 10,
    marginLeft: 10
  },
  notiListRoot: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  notiItemTxt: {
    '&:hover': {
      color: theme.palette.secondary
    }
  },
  logoCenter: {
    // transform: 'translate(-13rem, 0px)'
    width: "100%",
    textAlign: "center",
    margin: "auto",
    position: "relative"
  },
  rightMenu: {
    display: 'flex',
    position: "absolute",
    top: 36,
    right: 0
  },
  itemName: {
    color: theme.palette.common.white
  }
}));

const primaryOptions = [
  {
    label: 'dashboard.label',
    link: Paths.Dashboard
  },
  {
    label: 'subscriptions.label',
    link: Paths.Subscriptions
  },
  {
    label: 'serviceTickets.label',
    link: Paths.ServiceTickets
  },
  {
    label: 'pmoDashboard.label',
    link: InternalPaths.pmoDashboard
  },
  {
    label: 'biDashboard.label',
    link: InternalPaths.biDashboard
  },
  {
    label: 'workflowManagement.label',
    link: InternalPaths.workflowManagement
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
  // {
  //   label: 'support.label',
  //   link: '/support'
  // }
  // {
  //   label: 'billings.label',
  //   link: '/billings'
  // }
];
const internalOptions = [
  {
    label: 'dashboard.label',
    link: InternalPaths.Dashboard
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'users.label',
    link: InternalPaths.users
  },
  // {
  //   label: 'customers.label',
  //   link: InternalPaths.customers
  // },
  {
    label: 'countries.label',
    link: InternalPaths.countries
  },
  {
    label: 'vnfMarketplace.label',
    link: InternalPaths.vnfMarketPlace
  },
  {
    label: 'servicesAndProducts.label',
    link: InternalPaths.servicesAndProducts
  },
  {
    label: 'pmoDashboard.label',
    link: InternalPaths.pmoDashboard
  },
  {
    label: 'biDashboard.label',
    link: InternalPaths.biDashboard
  },
  {
    label: 'workflowManagement.label',
    link: InternalPaths.workflowManagement
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
];
const cntryAdminOptions = [
  {
    label: 'dashboard.label',
    link: InternalPaths.Dashboard
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'users.label',
    link: InternalPaths.users
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
  // {
  //   label: 'customers.label',
  //   link: InternalPaths.customers
  // }
];

const serviceManagerOptions = [
  {
    label: 'vendors.label',
    link: ServiceManager.Vendors
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'incidentOnly.label',
    link: ServiceManager.IncidentType
  },
  {
    label: 'incidentGroup.label',
    link: ServiceManager.IncidentTypeGroup
  },
  {
    label: 'agent.label',
    link: ServiceManager.AdditionalAgent
  },
  {
    label: 'ngList.label',
    link: ServiceManager.NGList
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
  // {
  //   label: 'users.label',
  //   link: ServiceManager.Users
  // },
  // {
  //   label: 'customers.label',
  //   link: ServiceManager.Customers
  // }
];

const normalUserOption = [
  {
    label: 'dashboard.label',
    link: Paths.Dashboard
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
];

const ticketAdminOptions = [
  {
    label: 'dashboard.label',
    link: TicketingSystem.Dashboard
  },
  {
    label: 'serviceTickets.label',
    link: Paths.TicketSystem
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'knowladge.label',
    link: TicketingSystem.KnowladgeBase
  },
  {
    label: 'customers.label',
    link: TicketingSystem.Customers
  },
  {
    label: 'analytics.label',
    link: TicketingSystem.Analytics
  },
  {
    label: 'upload.label',
    link: TicketingSystem.Upload
  },
  {
    label: 'history.label',
    link: TicketingSystem.History
  },
  {
    label: 'otherServices.label',
    link: Paths.OtherServices
  }
  // {
  //   label: 'changeManagement.label',
  //   link: TicketingSystem.CM
  // }
];
function Navbar({ history, breadcrumbList, type, t, headerNotifications, handleSetImageModalSrc, handleNotificationClick }) {
  let emailDomain = localStorage.getItem('domain')
  const { setProfileData, resetProfile } = useContext(ProfileContext);

  const { data, loading, error } = useQuery(GET_USER_INFO);
  const menuItems = [
    { label: t('english.label'), value: 'en' },
    { label: t('french.label'), value: 'fr' }
  ];
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const path = window.location.pathname;
  const role = localStorage.getItem('role');
  const orgName = localStorage.getItem('orgName');
  const country = localStorage.getItem('country');

  const optionList =
    role && role.includes(USER_ROLES.IP_SUPERADMIN)
      ? internalOptions
      : role.includes(USER_ROLES.IP_COUNTRYADMIN)
        ? cntryAdminOptions
        : role.includes(USER_ROLES.CSOC_MANAGER) ||
          role.includes(USER_ROLES.CSOC) ||
          role.includes(USER_ROLES.SD_READONLY) ||
          role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
          ? ticketAdminOptions
          : role.includes(USER_ROLES.SERVICE_MANAGER)
            ? serviceManagerOptions
            : role.includes(USER_ROLES.IP_NUSER)
              ? normalUserOption
              : primaryOptions;

  const isEadminRole = checkUserSettingAccess(role);
  const [profileOpen, setProfileOpen] = useState(false);
  const [collabOpen, setCollabOpen] = useState(false);
  const [humanCapitalOpen, setHumanCapitalOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [financeOpen, setFinanceOpen] = useState(false);
  const [afterSalesOpen, setAfterSalesOpen] = useState(false);
  const [ticketOpen, setTicketOpen] = useState(false);
  const [salesOpen, setSalesOpen] = useState(false);
  const [showNotiIcon, setShowNotiIcon] = useState(false);
  const anchorRef = React.useRef(null);
  const ticketRef = React.useRef(null);
  const collabRef = React.useRef(null);
  const salesRef = React.useRef(null);
  const marketingRef = React.useRef(null);
  const deliveryRef = React.useRef(null);
  const financeRef = React.useRef(null);
  const afterSalesRef = React.useRef(null);
  const humanCapitalRef = React.useRef(null);
  const [notiAnchorEl, setNotiAnchorEl] = useState(null);

  React.useEffect(() => {
    if (history.location && history.location.pathname && history.location.pathname.includes("/subscriptions/active/details")) {
      setShowNotiIcon(true);
    } else {
      setShowNotiIcon(false);
    }
  }, []);

  const handleProfileCloseTicket = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setTicketOpen(false);
  };
  function handleListKeyDownTicket(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setTicketOpen(false);
    }
  }
  const handleProfileClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setProfileOpen(false);
    }
  }

  const ticketPrevOpen = React.useRef(ticketOpen);
  React.useEffect(() => {
    if (ticketPrevOpen.current === true && ticketOpen === false) {
      ticketRef.current.focus();
    }
    ticketPrevOpen.current = ticketOpen;
  }, [ticketOpen]);

  const handleToggleTicket = () => {
    setTicketOpen(ticketPrevOpen => !ticketPrevOpen);
  };

  const prevOpen = React.useRef(profileOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && profileOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = profileOpen;
  }, [profileOpen]);
  const handleToggle = () => {
    setProfileOpen(prevOpen => !prevOpen);
  };
  const [userLogout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      onLogout();
    }
  });

  const handleClickShowNotifications = (event) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleHideNotifications = () => {
    setNotiAnchorEl(null);
  };

  const open = Boolean(notiAnchorEl);
  const id = open ? 'simple-popover' : undefined;
  const logo_url = localStorage.getItem("logo_url");

  // Collaberation DropDown

  const prevCollabOpen = React.useRef(collabOpen);
  React.useEffect(() => {
    if (prevCollabOpen.current === true && collabOpen === false) {
      collabRef.current.focus();
    }
    prevCollabOpen.current = collabOpen;
  }, [collabOpen]);

  const handleToggleCollab = () => {
    setCollabOpen(prevCollabOpen => !prevCollabOpen);
  };
  const handleCollabClose = (event) => {
    if (collabRef.current && collabRef.current.contains(event.target)) {
      return;
    }
    setCollabOpen(false);
  }
  function handleCollabListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setCollabOpen(false);
    }
  }

  // Human Capital DropDown

  const prevHumanCapitalOpen = React.useRef(humanCapitalOpen);
  React.useEffect(() => {
    if (prevHumanCapitalOpen.current === true && humanCapitalOpen === false) {
      humanCapitalRef.current.focus();
    }
    prevHumanCapitalOpen.current = humanCapitalOpen;
  }, [humanCapitalOpen]);

  const handleToggleHumanCapital = () => {
    setHumanCapitalOpen(prevHumanCapitalOpen => !prevHumanCapitalOpen);
  };
  const handlebHumanCapitalClose = (event) => {
    if (humanCapitalRef.current && humanCapitalRef.current.contains(event.target)) {
      return;
    }
    setHumanCapitalOpen(false);
  }
  function handleHumanCapitalListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setHumanCapitalOpen(false);
    }
  }


  // Sales DropDown

  const prevSalesOpen = React.useRef(salesOpen);
  React.useEffect(() => {
    if (prevSalesOpen.current === true && salesOpen === false) {
      salesRef.current.focus();
    }
    prevSalesOpen.current = salesOpen;
  }, [salesOpen]);

  const handleToggleSales = () => {
    setSalesOpen(prevSalesOpen => !prevSalesOpen);
  };
  const handleSalesClose = (event) => {
    if (salesRef.current && salesRef.current.contains(event.target)) {
      return;
    }
    setSalesOpen(false);
  }
  function handleSalesListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSalesOpen(false);
    }
  }

  // Marketing DropDown

  const prevMarketingOpen = React.useRef(marketingOpen);
  React.useEffect(() => {
    if (prevMarketingOpen.current === true && marketingOpen === false) {
      marketingRef.current.focus();
    }
    prevMarketingOpen.current = marketingOpen;
  }, [marketingOpen]);

  const handleToggleMarketing = () => {
    setMarketingOpen(prevMarketingOpen => !prevMarketingOpen);
  };
  const handleMarketingClose = (event) => {
    if (marketingRef.current && marketingRef.current.contains(event.target)) {
      return;
    }
    setMarketingOpen(false);
  }
  function handleMarketingListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMarketingOpen(false);
    }
  }


  // Delivery DropDown

  const prevDeliveryOpen = React.useRef(deliveryOpen);
  React.useEffect(() => {
    if (prevDeliveryOpen.current === true && deliveryOpen === false) {
      deliveryRef.current.focus();
    }
    prevDeliveryOpen.current = deliveryOpen;
  }, [deliveryOpen]);

  const handleToggleDelivery = () => {
    setDeliveryOpen(prevDeliveryOpen => !prevDeliveryOpen);
  };
  const handleDeliveryClose = (event) => {
    if (deliveryRef.current && deliveryRef.current.contains(event.target)) {
      return;
    }
    setDeliveryOpen(false);
  }
  function handleDeliveryListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setDeliveryOpen(false);
    }
  }


  // Finance DropDown

  const prevFinanceOpen = React.useRef(financeOpen);
  React.useEffect(() => {
    if (prevFinanceOpen.current === true && financeOpen === false) {
      financeRef.current.focus();
    }
    prevFinanceOpen.current = financeOpen;
  }, [financeOpen]);

  const handleToggleFinance = () => {
    setFinanceOpen(prevFinanceOpen => !prevFinanceOpen);
  };
  const handleFinanceClose = (event) => {
    if (financeRef.current && financeRef.current.contains(event.target)) {
      return;
    }
    setFinanceOpen(false);
  }
  function handleFinanceListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setFinanceOpen(false);
    }
  }


  // After Sales DropDown

  const prevAfterSalesOpen = React.useRef(afterSalesOpen);
  React.useEffect(() => {
    if (prevAfterSalesOpen.current === true && afterSalesOpen === false) {
      afterSalesRef.current.focus();
    }
    prevAfterSalesOpen.current = afterSalesOpen;
  }, [afterSalesOpen]);

  const handleToggleAfterSales = () => {
    setAfterSalesOpen(prevAfterSalesOpen => !prevAfterSalesOpen);
  };
  const handleAfterSalesClose = (event) => {
    if (afterSalesRef.current && afterSalesRef.current.contains(event.target)) {
      return;
    }
    setAfterSalesOpen(false);
  }
  function handleAfterSalesListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAfterSalesOpen(false);
    }
  }
  useEffect(() => {
    if (data) {
      setProfileData(data);
    }
  }, [data, setProfileData]);

  const handlePrimaryMenuClick = (fun, link) => {
    fun(false)
    window.open(link, '_blank');
  }

  const handleAdminMenuClick = (fun) => {
    let link;
    fun(false)
    if (role && role.includes(USER_ROLES.IP_SUPERADMIN)) {
      link = InternalPaths.Dashboard
    } else {
      link = InternalPaths.Dashboard
    }
    window.open(link, '_blank')
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar variant="dense"
          style={{
            height: '96px',
            justifyContent: 'end',
            backgroundColor: '#661723'
          }}>
          <div className={classes.logoCenter}>
            <img
              src={type && type === 'internal' ? InqLogo : InqExLogo}
              style={{
                height: type && type === 'internal' ? '95px' : '120px'
              }}
            />
            <div className={classes.rightMenu}>
              <Grid
                item
                className={classes.secondaryNavItem}
                ref={anchorRef}
                aria-controls={profileOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <Avatar
                  name={
                    !loading && !error
                      ? `${data.userInfo.firstName}`
                      : t('profile.label')
                  }
                />
                <Typography
                  variant={'subtitle1'}
                  style={
                    path === Paths.Profile ? { color: colors.common.white } : {}
                  }
                  className={classes.secondaryOptions}>
                  {!loading && !error
                    ? `${data.userInfo.firstName}`
                    : t('profile.label')}
                </Typography>
              </Grid>
              <Popper
                open={profileOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: '999' }}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleProfileClose}>
                        <List
                          className={classes.root}
                          subheader={<li />}
                          autofocusitem={profileOpen ? 'true' : 'false'}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={() => history.push(Paths.Profile)}>
                            {t('profile.label')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => history.push(Paths.ChangePassword)}>
                            {t('resetPassword.label')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => history.push(Paths.ActivityLogs)}>
                            {t('activityLogs.label')}
                          </MenuItem>
                          {isEadminRole === true && (
                            <>
                              <ListSubheader>
                                {!loading && !error
                                  ? data.userInfo.organization.name
                                  : ''}
                              </ListSubheader>
                              <MenuItem
                                onClick={() => handlePrimaryMenuClick(setProfileOpen, `${Paths.Dashboard}`)}>
                                {t('switchToExternal.label')}
                              </MenuItem>
                              <MenuItem
                                onClick={() => history.push(Paths.Settings)}>
                                {t('settings.label')}
                              </MenuItem>
                            </>
                          )}
                          {role && (role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN)) ? (
                            <MenuItem
                              onClick={() => handleAdminMenuClick(setProfileOpen)}>
                              {t('switchToExternal.label')}
                            </MenuItem>
                          ) : null}
                          <MenuItem
                            onClick={() => {
                              resetProfile();
                              userLogout({
                                variables: {
                                  refreshToken: localStorage.getItem(
                                    'syncomRefreshToken'
                                  )
                                }
                              })
                            }
                            }
                          >
                            {t('logout.label')}
                          </MenuItem>
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Grid item className={classes.secondaryNavItem}>
                <Typography
                  variant={'subtitle1'}
                  onClick={evt => setAnchorEl(evt.currentTarget)}
                  className={clsx(classes.secondaryOptions, classes.selector)}>
                  {i18n.language === 'en'
                    ? t('english.label')
                    : t('french.label')}
                  <ArrowDropDownIcon />
                </Typography>
              </Grid>
              <Grid item className={classes.secondaryNavItem}>
                <Typography
                  variant={'subtitle1'}
                  onClick={() => {
                    window.open(ServiceTicketPaths.NewServiceTicket, '_blank');
                  }}
                  className={classes.secondaryOptions}>
                  {t('support.label')}
                </Typography>
              </Grid>
            </div>
          </div>
        </Toolbar>
        <Toolbar variant="dense" style={{ justifyContent: 'center' }}>
          <div style={{ marginLeft: type && type === 'internal' ? '10rem' : '0' }} className={classes.primaryMenu}>
            {emailDomain == 'inq.inc' ? (
              <>
                <Grid item className={classes.primaryNavItems}>
                  <Typography
                    variant={'subtitle1'}
                    onClick={() => history.push(InternalUser.Dashboard)}
                    className={classes.primaryIntOptions}>
                    {t('home.label')}
                  </Typography>
                </Grid>
                <Grid item className={classes.primaryNavItems}>
                  <Typography
                    variant={'subtitle1'}
                    onClick={() => history.push(InternalUser.Events)}
                    className={classes.primaryIntOptions}>
                    {t('events.label')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={collabRef}
                  aria-controls={collabOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleCollab}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('collaboration.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={collabOpen}
                  anchorEl={collabRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleCollabClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={collabOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleCollabListKeyDown}>
                            <MenuItem className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setCollabOpen, 'https://mail.google.com/mail/?tab=rm&authuser=0')}>
                              {t('rdMail.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setCollabOpen, 'https://drive.google.com/drive/u/0/my-drive')}>
                              {t('drive.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setCollabOpen, 'https://calendar.google.com/calendar/u/0/r')}>
                              {t('calendar.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setCollabOpen, 'https://meet.google.com/')}>
                              {t('meet.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={humanCapitalRef}
                  aria-controls={humanCapitalOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleHumanCapital}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('humanCapital.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={humanCapitalOpen}
                  anchorEl={humanCapitalRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handlebHumanCapitalClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={humanCapitalOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleHumanCapitalListKeyDown}>
                            <MenuItem className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setHumanCapitalOpen, 'https://secure.payspace.com/nextgen/')}>
                              {t('leave.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setHumanCapitalOpen, 'https://secure.payspace.com/nextgen/')}>
                              {t('payroll.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setHumanCapitalOpen, 'https://academy.inqed.io/')}>
                              {t('inqAcademy.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setHumanCapitalOpen, 'https://academy.inqed.io/?view=article:12ccde8bfdee3abb406569da3b815bbf')}>
                              {t('policies.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Grid item className={classes.primaryNavItems}>
                  <Typography
                    variant={'subtitle1'}
                    onClick={() => {
                      window.open('https://inq.my.salesforce.com/', '_blank');
                    }}
                    className={classes.primaryIntOptions}>
                    {t('sales.label')}
                  </Typography>
                </Grid>
                {/* <Grid
                item
                className={classes.primaryNavItems}
                ref={salesRef}
                aria-controls={salesOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggleSales}>
                <Typography
                  variant={'subtitle1'}
                  className={classes.primaryIntOptions}>
                    {t('sales.label')}
                </Typography>
              </Grid>
              <Popper
                open={salesOpen}
                anchorEl={salesRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: '999' }}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleSalesClose}>
                        <List
                          className={classes.root}
                          style={{backgroundColor:'#300B11', marginTop:'16px'}}
                          subheader={<li />}
                          autofocusitem={salesOpen ? 'true' : 'false'}
                          id="menu-list-grow"
                          onKeyDown={handleSalesListKeyDown}>
                          <MenuItem className={classes.itemName}
                            onClick={() => handlePrimaryMenuClick(setSalesOpen, 'https://inq.my.salesforce.com/')}>
                            {t('leadsManagement.label')}
                          </MenuItem>
                          <MenuItem
                            className={classes.itemName}
                            onClick={() => handlePrimaryMenuClick(setSalesOpen, 'https://inq.my.salesforce.com/')}>
                            {t('conf&price.label')}
                          </MenuItem>
                          <MenuItem
                            className={classes.itemName}
                            onClick={() => handlePrimaryMenuClick(setSalesOpen, 'https://inq.my.salesforce.com/')}>
                            {t('contractManagement.label')}
                          </MenuItem>
                          <MenuItem
                            className={classes.itemName}
                            onClick={() => handlePrimaryMenuClick(setSalesOpen, 'https://inq.my.salesforce.com/')}>
                            {t('salesForce.label')}
                          </MenuItem>
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper> */}
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={marketingRef}
                  aria-controls={marketingOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleMarketing}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('marketingBranding.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={marketingOpen}
                  anchorEl={marketingRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleMarketingClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={marketingOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleMarketingListKeyDown}>
                            <MenuItem className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setMarketingOpen, 'https://sites.google.com/inq.inc/hub/home')}>
                              {t('inqVault.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setMarketingOpen, 'https://pi.pardot.com/')}>
                              {t('pardot.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={deliveryRef}
                  aria-controls={deliveryOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleDelivery}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('delivery.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={deliveryOpen}
                  anchorEl={deliveryRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleDeliveryClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={deliveryOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleDeliveryListKeyDown}>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setDeliveryOpen, `${InternalPaths.pmoDashboard}`)}>
                              {t('projectManagement.label')}
                            </MenuItem>
                            {(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.BI_DASHBOARD_ADMIN)) &&
                              <MenuItem
                                className={classes.itemName}
                                onClick={() => handlePrimaryMenuClick(setDeliveryOpen, `${InternalPaths.biDashboard}`)}>
                                {t('biDashboard.label')}
                              </MenuItem>}
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setDeliveryOpen, 'https://inq.my.salesforce.com/')}>
                              {t('qualityAssurance.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setDeliveryOpen, 'https://6035754e1e074c1691b4fdbde8bf040c.europe-west2.gcp.elastic-cloud.com:9243/login?next=%2Fspaces%2Fspace_selector')}>
                              {t('supportElastic.label')}
                            </MenuItem>
                            {role && (role.includes(USER_ROLES.CSOC_MANAGER) || role.includes(USER_ROLES.CSOC))
                              && (!role.includes(USER_ROLES.IP_COUNTRYADMIN) && !role.includes(USER_ROLES.IP_SUPERADMIN)) ? (
                              <MenuItem
                                className={classes.itemName}
                                onClick={() => handlePrimaryMenuClick(setDeliveryOpen, `${TicketingSystem.Dashboard}`)}>
                                {t('serviceDeskSupport.label')}
                              </MenuItem>
                            ) : null}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={financeRef}
                  aria-controls={financeOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleFinance}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('finance.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={financeOpen}
                  anchorEl={financeRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleFinanceClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={financeOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleFinanceListKeyDown}>
                            <MenuItem className={classes.itemName} >
                              {t('invoicing.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setFinanceOpen, 'https://inq.cloud.sage.co.za/')}>
                              {t('payments.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setFinanceOpen, 'https://inq.cloud.sage.co.za:8443/')}>
                              {t('financials.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setFinanceOpen, 'https://drive.google.com/drive/folders/1J_1j0910TGtFNOV5ktGl6imeSrlAZuAK')}>
                              {t('sageManuals.label')}
                            </MenuItem>
                            {COUNTRY_LIST.NIGERIA === country &&
                              <MenuItem
                                className={classes.itemName}
                                onClick={() => handlePrimaryMenuClick(setFinanceOpen, `${InternalPaths.workflowManagement}`)}>
                                {t('cashAdvanceNigeria.label')}
                              </MenuItem>}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Grid
                  item
                  className={classes.primaryNavItems}
                  ref={afterSalesRef}
                  aria-controls={afterSalesOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleAfterSales}>
                  <Typography
                    variant={'subtitle1'}
                    className={classes.primaryIntOptions}>
                    {t('afterSales.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={afterSalesOpen}
                  anchorEl={afterSalesRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleAfterSalesClose}>
                          <List
                            className={classes.root}
                            style={{ backgroundColor: '#300B11', marginTop: '16px' }}
                            subheader={<li />}
                            autofocusitem={afterSalesOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleAfterSalesListKeyDown}>
                            <MenuItem className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setAfterSalesOpen, 'https://inq.my.salesforce.com/')}>
                              {t('dashboard.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setAfterSalesOpen, 'https://6035754e1e074c1691b4fdbde8bf040c.europe-west2.gcp.elastic-cloud.com:9243/login?next=%2Fspaces%2Fspace_selector')}>
                              {t('support.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setAfterSalesOpen, 'https://inq.cloud.sage.co.za/auth/login/page')}>
                              {t('supportX3.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setAfterSalesOpen, 'https://6035754e1e074c1691b4fdbde8bf040c.europe-west2.gcp.elastic-cloud.com:9243/login?next=%2Fspaces%2Fspace_selector')}>
                              {t('resources.label')}
                            </MenuItem>
                            <MenuItem
                              className={classes.itemName}
                              onClick={() => handlePrimaryMenuClick(setAfterSalesOpen, `${TicketingSystem.CM}`)}>
                              {t('cm.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            ) : optionList.map((item, index) => (
              <Typography
                variant={'subtitle1'}
                key={index}
                className={clsx(
                  classes.primaryOptions,
                  path.includes(item.link) && classes.active
                )}
                onClick={() => history.push(item.link)}>
                {t(item.label)}
              </Typography>
            ))
            }
            {/* {optionList.map((item, index) => (
              <Typography
                variant={'subtitle1'}
                key={index}
                className={clsx(
                  classes.primaryOptions,
                  path.includes(item.link) && classes.active
                )}
                onClick={() => history.push(item.link)}>
                {t(item.label)}
              </Typography>
            ))} */}
          </div>

          <div className={classes.secondaryMenu}>
            {showNotiIcon && <React.Fragment>
              <IconButton disabled={(headerNotifications && headerNotifications.count) ? false : true} onClick={handleClickShowNotifications} className={classes.notiIcon} aria-label="show 11 new notifications" color="secondary">
                <Badge max={99999} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent={headerNotifications && headerNotifications.count ? headerNotifications.count : 0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={notiAnchorEl}
                onClose={handleHideNotifications}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <List className={classes.notiListRoot}>
                  {(headerNotifications && headerNotifications.notifications && headerNotifications.notifications.length > 0) ? headerNotifications.notifications.map((item, index) => (
                    <>
                      <ListItem style={{ cursor: "pointer" }} alignItems="flex-start" key={index} onClick={handleNotificationClick}>
                        <ListItemAvatar>
                          <ImgAvatar
                            onClick={handleSetImageModalSrc}
                            style={{ cursor: 'pointer' }}
                            alt=""
                            src={item.image_url}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography
                                className={classes.notiItemTxt}
                                variant="subtitle2"
                                component="p"
                              >
                                Time {` -  ${dateFormatterLLLHHMMSS(item.created_at)}`}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className={classes.notiItemTxt}
                                style={{ paddingTop: 2 }}
                              >
                                Model {` - ${item.model_type ? item.model_type : "-"}`}
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                style={{ paddingTop: 2 }}
                                className={classes.notiItemTxt}
                              >
                                Cam {` - ${item.camera ? item.camera.name : "-"}`},
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                style={{ paddingTop: 2 }}
                                className={classes.notiItemTxt}
                              >
                                Place {` -  ${item.camera && item.camera.place ? item.camera.place.name : "-"}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  )) : null}
                </List>
              </Popover>
            </React.Fragment>
            }
            {country === 'null' && (
              <>
                <Grid
                  item
                  className={classes.secondaryNavItem}
                  ref={ticketRef}
                  aria-controls={ticketOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleTicket}>
                  <Typography
                    variant={'subtitle1'}
                    style={
                      path === Paths.Profile
                        ? { color: colors.common.white }
                        : {}
                    }
                    className={classes.secondaryOptions}>
                    {t('ticket.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={ticketOpen}
                  anchorEl={ticketRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener
                          onClickAway={handleProfileCloseTicket}>
                          <List
                            className={classes.root}
                            subheader={<li />}
                            autofocusitem={ticketOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDownTicket}>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.Dashboard)
                              }>
                              {t('dashboard.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() => history.push(Paths.TicketSystem)}>
                              {t('serviceTickets.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.KnowladgeBase)
                              }>
                              {t('knowladge.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.Analytics)
                              }>
                              {t('analytics.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            {/* <Grid item className={classes.secondaryNavItem}>
              <Typography
                variant={'subtitle1'}
                onClick={evt => setAnchorEl(evt.currentTarget)}
                className={clsx(classes.secondaryOptions, classes.selector)}>
                {i18n.language === 'en'
                  ? t('english.label')
                  : t('french.label')}
                <ArrowDropDownIcon />
              </Typography>
            </Grid> */}
            {/* {country !== 'null' && (
              <Grid item className={classes.secondaryNavItem}>
                <Icon
                  component={() => (
                    <LocationOn
                      className={commonClasses.iconRegular}
                      color={'inherit'}
                      style={marginGenerator('mr-8')}
                    />
                  )}
                  alt="location"
                />
                <Typography
                  variant={'subtitle1'}
                  className={classes.secondaryOptions}>
                  {country}
                </Typography>
              </Grid>
            )} */}
            {/* <Grid
              item
              className={classes.secondaryNavItem}
              ref={anchorRef}
              aria-controls={profileOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}>
              <Avatar
                name={
                  !loading && !error
                    ? `${data.userInfo.firstName}`
                    : t('profile.label')
                }
              />
              <Typography
                variant={'subtitle1'}
                style={
                  path === Paths.Profile ? { color: colors.common.white } : {}
                }
                className={classes.secondaryOptions}>
                {!loading && !error
                  ? `${data.userInfo.firstName}`
                  : t('profile.label')}
              </Typography>
            </Grid>
            <Popper
              open={profileOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: '999' }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleProfileClose}>
                      <List
                        className={classes.root}
                        subheader={<li />}
                        autofocusitem={profileOpen ? 'true' : 'false'}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={() => history.push(Paths.Profile)}>
                          {t('profile.label')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Paths.ChangePassword)}>
                          {t('resetPassword.label')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Paths.ActivityLogs)}>
                          {t('activityLogs.label')}
                        </MenuItem>
                        {isEadminRole === true && (
                          <>
                            <ListSubheader>
                              {!loading && !error
                                ? data.userInfo.organization.name
                                : ''}
                            </ListSubheader>
                            <MenuItem
                              onClick={() => history.push(Paths.Settings)}>
                              {t('settings.label')}
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => 
                            {
                              resetProfile();
                              userLogout({
                                variables: {
                                  refreshToken: localStorage.getItem(
                                    'syncomRefreshToken'
                                  )
                                }
                              })
                            }
                          }
                        >
                          Logout
                        </MenuItem>
                      </List>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}
          </div>
        </Toolbar>
        {type && type === 'external' && breadcrumbList && breadcrumbList.length && (
          <Breadcrumb type={type} breadcrumbList={breadcrumbList} />
        )}
      </AppBar>
      {anchorEl && (
        <MenuWrapper
          id={'ticket-menu'}
          anchorEl={anchorEl}
          handleClose={evt => {
            evt.stopPropagation();
            setAnchorEl(null);
          }}
          menuItems={menuItems}
          handleMenuItemClick={item => {
            setAnchorEl(null);
            return i18n.changeLanguage(item.value);
          }}
        />
      )}
    </div>
  );
}
Navbar.propTypes = {
  breadcrumbList: PropTypes.array.isRequired
};
export default withTranslation()(withRouter(Navbar));
