import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/inq_logo_primary.png';

const styles = StyleSheet.create({
    container: {
        // marginRight: 1,
        marginBottom: 2,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        height: 100,
        fontSize: 8,
    },
    leftBox: {
        padding: "7px",
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
    },
    rightContainer: {
        flexDirection: 'column',
        paddingTop: 30,
        paddingLeft: 0,
        paddingBottom: 0,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    logo: {
        width: '100%',
        height: '100%'
    },
    rightColumn: {
        flexDirection: 'column',
        width: '33%',
        marginLeft: 5,
        marginBottom: 5
    },
    floatRight: {
        // textAlign: 'right'
    },
    HeadingRed: {
        fontSize: 12,
        fontStyle: 'bold',
        textDecorationColor: 'Red',
        textAlign: 'center'
    },
    centerHeading: {
        fontStyle: 'bold',
    },
    leftColumn: {
        flexDirection: 'column',
        width: '33%',
        marginRight: 2
    },
    centerColumn: {
        width: '33%',
        padding: "7px",
        flexDirection: 'column',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
        borderColor: 'black',
        borderWidth: 1,
        marginRight: 3,
    }
});
const InvoiceHeaderMenu = ({ invoiceCompanyDetails }) => (
    <Fragment>
        <View style={styles.container}>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>{invoiceCompanyDetails.BPCSHO}          {invoiceCompanyDetails.BPRNAM}</Text>
                <Text>{invoiceCompanyDetails.XVBASITE}</Text>
                <Text>&nbsp;</Text>
                <Text>&nbsp;</Text>
                <Text>{invoiceCompanyDetails.BPRCRYNAM}</Text>
            </View>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>Attention:         {invoiceCompanyDetails.CNTFNA} {invoiceCompanyDetails.CNTLNA}</Text>
                <Text style={styles.centerHeading}>Telephone:       8090270934</Text>
                <Text style={styles.centerHeading}>Facsimile: </Text>
                <Text style={styles.centerHeading}>E-mail:             {invoiceCompanyDetails.ZCWEB}</Text>
                <Text style={styles.centerHeading}>Project Code: </Text>
                <Text style={styles.centerHeading}>Your Ref: </Text>
            </View>
            <View style={styles.centerColumn}>
                <Text style={styles.centerHeading}>Payable on or before the last day of the month by eletronic wire
                    transfer (NIBSS) at the following bank: </Text>
                <Text style={styles.centerHeading}>Bank:                 Guaranty Trust Bank</Text>
                <Text style={styles.centerHeading}>Address:            Adeola Odeku</Text>
                <Text style={styles.centerHeading}>Account No:       0006111361</Text>
                <Text style={styles.centerHeading}>Beneficiary:        inq.Digital Nigeria Limited</Text>
                <Text style={styles.centerHeading}>Reference:         NUM_0</Text>
            </View>
        </View>
        {/* <View style={styles.container}>
            <View style={styles.detailColumn}>
                <Text style={styles.name}>Luke Skywalker</Text>
                <Text style={styles.subtitle}>Jedi Master</Text>
            </View>
            <View style={styles.linkColumn}>
                <Link href="mailto:luke@theforce.com" style={styles.link}>
                    luke@theforce.com
                </Link>
            </View>
        </View> */}
    </Fragment>
);
export default InvoiceHeaderMenu