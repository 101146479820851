import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../theme/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { marginGenerator, paddingGenerator } from '../../../theme/utils';
import Timer from '@material-ui/icons/Timer';
import { SERVICE_TYPES, STATUS_TYPES } from '../../../utils/constants';
import { dateFormatterLLL } from '../../../utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuWrapper from '../../../components/MenuWrapper';
import { colors } from '../../../theme/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  iteContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    flexGrow: 1
  },
  uptimeContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));
const ActiveItem = ({
  t,
  data,
  handleSubscriptionItemClick,
  handleMenuItemClick,
  menuItems
}) => {
  const commonClasses = commonStyles();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Grid
      container
      style={{ ...paddingGenerator('pt-8'), ...paddingGenerator('pb-24') }}
      className={classes.iteContainer}>
      <Grid item>
        <Typography variant={'h4'}>
          {data.name
            ? `${data.name} - ${data.service.name}`
            : data.service.name}
        </Typography>
        {data.service.serviceType.name === SERVICE_TYPES.SDN ? (
          <Typography variant={'subtitle2'}>
            {data.sites ? data.sites.length : 0} {t('activeSites.label')} •{' '}
            {data.noOfCPEs || 0} {t('uCPE.label')}
          </Typography>
        ) : (
          <Typography variant={'subtitle2'}>
            {data.sites ? data.sites.length : 0} {t('activeSites.label')} •{' '}
            {data.noOfCPEs || 0} {t('cameras.label')}
          </Typography>
        )}
        <Grid
          container
          item
          style={marginGenerator('mt-16')}
          alignItems={'center'}>
          <Typography
            variant={'subtitle2'}
            className={classes.uptimeContainer}
            style={
              { color: colors.success.main }
              // data.avgUptime === 100
              //   ? { color: colors.success.main }
              //   : data.avgUptime < 100 && data.avgUptime > 50
              //   ? { color: colors.warning.main }
              //   : { color: colors.error.main }
            }>
            <Timer
              className={commonClasses.iconRegular}
              style={marginGenerator('mr-10')}
            />{' '}
            {100}% {t('uptime.label')}
          </Typography>
        </Grid>
        {data.status === STATUS_TYPES.CLOSED && (
          <Typography style={marginGenerator('mt-16')} variant={'subtitle2'}>
            {t('closedOn.label')} {dateFormatterLLL(data.updatedAt)}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs
        container
        justify={'flex-end'}
        alignItems={'flex-start'}
        style={marginGenerator('mt-8')}>
        {data.isDelete ? (
          <Button disabled={true} color={'secondary'} variant={'contained'}>
            Deleted
          </Button>
        ) : (
          <React.Fragment>
            <Button
              variant={'outlined'}
              onClick={() => handleSubscriptionItemClick(data)}>
              Add New Sites
            </Button>
          </React.Fragment>
        )}
      </Grid>
      {anchorEl && (
        <MenuWrapper
          id={'ticket-menu'}
          anchorEl={anchorEl}
          handleClose={evt => {
            evt.stopPropagation();
            setAnchorEl(null);
          }}
          menuItems={menuItems}
          handleMenuItemClick={item => {
            handleMenuItemClick(item, data.id);
            setAnchorEl(null);
          }}
        />
      )}
    </Grid>
  );
};
ActiveItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubscriptionItemClick: PropTypes.func.isRequired,
  menuItems: PropTypes.array,
  handleMenuItemClick: PropTypes.func
};
export default withTranslation()(ActiveItem);
