import {gql} from 'apollo-boost';
/*
  Create new subscription
 */
export const CREATE_NEW_SUBSCRIPTION = gql`
    mutation Subscription($serviceId: String!, $noOfSites: Int, $name: String) {
        createNewSubscription(
            serviceId: $serviceId
            noOfSites: $noOfSites
            name: $name
        ) {
            id
            noOfSites
        }
    }
`;
/*
  Add site to subscription
 */
export const ADD_SITE_TO_SUBSCRIPTION = gql`
    mutation Subscription(
        $name: String
        $subscriptionId: String
        $configuration: String
        $latitude: Float
        $longitude: Float
        $address: String
        $serviceType: String
        $regionId: String
        $countryId: String
        $zipcode: String
    ) {
        addNewSiteToSubscription(
            name: $name
            subscriptionId: $subscriptionId
            configuration: $configuration
            latitude: $latitude
            longitude: $longitude
            address: $address
            serviceType: $serviceType
            regionId: $regionId
            countryId: $countryId
            zipcode: $zipcode
        ) {
            id
        }
    }
`;
/*
  Update site
 */
export const UPDATE_SITE_DETAILS = gql`
    mutation Site(
        $id: String
        $name: String
        $configuration: String
        $latitude: Float
        $longitude: Float
        $address: String
        $serviceType: String
        $regionId: String
        $countryId: String
        $zipcode: String
    ) {
        updateNewSite(
            id: $id
            name: $name
            configuration: $configuration
            latitude: $latitude
            longitude: $longitude
            address: $address
            serviceType: $serviceType
            regionId: $regionId
            countryId: $countryId
            zipcode: $zipcode
        ) {
            id
        }
    }
`;
/*
  Place order
 */
export const PLACE_ORDER = gql`
    mutation Subscription($subscriptionId: String!, $siteIds: [String]!) {
        placeSubscriptionOrder(subscriptionId: $subscriptionId, siteIds: $siteIds) {
            id
        }
    }
`;
