import React from 'react';
import { marginGenerator } from '../../../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import TicketItem from '../CyberSecurityItem';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const CyberSecurityPanel = ({
    t,
    ticketList,
    loading,
    handleMenuItemClick,
    handleTicketItemClick
}) => {

    return (
        <div>
            <Typography
                variant={'subtitle2'}
                style={{
                    ...marginGenerator('mt-0'),
                    ...marginGenerator('mb-32')
                }}>
                {t('showing.label')} {ticketList && ticketList.length}{' '}
                {t('CMTickets.label')}
            </Typography>
            {loading ? (
                <LinearProgress color="secondary" />
            ) :
                ticketList && ticketList.length > 0 && ticketList.map((item, index) => (
                    <TicketItem
                        key={index}
                        data={item}
                        handleMenuItemClick={handleMenuItemClick}
                        handleTicketItemClick={handleTicketItemClick}
                    />
                ))
            }
        </div>
    );
};

CyberSecurityPanel.propTypes = {
    ticketList: PropTypes.array.isRequired
};

export default CyberSecurityPanel;
