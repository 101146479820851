import { gql } from 'apollo-boost';
/*
  Get all subscriptions
 */
export const GET_EDGE_AI_ANALYTICS_DATA = gql`
  query AnalyticsChart(
    $organization: String
    $cameraId: [String]
    $startTime: Float
    $endTime: Float
    $objectType: [String]
    $filterType: String
    $urlEndPoint: String
    $objectAnalyticsFilters: String
    $lines: [String]
    $activeReportMenu: String
    $officeTime: String
  ) {
    getAnalyticsChartData(
      organization: $organization
      cameraId: $cameraId
      startTime: $startTime
      endTime: $endTime
      objectType: $objectType
      filterType: $filterType
      urlEndPoint: $urlEndPoint
      objectAnalyticsFilters: $objectAnalyticsFilters
      lines: $lines
      activeReportMenu: $activeReportMenu
      officeTime: $officeTime
    ) {
      data
    }
  }
`;

export const GET_NOTIFICATION_REPORT_CHART = gql`
  query NotificationsReport(
    $organization: String
    $placeId: String
    $cameraId: String
    $modelType: String
    $dateTime: Int
    $endTime: Int
    $page: Int
    $tag: JSON
    $status: String
    $limit: Int
  ) {
    getNotificationsReportList(
      organization: $organization
      placeId: $placeId
      cameraId: $cameraId
      modelType: $modelType
      dateTime: $dateTime
      endTime: $endTime
      page: $page
      tag: $tag
      status: $status
      limit: $limit
    ) {
      count
      acknowledgedCount
      agreeCount
      disagreeCount
      notifications
    }
  }
`;

export const GET_DIRECTIONAL_LINE_CROSS_REPORT = gql`
  query DirectionalLineCrossReport(
    $organization: String
    $cameraId: String
    $dateTime: Int
    $endTime: Int
    $line_croods: JSON
    $direction: String
    $actionType: String
    $width: Int
    $height: Int
    $reportType: String
  ) {
    getDirectionalLineCrossReport(
      organization: $organization
      cameraId: $cameraId
      dateTime: $dateTime
      endTime: $endTime
      actionType: $actionType
      line_croods: $line_croods
      direction: $direction
      width: $width
      height: $height
      reportType: $reportType
    ) {
      Total
      Unique
      UniqueOut
      TotalOut
      actionType
    }
  }
`;

export const GET_PROCEED_NOTIFICATIONS_REPORT = gql`
  query ProceedNotificationReport(
    $organization: String
  ) {
    getProceedNotificationReport(
      organization: $organization
    ) {
      id
      startTime
      endTime
      agreedCount
      totalCount
      processedCount
      disagreedCount
    }
  }
`;

export const GET_NOTIFICATIONS_COUNTS_PER_DAY = gql`
  query NotificationsCountReport(
    $organization: String
    $cameraId: String
    $modelType: String
    $dateTime: Int
    $endTime: Int
    $status: String
  ) {
    getNotificationCountPerDay(
      organization: $organization
      cameraId: $cameraId
      modelType: $modelType
      dateTime: $dateTime
      endTime: $endTime
      status: $status
    ) {
      date
      total_count
      acknowledgedCount
      agreeCount
      disagreeCount
    }
  }
`;


export const GET_NOTIFICATIONS_EXCEL_REPORT = gql`
  query ProceedNotificationExcelReport(
    $organization: String
    $dateTime: Int
    $endTime: Int
    $status: String
    $limit: Int
  ) {
    getProceedNotificationExcelReport(
      organization: $organization
      dateTime: $dateTime
      endTime: $endTime
      status: $status
      limit: $limit
    ) {
      cameraName
      eventTime
      driverCell
      proceedTime
      processSteps
      proceedBy
    }
  }
`;

export const GET_SCHEDULE_REPORTS = gql`
    query ScheduleReport(
        $organization: String, 
        $page: Int,
        $limit: Int
    ) {
      getScheduleReport(
          organization: $organization
          page: $page
          limit: $limit
      ) {
        reports
        count
      }
    }
`;

export const GET_AGENT_WISE_REPORT = gql`
    query AgentWiseReport(
        $orgId: String, 
        $startDate: Int,
        $endDate: Int
    ) {
      getAgentWiseReport(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
      ) {
        success
        data
        message
      }
    }
`;

export const GET_PROCESSED_ALARM_COUNTS_REPORT = gql`
  query ProcessedAlarmCountReport(
        $orgId: String, 
        $startDate: Int,
        $endDate: Int
) {
  getProcessedAlarmCountReport(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
  ) {
    success
    message
    presigned_url
  }
}
`;