import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { USER_ORG_INFO } from '../../containers/commonQueries';
import {
  InternalPaths,
  Paths,
  TicketingSystem,
  InternalUser,
  ServiceManager
} from '../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import { GROUP_TYPES_KEYS, USER_ROLES } from '../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isOtherPlatform } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
}));

const LoginInProgress = ({ t }) => {
  const classes = useStyles();
  useQuery(USER_ORG_INFO, {
    variables: { orgId: localStorage.getItem('orgId'), action: 'login' },
    onCompleted: data => {
      const {
        name,
        countryId,
        country,
        userCountryId,
        userCountryName,
        type,
        subscriptionId
      } = data.userOrganizationInfo;
      let dnsLogo = localStorage.getItem('login_logo'); 
      localStorage.removeItem("login_logo");
      let emailDomain = localStorage.getItem('domain');
      const role = localStorage.getItem('role');
      if( isOtherPlatform() ){
        localStorage.setItem("logo_url", dnsLogo);
        localStorage.setItem("org_type", type);
      }
      if(subscriptionId){
        localStorage.setItem("user_subscription", subscriptionId);
      }
      if (role.includes(USER_ROLES.IP_COUNTRYADMIN) && userCountryId) {
        localStorage.setItem('countryId', userCountryId);
        localStorage.setItem(
          'country',
          userCountryName ? userCountryName : country
        );
      } else {
        localStorage.setItem(
          'countryId',
          userCountryId ? userCountryId : countryId
        );
        localStorage.setItem(
          'country',
          userCountryName ? userCountryName : country
        );
      }
      localStorage.setItem('orgName', name);
      let path;
      if(emailDomain === 'inq.inc'){
        path =  InternalUser.Dashboard
      } else if (role.includes(USER_ROLES.SERVICE_MANAGER)) {
        path = ServiceManager.Vendors;
      } else {
        path = role.includes(USER_ROLES.IP_SUPERADMIN) ? InternalPaths.Dashboard : Paths.Dashboard;
        let userPath = path;
        path =
          role.includes(USER_ROLES.CSOC_MANAGER) ||
          role.includes(USER_ROLES.CSOC) ||
          role.includes(USER_ROLES.SD_READONLY) ||
          role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
            ? TicketingSystem.Dashboard
            : userPath;
      }
      window.location.replace(path);
    },
    onError: error => {
      // console.log('error', error);
      window.location.replace('/');
    }
  });

  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default withTranslation()(LoginInProgress);
