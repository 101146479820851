import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid
} from '@material-ui/core';
import { renderText } from '../displayComponent';
import Popup from '../Popup';
import { useAuth } from '../../../contexts/AuthContext';
import { createPort } from '../../../services/portService';
import Message from '../../../components/Message';
import { useHistory } from 'react-router';

const userEmail = localStorage.getItem('email');

const Step3Port = ({ classes, formData, podId, handlePrev }) => {
  const { user } = useAuth();
  let history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);

  const handleSave = async e => {
    e.preventDefault();
    let payload;
    if (user?.email === 'testuser40@mail.com' && formData?.port2) {
      payload = {
        device_type: 'pswitch',
        pod_id: podId,
        name: formData?.portName,
        port_speed: formData?.portSpeed,
        minTerm: formData?.minTerm,
        status: 'Order',
        type: 'Port',
        high_availability: formData?.highAvailability,
        static_port1_num: formData?.port1,
        static_port2_num: formData?.port2
      };
    } else if (
      user?.email === 'testuser40@mail.com' &&
      formData?.port2 === null
    ) {
      payload = {
        device_type: 'pswitch',
        pod_id: podId,
        name: formData?.portName,
        port_speed: formData?.portSpeed,
        minTerm: formData?.minTerm,
        status: 'Order',
        type: 'Port',
        high_availability: formData?.highAvailability,
        static_port1_num: formData?.port1
      };
    } else {
      payload = {
        device_type: 'pswitch',
        pod_id: podId,
        name: formData?.portName,
        port_speed: formData?.portSpeed,
        minTerm: formData?.minTerm,
        status: 'Order',
        type: 'Port',
        high_availability: formData?.highAvailability
      };
    }
    const res = await createPort(payload);
    if (res?.status === 200 || res?.statusCode === 200) {
      Message.success('Port Created Successfully');
      history.push('/fabricService/Services');
    } else {
      Message.error(
        res?.message ||
          res?.response?.data?.message ||
          res?.data?.message ||
          'Something went wrong'
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Box>{renderText({ label: 'Summary', variant: 'h6' })}</Box>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={2}
            justify="center">
            <Grid item container alignItems="center" justify="center">
              <Grid item container justify="center" sm={5}>
                <span>Port Name</span>
              </Grid>
              <Grid container justify="center" item sm={2}>
                :
              </Grid>
              <Grid container justify="center" item sm={5}>
                {formData?.portName}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid container justify="center" item sm={5}>
                Port Speed
              </Grid>
              <Grid container justify="center" item sm={2}>
                :
              </Grid>
              <Grid container justify="center" item sm={5}>
                {formData?.portSpeed}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid container justify="center" item sm={5}>
                Term
              </Grid>
              <Grid container justify="center" item sm={2}>
                :
              </Grid>
              <Grid container justify="center" item sm={5}>
                {formData?.minTerm}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid container justify="center" item sm={5}>
                Market Place
              </Grid>
              <Grid container justify="center" item sm={2}>
                :
              </Grid>
              <Grid container justify="center" item sm={5}>
                {formData?.marketPlace}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid container justify="center" item sm={5}>
                High Availbility
              </Grid>
              <Grid container justify="center" item sm={2}>
                :
              </Grid>
              <Grid container justify="center" item sm={5}>
                {formData?.highAvailability}
              </Grid>
            </Grid>
            {formData?.highAvailability === 'no' &&
              userEmail === 'testuser40@mail.com' && (
                <Grid item container alignItems="center" justify="center">
                  <Grid container justify="center" item sm={5}>
                    Port Number
                  </Grid>
                  <Grid container justify="center" item sm={2}>
                    :
                  </Grid>
                  <Grid container justify="center" item sm={5}>
                    {formData?.port1}
                  </Grid>
                </Grid>
              )}
            {formData?.highAvailability === 'yes' &&
              userEmail === 'testuser40@mail.com' && (
                <>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Port Number 1
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.port1}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                    <Grid container justify="center" item sm={5}>
                      Port Number 2
                    </Grid>
                    <Grid container justify="center" item sm={2}>
                      :
                    </Grid>
                    <Grid container justify="center" item sm={5}>
                      {formData?.port2}
                    </Grid>
                  </Grid>
                </>
              )}
          </Grid>

          <Grid item container justify="space-evenly" alignItems="center">
            <Grid item>
              <Button variant="outlined" size="medium" onClick={handlePrev}>
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                onClick={() => {
                  setOpenPopup(true);
                }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {openPopup && (
        <Popup
          title="Add Configured Services"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Are you sure you add this service from your configured services?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => setOpenPopup(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleSave}>
              Order Now
            </Button>
          </DialogActions>
        </Popup>
      )}
    </>
  );
};

export default Step3Port;
