import axios from '../utils/httpClient';
import moment from 'moment';

const accessToken = localStorage.getItem('syncomToken');

const getPortById = async portId => {
  try {
    const response = await axios.get(`port/${portId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};
const getLoa = async id => {
  try {
    const response = await axios.put(
      `port/update/${id}`,
      {},
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getPortLogs = async id => {
  try {
    const response = await axios.get(`serviceLog?log_type=port&port=${id}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const getPortListWithRegionId = async (page = 1, limit = 10, regionId) => {
  try {
    const response = await axios.get(
      `port?page=${page}&limit=${limit}&sort=-createdAt&region=${regionId}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};
const getPortLists = async (page = 1, limit = 10, word) => {
  try {
    const response = await axios.get(
      `port?page=${page}&limit=${limit}&sort=-createdAt&search=${word}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};
const getPortsWithRegionId = async (search, regionId) => {
  try {
    const response = await axios.get(
      `port?search=${search}&region=${regionId}`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const getAllPortList = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `port?page=${page}&limit=${limit}&sort=-createdAt`,
      {
        headers: { accessToken: `${accessToken}` }
      }
    );

    return response;
  } catch (e) {
    return e;
  }
};

const activatePortByConnId = async (connId, payload) => {
  try {
    const response = await axios.put(`port/admin/update/${connId}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const editPortById = async (id, payload) => {
  try {
    const response = await axios.put(`port/${id}`, payload, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const deletePortById = async portId => {
  try {
    const response = await axios.delete(`port/${portId}`, {
      headers: { accessToken: `${accessToken}` }
    });

    return response;
  } catch (e) {
    return e;
  }
};

const createPort = async payload => {
  try {
    const response = await axios.post(`port`, payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const createPortNum = async payload => {
  try {
    const response = await axios.post(`port/check/port_num`, payload, {
      headers: { accessToken: `${accessToken}` }
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getChartDataOfPort = function(
  dateFilter,
  setLoader,
  setChartData,
  selectedPortData
) {
  let query = '';
  var date = new Date();

  if (dateFilter === 15 || dateFilter === 60) {
    date.setDate(date.getDate());
    query = date;
  }

  const testURL = `https://54dc60343a3b46d3a26840aa77dc46d3.europe-west2.gcp.elastic-cloud.com:9243/fabric-snmp-${moment(
    query
  ).format('YYYY')}.${moment(query).format('MM')}/_search`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'ApiKey QnVuWnRZSUJ0am1vUGpUUUxSTDQ6cGZabnhkYWZTR3lISWFxVzBRV1RPdw=='
    },

    body: JSON.stringify({
      size: dateFilter,
      query: {
        bool: {
          filter: [
            {
              range: {
                '@timestamp': {
                  gte: moment
                    .utc(date)
                    .subtract(dateFilter, 'm')
                    .format(),
                  lt: moment.utc(date).format()
                }
              }
            }
          ],
          must: [
            {
              match_phrase: {
                'interfaces.ifDescr': `uBond${selectedPortData[0]?.sp_vlan_name}`
              }
            }
          ]
        }
      },
      sort: [{ '@timestamp': 'asc' }]
    })
  };

  setLoader(true);
  fetch(testURL, requestOptions)
    .then(response => response.json())
    .then(async data => {
      (await data?.hits?.hits?.length) > 0
        ? setChartData(
            data?.hits?.hits
          )
        : setChartData([]);
      setLoader(false);
    })
    .catch(function(error) {
      console.log({ error });
      setLoader(false);
    });
};

export {
  getPortById,
  getPortListWithRegionId,
  getAllPortList,
  getPortsWithRegionId,
  activatePortByConnId,
  deletePortById,
  getPortLogs,
  getLoa,
  editPortById,
  getPortLists,
  getChartDataOfPort,
  createPort,
  createPortNum
};
