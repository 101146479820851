import { gql } from 'apollo-boost';

export const CREATE_LIVENESS_VIDEO_RECORD = gql`
    mutation LivenessVideoRecord($person: String, $organization: String, $videoFile: Upload!) {
        addLivenessVideoRecord(person: $person, organization: $organization, videoFile: $videoFile) {
            id
        }
    }
`;

export const DELETE_LIVENESS_VIDEO_RECORD = gql`
    mutation LivenessVideoRecord($id: String) {
        deleteLivenessVideoRecord(id: $id) {
            id
        }
    }
`;