import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import GenericTable from '../../../../../../../components/GenericTable';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

function QuoteDocsModal({
    t,
    open,
    handleClose,
    data,
    loading
}) {
    
    const [docList, setDocList] = useState([]);
    const docColumn = [
        {
            name: "Doc Type",
            key: "document_type"
        },
        {
            name: "File",
            key: "file_url"
        }
    ];

    const handleOpenPdfUrl = (url) => {
        window.open(url, {target: "_blank"});
    }

    useEffect(() => {
        if(data && data.docs && data.docs.length){
            setDocList(data.docs.map(itm => {
                itm.file_url = <>
                    <IconButton onClick={() => handleOpenPdfUrl(itm.url)} aria-label="view">
                        <PictureAsPdfIcon color="primary" />
                    </IconButton>
                </>;
                return itm;
            }));
        }
    }, [data]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">Quote Details</DialogTitle>
            <DialogContent>
                {loading ? 
                    <div style={{textAlign: "center", marginTop: 30}}>
                        <CircularProgress size={25} color={"primary"} />
                    </div>
                :
                    data ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: 10, marginTop: 20 }} variant="subtitle1">Name: {data.name}</Typography>
                                <Typography style={{ marginBottom: 10, marginTop: 10 }} variant="subtitle1">Vendor: {data.vendor_name}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <Typography style={{ marginBottom: 10 }} variant="h4">Quote Documents</Typography>
                                <GenericTable list={docList} columns={docColumn} />
                            </Grid>
                        </Grid>
                    :
                        <Grid container spacing={2}>
                            <Typography style={{ marginBottom: 10, marginTop: 24 }} variant="h4">Documents not Found!</Typography>
                        </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

QuoteDocsModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateBidSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}
export default withTranslation()(QuoteDocsModal)