import React from 'react';
import {
  Area,
  AreaChart,
  LineChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  Line,
  YAxis
} from 'recharts';
import { colors } from '../../theme/colors';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core';

const AreaChartWrapper = ({
  data,
  dataKeyXAxis,
  dataKeyChart,
  chartColor,
  name,
  stacked = false,
  secondChartName,
  secondChartColor,
  secondChartDataKey
}) => {
  return (
    <div style={{ width: '100%', height: 322 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke={colors.grey[200]} />
          <Legend
            verticalAlign={'top'}
            align={'right'}
            layout={'horizontal'}
            wrapperStyle={{
              top: 32,
              color: colors.common.black,
              fontSize: 14
            }}
            iconType={'circle'}
            iconSize={12}
          />
          <XAxis
            dataKey={dataKeyXAxis}
            stroke={colors.grey[200]}
            dy={10}
            tick={{ fill: colors.grey[400], fontSize: 12, marginTop: 20 }}
          />
          <YAxis
            stroke={colors.grey[200]}
            dx={-10}
            tick={{ fill: colors.grey[400], fontSize: 12 }}
          />
          <Tooltip />
          <Line
            name={name}
            type="monotone"
            dataKey={dataKeyChart}
            strokeWidth={3}
            stroke={chartColor}
            fill={fade(chartColor, 0.5)}
            stackId="1"
          />
          {stacked && (
            <Line
              name={secondChartName}
              type="monotone"
              dataKey={secondChartDataKey}
              strokeWidth={3}
              stroke={secondChartColor}
              fill={fade(secondChartColor, 0.5)}
              stackId="1"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
AreaChartWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeyXAxis: PropTypes.string.isRequired,
  dataKeyChart: PropTypes.string.isRequired,
  chartColor: PropTypes.string.isRequired,
  secondChartName: PropTypes.string,
  secondChartColor: PropTypes.string,
  secondChartDataKey: PropTypes.string
};
export default AreaChartWrapper;
