import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../../../theme/commonStyles';
import clsx from 'clsx';
import { STATUS_TYPES } from '../../../../../../utils/constants';
import { paddingGenerator } from '../../../../../../theme/utils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    ticketStatusContainer: {
        height: '15px',
        borderRadius: '16px',
        alignItems: 'center',
        display: 'flex'
    },
    openTicket: {
        background: theme.palette.error.main
    },
    closedTicket: {
        background: theme.palette.grey[400]
    },
    textStyle: {
        color: theme.palette.common.white
    }
}));

const CyberSecurityStatus = ({ t, status }) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    return (
        <div
            className={clsx(
                classes.ticketStatusContainer,
                status === STATUS_TYPES.CLOSED ? classes.closedTicket : classes.openTicket
            )}
            style={{
                ...paddingGenerator('pt-4'),
                ...paddingGenerator('pb-5'),
                ...paddingGenerator('pl-12'),
                ...paddingGenerator('pr-12')
            }}>
            <Typography
                className={clsx(commonClasses.buttonTextSmall, classes.textStyle)}
                variant={'body1'}>
                {status === STATUS_TYPES.OPEN ? t('open.label') : t('close.label')}
            </Typography>
        </div>
    );
};

CyberSecurityStatus.propTypes = {
    status: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
};

export default CyberSecurityStatus;
