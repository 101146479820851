import React, {useState, useEffect, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import {useMutation} from '@apollo/react-hooks';
import {useFormik} from "formik";
import * as Yup from "yup";
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Message from '../../../../../../components/Message';
import {
  CREATE_BRAND
  } from '../../../../Shayona/mutations';
import {ShayonaCementsContext} from '../../../../../../contexts/ShayonaCementsProvider';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContain: {
        width: "100%",
    }
  });
  
  const useStyles = makeStyles(theme => ({
    testCon: {marginRight: '6px', marginLeft: '6px'},
    chartTitle: {marginTop: '10px'},
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    mapContainer: {
        height: '352px',
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(2)
    },
    inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 15,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #ced4da',
          fontSize: 14,
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  function ButtonComponent(props) {
    const { t, onClick, loading, disabledValue } = props;
    return (
        <Button 
        color="secondary"
        variant={'contained'}
        onClick={onClick} 
        disabled={loading||disabledValue}
        type="submit"
        >
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
  }

const AddBrandModal = ({ 
    t,
    open,
    onClose,
    data
 }) => {

    const classes = useStyles();
    const {organizationId,
      reloadShayonaApisOrPagination
           } = useContext(ShayonaCementsContext);
  
    const validationSchema = Yup.object({
        name: Yup
                  .string('Please fill  brand name')
                  .required('Please fill brand name'),
    });
  
    const formik = useFormik({
      initialValues: {
        name: ''
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmtiOfTruckDetails(values);
      },
    });
  
    const {handleSubmit, handleBlur, handleChange, touched, errors, values, resetForm } = formik;
  
    const [craeteNewBrand, { loading: craeteNewBrandLoading }] = useMutation(CREATE_BRAND, {
     onError: error => {
         console.log('err', error);
     },
     onCompleted: data => {
         Message.success(data && data.createBrand.message);
         onClose();
         reloadShayonaApisOrPagination('brand','');
     }
    });
  
    const handleSubmtiOfTruckDetails = (values) => {
      craeteNewBrand({
         variables:{
             name:  values && values.name
         }
     })
    };
   
   useEffect(() => {
    if(!open){
      resetForm();
    }
   }, [open]);

  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            Add Brand
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
                <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
                                  <Grid item xs={12}>
                                      <TextField
                                        id="standard-multiline-flexible"
                                        rows={2}
                                        variant="standard" 
                                        value={values.name}
                                        label="Brand Name" 
                                        name="name"
                                        style={{ width: '100%'}}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.name && errors.name}
                                        error={touched.name && Boolean(errors.name)}
                                      />
                                  </Grid>
                                </Grid>
                                <DialogActions>
                                  <Grid container justify={'space-between'}>
                                      <Button 
                                          variant={'outlined'}
                                          color="primary"
                                          onClick={onClose}
                                      >
                                          {t('cancel.label')}
                                      </Button>
                                      <ButtonComponent
                                       t={t}
                                       loading={craeteNewBrandLoading}
                                      />
                                  </Grid>          
                              </DialogActions>
                            </form>
        </DialogContent>
    </Dialog>
  )
}

export default AddBrandModal