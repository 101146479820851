import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ProfileContext } from '../../contexts/ProfileProvider';
import InqExLogo from '../../assets/icons/inq. Control Platform.png';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Breadcrumb from '../Breadcrumb';
import { FABRIC_URL } from '../../envConstants';
import {
  InternalPaths,
  Paths,
  TicketingSystem,
  ServiceManager
} from '../../routes/routePaths';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MenuWrapper from '../MenuWrapper';
import i18n from './../../i18n';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { USER_ROLES } from '../../utils/constants';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../theme/commonStyles';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_USER_INFO } from '../../containers/Profile/queries';
import Avatar from '../Avatar';
import { colors } from '../../theme/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { LOGOUT } from '../../containers/commonQueries';
import { onLogout, checkUserSettingAccess } from '../../utils';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { dateFormatterLLLHHMMSS } from '../../utils';
import ImgAvatar from '@material-ui/core/Avatar';

const hostName = window.location.hostname;

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1
  },
  logo: {
    height: '120px'
  },
  dynamicLogo: {
    height: 60,
    borderRadius: 10,
    padding: '6px 10px',
    backgroundColor: '#ffff'
  },
  primaryHeader: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2%',
    paddingRight: '2%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  primaryMenu: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    width: '82%',
    marginRight: '1%'
  },
  secondaryMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '13%'
  },
  serviceTicketMenu: {
    display: 'flex',
    position: 'absolute',
    marginLeft: '20px'
  },
  primaryOptions: {
    marginRight: '20px',
    cursor: 'pointer',
    fontSize: '15px'
  },
  primaryIntOptions: {
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    fontWeight: 'lighter'
  },
  secondaryOptions: {
    cursor: 'pointer',
    color: 'white'
  },

  selector: {
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    color: theme.palette.common.white
  },
  secondaryNavItem: {
    borderLeft: `1px solid ${fade(theme.palette.common.white, 0.2)}`,
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 3)
  },
  primaryNavItems: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 1.2)
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  notiIcon: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    margin: 13
  },
  notif_heading: {
    borderLeft: '2px solid #5a1f2a',
    paddingLeft: 10,
    marginLeft: 10
  },
  notiListRoot: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300
  },
  notiItemTxt: {
    '&:hover': {
      color: theme.palette.secondary
    }
  },
  logoCenter: {
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
    position: 'relative'
  },
  rightMenu: {
    display: 'flex',
    position: 'absolute',
    top: 45,
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      left: 10,
      top: 80
    }
  },
  itemName: {
    color: theme.palette.common.white
  },
  mainHeader: {
    height: '96px',
    justifyContent: 'end',
    backgroundColor: theme.palette.primary.light
  },
  secondaryHeader: {
    backgroundColor: theme.palette.secondary.main
  }
}));

const primaryOptions = [
  {
    label: 'dashboard.label',
    link: Paths.Dashboard
  },
  {
    label: 'subscriptions.label',
    link: Paths.Subscriptions
  },
  {
    label: 'serviceTickets.label',
    link: Paths.ServiceTickets
  },
  {
    label: 'Fabric',
    link: Paths.FabricServices
  }
];
const internalOptions = [
  {
    label: 'dashboard.label',
    link: InternalPaths.Dashboard
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'users.label',
    link: InternalPaths.users
  },
  {
    label: 'countries.label',
    link: InternalPaths.countries
  },
  {
    label: 'vnfMarketplace.label',
    link: InternalPaths.vnfMarketPlace
  },
  {
    label: 'servicesAndProducts.label',
    link: InternalPaths.servicesAndProducts
  },
  {
    label: 'pmoDashboard.label',
    link: InternalPaths.pmoDashboard
  },
  {
    label: 'biDashboard.label',
    link: InternalPaths.biDashboard
  },
  {
    label: 'workflowManagement.label',
    link: InternalPaths.workflowManagement
  },
  {
    label: 'Fabric',
    link: Paths.FabricServices
  }
];
const cntryAdminOptions = [
  {
    label: 'dashboard.label',
    link: InternalPaths.Dashboard
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'users.label',
    link: InternalPaths.users
  }
];

const serviceManagerOptions = [
  {
    label: 'vendors.label',
    link: ServiceManager.Vendors
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'incidentOnly.label',
    link: ServiceManager.IncidentType
  },
  {
    label: 'incidentGroup.label',
    link: ServiceManager.IncidentTypeGroup
  },
  {
    label: 'agent.label',
    link: ServiceManager.AdditionalAgent
  },
  {
    label: 'ngList.label',
    link: ServiceManager.NGList
  }
];

const normalUserOption = [
  {
    label: 'dashboard.label',
    link: Paths.Dashboard
  }
];

const ticketAdminOptions = [
  {
    label: 'dashboard.label',
    link: TicketingSystem.Dashboard
  },
  {
    label: 'serviceTickets.label',
    link: Paths.TicketSystem
  },
  {
    label: 'organisations.label',
    link: InternalPaths.organisations
  },
  {
    label: 'knowladge.label',
    link: TicketingSystem.KnowladgeBase
  },
  {
    label: 'customers.label',
    link: TicketingSystem.Customers
  },
  {
    label: 'analytics.label',
    link: TicketingSystem.Analytics
  },
  {
    label: 'upload.label',
    link: TicketingSystem.Upload
  },
  {
    label: 'history.label',
    link: TicketingSystem.History
  },
  {
    label: 'changeManagement.label',
    link: TicketingSystem.CM
  }
];

const countArr = internalOptions.length;
function Navbar({
  history,
  breadcrumbList,
  type,
  t,
  headerNotifications,
  handleSetImageModalSrc,
  handleNotificationClick
}) {
  let userEmail = localStorage.getItem('email');
  const { setProfileData, resetProfile } = useContext(ProfileContext);

  const { data, loading, error } = useQuery(GET_USER_INFO);
  const menuItems = [
    { label: t('english.label'), value: 'en' },
    { label: t('french.label'), value: 'fr' }
  ];
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const path = window.location.pathname;
  const role = localStorage.getItem('role');
  const country = localStorage.getItem('country');

  const optionList =
    role && role.includes(USER_ROLES.IP_SUPERADMIN)
      ? internalOptions
      : role.includes(USER_ROLES.IP_COUNTRYADMIN)
      ? cntryAdminOptions
      : role.includes(USER_ROLES.CSOC_MANAGER) ||
        role.includes(USER_ROLES.CSOC) ||
        role.includes(USER_ROLES.SD_READONLY) ||
        role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
      ? ticketAdminOptions
      : role.includes(USER_ROLES.SERVICE_MANAGER)
      ? serviceManagerOptions
      : role.includes(USER_ROLES.IP_NUSER)
      ? normalUserOption
      : primaryOptions;

  useEffect(() => {
    if (optionList && optionList.length > 0 && optionList.length === countArr) {
      if (
        role &&
        role.includes(USER_ROLES.IP_SUPERADMIN) &&
        (userEmail === 'duncan@inq.inc' ||
          userEmail === 'tunde.dada@inq.inc' ||
          userEmail === 'pramodv@inq.inc')
      ) {
        optionList.push({
          label: 'mttrAnalytics.label',
          link: TicketingSystem.MttrAnalytics
        });
      }
    }
  }, []);

  const isEadminRole = checkUserSettingAccess(role);
  const [profileOpen, setProfileOpen] = useState(false);
  const [collabOpen, setCollabOpen] = useState(false);
  const [humanCapitalOpen, setHumanCapitalOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [financeOpen, setFinanceOpen] = useState(false);
  const [afterSalesOpen, setAfterSalesOpen] = useState(false);
  const [ticketOpen, setTicketOpen] = useState(false);
  const [salesOpen, setSalesOpen] = useState(false);
  const [showNotiIcon, setShowNotiIcon] = useState(false);
  const anchorRef = React.useRef(null);
  const ticketRef = React.useRef(null);
  const collabRef = React.useRef(null);
  const salesRef = React.useRef(null);
  const marketingRef = React.useRef(null);
  const deliveryRef = React.useRef(null);
  const financeRef = React.useRef(null);
  const afterSalesRef = React.useRef(null);
  const humanCapitalRef = React.useRef(null);
  const [notiAnchorEl, setNotiAnchorEl] = useState(null);

  const handleClick = () => {
    const token = localStorage.getItem('syncomToken');
    const org = localStorage.getItem('orgId');
    const orgName = localStorage.getItem('orgName');
    const role = localStorage.getItem('role');
    axios.post(`${FABRIC_URL}auth/login`, {
      accessToken: token,
      org_code: org,
      org_name: orgName,
      role: role.split(',')[0]
    });
    history.push('/fabricService');
  };

  React.useEffect(() => {
    if (
      history.location &&
      history.location.pathname &&
      history.location.pathname.includes('/subscriptions/active/details')
    ) {
      setShowNotiIcon(true);
    } else {
      setShowNotiIcon(false);
    }
  }, []);

  const handleProfileCloseTicket = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setTicketOpen(false);
  };

  function handleListKeyDownTicket(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setTicketOpen(false);
    }
  }

  const handleProfileClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setProfileOpen(false);
    }
  }

  const ticketPrevOpen = React.useRef(ticketOpen);
  React.useEffect(() => {
    if (ticketPrevOpen.current === true && ticketOpen === false) {
      ticketRef.current.focus();
    }
    ticketPrevOpen.current = ticketOpen;
  }, [ticketOpen]);

  const handleToggleTicket = () => {
    setTicketOpen(ticketPrevOpen => !ticketPrevOpen);
  };

  const prevOpen = React.useRef(profileOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && profileOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = profileOpen;
  }, [profileOpen]);

  const handleToggle = () => {
    setProfileOpen(prevOpen => !prevOpen);
  };
  const [userLogout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      onLogout();
    }
  });

  const handleClickShowNotifications = event => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleHideNotifications = () => {
    setNotiAnchorEl(null);
  };

  const open = Boolean(notiAnchorEl);
  const id = open ? 'simple-popover' : undefined;
  const logo_url =
    localStorage.getItem('logo_url') &&
    localStorage.getItem('logo_url') !== 'null'
      ? localStorage.getItem('logo_url')
      : null;

  // Collaberation DropDown

  const prevCollabOpen = React.useRef(collabOpen);
  React.useEffect(() => {
    if (prevCollabOpen.current === true && collabOpen === false) {
      collabRef.current.focus();
    }
    prevCollabOpen.current = collabOpen;
  }, [collabOpen]);

  // Human Capital DropDown

  const prevHumanCapitalOpen = React.useRef(humanCapitalOpen);
  React.useEffect(() => {
    if (prevHumanCapitalOpen.current === true && humanCapitalOpen === false) {
      humanCapitalRef.current.focus();
    }
    prevHumanCapitalOpen.current = humanCapitalOpen;
  }, [humanCapitalOpen]);

  // Sales DropDown

  const prevSalesOpen = React.useRef(salesOpen);
  React.useEffect(() => {
    if (prevSalesOpen.current === true && salesOpen === false) {
      salesRef.current.focus();
    }
    prevSalesOpen.current = salesOpen;
  }, [salesOpen]);

  // Marketing DropDown

  const prevMarketingOpen = React.useRef(marketingOpen);
  React.useEffect(() => {
    if (prevMarketingOpen.current === true && marketingOpen === false) {
      marketingRef.current.focus();
    }
    prevMarketingOpen.current = marketingOpen;
  }, [marketingOpen]);

  // Delivery DropDown

  const prevDeliveryOpen = React.useRef(deliveryOpen);
  React.useEffect(() => {
    if (prevDeliveryOpen.current === true && deliveryOpen === false) {
      deliveryRef.current.focus();
    }
    prevDeliveryOpen.current = deliveryOpen;
  }, [deliveryOpen]);

  // Finance DropDown

  const prevFinanceOpen = React.useRef(financeOpen);
  React.useEffect(() => {
    if (prevFinanceOpen.current === true && financeOpen === false) {
      financeRef.current.focus();
    }
    prevFinanceOpen.current = financeOpen;
  }, [financeOpen]);

  // After Sales DropDown

  const prevAfterSalesOpen = React.useRef(afterSalesOpen);
  React.useEffect(() => {
    if (prevAfterSalesOpen.current === true && afterSalesOpen === false) {
      afterSalesRef.current.focus();
    }
    prevAfterSalesOpen.current = afterSalesOpen;
  }, [afterSalesOpen]);

  useEffect(() => {
    if (data) {
      setProfileData(data);
    }
  }, [data, setProfileData]);

  const otherPlatform =
    hostName === 'localhost' ||
    hostName === 'platform-staging.inq.inc' ||
    hostName === 'platform.inq.inc'
      ? false
      : true;

  return (
    <Grid container xs={12}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar variant="dense" className={classes.mainHeader}>
          <div className={classes.logoCenter}>
            <img
              className={logo_url ? classes.dynamicLogo : classes.logo}
              src={logo_url ? logo_url : InqExLogo}
              alt="logo"
            />
          </div>
          <div className={classes.rightMenu}>
            <Grid
              item
              className={classes.secondaryNavItem}
              ref={anchorRef}
              aria-controls={profileOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}>
              <Avatar
                name={
                  !loading && !error
                    ? `${data.userInfo.firstName}`
                    : t('profile.label')
                }
              />
              <Typography
                variant={'subtitle1'}
                style={
                  path === Paths.Profile ? { color: colors.common.white } : {}
                }
                className={classes.secondaryOptions}>
                {!loading && !error
                  ? `${data.userInfo.firstName}`
                  : t('profile.label')}
              </Typography>
            </Grid>
            <Popper
              open={profileOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: '999' }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleProfileClose}>
                      <List
                        className={classes.root}
                        subheader={<li />}
                        autofocusitem={profileOpen ? 'true' : 'false'}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={() => history.push(Paths.Profile)}>
                          {t('profile.label')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Paths.ChangePassword)}>
                          {t('resetPassword.label')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Paths.ActivityLogs)}>
                          {t('activityLogs.label')}
                        </MenuItem>
                        {isEadminRole === true && (
                          <>
                            <ListSubheader>
                              {!loading && !error
                                ? data.userInfo.organization.name
                                : ''}
                            </ListSubheader>
                            <MenuItem
                              onClick={() => history.push(Paths.Settings)}>
                              {t('settings.label')}
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => {
                            resetProfile();
                            userLogout({
                              variables: {
                                refreshToken: localStorage.getItem(
                                  'syncomRefreshToken'
                                )
                              }
                            });
                          }}>
                          {t('logout.label')}
                        </MenuItem>
                      </List>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Grid item className={classes.secondaryNavItem}>
              <Typography
                variant={'subtitle1'}
                onClick={evt => setAnchorEl(evt.currentTarget)}
                className={clsx(classes.secondaryOptions, classes.selector)}>
                {i18n.language === 'en'
                  ? t('english.label')
                  : t('french.label')}
                <ArrowDropDownIcon />
              </Typography>
            </Grid>
            <Grid item className={classes.secondaryNavItem}>
              <Typography
                variant={'subtitle1'}
                onClick={() => {
                  window.open('https://inq.my.salesforce.com/', '_blank');
                }}
                className={classes.secondaryOptions}>
                {t('support.label')}
              </Typography>
            </Grid>
          </div>
        </Toolbar>
        <Toolbar
          variant="dense"
          className={
            otherPlatform ? classes.secondaryHeader : classes.primaryHeader
          }>
          <div className={classes.primaryMenu}>
            {optionList.map((item, index) => (
              <Typography
                variant={'subtitle1'}
                key={index}
                className={clsx(
                  classes.primaryOptions,
                  path.includes(item.link) && classes.active
                )}
                onClick={() => {
                  item.link === '/fabricService'
                    ? handleClick()
                    : history.push(item.link);
                }}>
                {t(item.label)}
              </Typography>
            ))}
          </div>

          <div className={classes.secondaryMenu}>
            {showNotiIcon && (
              <React.Fragment>
                <IconButton
                  disabled={
                    headerNotifications && headerNotifications.count
                      ? false
                      : true
                  }
                  onClick={handleClickShowNotifications}
                  className={classes.notiIcon}
                  aria-label="show 11 new notifications"
                  color="secondary">
                  <Badge
                    max={99999}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    badgeContent={
                      headerNotifications && headerNotifications.count
                        ? headerNotifications.count
                        : 0
                    }
                    color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={notiAnchorEl}
                  onClose={handleHideNotifications}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}>
                  <List className={classes.notiListRoot}>
                    {headerNotifications &&
                    headerNotifications.notifications &&
                    headerNotifications.notifications.length > 0
                      ? headerNotifications.notifications.map((item, index) => (
                          <>
                            <ListItem
                              style={{ cursor: 'pointer' }}
                              alignItems="flex-start"
                              key={index}
                              onClick={handleNotificationClick}>
                              <ListItemAvatar>
                                <ImgAvatar
                                  onClick={handleSetImageModalSrc}
                                  style={{ cursor: 'pointer' }}
                                  alt=""
                                  src={item.image_url}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      className={classes.notiItemTxt}
                                      variant="subtitle2"
                                      component="p">
                                      Time{' '}
                                      {` -  ${dateFormatterLLLHHMMSS(
                                        item.created_at
                                      )}`}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      className={classes.notiItemTxt}
                                      style={{ paddingTop: 2 }}>
                                      Model{' '}
                                      {` - ${
                                        item.model_type ? item.model_type : '-'
                                      }`}
                                    </Typography>
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      style={{ paddingTop: 2 }}
                                      className={classes.notiItemTxt}>
                                      Cam{' '}
                                      {` - ${
                                        item.camera ? item.camera.name : '-'
                                      }`}
                                      ,
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      style={{ paddingTop: 2 }}
                                      className={classes.notiItemTxt}>
                                      Place{' '}
                                      {` -  ${
                                        item.camera && item.camera.place
                                          ? item.camera.place.name
                                          : '-'
                                      }`}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        ))
                      : null}
                  </List>
                </Popover>
              </React.Fragment>
            )}
            {country === 'null' && (
              <>
                <Grid
                  item
                  className={classes.secondaryNavItem}
                  ref={ticketRef}
                  aria-controls={ticketOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleTicket}>
                  <Typography
                    variant={'subtitle1'}
                    style={
                      path === Paths.Profile
                        ? { color: colors.common.white }
                        : {}
                    }
                    className={classes.secondaryOptions}>
                    {t('ticket.label')}
                  </Typography>
                </Grid>
                <Popper
                  open={ticketOpen}
                  anchorEl={ticketRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999' }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}>
                      <Paper>
                        <ClickAwayListener
                          onClickAway={handleProfileCloseTicket}>
                          <List
                            className={classes.root}
                            subheader={<li />}
                            autofocusitem={ticketOpen ? 'true' : 'false'}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDownTicket}>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.Dashboard)
                              }>
                              {t('dashboard.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() => history.push(Paths.TicketSystem)}>
                              {t('serviceTickets.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.KnowladgeBase)
                              }>
                              {t('knowladge.label')}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push(TicketingSystem.Analytics)
                              }>
                              {t('analytics.label')}
                            </MenuItem>
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </div>
        </Toolbar>
        {breadcrumbList && breadcrumbList.length && (
          <Breadcrumb type={type} breadcrumbList={breadcrumbList} />
        )}
      </AppBar>
      {anchorEl && (
        <MenuWrapper
          id={'ticket-menu'}
          anchorEl={anchorEl}
          handleClose={evt => {
            evt.stopPropagation();
            setAnchorEl(null);
          }}
          menuItems={menuItems}
          handleMenuItemClick={item => {
            setAnchorEl(null);
            return i18n.changeLanguage(item.value);
          }}
        />
      )}
    </Grid>
  );
}
Navbar.propTypes = {
  breadcrumbList: PropTypes.array.isRequired
};
export default withTranslation()(withRouter(Navbar));
