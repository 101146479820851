import React, { useState, useQuery, useEffect, useLazyQuery } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, USER_ROLES } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    },
    displayNone: {
        display: 'none'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateActivationTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    selectedProjectTypeAndUid,
    CurrentUserName,
    preValuesOfForm,
    modalContentLoading
}) {
    const classes = useStyles();
    const [clientName, setClientName] = useState('');
    const [location, setLocation] = useState('');
    const [assignedIp, setAssignedIp] = useState('');
    const [allocatedBandwidth, setAllocatedBandwidth] = useState('');
    const [sideAAddress, setSideAAddress] = useState('');
    const [sideBAddress, setSideBAddress] = useState('');
    const [baseStation, setBaseStation] = useState('');
    const [domainName, setDomainName] = useState('');
    const [domainOwner, setDomainOwner] = useState('');
    const [contactPersonDetails, setContactPersonDetails] = useState('');
    const [domainServiceAttached, setDomainServiceAttached] = useState('');
    const [emailPackage, setEmailPackage] = useState('');
    const [addressService, setAddressService] = useState('');
    const [VMIP, setVMIP] = useState('');
    const [internetCapacity, setInternetCapacity] = useState('');
    const [VMSpecs, setVMSpecs] = useState('');
    const [backupCapacity, setBackupCapacity] = useState('');
    const [numberOfMachines, setNumberOfMachines] = useState('');
    const [attachAnyOtherDoc, setAttachAnyOtherDoc] = useState([]);
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    // new fields
    const [vlan, setVlan] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [OrderType, setOrderType] = useState('');
    const [OrderDescription, setOrderDescription] = useState('');
    const [PortNumber, setPortNumber] = useState('');
    const [Capacity, setCapacity] = useState('');
    const [ServiceType, setServiceType] = useState('');
    const [BackupType, setBackupType] = useState('');
    const [DNSHosting, setDNSHosting] = useState(false);
    const [DNSRegistration, setDNSRegistration] = useState(false);
    const [WebHosting, setWebHosting] = useState(false);
    const [MailHosting, setMailHosting] = useState(false);
    const [MailTurning, setMailTurning] = useState(false);
    const [MailSetup, setMailSetup] = useState(false);
    const [WebsiteSetup, setWebsiteSetup] = useState(false);
    const [AdditionalGigabyteStorage, setAdditionalGigabyteStorage] = useState(false);
    const [ServiceTypeList, setServiceTypeList] = useState([]);
    const [equipmentsToBeDeployed, setEquipmentsToBeDeployed] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setClientName('');
            setLocation('');
            setAssignedIp('');
            setVlan('');
            setServiceId('');
            setAllocatedBandwidth('');
            setSideAAddress('');
            setSideBAddress('');
            setBaseStation('');
            setDomainName('');
            setDomainOwner('');
            setContactPersonDetails('');
            setDomainServiceAttached('');
            setEmailPackage('');
            setAddressService('');
            setVMIP('');
            setInternetCapacity('');
            setVMSpecs('');
            setBackupCapacity('');
            setNumberOfMachines('');
            setAttachAnyOtherDoc([]);
            setOnHold('');
            setCategory('');
            setEquipmentsToBeDeployed('');
        }
        if (selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services') {
            setServiceTypeList([
                {
                    id: 'Radio',
                    name: 'Radio'
                },
                {
                    id: 'Fibre',
                    name: 'Fibre'
                },
            ])
        }
        else if (selectedProjectTypeAndUid.project_type === 'Broadband') {
            setServiceTypeList([
                {
                    id: 'Fixed Wireless',
                    name: 'Fixed Wireless'
                },
                {
                    id: 'Mimosa',
                    name: 'Mimosa'
                },
            ])
        }
        else if (selectedProjectTypeAndUid.project_type === 'Access Points') {
            setServiceTypeList([
                {
                    id: 'Rukus',
                    name: 'Rukus'
                },
                {
                    id: 'UniFi',
                    name: 'UniFi'
                },
            ])
        }
        else if (selectedProjectTypeAndUid.project_type === 'FTTH') {
            setServiceTypeList([
                {
                    id: 'DIA',
                    name: 'DIA'
                },
                {
                    id: 'Broadband',
                    name: 'Broadband'
                },
            ])
        }
        else if (selectedProjectTypeAndUid.project_type === 'Firewall Services') {
            setServiceTypeList([
                {
                    id: 'SDN',
                    name: 'SDN'
                },
                {
                    id: 'Mikrotik',
                    name: 'Mikrotik'
                },
            ])
        }
        else if (selectedProjectTypeAndUid.project_type === 'Web Services') {
            setServiceTypeList([
                {
                    id: 'Design',
                    name: 'Design'
                },
                {
                    id: 'Hosting',
                    name: 'Hosting'
                },
            ])
        }
    }, [modalState, open, modalContentLoading, preValuesOfForm, CurrentUserName, selectedProjectTypeAndUid]);
    let VMSpecsType = [
        {
            id: 'RAM',
            name: 'RAM'
        },
        {
            id: 'CPU Cores',
            name: 'CPU Cores'
        },
        {
            id: 'HDD',
            name: 'HDD'
        },
        {
            id: 'OS',
            name: 'OS'
        },
    ];
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    const OrderTypeList = [
        {
            id: t("new.label"),
            name: t("new.label")
        },
        {
            id: t("modify.label"),
            name: t("modify.label")
        },
        {
            id: t("cease.label"),
            name: t("cease.label")
        },
    ]
    const BackupTypeList = [
        {
            id: t("withMirror.label"),
            name: t("withMirror.label")
        },
        {
            id: t("withoutMirror.label"),
            name: t("withoutMirror.label")
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('activationTeamSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label')} {t('type.label')} : {selectedProjectTypeAndUid.project_type ? selectedProjectTypeAndUid.project_type : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                    <Grid container spacing={2}>
                        {(selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services') &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('VLAN.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={vlan}
                                            type={'text'}
                                            placeholder={t('VLAN.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setVlan(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('serviceId.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={serviceId}
                                            type={'text'}
                                            placeholder={t('serviceId.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setServiceId(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('portNumber.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={PortNumber}
                                            type={'text'}
                                            placeholder={t('portNumber.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setPortNumber(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {(selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services' || selectedProjectTypeAndUid.project_type === 'Cloud Backup Services') &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('capacity.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={Capacity}
                                            type={'text'}
                                            placeholder={t('capacity.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setCapacity(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {(selectedProjectTypeAndUid.project_type === 'Virtual Machine Service' || selectedProjectTypeAndUid.project_type === 'Doc AI' || selectedProjectTypeAndUid.project_type === 'Software Development' || selectedProjectTypeAndUid.project_type === 'Video AI' || selectedProjectTypeAndUid.project_type === 'IP Addresses' || selectedProjectTypeAndUid.project_type === 'Rack Hosting' || selectedProjectTypeAndUid.project_type === 'Elastic' || selectedProjectTypeAndUid.project_type === 'Mimecast') &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <OutlinedSelect
                                            label={`${t('orderType.label') + '*'}`}
                                            handleChange={evt => {
                                                setOrderType(evt.target.value);
                                            }}
                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                            styleOverrides={{ width: '100%', marginBottom: 10 }}
                                            options={OrderTypeList ? OrderTypeList : []}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('orderDescription.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={OrderDescription}
                                            type={'text'}
                                            placeholder={t('orderDescription.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setOrderDescription(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {(selectedProjectTypeAndUid.project_type === 'Cloud Backup Services') &&
                            <Grid item xs={12}>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <OutlinedSelect
                                        label={`${t('backupType.label') + '*'}`}
                                        handleChange={evt => {
                                            setBackupType(evt.target.value);
                                        }}
                                        selectStyle={{ ...paddingGenerator('p-8') }}
                                        styleOverrides={{ width: '100%', marginBottom: 10 }}
                                        options={BackupTypeList ? BackupTypeList : []}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {(selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Broadband' || selectedProjectTypeAndUid.project_type === 'Access Points' || selectedProjectTypeAndUid.project_type === 'FTTH' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services' || selectedProjectTypeAndUid.project_type === 'Firewall Services' || selectedProjectTypeAndUid.project_type === 'Web Services') &&
                            <Grid item xs={12}>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <OutlinedSelect
                                        label={`${t('serviceType.label') + '*'}`}
                                        handleChange={evt => {
                                            setServiceType(evt.target.value);
                                        }}
                                        selectStyle={{ ...paddingGenerator('p-8') }}
                                        styleOverrides={{ width: '100%', marginBottom: 10 }}
                                        options={ServiceTypeList ? ServiceTypeList : []}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {(selectedProjectTypeAndUid.project_type === 'Domain Services') &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('serviceType.label')}*</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={DNSHosting}
                                                onChange={(event) => {
                                                    setDNSHosting(event.target.checked)
                                                }}
                                            />{t('DNSHosting.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={DNSRegistration}
                                                onChange={(event) => {
                                                    setDNSRegistration(event.target.checked)
                                                }}
                                            />{t('DNSRegistration.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={MailHosting}
                                                onChange={(event) => {
                                                    setMailHosting(event.target.checked)
                                                }}
                                            />{t('mailHosting.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={MailTurning}
                                                onChange={(event) => {
                                                    setMailTurning(event.target.checked)
                                                }}
                                            />{t('mailTurning.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={MailSetup}
                                                onChange={(event) => {
                                                    setMailSetup(event.target.checked)
                                                }}
                                            />{t('mailSetup.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={WebsiteSetup}
                                                onChange={(event) => {
                                                    setWebsiteSetup(event.target.checked)
                                                }}
                                            />{t('websiteSetup.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={WebHosting}
                                                onChange={(event) => {
                                                    setWebHosting(event.target.checked)
                                                }}
                                            />{t('webHosting.label')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            <Checkbox
                                                color="secondary"
                                                value={AdditionalGigabyteStorage}
                                                onChange={(event) => {
                                                    setAdditionalGigabyteStorage(event.target.checked)
                                                }}
                                            />{t('additionalGigabyteStorage.label')}</Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={equipmentsToBeDeployed}
                                    type={'text'}
                                    placeholder={t('equipmentsToBeDeployed.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setEquipmentsToBeDeployed(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label') + ' ' + t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label') + ' ' + t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                        {/*(selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'MPLS' || selectedProjectTypeAndUid.project_type === 'Broadband') &&
                          
                        }
                        {{(selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Broadband') &&
                        }
                        {selectedProjectTypeAndUid.project_type === 'MPLS' &&
                            
                        }
                        {selectedProjectTypeAndUid.project_type === 'Broadband' &&
                        }
                        {selectedProjectTypeAndUid.project_type === 'Domain Services' &&
                        
                        }
                        {selectedProjectTypeAndUid.project_type === 'Cloud Data Backup' &&
                            
                        }
                        {selectedProjectTypeAndUid.project_type === 'Virtual Machine Service' &&} */}
                        {/*   <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('location.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={location}
                                            type={'text'}
                                            placeholder={t('location.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setLocation(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('allocatedBandwidth.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={allocatedBandwidth}
                                            type={'text'}
                                            placeholder={t('allocatedBandwidth.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setAllocatedBandwidth(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('assignedIp.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={assignedIp}
                                            type={'text'}
                                            placeholder={t('assignedIp.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setAssignedIp(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('sideAAddress.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={sideAAddress}
                                            type={'text'}
                                            placeholder={t('sideAAddress.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setSideAAddress(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('sideBAddress.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={sideBAddress}
                                            type={'text'}
                                            placeholder={t('sideBAddress.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setSideBAddress(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('baseStation.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Input
                                        value={baseStation}
                                        type={'text'}
                                        placeholder={t('baseStation.label') + '*'}
                                        style={{ width: '100%' }}
                                        color={'secondary'}
                                        onChange={evt => setBaseStation(evt.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('domainName.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Input
                                        value={domainName}
                                        type={'text'}
                                        placeholder={t('domainName.label') + '*'}
                                        style={{ width: '100%' }}
                                        color={'secondary'}
                                        onChange={evt => setDomainName(evt.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('contactPersonDetails.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Input
                                        value={contactPersonDetails}
                                        type={'text'}
                                        placeholder={t('contactPersonDetails.label') + '*'}
                                        style={{ width: '100%' }}
                                        color={'secondary'}
                                        onChange={evt => setContactPersonDetails(evt.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('emailPackage.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Input
                                        value={emailPackage}
                                        type={'text'}
                                        placeholder={t('emailPackage.label') + '*'}
                                        style={{ width: '100%' }}
                                        color={'secondary'}
                                        onChange={evt => setEmailPackage(evt.target.value)}
                                    />
                                </Grid>
                            </Grid>
                             <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('domainOwner.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={domainOwner}
                                    type={'text'}
                                    placeholder={t('domainOwner.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setDomainOwner(evt.target.value)}
                                />
                            </Grid>
                        </Grid> 
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('domainServiceAttached.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={domainServiceAttached}
                                    type={'text'}
                                    placeholder={t('domainServiceAttached.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setDomainServiceAttached(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('addressService.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={addressService}
                                    type={'text'}
                                    placeholder={t('addressService.label') + '*'}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setAddressService(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('backupCapacity.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={backupCapacity}
                                            type={'text'}
                                            placeholder={t('backupCapacity.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setBackupCapacity(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('numberOfMachines.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={numberOfMachines}
                                            type={'text'}
                                            placeholder={t('numberOfMachines.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setNumberOfMachines(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('VMIP.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={VMIP}
                                            type={'text'}
                                            placeholder={t('VMIP.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setVMIP(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('internetCapacity.label')}*</Typography>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <Input
                                            value={internetCapacity}
                                            type={'text'}
                                            placeholder={t('internetCapacity.label') + '*'}
                                            style={{ width: '100%' }}
                                            color={'secondary'}
                                            onChange={evt => setInternetCapacity(evt.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        style={{
                                            ...marginGenerator('mt-5')
                                        }}>
                                        <OutlinedSelect
                                            label={`${t('VMSpecs.label')} *`}
                                            handleChange={evt => {
                                                setVMSpecs(evt.target.value);
                                            }}
                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                            styleOverrides={{ width: '100%', marginBottom: 10 }}
                                            options={VMSpecsType ? VMSpecsType : []}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment> */}
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            let getAttachAnyOtherDoc = [];
                            let getFinalServiceType = '';
                            if (selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services') {
                                if (!vlan || !serviceId || !PortNumber) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services' || selectedProjectTypeAndUid.project_type === 'Cloud Backup Services') {
                                if (!Capacity) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (selectedProjectTypeAndUid.project_type === 'Virtual Machine Service' || selectedProjectTypeAndUid.project_type === 'Doc AI' || selectedProjectTypeAndUid.project_type === 'Software Development' || selectedProjectTypeAndUid.project_type === 'Video AI' || selectedProjectTypeAndUid.project_type === 'IP Addresses' || selectedProjectTypeAndUid.project_type === 'Rack Hosting' || selectedProjectTypeAndUid.project_type === 'Elastic' || selectedProjectTypeAndUid.project_type === 'Mimecast') {
                                if (!OrderType || !OrderDescription) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (selectedProjectTypeAndUid.project_type === 'Cloud Backup Services') {
                                if (!BackupType) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (selectedProjectTypeAndUid.project_type === 'Dedicated Internet Access' || selectedProjectTypeAndUid.project_type === 'Broadband' || selectedProjectTypeAndUid.project_type === 'Access Points' || selectedProjectTypeAndUid.project_type === 'FTTH' || selectedProjectTypeAndUid.project_type === 'Metro (MPLS) Services' || selectedProjectTypeAndUid.project_type === 'Firewall Services' || selectedProjectTypeAndUid.project_type === 'Web Services') {
                                if (!ServiceType) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (selectedProjectTypeAndUid.project_type === 'Domain Services') {
                                if (!DNSHosting && !DNSRegistration && !MailHosting && !MailTurning && !MailSetup && !WebsiteSetup && !WebHosting && !AdditionalGigabyteStorage) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                } else {
                                    if (DNSHosting === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('DNSHosting.label');
                                    }
                                    if (DNSRegistration === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('DNSRegistration.label');
                                    }
                                    if (MailHosting === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('mailHosting.label');
                                    }
                                    if (MailTurning === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('mailTurning.label');
                                    }
                                    if (MailSetup === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('mailSetup.label');
                                    }
                                    if (WebsiteSetup === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('websiteSetup.label');
                                    }
                                    if (WebHosting === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('webHosting.label');
                                    }
                                    if (AdditionalGigabyteStorage === true) {
                                        getFinalServiceType = getFinalServiceType + ', ' + t('additionalGigabyteStorage.label');
                                    }
                                    getFinalServiceType = getFinalServiceType.slice(2)
                                }
                            } else {
                                getFinalServiceType = ServiceType;
                            }
                            if(!equipmentsToBeDeployed){
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            handleSubmitClick({
                                project_uid: selectedProjectTypeAndUid.uid,
                                client_name: clientName,
                                location: location,
                                equipments_to_be_deployed: equipmentsToBeDeployed,
                                assigned_Ip: assignedIp,
                                vlan: vlan,
                                service_Id: serviceId,
                                allocated_bandwidth: allocatedBandwidth,
                                base_station: baseStation,
                                domain_name: domainName,
                                contact_person_details: contactPersonDetails,
                                email_package: emailPackage,
                                vm_specs: VMSpecs,
                                backup_capacity: backupCapacity,
                                number_of_machines: numberOfMachines,
                                vm_ip: VMIP,
                                category: category,
                                on_hold: onHold,
                                // sideA_address: sideAAddress,
                                // sideB_address: sideBAddress,
                                // domain_owner: domainOwner,
                                // domain_service_attached: domainServiceAttached,
                                // address_service: addressService,
                                // capicity_allocated: internetCapacity,
                                // attachAnyOtherDoc: getAttachAnyOtherDoc,
                                service_id: serviceId,
                                vlan: vlan,
                                order_type: OrderType,
                                order_description: OrderDescription,
                                port_number: PortNumber,
                                capacity: Capacity,
                                service_type: getFinalServiceType,
                                backup_type: BackupType
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateActivationTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateActivationTeamModal);
