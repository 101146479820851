import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import QueryStatsIcon from '@material-ui/icons/BarChartRounded';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BasicTable from './BasicTable'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function ProductDetail({products, productName}) {
  const [open, setOpen] = React.useState(false);
  const [productData, setProductData] = useState()

useEffect(()=>{
  products ? setProductData(products) : setProductData()
},[products])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
    <div>
    <Button
                       color="secondary"
                       variant={'outlined'}
                       onClick={handleClickOpen}
                       >
                        <IconButton color="secondary" aria-label="View">
    <QueryStatsIcon /> {/* Pie chart icon */}
  </IconButton>
  <Typography variant="inherit">View</Typography>
                       </Button>


   
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${productName ? productName : ''}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
         <BasicTable 

      
         products={productData}


         
         
         />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          
          color="secondary"
          variant={'outlined'}
          onClick={handleClose} 
          >
            Close
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
}
