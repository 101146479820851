import React, { useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Message from '../../../../../../components/Message';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { REQUIRED_ERROR } from '../../../../../../utils/constants';
import { UPDATE_CYBER_SECURITY_FORM } from '../../mutations';

const useStyles = makeStyles(theme =>
    createStyles({
        formControl: {
            minWidth: '100%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
        iconEdit: {
            cursor: 'pointer',
            fontSize: 'large',
            marginLeft: '10px'
        }
    })
);

export default function UpdateCybersecurityAssignee({ id, reloadTickets, assigneeList, requestId }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [assignee, setAssignee] = React.useState();

    const handleChange = (event, newValue) => {
        setAssignee(newValue);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [updateCyberSecurityForm] = useMutation(UPDATE_CYBER_SECURITY_FORM, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            Message.success('Cyber Security Form Updated Successfully.');
            setOpen(false);
            setLoading(false);
            setAssignee();
            reloadTickets()
        }
    });

    return (
        <>
            <EditTwoToneIcon
                color="primary"
                onClick={handleOpen}
                className={classes.iconEdit}
            />

            <div>
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={'sm'}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        Change Cyber Security Incident Assignee
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                >
                                    {'Select Assignee'}*
                                </Typography>

                                <Autocomplete
                                    id="combo-box-demo"
                                    val={assignee}
                                    options={assigneeList && assigneeList.length > 0 ? assigneeList : []}
                                    getOptionLabel={option => `${option.name} ${option.surname} `}
                                    onChange={(event, newValue) => handleChange(event, newValue)}
                                    style={{ width: '100%' }}
                                    renderInput={params => (
                                        <div ref={params.InputProps.ref}>
                                            <input
                                                style={{
                                                    width: '94%',
                                                    padding: '18px',
                                                    border: '1px solid #ebebeb !important'
                                                }}
                                                type="text"
                                                {...params.inputProps}
                                            />
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button
                            variant={'contained'}
                            disabled={false}
                            color={'primary'}
                            onClick={() => {
                                if (!assignee.email) {
                                    Message.error(REQUIRED_ERROR)
                                    return null;
                                }
                                setLoading(true)
                                return updateCyberSecurityForm({
                                    variables: {
                                        id: id,
                                        assignee: assignee.email.toLowerCase(),
                                        requestId: requestId
                                    }
                                });
                            }}>
                            {loading ? <CircularProgress size={22} color="secondary" /> : 'Update Assignee'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}