import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateInstallTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    preValuesOfForm,
    allUserList,
    CurrentUserName,
    handleUploadFiles,
    handleProjectAttachmentClick,
    handleSelectTeam,
    handleViewCompleteDetailClick,
    isActionUpdate
}) {
    const classes = useStyles();
    const [changeControlForm, setChangeControlForm] = useState('');
    const [circuitId, setCircuitId] = useState('');
    const [attachMannual, setAttachMannual] = useState("");
    const [ImTeam, setImTeam] = useState('');
    const [comment, setComment] = useState('');
    const [platform, setPlatform] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamResourceList, setTeamResourceList] = useState([]);
    const [financeEmail, setFinanceEmail] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [documentDeleteLoading, setDocumentDeleteLoading] = useState(false);
    const platformList = [
        {
            id: 'BWA',
            name: "BWA"
        },
        {
            id: 'Alcatel',
            name: "Alcatel"
        },
        {
            id: 'Cable',
            name: "Cable"
        },
        {
            id: 'Ceragon',
            name: "Ceragon"
        },
        {
            id: 'CLoud Services',
            name: "CLoud Services"
        },
        {
            id: 'MIMOSA',
            name: "MIMOSA"
        },
        {
            id: 'ECI',
            name: "ECI"
        },
        {
            id: 'Fibre',
            name: "Fibre"
        },
        {
            id: 'Hosted Services',
            name: "Hosted Services"
        },
        {
            id: 'LTE',
            name: "LTE"
        },
        {
            id: 'M2M',
            name: "M2M"
        },
        {
            id: 'NEC Radio',
            name: "NEC Radio"
        },
        {
            id: 'Proxim',
            name: "Proxim"
        },
        {
            id: ' RADWIN (P2M)',
            name: " RADWIN (P2M)"
        },
        {
            id: ' RADWIN (P2P)',
            name: " RADWIN (P2P)"
        },
        {
            id: 'SAF',
            name: "SAF"
        },
        {
            id: 'Trunk',
            name: "Trunk"
        },
        {
            id: 'VSAT-IDIRECT',
            name: "VSAT-IDIRECT"
        },
        {
            id: 'VSAT-SCPC',
            name: "VSAT-SCPC"
        },
        {
            id: 'Wifi Access',
            name: "Wifi Access"
        }
    ];
    const teamList = [
        {
            id: "IM Team",
            name: "IM Team"
        },
        {
            id: "Change Control Team",
            name: "Change Control Team"
        },
        {
            id: "Integration Engineer Team",
            name: "Integration Engineer Team"
        },
        {
            id: "Quality Assurance Team",
            name: "Quality Assurance Team"
        },
        {
            id: "Project Management Team",
            name: "Project Management Team"
        },
        {
            id: "Finance Team",
            name: "Finance Team"
        },
        {
            id: "CSOC Team",
            name: "CSOC Team"
        },
    ];
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    React.useEffect(() => {
        if (allUserList) {
            if (preValuesOfForm && preValuesOfForm.project_type != t("newService.label") && preValuesOfForm.project_type != null && preValuesOfForm.project_type != '') {
                setTeamResourceList(allUserList);
            }
        }
        if (modalState.clearData === true) {
            setChangeControlForm('');
            setCircuitId('');
            setAttachMannual('');
            setImTeam('');
            setComment('');
            setPlatform('');
            setTeamName('');
            setTeamResourceList([]);
            setFinanceEmail('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setDocumentDeleteLoading(false);
            setCategory('');
        }
        if (isActionUpdate === true) {
            setPlatform(preValuesOfForm.platform);
            setCircuitId(preValuesOfForm.circuit_id);
            setImTeam(preValuesOfForm.im_team);
            setCircuitId(preValuesOfForm.circuit_id);
            setCircuitId(preValuesOfForm.circuit_id);
            setCircuitId(preValuesOfForm.circuit_id);
            setCircuitId(preValuesOfForm.circuit_id);
            setCircuitId(preValuesOfForm.circuit_id);


            setComment(preValuesOfForm.comment);
            setNextTeamMemberEmail(preValuesOfForm.next_team_member_email);
            setOnHold(preValuesOfForm.project_info && preValuesOfForm.project_info.status ? preValuesOfForm.project_info.status : '');
            setCategory(preValuesOfForm.project_info && preValuesOfForm.project_info.category ? preValuesOfForm.project_info.category : '');
        }
    }, [modalState, CurrentUserName, allUserList, preValuesOfForm, isActionUpdate]);

    React.useEffect(() => { }, [platform, circuitId, ImTeam, comment, onHold, category, nextTeamMemberEmail, preValuesOfForm])

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{isActionUpdate === true ? t('update.label') : t('create.label')} {t('installTeamForm.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : (preValuesOfForm.case_number ? preValuesOfForm.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.customer_name} / {preValuesOfForm.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewCompleteDetails.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={handleViewCompleteDetailClick}>{t('clickHereToViewCompleteDetails.label')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('ViewAttachedDocuments.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Button variant={'outlined'}
                                disabled={preValuesOfForm.documents && preValuesOfForm.documents.length == 0 ? true : false}
                                color={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleProjectAttachmentClick(preValuesOfForm.documents);
                                }}>{t('clickHereToViewFiles.label')}</Button>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : ''}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label')}
                                style={{ width: '100%' }}
                                disabled
                                color={'secondary'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                            val={platform}
                                label={`${t('platform.label')}`}
                                handleChange={evt => {
                                    setPlatform(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={platformList ? platformList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('circuitId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={circuitId}
                                type={'text'}
                                placeholder={t('circuitId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCircuitId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('attach.label')} {t('installationMannual.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                placeholder={t('attach.label') + ' ' + t('installationMannual.label')}
                                style={{ width: '100%' }}
                                disabled={((isActionUpdate === true) && (preValuesOfForm.attach_manual != "")) ? true : false}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setAttachMannual(file);
                                    } else {
                                        setAttachMannual("");
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.attach_manual) ?
                                <Typography variant={'body2'}> <Button variant={'outlined'}
                                    disabled={!preValuesOfForm.attach_manual ? true : false}
                                    style={{ marginBottom: 5 }}
                                    onClick={() => {
                                        if (preValuesOfForm.attach_manual != undefined) {
                                            window.open(preValuesOfForm.attach_manual);
                                        }
                                    }}><InsertDriveFileIcon></InsertDriveFileIcon> File 1</Button> <IconButton onClick={() => {
                                        setDocumentDeleteLoading(true);
                                        preValuesOfForm.attach_manual = '';
                                        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
                                    }} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                </Typography>
                                : ''}
                        </Grid>
                    </Grid>
                    {(preValuesOfForm.project_type === t("newService.label") || preValuesOfForm.project_type == null || preValuesOfForm.project_type == '' || isActionUpdate === true) ? <React.Fragment>
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2">{t('ImTeam.label')}*</Typography> */}
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('imTeam.label')} *`}
                                    val={ImTeam}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setImTeam(evt.target.value);
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={allUserList ? allUserList : []}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment> : <React.Fragment>
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2">{t('ImTeam.label')}*</Typography> */}
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('selectTeam.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value != '') {
                                            setTeamName(evt.target.value);
                                            handleSelectTeam(evt.target.value);
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={teamList ? teamList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2">{t('ImTeam.label')}*</Typography> */}
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('selectResource.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setImTeam(evt.target.value);
                                            for (let i = 0; i < allUserList.length; i++) {
                                                if (allUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(allUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={teamResourceList ? teamResourceList : []}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    }
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                val={category}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                val={onHold}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('previousComment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {preValuesOfForm.previous_comment && preValuesOfForm.previous_comment.length ?
                                JSON.parse(preValuesOfForm.previous_comment).map((item, index) => (
                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async (e) => {
                            e.preventDefault();
                            if (preValuesOfForm != '') {
                                if (!isActionUpdate) {
                                    if (!circuitId || !ImTeam || !nextTeamMemberEmail) {
                                        Message.error(REQUIRED_ERROR);
                                        return null;
                                    }
                                }
                                let getPreviousComment = preValuesOfForm.previous_comment ? JSON.parse(preValuesOfForm.previous_comment) : [];
                                if (comment != '') {
                                    if (isActionUpdate === true && preValuesOfForm.comment == comment) {
                                    } else {
                                        getPreviousComment.push({ name: CurrentUserName, comment: comment });
                                    }
                                }
                                let previous_comment = Object.entries(getPreviousComment).map(e => e[1]);
                                // console.log("formdata type : ", typeof (formData))
                                let getAttachManual = "";
                                if (attachMannual != "" && attachMannual != null) {
                                    let getAttachManualUrl = await handleUploadFiles(attachMannual, preValuesOfForm.project, 'install_team_section');
                                    if (getAttachManualUrl.data && getAttachManualUrl.data.allUploadFile && getAttachManualUrl.data.allUploadFile.url) {
                                        getAttachManual = getAttachManualUrl.data.allUploadFile.url;
                                    } else {
                                        Message.error("File not uploaded! Please try again.");
                                    }
                                }
                                if (isActionUpdate === true) {
                                    // merge document
                                    if (getAttachManual === "") {
                                        getAttachManual = preValuesOfForm.attach_manual;
                                    }
                                }
                                handleSubmitClick({
                                    formUid: isActionUpdate ? preValuesOfForm.uid : '',
                                    changeControlForm: preValuesOfForm.change_control_questionaire_form,
                                    circuitId: circuitId,
                                    attachMannual: getAttachManual,
                                    ImTeam: ImTeam,
                                    comment: comment,
                                    formName: 'IMForm',
                                    project_uid: preValuesOfForm.project,
                                    equipments_to_be_deployed: preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : '',
                                    previous_comment: JSON.stringify(previous_comment),
                                    auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                                    platform: platform,
                                    teamName: teamName,
                                    finance_email: financeEmail,
                                    next_team_member_email: nextTeamMemberEmail,
                                    category: category,
                                    on_hold: onHold,
                                });
                            } else {
                                if (!changeControlForm || !circuitId || !attachMannual || !ImTeam) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                                handleSubmitClick({ changeControlForm: changeControlForm, circuitId: circuitId, attachMannual: attachMannual, ImTeam: ImTeam, comment: comment, formName: 'IMForm' });
                            }
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateInstallTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateInstallTeamModal);
