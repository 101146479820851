import { gql } from 'apollo-boost';

/*
  Update service ticket
 */
export const UPDATE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $logMessage: String
    $serviceTicketId: String
    $title: String
    $mailThreadId: String
    $attachments: [String]
  ) {
    updateServiceTicket(
      logMessage: $logMessage
      serviceTicketId: $serviceTicketId
      title: $title
      mailThreadId: $mailThreadId
      attachments: $attachments
    ) {
      id
    }
  }
`;

/*
  Get service ticket details by subscription id
 */
  export const ADD_PRODUCT_TO_TICKET = gql`
  mutation AddProductToTicket(
    $ticketId : String
    $product : String
    ) {
      addProductToTicket(ticketId: $ticketId, product: $product ) {
     success
     message
    }
  }
`;

export const ADD_CIRCUIT_TO_TICKET = gql`
mutation AddCircuitToTicket(
  $ticketId : String
  $circuitId : String
  ) {
    addCircuitToTicket(ticketId: $ticketId, circuitId: $circuitId ) {
   success
   message
  }
}
`;

/*
  Close service ticket
 */
export const CLOSE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $id: String
    $currentUrl: String
    $ticketCloseTime: String
    $closeDescription: String
    $rfo : String
    $extraNote: String
    $vendorEndDate: String
    $equipemntReplaced: String
  ) {
    closeServiceTicket(
      id: $id
      currentUrl: $currentUrl
      ticketCloseTime: $ticketCloseTime
      rfo: $rfo
      closeDescription: $closeDescription
      extraNote: $extraNote
      vendorEndDate: $vendorEndDate
      equipemntReplaced: $equipemntReplaced
    ) {
      id
    }
  }
`;

/*
  Close mail service ticket
 */
export const CLOSE_MAIL_SERVICE_TICKET = gql`
  mutation MailServiceTickets($id: String) {
    closeMailServiceTicket(id: $id) {
      id
    }
  }
`;

/*
  Create service ticket
 */
export const CREATE_SERVICE_TICKET = gql`
  mutation ServiceTickets(
    $title: String
    $description: String
    $contactType: String
    $ticketType: String
    $country: String
    $region: String
    $customers: [String]
    $subscription: String
    $category: String
    $attachments: [String]
    $sites: [String]
    $selectedCustomer : [JSON]
    $incidentTypeGroup: String
    $circuitId: String
    $platform: String
    $linkType: String
    $natureOfCase: String
    $severity: String
    $incidentType: String
    $escalationLevel: String
    $vendor: String
    $assignee: String
    $incidentCause: String
    $incidentTime: String
    $vodacomProblem: Boolean
    $baseStation: String
    $location: [String]
    $additionalAgent1: String
    $additionalAgent2: String
    $salesSpecilistData: String
    $incidentEndTime: String
    $lusakaZMData: String
    $ndolaZMData: String
    $kitweZMData: String
    $livingStoneZMData: String
    $solweziZMData: String
    $lagosNGData: String
    $phcNGData: String
    $abujaNGData: String
    $vendorStartDate: String
    $vendorEndDate: String
    $projectCategory: String
    $reportComment: String
    $reportComment2: String
    $reportComment3: String
    $reportComment4: String
    $reportComment5: String
    $allCCEmails: [String]
  ) {
    createServiceTicket(
      title: $title
      description: $description
      contactType: $contactType
      customers: $customers
      ticketType: $ticketType
      country: $country
      region: $region
      subscription: $subscription
      category: $category
      selectedCustomer: $selectedCustomer
      attachments: $attachments
      sites: $sites
      circuitId: $circuitId
      platform: $platform
      incidentTypeGroup: $incidentTypeGroup
      linkType: $linkType
      natureOfCase: $natureOfCase
      severity: $severity
      incidentType: $incidentType
      escalationLevel: $escalationLevel
      vendor: $vendor
      assignee: $assignee
      incidentCause: $incidentCause
      incidentTime: $incidentTime
      vodacomProblem: $vodacomProblem
      baseStation: $baseStation
      location: $location
      additionalAgent1: $additionalAgent1
      additionalAgent2: $additionalAgent2
      salesSpecilistData: $salesSpecilistData
      incidentEndTime: $incidentEndTime
      lusakaZMData: $lusakaZMData
      ndolaZMData: $ndolaZMData
      kitweZMData: $kitweZMData
      livingStoneZMData: $livingStoneZMData
      solweziZMData: $solweziZMData
      lagosNGData: $lagosNGData
      phcNGData: $phcNGData
      abujaNGData: $abujaNGData
      vendorStartDate: $vendorStartDate
      vendorEndDate: $vendorEndDate
      projectCategory: $projectCategory
      reportComment: $reportComment
      reportComment2: $reportComment2
      reportComment3: $reportComment3
      reportComment4: $reportComment4
      reportComment5: $reportComment5
      allCCEmails: $allCCEmails
    ) {
      ticketId
    }
  }
`;

/*
  Create csoc service ticket
 */
export const CREATE_CSOC_SERVICE_TICKET = gql`
  mutation CsocServiceTickets(
    $title: String
    $description: String
    $customer: String
    $subscription: String
    $categoryId: String
    $sites: [String]
    $incidentTypeGroup: String
    $incidentType: String
    $escalationLevel: String
    $vendor: String
    $assignee: String
  ) {
    createCsocServiceTicket(
      title: $title
      description: $description
      customer: $customer
      subscription: $subscription
      categoryId: $categoryId
      sites: $sites
      incidentTypeGroup: $incidentTypeGroup
      incidentType: $incidentType
      escalationLevel: $escalationLevel
      vendor: $vendor
      assignee: $assignee
    ) {
      id
    }
  }
`;

export const UPDATE_MAIL_TICKET = gql`
  mutation ServiceTickets(
    $category: String
    $subscription: String
    $title: String
    $status: String
    $sites: [String]
    $country: String
    $ticketCloseTime: String
    $closeDescription: String
    $extraNote: String
    $vendorEndDate: String
    $equipemntReplaced: String
    $region: String
    $customer: String
    $serviceTicketId: String
    $priority: String
    $incidentTypeGroup: String
    $incidentType: String
    $escalationLevel: String
    $vendor: String
    $assignee: String
    $incidentCause: String
    $baseStation: String
    $location: String
    $lagosNGData: String
    $inqProblem : String
    $phcNGData: String
    $createdAt : String
    $abujaNGData: String
    $mailThreadId: String
    $circuitId: String
    $platform: String
    $vodacomProblem: Boolean
    $selectedCustomer : [JSON]
    $action: String
  ) {
    updateTicket(
      category: $category
      subscription: $subscription
      sites: $sites
      priority: $priority
      title: $title
      status: $status
      country: $country
      ticketCloseTime: $ticketCloseTime
      closeDescription: $closeDescription
      extraNote: $extraNote
      vendorEndDate: $vendorEndDate
      equipemntReplaced: $equipemntReplaced
      region: $region
      customer: $customer
      serviceTicketId: $serviceTicketId
      incidentTypeGroup: $incidentTypeGroup
      incidentType: $incidentType
      escalationLevel: $escalationLevel
      vendor: $vendor
      incidentCause: $incidentCause
      assignee: $assignee
      baseStation: $baseStation
      location: $location
      lagosNGData: $lagosNGData
      inqProblem : $inqProblem
      phcNGData: $phcNGData
      abujaNGData: $abujaNGData
      createdAt : $createdAt
      mailThreadId: $mailThreadId
      circuitId: $circuitId
      platform: $platform
      vodacomProblem: $vodacomProblem
      selectedCustomer: $selectedCustomer
      action: $action
    ) {
      id
    }
  }
`;

export const UPDATE_MAIL_TICKET_ASSIGNEE = gql`
  mutation ServiceTickets(
    $assignee: String
    $serviceTicketId: String
    $currentUrl: String
    $title: String
    $mailThreadId: String
    $ticketId: String
  ) {
    updateTicketAssignee(
      assignee: $assignee
      serviceTicketId: $serviceTicketId
      currentUrl: $currentUrl
      title: $title
      mailThreadId: $mailThreadId
      ticketId: $ticketId
    ) {
      id
    }
  }
`;

export const ADD_ASSIGNEE = gql`
  mutation addAssignee($ticketId: String, $assignTo: String) {
    assigneTicket(ticketId: $ticketId, assignTo: $assignTo) {
      id
    }
  }
`;

export const AUTO_ESCALATION_NOTIFICATION = gql`
  mutation ServiceTickets(
    $serviceTicketId: String
    $members: [String]
    $currentUrl: currentUrl
  ) {
    autoEscalateNotification(
      serviceTicketId: $serviceTicketId
      members: $members
      currentUrl: $currentUrl
    ) {
      id
    }
  }
`;

export const ADD_CUSTOMER_RATING = gql`
  mutation SupportRating($ticketId: String, $rating: String) {
    supportRating(ticketId: $ticketId, rating: $rating) {
      message
    }
  }
`;


export const ADD_CHANGE_MANAGEMENT_TICKET = gql`
  mutation CMTicket(
    $title: String
    $inCharge: String
    $changeImpact: String
    $description: String
    $changeType: String
    $lineManager: String
    $changeAgent: String
    $reserveAgent: String
    $reserveAgent2: String
    $implimentStartDate: String
    $implimentEndDate: String
    $risk: String
    $userAcceptanceTesting: String
    $rollbackPlan: String
    $attachments: [String]

  ) {
    addChangeManagement(
      title:  $title
      inCharge: $inCharge
      changeImpact: $changeImpact
      description:  $description
      changeType: $changeType
      lineManager: $lineManager
      changeAgent: $changeAgent
      reserveAgent: $reserveAgent
      reserveAgent2: $reserveAgent2
      implimentStartDate: $implimentStartDate
      implimentEndDate: $implimentEndDate
      risk: $risk
      userAcceptanceTesting: $userAcceptanceTesting
      rollbackPlan: $rollbackPlan
      attachments: $attachments
    ) {
      title
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $title: String
    $id: String
    $description: String
    $email: String
    $attachments: [String]
    $ticketTitle: String
    $mailThreadId : String
    $allCCEmails: [String]
    $serviceTicketId: String
    $ticketId: String
    $createdOn: String
    $userName: String
  ){
    updateCustomer(
      title: $title
      id: $id
      description: $description
      email: $email
      allCCEmails: $allCCEmails
      mailThreadId: $mailThreadId
      attachments: $attachments
      serviceTicketId: $serviceTicketId
      ticketTitle: $ticketTitle
      ticketId: $ticketId
      createdOn: $createdOn
      userName: $userName
    ){
      title
    }
  }
`;

export const UPDATE_CHANGE_MANAGEMENT_TICKET = gql`
mutation CMTicket(
  $id: String
  $inCharge: String
  $title: String
  $status: String
  $changeMatrixImpact: String
  $changeUrgency: String
  $changeImpact: String
  $description: String
  $changeType: String
  $lineManager: String
  $changeAgent: String
  $reserveAgent: String
  $reserveAgent2: String
  $implimentStartDate: String
  $implimentEndDate: String
  $risk: String
  $userAcceptanceTesting: String
  $rollbackPlan: String
  $lineManagerComment: String
  $cabLeadComment: String
  $eHOD: String
  $EHODComment: String
  $attachments: [String]
) {
  updateChangeManagement(
    id: $id
    inCharge: $inCharge
    title:  $title
    status: $status
    changeImpact: $changeImpact
    changeMatrixImpact: $changeMatrixImpact
    changeUrgency: $changeUrgency
    description:  $description
    changeType: $changeType
    lineManager: $lineManager
    changeAgent: $changeAgent
    reserveAgent: $reserveAgent
    reserveAgent2: $reserveAgent2
    implimentStartDate: $implimentStartDate
    implimentEndDate: $implimentEndDate
    risk: $risk
    userAcceptanceTesting: $userAcceptanceTesting
    rollbackPlan: $rollbackPlan
    lineManagerComment: $lineManagerComment
    cabLeadComment: $cabLeadComment
    eHOD: $eHOD
    EHODComment: $EHODComment
    attachments: $attachments
  ) {
    title
  }
}
`;

export const CLOSE_CM_REQUEST = gql`
  mutation CMTicket($id: String $status: String){
    updateCMRequest(id: $id status: $status){
      id
    }
  } 
`;