import React, { useState } from 'react';
import { get } from "lodash-es";
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import { marginGenerator } from '../../../../theme/utils';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NodeManagementItem from './NodeManagementItem'
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import NodeDetailsModal from './NodeDetailsModal';

let filterdMenus = [];

function NodeManagementPanel({ 
    t,
    menuItems,
    list,
    handleCreateButtonClick,
    handleMenuItemClick,
    loading,
    error,
    allowConfigure,
    extraDetails,
    organization,
    page,
    handlePageChange
}) {

    const [nodeDetailsModelState, setNodeDetailsModelState] = useState(false);
    const [nodeCamras, setNodeCamras] = useState([]);
    const role = localStorage.getItem('role');

    let columns = [];
    
    React.useEffect(() => {
        filterdMenus = menuItems.filter(item => {
            if (allowConfigure && item.label === t('delete.label')){
                return item;
            }
        });
        if(extraDetails){
            filterdMenus.push({
                label: t("manage.label")
            });
        }
    }, []);

    if(extraDetails === true){
        columns = [
            {
                label: t('name.label'),
                key:"name"
            },
            {
                label: t('nodeId.label'),
                key:"nodeId"
            },
            {
                label: t('orgId.label'),
                key:"orgId"
            },
            {
                label: t('mappedCamId.label'),
                key:"mappedCamId"
            },
            {
                label:t('mode.label'),
                key:"mode"
            },
            {
                label:t('instanceIp.label'),
                key:"instanceIp"
            },
            {
                label:t('numberOfAttachedCam.label'),
                key:"noOfCam"
            },
            {
                label: t('limit.label'),
                key: 'limit'
            },
            {
                label:t('status.label'),
                key:"status"
            },
            {
                label:t('createdDate.label'),
                key:"createdDate"
            }
        ]
    }else{
        columns = [
            {
                label: t('name.label'),
                key:"name"
            },
            {
                label:t('mode.label'),
                key:"mode"
            },
            {
                label:t('instanceIp.label'),
                key:"instanceIp"
            },
            {
                label:t('numberOfAttachedCam.label'),
                key:"noOfCam"
            },
            {
                label: t('limit.label'),
                key: 'limit'
            },
            {
                label:t('status.label'),
                key:"status"
            },
            {
                label:t('createdDate.label'),
                key:"createdDate"
            }
        ]
    }

    if(allowConfigure){
        columns.push({
            label:`${t('edit.label')}/${t('delete.label')}/${t('manage.label')}`,
            key:"button"
        });
    }else if(extraDetails){
        columns.push({
            label:t('manage.label'),
            key:"button"
        });
    }

    const handleViewDetails = (data) => {
        // console.log("data", data);
        setNodeCamras(data);
        setNodeDetailsModelState(true);
    }

    return (
        <div>
            {allowConfigure && 
                <Grid style={{ display: 'flex', alignItems: 'right' }} style={{...marginGenerator('mb-20'), float: 'right'}} >
                    <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() =>
                        handleCreateButtonClick('node')
                    }>
                        {t('createNow.label')}
                    </Button>
                </Grid>
            }
            <Grid container >
                <TableContainer>
                    <Table>
                        <TableHead>
                        <TableRow>
                            {columns.map((item, index) => {
                                return ( 
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            { !loading && !error && list.getNodeList ? 
                                list.getNodeList.nodes && list.getNodeList.nodes.length > 0 ?
                                list.getNodeList.nodes.map((rowitem, rowIndex) => (
                                    <NodeManagementItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        menuItems={filterdMenus}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={(!allowConfigure) ? extraDetails : allowConfigure}
                                        extraDetails={extraDetails}
                                        organization={organization}
                                        handleViewDetails={handleViewDetails}
                                    />
                                )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color="primary"/> }
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    { !loading &&
                        <Grid container justify={'space-between'} style={{marginTop: 20, marginBottom: 30, justifyContent:'flex-end',alignItems:'center'}}>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={2}>
                                <span>Total {Math.ceil(get(list, 'getNodeList.count', null)/20)} page,  {get(list, 'getNodeList.count', null)} Row</span>
                            </Grid>
                            <Grid item xs={4}>
                                <Pagination 
                                    color="secondary"
                                    count={(list && list.getNodeList) ? Math.ceil(list.getNodeList.count/20) : 1} 
                                    page={page} 
                                    onChange={(evt, value) => {
                                        handlePageChange("node", value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                </TableContainer>
            </Grid>
            {nodeCamras.length > 0 && <NodeDetailsModal t={t} cameras={nodeCamras} open={nodeDetailsModelState} handleClose={() => { 
                setNodeDetailsModelState(false);
                setNodeCamras([]);
            }}/>}
        </div>
    );
}

NodeManagementPanel.propTypes = {
    t: PropTypes.func.isRequired,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default NodeManagementPanel;
