import React, { useContext, useRef, useState } from 'react';
import { get } from 'lodash-es';
import { useLazyQuery } from '@apollo/react-hooks';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../utils';
import Message from '../../../../../../../components/Message';
import PrintSlip from '../../../components/PrintSlip';
import { GET_SHAYONA_TRIP_DETAILS } from '../../../../../Shayona/queries';
import {
  STOP_COUNTING_TRIP,
  RESUME_COUNTING_TRIP
} from '../../../../../Shayona/mutations';
import { ShayonaCementsContext } from '../../../../../../../contexts/ShayonaCementsProvider';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import SpeedIcon from '@material-ui/icons/Speed';
import UndoIcon from '@material-ui/icons/Undo';

function ButtonComponent(props) {
  const { t, onClick, loading, data } = props;
  return (
    <Button color="primary" onClick={onClick}>
      {loading && <CircularProgress size={19} />}
      {!loading && data.active ? (
        <DeleteIcon color="secondary" />
      ) : !loading && !data.active ? (
        <UndoIcon color="secondary" />
      ) : (
        ''
      )}
    </Button>
  );
}

function ButtonComponentOne(props) {
  const { t, onClick, loading } = props;
  return (
    <Button color="primary" onClick={onClick}>
      {loading && <CircularProgress size={19} />}
      {!loading && <PrintIcon color="secondary" />}
    </Button>
  );
}

const ShayonaTripsItem = ({
  key,
  t,
  data,
  deleteTripLoading,
  loadingBagsTripLoading,
  exitPrintLoading,
  selecteIdForDelete,
  handleSubmitOfTripActions,
  handleEnableModal,
  organizationId,
  //handleClearFilter
}) => {
  const commonClasses = commonStyles();
  const componentRef = useRef();
  const {
    reloadShayonaApisOrPagination,
  } = useContext(ShayonaCementsContext);
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current;
    }
  });

  const tripsStatusNames = {
    entry: 'Entry',
    slip_printed: 'Slip Printed',
    tare_weighed: 'Tare Weighted',
    loading_done: 'Loading Done',
    exited: 'Exited',
    loading_started: 'Loading started',
    loading_stopped: 'Loading Stopped',
    loading_resumed: 'Loading Resumed'
  };

  const [apiTimer, setApiTimer] = useState(0);
  const [loadingState, setLoadingState] = useState(true);
  const [bagsLoaded, setBagsLoaded] = useState('-');

  const [stopCountingTrip, { loading: stopCountingLoading }] = useMutation(
    STOP_COUNTING_TRIP,
    {
      onError: error => {
        console.log('err', error);
      },
      onCompleted: data => {
        Message.success(data && data.stopCountingTripTruck.message);
        reloadShayonaApisOrPagination('trip','');
        //handleClearFilter();
      }
    }
  );
  const [resumeCountingTrip, { loading: resumeCountingLoading }] = useMutation(
    RESUME_COUNTING_TRIP,
    {
      onError: error => {
        console.log('err', error);
      },
      onCompleted: data => {
        Message.success(data && data.resumeCountingTripTruck.message);
        reloadShayonaApisOrPagination('trip','');
        //handleClearFilter();
      }
    }
  );

  const [getShayonaTripDetails] = useLazyQuery(GET_SHAYONA_TRIP_DETAILS, {
    fetchPolicy: 'no-cache',
    onError: e => {
      console.log('e', e);
    },
    onCompleted: res => {
      if (res?.getTripDetails) {
        setBagsLoaded(res?.getTripDetails?.bags_loaded);
        if (res?.getTripDetails?.bags_loaded !== '') {
          setLoadingState(false);
        }
      }
    }
  });
  const ReCallAPIEvery30Second = () => {
    return getShayonaTripDetails({
      variables: { organization: organizationId, tripId: data.trip_id }
    });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setApiTimer(apiTimer + 1);
      //data && data.status === 'tare_weighed' && data.bags_loaded === '-' && ReCallAPIEvery30Second();
      data &&
        data.status === 'loading_started' &&
        data.bags_loaded === '-' &&
        ReCallAPIEvery30Second();
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [apiTimer]);

  React.useEffect(() => {
    setBagsLoaded(data && data.bags_loaded);
  }, [data]);
  return (
    <>
      <TableRow key={data.id}>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? data.truck_number : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? dateFormatterLLL(data.detected_on) : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? tripsStatusNames[data.status] : '-'}
              </Typography>
            </Grid>
            {data.status === 'slip_printed' && (
              <Grid style={{ marginLeft: 5 }}>
                {(data?.bags_allocated !== '-' ||
                  data?.bags_allocated !== null) &&
                data?.bags_allocated ? (
                  <Button color="primary" onClick={handlePrint}>
                    <PrintIcon color="secondary" />
                  </Button>
                ) : null}
              </Grid>
            )}
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? data.empty_weight : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? data.bags_allocated : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {/* {data? data.bags_loaded: '-'} */}
                {loadingState &&
                data &&
                data.status === 'loading_started' &&
                data.bags_loaded === '-' ? (
                  <CircularProgress size={19} />
                ) : (
                  bagsLoaded
                )}
                {/* {loadingState &&
                data &&
                data.status === 'loading_stopped' &&
                data.bags_loaded === '-' ? (
                  <>
                    {' '}
                    <CircularProgress size={19} /> <PlayArrowIcon size={19}  onClick={() =>
                        resumeCountingTrip({
                          variables: {
                            tripId: data?.trip_id,
                            organization:organizationId,
                          }
                        })
                      } />{' '}
                  </>
                ) : (
                  bagsLoaded
                )} */}
                {/* { loadingState && data && data.status === 'tare_weighed' && data.bags_loaded === '-' ? <CircularProgress size={19} /> : bagsLoaded }  */}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? data.gross_weight : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              <Typography variant={'subtitle1'}>
                {data ? dateFormatterLLL(data.updated_at) : '-'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid className={commonClasses.column}>
            <Grid>
              {/* for entry row */}
              {data &&
                data.status.toUpperCase() ===
                  tripsStatusNames.entry.toUpperCase() && (
                  <>
                    <Button
                      color="primary"
                      onClick={() => handleEnableModal('print', data)}>
                      <PrintIcon color="secondary" />
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleEnableModal('edit', data)}>
                      <EditIcon color="secondary" />
                    </Button>
                    <ButtonComponent
                      t={t}
                      loading={
                        selecteIdForDelete === data.trip_id
                          ? deleteTripLoading
                          : false
                      }
                      onClick={() => handleSubmitOfTripActions(data, 'delete')}
                      data={data}
                    />
                  </>
                )}
              {/* for slip_printed row */}
              {data && data.status === 'slip_printed' && (
                <Button
                  color="primary"
                  onClick={() => handleEnableModal('lodingbags', data)}>
                  <SpeedIcon color="secondary" />
                </Button>
              )}
              {/* for tare_weighed row */}
              {data && data.status === 'tare_weighed' && (
                <Button
                  color="primary"
                  onClick={() => handleEnableModal('weight', data)}>
                  <PublishIcon color="secondary" />
                </Button>
              )}
              {data && data.status === 'loading_done' && (
                <Button
                  color="primary"
                  onClick={() => handleEnableModal('exit', data)}>
                  <PrintIcon color="secondary" />
                </Button>
              )}
              {data && data.status === 'loading_started' && (
                <>
                  <Button color="primary">
                    <CircularProgress size={19} />
                  </Button>
                 <Button color="primary" loading={stopCountingLoading}>
                 <PauseIcon
                    size={19}
                    onClick={() =>
                      stopCountingTrip({
                        variables: {
                          tripId: data?.trip_id
                        }
                      })
                    }
                  />
                  </Button>
                </>
              )}
              {data && data.status === 'loading_stopped' && (
                <Button color="primary">
                <PlayArrowIcon
                  size={19}
                  onClick={() =>
                    resumeCountingTrip({
                      variables: {
                        tripId: data?.trip_id,
                        organization: organizationId
                      }
                    })
                  }
                />
                </Button>
              )}
               {data && data.status === 'loading_resumed' && (
                <>
                  <Button color="primary">
                    <CircularProgress size={19} />
                  </Button>
                 <Button color="primary" loading={stopCountingLoading}>
                 <PauseIcon
                    size={19}
                    onClick={() =>
                      stopCountingTrip({
                        variables: {
                          tripId: data?.trip_id
                        }
                      })
                    }
                  />
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <div style={{ display: 'none' }}>
        <PrintSlip
          t={t}
          type="slip_print"
          ref={componentRef}
          truckDetails={data}
          formDetails={{}}
        />
      </div>
    </>
  );
};

export default ShayonaTripsItem;
