import React, { useState, Fragment, useEffect } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete
} from "@react-google-maps/api";
import { get } from "lodash-es";
import { colors } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import '../../../../components/Map/mapStyle.css';

const useStyles = makeStyles(theme => ({
  searchInput: {
    background: "#fff",
    top: 8,
    left: 12,
    minWidth: 270
  }
}));


const EventsMap = ({ id, markers, handleMarkerClick, infoWindowData, infoWindowLoading, closeInfoWindow }) => {
  
  // The things we need to track in state
  const [mapRef, setMapRef] = useState(null);
  const [autocompleteRef, setAutocompleteRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [statusInfoWindowData, setStatusInfoWindowData] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if(markers && markers.length){
      setFirstLoad(true);
      setCenter(markers[0].position);
    }
  }, [markers]);

  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD1d45qiv804kgEGCiHHwyQFLXzIxaoroE",
    libraries: ["places", "visualization"]
  });

  const loadHandler = map => {
    setMapRef(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.placeId]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    if(id === "events-map"){
      handleMarkerClick(place.placeId);
    }else{
      setStatusInfoWindowData(place.infoData);
    }
  };

  const onLoadAutoComplete = (autocomplete) => {
    setAutocompleteRef(autocomplete);
  }

  const onSearchPlaceChanged = () => {
    if (autocompleteRef !== null) {
      const selectedPlace = autocompleteRef.getPlace();
      if(selectedPlace && selectedPlace.geometry && selectedPlace.geometry.location){
        let lat = selectedPlace.geometry.location.lat();
        let lng = selectedPlace.geometry.location.lng();
        setClickedLatLng({lat, lng});
        setCenter({lat, lng});
      }else{
        setClickedLatLng(null);
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  return (
    <Fragment>
      { isLoaded ?
        <GoogleMap
          id="searchbox-example"
          onLoad={loadHandler}
          onClick={e => setClickedLatLng(e.latLng.toJSON())}
          center={center}
          zoom={zoom}
          mapContainerStyle={{
            height: "328px",
            width: "100%"
          }}
        >
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={onSearchPlaceChanged}
          >
            <TextField className={classes.searchInput} id="standard-basic" placeholder="Search" variant="standard" />
          </Autocomplete>
          {clickedLatLng && 
            <Marker key="marker_1"
              position={clickedLatLng}
            />
          }
          {markers && markers.length && markers.map((place) => {
              let fillColor = colors.common.green;
              if(id === "cam-status-map"){
                fillColor = place.infoData && place.infoData.inactiveCameras && place.infoData.inactiveCameras.length > 0 ? colors.error.main : colors.common.green;
              }else{
                fillColor = place.markerColor ? place.markerColor : fillColor;
              }
              return (
                <Marker
                    key={place.placeId}
                    position={place.position}
                    onLoad={marker => markerLoadHandler(marker, place)}
                    onClick={event => markerClickHandler(event, place)}
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: fillColor,
                        fillOpacity: 1,
                        scale: 7,
                        strokeColor: colors.common.white,
                        strokeWeight: 4,
                    }}
                />
              )
          })}
          {infoOpen && selectedPlace && (
              <InfoWindow
                anchor={markerMap[selectedPlace.placeId]}
                onCloseClick={() => {
                  setInfoOpen(false);
                  setSelectedPlace(null);
                  closeInfoWindow();
                }}
              >
                <div id="iw-container">
                  <div class="iw-content">
                        <div class="iw-subTitle">{selectedPlace.title ? selectedPlace.title : "-"}</div>
                        {id === "cam-status-map" ?
                          <>
                            <div class="iw-item">Number of cameras :  {statusInfoWindowData ? statusInfoWindowData.totalCameras : 0}</div>
                            <div class="iw-item">Number of up cameras:  {statusInfoWindowData ? statusInfoWindowData.activeCameras : 0}</div>
                            <div class="iw-item">Number of down cameras:  {statusInfoWindowData && statusInfoWindowData.inactiveCameras ? statusInfoWindowData.inactiveCameras.length : 0}</div>
                            <div class="iw-item"><b>Down cameras list :</b><br/>
                              { statusInfoWindowData && statusInfoWindowData.inactiveCameras && statusInfoWindowData.inactiveCameras.map(cam => <React.Fragment id={cam.name}><span class="iw-item">{cam.name}</span><br/></React.Fragment>)}
                            </div>
                          </>
                        : id === "events-map" ?
                          <>
                            {infoWindowLoading && <div class="iw-item">Loading...</div>}
                            {!infoWindowLoading && infoWindowData &&
                              <>
                                <div class="iw-item">Number of cameras :  {infoWindowData.ag_data ? infoWindowData.ag_data.totalCameras : 0}</div>
                                <div class="iw-item">Number of models :  {infoWindowData.ag_data ? infoWindowData.ag_data.totalModels : 0}</div>
                                <div class="iw-item">Number of models enabled : {infoWindowData.ag_data ? infoWindowData.ag_data.activeModels : 0}</div>
                                <div class="iw-item">Number of events : {infoWindowData.ag_data ? infoWindowData.ag_data.totalEvents : 0}</div>
                              </>
                            }
                          </>
                        : 
                        <>
                            {!infoWindowLoading &&
                              <>
                                <div class="iw-item">Number of Projects :  {selectedPlace ? get(selectedPlace, 'infoData.info.project', '') : 0}</div>
                                <div class="iw-item">Number of Folders :  {selectedPlace ? get(selectedPlace, 'infoData.info.folder', '') : 0}</div>
                                <div class="iw-item">Number of Docs : {selectedPlace ? get(selectedPlace, 'infoData.info.docs', '') : 0}</div>
                              </>
                            }
                          </> }
                  </div>
                </div>
              </InfoWindow>
          )}
        </GoogleMap>
      : null}
    </Fragment>
  )
}

export default EventsMap;