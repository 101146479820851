import * as React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {dateFormatterLLL} from '../../../../../utils';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MenuWrapper from '../../../../../components/MenuWrapper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Icon from '@material-ui/core/Icon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const MarketPlaceItem = ({
                             t,
                             item,
                             key,
                             handleMenuItemClick,
                             classes,
                             menuItems
                         }) => {

    const commonClasses = commonStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <Grid style={{float: 'right'}} key={key}>
                <IconButton
                    size={'small'}
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'secondary'}
                    onClick={evt => {
                        setAnchorEl(evt.currentTarget);
                        evt.stopPropagation();
                    }}>
                    <MoreHoriz
                        className={commonClasses.iconRegular}/>
                </IconButton>
            </Grid>
            <MenuWrapper
                id={'ticket-menu'}
                anchorEl={anchorEl}
                handleClose={evt => {
                    evt.stopPropagation();
                    setAnchorEl(null);
                }}
                menuItems={menuItems}
                handleMenuItemClick={elem => {
                    handleMenuItemClick(elem, item._id, 'vnf');
                    setAnchorEl(null);
                }}
            />
            <ListItem alignItems="flex-start"
                      className={classes.listItem}>
                <div className={classes.image}>
                    <img alt="Cindy Baker" className={classes.cover}
                         src={item.imageLogo}/>
                </div>
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="h4"
                            variant="body2"
                            className={classes.title}
                            color="textPrimary"
                        >
                            {item.imageName}
                        </Typography>
                    </React.Fragment>}
                    secondary={
                        <React.Fragment>
                            {/*  <Typography
                                                                component="span"
                                                                variant="body2"
                                                                className={classes.inline}
                                                                color="textPrimary"
                                                            >
                                                                {item.imageType}
                                                            </Typography>
                                                            {' — ' + item.createdAt}*/}
                            {/*<Typography
                                                                variant="body2"
                                                                className={classes.description}
                                                                color="textPrimary"
                                                            >
                                                                <Rating name="read-only" value={value} readOnly/> {' — 2 AWS review'}
                                                            </Typography>*/}
                            <Typography paragraph
                                        className={classes.description}>
                                {item.description}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li"/>
        </>
    );
};

MarketPlaceItem.propTypes = {
    data: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    handleMenuItemClick: PropTypes.func
};

export default withTranslation()(MarketPlaceItem);
