import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Message from '../../../../../../../../components/Message';
// import { REQUIRED_ERROR } from '../../../../../../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, Typography } from '@material-ui/core';
import { marginGenerator } from '../../../../../../../../theme/utils';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormGroup } from '@material-ui/core';
import DateTimeSelector from '../../../../../../../../components/DateTimeSelector';
import SignIcon from '../../../../../../../../assets/icons/sign-pen.png';
import commonStyles from '../../../../../../../../theme/commonStyles';
import ProposalHeader from '../../../ProposalHeader';

const useStyles = makeStyles(theme => ({
  paper: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  blueBorder: {
    border: '5px solid ',
    borderRight: '5px solid #06373e',
    borderTop: '5px solid #2e8a88',
    borderBottom: '5px solid #2e8a88',
    borderLeft: '5px solid #47cfc7',
    width: '350px',
    padding: '50px',
    borderRadius: '12px'
  },
  blackBorder: {
    border: '0.5px solid black',
    outline: '0.5px solid',
    padding: '0px'
  },
  root: {
    flexGrow: 1
  },
  input: {
    minWidth: '224px',
    marginTop: '25px',
    border: '0px',
    outline: 'none'
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(1.25, 3),
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    border: '1px solid #b8b8b8'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const urInitFields = {
  id: '',
  name: '',
  surName: '',
  site: '',
  idNo: '',
  employeeSecurityNo: '',
  title: '',
  maritalStatus: '',
  email: '',
  postal: '',
  telNo: '',
  residential: '',
  mobile: '',
  inceptionDateFrom: '',
  inceptionDateTo: '',
  premiumPaymentMethod: '',
  buildingResidentialAddressFirst: '',
  buildingResidentialAddressSecond: '',
  buildingResidentialAddresThird: '',
  buildingResidenceRentedFirst: '',
  buildingResidenceRentedSecond: '',
  buildingResidenceRentedThird: '',
  buildingRoofConstructionOfWallsFirst: '',
  buildingRoofConstructionOfWallsSecond: '',
  buildingRoofConstructionOfWallsThree: '',
  buildingRoofConstructionFirst: '',
  buildingRoofConstructionSecond: '',
  buildingRoofConstructionThree: '',
  buildingCurrentlyOccupiedFirst: '',
  buildingCurrentlyOccupiedSecond: '',
  buildingCurrentlyOccupiedThird: '',
  buildingInoccupancyFirst: '',
  buildingInoccupancySecond: '',
  buildingInoccupancyThree: '',
  buildingInsuranceReplacementFirst: '',
  buildingInsuranceReplacementSecond: '',
  buildingInsuranceReplacementThree: '',
  buildingBondholderFirst: '',
  buildingBondholderSecond: '',
  buildingBondholderThree: '',
  buildingFirstThatchedStructures: '',
  buildingSecondThatchedStructures: '',
  buildingThreeThatchedStructures: '',
  buildingOneSpecify: '',
  buildingTwoSpecify: '',
  buildingThreeSpecify: '',
  residentalAddressFirst: '',
  residentalAddressSecond: '',
  StreetAndLocationFirst: '',
  StreetAndLocationSecond: '',
  constructionOfWallsFirst: '',
  constructionOfWallsSecond: '',
  roofConstructionFirst: '',
  roofConstructionSecond: '',
  isTheResidenceRentedFirst: '',
  isTheResidenceRentedSecond: '',
  residenceOccupiedFirst: '',
  residenceOccupiedSecond: '',
  unoccupancyPerAnnumFirst: '',
  unoccupancyPerAnnumSecond: '',
  haveAnAlarmSystemFirst: '',
  haveAnAlarmSystemSecond: '',
  IsTheAlarmLinkedFirst: '',
  IsTheAlarmLinkedSecond: '',
  responseCompanyFirst: '',
  responseCompanySecond: '',
  unspecifiedItems: '',
  descriptionSumInsured: '',
  unspecifiedItemsSectionThree: '',
  descriptionSumSectionThree: '',
  motorVechileTypeOfCoverFirst: '',
  motorVechileTypeOfCoverSecond: '',
  motorVechileTypeOfCoverThird: '',
  motorVechileUseOfVechileFirst: '',
  motorVechileUseOfVechileSecond: '',
  motorVechileUseOfVechileThird: '',
  motorVechileYearOfManufautreFirst: '',
  motorVechileYearOfManufautreSecond: '',
  motorVechileYearOfManufautreThird: '',
  motorVechileMakeAndModelFirst: '',
  motorVechileMakeAndModelSecond: '',
  motorVechileMakeAndModelThird: '',
  motorVechileColourFirst: '',
  motorVechileColourSecond: '',
  motorVechileColourThird: '',
  motorVechileRegistrationNumberFirst: '',
  motorVechileRegistrationNumberSecond: '',
  motorVechileRegistrationNumberThird: '',
  motorVechileChasisFirst: '',
  motorVechileChasisSecond: '',
  motorVechileChasisThird: '',
  motorVechileValueFirst: '',
  motorVechileValueSecond: '',
  motorVechileValueThird: '',
  motorVechileImportedVechileFrist: '',
  motorVechileImportedVechileSecond: '',
  motorVechileImportedVechileThird: '',
  motorVechileIMMobiliserFirst: '',
  motorVechileIMMobiliserSecond: '',
  motorVechileIMMobiliserThird: '',
  motorVechileTrackingDeviceFirst: '',
  motorVechileTrackingDeviceSecond: '',
  motorVechileTrackingDeviceThird: '',
  motorVechileMakeTrackingFirst: '',
  motorVechileMakeTrackingSecond: '',
  motorVechileMakeTrackingThird: '',
  motorVechileFinacialRegisterdOwnerFirst: '',
  motorVechileFinacialRegisterdOwnerSecond: '',
  motorVechileFinacialRegisterdOwnerThird: '',
  motorVechileFinacialProposerFirst: '',
  motorVechileFinacialProposerSecond: '',
  motorVechileFinacialProposerThird: '',
  motorVechileFinacialAccessoriesFirst: '',
  motorVechileFinacialAccessoriesSecond: '',
  motorVechileFinacialAccessoriesThird: '',
  motorVechileFinacialValuOfAccessoriesFirst: '',
  motorVechileFinacialValuOfAccessoriesSecond: '',
  motorVechileFinacialValuOfAccessoriesThird: '',
  motorVechileFinacialIntrustCarLoadFirst: '',
  motorVechileFinacialIntrustCarLoadSecond: '',
  motorVechileFinacialIntrustCarLoadThird: '',
  motorVechileBankFirst: '',
  motorVechileBankSecond: '',
  motorVechileBankThird: '',
  motorVechileLoanPeriodMonthFirst: '',
  motorVechileLoanPeriodMonthSecond: '',
  motorVechileLoanPeriodMonthThird: '',
  motorVechileRegDriverDetailsFirst: '',
  motorVechileRegDriverDetailsSecond: '',
  motorVechileRegDriverDetailsThird: '',
  motorVechileSurNameFirst: '',
  motorVechileSurNameSecond: '',
  motorVechileSurNameThird: '',
  motorVechileFirstNameFirst: '',
  motorVechileFirstNameSecond: '',
  motorVechileFirstNameThird: '',
  motorVechileDobFirst: '',
  motorVechileDobSecond: '',
  motorVechileDobThird: '',
  motorVechileEbbCoverdFirst: '',
  motorVechileEbbCoverdSecond: '',
  motorVechileEbbCoverdThird: '',
  motorVechileEaCoverdFirst: '',
  motorVechileEaCoverdSecond: '',
  motorVechileEaCoverdThird: '',
  makeAndModalFirst: '',
  makeAndModalSecond: '',
  makeAndModalThird: '',
  carvansFirstYear: '',
  carvansSecondYear: '',
  carvansThirdYear: '',
  carvansFirstValue: '',
  carvansSecondValue: '',
  carvansThirdValue: '',
  carvansRegistrationNumberFirst: '',
  carvansRegistrationNumberSecond: '',
  carvansRegistrationNumberThird: '',
  make: '',
  model: '',
  year: '',
  value: '',
  registrationNumber: '',
  use: '',
  motorCycleLocked: '',
  typeOfMotorCycle: '',
  FinancialInterestMotorcycleLoan: '',
  bank: '',
  loanPeriodMonthsLeft: '',
  sectionRequired: '',
  totalPremimum: '',
  nameOfInsurer: '',
  policyNumber: '',
  yearFirst: '',
  descriptionFirst: '',
  amountFirst: '',
  yearSecond: '',
  descriptionSecond: '',
  amountSecond: '',
  signatureOfInsuredDate: null,
  sesiroDate: null,
  sesiroAuthorizeDate: null,
  specifiedItemsFirst: '',
  serialNoFirst: '',
  sumInsuredFirst: '',
  premiumFirst: '',
  specifiedItemsSecond: '',
  serialNoSecond: '',
  sumInsuredSecond: '',
  premiumSecond: '',
  specifiedItemsThird: '',
  serialNoThird: '',
  sumInsuredThird: '',
  premiumThird: '',
  specifiedItemsFourth: '',
  serialNoFourth: '',
  sumInsuredFourth: '',
  premiumFourth: '',
  specifiedItemsFive: '',
  serialNoFive: '',
  sumInsuredFive: '',
  premiumFive: '',
  specifiedItemsSix: '',
  serialNoSix: '',
  sumInsuredSix: '',
  premiumSix: '',
  specifiedItemsSeven: '',
  serialNoSeven: '',
  sumInsuredSeven: '',
  premiumSeven: '',
  handBagSectionspecifiedItemsFirst: '',
  handBagSectionserialNoFirst: '',
  handBagSectionsumInsuredFirst: '',
  handBagSectionpremiumFirst: '',
  handBagSectionspecifiedItemsSecond: '',
  handBagSectionserialNoSecond: '',
  handBagSectionsumInsuredSecond: '',
  handBagSectionpremiumSecond: '',
  handBagSectionspecifiedItemsThird: '',
  handBagSectionserialNoThird: '',
  handBagSectionsumInsuredThird: '',
  handBagSectionpremiumThird: '',
  handBagSectionspecifiedItemsFourth: '',
  handBagSectionserialNoFourth: '',
  handBagSectionsumInsuredFourth: '',
  handBagSectionpremiumFourth: '',
  handBagSectionspecifiedItemsFive: '',
  handBagSectionserialNoFive: '',
  handBagSectionsumInsuredFive: '',
  handBagSectionpremiumFive: '',
  handBagSectionspecifiedItemsSix: '',
  handBagSectionserialNoSix: '',
  handBagSectionsumInsuredSix: '',
  handBagSectionpremiumSix: '',
  handBagSectionspecifiedItemsSeven: '',
  handBagSectionserialNoSeven: '',
  handBagSectionsumInsuredSeven: '',
  handBagSectionpremiumSeven: ''
};

const SesiroPropasolForm = ({
  t,
  open,
  data,
  handleClose,
  modalState,
  handleCreateProposalSubmit,
  handleOpenSignPanModal,
  datas = []
}) => {
  const [urFormState, setURFormState] = useState({ ...urInitFields });
  const classes = useStyles();
  const commonClasses = commonStyles();

  React.useEffect(() => {
    if (!open) {
      setURFormState({ ...urInitFields });
      handleClose();
    }
  }, [open, handleClose]);

  React.useEffect(() => {
    if (data && data._id) {
      const id = data._id;
      setURFormState({ ...data, id });
    } else {
      // setShowUserSelect(true);
      // setShowLearnDevelopment(true);
      // setShowHOD(true);
      // setShowHRManager(true);
      // setShowGeneralManager(true);
    }
  }, [data]);

  const handleChange = (value, name, key) => {
    setURFormState({ ...urFormState, [name]: value });
  };

  const columns = [
    {
      label: 'SPECIFIED ITEMS',
      key: 'SPECIFIED_ITEMS'
    },
    {
      label: 'SERIAL NO:',
      key: 'SERIAL_NO'
    },
    {
      label: 'SUM INSURED',
      key: 'SUM_INSURED'
    },
    {
      label: 'PREMIUM',
      key: 'PREMIUM'
    }
  ];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="sensor-template-modal"
      aria-describedby="sensor-template-modal"
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle id="sensor-template-title">
        <ProposalHeader/>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              PERSONAL DETAILS
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Name
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.name}
              onChange={evt => handleChange(evt.target.value, 'name')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Surname
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.surName}
              onChange={evt => handleChange(evt.target.value, 'surName')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Site
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.site}
              onChange={evt => handleChange(evt.target.value, 'site')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              ID No.
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.idNo}
              onChange={evt => handleChange(evt.target.value, 'idNo')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Employee / Security No.
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.employeeSecurityNo}
              onChange={evt =>
                handleChange(evt.target.value, 'employeeSecurityNo')
              }
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Title (Mr, Ms. etc.):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.title}
              onChange={evt => handleChange(evt.target.value, 'title')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Marital status
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `maritalStatus`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Single"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.maritalStatus === 'Single' ? true : false}
                labelPlacement="start"
                label="Single"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Married"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.maritalStatus === 'Married' ? true : false}
                label="Married"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Other"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.maritalStatus === 'Other' ? true : false}
                label="Other"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              ADDRESS
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Email
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.email}
              onChange={evt => handleChange(evt.target.value, 'email')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Postal
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.postal}
              onChange={evt => handleChange(evt.target.value, 'postal')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Tel no.
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.telNo}
              onChange={evt => handleChange(evt.target.value, 'telNo')}
            />
          </Grid>

          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Residential
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.residential}
              onChange={evt => handleChange(evt.target.value, 'residential')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Mobile
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.mobile}
              onChange={evt => handleChange(evt.target.value, 'mobile')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              PERIOD OF INSURANCE
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Inception Date (From):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.inceptionDateFrom}
              onChange={evt =>
                handleChange(evt.target.value, 'inceptionDateFrom')
              }
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              Inception Date (To):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={7}>
            <Input
              type={'text'}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.inceptionDateTo}
              onChange={evt =>
                handleChange(evt.target.value, 'inceptionDateTo')
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif'
              }}>
              PREMIUM PAYMENT METHOD (pick one):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={7}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `premiumPaymentMethod`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.premiumPaymentMethod === 'No' ? true : false
                }
                labelPlacement="start"
                label="ANNUAL
                                (e.g. Once off/ 3 months)"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.premiumPaymentMethod === 'Yes' ? true : false
                }
                label="MONTHLY
                                (e.g. Payroll deductions)"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              SECTION 1 - HOUSEOWNERS (BUILDINGS)
            </Typography>
          </Grid>
        </Grid>

        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BUILDING DETAILS
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BUILDING 1
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BUILDING 2
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BUILDING 3
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              RESIDENTIAL ADDRESS
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingResidentialAddressFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingResidentialAddressFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingResidentialAddressSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingResidentialAddressSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingResidentialAddresThird}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingResidentialAddresThird')
              }
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={11}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              TYPE OF RESIDENCE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the residence rented out?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `buildingResidenceRentedFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedFirst === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedFirst === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `buildingResidenceRentedSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `buildingResidenceRentedThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingResidenceRentedThird === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Construction of walls (e.g. Concrete, Asbestos etc.)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionOfWallsFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingRoofConstructionOfWallsFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionOfWallsSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingRoofConstructionOfWallsSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionOfWallsThree}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingRoofConstructionOfWallsThree'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Roof construction (e.g. Thatch, Tile, etc.)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingRoofConstructionFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingRoofConstructionSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.buildingRoofConstructionThree}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingRoofConstructionThree')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the residence currently occupied?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingCurrentlyOccupiedFirst`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedFirst === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedFirst === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingCurrentlyOccupiedSecond`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingCurrentlyOccupiedThird`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingCurrentlyOccupiedThird === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              If NO, state period of inoccupancy
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInoccupancyFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingInoccupancyFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInoccupancySecond}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingInoccupancySecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInoccupancyThree}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingInoccupancyThree')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Insurance Replacement Value
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInsuranceReplacementFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingInsuranceReplacementFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInsuranceReplacementSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingInsuranceReplacementSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingInsuranceReplacementThree}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'buildingInsuranceReplacementThree'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Bondholder (Financial Interest)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingBondholderFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingBondholderFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingBondholderSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingBondholderSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingBondholderThree}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingBondholderThree')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Are there any thatched structures apart from main building?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingFirstThatchedStructures`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingFirstThatchedStructures === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingFirstThatchedStructures === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingSecondThatchedStructures`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingSecondThatchedStructures === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingSecondThatchedStructures === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `buildingThreeThatchedStructures`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingThreeThatchedStructures === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.buildingThreeThatchedStructures === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Specify (Value)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingOneSpecify}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingOneSpecify')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingTwoSpecify}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingTwoSpecify')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.buildingThreeSpecify}
              onChange={evt =>
                handleChange(evt.target.value, 'buildingThreeSpecify')
              }
            />
          </Grid>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              SECTION 2 - HOUSEHOLDERS (CONTENTS)
            </Typography>
          </Grid>
        </Grid>
        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BUILDING DETAILS
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={4}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MAIN RESIDENCE
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={5}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              HOLIDAY HOME
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              RESIDENTIAL ADDRESS
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.residentalAddressFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'residentalAddressFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.residentalAddressSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'residentalAddressSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Plot No., Street and Location
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.StreetAndLocationFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'StreetAndLocationFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.StreetAndLocationSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'StreetAndLocationSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Construction of walls
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.constructionOfWallsFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'constructionOfWallsFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.constructionOfWallsSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'constructionOfWallsSecond')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Roof construction
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              style={{ border: '0px', outline: 'none' }}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.roofConstructionFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'roofConstructionFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              style={{ border: '0px', outline: 'none' }}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.roofConstructionSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'roofConstructionSecond')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the residence rented out?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `isTheResidenceRentedFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.isTheResidenceRentedFirst === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.isTheResidenceRentedFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={5}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `isTheResidenceRentedSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.isTheResidenceRentedSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.isTheResidenceRentedSecond === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the residence occupied during the day?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `residenceOccupiedFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.residenceOccupiedFirst === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.residenceOccupiedFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={5}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `residenceOccupiedSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.residenceOccupiedSecond === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.residenceOccupiedSecond === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              If NO, state period of unoccupancy per annum
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.unoccupancyPerAnnumFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'unoccupancyPerAnnumFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.unoccupancyPerAnnumSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'unoccupancyPerAnnumSecond')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Do you have an alarm system?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `haveAnAlarmSystemFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.haveAnAlarmSystemFirst === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.haveAnAlarmSystemFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={5}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `haveAnAlarmSystemSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.haveAnAlarmSystemSecond === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.haveAnAlarmSystemSecond === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the alarm linked to a response?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={4}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `IsTheAlarmLinkedFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.IsTheAlarmLinkedFirst === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.IsTheAlarmLinkedFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={5}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `IsTheAlarmLinkedSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.IsTheAlarmLinkedSecond === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.IsTheAlarmLinkedSecond === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Provide the name of the response company
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.responseCompanyFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'responseCompanyFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={5}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.responseCompanySecond}
              onChange={evt =>
                handleChange(evt.target.value, 'responseCompanySecond')
              }
            />
          </Grid>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20, marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h4">SECTION 3.a – ALL RISK</Typography>
          </Grid>
        </Grid>

        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={5}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              UNSPECIFIED ITEMS / PERSONAL EFFECTS
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={6}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.unspecifiedItems}
              onChange={evt =>
                handleChange(evt.target.value, 'unspecifiedItems')
              }
            />
          </Grid>

          <Grid
            className={classes.blackBorder}
            item
            xs={5}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              DESCRIPTION SUM INSURED
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={6}>
            <TextField
              type="textarea"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.descriptionSumInsured}
              onChange={evt =>
                handleChange(evt.target.value, 'descriptionSumInsured')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={11}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Wearing apparel and personal effects. Individual items less than
              P2500 limited to 25% of the householders Sum insured. Jewelry
              limited to 10% of Householders Sum Insured, unless specified
            </h5>
          </Grid>

          <FormGroup row style={{ marginBottom: 20 }}>
            <Grid
              className={classes.blackBorder}
              item
              xs={2}
              style={{ backgroundColor: '#6cccf560' }}>
              <h5
                className={classes.blackBorderTitle}
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  fontFamily: 'sans-serif',
                  marginLeft: 5
                }}>
                SPECIFIED ITEMS
              </h5>
            </Grid>
            <Grid
              className={classes.blackBorder}
              item
              xs={3}
              style={{ backgroundColor: '#6cccf560' }}>
              <h5
                className={classes.blackBorderTitle}
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  fontFamily: 'sans-serif',
                  marginLeft: 5
                }}>
                SERIAL NO.
              </h5>
            </Grid>
            <Grid
              className={classes.blackBorder}
              container
              xs={3}
              style={{ backgroundColor: '#6cccf560' }}>
              <h5
                className={classes.blackBorderTitle}
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  fontFamily: 'sans-serif',
                  marginLeft: 5
                }}>
                SUM INSURED
              </h5>
            </Grid>
            <Grid
              className={classes.blackBorder}
              item
              xs={3}
              style={{ backgroundColor: '#6cccf560' }}>
              <h5
                className={classes.blackBorderTitle}
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  fontFamily: 'sans-serif',
                  marginLeft: 5
                }}>
                PREMIUM
              </h5>
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsFirst}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsFirst')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoFirst}
                onChange={evt =>
                  handleChange(evt.target.value, 'serialNoFirst')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredFirst}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredFirst')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumFirst}
                onChange={evt => handleChange(evt.target.value, 'premiumFirst')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsSecond}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsSecond')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoSecond}
                onChange={evt =>
                  handleChange(evt.target.value, 'serialNoSecond')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredSecond}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredSecond')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumSecond}
                onChange={evt =>
                  handleChange(evt.target.value, 'premiumSecond')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsThird}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsThird')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoThird}
                onChange={evt =>
                  handleChange(evt.target.value, 'serialNoThird')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredThird}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredThird')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumThird}
                onChange={evt => handleChange(evt.target.value, 'premiumThird')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsFourth}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsFourth')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoFourth}
                onChange={evt =>
                  handleChange(evt.target.value, 'serialNoFourth')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredFourth}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredFourth')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumFourth}
                onChange={evt =>
                  handleChange(evt.target.value, 'premiumFourth')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsFive}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsFive')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoFive}
                onChange={evt => handleChange(evt.target.value, 'serialNoFive')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredFive}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredFive')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumFive}
                onChange={evt => handleChange(evt.target.value, 'premiumFive')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsSix}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsSix')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoSix}
                onChange={evt => handleChange(evt.target.value, 'serialNoSix')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredSix}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredSix')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumSix}
                onChange={evt => handleChange(evt.target.value, 'premiumSix')}
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={2}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.specifiedItemsSeven}
                onChange={evt =>
                  handleChange(evt.target.value, 'specifiedItemsSeven')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.serialNoSeven}
                onChange={evt =>
                  handleChange(evt.target.value, 'serialNoSeven')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.sumInsuredSeven}
                onChange={evt =>
                  handleChange(evt.target.value, 'sumInsuredSeven')
                }
              />
            </Grid>
            <Grid className={classes.blackBorder} container xs={3}>
              <Input
                type={'text'}
                placeholder={''}
                style={{ width: '100%' }}
                className={classes.input}
                color={'secondary'}
                value={urFormState.premiumSeven}
                onChange={evt => handleChange(evt.target.value, 'premiumSeven')}
              />
            </Grid>
          </FormGroup>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4">SECTION 3.b – HANDBAG COVER</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid container xs={8}>
            <TextField
              type="textarea"
              placeholder="UNSPECIFIED ITEMS / PERSONAL EFFECTS"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.unspecifiedItemsSectionThree}
              onChange={evt =>
                handleChange(evt.target.value, 'unspecifiedItemsSectionThree')
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid container xs={8}>
            <TextField
              type="textarea"
              placeholder="DESCRIPTION SUM INSURED"
              variant="standard"
              fullWidth={true}
              multiline
              rows={3}
              rowsMax={3}
              value={urFormState.descriptionSumSectionThree}
              onChange={evt =>
                handleChange(evt.target.value, 'descriptionSumSectionThree')
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          <Grid item xs={11}>
            <Typography variant="h4">
              Individual Items less than P1000 limited to:
            </Typography>
            <Typography variant="h4">
              P5000 inclusive of handbag - excludes phone
            </Typography>
            <Typography variant="h4">P200 for replacement of cards</Typography>
          </Grid>
        </Grid>
        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              SPECIFIED ITEMS
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              SERIAL NO.
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              SUM INSURED
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              PREMIUM
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsThird'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoThird}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoThird')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredThird}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredThird')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumThird}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumThird')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsFourth}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsFourth'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoFourth}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoFourth')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredFourth}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredFourth')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumFourth}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumFourth')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsFive}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsFive'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoFive}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoFive')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredFive}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredFive')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumFive}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumFive')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsSix}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsSix'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoSix}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoSix')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredSix}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredSix')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumSix}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumSix')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionspecifiedItemsSeven}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'handBagSectionspecifiedItemsSeven'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionserialNoSeven}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionserialNoSeven')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionsumInsuredSeven}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionsumInsuredSeven')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.handBagSectionpremiumSeven}
              onChange={evt =>
                handleChange(evt.target.value, 'handBagSectionpremiumSeven')
              }
            />
          </Grid>
        </FormGroup>
        {/* <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((item, index) => (
                    <TableCell key={index}>
                      <Typography variant={'subtitle2'}>
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.length > 0 ? (
                  datas.map((rowitem, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <Typography variant="subtitle1">
                              DEBS-MCM-NMS-0{++rowIndex}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid className={commonClasses.column}>
                          <Grid>
                            <FormControlLabel
                              style={{ ...marginGenerator('mb-8') }}
                              value="S/F"
                              control={
                                <Radio
                                  style={{
                                    border: '0px',
                                    ...marginGenerator('mr-8')
                                  }}
                                />
                              }
                              labelPlacement="start"
                              label="S/F"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography variant={'subtitle2'}>
                        Record not found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              SECTION 4 - MOTOR
            </Typography>
          </Grid>
        </Grid>

        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}></h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VEHICLE 1
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VEHICLE 2
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VEHICLE 3
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              TYPE OF COVER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileTypeOfCoverFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Comprehensive"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverFirst === 'Comprehensive'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Comprehensive"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdParty"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverFirst === 'ThirdParty'
                    ? true
                    : false
                }
                label="Third Party, Fire, Theft"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdPartyOnly"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverFirst === 'ThirdPartyOnly'
                    ? true
                    : false
                }
                label="Third Party Only"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileTypeOfCoverSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Comprehensive"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverSecond === 'Comprehensive'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Comprehensive"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdParty"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverSecond === 'ThirdParty'
                    ? true
                    : false
                }
                label="Third Party, Fire, Theft"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdPartyOnly"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverSecond === 'ThirdPartyOnly'
                    ? true
                    : false
                }
                label="Third Party Only"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileTypeOfCoverThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Comprehensive"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverThird === 'Comprehensive'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Comprehensive"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdParty"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverThird === 'ThirdParty'
                    ? true
                    : false
                }
                label="Third Party, Fire, Theft"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="ThirdPartyOnly"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTypeOfCoverThird === 'ThirdPartyOnly'
                    ? true
                    : false
                }
                label="Third Party Only"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} container xs={11}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}></h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              USE OF VEHICLE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileUseOfVechileFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="StrictlyPrivate"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileFirst ===
                  'StrictlyPrivate'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Strictly Private"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="BusinessUse"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileFirst === 'BusinessUse'
                    ? true
                    : false
                }
                label="Business Use"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Private"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileFirst === 'Private'
                    ? true
                    : false
                }
                label="Private (including travelling to & from work)"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileUseOfVechileSecond`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="StrictlyPrivate"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileSecond ===
                  'StrictlyPrivate'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Strictly Private"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="BusinessUse"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileSecond === 'BusinessUse'
                    ? true
                    : false
                }
                label="Business Use"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Private"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileSecond === 'Private'
                    ? true
                    : false
                }
                label="Private (including travelling to & from work)"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileUseOfVechileThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="StrictlyPrivate"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileThird ===
                  'StrictlyPrivate'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Strictly Private"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="BusinessUse"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileThird === 'BusinessUse'
                    ? true
                    : false
                }
                label="Business Use"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Private"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileUseOfVechileThird === 'Private'
                    ? true
                    : false
                }
                label="Private (including travelling to & from work)"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              YEAR OF MANUFACTURE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileYearOfManufautreFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileYearOfManufautreFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileYearOfManufautreSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileYearOfManufautreSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileYearOfManufautreThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileYearOfManufautreThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MAKE AND MODEL
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeAndModelFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeAndModelFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeAndModelSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeAndModelSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeAndModelThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeAndModelThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              COLOUR
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileColourFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileColourFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileColourSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileColourSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileColourThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileColourThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              REGISTRATION NUMBER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegistrationNumberFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegistrationNumberFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegistrationNumberSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegistrationNumberSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegistrationNumberThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegistrationNumberThird'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              CHASSIS NUMBER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileChasisFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileChasisFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileChasisSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileChasisSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileChasisThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileChasisThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VALUE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileValueFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileValueFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileValueSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileValueSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileValueThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileValueThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              IMPORTED VEHICLE (Grey Import)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileImportedVechileFrist`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileFrist === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileFrist === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileImportedVechileSecond`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileImportedVechileThird`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileImportedVechileThird === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              IMMOBILISER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileIMMobiliserFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserFirst === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserFirst === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileIMMobiliserSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileIMMobiliserThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileIMMobiliserThird === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              TRACKING DEVICE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileTrackingDeviceFirst`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceFirst === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceFirst === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileTrackingDeviceSecond`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(
                  evt.target.value,
                  `motorVechileTrackingDeviceThird`
                );
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileTrackingDeviceThird === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MAKE OF TRACKING DEVICE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeTrackingFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeTrackingFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeTrackingSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeTrackingSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileMakeTrackingThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileMakeTrackingThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              REGISTERED OWNER IF NOT
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialRegisterdOwnerFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialRegisterdOwnerFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialRegisterdOwnerSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialRegisterdOwnerSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialRegisterdOwnerThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialRegisterdOwnerThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              PROPOSER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialProposerFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialProposerFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialProposerSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialProposerSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialProposerThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialProposerThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              ACCESSORIES (Not factory fitted e.g, mag wheels)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialAccessoriesFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialAccessoriesFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialAccessoriesSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialAccessoriesSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialAccessoriesThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialAccessoriesThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VALUE OF ACCESSORIES
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialValuOfAccessoriesFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialValuOfAccessoriesFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialValuOfAccessoriesSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialValuOfAccessoriesSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialValuOfAccessoriesThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialValuOfAccessoriesThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Financial Interest (Car Loan)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialIntrustCarLoadFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialIntrustCarLoadFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialIntrustCarLoadSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialIntrustCarLoadSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFinacialIntrustCarLoadThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileFinacialIntrustCarLoadThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BANK
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileBankFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileBankFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileBankSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileBankSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileBankThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileBankThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              LOAN PERIOD (months left)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileLoanPeriodMonthFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileLoanPeriodMonthFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileLoanPeriodMonthSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileLoanPeriodMonthSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileLoanPeriodMonthThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileLoanPeriodMonthThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              REGULAR DRIVER DETAILS
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegDriverDetailsFirst}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegDriverDetailsFirst'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegDriverDetailsSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegDriverDetailsSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileRegDriverDetailsThird}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'motorVechileRegDriverDetailsThird'
                )
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              SURNAME
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileSurNameFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileSurNameFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileSurNameSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileSurNameSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileSurNameThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileSurNameThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              FIRST NAMES
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFirstNameFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileFirstNameFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFirstNameSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileFirstNameSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileFirstNameThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileFirstNameThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              DATE OF BIRTH
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileDobFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileDobFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileDobSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileDobSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.motorVechileDobThird}
              onChange={evt =>
                handleChange(evt.target.value, 'motorVechileDobThird')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              EBB COVER REQUIRED?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEbbCoverdFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdFirst === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEbbCoverdSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdSecond === 'No'
                    ? true
                    : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEbbCoverdThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdThird === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEbbCoverdThird === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              EA COVER REQUIRED?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEaCoverdFirst`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdFirst === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdFirst === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEaCoverdSecond`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdSecond === 'Yes'
                    ? true
                    : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdSecond === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorVechileEaCoverdThird`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdThird === 'Yes' ? true : false
                }
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.motorVechileEaCoverdThird === 'No' ? true : false
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              SECTION 5 - CARAVANS/TRAILERS
            </Typography>
          </Grid>
        </Grid>
        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}></h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              CARAVANS/TRAILERS 1
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            container
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              CARAVANS/TRAILERS 2
            </h5>
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={3}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              CARAVANS/TRAILERS 3
            </h5>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MAKE & MODEL
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.makeAndModalFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'makeAndModalFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.makeAndModalSecond}
              onChange={evt =>
                handleChange(evt.target.value, 'makeAndModalSecond')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.makeAndModalThird}
              onChange={evt =>
                handleChange(evt.target.value, 'makeAndModalThird')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              YEAR
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansFirstYear}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansFirstYear')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansSecondYear}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansSecondYear')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansThirdYear}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansThirdYear')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VALUE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansFirstValue}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansFirstValue')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansSecondValue}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansSecondValue')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansThirdValue}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansThirdValue')
              }
            />
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              REGISTRATION NUMBER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansRegistrationNumberFirst}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansRegistrationNumberFirst')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansRegistrationNumberSecond}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'carvansRegistrationNumberSecond'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.carvansRegistrationNumberThird}
              onChange={evt =>
                handleChange(evt.target.value, 'carvansRegistrationNumberThird')
              }
            />
          </Grid>
        </FormGroup>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: '700'
              }}>
              SECTION 6 - MOTOR CYCLES
            </Typography>
          </Grid>
        </Grid>
        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid
            className={classes.blackBorder}
            item
            xs={1}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MAKE:
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.make}
              onChange={evt => handleChange(evt.target.value, 'make')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={1}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              MODEL
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={2}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '50%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.model}
              onChange={evt => handleChange(evt.target.value, 'model')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={1}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              YEAR
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.year}
              onChange={evt => handleChange(evt.target.value, 'year')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              VALUE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.value}
              onChange={evt => handleChange(evt.target.value, 'value')}
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              REGISTRATION NUMBER
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={4}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.registrationNumber}
              onChange={evt =>
                handleChange(evt.target.value, 'registrationNumber')
              }
            />
          </Grid>
          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              USE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={9}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `use`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Business"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.use === 'Business' ? true : false}
                labelPlacement="start"
                label="Business"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Private"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.use === 'Private' ? true : false}
                label="Private"
              />
            </RadioGroup>
          </Grid>

          <Grid
            className={classes.blackBorder}
            item
            xs={2}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              Is the motor cycle kept in a locked in a garage overnight?
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={9}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `motorCycleLocked`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Yes"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.motorCycleLocked === 'Yes' ? true : false}
                labelPlacement="start"
                label="Yes"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="No"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.motorCycleLocked === 'No' ? true : false}
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid
            className={classes.blackBorder}
            item
            xs={11}
            style={{ backgroundColor: '#6cccf560' }}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              TYPE OF MOTOR CYCLE
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={11}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `typeOfMotorCycle`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="RoadBike"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.typeOfMotorCycle === 'RoadBike' ? true : false
                }
                labelPlacement="start"
                label="ROAD BIKE"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Cruiser"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.typeOfMotorCycle === 'Cruiser' ? true : false
                }
                label="CRUISER"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="OffRoad"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.typeOfMotorCycle === 'OffRoad' ? true : false
                }
                label="OFF ROAD"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="Quad"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.typeOfMotorCycle === 'Quad' ? true : false}
                label="QUAD"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="MCTrailer"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.typeOfMotorCycle === 'MCTrailer' ? true : false
                }
                label="MOTOR CYCLE TRAILER"
              />
            </RadioGroup>
          </Grid>
        </FormGroup>

        <FormGroup row style={{ marginBottom: 20 }}>
          <Grid className={classes.blackBorder} item xs={3}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              FINANCIAL INTEREST (Motorcycle Loan):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={8}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%', marginBottom: '20' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.FinancialInterestMotorcycleLoan}
              onChange={evt =>
                handleChange(
                  evt.target.value,
                  'FinancialInterestMotorcycleLoan'
                )
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={3}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              BANK
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.bank}
              onChange={evt => handleChange(evt.target.value, 'bank')}
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              LOAN PERIOD (Months left):
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} container xs={3}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.loanPeriodMonthsLeft}
              onChange={evt =>
                handleChange(evt.target.value, 'loanPeriodMonthsLeft')
              }
            />
          </Grid>
          <Grid className={classes.blackBorder} item xs={11}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              SECTIONS REQUIRED (pick)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={11}>
            <RadioGroup
              row
              aria-label="change_classification"
              onChange={evt => {
                handleChange(evt.target.value, `sectionRequired`);
              }}>
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="houseowners"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.sectionRequired === 'houseowners' ? true : false
                }
                labelPlacement="start"
                label="HOUSEOWNERS"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="householders"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.sectionRequired === 'householders' ? true : false
                }
                label="HOUSEHOLDERS"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="allrisks"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.sectionRequired === 'allrisks' ? true : false
                }
                label="ALL RISKS"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="motor"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={urFormState.sectionRequired === 'motor' ? true : false}
                label="MOTOR"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="caravans/trailers"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.sectionRequired === 'caravans/trailers'
                    ? true
                    : false
                }
                label="CARAVANS/TRAILERS"
              />
              <FormControlLabel
                style={{ ...marginGenerator('mb-8') }}
                value="motorcycles"
                labelPlacement="start"
                control={
                  <Radio
                    style={{ border: '0px', ...marginGenerator('mr-8') }}
                  />
                }
                checked={
                  urFormState.sectionRequired === 'motorcycles' ? true : false
                }
                label="MOTORCYCLES"
              />
            </RadioGroup>
          </Grid>

          <Grid className={classes.blackBorder} item xs={2}>
            <h5
              className={classes.blackBorderTitle}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                fontFamily: 'sans-serif',
                marginLeft: 5
              }}>
              TOTAL PREMIUM (P)
            </h5>
          </Grid>
          <Grid className={classes.blackBorder} item xs={9}>
            <Input
              type={'text'}
              placeholder={''}
              style={{ width: '100%' }}
              className={classes.input}
              color={'secondary'}
              value={urFormState.totalPremimum}
              onChange={evt => handleChange(evt.target.value, 'totalPremimum')}
            />
          </Grid>
        </FormGroup>

        <Grid container style={{ padding: '0 32px' }}>
          <Grid item xs={12}>
            <div>
              <p
                style={{
                  color: '#6f7070',
                  fontSize: '16px',
                  fontfamily: 'sans-serif',
                  fontWeight: '300'
                }}>
                IMPORTANT NOTES
                <br />
                1. No cover will be in force until this proposal has been
                accepted by the Company who reserve the right to decline any
                proposal or impose special terms.
                <br />
                2. No claim shall be payable until such time as the first
                premium has been received by the Company
                <br />
                3. Remember to read your policy wording and familiarize yourself
                with the applicable terms and conditions
                <br />
                <br />
                DECLARATION
                <br />
                1. I hereby declare that all particulars and answers in this
                proposal are true and complete in every respect, and that no
                material fact has been withheld.
                <br />
                2. I accept that this proposal and declaration shall be the
                basis of the contract between Sesiro Insurance Company and
                myself.
                <br />
                3. No insurer has ever cancelled/declined or refused to renew or
                imposed special terms or conditions on any insurance affecting
                me.
                <br />
                4. I have previously been insured with:
                <br />
                <br />
                <Grid item xs={4} style={{ fontSize: '15px' }}>
                  <Input
                    type={'text'}
                    placeholder={'Name of insurer'}
                    style={{ width: '100%', fontSize: '15px' }}
                    className={classes.input}
                    color={'secondary'}
                    value={urFormState.nameOfInsurer}
                    onChange={evt =>
                      handleChange(evt.target.value, 'nameOfInsurer')
                    }
                  />
                </Grid>
                <Grid item xs={4} style={{ fontSize: '15px' }}>
                  <Input
                    type={'text'}
                    placeholder={'Policy Number'}
                    style={{ width: '100%', fontSize: '15px' }}
                    className={classes.input}
                    color={'secondary'}
                    value={urFormState.policyNumber}
                    onChange={evt =>
                      handleChange(evt.target.value, 'policyNumber')
                    }
                  />
                </Grid>
                <br />
                <br />
                6. I suffered or was concerned in the following losses during
                the past three years (whether insured or not):
                <br />
                <br />
                <FormGroup row style={{ marginBottom: 20 }}>
                  <Grid className={classes.blackBorder} item xs={4}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{
                        fontSize: '15px',
                        fontWeight: '600',
                        fontFamily: 'sans-serif',
                        marginLeft: 5
                      }}>
                      YEAR
                    </h5>
                  </Grid>
                  <Grid className={classes.blackBorder} item xs={4}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{
                        fontSize: '15px',
                        fontWeight: '600',
                        fontFamily: 'sans-serif',
                        marginLeft: 5
                      }}>
                      DESCRIPTION
                    </h5>
                  </Grid>
                  <Grid className={classes.blackBorder} item xs={4}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{
                        fontSize: '15px',
                        fontWeight: '600',
                        fontFamily: 'sans-serif',
                        marginLeft: 5
                      }}>
                      AMOUNT
                    </h5>
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%', marginBottom: '20' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.yearFirst}
                      onChange={evt =>
                        handleChange(evt.target.value, 'yearFirst')
                      }
                    />
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.descriptionFirst}
                      onChange={evt =>
                        handleChange(evt.target.value, 'descriptionFirst')
                      }
                    />
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.amountFirst}
                      onChange={evt =>
                        handleChange(evt.target.value, 'amountFirst')
                      }
                    />
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%', marginBottom: '20' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.yearSecond}
                      onChange={evt =>
                        handleChange(evt.target.value, 'yearSecond')
                      }
                    />
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.descriptionSecond}
                      onChange={evt =>
                        handleChange(evt.target.value, 'descriptionSecond')
                      }
                    />
                  </Grid>
                  <Grid className={classes.blackBorder} container xs={4}>
                    <Input
                      type={'text'}
                      placeholder={''}
                      style={{ width: '100%' }}
                      className={classes.input}
                      color={'secondary'}
                      value={urFormState.amountSecond}
                      onChange={evt =>
                        handleChange(evt.target.value, 'amountSecond')
                      }
                    />
                  </Grid>
                </FormGroup>
                <br />
                7. All correspondence sent by ordinary mail addressed to me at
                the above postal address shall be deemed to have been duly
                received by me in the normal course.
                <br />
                <Grid container spacing={4} style={{ marginBottom: 5 }}>
                  <Grid item xs={2}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{ fontSize: 14, fontWeight: 400 }}>
                      Signature of Insured:
                    </h5>
                  </Grid>
                  <Grid item xs={3} style={{ marginLeft: 44, marginTop: 20 }}>
                    <IconButton
                      variant={'outlined'}
                      onClick={() =>
                        handleOpenSignPanModal('signatureOfInsured')
                      }>
                      Signature{' '}
                      <img src={SignIcon} width="26" height={26} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{ fontSize: 14, fontWeight: 400 }}>
                      Date
                    </h5>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 10 }}>
                    <DateTimeSelector
                      inputStyle={{ minWidth: '100%' }}
                      dateFormat={'yyyy-MM-dd'}
                      value={urFormState.dateOfPurchase}
                      handleChange={newDate =>
                        handleChange(newDate, 'signatureOfInsuredDate')
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginBottom: 10 }}>
                  <Grid item xs={2}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{ fontSize: 14, fontWeight: 400 }}>
                      Signed on behalf of Sesiro Insurance:
                    </h5>
                  </Grid>
                  <Grid item xs={3} style={{ marginLeft: 44, marginTop: 20 }}>
                    <IconButton
                      variant={'outlined'}
                      onClick={() => handleOpenSignPanModal('sesiroSign')}>
                      Signature{' '}
                      <img src={SignIcon} width="26" height={26} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <h5
                      className={classes.blackBorderTitle}
                      style={{ fontSize: 14, fontWeight: 400 }}>
                      Date
                    </h5>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 10 }}>
                    <DateTimeSelector
                      inputStyle={{ minWidth: '100%' }}
                      dateFormat={'yyyy-MM-dd'}
                      value={urFormState.sesiroDate}
                      handleChange={newDate =>
                        handleChange(newDate, 'sesiroDate')
                      }
                    />
                  </Grid>
                </Grid>
                <br />
                I hereby authorize Sesiro Insurance Company (Pty) Ltd to deduct
                my monthly premiums directly from payroll
                <br />
                <br />
                <Grid item xs={2} style={{ marginLeft: 44 }}>
                  <IconButton
                    variant={'outlined'}
                    onClick={() => handleOpenSignPanModal('sesiroAuthorize')}>
                    Signature{' '}
                    <img src={SignIcon} width="26" height={26} alt="" />
                  </IconButton>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 20 }}>
                  <DateTimeSelector
                    inputStyle={{ minWidth: '100%' }}
                    dateFormat={'yyyy-MM-dd'}
                    value={urFormState.sesiroAuthorizeDate}
                    handleChange={newDate =>
                      handleChange(newDate, 'sesiroAuthorizeDate')
                    }
                  />
                </Grid>
              </p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={() => {
              handleClose();
            }}>
            {t('cancel.label')}
          </Button>
          <ButtonComponent
            t={t}
            onClick={() => {
              handleCreateProposalSubmit(urFormState);
            }}
            loading={false}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SesiroPropasolForm;
