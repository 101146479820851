import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import Autocomplete from '../../../../../../../components/Autocomplete';
import { IconButton, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import HrHeader from '../../HrHeader';
import { GET_SECTION_LIST } from '../../../queries'
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';


const useStyles = makeStyles(theme => ({
    paper: {},
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        flexGrow: 1,
    },
    input: {
        minWidth: '224px'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2),
        border: "1px solid #b8b8b8"
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

const initFields = { title: "", issueDateTime: null, section: "", attendees: [], description: ""};

const AddBriefiengModal = ({
    t,
    open,
    data,
    handleClose,
    modalState,
    handleCreateMeetingSubmit,
    subscriptionUserData,
    subscriptionId
}) => {
    
    const classes = useStyles();
    const [briefingFormState, setBriefingFormState] = useState({ ...initFields });
    React.useEffect(() => {
        if (data && data.id) {
            const attendees = subscriptionUserData.filter(itm => data.attendees.includes(itm.id));
            const section = data.section && data.section._id;
            setBriefingFormState({...data, section, attendees, issueDateTime: data.issueDateTime });
        }
    }, [data]);

    const {
        loading: sectionLoading,
        data: sectionList,
        error: sectionError,
        refetch: reloadSections
    } = useQuery(GET_SECTION_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            subscriptionId: subscriptionId
        }
    });

    const handleChange = (value, field) => {
        setBriefingFormState({ ...briefingFormState, [field]: value });
    }

    useEffect(() => {
      if(!open){
        setBriefingFormState({ ...initFields });
      }
    }, [open])
    
  return (
    <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    aria-labelledby="sensor-template-modal"
    aria-describedby="sensor-template-modal"
    open={open}
    onClose={handleClose}>
    <DialogTitle style={{padding: '16px 0'}} id="sensor-template-title">
        <HrHeader />
    </DialogTitle>
    <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" >Create Brief</Typography>
                        </Grid>
                </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }} >
            <Grid item xs={12}>
                <Input
                    type={'text'}
                    placeholder={"Title"}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    onChange={(evt) => handleChange(evt.target.value, "title")}
                    value={briefingFormState.title}
                />
            </Grid>
            <Grid item xs={12}>
                <DateTimeSelector
                    styleOverrides={{width: '100%'}}
                    inputStyle={{ minWidth: '100%', width: '100%' }}
                    label={"Issue Date & Time"}
                    dateFormat={"yyyy-MM-dd HH:mm"}
                    value={briefingFormState.issueDateTime}
                    handleChange={newDate => handleChange(newDate, "issueDateTime")}
                />
            </Grid>
            <Grid item xs={12}>
                {/* <Input
                    type={'text'}
                    placeholder={"Section"}
                    style={{ width: '100%' }}
                    className={classes.input}
                    color={'secondary'}
                    onChange={(evt) => handleChange(evt.target.value, "section")}
                    value={briefingFormState.section}
                /> */}
                 <OutlinedSelect
                    options={sectionList && sectionList.getAllSections ? sectionList.getAllSections.data :[]}
                    label="Section"
                    styleOverrides={{ minWidth: '100%', marginRight: 2 }}
                    val={briefingFormState.section}
                    //selectStyle={{ ...paddingGenerator('p-4') }}
                    handleChange={(evt) => {
                        handleChange(evt.target.value, "section");
                    }}
                 />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    styleOverrides={{width: "100%"}}
                    handleChange={(evt, newVal) => handleChange(newVal, "attendees")}
                    val={briefingFormState.attendees}
                    options={subscriptionUserData}
                    label={"Attendees"}
                    size="small"
                    multiple={true}
                    onInputChange={evt => {}}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="textarea"
                    placeholder="Comments or Notes"
                    variant="standard"
                    fullWidth={true}
                    multiline
                    rows={2}
                    onChange={(evt) => handleChange(evt.target.value, "description")}
                    value={briefingFormState.description}
                    rowsMax={4}
                />
            </Grid>
        </Grid>

    </DialogContent>
    <DialogActions>
        <Grid container justify={'space-between'}>
            <Button
                variant={'outlined'}
                onClick={() => {
                    setBriefingFormState({ ...initFields });
                    handleClose();
                }}
            >
                {t('cancel.label')}
            </Button>
            <ButtonComponent
                t={t}
                 onClick={() => {
                     handleCreateMeetingSubmit(briefingFormState);
                 }}
                loading={modalState.btnLoading}
            />
        </Grid>
    </DialogActions>
</Dialog>
  )
}

export default AddBriefiengModal