import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { GET_SUBSCRIPTION_LIST_SERVICE_MANAGER } from '../../Subscriptions/queries';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { Paths, ServiceManager } from '../../../routes/routePaths';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SUBSCRIPTION_STATUS } from '../../../utils/constants';
import { getParam } from '../../../utils';
import { CREATE_NEW_SUBSCRIPTION } from '../mutations';
import SubscriptionPanel from '../components/SubscriptionPanel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { USER_ROLES } from '../../../utils/constants';
import SelectSiteModal from '../components/SelectedSites';

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'subscriptions.label', link: Paths.Subscriptions }
];

const SubscriptionLists = ({ t }) => {
  const id = getParam();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [subscribeBtnShow, setSubscribeBtnShow] = useState(false);
  const [selectSiteModal, setSelectSiteModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});

  const history = useHistory();
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (location.state) setActiveIndex(location.state.activeIndex);

    if (
      role.includes(USER_ROLES.EP_FINANCE) ||
      role.includes(USER_ROLES.EP_IT) ||
      role.includes(USER_ROLES.IP_USER)
    ) {
      setSubscribeBtnShow(false);
    } else {
      setSubscribeBtnShow(true);
    }
  }, [location]);

  /*
     List Queries
    */
  const {
    loading: activeLoading,
    error: activeError,
    data: getAllSubscriptionsServicemanager
  } = useQuery(GET_SUBSCRIPTION_LIST_SERVICE_MANAGER, {
    fetchPolicy: 'no-cache',
    variables: {
      status: SUBSCRIPTION_STATUS.ACTIVE,
      ownerId: id
    }
  });

  const handleBeginConfigurationClick = selectedSites => {
    history.push({
      pathname: ServiceManager.ConfigureSites,
      search: `?id=${selectedService.id}?sites=${selectedSites}?type=${selectedService.service.serviceType.name}`
    });
  };

  const handleActiveSubscriptionItemClick = (data, activeIndex) => {
    setSelectedService(data);
    setSelectSiteModal(true);
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('subscriptions.label')}
        subText={`${
          getAllSubscriptionsServicemanager &&
          getAllSubscriptionsServicemanager.getAllSubscriptionsServicemanager
            ? getAllSubscriptionsServicemanager
                .getAllSubscriptionsServicemanager.length
            : 0
        } ${t('activeSubscriptions.label')}`}
      />

      {!activeLoading && !activeError && getAllSubscriptionsServicemanager && (
        <SubscriptionPanel
          subscriptionList={
            getAllSubscriptionsServicemanager.getAllSubscriptionsServicemanager
          }
          type={SUBSCRIPTION_STATUS.ACTIVE}
          t={t}
          label={t('activeSubscriptions.label')}
          handleSubscriptionItemClick={handleActiveSubscriptionItemClick}
        />
      )}

      {activeLoading && (
        <LinearProgress
          color="primary"
          style={{
            top: '98px',
            left: '0px',
            position: 'absolute',
            width: '100%'
          }}
        />
      )}

      <SelectSiteModal
        selectedService={selectedService}
        open={selectSiteModal}
        handleBeginConfigurationClick={handleBeginConfigurationClick}
        handleClose={() => setSelectSiteModal(false)}
      />
    </Layout>
  );
};
export default withTranslation()(SubscriptionLists);
