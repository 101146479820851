import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, withStyles } from '@material-ui/core';
import axios from 'axios';
import { FABRIC_URL } from '../../../../../envConstants';
import { useAppContext } from '../../../../../contexts/AppContext';

const styles = {
  input1: {
    width: 30,
    height: 15,
    border: '1px solid #5a1f2aff',
    borderRadius: '4px'
  },
  button: {
    backgroundColor: 'white',
    color: '#5a1f2a',
    borderRadius: '10px',
    width: '-webkit-fill-available',
    height: '43px',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: '#5a1f2a',
      color: 'white'
    }
  }
};

const Step2 = ({
  classes,
  getClientName,
  handlePrev,
  handleNext,
  getDestinationPodId
}) => {
  const [items, setItems] = useState([]);
  const [isActive, setIsActive] = useState('');

  const { state, dispatch } = useAppContext();

  const handleDataCenter = async (e, index, item) => {
    e.preventDefault();
    getDestinationPodId(item?.pod);
    const bEndId = item._id;
    localStorage.setItem('B-EndVLAN', bEndId);

    if (state?.connectionDetails?.destinationType === 'port') {
      dispatch({
        type: 'SET_DESTINATION_PORT_ID',
        payload: bEndId
      });
    } else {
      dispatch({ type: 'SET_DESTINATION_FCR_ID', payload: bEndId });
      dispatch({
        type: 'SET_CLIENT_NAME',
        payload: item?.client_id?.client_name
      });
    }

    const token = localStorage.getItem('syncomToken');
    if (state.connectionDetails.destinationType === 'fcr') {
      const res = await axios.get(`${FABRIC_URL}fcr/${bEndId}`, {
        headers: { accessToken: `${token}` }
      });
      const data = await res.data.result;
      if (state.connectionDetails.sourceType === 'port') {
        getClientName(data?.client_id?.client_name);
      }
      localStorage.setItem('Port_B', JSON.stringify([data]));
    } else if (state.connectionDetails.destinationType === 'port') {
      const res = await axios.get(`${FABRIC_URL}port/${bEndId}`, {
        headers: { accessToken: `${token}` }
      });
      const data = await res.data.result;
      localStorage.setItem('Port_B', JSON.stringify([data]));
    }

    setIsActive(index);
  };

  useEffect(() => {
    const portData = async () => {
      const token = localStorage.getItem('syncomToken');
      const A_end_port = state.sourcePortDetails.sourcePortId;
      const A_end_fcr = state.sourceFcrDetails.sourceFcrId;
      const testing =
        state.connectionDetails.sourceType === 'fcr' ? A_end_fcr : A_end_port;
      const destinationType = state.connectionDetails.destinationType;
      const res = await axios.get(
        `${FABRIC_URL}connection?source=${testing}&type=${destinationType}`,
        { headers: { accessToken: `${token}` } }
      );
      const portDatas = res?.data?.result;
      setItems(portDatas);
    };
    portData();
  }, []);

  return (
    <div>
      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        {state.connectionDetails.destinationType === 'fcr' ? (
          <h5> *Select Destination Fcr :</h5>
        ) : (
          <h5> *Select Destination Port :</h5>
        )}
        <div style={{ height: '200px', overflowY: 'scroll' }}>
          {items &&
            items?.map((item, index) => (
              <button
                variant="outlined"
                onClick={e => handleDataCenter(e, index, item)}
                style={{
                  marginLeft: '50px',
                  width: '400px',
                  height: '70px',
                  borderStyle: 'solid',
                  borderRadius: '5px',
                  borderWidth: '1px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  flexDirection: 'column',
                  backgroundColor: index === isActive ? '#5a1f2a' : '',
                  color: index === isActive ? 'white' : ''
                }}
                key={item._id}
                value={item._id}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Name:</span> {item.name},{' '}
                  {item.datacenter_name}
                </div>{' '}
                {state.connectionDetails.destinationType === 'fcr' ? (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Client Details:</span>
                    {item.client_id?.client_name},
                    {item.client_id?.head_quarters}
                  </div>
                ) : (
                  ''
                )}
              </button>
            ))}
        </div>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box p={2}>
          <Button variant="outlined" size="medium" onClick={handlePrev}>
            Prev
          </Button>
        </Box>
        <Box p={2}>
          <Button
            onClick={handleNext}
            variant="contained"
            size="medium"
            color="primary"
            type="submit">
            Next
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Step2);
