import * as React from 'react';
import { Switch } from 'react-router-dom';
import FabricServices from '../FabricDashboard/FabricServices';
import TestDrawer from '../TestDrawer';
import Connection from '../ConnectionDashboard/Connection';
import CreatePortDashBoard from '../PortDashboard/CreatePortDashBoard';
import { Paths, fabricServicePaths } from '../../../routes/routePaths';
import CreateFCRDashBoard from '../FCR/CreateFCRDashBoard';
import CreateFcr from '../FCR/CreateFcr';
import CreateClientDashboard from '../Views/Client/CreateClientDashboard';
import CreateClientForm from '../Views/Client/CreateClientForm';
import FormComponentPort from '../PortDashboard/FormComponentPort';
import CreateConnection from '../Views/Connections copy/CreateConnection';
import { MuiThemeProvider } from '@material-ui/core';
import fabricTheme from '../../../theme/fabricTheme';
import PodDashboard from '../PodDashboard';
import RegionDashboard from '../RegionDashboard';
import CreateDatacenterForm from '../DatacenterDashboard/CreateDatacenterForm';
import CreateRegion from '../RegionDashboard/CreateRegionForm';
import DatacenterDashboard from '../DatacenterDashboard';
import CreatePodForm from '../PodDashboard/CreatePodForm';
import Insight from '../Insight';
import ProtectedRoute from '../../../routes/routeUtils/ProtectedRoute';
import ScrollToTop from '../ScrollToTop';
import ServiceProviderDashboard from '../ServiceProviderDashboard';
import UnderLayProviderDashboard from '../UnderLayProviderDashboard';
import PremiumLinkDashboard from '../PremiumLinkDashboard';
import CreatePremiumLinkForm from '../PremiumLinkDashboard/CreatePremiumLinkForm';
import UnderLayProviderForm from '../UnderLayProviderDashboard/UnderLayProviderForm';
import CreateServiceProviderForm from '../ServiceProviderDashboard/CreateServiceProviderForm';

class FabricRoutes extends React.PureComponent {
  render() {
    return (
      <MuiThemeProvider theme={fabricTheme}>
        <ScrollToTop />
        <TestDrawer>
          <Switch>
            <ProtectedRoute
              exact={true}
              path={Paths.FabricServices}
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <FabricServices />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path={fabricServicePaths.Ports}
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <CreatePortDashBoard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path={fabricServicePaths.Connections}
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <Connection />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path={fabricServicePaths.FCR}
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <CreateFCRDashBoard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/CreatePort"
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization'
              ]}>
              <FormComponentPort />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path={fabricServicePaths.CreateConnection}
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization'
              ]}>
              <CreateConnection />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/CreateFcr"
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization'
              ]}>
              <CreateFcr />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/Client"
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <CreateClientDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/createClient"
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization'
              ]}>
              <CreateClientForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/regions"
              roles={['superadmin']}>
              <RegionDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/create-region"
              roles={['superadmin']}>
              <CreateRegion />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/datacenters"
              roles={['superadmin']}>
              <DatacenterDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/data-center/:mode/:id"
              roles={['superadmin']}>
              <CreateDatacenterForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/data-center/:mode"
              roles={['superadmin']}>
              <CreateDatacenterForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/pods"
              roles={['superadmin']}>
              <PodDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/serviceproviders"
              roles={['superadmin']}>
              <ServiceProviderDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/serviceproviders/:mode"
              roles={['superadmin']}>
              <CreateServiceProviderForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/serviceproviders/:mode/:id"
              roles={['superadmin']}>
              <CreateServiceProviderForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/underlayproviders"
              roles={['superadmin']}>
              <UnderLayProviderDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/underlayproviders/:mode"
              roles={['superadmin']}>
              <UnderLayProviderForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/underlayproviders/:mode/:id"
              roles={['superadmin']}>
              <UnderLayProviderForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/premiumlink"
              roles={['superadmin']}>
              <PremiumLinkDashboard />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/premiumlink/:mode"
              roles={['superadmin']}>
              <CreatePremiumLinkForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/premiumlink/:mode/:id"
              roles={['superadmin']}>
              <CreatePremiumLinkForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/pod/:mode/:id"
              roles={['superadmin']}>
              <CreatePodForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/pod/:mode"
              roles={['superadmin']}>
              <CreatePodForm />
            </ProtectedRoute>

            <ProtectedRoute
              exact={true}
              path="/fabricService/insight"
              roles={[
                'superadmin',
                'eadmin',
                'offline_access',
                'fabric_admin',
                'uma_authorization',
                'fabric_readonly'
              ]}>
              <Insight />
            </ProtectedRoute>
          </Switch>
        </TestDrawer>
      </MuiThemeProvider>
    );
  }
}
export default FabricRoutes;
