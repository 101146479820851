import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../../../utils';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateFinanceTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    allBduTeamUserList
}) {
    const classes = useStyles();
    const [serviceTestResult, setServiceTestResult] = useState('');
    const [jobCard, setJobCard] = useState('');
    const [sitePictures, setSitePictures] = useState('');
    const [equipmentDeployed, setEquipmentDeployed] = useState('');
    const [pmTeam, setPMTeam] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Occurrence, setOccurrence] = useState('');
    const [BillEndIndicator, setBillEndIndicator] = useState('');
    const [ChargePeriod, setChargePeriod] = useState('');
    const [Prorated, setProrated] = useState('');
    const [NetworkCode, setNetworkCode] = useState('');
    const [SupplierBaseCost, setSupplierBaseCost] = useState('');
    const [ChargeStartDate, setChargeStartDate] = useState('');
    const [ChargeEndDate, setChargeEndDate] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const isNumber = (str) => /^[0-9.]*$/.test(str);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setServiceTestResult('');
            setJobCard('');
            setSitePictures('');
            setEquipmentDeployed('');
            setPMTeam('');
            setNextTeamMemberEmail('');
            setStartDate('');
            setEndDate('');
            setOccurrence('');
            setBillEndIndicator('');
            setChargePeriod('');
            setProrated('');
            setNetworkCode('');
            setSupplierBaseCost('');
            setChargeStartDate('');
            setChargeEndDate('');
            setOnHold('');
            setCategory('');
        }
    }, [modalState, open]);

    const OccurrenceList = [
        {
            id: '1',
            name: '1'
        },
        {
            id: '2',
            name: '2'
        },
    ]
    const ChargePeriodList = [
        {
            id: 'M',
            name: 'M'
        },
        {
            id: 'Y',
            name: 'Y'
        },
    ]
    const ProratedList = [
        {
            id: '0',
            name: '0'
        },
        {
            id: '1',
            name: '1'
        },
    ]
    const handleChangeSupplierBaseCost = (evt) => {
        const { value } = evt.target;
        if (isNumber(value)) {
            let convertNumber = parseFloat(value).toFixed(2);
            // console.log("typeof number : ", convertNumber);
            // let res = parseFloat(Number(value).toFixed(2));
            // console.log("typeof res : ", res);
            setSupplierBaseCost(convertNumber);
        }
    }
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('financeTeam.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : ((preValuesOfForm.project_info && preValuesOfForm.project_info.case_number) ? preValuesOfForm.project_info.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t("projectManagementTeam.label")} *`}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setPMTeam(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('startDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={StartDate}
                                    selectsStart
                                    onChange={date => setStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    startDate={StartDate}
                                    endDate={EndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('endDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={EndDate}
                                    selectsEnd
                                    onChange={date => setEndDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    endDate={EndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('occurrence.label') + ' *'}`}
                                handleChange={evt => {
                                    setOccurrence(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={OccurrenceList ? OccurrenceList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('billEndIndicator.label') + ' *'}`}
                                handleChange={evt => {
                                    setBillEndIndicator(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={OccurrenceList ? OccurrenceList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('chargePeriod.label') + ' *'}`}
                                handleChange={evt => {
                                    setChargePeriod(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={ChargePeriodList ? ChargePeriodList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('prorated.label') + ' *'}`}
                                handleChange={evt => {
                                    setProrated(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={ProratedList ? ProratedList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('networkCode.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={NetworkCode}
                                type={'text'}
                                placeholder={t('networkCode.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNetworkCode(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('supplierBaseCost.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={SupplierBaseCost}
                                type={'text'}
                                placeholder={t('supplierBaseCost.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => handleChangeSupplierBaseCost(evt)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('charge.label')} {t('startDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={ChargeStartDate}
                                    selectsStart
                                    onChange={date => setChargeStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    startDate={ChargeStartDate}
                                    endDate={ChargeEndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('charge.label')} {t('endDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={ChargeEndDate}
                                    selectsEnd
                                    onChange={date => setChargeEndDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    endDate={ChargeEndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                val={category}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                val={onHold}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            let convertContactDate = "";
                            if (!StartDate || !EndDate || !Occurrence || !BillEndIndicator ||
                                !ChargePeriod || !Prorated || !NetworkCode || !ChargeStartDate || !ChargeEndDate) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }

                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                start_date: moment(StartDate).format("YYYY-MM-DD"),
                                end_date: moment(EndDate).format("YYYY-MM-DD"),
                                occurrence: Occurrence,
                                billend_indicator: BillEndIndicator,
                                charge_period: ChargePeriod,
                                prorated: Prorated,
                                network_code: NetworkCode,
                                supplier_base_cost: SupplierBaseCost,
                                charge_start_date: moment(ChargeStartDate).format("YYYY-MM-DD"),
                                charge_end_date: moment(ChargeEndDate).format("YYYY-MM-DD"),
                                category: category,
                                on_hold: onHold,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog >
    );
}

CreateFinanceTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateFinanceTeamModal);