import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import Layout from '../../../../components/Layout';
import Header from '../../../../components/Header';
import {
    Paths,
    TicketingSystem
} from '../../../../routes/routePaths';
import { LinearProgress } from "@material-ui/core";
import CustomTabs from '../../../../components/CustomTabs';
import { dateFormatterLLL, getParam } from '../../../../utils';
import CyberSecurityDetailPanel from './components/CyberSecurityDetailPanel';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { getUrl } from '../../../../utils';
import Message from '../../../../components/Message';
import { STATUS_TYPES } from '../../../../utils/constants';
import { UPDATE_CYBER_SECURITY_FORM } from './mutations';
import { GET_CYBER_SECURITY_BY_ID } from './queries'
import { CYBER_SECURITY_TITLE } from '../../../../utils/constants';

const useStyles = makeStyles(theme => ({
    commentBar: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3, 0),
        maxWidth: '1100px'
    },
    commentContainer: {
        bottom: 0,
        left: 0,
        borderBottom: '1px solid #eaeaea',
        borderTop: '1px solid #eaeaea',
        ...(window.innerWidth < 1100 && { padding: 24 })
    },
    exportBtn: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    updateBtn: {
        marginRight: '10px'
    }
}));

const CyberSecurityDetail = ({ t, history }) => {
    const classes = useStyles();
    const id = getParam();

    let [breadcrumbList, setBreadcrumbList] = useState([
        { label: 'home.label', link: Paths.Dashboard },
        { label: 'cyberSecurity.label', link: TicketingSystem.CyberSecurity },
        { label: 'cyberSecurityDetail.label', link: TicketingSystem.CSDetails }
    ]);

    useEffect(() => {
        reloadCSIncidnet()
    }, [])

    const { loadingCS, error, data: getCyberSecurityById, refetch: reloadCSIncidnet } = useQuery(GET_CYBER_SECURITY_BY_ID, {
        variables: {
            id: id
        }
    });

    const data = getCyberSecurityById ? getCyberSecurityById.getCyberSecurityById : {}

    const [closeCSIncidentRequest] = useMutation(UPDATE_CYBER_SECURITY_FORM, {
        onError: error => {
            console.log('Error', error);
            Message.error('There is some error in closing ticket. Please try again');
        },
        onCompleted: () => {
            reloadCSIncidnet()
            Message.success('Cyber Security Request Closed Successfully.');
        }
    });

    const handleCloseTicketAction = () => {
        if (
            window.confirm('Are you sure you want to close the change request?') ===
            false
        ) {
            return false;
        }
        return closeCSIncidentRequest({
            variables: {
                id: id,
                status: 'Closed'
            }
        });
    };

    return (
        <Layout breadcrumbList={breadcrumbList}>
            {loadingCS ? <LinearProgress color='primary' /> : (
                <>
                    <Header
                        title={CYBER_SECURITY_TITLE.TITLE + " " + data.requestId}
                        subText={`${t('id.label').toUpperCase()} ${data.requestId}
                  • ${t('ticketRaisedOn.label')} ${dateFormatterLLL(
                            data.createdAt
                        )} ${data.status === 'Closed' ?
                            t('TicketClosedOn.label') + " " + dateFormatterLLL(
                                data.updatedAt
                            ) : ""}`}
                        primaryButtonText={
                            data.status != STATUS_TYPES.CLOSED ? t('closeTicket.label') : null
                        }
                        handlePrimaryButtonClick={
                            data.status != STATUS_TYPES.CLOSED ? handleCloseTicketAction : null
                        }
                        secondaryButtonText={t('back.label')}
                        handleSecondaryButtonClick={() => {
                            const backPath = getUrl(TicketingSystem.CyberSecurity);
                            history.push(backPath)
                        }}
                    />

                    <CustomTabs
                        tabs={[
                            {
                                label: t('details.label'),
                                children: (
                                    <CyberSecurityDetailPanel
                                        requestData={data}
                                        reloadTickets={reloadCSIncidnet}
                                        t={t}
                                    />
                                )
                            },
                        ]}
                    />
                </>
            )}
        </Layout>
    );
};
export default withTranslation()(withRouter(CyberSecurityDetail));
