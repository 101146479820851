import React from 'react';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../theme/commonStyles';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { marginGenerator } from '../../../../theme/utils';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    width: theme.spacing(30),
    height: theme.spacing(20),
    border: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: theme.spacing(1),
    background: '#fcfcfc',
    borderRadius: '2px',
    cursor: 'pointer'
  },
  textContainer: {
    maxWidth: theme.spacing(20)
  }
}));

const ActionCard = ({ icon, label, link }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const history = useHistory();
  return (
    <Grid item>
      <Grid
        className={classes.cardContainer}
        container
        item
        direction={'column'}
        justify={'center'}
        alignItems={'center'}
        onClick={() =>
          link ? history.push({ pathname: link, search: '?dashboard' }) : null
        }>
        <Grid item>
          <img
            src={icon}
            className={commonClasses.iconExtraLarge}
            alt={'statIcon'}
          />
        </Grid>
        <Grid
          item
          style={marginGenerator('mt-16')}
          className={classes.textContainer}>
          <Typography
            align={'center'}
            variant={'body1'}
            style={{ fontWeight: 500 }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionCard;
