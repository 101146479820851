import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { dateFormatterDDMMMYYYYHMS } from '../../../../../utils';

export const ExportCSV = ({ csvData, fileName, type, fields }) => {

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        let finalData = csvData && csvData.length && csvData.map(item => {
            let description = item.description != null && item.description.length > 30000 ? item.description.slice(0, 30000).concat('...')
                : item.description.length > 0 ? item.description : ""
            let newObj = {
                createdAt: dateFormatterDDMMMYYYYHMS(item.createdAt),
                title: item.title,
                createdBy: item.createdByName,
                description: description,
                changeAgent: item.changeAgent.firstName + " " + item.changeAgent.lastName,
                reserveChangeAgent1: item.reserveAgent.firstName + " " + item.reserveAgent.lastName,
                reserveChangeAgent2: item.reserveAgent2.firstName + " " + item.reserveAgent2.lastName,
                changeImpact: item.changeImpact,
                proposedImplementationTime: dateFormatterDDMMMYYYYHMS(item.implimentStartDate),
                risk: item.risk,
                rollbackPlan: item.rollbackPlan,
                userAcceptanceTesting: item.userAcceptanceTesting,
                implementationEndTime: dateFormatterDDMMMYYYYHMS(item.implimentEndDate),
                lineManager: item.lineManager.firstName + " " + item.lineManager.lastName,
                status: item.status,
                inCharge: item.inCharge
            }

            if (type && type === 'singleReport') {
                if (item && item.inCharge === 'line manager') {
                    item.status === 'in progress' ? newObj.lineManagerApproval = 'Approved' : newObj.lineManagerApproval = item.status
                }
                if (item && item.inCharge === 'cab') {
                    newObj.lineManagerApproval = 'Approved'
                    newObj.cabApproval = item.status
                }
                if (item && item.inCharge === 'e-hod') {
                    newObj.lineManagerApproval = 'Approved'
                    newObj.cabApproval = 'escalate'
                    newObj.ehodApproval = item.status
                }
                if (item && item.inCharge === 'md') {
                    newObj.lineManagerApproval = 'Approved'
                    newObj.cabApproval = 'escalate'
                    newObj.ehodApproval = 'escalate'
                    newObj.mdApproval = item.status
                }
                if (item && item.lineManagerComment) newObj.lineManagerComment = item.lineManagerComment
                if (item && item.changeUrgency) newObj.urgency = item.changeUrgency
                if (item && item.changeMatrixImpact) newObj.impact = item.changeMatrixImpact
                if (item && item.cabLeadComment) newObj.cabLeadComment = item.cabLeadComment
                if (item && item.eHOD) newObj.eHOD = item.eHOD.firstName + " " + item.eHOD.lastName
                if (item && item.EHODComment) newObj.EHODComment = item.EHODComment
            }
            if (type && type === 'multiReport' && fields.length > 0) {
                if (fields.includes('Line manager Comment')) {
                    newObj.lineManagerComment = item.lineManagerComment ? item.lineManagerComment : ""
                }
                if (fields.includes('Urgency')) {
                    newObj.urgency = item.changeUrgency ? item.changeUrgency : ""
                }
                if (fields.includes('Impact')) {
                    newObj.impact = item.changeMatrixImpact ? item.changeMatrixImpact : ""
                }
                if (fields.includes('CAB Comment')) {
                    newObj.cabLeadComment = item.cabLeadComment ? item.cabLeadComment : ""
                }
                if (fields.includes('E-HOD')) {
                    newObj.eHOD = item.eHOD ? item.eHOD.firstName + " " + item.eHOD.lastName : ""
                }
                if (fields.includes('E-HOD Comment')) {
                    newObj.EHODComment = item.EHODComment ? item.EHODComment : ""
                }
                if (fields.includes('Line Manager Approval')) {
                    if (item.inCharge === 'agent') {
                        newObj.lineManagerApproval = ""
                    }
                    else if (item.inCharge === 'line manager') {
                        item.status === 'in progress' ? newObj.lineManagerApproval = 'Approved' : newObj.lineManagerApproval = item.status
                    } else {
                        newObj.lineManagerApproval = 'Approved'
                    }
                }
                if (fields.includes('CAB Approval')) {
                    if (item.inCharge === 'agent' || item.inCharge === 'line manager') {
                        newObj.cabApproval = ""
                    }
                    else if (item.inCharge === 'cab') {
                        newObj.cabApproval = item.status
                    } else {
                        newObj.cabApproval = 'escalate'
                    }
                }
                if (fields.includes('E-HOD Approval')) {
                    if (item.inCharge === 'agent' || item.inCharge === 'line manager' || item.inCharge === 'cab') {
                        newObj.ehodApproval = ""
                    }
                    else if (item.inCharge === 'e-hod') {
                        newObj.ehodApproval = item.status
                    } else {
                        newObj.ehodApproval = 'escalate'
                    }
                }
                if (fields.includes('MD Approval')) {
                    if (item.inCharge === 'md') {
                        newObj.mdApproval = item.status
                    } else {
                        newObj.mdApproval = ""
                    }
                }
            }

            return newObj
        })

        const ws = XLSX.utils.json_to_sheet(finalData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <Button
            variant={'contained'}
            color={'primary'}
            onClick={e => exportToCSV(csvData, fileName, type)}>
            Export Ticket
        </Button>
    );
};
