import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const initState = {
  name: '',
  description: '',
  id: ''
};
function EditIncidentGroup({
  open,
  handleClose,
  handleSubmit,
  buttonLoading,
  clearModal,
  data,
  t
}) {
  const [formData, setFormData] = React.useState(initState);

  useEffect(() => {
    if (clearModal) {
      setFormData(initState);
    }
  }, [clearModal]);

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name,
        description: data.description,
        id: data._id
      });
    }
  }, [data]);

  const handleFormData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitData = () => {
    handleSubmit(formData);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        className={classes.beg}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Users</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Name"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="name"
                value={formData.name}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <TextField
                label="Description"
                type="text"
                variant="outlined"
                fullWidth={true}
                name="description"
                value={formData.description}
                onChange={handleFormData}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitData}
            disabled={buttonLoading}
            variant="contained"
            color="primary"
            size="small">
            {buttonLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(EditIncidentGroup);
