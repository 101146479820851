import { Tooltip } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';

const menuAnimation = {
    hidden: {
        opacity: 0,
        height: 0,
        padding: 0,
        transition: { duration: 0.3, when: 'afterChildren' }
    },
    show: {
        opacity: 1,
        height: 'auto',
        transition: {
            duration: 0.3,
            when: 'beforeChildren'
        }
    }
};
const menuItemAnimation = {
    hidden: i => ({
        padding: 0,
        x: '-100%',
        transition: {
            duration: (i + 1) * 0.1
        }
    }),
    show: i => ({
        x: 0,
        transition: {
            duration: (i + 1) * 0.1
        }
    })
};

const ExpandedSideNavComponents = ({ route, showAnimation, isOpen, setIsOpen, setSubActiveIndex, selectedPath, setSelectedPath, setActiveIndex, activeSubpath, setActiveSubpath, setActiveDiv }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsOpen(true);
    };

    const handleSubPathClick = (i, route) => {
        setActiveDiv(null);
        setSubActiveIndex(i);
        setSelectedPath(route.label);
        setActiveIndex(route.path);
        setActiveSubpath(i + route.label);
        localStorage.setItem('activePath', route.label);
        localStorage.setItem('activeSubIndex', i);
    }

    useEffect(() => {
        if (!isOpen) {
            setIsMenuOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (route?.label !== selectedPath) {
            setIsMenuOpen(false);
        }
    }, [route, selectedPath]);

    return (
        <>
            <div
                className={`menu ${route?.label === selectedPath ? 'active' : ''}`}
                onClick={toggleMenu} style={{ cursor: 'pointer' }}>
                <Tooltip title={route.label} placement="right-start">
                    <div className="menu_item">
                        <div className="icon">{route.icon}</div>
                        <AnimatePresence>
                            {isOpen && (
                                <motion.div
                                    variants={showAnimation}
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                    className="link_text">
                                    {route.label}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </Tooltip>
                {isOpen && (
                    <motion.div
                        animate={
                            isMenuOpen
                                ? {
                                    rotate: -90
                                }
                                : { rotate: 0 }
                        }>
                        <FaAngleDown />
                    </motion.div>
                )}
            </div>{' '}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        variants={menuAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="menu_container">
                        {route.subPaths?.map((subPath, i) => {
                            return (
                                <motion.div variants={menuItemAnimation} key={i} custom={i}>
                                    <div
                                        className={`link ${activeSubpath === (i + route.label) ? 'active' : ''}`}
                                        style={{ cursor: 'pointer' }} onClick={() => handleSubPathClick(i, route)}
                                    >
                                        <div className="icon">
                                            {subPath?.icon}
                                        </div>
                                        <motion.div className="link_text">
                                            {subPath?.name}
                                        </motion.div>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default ExpandedSideNavComponents;
