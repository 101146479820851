import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBox from '../../../../../../../../components/SearchBox';
import MaintenancePanelItem from './MaintenancePanelItem';
import Pagination from '@material-ui/lab/Pagination';
import Input from '@material-ui/core/Input';

function MaintenancePanel({
    t,
    list,
    loading,
    error,
    allowConfigure,
    handleCreateNew,
    handlePageChange,
    maintenanceFilterState,
    handleSearch,
    handleChangeMaintenance
}) {
    
    const columns = [
        {
            label: t('places.label') + ' ' + t('name.label'),
            key: "placeName"
        },
        {
            label: "Number Of Sensors",
            key: "noOfSensors"
        },
        {
            label: t('Person'),
            key: "person"
        },
        {
            label: t('startDate.label'),
            key: "startDate"
        },
        {
            label: t('endDate.label'),
            key: "endDate"
        }
    ];
    if (allowConfigure) {
        columns.push({
            label: t('action.label'),
            key: "action"
        });
    }
    const [goToPage, setGoToPage] = useState(null);

    const handleSearchChange = (val) => {
       handleSearch("maintenance", val);
    }
    return (
        <div>
            {allowConfigure &&
                <Grid container justify={'space-between'}>
                    <Grid item>{<SearchBox handleSearch={handleSearchChange} />}</Grid>
                    <Grid item>
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={handleCreateNew}
                            >
                                {t('createNow.label')}
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !error ?
                                list && list.getAllMaintenance && list.getAllMaintenance.maintenance.length > 0 ?
                                    list.getAllMaintenance.maintenance.map((rowitem, rowIndex) => (
                                        <MaintenancePanelItem
                                            key={rowIndex}
                                            t={t}
                                            data={rowitem}
                                            handleChangeMaintenance={handleChangeMaintenance}
                                            allowConfigure={allowConfigure}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {loading && !error && <LinearProgress color="primary"/>}
                                    </TableCell>
                                </TableRow>
                             }
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loading &&
                <>
                    <Grid container justify={'space-between'} style={{ marginTop: 20, marginBottom: 30, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Grid item xs={2}>
                            <span>Total {(list && list.getAllMaintenance) ? Math.ceil(list.getAllMaintenance.count / 20) : 0} Page,  {(list && list.getAllMaintenance) ? list.getAllMaintenance.count : 0} Row</span>
                        </Grid>
                        <Grid item xs={2}>
                            <Pagination
                                color="secondary"
                                count={(list && list.getAllMaintenance) ? Math.ceil(list.getAllMaintenance.count / 20) : 0}
                                page={maintenanceFilterState.page}
                                onChange={(evt, value) => {
                                    handlePageChange("maintenance", value);
                                }} />
                        </Grid>
                        <Grid item xs={1}>
                            <span>Go to page </span>
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={goToPage}
                                type="number"
                                style={{ minWidth: 100 }}
                                color={'secondary'}
                                onChange={evt => {
                                    const maxRecord = list && list.getAllMaintenance ? parseInt(Math.ceil(list.getAllMaintenance.count / 20)) : 0;
                                    if (parseInt(evt.target.value) > maxRecord) {
                                        return null;
                                    }
                                    setGoToPage(evt.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => handlePageChange('place', parseInt(goToPage))}
                                variant={'outlined'}
                                color="primary"
                                disabled={!goToPage}
                                style={{ marginLeft: 10 }}
                            >Go</Button>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        </div>
    );
}

MaintenancePanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    handleCreateButtonClick: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default MaintenancePanel;
