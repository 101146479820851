import React, { Component, createRef } from 'react'
import { withTranslation } from 'react-i18next';
import { fabric } from 'fabric'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

class EdgeAIROIPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cam_id: false,
      created: false,
      imageResolution: {},
      roi_action: "",
      editRoiCords: {}
    }
  }

  roundOF2 = (num) => {
    return Math.round(num * 100) / 100;
  }

  createInitObject() {
    this.editorCanvas = new fabric.Canvas("roi-canvas", { selection: false, width: 0.1, height: 0.1 });
    if(localStorage.getItem("roi_img_url")){
      this.mainElem.style = {display:'block'};
      let imgData = localStorage.getItem("roi_img_url");
      var canvas = this.editorCanvas;
      fabric.Image.fromURL(imgData, (img1) => {
        this.setState({imageResolution: {width: img1.width, height: img1.height}});
        // add background image
        this.editorCanvas.setDimensions({width: img1.width, height: img1.height});
        this.editorCanvas.setBackgroundImage(img1, this.editorCanvas.renderAll.bind(this.editorCanvas), {
          backgroundImageOpacity: 0.5,
          backgroundImageStretch: false
        });
        let editRoi = localStorage.getItem("edit_roi");
        if(editRoi){
          let {x1, x2, y1, y2} = JSON.parse(editRoi);
          x1 = this.roundOF2(parseFloat(x1)*parseInt(img1.width));
          y1 = this.roundOF2(parseFloat(y1)*parseInt(img1.height));
          x2 = this.roundOF2(parseFloat(x2)*parseInt(img1.width));
          y2 = this.roundOF2(parseFloat(y2)*parseInt(img1.height));
          let rect = new fabric.Rect({
            left: x1,
            top: y1,
            fill: '',
            width: (x2 - x1),
            height: (y2 - y1),
            objectCaching: false,
            hasRotatingPoint: false,
            stroke: 'red',
            strokeWidth: 2
          });
          canvas.add(rect);
          this.editorCanvas.setActiveObject(rect);
          this.setState({created: true});
        }
      });
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = 'blue';
      fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: this.deleteObject,
        render: this.renderIcon,
        cornerSize: 24
      });
    }
  }

  componentDidMount(){
    this.createInitObject();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action');
    this.setState({roi_action: action});
  }
  
  createNewShape = () => {
    var rect = new fabric.Rect({
      left: 250,
      top: 150,
      fill: '',
      width: 300,
      height: 150,
      objectCaching: false,
      hasRotatingPoint: false,
      stroke: 'red',
      strokeWidth: 2,
    });
    if ( this.state.roi_action.indexOf("_aim_") === -1 ){
      rect.setControlsVisibility({ mtr: false });
    }
    this.editorCanvas.add(rect);
    this.editorCanvas.setActiveObject(rect);
    this.setState({created: true});
  }

  deleteObject = (eventData, transform) => {
		let target = transform.target;
		let canvas = target.canvas;
		    canvas.remove(target);
        canvas.requestRenderAll();
    this.setState({created: false});
	}

  renderIcon(ctx, left, top, styleOverride, fabricObject) {
    let img = document.createElement('img');
    img.src = deleteIcon;
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size/2, -size/2, size, size);
    ctx.restore();
  }

  saveCordinates = () => {
    let activeObject = this.editorCanvas.getActiveObject();
    if(activeObject && activeObject.lineCoords){
      let lineCords = {x1: activeObject.lineCoords.tl.x, y1: activeObject.lineCoords.tl.y, x2: activeObject.lineCoords.br.x, y2: activeObject.lineCoords.br.y};
      if(this.state.roi_action){
        if ( this.state.roi_action.indexOf("_aim_") > -1 ){
          lineCords.x1 = this.roundOF2(parseInt(lineCords.x1)/parseInt(this.state.imageResolution.width));
          lineCords.y1 = this.roundOF2(parseInt(lineCords.y1)/parseInt(this.state.imageResolution.height));
          lineCords.x2 = this.roundOF2(parseInt(lineCords.x2)/parseInt(this.state.imageResolution.width));
          lineCords.y2 = this.roundOF2(parseInt(lineCords.y2)/parseInt(this.state.imageResolution.height));
        }else{
          lineCords.x1 = this.roundOF2(parseInt(lineCords.x1));
          lineCords.y1 = this.roundOF2(parseInt(lineCords.y1));
          lineCords.x2 = this.roundOF2(parseInt(lineCords.x2));
          lineCords.y2 = this.roundOF2(parseInt(lineCords.y2));
        }
        localStorage.setItem(this.state.roi_action, JSON.stringify(lineCords));
      }else{
        lineCords.x1 = this.roundOF2(parseInt(lineCords.x1)/parseInt(this.state.imageResolution.width));
        lineCords.y1 = this.roundOF2(parseInt(lineCords.y1)/parseInt(this.state.imageResolution.height));
        lineCords.x2 = this.roundOF2(parseInt(lineCords.x2)/parseInt(this.state.imageResolution.width));
        lineCords.y2 = this.roundOF2(parseInt(lineCords.y2)/parseInt(this.state.imageResolution.height));
        localStorage.setItem("cam_roi_cords", JSON.stringify(lineCords));
      }
      localStorage.removeItem("roi_img_url")
      localStorage.removeItem("edit_roi");
      window.close();
    }else{
      return null;
    }
  }

  render () {

    return (
      <>
          {/* <Grid item xs={8}> */}
          <div
          ref={el => this.mainElem = el}
          className="counting-areas-editor"
          style={{display:'none'}}
          >
              <canvas
                  ref={el => this.elCanvas = el}
                  id="roi-canvas"
                  className="editor-canvas" 
              />
              <style jsx={'true'}>{`
                  .editor-canvas{
                      border:1px solid;
                  }
                  .controls{
                    align-items: flex-end;
                    display: flex;
                    padding-bottom: 40px;
                  }
                  .counting-areas-editor, .editor-canvas  {
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                      z-index: '2'
                  };
                  }
                  {/* @media (min-aspect-ratio: 16/9) {
                  :global(.canvas-container),.editor-canvas {
                      width: 100% !important;
                      height: auto !important;
                  }
                  }
                  @media (max-aspect-ratio: 16/9) {
                  :global(.canvas-container),.editor-canvas {
                      width: auto !important;
                      height: 100% !important;
                  }
                  } */}
              `}
              </style>
          </div>
          <div style={{marginBottom: 50, marginTop: 20}}>
            <Grid style={{marginBottom:20, marginLeft: 30, width: '100%', display: "inline-block"}}>
              <Button
                disabled={this.state.created} 
                color="primary" 
                onClick={this.createNewShape} 
                variant="outlined"
                style={{width: "279px", marginRight: 20}}
              >
                Draw
              </Button>
              <Button
                color="primary"
                variant={'contained'}
                disabled={!this.state.created}
                onClick={() => {
                  this.saveCordinates();
                }}
                style={{width: "279px"}}
              >
                Submit
              </Button>
            </Grid>
          </div>
      </>
    )
  }
}

export default withTranslation()(EdgeAIROIPanel);