import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useLazyQuery , useMutation} from '@apollo/react-hooks';
import { GET_SITES } from './queries';
import { ADD_CIRCUIT_TO_TICKET } from './mutations';
import Message from '../../components/Message';



const TicketSubscriptions = ({ticketData}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [theSubscription, settheSubscription] = useState(null);

  const [AddCircuitToTicket] = useMutation(ADD_CIRCUIT_TO_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: () => {
   
      Message.success(' Site Details Successfully added  to Ticket.');
    }
  });

  const [loadSites, { loading, data, error }] = useLazyQuery(GET_SITES, {
    fetchPolicy: 'no-cache',
    onError: queryError => {
      console.error('Error fetching sites:', queryError);
    },
    onCompleted: queryData => {
      settheSubscription(queryData?.getSites);
    },
  });

  useEffect(() => {
    // Fetch sites when the component mounts
  if(ticketData?.createdBy){
    loadSites({
      variables: {
        email: ticketData?.createdBy,
      },
    });
  }
  }, []);

  const handleSiteChange = (newValue) => {
  // console.log({id:ticketData?.id, circuitId:newValue?.circuitId},"newValue")
    // setSelectedOption(newValue);

    if(newValue){
      AddCircuitToTicket({
          variables: {
            ticketId: ticketData?.id,
            circuitId:newValue?.circuitId
          }
        });
     }


  };

  return (
    <div>
      {!loading && theSubscription?.length ? (
        <Autocomplete
          options={theSubscription || []}
          getOptionLabel={option =>
            `${option.circuitId ? option.circuitId : ''} ${option.platform ? option.platform : ''
            } ${option.name ? option.name : ''}`
          }
          value={selectedOption}
          style={{ width: '250px' }}
          onChange={(event, newValue) => handleSiteChange(newValue)}
          renderInput={params => (
            <TextField
              {...params}
              label="Select Site"
              variant="outlined"
            />
          )}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketSubscriptions;
