import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody'; 
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import PropertyDamegeForm from './PropertyDamegeForm';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../../components/DateTimeSelector';
import CanvasSignaturePadModal from '../../../../../../../components/CanvasSignaturePadModal';
import Message from '../../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_PROPERTY_DAMAGE } from '../../../queries';
import { CREATE_PROPERTY_DAMAGE, DELETE_PROPERTY_DAMAGE } from '../../../mutation';
import PropertyDamageForm from '../PropertyDamage/PropertyDamageForm';
import PropertyDamageItem from './PropertyDamageItem';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const PropertyDamege = ({
    t,
    data,
    allowConfigure,
    menuItems,
}) => {

    const classes = useStyles();
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, section: "", search: "" });
    const [selectedPropertyDamageItem, setSelectedPropertyDamageItem] = useState(null);
    const [propertyDamageModalOpen, setPropertyDamageModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");


    const subscriptionId = data.id;

    const [getAllPropertyDamages, {
    loading,
    data: propertyDamageList
    }] = useLazyQuery(GET_PROPERTY_DAMAGE, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllPropertyDamages({variables: {...filterStateData, subscriptionId}});
    }, []);
    
    useEffect(() => {
        getAllPropertyDamages({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [createPropertyDamage] = useMutation(CREATE_PROPERTY_DAMAGE, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Property Damage created/updated successfully');
            setCRModalState({clearData: true, btnLoading: false});
            return getAllPropertyDamages({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deletePropertyDamage, { loading: deletePropertyDamageLoading }] = useMutation(DELETE_PROPERTY_DAMAGE, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Property Damage deleted successfully');
            setPropertyDamageModalOpen(false);
            return getAllPropertyDamages({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createPropertyDamage({ variables: {
            ...formData
        }});
    }

    const columns = [
        {
            label: 'Policy No',
            key: "policyNo"
        },
        {
            label: 'VAT Registration No',
            key: "vatRegNo"
        },
        {
            label: 'Insured Name',
            key: "insured_name"
        },
        {
            label: "insured Occupation",
            key: "insured_occupation"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const meetingRequestList = [
    {
        name: "Server 1",
        schedule: new Date(),
        status: "Admin",
        attendees: "test",
        organizer: "test",
        meetingNotes: "multiple line code",
        actions: ""
    },
    {
        name: "Server 2",
        schedule: new Date(),
        status: "Admin",
        attendees: "test",
        organizer: "test",
        meetingNotes: "multiple line code",
        actions: ""
    },
    {
        name: "Server 3",
        schedule: new Date(),
        status: "Admin",
        attendees: "test",
        organizer: "test",
        meetingNotes: "multiple line code",
        actions: ""
    },
    {
        name: "Server 4",
        schedule: new Date(),
        status: "Admin",
        attendees: "test",
        organizer: "test",
        meetingNotes: "multiple line code",
        actions: ""
    }
    ];

    const handleNewPropertyDamage = () => {
        setPropertyDamageModalOpen(true);
    }
    
    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedPropertyDamageItem(rowItem);
        if(type === "sign"){
            setSelectedPropertyDamageItem(rowItem);
            setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setPropertyDamageModalOpen(true)
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this Property Damage ?') ===
                false
            ) {
                return false;
            }
            return deletePropertyDamage({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    const filterList = [
        {
          label: t('search.label'),
          handleChange: evt => {
            console.log('evt.target.value: ', evt.target.value);
            setFilterStateData({ ...filterStateData, search: evt.target.value });
          },
          val: filterStateData.search,
          type: 'text',
          styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 }
        },
        {
          label: t('startDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, startDate: newDate });
          },
          val: filterStateData.startDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        },
        {
          label: t('endDate.label'),
          handleChange: newDate => {
            setFilterStateData({ ...filterStateData, endDate: newDate });
          },
          val: filterStateData.endDate,
          type: 'input',
          styleOverrides: { marginRight: 20 },
          className: classes.dateInput
        }
      ];

  return (
        <div style={{ marginTop: 45 }}>
    <Grid container style={{ ...marginGenerator("mb-20") }}>
        <Grid item xs="10">
            {filterList && filterList.map((filter, index) => {
                if (filter.type && filter.type === 'input') {
                    return (
                        <DateTimeSelector
                            key={index}
                            label={filter.label ? filter.label : ""}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            value={filter.val}
                            handleChange={filter.handleChange}
                            styleOverrides={filter.styleOverrides}
                            inputStyle={{ minWidth: 220, padding: 10 }}
                            disableFuture={true}
                        />
                    );
                } else if (filter.type && filter.type === 'text') {
                    return (
                        <Input
                            key={index}
                            placeholder={filter.label ? filter.label : ""}
                            value={filter.val}
                            onChange={filter.handleChange}
                            style={filter.styleOverrides}
                        />
                    );
                } else {
                    return (
                        <OutlinedSelect
                            key={index}
                            val={filter.val}
                            label={filter.label}
                            handleChange={filter.handleChange}
                            IconComponent={filter.iconComponent}
                            options={filter.options}
                            styleOverrides={filter.styleOverrides || null}
                            disabled={filter.disabled || false}
                        />
                    );
                }
            })}
            <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
        </Grid>
        <Grid item xs="2">
            {allowConfigure && <>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{ ...marginGenerator('ml-10'), float: "right" }}
                    onClick={handleNewPropertyDamage}
                >
                    {"Open Form"}
                </Button>
            </>}
        </Grid>
    </Grid>
    <Grid container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant={'subtitle2'}>{item.label}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !loading ? propertyDamageList && propertyDamageList.getAllPropertyDamages && propertyDamageList.getAllPropertyDamages.data.length > 0 ?
                        propertyDamageList.getAllPropertyDamages.data.map((rowitem, rowIndex) => (
                            <PropertyDamageItem
                                key={rowIndex}
                                t={t}
                                data={rowitem}
                                menuItems={menuItems}
                                handleMenuItemClick={handleMenuItemClick}
                                allowConfigure={allowConfigure}
                            />
                        )) :
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                            </TableCell>
                        </TableRow> : null
                    }
                    {loading && 
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <div style={{textAlign: "center", width: "100%"}}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    <PropertyDamegeForm
        t={t}
        open={propertyDamageModalOpen}
        data={selectedPropertyDamageItem}
        modalState={crModalState}
        handleClose={() => setPropertyDamageModalOpen(false)}
        handleOpenSignPanModal={handleOpenSignPanModal}
        handleCreateRequestSubmit={handleCreateRequestSubmit}
    />
    <CanvasSignaturePadModal 
        open={signaturePadModalOpen} 
        handleClose={() => setSignaturePadModalOpen(false)} 
        handleSaveSignature={(image) => {
            console.log("image: ", image);
            setSignaturePadModalOpen(false);
        }}
    />
    </div>
)}

export default PropertyDamege