import React, { useState, useEffect } from 'react';
import { get } from "lodash-es";
import CustomTabs from '../../../../../components/CustomTabs';
import { useLocation, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  GET_ML_TEMPLATE_LIST,
  GET_NOTIFICATION_TEMPLATE_LIST,
  GET_PLACE_LIST,
  GET_CAMERA_LIST,
  GET_NODE_LIST,
  GET_VPNS_LIST
} from '../../../queries';
import {
  DELETE_ML_TEMPLATE,
  DELETE_NOTIFICATION_TEMPLATE,
  DELETE_PLACE,
  DELETE_CAMERA,
  DELETE_MULTIPLE_CAMERA,
  DELETE_NODE,
  UPDATE_ML_TEMPLATE_WITH_CAMERA
}
  from '../../../mutations';
import MLPanel from '../../MLPanel';
import NotificationPanel from '../../NotificationPanel';
import PlacePanel from "../../PlacePanel";
import CameraPanel from "../../CameraPanel";
import { USER_ROLES, NODE_MANAGEMENT_TYPE, CAMERA_STATUS_OPTIONS, EDGE_AI_ORGS, EDGE_AI_LIVE_CAM_URL } from '../../../../../utils/constants';
import Message from '../../../../../components/Message';
import NodeManagementPanel from '../../NodeManagementPanel';
import Vpns from "../../Vpns";
import ShayonaConfigurationTruckMaster from './ShayonaConfigurationTruckMaster';
import TruckMasterModal from '../components/TruckMasterModal';
import EdgeAILiveCamPanel from '../../EdgeAILiveCamPanel';
import ShayonaConfigurationBrands from './ShayonaConfigurationBrands';
import ShayonaAudit from '../ShayonaAudit';

const ShayonaConfiguration = ({
  t,
  data,
  handleUpdateAction,
  handleCreateButtonClick,
  reloadData,
  handleCreateURLClick,
  handleAddURLSubmitClick,
  edgeAILiveCamURL
}) => {

  const menuItems = [
    { label: t('edit.label') },
    { label: t('delete.label') }
  ];

  const vpnMenuItems = [{ label: t('edit.label') }, { label: t('delete.label') }, { label: t('addProxies.label') }, { label: t('viewProxies.label') }];
  const role = localStorage.getItem('role');
  const location = useLocation();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const [filterPlaceState, setFilterPlaceState] = useState('');
  const [filterModelState, setFilterModelState] = useState('');
  const [checkboxCameraRow, onCheckCameraRow] = useState([]);
  const [filterdCameraState, setFilterdCameraState] = useState(false);
  const [filteredCameraList, setFilteredCameraList] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [apiTimer, setApiTimer] = useState(0);
  const [configureActions, setConfigureActions] = useState(false);
  const [filterState, setFilterState] = useState({ ml_template: "", place: "", camera: "", notification: "", vpns: "", node: "" });
  const [activeFilterState, setActiveFilterState] = useState("");
  const [paginationState, setPaginationState] = useState({ ml_template: 1, place: 1, camera: 1, notification: 1, vpns: 1, iot_device: 1, node: 1 });
  const [filterStatusState, setFilterStatusState] = useState("");
  const [checkedcameraFromMlTemp, setCheckedcameraFromMlTemp] = useState([]);
  const [updateCameraTempLoading, setUpdateCameraTempLoading] = useState(false);
  const [placeFilterList, setPlaceFilterList] = useState([]);
  const [isMultiPurposeDialogueEnable, setIsMultiPurposeDialogueEnable] = useState(false);
  const [conetentData, setConetentData] = useState('');
  const [contentTitle, setContentTitle] = useState('');

  const onHandleSelectCameraRow = (evnt, index) => {
    checkboxCameraRow[index] = evnt.target.checked;
    onCheckCameraRow(checkboxCameraRow);
  }

  const organization = data.organizationId;
  useEffect(() => {
    if (location.state) setActiveIndex(location.state.activeIndex);
  }, [location]);

  useEffect(() => {
    if (role.includes(USER_ROLES.EP_FINANCE) || role.includes(USER_ROLES.IP_USER)) {
      setConfigureActions(false);
    } else {
      setConfigureActions(true);
    }
  }, []);

  const {
    loading: mlTemplateLoading,
    data: mlTemplateList,
    error: mlTemplateError,
    refetch: reloadMlData
  } = useQuery(GET_ML_TEMPLATE_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      limit: 20,
      search: filterState.ml_template,
      page: paginationState.ml_template
    }
  });

  const {
    loading: notiTemplateLoading,
    data: notiTemplateList,
    error: notiTemplateError,
    refetch: reloadNotiTemplateData
  } = useQuery(GET_NOTIFICATION_TEMPLATE_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      limit: 20,
      search: filterState.notification,
      page: paginationState.notification
    }
  });

  const {
    loading: placeLoading,
    data: placeList,
    error: placeError,
    refetch: reloadPlaceData
  } = useQuery(GET_PLACE_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      search: filterState.place,
      page: paginationState.place,
      limit: 1000
    },
    onCompleted: (res) => {
      if (res.getAllPlaceList && res.getAllPlaceList.places) {
        setPlaceFilterList(res.getAllPlaceList.places);
      }
    }
  });
  const {
    loading: cameraLoading,
    data: cameraList,
    error: cameraError,
    refetch: reloadCameraData
  } = useQuery(GET_CAMERA_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      search: filterState.camera,
      page: paginationState.camera,
      limit: 20,
      placeId: filterPlaceState ? filterPlaceState.place_id : "",
      status: filterStatusState ? filterStatusState : ""
    }
  });

  const {
    loading: nodeLoading,
    data: nodeList,
    error: nodeError,
    refetch: reloadNodeList
  } = useQuery(GET_NODE_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      limit: 20,
      search: filterState.node,
      page: paginationState.node
    }
  });

  const {
    loading: vpnsLoading,
    data: vpnsList,
    error: vpnsError,
    refetch: reloadVpnsList
  } = useQuery(GET_VPNS_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      organization,
      search: filterState.vpns,
      page: paginationState.vpns
    }
  });

  const [getPlaceList] = useLazyQuery(GET_PLACE_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res.getAllPlaceList) {
        setPlaceFilterList(res.getAllPlaceList.places ? res.getAllPlaceList.places : []);
      }
    }
  });

  const handleAutoPlaceSearch = (value) => {
    if (!value) {
      if (placeList && placeList.getAllPlaceList) {
        setPlaceFilterList(placeList.getAllPlaceList ? placeList.getAllPlaceList.places : []);
      }
    } else {
      setPlaceFilterList([]);
      return getPlaceList({
        variables: {
          organization,
          search: value,
          limit: 20,
          page: 1
        }
      });
    }
  }

  const cameraFilter = [
    {
      label: t("filterByPlace.label"),
      handleChange: (evt, newValue) => {
        if (!newValue) {
          handleAutoPlaceSearch("");
        }
        setFilterPlaceState(newValue);
        setPaginationState({ ...paginationState, camera: 1 });
        setFilterLoading(true);
        const place_id = (newValue) ? newValue.place_id : '';
        let filterBy = {
          variables: {
            organization: data.organizationId,
            templateId: filterModelState ? filterModelState : null,
            search: filterState.camera ? filterState.camera : "",
            page: 1,
            status: filterStatusState ? filterStatusState : ""
          }
        }
        if (place_id) {
          filterBy.variables.placeId = place_id;
        }
        setApiTimer(apiTimer + 1);
        return getCameraListByFilter(filterBy);
      },
      val: filterPlaceState,
      styleOverrides: { minWidth: '172px', marginRight: 12 },
      options: placeFilterList,
      fieldType: "autocomplete",
      onInputChange: handleAutoPlaceSearch
    },
    {
      label: t('statusFilter.label'),
      handleChange: evt => {
        const status = evt.target.value;
        setFilterStatusState(status);
        setFilterLoading(true);
        let filterBy = {
          variables: {
            organization: data.organizationId,
            placeId: filterPlaceState ? filterPlaceState.place_id : '',
            templateId: filterModelState ? filterModelState : null,
            search: filterState.camera ? filterState.camera : "",
            page: paginationState.camera ? paginationState.camera : 1
          }
        }
        if (status) {
          filterBy.variables.status = status;
        }
        setApiTimer(apiTimer + 1);
        return getCameraListByFilter(filterBy);
      },
      val: filterStatusState,
      styleOverrides: { minWidth: '172px' },
      options: CAMERA_STATUS_OPTIONS
    }
  ];

  React.useEffect(() => {
    if (reloadData) {
      switch (reloadData) {
        case "ml_template":
          reloadMlData();
          break;

        case "notification":
          reloadNotiTemplateData();
          break;

        case "places":
          reloadPlaceData();
          break;

        case "camera":
          reloadCameraData();
          break;

        case "node":
          reloadNodeList();
          break;
      }
    }
  }, [reloadData]);

  React.useEffect(() => {
    let filterBy = {
      variables: {
        organization: data.organizationId,
        placeId: filterPlaceState ? filterPlaceState.place_id : '',
        search: filterState.camera ? filterState.camera : "",
        page: paginationState.camera ? paginationState.camera : 1,
        status: filterStatusState ? filterStatusState : "",
        limit: 20,
        templateId: filterModelState ? filterModelState : null
      }
    }
    const timer = setTimeout(() => {
      setApiTimer(apiTimer + 1);
      return getCameraListByFilter(filterBy);
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [apiTimer]);

  const [getCameraListByFilter] = useLazyQuery(GET_CAMERA_LIST, {
    "fetchPolicy": "no-cache",
    onError: error => {
      setFilterLoading(false);
      console.log('Error', error);
    },
    onCompleted: data => {
      setFilterdCameraState(true);
      setFilteredCameraList(data);
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    if (activeFilterState) {
      if (activeFilterState === "ml_template") {
        reloadMlData();
        // }else if(activeFilterState === "camera"){
        //   reloadCameraData();
        //   setApiTimer(apiTimer + 1);
      } else if (activeFilterState === "place") {
        reloadPlaceData();
      } else if (activeFilterState === "notification") {
        reloadNotiTemplateData();
      } else if (activeFilterState === "vpns") {
        reloadVpnsList();
      }
    }
  }, [filterState, activeFilterState, paginationState]);

  const [updateMLTemplateWithCamera] = useMutation(UPDATE_ML_TEMPLATE_WITH_CAMERA, {
    onError: error => {
      Message.error(error);
      setUpdateCameraTempLoading(false);
    },
    onCompleted: data => {
      setUpdateCameraTempLoading(false);
      Message.success(get(data, 'updateMLTemplateWithCamera.message', null));
      reloadMlData();
      reloadCameraData();
    }
  });

  const [deleteMLTemplate] = useMutation(DELETE_ML_TEMPLATE, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('ML template deleted successfully.');
      reloadMlData();
    }
  });

  const [deleteNotificationTemplate] = useMutation(DELETE_NOTIFICATION_TEMPLATE, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('Notification template deleted successfully.');
      reloadNotiTemplateData();
    }
  });

  const [deletePlace] = useMutation(DELETE_PLACE, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('Place deleted successfully.');
      reloadPlaceData();
    }
  });

  const [deleteCamera] = useMutation(DELETE_CAMERA, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('Camera deleted successfully.');
      reloadCameraData();
    }
  });

  const [deleteMultipleCameras] = useMutation(DELETE_MULTIPLE_CAMERA, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('Cameras deleted successfully.');
      reloadCameraData();
    }
  });

  const [deleteNode] = useMutation(DELETE_NODE, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: res => {
      Message.success('Node deleted successfully.');
      reloadPlaceData();
    }
  });

  const handleMenuItemClick = (elem, itemData, type) => {

    if (!itemData) {
      return false;
    }
    let id = itemData.id;
    if (elem.label === t('edit.label')) {
      handleUpdateAction({ action: type, data: itemData });
    } else if (elem.label === t('delete.label')) {
      if (type === 'ml_template') {
        if (window.confirm('Are you sure? You want to delete this template!') === false) {
          return false;
        }
        return deleteMLTemplate({
          variables: {
            id: id
          }
        });
      } else if (type === 'notification') {
        if (window.confirm('Are you sure? You want to delete this template!') === false) {
          return false;
        }
        return deleteNotificationTemplate({
          variables: {
            id: id
          }
        });
      } else if (type === 'place') {
        if (window.confirm('Are you sure? You want to delete this place!') === false) {
          return false;
        }
        return deletePlace({
          variables: {
            id: itemData.place_id
          }
        });
      } else if (type === 'camera') {
        if (window.confirm('Are you sure? You want to delete this camera!') === false) {
          return false;
        }
        if (!itemData.camera_id) {
          return null;
        }
        return deleteCamera({
          variables: {
            id: (itemData) ? itemData.camera_id.toString() : ''
          }
        });
      } else if (type === 'node') {
        if (window.confirm('Are you sure? You want to delete this node!') === false) {
          return false;
        }
        if (itemData && itemData.id) {
          return deleteNode({
            variables: {
              id: itemData.id
            }
          });
        } else {
          return null;
        }
      }
    } else if (elem.label === t('addProxies.label')) {
      console.log("addProxies", itemData);
    } else if (elem.label === t('viewProxies.label')) {
    } else if (elem.label === t('manage.label')) {
      if (type === "node" && role.includes(USER_ROLES.IP_SUPERADMIN)) {
        if (itemData.node_page_table && itemData.node_page_table.length > 0 && itemData.node_page_table[0].physical_node) {
          history.push(`/subscriptions/nodeManagement/${data.id}/${id}`);
        } else {
          Message.error("Physical node not found!");
          return false;
        }
      }
      return null;
    }
  };

  const handleMultipleButtonClick = (action) => {
    const camIds = [];
    for (let key in checkboxCameraRow) {
      if (checkboxCameraRow[key])
        camIds.push(key);
    }
    if (camIds.length) {
      if (window.confirm('Are you sure? You want to delete selected cameras!') === false) {
        return false;
      }
      return deleteMultipleCameras({
        variables: {
          ids: camIds
        }
      });
    } else {
      return false;
    }
  };

  const handleSearch = (type, val) => {
    if (type === "camera") {
      setFilterLoading(true);
      let filterBy = {
        variables: {
          organization: data.organizationId,
          placeId: filterPlaceState ? filterPlaceState.place_id : '',
          search: val,
          page: paginationState.camera ? paginationState.camera : 1,
          status: filterStatusState ? filterStatusState : "",
          templateId: filterModelState ? filterModelState : null
        }
      }
      setApiTimer(apiTimer + 1);
      return getCameraListByFilter(filterBy);
    }
    setActiveFilterState(type);
    setFilterState({ ...filterState, [type]: val });
  }

  const handlePageChange = (type, page) => {
    if (type === "camera") {
      setFilterLoading(true);
      let filterBy = {
        variables: {
          organization: data.organizationId,
          placeId: filterPlaceState ? filterPlaceState.place_id : '',
          search: filterState.camera,
          page: page,
          status: filterStatusState ? filterStatusState : "",
          templateId: filterModelState ? filterModelState : null
        }
      }
      setApiTimer(apiTimer + 1);
      getCameraListByFilter(filterBy);
    }
    setActiveFilterState(type);
    setPaginationState({ ...paginationState, [type]: page });
  }

  const hadleUpdateCameraInMlTemp = (mlId, data, handleCloseOfMlBulkUpload, setCheckCameras) => {
    setUpdateCameraTempLoading(true);
    data.forEach(el => {
      if (!el.startTime) Message.error('Please Fill start date');
      if (!el.endTime) Message.error('Please Fill end date');
      if (el.startTime && el.endTime) {
        handleCloseOfMlBulkUpload();
        setCheckCameras({});
      }
    });
    if (data.length > 0) {
      updateMLTemplateWithCamera({
        variables: {
          id: mlId,
          payload: data
        }
      });
    } else {
      Message.error('Please fill camera Details');
    }
  };

  const handleEnableMultiPurposeDialogue = (title, data) => {
    setContentTitle(title);
    setConetentData(data);
    setIsMultiPurposeDialogueEnable(true);
  };

  const handleCloseOfMultiPurposeDialogue = () => {
    setContentTitle('');
    setConetentData('');
    setIsMultiPurposeDialogueEnable(false);
  };

  const configTabs = [
    {
      label: t('machineLearning.label') + ' ' + t('template.label'),
      children: (
        <>
          <MLPanel
            t={t}
            menuItems={menuItems}
            list={mlTemplateList}
            loading={mlTemplateLoading}
            error={mlTemplateError}
            cameraList={(filterdCameraState) ? filteredCameraList : cameraList}
            camLoading={cameraLoading || filterLoading}
            camError={cameraError}
            checkedcameraFromMlTemp={checkedcameraFromMlTemp}
            hadleUpdateCameraInMlTemp={hadleUpdateCameraInMlTemp}
            updateCameraTempLoading={updateCameraTempLoading}
            handleCreateButtonClick={handleCreateButtonClick}
            handleMenuItemClick={handleMenuItemClick}
            allowConfigure={configureActions}
            handleSearch={handleSearch}
            page={paginationState.ml_template}
            handlePageChange={handlePageChange}
          />
        </>
      )
    },
    {
      label: t('notification.label') + ' ' + t('template.label'),
      children: (
        <>
          <NotificationPanel
            t={t}
            menuItems={menuItems}
            list={notiTemplateList}
            loading={notiTemplateLoading}
            error={notiTemplateError}
            cameraList={(filterdCameraState) ? filteredCameraList : cameraList}
            camLoading={cameraLoading || filterLoading}
            camError={cameraError}
            handleCreateButtonClick={handleCreateButtonClick}
            handleMenuItemClick={handleMenuItemClick}
            allowConfigure={configureActions}
            handleSearch={handleSearch}
            page={paginationState.notification}
            handlePageChange={handlePageChange}
            reloadNotiTemplateData={reloadNotiTemplateData}
          />
        </>
      )
    },
    {
      label: t('places.label'),
      children: (
        <>
          <PlacePanel
            t={t}
            menuItems={menuItems}
            list={placeList}
            loading={placeLoading}
            error={placeError}
            handleCreateButtonClick={handleCreateButtonClick}
            handleMenuItemClick={handleMenuItemClick}
            allowConfigure={configureActions}
            handleSearch={handleSearch}
            page={paginationState.place}
            handlePageChange={handlePageChange}
          />
        </>
      )
    },
    {
      label: t('vpns.label'),
      children: (
        <>
          <Vpns
            t={t}
            menuItems={vpnMenuItems}
            list={vpnsList}
            loading={vpnsLoading}
            error={vpnsError}
            handleCreateButtonClick={handleCreateButtonClick}
            handleUpdateAction={handleUpdateAction}
            allowConfigure={configureActions}
            subscriptionData={data}
            relaodVpnsData={reloadVpnsList}
            handleSearch={handleSearch}
            page={paginationState.vpns}
            handlePageChange={handlePageChange}
          />
        </>
      )
    },
    {
      label: t('camera.label'),
      children: (
        <>
          <CameraPanel
            t={t}
            menuItems={menuItems}
            list={(filterdCameraState) ? filteredCameraList : cameraList}
            loading={cameraLoading || filterLoading}
            error={cameraError}
            filterList={cameraFilter}
            handleCreateButtonClick={handleCreateButtonClick}
            handleMenuItemClick={handleMenuItemClick}
            onHandleSelectCameraRow={onHandleSelectCameraRow}
            checkboxCameraRow={checkboxCameraRow}
            handleMultipleButtonClick={handleMultipleButtonClick}
            filterLoading={filterLoading}
            allowConfigure={configureActions}
            handleSearch={handleSearch}
            page={paginationState.camera}
            handlePageChange={handlePageChange}
            reloadList={evt => {
              let filterBy = {
                variables: {
                  organization: data.organizationId,
                  placeId: filterPlaceState ? filterPlaceState.place_id : '',
                  search: filterState.camera ? filterState.camera : "",
                  page: paginationState.camera ? paginationState.camera : 1,
                  status: filterStatusState ? filterStatusState : "",
                  templateId: filterModelState ? filterModelState : null
                }
              }
              setApiTimer(apiTimer + 1);
              return getCameraListByFilter(filterBy);
            }}
          />
        </>
      )
    },
    {
      label: t('nodeMng.label'),
      children: (
        <>
          <NodeManagementPanel
            t={t}
            menuItems={menuItems}
            list={(!nodeLoading && !nodeError) ? nodeList : null}
            loading={nodeLoading}
            error={nodeError}
            handleCreateButtonClick={handleCreateButtonClick}
            handleMenuItemClick={handleMenuItemClick}
            allowConfigure={((role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.IP_COUNTRYADMIN)) && data.nodeManagmentMode === NODE_MANAGEMENT_TYPE.UNMANAGED) ? true : false}
            extraDetails={(role.includes(USER_ROLES.IP_SUPERADMIN) || role.includes(USER_ROLES.IP_COUNTRYADMIN)) ? true : false}
            organization={organization}
            page={paginationState.node}
            handlePageChange={handlePageChange} />
        </>
      )
    },
    {
      label: 'Truck Master',
      children: (
        <ShayonaConfigurationTruckMaster
          t={t}
          creteNewTruck={handleEnableMultiPurposeDialogue}
        />
      )
    },
    {
      label: 'Audits',
      children: (
        <ShayonaAudit
          t={t}
        />
      ) 
    },
    {
      label: 'Brands',
      children: (
        <ShayonaConfigurationBrands
          t={t}
        />
      )
    }
  ];

  if (
    role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
    role.includes(USER_ROLES.IP_SUPERADMIN)
  ) {
    configTabs.push({
      label: t('liveCam.label'),
      children: (
        <>
          <EdgeAILiveCamPanel
            t={t}
            data={data}
            handleCreateURLClick={handleCreateURLClick}
            handleAddURLSubmitClick={handleAddURLSubmitClick}
            viewCustomLiveCam={true}
            liveCamURL={edgeAILiveCamURL ? edgeAILiveCamURL : EDGE_AI_LIVE_CAM_URL}
          />
        </>
      )
    });
  } else {
    if (
      !role.includes(USER_ROLES.THIRD_PARTY_VENDOR)
    ) {
      configTabs.push({
        label: t('liveCam.label'),
        children: (
          <>
            <EdgeAILiveCamPanel
              t={t}
              data={data}
              viewCustomLiveCam={false}
              liveCamURL={edgeAILiveCamURL ? edgeAILiveCamURL : EDGE_AI_LIVE_CAM_URL}
            />
          </>
        )
      });
    }
  }

  return (
    <div>
      <CustomTabs
        activeIndex={activeIndex}
        tabs={configTabs}
      />
      <TruckMasterModal
        t={t}
        open={isMultiPurposeDialogueEnable}
        onClose={handleCloseOfMultiPurposeDialogue}
        title={contentTitle}
        data={conetentData}
      />
    </div>
  );
};

ShayonaConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleCreateButtonClick: PropTypes.func.isRequired
};

export default React.memo(ShayonaConfiguration);