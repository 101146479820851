import "date-fns";
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from '../../../components/OutlinedSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { IsNumeric } from '../../../utils';
import Button from '@material-ui/core/Button';

const TableCell = withStyles({
    root: {
        borderBottom: 0
    }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 40
    },
    loader: {
        width: "100%",
        textAlign: "center"
    },
    yearMonthFilter: {

        padding: 10
    }
}));

const initAddOns = { moduleName: "", number: 0, costPerMonth: 0, amount: 0, freeTierDiscount: 0, finalPrice: 0 };
const initTotal = { taxes: 0, totalCost: 0, totalDiscount: 0, totalPayableAmt: 0 };

const BillingUtilization = ({
    t,
    loadingData,
    handleChangeDateFilter,
    startDate,
    allowConfigure,
    currency,
    handleChangeCurrency,
    currencyList,
    billingData,
    handleSubmitInvoice,
    paidBtnLoading,
    handlePaidInvoice,
    saveBtnLoading
}) => {

    const classes = useStyles();
    const [addonsState, setAddonsState] = useState([initAddOns]);
    const [totalState, setTotalState] = useState(initTotal);
    const [notAllowChange, setNotAllowChange] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [billingSubscriptions, setBillingSubscriptions] = useState([]);
    const [invoicePaid, setInvoicePaid] = useState(false);
    const [invoiceId, setInvoiceId]     = useState(null);
    const [readyState, setReadyState]   = useState(false);
    const [invoiceBillData, setInvoiceBillData] = useState({ startDate: null, endDate: null, invoiceNumber: "", totalPayableAmount: 0, currancy: "", invoiceDate: "" });    
    const ref = React.createRef();

    const handleChangeAddons = (evt, idx) => {
        const { name, value } = evt.target;
        if (name !== "moduleName") {
            if (!value) {
                calculateAddonsPrice(evt, idx);
                setAddonsState(
                    addonsState.map((item, i) =>
                        i === idx
                            ? { ...item, [name]: 0 }
                            : item
                    ));
                return null;
            }
            if (!IsNumeric(value)) {
                return null;
            }
            calculateAddonsPrice(evt, idx);
        }
        setAddonsState(
            addonsState.map((item, i) =>
                i === idx
                    ? { ...item, [name]: name !== "moduleName" ? parseFloat(value) : value }
                    : item
            ));
    }

    const calculateAddonsPrice = (evt, indx) => {
        const { name, value } = evt.target;
        const preData = addonsState[indx];
        let amount = preData.amount;
        let finalPrice = preData.finalPrice;
        if (name === "number") {
            if (preData.costPerMonth > 0) {
                amount = preData.costPerMonth * parseFloat(value);
            } else {
                amount = 0;
            }
        } else if (name === "costPerMonth") {
            if (value > 0) {
                amount = preData.number * parseFloat(value);
            } else {
                amount = 0;
            }
        }
        if (name === "freeTierDiscount") {
            finalPrice = amount - parseFloat(value);
        } else {
            finalPrice = preData.freeTierDiscount ? amount - preData.freeTierDiscount : amount;
        }
        setAddonsState(
            addonsState.map((item, i) => {
                if (i === indx) {
                    item.finalPrice = finalPrice;
                    item.amount = amount;
                    return item;
                } else {
                    return item;
                }
            }
        ));
    }

    const handleChangeTaxes = (evt) => {
        const { name, value } = evt.target;
        if (!value) {
            setTotalState({ ...totalState, [name]: 0 });
            return null;
        }
        if (!IsNumeric(value)) {
            return null;
        }
        setTotalState({ ...totalState, [name]: parseFloat(value) });
    }

    const handleCreateAddOns = () => {
        setAddonsState([...addonsState, initAddOns]);
    }

    useEffect(() => {
        console.log("currencyList", currencyList);
        if(billingData){
            let subscriptions = [];
            setAddonsState([initAddOns]);
            setTotalState(initTotal);
            setInvoicePaid(false);
            setReadyState(true);
            if(billingData.invoice && billingData.invoice.length > 0){
                let invoiceData = billingData.invoice[0];
                setInvoiceBillData({...invoiceBillData, totalPayableAmount: invoiceData.totalPayableAmount, invoiceNumber: invoiceData.invoiceNumber, invoiceDate: invoiceData.invoiceDate });
                setTotalState({taxes: invoiceData.taxAmount, totalCost: invoiceData.totalCost, totalDiscount: invoiceData.totalDiscount, totalPayableAmt: invoiceData.totalPayableAmount ? invoiceData.totalPayableAmount : 0});
                setInvoiceId(invoiceData._id);
                if(invoiceData.status === "Paid"){
                    setInvoicePaid(true);
                }
                let addons = [];
                if(invoiceData.utilizations && invoiceData.utilizations.length > 0){
                    invoiceData.utilizations.map(item => {
                        if(item.type === "subscription"){
                            subscriptions.push({ moduleName: item.moduleName, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        }else if(item.type === "addons"){
                            addons.push({ moduleName: item.moduleName, number: item.numberOfItems, costPerMonth: item.perItemCost, amount: item.amount, freeTierDiscount: item.discount, finalPrice: item.finalPrice });
                        }
                    });
                }
                setAddonsState(addons.length > 0 ? addons : [initAddOns]);
            }else{
                setInvoiceId(null);
                if(billingData.subscriptions && billingData.subscriptions.length > 0){
                    for(let item of billingData.subscriptions){
                        let name = item.service ? item.service.name : "";
                        name = item.name ? item.name : name;
                        subscriptions.push({ moduleName: name, amount: 0, freeTierDiscount: 0, finalPrice: 0 });
                    }
                }
            }
            setBillingSubscriptions(subscriptions);
        }
        setNotAllowChange(!allowConfigure);
    }, [billingData]);

    const handleChangeSubscription = (evt, idx) => {
        const { name, value } = evt.target;
        if (!value) {
            calculateSubscriptionPrice(evt, idx);
            setBillingSubscriptions(
                billingSubscriptions.map((item, i) =>
                    i === idx
                        ? { ...item, [name]: 0 }
                        : item
            ));
            return null;
        }
        if (!IsNumeric(value)) {
            return null;
        }
        calculateSubscriptionPrice(evt, idx);
        setBillingSubscriptions(billingSubscriptions.map((item, i) => i === idx ? { ...item, [name]: parseFloat(value) } : item));
    }

    const calculateSubscriptionPrice = (evt, indx) => {
        const { name, value } = evt.target;
        const preData = billingSubscriptions[indx];
        let amount = preData.amount;
        let finalPrice = preData.finalPrice;
        if (name === "amount") {
            amount = value;
            finalPrice = preData.freeTierDiscount ? amount - preData.freeTierDiscount : amount;
        }else if (name === "freeTierDiscount") {
            finalPrice = amount - parseFloat(value);
        } else {
            finalPrice = preData.freeTierDiscount ? amount - preData.freeTierDiscount : amount;
        }
        setBillingSubscriptions(
            billingSubscriptions.map((item, i) => {
                if (i === indx) {
                    item.finalPrice = finalPrice;
                    item.amount = amount;
                    return item;
                } else {
                    return item;
                }
            }
        ));
    }

    useEffect(() => {
        let totalCost = 0;
        let totalDiscount = 0;
        if(billingSubscriptions.length){
            for(let subs of billingSubscriptions){
                if(subs.amount){
                    totalCost = totalCost+subs.amount;
                }
                if(subs.freeTierDiscount){
                    totalDiscount = totalDiscount+subs.freeTierDiscount;
                }
            }
        }
        if(addonsState.length){
            for(let adns of addonsState){
                if(adns.amount){
                    totalCost = totalCost+adns.amount;
                }
                if(adns.freeTierDiscount){
                    totalDiscount = totalDiscount+adns.freeTierDiscount;
                }
            }
        }
        let totalPayableAmount = totalDiscount ? totalCost-totalDiscount : totalCost;
        if(totalState.taxes){
            totalPayableAmount = totalPayableAmount+totalState.taxes;
        }
        setTotalState({...totalState, totalPayableAmt: totalPayableAmount, totalCost, totalDiscount});
    }, [totalState.taxes, addonsState, billingSubscriptions]);

    useEffect(() => {
        if(currency){
            setSelectedCurrency(currency);
        }
    }, [currency]);

    return (
        <div className={classes.root}>
            <Grid container style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["month", "year"]}
                            value={startDate}
                            size="small"
                            onChange={handleChangeDateFilter}
                            InputProps={
                                { style: { minWidth: 220, padding: 9 } }
                            }
                        />
                    </MuiPickersUtilsProvider>
                    {allowConfigure &&
                        <OutlinedSelect
                            val={currency}
                            label={`${t('currency.label')} *`}
                            handleChange={evt => {
                                handleChangeCurrency(evt.target.value);
                            }}
                            styleOverrides={{ width: '220px', marginRight: 0, marginLeft: 10 }}
                            options={currencyList.length ? currencyList : []}
                        />
                    }
                </Grid>
            </Grid>
            {loadingData ?
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
                :
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            {/*************  Subscription module **************** */}
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography color="primary" style={{ marginTop: 30 }} variant="h6" component="h6">{t("subscriptions.label")}</Typography>
                                </Grid>
                            </Grid>
                            {billingSubscriptions.map((item, index) => {
                                return (
                                    <Table key={index} className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell width="30%" align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.moduleName}
                                                        id="filled-basic"
                                                        label={t("moduleName.label")}
                                                        name="moduleName"
                                                        disabled={true}
                                                        variant="filled"
                                                        style={{ minWidth: "80px", width: "100%" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        id="filled-basic"
                                                        value={item.amount}
                                                        name="amount"
                                                        onChange={evt => { handleChangeSubscription(evt, index) }}
                                                        disabled={notAllowChange}
                                                        label={t("amount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.freeTierDiscount}
                                                        id="filled-basic"
                                                        disabled={notAllowChange}
                                                        name="freeTierDiscount"
                                                        label={t("freeTierDiscount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={evt => { handleChangeSubscription(evt, index) }}
                                                        style={{ minWidth: "150px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.finalPrice}
                                                        id="filled-basic"
                                                        label={t("finalPrice.label")}
                                                        name="finalPrice"
                                                        variant="filled"
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}

                            {/*************  Addons module **************** */}
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography color="primary" style={{ marginTop: 30 }} variant="h6" component="h6">{t("addOns.label")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {allowConfigure &&
                                        <Button disabled={invoicePaid} style={{ marginTop: 22, marginRight: 17, float: "right" }} size="medium" variant="contained" color="primary" onClick={handleCreateAddOns}>{t("createAddons.label")}</Button>
                                    }
                                </Grid>
                            </Grid>
                            {addonsState.map((item, index) => {
                                return (
                                    <Table key={index} className={classes.table} aria-label="caption table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.moduleName}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        id="filled-basic"
                                                        label={t("moduleName.label")}
                                                        name="moduleName"
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{ minWidth: "80px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{ width: "13%" }}>
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        id="filled-basic"
                                                        name="number"
                                                        value={item.number}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        label={t("number.label")}
                                                        disabled={notAllowChange}
                                                        variant="filled"
                                                        style={{ minWidth: "80px", marginRight: 8 }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.costPerMonth}
                                                        id="filled-basic"
                                                        name="costPerMonth"
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        label={t("costPerMonth.label")}
                                                        disabled={notAllowChange || !item.number}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "180px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        id="filled-basic"
                                                        value={item.amount}
                                                        name="amount"
                                                        disabled={true}
                                                        label={t("amount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.freeTierDiscount}
                                                        id="filled-basic"
                                                        disabled={notAllowChange || !item.number}
                                                        name="freeTierDiscount"
                                                        label={t("freeTierDiscount.label")}
                                                        variant="filled"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={evt => { handleChangeAddons(evt, index) }}
                                                        style={{ minWidth: "150px" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                                        size="small"
                                                        value={item.finalPrice}
                                                        id="filled-basic"
                                                        label={t("finalPrice.label")}
                                                        name="finalPrice"
                                                        variant="filled"
                                                        disabled={true}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ minWidth: "100px" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}
                            <Grid container style={{ marginTop: 30 }}>
                                <Grid item xs={10}>
                                    <Typography variant="subtitle1">{t("taxes.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                        size="small"
                                        disabled={notAllowChange}
                                        value={totalState.taxes}
                                        onChange={handleChangeTaxes}
                                        id="filled-basic"
                                        name="taxes"
                                        label={t("amount.label")}
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 168, maxWidth: 168 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 30 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">{t("total.label")} : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalCost.label")}
                                        variant="filled"
                                        value={totalState.totalCost}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 150, maxWidth: 165 }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("totalDiscount.label")}
                                        variant="filled"
                                        value={totalState.totalDiscount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 150, maxWidth: 165 }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                        size="small"
                                        id="filled-basic"
                                        disabled={true}
                                        label={t("toatlPricePayable.label")}
                                        variant="filled"
                                        value={totalState.totalPayableAmt}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{selectedCurrency ? selectedCurrency.symbol : "$"}</InputAdornment>
                                            ),
                                        }}
                                        style={{ minWidth: 168, maxWidth: 168 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {allowConfigure &&
                        <Grid container className={classes.container}>
                            <Grid item xs={(!invoicePaid && invoiceId) ? 8 : 10}>
                            </Grid>
                            {(!invoicePaid && invoiceId) && 
                                <Grid item xs={2}>
                                    <Button disabled={paidBtnLoading} onClick={evt => {
                                        if(paidBtnLoading){
                                            return null;
                                        }
                                        handlePaidInvoice({ status: "Paid", id: invoiceId })
                                    }} style={{marginTop: 22, width: "92%"}} size="medium" variant="contained" color="secondary">{!paidBtnLoading ? t("paid.label") : <CircularProgress color="secondary" size={12} /> }</Button>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <Button disabled={invoicePaid || saveBtnLoading} style={{marginTop: 22, width: "90%"}} size="medium" variant="contained" color="primary" onClick={evt => {
                                    if(saveBtnLoading){
                                        return null;
                                    }
                                    handleSubmitInvoice({ totalState, addonsState, subscriptions: billingSubscriptions, startDate: new Date(startDate).getTime()/1000, invoiceId });
                                }}>{!saveBtnLoading ? t("submit.label") : <CircularProgress color="secondary" size={12} /> }</Button>
                            </Grid>
                        </Grid>
                    }
                </>
            }
        </div>
    );
};
export default BillingUtilization;