import React, { useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Dialog from '@material-ui/core/Dialog';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { UPDATE_MAIL_TICKET } from '../../mutations';
import {
  GET_INCIDENT_TYPE_GROUP_LIST,
  GET_ALL_ABUJANG_LIST,
  GET_ALL_PHCNG_LIST,
  GET_ALL_LAGOSNG_LIST,
  GET_BASE_STATION_LIST,
  GET_LOCATION_LIST
} from '../../queries';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    iconEdit: {
      cursor: 'pointer',
      fontSize: 'large',
      marginLeft: '10px'
    }
  })
);

function TicketChangeAdditionalData({ id, reloadTickets, type, t, ticketData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [abuja, setAbuja] = React.useState('');
  const [lagos, setLagos] = React.useState('');
  const [phc, setPhc] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [baseStation, setBaseStation] = React.useState('');

  const [getAbujaNGListData, { data: getAbujaNGList }] = useLazyQuery(
    GET_ALL_ABUJANG_LIST
  );
  const [getPhcNGListData, { data: getPhcNGList }] = useLazyQuery(
    GET_ALL_PHCNG_LIST
  );
  const [getLagosNGListData, { data: getLagosNGList }] = useLazyQuery(
    GET_ALL_LAGOSNG_LIST
  );
  const [getBaseStationListData, { data: getBaseStationList }] = useLazyQuery(
    GET_BASE_STATION_LIST
  );
  const [getLocationListData, { data: getLocationList }] = useLazyQuery(
    GET_LOCATION_LIST
  );

  useEffect(() => {
    if (type === 'phc') {
      getPhcNGListData();
    }
    if (type === 'lagos') {
      getLagosNGListData();
    }
    if (type === 'abuja') {
      getAbujaNGListData();
    }
    if (type === 'location') {
      getLocationListData();
    }
    if (type === 'basestation') {
      getBaseStationListData();
    }
  }, []);

  const [updateMailServiceTicket] = useMutation(UPDATE_MAIL_TICKET, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Ticket updated successfully.');
      reloadTickets();
      setOpen(false);
      setAbuja('');
      setLagos('');
      setPhc('');
      setLocation('');
      setBaseStation('');
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    console.log('type', type, event.target.value);
    if (type === 'phc') {
      setPhc(event.target.value);
    }

    if (type === 'lagos') {
      setLagos(event.target.value);
    }

    if (type === 'abuja') {
      setAbuja(event.target.value);
    }

    if (type === 'location') {
      setLocation(event.target.value);
    }

    if (type === 'basestation') {
      setBaseStation(event.target.value);
    }
  };

  return (
    <>
      <EditTwoToneIcon
        color="primary"
        onClick={handleOpen}
        className={classes.iconEdit}
      />

      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={'sm'}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {type === 'phc'
              ? t('changePhcFieldOps.label')
              : type === 'lagos'
                ? t('changeLagosFieldOps.label')
                : type === 'abuja'
                  ? t('changeAbujaFieldOps.label')
                  : type === 'location'
                    ? t('changeLocation.label')
                    : t('changeBaseStation.label')}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {type === 'phc'
                      ? t('selectPhcFieldOps.label')
                      : type === 'lagos'
                        ? t('selectLagosFieldOps.label')
                        : type === 'abuja'
                          ? t('selectAbujaFieldOps.label')
                          : type === 'location'
                            ? t('selectLocation.label')
                            : t('selectBaseStation.label')}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={
                      type === 'phc'
                        ? phc
                        : type === 'lagos'
                          ? lagos
                          : type === 'abuja'
                            ? abuja
                            : type === 'location'
                              ? location
                              : baseStation
                    }
                    className="his"
                    style={{ width: '100%' }}
                    placeholder="Please select Incident Type Group"
                    onChange={handleChange}>
                    <MenuItem value="" hidden selected>
                      Select Item
                    </MenuItem>
                    {type === 'phc' &&
                      getPhcNGList &&
                      getPhcNGList.getPhcNGList &&
                      getPhcNGList.getPhcNGList.map(data => (
                        <MenuItem value={data.id}>{data.memberName}</MenuItem>
                      ))}

                    {type === 'lagos' &&
                      getLagosNGList &&
                      getLagosNGList.getLagosNGList &&
                      getLagosNGList.getLagosNGList.map(data => (
                        <MenuItem value={data.id}>{data.memberName}</MenuItem>
                      ))}

                    {type === 'abuja' &&
                      getAbujaNGList &&
                      getAbujaNGList.getAbujaNGList &&
                      getAbujaNGList.getAbujaNGList.map(data => (
                        <MenuItem value={data.id}>{data.memberName}</MenuItem>
                      ))}

                    {type === 'location' &&
                      getLocationList &&
                      getLocationList.getLocationList &&
                      getLocationList.getLocationList.map(data => (
                        <MenuItem value={data.id}>
                          {data.ulCTCLocation}
                        </MenuItem>
                      ))}

                    {type === 'basestation' &&
                      getBaseStationList &&
                      getBaseStationList.getBaseStationList &&
                      getBaseStationList.getBaseStationList.map(data => (
                        <MenuItem value={data.id}>
                          {data.ulCTCBaseStationCode}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant={'contained'}
              disabled={false}
              color={'primary'}
              onClick={() => {
                if (type === 'phc') {
                  if (!phc) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                  }

                  return updateMailServiceTicket({
                    variables: {
                      serviceTicketId: id,
                      phcNGData: phc,
                      title: ticketData.title,
                      mailThreadId: ticketData.ticketId
                    }
                  });
                }

                if (type === 'lagos') {
                  if (!lagos) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                  }

                  return updateMailServiceTicket({
                    variables: {
                      serviceTicketId: id,
                      lagosNGData: lagos,
                      title: ticketData.title,
                      mailThreadId: ticketData.ticketId
                    }
                  });
                }

                if (type === 'abuja') {
                  if (!abuja) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                  }

                  return updateMailServiceTicket({
                    variables: {
                      serviceTicketId: id,
                      abujaNGData: abuja,
                      title: ticketData.title,
                      mailThreadId: ticketData.ticketId
                    }
                  });
                }

                if (type === 'location') {
                  if (!location) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                  }

                  return updateMailServiceTicket({
                    variables: {
                      serviceTicketId: id,
                      location: location,
                      title: ticketData.title,
                      mailThreadId: ticketData.ticketId
                    }
                  });
                }

                if (type === 'basestation') {
                  if (!baseStation) {
                    Message.error(REQUIRED_ERROR);
                    return null;
                  }

                  return updateMailServiceTicket({
                    variables: {
                      serviceTicketId: id,
                      baseStation: baseStation,
                      title: ticketData.title,
                      mailThreadId: ticketData.ticketId
                    }
                  });
                }
              }}>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default withTranslation()(withRouter(TicketChangeAdditionalData));
