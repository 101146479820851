import React, {useState} from 'react';
import {colors} from '../../../../theme/colors';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Popover from '@material-ui/core/Popover';
import {
    ORDER_DIA_ADMIN_TRACK_STATUS,
    ORDER_DIA_TRACK_STATUS, ORDER_DIAN_ADMIN_TRACK_STATUS,
    ORDER_TRACK_STATUS,
    SERVICE_TYPES
} from '../../../../utils/constants';
import {paddingGenerator} from '../../../../theme/utils';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '240px'
    },
    listRoot: {
        padding: theme.spacing(2, 4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

function NestedMenu({
                        t,
                        anchorEl,
                        handleClose,
                        handleMenuItemClick,
                        serviceType,
                        handleViewDetailsClick
                    }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const getNestedMenu = (serviceType) => {
        switch (serviceType) {
            case SERVICE_TYPES.DIA:
                return ORDER_DIA_ADMIN_TRACK_STATUS;
            case SERVICE_TYPES.DIAN:
                return ORDER_DIAN_ADMIN_TRACK_STATUS;
            default:
                return ORDER_TRACK_STATUS;
        }
    }
    return (
        <Popover
            id="siteItem-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            classes={{
                paper: classes.root
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            onClose={evt => handleClose(evt)}>
            <List>
                <ListItem
                    button
                    style={{borderBottom: `1px solid ${colors.grey[200]}`}}
                    onClick={handleViewDetailsClick}
                    classes={{root: classes.listRoot}}>
                    <ListItemText primary={t('viewDetails.label')}/>
                </ListItem>
                <ListItem
                    style={{borderBottom: `1px solid ${colors.grey[200]}`}}
                    button
                    onClick={() => setOpen(!open)}
                    classes={{root: classes.listRoot}}>
                    <ListItemText primary={t('changeStatus.label')}/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {getNestedMenu(serviceType).map((item, index) => (
                            <ListItem
                                onClick={() => handleMenuItemClick(item)}
                                style={{
                                    ...(index !== getNestedMenu(serviceType).length - 1
                                        ? {borderBottom: `1px solid ${colors.grey[200]}`}
                                        : null),
                                    ...paddingGenerator('pl-48')
                                }}
                                button
                                key={index}
                                classes={{root: classes.listRoot}}>
                                <ListItemText primary={item}/>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </List>
        </Popover>
    );
}

NestedMenu.propTypes = {
    anchorEl: PropTypes.instanceOf(Element).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};

export default NestedMenu;
