import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import OutlinedSelect from '../../../../components/OutlinedSelect';
import Radio from '@material-ui/core/Radio';
import { colors } from '../../../../theme/colors';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';
import { REQUIRED_ERROR, MULTI_OBJECTS_LIST, FREQUENCY_OPTIONS, ACD_OBJECTS_LIST, OBJECTS_DETECTION_LIST } from '../../../../utils/constants';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Fab from '@material-ui/core/Fab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import { removeSpacialCharecter, IsNumeric } from '../../../../utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: 900 },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    },
    faceLarge: {
        width: "40px",
        height: "40px",
    },
    faceRcContain: {
        '& .MuiInput-root': { minWidth: 'auto' }
    },
    objDetecType: {
        height: 48,
        width: 70
    }
}));

function valuetext(value) {
    return `${value}°C`;
}

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}
function UpdateMLTemplateModal({
    t,
    open,
    data,
    handleCreateMLSubmitClick,
    handleClose,
    aiTypes,
    handleSelectAIType,
    modalState,
    selectedAiType,
    lineCrossObject,
    lineCrossDataLoading,
    dataLoading,
    providerTypes,
    multiObjectOpt,
    frequencies,
    accidentDetectionObj,
    customDetectionObjects
}) {

    const initMultiObjectFields = { name: '', threshold: 0, number: '', condition: "", between_number: '' };
    const multiObjectConditions = [{ id: "greater", name: "Greater then" }, { id: "in_between", name: "In between" }, { id: "lesser", name: "Lesser then" }];
    const initFaceRecoState = { name: '', image: '', files: '', list_type: "", notification: false, disable_noti_field: false };
    const initAcDFields = { name: '', threshold: 0, accident_threshold: 0, number: '', condition: "", between_number: '' };
    const initAbsDetecFields = { name: '', threshold: 0, number: '', condition: "", between_number: '', custom_duration: "" };
    const initLPRState = { plate_number: '', list_type: "", notification: false, disable_noti_field: false, car_description: "", min_character: 0, max_character: 0 };
    const initObjectDetecFields = { type: '', class: "", threshold: 0, notification: true, base_class: "" };

    const classes = useStyles();
    const [templateName, setTemplateName] = useState("");
    const [aiTemplateType, setAiTemplateType] = useState("");
    const [priority, setPriority] = useState("");
    const [aiParamSubFields, setAiParamSubFields] = useState({});
    const [aiParamsOptions, setAiParamOptions] = useState([]);
    const [aiTemplateSubElem, setAITemplateSubElem] = useState(null);
    const [changeSubFieldState, setChangeSubFieldState] = useState(false);
    const [aiTypeName, setAiTypeName] = useState('');
    const [multiObjects, setMultiObjects] = useState([]);
    const [faceRecognition, setFaceRecognition] = useState([]);
    const [lineCrossList, setLineCrossList] = useState([]);
    const [lcrTimer, setLcrTimer] = useState(false);
    const [notificationFrequency, setNotificationFrequency] = useState("");
    const [definationName, setDefinationName] = useState("");
    const [lineCrossBase64Image, setLineCrossBase64Image] = useState("");
    const [accidentDetections, setAccidentDetections] = useState([]);
    const [oldFaceRecognition, setOldFaceRecognition] = useState(null);
    const [absenceDetection, setAbsenceDetection] = useState([]);
    const [licencePlates, setLicencePlates] = useState([{ ...initLPRState }]);
    const [objectDetections, setObjectDetections] = useState([{ ...initObjectDetecFields }]);

    React.useEffect(() => {
        if (selectedAiType) {
            let aiTypeDtl = null;
            for (let key in aiTypes) {
                if (aiTypes[key].id === selectedAiType) {
                    aiTypeDtl = aiTypes[key];
                    setAiTypeName(aiTypes[key].name);
                }
            }
            setAiParamSubFields({});
            setChecked([]);
            setAiTemplateType(selectedAiType);
            setAiParamOptions((aiTypeDtl && aiTypeDtl.params.length) ? aiTypeDtl.params : []);
            updateAiSubFields(selectedAiType);
        }
    }, [selectedAiType]);

    React.useEffect(() => {
        if (modalState.clearData && open) {
            setTemplateName('');
            setPriority('');
            setAiTemplateType('');
            setAITemplateSubElem(null);
            setAiParamSubFields({});
            setAiParamOptions([]);
            setFaceRecognition([{ ...initFaceRecoState }]);
            setLineCrossList([]);
            setMultiObjects([]);
            setLicencePlates([{ ...initLPRState }]);
            handleClose();
        }
    }, [modalState]);

    const [checked, setChecked] = React.useState([]);
    const handleCheckAIParam = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    useEffect(() => {
        updateAiSubFields(aiTemplateType);
    }, [checked]);

    const onChangeAiSubFields = (value, item, field) => {
        let prevState = aiParamSubFields;
        if (item.fieldType == 'text') {
            prevState[field] = value;
        } else {
            prevState[item.key] = value;
        }
        setAiParamSubFields(prevState);
        setChangeSubFieldState(!changeSubFieldState);
    }

    const updateAiSubFields = (value) => {
        let elements = {};
        if (aiParamsOptions && aiParamsOptions.length) {
            for (let i = 0; i < aiParamsOptions.length; i++) {
                let item = aiParamsOptions[i];
                if (item.fieldType === 'radio') {
                    elements[item.key] = item.options.map((field, index) => {
                        return (
                            <Radio
                                key={index}
                                disabled={!checked.includes(item.key)}
                                checked={field === aiParamSubFields[item.key]}
                                classes={{ root: classes.iconButton }}
                                style={{
                                    ...marginGenerator('mr-16'),
                                    ...marginGenerator('mt-0'),
                                    ...(field === aiParamSubFields[item.key]
                                        ? { backgroundColor: colors.secondary.main }
                                        : '')
                                }}
                                onChange={evt => onChangeAiSubFields(field, item)}
                                value={field}
                                name="site-select"
                                checkedIcon={
                                    <div>
                                        <Typography
                                            variant={'subtitle2'}
                                            style={{ color: colors.common.white }}>
                                            {field}
                                        </Typography>
                                    </div>
                                }
                                icon={
                                    <div className={classes.icon}>
                                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                                            {field}
                                        </Typography>
                                    </div>
                                }
                            />
                        );
                    });
                } else if (item.fieldType === 'text') {
                    elements[item.key] = item.options.map((field, index) => {
                        return (
                            <Input
                                disabled={!checked.includes(item.key)}
                                key={index}
                                value={aiParamSubFields[field.name]}
                                type={item.inputType ? item.inputType : 'text'}
                                placeholder={field.label}
                                style={{ minWidth: 'auto', maxWidth: '160px', marginLeft: '15px' }}
                                color={'secondary'}
                                onChange={evt => onChangeAiSubFields(evt.target.value, item, field.name)}
                            />
                        );
                    });
                } else if (item.fieldType === 'slider') {
                    elements[item.key] =
                        <Slider
                            disabled={!checked.includes(item.key)}
                            defaultValue={aiParamSubFields[item.key] ? parseFloat(aiParamSubFields[item.key]) : item.defaultValue}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={0.1}
                            marks
                            min={item.min}
                            max={item.max}
                            onChange={(evt, number) => {
                                onChangeAiSubFields(number, item);
                            }}
                        />
                } else if (item.fieldType === 'dropdown') {
                    elements[item.key] =
                        <OutlinedSelect
                            disabled={!checked.includes(item.key)}
                            val={aiParamSubFields[item.key]}
                            label={item.name}
                            handleChange={evt => {
                                onChangeAiSubFields(evt.target.value, item);
                            }}
                            selectStyle={{ ...paddingGenerator('p-8') }}
                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: '180px' }}
                            options={item.options}
                        />
                } else if (item.fieldType === 'multi_select') {
                    elements[item.key] = <OutlinedSelect
                        disabled={!checked.includes(item.key)}
                        multiple={true}
                        val={aiParamSubFields[item.key] && aiParamSubFields[item.key].length > 0 ? aiParamSubFields[item.key] : []}
                        label={item.name}
                        handleChange={evt => {
                            onChangeAiSubFields(evt.target.value, item);
                        }}
                        selectStyle={{ ...paddingGenerator('p-8') }}
                        styleOverrides={{ width: '100%', marginRight: 0, minWidth: '300px' }}
                        options={item.options}
                    />
                }
            }
        }
        if (value === 'people_counting_alert' || value === 'line_cross' || value === 'line_cross2') {
            setLcrTimer(true);
        }
        setAITemplateSubElem(elements);
    }

    useEffect(() => {
        updateAiSubFields(aiTemplateType);
    }, [changeSubFieldState]);

    const onCreateMLTemplateSubmit = () => {
        let postData = { name: templateName, type: aiTemplateType, priority: priority, id: data.id };
        let definition = { [aiTemplateType]: {} };
        let lineCrossObjectData = [];
        if (aiTemplateType === 'people_counting_alert') {
            definition[aiTemplateType].line_params = { end_x: 0, end_y: 0, start_x: 0, start_y: 0 };
            definition[aiTemplateType].region_params = { lower_region: "exit", upper_region: "entry" };
        }
        if (aiTemplateType === 'multi_object') {
            if (multiObjects.length) {
                let objects = {};
                for (let i = 0; i < multiObjects.length; i++) {
                    if (multiObjects[i].name) {
                        let key = multiObjects[i].name;
                        Object.assign(objects, { [key]: { threshold: multiObjects[i].threshold ? multiObjects[i].threshold.toString() : "0", number: multiObjects[i].number ? multiObjects[i].number.toString() : "0", condition: multiObjects[i].condition, between_number: multiObjects[i].between_number ? multiObjects[i].between_number.toString() : "0" } });
                    }
                }
                delete definition[aiTemplateType];
                definition['multi-object'] = { objects };
            }
        } else if (aiTemplateType === 'accident_detection') {
            if (accidentDetections.length) {
                let objects = {};
                for (let i = 0; i < accidentDetections.length; i++) {
                    if (accidentDetections[i].name) {
                        let key = accidentDetections[i].name;
                        Object.assign(objects, { [key]: { threshold: accidentDetections[i].threshold ? accidentDetections[i].threshold.toString() : "0", accident_threshold: accidentDetections[i].accident_threshold ? accidentDetections[i].accident_threshold.toString() : "0" } });
                    }
                }
                definition[aiTemplateType] = { objects };
            }
        } else if (aiTemplateType === 'face_recognition') {
            if (faceRecognition.length) {
                let faceObjects = [];
                for (let i = 0; i < faceRecognition.length; i++) {
                    let key = faceRecognition[i].name;
                    if (faceRecognition[i].files) {
                        let file = (faceRecognition[i].files === faceRecognition[i].singed_url) ? faceRecognition[i].main_url : faceRecognition[i].files;
                        let enable_notification = true;
                        if (faceRecognition[i].list_type && faceRecognition[i].list_type === "whitelist") {
                            enable_notification = (faceRecognition[i].notification) ? true : false;
                        }
                        faceObjects.push({ [key]: file, list_type: faceRecognition[i].list_type ? faceRecognition[i].list_type : "whitelist", enable_notification });
                    }
                }
                definition[aiTemplateType] = { faces: faceObjects };
            }

        } else if (aiTemplateType === 'license_plate_recognition') {
            if (licencePlates.length) {
                let plates = [];
                for (let i = 0; i < licencePlates.length; i++) {
                    let enable_notification = true;
                    if (licencePlates[i].list_type && licencePlates[i].list_type === "whitelist") {
                        enable_notification = (licencePlates[i].notification) ? true : false;
                    }
                    plates.push({ plate_number: licencePlates[i].plate_number, list_type: licencePlates[i].list_type ? licencePlates[i].list_type : "whitelist", enable_notification, car_description: licencePlates[i].car_description, min_character: licencePlates[i].min_character ? parseInt(licencePlates[i].min_character) : 0, max_character: licencePlates[i].max_character ? parseInt(licencePlates[i].max_character) : 0 });
                }
                definition[aiTemplateType] = { plates };
            }
        } else if (aiTemplateType === 'line_cross' || aiTemplateType === 'line_cross2') {

            if (lineCrossList.length) {
                let lines = {};
                for (let i = 0; i < lineCrossList.length; i++) {
                    let key = (lineCrossList[i].name) ? lineCrossList[i].name : 'line' + (i + 1);
                    Object.assign(lines, { [key]: { start_x: lineCrossList[i].relativeCords.startX ? lineCrossList[i].relativeCords.startX.toString() : "0", start_y: lineCrossList[i].relativeCords.startY ? lineCrossList[i].relativeCords.startY.toString() : "0", end_x: lineCrossList[i].relativeCords.endX ? lineCrossList[i].relativeCords.endX.toString() : "0", end_y: lineCrossList[i].relativeCords.endY ? lineCrossList[i].relativeCords.endY.toString() : "0", objects: lineCrossList[i].lineObjects, thresh_conf: lineCrossList[i].thresh_conf.toString() } });
                    lineCrossObjectData.push({ coordinates: lineCrossList[i].coordinates ? lineCrossList[i].coordinates : null, pointers: lineCrossList[i].pointers ? lineCrossList[i].pointers : null, objects: lineCrossList[i].lineObjects, thresh_conf: lineCrossList[i].thresh_conf.toString(), relativeCords: lineCrossList[i].relativeCords, name: lineCrossList[i].name });
                }
                definition[aiTemplateType] = { lines };
            }
        } else if (aiTemplateType === 'volume_analysis') {

            if (lineCrossList.length) {
                let lines = {};
                for (let i = 0; i < lineCrossList.length; i++) {
                    let key = (lineCrossList[i].name) ? lineCrossList[i].name : 'line' + (i + 1);
                    Object.assign(lines, { [key]: { start_x: lineCrossList[i].relativeCords.startX ? lineCrossList[i].relativeCords.startX.toString() : "0", start_y: lineCrossList[i].relativeCords.startY ? lineCrossList[i].relativeCords.startY.toString() : "0", end_x: lineCrossList[i].relativeCords.endX ? lineCrossList[i].relativeCords.endX.toString() : "0", end_y: lineCrossList[i].relativeCords.endY ? lineCrossList[i].relativeCords.endY.toString() : "0" } });
                    lineCrossObjectData.push({ coordinates: lineCrossList[i].coordinates ? lineCrossList[i].coordinates : null, pointers: lineCrossList[i].pointers ? lineCrossList[i].pointers : null, relativeCords: lineCrossList[i].relativeCords, name: lineCrossList[i].name });
                }
                definition[aiTemplateType] = { lines };
            }
        } else if (aiTemplateType === "absence_detection") {
            if (absenceDetection.length) {
                let objects = {};
                for (let i = 0; i < absenceDetection.length; i++) {
                    if (absenceDetection[i].name) {
                        let key = absenceDetection[i].name;
                        Object.assign(objects, { [key]: { threshold: absenceDetection[i].threshold ? absenceDetection[i].threshold.toString() : "0", number: absenceDetection[i].number ? absenceDetection[i].number.toString() : "0", condition: absenceDetection[i].condition, between_number: absenceDetection[i].between_number ? absenceDetection[i].between_number.toString() : "0", custom_duration: absenceDetection[i].custom_duration ? absenceDetection[i].custom_duration.toString() : "0", duration_condition: absenceDetection[i].durationCondition ? absenceDetection[i].durationCondition : "" } });
                    }
                }
                definition[aiTemplateType] = { objects };
            }

        } else if (aiTemplateType === "custom_object_detection") {
            if (objectDetections.length) {
                const objects = objectDetections.map(obj => {
                    obj.threshold = obj.threshold ? obj.threshold.toString() : "0";
                    return obj;
                });
                definition[aiTemplateType] = { objects };
            }
        } else {
            if (aiParamsOptions && aiParamsOptions.length) {
                for (let i = 0; i < aiParamsOptions.length; i++) {
                    let item = aiParamsOptions[i];
                    if (checked.includes(item.key)) {
                        if (item.key === "line_crossing_start") {
                            definition[aiTemplateType].line_params.start_x = (aiParamSubFields.start_x) ? parseFloat(aiParamSubFields.start_x) : 0;
                            definition[aiTemplateType].line_params.start_y = (aiParamSubFields.start_y) ? parseFloat(aiParamSubFields.start_y) : 0;
                        } else if (item.key === "line_crossing_end") {
                            definition[aiTemplateType].line_params.end_x = (aiParamSubFields.end_x) ? parseFloat(aiParamSubFields.end_x) : 0;
                            definition[aiTemplateType].line_params.end_y = (aiParamSubFields.end_y) ? parseFloat(aiParamSubFields.end_y) : 0;
                        } else if (item.key === "time" && aiTemplateType === "sack_counting") {
                            definition[aiTemplateType][item.key] = (parseInt(aiParamSubFields[item.key]) * 60);
                        } else {
                            if (item.key === 'show_person_trail' || item.key === 'enable') {
                                definition[aiTemplateType][item.key] = (aiParamSubFields[item.key] && aiParamSubFields[item.key] == 'Yes') ? true : false;
                            } else {
                                definition[aiTemplateType][item.key] = aiParamSubFields[item.key] ? aiParamSubFields[item.key] : item.defaultValue;
                            }
                        }
                    } else {
                        if (item.fieldType !== 'text') {
                            definition[aiTemplateType][item.key] = item.defaultValue;
                        }
                    }
                    if (item.key === 'thresh_conf') {
                        definition[aiTemplateType][item.key] = definition[aiTemplateType][item.key].toString();
                    }
                    if (item.isThreshold) {
                        definition[aiTemplateType][item.key] = definition[aiTemplateType][item.key].toString();
                    }
                    if (item.isNumber) {
                        definition[aiTemplateType][item.key] = parseInt(definition[aiTemplateType][item.key]);
                    }
                }
            }
        }
        postData.definition = definition;
        postData.notification_frequency = notificationFrequency ? notificationFrequency : data.notification_frequency;
        postData.provider = (providerTypes.edgeAI.includes(aiTemplateType)) ? 'edgeai' : 'uncanny';
        postData.lineCrossObjectData = lineCrossObjectData;
        postData.lineCrossImage = lineCrossBase64Image ? lineCrossBase64Image : (lineCrossObject && lineCrossObject.image) ? lineCrossObject.image : null;
        handleCreateMLSubmitClick(postData);
    }

    const handleAddObjects = (type) => {
        if (type === 'acd') {
            setAccidentDetections(oldArray => [...oldArray, { ...initAcDFields }]);
        } else if (type === 'abs_detec') {
            setAbsenceDetection(oldArray => [...oldArray, { ...initAbsDetecFields }]);
        } else if (type === "LPR") {
            setLicencePlates(oldArray => [...oldArray, { ...initLPRState }]);
        } else if (type === "custom_object_detection") {
            setObjectDetections(oldArray => [...oldArray, { ...initObjectDetecFields }]);
        } else {
            setMultiObjects(oldArray => [...oldArray, { ...initMultiObjectFields }]);
        }
    }

    const handleObjectInputChange = (value, index, field) => {
        const updatedState = [...multiObjects];
        updatedState[index][field] = value;
        setMultiObjects(updatedState);
    }

    const handleAddNewFace = () => {
        setFaceRecognition(oldArray => [...oldArray, { ...initFaceRecoState }]);
    }

    const handleFaceRecoInputChange = (value, index, field) => {
        const updatedState = [...faceRecognition];
        if (field === 'image') {
            updatedState[index][field] = value.target.value;
            const file = value.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                // updatedState[index]['files'] = file;
                // updatedState[index]['imgView'] = reader.result;
                updatedState[index]['files'] = reader.result;
            };
        } else {
            updatedState[index][field] = value;
        }
        setFaceRecognition(updatedState);
    }

    React.useEffect(() => {
        if (data) {
            setTemplateName(data.name);
            setAiTemplateType(data.model_type);
            let aiTypeDtl = null;
            for (let aikey in aiTypes) {
                if (aiTypes[aikey].id === data.model_type) {
                    aiTypeDtl = aiTypes[aikey];
                }
            }
            setAiParamOptions((aiTypeDtl && aiTypeDtl.params.length) ? aiTypeDtl.params : []);
            setPriority(data.priority ? data.priority : "");
            setNotificationFrequency(data.notification_frequency ? parseInt(data.notification_frequency) : data.notification_frequency);
            if (data.model_type === 'multi_object' && data.definition && data.definition['multi-object'] && data.definition['multi-object'].objects) {
                let MObjArr = [];
                for (const [key, value] of Object.entries(data.definition['multi-object'].objects)) {
                    MObjArr.push({ name: key.toString(), number: value.number ? value.number : 0, threshold: value.threshold ? parseFloat(value.threshold) : 0, condition: value.condition, between_number: value.between_number });
                }
                setMultiObjects(MObjArr);
            } else if (data.model_type === 'absence_detection' && data.definition && data.definition[data.model_type].objects) {
                let MObjArr = [];
                for (const [key, value] of Object.entries(data.definition[data.model_type].objects)) {
                    MObjArr.push({ name: key.toString(), number: value.number ? value.number : 0, threshold: value.threshold ? parseFloat(value.threshold) : 0, condition: value.condition, between_number: value.between_number, custom_duration: value.custom_duration ? parseInt(value.custom_duration) : 0, durationCondition: value.duration_condition ? value.duration_condition : "" });
                }
                setAbsenceDetection(MObjArr);
            } else if (data.model_type === 'accident_detection' && data.definition && data.definition[data.model_type].objects) {
                let MObjArr = [];
                for (const [key, value] of Object.entries(data.definition[data.model_type].objects)) {
                    MObjArr.push({ name: key.toString(), threshold: value.threshold ? parseFloat(value.threshold) : 0, accident_threshold: value.accident_threshold ? parseFloat(value.accident_threshold) : 0 });
                }
                setAccidentDetections(MObjArr);
            } else if (data.model_type === 'license_plate_recognition' && data.definition && data.definition[data.model_type]) {
                let plates = (data.definition[data.model_type] && data.definition[data.model_type].plates) ? data.definition[data.model_type].plates : data.definition[data.model_type];
                if (plates instanceof Array) {
                    let LPRArray = [];
                    for (const plate of plates) {
                        LPRArray.push({
                            plate_number: plate.plate_number,
                            car_description: plate.car_description,
                            list_type: plate.list_type,
                            min_character: plate?.min_character || 0,
                            max_character: plate?.max_character || 0,
                            notification: (plate.list_type && plate.list_type === "blacklist") ? true : plate.enable_notification, disable_noti_field: (plate.list_type && plate.list_type === "blacklist") ? true : false
                        });
                    }
                    setLicencePlates(LPRArray);
                }
            } else if (data.model_type === 'face_recognition' && data.definition && data.definition[data.model_type]) {
                let faceArray = [];
                if (data.definition[data.model_type] instanceof Array) {
                    for (const face of data.definition[data.model_type]) {
                        let faceKeyArr = Object.keys(face).filter(item => {
                            if (item !== "list_type" && item !== "enable_notification") {
                                return item;
                            }
                        });
                        if (faceKeyArr.length > 0) {
                            let fKey = faceKeyArr[0];
                            faceArray.push({ name: fKey, files: face.singed_url, singed_url: face.singed_url, main_url: face.main_url, image: '', list_type: face.list_type, notification: (face.list_type && face.list_type === "blacklist") ? true : face.enable_notification, disable_noti_field: (face.list_type && face.list_type === "blacklist") ? true : false });
                        }
                    }
                } else {
                    for (const [key, value] of Object.entries(data.definition[data.model_type])) {
                        faceArray.push({ name: key, files: value.singed_url, singed_url: value.singed_url, main_url: value.main_url, image: '', list_type: "whitelist", notification: false, disable_noti_field: false });
                    }
                }
                setFaceRecognition(faceArray);
                setOldFaceRecognition(data.definition[data.model_type]);
            } else if ((data.model_type === 'line_cross' || data.model_type === 'line_cross2') && data.definition && data.definition[data.model_type]) {
                if (data.definition[data.model_type].lines) {
                    let lineCrossData = [];
                    for (const [key, value] of Object.entries(data.definition[data.model_type].lines)) {
                        let tempObj = {};
                        tempObj.lineObjects = value.objects;
                        tempObj.name = key;
                        tempObj.thresh_conf = value.thresh_conf;
                        tempObj.relativeCords = { startX: value.start_x, startY: value.start_y, endX: value.end_x, endY: value.end_y };
                        lineCrossData.push(tempObj);
                    }
                    setLineCrossList(lineCrossData);
                }
            } else if (data.model_type === 'volume_analysis' && data.definition && data.definition[data.model_type]) {
                if (data.definition[data.model_type].lines) {
                    let lineCrossData = [];
                    for (const [key, value] of Object.entries(data.definition[data.model_type].lines)) {
                        let tempObj = {};
                        tempObj.name = key;
                        tempObj.relativeCords = { startX: value.start_x, startY: value.start_y, endX: value.end_x, endY: value.end_y };
                        lineCrossData.push(tempObj);
                    }
                    setLineCrossList(lineCrossData);
                }
            } else if (data.model_type === 'custom_object_detection' && data.definition && data.definition[data.model_type]) {
                if (data.definition[data.model_type].objects && data.definition[data.model_type].objects.length > 0) {
                    setObjectDetections(data.definition[data.model_type].objects);
                }
            } else if (data.model_type === 'custom') {
                setDefinationName(data.notification_frequency);
            }
            if (aiTypeDtl && aiTypeDtl.params && aiTypeDtl.params.length) {
                if (data.definition && data.definition[data.model_type]) {
                    let paramsArray = [];
                    let definitionState = {};
                    for (let i = 0; i < aiTypeDtl.params.length; i++) {
                        let item = aiTypeDtl.params[i];
                        if (item.key === 'line_crossing_start') {
                            if (data.definition[data.model_type].line_params && (data.definition[data.model_type].line_params.start_x || data.definition[data.model_type].line_params.start_y)) {
                                paramsArray.push(item.key);
                                definitionState.start_x = data.definition[data.model_type].line_params.start_x;
                                definitionState.start_y = data.definition[data.model_type].line_params.start_y;
                            }
                        } else if (item.key === 'line_crossing_end') {
                            if (data.definition[data.model_type].line_params && (data.definition[data.model_type].line_params.end_x || data.definition[data.model_type].line_params.end_x)) {
                                paramsArray.push(item.key);
                                definitionState.end_x = data.definition[data.model_type].line_params.end_x;
                                definitionState.end_y = data.definition[data.model_type].line_params.end_y;
                            }
                        } else if (item.key === 'enable' || item.key === 'show_person_trail') {
                            paramsArray.push(item.key);
                            definitionState[item.key] = (data.definition[data.model_type][item.key]) ? 'Yes' : 'No';
                        } else if (item.key === "time" && data.model_type === "sack_counting") {
                            definitionState[item.key] = parseInt(data.definition[data.model_type][item.key]) / 60;
                        } else {
                            paramsArray.push(item.key);
                            definitionState[item.key] = data.definition[data.model_type][item.key];
                        }
                    }
                    setAiParamSubFields(definitionState);
                    setChecked(paramsArray);
                }
            }
        }
    }, [data]);

    React.useEffect(() => {
        if (lcrTimer) {
            const interval = setInterval(() => {
                if (selectedAiType === 'people_counting_alert' && localStorage.getItem('lineCrossData')) {
                    let lineCrsCords = JSON.parse(localStorage.getItem('lineCrossData'));
                    onChangeAiSubFields(lineCrsCords.startX, { fieldType: 'text' }, 'start_x');
                    onChangeAiSubFields(lineCrsCords.startY, { fieldType: 'text' }, 'start_y');
                    onChangeAiSubFields(lineCrsCords.endX, { fieldType: 'text' }, 'end_x');
                    onChangeAiSubFields(lineCrsCords.endY, { fieldType: 'text' }, 'end_y');
                    updateAiSubFields(selectedAiType);
                    localStorage.removeItem('lineCrossData');
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [lcrTimer]);

    React.useEffect(() => {
        if (lineCrossObject && lineCrossObject.lines && lineCrossObject.lines.length > 0) {
            let lineCrossData = lineCrossList.map(itm => {
                let matchLine = lineCrossObject.lines.filter(line => {
                    if (itm.name == line.name) {
                        return line;
                    }
                });
                if (matchLine && matchLine.length > 0) {
                    itm.pointers = matchLine[0].pointers;
                    itm.coordinates = matchLine[0].coordinates;
                }
                return itm;
            });
            setLineCrossList(lineCrossData);
        }
    }, [lineCrossObject]);

    const updateLineCrossObjectFromStorage = (lineData) => {
        if (lineData && lineData.linedata) {
            setLineCrossList(lineData.linedata);
            setLineCrossBase64Image(lineData.line_img_url);
        }
    }

    window.addEventListener('storage', function (event) {
        if (event.key === "linedata" && event.newValue) {
            let lineData = JSON.parse(event.newValue);
            updateLineCrossObjectFromStorage(lineData);
        }
    }, false);

    const handleRemoveMultiData = (idx, type) => {
        if (type === 'multi_object') {
            const temp = [...multiObjects];
            temp.splice(idx, 1);
            setMultiObjects(temp);
        } else if (type === 'face_recognition') {
            const temp = [...faceRecognition];
            temp.splice(idx, 1);
            setFaceRecognition(temp);
        } else if (type === 'accident_detection') {
            const temp = [...accidentDetections];
            temp.splice(idx, 1);
            setAccidentDetections(temp);
        } else if (type === "abs_detec") {
            let updatedState = [...absenceDetection];
            updatedState.splice(idx, 1);
            setAbsenceDetection(updatedState);
        } else if (type === "license_plate_recognition") {
            let updatedState = [...licencePlates];
            updatedState.splice(idx, 1);
            setLicencePlates(updatedState);
        } else if (type === 'custom_object_detection') {
            const temp = [...objectDetections];
            temp.splice(idx, 1);
            setObjectDetections(temp);
        }
    }

    const handleEditLineCrossClick = () => {
        localStorage.setItem("editLineCrossData", JSON.stringify(lineCrossObject));
        if (selectedAiType === "volume_analysis") {
            const newWindow = window.open(`/volumeLineCrossImage?action=edit&id=${lineCrossObject._id}`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        } else {
            const newWindow = window.open(`/multiLineCrosingImage?action=edit&id=${lineCrossObject._id}`, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null;
        }
    }

    const handleAcdObjectInputChange = (value, index, field) => {
        const updatedState = [...accidentDetections];
        updatedState[index][field] = value;
        setAccidentDetections(updatedState);
    }

    const handleAbsDetectInputChange = (value, index, field) => {
        const updatedState = [...absenceDetection];
        updatedState[index][field] = value;
        setAbsenceDetection(updatedState);
    }

    const handleLPRChange = (value, index, field) => {
        const updatedState = [...licencePlates];
        updatedState[index][field] = value;
        setLicencePlates(updatedState);
    }

    const handleObjectDetectionChange = (value, index, field) => {
        setObjectDetections((prev) => {
            const updatedState = [...prev];
            updatedState[index][field] = value;
            return updatedState;
        });
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="ml-template-modal"
            aria-describedby="ml-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">{t('edit.label')} {t('machineLearning.label')} {t('template.label')}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        ...paddingGenerator('pb-30')
                    }}>
                    <Grid
                        style={{
                            ...marginGenerator('mt-10')
                        }}>
                        {dataLoading ?
                            <div style={{ textAlign: "center" }}>
                                <CircularProgress size={25} />
                            </div>
                            :
                            <Grid
                                style={{
                                    ...marginGenerator('mt-8')
                                }}>
                                <Grid>
                                    <Input
                                        value={templateName}
                                        type={'text'}
                                        placeholder={`${t('template.label')} ${t('name.label')}*`}
                                        style={{ width: '100%' }}
                                        color={'secondary'}
                                        onChange={evt => setTemplateName(evt.target.value)}
                                    />
                                </Grid>
                                <Grid style={marginGenerator('mt-24')}>
                                    <Button onClick={handleSelectAIType} variant="contained" color="primary">
                                        {selectedAiType ? 'Change AI Type' : 'Select AI Type'}
                                    </Button>
                                </Grid>
                                {aiParamsOptions && aiParamsOptions.length > 0 &&
                                    <List className={classes.aiParamList}>
                                        <ListSubheader>{aiTypeName}</ListSubheader>
                                        {aiTemplateType && aiTemplateType === "custom" &&
                                            <Grid style={marginGenerator('mt-10')}>
                                                <Input
                                                    value={definationName}
                                                    type={'text'}
                                                    placeholder={`${t('defination.label')} ${t('name.label')}*`}
                                                    style={{ width: '100%' }}
                                                    color={'secondary'}
                                                    onChange={evt => setDefinationName(evt.target.value)}
                                                />
                                            </Grid>
                                        }
                                        {(aiTemplateType === 'people_counting_alert' || aiTemplateType === 'line_cross' || aiTemplateType === 'line_cross2') &&
                                            <ListItem role={undefined} dense button>
                                                <a style={{ display: "table-cell" }} href={(aiTemplateType === 'line_cross' || aiTemplateType === 'line_cross2') ? '/multiLineCrosingImage' : '/lineCrosingImage'} target="_blank" rel="noopener noreferrer">
                                                    <Button variant={'outlined'} color="primary" >
                                                        Get Line Crossing Axis
                                                    </Button>
                                                </a>
                                            </ListItem>}
                                        {aiParamsOptions.map((item, index) => {
                                            const labelId = `checkbox-list-label-${index}`;
                                            return (
                                                <ListItem key={index} role={undefined} dense button onClick={handleCheckAIParam(item.key)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(item.key) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={item.name} />
                                                    <ListItemSecondaryAction style={item.fieldType == 'slider' ? { minWidth: '400px' } : null}>
                                                        {aiTemplateSubElem && aiTemplateSubElem[item.key]}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                }

                                {selectedAiType && (selectedAiType === 'line_cross' || selectedAiType === 'line_cross2') ?
                                    <List className={classes.aiParamList}>
                                        <ListSubheader>{aiTypeName}</ListSubheader>
                                        <ListItem role={undefined} dense button>
                                            {!lineCrossDataLoading ?
                                                lineCrossObject ?
                                                    <Button onClick={handleEditLineCrossClick} variant={'outlined'} color="primary" >
                                                        Edit Line Crossing Axis
                                                    </Button>
                                                    :
                                                    <a style={{ display: "table-cell" }} href={'/multiLineCrosingImage'} target="_blank" rel="noopener noreferrer">
                                                        <Button variant={'outlined'} color="primary" >
                                                            Get Line Crossing Axis
                                                        </Button>
                                                    </a>
                                                :
                                                <Button disabled={true} variant={'outlined'} color="primary" >
                                                    <CircularProgress size={14} />
                                                </Button>
                                            }
                                        </ListItem>
                                        {lineCrossList.length > 0 && lineCrossList.map((item, index) => {
                                            return (
                                                <ListItem key={index} role={undefined} dense button>
                                                    <Grid container spacing={3} style={{ ...marginGenerator('mb-16'), margin: 'auto' }}>
                                                        <Grid item xs={2} style={{ margin: 'auto' }}>
                                                            <Typography variant="subtitle1" color={"primary"} style={{ fontWeight: "bold" }}>{item.name ? item.name : `Line ${index + 1}`} : </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ margin: 'auto' }}>
                                                            <Typography variant="subtitle1">
                                                                <b>Objects :</b> {item.lineObjects.join(', ')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ margin: 'auto' }}>
                                                            <Typography variant="subtitle1">
                                                                <b>Threshold :</b> {item.thresh_conf}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle1" style={{ marginBottom: '6px' }}>
                                                                <b>Start X : </b> {item.relativeCords.startX}
                                                                <b>&nbsp;, Start Y : </b> {item.relativeCords.startY}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <b>End X :</b> {item.relativeCords.endX}
                                                                <b>&nbsp;, End Y :</b> {item.relativeCords.endY}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                    : null}

                                {selectedAiType && selectedAiType === "volume_analysis" ?
                                    <List className={classes.aiParamList}>
                                        <ListSubheader>{aiTypeName}</ListSubheader>
                                        <ListItem role={undefined} dense button>
                                            {!lineCrossDataLoading ?
                                                lineCrossObject ?
                                                    <Button onClick={handleEditLineCrossClick} variant={'outlined'} color="primary" >
                                                        Edit Line Crossing Axis
                                                    </Button>
                                                    :
                                                    <a style={{ display: "table-cell" }} href={'/volumeLineCrossImage'} target="_blank" rel="noopener noreferrer">
                                                        <Button variant={'outlined'} color="primary" >
                                                            Get Line Crossing Axis
                                                        </Button>
                                                    </a>
                                                :
                                                <Button disabled={true} variant={'outlined'} color="primary" >
                                                    <CircularProgress size={14} />
                                                </Button>
                                            }
                                        </ListItem>
                                        {lineCrossList.length > 0 && lineCrossList.map((item, index) => {
                                            return (
                                                <ListItem key={index} role={undefined} dense button>
                                                    <Grid container spacing={3} style={{ ...marginGenerator('mb-16'), margin: 'auto' }}>
                                                        <Grid item xs={3} style={{ margin: 'auto' }}>
                                                            <Typography variant="subtitle1" color={"primary"} style={{ fontWeight: "bold" }}>{item.name ? item.name : `Line ${index + 1}`} : </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="subtitle1" style={{ marginBottom: '6px' }}>
                                                                <b>Start X : </b> {item.relativeCords.startX}
                                                                <b>&nbsp;, Start Y : </b> {item.relativeCords.startY}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <b>End X :</b> {item.relativeCords.endX}
                                                                <b>&nbsp;, End Y :</b> {item.relativeCords.endY}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                    : null}

                                {selectedAiType && selectedAiType === 'multi_object' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={evt => { handleAddObjects("mo") }} variant="outlined" color="primary">
                                            {t('addObject.label')}
                                        </Button>
                                        {multiObjects && multiObjects.length > 0 && multiObjects.map((object, index) => {
                                            return (
                                                <Grid key={index} container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.name}
                                                            label={`${t('object.label')} ${t('name.label')}`}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0 }}
                                                            handleChange={evt => {
                                                                handleObjectInputChange(evt.target.value, index, 'name');
                                                            }}
                                                            options={(multiObjectOpt && multiObjectOpt.length) ? multiObjectOpt : MULTI_OBJECTS_LIST}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography id="discrete-slider" gutterBottom>
                                                            {t('threshold.label')}
                                                        </Typography>
                                                        <Slider
                                                            value={object.threshold ? object.threshold : 0}
                                                            getAriaValueText={valuetext}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            step={0.1}
                                                            marks
                                                            min={0}
                                                            max={1}
                                                            onChange={(evt, number) => {
                                                                handleObjectInputChange(number, index, 'threshold');
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <OutlinedSelect
                                                            val={object.number}
                                                            label={t('number.label')}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            handleChange={evt => {
                                                                handleObjectInputChange(evt.target.value, index, 'number');
                                                            }}
                                                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.condition}
                                                            label={t('condition.label')}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            handleChange={evt => {
                                                                handleObjectInputChange(evt.target.value, index, 'condition');
                                                            }}
                                                            options={multiObjectConditions}
                                                        />
                                                    </Grid>
                                                    {object.condition === "in_between" &&
                                                        <Grid item xs={2}>
                                                            <Input
                                                                value={object.between_number}
                                                                type={'number'}
                                                                placeholder={`${t('number.label')}*`}
                                                                style={{ width: '100%', minWidth: 120 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    handleObjectInputChange(evt.target.value, index, 'between_number');
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={1} style={{ margin: 'auto' }}>
                                                        <Fab onClick={evt => { handleRemoveMultiData(index, 'multi_object') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                            <DeleteForeverIcon />
                                                        </Fab>
                                                    </Grid>
                                                    <div style={{ width: "100%", borderBottom: "2px solid #ebebeb", paddingTop: 18, marginLeft: 12 }}></div>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                    : null}

                                {selectedAiType && selectedAiType === 'accident_detection' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={evt => { handleAddObjects("acd") }} variant="outlined" color="primary">
                                            {t('addObject.label')}
                                        </Button>
                                        {accidentDetections && accidentDetections.length > 0 && accidentDetections.map((object, index) => {
                                            return (
                                                <Grid key={index} container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.name}
                                                            label={`${t('object.label')} ${t('name.label')}`}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0 }}
                                                            handleChange={evt => {
                                                                handleAcdObjectInputChange(evt.target.value, index, 'name');
                                                            }}
                                                            options={(accidentDetectionObj && accidentDetectionObj.length) ? accidentDetectionObj : ACD_OBJECTS_LIST}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography id="discrete-slider" gutterBottom>
                                                            {t('threshold.label')}
                                                        </Typography>
                                                        <Slider
                                                            value={object.threshold ? object.threshold : 0}
                                                            getAriaValueText={valuetext}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            step={0.1}
                                                            marks
                                                            min={0}
                                                            max={1}
                                                            onChange={(evt, number) => {
                                                                handleAcdObjectInputChange(number, index, 'threshold');
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography id="discrete-slider" gutterBottom>
                                                            {t('accidentThreshold.label')}
                                                        </Typography>
                                                        <Slider
                                                            value={object.accident_threshold ? object.accident_threshold : 0}
                                                            getAriaValueText={valuetext}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            step={0.1}
                                                            marks
                                                            min={0}
                                                            max={1}
                                                            onChange={(evt, number) => {
                                                                handleAcdObjectInputChange(number, index, 'accident_threshold');
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{ margin: 'auto' }}>
                                                        <Fab onClick={evt => { handleRemoveMultiData(index, 'accident_detection') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                            <DeleteForeverIcon />
                                                        </Fab>
                                                    </Grid>
                                                    <div style={{ width: "100%", borderBottom: "2px solid #ebebeb", paddingTop: 18, marginLeft: 12 }}></div>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                    : null}

                                {selectedAiType && selectedAiType === 'face_recognition' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={handleAddNewFace} variant="outlined" color="primary">
                                            {t('addNewFace.label')}
                                        </Button>
                                        {faceRecognition.length > 0 &&
                                            faceRecognition.map((object, index) => {
                                                return (
                                                    <Grid key={index} className={classes.faceRcContain} container spacing={1} style={{ ...marginGenerator('mt-10') }}>
                                                        <Grid item xs={3}>
                                                            <OutlinedSelect
                                                                val={object.list_type}
                                                                label={t('listType.label')}
                                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                                styleOverrides={{ width: '100%', marginRight: 0, minWidth: 150 }}
                                                                handleChange={evt => {
                                                                    let newFRState = [...faceRecognition];
                                                                    newFRState[index].list_type = evt.target.value;
                                                                    if (evt.target.value && evt.target.value === "blacklist") {
                                                                        newFRState[index].notification = true;
                                                                        newFRState[index].disable_noti_field = true;
                                                                    } else {
                                                                        newFRState[index].disable_noti_field = false;
                                                                    }
                                                                    setFaceRecognition(newFRState);
                                                                }}
                                                                options={["blacklist", "whitelist"]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Input
                                                                value={object.name}
                                                                type={'text'}
                                                                placeholder={`${t('name.label')}*`}
                                                                fullWidth={true}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    let value = removeSpacialCharecter(evt.target.value);
                                                                    handleFaceRecoInputChange(value.replace(/\s+/g, '_'), index, 'name');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                value={object.image}
                                                                type={'file'}
                                                                placeholder={`${t('faceImg.label')}*`}
                                                                fullWidth={true}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    const file = evt.target.files[0];
                                                                    if (file) {
                                                                        if (/\.(jpe?g)$/i.test(file.name) === false) {
                                                                            Message.error("File must be JPEG or JPG format!");
                                                                            return null;
                                                                        }
                                                                        let _size = file.size;
                                                                        if (_size > MAX_FILE_SIZE) {
                                                                            Message.error("File size must be less than 3MB!");
                                                                            return null;
                                                                        }
                                                                    }
                                                                    handleFaceRecoInputChange(evt, index, 'image');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={2}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={object.notification}
                                                                        onChange={evt => {
                                                                            handleFaceRecoInputChange(evt.target.checked, index, 'notification');
                                                                        }}
                                                                        disabled={object.disable_noti_field}
                                                                        title={t("enableNotif.label")}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t("notification.label")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Avatar style={{ marginRight: 5, marginLeft: 10 }} alt={object.name} src={object.files} className={classes.faceLarge} />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Fab onClick={evt => { handleRemoveMultiData(index, 'face_recognition') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                                <DeleteForeverIcon />
                                                            </Fab>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                    </>
                                    : null}

                                {selectedAiType && selectedAiType === 'license_plate_recognition' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={evt => { handleAddObjects("LPR") }} variant="outlined" color="primary">
                                            {t('addNewLPR.label')}
                                        </Button>
                                        {licencePlates.length > 0 &&
                                            licencePlates.map((object, index) => {
                                                return (
                                                    <Grid key={index} container spacing={3} style={{ ...marginGenerator('mt-10') }}>
                                                        <Grid item xs={3}>
                                                            <OutlinedSelect
                                                                val={object.list_type}
                                                                label={t('listType.label')}
                                                                selectStyle={{ ...paddingGenerator('p-8') }}
                                                                styleOverrides={{ width: '100%', minWidth: 160 }}
                                                                handleChange={evt => {
                                                                    let newFRState = [...licencePlates];
                                                                    newFRState[index].list_type = evt.target.value;
                                                                    if (evt.target.value && evt.target.value === "blacklist") {
                                                                        newFRState[index].notification = true;
                                                                        newFRState[index].disable_noti_field = true;
                                                                    } else {
                                                                        newFRState[index].disable_noti_field = false;
                                                                    }
                                                                    console.log("newFRState: ", newFRState);
                                                                    setLicencePlates(newFRState);
                                                                }}
                                                                options={["blacklist", "whitelist", "unknown"]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                value={object.plate_number}
                                                                type={'text'}
                                                                placeholder={`${t('plateNumber.label')}*`}
                                                                style={{ width: '100%', minWidth: 160 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    let pltNum = removeSpacialCharecter(evt.target.value);
                                                                    pltNum = pltNum.replace(/\s+/g, '');
                                                                    handleLPRChange(pltNum.toUpperCase(), index, 'plate_number');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                value={object.min_character}
                                                                type={'number'}
                                                                placeholder={`Min Character`}
                                                                style={{ width: '100%', minWidth: 120 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    handleLPRChange(evt.target.value, index, 'min_character');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                value={object.max_character}
                                                                type={'number'}
                                                                placeholder={`Max Character`}
                                                                style={{ width: '100%', minWidth: 120 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    handleLPRChange(evt.target.value, index, 'max_character');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                value={object.car_description}
                                                                type={'text'}
                                                                multiline={true}
                                                                placeholder={`${t('carDescription.label')}*`}
                                                                style={{ width: '100%', minWidth: 160 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    handleLPRChange(evt.target.value, index, 'car_description');
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={2}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={object.notification}
                                                                        onChange={evt => {
                                                                            handleLPRChange(evt.target.checked, index, 'notification');
                                                                        }}
                                                                        disabled={object.disable_noti_field}
                                                                        title={t("enableNotif.label")}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t("notification.label")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Fab onClick={evt => { handleRemoveMultiData(index, 'license_plate_recognition') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                                <DeleteForeverIcon />
                                                            </Fab>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                    </>
                                    : null}

                                {selectedAiType && selectedAiType === 'absence_detection' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={evt => { handleAddObjects("abs_detec") }} variant="outlined" color="primary">
                                            {t('addObject.label')}
                                        </Button>
                                        {absenceDetection && absenceDetection.length > 0 && absenceDetection.map((object, index) => {
                                            return (
                                                <Grid key={index} container spacing={3} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.name}
                                                            label={`${t('object.label')} ${t('name.label')}`}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0 }}
                                                            handleChange={evt => {
                                                                handleAbsDetectInputChange(evt.target.value, index, 'name');
                                                            }}
                                                            options={(multiObjectOpt && multiObjectOpt.length) ? multiObjectOpt : MULTI_OBJECTS_LIST}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography id="discrete-slider" gutterBottom>
                                                            {t('threshold.label')}
                                                        </Typography>
                                                        <Slider
                                                            defaultValue={0}
                                                            value={object.threshold}
                                                            getAriaValueText={valuetext}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            step={0.1}
                                                            marks
                                                            min={0}
                                                            max={1}
                                                            onChange={(evt, number) => {
                                                                handleAbsDetectInputChange(number, index, 'threshold');
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <OutlinedSelect
                                                            val={object.number}
                                                            label={t('number.label')}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            handleChange={evt => {
                                                                handleAbsDetectInputChange(evt.target.value, index, 'number');
                                                            }}
                                                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.condition}
                                                            label={t('condition.label')}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            handleChange={evt => {
                                                                handleAbsDetectInputChange(evt.target.value, index, 'condition');
                                                            }}
                                                            options={multiObjectConditions}
                                                        />
                                                    </Grid>
                                                    {object.condition === "in_between" &&
                                                        <Grid item xs={2}>
                                                            <Input
                                                                value={object.between_number}
                                                                type={'number'}
                                                                placeholder={`${t('number.label')}*`}
                                                                style={{ width: '100%', minWidth: 120 }}
                                                                color={'secondary'}
                                                                onChange={evt => {
                                                                    handleAbsDetectInputChange(evt.target.value, index, 'between_number');
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={3}>
                                                        <Input
                                                            value={object.custom_duration}
                                                            type={'number'}
                                                            placeholder={`${t('durationMin.label')}*`}
                                                            style={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            color={'secondary'}
                                                            onChange={evt => {
                                                                if (evt.target.value && IsNumeric(evt.target.value)) {
                                                                    if (parseInt(evt.target.value) > 120) {
                                                                        Message.error("Number must be less then 120!");
                                                                        return false;
                                                                    }
                                                                    handleAbsDetectInputChange(evt.target.value, index, 'custom_duration');
                                                                } else {
                                                                    handleAbsDetectInputChange(evt.target.value, index, 'custom_duration');
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.durationCondition}
                                                            label={t('durationCondition.label')}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0, minWidth: 100 }}
                                                            handleChange={evt => {
                                                                handleAbsDetectInputChange(evt.target.value, index, 'durationCondition');
                                                            }}
                                                            options={[{ id: "greater", name: "Greater then" }, { id: "lesser", name: "Lesser then" }]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Fab onClick={evt => { handleRemoveMultiData(index, 'abs_detec') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                            <DeleteForeverIcon />
                                                        </Fab>
                                                    </Grid>
                                                    <div style={{ width: "100%", borderBottom: "2px solid #ebebeb", paddingTop: 18, marginLeft: 12 }}></div>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                    : null}

                                {selectedAiType && selectedAiType === 'custom_object_detection' ?
                                    <>
                                        <List className={classes.aiParamList}>
                                            <ListSubheader style={{ padding: '0px' }}>{aiTypeName}</ListSubheader>
                                        </List>
                                        <Button onClick={evt => { handleAddObjects("custom_object_detection") }} variant="outlined" color="primary">
                                            {t('addObject.label')}
                                        </Button>
                                        {objectDetections && objectDetections.length > 0 && objectDetections.map((object, index) => {
                                            return (
                                                <Grid key={index} container spacing={2} style={{ ...marginGenerator('mt-10'), paddingBottom: 10 }}>
                                                    <Grid item xs={3}>
                                                        {['absent', "present"].map((item) => (
                                                            <Radio
                                                                checked={item === object.type}
                                                                key={item}
                                                                classes={{ root: classes.objDetecType }}
                                                                style={{
                                                                    marginRight: (index === 0) ? 10 : 0,
                                                                    ...(item === object.type
                                                                        ? { backgroundColor: colors.secondary.main }
                                                                        : '')
                                                                }}
                                                                onChange={() => handleObjectDetectionChange(item, index, 'type')}
                                                                value={item}
                                                                name="site-select"
                                                                checkedIcon={
                                                                    <div className={classes.icon}>
                                                                        <Typography
                                                                            variant={'subtitle1'}
                                                                            style={{ color: colors.common.white }}>
                                                                            {item}
                                                                        </Typography>
                                                                    </div>
                                                                }
                                                                icon={
                                                                    <div className={classes.icon}>
                                                                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                                            {item}
                                                                        </Typography>
                                                                    </div>
                                                                }
                                                            />
                                                        ))}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <OutlinedSelect
                                                            val={object.base_class}
                                                            label={"Base Object"}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ minWidth: '120px', marginRight: 0 }}
                                                            handleChange={evt => {
                                                                handleObjectDetectionChange(evt.target.value, index, 'base_class');
                                                            }}
                                                            options={(multiObjectOpt && multiObjectOpt.length) ? multiObjectOpt : MULTI_OBJECTS_LIST}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <OutlinedSelect
                                                            val={object.class}
                                                            label={`${t('object.label')} ${t('name.label')}`}
                                                            selectStyle={{ ...paddingGenerator('p-8') }}
                                                            styleOverrides={{ width: '100%', marginRight: 0 }}
                                                            handleChange={evt => {
                                                                handleObjectDetectionChange(evt.target.value, index, 'class');
                                                            }}
                                                            options={(customDetectionObjects && customDetectionObjects.length > 0) ? customDetectionObjects : OBJECTS_DETECTION_LIST}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography id="discrete-slider" gutterBottom>
                                                            {t('threshold.label')}
                                                        </Typography>
                                                        <Slider
                                                            defaultValue={0}
                                                            getAriaValueText={valuetext}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            step={0.1}
                                                            marks
                                                            min={0}
                                                            value={object.threshold}
                                                            max={1}
                                                            onChange={(evt, number) => handleObjectDetectionChange(number, index, 'threshold')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{ margin: 'auto' }}>
                                                        <Fab onClick={evt => { handleRemoveMultiData(index, 'custom_object_detection') }} size="small" color="secondary" aria-label="remove" title="Remove Object" className={classes.margin}>
                                                            <DeleteForeverIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                    : null}

                                {selectedAiType && providerTypes.edgeAI.includes(selectedAiType) && <Grid style={marginGenerator('mt-24')}>
                                    <OutlinedSelect
                                        val={notificationFrequency}
                                        label={`${t('notificationFreq.label')} *`}
                                        handleChange={evt => {
                                            setNotificationFrequency(evt.target.value);
                                        }}
                                        selectStyle={{ ...paddingGenerator('p-8') }}
                                        styleOverrides={{ width: '100%', marginRight: 0 }}
                                        options={(frequencies && frequencies.length > 0) ? frequencies : FREQUENCY_OPTIONS}
                                    />
                                </Grid>
                                }

                                <Grid style={{
                                    ...marginGenerator('mt-34')
                                }}>
                                    <Typography variant={'subtitle2'}>{`${t('priority.label')}*`}</Typography>
                                    {['P1', 'P2', "P3"].map((item, index) => (
                                        <Radio
                                            checked={item === priority}
                                            key={index}
                                            classes={{ root: classes.root }}
                                            style={{
                                                ...marginGenerator('mr-25'),
                                                ...(item === priority
                                                    ? { backgroundColor: colors.secondary.main }
                                                    : '')
                                            }}
                                            onChange={() => setPriority(item)}
                                            value={item}
                                            name="site-select"
                                            checkedIcon={
                                                <div className={classes.icon}>
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        style={{ color: colors.common.white }}>
                                                        {item}
                                                    </Typography>
                                                </div>
                                            }
                                            icon={
                                                <div className={classes.icon}>
                                                    <Typography variant={'subtitle1'} color={'textPrimary'}>
                                                        {item}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setTemplateName('');
                            setPriority('');
                            setAiTemplateType('');
                            setAITemplateSubElem(null);
                            setAiParamSubFields({});
                            setAiParamOptions([]);
                            setFaceRecognition([{ ...initFaceRecoState }]);
                            setOldFaceRecognition(null);
                            setLineCrossList([]);
                            setMultiObjects([]);
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={() => {
                            if (!templateName || !aiTemplateType || !priority) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }

                            let checkValid = true;
                            if (aiTemplateType === 'face_recognition') {
                                if (faceRecognition.length > 0) {
                                    for (let i = 0; i < faceRecognition.length; i++) {
                                        if (!faceRecognition[i].name || !faceRecognition[i].files || !faceRecognition[i].list_type) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                }
                            } else if (aiTemplateType === 'multi_object') {
                                if (multiObjects.length > 0) {
                                    for (let i = 0; i < multiObjects.length; i++) {
                                        if (!multiObjects[i].name || !multiObjects[i].number || !multiObjects[i].threshold || !multiObjects[i].condition) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                } else {
                                    checkValid = false;
                                }
                            } else if (aiTemplateType === 'line_cross' || aiTemplateType === 'line_cross2' || aiTemplateType === 'volume_analysis') {
                            } else if (aiTemplateType === 'accident_detection') {
                                if (accidentDetections.length > 0) {
                                    for (let i = 0; i < accidentDetections.length; i++) {
                                        if (!accidentDetections[i].name || !accidentDetections[i].threshold || !accidentDetections[i].accident_threshold) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                } else {
                                    checkValid = false;
                                }
                            } else if (aiTemplateType === "absence_detection") {
                                if (absenceDetection.length > 0) {
                                    for (let i = 0; i < absenceDetection.length; i++) {
                                        if (!absenceDetection[i].name || !absenceDetection[i].number || !absenceDetection[i].threshold) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                } else {
                                    checkValid = false;
                                }
                            } else if (aiTemplateType === "license_plate_recognition") {
                                if (licencePlates.length > 0) {
                                    for (let i = 0; i < licencePlates.length; i++) {
                                        if (!licencePlates[i].list_type) {
                                            checkValid = false;
                                            break;
                                        }
                                        if (licencePlates[i].list_type !== "unknown" && !licencePlates[i].plate_number) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                } else {
                                    checkValid = false;
                                }
                            } else if (aiTemplateType === "custom_object_detection") {
                                if (objectDetections.length > 0) {
                                    for (let i = 0; i < objectDetections.length; i++) {
                                        if (!objectDetections[i].type || !objectDetections[i].class) {
                                            checkValid = false;
                                            break;
                                        }
                                    }
                                } else {
                                    checkValid = false;
                                }
                            } else {
                                if (!aiParamSubFields || !checked.length) {
                                    checkValid = false;
                                }
                            }

                            if (!checkValid) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            return onCreateMLTemplateSubmit();
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

UpdateMLTemplateModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateMLSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(UpdateMLTemplateModal);
