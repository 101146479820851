import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { marginGenerator, paddingGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../components/Message';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button variant="contained" onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('verify.label')}
        </Button>
    );
}

function VerifyEmailModal({
    t,
    open,
    handleVerifyEmailClick,
    handleClose,
    handleResendOtp,
    loading,
    type,
    verifiedEmail
}) {
    
    const [otp, setOtp] = useState('');
    const [timerCount, setTimerCount] = useState(0);

    React.useEffect(() => {
        function progress() {
            setTimerCount((prevCompleted) => (prevCompleted < 100 ? prevCompleted + 5 : prevCompleted));
        }
        const timer = setInterval(progress, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);
        
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="verify-email-modal"
            aria-describedby="verify-email-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}
            disableBackdropClick
            >
            <DialogContent>
                <Grid
                style={{
                    ...paddingGenerator('pb-30')
                }}>
                    <Grid style={{ ...marginGenerator('mt-10') }}>
                        <Typography variant="h1" color={'textSecondary'}>
                        {t('verification.label')}...
                        </Typography>
                    </Grid>
                    <Grid
                        style={{
                        ...marginGenerator('mt-10')
                        }}>
                        <Typography variant="body1" color={'textSecondary'}
                        style={{lineHeight:"24px"}}
                        >
                        { type && type === 'reset_password' ?
                            `${t("forgotPassOtpMsg1.label")} ${verifiedEmail} ${t("forgotPassOtpMsg2.label")}`
                        :
                            t('otpMsg.label')
                        }
                        </Typography>
                    </Grid>
                    <Grid
                        style={{
                        ...marginGenerator('mt-10')
                        }}>
                        <Input
                            value={otp}
                            type={'number'}
                            style={{ width: '100%', ...marginGenerator('mt-24') }} 
                            color={'secondary'}
                            inputProps={{max:'6'}}
                            placeholder={`${t('enterOtp.label')} *`}
                            onChange={evt => {
                                setOtp(evt.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        style={{
                        ...marginGenerator('mt-36'),
                        position:'relative'
                        }}>
                        <Button
                        disabled={timerCount<100}
                        onClick={() => {
                            handleResendOtp();
                            setTimerCount(0);
                        }}
                        color="secondary"
                        variant={'contained'}
                        >
                            {t('resendOtp.label')}
                        </Button>
                        {timerCount < 100 && open && 
                            <CircularProgress style={{position:'absolute', left:'120px'}} variant="static" value={timerCount} />
                         }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setOtp('');
                            handleClose();
                        }}
                    >
                    {t('cancel.label')}
                    </Button>
                    <ButtonComponent t={t} onClick={()=> {
                         if(!otp){
                            Message.error('Please enter OTP');
                            return null;
                        }
                        handleVerifyEmailClick({otp:otp});
                     }} loading={loading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

VerifyEmailModal.propTypes = {

  handleClose: PropTypes.func.isRequired,
  handleVerifyEmailClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleResendOtp: PropTypes.func
};

export default withTranslation()(VerifyEmailModal);
