import React from 'react'
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Statcard from '../../../../../components/Statcard';
import DashbordWordCollection from './DashbordWordCollection';
import OcrDashBoardMapAndDetails from './OcrDashBoardMapAndDetails';
import { CircularProgress, IconButton, Input, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const OcrDashBoardPage = ({
    t,
    dashboardPanelData,
    ocrPlaceData,
    ocrProjectData,
    ocrFolderData,
    loadingOcrPlace,
    loadingOcrProject,
    loadingOcrFolder,
    handleFilterdataForOptions,
    ocrDashboardMapLoading,
    ocrDashboardMapData,
    handleFilterForDashboardMap,
    handleChangeGlobalSearch,
    documentSearchValue,
    globalDocSearchList,
    handleGlobalDocItemClick,
    globalDocSearchLoading
}) => {
    const { number_of_folders, number_of_places, number_of_projects } = !ocrDashboardMapLoading && get(ocrDashboardMapData, 'dasboardAllData', {});

    return (
        <>
            <Grid container style={{ marginTop: 25 }}>
                <Grid item xs={12}>
                    <Input
                        style={{ width: '100%' }}
                        id="outlined-basic"
                        placeholder="Search Documents"
                        variant="outlined"
                        value={documentSearchValue}
                        onKeyDown={handleChangeGlobalSearch}
                        onChange={handleChangeGlobalSearch}
                        disabled={globalDocSearchLoading}
                        endAdornment={
                            <InputAdornment position="end">
                                {!globalDocSearchLoading ? 
                                <IconButton onClick={evt => handleChangeGlobalSearch(null, 'submit')} aria-label="toggle password visibility">
                                    <Search />
                                </IconButton> : <CircularProgress size={20} />}
                            </InputAdornment>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {(globalDocSearchList && globalDocSearchList.length > 0) &&
                            <List component="nav" style={{ marginTop: 10, border: '1px solid #ebebeb' }} aria-label="secondary mailbox folder">
                                {globalDocSearchList.map(itm => (
                                    <ListItem
                                        button
                                        onClick={(event) => handleGlobalDocItemClick(itm)}
                                    >
                                        <ListItemText primary={itm.name} />
                                    </ListItem>
                                ))}
                            </List>
                    }
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10, marginBottom: 20 }}>
                <OcrDashBoardMapAndDetails
                    t={t}
                    ocrPlaceData={ocrPlaceData}
                    ocrProjectData={ocrProjectData}
                    ocrFolderData={ocrFolderData}
                    loadingOcrPlace={loadingOcrPlace}
                    loadingOcrProject={loadingOcrProject}
                    loadingOcrFolder={loadingOcrFolder}
                    handleFilterdataForOptions={handleFilterdataForOptions}
                    ocrDashboardMapLoading={ocrDashboardMapLoading}
                    ocrDashboardMapData={ocrDashboardMapData}
                    handleFilterForDashboardMap={handleFilterForDashboardMap}
                />
            </Grid>
            <Grid container style={{ marginTop: 30, marginBottom: 20 }}>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Location`}
                        statValue={number_of_places}
                        customStyles={{ width: 180, padding: "8px 16px" }}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Project`}
                        statValue={number_of_projects}
                        customStyles={{ width: 180 }}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Folders`}
                        statValue={number_of_folders}
                        customStyles={{ width: 180 }}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Docs`}
                        statValue={dashboardPanelData.number_of_doc}
                        customStyles={{ width: 180, padding: "8px 16px" }}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Rules`}
                        statValue={dashboardPanelData.number_of_rules}
                        customStyles={{ width: 180 }}
                    />
                </Grid>
                <Grid item xs="2">
                    <Statcard
                        statLabel={`${t("numberOf.label")} Labels`}
                        statValue={dashboardPanelData.label_count}
                        customStyles={{ width: 180 }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs="12">
                    <DashbordWordCollection t={t} words={dashboardPanelData && dashboardPanelData.word_map} />
                </Grid>
            </Grid>
        </>
    )
}

export default OcrDashBoardPage
