import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { fade,makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContain: {
      width: "100%"
  }
});

const useStyles = makeStyles(theme => ({
  testCon: {marginRight: '6px', marginLeft: '6px'},
  chartTitle: {marginTop: '10px'},
  paging: {
      float: "right",
      marginBottom: 30,
      marginTop: 20
  },
  mapContainer: {
      height: '352px',
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2)
  },
  inputBase: {
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 15,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
      <Button 
      color="secondary"
      variant={'contained'}
      onClick={onClick} 
      disabled={loading||disabledValue}
      >
          {loading && <CircularProgress size={14} />}
          {!loading && t('submit.label')}
      </Button>
  );
}

const OcrConfigurationEditProject = ({
  t,
  open,
  onClose,
  data,
  editProjectLoading,
  handleEditSubmitOfProject
}) => {
  const classes = useStyles();
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    if(data){
      setProjectName(data.name);
    }
  }, [data]);
  return (
    <Dialog 
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
             Edit Folder
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContain}>
        <Grid container spacing={2} style={{ alignItems: 'center' , marginBottom: '5px'  }}>
            <Grid item xs={4}>
                <TextField
                  value={projectName}
                  id="outlined-search" 
                  label="Folder Name" 
                  type="search"
                  style={{ width: '100%'}}
                  onChange={(e) => setProjectName(e.target.value)}
                />
            </Grid>
            <Grid item xs={8}/>
        </Grid>    
        </DialogContent>
        <DialogActions>
            <Grid container justify={'space-between'}>
                <Button 
                    variant={'outlined'}
                    color="primary"
                    onClick={onClose}
                >
                    {t('cancel.label')}
                </Button>
                <ButtonComponent 
                  t={t}
                  loading={editProjectLoading}
                  onClick={()=> 
                    handleEditSubmitOfProject({
                      projectName,
                      id:data.id
                    })
                  }
                />
            </Grid>          
        </DialogActions>
    </Dialog>
  )
}

export default OcrConfigurationEditProject