import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { marginGenerator } from '../../../../../../../theme/utils';
import SalesMapWithHeapMap from './SalesMapWithHeapMap';
import { convertLocalToUTCDate } from '../../../../../../../utils';
import ChartContainer from '../../ChartContainer';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden'
  },
  dateInput: {
    width: 250,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
    position: 'absolute',
    width: '100%',
    height: 352,
    backgroundColor: '#9fa0a26e',
    top: 0,
    textAlign: 'center',
    paddingTop: 130,
    zIndex: 999
  }
}));

const initMapFilter = {
  time: 'Today',
  startDate: null,
  endDate: null,
  distributorName: null
};

const SalesTrackerDashBoardMapAndDetails = ({
  t,
  loadingSalesDashboardMap,
  distributorsData,
  locationsForSalesDashBoard,
  handleFilterForSalesDashboardMap,
  getSalesDashBoardMapDetails
}) => {
  const classes = useStyles();

  const [distributorsList, setDistributorsList] = useState([]);

  const [salesDashboardMapState, setSalesDashboardMapState] = useState({
    ...initMapFilter
  });

  useEffect(() => {
    if (distributorsData) {
      const outputArray =
        distributorsData &&
        distributorsData?.map((item, index) => ({
          name: item.distributor_name,
          id: index + 1
        }));
      setDistributorsList(outputArray);
    }
  }, [distributorsData]);

  const handleClearFilter = async () => {
    setSalesDashboardMapState({ ...initMapFilter });
    // Do a get all locations api call here
    getSalesDashBoardMapDetails({
      variables: {
        start_date: '',
        end_date: '',
        distributor: ''
      }
    });
  };

  const salesDasboardMapFilter = [
    {
      label: t('startDate.label'),
      handleChange: newDate => {
        setSalesDashboardMapState({
          ...salesDashboardMapState,
          startDate: newDate
        });
      },
      val: salesDashboardMapState.startDate,
      type: 'input',
      styleOverrides: { marginRight: 24 },
      className: classes.dateInput
    },
    {
      label: t('endDate.label'),
      handleChange: newDate => {
        setSalesDashboardMapState({
          ...salesDashboardMapState,
          endDate: newDate
        });
      },
      val: salesDashboardMapState.endDate,
      type: 'input',
      styleOverrides: { marginRight: 24 },
      className: classes.dateInput
    },
    {
      label: t('distributor.label'),
      handleChange: (evt, distributorName) => {
        setSalesDashboardMapState({
          ...salesDashboardMapState,
          distributorName: distributorName
        });
      },
      onInputChange: val => {
        // console.log(val);
      },
      handleClose: evt => {
        // console.log('closed!!!');
      },
      val: salesDashboardMapState.distributorName,
      options: distributorsList,
      loading: false,
      type: 'autocomplete',
      styleOverrides: {
        display: 'inline-block',
        width: 260,
        marginTop: 10
      }
    }
  ];

  useEffect(() => {
    let startTime = '';
    let endTime = '';
    if (salesDashboardMapState.startDate && salesDashboardMapState.endDate) {
      startTime = new Date(
        convertLocalToUTCDate(salesDashboardMapState.startDate)
      );
      startTime = Math.floor(startTime.getTime() / 1000);
      endTime = new Date(convertLocalToUTCDate(salesDashboardMapState.endDate));
      endTime = Math.floor(endTime.getTime() / 1000);
    }
    if (
      startTime !== '' &&
      endTime !== '' &&
      salesDashboardMapState.distributorName !== null
    ) {
      handleFilterForSalesDashboardMap({
        startTime,
        endTime,
        distributor: salesDashboardMapState.distributorName?.name
      });
    }
  }, [salesDashboardMapState]);

  return (
    <>
      <ChartContainer
        title={null}
        filterList={salesDasboardMapFilter}
        clearFilter={evt => handleClearFilter()}>
        <Grid
          container
          className={classes.mapContainer}
          style={marginGenerator('mt-32')}>
          {loadingSalesDashboardMap ? (
            <div className={classes.infoWindowLoading}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <SalesMapWithHeapMap
              locationsForSalesDashBoard={locationsForSalesDashBoard}
            />
          )}
        </Grid>
      </ChartContainer>
    </>
  );
};

export default SalesTrackerDashBoardMapAndDetails;
