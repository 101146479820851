import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PaymentVoucherPanelItem from './PaymentVoucherPanelItem';
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import Message from '../../../../../../components/Message';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentVoucherFormModal from './PaymentVoucherFormModal';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import {
    GET_PAYMENT_VOUCHER,
    GET_TRANSPORT_REQUESTS
} from '../../queries';
import { CREATE_PAYMENT_VOUCHER, DELETE_PAYMENT_VOUCHER_ } from '../../mutation';
import { result } from 'lodash-es';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

const initFilterState = {search: "", startDate: null, endDate: null, status: ""};
function PaymentVoucherForm({
    t,
    allowConfigure,
    data,
    menuItems,
    users,
    subscriptionUserData,
    departments
}) {

    const classes = useStyles();
    const subscriptionId = data.id;

    const [filterStateData, setFilterStateData] = useState({...initFilterState});
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [selectedPaymentVoucherItem, setSelectedPaymentVoucherItem] = useState(null);
    const [PaymentVoucherFormModalOpen, setPaymentVoucherFormModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [signatureState, setSignatureState] = useState({ReceiverSign: "", PreparedSign: "", CheckedSign: "",
    AuthorizedSign: "", ApproverSign: "",});

    const {
        data: userdata,
        loading: userDataLoading,
        error: userDataError
      } = useQuery(GET_USER_INFO); 

  const [getAllPaymentVoucher, {
        loading,
        data: PaymentVoucherList
    }] = useLazyQuery(GET_PAYMENT_VOUCHER, {
        fetchPolicy: "no-cache",
        // onCompleted: (result) => {
        //     console.log("Result ============= ", result)
        // }
    });

    useEffect(() => {
        return getAllPaymentVoucher({variables: {...filterStateData, subscriptionId}});
    }, []);

    useEffect(() => {
        if(filterStateData.startDate && filterStateData.endDate){
            getAllPaymentVoucher({variables: {...filterStateData, subscriptionId}});
        }else{
            getAllPaymentVoucher({variables: {...filterStateData, subscriptionId}});
        }
    }, [filterStateData, subscriptionId]);

    const [createPaymentVoucher] = useMutation(CREATE_PAYMENT_VOUCHER, {
        onError: error => {
            console.log("error == ", error);
            // Message.error(error);
        },
        onCompleted: () => {
            Message.success('Payment Voucher Created Successfully');
            setPaymentVoucherFormModalOpen(false);
            setSignaturePadModalOpen(false);
            setCRModalState({clearData: true, btnLoading: false});
            setSelectedPaymentVoucherItem(null);
            setSignatureType("");
            return getAllPaymentVoucher({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deletePaymentVoucher] = useMutation(DELETE_PAYMENT_VOUCHER_, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Payment Voucher Deleted Successfully');
            return getAllPaymentVoucher({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreatePaymentVoucherSubmit = (formData) => {
        //formData.payee = formData.payee.map(itm => itm.id);
        formData.subscriptionId = subscriptionId;
        //console.log("Create",formData)
        return createPaymentVoucher({ variables: formData });
    } 

   
    const columns = [

        {
            label: 'Voucher Date',
            key: "date"
        },
        {
            label: 'Voucher Number',
            key: "voucherNo"
        },
        {
            label: 'Payee',
            key: "payee"
        },
        {
            label: 'Cheque Number',
            key: "chequeNo"
        }
    ];

    const handlePaymentVoucher = () => {
        setPaymentVoucherFormModalOpen(true);
    }

    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }
    
    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                console.log("evt.target.value: ", evt.target.value);
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            value: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        }, 
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        }

         /**{
            label: 'Status',
            handleChange: evt => {

            },
            val: filterStateData.status,
            styleOverrides: { minWidth: 200, marginRight: 20 },
            options: []
        }**/
    ];


    const handleAddPaymentVoucher = (PaymentVoucher) => {
        setPaymentVoucherFormModalOpen(true);
        setSelectedPaymentVoucherItem(PaymentVoucher);
    } 

    const handleMenuItemClick = (type, rowItem) => {
        setCRModalState({clearData: false, btnLoading: false});
        setSelectedPaymentVoucherItem(rowItem);
        if(type === "sign"){
            
            setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setPaymentVoucherFormModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are You Sure You Want To Close This Voucher?') ===
                false
            ) {
                return false;
            }
            return deletePaymentVoucher({
                variables: {
                    id : rowItem.id
                }
            })
        }
    }

    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => {
                        if (filter.type && filter.type === 'input') {
                            return (
                                <DateTimeSelector
                                    key={index}
                                    label={filter.label ? filter.label : ""}
                                    dateFormat={"yyyy-MM-dd HH:mm"}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    styleOverrides={filter.styleOverrides}
                                    inputStyle={{ minWidth: 220, padding: 10 }}
                                    disableFuture={true}
                                />
                            );
                        } else if (filter.type && filter.type === 'text') {
                            return (
                                <Input
                                    key={index}
                                    placeholder={filter.label ? filter.label : ""}
                                    value={filter.val}
                                    handleChange={filter.handleChange}
                                    style={filter.styleOverrides}
                                />
                            );
                        } else {
                            return (
                                <OutlinedSelect
                                    key={index}
                                    val={filter.val}
                                    label={filter.label}
                                    handleChange={filter.handleChange}
                                    IconComponent={filter.iconComponent}
                                    options={filter.options}
                                    styleOverrides={filter.styleOverrides || null}
                                    disabled={filter.disabled || false}
                                />
                            );
                        }
                    })}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handlePaymentVoucher}
                        >
                            {"Add New"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
            <TableContainer>
                <Table>
                    <TableHead>

                    <TableRow>
                        {columns.map((item, index) => (
                        <TableCell key={index} style={item.style ? item.style : null} >
                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                        </TableCell>
                        ))}
                    </TableRow>

                    </TableHead>

                    <TableBody>
                    { console.log("PaymentVoucherList == ", PaymentVoucherList) }
                    {!loading ? PaymentVoucherList && PaymentVoucherList.getAllPaymentVoucher && PaymentVoucherList.getAllPaymentVoucher.length > 0 ?
                                PaymentVoucherList.getAllPaymentVoucher.map((rowitem, rowIndex) => (
                                    <PaymentVoucherPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        handleAddPaymentVoucher={handleAddPaymentVoucher}
                                        users={subscriptionUserData}
                                    />
                                )) :
                        <TableRow>

                            <TableCell colSpan={columns.length} align="center" component="td">
                                Records Not Found
                            </TableCell>

                        </TableRow>
                         : null
                        }
                        {loading && 
                         <TableRow>
                         <TableCell colSpan={columns.length}>
                             <div style={{textAlign: "center", width: "100%"}}>
                                 <CircularProgress size={20} color="primary" />
                             </div>
                         </TableCell>
                     </TableRow>
                 }
                </TableBody>
                </Table>
            </TableContainer>
            </Grid>


            <PaymentVoucherFormModal
                open={PaymentVoucherFormModalOpen}
                handleClose={evt => {
                    setPaymentVoucherFormModalOpen(false);
                    setSelectedPaymentVoucherItem(null);
                }}

                // handleClose={() => setPaymentVoucherFormModalOpen(false)}
                modalState={crModalState}
                users={users}
                //userdata={userdata && userdata.userInfo}
                data={selectedPaymentVoucherItem}
                //departments={departments}
                handleCreatePaymentVoucherSubmit={handleCreatePaymentVoucherSubmit}
                subscriptionUserData={subscriptionUserData}
                signatures={signatureState}
                handleOpenSignPanModal={handleOpenSignPanModal}
            />

            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    );
}

PaymentVoucherForm.propTypes = {
    t: PropTypes.func.isRequired,
    //list: PropTypes.object,
    //menuItems: PropTypes.array.isRequired,
    //handleMenuItemClick: PropTypes.func.isRequired
};
export default PaymentVoucherForm;
