import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
  
function ImagePreviewModal(
    {
        open,
        handleClose,
        image
    }
) {
  
  const classes = useStyles();
  
  return (
    <div>
        <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <DialogContent>
                <img src={image} style={{width:'100%', height:'auto'}} />
            </DialogContent>
        </Dialog>
    </div>
  );
}

ImagePreviewModal.propTypes = {
    open: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ImagePreviewModal;
