import React from 'react';
import { withTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { get } from "lodash-es";
import {useLazyQuery} from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../../../../../../theme/commonStyles';
import Typography from '@material-ui/core/Typography';
import { dateFormatterLLL } from '../../../../../../../../utils';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button 
        color="primary"
        onClick={onClick}
        >
            {loading && <CircularProgress size={19} />}
            {!loading && <DeleteIcon color="secondary"/> }
        </Button>
    );
  }

const OcrConfigrationGroupItem = ({
    key,
    t,
    data,
    handleSubmitOfAllOcrGroupAction,
    deleteOcrGroupLoading,
    selecteIdForDelete,
    setSelectedGroupId
}) => {
  const commonClasses = commonStyles();

  return (
    <TableRow key={data.id}>
    <TableCell>
        <Grid className={commonClasses.column}>
            <Grid>
                <Typography variant={'subtitle1'}>
                {data ? data.name : '-'}
                </Typography>
            </Grid>
        </Grid>
    </TableCell>
    <TableCell>
            <div style={{ display: 'flex' }}>
                <Button 
                    color="primary" 
                    onClick={() =>{
                        handleSubmitOfAllOcrGroupAction('getById',{id:data.id});
                        setSelectedGroupId(data.id);
                    }}
                >
                    <GroupAddIcon />
                </Button>
                <ButtonComponent 
                    t={t}
                    loading={selecteIdForDelete === data.id ? deleteOcrGroupLoading: false}
                    onClick={() => handleSubmitOfAllOcrGroupAction('delete',{id:data.id})}
                />
            </div>
        
    </TableCell>
</TableRow>
  )
}

export default OcrConfigrationGroupItem