import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { marginGenerator } from '../../../../../../theme/utils';
import Message from '../../../../../../components/Message';
import CreateDepartmentModal from './CreateDepartmentModal';
import CreateSectionModal from './CreateSectionModal';
import {
    CREATE_DEPARTMENT,
    CREATE_SECTION
} from '../../mutation';
import { CircularProgress } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';

const SectionPanel = ({
    allowConfigure,
    handleClickOfTabs,
    data,
    reloadDepartments,
    departmentLoading,
    departmentError,
    departmentList
}) => {

    const [departmentModalOpen, setDepartmentModalOpen] = useState(false);
    const [sectionModalOpen, setSectionModalOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [dense, setDense] = React.useState(false);
    const subscriptionId = data.id;

    const [createSection, { loading: createSecLoading }] = useMutation(CREATE_SECTION, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Section created successfully');
            setSectionModalOpen(false);
            setSelectedDepartment(null);
            return reloadDepartments();
        }
    });

    const [createDepartment, { loading: createDepartLoading }] = useMutation(CREATE_DEPARTMENT, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Depatrtment created successfully');
            setDepartmentModalOpen(false);
            return reloadDepartments();
        }
    });

    const handleCreateSectionSubmit = (name) => {
        console.log("selectedDepartment: ", selectedDepartment);
        if (selectedDepartment) {
            const formData = {
                name,
                subscriptionId,
                department: selectedDepartment.id
            };
            console.log("formData", formData);
            return createSection({ variables: formData });
        }
        return null;
    }

    const handleCreateDepartment = (name) => {
        const formData = {
            name,
            subscriptionId
        };
        return createDepartment({ variables: formData });
    }

    return (
        <div style={{ marginTop: 45, overflow: 'hidden', padding: 8 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="8" />
                <Grid item xs="4">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => setDepartmentModalOpen(true)}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                        >
                            {"Add Department"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={2} style={{ ...marginGenerator("mb-20"), ...marginGenerator("mt-20") }}>
                {(!departmentLoading && !departmentError && departmentList && departmentList.getAllDepartments) ? departmentList.getAllDepartments.map((item) => (
                    <Grid item xs={3} key={item.id}>
                        <Paper style={{ height: 300, padding: 8, borderRadius: 8 }} elevation={10}>
                            <Grid container spacing={1} style={{ paddingBottom: 8 }}>
                                <Grid item xs="6">
                                    <Typography
                                        variant={'h4'}
                                        style={{ padding: 10 }}
                                    >
                                        {item.name}
                                    </Typography>
                                </Grid>
                                {item.sections && item.sections.length > 0 &&
                                    <Grid item xs="6">
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            style={{
                                                fontSize: "0.8125rem",
                                                padding: "10px 6px",
                                                marginTop: 4,
                                                marginLeft: 20,
                                                borderRadius: 8
                                            }}
                                            startIcon={<LocalHospitalIcon color="primary" />}
                                            onClick={() => {
                                                setSectionModalOpen(true);
                                                setSelectedDepartment(item);
                                            }}
                                        >
                                            {"Add Section"}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                            <Divider />
                            <List style={{overflowY: "auto", maxHeight: "226px"}} dense={dense}>
                                {item.sections && item.sections.length ? item.sections.map((secItm, idx) => (
                                    <ListItem key={secItm.id} style={{cursor: "pointer"}}  onClick={() =>{
                                        if(secItm.name === "Cash Reports"){
                                            handleClickOfTabs(0, 6)
                                        }else if(secItm.name === "Transport"){
                                            handleClickOfTabs(0, idx+5) 
                                        }else {
                                            handleClickOfTabs(0, idx+2)
                                        }
                                    }}>
                                        <ListItemIcon>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={secItm.name} />
                                    </ListItem>
                                )) :
                                    <ListItem>
                                        <Button
                                            variant={'outlined'}
                                            style={{width: "100%"}}
                                            color={'primary'}
                                            startIcon={<LocalHospitalIcon color="primary" />}
                                            onClick={() => {
                                                setSectionModalOpen(true);
                                                setSelectedDepartment(item);
                                            }}
                                        >
                                            {"Add Section"}
                                        </Button>
                                    </ListItem>}
                            </List>
                        </Paper>
                    </Grid>
                )) : null}
                {departmentLoading &&
                    <div style={{ width: "100%", textAlign: "center", marginTop: 32 }}>
                        <CircularProgress size={25} />
                    </div>}
            </Grid>
            <CreateSectionModal
                open={sectionModalOpen}
                department={selectedDepartment}
                handleClose={() => {
                    setSelectedDepartment(null);
                    setSectionModalOpen(false);
                }}
                loading={createSecLoading}
                handleSubmit={handleCreateSectionSubmit}
            />
            <CreateDepartmentModal
                open={departmentModalOpen}
                loading={createDepartLoading}
                handleSubmit={handleCreateDepartment}
                handleClose={() => setDepartmentModalOpen(false)}
            />
        </div>
    )
}

export default SectionPanel