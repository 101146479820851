import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { USER_ROLES } from '../../../../utils/constants';
import { marginGenerator } from '../../../../theme/utils';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../components/OutlinedSelect";
import Link from '@material-ui/core/Link';

const CustomLiveCameraPanel = ({ 
    t, 
    data,
    handleCreateURLClick
}) => {
    
    const [selectedLiveCam, setSelectedLiveCam] = useState('');
    const role = localStorage.getItem('role');
 
    const handleSelectLiveCam = (val) => {
        if(!val){
            val = data.liveCamUrls && data.liveCamUrls.length ? data.liveCamUrls[0] : '';
        }
        setSelectedLiveCam(val);
    }

    useEffect(() => {
        if(data.liveCamUrls && data.liveCamUrls.length) setSelectedLiveCam(data.liveCamUrls[0]);
    }, [data]);

    return (
        <>
            <OutlinedSelect
                val={selectedLiveCam}
                label={`${t('selectCam.label')}`}
                handleChange={(evt) => {
                    handleSelectLiveCam(evt.target.value);
                }}
                IconComponent={null}
                options={data.liveCamUrls}
                styleOverrides={{minWidth: '250px'}}
                disabled={false}
            />
            {role.includes(USER_ROLES.IP_COUNTRYADMIN) || role.includes(USER_ROLES.IP_SUPERADMIN) ?
                <Grid style={{ display: 'flex', alignItems: 'right', float: 'right' }} >
                    <Button
                    align={'right'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() =>
                        handleCreateURLClick('liveCam')
                    }>
                        { t('add.label')} {t('camera.label')} {t('url.label')}
                    </Button>
                </Grid>
            : null }
            <Grid style={{...marginGenerator('mt-20')}}>
                {data.liveCamUrls && data.liveCamUrls.length ? 
                    // <iframe width="100%" height={window.innerHeight-250} src={selectedLiveCam.url} frameBorder="0" style={{border:0, ...marginGenerator('mb-34')}}></iframe>
                <Link 
                href={selectedLiveCam.url}
                target={'blank'}
                component="a"
                >
                <Button align={'center'}
                variant={'contained'}
                color={'primary'}>Open {selectedLiveCam.name}</Button>
                </Link> :  <Typography variant={'subtitle2'}>{t('dataNotFound.label')}</Typography>
                }
            </Grid>
        </>
    );
};

CustomLiveCameraPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default CustomLiveCameraPanel;