import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel" la

            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '400px'
    },
    container: {
        padding: '10px'
    }
}));

export default function AnalysisOfOutOfSLACausesTableItem({ data, column, loading, error, onHold }) {
    const classes = useStyles();

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: '100%',
                        padding: 20,
                        textAlign: 'center',
                        display: 'inline-block'
                    }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {column &&
                                    column.map(column => (
                                        <TableCell>{column.label}</TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(onHold === true) ? <React.Fragment>{!loading && !error ?
                                data && data.analysis_hold_projects ? data.analysis_hold_projects.map((row, index) => (
                                    <StyledTableRow>
                                        <TableCell>{index + 1}</TableCell>
                                        {Object.entries(row).map(([key, val]) => (
                                            <React.Fragment>
                                                <TableCell>{key}</TableCell>
                                                <TableCell>{val.count}</TableCell>
                                                <TableCell>{val.total_nrc.total_nrc__sum ? val.total_nrc.total_nrc__sum : '0'}</TableCell>
                                                <TableCell>{val.total_mrc.total_mrc__sum ? val.total_mrc.total_mrc__sum : '0'}</TableCell>
                                            </React.Fragment>
                                        )
                                        )}
                                        {/* <TableCell>{row}</TableCell> */}
                                        {/* <TableCell>{row.count}</TableCell>
                                        <TableCell>{row.nrc}</TableCell>
                                        <TableCell>{row.mrc}</TableCell>
                                        <TableCell>{row.percentage}</TableCell> */}
                                    </StyledTableRow>)) :
                                    <TableRow>
                                        <TableCell colSpan={column.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : null}</React.Fragment> : <React.Fragment>{!loading && !error ?
                                    data && data.analysis_inprogress_projects ? data.analysis_inprogress_projects.map((row, index) => (
                                        <StyledTableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            {Object.entries(row).map(([key, val]) => (
                                                <React.Fragment>
                                                    <TableCell>{key}</TableCell>
                                                    <TableCell>{val.count}</TableCell>
                                                    <TableCell>{val.total_nrc.total_nrc__sum ? val.total_nrc.total_nrc__sum : '0'}</TableCell>
                                                    <TableCell>{val.total_mrc.total_mrc__sum ? val.total_mrc.total_mrc__sum : '0'}</TableCell>
                                                </React.Fragment>
                                            )
                                            )}
                                            {/* <TableCell>{row}</TableCell> */}
                                            {/* <TableCell>{row.count}</TableCell>
                                            <TableCell>{row.nrc}</TableCell>
                                            <TableCell>{row.mrc}</TableCell>
                                            <TableCell>{row.percentage}</TableCell> */}
                                        </StyledTableRow>)) :
                                        <TableRow>
                                            <TableCell colSpan={column.length}>
                                                <Typography variant={'subtitle2'}>Record not found!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    : null}</React.Fragment>}

                            {/* {!loading && !error ?
                                data ?  data.map(row => (
                                    <StyledTableRow>
                                        <TableCell>{row.column}</TableCell>
                                        <TableCell>{row.category}</TableCell>
                                        <TableCell>{row.count}</TableCell>
                                        <TableCell>{row.nrc}</TableCell>
                                        <TableCell>{row.mrc}</TableCell>
                                        <TableCell>{row.percentage}</TableCell>
                                    </StyledTableRow>)) :
                                    <TableRow>
                                        <TableCell colSpan={column.length}>
                                            <Typography variant={'subtitle2'}>Record not found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                : null} */}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}