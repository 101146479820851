import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CustomTable from '../CustomTableOrg';
import { marginGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const MembersPanel = ({ 
  t, 
  membersList, 
  handleAssignUser,
  handleActionClick 
}) => {
  
  const [users, setUsers] = React.useState([]);
  
  const columns = [
    {
      label: t('name.label'),
      key: ['firstName', 'lastName'],
      join: true
    },
    {
      label: t('email.label'),
      key: 'email'
    },
    {
      label: t('phone.label'),
      key: 'phone'
    },
    {
      label: t('role.label'),
      key: 'roles'
    },
    {
      label: t('action.label'),
      key: 'action'
    }
  ];

  React.useEffect(() => {
    if(membersList.length){
      setUsers(membersList.map(itm => {
        return {
          ...itm,
          action: <>
          <IconButton aria-label="delete" onClick={evt => handleActionClick("delete", itm)} >
            <DeleteIcon color="secondary" />
          </IconButton>
          <IconButton aria-label="edit" onClick={evt => handleActionClick("edit", itm)} >
            <EditIcon color="primary" />
          </IconButton>
          </>
        }
      }))
    }
  }, [membersList]);
  
  return (
    <div>
      <Grid style={marginGenerator('mb-16')}>
        <Typography variant={'subtitle2'}>
          {t('showingAll.label')} {membersList.length}{' '}
          {t(`members.label`).toLowerCase()}
        </Typography>
        <Button onClick={handleAssignUser} style={{float: "right"}} variant="outlined" color="default"  >
          Assign New User
        </Button>
      </Grid>
      <CustomTable
        list={users}
        t={t}
        columns={columns}
        handleItemClick={() => null}
      />
    </div>
  );
};

MembersPanel.propTypes = {
  membersList: PropTypes.array.isRequired
};

export default MembersPanel;
