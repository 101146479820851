import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceFrontPage from './InvoiceFrontpage'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  invoiceBox: {
    maxWidth: 800,
    margin: 'auto',
    padding: 30,
    border: '1px solid #eee',
    boxShadow: '0 0 10px rgba(0, 0, 0, .15)',
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif',
    color: '#555'
  },
  invoiceBoxTable: {
    width: '100%',
    lineHeight: 'inherit',
    textAlign: 'left'
  }
});

const Invoice = ({ userInfo, invoice, t, key, time, org, country,platform,circuitId,siteName }) => (
  <Document wrap={true} key={key}>
    <Page size="A4" style={styles.page}>
      <InvoiceFrontPage time={time} org={org} country={country} />
    </Page>
    <InvoiceItemsTable 
    invoice={invoice} 
    time={time}
     country={country}
    userInfo={userInfo}
     t={t}
     platform={platform}
     circuitId={circuitId}
     siteName={siteName}
    org={org} />
  </Document>
);
export default Invoice;
