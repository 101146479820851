import React, {useState, useEffect, useContext} from 'react';
import { get } from "lodash-es";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import ChartContainer from '../../../../../components/Charts/ChartContainer';
import {  convertLocalToUTCDate } from '../../../../../utils';
import { marginGenerator } from '../../../../../theme/utils';
import ShayonaStatCardAndChart from './component/ShayonaStatCardAndChart';
import { ShayonaCementsContext } from '../../../../../contexts/ShayonaCementsProvider';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '352px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden"
  },
  dateInput: {
    width: 250,
    minWidth: 200,
    padding: 10
  },
  infoWindowLoading: {
      position: "absolute",
      width: "100%",
      height: 352,
      backgroundColor: "#9fa0a26e",
      top: 0,
      textAlign: "center",
      paddingTop: 130,
      zIndex: 999
  }
  
}));

const initDashboardFilter = {time: 'Today', startDate: null, endDate: null, status: ''};

const ShayonaDashboard = ({
    t,
    ocrDashboardMapLoading= false
}) => {

  const classes = useStyles();
  const {
    shayonaApisFilterMethod
  } = useContext(ShayonaCementsContext);
  const [shyonaDashboardState, setShyonDashboardtState] = useState({...initDashboardFilter});

  const handleClearFilter = () => {
    setShyonDashboardtState({...initDashboardFilter});
    shayonaApisFilterMethod('dashboard',{startTime: null, endTime: null});
  };

  const handleAutoPlaceSearch = (type, value) => null;

  const shayonaDashboardFilter =  [
    {
        label: t("startDate.label"),
        handleChange:(newDate) => {
            setShyonDashboardtState({...shyonaDashboardState, startDate: newDate});
        },
        val: shyonaDashboardState.startDate,
        type: "input",
        styleOverrides: { marginRight: 24 },
        className: classes.dateInput
    },
    {
        label: t("endDate.label"),
        handleChange:(newDate) => {
            setShyonDashboardtState({...shyonaDashboardState, endDate: newDate})
        },
        val: shyonaDashboardState.endDate,
        type: "input",
        styleOverrides: { marginRight: 24 },
        className: classes.dateInput,
    },
    // {
    //   label: t('criteria.label'),
    //   handleChange: evt => {
    //       setShyonaAlertState({...shyonaAlertState, status: evt.target.value});
    //   },
    //   val: shyonaAlertState.status,
    //   options: alertStatusOption,
    //   styleOverrides: { marginLeft: 10, marginTop: 10, minWidth: 230 }
    // }
    ];


useEffect(() => {
    let startTime = "";
    let endTime = "";
    if(shyonaDashboardState.startDate && shyonaDashboardState.endDate){
        startTime = new Date(convertLocalToUTCDate(shyonaDashboardState.startDate));
        startTime = Math.floor(startTime.getTime() / 1000); 
        endTime = new Date(convertLocalToUTCDate(shyonaDashboardState.endDate));
        endTime = Math.floor(endTime.getTime() / 1000);
    }
    if(startTime || endTime || shyonaDashboardState.status){
    shayonaApisFilterMethod('dashboard',{startTime, endTime});
    }
    }, [shyonaDashboardState]);

  return (
    <>
        <ChartContainer
            title={null}
            filterList={shayonaDashboardFilter}
            clearFilter={handleClearFilter}
        />
        <ShayonaStatCardAndChart
          t={t}
        />
    </>
  )
}

export default ShayonaDashboard