import React, {useEffect, useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../../components/TabPanel';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    tabAppbar: {
        backgroundColor: theme.palette.common.white,
        borderBottom: 'none'
    },
    tabStyles: {
        color: theme.palette.grey[400]
    }
}));
export default function CustomTabsForEdgeAiDashBoard({tabs, activeIndex, currentTabValue}) {
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const theme = useTheme();
    useEffect(() => {
        setValue(activeIndex);
    }, [activeIndex]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        currentTabValue(newValue);
    };
    return (
        <>
            <AppBar position="static" className={classes.tabAppbar} elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary">
                    {tabs.length &&
                    tabs.map((tab, tabIndex) => (
                        <Tab
                            label={tab.label}
                            id={`tab-${tabIndex}`}
                            key={tabIndex}
                            className={classes.tabStyles}
                            color={'primary'}
                        />
                    ))}
                </Tabs>
            </AppBar>
            <Divider variant={'fullWidth'} absolute style={{bottom: 'auto'}}/>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChange}>
                {tabs.length &&
                tabs.map((tab, tabIndex) => (
                    <TabPanel
                        value={value}
                        index={tabIndex}
                        dir={theme.direction}
                        key={tabIndex}>
                        {tab.children}
                    </TabPanel>
                ))}
            </SwipeableViews>
        </>
    );
}
CustomTabsForEdgeAiDashBoard.defaultProps = {
    activeIndex: 0
};
CustomTabsForEdgeAiDashBoard.propTypes = {
    tabs: PropTypes.array.isRequired,
    activeIndex: PropTypes.number,
    currentTabValue: PropTypes.func.isRequired
};
