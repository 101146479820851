import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {
    USER_ROLES,
} from '../../../../utils/constants';
import {useHistory} from 'react-router';
import CustomTabs from '../../../../components/CustomTabs';
import TicketPanel from '../../../ServiceTickets/components/TicketPanel';
import Message from '../../../../components/Message';
import DashboardPanel from './components/DashboardPanel';
import BillingPanel from './components/BillingPanel';
import ConfigurationPanel from './components/ConfigurationPanel';

const CloudSolutions = ({
    t, 
    data, 
    activeIndex,
    serviceTickets,
    filterListRecentTickets,
    handleTicketItemClick,
    handleTicketMenuItemClick,
    handleSearchRecentTickets,
    recentTicketsSearched,
    updatedRecentTickets
}) => {
    
    const history = useHistory();
    const role = localStorage.getItem('role');
    const [isReloadState, setIsReloadState] = useState(false);
    
    useEffect(() => {
    }, []);
    
    let tabList = [];
    if (data) {
        if (data.getSubscriptionById && data.getSubscriptionById.isDelete) {
            Message.error('Subscription termineted!');
            history.goBack();
        }
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <DashboardPanel
                        t={t}
                        subscriptionData={data.getSubscriptionById}
                        ticketData={
                            (serviceTickets &&
                                serviceTickets.servicetickets.slice(0, 10)) ||
                            []
                        }
                        type={data.getSubscriptionById.service.serviceType.name}
                    />
                )
            },
            {
                label: t('configuration.label'),
                children: (
                    <>
                        <ConfigurationPanel
                            t={t}
                            isReload={isReloadState}
                            data={data.getSubscriptionById}
                        />
                    </>
                )
            },
            {
                label: t('serviceTickets.label'),
                children: (
                    <>
                        {serviceTickets && (
                            <TicketPanel
                                ticketList={
                                    recentTicketsSearched
                                        ? updatedRecentTickets
                                        : serviceTickets.servicetickets.slice(0, 10)
                                }
                                type={'recentTickets'}
                                t={t}
                                handleMenuItemClick={handleTicketMenuItemClick}
                                filterList={filterListRecentTickets}
                                handleTicketItemClick={handleTicketItemClick}
                                handleSearch={handleSearchRecentTickets}
                            />
                        )}
                    </>
                )
            }
        ];
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.CSOC_MANAGER) &&
            !role.includes(USER_ROLES.CSOC) &&
            !role.includes(USER_ROLES.SD_READONLY) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('billing.label'),
                children: (
                    <>
                        <BillingPanel t={t} data={data.getSubscriptionById}/>
                    </>
                )
            });
        }
    }

    return (
        <>
            <CustomTabs 
                activeIndex={activeIndex} 
                tabs={tabList}
            />
        </>
    );
};
export default withTranslation()(React.memo(CloudSolutions));