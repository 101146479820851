import React from 'react';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import { Paths } from '../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_USER_INFO } from './queries';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LOGOUT } from '../commonQueries';
import { onLogout } from '../../utils';
import Avatar from '../../components/Avatar';
import { colors } from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  container: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  }
}));

const breadcrumbList = [
  { label: 'home.label', link: Paths.Dashboard },
  { label: 'profile.label', link: Paths.Profile }
];

const Profile = ({ t }) => {
  const { data, loading, error } = useQuery(GET_USER_INFO);
  const classes = useStyles();
  const basicInfo =
    !loading && !error
      ? [
          {
            key: t('name.label'),
            value: `${data.userInfo.firstName} ${data.userInfo.lastName}`
          },
          {
            key: t('email.label'),
            value: data.userInfo.email
          },
          {
            key: t('phone.label'),
            value: data.userInfo.phone
          }
        ]
      : [];

  const orgInfo =
    !loading && !error
      ? [
          {
            key: t('name.label'),
            value: data.userInfo.organization.name
          },
          {
            key: t('address.label'),
            value: data.userInfo.organization.address
          },
          {
            key: t('region.label'),
            value: data.userInfo.organization.region
          },
          {
            key: t('country.label'),
            value: data.userInfo.organization.country
          }
        ]
      : [];

  const [userLogout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      onLogout();
    }
  });

  return (
    <Layout
      breadcrumbList={breadcrumbList}
      contentStyle={{ maxWidth: '680px' }}>
      <Header
        title={
          !loading && !error
            ? `${data.userInfo.firstName} ${data.userInfo.lastName}`
            : ''
        }
        iconComponent={
          !loading && !error ? (
            <Avatar
              name={`${data.userInfo.firstName} ${data.userInfo.lastName}`}
              avatarStyles={{
                height: '56px',
                width: '56px',
                background: colors.primary.main
              }}
              identifierStyle={{
                fontSize: '1rem',
                color: colors.common.white
              }}
            />
          ) : null
        }
        primaryButtonText={t('logout.label')}
        handlePrimaryButtonClick={() =>
          userLogout({
            variables: {
              refreshToken: localStorage.getItem('syncomRefreshToken')
            }
          })
        }
        divider={true}
      />

      <Grid
        style={{ ...marginGenerator('mt-48'), ...paddingGenerator('pb-32') }}
        className={classes.container}>
        <Typography variant={'h4'}>{t('basicInfo.label')}</Typography>

        {basicInfo.map((item, index) => (
          <Grid
            container
            justify={'space-between'}
            style={marginGenerator('mt-24')}
            key={index}>
            <Typography variant={'body1'} color={'textSecondary'}>
              {item.key}
            </Typography>
            <Typography variant={'body1'}>{item.value || '-'}</Typography>
          </Grid>
        ))}
      </Grid>

      <Grid style={{ ...marginGenerator('mt-24') }}>
        <Typography variant={'h4'}>{t('organizationInfo.label')}</Typography>

        {orgInfo.map((item, index) => (
          <Grid
            container
            justify={'space-between'}
            style={marginGenerator('mt-24')}
            key={index}>
            <Typography variant={'body1'} color={'textSecondary'}>
              {item.key}
            </Typography>
            <Typography variant={'body1'}>{item.value || '-'}</Typography>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default withTranslation()(Profile);
