import {gql} from 'apollo-boost';
/*
  Add Vnf Market
 */
export const ADD_VNF_MARKET_PLACE = gql`
  mutation AddVnfMarketPlaceData($categoryId: String, $imageName: String, $imageURL: String, $imageLogo: String, $imageType: String, $imagePrice: String, $description: String, $overview: String, $review: String, $support: String, $highlightsList: [JSON], $overviewList: [JSON]){
    addVnfMarketPlaceData(categoryId: $categoryId, imageName: $imageName, imageURL: $imageURL, imageLogo: $imageLogo, imageType: $imageType, imagePrice: $imagePrice, description: $description, overview: $overview, review: $review, support: $support, highlightsList: $highlightsList, overviewList: $overviewList) {
      _id
    }
  }
`;/*
  Add update category
 */
export const ADD_UPDATE_CATEGORY = gql`
  mutation AddUpdateVnfMarketCategory($name: String, $_id: String){
    addUpdateVnfMarketCategory(name: $name, _id: $_id) {
      _id
    }
  }
`;
/*
  Add Vnf Market
 */
export const UPDATE_VNF_MARKET_PLACE = gql`
  mutation UpdateVnfMarketPlaceData($isMarketPlaceID: String, $categoryId: String, $imageName: String, $imageURL: String, $imageLogo: String, $imageType: String, $imagePrice: String, $description: String, $overview: String, $review: String, $support: String, $highlightsList: [JSON], $overviewList: [JSON]){
    updateVnfMarketPlaceData(isMarketPlaceID: $isMarketPlaceID, categoryId: $categoryId, imageName: $imageName, imageURL: $imageURL, imageLogo: $imageLogo, imageType: $imageType, imagePrice: $imagePrice, description: $description, overview: $overview, review: $review, support: $support, highlightsList: $highlightsList, overviewList: $overviewList) {
      _id
    }
  }
`;

/*
  Delete Vnf Marketplace
 */
export const DELETE_VNF_MARKETPLACE = gql`
  mutation DeleteVnfMarketPlaceData($market_place_id: String){
    deleteVnfMarketPlaceData(market_place_id: $market_place_id) {
      _id
    }
  }
`;
/*
  Delete Vnf Marketplace Category
 */
export const DELETE_VNF_MARKETPLACE_CATEGORY = gql`
  mutation DeleteVnfMarketPlaceCategory($category_id: String){
    deleteVnfMarketPlaceCategory(category_id: $category_id) {
      _id
    }
  }
`;
