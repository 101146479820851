import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import Message from '../../../../../../components/Message';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import ChangeRequestPanelItem from './ChangeRequestPanelItem';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import OutlinedSelect from "../../../../../../components/OutlinedSelect";
import { marginGenerator } from '../../../../../../theme/utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateTimeSelector from '../../../../../../components/DateTimeSelector';
import ChangeRequestModal from './ChangeRequestModal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import CanvasSignaturePadModal from '../../../../../../components/CanvasSignaturePadModal';
import FiltersBar from '../../../../../../components/FiltersBar';
import { GET_USER_INFO } from '../../../../../TicketingSystem/queries'
import { CREATE_CHANGE_REQUEST, DELETE_CHANGE_REQUEST } from '../../mutation';
import {
    GET_CHANGE_ISSUES
} from '../../queries';

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    },
    dateInput: {
        width: 180,
        minWidth: 180,
        padding: 10
    },
}));

function ChangeRequestPanel({
    t,
    allowConfigure,
    menuItems,
    users,
    data,
    subscriptionUserData
}) {
    const [goToPage, setGoToPage] = useState(null);
    const [filterStateData, setFilterStateData] = useState({ startDate: null, endDate: null, search: "" });
    const [crModalState, setCRModalState] = useState({btnLoading: false, clearDate: false});
    const [changeRequestModalOpen, setChangeRequestModalOpen] = useState(false);
    const [signaturePadModalOpen, setSignaturePadModalOpen] = useState(false);
    const [signatureType, setSignatureType] = useState("");
    const [selectedUserItem, setSelectedUserItem] = useState(null);
    const [signatureState, setSignatureState] = useState({
        // authorisationSign: "",
        // recommendationSign: "",
        rgSign:"",
        svSign:"",
        responsibleSign: "",
        accountableSign: "",
        consultedSign: "",
        informedSign: "",
        commercialManagerSign:""
    });
    const classes = useStyles();
    const subscriptionId = data.id;

    const {
        data: userdata,
        loading: userDataLoading,
        error: userDataError
      } = useQuery(GET_USER_INFO);

      const [getAllChangeRequests, {
        loading,
        data: chnageRequestList
    }] = useLazyQuery(GET_CHANGE_ISSUES, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        return getAllChangeRequests({variables: {...filterStateData, subscriptionId}});
    }, []);


    useEffect(() => {
        getAllChangeRequests({variables: {...filterStateData, subscriptionId}});
    }, [filterStateData, subscriptionId]);

    const [createChangeRequest] = useMutation(CREATE_CHANGE_REQUEST, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            setSelectedUserItem(null);
            Message.success('Change request created successfully');
            setCRModalState({clearData: true, btnLoading: false});
            setChangeRequestModalOpen(false)
            return getAllChangeRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const [deleteChangeRequest, { loading: deleteChangeRequestLoading }] = useMutation(DELETE_CHANGE_REQUEST, {
        onError: error => {
            Message.error(error);
        },
        onCompleted: () => {
            Message.success('Change request deleted successfully');
            return getAllChangeRequests({variables: {...filterStateData, subscriptionId}});
        }
    });

    const handleCreateChangeRequestSubmit = (formData) => {
        formData.subscriptionId = subscriptionId;
        return createChangeRequest({ variables: {
            ...signatureState,
            ...formData,
        }});
    }

    const columns = [
        {
            label: t('title.label'),
            key: "title"
        },
        {
            label: 'Request Date',
            key: "requestDate"
        },
        {
            label: 'Section',
            key: "section"
        },
        {
            label: "Responsible",
            key: "responsible"
        },
        {
            label: 'Requester',
            key: "requester"
        },
        {
            label: 'Changes',
            key: "changes"
        },
        {
            label: 'Actions',
            key: "actions"
        }
    ];

    const changeRequestList = [
        {
            title: "Request 1",
            requestDate: new Date(),
            section: "maintenence",
            responsible: "test",
            requester: "test",
            changes: "A change request is a proposal to alter some aspect of a given project.",
            actions: ""
        },
        {
            title: "Request 2",
            requestDate: new Date(),
            section: "maintenence",
            responsible: "test",
            requester: "test",
            changes: "A change request is a proposal to alter some aspect of a given project.",
            actions: ""
        },
        {
            title: "Request 3",
            requestDate: new Date(),
            section: "maintenence",
            responsible: "test",
            requester: "test",
            changes: "A change request is a proposal to alter some aspect of a given project.",
            actions: ""
        },
        {
            title: "Request 4",
            requestDate: new Date(),
            section: "maintenence",
            responsible: "test",
            requester: "test",
            changes: "A change request is a proposal to alter some aspect of a given project.",
            actions: ""
        },
        {
            title: "Request 5",
            requestDate: new Date(),
            section: "maintenence",
            responsible: "test",
            requester: "test",
            changes: "A change request is a proposal to alter some aspect of a given project.",
            actions: ""
        }
    ];

    const handleNewChangeRequest = () => {
        setChangeRequestModalOpen(true);
    }
    
    const handleOpenSignPanModal = (type) => {
        setSignaturePadModalOpen(true);
        setSignatureType(type);
    }

    const filterList = [
        {
            label: t("search.label"),
            handleChange: (evt) => {
                setFilterStateData({...filterStateData, search: evt.target.value});
            },
            val: filterStateData.search,
            type: "text",
            styleOverrides: { marginRight: 20, minWidth: 200, padding: 10 },
        },
        {
            label: t("startDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, startDate: newDate});
            },
            val: filterStateData.startDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
        {
            label: t("endDate.label"),
            handleChange: (newDate) => {
                setFilterStateData({...filterStateData, endDate: newDate});
            },
            val: filterStateData.endDate,
            type: "input",
            styleOverrides: { marginRight: 20 },
            className: classes.dateInput
        },
    ];

    const handleMenuItemClick = (type, rowItem) => {
        setSelectedUserItem(rowItem);
        if(type === "sign"){
            setSelectedUserItem(rowItem);
           setSignaturePadModalOpen(true);
            setSignatureType(type);
        }else if(type === "edit"){
            setChangeRequestModalOpen(true);
        }else if(type === 'delete'){
            if (
                window.confirm('Are you sure you want to close this project ?') ===
                false
            ) {
                return false;
            }
            return deleteChangeRequest({
                variables: {
                    id : rowItem._id
                }
            })
        }
    }

    return (
        <div style={{ marginTop: 45 }}>
            <Grid container style={{ ...marginGenerator("mb-20") }}>
                <Grid item xs="10">
                    {filterList && filterList.map((filter, index) => <FiltersBar key={filter.label} filter={filter} /> )}
                    <Button variant="contained" color={'primary'} style={{ padding: 10 }} onClick={() => { }}>Filter</Button>
                </Grid>
                <Grid item xs="2">
                    {allowConfigure && <>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            style={{ ...marginGenerator('ml-10'), float: "right" }}
                            onClick={handleNewChangeRequest}
                        >
                            {"Request Change"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
            <Grid container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell key={index}>
                                        <Typography variant={'subtitle2'}>{item.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!loading ? chnageRequestList && chnageRequestList.getAllChangeRequest && chnageRequestList.getAllChangeRequest.data.length > 0 ?
                                chnageRequestList.getAllChangeRequest.data.map((rowitem, rowIndex) => (
                                    <ChangeRequestPanelItem
                                        key={rowIndex}
                                        t={t}
                                        data={rowitem}
                                        handleMenuItemClick={handleMenuItemClick}
                                        allowConfigure={allowConfigure}
                                        users={subscriptionUserData}
                                        // handleAddMeetingNotes={handleAddMeetingNotes}
                                    />
                                )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                                : null
                            }
                            {loading && 
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            <CircularProgress size={20} color="primary" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ChangeRequestModal
                open={changeRequestModalOpen}
                handleClose={evt =>{
                    setChangeRequestModalOpen(false)
                    setSelectedUserItem(null);
                }}
                modalState={crModalState}
                handleOpenSignPanModal={handleOpenSignPanModal}
                users={users}
                userdata={userdata && userdata.userInfo}
                data={selectedUserItem}
                signatures={signatureState}
                handleCreateChangeRequestSubmit={handleCreateChangeRequestSubmit}
            />
            <CanvasSignaturePadModal 
                open={signaturePadModalOpen} 
                handleClose={() => setSignaturePadModalOpen(false)} 
                handleSaveSignature={(image) => {
                    setSignaturePadModalOpen(false);
                    setSignatureState((prevState) => {
                        const newState = {...prevState};
                        newState[signatureType] = image;
                        return newState;
                    });
                }}
            />
        </div>
    );
}

ChangeRequestPanel.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.object,
    menuItems: PropTypes.array.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired
};
export default ChangeRequestPanel;
