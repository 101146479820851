import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import commonStyles from '../../../../theme/commonStyles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CustomTabs from "../../../../components/CustomTabs";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CreateUpdateSwitch from "./CreateUpdateSwitch";
import CreateUpdateRouter from "./CreateUpdateRouter";
import CreateUpdateBwmgr from "./CreateUpdateBwmgr";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {GET_CONNECTIVITY_INFO_DETAILS, GET_OPPORTUNITY_BY_ID, GET_PLACE_LIST, GET_SUBSCRIPTION_DETAILS} from "../../queries";
import {SERVICE_TYPES, SUBSCRIPTION_STATUS} from "../../../../utils/constants";
import {OPPORTUNITY_ACTIVATE, UPDATE_BWMGR, UPDATE_ROUTER, UPDATE_SUBSCRIPTION, UPDATE_SWITCH} from "../../mutations";
import Message from "../../../../components/Message";

const useStyles = makeStyles(theme => ({
    paging: {
        float: "right",
        marginBottom: 30,
        marginTop: 20
    }
}));
function DIAConfigurationDetailsPanel({
                                          t,
                                          open,
                                          activateSubscriptionId,
                                          connectivityInfoData,
                                          handleClose,
                                      }) {
    const commonClasses = commonStyles();
    const classes = useStyles();
    const [orderId, setOrderId] = useState('');
    const [switchLoadModalState, setSwitchLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [routerLoadModalState, setRouterLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [bwmgrLoadModalState, setBwmgrLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [updateSwitch] = useMutation(UPDATE_SWITCH, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            if (!loading
                && !error
                && data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId) {
                opportunityDetails({
                    variables: {
                        opportunityId: data
                            && data.getSubscriptionById
                            && data.getSubscriptionById.opportunityId
                    }
                })
            }
            if (data.updateSwitch.status !== 'false') {
                Message.success('Switch Updated successfully');
            } else {
                Message.success('Something went wrong');
            }
        }
    });
    const [updateRouter] = useMutation(UPDATE_ROUTER, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            if (!loading
                && !error
                && data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId) {
                opportunityDetails({
                    variables: {
                        opportunityId: data
                            && data.getSubscriptionById
                            && data.getSubscriptionById.opportunityId
                    }
                })
            }
            if (data.updateRouter.status !== 'false') {
                Message.success('Router Updated successfully');
            } else {
                Message.success('Something went wrong');
            }
        }
    });
    const [updateBwmgr] = useMutation(UPDATE_BWMGR, {
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: data => {
            if (!loading
                && !error
                && data
                && data.getSubscriptionById
                && data.getSubscriptionById.opportunityId) {
                opportunityDetails({
                    variables: {
                        opportunityId: data
                            && data.getSubscriptionById
                            && data.getSubscriptionById.opportunityId
                    }
                })
            }
            if (data.updateBwmgr.status !== 'false') {
                Message.success('Bwmgr Updated successfully');
            } else {
                Message.success('Something went wrong');
            }
        }
    });
    const {loading, data, error, refetch: reloadSubscription} = useQuery(
        GET_SUBSCRIPTION_DETAILS,
        {
            variables: {
                id: activateSubscriptionId
            }
        }
    );
    useEffect(() => {
        if (!loading
            && !error
            && data
            && data.getSubscriptionById
            && data.getSubscriptionById.opportunityId) {
            if (data.getSubscriptionById.service
                && data.getSubscriptionById.service.serviceType
                && data.getSubscriptionById.service.serviceType.name === SERVICE_TYPES.DIA) {
                opportunityDetails({
                    variables: {
                        opportunityId: data
                            && data.getSubscriptionById
                            && data.getSubscriptionById.opportunityId
                    }
                })
            }
        }
    }, [data && data.getSubscriptionById && data.getSubscriptionById.opportunityId]);
    const [updateSubscriptionStatus] = useMutation(UPDATE_SUBSCRIPTION, {
        onCompleted: data => {
            window.location.reload();
        },
        onError: e => {
            console.log(e);
        }
    });
    /*Get Opportunity Details*/
    const [opportunityDetails] = useLazyQuery(GET_OPPORTUNITY_BY_ID, {
        fetchPolicy: 'no-cache',
        onError: error => {
            console.log('Error', error);
        },
        onCompleted: response => {
            if (response && response.getOpportunityById && response.getOpportunityById.opportunitiesDetails) {
                const {is_bwmgr_configured, orderid, is_router_configured, is_switch_configured} = JSON.parse(response.getOpportunityById.opportunitiesDetails);
                if (orderid) {
                    setOrderId(orderid);
                }
                if (is_bwmgr_configured && is_router_configured && is_switch_configured) {
                    if (window.confirm("Are you sure? you want to active this subscription")) {
                        updateSubscriptionStatus({
                            variables: {
                                id: activateSubscriptionId,
                                status: SUBSCRIPTION_STATUS.ACTIVE
                            }
                        });
                    }
                }
            }
        }
    });
    const handleCreateUpdateSwitch = (formData) => {
        formData.orderid = orderId;
        updateSwitch({variables: formData})
    }
    const handleCreateUpdateRouter = (formData) => {
        formData.orderid = orderId;
        updateRouter({variables: formData})
    }
    const handleCreateUpdateBwmgr = (formData) => {
        formData.orderid = orderId;
        updateBwmgr({variables: formData})
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="dia-template-modal"
            aria-describedby="dia-template-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle id="ml-template-title">
                {t('configuration.label')} {t('details.label')}
            </DialogTitle>
            <DialogContent>
                <CustomTabs
                    tabs={[
                        {
                            label: t('switch.label'),
                            children: (
                                <>
                                    <CreateUpdateSwitch handleClose={handleClose}
                                                        connectivityInfoData={connectivityInfoData}
                                                        handleCreateUpdateSwitch={handleCreateUpdateSwitch}
                                                        modalState={switchLoadModalState}/>
                                </>
                            )
                        },
                        {
                            label: t('router.label'),
                            children: (
                                <>
                                    <CreateUpdateRouter handleClose={handleClose}
                                                        connectivityInfoData={connectivityInfoData}
                                                        handleCreateUpdateRouter={handleCreateUpdateRouter}
                                                        modalState={routerLoadModalState}/>
                                </>
                            )
                        },
                        {
                            label: t('bwmgr.label'),
                            children: (
                                <>
                                    <CreateUpdateBwmgr handleClose={handleClose}
                                                       connectivityInfoData={connectivityInfoData}
                                                       handleCreateUpdateBwmgr={handleCreateUpdateBwmgr}
                                                       modalState={bwmgrLoadModalState}/>
                                </>
                            )
                        },
                    ]}
                />
            </DialogContent>
        </Dialog>
    );
}
DIAConfigurationDetailsPanel.propTypes = {
    t: PropTypes.func.isRequired
};
export default DIAConfigurationDetailsPanel;
