import React from 'react';
import Layout from '../../../components/Layout';
import { TicketingSystem } from '../../../routes/routePaths';
import { withTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import Message from '../../../components/Message';
import {
  IMPORT_DATA_TO_DB,
  IMPORT_ORG_USER_ROLE_TO_DB,
  IMPORT_CHILD_CUSTOMER_TO_DB
} from '../../commonMutations';
import { useMutation } from '@apollo/react-hooks';
import CSVReader from 'react-csv-reader';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';

const breadcrumbList = [
  { label: 'home.label', link: TicketingSystem.Dashboard },
  { label: 'uploadFile.label', link: TicketingSystem.Upload }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const UploadFile = ({ t }) => {
  const classes = useStyles();

  const [importData] = useMutation(IMPORT_DATA_TO_DB, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Customer File Imported successfully.');
      console.log('Completed finally dbdata');
      document.querySelector('.csv-input').value = '';
    }
  });

  const [importOrgRoleData] = useMutation(IMPORT_ORG_USER_ROLE_TO_DB, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('User Roles and Organisation Imported successfully.');
      console.log('Completed finally dborgdata');
      document.querySelector('.react-csv-input').value = '';
    }
  });

  const [importChildCustomerData] = useMutation(IMPORT_CHILD_CUSTOMER_TO_DB, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Child Customer Imported successfully.');
      console.log('Completed finally dborgdata');
      document.querySelector('.react-csv-input').value = '';
    }
  });

  const handleAddChildCustomer = (data, fileInfo) => {
    let finalData = JSON.stringify(data);
    return importChildCustomerData({
      variables: {
        data: finalData
      }
    });
  };

  const handleAddOrgUserRole = (data, fileInfo) => {
    let finalData = JSON.stringify(data);
    return importOrgRoleData({
      variables: {
        data: finalData
      }
    });
  };

  const handleForce = (data, fileInfo) => {
    let finalData = JSON.stringify(data);
    return importData({
      variables: {
        data: finalData
      }
    });
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
  };

  return (
    <Layout breadcrumbList={breadcrumbList}>
      <Header
        title={t('uploadFile.label')}
        subText={t('uploadToImport.label')}
      />
      <div className="filecontainer">
        <CSVReader
          cssClass="csv-input"
          label="Select CSV to import users to keycloak"
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
        />
      </div>
      <div className="filecontainer">
        <CSVReader
          cssClass="react-csv-input"
          label="Select CSV to import organisation and user role"
          onFileLoaded={handleAddOrgUserRole}
          parserOptions={papaparseOptions}
        />
      </div>
      <div className="filecontainer">
        <CSVReader
          cssClass="react-csv-input"
          label="Select CSV to Import child customer to DB"
          onFileLoaded={handleAddChildCustomer}
          parserOptions={papaparseOptions}
        />
      </div>
    </Layout>
  );
};
export default withTranslation()(UploadFile);
