import React, { useState, useEffect } from 'react';
import { get } from "lodash-es";
import Message from '../../../../components/Message';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import { USER_ROLES } from '../../../../utils/constants';
import CustomTabs from '../../../../components/CustomTabs';
import SectionPanel from './components/SectionPanel'
import DashboardPanel from './components/DashboardPanel';
import RiskPanel from './components/RiskPanel';
import InformationMangementPanel from './components/InformationMangementPanel';
import HrPanel from './components/HrPanel';
import EngineeringPanel from './components/EngineeringPanel';
import CashReportPanel from './components/CashReportPanel';
import SecurityPanel from './components/SecurityPanel';
import BotashPoc from './components/BotashPoc';

import {
    GET_SUBSCRIPTION_USERS,
} from '../../Ocr/queries';
import {
    GET_DEPARTMENT_LIST
} from './queries';

const MorupuleCoalService = ({
    t,
    data,
    countryCodes
}) => {

    const role = localStorage.getItem('role');
    const { ownerId } = data.getSubscriptionById;
    const [subscriptionUserData, setSubscriptionUserData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [childActiveIndex, setChildActiveIndex] = useState(0);

    const handleClickOfTabs = (val, newValue, childeValue) => {
        console.log("val: ", val)
        console.log("newValue: ", newValue)
        console.log("childeValue: ", childeValue)
        setActiveIndex(newValue);
        setChildActiveIndex(childeValue === undefined ? 0 : childeValue);
    }; 

    const {
        loading: departmentLoading,
        data: departmentList,
        error: departmentError,
        refetch: reloadDepartments
    } = useQuery(GET_DEPARTMENT_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            subscriptionId: data.getSubscriptionById.id
        }
    });

    let tabList = [];
    if (data) {
        tabList = [
            // {
            //     label: 'Section',
            //     children: (
            //         <>
            //             <SectionPanel
            //                 t={t}
            //                 data={data.getSubscriptionById}
            //                 countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
            //                 allowConfigure={true}
            //                 subscriptionUserData={subscriptionUserData}
            //                 handleClickOfTabs={handleClickOfTabs}
            //                 reloadDepartments={reloadDepartments}
            //                 departmentLoading={departmentLoading}
            //                 departmentList={departmentList}
            //                 departmentError={departmentError}
            //             />
            //         </>
            //     )
            // },
            // {
            //     label: "Dashboard",
            //     children: (
            //         <DashboardPanel
            //             t={t}
            //             subscriptionUserData={subscriptionUserData}
            //         />
            //     )
            // }, 
            {
                label: "Botash Poc",
                children: (
                    <BotashPoc
                    t={t}
                                        data={data.getSubscriptionById}
                                        countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                                        allowConfigure={true}
                                        departments={departmentList ? departmentList.getAllDepartments : []}
                                        subscriptionUserData={subscriptionUserData}
                                        activeIndex={childActiveIndex}
                    />
                )
            }
        ];

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "IM Panel",
        //         children: (
        //             <>
        //                 <InformationMangementPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     departments={departmentList ? departmentList.getAllDepartments : []}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "RiskPanel",
        //         children: (
        //             <>
        //                 <RiskPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "HR Panel",
        //         children: (
        //             <>
        //                 <HrPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     departments={departmentList ? departmentList.getAllDepartments : []}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "Engineering Panel",
        //         children: (
        //             <>
        //                 <EngineeringPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     departments={departmentList ? departmentList.getAllDepartments : []}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "CashReport Panel",
        //         children: (
        //             <>
        //                 <CashReportPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     departments={departmentList ? departmentList.getAllDepartments : []}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     !role.includes(USER_ROLES.EP_IT) &&
        //     !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
        //     !role.includes(USER_ROLES.IP_VNFADMIN)
        // ) {
        //     tabList.push({
        //         label: "Security Panel",
        //         children: (
        //             <>
        //                 <SecurityPanel
        //                     t={t}
        //                     data={data.getSubscriptionById}
        //                     countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
        //                     allowConfigure={true}
        //                     departments={departmentList ? departmentList.getAllDepartments : []}
        //                     subscriptionUserData={subscriptionUserData}
        //                     activeIndex={childActiveIndex}
        //                 />
        //             </>
        //         )
        //     });
        // }

        // if (
        //     role.includes(USER_ROLES.IP_COUNTRYADMIN) ||
        //     role.includes(USER_ROLES.IP_SUPERADMIN) ||
        //     role.includes(USER_ROLES.EP_ADMIN)
        // ) {
        //     tabList.push({
        //         label: t('settings.label'),
        //         children: (
        //             <>
        //                 <p>Settings</p>
        //             </>
        //         )
        //     });
        // }
    }

    const [getUserListOfSubscriptiontByOwnerId, { loading: userListLoading }] = useLazyQuery(GET_SUBSCRIPTION_USERS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const userData = get(data, 'getSubuscriptionUsers.data', []);
            if (userData && userData.length > 0) {
                setSubscriptionUserData(userData);
            }
            // setOcrFetchLoading(false);
        },
        onError: error => {
            Message.error(error);
            // setOcrFetchLoading(false);
        }
    });

    useEffect(() => {
        getUserListOfSubscriptiontByOwnerId({ variables: { groupId: ownerId } });
    }, [ownerId])

    return (
        <>
            <CustomTabs
                activeIndex={activeIndex}
                tabs={tabList}
                handleChangeActiveNotiTab={handleClickOfTabs}
            />
        </>
    );
};
export default withTranslation()(React.memo(MorupuleCoalService));
