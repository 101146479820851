import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL, CURRENCIES } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import { checkValidEmail, dateFormatterYYYYMMDD } from '../../../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateProjectManagementTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    CurrentUserName,
    customerList,
    customerListLoading,
    handleChangeCustomerList,
    handleUploadFiles,
    handleProjectAttachmentClick,
    handleViewCompleteDetailClick,
    selectedProjectTypeAndUid,
    showPreValueOfForm,
    isActionUpdate
}) {
    const classes = useStyles();
    const [changeControlForm, setChangeControlForm] = useState('');
    const [circuitId, setCircuitId] = useState('');
    const [equipmentsToBeDeployed, setEquipmentsToBeDeployed] = useState('');
    const [attachMannual, setAttachMannual] = useState('');
    const [configChangeForm, setConfigChangeForm] = useState('');
    const [pingResult, setPingResult] = useState('');
    const [mrc, setMrc] = useState('');
    const [oldMrc, setOldMrc] = useState('');
    const [nrc, setNrc] = useState('');
    const [frequencyOfBilling, setFrequencyOfBilling] = useState('');
    const [currency, setCurrency] = useState('');
    const [sendEmail, setSendEmail] = useState('');
    const [finance, setFinance] = useState('');
    const [comment, setComment] = useState('');
    const [jobCompletionCertificate, setJobCompletionCertificate] = useState('');
    const [faultInformationManagementMatrix, setFaultInformationManagementMatrix] = useState('');
    const [letterServiceDetails, setLetterServiceDetails] = useState('');
    const [billingStartDate, setBillingStartDate] = useState('');
    const [financeEmail, setFinanceEmail] = useState('');
    const [bitType, setBitType] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Occurrence, setOccurrence] = useState('');
    const [BillEndIndicator, setBillEndIndicator] = useState('');
    const [ChargePeriod, setChargePeriod] = useState('');
    const [Prorated, setProrated] = useState('');
    const [NetworkCode, setNetworkCode] = useState('');
    const [SupplierBaseCost, setSupplierBaseCost] = useState('');
    const [ChargeStartDate, setChargeStartDate] = useState('');
    const [ChargeEndDate, setChargeEndDate] = useState('');
    const [documentDeleteLoading, setDocumentDeleteLoading] = useState(false);
    const isNumber = (str) => /^[0-9.]*$/.test(str);

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setChangeControlForm('');
            setCircuitId('');
            setEquipmentsToBeDeployed('');
            setAttachMannual('');
            setConfigChangeForm('');
            setPingResult('');
            setMrc('');
            setOldMrc('');
            setNrc('');
            setFrequencyOfBilling('');
            setCurrency('');
            setSendEmail('');
            setFinance('');
            setComment('');
            setJobCompletionCertificate('');
            setFaultInformationManagementMatrix('');
            setLetterServiceDetails('');
            setBillingStartDate('');
            setFinanceEmail('');
            setBitType('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
            setEndDate('');
            setOccurrence('');
            setBillEndIndicator('');
            setChargePeriod('');
            setProrated('');
            setNetworkCode('');
            setSupplierBaseCost('');
            setChargeStartDate('');
            setChargeEndDate('');
            setDocumentDeleteLoading(false);
        }
        if (!showPreValueOfForm) {
            setMrc(selectedProjectTypeAndUid.total_mrc);
            setNrc(selectedProjectTypeAndUid.total_nrc);
        } else {
            if (preValuesOfForm && preValuesOfForm.project_info) {
                setMrc(preValuesOfForm.project_info.total_mrc);
                setNrc(preValuesOfForm.project_info.total_nrc);
            }
        }
        if (isActionUpdate === true) {
            setFinance(preValuesOfForm.finance);
            setBitType(preValuesOfForm.bit_type);
            if (preValuesOfForm.finance_detail) {
                setMrc(preValuesOfForm.finance_detail.mrc);
                setOldMrc(preValuesOfForm.finance_detail.old_mrc);
                setNrc(preValuesOfForm.finance_detail.nrc);
                setFrequencyOfBilling(preValuesOfForm.finance_detail.freq_of_billing);
                setCurrency(preValuesOfForm.finance_detail.currency);
            }
            setSendEmail(preValuesOfForm.send_email);
            if (preValuesOfForm.billing_start_date != "" && preValuesOfForm.billing_start_date != undefined && preValuesOfForm.billing_start_date != null) {
                setBillingStartDate(new Date(preValuesOfForm.billing_start_date));
            }
            setEndDate(preValuesOfForm.end_date);
            setOccurrence(preValuesOfForm.occurrence);
            setBillEndIndicator(preValuesOfForm.billend_indicator);
            setChargePeriod(preValuesOfForm.charge_period);
            setProrated(preValuesOfForm.prorated);
            setNetworkCode(preValuesOfForm.network_code);
            setSupplierBaseCost(preValuesOfForm.supplier_base_cost);
            setComment(preValuesOfForm.comment);
            setNextTeamMemberEmail(preValuesOfForm.next_team_member_email);
            setOnHold(preValuesOfForm.project_info && preValuesOfForm.project_info.status ? preValuesOfForm.project_info.status : '');
            setCategory(preValuesOfForm.project_info && preValuesOfForm.project_info.category ? preValuesOfForm.project_info.category : '');
        }
    }, [modalState, CurrentUserName, showPreValueOfForm, preValuesOfForm, isActionUpdate]);

    React.useEffect(() => { }, [finance, bitType, mrc, oldMrc, nrc, frequencyOfBilling, currency, sendEmail, billingStartDate, EndDate,
        Occurrence, BillEndIndicator, ChargePeriod, Prorated, NetworkCode, SupplierBaseCost, comment, onHold, category, nextTeamMemberEmail, preValuesOfForm])

    const OccurrenceList = [
        {
            id: '1',
            name: '1'
        },
        {
            id: '2',
            name: '2'
        },
        {
            id: '3',
            name: '3'
        },
    ]
    const BillEndIndicatorList = [
        {
            id: '1',
            name: '1'
        },
        {
            id: '2',
            name: '2'
        }
    ]
    const ChargePeriodList = [
        {
            id: 'M',
            name: 'M'
        },
        {
            id: 'Y',
            name: 'Y'
        },
        {
            id: 'Q',
            name: 'Q'
        },
    ]
    const ProratedList = [
        {
            id: '0',
            name: '0'
        },
        {
            id: '1',
            name: '1'
        },
    ]
    const handleChangeSupplierBaseCost = (evt) => {
        const { value } = evt.target;
        if (isNumber(value)) {
            let convertNumber = parseFloat(value).toFixed(2);
            // console.log("typeof number : ", convertNumber);
            // let res = parseFloat(Number(value).toFixed(2));
            // console.log("typeof res : ", res);
            setSupplierBaseCost(convertNumber);
        }
    }
    const bitTypeList = [
        {
            id: t("fixed.label"),
            name: t("fixed.label")
        },
        {
            id: t("variable.label"),
            name: t("variable.label")
        },
        {
            id: t("percentageOfAccount.label"),
            name: t("percentageOfAccount.label")
        }
    ]
    const onHoldList = [
        {
            id: t("project.label") + ' ' + t("inProgress.label"),
            name: t("project.label") + ' ' + t("inProgress.label")
        },
        {
            id: t("project.label") + ' ' + t("onHold.label"),
            name: t("project.label") + ' ' + t("onHold.label")
        },
        {
            id: t("project.label") + ' ' + t("completed.label"),
            name: t("project.label") + ' ' + t("completed.label")
        },
        {
            id: t("project.label") + ' ' + t("canceled.label"),
            name: t("project.label") + ' ' + t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    const handleChangeStartDate = (evt) => {
        setBillingStartDate(evt);
        Date.prototype.addDays = function (days) {
            var date = new Date(evt);
            date.setDate(date.getDate() + days);
            let convertDate = moment(date).format("YYYY-MM-DD")
            return convertDate;
        }
        var date = new Date();
        setEndDate(date.addDays(364))
    }
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{isActionUpdate === true ? t('update.label') : t('create.label')} {t('projectManagementTeamSection.label')}</DialogTitle>
            {(showPreValueOfForm === true) ? <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : (preValuesOfForm.case_number ? preValuesOfForm.case_number : 'N/A')}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.customer_name} / {preValuesOfForm.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle> : <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>}
            <DialogContent>
                <Grid container spacing={2}>
                    {(showPreValueOfForm === true) &&
                        <React.Fragment>
                            {/* <Grid item xs={6}>
                                <Typography variant="subtitle2">{t('ViewCompleteDetails.label')}</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Button variant={'outlined'}
                                        color={'primary'}
                                        style={{ width: '100%' }}
                                        onClick={handleViewCompleteDetailClick}>{t('clickHereToViewCompleteDetails.label')}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">{t('ViewAttachedDocuments.label')}</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Button variant={'outlined'}
                                        disabled={preValuesOfForm.previous_docs && preValuesOfForm.previous_docs.length == 0 ? true : false}
                                        color={'primary'}
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            handleProjectAttachmentClick(preValuesOfForm.previous_docs);
                                        }}>{t('clickHereToViewFiles.label')}</Button>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">{t('changeControlForm.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Button variant={'outlined'}
                                        disabled={!preValuesOfForm.change_control_form ? true : false}
                                        color={'primary'}
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            if (preValuesOfForm.change_control_form != undefined) {
                                                window.open(preValuesOfForm.change_control_form);
                                            }
                                        }}>{t('clickHereToViewFiles.label')}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">{t('installationMannual.label')}</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Button variant={'outlined'}
                                        disabled={!preValuesOfForm.attach_manual ? true : false}
                                        color={'primary'}
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            if (preValuesOfForm.attach_manual != undefined) {
                                                window.open(preValuesOfForm.attach_manual);
                                            }
                                        }}>{t('clickHereToViewFiles.label')}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{t('configChangeForm.label')}*</Typography>
                                <Grid
                                    style={{
                                        ...marginGenerator('mt-5')
                                    }}>
                                    <Button variant={'outlined'}
                                        disabled={!preValuesOfForm.config_change_form ? true : false}
                                        color={'primary'}
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            if (preValuesOfForm.config_change_form != undefined) {
                                                window.open(preValuesOfForm.config_change_form);
                                            }
                                        }}>{t('clickHereToViewFiles.label')}</Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('circuitId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.circuit_id ? preValuesOfForm.circuit_id : circuitId}
                                type={'text'}
                                disabled={(showPreValueOfForm && preValuesOfForm.circuit_id) ? true : false}
                                placeholder={t('circuitId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setCircuitId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : equipmentsToBeDeployed}
                                type={'text'}
                                placeholder={t('equipmentsToBeDeployed.label')}
                                style={{ width: '100%' }}
                                disabled={showPreValueOfForm && preValuesOfForm.equipments_to_be_deployed ? true : false}
                                color={'secondary'}
                                onChange={evt => setEquipmentsToBeDeployed(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('pingResult.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={preValuesOfForm.ping_result ? preValuesOfForm.ping_result : pingResult}
                                type={'text'}
                                placeholder={t('pingResult.label') + '*'}
                                style={{ width: '100%' }}
                                disabled={showPreValueOfForm && preValuesOfForm.ping_result ? true : false}
                                color={'secondary'}
                                onChange={evt => setPingResult(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('jobCompletionCertificate.label')}*</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={((isActionUpdate === true) && (preValuesOfForm.job_completion_certificate != "")) ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setJobCompletionCertificate(file);
                                    } else {
                                        setJobCompletionCertificate('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{
                            ...marginGenerator('mt-5')
                        }}>
                        {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                        {(isActionUpdate === true && preValuesOfForm.job_completion_certificate) ?
                            <Typography variant={'body2'}> <Button variant={'outlined'}
                                disabled={!preValuesOfForm.job_completion_certificate ? true : false}
                                style={{ marginBottom: 5 }}
                                onClick={() => {
                                    if (preValuesOfForm.job_completion_certificate != undefined) {
                                        window.open(preValuesOfForm.job_completion_certificate);
                                    }
                                }}><InsertDriveFileIcon></InsertDriveFileIcon> File 1</Button> <IconButton onClick={() => {
                                    setDocumentDeleteLoading(true);
                                    preValuesOfForm.job_completion_certificate = '';
                                    setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
                                }} aria-label="delete">
                                    <DeleteIcon color="secondary" /></IconButton>
                            </Typography>
                            : ''}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('faultInformationManagementMatrix.label')}</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                disabled={((isActionUpdate === true) && (preValuesOfForm.fault_information_management_matrix != "")) ? true : false}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setFaultInformationManagementMatrix(file);
                                    } else {
                                        setFaultInformationManagementMatrix('');
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.fault_information_management_matrix) ?
                                <Typography variant={'body2'}> <Button variant={'outlined'}
                                    disabled={!preValuesOfForm.fault_information_management_matrix ? true : false}
                                    style={{ marginBottom: 5 }}
                                    onClick={() => {
                                        if (preValuesOfForm.fault_information_management_matrix != undefined) {
                                            window.open(preValuesOfForm.fault_information_management_matrix);
                                        }
                                    }}><InsertDriveFileIcon></InsertDriveFileIcon> File 1</Button> <IconButton onClick={() => {
                                        setDocumentDeleteLoading(true);
                                        preValuesOfForm.fault_information_management_matrix = '';
                                        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
                                    }} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                </Typography>
                                : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('letterServiceDetails.label')}</Typography>
                        <Grid style={{
                            ...marginGenerator('mt-5')
                        }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                disabled={((isActionUpdate === true) && (preValuesOfForm.letter_service_details != "")) ? true : false}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setLetterServiceDetails(file);
                                    } else {
                                        setLetterServiceDetails('');
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {(isActionUpdate === true && documentDeleteLoading === true) && <CircularProgress size={14} />}
                            {(isActionUpdate === true && preValuesOfForm.letter_service_details) ?
                                <Typography variant={'body2'}> <Button variant={'outlined'}
                                    disabled={!preValuesOfForm.letter_service_details ? true : false}
                                    style={{ marginBottom: 5 }}
                                    onClick={() => {
                                        if (preValuesOfForm.letter_service_details != undefined) {
                                            window.open(preValuesOfForm.letter_service_details);
                                        }
                                    }}><InsertDriveFileIcon></InsertDriveFileIcon> File 1</Button> <IconButton onClick={() => {
                                        setDocumentDeleteLoading(true);
                                        preValuesOfForm.letter_service_details = '';
                                        setTimeout(() => { setDocumentDeleteLoading(false); }, 1000);
                                    }} aria-label="delete">
                                        <DeleteIcon color="secondary" /></IconButton>
                                </Typography>
                                : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('financeTeam.label')} *`}
                                val={finance}
                                handleChange={evt => {
                                    if (evt.target.value) {
                                        setFinance(evt.target.value);
                                        for (let i = 0; i < allUserList.length; i++) {
                                            if (allUserList[i].name == evt.target.value) {
                                                setFinanceEmail(allUserList[i].email);
                                                setNextTeamMemberEmail(allUserList[i].email);
                                                break;
                                            }
                                        }
                                    }
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={allUserList ? allUserList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('bit.label') + ' ' + t('type.label')}`}
                                val={bitType}
                                handleChange={evt => {
                                    setBitType(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={bitTypeList ? bitTypeList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('inputFinanceDetails.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={mrc}
                                disabled={!showPreValueOfForm ? true : false}
                                type={'text'}
                                placeholder={t('mrc.label')}
                                style={{ width: '48%', marginRight: '2%', marginBottom: '10px' }}
                                color={'secondary'}
                                onChange={evt => setMrc(evt.target.value)}
                            />
                            <Input
                                value={oldMrc}
                                type={'text'}
                                placeholder={t('old.label') + ' ' + t('mrc.label')}
                                style={{ width: '50%' }}
                                color={'secondary'}
                                onChange={evt => setOldMrc(evt.target.value)}
                            />
                            <Input
                                value={nrc}
                                disabled={!showPreValueOfForm ? true : false}
                                type={'text'}
                                placeholder={t('nrc.label')}
                                style={{ width: '48%', marginRight: '2%', marginBottom: '10px' }}
                                color={'secondary'}
                                onChange={evt => setNrc(evt.target.value)}
                            />
                            <Input
                                value={frequencyOfBilling}
                                type={'text'}
                                placeholder={t('monthly.label')}
                                style={{ width: '50%' }}
                                color={'secondary'}
                                onChange={evt => setFrequencyOfBilling(evt.target.value)}
                            />
                            <OutlinedSelect
                                label={`${t('currency.label')} *`}
                                // val={currency}
                                handleChange={evt => {
                                    setCurrency(evt.target.value.name);
                                }}
                                color={'secondary'} selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '48%', marginRight: '2%' }}
                                options={CURRENCIES ? CURRENCIES : []}
                            />
                            {isActionUpdate === true && <Button variant={'outlined'}
                                color={'primary'}
                                style={{ width: '48%', marginRight: '2%' }}
                               >{t('currency.label')}: {currency}</Button>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Typography variant="subtitle2">{t('customerName.label')}</Typography> */}
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {/* <Autocomplete
                                id={`place-select-${idx}`}
                                options={selectePlaceList}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    handleCameraChange(newValue)
                                }}
                                style={{ border: '1px solid #ebebeb' }}
                                renderInput={(params) => <TextField {...params} label={`${t('place.label')} *`} variant="outlined" />}
                            /> */}
                            <Autocomplete
                                // id="asynchronous-demo"
                                style={{ width: "100%", marginTop: 24, border: "1px solid #ebebeb" }}
                                // name="userId"
                                onChange={(event, newValue) => {
                                    if (newValue.email != undefined) {
                                        setSendEmail(newValue.email);
                                    } else {
                                        setSendEmail("");
                                    }
                                }}
                                // value={sendEmail}
                                getOptionSelected={(option, value) => option.customer_name === value}
                                getOptionLabel={(option) => `${option.customer_name}`}
                                options={customerList}
                                loading={customerListLoading}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue != "") {
                                        handleChangeCustomerList(newInputValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        autoFocus={false}
                                        label={t("customerName.label")}
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {customerListLoading ? <CircularProgress color="inherit" size={20} /> : ""}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                            // type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('sendEmail.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={sendEmail}
                                type={'text'}
                                placeholder={t('sendEmail.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSendEmail(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('osla.label') + ' ' + t('category.label')}`}
                                val={category}
                                handleChange={evt => {
                                    setCategory(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={categoryList ? categoryList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                label={`${t('project.label') + ' ' + t('status.label')}`}
                                val={onHold}
                                handleChange={evt => {
                                    setOnHold(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={onHoldList ? onHoldList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('previousComment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            {preValuesOfForm.previous_comment && preValuesOfForm.previous_comment.length ?
                                JSON.parse(preValuesOfForm.previous_comment).map((item, index) => (
                                    <Typography variant={'body2'}>{item.name} : {item.comment}</Typography>
                                )) : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('comment.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={comment}
                                type={'text'}
                                placeholder={t('comment.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setComment(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('billing.label') + ' ' + t('startDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={billingStartDate}
                                    // selectsStart
                                    onChange={date => handleChangeStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                // startDate={billingStartDate}
                                // endDate={EndDate}
                                />
                            </div>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // label={`${t('contract_date.label')} *`}
                                    format="MM/dd/yyyy"
                                    value={billingStartDate}
                                    onChange={value => setBillingStartDate(value)}
                                    style={{ width: '100%', marginRight: 0 }}
                                />
                            </MuiPickersUtilsProvider> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('endDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={EndDate} disabled
                                type={'text'}
                                placeholder={t('endDate.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                            // onChange={evt => setNetworkCode(evt.target.value)}
                            />
                            {/* <div className="datePicker">
                                <DatePicker
                                    selected={EndDate}
                                    selectsEnd
                                    onChange={date => setEndDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    endDate={EndDate}
                                    minDate={billingStartDate}
                                />
                            </div> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={Occurrence}
                                label={`${t('occurrence.label')}`}
                                handleChange={evt => {
                                    setOccurrence(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={OccurrenceList ? OccurrenceList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={BillEndIndicator}
                                label={`${t('billEndIndicator.label') + ' *'}`}
                                handleChange={evt => {
                                    setBillEndIndicator(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={BillEndIndicatorList ? BillEndIndicatorList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={ChargePeriod}
                                label={`${t('chargePeriod.label')}`}
                                handleChange={evt => {
                                    setChargePeriod(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={ChargePeriodList ? ChargePeriodList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <OutlinedSelect
                                val={Prorated}
                                label={`${t('prorated.label')}`}
                                handleChange={evt => {
                                    setProrated(evt.target.value);
                                }}
                                selectStyle={{ ...paddingGenerator('p-8') }}
                                styleOverrides={{ width: '100%', marginBottom: 10 }}
                                options={ProratedList ? ProratedList : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('networkCode.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={NetworkCode}
                                type={'text'}
                                placeholder={t('networkCode.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setNetworkCode(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('supplierBaseCost.label')}</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={SupplierBaseCost}
                                type={'text'}
                                placeholder={t('supplierBaseCost.label')}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => handleChangeSupplierBaseCost(evt)}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('charge.label')} {t('startDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={ChargeStartDate}
                                    selectsStart
                                    onChange={date => setChargeStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    startDate={ChargeStartDate}
                                    endDate={ChargeEndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('charge.label')} {t('endDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={ChargeEndDate}
                                    selectsEnd
                                    onChange={date => setChargeEndDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Please select a date"
                                    endDate={ChargeEndDate}
                                    minDate={ChargeStartDate}
                                />
                            </div>
                        </Grid>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            // if (preValuesOfForm.circuit_id != '') {
                            let convertContactDate = "";
                            let convertMrc = mrc;
                            let convertNrc = nrc;
                            if (typeof(mrc) == 'number') {
                                convertMrc = String(mrc);
                            }
                            if (typeof(nrc) == 'number') {
                                convertNrc = String(nrc);
                            }
                            if (!finance || !oldMrc || !frequencyOfBilling || !currency || !billingStartDate || !EndDate || !BillEndIndicator) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (!convertMrc || !convertNrc) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (isActionUpdate === true) {
                                if (jobCompletionCertificate == "" && preValuesOfForm.job_completion_certificate == "") {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            } else {
                                if (!jobCompletionCertificate) {
                                    Message.error(REQUIRED_ERROR);
                                    return null;
                                }
                            }
                            if (sendEmail != '' && !checkValidEmail(sendEmail)) {
                                Message.error(INVALID_EMAIL);
                                return null;
                            }
                            let getPreviousComment = ((showPreValueOfForm === true) ? preValuesOfForm.previous_comment : '') ? JSON.parse(preValuesOfForm.previous_comment) : [];
                            if (comment != '') {
                                if (isActionUpdate === true && preValuesOfForm.comment == comment) {
                                } else {
                                    getPreviousComment.push({ name: CurrentUserName, comment: comment });
                                }
                            }
                            let previous_comment = Object.entries(getPreviousComment).map(e => e[1]);
                            let getJobCompletionCertificate = "";
                            if (jobCompletionCertificate != "" && jobCompletionCertificate != null) {
                                let getJobCompletionCertificateUrl = await handleUploadFiles(jobCompletionCertificate, (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid, 'project_management_team_section');
                                if (getJobCompletionCertificateUrl.data && getJobCompletionCertificateUrl.data.allUploadFile && getJobCompletionCertificateUrl.data.allUploadFile.url) {
                                    getJobCompletionCertificate = getJobCompletionCertificateUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            let getFaultInformationManagementMatrix = "";
                            if (faultInformationManagementMatrix != "" && faultInformationManagementMatrix != null) {
                                let getFaultInformationManagementMatrixUrl = await handleUploadFiles(faultInformationManagementMatrix, (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid, 'project_management_team_section');
                                if (getFaultInformationManagementMatrixUrl.data && getFaultInformationManagementMatrixUrl.data.allUploadFile && getFaultInformationManagementMatrixUrl.data.allUploadFile.url) {
                                    getFaultInformationManagementMatrix = getFaultInformationManagementMatrixUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            let getLetterServiceDetails = "";
                            if (letterServiceDetails != "" && letterServiceDetails != null) {
                                let getLetterServiceDetailsUrl = await handleUploadFiles(letterServiceDetails, (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid, 'project_management_team_section');
                                if (getLetterServiceDetailsUrl.data && getLetterServiceDetailsUrl.data.allUploadFile && getLetterServiceDetailsUrl.data.allUploadFile.url) {
                                    getLetterServiceDetails = getLetterServiceDetailsUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            if (isActionUpdate === true) {
                                // merge document
                                if (getJobCompletionCertificate === "") {
                                    getJobCompletionCertificate = preValuesOfForm.job_completion_certificate;
                                }
                                if (getFaultInformationManagementMatrix === "") {
                                    getFaultInformationManagementMatrix = preValuesOfForm.fault_information_management_matrix;
                                }
                                if (getLetterServiceDetails === "") {
                                    getLetterServiceDetails = preValuesOfForm.letter_service_details;
                                }
                            }
                            handleSubmitClick({
                                formUid: isActionUpdate ? preValuesOfForm.uid : '',
                                changeControlForm: (showPreValueOfForm === true) ? preValuesOfForm.change_control_form : '',
                                circuitId: (showPreValueOfForm === true) ? preValuesOfForm.circuit_id : circuitId,
                                attachMannual: (showPreValueOfForm === true) ? preValuesOfForm.attach_manual : '',
                                configChangeForm: (showPreValueOfForm === true) ? preValuesOfForm.config_change_form : '',
                                pingResult: (showPreValueOfForm === true) ? preValuesOfForm.ping_result : pingResult,
                                jobCompletionCertificate: getJobCompletionCertificate,
                                faultInformationManagementMatrix: getFaultInformationManagementMatrix,
                                letterServiceDetails: getLetterServiceDetails,
                                billingStartDate: moment(billingStartDate).format("YYYY-MM-DD"),
                                comment: comment,
                                finance: finance,
                                finance_detail: {
                                    mrc: convertMrc,
                                    old_mrc: oldMrc,
                                    nrc: convertNrc,
                                    freq_of_billing: frequencyOfBilling,
                                    currency: currency,
                                },
                                sendEmail: sendEmail,
                                formName: 'ProjectManagementForm',
                                project_uid: (showPreValueOfForm === true) ? preValuesOfForm.project : selectedProjectTypeAndUid.uid,
                                previous_comment: JSON.stringify(previous_comment),
                                equipments_to_be_deployed: preValuesOfForm.equipments_to_be_deployed ? preValuesOfForm.equipments_to_be_deployed : equipmentsToBeDeployed,
                                auto_generated_project_id: preValuesOfForm.auto_generated_project_id ? preValuesOfForm.auto_generated_project_id : '',
                                finance_email: financeEmail,
                                bit_type: bitType,
                                next_team_member_email: nextTeamMemberEmail,
                                category: category,
                                on_hold: onHold,
                                end_date: EndDate,
                                occurrence: Occurrence,
                                billend_indicator: BillEndIndicator,
                                charge_period: ChargePeriod,
                                prorated: Prorated,
                                network_code: NetworkCode,
                                supplier_base_cost: SupplierBaseCost,
                                charge_start_date: moment().format("YYYY-MM-DD"),
                                charge_end_date: moment().format("YYYY-MM-DD"),
                            });


                            // } else {
                            // if (!finance || !changeControlForm || !circuitId || !attachMannual || !configChangeForm || !pingResult || !mrc || !oldMrc || !nrc || !frequencyOfBilling || !currency || !sendEmail) {
                            //     Message.error(REQUIRED_ERROR);
                            //     return null;
                            // }
                            // if (!checkValidEmail(sendEmail)) {
                            //     Message.error(INVALID_EMAIL);
                            //     return null;
                            // }
                            // handleSubmitClick({
                            //     changeControlForm: changeControlForm,
                            //     circuitId: circuitId,
                            //     attachMannual: attachMannual,
                            //     configChangeForm: configChangeForm,
                            //     pingResult: pingResult,
                            //     comment: comment,
                            //     finance: finance,
                            //     finance_detail: {
                            //         mrc: mrc,
                            //         old_mrc: oldMrc,
                            //         nrc: nrc,
                            //         freq_of_billing: frequencyOfBilling,
                            //         currency: currency,
                            //     },
                            //     sendEmail: sendEmail,
                            //     formName: 'ProjectManagementForm'
                            // });
                            // }
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateProjectManagementTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateProjectManagementTeamModal);
