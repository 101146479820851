import React, {useState, useEffect} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './InvoiceTableFooter';
import InvoiceSubscriptionHeader from './InvoiceSubscriptionHeader';
import InvoiceSubscriptionRow from './InvoiceSubscriptionRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  }
});

const InvoiceItemsTable = ({invoiceCompanyDetails, t}) => {

  // const [subscriptionState, setSubscriptionState] = useState([]);
  // const [addonsState, setAddonsState] = useState([]);
  
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader row={{title: "Line", head1: "Circuit ID", head2: "Site", head3: "Description", head4: "From", head5: 'To', head6: 'Units', head7: 'Unit Price', head8: 'Total '+invoiceCompanyDetails.CUR}} />
      <InvoiceTableRow item={invoiceCompanyDetails} />
    </View>
  )
}


export default InvoiceItemsTable