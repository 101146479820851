import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { renderText } from '../../displayComponent';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { USER_ROLES } from '../../../../utils/constants';
import { AddRegion } from '../../../../services/regionService';
import Message from '../../../../components/Message';
import { useAuth } from '../../../../contexts/AuthContext';

const CreateRegion = () => {
  const history = useHistory();
  const { loggedInUserRoles } = useAuth();

  const initialValues = { regionName: '', regionCode: '' };
  const validationSchema = yup.object().shape({
    regionName: yup.string().required('Enter a region name'),
    regionCode: yup.string().required('Enter a region code')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit } = methods;

  const onSubmit = async data => {
    const payload = {
      region_name: data?.regionName,
      region_code: data?.regionCode
    };
    const res = await AddRegion(payload);
    if (res?.status === 200) {
      history.push('/fabricService/regions');
    } else {
      if (res?.response?.data?.message) {
        Message.error(res?.response?.data?.message);
      } else if (res?.message) {
        Message.error(res?.message);
      } else {
        Message.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (loggedInUserRoles[0] !== USER_ROLES.IP_SUPERADMIN) {
      history.push('/fabricService');
    }
  }, [loggedInUserRoles, history]);

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>{renderText({ label: 'Create Region' })}</Box>
            </Grid>{' '}
          </Paper>
        </Grid>

        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Region Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="regionName"
                        placeholder="Enter Region Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Region Code
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="regionCode"
                        placeholder="Enter Region Code"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => history.push('/fabricService/regions')}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateRegion;
