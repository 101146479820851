import { gql } from 'apollo-boost';

/*
  Get all service tickets
 */

export const GET_CYBER_SECURITY_LIST = gql`
  query CyberSecurity(
    $status: String
    $startDate: String
    $page: Int
    $endDate: String
  ) {
    cyberSecurity(
      status: $status
      startDate: $startDate
      page: $page
      endDate: $endDate
    ) {
        data
        total
    }
  }
`;

export const GET_CYBER_SECURITY_BY_ID = gql`
  query CyberSecurity(
    $id : String
  ){
    getCyberSecurityById(
      id: $id
    ){
      id
      fullName
      address
      jobTitle
      requestId
      email
      phone
      incidentTime
      incidentType
      incidentDetected
      notified
      notifiedDesc
      assignee
      containment
      containmentDesc
      impactedService
      impactedServiceDesc
      attackVendor
      attackVendorDesc
      infoImpact
      infoImpactDesc
      otherImpact
      otherImpactDesc
      priority
      status
      createdAt
      createdBy
      createdByName
      updatedAt
      updatedByName
    }
  }
`;