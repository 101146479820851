import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Popup from '../../Popup';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import EditIpSecPopup from './EditIpSecPopup';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import {
  deleteIpsec,
  getIpsecById,
  getIpsecListByFcrId,
  getIpSecLogDetails
} from '../../../../services/fcrService';
import { useToasts } from 'react-toast-notifications';
import LogsPopup from './LogsPopup';
import moment from 'moment';
import IpsecChartPopup from './IpsecChartPopup';

const ViewAllIpSecPopup = ({ viewIpSec, setViewIpSec, fcrId, classes }) => {
  const { addToast } = useToasts();

  const [editIpSec, setEditIpSec] = useState(false);
  const [ipSecList, setIpSecList] = useState([]);
  const [ipSecData, setIpSecData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [svti, setSvti] = useState('');
  const [dateFilter, setDateFilter] = useState(15);
  const [chartData, setChartData] = useState([]);
  const [matricType, setMatricType] = useState('Mbps');

  const [openLogsPopup, setOpenLogsPopup] = useState(false);
  const [openChartsPopup, setOpenChartsPopup] = useState(false);

  const EditIpsec = async (id, e) => {
    e.preventDefault();
    const response = await getIpsecById(id);
    setIpSecData(response?.data?.result);
    setEditIpSec(true);
  };

  const DeleteIpsec = async (id, e) => {
    e.preventDefault();
    const res = await deleteIpsec(id);
    if (res?.data?.statusCode === 200) {
      addToast('Ipsec Deleting', { appearance: 'success' });
    }
    if (res?.response?.status === 422 || res?.response?.status === 500) {
      addToast(res?.response?.data?.message, { appearance: 'error' });
    }
    if (res?.response?.status === 404) {
      addToast('Something went wrong.', { appearance: 'error' });
    }
    await GetIpsecList();
  };

  const showLogs = async (id, e) => {
    setOpenLogsPopup(true);
    e.preventDefault();
    try {
      setLoading(true);
      const res = await getIpSecLogDetails(id);
      if (res && res.status === 200) {
        setLogsData(res?.data?.result);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const GetIpsecList = async () => {
    const res = await getIpsecListByFcrId(fcrId);
    setIpSecList(res?.data?.result);
  };

  const Stats = async (id, e) => {
    e.preventDefault();
    const res = await getIpsecById(id);
    if (res && res.status === 200) {
      setSvti(res?.data?.result?.svti);
      setOpenChartsPopup(true);
    }
  };

  useEffect(() => {
    function getData() {
      let query = '';
      var date = new Date();

      if (dateFilter === 15 || dateFilter === 60) {
        date.setDate(date.getDate());
        query = date;
      }

      const testURL = `https://54dc60343a3b46d3a26840aa77dc46d3.europe-west2.gcp.elastic-cloud.com:9243/fabric-snmp-${moment(
        query
      ).format('YYYY')}.${moment(query).format('MM')}/_search`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'ApiKey QnVuWnRZSUJ0am1vUGpUUUxSTDQ6cGZabnhkYWZTR3lISWFxVzBRV1RPdw=='
        },
        body: JSON.stringify({
          size: dateFilter,
          query: {
            bool: {
              filter: [
                {
                  range: {
                    '@timestamp': {
                      gte: moment
                        .utc(date)
                        .subtract(dateFilter, 'm')
                        .format(),
                      lt: moment.utc(date).format()
                    }
                  }
                }
              ],
              must: [
                {
                  match_phrase: {
                    'interfaces.ifDescr': `svti${String(svti)}`
                  }
                }
              ]
            }
          },
          sort: [{ '@timestamp': 'asc' }]
        })
      };

      setLoading(true);
      fetch(testURL, requestOptions)
        .then(response => response.json())
        .then(async data => {
          (await data?.hits?.hits?.length) > 0
            ? setChartData(data?.hits?.hits)
            : setChartData([]);
          setLoading(false);
        })
        .catch(function(error) {
          console.log({ error });
          setLoading(false);
        });
    }
    getData();
  }, [matricType, dateFilter, svti, openChartsPopup]);

  useEffect(() => {
    GetIpsecList();
    const intervalCall = setInterval(() => {
      GetIpsecList();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [editIpSec, deleteIpsec]);

  return (
    <>
      <Popup openPopup={viewIpSec} setOpenPopup={setViewIpSec} width="md">
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TableContainer component={Paper} style={{ height: '400px' }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead style={{ backgroundColor: '#5a1f2a' }}>
                    <TableRow style={{ backgroundColor: '#5a1f2a' }}>
                      <TableCell
                        align="center"
                        style={{
                          color: 'white',
                          // paddingLeft: '10px',
                          backgroundColor: '#5a1f2a'
                        }}>
                        Client Site Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}>
                        IP Address (Client)
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}>
                        Rate Limit
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="center">
                        Shared Secret
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="center">
                        Local GW Subnet
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="center">
                        Remote Client Subnet
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="center">
                        Operational State
                      </TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#5a1f2a'
                        }}
                        align="center">
                        Options
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ipSecList.length !== 0 ? (
                      ipSecList.map((item, index) => (
                        <TableRow key={item._id}>
                          {/* Client Site Name */}
                          <TableCell component="th" scope="row" align="center">
                            {item?.clientSiteName}
                          </TableCell>
                          {/* IP Address (Client) */}
                          <TableCell component="th" scope="row" align="center">
                            {item?.ipAddressOfClientSite}
                          </TableCell>
                          {/* Rate Limit */}
                          <TableCell align="center">{item?.speed}</TableCell>
                          {/* Shared Secret */}
                          <TableCell align="center">
                            {item?.sharedSecert}
                          </TableCell>
                          {/* Local GW Subnet */}
                          <TableCell align="center">
                            {item?.localSubnet}
                          </TableCell>
                          {/* Remote Client Subnet */}
                          <TableCell align="center">
                            {item?.remoteSubnet}
                          </TableCell>
                          {/* Operational State */}
                          <TableCell align="center">
                            {item?.oper_status}
                          </TableCell>
                          {/* Options */}
                          <TableCell align="center">
                            <Tooltip
                              title={
                                item?.oper_status === 'Error' ? '' : 'Edit'
                              }
                              placement="bottom"
                              arrow
                              backgroundColor="#5a1f2a">
                              {localStorage
                                .getItem('role')
                                .split(',')[0]
                                .includes('fabric_readonly') === false &&
                              item?.oper_status !== 'Error' ? (
                                <SettingsOutlinedIcon
                                  className="icons"
                                  onClick={e => EditIpsec(item._id, e)}
                                  style={{ color: '#5a1f2a' }}
                                />
                              ) : (
                                <SettingsOutlinedIcon
                                  style={{ color: 'grey' }}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              title="Stats"
                              placement="bottom"
                              arrow
                              backgroundColor="#5a1f2a">
                              <svg
                                onClick={e => Stats(item?._id, e)}
                                className="icons"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 459.75 459.75"
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  color: '#5a1f2a'
                                }}>
                                <g>
                                  <path
                                    d="M447.652,304.13h-40.138c-6.681,0-12.097,5.416-12.097,12.097v95.805c0,6.681,5.416,12.098,12.097,12.098h40.138
                                      		c6.681,0,12.098-5.416,12.098-12.098v-95.805C459.75,309.546,454.334,304.13,447.652,304.13z"
                                    fill="#5a1f2a"
                                  />
                                  &nbsp;
                                  <path
                                    d="M348.798,258.13H308.66c-6.681,0-12.098,5.416-12.098,12.097v141.805c0,6.681,5.416,12.098,12.098,12.098h40.138
                                      		c6.681,0,12.097-5.416,12.097-12.098V270.228C360.896,263.546,355.48,258.13,348.798,258.13z"
                                    fill="#5a1f2a"
                                  />
                                  &nbsp;
                                  <path
                                    d="M151.09,304.13h-40.138c-6.681,0-12.097,5.416-12.097,12.097v95.805c0,6.681,5.416,12.098,12.097,12.098h40.138
                                      		c6.681,0,12.098-5.416,12.098-12.098v-95.805C163.188,309.546,157.771,304.13,151.09,304.13z"
                                    fill="#5a1f2a"
                                  />
                                  &nbsp;
                                  <path
                                    d="M52.236,258.13H12.098C5.416,258.13,0,263.546,0,270.228v141.805c0,6.681,5.416,12.098,12.098,12.098h40.138
                                      		c6.681,0,12.097-5.416,12.097-12.098V270.228C64.333,263.546,58.917,258.13,52.236,258.13z"
                                    fill="#5a1f2a"
                                  />
                                  &nbsp;
                                  <path
                                    d="M249.944,196.968h-40.138c-6.681,0-12.098,5.416-12.098,12.098v202.967c0,6.681,5.416,12.098,12.098,12.098h40.138
                                      		c6.681,0,12.098-5.416,12.098-12.098V209.066C262.042,202.384,256.625,196.968,249.944,196.968z"
                                    fill="#5a1f2a"
                                  />
                                </g>
                              </svg>
                            </Tooltip>
                            <Tooltip
                              title="Logs"
                              placement="bottom"
                              arrow
                              backgroundColor="#5a1f2a">
                              <EventNoteOutlinedIcon
                                onClick={e => showLogs(item?._id, e)}
                                className="icons"
                                style={{ color: '#5a1f2a' }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={
                                item?.oper_status === 'Error' ? '' : 'Delete'
                              }
                              placement="bottom"
                              arrow
                              backgroundColor="#5a1f2a">
                              {localStorage
                                .getItem('role')
                                .split(',')[0]
                                .includes('fabric_readonly') === false &&
                              item?.oper_status !== 'Error' &&
                              item?.oper_status !== 'error' ? (
                                <DeleteOutlineOutlinedIcon
                                  className="icons"
                                  style={{ color: '#5a1f2a' }}
                                  onClick={e => DeleteIpsec(item._id, e)}
                                />
                              ) : (
                                <DeleteOutlineOutlinedIcon
                                  style={{ color: 'grey' }}
                                />
                              )}
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              setViewIpSec(false);
            }}>
            Close
          </Button>
        </DialogActions>
      </Popup>
      {editIpSec && (
        <EditIpSecPopup
          ipSecData={ipSecData}
          editIpSec={editIpSec}
          setEditIpSec={setEditIpSec}
          classes={classes}
        />
      )}
      {openLogsPopup && (
        <LogsPopup
          logsData={logsData}
          loading={loading}
          openLogsPopup={openLogsPopup}
          setOpenLogsPopup={setOpenLogsPopup}
          classes={classes}
        />
      )}
      {openChartsPopup && (
        <IpsecChartPopup
          chartData={chartData}
          loading={loading}
          openChartsPopup={openChartsPopup}
          setOpenChartsPopup={setOpenChartsPopup}
          dateFilter={dateFilter}
          matricType={matricType}
          setMatricType={setMatricType}
          setDateFilter={setDateFilter}
          classes={classes}
        />
      )}
    </>
  );
};

export default ViewAllIpSecPopup;
