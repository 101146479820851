import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../../../utils/constants';
import OutlinedSelect from '../../../../../../../components/OutlinedSelect';
import { checkValidEmail } from '../../../../../../../utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DatePicker from 'react-datepicker';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateSiteAcceptanceFormModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    allUserList,
    preValuesOfForm,
    handleUploadFiles,
    modalContentLoading,
}) {
    const classes = useStyles();
    const [projectClientName, setProjectClientName] = useState('');
    const [projectManagerName, setProjectManagerName] = useState('');
    const [provisioningEngineer, setProvisioningEngineer] = useState('');
    const [solutionId, setSolutionId] = useState('');
    const [date, setDate] = useState('');
    const [solutionName, setSolutionName] = useState('');
    const [solutionDescription, setSolutionDescription] = useState('');
    const [deliverables, setDeliverables] = useState('');
    const [solutionCompletionDate, setSolutionCompletionDate] = useState('');
    const [name, setName] = useState('');
    const [division, setDivision] = useState('');
    const [contactDetails, setContactDetails] = useState('');
    const [document, setDocument] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setProjectClientName('');
            setProjectManagerName('');
            setProvisioningEngineer('');
            setSolutionId('');
            setDate('');
            setSolutionName('');
            setSolutionDescription('');
            setDeliverables('');
            setSolutionCompletionDate('');
            setName('');
            setDivision('');
            setContactDetails('');
            setDocument('');
        }
    }, [modalState, open, modalContentLoading]);

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            disableBackdropClick={true}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>Create {t('siteAcceptanceForm.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.case_number ? preValuesOfForm.project_info.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.customer_name ? preValuesOfForm.project_info.customer_name : 'N/A'} / {preValuesOfForm.project_info && preValuesOfForm.project_info.organization_name ? preValuesOfForm.project_info.organization_name : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_owner ? preValuesOfForm.project_info.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {preValuesOfForm.project_info && preValuesOfForm.project_info.project_name ? preValuesOfForm.project_info.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('projectClientName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectClientName}
                                type={'text'}
                                placeholder={t('projectClientName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectClientName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('projectManagerName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={projectManagerName}
                                type={'text'}
                                placeholder={t('projectManagerName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProjectManagerName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('provisioningEngineer.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={provisioningEngineer}
                                type={'text'}
                                placeholder={t('provisioningEngineer.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setProvisioningEngineer(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('solutionId.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={solutionId}
                                type={'text'}
                                placeholder={t('solutionId.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSolutionId(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('date.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <div className="datePicker">
                                <DatePicker
                                    selected={date}
                                    onChange={date => setDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('solutionName.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={solutionName}
                                type={'text'}
                                placeholder={t('solutionName.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSolutionName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('solutionDescription.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={solutionDescription}
                                type={'text'}
                                placeholder={t('solutionDescription.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setSolutionDescription(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('deliverables.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={deliverables}
                                type={'text'}
                                placeholder={t('deliverables.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDeliverables(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('solutionCompletionDate.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                                 <div className="datePicker">
                                <DatePicker
                                    selected={solutionCompletionDate}
                                    onChange={date => setSolutionCompletionDate(date)}
                                    placeholderText="Please select a date"
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('name.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={name}
                                type={'text'}
                                placeholder={t('name.label')+ '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setName(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('division.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={division}
                                type={'text'}
                                placeholder={t('division.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setDivision(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('contactDetails.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                value={contactDetails}
                                type={'text'}
                                placeholder={t('contactDetails.label') + '*'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => setContactDetails(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('signature.label')}*</Typography>
                        <Grid
                            style={{
                                ...marginGenerator('mt-5')
                            }}>
                            <Input
                                type={'file'}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={evt => {
                                    if (evt.target.files && evt.target.files.length > 0) {
                                        let file = evt.target.files[0];
                                        setDocument(file);
                                    } else {
                                        setDocument('');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!projectClientName || !projectManagerName || !provisioningEngineer || !solutionId || !date || !solutionName || !solutionDescription || !deliverables ||
                                !solutionCompletionDate || !name || !division || !contactDetails || !document) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            let getDocument = '';
                            if (document != "" && document != null) {
                                let getDocumentUrl = await handleUploadFiles(document, preValuesOfForm.project, 'csoc_cameroon_team');
                                if (getDocumentUrl.data && getDocumentUrl.data.allUploadFile && getDocumentUrl.data.allUploadFile.url) {
                                    getDocument = getDocumentUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                project_uid: preValuesOfForm.project,
                                date: moment(date).format("YYYY-MM-DD"),
                                project_client_name: projectClientName,
                                project_manager_name: projectManagerName,
                                provisioning_engineer: provisioningEngineer,
                                solution_id: solutionId,
                                solution_name: solutionName,
                                solution_description: solutionDescription,
                                deliverables: deliverables,
                                solution_completion_date: moment(solutionCompletionDate).format("YYYY-MM-DD"),
                                name: name,
                                division: division,
                                contact_details: contactDetails,
                                signature: getDocument,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateSiteAcceptanceFormModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateSiteAcceptanceFormModal);