import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { renderText } from '../../displayComponent';
import { getRegions } from '../../../../services/fcrService';
import { getDatacenters } from '../../../../services/dataCenterService';
import {
  addPod,
  getPodById,
  updatePodById
} from '../../../../services/podService';
import Message from '../../../../components/Message';

const CreatePodForm = () => {
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [dataCenters, setDataCenters] = useState([]);
  const { mode, id } = useParams();

  const initialValues = {
    podName: '',
    podCode: '',
    region: '',
    dataCenter: '',
    zone: '',
    switchNumber: '',
    deviceName: '',
    deviceType: '',
    vendor: '',
    rackLabelName: '',
    rackLocation: '',
    hostedOn: '',
    ceVlanRange: ''
  };
  const validationSchema = yup.object().shape({
    podName: yup.string().required('Required'),
    podCode: yup.string().required('Required'),
    region: yup.string().required('Required'),
    dataCenter: yup.string().required('Required'),
    zone: yup.string().required('Required'),
    switchNumber: yup.string().required('Required'),
    deviceName: yup.string().required('Required'),
    deviceType: yup.string().required('Required'),
    vendor: yup.string().required('Required'),
    rackLabelName: yup.string().required('Required'),
    rackLocation: yup.string().required('Required'),
    hostedOn: yup.string().required('Required'),
    ceVlanRange: yup.string().required('Required')
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async data => {
    const payload = {
      name: data?.podName,
      pod_code: data?.podCode,
      region_id: data?.region,
      dc_id: data?.dataCenter,
      Switch_number: data?.switchNumber,
      device_name: data?.deviceName,
      device_type: data?.deviceType,
      vendor: data?.vendor,
      rack: {
        label_name: data?.rackLabelName,
        location: data?.rackLocation
      },
      hosted_on: data?.hostedOn,
      ce_vlan_range: data?.ceVlanRange,
      throughput: '8 GBPS',
      total_SVTI: 20000,
      zone: data?.zone
    };
    // console.log(payload);
    if (mode === 'add') {
      const res = await addPod(payload);
      console.log(res);
      if (res?.status === 200) {
        history.push('/fabricService/pods');
      } else {
        if (res?.response?.data?.message) {
          Message.error(res?.response?.data?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    } else {
      const res = await updatePodById(id, payload);
      if (res?.status === 200) {
        if (res?.response?.data?.message || res?.message) {
          Message.success(res?.response?.data?.message || res?.message);
        } else {
          Message.success('Pod created successfully');
        }
        history.push('/fabricService/pods');
      } else {
        if (res?.response?.data?.message || res?.message) {
          Message.error(res?.response?.data?.message || res?.message);
        } else if (res?.message) {
          Message.error(res?.message);
        } else {
          Message.error('Something went wrong');
        }
      }
    }
  };

  const GetRegions = async () => {
    const res = await getRegions();
    if (res?.status === 200) setRegions(res?.data?.result?.data);
  };
  const GetDatacenters = async () => {
    const res = await getDatacenters();
    if (res?.status === 200) setDataCenters(res?.data?.result?.data);
  };

  useEffect(() => {
    GetRegions();
    GetDatacenters();
  }, []);

  const getPod = async () => {
    const res = await getPodById(id);
    setValue('podName', res?.data?.result?.name);
    setValue('podCode', res?.data?.result?.pod_code);
    setValue('region', res?.data?.result?.region_id?._id);
    setValue('dataCenter', res?.data?.result?.dc_id?._id);
    setValue('zone', res?.data?.result?.zone);
    setValue('switchNumber', res?.data?.result?.Switch_number);
    setValue('deviceName', res?.data?.result?.device?.device_name);
    setValue('deviceType', res?.data?.result?.device_type?.type);
    setValue('vendor', res?.data?.result?.vendor);
    setValue('rackLabelName', res?.data?.result?.rack?.label_name);
    setValue('rackLocation', res?.data?.result?.rack?.location);
    setValue('hostedOn', res?.data?.result?.hosted_on);
    setValue('ceVlanRange', res?.data?.result?.ce_vlan?.total_vlans);
  };

  useEffect(() => {
    if (id) {
      getPod();
    }
  }, [id]);

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction="column">
        <Grid item justify="center">
          <Paper
            Component={Box}
            style={{
              display: 'flex',
              backgroundColor: '#f8f8f8',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}>
            <Grid item xs={12}>
              <Box p={1}>
                {renderText({
                  label: mode === 'add' ? 'Create POD' : 'Edit POD'
                })}
              </Box>
            </Grid>{' '}
          </Paper>
        </Grid>

        <Grid item style={{ marginTop: '50px' }}>
          <Paper>
            <Container maxWidth="md">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  justify="center"
                  alignItems="center">
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Pod Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="podName"
                        placeholder="Enter POD Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Pod Code
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="podCode"
                        placeholder="Enter POD Code"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Region
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="region"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {regions?.map(region => (
                          <option key={region?._id} value={region?._id}>
                            {region?.region_name}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Data Center
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="dataCenter"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {dataCenters?.map(dataCenter => (
                          <option key={dataCenter?._id} value={dataCenter?._id}>
                            {dataCenter?.name}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Zone
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="zone"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'Zone A', value: 'zoneA' },
                          { key: 'Zone B', value: 'zoneB' }
                        ]?.map(zone => (
                          <option key={zone?.value} value={zone?.value}>
                            {zone?.key}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Switch Number
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="switchNumber"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: '1', value: '2' },
                          { key: '2', value: '2' }
                        ]?.map(number => (
                          <option key={number?.value} value={number?.value}>
                            {number?.key}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Device Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="deviceName"
                        placeholder="Enter Device Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Device Type
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        select
                        name="deviceType"
                        SelectProps={{
                          native: true
                        }}
                        variant="outlined"
                        size="small">
                        <option value="">Select</option>
                        {[
                          { key: 'P Switch', value: 'pswitch' },
                          { key: 'V Switch', value: 'vswitch' },
                          { key: 'P Router', value: 'prouter' },
                          { key: 'V Router', value: 'vrouter' },
                          { key: 'Server', value: 'server' },
                          { key: 'Common', value: 'common' }
                        ]?.map(type => (
                          <option key={type?.value} value={type?.value}>
                            {type?.key}
                          </option>
                        ))}
                      </RHFTextField>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Vendor
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="vendor"
                        placeholder="Enter Vendor Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Rack Label Name
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="rackLabelName"
                        placeholder="Enter Rack Label Name"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Rack Location
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="rackLocation"
                        placeholder="Enter Rack Location"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      Hosted On
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="hostedOn"
                        placeholder="Hosted on"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item sm={3}>
                      CE Vlan Range
                    </Grid>
                    <Grid item sm={1}>
                      <span>:</span>
                    </Grid>
                    <Grid item sm={5}>
                      <RHFTextField
                        variant="outlined"
                        name="ceVlanRange"
                        placeholder="Enter CE vlan range"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    justify="flex-end"
                    sm={10}
                    style={{ marginTop: '3px', paddingRight: '45px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => history.push('/fabricService/pods')}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit">
                        {mode === 'add' ? 'Submit' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormProvider>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreatePodForm;
