import React from 'react';
import Popup from '../../Popup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

const DeleteConnectionConfirmPopup = ({
  openPopup,
  setOpenPopup,
  modeldata,
  handleDelete,
  classes
}) => {
  return (
    <>
      <Popup
        title="Remove Configured Services"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Are you sure you want to remove <b>{modeldata?.name}</b> service
            from your configured services?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={() => setOpenPopup(false)}>
            No,Keep Configured Service
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={e => handleDelete(modeldata?._id, e)}>
            Yes,Remove Configuration
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default DeleteConnectionConfirmPopup;
