import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import {withTranslation} from 'react-i18next';
import {
    CHECK_DUPLICATE_DEVICE_INTERFACE,
    GET_ALL_DEVICE_INTERFACES,
    GET_ALL_NETWORK_INTERFACES,
    GET_CONNECTIVITY_INFO, 
    GET_DEVICE_DETAILS
} from '../../queries';
import {
    ServiceTicketPaths
} from '../../../../routes/routePaths';
import {
    checkValidURL,
    getParam,
    getUrl,
    GenerateRandomString,
} from '../../../../utils';
import {
    SUBSCRIPTION_STATUS,
    USER_ROLES,
    REQUIRED_ERROR,
    FIELD_REQUIRED_ERROR,
} from '../../../../utils/constants';
import {
    CREATE_CONTROLLER,
    CREATE_UCPE,
    CREATE_IMAGE,
    CREATE_VNF_TEMPLATE,
    CREATE_VNF,
    CREATE_SDN_PLACE,
    CONFIGURE_UCPE,
    CREATE_SDN_NOTIFICATION_TEMPLATE,
    VERIFY_SDN_NOTIFICATION_TEMPLATE,
    EDIT_VNF,
    EDIT_SDN_NOTIFICATION,
    DELETE_CONTROLLER,
    EDIT_UCPE,
    DELETE_SDN_PLACE,
    DELETE_UCPE,
    DELETE_IMAGE,
    DELETE_VNF_TEMPLATE,
    DELETE_VNF,
    DELETE_SDN_NOTIFICATION,
    DELETE_SUBSCRIPTION,
    SAVE_ELASTIC_USER,
    CREATE_SFC,
    DELETE_SFC,
    CREATE_SCRIPT,
    DELETE_SCRIPT, 
    CONTROL_VNF
} from '../../mutations';
import {useHistory} from 'react-router';
import CustomTabs from '../../../../components/CustomTabs';
import Message from '../../../../components/Message';
import TicketPanel from '../../../ServiceTickets/components/TicketPanel';
//SDN-NFV Service Modules
import SDNConfigurationPanel from '../../components/SDNConfigurationPanel';
import CreateControllerModal from '../../components/CreateControllerModal';
import CreateUcpeModal from '../../components/CreateUcpeModal';
import CreateImagesModal from '../../components/CreateImagesModal';
import CreateVNFTemplateModal from '../../components/CreateVNFTemplateModal';
import CreateVNFModal from '../../components/CreateVNFModal';
import CreateSDNNotificationModal from '../../components/CreateSDNNotificationModal';
import SDNDashboardPanel from '../../components/SDNDashboardPanel';
import SDNReportPanel from '../../components/SDNReportPanel';
import CreateUcpeConfiguration from '../../components/CreateUcpeConfiguration';
import CreateUcpeWap from '../../components/CreateUcpeWap';
import CreateSDNPlaceModal from '../../components/CreateSDNPlaceModal';
import EditVNFModal from '../../components/EditVNFModal';
import DeleteConfirmBox from '../../components/DeleteConfirmBox';
import EditSDNNotificationModal from '../../components/EditSDNNotificationModal';
import EditUcpeModal from '../../components/EditUcpeModal';
import SDNTerminateSubscriptionPanel from '../../components/SDNTerminateSubscriptionPanel';
import VNFMarketplacePanel from '../../components/VNFMarketplacePanel';
import SDNBillingPanel from '../../components/SDNBillingPanel';
import CreateSFCModal from '../../components/CreateSFCModal';
import CreateScriptModal from '../../components/CreateScriptModal';
import ScriptDeviceList from "../../components/ScriptDeviceList";
import ControlsVNFModal from "../../components/ControlsVNFModal";

const ManagedNFVService = ({
    t, 
    data, 
    countryCodes, 
    handleChangeActiveNotiTab, 
    activeIndex,
    serviceTickets,
    filterListRecentTickets,
    handleTicketItemClick,
    handleTicketMenuItemClick,
    handleSearchRecentTickets,
    updatedRecentTickets,
    recentTicketsSearched
}) => {
    
    const history = useHistory();
    const role = localStorage.getItem('role');
    const [imageModal, setImageModalState] = useState(false);
    const [imageModalSrc, setImageModalSrcState] = useState('');
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [updateModalState, setUpdateModalState] = useState({
        mlTemplate: {},
        notification: {},
        place: {},
        camera: {}
    });
    const [notificationDataModalState, setNotificationDataModalState] = useState(
        {}
    );
    const [reloadDataState, setReloadDataState] = useState('');
    const [createControllerModal, setControllerModalState] = useState(false);
    const [createUcpeModal, setUcpeModalState] = useState(false);
    const [createSFCModal, setSFCModalState] = useState(false);
    const [createScriptModal, setScriptModalState] = useState(false);
    const [createImagesModal, setImagesModalState] = useState(false);
    const [vnfMarketPlaceListModal, setVnfMarketPlaceModalState] = useState(
        false
    );
    const [createVNFTemplateModal, setVNFTemplateModalState] = useState(false);
    const [createVNFModal, setVNFModalState] = useState(false);
    const [editVNFModal, setEditVNFModalState] = useState(false);
    const [editUCPEModal, setEditUCPEModalState] = useState(false);
    const [applyScriptModal, setApplyScriptModalState] = useState(false);
    const [vnfControlsModal, setVnfControlsModalState] = useState(false);
    const [editSDNNotificationModal, setEditSDNNotificationModalState] = useState(
        false
    );
    const [
        createSDNNotificationModal,
        setCreateSDNNotificationModalState
    ] = useState(false);
    const [ucpeConfigurationModal, setUcpeConfigurationState] = useState(false);
    const [ucpeConfigurationLoadingModal, setUcpeConfigurationLoadingState] = useState(true);
    const [ucpeConfigureId, setUcpeConfigurationId] = useState('');
    const [ucpeWapModal, setUcpeWapState] = useState(false);
    const [ucpeWapLoadingModal, setUcpeWapLoadingState] = useState(true);
    const [ucpeWapId, setUcpeWapId] = useState('');
    const [vnfId, setEditVnfId] = useState('');
    const [vnfName, setVnfName] = useState('');
    const [ucpeId, setEditUcpeId] = useState('');
    const [scriptId, setScriptId] = useState('');
    const [sdnNotificationId, setEditSDNNotificationId] = useState('');
    const [controllerId, setDeleteControllerId] = useState('');
    const [sdnPlaceId, setDeleteSDNPlaceId] = useState('');
    const [deleteUCPEId, setDeleteUCPEId] = useState('');
    const [deleteSFCId, setDeleteSFCId] = useState('');
    const [deleteScriptId, setDeleteScriptId] = useState('');
    const [deleteImageId, setDeleteImageId] = useState('');
    const [deleteVnfTemplateId, setDeleteVnfTemplateId] = useState('');
    const [deleteVnfId, setDeleteVnfId] = useState('');
    const [deleteSdnNotificationId, setDeleteSDNNotifactionId] = useState('');
    const [createSDNPlaceModal, setSDNPlaceModalState] = useState(false);
    const [UCModalState, setUCModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [vnfLoadModalState, setVNFLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [vnfControlsLoadModalState, setVNFControlsLoadModalState] = useState({
        btnLoading: false,
        clearData: false,
        type: ''
    });
    const [vnfTemplLoadModalState, setVNFTEMPLLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [vnfImageModalState, setVnfImageModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [UcpeLoadModalState, setUcpeLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [SFCLoadModalState, setSFCLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [scriptLoadModalState, setScriptLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [placeLoadModalState, setPlaceLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [controllerLoadModalState, setControllerLoadModalState] = useState({
        btnLoading: false,
        clearData: false
    });
    const [notificationSDNModalState, setSDNNotificationModalState] = useState({
        btnLoading: false,
        clearData: false,
        verifyData: false
    });
    const [
        terminateSubscriptionModalState,
        setTerminateSubscriptionModalState
    ] = useState({
        btnLoading: false,
        clearData: false
    });
    const [deleteType, setDeleteType] = useState('');
    const [networkInterfaceList, setNetworkInterfaceList] = useState([]);
    const [UcpeInterfaceList, setUcpeInterfaceList] = useState([]);
    const [UcpeDetails, setUcpeDetails] = useState({});

    /**
     * SDN SERVICE QUERY/MUTATION
     */
     const [createSDNNotificationTemplate] = useMutation(
        CREATE_SDN_NOTIFICATION_TEMPLATE,
        {
            onError: error => {
                setSDNNotificationModalState({
                    btnLoading: false,
                    clearData: false,
                    verifyData: false
                });
                Message.error(error);
            },
            onCompleted: data => {
                if (!data.addSDNNotificationTemplate.verified) {
                    setNotificationDataModalState(data.addSDNNotificationTemplate);
                    setSDNNotificationModalState({
                        btnLoading: false,
                        clearData: false,
                        verifyData: true
                    });
                    Message.success(t('verifiedEmailBtn.label'));
                    setReloadDataState('sdn_notifications');
                }
            }
        }
    );
    const [verifySDNNotificationTemplate] = useMutation(
        VERIFY_SDN_NOTIFICATION_TEMPLATE,
        {
            onError: error => {
                Message.error('Something went wrong!');
                setSDNNotificationModalState({
                    btnLoading: false,
                    clearData: false,
                    verifyData: true
                });
            },
            onCompleted: data => {
                if (data.verifySDNNotificationTemplate.verified) {
                    setSDNNotificationModalState({
                        btnLoading: false,
                        clearData: true,
                        verifyData: false
                    });
                    Message.success('OTP verify Successfully');
                    setReloadDataState('sdn_notifications');
                }
            }
        }
    );
    const [createController] = useMutation(CREATE_CONTROLLER, {
        onError: error => {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            // Message.error(error);
        },
        onCompleted: data => {
            if (data.createController.controller_id) {
                Message.success('Controller created successfully');
                setControllerLoadModalState({btnLoading: false, clearData: true});
                setReloadDataState('controller');
            } else {
                Message.error('Controller is not create');
                setControllerLoadModalState({btnLoading: false, clearData: false});
            }
        }
    });
    const [createUcpe] = useMutation(CREATE_UCPE, {
        onError: error => {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            // Message.error(error);
        },
        onCompleted: data => {
            if (data.createUcpe.status) {
                setUcpeLoadModalState({btnLoading: false, clearData: true});
                Message.success('uCPE created successfully');
                setReloadDataState('ucpe');
            }
        }
    });
    const [createSFC] = useMutation(CREATE_SFC, {
        onError: error => {
            setSFCLoadModalState({btnLoading: false, clearData: false});
            // Message.error(error);
        },
        onCompleted: data => {
            if (data.createSFC.status) {
                setSFCLoadModalState({btnLoading: false, clearData: true});
                Message.success('SFC created successfully');
                setReloadDataState('sfc');
            }
        }
    });
    /**
     * @description Create Function for addition script
     * */
    const [createScript] = useMutation(CREATE_SCRIPT, {
        onError: error => {
            setScriptLoadModalState({btnLoading: false, clearData: false});
            // Message.error(error);
        },
        onCompleted: data => {
            if (data.createScript.status) {
                setScriptLoadModalState({btnLoading: false, clearData: true});
                Message.success('Script created successfully');
                setReloadDataState('script');
            }
        }
    });
    const [createImage] = useMutation(CREATE_IMAGE, {
        onError: error => {
            setVnfImageModalState({btnLoading: false, clearData: false});
            //  Message.error(error);
        },
        onCompleted: data => {
            if (data.createImage.Image_ID) {
                setVnfImageModalState({btnLoading: false, clearData: true});
                Message.success('image created successfully');
                setReloadDataState('image');
            }
        }
    });
    const [createVnfTemplate] = useMutation(CREATE_VNF_TEMPLATE, {
        onError: error => {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(error);
        },
        onCompleted: data => {
            if (data.createVnfTemplate.status) {
                setVNFTEMPLLoadModalState({btnLoading: false, clearData: true});
                Message.success('Vnf template created successfully');
                setReloadDataState('vnftemplate');
            }
        }
    });
    const [createVnf] = useMutation(CREATE_VNF, {
        onError: error => {
            setVNFLoadModalState({btnLoading: false, clearData: false});
            Message.error(error);
        },
        onCompleted: data => {
            if (data.createVnf.status) {
                setVNFLoadModalState({btnLoading: false, clearData: true});
                Message.success('VNF created successfully');
                setReloadDataState('vnf');
            }
        }
    });
    const [controlVnf] = useMutation(CONTROL_VNF, {
        onError: error => {
            setVNFControlsLoadModalState({btnLoading: false, clearData: false, type: ''});
        },
        onCompleted: data => {
            if (data.controlVnf.status) {
                setVNFControlsLoadModalState({btnLoading: false, clearData: true, type: ''});
                Message.success('VNF controls status updated successfully');
                setReloadDataState('vnf');
            }
        }
    });
    const [editVnf] = useMutation(EDIT_VNF, {
        onError: error => {
            setVNFLoadModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            if (data.editVnf.status) {
                setVNFLoadModalState({btnLoading: false, clearData: true});
                Message.success('VNF updated successfully');
                setReloadDataState('vnf');
            }
        }
    });
    const [editUcpe] = useMutation(EDIT_UCPE, {
        onError: error => {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            if (data.editUcpe.status) {
                setUcpeLoadModalState({btnLoading: false, clearData: true});
                Message.success('uCPE updated successfully');
                setReloadDataState('ucpe');
            }
        }
    });
    const [editSDNNotification] = useMutation(EDIT_SDN_NOTIFICATION, {
        onError: error => {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
        },
        onCompleted: data => {
            if (data.editSDNNotification.status) {
                setSDNNotificationModalState({
                    btnLoading: false,
                    clearData: true,
                    verifyData: false
                });
                Message.success('Notification message updated successfully');
                setReloadDataState('sdn_notifications');
            }
        }
    });
    const [createUcpeConfiguraton] = useMutation(CONFIGURE_UCPE, {
        onError: error => {
            setUCModalState({btnLoading: false, clearData: false});
            Message.error(error);
        },
        onCompleted: data => {
            if (data.createConfigureUcpe.status) {
                setUCModalState({btnLoading: false, clearData: true});
                Message.success('Ucpe configuraton created successfully');
                //  setReloadDataState('ucpe');
            }
        }
    });
    const [createUcpeWap] = useMutation(CONFIGURE_UCPE, {
        onError: error => {
            setUCModalState({btnLoading: false, clearData: false});
            Message.error(error);
        },
        onCompleted: data => {
            if (data.createConfigureUcpe.status) {
                setUCModalState({btnLoading: false, clearData: true});
                Message.success('Ucpe wap created successfully');
                //  setReloadDataState('ucpe');
            }
        }
    });
    const [createSDNPlace] = useMutation(CREATE_SDN_PLACE, {
        onError: error => {
            setPlaceLoadModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            if (data.addSDNPlace.id) {
                setPlaceLoadModalState({btnLoading: false, clearData: true});
                Message.success('Place created successfully');
                setReloadDataState('sdn_places');
            } else {
                Message.error('Place is not create');
                setPlaceLoadModalState({btnLoading: false, clearData: false});
            }
        }
    });
    const [deleteController] = useMutation(DELETE_CONTROLLER, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('Controller deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('controller');
        }
    });
    const [deleteSDNPlace] = useMutation(DELETE_SDN_PLACE, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('Place deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('sdn_place');
        }
    });
    const [deleteUcpe] = useMutation(DELETE_UCPE, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('UCPE deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('ucpe');
        }
    });
    const [deleteSFC] = useMutation(DELETE_SFC, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('SFC deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('sfc');
        }
    });
    const [deleteScript] = useMutation(DELETE_SCRIPT, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('Script deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('sfc');
        }
    });
    const [deleteImage] = useMutation(DELETE_IMAGE, {
        onError: error => {
            setDeleteModalState(false);
            //   Message.error(error);
        },
        onCompleted: data => {
            Message.success('Image deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('vmImage');
        }
    });
    const [deleteVnfTemplate] = useMutation(DELETE_VNF_TEMPLATE, {
        onError: error => {
            setDeleteModalState(false);
            //  Message.error(error);
        },
        onCompleted: data => {
            Message.success('Vnf Template deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('vnfTemplate');
        }
    });
    const [deleteVnf] = useMutation(DELETE_VNF, {
        onError: error => {
            setDeleteModalState(false);
            // Message.error(error);
        },
        onCompleted: data => {
            Message.success('Vnf deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('vnf');
        }
    });
    const [deleteSDNNotification] = useMutation(DELETE_SDN_NOTIFICATION, {
        onError: error => {
            setDeleteModalState(false);
        },
        onCompleted: data => {
            Message.success('Notification deleted successfully');
            setDeleteModalState(false);
            setReloadDataState('notification');
        }
    });
    const [deleteSubscription] = useMutation(DELETE_SUBSCRIPTION, {
        onError: error => {
            setTerminateSubscriptionModalState({
                btnLoading: false,
                clearData: false
            });
        },
        onCompleted: data => {
            Message.success('Terminate Subscription successfully');
            setTerminateSubscriptionModalState({
                btnLoading: false,
                clearData: false
            });
            history.push('/i/organisations');
            //  window.location.reload();
        }
    });
    const handleDeleteConfirmClose = () => {
        setDeleteModalState(false);
    };
    /* Delete Controller*/
    const handleConfirmDelete = () => {
        if (deleteType === 'controller') {
            return deleteController({
                variables: {
                    controller_id: controllerId
                }
            });
        }
        if (deleteType === 'sdn_place') {
            return deleteSDNPlace({
                variables: {
                    id: sdnPlaceId
                }
            });
        }
        if (deleteType === 'ucpe') {
            return deleteUcpe({
                variables: {
                    device_id: deleteUCPEId
                }
            });
        }
        if (deleteType === 'sfc') {
            return deleteSFC({
                variables: {
                    bridge_id: deleteSFCId
                }
            });
        }
        if (deleteType === 'script') {
            return deleteScript({
                variables: {
                    uuid: deleteScriptId
                }
            });
        }
        if (deleteType === 'vmImage') {
            return deleteImage({
                variables: {
                    image_id: deleteImageId
                }
            });
        }
        if (deleteType === 'vnfTemplate') {
            return deleteVnfTemplate({
                variables: {
                    vnf_template_id: deleteVnfTemplateId
                }
            });
        }
        if (deleteType === 'vnf') {
            return deleteVnf({
                variables: {
                    vnf_id: deleteVnfId
                }
            });
        }
        if (deleteType === 'notification') {
            return deleteSDNNotification({
                variables: {
                    id: deleteSdnNotificationId
                }
            });
        }
    };
    const activeSites = (data.getSubscriptionById &&
        data.getSubscriptionById.sites && data.getSubscriptionById.sites.length && data.getSubscriptionById.sites.filter(item => item.status === SUBSCRIPTION_STATUS.ACTIVE)) || [];
    /*SDN NFV*/
    const [getNetworkInterfaces] = useLazyQuery(GET_ALL_NETWORK_INTERFACES, {
        onCompleted: data => {
            if (data.getNetworkInterfaces && data.getNetworkInterfaces.length) {
                let niData = data.getNetworkInterfaces.map(item => {
                    let o = Object.assign({}, item);
                    o.id = item.interface_name;
                    o.name = item.interface_name;
                    return o;
                });
                setNetworkInterfaceList(niData);
            }
        }
    });
    /*SDN NFV UCPE CONFIGURE*/
    const [getUcpeConfigure] = useLazyQuery(GET_ALL_DEVICE_INTERFACES, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getDeviceInterfaceList && data.getDeviceInterfaceList.length) {
                let ucpeConfigure = data.getDeviceInterfaceList.map(item => {
                    let o = Object.assign({}, item);
                    return {
                        en0: o.ni_name,
                        type: o.ni_type,
                        bridgeName: o.bridge_interface
                            ? o.bridge_interface[0].bridge_name
                            : null,
                        typeOfConnection: o.bridge_interface
                            ? o.bridge_interface[0].bridge_type
                            : null,
                        vnfIpAddress: '',
                        managementIpAddress: '',
                        port: '',
                        communication: '',
                        number_of_adaptors: o.number_of_adaptors ? o.number_of_adaptors : ''
                    };
                });
                setUcpeInterfaceList(ucpeConfigure);
                setUcpeConfigurationLoadingState(false);
                setUcpeWapLoadingState(false);
            }
            setUcpeConfigurationLoadingState(false);
            setUcpeWapLoadingState(false);
        }
    });
    /*SDN NFV UCPE INTERFACE CHECK*/
    const [checkUcpeInterfaceDuplicate] = useLazyQuery(CHECK_DUPLICATE_DEVICE_INTERFACE, {
        fetchPolicy: 'no-cache',
        onError: error => {
            setUCModalState({btnLoading: false, clearData: false});
        },
        onCompleted: data => {
            if (data.checkDeviceInterface && data.checkDeviceInterface.status) {
            }
        }
    });
    /*SDN UCPE Details*/
    const [getUcpeDetails] = useLazyQuery(GET_DEVICE_DETAILS, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data.getDeviceDetails && data.getDeviceDetails.id) {
                setUcpeDetails(data.getDeviceDetails);
            }
        }
    });
    const handleDeleteOrgButtonClick = item => {
        if (window.confirm('Are you sure want to terminate?')) {
            setTerminateSubscriptionModalState({
                btnLoading: true,
                clearData: false
            });
            return deleteSubscription({
                variables: {
                    organisation_id: item.organization_id,
                    subscription_id: item.subscription_id
                }
            });
        }
    };
    const handleCreateButtonClick = item => {
        if (item === 'controller_modal') {
            setControllerModalState(true);
        } else if (item === 'ucpe_modal') {
            setUcpeModalState(true);
        } else if (item === 'sfc_modal') {
            setSFCModalState(true);
        } else if (item === 'script_modal') {
            setScriptModalState(true);
        } else if (item === 'images_modal') {
            setImagesModalState(true);
        } else if (item === 'vnf_maketplace_list_modal') {
            setVnfMarketPlaceModalState(true);
        } else if (item === 'vnftemplate_modal') {
            setVNFTemplateModalState(true);
        } else if (item === 'vnf_modal') {
            setVNFModalState(true);
        } else if (item === 'notification_modal') {
            setCreateSDNNotificationModalState(true);
        } else if (item === 'sdn_place') {
            setSDNPlaceModalState(true);
        }
    };
    const handleSetImageModalSrc = event => {
        setImageModalSrcState(event.target.src);
        setImageModalState(true);
    };
    const handleSDNCreateNotificationSubmitClick = formData => {
        setSDNNotificationModalState({
            btnLoading: true,
            clearData: false,
            verifyData: false
        });
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.controller_id) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('controller.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_id) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('uCPE.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        // if (!formData.vnf_id) {
        //     setSDNNotificationModalState({
        //         btnLoading: false,
        //         clearData: false,
        //         verifyData: false
        //     });
        //     Message.error(t('vnfs.label') + FIELD_REQUIRED_ERROR);
        //     return;
        // }
        if (!formData.notification_priority) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('notificationsPriority.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.notification_type) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('notificationType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.notification_name) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('notification.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.notification_parameter) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('emailOrPhone.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.notification_template) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('notificationMsg.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createSDNNotificationTemplate({
            variables: formData
        });
    };
    const handleVerifyNotificationEmailSubmitClick = formData => {
        setSDNNotificationModalState({
            btnLoading: true,
            clearData: false,
            verifyData: true
        });
        if (!formData.otp) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: true
            });
            Message.error(t('otp.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return verifySDNNotificationTemplate({
            variables: formData
        });
    };
    const handleDeleteConfirmAction = actData => {
        if (actData.action === 'controller') {
            setDeleteType(actData.action);
            setDeleteControllerId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'sdn_place') {
            setDeleteType(actData.action);
            setDeleteSDNPlaceId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'ucpe') {
            setDeleteType(actData.action);
            setDeleteUCPEId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'sfc') {
            setDeleteType(actData.action);
            setDeleteSFCId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'script') {
            setDeleteType(actData.action);
            setDeleteScriptId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'vmImage') {
            setDeleteType(actData.action);
            setDeleteImageId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'vnfTemplate') {
            setDeleteType(actData.action);
            setDeleteVnfTemplateId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'vnf') {
            setDeleteType(actData.action);
            setDeleteVnfId(actData.id);
            setDeleteModalState(true);
        }
        if (actData.action === 'notification') {
            setDeleteType(actData.action);
            setDeleteSDNNotifactionId(actData.id);
            setDeleteModalState(true);
        }
    };
    const handleUpdateAction = actData => {
        if (actData.action === 'ucpe_configure') {
            setUcpeConfigurationId(actData.id);
            setUcpeConfigurationLoadingState(true);
            setUcpeConfigurationState(true);
            getUcpeConfigure({
                variables: {deviceID: actData.id}
            });
            getNetworkInterfaces({
                variables: {deviceID: actData.id}
            });
        } else if (actData.action === 'ucpe_wap') {
            setUcpeWapId(actData.id);
            setUcpeWapLoadingState(true);
            setUcpeWapState(true);
            getUcpeConfigure({
                variables: {deviceID: actData.id}
            });
            getNetworkInterfaces({
                variables: {deviceID: actData.id}
            });
        } else if (actData.action === 'vnf') {
            //
            setEditVnfId(actData.id);
            setEditVNFModalState(true);
        } else if (actData.action === 'ucpe') {
            setEditUcpeId(actData.id);
            getUcpeDetails({
                variables: {deviceID: actData.id}
            });
            setEditUCPEModalState(true);
        } else if (actData.action === 'script_apply') {
            setScriptId(actData.id);
            setApplyScriptModalState(true);
        } else if (actData.action === 'vnf_controls') {
            setEditVnfId(actData.id && actData.id.vnf_id);
            setVnfName(actData.id && actData.id.vnf_name);
            setVnfControlsModalState(true);
        }
    };
    const handleCreateController = formData => {
        setControllerLoadModalState({btnLoading: true, clearData: false});
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.controller_name) {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('controller.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.deployment_type) {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deploymentType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.role) {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('role.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.provision_type) {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('provisioningType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.version) {
            setControllerLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('version.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createController({
            variables: formData
        });
    };
    const handleCreateUcpe = formData => {
        setUcpeLoadModalState({btnLoading: true, clearData: false});
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.name) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('uCPEName.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.controller_id) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('controllerId.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.place_id) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('places.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_type) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_id) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceID.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.address) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceIP.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_username) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceUsername.label') + FIELD_REQUIRED_ERROR);
        }
        if (!formData.device_password) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('devicePassword.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.port) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('netconfPort.label') + FIELD_REQUIRED_ERROR);
        }
        if (!formData.call_home) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('callHome.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createUcpe({
            variables: formData
        });
    };
    /**
     * @description Create SFC Bridge
     * */
    const handleCreateSFC = formData => {
        setUcpeLoadModalState({btnLoading: true, clearData: false});
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.device_id) {
            setSFCLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceID.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.bridge_name) {
            setSFCLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('bridgeName.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.controller_id) {
            setSFCLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('controllerId.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.bridgeType) {
            setSFCLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('bridgeType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createSFC({
            variables: formData
        });
    };
    /**
     * @description Apply Script
     * */
    const handleApplyScript = formData => {
    }
    /**
     * @description Create Script
     * */
    const handleCreateScript = formData => {
        setScriptLoadModalState({btnLoading: true, clearData: false});
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.device_id) {
            setScriptLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceID.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.scriptFile) {
            setScriptLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('script.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.controller_id) {
            setScriptLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('controllerId.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.scriptType) {
            setScriptLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('scriptType.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createScript({
            variables: formData
        });
    };
    const handleCreateImage = formData => {
        formData.organisation_id = data.getSubscriptionById.organizationId;
        setVnfImageModalState({btnLoading: true, clearData: false});
        if (!formData.image_name) {
            setVnfImageModalState({btnLoading: false, clearData: false});
            Message.error(t('imageName.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.image_url) {
            setVnfImageModalState({btnLoading: false, clearData: false});
            Message.error(t('imageUrl.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (formData.image_url) {
            if (!checkValidURL(formData.image_url)) {
                Message.error(t('imageUrlValidation.label'));
                setVnfImageModalState({btnLoading: false, clearData: false});
                return;
            }
        }
        if (!formData.image_version) {
            setVnfImageModalState({btnLoading: false, clearData: false});
            Message.error(t('version.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.image_logo) {
            setVnfImageModalState({btnLoading: false, clearData: false});
            Message.error(t('logoImage.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return createImage({
            variables: formData
        });
    };
    const handleCreateVnfTemplate = formData => {
        setVNFTEMPLLoadModalState({btnLoading: true, clearData: false});
        if (!formData.vnf_name) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vnfTemplateNames.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.controller_id) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('controllerId.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_version) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vnfVersion.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_description) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vnfDescription.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_provider) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vnfProvider.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vm_image_id) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vnfImage.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_numVcpus) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('vCPU.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_memory_in_MB) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('ram.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_storage_in_GB) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('storageDisk.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_interfaces[0].name) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(
                t('interface.label') + ' ' + t('name.label') + FIELD_REQUIRED_ERROR
            );
            return;
        }
        if (!formData.vnf_interfaces[0].type) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(
                t('interface.label') + ' ' + t('type.label') + FIELD_REQUIRED_ERROR
            );
            return;
        }
        if (!formData.vnf_interfaces[0].description) {
            setVNFTEMPLLoadModalState({btnLoading: false, clearData: false});
            Message.error(
                t('interface.label') +
                ' ' +
                t('description.label') +
                FIELD_REQUIRED_ERROR
            );
            return;
        }
        formData.organisation_id = data.getSubscriptionById.organizationId;
        return createVnfTemplate({
            variables: formData
        });
    };
    const handleCreateVnf = formData => {
        setVNFLoadModalState({btnLoading: true, clearData: false});
        let arr = [];
        if (formData && formData.length) {
            for (let i = 0; i < formData.length; i++) {
                let interface_network = formData[i].interface_network.map(item => {
                    let o = Object.assign({}, item);
                    delete o.filteredArray;
                    return o;
                });
                for (let j = 0; j < interface_network.length; j++) {
                    if (!interface_network[j].networkName) {
                        setVNFLoadModalState({btnLoading: false, clearData: false});
                        Message.error(REQUIRED_ERROR);
                        return false;
                    }
                }
                arr.push({
                    vnf_template_id: formData[i].vnfTemplate,
                    vnf_instance_name: formData[i].vnfName,
                    device_id: formData[i].uCPE,
                    vnf_autoRestart: formData[i].vnfAutoRestart,
                    vnf_ssh_ip: formData[i].sshIP,
                    vnf_ssh_port: formData[i].sshPort,
                    lic_data: formData[i].lic_data,
                    conf_data: formData[i].conf_data,
                    /*  vnf_management_ip: formData[i].webUIIP, vnf_management_port: formData[i].webUIPort,*/
                    vnf_web: formData[i].webUIType + formData[i].webUIUrl,
                    vnf_connections: interface_network,
                    createdBy: 'Anil',
                    organisation_id: data.getSubscriptionById.organizationId
                });
            }
        }
        return createVnf({
            variables: {vnf_muldata: JSON.stringify(arr)}
        });
    };
    const handleControlsVnf = formData => {
        setVNFControlsLoadModalState({btnLoading: true, clearData: false, type: formData.type});
        if (!formData.type) {
            setVNFControlsLoadModalState({btnLoading: false, clearData: false, type: ''});
            Message.error(t('type.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnfId) {
            setVNFControlsLoadModalState({btnLoading: false, clearData: false, type: ''});
            Message.error(t('vnfId.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return controlVnf({
            variables: formData
        });
    };
    const handleEditVnf = formData => {
        setVNFLoadModalState({btnLoading: true, clearData: false});
        if (!formData.vnf_ssh_ip) {
            setVNFLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('ipAddress.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_web) {
            setVNFLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('url.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.vnf_ssh_port) {
            setVNFLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('sshPort.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return editVnf({
            variables: {
                vnf_ssh_ip: formData.vnf_ssh_ip,
                vnf_web: formData.vnf_web,
                vnf_ssh_port: formData.vnf_ssh_port,
                vnf_id: vnfId
            }
        });
    };
    const handleUpdateUcpe = formData => {
        setUcpeLoadModalState({btnLoading: true, clearData: false});
        formData.organisation_id = data.getSubscriptionById.organizationId;
        if (!formData.place_id) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('places.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.address) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceIP.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_username) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceUsername.label') + FIELD_REQUIRED_ERROR);
        }
        if (!formData.device_password) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('devicePassword.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.port) {
            setUcpeLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('netconfPort.label') + FIELD_REQUIRED_ERROR);
        }
        formData.ucpeId = ucpeId;
        return editUcpe({
            variables: formData
        });
    };
    const handleEditSDNNotification = formData => {
        setSDNNotificationModalState({
            btnLoading: true,
            clearData: false,
            verifyData: false
        });
        if (!formData.notification_template) {
            setSDNNotificationModalState({
                btnLoading: false,
                clearData: false,
                verifyData: false
            });
            Message.error(t('notificationMsg.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        return editSDNNotification({
            variables: {
                notification_template: formData.notification_template,
                id: sdnNotificationId
            }
        });
    };
    const handleCheckInterfaceExits = formData => {
        if (!formData.value) {
            setUCModalState({btnLoading: false, clearData: false});
            Message.error(t('interface.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.device_id) {
            setUCModalState({btnLoading: false, clearData: false});
            Message.error(t('deviceID.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        checkUcpeInterfaceDuplicate({
            variables: {deviceID: formData.device_id, ni_name: formData.value}
        })
    }
    const handleCreateUcpeConfiguraton = formData => {
        let arr = [];
        if (formData && formData.length) {
            for (let i = 0; i < formData.length; i++) {
                let interfacebridge = {};
                if (
                    formData[i].type === 'Bridge-DPDK' ||
                    formData[i].type === 'Bridge-TAP'
                ) {
                    if (!formData[i].bridgeName && !formData[i].editable) {
                        setUCModalState({btnLoading: false, clearData: false});
                        Message.error(t('bridgeName.label') + FIELD_REQUIRED_ERROR);
                        return;
                    }
                    interfacebridge = {
                        bridge_name: formData[i].bridgeName,
                        bridge_type: formData[i].typeOfConnection,
                        vnf_ip_address: formData[i].vnfIpAddress
                    };
                    if (formData[i].typeOfConnection === 'VNF_MANAGEMENT') {
                        interfacebridge.vnf_ip_address = formData[i].vnfIpAddress;
                    } else {
                        delete interfacebridge.vnf_ip_address;
                    }
                }
                let finalRequest = {
                    organisation: data.getSubscriptionById.organizationId,
                    interface_bridge_id: 1,
                    created_by: 'Anil',
                    updated_by: 'Anil',
                    device: ucpeConfigureId,
                    ni_name: formData[i].en0,
                    ni_type: formData[i].type,
                    ni_subtype:
                        formData[i].en0 && formData[i].type === 'sr-iov'
                            ? 'adapter-pool'
                            : 'vfio-pci',
                    number_of_adaptors: formData[i].number_of_adaptors,
                    interfacebridge: interfacebridge
                };
                if (
                    formData[i].type === 'PassThrough' ||
                    formData[i].type === 'sr-iov'
                ) {
                    delete finalRequest.interfacebridge;
                }
                setUCModalState({btnLoading: true, clearData: false});
                if (formData[i].type !== 'sr-iov') {
                    delete finalRequest.number_of_adaptors;
                }
                arr.push(finalRequest);
            }
        }
        return createUcpeConfiguraton({
            variables: {
                configucpes: JSON.stringify(arr),
                device_id: ucpeConfigureId
            }
        });
    };
    const handleCreateUcpeWap = formData => {
        let arr = [];
        if (formData && formData.length) {
            for (let i = 0; i < formData.length; i++) {
                let interfacebridge = {};
                if (
                    formData[i].type === 'Bridge-DPDK' ||
                    formData[i].type === 'Bridge-TAP'
                ) {
                    if (!formData[i].bridgeName && !formData[i].editable) {
                        setUCModalState({btnLoading: false, clearData: false});
                        Message.error(t('bridgeName.label') + FIELD_REQUIRED_ERROR);
                        return;
                    }
                    interfacebridge = {
                        bridge_name: formData[i].bridgeName,
                        bridge_type: formData[i].typeOfConnection,
                        vnf_ip_address: formData[i].vnfIpAddress
                    };
                    if (formData[i].typeOfConnection === 'VNF_MANAGEMENT') {
                        interfacebridge.vnf_ip_address = formData[i].vnfIpAddress;
                    } else {
                        delete interfacebridge.vnf_ip_address;
                    }
                }
                let finalRequest = {
                    organisation: data.getSubscriptionById.organizationId,
                    interface_bridge_id: 1,
                    created_by: 'Anil',
                    updated_by: 'Anil',
                    device: ucpeWapId,
                    ni_name: formData[i].en0,
                    ni_type: formData[i].type,
                    ni_subtype:
                        formData[i].en0 && formData[i].type === 'sr-iov'
                            ? 'adapter-pool'
                            : 'vfio-pci',
                    number_of_adaptors: formData[i].number_of_adaptors,
                    interfacebridge: interfacebridge
                };
                if (
                    formData[i].type === 'PassThrough' ||
                    formData[i].type === 'sr-iov'
                ) {
                    delete finalRequest.interfacebridge;
                }
                setUCModalState({btnLoading: true, clearData: false});
                if (formData[i].type !== 'sr-iov') {
                    delete finalRequest.number_of_adaptors;
                }
                arr.push(finalRequest);
            }
        }
        return createUcpeWap({
            variables: {
                configucpes: JSON.stringify(arr),
                device_id: ucpeWapId
            }
        });
    };
    const handleCreateSDNPlace = formData => {
        setPlaceLoadModalState({btnLoading: true, clearData: false});
        if (!formData.place_name) {
            setPlaceLoadModalState({btnLoading: false, clearData: false});
            Message.error(t('name.label') + FIELD_REQUIRED_ERROR);
            return;
        }
        if (!formData.location.latitude && !formData.location.longitude) {
            setPlaceLoadModalState({btnLoading: false, clearData: false});
            Message.error(
                t('latitude.label') + ' ' + t('longitude.label') + FIELD_REQUIRED_ERROR
            );
            return;
        }
        if (formData.place_name && formData.location) {
            let placeData = {
                place_name: formData.place_name,
                gps_latitude: formData.location.latitude,
                gps_longitude: formData.location.longitude,
                organisation: data.getSubscriptionById.organizationId
            };
            return createSDNPlace({
                variables: placeData
            });
        } else {
            return false;
        }
    };
    
    let tabList = [];
    if (data) {
        tabList = [
            {
                label: t('dashboard.label'),
                children: (
                    <SDNDashboardPanel
                        t={t}
                        subscriptionData={data.getSubscriptionById}
                        data={activeSites}
                        ticketData={
                            (serviceTickets &&
                                serviceTickets.servicetickets.slice(0, 10)) ||
                            []
                        }
                        type={data.getSubscriptionById.service.serviceType.name}
                    />
                )
            }
        ];
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.CSOC_MANAGER) &&
            !role.includes(USER_ROLES.CSOC) &&
            !role.includes(USER_ROLES.SD_READONLY) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('configuration.label'),
                children: (
                    <>
                        <SDNConfigurationPanel
                            t={t}
                            data={data.getSubscriptionById}
                            handleDeleteConfirmAction={handleDeleteConfirmAction}
                            handleUpdateAction={handleUpdateAction}
                            handleCreateButtonClick={handleCreateButtonClick}
                            reloadData={reloadDataState}
                        />
                    </>
                )
            });
        }
        tabList.push({
            label: t('support.label'),
            children: (
                <>
                    {serviceTickets && (
                        <TicketPanel
                            ticketList={
                                recentTicketsSearched
                                    ? updatedRecentTickets
                                    : serviceTickets.servicetickets.slice(0, 10)
                            }
                            type={'recentTickets'}
                            t={t}
                            handleMenuItemClick={handleTicketMenuItemClick}
                            filterList={filterListRecentTickets}
                            handleTicketItemClick={handleTicketItemClick}
                            handleSearch={handleSearchRecentTickets}
                        />
                    )}
                </>
            )
        });
        if (
            !role.includes(USER_ROLES.EP_IT) &&
            !role.includes(USER_ROLES.CSOC_MANAGER) &&
            !role.includes(USER_ROLES.SD_READONLY) &&
            !role.includes(USER_ROLES.CSOC) &&
            !role.includes(USER_ROLES.THIRD_PARTY_VENDOR) &&
            !role.includes(USER_ROLES.IP_VNFADMIN)
        ) {
            tabList.push({
                label: t('billing.label'),
                children: (
                    <>
                        <SDNBillingPanel t={t} data={data.getSubscriptionById}/>
                    </>
                )
            });
        }
        tabList.push({
            label: t('reporting.label'),
            children: (
                <>
                    <SDNReportPanel
                        t={t}
                        data={data.getSubscriptionById}
                        handleCreateButtonClick={handleCreateButtonClick}
                        handleSetImageModalSrc={handleSetImageModalSrc}
                    />
                </>
            )
        },
        {
            label: t('settings.label'),
            children: (
                <>
                    <SDNTerminateSubscriptionPanel
                        t={t}
                        data={data.getSubscriptionById}
                        modalState={terminateSubscriptionModalState}
                        handleDeleteOrgButtonClick={handleDeleteOrgButtonClick}
                    />
                </>
            )
        });
    }
    
    return (
        <>
            <CustomTabs 
                handleChangeActiveNotiTab={handleChangeActiveNotiTab} 
                activeIndex={activeIndex} 
                tabs={tabList}
            />
            {/****************** SDN MODALS ******************** */}
            <React.Fragment>
                <DeleteConfirmBox
                    t={t}
                    open={deleteModalState}
                    handleClose={handleDeleteConfirmClose}
                    type={deleteType}
                    handleDelete={handleConfirmDelete}
                />
                <CreateControllerModal
                    t={t}
                    open={createControllerModal}
                    handleCreateController={handleCreateController}
                    handleClose={() => {
                        setControllerLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setControllerModalState(false);
                    }}
                    modalState={controllerLoadModalState}
                />
                <CreateUcpeModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={createUcpeModal}
                    handleCreateUcpe={handleCreateUcpe}
                    handleClose={() => {
                        setUcpeLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setUcpeModalState(false);
                    }}
                    modalState={UcpeLoadModalState}
                />
                <CreateSFCModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={createSFCModal}
                    handleCreateSFC={handleCreateSFC}
                    handleClose={() => {
                        setSFCLoadModalState({btnLoading: false, clearData: false});
                        setSFCModalState(false);
                    }}
                    modalState={SFCLoadModalState}
                />
                <CreateScriptModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={createScriptModal}
                    handleCreateScript={handleCreateScript}
                    handleClose={() => {
                        setScriptLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setScriptModalState(false);
                    }}
                    modalState={scriptLoadModalState}
                />
                {scriptId && <ScriptDeviceList
                    t={t}
                    data={data.getSubscriptionById}
                    open={applyScriptModal}
                    handleApplyScript={handleApplyScript}
                    scriptId={scriptId}
                    handleClose={() => {
                        setScriptLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setApplyScriptModalState(false);
                    }}
                    modalState={scriptLoadModalState}
                />}
                <CreateImagesModal
                    t={t}
                    open={createImagesModal}
                    handleCreateImage={handleCreateImage}
                    handleClose={() => {
                        setVnfImageModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setImagesModalState(false);
                    }}
                    modalState={vnfImageModalState}
                />
                <VNFMarketplacePanel
                    t={t}
                    subscriptionData={data.getSubscriptionById}
                    openModal={vnfMarketPlaceListModal}
                    handleClose={() => {
                        setVnfMarketPlaceModalState(false);
                    }}
                />
                <CreateVNFTemplateModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={createVNFTemplateModal}
                    handleCreateVnfTemplate={handleCreateVnfTemplate}
                    handleClose={() => {
                        setVNFTEMPLLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setVNFTemplateModalState(false);
                    }}
                    modalState={vnfTemplLoadModalState}
                />
                <CreateVNFModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={createVNFModal}
                    handleCreateVnf={handleCreateVnf}
                    handleClose={() => {
                        setVNFLoadModalState({btnLoading: false, clearData: false});
                        setVNFModalState(false);
                    }}
                    modalState={vnfLoadModalState}
                />
                <ControlsVNFModal
                    t={t}
                    data={data.getSubscriptionById}
                    vnfId={vnfId}
                    vnfName={vnfName}
                    open={vnfControlsModal}
                    handleControlsVnf={handleControlsVnf}
                    handleClose={() => {
                        setVNFControlsLoadModalState({btnLoading: false, clearData: false, type: ''});
                        setVnfControlsModalState(false);
                    }}
                    modalState={vnfControlsLoadModalState}
                />
                <CreateSDNNotificationModal
                    t={t}
                    data={data.getSubscriptionById}
                    notificationData={notificationDataModalState}
                    open={createSDNNotificationModal}
                    countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                    handleCreateNotificationSubmitClick={
                        handleSDNCreateNotificationSubmitClick
                    }
                    handleVerifyNotificationEmailSubmitClick={
                        handleVerifyNotificationEmailSubmitClick
                    }
                    handleClose={() => {
                        setCreateSDNNotificationModalState(false);
                        setSDNNotificationModalState({
                            verifyData: false,
                            clearData: false,
                            btnLoading: false
                        });
                    }}
                    modalState={notificationSDNModalState}
                />
                <CreateUcpeConfiguration
                    t={t}
                    open={ucpeConfigurationModal}
                    device_id={ucpeConfigureId}
                    data={data.getSubscriptionById}
                    ucpeConfigurationLoadingModal={ucpeConfigurationLoadingModal}
                    networkInterfaceList={networkInterfaceList}
                    ucpeInterfaceList={UcpeInterfaceList}
                    handleCreateUcpeConfiguraton={handleCreateUcpeConfiguraton}
                    handleCheckInterfaceExits={handleCheckInterfaceExits}
                    handleClose={() => {
                        setUCModalState({clearData: false, btnLoading: false});
                        setUcpeConfigurationState(false);
                    }}
                    modalState={UCModalState}
                />
                    <CreateUcpeWap
                    t={t}
                    open={ucpeWapModal}
                    device_id={ucpeWapId}
                    data={data.getSubscriptionById}
                    countryCodes={countryCodes ? countryCodes.getCoutriesCode : []}
                    ucpeWapLoadingModal={ucpeWapLoadingModal}
                    networkInterfaceList={networkInterfaceList}
                    ucpeInterfaceList={UcpeInterfaceList}
                    handleCreateUcpeWap={handleCreateUcpeWap}
                    handleCheckInterfaceExits={handleCheckInterfaceExits}
                    handleClose={() => {
                        setUCModalState({clearData: false, btnLoading: false});
                        setUcpeWapState(false);
                    }}
                    modalState={UCModalState}
                />
                <EditVNFModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={editVNFModal}
                    handleEditVnf={handleEditVnf}
                    handleClose={() => {
                        setVNFLoadModalState({btnLoading: false, clearData: false});
                        setEditVNFModalState(false);
                    }}
                    modalState={vnfLoadModalState}
                />
                <EditUcpeModal
                    t={t}
                    data={data.getSubscriptionById}
                    UcpeDetails={UcpeDetails}
                    open={editUCPEModal}
                    handleUpdateUcpe={handleUpdateUcpe}
                    handleClose={() => {
                        setUcpeLoadModalState({
                            btnLoading: false,
                            clearData: false
                        });
                        setEditUCPEModalState(false);
                    }}
                    modalState={UcpeLoadModalState}
                />
                <EditSDNNotificationModal
                    t={t}
                    data={data.getSubscriptionById}
                    open={editSDNNotificationModal}
                    handleEditSDNNotification={handleEditSDNNotification}
                    handleClose={() => {
                        setSDNNotificationModalState({
                            btnLoading: false,
                            clearData: false,
                            verifyData: false
                        });
                        setEditSDNNotificationModalState(false);
                    }}
                    modalState={notificationSDNModalState}
                />
                <CreateSDNPlaceModal
                    t={t}
                    open={createSDNPlaceModal}
                    handleCreateSDNPlace={handleCreateSDNPlace}
                    data={updateModalState.place}
                    handleClose={() => {
                        setPlaceLoadModalState({
                            clearData: false,
                            btnLoading: false
                        });
                        setSDNPlaceModalState(false);
                    }}
                    modalState={placeLoadModalState}
                />
            </React.Fragment>
        </>
    );
};
export default withTranslation()(React.memo(ManagedNFVService));