import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marginGenerator, paddingGenerator } from '../../../../../theme/utils';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../../../../components/Message';
import { REQUIRED_ERROR, INVALID_EMAIL } from '../../../../../utils/constants';
import OutlinedSelect from '../../../../../components/OutlinedSelect';
import TextField from '@material-ui/core/TextField';
import { checkValidEmail } from '../../../../../utils';

const useStyles = makeStyles(theme => ({
    paper: { maxWidth: "800px" },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: 48,
        width: 48,
        borderRadius: '50%'
    },
    iconButton: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1.25, 3),
        borderRadius: '24px',
        marginTop: theme.spacing(2)
    },
    aiParamList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: '24px'
    }
}));

function ButtonComponent(props) {
    const { t, onClick, loading } = props;
    return (
        <Button
            color="secondary"
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && t('submit.label')}
        </Button>
    );
}

function CreateOutsideProjectManagementTeamModal({
    t,
    open,
    handleSubmitClick,
    handleClose,
    modalState,
    AllInstallTeamUserList,
    selectedProjectTypeAndUid,
    handleUploadFiles,
    modalContentLoading
}) {
    const classes = useStyles();
    const [projectFiles, setProjectFiles] = useState('');
    const [costControlForm, setCostControlForm] = useState('');
    const [installTeam, setInstallTeam] = useState('');
    const [purchaseOrderForm, setPurchaseOrderForm] = useState('');
    const [vendor, setVendor] = useState('');
    const [vendorEmail, setVendorEmail] = useState('');
    const [nextTeamMemberEmail, setNextTeamMemberEmail] = useState('');
    const [onHold, setOnHold] = useState('');
    const [category, setCategory] = useState('');
    const [equipmentsToBeDeployed, setEquipmentsToBeDeployed] = useState('');

    React.useEffect(() => {
        if (modalState.clearData === true) {
            setProjectFiles('');
            setCostControlForm('');
            setInstallTeam('');
            setPurchaseOrderForm('');
            setVendor('');
            setVendorEmail('');
            setNextTeamMemberEmail('');
            setOnHold('');
            setCategory('');
            setEquipmentsToBeDeployed('');
        }
    }, [modalState, open, modalContentLoading]);
    const nextTeamList = [
        {
            id: 'Team_1',
            name: "Team 1"
        },
        {
            id: 'Team_2',
            name: "Team 2"
        },
        {
            id: 'Team_3',
            name: "Team 3"
        }
    ];
    const documentTypes = [
        {
            id: 'fault_management',
            name: "Fault Management"
        },
        {
            id: 'assured_certificate',
            name: "Assured Certificate"
        },
        {
            id: 'refuribish_request',
            name: "Refuribish Request"
        }
    ];
    const onHoldList = [
        {
            id: t("project.label")+' '+t("inProgress.label"),
            name: t("project.label")+' '+t("inProgress.label")
        },
        {
            id: t("project.label")+' '+t("onHold.label"),
            name: t("project.label")+' '+t("onHold.label")
        },
        {
            id: t("project.label")+' '+t("completed.label"),
            name: t("project.label")+' '+t("completed.label")
        },
        {
            id: t("project.label")+' '+t("canceled.label"),
            name: t("project.label")+' '+t("canceled.label")
        },
    ]
    const categoryList = [
        {
            id: t("customerInstallDelay.label"),
            name: t("customerInstallDelay.label")
        },
        {
            id: t("customerIntegrationDelay.label"),
            name: t("customerIntegrationDelay.label")
        },
        {
            id: t("customerAcceptanceDelay.label"),
            name: t("customerAcceptanceDelay.label")
        },
        {
            id: t("inqInternalDelay.label"),
            name: t("inqInternalDelay.label")
        },
        {
            id: t("3rdPartyDelay.label"),
            name: t("3rdPartyDelay.label")
        },
        {
            id: t("equipmentOrderAndDelivery.label"),
            name: t("equipmentOrderAndDelivery.label")
        },
        {
            id: t("solutionRedesigningDelay.label"),
            name: t("solutionRedesigningDelay.label")
        },
        {
            id: t("none.label"),
            name: t("none.label")
        },
    ]
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="country-modal"
            aria-describedby="country-modal"
            open={open}
            onClose={() => {
                handleClose();
            }}>
            <DialogTitle>{t('projectManagementTeamSection.label')}</DialogTitle>
            <DialogTitle>
                <Typography variant="subtitle1">{t('caseId.label')} : {selectedProjectTypeAndUid.case_number ? selectedProjectTypeAndUid.case_number : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('customer.label') + ' / ' + t('organisationName.label')} : {selectedProjectTypeAndUid.customer_name} / {selectedProjectTypeAndUid.organization_name}</Typography>
                <Typography variant="subtitle1">{t('project.label') + ' ' + t('owner.label')} : {selectedProjectTypeAndUid.project_owner ? selectedProjectTypeAndUid.project_owner : 'N/A'}</Typography>
                <Typography variant="subtitle1">{t('projectName.label')} : {selectedProjectTypeAndUid.project_name ? selectedProjectTypeAndUid.project_name : 'N/A'}</Typography>
            </DialogTitle>
            <DialogContent>
                {modalContentLoading ? <Grid style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "2%" }} color="inherit" size={35} />
                </Grid> :
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('projectFile.label')}*</Typography>
                            <Grid style={{
                                ...marginGenerator('mt-5')
                            }}>
                                <Input
                                    type={'file'}
                                    placeholder={t('clickHereToViewFiles.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0) {
                                            let file = evt.target.files[0];
                                            setProjectFiles(file);
                                        } else {
                                            setProjectFiles('');
                                        }
                                    }}
                                />
                                {/* <Button variant={'outlined'}
                                color={'primary'}
                                onClick={() => {                                    
                                }}>{t('clickHereToViewFiles.label')}</Button> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('costControlForm.label')}*</Typography>
                            <Grid style={{
                                ...marginGenerator('mt-5')
                            }}>
                                <Input
                                    type={'file'}
                                    placeholder={t('costControlForm.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0) {
                                            let file = evt.target.files[0];
                                            setCostControlForm(file);
                                        } else {
                                            setCostControlForm('');
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2">{t('installTeam.label')}*</Typography> */}
                            <Grid style={{
                                ...marginGenerator('mt-5')
                            }}>
                                <OutlinedSelect
                                    label={`${t('installTeam.label')} *`}
                                    handleChange={evt => {
                                        if (evt.target.value) {
                                            setInstallTeam(evt.target.value);
                                            for (let i = 0; i < AllInstallTeamUserList.length; i++) {
                                                if (AllInstallTeamUserList[i].name == evt.target.value) {
                                                    setNextTeamMemberEmail(AllInstallTeamUserList[i].email);
                                                    break;
                                                }
                                            }
                                        }
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={AllInstallTeamUserList ? AllInstallTeamUserList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('purchaseOrderForm.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    type={'file'}
                                    placeholder={t('name.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => {
                                        if (evt.target.files && evt.target.files.length > 0) {
                                            let file = evt.target.files[0];
                                            setPurchaseOrderForm(file);
                                        } else {
                                            setPurchaseOrderForm('');
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('vendor.label')} *`}
                                    handleChange={evt => {
                                        setVendor(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={documentTypes ? documentTypes : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('vendors.label')} {t('emailAddress.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={vendorEmail}
                                    type={'text'}
                                    placeholder={t('vendors.label') + ' ' + t('emailAddress.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setVendorEmail(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('equipmentsToBeDeployed.label')}*</Typography>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <Input
                                    value={equipmentsToBeDeployed}
                                    type={'text'}
                                    placeholder={t('equipmentsToBeDeployed.label')}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={evt => setEquipmentsToBeDeployed(evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('osla.label')+' '+t('category.label')}`}
                                    handleChange={evt => {
                                        setCategory(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={categoryList ? categoryList : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                style={{
                                    ...marginGenerator('mt-5')
                                }}>
                                <OutlinedSelect
                                    label={`${t('project.label')+' '+t('status.label')}`}
                                    handleChange={evt => {
                                        setOnHold(evt.target.value);
                                    }}
                                    selectStyle={{ ...paddingGenerator('p-8') }}
                                    styleOverrides={{ width: '100%', marginBottom: 10 }}
                                    options={onHoldList ? onHoldList : []}
                                />
                            </Grid>
                        </Grid>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Grid container justify={'space-between'}>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancel.label')}
                    </Button>
                    <ButtonComponent
                        t={t}
                        onClick={async () => {
                            if (!projectFiles || !costControlForm || !equipmentsToBeDeployed || !installTeam || !purchaseOrderForm || !vendor || !vendorEmail || !nextTeamMemberEmail) {
                                Message.error(REQUIRED_ERROR);
                                return null;
                            }
                            if (!checkValidEmail(vendorEmail)) {
                                Message.error(INVALID_EMAIL);
                                return null;
                            }
                            let getProjectFiles = "";
                            if (projectFiles != "" && projectFiles != null) {
                                let getProjectFilesUrl = await handleUploadFiles(projectFiles, selectedProjectTypeAndUid.uid, 'outside_project_team_section');
                                if (getProjectFilesUrl.data && getProjectFilesUrl.data.allUploadFile && getProjectFilesUrl.data.allUploadFile.url) {
                                    getProjectFiles = getProjectFilesUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            let getCostControlForm = "";
                            if (costControlForm != "" && costControlForm != null) {
                                let getCostControlFormUrl = await handleUploadFiles(costControlForm, selectedProjectTypeAndUid.uid, 'outside_project_team_section');
                                if (getCostControlFormUrl.data && getCostControlFormUrl.data.allUploadFile && getCostControlFormUrl.data.allUploadFile.url) {
                                    getCostControlForm = getCostControlFormUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            let getPurchaseOrderForm = "";
                            if (purchaseOrderForm != "" && purchaseOrderForm != null) {
                                let getPurchaseOrderFormUrl = await handleUploadFiles(purchaseOrderForm, selectedProjectTypeAndUid.uid, 'outside_project_team_section');
                                if (getPurchaseOrderFormUrl.data && getPurchaseOrderFormUrl.data.allUploadFile && getPurchaseOrderFormUrl.data.allUploadFile.url) {
                                    getPurchaseOrderForm = getPurchaseOrderFormUrl.data.allUploadFile.url;
                                }
                                else {
                                    Message.error("File not uploaded! Please try again.");
                                }
                            }
                            handleSubmitClick({
                                projectFiles: getProjectFiles,
                                costControlForm: getCostControlForm,
                                equipments_to_be_deployed: equipmentsToBeDeployed,
                                installTeam: installTeam,
                                purchaseOrderForm: getPurchaseOrderForm,
                                vendor: vendor,
                                vendorEmail: vendorEmail,
                                project_uid: selectedProjectTypeAndUid.uid,
                                formName: 'OutsideProjectManagementForm',
                                next_team_member_email: nextTeamMemberEmail,
                                category: category,
                                on_hold: onHold,
                            });
                        }}
                        loading={modalState.btnLoading} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

CreateOutsideProjectManagementTeamModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(CreateOutsideProjectManagementTeamModal);
