import { gql } from 'apollo-boost';

/*
  Update service ticket
 */

export const UPDATE_CYBER_SECURITY_FORM = gql`
  mutation CyberSecurity($id: String $status: String $assignee : String $requestId: String){
    updateCSIncidentForm(id: $id status: $status assignee: $assignee requestId: $requestId){
      id
    }
  } 
`;