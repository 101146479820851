import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/react-hooks';
import { colors } from '../../../../../../../theme/colors';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SecondaryLayout from '../../../../../../../containers/OnBoarding/components/SecondaryLayout';
import Header from '../../../../../../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import {
  marginGenerator
} from '../../../../../../../theme/utils';
import Message from '../../../../../../../components/Message';
import { UPDATE_ALERT } from '../../../../../Shayona/mutations';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: '80%',
    maxHeight: 435
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
}));

function ButtonComponent(props) {
  const { t, onClick, loading, disabledValue } = props;
  return (
    <Button
      color="secondary"
      variant={'contained'}
      onClick={onClick}
      disabled={loading || disabledValue}
      type="submit">
      {loading && <CircularProgress size={14} />}
      {!loading && t('submit.label')}
    </Button>
  );
}

const ShayonaAlertApproverFlow = ({ t, props }) => {
  
  const history = useHistory(); 
  const { id } = useParams();

  const validationSchema = Yup.object({
    comment: Yup.string('Please fill comment').required('Please fill comment')
  });

  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmtiOfTruckDetails(values);
    }
  });

  const [isApproved, setIsApproved] = useState(null);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    errors,
    values
  } = formik;

  const [updateAlert, { loading: updateAlertLoading }] = useMutation(
    UPDATE_ALERT,
    {
      onError: error => {
        console.log('err', error);
      },
      onCompleted: data => {
        console.log('data', data);
        Message.success(data && data.updateAlert.message);
        history.push({ pathname: '/' });
      }
    }
  );

  const handleSubmtiOfTruckDetails = values => {
    updateAlert({
      variables: {
        comment: values && values.comment,
        is_approved: isApproved,
        alertId: parseInt(id)
      }
    });
  };
  return (
    <SecondaryLayout
      scope={'Login'}
      contentStyle={{
        maxWidth: '328px',
        ...marginGenerator('mt-130'),
        ...marginGenerator('mb-150')
      }}>
      <Header
        title={'Fill your feedback'}
        titleStyleOverride={{
          color: colors.grey[400],
          fontSize: '2.2rem',
          padding: 0
        }}
        subText={''}
        titleVariant="body1"
        subtitleVariant={'h2'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0 }}
      />
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          style={{ alignItems: 'center', marginBottom: '5px' }}>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              color={isApproved?"secondary":"primary"}
              onClick={() => setIsApproved(true)}>
              Approved
            </Button>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              color={isApproved === false?"secondary":"primary"}
              onClick={() => setIsApproved(false)}>
              Reject
            </Button>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="standard-multiline-flexible"
              rows={4}
              variant="standard"
              value={values.comment}
              label="Comments"
              name="comment"
              style={{ width: '100%' }}
              multiline
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.comment && errors.comment}
              error={touched.comment && Boolean(errors.comment)}
            />
          </Grid>
        </Grid>
        <Grid container justify={'space-between'}>
          <ButtonComponent t={t} loading={false} />
        </Grid>
      </form>
    </SecondaryLayout>
  );
};

export default withTranslation()(ShayonaAlertApproverFlow);
