import React, { useState } from 'react';
import Header from '../../components/Header';
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { marginGenerator, paddingGenerator } from '../../theme/utils';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Message from '../../components/Message';
import { GET_COUNTRY_CODE_LIST } from '../commonQueries';
import { ADD_USER_TO_ORG, CREATE_USER, EMAIL_OTP_VERIFICATION, RESEND_OTP } from './mutations';
import SecondaryLayout from './components/SecondaryLayout';
import { colors } from '../../theme/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { REQUIRED_ERROR, INVALID_EMAIL, INVALID_PHONE } from '../../utils/constants';
import { checkValidEmail } from '../../utils';
import VerifyEmailModal from './components/verifyEmailModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  inputBase: {
    width: '100%',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 15,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

function CreateUser({ t }) {

  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const [verifyEmailModalState, setVerifyEmailModalState] = useState(false);
  const [createdEmailState, setCreatedEmailState] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  const { data: countryCodes, loading: ccLoading } = useQuery(GET_COUNTRY_CODE_LIST);

  const [addUserToOrg] = useMutation(ADD_USER_TO_ORG, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      Message.success('Verification link has been sent on your email, Please check your email and activate your account!');
      localStorage.removeItem('syncomToken');
      setVerifyEmailModalState(true);
    }
  });

  const [createUser] = useMutation(CREATE_USER, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      setCreatedEmailState(data.createUser.email);
      return addUserToOrg({
        variables: {
          userEmail: email,
          orgName: window.location.search && window.location.search.slice(1)
        }
      });
    }
  });

  const [emailOTPVerification] = useMutation(EMAIL_OTP_VERIFICATION, {
    onError: error => {
      console.log('Error', error);
      setVerificationLoading(false);
      Message.success('Invalid OTP!');
    },
    onCompleted: data => {
      setVerificationLoading(false);
      if (data.emailOTPVerification && data.emailOTPVerification.id) {
        Message.success('Email verified successfully.');
        localStorage.removeItem('syncomToken');
        window.location.replace('/');
      } else if (!data.emailOTPVerification.success && data.emailOTPVerification.msg === 'otp_expired') {
        Message.success('OTP expired, Please resend OTP.');
      } else {
        Message.success('Invalid OTP!');
      }
    }
  });

  const [resendOTP] = useMutation(RESEND_OTP, {
    onError: error => {
      console.log('Error', error);
    },
    onCompleted: data => {
      if (data.resendOTP && data.resendOTP.email) {
        Message.success('OTP sent successfully on your registerd email address');
      }
    }
  });

  const handleVerifyEmailClick = (data) => {
    setVerificationLoading(true);
    setTimeout(() => {
      return emailOTPVerification({
        variables: {
          email: createdEmailState,
          otp: data.otp,
          login: ''
        }
      });
    }, 1500);
  }

  const handleResendOtp = () => {
    if (createdEmailState) {
      return resendOTP({ variables: { email: createdEmailState, action: 'email_verify' } });
    }
  }

  if (!ccLoading && countryCodes.getCoutriesCode) {
    countryCodes.getCoutriesCode = countryCodes.getCoutriesCode.map((item) => {
      item.name = item.flag + ' ' + item.dial_code;
      item.id = item.dial_code;
      return item;
    });
  }

  return (
    <SecondaryLayout
      contentStyle={{ maxWidth: '468px', ...marginGenerator('mt-162') }}>
      <Header
        title={t('createAccount.label')}
        titleStyleOverride={{ fontWeight: 'bold', padding: 0 }}
        subText={t('createAccountStep2.label')}
        subtitleVariant={'h2'}
        subtitleStyleOverride={{ color: colors.grey[400], padding: 0 }}
      />
      <Header
        title={t('userDetails.label')}
        containerStyle={marginGenerator('mt-16')}
        titleStyleOverride={{ fontSize: '2rem', padding: 0 }}
        subText={t('userDetailsSub.label')}
        subtitleVariant={'h2'}
        subtitleStyleOverride={{
          color: colors.grey[400],
          padding: 0,
          fontWeight: 'normal'
        }}
      />
      <Grid container style={{ ...marginGenerator('mt-24'), ...marginGenerator('mb-24') }}>
        <Grid container item justify={'space-between'}>
          <Input
            value={firstName}
            type={'text'}
            placeholder={`${t('firstName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setFirstName(evt.target.value)}
          />
          <Input
            value={lastName}
            type={'text'}
            placeholder={`${t('lastName.label')} *`}
            style={{ minWidth: '220px' }}
            color={'secondary'}
            onChange={evt => setLastName(evt.target.value)}
          />
        </Grid>
        <Input
          value={email}
          type={'text'}
          placeholder={`${t('email.label')} *`}
          style={{ width: '100%', ...marginGenerator('mt-24') }}
          color={'secondary'}
          onChange={evt => setEmail(evt.target.value)}
        />
        <Grid container spacing={3} style={marginGenerator('mt-5')}>
          <Grid item xs={4}>
            <Autocomplete
              style={{ width: '100%', marginRight: 0, ...marginGenerator('mt-24') }}
              options={!ccLoading ? countryCodes.getCoutriesCode : []}
              getOptionLabel={(option) => option.name}
              onChange={(evt, newValue) => {
                setCountryCode(newValue ? newValue.dial_code : '');
              }}
              renderInput={(params) => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.inputBase}
                  placeholder={t('code.label')}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Input
              value={phone}
              type={'text'}
              placeholder={`${t('phone.label')} *`}
              style={{ width: '100%', ...marginGenerator('mt-24') }}
              color={'secondary'}
              onChange={evt => setPhone(evt.target.value)}
            />
          </Grid>
        </Grid>
        <Input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder={`${t('password.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Input
          value={confirmPassword}
          type={showConfPassword ? 'text' : 'password'}
          placeholder={`${t('confPassword.label')} *`}
          style={{
            width: '100%',
            ...marginGenerator('mt-24'),
            ...paddingGenerator('pr-12')
          }}
          color={'secondary'}
          onChange={evt => setConfirmPassword(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{
                  ...paddingGenerator('pt-8'),
                  ...paddingGenerator('pr-4')
                }}
                onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant={'contained'}
          size={'large'}
          style={{ ...marginGenerator('mt-24') }}
          disabled={false}
          color={'primary'}
          onClick={() => {
            if (!firstName || !lastName || !email || !phone || !countryCode || !password || !confirmPassword) {
              Message.error(REQUIRED_ERROR);
              return null;
            }

            if (!checkValidEmail(email)) {
              setEmail('');
              Message.error(INVALID_EMAIL);
              return null;
            }

            const regex = /^\d+$/;
            if (!regex.test(phone) || phone.length > 10 || phone.length < 7) {
              Message.error(INVALID_PHONE);
              return null;
            }

            if (password !== confirmPassword) {
              Message.error("Password and confirm password must be same");
              return null;
            }
            return createUser({
              variables: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: countryCode + phone,
                password: password,
                isInvite: false
              }
            });
          }}>
          Create Account
        </Button>
      </Grid>
      {verifyEmailModalState &&
        <VerifyEmailModal
          t={t}
          open={verifyEmailModalState}
          handleClose={() => setVerifyEmailModalState(false)}
          handleVerifyEmailClick={handleVerifyEmailClick}
          handleResendOtp={handleResendOtp}
          loading={verificationLoading}
        />
      }
    </SecondaryLayout>
  );
}

export default withTranslation()(CreateUser);
