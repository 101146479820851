import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableHead, TableRow, TableCell, Typography, TableBody, TableContainer, IconButton, Button, CircularProgress } from '@material-ui/core';
// import { marginGenerator } from '../../../../theme/utils';
// import Button from '@material-ui/core/Button';
// import Autocomplete from '../../../../components/Autocomplete';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Checkbox from '@material-ui/core/Checkbox';

const LiveCameraViewURLPanel = ({
    t,
    cameraList,
    multipleCameraPlayer,
    handleChangeMultiCamera,
    handlePlayStream,
    liveMultiCamLoading,
    handleMultipleCameraPlayer
}) => {

    // const [selectedLiveCam, setSelectedLiveCam] = useState('');
    // const [firstLoad, setFirstLoad] = useState(false);

    // useEffect(() => {
    //     if (!firstLoad && cameraList && cameraList.length) {
    //         console.log("cameraList: ", cameraList);
    //         setFirstLoad(true);
    //     }
    // }, [cameraList]);

    // const handleSelectLiveCam = (val) => {
    //     setSelectedLiveCam(val);
    // }

    const columns = [
        {
            label: '',
            key: "select"
        },
        {
            label: 'Camera ID',
            key: "camera_id"
        },
        {
            label: t('name.label'),
            key: "mode"
        },
        {
            label: 'T4 Camera ID',
            key: "t4_camera"
        },
        {
            label: 'Play',
            key: "action"
        }
    ];

    return (
        <>
            <Button onClick={(evt) => handleMultipleCameraPlayer('start')} disabled={(!Object.keys(multipleCameraPlayer).length) || liveMultiCamLoading} style={{ float: 'right' }} color="primary" variant="outlined" >{liveMultiCamLoading ? <CircularProgress size={20}/> : "Play Selected Cameras"}</Button>
            <Grid container >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Typography variant={'subtitle2'}>{item.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(cameraList && cameraList.length > 0) ? cameraList.map((cam) => (
                                <React.Fragment key={cam.camera_id}>
                                    <TableRow>
                                        <TableCell>
                                        <Checkbox
                                            checked={(multipleCameraPlayer && multipleCameraPlayer[cam.camera_id])} 
                                            value={cam.camera_id}
                                            onChange={(evt) => handleChangeMultiCamera(evt)}
                                        />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle2'}>{cam.camera_id}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle2'}>{cam.name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle2'}>{cam.t4_cam_id}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={(evt) => handlePlayStream(cam, 'start')}>
                                                <PlayCircleFilledWhiteIcon color="primary" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )) :
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <Typography variant={'subtitle2'}>Record not found!</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {/* <Autocomplete
                styleOverrides={{ minWidth: '250px', width: 280 }}
                handleChange={(evt, newVal) => {
                    handleSelectLiveCam(newVal);
                }}
                val={selectedLiveCam}
                options={cameraList}
                label={t("camera.label")}
                size="small"
                handleClose={evt => {
                    onInputChange("");
                }}
                onInputChange={onInputChange}
            /> */}
            {/* {selectedLiveCam && <React.Fragment>
                    <Button style={{float: "right"}} size="small" variant="outlined" color="primary" target="_blank" title={t("openUrlTitle.label")} href={liveCamURL + selectedLiveCam.node + selectedLiveCam.t4_cam_id}>{t("openUrl.label")}</Button>
                    <Grid style={{...marginGenerator('mb-24')}}>
                        <iframe
                            style={{width:'100%', height: 850, overflow:'visible', display: "inline-block"}}
                            src={liveCamURL + selectedLiveCam.node + selectedLiveCam.t4_cam_id} 
                            width="100%"
                            frameBorder="0"
                            loading="lazy"
                        />
                    </Grid>
                </React.Fragment>
            } */}


        </>
    );
};

LiveCameraViewURLPanel.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default LiveCameraViewURLPanel;