import React from 'react';
import TextField from '@material-ui/core/TextField';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import './index.css';

const useStyles = makeStyles({
  endAdornment: {
    top: '0px'
  }
});

const Autocomplete = ({
  handleChange,
  options,
  label,
  val,
  styleOverrides,
  size,
  onInputChange,
  handleClose,
  multiple
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  return (
    <MUIAutocomplete
      id="size-small-outlined-multi"
      multiple={multiple ? multiple : false}
      value={val}
      style={styleOverrides ? styleOverrides : null}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        if (typeof handleClose !== 'undefined') {
          handleClose();
        }
      }}
      limitTags={1}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      options={options}
      loading={loading}
      size={size ? size : 'medium'}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={ev => {
            if (ev.target.value !== '' || ev.target.value !== null) {
              onInputChange(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            className: `${params.InputProps.className} ${classes.endAdornment}`
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
